import React from 'react';
import "./Mail.css";
import LoginView from "./MailComponents/LoginView";
import MailView from "./MailComponents/MailView";
import DataHandler from "./../general/DataHandler";
import PageTopSection from '../general/PageTopSection';

//Mail componens root component
class Mail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mode:'view',
			isOffice: false,
			chosenMail: "",
		};
	}

	//Triggers when office 365 is selected
	onClickMailOffice = () => {
		DataHandler.get({url: `mails/login`}).done(response => {
			if (response.url){
				sessionStorage.setItem("previousUrl", window.location);
				sessionStorage.setItem("nextAction", "mail-office-login");
				window.location = response.url;
			}
		})
	}

	//Triggers when google mail is selected
	onClickMailGoogle= () => {};

	//Triggers after component is mounted
	componentDidMount() {
		DataHandler.get({url: `mails/login`}).done(response => {

			if (response.url)
			{
				this.setState({
					isOfficeAuthenticated: false,
				})

			} else {
				this.setState({
					isOfficeAuthenticated: true,
				})
			}
			
		})
	}
 
	//Returns page depending is user authenticated to mail client (office 365)
	renderView() {
		if(this.state.isOfficeAuthenticated) {
			return <MailView projectid={this.props.projectid}/>		
		} else {
			return <LoginView onClickMailOffice={this.onClickMailOffice} onClickMailGoogle={this.onClickMailGoogle}/>;
		}
	}

	//Renders page
	render() {
		return (
			<div id={'contentBlockMail'} className={`${this.props.className}`} >
				{this.props.header && (
                    <PageTopSection
                        header={this.props.header}
                        subheaders={this.props.subheaders}
                    />
                )}
				{this.renderView()}		
			</div>
		);
	}
}

export default Mail;