import React from 'react';

import TaimerComponent from '../TaimerComponent';
import styles from './ResourceDialogUsers.module.scss';
import ResourceDialogUsersRow from './ResourceDialogUsersRow';
import cn from 'classnames';
import DataList from '../general/DataList';

import { ReactComponent as UserIcon } from '../navigation/ActionIcons/my_profile.svg';
import { ReactComponent as TeamIcon } from '../navigation/NavIcons/customers.svg';
import DataHandler from '../general/DataHandler';

import { ReactComponent as Share } from '../general/icons/share.svg';
import { divideHoursEqually, Employee, getAutocompleteDataForDialog } from '../resourcing/helpers';
import { parse } from 'date-fns';
import minBy from 'lodash/minBy';
import keyBy from 'lodash/keyBy';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Task, UserHour, UserHourPeriod } from '../resourcing/resourcing';
import moment from 'moment';

export interface DialogUserHourPeriod extends UserHourPeriod {
    invalid?: boolean;
    deleted?: boolean;
    lastHours?: number;
}

export interface DialogUserHour extends UserHour {
    name?: string;
    lastHours?: number;
    invalid?: boolean;
    deleted?: boolean;
    periods: DialogUserHourPeriod[];
}

interface Project {
    id: string|number;
    companies_id: string|number;
}

interface Team {
    id: number;
    name: string;
    type: string;
    users: number[];
}

interface TeamMembers {
    id: string;
}

export interface ChangeParams {
    portionNotShared?: number;
}

interface Props extends WithSnackbarProps {
    className?: string;
    task: Task;
    disableEdit: boolean;
    showVacationCheck: boolean;
    projects: Project[];
    userHours: DialogUserHour[];
    employees: Employee[];
    teams: Team[];
    onChange?: (userHours: DialogUserHour[], indexChanged: number|undefined, changeParams: ChangeParams) => void;
    isOneDayTask?: boolean;
}

interface State {
}

interface Dropitem {
    id?: string|number;
    label: string;
    name: string;
    headerName?: 'team'|'user';
    type?: 'user'|'team'|'header'|'project_team';
    icon?: React.ElementType;
}

export function validateUsers(task: Task, users: DialogUserHour[]): boolean {
    return users.every(user => validateUser(task, user));
}

export function validateUser(task: Task, user: DialogUserHour): boolean {
    const lastDate: string|null = null;

    for (const period of user.periods) {
    }

    return true;
}

function compareTeams(t1: Dropitem, t2: Dropitem) {
    const label1 = t1.label.toLowerCase();
    const label2 = t2.label.toLowerCase();

    if (label1 > label2) return 1;
    if (label2 > label1) return -1;
  
    return 0;
}

class ResourceDialogUsers extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'dialogs/ResourceDialogUsers');

        this.state = {
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
    };

    createAssignDropdown = (hasOwnHours) => { 
        const { userObject } = this.context;
        const { projects: projectsSelected, employees, teams } = this.props;
        
        let items: Dropitem[] = [];

        items = teams.map<Dropitem>(team => ({id: team.id, name: team.name, label: team.name, type: "team", headerName: "team" }));
        items.sort(compareTeams);

        if (items.length > 0)
            items.unshift({icon: TeamIcon, type: "header", label: this.tr('Team'), name: 'team'});
        items.push({icon: UserIcon, type: "header", label: this.tr('User'), name: 'user'});

        if (projectsSelected.length === 1)
             items.unshift({id: projectsSelected[0].id, label: this.tr("Assign to project team"), name: this.tr("Assign to project team"), type: "project_team"});

        for (const x of employees) {
            if (x.id < 0)
                continue;

            if (!x.read_companies_all.length && !x.read_companies_projects_only.length)
                continue;

            let hasProjectRight = true;
                
            for (const p of projectsSelected) {
                if (!x.read_companies_all.includes(Number(p.companies_id)) && !x.projects.includes(Number(p.id)))
                    hasProjectRight = false;
            }

            if (!hasProjectRight) {
                continue;
            }

            if (!hasOwnHours && Number(x.id) === Number(userObject.usersId)) {
                items.unshift({id: x.id, label: this.tr("Assign to me"), name: this.tr("Assign to me"), type: 'user'});
            }

            items.push({id: x.id, label: x.name, name: 'user', type: 'user', headerName: "user"});
        }

        return items;
    }

    getAssignableUsers = async (e: Dropitem): Promise<number[]> => {
        const { teams } = this.props;

        if (e.type === 'project_team') {
            const team_members: TeamMembers[] = await DataHandler.get({ url: `projects/${e.id}/team`});
            return team_members.map(x => Number(x.id));
        } else if (e.type === "team") {
            const team = teams.find(t => t.id === e.id);

            return team?.users ?? [];
        }
        else if (e.type === 'user' && e.id) {
            return [Number(e.id)];
        }

        return [];
    }

    assignUsers = async (e: Dropitem) => {
        const { task, employees, userHours, projects, enqueueSnackbar, onChange, isOneDayTask } = this.props;

        const usersToAdd = await this.getAssignableUsers(e);

        const updated = [...userHours];

        const minNew = minBy(updated.filter(x => x.id < 0), x => x.id);
        let nextId = minNew ? minNew.id - 1 : -1;

        let assignedAmount = 0;
        let noPermissionCount = 0;

        let hours = 0;

        if (isOneDayTask) {
            const startString = task.starttime;
            const endString = task.endtime === '00:00:00' ? '23:59:59' : task.endtime;
            let start: any = moment(startString, 'HH:mm:ss');
            let end: any = moment(endString, 'HH:mm:ss');
            start = start.isValid() ? start : undefined;
            end = end.isValid() ? end : undefined;
            const timeInvalid = !start || !end || end < start;
            if (!timeInvalid) {
                hours = Number(((end - start) / (1000 * 60 * 60)).toFixed(2));
            }
        }

        for (const userId of usersToAdd) {
            const user = employees.find(el => el.id === userId);
            const uh = updated.findIndex(x => x.users_id === userId && !x.deleted);

            if (!user) { // Unknown user (no permission to see)
                noPermissionCount++;
                continue;
            }

            if (uh > -1) // Already added
                continue;

            let canAddUser = true;

            for (const project of projects) {
                const ac = await getAutocompleteDataForDialog(project.companies_id);
                
                const companyUser = ac.employees.find(el => el.id === user.id);

                if (!companyUser) {
                    canAddUser = false;
                }
                else if (!companyUser.projects.includes(Number(project.id)) && !companyUser.read_companies_all.includes(Number(project.companies_id))) {
                    canAddUser = false;
                }
            }

            if (!canAddUser) {
                console.log({projects, user})
                noPermissionCount++;
                continue;
            }

            assignedAmount++;

            updated.push({
                resources_id: task.task_id,
                id: nextId,
                users_id: user.id,
                deleted: false,
                done: false,
                hours,
                hours_done: 0,
                hours_done_today: 0,
                hours_done_before_today: 0,
                is_split: false,
                periods: [],
                name: user.name,
                days: 0, // TODO
                allTotal: null,
                periodTotal: null,
                gridData: null,
            });

            nextId--;
        }

        const noPermissionInfo = noPermissionCount > 0 ? this.tr("${users} users have no permission", {users: noPermissionCount}) : '';

        if (assignedAmount === 0) {
            enqueueSnackbar(this.tr("Already assigned to all team members") + (noPermissionInfo ? `. ${noPermissionInfo}` : ''), {
                variant: "warning",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                }
            });
        }
        else {
            enqueueSnackbar(this.tr("${assignedAmount} users added", {assignedAmount: assignedAmount}) +  (noPermissionInfo ? `. ${noPermissionInfo}` : ''), {
                variant: "info",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                }
            });
        }

        onChange?.(updated, undefined, {});
    }

    onChangeRow = (row: DialogUserHour) => {
        const { onChange, userHours } = this.props;
        const index = userHours.findIndex(x => x.id === row.id);

        if (index < 0)
            return;

        const updated = [
            ...userHours,
        ];
        updated[index] = row;

        onChange?.(updated, index, {});
    }

    onDeleteRow = (row: DialogUserHour) => {
        const { onChange, userHours } = this.props;

        const index = userHours.findIndex(x => x.id === row.id);

        if (index < 0)
            return;

        const updated = [...userHours];
        updated[index] = {
            ...updated[index],
            deleted: true,
        };

        onChange?.(updated.filter(x => !(x.id < 0 && x.deleted)), index, {});
    }

    shareHours = () => {
        const { task, userHours, onChange } = this.props;

        const userHoursFiltered = userHours.filter(x => !x.deleted);
        const activeUsers = userHoursFiltered.length;

        const hoursPerUser = divideHoursEqually(Number(task.hours), activeUsers);
        const diff = Number((task.hours - (hoursPerUser * activeUsers)).toFixed(2));

        const userHoursNew = userHours.map(x => ({
            ...x,
            hours: x.deleted ? 0 : hoursPerUser,
            lastHours: x.deleted ? undefined : hoursPerUser,
        }));

        onChange?.(userHoursNew, undefined, {
            portionNotShared: diff > 0 ? diff : undefined,
        });
    }

    render() {
        const { userObject } = this.context;
        const { task, employees, className, disableEdit, showVacationCheck, userHours, projects, isOneDayTask } = this.props;

        const ownHour = userHours.find(el => el.users_id === Number(userObject.usersId) && !el.deleted);

        const userHoursFiltered = userHours.filter(x => !x.deleted);
        const assignItems = this.createAssignDropdown(ownHour != null);

        const employeeMap = keyBy(employees, x => x.id);

        const disableHoursEdit = disableEdit || (showVacationCheck && task.is_full_vacation) || isOneDayTask;

        return (
            <div className={cn(styles.users, className)}>
                <DataList
                    disabled={disableEdit || task.done}
                    isDisabled={disableEdit || task.done}
                    dropLabel={this.tr("Assigned to")}
                    options={assignItems}
                    onChange={e => this.assignUsers(e)}
                    fullWidth={true}
                    className="full"
                    hasHeaders={true}
                    virtualized={true}
                    name="task_assigned_user"
                />

                <div className={styles.buttons}>
                    <div className={styles.assignButtons}>
                        {!ownHour && !disableEdit &&
                            <div
                                className={styles.button}
                                onClick={() => {
                                    this.assignUsers({type: 'user', id: Number(userObject.usersId), name: '', label: ''});
                                }}>
                                {this.tr('Assign to me')}
                            </div>
                        }
                        {projects.length === 1 && !disableEdit &&
                            <div
                                className={styles.button}
                                onClick={() => {
                                    this.assignUsers({type: 'project_team', id: projects[0].id, name: '', label: ''});
                                }}>
                                {this.tr('Assign to project members')}
                            </div>
                        }
                    </div>
                    <div data-testid="share_hours_evenly">
                        {!disableEdit && !isOneDayTask && <div className={styles.button} onClick={this.shareHours}>
                            <Share /> {this.tr("Share hours evenly")}
                        </div>}
                    </div>
                </div>
                
                {userHoursFiltered.length > 0 && <div className={styles.grid}>
                    <div className={styles.header}>
                        <div></div>
                        <div>{this.tr("User")}</div>
                        <div className={styles.twocol}>{this.tr("Tracked")} / {this.tr("Resourced")}</div>
                        <div></div>
                    </div>

                    {userHoursFiltered.map(user => <ResourceDialogUsersRow
                        className={styles.row}
                        task={task}
                        disableEdit={disableEdit}
                        disableHoursEdit={disableHoursEdit || user.done} 
                        user={user}
                        employee={employeeMap[user.users_id]}
                        onChange={this.onChangeRow}
                        onDelete={this.onDeleteRow} />)}                    
                </div>}
            </div>
        );
    }
}

export default withSnackbar(ResourceDialogUsers);