import React from 'react';

import TaimerComponent from '../TaimerComponent';
import { Table, TableHead, TableCell, TableBody, TableFooter, TableRow, Switch, Button } from '@mui/material';

import './GoalsTable.css';
import moment from 'moment';
import InsightDropDown from '../dashboard/insights/InsightDropDown';
import { SettingsContext } from '../SettingsContext';
import { Add, Edit } from '@mui/icons-material';
import { isEqual } from 'lodash';
import { withSnackbar } from 'notistack';

class GoalsTable extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'accounts/GoalsTable');

        const { tr } = this;

        this.monthNames = {
            1: tr('Jan'),
            2: tr('Feb'),
            3: tr('Mar'),
            4: tr('Apr'),
            5: tr('May'),
            6: tr('Jun'),
            7: tr('Jul'),
            8: tr('Aug'),
            9: tr('Sep'),
            10: tr('Oct'),
            11: tr('Nov'),
            12: tr('Dec'),
        };

        this.dateFormat = 'YYYY-MM-DD';

        this.currencyFormatter = new Intl.NumberFormat(context.taimerAccount.numberFormat, {
            style: 'currency',
            currency: this.props.currency,
        }).format;

        this.state = {
            editMode: this.props.selectedForecast?.id == -1,
            data: { ...this.props.rows },
            splitValue: 0,
            disabledMonths: this.getDefaultDisabledMonths(),
            columns: this.getColumnsFromProps(),
        };
    }

    getDefaultDisabledMonths = () => {
        const disabledMonths = {};
        const currentMonth = moment();
        this.props.months.forEach((m) => {
            if (moment(m, this.dateFormat).isBefore(currentMonth, 'month')) {
                disabledMonths[m] = true;
            }
        });
        return disabledMonths;
    };

    componentDidMount = () => {
        super.componentDidMount();
        if (this.props.dataUrl) {
            this.setState({ loading: true }, () => {
                setTimeout(() => {
                    // get data here with dataUrl
                    this.setState({ loading: false });
                }, 2000);
            });
        }
    };

    componentDidUpdate = (oldProps) => {
        if (oldProps.rows != this.props.rows) {
            this.setState({
                data: { ...this.props.rows },
            });
        }

        if (oldProps.months != this.props.months) {
            this.setState({
                disabledMonths: this.getDefaultDisabledMonths(),
            });
        }

        if (!isEqual(oldProps.columns, this.props.columns) || oldProps.showMonths != this.props.showMonths) {
            this.setState({ columns: this.getColumnsFromProps() });
        }

        if (oldProps.selectedForecast != this.props.selectedForecast && this.props.selectedForecast?.id == -1) {
            this.setState({
                editMode: true,
            });
        }

        if (this.props.isTotal != oldProps.isTotal) {
            this.setState({
                editMode: false,
            });
        }
    };

    formatCurrency = (value, editable = false) => {
        if (!Number.isFinite(Number(value))) return value;
        return this.state.editMode && editable ? (value !== '0.' && value == 0 ? '' : value) : this.currencyFormatter(Number(value));
    };

    toggleEditMode = (editMode) => {
        this.setState({ editMode, splitValue: 0 });
    };

    getColumnsFromProps = () => {
        const { showMonths, columns } = this.props;
        const cols = [...(columns || [])];
        if (showMonths) {
            cols.unshift({ title: this.tr('Month'), key: 'month' });
        }
        return cols;
    };

    saveChanges = () => {
        const { id = 0, type, start_date, enqueueSnackbar } = this.props;
        if (type == 3 && !this.state.columns[0].title) {
            enqueueSnackbar(this.tr('Please give the forecast a title.'), {
                variant: 'error',
            });
            return;
        }
        this.toggleEditMode(false);
        const headerData = {
            id,
            type,
            start_date,
            title: type == 3 ? (this.props.showMonths ? this.state.columns[1].title : this.state.columns[0].title) : '',
        };
        this.props.onSaveData(headerData, this.state.data);
    };

    getDataObject = () => {
        if (!this.props.summaryTable) {
            return { ...this.state.data };
        }
        return { ...(this.props.data[this.props.mainColumn] || {}) };
    };

    splitEvenly = () => {
        const { months } = this.props;
        const { splitValue, disabledMonths } = this.state;
        const monthKeys = months.filter((m) => !disabledMonths[m]);
        const splittedValue = Math.round((Number(splitValue) / monthKeys.length) * 100) / 100;
        const data = this.getDataObject();
        monthKeys.map((m) => {
            data[m] = splittedValue;
        });
        this.setState({ data });
    };

    cancelEdit = () => {
        if (this.props.isForecast && (!this.props.selectedForecast || this.props.selectedForecast.id == -1)) {
            this.props.onCancelForecast();
        }
        this.setState({
            editMode: false,
            data: { ...this.props.rows },
        });
    };

    formatInputValue = (value) => {
        let formattedValue = value.replace(',', '.');
        if (formattedValue == '.') formattedValue = '0.';
        return formattedValue;
    };

    isInputValueInvalid = (value) => {
        return value != '0' && value != '0.' && value != '' && !Number(value);
    };

    setSplitValue = (e) => {
        const splitValue = this.formatInputValue(e.target.value);
        if (this.isInputValueInvalid(splitValue)) return;
        this.setState({ splitValue });
    };

    renderEditButton = () => {
        const { editTitle, createTitle, onCreate, noEdit, summaryTable, isForecast, selectedForecast } = this.props;
        const { editMode, splitValue } = this.state;
        if (noEdit || summaryTable) return null;

        return !editMode ? (
            <div className="edit-container">
                {isForecast ? (
                    <InsightDropDown
                        titleLabel={this.tr('Actions')}
                        tabs={[
                            {
                                key: 'create',
                                label: createTitle,
                                iconComponent: Add,
                                action: onCreate,
                            },
                            ...(!selectedForecast ? [] : [{
                                key: 'edit',
                                label: editTitle,
                                iconComponent: Edit,
                                action: () => this.toggleEditMode(true),
                            }]),
                        ]}
                    />
                ) : (
                    <button onClick={() => this.toggleEditMode(true)}>
                        <Edit className="edit-icon" />
                        {editTitle || this.tr('Edit')}
                    </button>
                )}
            </div>
        ) : (
            <div className="edit-container">
                <div className="buttons">
                    <button className="cancel" onClick={this.cancelEdit}>
                        {this.tr('Cancel')}
                    </button>
                    <button onClick={this.saveChanges}>{this.tr('Save')}</button>
                </div>
                <div className="input">
                    <input value={this.formatCurrency(splitValue, true)} onFocus={(e) => e.target.select()} placeholder="0" onChange={this.setSplitValue} className="active" />
                    <button onClick={this.splitEvenly}>{this.tr('Split evenly')}</button>
                </div>
            </div>
        );
    };

    renderTopSection = () => {
        return <div className="top">{this.renderEditButton()}</div>;
    };

    renderHeader = () => {
        const { isForecast, forecasts, onChangeForecast, summaryTable, isTotal } = this.props;
        const { editMode, columns } = this.state;
        return (
            <TableHead className={summaryTable && 'dark'}>
                {columns.map((c, i) => (
                    <TableCell style={{ minWidth: c.width || 160 }} align={c.align || 'left'}>
                        {isForecast && !isTotal ? (
                            c.key == 'month' ? (
                                c.title
                            ) : editMode ? (
                                <input
                                    value={c.title}
                                    placeholder={this.tr('Name Forecast')}
                                    onChange={(e) => this.editColumnHeader(i, e.target.value)}
                                    className="active light"
                                    style={{ textAlign: c.align || 'left' }}
                                />
                            ) : (
                                forecasts && (
                                    <InsightDropDown
                                        defaultSelection={{ label: c.title, key: c.title }}
                                        tabs={forecasts.map((f) => ({
                                            ...f,
                                            label: f.title,
                                            key: f.title,
                                            action: () => onChangeForecast(f),
                                        }))}
                                    />
                                )
                            )
                        ) : (
                            c.title
                        )}
                    </TableCell>
                ))}
            </TableHead>
        );
    };

    editColumnHeader = (index, value) => {
        const columns = [...this.state.columns];
        columns[index] = {
            ...columns[index],
            title: value,
        };
        this.setState({ columns });
    };

    editCellValue = (index, value) => {
        const { months } = this.props;
        const data = this.getDataObject();
        const month = months[index];
        const formattedValue = this.formatInputValue(value);
        if (this.isInputValueInvalid(formattedValue)) return;
        data[month] = formattedValue;
        this.setState({ data });
    };

    toggleDisabledMonths = (month, checked) => {
        const disabledMonths = { ...this.state.disabledMonths };
        disabledMonths[month] = !checked;
        this.setState({ disabledMonths });
    };

    isEditable = (column) => {
        return column.key != 'month' && !column.cumulative;
    };

    getPercentage = (real, target) => {
        if (target == 0) return 0;
        const percentage = Math.round((Number(real) / Number(target)) * 100);
        return percentage;
    };

    getCumulativeValue = (month, column, currentMonth = null, returnNumber = false) => {
        const { months, data, selectedViewMode } = this.props;

        let cumulative = 0;
        let cumulativeTarget = 0;

        months.forEach((m) => {
            const monthMoment = moment(m, this.dateFormat);
            if (monthMoment.isSameOrBefore(moment(month, this.dateFormat), 'month') && (!currentMonth || monthMoment.isSameOrBefore(currentMonth, 'month'))) {
                const cumulativeTo = Number((data[column.cumulative_to] || {})[m] || 0);
                const cumulativeFrom = Number((data[column.cumulative_from] || {})[m] || 0);
                cumulative += cumulativeFrom - cumulativeTo;
                cumulativeTarget += cumulativeTo;
            }
        });

        if (returnNumber) {
            return selectedViewMode == 'currency' ? cumulative : this.getPercentage(cumulative, cumulativeTarget);
        }

        return selectedViewMode == 'currency' ? this.formatCurrency(cumulative) : this.getPercentage(cumulative, cumulativeTarget) + ' %';
    };

    getInputClassForCell = (m, c) => {
        const { editMode, disabledMonths } = this.state;
        const { isTotal } = this.props;
        let string = ['input'];
        if (c.key == 'month') {
            string.push('left');
        } else if (c.cumulative) {
            const val = this.getCumulativeValue(m, c, null, true);
            if (val != 0) {
                string.push(val > 0 ? 'green' : 'red');
            }
        } else if (editMode && this.isEditable(c)) {
            string.push('active');
            if (disabledMonths[m] && !isTotal) string.push('disabled');
        }
        return string.join(' ');
    };

    getCellValue = (value, month, column) => {
        if (column.key == 'month') {
            const monthMoment = moment(month, this.dateFormat);
            const monthName = this.monthNames[monthMoment.month() + 1];
            return `${monthName} ${monthMoment.format('YYYY')}`;
        }
        return column.cumulative ? this.getCumulativeValue(month, column) : this.formatCurrency(value, true);
    };

    renderRows = () => {
        const { months, isTotal } = this.props;
        const { editMode, disabledMonths, columns } = this.state;
        const data = this.getDataObject();
        const currentMonth = moment().startOf('month').format(this.dateFormat);
        return (
            <TableBody>
                {months.map((m, i) => {
                    const value = data[m] || 0;
                    return (
                        <TableRow className={m == currentMonth && 'current'}>
                            {columns.map((c) => (
                                <TableCell className={m == currentMonth && 'current'} align={c.align || 'left'}>
                                    <div className={this.isEditable(c) && 'cell-content'}>
                                        {this.isEditable(c) && !isTotal && editMode && (
                                            <Switch color="primary" checked={!disabledMonths[m]} onChange={(e) => this.toggleDisabledMonths(m, e.target.checked)} className="switch" />
                                        )}
                                        <input
                                            className={this.getInputClassForCell(m, c)}
                                            disabled={!this.isEditable(c) || !editMode || disabledMonths[m]}
                                            style={{ textAlign: c.align || 'left' }}
                                            placeholder="0"
                                            onFocus={(e) => e.target.select()}
                                            onKeyDown={this.handleEnter}
                                            onChange={(e) => this.editCellValue(i, e.target.value)}
                                            value={this.getCellValue(value, m, c)}
                                        />
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    );
                })}
            </TableBody>
        );
    };

    handleEnter = (e) => {
        if (e.key === 'Enter') {
            this.saveChanges();
        }
    };

    getTotalForColumn = (c) => {
        const { months } = this.props;
        if (c.cumulative) {
            return this.getCumulativeValue(months[months.length - 1], c, null, true);
        }
        if (this.props.summaryTable) {
            const dataObj = this.props.data[c.key] || {};
            return Object.values(dataObj).reduce((prev, v) => prev + Number(v), 0);
        }
        const { data } = this.state;
        return Object.values(data).reduce((prev, v) => prev + Number(v), 0);
    };

    getMonthToDateForColumn = (c) => {
        const { months } = this.props;
        const currentMonth = moment();
        if (c.cumulative) {
            return this.getCumulativeValue(months[months.length - 1], c, currentMonth, true);
        }
        if (this.props.summaryTable) {
            const dataObj = this.props.data[c.key] || {};
            return Object.keys(dataObj)
                .filter((month) => moment(month, this.dateFormat).isSameOrBefore(currentMonth, 'month'))
                .reduce((prev, month) => prev + Number(dataObj[month]), 0);
        }

        const { data } = this.state;

        return Object.keys(data)
            .filter((month) => moment(month, this.dateFormat).isSameOrBefore(currentMonth, 'month'))
            .reduce((prev, month) => prev + Number(data[month]), 0);
    };

    renderFooter = () => {
        const { columns } = this.state;
        const { selectedViewMode } = this.props;
        return (
            <TableFooter>
                <TableRow>
                    {columns.map((c) => {
                        const val = c.key == 'month' ? this.tr('Total') : this.getTotalForColumn(c);
                        let className = '';
                        if (c.cumulative && val != 0) {
                            className = val > 0 ? 'green' : 'red';
                        }
                        return (
                            <TableCell className={className} align={c.align || 'left'}>
                                {c.cumulative && selectedViewMode != 'currency' ? val + ' %' : this.formatCurrency(val)}
                            </TableCell>
                        );
                    })}
                </TableRow>
                <TableRow>
                    {columns.map((c) => {
                        const val = c.key == 'month' ? this.tr('Month-to-Date') : this.getMonthToDateForColumn(c);
                        let className = '';
                        if (c.cumulative && val != 0) {
                            className = val > 0 ? 'green' : 'red';
                        }
                        return (
                            <TableCell className={className} align={c.align || 'left'}>
                                {c.cumulative && selectedViewMode != 'currency' ? val + ' %' : this.formatCurrency(val)}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableFooter>
        );
    };

    render() {
        const { loading } = this.state;
        const { isForecast, selectedForecast, noEdit, createTitle, onCreate } = this.props;
        return (
            <div className="account-goals-table">
                {this.renderTopSection()}
                <Table>
                    {this.renderHeader()}
                    {this.renderRows()}
                    {this.renderFooter()}
                    {isForecast && !selectedForecast && <div className="no-forecasts-overlay">
                        <p>{this.tr("No forecasts yet.")}</p>
                        {!noEdit && <Button onClick={onCreate} size="large" color="primary">{createTitle}</Button>}
                    </div>}
                </Table>
                {(loading || this.props.loading) && (
                    <div className="loading-overlay">
                        <img src={require('../dashboard/insights/img/loading.svg').default} />
                    </div>
                )}
            </div>
        );
    }
}

export default withSnackbar(GoalsTable);
