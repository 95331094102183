import React from "react";

import { SettingsContext } from "../../SettingsContext";
import TaimerComponent from "../../TaimerComponent";

import styles from './Keyfigures.module.scss';
import BlockBase from "./BlockBase";
import { BlockProps, SubItemConfig, SubitemsProps } from "../base/CustomViewBase";
import { Button, LinearProgress, Tooltip } from "@mui/material";
import cn from 'classnames';

import { ReactComponent as SelectedIcon } from './img/selected.svg';
import { ReactComponent as UnselectedIcon } from './img/unselected.svg';
import _, { sumBy } from "lodash";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { CheckBox, CheckBoxOutlineBlank, Edit, InfoOutlined } from "@mui/icons-material";
import Slider from "../../general/Slider";

interface KeyfigureBase {
    key: string;
    title: string;
    defaultVisible?: boolean;
    /**
     * Can be visible? (Ie. permission check)
     */
    canBeVisible?: boolean;
    classNameSubfigures?: string;
    tooltipText?: string;
    subfigures?: Subfigure[];
}

interface KeyfigureCurrency extends KeyfigureBase {
    type: 'currency';
    currency: string;
    value: number;
}

interface KeyfigureText extends KeyfigureBase {
    type: 'text';
    text: string;
}

interface KeyfigureNumber extends KeyfigureBase {
    type: 'number';
    value: number;
    postfix?: string;
    decimals?: number;
}

type Keyfigure = KeyfigureCurrency | KeyfigureText | KeyfigureNumber;

interface SubfigureBase {
    key: string;
    title?: string;
    extra?: React.ReactNode;
    className?: string;
    classNameContent?: string;
}

interface SubfigureNumber extends SubfigureBase {
    type: 'number';
    value: number;
    postfix?: string;
    decimals?: number;
}

interface SubfigureProgress extends SubfigureBase {
    type: 'progress';
    value: number;
    postfix?: string;
    color?: string|null;
}

interface ProgressSegment {
    start?: number;
    end?: number;
    value?: number;
    color: string;
    tooltip?: string;
}

interface SubfigureProgressMulti extends SubfigureBase {
    type: 'progress_multi';
    postfix?: string;
    minSize: number;
    segments: ProgressSegment[];
}

interface SubfigureText extends SubfigureBase {
    type: 'text';
    text: string;
}

interface SubfigureCurrency extends SubfigureBase {
    type: 'currency';
    currency: string;
    value: number;
}

interface SubfigureLink extends SubfigureBase {
    type: 'link';
    text: string;
    link: Record<string, string|number>;
}

interface SubfigureAction extends SubfigureBase {
    type: 'action';
    text: string;
    action: (newWindow: boolean) => void;
}

interface SubfigureComponent extends SubfigureBase {
    type: 'component';
}

type Subfigure = SubfigureLink | SubfigureAction | SubfigureNumber | SubfigureText | SubfigureCurrency | SubfigureComponent | SubfigureProgress | SubfigureProgressMulti;

interface Props extends SubitemsProps {
    blockProps: BlockProps;
    figures: Keyfigure[];
}

interface State {
    sliderOpen: boolean;
    config?: Record<string, SubItemConfig>;
}

function SegmentValue(segment: ProgressSegment) {
    if (segment.value !== undefined) {
        return Math.max(0, segment.value);
    } else if (segment.start !== undefined && segment.end !== undefined) {
        return Math.max(0, segment.end - segment.start);
    }

    return 0;
}

class Keyfigures extends TaimerComponent<Props & WithSnackbarProps, State> {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "customview/blocks/Keyfigures");

        this.state = {
            sliderOpen: false,
            config: undefined,
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
    }

    componentDidUpdate() {
        const { } = this.props;
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (
            _.isEqual(nextState, this.state) && 
            _.isEqual(nextProps.blockProps.title, this.props.blockProps.title) && 
            _.isEqual(nextProps.blockProps.canCollapse, this.props.blockProps.canCollapse) && 
            _.isEqual(nextProps.blockProps.collapsed, this.props.blockProps.collapsed) && 
            _.isEqual(nextProps.figures, this.props.figures) && 
            _.isEqual(nextProps.subitems, this.props.subitems))
            return false;

        return true;
    }

    renderFigure(figure: Keyfigure) {
        const { taimerAccount } = this.context;

        let value: React.ReactNode | null = null;

        if (figure.type === 'text') {
            value = figure.text;
        } else if (figure.type === 'currency') {
            const currencyFormatter = new Intl.NumberFormat(
                taimerAccount.numberFormat,
                {
                    style: "currency",
                    currency: figure.currency,
                    currencyDisplay: "symbol",
                }
            );

            value = currencyFormatter.format(figure.value);
        } else if (figure.type === 'number') {
            value = `${figure.value.toFixed(2)} ${figure.postfix ?? ''}`;
        }

        return (
            <div data-testid={`keyfigure-${figure.key}`} key={figure.key} className={styles.figure}>
                <h4 data-testid={`keyfigure-${figure.key}-title`}>{figure.title}</h4>
                <Tooltip title={figure.tooltipText ?? ""}>
                    <div className={styles.value} data-testid={`keyfigure-${figure.key}-value`}>
                        {value}
                        {figure.tooltipText && <InfoOutlined fontSize='small' />}
                    </div>
                </Tooltip>
                <div className={styles.fill} />
                {figure.subfigures && <div className={cn(styles.subfigures, figure.classNameSubfigures)}>
                    {figure.subfigures.map(x => this.renderSubfigure(x))}
                </div>}
            </div>
        );
    }

    clickLink = (e: React.MouseEvent<HTMLAnchorElement>, link: Record<string, string|number>) => {
        const { functions: { updateView } } = this.context;

        !e.ctrlKey && !e.metaKey && e.preventDefault();
        updateView(link, false);
    }

    renderSubfigure = (figure: Subfigure) => {
        const { functions: { urlify }, taimerAccount } = this.context;

        let content: React.ReactNode|null = null;

        if (figure.type === 'text') {
            content = figure.text;
        } else if (figure.type === 'link') {
            content = <a href={urlify(figure.link)} onClick={(e) => this.clickLink(e, figure.link)}>{figure.text}</a>
        } else if (figure.type === 'action') {
            content = <a onClick={(e) => figure.action(e.ctrlKey || e.metaKey || e.button === 1)}>{figure.text}</a>
        } else if (figure.type === 'currency') {
            const currencyFormatter = new Intl.NumberFormat(
                taimerAccount.numberFormat,
                {
                    style: "currency",
                    currency: figure.currency,
                    currencyDisplay: "symbol",
                }
            );

            content = currencyFormatter.format(figure.value);
        } else if (figure.type === 'number') {
            content = `${figure.value.toFixed(2)} ${figure.postfix ?? ''}`;
        } else if (figure.type === 'progress') {
            content = <div className={styles.progress}><div className={styles.inner} style={{backgroundColor: figure.color ? figure.color : undefined, width: `${Math.min(100, Math.max(0, figure.value))}%`}} /></div>
        } else if (figure.type === 'progress_multi') {
            const totalSegmentsValue = sumBy(figure.segments, SegmentValue);
            const size = Math.max(figure.minSize, totalSegmentsValue);

            content = <div className={styles.progress}>
                {figure.segments.map((x, i) => <div key={i} title={x.tooltip} style={{backgroundColor: x.color, width: `${(SegmentValue(x) / size * 100).toFixed(2)}%`}} />)}
            </div>
        }

        return (
            <div data-testid={`keyfigure-sub-${figure.key}`} key={figure.key} className={figure.className}>
                {figure.title && <h5>{figure.title}</h5>}
                <div className={cn(styles.subvalue, styles[`subfigure_${figure.type}`])} data-testid={`keyfigure-sub-${figure.key}-value`}>
                    {content !== null && <div className={cn(styles.content, figure.classNameContent)}>{content}</div>}
                    {figure.extra}
                </div>
            </div>
        )
    }

    onClickEdit = () => {
        const { subitems } = this.props;

        this.setState({
            sliderOpen: true,
            config: subitems,
        });
    }

    onSliderCancel = () => {
        this.setState({
            sliderOpen: false,
        });
    }

    getNewSelected = (): Keyfigure[] => {
        const { figures } = this.props;
        const { config } = this.state;

        if (!config) {
            return [];
        }

        const visible = figures.filter(f => config[f.key]?.visible ?? f.defaultVisible ?? true);

        return visible;
    }

    sliderSave = () => {
        const { onConfigure, blockProps: { viewBlock }, enqueueSnackbar, figures } = this.props;
        const { config } = this.state;

        if (!config) {
            return;
        }

        const visible = figures.filter(f => config[f.key]?.visible ?? f.defaultVisible ?? true);

        if (visible.length === 0) {
            enqueueSnackbar(this.tr("Must choose at least one item to show"), {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
            });

            return;
        }

        onConfigure(viewBlock, config);

        this.setState({
            sliderOpen: false,
        });
    }

    onToggleSubItem = (item: Keyfigure) => {
        const { config } = this.state;

        if (!config) {
            return;
        }

        const currentConfig = config[item.key] ?? {};
        const isVisible = currentConfig.visible ?? item.defaultVisible ?? true;

        this.setState({
            config: {
                ...config,
                [item.key]: {
                    ...currentConfig,
                    visible: !isVisible,
                }
            }
        })

    }

    renderSliderFigure = (figure: Keyfigure, config: SubItemConfig) => {
        const isVisible = config.visible ?? figure.defaultVisible ?? true;

        const Icon = isVisible ? CheckBox : CheckBoxOutlineBlank;

        return (
            <div data-testid={`keyfigure-${figure.key}-toggle`} className={cn(styles.editFigure, isVisible && styles.selected)} onClick={this.onToggleSubItem.bind(this, figure)}>
                <div className={styles.iconContainer}>
                    <Icon className={cn(styles.icon, isVisible && styles.selected)} />
                </div>
                {this.renderFigure(figure)}
            </div>
        );
    }

    render() {
        const { blockProps, figures, subitems } = this.props;
        const { sliderOpen, config } = this.state;

        const newSelected = this.getNewSelected();

        return (
            <BlockBase {...blockProps} topbarClassName={styles.topbar} topbarComponent={<><Button onClick={this.onClickEdit} variant="text" data-testid={`keyfigure-slider-edit-btn`}><Edit />{this.tr('Edit content')}</Button></>}>
                <Slider data-testid={`keyfigure-slider`} title={this.tr('Edit content')} open={sliderOpen} anchor="right" onBackdropClick={this.onSliderCancel} onClose={this.onSliderCancel}>
                    <div className={styles.editSlider}>
                        <h2>{this.tr('Choose Displayed Data')}</h2>
                        <p>{this.tr('Select which data boxes to show in current box')}</p>

                        <div className={styles.content}>
                            {sliderOpen && config && <div className={styles.sliderFigures}>
                                {figures.filter(x => x.canBeVisible ?? true).map(figure => this.renderSliderFigure(figure, config[figure.key] ?? {}))}
                            </div>}

                            <div className={styles.controls}>
                                <Button size="large" onClick={this.onSliderCancel} variant="text" data-testid={`keyfigure-slider-cancel`}>{this.tr('Cancel')}</Button>

                                <Tooltip title={newSelected.length === 0 ? this.tr('Must select at least one data to show') : ""}>
                                    <span>
                                        <Button size="large" classes={{disabled: styles.disabled}} onClick={this.sliderSave} disabled={newSelected.length === 0} data-testid={`keyfigure-slider-save`}>{this.tr('Save')}</Button>
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </Slider>
                <div className={styles.root}>
                    {figures.filter(f => (f.canBeVisible ?? true) && (subitems[f.key]?.visible ?? f.defaultVisible ?? true)).map(x => this.renderFigure(x))}
                </div>
            </BlockBase>
        );
    }
}  

export default withSnackbar(Keyfigures);