import React from 'react';

/* css */
import './InsightHoursByUser.css';

/* list */
import List from "../../list/List";
import ListRow from "../../list/ListRow";
import ListCell from '../../list/ListCell';
import TextInputCell from "../../list/cells/TextInputCell";
import LinkListCell from "../../list/LinkListCell";
import CurrencyListCell from '../../list/CurrencyListCell';
import NumberListCell from "../../list/cells/NumberListCell";
import PercentageListCell from "../../list/PercentageListCell";

import { ArrowUpward as ArrowDropUp, ArrowDownward as ArrowDropDown, Remove as Dash } from '@mui/icons-material'

/* others */
import _ from 'lodash';
import ContentBlock from '../../general/ContentBlock';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../../src/SettingsContext';
import { Tabs, Tab, Avatar } from '@mui/material';
import TaimerAvatar from '../../general/TaimerAvatar';
import InsightSlider from './InsightSlider';
import DataHandler from '../../general/DataHandler';

class InsightHoursByUserTableListRow extends ListRow {
    constructor(props, context) {
        super(props, {}, {}, "dashboard/insights/InsightHoursByUserTableListRow");

        this.refProject = React.createRef();
    }

    // TODO: abstract
    defineClassName() {
        return "insightHoursTableListRow";
    }

    defineCells() {
        const { data } = this.state;
        const { sharedData: { currency } } = this.props;

        const { taimerAccount, functions: { presentCurrency } } = this.context;

        const formatHours = (h) => {
            if (!h) return '–';

            return `${h.toFixed(2)} ${this.tr('h')}`;
        }

        const usernameDisplayed = data.users_company < 1 ? `${data.users_name} (${this.tr("freelancer")})` : data.users_name;

        let cells = {
            user:
                <ListCell name="user" onlyDisplay editable={false}>
                    <div className="UserAvatarCell">
                        <TaimerAvatar
                            id={data.users_id}
                            name={data.users_name}
                            color={data.users_color}
                        />

                        <div className="usertitle">
                            <div className="name">{usernameDisplayed || '–'}</div>
                            {data.users_title && <div className="title">{data.users_title}</div>}
                        </div>
                    </div>
                </ListCell>,
            available:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(data.available)} />,
            tracked:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(data.tracked)} />,
            nonbillable:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(data.nonbilled)} />,
            billable:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(data.billed)} />,
            hourly_cost:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={presentCurrency(data.hourly_cost)} />,
            billable_amount:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={presentCurrency(data.billable_amount)} />,
            margin:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={presentCurrency(data.billable_amount - data.hourly_cost)} />,
            invoiced:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(data.invoiced_hours)} />,
            invoiced_amount:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={presentCurrency(data.invoiced_amount)} />,
            rate:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={presentCurrency(data.tracked ? (data.invoiced_amount - data.hourly_cost) / data.tracked : 0)} />,
        };

        return cells;
    }
}

class InsightHoursByUserTableListSummaryRow extends ListRow {
    constructor(props, context) {
        super(props, {}, {}, "dashboard/insights/InsightHoursByUserTableSummaryRow");

        // this.refProject = React.createRef();
    }

    // TODO: abstract
    defineClassName() {
        return "insightHoursTableListSummaryRow";
    }

    defineCells() {
        const { rows, sharedData: { currency } } = this.props;

        const { taimerAccount, functions: { presentCurrency } } = this.context;

        const formatHours = (h) => {
            if (!h) return '–';

            return `${h.toFixed(2)} ${this.tr('h')}`;
        }

        let sumAvailable = 0;
        let sumTracked = 0;
        let sumNonBillable = 0;
        let sumBillable = 0;
        let sumHourlyCost = 0;
        let sumBillableAmount = 0;
        let sumInvoiced = 0;
        let sumInvoicedAmount = 0;

        rows.map(row => {
            sumAvailable += row.data.available;
            sumTracked += row.data.tracked;
            sumNonBillable += row.data.nonbilled;
            sumBillable += row.data.billed;
            sumHourlyCost += row.data.hourly_cost;
            sumBillableAmount += row.data.billable_amount;
            sumInvoiced += row.data.invoiced_hours;
            sumInvoicedAmount += row.data.invoiced_amount;
        })

        let cells = {
            user:
                <ListCell className="totalcell" name="user" onlyDisplay editable={false} alignCell>
                    {this.tr("Total")}
                </ListCell>,
            available:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(sumAvailable)} />,
            tracked:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(sumTracked)} />,
            nonbillable:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(sumNonBillable)} />,
            billable:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(sumBillable)} />,
            hourly_cost:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={presentCurrency(sumHourlyCost)} />,
            billable_amount:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={presentCurrency(sumBillableAmount)} />,
            margin:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={presentCurrency(sumBillableAmount - sumHourlyCost)} />,
            invoiced:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(sumInvoiced)} />,
            invoiced_amount:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={presentCurrency(sumInvoicedAmount)} />,
            rate:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={presentCurrency(sumTracked != 0 ? (sumInvoicedAmount - sumHourlyCost) / sumTracked : 0)} />,
        };

        return cells;
    }

    render() {
        const cells = this.defineCells();
        const className = ["summaryRow listElement row flex", this.defineClassName()].join(" ");

        return (
            <div className={className} style={{ height: `${InsightHoursByUserTableListSummaryRow.rowHeight}px` }}>
                {this.props.columnOrder.map(columnName => React.cloneElement(cells[columnName], {
                    width: this.props.columnConfig[columnName].width
                }))}
            </div>
        );
    }
}

class InsightHoursByUser extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        width: 3,
        visible: true,
        sliderTitleColumn: "name",
    }

    constructor(props, context) {
        super(props, context, "dashboard/insights/InsightHoursByUser");

        this.state = {
            currentTab: props.defaultTab,
            sliderId: 0,
            sliderOpen: false,
            sliderTitle: null,
        }
    }

    handleTabChange = (event, value) => {
        this.setState({ currentTab: value });
    }

    onClickRow = async (row) => {
        const { fetchUserSlider } = this.props;

        const sliderData = await fetchUserSlider(row.id);
        this.setState({ sliderTitle: row.users_name, sliderData, sliderOpen: true });
    }

    onCloseSlider = () => {
        this.setState({ sliderOpen: false });
    }

    render() {
        const { title, className, data, table, tabs, extraCols, height, style, sliderTitleColumn, sliderColumns, currency } = this.props;
        const { currentTab, sliderTitle, sliderOpen, sliderData } = this.state;
        const { tr } = this;

        const cols = [
            { field: "user", name: "user", header: tr("User"), width: 300, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "available", name: "available", header: tr("Available"), width: 100, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "tracked", name: "tracked", header: tr("Tracked"), width: 100, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "nonbillable", name: "nonbillable", header: tr("Non-Billable"), width: 100, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "billable", name: "billable", header: tr("Billable"), width: 100, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "hourly_cost", name: "hourly_cost", header: tr("Hourly Cost"), width: 100, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "billable_amount", name: "billable_amount", header: tr("Billable Amount"), width: 100, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "margin", name: "margin", header: tr("Tot. Margin"), width: 100, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "invoiced", name: "invoiced", header: tr("Invoiced h"), width: 100, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "invoiced_amount", name: "invoiced_amount", header: tr("Invoiced Amount"), width: 100, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "rate", name: "rate", header: tr("Ave. h rate"), width: 100, resizeable: false, moveable: false, hideable: false, showMenu: false },
        ];

        return (
            <div className={className} style={style}>
                <InsightSlider
                    open={sliderOpen}
                    label={sliderTitle}
                    onClose={this.onCloseSlider}
                    data={sliderData ? sliderData : []}
                    // sum={sliderData ? sliderData.sum : 0}
                    table={table}
                    columns={sliderColumns}
                    currency={currency}
                    parent={sliderData}
                    columns={['account', 'project', 'hourly_cost', 'invoiced']}
                />

                <ContentBlock
                    filterComponents={tabs && <Tabs key="tabs" color="primary" indicatorColor="primary" value={currentTab} onChange={this.handleTabChange}>
                        {_.map(tabs, (tab, i) => <Tab key={i} value={i} label={tab} />)}
                    </Tabs>}
                    buttonTitle={title} noPadding wrapperClass="insight-hours-by-user-container">
                    {data && <div className="table">
                        <List
                            fluid
                            height={height}
                            rowHeight={44}
                            rowKey="key"
                            data={data}
                            columns={cols}
                            listRowType={InsightHoursByUserTableListRow}
                            summaryRowType={InsightHoursByUserTableListSummaryRow}
                            rowProps={{
                                onClickRow: this.onClickRow
                            }}
                            sharedData={{
                                currency,
                            }}
                        />
                    </div>}
                </ContentBlock>
            </div>
        );
    }
}

export default InsightHoursByUser;