import React from 'react';
import { 
    Table, 
    TableCell, 
    TableRow, 
} from '@mui/material';
import styles from "./SimpleTable.module.scss";

type Row = { [key: string]: string | number | JSX.Element };

interface SimpleTableProps {
    rows: Row[];
    columnStyles?: { [key: string]: { [key: string]: string } };
}

class SimpleTable extends React.Component<SimpleTableProps> {
    static defaultProps = {
        identifierField: "simpleTableRowId"
    };

    render() {
        const { 
            rows 
        }: {
            rows: Row[]
        } = this.props;

        if(!rows || rows.length === 0) {
            return null;
        }

        const keys: string[] = Object.keys(rows[0]);

        return (
            <Table className={styles.simpleTable}>
                {this.props.rows?.map((row: Row) => {
                    return (
                        <TableRow>
                            {keys.map((k: string) => (
                                <TableCell style={this.props.columnStyles?.[k] ?? {}}>
                                    {row[k]}
                                </TableCell>
                            ))}
                        </TableRow>
                    );
                })}
            </Table>
        )
    }
}

export default SimpleTable;
