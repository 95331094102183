/* css */
import './TabActivities.css';

import React from 'react';
import _ from 'lodash';
import isEqual from "lodash/isEqual";
import { startOfWeek, endOfWeek } from "date-fns";
import moment from "moment";

import DataHandler from '../general/DataHandler';
import ActivitiesList from '../dashboard/insights/activities/ActivitiesInsightList';
import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from './../SettingsContext';
import { getActivityTypes } from '../Data';
import AdvancedSearch from "./../search/AdvancedSearch";
import InsightDropDown from "./../dashboard/insights/InsightDropDown";
import Utils from "./Utils";

import MultiSelect from './MultiSelect';

import { CheckBox } from "@mui/icons-material";
import PageTopSection from './PageTopSection';

/**
 * Activities tab for project and account card
 * 
 */
class TabActivities extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "general/TabActivities");
        const dateRange = {start: null, end: null, key: "selection"};
        this.state = {
            dialogData: false,
            showOverlay: false,
            dateRange: {
                startDate: dateRange.start,
                endDate: dateRange.end,
                valid: true,
                key: "selection"
            },
            filterData: {
                type:[],
                status: []
            },
            pageData: {
                page: 1,
                perpage: 30,
                pageCount: 1,
                activitiesCount: 0,
                sortby: "created",
                sortasc: false,
            },
            cleanActivityTypes: [],
            scheduleStatus: 'all',
            typeStatuses: ['project', 'account'],
            selectedType: props.module == "customers" ? "account" : "project",
            stickySearchInitialized: false,
            haswritepermission: undefined,
            activities: []
        };

		this.stickySearchKey = "tab_activities_list"
        this.searchTerms = undefined;
        this.autoCompleteData = [];

        this.statuses = [
            {value: '1', label: this.tr("Done")},
            {value: '2', label: this.tr("Due")},
            {value: '3', label: this.tr("Overdue")}
        ]

        this.scheduledFilters = [
            {key: "all", label: this.tr("All")},
            {key: "this_week", label: this.tr("Activity Date This Week")},
            {key: "today", label: this.tr("Activity Date Today")},
            {key: "due_this_week", label: this.tr("Due This Week")},
            {key: "due_today", label: this.tr("Due Today")},
            {key: "overdue", label: this.tr("Overdue")},
        ];
        this.scheduledFilters = this.scheduledFilters.map(s => {
            s = {...s, action: () => this.setState(state => {
                        state.scheduleStatus = s.key;
                        if (s.key == 'all') {
                            state.filterData = {type: state.filterData.type};
                            if(this.props.module === "customers") {
                                state.filterData.account = [this.props.account.id];
                            } else {
                                state.filterData.account = [this.props.project.customers_id];
                                state.filterData.project = [this.props.project.id];
                            }                            
                        }
                        else
                            state.filterData = {...state.filterData, ...this.createScheduleStatusFilters(s.key)};
                        return state;
                        }, () => { this.resetListAndGetActivities(); 
                })};
            return s;
        });

        this.filtersInitialValues = {
            scheduleStatus: 'all',
            typeStatuses: ['project', 'account'],
        };

        this.initialPageData = {
            page: 1,
            perpage: 30,
            sortby: "created",
            sortasc: false,       
        }
        
        this.viewOptions = [
            {key: "project", label: this.tr("Project"), name: this.tr("Project")},
            {key: "account", label: this.tr("Account"), name: this.tr("Account")}
        ];

        this.advancedSearchFields = [
            { field: "description", transl: this.tr("Description")},
            { field: "contacts", transl: this.tr("Contact")},
            { field: "targeted_to", transl: this.tr("Targeted to")},
            { field: "created_by", transl: this.tr("Created by")},
        ];

        if(this.props.module === "customers") {
            this.advancedSearchFields = [...this.advancedSearchFields, {field: "project", transl: this.tr("Project")}];
        }

        this.ActivitiesInsightList = React.createRef()
        this.advancedSearch  = React.createRef();
		this.typeSelect = React.createRef();
		this.statusSelect = React.createRef();

        this.newIcons = Utils.importAllIcons(require.context('./../settings/img/Activity_icons/', false, /\.(png|jpe?g|svg)$/));

        this.filtersAreInInitialState = this.filtersAreInInitialState.bind(this);
        this.initializeStickySearch   = this.initializeStickySearch.bind(this);
        this.saveStickySearch         = this.saveStickySearch.bind(this);
	}
    
    componentDidMount() {
        super.componentDidMount();
        this.getAutocompleteData();
        this.initializeStickySearch();
        this.listenReset();

        window.addEventListener("activitySaved", () => this.getActivityData(false, true));
    }

    componentDidUpdate(prevProps, prevState) {
        if( prevProps.company !== this.props.company) {
            this.getActivityData();
            this.getAutocompleteData();
        }
    }

    componentWillUnmount() {
		super.componentWillUnmount();
        this.unListenReset();
        window.removeEventListener("activitySaved", () => this.getActivityData(false, true));
    }

    listenReset = () => {
		document.body.addEventListener("keyup", this._resetFilters);
	}

	unListenReset = () => {
		document.body.removeEventListener("keyup", this._resetFilters);
	}

    getAutocompleteData = () => {
        DataHandler.get({url: 'activities/autocompletedata', company: this.props.company, module: this.props.module}).done(data => {
            data.users.forEach(u => {if (u.company < 1) u.label += ` (${this.tr('Freelancer')})`});
            this.autoCompleteData = data;

            if(this.props.module === "customers") {
                DataHandler.get({url: `accounts/projects/${this.props.account.id}/${this.props.company}`}).done(data => {
                    this.autoCompleteData.project = data;
                }).fail(response => {});
            }
        }).fail(response => {});
    }

    initializeStickySearch() {
        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` }).done((response, _, request) => {
            if(request.status !== 200) {
				this.getActivityData(true);
                return;            
			}
			
			if (response.searchTerms)
				this.searchTerms = response.searchTerms;
            if (response.viewOptions)
                this.viewOptions = response.viewOptions;

            // Delete projectsearch if in project card
            if(this.props.module !== "customers" && this.searchTerms && this.searchTerms.currentFilters) {
                const index = this.searchTerms.currentFilters.map(e => e.name ).indexOf('project');
                delete this.searchTerms.advanced_search_criteria.filters.project;
                if (index > -1)
                    delete this.searchTerms.currentFilters[index];

                if (Object.keys(this.searchTerms.advanced_search_criteria.filters).length === 0)
                    this.searchTerms.mode = "freetext";
            }

            response.pageData = {...this.state.pageData, ...response.pageData};

            this.setState({ ...response }, () => this.getActivityData(true));
        }).fail(response => {
			this.getActivityData(true);
        });
    }


    saveStickySearch() {
        const stateToSave = {};
        stateToSave.filterData = _.cloneDeep(this.state.filterData);
        stateToSave.pageData = _.cloneDeep(this.state.pageData);
        stateToSave.searchTerms = this.searchTerms;
        stateToSave.viewOptions = this.viewOptions;

        ["pageCount", "activitiesCount", "page"].forEach(e => {
            delete stateToSave.pageData[e];
        });

        ["scheduleStatus", "typeStatuses"].forEach(e => {
            stateToSave[e] = this.state[e];
        });
    
        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}`, }, { search: stateToSave });
    }

    filtersAreInInitialState() {
        const initial = _.cloneDeep(this.filtersInitialValues);

        if (this.state.filterData.type && this.state.filterData.type.length > 0 && this.state.filterData.type[0] !== "0")
            return false;
        if (this.state.filterData.status && this.state.filterData.status.length > 0 && this.state.filterData.status[0] !== "0")
            return false;

        const filters = {};
        for(const key in initial) {
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = JSON.stringify(this.state[key]);
        }

        const freetext = this.searchTerms ? this.searchTerms.freetextSearchTerm : "";

        return _.isEqual(initial, filters) && !freetext;
    }

	_resetFilters = (evt) => {
		if (!evt || evt.keyCode == '27') {
			this.advancedSearch.current.clearSearch(evt, true);
            this.advancedSearch.current.clearSearchTextInput();
            this.searchTerms = undefined;
			this.setState({
                ...this.filtersInitialValues,
                filterData: {type: [], status: []},
                pageData: this.initialPageData
			}, () => this.resetMultiselects());
		}
    }
    
    resetMultiselects = () => {
        this.typeSelect.current.handleChangeMulti([]);
        this.statusSelect.current.handleChangeMulti([]);
        this.getActivityData();
    }

    /**
     * Gets activities with filter and page data
     *
     */
    async getActivityData(stickySearch = false, updateViewData = false) {
        const defaultNameField = Utils.getNameFieldByUserLang(this.context.userObject.language, 'name');

        let filterData = {...this.state.filterData};
        filterData.company = this.props.company;

        if(this.props.module === "customers") {
                filterData = {...filterData, account: [this.props.id], project: {}};
        } else {
            filterData = {...filterData, account: [this.props.project.customers_id], project: [this.props.project.id]};
        }

        let activityTypes = await getActivityTypes(this.props.company ? this.props.company : this.context.userObject.companies_id);
        activityTypes     = _.sortBy(activityTypes, x => x[defaultNameField].toUpperCase() || x.name.toUpperCase());
        
        const cleanActivityTypes = [];
        activityTypes.forEach((item) => {
            cleanActivityTypes.push({label: item.name, value: item.id});
        });

        this.setState({cleanActivityTypes: cleanActivityTypes, filterData: filterData});

        if (!stickySearch)
            this.saveStickySearch();

        DataHandler.post({url: `activities/activities`}, {onlyListData: true, ...filterData, types: this.state.typeStatuses, ...this.state.pageData, advancedSearchTerms: this.searchTerms}).done(response => {
            //Icon data to listrows
            if(response) {
                const newactivities = response.listActivities;
                newactivities.map((row, i) => {
                    let newitem = { icon: "" };
                    try {
                        activityTypes.map((item, i) => {
                            if(item.id === row.type_id) {
                                newitem = {icon: item.icon};
                                if (!item.old_icon_set)
                                    newitem.icon = this.newIcons[item.icon]                                
                            }
                        })
                    } catch(err) {
                        newitem = { icon: "" };
                    }
                    const newrow = {...row, ...newitem, key: row.projects_id ? "p" + row.id : "c" + row.id, 
                        created_by: row.created_by && row.created_by_company < 1 ? ` ${row.created_by} (${this.tr("freelancer")})` : row.created_by,
                        targeted_to: row.targeted_to && row.targeted_to_company < 1 ? ` ${row.targeted_to} (${this.tr("freelancer")})` : row.targeted_to,
                    };
                    newactivities[i] = newrow;
                })

                //Activities count information
                const pageData = this.state.pageData;
                pageData.activitiesCount = response.activitiesCount;
                pageData.pageCount = response.pageCount;

                this.setState({ activities: newactivities, pageData, showOverlay: response.showOverlay, haswritepermission: response.haswritepermission, stickySearchInitialized: true });
            }

            if (updateViewData) {
                this.props.module == "projects" && this.props.updateProjectData && this.props.updateProjectData();
                this.props.module == "customers" && this.props.updateAccountData && this.props.updateAccountData();
            }

        }).fail(response => {

        });
    }

    createScheduleStatusFilters = (mode) => {
        let startDate, endDate, dueStartDate, dueEndDate;
        let status = [1, 2, 3];
        const today = new Date();
        const options = { weekStartsOn: this.context.calendar.startOfWeek };
        switch (mode) {
            case "today":
                startDate = moment(today).format("YYYY-MM-DD");
                endDate = moment(today).format("YYYY-MM-DD");
            break;
            case "due_today":
                dueStartDate = moment(today).format("YYYY-MM-DD");
                dueEndDate = moment(today).format("YYYY-MM-DD");
                status = [2, 3];
            break;
            case "this_week":
                startDate = moment(startOfWeek(today, options)).format("YYYY-MM-DD");
                endDate = moment(endOfWeek(today, options)).format("YYYY-MM-DD");
            break;
            case "due_this_week":
                dueStartDate = moment(startOfWeek(today, options)).format("YYYY-MM-DD");
                dueEndDate = moment(endOfWeek(today, options)).format("YYYY-MM-DD");
                status = [2, 3];
            break;
            case "overdue":
                status = [3];
            break;
            default:
            break;
        }
        return { startDate, endDate, dueStartDate, dueEndDate, status };
    };    

    resetListAndGetActivities() {
        this.setState(state => {
            state.pageData = {...state.pageData, page: 1};
            return state;
        });
        this.getActivityData();
    }
    
    render () {
        if(!this.state.stickySearchInitialized) {
            return null;
		}
        
        const { pageData, activities, filteredActivities, filterData } = this.state;
        let accountId = undefined;
        let projectId = undefined;

        if(this.props.account && this.props.account.id) {
            accountId = this.props.account.id;
        } else {
            if(this.props.project && this.props.project.customers_id && this.props.project.id) {
                accountId = this.props.project.customers_id;
                projectId = this.props.project.id;
            }
        }

        return (
            <div id="tab-activities" >
                {/* <div  className="tab-activities-column-top">
                    <h1>{this.tr("Activities")}</h1>
                </div> */}
                 {this.props.header && (
                    <PageTopSection
                        header={this.props.header}
                        subheaders={this.props.subheaders}
                    />
                )}
                <div className="listControlsContainer">
                   <div className="header-container">
                        <div className="button-container">
                            <MultiSelect 
                            	ref={this.typeSelect}
                                className="type"
                                width="200px"
                                options={this.state.cleanActivityTypes}
                                skipInitialChange={true}
                                defaultValue={this.state.filterData.type ? this.state.cleanActivityTypes.filter(e => this.state.filterData.type.includes(e.value)): []}
                                label={this.tr("Type")}
                                onChange={(data) => {
                                    this.setState(state => {
                                        filterData.type = data.map(d => d.value);
                                        return state;
                                    });
                                    this.resetListAndGetActivities();
                                }}
                            />
                            <MultiSelect 
                                ref={this.statusSelect}
                                className="status"
                                width="200px"
                                options={this.statuses}
                                defaultValue={this.state.filterData.status ? this.statuses.filter(e => this.state.filterData.status.includes(e.value)) : []}
                                skipInitialChange={true}
                                label={this.tr("Status")}
                                onChange={(data) => {
                                    this.setState(state => {
                                        filterData.status = data.map(d => d.value);
                                        return state;
                                    });
                                    this.resetListAndGetActivities();
                                }}
                            />

                            <AdvancedSearch
                                ref={this.advancedSearch}
                                mode={this.searchTerms && this.searchTerms.mode ? this.searchTerms.mode : undefined}
						        initialFilters={this.searchTerms ? this.searchTerms.currentFilters : undefined}
						        mainConfig={this.searchTerms && this.searchTerms.advanced_search_criteria ? { operator: this.searchTerms.advanced_search_criteria.operator } : undefined} 
						        freetextLabel={this.searchTerms ? this.searchTerms.freetextSearchTerm : ""}
						        alwaysShowClearFilters={!this.filtersAreInInitialState()}
                                onClearSearch={this._resetFilters}
                                fields={this.advancedSearchFields}
                                noRequests={true}
                                onSearchTrigger={(searchTerms) => {
                                    if(isEqual(searchTerms, this.searchTerms))
                                        return;
                                    this.searchTerms = searchTerms;
                                    this.resetListAndGetActivities();
                                }}
                                autoCompleteData={{...this.autoCompleteData}}
                                perpage={pageData.perpage} />                            
                        </div>
                        <div className="right-container" >
                            <InsightDropDown
                              title={this.tr("Scheduled")}
                              color="#e3f4ff"
                              titleColor="#2d9ff7"
                              tabs={this.scheduledFilters}
                              selected={this.state.scheduleStatus}
                            />
                            <InsightDropDown
                                tabs={this.viewOptions}
                                titleLabel={this.state.typeStatuses?.length == 2 && this.tr("All")}
                                selected={this.state.typeStatuses?.length == 2 ? this.state.selectedType : this.state.typeStatuses[0]}
                                title={this.tr("View activities")}
                                customComponent={(this.viewOptions || []).map((o) => {
                                    return (
                                        <div
                                            key={o.key}
                                            onClick={() => this.setState(state => {
                                                if (state.typeStatuses.includes(o.key)) { 
                                                    state.typeStatuses = state.typeStatuses.filter(ts => ts != o.key);
                                                    if (state.typeStatuses.length < 1)
                                                        state.typeStatuses.push(this.viewOptions.find(m => m.key != o.key).key);
                                                }
                                                else
                                                    state.typeStatuses.push(o.key);
                                                if (state.typeStatuses.includes('project') && state.typeStatuses.includes('account'))
                                                    this.viewOptions = this.viewOptions.map(v => {v.label = this.tr("All"); return v;});
                                                else {
                                                    this.viewOptions = this.viewOptions.map(v => {v.label = v.name; return v;});  
                                                }
                                                return state;
                                            }, () => this.resetListAndGetActivities() )}
                                            className="column-options-dropdown-item">
                                            <div className="check-box">
                                                {(this.state.typeStatuses.includes(o.key)) && (
                                                <CheckBox className="icon" />
                                                )}
                                            </div>
                                          {o.name}
                                        </div>
                                    );
                                })}
                            />                            
                        </div>
                    </div>
                </div>
                        

                <div id="insights-activities-list-main" className="tab-activities-column-bottom">
                    <ActivitiesList 
                        company={this.props.company}
                        ref={ this.ActivitiesInsightList }
                        pageData={ pageData }
                        updateProjectData={this.props.updateProjectData}
                        updateAccountData={this.props.updateAccountData}
                        activities={ filteredActivities || activities }
                        showPageSelector={ true }
                        onListSettingsChange={(pageData) => this.setState({pageData: {...this.state.pageData, ...pageData }}, () => this.getActivityData())} 
                        getActivityData = {() => {
                            setTimeout(() => {
                                this.getActivityData();      
                            }, 1001); 
                        }}
                        accountId={ accountId }
                        projectId={ projectId }
                        height="fitRemaining"
                        trimHeight={-20}
                        haswritepermission = {this.state.haswritepermission}
                        module={this.props.module}
                    />
                </div>
            </div>
        );
    }
}

export default TabActivities;
