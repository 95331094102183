import React from "react";
import TaimerCalendarSidebarBody from "./TaimerCalendarSidebarBody";
import TaimerCalendarSidebarHeader from "./TaimerCalendarSidebarHeader";


export default function TaimerCalendarSidebar(props) {
    return (
        <div className="TaimerCalendarSidebar">
            <TaimerCalendarSidebarHeader {...props} />
            <TaimerCalendarSidebarBody {...props} />
        </div>
    );
}