import React from 'react';
import cn from 'classnames';
import { Tooltip } from '@mui/material';
import "./ProgressBar.css";

// TODO: disabled coloring, etc.

class ProgressBar extends React.Component {
    static defaultProps = {
        activeIndex: 0,
        colorActiveOnly: false,
        disabled: false,
        disabledColor: "#f7548f",
        disableColorAll: true,
        items: [], // { }
        onClick: () => {},
        tooltip: undefined,
        value: undefined,
        style: {}
    };


    constructor(props) {
        super(props);

        this.color = props.colorActiveOnly ? (i, active) => i === active : (i, active) => i <= active;
    }


    render() {
        const Wrapper      = this.props.tooltip !== undefined ? Tooltip : React.Fragment;
        const wrapperProps = this.props.tooltip !== undefined ? { title: this.props.tooltip } : {};
        const activeIndex  = this.props.items.length > 0 && this.props.items[0].hasOwnProperty("id") ? this.props.items.findIndex(i => i.id == this.props.value) : this.props.value;

        return (
            <Wrapper {...wrapperProps} style={{...this.props.style}}> 
                <div data-testid="progress-bar" className={cn('progress-bar', this.props.disabled && "")}>
                    {this.props.items.map((row, i) => {
                        const color            = !this.props.disabled ? row.color : this.props.disabledColor;
                        const portionClassName = ["portion", this.color(i, activeIndex) ? "active" : ""].join(" ");
                        const style            = this.color(i, activeIndex) ? { 
                            backgroundColor: color, 
                            borderColor: i < this.props.items.length - 1 ? color : undefined
                        } : {};

                        return (
                            <div
                                data-testid={`progress-bar-option_${row.hasOwnProperty("id") ? row.id : i}`}
                                style={style}
                                key={row.hasOwnProperty("id") ? row.id : i} 
                                className={portionClassName} 
                                onClick={e => {
                                    this.props.onClick(row);
                                }}>
                                <div className={row.subtitle === undefined ? "only" : ""}>{row.title + String.fromCharCode(160) /* nbsp */}</div>
                                {row.hasOwnProperty("subtitle") && row.subtitle !== undefined ? row.subtitle : String.fromCharCode(160)}
                            </div>
                        );
                    })}
                </div>
            </Wrapper>
        );
    }
}

export default ProgressBar;
