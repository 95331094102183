import React from 'react';
import TaimerComponent from "../../TaimerComponent"

/* material ui */
import { Button } from '@mui/material';

import Taimer from "../../Taimer"
import { SettingsContext } from '../../SettingsContext';

import { ReactComponent as OverlayImage } from './ProjectList.svg';

class ProjectListOverlay extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'list/overlays/ProjectListOverlay');
    }

    render () {
        const evt = {button: 0}; // fake event for projectview addProject funtion
        return (
            <div className="list-overlay">
                <OverlayImage style={{width: "260px", height: 'auto'}} />
                <div className="text">
                    {this.tr("Add your first Lead to Start Selling.") }
                </div>
                <Button
                    color="primary"
                    onClick={() => this.props.addProject(evt)}
                >
                {this.tr("ADD LEAD") }
                </Button>
             </div>
        );
    }

}

export default ProjectListOverlay;