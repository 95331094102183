import { KeyboardArrowRight } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import TaimerComponent from '../TaimerComponent';
import styles from './CustomizeAppearanceView.module.scss';

class CustomizeAppearanceView extends TaimerComponent {
    themes = [
        {
            id: 'heeros',
            name: 'Blue',
            color: '#003A78',
        },
        {
            id: 'teal',
            name: 'Teal',
            color: '#00b8e4',
        },
        {
            id: 'pink',
            name: 'Pink',
            color: '#ec008a',
        },
        {
            id: 'purple',
            name: 'Purple',
            color: '#6f42c1',
        },
        {
            id: 'green',
            name: 'Green',
            color: '#50E3C2',
        },
        {
            id: 'yellow',
            name: 'Yellow',
            color: '#FCD638',
        },
    ];
    state = {
        currentTheme: this.context.userObject.theme,
    };
    setTheme = (currentTheme) => {
        this.setState({ currentTheme }, () => {
            this.context.functions.setTheme(currentTheme);
        });
    };
    render() {
        const { currentTheme } = this.state;
        return (
            <div className={styles.customizeAppearanceView}>
                <section>
                    <h2>Preset colors</h2>
                    <div className={styles.themes}>
                        {this.themes.map((theme) => (
                            <div className={`${styles.theme} ${theme.id == currentTheme ? styles.selected : ''}`}>
                                {/* <Tooltip title={theme.name} placement="bottom" arrow> */}
                                <button onClick={() => this.setTheme(theme.id)}>
                                    <span className={styles.colorIndicator} style={{ backgroundColor: theme.color }} />
                                </button>
                                {/* </Tooltip> */}
                            </div>
                        ))}
                    </div>
                </section>
                <section>
                    <h2>User settings</h2>
                    <button
                        onClick={() => {
                            this.context.functions.closeSlider();
                            this.context.functions.updateView({ selectedPage: 'settings-index', group: 'my-profile', page: 'default' }, false, true, false, false, this.tr('Settings'));
                        }}
                    >
                        Go to user settings
                        <KeyboardArrowRight className={styles.arrow} />
                    </button>
                </section>
            </div>
        );
    }
}

export default CustomizeAppearanceView;
