import React from 'react';

import DialogContentText from '@mui/material/DialogContentText';
import { SettingsContext } from './../SettingsContext';
import TaimerComponent from "../TaimerComponent";
import _ from 'lodash';

class CloseDialogContent extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, null, "list/lists/ProjectList/CloseDialogContent");
    }

    showProjectList(data, header) {
        if (data.invoicing_info) {
            data.sub_projects.forEach(p => {
                if (!_.isEmpty(data.invoicing_info[p.id]))
                    p.has_invoice_data = true
            })
        }
        return (
            <>
                <span>{header}</span>
                <div class="non-deletable-list">
                    <ul>
                        {data.sub_projects.map(p => (
                            <li>
                                {p.name} ({p.project_id}), {/* {this.tr("Status: ")}  */}
                                <span className={p.locked == "1" ? "closed" : (p.locked == "2" ? "waiting" : "active")}>
                                    {p.locked == "1" ? this.tr("Closed") : (p.locked == "2" ? this.tr("Waiting") : this.tr("Active"))}
                                </span> 
                                {p.has_invoice_data && <span>, <span className="red"> { this.tr("Has uninvoiced data!")}</span></span>}
                            </li>
                        ))}
                    </ul>
                </div>
            </>
        )
    }

    render() {
        const data = this.props.data;

        let has_invoice_data = false;
        if (data.invoicing_info && !_.isEmpty(data.invoicing_info[data.project.id])) 
            has_invoice_data = true

        return (
            <>
                <DialogContentText id="project-delete-dialog-warning">
                    {this.tr("Are you sure you want to close project: ${project} and all it's subprojects?", { project: data.project.name + " (" + data.project.project_id + ")" })}
                </DialogContentText>
                <DialogContentText id="project-delete-dialog-content">
                    {has_invoice_data && <p className="red">{this.tr("Project has uninvoiced data!")}</p>}
                    {data.sub_projects.length > 0 && this.showProjectList(data, this.tr("Sub projects:"))}
                </DialogContentText>
            </>
        )
    }
}

export default CloseDialogContent;
