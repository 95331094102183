import React from 'react';
import TextInput from "./TextInput";
// import ConfigurableComponent from "../general/ConfigurableComponent";
import searchIcon from './icons/search.svg';
import "./SearchTextInput.css";

class SearchTextInput extends React.Component {
	constructor(props) {
		super(props, { throttled: false, throttleMs: 500 });

		this.textInputRef 	 = React.createRef();
		this.throttleTimerId = undefined;

		if(props.throttled === true) {
			const originalListener = props.onInputListener;

			this.onInputListener = (value) => {
				(this.throttleTimerId !== undefined) && clearTimeout(this.throttleTimerId);

				this.throttleTimerId = setTimeout(() => originalListener(value), 500);
			}
		} else
			this.onInputListener = props.onInputListener;

		// Scope bindings,
		this.focus = this.focus.bind(this);
	}
	

	focus() {
		this.textInputRef.current.focus();
	}


	getValue() {
		return this.textInputRef.current.getValue();
	}

	clear() {
		return this.textInputRef.current.setValue("");
	}


	render() {
		return (
			<div className={`searchTextInputWrapper ${this.props.className} ${this.props.disabled ? "disabled" : ""}`} 
                onClick={this.props.onClick}>
				<TextInput
					className={`searchTextInput ${this.props.textInputClassName}`}
					disabled={this.props.disabled}
					initValue={this.props.initValue}
					ref={this.textInputRef}
					placeholder={this.props.placeholder}
					onFocus={this.props.onFocus}
					onKeyUp={this.props.onKeyUp}
					onInputListener={this.onInputListener} />
					<img src={searchIcon} alt="Search" onClick={this.props.onSearchPressed} />
			</div>
		);
	}
}

SearchTextInput.defaultProps = {
    className: "",
    textInputClassName: "",
	throttled: false, 
    onFocus: () => {}, 
    onKeyUp: () => {}, 
    onClick: () => {}, 
    onSearchPressed: () => {}, 
    onInputListener: () => {}, 
};

export default SearchTextInput;
