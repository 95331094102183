import React, { Component } from 'react';
import TaimerComponent from '../TaimerComponent';
import _ from 'lodash';
import './ProfitLossTable.css';
import cn from 'classnames';
import ProfitLossTableSlot from './ProfitLossTableSlot';

class ProfitLossTableRow extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "charts/ProfitLossTableRow");

        this.state = {
            hover: false,
        }
    }

    onMouseOver = (e) => {
        this.setState({hover: true});
    }

    onMouseOut = (e) => {
        this.setState({hover: false});
    }

    render() {
        const { className, label, color, keys, data, values, currency, currencyFormatter, table, linked, labels, openSlider, chartId, lastValueIsTotal } = this.props;
        const { hover } = this.state;

        const sum = lastValueIsTotal ? values[_.last(keys)] : _.sum(_.values(values));

        return <React.Fragment>
            <div className={cn("column row-header row-item", color && "row-header-legend", className, hover && "row-hover")} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
                {color && <span className="legend-color" style={{backgroundColor: color}} />}
                {label}
            </div>

            {keys.map( (key) => 
                <ProfitLossTableSlot
                    chartId={chartId}
                    className={cn(className, "row-item", hover && "row-hover")}
                    linked={linked} 
                    key={key}
                    label={label}
                    monthLabel={labels && labels[key]}
                    sum={sum}
                    month={key}
                    value={values[key]}
                    data={data && data[key]}
                    table={table}
                    onMouseOver={this.onMouseOver}
                    onMouseOut={this.onMouseOut}
                    openSlider={openSlider}
                    currency={currency}
                    currencyFormatter={currencyFormatter} />)}

            <ProfitLossTableSlot 
                chartId={chartId}
                className={cn("row-total", "row-item", className, this.state.hover && "row-hover")}
                value={sum} 
                currency={currency}
                currencyFormatter={currencyFormatter}
                onMouseOver={this.onMouseOver} 
                onMouseOut={this.onMouseOut} />
        </React.Fragment>
    }
}

export default ProfitLossTableRow;