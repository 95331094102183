import React, { useContext } from "react";
import TaimerComponent from "../../../../TaimerComponent";
import InsightBlock from "../../InsightBlock";
import CircularProgressBar from "../../../../general/CircularProgressBar";

import colors from "../../../../colors";
import { SettingsContext } from "../../../../SettingsContext";
import "./QuarterlyOverview.css";

const QuarterOverviewBlock = (props) => {
  const { value, target, title, months } = props;
  let progress = 0;
  if (target.value != 0) {
    progress = (value.value / target.value) * 100;
  }
  const context = useContext(SettingsContext);
  const {
    functions: { presentCurrency },
  } = context;
  const getLabelColor = (progress) => {
    if (progress == 0) return colors.cloudy_blue;
    return progress < 100 ? colors.carnation_pink : "#42b677";
  };
  return (
    <div className="block">
      <div className="titles">
        <h2>{title}</h2>
        {months && <p>{months}</p>}
      </div>
      <div className="values">
        <CircularProgressBar progress={progress} size={85} />
        <div className="labels">
          <div className="big">
            <h3>{value.title}</h3>
            <p style={{ color: getLabelColor(progress) }}>
              {presentCurrency(value.value)}
            </p>
          </div>
          <div>
            <h3>{target.title}</h3>
            <p>{presentCurrency(target.value)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default class QuarterlyOverview extends TaimerComponent {
  constructor(props, context) {
    super(
      props,
      context,
      "dashboard/insights/goals/components/QuarterlyOverview"
    );
    this._monthKeyFormat = "YYYY-MM";
    this.state = {
      items: this._getItems(),
    };
  }

  componentDidUpdate = (oldProps) => {
    if (
      this.props.selectedGoal != oldProps.selectedGoal ||
      this.props.selectedGoalType != oldProps.selectedGoalType ||
      this.props.months != oldProps.months ||
      this.props.data != oldProps.data
    ) {
      this.setState({
        items: this._getItems(),
      });
    }
  };

  _getItems = () => {
    const {
      data = {},
      monthTitles,
      months,
      selectedGoal,
      selectedGoalType,
    } = this.props;
    const selectedGoalTypeName = selectedGoalType ? selectedGoalType.name : '';
    const selectedGoalName = selectedGoal ? selectedGoal.name : '';
    let items = [];
    let totalValue = 0;
    let totalTarget = 0;
    let value = 0;
    let target = 0;
    let startTitle = "";
    if (!data || !data.goal_type || !data.type) {
        return items;
    }
    months.forEach((m, i) => {
      const q = Math.ceil(i / 3);
      const title = `Q${q}`;
      const currentValue = data.goal_type[m.format(this._monthKeyFormat)] || 0;
      const currentTarget = data.type[m.format(this._monthKeyFormat)] || 0;
      totalValue += currentValue;
      totalTarget += currentTarget;
      value += currentValue;
      target += currentTarget;
      if (!startTitle) {
        startTitle = monthTitles[m.month() + 1];
      }
      if ((i + 1) % 3 == 0) {
        const endTitle = monthTitles[m.month() + 1];
        items.push({
          title,
          months: `${startTitle} - ${endTitle}`,
          value: {
            title: selectedGoalName,
            value,
          },
          target: {
            title: selectedGoalTypeName,
            value: target,
          },
        });
        value = 0;
        target = 0;
        startTitle = "";
      }
    });
    items.push({
      title: this.tr("Fiscal Year Total"),
      value: {
        title: selectedGoalName,
        value: totalValue,
      },
      target: {
        title: selectedGoalTypeName,
        value: totalTarget,
      },
    });
    return items;
  };
  render() {
    return (
      <InsightBlock
        {...this.props}
        className="quarterly-overview"
        title={this.tr("Q1-Q4 Overview")}
      >
        <div className="row">
          {this.state.items.map((item) => (
            <QuarterOverviewBlock {...item} />
          ))}
        </div>
      </InsightBlock>
    );
  }
}
