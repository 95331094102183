import React from 'react'

/* css */
import './ActivitiesInsightGraph.css';

/* others */
import _ from 'lodash';
import ContentBlock from '../../../general/ContentBlock';
import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from '../../../../src/SettingsContext';
import ActivitiesInsightChart from './ActivitiesInsightChart';
import ActivitiesInsightLegend from './ActivitiesInsightLegend';

class ActivitiesInsightGraph extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context){
        super(props, context, "dashboard/insights/activities/ActivitiesInsightGraph");
    }
    
    componentDidMount(){
        super.componentDidMount();
    }

    render() {
        return (
            <React.Fragment>
                <ActivitiesInsightChart
                    labels = { this.props.labels }
                    datasets = { this.props.datasets } />
                <ActivitiesInsightLegend />
            </React.Fragment>
        )
    }
}

export default ActivitiesInsightGraph;