/* jquery */
import $ from 'jquery';

const useBackEnd = process.env.REACT_APP_USE_BACKEND > 0 || process.env.NODE_ENV === 'production';

const storage = sessionStorage.taimerToken ? sessionStorage : localStorage;

const { taimerToken } = storage;

/* for odin only, basic auth f*ck things up */
 const headers = {[window.location.href.indexOf(':8080')  > -1 ? 'Authorization-taimer' : 'Authorization']: `Bearer ${taimerToken}`};

export function delay(t, v) {
    return new Promise(function(resolve) { 
        setTimeout(resolve.bind(null, v), t)
    });
 }

const DataHandler = {
    prefix: window.location.href.indexOf(":8080") > -1 || window.location.href.indexOf("branches.dev.psa") > -1 ? "react/api/" : "/react/api/",
    url: (params) => {
        let url = "";
        if (!useBackEnd)
            url = "/json/" + (params.url ? params.url.replace(/\//g, '_') + ".json" : `/${params.module}_${params.action}.json`);
        else if (params.url)
        {
            const { url: params_url, ...p } = params;
            url = DataHandler.prefix + params_url + (p ? `?&${$.param(p)}` : "");
        }
        else {
            url = "faceless.php?" + $.param(params);
        }

        return url;
    },
    request: (method, params, data, json = false) => {
        const { taimerToken } = storage;
        const headers = {[window.location.href.indexOf(':8080')  > -1 ? 'Authorization-taimer' : 'Authorization']: `Bearer ${taimerToken}`};
        if (sessionStorage.testVersion) {
            headers['testVersion'] = sessionStorage.testVersion;
        }
        if (sessionStorage.external_token) {
            headers['Authorization-taimer-ext'] = "Bearer " + sessionStorage.external_token;
        }

        const url = typeof params === "string" ? params : DataHandler.url(params);
        
        const addedParams = {};
        if (json === true && params.url) {
            addedParams.contentType = "application/json; charset=utf-8";
            data = JSON.stringify(data);
        }
        
        return $.ajax({
            url,
            data,
            headers,
            async: params.async || true,
            method: method,
            ...addedParams
        });
    },
    get: (params) => {
        return DataHandler.request("GET", params);
    },
    post: (params, data) => {
        return useBackEnd ? DataHandler.request("POST", params, data, true) : DataHandler.request("GET", params);
    },
    put: (params, data) => {
        return useBackEnd ? DataHandler.request("PUT", params, data, true) : DataHandler.request("GET", params);
    },
    patch: (params, data) => {
        return useBackEnd ? DataHandler.request("PATCH", params, data, true) : DataHandler.request("GET", params);
    },
    "delete": (params, data) => {
        return useBackEnd ? DataHandler.request("DELETE", params, data, true) : DataHandler.request("GET", params);
    },
    file: (params, file, success, complete) => {
        const { taimerToken } = storage;
        const headers = {[window.location.href.indexOf(':8080')  > -1 ? 'Authorization-taimer' : 'Authorization']: `Bearer ${taimerToken}`};
        
        if (useBackEnd) {
            let data = file;

            if (!(data instanceof FormData))
            {
                data = new FormData();
                data.append("attachment", file);
            }

            return $.ajax({
                url: DataHandler.url(params),
                type: 'POST',
                data,
                dataType: "json",
                processData: false,
                contentType: false,
                headers,
                success: success,
                complete: complete
            });
        } else {
            return $.get( DataHandler.url(params) );
        }
    },
    arrayBufferRequest: (method, params, data = undefined, json = false) => {
        const { taimerToken } = storage;
        const headers = {[window.location.href.indexOf(':8080')  > -1 ? 'Authorization-taimer' : 'Authorization']: `Bearer ${taimerToken}`};
        if (sessionStorage.external_token) {
            headers['Authorization-taimer-ext'] = "Bearer " + sessionStorage.external_token;
        }
        
        const addedParams = {};

        if(json) {
            addedParams.contentType = "application/json; charset=utf-8";
            data                    = JSON.stringify(data);
        }

        return $.ajax({
            url: DataHandler.url(params),
            type: method,
            data: data,
            processData: method !== "GET",
            headers,
            xhrFields: { responseType: 'arraybuffer' },
            ...addedParams
        });
   
    },
    getArrayBuffer: (params) => {
        return DataHandler.arrayBufferRequest("GET", params);
    },
    postArrayBuffer: (params, data, json = false) => {
        return DataHandler.arrayBufferRequest("POST", params, data, json);
    },
    autoRetryPost: async (params, data, statusCallback = null) => {
        while (true) {
            try {
                const resp = await DataHandler.request("POST", params, data, true);
                statusCallback && statusCallback({done: true});
                return resp;
            } catch (error) {
                if (error.status === 460) {
                    statusCallback && statusCallback({done: false});
                    await delay(250);
                    continue;
                }

                throw error;
            }
        }
    },
    autoRetryGet: async (params, statusCallback = null) => {
        while (true) {
            try {
                const resp = await DataHandler.request("GET", params);
                statusCallback && statusCallback({done: true});

                return resp;
            } catch (error) {
                if (error.status === 460) {
                    statusCallback && statusCallback({done: false});
                    await delay(250);
                    continue;
                }

                throw error;
            }
        }
    },
};

export default DataHandler;
