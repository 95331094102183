import MoreHoriz from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import moment from "moment";
import { withSnackbar } from 'notistack';
import ContextMenu from '../../general/ContextMenu';
import DataHandler from '../../general/DataHandler';
import { formatInputNumber } from '../../helpers';
import LinkListCell from "../LinkListCell";
import ListCell from "../ListCell";
import PropsOnlyListRow from "../ListRow";
import AutoCompleteCell from "../cells/AutoCompleteCell";
import CheckboxCell from "../cells/CheckboxCell";
import DateCell from "../cells/DateCell";
import EditableStatusCell from "../cells/EditableStatusCell";
import NumberListCell from "../cells/NumberListCell";
import TextInputCell from "../cells/TextInputCell";
import UserAvatarCell from "../cells/UserAvatarCell";
import { SettingsContext } from './../../SettingsContext';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ConfirmationDialog from "../../settings/dialogs/ConfirmationDialog";

import cn from 'classnames';
import { ReactComponent as ActivateTaskIcon } from '../../general/icons/ActivateTask.svg';
import { ReactComponent as CompleteTaskIcon } from '../../general/icons/CompleteTask.svg';
import { ReactComponent as EditTaskIcon } from '../../general/icons/EditTask.svg';
import { ReactComponent as MarkHoursAsDoneIcon } from '../../general/icons/MarkHoursAsDone.svg';
import { ReactComponent as MarkHoursAsUndoneIcon } from '../../general/icons/MarkHoursAsUndone.svg';
import { ReactComponent as MoveUserIcon } from '../../general/icons/MoveUser.svg';
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';

function Link(props) {
	return <a href={props.url}>{props.text}</a>;
}

class ResourcingListRow extends PropsOnlyListRow {
	static contextType = SettingsContext;

	constructor(props) {
		super(props, {
			checked: false,
			confirmationRecurringTask: false,
			confirmationDialogRadio: 'this'
		}, undefined, "list/rows/ResourcingListRow");

		this.state.invalidHours = false;
		this.state.invalidTimeRange = false;

		this.cellEdited = this.cellEdited.bind(this);
		this.showWorkhourDialog = this.showWorkhourDialog.bind(this);
	}

	cellEdited(name, value) {
		const data = { ...this.props.data };

		if (data[name] == value) {
			return;
		}

		if (name == 'text' && value.trim() === "") {
			this.props.rowProps.enqueueSnackbar(this.tr("Description cannot be empty"), {
				variant: "error",
			});
			return;
		}

		if ((name == 'progress' || name == 'hours') && Number(value) !== 0 && !Number(String(value).replace(",", "."))) {
			this.props.rowProps.enqueueSnackbar(this.tr("Invalid number"), {
				variant: "error",
			});
			if (name === "hours")
				this.setState({ invalidHours: true });

			return;
		}

		if (name == 'hours' && Number(value) < data.budgeted) {
			this.props.rowProps.enqueueSnackbar(this.tr("Allocated hours cannot be lower than resourced hours"), {
				variant: "error",
			});

			this.setState({ invalidHours: true });
			return;
		}

		if (typeof value === "object") {
			for (const i in value) {
				data[i] = value[i];
			}
		} else {
			data[name] = value;
			if (name == 'startdate') {
				data.start_date = value;
			}
			if (name == 'enddate') {
				data.end_date = value;
			}
		}
		if (name == 'timerange') {
			const times = value.split("-");
			const start = moment(times[0], 'LT');
			const end = moment(times[1], 'LT');
			const invalidTimeRange = !start || !end || end < start;
			if (invalidTimeRange) {
				this.setState({ invalidTimeRange: invalidTimeRange });
				return;
			} else {
				data.starttime = start.format('HH:mm:ss');
				data.endtime = end.format('HH:mm:ss');
			}
		}
		data.start_date = moment(data.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD');
		data.end_date = moment(data.end_date, 'YYYY-MM-DD').format('YYYY-MM-DD');

		if (data.recurrence_parent_id > 0) {
			this.setState({
				invalidHours: false,
				invalidTimeRange: false,
				showConfirmationDialog: true,
				confirmationRecurringTask: true,
				confirmationDialogText: this.tr("Do you want to edit recurring elements also?"),
				confirmationDialogSaveFunc: () => {
					this.setState({
						showConfirmationDialog: false,
					});
					this.props.rowProps.onUpdate(data, this.state.confirmationDialogRadio);
				},
				confirmationDialogExtra: {},
			});
		} else {
			this.setState({ invalidHours: false, invalidTimeRange: false });
			this.props.rowProps.onUpdate(data, 'this');
		}
	}

	showWorkhourDialog() {
		const data = this.props.data;
		const item = {
			customer: { id: data.resource?.customers_id, name: data.customer_name },
			project: { id: data.resource?.projects_id, name: data.project_name },
			wh_projects_resource: { id: data.id.split("-")[1], name: data.text },
			start: new Date(),
			end: new Date(),
		};

		this.props.rowProps.showWorkhourDialog(item);
	}

	//Marks whole task as done
	markAsDone = (id) => {
		DataHandler.post({ url: 'resourcing/mark_task_done/' + id }).done(() => {
			this.props.rowProps.updateData();
		});
	}

	unlockTask(id) {
		DataHandler.post({ url: 'resourcing/unlock_task/' + id }).done(() => {
			this.props.rowProps.updateData();
		});
	}

	//Marks the task done for your part
	markOwnAsDone = (id) => {
		const { userObject } = this.context;
		const data = {
			users_id: userObject.usersId
		};
		DataHandler.post({ url: 'resourcing/mark_hours_done/' + id }, data).done(() => {
			this.props.rowProps.updateData();
		});
	}

	//Marks the task undone for your part
	markOwnAsUndone = (id) => {
		const { userObject } = this.context;
		const data = {
			users_id: userObject.usersId
		};

		DataHandler.post({ url: 'resourcing/mark_hours_undone/' + id }, data).done(() => {
			this.props.rowProps.updateData();
		});
	}

	handleConfirmationRadio = (evt) => {
		this.setState({ confirmationDialogRadio: evt.target.value });
	}

	handleDelete = () => {
		const { data } = this.props;

		this.props.rowProps.onDelete(data);
	}

	render() {
		const { userObject } = this.context;
		const sharedData = this.props.sharedData
		const data = this.props.data;
		const { checkPrivilege } = this.props.rowProps;
		const className = ["resourcingListRow listElement row", this.props.hidden ? "hidden" : "", data.id < 0 ? "new" : ""].join(" ");

		let hasOwnHours = false;
		let hasOwnHoursUndone = false;
		if (data.type === "task") {
			if (data.resource.user_ids) {
				hasOwnHours = data.resource.user_ids.find(el => el == userObject.usersId) != null;
			}
			if (data.resource.users_hours) {
				hasOwnHoursUndone = data.resource.users_hours.find(el => el.users_id == userObject.usersId && !el.done) != null;
			}
		}

		const hasEditRight = (checkPrivilege("projects", "project_resourcing_write", data.companies_id) || (hasOwnHours && (checkPrivilege("projects", "own_resourcing_write", data.companies_id))));
		const isEditable = hasEditRight && !data.done;
		const start = moment(data.start_date);
		start._d.setHours(0, 0, 0);
		const end = moment(data.end_date);
		end._d.setHours(0, 0, 0);
		const isOneDayTask = !end || ((end - start) / (1000 * 60 * 60 * 24) <= 1);

		const statusTypes = this.props.rowProps.statusTypes;

		const actions = [];
		const trackingAllowed = sharedData?.allow_tracking_all_tasks || (data.resource?.user_ids || []).findIndex(uid => uid == this.context.userObject.usersId) != -1;

		if (data.type === 'task' || data.type === 'milestone') {
			actions.push(<MenuItem onClick={() => this.props.rowProps.showTaskDialog(data)}>
				<EditTaskIcon />{isEditable ? this.tr("Edit task") : this.tr("View task")}
			</MenuItem>);
		}

		if (hasEditRight && (data.type === 'task' && !data.done)) {
			actions.push(<MenuItem onClick={() => this.markAsDone(data.id)}>
				<CompleteTaskIcon />{this.tr("Complete the task")}
			</MenuItem>);
		}

		if (hasEditRight && (data.type === 'task' && data.done)) {
			actions.push(<MenuItem onClick={() => this.unlockTask(data.id)}>
				<ActivateTaskIcon />{this.tr("Activate task")}
			</MenuItem>);
		}

		if (hasOwnHours && hasOwnHoursUndone) {
			actions.push(<MenuItem onClick={() => this.markOwnAsDone(data.id)}>
				<MarkHoursAsDoneIcon />{this.tr("Mark your hours done")}
			</MenuItem>);
		}

		if (hasOwnHours && !hasOwnHoursUndone) {
			actions.push(<MenuItem onClick={() => this.markOwnAsUndone(data.id)}>
				<MarkHoursAsUndoneIcon />{this.tr("Mark your hours undone")}
			</MenuItem>);
		}

		if (hasEditRight && (data.type === 'task' && !data.done)) {
			actions.push(<MenuItem onClick={() => { this.props.rowProps.moveTaskToNewUser(data) }}>
				<MoveUserIcon />{this.tr("Move task to new user")}
			</MenuItem>);
		}

		if (hasEditRight) {
			actions.push(<MenuItem className="delete" onClick={this.handleDelete}>
				<RemoveIcon className="Delete" />{this.tr("Delete")}
			</MenuItem>);
		}

		const cells = {
			expand: actions.length > 0 ?
				<ContextMenu label={<MoreHoriz />} buttonProps={{ className: 'action-menu' }} className="cell row-menu" style={{ width: this.props.columnWidthMap['expand'] + "px", textAlign: "center" }} noExpandIcon>
					{actions}
				</ContextMenu> : <ListCell editable={false} width={this.props.columnWidthMap['expand']} />,
			checked:
				<CheckboxCell
					width={this.props.columnWidthMap['checked']}
					checked={this.props.checked}
					onClick={() => this.props.listRef.check(data.id)}
				/>,
			customer:
				<TextInputCell
					listCellType={LinkListCell}
					listCellProps={{
						urlHandler: value => `index.html?module=customers&action=view&id=${data.resource?.customers_id}`,
						valueHandler: value => value,
						editable: false,
						noTab: true,
					}}
					width={this.props.columnWidthMap['customer']}
					name="customer"
					editable={false}
					value={data.customer_name}
					onEdited={this.cellEdited} />,
			text:
				<TextInputCell
					listCellType={LinkListCell}
					listCellProps={{
						handleClick: () => this.props.rowProps.showTaskDialog(data),
						editable: isEditable
					}}
					width={this.props.columnWidthMap['text']}
					name="text"
					editable={isEditable}
					validation={["empty"]}
					value={data.text}
					onEdited={this.cellEdited} />,
			users:
				<UserAvatarCell
					width={this.props.columnWidthMap['users']}
					name="users"
					editable={false}
					value={data['users']}
					users={data['users']}
					useCustomStyles={true}
					onEdited={this.cellEdited} />,
			status:
				<EditableStatusCell
					width={this.props.columnWidthMap['status']}
					editable={false}
					options={statusTypes}
					value={data.type === 'milestone' ? 4 : data.status} />,
			project:
				<TextInputCell
					listCellType={LinkListCell}
					listCellProps={{
						urlHandler: value => `index.html?module=projects&action=view&id=${data.resource?.projects_id}`,
						valueHandler: value => value,
						editable: false,
						noTab: true,
					}}
					width={this.props.columnWidthMap['project']}
					name="project"
					editable={false}
					value={data.project_name}
					onEdited={this.cellEdited} />,
			startdate:
				<DateCell
					editable={isEditable}
					name="startdate"
					width={this.props.columnWidthMap['startdate']}
					value={data.start_date}
					onEdited={this.cellEdited} />,
			enddate:
				<DateCell
					editable={isEditable && data.type === 'task'}
					name="enddate"
					width={this.props.columnWidthMap['enddate']}
					value={data.end_date}
					onEdited={this.cellEdited} />,
			timerange:
				<TextInputCell
					editable={isEditable && isOneDayTask && data.type === 'task'}
					listCellProps={{
						showErrorBorder: this.state.invalidTimeRange
					}}
					name="timerange"
					width={this.props.columnWidthMap['timerange']}
					value={data.is_one_day_task && data.starttime && data.endtime ? moment(data.start_date).format('LT') + " - " + moment(data.end_date).format('LT') : ''}
					onEdited={this.cellEdited} />,
			priority:
				<AutoCompleteCell
					autoCompleteData={sharedData.priorities}
					width={this.props.columnWidthMap['priority']}
					name="priority"
					menuPlacement="bottom"
					editable={isEditable}
					value={Number(data.priorities_id)}
					onEdited={value => this.cellEdited('priorities_id', value.id)}
				/>,
			resource_group:
				<ListCell
					width={this.props.columnWidthMap['resource_group']}
					name="resource_group"
					editable={true}
					value={data.priority}
					onEdited={this.cellEdited} />,
			skill:
				<AutoCompleteCell
					autoCompleteData={sharedData.skills}
					width={this.props.columnWidthMap['skill']}
					name="skill"
					menuPlacement="bottom"
					editable={isEditable}
					value={data.skills_id}
					onEdited={value => this.cellEdited('skills_id', value.id)}
				/>,
			progress:
				<TextInputCell
					width={this.props.columnWidthMap['progress']}
					name="progress"
					textAlign="right"
					editable={isEditable && data.type === 'task'}
					value={data.progress ? (Number(data.progress) * 100).toFixed(0) + " %" : ""}
					validation={['percent100']}
					onEdited={(name, value) => this.cellEdited(name, Number(value.replace(",", ".")) / 100)} />,
			hours_done:
				<NumberListCell
					className={cn("hours_done", trackingAllowed && "can_add_hours")}
					width={this.props.columnWidthMap['hours_done']}
					name="hours_done"
					textAlign="right"
					value={data.hours_done}
					decimals={2}
					allowEmpty
					permanentEditMode={true}
					onEdited={this.cellEdited}
					onClick={data.type === 'task' && trackingAllowed ? this.showWorkhourDialog : () => { }} />,
			hours_done_all_time:
				<NumberListCell
					className={cn("hours_done", trackingAllowed && "can_add_hours")}
					width={this.props.columnWidthMap['hours_done_all_time']}
					name="hours_done_all_time"
					textAlign="right"
					value={data.hours_done_all_time}
					decimals={2}
					allowEmpty
					permanentEditMode={true}
					onEdited={this.cellEdited}
					onClick={data.type === 'task' && trackingAllowed ? this.showWorkhourDialog : () => { }} />,
			hours:
				<TextInputCell
					width={this.props.columnWidthMap['hours']}
					name="hours"
					textAlign="right"
					listCellProps={{
						showErrorBorder: this.state.invalidHours
					}}
					editable={false}
					value={data.type == 'milestone' ? "" : formatInputNumber(data.hours, "hours")}
					validation={['numeric']}
					decimals={2}
					allowEmpty
					onEdited={(name, value) => this.cellEdited(name, value.replace(",", "."))} />,
			hours_all_time:
				<TextInputCell
					width={this.props.columnWidthMap['hours_all_time']}
					name="hours_all_time"
					textAlign="right"
					listCellProps={{
						showErrorBorder: this.state.invalidHours
					}}
					editable={false}
					value={data.type == 'milestone' ? "" : formatInputNumber(data.hours_all_time, "hours")}
					validation={['numeric']}
					decimals={2}
					allowEmpty
					onEdited={(name, value) => this.cellEdited(name, value.replace(",", "."))} />,
			budgeted:
				<NumberListCell
					width={this.props.columnWidthMap['budgeted']}
					name="budgeted"
					textAlign="right"
					editable={false}
					value={data.resourced}
					decimals={2}
					allowEmpty
					onEdited={this.cellEdited} />,
			budgeted_all_time:
				<NumberListCell
					width={this.props.columnWidthMap['budgeted_all_time']}
					name="budgeted_all_time"
					textAlign="right"
					editable={false}
					value={data.resourced_all_time}
					decimals={2}
					allowEmpty
					onEdited={this.cellEdited} />,
			remaining:
				<NumberListCell
					width={this.props.columnWidthMap['remaining']}
					name="remaining"
					textAlign="right"
					editable={false}
					value={data.remaining}
					decimals={2}
					allowEmpty
					onEdited={this.cellEdited} />,
			remaining_all_time:
				<NumberListCell
					width={this.props.columnWidthMap['remaining_all_time']}
					name="remaining_all_time"
					textAlign="right"
					editable={false}
					value={data.remaining_all_time}
					decimals={2}
					allowEmpty
					onEdited={this.cellEdited} />,
		};

		const childRows = this.createChildren(this.props.children, ResourcingListRow);

		return (
			<div className="listElement">
				<div className={className} style={{ height: !this.props.hidden ? "44px" : "0px", lineHeight: "44px" }}>
					{this.props.columnOrder.map(columnName => cells[columnName])}
				</div>
				{childRows !== false && <div className="listElement">{childRows}</div>}

				{this.state.showConfirmationDialog && (
					<ConfirmationDialog
						data={{
							text: this.state.confirmationDialogText,
							cancelText: this.state.confirmationDialogExtra?.cancelText,
						}}
						onDialogClose={() => this.setState({ showConfirmationDialog: false })}
						onDialogSave={this.state.confirmationDialogSaveFunc}
					>
						{this.state.confirmationRecurringTask &&
							<RadioGroup
								name="resource-edit-recurrence"
								value={this.state.confirmationDialogRadio}
								onChange={this.handleConfirmationRadio}
							>
								<FormControlLabel
									value="this"
									label={this.tr("Only this")}
									labelPlacement="start"
									control={<Radio />}
								/>
								<FormControlLabel
									value="future"
									label={this.tr("Future")}
									labelPlacement="start"
									control={<Radio />}
								/>
								<FormControlLabel
									value="all"
									label={this.tr("All")}
									labelPlacement="start"
									control={<Radio />}
								/>
							</RadioGroup>
						}
					</ConfirmationDialog>
				)
				}
			</div>
		);
	}
}

export default withSnackbar(ResourcingListRow);
