import React, { Component } from 'react';
import cn from 'classnames';
import './Checkbox.css';

export default class Checkbox extends Component {
    static defaultProps = {
        style: {},
        onChange: () => {},
		disabled: false,
		showCheckedEvenWhenDisabled: false
    };

    onClick = () => {
        const { checked, name } = this.props;

        this.props.onChange({
            target: {
                name,
                checked: !checked
            }
        });
    }
    
	render() {
        const { checked, label, title, disabled, showCheckedEvenWhenDisabled } = this.props;

        return (<div 
                style={this.props.style}
                data-testid={this.props.key}
                className={cn("TMRCheckbox", title && "HasTooltip", disabled && "disabled", this.props.className)}
                title={title}>
            <div className={cn("check", checked && (!disabled || showCheckedEvenWhenDisabled) && "checked")} onClick={this.onClick}>
                <div className="inner" />
            </div>{label}
        </div>)
	}
}
