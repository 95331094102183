import React from 'react';
import { IconButton } from '@mui/material';
import OutlinedField from '../general/OutlinedField';
import TaimerComponent from '../TaimerComponent';
import { DialogUserHour, DialogUserHourPeriod } from './ResourceDialogUsers';
import cn from 'classnames';

import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import ResourceDialogPeriodRow from './ResourceDialogPeriodRow';

import styles from './ResourceDialogUsersRow.module.scss';
import { Employee } from '../resourcing/helpers';
import { Task } from '../resourcing/resourcing';

interface Props {
    className?: string;
    disableEdit: boolean;
    disableHoursEdit: boolean;
    task: Task;
    user: DialogUserHour;
    employee?: Employee;
    onChange?: (user: DialogUserHour) => void;
    onDelete?: (user: DialogUserHour) => void;
}

interface State {
    expanded: boolean;
}

class ResourceDialogUsersRow extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'dialogs/ResourceDialogUserRow');

        this.state = {
            expanded: false,
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
    };

    deleteClick = () => {
        const { user, onDelete } = this.props;

        onDelete?.(user);
    }

    changeHours = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { task, user, onChange } = this.props;

        const { target: { value } } = e;

        const hours = Number(value.replace(',', '.'));

        onChange?.({
            ...user, 
            hours, 
            lastHours: hours,
            invalid: isNaN(hours) || hours > task.hours,
        });
    }

    toggle = () => {
        const { expanded } = this.state;

        this.setState({expanded: !expanded});
    }

    calculatePeriods = (periods: DialogUserHourPeriod[]) => {
        return periods.reduce( (c, v) => ({
            hours: c.hours + v.hours,
            days: c.days + (v.days ?? 0),
        }) , {
                hours: 0,
                days: 0,
        });
    }

    onChangePeriod = (period: DialogUserHourPeriod) => {
        const { task, user, onChange } = this.props;

        const index = user.periods.findIndex(x => x.key === period.key);

        if (index < 0)
            return;

        const periods = [...user.periods];
        periods[index] = period;

        const { hours, days } = this.calculatePeriods(periods);

        console.log(hours, days);

        onChange?.({
            ...user,
            periods,
            hours, 
            days,
            lastHours: hours,
            invalid: isNaN(hours) || hours > task.hours,
        });
    }

    onDeleletePeriod = (period: DialogUserHourPeriod) => {
        const { task, user, onChange } = this.props;

        const periods = user.periods.filter(x => x.key !== period.key);

        if (periods.length > 0) {
            const { hours, days } = this.calculatePeriods(periods);

            onChange?.({
                ...user,
                periods,
                hours, 
                lastHours: hours,
                invalid: isNaN(hours) || hours > task.hours,
            });
        } else {
            onChange?.({
                ...user,
                periods,
                hours: 0, 
                lastHours: 0,
                is_split: false,
                invalid: false,
            });
        }
    }

    render() {
        const { className, task, employee, user, disableEdit, disableHoursEdit } = this.props;
        const { expanded } = this.state;

        const canExpand = user.is_split;

        return (<>
            <div className={cn(className, styles.row)}>
                <div className={styles.expand}>
                    {canExpand && <IconButton onClick={this.toggle}>
                        {expanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>}
                </div>
                <div className={styles.nametitle}>
                    <span className="name">{employee?.name}</span>
                    <span className={styles.title}>{employee?.role ? ', ' + employee.role : ''}</span>
                </div>
                <div className={styles.hours_done}>
                    <span>
                        {user.allTotal?.tracked.toFixed(2) ?? '0.00'}
                    </span>
                    <span> / </span>
                </div>
                <div className={styles.hours}>
                    <OutlinedField 
                        disabled={disableHoursEdit || user.is_split} 
                        error={user.invalid}
                        onInput={this.changeHours} 
                        value={user.hours} />
                </div>
                <div>
                    <IconButton
                        classes={{ disabled: 'disabled' }}
                        disabled={disableEdit || user.done}
                        aria-label={this.tr("Delete")}
                        onClick={this.deleteClick}
                        size="large">
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </div>
            </div>
            {canExpand && expanded && user.periods.map( (period, index) => <ResourceDialogPeriodRow
                key={index}
                index={index}
                disableEdit={disableEdit}
                disableHoursEdit={disableHoursEdit}
                period={period}
                task={task}
                user={user}
                employee={employee}
                onChange={this.onChangePeriod}
                onDelete={this.onDeleletePeriod}
            />)}
        </>);
    }
}

export default ResourceDialogUsersRow;
