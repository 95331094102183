import React from 'react'
import styles from './SliderCheckBoxRowGroup.module.scss';
import _ from 'lodash';

/* Material UI */
import { Checkbox, FormGroup, FormControlLabel, FormControl } from '@mui/material';

interface Props {
    boxes: LabelCheck[];
    checkedBoxes: string[];
    onCheckChange: (checkedBoxes: string[]) => void;
    title?: string;
}

export interface LabelCheck {
    name: string;
    label: string;
}

export default class SliderCheckBoxRowGroup extends React.PureComponent<Props> {

    onCheckChange = (evt) => {
        const { checkedBoxes, onCheckChange } = this.props;
        const { value, checked } = evt.target;

        let enabledBoxes = _.cloneDeep(checkedBoxes);

        const isActive = enabledBoxes.find(c => c == value);

        if (!isActive && checked) {
            enabledBoxes.push(value);
        }
        else if (isActive && !checked) {
            enabledBoxes = enabledBoxes.filter(e => e != value);
        }

        onCheckChange(enabledBoxes);
    }

    render() {
        const { boxes, title, checkedBoxes } = this.props;

        return (
            <div className={styles.container}>
                {title && <span className={styles.title}>{title}</span>}
                <FormControl className={styles.formControl}>
                    <FormGroup row className={styles.formGroup}>
                        {boxes.map(f =>
                            <FormControlLabel
                                value={f.name}
                                control={<Checkbox size="small" />}
                                label={f.label}
                                labelPlacement="start"
                                onChange={(evt) => {
                                    this.onCheckChange(evt)
                                }}
                                checked={checkedBoxes.find(a => a == f.name) ? true : false}
                                className={styles.formControlLabel}
                            />
                        )}
                    </FormGroup>
                </FormControl>
            </div>
        )
    }
}