import React from 'react';
import ListCell from "./../ListCell";

import AttachFile from '@mui/icons-material/AttachFile';
import Avatar from '@mui/material/Avatar';
import withStyles from '@mui/styles/withStyles';


class AttachmentCell extends React.Component {
	constructor(props) {
		super(props);

	}

	render() {
		const StyledAvatar = withStyles({
			root: {
				backgroundImage: "unset",
				backgroundColor: "unset",
				height: "100%",
				cursor: "pointer"
			}
		})(Avatar);

		return (
			<ListCell
				width={this.props.width}
				permanentEditMode={true}>
				{!this.props.hidden && <StyledAvatar
					onClick={this.props.onClick}>
					<AttachFile fontSize="small"/>
				</StyledAvatar>}
			</ListCell>
		);
	}
}

AttachmentCell.defaultProps = {
	width: 40,
	ownerRow: undefined,
	name: undefined,
	value: "",
	hidden: false,
};

export default AttachmentCell;