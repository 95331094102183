import React from "react";
import moment from "moment";
import { InsightTableListRow } from "../../InsightTable";
import ListCell from "../../../../list/ListCell";

export default class GoalsVsActualRow extends InsightTableListRow {
  getCellValue = (data, forTimespan = false) => {
    const {
      rowProps: { month, week },
    } = this.props;

    let value = 0;
    Object.keys(data || {}).forEach((date) => {
      if (forTimespan) {
        const dateMoment = moment(date, "YYYY-MM-DD");
        if (week != "-1") {
          const weekMoment = moment(week, "WW-YYYY");
          if (dateMoment.isSame(weekMoment, "week")) {
            value += data[date];
          }
        } else {
          const monthMoment = moment(month, "YYYY-MM");
          if (dateMoment.isSame(monthMoment, "month")) {
            value += data[date];
          }
        }
      } else {
        value += data[date];
      }
    });
    return value;
  };

  getCellForKey = (key) => {
    const { data } = this.props;
    const {
      functions: { presentCurrency },
    } = this.context;
    switch (key) {
      case "type_ytd": {
        const value = this.getCellValue(data.type);
        return presentCurrency(value);
      }
      case "goal_type_ytd": {
        const value = this.getCellValue(data.goal_type);
        return presentCurrency(value);
      }
      case "cumulative_difference_ytd": {
        const profit = this.getCellValue(data.goal_type);
        const target = this.getCellValue(data.type);
        return presentCurrency(profit - target);
      }
      case "goal_type_month": {
        const value = this.getCellValue(data.goal_type, true);
        return presentCurrency(value);
      }
      case "type_month": {
        const value = this.getCellValue(data.type, true);
        return presentCurrency(value);
      }
      case "difference_month": {
        const profit = this.getCellValue(data.goal_type, true);
        const target = this.getCellValue(data.type, true);
        return presentCurrency(profit - target);
      }
      default:
        return null;
    }
  };

  defineCells() {
    const { data, columnOrder } = this.props;
    let otherCells = {};
    (columnOrder || []).forEach((key) => {
      otherCells[key] = (
        <ListCell
          editable={false}
          value={this.getCellForKey(key) || data[key] || "-"}
        />
      );
    });

    let cells = {
      ...super.defineCells(),
      ...otherCells,
    };

    return cells;
  }
}
