import React from "react";
import { CheckRounded } from "@mui/icons-material";
import TaimerAvatar from "../../../../general/TaimerAvatar";
import TaimerComponent from "../../../../TaimerComponent";

import "./AddTeamMembersPopUp.css";

export default class AddTeamMembersPopUp extends TaimerComponent {

  constructor(props, context) {
    super(props, context, 'resourcing/views/ResourcingGrid/components/AddTeamMembersPopUp');
    this.state = {
      selected: []
    };
  }

  _toggleSelection = user => {
    const selected = this.props.single ? [] : [...this.state.selected];
    const index = selected.indexOf(user.users_id);
    index != -1 ? selected.splice(index, 1) : selected.push(user.users_id);
    this.setState({ selected });
  };

  render() {
    const { users, onSave, onCancel, header, subtitle } = this.props;
    const { selected } = this.state;
    return (
      <div className="project-member-dialog">
        <div className="header-container">
          <div>
            <h1>{header}</h1>
            {subtitle && <p>{subtitle}</p>}
          </div>
        </div>
        <div className="project-member-list">
          {users.length == 0 ? (
            <div className="no-values-container">
              <p>{this.tr("No users to add.")}</p>
            </div>
          ) : (
            users.map(user => {
              const rowSelected = selected.indexOf(user.users_id) != -1;
              return (
                <div
                  onClick={() => this._toggleSelection(user)}
                  className="project-member-row"
                >
                  <TaimerAvatar id={user.users_id} name={user.label} />
                  <p>{user.label}</p>
                  {rowSelected && <CheckRounded className="selected-icon" />}
                </div>
              );
            })
          )}
        </div>
        <div className="project-member-dialog-buttons">
          <div
            style={{ backgroundColor: "#eee" }}
            className="button"
            onClick={onCancel}
          >
            {this.tr("Close")}
          </div>
          <div
            style={{
              opacity: selected.length == 0 ? 0.4 : 1,
              backgroundColor: "#2d9ff7",
              color: "white"
            }}
            className="button"
            onClick={() => selected.length > 0 && onSave(this.state.selected)}
          >
            {this.tr("Save")}
          </div>
        </div>
      </div>
    );
  }
}
