import { MenuItem, Tooltip } from '@mui/material';
import { ReactElement } from 'react';
import ContextMenu, { ContextSubMenu } from './ContextMenu';
import styles from './DropdownMenuButton.module.scss';

export interface DropdownMenuButtonItem {
    icon: ReactElement;
    label: string;
    action?: (event: any) => void;
    buttonProps?: object;
    visible?: boolean;
    disabled?: boolean;
    tooltip?: string;
    component?: () => ReactElement;
    isSubMenu?: boolean;
    subItems?: object[];
}

interface DropdownMenuButtonProps {
    label: string;
    items: DropdownMenuButtonItem[];
    className?: string;
    closeWithSubmenuSelection?: boolean;
    clickAwayListenerProps?: object;
}

const DropdownMenuButton = (props: DropdownMenuButtonProps) => {
    const { label, items, className, ...rest } = props;

    const filterItem = (i) => i.visible == undefined || !!i.visible;

    const renderItem = (item, index) => {
        let menuItem;
        if (item.component) {
            {
                menuItem = item.component();
            }
        } else if(item.isSubMenu) {
            menuItem = (
                <ContextSubMenu data-testid={`dropdown_option_${item.label}`} key={`${index}_${item.label}`} disabled={item.disabled} icon={item.icon} title={item.label}>
                    {item.subItems.map(subItem => {
                        return (
                            <MenuItem onClick={() => item.action(subItem)}>
                                {subItem.label}
                            </MenuItem>
                        );
                    })}
                </ContextSubMenu>
            );
        } else {
            menuItem = (
                <MenuItem data-testid={`dropdown_option_${item.label}`} key={`${index}_${item.label}`} onClick={item.action} disabled={item.disabled}>
                    {item.icon}
                    {item.label}
                </MenuItem>
            );

        }
        if (item.tooltip) {
            return (
                <Tooltip title={item.tooltip}>
                    <div>{menuItem}</div>
                </Tooltip>
            );
        }
        return menuItem;
    };

    return (
        <ContextMenu
            className={`${styles.dropdownMenuButton} ${className}`}
            buttonProps={{
                stickyIcon: true,
                size: 'large',
            }}
            variant="outlined"
            label={label}
            size="medium"
            placement={'bottom-end'}
            data-testid="dropdown-menu-button"
            {...rest}
        >
            {items.filter(filterItem).map(renderItem)}
        </ContextMenu>
    );
};

export default DropdownMenuButton;
