import React from "react";
import _ from 'lodash';

import InsightChart from "../../InsightChart";
import StackedCurrencyChart from "../../StackedCurrencyChart";
import InsightChartTable from "../../InsightChartTable";
import { stringToColour } from "../../../../helpers";

const BAR_COLORS = ["#b2b9c9", "#c88ffb", "#ffa082", "#2589d7", "#f7bc59"];

const PipelineByStageAndCloseDate = props => {
  const { tr, data, months, multipipeline, ...otherProps } = props;

  return (
    <InsightChart
      {...otherProps}
      className="pipeline-by-stage-and-close"
      title={tr("Pipeline by Stage & Close Date")}
      data={{
        sets: data.stages.map((stage, i) => ({
          ...stage,
          values: data.values[stage.id] || [],
          data: [],
          // data: data.slider[stage.id] || [],
          label: stage.name,
          colors: {
            backgroundColor: BAR_COLORS[i] || stringToColour(stage.name),
            borderColor: BAR_COLORS[i] || stringToColour(stage.name)
          }
        })),
        labels: months
      }}
      titleOnSlider={true}
      chartType={StackedCurrencyChart}
      tableType={InsightChartTable}
      lowOpacityChart={true}
      sliderColumns={["user", "project", "account", "value", "stage", "funnel", "closing_date"]}
      chartProps={{
        stacked: true,
        xStacked: true,
        displayLegend: true
      }}
      tableProps={{
        setTitle: multipipeline ? tr("Pipeline") : tr("Stage"),
        subtitles: data.stages.map(stage => data.counts[stage.id] + " " + tr("projects")),
        extraColumns: !multipipeline ? [
          {
            title: tr("Probability"),
            getValue: item =>
                _.sum(item.values) * (item.probabilityPercentage)
          }
        ] : [],
      }}
    />
  );
};

export default PipelineByStageAndCloseDate;
