import { FormControlLabel, Switch } from '@mui/material';
import { isEqual } from 'lodash';
import React from 'react';
import DataHandler from '../general/DataHandler';
import DataList from '../general/DataList';
import QuotePDFView from '../general/QuotePDFView';
import { WizardPageContentObject } from '../general/TaimerWizard';
import WizardPageContentRenderer from '../general/WizardPageContentRenderer';
import { convertDateFormat } from '../helpers';
import TaimerComponent from '../TaimerComponent';
import styles from './QuotePrintPreview.module.scss';

interface QuotePrintPreviewProps {
    quoteId: string;
    project: any;
    printLanguage?: string;
    printDateFormat?: string;
    onPrintSettingChanged?: (key: string, value: string) => void;
    onPrintSettingsLoaded?: (settings: any) => void;
    onPDFChanged?: (quotePDF: any, quotePDFFile: any) => void;
    includeProposal?: boolean;
    onboardingContent?: WizardPageContentObject;
}

interface QuotePrintPreviewState {
    printDateFormat?: string;
    printLanguage?: any;
    printLanguageOptions: any[];
    printDateOptions: any[];
    includeProposal?: boolean;
    quote?: any;
    loadingPDF?: any;
    quotePDF?: any;
}

class QuotePrintPreview extends TaimerComponent<QuotePrintPreviewProps, QuotePrintPreviewState> {
    quotePDFFile: any = React.createRef();
    constructor(props, context) {
        super(props, context, 'projects/QuotePrintPreview');
        this.state = {
            printLanguageOptions: [],
            printDateOptions: [],
            includeProposal: this.hasProposalRight() ? this.props.includeProposal : false,
            loadingPDF: true,
        };
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.getCompanyData();
    }

    hasProposalRight = () => {
        return this.context.functions.checkPrivilege('projects', 'proposal_read');
    };

    getCompanyData = async () => {
        const {
            project: { companies_id },
        } = this.props;
        const companies = await DataHandler.get({
            url: `subjects/companies_with_project_right/read+project_cost_estimate_read`,
            currency: 1,
            date_format: 1,
            print_lang: 1,
            country_lang: 1,
            print_options: 1,
        });
        const currentCompany = companies.find((c) => c.id == companies_id);
        if (!currentCompany) return;
        const defaultPrintOptions: any = this.getDefaultPrintOptions(currentCompany);
        this.setState(
            {
                ...defaultPrintOptions,
            },
            () => {
                this.props.onPrintSettingsLoaded && this.props.onPrintSettingsLoaded({ printLanguage: defaultPrintOptions.printLanguage, printDateFormat: defaultPrintOptions.printDateFormat });
            }
        );
    };

    getDefaultPrintOptions = (company) => {
        return {
            printLanguage: this.props.printLanguage || (company.print_lang ? company.print_lang : company.country_lang),
            printLanguageOptions: company.print_languages || [],
            printDateFormat: this.props.printDateFormat || company.date_format || convertDateFormat(this.context.taimerAccount.companyDateFormat, true),
            printDateOptions: company.print_date_formats || [],
        };
    };

    onPrintSettingChanged = ({ value }, { name }) => {
        if (isEqual(this.state[name], value)) return;
        const update: any = {
            [name]: value,
            loadingPDF: true,
        };
        this.setState(update, () => {
            if (this.props.onPrintSettingChanged) {
                this.props.onPrintSettingChanged(name, value);
            }
        });
    };

    onPDFLoaded = (quotePDF, fileId, quote) => {
        this.quotePDFFile = fileId;
        this.setState({ quotePDF, loadingPDF: false, quote }, () => {
            this.props.onPDFChanged && this.props.onPDFChanged(quotePDF, this.quotePDFFile);
        });
    };

    render() {
        const { quoteId, project } = this.props;
        const { printDateFormat, printLanguage, printLanguageOptions, printDateOptions, includeProposal, loadingPDF, quote } = this.state;
        const showProposal = this.hasProposalRight() && !!quote && !(!quote.proposals_id || quote.proposals_id == '-1');
        const translatedPrintLanguages = printLanguageOptions.map((o) => ({ ...o, label: this.tr(o.label) }));
        return (
            <div id={styles.quotePrintPreview}>
                <div className={styles.main}>
                    <QuotePDFView
                        quoteId={quoteId}
                        project={project}
                        printDateFormat={printDateFormat}
                        printLanguage={printLanguage}
                        onFileLoaded={this.onPDFLoaded}
                        includeProposal={includeProposal}
                    />
                </div>
                <div className={styles.right}>
                    <div className={`${styles.settings} ${loadingPDF ? styles.disabled : ''}`}>
                        <h2>{this.tr('PDF details')}</h2>
                        <div className={styles.fields}>
                            {showProposal && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            name="includeProposal"
                                            checked={includeProposal}
                                            onChange={(e) => this.onPrintSettingChanged({ value: e.target.checked }, { name: e.target.name })}
                                        />
                                    }
                                    label={this.tr('Include proposal')}
                                />
                            )}
                            <DataList
                                name="printLanguage"
                                label={this.tr('Print language')}
                                options={translatedPrintLanguages}
                                value={translatedPrintLanguages.find((pl) => pl.value == printLanguage)}
                                onChange={this.onPrintSettingChanged}
                            />
                            <DataList
                                name="printDateFormat"
                                label={this.tr('Date format')}
                                options={printDateOptions}
                                value={printDateOptions.find((pd) => pd.value == printDateFormat)}
                                onChange={this.onPrintSettingChanged}
                            />
                        </div>
                        {this.props.onboardingContent && (
                            <div className={styles.onboardingContent}>
                                <WizardPageContentRenderer
                                    item={{}}
                                    content={this.props.onboardingContent}
                                    onAction={() => {}}
                                    onFieldChanged={() => {}}
                                    onClose={() => {}}
                                    onBack={() => {}}
                                    tr={this.tr}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default QuotePrintPreview;
