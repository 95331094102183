import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { validEmail } from "../dialogs/Validate";
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from './../SettingsContext';
import LabelFieldGroup from '../general/LabelFieldGroup';
import DataHandler from "./../general/DataHandler";
import Utils from "./../general/Utils";
import CoreDialog from '../dialogs/mass_operations/CoreDialog';

import withStyles from '@mui/styles/withStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import EditIcon from "@mui/icons-material/Edit";


const styles = theme => ({
    reqContainer: {
        gridArea: "address",
        justifySelf: "end",
        width: "61%"
    }    
});

class CompanyAddress extends TaimerComponent {
    static contextType  = SettingsContext;
    static propTypes    = { enqueueSnackbar: PropTypes.func.isRequired };
    static defaultProps = {
        requireValidEmail: false,
        onEmailValidityChange: () => {}
    };
    
    state = {
        emailValid: true,
        editingAddress: false,
    };

    constructor(props, context) {
        super(props, context, "invoices/CompanyAddress");
    }
 

    editAddress = (name, value, capitalize, allcap) => {
        if (this.props.data[name] == value)
            return;

        if(name === "email" && this.props.requireValidEmail) {
            const valid = validEmail(value);

            this.setState({ emailValid: valid }, () => this.props.onEmailValidityChange(valid));

            if(!valid) {
                this.props.enqueueSnackbar(this.tr("Please enter a valid email."), { variant: "warning" });
                return;
            }        
        }

        value = capitalize ? (!allcap ? Utils.capitalize(value) : Utils.startCase(value)) : value;

        const { onChange } = this.props;

        onChange && onChange(name, value);
    };

    addressEditClicked = () => {
        if (!this.props.editMode || this.state.editingAddress) return;

        this.context.functions.showDialog(
            <CoreDialog
                onDialogClose={this.context.functions.closeDialog}
                onDialogSave={this.onEditQuote}
                dialogType={'delete'}
                dialogProps={{
                    wider: true,
                    onCloseClick: this.context.functions.closeDialog,
                    open: true,
                    close: this.context.functions.closeDialog,
                    confirmButtonClass: 'blue',
                    confirmDisabled: false,
                    header: this.tr('Edit company address?'),
                    translatedConfirmButtonText: this.tr('Edit address'),
                    warning: () => <p>{this.tr('This changes company address details in settings. Edit address?')}</p>,
                    onConfirm: () => {
                        this.context.functions.closeDialog();
                        this.setState({ editingAddress: true });
                    },
                }}
            />
        );
    };
    addressAwayClicked = () => this.setState({ editingAddress: false });

    render() {

        const { taimerAccount: { showState, isMulticompany } } = this.context;
        const { classes, editMode, data, company, ...rest } = this.props;
        const { editingAddress } = this.state;

        const usMode = /* (data && data.state) || */ showState;    

        const commonProps = {className: "test"};
        return (
            <React.Fragment>
                <ClickAwayListener onClickAway={this.addressAwayClicked}>
                    <div id="companyAddress" onClick={this.addressEditClicked} className={classNames(classes.reqContainer, editMode && "editable", !editingAddress && "notEditing")}>
                    
                        <EditIcon className="editIcon" />
                        <LabelFieldGroup
                            required
                            editMode={editingAddress}
                            values={data}
                            errorsInFields={this.state.emailValid ? [] : ["email"]}
                            fields={[
                                { label: this.tr("Company"), name: "name", value: undefined, editable: false },
                                { label: this.tr("Address"), name: "address", value: undefined, onChange: e => this.editAddress("address", e.target.value, true, true), ...commonProps },
                                usMode && { label: this.tr("City"), name: "city", value: undefined, onChange: e => this.editAddress("city", e.target.value, true, true),  ...commonProps},
                                usMode && { label: this.tr("State"), name: "state", value: undefined, onChange: e => this.editAddress("state", e.target.value, true, true),  ...commonProps},
                                { label: this.tr("Zip code"), name: "postalcode", value: undefined, onChange: e => this.editAddress("postalcode", e.target.value), ...commonProps},
                                !usMode && { label: this.tr("City"), name: "city", value: undefined, onChange: e => this.editAddress("city", e.target.value, true, true),  ...commonProps},
                                { label: this.tr("Country"), name: "country", value: undefined, onChange: e => this.editAddress("country", e.target.value, true, true), ...commonProps},
                                { label: this.tr("Business id"), name: "vatid", value: undefined, onChange: e => this.editAddress("vatid", e.target.value), ...commonProps },
                                { label: this.tr("Email"), name: "email", value: undefined, onChange: e => this.editAddress("email", e.target.value), ...commonProps },                            
                                { label: this.tr("Phone"), name: "telephone", value: undefined, onChange: e => this.editAddress("telephone", e.target.value), ...commonProps },
                                { label: this.tr("WWW"), name: "www", value: undefined, onChange: e => this.editAddress("www", e.target.value),  ...commonProps },
                            ]}
                            {...rest} />  
                    </div>
                </ClickAwayListener>              
            </React.Fragment>
        );
    }
}

export default withSnackbar(withStyles(styles)(CompanyAddress));
