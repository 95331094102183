import React, { Component } from 'react';
import { SettingsContext } from '../../SettingsContext';
import TaimerComponent from  '../../TaimerComponent';

import './ErrorScreen.css';

class ErrorScreen extends TaimerComponent {
    static contextType = SettingsContext;
    constructor(props, context) { 
        super(props, context, "dialogs/imports/ErrorScreen");
    }
    
    render() {
        const {tr} = this;
        const { errorParams: { error, onlyIntStr, noMemory} } = this.props;
        return (
            <div id="error-screen">
                <h1>{tr("ERROR")}</h1>
                { error ?
                    <h3>{tr("Something went wrong during file processing.")}</h3>
                    :
                    noMemory ?
                    <h3>{tr("The used file had too much data")}.</h3>
                    :
                    onlyIntStr ?
                    <h3>{tr("File had number formatted cells with text")}.</h3> 
                    : undefined
                }
            </div>
        );
    }
}

export default ErrorScreen;