import React from "react";
import InsightTable, { InsightTableListRow } from "../../InsightTable";
import ListCell from "../../../../list/ListCell";
import cn from "classnames";
import ListRow from "../../../../list/ListRow";

class OverviewByUserSummaryRow extends ListRow {
  constructor(props, context) {
    super(
      props,
      {},
      {},
      "dashboard/insights/hours/components/OverviewByUserSummaryRow"
    );
  }

  defineClassName() {
    return "insightSummaryRow";
  }

  defineCells() {
    const {
      rows,
      sharedData: { currency },
    } = this.props;

    let values = {};
    rows.map((row) => {
      Object.keys(row.data || {}).forEach((key) => {
        values[key]
          ? (values[key] += Number(row.data[key] || 0))
          : (values[key] = Number(row.data[key] || 0));
      });
    });

    let cells = {};

    Object.keys(this.props.columnConfig).forEach((key) => {
      cells[key] = (
        <ListCell
          width={this.props.columnConfig[key].width}
          className="totalcell"
          name="user"
          onlyDisplay
          editable={false}
          alignCell
        >
          {(Number(values[key] || 0).toFixed(2) || "-") + " h"}
        </ListCell>
      );
    });

    const totalColumns = [
      "user",
    ];

    totalColumns.forEach((col) => {
      cells[col] = (
        <ListCell
          width={(this.props.columnConfig[col] || {}).width || 0}
          className="totalcell"
          name={col}
          onlyDisplay
          editable={false}
          alignCell
        >
          {this.tr("Total")}
        </ListCell>
      );
    });

    return cells;
  }

  render() {
    const cells = this.defineCells();
    const className = [
      "summaryRow listElement row flex",
      this.defineClassName(),
    ].join(" ");

    return (
      <div className={className} style={{ height: `50px` }}>
        {this.props.columnOrder.map((columnName) => cells[columnName])}
      </div>
    );
  }
}

class OverviewByUserCell extends InsightTableListRow {
  defineCells() {
    const { data } = this.props;
    let otherCells = {};
    Object.keys(data).forEach(key => {
      switch (key) {
        case "available_h":
          otherCells[key] = (
            <ListCell onlyDisplay={true}>
              <div className="list-cell-container">
                <p>{Number(data[key]).toFixed(2) || "-"} h</p>
              </div>
            </ListCell>
          );
          break;
        case "tracked_h":
          {
            otherCells[key] = (
              <ListCell onlyDisplay={true}>
                <div className="list-cell-container">
                  <p>{Number(data[key]).toFixed(2) || "-"} h</p>
                  <span className="percent-label">{(data["available_h"] ? data[key] / data["available_h"] * 100 : 0).toFixed(0)}%</span>
                </div>
              </ListCell>
            );
          }
          break;
        case "non_billable_h":
          {
            otherCells[key] = (
              <ListCell onlyDisplay={true}>
                <div className="list-cell-container">
                  <p>{Number(data[key]).toFixed(2) || "-"} h</p>
                  <span className="percent-label">{(data["tracked_h"] ? data[key] / data["tracked_h"] * 100 : 0).toFixed(0)}%</span>
                </div>
              </ListCell>
            );
          }
          break;
        case "billable_h":
          {
            otherCells[key] = (
              <ListCell onlyDisplay={true}>
                <div className="list-cell-container">
                  <p>{Number(data[key]).toFixed(2) || "-"} h</p>
                  <span className="percent-label">{(data["tracked_h"] ? data[key] / data["tracked_h"] * 100 : 0).toFixed(0)}%</span>
                </div>
              </ListCell>
            );
          }
          break;
          case "vacation_h":
            {
              otherCells[key] = (
                <ListCell onlyDisplay={true}>
                  <div className="list-cell-container">
                    <p>{Number(data[key]).toFixed(2) || "-"} h</p>
                    <span className="percent-label">{(data["tracked_h"] ? data[key] / data["tracked_h"] * 100 : 0).toFixed(0)}%</span>
                  </div>
                </ListCell>
              );
            }
            break;
        default:
          otherCells[key] = (
            <ListCell onlyDisplay={true}>
              <div className="list-cell-container">
                <p>{data[key] || "-"}</p>
              </div>
            </ListCell>
          );
          break;
      }
    });

    let cells = {
      ...super.defineCells(),
      ...otherCells
    };

    return cells;
  }
}

const OverviewByUser = props => {
  const { tr, loaded, currency, data, sliderRows, numberFormatter, showSlider } = props;
  const columns = [
    {
      field: "user",
      name: "user",
      header: tr("User"),
      width: 200,
      resizeable: false,
      moveable: false,
      hideable: false,
      showMenu: false
    },
    {
      field: "available_h",
      name: "available_h",
      header: tr("Available h"),
      width: 100,
      resizeable: false,
      moveable: false,
      hideable: false,
      showMenu: false
    },
    {
      field: "tracked_h",
      name: "tracked_h",
      header: tr("Tracked h"),
      width: 100,
      compare_to: "available_h",
      resizeable: false,
      moveable: false,
      hideable: false,
      showMenu: false
    },
    {
      field: "non_billable_h",
      name: "non_billable_h",
      header: tr("Non-billable h"),
      width: 100,
      resizeable: false,
      moveable: false,
      hideable: false,
      showMenu: false
    },
    {
      field: "billable_h",
      name: "billable_h",
      header: tr("Billable h"),
      width: 100,
      resizeable: false,
      moveable: false,
      hideable: false,
      showMenu: false
    },
    {
      field: "vacation_h",
      name: "vacation_h",
      header: tr("Vacation h"),
      width: 100,
      resizeable: false,
      moveable: false,
      hideable: false,
      showMenu: false
    },
    // {
    //   field: "hour_balance",
    //   name: "hour_balance",
    //   header: tr("Hour Balance"),
    //   width: 100,
    //   resizeable: false,
    //   moveable: false,
    //   hideable: false,
    //   showMenu: false
    // }
  ];

  return (
    <InsightTable
      loaded={loaded}
      height={400}
      hideDefaults={true}
      width={6}
      blockProps={{
        largeTitle: true,
        rootClassName: "block",
        wrapperClass: "block-wrapper",
        headerClass: "block-header"
      }}
      className={cn("insight-hours-list", "full-width")}
      sliderTitleColumn="users_name"
      title={tr("Overview by User")}
      rowType={OverviewByUserCell}
      summaryRowType={OverviewByUserSummaryRow}
      rowKey="users_id"
      data={data}
      showSlider={showSlider}
      columns={columns}
      currency={currency}
      sharedData={{
        numberFormatter,
      }}
    />
  );
};

export default OverviewByUser;
