import React from "react";

import TaimerComponent from "../TaimerComponent";
import DataHandler from "../general/DataHandler";

import "./TabMemo.css";
import { SettingsContext } from "../SettingsContext";
import MemoView from "../general/MemoView";
import PageTopSection from "../general/PageTopSection";

class TabMemo extends TaimerComponent {
  static contextType = SettingsContext;

  constructor(props, context) {
    super(props, context, "accounts/TabMemo");

    this.state = {
      memos: []
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this._getMemos();
  }

  componentWillUnmount = () => {
    super.componentWillUnmount();
  };

  _getMemos = () => {
    const { id, company } = this.props;
    DataHandler.get({ url: `accounts/${id}/company/${company}/memo` }).done(
      memos => {
        memos = memos.map(m => {
          if (m.users_company < 1)
            m.users_name += ` (${this.tr("freelancer")})`;
          return m;
        });        
        this.setState({ memos });
      }
    );
  };

  saveMemo = selectedMemo => {
    const { id, enqueueSnackbar, company } = this.props;
    const req =
      selectedMemo.id == "-1"
        ? DataHandler.put(
            { url: `accounts/${id}/company/${company}/memo` },
            selectedMemo
          )
        : DataHandler.put(
            {
              url: `accounts/${id}/company/${company}/memo/${selectedMemo.id}`
            },
            selectedMemo
          );
    req
      .then(() => {
        enqueueSnackbar(this.tr("Memo saved successfully!"), {
          variant: "success"
        });
        setTimeout(() => {
          this._getMemos();
        }, 1000);
      })
      .catch(() => {
        enqueueSnackbar(this.tr("Error saving memo!"), { variant: "error" });
      });
  };

  deleteMemo = selectedMemo => {
    const { id, enqueueSnackbar, company } = this.props;
    DataHandler.delete({
      url: `accounts/${id}/company/${company}/memo/${selectedMemo.id}`
    })
      .then(() => {
        enqueueSnackbar(this.tr("Memo deleted successfully!"), {
          variant: "success"
        });
        setTimeout(() => {
          this._getMemos();
        }, 1000);
      })
      .catch(() => {
        enqueueSnackbar(this.tr("Error deleting memo!"), {
          variant: "error"
        });
      });
  };

  render() {
    return (
      <div id="accounts-memo">
        {this.props.header && (
            <PageTopSection
                header={this.props.header}
                subheaders={this.props.subheaders}
            />
        )}
        <MemoView
          {...this.props}
          memos={this.state.memos}
          saveMemo={this.saveMemo}
          deleteMemo={this.deleteMemo}
        />
      </div>
    );
  }
}

export default TabMemo;
