import React from "react";

import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from "../../../SettingsContext";
import OverviewBlock from "./OverviewBlock";
import colors from "../../../colors";
import OverviewBoxes from "./OverviewBoxes";
import DataHandler from "../../../general/DataHandler";
import "./ProjectsOverview.css";
import InsightSlider from "../../insights/InsightSlider";
import InsightDropDown from "../../insights/InsightDropDown";
import moment from "moment";
import VersionContentManager from "../../../general/VersionContentManager";

const MODE_KEY = "my_day_projects_overview_mode";
export default class ProjectsOverview extends TaimerComponent {
  static contextType = SettingsContext;
  constructor(props, context) {
    super(props, context, "dashboard/my_day/components/ProjectsOverview");

    this.modes = [
      {
        key: "today",
        label: this.tr("Today"),
        action: () => this._setMode("today"),
      },
      {
        key: "this_week",
        label: this.tr("This Week"),
        action: () => this._setMode("this_week"),
      },
      {
        key: "this_month",
        label: this.tr("This Month"),
        action: () => this._setMode("this_month"),
      },
    ];

    this.roles = [
      {
        key: "any",
        label: this.tr("Any"),
        action: () => this._setRole("any"),
      },
      {
        key: "sales_agent",
        label: this.tr("Sales Agent"),
        action: () => this._setRole("sales_agent"),
      },
      {
        key: "project_manager",
        label: this.tr("Project Manager"),
        action: () => this._setRole("project_manager"),
      },
      {
        key: "team_member",
        label: this.tr("Team Member"),
        action: () => this._setRole("team_member"),
      },
    ];

    const mode = this._getModeFromLS(MODE_KEY, "this_month");
    const role = "team_member";

    this.state = {
      mode,
      data: initialData,
      loading: true,
      sliderOpen: false,
      role,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this._getData(true);
  }

  shouldComponentUpdate = (_, newState) => {
    return newState != this.state;
  };

  _getData = (graphData = false) => {
    const { userObject } = this.context;
    const { mode, role } = this.state;
    this.setState({ loading: true }, () => {
      DataHandler.get({
        url: `myday/my_projects/${userObject.usersId}`,
        mode,
        role,
        graphData,
      }).done((data) => {
        this.setState({ loading: false, data });
      });
    });
  };

  _getModeFromLS = (key, defaultValue) => {
    let mode = defaultValue;
    try {
      mode = localStorage.getItem(key) || defaultValue;
    } catch (e) {}
    return mode;
  };

  _setMode = (mode) => {
    this.setState({ mode }, () => {
      this._getData(true);
    });
    try {
      mode = localStorage.setItem(MODE_KEY, mode);
    } catch (e) {}
  };

  _setRole = (role) => {
    this.setState({ role }, () => {
      this._getData(true);
    });
  };

  _formatCurrency = (value, currency) =>
    this.context.functions.presentCurrency(value, currency);

  _getSliderColumns = (status) => {
    switch (status) {
      case "new_leads":
      case "on_hold":
      case "total_pipeline":
        return [
          "project_my_day",
          "account",
          "stage",
          "closing_date",
          "value",
          "sales_agent",
          "role",
        ];
      case "won_deals":
        return ["project_my_day", "account", "won_date", "value", "sales_agent"];
      case "lost":
        return ["project_my_day", "account", "lost_date", "value", "sales_agent"];
      default:
        return [];
    }
  };

  _renderContent = () => {
    const { data, mode, currency } = this.state;
    const modeLabel = this.modes.find((obj) => obj.key == mode).label;
    return (
      <OverviewBoxes
        topItems={[
          {
            color: "#7580eb",
            value: this._formatCurrency(
              data.overview.new_leads.value,
              currency
            ),
            sliderData: data.overview.new_leads.slider_data,
            sliderColumns: this._getSliderColumns("new_leads"),
            sliderTitle: `${this.tr("New Leads")} - ${modeLabel}`,
            title: this.tr("New Leads"),
            subtitle: `${data.overview.new_leads.count || 0} ${this.tr(
              "leads"
            )}`,
            overrideOnClick: VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'pipelines') && this.showUpgradeSlider,
            needsUpgrade: VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'pipelines')
          },
          {
            color: colors.orangey_yellow,
            value: this._formatCurrency(data.overview.on_hold.value, currency),
            sliderData: data.overview.on_hold.slider_data,
            sliderColumns: this._getSliderColumns("on_hold"),
            sliderTitle: `${this.tr("On Hold")} - ${modeLabel}`,
            title: this.tr("On Hold"),
            subtitle: `${data.overview.on_hold.count || 0} ${this.tr("deals")}`,
          },
        ]}
        bottomItems={[
          {
            color: colors.greenish_cyan,
            value: this._formatCurrency(
              data.overview.won_deals.value,
              currency
            ),
            sliderData: data.overview.won_deals.slider_data,
            sliderColumns: this._getSliderColumns("won_deals"),
            sliderTitle: `${this.tr("Won Deals")} - ${modeLabel}`,
            title: this.tr("Won Deals"),
            subtitle: `${data.overview.won_deals.count || 0} ${this.tr(
              "deals"
            )}`,
          },
          {
            color: colors.carnation_pink,
            value: this._formatCurrency(data.overview.lost.value, currency),
            sliderData: data.overview.lost.slider_data,
            sliderColumns: this._getSliderColumns("lost"),
            sliderTitle: `${this.tr("Lost")} - ${modeLabel}`,
            title: this.tr("Lost"),
            subtitle: `${data.overview.lost.count || 0} ${this.tr("deals")}`,
          },
        ]}
      />
    );
  };

  _getGraphData = () => {
    const { data } = this.state;
    return {
      months: data.graph_data.months,
      color: "#7580eb",
      datasets: [
        {
          label: this.tr("Total"),
          values: data.graph_data.total,
          formatTooltipLabel: label => this._formatCurrency(label),
        },
        {
          label: this.tr("New Leads"),
          values: data.graph_data.new,
          formatTooltipLabel: label => this._formatCurrency(label),
          hidden: true,
        },
        {
          label: this.tr("Won Deals"),
          values: data.graph_data.won,
          formatTooltipLabel: label => this._formatCurrency(label),
          hidden: true,
        },
        {
          label: this.tr("On Hold"),
          values: data.graph_data.on_hold,
          formatTooltipLabel: label => this._formatCurrency(label),
          hidden: true,
        },
        {
          label: this.tr("Lost"),
          values: data.graph_data.lost,
          formatTooltipLabel: label => this._formatCurrency(label),
          hidden: true,
        },
      ],
    };
  };

  showUpgradeSlider = () => {
    this.context.functions.showUpgradeSlider();
  }

  render() {
    const { mode, loading, currency, data, sliderOpen, role } = this.state;
    return (
      <React.Fragment>
        <InsightSlider
          onClose={() => this.setState({ sliderOpen: false })}
          open={sliderOpen}
          data={data.overview.total_pipeline.slider_data}
          label={this.tr("Total Pipeline")}
          columns={this._getSliderColumns("total_pipeline")}
        />
        <OverviewBlock
          className="my-day-projects-overview"
          overlayBottomOffset={40}
          overlayTopOffset={15}
          dropdownItems={this.modes}
          selected={mode}
          loading={loading}
          graphData={this._getGraphData()}
          extraTopBarComponents={
            <InsightDropDown
              color="#fff"
              style={{ marginRight: 10 }}
              title={this.tr("Role")}
              selected={role}
              tabs={this.roles}
            />
          }
          title={this.tr("Projects Overview")}
          subtitles={VersionContentManager.isFeatureHidden(this.namespace, 'pipelineValue') ? undefined : [
            {
              title: this.tr("Total Pipeline"),
              action: VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'pipelines') ? this.showUpgradeSlider : () => this.setState({ sliderOpen: true }),
            },
            {
              title: this._formatCurrency(
                data.overview.total_pipeline.value,
                currency
              ),
              action: VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'pipelines') ? this.showUpgradeSlider : () => this.setState({ sliderOpen: true }),
            },
          ]}
          onSubtitleClick={() => this.setState({ sliderOpen: true })}
          content={this._renderContent()}
        />
      </React.Fragment>
    );
  }
}

const getMonths = () => {
  let months = [];
  const now = moment();
  for (let i = 0; i < 6; i++) {
    months.push(moment(now).add(i, "month").format("MM-YYYY"));
  }
  return months;
};

const initialData = {
  graph_data: {
    new: [0, 0, 0, 0, 0, 0],
    pipeline: [0, 0, 0, 0, 0, 0],
    won: [0, 0, 0, 0, 0, 0],
    lost: [0, 0, 0, 0, 0, 0],
    total: [0, 0, 0, 0, 0, 0],
    months: getMonths(),
  },
  overview: {
    total_pipeline: {
      value: 0,
      slider_data: [],
    },
    new_leads: {
      value: 0,
      count: 0,
      slider_data: [],
    },
    on_hold: {
      value: 0,
      count: 0,
      slider_data: [],
    },
    won_deals: {
      value: 0,
      count: 0,
      slider_data: [],
    },
    lost: {
      value: 0,
      count: 0,
      slider_data: [],
    },
  },
};
