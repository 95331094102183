import React, { Component } from 'react';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import TaimerComponent from '../../TaimerComponent';
import ImportTasksReviewListRow from './ImportTasksReviewListRow';
import List from '../../list/List';

/* other */
import _ from 'lodash';
import cn from 'classnames';

/* css */
import styles from './ImportTasksReview.module.scss';
import { FormGroup } from '@mui/material';

class ImportTasksReview extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
        data: [],
        skills: [],
        priorities: [],
    }
    constructor(props, context) {
        super(props, context, "dialogs/imports/ImportTasksReview");

        const { resourcing_disable_skill, resourcing_disable_priority } = props;
        const { taimerAccount: { useSubresources } } = context;

        const common = {
            width: 200, showMenu: false, resizeable: true, showResizeMarker: false, moveable: false, hideable: false, 
        };

        this.fields = [
            useSubresources && { ...common, field: "hierarchy", name: "hierarchy", header: this.tr("Hierarchy"), width: 60, },
            !resourcing_disable_skill && { ...common, field: "type", name: "type", header: this.tr("Type"), width: 100 },
            { ...common, field: "description", name: "description", header: this.tr("Description"), width: 200, visualizationType: "tree" },
            { ...common, field: "notes", name: "notes", header: this.tr("Notes"), width: 200 },
            !resourcing_disable_priority && { ...common, field: "priority", name: "priority", header: this.tr("Priority"), width: 60 },
            { ...common, field: "start_date", name: "start_date", header: this.tr("Start Date"), width: 60 },
            { ...common, field: "end_date", name: "end_date", header: this.tr("End Date"), width: 60 },
            { ...common, field: "allocated", name: "allocated", header: this.tr("Allocated h"), width: 60 },
        ].filter(x => x);
    }

    render() {
        const { skills, priorities } = this.props;
        const { taimerAccount: { useSubresources } } = this.context;

        return (
            <div className={styles.root}>
                <div className={cn(styles.top, styles.section)}>
                    <div className={styles.reviewList}>
                    <List
                        ref={this.list}
                        data={this.props.data}
                        columns={this.fields}
                        sharedData={{skills, priorities}}
                        height="fitRemaining"
                        parentKey="parentId"
                        minHeight={448}
                        trimHeight={-10}
                        className={styles.list}
                        listRowType={ImportTasksReviewListRow}
                        treeData={useSubresources}
                        parentsExpandedOnInit
                        noStateData
                    />
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportTasksReview;