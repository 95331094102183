import React from 'react';

import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import withStyles from '@mui/styles/withStyles';

import "./NotificationContent.css";
import { SnackbarContent } from 'notistack';

const notificationContentStyles = {
    card: {
        boxSizing: "border-box",
        padding: "0px 24px",
        backgroundColor: "#2d9ff7",
        minWidth: "336px", 
        color: "#fff"
    }
};

const notificationContentVariantColors = {
    success: "#43a047",
    error: "#d32f2f",
    info: "#2979ff",
    warning: "#ffa000"
};

class NotificationContent extends React.Component {
    static defaultProps = {
        style: {},
        variant: "info"
    };


    constructor(props) {
        super(props); 
    }


    render() {
        const style = {
            backgroundColor: notificationContentVariantColors.hasOwnProperty(this.props.variant) 
            ? 
            notificationContentVariantColors[this.props.variant]
            :
            undefined,
            ...this.props.style
        };


        return (
            <SnackbarContent ref={this.props.innerRef}>
                <Card className={this.props.classes.card} style={style}>
                    {this.props.children}
                </Card>
            </SnackbarContent>
        );
    }
}


NotificationContent.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(notificationContentStyles)(NotificationContent);
