/* react */
import React from 'react';

/* css */
import './Efina.css';

/* material-ui */
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";

/* others */
import List from "../../list/List";
import Utils from "./../../general/Utils.js";
import ListCell from "../../list/ListCell";
import TreeOption from "../../list/TreeOption";
import SettingRow from "../../list/rows/SettingRow";
import DataHandler from '../../general/DataHandler';
import TrashCanIcon from "@mui/icons-material/Delete";
import TextInputCell from "../../list/cells/TextInputCell";
import CreateBobOptions from "../../list/CreateBobOptions";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import { SettingsContext } from '../../SettingsContext';

class AccountRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            account_number: <TextInputCell
                width={this.props.columnWidthMap['account_number']}
                name="account_number"
                value={this.state.data['account_number']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class DimensionRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            dimension: <TextInputCell
                width={this.props.columnWidthMap['dimension']}
                name="dimension"
                value={this.state.data['dimension']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class VatCodeRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            value: <TextInputCell
                width={this.props.columnWidthMap['value']}
                name="value"
                value={this.state.data['value']}
                onEdited={this.editAndSave}
            />,
            vat_code: <TextInputCell
                width={this.props.columnWidthMap['vat_code']}
                name="vat_code"
                value={this.state.data['vat_code']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class Wintime extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Wintime");

        this.state = {
            username: "",
            password: "",
            access_token: "",
            realm: ""
        };

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.salesAccountsList = React.createRef();
        this.dimensionsList = React.createRef();
        this.vatCodesList = React.createRef();
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getSalesAccounts();
        this.getDimensions();
        this.getVatCodes();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/wintime`}).done(response => {
            this.setState(response);
        });
    }

    getSalesAccounts = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/wintime/1`}).done(response => {
            this.setState(response);
        });
    }

    getDimensions = () => {
        DataHandler.get({url: `accounting/dimensions/${this.props.company}/wintime`}).done(response => {
            this.setState(response);
        });
    }

    getVatCodes = () => {
        DataHandler.get({url: `accounting/vatcodes/${this.props.company}/wintime`}).done(response => {
            this.setState(response);
        });
    }

    onClickSave = () => {
        DataHandler.put({url: `settings/company/${this.props.company}/wintime`}, this.state).done(resp => {
            this.props.enqueueSnackbar(this.tr("SFTP information saved"), {
                variant: "success",
            });	
            this.updateComponentData();
        });
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        DataHandler.delete({url: `settings/company/${this.props.company}/wintime`}).done(this.back);
    }
    
    render(){
        const { username, password, access_token, realm, activated } = this.state;        
        const { tr } = this;

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Wintime Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("SFTP information")}</h4>
                    <OutlinedField value={realm} onChange={(evt) => this.setState({realm: evt.target.value})} label={tr("Domain")} />
                    <OutlinedField value={username} onChange={(evt) => this.setState({username: evt.target.value})} label={tr("Username")} />
                    <OutlinedField value={password} onChange={(evt) => this.setState({password: evt.target.value})} label={tr("Password")} autoComplete="new-password" />
                    <OutlinedField value={access_token} onChange={(evt) => this.setState({access_token: evt.target.value})} label={tr("Folder path")} />
                </div>

                <div className="settings">
                    <Button color="primary" onClick={this.onClickSave} size="large">{tr("Save")}</Button>
                </div>

                <div className="settings">
                    <h4>{tr("Sales accounts")}</h4>
                    <Button className="green" onMouseUp={() => this.salesAccountsList.current.addNewRow()} size="large">{tr("Add account")}</Button>
                    <br />
                    <List
                        ref={this.salesAccountsList}
                        id="accountingSalesAccountList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            account_number: "",
                        }}
                        listRowType={AccountRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Account name"), ...this.dColConf },
                            { width: 200, name: "account_number", header: tr("Account number"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                const company = this.props.company;
                                DataHandler.post({url: `accounting/accounts/${company}/wintime/1`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            },
                            onUpdate: data => {
                                const company = this.props.company;
                                DataHandler.put({url: `accounting/accounts/${company}`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            },
                            onDelete: data => {
                                const company = this.props.company;
                                data.deleted = "1";
                                DataHandler.delete({url: `accounting/accounts/${company}`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            }
                        }}
                        data={this.state.salesAccounts}
                    />
                </div>

                <div className="settings">                  
                    <h4>{tr("Dimensions")}</h4>
                    <Button className="green" onMouseUp={() => this.dimensionsList.current.addNewRow()} size="large">{tr("Add dimension")}</Button>
                    <br />
                    <List
                        ref={this.dimensionsList}
                        id="dimensionsList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            dimension: 0,
                        }}
                        listRowType={DimensionRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Dimension name"), ...this.dColConf },
                            { width: 350, name: "dimension", header: tr("Dimension ID"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                data.companies_id = this.props.company;
                                DataHandler.post({url: 'accounting/dimensions/wintime'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensions();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: 'accounting/dimensions'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensions();
                                });
                            },
                            onDelete: data => {
                                data.deleted = "1";
                                DataHandler.delete({url: 'accounting/dimensions'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensions();
                                });
                            }
                        }}
                        data={this.state.dimensions}
                    />
                </div>

                <div className="settings">                  
                    <h4>{tr("VAT codes")}</h4>
                    <Button className="green" onMouseUp={() => this.vatCodesList.current.addNewRow()} size="large">{tr("Add VAT codes")}</Button>
                    <br />
                    <List
                        ref={this.vatCodesList}
                        id="vatCodesList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            value: 24,
                            vat_code: 0
                        }}
                        listRowType={VatCodeRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Name"), ...this.dColConf },
                            { width: 350, name: "value", header: tr("VAT %"), ...this.dColConf },
                            { width: 350, name: "vat_code", header: tr("VAT code ID"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                data.companies_id = this.props.company;
                                DataHandler.post({url: 'accounting/vatcodes/wintime'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getVatCodes();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: 'accounting/vatcodes'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getVatCodes();
                                });
                            },
                            onDelete: data => {
                                data.deleted = "1";
                                DataHandler.delete({url: 'accounting/vatcodes'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getVatCodes();
                                });
                            }
                        }}
                        data={this.state.vatcodes}
                    />
                </div>
            </div>
        )
    }
}

export default Wintime;
