import React from 'react';
import OutlinedField from "./../../general/OutlinedField";
import TaimerComponent from "./../../TaimerComponent";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';


class SaveSearchQuery extends TaimerComponent {

    constructor(props, context) {
        super(props, context, "reports/dialogs/SaveSearchQuery");

        this.state = {
            queryName: '',
            isPrivate: true
        }
    }

    onNameChange = (evt) => {
        this.setState({queryName: evt.target.value});
    }

    onPrivateChange = (evt) => {
        this.setState({isPrivate: evt.target.checked});
    }

    handleCancel = () => {
        this.props.onDialogClose();
    }

    save = () => {     
        const { queryName, isPrivate } = this.state;
        this.props.onDialogSave(this.props.data.saveFunc, {
            name: queryName,
            isPrivate: isPrivate});
    }

    render() {

        const { queryName, isPrivate } = this.state;

        return (
            <Dialog
                open
                className="saveQueryDialog"
                maxWidth="sm"
                aria-labelledby="saveQuery" >
                <DialogContent className="saveQueryDialogContent" ref={cont => this.container = cont}>
                    <OutlinedField className="fullWidth" autoFocus type="text" label={this.tr("Name")} name="name" value={queryName} onChange={this.onNameChange} />
                    <Switch checked={isPrivate} value={isPrivate} color="primary" onChange={this.onPrivateChange} />
                    <span>{this.tr("Save as private query")}</span>
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="outlined" onClick={this.handleCancel} >
                        {this.tr("Cancel")}
                    </Button>
                    <Button size="large" variant="outlined" onClick={this.save} color="primary">
                        {this.tr("Ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

SaveSearchQuery.defaultProps = {
    onDialogClose: () => {},
    onDialogSave: () => {}
}

export default SaveSearchQuery;
