import React, { Component } from 'react';

import Drawer from '@mui/material/Drawer';
import { Button } from '@mui/material';
import { Clear } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';

import List from "../../list/List";
import PropsOnlyListRow from "../../list/PropsOnlyListRow";
import ListCell from '../../list/ListCell';
import TaimerComponent from '../../TaimerComponent';
import Utils from "../../general/Utils";
import TextInputCell from "../../list/cells/TextInputCell";

import Finnish from '../img/Activity_icons/Finnish.svg'
import UK from '../img/Activity_icons/UK.svg'
import Swedish from '../img/Activity_icons/Swedish.svg'
import USA from '../img/Activity_icons/USA.svg'
import AU from '../img/Activity_icons/AU.svg'
import CA from '../img/Activity_icons/CA.svg'

import './ActivityTranslationSlider.css';

class ActivityTranslationSliderRow extends PropsOnlyListRow {
    constructor(props, context) {
        super(props, {}, {}, "settings/pages/ActivityTranslationSlider");

    }

    defineClassName() {
        return "activitytranslationSliderRow";
    }

    defineCells() {
        const { data } = this.props;

        const cells = {
            
            translation:
                 <div  className={"translationCell"} >
                    <TextInputCell 
                        className={"cellValue"} 
                        name={data.field}
                        value={data.translation}
                        onEdited={(name, value) => this.setDataAndUpdate({...data, translation: value})}                        
                    />
                    <EditIcon className="editIcon" />
                 </div>,
    
            language:
                <ListCell alignCell className="languageCell" onlyDisplay name="language" value={data['language']} >
                      <img className="flag"  src={data.flagIcon} />{data.language}
                </ListCell>
        };

        return cells;
    }
}

class ActivityTranslationSlider extends TaimerComponent {
    static defaultProps = {
        columns: ['translation', 'language']
    };

    constructor(props, context) {
        super(props, context, "settings/pages/ActivityTranslationSlider");

        this.state = {
            rows: [
                { id: 1, field: 'name_fi', translation: '', language: this.tr('Finnish'), flagIcon: Finnish },
                { id: 2, field: 'name_se', translation: '', language: this.tr('Swedish'), flagIcon: Swedish },
                { id: 3, field: 'name_en_us', translation: '', language: this.tr('USA'), flagIcon: USA },
                { id: 5, field: 'name_en', translation: '', language: this.tr('UK'), flagIcon: UK },
                { id: 4, field: 'name_en_au', translation: '', language: this.tr('AU'), flagIcon: AU },
                { id: 6, field: 'name_en_ca', translation: '', language: this.tr('CA'), flagIcon: CA },
            ]
        };
    }

    componentDidUpdate(prevProps) {
         if (this.props.data && prevProps.data != this.props.data)
            this.setTranslations(this.props.data);
    }

    setTranslations = (data) => {
        let {rows} = this.state;
        rows = rows.map(r => {return {...r, translation: data[r.field]}});
        this.setState({rows: rows});
    }

    updateTranslations = (rowData) => {
        const {id, field, translation } = rowData;
        const {data, onSave} = this.props;
        let {rows} = this.state;
        rows = rows.map(r => r.id == id ? {...r, translation: translation} : r);
        this.setState({rows: rows});
        data[field] = translation;
        onSave(data);
    }    

    render() {
        const { data, open, onClose, onSave, columns, icons } = this.props;
        const { userObject } = this.context;
        const { tr } = this;
        const { rows } = this.state;

        const defaultNameField = Utils.getNameFieldByUserLang(userObject.language, 'name');

        const cols = [
            { field: "translation", name: "translation", header: tr("Translation"), width:120, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "language", name: "language", header: tr("Language"), width: 80, resizeable: false, moveable: false, hideable: false, showMenu: false }
        
        ].filter(x => columns.indexOf(x.name) !== -1);

        return (
            <Drawer className="activity_translation_slider" anchor="right" open={open} onClose={onClose}>
                <div className="header">
                    <div className="title">
                    <div className={`headerIcon ${data.old_icon_set > 0 ? '' : 'new-icon'}`}> <img src={data.old_icon_set > 0 ? data.icon : icons[data.icon]} /></div>
                        <h3>{data[defaultNameField]}</h3>
                    </div>
                    <div className="close"><Button onClick={onClose}><Clear /></Button></div>
                </div>
                <div className="table">
                    <List
                        fluid
                        noStateData={true}
                        height="fitRemaining"
                        rowHeight={44}  
                        rowKey="id"
                        data={rows}
                        columns={cols}
                        listRowType={ActivityTranslationSliderRow}
                        rowProps={{
                            onUpdate: (data) => this.updateTranslations(data)
                        }}
                    />
                </div>
            </Drawer>
        );
    }
}

export default ActivityTranslationSlider;
