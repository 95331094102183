import { ViewColumn, ViewConfiguration, ViewGrouping } from "../ViewConfiguration";

export type ValidGanttColumn = "text" | "users_name" | 
    "pipeline_name" | "projects_allocated" | "projects_allocated_all_time" | 
    "hours" | "hours_all_time" | 
    "budgeted" | "budgeted_all_time" | 
    "hours_done" | "hours_done_all_time" |
    "remaining" | "remaining_all_time" |
    "priority"|"skill"|"progress"|"add";

export type ValidGanttGrouping = "customer"|'user'|'priority'|'skill';

export interface GanttViewColumn extends ViewColumn {
    name: ValidGanttColumn;
    label?: string;
    tree?: boolean;
    align?: "left"|"right"|"center";
    resize: boolean;
    width: number;
}

export interface GanttViewGrouping extends ViewGrouping {
    name: ValidGanttGrouping;
    label: string;
}

const viewConfiguration: ViewConfiguration<GanttViewColumn, GanttViewGrouping> = {
    defaultGrouping: 'customer',
    defaultZoom: 'Months',
    zooms: [
        {
            name: 'Hours',
            label: 'Hours',
        },
        {
            name: 'Days',
            label: 'Days',
        },
        {
            name: 'Weeks',
            label: 'Weeks',
        },
        {
            name: 'Months',
            label: 'Months',
        },
    ],
    groupings: [
        {
            name: 'customer',
            label: 'Project',
        },
        {
            name: 'user',
            label: 'User',
        },
        {
            name: 'priority',
            label: 'Priority',
        },
        {
            name: 'skill',
            label: 'Skill',
        },
    ],
    columns:
    [
        {
            name: "text",
            label: "Task",
            tree: true,
            width: 300,
            resize: true,
            defaultVisible: true,
            allowHiding: false,
        },
        {
            name: "users_name",
            label: "Owner",
            align: "left",
            width: 100,
            resize: true,
            defaultVisible: true,
            allowHiding: true,
        },
        {
            name: "pipeline_name",
            label: "Pipeline",
            align: "left",
            width: 100,
            resize: true,
            defaultVisible: false,
            allowHiding: true,
        },
        {
            name: "projects_allocated",
            label: "Projects allocated",
            align: "right",
            width: 70,
            resize: true,
            defaultVisible: true,
            allowHiding: true,
        },
        {
            name: "projects_allocated_all_time",
            label: "Projects allocated (All Time)",
            align: "right",
            width: 70,
            resize: true,
            defaultVisible: false,
            allowHiding: true,
        },
        {
            name: "hours",
            label: "Allocated",
            align: "right",
            width: 70,
            resize: true,
            defaultVisible: true,
            allowHiding: true,
        },
        {
            name: "hours_all_time",
            label: "Allocated (All Time)",
            align: "right",
            width: 70,
            resize: true,
            defaultVisible: false,
            allowHiding: true,
        },
        {
            name: "budgeted",
            label: "Tasked",
            align: "right",
            width: 70,
            resize: true,
            defaultVisible: true,
            allowHiding: true,
        },
        {
            name: "budgeted_all_time",
            label: "Tasked (All Time)",
            align: "right",
            width: 70,
            resize: true,
            defaultVisible: false,
            allowHiding: true,
        },
        {
            name: "hours_done",
            label: "Tracked",
            align: "right",
            width: 90,
            resize: true,
            defaultVisible: false,
            allowHiding: true,
        },
        {
            name: "hours_done_all_time",
            label: "Tracked (All Time)",
            align: "right",
            width: 90,
            resize: true,
            defaultVisible: false,
            allowHiding: true,
        },
        {
            name: "remaining",
            label: "Remaining",
            align: "right",
            width: 90,
            resize: true,
            defaultVisible: true,
            allowHiding: true,
        },
        {
            name: "remaining_all_time",
            label: "Remaining (All Time)",
            align: "right",
            width: 90,
            resize: true,
            defaultVisible: false,
            allowHiding: true,
        },
        {
            name: "priority", 
            label: "Priority",
            align: "center", 
            width: 70, 
            resize: true,
            defaultVisible: false,
            allowHiding: true,
        },
        {
            name: "skill",
            label: "Skill",
            align: "left",
            width: 70,
            resize: true,
            defaultVisible: false,
            allowHiding: true,
        },
        {
            name: "progress",
            label: "Progress",
            align: "left",
            width: 70,
            resize: true,
            defaultVisible: true,
            allowHiding: true,
        },
        {
            name: "add",
            label: 'Add',
            width: 40,
            resize: true,
            defaultVisible: true,
            allowHiding: true,
        }
    ]
}

export default viewConfiguration;