import React from "react";
import { Dialog, Button, Chip, Radio, Switch } from "@mui/material";
import {
  Close,
  AddCircleOutline,
  CancelOutlined,
  Clear,
  WarningRounded,
} from "@mui/icons-material";
import DataList from "../../general/DataList";
import OutlinedField from "../../general/OutlinedField";
import TaimerComponent from "../../TaimerComponent";
import colors from "../../colors";

import "./ProductBalanceWarningDialog.css";
import DataHandler from "../../general/DataHandler";
import TextInput from "../../general/TextInput";
import { withSnackbar } from 'notistack';

const CatalogDialog = (props) => {
    const { header,subheader, warning, children, buttons, onClose, stock, over, lower, than, warningData } = props;

    return (
      <Dialog
        PaperProps={{ className: "balance-warning-dialog-container" }}
        className="balance-warning-dialog"
        open={true}
      >
        <div className="header-bar">
          <div className="right">
            {warning && <WarningRounded className="icon" />}
            <h1>{header} <span className="subheader">{subheader}</span></h1>
          </div>
          <button onClick={onClose}>
            <Close />
          </button>
        </div>
        <div  className="dialog-content">
        <div className="space">
            <span className="dialog-content-text">{stock } <b>{over}</b> {than}</span>
            <TextInput name="stock_over_count" className="overTextInput" initValue={warningData.higher_warning_amount} onBlur={(e) => props.updateWarningData("higher_warning_amount", e.target.value)} />
            <Switch
                name="stock_over"
                color="primary"
                className="stock_switch"
                disableRipple={true}
                checked={warningData.has_higher_warning}
                onChange={(e) => props.updateWarningData("has_higher_warning", e.target.checked ? 1 : 0)}
            />
        </div>
        <div className="space">
            <span className="dialog-content-text">{stock} <b>{lower}</b> {than}</span>
            <TextInput name="stock_lower_count"  className="lowerTextInput" initValue={warningData.lower_warning_amount} onBlur={(e) => props.updateWarningData("lower_warning_amount", e.target.value)} />
            <Switch
                name="stock_lower"
                className="stock_switch"
                color="primary"
                disableRipple={true}
                checked={warningData.has_lower_warning}
                onChange={(e) => props.updateWarningData("has_lower_warning", e.target.checked ? 1 : 0)}
            />
        </div>

        </div>
       

        {buttons && (
          <div className="bottom-bar">
            {buttons.map((btn) => (
              <Button disabled={btn.disabled} onClick={btn.onClick} style={{ ...btn.style, opacity: btn.disabled ? 0.5 : 1 }}>
                {btn.title}
              </Button>
            ))}
          </div>
        )}
      </Dialog>
    );
  };

class ProductBalanceWarningDialog extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/dialogs/ProductBalanceWarningDialog");

    this.state = {
      warningData: {
        has_lower_warning: Number(props.product.has_lower_warning),
        has_higher_warning: Number(props.product.has_higher_warning),
        lower_warning_amount: Number(props.product.lower_warning_amount),
        higher_warning_amount: Number(props.product.higher_warning_amount)
      }
    };
  }

  componentDidMount = () => {
    super.componentDidMount();
  };

  updateWarningData = (name, value) => {
    this.setState(state => {
      state.warningData = {...state.warningData, [name]: value};
      return state;
    });  
  };

  saveBalanceWarning = () => {
    this.props.onClose();
    DataHandler.post({ url: `products/${this.props.product.id}/setbalancewarning` }, { ...this.state.warningData }).done(response => {
      this.props.getProductData();
      this.props.enqueueSnackbar(this.tr("Balance warning saved for product \"${product}\"!", { product: this.props.product.name }), {
        variant: "success",
      });
    });
  };

  render() {
    const { onClose, product } = this.props;

    return (
      <CatalogDialog
        onClose={onClose}
        buttons={[
          {
            title: this.tr("Cancel"),
            onClick: onClose,
            style: {
              backgroundColor: "white",
              color: colors.steel,
            },
          },
          {
            title: this.tr("Save"),
            onClick: () => this.saveBalanceWarning(),
            style: {
              backgroundColor: colors.deep_sky_blue,
              color: "white",
            },
          },
        ]}
        warningData={this.state.warningData}
        updateWarningData={this.updateWarningData}
        header={`${this.tr("Balance Warning")}`}
        subheader={product.name}
        stock={this.tr("Stock is")}
        over={this.tr("over")}
        lower={this.tr("lower")}
        than={this.tr("than")}
      >
       
      </CatalogDialog>
    );
  }
}

export default withSnackbar(ProductBalanceWarningDialog);