import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import CustomerList from '../../list/lists/CustomerList';
import ContactList from '../../list/lists/ContactList';
import UserList from '../../list/lists/UserList';
import VersionContentManager from '../../general/VersionContentManager';

class Contacts extends TaimerComponent<any> {
    tabs;
    constructor(props, context) {
        super(props, context, 'navigation/pages/Contacts');
        this.tabs = VersionContentManager.getViewTabs(this.namespace).map((tab) => ({ ...tab, label: this.tr(tab.label) }));
    }

    getAppcuesFlow = (mode) => {
        if (mode == 'accounts') {
            switch (this.context.versionId) {
                case 10:
                case 11:
                case 12:
                    return this.context.userObject.language == 'fi' ? 'cc0317cf-7371-4ef4-8879-f1d589672dba' : 'e2acfbdc-e843-4ff9-bea4-82be2445c907';
                default:
                    return this.context.userObject.language == 'fi' ? '365f0889-1a11-40a0-9d60-9ff87e9a2d28' : '22e99e7e-e5e4-4dbe-a480-ffa46fdc0290';
            }
        }
    };

    getTabInfoButtonProps = (selectedTab) => {
        switch (selectedTab) {
            case 'accounts':
                return {
                    title: this.tr('How Accounts works'),
                    methods: {
                        article:
                            this.context.userObject.language == 'fi' ? 'https://psahelpcenter.heeros.com/hc/fi/articles/8081419179282' : 'https://psahelpcenter.heeros.com/hc/en-us/articles/8081419179282',
                        flow: this.getAppcuesFlow('accounts'),
                    },
                };
            case 'contacts':
                return {
                    title: this.tr('How Contacts works'),
                    methods: {
                        article:
                            this.context.userObject.language == 'fi' ? 'https://psahelpcenter.heeros.com/hc/fi/articles/8079909569810' : 'https://psahelpcenter.heeros.com/hc/en-us/articles/8079909569810',
                    },
                };
            default:
                return undefined;
        }
    };

    render() {
        return (
            <WithTabs offsetTop={10} tabs={this.tabs} selectedTab={this.props.selectedTab} tabsAlwaysVisible getTabInfoButtonProps={this.getTabInfoButtonProps}>
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'accounts':
                            return <CustomerList {...this.props} />;
                        case 'contacts':
                            return <ContactList {...this.props} />;
                        case 'users':
                            return <UserList {...this.props} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default Contacts;
