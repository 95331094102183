import React, { Component } from 'react';
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { Button, MenuItem } from '@mui/material';
import Checkbox from '../../general/Checkbox';
import ContextMenu from '../../general/ContextMenu';
import DeleteIcon from '@mui/icons-material/Delete';
import MoveIcon from '@mui/icons-material/UnfoldMore';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import { IconButton } from '@mui/material';
import './CustomFields.css';
import TextInputCell from '../../list/cells/TextInputCell';
import _ from 'lodash';
import cn from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';

/* context */
import { SettingsContext } from '../../SettingsContext';
import AutoCompleteCell from '../../list/cells/AutoCompleteCell';

const styles = theme => ({
    input: {
        padding: '5px',
    },
    delete: {
        padding: '10px',
    }
});

class CustomFieldListDropdownOption extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/CustomFields");

        this.state = {
            dialogData: undefined
        };

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
    }

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    }

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    }

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    }

    onChange = (e) => {
        const { name: fname, value: row } = this.props;
        const { name, value } = e.target;

        this.props.onChange({
            target: {
                name,
                value: {
                    ...row,
                    [name]: value,
                },
            }
        })
    }

    onChangeName = (value) => {
        this.onChange({
            target: {
                name: "name",
                value: value
            }
        });
    }

    delete = () => {
        const { name, value: row } = this.props;

        this.props.onChange({
            target: {
                name,
                value: {
                    ...row,
                    deleted: 1,
                },
            }
        });
    }

    save = () => {
        const { name, value: row } = this.props;

        this.props.onSave({
            target: {
                name,
                value: {
                    ...row,
                },
            }
        });
    }

    isError = (f) => {
        return this.props.isError(`${this.props.name}.${f}`);
    }

    onDragStart = (e) => {
        e.dataTransfer.setData(`customfieldoption_${this.props.field.id}`, this.props.value.id);
        e.dataTransfer.effectAllowed = "move";
    }

    dragEnter = (e) => {
        if (e.dataTransfer.types.indexOf(`customfieldoption_${this.props.field.id}`) === -1)
            return;

        this.setState({ over: true });
        e.preventDefault();
    }

    dragLeave = (e) => {
        if (e.dataTransfer.types.indexOf(`customfieldoption_${this.props.field.id}`) === -1)
            return;

        this.setState({ over: false });
        e.preventDefault();
    }

    dragOver = (ev) => {
        ev.preventDefault();
    }

    drop = (e) => {
        e.preventDefault();

        var data = e.dataTransfer.getData(`customfieldoption_${this.props.field.id}`);
        this.setState({ over: false });

        this.props.changeOrder(data, this.props.value.optorder);
    }

    onChangeHubspotId = (value) => {
        this.onChange({
            target: {
                name: "hubspot_id",
                value: value
            }
        });
    }

    render = () => {
        const { form, value, classes, field } = this.props;
        const { over } = this.state;
        const { tr } = this;

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <React.Fragment>
                <tr className={cn("CustomFieldOptionRow", over && "drag-over")}
                    onDragEnter={this.dragEnter} onDragLeave={this.dragLeave} onDragOver={this.dragOver} onDrop={this.drop}>
                    <td className="moveIcon" draggable={value.id > 0} onDragStart={value.id > 0 && this.onDragStart || undefined}>
                        {value.id > 0 && <MoveIcon />}
                    </td>
                    <td className="deleteIcon">
                        {value.id > 0 && <IconButton
                            classes={{ root: classes.delete }}
                            onClick={() => {
                                this.setState({
                                    dialogData: {
                                        saveFunc: () => this.delete(),
                                        text: tr("Do you want to delete field entry") + ": " + value.name + "?"
                                    }
                                });
                                this.openDialog('confirmation');
                            }}
                            size="large"><DeleteIcon />
                        </IconButton>}
                        {value.id < 0 && field.id > 0 && <>
                            <Tooltip title={this.tr("Save")} placement="bottom">
                                <CheckCircle className="saveNewRowCheckCircleIcon" onClick={this.save} />
                            </Tooltip>
                            <Tooltip title={this.tr("Cancel")} placement="bottom">
                                <Cancel className="cancelIcon" onClick={this.delete} />
                            </Tooltip>
                        </>}
                    </td>
                    <td className="answerCell">{this.tr("Answer")}</td>
                    <td className="nameCell ellipsis">
                        <TextInputCell
                            listCellProps={{ permanentEditMode: value.id < 0 }}
                            width="100%" 
                            name="name" 
                            editable 
                            value={value.name} 
                            onEdited={this.onChangeName} />
                    </td>
                    <td className="test"></td>
                    <td colSpan={this.props.form ? 3 : 2}></td>
                    {this.context.addons.hubspot && <td className="nameCell">
                        <TextInputCell
                            listCellProps={{ permanentEditMode: value.id < 0 }}
                            //ref={this.refName}
                            width="100%"
                            //placeholder={this.tr("Enter Name")}
                            //placeholderOnEmpty
                            name="hubspot_id"
                            editable
                            value={value.hubspot_id}
                            onEdited={this.onChangeHubspotId} />
                    </td>}
                </tr>

                {Dialog && <Dialog
                    open
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData} />}
            </React.Fragment>
        );
    }
}

class CustomFieldListRow extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/CustomFields");

        this.state = {
            dialogData: undefined,
            over: false,
            newRows: [],
        };

        this.dialogs = {
            confirmation: ConfirmationDialog
        };

        this.refName = React.createRef();
    }

    onDragStart = (e) => {
        e.dataTransfer.setData("customfield", this.props.value.id);
        e.dataTransfer.effectAllowed = "move";
    }

    dragEnter = (e) => {
        if (e.dataTransfer.types.indexOf("customfield") === -1)
            return;

        this.setState({ over: true });
        e.preventDefault();
    }

    dragLeave = (e) => {
        if (e.dataTransfer.types.indexOf("customfield") === -1)
            return;

        this.setState({ over: false });
        e.preventDefault();
    }

    dragOver = (ev) => {
        ev.preventDefault();
    }

    drop = (e) => {
        e.preventDefault();

        var data = e.dataTransfer.getData("customfield");
        this.setState({ over: false });

        this.props.changeOrder(data, this.props.value.form_order);
    }

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    }

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    }

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    }

    onChange = (e) => {
        const { value: row } = this.props;
        const { name, value } = e.target;

        this.props.onChange({
            target: {
                name,
                value: {
                    ...row,
                    [name]: value,
                },
            }
        });
    }

    onChangeName = (value) => {
        this.onChange({
            target: {
                name: "name",
                value: value
            }
        });
    }

    onChangeHubspotId = (value) => {
        this.onChange({
            target: {
                name: "hubspot_id",
                value: value
            }
        });
    }

    onChangeState = (value) => {
        const { value: row } = this.props;

        this.props.onChange({
            target: {
                name: "projects_sales_states_id",
                value: {
                    ...row,
                    projects_sales_states_id: value.id,
                },
            }
        })
    }

    onChangeBool = (e) => {
        const { value: row } = this.props;
        const { name, checked } = e.target;

        this.props.onChange({
            target: {
                name,
                value: {
                    ...row,
                    [name]: checked ? 1 : 0,
                },
            }
        })
    }

    onChangeOption = (e) => {
        const { name, value } = e.target;
        const { value: { options } } = this.props;

        const row = options.findIndex(x => x.id === value.id);

        const newRows = [...options];
        newRows[row] = value;

        this.onChange({
            target: {
                name: "options",
                value: newRows
            }
        });
    }

    onChangeOptionNew = (e) => {
        const { name, value } = e.target;

        const row = this.state.newRows.findIndex(x => x.id === value.id);

        const newRows = [...this.state.newRows];

        if (!value.deleted) {
            newRows[row] = value;
        }
        else {
            newRows.splice(row, 1);
        }

        this.setState({ newRows });
    }

    onSave = (e) => {
        const { name, value } = e.target;

        const row = this.state.newRows.findIndex(x => x.id === value.id);

        const newRows = [...this.state.newRows];
        newRows.splice(row, 1);

        this.onChange({
            target: {
                name: "options",
                value: [...this.props.value.options, value]
            }
        });

        this.setState({ newRows });
    }

    onChangeOrder = (id, order) => {
        let options = [...this.props.value.options];

        for (const item of options) {
            if (item.id == id) {
                item.optorder = order;
            } else if (item.optorder >= order) {
                item.optorder = Math.max(item.optorder, order + 1);
            }
        }

        options = options.sort((a, b) => a.optorder - b.optorder);

        for (let index = 0; index < options.length; index++) {
            options[index].optorder = index;
        }

        this.onChange({
            target: {
                name: "options",
                value: options
            }
        });
    }

    componentDidMount() {
        super.componentDidMount();
        const { value } = this.props;

        if (value.id < 0)
             setTimeout(() => this.refName.current && this.refName.current.focusOnEditor(), 10);
    }

    newId = -3;

    addValue = () => {
        const { value: { options } } = this.props;

        const last = _.maxBy(options, 'optorder');
        const optorder = last ? last.optorder + 1 : 0;

        const newRows = [...this.state.newRows, {
            id: this.newId--,
            optorder,
            name:"",
            name: "",
            //name: this.tr("New Option"),
        }];

        this.setState({ newRows });

        // this.onChange({
        //     target: {
        //         name: "options",
        //         value: newRows
        //     }
        // });
    }

    addValueNew = () => {
        const { value: { options } } = this.props;

        const last = _.maxBy(options, 'optorder');
        const optorder = last ? last.optorder + 1 : 0;

        const newRows = [...options, {
            id: this.newId--,
            optorder,
           // name: this.tr("New Option"),
            name: "",
        }];

        this.onChange({
            target: {
                name: "options",
                value: newRows
            }
        });
    }

    save = () => {
        const { name, value: row } = this.props;

        this.props.onSave({
            target: {
                name,
                value: {
                    ...row,
                },
            }
        });
    }

    delete = () => {
        const { name, value: row } = this.props;

        this.props.onChange({
            target: {
                name,
                value: {
                    ...row,
                    deleted: 1,
                },
            }
        });
    }

    isError = (f) => {
        return this.props.isError(`${this.props.name}.${f}`);
    }

    renderDropdownValues = () => {
        const { form, value, classes } = this.props;
        const { newRows } = this.state;

        const allValues = [...value.options, ...newRows];

        return (<React.Fragment>
            {allValues.map(opt => !opt.deleted &&
                <CustomFieldListDropdownOption
                    form={form}
                    mode={this.props.mode}
                    field={value}
                    key={opt.id}
                    classes={classes}
                    value={opt}
                    onChange={value.id > 0 && opt.id < 0 ? this.onChangeOptionNew : this.onChangeOption}
                    onSave={this.onSave}
                    changeOrder={this.onChangeOrder} />)}

            <tr className="CustomFieldsListHeader">
                <td colSpan="3"></td>
                <td colSpan={this.props.mode === "account" ? "4" : "3"}><span className="addOption" onClick={value.id < 0 ? this.addValueNew : this.addValue}>{this.tr("Add Another")}</span></td>
            </tr>
        </React.Fragment>)
    }

    render() {

        const { classes, value, type, form, currentForm, stages } = this.props;
        const { over } = this.state;
        const { tr } = this;
        const { isNew } = this.props;

        let is_new = value.id < 0;

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <React.Fragment>
                <tr className={cn("customField", over && "drag-over")} key={value.id}
                    onDragEnter={this.dragEnter} onDragLeave={this.dragLeave} onDragOver={this.dragOver} onDrop={this.drop}>
                    <td className="moveIconWide" draggable onDragStart={this.onDragStart}>
                        <MoveIcon />
                    </td>
                    <td className="deleteIcon">
                        {!is_new && <IconButton
                            classes={{ root: classes.delete }}
                            onClick={() => {
                                this.setState({
                                    dialogData: {
                                        saveFunc: () => this.delete(),
                                        text: tr("Do you want to delete extra project field") + ": " + value.name + "?"
                                    }
                                });
                                this.openDialog('confirmation');
                            }}
                            size="large">
                            <DeleteIcon />
                        </IconButton>}
                        {is_new && <>
                            <Tooltip title={this.tr("Save")} placement="bottom">
                                <CheckCircle className="saveNewRowCheckCircleIcon" onClick={this.save} />
                            </Tooltip>
                            <Tooltip title={this.tr("Cancel")} placement="bottom">
                                <Cancel className="cancelIcon" onClick={this.delete} />
                            </Tooltip>
                        </>}
                    </td>
                    <td className="type">{type.name}</td>
                    <td className="nameCell ellipsis">
                        <TextInputCell
                            listCellProps={{ permanentEditMode: value.id < 0 }}
                            ref={this.refName}
                            width="100%"
                            placeholder={this.tr("Enter Name")}
                            placeholderOnEmpty
                            name="name"
                            editable
                            value={value.name}
                            onEdited={this.onChangeName} />
                    </td>
                    <td className="centered check">
                        <Checkbox
                            name="show_in_details"
                            checked={value.show_in_details}
                            onChange={this.onChangeBool} />
                    </td>
                    <td className="centered check">
                        <Checkbox
                            name="required"
                            checked={value.required}
                            onChange={this.onChangeBool} />
                    </td>
                    <td className="centered check">
                        <Checkbox
                            name="show_on_reports"
                            checked={value.show_on_reports}
                            onChange={this.onChangeBool} />
                    </td>
                    {this.context.addons.hubspot && <td className="nameCell">
                        <TextInputCell
                            listCellProps={{ permanentEditMode: value.id < 0 }}
                            //ref={this.refName}
                            width="100%"
                            //placeholder={this.tr("Enter Name")}
                            //placeholderOnEmpty
                            name="hubspot_id"
                            editable
                            value={value.hubspot_id}
                            onEdited={this.onChangeHubspotId} />
                    </td>}
                    {!!form && <td className="autocomplete stageCell">
                        <AutoCompleteCell
                            width="100%"
                            value={value.projects_sales_states_id}
                            autoCompleteData={[{ id: 0, name: tr('(none)') }, ...stages]}
                            onEdited={this.onChangeState}
                        />
                    </td>}
                </tr>
                {type.options && this.renderDropdownValues()}

                {Dialog && <Dialog
                    open
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData} />}
            </React.Fragment>
        );
    }
}

class CustomFieldsList extends TaimerComponent {
    isSaving = false;
    constructor(props, context) {
        super(props, context, "settings/pages/CustomFields");


        this.state = {
            newValue: [],
        }
    }

    raiseChange = (value, change) => {
        const { name } = this.props;

        this.props.onChange({
            target: {
                name,
                value
            }
        }, change);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.value, this.props.value)) {
            this.setState({ newValue: this.state.newValue.filter(x => !x.saving) });
        }
    }

    newId = -1;
    newOrder = -1;

    addNew = (type, info) => {
        const { form, forms, value } = this.props;
        const { newValue: list } = this.state;

        let form_order = 1;

        // if (form) {
        const formCurrent = value.filter(x => x.form == form);
        const formNew = list.filter(x => x.form == form);

        const last = _.maxBy(formCurrent, 'form_order');
        const lastNew = _.maxBy(formNew, 'form_order');

        if (lastNew) {
            form_order = lastNew.form_order + 1;
        } else if (last) {
            form_order = last.form_order + 1;
        }
        // }

        const newValue = [...list, {
            id: this.newId--,
            new: true,
            //name: this.tr("New Field"),
            name: "",
            type,
            options: info.options ? [
                {
                    id: -1,
                    optorder: 1,
                    name: "",
                   // name: this.tr("New Option"),
                },
                {
                    id: -2,
                    optorder: 2,
                    name: "",
                    //name: this.tr("New Option"),
                }
            ] : [],
            form,
            show_in_details: form ? 0 : 1,
            form_order,
        }];

        this.newOrder = form_order;

        const { value: rows = [] } = this.props;

        // this.raiseChange([...rows, ...newValue]);

        this.setState({ newValue })
    }

    onChange = (e) => {
        const { name, value } = e.target;
        const { value: rows = [] } = this.props;

        const row = rows.findIndex(x => x.id === value.id);

        const newRows = [...rows];
        newRows[row] = value;

        this.raiseChange(newRows, name !== "options" ? [value] : undefined);
    }

    onChangeNew = (e) => {
        const { name, value } = e.target;
        const { value: currentRows = [] } = this.props;
        const { newValue: rows = [] } = this.state;

        // if (value.saving) {
        //     return;
        // }

        const row = rows.findIndex(x => x.id === value.id);

        const newValue = [...rows];
        newValue[row] = value;

        if (value.name) {
            // newValue[row].saving = true;

            // newValue.splice(row, 1);

            //this.props.onCreateNew(value);
        }
        else if (value.deleted) {
            newValue.splice(row, 1);
        }

        this.setState({ newValue });
    }

    onSaveNew = (e) => {
        const { name, value } = e.target;
        const { newValue: rows = [] } = this.state;

        const index = rows.findIndex(x => x.id === value.id);

        const newValue = [...rows];
        // newValue[row] = value;
        newValue.splice(index, 1);

        this.setState({ newValue });

        this.raiseChange([...this.props.value, value]);
    }

    changeOrder = (id, order) => {
        const value = [...this.props.value];
        let newValue = [...this.state.newValue];
        const { form } = this.props;

        for (const item of value) {
            if (item.form != form)
                continue;

            if (item.id == id) {
                item.form_order = order;
            } else if (item.form_order >= order) {
                item.form_order++;
            }
        }

        for (const item of newValue) {
            if (item.form != form)
                continue;

            if (item.id == id) {
                item.form_order = order;
            } else if (item.form_order >= order) {
                item.form_order++;
            }
        }

        newValue = newValue.sort((a, b) => a.form_order - b.form_order);

        for (let index = 0; index < newValue.length; index++) {
            newValue[index].form_order = index;
        }

        this.setState({ newValue });
        this.raiseChange(value);
    }

    render() {
        const { versionId, folder } = this.context;
        const { classes, value, form, forms, stages, mode = 'project' } = this.props;
        const { newValue } = this.state;
        const { tr } = this;

        const types = {
            text: { name: tr('Text') },
            textarea: { name: tr('Textarea') },
            dropdown: { name: tr('Dropdown'), options: true },
            date: { name: tr('Date') },
            // daterange: {name: tr('Daterange')},
            radio: { name: tr('Radio'), options: true },
            multiselect: { name: tr('Multiselect'), options: true },
            link: { name: tr('Link') },
        };

        const sorted = _.sortBy([
            ...value,
            ...newValue,
        ].filter(x => !x.deleted && x.form == form), 'form_order');

        const currentForm = form > 0 && forms.find(x => x.id == form);

        const button = {
            color: 'green',
            className: 'green',
            stickyIcon: true,
            size: "large"
        }

        return <div className="full">
            <ContextMenu buttonProps={button} variant="outlined" className="option-menu" label={this.tr("Add Field")} size="large">
                {_.map(types, (v, type) =>
                    <MenuItem key={type} onClick={this.addNew.bind(this, type, v)}>{v.name}</MenuItem>
                )}
            </ContextMenu>
            <table className="CustomFieldsList">
                <tbody>
                {sorted.length > 0 && <tr>
                        <th className="moveIconWide"></th>
                        <th className="deleteIcon"></th>
                        <th className="typeCell">{this.tr("Type")}</th>
                        <th className="nameCell">{this.tr("Field Name")}</th>
                        <th className="checkDouble">{mode == 'account' ? this.tr("Show in account's details & list") : this.tr("Show in project's details & list")}</th>
                        <th className="check">{this.tr("Required")}</th>
                        <th className="check">{this.tr("Show on reports")}</th>
                        {this.context.addons.hubspot && <th className="nameCell">{this.tr("Hubspot ID")}</th>}
                        {!!form && <th className="autocomplete">{this.tr("Stage")}</th>}
                    </tr>
                }
                    {
                        sorted.map((x, i) => <CustomFieldListRow
                            key={x.id}
                            mode={this.props.mode}
                            type={types[x.type]}
                            classes={classes}
                            name={`custom.${x.id}`}
                            isError={this.props.isError}
                            isNew={this.newOrder == x.form_order}
                            key={x.id}
                            value={x}
                            form={form}
                            changeOrder={this.changeOrder}
                            onChange={x.new ? this.onChangeNew : this.onChange}
                            currentForm={currentForm}
                            onSave={this.onSaveNew}
                            stages={stages} />)}
                    {sorted.length === 0 &&
                        <tr className="CustomFieldsListHeader">
                            <td colspan="6">
                                <div className="noFieldsMessage">{tr('There are no fields yet')}.</div>
                            </td>
                        </tr>}

                </tbody>
            </table>
        </div>
    }
}

export default withStyles(styles)(CustomFieldsList);
