import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import DataHandler from '../../general/DataHandler';
import Button from '@mui/material/Button';
import "../Settings.css";
import "./Pipeline.css";

import { FormControlLabel, Switch, TextField, MenuItem } from '@mui/material';
import OutlinedField from "../../general/OutlinedField";
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MassDialog from '../../dialogs/mass_operations/CoreDialog';

import TrashCanIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";

import ContextMenu from '../../general/ContextMenu';
import CustomFields from './CustomFields';
import ProjectForms from './ProjectForms';

import _ from 'lodash';
import { formatInputNumber } from '../../helpers';

class SmallInput extends TaimerComponent {
    static defaultProps = {
        value: "",
        unit: "",
        focus: false,
        onEdit: () => { },
        onTab: () => { }
    };


    constructor(props, context) {
        super(props, context, "settings/pages/Pipeline");

        this.input = React.createRef();

        this.state = {
            editMode: false,
            value: props.value,
        };

        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        if (!prevState.editMode && this.state.editMode)
            setTimeout(() => { this.input.current.select() }, 0);
        else if (prevState.editMode && !this.state.editMode)
            return;

        if (prevProps.value === this.props.value && prevProps.unit === this.props.unit && prevProps.focus === this.props.focus && prevState.editMode === this.state.editMode)
            return;

        this.setState({ value: this.props.value });
    }


    toggleEditMode(which = undefined) {
        if (which === this.state.editMode)
            return;

        const newEditMode = which === undefined ? !this.state.editMode : which;

        this.setState({ editMode: newEditMode });

        if (newEditMode)
            setTimeout(() => this.input.current.focus());
        else
            this.props.onEdit(this.state.value)
    }


    handleKeyDown(e) {
        if (e.key === "Enter" || e.key === "Tab")
            this.toggleEditMode(false);

        if (e.key === "Tab")
            this.props.onTab(e);
    }


    render() {
        const editMode = this.state.editMode;
        const displayValue = `${this.props.value}${this.props.unit}`;

        return (
            <ClickAwayListener onClickAway={(e) => {
                if (!this.state.editMode || e.target === this.input.current)
                    return;

                this.toggleEditMode(false);
            }}>
                <div>
                    {editMode && <input type="text" ref={this.input} value={this.state.value} onKeyDown={this.handleKeyDown} onChange={e => this.setState({ value: e.target.value })} className="pipeline-stage-input" />}
                    {!editMode && <p onClick={this.preventPropagation} onClick={e => this.toggleEditMode(true)}>{displayValue}</p>}
                </div>
            </ClickAwayListener>
        );
    }
}

class StageIndicator extends TaimerComponent {
    static defaultProps = {
        onMouseDown: () => { },
        onMouseUp: () => { },
        onEditName: () => { },
        onEditPercentage: () => { },
        onDelete: () => { },
        button: false,
        first: false,
        last: false,
        tabIndex: "0"
    };


    constructor(props, context) {
        super(props, context, "settings/pages/Pipeline");

        this.state = {
            name: props.name,
            percentage: props.percentage,
            focusNameInput: false
        };
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.name === this.props.name && prevProps.percentage === this.props.percentage)
            return;

        this.setState({
            name: this.props.name,
            percentage: this.props.percentage
        });
    }


    render() {
        const inputs = (
            <React.Fragment>
                <SmallInput onEdit={value => this.props.onEditName(value)} value={this.state.name} />
                <SmallInput onEdit={value => this.props.onEditPercentage(value)} value={`${formatInputNumber(this.state.percentage, 'no-zero-decimals')}`} unit="%" />
            </React.Fragment>
        );

        const contClassName = this.state.hidden ? "hidden" : "";
        const className = this.props.first ? "first" : this.props.last ? "last" : "mid";
        const { tr } = this;
        return (
            <div
                className={`pipeline-stage-container ${contClassName}`}
            // onFocus={e => this.setState({ focusNameInput: true })}
            // onBlur={e => this.setState({ focusNameInput: false })}
            // tabIndex={this.props.tabIndex}
            >
                <div onMouseDown={this.props.onMouseDown} onMouseUp={this.props.onMouseUp} className={`pipeline-stage ${className}`}>
                    {!this.props.button && inputs}
                    {this.props.button && <div className="addNewButton"><div className="plus">+</div> {tr("New Stage")}</div>}
                    {(!this.props.button || !this.props.last) && <div className="right-arrow"></div>}
                    {(!this.props.button || !this.props.last) && <div className="right-arrow white"></div>}
                </div>
                {!this.props.button && <div className="deleteContainer" onClick={this.props.onDelete}><TrashCanIcon onClick={this.props.onDelete} style={{ color: "#d0021b", height: "16px" }} /> {tr("Delete Stage")}</div>}
            </div>
        );
    }
}


class Pipeline extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/Pipeline");

        this.pipelineNameInput = React.createRef();

        this.state = {
            pipelines: [],
            allStages: [],
            stages: [],
            stagesForm: [],
            selectedPipelineId: undefined,
            selectedPipelineIdForm: undefined,
            defaultPipelineId: undefined,
            lastStageDialogOpen: false,
            deletePipelineDialogOpen: false,
            selectedForm: 0,
            customFields: [],
            projectForms: [],
            currentDialogProps: undefined,
            initialized:false,
        };
        this.dialogs = {
            delete: MassDialog
        };

        this.fetchPipelines = this.fetchPipelines.bind(this);
        this.setPipelineName = this.setPipelineName.bind(this);
        this.addNewPipeline = this.addNewPipeline.bind(this);
        this.addNewStage = this.addNewStage.bind(this);
        this.deletePipeline = this.deletePipeline.bind(this);
        this.deleteStage = this.deleteStage.bind(this);
        this.stageDeletecloseDialog = this.stageDeletecloseDialog.bind(this);
        this.onDeleteStageClick  = this.onDeleteStageClick.bind(this);


        this.stageDeleteDialogProps = () => ({
            delete:{
                dialogType: "delete",
                open: this.state.currentDialog === "delete",
                close: this.stageDeletecloseDialog,
                dialogProps: {
                    header: this.tr("Are you sure") + "?",
                    warning: () => {
                        return this.tr("Are you sure you want to delete this pipeline stage? the stage will be permanently deleted.");
                    },
                    close: this.stageDeletecloseDialog,
                    onConfirm: () => {
                      let stage_id =this.state.dialogData;
                       this.deleteStage(stage_id);
                       this.stageDeletecloseDialog();
                    }
                }
           },
        });
        this.dialogProps = this.stageDeleteDialogProps();
    }
  
    openStageDeleteDialog(name, data = undefined) {
        this.setState({ 
            currentDialogProps: { 
                ...(this.dialogProps.hasOwnProperty(name) ? this.dialogProps[name].dialogProps : {}), 
                ...(typeof(data) === "object" ? data : {}) 
            }, 
            currentDialog: name,
            dialogData: data
        });
    }


    stageDeletecloseDialog(cb = () => {}) {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog(saveFunc, id) {
        this.stageDeletecloseDialog();
    }
    onDeleteStageClick(id) {
        this.openStageDeleteDialog("delete", id);
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateComponentData();
    }
    updateComponentData = () => {
        this.fetchPipelines();
        this.updateCustomFields();
        this.updateProjectForms();
    }
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    fetchPipelines(refresh = false, newPipeline = undefined) {
        const { company } = this.props;
        const { selectedPipelineId, selectedPipelineIdForm, selectedForm } = this.state;
        
        return DataHandler.get({url: `settings/company/${company}/pipelines`, refresh: refresh ? 1 : 0}).done(({ pipelines, stages, default_pipeline_id }) => {
            pipelines.push({
                id: -1,
                name: this.tr('Won deals'),
                default: default_pipeline_id == -1,
            });
            pipelines.push({
                id: -5,
                name: this.tr('Internal projects'),
                default: default_pipeline_id == -5,
            });
        
            const currentPipeline = pipelines.find(p => p.id == (newPipeline ?? selectedPipelineId)) ?? pipelines[0];
            const currentPipelineForm = pipelines.find(p => p.id == selectedPipelineIdForm) ?? pipelines[0];            

            const defaultPipeline = pipelines.find(p => p.default);

            const { projectForms } = this.state;
            const pipelineForm = projectForms.find(x => x.id === selectedForm) ?? projectForms.find(x => x.projects_pipelines_id == currentPipelineForm?.id);

            this.setState({
                initialized: true,
                pipelines,
                allStages: stages,
                selectedPipelineId: currentPipeline?.id,
                stages: this.filterStages(stages, currentPipeline?.id),
                selectedPipelineIdForm: currentPipelineForm?.id,
                selectedForm: pipelineForm?.id,
                stagesForm: this.filterStages(stages, currentPipelineForm?.id),
                defaultPipelineId: (defaultPipeline && defaultPipeline.id) || undefined
            });
        });
    }

    filterStages(allStages, pipelineId) {
        return allStages.filter(x => pipelineId > 0 ? x.projects_pipelines_id == pipelineId : x.project_type == Math.abs(pipelineId));
    }

    setPipelineName = _.debounce(async (name, id) => {
        const res = await this.savePipelineChanges(id, {name});
        if (res) {
            this.setState({ editPipelineName: false });
        }
    }, 200);

    changePipelineSetting = (e) => {
        const { selectedPipelineId } = this.state;

        this.savePipelineChanges(selectedPipelineId, {
            [e.target.name]: e.target.checked ? 1 : 0,
        });
    }

    savePipelineChanges = async (id, update) => {
        const { company, enqueueSnackbar } = this.props;

        this.setState({
            pipelines: this.state.pipelines.map(p => {
                if (p.id == id) {
                    return { ...p, ...update };
                }

                return p;
            })
        });

        if (id < 0) {
            update.stage_name = this.tr("Name Stage");
        }

        const response = await DataHandler.post({url: `settings/company/${company}/pipelines/${id}`}, update);
           
        if (update.name) {
            if (response.Pipeline_exist == "true") {
                enqueueSnackbar(this.tr('Pipeline ' + "\"" + update.name + "\"" + ' already exists'), {
                    variant: "error",
                });

                return false;
            }
        }

        if (id < 0) {
            this.fetchPipelines(true, response.id);
        } else {
            this.fetchPipelines(true);
        }

        return true;
    }

    saveStageChanges = async (id, update) => {
        const { company, enqueueSnackbar } = this.props;
        const { pipelines, allStages, selectedPipelineId } = this.state;

        const currentPipeline = pipelines.find(p => p.id == selectedPipelineId);

        const updateStages = allStages.map( s => {
            if (s.id === id) {
                return {...s, ...update};
            }

            return s;
        });

        this.setState({
            allStages: updateStages,
            stages: this.filterStages(updateStages, currentPipeline?.id),
        });

        const response = await DataHandler.post({url: `settings/company/${company}/stages/${id}`}, update);
    }

    addNewPipeline() {
        const { company } = this.props;

        // -1 is Won Deals
        this.setState({
            pipelines: [{ id: -2, name: "" }].concat(this.state.pipelines),
            selectedPipelineId: -2,
            stages: [{ name: this.tr("Name Stage"), percentage: 0, projects_pipelines_id: -1, companies_id: company }],
            editPipelineName: true
        });
    }

    async addNewStage() {
        const { company } = this.props;
        
        await DataHandler.post({url: `settings/company/${company}/stages/-1`}, {
            name: this.tr("New Stage"),
            percentage: 0,
            projects_pipelines_id: this.state.selectedPipelineId,
            companies_id: company
        });
        
        this.fetchPipelines(true);
    }

    async deletePipeline(pipelineId) {
        const { company, enqueueSnackbar } = this.props;

        try {
            const response = await DataHandler.delete({url: `settings/company/${company}/pipelines/${pipelineId}`});
        } catch (error) {
            if (error.status === 422) {
                enqueueSnackbar(this.tr('Pipeline delete failed because it has projects.'), {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(this.tr('Pipeline delete failed'), {
                    variant: "error",
                });
            }
        }

        this.fetchPipelines(true);
    }

    async deleteStage(id) {
        const { company } = this.props;

        try {
            const response = await DataHandler.delete({url: `settings/company/${company}/stages/${id}`});
            
            this.fetchPipelines(true);
        } catch (error) {
            if (error.status === 409) {
                switch (error.responseJSON.status) {
                    case "NOT_DELETING_LAST_STAGE":
                        this.setState({ lastStageDialogOpen: true });
                        break;

                    case "NOT_DELETING_FIRST_STAGE": // Not in use
                        alert("/* TODO: what to do when the user attempts to delete the left-most stage? */");
                        break;
                };
            }
        }
    }

    onFormsChaged = () => {
        this.updateCustomFields();
        this.updateProjectForms();
    }

    updateProjectForms = (callback) => {
        const { company } = this.props;

        return DataHandler.get({ url: `settings/company/${company}/project/forms` })
            .then((projectForms) => {
                const { selectedPipelineIdForm } = this.state;

                const pipelineForm = projectForms.find(x => x.projects_pipelines_id == selectedPipelineIdForm)

                const selectedForm = this.state.selectedForm || (pipelineForm ? pipelineForm.id : 0)

                this.setState({
                    projectForms,
                    selectedForm,
                }, callback)
            });
    }

    updateCustomFields = () => {
        const { company } = this.props;

        return DataHandler.get({ url: `settings/company/${company}/project/customfields` }).then(customFields => {
            this.setState({ customFields });
        });
    }

    createCustomField = async (field) => {
        const { company } = this.props;

        const customFields = await DataHandler.post({ url: `settings/company/${company}/project/customfields` }, { customFields: [field] });

        if (Array.isArray(customFields))
            this.setState({ customFields });
    }

    onChangeCustomFields = async (e, change) => {
        const { company } = this.props;

        const { target: { value } } = e;

        this.setState({ customFields: value })

        const customFields = await DataHandler.post({ url: `settings/company/${company}/project/customfields` }, { customFields: change || value });

        if (!change && Array.isArray(customFields))
            this.setState({ customFields });
    }

    changePipeline = (e) => {
        const { allStages } = this.state;

        this.setState({ selectedPipelineId: e.target.value, stages: this.filterStages(allStages, e.target.value) })
    }

    changePipelineForm = (e) => {
        const { projectForms, allStages } = this.state;

        const pipelineForm = projectForms.find(x => x.projects_pipelines_id == e.target.value);

        this.setState({ selectedPipelineIdForm: e.target.value, selectedForm: pipelineForm ? pipelineForm.id : 0, stagesForm: this.filterStages(allStages, e.target.value) })
    }

    onChangeForm = (e) => {
        this.setState({ selectedForm: e })
    }

    render() {
        const { tr } = this;
        const { versionId, folder } = this.context;
        const { pipelines, stagesForm, projectForms, selectedForm, selectedPipelineId, selectedPipelineIdForm, initialized } = this.state;
        const { company } = this.props;
        const loaderStyle = {
            width: 60,
            height: 60,
            padding: 20,
          };

        const PipelineStageDeleteDialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        const stagesForForm = stagesForm.map((stage) => {
            return {
                ...stage,
                id: parseInt(stage.id),
                label: stage.name,
                value: parseInt(stage.id),
            }
        });

        const currentPipeline = this.state.pipelines.find(p => p.id == this.state.selectedPipelineId);
        const isPipeline = currentPipeline && currentPipeline.id > 0;

        return (
            <React.Fragment>
                {!this.state.initialized && 
                    <div> 
                      <img style={loaderStyle} src={require("../img/loading.svg").default}/>
                    </div>
                }
                {this.state.initialized && 
                 <React.Fragment>
                        <h3>{tr("Pipeline stages customisation")}</h3>
                        <p className="subtitle">
                            {tr("Here you can define your sales process and manage your Pipeline stages.")}<br />
                            {tr("You can even create stages for your internal Projects and Won Deals, this gives you total flexibility to arrange and segment your leads and projects throughout all statuses.")}<br /><br />
                            {tr("You can even create multiple Sales Pipelines for your different sales agents, teams and departments.")}
                        </p>

                        <React.Fragment>
                            <Button className="green" onMouseUp={() => this.addNewPipeline()} size="large">{tr("Add pipeline")}</Button>

                            <div style={{ clear: "both", height: 18 }}></div>

                            {this.state.editPipelineName && <OutlinedField
                                ref={this.pipelineNameInput}
                                autoFocus={true}
                                placeholder={this.tr("Name pipeline")}
                                style={{ width: 230, float: "left", height: 54, marginTop: 20 }}
                                value={currentPipeline?.name}
                                onChange={e => {
                                    e.target.value !== "" && this.setPipelineName(e.target.value, this.state.selectedPipelineId);
                                }}
                                onKeyUp={e => {
                                    if (e.key === "Escape" || e.key === "Enter")
                                        this.setState({ editPipelineName: false });

                                    if (e.key === "Enter")
                                        e.target.value !== "" && this.setPipelineName(e.target.value, this.state.selectedPipelineId);
                                }}
                                label={tr("Pipeline name")} />
                            }
                            {!this.state.editPipelineName && <OutlinedField
                                select
                                style={{ width: 230, float: "left" }}
                                className="select"
                                label={tr("Pipeline")}
                                value={this.state.selectedPipelineId}
                                onChange={this.changePipeline}
                                margin="normal"
                                variant="filled">
                                {this.state.pipelines.map(pipeline => (
                                    <MenuItem key={pipeline.id} value={pipeline.id}>
                                        {pipeline.name}
                                    </MenuItem>
                                ))}
                            </OutlinedField>}
                            {currentPipeline && isPipeline && <ContextMenu label={<MoreHorizIcon />} className="cell row-menu" style={{ marginTop: 12, height: 54, width: 34, float: "left" }} noExpandIcon>
                                <MenuItem onClick={() => this.setState({ editPipelineName: true })}><EditIcon style={{ color: "#6b7897", marginRight: 16 }} /> {tr("Edit pipeline name")}</MenuItem>
                                {this.state.defaultPipelineId != this.state.selectedPipelineId && <MenuItem className="delete" style={{ color: "#dc4054" }} onClick={() => this.setState({ deletePipelineDialogOpen: true })}><RemoveIcon className="Delete" style={{ color: "#dc4054", marginRight: 16 }} /> {tr("Delete pipeline")}</MenuItem>}
                            </ContextMenu>}
                        </React.Fragment>

                        <div style={{ clear: "both", height: 18 }}></div>

                        {currentPipeline && isPipeline && <div className="pipeline_settings">
                            <FormControlLabel
                                value="skip"
                                control={
                                    <Switch name="mark_non_billable" checked={currentPipeline.mark_non_billable} onChange={this.changePipelineSetting} color="primary" />
                                }
                                label={this.tr("Mark non-billable when moved to this pipeline")}
                                labelPlacement="end"
                            />
                        </div>}

                        <React.Fragment>
                            {this.state.stages.sort((a, b) => a.stateorder - b.stateorder).map((stage, index) => {
                                let props = {
                                    first: index === 0,
                                    last: false,
                                    name: stage.name,
                                    percentage: stage.percentage,
                                    //onDelete: () => this.deleteStage(stage.id),
                                    onDelete: () => this.onDeleteStageClick(stage.id),
                                    onEditName: newName => {
                                        this.saveStageChanges(stage.id, {
                                            name: newName,
                                        });
                                    },
                                    onEditPercentage: newPercentage => {
                                        this.saveStageChanges(stage.id, {
                                            percentage: newPercentage,
                                        });
                                    },
                                };

                                return <StageIndicator {...props} />;
                            })}

                            <StageIndicator onMouseUp={this.addNewStage} last={true} button={true} />
                        </React.Fragment>

                        <div style={{ clear: "both"}}></div>

                    <div className ="settings-feature-wrapper">
                        <React.Fragment>
                                <h3>{tr("Default Pipeline")}</h3>
                                <p className="subtitle">{tr("Define default pipeline when adding a new lead.")}</p>

                                <OutlinedField
                                    select
                                    label={tr("Default Pipeline")}
                                    style={{ width: 230, float: "left", height: 54 }}
                                    value={this.state.defaultPipelineId}
                                    onChange={async e => {
                                        const pipelineId = e.target.value;

                                        if (pipelineId == this.state.defaultPipelineId)
                                            return;

                                        this.setState({
                                            defaultPipelineId: pipelineId
                                        });

                                        const resp = await DataHandler.post({url: `settings/company/${company}/pipelines/default`}, {id: pipelineId});

                                        if (resp.default_pipeline == pipelineId)
                                            return;

                                        this.setState({ defaultPipelineId: resp.default_pipeline });
                                    }}>
                                    {this.state.pipelines.map(pipeline => (
                                        <MenuItem key={pipeline.id} value={pipeline.id}>
                                            {pipeline.name}
                                        </MenuItem>
                                    ))}
                                </OutlinedField>
                            </React.Fragment>
                        </div>

                    
                        <div style={{ clear: "both"}}></div> 

                        <div className ="settings-feature-wrapper">

                            <h3>{this.tr("Form Builder to collect project data")}</h3>
                            <p className="subtitle">
                                {this.tr("Create customised Forms to be shown on the project card. You can define fields mandatory and target to specific stages in your Pipeline.")}<br />
                                {this.tr("Also make dedicated fields visible in the project list as separate columns, great for reporting purposes and quick follow up.")}<br />
                                {this.tr("You can choose what kind of field you want your team to fill in: Textbox, textarea, dropdown, date, radio and multi-select.")}<br />
                                <br />
                                {this.tr("Step 1: Choose Pipeline where to add a custom Form")}<br />
                                {this.tr("Step 2: Give your Form a name")}<br />
                                {this.tr("Step 3: Add fields")}<br />
                                <br />
                                <i>{this.tr("Tip: you can add multiple Forms to same Pipeline")}</i><br />
                            </p>
                            <ProjectForms
                                forms={this.state.projectForms}
                                selected={this.state.selectedForm}
                                onChangeForm={this.onChangeForm}
                                onUpdated={this.onFormsChaged}
                                pipeline={selectedPipelineIdForm}
                                pipelines={pipelines}
                                company={company}>

                                <OutlinedField
                                    select
                                    style={{ width: 230, marginRight: 5 }}
                                    className="select"
                                    label={this.tr("Pipeline")}
                                    value={selectedPipelineIdForm}
                                    onChange={this.changePipelineForm}
                                    margin="normal"
                                    variant="filled">
                                    {this.state.pipelines.map(pipeline => (
                                        <MenuItem key={pipeline.id} value={pipeline.id}>
                                            {pipeline.name}
                                        </MenuItem>
                                    ))}
                                </OutlinedField>
                            </ProjectForms>

                            {selectedForm > 0 && <CustomFields
                                key={selectedForm}
                                name="customFields"
                                value={this.state.customFields}
                                onChange={this.onChangeCustomFields}
                                onCreateNew={this.createCustomField}
                                form={selectedForm}
                                forms={projectForms}
                                pipelines={pipelines}
                                stages={stagesForForm} />}
                        </div>
                        </React.Fragment>
                    }

                        <Dialog
                            open={this.state.lastStageDialogOpen}
                            onClose={() => this.setState({ lastStageDialogOpen: false })}>
                            <DialogTitle><WarningIcon style={{ marginRight: 16 }} />{tr(" Unable to delete last pipeline stage")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {tr("You can not delete the last stage, since you still have active projects in it. Please archive the projects or move the projects to a different pipeline.")}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({ lastStageDialogOpen: false })} color="primary">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.deletePipelineDialogOpen}
                            onClose={() => this.setState({ deletePipelineDialogOpen: false })}>
                            <DialogTitle><WarningIcon style={{ marginRight: 16 }} /> {this.tr("Delete Pipeline?")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {tr("Are you sure you want to delete")} {(this.state.pipelines.find(p => p.id === this.state.selectedPipelineId) || { name: "" }).name}?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({ deletePipelineDialogOpen: false })} variant="outlined" className="white">
                                    {tr("CANCEL")}
                                </Button>
                                <Button onClick={() => {
                                    this.deletePipeline(this.state.selectedPipelineId);
                                    this.setState({ deletePipelineDialogOpen: false });
                                }} variant="outlined" className="red">
                                    {tr("DELETE")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                
                        {PipelineStageDeleteDialog && <PipelineStageDeleteDialog
                            open
                            onDialogClose={this.stageDeletecloseDialog}
                            onDialogSave={this.confirmDialog}
                            data={this.state.dialogData}
                            dialogType={this.dialogProps[this.state.currentDialog].dialogType}
                            dialogProps={{
                                ...this.state.currentDialogProps
                            }}
                        />}

    
                    </React.Fragment>
                        
    
            
        );
    }
}

export default Pipeline;
