import React from 'react';

/* css */
import './BillsList.css';

/* material-ui */
import { MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Portal } from '@mui/base';

/* others */
import List from "../List";
import AdvancedSearch from "../../search/AdvancedSearch";
import DataHandler from "../../general/DataHandler";
import OutlinedField from "../../general/OutlinedField";
import Utils from "./../../general/Utils.js";
import BillAssign from "../dialogs/BillAssign";
import TalenomCirculation from "../dialogs/TalenomCirculation";
import BillsListRow from '../rows/BillsListRow';
import FileSaver from 'file-saver';
import { DateRangePicker } from './../../general/react-date-range/src';
import { format } from "date-fns";

import AttachmentDialog from "../dialogs/HandleRowAttachments";
import ConfirmSuccessDialog from "../../dialogs/ConfirmSuccessDialog";
import { SettingsContext } from '../../SettingsContext';
import TaimerComponent from '../../TaimerComponent';
import _ from 'lodash';

/* css */
import "./BillsList.css";
import BillListOverlay from '../overlays/BillListOverlay';

class BillsList extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
        perpage: 30,
        showLockedUsersWithTag: true,
        onCheck: () => {}
    };

    constructor(props, context) {
        super(props, context, 'list/lists/BillsList');

        this.context = context;
        this.checked = [];
		this.stickySearchKey = "bills_list"

		const startDate = this.props.start ? format(new Date(this.props.start), "YYYY-MM-DD") : undefined;
        const endDate = this.props.end ? format(new Date(this.props.end), "YYYY-MM-DD") : undefined;

        const initialCompany = this.props.companies_id ? this.props.companies_id : (context.functions.getCompany("receivedinvoices", "approve", false, true) || context.functions.getCompany("receivedinvoices", "pre_approve", false, true));
        
        this.state = {
            bills: [],
            has_bills: true,
            count: 0,
            pages: 0,
            page: 1,
            perpage: props.perpage, 
            creditedDateSpan: { 
                startDate: undefined, 
                endDate:  undefined,
                key: "selection" 
            },
            voucherDateSpan: { 
                startDate: undefined, 
                endDate: undefined,
                key: "selection"
            },
            dueDateSpan: { 
                startDate: startDate, 
                endDate: endDate,
                key: "selection"
            },
            filters: { state: props.state || props.state === 0 ? props.state.toString() : undefined },
            attachmentDialogOpen: false, 
            attachmentBillId: undefined,
            company: initialCompany,
            companies: [],
            archiveDialogOpen: false,
            sendingInProgress: false,
            snackBarKey: undefined,
            companyCurrency: "EUR",
            stickySearchInitialized: false
        };

        this.filtersInitialValues = {
            company: initialCompany,
			creditedDateSpan: { 
                startDate: undefined, 
                endDate:  undefined,
                key: "selection" 
            },
            voucherDateSpan: { 
                startDate: undefined, 
                endDate:  undefined,
                key: "selection" 
            },
            dueDateSpan: { 
                startDate: this.props.start ? format(new Date(this.props.start), "YYYY-MM-DD") : undefined, 
                endDate: this.props.end ? format(new Date(this.props.end), "YYYY-MM-DD") : undefined,
                key: "selection"
            },
            page: 1,
            perpage: props.perpage, 
            filters: { state: props.state || props.state === 0 ? props.state.toString() : undefined },
            sortTerms: {},
		};

        const { addons } = this.context;
        const showCurrencySettings = addons && (addons.invoice_currency || addons.netvisor || addons.procountor || addons.meritaktiva);

        // Bills
        this.fields = [
            { name: "expand", header: "", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { name: "checked", header: "", columnHeaderType: "checkbox", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { name: "attachment", header: "", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { name: "state", header: this.tr("Status"), width: 100 },
            { name: "id", header: this.tr("No."), width: 61, searchable: true },
            { name: "date", header: this.tr("Credited"), width: 110 },
            { name: "voucher_date", header: this.tr("Accounting Date"), width: 110 },
            { name: "date_due", header: this.tr("Due Date"), width: 110 },
            { name: "supplier", header: this.tr("Supplier"), width: 160, searchable: true, visualizationType: "tree" },
            { name: "project", header: this.tr("Project"), width: 160 },
            { name: "buyer_reference", header: this.tr("Buyer reference"), width: 110 },
            { name: "reference_nr", header: this.tr("Reference nr."), width: 110 },
            { name: "synced_to_po", header: this.tr("Synced to PO"), width: 110 },
            { name: "sum", header: this.tr("Sum VAT 0%"), width: 160, searchable: true, type: "number" },
            { name: "sum_vat", header: this.tr("Sum"), width: 160, searchable: true, type: "number"  },
            ...(showCurrencySettings ? [{ name: "invoice_currency", header: this.tr("Currency"), width: 160, searchable: true }] : []),
            ...(showCurrencySettings ? [{ name: "currency_rate", header: this.tr("Currency rate"), width: 160, searchable: true, type: "number" }] : []),
            ...(showCurrencySettings ? [{ name: "currency_sum", header: this.tr("Sum VAT 0% in currency"), width: 160, searchable: true, type: "number" }] : []),
            ...(showCurrencySettings ? [{ name: "currency_sum_tax", header: this.tr("Sum in currency"), width: 160, searchable: true, type: "number" }] : []),
            { name: "approved", header: this.tr("Approved"), width: 160 },
            { name: "assigned", header: this.tr("Assigned"), width: 160 }
        ];

        if (this.context.addons && this.context.addons.procountor) {
            this.fields.push({name: "in_procountor", header: this.tr("In Procountor"), width: 125});
        }
        if (this.context.addons && this.context.addons.netvisor) {
            this.fields.push({name: "in_netvisor", header: this.tr("In Netvisor"), width: 125});
        }

        this.userTypeDataHeaders = {
            approved_id: 'approved',
            assigned_id: 'assigned'
        };

        this.translations = {
            locked: this.tr("locked"),
            freelancer: this.tr("freelancer")
        };        

        this.initialFetchDone = false;

        this.sortTerms = {};
        this.searchTerms = undefined;

        this.refList = React.createRef();
        this.advancedSearch = React.createRef();

        this.billsSentMessage = this.tr("Bills exported successfully!");
        this.billsApprovedMessage = this.tr("Bills approved successfully!");

        ["sendProcountor", "sendNetvisor", "getCheckedRows", "approveTalenom", "approveProcountor", "approveNetvisor", "sendTalenom", "printFinvoice", "approveFortnox", "sendProcountorAccounting"].forEach(e => this[e] = this[e].bind(this));
        this.filtersAreInInitialState = this.filtersAreInInitialState.bind(this);
        this.initializeStickySearch   = this.initializeStickySearch.bind(this);
        this.saveStickySearch         = this.saveStickySearch.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.listenReset();
        this.initializeStickySearch();
        DataHandler.get({url: `subjects/companies/receivedinvoices/approve+pre_approve`, currency: 1}).done(companies => {
            let c = false;
            companies.forEach(company => {
                if(company.id == this.state.company) {
                    this.setState({companyCurrency: company.currency});
                    c = true;
                }
            })
            if (!c) {
                this.setState({companyCurrency: companies[0].currency})
            }
            this.setState({companies})
        });
    }

    componentWillUnmount() {
		super.componentWillUnmount();
		this.unListenReset();
	}

    componentDidUpdate(prevProps, prevState) {
        const { company, companies } = this.state;
        if (prevState.company !== company) {
            companies.forEach(company => {
                if(company.id == this.state.company) {
                    this.setState({companyCurrency: company.currency});
                }
            })
            this.fetchData();
            this.props.getAutoCompleteData(company);
        }
    }

    fetchCount = async (overrides = {}) => {
        const { filters} = this.state;

        const data = await DataHandler.get({
            url: 'bills',
            getCount: 1,
            ...filters,
            ...overrides
        });
    
       this.setState(data,);
    }
    
    updateStatus = async (status, ids = false) => {
        if (!ids) {
            try {
                ids = await this.getCheckedRows();
            } catch (e) {}
        }

        if (ids.length === 0)
            return;

        this.openDialog("assign", {
            ids,
            user: this.context.userObject.usersId,
            status: status,
            onSaved: (data) => this.statusUpdated(data, ids, {state: status, userId: this.context.userObject.usersId}),
        });
    }

    statusUpdated = (data, ids = [], details = {}) => {
        if (data.response?.updated) {
            this.props.enqueueSnackbar(this.tr("${amount} bills updated!", {amount: data.response?.updated}), {
                variant: "success",
            });        
        }
        if (data.response?.not_updated) {
            this.props.enqueueSnackbar(this.tr("${amount} bills could not be updated: Wrong status or no permission!", {amount: data.response?.not_updated}), {
                variant: "error",
            });
        }

        this.uncheckRowsAndFetchData();
    }

    uncheckRowsAndFetchData = () => {
        if(this.refList.current) {
            this.refList.current.uncheckAll();
        }
        this.fetchData();
    }

    hasInitialFilterProps = () => {
        const initialProps = ["state", "start", "end"];
		
		let i;
		for (i = 0; i < initialProps.length; i++) {
			if (this.props[initialProps[i]]) 
				return true;
		}

		return false;
	}

    initializeStickySearch() {
        if (this.hasInitialFilterProps()) {
            this.setState({ stickySearchInitialized: true }, () => this.fetchData({}, false, true));
            return;
        }
        
        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` }).done((response, _, request) => {
            if(request.status !== 200) {
				this.fetchData({}, false, true);
                return;            
            }

            const approvePrivileges = this.context.privileges.receivedinvoices.approve;
			const pre_approvePrivileges = this.context.privileges.receivedinvoices.pre_approve;
			const hasApprovePrivileges = approvePrivileges && approvePrivileges.find(el => el == response.company);
			const hasPre_approvePrivileges = pre_approvePrivileges && pre_approvePrivileges.find(el => el == response.company);
            
            if (!hasApprovePrivileges && !hasPre_approvePrivileges) {
				response.company = this.filtersInitialValues.company;
			}
			
			if (response.searchTerms)
                this.searchTerms = response.searchTerms;
            if (response.sortTerms)
                this.sortTerms = response.sortTerms;
                                
            this.props.getAutoCompleteData(response.company);

            this.setState({ ...response }, () => this.fetchData({}, false, true));
        }).fail(response => {
			this.fetchData({}, false, true);

        }).always((response, _, request) => {
            this.setState({ stickySearchInitialized: true });
        });
    }


    saveStickySearch(filters) {
		const stateToSave = _.cloneDeep(filters);

		stateToSave.searchTerms = this.searchTerms;
		stateToSave.sortTerms = this.sortTerms;

        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}`, }, { search: stateToSave });
    }

    filtersAreInInitialState() {
        const initial = _.cloneDeep(this.filtersInitialValues);
        delete initial.page;
        delete initial.perpage;
        const filters = {};

        for(const key in initial) {
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = key === "sortTerms" ? JSON.stringify(this[key]) : JSON.stringify(this.state[key]);
        }

        const freetext = this.searchTerms ? this.searchTerms.freetextSearchTerm : "";

        return _.isEqual(initial, filters) && freetext === "";
    }

	_resetFilters = (evt) => {
		if (!evt || evt.keyCode == '27') {
			this.advancedSearch.current.clearSearch(evt, true);
			this.advancedSearch.current.clearSearchTextInput();

            this.searchTerms = undefined;
            this.sortTerms = {};

			this.setState({
				...this.filtersInitialValues,
			}, () => this.fetchData());
		}
    }
    
    listenReset = () => {
		document.body.addEventListener("keyup", this._resetFilters);
	}

	unListenReset = () => {
		document.body.removeEventListener("keyup", this._resetFilters);
	}

    fetchData = async (overrides = {}, onlyIds = false, stickySearch = false, resetPage = false) => {
        const { filters, page, perpage, company, creditedDateSpan, voucherDateSpan, dueDateSpan } = this.state;

        const searchData = this.getSearchData();

        this.saveStickySearch({filters, page, perpage, company, creditedDateSpan, dueDateSpan});

        this.refList.current && this.refList.current.setState({ isLoading: true});

        const data = await DataHandler.post({
            url: 'bills',
            page,
            perpage,
            ...filters,
            ...overrides,
            ...({
                creditedDateStart: creditedDateSpan.startDate !== undefined ? format(creditedDateSpan.startDate, "YYYY-MM-DD") : undefined,
                creditedDateEnd: creditedDateSpan.endDate !== undefined ? format(creditedDateSpan.endDate, "YYYY-MM-DD") : undefined,
                voucherDateStart: voucherDateSpan.startDate !== undefined ? format(voucherDateSpan.startDate, "YYYY-MM-DD") : undefined,
                voucherDateEnd: voucherDateSpan.endDate !== undefined ? format(voucherDateSpan.endDate, "YYYY-MM-DD") : undefined,
                dueDateStart: dueDateSpan.startDate !== undefined ? format(dueDateSpan.startDate, "YYYY-MM-DD") : undefined,
                dueDateEnd: dueDateSpan.endDate !== undefined ? format(dueDateSpan.endDate, "YYYY-MM-DD") : undefined
            }),
            sort: this.sortTerms,
            company,
            onlyIds,
            projectId: this.props.projectId
        }, searchData);

        if (onlyIds) 
            return data;
        
        const userTagProps = {
            fields: this.userTypeDataHeaders,
            showLocked: this.props.showLockedUsersWithTag,
            transl: this.translations,
            userData: this.props.autoCompleteData.all_users
        };
        data.bills.forEach((p, i) => {
            if (Object.keys(p).some(k => Object.values(this.userTypeDataHeaders).includes(k))) {
                data.bills[i] = ({...Utils.showLockedAndFreelancerUserTag(p, userTagProps)});
            }
        })
        
        this.setState(data, () => {
            this.refList.current && this.refList.current.setState({ isLoading: false});
            if(this.refList.current) {
                this.refList.current.endPageChangeAnimation();
                if (resetPage)
                    this.refList.current.setPage(1);
            }
            this.props.setCount && this.props.setCount(this.state.count);
            this.initialFetchDone = true;
        });
    }

    getSearchData() {
        let searchData = {};

        if(this.searchTerms !== undefined && this.searchTerms.mode === "advanced") {
            searchData = {
                mode: 'advanced',
                advanced_search_criteria: JSON.stringify(this.searchTerms.advanced_search_criteria)
            };
            
        } else if(this.searchTerms !== undefined && this.searchTerms.mode === "freetext") {
            searchData = {
                mode: 'freetext',
                freetext: this.searchTerms.freetextSearchTerm,
                advanced_search_criteria: JSON.stringify(this.searchTerms.advanced_search_criteria)
            };
        }

        return searchData;
    }

    setFilter = (name, value = undefined) => {
        const filters = {
            ...this.state.filters
        };

        if (value === undefined)
            delete filters[name];
        else
            filters[name] = value;

        this.setState({filters}, this.fetchData);
    }

    onDateChange = (event, name) => {

        const { startDate, endDate } = event.selection;

        this.setState({
            [name]: {
                startDate: format(startDate, "YYYY-MM-DD"),
                endDate: format(endDate, "YYYY-MM-DD"),
                key: "selection"
            }
        }, () => this.fetchData());

    }

    onDateInputChange = (dateType, date, name) => {

        const { endDate, startDate } = this.state[name];
        date = format(date, "YYYY-MM-DD");      

        if (dateType == "start") {
            this.setState({
                [name]: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection"
                }
            }, () => this.fetchData());
        } else {
            this.setState({
                [name]: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection"
                }
            }, () => this.fetchData());
        }
    }

    export = (target) => {
        const { bills, filters, creditedDateSpan, voucherDateSpan, dueDateSpan } = this.state;

        if (!bills || bills.length < 1) {
            this.props.enqueueSnackbar(this.tr("Nothing to export!"), {
                variant: "warning",
            });
            return;
        }

        const overrides = {};
        const searchData = this.getSearchData();
        this.refList.current && this.refList.current.setState({ isLoading: true});
        const params = { 
            ...filters,
            ...overrides,
            sort: this.sortTerms,
            url: "bills/export",
            company: this.state.company,
            statuses: [],
            projectId: this.props.projectId,
            ...({
                creditedDateStart: creditedDateSpan.startDate !== undefined ? format(creditedDateSpan.startDate, "YYYY-MM-DD") : undefined,
                creditedDateEnd: creditedDateSpan.endDate !== undefined ? format(creditedDateSpan.endDate, "YYYY-MM-DD") : undefined,
                voucherDateStart: voucherDateSpan.startDate !== undefined ? format(voucherDateSpan.startDate, "YYYY-MM-DD") : undefined,
                voucherDateEnd: voucherDateSpan.endDate !== undefined ? format(voucherDateSpan.endDate, "YYYY-MM-DD") : undefined,
                dueDateStart: dueDateSpan.startDate !== undefined ? format(dueDateSpan.startDate, "YYYY-MM-DD") : undefined,
                dueDateEnd: dueDateSpan.endDate !== undefined ? format(dueDateSpan.endDate, "YYYY-MM-DD") : undefined
            }),
        };

        const allCheckedExcept = this.refList.current.getAllCheckedExcept();
        let ids = [];

        if (!allCheckedExcept) {
            ids =  this.refList.current.getCheckedRows();
        }

        const columnOrder = this.refList.current.visibleColumnsOrder;
        const fields = this.fields;
        const exportHeaders = [];        
		_.forEach(columnOrder, column => {
			_.forEach(fields, (field, i) => {
				if(column == field.name && column != "expand" && column != "checked" && column != "attachment"){
					exportHeaders.push(field.header);
				}
			})
		})
        this.props.autoCompleteData.bills_statuses.forEach(status => {
            params.statuses.push({id: status.id, label: status.label});
        })

        params.allCheckedExcept = allCheckedExcept;
        params.order = columnOrder;
        params.columnNames = exportHeaders;
        params.currency = this.state.companyCurrency;
        params.file_name = this.tr("bills_list_export")
        DataHandler.postArrayBuffer({...params, export: target, ...searchData}, {ids}, true).done(response => {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            FileSaver.saveAs(blob, `${params.file_name}.${target}`);
            this.refList.current && this.refList.current.setState({ isLoading: false});
        });
    }


    openDialog = (dialog, props) => {
        this.setState({dialog, dialogProps: props});
    }


    closeDialog = () => {
        this.setState({dialog: undefined, dialogProps: undefined});
    }

    setSendingStatus = (status) => {
        if (status == true) {
            const key = this.props.enqueueSnackbar(this.tr("Sending invoices. This may take a while."), {
                variant: "info",
                persist: true
            });
            this.setState({sendingInProgress: status, snackBarKey: key});
        } else {
            this.props.closeSnackbar(this.state.snackBarKey);
            this.setState({sendingInProgress: status, snackBarKey: undefined});
        }
    }

    showIntegrationResponse = (response = {}, type = "send", showArchiveDialog = true) => {
        this.setSendingStatus(false);

        if (response?.fail) {
			this.props.enqueueSnackbar(this.tr("Error in sending expenses!"), {
				variant: "error",
			});
		}

        let invalidBills = [];
        let accountingFailedBills = [];

        if (response?.invalid?.length > 0) {
            invalidBills = response.invalid.map(el => {
                const errorMessage = el.errors ? el.errors.map(c => c.translate_message == 1 ? this.tr(c.message) : c.message).join(", ") : (el.translate_message == 1 ? this.tr(el.message) : el.message);
                return {nr: el.invoice_nr, message: errorMessage};
            });
        }

        if (response?.accounting_invalid?.length > 0) {
            accountingFailedBills = response.accounting_invalid.map(el => {
                const errorMessage = el.errors ? el.errors.map(c => c.translate_message == 1 ? this.tr(c.message) : c.message).join(", ") : (el.translate_message == 1 ? this.tr(el.message) : el.message);
                return {nr: el.invoice_nr, message: errorMessage};
            });
        }

        if(response?.success?.length > 0 && showArchiveDialog){
            this.fetchData();
            this.openArchiveDialog({ successBills: response.success, invalidBills, accountingFailedBills }, type);
        } 
        else if (invalidBills.length > 0) {
            this.fetchData();
            const billErrors = invalidBills.map((be) => {
                const beMsg = this.tr("Bill number") + ": " + be.nr + ": " + be.message;
                return beMsg;
            });
            const errorMsg = billErrors.join(" | ");

            this.props.enqueueSnackbar(this.tr("Failed to send some bills: ${errors}", {errors: errorMsg}), {
                variant: "error",
                autoHideDuration: 10000
            });

            if (response.success?.length > 0) {
                this.props.enqueueSnackbar(this.tr("${amount} bills sent successfully", {amount: response.success?.length}), {
                    variant: "success",
                    autoHideDuration: 10000
                });
            }
        } 
        else if (!showArchiveDialog) {
            this.props.enqueueSnackbar(response?.successMsg, {
                variant: "success",
            });
        }
    }

    async approveNetvisor() {
        const { company } = this.state;
        
        const ids = await this.getCheckedRows();
       
        if (ids.length == 0 || this.state.sendingInProgress)
            return;

        this.setSendingStatus(true);
        
	    DataHandler.put({url: `bills/netvisor/approve`}, {company: company, ids: ids}).done(response => {
            this.showIntegrationResponse(response, "approve");
        })
        .fail(err => {
            this.showIntegrationResponse({ fail: true });
        })
    }

    sendHeeros = async () => {
        const { company } = this.state;
        
        const ids = await this.getCheckedRows();
       
        if (ids.length == 0 || this.state.sendingInProgress)
            return;

        this.setSendingStatus(true);

        DataHandler.put({url: `bills/heeros`}, {company: company, ids: ids}).done(response => {
            this.showIntegrationResponse(response);
        });
    }

    async sendProcountor() {
        const { company } = this.state;
        
        const ids = await this.getCheckedRows();
       
        if (ids.length == 0 || this.state.sendingInProgress)
            return;

        this.setSendingStatus(true);
        
		DataHandler.put({url: `bills/procountor`}, {company: company, ids: ids}).done(response => {
            this.showIntegrationResponse(response);
        })
        .fail(err => {
            this.showIntegrationResponse({ fail: true });
        })
    }

    async approveProcountor() {
        const { company } = this.state;
        const ids = await this.getCheckedRows();
       
        if (ids.length == 0 || this.state.sendingInProgress)
            return;

        this.setSendingStatus(true);
        
		DataHandler.put({url: `bills/procountor/approve`}, {company: company, ids: ids}).done(response => {
            this.showIntegrationResponse(response, "approve");
        })
        .fail(err => {
            this.showIntegrationResponse({ fail: true });
        })
    }

    async sendProcountorAccounting() {
        const { company } = this.state;
        const ids = await this.getCheckedRows();
       
        if (ids.length == 0 || this.state.sendingInProgress)
            return;

        this.setSendingStatus(true);
        
		DataHandler.put({url: `bills/procountor/send_accounting`}, {company: company, ids: ids}).done(response => {
            response.successMsg = this.tr("Bills accounting sent succesfully");
            this.showIntegrationResponse(response, "accounting", false);
        })
        .fail(err => {
            this.showIntegrationResponse({ fail: true });
        })
    }

    async approveFortnox(type) {
        const { company } = this.state;
        const ids = await this.getCheckedRows();
       
        if (ids.length == 0 || this.state.sendingInProgress)
            return;

        this.setSendingStatus(true);
        
		DataHandler.put({url: `bills/fortnox/approve`}, {company: company, ids: ids, type: type}).done(response => {
            this.showIntegrationResponse(response, "approve");
        })
        .fail(err => {
            this.showIntegrationResponse({ fail: true });
        })
    }

    openArchiveDialog = (dialogData = {}, type = "send") => {
        dialogData.type = type;
        this.setState({ archiveDialogOpen: true, dialogData });
    }

    renderArchiveDialog = () => {
        const { dialogData } = this.state;

        const type = dialogData.type || "send";
        const header = type == "approve" ? this.tr('Archive approved bills') : this.tr('Archive sent bills');
        const successAmount = dialogData?.successBills?.length || 0;
        const invalidsAmount = dialogData?.invalidBills?.length || 0;
        const successMessage = type == "approve" ? this.tr("${amount} bills were approved succesfully.", { amount: successAmount }) : this.tr("${amount} bills were sent succesfully.", { amount: successAmount });
        const notSentMessage = type == "approve" ? this.tr("${amount} bills were not approved", { amount: invalidsAmount }) : this.tr("${amount} bills were not sent", { amount: invalidsAmount});

        const invalids = (dialogData?.invalidBills || []).map(el => {
			const header = this.tr("Bill number: ${nr}:", { nr: el.nr });
			return {...el, header };
		});

        const accountingFailedBills = (dialogData?.accountingFailedBills || []).map(el => {
			const header = this.tr("Bill number: ${nr}:", { nr: el.nr });
			return {...el, header };
		});

        const errorsData = [
            {
                header: notSentMessage,
                data: invalids
            },
            {
                header: this.tr("Accounting send failed for following bills") + ":",
                data: accountingFailedBills,
                type: "warning"
            }
        ];

		return <ConfirmSuccessDialog
            header={header}
			confirmText={type == "approve" ? this.tr('Archive approved bills') : this.tr('Archive sent bills')}
            successMessage={successMessage}
			successData={dialogData.successBills}
			errorsData={errorsData}
            onConfirm={(successIds) => {
                this.updateStatus(4, successIds);
            }}
			closeDialog={() => {
				this.setState({ archiveDialogOpen: false, dialogData: {} })
            }}
		/>
    }

    async sendNetvisor() {
        const { company } = this.state;

        const ids = await this.getCheckedRows();
       
        if (ids.length == 0 || this.state.sendingInProgress)
            return;

        this.setSendingStatus(true);
        
		DataHandler.put({url: `bills/netvisor`}, {company: company, ids: ids}).done(response => {
            this.showIntegrationResponse(response);
        })
        .fail(err => {
            this.showIntegrationResponse({ fail: true });
        })
    }

    getTalenomCirculation = () => {
        const { company } = this.state;

        DataHandler.get({url: `bills/talenom/circulations`}, {company: company}).done(response => {
            if (response.error) {
                const msg = response.message ? (response.translate == 1 ? this.tr(response.message) : response.message) : this.tr("Failed to fetch circulation list");
                this.props.enqueueSnackbar(msg , {
                    variant: "error",
                });
                return;
            }

            if (response.circulation_ids?.length == 1) {
                this.sendTalenom(response.circulation_ids[0].id)
            } else {
                this.openDialog("talenomCirculation", {
                    circulationList: response.circulation_ids || [],
                    listSelected: (id) => this.sendTalenom(id),
                });
            }
        });
    }

    async sendTalenom(circulationId) {
        const { company } = this.state;
        
        const ids = await this.getCheckedRows();
        if (ids.length == 0 || this.state.sendingInProgress)
            return;

        this.setSendingStatus(true);
        
        DataHandler.put({url: `bills/talenom`}, {company: company, ids: ids, circulationId: circulationId}).done(response => {
            this.showIntegrationResponse(response);
        })
        .fail(err => {
            this.showIntegrationResponse({ fail: true });
        })
    }

    async approveTalenom() {
        const { company } = this.state;
        
        const ids = await this.getCheckedRows();
        if (ids.length == 0 || this.state.sendingInProgress)
            return;

        this.setSendingStatus(true);
        
        DataHandler.put({url: `bills/talenom/approve`}, {company: company, ids: ids}).done(response => {
            this.showIntegrationResponse(response, "approve");
        })
        .fail(err => {
            this.showIntegrationResponse({ fail: true });
        })
    }


    async printFinvoice() {
        const checkedRows = await this.getCheckedRows();

        DataHandler.get({url: `bills/fivaldi`, ids: checkedRows}).done(response => {
            const blob = new Blob([response.documentElement.outerHTML], {
                type: 'application/xml'
            });

            FileSaver.saveAs(blob, 'finvoice.xml');
        });
    }

    async createContractBillRows(integration, company, type = null) {
        const ids = await this.getCheckedRows();

        if (ids.length == 0)
            return;

        const checkKey = this.props.enqueueSnackbar(this.tr("Finding target customers, projects and bills..."), {
            variant: "info",
            persist: true
        });
        
		DataHandler.put({url: `bills/contract_bill`}, {ids: ids, checkMode: 1, integration: integration, company: company}).done(response => {
            this.props.closeSnackbar(checkKey);

            if (response.missing_products != "") {
                this.props.enqueueSnackbar(this.tr("Product codes missing from products register: ") + response.missing_products, {
                    variant: "error",
                });
            } else if (response.missing_customers.length != "") {
                this.props.enqueueSnackbar(this.tr("Customers missing from customer register: ") + response.missing_customers, {
                    variant: "error",
                });
            } else if (response.missing_contract_bills.length != "") {
                this.props.enqueueSnackbar(this.tr("Targeted contract bills missing from projects: ") + response.missing_contract_bills, {
                    variant: "error",
                });
            } else {
                const key = this.props.enqueueSnackbar(this.tr("Targeting invoice rows..."), {
                    variant: "info",
                    persist: true
                });

                DataHandler.put({url: `bills/contract_bill`}, {ids: ids, integration: integration, type: type}).done(() => {
                    this.props.closeSnackbar(key);

                    this.props.enqueueSnackbar(this.tr("Invoice rows targeted successfully"), {
                        variant: "success",
                    });
                });
            }
        });
    }

    async printOriginals() {
        this.getCheckedRows().then(resp => {
            const ids = resp;

            const params = {
                url: "bills/print_originals",
            };

            if (ids.length > 0)
                params.ids = ids.join(",");
            else return;

            DataHandler.getArrayBuffer({ ...params }).done(response => {
                const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });

                FileSaver.saveAs(blob, "bills.pdf");
            });
        });
    }

    resetList() {
        this.refList.current.resetCheckedRows();
        this.refList.current.setPage(1);
    }

    async getCheckedRows() {
        const allCheckedExcept = this.refList.current.getAllCheckedExcept();
        let selected;

        if (allCheckedExcept) {
            selected = await this.fetchData({}, true);
            selected = selected.ids.filter(id => {
                return !allCheckedExcept[id]; 
            });            
        } 
        else 
            selected = this.refList.current.getCheckedRows();

        return selected;
    }

    render() {
        if(!this.state.stickySearchInitialized) {
            return null;
        }
        
        const { autoCompleteData, filtersEl, sumsEl } = this.props;
        const { filters, dialog, dialogProps, has_bills, companies, company } = this.state;
        const { tr } = this;
        const dialogs = { assign: BillAssign, talenomCirculation: TalenomCirculation }
        const CustomDialog = dialog && dialogs[dialog];

        // const fieldColumns = this.fields.filter(e => e.name != 'reporting_group').map(e => {
			// return e;
		// });

        const fieldColumns = this.fields;

        return (
            <React.Fragment>
                <Portal container={filtersEl}>
                    {companies.length > 1 && <OutlinedField  className="listFilterOutlinedField" label={this.tr("Company")} value={company} select onChange={e => { 
                            this.resetList(); 
                            this.context.functions.setLastCompany(e.target.value);
                            this.setState({ company: e.target.value }) }}>
                        { companies.map(row => (
                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                        ))}
                        </OutlinedField>
                    }

                    {CustomDialog && <CustomDialog {...dialogProps} autoCompleteData={{...autoCompleteData, users: autoCompleteData.privileged_users}} onClose={this.closeDialog} />}

                    
                        <OutlinedField
                            className="listFilterOutlinedField"
                            select
                            //SelectProps={SelectProps}
                            name="exports"
                            label={this.tr("Print & export")}
                            onChange={e => {
                                if (!e.target.value)
                                    return;

                                switch (e.target.value) {
                                    case 'procountor':
                                        this.sendProcountor();
                                        break;
                                    case 'approve_procountor':
                                        this.approveProcountor();
                                        break;
                                    case 'send_procountor_accounting':
                                        this.sendProcountorAccounting();
                                        break;
                                    case 'fivaldi':
                                        this.printFinvoice();
                                        break;
                                    case 'netvisor':
                                        this.sendNetvisor();
                                        break;
                                    case 'approve_netvisor':
                                        this.approveNetvisor();
                                        break;
                                    case 'talenom':
                                        this.getTalenomCirculation();
                                        break;
                                    case 'approve_talenom':
                                        this.approveTalenom();
                                        break;
                                    case 'heeros':
                                        this.sendHeeros();
                                        break;
                                    case 'print-originals':
                                        this.printOriginals();
                                        break;
                                }
                            }}
                            shrinkLabel={false}>
                            {<MenuItem key={"print-originals"} value={"print-originals"}>
                                {this.tr("Print originals")}
                            </MenuItem>}
                            {this.context.addons.procountor && <MenuItem key={'procountor'} value={'procountor'}>{this.tr('Send Procountor')}</MenuItem>}
                            {this.context.addons.procountor && <MenuItem key={'approve_procountor'} value={'approve_procountor'}>{this.tr('Approve Procountor')}</MenuItem>}
                            {this.context.addons.procountor && <MenuItem key={'send_procountor_accounting'} value={'send_procountor_accounting'}>{this.tr('Send accounting info to Procountor')}</MenuItem>}
                            {this.context.addons.fivaldi && <MenuItem key={'fivaldi'} value={'fivaldi'}>{this.tr('Print Finvoice')}</MenuItem>}
                            {this.context.addons.netvisor && <MenuItem key={'netvisor'} value={'netvisor'}>{this.tr('Send Netvisor')}</MenuItem>}
                            {this.context.addons.netvisor && <MenuItem key={'approve_netvisor'} value={'approve_netvisor'}>{this.tr('Approve Netvisor')}</MenuItem>}
                            {this.context.addons.talenom_bill && <MenuItem key={'talenom'} value={'talenom'}>{this.tr('Send Talenom')}</MenuItem>}
                            {this.context.addons.talenom_bill && <MenuItem key={'approve_talenom'} value={'approve_talenom'}>{this.tr('Approve Talenom')}</MenuItem>}
                            {this.context.addons.heeros && <MenuItem key={'heeros'} value={'heeros'}>{this.tr('Send to Heeros')}</MenuItem>}
                            {/*this.context.addons.fortnox && <MenuItem key={'approve_procountor'} onClick={() => this.approveFortnox("approvalpayment")}>{this.tr('Fortnox - Approval of payment')}</MenuItem>*/}
                            {/*this.context.addons.fortnox && <MenuItem key={'approve_procountor'} onClick={() => this.approveFortnox("approvalbookkeep")}>{this.tr('Fortnox - Approval of bookkeep')}</MenuItem>*/}
                        </OutlinedField>
                        

                    <OutlinedField 
                        select 
                        label={this.tr("Status")} 
                        className="listFilterOutlinedField" 
                        value={filters.state || -1}>
                        <MenuItem value={-1} onClick={() => { this.resetList(); this.setFilter("state") }}>
                            {this.tr("All")}
                        </MenuItem>
                        {
                            autoCompleteData.bills_statuses.map( (opt) =>
                                <MenuItem value={opt.id} key={opt.id} onClick={() => { this.resetList(); this.setFilter("state", opt.id) }}>
                                    {opt.label}
                                </MenuItem>
                            )
                        }
                    </OutlinedField>

                    <div className="flex"><DateRangePicker
                        className="daterange"
                        ranges={[this.state.creditedDateSpan]}
                        onChange={(event) => { this.resetList(); this.onDateChange(event, 'creditedDateSpan') }}
                        onInputChange={(dateType, date) => { this.resetList(); this.onDateInputChange(dateType, date, 'creditedDateSpan') }}
                        label={this.tr("Credited date span")}
                        dateFormat={this.context.userObject.dateFormat} /></div>

                    <div className="flex"><DateRangePicker
                        className="daterange"
                        ranges={[this.state.voucherDateSpan]}
                        onChange={(event) => { this.resetList(); this.onDateChange(event, 'voucherDateSpan') }}
                        onInputChange={(dateType, date) => { this.resetList(); this.onDateInputChange(dateType, date, 'voucherDateSpan') }}
                        label={this.tr("Accounting date span")}
                        dateFormat={this.context.userObject.dateFormat} /></div>

                    <div className="flex"><DateRangePicker
                        className="daterange"
                        ranges={[this.state.dueDateSpan]}
                        onChange={(event) => { this.resetList(); this.onDateChange(event, 'dueDateSpan') }}
                        onInputChange={(dateType, date) => { this.resetList(); this.onDateInputChange(dateType, date, 'dueDateSpan') }}
                        label={this.tr("Due date span")}
                        dateFormat={this.context.userObject.dateFormat} /></div>

                    <AdvancedSearch
                        ref={this.advancedSearch}
                        mode={this.searchTerms && this.searchTerms.mode ? this.searchTerms.mode : undefined}
                        initialFilters={this.searchTerms ? this.searchTerms.currentFilters : undefined}
                        mainConfig={this.searchTerms && this.searchTerms.advanced_search_criteria ? { operator: this.searchTerms.advanced_search_criteria.operator } : undefined} 
                        freetextLabel={this.searchTerms ? this.searchTerms.freetextSearchTerm : ""}
                        alwaysShowClearFilters={!this.filtersAreInInitialState()}
                        onClearSearch={this._resetFilters}
                        fields={this.fields.filter(f => f.searchable).map(f => { return { field: f.name, type: f.type, transl: f.header, visualizationType: f.visualizationType || "list", entityMode: f.entityMode || false } })}
                        noRequests={true}
                        onSearchTrigger={(searchTerms) => {
                            this.searchTerms = searchTerms;
                            this.resetList();
                        }}
                        autoCompleteData={{
                            supplier: [autoCompleteData.customers, "parent_id"],
                            reporting_group: [autoCompleteData.reporting_groups, "parent_id"]
                        }}
                        autoCompleteDataFilters={{
                            reporting_group: "customers_id|supplier.id"
                        }}
                    />
                </Portal>
                <Portal container={sumsEl}>
                    <span className="info"></span>
                </Portal>
                <List
                    ref={this.refList}
                    data={this.state.bills}
                    columns={fieldColumns}
                    height="fitRemaining"
                    trimHeight={-10}
                    className="projectList"
                    listRowType={BillsListRow}
                    noColorVariance={true}
                    showNoResultsMessage={this.initialFetchDone}
                    showOverlay={!has_bills}
                    noStateData={true}
                    useAllCheckedExcept={true}
                    overlayComponent={BillListOverlay}
                    ignoreRowPropsChange={false}
                    rowProps={{
                        openAttachment: billId => {
                            this.setState({ attachmentDialogOpen: true, attachmentBillId: billId});
                        },
                        enqueueSnackbar: this.props.enqueueSnackbar,
                        onCheck: (checked, data) => {
                            checked && this.checked.push(data.id);
                            !checked && (this.checked = this.checked.filter(c => c !== data.id));
                            

                            this.props.onCheck(this.checked);
                        },
                        currency: this.state.companyCurrency,
                        sharedData: {...autoCompleteData, openDialog: this.openDialog},
                        updateStatus: this.updateStatus
                    }}
                    sharedData={{...autoCompleteData, openDialog: this.openDialog}}
                    saveColumnConfig={true}
                    userListSettingsKey="bills_list"
                    showPageSelector={true}
                    pageCount={this.state.pages}
                    totalCount={this.state.count}
                    perpage={this.state.perpage}
                    page={this.state.page}
                    controlPage={true}
                    onPerPageChange={perpage => {
                        this.setState({ perpage: perpage, page: 1 }, () => this.fetchData({}, false, false, true));
                    }}
                    onPageChange={page => {
                        this.refList.current.startPageChangeAnimation();
                        this.setState({ page: page }, this.fetchData);
                    }}
                    onSortRows={(colName, asc) => {
                        this.sortTerms = { name: colName, asc: asc };
                        this.refList.current.setPage(1);
                    }}
                    onUncheckAll={() => { 
                        this.checked = [];
                        this.props.onCheck(this.checked);
                    }} 
                    useHSRightPadding
                    />
                    {this.state.attachmentDialogOpen && <AttachmentDialog
                        open
                        data={{ targetModule: "bills", id: this.state.attachmentBillId }} 
                        onDialogClose={() => this.setState({ attachmentDialogOpen: false })}
                    />}

                    {this.state.archiveDialogOpen && this.renderArchiveDialog()}
            </React.Fragment>
        );
    }
}

export default BillsList;
