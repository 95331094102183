import React from 'react';
import { Check, Close } from '@mui/icons-material';
import { Button, Drawer } from '@mui/material';
import DataHandler from '../general/DataHandler';
import LoaderButton from '../general/LoaderButton';
import VersionContentManager from '../general/VersionContentManager';
import TaimerComponent from '../TaimerComponent';
import styles from './EntryOrderView.module.scss';

interface State {
    cancelStage: number;
}
class EntryOrderView extends TaimerComponent<{}, State> {
    constructor(props, context) {
        super(props, context, 'onboarding/EntryOrderView');
        this.state = {
            cancelStage: 0,
        };
    }

    onUpgradeClicked = () => {
        const version = VersionContentManager.getCurrentVersion();
        this.context.functions.sendMixpanelEvent('Upgrade to paid after trial', {
            "Plan": version.name,
            "Plan ID": version.id
        });
        this.context.functions.showUpgradeSlider(version);
    };

    renderContent = () => {
        const version = VersionContentManager.getCurrentVersion();
        const { cancelStage } = this.state;
        switch (cancelStage) {
            case 0:
                return (
                    <div className={styles.content}>
                        <img src={require('../general/icons/rocket.svg').default} />
                        <div className={styles.header}>
                            <h1>{this.tr('Your free trial has ended')}</h1>
                            <p>
                                {this.tr(
                                    "Your 14-day trial has now expired. Don't worry, we have got you covered: you can easily upgrade your current plan to get unlimited access to the features. You can also choose to continue using our free plan."
                                )}
                            </p>
                        </div>
                        <div className={styles.benefits}>
                            <h2>{this.tr('Benefits of upgrading to a paid plan')}:</h2>
                            <ul>
                                <li>
                                    <Check />
                                    <p>{this.tr(`Benefit_1`)}</p>
                                </li>
                                <li>
                                    <Check />
                                    <p>{this.tr(`Benefit_2`)}</p>
                                </li>
                                <li>
                                    <Check />
                                    <p>{this.tr(`Benefit_3`)}</p>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.actions}>
                            <Button onClick={() => this.onUpgradeClicked()} size="large">
                                {this.tr('Buy ${version} plan', { version: version.name })}
                            </Button>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className={styles.content}>
                        <img src={require('../dashboard/images/ActivitiesGraphic.svg').default} />
                        <div className={styles.header}>
                            <h1>{this.tr('Are you sure you want to downgrade to the Free plan?')}</h1>
                            <p>{this.tr('By downgrading to the Free plan you will still be able to continue using our software, but you will lose some features and functions. ')}</p>
                        </div>
                        <div className={styles.benefits}>
                            <h2>{this.tr('Changes compared to ${version} plan', { version: version.name })}:</h2>
                            <ul>
                                <li>
                                    <Close className={styles.red} />
                                    <p>{this.tr(`Free_limit_1`)}</p>
                                </li>
                                <li>
                                    <Close className={styles.red} />
                                    <p>{this.tr(`Free_limit_2`)}</p>
                                </li>
                                <li>
                                    <Close className={styles.red} />
                                    <p>{this.tr(`Free_limit_3`)}</p>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.actions}>
                            <Button onClick={() => this.onUpgradeClicked()} size="large">
                                {this.tr('Buy ${version} plan', { version: version.name })}
                            </Button>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className={styles.content}>
                        <img src={require('../general/icons/sale.svg').default} />
                        <div className={styles.header}>
                            <h1>{this.tr('We really don’t want you to go')}</h1>
                            <p>{this.tr('${version}_campaign_description', { version: version.name })}</p>
                        </div>
                        <div className={styles.benefits}>
                            <div className={styles.campaignCode}>
                                <p>{this.tr('${version}_campaign_code', { version: version.name })}</p>
                            </div>
                            <p>{this.tr('Copy this code and paste it in the campaign code field when making your purchase.')}</p>
                        </div>
                        <div className={styles.actions}>
                            <Button onClick={() => this.onUpgradeClicked()} size="large">
                                {this.tr('Buy ${version} plan', { version: version.name })}
                            </Button>
                        </div>
                    </div>
                );
        }
    };

    render() {
        return (
            <div id={styles.entryOrderView}>
                <Drawer anchor="right" open hideBackdrop>
                    {this.renderContent()}
                </Drawer>
            </div>
        );
    }
}

export default EntryOrderView;
