import React from "react";
import moment from "moment";

import TaimerComponent from "../../../TaimerComponent";
import InsightsBase from "../InsightsBase";
import QuarterlyOverview from "./components/QuarterlyOverview";
import MonthlyOverview from "./components/MonthlyOverview";
import GoalVsActual from "./components/GoalVsActual";
import { GOAL_IDS, GOAL_TYPES } from "../../../accounts/TabGoals";
import DataHandler from "../../../general/DataHandler";
import NoPermissionOverlay from "../../../overlays/NoPermissionOverlay";

import './GoalsInsight.css';

class GoalsInsight extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "dashboard/insights/goals");

    this.refInsightBase = React.createRef();
    this.goalsVsActual = React.createRef();

    this.monthTitles = {
      1: this.tr("Jan"),
      2: this.tr("Feb"),
      3: this.tr("Mar"),
      4: this.tr("Apr"),
      5: this.tr("May"),
      6: this.tr("Jun"),
      7: this.tr("Jul"),
      8: this.tr("Aug"),
      9: this.tr("Sep"),
      10: this.tr("Oct"),
      11: this.tr("Nov"),
      12: this.tr("Dec"),
    };

    this.goals = [
      {
        id: GOAL_IDS.gross_profit,
        name: this.tr("Gross Profit"),
      },
      {
        id: GOAL_IDS.net_profit,
        name: this.tr("Net Profit"),
      },
      {
        id: GOAL_IDS.gross_margin,
        name: this.tr("Gross Margin"),
      },
      {
        id: GOAL_IDS.net_margin,
        name: this.tr("Net Margin"),
      },
    ];

    this.goalTypes = [
      {
        id: 1,
        name: this.tr("Target"),
      },
      {
        id: 2,
        name: this.tr("Commitment"),
      },
      {
        id: 3,
        name: this.tr("Forecast"),
      },
    ];

    this.state = {
      goals: [],
      months: this.getMonths(),
      settingsLoaded: false,
      overViewLoaded: true,
      goalsVsActualLoaded: true,
      goalTypes: [],
      projectTypes: [],
      selectedProjectTypes: [],
      defaultFilters: {
        enterprise_groups: [],
      },
      selectedGoal: this.goals[0],
      selectedGoalType: this.goalTypes[0],
      data: {
        goal_type: {},
        type: {},
      },
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  getProjectTypes = (company) => {
    DataHandler.get({
      url: `subjects/project_types/${company}`,
    }).done((projectTypes) => {
      this.setState({ projectTypes });
    });
  };

  getMonths = (startDate = null) => {
    const months = [];
    const startMonth = this.context.taimerAccount.financialYearStart;
    let currentMonth = startDate
      ? moment(startDate)
      : moment()
          .set("month", startMonth - 1)
          .startOf("month");

    if (currentMonth.isAfter(moment.now())) {
      currentMonth.set("year", currentMonth.get("year") - 1);
    }
          
    for (let i = 0; i < 12; i++) {
      months.push(moment(currentMonth));
      currentMonth.add(1, "month");
    }

    return months;
  };

  getProjectTypesForFilterList = () => {
    const { selectedProjectTypes, projectTypes } = this.state;
    const allProjectTypes = [
      { id: "-1", name: this.tr("All not tracked") },
      ...selectedProjectTypes.map((spt) => {
        return (
          projectTypes.find((pt) => pt.id == spt) || {
            id: spt,
            name: "",
          }
        );
      }),
    ];
    if (allProjectTypes.length > 2) {
      allProjectTypes.splice(1, 0, { id: "-2", name: this.tr("All tracked") });
    }
    return allProjectTypes;
  };

  getSettingsAndData = (filters) => {
    this.setState(
      {
        settingsLoaded: false,
      },
      () => {
        //const filters = this.refInsightBase.current.getFilters();
        this.getProjectTypes(filters.company);
        DataHandler.get({
          url: `settings/company/${filters.company}/goals`,
        }).done((response) => {
          const goalTypes = [...this.goalTypes];
          const selectedProjectTypes = response.project_types
            ? response.project_types.split(",")
            : [];
          const useCommitment = response.activate_commitment == 1;
          if (!useCommitment) {
            const index = goalTypes.findIndex(
              (g) => g.id == GOAL_TYPES.commitment
            );
            if (index != -1) {
              goalTypes.splice(index, 1);
            }
          }
          const goals = [];

          if (response.gross_profit == 1 || response.profit == 1) {
            goals.push(this.goals.find((g) => g.id == GOAL_IDS.gross_profit));
          }
          if (response.net_profit == 1) {
            goals.push(this.goals.find((g) => g.id == GOAL_IDS.net_profit));
          }
          if (response.gross_margin == 1 || response.margin == 1) {
            goals.push(this.goals.find((g) => g.id == GOAL_IDS.gross_margin));
          }
          if (response.net_margin == 1) {
            goals.push(this.goals.find((g) => g.id == GOAL_IDS.net_margin));
          }
          const goal_type = goals[0]?.id;
          const type = this.goalTypes[0]?.id;
          let project_type = undefined;
          if (selectedProjectTypes.length == 1) {
            project_type = selectedProjectTypes[0];
          } else if (selectedProjectTypes.length > 1) {
            project_type = "-2";
          }
          this.setState(
            {
              settingsLoaded: true,
              goals,
              goalTypes,
              selectedProjectTypes,
              defaultFilters: {
                goal_type,
                type,
                project_type,
                enterprise_groups: []
              },
            },
            () => {
              const filters = {
                ...this.refInsightBase.current.getFilters(),
                goal_type,
                type,
                project_type
              };
              //this.getData(filters);
            }
          );
        });
      }
    );
  };

  getData = (filters, company, extra, tab, afterCompanyChange) => {
    if (afterCompanyChange) {
      this.getSettingsAndData(filters);
      return;
    }
    this.setState(
      {
        overViewLoaded: false,
      },
      () => {
        const selectedGoal = this.goals.find((g) => g.id == filters.goal_type);
        const selectedGoalType = this.goalTypes.find(
          (g) => g.id == filters.type
        );
        const startDate = moment(filters.dateRange.startDate);
        this.setState({
          months: this.getMonths(startDate),
          selectedGoal,
          selectedGoalType,
        });
        DataHandler.post(
          {
            url: `insight/company/${filters.company}/goals/${
              filters.goal_type
            }/start_date/${startDate.format("YYYY-MM-DD")}`,
          },
          filters
        ).done((response) => {
          this.setState(
            {
              overViewLoaded: true,
              data: {
                ...this.state.data,
                ...response,
              },
            },
            () => {
              /*this.goalsVsActual.current &&
                this.goalsVsActual.current.getData(filters);*/
            }
          );
        });
        this.goalsVsActual.current &&
                this.goalsVsActual.current.getData(filters);
      }
    );
  };

  setLoaded = (loaded) => this.setState({ goalsVsActualLoaded: loaded });

  getFilters = () => {
    return this.refInsightBase.current.getFilters();
  };

  render() {
    const {
      functions: { hasPrivilege }, taimerAccount: { hasEnterpriseGroups }
    } = this.context;
    if (!hasPrivilege("dashboard", "goals_read")) {
      return <NoPermissionOverlay />;
    }
    const {
      data,
      defaultFilters,
      goals,
      goalTypes,
      selectedProjectTypes,
      selectedGoalType,
      selectedGoal,
      months,
      overViewLoaded,
      goalsVsActualLoaded,
      settingsLoaded,
    } = this.state;
    return (
      <div id="goals-insight">
        <InsightsBase
          ref={this.refInsightBase}
          allowDefaultFiltersUpdate
          useSearchButton={true}
          refreshAfterCompanyChange={true}
          defaultTime="year"
          defaultFilters={defaultFilters}
          right="goals_read"
          loaded={(overViewLoaded && goalsVsActualLoaded)}
          onRequestData={this.getData}
          insightFilterProps={{
            showPeriodSelector: false,
            noProjectFilter: true,
            showFiscalSelector: true,
            disableAdvancedSearchDimensions: true,
            dropFilters: ["customers", "account_manager", "customership_group"],
            customFilters: [
              {
                name: "goal_type",
                type: "select",
                label: this.tr("Goal Type"),
                options: goals,
              },
              {
                name: "type",
                type: "select",
                label: this.tr("Type"),
                options: goalTypes,
              },
              ...(selectedProjectTypes.length > 0
                ? [
                    {
                      name: "project_type",
                      type: "select",
                      label: this.tr("Project Type"),
                      options: this.getProjectTypesForFilterList(),
                    },
                  ]
                : []),
              hasEnterpriseGroups ?
              {
                name: 'enterprise_groups',
                type: 'multiselect',
                label: this.tr('Enterprise group'),
                optionsSource: 'enterprise_groups',
                returnAll: true
              } : undefined,
            ],
            advancedFilters: [],
            advancedSearchProps: {
              hideTextInput: true,
            },
          }}
        >
          <QuarterlyOverview
            width={6}
            months={months}
            monthTitles={this.monthTitles}
            selectedGoal={selectedGoal}
            selectedGoalType={selectedGoalType}
            data={data}
          />
          <MonthlyOverview
            months={months}
            data={data}
            monthTitles={this.monthTitles}
            selectedGoal={selectedGoal}
            selectedGoalType={selectedGoalType}
            width={6}
          />
          <GoalVsActual
            ref={this.goalsVsActual}
            width={6}
            months={months}
            getFilters={this.getFilters}
            setLoaded={this.setLoaded}
            monthTitles={this.monthTitles}
            selectedGoal={selectedGoal}
            selectedGoalType={selectedGoalType}
          />
        </InsightsBase>
      </div>
    );
  }
}

export default GoalsInsight;
