import { Close } from "@mui/icons-material";
import { Button, Drawer, Modal } from "@mui/material";
import { endOfWeek, startOfWeek } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";

import InsightDropDown from "../dashboard/insights/InsightDropDown";
import DataHandler from "../general/DataHandler";
import Utils from "../general/Utils";
import NewActivitiesList from "../list/lists/NewActivitiesList";
import "./ActivitySlider.css";

const newIcons = Utils.importAllIcons(require.context('../settings/img/Activity_icons/', false, /\.(png|jpe?g|svg)$/));

// Tried making this one with hooks & bit more modern react
const ActivitySlider = (props) => {
  const { open, onClose, project, weekStart, tr, onMove } = props;
  const [data, setData] = useState([]);
  const [mode, setMode] = useState("all");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [popUpComponent, setPopUpComponent] = useState(null);
  const [pageData, setPageData] = useState({ pageCount: 0, totalCount: 0 });
  const activitiesList = React.createRef();

  const columnKeys = [
    "actions",
    "type",
    "description",
    "status",
    "reminder",
    "contact",
    "user",
  ];

  const filtersByMode = (mode) => {
    let startDate;
    let endDate;
    let dueStartDate;
    let dueEndDate;
    let status = [1, 2, 3];
    const today = new Date();
    const options = { weekStartsOn: weekStart };
    switch (mode) {
      case "today":
        startDate = moment(today).format("YYYY-MM-DD");
        endDate = moment(today).format("YYYY-MM-DD");
        break;
      case "due_today":
        dueStartDate = moment(today).format("YYYY-MM-DD");
        dueEndDate = moment(today).format("YYYY-MM-DD");
        break;
      case "this_week":
        startDate = moment(startOfWeek(today, options)).format("YYYY-MM-DD");
        endDate = moment(endOfWeek(today, options)).format("YYYY-MM-DD");
        break;
      case "due_this_week":
        dueStartDate = moment(startOfWeek(today, options)).format("YYYY-MM-DD");
        dueEndDate = moment(endOfWeek(today, options)).format("YYYY-MM-DD");
        break;
      case "overdue":
        status = [3];
        break;
      default:
        break;
    }
    return { startDate, endDate, dueStartDate, dueEndDate, status };
  };

  const modes = [
    {
      key: "all",
      label: tr("All"),
      action: () => setMode("all"),
    },
    {
      key: "this_week",
      label: tr("Activity Date This Week"),
      action: () => setMode("this_week"),
    },
    {
      key: "today",
      label: tr("Activity Date Today"),
      action: () => setMode("today"),
    },
    {
      key: "due_this_week",
      label: tr("Due This Week"),
      action: () => setMode("due_this_week"),
    },
    {
      key: "due_today",
      label: tr("Due Today"),
      action: () => setMode("due_today"),
    },
    {
      key: "overdue",
      label: tr("Overdue"),
      action: () => setMode("overdue"),
    },
  ];

  const renderPopUp = () => {
    return (
      <Modal
        open={Boolean(popUpComponent)}
        onClose={() => setPopUpComponent(null)}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="popup-container"
        >
          {popUpComponent}
        </div>
      </Modal>
    );
  };

  const renderContent = () => {
    return (
      <div className="content">
        <div className="top-bar">
          <div className="left">
            <div>
              <h2
                onClick={() =>
                  window.open(
                    `index.html?module=projects&action=view&id=${project.id}`,
                    "_blank"
                  )
                }
              >
                {project ? project.name : ""}
              </h2>
              <h3
                onClick={() =>
                  window.open(
                    `index.html?module=customers&action=view&id=${project.customer_id}`,
                    "_blank"
                  )
                }
              >
                {project ? project.customer : ""}
              </h3>
            </div>
            {loading && (
              <img src={require("../dashboard/insights/img/loading.svg").default} />
            )}
          </div>
          <div className="right">
            <InsightDropDown title={tr("View")} tabs={modes} selected={mode} />
            <Button
              onClick={() => {
                setDataLoaded(false);
                setData([]);
                onClose();
              }}
            >
              <Close />
            </Button>
          </div>
        </div>
        <NewActivitiesList
          ref={activitiesList}
          companyId={(project || {}).companies_id}
          data={data}
          project={project}
          emptyOverlayTop={70}
          offsetTop={70}
          trimHeight={-20}
          height="fitRemaining"
          columnKeys={columnKeys}
          dataLoaded={dataLoaded}
          projectSliderMode={true}
          totalCount={pageData.totalCount}
          pageCount={pageData.pageCount}
          getData={getData}
          stopLoading={() => setLoading(false)}
          setPopUp={(c) => setPopUpComponent(c)}
          icons={newIcons}
          parentComponent={props.parentComponent + " - Activity Slider"}
        />
        {renderPopUp()}
      </div>
    );
  };

  const getData = (project, pageData, activityTypes) => {
    if (!project) {
      return;
    }

    setLoading(true);
    const filters = {
      sortby: "created",
      sortasc: false,
      company: project && project.companies_id,
      project: project && [project.id],
      types: ['project'],
      ...filtersByMode(mode),
      ...pageData,
    };
    DataHandler.post({ url: `activities/activities` }, filters).done(
      (response) => {
        const data = response.listActivities || [];
        //Icon data to listrows
        data.map((row, i) => {
          const activityType = (activityTypes || []).find((type) => type.id === row.type_id) || {};
          let icon = activityType.icon || "";
          if (!activityType.old_icon_set)
            icon = newIcons[icon];
          const newRow = {
            ...row,
            icon,
            key: row.projects_id ? "p" + row.id : "c" + row.id,
          };
          data[i] = newRow;
        });
        const totalCount = response.activitiesCount || 0;
        const pageCount = response.pageCount || 0;
        setDataLoaded(true);
        setLoading(false);
        setData(data);
        setPageData({ totalCount, pageCount });
        onMove();
      }
    );
  };

  useEffect(() => {
    activitiesList.current && activitiesList.current.getData();
  }, [mode]);

  return (
    <Drawer
      id="project-kanban-activity-slider"
      anchor="right"
      open={open}
      onClose={() => {
        setDataLoaded(false);
        setData([]);
        onClose();
      }}
    >
      {renderContent()}
    </Drawer>
  );
};

export default ActivitySlider;
