import React from 'react';
import { Check, Close, KeyboardArrowRight } from '@mui/icons-material';
import { Button, LinearProgress, Tooltip } from '@mui/material';
import TaimerComponent from '../../TaimerComponent';
import styles from './OnboardingSection.module.scss';
import { ReactComponent as setupAccountIcon } from './setupAccount.svg';
import { ReactComponent as salesQuoteIcon } from './salesQuote.svg';
import { ReactComponent as timeTrackerIcon } from './timeTracker.svg';
import { ReactComponent as expenseIcon } from './expense.svg';
import { ReactComponent as knowledgeBaseIcon } from './knowledgeBase.svg';
import { ReactComponent as latestUpdatesIcon } from './latestUpdates.svg';
import { ReactComponent as arrowIcon } from './arrow.svg';
import HoursWizard from '../../general/HoursWizard';
import Lottie from 'react-lottie';
import { cloneDeep } from 'lodash';
import ExpenseWizard from '../../expenses/ExpenseWizard';
import QuoteWizard from '../../general/QuoteWizard';
import VersionContentManager from '../../general/VersionContentManager';
import Link from '../../general/Link';
import Dialog from '../../dialogs/mass_operations/CoreDialog';
import SetupAccountWizard from '../../general/SetupAccountWizard';
import moment from 'moment';
const confetti = require('../../general/animations/confettiAnimation.json');
const bannerIcon = require('./bannerIcon.svg').default;

interface OnboardingSectionCard {
    id: string;
    title: string;
    subtitle: string;
    subtitleLong?: string;
    action: () => void;
    completed?: boolean;
    color: string;
    icon: any;
}

interface CardProps {
    card: OnboardingSectionCard;
    onSectionCompleted: (id: string) => void;
    onCardAction?: () => void;
    animateOut?: boolean;
    index?: number;
    sliderMode?: boolean;
}

interface CardState {
    playAnimation: boolean;
    completed?: boolean;
}

class Card extends TaimerComponent<CardProps, CardState> {
    lottie: any = React.createRef();
    constructor(props, context) {
        super(props, context, 'dashboard/my_day/OnboardingSectionCard');
        this.state = {
            playAnimation: false,
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        window.addEventListener(`onboardingCompleted_${this.props.card.id}`, this.onSectionCompleted);
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
        window.removeEventListener(`onboardingCompleted_${this.props.card.id}`, this.onSectionCompleted);
    };

    onSectionCompleted = () => {
        this.props.onSectionCompleted(this.props.card.id);
        this.setState({ playAnimation: true, completed: true }, () => {
            setTimeout(() => {
                this.setState({ playAnimation: false });
            }, 5000);
        });
    };

    render() {
        const { card, onCardAction, animateOut, index, sliderMode } = this.props;
        const { playAnimation } = this.state;
        const completed = card.completed || this.state.completed;
        return (
            <div className={`${styles.cardContainer} ${sliderMode ? styles.sliderMode : ''} ${animateOut ? styles.animateOut : ''}`} style={{ '--animation-stutter': index } as React.CSSProperties}>
                {playAnimation && (
                    <div className={styles.confetti}>
                        <Lottie
                            lottieRef={this.lottie}
                            options={{
                                loop: false,
                                autoplay: true,
                                animationData: confetti,
                            }}
                            speed={0.7}
                            height={'100%'}
                            width={'100%'}
                        />
                    </div>
                )}
                <div className={`${styles.card} ${completed ? styles.completed : ''}`}>
                    {!completed && <span className={styles.colorLine} style={{ backgroundColor: card.color }} />}
                    {completed && (
                        <div className={`${styles.completedIndicator} ${this.state.completed ? styles.animated : ''}`}>
                            <Check />
                        </div>
                    )}
                    <card.icon alt={card.title} style={{ fill: card.color }} />
                    <div className={styles.texts}>
                        <h2>{card.title}</h2>
                        <p>{card.subtitle}</p>
                    </div>
                    <Button
                        onClick={() => {
                            onCardAction && onCardAction();
                            card.action();
                        }}
                        size="large"
                        classes={{ root: completed ? styles.completed : '' }}
                    >
                        {completed ? this.tr('Completed') : this.tr('Try now')}
                    </Button>
                </div>
            </div>
        );
    }
}

interface OnboardingSectionState {
    cards: { [key: string]: OnboardingSectionCard };
    animationStatus: number;
    onboardingStatus: number;
    completed?: boolean;
    hide?: boolean;
}

interface OnboardingSectionProps {
    sliderMode?: boolean;
    onCardAction?: () => void;
}

class OnboardingSection extends TaimerComponent<OnboardingSectionProps, OnboardingSectionState> {
    finishCards: any[];
    constructor(props, context) {
        super(props, context, 'dashboard/my_day/OnboardingSection');
        this.finishCards = [
            {
                id: 'knowledgeBase',
                title: this.tr('Continue learning more...'),
                subtitle: this.tr('Visit knowledge base'),
                url: this.context.userObject.language == 'fi' ? ' https://psahelpcenter.heeros.com/hc/fi' : 'https://psahelpcenter.heeros.com/hc/en-us',
                color: '#F7BC59',
                icon: knowledgeBaseIcon,
            },
            {
                id: 'latestUpdates',
                title: this.tr("See what's new!"),
                subtitle: this.tr('Latest updates'),
                url: this.context.userObject.language == 'fi' ? 'https://psahelpcenter.heeros.com/hc/fi/categories/6457343331346' : 'https://psahelpcenter.heeros.com/hc/en-us/categories/6457343331346',
                color: '#F7548F',
                icon: latestUpdatesIcon,
            },
            {
                id: 'supportLinks',
                title: this.tr("Pro tip! In the future you'll find support links by clicking your profile picture!"),
                subtitle: '',
                action: () => {},
                variant: 'filled',
                color: '#2D9FF7',
                icon: arrowIcon,
            },
        ];
        const cards = {
            setup: {
                id: 'setup',
                title: this.tr('Setup your account'),
                subtitle: this.tr("Let's get you up and running by filling in your company details."),
                action: () => {
                    this.context.functions.setOverlayComponent(<SetupAccountWizard sliderMode={this.props.sliderMode} />);
                },
                color: '#003A78',
                icon: setupAccountIcon,
            },
            quote: {
                id: 'quote',
                title: this.tr('Create your first sales quote'),
                subtitle: this.tr('Learn how to create, send and get approval for your sales quote and proposal.'),
                action: () => {
                    this.context.functions.sendMixpanelEvent('sales_quote_wizard_started');
                    this.context.functions.sendMixpanelPeople('set', {
                        "sales_quote_wizard_start_date": new Date().toISOString(),
                    });
                    this.context.functions.setOverlayComponent(<QuoteWizard onboardingMode sliderMode={this.props.sliderMode} />);
                },
                color: '#EC008A',
                icon: salesQuoteIcon,
            },
            hours: {
                id: 'hours',
                title: this.context.functions.isUserOwner() ? this.tr('Setup & test the time tracker') : this.tr('Learn how to track hours'),
                subtitle: this.tr('Take a guided tour and learn all the different ways to track hours in Taimer.'),
                action: () => {
                    this.context.functions.sendMixpanelEvent('track_hours_wizard_started');
                    this.context.functions.sendMixpanelPeople('set', {
                        "track_hours_wizard_start_date": new Date().toISOString(),
                    });
                    this.context.functions.setOverlayComponent(<HoursWizard sliderMode={this.props.sliderMode} />);
                },
                color: '#20C6A1',
                icon: timeTrackerIcon,
            },
            expense: {
                id: 'expense',
                title: this.context.functions.isUserOwner() ? this.tr('Setup & test expense creation') : this.tr('Learn how to create an expense'),
                subtitle: this.tr('Take a guided tour and see how easy it is to create an expense in Taimer.'),
                action: () => {
                    this.context.functions.sendMixpanelEvent('expenses_wizard_started');
                    this.context.functions.sendMixpanelPeople('set', {
                        "expenses_wizard_start_date": new Date().toISOString(),
                    });
                    this.context.functions.setOverlayComponent(<ExpenseWizard expenseType="1" onBoarding={true} />);
                },
                color: '#6F42C1',
                icon: expenseIcon,
            },
        };
        Object.keys(cards).map((cKey) => {
            cards[cKey] = {
                ...cards[cKey],
                completed: this.context.userObject.completedOnboardingItems.indexOf(cKey) != -1,
            };
        });
        this.state = {
            cards,
            animationStatus: 1,
            onboardingStatus: this.context.userObject.onboardingStatus,
            completed: this.context.userObject.onboardingStatus == 3,
        };
    }

    componentDidMount(): void {
        super.componentDidMount();
        window.addEventListener(`onboardingStatusChanged_1`, this.show);
        window.addEventListener(`onboardingStatusChanged_-1`, this.hide);
        window.addEventListener(`onboardingCompleted`, this.onOnboardingCompleted);
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        window.removeEventListener(`onboardingStatusChanged_1`, this.show);
        window.removeEventListener(`onboardingStatusChanged_-1`, this.hide);
        window.removeEventListener(`onboardingCompleted`, this.onOnboardingCompleted);
    }

    sortByCompleted = (a, b) => Number(this.state.cards[b]?.completed || false) - Number(this.state.cards[a]?.completed || false);

    onSectionCompleted = (id) => {
        const cards = cloneDeep(this.state.cards);
        cards[id] = {
            ...cards[id],
            completed: true,
        };
        this.setState({ cards });
    };

    show = () => {
        if (this.props.sliderMode) return;
        this.setState({ hide: false, onboardingStatus: 1 }, () => {
            setTimeout(() => {
                this.setState({ hide: undefined });
            }, 2000);
        });
    };

    hide = () => {
        if (this.props.sliderMode) return;
        this.setState({ hide: true }, () => {
            setTimeout(() => {
                this.setState({ onboardingStatus: -1 });
            }, 2000);
        });
    };

    hideOnboarding = () => {
        this.context.functions.closeDialog();
        this.context.functions.setOnboardingStatus(-1);
    };

    onHideOnboarding = () => {
        this.context.functions.showDialog(
            <Dialog
                onDialogClose={this.context.functions.closeDialog}
                onDialogSave={this.hideOnboarding}
                dialogType={'delete'}
                dialogProps={{
                    wider: true,
                    onCloseClick: this.context.functions.closeDialog,
                    open: true,
                    close: this.context.functions.closeDialog,
                    confirmDisabled: false,
                    header: this.tr('Hide onboarding items?'),
                    translatedConfirmButtonText: this.tr('Hide onboarding'),
                    warning: () => (
                        <p>{this.tr("This box will be hidden from My Day. You'll still be able to access the onboarding items from the top navigation bar's onboarding progress indicator.")}</p>
                    ),
                    onConfirm: this.hideOnboarding,
                }}
            />
        );
    };

    onFinishOnboarding = () => {
        const analyticsData = {
            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
            taimer_version: this.context.versionId,
            taimer_language: this.context.userObject.language,
        };
        this.context.functions.sendMixpanelEvent('onboarding_finished');
        this.setState({ animationStatus: 3, hide: true }, () => {
            this.context.functions.setOnboardingStatus(3);
            setTimeout(() => {
                this.setState({ onboardingStatus: 3 });
            }, 1000);
        });
    };

    renderCard = (cardId: string, index: number) => {
        const card = this.state.cards[cardId];
        if (!card) return null;
        return (
            <Card
                sliderMode={this.props.sliderMode}
                key={card.id}
                card={card}
                onSectionCompleted={this.onSectionCompleted}
                onCardAction={this.props.onCardAction}
                animateOut={this.state.animationStatus == 2}
                index={index}
            />
        );
    };

    renderFinishCard = (card: any, index: number) => {
        return (
            <div
                className={`${styles.cardContainer} ${styles.finishCardContainer} ${this.state.animationStatus == 2 ? styles.animateIn : ''}`}
                style={{ '--animation-stutter': index } as React.CSSProperties}
            >
                <div className={`${styles.finishCard} ${card.variant == 'filled' ? styles.filled : ''}`}>
                    {<span className={styles.colorLine} style={{ backgroundColor: card.color }} />}
                    <div className={styles.texts}>
                        <h2>{card.title}</h2>
                        {card.subtitle && (
                            <Link url={card.url} openInNewTab>
                                {card.subtitle}
                                <KeyboardArrowRight />
                            </Link>
                        )}
                    </div>
                    {card.icon && <card.icon />}
                </div>
            </div>
        );
    };

    onOnboardingCompleted = () => {
        if (this.props.sliderMode) return;
        const onboardingItems = VersionContentManager.getOnboardingItems();
        onboardingItems.forEach((item) => {
            window.dispatchEvent(new Event(`onboardingCompleted_${item}`));
        });
        this.setState({ completed: true }, () => {
            setTimeout(() => {
                this.setState({ animationStatus: 2 }, () => {
                    setTimeout(() => {
                        this.setState({ onboardingStatus: 2 });
                    }, 1000);
                });
            }, 2000);
        });
    };

    render() {
        const { sliderMode } = this.props;
        const { animationStatus, onboardingStatus, completed, hide } = this.state;
        const { completedOnboardingItems, fullname } = this.context.userObject;
        const onboardingItems = VersionContentManager.getOnboardingItems();
        const names = (fullname || '').split(' ').reverse();
        const firstName = names[0];
        const progress = Math.round((completedOnboardingItems.length / onboardingItems.length) * 100);
        const isOdin = window.location.href.indexOf(':8080') > -1;
        if (!sliderMode && onboardingStatus == -1) return null;
        if ((this.context.taimerAccount.onboardingSeen == 1 && !isOdin) || !this.context.addons.new_stripe || onboardingItems.length == 0 || onboardingStatus == 3) {
            return (
                <section className={styles.onboardingSection}>
                    <div className={styles.content}>
                        <div className={styles.top}>
                            <div className={styles.titles}>
                                <h1>{this.tr('Hi ${firstName}', { firstName })}</h1>
                                <p>{this.tr("Ready to see what's in store for you today? Have a quick look!")}</p>
                            </div>
                        </div>
                        <img src={bannerIcon} />
                    </div>
                </section>
            );
        }
        if (onboardingStatus == 2) {
            return (
                <section className={`${styles.onboardingSection} ${hide ? styles.hidePartially : hide == false ? styles.show : ''}`}>
                    <div className={styles.content}>
                        {!sliderMode && (
                            <div className={styles.top}>
                                <div className={styles.titles}>
                                    <h1>{animationStatus == 3 ? this.tr('Hi ${firstName}', { firstName }) : this.tr('Great job, ${firstName}', { firstName })}</h1>
                                    <p>{animationStatus == 3 ? this.tr("Ready to see what's in store for you today? Have a quick look!") : this.tr('Your first onboarding steps are completed!')}</p>
                                </div>
                                <div className={`${styles.right} ${animationStatus == 2 ? styles.animateIn : animationStatus == 3 ? styles.animateOut : ''}`}>
                                    <Button size="large" onClick={this.onFinishOnboarding}>
                                        {this.tr('Finish onboarding')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {animationStatus == 3 && <img src={bannerIcon} className={styles.animateIn} />}
                        <div className={styles.cards}>{this.finishCards.map(this.renderFinishCard)}</div>
                    </div>
                </section>
            );
        }
        return (
            <section className={`${styles.onboardingSection} ${hide ? styles.hide : hide == false ? styles.show : ''}`}>
                <div className={styles.content}>
                    {!sliderMode && (
                        <div className={styles.top}>
                            <div className={styles.titles}>
                                <h1>{completed ? this.tr('Great job, ${firstName}', { firstName }) : this.tr('Welcome onboard, ${firstName}', { firstName })}</h1>
                                <p>{completed ? this.tr('Your first onboarding steps are completed!') : this.tr('Let our wizards help you get started')}</p>
                            </div>
                            <div className={`${styles.right} ${animationStatus == 2 ? styles.animateOut : ''}`}>
                                <div className={styles.progress}>
                                    <Tooltip title={this.tr('${progress}% of onboarding completed', { progress })}>
                                        <LinearProgress variant="determinate" value={progress} />
                                    </Tooltip>
                                    <p>{this.tr('${progress}% of onboarding completed', { progress })}</p>
                                </div>
                                <Tooltip title={this.tr("Don't show this")}>
                                    <Button onClick={this.onHideOnboarding} className={styles.cancelButton}>
                                        <Close />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                    <div className={styles.cards}>{onboardingItems.sort(this.sortByCompleted).map(this.renderCard)}</div>
                </div>
            </section>
        );
    }
}

export default OnboardingSection;
