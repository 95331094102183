import React from "react";
import TaimerComponent from "../TaimerComponent";
import DataList from './DataList';
import MassDialog from '../dialogs/mass_operations/CoreDialog';
import { AddAccount } from './no-options/AddItemComponents';
import DataHandler from './DataHandler';
import "./SupplierSelect.css";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

class SupplierSelect extends TaimerComponent {

    constructor(props, context) {
        super(props, context, "general/SupplierSelect");
        this.state = {
            suppliers: this.initSuppliers(props.options, props.showArchivedAccounts),
            confirmationDialogOpen: false,
            dialogData: {}
        }
    }

    initSuppliers(options, showArchivedAccounts, updatedAccountId = 0) {
        let suppliers = cloneDeep(options);
        suppliers = suppliers.map(s => {
            if (s.id == updatedAccountId) {
                s.account_type = "supplier";
                s.locked = -1;
            }
            let additionalText = [];
            s.account_type == "supplier" && additionalText.push(this.tr("Supplier"));
            s.locked == 1 && additionalText.push(this.tr("Archived"));
            s.additionalText = additionalText.length > 0 ? "(" + additionalText.join(", ") + ")" : "";
            return s;
        });

        return showArchivedAccounts ? suppliers : suppliers.filter(s => s.locked != 1);
    }
   
    componentWillReceiveProps(nextProps) {
        if(!isEqual(this.props.options, nextProps.options) || this.props.showArchivedAccounts != nextProps.showArchivedAccounts) {
            const suppliers = this.initSuppliers(nextProps.options, nextProps.showArchivedAccounts);
            this.setState({ suppliers });
        }
    }

    onChange = (supplier) => {
        const { onChange} = this.props;
        if (supplier.locked == 1 || supplier.account_type != "supplier") {
            this.openConfirmationDialog(supplier);
        }
        else {
            onChange && onChange(supplier);
        }
    }

    updateAccount = (supplier) => {
        if (supplier.account_type != "supplier") {
            const relationData = {
                typeName: "sub_contractor",
                customers_id: supplier.id,
                companies_id: this.props.company,
                allow_project_creation: 1,
                r_locked: -1,
                c_locked: -1
            }
            DataHandler.post({ url: `accounts/relations/new` }, { data: relationData }).done(e => {
                const suppliers = this.initSuppliers(this.state.suppliers, this.props.showArchivedAccounts, supplier.id);
                this.setState({ suppliers });
                this.props.accountUpdated && this.props.accountUpdated(supplier);
            })
            .fail(err => {
                this.props.enqueueSnackbar && this.props.enqueueSnackbar(this.tr("Error in updating account!"), {
                    variant: "error"
                });
            });
        }
        else {
            const relationData = {
                only_locked_update: 1,
                customers_id: supplier.id,
                companies_id: this.props.company,
                locked: -1
            }
            DataHandler.put({ url: `accounts/relations/sub_contractor` }, { data: relationData }).done(e => {
                const suppliers = this.initSuppliers(this.state.suppliers, this.props.showArchivedAccounts, supplier.id);
                this.setState({ suppliers });
                this.props.accountUpdated && this.props.accountUpdated(supplier);
            })
            .fail(err => {
                this.props.enqueueSnackbar && this.props.enqueueSnackbar(this.tr("Error in updating account!"), {
                    variant: "error"
                });
            });
        }
        this.closeConfirmationDialog();
    }

    openConfirmationDialog = (supplier) => {
        const data = {
            confirmFunc: () => this.updateAccount(supplier),
            header: this.tr("Update account details?"),
            warning: this.renderConfirmationContent(supplier),
            translatedConfirmButtonText: this.tr("Update"),
            confirmDisabled: supplier.writeable == 0
        }
        this.setState({ confirmationDialogOpen: true, dialogData: data });
    }

    renderConfirmationContent = (supplier) => {
        const isMulticompany = this.context.taimerAccount.isMulticompany;
        const supplierStatus = supplier.account_type + "_" + (supplier.locked == 1 ? "locked" : "active"); 
        let updateInfo = [];

        switch (supplierStatus) {
            case 'supplier_locked':
                if (isMulticompany)
                    updateInfo.push(this.tr("Activate supplier relation"));
                else 
                    updateInfo.push(this.tr("Activate account"));
              break;
            case 'customer_locked':
                if (isMulticompany)
                    updateInfo.push(this.tr("Add active supplier relation"));
                else {
                    updateInfo.push(this.tr("Add supplier type"));
                    updateInfo.push(this.tr("Activate account"));
                }
                break;
            case 'customer_active':
                if (isMulticompany)
                    updateInfo.push(this.tr("Add active supplier relation"));
                else {
                    updateInfo.push(this.tr("Add supplier type"));
                }
                break;
        }

        let infoText = "";
        if (supplier.writeable == 1) 
            infoText = this.tr("Update account so it can be selected as supplier (has to have supplier relation and be active)?");
        else 
            infoText = this.tr("Account has to have supplier relation and be active to be selectable as supplier. You don't have permission to update this account.");

        return (
            <>
                <p><b>{this.tr("Selected account")}: </b>{supplier.name}</p>
                <p>{infoText}</p>
                <ul>
                    {updateInfo.map(info => {
                        return <li>{info}</li>
                    })}
                </ul>
            </>
        );
    } 

    closeConfirmationDialog = () => {
        this.setState({ confirmationDialogOpen: false, dialogData: {} });
    }

    renderConfirmationDialog = () => {
        return (
            <MassDialog
                onDialogClose={this.closeConfirmationDialog}
                onDialogSave={this.state.dialogData.confirmFunc}
                dialogType={"delete"}
                dialogProps={{
                    translatedConfirmButtonText: this.state.dialogData.translatedConfirmButtonText,
                    confirmDisabled: this.state.dialogData.confirmDisabled,
                    confirmButtonClass: "blue",
                    onCloseClick: this.closeConfirmationDialog,
                    open: this.state.confirmationDialogOpen,
                    close: this.closeConfirmationDialog,
                    header: this.state.dialogData.header,
                    warning: () => {
                        return this.state.dialogData.warning;
                    },
                    onConfirm: () => {
                       this.state.dialogData.confirmFunc();
                    }
                }}
            />
        )
    }

    render() {
        const { isDisabled, value, label, shownCount, className, supplierCreated, company, error, placeholder, fromLabelFieldGroup } = this.props;
        const { suppliers } = this.state;
        const { functions: { checkPrivilege } } = this.context;

        return (
            <>
            {this.state.confirmationDialogOpen && this.renderConfirmationDialog()}
            <div className={fromLabelFieldGroup ? "supplier-select-field-group" : "supplier-select-field"}>
            <DataList 
                name={this.props.name}
                error={error}
                label={label} 
                value={value} 
                placeholder={placeholder} 
                options={suppliers}
                noOptions={AddAccount}
                companies_id={company}
                noOptionsProps={{accountType: "sub_contractor", company: company}}
                company={company}
                onItemCreated={(supplier) => supplierCreated && supplierCreated(supplier)}
                className={className}
                isDisabled={isDisabled} 
                shownCount={shownCount}
                onChange={supplier => {
                    this.onChange(supplier);
                }}
            />
            </div>
            </>);
    }	
}

export default SupplierSelect;