import React from 'react';
import ListRow from "../../../list/ListRow";
import ListCell from '../../../list/ListCell';

import styles from './InvoiceMaterialBlock.module.scss';
import cn from 'classnames';
import { CurrencyUtils } from "../../../general/CurrencyUtils";

export default class InvoiceMaterialBlockSummaryRow extends ListRow {
    constructor(props) {
        super(props, {}, {}, "customview/blocks/InvoiceMaterial/InvoiceMaterialBlock");
    }

    defineClassName() {
        return cn("invoice-material-block-summary-row");
    }

    defineCells() {

        const { rows, sharedData: { currency } } = this.props;
        const { taimerAccount, functions: { presentCurrency } } = this.context;

        let values = {};

        rows.forEach(row => {
            const total = row.row_type == "quote_row" 
                ? Number(CurrencyUtils.calculateNetTotal(row.value, row.quantity, 2)) 
                : Number(row.quantity * row.value || 0);
            
            values['total']
                ? (values['total'] += total)
                : (values['total'] = total);
        });

        let cells = {};

        cells['context'] = (
            <ListCell
                key={'context'}
                width={"50%"}
                className={styles.rowtitle}
                name={'context'}
                onlyDisplay
                editable={false}
                alignCell
                textAlign={"left"}
            >
                {this.tr("Total")}
            </ListCell>
        );

        const totalColumns = [
            "total",
        ];

        totalColumns.forEach((col) => {
            cells[col] = (
                <ListCell
                    key={col}
                    width={"50%"}
                    className={styles.alignRight}
                    name={col}
                    onlyDisplay
                    editable={false}
                    alignCell
                    textAlign={"right"}
                >
                    {Number(values[col]) != '0' && values[col] ? presentCurrency(values[col].toFixed(2), currency) : '-'}
                </ListCell>
            );
        });

        return cells;
    }

    render() {
        const cells = this.defineCells();
        return (
            <div className={styles[this.defineClassName()]} style={{ height: `50px` }}>
                {this.props.columnOrder.map((columnName) => cells[columnName])}
                <div
                    className={styles.alignLeft}
                >
                    {this.tr('Total')}
                </div>
                {cells['total']}
            </div>
        );
    }
}
