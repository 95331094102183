import React, { ReactElement } from 'react';
import { hexColorWithOpacity } from '../helpers';
import styles from './Indicator.module.scss';

import { Tooltip } from '@mui/material';

interface Props {
    color: string;
    text: string;
    icon?: ReactElement;
    style?: object;
}

/**
 * An indicator element with an icon and text.
 *
 * @property {string} color is used to determine the color of the label,
 * and the same color is used in the background with a lower opacity.
 * @property {string} text is the text shown in the label. Is styled with CSS to always be uppercase.
 * @property {ReactElement} icon is the icon shown next to the label.
 */
const renderIndicator = (props: Props) => {
    const { color, text, style, icon } = props;
    return (
        <div
            style={{
                backgroundColor: color && hexColorWithOpacity(color, 0.15),
                color: color,
                ...style,
            }}
            className={styles.indicator}
        >
            {icon && React.cloneElement(icon, { style: { color, fill: color } })}
            {text}
        </div>
    );
};
const Indicator = (props) => {
    const { tooltip } = props;

    if (tooltip) {
        const tooltipProps = typeof props.tooltip === 'object' ? tooltip : { title: tooltip, arrow: true };

        return (
            <div>
                <Tooltip {...tooltipProps}>{renderIndicator(props)}</Tooltip>
            </div>
        );
    } else return renderIndicator(props);
};

export default Indicator;
