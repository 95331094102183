import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningIcon from "@mui/icons-material/Warning";
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

import './Quickbooks.css';

class Quickbooks extends TaimerComponent {
    
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Quickbooks");

        this.state = {
            dialogOpen: false
        }
    }

    dialogOpen = () => {
        const open = this.state.dialogOpen;
        this.setState({dialogOpen: !open});
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''})
    }

    quickbookAuth = async () => {
        const { company } = this.props;

        await DataHandler.get({url: `settings/company/${company}/quickbooks/link`}).done(data => {   
            sessionStorage["nextAction"] = "settings-accounting";
            window.location = data.url;
        });

    }

    deactivate = () => {
        const { company } = this.props;
        const name = "quickbooks";
        DataHandler.post({url: `settings/integrations/${name}/${company}/deactivate`}).done(response => {
            if (response == "true"){
                this.props.fetchData();
            }
        }).done(() => {
            this.back();
        });
    }

    render(){
        const {active} = this.props;
        const { tr } = this;
        return (
            <div id="integration-quickbooks" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Quickbooks Settings")}</h3>
                </div>
                {!active ?
                    <div className="settings">
                        <h4>{tr("Activate Quickbooks")}</h4>
                        <Button color="primary" onClick={this.quickbookAuth} size="large">{tr("Activate")}</Button>
                    </div>
                    :
                    <div className="settings">
                        <h4>{tr("Deactivate Quickbooks")}</h4>
                        <Button className="deactivate" onClick={this.dialogOpen} size="large">{tr("Deactivate")}</Button>
                     
                        <Dialog
                            open={this.state.dialogOpen}
                            >
                            <DialogTitle><WarningIcon style={{ marginRight: 16 }} /> {tr("Deactivate Quickbooks?")}</DialogTitle>
                            <DialogContent>
                                    <DialogContentText>
                                        {tr("Are you sure you want to deactivate Quickbooks?")}
                                    </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={this.dialogOpen}>
                                    {tr("Cancel")}
                                </Button>
                                <Button onClick={this.deactivate} style={{background: "#d0021b", color: "#ffffff"}}>
                                    {tr("Deactivate")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
            </div>
        )
    }
}

export default Quickbooks;