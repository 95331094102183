import React, { Component } from 'react';
import DialogBorder from './../../dialogs/DialogBorder';
import OutlinedField from './../../general/OutlinedField';
import DataHandler from './../../general/DataHandler';
import TaimerComponent from './../../TaimerComponent';
import { SettingsContext } from './../../SettingsContext';
import _ from 'lodash';

export default class AddToMailingListDialog extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context)
	{
		super(props, context, "list/dialogs/CreateMailingListDialog");
        this.createMailingList	= this.createMailingList.bind(this);

    }
   
    createMailingList(name, value){ 
        const contacts = _.map(this.props.contacts, 'relation_id'); 
        const listname = document.getElementById("mailing-list-name").value;
        
        DataHandler.post({url: `company/${this.props.company}/mail_list_create`}, {contacts, listname}).done(() =>  this.props.onClose());
        
    }

	render() {
        return <DialogBorder className="CreateMailingListDialog" id="dialog" textOk={this.tr("Create")} title={this.tr("Create mailing list")} onClose={this.props.onClose} onSave={this.createMailingList}>
			<div id="container" className="CreateMailingListDialog">
                <OutlinedField id="mailing-list-name" fullWidth ></OutlinedField>
			</div>
		</DialogBorder>
	}
}
