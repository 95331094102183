import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import React from "react";
import TaimerComponent from "../../TaimerComponent";
import { AutocompleteData, getAutocompleteDataForDialog } from '../helpers';
import DataHandler from "../../general/DataHandler";

import styles from "./SplitTaskDialog.module.scss";
import OutlinedField from "../../general/OutlinedField";
import { DatePicker, DateRangePicker } from "../../general/react-date-range/src";
import { format, parse, addDays, eachDayOfInterval, getDay, startOfDay } from 'date-fns';
import _, { throttle } from 'lodash';
import cn from 'classnames';
import SplitTaskDialogUserRow from "./SplitTaskDialogUserRow";
import { withSnackbar, WithSnackbarProps } from "notistack";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import InfoIcon from '@mui/icons-material/Info';
import { Task } from "../resourcing";

interface Props extends WithSnackbarProps {
	open: boolean;
	task: Task;
	onSave?: () => void;
	onCancel: () => void;
}

interface TaskPart {
	name: string;
	days: Date[];
	holidays: number;
}

interface SplitUser {
	id: number;
	name?: string;
	role?: string;
	hours?: number;
	tracked?: number;
	firstPart?: number;
	secondPart?: number;
	firstPartTracked?: number;
	secondPartTracked?: number;
	first_hours?: number;
	second_hours?: number;
}

interface State {
	date: Date;
	ready: boolean;
	autocomplete: AutocompleteData | null;
	holidays: Dictionary<boolean>;
	companies_id: number;
	firstPart: TaskPart;
	secondPart: TaskPart;
	users: SplitUser[];
	/**
	 * user -> day -> hours
	 */
	tracked: Dictionary<Dictionary<number>>;
	isSaving: boolean;
	createSeparateTasks: boolean;
	hoursEdited: boolean;
}

export default withSnackbar(class SplitTaskDialog extends TaimerComponent<Props, State> {
	constructor(props, context) {
		super(props, context, 'resourcing/dialogs/SplitTaskDialog');
		this.state = {
			date: startOfDay(new Date()),
			ready: false,
			autocomplete: null,
			holidays: {},
			companies_id: 0,
			firstPart: {
				name: '',
				days: [],
				holidays: 0,
			},
			secondPart: {
				name: '',
				days: [],
				holidays: 0,
			},
			users: [],
			tracked: {},
			isSaving: false,
			createSeparateTasks: true,
			hoursEdited: false,
		};

		if (props.task)
			this.initializeData();
	}

	componentDidUpdate(prevProps, prevState) {
		const { task } = this.props;

		if (prevProps.task !== task) {
			this.setState({
				date: startOfDay(new Date()),
				ready: false,
				autocomplete: null,
				holidays: {},
				companies_id: 0,
				firstPart: {
					name: '',
					days: [],
					holidays: 0,
				},
				secondPart: {
					name: '',
					days: [],
					holidays: 0,
				},
				users: [],
				tracked: {},
				createSeparateTasks: true,
				hoursEdited: false,
			}, () => {
				if (task)
					this.initializeData();
			});
		}
	}

	initializeData = async () => {
		const { tr } = this;
		const { task } = this.props;
		const { firstPart, secondPart } = this.state;

		const [autocomplete, holidays, tracked] = await Promise.all([
			getAutocompleteDataForDialog(task.companies_id),
			DataHandler.get({ url: "settings/holidays", company: task.companies_id }),
			DataHandler.get({ url: `resourcing/task/${task.id}/tracked`, company: task.companies_id }),
		]);

		this.setState({
			autocomplete,
			holidays: _.keyBy(holidays, x => x.date),
			tracked,
			createSeparateTasks: true,
			firstPart: {
				...firstPart,
				name: task.description,
			},
			secondPart: {
				...secondPart,
				name: task.description  + ` ${tr('(Split)')}`,
			},
		}, () => this.calculateSplits());
	}

	cancel = () => {
		const { onCancel } = this.props;

		onCancel();
	}

	onDateChange = date => {
		this.setState({
			date,
		}, () => this.calculateSplits());
	};

	onDateInputChange = (text, date) => {
		this.setState({
			date
		}, () => this.calculateSplits());
	};

	onChangeCheckbox = ({ target: { name, checked } }) => {
		const { task } = this.props;
		const { secondPart } = this.state;
		const { tr } = this;

		if (name === "createSeparateTasks") {
			this.setState({
				createSeparateTasks: Boolean(checked),
				secondPart: {
					...secondPart,
					name: task.description + ` ${tr('(Split)')}`,
				},
			});

		}
	}

	calculateSplits = () => {
		const { task } = this.props;
		const { autocomplete } = this.state;

		if (!autocomplete) {
			console.error('calculateSplits called before data was loaded');
			return;
		}

		const {
			firstPart: fp, 
			secondPart: sp, 
			date,
			holidays,
			tracked, 
			hoursEdited,
		} = this.state;
		const { employees, resourcing_count_saturday, resourcing_count_sunday } = autocomplete;

		const firstPart: TaskPart = {
			...fp,
			days: [],
			holidays: 0,
		};
		const secondPart: TaskPart = {
			...sp,
			days: [],
			holidays: 0,
		};

		const users: SplitUser[] = [];

		const days = eachDayOfInterval({
			start: task.start_date,
			end: task.end_date,
		});

		let totalDays = 0;

		for (const day of days) {
			const part = day < date ? firstPart : secondPart;

			const d = format(day, 'YYYY-MM-DD');
			const dayOfWeek = getDay(day);

			// TODO: Migrate to Workdays
			if (!!holidays[d] || (!resourcing_count_sunday && dayOfWeek === 0) || (!resourcing_count_saturday && dayOfWeek === 6)) {
				part.holidays++;
				continue;
			}

			part.days.push(day);
			totalDays++;
		}

		const currentUsers = _.keyBy(this.state.users, x => x.id);

		for (const u of task.users_hours) {
			const user = employees.find(x => Number(x.id) === Number(u.users_id));

			const hours = Number(u.hours);
			const perDay = (hours / totalDays) || 0;

			let firstPartTracked = 0;
			let secondPartTracked = 0;

			_.forEach(tracked[u.users_id] || {}, (v, k) => {
				const d = parse(k, 'YYYY-MM-DD', new Date());
				const part = d < date ? 'first' : 'second';

				if (part === 'first') {
					firstPartTracked += v;
				} else if (part === 'second') {
					secondPartTracked += v;
				}
			});

			const overrides: Partial<SplitUser> = {};
			const currentUser = currentUsers[u.users_id];

			if (currentUser && hoursEdited) {
				const { firstPart, secondPart } = currentUser;
				overrides.firstPart = firstPart;
				overrides.secondPart = secondPart;
			}

			const row: SplitUser = {
				id: u.users_id,
				name: user?.name ?? "-",
				role: user?.role,
				hours,
				tracked: Number(u.hours_done),
				firstPart: Number((perDay * firstPart.days.length).toFixed(2)),
				secondPart: Number((perDay * secondPart.days.length).toFixed(2)),
				firstPartTracked,
				secondPartTracked,
				...overrides,
			};

			users.push(row);
		}

		this.setState({
			ready: true,
			firstPart,
			secondPart,
			users,
		});
	}

	header(part: TaskPart) {
		const { userObject } = this.context;

		if (part.days.length === 0) {
			return '-';
		}

		const start = _.first(part.days);
		const last = _.last(part.days);

		if (!start || !last) {
			return '-';
		}

		return `${format(start, userObject.dateFormat)} - ${format(last, userObject.dateFormat)}`;
	}

	changeUser = (user, update) => {
		const { users } = this.state;

		const index = users.findIndex(x => x.id === user.id);

		if (index > -1) {
			const updated = [...users];
			updated[index] = {
				...(users[index]),
				...update,
			};

			this.setState({ hoursEdited: true, users: updated });
		}
	}

	onChangeTaskName = ({ target: { name, value } }) => {
		const { secondPart, createSeparateTasks } = this.state;

		if (name === "task1") {
			const { firstPart } = this.state;
			this.setState({
				firstPart: {
					...firstPart,
					name: value,
				},
			});
		} else if (name === "task2") {
			this.setState({
				secondPart: {
					...secondPart,
					name: value,
				},
			});
		}
	}

	save = throttle(async () => {
		const { task, onSave, enqueueSnackbar } = this.props;
		const { isSaving, date, users, firstPart, secondPart, createSeparateTasks } = this.state;

		if (firstPart.days.length === 0 || secondPart.days.length === 0) {
			if (firstPart.holidays && !firstPart.days.length) {
				enqueueSnackbar(this.tr("Task 1 has no working days."), { variant: "error" });
			} else if (secondPart.holidays && !secondPart.days.length) {
				enqueueSnackbar(this.tr("Task 2 has no working days."), { variant: "error" });
			} else {
				enqueueSnackbar(this.tr("Split Date must be between start and end date."), { variant: "error" });
			}

			return;
		}

		if (users.find(x => x.firstPart === undefined || x.secondPart === undefined || x.firstPart < 0 || x.secondPart < 0)) {
			enqueueSnackbar(this.tr("Negative hours are not allowed."), { variant: "error" });

			return;
		}

		if (isSaving)
			return;

		this.setState({ isSaving: true });

		try {
			const res = await DataHandler.post({ url: `resourcing/task/${task.id}/split` }, {
				date: format(date, 'YYYY-MM-DD'),
				separate: createSeparateTasks,
				task1: firstPart.name,
				task2: secondPart.name,
				users: users.map(x => ({
					id: x.id,
					first_hours: x.firstPart,
					second_hours: x.secondPart,
				}))
			});

			enqueueSnackbar(this.tr("Task was split succesfully."), { variant: "success" });

		} catch (error) {
			enqueueSnackbar(this.tr("Error splitting task."), { variant: "error" });
			console.error(error);
		}

		onSave && onSave();

		window.dispatchEvent(new Event('taskSaved'));
		this.setState({ isSaving: false });

	}, 500);

	render() {
		const { task, open } = this.props;
		const { ready, firstPart, secondPart, users, isSaving, createSeparateTasks } = this.state;
		const { userObject } = this.context;
		const { tr } = this;

		const totals = {
			originalResourced: 0,
			remaining: 0,
			trackedFirst: 0,
			trackedSecond: 0,
			firstPart: 0,
			secondPart: 0,
			difference: 0,
		};

		for (const user of users) {
			totals.originalResourced += (user.hours || 0);
			totals.remaining += Math.max(0, (user.hours || 0) - (user.tracked || 0));
			totals.trackedFirst += (user.firstPartTracked || 0);
			totals.trackedSecond += (user.secondPartTracked || 0);
			totals.firstPart += (user.firstPart || 0);
			totals.secondPart += (user.secondPart || 0);
		}

		return (<Dialog open={open} className={styles.dialog} PaperProps={{ className: styles.paper }}>
			<DialogTitle className={styles.dialogTitle}>{tr("Split Task")}</DialogTitle>
			{task && <DialogContent className={styles.content}>

				<div className={styles.fields}>
					<div className={styles.row}>
						<div>
							<DateRangePicker
								label={tr('Original Date Range')}
								ranges={[{
									startDate: task.start_date,
									endDate: task.end_date,
								}]}
								dateFormat={userObject.dateFormat}
								disabled
							/>
						</div>
						<div>
							<OutlinedField
								className={styles.full}
								label={tr('Allocated h')}
								value={Number(task.hours).toFixed(2)}
								disabled />
						</div>
					</div>
					<div className={styles.row}>
						<div>
							<DatePicker
								className="date full"
								label={tr('Split Date')}
								date={this.state.date}
								dateFormat={userObject.dateFormat}
								onChange={this.onDateChange}
								minDate={addDays(task.start_date, 1)}
								maxDate={task.end_date}
							/>
						</div>
						<div>
							<FormControlLabel
								control={
									<Checkbox name="createSeparateTasks" checked={createSeparateTasks} onChange={this.onChangeCheckbox} color="primary" />
								}
								label={tr("Create split task to own row")}
								labelPlacement="end"
							/>

							<Tooltip classes={{ tooltip: cn('darkblue-tooltip', styles.tooltip) }} title={<>
								<div>{tr("Check the box to create task as independent new task to own row.")}</div>
								<div>{tr("Renaming Task 2 will automatically create independent task when saving.")}</div>
							</>}>
								<InfoIcon />
							</Tooltip>
						</div>
					</div>
					{createSeparateTasks && <div className={styles.row}>
						<div>
							<OutlinedField
								className={styles.full}
								label={tr('Task 1 (${day})', {
									day: this.header(firstPart),
								})}
								name="task1"
								value={firstPart.name}
								onChange={this.onChangeTaskName}
							/>
						</div>
						<div>
							<OutlinedField
								className={styles.full}
								label={tr('Task 2 (${day})', {
									day: this.header(secondPart),
								})}
								name="task2"
								value={secondPart.name}
								onChange={this.onChangeTaskName}
							/>
						</div>
					</div>}
				</div>

				<div className={styles.userGrid}>
					{createSeparateTasks && <div className={cn(styles.row, styles.taskNames)}>
						<div>{this.tr("Task 1")}</div>
						<div>{this.tr("Task 2")}</div>
					</div>}
					{!createSeparateTasks && <div className={cn(styles.row, styles.taskNames)}>
						<div>{this.header(firstPart)}</div>
						<div>{this.header(secondPart)}</div>
					</div>}
					<div className={cn(styles.row, styles.header)}>
						<div>{this.tr("User")}</div>
						<div>{this.tr("Original Resourced")}</div>
						<div>{this.tr("Remaining Hours")}</div>
						<div className={styles.twocol}>{this.tr("Tracked / Resourced")}</div>
						<div className={styles.twocol}>{this.tr("Tracked / Resourced")}</div>
						<div>{this.tr("Remaining Difference")}</div>
						<div>{this.tr("Tracked / Resourced after saving")}</div>
					</div>

					{users.map(x => <SplitTaskDialogUserRow key={x.id} user={x} className={styles.row} onChange={this.changeUser} />)}

					<div className={cn(styles.row, styles.footer)}>
						<div>{this.tr("Total")}</div>
						<div className={styles.center}>
							{totals.originalResourced.toFixed(2)} {this.tr("h")}
						</div>
						<div className={styles.center}>
							{totals.remaining.toFixed(2)} {this.tr("h")}
						</div>
						<div className={cn(styles.right, styles.staticValue)}>
							{totals.trackedFirst.toFixed(2)} /
						</div>
						<div className={cn(styles.resourced)}>
							{totals.firstPart.toFixed(2)}
						</div>
						<div className={cn(styles.tracked, styles.right, styles.staticValue)}>
							{totals.trackedSecond.toFixed(2)} /
						</div>
						<div className={cn(styles.resourced)}>
							{totals.secondPart.toFixed(2)}
						</div>
						<div className={styles.center}>
							{((totals.firstPart + totals.secondPart) - totals.originalResourced).toFixed(2)} {this.tr("h")}
						</div>
						<div className={styles.right}>
							{totals.trackedFirst.toFixed(2)} / {(totals.firstPart + totals.secondPart).toFixed(2)} {this.tr("h")}
						</div>
					</div>
				</div>
			</DialogContent>}
			<DialogActions className={styles.actions}>
				<Button className={styles.cancel} onClick={this.cancel} variant="text">
					{this.tr("Cancel")}
				</Button>
				<Button className={styles.ok} disabled={!ready || isSaving} onClick={this.save} color="primary">
					{this.tr('Split')}
				</Button>
			</DialogActions>
		</Dialog>
		);
	}
});