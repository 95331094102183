import React from "react";
import { Bar, Chart } from "react-chartjs-2";
import TaimerComponent from "../../TaimerComponent";
import _ from "lodash";


export const abbreviateNumber = (value) => {
  let formatted = Math.round(value);
  const breakpoints = {
    1000: {
      formatValue: (value) => {
        return `${Math.round(value / 1000)}K`;
      },
    },
    1000000: {
      formatValue: (value) => {
        return `${Math.round((value / 1000000) * 10) / 10}M`;
      },
    },
  };

  Object.keys(breakpoints).forEach((point) => {
    if (value >= point) {
      formatted = breakpoints[point].formatValue(value);
    }
  });
  return formatted;
};

export const getBarTotalFromContext = (context) => {
  const total = (context.chart.data.datasets || []).reduce(
    (prev, ds) => (prev += ds.data[context.dataIndex] || 0),
    0
  );
  return total;
};

class StackedCurrencyChart extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "dashboard/insights/StackedCurrencyChart");

    this.refChart = React.createRef();
  }

  render() {
    const {
      labels,
      datasets,
      currency,
      displayLegend,
      hiddenIndexes,
      toggleHidden,
      options,
      stacked = true,
      xStacked = true,
    } = this.props;
    const {
      functions: { presentCurrency },
    } = this.context;

    const data = {
      labels,
      datasets,
    };

    return (
      <Bar
        ref={this.refChart}
        data={data}
        width={"100%"}
        options={{
          ...options,
          layout: {
            padding: {
              top: xStacked && stacked ? 25 : 0,
            },
          },
          barValueSpacing: 20,
          plugins: {
            tooltip: {
              ...options.plugins.tooltip,
              callbacks: {
                labelColor: (tooltipItem) => {
                  return {
                    backgroundColor:
                      datasets[tooltipItem.datasetIndex].borderColor,
                  };
                },
                label: (tooltipItem) => {
                  const dataset = tooltipItem.dataset;
                  var label =
                    " " + _.escape(dataset.label) || "";
  
                  if (label) {
                    label += ": ";
                  }
                  label += presentCurrency(tooltipItem.raw, currency);
                  return label;
                },
              },
            },
            legend: {
              ...options.plugins.legend,
              display: displayLegend,
              onClick: (e, legendItem) => {
                const { datasetIndex } = legendItem;
                toggleHidden(datasetIndex);
              },
              labels: {
                ...options.plugins.legend.labels,
                generateLabels: () => {
                  return datasets.map((obj, i) => {
                    return {
                      text: obj.label,
                      datasetIndex: i,
                      pointStyle: "rectRounded",
                      fillStyle: obj.borderColor,
                      lineCap: "round",
                      lineJoin: "round",
                      strokeStyle: "#FFF",
                      lineWidth: 5,
                      hidden: hiddenIndexes.indexOf(i) != -1,
                    };
                  });
                },
              },
            },
            datalabels: {
              color: "#34546b",
              backgroundColor: "#f9f9f9",
              borderRadius: 5,
              font: {
                size: 10,
              },
              clamp: true,
              anchor: "end",
              align: "end",
              offset: 6,
              display: (context) => {
                const total = getBarTotalFromContext(context);
                const shouldShow = total !== 0 &&
                stacked &&
                xStacked &&
                context.datasetIndex ===
                  (context.chart.data.datasets || []).length - 1
                ? "auto"
                : false;
                return shouldShow;
              },
              formatter: (_, context) => {
                const total = getBarTotalFromContext(context);
                const formatted = abbreviateNumber(total);
                return formatted;
              },
            },
          },
          onClick: (evt) => {
            const activePoint = this.refChart.current.chartInstance.getElementAtEvent(
              evt
            )[0];

            if (!activePoint) return;

            const data = activePoint._chart.data;
            const datasetIndex = activePoint._datasetIndex;

            data.datasets[datasetIndex] &&
              this.props.onClick(
                data.datasets[datasetIndex],
                activePoint._index
              );
          },
          scales: {
            ...options.scales,
            y: {
                ...options.scales.y,
                stacked,
                type: "linear",
                position: "left",
                beginAtZero: true,
                ticks: {
                  ...options.scales.y?.ticks,
                  callback: (value, index, values) => {
                    return presentCurrency(value, currency);
                  },
                },
              },
            x: {
                ...options.scales.x,
                stacked: xStacked,
              },
          },
        }}
      />
    );
  }
}

export default StackedCurrencyChart;
