import TaimerComponent from '../TaimerComponent';
import { CurrencyExchange, ArrowRightAlt } from '@mui/icons-material';

import { getCurrencyConvertData } from './CurrencyUtils';
import { CurrencyConvertData, UsedConvert, ConvertNotFound, ReportData } from "./CurrencyUtils";
import { Tooltip } from "./IconCell";

import IconCell from './IconCell';
import styles from './CellRenderer.module.scss';

import {
    ColDef,
  } from 'ag-grid-community';

interface Props {
    data: ReportData;
    groupedDataValue?: CurrencyConvertData;
    valueFormatted?: string;
    colDef: ColDef;
    showEmptyAggSum?: boolean;
}

const getTooltip = (convertData) => {
    const usedConverts: UsedConvert[] = convertData?.usedConverts || [];
    const convertsNotFound: ConvertNotFound[] = convertData?.convertsNotFound || [];
    const amountConverted: number = convertData?.amountConverted || 0;

    const showTooltip = convertsNotFound.length > 0 || usedConverts.length > 0;
    if (!showTooltip) {
        return undefined;
    }

    const tooltip: Tooltip = {
        placement: "left",
        content:
            <div className={styles.cellTooltip}>
                {amountConverted > 1 && <p>{amountConverted} values converted:</p>}
                {amountConverted == 1 && convertsNotFound.length == 0 && <p>Converted:</p>}

                {usedConverts.map(n => {
                    return (<p>1 {n.companyCurrency}: {n.rate} {n.defaultCurrency}</p>);
                })}
                {convertsNotFound.map(n => {
                    return (<p>Rate not found: {n.companyCurrency} <ArrowRightAlt className={styles.arrowWhite} /> {n.defaultCurrency}</p>);
                })}
            </div>
    }
    return tooltip;
}

export default class CurrencyCellRenderer extends TaimerComponent<Props> {
    render() {
        const { data, groupedDataValue, valueFormatted, showEmptyAggSum } = this.props;

        const value: string = valueFormatted && !showEmptyAggSum 
            ? valueFormatted 
            : "";

        const convertData: CurrencyConvertData = groupedDataValue
            ? groupedDataValue
            : getCurrencyConvertData([data], this.props.colDef.field || "");

        const tooltip = getTooltip(convertData);

        return !tooltip 
            ? value
            : <IconCell
                value={value}
                icon={CurrencyExchange}
                tooltip={getTooltip(convertData)}
                justifyContent="right"
            />
    }
}