import getColumnType from './ColumnTypes';
import DataHandler from "./../general/DataHandler";
import {
    ColDef
} from 'ag-grid-community';

function extractDimensionPrefix(str: string): string | undefined {
    const parts: string[] = str.split("_dimension_item_level");

    return parts.length > 1
        ? parts[0]
        : undefined;
}

function extractDimensionLevelNames(obj): string[] {
    const keys: string[] = Object.keys(obj);
    const regex          = /dimension_item_level_([0-9]*)_name/;

    return keys
        .filter((k: string) => k.match(regex) !== null);
}

interface DimensionColumnParameters {
    data: any[];
    columns: ColDef[];
    settingsContext: any;
    userPrefix: string | string[];
    dimensionColumnTypes?: string[] | undefined;
}

// TODO: Refactor.
async function handleDimensionColumns(parameters: DimensionColumnParameters): Promise<ColDef[]> {
    const {
        data,
        columns,
        settingsContext,
        userPrefix,
    }: {
        data: any[];
        columns: ColDef[];
        settingsContext: any;
        userPrefix: string | string[];
    } = parameters;

    const allDimensions                = await DataHandler.get({ url: `settings/dimensions` });
    const dimensionColumnTypes         = parameters.dimensionColumnTypes ?? ["project", "user"];
    const customDimensions: string[][] = [[], []];

    Object.keys(allDimensions).forEach((companyId: string) => {
        if(allDimensions[companyId].length < 3) {
            return;
        }

        // TODO: refactor if the limit on the amount of levels is ever removed.
        
        if(allDimensions[companyId].length === 3) {
            customDimensions[0].push(allDimensions[companyId][0]?.name ?? "");
        }

        if(allDimensions[companyId].length === 4) {
            customDimensions[0].push(allDimensions[companyId][1]?.name ?? "");
            customDimensions[1].push(allDimensions[companyId][0]?.name ?? "");
        }
    });

    const customDimensionNames: string[] = [
        customDimensions[0].join(" / "),
        customDimensions[1].join(" / ")
    ];

    // Find at least one row that has 
    // the dimension level fields set.
    const rowsWithDimensionLevels = data
        .filter(r => extractDimensionLevelNames(r).length > 0);

    if(rowsWithDimensionLevels.length === 0) {
        return columns;
    }

    let rowWithDimensionLevels;
    let max = 0;

    rowsWithDimensionLevels.forEach(r => {
        const n: number = extractDimensionLevelNames(r).length;

        if(n <= max) {
            return;
        }

        max                    = n;
        rowWithDimensionLevels = r;
    });

    if(!rowWithDimensionLevels || settingsContext.addons.dimensions?.used_by_companies?.length === 0) {
        return columns;
    }

    const levelNames: string[] = extractDimensionLevelNames(rowWithDimensionLevels);

    if(levelNames.length === 0) {
        return columns;
    }

    const levelColumns: ColDef[] = levelNames.map((name: string, index: number): ColDef => {
        return {
            field: name,
            headerName: customDimensionNames[index % 2],
            ...getColumnType("text"),
            defaultAggFunc: 'sumAndGroup',
            hide: true,
        };
    });

    // Split the columns by type, because
    // they have to be after their respective columns.
    const levelColumnsByType: {
        [type: string]: ColDef[]
    } = {};

    levelColumns.forEach((cd: ColDef) => {
        const type: string | undefined = extractDimensionPrefix(cd?.field ?? "");

        if(type === undefined) {
            return;
        }

        if(!levelColumnsByType.hasOwnProperty(type)) {
            levelColumnsByType[type] = [];
        }

        cd.headerName = `${cd.headerName} (${type})`;

        levelColumnsByType[type].push(cd);
    });

    const projectColumns: ColDef[] = [
        {
            field: 'project_dimension_team_name',
            headerName: 'project_dimension_team_name',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
            hide: true,
        },
        {
            field: 'project_dimension_unit_name',
            headerName: 'project_dimension_unit_name',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
            hide: true,
        },
        ...(levelColumnsByType?.project ?? []),
        {
            field: 'project_dimension_team_leader',
            headerName: 'project_dimension_team_leader',
            ...getColumnType('links'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)"
            },
            defaultAggFunc: 'sumAndGroup',
            hide: true,
        },
    ];

    const userPrefixes = Array.isArray(userPrefix)
        ? userPrefix
        : [userPrefix];

    const userColumns: ColDef[] = userPrefixes.map((prefix: string) => {
        return [
            {
                field: `${prefix}_dimension_team_name`,
                headerName: `${prefix}_dimension_team_name`,
                ...getColumnType('text'),
                defaultAggFunc: 'sumAndGroup',
                hide: true,
            },
            {
                field: `${prefix}_dimension_unit_name`,
                headerName: `${prefix}_dimension_unit_name`,
                ...getColumnType('text'),
                defaultAggFunc: 'sumAndGroup',
                hide: true,
            },
            ...(levelColumnsByType?.[prefix] ?? []),
            {
                field: `${prefix}_dimension_team_leader`,
                headerName: `${prefix}_dimension_team_leader`,
                ...getColumnType('link'),
                cellRendererParams: {
                    url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)"
                },
                defaultAggFunc: 'sumAndGroup',
                hide: true,
            },
        ];
    }).flat();

    return [
        ...columns,
        ...[
            ...(dimensionColumnTypes.indexOf("project") > -1
                ? projectColumns
                : []),
            ...(dimensionColumnTypes.indexOf("user") > -1
                ? userColumns
                : []),
        ]   
    ];
}

export default handleDimensionColumns;
