import React from 'react';
import TaimerComponent from '../TaimerComponent';
import { SettingsContext } from './../SettingsContext';
import MepcoExporter from "./MepcoExporter";
import MepcoV2Exporter from "./MepcoV2Exporter";
import ProcountorExporter from "./ProcountorExporter";
import ESalaryExporter from "./ESalaryExporter";

class HoursIntegrationExporter extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "reports/HoursIntegrationExporter");

        this.integrationElements = {
            "mepco": MepcoExporter,
            "mepco_v2": MepcoV2Exporter,
            "procountor": ProcountorExporter,
            "esalary": ESalaryExporter,
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    render() {
        if (!this.props.integration == null || !this.integrationElements[this.props.integration])
            return null;

        const Element = this.integrationElements[this.props.integration];

        return (
            <Element
                classes={this.props.classes}
                company={this.props.company}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
            />
        )
    }
}

HoursIntegrationExporter.defaultProps = {
    company: 1,
    integration: null,
    startDate: null,
    endDate: null
}

export default HoursIntegrationExporter;