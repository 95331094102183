import React from 'react';
import styles from './PreviewList.module.scss';
import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from './../SettingsContext';
import { Button, IconButton } from '@mui/material';
import { Close } from "@mui/icons-material";

interface Page {
    name?: string;
    url: Record<string, string>;
}

interface Props {
    /**
     * Previous Page (for link)
     */
    previousPage?: Page | null;
    /**
     * PreNevious Page (for link)
     */
    nextPage?: Page | null;

    /**
     * Current Page
     */
    currentPage: Page | null;
    /**
     * Number of current page
     */
    currentPageNumber: number;
    /**
     * Number of pages in total
     */
    pages: number;

    onClickPrevious?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    onClickNext?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    onClickClose?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}
interface State {

}

class PreviewList extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "navigation/PreviewList");

        this.state = {
        }
    }

    clickBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const { onClickPrevious, previousPage } = this.props;
        const { functions: { updateView } } = this.context;

        if (previousPage) {
            e.preventDefault();
            updateView(previousPage.url);
        } else {
            onClickPrevious?.(e);
        }
    }

    clickNext = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const { onClickNext, nextPage } = this.props;
        const { functions: { updateView } } = this.context;

        if (nextPage) {
            e.preventDefault();
            updateView(nextPage.url);
        } else {
            onClickNext?.(e);
        }
    }

    clickClose = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const { onClickClose, currentPage } = this.props;
        const { functions: { closePreviewList } } = this.context;

        if (currentPage) {
            e.preventDefault();
            closePreviewList();
        } else {
            onClickClose?.(e);
        }
    }

    render() {
        const { tr } = this;
        const { functions: { urlify } } = this.context;
        const { currentPage, currentPageNumber, pages, previousPage, nextPage } = this.props;

        return (<div className={styles.root}>
            <div className={styles.page_nr}>
                ({currentPageNumber} / {pages})
            </div>
            <div>
                <Button
                    classes={{
                        outlined: styles.moveButton,
                        disabled: styles.disabled,
                    }}
                    variant='outlined'
                    disabled={!previousPage}
                    href={previousPage ? urlify(previousPage.url) : '#'}
                    onClick={this.clickBack}>
                    {this.tr('Back')}
                </Button>
                <Button
                    classes={{
                        outlined: styles.moveButton,
                        disabled: styles.disabled,
                    }}
                    variant='outlined'
                    disabled={!nextPage}
                    href={nextPage ? urlify(nextPage.url) : '#'}
                    onClick={this.clickNext}>
                    {this.tr('Next')}
                </Button>
                {currentPage && <Button
                    classes={{
                        outlined: styles.moveButton,
                        disabled: styles.disabled,
                    }}
                    variant='outlined'
                    href={currentPage ? urlify(currentPage?.url) : '#'}
                    onClick={this.clickClose}>
                    <Close className={styles.close} />
                </Button>}
            </div>
        </div>);
    }
}

export default PreviewList;