import React from 'react';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import DataHandler from '../../general/DataHandler';
import DialogBorder from '../DialogBorder';
import DialogSection from '../DialogSection';
import TaimerComponent from  '../../TaimerComponent';

/* dialog parts */
import LoadingScreen from '../../general/LoadingScreen';
import ErrorScreen from './ErrorScreen';
import FileUpload from './FileUpload';
import ImportTool from '../../general/ImportTool';

/* material ui */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

/* other */
import { withSnackbar, WithSnackbarProps } from 'notistack';
import _ from 'lodash';

/* css */
import './ImportDialog.css';
import './ImportQuoteRowsDialog.css';

const initialState = {
    file: [],
    fileData: false,
    importStage: "upload",
    results: {
        total: 0, // Processed rows amount.
        rows: 0, // Importable/imported rows amount.
        rows_data: [], // Rows data from review
        errors: [], 
        row_errors: [], // From import when some rows failed to save
        error: false
    },
    importObjects: [],
    importData: [],
    noMemory: false,
    onlyIntStr: false,
    noData: false,
    importResults: {},
    importStep: 0,
    errors: []
}

interface RowError {
    row: string | number; // row number in excel.
    message: string;
    error: string;
 }

interface Result {
    total: number; // Processed rows amount.
    rows: number; // Importable/imported rows amount.
    rows_data: any[]; // Rows data from review. Sent to import.
    errors: RowError[]; // From import/review when no import is done.
    row_errors: RowError[]; // From import when some rows failed to save
    error: boolean; // Error other than from rows (e.g. no permission), no row errors shown.
 }

 interface PSAField {
    id: string | number;
    name: string;
 }

interface State {
    results: Result; 
    file?: any;
    fileData: any;
    importStage: string;
    importObjects: any[];
    importData: any[];
    noMemory: boolean;
    onlyIntStr: boolean;
    noData: boolean;
    importResults: object,
    importStep: number,
    errors: any[]
}

export interface ImportDialogProps extends WithSnackbarProps {
    onClose: (event?: any, reason?: string) => void;
    onSuccess?: () => void;
    psaFields: PSAField[];
    company: string;
    importUrl: string;
    getRowError: (error: RowError) => string;
    requiredFields: string[];
    requiredFieldNames: string;
    reviewUrl: string;
    renderReviewComponent: (results: Result) => any;
    title: string;
    importDataExtra?: object;
    ImportTemplate?: File;
}


class ImportDialog extends TaimerComponent<ImportDialogProps, State> {
    dialog: any;
    upload: any;

    static contextType = SettingsContext;
    constructor(props, context) {
        super(props, context, "dialogs/imports/ImportQuoteRowsDialog")
       
        this.state = _.cloneDeep(initialState);
        this.dialog = React.createRef();
        this.upload = React.createRef();

        ["startLoadingAnimation", 
        "stageFile", 
        "uploadFile"].forEach(e => this[e] = this[e].bind(this));
    }
    
    componentDidMount() {
        super.componentDidMount();
    }

    close = () => {
        this.props.onClose();
    }

    stageFile(file) {
        this.setState({ file: file });
    }

    uploadFile() {
        const { enqueueSnackbar } = this.props;
        const { file } = this.state;
        if (file.length == 0) {
            enqueueSnackbar(this.tr(`Upload a file to continue.`), {
                variant: "error",
            });
            return;
        }
        if (file.length > 0 && !file[0].name.endsWith('xlsx')) {
            enqueueSnackbar(this.tr(`File format is not XLSX.`), {
                variant: "error",
            });
            return;
        }
        if (file.length > 0 && file[0].size > (1048576 * 5)) {
            enqueueSnackbar(this.tr(`File is too large`, "."), {
                variant: "error",
            });
            return;
        }

        this.upload.current.uploadFiles(file);
    }

    importRows = async () => {
        const { results } = this.state;
        const { importUrl, importDataExtra } = this.props;

        this.startLoadingAnimation(null);

        const importData = {
            data: results.rows_data || [],
            ...importDataExtra
        }

        try {
            const importResults = await DataHandler.post({url: importUrl}, {...importData});
            this.handleNext({ results: importResults, importStage: "results" });
            this.props.onSuccess && this.props.onSuccess();
        }
        catch (err) {
            this.handleNext({ importStage: "error" });
        }
    }

    startLoadingAnimation(e) {
        this.setState({importStage: "loading"});
    }

    renderErrors = () => {
        const { results } = this.state;
        const { getRowError } = this.props;

        const errorHeader = results.errors?.length > 0 || results.error
            ? this.tr("Importing failed. No rows were imported. Import has following error(s):") 
            : this.tr("Importing had following error(s):");

        if (results.error) {
            return (
                <div className="content-block">
                    <p>{errorHeader}</p>
                    <p><span className='error'><b>{this.tr("Error")}: </b></span>{this.tr(results.error)}</p>
                </div>
            )
        }

        const errorResults = results.errors?.length > 0 
            ? results.errors 
            : results.row_errors;

        return errorResults?.length > 0 ? (
            <div className="content-block">
                <p>{errorHeader}</p>
                <Table className="error-details">
                    <TableHead className="error-headers">
                        <TableRow>
                            <TableCell className="left">{this.tr("Row")}</TableCell>
                            <TableCell className="right">{this.tr("Error")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="error-container">
                        {errorResults.map(e => {
                            return (
                                <TableRow className="error-message">
                                    <TableCell className="left">{e.row}</TableCell>
                                    <TableCell className="right">{getRowError(e)}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        ) : <></>
    }

    renderResults = () => {
        const { results } = this.state;

        const headers = {
            total: this.tr("Rows Processed"),
            rows: this.tr("Rows Imported"),
            row_errors: this.tr("Errors"),
            errors: this.tr("Errors")
        }

        return (
            <div className="quoterow-import-dialog-result-container">
                {<div className="result-headers" id="results">
                    {!results.error && _.map(results, (result, i) => {
                            if (!headers.hasOwnProperty(i)) {
                                return;
                            }
                            if (i === "errors" || i === "row_errors") {
                                return (
                                    <div className="result">
                                        <h1 className={i}>{Array.isArray(result) ? result.length : 0}</h1>
                                        <h3 className={"sub-header"}>{headers[i]}</h3>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="result">
                                        <h1 className={i}>{result}</h1>
                                        <h3 className={"sub-header"}>{headers[i]}</h3>
                                    </div>
                                )
                            }
                    })}
                </div>}
                {this.renderErrors()}
            </div>
        )
    }

    setFileData = (data) => {
        if (!data) {
            this.setState({ importStage: "error" });
        }
        else if (data.errors?.out_of_memory) {
            this.setState({ importStage: "error", noData: true });
        }
        else if (data.errors?.only_int_with_str) {
            this.setState({ importStage: "error", onlyIntStr: true });
        }
        else if (data.errors?.error || !data.data) {
            this.setState({ importStage: "error" });
        }
        else {
            const objects: object[] = [];
            _.forEach(data.data, d => {
                const obj = JSON.parse(d)
                objects.push(obj)
            })
            data.data = objects;

            this.setState({ fileData: data, importStage: "config", file: [] });
            this.handleNext();
        }
    }

    handleNext = (state = {}) => {
        let { importStep } = this.state;
        importStep++;
        this.setState({ importStep: importStep, ...state });
    }

    handleBack = () => {
        let component = "";
        let { importStep } = this.state;
        const stage = this.state.importStage;
        let extra = {};

        if (stage === "config" || stage === "error" || stage === "errors") {
            component = "upload";
            extra = _.cloneDeep(initialState);
        } else if (stage === "review") {
            component = "config";
        }
        if (importStep > 0)
            importStep--;
        this.setState({ importStep: importStep, importStage: component, ...extra });
    }

    setImportObjects = (importObjects) => {
        this.setState({ importObjects: importObjects });
    }

    reviewImport = async () => {
        const { fileData, importObjects } = this.state;
        const { enqueueSnackbar, requiredFields, requiredFieldNames, reviewUrl, importDataExtra } = this.props
        let duplicates = 0;
        const fieldsToSave: object[] = [];
        const fieldsMapped = {};

        _.forEach(importObjects, object => {
            if (object.importable) {
                fieldsMapped[object.taimerField.id] = object;

                if (fieldsToSave.find(x => x['taimerField'].id == object.taimerField.id)) {
                    duplicates++;
                }
                fieldsToSave.push(object);
            }
        });

        if (requiredFields.find(x => !fieldsMapped[x])) {
            enqueueSnackbar(this.tr("Following fields are required in import: ${required_fields}", {required_fields: requiredFieldNames}), {
                variant: "error",
            });
            return;
        }

        if (duplicates > 0) {
            enqueueSnackbar(this.tr(`You have `) + duplicates + this.tr(` duplicate fields`), {
                variant: "error",
            });
            return;
        }

        this.setState({ importStage: "loading" }, async () => {
            const allRows: object[] = [];

            fileData.data?.forEach((data, i) => {
                const row: object = {};
                row['row_number'] = data.row_number;

                _.forEach(fieldsToSave, (field, n) => {
                    if (!data[field['id']])
                        return;

                    const value = data[field['id']].name;
                    const valueStr = String(value).trim();
                    row[field['taimerField'].id] = valueStr;
                })
                allRows.push(row);
            })

            const reviewData = {
                data: allRows,
                ...importDataExtra
            }

            try {
                const results = await DataHandler.post({url: reviewUrl}, {...reviewData});

                const hasErrors = results.errors?.length > 0 || results.error;
    
                setTimeout(() => {
                    this.handleNext({ results, importStage: hasErrors ? "errors" : "review" });
                }, 1000);
            }
            catch (err: any) {
                const error = err?.responseJSON?.error || "";
                this.handleNext({ importStage: error == "NO_PERMISSION" ? "errors" : "error", results: {error} });
            }
        })

    }

    render() {
        const { company, psaFields, renderReviewComponent, title, ImportTemplate } = this.props;
        const { importStage, importStep, fileData, importObjects, results } = this.state;
        const { tr } = this;

        const importSteps = [tr("Upload File"), tr("Map Attributes"), tr("Review Import"), tr("Results")];

        const hasErrors = results.errors?.length > 0 || results.error;

        const Components = {
            upload: 
                <FileUpload
                    ref={this.upload}
                    startLoadingAnimation={this.startLoadingAnimation} 
                    setFileData={this.setFileData}
                    stageFile={this.stageFile}
                    company={company}
                    suggestedFilesize={tr("Max file size: 5.0MiB")}
                    uploadHeader={tr("Import rows by dragging a file here or by")}
                    fileTemplate={ImportTemplate}
                    returnRowNumbers={true}
                />,
            loading:
                <LoadingScreen loadingMessage={tr("Processing data...")} />, 
            results: 
                this.renderResults(),
            error:
                <ErrorScreen errorParams={{ error: true }} />,
            config:
                <ImportTool
                    importFields={Object.keys(importObjects).length > 0 ? importObjects : fileData.field_names}
                    exampleData={fileData.example_data}
                    taimerFields={psaFields}
                    setData={this.setImportObjects}
                    dialogRef={this.dialog}
                />,
            review: renderReviewComponent(results),
            errors: this.renderResults(),
        }

        const Component = Components[importStage];
        
        return (
            <DialogBorder
                title={title}
                onClose={this.close}
                className="Account"
                id="ImportDialog"
                saveText={importStage === "results" ? tr("Close") : importStage === "review" ? tr("Import") : tr("Continue")}
                onSave={
                    importStage === "upload" ? this.uploadFile :
                        importStage === "review" && !hasErrors ? this.importRows :
                            importStage === "config" ? this.reviewImport :
                                importStage === "results" ? this.close :
                                    undefined
                }
                hideCancel={importStage == "results"}
                onBack={(importStage !== "results" && importStage !== "upload" && importStage !== "loading" && importStage !== "import") ? this.handleBack : undefined}
                dialogRef={this.dialog}>
                <DialogSection>
                    <Stepper
                        activeStep={importStep}
                        alternativeLabel
                        className="stepper">
                        {importSteps.map(label => (
                            <Step key={label} className="step">
                                <StepLabel className="step-label">{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {Component}
                </DialogSection>
            </DialogBorder>
        );
    }
}

export default withSnackbar(ImportDialog);
