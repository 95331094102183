import React from "react";
import ListCell from "./../ListCell";
import LinkListCell from "./../LinkListCell";
import StatusTag from "../../general/StatusTag";

import "./StatusCell.css";

class StatusCell extends React.Component {
  static defaultProps = {
    style: {}
  };

  constructor(props) {
    super(props);
  }

  render() {
    return this.props.clickable ? (
      <LinkListCell
        width={this.props.width}
        className={
          this.props.listCellProps ? this.props.listCellProps.className : ""
        }
        cellClassName="status-cell-value-container"
        value={
          <StatusTag
            style={{ 
              left: 16,
              ...this.props.style
            }}
            absolute
            clickable={this.props.clickable}
            color={this.props.displayData.color}
            onClick={this.props.onClick}
            text={this.props.displayData.name}
            tooltip={this.props.tooltip}
          />
        }
        editable={false}
        urlHandler={this.props.urlHandler}
        noTab={true}
      ></LinkListCell>
    ) : (
      <ListCell
        width={this.props.width}
        className={
          this.props.listCellProps ? this.props.listCellProps.className : ""
        }
        permanentEditMode={true}
        {...this.props.listCellProps}
      >
        <StatusTag
          style={{ 
            left: 16,
            ...this.props.style
          }}
          absolute
          clickable={this.props.clickable}
          color={this.props.displayData.color}
          onClick={this.props.onClick}
          text={this.props.displayData.name}
          tooltip={this.props.tooltip}
        />
      </ListCell>
    );
  }
}

StatusCell.defaultProps = {
  width: 50,
  ownerRow: undefined,
  name: undefined,
  value: "",
  clickable: false,
};

export default StatusCell;
