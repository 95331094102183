import React from "react";
import FilledLineChart from "../../FilledLineChart";
import InsightChartTable from "../../InsightChartTable";
import InsightChart from "../../InsightChart";
import ModeToggle from "../../ModeToggle";
import { useState } from "react";
import colors from "../../../../colors";

const MODE_KEY = "sales-insight-won-lost-mode-key";

const WonVsLostDeals = props => {
  const { tr, data, months, ...otherProps } = props;
  let initialMode = "area";
  try {
    initialMode = localStorage.getItem(MODE_KEY) || "area";
  } catch (e) {}
  const [mode, setMode] = useState(initialMode);
  const modes = [
    { key: "area", title: tr("Area") },
    { key: "line", title: tr("Line") }
  ];
  const stacked = mode == "area";
  const xStacked = months.length > 1;

  return (
    <InsightChart
      {...otherProps}
      filterComponents={
        <ModeToggle
          setMode={mode => {
            setMode(mode);
            try {
              localStorage.setItem(MODE_KEY, mode);
            } catch (e) {}
          }}
          selected={mode}
          modes={modes}
        />
      }
      title={tr("Won Vs. Lost Deals")}
      data={{
        sets: [
          {
            slider: 'won',
            label: tr("Won Deal"),
            values: data.won.map(x => x.value),
            type: months.length == 1 ? "bar" : undefined,
            colors: {
              backgroundColor: (stacked || months.length == 1) ? colors.greenish_cyan : "transparent",
              fill: {
                target: 'origin',
                below: colors.greenish_cyan
              },
              pointBackgroundColor: colors.greenish_cyan,
              borderColor: colors.greenish_cyan
            }
          },
          {
            slider: 'lost',
            label: tr("Lost"),
            values: data.lost.map(x => x.value),
            type: months.length == 1 ? "bar" : undefined,
            colors: {
              backgroundColor: (stacked || months.length == 1) ? "#f97fab" : "transparent",
              fill: {
                target: 'origin',
                below: '#f97fab'
              },
              pointBackgroundColor: "#f97fab",
              borderColor: "#f97fab"
            }
          },
        ],
        labels: months,
      }}
      chartType={FilledLineChart}
      tableType={InsightChartTable}
      lowOpacityChart={false}
      sliderColumns={["project", "account", "user", "value", "total_split"]}
      chartProps={{
        displayLegend: true,
        stacked,
        xStacked
      }}
      tableProps={{
        displayByLabels: true,
        displayFooter: true,
        // style: {
        //   maxHeight: 9999
        // }
      }}
    />
  );
};

export default WonVsLostDeals;
