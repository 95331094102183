import React from "react";

import { SettingsContext } from "../../../SettingsContext";
import TaimerComponent from "../../../TaimerComponent";

import styles from './ChartTable.module.scss';
import { BlockProps } from "../../base/CustomViewBase";
import { Dataset, DatasetWithSum } from "./StackedChart";
import { Table, TableCell, TableFooter, TableHead, TableRow } from "@mui/material";
import cn from 'classnames';
import { sumBy } from "lodash";

interface Props {
    className?: string;
    tableClassName?: string;
    datasets: DatasetWithSum[];
    formatValue?: (val: number) => string;

    setColumnTitle: string;
    valueColumnTitle: string;

    hideTotal?: boolean;
}

interface State {
}

export default class ChartTable extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "customview/blocks/Chart/ChartTable");

        this.state = {
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
    }

    render() {
        const { className, tableClassName, datasets, formatValue, setColumnTitle, valueColumnTitle, hideTotal } = this.props;
        const {  } = this.state;

        const total = sumBy(datasets, x => x.hideFromTotal ? 0 : x.sum);

        return (<div className={cn(styles.root, className)}>
            <Table className={cn(styles.table, tableClassName)}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>{setColumnTitle}</TableCell>
                        <TableCell>{valueColumnTitle}</TableCell>
                    </TableRow>
                </TableHead>
                {datasets.map(x => <TableRow className={x.totalClassName} key={x.key ?? x.label}>
                    <TableCell>
                        <div className={styles.marker} style={{background: x.backgroundColor}} />
                    </TableCell>
                    <TableCell>{x.label}</TableCell>
                    <TableCell>{formatValue ? formatValue(x.totalDisplayAbsolute ? Math.abs(x.sum) : x.sum) : (x.totalDisplayAbsolute ? Math.abs(x.sum) : x.sum)}</TableCell>
                </TableRow>)}
                {!hideTotal && <TableFooter>
                    <TableCell></TableCell>
                    <TableCell>{this.tr('Total')}</TableCell>
                    <TableCell>{formatValue ? formatValue(total) : total}</TableCell>
                </TableFooter>}
            </Table>
        </div>);
    }
}