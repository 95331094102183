import React, { Component } from 'react';
import './TeamChat.css';
import DataHandler from './../general/DataHandler';
import { SettingsContext } from '../SettingsContext';
import GroupList from './GroupList';
import GroupViewData from './GroupViewData';
import TabHeader from './TabHeader';
import _ from 'lodash';

// Team Chat
export default class TabGroups extends Component {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context);

        this.state = {
            groups: [],
        };

        this.refGroupView = React.createRef();
    }

    componentDidMount() {
        this.refreshGroups();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.selectedExtra, this.props.selectedExtra) && this.props.selectedExtra) {
            this.refreshGroups();
        }
    }

    onSendMessage = async (message, attachments) => {
        const { selectedId } = this.props;

        const form = new FormData();
        form.append("message", message);

        if (attachments.length === 1)
            form.append("attachment", attachments[0].file);

        const msg = await DataHandler.file({ url: `teamchat/groups/${selectedId}` }, form);

        this.refreshGroups({refresh: 1});

        return msg;
    }

    refresh = async () => {
        const { selectedId, enqueueSnackbar, tr, setPage } = this.props;
        const { groups: oldGroups } = this.state;

        const groups = await this.refreshGroups();

        const current = groups.find(x => x.id == selectedId);

        if (current && current.unseen && this.refGroupView.current) {
            this.refGroupView.current.refresh();
        } else if (!current && selectedId > 0) {
            const oldGroup = oldGroups.find(x => x.id == selectedId);
        
            if (oldGroup) {
                enqueueSnackbar(tr("The group '${name}' was removed", {
                    name: oldGroup.name,
                }), {
                    variant: "error",
                });
            }

            const item = _.first(groups);

            if (item)
                setPage("groups", item.id);
            else
                setPage("groups", 0);        }
    }

    refreshGroups = async (params = {}) => {
        const { selectedId, selectedExtra, setPage } = this.props;
        const groups = await DataHandler.get({ url: 'teamchat/groups', ...params });
        this.setState({ groups });

        if (selectedId == 0) {
            const item = _.first(groups);

            if (item)
                setPage("groups", item.id)
        } else if (selectedId == -1 && selectedExtra) {
            const item = _.find(groups, (g) => g.users_ids.length == 2 && g.users_ids.indexOf(selectedExtra.user) !== -1);

            if (item) {
                setPage("groups", item.id);
            } else {
                const g = await this.createGroup(selectedExtra.chatName, [
                    selectedExtra.user
                ]);
            }
        }

        return groups;
    }

    onSelect = (item) => {
        const { setPage } = this.props;

        setPage("groups", item.id);
    }

    createGroup = async (name, users = []) => {
        const { setPage } = this.props;

        const data = await DataHandler.post({ url: 'teamchat/groups' }, {
            name, users
        });

        if (data.id) {
            setPage("groups", data.id, { new: 1 });

            this.refreshGroups({refresh: 1});

            return data;
        } else {
            setPage("groups", 0);

            return false;
        }
    }

    onRefreshGroups = () => {
        this.refreshGroups({refresh: 1});
    }

    onLeaveGroup = async () => {
        const { setPage } = this.props;

        const groups = await this.refreshGroups({refresh: 1});

        const item = _.first(groups);

        if (item)
            setPage("groups", item.id);
        else
            setPage("groups", 0);
    }

    render() {
        const { classes, selectedId, selectedExtra, tr, enqueueSnackbar, openPopup, onClose, inPopup } = this.props;
        const { groups } = this.state;

        const current = groups.find(x => x.id == selectedId);
        
        return (<React.Fragment>
            <TabHeader
                ref={this.refHeader}
                classes={classes}
                title={current ? current.name : tr("Groups")}
                type="groups"
                id={current ? current.id : undefined}
                isNew={current && selectedExtra && selectedExtra.new === 1}
                tr={tr}
                showMemberSelect
                enqueueSnackbar={enqueueSnackbar}
                openPopup={openPopup}
                onClose={onClose}
                inPopup={inPopup}
                onLeaveGroup={this.onLeaveGroup}
                allowRename={current && current.can_rename}
                onRefresh={this.onRefreshGroups} />

            <GroupList
                type="groups"
                className="TeamChatLeftSide"
                items={groups}
                selectedId={selectedId}
                onSelect={this.onSelect}
                onCreate={this.createGroup}
                enqueueSnackbar={enqueueSnackbar} />

            {current && <GroupViewData
                ref={this.refGroupView}
                key={selectedId}
                type="groups"
                id={current.id}
                autoScroll
                onSendMessage={this.onSendMessage} />}
        </React.Fragment>);
    }
}