import React from 'react'
import cn from 'classnames';

/* context */
import { SettingsContext } from './../SettingsContext';

/* local components */
import TaimerComponent from "../TaimerComponent";
import ContextMenu, { ContextSubMenu } from '../general/ContextMenu';
import OutlinedField from "./../general/OutlinedField";
import AddStage from "./AddStage";
import AddProject from "./AddProject";
import DateCell from "../list/cells/DateCell";
import ClickAwayWrapper from "./../general/ClickAwayWrapper";

import Utils from "./../general/Utils";

/*material ui */
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

/* data handler */
import DataHandler from './../general/DataHandler';


/* icons */
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { CheckBoxOutlined, AddCircleOutline, MenuRounded, MonetizationOnOutlined } from '@mui/icons-material';
import { ReactComponent as OnHoldIcon } from './images/on_hold.svg';
import { ReactComponent as MovePipelineIcon } from './images/move_pipeline.svg';
import { ReactComponent as CloseAsLostIcon } from './../general/icons/CloseAsLost.svg';
import { ReactComponent as ViewIcon } from './../general/icons/view.svg';
import { ReactComponent as ArchiveIcon } from './../general/icons/Archive.svg';
import { ReactComponent as ActivateIcon } from './../general/icons/Activate.svg';
import { ReactComponent as MarkAsWonIcon } from './../general/icons/MarkAsWon.svg';
import { ReactComponent as AssignAsSubProjectIcon } from '../general/icons/Add_subunit.svg';
import { ReactComponent as CreateSubProjectIcon } from '../general/icons/AddSubsidairy.svg'; //svg:n nimessä typo pitäisi olla subsidiary, mutta älä vaihda.

import List from "./../list/List";
import { makeMapOfPrimitives } from "./../list/ListUtils";
import PropsOnlyListRow from "./../list/PropsOnlyListRow";
import ListCell from "./../list/ListCell";

import { withSnackbar } from 'notistack';
import _ from 'lodash';
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

import './ProjectKanban.css'
import { Menu, Tooltip, Badge } from '@mui/material';
import ActivitySlider from './ActivitySlider';

import './ProjectKanban.css'
import "./ListKanban.css";
import colors from '../colors';
import ProjectStatusReasonDialog from '../dialogs/ProjectStatusReasonDialog';


// TODO: Column/card width is hard-coded for now; make the width customizable.


// TODO: One more level above this, so the dev doesn't have to adhere to the 
// structure of this row, but can still use the virtualized row easily.
class CardRow extends PropsOnlyListRow {
    constructor(props, namespace) {
        super(props, undefined, undefined, namespace);

        this.determineColor         = this.determineColor.bind(this);
        this.initiateDrag           = this.initiateDrag.bind(this);
        this.defineContent          = this.defineContent.bind(this);
        this.defineContextMenuItems = this.defineContextMenuItems.bind(this);
        this.handleMouseDown        = this.handleMouseDown.bind(this);
    }


    initiateDrag(e) {
        if(e.button !== 0 || !this.props.rowProps.cardDraggingEnabled) {
            return;
        }

        this.props.rowCallbacks.startDrag({
            event: e, 
            ref: this.ref, 
            data: this.props.data,
            list: this.props.listRef,
            rowIndex: this.props.rowIndex
        });
    }


    defineContent() {
        return null;
    }


    defineContextMenuItems() {
        return null;
    }


    defineClassName() {
        return `card ${this.props.rowProps.cardDraggingEnabled ? "draggable" : ""}`;
    }


    handleMouseDown(event) {
        event.preventDefault();
        event.stopPropagation();

        return false;
    }

    determineColor() {
        let color;
        const { locked, status } = this.props.data;

        if(locked == 2) {
            color = "hold";
        } else if(locked == 1 || locked == 3) {
            color = "lost";
        } else if(status == 5) {
            color = "internal";
        } else if(status == 1) {
            color = "won";
        }

        return color;
    }


    defineCells() {
        const { data } = this.props;

        return {
            column: (
                <div style={{ height: "100%" }} onMouseDown={this.initiateDrag}>
                    <div className={`menuButtonContainer`} onMouseDown={this.handleMouseDown}>
                        <ContextMenu label={<MoreHoriz />} noExpandIcon disablePortal={false}>
                            {this.defineContextMenuItems()}
                        </ContextMenu>
                    </div>
                    {this.defineContent()}
                </div>
            )
        };
    }
}


class ProjectKanbanCard extends CardRow {
    constructor(props) {
        super(props, "projects/ProjectKanbanCard");

        this.onHold             = this.onHold.bind(this);
        this.lost               = this.lost.bind(this);
        this.active             = this.active.bind(this);
        this.won                = this.won.bind(this);
        this.internal           = this.internal.bind(this);
        this.open               = this.open.bind(this);
        this.move               = this.move.bind(this);
        this.changeClosingDate  = this.changeClosingDate.bind(this);
        this.showActivitySlider = this.showActivitySlider.bind(this);
        this.cancelOnMouseDown  = this.cancelOnMouseDown.bind(this);
    }


    onHold() {
        DataHandler.post({url: `projects/${this.props.data.id}/status`}, {"locked": "2"});

        this.setData({ locked: 2 });
    }


    lost() {
        const saveLost = (data) => {
            DataHandler.post({ url: `projects/${this.props.data.id}/status` }, data);
            this.setData({ locked: 1 });
        }
        this.props.sharedData.archiveProject(this.props.data, () => {
            if (this.props.data.status == "0" && this.props.data.locked == '-1' && this.props.sharedData.projectLostReasonIsInUse()) {
                this.context.functions.showDialogContent(<ProjectStatusReasonDialog type="lost" project={this.props.data} onSave={(reason, status_reason_comment) => {
                    saveLost({ "locked": "1", status_reason_id: reason.id, status_reason_comment });
                }} />)
            } else {
                saveLost({ "locked": "1" });
            }
        });
    }


    active() {
        DataHandler.post({url: `projects/${this.props.data.id}/status`}, {"locked": "-1"});

        this.setData({ locked: -1 });
    }


    won() {
        const saveWon = (newData) => {
            DataHandler.post({url: `projects/${this.props.data.id}/status`}, newData)
            .done(() => {
                this.props.sharedData.onWon && setTimeout(() => {
                    this.props.sharedData.onWon();
                }, 1000);
            }).fail(e => {
                const { enqueueSnackbar } = this.props.sharedData;
                const resp = e.responseJSON;
                if(resp && resp.error === "invalid_state") {
                    enqueueSnackbar(`${this.tr('Required fields not filled:')} ${resp.missing.join(", ")}`, {
                        variant: "error",
                    });
                }
            });
        }
        if (this.props.sharedData.projectWonReasonIsInUse()) {
            this.context.functions.showDialogContent(<ProjectStatusReasonDialog type="won" project={this.props.data} onSave={(reason, status_reason_comment) => {
                const newData = { type: 1, status: 1, status_reason_id: reason.id, status_reason_comment, status_date: new Date() };
                saveWon(newData);
            }} />);

        } else {
            const newData = { type: 1, status: 1, status_date: new Date() };
            saveWon(newData);
        }
    }


    internal() {
        DataHandler.post({url: `projects/${this.props.data.id}/status`}, {"status": "5"}).done(() => {
            this.setData({ status: 5 });
        });
    }


    open(e) {
        this.context.functions.updateView({ module: 'projects', action: 'view', id: this.props.data.id, view: 'kanban'}, e.ctrlKey || e.metaKey || e.button === 1);
    }


    move(pipeline)  {
        const { enqueueSnackbar } = this.props.sharedData;
        let data = { "projects_pipelines_id": pipeline.id };
        if (Number(pipeline.id) < 0) {
            data = { "status" : Math.abs(Number(pipeline.id)) }
        }
        if (pipeline.id == '-1') {
            data = {
                ...data,
                type: 1
            }
        }
        
        const saveMove = (saveData) => {
            DataHandler.post({url: `projects/${this.props.data.id}/status`}, saveData).done(() => {
                this.props.sharedData.onMove && setTimeout(() => {
                    this.props.sharedData.onMove();
                }, 1000);
            }).fail(e => {
                const resp = e.responseJSON;
    
                if (resp && resp.error === "invalid_state") {
                    enqueueSnackbar(`${this.tr('Required fields not filled:')} ${resp.missing.join(", ")}`, {
                        variant: "error",
                    });
                }
            });
        }

        if (pipeline.id == '-1' && this.props.data.locked == '-1' && this.props.sharedData.projectWonReasonIsInUse()) {
            this.context.functions.showDialogContent(<ProjectStatusReasonDialog type="won" project={this.props.data} onSave={(reason, status_reason_comment) => {
                saveMove({ ...data, status_reason_id: reason.id, status_reason_comment });
            }} />)
        } else {
            saveMove(data);
        }
        
    }


    changeClosingDate(date) {
        const data                = { closing_date: date };
        const { enqueueSnackbar } = this.props.sharedData;

        DataHandler.put({ url: `projects/${this.props.data.id}` }, data).done(response => {
            this.setData({ closing_date: date });

            enqueueSnackbar(this.tr("Closing date saved successfully!"), {
                variant: "success",
            });
        }).fail(e => {
            enqueueSnackbar(this.tr("Error in saving closing date!"), {
                variant: "error",
            });
        });
    }


    showActivitySlider() {
        if(!this.props.sharedData.showActivitySlider) {
            return;
        }

        this.props.sharedData.showActivitySlider(this.props.data);
    }


    cancelOnMouseDown(event) {
        event.preventDefault();
        event.stopPropagation();

        return false;
    }

    defineContextMenuItems() {
        // TODO: write, pipelines
        const write         = this.props.sharedData.projectWritePrivilege(this.props.data.companies_id);
        const color         = this.determineColor();
        const { pipelines, isProjectLimitReached } = this.props.sharedData;

        let items = [
            <MenuItem 
                key={0}
                className="menuIcon"
                onClick={e => (e.button !== 1) && this.open(e)}
                onMouseUp={e => (e.button === 1) && this.open(e)}>
                <ViewIcon />
                {this.tr('View')}
            </MenuItem>
        ];

        if (write) {
            items = [...items, 
                <MenuItem 
                    key={1}
                    className="menuIcon" 
                    onClick={() => { this.context.functions.addProject({ customers_id: this.props.data.customers_id, companies_id: this.props.data.companies_id, parentid: this.props.data.id, parentname: this.props.data.name, projects_sales_states_id: this.props.data.projects_sales_states_id, projects_pipelines_id: this.props.data.projects_pipelines_id, status: this.props.data.status, origin_point: "kanban", }, { stayInCurrentView: true }) }}
                >
                    <CreateSubProjectIcon />{this.tr('Add subproject')}
                </MenuItem>,
                <MenuItem key={2} className="menuIcon" onClick={() => this.props.sharedData.openDialog("setAsSubProjectDialog", {company: this.props.data.companies_id, project: this.props.data, onClose: this.props.sharedData.closeDialog, onSave: (evt) => {
                    const fields = ["id", "parentid"];
                    let d = this.props.data; 
                    d[evt.target.name] = evt.target.value;
                    d = _.pick(d, fields);
                    this.props.sharedData.onDialogSave(d);
                }})}>
                    <AssignAsSubProjectIcon />
                    {this.tr('Assign as a subproject')}
                </MenuItem>,
            ];
            if (color) items.push(<MenuItem key={3} className="menuIcon" onClick={e => this.active()}><ActivateIcon />{this.tr('Activate')}</MenuItem>);
            if (color !== 'won' && color !== 'internal') items.push(<MenuItem  key={4} className="menuIcon" onClick={e => this.won()}><MarkAsWonIcon />{this.tr('Mark as won')}</MenuItem>);
            if (color !== 'hold') items.push(<MenuItem key={5} className="menuIcon" onClick={e => this.onHold()}><OnHoldIcon title="" />{this.tr('Put on hold')}</MenuItem>)
            if (color !== 'lost') items.push(<MenuItem key={6} className="menuIcon" onClick={e => this.lost()}>{(color == 'won' || color == 'internal') ? <><ArchiveIcon /> {this.tr('Archive')}</> : <><CloseAsLostIcon /> {this.tr('Close as lost')}</>} </MenuItem>);
            if (pipelines && pipelines.length > 0) items.push(
                    <ContextSubMenu key={7} icon={<MovePipelineIcon className="PipelinemenuIcon"  title="" />} title={this.tr("Move to Pipeline")}>
                        {pipelines.map((p) => <MenuItem onClick={() => this.move(p)}>{p.name}</MenuItem>)}
                    </ContextSubMenu>
                ); 
        }

        return items;
    }

    getQuoteStatusIcon = () => {
		const { data, sharedData } = this.props;
		const { getQuoteStatusTooltip } = sharedData;
		const tooltip = getQuoteStatusTooltip(data);
			
        return (tooltip ? 
            <Tooltip {...tooltip}>
                {this.renderQuoteStatusIcon()}
            </Tooltip> 
            : 
            this.renderQuoteStatusIcon()
        );
	}

    renderQuoteStatusIcon = () => {
        const { data, sharedData } = this.props;
        const { quoteStatusMap = {}, goToQuote } = sharedData;
        const { 
			project_cost_estimate_read = {},
            project_cost_estimate_write = {}
	 	} = data.rights || {};

        const color = data.quote_statuses?.length == 1 && project_cost_estimate_read ? quoteStatusMap[data.quote_statuses[0].status].color : "#B2B9C9";
        const showBadge = data.quote_statuses?.length > 1;
        const showCreate = !data.quote_statuses?.length && project_cost_estimate_write;
        const clickable = project_cost_estimate_read && (showCreate || data.quote_statuses?.length);

        return (
            <Badge badgeContent={showBadge ? data.quote_statuses?.length : ''} className={showBadge ? "icon-badge" : ''}>
                <MonetizationOnOutlined style={{ color: color }} onMouseDown={this.cancelOnMouseDown} onClick={() => clickable && goToQuote(data.id)} className= {`quote-status icon ${clickable ? "" : "not-clickable"}`} />
            </Badge>
        );
    }

    // TODO: project_team, project_crm_read privilege checks
    defineContent() {
        const { data } = this.props;
        const { 
            userObject, 
            taimerAccount  
        }                      = this.context;
        const closingClassName = data.status == 1 || data.status == 5 ? "closing-white-input" : "";
        const activityColor    = data.activitiesOverdue ? "#f97fab" : (data.activitiesDue ? "#d4890d" : "#828691");
        const colorClassName   = this.determineColor();

        return (
            <div className={`project-card ${colorClassName}`}>
                <div className="color-indicator" />
                <div className={`project-name`} onClick={this.open} onMouseDown={e => e.stopPropagation()}>
                    {data.name} <span className="project-id">({data.project_id})</span>
                </div>
                <div>{data.dueOn}</div>
                <div className="account">{data.customer}</div>
                {data.revenue !== false && data.salesmargin !== false && (
                    <div className="values">
                        {data.revenue} / {data.salesmargin}
                    </div>
                )}
                <div className="footer">
                    <div className="icons">
                        {this.props.sharedData.newsFeedPrivilege && data.project_team[userObject.usersId] && 
                        <SmsOutlined
                            onMouseDown={this.cancelOnMouseDown}
                            onClick={() => this.props.sharedData.openTeamChat({module: 'projects', action: 'view', id: data.id})}
                            className="messages" />}
                        {this.props.sharedData.crmReadPrivilege && <Tooltip placement="top" title={this.tr("Activity")}>
                        <div style={{ backgroundColor: activityColor }} onMouseDown={this.cancelOnMouseDown} onClick={this.showActivitySlider} className="activity">
                            <MenuRounded className="icon" />
                        </div>
                        </Tooltip>}
                        {this.getQuoteStatusIcon()}
                    </div>
                    <span className="closing" onMouseDown={e => e.stopPropagation()}>{this.tr('Closing')} 
                        <DateCell
                            width={60}
                            listCellProps={{textAlign: "right"}}
                            className={"closing-date " + closingClassName}
                            editable={true}
                            offsetCalendar={true}
                            closeOnComplete={false}
                            closeCalendarOnComplete={true}
                            popperBottom={true}
                            usePopper={true}
                            preventDefaultOnCalendarClick={true}
                            value={this.state.closing_date ? this.state.closing_date : data.closing_date}
                            // value={this.state.closing_date ? this.state.closing_date : props.closing_date}
                            showEmptyValue={true}
                            onEdited={(name, value) => {
                                this.changeClosingDate(value);
                            }}
                        />
                    </span>
                </div>
            </div>
        );
    }
}


ProjectKanbanCard.defaultProps = {
    contextType: SettingsContext,
};



// So far this KanbanList component is only used here. 
// If you need to use it somewhere else, DO NOT COPY THE CODE, OK??? If I find out someone's copied the code from here,
// there will be a public execution of this person held in the parking lot at Pajatie 8.
// Instead, move the component's code to a separate file and import it here and where ever you need to use it.
class KanbanList extends List {
    constructor(props) {
        super(props);

        this.shiftRowsBelow = this.shiftRowsBelow.bind(this);
        this.resetShifts    = this.resetShifts.bind(this);
    }


    shiftRowsBelow(order) {
        const keys        = Object.keys(this._order).filter(k => this._order[k] >= order);
        const startHeight = this.determineVirtualizationStartHeight();

        keys.filter(k => this.listElementReferences[k].current !== null).forEach(k => {
            const top = (this._order[k] + 1) * this.props.rowHeight + startHeight;

            this.listElementReferences[k].current.moveRow(top);
        });
    }


    resetShifts(order = -1) {
        const index       = Object.keys(this._order).findIndex(k => this._order[k] === order);
        const moveRows    = Object.keys(this._order).filter(k => this._order[k] > index).map(k => this._order[k]);
        const startHeight = this.determineVirtualizationStartHeight();

        // TODO: Find out why there's a reference that's null here sometimes.
        moveRows.filter(i => this.listElementReferences[i].current !== null).forEach(i => {
            const top = (this._order[i]) * this.props.rowHeight + startHeight;

            this.listElementReferences[i].current.moveRow(top);
        });
    }
}


class KanbanColumnHeader extends React.Component {
    render() {
        const { name } = this.props;

        return (
            <>
                <div className="name">{name}</div>
            </>
        );
    }
}


class ProjectKanbanColumnHeader extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "projects/ProjectKanbanColumnHeader");

        this.state = {
            name: "",
            probability_percent: "",
            edit: {
                name: false,
                probability_percent: false
            }
        };

        this.closeEdits   = this.closeEdits.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.edit         = this.edit.bind(this);
    }


    componentDidMount() {
        this.setState({
            name: this.props.column.name,
            probability_percent: this.props.column.probability_percent,
        });
    }


    componentDidUpdate(prevProps) {
        if(!isEqual(this.props, prevProps)) {
            this.setState({
                name: this.props.column.name,
                probability_percent: this.props.column.probability_percent,
            });
        }
    }


    closeEdits() {
        this.props.laneChanged(this.props.column.id, {
            name: this.state.name,
            probability_percent: this.state.probability_percent
        });

        this.setState({ edit: { name: false, probability_percent: false } });
    }


    handleChange(e) {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }


    edit(field) {
        if (this.props.editDisabled) return;
        this.setState({ edit: {
            ...this.state.edit,
            [field]: true
        } });
    }


    render() {
        const { name, probability_percent, revenue, margin, hasProjectWithValues } = this.props.column;
        const cardAmount        = this.props.column.cards.length;
        const p                 = probability_percent / 100.0;
        const { taimerAccount } = this.context;
        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: taimerAccount.currency
        }).format;

        return (
            <>
                <div className={`color-indicator ${this.props.className}`} />
                {!this.state.edit.name && <div className={`name ${this.props.editDisabled ? "disabled" : ""}`} onClick={e => this.edit("name")}>{this.state.name}</div>}
                {this.state.edit.name && (
                    <ClickAwayWrapper onClickAway={this.closeEdits}>
                        <OutlinedField 
                            onKeyDown={(e) => e.keyCode === 13 && this.closeEdits()} 
                            autoFocus 
                            variant="standard" 
                            className="name-edit" 
                            name="name" 
                            value={this.state.name} 
                            useOnChange={true} 
                            onChange={e => this.handleChange(e)} />
                        </ClickAwayWrapper>
                )}
                {!this.state.edit.probability_percent && (<div className={`probability_percent ${this.props.editDisabled ? "disabled" : ""}`} onClick={e => this.edit("probability_percent")}>{this.state.probability_percent}%</div>)}
                {this.state.edit.probability_percent && (
                    <ClickAwayWrapper onClickAway={this.closeEdits}>
                        <OutlinedField 
                            onKeyDown={(e) => e.keyCode === 13 && this.closeEdits()} 
                            autoFocus 
                            variant="standard" 
                            className="probability_percent-edit" 
                            name="probability_percent" 
                            value={this.state.probability_percent} 
                            validate={["numeric"]} 
                            useOnChange={true} 
                            onChange={e =>  this.handleChange(e)} />
                    </ClickAwayWrapper>
                )}

                <div className="count" onClick={e => this.edit("probability_percent")}>{cardAmount} {this.tr("projects")}</div>

                {hasProjectWithValues && (
                    <React.Fragment>
                        <div className="values">
                            {this.tr('Value')} <span>{currencyFormatter(revenue)} / {currencyFormatter(margin)}</span>
                        </div>
                        <div className="values">
                            {this.tr('Probability')} <span>{currencyFormatter(p * revenue)} / {currencyFormatter(p * margin)}</span>
                        </div>
                    </React.Fragment>
                )}
            </>
        );
    }
}


ProjectKanbanColumnHeader.defaultProps = {
    contextType: SettingsContext, 
    column: { name: "", probability_percent: "", cards: [] }
};


// TODO: the column header (and preferably all the components) must be fully customizable – now there are styles in ListKanban.scss that should only be in ProjectKanban.scss.

const KANBAN_HEADER_COMPENSATION = 100;

class ListKanban extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "projects/ListKanban");

        this.container         = React.createRef();
        this.draggedCardData   = undefined;
        this.draggedCard       = null;
        this.draggedColumn     = null;
        this.grabX             = undefined;
        this.grabY             = undefined;

        this.lanes             = [];
        this.columnReferences  = [];

        this.lastColumn        = undefined;
        this.startRowIndex     = undefined;
        this.lastData          = undefined;
        this.lastNth           = undefined;
        this.lastColumnNth     = undefined;
        this.lastList          = undefined;

        this.columnDragTimeout = undefined;
        this.cardDragTimeout   = undefined;

        this.state = {
            addColumnDialog: false,
            addCardDialog: false,
            dragging: false,
            draggingWhich: undefined,
            columns: props.columns,
            scrolling: false
        };

        this.handleColumnsChange    = this.handleColumnsChange.bind(this);
        this.openAddColumnDialog    = this.openAddColumnDialog.bind(this);
        this.closeAddColumnDialog   = this.closeAddColumnDialog.bind(this);
        this.openAddCardDialog      = this.openAddCardDialog.bind(this);
        this.closeAddCardDialog     = this.closeAddCardDialog.bind(this);
        this.handleScrollStart      = this.handleScrollStart.bind(this);
        this.handleScrollEnd        = this.handleScrollEnd.bind(this);
        this.handleMouseMove        = this.handleMouseMove.bind(this);
        this.handleMouseLeave       = this.handleMouseLeave.bind(this);
        this.handleDrag             = this.handleDrag.bind(this);
        this.handleColumnDrag       = this.handleColumnDrag.bind(this);
        this.handleCardDragStart    = this.handleCardDragStart.bind(this);
        this._handleCardDragStart    = this._handleCardDragStart.bind(this);
        this.handleColumnDragStart  = this.handleColumnDragStart.bind(this);
        this._handleColumnDragStart = this._handleColumnDragStart.bind(this);
        this.handleMouseUpOnHeader  = this.handleMouseUpOnHeader.bind(this);
        this.handleMouseUp          = this.handleMouseUp.bind(this);
        this.handleMouseUpForCard   = this.handleMouseUpForCard.bind(this);
        this.handleMouseUpForColumn = this.handleMouseUpForColumn.bind(this);
    }


    componentDidUpdate(prevProps) {
        if(!isEqual(prevProps.columns, this.props.columns)) {
            this.handleColumnsChange();
        }

        if(this.props.columns !== prevProps.columns) {
            this.setState({ columns: this.props.columns });
        }
    }


    handleColumnsChange() {
        [...this.props.columns, null].forEach((column, index) => {
            if(this.columnReferences[index]) {
                return;
            }

            this.columnReferences[index] = React.createRef();
        });

        this.setState({
            columns: this.props.columns
        });
    }


    componentDidMount() {
        this.handleColumnsChange();

        document.addEventListener("mouseup", this.handleMouseUp)
    }


    componentWillUnmount() {
        document.removeEventListener("mouseup", this.handleMouseUp)
    }


    openAddColumnDialog(e) {
        if(e.target !== this.columnReferences[this.columnReferences.length - 1].current) {    
           return;
        }

        this.setState({ addColumnDialog: true });
    }


    closeAddColumnDialog() {
        this.setState({ addColumnDialog: false });
    }


    openAddCardDialog(i) {
        this.setState({ addCardDialog: i });
    }


    closeAddCardDialog() {
        this.setState({ addCardDialog: -1 });
    }


    handleScrollStart() {
        if(this.state.scrolling) {
            return;
        }

        this.setState({ scrolling: true });
    }


    handleScrollEnd() {
        if(!this.state.scrolling) {
            return;
        }

        this.setState({ scrolling: false });
    }


    // Only used for cards.
    handleMouseMove({ event, scrollTop, column, list }) {
        if(!this.state.dragging || this.state.draggingWhich !== "card") {
            return;
        }

        const halfCard         = this.props.cardHeight / 2;
        let scrollCompensation = scrollTop % this.props.cardHeight;
        scrollCompensation     = (scrollCompensation >= halfCard) ? -scrollCompensation : +scrollCompensation;

        if(Math.abs(scrollCompensation) > halfCard) {
            scrollCompensation = scrollCompensation < 0 ? -(halfCard) : + (halfCard);
        }

        const withoutScroll = event.nativeEvent.pageY - this.container.current.offsetTop - KANBAN_HEADER_COMPENSATION;
        const absoluteNth   = Math.round((withoutScroll + scrollTop) / this.props.cardHeight);
        const floatNth      = (withoutScroll + scrollCompensation) / this.props.cardHeight;
        const nth             = Math.round(floatNth) + (list._overscanActive ? list.props.virtualization__overscan / 2 : 0);

        if(nth === this.lastNth && list === this.lastList && this.lastColumn === column) {
            return;
        }

        this.lastColumn = column;
        this.lastData   = column.cards.hasOwnProperty(absoluteNth) ? column.cards[absoluteNth] : undefined;
        this.lastNth    = nth;
        this.lastList   = list;

        list.resetShifts();
        list.shiftRowsBelow(nth);
    }


    handleMouseLeave(list) {
        if(!this.state.dragging) {
            return;
        }

        // This is a bit problematic.. 
        // If the card is dragged on top of the header, 
        // then the shifts should not be reset.
        // if(this.state.dragging) { 
            // return;
        // }

        list.resetShifts();

        this.lastNth  = undefined;
        this.lastList = undefined;
    }


    // Handles dragging of both cards and columns.
    handleDrag(event) {
        if(!this.state.dragging || this.state.draggingWhich === undefined) {
            return;
        }

        const toDrag = ({ card: this.draggedCard, column: this.draggedColumn })[this.state.draggingWhich];

        event.preventDefault();

        const { pageX, pageY }          = event.nativeEvent;
        const { offsetLeft, offsetTop } = this.container.current
        const scrollOffset              = this.props.getScrollOffset();

        const x = (pageX - offsetLeft + scrollOffset) - this.grabX;
        const y = (pageY - offsetTop) - this.grabY;

        toDrag.style.left = `${x}px`;
        toDrag.style.top  = `${y}px`;

        // If the user's dragging a card, 
        // stop here, since card dragging is 
        // handled differently (check handleMouseMove).
        if(this.state.draggingWhich === "card") {
            return;
        }

        this.handleColumnDrag({ x, y });
    }


    // TODO: Column width is hard-coded as 300px for now. Make it customizable. 
    // Check the todo at the top of this file too.
    handleColumnDrag({ x, y }) {
        const nth  = Math.floor((x + this.grabX) / 300) - 1;
        const refs = this.columnReferences.filter(column => column.current !== this.draggedColumn);

        if(nth === this.lastColumnNth || !this.columnReferences[nth + 1]) {
            return;
        }

        this.lastColumnNth = nth;

        // No action if dragged past the "add column" column.
        if(this.columnReferences[nth + 1] === this.columnReferences[this.columnReferences.length - 1]) {
            return;
        }

        refs.forEach((ref, index) => {
            // If the column is to the left of which we're 
            // hovering on right now, reset its left offset.
            if(index <= nth) {
                ref.current.style.left = "0px";
                return;
            }

            ref.current.style.left = "300px";
        });

    }

    handleCardDragStart({ event, ref, data, column, rowIndex }) {
        this.cardDragTimeout && clearTimeout(this.cardDragTimeout);
        event.persist();
        this.cardDragTimeout = setTimeout(() => {
            this._handleCardDragStart({event, ref, data, column, rowIndex});
        }, 300);
    }


    _handleCardDragStart({ event, ref, data, column, rowIndex }) {
        event.persist();

        const { nativeEvent } = event;
        const target          = ref.current;

        this.draggedCardData = cloneDeep(data);
        this.lastColumn      = column;
        this.startRowIndex   = rowIndex;
        this.grabX           = nativeEvent.layerX;
        this.grabY           = nativeEvent.layerY;
        this.draggedCard     = target.cloneNode(true);
        
        this.draggedCard.classList.add("isBeingDragged");
        Array.from(this.draggedCard.childNodes)[0].classList.add("isBeingDragged");

        this.draggedCard.style.pointerEvents = "none";

        this.container.current.appendChild(this.draggedCard);

        // Take the grabbed card out of the data of the column, 
        // so it appears to have been grabbed by the user.
        const columns = cloneDeep(this.state.columns).map(column => {
            if(column[this.props.rowKey] === column.id) {
                column.cards = column.cards.filter(c => c[this.props.rowKey] !== data[this.props.rowKey]);
            }

            return column;
        });

        this.setState({ 
            dragging: true, 
            draggingWhich: "card",
            columns: columns
        }, () => {
            this.handleDrag(event);
        });

        event.stopPropagation();
    }


    handleColumnDragStart({ event, columnRef, data }) {
        clearTimeout(this.columnDragTimeout);

        event.persist();

        this.columnDragTimeout = setTimeout(() => {
            this._handleColumnDragStart({ event, columnRef, data });
        }, 300);
    }


    _handleColumnDragStart({ event, columnRef, data }) {
        if(event.button !== 0) {
            return;
        }

        event.persist();

        const { nativeEvent }  = event;

        this.draggedColumnData = cloneDeep(data);
        this.grabX             = nativeEvent.layerX;
        this.grabY             = nativeEvent.layerY;

        this.draggedColumn     = columnRef.current;
        
        columnRef.current.classList.add("isBeingDragged");

        this.setState({
            dragging: true, 
            draggingWhich: "column"
        }, () => {
            this.handleDrag(event);
        });
    }


    handleMouseUpOnHeader() {
        clearTimeout(this.columnDragTimeout);
    }


    handleMouseUp() {
        if(this.cardDragTimeout) {
            clearTimeout(this.cardDragTimeout);
            this.cardDragTimeout = undefined;
        }

        if(!this.state.dragging) {
            return;
        }

        ({ card: this.handleMouseUpForCard, column: this.handleMouseUpForColumn })[this.state.draggingWhich]();
    }


    handleMouseUpForCard() {
        const droppedCard                 = this.draggedCardData;
        const previousColumn              = droppedCard[this.props.columnKey];
        droppedCard[this.props.columnKey] = this.lastColumn.id;

        const columns = cloneDeep(this.state.columns).map(column => {
            if(column[this.props.rowKey] !== this.lastColumn.id) {
                return column;
            }

            const index  = this.lastData !== undefined ? column.cards.findIndex(card => card[this.props.rowKey] === this.lastData[this.props.rowKey]) : column.cards.length;
            const start  = column.cards.slice(0, index);
            const rest   = column.cards.slice(index);

            column.cards = [...start, droppedCard, ...rest];

            return column;
        });

        this.setState({
            columns: columns,
            dragging: false,
            draggingWhich: undefined
        }, () => {
            this.draggedCard.parentNode.removeChild(this.draggedCard);

            this.props.onCardMove(droppedCard, droppedCard[this.props.columnKey], previousColumn, this.startRowIndex, this.lastNth);

            this.draggedCardData = undefined;
            this.draggedCard     = null;
            this.grabX           = undefined;
            this.grabY           = undefined;
            this.lastColumn      = undefined;
            this.startRowIndex   = undefined;
            this.lastData        = undefined;
            this.lastNth         = undefined;
            this.lastList        = undefined;
        });
    } 


    handleMouseUpForColumn() {
        const droppedColumn = this.draggedColumnData;
        const droppedAsNth  = ++this.lastColumnNth;
        let columns         = this.state.columns.filter(column => column[this.props.rowKey] !== this.draggedColumnData[this.props.rowKey]);
        columns = [...columns.slice(0, droppedAsNth), droppedColumn, ...columns.slice(droppedAsNth)];

        // Add a class to all column elements,
        // which disables their transition style.
        this.columnReferences.forEach(ref => ref.current.classList.add("isBeingRearranged"));

        // Reset the dragged column's class and styles.
        this.draggedColumn.classList.remove("isBeingDragged");
        this.draggedColumn.style.top = null;

        this.setState({
            columns: columns,
            dragging: false,
            draggingWhich: undefined
        }, () => {
            // // Remove the class that disables the transition style of columns.
            this.columnReferences.forEach(ref => {
                ref.current.style.left = null;
            });

            // Wow.
            setTimeout(() => {
                this.columnReferences.filter(r => r.current !== null).forEach(ref => {
                    ref.current.classList.remove("isBeingRearranged");
                });
            });

            this.props.onColumnMove(droppedColumn, droppedAsNth);

            this.draggedColumnData = undefined;
            this.draggedColumn     = null;
            this.grabX             = undefined;
            this.grabY             = undefined;
            this.lastColumnNth     = undefined;
        });
    }


    render() {
        const AddColumnComponent = this.props.addColumnComponent;

        return (
            <div className={`kanban-container ${this.state.dragging && !this.state.scrolling ? "dragging" : ""}`} ref={this.container} style={{ width: (this.props.columns.length + 1) * 310 }} onMouseMove={this.handleDrag}>
                {this.state.columns.map((column, i) => {
                    return (
                        <div ref={this.columnReferences[i]} className="column">
                            {this.state.addCardDialog === i && this.props.addCardComponent({ ...column, closeDialog: () => this.setState({ addCardDialog: -1 }) })}
                            <div className={`headerContainer ${this.props.canDragColumns ? "draggable" : ""}`} onMouseDown={e => this.props.canDragColumns && this.handleColumnDragStart({
                                event: e, 
                                columnRef: this.columnReferences[i],
                                data: column
                            })}
                            onMouseUp={this.handleMouseUpOnHeader}>
                                {this.props.headerComponent(column)}
                            </div>
                            <KanbanList
                                rowKey={this.props.rowKey}
                                virtualized={true}
                                virtualization__overscan={8}
                                showPageSelector={false}
                                noStateData={true}
                                hideHeader={true}
                                noColorVariance={true}
                                rowHeight={122}
                                manualListWidth={292}
                                trimHeight={-4}
                                ignoreRowPropsChange={false}
                                virtualization__beforeVirtualizationOrderRotation={this.handleScrollStart}
                                virtualization__afterVirtualizationOrderRotation={this.handleScrollEnd}
                                onMouseMove={(event, scrollTop, list) => {
                                    this.handleMouseMove({ event, scrollTop, column, list })
                                }}
                                onMouseLeave={this.handleMouseLeave}
                                height={"fitRemaining"}
                                rowProps={{
                                    cardDraggingEnabled: this.props.canDragCards,
                                    dragging: this.state.dragging,
                                    getQuoteStatusTooltip: this.props.getQuoteStatusTooltip
                                }}
                                rowCallbacks={{
                                    startDrag: (args) => this.handleCardDragStart({...args, column})
                                }}
                                listRowType={ProjectKanbanCard}
                                columns={[
                                    { name: "column", header: "" }
                                ]}
                                data={column.cards}
                                sharedData={this.props.sharedData}
                            />
                            {this.props.sharedData.projectWritePrivilege(this.props.data.companies_id) &&
                                <div data-testid={`kanban_add_project_button_${i}`} className="addCardButton" onClick={() => this.props.openAddCardDialog ? this.props.openAddCardDialog(column) : this.openAddCardDialog(i)}>
                                    {this.props.addCardButtonText}
                                </div>
                            }
                        </div>
                    );
                })}
                <div className={cn("column addColumn", this.state.addColumnDialog && "addingColumn")} ref={this.columnReferences[this.columnReferences.length - 1]} onClick={this.openAddColumnDialog}>
                    {!this.state.addColumnDialog && this.props.canAddColumns && <>
                        <AddCircleOutline style={{ color: colors.subtitle, height: "16px", marginLeft: "4px", marginTop: "-2px" }} />
                        {this.tr("Add column")}
                    </>}
                    {this.state.addColumnDialog && (
                        <AddColumnComponent 
                            onCancel={this.closeAddColumnDialog}
                            onAdd={(columnData) => {
                                this.closeAddColumnDialog();

                                this.props.onColumnAdd(columnData);
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

ListKanban.defaultProps = {
    addCardButtonText: "Add card",
    addCardComponent: () => null,
    cardHeight: 122,
    cardType: CardRow,
    columnKey: "columnId",
    canAddColumns: true,
    canDragCards: true,
    canDragColumns: true,
    contextType: SettingsContext,
    columns: [],
    data: [],
    headerComponent: () => KanbanColumnHeader,
    addColumnComponent: null,
    onCardMove: () => {},
    onColumnMove: () => {},
    onColumnAdd: () => {},
    rowKey: "id",
    sharedData: {}
};


class Card extends TaimerComponent {
    static contextType = SettingsContext;
    state = {
        color: "",
        closing_date: false
    }

    constructor(props, context) {
        super(props, context, "projects/ProjectKanban");

        const { locked, status } = props;

        let color = "";

        if (locked == 2)
            color = "hold";
        else if (locked == 1)
            color = "lost";
        else if (status == 5)
            color = "internal";
        else if (status == 1)
            color = "won";
        
        // else if (locked == -1)
        //     color = "";

        this.state = {
            color
        }
    }

    onHold () {
        DataHandler.post({url: `projects/${this.props.id}/status`}, {"locked": "2"});
        this.setState({color: 'hold'});
    }
    lost () {
        DataHandler.post({url: `projects/${this.props.id}/status`}, {"locked": "1"});
        this.setState({color: 'lost'});
    }
    active () {
        DataHandler.post({url: `projects/${this.props.id}/status`}, {"locked": "-1"});
        this.setState({color: ''});
    }
    won () {
        DataHandler.post({url: `projects/${this.props.id}/status`}, {"status": "1", 'type': '1', 'status_date': new Date()})
            .done( () => {
                this.setState({color: 'won'});
            }).fail(e => {
                const { enqueueSnackbar } = this.props;
                const resp = e.responseJSON;

                if (resp && resp.error === "invalid_state") {
                    enqueueSnackbar(`${this.tr('Required fields not filled:')} ${resp.missing.join(", ")}`, {
                        variant: "error",
                    });
                }
            });
    }
    internal () {
        DataHandler.post({url: `projects/${this.props.id}/status`}, {"status": "5"});
        this.setState({color: 'internal'});
    }
    open (e) {
        const { functions: { updateView }} = this.context;
        updateView({ module: 'projects', action: 'view', id: this.props.id, view: 'kanban'}, e.ctrlKey || e.metaKey || e.button === 1);
    }
    move = (pipeline) => {
        DataHandler.post({url: `projects/${this.props.id}/status`}, {"projects_pipelines_id": pipeline.id}).done(response => {
            this.props.onMove && this.props.onMove();
        }).fail(e => {
            const { enqueueSnackbar } = this.props;
            const resp = e.responseJSON;

            if (resp && resp.error === "invalid_state") {
                enqueueSnackbar(`${this.tr('Required fields not filled:')} ${resp.missing.join(", ")}`, {
                    variant: "error",
                });
            }
        });
    }

    showActivitySlider = () =>  {
        if (!this.props.showActivitySlider) return;
        this.props.showActivitySlider(this.props);
    }

    changeClosingDate = (date) => {
        const data = { closing_date: date };

        DataHandler.put({ url: `projects/${this.props.id}` }, data).done(response => {
            this.setState({ closing_date: date });
            this.props.enqueueSnackbar(this.tr("Closing date saved successfully!"), {
                variant: "success",
            });
        }).fail(e => {
            this.props.enqueueSnackbar(this.tr("Error in saving closing date!"), {
                variant: "error",
            });
        });
    }


    render() {
        const { props } = this;
        const { projects_pipelines_id, write } = props;
        const { color } = this.state;
        const { userObject, taimerAccount, functions: { checkPrivilege } } = this.context;
        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: taimerAccount.currency
        }).format;

        let project_team = [];

        if(Array.isArray(props.project_team))
            project_team = props.project_team;
        else if(typeof props.project_team === "string")
            project_team = String(props.project_team).split(",");

        const pipelines = props.pipelines && props.pipelines.filter(p => p.id > 0 && p.id != projects_pipelines_id);
        const activityColor = this.props.activitiesOverdue ? "#f97fab" : (this.props.activitiesDue ? "#d4890d" : "#828691");
        const closingClassName = this.props.status === "1" || this.props.status === "5" ? "closing-white-input" : "";

        return (
            <div className={`project ${color}`}>
                <div className="project-name" 
                    onClick={e => (e.button !== 1) && this.open(e)}
                    onMouseUp={e => (e.button === 1) && this.open(e)}>
                    {props.name} <span className="project-id">({props.project_id})</span>
                </div>
                <div>{props.dueOn}</div>
                <ContextMenu label={<MoreHoriz />} noExpandIcon disablePortal={false} className="options cell row-menu">
                    <MenuItem 
                        className="menuIcon"
                        onClick={e => (e.button !== 1) && this.open(e)}
                        onMouseUp={e => (e.button === 1) && this.open(e)}>
                        <ViewIcon />{this.tr('View')
                        }</MenuItem>
                    {write && color && (<MenuItem className="menuIcon" onClick={e => this.active()}><ActivateIcon />{this.tr('Activate')}</MenuItem>)}
                    {write && color !== 'won' && color !== 'internal' && (<MenuItem  className="menuIcon" onClick={e => this.won()}><MarkAsWonIcon />{this.tr('Mark as won')}</MenuItem>)}
                    {write && color !== 'hold' && (<MenuItem  className="menuIcon" onClick={e => this.onHold()}><OnHoldIcon title="" />{this.tr('Put on hold')}</MenuItem>)}
                    {write && color !== 'lost' && (<MenuItem  className="menuIcon" onClick={e => this.lost()}>{color == 'won' ? <><ArchiveIcon /> {this.tr('Archive')}</> : <><CloseAsLostIcon /> {this.tr('Close as lost')}</>} </MenuItem>)}
                    {write && pipelines && pipelines.length > 0 && <ContextSubMenu  icon={<MovePipelineIcon className="PipelinemenuIcon"  title="" />} title={this.tr("Move to Pipeline")}>
                        {
                            pipelines.map( (p) => <MenuItem onClick={() => this.move(p)}>{p.name}</MenuItem>)
                        }
                    </ContextSubMenu>}
                </ContextMenu>
                <div className="account">{props.customer}</div>
                { props.revenue !== false  && props.salesmargin !== false && (
                    <div className="values">
                        {currencyFormatter(props.revenue)} / {currencyFormatter(props.salesmargin)}
                    </div>
                )
                }
                <div className="footer">
                    <div className="icons">
                        {project_team.find(u => u == userObject.usersId) && checkPrivilege("newsfeed", "newsfeed") &&
                        <SmsOutlined
                            onClick={() => this.props.openTeamChat({module: 'projects', action: 'view', id: props.id})}
                            className="messages" /> }
                        {checkPrivilege("projects", "project_crm_read") && <Tooltip placement="top" title={this.tr("Activity")}>
                            <div style={{ backgroundColor: activityColor }} onClick={this.showActivitySlider} className="activity">
                                <MenuRounded className="icon" />
                            </div>
                        </Tooltip>}
                    </div>
                    <span className="closing">{this.tr('Closing')} 
                        <DateCell
                            className={"closing-date " + closingClassName}
                            editable={true}
                            offsetCalendar={true}
                            closeOnComplete={false}
                            closeCalendarOnComplete={true}
                            popperBottom={true}
                            usePopper={true}
                            preventDefaultOnCalendarClick={true}
                            value={this.state.closing_date ? this.state.closing_date : props.closing_date}
                            showEmptyValue={true}
                            onEdited={(name, value) => {
                                this.changeClosingDate(value);
                            }}
                        />
                    </span>
                </div>
            </div>
        );
    }
}


class LaneHeader extends TaimerComponent {
    static contextType = SettingsContext;
    constructor(props, context) {
        super(props, context, "projects/ProjectKanban");
    }    
    state = {
        name: false,
        probability_percent: false,
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({name: false, probability_percent: false});
        this.props.laneChanged(this.props.id, {[name]: value});
    }
    edit(field) {
        this.setState({[field]: true});
    }
    render() {
        const { props } = this;
        const { name, probability_percent } = this.state;
        const { taimerAccount } = this.context;
        let margin = 0, revenue = 0;
        let hasProjectWithValues = false;
        props.cards.forEach(e => { 
            if(e.revenue !== false && e.salesmargin !== false) {
                margin += e.salesmargin-0;
                revenue += e.revenue-0;
                hasProjectWithValues = true;
            }
        });

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: taimerAccount.currency
        }).format;

        const p = props.probability_percent / 100.0;

        return (
            <div className="lane-header">
                {!name && (<div className="name" onClick={e => this.edit("name")}>{props.name}</div>)}
                {name && (<OutlinedField autoFocus variant="standard" className="name-edit" name="name" value={props.name} onChange={e => this.handleChange(e)} />)}
                {!probability_percent && (<div className="probability_percent" onClick={e => this.edit("probability_percent")}>{props.probability_percent}%</div>)}
                {probability_percent && (<OutlinedField autoFocus variant="standard" className="probability_percent-edit" name="probability_percent" value={props.probability_percent} validate={["numeric"]} onChange={e =>  this.handleChange(e)} />)}

                <div className="count">
                    {`${props.cards.length}`} {this.tr('projects')}<br/>
                </div>
                {
                    hasProjectWithValues && (
                        <React.Fragment>
                            <div className="values">
                                {this.tr('Value')} <span>{currencyFormatter(revenue)} / {currencyFormatter(margin)}</span>
                            </div>
                            <div className="values">
                                {this.tr('Probability')} <span>{currencyFormatter(p * revenue)} / {currencyFormatter(p * margin)}</span>
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
        );
    }
}

class ProjectKanban extends TaimerComponent {
    state = {
        sales_states: [],
        projects: undefined,
        status: undefined,
        projects_pipelines_id: undefined,
        curStatus: undefined,
        curPipeline: undefined,
        activitySliderProject: null,
        isProjectLimitReached: false,
        currentDialog: "",
        showDialog: false,
        targetProject: null
    }
    constructor(props, context) {
        super(props, context, "project/ProjectKanban");
        this.container = React.createRef();
        this.addProjectDialog = React.createRef();
        ["handleLaneDragEnd", "handleDragEnd", "laneChanged", "updateComponentData", "handleProjectMove", "handleLaneMove", "handleNewLane"].forEach(e => this[e] = this[e].bind(this));
    }

    static getDerivedStateFromProps(props, state) {
        if (props.status === state.curStatus && props.projects_pipelines_id === state.curPipeline)
            return state;

        return {...state, status: props.status, projects_pipelines_id: props.projects_pipelines_id};
    }

    async componentDidMount() {
        this.updateComponentData();    
    }

    componentDidUpdate(prevProps) {
        if(!isEqual(this.props, prevProps)) {
            this.updateComponentData();
        }
    }

    delayedUpdateComponentData(force) {
        setTimeout(() => this.updateComponentData(force), 1000);
    }

    updateComponentData(force) {
        const {  status, projects_pipelines_id , curStatus, curPipeline, curCompany } = this.state;
        const { company } = this.props;

        if (!force && curStatus === status && curPipeline === projects_pipelines_id && curCompany === company) {
            return;
        }

        const handler = data => {
            data.forEach(e => {
                e.cards = [];
                e.laneChanged = this.laneChanged;
            });
            this.addProjectDialog.current && this.addProjectDialog.current.cancel && this.addProjectDialog.current.cancel();
            this.setState({sales_states: data, curStatus: status, curPipeline: projects_pipelines_id, curCompany: company});
        };

        if (status == 0)
            DataHandler.get({url: `subjects/sales_states/${projects_pipelines_id}`}).done(handler);
        else if (status == 1)
            DataHandler.get({url: `subjects/project_states/${company}`}).done(handler);
        else if (status == 5)
            DataHandler.get({url: `subjects/internal_states/${company}`}).done(handler);


    }
    laneSortFunction(card1, card2) {
        const name1 = card1.project.toLowerCase();
        const name2 = card2.project.toLowerCase();

        if (name1 < name2) {
            return -1;
        }
        if (name1 > name2) {
            return 1;
        }
        return 0;
    }
    handleLaneDragEnd(laneId, newPosition) {

        const { sales_states } = this.state;
        const stateId = sales_states[laneId].id;
        sales_states.splice(newPosition, 0, sales_states.splice(laneId, 1)[0]);
        this.setState({ sales_states });

        DataHandler.put({url: `subjects/sales_states/change_order/${stateId}`}, {position: newPosition+1});
    }

    handleProjectMove(data, laneId, previousLaneId, startRowIndex, endRowIndex) {
        if(laneId === previousLaneId) {
            this.updateComponentData(true);

            if (startRowIndex !== endRowIndex && endRowIndex != undefined) {
                this.props.enqueueSnackbar(this.tr("Card was moved to the same state. States are sorted automatically."), {
                    variant: "warning",
                });
            }

            return;
        }

        DataHandler.put({url: `projects/${data.id}/sales_state`}, { projects_sales_states_id: laneId }).fail(e => {
            const { fetchData, enqueueSnackbar } = this.props;
            const resp = e.responseJSON;

            if(resp && resp.error === "invalid_state") {
                enqueueSnackbar(this.tr("Required fields not filled: ${response}", {response: resp.missing.join(", ")}), {
                    variant: "error",
                });
            }

            // Second param -> force override 
            // shouldComponentUpdate in ProjectList.
            this.props.fetchData(null, true);
        }).done(() => {
            this.props.onMove();
        });
    }

    handleLaneMove(droppedColumn, droppedAsNth) {
        const { sales_states } = this.state;
        let newSalesStates     = sales_states.filter(ss => ss.id !== droppedColumn.id);
        newSalesStates         = [...newSalesStates.slice(0, droppedAsNth), droppedColumn, ...newSalesStates.slice(droppedAsNth)];

        this.setState({ sales_states: newSalesStates }, () => {
            DataHandler.put({ url: `subjects/sales_states/change_order/${droppedColumn.id}` }, { position: droppedAsNth + 1 });
        });
    }


    handleDragEnd(cardId, sourceLaneId, targetLaneId, position, cardDetails) {
        if (sourceLaneId === targetLaneId) {
            this.updateComponentData(true);
            this.props.enqueueSnackbar(this.tr("Card was moved to the same state. States are sorted automatically."), {
                variant: "warning",
            });
            return;
        }

        DataHandler.put({url: `projects/${cardId}/sales_state`}, {projects_sales_states_id: targetLaneId}).fail(e => {

            const { fetchData, enqueueSnackbar } = this.props;
            const resp = e.responseJSON;

            if (resp && resp.error === "invalid_state") {
                enqueueSnackbar(this.tr("Required fields not filled: ${response}", {response: resp.missing.join(", ")}), {
                    variant: "error",
                });
            }

            this.updateComponentData(true);
        }).done( () => this.props.onMove());
    }
    handleNewLane(data) {
        const { 
            status, 
            projects_pipelines_id,
            company
        }    = this.props;
        
        data = { name: data.title, company: company };

        if(status == 0) {
            DataHandler.post({url: "subjects/sales_states/" + projects_pipelines_id}, data).done(() => this.delayedUpdateComponentData(true));
        } else if(status == 1) {
            DataHandler.post({url: "subjects/project_states"}, data).done(() => this.delayedUpdateComponentData(true));
        } else if(status == 5) {
            DataHandler.post({url: "subjects/internal_states"}, data).done(() => this.delayedUpdateComponentData(true));
        }
    }
    laneChanged(id, data) {
        // TODO What is the updateComponentData needed for?
        // DataHandler.put({url: 'subjects/sales_states/' + id}, data).done(this.updateComponentData);
        
        DataHandler.put({url: 'subjects/sales_states/' + id}, data); 
    }

    showActivitySlider = (activitySliderProject) => {
        this.setState({ activitySliderProject });
    }

    hideActivitySlider = () => {
        this.setState({ activitySliderProject: null });
    }

    getScrollOffset = () => {
        return this.container.current?.scrollLeft || 0;
    }

    determineColor() {
        let color;
        const { status } = this.props;
        if(status == 5) {
            color = "internal";
        } else if(status == 1) {
            color = "won";
        }
        return color;
    }

    onAddProject = stage => {
        this.context.functions.addProject({ projects_sales_states_id: stage.id, projects_pipelines_id: this.state.projects_pipelines_id, status: this.props.status, origin_point: "kanban", }, { stayInCurrentView: true });
    }

    render() {
        const { taimerAccount } = this.context;
        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: taimerAccount.currency
        }).format;

        const { sales_states, status, projects_pipelines_id, activitySliderProject, isProjectLimitReached, dialogOpen } = this.state;
        const data = cloneDeep(this.props.data);
        const { functions: { checkPrivilege } } = this.context;

        if (!sales_states.length || data === undefined && data.length) {
            return (<div></div>);
        }

        const projects = {};

        data.sort(this.laneSortFunction);
        data.forEach(e => projects[e.projects_sales_states_id] ? projects[e.projects_sales_states_id].push(e) : projects[e.projects_sales_states_id] = [e]);

        const lanes = [];

        sales_states.forEach(e => {
            lanes.push({
                ...e,
                cards: projects[e.id] || [],
            })
        });

        const laneColorClass = this.determineColor();

        // const components = {
            // AddCardLink:      (props) => <Button size="large" {...props} className="add-project-button">{this.tr('Add Project')} <AddCircleOutline /></Button>,
            // LaneHeader:       LaneHeader,
            // NewCardForm:      (props) => <AddProject {...props} fetchData={this.props.fetchData} status={status} projects_pipelines_id={projects_pipelines_id} enqueueSnackbar={this.props.enqueueSnackbar} />,
            // NewLaneForm:      AddStage,
            // Card:             (props) => <Card
                                            // {...props}
                                            // showActivitySlider={this.showActivitySlider}
                                            // onMove={this.props.onMove}
                                            // pipelines={this.props.pipelines} 
                                            // updateView={this.props.updateView} 
                                            // openTeamChat={this.props.openTeamChat}
                                            // enqueueSnackbar={this.props.enqueueSnackbar} />
        // };

        lanes.forEach(lane => {
            lane.margin               = 0;
            lane.revenue              = 0;
            lane.hasProjectWithValues = false;

            lane.cards.forEach(card => {
                if(card.project_team === null) {
                    card.project_team = {};
                } else if(typeof(card.project_team) === "string") {
                    card.project_team = makeMapOfPrimitives(card.project_team.split(","));
                } 

                if(card.revenue === false && card.salesmargin === false) {
                    return;
                }

                lane.hasProjectWithValues = true;
                lane.margin  += (parseFloat(card.salesmargin) || 0);
                lane.revenue += (parseFloat(card.revenue) || 0);
            });

            lane.cards = lane.cards.map(c => {
                const hasProjectWithValues = c.revenue !== false && c.salesmargin !== false;

                if (hasProjectWithValues) {
                    c.revenue     = currencyFormatter(c.revenue);
                    c.salesmargin = currencyFormatter(c.salesmargin);
                } else {
                    c.revenue = '-';
                    c.salesmargin = '-';
                }

                return c;
            });
        });

        return (
            <div id="project-kanban" ref={this.container}>
                <ActivitySlider tr={this.tr} onMove={this.props.onMove} weekStart={this.context.calendar.startOfWeek} project={activitySliderProject} open={Boolean(activitySliderProject)} onClose={this.hideActivitySlider} parentComponent="Project Kanban"/>
                
                <ListKanban 
                    addCardButtonText={(
                        <>
                            <AddCircleOutline style={{ color: colors.subtitle, height: "16px", marginLeft: "4px", marginTop: "-2px" }} />
                            {this.tr("Add project")}
                        </>
                    )}
                    // addCardComponent={props => <AddProject {...props} fetchData={this.props.fetchData} status={status} projects_pipelines_id={projects_pipelines_id} enqueueSnackbar={this.props.enqueueSnackbar} />}
                    addCardComponent={props => <AddProject ref={this.addProjectDialog} onAdd={props.closeDialog} onCancel={props.closeDialog} fetchData={this.props.fetchData} status={status} projects_pipelines_id={this.state.projects_pipelines_id} laneId={props.id} enqueueSnackbar={this.props.enqueueSnackbar} />}
                    openAddCardDialog={this.onAddProject}
                    canAddColumns={checkPrivilege("admin", "admin", this.props.company)}
                    canDragCards={checkPrivilege("projects", "write", this.props.company)}
                    canDragColumns={checkPrivilege("admin", "admin", this.props.company)}
                    columns={lanes} 
                    columnKey="laneId" 
                    onCardMove={this.handleProjectMove}
                    onColumnMove={this.handleLaneMove}
                    onColumnAdd={this.handleNewLane}
                    getScrollOffset={this.getScrollOffset}
                    onCardAdd={() => {}}
                    headerComponent={(column) => <ProjectKanbanColumnHeader className={laneColorClass} column={column} editDisabled={!checkPrivilege("admin", "admin", this.props.company)} laneChanged={this.laneChanged} />}
                    addColumnComponent={AddStage}
                    sharedData={{
                        archiveProject: this.props.archiveProject,
                        onMove: this.props.onMove,
                        onWon: this.props.onWon,
                        showActivitySlider: this.showActivitySlider,
                        openTeamChat: this.props.openTeamChat,
                        pipelines: this.props.pipelines,
                        newsFeedPrivilege: checkPrivilege("newsfeed", "newsfeed"),
                        crmReadPrivilege: checkPrivilege("projects", "project_crm_read", this.props.company),
                        projectWritePrivilege: (company) => checkPrivilege("projects", "write", company),
                        enqueueSnackbar: this.props.enqueueSnackbar,
                        isProjectLimitReached: this.props.isProjectLimitReached,
                        openDialog: this.props.openDialog,
                        closeDialog: this.props.closeDialog,
                        onDialogSave: this.props.onDialogSave,
                        toggleBuyDialog: this.props.toggleBuyDialog,
                        projectWonReasonIsInUse: this.props.projectWonReasonIsInUse,
                        projectLostReasonIsInUse: this.props.projectLostReasonIsInUse,
                        getQuoteStatusTooltip: this.props.getQuoteStatusTooltip,
                        goToQuote: this.props.goToQuote,
                        quoteStatuses: this.props.quoteStatuses,
                        quoteStatusMap: this.props.quoteStatusMap,
                    }}  
                />

                {/*
                <Board  data={{lanes}}
                        draggable
                    // customCardLayout
                        handleCardDragEnd
                        handleDragEnd={this.handleDragEnd}
                        handleLaneDragEnd={this.handleLaneDragEnd}
                        className="kanban"
                        editable={checkPrivilege("projects", "write")}
                        canAddLanes={checkPrivilege("admin", "admin")}
                        laneDraggable={checkPrivilege("admin", "admin")}
                        hideCardDeleteIcon
                        onDataChange={e => this.handleNewLane(e)}
                        laneChanged={this.laneChanged}
                        t={this.tr}
                        components={components} />

*/}
            </div>
        );
                }
                }


export default withSnackbar(ProjectKanban);
