import React from "react";
import CalendarLoginView from "./CalendarComponents/CalendarLoginView";
import GoogleView from "./CalendarComponents/TaimerCalendarView";
import TaimerCalendarNavbar from "./CalendarComponents/TaimerCalendarNavbar";
import DataHandler from "./../general/DataHandler";
import { SettingsContext } from "./../SettingsContext";

/* css */
import "./TaimerCalendar.css";

/**
 * Main component of TaimerCalendar
 *
 * @class TaimerCalendarLogin
 */
class TaimerCalendarLogin extends React.Component {
	static contextType = SettingsContext;

	constructor(props, context) {
		super(props);
		this.state = {
			mode: "view",
			url: false,
			tab: "google",
			loading: true,
		};
	}

	componentDidMount() {
		this.googleConnect();
		this.outlookConnect();
	}

	/**
	 * Checks google connection (authentication status)
	 * - authenticated -> Shows GoogleView
	 * - not authenticated -> Shows CalendarLoginView
	 */
	googleConnect = () => {
		DataHandler.get({ url: `calendar/google/connect` })
			.done(response => {
				if (response.authenticated) {
					this.setState({
						isGoogleAuthenticated: true,
						loading: false,
					});
				} else {
					this.setState({
						isGoogleAuthenticated: false,
						loading: false,
					});
				}
			})
			.fail(response => {
				this.setState({ loading: false });
			});
	};

	outlookConnect = () => {
		DataHandler.get({ url: `calendar/office/login` })
			.done(response => {
				if (response.url) {
					this.setState({
						isOutlookAuthenticated: false,
						loading: false,
					});
				} else {
					this.setState({
						isOutlookAuthenticated: true,
						loading: false,
					});
				}
			})
			.fail(response => {
				this.setState({ loading: false });
			});
	};


	/**
	 * Logout from google client
	 *
	 */
	googleLogout = () => {
		DataHandler.post({ url: "calendar/google/logout" })
			.done(response => {
				this.changeGoogleAuthStatus();
			})
			.fail(err => {
				this.changeGoogleAuthStatus();
			});
	};

	/**
	 * Logout from google client
	 *
	 */
	 outlookLogout = () => {
		DataHandler.post({ url: "calendar/office/logout" })
			.done(response => {
				this.changeOutlookAuthStatus();
			})
			.fail(err => {
				this.changeOutlookAuthStatus();
			});
	};



	changeGoogleAuthStatus = () => {
		this.setState({ isGoogleAuthenticated: !this.state.isGoogleAuthenticated });
	};

	changeOutlookAuthStatus = () => {
		this.setState({ isOutlookAuthenticated: !this.state.isOutlookAuthenticated });
	};	
	/**
	 * Selects which view will be shown
	 * - View or
	 * - Login
	 *
	 */
	renderView() {
		if (this.state.isGoogleAuthenticated) {
			return <GoogleView changeAuthStatus={() => this.changeGoogleAuthStatus()} setLoading={this.setLoading} loading={this.state.loading} selectedCalendar='google' />;
		} else if (this.state.isOutlookAuthenticated) {
			return <GoogleView changeAuthStatus={() => this.changeOutlookAuthStatus()} setLoading={this.setLoading} loading={this.state.loading} selectedCalendar='outlook'  />;
		} else {
			return (
				<CalendarLoginView
					changeGoogleAuthStatus={() => this.changeGoogleAuthStatus()}
					loading={this.state.loading}
					setLoading={this.setLoading}
				/>
			);
		}
	}

	setLoading = value => {
		this.setState({ loading: value });
	};

	/**
	 * TaimerCalendarLogin renderer
	 *
	 */
	render() {
		const { isGoogleAuthenticated, isOutlookAuthenticated, loading } = this.state;

		return (
			<div id={"contentBlockCalendar"} className={`${this.props.className}`}>
                <React.Fragment>
                    <TaimerCalendarNavbar
                        googleLogout={this.googleLogout}
						outlookLogout={this.outlookLogout}
                        isGoogleAuthenticated={isGoogleAuthenticated}
						isOutlookAuthenticated={isOutlookAuthenticated}
                        loading={loading}
                    />
                    {this.renderView()}
                </React.Fragment>
			</div>
		);
	}
}

export default TaimerCalendarLogin;
