import { Error, FileCopy, Lock, MoreHoriz } from '@mui/icons-material';
import { MenuItem, Tooltip } from '@mui/material';
import { isEqual } from 'lodash';
import { withSnackbar } from 'notistack';
import ContextMenu from '../../general/ContextMenu';
import DataHandler from '../../general/DataHandler';
import AutoCompleteCell from '../cells/AutoCompleteCell';
import CheckboxCell from '../cells/CheckboxCell';
import TextInputCell from '../cells/TextInputCell';
import CurrencyListCell from '../CurrencyListCell';
import LinkListCell from '../LinkListCell';
import ListCell from '../ListCell';
import PropsOnlyListRow from '../PropsOnlyListRow';
import StyledTooltip from '../../general/StyledTooltip';

class MassInvoicingListRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, { addresses: [], isLoading: false }, {}, 'list/rows/MassInvoicingListRow');
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(this.state.addresses, nextState.addresses) || this.state.isLoading !== nextState.isLoading) {
            return true;
        }
        return super.shouldComponentUpdate(nextProps, nextState);
    }

    getAddresses = () => {
        const { data } = this.props;
        this.setState({ isLoading: true }, () => {
            DataHandler.get({ url: `accounts/${data.account_id}/invoicing_addresses/${data.companies_id}` }).done((addresses) => {
                this.setState({
                    isLoading: false,
                    addresses: addresses.map((addressData) => ({
                        ...addressData,
                        name: `${addressData.name}, ${addressData.address}, ${addressData.postalcode} ${addressData.city}, ${addressData.country}`,
                    })),
                });
            });
        });
    };

    onAddressEdited = (value) => {
        const { data, enqueueSnackbar } = this.props;
        const { addresses } = this.state;
        this.cellEdited({ address: value.name, bill_language: value.bill_language, reverse_charge: value.reverse_charge });
        const address = addresses.find((a) => a.id == value.id);
        if (address) {
            DataHandler.put({ url: `projects/${data.id}` }, { invoicing_address: address })
                .done(() => {
                    enqueueSnackbar(this.tr("Project's invoicing address changed successfully!"), { variant: 'success' });
                })
                .fail((err) => {
                    console.error(err);
                    enqueueSnackbar(this.tr("Changing project's invoicing address failed!"), { variant: 'error' });
                });
        }
    };

    goToProject = (e) => {
        const { data } = this.props;
        e.preventDefault && e.preventDefault();
		e.stopPropagation && e.stopPropagation();

        this.context.functions.updateView({ module: "projects", id: data.id, action: "view" });
    }

    renderAddressesTooltip = (addresses) => {
        const { taimerAccount } = this.context;

        const percentFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            useGrouping: false,
            minimumFractionDigits: 0,
            maximumFractionDigits: 4
        }).format;

        return addresses.map(a => {
            const percentage = percentFormatter(a.percentage) + " %" 
            return <p>{a.customer_name + ": " + a.address_label + ", " + percentage}</p>
        });
    }

    renderMultiAddressCell = (data, tooltipContent, text) => {
        return (
            <ListCell onlyDisplay alignCell textAlign={"left"}>
            <div className={`cellValue normal pseudoLink`}>
                <StyledTooltip content={tooltipContent} placement="bottom">
                    <a href={`index.html?module=projects&action=view&id=${data.id}`} onClick={(e) => this.goToProject(e)}>
                        {this.tr("${amount} " + text, {amount: data.invoicing_addresses?.length})}
                    </a>
                </StyledTooltip>                    
            </div>
        </ListCell>
        )
    }

    renderAddressValueTooltip = (values) => {
        return values.map(v => {
            return <p>{v}</p>
        });
    }

    defineCells() {
        const {
            data,
            columnWidthMap,
            rowProps: { onLockRow, onCreateRegularInvoice, currency },
        } = this.props;
        const { addresses, isLoading } = this.state;
        const currencyCells = ['invoiced_total', 'invoice_material', 'scheduled_invoices', 'hours', 'expenses', 'bills', 'quotes'];
        const cells = {};

        let reverseCharges = [data.reverse_charge == 1 ? this.tr('Yes') : this.tr('No')];
        let billLanguages = [(data.bill_language || '').toUpperCase()];
        let eOperators = [data.e_operator];
        let eAddresses = [data.e_address];

        if (data.invoicing_addresses?.length > 0) {
            reverseCharges = [...new Set( data.invoicing_addresses.map(i => i.reverse_charge == 1 ? this.tr('Yes') : this.tr('No')))];
            billLanguages = [...new Set( data.invoicing_addresses.map(i => (i.bill_language || '').toUpperCase()))].filter(x => x);
            eOperators = [...new Set( data.invoicing_addresses.map(i => i.e_operator ? i.e_operator : this.tr("No value")))];
            eAddresses = [...new Set( data.invoicing_addresses.map(i => i.e_address ? i.e_address : this.tr("No value")))];
        }
        
        Object.keys(this.props.columnConfig).forEach((key) => {   
            if (currencyCells.includes(key)) {
                cells[key] = <CurrencyListCell allowNaN={true} showZero={true} value={data[key]} currency={currency} editable={false} />;
            } else {
                if (key == "e_operator" && eOperators.length > 1) {
                    cells[key] = this.renderMultiAddressCell(data, this.renderAddressValueTooltip(eOperators), "values");
                }
                else if (key == "e_address" && eAddresses.length > 1) {
                    cells[key] = this.renderMultiAddressCell(data, this.renderAddressValueTooltip(eAddresses), "values");
                }
                else {
                    cells[key] = <TextInputCell width={columnWidthMap[key]} name={key} editable={false} value={data[key]} />;
                }
            }
        });

        return {
            ...cells,
            menu: (
                <ContextMenu
                    label={<MoreHoriz />}
                    buttonProps={{ className: 'action-menu' }}
                    className="cell row-menu"
                    style={{ width: columnWidthMap['menu'] + 'px', flex: columnWidthMap['menu'] + ' 1 0px' }}
                    noExpandIcon
                >
                    <MenuItem onClick={() => onLockRow(data)}>
                        <Lock title="" />
                        {this.tr('Lock Material and Generate Invoice')}
                    </MenuItem>
                    <MenuItem onClick={() => onCreateRegularInvoice(data)}>
                        <FileCopy title="" />
                        {this.tr('Create Regular Invoice')}
                    </MenuItem>
                </ContextMenu>
            ),
            checked: <CheckboxCell checked={this.props.checked} onClick={() => this.props.listRef.check(data.id)} />,
            account: (
                <LinkListCell
                    urlHandler={(value) => `index.html?module=customers&action=view&id=${data.account_id}`}
                    asText={data.can_see_account == 0}
                    style={{ width: columnWidthMap.account + 'px' }}
                    width={columnWidthMap.account}
                    value={data.account}
                    editable={false}
                    noTab={true}
                />
            ),
            project: (
                <LinkListCell
                    urlHandler={(value) => `index.html?module=projects&action=view&id=${data.id}`}
                    asText={data.can_see_project == 0}
                    style={{ width: columnWidthMap.project + 'px' }}
                    width={columnWidthMap.project}
                    value={data.project}
                    editable={false}
                    noTab={true}
                />
            ),
            reverse_charge: reverseCharges.length > 1 ? 
                this.renderMultiAddressCell(data, this.renderAddressValueTooltip(reverseCharges), "values")
                : <TextInputCell width={columnWidthMap.reverse_charge} name="reverse_charge" editable={false} value={data.reverse_charge == 1 ? this.tr('Yes') : this.tr('No')} />,
            custom_grouping: <TextInputCell width={columnWidthMap.custom_grouping} name="reverse_charge" editable={false} value={data.custom_grouping == 1 ? this.tr('Yes') : this.tr('No')} />,
            bill_language: billLanguages.length > 1 ? 
                this.renderMultiAddressCell(data, this.renderAddressValueTooltip(billLanguages), "values")
                : <TextInputCell width={columnWidthMap.bill_language} name="bill_language" editable={false} value={(data.bill_language || '').toUpperCase()} />,
            has_grouping_settings: (
                <ListCell onlyDisplay width={columnWidthMap.has_grouping_settings}>
                    {!(!Number(data.invoicing_hour_task_grouping || 0) && !Number(data.invoicing_hour_grouping || 0) && !Number(data.invoicing_expense_grouping || 0) && !Number(data.invoicing_quote_grouping || 0)) && (
                        <Tooltip title={this.tr('This project has invoice material grouping settings that will override the settings chosen in this dialog.')}>
                            <Error />
                        </Tooltip>
                    )}
                </ListCell>
            ),
            address: data.invoicing_addresses?.length > 0 ? 
                this.renderMultiAddressCell(data, this.renderAddressesTooltip(data.invoicing_addresses), "addresses selected")
            : (
                <AutoCompleteCell
                    width={columnWidthMap.address}
                    value={data.address}
                    selectProps={{ onFocus: this.getAddresses, isLoading }}
                    autoCompleteData={addresses}
                    showStringValue
                    searchable
                    name="address"
                    onEdited={this.onAddressEdited}
                    editable={true}
                />
            ),
        };
    }
}

export default withSnackbar(MassInvoicingListRow);
