import React from "react";

import { SettingsContext } from "../../../SettingsContext";
import TaimerComponent from "../../../TaimerComponent";

import styles from './ProjectListBlock.module.scss';
import BlockBase from "../BlockBase";
import { BlockProps } from "../../base/CustomViewBase";
import ProjectListBlockRow from "./ProjectListBlockRow";
import List from "../../../list/List";
import colors from "../../../colors";
import InsightDropDown from "../../../dashboard/insights/InsightDropDown";
import { CheckBox } from "@mui/icons-material";
import { ProjectListFilterName } from "../../../projects/ProjectStatistics";

interface Project {
    id: number;

    selected?: boolean;
}

interface Props {
    blockProps: BlockProps;
    selectedProjectFilters: ProjectListFilterName[];
    onSelectProjectListFilter: (filter: ProjectListFilterName) => void;
    onCheckedProjects?: (ids: number[]) => void;
    projects: Project[];
    finance_rights: boolean;
}

interface State {
}

export default class ProjectListBlock extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    columns = [
        { field: "checked", name: "checked", header: "", columnHeaderType: "checkbox", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
        { field: "project_id", name: "project_id", header: this.tr("Nr."), width: 90, resizeable: true, moveable: false },
        { field: "name", name: "name", header: this.tr("Project"), width: 200, showMenu: true, resizeable: true, showResizeMarker: true, moveable: false, hideable: false },
        { field: "customer", name: "customer", header: this.tr("Account"), width: 200 },
        { field: "status", name: "status", header: this.tr("Status"), width: 100 },
        { field: "invoiced", name: "invoiced", header: this.tr("Invoiced"), width: 120, type: "currency", finance_right_needed: true },
        { field: "budgeted_sales", name: "budgeted_sales", header: this.tr("Budgeted sales"), width: 120, type: "currency", finance_right_needed: true },
        { field: "sale_difference", name: "sale_difference", header: this.tr("Difference"), width: 120, type: "currency", finance_right_needed: true },
        { field: "budgeted_costs", name: "budgeted_costs", header: this.tr("Budgeted costs"), width: 120, type: "currency", finance_right_needed: true },
        { field: "actual_costs", name: "actual_costs", header: this.tr("Actual costs"), width: 120, type: "currency", finance_right_needed: true },
        { field: "cost_difference", name: "cost_difference", header: this.tr("Difference"), width: 120, type: "currency", finance_right_needed: true }
    ];

    projectStatuses: { key: ProjectListFilterName, id: string, name: string, label: string, value: string, color: string }[] = [
        { key: "active", id: '-1', name: this.tr('Active'), label: this.tr('Active'), value: '-1', color: colors.greenish_cyan },
        { key: "closed", id: '1', name: this.tr('Closed'), label: this.tr('Closed'), value: '1', color: "#f52b2b" },
        { key: "onHold", id: '2', name: this.tr('On hold'), label: this.tr('On hold'), value: '2', color: "#ffaf0f" }
    ];

    constructor(props, context) {
        super(props, context, "customview/blocks/ProjectList/ProjectListBlock");

        this.state = {
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: Props) {
        const { projects } = this.props;

        if (projects !== prevProps.projects) {

        }
    }

    getProjectFilterTitle = () => {
        const { selectedProjectFilters } = this.props;

        if (selectedProjectFilters.length === 0 || selectedProjectFilters.length === this.projectStatuses.length) {
            return this.tr("All");
        }

        return selectedProjectFilters.map(x => this.projectStatuses.find(e => e.key === x)?.label).filter(x => !!x).join(', ');
    }

    renderToolbar = () => {
        const { onSelectProjectListFilter } = this.props;

        return (<>
            <InsightDropDown
                title={this.tr("Project status")}
                titleLabel={this.getProjectFilterTitle()}
                color="#e3f4ff"
                titleColor="#2d9ff7"
                tabs={this.projectStatuses.map(x => ({...x, action: () => {}}))}
                customComponent={(this.projectStatuses || []).map((o) => {
                    return (
                        <div
                            key={o.key}
                            onClick={(e) => onSelectProjectListFilter(o.key)}
                            className="column-options-dropdown-item">
                            <div className="check-box">
                                {(this.props.selectedProjectFilters.includes(o.key)) && (
                                    <CheckBox className="icon" />
                                )}
                            </div>
                            {o.name}
                        </div>
                    );
                })}
            />
        </>)
    }

    onCheck = (checked: string[]|boolean) => {
        const { projects, onCheckedProjects } = this.props;

        if (Array.isArray(checked)) {
            const ids = checked.map(x => Number(x));

            onCheckedProjects?.(ids);
        } else {
            const ids = checked ? projects.map(x => x.id) : [];

            onCheckedProjects?.(ids);
        }
    }

    getSubtitle(selected: number, total: number) {
        if (selected === 0)
            return this.tr('No projects selected');

        if (selected === 1 && total === 1)
            return this.tr('${number} project', {number: total}); 

        if (selected === total)
            return this.tr('${number} projects', {number: total});

        return this.tr('${number} of ${max} projects', {number: selected, max: total});

    }

    render() {
        const { blockProps, projects, finance_rights } = this.props;
        const { } = this.state;

        const selected = projects.filter(x => x.selected);
        const allSelected = selected.length === projects.length;

        return (
            <BlockBase
                {...blockProps} 
                subtitle={this.getSubtitle(selected.length, projects.length)}
                subtitleOnlyWhenCollapsed
                topbarComponent={this.renderToolbar()}>
                <List
                    // ref={this.subprojectsList}
                    className="projects-list"
                    allChecked
                    // fluid
                    data={projects}
                    checkedRows={projects.filter(x => x.selected).map(x => x.id)}
                    columns={finance_rights ? this.columns : this.columns.filter(x => !x.finance_right_needed)}
                    trimHeight={20}
                    height="auto"
                    parentKey="parentId"
                    listRowType={ProjectListBlockRow}
                    treeData={true}
                    parentsExpandedOnInit={true}
                    noStateData={true}
                    onCheckAll={() => this.onCheck(true)}
                    onUncheckAll={() => this.onCheck(false)}
                    rowProps={{
                        onCheck: (x) => this.onCheck(x),
                        // showWholeTrees: this.state.showWholeTrees,
                        // showTreeStructures: this.state.showTreeStructures,
                        statuses: this.projectStatuses,
                        // currency: currency
                    }}
                />
            </BlockBase>
        );
    }
}  