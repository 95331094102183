import React from "react";
import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from "./../SettingsContext";
import cn from "classnames";
import { Portal } from "@mui/base";
import CircularProgress from '@mui/material/CircularProgress';
import "./Dialog.css";
import "./DialogGrid.css";

export default class DialogBorder extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
        onClose: () => { },
        isSaving: false,
        onRendered: () => {},
        hideCancel: false,
        extendedClose: false,
        isSaving: false,
    }

	constructor(props, context) {
        super(props, context, "dialogs/DialogBorder");
	}

	close = (source) => {
        const { extendedClose, onClose } = this.props;

        if (extendedClose) {
            onClose(source);
        } else {
            onClose();
        }
	};

	back = () => {
		this.props.onBack();
	};

	save = () => {
		this.props.onSave();
	};

	keyup = e => {
		if (e.key === "Escape") this.close("esc");
	};

	componentDidMount() {
        super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
    }

	render() {
		const { icon, title, subtitle, children, saveText, backText, dialogRef, editText, noTopBar, noCloseButton, customButton, closeOnBGClick, focusToDialog, isSaving } = this.props;
        
        const actualSaveText = (saveText || ((this.props.textOk && (this.props.className == "DeleteContactDialog" )) ? this.tr("Delete") : this.tr("Save")));

        return (
            <Portal onRendered={this.props.onRendered}>
                <div onClick={closeOnBGClick && (() => this.close("close_bg"))} className={cn("TMRDialogBackground", this.props.classNameBg)}></div>
                <div ref={dialogRef} className={cn("TMRDialogArea")} onKeyDown={this.keyup}>
                    <div id={this.props.id} data-testid={this.props['data-testid']} className={cn("TMRDialog", this.props.className)}>
                        {!noTopBar && <div className="topbar" />}

                        <div className="header">
                            <div className="title">
                                {icon && <img src={icon} alt="" />}
                                {title}
                            </div>
                            {this.props.subtitle &&
                                <div className="subtitle">
                                    {subtitle}
                                </div>
                            }
                            <div className="TMRDialogTopButtons">
                                {customButton}
                                {!noCloseButton && (focusToDialog ? <button className="close" onClick={() => this.close("close")} autoFocus>&times;</button>:<button className="close" onClick={this.close}>&times;</button>)}
                            </div>
                        </div>

                        {children}

                        <div className="TMRDialogButtons">
                            <div className="left">
                                {!this.props.hideCancel && <button onClick={() => this.close("cancel")}>{this.tr("Cancel")}</button>}
                                {this.props.leftButtons}
                            </div>
                            <div className="right">
                                {this.props.rightButtons}
                                {this.props.onBack && <div className={"TMRDialogButton text"} onClick={this.back}>{backText || this.tr("Back")}</div>}
                                {/*this.props.onSave && <div className={"TMRDialogButton blue" + (this.props.disableSave ? ' disabled' : '')} onClick={!this.props.disableSave ? this.save : () => {}}>{saveText || this.tr("Save")}</div>*/}
                                {this.props.onSave && <div 
                                    data-testid="save-button" 
                                    className={"TMRDialogButton blue" + (this.props.disableSave ? ' disabled' : '')}
                                    onClick={!this.props.disableSave ? this.save : () => {}}>
                                        {!isSaving && actualSaveText}
                                        {isSaving && <div style={{minWidth: actualSaveText.length + 'ch'}}>
                                            <CircularProgress disableShrink size={18} className={`TMRDialogProgress`} />
                                        </div>}
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </Portal>)
    }
}
