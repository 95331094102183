/* css */
import './TabQuotes.css';

/* material-ui */
import Switch from '@mui/material/Switch';
import ThreeDotsIcon from '@mui/icons-material/MoreHoriz';
import { Tooltip, Button, Tabs, Tab, MenuItem }  from '@mui/material';


import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';

/* others */
import React from 'react';
import Quote from './Quote.old';
import ReactDOM from 'react-dom';
import FileSaver from 'file-saver';
import PropTypes from "prop-types";
import { format } from "date-fns";
import _, { isEqual } from 'lodash';
import cloneDeep from "lodash/cloneDeep";
import { getWorktypesForProject } from '../Data';

import DataHandler from '../general/DataHandler';
import ContextMenu from '../general/ContextMenu';
import OutlinedField from "../general/OutlinedField";
import CostTargeting from './CostTargeting';
import BillTargeting from './BillTargeting';
import ResourceDialog from "../dialogs/ResourceDialog";
import TaimerComponent from "../TaimerComponent";
import { withSnackbar } from 'notistack';
import { SettingsContext } from '../SettingsContext';
import ConfirmationDialog from "../settings/dialogs/ConfirmationDialog";
import { CopyQuoteDialog, CopySimpleQuoteDialog } from "../dialogs/CopyQuoteDialog";
import { validEmail } from '../dialogs/Validate';
import LoaderButton from "../general/LoaderButton";

import { ReactComponent as CopyIcon } from './../general/icons/copy.svg';
import { ReactComponent as DeleteIcon } from './../general/icons/remove.svg';
import { ReactComponent as SearchIcon } from './../general/icons/search.svg';
import { ReactComponent as NewIcon } from './images/new_quote.svg';
import { ReactComponent as NewInvoice } from './images/create_invoice.svg';
import { Print as PrintIcon } from "@mui/icons-material";

import { makeMap } from "../list/ListUtils";
import ProposalEditor from './proposal/ProposalEditor';
import PageTopSection from '../general/PageTopSection';

const QUOTE_MIN_ROWS_EDITMODE_NOTIFICATION = 15;

function arrangeQuoteRowsUnderHeaders(data) {
    const isHeader  = r => !r.parentId || r.parentId === "0";
    const headers   = data.filter(r => isHeader(r));
    const rows      = data.filter(r => !isHeader(r));
    const hOrder    = headers.map(h => h.id);
    const headerMap = makeMap(headers.map(r => {
        r.rows = []; 

        return r; 
    }), "id");

    rows.forEach(r => {
        headerMap[Number(r.parentId)].rows.push(r) 
    });

    return hOrder.map(id => headerMap[id]);
}

class TabQuotes extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "projects/TabQuotes");
        // const { privileges } = this.context;

        this.state = {
            rightColumnData: undefined,
            projectId: this.props.id,
            quotes: [],
            project: {},
            products: [],
            deletedProducts: [],
            selectedQuoteId: this.props.selectedQuoteId || -1,
            // selectedTab: 0,
            editMode: false,
            selectedTab: "costTargeting",
            companyCurrency: "EUR",
            currentDialog: false,
            dialogData: undefined,
            scale: 1,
            offset: 0,
            resourcingAutoCompleteData: false,
            renderedOnce: false,
            emailValidations: {},
            saving: false,
            jobtypes: [],
            dataLoaded: false,
            renderPrintQuote: false,
            renderProposal: false,
            printQuoteId: -1,
            printLanguage: "en",
            printDateFormat: this.convertDateFormat(this.context.taimerAccount.companyDateFormat, true),
            printLanguageOptions: [],
            printDateOptions: [],
            visitingAddresses: [],
            defaultVAT: undefined
        }

        /*this.columns = [
            {field: "context", name: "context", header: "", width: 25, showMenu: false, resizeable: false, moveable: false, hideable: false},
            {field: "createTask", name: "createTask", header: "", width: 25, showMenu: false, resizeable: false, moveable: false, hideable: false},
            {field: "move", name: "move", header: "", width: 25, showMenu: false, resizeable: false, moveable: false, hideable: false},
            {field: "name", name: "name", header: this.tr("Rows"), width: 75, showMenu: false, resizeable: false, moveable: false, hideable: false},
            {field: "quantity", name: "quantity", header: this.tr("Unit"), width: 20, showMenu: false, resizeable: false, moveable: false, hideable: false},
            {field: "quantityType", name: "quantityType", header: "", width: 25, showMenu: false, resizeable: false, moveable: false, hideable: false},
            {field: "cost", name: "cost", header: this.tr("Unit cost"), width: 25, showMenu: false,resizeable: false,  moveable: false, hideable: false},
            {field: "value", name: "value", header: this.tr("Selling price"), width: 25, showMenu: false, resizeable: false, moveable: false, hideable: false},
            {field: "vat", name: "vat", header: this.tr("VAT %"), width: 20, showMenu: false, resizeable: false, moveable: false, hideable: false},
            {field: "vatTotal", name: "vatTotal", header: this.tr("VAT total"), width: 25, showMenu: false, resizeable: false, moveable: false, hideable: false},
            {field: "discountPercentage", header: this.tr("Discount %"), name: "discountPercentage", width: 20, showMenu: false, resizeable: false, moveable: false, hideable: false},
            {field: "sum", name: "sum", header: this.tr("Sum 0%"), width: 30, showMenu: false, resizeable: false, moveable: false, hideable: false},
            {field: "margin", name: "margin", header: this.tr("Margin 0%"), width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false},
            {field: "workType", name: "workType", header: this.tr("Type of work"), width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false},
        ];*/

        this.columns = [
            { field: "foobarOne", name: "foobarOne", header: "Foobar?", width: 200, showMenu: false, resizeable: false, moveable: false, hideable: false },
            { field: "foobarTwo", name: "foobarTwo", header: "Foobar!", width: 300, showMenu: false, resizeable: false, moveable: false, hideable: false },
        ];

        if(!props.checkPrivilege("projects", "project_resourcing_read") && !props.checkPrivilege("projects", "own_resourcing_read")) {
            this.columns = this.columns.filter(el => el.field != 'createTask');
        }

        this.dialogs = {
            confirmation: ConfirmationDialog,
            resourcing: ResourceDialog,
            copySimple: CopySimpleQuoteDialog,
            copyQuote: CopyQuoteDialog,
            cancel: ConfirmationDialog
        };

        this.addedBillingAddressIds  = [];
        this.addedVisitingAddressIds = [];
        this.cachedRightColumnData = null;

        this.container     = React.createRef();
        this.quote         = React.createRef();
        this.printquote    = React.createRef();
        this.costTargeting = React.createRef();
        this.billTargeting = React.createRef();

        this.getBillingAddresses  = this.getBillingAddresses.bind(this);
        this.getVisitingAddresses  = this.getVisitingAddresses.bind(this);
        this.quoteListDataChanged = this.quoteListDataChanged.bind(this);
        this.uneditedQuotes = undefined;
    }

    convertDateFormat(format, reverse = false) {
        if (!format) return undefined;

        if (reverse) {
            return format.replace('DD', '%d')
            .replace('MM', '%m')
            .replace('YYYY', '%Y')
        }

        return format.replace('%d', 'DD')
            .replace('%m', 'MM')
            .replace('%Y', 'YYYY')
    }

    componentDidMount = () => {
        super.componentDidMount();

        this.getData();
        this.getDefaultQuoteVAT();

        this.setState({ renderedOnce: true });
    }

    componentDidUpdate = (oldProps, oldState) => {
        if ((!this.state.editMode || this.state.saving) && oldState.quotes != this.state.quotes && !isEqual(this.uneditedQuotes, this.state.quotes)) {
            this.uneditedQuotes = cloneDeep(this.state.quotes);
        }
        if (!oldState.editMode && this.state.editMode) {
            //update products and cpq when entering edit mode just in case user adds products/cpq on another tab
            this.updateProducts();
        }
    }

    updateProducts = async () => {
        const { project: { companies_id  } } = this.props;
        let products         = await DataHandler.get({ url: `/products/array/${companies_id}`, include_deleted: 1, account: this.state.project.customers_id});
        let CPQParents       = await DataHandler.get({ url: `/cpq/parents/${companies_id}`});
        let allProductNames  = await DataHandler.get({ url: `/products/array/${companies_id}`, include_deleted: 1, allProductNames: true});

        this.setState({
            products: products.products,
            allProductNames: allProductNames.products,
            CPQParents: CPQParents.CPQParents,
            refreshingData: false
        });
    }

   
    getDefaultPrintOptions = (company) => {
        let options = {};
        options.printDateFormat = this.convertDateFormat(this.context.taimerAccount.companyDateFormat, true);
        if (company) {
            options.printLanguage = company.print_lang ? company.print_lang : company.country_lang;
            options.printLanguageOptions = company.print_languages;
            options.printDateFormat = company.date_format || this.convertDateFormat(this.context.taimerAccount.companyDateFormat, true);
            options.printDateOptions = company.print_date_formats;
        } 
        else {
            options.printLanguage = this.state.printLanguage;
            options.printLanguageOptions = this.state.printLanguageOptions;
            options.printDateOptions = this.state.printDateOptions;
        }

        return options;
    }

    getData = async () => {
        this.setState({ refreshingData: true }, async () => {
            const { projectId } = this.state;
            const { checkPrivilege, project: { companies_id  } } = this.props;
    
            let project          = await DataHandler.request("GET", {url: "projects/" + projectId});
            let billingAddresses = await DataHandler.request("GET", {url: "subjects/billing_addresses/" + project.customers_id});
            let visitingAddresses = await DataHandler.get({url: `accounts/${project.account.id}/visiting_addresses/${project.companies_id}`});
            let quotes           = await DataHandler.request("GET", {url: "projects/" + projectId + "/quotes"});
            let companyAddress   = await DataHandler.get({ url: `/settings/company/${companies_id}/address`});
            let products         = await DataHandler.get({ url: `/products/array/${companies_id}`, include_deleted: 1, account: project.customers_id});
            let allProductNames  = await DataHandler.get({ url: `/products/array/${companies_id}`, include_deleted: 1, allProductNames: true});
            let CPQParents       = await DataHandler.get({ url: `/cpq/parents/${companies_id}`});
            let units            = await DataHandler.get({ url: '/settings/company/product/units'});
            let customerContacts = await DataHandler.request("GET", {url: "subjects/contacts/" + project.customers_id});
            const companies      = await DataHandler.get({ url: `subjects/companies_with_project_right/read+project_cost_estimate_read`, currency: 1, date_format: 1, print_lang: 1, country_lang: 1, print_options: 1 });
            const allUsers       = await DataHandler.request("GET", {url: `subjects/employees`, frTransl: this.tr('Freelancer')});
            const defaultPrintOptions = this.getDefaultPrintOptions(companies.find(c => c.id == companies_id));

            let companyContacts  = [];
            let onlyCompanyEmployees = false;
            if (this.props.project.limited_project_team == 1)
                onlyCompanyEmployees = true;
    
            companyContacts = await DataHandler.request("GET", {url: `subjects/employees/projects/project_cost_estimate_write/${companies_id}`, frTransl: this.tr('Freelancer'), dontIgnoreCompany: onlyCompanyEmployees, showAuthUserFromWrongCompany: true });
    
            let c = false;
            companies.forEach(company => {
                if (company.id == companies_id) {
                    this.setState({ companyCurrency: company.currency });
                    c = true;
                }
            })
            if (!c && companies && companies.length > 0) {
                this.setState({ companyCurrency: companies[0].currency });
            }
    
            // let deletedProducts = [];
            // quotes.forEach(function(quote) {
            // 
            // quote.headers.forEach(function(header) {
            // 
            // header.rows.forEach(function(row) {
            // if(!products.products.some(x => x.id == row.product_id)) {
            // if(!deletedProducts.some(x => x == row.product_id)) {
            // deletedProducts.push(row.product_id);
            // }
            // }
            // });
            // });
            // });
    
            // deletedProducts = await DataHandler.get({url: 'products/product', deletedProducts: deletedProducts});
    
            billingAddresses = billingAddresses.map(a => {a.label = a.address; a.value = a.id; return a;});
            customerContacts = customerContacts.map(a => {a.value = a.id; return a;});
            companyContacts  = companyContacts.map(a => {a.value = a.id; return a;});
            visitingAddresses = visitingAddresses.map(a => {a.label = a.address; a.value = a.id; return a;});

            let defaultVisitingAddress = visitingAddresses.find(obj => {return obj.is_customer_default == 1});
            if (!defaultVisitingAddress) {
                defaultVisitingAddress = visitingAddresses.length > 0 ? visitingAddresses[0] : undefined;
            }
            
            let receiverContacts = [...customerContacts] || [];
    
            if (this.props.project.contacts) {
                let projectContacts = [...this.props.project.contacts];
                projectContacts.forEach(contact => {
                    contact.id = String(contact.id);
                });
                receiverContacts = _.uniqBy(customerContacts.concat(projectContacts), 'id');
            }
         
            receiverContacts = _.sortBy(receiverContacts, function (r) {
                return r.label.toLowerCase();
            });
    
            this.setState({
                selectedQuoteId: (this.state.selectedQuoteId > 0) ? this.state.selectedQuoteId : quotes.length > 0 ? quotes[0].id : -1,
                project: project,
                billingAddresses: billingAddresses,
                visitingAddresses: visitingAddresses,
                companyContacts: companyContacts,
                allUsers: allUsers,
                receiverContacts: receiverContacts,
                quotes: quotes,
                companyAddress: companyAddress,
                defaultBillingAddressId: project.customer_address_id > 0 ? project.customer_address_id : undefined,
                defaultVisitingAddressId: defaultVisitingAddress ? defaultVisitingAddress.id : undefined,
                editMode: false,
                products: products.products,
                allProductNames: allProductNames.products,
                CPQParents: CPQParents.CPQParents,
                units: units.units,
                dataLoaded: true,
                refreshingData: false,
                printLanguage: defaultPrintOptions.printLanguage,
                printDateFormat: defaultPrintOptions.printDateFormat,
                printLanguageOptions: defaultPrintOptions.printLanguageOptions,
                printDateOptions: defaultPrintOptions.printDateOptions
                // deletedProducts: deletedProducts.product
            });
    
            this.getJobtypes(project.id);
        });
    }

    getJobtypes = async (projectId) => {
        const jobtypes = await getWorktypesForProject(projectId);
        this.setState({
            jobtypes: jobtypes,
        });
    }

    selectQuote = (id) => {
        const { quotes } = this.state;
        let foundQuote = null;

        if (id == -1) {
            foundQuote = quotes.find(c => c.active == 1);

            if (!foundQuote)
                foundQuote = quotes[0];
        }
        else
            foundQuote = quotes.find(c => c.id == id);

        if (foundQuote)
            return foundQuote;
        else
            return false;
    }


    activeChanged = (quote, checked) => {
        const quotes = cloneDeep(this.state.quotes);
        const index  = quotes.findIndex(c => c.id == quote.id)

        quotes[index].active = checked;

        DataHandler
            .request("POST", { url: "projects/quotes/" + quote.id + "/status" }, { active: checked })
            .done();

        this.setState({ quotes });
    }


    switchTabs = (e, value) => {
		this.setState({ 
            selectedTab: value 
        }, () => {
            if(value === "costTargeting" || value === "billTargeting") {
                if(this.state.selectedQuoteId === this.cachedRightColumnData.quoteId && this.cachedRightColumnData !== null) {
                    this[value].current.setData({ 
                        headers: this.cachedRightColumnData.data
                    });
                } else {
                    this.cachedRightColumnData = {
                        quoteId: this.state.selectedQuoteId,
                        data: arrangeQuoteRowsUnderHeaders(cloneDeep(this.quote.current.getQuoteData())) 
                    };

                    this[value].current.setData({ 
                        headers: this.cachedRightColumnData.data
                    });
                }
            }
        });
	}


    headerNameChanged = (quoteId, headerId, value) => {
        const { quotes } = this.state;
        const quoteIndex = quotes.findIndex(c => c.id == quoteId);
        const headerIndex = quotes[quoteIndex]['headers'].findIndex(h => h.id == headerId);
        quotes[quoteIndex]['headers'][headerIndex]['name'] = value;
        this.setState({quotes});
    }


    CPQGroupChanged = (quoteId, headerId, value) => {

        const { quotes } = this.state;
        const { project: { companies_id  } } = this.props;
        const quoteIndex = quotes.findIndex(c => c.id == quoteId);
        const headerIndex = quotes[quoteIndex]['headers'].findIndex(h => h.id == headerId);
        quotes[quoteIndex]['headers'][headerIndex]['name'] = value.name;
        quotes[quoteIndex]['headers'][headerIndex]['type'] = 0;
        
        let parameters = { parentId: value.id, company: companies_id };
        DataHandler.get({ url: `cpq/childrens`, ...parameters}).done(response => {

            response.cpqs.forEach(cpq => {

                let ctype = 1;
                let cproduct_id = 0;
                let work_type = 0;

                //Product
                if(cpq.type == 2) {
                    ctype = 3;
                    cproduct_id = cpq.type_id;
                    work_type = cpq.worktype;
                }

                //Description
                if(cpq.type == 3) ctype = 2;

                quotes[quoteIndex]['headers'][headerIndex]['rows'].push({
                    cost: parseFloat(cpq.unit_cost),
                    quoteId: quoteId,
                    deleted: 0,
                    id: quotes[quoteIndex]['headers'][headerIndex]['rows'].length * -1 - 1,
                    name: cpq.description,
                    parentId: value.id,
                    quantity: cpq.quantity,
                    type: ctype,
                    value: parseFloat(cpq.selling_price),
                    vat: cpq.vat,
                    workType: work_type,
                    quantityType: cpq.unit,
                    discountPercentage: 0,
                    product_id: cproduct_id,
                    has_automatic_targeting: 0,
                });

            });

            this.setState({quotes});

        }).fail(response => {});
    }

    rowDataChanged = (name, value, quoteId, headerId, rowId) => {

        return; // TODO
    
        const { quotes } = this.state;

        const numberValueFields = ['cost', 'value', 'vat', 'quantity', 'discountPercent'];
        if (numberValueFields.indexOf(name) > -1 && typeof value == "string") {
            value = value.replace(',', '.');
        }

        const quoteIndex = quotes.findIndex(c => c.id == quoteId);
        const headerIndex = quotes[quoteIndex]['headers'].findIndex(h => h.id == headerId);
        const rowIndex = quotes[quoteIndex]['headers'][headerIndex]['rows'].findIndex(r => r.id == rowId);

        if(name == "product") {

            const { editMode } = this.state;

            if (!editMode) return;

            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['cost'] = value.cost_price;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['quoteId'] = quoteId;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['deleted'] = 0;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['name'] = value.name;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['parentId'] = headerId;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['quantity'] = 1;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['type'] = 3;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['value'] = value.income_price;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['vat'] = value.vat;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['workType'] = 4;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['quantityType'] = value.unit;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['discountPercentage'] = value.discount_percent;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['product_id'] = value.id;
            quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['has_automatic_targeting'] = 0

            this.setState({quotes});

        } else if (name == "cpqSingleRow") {

            let parameters = { parentId: value.id };

            DataHandler.get({ url: `cpq/parent`, ...parameters}).done(response => {

                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['cost'] = response.CPQ.cost_price;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['quoteId'] = quoteId;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['deleted'] = 0;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['name'] = value.name;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['parentId'] = headerId;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['quantity'] = 1;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['type'] = 4;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['value'] = response.CPQ.income_price;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['vat'] = 0;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['workType'] = 0;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['quantityType'] = 1;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['discountPercentage'] = 0;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['product_id'] = value.id;
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['has_automatic_targeting'] = 0

                this.setState({quotes})

            }).fail(response => {});

        } else {

            const rowIndex = quotes[quoteIndex]['headers'][headerIndex]['rows'].findIndex(r => r.id == rowId);
            const oldValue = quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex][name];

            if (oldValue != value) {
                quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex][name] = value;

                if (name == "quantityType" && value == 2) {
                    quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex]['workType'] = 1
                }

                this.setState({quotes});
            }
        } 
    }

    //Adds normal item row to under quotes header row
    addRow = (quoteId, headerId) => {
        const { quotes, editMode } = this.state;

        if (!editMode)
            return;

        const quoteIndex = quotes.findIndex(c => c.id == quoteId);
        const headerIndex = quotes[quoteIndex]['headers'].findIndex(h => h.id == headerId);

        quotes[quoteIndex]['headers'][headerIndex]['rows'].push({
            cost: 0,
            quoteId: quoteId,
            deleted: 0,
            id: quotes[quoteIndex]['headers'][headerIndex]['rows'].length * -1 - 1,
            name: "",
            parentId: headerId,
            quantity: 0,
            type: 1,
            value: 0,
            vat: 0,
            workType: 0,
            quantityType: 1,
            discountPercentage: 0,
            has_automatic_targeting: 0,
            product_id: 0
        })

        this.setState({quotes});
    }

    //Adds product row to under quotes header row
    addProductRow = (quoteId, headerId) => {
        const { quotes, editMode } = this.state;

        if (!editMode)
            return;

        const quoteIndex = quotes.findIndex(c => c.id == quoteId);
        const headerIndex = quotes[quoteIndex]['headers'].findIndex(h => h.id == headerId);

        quotes[quoteIndex]['headers'][headerIndex]['rows'].push({
            cost: 0,
            quoteId: quoteId,
            deleted: 0,
            id: quotes[quoteIndex]['headers'][headerIndex]['rows'].length * -1 - 1,
            name: "",
            parentId: headerId,
            quantity: 0,
            type: 3,
            value: 0,
            vat: 0,
            workType: 4,
            quantityType: 1,
            discountPercentage: 0,
            has_automatic_targeting: 0,
            product_id: 0
        })

        this.setState({quotes});
    }

    //Adds description row to under quotes header row
    addDescriptionRow = (quoteId, headerId) => {
        const { quotes, editMode } = this.state;

        if (!editMode)
            return;

        const quoteIndex = quotes.findIndex(c => c.id == quoteId);
        const headerIndex = quotes[quoteIndex]['headers'].findIndex(h => h.id == headerId);

        quotes[quoteIndex]['headers'][headerIndex]['rows'].push({
            cost: 0,
            quoteId: quoteId,
            deleted: 0,
            id: quotes[quoteIndex]['headers'][headerIndex]['rows'].length * -1 - 1,
            name: "",
            parentId: headerId,
            quantity: 0,
            type: 2,
            value: 0,
            vat: 0,
            workType: 0,
            product_id: 0
        })

        this.setState({quotes});
    }

    //Adds Topic header row to quote
    addHeaderRow = (quoteId) => {
        const { quotes, editMode } = this.state;

        if (!editMode && quoteId > 0)
            return;

        const quoteIndex = quotes.findIndex(c => c.id == quoteId);

        quotes[quoteIndex]['headers'].push({
            cost: 0,
            quoteId: quoteId,
            deleted: 0,
            id: quotes[quoteIndex]['headers'].length * -1 - 1,
            name: "",
            parentId: 0,
            quantity: 0,
            rows: [],
            type: 0,
            value: 0,
            vat: 0,
            workType: 0,
            product_id: 0
        })

        this.setState({quotes});
    }

    //Adds single CPQ row to under quotes header row
    addCPQRow = (quoteId, headerId) => {
        const { quotes, editMode } = this.state;

        if (!editMode)
            return;

        const quoteIndex = quotes.findIndex(c => c.id == quoteId);
        const headerIndex = quotes[quoteIndex]['headers'].findIndex(h => h.id == headerId);

        quotes[quoteIndex]['headers'][headerIndex]['rows'].push({
            cost: 0,
            quoteId: quoteId,
            deleted: 0,
            id: quotes[quoteIndex]['headers'][headerIndex]['rows'].length * -1 - 1,
            name: "",
            parentId: headerId,
            quantity: 0,
            type: 4,
            value: 0,
            vat: 0,
            workType: 0,
            quantityType: 1,
            discountPercentage: 0,
            has_automatic_targeting: 0,
            product_id: 0
        })

        this.setState({quotes});
    }

    //Adds CPQ Group header row to quote
    addCPQGroup = (quoteId) => {
        const { quotes, editMode } = this.state;

        if (!editMode && quoteId > 0)
            return;

        const quoteIndex = quotes.findIndex(c => c.id == quoteId);

        quotes[quoteIndex]['headers'].push({
            cost: 0,
            quoteId: quoteId,
            deleted: 0,
            id: quotes[quoteIndex]['headers'].length * -1 - 1,
            name: "",
            parentId: 0,
            quantity: 0,
            rows: [],
            type: 5,
            value: 0,
            vat: 0,
            workType: 0,
            product_id: 0
        })

        this.setState({quotes});
    }

    deleteRow = (quoteId, headerId, rowId) => {
        const { quotes, editMode } = this.state;

        if (!editMode)
            return;

        const quoteIndex = quotes.findIndex(c => c.id == quoteId);
        const headerIndex = quotes[quoteIndex]['headers'].findIndex(h => h.id == headerId);
        const rowIndex = quotes[quoteIndex]['headers'][headerIndex]['rows'].findIndex(r => r.id == rowId);
        quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex].deleted = 1;
        this.setState({quotes});
    }

    deleteHeaderRow = (quoteId, headerId) => {
        const { quotes, editMode } = this.state;

        if (!editMode)
            return;

        const quoteIndex = quotes.findIndex(c => c.id == quoteId);
        const headerIndex = quotes[quoteIndex]['headers'].findIndex(h => h.id == headerId);
        quotes[quoteIndex]['headers'][headerIndex].deleted = 1;
        this.setState({quotes});
    }

    addNewQuote = (type = 1) => {
        const { quotes, project } = this.state;
        const { userObject }      = this.context;
        const id                  = quotes.length * -1 - 1;
        const defaultUser         = this.state.companyContacts.find(c => c.id == this.context.userObject.usersId);

        this.context.functions.setDirty(true, false, {
            title: this.tr('Sales Quote is not saved!'),
            text: this.tr('Are you sure you want to leave? All unsaved changes will be lost.'),
        });

        let addressId = this.state.defaultVisitingAddressId ? this.state.defaultVisitingAddressId : this.state.visitingAddresses.length > 0 ? this.state.visitingAddresses[0].id : undefined;
        let addressData = this.state.visitingAddresses.find(obj => {return obj.id == addressId});

        quotes.push({
            active: quotes.length == 0 ? 1 : 0,
            created: format(new Date(), "YYYY-MM-DD"),
            deleted: 0,
            headers: [],
            id: id,
            name: this.tr("New quote"),
            projectId: project.id,
            userId: userObject.usersId,
            address_id: addressId,
            receiver_contact_id: this.state.receiverContacts.length > 0 ? this.state.receiverContacts[0].id : undefined,
            sender_contact_id: userObject.usersId,
            editedAddress: {
                address: addressData?.address,
                name: addressData?.name,
                postalcode: addressData?.postalcode,
                city: addressData?.city,
                state: addressData?.state,
                country: addressData?.country,
                vatid: addressData?.vatid,
                phone: this.state.receiverContacts.length > 0 ? this.state.receiverContacts[0].phone : undefined,
                email: this.state.receiverContacts.length > 0 ? this.state.receiverContacts[0].email : undefined
            },
            company_phone: defaultUser?.phone,
            company_email: defaultUser?.email,
            validEmail: true,
            validCompanyEmail: true,
            print_exceptions: ['cost', 'discountPercentage', 'margin'],
            type,
        });

        this.setState({ quotes: quotes, selectedQuoteId: id, editMode: true });
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    }

    deleteQuote = async (id) => {
        if(id > 0) {
            await DataHandler.request("DELETE", {url: "projects/quotes/" + id});
        }

        const { quotes } = this.state;
        const quoteIndex = quotes.findIndex(c => c.id == id);

        quotes.splice(quoteIndex, 1);
        this.context.functions.setDirty(false);

        if(quotes.length == 0) {
            this.setState({ quotes, selectedQuoteId: undefined });
        } else {
            this.setState({
                quotes,
                editMode: false,
                selectedQuoteId: quotes[quotes.length - 1].id
            }); 
        }
    }

    deleteQuoteConfirmation = (id) => {
        this.setState({
            dialogData: {
                id: id,
                saveFunc: () => this.deleteQuote(id),
                text: this.tr("Do you want to delete this quote?")
            }
        });

        this.openDialog('confirmation');
    }

    quoteDataChanged = (id, key, value) => {
        const { quotes, receiverContacts, companyContacts } = this.state;

        const quoteIndex = quotes.findIndex(c => c.id == id);

        if (key == "sent_date" || key == "valid_until")
            value = format(value, "YYYY-MM-DD")

        quotes[quoteIndex][key] = value;

        if (key == 'address_id') {
            quotes[quoteIndex]['editedAddress']['name'] = undefined;
            quotes[quoteIndex]['editedAddress']['postalcode'] = undefined;
            quotes[quoteIndex]['editedAddress']['city'] = undefined;
            quotes[quoteIndex]['editedAddress']['state'] = undefined;
            quotes[quoteIndex]['editedAddress']['country'] = undefined;
            quotes[quoteIndex]['editedAddress']['vatid'] = undefined;
            quotes[quoteIndex]['editedAddress']['address'] = undefined;
            quotes[quoteIndex]['validEmail'] = true;
        }
            
        if (key == 'receiver_contact_id') {
            const contact = receiverContacts.find(contact => contact.id === value)
            if (!quotes[quoteIndex]['editedAddress']) {
                quotes[quoteIndex]['editedAddress'] = []
                quotes[quoteIndex]['validEmail'] = true;
            }
            if (contact) {
                quotes[quoteIndex]['editedAddress']['email'] = contact.email || "";
                quotes[quoteIndex]['editedAddress']['phone'] = contact.phone || "";
    
                let isValidEmail = validEmail(contact.email, true);
                quotes[quoteIndex]['validEmail'] = isValidEmail;
            }
        }

        if (key == 'sender_contact_id') {
            const contact = companyContacts.find(contact => contact.id === value);
            quotes[quoteIndex]['company_email'] = contact?.email || "";
            quotes[quoteIndex]['company_phone'] = contact?.phone || "";
            
            let isValidEmail = validEmail(contact.email, true);
            quotes[quoteIndex]['validCompanyEmail'] = isValidEmail;
        }

        if (key == 'company_email') {
            let isValidEmail = validEmail(value.trim(), true);
            quotes[quoteIndex]['validCompanyEmail'] = isValidEmail;
        }

        this.setState({quotes});
    }

    quoteListDataChanged(data) {
        if(!this.costTargeting.current && !this.billTargeting.current) {
            return;
        }

        this.cachedRightColumnData = {
            quoteId: this.state.selectedQuoteId,
            data: arrangeQuoteRowsUnderHeaders(cloneDeep(data).filter(r => Number(r.deleted) !== 1)) 
        };

        this[this.state.selectedTab].current.setData({ 
            headers: this.cachedRightColumnData.data
        });
    }

    async getBillingAddresses(customersId, quoteId, addressId) {
        const { quotes } = this.state;

        let billingAddresses = await DataHandler.request("GET", {url: "subjects/billing_addresses/" + customersId});
        billingAddresses = billingAddresses.map(a => {a.label = a.address; return a;});

        let quoteIndex = quotes.findIndex(c => c.id == quoteId);
        if (quoteIndex > -1)
            quotes[quoteIndex]['address_id'] = addressId;

        this.addedBillingAddressIds.push(addressId);

        this.setState({
            billingAddresses: billingAddresses,
            quotes: quotes
        });
    }

    async getVisitingAddresses(customersId, quoteId, addressId) {
        const { quotes } = this.state;
        const { project } = this.props;

        let visitingAddresses = await DataHandler.get({url: `accounts/${customersId}/visiting_addresses/${project.companies_id}`})
        visitingAddresses = visitingAddresses.map(a => {a.label = a.address; return a;});

        let quoteIndex = quotes.findIndex(c => c.id == quoteId);
        if (quoteIndex > -1)
            quotes[quoteIndex]['address_id'] = addressId;

        this.addedVisitingAddressIds.push(addressId);

        this.setState({
            visitingAddresses: visitingAddresses,
            quotes: quotes
        });
    }

    showBlockingOperationNotification = (msg = "", cb = () => {}) => {
        const snackbarKey = this.props.enqueueSnackbar(msg, { variant: "info" });

        setTimeout(() => {
            cb(snackbarKey);
        }, 300);
    }

    edit = () => {
        if (!this.quote.current)
            return;

        this.context.functions.setDirty(true, false, {
            title: this.tr('Sales Quote is not saved!'),
            text: this.tr('Are you sure you want to leave? All unsaved changes will be lost.'),
        });

        if(this.quote.current.getQuoteData().length > QUOTE_MIN_ROWS_EDITMODE_NOTIFICATION) {
            this.showBlockingOperationNotification(
                this.tr("Starting edit mode – with large amounts of data this may take a few seconds"), 
                (key) => this.setState({ editMode: true }, () => this.props.closeSnackbar(key))
            );
        } else {
            this.setState({ editMode: true });           
        }
    }

    cancel = () => {
        if(this.state.selectedQuoteId < 0) {
            this.setState({
                dialogData: {
                    cancelText: this.tr("No"),
                    okText: this.tr("Yes"),
                    saveFunc: () => {
                        this.context.functions.setDirty(false);
                        this.deleteQuote(this.state.selectedQuoteId);
                    },
                    text: this.tr("You haven't saved this quote yet. Canceling now will delete the quote. Are you sure you want to cancel?")
                }
            });

            this.openDialog("cancel");
        } else {
            this.setState({
                dialogData: {
                    cancelText: this.tr("No"),
                    okText: this.tr("Yes"),
                    saveFunc: () => {
                        const quotes = cloneDeep(this.uneditedQuotes || this.state.quotes);
                        if(this.quote.current.getQuoteData().length > QUOTE_MIN_ROWS_EDITMODE_NOTIFICATION) {
                            this.showBlockingOperationNotification(
                                this.tr("Leaving edit mode – with large amounts of data this may take a few seconds"), 
                                (key) => {
                                    this.setState({ quotes, editMode: false }, () => {
                                        this.quote.current.resetData();
                                        this.props.closeSnackbar(key);
                                    });
                                }
                            ); 
                        } else {
                            this.setState({ quotes, editMode: false }, () => {
                                this.quote.current.resetData();
                            });
                        }
                
                        this.context.functions.setDirty(false);
                        this.props.validateForms();
                    },
                    text: this.tr("Are you sure you want to cancel?")
                }
            });

            this.openDialog("cancel");
        }
    }

    save = () => {
        this.setState({ saving: true }, () => {
            const { project, selectedQuoteId, deletedProducts } = this.state;

            // TODO: the email check etc.
    
            const invalidEmails = this.state.quotes.find(q => (q.validEmail == false || q.validCompanyEmail == false));
    
            if (invalidEmails) {
                this.props.enqueueSnackbar(this.tr('You have invalid email addresses in your quotes.'), {
                    variant: "error",
                });
                this.setState({ saving: false });
                return;
            }
    
            // Sender info
            // quotes[0][company_email]: jarmo.jaaskelainen@taimer.com
            // quotes[0][company_phone]: 01010101-47
            // quotes[0][sender_contact_id]: 124
    
            // quotes.forEach((q) => {
                // let rowOrder = 0;
                // q.headers.forEach(h => {
                    // h.roworder = rowOrder;
                    // rowOrder++;
                    // h.rows.forEach(r => {
                        // r.roworder = rowOrder;
                        // rowOrder++;
                    // });
                // })
            // });
            
            const thisQuote = this.state.quotes.find(q => {
                return q.id == selectedQuoteId; 
            });
    
            if(!thisQuote) {
                this.setState({ saving: false });
                console.error("Quote not found.");
                return;
            }
    
            const snackbarKey = this.props.enqueueSnackbar(this.tr("Saving quote"), {
                variant: "info",
                persist: true
            });
    
            // true parameter = reversed row type map.
            const typeMap = this.quote.current.getRowTypeMap(true);
            let hRowOrder = 0;
    
            let quoteData = this.quote.current.getQuoteData().map(row => {
                if(row.hasOwnProperty("text")) {
                    row.name        = row.text;
                    row.description = row.text;
                }
    
                if(typeMap.hasOwnProperty(row._type))
                    row.type = typeMap[row._type];
                else
                    row.type = 0;
    
                row.roworder = hRowOrder++;
    
                return row;
            });
    
            const headers = {};
    
            quoteData.forEach(row => {
                if(row.hasOwnProperty("parentId") && row.parentId && row.parentId !== "0") {
                    return;
                }
    
                delete row.rows;
    
                row.rows                = [];
                headers[String(row.id)] = row;
            });
    
            quoteData.filter(row => row.hasOwnProperty("parentId") && row.parentId && row.parentId !== "0").forEach(row => {
                headers[String(row.parentId)].rows.push(row); 
            });
    

    
            // TODO!!
            const data = {
                quotes: [{
                    ...thisQuote,
                    id: selectedQuoteId,
                    name: thisQuote.name,
                    project_name: this.quote.current.getProjectName(),
                    projectId: project.id,
                    deleted: 0,
                    headers: headers,
                    // editedAddress: thisQuote.editedAddress,
                    // company_phone: thisQuote.company_phone,
                    // company_email: thisQuote.company_email,
                    // sender_contact_id: thisQuote.sender_contact_id,
                    // receiver_contact_id: thisQuote.receiver_contact_id
                }],
                newAddressIds: this.addedVisitingAddressIds
            };
    
            this.addedVisitingAddressIds = [];
    
            DataHandler.request("POST", { url: "projects/quotes" }, data, true).done(response => {
                let selectedQuote = null;
    
                this.context.functions.setDirty(false);
    
                // TODO: What's this?
                if (response.cost_est_ids && response.cost_est_ids.length > 0) {
                    selectedQuote = response.cost_est_ids.find(r => r.oldId == selectedQuoteId);
                }
    
                // newQuotes.forEach(e => {
                // this.context.mixpanel.track('Create Quote', {'Origin': 'Project view'});
                // this.context.mixpanel.people.increment('# of quotes created');
                // });
    
                setTimeout(() => {
                    this.props.updateProjectData(); // updating project data to get updated values in revenue recognition
                    DataHandler.request("GET", {url: "projects/" + project.id + "/quotes"}).done(response => {
                        const update = {
                            quotes: response,
                            selectedQuoteId: selectedQuote ? selectedQuote.newId : this.state.selectedQuoteId
                        };
    
                        // Waaaaau.
                        this.quote.current ? this.quote.current.emptyNewData(() => {
                            this.setState(update, () => {
                                this.setState({ editMode: false, saving: false }, () => {
                                    this.props.closeSnackbar(snackbarKey); 
                                });
                            });
                        }) : this.props.closeSnackbar(snackbarKey);
    
                        this.props.validateForms();
                    });
                }, 1000);
            });
        });
    }

    editAddress = (quoteId, name, value) => {
        let { quotes } = this.state;

        const quoteIndex = quotes.findIndex(c => c.id == quoteId);

        let editedAddress = (quotes[quoteIndex]['editedAddress'] === undefined) ? {} : quotes[quoteIndex]['editedAddress'];
        editedAddress[name] = value;

        quotes[quoteIndex]['editedAddress'] = editedAddress;

        if (name == "email") {
            let isValidEmail = validEmail(value.trim(), true);
            quotes[quoteIndex]['validEmail'] = isValidEmail;
        }

        this.setState({ quotes });
    }

    editCompanyAddress = (quoteId, name, value) => {
        const { quotes } = this.state;

        const quoteIndex = quotes.findIndex(c => c.id == quoteId);

        let editedCompanyAddress = (quotes[quoteIndex]['editedCompanyAddress'] === undefined) ? {} : quotes[quoteIndex]['editedCompanyAddress'];
        editedCompanyAddress[name] = value;

        quotes[quoteIndex]['editedCompanyAddress'] = editedCompanyAddress;
        this.setState({ editedCompanyAddress });
    }

    getQuoteHTML = () => {
        return ReactDOM.findDOMNode(this.quote.current).outerHTML;
    }

    printQuote = async (id) => {
        const snackbarKey = this.props.enqueueSnackbar(this.tr("Generating quote PDF-file..."), {
            variant: "info",
            persist: true
        });
      
        this.setState({ renderPrintQuote: true, printQuoteId: id, snackbarKey });
    }

    getDefaultQuoteVAT = () => {
        DataHandler.get({ url: `settings/company/${this.props.company}/project/defaultQuoteVat` }).done(response => {     
            this.setState({ defaultVAT: response.default_quote_vat });
        });
    }

    onPrintRenderReady = () => {
        if (this.state.renderProposal) {
            setTimeout(() => {
                const printQuoteId    = this.state.selectedQuoteId;
                const printLanguage   = this.state.printLanguage;
                const printDateFormat = this.convertDateFormat(this.state.printDateFormat);
        
                const quote = this.state.quotes.find(q => q.id == printQuoteId);
                this.context.functions.setOverlayComponent(<ProposalEditor printLanguage={printLanguage} printDateFormat={printDateFormat} refreshQuoteData={this.getData} quoteHTML={this.printquote.current && ReactDOM.findDOMNode(this.printquote.current).outerHTML} checkPrivilege={this.props.checkPrivilege} project={this.props.project} quote={quote} />);
                this.setState({renderPrintQuote: false, renderProposal: false})
            }, 500);
        }
        else {
            setTimeout(() => {
                this.print(this.state.printQuoteId, this.state.snackbarKey);
                this.setState({renderPrintQuote: false});
            }, 500);
        }
    }

    print = (id, snackbarKey) => {
        const quoteName = this.state.quotes.find(c => c.id == id).name;
        const projectName = this.state.project.name;

        let styleTags = document.head.getElementsByTagName('style');
        let styles = "";
        for (let style in styleTags) {
            if (styleTags[style].innerText != "" && styleTags[style].innerText != undefined && styleTags[style].innerText != "undefined")
                if (!styleTags[style].innerText.startsWith("iframe"))
                    styles += styleTags[style].innerText;
        };

        let linkTags = document.head.getElementsByTagName('link');
        let links = [];
        for (let link in linkTags) {
            if (linkTags[link].rel == "stylesheet" && (linkTags[link].href.indexOf("taimer.com") > 0 || linkTags[link].href.indexOf("heeros.com") > 0 || linkTags[link].href.indexOf("taimer-stack:1025") > 0)) {
                links.push(linkTags[link].href);
            }

        };
        
        // For dev - open the page in new window to inspect styles
        
        // let testHtml = '<html><head><style>' + styles + "</style>";
        // for (let link in links) {
        //     testHtml += "<link rel='stylesheet' href='" + links[link] + "'>";
        // }
        // testHtml += '</head><body style="margin:0px"><div id="print-container">';
        // let testQuoteHtml = ReactDOM.findDOMNode(this.printquote.current).outerHTML;
        // testHtml += testQuoteHtml + '</div></body></html>';
        // const printWindow = window.open('', 'PRINT');
        // if (printWindow && testHtml) printWindow.document.body.innerHTML = testHtml;
        // printWindow && printWindow.focus();
        // snackbarKey && this.props.closeSnackbar(snackbarKey);
        // return;
        
       
        // For production
        let html = '<html><head><meta charset="UTF-8"></head><body style="margin:0px"><div id="print-container">';
        let quoteHtml = ReactDOM.findDOMNode(this.printquote.current).outerHTML;
        html += quoteHtml + '</div></body></html>';

        const params = {
            quoteId: id,
            projectId: this.state.projectId,
            html: html,
            styles: styles,
            links: links,
        }

        DataHandler.post({url: "print/quote"}, params).done(response => {
            snackbarKey && this.props.closeSnackbar(snackbarKey);
            if (!response.error) {
                DataHandler.getArrayBuffer({url: 'file', filename: response.filename}).done((response) => {
                    if (response != false) {
                        const blob = new Blob([response], {
                            type: 'application/pdf'
                        });
                        FileSaver.saveAs(blob, format(new Date(), "YYYY-MM-DD") + "_" + quoteName + "_" + projectName + ".pdf");
                    } else {
                        this.props.enqueueSnackbar(this.tr("Failed to generate PDF-file."), {
                            variant: "error",
                        });
                    }
                });
            } else {
                this.props.enqueueSnackbar(this.tr("Failed to generate PDF-file."), {
                    variant: "error",
                });
            }
            
        });
    }

    copy = (id, activeStatus, targeting, name) => {

        DataHandler.post({ url: `projects/quotes/${id}/copy`, project: this.state.projectId, active: activeStatus, targeting: targeting, name: name, tr: this.tr("copystring")}).done(response => {
            this.setState({selectedQuoteId: response}, () => {
                setTimeout(() => {
                    DataHandler.get({url: "projects/" + this.state.projectId + "/quotes"}).done(response => {
                        this.setState({quotes: response});
                        this.edit();
                    });
                }, 1000);
            });            
        });

    }

    targetingChanged = (value, quoteId, headerId, rowId) => {
        const { quotes, editMode } = this.state;

        if (!editMode)
            return;

        value = value.replace(",", ".");

        const quoteIndex = quotes.findIndex(c => c.id == quoteId);
        const headerIndex = quotes[quoteIndex]['headers'].findIndex(h => h.id == headerId);
        const rowIndex = quotes[quoteIndex]['headers'][headerIndex]['rows'].findIndex(r => r.id == rowId);

        quotes[quoteIndex]['headers'][headerIndex]['rows'][rowIndex].targeted = value;

        this.setState({quotes});
    }

    onScaleChange = (scale) => {
        if (this.state.renderPrintQuote) return;

        this.setState({ scale: scale }, this.setOffset());
    }

    setOffset = () => {
        if (this.quote.current) {
            let elem = ReactDOM.findDOMNode(this.quote.current.list.current);

            setTimeout(() => {
                let bodyRect = document.body.getBoundingClientRect();
                let elemRect = elem.getBoundingClientRect();

                let offset = elemRect.top - bodyRect.top;
                this.setState({offset: offset});
            }, 1000)
        }
    }

    openTaskDialog = (quoteRowId, quantity, name) => {
        let { project } = this.state;
        project.label = project.name;
        let enddate = new Date();
        enddate.setDate(enddate.getDate() + 7);

        let dialogData = {
            quote_rows_id: quoteRowId,
            start_date: new Date(),
            end_date: enddate,
            projects_id: project.id,
            type: 'task',
            hours: quantity,
            $new: true,
            project_disabled: 1,
            text: name,
            onlyTask: true

        }
        if(!this.state.resourcingAutoCompleteData) {
            DataHandler.get({url: `resourcing/autocomplete/${project.companies_id}`, include_employees: 1}).done(data => {

                data.project_drop = data.projects.map(p => {
                    let cust = data.customers.find(c => c.id == p.customers_id);
                    let label = p.name + " ";
                    cust = cust ? cust.name : '';
                    label += " " + cust;
                    return {customer: cust, label: label, ...p};
                });
                data.project_drop.sort((a, b) => {
                    let result = (a.customer ? a.customer : '').localeCompare((b.customer ? b.customer : ''));
                    if(result != 0) {
                        return result;
                    }
                    return (a.name ? a.name : '').localeCompare((b.name ? b.name : ''));
                })
                data.customers.unshift({id: 0, label: 'All'});
                data.pipelines.unshift({id: 0, label: 'All'});

                data.employees.unshift({id: '', label: 'All'});
                data.employees.push({id: 0, employee: 'Unassigned'});
                data.employees = data.employees.map(el => ({label: el.employee, ...el}));
                this.setState({
                    resourcingAutoCompleteData: data,
                    dialogData: dialogData,
                    currentDialog: 'resourcing',
                });
            });
        } else {
            this.setState({
                dialogData: dialogData,
                currentDialog: 'resourcing',
            });
        }
    }

    openCopyDialog = (dialogType, id, name) => {

        let dialogData = {
            quote: id,
            quoteName: name,
            company: this.props.company,
            project: this.props.project.id,
            handleCopyResult: this.copy
        }

        this.setState({
            dialogData: dialogData,
            currentDialog: dialogType,
        });

    }


    updateRowOrder = (id, movedId, newheaderId, oldHeaderId, pos) => {
        let quotes = this.state.quotes;
        let data = Object.assign({}, this.selectQuote(this.state.selectedQuoteId));
        let quoteIndex = this.state.quotes.findIndex(el => el.id == data.id);
        let newHeaderIndex = data.headers.findIndex(el => el.id == newheaderId);
        let oldHeaderIndex = data.headers.findIndex(el => el.id == oldHeaderId);

        let newHeaderRows = data.headers[newHeaderIndex].rows.slice();
        let oldHeaderRows = data.headers[oldHeaderIndex].rows.slice();
        let idIndex = newHeaderRows.findIndex(el => el.id == id);
        let oldIndex = oldHeaderRows.findIndex(el => el.id == movedId);
        let offset = idIndex - oldIndex;
        let newIndex;
        if(newheaderId == oldHeaderId) {
            newIndex = oldIndex + offset;
            let old = newHeaderRows.splice(oldIndex, 1)[0];
            newHeaderRows.splice(newIndex, 0, old);   
            data.headers[newHeaderIndex].rows = newHeaderRows;
        } else {
            newIndex = idIndex + 1;
            let old = oldHeaderRows.splice(oldIndex, 1)[0];
            old.parentId = newheaderId;
            newHeaderRows.splice(newIndex, 0, old);   
            data.headers[oldHeaderIndex].rows = oldHeaderRows;
            data.headers[newHeaderIndex].rows = newHeaderRows;
        }        
        
        quotes[quoteIndex] = data;
        this.setState({ quotes: quotes});
        
    };

    updateHeaderOrder = (id, movedId, pos) => {
        let quotes = this.state.quotes;
        let data = Object.assign({}, this.selectQuote(this.state.selectedQuoteId));
        let quoteIndex = this.state.quotes.findIndex(el => el.id == data.id);

        let headers = data.headers.slice();

        let idIndex = headers.findIndex(el => el.id == id);
        let oldIndex = headers.findIndex(el => el.id == movedId);
        let offset = idIndex - oldIndex;
        let newIndex;
        newIndex = oldIndex + offset;
        let old = headers.splice(oldIndex, 1)[0];
        headers.splice(newIndex, 0, old);   
        data.headers = headers;
                       
        quotes[quoteIndex] = data;
        this.setState({ quotes: quotes});
        
    };

    invoiceQuote = (e) => {
        const { project: { id: projects_id, companies_id, customer_reference, account: { id: customers_id } } } = this.props;
        const { selectedQuoteId } = this.state;
        const { functions: { updateView } } = this.context;
        const data = this.selectQuote(selectedQuoteId);
        
        const update = {
            companies_id, 
            projects_id, 
            customers_id, 
            template: "material", 
            start: data.created, 
            end: data.created, 
            preselect: "quote", 
            reference: customer_reference, 
            quote: data.id,
            invoicetype: data.type == 2 ? 3 : 2
        }
        
        updateView({module: 'invoices', action: 'view', ...update}, e.ctrlKey || e.metaKey || e.button === 1);
    }

    showProposal = () => {
        const printQuoteId = this.state.selectedQuoteId;
        this.setState({ renderPrintQuote: true, printQuoteId, renderProposal: true });
    }
    
    renderEmptyView = () => {
        return (
            <div className="empty-quote-view">
                <img src={require('../dashboard/images/ActivitiesGraphic.svg').default} />
                <p>{this.tr('No quotes to show.')}</p>
                <Button onClick={this.addNewQuote} size="large" color="primary">{this.tr("Add new quote")}</Button>
                {this.context.addons.refund_material && <>
                    <div>&nbsp;</div>
                    <Button onClick={() => this.addNewQuote('2')} size="large" color="primary">{this.tr("Add new refund quote")}</Button>
                </>}
            </div> 
        );
    }

    renderPrintSettings = () => {
        return (
            <>
                <OutlinedField
                    select
                    label={this.tr("Print Language")}
                    className="listFilterOutlinedField quote-print-options"
                    value={this.state.printLanguage}>
                    {
                        this.state.printLanguageOptions.map((opt) =>
                            <MenuItem value={opt.value} key={opt.value} onClick={() => this.setState({ printLanguage: opt.value })}>
                                {this.tr(opt.label)}
                            </MenuItem>
                        )
                    }
                </OutlinedField>
                <OutlinedField
                    select
                    label={this.tr("Date Format")}
                    className="listFilterOutlinedField quote-print-options"
                    value={this.state.printDateFormat}>
                    {
                        this.state.printDateOptions.map((opt) =>
                            <MenuItem value={opt.value} key={opt.value} onClick={() => this.setState({ printDateFormat: opt.value })}>
                                {opt.label}
                            </MenuItem>
                        )
                    }
                </OutlinedField>
            </>
        )
    }
    
    hasProposalReadRight = () => {
        return this.props.checkPrivilege("projects", "proposal_read");
    }

    hasProposalWriteRight = () => {
        return this.props.checkPrivilege("projects", "proposal_write");
    }

    noProposalAndWriteRight = (data) => (!data.proposals_id || data.proposals_id == '-1') && this.hasProposalWriteRight();
    hasProposalAndReadOrWriteRight = (data) => !!data.proposals_id && (this.hasProposalWriteRight() || this.hasProposalReadRight());
    
    renderInvoicingDisabledMsg = (data, hasUnbilledMaterial) => {
        if (Number(data.active) === 0) 
            return this.tr("This quote is not active");
        if (!hasUnbilledMaterial || data.fully_invoiced === '1')
            return this.tr("This quote is fully invoiced");
        
        return "";
    }
    render () {
        const { quotes, selectedQuoteId, dataLoaded, project, editMode, products, CPQParents, units, deletedProducts, allProductNames, selectedTab, defaultBillingAddressId, companyAddress, billingAddresses, visitingAddresses, receiverContacts, companyContacts, companyCurrency, allUsers, refreshingData, renderPrintQuote, printQuoteId, printLanguage, printDateFormat, renderProposal, defaultVAT } = this.state;
        const { addons }                                            = this.context;
        const { checkPrivilege, project: { charge_costest, type }, canCreateInvoice } = this.props;

        const button = {
			className: 'option-menu-button',
            stickyIcon: true,
            size: "large"
        };

        const paperProps = {
            container:      this.props.printMode ? this.props.container : this.container,
            onScaleChange:  this.onScaleChange
        };

        const allProducts        = products?.map(e => ({...e, value: e.id })) || [];
        const nondeletedProducts = allProducts?.filter(e => Number(e.deleted) === 0) || [];

        const rowProps = {
            quoteId:            this.state.selectedQuoteId,
            editMode:           editMode,
            products:           allProducts,
            nondeletedProducts: nondeletedProducts,
            allProductNames:    allProductNames,
            CPQParents:         CPQParents?.map(e => ({ ...e, value: e.id })) || [],
            company:            this.props.company,
            units:              units?.map(u => ({ ...u, value: u.name, id: u.name })) || [],
            renderPrintQuote:   renderPrintQuote,
	    printMoode:         false,
            deletedProducts:    deletedProducts,
            currency:           companyCurrency,
            jobtypes:           this.state.jobtypes?.map(jt => ({...jt, value: jt.id})) || [],
            deleteRow:          this.deleteRow,
            createTask:         this.openTaskDialog,
            canCreateTask:      checkPrivilege("projects", "project_resourcing_write") || checkPrivilege("projects", "own_resourcing_write"),
            rowDataChanged:     this.rowDataChanged,
            deleteHeaderRow:    this.deleteHeaderRow,
            headerNameChanged:  this.headerNameChanged,
            CPQGroupChanged:    this.CPQGroupChanged,
            addRow:             this.addRow,
            addProductRow:      this.addProductRow,
            addHeaderRow:       this.addHeaderRow,
            addCPQRow:          this.addCPQRow,
            addCPQGroup:        this.addCPQGroup,
            addDescriptionRow:  this.addDescriptionRow,
            addRefundRow:       this.addRefundRow,
            editAddress:        this.editAddress,
            editCompanyAddress: this.editCompanyAddress,
            updateRowOrder:     this.updateRowOrder,
            updateHeaderOrder:  this.updateHeaderOrder,

        };

        const Dialog   = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        const editable = checkPrivilege("projects", "project_cost_estimate_write");
        
        if(!dataLoaded)
            return null;

        // Default value if data is not loaded yet, prevent crashes (quote copy)
        const data = this.state.quotes.find(q => Number(q.id) === Number(selectedQuoteId)) || {};
        const showRightMenuTabs = !!(addons.quoterow_partial_invoicing_for_products);
        
        let printQuoteContent = <Quote
            ref={this.printquote}
            // selectedQuoteId={selectedQuoteId}
            id={printQuoteId ? printQuoteId : -1} 
            data={data}
            quotes={quotes}
            project={project}
            onPrintRenderReady={this.onPrintRenderReady}
            renderPrintQuote={renderPrintQuote}
            printLanguage={printLanguage}
            defaultVAT={defaultVAT}
            printDateFormat={this.convertDateFormat(printDateFormat)}
            defaultBillingAddressId={defaultBillingAddressId}
            editMode={editMode}
            billingAddresses={billingAddresses}
            visitingAddresses={visitingAddresses}
            receiverContacts={receiverContacts}
            companyContacts={companyContacts}
            allUsers={allUsers}
            paperProps={paperProps}
            rowProps={rowProps}
            quoteDataChanged={this.quoteDataChanged}
            onListDataChange={this.quoteListDataChanged}
            companyAddress={companyAddress}
            columns={this.columns}
            printMode={this.props.printMode}
            getBillingAddresses={this.getBillingAddresses}
            getVisitingAddresses={this.getVisitingAddresses}
        />;

        if(this.props.printMode) {
            return printQuoteContent;
        }

        let quoteContent = (
                <Quote
                    ref={this.quote}
                    // selectedQuoteId={selectedQuoteId}
                    id={data ? data.id : -1} 
                    data={data}
                    quotes={quotes}
                    project={project}
                    renderPrintQuote={false}
                    defaultBillingAddressId={defaultBillingAddressId}
                    editMode={editMode}
                    defaultVAT={defaultVAT}
                    billingAddresses={billingAddresses}
                    visitingAddresses={visitingAddresses}
                    receiverContacts={receiverContacts}
                    companyContacts={companyContacts}
                    allUsers={allUsers}
                    paperProps={paperProps}
                    rowProps={rowProps}
                    quoteDataChanged={this.quoteDataChanged}
                    onListDataChange={this.quoteListDataChanged}
                    companyAddress={companyAddress}
                    columns={this.columns}
                    printMode={this.props.printMode}
                    getBillingAddresses={this.getBillingAddresses}
                    getVisitingAddresses={this.getVisitingAddresses}
                />
        );
        
        let hasUnbilledMaterial = false;

        if (data && data.headers) {
            for (const header of data.headers) {
                if (header.deleted === "1")
                    continue;

                for (const row of header.rows) {
                    if (row.deleted === "1")
                        continue;

                    if (row.bills_id === "0") {
                        hasUnbilledMaterial = true;
                        break;
                    }
                }
            }
        }

        return (
            <div id="projects-quotes" className={editMode ? "editMode" : ""} ref={this.container}>
                {quotes.length == 0 ? this.renderEmptyView() : (
                <div className='quote-content'>
                <div id="left-column" className="column">
                    <div id="button-container">
                        <div className="quote-tabs">

                        </div>
                        <div className="buttons">
                        {!editMode && this.state.printLanguageOptions.length > 1 ? this.renderPrintSettings() : <OutlinedField
                            select
                            label={this.tr("Date Format")}
                            className="listFilterOutlinedField quote-print-options"
                            value={this.state.printDateFormat}>
                            {
                                this.state.printDateOptions.map((opt) =>
                                    <MenuItem value={opt.value} key={opt.value} onClick={() => this.setState({ printDateFormat: opt.value })}>
                                        {opt.label}
                                    </MenuItem>
                                )
                            }
                </OutlinedField>}
                        {!editMode && (this.noProposalAndWriteRight(data) || this.hasProposalAndReadOrWriteRight(data)) && <LoaderButton loading={refreshingData || renderProposal} className="button" text={!data.proposals_id || data.proposals_id == '-1' ? this.tr('Create Proposal') : this.tr('View Proposal')} onClick={this.showProposal} size="large" />}
                            {editMode == false ? (<ContextMenu buttonProps={button} variant="outlined" className="option-menu" label={this.tr("Options")} size="medium" placement={"bottom-start"}>
                                <MenuItem onClick={() => this.printQuote(data.id, printLanguage, printDateFormat)}><PrintIcon />{this.tr('Print')}</MenuItem>
                                {editable && <MenuItem onClick={() => this.openCopyDialog('copySimple', data.id, data.name)}><CopyIcon />{this.tr('Copy')}</MenuItem>}
                                {editable && <MenuItem onClick={() => this.addNewQuote()}><NewIcon />{this.tr('New quote')}</MenuItem>}
                                {editable && this.context.addons.refund_material &&
                                    <MenuItem onClick={() => this.addNewQuote('2')}><NewIcon />{this.tr("New refund quote")}</MenuItem>
                                }
                                {editable && <MenuItem onClick={() => this.openCopyDialog('copyQuote', false, data.name)}><SearchIcon />{this.tr('Copy from another project')}</MenuItem>}
                                {data && type == "1" && charge_costest == "1" && canCreateInvoice &&
                                <Tooltip title={this.renderInvoicingDisabledMsg(data, hasUnbilledMaterial)}>
                                    <div>
                                        <MenuItem
                                            disabled={!hasUnbilledMaterial || data.fully_invoiced === '1' || Number(data.active) === 0}
                                            onClick={e => (e.button !== 1) && (this.invoiceQuote(e))}
                                            onMouseUp={e => (e.button === 1) && this.invoiceQuote(e)}>
                                            <NewInvoice />{this.tr('Add Invoice')}
                                        </MenuItem>
                                    </div>
                                </Tooltip>}
                                </ContextMenu>) 
                                :
                                <Button className="button grey" onClick={this.cancel} size="large">{this.tr('Cancel')}</Button>
                                }

                                {editable && ((editMode == true) ? (
                                    <LoaderButton className="button" loading={this.state.saving} onClick={this.save} size="large" text={this.tr('Save')} />
                                ) : 
                                    <Button className="button" onClick={this.edit} size="large">{this.tr('Edit')}</Button>
                                )}
                            </div>
                        </div>

                        {this.state.renderedOnce && quoteContent}
                        {this.state.renderedOnce && this.state.renderPrintQuote && (
                            <div  style={{ display: "none", width: "0px", height: "0px" }}>
                                {printQuoteContent}
                            </div>
                        )}
                    </div>

                    <div id="right-column" className="column">
                        {showRightMenuTabs && <div className="tabs-container">
                            <Tabs color="primary" value={this.state.selectedTab} onChange={this.switchTabs} className="details" >
                                <Tab value="costTargeting" label={this.tr("Cost targeting")} />
                                <Tab value="billTargeting" label={this.tr("Bill targeting")} />
                            </Tabs>
                        </div>}

                        {selectedTab === "costTargeting" && (
                            <CostTargeting
                                // data={this.state.rightColumnData !== undefined ? this.state.rightColumnData : {}}
                                ref={this.costTargeting}
                                currency={companyCurrency}
                                editMode={editMode}
                                // onChange={this.targetingChanged}
                                onChange={(value, quoteId, headerId, rowId) => {
                                    this.quote.current.editData({ id: rowId, targeted: value });
                                }}
                                noProposalAndWriteRight={this.noProposalAndWriteRight}
                                hasProposalAndReadOrWriteRight={this.hasProposalAndReadOrWriteRight}
                                offset={this.state.offset}
                                scale={this.state.scale}
                                project={this.props.project}
                                quotes={quotes}
                                editable={editable}
                                showTabs={showRightMenuTabs}
                                handleActiveChange={this.activeChanged}
                                selectedQuoteId={selectedQuoteId}
                                printQuote={this.printQuote}
                                handleQuoteIdChange={(id, showProposal) => this.setState({ selectedQuoteId: id }, () => {
                                    if (showProposal) {
                                        this.showProposal();
                                    }
                                })}
                                handleQuoteDelete={id => this.deleteQuoteConfirmation(id)}
                            />
                        )}
                        {addons.quoterow_partial_invoicing_for_products && selectedTab === "billTargeting" && (
                            <BillTargeting
                                ref={this.billTargeting}
                                // data={data}
                                editMode={editMode}
                                // onChange={this.targetingChanged}
                                rowDataChanged={this.quote.current.editData}
                                offset={this.state.offset}
                                scale={this.state.scale}
                                project={this.props.project}
                                showTabs={showRightMenuTabs}
                            />
                        )}
                    </div>
                    {Dialog && <Dialog
                        open
                        onDialogClose={this.closeDialog}
                        onClose={this.closeDialog}
                        checkPrivilege={this.props.checkPrivilege}
                        onDialogSave={Dialog == ResourceDialog ? () => {
                            DataHandler.request("GET", {url: "projects/" + project.id + "/quotes"}).done(response => {
                                const update = {
                                    quotes: response,
                                    editMode: false
                                }

                                this.context.functions.setDirty(false);
                                this.props.validateForms();
                                this.setState(update, () => {
                                    this.closeDialog();
                                });
                            });
                        } : this.confirmDialog}
                        data={this.state.dialogData}
                        autoCompleteData={Dialog == ResourceDialog ? this.state.resourcingAutoCompleteData : {}}
                        />
                    }
                    </div>
                    )}
            </div>
        );
    }
};

TabQuotes.propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired
};

export default withSnackbar(TabQuotes);
