import React from 'react';

import { formatInputNumber } from '../helpers';
import TextInputCell from '../list/cells/TextInputCell';
import ListCell from "../list/ListCell";
import DateCell from "../list/cells/DateCell";
import CurrencyListCell from "../list/CurrencyListCell";
import PercentageListCell from "../list/PercentageListCell";
import PropsOnlyListRow from '../list/PropsOnlyListRow';
import TrashCanIcon from "@mui/icons-material/Delete";
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';

class QuoteScheduledInvoiceDialogListRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, {}, 'dialogs/QuoteScheduledInvoiceDialogListRow');
    }

    defineClassName = () => {
        return this.props.data.type == "totals"
            ? "totalsRow"
            : "dialogRow";
    };

    onEdit = (name, value) => {
        const { data, rowProps: { onEdit }} = this.props;

        this.setData(name, value);
        onEdit(data.id, name, value);
    }

    defineCells = () => {
        const { 
            data, 
            columnWidthMap, 
            columnConfig, 
            rowProps: {
                onDelete,
                invalidFields,
                vatEditable
            }
        } = this.props;
        const { taimerAccount } = this.context;

        const isTotalRow = data.type == "totals";

        const priceFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            useGrouping: false,
            minimumFractionDigits: 2,
            maximumFractionDigits: isTotalRow ? 2 : 4
        }).format;

        const percentageFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            useGrouping: false,
            minimumFractionDigits: 0,
            maximumFractionDigits: isTotalRow ? 2 : 4
        }).format;

        const cells = {};
        Object.keys(columnConfig).forEach((key) => {
            const column = columnConfig[key];
            const listCellEditModeProps = {
                editable: !isTotalRow,
                inEditMode: !isTotalRow,
                noInitFocus: true,
                disableInitialFocus: true,
                className: "dialogCell",
            };

            const textInputCellProps = {
                useClickAwayListener: true,
                runOnEditedOnInput: false,
                onEdit: this.onEdit,
                showErrorBorder: invalidFields.find(i => i == key + "_" + data.id),
            };

            switch (key) {
                case 'hidden_to_focus': { // Hidden cell that gets focused. To prevent datecell initial focus and calendar open.
                    cells[key] = (
                        <TextInputCell
                            width={columnWidthMap[key]}
                            name={key}
                            textAlign={'left'}
                            editable={true}
                            value={""}
                            {...textInputCellProps}
                            listCellProps={{ 
                                ...listCellEditModeProps, 
                                onEdit: () => {},
                                className: "hiddenCell"
                            }}
                        />
                    );
                    break;
                }
                case 'invoice_date': {
                    cells[key] = (isTotalRow ?
                    <TextInputCell
                            width={columnWidthMap[key]}
                            name={key}
                            editable={!isTotalRow}
                            value={data.invoice_date || ""}
                            {...textInputCellProps}
                            listCellProps={{ ...listCellEditModeProps }}
                        />
                    : 
                        <DateCell
                            width={columnWidthMap[key]}
                            name={key}
                            usePopper={true}
                            popperBottom={true}
                            openOnEnterEditMode={false}
                            closeCalendarOnComplete={true}
                            closeOnComplete={true}
                            editable={true}
                            value={moment(data.invoice_date)?.format("YYYY-MM-DD")}
                            {...textInputCellProps}
                            listCellProps={{ 
                                ...listCellEditModeProps,
                                showErrorBorder: invalidFields.find(i => i == key + "_" + data.id),
                            }}
                        />
                    );
                    break;
                }
                case 'description': {
                    cells[key] = (
                        <TextInputCell
                            width={columnWidthMap[key]}
                            name={key}
                            textAlign={'left'}
                            editable={!isTotalRow}
                            value={data.description || ""}
                            {...textInputCellProps}
                            listCellProps={{ ...listCellEditModeProps }}
                        />
                    );
                    break;
                }
                case 'total_no_vat': {
                    cells[key] = (
                        <TextInputCell
                            width={columnWidthMap[key]}
                            name={key}
                            textAlign={'right'}
                            editable={!isTotalRow}
                            value={priceFormatter(data.currency_total_no_vat)?.toString().replace('.', ',')?.replace('−', '-')}
                            listCellType={CurrencyListCell}
                            {...textInputCellProps}
                            listCellProps={{ 
                                ...listCellEditModeProps, 
                                currency: this.props.rowProps.currency 
                            }}
                        />
                    );
                    break;
                }
                case 'vat_percentage': {
                    cells[key] = !isTotalRow && !vatEditable ?
                        <ListCell className={listCellEditModeProps.className + " disabled alignRight"} permanentEditMode={true} width={columnWidthMap[key]} onlyDisplay={true}>
                            <Tooltip title={this.tr("Quote row's VAT % is used when creating scheduled invoice.")} placement="bottom" classes={{ tooltip: 'darkblue-tooltip' }} arrow>
                                <div>-</div>
                            </Tooltip>
                        </ListCell>
                        : 
                        <TextInputCell
                            width={columnWidthMap[key]}
                            name={key}
                            textAlign={'right'}
                            editable={!isTotalRow && vatEditable}
                            value={isTotalRow ? "" : (vatEditable ? formatInputNumber(data.vat_percentage || 0, "no-zero-decimals") : "-")}
                            {...textInputCellProps}
                            listCellProps={{ 
                                ...listCellEditModeProps,
                                editable: !isTotalRow && vatEditable,
                                inEditMode: !isTotalRow && vatEditable, 
                            }}
                        />
                    break;
                }
                case 'total': {
                    cells[key] = !vatEditable ?
                        <ListCell className={listCellEditModeProps.className + " alignRight"} width={columnWidthMap[key]} onlyDisplay={true} inEditMode={false} editable={false}>
                            <Tooltip title={this.tr("Total is calculated based on row-specific VAT percentages.")} placement="bottom" classes={{ tooltip: 'darkblue-tooltip' }} arrow>
                                <div>-</div>
                            </Tooltip>
                        </ListCell>
                        : 
                        <TextInputCell
                            width={columnWidthMap[key]}
                            name={key}
                            textAlign={'right'}
                            editable={false}
                            value={priceFormatter(data.currency_total)?.toString().replace('.', ',')?.replace('−', '-')}
                            listCellType={!vatEditable ? ListCell : CurrencyListCell}
                            {...textInputCellProps}
                            listCellProps={{ 
                                ...listCellEditModeProps,
                                currency: this.props.rowProps.currency,
                                inEditMode: false,
                                editable: false,
                            }}
                        />
                    break;
                }
                case 'percentage': {
                    cells[key] = (
                        <TextInputCell
                            listCellType={isTotalRow ? PercentageListCell : undefined}
                            width={columnWidthMap[key]}
                            name={key}
                            textAlign={'right'}
                            editable={!isTotalRow}
                            value={textInputCellProps.showErrorBorder ? data.percentage : percentageFormatter(data.percentage)?.toString().replace('.', ',')?.replace('−', '-')}
                            {...textInputCellProps}
                            listCellProps={{ ...listCellEditModeProps }}
                        />
                    );
                    break;
                }
                case 'delete': {
                    cells[key] = (
                        <ListCell width={this.props.columnWidthMap.delete} className="noBg pointer deleteCell" onClick={() => onDelete(data)} permanentEditMode>
                            {!isTotalRow ? <TrashCanIcon />  : <div></div>}
                        </ListCell> 
                    );
                    break;
                } 
            }
        })
        return cells;
    };
}

export default QuoteScheduledInvoiceDialogListRow;
