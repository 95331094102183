import React from "react";
import TaimerComponent from "../../../TaimerComponent";
import InsightTable, { InsightTableListRow } from "../InsightTable";
import ListCell from "../../../list/ListCell";
import InsightTabs from "../InsightTabs";
import { createExport } from "../InsightExport";
import _ from 'lodash';
import { format } from 'date-fns';
import "./BillableMaterialsByProject.css";
import ListRow from "../../../list/ListRow";

class BillableMaterialsSummaryRow extends ListRow {
  constructor(props, context) {
    super(
      props,
      {},
      {},
      "dashboard/insights/invoicing/BillableMaterialsSummaryRow"
    );
  }

  defineClassName() {
    return "insightSummaryRow";
  }

  defineCells() {
    const {
      rows,
      sharedData: { currency },
    } = this.props;

    const {
      taimerAccount,
      functions: { presentCurrency },
    } = this.context;

    let values = {};
    rows.map((row) => {
      Object.keys(row.data || {}).forEach((key) => {
        values[key]
          ? (values[key] += Number(row.data[key] || 0))
          : (values[key] = Number(row.data[key] || 0));
      });
    });

    let cells = {};

    Object.keys(this.props.columnConfig).forEach((key) => {
      cells[key] = (
        <ListCell
          width={this.props.columnConfig[key].width}
          className="totalcell"
          name="user"
          onlyDisplay
          editable={false}
          alignCell
        >
          {presentCurrency(values[key] || 0)}
        </ListCell>
      );
    });

    const totalColumns = [
      "project",
      "account",
    ];

    totalColumns.forEach((col) => {
      cells[col] = (
        <ListCell
          width={(this.props.columnConfig[col] || {}).width || 0}
          className="totalcell"
          name={col}
          onlyDisplay
          editable={false}
          alignCell
        >
          {this.tr("Total")}
        </ListCell>
      );
    });

    return cells;
  }

  render() {
    const cells = this.defineCells();
    const className = [
      "summaryRow listElement row flex",
      this.defineClassName(),
    ].join(" ");

    return (
      <div className={className} style={{ height: `50px` }}>
        {this.props.columnOrder.map((columnName) => cells[columnName])}
      </div>
    );
  }
}
class BillableMaterialsTableRow extends InsightTableListRow {
  defineCells = () => {
    const { data } = this.props;
    let otherCells = {};
    const {
      sharedData: { currency }
    } = this.props;

    const { taimerAccount } = this.context;
    const currencyFormatter = new Intl.NumberFormat(
      taimerAccount.numberFormat,
      {
        style: "currency",
        currency
      }
    ).format;
    Object.keys(data).forEach(key => {
      switch (key) {
        default:
          otherCells[key] = (
            <ListCell alignCell={true} onlyDisplay={true}>
              <p>{currencyFormatter(data[key] || 0)}</p>
            </ListCell>
          );
          break;
      }
    });
    const cells = {
      ...super.defineCells(),
      ...otherCells
    };
    return cells;
  };
}

const CURRENCY_COLUMN_PROPS = {
  width: 120,
  resizeable: false,
  moveable: false,
  hideable: false,
  showMenu: false
};

export default class BillableMaterialsByProject extends TaimerComponent {
  constructor(props, context) {
    super(
      props,
      context,
      "dashboard/insights/invoicing/BillableMaterialsByProject"
    );
    this.modes = [
      {
        label: this.tr("Project"),
        key: "project",
        action: () => this._setMode("project")
      },
      {
        label: this.tr("Account"),
        key: "account",
        action: () => this._setMode("account")
      }
    ];
    this.state = {
      mode: "project"
    };
  }

  _renderFilters = () => {
    const { mode } = this.state;
    return <InsightTabs selected={mode} tabs={this.modes} />;
  };

  _setMode = mode => {
    this.setState({ mode });
  };

  _getData = () => {
    const { mode } = this.state;
    const { data } = this.props;

    return mode == "project" ? data.projects : data.customers;
  }

  export = ({ sliders }) => {
    const { currency, data: { start, end } } = this.props;
    const { userObject: { dateFormat } } = this.context;

    createExport({name: 'billable_materials'}, [{
      title: this.tr('Billable Materials'),
      header: this.tr('Billable Materials between ${start} and ${end}', {start: format(start, dateFormat), end: format(end, dateFormat)}),
      data: _.values(sliders),
      columns: [
        {
          title: this.tr('Customer'),
          column: 'account_name',
        },
        {
          title: this.tr('Project'),
          column: 'project_name',
        },
        {
          title: this.tr('Type'),
          column: 'cost_type',
        },
        {
          title: this.tr('Date'),
          column: 'date',
          type: 'date',
          format: 'YYYY-MM-DD',
          displayFormat: dateFormat,
        },
        {
          title: this.tr('Sum'),
          column: 'sum',
          type: 'currency',
          currency,
        },
      ]
    }]);
  }

  render() {
    const { mode } = this.state;
    const { currency, loaded, data, ...props } = this.props;
    const { tr } = this;
    return (
      <InsightTable
        {...props}
        filterComponents={this._renderFilters()}
        loaded={loaded}
        className="bigscroll billable-materials-by-project"
        sliderTitleColumn="projects_name"
        sliderRows={data.slider_rows}
        sliderProps={{
          idColumn: "$id",
        }}
        sliderColumns={['number', 'account', 'project', 'cost_type', 'sum']}
        summaryRowType={BillableMaterialsSummaryRow}
        rowType={BillableMaterialsTableRow}
        title={this.tr("Billable Materials by Project")}
        blockProps={{
          largeTitle: true,
          rootClassName: "block",
          wrapperClass: "block-wrapper",
          headerClass: "block-header"
        }}
        data={this._getData()}
        columns={[
          mode == "project"
            ? {
              field: "project",
              name: "project",
              header: tr("Project"),
              ...CURRENCY_COLUMN_PROPS,
              width: 200
            }
            : {
              field: "account",
              name: "account",
              header: tr("Account"),
              ...CURRENCY_COLUMN_PROPS,
              width: 200
            },
          {
            field: "total",
            name: "total",
            header: tr("Total"),
            ...CURRENCY_COLUMN_PROPS
          },
          {
            field: "hours",
            name: "hours",
            header: tr("Hours"),
            ...CURRENCY_COLUMN_PROPS
          },
          {
            field: "bills",
            name: "bills",
            header: tr("Bills"),
            ...CURRENCY_COLUMN_PROPS
          },
          {
            field: "expense",
            name: "expense",
            header: tr("Expenses"),
            ...CURRENCY_COLUMN_PROPS
          },
          {
            field: "travel",
            name: "travel",
            header: tr("Travel Expenses"),
            ...CURRENCY_COLUMN_PROPS
          },
          {
            field: "quote",
            name: "quote",
            header: tr("Quotes"),
            ...CURRENCY_COLUMN_PROPS
          },
          {
            field: "scheduled",
            name: "scheduled",
            header: tr("Scheduled Invoices"),
            ...CURRENCY_COLUMN_PROPS,
            width: 140
          },
          {
            field: "automatic",
            name: "automatic",
            header: tr("Automatic Invoicing"),
            ...CURRENCY_COLUMN_PROPS,
            width: 140
          },
        ]}
        height={380}
        table={{
          defaultType: "invoice"
        }}
        currency={currency}
        showExport
        onExport={this.export}
      />
    );
  }
}
