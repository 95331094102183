import React from 'react';
import Popover from '@mui/material/Popover';


export default function PopoverMenu(props) {

  const [anchorEl, setAnchorEl] = React.useState(props.anchorEl);

  const handleClose = () => {
    props.onClose();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 15,
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <div className="popover-menu">
        {(props.options || []).map((option) => {
            return (
                option.show && <div onClick={event => {
                    option.function(props.calendarEvent);
                    handleClose();
                  }} className={option.class}>
                    {option.icon} {option.title}
                </div>
            );
        })}
        </div>
      </Popover>
  );
}