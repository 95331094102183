import React from 'react';
import ListCell from './../list/ListCell';
import styles from './InvoicedVSSalesRow.module.scss';
import PropsOnlyListRow from '../list/PropsOnlyListRow';
import cn from 'classnames';

export default class InvoicedVSSalesRow extends PropsOnlyListRow {
    constructor(props, context) {
        super(props, {}, {}, "projects/InvoicedVSSalesRow");
    }

    defineClassName() {
        return styles.row;
    }

    defineCells() {
        const { data, sharedData: { currency } } = this.props;
        const { taimerAccount, userObject } = this.context;

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency,
        }).format;

        let cells = {
            "type_name": (
                <ListCell onlyDisplay={true}>
                    <div className="list-cell-container">
                        <p>{this.tr(data.type_name)}</p>
                    </div>
                </ListCell>),
            "invoiced": (
                <ListCell onlyDisplay={true}>
                    <div className="list-cell-container">
                        <p>{data.invoiced ? currencyFormatter(Number(data.invoiced)) : "-"}</p>
                    </div>
                </ListCell>),
            "budgeted_price": (
                <ListCell onlyDisplay={true}>
                    <div className="list-cell-container">
                        {['scheduled', 'automatic'].includes(data.type_name) ?
                            <div className="disabled-cellvalue"></div>
                            :
                            <p>{data.budgeted_price ? currencyFormatter(Number(data.budgeted_price)) : "-"}</p>}
                    </div>
                </ListCell>
            ),
            "selling_price": (
                <ListCell onlyDisplay={true}>
                    <div className="list-cell-container">
                        {['products', 'services'].includes(data.type_name) ?
                            <div className="disabled-cellvalue"></div>
                            :
                            <p>{data.selling_price ? currencyFormatter(Number(data.selling_price)) : "-"}</p>}
                    </div>
                </ListCell>
            ),
            "own_cost": (
                <ListCell onlyDisplay={true}>
                    <div className="list-cell-container">
                        {['products', 'services', 'scheduled', 'automatic'].includes(data.type_name) ?
                            <div className="disabled-cellvalue"></div>
                            :
                            <p>{data.own_cost ? currencyFormatter(Number(data.own_cost)) : "-"}</p>}
                    </div>
                </ListCell>
            ),
            "budgeted_vs_selling": (
                <ListCell onlyDisplay={true}>
                    <div className={cn("list-cell-container", Number(data.budgeted_vs_selling) < 0 && styles.negative)}>
                        {['products', 'services', 'scheduled', 'automatic'].includes(data.type_name) ?
                            <div className="disabled-cellvalue"></div>
                            :
                            <p>{data.budgeted_vs_selling ? currencyFormatter(Number(data.budgeted_vs_selling)) : "-"}</p>}
                    </div>
                </ListCell>
            ),
            "to_be_invoiced": (
                <ListCell onlyDisplay={true}>
                    <div className="list-cell-container"> {['scheduled', 'automatic'].includes(data.type_name) ?
                        <div className="disabled-cellvalue"></div>
                        :
                        <p>{data.to_be_invoiced ? currencyFormatter(Number(data.to_be_invoiced)) : "-"}</p>}
                    </div>
                </ListCell>
            ),
        }

        cells = {
            ...super.defineCells(),
            ...cells
        };

        return cells;
    }
}