import React from 'react';

import TaimerComponent from "../TaimerComponent";

/*context */
import { SettingsContext } from './../SettingsContext';

/* local components */
import * as CognitoHelpers from './../general/CognitoHelpers';
import DataHandler from './../general/DataHandler';
import * as MsalHelpers from './../general/MsalHelpers';
import OutlinedField from "./../general/OutlinedField";

/*material ui */
import CloseIcon from '@mui/icons-material/Close';
import { Button, Switch } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

/* styles */
import styles from './LoginView.module.scss';

/* jquery */
import $ from 'jquery';
import Link from '../general/Link';

import { Auth } from '@aws-amplify/auth';
import O365Logo from './images/o365.png';

const O365LoadingButton = props => <Button className={styles.ssoButton} disabled {...props}><img width="19" height="19" src={O365Logo} /><CircularProgress disableShrink size={18} className={"button-progress"} /></Button>
const O365LoginButton = props => {
    if (props.loading)
        return <O365LoadingButton/>
    else
        return <Button className={styles.ssoButton} {...props}><img width="19" height="19" src={O365Logo} />{props.children}</Button>
}
const ErrorAlert = props => (
    <div className={styles.errorAlert}>
        <p>{props.children}</p>
    </div>
)
const DialogCloseButton = props => (
    <IconButton
        className={styles.closeDialogButton}
        aria-label="close"
        {...props} >
        <CloseIcon />
    </IconButton>
)

class LoginView extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "login/LoginView");
        this.getToken = this.getToken.bind(this);

        this.state = {
            username: '',
            password: '',
            error: false,
            loading: false,
            awsUser: {},
            mfaChallenge: false,
            mfaCode: "",
            keyListener: this.startSession,
            o365Loading: false
        };

        this.validation = {
            account: React.createRef(),
            username: React.createRef(),
            password: React.createRef(),
            mfa: React.createRef(),
        }
        
    }
    invokeListener = () => {
        this.state.keyListener.call(this);
    }
    componentDidMount() {
        this.testSSOCode();
    }
    testSSOCode = async () => {
        const match = window.location.href.match(/code=([\w-]+)/);
        
        if (!match)
            return

        const tokens = await CognitoHelpers.handleCodeFlow(match[1])
        const awsUser = await Auth.currentAuthenticatedUser();
        this.setState({awsUser});

        if (localStorage.ssoAccount)
            return this.getSSOToken(awsUser, localStorage.ssoAccount)
        
        const environments = CognitoHelpers.getUserEnvironments(awsUser)

        if (environments.length === 1)
            return this.getSSOToken(awsUser, environments[0])

        this.renderSSOAccountSelect()
    }
    startSession = async () => {
        const account = this.props.account || this.account.value;
        const username = this.username.value.trim();
        const password = this.password.value;
        let hasErrors = false;
        this.setState({ loading: true });

        const fields = ["username", "password"];
        if (!this.props.account)
            fields.push("account");

        fields.forEach(e => {
            if (!this.validation[e].current.isValid())
                hasErrors = true;
        });

        if (hasErrors) {
            this.setState({ error: false, loading: false });
            return;
        }

        const useCognito = await CognitoHelpers.isCognitoUser(username, account);


        if (useCognito > 0 || this.props.cognitoSignIn)
            this.cognitoSignIn(username, password, account);
        else
            this.getToken(username, password, account);

    }
    cognitoSignIn = async (username, password, account) => {
        try {
            const awsUser = await Auth.signIn(username, password);
 
            if (awsUser.challengeName !== 'SOFTWARE_TOKEN_MFA')
                this.getCognitoToken(awsUser, this.props.account || this.account?.value);
            else
                this.setState({ awsUser, keyListener: this.cognitoVerifyMfa, mfaChallenge: true, error: false, account, loading: false });
        }
        catch (e) {
            console.log(e);
            this.setState({ error: true, loading: false });
        }
    }
    cognitoVerifyMfa = () => {
        const { awsUser, account, mfaCode } = this.state;
        this.setState({ loading: true });

        Auth.confirmSignIn(awsUser, mfaCode, awsUser.challengeName).then((user) => this.getCognitoToken(user, this.props.account || account, true)).catch( e => {
            console.error(e);
            this.setState({
                error: e.code,
                loading: false
            })
            // Token is not verified
        });
    }
    setDeviceRemembering = async () => {
        const { rememberDevice } = this.state;

        if (rememberDevice) 
            await CognitoHelpers.rememberDevice();
        else
            await CognitoHelpers.forgetDevice();
    }
    getSSOToken = (awsUser, account, setupPassword = undefined) => {
        DataHandler.post({url: "auth/cognito"}, {
            accessToken: awsUser.signInUserSession.accessToken.jwtToken,
            account,
            setupPassword
        }).fail((e) => {
            if (e.status === 403)
                this.renderSSOAccountSelect(account)
            
        } )
            .done(async e => {
                if (e.error === "PasswordConfirmationRequired")
                    return this.renderSSOPasswordConfirmation(setupPassword)

                this.setState({o365Loading: false});
                this.setSession(e);
            });
    }
    getCognitoToken = (awsUser, account, setDeviceRemembering = false) => {
        if (this.props.cognitoSignIn) {
            this.props.onSignIn instanceof Function && this.props.onSignIn(awsUser);
            return;
        }

        DataHandler.post({url: "auth/cognito"}, {
            accessToken: awsUser.signInUserSession.accessToken.jwtToken,
            account
        }).fail((e) => this.setState({error: "invalid_mfa", loading: false}) )
            .done(async e => {
                setDeviceRemembering && await this.setDeviceRemembering();
                this.setSession(e);
            });
    }
    getToken(username, password, account) {
        DataHandler.post({ url: "auth/login" }, { username, password, account})
            .fail((response) => {
                console.log(response);
                this.setState({ error: response?.responseJSON?.error || true, loading: false }); 
            })
            .done((e) => {
                this.setState({ error: false, loading: false });
                this.setSession(e);
            });
    }

    onboardingAuth(authcode) {
        const { account } = this.state;
        
        DataHandler.post({url: "auth/onboarding"}, {authcode, account})
            .fail(() => this.setState({error: "onBoardingError"}))
            .done(e => this.setSession(e));
    }

    igAuth(authcode) {
        DataHandler.post({url: "auth/ig"}, {authcode})
            .fail(() => this.setState({error: "onBoardingError"}))
            .done(e => this.setSession(e));
    }

    setSession = (response) => {
        this.clearIntercomLocalStorage();

        // Delete preview list state (could be from another install)
        delete localStorage['activePreviewList'];

        localStorage.setItem('taimerToken', response.token);
        localStorage.setItem('taimerPrivileges', JSON.stringify(response.privileges) );
        localStorage.setItem('taimerVersion',response.version);
        localStorage.setItem('taimerVersionId',response.version_id);
        localStorage.setItem('taimerAddons', JSON.stringify(response.addons) );
        localStorage.setItem('onboardingEnded',response.onboarding_ended);
        localStorage.setItem('taimerLang', response.userLang)
        this.context.functions.setTokenExpiration();
        
        let location = `/${response.account}/index.html`;
        
        if (window.location.href.indexOf('odin.') > -1 || window.location.href.indexOf(':8080') > -1 || window.location.href.indexOf('branches.dev.psa') > -1) 
            location = 'index.html';
        
        if (localStorage.lastView)
            window.location = location + `?${$.param(JSON.parse(localStorage.lastView))}`
        else
            window.location = location + "?module=dashboard&action=main&selectedTab=my-day";
    }

    loginSAML = () => {
        const account = this.props.account || this.account.value;
        window.location = "/react/api/auth/login_saml?saml=1&account=" + account;
    };

    changePage = (e, page) => {
        e.preventDefault();
        this.props.changePage(page);
    };

    clearIntercomLocalStorage = () => {
        Object.keys(localStorage).forEach(e => e.indexOf("intercom-state") > -1 && localStorage.removeItem(e));
    }
    loginOffice365 = () => {
        localStorage.setItem("ssoAccount", this.props.account || this.account.value)
        CognitoHelpers.loginOffice365()
    }
    SSOPasswordConfirm = () => {
        const { awsUser, password } = this.state
        const account = localStorage.ssoAccount || this.state.account;
        this.renderSSOPasswordConfirmation(password, true)
        this.getSSOToken(awsUser, account, password)
    }
    SSOAccountSelect = () => {
        const { awsUser, account } = this.state
        this.renderSSOAccountSelect(account, true)
        this.getSSOToken(awsUser, account)
    }
    O365login = async () => {
        this.setState({o365Loading: true});
        const awsUser = await MsalHelpers.signIn();
        
        if (!awsUser) 
            return this.setState({o365Loading: false});
        
        this.setState({awsUser});
        const account = this.props.account || this.account.value;
        if (account)
            return this.getSSOToken(awsUser, account)
    
        const environments = CognitoHelpers.getUserEnvironments(awsUser)
        if (environments.length === 1)
            return this.getSSOToken(awsUser, environments[0])

        this.renderSSOAccountSelect()
    }
    getErrorMessage = (error) => {
        const { account } = this.props;
        const { tr } = this;

        if (error === 'RC_INSTALLATION')
            return tr('This installation is a test installation. Please use https://pilot.psa.heeros.com to sign in.');
        else if(error === 'PRODUCTION_INSTALLATION')
            return tr('This installation is a production installation. Please use https://psa.heeros.com to sign in.');
        else if (error === "onBoardingError")
            return tr("Log in failed");
        else if (error === 'CodeMismatchException')
            return tr("Incorrect MFA code");
        else if (error === 'NORMAL_LOGIN_DISABLED')
            return tr("Using SSO to log in is mandatory for this environment. Please use the SSO button below to log in.");

        else if (account)
            return tr("Incorrect username or password");
        else
            return tr("Incorrect company ID, username or password");
    }
    closeSSODialog = () => {
        const { functions } = this.context;
        this.setState({o365Loading: false})
        functions.closeDialog()
        functions.cognitoSignOut()
    }
    renderSSOAccountSelect = (account = null, loading = false) => {
        const { functions } = this.context;
        const { tr } = this;
        const error = !loading && !!account;

        functions.showDialogContent(
            <div className={styles.ssoDialog}>
                <h3>{tr("We need a bit more information")}</h3>
                <p>{tr("To login with O365, you need to define your Account ID. You’ll find it in your invitation e-mail or from the admin user.")}</p>
                {error && <ErrorAlert>{tr("The given account ID is invalid")}</ErrorAlert>}
                <DialogCloseButton onClick={() => this.closeSSODialog()} />
                <OutlinedField 
                    data-testid="o365-company" 
                    noOnchangeValidation={true} 
                    className={styles.ssoField} 
                    onChangeOnEnter
                    onChange={(e, enter) => this.setState({ account: e.target.value }, () => enter && this.SSOAccountSelect())}
                    label={tr("Company ID")}
                    autoComplete="one-time-code"
                    autoFocus
                    value={account}
                    error={error} />
                <O365LoginButton loading={loading} onClick={() => this.SSOAccountSelect()}>{tr("Login with O365").toUpperCase()}</O365LoginButton>
            </div>
        , {onClose: (object, reason) => reason !== 'backdropClick' && this.closeSSODialog()});
    }
    setPasswordRef = (ref) => {
        this.ssoPassword = ref
    }
    renderSSOPasswordConfirmation = (setupPassword = null, loading = false) => {
        const { functions } = this.context;
        const { tr } = this;
        const error = !loading && !!setupPassword
        functions.showDialogContent(
            <div className={styles.ssoDialog}>
                <h3>{tr("We want to keep you secure")}</h3>
                <p>{tr("To finalize logging in with O365, please enter your PSA account password (found in your invitation e-mail) below. You only need to do this once.")}</p>
                {error && <ErrorAlert>{tr("Incorrect password")}</ErrorAlert>}
                <DialogCloseButton onClick={() => this.closeSSODialog()} />
                <OutlinedField 
                    data-testid="o365-password" 
                    className={styles.ssoField} 
                    onChangeOnEnter
                    onChange={(e, enter) => this.setState({ password: e.target.value }, () => enter && this.SSOPasswordConfirm())}
                    type="password"
                    label={tr("Password")}
                    autoFocus
                    error={error} />
                <O365LoginButton loading={loading} onClick={() => this.SSOPasswordConfirm()}>{tr("Login").toUpperCase()}</O365LoginButton>
            </div>
        , {onClose: (object, reason) => reason !== 'backdropClick' && this.closeSSODialog()});
    }
    renderPasswordChallenge = () => {
        const { state, tr } = this;
        const { username, password, error, loading, o365Loading } = state;
        const { account, message } = this.props;

        const isSamlTaimer = window.location.host == "saml.taimer.com";
        const errorMessage = this.getErrorMessage(error);

        return (
            <React.Fragment>
                <h1 className="login-page-header">{tr("Login")}</h1>
                {error && <ErrorAlert>{errorMessage}</ErrorAlert>}
                {message &&
                    <div>
                        <p>{message}</p>
                    </div>
                }
                {!account && <OutlinedField data-testid="company" InputProps={{pswdManagerProps: {ignore_last_pass: true}}} noOnchangeValidation={true} className={"login-field"} ref={this.validation.account} validation={["empty"]} inputRef={el => this.account = el} label={tr("Company ID")} autoFocus={!account} />}
                {
                    !isSamlTaimer && <OutlinedField autoComplete="email" data-testid="username" noOnchangeValidation={true} className={"login-field"} value={this.props.username || username} disabled={this.props.username !== undefined} onChange={(e) => this.setState({ username: e.target.value.trim() })} ref={this.validation.username} validation={["empty"]} inputRef={el => this.username = el} type="text" name="username" id="username" label={tr("Username")} autoFocus={account} />
                }
                {
                    !isSamlTaimer && <OutlinedField autoComplete="password" data-testid="password" noOnchangeValidation={true} className={"login-field"} value={password} onChange={(e) => this.setState({ password: e.target.value })} ref={this.validation.password} validation={["empty"]} inputRef={el => this.password = el} type="password" name="password" label={tr("Password")} />
                }
                {this.props.showSSO && <Button className="login-button sso-button" color="primary" onClick={() => this.loginSAML()} size="large">{tr("Single Sign-On")}</Button>}
                {
                    isSamlTaimer && <Button className={"login-button"} color="primary" onClick={() => window.location = 'https://psa.heeros.com/' + account} size="large">{tr("Use normal login").toUpperCase()}</Button>
                }
                {!isSamlTaimer && !loading &&
                    <Button data-testid="login-button" className={"login-button"} color="primary" onClick={() => this.startSession()} size="large">{tr("Login").toUpperCase()}</Button>
                }
                { !isSamlTaimer && loading &&
                    <Button className={"login-button"} color="primary" size="large" disabled><CircularProgress disableShrink size={18} className={"button-progress"} /></Button>
                }
                <div className={styles.divider}>&nbsp;</div>
                <O365LoginButton loading={o365Loading} onClick={() => this.O365login()}>{tr("Login with O365").toUpperCase()}</O365LoginButton>
                { !isSamlTaimer && <div className={"additional"}>
                        <span className={"forgot-password"}>
                            {tr("Forgot password")}? <Link data-testid="forgot-password-button" onClick={(e) => this.changePage(e, "forgotPassword")}>{tr("Click here")}</Link>
                        </span>
                    </div>
                }
            </React.Fragment>
        );
    }
    renderLoading = () => {
        return <div><img className='main-page-loading-indicator' src={require('../dashboard/insights/img/loading.svg').default} /></div>
    }
    renderMfaChallenge = () => {
        const { tr } = this;
        const { mfaCode, error, loading } = this.state;
        const errorMessage = this.getErrorMessage(error);
 
        return <>
            <h1 className="login-page-header">{tr("Multi-factor Authentication")}</h1>
            <p>{tr('Enter an MFA code to complete sign-in.')}</p>

            {error &&
                <div className="error-alert">
                    <p>{errorMessage}</p>
                </div>
            }

            <OutlinedField 
                data-testid="mfa" 
                noOnchangeValidation={true} 
                className={"login-field"} 
                value={mfaCode}
                autoFocus
                autoComplete="new-password"
                onKeyUp={(e) => this.setState({ mfaCode: e.target.value })}
                label={tr("MFA Code")} />

            <div>
                <Switch 
                    color="primary" 
                    onChange={(e) => this.setState({ rememberDevice: e.target.checked }) } />

                {tr('Remember this device')}
            </div>
            {!loading ?
                <Button data-testid="login-button" className={"login-button"} color="primary" onClick={() => this.cognitoVerifyMfa()} size="large">{tr("Submit").toUpperCase()}</Button> :
                <Button className={"login-button"} color="primary" size="large" disabled><CircularProgress disableShrink size={18} className={"button-progress"} /></Button>}
        </>

    }

    render() {
        const { mfaChallenge } = this.state;

        if (!mfaChallenge)
            return this.renderPasswordChallenge();
        else
            return this.renderMfaChallenge();
    }
}

export default LoginView;
