import React from "react";
import InsightBlock from "./InsightBlock";
import TaimerComponent from "../../TaimerComponent";
import {
  Grid as VirtualizedGrid,
  List as VirtualizedList,
  AutoSizer,
  ScrollSync,
} from "react-virtualized";
import "./InsightMonthlySplit.css";
import TaimerAvatar from "../../general/TaimerAvatar";
import moment from "moment";
import InsightSlider from "./InsightSlider";
import InsightDropDown from "./InsightDropDown";

const ROW_HEIGHT = 50;
const HEADER_HEIGHT = 44;
const LIST_HEIGHT = 400;
const LEFT_TABLE_WIDTH = 200;
const COLUMN_WIDTH = 100;
const RIGHT_TABLE_WIDTH = COLUMN_WIDTH;

const defaultMonths = () => {
  const today = moment();
  const arr = [];
  for (let i = 0; i < 12; i++) {
    const formatted = today.set("month", i);
    arr.push(formatted.format("MM-YYYY"));
  }
  return arr;
};
class InsightMonthlySplit extends TaimerComponent {
  static defaultProps = {
    months: defaultMonths(),
  };

  constructor(props, context) {
    super(props, context, "dashboard/insights/InsightMonthlySplit");
    this.header = React.createRef();
    this.grid = React.createRef();
    this.footer = React.createRef();
    this.state = {
      horizontalScrollBarWidth: 0,
      verticalScrollBarWidth: 0,
      monthTitles: this._parseMonths(props.months),
      secondaryMode:
        props.secondaryTabs &&
        props.secondaryTabs.length > 0 &&
        props.secondaryTabs[0].key,
      sliderData: { open: false, data: [], total: 0, title: "" },
      mode: props.tabs && props.tabs.length > 0 && props.tabs[0].key,
      tabs:
        props.tabs &&
        props.tabs.map((tab) => ({
          ...tab,
          action: () => this.setState({ mode: tab.key }),
        })),
      secondaryTabs:
        props.secondaryTabs &&
        props.secondaryTabs.map((tab) => ({
          ...tab,
          action: () => this.setState({ secondaryMode: tab.key }),
        })),
    };
  }

  componentDidUpdate = (oldProps, oldState) => {
    if (oldProps.months != this.props.months) {
      this.setState({
        monthTitles: this._parseMonths(this.props.months),
      }, () => this.header.current?.forceUpdate());
    }

    if (oldProps.data != this.props.data) {
      this.footer.current.forceUpdate();
    }

    if (oldState.mode != this.state.mode) {
      this.grid.current.forceUpdate();
      this.footer.current.forceUpdate();
    }
  };

  _parseMonths = (months) => {
    const {monthNames} = this.props;
    const monthTitles = months.map((month) => {
      const momentDate = moment(month, "MM-YYYY");
      return `${monthNames[momentDate.month()]} ${momentDate.format("YYYY")}`
    }
    );
    return monthTitles;
  };

  _openSlider = (data, title = null) => {
    const { showSlider } = this.props;

    showSlider && showSlider(this.state.mode, this.state.secondaryMode, data);
  };

  _renderValues = (values) => {
    const {
      functions: { presentCurrency },
    } = this.context;
    const { currency, unit, noValue } = this.props;
    return (
      <div className="text-container">
        <h2>{values ? values.count + (unit ? unit : "") : ""}</h2>
        {values && values.value !== undefined && !noValue && (
          <p>{presentCurrency(values.value, currency)}</p>
        )}
      </div>
    );
  };

  _renderRightTableFooter = () => {
    const { getTotal } = this.props;

    const totals = this._getData().totals.totals;
    const values = getTotal ? getTotal(totals, this.state.mode) : totals;

    return (
      <div
        // onClick={() => this._openSlider(values.slider)}
        style={{ flex: 1 }}
        className="cell no-border"
      >
        <div style={{ height: ROW_HEIGHT }} className="cell-content">
          {this._renderValues(values)}
        </div>
      </div>
    );
  };

  _gridCellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const { months, getValues } = this.props;

    const monthly = this._getData().data[rowIndex].monthly[months[columnIndex]];
    const values = getValues
      ? getValues(monthly, this.state.mode, rowIndex, months[columnIndex])
      : monthly;

    return (
      <div
        onClick={() => this._openSlider({ type: "cell", item: this._getData().data[rowIndex], month: months[columnIndex], columnIndex, key, rowIndex })}
        key={key}
        className={`cell ${
          columnIndex == months.length - 1 ? "no-border" : ""
        }`}
        style={style}
      >
        <div style={{ height: ROW_HEIGHT }} className="cell-content">
          {this._renderValues(values)}
        </div>
      </div>
    );
  };

  _footerCellRenderer = ({ columnIndex, key, style }) => {
    const { months, getColumnTotal } = this.props;

    const monthly = this._getData().totals[months[columnIndex]];
    const values = getColumnTotal
      ? getColumnTotal(monthly, this.state.mode, months[columnIndex])
      : monthly;

    return (
      <div
        onClick={() => this._openSlider({ type: "column", month: months[columnIndex] })}
        key={key}
        className={`cell ${
          columnIndex == months.length - 1 ? "no-border" : ""
        }`}
        style={style}
      >
        <div style={{ height: ROW_HEIGHT }} className="cell-content">
          {this._renderValues(values)}
        </div>
      </div>
    );
  };

  _headerCellRenderer = ({ columnIndex, key, style }) => {
    const { monthTitles } = this.state;
    return (
      <div
        key={key}
        className={`header-cell ${columnIndex % 2 == 0 ? "dark" : "light"}`}
        style={style}
      >
        {monthTitles[columnIndex]}
      </div>
    );
  };

  _getLeftCellContent = (item) => {
    const { secondaryMode } = this.state;
    if (!secondaryMode) {
      return (
        <div className="row">
          <TaimerAvatar id={item.users_id} name={item.users_name} />
          <div
            className="text-container"
          >
            <h2>{item.users_name}</h2>
            <p>{item.users_title}</p>
          </div>
        </div>
      );
    }
    switch (secondaryMode) {
      case "sales_agent":
        return (
          <div className="row">
            <TaimerAvatar id={item.users_id} name={item.users_name} />
            <div className="text-container">
              <h2>{item.users_name}</h2>
              <p>{item.users_title}</p>
            </div>
          </div>
        );
      case "account_manager":
        return (
          <div className="row">
            <TaimerAvatar id={item.users_id} name={item.users_name} />
            <div className="text-container">
              <h2>{item.users_name}</h2>
              <p>{item.account_name}</p>
            </div>
          </div>
        );
      case "account_group":
        return (
          <div className="row">
            <div style={{ marginLeft: 0 }} className="text-container">
              <h2>{item.name}</h2>
            </div>
          </div>
        );
      case "project_manager":
        return (
          <div className="row">
            <TaimerAvatar id={item.users_id} name={item.users_name} />
            <div className="text-container">
              <h2>{item.users_name}</h2>
            </div>
          </div>
        );
      case "account":
        return (
          <div className="row">
            <div style={{ marginLeft: 0 }} className="text-container">
              <h2>{item.customers_name}</h2>
            </div>
          </div>
        );
      case "sub_unit":
        return (
          <div className="row">
            <div style={{ marginLeft: 0 }} className="text-container">
              <h2>{item.customers_name}</h2>
              <p>{item.parent_name}</p>
            </div>
          </div>
        );
    }
  };

  _leftCellRenderer = ({ key, index, style }) => {
    const item = this._getData().data[index];
    return (
      <div key={key} className="cell user-cell" style={style}>
        <div style={{ height: ROW_HEIGHT }} className="cell-content">
          {this._getLeftCellContent(item)}
        </div>
      </div>
    );
  };

  _rightCellRenderer = ({ key, index, style }) => {
    const { months, getRowTotal } = this.props;

    const totals = this._getData().data[index].totals;
    const values = getRowTotal
      ? getRowTotal(totals, this.state.mode, index)
      : totals;

    return (
      <div
        onClick={() => this._openSlider({ type: "row", item: this._getData().data[index] })}
        key={key}
        className="cell"
        style={style}
      >
        <div style={{ height: ROW_HEIGHT }} className="cell-content">
          {this._renderValues(values)}
        </div>
      </div>
    );
  };

  _renderLeftTable = (onScroll, scrollTop) => {
    const { horizontalScrollBarWidth } = this.state;
    return (
      <div style={{ position: "relative" }}>
        <VirtualizedList
          scrollTop={scrollTop}
          className="no-outline no-scroll left-table"
          style={{
            borderLeft: "1px solid #eee",
            borderTop: "1px solid #eee",
            marginTop: HEADER_HEIGHT - 1,
            marginBottom: 0,
          }}
          width={LEFT_TABLE_WIDTH}
          height={this._getListHeight()}
          rowCount={this._getData().data.length}
          rowHeight={this._getRowHeight}
          rowRenderer={this._leftCellRenderer}
        />
        <div
          style={{
            height: ROW_HEIGHT,
            width: LEFT_TABLE_WIDTH,
            bottom: 0,
          }}
          className="footer-container"
        >
          <div className="left-footer">{this.tr("Total")}</div>
        </div>
      </div>
    );
  };

  _getData = () => {
    const { data, tabsMode } = this.props;
    const { mode, secondaryMode } = this.state;

    let found = null;

    if (tabsMode === "shared") { 
      found = data;
    } else if (tabsMode === "secondary")  {
      found = data[secondaryMode];
    } else if (mode && secondaryMode) {
      found = data[mode][secondaryMode];
    } else if (mode) {
      found = data[mode];
    } else if (secondaryMode) {
      found = data[secondaryMode];
    } else {
      found = data;
    }

    if (!found || !found.data) {
      found = {data: []};
    }

    if (!found.totals && data.totals) {
      found.totals = data.totals
    } else if (!data.totals) {
      data.totals = {};
    }

    return found;
  };

  _renderRightTable = (onScroll, scrollTop) => {
    const { horizontalScrollBarWidth, verticalScrollBarWidth } = this.state;
    return (
      <div className="right-container">
        <div
          style={{
            width: RIGHT_TABLE_WIDTH,
            height: HEADER_HEIGHT,
            left: -verticalScrollBarWidth,
          }}
          className="right-header"
        >
          {this.tr("Total")}
        </div>
        <VirtualizedList
          scrollTop={scrollTop}
          className="no-outline no-scroll right-table"
          style={{
            borderLeft: "1px solid #eee",
            marginTop: HEADER_HEIGHT,
            marginBottom: 0,
            marginLeft: -verticalScrollBarWidth,
          }}
          width={RIGHT_TABLE_WIDTH}
          height={this._getListHeight()}
          rowCount={this._getData().data.length}
          rowHeight={this._getRowHeight}
          rowRenderer={this._rightCellRenderer}
        />
        <div
          style={{
            height: ROW_HEIGHT,
            width: RIGHT_TABLE_WIDTH,
            left: -verticalScrollBarWidth,
            bottom: 0,
          }}
          className="footer-container"
        >
          <div className="right-footer">{this._renderRightTableFooter()}</div>
        </div>
      </div>
    );
  };

  _getRowHeight = ({ index }) => {
    if (index == this._getData().data.length - 1) {
      return ROW_HEIGHT * 2;
    }
    return ROW_HEIGHT;
  };

  _getListHeight = () => {
    return Math.min(
      this._getData().data.length * ROW_HEIGHT + ROW_HEIGHT,
      LIST_HEIGHT
    );
  };

  _renderGrid = (onScroll, scrollTop, scrollLeft) => {
    const { months } = this.props;
    const { verticalScrollBarWidth, horizontalScrollBarWidth } = this.state;
    return (
      <div className="grid">
        <AutoSizer disableHeight>
          {({ width }) => (
            <div>
              <VirtualizedGrid
                ref={this.header}
                scrollLeft={scrollLeft}
                className="no-scroll no-outline"
                cellRenderer={this._headerCellRenderer}
                columnCount={months.length}
                columnWidth={Math.max(
                  COLUMN_WIDTH,
                  (width - verticalScrollBarWidth) / months.length
                )}
                height={HEADER_HEIGHT}
                rowCount={1}
                rowHeight={HEADER_HEIGHT}
                width={width - verticalScrollBarWidth}
              />
              <VirtualizedGrid
                ref={this.grid}
                style={{ marginBottom: -horizontalScrollBarWidth }}
                onScrollbarPresenceChange={({ size, horizontal, vertical }) => {
                  const horizontalScrollBarWidth = horizontal
                    ? size
                    : 0;
                  const verticalScrollBarWidth = vertical
                    ? size
                    : 0;
                  this.setState({
                    horizontalScrollBarWidth,
                    verticalScrollBarWidth,
                  });
                }}
                cellRenderer={this._gridCellRenderer}
                columnCount={months.length}
                className="no-outline main-grid"
                onScroll={onScroll}
                columnWidth={Math.max(
                  COLUMN_WIDTH,
                  (width - verticalScrollBarWidth) / months.length
                )}
                height={this._getListHeight() + horizontalScrollBarWidth}
                rowCount={this._getData().data.length}
                rowHeight={this._getRowHeight}
                width={width}
              />
              <div
                style={{ bottom: 0 }}
                className="footer-container"
              >
                <VirtualizedGrid
                  ref={this.footer}
                  scrollLeft={scrollLeft}
                  className="no-scroll no-outline"
                  cellRenderer={this._footerCellRenderer}
                  columnCount={months.length}
                  columnWidth={Math.max(
                    COLUMN_WIDTH,
                    (width - verticalScrollBarWidth) / months.length
                  )}
                  height={ROW_HEIGHT}
                  rowCount={1}
                  rowHeight={ROW_HEIGHT}
                  width={width - verticalScrollBarWidth}
                />
              </div>
            </div>
          )}
        </AutoSizer>
      </div>
    );
  };

  _onCloseSlider = () => {
    this.setState({
      sliderData: { open: false, data: [], total: 0, title: "" },
    });
  };

  render() {
    const { currency } = this.props;
    const {
      sliderData,
      tabs,
      secondaryTabs,
      mode,
      secondaryMode,
      horizontalScrollBarWidth,
    } = this.state;
    return (
      <React.Fragment>
        <InsightSlider
          open={sliderData.open}
          label={sliderData.title}
          onClose={this._onCloseSlider}
          data={sliderData.data}
          sum={sliderData.total}
          columns={["project", "account", "user", "value", "funnel"]}
          sortKey={"value"}
          currency={currency}
        />
        <InsightBlock
          {...this.props}
          className="insight-monthly-split"
          filterComponents={
            <div className="tab-container">
              {secondaryTabs && (
                <InsightDropDown
                  style={{ marginRight: 10 }}
                  tabs={secondaryTabs}
                  selected={secondaryMode}
                />
              )}
              {tabs && <InsightDropDown tabs={tabs} selected={mode} />}
            </div>
          }
        >
          <ScrollSync>
            {({ onScroll, scrollLeft, scrollTop }) => (
              <div className="content">
                {this._renderLeftTable(onScroll, scrollTop)}
                {this._renderGrid(onScroll, scrollTop, scrollLeft)}
                {this._renderRightTable(onScroll, scrollTop)}
                <div
                  style={{
                    height: ROW_HEIGHT,
                    bottom: 0,
                  }}
                  className="footer-shadow"
                />
              </div>
            )}
          </ScrollSync>
        </InsightBlock>
      </React.Fragment>
    );
  }
}

export default InsightMonthlySplit;
