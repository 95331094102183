import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const styles = theme => ({
	root: {
		width: '100%',
		height: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
		overflow: 'auto',
	},
});

class ScrollList extends Component {
	constructor(props) {
	  super(props);

	  this.refSelected = React.createRef();
	}

	onClick = (item) => {
		const { name } = this.props;

		this.props.onChange && this.props.onChange({
			target: {
				name,
				value: item.value
			}
		});
	}

	componentDidMount = () => {
		this.refSelected.current && this.refSelected.current.scrollIntoView({block: 'center', inline: 'center'});
	}
	
	componentDidUpdate = (prevProps, prevState) => {
		//this.refSelected.current && this.refSelected.current.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
	}	

	render() {
		const { classes, options, value, noTab } = this.props;

		return (
			<div className={classes.root}>
				<List dense>{
					options.map(x =>
						<ListItem classes={{selected: 'selectedScrollListItem'}} tabIndex={noTab && -1} key={x.value} button selected={value === x.value} onClick={() => this.onClick(x)}>
							<span ref={value === x.value && this.refSelected}>{x.label}</span>
						</ListItem>
					)
				}
				</List>
			</div>
		);
	}
}

ScrollList.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ScrollList);