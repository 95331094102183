import React, { Component } from 'react';

/* local components */
import TaimerComponent from '../../TaimerComponent';
import DataList from '../../general/DataList';
import FilterDropdown from '../../general/FilterDropdown';
import DataHandler from '../../general/DataHandler';
import LoaderButton from "../../general/LoaderButton";

/* material ui */
import Button from '@mui/material/Button';
import { Warning as WarningIcon, InfoOutlined } from '@mui/icons-material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

/* context */
import { SettingsContext } from './../../SettingsContext';

/* css */
// import './Delete.css';

class Delete extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
        ...this.props,
        onCancel: () => {},
        onConfirm: () => {},
        confirmButtonClass: "red",
        confirmButtonText: "DELETE",
        confirmDisabled: false,
        wider: false,
        loading: false,
        hideWarningIcon: false,
        extraContent: undefined,
        warning: () => {},
        close: () => {}
    }

    constructor(props, context) {
        super(props, context, "dialogs/mass_operations/Delete");
    }

    cancel = () => {
        this.props.onCancel(); 
    }

    confirm = () => {
        this.props.onConfirm();
    }

    render() {
        const { header, warning, close, confirmButtonText, confirmDisabled, confirmButtonClass, hideWarningIcon, loading, translatedConfirmButtonText, cancelButtonText, cancelButtonClass } = this.props;

        return (
            <div className={`delete main implementation ${this.props.wider ? "wider" : ""}`}>
                <DialogTitle className="title">{!hideWarningIcon && <WarningIcon />}{header}</DialogTitle>
                <DialogContent className="content">
                    <DialogContentText data-testid="dialog_content">{warning(this.props)}</DialogContentText>
                    {this.props.infoText && <div className="info" data-testid="dialog_infotext"><InfoOutlined className={"infoIcon"} /> <span>{this.props.infoText}</span></div>}
                    {this.props.extraContent && this.props.extraContent()}
                </DialogContent>
                <DialogActions className="buttons">
                    <Button 
                        data-testid="button-cancel"
                        className={cancelButtonClass || "cancel-delete"}
                        onClick={(e) => {
                            this.cancel();
                            close();

                            e.stopPropagation();
                        }} 
                        variant="text">
                        {cancelButtonText ? cancelButtonText : this.tr('CANCEL')}
                    </Button>
                    <LoaderButton
                        data-testid="button-delete"
                        loading={loading} 
                        notAllowed={confirmDisabled} 
                        onClick={(e) => {
                            this.confirm();

                            e.stopPropagation();
                        }} 
                        variant={"outlined"}
                        className={confirmButtonClass ? confirmButtonClass : "red"}
                        text={translatedConfirmButtonText ? translatedConfirmButtonText : this.tr(confirmButtonText ? confirmButtonText : 'DELETE')}
                    />
                </DialogActions>
            </div>
        );
    }
}

export default Delete;
