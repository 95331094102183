import TaimerComponent from '../TaimerComponent';
import { prefixLink } from '../helpers';
import Link from './../general/Link';

interface Props {
    value: string;
    url: string;
    data: object;
    colDef: {
      field: string;
    };
    node: {
      field: string;
      group: boolean;
      footer: boolean;
      expanded: boolean;
      aggData: object;
      allLeafChildren: {data}[];
    };
    column: {colId: string};
    groupedDataValue?: string | number;
}

export default class TextLinkCellRenderer extends TaimerComponent<Props> {
  render() {
    const data = this.props.groupedDataValue 
      ? this.props.groupedDataValue 
      : this.props.data && this.props.data[this.props.colDef.field];

    const str = data !== undefined ? prefixLink(String(data)): '';

    if (!str.startsWith('https://') && !str.startsWith('http://')) {
      return str;
    }
    return <Link url={str}>{str}</Link>
  }
}
