import React from 'react';
import cn from 'classnames';

/* css */
import './ActivitiesInsight.css';

import _ from 'lodash';
import DataHandler from './../../../general/DataHandler';
import ContentBlock from '../../../general/ContentBlock';
import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from '../../../../src/SettingsContext';
import { getActivityTypes } from './../../../Data';
import ActivitiesInsightBox from './ActivitiesInsightBox';
import ActivitiesInsightList from './ActivitiesInsightList';
import ActivitiesInsightGraph from './ActivitiesInsightGraph';
import ActivitiesInsightFilter from './ActivitiesInsightFilter';
import ActivitiesInsightTypeView from './ActivitiesInsightTypeView';
import NoPermissionOverlay from '../../../overlays/NoPermissionOverlay';
import Utils from "../../../general/Utils";

import Loading from '../img/loading.svg';
import PageTopSection from '../../../general/PageTopSection';
import InsightsBase from '../InsightsBase';
import { format } from 'date-fns';

/**
 * Main component of overviews activities insight
 * 
 */
class ActivitiesInsight extends TaimerComponent {
	static contextType = SettingsContext;
	constructor(props, context) {
		super(props, context, "dashboard/insights/activities/ActivitiesInsight");
		this.state = {
			loading: false,
			company: 0,
			activityTypes: [],
			cleanActivityTypes: [],
			activities: false,
			hasWritePermission: false,

			filterData: {},

			// unchecked
			dialogData: false,
			companies: [],
			pageData: {
				page: 1,
				perpage: 30,
				pageCount: 1,
				activitiesCount: 0,
				sortby: "created",
				sortasc: false,
			},
			datasets: [
				//Done
				{
					key: 'done',
					borderColor: '#4FE3C2',
					pointBackgroundColor: "#4FE3C2",
					type: 'bar',
					borderDash: [5, 3],
					borderWidth: 2,
					backgroundColor: "#4FE3C2"
				},
				//Due
				{
					key: 'due',
					borderColor: '#FBB824',
					pointBackgroundColor: "#FBB824",
					type: 'bar',
					borderDash: [5, 3],
					borderWidth: 2,
					backgroundColor: "#FBB824"
				},
				//Overdue
				{
					key: 'overdue',
					borderColor: '#F7273D',
					pointBackgroundColor: "#F7273D",
					type: 'bar',
					borderDash: [5, 3],
					borderWidth: 2,
					backgroundColor: "#F7273D"
				}
			],
			boxdata: {
				total: 0,
				done: 0,
				due: 0,
				overdue: 0,
			}
		}

		this.newIcons = Utils.importAllIcons(require.context('../../../settings/img/Activity_icons/', false, /\.(png|jpe?g|svg)$/));
		this.refInsightBase = React.createRef();
	}

	componentDidMount() {
		super.componentDidMount();
		window.addEventListener("activitySaved", this._updateActivitiesList);
	}


	_updateActivitiesList = () => {
		setTimeout(() => {
			if (!this.state.activities) return;
			this.refreshData();
		}, 1000)
	};



	componentWillUnmount() {
		super.componentWillUnmount();
		window.removeEventListener("activitySaved", this._updateActivitiesList);

	}

	/**
	 * Updates pageData state of pageData
	 *
	 */
	onListSettingsChange = (pageData) => {
		this.setState({ pageData: { ...this.state.pageData, ...pageData } }, () => this.getData(false, true));
	}

	

	refreshCompanyData = async (company) => {
		const activityTypes = await getActivityTypes(company);

		const defaultNameField = Utils.getNameFieldByUserLang(this.context.userObject.language, 'name');

		//Removes excess information from activitytypes 
		const cleanActivityTypes = activityTypes.map((item) => ({ value: item.id, label: item[defaultNameField] || item.name }));

		cleanActivityTypes.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1);

		this.setState({ activityTypes, cleanActivityTypes });
	}

	refreshData = (filters = false) => {
		return this.getData(filters, false);
	}

	getData = async (filters = false, isPageChange = false) => {
		this.setState({ loading: true });

		if (filters === false)
			filters = this.refInsightBase.current.getFilters();

		if (filters.company != this.state.company) {
			await this.refreshCompanyData(filters.company);
		}

		// May change in refreshCompanyData
		const { activityTypes, datasets } = this.state;

		const data = await DataHandler.post({ url: `activities/activities` }, {
			company: [filters.company],
			startDate: format(filters.dateRange.startDate, 'YYYY-MM-DD', new Date()),
			endDate: format(filters.dateRange.endDate, 'YYYY-MM-DD', new Date()),
			targetedto: filters.targetedto,
			team: filters.teams,
			createdby: filters.createdby,
			account: filters.customers,
			status: filters.status,
			type: filters.type,
			page: this.state.pageData.page,
			perpage: this.state.pageData.perpage,
			sortasc: this.state.pageData.sortasc,
			sortby: this.state.pageData.sortby,
			onlyListData: isPageChange,
			enterprise_groups: filters.enterprise_groups,
		});

		const {
			listActivities,
			boxdata,
			haswritepermission: hasWritePermission,
			activitiesCount,
			pageCount,
			labels,
			datasets: datasetsData,
			typeviewdata,
		} = data;

		const pageData = {
			...this.state.pageData,
		};

		pageData.activitiesCount = activitiesCount ?? pageData.activitiesCount;
		pageData.pageCount = pageCount ?? pageData.pageCount;

		// Add Icons, row key
		listActivities.forEach((row, i) => {
			const activityType = activityTypes.find((type) => type.id === row.type_id) || {};
			row.icon = activityType.icon || "";
			if (!activityType.old_icon_set)
				row.icon = this.newIcons[row.icon];

			row.key = row.projects_id ? "p" + row.id : "c" + row.id;

			if (row.targeted_to_company < 1)
				row.targeted_to = `${row.targeted_to} (${this.tr("freelancer")})`;
			if (row.created_by_company < 1)
				row.created_by = `${row.created_by} (${this.tr("freelancer")})`;
			return row;
		});

		if (isPageChange) {
			this.setState({
				loading: false,
				hasWritePermission,
				activities: listActivities
			});

			return;
		}

		// 	Full Update
		datasets.forEach((set, i) => {
			set.data = _.values(datasetsData[i].data);
		});

		datasets[0]['label'] = this.tr('Done');
		datasets[1]['label'] = this.tr('Due');
		datasets[2]['label'] = this.tr('Overdue');

		const defaultNameField = Utils.getNameFieldByUserLang(this.context.userObject.language, 'name');

		typeviewdata.forEach((row, i) => {
			try {
				const item = activityTypes.find(i => i.id == row.type_id);

				row.icon = item.icon;
				row.name = item[defaultNameField] || item.name;

				if (!item.old_icon_set)
					row.icon = this.newIcons[item.icon];


			} catch (err) {
			}
		});

		const update = {
			loading: false,
			hasWritePermission,
			activities: listActivities,
			boxdata,
			pageData,
			labels,
			datasets,
			typeviewdata,
		};

		this.setState(update);
	}

	/**
	 * Renderer of ActivitiesInsight
	 *
	 */
	render() {
		const { loading, hasWritePermission } = this.state;
		const { functions: { hasPrivilege }, taimerAccount: { hasEnterpriseGroups } } = this.context;

		if (!hasPrivilege("dashboard", "activities_read"))
			return <NoPermissionOverlay />

		let currentCompany = 0;
		if (this.refInsightBase.current) {
			currentCompany = this.refInsightBase.current.getCurrentCompany();
		}
		
		return (<InsightsBase
				ref={this.refInsightBase}
				noHeader={this.props.noHeader}
				loaded={!loading}
				onRequestData={this.refreshData}
				className={cn("insight-activities")}
				// savedQueryName="activities"
				rightGroup="dashboard"
				right="activities_read"
				dropFilters={[]}
				advancedFilters={[]}
				useSearchButton
				defaultFilters={{
					targetedto: [],
					teams: [],
					createdby: [],
					customers: [],
					status: [],
					type: [],
					enterprise_groups: [],
				}}
				autocompleteParams={{
					usersType: 'activity',
				}}
				insightFilterProps={{
					showFiscalSelector: false,
					disableAdvancedSearchDimensions: true,
					disableCustomFields: true,
					customFilters: [
						{
							name: 'teams',
							type: 'multiselect',
							label: this.tr('Teams'),
							optionsSource: 'teams',
						},
						{
							name: 'targetedto',
							type: 'multiselect',
							label: this.tr('Targeted to'),
							optionsSource: 'users_read',
						},
						{
							name: 'createdby',
							type: 'multiselect',
							label: this.tr('Created by'),
							optionsSource: 'users_write',
						},
						{
							name: 'customers',
							type: 'multiselect',
							label: this.tr('Account'),
							optionsSource: 'customers',
						},
						{
							name: 'status',
							type: 'multiselect',
							label: this.tr('Status'),
							options: [
								{value: "1", label: this.tr("Done")},
								{value: "2", label: this.tr("Due")},
								{value: "3", label: this.tr("Overdue")},
							]
						},
						{
							name: 'type',
							type: 'multiselect',
							label: this.tr('Type'),
							options: this.state.cleanActivityTypes,
						},
						hasEnterpriseGroups ? 
						{
							name: 'enterprise_groups',
							type: 'multiselect',
							label: this.tr('Enterprise group'),
							optionsSource: 'enterprise_groups',
							returnAll: true
						} : undefined,
					]
				}}
				monthRange={[-10, 1]}
			>
				<div id="insights-activities-box-container" className={cn(loading && "insights-loading")}>
					<ActivitiesInsightBox
						classNameBoxHeader="box-header"
						classNameBox="content-block-box"
						boxdata={this.state.boxdata} />
				</div>
				<div id="insights-activities-graph-container" className={cn(loading && "insights-loading")}>
					<ContentBlock filterComponents={this.filterComponents} buttonTitle={this.tr("Activities Overview")} wrapperId="insights-activities-graph-main" wrapperClass="activitiesgraphwrapper">
						<ActivitiesInsightGraph
							labels={this.state.labels}
							datasets={this.state.datasets} />
					</ContentBlock>
				</div>
				<div id="insights-activities-typeview-container" className={cn(loading && "insights-loading")}>
					<ContentBlock buttonTitle={this.tr("Activities by Type")} wrapperId="insights-activities-typeview-main" wrapperClass="activitiestypeviewrapper" noPadding={true}>
						<ActivitiesInsightTypeView
							labels={this.state.labels}
							typeviewdata={this.state.typeviewdata} />
					</ContentBlock>
				</div>
				<div id="insights-activities-list-container" className={cn(loading && "insights-loading")}>
					<ContentBlock buttonTitle={this.tr("Activities List")} wrapperId="insights-activities-list-main" wrapperClass="activitieslistwrapper" noPadding={true}>
						<ActivitiesInsightList
							pageData={this.state.pageData}
							activities={this.state.activities || []}
							getActivityData={this.refreshData}
							showPageSelector={true}
							height={"545"}
							company={currentCompany}
							has_write_permission={hasWritePermission}
							onListSettingsChange={this.onListSettingsChange} />
					</ContentBlock>
				</div>
			</InsightsBase>);
	}
}

export default ActivitiesInsight;
