import getColumnType, { ColumnDefinition } from './ColumnTypes';

type Params = {
    checkPrivilege: (group: string, perm: string, company?: number) => boolean;
    tr: (text, replacers?: Record<string, string>) => string;
}

export default function getUserColumns(prefix: string, params: Params, showSensitiveInfo = false) { //TODO käännökset
    const { checkPrivilege } = params;

    const columns: ColumnDefinition[] = [
        {
            field: prefix + '_id',
            headerName: prefix + ' id',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !checkPrivilege('admin', 'admin', r.user_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: prefix,
            headerName: prefix,
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !checkPrivilege('admin', 'admin', r.user_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: prefix + '_superior',
            headerName: prefix + '_superior',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !checkPrivilege('admin', 'admin', r.user_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: prefix + '_teams',
            headerName: prefix + '_teams',
            ...getColumnType('multi'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: prefix + '_pro_title',
            headerName: prefix + '_pro_title',
            ...getColumnType('multi'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: prefix + '_type',
            headerName: prefix + '_type',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas: [
                    { id: 1, name: "user" },
                    { id: 2, name: "Freelancer" },
                ],
                tr: params.tr,
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: prefix + '_locked',
            headerName: 'User status',
            ...getColumnType('status', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 0, "name": "Active", color: "#20C6A1"},
                    {id: 1, "name": "Locked", color: "#f52b2b"},
                ],
                tr: params.tr,
            },
        },
        ...(showSensitiveInfo ? [
            {
                field: prefix + '_employee_start',
                headerName: prefix + '_employee_start',
                ...getColumnType('date', null, prefix + '_employee_start'),
            },
            {
                field: prefix + '_employee_end',
                headerName: prefix + '_employee_end',
                ...getColumnType('date', null, prefix + '_employee_end'),
            }
        ] : []),
    ]
    return columns;
}

            
