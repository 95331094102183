import customTooltips from '../../general/ChartTooltip';

const InsightChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxWidth: 15,
        padding: 20,
        fontSize: 12,
        usePointStyle: true
      }
    },
    tooltip: {
      enabled: false,
      external: customTooltips,
      mode: "index",
      titleFontSize: 12,
      titleFontStyle: "bold",
      titleMarginBottom: 15,
      bodyFontSize: 11,
      bodyFontColor: "rgba(255,255,255,0.7)",
      bodySpacing: 8,
      caretSize: 0,
      xPadding: 20,
      yPadding: 20,
      intersect: false
    },
  },
  maintainAspectRatio: false,
  scales: {
    y: {
        grid: {
          color: "rgba(0,0,0,0.04)",
          zeroLineColor: "rgba(0,0,0,0.04)",
          drawBorder: false
        },
        drawBorder: false,
        beginAtZero: true,
        ticks: {
          font: {
            size: 12,
            weight: 'bold',
            padding: 15
          },
        }
      },
    x: {
        offset: true,
        stacked: true,
        ticks: {
          padding: 10,
          font: {
            size: 12,
          },
        },
        grid: { display: false, drawBorder: false }
      }
  }
};

export default InsightChartOptions;
