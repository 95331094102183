import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import MyDay from '../../dashboard/my_day/MyDay';
import ProfitLoss from '../../charts/ProfitLoss';
import HoursInsight from '../../dashboard/insights/hours/HoursInsight';
import InvoicingInsight from '../../dashboard/insights/invoicing/InvoicingInsight';
import SalesInsight from '../../dashboard/insights/sales/SalesInsight';
import ActivitiesInsight from '../../dashboard/insights/activities/ActivitiesInsight';
import GoalsInsight from '../../dashboard/insights/goals/GoalsInsight';
import VersionContentManager from '../../general/VersionContentManager';

class Dashboard extends TaimerComponent<any> {
    tabs;
    constructor(props, context) {
        super(props, context, 'navigation/pages/Dashboard');
        this.tabs = VersionContentManager.getViewTabs(this.namespace).map((tab) => ({ ...tab, label: this.tr(tab.label) }));
    }

    getAppcuesFlow = () => {
        switch (this.context.versionId) {
            case 10:
            case 11:
                return this.context.userObject.language == 'fi' ? '4e0208a7-acd7-4263-815e-a9652d795512' : 'b6c4f420-dd5d-454e-a56e-4e9a2553e674';
            case 12:
                return this.context.userObject.language == 'fi' ? 'a1153437-2e9a-4c5f-ab98-2644280c36d4' : '1a84d9bc-5ed0-4050-91a5-4ece302067f6';
            default:
                return this.context.userObject.language == 'fi' ? 'ab5afa28-e476-4677-82a4-ca6e758653bd' : '0b4792b6-ac49-400b-92e9-4d72cd476204';
        }
    };

    getTabInfoButtonProps = (selectedTab) => {
        switch (selectedTab) {
            case 'my-day':
                return {
                    title: this.tr('How My Day works'),
                    methods: {
                        article:
                            this.context.userObject.language == 'fi'
                                ? 'https://psahelpcenter.heeros.com/hc/fi/articles/8079556478610'
                                : 'https://psahelpcenter.heeros.com/hc/en-us/articles/8079556478610',
                        flow: this.getAppcuesFlow(),
                    },
                };
            // case 'profit-loss':
            //     return {
            //         title: this.tr('How Profit & Loss works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8079821149714' : 'https://taimersupport.zendesk.com/hc/articles/8079821149714',
            //         },
            //     };
            // case 'hours-overview':
            //     return {
            //         title: this.tr('How Hours Dashboard works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8079307977746' : 'https://taimersupport.zendesk.com/hc/articles/8079307977746',
            //         },
            //     };
            // case 'invoicing-overview':
            //     return {
            //         title: this.tr('How Invoicing Dashboard works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8079459133714' : 'https://taimersupport.zendesk.com/hc/articles/8079459133714',
            //         },
            //     };
            // case 'leads-overview':
            //     return {
            //         title: this.tr('How Leads Dashboard works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8079218162194' : 'https://taimersupport.zendesk.com/hc/articles/8079218162194',
            //         },
            //     };
            // case 'deals-overview':
            //     return {
            //         title: this.tr('How Deals Dashboard works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8079326417554' : 'https://taimersupport.zendesk.com/hc/articles/8079326417554',
            //         },
            //     };
            // case 'activities-overview':
            //     return {
            //         title: this.tr('How Activities Dashboard works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8081476949522' : 'https://taimersupport.zendesk.com/hc/articles/8081476949522',
            //         },
            //     };
            // case 'goals-overview':
            //     return {
            //         title: this.tr('How Goals Dashboard works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8079654639634' : 'https://taimersupport.zendesk.com/hc/articles/8079654639634',
            //         },
            //     };
            default:
                return undefined;
        }
    };

    render() {
        return (
            <WithTabs
                offsetTop={10}
                tabs={this.tabs}
                selectedTab={this.props.selectedTab}
                selectedSubTab={this.props.selectedSubTab}
                tabsAlwaysVisible
                getTabInfoButtonProps={this.getTabInfoButtonProps}
            >
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'my-day':
                            return <MyDay {...this.props} />;
                        case 'profit-loss':
                            return <ProfitLoss {...this.props} />;
                        case 'hours-overview':
                            return <HoursInsight {...this.props} />;
                        case 'invoicing-overview':
                            return <InvoicingInsight {...this.props} />;
                        case 'leads-overview':
                            return <SalesInsight {...this.props} mode="leads" />;
                        case 'deals-overview':
                            return <SalesInsight {...this.props} mode="deals" />;
                        case 'activities-overview':
                            return <ActivitiesInsight {...this.props} />;
                        case 'goals-overview':
                            return <GoalsInsight {...this.props} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default Dashboard;
