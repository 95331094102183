import { ReactComponent as actions } from './ActionIcons/actions.svg';
import { ReactComponent as activity } from './ActionIcons/activities.svg';
import { ReactComponent as my_profile } from './ActionIcons/my_profile.svg';
import { ReactComponent as customer } from './NavIcons/customers.svg';
import { ReactComponent as project } from './NavIcons/leads.svg';
import { ReactComponent as expenses } from './NavIcons/expenses.svg';
import { ReactComponent as travel } from './NavIcons/travel.svg';
import { ReactComponent as task } from './ActionIcons/Task.svg';
import { ReactComponent as help } from './ActionIcons/help.svg';
import { ReactComponent as contact} from './ActionIcons/Contact.svg';
import { ReactComponent as faq } from './ActionIcons/faq.svg';
import { ReactComponent as workhours } from './NavIcons/workhours.svg';
import { ReactComponent as chat } from './ActionIcons/chat_new.svg';
import { ReactComponent as reports } from './ActionIcons/reports.svg';
import { ReactComponent as settings } from './ActionIcons/settings.svg';
import { ReactComponent as share } from './ActionIcons/share.svg';
import { ReactComponent as milestone } from './ActionIcons/Milestone.svg';
import { ReactComponent as bill} from './ActionIcons/Bill.svg';
import { ReactComponent as product } from './ActionIcons/AddProduct.svg';
import { ReactComponent as invoice } from './NavIcons/received_invoices.svg';
import latestUpdate  from './NavIcons/update.svg';
import knowledgeBase from './NavIcons/knowledge_base.svg';
import community from './NavIcons/community.svg';
import helpChat from './NavIcons/chat_with_us.svg';
import collaborate from './NavIcons/boards.svg';
import { ShoppingCart as purchaseorders } from '@mui/icons-material';

export default {
    account: customer,
    activity: activity,
    project: project,
    expenses: expenses,
    travel,
    task,
    workhours,
    contact,
    bill,
    milestone,
    chat,
    product,
    help,
    faq,
    reports,
    actions,
    settings,
    share,
    invoice,
    knowledgeBase,
    community,
    helpChat,
    collaborate,
    my_profile,
    latestUpdate,
    purchaseorders,
    "default": customer
}