import React from 'react';

import Select, { components } from 'react-select';
import CreatableSelect from "react-select/creatable";

import LabelField from "./LabelField";
import OutlinedField from "./OutlinedField";

import DataList from "../general/DataList";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import clone from "lodash/clone";

const DropdownIndicator = (props) => {
  return components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};

const IndicatorsContainer = (props) => {
  return (
    <div style={{}}>
      <components.IndicatorsContainer {...props}/>
    </div>
  );
};

class ToggleableLabelField extends React.Component {
    static defaultProps = {
        error: false,
        autoFocus: false,
    };
    
    
    constructor(props) {
		super(props);

		this.state = {
			focused: false
		};

		this.inputRef = React.createRef();
		this.focus    = this.focus.bind(this);
  }
  
  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus();
    }
  }


	focus() {
        if(this.inputRef === undefined && this.inputRef.current === undefined)
            return;

		this.inputRef.current.focus();
	}


	render() {
		const editClassName  = this.props.disabled ? "disabled" : "";
        const focusClassName = this.state.focused ? "focused" : "";
        const errorClassName = this.props.error ? "error" : "";
		let className 	 	 = this.props.className !== undefined ? this.props.className : "";
		const editorType 	 = this.props.hasOwnProperty("editorType") && this.props.editorType !== null && this.props.editorType !== undefined ? this.props.editorType : OutlinedField;
		let value 			 = this.props.value;

        let selectProps = {};

        if(editorType === CreatableSelect || editorType === Select || this.props.limitEditorType === CreatableSelect)
			selectProps.components = this.props.components || { DropdownIndicator: DropdownIndicator, IndicatorsContainer: IndicatorsContainer };

		if(typeof this.props.value !== "object" && (editorType === CreatableSelect || editorType === Select || this.props.limitEditorType === CreatableSelect)) {
			selectProps.value 	   		= this.props.options.find(option => option.id == this.props.value) || false;
      if (this.props.showStringValue) {
        selectProps.placeholder = this.props.value;
      }
			selectProps.classNamePrefix = "reactSelect";
			className 				   += " paperLabelField";
        }

        // Please don't touch my house of cards.
        let props = clone(this.props);

        delete props.className;
        // ...

		return <LabelField
      components={this.props.components}
			onFocus={e => this.setState({ focused: true })} 
			onBlur={e => this.setState({ focused: false })} 
			inputRef={this.inputRef} 
            // className={`${className} ${editClassName} ${focusClassName} ${errorClassName}`} 
            className={`${className} ${editClassName} ${errorClassName}`}
			value={value} 
			editorType={editorType}
			onClick={this.props.onClick || undefined}
            {...props} 
            {...selectProps} />;
	}
}

export default ToggleableLabelField;
