import React from 'react';
import TaimerComponent from '../../TaimerComponent';

import { SettingsContext } from '../../SettingsContext';

import './AccountingAccounts.scss';

import DataHandler from "../../general/DataHandler";

import SettingRow from "../../list/rows/SettingRow";
//import List from "../../list/List";
import { SettingsList as List } from "../../list/lists/SettingsList";
import ListCell from "../../list/ListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import RadioButtonCell from "../../list/cells/RadioButtonCell";
import { isCompanyUsingInvoiceCountryCode } from '../../helpers';
import PropsOnlyListRow from "../../list/PropsOnlyListRow";
import MassDialog from '../../dialogs/mass_operations/CoreDialog';

import Button from '@mui/material/Button';
import Switch from "@mui/material/Switch";

import TrashCanIcon from "@mui/icons-material/Delete";
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { Tooltip } from '@mui/material';

class VatCodeRow extends PropsOnlyListRow {

    createNew = () => {
        if (!this.validateAllFields()) {
            return;
        }
        const { data } = this.props;
        this.props.rowProps.onCreate(data)
    }

    getRequiredFields = () => {
        const { rowProps: { isCompanyUsingCountryCode, showVatCode, showIntegrationID } } = this.props;
        const requiredFields =  ['name', 'value'];
        if (isCompanyUsingCountryCode()) {
            requiredFields.push('country_code');
        }
        if (showVatCode) {
            requiredFields.push('vat_code');
        }
        if (showIntegrationID) {
            requiredFields.push('integration_id');
        }
        return requiredFields;
    }

    validateField = (name, value) => {
        const { rowProps, rowProps: { enqueueSnackbar, errorMessages, showVatCode } } = this.props;
        const requiredFields = this.getRequiredFields();
        let hasError = false;
        const vatcodeIsNumeric = showVatCode && rowProps.integration != "netvisor";

        if (requiredFields.find(f => f == name) && (!value && value !== 0)) {
            enqueueSnackbar && enqueueSnackbar(errorMessages?.fieldCannotBeEmpty, {
                variant: "error",
            });
            hasError = true;        
        }
        else if (name == "vat_code" && vatcodeIsNumeric && !this.validateInteger(value)) { 
            enqueueSnackbar && enqueueSnackbar(errorMessages?.invalidVatcode, {
                variant: "error",
            });
            hasError = true;
        }
        // Commented out because we have to support VATs with decimals
        /* else if (name == "value" && !this.validateInteger(value)) {
            enqueueSnackbar && enqueueSnackbar(errorMessages?.invalidValue, {
                variant: "error",
            });
            hasError = true;
        } */
        return !hasError;
    }

    validateAllFields = () => {
        const { data, rowProps, rowProps: { enqueueSnackbar, errorMessages, showVatCode } } = this.props;
        let hasErrors = false;
        const errors = {};
        const vatcodeIsNumeric = showVatCode && rowProps.integration != "netvisor";
        const requiredErrors = this.checkRequiredFields(data);
        const vatCodeValid = vatcodeIsNumeric ? this.validateInteger(data.vat_code) : true;
        // const valueValid = this.validateInteger(data.value);

        if (Object.values(requiredErrors).find(e => e)) {
            enqueueSnackbar && enqueueSnackbar(errorMessages?.requiredFieldsNotFilled, {
                variant: "error",
            });
            hasErrors = true;
        }
        if (!vatCodeValid) {
            enqueueSnackbar && enqueueSnackbar(errorMessages?.invalidVatcode, {
                variant: "error",
            });
            errors.vat_code = true;
            hasErrors = true;
        }
        // Commented out because we have to support VATs with decimals
        /* if (!valueValid) {
            enqueueSnackbar && enqueueSnackbar(errorMessages?.invalidValue, {
                variant: "error",
            });
            errors.value = true;
            hasErrors = true;
        } */

        this.setData({errors: {...requiredErrors, ...errors}});
        return !hasErrors;
    }

    checkRequiredFields = (data) => {
        const requiredFields = this.getRequiredFields();
        const errors = {};

        if (requiredFields.length < 1) {
            return {};
        }

        requiredFields.forEach(f => {
            if (!data[f]) {
                errors[f] = true;
            }
            else {
                errors[f] = false;
            }
        });

        return errors;
    }

    validateInteger = (value) => {
        if (!value) { // Required fields are checked separately, so return no error here if value is empty
            return true;
        }

        const numericCode = Number(value);
        if (!Number.isInteger(numericCode) || numericCode > 2147483647 || numericCode < -2147483648) {
            return false;
        }
        return true;
    }

    setFieldData = (name, value) => {
        const { data } = this.props;
        const errors = data.errors || {};

        if (Number(data.id) > 0 && !this.validateField(name, value)) {
            errors[name] = true;
            const update = {[name]: value, errors: {...errors}}
            this.setData({...update})
            return;
        }
        if (name == "use_reverse_vat" && value == 1) {
            data.value = "0";
        }
        data.errors = this.removeFieldError(name);
        data[name] = value;
        data.updatedField = name;
        this.setDataAndUpdate({...data})
    }

    removeFieldError = (name) => {
        const { data } = this.props;

        if (data?.errors) {
            data.errors[name] = false;
            if (name == "use_reverse_vat") {
                data.errors["value"] = false;
            }
        }
        else {
            data.errors = {};
        }
        return data.errors;
    }

    render() {
        const { rowProps: { accountingAccounts, countryCodes, isCompanyUsingCountryCode, getCodeAmountForCountry, isDeletingForbidden, errorMessages } } = this.props;
        const { data } = this.props;
        const fieldErrors = data.errors ? data.errors : {};

        const accounting_accounts_id = accountingAccounts.find(d => d.id == data['accounting_accounts_id']);
        const country_code = countryCodes?.find(c => c.id == data['country_code']);
        const listCellProps = {inEditMode: Number(data.id) < 0};
        const countryVatcodesAmount = isCompanyUsingCountryCode() ? getCodeAmountForCountry(data['country_code']) : 0;
        const isNew = Number(data.id) < 1;
        const deleteStyle = isDeletingForbidden(data) ? "disabled-icon" : "";

        const cells = {
            delete: (data.id > 0 ? 
                <ListCell width={this.props.columnWidthMap.delete} className="noBg pointer" onClick={() => !deleteStyle && this.props.rowProps.onDelete(data)} permanentEditMode>
                    {deleteStyle ?
                    	<Tooltip title={errorMessages?.defaultVatcodeDelete}>
                            <TrashCanIcon className={deleteStyle} />
                        </Tooltip>
                    :
                    <TrashCanIcon />
                    }
                </ListCell> 
                : 
                <ListCell width={this.props.columnWidthMap.delete} permanentEditMode={true} onlyDisplay={true}>
                    <Cancel className="cancelIcon" onClick={() => this.props.rowProps.onDelete(data)} />
                    <CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.createNew()} />
                </ListCell>),
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={data['name']}
                onEdited={(name, value) => this.setFieldData(name, value)}
                listCellProps={{
                    ...listCellProps,
                    inEditMode: isNew || fieldErrors['name'],
                    showErrorBorder: fieldErrors['name']
                }}
            />,
            vat_code: <TextInputCell
                width={this.props.columnWidthMap['vat_code']}
                name="vat_code"
                value={data['vat_code']}
                listCellProps={{
                    ...listCellProps,
                    inEditMode: isNew || fieldErrors['vat_code'],
                    showErrorBorder: fieldErrors['vat_code']
                }}
                onEdited={(name, value) => this.setFieldData(name, value)}
            />,
            value: <TextInputCell
                width={this.props.columnWidthMap['value']}
                name="value"
                editable={data['use_reverse_vat'] != 1}
                value={data['value'].replace(".", ",")}
                listCellProps={{
                    inEditMode: (isNew && data['use_reverse_vat'] != 1) || fieldErrors['value'],
                    showErrorBorder: fieldErrors['value']
                }}
                onEdited={(name, value) => this.setFieldData(name, value)}
            />,
            accounting_accounts_id: <AutoCompleteCell
                listCellProps={{
                    ...listCellProps,
                    className: "accounting_accounts_id"
                }}
                editable={true}
                autoCompleteData={accountingAccounts}
                name="accounting_accounts_id"
                width={this.props.columnWidthMap['accounting_accounts_id']}
                value={accounting_accounts_id}
                onEdited={obj => this.setFieldData("accounting_accounts_id", obj.id)}
            />,
            integration_id: <TextInputCell
                width={this.props.columnWidthMap['integration_id']}
                name="integration_id"
                value={data['integration_id']}
                listCellProps={{
                    ...listCellProps,
                    showErrorBorder: fieldErrors['integration_id']
                }}
                onEdited={(name, value) => this.setFieldData(name, value)}
            />,
            use_reverse_vat:
                <ListCell name="use_reverse_vat" className="" width={this.props.columnWidthMap["use_reverse_vat"]} onlyDisplay>
                    <Switch
                        color="primary"
                        checked={data['use_reverse_vat'] == 1}
                        onChange={event => {
                            this.setFieldData("use_reverse_vat", event.target.checked ? 1 : 0);
                        }}
                    />
                </ListCell>,
            country_code: <AutoCompleteCell
                listCellProps={{
                    ...listCellProps,
                    className: "accounting_accounts_id",
                    showErrorBorder: fieldErrors['country_code']
                }}
                editable={true}
                autoCompleteData={countryCodes}
                name="country_code"
                width={this.props.columnWidthMap['country_code']}
                value={country_code}
                onEdited={obj => this.setFieldData("country_code", obj.id)}
            />,
            is_default: isCompanyUsingCountryCode() ? 
                <ListCell className="" width={this.props.columnWidthMap["is_default"]} onlyDisplay>
                    {(countryVatcodesAmount > 1 || data.id < 1) && 
                        <Switch
                            color="primary"
                            checked={data['is_default'] == 1}
                            disabled={data['is_default'] == 1 && data.id > 0}
                            onChange={event => {
                                this.setFieldData("is_default", event.target.checked ? 1 : 0);
                            }}
                        />} 
                </ListCell>
                :
                <RadioButtonCell 
                    onEdited={(event, checked) => {
                        this.setFieldData("is_default", checked);
                    }}
                    width={this.props.columnWidthMap['is_default']}
                    checked={data['is_default'] == 1} 
                />
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName]) }</div>;
    }
}

class AccountingVatCodes extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        showIntegrationID: false,
        showVatCode: true,
        showVatAccount: true
    };
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/AccountingVatCodes");

        this.state = {
            vatCodes: [],
            accountingAccounts: [],
            countryCodes: [],
            deleteDialogOpen: false,
            dialogData: {}
        }

        this.columnConfig = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.errorMessages = {
            requiredFieldsNotFilled: this.tr("Required fields not filled"),
            fieldCannotBeEmpty: this.tr("Field cannot be empty"),
            invalidValue: this.tr("Invalid VAT %"),
            invalidVatcode: this.tr("Invalid VAT code (only numbers allowed)"),
            defaultVatcodeDelete: this.tr("Default VAT code cannot be deleted"),
        }

        this.list = React.createRef();
    }

    componentDidMount () {
        super.componentDidMount();
        this.getData();
        this.getVatAccounts();
        this.getAutocompleteData();
    }

    getData = () => {
        DataHandler.get({url: `accounting/vatcodes/${this.props.company}/${this.props.integration}`}).done(response => {
            this.setState({vatCodes: response[Object.keys(response)[0]]});
        });
    }

    getVatAccounts = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/${this.props.integration}/4`}).done(response => {
            this.setState({accountingAccounts: response[Object.keys(response)[0]]});
        });
    }

    getCodeAmountForCountry = (countryCode) => {
        const codes = this.state.vatCodes.filter(v => v.country_code == countryCode);
        return codes ? codes.length : 0;
    }
    
    isCompanyUsingCountryCode = () => {
        const { addons } = this.context;
        return isCompanyUsingInvoiceCountryCode(addons, this.props.company);
    }

    isCompanyUsingNav = () => {
        const { addons } = this.context;
        return (addons.nav && addons.nav.used_by_companies.indexOf(this.props.company) > -1);
    }

    getAutocompleteData = () => {
        DataHandler.get({ url: `settings/autocomplete`, country_codes: 1 }).done(settings => {
            this.setState({ countryCodes: settings.country_codes })
        });
    }

    deleteVatcode = (data) => {
        data.deleted      = "1";
        data.integration  = this.props.integration;
        data.companies_id = this.props.company;
        DataHandler.delete({url: 'accounting/vatcodes'}, { data: JSON.stringify(data) }).done(response => {
            this.getData();
        }).fail(err => {
            if (err?.responseJSON?.error == "DEFAULT_VATCODE_DELETE_FORBIDDEN") {
                this.props.enqueueSnackbar && this.props.enqueueSnackbar(this.errorMessages?.defaultVatcodeDelete, {
                    variant: "error",
                });
            }
        });

        this.closeDialog();
    }

    isDeletingForbidden = (data) => {
        if (this.isCompanyUsingCountryCode())
            return data?.is_default == 1 && this.getCodeAmountForCountry(data?.country_code) > 1
        else 
            return data?.is_default == 1;
    }

    closeDialog = () => {
        this.setState({deleteDialogOpen: false, dialogData: undefined});
    }

    showBackendError = (err) => {
        if (err == "REQUIRED_FIELDS_NOT_FILLED") {
            this.props.enqueueSnackbar && this.props.enqueueSnackbar(this.errorMessages?.requiredFieldsNotFilled, {
                variant: "error",
            });
        }
        else if (err == "VAT_CODE_INVALID") {
            this.props.enqueueSnackbar && this.props.enqueueSnackbar(this.errorMessages?.invalidVatcode, {
                variant: "error",
            });
        }
        else if (err == "VALUE_INVALID") {
            this.props.enqueueSnackbar && this.props.enqueueSnackbar(this.errorMessages?.invalidValue, {
                variant: "error",
            });
        }
    }

    render() {
        const { dialogData, deleteDialogOpen } = this.state;

        return (
            <div className="accounting-list">
                <Button className="green" onMouseUp={() => this.list.current.addNewRow()} size="large">{this.tr("Add VAT code")}</Button>
            
                <List
                    innerRef={this.list}
                    className="settingsList integrationList"
                    height="auto"
                    rowHeight={SettingRow.rowHeight}
                    ignoreRowPropsChange={false}
                    manualCreate
                    noStateData={true}
                    emptyNewDataOnUpdate={false}
                    newRow={{
                        id: -1,
                        name: "",
                        type: "",
                        value: "",
                        accounting_accounts_id: 0,
                    }}
                    listRowType={VatCodeRow}
                    columns={[
                        {width: 55, name: "delete", header: "", ...this.columnConfig},
                        ...(this.isCompanyUsingCountryCode() ? [{width: 300, name: "country_code", header: this.tr("Country code"), ...this.columnConfig}] : []),
                        ...(this.props.showVatCode ? [{width: 200, name: "vat_code", header: this.tr("VAT code"), ...this.columnConfig}] : []),
                        {width: 300, name: "name", header: this.tr("VAT code name"), ...this.columnConfig},
                        {width: 200, name: "value", header: this.tr("VAT %"), ...this.columnConfig},
                        ...(this.props.showVatAccount ? [{width: 200, name: "accounting_accounts_id", header: this.tr("VAT account"), ...this.columnConfig}] : []),
                        ...(this.props.showIntegrationID ? [{width: 300, name: "integration_id", header: this.tr("Integration ID"), ...this.columnConfig}] : []),
                        ...(this.isCompanyUsingNav() ? [{width: 100, name: "use_reverse_vat", header: this.tr("Reverse VAT"), ...this.columnConfig}] : []),
                        {width: 100, name: "is_default", header: this.tr("Default"), ...this.columnConfig}
                    ]}
                    rowProps={{
                        enqueueSnackbar: this.props.enqueueSnackbar,
                        countryCodes: this.state.countryCodes,
                        accountingAccounts: this.state.accountingAccounts ? this.state.accountingAccounts : [],
                        isCompanyUsingCountryCode: this.isCompanyUsingCountryCode,
                        getCodeAmountForCountry: this.getCodeAmountForCountry,
                        isDeletingForbidden: this.isDeletingForbidden,
                        integration: this.props.integration,
                        errorMessages: this.errorMessages,
                        showVatCode: this.props.showVatCode,
                        showIntegrationID: this.props.showIntegrationID,
                        onCreate: data => {
                            data.companies_id = this.props.company;
                            DataHandler.post({url: `accounting/vatcodes/${this.props.integration}`}, { data: JSON.stringify(data) }).done(response => {
                                this.list.current.removeRow(data.id);
                                this.getData();
                            })
                            .fail(err => {
                                this.showBackendError(err?.responseJSON?.error);
                            })
                        },
                        onUpdate: data => {
                            data.integration = this.props.integration;
                            data.companies_id = this.props.company;
                            DataHandler.put({url: 'accounting/vatcodes'}, { data: JSON.stringify(data) }).done(response => {
                                this.getData();
                            })
                            .fail(err => {
                                this.showBackendError(err?.responseJSON?.error);
                            })
                        },
                        onDelete: data => {
                            if (Number(data.id) < 1) {
                                this.list.current.removeRow(data.id);
                                return;
                            }
                            this.setState({ deleteDialogOpen: true, dialogData: data });
                        }
                    }}
                    data={this.state.vatCodes}
                />
                 {deleteDialogOpen &&
                        <MassDialog
                            onDialogClose={this.closeDialog}
                            onDialogSave={() => this.deleteVatcode(dialogData)}
                            dialogType={"delete"}
                            dialogProps={{
                                wider: true,
                                confirmDisabled: this.isDeletingForbidden(this.state.dialogData),
                                onCloseClick: this.closeDialog,
                                open: deleteDialogOpen,
                                close: this.closeDialog,
                                header: this.tr("Delete VAT code?"),
                                warning: () => {
                                    if (this.isDeletingForbidden(this.state.dialogData))
                                        return this.errorMessages?.defaultVatcodeDelete;
                                    else 
                                        return this.tr("Are you sure you want to delete VAT code: ${code}?", {code: this.state.dialogData?.name});
                                },
                                onConfirm: () => {
                                    this.deleteVatcode(dialogData);
                                }

                            }}
                        />
                    }
            </div>
        );
    }
}

export default AccountingVatCodes;
