import React from 'react';
import { History, PlaylistAdd, Receipt } from '@mui/icons-material';
import PageTopSection from '../../general/PageTopSection';
import TaimerComponent from '../../TaimerComponent';
import Utils from "./../../general/Utils.js";
import List from '../List';
import InvoiceLogListRow from '../rows/InvoiceLogListRow';
import styles from './InvoiceLogList.module.scss';
import DataHandler from '../../general/DataHandler';
import OutlinedField from '../../general/OutlinedField';
import { MenuItem } from '@mui/material';
import { DateRangePicker } from '../../general/react-date-range/src';
import moment from 'moment';
import { isEqual, cloneDeep } from 'lodash';
import MultiSelect from '../../general/MultiSelect';
import AdvancedSearch from '../../search/AdvancedSearch';
import InvoiceLogProvider, { InvoiceLogContext, InvoiceLogContextType } from '../../invoices/InvoiceLogProvider';

interface Props {
    logContext: InvoiceLogContextType;
    showLockedUsersWithTag: boolean;
}
interface State {
    loading: boolean;
    dataLoaded: boolean;
    data: any[];
    filters: any;
    companies: any[];
    autoCompleteData: any;
    resetAccounts: boolean;
    currency: string;
    listSettings: {
        totalCount: number;
        pageCount: number;
        page: number;
        perPage: number;
        sort?: string;
    };
}

class InvoiceLogList extends TaimerComponent<Props, State> {
    static defaultProps = {
        showLockedUsersWithTag: true,
    };    
    list: React.RefObject<List> = React.createRef();
    advancedSearch: React.RefObject<AdvancedSearch> = React.createRef();
    searchTerms: any;
    eventTypeFilters: any[] = [
        {
            id: -1,
            name: this.tr('Not selected'),
        },
        {
            id: [this.props.logContext.eventTypes.sentToNordea, this.props.logContext.eventTypes.sentToMaventa, this.props.logContext.eventTypes.sentToNav],
            name: this.tr('Sent to integration'),
        },
        {
            id: [this.props.logContext.eventTypes.invoiceReverted],
            name: this.props.logContext.eventTypeNames[this.props.logContext.eventTypes.invoiceReverted],
        },
        {
            id: [this.props.logContext.eventTypes.invoiceDeleted],
            name: this.props.logContext.eventTypeNames[this.props.logContext.eventTypes.invoiceDeleted],
        },
        {
            id: [this.props.logContext.eventTypes.paymentReceived],
            name: this.props.logContext.eventTypeNames[this.props.logContext.eventTypes.paymentReceived],
        },
    ];
    eventStatuses: any[] = [
        {
            id: -1,
            name: this.tr('Not selected'),
        },
        {
            id: 'error',
            name: this.tr('Error'),
        },
        {
            id: 'success',
            name: this.tr('Success'),
        },
        {
            id: 'log',
            name: this.tr('Log'),
        },
    ];
    listColumns = [
        {
            field: 'menu',
            visibleInToolbar: true,
            hideFromSearch: true,
            name: 'menu',
            header: '',
            width: 60,
            fieldType: 'rowmenu',
            showMenu: false,
            resizeable: false,
            moveable: false,
            hideable: false,
        },
        { field: 'bills_id', hideFromSearch: false, name: 'bills_id', transl: this.tr('Invoice nr.'), header: this.tr('Invoice nr.'), width: 110 },
        { field: 'event_status', hideFromSearch: true, name: 'event_status', transl: this.tr('Event Status'), header: this.tr('Event Status'), width: 120 },
        { field: 'event_stamp', hideFromSearch: false, name: 'event_stamp', transl: this.tr('Event Timestamp'), header: this.tr('Event Timestamp'), width: 150, type: 'date' },
        { field: 'event', hideFromSearch: true, name: 'event', transl: this.tr('Event'), header: this.tr('Event'), width: 200 },
        { field: 'message', hideFromSearch: true, sortable: false, name: 'message', transl: this.tr('Message'), header: this.tr('Message'), width: 250 },
        { field: 'edited_by', hideFromSearch: false, name: 'edited_by', transl: this.tr('Edited by'), header: this.tr('Edited by'), width: 200, entityMode: true },
        { field: 'invoice_date', hideFromSearch: false, name: 'invoice_date', transl: this.tr('Invoice Date'), header: this.tr('Invoice Date'), width: 150, type: 'date' },
        { field: 'due_date', hideFromSearch: false, name: 'due_date', transl: this.tr('Due Date'), header: this.tr('Due Date'), width: 150, type: 'date' },
        { field: 'invoice_status', hideFromSearch: true, name: 'invoice_status', transl: this.tr('Invoice Status'), header: this.tr('Invoice Status'), width: 150 },
        { field: 'invoice_account', hideFromSearch: true, name: 'invoice_account', transl: this.tr('Account'), header: this.tr('Account'), width: 200 },
        {
            field: 'invoice_projects',
            hideFromSearch: false,
            name: 'invoice_projects',
            transl: this.tr('Projects'),
            header: this.tr('Projects'),
            width: 200,
            visualizationType: 'tree',
            entityMode: true,
        },
    ];
    userTypeAutocompleteClasses: any[] = ['users', 'edited_by'];
    userTypeDataHeaders: any = {};
    translations: any = {};    
    initialFilters: any = {};
    constructor(props, context) {
        super(props, context, 'list/lists/InvoiceLogList');
        const {
            functions: { getCompany },
        } = this.context;
        this.initialFilters = {
            dateRange: {
                startDate: undefined,
                endDate: undefined,
                key: 'selection',
            },
            company: getCompany('invoices', 'write_full', false, true) || getCompany('invoices', 'write_simple', false, true),
        };

        this.state = {
            loading: false,
            dataLoaded: false,
            resetAccounts: false,
            data: [],
            filters: this.initialFilters,
            autoCompleteData: {},
            companies: [],
            currency: this.context.taimerAccount.currency,
            listSettings: {
                totalCount: 0,
                pageCount: 0,
                page: 1,
                perPage: 30,
            },
        };

        this.translations = {
            locked: this.tr("locked"),
            freelancer: this.tr("freelancer")
        };
        this.userTypeDataHeaders = {users_id: 'users_name'};
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.getCompaniesAndData();
    };

    getCompaniesAndData = async () => {
        const {
            filters: { company },
        } = this.state;
        const companies = await DataHandler.get({ url: `subjects/companies/invoices/write_full+write_simple`, currency: 1, allow_mass_invoicing: 1 });
        const selectedCompany = (companies || []).find((c) => c.id == company) || companies[0];
        const currency = selectedCompany?.currency || this.context.taimerAccount.currency;
        this.initialFilters = {
            ...this.initialFilters,
            company: selectedCompany?.id || company,
        };
        this.setState(
            {
                companies,
                currency,
                filters: {
                    ...this.state.filters,
                    company: selectedCompany?.id || company,
                },
            },
            () => {
                this.getAutoCompleteData();
                this.getData();
            }
        );
    };

    getData = () => {
        this.setState({ loading: true }, async () => {
            try {
                const {
                    filters: {
                        company,
                        accounts: selectedAccounts,
                        eventStatus,
                        eventType,
                        dateRange: { startDate: startdate, endDate: enddate },
                    },
                    listSettings: { page, perPage: perpage, sort },
                } = this.state;
                const { freetextSearchTerm, advanced_search_criteria, mode } = this.searchTerms || {};
                let accounts = (selectedAccounts || []).map((a) => a.id);
                if (accounts.length == 0 || (accounts.length == 1 && selectedAccounts[0].value == 0)) {
                    accounts = undefined;
                }
                const data = await DataHandler.post(
                    { url: `invoices/invoice_log/${company}` },
                    { page, perpage, sort, startdate, enddate, accounts, eventStatus, eventType, freetextSearchTerm, advanced_search_criteria, mode }
                );
                const userTagProps = {
                    fields: this.userTypeDataHeaders,
                    showLocked: this.props.showLockedUsersWithTag,
                    transl: this.translations,
                    userData: this.state.autoCompleteData.users
                };
                data.data.forEach((inv, i) => {
                    if (Object.keys(inv).some(k => Object.values(this.userTypeDataHeaders).includes(k))) {          
                        data.data[i] = ({...Utils.showLockedAndFreelancerUserTag(inv, userTagProps)});
                    }
                })

                this.setState(
                    {
                        data: data.data,
                        listSettings: {
                            ...this.state.listSettings,
                            totalCount: data.totalCount,
                            pageCount: data.pageCount,
                        },
                        dataLoaded: true,
                        loading: false,
                    },
                    () => {
                        this.list.current && this.list.current.endPageChangeAnimation();
                    }
                );
            } catch (err) {
                console.error(err);
                this.list.current && this.list.current.endPageChangeAnimation();
                this.setState({ dataLoaded: true, loading: false });
            }
        });
    };

    getAutoCompleteData = () => {
        const { company } = this.state.filters;
        DataHandler.get({ url: `invoices/autoCompleteData/${company}`})
            .done((autoCompleteData) => {
                autoCompleteData = {
                    ...autoCompleteData,
                    users: (autoCompleteData.users || []).map((u) => {
                        if (u.companies_id < 1) u.name = `${u.name} (${this.tr('freelancer')})`;
                        return u;
                    }),
                    customers: (autoCompleteData.customers || []).map((c) => ({ ...c, label: c.name, value: c.id })),
                };
                const userTagProps = {
                   fields: {name: 'name'},
                   showLocked: this.props.showLockedUsersWithTag,
                   transl: this.translations
                };
                Object.keys(autoCompleteData).forEach(k => {
                   if (this.userTypeAutocompleteClasses.includes(k)) {
                       autoCompleteData[k] = autoCompleteData[k].map(d => ({...Utils.showLockedAndFreelancerUserTag(d, userTagProps)}))
                   }
                })

                this.setState({ autoCompleteData });
            })
            .fail((err) => {
                console.error(err);
            });
    };

    handleTabChange = (selectedPage) => {
        const {
            functions: { updateView },
        } = this.context;
        updateView({ selectedPage });
    };

    setDateRangeFilter = (startDate?: string, endDate?: string) => {
        this.setState(
            {
                filters: {
                    ...this.state.filters,
                    dateRange: {
                        startDate,
                        endDate,
                        key: 'selection',
                    },
                },
            },
            () => {
                this.resetListAndGetData();
            }
        );
    };

    onListSettingsChanged = (value, pageAnimation = false) => {
        let listSettings = cloneDeep(this.state.listSettings);
        listSettings = {
            ...listSettings,
            ...value,
        };
        if (pageAnimation) {
            this.list.current && this.list.current.startPageChangeAnimation();
        }
        this.setState({ listSettings }, () => {
            this.getData();
        });
    };

    resetListAndGetData = (getAutoCompleteData = false) => {
        if (this.list.current) {
            this.list.current.resetCheckedRows();
        }
        if (getAutoCompleteData) {
            this.getAutoCompleteData();
        }
        this.onListSettingsChanged({ page: 1 }, true);
    };

    onSearchTrigger = (searchTerms) => {
        this.searchTerms = searchTerms;
        this.resetListAndGetData();
    };

    filtersAreInInitialState() {
        const initial = cloneDeep(this.initialFilters);
        const filters = {};
        for (const key in initial) {
            let value = this.state.filters[key];
            if (key == 'accounts' && value) {
                if (value.length == 1 && value[0].value == 0) {
                    value = [];
                }
            }
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = JSON.stringify(value);
        }
        const freetext = this.searchTerms ? this.searchTerms.freetextSearchTerm : '';
        return isEqual(initial, filters) && !freetext;
    }

    onFilterChanged = (e) => {
        const { name, value } = e.target;
        if (isEqual(this.state.filters[name], value)) return;
        if (name == 'company') {
            this.context.functions.setLastCompany(value);
        }
        const filters = {
            ...this.state.filters,
            [name]: value,
        };
        if (value == -1) {
            delete filters[name];
        }
        this.setState({ filters }, () => {
            this.resetListAndGetData(name == 'company');
        });
    };

    onDateRangeChanged = (e) => {
        const { startDate, endDate } = e.selection;
        if (!startDate || !endDate) {
            this.setDateRangeFilter(undefined, undefined);
            return;
        }
        this.setDateRangeFilter(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
    };

    onDateRangeInputChanged = (dateType, date) => {
        const newDate = moment(date).format('YYYY-MM-DD');
        const startDate = dateType == 'start' ? newDate : this.state.filters.dateRange.startDate;
        const endDate = dateType == 'end' ? newDate : this.state.filters.dateRange.endDate;
        this.setDateRangeFilter(startDate, endDate);
    };

    resetSelectedAccounts = () => {
        this.setState({ resetAccounts: false }, () => {
            this.onFilterChanged({ target: { name: 'accounts', value: [] } });
        });
    };

    setSelectedAccounts = (value) => {
        this.onFilterChanged({ target: { name: 'accounts', value } });
    };

    resetFilters = (e) => {
        if (!e || e.keyCode == '27') {
            this.searchTerms = undefined;
            this.advancedSearch.current?.clearSearch(undefined, true);
            this.advancedSearch.current?.clearSearchTextInput();
            this.setState(
                {
                    resetAccounts: true,
                    filters: {
                        ...this.state.filters,
                        ...this.initialFilters,
                    },
                },
                () => this.resetListAndGetData()
            );
        }
    };

    render() {
        const {
            loading,
            data,
            dataLoaded,
            companies,
            autoCompleteData: { customers, projects, users },
            resetAccounts,
            filters,
            listSettings: { page, perPage, pageCount, totalCount },
            currency,
        } = this.state;
        const {
            userObject: { dateFormat },
        } = this.context;
        return (
            <div id={styles.invoiceLogList} className="contentBlock">
                <div className="listControlsContainer clearfix">
                    <div className={styles.filterContainer}>
                        {companies.length > 1 && (
                            <OutlinedField className="listFilterOutlinedField" select label={this.tr('Company')} value={filters.company} name="company" onChange={this.onFilterChanged}>
                                {companies.map((row) => (
                                    <MenuItem key={row.id} value={row.id}>
                                        {row.name}
                                    </MenuItem>
                                ))}
                            </OutlinedField>
                        )}
                        <DateRangePicker
                            className={styles.dateRange}
                            ranges={[filters.dateRange]}
                            onChange={this.onDateRangeChanged}
                            onInputChange={this.onDateRangeInputChanged}
                            label={this.tr('Time span')}
                            dateFormat={dateFormat}
                            showClearButton
                        />
                        <MultiSelect
                            className={`listFilterOutlinedField ${styles.multiselect}`}
                            options={customers}
                            defaultValue={filters.accounts}
                            reset={resetAccounts}
                            resetFunction={this.resetSelectedAccounts}
                            resetValue={filters.accounts}
                            label={this.tr('Account')}
                            name="accounts"
                            onChange={this.setSelectedAccounts}
                            skipInitialChange
                        />
                        <OutlinedField className="listFilterOutlinedField" select label={this.tr('Event Status')} value={filters.eventStatus} name="eventStatus" onChange={this.onFilterChanged}>
                            {this.eventStatuses.map((row) => (
                                <MenuItem key={row.id} value={row.id}>
                                    {row.name}
                                </MenuItem>
                            ))}
                        </OutlinedField>
                        <OutlinedField className="listFilterOutlinedField" select label={this.tr('Event Type')} value={filters.eventType} name="eventType" onChange={this.onFilterChanged}>
                            {this.eventTypeFilters.map((row) => (
                                <MenuItem key={row.id} value={row.id}>
                                    {row.name}
                                </MenuItem>
                            ))}
                        </OutlinedField>
                        <AdvancedSearch
                            ref={this.advancedSearch}
                            mode={this.searchTerms && this.searchTerms.mode ? this.searchTerms.mode : undefined}
                            initialFilters={this.searchTerms ? this.searchTerms.currentFilters : undefined}
                            alwaysShowClearFilters={!this.filtersAreInInitialState()}
                            mainConfig={this.searchTerms && this.searchTerms.advanced_search_criteria ? { operator: this.searchTerms.advanced_search_criteria.operator } : undefined}
                            freetextLabel={this.searchTerms ? this.searchTerms.freetextSearchTerm : ''}
                            onClearSearch={this.resetFilters}
                            fields={this.listColumns.filter((c) => !c.hideFromSearch)}
                            autoCompleteData={{
                                invoice_projects: [projects, 'parent_id'],
                                edited_by: users,
                            }}
                            perpage={perPage}
                            noRequests={true}
                            onSearchTrigger={this.onSearchTrigger}
                        />
                    </div>
                </div>
                <List
                    userListSettingsKey="invoices_log_list"
                    saveColumnConfig
                    ref={this.list}
                    data={data}
                    columns={this.listColumns}
                    height="fitRemaining"
                    idType="string"
                    pageCount={pageCount}
                    totalCount={totalCount}
                    rowProps={{ logContext: this.props.logContext, currency }}
                    perpage={perPage}
                    page={page}
                    noStateData
                    controlPage
                    onPageChange={(page) => this.onListSettingsChanged({ page }, true)}
                    onSortRows={(name, asc) => this.onListSettingsChanged({ sort: { name, asc } })}
                    onPerPageChange={(perPage) => {
                        this.onListSettingsChanged({ perPage, page: 1 }, true);
                    }}
                    listRowType={InvoiceLogListRow}
                    showNoResultsMessage={dataLoaded}
                    showPageSelector
                />
            </div>
        );
    }
}

const InvoiceLogListWithContext = (props) => (
    <InvoiceLogProvider>
        <InvoiceLogContext.Consumer>{(context) => <InvoiceLogList logContext={context} {...props} />}</InvoiceLogContext.Consumer>
    </InvoiceLogProvider>
);

export default InvoiceLogListWithContext;
