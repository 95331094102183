import React from 'react';
import ListCell from "./../list/ListCell";
import TextInput from "./TextInput";
import ClickAwayListener from '@mui/material/ClickAwayListener';

/* validator */
import validator from 'validator';

const validators = {
    empty: e => !validator.isEmpty(e, { ignore_whitespace: true }),
    numeric: e => !e.length || validator.isNumeric(e),
    email: e => !e.length || validator.isEmail(e),
    percent100: e => {
    	if(!e.length) {
    		return false;
    	}
    	let percent = Number(e.replace(/[^0-9\-]/,""));
    	if(percent < 0 || percent > 100) {
    		return false;
    	}
    	return true;
	},
};

class TextInputCell extends React.Component {
	static defaultProps = {
		listCellType: ListCell,
		listCellProps: {},
		runOnEditedOnInput: false,
		width: 100,
		ownerRow: undefined,
		name: undefined,
		focusOnMount: false,
		value: "",
		editable: true,
		inputType: "text",
		placeholder: "",
		textAlign: "left",
		onEdited: () => {},
		onEnter: () => {},
		onOverLimit: () => {}
	};


	constructor(props) {
		super(props);

		this.listCell  = React.createRef();
		this.textInput = React.createRef();

		this.state = {
	        initialValue: undefined,
	        value: "",
	        valid: true,
	        avoidFocus: props.avoidFocus
	    };

		this.runOnEdited   = this.runOnEdited.bind(this);
		this.focusOnEditor = this.focusOnEditor.bind(this);
	}

	componentDidUpdate() {
		if (this.state.avoidFocus && this.textInput.current) {
			this.setState({avoidFocus: undefined});
			this.textInput.current.inputRef.current.blur();
		}
	}

	runOnEdited(evt) {
        const value = this.textInput.current.getValue();
        const valid = !this.props.validation || this.props.validation.find(e => !validators[e](value)) === undefined;

        this.setState({ valid });

		if(!valid)
			return;

		this.props.onEdited.length === 1 ? this.props.onEdited(value) : this.props.onEdited(this.props.name, value);

		this.listCell.current.closeEdit();
	}


	focusOnEditor() {
		this.textInput.current && this.textInput.current.focus();
		this.textInput.current && this.textInput.current.select();
	}
    

	render() {
		const ListCellType  = this.props.listCellType;
		const listCellProps = Object.assign({}, {
			owner: 	  this,
			ref: 	  this.listCell,
			width: 	  this.props.width,
			value: 	  this.props.value,
			editable: this.props.editable,
			onEnterEditMode: () => {
				// if(!this.props.listCellProps.hasOwnProperty("noInitFocus") || !this.props.listCellProps.noInitFocus) {
					this.focusOnEditor();
				// }
			},
		}, this.props.listCellProps);
		return (
			<ClickAwayListener onClickAway={() => (this.listCell.current.isInEditMode()) && (this.listCell.current.closeEdit())}>
				<ListCellType textAlign={this.props.textAlign} {...listCellProps} error={this.props.validation ? !this.state.valid : undefined}>
					<TextInput
						key={this.props.value}
						ref={this.textInput}
						limit={this.props.limit}
						onOverLimit={this.props.onOverLimit}
						initValue={this.props.editValue || this.props.value}
						onEnter={e => {
							this.runOnEdited(e);
							this.props.onEnter(e);
						}}
						onBlur={this.runOnEdited}
						onInputListener={this.props.runOnEditedOnInput ? this.runOnEdited : () => {}}
						error={this.props.validation ? !this.state.valid : undefined}
						className="textInputCell"
						type={this.props.inputType}
						textAlign={this.props.textAlign}
						// focusOnMount={(!this.props.listCellProps.hasOwnProperty("noInitFocus") || !this.props.listCellProps.noInitFocus) && this.props.focusOnMount}
						placeholder={this.props.placeholder} 
						overRunNativeValue={this.props.overRunNativeValue} />
				</ListCellType>
			</ClickAwayListener>
		);
	}
}

export default TextInputCell;
