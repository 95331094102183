import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { MenuItem } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ContextMenu from '../../general/ContextMenu';
import AutoCompleteCell from "../cells/AutoCompleteCell";
import CheckboxCell from "../cells/CheckboxCell";
import DateCell from "../cells/DateCell";
import EditableStatusCell from "../cells/EditableStatusCell";
import TextAreaCell from "../cells/TextAreaCell";
import TextInputCell from "../cells/TextInputCell";
import TreeStructureIndicatorCell from "../cells/TreeStructureIndicatorCell";
import LinkListCell from "../LinkListCell";
import ListCell from "../ListCell";
import PropsOnlyListRow from "../PropsOnlyListRow";
import { SettingsContext } from './../../SettingsContext';

import { prefixLink } from '../../helpers';
import DataHandler from "./../../general/DataHandler";

import AddIcon from '@mui/icons-material/AddCircleOutline';
import AttachFile from '@mui/icons-material/AttachFile';
import { ReactComponent as AddSubUnitIcon } from '../../general/icons/Add_subunit.svg';
import { ReactComponent as LockIcon } from '../../general/icons/lock-filled.svg';
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import { ReactComponent as UnLockIcon } from '../../general/icons/Unlock-filled.svg';
import { ReactComponent as ViewIcon } from '../../general/icons/view.svg';

import { withSnackbar } from 'notistack';
import rightArrow from './icons/right-arrow.svg';

import "./CustomerListRow.css";

/* validator */
import validator from 'validator';
import VersionContentManager from '../../general/VersionContentManager';


function Link(props) {
	return <a href={props.url}>{props.text}</a>;
}

const dropdownTypes = ["dropdown", "radio"];

const nUndef = v => v !== undefined;
const nUndefGtZero = v => v !== undefined && v > 0;
const nEmptyString = v => typeof v === "string" && v.trim().length > 0;

class CustomerListRow extends PropsOnlyListRow {
	static contextType = SettingsContext;

	constructor(props, context) {
		super(props, { checked: false }, { childRowType: CustomerListRow }, "list/rows/CustomerListRow");
		this.emptyValidation = ["name", "customer_types_id"];
		if (this.props.rowProps.addons && this.props.rowProps.addons.custom_customer_id && this.props.rowProps.addons.custom_customer_id.used_by_companies.indexOf(this.props.rowProps.company) > -1) 
			this.emptyValidation.push("customer_id")
		if (context.taimerAccount.forceCustomerShipGroup) 
			this.emptyValidation.push("customership_groups_id")

        this.saveNewRow = this.saveNewRow.bind(this);
        
        this.state.nonDeleteableReasons = {};
        this.state.nonDeleteableReasonStrings = {
            has_projects: this.tr('projects'),
            has_attachments: this.tr('attachments'),
            has_classifications: this.tr('classifications'),
            has_children: this.tr('children'),
            has_crm: this.tr('crm'),
            has_comments: this.tr('memo'),
            has_partners: this.tr('partners'),
            has_pricelists: this.tr('price lists'),
            has_contracts: this.tr('contracts'),
            has_product_structures: this.tr('product structures'),
            //has_contacts: this.tr('contacts'),
            //has_team: this.tr('team'),
        };
       	this.customCells = {
			dropdown: AutoCompleteCell,
			radio: AutoCompleteCell,
			multiselect: AutoCompleteCell,
			text: TextInputCell,
			textarea: TextAreaCell,
			date: DateCell,
			link: TextInputCell,
		};
	}

	shouldComponentUpdate(nextProps, prevProps) {
		if(nextProps.hasOwnProperty("data") && nextProps.data.id !== this.props.data.id) {
			return false;
		}

		return true;
	}

	openCustomer(params) {
            this.context.functions.updateView({
                "module": "customers",
                "action": "view",
                "id": this.props.data.orig_id,
                "company": this.props.data.companies_id,
                ...params
            }, false);
	}


	addCustomer = (data) => {
		this.context.functions.closeSlider(data);
		setTimeout(() => {
			this.context.functions.addAccount(data);
		}, 500);
	}

	saveNewRow() {
		if (!this.validateFields())
			return;
		
		try {
			this.create(this.props.data).then(response => {
				if(!response.hasOwnProperty("id"))
				return;
				
				this.setData("id", response.id);
                this.context.functions.sendMixpanelEvent('create_account', {
                    'origin_point': "customer_list_row",
                });
                this.context.functions.sendMixpanelPeople('set_once', {
                    'first_create_account_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('set', {
                    'last_create_account_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('increment', {
                    'lifetime_create_account': 1,
                });
			});
		} catch (e) {
			console.error(e);
		}
	}

	validateFields() {
		const emptyFields = [];
		let emailValid = true;
		const data = {};
		const fieldsToValidate = [...this.emptyValidation];

		for (const customField of (this.props.rowProps?.sharedData?.custom_fields || [])) {
			if (!customField.show_in_details || !customField.required) continue;
			fieldsToValidate.push(`custom_${customField.id}`);
		}

		
		fieldsToValidate.forEach(e => {
			data["_customerFieldEmpty_" + e] = false; 
			if (!this.props.data[e] || (typeof this.props.data[e] === "string" && this.props.data[e].trim() === "")) {
				emptyFields.push(e);
				data["_customerFieldEmpty_" + e] = true; 
			}
		})
		
		if (!this.validateEmail(this.props.data.email))
			emailValid = false;
		
		this.setDataAndUpdate(data);

		if (emptyFields.length > 0 || !emailValid) {
			if (emptyFields.length > 0) {
				this.props.enqueueSnackbar(this.tr('Required fields not filled'), {
					variant: 'error'
				});
			}
			return false;
		} else
			return true;
	}

	validateEmail = (value) => {
		let valid = false;
		if (value && value.trim() !== "" && !validator.isEmail(value)) {
				this.setInvalidFields("email");

				this.props.rowProps.enqueueSnackbar(this.tr("Email is not valid"), {
					variant: "error"
				});
		}
		else {
			this.clearInvalidFields();
			valid = true;
		}
		
		this.cellEdited("email", value, true);
		return valid;
	}

	createUnit() {
		const { functions: { updateView } } = this.context;

		updateView({
			"module": "customers",
			"action": "view",
			"unit_parent": this.props.data.orig_id
		}, true);
	}

	openAttachments() {
		this.props.rowProps.callAttachmentDialog(this.props.data.orig_id);
	}

	defineClassName() {
		return "customerListRow";
	}

	onCtrlS() {
		this.props.data.id < 0 ? this.saveNewRow() : this.update();
	}

	checkDeleteable = () => {
        if (!this.state.deleteable)
            DataHandler.get({url: `accounts/delete_check/${this.props.data.orig_id}`, companies_id: this.props.data.companies_id}).done(response => this.setState({deleteable: response.deleteable > 0 ? response.deleteable : undefined, nonDeleteableReasons: response.reasons}));	
			//DataHandler.get({url: `accounts/delete_check/${this.props.data.orig_id}`, companies_id: this.props.data.companies_id}).done(bool => this.setState({deleteable: bool > 0 ? bool : undefined}));		
    }
    
    nonDeleteableString = () => {
        const reasons = [];
        for (const [key, value] of Object.entries(this.state.nonDeleteableReasons)) {
            reasons.push(this.state.nonDeleteableReasonStrings[value]);
        }
        return this.tr('Can not delete because customer has') + ' ' + reasons.join(', ');
    }

	cellEdited = (name, value, emailValidated = true) => {
		if (!emailValidated && Number(this.props.data.id) > 0 && !this.validateEmail(this.props.data.email))
			return;

		this.setDataAndUpdate(name, value);
	}

	onTagCreate = (tag, tagsString, tagOptions) => {
        const data = {tag: tag, type: 1, id: -1};
        DataHandler.post({url: `tags/save/${this.props.rowProps.company}`}, {data}).done(t => {
			this.setState({tagOptions: tagOptions.concat({tag: tag, name: tag, value: tag, id: tag})}, () => this.cellEdited("tags", tagsString));

			this.props.rowProps.enqueueSnackbar(this.tr("Tag ${tag} created!", {tag: data.tag}), {
                variant: "success",
            });
        })
        .fail(response => {
            if (response && response.responseJSON && response.responseJSON.error === "TAG_EXISTS_AS_DISABLED") {    
                this.props.rowProps.enqueueSnackbar(this.tr("Tag already exists, but is disabled!"), {
                    variant: "error",
                });
            }
        });
	}
	
	onTagDelete = (selectedObject, tags) => {
		const deleted = tags.filter(e => !selectedObject.find(s => s.id == e.id))[0];
		const data = {tag: deleted.tag, type: 1, id: -1};
        DataHandler.post({url: `tags/save/${this.props.data.companies_id}`}, {data});
	}

	defineCells() {
		const { functions: { checkPrivilege } } = this.context;
		
		const data 					= this.props.data;
		const rowProps				= this.props.rowProps;
		const sharedData 			= rowProps.sharedData
		const className 			= ["customerListRow listElement row", this.props.hidden ? "hidden" : "", data.id < 0 ? "new" : ""].join(" ");
		const imgSrc 	   			= this.props.children.length > 0 ? rightArrow : false;
		const expandMarkerClassName = this.state.expanded ? "expanderImage open" : "expanderImage";
		const expandMarker 		 	= imgSrc !== false ? <img alt="Expand" style={{height: "44px"}} src={imgSrc} className={expandMarkerClassName} /> : "";
		const customerCustomerIdOn  = rowProps.addons && rowProps.addons.custom_customer_id && rowProps.addons.custom_customer_id.used_by_companies.indexOf(rowProps.company) > -1 ? true : false;

		const customership_group  	= sharedData.customership_groups.find(c => c.id == data.customership_groups_id);
		const customer_type  	 	= sharedData.customer_types.find(c => c.id == data.customer_types_id);
		const is_parent  	 		= sharedData.is_parent_values.find(c => c.id == data.is_parent);
		const customer_unit  	 	= sharedData.customer_units.find(c => c.id == data.customer_unit);
		const crm_type 		 	 	= sharedData.activity_types && sharedData.activity_types.find(c => c.id == data.crm_types_id);		
		const tagOptions 		   		= this.state.tagOptions || sharedData.tags || [];
		const customerRights        = sharedData.customer_rights || [];
		const writeRights           = data.customer_unit == 2 ? customerRights.unit_write : customerRights.write;

		const writeRight = writeRights?.[data.orig_id]?.find(c => c == data.companies_id);
		const crmWriteRight = customerRights.crm_write?.[data.orig_id]?.find(c => c == data.companies_id);
		const corporateStructureWriteRight = data.rights?.corporate_structure_write == 1 || customerRights.corporate_structure_write?.[data.orig_id]?.find(c => c == data.companies_id);

		let tags = [];
		if(this.props.data.tags != null) {
			const currentTags = this.props.data.tags.split(",");
			tags            = tagOptions.filter(t => currentTags.indexOf(t.id) > -1);
		}

		const selectable_customer_types = sharedData.customer_types.slice();
		selectable_customer_types.shift();
		const selectable_customership_groups = sharedData.customership_groups.slice();
		selectable_customership_groups.shift();

		const customerEditable = Boolean(writeRight || this.props.data.id < 0 || this.props.newRow);
		const crmEditable 	   = crmWriteRight || this.props.data.id < 0;
		const enterprise_groups = sharedData.enterprise_groups ? sharedData.enterprise_groups : [];

		//let showAddSubsidairy = checkPrivilege('customers', 'write') && this.context.versionId >= 3;
		let showAddSubsidairy = !VersionContentManager.isFeatureHidden(this.namespace, 'subsidiaries') && checkPrivilege('customers', 'write', data.companies_id) && corporateStructureWriteRight;
		let showAddSubUnit = !VersionContentManager.isFeatureHidden(this.namespace, 'subUnits') && checkPrivilege('customers', 'unit_write', data.companies_id);

		if(data.is_parent == 1) {
			showAddSubUnit = false;
		} else if(data.is_parent == -1) {
			showAddSubsidairy = false;
		}
		if(data.customer_unit == 1) {
			showAddSubsidairy = false;
		} else if(data.customer_unit == 2) {
			showAddSubsidairy = false;
			showAddSubUnit = false;
		}

		let customerCompany = rowProps.companies?.find(e => e.id == data.companies_id);
		if(!customerCompany) {
			customerCompany = data.company;
		} else {
			customerCompany = customerCompany.name;
		}

		const nameInputCell = () => {
			return (
				<TextInputCell
					listCellType={LinkListCell}
					useClickAwayListener={false}
					listCellProps={{
						urlHandler: value => `index.html?module=customers&action=view&id=${data.orig_id}&company=${data.companies_id}`,
						valueHandler: value => value,
						showErrorBorder: this.props.data['_customerFieldEmpty_name'],
						editable: customerEditable,
						noTab: true,
					}}
					editable={customerEditable}
					width={this.props.columnWidthMap['name']}
					name="name"
					value={data.name}
					allowEmpty={false}
					onEmptyInput={() => {
						if (data.id > 0) {
							this.setData("_customerFieldEmpty_name", true);
							this.props.rowProps.enqueueSnackbar(this.tr("Name cannot be empty"), {
								variant: "error"
							});
						}
						else {
							this.cellEdited("name", "");
						}
                    }}
					onEdited={(name, value) => {
						this.setData("_customerFieldEmpty_name", false);
						this.cellEdited(name, value);
					}}
				/>
			)
		}

		const cells = {
			context:
				this.props.data.id > 0
				?
				<ContextMenu label={<MoreHoriz />} menuOpenCallback={this.checkDeleteable} buttonProps={{className: 'action-menu'}} className="cell row-menu" width={this.props.columnWidthMap['context']} style={{width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + " 1 0px" }} noExpandIcon>
					{showAddSubsidairy && (<MenuItem onClick={() => {this.addCustomer({ parent_company: data.orig_id, parentname: data.name, origin_point: "customer_list_row_add_subsidiary" })}}><AddIcon />{this.tr('Add Subsidairy')}</MenuItem>)}
						{showAddSubUnit && (<MenuItem onClick={() => {this.addCustomer({ main_unit: data.orig_id, parentname: data.name, origin_point: "customer_list_row_add_sub_unit" })}}><AddSubUnitIcon className="subUnit" />{this.tr('Add Sub-unit')}</MenuItem>)}
						<MenuItem onClick={() => {this.openCustomer()}}><ViewIcon />{this.tr("Open")}</MenuItem>
					<MenuItem onClick={() => {this.openAttachments()}}><AttachFile className="Attachment" />{this.tr("Attachments")}</MenuItem>
					{
						customerEditable && (data.locked-0 > 0 ? <MenuItem onClick={() => {rowProps.unLockCustomer(data)}}><UnLockIcon />{this.tr("Unlock")}</MenuItem>: <MenuItem onClick={() => {rowProps.lockCustomer(data)}}><LockIcon />{this.tr("Lock")}</MenuItem>)
					}
					{customerEditable && <Tooltip title={this.state.deleteable ? '' : this.nonDeleteableString()} placement="bottom"><div><MenuItem className="delete" disabled={!this.state.deleteable} onClick={() => {this.delete()}}><RemoveIcon className="Delete" />{this.tr("Delete")}</MenuItem></div></Tooltip>}
				</ContextMenu>
				:
				<ListCell permanentEditMode={true} onlyDisplay={true} width={this.props.columnWidthMap['context']}>
					<Tooltip title={this.tr("Save")} placement="bottom">
						<CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.saveNewRow()} />
					</Tooltip>
				</ListCell>,
			expand:
				<div onClick={this.props.children.length > 0 ? this.toggleExpand : () => {}} className="cell" style={{width: this.props.columnWidthMap['expand'] + "px", textAlign: "center", cursor: "pointer"}}>
					{expandMarker}
				</div>,
			checked:
				this.props.data.id > 0
				?
				<CheckboxCell checked={this.props.checked} onClick={() => this.props.listRef.check(this.props.data.id)} />
				:
				<ListCell permanentEditMode={true} onlyDisplay={true} width={this.props.columnWidthMap['checked']}>
					<Tooltip title={this.tr("Cancel")} placement="bottom">
						<Cancel className="cancelIcon" onClick={() => this.delete(this.props.data)} />
					</Tooltip>
				</ListCell>,
			id:
				<LinkListCell
					editable={false}
					urlHandler={value => `index.html?module=customers&action=view&id=${data.orig_id}&company=${data.companies_id}`}
					valueHandler={value => value}
					noTab={true}
					width={this.props.columnWidthMap['id']}
					value={parseInt(this.props.data['id'], 10) < 0 ? "" : this.props.data['orig_id']} />,
			wintime_id:
				<TextInputCell
					editable={false}
					width={this.props.columnWidthMap['wintime_id']}
					name="wintime_id"
					value={data.wintime_id}
				/>,
			mediapro_id:
				<TextInputCell
					editable={false}
					width={this.props.columnWidthMap['mediapro_id']}
					name="mediapro_id"
					value={data.mediapro_id}
				/>,
			customer_id:
				<TextInputCell
					allowEmpty={false}
					onEmptyInput={() => {
						if (data.id > 0) {
                        	this.props.rowProps.enqueueSnackbar(this.tr("An account's custom id can not be empty."), {
                            	variant: "error" 
							}); 	
							this.setData("_customerFieldEmpty_customer_id", true);
						}
						else {
							this.cellEdited("customer_id", "");
						}
                    }}
					useClickAwayListener={false}
					listCellType={LinkListCell}
					listCellProps={{
						urlHandler: value => `index.html?module=customers&action=view&id=${data.orig_id}&company=${data.companies_id}`,
						showErrorBorder: this.props.data['_customerIdIsDuplicate'] || this.props.data['_customerFieldEmpty_customer_id'],
						inEditMode: this.props.data['_customerIdIsDuplicate'] ? true : this.props.data.id < 0,
						value: this.props.data.customer_id,
					}}
					editable={customerEditable}
					width={this.props.columnWidthMap['customer_id']}
					name="customer_id"
					value={this.props.data['customer_id']} 
					onEdited={async (name, value) => {
						const currentData = {};
						currentData._customerIdIsDuplicate = false;
						currentData._customerFieldEmpty_customer_id = false;
						currentData[name] = value;
						if (this.props.data.id > 0) {
							try {
								const promise = await DataHandler.put({url: `accounts/${this.props.data.orig_id}/company/${this.props.data.companies_id}`}, currentData); 
								this.setDataAndUpdate(currentData);
								return promise;
							} catch (e) {
								if(e) {
									e.fail(response => {
										if(response.responseJSON.error === "EXISTING_ACCOUNT_ID") {
											currentData._customerIdIsDuplicate = true;
											this.setData(currentData);
											this.props.rowProps.enqueueSnackbar(this.tr("An account is already using custom id ${id}.", {id: value}), {
												variant: "error" 
											});
										}
									}); 
								}
							}
						} else {
							this.setDataAndUpdate(currentData);
						}
					}}
					/>,
			name:
				!this.props.isChild && this.props.data.id < 0 
                ? 
                	nameInputCell()
                :
				<TreeStructureIndicatorCell
					name="name"
					childrenVisible={this.props.childrenVisible}
					width={this.props.columnWidthMap['name']}
                    listCellProps={{ alignCell: true, textAlign: "left" }}
                    onExpanderClick={e => this.props.listRef.toggleShowChildren(this.props.data.id)}
                    rowData={this.props}
                    value={data.name}
				>
					{nameInputCell()}
				</TreeStructureIndicatorCell>,
			company:
				<TextInputCell
					width={this.props.columnWidthMap['company']}
					name="company"
					editable={false}
					value={customerCompany}
					onEdited={this.cellEdited} />,
			vatid:
				<TextInputCell
						editable={customerEditable}
						width={this.props.columnWidthMap['vatid']}
						name="vatid"
						editable={customerEditable}
						value={data.vatid}
						onEdited={this.cellEdited} />,

			customership_group:
				<AutoCompleteCell
					autoCompleteData={selectable_customership_groups}
					name="customership_group"
					value={this.props.data.customership_groups_id}
					editable={customerEditable}
					width={this.props.columnWidthMap['customership_group']}
					listCellProps={{
						showErrorBorder: this.props.data['_customerFieldEmpty_customership_groups_id'],
					}}
					onEdited={value => {
						this.cellEdited('customership_groups_id', value.id);
					}} />,
			enterprise_group:
				<AutoCompleteCell
					autoCompleteData={enterprise_groups.filter(g => g.id > 0)}
					addNoneOption
					name="enterprise_group"
					value={this.props.data.enterprise_groups_id}
					editable={customerEditable}
					width={this.props.columnWidthMap['enterprise_group']}
					onEdited={value => {
						this.cellEdited('enterprise_groups_id', value.id);
					}} />,					
			customer_type:
				<AutoCompleteCell
					autoCompleteData={selectable_customer_types}
					name="customer_type"
					value={this.props.data.customer_types_id}
					editable={customerEditable}
					width={this.props.columnWidthMap['customer_type']}
					listCellProps={{
						showErrorBorder: this.props.data['_customerFieldEmpty_customer_types_id'],
					}}
					// multiple={true}
					onEdited={value => {
						
						this.setData("customer_types_id", value.id);

						DataHandler.put({ url: `accounts/relation/${this.props.data.relations_id}` }, { type: value.id });
					}} />,
			locked:
				<EditableStatusCell
					value={{ id: data.locked, label: rowProps.statusLabels ? rowProps.statusLabels[data.locked] : '–' }}//{sharedData.companies.length > 1 ? { id: data.r_locked, label: rowProps.statusLabels[data.r_locked] } : { id: data.c_locked, label: rowProps.statusLabels[data.c_locked] }}
					options={sharedData.statuses}
					editable={customerEditable}
					onEdited={cellData => {
						if(cellData.id == 1) {
							/*
							const f = () => {
								const update = {
									customers_id: data.orig_id,
									companies_id: data.companies_id, 
									locked: 1,
									relations_id: data.relations_id || data.orig_id,
									type_id: data.customer_types_id
								}
								DataHandler.put({ url: `accounts/relations/${data.relations_id ? data.relations_id : data.id}`, data: update });
								this.setData("locked", cellData.id);
							};
							*/
							this.props.rowProps.lockCustomer(data);
						} else {
							this.props.rowProps.unLockCustomer(data);
						}
					}}
				/>,	
			email:
				<TextInputCell
					listCellType={LinkListCell}
					listCellProps={{
						urlHandler: value => `mailto:${data.email}`,
						valueHandler: value => value,
						editable: customerEditable,
						noTab: true,
						asNormalLink: true
					}}
					validation={['email']}
					width={this.props.columnWidthMap['email']}
					name="email"
					editable={customerEditable}
					value={data.email}
					onEdited={(name, value) => data.id > 0 ? this.validateEmail(value) : this.cellEdited(name, value)}
					 />,
			telephone:
				<TextInputCell
					editable={customerEditable}
					width={this.props.columnWidthMap['telephone']}
					name="telephone"
					editable={customerEditable}
					value={data.telephone}
					onEdited={this.cellEdited} />,
			address:
				<TextInputCell
					width={this.props.columnWidthMap['address']}
					name="address"
					editable={false}
					value={data.address}
					onEdited={this.cellEdited} />,
			postalcode:
				<TextInputCell
					width={this.props.columnWidthMap['postalcode']}
					name="postalcode"
					editable={false}
					value={data.postalcode}
					onEdited={this.cellEdited} />,
			city:
				<TextInputCell
					width={this.props.columnWidthMap['city']}
					name="city"
					editable={false}
					value={data.city}
					onEdited={this.cellEdited} />,
			country:
				<TextInputCell
					width={this.props.columnWidthMap['country']}
					name="country"
					editable={false}
					value={data.country}
					onEdited={this.cellEdited} />,
			www:
				<TextInputCell
					listCellType={LinkListCell}
					listCellProps={{
						urlHandler: value => value.startsWith("http://") || value.startsWith("https://") ? value : "http://" + value,
						valueHandler: value => value,
						editable: customerEditable,
						asNormalLink: true,
						target: "_blank",
						inEditMode: this.props.data.id < 0
					}}
					width={this.props.columnWidthMap['www']}
					name="www"
					value={data.www}
					editable={customerEditable}
					onEdited={this.cellEdited} />,
			account_manager:
				<AutoCompleteCell
                    autoCompleteData={sharedData.all_users ? [{id: "0", users_id: "0", name: this.tr("No account manager"), label: this.tr("No account manager"), deleted: "0"}, ...sharedData.all_users_locked.filter(u => u.id == data.account_manager), ...sharedData.all_users] : []}
					name="account_manager"
					value={data.account_manager}
					width={this.props.columnWidthMap['account_manager']}
					editable={customerEditable}
					onEdited={value => this.cellEdited('account_manager', value.id)} />,
			branchofbusiness:
				<TextInputCell
					width={this.props.columnWidthMap['branchofbusiness']}
					name="branchofbusiness"
					value={data.branchofbusiness}
					editable={customerEditable}
					onEdited={this.cellEdited} />,
			company_created:
				<DateCell
					editable={false}
					width={this.props.columnWidthMap['company_created']}
					value={data.company_created} />,
			last_visit:
				<DateCell
					editable={false}
					width={this.props.columnWidthMap['last_visit']}
					value={data.last_visit} />,
			crm_created:
				<DateCell
					editable={false}
					width={this.props.columnWidthMap['crm_created']}
					value={data.crm_created} />,
			crm_date:
				<DateCell
					listCellProps={{
						inEditMode: this.props.data.id < 0 && crmEditable
					}}
					editable={crmEditable}
					name="crm_date"
					width={this.props.columnWidthMap['crm_date']}
					value={data.crm_date}
					onEdited={this.cellEdited} />,

			crm_type:
				<AutoCompleteCell
					listCellProps={{
						inEditMode: this.props.data.id < 0 && crmEditable
					}}
					autoCompleteData={sharedData.activity_types}
					name="crm_type"
					value={crm_type}
					editable={crmEditable}
					width={this.props.columnWidthMap['crm_type']}
					onEdited={value => {
						this.cellEdited('crm_type', {
							crm_type: value.name,
							crm_types_id: value.id
						});
					}} />,

			crm_desc:
				<TextInputCell
					listCellType={LinkListCell}
					listCellProps={{
						urlHandler: value => `index.html?module=customers&action=view&id=${data.orig_id}`,
						valueHandler: value => value,
						editable: crmEditable,
						inEditMode: this.props.data.id < 0 && crmEditable
					}}
					editable={crmEditable}
					width={this.props.columnWidthMap['crm_desc']}
					name="crm_desc"
					value={data.crm_desc}
					onEdited={this.cellEdited} />,
			tags:
				<AutoCompleteCell
					autoCompleteData={tagOptions.filter(e => Number(e.disabled) < 1)}
					value={tags}
					multiple={true}
					placeholder="Tags"
					autoCompleteLabelKey="name" 
					editable={crmEditable}
					allowCreate={sharedData.tagPoolSettingsData ? sharedData.tagPoolSettingsData.create_tags_only_from_settings == "0" : false}
					onEdited={ selectedObject => {
						if (selectedObject.length < tags.length)
							this.onTagDelete(selectedObject, tags);

						const tagsString = selectedObject.map(t => t.value).join(",");
						const newTag = selectedObject.find(s => s.new == true);

						if (newTag) {
							this.onTagCreate(newTag.value, tagsString, tagOptions);
							return;
						} 

						this.cellEdited("tags", tagsString);
					}
					}			
				/>,
			is_parent:
				<AutoCompleteCell
					listCellProps={{
						inEditMode: false
					}}
					autoCompleteData={sharedData.is_parent_values}
					name="is_parent"
					value={this.props.data.is_parent}
					editable={false}
					width={this.props.columnWidthMap['is_parent']}
					/>,
			customer_unit: 
				<AutoCompleteCell
					listCellProps={{
						inEditMode: false
					}}
					autoCompleteData={sharedData.customer_units}
					name="customer_unit"
					value={this.props.data.customer_unit < 1 ? null : this.props.data.customer_unit /* sharedData.customer_units includes values what we dont want to show */}
					editable={false}
					width={this.props.columnWidthMap['customer_unit']}
					/>,
		};
        if (sharedData.custom_fields) 
            for(const customField of sharedData.custom_fields) {
                if(!customField.show_in_details)
                    continue;

                const CellType = this.customCells[customField.type] ? this.customCells[customField.type] : ListCell;
                const cellName   = `custom_${customField.id}`;
                const valueName  = this.props.data.hasOwnProperty(`${cellName}_id`) ? `${cellName}_id` : cellName;
                let value      = this.props.data[valueName];

                if(customField.type == "multiselect") {
                    value = value ? value.split(",").map(x => { 
                        const opt = customField.options.find(opt => opt.id == x);
                        return {
                            id: x,
                            value: x,
                            label: (opt && opt.name) || x,
                        }
                    }) : [];
                }

                const props = {
                    name: cellName,
                    key: cellName,
                    value,
                    listCellProps: {
                        editable: customerEditable,
                        inEditMode: this.props.data.id < 0,
                        showErrorBorder: this.props.data[`_customerFieldEmpty_${cellName}`]
                    },
                    multiple: customField.type == "multiselect" ? true : undefined,
                    onEdited: (a, b = undefined) => {
                        const name  = b !== undefined ? a : valueName;
                        let value = b !== undefined ? b : a;

                        if(customField.type == "multiselect")
                            value = value ? value.map(x => x.id).join(",") : "";

                        this.setData(name, value);

                        if(this.props.data.id < 0)
                            return;

                        DataHandler
                            .put({ url: `accounts/${this.props.data.orig_id}/company/${this.props.data.companies_id}`}, { row_update: true, custom: { [customField.id]: value && typeof value === "object" && value.hasOwnProperty("id") ? value.id : value } })
                            .fail((e) => {
                                const resp = e.responseJSON;
                                if (resp.error == 'fields_missing') {
                                    this.props.enqueueSnackbar(`${this.tr('Required fields not filled:')} ${(resp.missing || []).join(", ")}`, {
                                        variant: "error",
                                    });	
                                }
                                this.props.rowProps.refreshList();
                            });
                    }
                };

                (customField.required) && (props.validation = ["empty"]);
                (customField.options)  && (props.autoCompleteData = customField.options);

                if (customField.type === "link") {
                    props.listCellType = LinkListCell;
                    props.listCellProps.asNormalLink = true;
                    props.listCellProps.noTab = false;
                    props.value = prefixLink(props.value);

                    if (props.validation)
                        props.validation.push('link');
                    else
                        props.validation = ['link'];
               }

                cells[cellName] = <CellType {...props} />;
            }

		return cells;
	}
}

export default withSnackbar(CustomerListRow);
