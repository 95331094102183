import React from 'react';
import ListCell from '../ListCell';
import ListRow from '../ListRow';
import CurrencyListCell from '../CurrencyListCell';

export default class MassInvoicingSummaryRow extends ListRow {
    constructor(props) {
        super(props, {}, {}, 'list/rows/MassInvoicingSummaryRow');
    }

    defineClassName() {
        return 'massInvoicingSummaryRow';
    }

    defineCells() {
        const {
            rows,
            rowProps: { currency },
        } = this.props;

        let values = {};
        rows.map((row) => {
            Object.keys(row.data || {}).forEach((key) => {
                values[key] ? (values[key] += Number(row.data[key] || 0)) : (values[key] = Number(row.data[key] || 0));
            });
        });

        let cells = {};

        Object.keys(this.props.columnConfig).forEach((key) => {
            cells[key] = <CurrencyListCell className="totalcell" width={this.props.columnConfig[key].width} allowNaN={true} showZero={true} value={values[key]} currency={currency} editable={false} />;
        });

        const totalColumns = ['menu'];
        const emptyColumns = ['has_grouping_settings', 'project_id', 'checked', 'account', 'project', 'address', 'bill_language', 'e_operator', 'e_address', 'reverse_charge', 'project_manager'];

        totalColumns.forEach((col) => {
            cells[col] = (
                <ListCell noBorder width={(this.props.columnConfig[col] || {}).width || 0} className="totalcell overflow-visible" name={col} editable={false} alignCell value={this.tr('Total')} />
            );
        });

        emptyColumns.forEach((col) => {
            cells[col] = <ListCell noBorder width={(this.props.columnConfig[col] || {}).width || 0} className="totalcell" name={col} editable={false} alignCell value="" />;
        });

        return cells;
    }

    render() {
        const cells = this.defineCells();
        const className = ['summaryRow listElement row flex', this.defineClassName()].join(' ');

        return (
            <div className={className} style={{ width: this.props.listWidth }}>
                {this.props.columnOrder.map((columnName) => cells[columnName])}
            </div>
        );
    }
}
