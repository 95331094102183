import React, { Fragment } from 'react';

import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import SendIcon from '@mui/icons-material/Send';
import MailIcon from '@mui/icons-material/MailOutlined';
import ArchiveIcon from '@mui/icons-material/Archive';
import Delete from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import TaimerComponent from '../../TaimerComponent';

const styles = theme => ({
  textItemUnread: {
    fontSize: "12px",
    color: "#2d9ff7",
    fontWeight: "bold"
  },
  textItem: {
    fontSize: "12px",
    padding: "0px 16px 0px 0px" 
  },
  iconItemUnread: {
    color: "#2d9ff7",
  },
  menuItem: {
    padding: '0px 24px 0px 24px',
    height: '47px',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.primary.main,
      },
    },
  },
  primary: {},
  icon: {},
});

function ListItemLink(props) {
  const { selectedIndex, classes, mailfolderinfo } = props;
  var trans = new TaimerComponent(props, null, "mail/MailComponents/MailFolderLink");

  var icons = [<MailIcon />, <SendIcon />, <ArchiveIcon />, <WarningIcon /> , <Delete />];
  var iconsUnread = [<MailIcon classes={{root: props.classes.iconItemUnread}} />, <SendIcon classes={{root: props.classes.iconItemUnread}} />, <ArchiveIcon classes={{root: props.classes.iconItemUnread}} />, <WarningIcon classes={{root: props.classes.iconItemUnread}} /> , <Delete classes={{root: props.classes.iconItemUnread}} />];
  var orderedfolders = [{id:"inbox", displayName: trans.tr("Inbox")}, {id:"sentitems", displayName: trans.tr("Sent mails")}, {id:"archive", displayName: trans.tr("Archive")}, {id:"junkemail", displayName: trans.tr("Junkmail")}, {id:"deleteditems", displayName: trans.tr("Deleted mails")}];

  function DontShowZero(props) {
    const { number } = props;
  
    if (number > 0) {
      return <p className={"unreadcounttext"}>{number}</p>;
    } else {
      return <div></div>;
    }
  }

  return (
    <List subheader={<ListSubheader disableSticky="true" component="div">{trans.tr("Folders")}</ListSubheader>}>
      {orderedfolders.map(({ id, displayName, unreadItemCount}, i) => (
          mailfolderinfo ? mailfolderinfo[i].unreadItemCount > 0 ?
          <ListItem
            button 
            onClick={() => props.onClick(id, displayName)}
            className={classes.menuItem}
            selected={selectedIndex === id}
            >
            
            <ListItemIcon >
              {iconsUnread[i]}
            </ListItemIcon>    
               <ListItemText classes={{primary: props.classes.textItemUnread}} primary={displayName} />
            <DontShowZero number={mailfolderinfo[i].unreadItemCount} />
          </ListItem>
          :
          <ListItem
            button 
            onClick={() => props.onClick(id, displayName)}
            className={classes.menuItem}
            selected={selectedIndex === id}
            >
            <ListItemIcon >
              {icons[i]}
            </ListItemIcon>    
              <ListItemText classes={{primary: props.classes.textItem}} primary={displayName} />
            <DontShowZero number={0} />
          </ListItem>
          :
          <ListItem
            button 
            onClick={() => props.onClick(id, displayName)}
            className={classes.menuItem}
            selected={selectedIndex === id}
            >  
            <ListItemIcon >
              {icons[i]}
            </ListItemIcon>    
              <ListItemText classes={{primary: props.classes.textItem}} primary={displayName} />
            <DontShowZero number={0} />
          </ListItem>
      ))}
    </List>
  );
}

/* (mailfolderinfo) ? mailfolderinfo[i].unreadItemCount > 0 ? <ListItemText classes={{primary: props.classes.textItemUnread}} primary={displayName + " " + mailfolderinfo[i].unreadItemCount} /> : <ListItemText classes={{primary: props.classes.textItem}} primary={displayName} /> :
              <ListItemText classes={{primary: props.classes.textItem}} primary={displayName} /> */

ListItemLink.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListItemLink);