import React from 'react';

import "./FlexUtils.css";

function FlexContainer(props) {
	const id        = props?.id || "";
	const className = props?.className || "";
    const direction = props?.direction || "row";
    let style       = props?.style || {};

    style = {
        ...style,
        flexDirection: direction
    };

	return (<div 
        ref={props.forwardedRef} 
        id={id} 
        className={`flex-container ${className}`} 
        style={style}>
            {props.children}
    </div>);
}

function FlexChild(props) {
    const className = props.className !== undefined ? ` ${props.className}` : "";
    const basis     = props.basis ?? "0px";
    const style     = { 
        flex: !props.flexConfig ? `${props.weight} 1 ${basis}` : `${props.flexConfig}`,
        ...(props.style ? props.style : {})
    };

	return (
		<div ref={props.forwardedRef} 
            onClick={e => props.onClick !== undefined ? props.onClick(e) : null} 
            id={props.id} 
            className={`flex-child${className}`} 
            style={style}>
			{props.children}
		</div>
	);
}

export {
	FlexContainer,
	FlexChild
};
