import { eachDayOfInterval, format } from "date-fns";
import _, { cloneDeep, filter, forEach, sortBy } from "lodash";
import { Employee, Skill } from "../../helpers";
import { HourTotalFields, Milestone, Project, Resource, Task, TaskStatus } from "../../resourcing";
import { Priority, ProjectsShowMode, ResourcingFilters, ViewOptions } from "../../ResourcingView";

interface GanttUser {
    id: number;
    name: string;
}

interface GanttRowBase {
    $open: boolean;
    $new?: boolean;
    render?: 'split';

    start_date?: Date;
    end_date?: Date;

    drawnTaskDefaults?: {
        projects_id?: number;
        users_id?: number;
        users_hours?: { users_id: number, hours: number, hours_done: boolean, id: -1 }[];
        priorities_id?: number;
        skills_id?: number;
    }

    type: string;
    id: string;
    parent: string;
    text: string;
    is_root: boolean;
    users?: GanttUser[];
    done: boolean;

    /**
     * Allocated hours
     */
    hours: false | number;
    /**
     * Allocated hours (all time)
     */
    hours_all_time?: false | number;
    /**
     * Tracked hours
     */
    hours_done: false | number;
    /**
     * Tracked hours (all time)
     */
    hours_done_all_time?: false | number;
    /**
     * Resourced hours
     */
    budgeted: false | number;
    /**
     * Resourced hours (all time)
     */
    budgeted_all_time?: false | number;
    /**
     * Remaining hours
     */
    remaining: false | number;
    /**
     * Remaining hours (all time)
     */
    remaining_all_time?: false | number;

    projectInfo?: Project;
    resource?: Resource;

    progress: false | number;

    customer?: string;
    project?: string;

    priority?: Priority;
    skill?: string;

    matched?: boolean;
}

interface GanttRowExtra extends GanttRowBase {
    start_date: Date;
    end_date: Date;
}

export interface GanttRowGrouping extends GanttRowBase {
    type: 'group';
}

export interface GanttRowUser extends GanttRowBase {
    type: 'user';
    user?: Employee;
}

interface GanttRowProject extends GanttRowExtra {
    type: 'taimer-project';
    projectInfo: Project;
    customer: string;
    project: string;
    project_number: string;
    viewable: boolean;
    can_add_task: boolean;
    allocated: number | false;
    tree: string[];
    isTreeRoot: boolean;
    childProjects: string[];
}

interface GanttResourceRow extends GanttRowExtra {
    resource: Resource;
    done: boolean;
    editable: boolean;
}

interface GanttRowTask extends GanttResourceRow {
    type: 'task';
    progress: number;
}

interface GanttRowPeriod extends GanttResourceRow {
    type: 'task-part';
}

interface GanttRowMilestone extends GanttResourceRow {
    type: 'milestone';
}

interface GanttRowTotal extends GanttRowExtra {
    type: 'total';
    customer: string;
    project: string;
    project_number: string;
    /**
     * Allocated hours
     */
    hours: number;
    /**
     * Tracked hours
     */
    hours_done: number;
    /**
     * Resourced hours
     */
    budgeted: number;
    /**
     * Remaining hours
     */
    remaining: number;
}

export type GanttRow = GanttRowProject | GanttRowTask | GanttRowPeriod | GanttRowMilestone | GanttRowTotal | GanttRowGrouping | GanttRowUser;

interface GanttFormatingOptions {
    filters: ResourcingFilters;
    unassignedLabel: string;
    noneLabel: string;
    grouping: string;
    employees: Dictionary<Employee>;
    useProjectTrees: boolean;
    viewOptions: ViewOptions;
    expandedRows: Dictionary<boolean>;
    lastOrder?: OrderData | null;
    priorities: Priority[];
    skills: Dictionary<Skill>;
    usersAllowedToShow: false | Dictionary<number>;
}

const userInputs = [
    'customer', 'customer_name', 'project', 'project_name', 'project_number', 'users_name', 'text', 'skill', 'additional_description', 'description'
];

function escapeGanttRow(resource: GanttRow) {
    const row = { ...resource };

    for (const col of userInputs) {
        if (row[col] !== undefined && row[col] !== null) {
            row[`${col}_unescaped`] = String(row[col]);
            row[col] = _.escape(String(row[col]));
        }
    }

    return row;
}

interface GanttRows {
    roots: Dictionary<GanttRow>;
    allRows: Dictionary<GanttRow>;
}

function addSkill(options: GanttFormatingOptions, target: GanttRows, skill?: Skill, groupingParent: string | undefined = undefined): string | undefined {
    const actualId = groupingParent ? `${groupingParent}_${skill?.id ?? 0}` : `sk-${skill?.id ?? 0}`;

    if (target.allRows[actualId]) {
        // Already added
        return actualId;
    }

    const row: GanttRowGrouping = {
        $open: !!options.expandedRows[actualId],
        id: actualId,
        type: 'group',
        parent: groupingParent ?? '',
        drawnTaskDefaults: {
            ...groupingParent && target.allRows[groupingParent]?.drawnTaskDefaults,
            skills_id: skill?.id,
        },
        text: skill?.name ?? options.noneLabel,
        is_root: groupingParent === undefined,
        budgeted: false,
        hours: false,
        hours_done: false,
        remaining: false,
        progress: false,
        done: false,
    };

    target.allRows[row.id] = row;
    return row.id;
}

function addPriority(options: GanttFormatingOptions, target: GanttRows, priority: Priority, groupingParent: string | undefined = undefined): string | undefined {
    const actualId = groupingParent ? `${groupingParent}_${priority.id}` : `p-${priority.id}`;

    if (target.allRows[actualId]) {
        // Already added
        return actualId;
    }

    const row: GanttRowGrouping = {
        $open: !!options.expandedRows[actualId],
        id: actualId,
        type: 'group',
        parent: groupingParent ?? '',
        drawnTaskDefaults: {
            ...groupingParent && target.allRows[groupingParent]?.drawnTaskDefaults,
        },
        text: priority.name,
        is_root: false,
        budgeted: false,
        hours: false,
        hours_done: false,
        remaining: false,
        progress: false,
        done: false,
    };

    target.allRows[row.id] = row;
    return row.id;
}

function addUser(options: GanttFormatingOptions, target: GanttRows, user?: Employee, groupingParent: string | undefined = undefined): string | undefined {
    const actualId = groupingParent ? `${groupingParent}_${user?.id ?? 0}` : `u-${user?.id ?? 0}`;

    if (target.allRows[actualId]) {
        // Already added
        return actualId;
    }

    const row: GanttRowUser = {
        $open: !!options.expandedRows[actualId],
        id: actualId,
        user,
        type: 'user',
        parent: groupingParent ?? '',
        drawnTaskDefaults: {
            ...groupingParent && target.allRows[groupingParent]?.drawnTaskDefaults,
            users_id: user?.id,
            users_hours: user ? [
                { id: -1, hours: 1, hours_done: false, users_id: user?.id }
            ] : [],
        },
        text: user?.name ?? options.unassignedLabel,
        is_root: false,
        budgeted: 0,
        budgeted_all_time: 0,
        hours: 0,
        hours_all_time: 0,
        hours_done: 0,
        hours_done_all_time: 0,
        remaining: 0,
        remaining_all_time: 0,
        progress: false,
        done: false,
    };

    target.allRows[row.id] = row;
    return row.id;
}

function addProject(options: GanttFormatingOptions, target: GanttRows, projects: Dictionary<Project>, id: number, groupingParent: string | undefined = undefined, useProjectHours = false): string | undefined {
    const project = projects[id];

    if (!project) {
        console.error('missing project', id);
        return undefined;
    }

    const actualId = groupingParent ? `${groupingParent}_${project.id}` : project.id;

    if (target.allRows[actualId]) {
        // Already added
        return actualId;
    }

    let actualParent = groupingParent;
    let isTreeRoot = true;

    if (options.useProjectTrees && project.parentid > 0) {
        const treeParent = addProject(options, target, projects, project.parentid, groupingParent, useProjectHours);
        if (treeParent) {
            actualParent = treeParent;
            isTreeRoot = false;
        }
    }

    const parentRow = actualParent ? target.allRows[actualParent] : undefined;

    if (parentRow && parentRow.type === 'taimer-project') {
        let currentParent: GanttRow | undefined = parentRow;

        while (currentParent) {
            if (currentParent.type === 'taimer-project') {
                currentParent.childProjects.push(actualId);
            }

            currentParent = target.allRows[currentParent.parent];
        }
    }

    const row: GanttRowProject = {
        $open: !!options.expandedRows[actualId],
        projectInfo: project,
        id: actualId,
        parent: actualParent ?? '',
        drawnTaskDefaults: {
            ...parentRow?.drawnTaskDefaults,
            projects_id: project.projects_id,
        },
        text: project.text,
        type: 'taimer-project',
        start_date: project.start_date,
        end_date: project.end_date,
        viewable: project.viewable,
        can_add_task: project.can_add_task,
        customer: project.customer,
        project: project.text,
        project_number: project.project_number,
        is_root: false,
        allocated: useProjectHours ? project.projects_allocated ?? false : 0,
        hours: useProjectHours ? project.maxhours ?? false : 0,
        hours_done: useProjectHours ? project.hours_done : 0,
        budgeted: useProjectHours ? project.budgeted : 0,
        remaining: useProjectHours ? project.remaining : 0,
        hours_all_time: useProjectHours ? project.maxhours ?? false : 0,
        hours_done_all_time: useProjectHours ? project.hours_done : 0,
        budgeted_all_time: useProjectHours ? project.budgeted : 0,
        remaining_all_time: useProjectHours ? project.remaining : 0,
        progress: project.progress,
        done: false,
        tree: parentRow && parentRow.type === 'taimer-project' ? [...parentRow.tree, parentRow.id] : [],
        childProjects: [],
        isTreeRoot,
    };

    target.allRows[row.id] = row;
    return row.id;
}

interface TaskPeriod {
    start: Date;
    end: Date;
}

function makeTaskRow(options: GanttFormatingOptions, gantt: GanttRows, resource: Task, id: string, parent: string | undefined, priority?: Priority, skill?: Skill, hours?: HourTotalFields | null | false, hoursAlltime?: HourTotalFields | null | false, originalResource?: Task): GanttRowTask {
    const { employees, expandedRows } = options;
    const users: GanttUser[] = [];

    const datesMap: Dictionary<boolean> = {};
    let renderAsSplits = false;

    for (const uh of resource.users_hours) {
        const user = employees[uh.users_id];

        if (!user) {
            console.error('missing user', uh);
            continue;
        }

        if (uh.periods.length && options.viewOptions.showSplits) {
            for (const period of uh.periods) {
                try {
                    const days = eachDayOfInterval({
                        start: period.start,
                        end: period.end,
                    });

                    for (const day of days) {
                        datesMap[format(day, 'YYYY-MM-DD')] = true;
                    }
                } catch (error) {
                    console.error(error, { period });
                }
            }
        }

        users.push({
            id: user.id,
            name: user.name,
        });
    }

    const ganttRowCommon = {
        resource: originalResource ?? resource,
        text: resource.description,
        done: resource.done,
        editable: resource.editable,
    }

    if (options.viewOptions.showSplits) {
        try {
            const days = eachDayOfInterval({
                start: resource.start_date,
                end: resource.end_date,
            });

            let firstStart: Date | null = null;
            let lastEnd: Date | null = null;

            let start: Date | null = null;
            let end: Date | null = null;

            const taskPeriods: TaskPeriod[] = [];

            for (const day of days) {
                const d = format(day, 'YYYY-MM-DD');

                if (!datesMap[d]) {
                    if (start && end) {
                        taskPeriods.push({ start, end });
                    }

                    start = null;
                    end = null;
                } else {
                    if (!firstStart) {
                        firstStart = day;
                    }

                    if (!start) {
                        start = day;
                    }

                    end = day;
                    lastEnd = day;
                }
            }

            if (start && end) {
                taskPeriods.push({ start, end });
            }

            if (taskPeriods.length > 1 || firstStart !== resource.start_date || lastEnd !== resource.end_date) {
                renderAsSplits = true;

                let i = 0;

                for (const { start, end } of taskPeriods) {
                    const part_id = `${id}_part${i++}`;

                    const row: GanttRowPeriod = {
                        $open: false,
                        render: renderAsSplits ? 'split' : undefined,
                        id: part_id,
                        parent: id,
                        type: 'task-part',
                        ...ganttRowCommon,
                        start_date: start,
                        end_date: end,
                        progress: false,
                        budgeted: false,
                        hours: false,
                        hours_done: false,
                        remaining: false,
                        is_root: false,
                    };
                    gantt.allRows[row.id] = row;
                }
            }
        } catch (error) {
            console.error(error, { resource });
        }
    }

    const h = hours ?? resource.periodTotal;
    const ha = hoursAlltime ?? resource.allTotal;

    const row: GanttRowTask = {
        $open: !!expandedRows[id],
        render: renderAsSplits ? 'split' : undefined,
        id,
        parent: parent ?? '',
        type: 'task',
        ...ganttRowCommon,
        start_date: resource.start_date,
        end_date: resource.end_date,
        progress: resource.progress,
        is_root: false,
        users,
        priority,
        skill: skill?.name,
        matched: resource.matched,
        // Period
        hours: false,
        budgeted: false,
        hours_done: false,
        remaining: false,
        // All time
        hours_all_time: false,
        budgeted_all_time: false,
        hours_done_all_time: false,
        remaining_all_time: false,
    };

    if (h !== false) {
        addHours(options, row, h);
    }

    if (ha !== false) {
        addAllTimeHours(options, row, ha);
    }

    gantt.allRows[row.id] = row;

    return row;
}

function makeMilestoneRow(options: GanttFormatingOptions, gantt: GanttRows, resource: Milestone, id: string, parent: string | undefined, priority?: Priority, skill?: Skill): GanttRowMilestone {
    const { employees, expandedRows } = options;
    const users: GanttUser[] = [];

    const user = employees[resource.users_id];
    if (user) {
        users.push({
            id: user.id,
            name: user.name,
        });
    } else {
        console.error('missing user', resource);
    }

    const row: GanttRowMilestone = {
        resource,
        $open: !!expandedRows[id],
        id,
        parent: parent ?? '',
        text: resource.text,
        type: 'milestone',
        start_date: resource.start_date,
        end_date: resource.end_date,
        done: resource.done,
        editable: resource.editable,
        is_root: false,
        users,
        hours: false,
        budgeted: false,
        hours_done: false,
        remaining: false,
        progress: false,
        priority,
        skill: skill?.name,
    };

    gantt.allRows[row.id] = row;

    return row;
}

function addHours(options: GanttFormatingOptions, target?: GanttRow, source?: HourTotalFields | null) {
    if (!target || !source)
        return;

    target.hours = (target.hours || 0) + (source.allocated ?? 0);
    target.budgeted = (target.budgeted || 0) + (source.resourced ?? 0);
    target.hours_done = (target.hours_done || 0) + (source.tracked ?? 0);

    if (options.viewOptions.includeNegative || target.type === 'task') {
        target.remaining = (target.remaining || 0) + (source.remaining ?? 0);
    } else {
        target.remaining = (target.remaining || 0) + (Math.max(0, source.remaining ?? 0));
    }
}

function addAllTimeHours(options: GanttFormatingOptions, target?: GanttRow, source?: HourTotalFields | null) {
    if (!target || !source)
        return;

    target.hours_all_time = (target.hours_all_time || 0) + (source.allocated ?? 0);
    target.budgeted_all_time = (target.budgeted_all_time || 0) + (source.resourced ?? 0);
    target.hours_done_all_time = (target.hours_done_all_time || 0) + (source.tracked ?? 0);

    if (options.viewOptions.includeNegative || target.type === 'task') {
        target.remaining_all_time = (target.remaining_all_time || 0) + (source.remaining ?? 0);
    } else {
        target.remaining_all_time = (target.remaining_all_time || 0) + (Math.max(0, source.remaining ?? 0));
    }
}

export function formatDataForGantt(options: GanttFormatingOptions, projects: Project[], resources: Resource[]): [GanttRow[], OrderData] {
    const { grouping, employees, viewOptions, usersAllowedToShow } = options;

    const gantt: GanttRows = {
        roots: {},
        allRows: {},
    }

    const unassginedUser: Employee = {
        id: 0,
        companies_id: 0,
        deleted: false,
        locked: 0,
        name: options.unassignedLabel,
        projects: [],
        read_companies: [],
        read_companies_all: [],
        read_companies_projects_only: [],
    }

    const projectsById = _.keyBy(projects, x => x.projects_id);
    const resourcesByProject = _.groupBy(resources, x => x.projects_id);
    const resourcesByID = _.groupBy(resources, x => x.id);
    const prioritiesById = _.keyBy(options.priorities, x => x.id);
    const skillsById = _.keyBy(options.skills, x => x.id);

    if (viewOptions.projectsShowMode !== ProjectsShowMode.WithoutTasks) {
        for (const resource of resources) {
            // if (resource.done && !viewOptions.showDone)
            //     continue;
            // else if (!resource.done && !viewOptions.showOnGoing)
            //     continue;

            if (!viewOptions.showOnGoing && resource.type === 'task' && resource.status === TaskStatus.Ongoing) {
                continue;
            }

            if (!viewOptions.showOverdue && resource.type === 'task' && resource.status === TaskStatus.Overdue) {
                continue;
            }

            if (!viewOptions.showDone && resource.type === 'task' && resource.status === TaskStatus.Done) {
                continue;
            }

            if (!viewOptions.showMilestones && resource.type === 'milestone') {
                continue;
            }

            const skill = skillsById[resource.skills_id];
            const priority = prioritiesById[resource.priorities_id] ?? prioritiesById[3];

            // User grouping is special case, we have to create row for each assignee
            if (grouping === 'user' && resource.type === 'task') {
                let rowWasAdded = false;

                const combined = [...resource.users_hours, ...resource.users_hours_other];

                for (const uh of combined) {
                    if (usersAllowedToShow !== false && !usersAllowedToShow[uh.users_id])
                        continue;
                    else if (uh.done && !viewOptions.showDone)
                        continue;
                    else if (!uh.done && !viewOptions.showOnGoing)
                        continue;

                    const user = employees[uh.users_id];

                    if (!user) {
                        console.error('missing user', uh);
                        continue;
                    }

                    rowWasAdded = true;

                    const userRowId = addUser(options, gantt, user);
                    const userRow = userRowId ? gantt.allRows[userRowId] : undefined;

                    const rowId = `${resource.id}-${uh.users_id}`;

                    const projectRowId = addProject(options, gantt, projectsById, resource.projects_id, userRowId, false);

                    if (!projectRowId)
                        continue;

                    const projectRow = projectRowId ? gantt.allRows[projectRowId] : undefined;

                    const newResource: Task = {
                        ...resource,
                        users_hours: [
                            uh,
                        ],
                    }

                    const parentId = resource.parent_id > 0 && resourcesByID[resource.parent] ? `${resource.parent}-${uh.users_id}` : projectRowId;

                    makeTaskRow(options, gantt, newResource, rowId, parentId, priority, skill, uh.periodTotal, uh.allTotal, resource);

                    // Add to project / user totals
                    addHours(options, projectRow, uh.periodTotal);
                    addHours(options, userRow, uh.periodTotal);

                    addAllTimeHours(options, projectRow, uh.allTotal);
                    addAllTimeHours(options, userRow, uh.allTotal);
                }

                // Unassgined
                if ((!rowWasAdded || resource.unassignedHours) && (usersAllowedToShow === false || !usersAllowedToShow[0])) {
                    const userRowId = addUser(options, gantt);
                    const userRow = userRowId ? gantt.allRows[userRowId] : undefined;

                    const projectRowId = addProject(options, gantt, projectsById, resource.projects_id, userRowId, false);

                    if (!projectRowId)
                        continue;

                    const projectRow = projectRowId ? gantt.allRows[projectRowId] : undefined;

                    const rowId = `${resource.id}-${userRowId}`;

                    const parentId = resource.parent_id > 0 && resourcesByID[resource.parent] ? `${resource.parent}-0` : projectRowId;

                    const row = makeTaskRow(options, gantt, resource, rowId, parentId, priority, skill);
                    row.users = [];

                    row.budgeted = 0;
                    row.budgeted_all_time = 0;
                    row.hours_done = 0;
                    row.hours_done_all_time = 0;
                    row.hours = resource.unassignedHours?.resourced || 0;
                    row.hours_all_time = resource.unassignedHours?.resourced_all_time || 0;
                    row.remaining = resource.unassignedHours?.resourced || 0;
                    row.remaining_all_time = resource.unassignedHours?.resourced_all_time || 0;

                    // Add to user totals
                    if (userRow && userRow.hours !== false)
                        userRow.hours += resource.unassignedHours?.resourced || 0;

                    if (userRow && userRow.hours_all_time !== false && userRow.hours_all_time !== undefined)
                        userRow.hours_all_time += resource.unassignedHours?.resourced_all_time || 0;

                    if (userRow && userRow.remaining !== false)
                        userRow.remaining += resource.unassignedHours?.resourced || 0;

                    if (userRow && userRow.remaining_all_time !== false && userRow.remaining_all_time !== undefined)
                        userRow.remaining_all_time += resource.unassignedHours?.resourced_all_time || 0;

                    // Add to project totals
                    if (projectRow && projectRow.hours !== false)
                        projectRow.hours += resource.unassignedHours?.resourced || 0;

                    if (projectRow && projectRow.hours_all_time !== false && projectRow.hours_all_time !== undefined)
                        projectRow.hours_all_time += resource.unassignedHours?.resourced_all_time || 0;

                    if (projectRow && projectRow.remaining !== false)
                        projectRow.remaining += resource.unassignedHours?.resourced || 0;

                    if (projectRow && projectRow.remaining_all_time !== false && projectRow.remaining_all_time !== undefined)
                        projectRow.remaining_all_time += resource.unassignedHours?.resourced_all_time || 0;


                    gantt.allRows[row.id] = row;
                }
            } else {
                let groupingParent: string | undefined = undefined;
                let totalsParent: string | undefined = undefined;
                let rowId = resource.id;

                if (resource.type === 'task' && resource.parent_id > 0 && resourcesByID[resource.parent]) {
                    totalsParent = addProject(options, gantt, projectsById, resource.projects_id);
                    groupingParent = `r-${resource.parent_id}`;
                } else if (grouping === 'customer') {
                    groupingParent = addProject(options, gantt, projectsById, resource.projects_id);
                } else if (grouping === 'user' && resource.type === 'milestone') {
                    const user = employees[resource.users_id];

                    if (!user) {
                        console.error('missing user', resource);
                    }

                    const userRowId = addUser(options, gantt, user);
                    rowId = `${resource.id}-${userRowId}`;

                    groupingParent = addProject(options, gantt, projectsById, resource.projects_id, userRowId);
                } else if (grouping === 'priority') {
                    const priorityRow = addPriority(options, gantt, priority);
                    groupingParent = addProject(options, gantt, projectsById, resource.projects_id, priorityRow);
                } else if (grouping === 'skill') {
                    if (!skill) {
                        console.error('missing skill', resource);
                    }

                    const skillRow = addSkill(options, gantt, skill);
                    groupingParent = addProject(options, gantt, projectsById, resource.projects_id, skillRow);
                } else {
                    console.error('unhandled grouping', grouping);
                }

                if (!groupingParent)
                    continue;

                const actualTotalParent = totalsParent ?? groupingParent;
                const parentRow = actualTotalParent ? gantt.allRows[actualTotalParent] : undefined;

                if (resource.type === 'task') {
                    const combined = [...resource.users_hours, ...resource.users_hours_other];

                    const taskRow = makeTaskRow(options, gantt, resource, rowId, groupingParent, priority, skill, false, false);

                    taskRow.hours = 0;
                    taskRow.hours_all_time = 0;
                    taskRow.remaining = 0;
                    taskRow.remaining_all_time = 0;

                    for (const uh of combined) {
                        const user = uh.users_id === 0 ? unassginedUser : employees[uh.users_id];

                        if (usersAllowedToShow !== false && !usersAllowedToShow[uh.users_id])
                            continue;

                        addHours(options, taskRow, uh.periodTotal);
                        addAllTimeHours(options, taskRow, uh.allTotal);
                        addHours(options, parentRow, uh.periodTotal);
                        addAllTimeHours(options, parentRow, uh.allTotal);
                    }

                    if (resource.unassignedHours && (usersAllowedToShow === false || usersAllowedToShow[0])) {
                        taskRow.hours += resource.unassignedHours.resourced;
                        taskRow.hours_all_time += resource.unassignedHours.resourced_all_time;
                        taskRow.remaining += resource.unassignedHours.resourced;
                        taskRow.remaining_all_time += resource.unassignedHours.resourced_all_time;

                        if (parentRow && parentRow.hours !== false)
                            parentRow.hours += resource.unassignedHours.resourced;

                        if (parentRow && parentRow.hours_all_time !== false && parentRow.hours_all_time !== undefined)
                            parentRow.hours_all_time += resource.unassignedHours.resourced_all_time;


                        if (parentRow && parentRow.remaining !== false)
                            parentRow.remaining += resource.unassignedHours.resourced;

                        if (parentRow && parentRow.remaining_all_time !== false && parentRow.remaining_all_time !== undefined)
                            parentRow.remaining_all_time += resource.unassignedHours.resourced_all_time;
                    }
                }
                else if (resource.type === 'milestone') {
                    makeMilestoneRow(options, gantt, resource, rowId, groupingParent);
                }
            }
        }
    }

    // Make total rows for project groups
    const projectRows = filter(gantt.allRows, x => x.type === 'taimer-project' && x.childProjects.length > 0);

    for (const p of projectRows) {
        if (p.type !== 'taimer-project') // For type hinting
            continue;

        const totalRow: GanttRowTotal = {
            ...p,
            type: 'total',
            id: `${p.id}_total`,
            hours: 0,
            hours_done: 0,
            budgeted: 0,
            remaining: 0,
        }

        p.parent = totalRow.id;

        for (const childId of p.childProjects) {
            const sp = gantt.allRows[childId];

            if (sp) {
                totalRow.hours += sp.hours || 0;
                totalRow.hours_done += sp.hours_done || 0;
                totalRow.budgeted += sp.budgeted || 0;
                totalRow.remaining += sp.remaining && sp.remaining > 0 ? sp.remaining : 0;

                if (sp.start_date && totalRow.start_date > sp.start_date) {
                    totalRow.start_date = sp.start_date;
                }

                if (sp.end_date && totalRow.end_date < sp.end_date) {
                    totalRow.end_date = sp.end_date;
                }
            }
        }

        gantt.allRows[totalRow.id] = totalRow;
    }

    // Add empty projects
    if (grouping !== 'user' && viewOptions.projectsShowMode !== ProjectsShowMode.WithTasks) {
        const allProjectsVisible = _.map(projects, 'projects_id');
        for (const id of allProjectsVisible) {
            const project = projectsById[id];

            if (!project)
                continue;

            if (viewOptions.projectsShowMode === ProjectsShowMode.WithoutTasks && resourcesByProject[id])
                continue;

            addProject(options, gantt, projectsById, id);
        }
    }

    _.each(gantt.allRows, (row) => {
        if (row.parent === "")
            row.is_root = true;
    });

    return sortGanttTasks(_.map(gantt.allRows, escapeGanttRow), options.lastOrder);
}

interface ChildrenMapItem {
    id: string;
    tasks: GanttRow[];
    projects: GanttRow[];
}

export interface OrderData {
    roots: string[];
    subs: Dictionary<{
        tasks: string[];
        projects: string[];
    }>;
}

/**
 * 
 */
function sortGanttTasks(tasks: GanttRow[], lastOrder: OrderData | undefined | null = null): [GanttRow[], OrderData] {
    const orderData = lastOrder !== null ? cloneDeep(lastOrder) : { roots: [], subs: {} };

    const roots: GanttRow[] = [];
    const childrenMap: Dictionary<ChildrenMapItem> = {};
    const idToItem = {};

    for (const task of tasks) {
        idToItem[task.id] = task;

        if (task.parent === '') {
            roots.push(task);
        } else {
            if (!childrenMap[task.parent]) {
                childrenMap[task.parent] = {
                    id: task.parent,
                    tasks: [],
                    projects: [],
                };
            }

            if (task.type === 'taimer-project' || task.type === 'total')
                childrenMap[task.parent].projects.push(task);
            else
                childrenMap[task.parent].tasks.push(task);
        }
    }

    forEach(childrenMap, (group, key) => {
        if (!orderData.subs[group.id])
            orderData.subs[group.id] = { tasks: [], projects: [] }

        const od = orderData.subs[group.id];

        for (const task of sortGanttGroup(group.tasks)) {
            if (od.tasks.includes(task.id))
                continue;

            od.tasks.push(task.id);
        }

        for (const project of sortGanttGroupProjects(group.projects)) {
            if (od.projects.includes(project.id))
                continue;

            od.projects.push(project.id);
        }
    });

    const rootsInOrder = sortGanttGroupProjects(roots);

    for (const root of rootsInOrder) {
        if (orderData.roots.includes(root.id))
            continue;

        orderData.roots.push(root.id);
    }

    const finalOrder = [];

    applyOrderData(finalOrder, orderData.roots, orderData);

    const finalItems: GanttRow[] = [];

    for (const id of finalOrder) {
        const item = idToItem[id];

        if (item)
            finalItems.push(idToItem[id]);
    }

    return [finalItems, orderData];
}

function applyOrderData(output, current, orderData) {
    for (const item of current) {
        output.push(item);

        const od = orderData.subs[item];

        // No subitems
        if (!od)
            continue;

        applyOrderData(output, od.tasks, orderData);
        applyOrderData(output, od.projects, orderData);
    }
}

function sortGanttGroupProjects(tasks: GanttRow[]) {
    return sortBy(tasks, [(t) => String(t.customer).toLowerCase(), (t) => String(t.project).toLocaleLowerCase()]);
}

function sortGanttGroup(tasks: GanttRow[]) {
    return sortBy(tasks, ['start_date', 'end_date']);
}