import React from 'react';
import cn from 'classnames';

import DataHandler from './../../general/DataHandler';


/* css */
import './InsightsBase.css';

/* material-ui */

/* others */
import moment from 'moment';
import _, { throttle } from 'lodash';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';

import { treeFormatDataForList, createTreeOptions } from "../../list/ListUtils";

import { endOfMonth, startOfMonth, addMonths } from "date-fns";
import withStyles from '@mui/styles/withStyles';
import InsightFilter from './InsightFilter';
import InsightSlider from './InsightSlider';

import Loading from './img/loading.svg';
import { Tabs, Tab, Tooltip, Popover } from '@mui/material';
import PageTopSection from '../../general/PageTopSection';

import colors from "../../colors";

import Button from '@mui/material/Button';
import LoaderButton from '../../general/LoaderButton';
import SearchButton from '../../general/icons/search.svg';
import { withSnackbar } from 'notistack';
import NoPermissionOverlay from '../../overlays/NoPermissionOverlay';
import WithTabs from '../../navigation/WithTabs';
import { ArrowDropDown, RemoveRedEye } from '@mui/icons-material';

export const styles = theme => ({
});

const getDefaultAutoComplete = () => ({
    company: 0,
    projects: [],
    tags: [],
    customers: [],
    customership_groups: [],
    project_types: [],
    project_categories: [],
    reporting_group: [],
    users: [],
    teams: [],
    pipelines: [],
});

class InsightsBase extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        width: 6,
        rightGroup: "dashboard",
        right: "invoicing_read",
        infoParams: {},
        defaultFilters: {},
        forcedFilters: {},
        outsideFiltersState: {},
        insightFilterProps: {},
        autocompleteParams: {},
        useSearchButton: false,
        defaultTime: "month",
        monthRange: [0, 0],
        pipelineByDefault: true,
        usesPipelines: true,
        allowMultipleCompanies: false, // Incomplete
        fullWidthContent: false,
        onChangeOutsideFilters: () => { },
        allowFullDateRange: false,
        allowEmptyDateRange: false,
        showLockedUsersWithTag: true,
    }

    localStorageKey = "";

    getDefaultTimeRange = () => {
        const { defaultTime, monthRange } = this.props;
        const { functions: { getFinancialYear } } = this.context;

        let dateRange = { start: startOfMonth(new Date()), end: endOfMonth(new Date()) };

        if (defaultTime === "year") {
            dateRange = getFinancialYear(dateRange.start);
        } else if (defaultTime === "month") {
            dateRange = { start: addMonths(startOfMonth(new Date()), monthRange[0]), end: addMonths(endOfMonth(new Date()), monthRange[1]) };
        }

        return dateRange;
    }

    constructor(props, context) {
        super(props, context, "dashboard/insights/InisightsBase");

        this.timespanErrorKey = null;

        const dateRange = { start: new Date(), end: new Date() };

        if (props.savedQueryName)
            this.localStorageKey = `insight_${props.savedQueryName}`;

        let savedFilter = {};

        if (this.localStorageKey && localStorage[this.localStorageKey]) {
            savedFilter = JSON.parse(localStorage[this.localStorageKey]);
            delete savedFilter.financialYear;
        }

        if (savedFilter.company && !context.functions.checkPrivilege(props.rightGroup, props.right, savedFilter.company)) {
            delete savedFilter.company;
        }

        const baseFilters = {
            company: context.functions.getCompany(props.rightGroup, props.right, false, true),
            dateRange: {
                startDate: dateRange.start,
                endDate: dateRange.end,
                valid: true,
                key: "selection"
            },
            pipeline: 0,
            pipeline_ids: [],
            projects_ids: [],
            project_tags: [],
            customers_ids: [],
            customer_tags: [],
            customership_group_ids: [],
            project_types_ids: [],
            project_categories_ids: [],
            reporting_group_ids: [],
            users_ids: [],
            sales_agents: [],
            groups_ids: [],
            advanced_search: {},
            selectedQuery: 0,
            status: 0,
            ...props.defaultFilters,
            ...props.forcedFilters,
        };

        this.state = {
            autoCompleteDataLoaded: false,
            autoCompleteData: getDefaultAutoComplete(),
            filters: {
                ...baseFilters,
                ...savedFilter,
                ...props.forcedFilters,
            },
            activeFilters: {
                ...baseFilters,
                ...savedFilter,
                ...props.forcedFilters,
            },
            companies: {},
            queries: [],
            loading: false,
            permissionMissing: false,
        };

        this.filterKey = 0;
        this.defaultFilters = _.cloneDeep(baseFilters);
        this.currentBaseFilters = _.cloneDeep(baseFilters);

        this.refFilters = React.createRef();
    }

    async componentDidMount() {
        const { right, rightGroup, usesPipelines, infoParams } = this.props;

        super.componentDidMount();

        try {
            const data = await DataHandler.get({ url: `dashboard/companies`, rightGroup, right, include_pipelines: usesPipelines ? 1 : 0, ...infoParams });

            const { companies } = data;

            if (companies.length == 0) {
                this.setState({ permissionMissing: true })
                return;
            }

            this.setState({ companies }, this.initFilters);
        } catch (error) {
            this.setState({ permissionMissing: true })
            return;
        }

        document.addEventListener('keydown', this.onKeyPress);
    }

    componentDidUpdate = (oldProps) => {
        if (oldProps.defaultFilters != this.props.defaultFilters && this.props.allowDefaultFiltersUpdate) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    ...this.props.defaultFilters
                }
            })
        }
    }

    getDefaultFilters = (company, useStoredState = false) => {
        const { pipelineByDefault, defaultFilters } = this.props;
        const { companies } = this.state;

        const companyData = companies[company];

        if (!companyData)
            return {};

        let pipelines = [];

        if (pipelineByDefault === "all") {
            pipelines = companyData.pipelines.map(x => x.id);
        } else if (pipelineByDefault) {
            let pipeline = pipelineByDefault ? companyData.pipelines.find(x => x.id == companyData.default_pipeline) : null;

            if (!pipeline) {
                pipeline = _.first(companyData.pipelines);
            }

            if (pipeline) {
                pipelines = [pipeline.id];
            }
        }

        const dateRange = this.getDefaultTimeRange();

        const f = {
            ...(useStoredState ? this.state.filters : _.cloneDeep(this.defaultFilters)),
            dateRange: defaultFilters.dateRange || {
                startDate: dateRange.start,
                endDate: dateRange.end,
                valid: true,
                key: "selection"
            },
            ...this.props.forcedFilters
        };

        f.company = company;

        if (pipelines && pipelines.length > 0) {
            f.pipeline = pipelines[0].id;
            f.pipeline_ids = pipelines;
        } else {
            f.pipeline = null;
            f.pipeline_ids = [];
        }

        return f;
    }

    changeCompany = async (company, force = false, useStoredState = false, refreshData = this.props.refreshAfterCompanyChange, isManual = true) => {
        const { savedQueryName, useSearchButton } = this.props;
        const { filters } = this.state;
        const { functions: { setLastCompany } } = this.context;

        if (company == filters.company && !force)
            return;

        if (isManual)
            setLastCompany(company);

        this.currentBaseFilters = this.getDefaultFilters(company, useStoredState);
        const f = this.getDefaultFilters(company, useStoredState);

        this.filterKey++;

        this.setState({
            autoCompleteData: getDefaultAutoComplete(),
            autoCompleteDataLoaded: false,
            filters: f,
            loading: false
        }, async () => {
            if (refreshData || !useSearchButton)
                this.refreshData(undefined, undefined, undefined, true);

            const autoCompleteData = await this.getCompanyData(company);

            this.setState({
                autoCompleteData,
                autoCompleteDataLoaded: true,
            });
        });

        if (savedQueryName) {
            this.updateSavedQueries();
        }
    }

    initFilters = () => {
        this.changeCompany(this.state.filters.company, true, true, true, false);
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        document.removeEventListener('keydown', this.onKeyPress);
        this.timespanErrorKey && this.props.closeSnackbar(this.timespanErrorKey);
    }

    onKeyPress = (e) => {
        if (e.key === "Escape") {
            this.resetFilters();
        }
    }

    getCompanyData = async (company) => {
        const { savedQueryName, autocompleteParams, showLockedUsersWithTag } = this.props;

        const autoCompleteData = await DataHandler.get({ url: `dashboard/autoCompleteData/${company}`, ...autocompleteParams });

        // createTreeOptions(treeFormatDataForList(data.projects, "parent_id"));

        autoCompleteData.users.forEach(u => {
            if (u.company < 1) {  
                u.label += ` (${this.tr('Freelancer')})`;
                u.name += ` (${this.tr('Freelancer')})`;
            }
            if (showLockedUsersWithTag && u.locked > 0) {
                u.label += ` (${this.tr('Locked')})`;
                u.name += ` (${this.tr('Locked')})`;
            }
        });
        return autoCompleteData;
    }

    updateSavedQueries = () => {
        const { filters: { company } } = this.state;
        const { savedQueryName } = this.props;

        DataHandler.get({ url: `reports/get_saved_queries`, company, querytype: savedQueryName }).done(queries => this.setState({ queries }));
    }

    refreshData = async (filters, extra, tab = null, afterCompanyChange = false) => {
        const { filters: filtersState, companies } = this.state;

        if (tab === null)
            tab = this.props.selectedTab;

        if (!filters)
            filters = filtersState;

        const company = companies[filters.company];

        if (this.props.onRequestData && company) {
            await this.props.onRequestData(filters, company, extra ? extra.change : undefined, tab, afterCompanyChange);
            this.setState({ activeFilters: filters });
        }
    }

    changeFilter = (filters, extra) => {
        if (!this.props.useSearchButton)
            this.refreshData(filters, extra);

        if (this.localStorageKey && !this.props.useSearchButton) {
            const saveFilters = { ...filters };

            if (!saveFilters.dateRange.manual) {
                delete saveFilters.dateRange;
            }
            delete saveFilters.financialYear;

            localStorage[this.localStorageKey] = JSON.stringify(saveFilters);
        }

        this.setState({ filters: { ...filters } }, () => {
            if (!this.isSearchAllowed()) {
                this.timespanErrorKey = this.props.enqueueSnackbar(this.tr("Please use a timespan that is not longer than a year."), {
                    preventDuplicate: true,
                    persist: true,
                    variant: "error"
                });
            } else {
                this.timespanErrorKey && this.props.closeSnackbar(this.timespanErrorKey);
                this.timespanErrorKey = null;
            }
        });
    }

    isSearchAllowed = () => {
        if (this.props.allowFullDateRange)
            return true;

        const dateRange = this.state.filters.dateRange;
        const start = moment(dateRange.startDate);
        const end = moment(dateRange.endDate);
        const diff = end.diff(start, "year", true);
        return diff <= 1;
    }

    resetFilters = () => {
        this.filterKey++;
        delete localStorage[this.localStorageKey];

        const filters = this.getDefaultFilters(this.state.filters.company);


        this.setState({ filters });
    }

    onSearchClick = throttle(async () => {
        if (this.state.searching)
            return;

        this.setState({ searching: true });
        await this.refreshData();
        this.setState({ searching: false });

    }, 1000);

    changeTab = (e, tab) => {
        if (e) {
            if (!e.ctrlKey && !e.metaKey)
                e.preventDefault();
            else
                return;
        }

        const { refreshDataOnTabChange, handleTabChange } = this.props;

        if (refreshDataOnTabChange) {
            this.refreshData(false, false, tab);
            handleTabChange(tab);
        } else {
            handleTabChange(tab);
        }
    }

    getFilters = () => {
        return this.state.activeFilters;
    }

    getCurrentCompany = () => {
        return this.state.filters.company;
    }

    setPopoverData = (popoverData) => this.setState({ popoverData });

    render() {
        const { filters, companies, autoCompleteData, queries, loading, permissionMissing, popoverData } = this.state;
        const {
            header, id, className, children, width, loaded, right, rightGroup, savedQueryName,
            dropFilters, advancedFilters, tabs, selectedTab, sliderProps, showTabTitle, useSearchButton,
            allowMultipleCompanies, fullWidthContent, outsideFiltersState, allowEmptyDateRange, viewButtonProps
        } = this.props;

        if (permissionMissing) {
            return <NoPermissionOverlay />;
        }

        const rows = [];

        if (!fullWidthContent) {
            // Group by rows
            React.Children.forEach(children, (node, i) => {
                // Skips nulls, from conditional boxes
                if (!node)
                    return;

                const { width: itemWidth = width } = node.props;

                for (const row of rows) {
                    if (row.spaceLeft >= itemWidth) {
                        row.spaceLeft -= itemWidth;

                        row.items.push(node);

                        return;
                    }
                }

                rows.push({
                    spaceLeft: width - itemWidth,
                    items: [
                        node,
                    ]
                });
            });
        }

        const company = companies[filters.company];

        let topTabs;

        if (tabs) {
            topTabs = tabs.map(t => {
                return {
                    ...t,
                    action: (e) => this.changeTab(e, t.id)
                }
            });
        }

        return (
            <div id={id} className={cn("insight-base", className, (loading || !loaded) && "insights-loading", !topTabs && 'insight-no-tabs')}>
                {this.props.header && <PageTopSection header={this.props.header} subheaders={this.props.subheaders} />}
                <div className={cn("insight-toolbar", useSearchButton && "insight-toolbar-search-button")}>
                    <InsightFilter
                        ref={this.refFilters}
                        key={this.filterKey}
                        right={right}
                        rightGroup={rightGroup}
                        filters={filters}
                        savedQueryName={savedQueryName}
                        onChange={this.changeFilter}
                        defaultFilters={this.currentBaseFilters}
                        allowMultipleCompanies={allowMultipleCompanies}
                        companies={companies}
                        autoCompleteData={autoCompleteData}
                        pipelines={company?.pipelines || []}
                        pipelinesStages={company?.pipelines_stages || []}
                        queries={queries}
                        updateSavedQueries={this.updateSavedQueries}
                        loaded={this.state.autoCompleteDataLoaded}
                        dropFilters={dropFilters}
                        advancedFilters={advancedFilters}
                        changeCompany={this.changeCompany}
                        searchButton={useSearchButton && <div data-testid="search-button" className="insight-search-control">
                            <LoaderButton data-testid="insight-filter-search-button" className="blue" progressPadding="1" notAllowed={!this.isSearchAllowed()} loading={loading || !loaded} onClick={this.onSearchClick} text={this.tr("Search")} />
                        </div>}
                        outsideFiltersState={outsideFiltersState}
                        onChangeOutsideFilters={this.props.onChangeOutsideFilters}
                        allowEmptyDateRange={allowEmptyDateRange}
                        {...this.props.insightFilterProps}
                    />
                    {viewButtonProps && <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={viewButtonProps.title}>
                            <div
                                onClick={(e) => {
                                    if (viewButtonProps.disabled) return;

                                    viewButtonProps.popoverComponent
                                        ? this.setPopoverData({
                                              component: viewButtonProps.popoverComponent,
                                              anchor: e.target,
                                              class: viewButtonProps.popoverClass,
                                          })
                                        : viewButtonProps && viewButtonProps.action();
                                }}
                                className="insight-view-button"
                            >
                                <RemoveRedEye />
                                <ArrowDropDown className="small" />
                            </div>
                        </Tooltip>}
                </div>

                <div data-testid="loading-overlay" className={cn("contents-loading-overlay", !(loading || !loaded) && "hidden")}>
                    <img src={Loading} />
                </div>
                <div className="contents">
                    {fullWidthContent ? this.props.children :
                        <div className="blocks">
                            {rows.map((row) => <>
                                {row.items.map((i) => {
                                    const blockWidth = (i.props.width ?? width);

                                    return React.cloneElement(i, {
                                        style: {
                                            gridColumnStart: blockWidth == width ? '1' : undefined,
                                            gridColumnEnd: blockWidth == width ? '-1' : `span ${i.props.width ?? width}`,
                                        }
                                    });
                                })}
                            </>)}
                        </div>}
                </div>
                {sliderProps !== false && <InsightSlider
                    currency={company && company.currency}
                    {...sliderProps}
                />}
                {popoverData && popoverData.anchor && (
                    <Popover
                        onClose={() => this.setPopoverData({})}
                        className={popoverData.class}
                        anchorEl={popoverData.anchor}
                        open={Boolean(popoverData.anchor)}
                        anchorOrigin={{ vertical: 45, horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        {typeof popoverData.component == 'function' ? popoverData.component(() => this.setPopoverData({})) : popoverData.component}
                    </Popover>
            )}
            </div>
        );
    }
}

export default withStyles(styles)(withSnackbar(InsightsBase));
