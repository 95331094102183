import React from "react";
import { withSnackbar } from 'notistack';

import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from "./../../SettingsContext";

import { DateRangePicker } from "../../general/react-date-range/src";

import OutlinedField from "./../../general/OutlinedField";
import LoaderButton from '../../general/LoaderButton';
import AdvancedSearch from "../../search/AdvancedSearch";

import MenuItem from "@mui/material/MenuItem";


import styles from './Toolbar.module.scss';
import _ from "lodash";
import { getAutocompleteDataForDialog } from "../helpers";

import DataList from "../../general/DataList";
import MultiSelect from "../../general/MultiSelect";

import cn from 'classnames';
import { endOfDay } from "date-fns";

class ResourcingToolbar extends TaimerComponent {
	static contextType = SettingsContext;

	static defaultProps = {
		filters: {},
		filtersInitialValues: {},
		onChangeFilters: () => { },
		loading: false,
	};

	constructor(props, context) {
		super(props, context, "resourcing/components/Toolbar");

		this.state = {
			key: 0,
			filters: {
				...props.filters,
			},
			autoCompleteData: {
				customers: [],
				projects: [],
				pipelines: [],
				employees: [],
				team: [],
			},
			loadingAutocomplete: true,
			filtersChanged: !_.isEqual(props.filters, props.filtersInitialValues),
		};

		this.advancedSearch = React.createRef();
	}

	componentDidMount() {
		super.componentDidMount();

		this.listenReset();
		this.updateAutocompleteData();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		this.unListenReset();
	}

	updateAutocompleteData = async (company = null) => {
		const { filters } = this.state;

		this.setState({ loadingAutocomplete: true });

		const autoCompleteData = await getAutocompleteDataForDialog(company ?? filters.company);

		const customers = _.keyBy(autoCompleteData.customers, x => x.id);

		autoCompleteData.customers = [
			{ id: 0, label: this.tr("All") },
			...autoCompleteData.customers,
		];

		autoCompleteData.projects = autoCompleteData.projects.map(x => ({
			...x,
			name: `${customers[x.customers_id]?.name ?? '-'} - ${x.name}`,
		}));

		this.setState({ autoCompleteData, loadingAutocomplete: false });
	}

	componentDidUpdate(prevProps) {
		const { filters, filtersInitialValues } = this.props;

		// If filters were externally changed, reset
		if (!_.isEqual(prevProps.filters, filters) && prevProps.filters.key < filters.key) {
			this.setState({ 
				filters, 
				key: this.state.key + 1, 
				filtersChanged: !_.isEqual(filters, filtersInitialValues),
			}, () => this.updateAutocompleteData());
		}
	}

	listenReset = () => {
		document.body.addEventListener("keyup", this.__resetKeyListener);
	}

	unListenReset = () => {
		document.body.removeEventListener("keyup", this.__resetKeyListener);
	}

	__resetKeyListener = (evt) => {
		if (!evt || evt.keyCode === 27) {
			this._resetFilters();
		}
	}

	_resetFilters = () => {
		const { filtersInitialValues } = this.props;
 
		this.setState({ filters: {...filtersInitialValues}, key: this.state.key + 1, filtersChanged: false, }, () => this.updateAutocompleteData());
	}

	updateFilters = (update) => {
		const { filters } = this.state;

		this.setState({ filters: { ...filters, ...update }, filtersChanged: true });
	}

	handleDateChange = (event) => {
		const { selection } = event;

		if (!selection) return;

		this.updateFilters({
			dateRange: {
				startDate: selection.startDate,
				endDate: selection.endDate ? endOfDay(selection.endDate) : selection.endDate,
				key: "selection"
			}
		});
	}

	handleDateInputChange = (type, date) => {
		const { filters } = this.state;
		const dateRange = { ...filters.dateRange };

		if (type === "start") {
			dateRange.startDate = date;
		} else {
			dateRange.endDate = date ? endOfDay(date) : date;
		}

		this.updateFilters({
			dateRange,
		});
	}

	isSearchAllowed = () => true;

	onSearchClick = () => {
		const { filters } = this.state;
		this.props.onChange(filters);
	}

	changeDropSearch = (field, value) => {
		this.updateFilters({
			[field]: value,
		});
	}

	changeMultiDropSearch = (field, value) => {
		if (value.length === 1 && (value[0].value === 0 || value[0].value === "0")) {
			value = [];
		}

		this.updateFilters({
			[field]: value,
		});
	}

	onInputListener = _.debounce(() => {
        this.advancedSearch.current.triggerSearch();
    }, 200);

	render() {
		const { singleProject, projectsId, view, companies, loading, statusOptions } = this.props;
		const { autoCompleteData, loadingAutocomplete, filtersChanged, filters: {
			company,
			dateRange,
			customer,
			projects,
			pipelines,
			users,
			teams,
			showStatusBy,
			query,
		} } = this.state;
		const { userObject } = this.context;

		const { tr } = this;
		
		return (
			<div className={cn(styles.searchcontainer, loadingAutocomplete && styles.loading)}>
				{companies.length > 1 && !singleProject && <OutlinedField
					className={cn(styles.control)}
					name="company"
					label={this.tr("Company")}
					value={company}
					select
					onChange={e => {
						this.updateFilters({ company: e.target.value });
						this.updateAutocompleteData(e.target.value);
					}}>
						<MenuItem value={0}>
							{this.tr("All")}
						</MenuItem>
					{companies.map(row => (
						<MenuItem key={row.id} value={row.id}>
							{row.name}
						</MenuItem>
					))}
				</OutlinedField>}

				<DateRangePicker
					name="daterange"
					className={cn(styles.control, styles.daterange)}
					label={tr("Date Range")}
					ranges={[dateRange ?? {
						startDate: null,
						endDate: null,
						key: 'selection',
					}]}
					onChange={this.handleDateChange}
					onInputChange={this.handleDateInputChange}
					dateFormat={userObject.dateFormat}
					allowClear
					customDateRanges={[
                        {
                            label: tr('All'),
                            range: () => ({
                                startDate: null,
                                endDate: null,
                              }),
                        }
                    ]}
				/>

				{!singleProject && (
					<React.Fragment>
						<div className={cn(styles.control)}>
							<DataList
								name="customer"
								dropLabel={tr("Account")}
								options={autoCompleteData.customers.map(x => ({value: Number(x.id), label: x.label}))}
								onChange={obj => this.changeDropSearch("customer", obj)}
								value={customer}
								fullWidth={true}
								className="listFilterOutlinedField fullwidth"
								virtualized
							/>
						</div>
						{!projectsId &&
							<div className={cn(styles.control)}>
								<MultiSelect
									label={tr("Project")}
									autoReset
									defaultValue={projects}
									options={autoCompleteData.projects.filter(x => !customer || !customer.id || !x.customers_id || Number(customer.id) === Number(x.customers_id)).map(x => ({ value: x.id, label: x.name }))}
									onChange={obj => this.changeMultiDropSearch("projects", obj)}
								/>
							</div>
						}
						{statusOptions && (view === "list" || view === "gantt") && (
							<React.Fragment>
								<div className={cn(styles.control, styles.drop)}>
									<OutlinedField
										className="listFilterOutlinedField fullwidth"
										label={tr("View with status")}
										name="showStatusBy"
										onChange={evt => {
											this.changeDropSearch("showStatusBy", {
												id: evt.target.value,
												label: statusOptions.find(
													el => el.id == evt.target.value
												).label
											});
										}}
										select
										fullWidth={true}
										value={showStatusBy.id}
									>
										{statusOptions.map(row => (
											<MenuItem key={row.id} value={row.id}>
												{row.label}
											</MenuItem>
										))}
									</OutlinedField>
								</div>
							</React.Fragment>
						)}

						<div className={cn(styles.control, styles.multiselect)}>
							<MultiSelect
								name="pipelines"
								label={tr("Funnel")}
								autoReset
								defaultValue={pipelines}
								options={autoCompleteData.pipelines.map(x => ({ value: x.id, label: company === 0 && x.id > 0 ? `${x.label} (${x.company_name})` : (x.id < 0 ? tr(x.label) : x.label) }))}
								onChange={obj => this.changeMultiDropSearch("pipelines", obj)}
							/>
						</div>
					</React.Fragment>
				)}

				<div className={cn(styles.control, styles.drop, styles.multiselect)}>
					<MultiSelect
						label={tr("User")}
						autoReset
						defaultValue={users}
						options={autoCompleteData.employees.map(x => ({ value: x.id, label: x.label }))}
						onChange={obj => this.changeMultiDropSearch("users", obj)}
					/>
				</div>
				<div className={cn(styles.control, styles.drop, styles.multiselect)}>
					<MultiSelect
						label={tr("Team")}
						autoReset
						defaultValue={teams}
						options={autoCompleteData.team.map(x => ({ value: x.id, label: x.label }))}
						onChange={obj => this.changeMultiDropSearch("teams", obj)}
					/>
				</div>
				<div className={cn(styles.clearSearchContainer)}>
					{(view === "usage") && filtersChanged && (
						<span onClick={() => this._resetFilters()} className={styles.clearSearchButton}>{this.tr("Clear filters")}</span>
					)}
				</div>
				{view !== "usage" && (
					<AdvancedSearch
						key={`ad_${this.state.key}`}
						className={cn(styles.advancedSearch, !this.state.searchExecuted && "not-executed")}
						ref={this.advancedSearch}
						freetextLabel={query}
						alwaysShowClearFilters={filtersChanged}
						onClearSearch={this._resetFilters}
						fields={["description"]}
						noRequests
						hideAdvanced
						onInputListener={this.onInputListener}
						onSearchTrigger={terms => {
							this.updateFilters({terms, query: terms.freetextSearchTerm});
						}}
					/>
				)}

				<div className={styles.searchbuttoncontainer}>
					<LoaderButton color="primary"data-testid="toolbar-search-button" notAllowed={!this.isSearchAllowed()} loading={loading} onClick={this.onSearchClick} text={this.tr("Search")} />
				</div>
			</div>
		);
	}
}

export default withSnackbar(ResourcingToolbar);