
//import { ReactComponent as dashboard } from './NavIcons/dashboard.svg';
import { ReactComponent as customers } from './NavIcons/customers.svg';
import { ReactComponent as contacts } from './NavIcons/contacts.svg';
import { ReactComponent as calendar } from './NavIcons/calendar.svg';
import { ReactComponent as expenses } from './NavIcons/expenses.svg';
import { ReactComponent as costs } from './NavIcons/costs.svg';
import { ReactComponent as projects } from './NavIcons/projects_new.svg';
import { ReactComponent as receivedInvoices } from './NavIcons/received_invoices.svg';
import { ReactComponent as resourcing } from './NavIcons/resourcing.svg';
import { ReactComponent as workhours } from './NavIcons/workhours.svg';
import { ReactComponent as email } from './NavIcons/email.svg';
import { ReactComponent as onedrive } from './NavIcons/onedrive.svg';
import { ReactComponent as leads } from './NavIcons/leads.svg';
import { ReactComponent as collaborate } from './NavIcons/boards.svg';
import { ReactComponent as addons } from './NavIcons/add-ons.svg';
import { ReactComponent as lock } from './NavIcons/lock-filled.svg';
import { ReactComponent as product } from './NavIcons/product.svg';
import { ReactComponent as contacts_new } from './NavIcons/contacts_newest.svg';
import { ReactComponent as financial } from './NavIcons/Financial.svg';
import { ReactComponent as insights } from './NavIcons/Insights.svg';
import { ReactComponent as productivity } from './NavIcons/Productivity.svg';
import { ReactComponent as projectManagement } from './NavIcons/Project_management.svg';
import { ReactComponent as sales } from './NavIcons/Sales.svg';
import { ReactComponent as dashboard } from './NavIcons/myday.svg';
import { ReactComponent as taimercalendar } from './NavIcons/calendar_today.svg';
import { ReactComponent as more } from './NavIcons/more.svg';
import { ReactComponent as timemanagement } from './NavIcons/timemanagement.svg';
import { ReactComponent as reports } from './NavIcons/reports.svg';
import { ReactComponent as  new_reports } from './NavIcons/new_reports.svg';

export default {
    dashboard,
    addons,
    lock,
    customers,
    calendar,
    expenses,
    costs,
    projects,
    receivedInvoices,
    resourcing,
    workhours,
    contacts,
    product,
    email,
    onedrive,
    leads,
    collaborate,
    contacts_new,
    financial,
    insights,
    productivity,
    projectManagement,
    sales,
    taimercalendar,
    more,
    timemanagement,
    reports,
    new_reports,
    "default": dashboard
}