import React from 'react';
import "./Paper.css";
import { SettingsContext } from './../SettingsContext';
import { FlexChild, FlexContainer } from "../general/FlexUtils";
import Utils from "./Utils";

function Header(props) {
    const Wrapper = props.noFlex !== true ? FlexContainer : "div";

    return <Wrapper className="header">{props.children}</Wrapper>;
}

function ContentArea(props) {
    // TODO: flex option
    return <div className={"content" + props.fullWidth ? " fullWidth" : ""}>{props.children}</div>;
}

function Footer(props) {
    const Wrapper = props.noFlex !== true ? FlexContainer : "div";

    return <Wrapper className="footer">{props.children}</Wrapper>;
}

class Totals extends React.Component {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props);

        this.context = context;

        this.state = {
            numberFormat: new Intl.NumberFormat(this.context.taimerAccount.numberFormat, { style: 'currency', currency: props.currency ? props.currency : this.context.taimerAccount.currency })
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.currency !== this.props.currency) {
            const numberFormat = new Intl.NumberFormat(this.context.taimerAccount.numberFormat, { style: 'currency', currency: this.props.currency ? this.props.currency : this.context.taimerAccount.currency })
            this.setState({ numberFormat })
        }
    }

    render() {
        return (
            <div className="sum-info">
                {this.props.rows.map((row, i) => {
                    return (<div data-testid={row["data-testid"]} key={i} className={`row ${row.grey ? "grey" : row.black ? "black" : ""}`}>
                        <div key={`${i}-name`} className="name">{row.name}</div>
                        <div key={`${i}-sum`} className="sum">{this.state.numberFormat.format(row.sum)}</div>
                    </div>);
                })}
            </div>
        );
    }
}

function determinePaperAndSideMenuWeights(paperMaxWidth, containerWidth, desiredWeights = []) {
    paperMaxWidth    = Math.floor(paperMaxWidth);
    let menuWidth    = Math.floor(containerWidth - paperMaxWidth);
    let paperFactors = Utils.pfactorize(paperMaxWidth);
    let menuFactors  = Utils.pfactorize(menuWidth);
    let intersection = Utils.arrayIntersect([paperFactors, menuFactors]);
    let gcd          = intersection.length > 0 ? intersection.reduce((a, c) => a > c ? a : c) : 1;

    paperMaxWidth = paperMaxWidth / gcd;
    menuWidth     = menuWidth / gcd;

    const desiredRatio = desiredWeights[0] / desiredWeights[1];

    if(menuWidth < 0 || paperMaxWidth / menuWidth > desiredRatio) {
        paperMaxWidth = desiredWeights[0];
        menuWidth     = desiredWeights[1];
    }

    return { 
        paperContainerWeight: paperMaxWidth,
        menuContainerWeight: menuWidth
    };
}


export {
    Header, ContentArea, Footer, Totals, determinePaperAndSideMenuWeights
};
