import TaimerComponent from '../TaimerComponent';
import InvoiceApi from "../general/InvoiceApi";

export interface SendInvoicesHelperProps {
    enqueueSnackbar: (message: string, props: any) => any;
    closeSnackbar: (key: any) => void;
    updateData?: () => void;
}

class SendInvoicesHelper extends TaimerComponent<SendInvoicesHelperProps> {
	sendingInProgress: boolean;
    snackBarKey: any;

    constructor(props, context) {
        super(props, context, "list/lists/InvoiceList");
		this.sendingInProgress = false;
    	this.snackBarKey = null;
    }

    send = (ids, company, func, analyticsKey = "") => {
		const { enqueueSnackbar, closeSnackbar, updateData } = this.props;

		if (this.sendingInProgress == true) {
			return;
		} else {
			const confirmFunc = () => InvoiceApi[func]({ ids: ids }, enqueueSnackbar, updateData, closeSnackbar, this.stateUpdater, company, this.tr, this.setSendingStatus, analyticsKey, this.htmlTr);
			confirmFunc();
		}
	}

    setSendingStatus = (status) => {
        const { enqueueSnackbar, closeSnackbar } = this.props;

		if (status == true) {
			const key = enqueueSnackbar(this.tr("Sending invoices. This may take a while."), {
				variant: "info",
				persist: true
			});
			this.sendingInProgress = true;
			this.snackBarKey = key;
		} else {
			closeSnackbar(this.snackBarKey);
			this.sendingInProgress = false;
			this.snackBarKey = null;
		}
		
	}

    stateUpdater = (obj) => {
		const { enqueueSnackbar } =  this.props;

		if(obj && obj.loading == true) {
			enqueueSnackbar(this.tr("Sending invoice"), {
				variant: "info",
			});
		}

		this.setState(obj);
	}
    
    render() {
        return null;  
   }
}

export default SendInvoicesHelper;