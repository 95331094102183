import React from 'react';
import _ from 'lodash';
import SliderFieldGroup from '../../general/SliderFieldGroup';
import TaimerComponent from '../../TaimerComponent';

class CustomFieldsPreview extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'projects/customfields/CustomFieldsPreview');
    }

    getFields = () => {
        const { fields: customFields } = this.props;
        const fields = customFields.map((c) => ({
            ...c,
            key: c.id,
            title: c.name,
            type: c.type == 'multiselect' ? 'select' : c.type == 'dropdown' || c.type == 'radio' ? 'data_select' : c.type,
            isMulti: c.type == 'multiselect',
            formatMultiValue: (value, options) => options.find((o) => o.id == value),
        }));
        return this.filterFields(fields);
    };

    filterFields = (fields) => {
        const { values, form } = this.props;
        let shown = [];
        if (form) {
            shown = _.sortBy(
                fields.filter((x) => x.form == form),
                'form_order'
            );
        } else {
            shown = _.sortBy(
                fields.filter((x) => x.show_in_details),
                'form_order'
            );
        }
        shown = shown.filter((field) => {
            const value = (values || {})[field.id] || '';
            if (field.deleted && !value) return false;
            return true;
        });
        return shown;
    };

    render() {
        const { values } = this.props;
        const fields = this.getFields();
        let hasVisibleValue = false;
        fields.forEach((field) => {
            const value = (values || {})[field.id];
            if (!!value) hasVisibleValue = true;
        });
        if (!hasVisibleValue) return null;
        return (
            <>
                <h3>{this.tr('Additional information')}</h3>
                <SliderFieldGroup fields={fields} items={[values || {}]} />
            </>
        );
    }
}

export default CustomFieldsPreview;
