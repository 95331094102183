import React from 'react';
import TaimerComponent from "../../TaimerComponent"

/* material ui */
import { Button } from '@mui/material';

import Taimer from "../../Taimer"
import { SettingsContext } from '../../SettingsContext';

import { ReactComponent as OverlayImage } from './Nopermission.svg';

class NoPermissionOverlay extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'list/overlays/NoPermissionOverlay');
    }

    render () {

        return (
            <div className="list-overlay">
                <OverlayImage style={{width: "260px", height: 'auto'}} />
                <div className="text">
                    {this.tr("You don’t have permission to view this page.") }
                </div>
              {/*   <Button
                    color="primary"
                    onClick={() => this.context.functions.updateView({module: 'projects', action: 'view'})}
                >
                {this.tr("REQUEST ACCESS") }
                </Button> */}
             </div>
        );
    }

}

export default NoPermissionOverlay;