import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import InvoiceList from '../../list/lists/InvoiceList';
import MassInvoicingList from '../../list/lists/MassInvoicingList';
import InvoiceLogListWithContext from '../../list/lists/InvoiceLogList';
import EmbedVideoOverlay from '../../general/EmbedVideoOverlay';
import VersionContentManager from '../../general/VersionContentManager';

class Invoices extends TaimerComponent<any, any> {
    tabs;
    constructor(props, context) {
        super(props, context, 'navigation/pages/Invoices');
        this.tabs = VersionContentManager.getViewTabs(this.namespace).map((tab) => ({ ...tab, label: this.tr(tab.label) }));
    }

    getTabInfoButtonProps = (selectedTab) => {
        switch (selectedTab) {
            case 'invoices':
                return {
                    title: this.tr('How Invoices works'),
                    methods: {
                        article: this.context.userObject.language == 'fi' ? 'https://psahelpcenter.heeros.com/hc/fi/articles/8080294440210' : 'https://psahelpcenter.heeros.com/hc/en-us/articles/8080294440210',
                        flow: this.context.userObject.language == 'fi' ? 'f0e434bb-48e2-446e-9f93-7621fb87bcbc' : '558149a2-6204-467d-9aac-cf769446965b',
                    },
                };
            // case 'mass-invoicing':
            //     return {
            //         title: this.tr('How Mass Invoicing works'),
            //         methods: {
            //             article: this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8080204872338' : 'https://taimersupport.zendesk.com/hc/articles/8080204872338',
            //             video: 'https://player.vimeo.com/video/470207594?h=0a58f7548d&badge=0&autoplay=1&autopause=0&player_id=0&app_id=58479',
            //         },
            //     };
            // case 'invoices-log':
            //     return {
            //         title: this.tr('How Invoices Log works'),
            //         methods: {
            //             article: this.context.userObject.language == 'fi' ? 'https://psahelpcenter.heeros.com/mass-invoicing' : 'https://psahelpcenter.heeros.com/fi/massalaskutus',
            //             // video: 'https://player.vimeo.com/video/470207594?h=0a58f7548d&badge=0&autoplay=1&autopause=0&player_id=0&app_id=58479',
            //         },
            //     };
            default:
                return undefined;
        }
    };

    render() {
        return (
            <WithTabs
                offsetTop={10}
                tabs={this.tabs}
                selectedTab={this.props.selectedTab}
                getTabInfoButtonProps={this.getTabInfoButtonProps}
                notifications={this.props.navigationNotifications}
                tabsAlwaysVisible
            >
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'invoices':
                            return <InvoiceList {...this.props} />;
                        case 'mass-invoicing':
                            return <MassInvoicingList {...this.props} />;
                        case 'invoices-log':
                            return <InvoiceLogListWithContext {...this.props} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default Invoices;
