import { ViewColumn, ViewConfiguration, ViewGrouping } from "../ViewConfiguration";

export type ValidGridGrouping = "project" | 'user';
type format = 'hours' | 'percent';

export interface GridViewColumn extends ViewColumn {
    name: string;
    label?: string;
    resize: boolean;
    width: number;
    format?: format;
}

export interface GridViewGrouping extends ViewGrouping {
    name: ValidGridGrouping;
    label: string;
}

const viewConfiguration: ViewConfiguration<GridViewColumn, GridViewGrouping> = {
    defaultGrouping: 'project',
    defaultZoom: 'monthly',
    defaultViewMode: 'hours',
    viewModes: [
        {
            name: "percent",
            label: "Resourced hours percentage",
        },
        {
            name: "hours",
            label: "Resourced hours",
        },
        {
            name: "available",
            label: "Available hours",
        },
    ],
    zooms: [
        {
            name: 'daily',
            label: 'Daily',
        },
        {
            name: 'weekly',
            label: 'Weekly',
        },
        {
            name: 'monthly',
            label: 'Monthly',
        },
    ],
    groupings: [
        {
            name: 'project',
            label: 'Project',
        },
        {
            name: 'user',
            label: 'User',
        },
    ],
    columns: [
        {
            name: "text",
            label: "Task",
            width: 300,
            resize: true,
            defaultVisible: true,
            allowHiding: false,
        },
        {
            name: 'pipeline_name',
            label: 'Pipeline',
            width: 100,
            defaultVisible: false,
            resize: true,
            allowHiding: true,
        },
        {
            name: 'projects_allocated',
            label: 'Projects allocated',
            width: 100,
            defaultVisible: true,
            resize: true,
            allowHiding: true,
            format: 'hours',
        },
        {
            name: 'projects_allocated_all_time',
            label: 'Projects allocated (All Time)',
            width: 100,
            defaultVisible: false,
            resize: true,
            allowHiding: true,
            format: 'hours',
        },
        {
            name: 'allocated',
            label: 'Allocated',
            width: 100,
            defaultVisible: false,
            resize: true,
            allowHiding: true,
            format: 'hours',
        },
        // {
        //     name: 'allocated_all_time',
        //     label: 'Allocated (All Time)',
        //     width: 100,
        //     defaultVisible: false,
        //     resize: true,
        //     allowHiding: true,
        //     format: 'hours',
        // },
        {
            name: 'resourced',
            label: 'Resourced',
            width: 100,
            defaultVisible: true,
            resize: true,
            allowHiding: true,
            format: 'hours',
        },
        {
            name: 'resourced_all_time',
            label: 'Resourced (All Time)',
            width: 100,
            defaultVisible: false,
            resize: true,
            allowHiding: true,
            format: 'hours',
        },
        {
            name: 'tracked',
            label: 'Tracked',
            width: 100,
            defaultVisible: true,
            resize: true,
            allowHiding: true,
            format: 'hours',
        },
        {
            name: 'tracked_all_time',
            label: 'Tracked (All Time)',
            width: 100,
            defaultVisible: false,
            resize: true,
            allowHiding: true,
            format: 'hours',
        },
        {
            name: 'remaining',
            label: 'Remaining',
            width: 100,
            defaultVisible: true,
            resize: true,
            allowHiding: true,
            format: 'hours',
        },
        {
            name: 'remaining_all_time',
            label: 'Remaining (All Time)',
            width: 100,
            defaultVisible: false,
            resize: true,
            allowHiding: true,
            format: 'hours',
        },
        {
            name: 'priority',
            label: 'Priority',
            width: 60,
            defaultVisible: false,
            resize: true,
            allowHiding: true,
        },
        {
            name: 'skill',
            label: 'Skill',
            width: 60,
            defaultVisible: false,
            resize: true,
            allowHiding: true,
        },
        {
            name: 'progress',
            label: 'Progress',
            width: 50,
            defaultVisible: false,
            resize: true,
            allowHiding: true,
            format: 'percent',
        },
    ]
}

export default viewConfiguration;