import React from "react";
import FilledLineChart from "../../FilledLineChart";
import InsightChartTable from "../../InsightChartTable";
import InsightChart from "../../InsightChart";
import ModeToggle from "../../ModeToggle";
import { useState } from "react";
import { stringToColour } from "../../../../helpers";
import colors from "../../../../colors";

const MODE_KEY = "sales-insight-funnels-overview-mode-key";
const PL_COLORS = [colors.greenish_cyan, colors.dark_sky_blue, "#7580eb"];

const FunnelsOverview = (props) => {
  const { tr, data, months, ...otherProps } = props;
  let initialMode = "area";
  try {
    initialMode = localStorage.getItem(MODE_KEY) || "area";
  } catch (e) {}
  const [mode, setMode] = useState(initialMode);
  const modes = [
    { key: "area", title: tr("Area") },
    { key: "line", title: tr("Line") },
  ];
  const stacked = mode == "area";
  const xStacked = months.length > 1;

  return (
    <InsightChart
      {...otherProps}
      filterComponents={
        <ModeToggle
          setMode={(mode) => {
            setMode(mode);
            try {
              localStorage.setItem(MODE_KEY, mode);
            } catch (e) {}
          }}
          selected={mode}
          modes={modes}
        />
      }
      title={tr("Pipeline by Month")}
      data={{
        sets: data.map((p, i) => {
          return {
            id: p.id,
            label: p.name,
            values: p.values || [],
            type: months.length == 1 ? "bar" : undefined,
            colors: {
              backgroundColor: (stacked || months.length == 1) ? (PL_COLORS[i] || stringToColour(p.name)) : "transparent",
              fill: {
                target: 'origin',
                below: (PL_COLORS[i] || stringToColour(p.name))
              },
              pointBackgroundColor: (PL_COLORS[i] || stringToColour(p.name)),
              borderColor: (PL_COLORS[i] || stringToColour(p.name)),
            },
          };
        }),
        labels: months,
      }}
      chartType={FilledLineChart}
      tableType={InsightChartTable}
      lowOpacityChart={false}
      sliderColumns={["project", "account", "user", "value", "total_split"]}
      chartProps={{
        displayLegend: true,
        stacked,
        xStacked
      }}
      tableProps={{
        displayByLabels: true,
      }}
    />
  );
};

export default FunnelsOverview;
