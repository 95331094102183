import TaimerComponent from '../TaimerComponent';
import { prefixLink } from '../helpers';
import Link from './../general/Link';

interface Props {
    value: string;
    url: string;
    data: object;
    colDef: {
      field: string;
    };
    node: {
      field: string;
      group: boolean;
      footer: boolean;
      expanded: boolean;
      aggData: object;
      allLeafChildren: {data}[];
    };
    column: {colId: string};
    groupedDataValue?: string | number;
}

export default class EmailLinkCellRenderer extends TaimerComponent<Props> {
    render() {
        const data = this.props.groupedDataValue 
            ? this.props.groupedDataValue 
            : this.props.data && this.props.data[this.props.colDef.field];

        return <a href={`mailto:${data}`}>{data}</a>
    }
}
