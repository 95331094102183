import React from "react";

import _ from "lodash";
import List from "@mui/material/List";
import moment from "moment";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/AccountCircle";
import ListItem from "@mui/material/ListItem";
import DataList from "../general/DataList";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactSelect from "react-select";
import DataHandler from "../general/DataHandler";
import DialogBorder from "./DialogBorder";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import OutlinedField from "../general/OutlinedField";
import DialogSection from "./DialogSection";
import TaimerComponent from "../TaimerComponent";
import TimeRangeOutlinedField from "../general/TimeRangeOutlinedField";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { format } from "date-fns";
import { withSnackbar } from "notistack";
import { SettingsContext } from "../SettingsContext";
import { DateRangePicker } from "./../general/react-date-range/src";
import { DialogDropDownButton, DialogDropDownButtonItem } from "./DialogDropDownButton";

/* css */
import "./GoogleEventDialog.css";
import ContactListOverlay from "../list/overlays/ContactListOverlay";

export class GooleEventDialog extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {};

    constructor(props, context) {
        super(props, context, "dialogs/GoogleEventDialog");

        this.state = {
            attendee: "",
            attendees: [],
            editmode: false,
            dialogEvent: {
                attendees: [],
                ...this.props.dialogEvent,
            },
        };
    }

    /**
     * Usable
     *
     */
    cancel = () => {
        this.props.onClose();
    };

    /**
     * Change function for DateRange from event
     *
     */
    onDateRangeChange = event => {
        const { startDate, endDate } = event.selection;

        this.setState({
            date: {
                startDate: format(startDate, "YYYY-MM-DD"),
                endDate: format(endDate, "YYYY-MM-DD"),
                key: "selection",
            },
        });
    };

    /**
     * OnChange function for DateRange (endDate || startDate)
     *
     */
    onDateRangeInputChange = (dateType, date) => {
        const { endDate, startDate } = this.state.date;
        date = format(date, "YYYY-MM-DD");

        if (dateType == "start") {
            this.setState({
                date: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection",
                },
            });
        } else {
            this.setState({
                date: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection",
                },
            });
        }
    };

    /**
     * OnChange function for TimeRange
     *
     */
    changeTime = (value, start, end) => {
        const { item } = this.state;

        this.setState({
            item: {
                ...item,
                timerange: value,
                starttime: start,
                endtime: end,
            },
        });
    };

    /**
     * Handles time change for "start" and "end" when saving
     *
     */
    updateTime = (old, newTime) => {
        return moment(old)
            .hour(newTime.hour())
            .minute(newTime.minute())
            .second(newTime.second());
    };

    /**
     * Usable
     *
     */
    isInvalid = field => {
        return this.state.validation && !this.state.validation.valid && this.state.validation[field];
    };

    /**
     *
     *
     */
    addAttendee = () => {
        const { attendee, dialogEvent } = this.state;
        if (attendee.length > 0) dialogEvent.attendees.push({ email: attendee });
        this.onChangeDialogEvent(dialogEvent.attendees, "attendees");
        this.setState({ attendee: "" });
    };

    deleteAttendee = i => {
        const { dialogEvent } = this.state;
        dialogEvent.attendees.splice(i, 1);
        this.onChangeDialogEvent(dialogEvent.attendees, "attendees");
    };

    onChangeDialogEvent = (value, name) => {
        let dialogEvent = this.state.dialogEvent;

        if (name === "daterange" || name === "timerange") {
            //Jäänyt kesken. Pitää katella timepickerin osalta asiat loppuun miten sen kanssa halutaan tehdä
            if (name === "timerange" && value.starttime && value.endtime) {
                dialogEvent["timerange"] = value.timerange;
                dialogEvent["starttime"] = moment(value.starttime).format("HH:mm");
                dialogEvent["endtime"] = moment(value.endtime).format("HH:mm");
            }

            dialogEvent = {
				...dialogEvent,
				...value,
            };
        } else if (name === "calendar") {
            dialogEvent["calendar"]["label"] = value.label;

            if (dialogEvent.id) dialogEvent["newcalendarid"] = value.value;

            if (!dialogEvent.id) dialogEvent["calendar"]["value"] = value.value;
        } else {
            dialogEvent = {
                ...dialogEvent,
                [name]: value,
            };
        }
        
        this.setState({ dialogEvent });

    };

    onSave = () => {
        let dialogEvent = this.state.dialogEvent;
        let calendarid = "";

        delete dialogEvent.timerange;
        if (dialogEvent.calendar) {
            calendarid = dialogEvent.calendar.value;
            delete dialogEvent.calendar;
        }

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const startTime = dialogEvent.startdate + "T" + dialogEvent.starttime + ":00";
        const endTime = dialogEvent.enddate + "T" + dialogEvent.endtime + ":00";

        dialogEvent.start = {};
        dialogEvent.start.dateTime = startTime;
        dialogEvent.start.timeZone = timeZone;

        dialogEvent.end = {};
        dialogEvent.end.dateTime = endTime;
        dialogEvent.end.timeZone = timeZone;

        delete dialogEvent.startdate;
        delete dialogEvent.startime;
        delete dialogEvent.enddate;
        delete dialogEvent.endtime;
        delete dialogEvent.hours;
        delete dialogEvent.from_primary;
        delete dialogEvent.calendar_role;
        delete dialogEvent.htmlLink;

        const url = this.props.selectedCalendar === "google" ? "calendar/google/event" : "calendar/office/event";

        if (dialogEvent.new) {
            delete dialogEvent.new;
            
            DataHandler.post({ url: url }, { event: dialogEvent, calendarid: calendarid })
                .done(response => {
                    this.setState({ dialogEvent: false });
                    this.props.onSave();
                })
                .fail(err => {
                    this.props.onSave();
                    this.setState({ dialogEvent: false });
                });
        } else {
            DataHandler.put({ url: url}, { event: dialogEvent, calendarid: calendarid })
                .done(response => {
                    this.props.onSave();
                    this.setState({ dialogEvent: false });
                })
                .fail(err => {
                    this.props.onSave();
                    this.setState({ dialogEvent: false });
                });
        }
    };

    /**
     * GoogleEventDialog renderer
     *
     */
    render() {
        const { lockTime } = this.state;
        const { userObject, functions: { hasPrivilege } } = this.context;
        const { dialogEvent, onClose, onEdit } = this.props;
        const editable =
            dialogEvent.new || dialogEvent.from_primary || dialogEvent.calendar_role == "owner" ? true : false;

        return (
            <DialogBorder
                title={this.tr("Add event")}
                onSave={() => this.onSave(dialogEvent)}
                disableSave={!editable}
                onClose={() => onClose()}
                disableSave={!editable}
                className="googleeventdialog TMRDialogGrid TMRDialogInputs"
                rightButtons={
                    dialogEvent.id && (
                        <DialogDropDownButton className="white" title={this.tr("Options")}>
                            {(hasPrivilege("workhours", "write") || hasPrivilege("workhours", "write_full")) ? <DialogDropDownButtonItem onClick={() => this.props.onCreateTimeEntry(dialogEvent)}>
                                {this.tr("Create a time entry")}
                            </DialogDropDownButtonItem> : undefined}
                            {userObject.hasCrmWritePermission ? <DialogDropDownButtonItem onClick={() => this.props.onCreateActivity(dialogEvent)}>
                                {this.tr("Create activity")}
                            </DialogDropDownButtonItem> : undefined}
                            {this.props.selectedCalendar === "google" ? <DialogDropDownButtonItem onClick={() => this.props.onOpenInGoogle(dialogEvent)}>
                                {this.tr("Edit in google")}
                            </DialogDropDownButtonItem> : undefined}
                            {editable && this.props.onDelete && (
                                <DialogDropDownButtonItem onClick={() => this.props.onDelete(dialogEvent)}>
                                    {this.tr("Delete")}
                                </DialogDropDownButtonItem>
                            )}
                        </DialogDropDownButton>
                    )
                }
            >
                <DialogSection>
                    <OutlinedField
                        className="full title"
                        label={this.tr("title")}
                        name="summary"
                        error={false}
                        value={dialogEvent.summary}
                        onChange={e => this.onChangeDialogEvent(e.target.value, e.target.name)}
                        disabled={!editable}
                    />
                    <DateRangePicker
                        ranges={[
                            {
                                startDate: dialogEvent.startdate,
                                endDate: dialogEvent.enddate,
                                key: "selection",
                            },
                        ]}
                        onChange={event => {
                            const { startDate, endDate } = event.selection;
                            const daterange = {
                                startdate: startDate,
                                enddate: endDate,
                            };
                            this.onChangeDialogEvent(daterange, "daterange");
                        }}
                        onInputChange={(dateType, date) => {
                            if (dateType == "start") {
                                this.onChangeDialogEvent(date, "startdate");
                            } else {
                                this.onChangeDialogEvent(date, "enddate");
                            }
                        }}
                        label={this.tr("dates")}
                        dateFormat={userObject.dateFormat}
                        minDate={lockTime}
                        disabled={true}
                    />
                    <TimeRangeOutlinedField
                        rootClass="time-range"
                        label={this.tr("time")}
                        value={dialogEvent.timerange}
                        onChange={(value, start, end) => {
                            const timerange = {
                                timerange: value,
                                starttime: start,
                                endtime: end,
                            };

                            this.onChangeDialogEvent(timerange, "timerange");
                        }}
                        error={this.isInvalid("time")}
                        disabled={true}
                    />
                    <OutlinedField
                        className="full location"
                        label={this.tr("location")}
                        name="location"
                        error={false}
                        value={dialogEvent.location}
                        onChange={e => this.onChangeDialogEvent(e.target.value, e.target.name)}
                        disabled={!editable}
                    />
                    <OutlinedField
                        className="full"
                        label={this.tr("description")}
                        name="description"
                        error={false}
                        value={dialogEvent.description}
                        onChange={e => this.onChangeDialogEvent(e.target.value, e.target.name)}
                        multiline
                        rows={3}
                        rowsMax={5}
                        disabled={!editable}
                    />
                    <DataList
                        value={dialogEvent.calendar}
                        label={this.tr("calendar")}
                        className="full calendar"
                        component={ReactSelect}
                        isLoading={false}
                        options={this.props.dialogcalendars}
                        onChange={option =>
                            this.onChangeDialogEvent({ value: option.value, label: option.label }, "calendar")
                        }
                        openMenuOnFocus
                        onInputChange={this.onInputChange}
                        isClearable={false}
                        isDisabled={!editable}
                    />
                    {/* 
                    Älä poista. Tullaan käyttämään.
                    <div className="attendees-container full" >
                        <OutlinedField
                            className="attendees-field"
                            label={this.tr("add attendees")}
                            name="attendees"
                            error={false}
                            value={this.state.attendee}
                            onChange={e => this.setState({attendee: e.target.value})}
                        />
                        <Button className="attendees-button" onClick={() => this.addAttendee()}>
                            {this.tr('add')}
                        </Button>
                    </div> 
                    */}
                    <List dense={true} className="full">
                        {(dialogEvent.attendees || []).map((value, i) => {
                            return (
                                <ListItem>
                                    <ListItemIcon>
                                        <SendIcon />
                                    </ListItemIcon>
                                    <ListItemSecondaryAction>
                                        {/* <IconButton
                                            className="icon-delete"
											edge="end"
											aria-label="delete"
											disableRipple={true}
                                            disableFocusRipple={true}
                                            onClick={() => this.deleteAttendee(i)}
                                            disabled={!editable }
										>
											<DeleteIcon />
										</IconButton> */}
                                    </ListItemSecondaryAction>
                                    <ListItemText primary={value.email} />
                                </ListItem>
                            );
                        })}
                    </List>
                </DialogSection>
            </DialogBorder>
        );
    }
}

export default withSnackbar(GooleEventDialog);
