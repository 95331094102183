import React, { ReactNode } from 'react';
import { Button } from '@mui/material';
import { cloneDeep, isEqual } from 'lodash';

import TaimerComponent from "../../TaimerComponent";
import List from "../../list/List";
import PropsOnlyListRow from "../../list/PropsOnlyListRow";
import TreeStructureIndicatorCell from "../../list/cells/TreeStructureIndicatorCell";
import TextInputCell from "../../list/cells/TextInputCell";
import ListCell from "../../list/ListCell";
import CheckboxCell from "../../list/cells/CheckboxCell";

import styles from './TreeSettingsList.module.scss';
import { InfoOutlined } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import FieldEditSlider from '../../general/FieldEditSlider';

class TreeSettingsListRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {
            settingsOpen: false,
        }, { childRowType: TreeSettingsListRow }, "settings/components/TreeSettingsList");
    }

    settingsOpen = () => {
        const { data, rowProps: { onOpenSettings, onCheckUpdate } } = this.props;

        onOpenSettings(data, (item) => {
            onCheckUpdate(data, { "settings": item });
        });
    }

    defineCells() {
        const { rowProps, childrenVisible, data, columnWidthMap } = this.props;

        const cells = {
            name:
                <TreeStructureIndicatorCell
                    childrenVisible={childrenVisible}
                    editable={false}
                    listCellProps={
                        {
                            alignCell: true,
                            textAlign: "left",
                        }
                    }
                    onExpanderClick={e => this.props.listRef.toggleShowChildren(data.id)}
                    rowData={this.props}
                    value={data.name}>
                    <div className={styles.settingsTitleCell}>
                        <div onClick={e => this.props.listRef.toggleShowChildren(data.id)} style={{cursor: (data.level < 0 ? 'pointer' : 'auto')}}>
                            {data.name}
                        </div>
                        {data.settingsString && data.settings && <div className={styles.settingText} onClick={this.settingsOpen}>
                            {data.settingsString && data.settingsString(data.settings)}
                        </div>}
                    </div>
                </TreeStructureIndicatorCell>,

            enable_notification:
                <ListCell className="categoryDefaultCell" width={columnWidthMap.enable_notification} onlyDisplay>
                    <Checkbox
                        onChange={(_, v) => {
                            this.setDataAndUpdate({ ...data, enable_notification: v});
                            rowProps.onCheckUpdate(data, { "enable_notification": v });
                        }}
                        indeterminate={data.partiallyCheckedNotification}
                        checked={data.enable_notification > 0} />

                </ListCell>,

            enable_email:
                <ListCell className="categoryDefaultCell" width={columnWidthMap.enable_email} onlyDisplay>

                    <Checkbox
                        onChange={(_, v) => {
                            this.setDataAndUpdate({ ...data, enable_email: v});
                            rowProps.onCheckUpdate(data, { "enable_email": v });
                        }}
                        indeterminate={data.partiallyCheckedEmail}
                        checked={data.enable_email > 0} />

                </ListCell>,

        }
        return cells;
    }
}

class TreeSettingsList extends TaimerComponent {

    constructor(props, context) {
        super(props, context, 'settings/pages/TreeSettingsList');

        this.list = React.createRef();

        this.state = {
            fieldErrors: {},
            settingsOpen: null,
            settings: {},
        };
    }

    openSettings = (row, callback) => {
        this.setState({
            settingsOpen: true,
            settings: {
                ...row.settingsConfig,
                item: row.settings,
                callback,
            },
        });
    }

    closeSettings = (row) => {
        this.setState({settingsOpen: false, settings: null});
    }

    saveSettings = (item) => {
        const { settings } = this.state;

        settings.callback(item);

        this.setState({settingsOpen: false, settings: null});
    }

    render() {
        const { header, subheader, columns, rows, startWithClosedRows} = this.props;
        const { settingsOpen, settings } = this.state;

        return (
            <div className={styles.treeSettingsListWrapper}>

                <h3>{header}</h3>

                <p className={styles.subtitle}>
                    {subheader}
                </p>

                <List
                    className={styles.treeSettingsList}
                    fluid
                    rowHeight={44}
                    listRowType={TreeSettingsListRow}
                    data={rows}
                    columns={columns}
                    ref={this.list}
                    showPageSelector={false}
                    treeData={true}
                    noStateData={true}
                    rowKey={"id"}
                    parentKey={"parent_id"}
                    parentsExpandedOnInit={false}
                    rowProps={{
                        rowHeight: 44,
                        indentWidth: 24,
                        onCheckUpdate: this.props.onRowChanged,
                        onOpenSettings: this.openSettings,
                        startWithClosedRows: startWithClosedRows,
                    }}
                />

                <FieldEditSlider 
                    open={settingsOpen}
                    title={settings?.title ?? ''}
                    fields={settings?.fields ?? []}
                    item={settings?.item ?? {}}
                    onSave={this.saveSettings}
                    onClose={this.closeSettings}
                />
            </div>
        );
    }
}

export default TreeSettingsList;