import React, { Component } from 'react';
import Button from '@mui/material/Button';
import {stateToHTML} from 'draft-js-export-html';
import'./RichEditor.css';
import {Editor, EditorState, RichUtils, getDefaultKeyBinding} from 'draft-js';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import AttachmentIcon from '@mui/icons-material/Attachment'
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FileBase64 from 'react-file-base64';
import TaimerComponent from "../../TaimerComponent";


export default class RichEditorExample extends TaimerComponent {

    constructor(props) {
      super(props, null, "mail/MailComponents/RichEditorExample");
      this.state = {editorState: EditorState.createEmpty(), displayvalue: "none", editorContentHtml: ""};
      this.focus = () => this.refs.editor.focus();
      this.onBlur = () => {
        this.props.onUpdateContent(this.state.editorContentHtml);
      }
      this.onChange = (editorState) => {
        var cnt = stateToHTML(editorState.getCurrentContent())
        //this.props.onUpdateContent(cnt);
        this.setState({
            editorState,
            editorContentHtml: cnt
        });
    };
      this.handleKeyCommand = this._handleKeyCommand.bind(this);
      this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
      this.toggleBlockType = this._toggleBlockType.bind(this);
      this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    }

    _handleKeyCommand(command, editorState) {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        this.onChange(newState);
        return true;
      }
      return false;
    }

    _mapKeyToEditorCommand(e) {
      if (e.keyCode === 9 /* TAB */) {
        const newEditorState = RichUtils.onTab(
          e,
          this.state.editorState,
          4, /* maxDepth */
        );
        if (newEditorState !== this.state.editorState) {
          this.onChange(newEditorState);
        }
        return;
      }
      return getDefaultKeyBinding(e);
    }

    _toggleBlockType(blockType) {
      this.onChange(
        RichUtils.toggleBlockType(
          this.state.editorState,
          blockType
        )
      );
    }

    _toggleInlineStyle(inlineStyle) {
      this.onChange(
        RichUtils.toggleInlineStyle(
          this.state.editorState,
          inlineStyle
        )
      );
    }
    
    handleChangeEmail = (e) => {
        this.setState({inputValueEmail: e.target.value });

    }

    handleChangeSubject = (e) => {
        this.setState({inputValueSubject: e.target.value });
    }

    hideControls = () => {
      if (this.state.displayvalue === "none") this.setState({displayvalue: "flex"})
      else this.setState({displayvalue: "none"})
    }

    send = () => {
      this.props.onClickSend(this.state.editorContentHtml);
    }

    getFiles(files){
      this.props.onUpdateFile(files);
    }


    render() {
      const {editorState} = this.state;
      // If the user changes block type before entering any text, we can
      // either style the placeholder or hide it. Let's just hide it now.
      let className = 'RichEditor-editor';
      var contentState = editorState.getCurrentContent();

      if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
          className += ' RichEditor-hidePlaceholder';
        }
      }

      return (
        <div className="RichEditor-root">
          
          
          <div className={className} onClick={this.focus}>
            <Editor
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={this.mapKeyToEditorCommand}
              onChange={this.onChange}
              ref="editor"
              spellCheck={false}
            />

          </div>
          <div className="editor-main-buttons">
            <Button color="primary"  className="sendbutton" onClick={this.send} size="small">
              {this.tr("Send")}
            </Button>
            <InlineStyleControls
                editorState={editorState}
                onToggle={this.toggleInlineStyle}
              />
              <BlockStyleControls
                editorState={editorState}
                onToggle={this.toggleBlockType}
              />
              <AttachmentControls
                editorState={editorState}
                onToggle={this.toggleBlockType}
                onUpdateFile={this.props.onUpdateFile}
              />
          </div>         
        </div>
      );
    }
  }

  // Custom overrides for "code" style.
  const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };

  function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return 'RichEditor-blockquote';
      default: return null;
    }
  }

  class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }
    render() {
      let className = 'RichEditor-styleButton';
      if (this.props.active) {
        className += ' RichEditor-activeButton';
      }
      return (
        <span className={className} onMouseDown={this.onToggle}>
          {this.props.label}
        </span>
      );
    }
  }

  class AttachmentButton extends React.Component {
    constructor() {
      super();

      this.upload = React.createRef();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
      this.state = {
        files: []
      }
    }

    getFiles(files){
      this.props.onUpdateFile(files);
    }

    uploadFiles = (evt) => {
    }

    handleClick () {
      this.setState({value: 'another random text'})
      var event = new Event('input', { bubbles: true });
      this.myinput.dispatchEvent(event);
    }


    render() {
      let className = 'RichEditor-styleButton';

      return (
        <span>
          <label className="RichEditor-styleButton">
          <AttachmentIcon className="rotate-90" />
          <FileBase64
            ref={(fileUpload) => {
              this.fileUpload = fileUpload;
            }}
            multiple={ true }
            onDone={ this.getFiles.bind(this) } />
          </label>
        </span>

      );
    }
  }

  const BLOCK_TYPES = [
    /* {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
    {label: 'Blockquote', style: 'blockquote'}, */
    {label: <FormatListBulletedIcon />, style: 'unordered-list-item'},
    {label: <FormatListNumberedIcon />, style: 'ordered-list-item'},
    /* {label: 'Code Block', style: 'code-block'}, */
  ];

  const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    return (
      <div className="RichEditor-controls">
        {BLOCK_TYPES.map((type, index) =>
          <StyleButton
            key={index}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
  };

  var INLINE_STYLES = [
    {label: <FormatBoldIcon />, style: 'BOLD'},
    {label: <FormatItalicIcon />, style: 'ITALIC'},
    {label: <FormatUnderlinedIcon />, style: 'UNDERLINE'},
/*     {label: <TextFormatIcon />, style: 'CODE'}, */
  ];

  const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type, index) =>
          <StyleButton
            key={index}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
  };

  var ATTACHMENT_STYLES = [
    {label: <AttachmentIcon  />, style: 'BOLD'},
  ];

  const AttachmentControls = (props) => {
    
    return (
      <div className="RichEditor-controls">
        {ATTACHMENT_STYLES.map((type, index) =>
          <AttachmentButton
            key={index}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
            onUpdateFile={props.onUpdateFile}
          />
        )}
      </div>
    );
  };