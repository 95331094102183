import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';

import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from './../SettingsContext';
import OutlinedField from "./../general/OutlinedField";
import { DatePicker, DateRangePicker } from './../general/react-date-range/src';
import DataList from './../general/DataList';
import PageTopSection from "./../general/PageTopSection";
import _ from 'lodash';
import { FlexContainer, FlexChild } from "./../general/FlexUtils";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '../list/List';
import ListRow from "../list/ListRow";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import ListCell from "../list/ListCell";
import CurrencyListCell from "../list/CurrencyListCell";
import TextInputCell from "../list/cells/TextInputCell";
import CheckboxCell from "../list/cells/CheckboxCell";
import withStyles from '@mui/styles/withStyles';
import ContextMenu from '../general/ContextMenu';
import {  Switch, Tooltip, Chip, ClickAwayListener } from '@mui/material';
import { ShortText, FormatListBulleted, ReportProblemRounded } from "@mui/icons-material";
import { ResizeSensor } from "css-element-queries";
import AutoCompleteCell from '../list/cells/AutoCompleteCell';
import { nmultiply } from "../general/MathUtils";
import { CurrencyUtils } from "../general/CurrencyUtils";

import { ReactComponent as ManageOwnHours } from "./../general/icons/ManageOwnHours.svg";

import { format, isValid } from "date-fns";
import { withSnackbar } from 'notistack';

import ToolTip from '@mui/material/Tooltip';

import './../general/react-date-range/src/styles.css';
import './../general/react-date-range/src/theme/default.css';

import './InvoiceMaterial.css';

function ProjectOption(props) {
  
    const rows = props.options;
    let menuAutoWidth = props.menuWidth || 'auto';
    if (rows && rows.length > 0 && !props.menuWidth) {
        const len = Math.max(...rows.map(x => String(x.label || "").length));
        menuAutoWidth = (len + 4) + "ch";
    }

    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            disabled={props.isDisabled}
            component="div"
            {...props.innerProps}
            style={{
                fontWeight: props.isSelected ? 500 : 400,
                width: menuAutoWidth,
                minWidth: "-webkit-fill-available"
            }} >
          
          <span className="project-menu-parent-project">{props.data.parent_name}</span>
          
          {props.children}
          
          {props.isDisabled && props.selectProps.disabledSubtext && 
            <div className="listitem-subtext">{props.selectProps.disabledSubtext}</div>}
    
        </MenuItem>
    
    );
}

function TooltipOption(props) {
  
    return (
        <ToolTip title={props.data.tooltip} placement="right">
            <MenuItem
                buttonRef={props.innerRef}
                selected={props.isFocused}
                component="div"
                style={{
                    fontWeight: props.isSelected ? 500 : 400,
                }}
                disabled={props.data.disabled}
                {...props.innerProps}
            >
                <span>{props.data.label}</span>
            </MenuItem>
        </ToolTip>
    );
}

const styles = theme => ({
    topBar: {
        backgroundColor: '#2D9FF7',
        height: '8px',
        marginBottom: '10px'
    },
    dialogRoot: {
        height: "95%"
    },
    controlsContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    buttonsContainer: {
        textAlign: "right",
        flex: "1 1 0px"
    },
    datalistInputRoot: {
        minWidth: "180px"
    },
    optionsDatalistInputRoot: {
        // The width is determined by flex.
        // minWidth: "250px"
    },   
    footerDatalistMenu: {
        bottom: "58px"
    },
    tabRoot: {
        border: "1px solid #dde3e8",
        borderRadius: "4px",
        minHeight: "46px", 
        maxHeight: "46px"
    },
    tabsRoot: {
        marginTop: "8px",
        marginBottom: "4px",
        marginRight: "16px"
    },
    tabIndicator: {
        display: "none"
    },
    button: {
        height: "46px",
        alignSelf: "flex-end",
        marginTop: "8px",
        marginBottom: "4px",
        marginLeft: 15,
        flex: "auto"
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: -4
    },
    chip: {
        margin: "5px",
        borderRadius: "4px",
        height: 'auto',
        minHeight: 30
    },
    chipLabel: {
        maxWidth: 270,
        whiteSpace: 'normal',
    },
    hidden: {
        display: "none"
    }
});


class ProjectRow extends PropsOnlyListRow {

    static contextType = SettingsContext;
 
    constructor(props, context) {
        super(props, {}, {}, "invoices/ProjectRow");
    }

    defineClassName() {
        return "project-total-row";
    }

    defineCells() {
        const { columnWidthMap, columnConfig, data, rowProps } = this.props;
        const { taimerAccount, addons } = this.context;
        const formatSum = (value) => {
            return Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: taimerAccount.currency}).format(value);
        };

        const commonProps = {
            editable: false,
            textAlign: "left"
        };
        

        const cells = {
            nr:
            <ListCell
                {...commonProps}
                name="nr"
                className="nr"
                value={data.nr} />,
            projects_name:
                <ListCell
                    {...commonProps}
                    name="projects_name"
                    value={data.projects_name} />,
            subcontracting: 
                <ListCell
                    {...commonProps}
                    name="subcontracting"
                    value={formatSum(data.subcontracting)}
                    />,
            travel_expense: 
                <ListCell
                    {...commonProps}
                    name="travel_expense"
                    value={formatSum(data.travel_expense)}
                />,
            material_hours: 
                <ListCell
                    {...commonProps}
                    name="material_hours"
                    value={data.material_hours}
                />,
            chosen_hours: 
                <ListCell
                    {...commonProps}
                    name="chosen_hours"
                    value={data.chosen_hours}
                />,
            own_work_list_price:
                <ListCell
                    {...commonProps}
                    name="own_work_list_price"
                    value={formatSum(data.own_work_list_price)}
                />,
            own_work:
                !addons.simple_complex_invoicing ? 
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{
                        inEditMode: rowProps.ownHours && data.hasOwnWork,
                        editable: rowProps.ownHours && data.hasOwnWork,
                    }}
                    onEdited={(name, value) => {
                        rowProps.editProjectRowTotal(data.projects_id, value, data.own_work_list_price);
                    }}
                    name="own_work"
                    textAlign="left"
                    value={data.own_work}
                /> : 
                <ListCell 
                    editable={false}
                    name="total"
                    textAlign="left" 
                    value={formatSum(data.own_work)}
                />,
            total:
                addons.simple_complex_invoicing ? 
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{
                        inEditMode: rowProps.ownHours && data.hasOwnWork,
                        editable: rowProps.ownHours && data.hasOwnWork,
                    }}
                    onEdited={(name, value) => {
                        value = value?.replace(",", ".");
                        rowProps.editProjectRowTotal(data.projects_id, value - data.subcontracting - data.travel_expense, data.own_work_list_price);
                    }}
                    name="own_work"
                    textAlign="left"
                    value={data.total}
                /> : 
                <ListCell 
                    editable={false}
                    name="total"
                    textAlign="left" 
                    value={formatSum(data.total)}
                />,
        };

        return cells;
    }
}


class ProjectTotalsRow extends PropsOnlyListRow {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, {}, {}, "invoices/ProjectTotalsRow");
    }

    defineClassName() {
        return "totals-row";
    }

    defineCells = () => {

        const { taimerAccount } = this.context;
        const { columnOrder, columnWidthMap, sharedData, rowProps, data } = this.props;

        const formatSum = (value) => {
            return Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: taimerAccount.currency}).format(value);
        };

        const dateFormat = new Intl.DateTimeFormat(taimerAccount.numberFormat).format;

        const commonProps = {
            inEditMode: false,
            editable: false
        };

        const cells = {
            "nr": 
                ( <ListCell />),
            "projects_name": (
                <ListCell
                    {...commonProps}
                    value={`${this.tr('Total')}:`} />
            ),           
            "subcontracting": (
                <ListCell
                {...commonProps}
                textAlign="left"
                listCellType={CurrencyListCell}
                name="subcontracting"
                 value={formatSum(data.subcontracting)}  />
            ),
            "travel_expense": (
                <ListCell
                    {...commonProps}
                    textAlign="left"
                    listCellType={CurrencyListCell}
                    name="travel_expense"
                    value={formatSum(data.travel_expense)}/>
            ),
            "material_hours": (
                <ListCell
                    {...commonProps}
                    textAlign="left"
                    name="material_hours"
                    value={data.material_hours} />
            ),
            "chosen_hours": (
                <ListCell
                    {...commonProps}
                    textAlign="left"
                    name="chosen_hours"
                    value={data.chosen_hours}  />
            ),
            "own_work_list_price": (
                <ListCell
                    {...commonProps}
                    textAlign="left"
                    listCellType={CurrencyListCell}
                    editable={false}
                    name="own_work_list_price"
                    value={formatSum(data.own_work_list_price)} />
            ),
            "own_work": (
                <ListCell
                    {...commonProps}
                    textAlign="left"
                    listCellType={CurrencyListCell}
                    editable={false}
                    name="own_work"
                    value={formatSum(data.own_work)} />
            ),     
             "total": (
                <ListCell
                    {...commonProps}
                    textAlign="left"
                    listCellType={CurrencyListCell}
                    editable={false}
                    name="total"
                    value={formatSum(data.total)} />
            ),          
        };

        return cells;
    }
}
class InvoiceMaterialRow extends PropsOnlyListRow {

    static contextType = SettingsContext;
 
    constructor(props, context) {
        super(props, {}, {}, "invoices/InvoiceMaterial");
    }

    defineClassName() {
        let className = "invoice-material-row";
        if(this.props.data.row_type == 13 && this.props.data.main_type == 1) {
            className += " joined-hour";
        }
        return className;
    }

    setQuantity = (name, val) => {
        const { quantity, partial_quantity } = this.props.data;
        if (val <= 0 || val > (quantity - partial_quantity))
            return false;
        else 
            this.setData("quantity_to_invoice", val);
    }
   
    defineCells() {
        const { columnWidthMap, columnConfig, data, rowProps } = this.props;

        const { taimerAccount, userObject, addons } = this.context;

        const formatSum = (value) => {
            return Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: taimerAccount.currency}).format(value);
        };

        const commonProps = {
            editable: false,
            textAlign: "left"
        };

        const partialInvoicing = addons.quoterow_partial_invoicing_for_products;
        
        const invoiceRowPriceClasses = ['invoice-row-price'];
        
        const isJoinedHour = data.row_type == 13 && data.main_type == 1;
        data.row_type != 1 && !rowProps.ownHours && !isJoinedHour && !rowProps.has_multi_address_relation && invoiceRowPriceClasses.push("editable");
        data.selected_price !== null && isJoinedHour && !rowProps.has_multi_address_relation && invoiceRowPriceClasses.push("edited");
        data.selected > 0 && !rowProps.has_multi_address_relation && invoiceRowPriceClasses.push("selected");
        
        let invoiceRowPrice;
        
        if(data.selected < 1)
            invoiceRowPrice = false;
        if (this.context.addons.simple_complex_invoicing) {
            if (data.row_type === "13")
                invoiceRowPrice = false;
            else if (data.row_type === "1")
                invoiceRowPrice = data.row_price;
            else 
                invoiceRowPrice = data.billed_price;
        }
        else {
            invoiceRowPrice = data.row_type == 1 ? data.quantity * data.value : data.billed_price;
        }

        let showBilledPrice = !addons.simple_complex_invoicing || !["5", "2"].includes(data.row_type);
        if (addons.simple_complex_invoicing && ["5"].includes(data.row_type) && Number(data.worktasks_id) > 0)
            showBilledPrice = true

        let showValue = true;

        if (data.row_type === '14')
            showValue = showBilledPrice = false;

        const isPartialInvoicingProduct = partialInvoicing && data.is_product_row == 1;
        
        const cells = {
            checked:
                showValue ? <CheckboxCell disabled={rowProps.has_multi_address_relation && data.selected == 0} key={data.id} checked={data.selected > 0} onEdited={() => !rowProps.has_multi_address_relation && this.props.rowProps.onCheck(data.id)} />: 
                <ListCell
                    {...commonProps}
                    name="placeholder"
                    textAlign="right"
                    value={""} />,        
            projects_name:
                <ListCell
                    {...commonProps}
                    name="projects_name"
                    value={this.props.rowProps.selectedProjects.find(sp => sp.id == data.projects_id)?.label} />,
            creation_date: 
                <ListCell
                    {...commonProps}
                    name="creation_date"
                    value={format(data.creation_date, userObject.dateFormat)} />,
            type_name: 
                <ListCell
                    {...commonProps}
                    name="type_name"
                    value={this.props.rowProps.invoiceDataTypes.find(idt => idt.id == data.row_type)?.name} />,
            header: 
                <ListCell
                    {...commonProps}
                    name="header"
                    value={data.material_header || ''} />,
            billable: 
            
                <AutoCompleteCell
                    width={columnWidthMap.billable}
                    name="billable"
                    listCellProps={{className: "billable"}}
                    value={data.billable}
                    searchable={false}
                    editable={data.row_type == 1}
                    autoCompleteData={rowProps.billableOptions}
                    onEdited={value => {
                        rowProps.setBillable(data.id, value);
                    }} 
                />,
            worktype: 
                <ListCell
                    {...commonProps}
                    name="description"
                    value={data.worktasks_name} />,
            description: 
                <ListCell
                    {...commonProps}
                    name="description"
                    value={data.entry_description || data.description} />,
            quantity_delivered:
                <ListCell 
                    value={data.is_product_row == 1 ? data.quantity_delivered : "–"}
                    editable={false}
                    textAlign="right" />,
            quantity_invoiced:
                data.is_product_row == 1 ? <TextInputCell
                    editable={!rowProps.has_multi_address_relation}
                    listCellProps={{ className: "invoiced_quantity" + (rowProps.has_multi_address_relation ? " not-editable" : "") }}
                    textAlign="right"
                    name="quantity_invoiced"
                    onEdited={(name, value) => rowProps.setQuantity(data.id, value)}
                    value={data.quantity} /> : <ListCell value={"–"} editable={false} />,
            quantity: 
                <ListCell
                    {...commonProps}
                    textAlign="right"
                    name="quantity"
                    value={isPartialInvoicingProduct ? `${data.costest_quantity} / ${(Number(data.invoiced_elsewhere) + Number(data.total_quantity || data.quantity)).toFixed(2)}` : Number(data.original_quantity || data.quantity).toFixed(2)} />,                    
            value: 
                showValue ? <TextInputCell
                    {...commonProps}
                    editable={false}
                    listCellType={CurrencyListCell}
                    listCellProps={{
                        maximumFractionDigits: 4,
                        currency: taimerAccount.currency,
                    }}
                    name="value"
                    textAlign="right"
                    value={data.value} /> : 
                <ListCell
                    {...commonProps}
                    name="placeholder"
                    textAlign="right"
                    value={""} />,        
                       
            total_no_vat: 
                showValue ? <ListCell
                    {...commonProps}
                    name="total_no_vat"
                    textAlign="right"
                    value={formatSum(nmultiply(
                        Number((isPartialInvoicingProduct || !data.original_quantity) ? data.quantity : data.original_quantity)
                        , Number(data.value)
                    ))} /> : 
                <ListCell
                    {...commonProps}
                    name="placeholder"
                    textAlign="right"
                    value={""} />,        
            invoice_row_price:
                showBilledPrice ? <TextInputCell
                    {...commonProps}
                    editable={data.row_type != 1 && !rowProps.ownHours && !isJoinedHour && !rowProps.has_multi_address_relation}
                    name="invoice_row_price"
                    textAlign="right"
                    listCellProps={{
                        className: invoiceRowPriceClasses.join(" ") + (data?.warning_border ? ' warning_border' : ''),
                        allowEmpty: true
                    }}
                    listCellType={CurrencyListCell}
                    onEdited={(name, value) => { 
                        value = value.replace(",", "."); 
                        (Number(data.billed_price) !== Number(value) || value === '') && rowProps.setBilledPrice(data.id, value === "" ? null : value)} }
                    value={invoiceRowPrice} /> : 
                <ListCell
                    {...commonProps}
                    name="placeholder"
                    textAlign="right"
                    value={""} />,
            billed_price:
                showBilledPrice ? <TextInputCell
                    editable={false}
                    textAlign="right"
                    name="billed_price"
                    listCellProps={{
                        allowEmpty: true,
                        className: 'billed_price'
                    }}
                    listCellType={CurrencyListCell}
                    onEdited={(name, value) => { 
                        value = value.replace(",", "."); 
                        (Number(data.billed_price) !== Number(value) || value === '') && rowProps.setBilledPrice(data.id, value === "" ? null : value)} }
                    value={data.selected > 0 ? Number(data.billed_price).toFixed(2) : false} /> : 
                <ListCell
                    {...commonProps}
                    name="placeholder"
                    textAlign="right"
                    value={""} />,
        };

        return cells;
    }
}


class InvoiceMaterial extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "invoices/InvoiceMaterial");

        this.tabs = [
            {
              key: "list",
              title: this.tr("List"),
              href: this.context.functions.urlify({...this.props.viewProps, view: "list"}),
              icon: <FormatListBulleted />,
              action: (e) => this.selectTab(e, "list"),
            },
        ];

        if(props.data.useManualOwnworkInvoicing) {
            this.tabs.push({
              key: "manageOwnHoursTotals",
              title: this.tr("Manage own hours totals"),
              href: this.context.functions.urlify({...this.props.viewProps, view: "manageOwnHoursTotals"}),
              icon: <ManageOwnHours />,
              action: (e) => this.selectTab(e, "manageOwnHoursTotals"),
            });
        }

        this.handleRowCheck = this.handleRowCheck.bind(this);
        this.setBilledPrice = this.setBilledPrice.bind(this);
        this.setQuantity = this.setQuantity.bind(this);

        this.projectsGrouping = [
            {id: "1", label: this.tr("By project"), tooltip: this.tr("Group entries by project. When invoicing multiple projects all entries grouped under respective project topic row.")},
            {id: "2", label: this.tr("No project grouping"), tooltip: this.tr("When invoicing multiple projects, use this selection to hide topic rows with project names and show all entries in one list.")},
            {id: "3", label: this.tr("By project and task"), tooltip: this.tr("View each task entires separately. Each task name is added to the topic name after the project name."), disabled: !(this.context.addons && this.context.addons.resourcing)},
        ];
        
        this.workhoursGrouping = [
            {id: "3", label: this.tr("Per employee"), tooltip: this.tr("Group all hours per employee to one row. Qty will show actual hours if selling price same for all entries. Qty will show as 1 if selling price differ.")}, /* disabled when procountor is enabled */
            {id: "2", label: this.tr("Per employee and task"), tooltip: this.tr("Group all hours per employee and task to one row. Qty will show actual hours if selling price same for all entries. Qty will show as 1 if selling price differ.")},
            {id: "4", label: this.tr("Per task"), tooltip: this.tr("Group all hours per task to one row. Qty will show actual hours if selling price same for all entries. Qty will show as 1 if selling price differ")},
            {id: "5", label: this.tr("All-in-one, show qty"), tooltip: this.tr("Group all hours to one row. Qty will show actual hours if selling price same for all entries. Qty will show as 1 if selling price differ.")}, /* disabled when procountor is enabled */
            {id: "7", label: this.tr("All-in-one, qty 1"), tooltip: this.tr("Group all hours to one row, regardless of hourly selling price. Quantity set as 1 and one total row for the sum.")},
            {id: "1", label: this.tr("By entry, show employee"), tooltip: this.tr("All entries by users specified by row showing date, user, jobtype and description.")},
            {id: "6", label: this.tr("By entry, hide employee"), tooltip: this.tr("All entries by users, excluding user name, specified by row showing date, jobtype and description.")},
            {id: "8", label: this.tr("By employee and entry"), tooltip: this.tr("Each employee separately shown by entry and date. Row showing employee, date, jobtype and description.")},
            ...((this.context.addons?.unit_and_protitle_grouping?.used_by_companies.indexOf(this.props.data.companyId) > -1) ? [{id: "9", label: this.tr("By dimension team and professional title"), tooltip: this.tr("Group all hours by user's dimension team and professional title.")}] : []),
        ];
        
        /* remove some grouping options when procountor accounting is enabled */
        if (this.context.addons.procountor_accounting)
           this.workhoursGrouping = this.workhoursGrouping.filter(e => ["3", "5"].includes(e.id) === false) 

        this.worktripsGrouping = [
            {id: "3", label: this.tr("Travel expenses all in one")},
            {id: "1", label: this.tr("Daily allowance, mileage and other expenses specified")},
            {id: "2", label: this.tr("Daily allowance and mileage in one, other expenses specified")}
        ];
        this.billableOptions = [
            {id: "0", value: "0", name: this.tr("Default")},
            {id: "-1",value: "-1", name: this.tr("Non-billable")},
        ];

        this.costestGrouping = [
            {id: "2", label: this.tr("Do not import description rows from quote")},
            {id: "1", label: this.tr("Import description rows from quote")}
        ];
        const invoiceDataRows = props.data.invoiceData.invoice_data_rows ? props.data.invoiceData.invoice_data_rows : [];
        
        this.state = {
            rows: invoiceDataRows,
            selectedProjects: _.cloneDeep(this.props.data.projects),
            row_type: props.data.invoiceDataTypes[0],
            grouping: {
                projects: this.projectsGrouping.find(e => e.id == this.props.data.groupingOptions.projects),
                workhours: this.workhoursGrouping.find(e => e.id == this.props.data.groupingOptions.workhours),
                worktrips: this.worktripsGrouping.find(e => e.id == this.props.data.groupingOptions.worktrips),
                costest: this.costestGrouping.find(e => e.id == this.props.data.groupingOptions.costest)
            },
            allChecked: invoiceDataRows.filter(s => s.selected > 0).length === invoiceDataRows.length,
            allowPlainUpdate: true,
            ownHours: false,
            view: 'list',
            projectTotals: [],
            projectsGrandTotal: {
                _type: 'projectTotalsRow',
                subcontracting: 0,
                travel_expense: 0,
                material_hours: 0,
                chosen_hours: 0,
                own_work_list_price: 0,
                own_work: 0,
                total: 0
            },
            selectedProjectsOwnWork: this.props.data.selectedProjectsOwnWork || {},
            ownHours: this.props.data.useOwnHours,
            fluid: {}
        }

        this.datePicker = React.createRef();
        this.list = React.createRef();
        this.container = React.createRef();
        this.dialogActions = React.createRef();

        const partialProductInvoicing = this.context.addons.quoterow_partial_invoicing_for_products;
        
        const commonProps = {
            showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false
        };
        
        this.fields = [
            { field: "checked", name: "checked", header: "", columnHeaderType: "checkbox", width: 50, ...commonProps },
            { field: "projects_name", name: "projects_name", header: this.tr("Project"), width: 250, ...commonProps},
            { field: "creation_date", name: "creation_date", header: this.tr("Creation date"), width: 110, ...commonProps },
            { field: "billable",name: "billable", header: this.tr("billable"), width: 170, ...commonProps },
            { field: "type_name", name: "type_name", header: this.tr("Type"), width: 110, ...commonProps },
            { field: "header", name: "header", header: this.tr("Header"), width: 110, ...commonProps },
            { field: "worktype", name: "worktype", header: this.tr("Worktype"), width: 170, ...commonProps },
            { field: "description", name: "description", header: this.tr("Description"), width: 180, ...commonProps },
            { field: "quantity", name: "quantity", header: this.tr(partialProductInvoicing ? "Qty / sold" : "Qty"), width: 110, ...commonProps },

            // This field is used to select the quantity to be invoiced now.
            partialProductInvoicing ? { field: "quantity_delivered", name: "quantity_delivered", header: this.tr("Delivered"), width: 110,...commonProps } : undefined,
            partialProductInvoicing ? { field: "quantity_invoiced", name: "quantity_invoiced", header: this.tr("Invoiced"), width: 110, ...commonProps } : undefined,
            
            { field: "value", name: "value", header: this.tr("Unit price"), width: 110, ...commonProps },
            { field: "total_no_vat", name: "total_no_vat", header: this.tr("Tot. 0%"), width: 110, ...commonProps },
            { field: "invoice_row_price", name: "invoice_row_price", header: this.tr("Update rows"), width: 110, ...commonProps },
            { field: "billed_price", name: "billed_price", header: this.tr("Report price"), width: 110, ...commonProps },
        ].filter(field => field !== undefined && field !== false);


        this.projectTotalFields = [
            { field: "nr", name: "nr", header:  this.tr("Nr"), width: 50, ...commonProps },
            { field: "projects_name", name: "projects_name", header: this.tr("Project"), width: 250, ...commonProps},
           // { field: "mainTotals", name: "mainTotals", header:"", width: 50, ...commonProps },
            { field: "subcontracting", name: "subcontracting", header: this.tr("Subcontracting"), width: 100, ...commonProps },
            { field: "travel_expense", name: "travel_expense", header: this.tr("Travel expenses"), width: 100, ...commonProps },
            { field: "material_hours", name: "material_hours", header: this.tr("Material Hours"), width: 100, ...commonProps },
            { field: "chosen_hours", name: "chosen_hours", header: this.tr("Chosen Hours"), width: 100, ...commonProps },
            { field: "own_work_list_price", name: "own_work_list_price", header: this.tr("Own Work List Price"), width: 100, ...commonProps },
            { field: "own_work", name: "own_work", header: this.tr("Own work"), width: 100, ...commonProps }, 
            { field: "total", name: "total", header: this.tr("Total"), width: 100, ...commonProps }
          
        ].filter(field => field !== undefined && field !== false);

        this.snackbarKey = null;

        this.determineListFluidProps();
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(!_.isEqual(prevProps.data.invoiceData, this.props.data.invoiceData)) {
            this.calculateBilledPrices([...this.props.data.invoiceData.invoice_data_rows]);
        }
        if(!_.isEqual(prevProps.data.projects, this.props.data.projects)) {
            this.setState({selectedProjects: _.cloneDeep(this.props.data.projects)});
        }
    }
    
    componentDidMount () {
        this.calculateBilledPrices();

        new ResizeSensor(document.body, this.determineListFluidProps);
    }

    determineListFluidProps = () => {
        // The dialog's width seems to be 
        // 100% - a 48 px margin on both sides, 
        // so this should do.
        const dialogWidth = window.innerWidth - (2 * 48);
        const list  = this.fields.map(f => f.width).reduce((acc, cur) => acc + cur);
        const hours = this.projectTotalFields.map(f => f.width).reduce((acc, cur) => acc + cur);
        const { 
            fluid
        } = this.state;

        const n = {
            list: list < dialogWidth,
            hours: hours < dialogWidth
        };

        if(_.isEqual(n, fluid)) {
            return;
        }

        this.setState({
            fluid: n
        });
    }
    
    calculateTotals = (rows) => {
        let hasWorkhours = false, 
            hasActualWorkhours = false, 
            workhourTotal = 0, 
            hoursTotal = 0, 
            whTotalPerProject = {},
            hasOther = false, 
            otherTotal = 0, 
            freeformOtherTotal = 0, 
            freeformRowsTotal = 0, 
            allowPlainUpdate = true,
            selWhTotalPerProject = {}, 
            otherPerProject = {}, 
            selOtherPerProject = {}, 
            otherZeroProjects = {},
            ownworkPerProject = {},
            perProject = {},
            actualTotal = 0,
            actualHoursTotal = 0;
        
        this.props.data.selectedProjects.forEach(e => perProject[e.id] = {
            workhourTotal: 0, 
            hoursTotal: 0, 
            actualTotal: 0, 
            actualHoursTotal: 0, 
            selectedTotal: this.state.selectedProjectsOwnWork[e.id] ? this.state.selectedProjectsOwnWork[e.id].selected_price : 0
        });
        
        for(const i in this.state.selectedProjectsOwnWork) {
            ownworkPerProject[i] = this.state.selectedProjectsOwnWork[i].selected_price;
        }
        
        rows.forEach(e => {
            e.quantity = Number(e.quantity);
            const project = perProject[ e.projects_id ];
            
            const originalPrice = this.originalPrice(e);

            if (!project)
                return;
            
            if(!this.context.addons.simple_complex_invoicing && hasActualWorkhours && e.row_type == 13) {
                return;
            }
            if (e.row_type == 1 || e.row_type == 13) {
                whTotalPerProject[e.projects_id] = (whTotalPerProject[e.project_id] || 0) + originalPrice; 
            } else {
                otherPerProject[e.projects_id] = (otherPerProject[e.project_id] || 0) + originalPrice;
            }
            if (e.selected != 1) {
                return false;
            }
            
            if (this.context.addons.simple_complex_invoicing && ["2"].includes(e.row_type))
                return false;

            if (this.context.addons.simple_complex_invoicing && ["5"].includes(e.row_type) && !Number(e.worktasks_id))
                return false;
            
            e.quantity -= 0; e.value -= 0;
            
            if (e.row_type == 1 || e.row_type == 13) {
                if(e.row_type == 1) {
                    hasActualWorkhours = true;
                    project.actualTotal +=  originalPrice;
                    project.actualHoursTotal += e.quantity;
                    actualTotal +=  originalPrice;
                    actualHoursTotal += e.quantity;
                    
                }
                hasWorkhours = true;
                hoursTotal += e.quantity;
                
                project.workhourTotal +=  originalPrice;
                project.hoursTotal += e.quantity;
                
                if (e.value != 0)
                    workhourTotal += originalPrice;
    
                selWhTotalPerProject[e.projects_id] = (selWhTotalPerProject[e.project_id] || 0) + originalPrice;

            }
            else {
                hasOther = true;
                
                otherTotal += e.selected_price !== null ? Number(e.selected_price) : originalPrice;
                selOtherPerProject[e.projects_id] = (selOtherPerProject[e.project_id] || 0) + originalPrice;
                if(e.quantity * e.value == 0) {
                    otherZeroProjects[e.project_id] = (otherZeroProjects[e.project_id] || 0) + 1;
                }
                /* freeform */
                if (e.selected_price === null) {
//                    if(e.value >= 0) {
                        // en oo oiekeen varma miten tää kannattaa tehä mutta ainakin tällä nyt sain toimimaan jos on negatiivisiakin
                        freeformOtherTotal += originalPrice;
//                    }
                    freeformRowsTotal++;
                }
            }
            
            return true;
        });
        
        return {
            hasWorkhours, 
            hasActualWorkhours, 
            workhourTotal, 
            hoursTotal, 
            whTotalPerProject,
            hasOther, 
            otherTotal, 
            freeformOtherTotal, 
            freeformRowsTotal, 
            allowPlainUpdate,
            selWhTotalPerProject, 
            otherPerProject, 
            selOtherPerProject, 
            otherZeroProjects,
            perProject,
            actualTotal,
            actualHoursTotal,
            ownworkPerProject,
        };
    }
    originalPrice = (materialRow) => {
        let discount = 0;

        if (!materialRow.value)
            materialRow.value = 0;

        if (!materialRow.quantity)
            materialRow.quantity = 0;
        
        if (materialRow.row_type == 5 && materialRow.discount_percent > 0) {
            /* quote row discount */
            discount = CurrencyUtils.calculateDiscountAmount(materialRow.value, materialRow.discount_percent, 2);
        }
        return Number(CurrencyUtils.calculateNetTotalWithDiscount(materialRow.value, materialRow.quantity, discount, 2));
    }
    calculateBilledPrices = (rows) => {
        
        if (this.context.addons.simple_complex_invoicing)
            return this.calculateBilledPricesSimple(rows);
        
        rows = rows || _.cloneDeep(this.state.rows);
        const netTotal = Number(this.props.data.netTotal);

        const {
            hasWorkhours, 
            hasActualWorkhours, 
            workhourTotal, 
            hoursTotal, 
            whTotalPerProject,
            hasOther, 
            otherTotal, 
            freeformOtherTotal, 
            freeformRowsTotal, 
            allowPlainUpdate,
            selWhTotalPerProject, 
            otherPerProject, 
            selOtherPerProject, 
            otherZeroProjects,
            ownworkPerProject
        } = this.calculateTotals(rows);

        let allowPlainUpdateFromTotals = allowPlainUpdate;
    
        let workhourMultiplier = hasWorkhours && (workhourTotal ? (netTotal - otherTotal) / workhourTotal : (netTotal - otherTotal) / hoursTotal);
        let otherMultiplier = hasOther && freeformOtherTotal &&  (netTotal - (otherTotal - freeformOtherTotal)) / freeformOtherTotal;
        let priceDiffersFromBilledPrice = false;

        if (freeformOtherTotal >= 0) {
            if (otherMultiplier < 0 && this.props.data.canEditContent) {
                otherMultiplier = 0;
                workhourMultiplier = 0;
                allowPlainUpdateFromTotals = false;
            }
            else if (otherMultiplier < 0) {
                this.props.enqueueSnackbar(this.tr("You can't set the selling prices over invoice net total"), {
                    variant: "error" 
                });
                return;
            }
        }
        // jos manuaalisesti projekteille
        if(this.state.ownHours) {
            let billedPriceSum = 0;
            rows = rows.map(e => {
                if (e.selected != 1) {
                   e.billed_price = 0;
                   return e;
                }
                const originalPrice = this.originalPrice(e);
                let ownwork = Number(ownworkPerProject[e.projects_id] ? ownworkPerProject[e.projects_id] : 0),
                      whTotal = Number(whTotalPerProject[e.projects_id] ? whTotalPerProject[e.projects_id] : 0),
                      selTotal = Number(selWhTotalPerProject[e.projects_id] ? selWhTotalPerProject[e.projects_id] : 0),
                      other = Number(otherPerProject[e.projects_id] ? otherPerProject[e.projects_id] : 0),
                      selOther = Number(selOtherPerProject[e.projects_id] ? selOtherPerProject[e.projects_id] : 0);
                // ei valittua omaa työtä
                if(selWhTotalPerProject[e.projects_id] === undefined) {
                    ownwork = 0;
                }
                if(e.row_type != 1 && e.row_type != 13) {
                    if(selOther > 0) {
                        const oM = (netTotal - ownwork) / selOther;
                        e.billed_price = originalPrice * oM;
                    } else if(otherZeroProjects[e.projects_id] > 0) {
                        e.billed_price = (whTotal - ownwork) / otherZeroProjects[e.projects_id];
                    } else {
                        e.billed_price = 0;
                    }
                    
                    if (!(e.row_type == 1 || e.row_type == 13)) {
                        if (originalPrice?.toFixed(2) != Number(e.billed_price).toFixed(2)) {
                            priceDiffersFromBilledPrice = true;
                            e.warning_border = true;
                        } else {
                            e.warning_border = false;
                        }
                    }

                    return e;
                }

                if(hasActualWorkhours && e.row_type == 13) {
                    e.billed_price = 0;
                } else {
                    if (selTotal > 0 && ownwork > 0) {
                        const whMultiplier =  ownwork / selTotal;
                        e.billed_price = (e.quantity * e.value) * whMultiplier;
                    }
                    else if (ownwork > 0) {
                        e.billed_price = ownwork / e.quantity;
                    }
                    else
                        e.billed_price = 0;
                }

                billedPriceSum += e.billed_price

                if (!(e.row_type == 1 || e.row_type == 13)) {
                    if (originalPrice?.toFixed(2) != Number(e.billed_price).toFixed(2)) {
                        priceDiffersFromBilledPrice = true;
                        e.warning_border = true;
                    } else {
                        e.warning_border = false;
                    }
                }

                return e;
            });
            if(billedPriceSum > netTotal) {
                this.differentOwnWorkBilledPriceSB = this.props.enqueueSnackbar(this.tr("You can't set the own work prices over invoice net total"), {
                    variant: "error",
                    preventDuplicate: true

                });
                return;
            }
        } else {

            // jos EI manuaalisesti projekteille
            if (workhourMultiplier > 0 || hasOther === false)
                rows = rows.map(e => {
                    if (e.selected != 1) {
                       e.billed_price = 0;
                       return e;
                    }

                    const originalPrice = this.originalPrice(e);

                    if (e.row_type == 1 || e.row_type == 13) {
                        e.quantity -= 0; e.value -= 0;
                        if(hasActualWorkhours && e.row_type == 13) {
                            e.billed_price = 0;
                        } else {
                            e.billed_price = workhourTotal ? e.quantity * e.value * workhourMultiplier : e.quantity * workhourMultiplier;
                        }
                    }
                    else {
                        e.billed_price = e.selected_price !== null ? e.selected_price : originalPrice;
                    }

                    if (!(e.row_type == 1 || e.row_type == 13)) {
                        if (originalPrice?.toFixed(2) != Number(e.billed_price).toFixed(2)) {
                            priceDiffersFromBilledPrice = true;
                            e.warning_border = true;
                        } else {
                            e.warning_border = false;
                        }
                    }

                    return e;
                });
            else
                rows = rows.map(e => {
                    if (e.selected != 1) {
                       e.billed_price = 0;
                       return e;
                    }
                    const originalPrice = this.originalPrice(e);
                    if (e.row_type == 1 || e.row_type == 13)  
                        e.billed_price = 0;
                    else if (e.selected_price !== null)
                        e.billed_price = e.selected_price;
                    else if (otherMultiplier !== false) {
                            // en oo oiekeen varma miten tää kannattaa tehä mutta ainakin tällä nyt sain toimimaan jos on negatiivisiakin
//                        if(e.value < 0) {
//                            e.billed_price = e.quantity * e.value;
//                        } else {
                            e.billed_price = originalPrice * otherMultiplier;
//                        }
                    }
                    else
                        e.billed_price = (netTotal - otherTotal) / freeformRowsTotal;

                    if (!(e.row_type == 1 || e.row_type == 13)) {
                        if (originalPrice?.toFixed(2) != Number(e.billed_price).toFixed(2)) {
                            priceDiffersFromBilledPrice = true;
                            e.warning_border = true;
                        } else {
                            e.warning_border = false;
                        }
                    }

                    return e;
                });
        }


        if (!this.snackbarKey && priceDiffersFromBilledPrice) {
            this.snackbarKey = this.props.enqueueSnackbar(this.tr("Prices differ from original prices"), {
                variant: "warning",
                preventDuplicate: true,
                persist: true
            });
        } else if (this.snackbarKey && !priceDiffersFromBilledPrice) {
            this.props.closeSnackbar(this.snackbarKey);
            this.snackbarKey = null;
        }
        
        this.setState({rows: rows, allowPlainUpdate: allowPlainUpdateFromTotals, showResetButton: priceDiffersFromBilledPrice}, () => {
            this.calculateProjectTotals();
        });
    }
    
    calculateBilledPricesSimple = (rows) => {
        rows = rows || _.cloneDeep(this.state.rows);
        
        const ownHours = this.state.ownHours-0;
        const netTotal = Number(this.props.data.netTotal);
        const {
            hasWorkhours, 
            workhourTotal, 
            hoursTotal, 
            whTotalPerProject,
            otherTotal,  
            perProject,
            actualTotal,
            actualHoursTotal,
        } = this.calculateTotals(rows);
        
        const multipliers = {};
        Object.keys(perProject).forEach(e => {
            const project = perProject[e];
            const selectedTotal = ownHours ? project.selectedTotal : netTotal - otherTotal;
            
            if (project.workhourTotal)
                multipliers[e] = {price:  selectedTotal / project.workhourTotal};
            else
                multipliers[e]  = {quantity: selectedTotal / project.hoursTotal};
            
            if (project.actualTotal)
                multipliers[e].actualPrice = selectedTotal / project.actualTotal;
            else
                multipliers[e].actualQuantity = selectedTotal / project.actualHoursTotal;
        });
        

        const workhourMultiplier = hasWorkhours && (workhourTotal ? (netTotal - otherTotal) / workhourTotal : (netTotal - otherTotal) / hoursTotal);
        const rowMultiplier = hasWorkhours && (actualTotal ? (netTotal - otherTotal) / actualTotal : (netTotal - otherTotal) / actualHoursTotal);

        rows = rows.map(e => {
            e.quantity  = Number(e.quantity); 
            e.value     = Number(e.value);
            
            if (e.selected != 1 || ["2"].includes(e.row_type)) {
               e.billed_price = null;
               return e;
            }
            if (["5"].includes(e.row_type) && !Number(e.worktasks_id)) {
                e.billed_price = null;
                return e;
            }

            if (["1", "13"].includes(e.row_type)) {
                const multiplier = multipliers[e.projects_id];
                if (!ownHours) {
                    //own work share is not manually defined
                    
                    e.billed_price = workhourTotal ? e.quantity * e.value * workhourMultiplier : e.quantity * workhourMultiplier;
                }
                else if (multiplier.price !== undefined) {
                    //own work share has been manually defined and workhours have list price
                    
                    e.billed_price = e.quantity * e.value * multiplier.price;
                }
                else if (multiplier.quantity !== undefined) {
                    //own work share has been manually defined and workhours dont have list price
                    
                    e.billed_price = e.quantity * multiplier.quantity;
                }
                
                if (e.row_type === "1") {
                    e.row_price = e.quantity * e.value;
                }
                    
            }
            else if (e.selected_price !== null)
                e.billed_price = e.selected_price;

            return e;
        });
        
        this.setState({rows}, () => {
            this.calculateProjectTotals();
        });
    }

    resetRowPrices = (rows) => {
        rows = rows || _.cloneDeep(this.state.rows);

        rows = rows.map(e => {
            if (!(e.row_type == 1 || e.row_type == 13)) {
                e.selected_price = null;

                if (e.selected != 1) {
                    e.billed_price = 0;
                    return e;
                }

                e.billed_price = e.quantity * e.value

                if (e.row_type == 5 && e.discount_percent > 0) {
                    /* quote row discount */
                    const discount = CurrencyUtils.calculateDiscountAmount(e.value, e.discount_percent, 2);
                    e.billed_price = Number(CurrencyUtils.calculateNetTotalWithDiscount(e.value, e.quantity, discount, 2));
                }
                
                e.warning_border = false;
                return e;
            } else {
                return e;
            }
        });

        const {
            allowPlainUpdate
        } = this.calculateTotals(rows);

        this.setState({rows, allowPlainUpdate, showResetButton: false}, () => {
            this.calculateProjectTotals();
            this.props.closeSnackbar(this.snackbarKey);
            this.snackbarKey = null;
        });
    }

    calculateProjectTotals = () => {
        const rows = [...this.state.rows];

        const projectTotals = {};

        rows.forEach(((e) => {
            if (e.row_type === '14')
                return;
            
            if(!projectTotals[e.projects_id]) {
                projectTotals[e.projects_id] = {
                    _type: 'projectRow',
                    nr: e.project_id,
                    projects_id: e.projects_id,
                    projects_name:"Project 1",
                    subcontracting: 0,
                    travel_expense: 0,
                    material_hours: 0,
                    chosen_hours: 0,
                    own_work_list_price: 0,
                    own_work: 0,
                    total: 0,
                    id: e.projects_id,
                    hasOwnWork: false
                };
            }
            const billed_price = Number(e.billed_price);
            
            const ownHours = this.state.ownHours -0;
            
            switch(Number(e.row_type)) {
                case 13:
                case 1:
                    projectTotals[e.projects_id].hasOwnWork = projectTotals[e.projects_id].hasOwnWork || e.selected > 0;
                    projectTotals[e.projects_id].own_work_list_price += e.quantity * e.value;
                    if(ownHours) {
                        if(this.state.selectedProjectsOwnWork[e.projects_id] !== undefined) {
                            projectTotals[e.projects_id].own_work = this.state.selectedProjectsOwnWork[e.projects_id].selected_price;
                        } 
                        else {
                            projectTotals[e.projects_id].own_work = projectTotals[e.projects_id].own_work_list_price;
                        }
                    } 
                    else {
                        projectTotals[e.projects_id].own_work +=  billed_price;
                    }
                    projectTotals[e.projects_id].material_hours += Number(e.quantity);
                    if(e.selected == 1) {
                        projectTotals[e.projects_id].chosen_hours += Number(e.quantity);
                    }
                    break;
                case 4:
                case 8:
                case 9:
                case 10:
                case 11:
                    if(e.selected == 1) {
                        projectTotals[e.projects_id].travel_expense += billed_price;
                    }
                    break;
                default:
                    if(e.selected == 1) {
                        projectTotals[e.projects_id].subcontracting += billed_price;
                    }
                    break;
            }
        }));
       
        const grandTotal = {
            _type: 'projectTotalsRow',
            subcontracting: 0,
            travel_expense: 0,
            material_hours: 0,
            chosen_hours: 0,
            own_work_list_price: 0,
            own_work: 0,
            total: 0
        };
        
        for(const i in projectTotals) {
            const p = this.props.data.projects.find(iP => iP.id == i);
            if(p) {
                projectTotals[i].projects_name = p.label;
            }
            if(!projectTotals[i].hasOwnWork) {
                projectTotals[i].own_work = 0;
            }

            projectTotals[i].total = Number(projectTotals[i].subcontracting) + Number(projectTotals[i].travel_expense) + Number(projectTotals[i].own_work);

            grandTotal.subcontracting += Number(projectTotals[i].subcontracting);
            grandTotal.travel_expense += Number(projectTotals[i].travel_expense);
            grandTotal.material_hours += Number(projectTotals[i].material_hours);
            grandTotal.chosen_hours += Number(projectTotals[i].chosen_hours);
            grandTotal.own_work_list_price += Number(projectTotals[i].own_work_list_price);
            grandTotal.own_work += Number(projectTotals[i].own_work);
            grandTotal.total += Number(projectTotals[i].total);
        }
        let roundedTotal = 0;
        for(const i in projectTotals) {
            projectTotals[i].total = Number(projectTotals[i].total.toFixed(2));
            roundedTotal += projectTotals[i].total;
        }
        
        if (Math.abs(grandTotal.total - roundedTotal) > 0.01) {
            const firstKey = Object.keys(projectTotals)[0];
            projectTotals[firstKey].total = projectTotals[firstKey].total + roundedTotal - grandTotal.total;
        }
        
        this.setState({projectTotals: [], projectsGrandTotal: {}}, () => {
            //FIXME right after i figure out why total row doesnt update properly
            this.setState({projectTotals: Object.values(projectTotals), projectsGrandTotal: grandTotal});
        });
    }
    
    projectOptionRenderer = (props) => {
 
        if (!props)
            return null;

        const checkedStatus = this.state.selectedProjects.length === this.props.data.projects.length ? true : (this.state.selectedProjects.find(sp => sp.id == props.id) ? true : false);
        return (
            <MenuItem noClose onClick={(e) => this.handleCheckboxSelect(props, checkedStatus)}>
                <Checkbox checked={checkedStatus}  />
                {props.label}
            </MenuItem>
        );
    };

    tabsRenderer = (type) => {
        const { classes } = this.props
        return this.props.data.groupingOptions[type].map( o => 
            <Tab key={o.id} 
                value={Number(o.id)} 
                label={o.name} 
                classes={{root: classNames(classes.tabRoot)}}/>
        );

    };

    onInputChange = (name, date) => {
        this.setState({date: format(new Date(date), 'YYYY-MM-DD')});
    };

    onDateChange = (date) => {
        this.setState({date: format(new Date(date), 'YYYY-MM-DD')});
    };

    handleCancel = () => {
        if (this.snackbarKey) {
            this.props.closeSnackbar(this.snackbarKey);
            this.snackbarKey = null;
        }
        this.props.onDialogClose();
    };

    handleCheckboxSelect = (project, checkedStatus) => {
        const selectedIds = this.state.selectedProjects.map(e => e.id);
        
        let selectedProjects = _.cloneDeep(this.props.data.projects);
        
        if (project.id === 0)
            /* select / deselect all */
            this.setState({selectedProjects: checkedStatus ? [] : selectedProjects});
        else {
            if (checkedStatus)
                /* deselect */
                selectedProjects = selectedProjects.filter(e => selectedIds.includes(e.id) && e.id !== project.id);
            else
                selectedProjects = selectedProjects.filter(e => selectedIds.includes(e.id) || e.id === project.id);
            
            this.setState({selectedProjects});
        }
    };

    handleTypeChange = (type, item) => {
        this.setState({ [item.name]: type});
    };    

    handleGroupingChange = (value, item) => {

        const { handleMaterialGroupingChange } = this.props.data;

        this.setState(state => {
            state.grouping[item.name] = value;
            return state;
        });

        handleMaterialGroupingChange(item.name, value.id);
    };
    handleRowCheck = (id) => {
        const rows = _.cloneDeep(this.state.rows);
        const i = rows.findIndex(e => e.id === id)
        
        if (['13', '14'].indexOf(rows[i].row_type) !== -1)
            return;
        
        rows[i].selected = rows[i].selected > 0 ? 0 : 1;

        rows[i].warning_border = false;
        
        if (this.context.addons.simple_complex_invoicing && rows[i].selected && rows[i].row_type !== '1' && rows[i].selected_price === null) {
            /* if content doesnt have selected price, reset it to original value when activated */
            rows[i].billed_price = rows[i].quantity * rows[i].value;
        }
 
        this.calculateBilledPrices(rows);
    };
    handleCheckAll = (checked, ids) => {
        const rows = _.cloneDeep(this.state.rows);
        
        rows.forEach(e => {
            if (['13', '14'].indexOf(e.row_type) === -1 && ids.find(id => id == e.id)) {
                e.selected = checked ? 1 : 0;
            
                if (this.context.addons.simple_complex_invoicing && e.selected && e.row_type !== '1' && e.selected_price === null) {
                    /* if non-hour content doesnt have selected price, reset it to original value when activated. */
                    e.billed_price = e.quantity * e.value;
                }
            }
        });

        this.calculateBilledPrices(rows);
    }
    setBilledPrice = (id, val) => {
        const rows = _.cloneDeep(this.state.rows);
        const i = rows.findIndex(e => e.id === id)
        rows[i].selected_price = val;
 
        this.calculateBilledPrices(rows);
    };
    setQuantity = (id, val) => {
        const rows = _.cloneDeep(this.state.rows);
        const i = rows.findIndex(e => e.id === id)
        rows[i].quantity = val;
        
        this.calculateBilledPrices(rows);
    }

    update = (update = true) => {
        const { rows, selectedProjectsOwnWork, ownHours } = this.state;
        const material = _.cloneDeep(rows);
        
        if (this.snackbarKey) {
            this.props.closeSnackbar(this.snackbarKey);
            this.snackbarKey = null;
        }
        this.props.onDialogSave(this.props.data.saveFunc, material, update, ownHours ? _.cloneDeep(selectedProjectsOwnWork) : false, ownHours );
    };

    getSubheaders = () => {
        const { view } = this.state;
        const subheaders = [];
        switch (view) {
            case 'list':
                subheaders.push({
                    header: this.tr("List")
                });
            break;
            case 'manageOwnHoursTotals':
                subheaders.push({
                    header: this.tr("Manage own hours totals")
                });
            break;
        } 
        return subheaders;
    }
    _renderTopTabs = (totals) => {
        return (
            <PageTopSection
                selected={this.state.view}
                tabs={this.tabs.length > 1 ? this.tabs : false}
                header={this.tr("Invoicing material")}
                subheaders={this.getSubheaders()}
                extraComponents={[<img className="loading-indicator" src={require('../dashboard/insights/img/loading.svg').default} />]}
                summaries={[
                    {
                        title: this.tr("Total quantity"),
                        value: totals.quantityTotal
                    },
                    {
                        title: this.tr("Total 0%"),
                        value: totals.netTotal
                    },
                    {
                        title: this.tr("Total update rows"),
                        value: totals.updateRowsTotal
                    },
                    {
                        title: this.tr("Total selling price"),
                        value: totals.sellingTotal
                    }
                ]}
            />
        );
    };

    _renderHeader = (totals) => {
        return (
            <div class="header listControlsContainer">
                {this._renderTopTabs(totals)}
            </div>
        );
    };
    _renderProjectChip = (p) => {
        const classes = this.props.classes;
        
        return (
            <Tooltip title={this.props.data.chipTitle(p)}>
                <Chip 
                    icon={!(p.customer_address_id-0) && <ReportProblemRounded className="warning" />}
                    classes={{root: classNames(classes.chip), label: classNames(classes.chipLabel), deleteIcon: (!p.notSaved ? classNames(classes.hidden) : null)}} 
                    name="selected projects" 
                    value={p.id} 
                    label={p.label} 
                    key={p.id} 
                    onDelete={(evt) => p.notSaved ? this.props.data.projectRemoved(p.id) : null} />
            </Tooltip>    
        );
    }
    
    selectTab = (e, view) => {
        if (!e.ctrlKey && !e.metaKey)
            e.preventDefault();
        else 
            return;

        this.context.functions.updateView({view});
        this.setState({ view: view });
    }

    editProjectRowTotal = (projectsId, newTotal, oldTotal) => {
        const selectedProjectsOwnWork = {...this.state.selectedProjectsOwnWork};
        selectedProjectsOwnWork[projectsId] = {selected_price: newTotal, old_price: oldTotal};
        
        this.setState({selectedProjectsOwnWork: selectedProjectsOwnWork}, () => {
            this.calculateBilledPrices();
        });
    }
    setBillable = (id, value) => {
        const rows = _.cloneDeep(this.state.rows);
        const i = rows.find(e => e.id === id);
        i.row_type = '14';
        i.billable = '-1'
        i.selected = 0;
        console.log(i);
        this.calculateBilledPrices(rows);
    }

    changeManuallyDefinedOwnWork = () => {
//        let rows = _.cloneDeep(this.state.rows);
//        rows = rows.map(el => {
//            el.selected_price = null;
//            return el;
//        });
        const projectTotals = _.cloneDeep(this.state.projectTotals);
//        projectTotals = projectTotals.map(el => {
//            el.own_work = el.own_work_list_price;
//            return el;
//        });
        const selectedProjectsOwnWork = {}
        if(!this.state.ownHours) {
            projectTotals.forEach(el => {
                selectedProjectsOwnWork[el.projects_id] = {
                    old_price: el.own_work_list_price,
                    selected_price: el.own_work
                };
            });
        }
//        this.setState({rows: rows, projectTotals: projectTotals, selectedProjectsOwnWork: selectedProjectsOwnWork, ownHours: !this.state.ownHours}, () => {
//            this.calculateBilledPrices();
//        });
        
        this.setState({selectedProjectsOwnWork, ownHours: !this.state.ownHours}, () => {
            this.calculateBilledPrices();
        });
        
    }
    
    getMaterialSelections = () => {
        const selections = {};
        
        const rows = this.state.rows;
        if (rows && rows.length) {
            rows.forEach(e => selections[ e.id ] = e.selected)
        }
        
        return selections;
    }

    render() {

        const { taimerAccount, userObject } = this.context;

        const { classes, columnOrder, data: {id, projects, settings, groupingOptions, canEditContent, invoiceDataTypes, selectedAccount, invoiceableProjects, startDate, endDate, has_multi_address_relation }} = this.props;

        const { selectedProjects, row_type, grouping, allChecked, allowPlainUpdate, ownHours, showExtras } = this.state;
        
        const rows = _.cloneDeep(this.state.rows);

        //if all projects are selected, display "all projects"
        const projectDisplayValue = selectedProjects.length === projects.length ? {id:0, label: this.tr("All projects")} : {id: -1, label: selectedProjects.map(sp => sp.label).join(', ')};

        const dateFormat = this.props.dateFormat ? this.props.dateFormat : userObject.dateFormat;

        const formatSum = (value) => {
            return Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: taimerAccount.currency}).format(value);
        };
        const formatNum = (value) => {
            return Intl.NumberFormat(taimerAccount.numberFormat).format(value);
        };
        
        let availableTypes = {"0": 'all'};
        rows.forEach(e => availableTypes[ e.row_type ] = true);
        availableTypes = Object.keys(availableTypes);
        
        //if availableTypes contain at least two travel invoice types, add the main type to the availableTypes
        if (_.intersection(availableTypes , ["8", "9", "10"]).length > 1)
            availableTypes.push("100");

        const currentTypes = invoiceDataTypes.filter(ct => availableTypes.indexOf(ct.id) !== -1);

        const rowProps = {
            onCheck: this.handleRowCheck,
            allChecked: this.state.allChecked,
            enqueueSnackbar: () => this.props.enqueueSnackbar,
            invoiceDataTypes: this.props.data.invoiceDataTypes,
            selectedProjects: this.props.data.selectedProjects,
            setBilledPrice: this.setBilledPrice,
            setQuantity: this.setQuantity,
            ownHours: this.context.addons.simple_complex_invoicing ? false : this.state.ownHours,
            billableOptions: this.billableOptions,
            menuPortalTarget: this.container,
            setBillable: this.setBillable,
            has_multi_address_relation
        };

        let displayedRows;
        if (!rows && !rows.length)
            displayedRows = [];
        else if (row_type.id == 0 && projectDisplayValue.id === 0)
            //all material types and all projects are selected
            displayedRows = rows;
        else
            displayedRows = rows.filter(r => {
                if (projectDisplayValue.id !== 0 && !selectedProjects.some(sel => sel.id == r.projects_id))
                    //material doesn't match selected project
                    return false;
                
                if (row_type.id == 0)
                    //all selected
                    return true;
                if (r.row_type === row_type.id)
                    //row type matches selected
                    return true;
                if (row_type.id == 100 && ["8", "9", "10"].includes(r.row_type))
                    //all travel invoice types selected
                    return true;
            });
        

        displayedRows = displayedRows.filter(r => r.selected > 0 || (r.creation_date >= startDate && r.creation_date <= endDate))

        if (row_type.id !== '14')
            displayedRows = displayedRows.filter(r => r.row_type !== '14');
        
        let quantityTotal = 0, netTotal = 0, sellingTotal = 0, updateRowsTotal = 0;
        displayedRows.forEach(e => {
            if (this.context.addons.simple_complex_invoicing && ["2", "5"].includes(e.row_type))
                return;
            
            const quantity = Number(e.original_quantity || e.quantity);
            quantityTotal += quantity;
            netTotal += quantity * e.value;
            sellingTotal += Number(e.billed_price);
            if(e.selected > 0) {
                if (this.context.addons.simple_complex_invoicing) {
                    if (e.row_type !== "13")
                        updateRowsTotal += e.row_type == 1 ? e.row_price : Number(e.billed_price);
                }
                else
                    updateRowsTotal += e.row_type == 1 ? e.quantity * e.value : Number(e.billed_price);
            }
        });
        
        const project_ids = projects.map(e => e.id);

        const dialogClasses = ["invoice-material-dialog"];
        
        this.props.invoiceDataLoading && dialogClasses.push("data-loading");
        
        const firstProject = projects[0];
        return (
            <Dialog
                id="invoice-material-dialog"
                open
                maxWidth={false}
                fullWidth
                PaperProps={{classes:{root: classNames(classes.dialogRoot)}}}
                className={dialogClasses.join(" ")}
                aria-labelledby="invoiceMaterialDialog"
                TransitionProps={{
                    onEntering: this.handleEnter
                }}>
                <div className={classNames(classes.topBar)}/>
                <DialogTitle className="invoicematerial-dialog-title">

                {this._renderHeader({
                    quantityTotal: quantityTotal,
                    netTotal: formatSum(netTotal),
                    sellingTotal: formatSum(sellingTotal),
                    updateRowsTotal: formatSum(updateRowsTotal),
                })}
                   
                    <div className={classNames(classes.controlsContainer)}>
                        <div className="fields-container" >
                            <DateRangePicker
                                ranges={[{startDate: startDate, endDate: endDate}]}
                                onChange={this.props.data.handleRangeSelect}
                                onInputChange={this.props.data.handleRangeInputChange}
                                label={this.tr("Invoicing material period")}
                                dateFormat={userObject.dateFormat}
                                disabled={has_multi_address_relation}
                            />
                            <ContextMenu description={this.tr("Projects")} label={projectDisplayValue.label} size="medium" dropdownMenu>
                                {[{id:0, label: this.tr("All projects")}, ...this.props.data.projects].map(this.projectOptionRenderer)}
                            </ContextMenu>
                            
                            <DataList 
                                label={this.tr("Current types")} 
                                name="row_type"
                                inputBaseProps={{classes: {root: classNames(classes.datalistInputRoot)}}}
                                options={currentTypes}
                                value={row_type}
                                isDisabled={has_multi_address_relation}
                                onChange={this.handleTypeChange} />
                            
                            <div className="project-selection">
                                <DataList 
                                    label={this.tr("Add project")} 
                                    name="project_ids"
                                    inputBaseProps={{classes: {root: classNames(classes.datalistInputRoot)}}}
                                    options={invoiceableProjects.filter(e => e.customers_id === selectedAccount.id && project_ids.indexOf(e.id) === -1 )}
                                    onChange={this.props.data.projectAdded}
                                    customOption={ProjectOption}
                                    isDisabled={has_multi_address_relation}
                                    disabledSubtext={"(" + this.tr("Different billing address") + ")"}
                                    /* every selected project has to have same address selected */
                                    isOptionDisabled={projects.length && (option => option.customer_address_id !== projects[0].customer_address_id)} 
                                    shownCount={20}/>
                                <div className={classNames(classes.chips) + " project-chips"}>         
                                    { firstProject && this._renderProjectChip(firstProject) }
                                    { projects.length > 1 && (
                                        <div onClick={() => this.setState({showExtras: true})} className="extra-count">+ {projects.length - 1} {this.tr('more')}</div>
                                    )}
                                    { showExtras && <ClickAwayListener onClickAway={() => this.setState({showExtras: false})}>
                                        <div className="extra-projects">
                                            {projects.map((project, i) => (
                                                i > 0 && this._renderProjectChip(project)
                                            ))}
                                        </div>
                                    </ClickAwayListener>} 
                                </div>
                            </div>
                            
                            { this.state.view === "manageOwnHoursTotals" &&  ( 
                            <div className="own-hours-option">
                  
                                <span className="own-hours">{this.tr('Manually define own hours share')}</span>
                                <Switch
                                    name="own_hours"
                                    color="primary"
                                    disableRipple={true}
                                    onChange={this.changeManuallyDefinedOwnWork}
                                    checked={this.state.ownHours-0}
                                />
                            </div> )}
                            
                        </div>
                    </div>                    
                </DialogTitle> 
                {this.state.view === "list" &&  ( 
                <DialogContent className="invoicematerial-dialog-content">
                    <div ref={this.container}>
                        <List
                            ref={this.list}
                            fluid={this.state.fluid.list}
                            // height="fitRemaining"
                            minHeight={32}
                            height={(listHtmlElements, setHeight, list) => {
                                // The third parameter passed to this function is List. Use it in case you need to "easily"
                                // manipulate it from here.
                            
                                // This needs to be shifted to the "end" of the event loop,
                                // so that this.dialogActions has been rendered once and we can measure its offsetTop.
                                setTimeout(() => {
                                    const height = this.dialogActions.current.offsetTop - listHtmlElements.outerMainWrapper.offsetTop;

                                    setHeight(Math.abs(height)); 
                                });
                            }}
                            bottomElement={this.dialogActions}
                            rowHeight={26 + 6}
                            data={displayedRows.map(row => {
                                row.original_quantity_invoiced = row.quantity_invoiced;
                                return row;
                            })}
                            noStateData={true}
                            ignoreRowPropsChange={false}
                            idType="string"
                            columns={this.fields}
                            className="invoice-material-list"
                            listRowType={InvoiceMaterialRow}
                            onDataChange={({ data, newData, origin }) => {
                                if(origin === "initial") {
                                    this.list.current.check(displayedRows.filter(r => r.selected == "1").map(r => r.id));
                                }
                            }}
                            rowProps={rowProps}
                            useGlobalAllChecked={false}
                            disableCheck={has_multi_address_relation}
                            onCheckAll={ids => this.handleCheckAll(true, ids)}
                            onUncheckAll={ids => this.handleCheckAll(false, ids)}/>
                    </div>
                </DialogContent>)}

            {/* Project Total  starts */}
            { this.state.view === "manageOwnHoursTotals" && (  
                <div className="invoicematerial-dialog-content">
                    <div ref={this.container}>
                        <List
                            ref={this.list}
                            fluid={this.state.fluid.hours}
                            columnHeaderConfigKey="projectRow"
                            height="fitRemaining"
                            trimHeight={-209}
                            rowHeight={26 + 6}
                            noStateData={true}
                            ignoreRowPropsChange={false}
                            columns={{
                                projectRow: this.projectTotalFields,
                                projectTotalsRow: this.projectTotalFields,
                            }}
                            className="project-total-list"
                            listRowTypeKey="_type"
                            listRowTypeMap={{
                                projectRow: ProjectRow,
                                projectTotalsRow: ProjectTotalsRow,
                            }}
                            data={[...this.state.projectTotals,this.state.projectsGrandTotal]}
                            rowProps={{
                                ownHours: this.state.ownHours,
                                editProjectRowTotal: this.editProjectRowTotal
                            }}
                        />
                    </div>
                </div>)
            }
            {/* Project Total ends */}

                <div ref={this.dialogActions}>
                    <DialogActions  className="invoicematerial-dialog-footer">
                        <FlexContainer style={{ justifyContent: "space-between" }}>
                            <FlexChild weight={5}>
                                <FlexContainer style={{ flexWrap: "wrap" }}>
                                    <FlexChild weight={1}>
                                        <FlexContainer>
                                            <DataList 
                                                label={this.tr("Project & Task grouping")} 
                                                name="projects"
                                                className="footer-datalist"
                                                classes={{paper: classNames(classes.footerDatalistMenu)}}
                                                inputBaseProps={{classes: {root: classNames(classes.optionsDatalistInputRoot)}}}
                                                options={this.projectsGrouping}
                                                customOption={TooltipOption}
                                                value={grouping.projects}
                                                menuPlacement="top"
                                                //isDisabled={this.context.addons.emce_invoicing ? true : false}
                                                onChange={this.handleGroupingChange} />
                                            <DataList 
                                                label={this.tr("Workhours grouping")} 
                                                name="workhours"
                                                className="footer-datalist"
                                                classes={{paper: classNames(classes.footerDatalistMenu)}}
                                                inputBaseProps={{classes: {root: classNames(classes.optionsDatalistInputRoot)}}}
                                                options={this.workhoursGrouping}
                                                customOption={TooltipOption}
                                                value={grouping.workhours}
                                                menuPlacement="top"
                                                //isDisabled={this.context.addons.emce_invoicing ? true : false}
                                                onChange={this.handleGroupingChange} />
                                        </FlexContainer>
                                    </FlexChild>
                                    <FlexChild weight={1}>
                                        <FlexContainer>
                                            <DataList 
                                                label={this.tr("Worktrips grouping")}
                                                name="worktrips"
                                                className="footer-datalist"
                                                classes={{paper: classNames(classes.footerDatalistMenu)}}
                                                inputBaseProps={{classes: {root: classNames(classes.optionsDatalistInputRoot)}}}
                                                options={this.worktripsGrouping}
                                                value={grouping.worktrips}
                                                menuPlacement="top"
                                                onChange={this.handleGroupingChange} />
                                            <DataList 
                                                label={this.tr("Costestimate grouping")} 
                                                name="costest"
                                                className="footer-datalist"
                                                classes={{paper: classNames(classes.footerDatalistMenu)}}
                                                inputBaseProps={{classes: {root: classNames(classes.optionsDatalistInputRoot)}}}
                                                options={this.costestGrouping}
                                                value={grouping.costest}
                                                menuPlacement="top"
                                                onChange={this.handleGroupingChange} />
                                        </FlexContainer>
                                    </FlexChild>
                                </FlexContainer>
                            </FlexChild>
                            <FlexChild weight={3}>
                                <FlexContainer>
                                    <Button className={classNames(classes.button)} color="info" variant="outlined" onClick={this.handleCancel} >
                                        {this.tr('CANCEL')}
                                    </Button>
                                    {this.state.showResetButton && <Button className={classNames(classes.button) + ' reset_row_prices_button'} variant="contained" onClick={() => this.resetRowPrices(this.state.rows)}>
                                        {this.tr('RESET ROW PRICES')}
                                    </Button>}
                                    <Button className={classNames(classes.button)} variant="contained" disabled={!allowPlainUpdate} onClick={() => this.update(false)} color="primary">
                                        {this.tr('UPDATE')}
                                    </Button>
                                    {canEditContent && <Button className={classNames(classes.button)} variant="contained" onClick={() => this.update(true)} color="primary">
                                        {this.tr('UPDATE AND GENERATE ROWS')}
                                    </Button>}
                                </FlexContainer>
                            </FlexChild>
                        </FlexContainer>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}
InvoiceMaterial.defaultProps = {
    onDialogClose: () => {},
    onDialogSave: () => {},
    enqueueSnackbar: PropTypes.func.isRequired
}

export default withSnackbar(withStyles(styles)(InvoiceMaterial));
    
