import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import colors from "../colors";
import "./CircularProgressBar.css";

const getStrokeColorFromProgress = (progress) => {
    if (progress == 0) return colors.cloudy_blue;
  return progress < 100 ? colors.carnation_pink : "#42b677";
};

const CircularProgressBar = (props) => {
  const {
    size,
    progress,
    strokeWidth = 8,
    backgroundStrokeColor = "#d5e9fa",
    strokeColor = getStrokeColorFromProgress(progress),
    labelColor = getStrokeColorFromProgress(progress),
    showLabel = true
  } = props;

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  const [offset, setOffset] = useState(circumference);

  useEffect(() => {
    const progressOffset = ((100 - Math.max(-100, Math.min(100, progress))) / 100) * circumference;
    setOffset(progressOffset);
  }, [setOffset, circumference, progress, offset]);

  return (
    <div className="circular-progress-bar">
      <svg className="svg" width={size} height={size}>
        <circle
          className="svg-circle-bg"
          stroke={backgroundStrokeColor}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="svg-circle"
          stroke={strokeColor}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
      {showLabel && <p style={{ color: labelColor }}>{Math.round(progress)}%</p>}
    </div>
  );
};

CircularProgressBar.propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number,
  backgroundStrokeColor: PropTypes.string,
  strokeColor: PropTypes.string,
  labelColor: PropTypes.string,
  showLabel: PropTypes.bool,
};

export default CircularProgressBar;
