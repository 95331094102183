import React, { Component } from 'react';
import InvoiceRow from './InvoiceRow';
import InvoiceRowProduct from './InvoiceRowProduct';
import InvoiceRowCPQ from './InvoiceRowCPQ';
import InvoiceRowMileage from './InvoiceRowMileage';
import InvoiceRowMileageAdditional from './InvoiceRowMileageAdditional';
import InvoiceRowDaily from './InvoiceRowDaily';
import InvoiceRowOther from './InvoiceRowOther';
import cn from 'classnames';
import './InvoiceRows.css';

export default class InvoiceRowsEdit extends Component {
	render() {
        const { item, rows, headers, disabled, company } = this.props;
        let currentCategory = undefined;
        
        if (!rows || (rows && rows.length === 0))
            return null;

        const mileage = this.props.mileage.filter(x => x.companies_id == company);
        const mileageAdditional = this.props.mileageAdditional.filter(x => x.companies_id == company);
        const daily = this.props.daily.filter(x => x.companies_id == company);    

		return (<div className={cn("TMRInvoiceRows", this.props.className)}>
            {this.props.rows.map((v) => {
                
                if (v.deleted > 0)
                    return false;
                
                let Type = null;
                let header = false;

                if (v.category && v.category !== currentCategory)
                {
                    header = headers[v.category];
                    currentCategory = v.category;
                }

                switch (v.type)
                {
                    case "product":
                        Type = InvoiceRowProduct;
                        break;
                    case "cpq":
                        Type = InvoiceRowCPQ;
                        break;                        
                    case "mileage":
                        Type = InvoiceRowMileage;
                        break;                
                    case "daily":
                        Type = InvoiceRowDaily;
                        break;
                    case "other":
                        Type = InvoiceRowOther;
                        break;                 
                    default: 
                        Type = InvoiceRow;
                        break;
                }

                return (<React.Fragment key={v.invoiceRowIndex}>
                    {header && <div className="subsection full">
                        <label>{header}</label>
                        <div className="gray_border"></div>
                    </div>}
                    <Type
                        invoice={item}
                        item={v}
                        company={company}
                        onDelete={this.props.onDelete} 
                        onChange={this.props.onChange} 
                        mileage={mileage}
                        types={this.props.types}
                        mileageAdditional={mileageAdditional}
                        daily={daily}
                        showValidationResult={this.props.showValidationResult}
                        disabled={disabled} />
                    </React.Fragment>)}
            )}     
		</div>)
	}
}

export function CreateInvoiceRow(invoiceRowIndex, type, category, extraData = {}) {
    const row = {
        invoiceRowIndex,
        type: type,
        name: extraData.name || '',
        amount: extraData.amount || '',
        unit_price: 0,
        total: '',
        category: category,
        valid: false,
        child: [],
        className:""
    };

    if (type === "mileage") {
        row.subtype = extraData.mileage || {};
        row.unit_price = row.subtype.rate;
    }
    else if (type === "mileage_additional") {
        row.subtype = extraData.mileageAdditional || {};
        row.unit_price = row.subtype.rate;
    }
    else if (type === "daily") {
        row.subtype = extraData.daily || {};
        row.unit_price = row.subtype.rate;
        row.part_rate = row.subtype.part_rate;
    }
    else if (type === "other") {
        row.subtype = extraData.types || {};
        row.vat = extraData.types.vat === undefined ? 24 : extraData.types.vat;
        row.total_no_vat = 0;
        row.className = "InvoiceRowOther"
    }
    else if (type === "product")
    {
        row.product = extraData.product || {
            id: 0,
            name: '',
        }
        row.amount = 1;
    }
    else if (type === "cpq") {
        row.cpq = extraData.cpq || {
            id: 0,
            name: '',
        }
        row.amount = 1;
    }    

    return UpdateInvoiceRow(row);
}

export function UpdateInvoiceRow(data, field) {
    const row = {...data};
    const comma = /[,]/.test(row.unit_price);
    
    if(comma) {
        row.unit_price = row.unit_price.replace(/,/g, '.')
    }
    
    switch (row.type) {
        default:
            if (row.unit_price && row.amount)
                row.total = (row.unit_price * row.amount).toFixed(2);
            else
                row.total = "0"

            break;

        case "mileage":

            if (row.unit_price && row.amount)
                row.total = (row.unit_price * row.amount).toFixed(2);
            else
                row.total = "0"

            break;

        case "daily":
            var fullDays = Math.floor(row.amount);
            var partial = (row.amount - fullDays) ? 1 : 0;

            if (row.unit_price && row.amount)
                row.total = (fullDays * row.unit_price + partial * row.part_rate).toFixed(2);
            else
                row.total = "0"
            break;

        case "other":
            if (field == "total_no_vat")
                row.total = ((row.total_no_vat) * (1 + (row.vat / 100))).toFixed(2);
            else
                row.total_no_vat = ((row.total) / (1 + (row.vat / 100))).toFixed(2);
            break;
    }

    return row;
}