
import cn from 'classnames';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import React from 'react';
import { getWorktypesForProject } from '../../Data';
import FocusGroup from "../../general/FocusGroup";
import { formatInputNumber } from '../../helpers';
import TaimerComponent from "../../TaimerComponent";
import AutoCompleteCell from '../cells/AutoCompleteCell';
import CheckboxCell from "../cells/CheckboxCell";
import DateCell from "../cells/DateCell";
import TextInputCell from "../cells/TextInputCell";
import LinkListCell from "../LinkListCell";
import ListCell from "../ListCell";
import { SettingsContext } from './../../SettingsContext';
import PropsOnlyListRow from "./../PropsOnlyListRow";

class TotalRow extends PropsOnlyListRow {

	static contextType = SettingsContext;

	constructor(props) {
		super(props, { checked: false}, undefined, "list/rows/ApprovalsSummaryListRow");
	}

	render() {
		const className	= "ApprovalsSummaryListRow row totalRow";
		const cells = this.props.cells;
		if (this.props.data['type'] == "3") {
			cells.tracked_h =
				<TextInputCell
					listCellProps={{
						inEditMode: false
					}}
					width={this.props.columnWidthMap['tracked_h']}
					name="tracked_h"
					value={this.props.data['tracked_h']}
					editable={false}
				/>

			cells.expected_h =
				<TextInputCell
					listCellProps={{
						inEditMode: false
					}}
					width={this.props.columnWidthMap['expected_h']}
					name="expected_h"
					value={this.props.data['expected_h']}
					editable={false}
				/>
		}
        return (
            <div style={{ height: "44px", lineHeight: "44px" }} id={this.props.data.id} className={cn(className)}>
                {this.props.columnOrder.map(columnName => {
                    const cell = cells[columnName];

                    return columnName === "expand" || columnName === "checked" ? cell : React.cloneElement(cell, {
                        listCellProps: { ...(cell.props.hasOwnProperty("listCellProps") ? cell.props.listCellProps : {}), showErrorBorder: this.props.invalids && this.props.invalids.indexOf(columnName) > -1 }
                    });
                })}
            </div>
        );
	}
}

class WorkhourRow extends PropsOnlyListRow {

	static contextType = SettingsContext;

	constructor(props) {
		super(props, { checked: false}, undefined, "list/rows/ApprovalsSummaryListRow");
	}

	shouldComponentUpdate(nextProps, nextState) {
		return super.shouldComponentUpdate(nextProps, nextState) || this.props.data !== nextProps.data
    }

	updateWorkTypes = async () => {
		const { data } = this.props;
		let worktypes = [];
		const projectId = data.projects_id;
		let workTypeFound = true;

		try {
			worktypes = await getWorktypesForProject(projectId);
		} catch (e) {
			console.log(e)
		}

		if (!worktypes.find(w => w.id == data.worktasks_id)) {
			workTypeFound = false;
			worktypes.unshift({id: data.worktasks_id, name: data.worktask_name, label: data.worktask_name });
		}

		this.setData({worktypes});
		return workTypeFound;
	}

	showFieldError(name, value, message) {
		this.props.rowProps.enqueueSnackbar(message, {
			variant: "error",
		});
		const data = {};
		data["_invalidField_" + name] = true;
		this[name] = value;
		this.setData(data);
	}

	descriptionEdited = (name, dataName, value) => {
		const { overtimeSettings, enqueueSnackbar } = this.props.rowProps;
		const { functions: { getTimeTrackerSettings } } = this.context;
		const timeTrackerSettings = getTimeTrackerSettings();
		
		if (name === "description" &&  timeTrackerSettings.hour_entry_description && (!value || value.toString().trim() === "")) {
			this.showFieldError(name, value, this.tr("Description cannot be empty."));
			return;
		}
		else if (name === "overtime_description" &&  
				overtimeSettings.activate_overtime_tracking == "1" && 
				overtimeSettings.overtime_description_mandatory == "1" &&
				(!value || value.toString().trim() === "")) {

				this.showFieldError(name, value, this.tr("overtime description is mandatory."));
			return;
		}

		const data = {};
		data["_invalidField_" + name] = false;
		data[dataName] = value;
		this[name] = value;
		this.setData(data);
		this.props.rowProps.onEdited(this.props.data.id, name, value);
	}

	render() {
		const className	= "ApprovalsSummaryListRow row";
		const { data, cells, columnWidthMap } = this.props;
		const { sharedData: { projects_resources } } = this.props;

		const userId = Number(data.users_id);

		const resources = projects_resources.filter(el => 
			el.id == 0 ||
			el.id == data.wh_projects_resource_id || 
			(
				(el.user_ids?.includes(userId) || el.allow_all) && 
				(el.projects_id == data.projects_id && el.done != 1)
			)
		);

		cells.tracked_h =
			<AutoCompleteCell
				name="tracked_h"
				autoCompleteData={data.worktypes || []}
				value={data.worktypes ? data.worktypes.find(w => w.id == data.worktasks_id) : data.worktask_name}
				width={columnWidthMap['tracked_h']}
				editable={data.editable} 
				selectProps={{
					onFocus: () => {
						this.updateWorkTypes();
					}
				}}
				onEdited={value => {
					this.setData({worktasks_id: value.id, worktask_name: value.name});
					this.props.rowProps.onEdited(data.id, "jobtype", value.id);
				}}
			/>

		cells.expected_h =
			<AutoCompleteCell
				name="expected_h"
				width={columnWidthMap['expected_h']}
				value={data.wh_projects_resource_id == 0 ? undefined : resources.find(r => r.id == data.wh_projects_resource_id)}
				editable={data.editable}
				autoCompleteData={resources}
				searchable={false}
				onEdited={value => {
					this.setData({wh_projects_resource_id: value.id});
					this.props.rowProps.onEdited(this.props.data.id, "task", value.id);
				}}
			/>

		cells.column2 =
			<TextInputCell
				name="column2"
				width={columnWidthMap['column2']}
				value={this.props.data['_invalidField_description'] ? this.description : data.column2}
				onEdited={(name, value) => {
					this.descriptionEdited("description", 'column2', value)
				}}
				listCellProps={{
					className: this.props.data['_invalidField_description'] ? "error" : "",
					showErrorBorder: this.props.data['_invalidField_description'],
				}}
				editable={data.editable} 
			/>

		cells.balance_change =
			<TextInputCell
				name="balance_change"
				width={columnWidthMap['balance_change']}
				value={this.props.data['_invalidField_overtime_description'] ? this.overtime_description : data.overtime_description}
				onEdited={(name, value) => {
					this.descriptionEdited("overtime_description", 'overtime_description', value)
				}}				
				listCellProps={{
					className: this.props.data['_invalidField_overtime_description'] ? "error" : "",
					showErrorBorder: this.props.data['_invalidField_overtime_description'],
				}}
				editable={data.editable && data.is_overtime == "1"} 
			/>


        return (
            <div style={{ height: "44px", lineHeight: "44px" }} id={this.props.data.id} className={cn(className)}>
                {this.props.columnOrder.map(columnName => {
                    const cell = cells[columnName];

                    return columnName === "expand" || columnName === "checked" ? cell : React.cloneElement(cell, {
                        listCellProps: { ...(cell.props.hasOwnProperty("listCellProps") ? cell.props.listCellProps : {}), showErrorBorder: this.props.invalids && this.props.invalids.indexOf(columnName) > -1 }
                    });
                })}
            </div>
        );
	}
}

class ChildRow extends PropsOnlyListRow {

    static contextType = SettingsContext;

	constructor(props) {
		super(props, { checked: false}, undefined, "list/rows/ApprovalsSummaryListRow");
		this.focusGroup = React.createRef();
	}
	
	shouldComponentUpdate(nextProps) {
		if(nextProps.hasOwnProperty("data") && nextProps['data']['id'] !== this.props.data['id']) {
			this.setData({ data: nextProps.data });
			return false;
		}
		return true;
	}

	render() {
		const className = ["ApprovalsSummaryListRow row", this.props.hidden ? "hidden" : "", this.props.data['id'] < 0 ? "new" : ""].join(" ");
		const cells = this.props.cells;

		cells.column1 =
			<TextInputCell
				width={this.props.columnWidthMap['column1']}
				value={this.props.data.column1}
				listCellProps={{
					inEditMode: false,
					showTooltipForOverflownText: true,
					className: `open-children column1`,
				}}
				openChildren={() => {
					this.setState({showChildren: !this.state.showChildren});				
				}}
				isChildrenOpen={!this.state.showChildren}
				editable={false}
			/>

		const children = this.props.children;
		const childRows = this.createChildren(children, ApprovalsSummaryListRow);

		const childHeaderMap = {
			column1: this.tr("Date"),
			column2: this.tr("Descripition"),
			hours_for_approval: this.props.rowProps.tr("Hours for approval"),
			hours_not_submitted: this.props.rowProps.tr("Hours not submitted"),
			tracked_h: this.tr("Jobtype"),
			expected_h: this.tr("Task"),
			internal_h: this.tr("Type"),
			customer_h: this.tr("Start time"),
			absence_vacation_h: this.tr("End time"),
			balance_change: this.tr("Overtime description"),
			overtime_change: this.tr("Status date")
		}

		return (
			<div className="listElement mainLevel">
				<div style={{height: "44px", lineHeight: "44px"}} className={className + " child"} >
					<FocusGroup 
						ref={this.focusGroup} 
						columnOrder={this.props.columnOrder}>
						{this.props.columnOrder.map(columnName => {
							const cell = cells[columnName];
							
							return columnName === "expand" || columnName === "checked" ? cell : React.cloneElement(cell, {
								listCellProps: { ...(cell.props.hasOwnProperty("listCellProps") ? cell.props.listCellProps : {}), showErrorBorder: this.props.invalids && this.props.invalids.indexOf(columnName) > -1 }
							});
						})}
					</FocusGroup>
				</div>
				{ this.state.showChildren ?
                    <React.Fragment>
                    <div className={className + "workhourHeaderDiv"} style={{lineHeight: "22px", display: "flex", height: "30px"}}>
						{this.props.columnOrder.map(columnName => {
							return (
								<div style={{width: this.props.columnWidthMap[columnName], flex: this.props.columnWidthMap[columnName] + ' 1 0'}}>
									<span className={columnName + " workhourHeader"}>{childHeaderMap[columnName]}</span>
								</div>
							)
						})}
                    </div>
                    {childRows}
					<div className={"workhourfooterdiv"}></div>
                    </React.Fragment> 
				: undefined}
			</div>
		);
	}
}

class ParentRow extends PropsOnlyListRow {

    static contextType = SettingsContext;

	constructor(props) {
		super(props, { checked: false}, undefined, "list/rows/ApprovalsSummaryListRow");
		this.focusGroup = React.createRef();
	}
	
	shouldComponentUpdate(nextProps) {
		if(nextProps.hasOwnProperty("data") && nextProps['data']['id'] !== this.props.data['id']) {
			this.setData({ data: nextProps.data });
			return false;
		}
		return true;
	}

	render() {
		const className = ["ApprovalsSummaryListRow row", this.props.hidden ? "hidden" : "", this.props.data['id'] < 0 ? "new" : ""].join(" ");
		const cells = this.props.cells;

		cells.openChildren =
			<TextInputCell
				width={this.props.columnWidthMap['openChildren']}
				value={""}
				listCellProps={{
					inEditMode: false,
					className: `open-children`,
				}}
				openChildren={() => {
					this.setState({showChildren: !this.state.showChildren});				
				}}
				isChildrenOpen={!this.state.showChildren}
				editable={false}
			/>

		const children = this.props.children;
		const childRows = this.createChildren(children, ApprovalsSummaryListRow);
		const column1Header = this.props.rowProps.mode == "summaryByUsers" ? this.props.rowProps.tr("account") : this.props.rowProps.tr("user"); 
		const column2Header = this.props.rowProps.mode == "summaryByUsers" ? this.props.rowProps.tr("Project") : this.props.rowProps.tr("team"); 

		return (
			<div className="listElement mainLevel">
				<div style={{height: "44px", lineHeight: "44px"}} className={className + " parent"} >
					<FocusGroup 
						ref={this.focusGroup} 
						columnOrder={this.props.columnOrder}>
						{this.props.columnOrder.map(columnName => {
							const cell = cells[columnName];
							
							return columnName === "expand" || columnName === "checked" ? cell : React.cloneElement(cell, {
								listCellProps: { ...(cell.props.hasOwnProperty("listCellProps") ? cell.props.listCellProps : {}), showErrorBorder: this.props.invalids && this.props.invalids.indexOf(columnName) > -1 }
							});
						})}
					</FocusGroup>
				</div>
				{ this.state.showChildren ?
                    <React.Fragment>
                    <div className={className + " childsheaderdiv"} style={{lineHeight: "22px", display: "flex", height: "30px"}}>
						{this.props.columnOrder.map(columnName => {
							return (
								<div style={{width: this.props.columnWidthMap[columnName], flex: this.props.columnWidthMap[columnName] + ' 1 0'}}>
									{(columnName == "column1" || columnName == "column2") ? (
										<span className={columnName + " childsheader"}>{columnName == "column1" ? column1Header : column2Header}</span>
									) : (
										<span className={columnName + " childsheader"}>{this.props.rowProps.tr(this.props.columnMap[columnName].header)}</span>
									)}
								</div>
							)
						})}
                    </div>
                    {childRows}
					{/* <div className={className + "childsfooterdiv"}></div> */}
                    </React.Fragment> 
				: undefined}
			</div>
		);
	}
}

//Main class for all rows that decide wich row will be used
class ApprovalsSummaryListRow extends TaimerComponent {

    static contextType = SettingsContext;
    
    constructor(props, context) {
		super(props, context, "list/rows/ApprovalsSummaryListRow");
	}

	checkRows = () => {
		switch (this.props.data.type) {
			case "0":
				this.props.rowProps.checkChildRows(this.props.data.id, "0");
				break;
			case "1":
				this.props.rowProps.checkChildRows(this.props.data.id);
				break;
			case "2":
				this.props.rowProps.checkAllRows();
				break;
			case "3":
				this.props.listRef.check(this.props.data.id);
				break;
		}
	}

	handleClick = (props) => {
		if (this.props.data.type == '0' && this.props.rowProps.mode != "summaryByUsers")
			props.users_id = 0;

		if (this.props.data.type == '3')
			props.whId = this.props.data.id.toString();
			
        this.props.rowProps.showUserProjectHours(props);
	}

	getCells = () => {
		const isWorkhourRow = this.props.data.type == '3';
		const isTotalRow = this.props.data.type == '2';

		const { data, checked, columnWidthMap } = this.props;

		let type = "";
        
        if(data.project_type == "3") {
            type = this.tr("vacation");
        } else if(data.is_overtime !== "0") {
            type = this.tr("overtime");
        } else {
            type = this.tr("normal");
		}

		const checkDisabled = data['check_disabled'];

		const cells = {
			checked:
				!checkDisabled ? 
					<CheckboxCell
						checked={checked} 
						width={columnWidthMap['checked']}
						name={"checked"}
						onClick={() => this.checkRows()} 
					/>
				:
					<ListCell
						width={columnWidthMap['checked']}
						listCellProps={{
							inEditMode: false,
						}}
						editable={false}
						name={"checked"}
					/>,
			openChildren:
				<ListCell
					width={columnWidthMap['openChildren']}
					listCellProps={{
						inEditMode: false,
					}}
					editable={false}
					name={"openChildren"}
				/>,
			column1:
				isWorkhourRow ?
					<DateCell
						listCellProps={{
							inEditMode: false,
							showTooltipForOverflownText: true,
							className: "column1"
						}}
						name="column1"
						value={moment(data.column1) || ''}
						width={columnWidthMap['column1']}
						cellClassName={"name-cell"}
						editable={false} />
					:
					<TextInputCell
						listCellProps={{
							inEditMode: false,
							showTooltipForOverflownText: true,
							className: "column1"
						}}
						width={columnWidthMap['column1']}
						name="column1"
						value={isTotalRow ? this.tr("Total") : data.column1}
						cellClassName={"name-cell"}
						editable={false}
					/>,
			column2:
				data.type == '2' ?
					<ListCell 
						width={columnWidthMap['column2']} 
						listCellProps={{
							inEditMode: false,
							showTooltipForOverflownText: true
						}}
						editable={false} 
						name={"column2"}
					/>
					:
					<TextInputCell
						listCellProps={{
							inEditMode: false,
							showTooltipForOverflownText: true
						}}
						width={columnWidthMap['column2']}
						name="column2"
						value={data.column2}
						cellClassName={"name-cell"}
						editable={false}
					/>,
			hours_not_submitted:
				<TextInputCell
					listCellProps={{
						inEditMode: false
					}}
					width={columnWidthMap['hours_not_submitted']}
					name="hours_not_submitted"
					value={formatInputNumber(data['hours_not_submitted'] || 0, "hours")}
					editable={false}
				/>,
			hours_for_approval:
				<LinkListCell
					listCellProps={{
						inEditMode: false
					}}
                    width={columnWidthMap['hours_for_approval']}
					name="hours_for_approval"
					value={formatInputNumber(data['hours_for_approval'] || 0, "hours")}
					editable={false}
                    urlHandler={value => `index.html?module=workhours&action=calendar&tab=approvals&listMode=hourList&projects_id=${this.props.data['projects_id']}&users_id=${this.props.data['users_id']}`}
					noTab={true}
					returnProps={true}
					handleClick={this.handleClick}
                    asText={!Number(data['hours_for_approval']) || data['hours_for_approval'] == 0}
                />,
			tracked_h:
				<TextInputCell
					listCellProps={{
						inEditMode: false
					}}
					width={columnWidthMap['tracked_h']}
					name="tracked_h"
					value={formatInputNumber(data['tracked_h'] || 0, "hours")}
					editable={false}
				/>,
			tracked_h_all:
				<TextInputCell
					listCellProps={{
						inEditMode: false
					}}
					width={columnWidthMap['tracked_h_all']}
					name="tracked_h_all"
					value={isWorkhourRow ? "-" : formatInputNumber(data['tracked_h_all'] || 0, "hours")}
					editable={false}
				/>,
			expected_h:
				<TextInputCell
					listCellProps={{
						inEditMode: false
					}}
					width={columnWidthMap['expected_h']}
					name="expected_h"
					value={isWorkhourRow ? data['wh_projects_resource'] : formatInputNumber(data['expected_h'] || 0, "hours")}
					editable={false}
				/>,
			internal_h:
				<TextInputCell
					listCellProps={{
						inEditMode: false,
						showTooltipForOverflownText: true
					}}
					width={columnWidthMap['internal_h']}
					name="internal_h"
					value={isWorkhourRow ? type : formatInputNumber(data['internal_h'] || 0, "hours")}
					editable={false}
				/>,
			customer_h:
				<TextInputCell
					listCellProps={{
						inEditMode: false,
						showTooltipForOverflownText: true
					}}
					width={columnWidthMap['customer_h']}
					name="customer_h"
					value={isWorkhourRow ? moment(data['timestart']).format('LT') : formatInputNumber(data['customer_h'] || 0, "hours")}
					editable={false}
				/>,
			absence_vacation_h:
				<TextInputCell
					listCellProps={{
						inEditMode: false
					}}
					width={columnWidthMap['absence_vacation_h']}
					name="absence_vacation_h"
					value={isWorkhourRow ? moment(data['timeend']).format('LT') : formatInputNumber(data['absence_vacation_h'] || 0, "hours")}
					editable={false}
				/>,
			balance_change:
					<TextInputCell
						listCellProps={{
							inEditMode: false
						}}
						width={columnWidthMap['balance_change']}
						name="balance_change"
						value={isWorkhourRow ? data['overtime_description'] : formatInputNumber(data['balance_change'] || 0, "hours")}
						editable={false}
					/>,
			overtime_change:
				isWorkhourRow ?
					<DateCell
						listCellProps={{
							inEditMode: false,
						}}
						name="overtime_change"
						value={moment(data.status_date) || ''}
						width={columnWidthMap['overtime_change']}
						editable={false} />
				:
				<TextInputCell
					listCellProps={{
						inEditMode: false,
						showTooltipForOverflownText: true
					}}
					width={columnWidthMap['overtime_change']}
					name="overtime_change"
					value={isWorkhourRow ? data['overtime_change'] : formatInputNumber(data['overtime_change'] || 0, "hours")}
					editable={false}
				/>,
			project_budgeted:
				<TextInputCell
					listCellProps={{
						inEditMode: false
					}}
					width={columnWidthMap['project_budgeted']}
					name="project_budgeted"
					value={formatInputNumber(data['project_budgeted'] || 0, "hours")}
					editable={false}
				/>,
			project_allocated:
				<TextInputCell
					listCellProps={{
						inEditMode: false
					}}
					width={columnWidthMap['project_allocated']}
					name="project_allocated"
					value={formatInputNumber(data['project_allocated'] || 0, "hours")}
					editable={false}
				/>,
		}

		return cells;
	}

	//Main class render
    render = () => {
		const cells = this.getCells();
		const { data } = this.props;
		const commonProps = {
			...this.props,
			key: data.id,
			cells,
			enqueueSnackbar: this.props.enqueueSnackbar
		}
		
		//Main class render return
        return (
            <React.Fragment>
                {data.type == '0' ? <ParentRow 
					{...commonProps}
                    /> : undefined}
				{(data.type == '1') ? <ChildRow 
                    {...commonProps}
					/> : undefined}
                {(data.type == '2') ? <TotalRow 
                    {...commonProps}
					/> : undefined}
				{(data.type == '3') ? <WorkhourRow 
                    {...commonProps}
					/> : undefined}
            </React.Fragment>
        )
    }
}

export default withSnackbar(ApprovalsSummaryListRow);
