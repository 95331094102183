/* react */
import React from 'react';
import './Efina.css';
import './Maventa.css';
import './Nav.css';
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import { DatePicker } from '../../general/react-date-range/src';
import { format } from 'date-fns';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import DataHandler from "../../general/DataHandler";
import FileSaver from 'file-saver';
import AccountingAccounts from '../components/AccountingAccounts';
import AccountingTermsOfPayments from '../components/AccountingTermsOfPayments';
import AccountingVatCodes from '../components/AccountingVatCodes';

class Nav extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Nav");

        this.state = {
            currentDialog: undefined,
            dialogData: {},
            stockDate: format(new Date(), "YYYY-MM-DD"),
            openEntryPeriod: format(new Date(), "YYYYMM"),
        };

        this.dialogs = {
            confirmation: ConfirmationDialog
        };

        this.integration = "nav";
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getCurrentPeriod();
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }

    handleDateSelect = (date) => {
        this.setState({ stockDate: format(date, "YYYY-MM-DD") });
    }

    handleDateInputChange = (dateType, date) => {
        this.setState({ stockDate: format(date, "YYYY-MM-DD") });
    }

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    }

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: {} });
    }

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    }

    getUnfinishedStock = () => {
        DataHandler.get({url: `settings/unfinished_stock`, company: this.props.company, date: this.state.stockDate}).done(response => {
            var blob = new Blob([response], {
                type: 'text/plain'
            });

            FileSaver.saveAs(blob, 'KeskenerainenAlihankinta.dat');
        });
    }

    getStock = () => {
        DataHandler.get({url: `settings/stock`, company: this.props.company, date: this.state.stockDate}).done(response => {
            var blob = new Blob([response], {
                type: 'text/plain'
            });

            FileSaver.saveAs(blob, 'Varasto.dat');
        });
    }

    getCurrentPeriod = () => {
        DataHandler.get({url: `settings/entry_period`, company: this.props.company}).done(response => {
            this.setState({openEntryPeriod: response.result});
        });
    }

    lockPeriod = () => {
        DataHandler.put({url: `settings/next_entry_period`}, {company: this.props.company}).done(response => {
            this.getCurrentPeriod();
        });
    }

    lockPeriodDialogOpen = () => {
        this.setState({
            dialogData: {
                id: 1,
                saveFunc: () => this.lockPeriod(),
                text: this.tr("Set next entry period for this company?")
            }
        }, () => this.openDialog('confirmation'));
    }
    
    render(){
        const { tr } = this;
        const { userObject } = this.context;

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <div id="nav-settings" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Microsoft Dynamics NAV Settings")}</h3>
                </div>

                <div className="settings">
                    <h4>{tr("Download KeskenerainenAlihankinta.dat")}</h4>
                    <div className="date-wrapper">
                        <DatePicker
                            className="basic-info date"
                            date={this.state.stockDate}
                            onChange={this.handleDateSelect}
                            onInputChange={this.handleDateInputChange}
                            label="Date"
                            dateFormat={userObject.dateFormat}
                            disabled={false}
                        />
                    </div>
                    <div className="inline-button">
                        <Button color="primary" onClick={() => this.getUnfinishedStock()} size="large">{this.tr('Download')}</Button>
                    </div>
                </div>

                <div className="settings">
                    <h4>{tr("Download Varasto.dat")}</h4>
                    <div className="date-wrapper">
                        <DatePicker
                            className="basic-info date"
                            date={this.state.stockDate}
                            onChange={this.handleDateSelect}
                            onInputChange={this.handleDateInputChange}
                            label="Date"
                            dateFormat={userObject.dateFormat}
                            disabled={false}
                        />
                    </div>
                    <div className="inline-button">
                        <Button color="primary" onClick={() => this.getStock()} size="large">{this.tr('Download')}</Button>
                    </div>
                </div>

                <div className="settings">
                    <h4>{tr("Next entry period")}</h4>
                    <span className="current-period-title">{this.tr("Open entry period")}:</span>
                    <span className="current-period">{this.state.openEntryPeriod}</span>
                    <div className="inline-button">
                        <Button color="primary" onClick={() => this.lockPeriodDialogOpen()} size="large">{this.tr('Lock period')}</Button>
                    </div>
                </div>

                <div className="settings">
                    <h4>{tr("Sales accounts")}</h4>
                    <AccountingAccounts 
                        integration={this.integration}
                        company={this.props.company}
                        type={1}
                        addButtonText={this.tr("Add sales account")}
                    />
                </div>

                <div className="settings">
                    <h4>{tr("Purchase accounts")}</h4>
                    <AccountingAccounts 
                        integration={this.integration}
                        company={this.props.company}
                        type={2}
                        addButtonText={this.tr("Add purchase account")}
                    />
                </div>

                <div className="settings">
                    <h4>{tr("Stock accounts")}</h4>
                    <AccountingAccounts 
                        integration={this.integration}
                        company={this.props.company}
                        type={3}
                        addButtonText={this.tr("Add stock account")}
                    />
                </div>

                <div className="settings">
                    <h4>{tr("VAT accounts")}</h4>
                    <AccountingAccounts 
                        integration={this.integration}
                        company={this.props.company}
                        type={4}
                        addButtonText={this.tr("Add VAT account")}
                    />
                </div>

                <div className="settings">
                    <h4>{tr("Payment terms")}</h4>
                    <AccountingTermsOfPayments 
                        integration={this.integration}
                        company={this.props.company}
                    />
                </div>

                <div className="settings">
                    <h4>{tr("VAT codes")}</h4>
                    <AccountingVatCodes 
                        integration={this.integration}
                        company={this.props.company}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                    />
                </div>

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
            </div>
        )
    }
}

export default Nav;