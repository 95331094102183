import React, { Component } from 'react';
import Lottie from 'react-lottie'
import animationData from './loadingScreen.json';

import './LoadingScreen.css';

class LoadingScreen extends Component {
    static defaultProps = {
        speed: 3,
        height: 400,
        width: 400,
        loadingMessage: "",
        className: "",
    };

    constructor(props) {
        super(props);
        
        this.ref = React.createRef();
    }
    
    componentDidMount() {
        this.ref.current.setSpeed();
    }

    render() {

        const { speed, height, width, loadingMessage, className } = this.props;

        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          };

        return (
            <div className={"loading-container " + className}>
                <Lottie ref={this.ref} options={defaultOptions}
                    speed={ speed }
                    height={ height }
                    width={ width }
                />
                {loadingMessage != "" ? <h2>{loadingMessage}</h2> : undefined}
            </div>
        );
    }
}

export default LoadingScreen;