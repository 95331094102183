import React from 'react';
import CompositeListCell from "./../CompositeListCell";
import Select, { components } from 'react-select';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import withStyles from '@mui/styles/withStyles';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import StatusTag from '../../general/StatusTag';


const DropdownIndicator = (props) => {
  return components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};

const IndicatorsContainer = (props) => {
  return (
    <div style={{}}>
      <components.IndicatorsContainer {...props}/>
    </div>
  );
};


class EditableStatusCell extends React.Component {
	static defaultProps = {
		editable: true,
		width: 50,
		value: 2,
		options: [],
		optionLabelKey: "name",
		openMenuOnEdit: true,
		onEdited: () => {},
		multiple: false,
		editable: true,
		listCellProps: {},
		onEnter: () => {}
	};


	constructor(props) {
		super(props);

		this.compositeListCell = React.createRef();
		this.autoComplete 	   = React.createRef();

		this.focusOnEditor 	   = this.focusOnEditor.bind(this);
	}


	focusOnEditor() {
		this.autoComplete.current.focus();
	}


	render() {
		let value = (this.props.value !== undefined && this.props.options.length > 0) ? this.props.options.find(o => {
			return (typeof this.props.value === "object") ? o.id === this.props.value.id : o.id === this.props.value;
    }) : "–";
    if (this.props.displayValue)
      value = this.props.displayValue;

		const color = typeof value === "object" ? value.color : "#ff9900";

        let label;
        if(!value || !value.hasOwnProperty) {
            label = "–";
        } else {
            label = typeof value === "object" && value.hasOwnProperty("label") ? value.label : value.hasOwnProperty("name") ? value.name : "–";
        }

        const cell = (
            <CompositeListCell className={this.props.className} owner={this} editable={this.props.editable} openEditOnFocus={true} width={this.props.width} ref={this.compositeListCell} {...this.props.listCellProps}>
                <StatusTag absolute clickable={this.props.editable} text={label} color={color} tooltip={this.props.tooltip} />
                <Select
                    ref={this.autoComplete}
                    components={{ DropdownIndicator, IndicatorsContainer }}
                    isSearchable={this.props.searchable}
                    isMulti={this.props.multiple}
                    autoFocus={false}
                    tabSelectsValue={false}
                    openMenuOnFocus={true}
                    value={value}
                    options={this.props.options.map(el => {
                        el['label'] = el.hasOwnProperty("label") ? el.label : el.hasOwnProperty(this.props.optionLabelKey) ? el[this.props.optionLabelKey] : "(no label)";
                        return el;
                    })}
                    // menuIsOpen={this.props.openMenuOnEdit}
                    className="autoCompleteInCell"
                    classNamePrefix="autoCompleteInCell"
                    onBlur={() => this.compositeListCell.current.closeEdit()}
                    onKeyDown={e => {
                        if(e.key !== "Enter")
                            return;

                        this.props.onEnter(e);
                    }}
                    onChange={value => {
                        this.compositeListCell.current.closeEdit();
                        this.props.onEdited(value);
                    }} />
            </CompositeListCell>
        );

        const editMode = this.compositeListCell.current && (!this.compositeListCell.current.isInCreateMode() && this.compositeListCell.current.isInEditMode());

        return editMode
            ?
            (<ClickAwayListener onClickAway={() => this.compositeListCell.current.closeEdit()}>
                {cell} 
            </ClickAwayListener>)
            :
            cell;
	}
}

export default EditableStatusCell;
