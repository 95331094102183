import TaimerComponent from '../TaimerComponent';
import IconCell from './IconCell';

import { UserColumnDefinition } from './ColumnTypes';

interface Props {
    value: string;
    colDef: UserColumnDefinition;
    justifyContent?: "left" | "right" | "icon_text";
    data: any;
}

export default class IconCellRenderer extends TaimerComponent<Props> {
    render() {
        const { value, data, colDef } = this.props;

        return <IconCell
                value={value}
                iconComponent={colDef?.getIconComponent && colDef?.getIconComponent(data)}
                justifyContent={colDef?.justifyContent || "icon_text"}
            />
    }
}