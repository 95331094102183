import React, { Component } from 'react';
import TaimerComponent from '../TaimerComponent';
import _ from 'lodash';
import './ProfitLossTable.css';
import cn from 'classnames';
import ProfitLossTableRow from './ProfitLossTableRow';
import ProfitLossTableSlot from './ProfitLossTableSlot';
import OpenIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/KeyboardArrowDown';

class ProfitLossTableGroup extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "charts/ProfitLossTableGroup");

        this.state = {
            collapsed: true,
            hover: false,
        }
    }

    click = () => {
        const { collapsed } = this.state;

        this.setState({collapsed: !collapsed});
    }

    onMouseOver = (e) => {
        this.setState({hover: true});
    }

    onMouseOut = (e) => {
        this.setState({hover: false});
    }

    render() {
        const { id, label, keys, values, groups, data, sets, currency, currencyFormatter, labels, openSlider } = this.props;
        const { collapsed, hover } = this.state;

        const sum = _.sum(_.values(values));

        return <React.Fragment>
            <div className={cn("column row-group row-header row-header-collapse", hover && "row-hover")} onClick={this.click} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
                {label}
                {collapsed ? <OpenIcon /> : <CloseIcon />}
            </div>

            {keys.map( (x) => <ProfitLossTableSlot 
                className={cn("row-group", hover && "row-hover")}
                key={x}
                groupId={id}
                value={values[x]}
                currency={currency}
                currencyFormatter={currencyFormatter}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                openSlider={openSlider} />)}

            <ProfitLossTableSlot
                groupId={id}
                className={cn("row-group row-total", hover && "row-hover")}
                value={sum}
                currency={currency}
                currencyFormatter={currencyFormatter}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                openSlider={openSlider} />
            {
                !collapsed && _.map(groups, (g, key) => <ProfitLossTableRow
                    key={key}
                    chartId={key}
                    keys={keys}
                    linked
                    onClickValue={this.onClickValue}
                    label={g.chart.label}
                    labels={labels}
                    color={g.chart.backgroundColor}
                    currency={currency}
                    currencyFormatter={currencyFormatter}
                    table={g.chart.table || {}}
                    data={data[key]}
                    values={sets[key]}
                    openSlider={openSlider} />)
            }
        </React.Fragment>
    }
}

export default ProfitLossTableGroup;