import React from "react";
import { Popover } from '@mui/material';
import StatusTag from './StatusTag';
import "./StatusTag.css";

class EditableStatusTag extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: undefined,
    }
  }

  handleClick = (e) => this.setState({ anchorEl: e.target });
  handleClose = () => this.setState({ anchorEl: undefined });

  render() {
    const { disabled, statuses, onChange } = this.props;
    return (
      <>
        <div onClick={e => !disabled && this.handleClick(e)}>
          <StatusTag clickable={!disabled} {...this.props}></StatusTag>
        </div>

        <Popover
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: -8 }}
          open={!!this.state.anchorEl}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
        >
          {statuses.map((status, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  onChange(status);
                  this.handleClose();
                }}
                className="editable-status-tag-menu-row"
              >
                <p>{status.label}</p>
              </div>
            );
          })}
        </Popover>
      </>
    );
  }
}

export default EditableStatusTag;