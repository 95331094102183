import { ReactComponent as collapse } from './icons/collapse.svg';
import { ReactComponent as expand } from './icons/expand.svg';
import { ReactComponent as gantt } from './icons/kanban.svg';
import { ReactComponent as list } from './icons/list.svg';

export default {
    collapse: collapse,
    expand: expand,
    gantt: gantt,
    list: list,
}