import React from 'react';

/*context*/
import { SettingsContext } from './../SettingsContext';

/*local components*/
import TaimerComponent from '../TaimerComponent';
import TabPersonalDetails from './TabPersonalDetails';

/*material-ui*/
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import TabMemo from './TabMemo';
import TabAttachments from '../general/TabAttachments';
/*style*/
import './ContactView.css';
import WithTabs from '../navigation/WithTabs';
import cardStyles from '../general/styles/CardStyles.module.scss';
import DataHandler from '../general/DataHandler';
import { Delete } from '@mui/icons-material';
import ContextMenu from '../general/ContextMenu';
import { MenuItem } from '@mui/material';
import { withSnackbar } from 'notistack';

class ContactView extends TaimerComponent {
    static contextType = SettingsContext;
    backButton = {
        visible: true,
        fallbackLocation: { module: 'contacts', action: 'main', selectedTab: 'contacts' },
    };
    tabs = [
        { id: 'personal_details', label: this.tr('Personal Details') },
        { id: 'memo', label: this.tr('Memo'), hidden: this.context.addons.hide_contact_stuff, disabled: !this.props.id },
        { id: 'attachments', label: this.tr('Attachments'), hidden: this.context.addons.hide_contact_stuff, disabled: !this.props.id },
    ];

    constructor(props, context) {
        super(props, context, 'contacts/ContactView');
        this.tabPersonalDetails = React.createRef();
        this.state = {
            contact: {},
            showContactNameInHeader: (this.props.selectedTab || 'personal_details') != 'personal_details',
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        DataHandler.get({ url: 'get_contacts', mode: 'none', contact: this.props.id, company: this.props.company ? this.props.company : this.context.userObject.companies_id }).done((data) => {
            this.setState({ contact: data.contacts[0] || {} });
        });
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedTab && prevProps.selectedTab !== this.props.selectedTab) {
            this.setState({ showContactNameInHeader: (this.props.selectedTab || 'personal_details') != 'personal_details' }, () => {
                this.context.functions.setOverrideHeaderTitles(undefined);
            });
        }
    };

    getHeaderTitles = () => {
        const noContactName = !this.state?.contact?.firstname || !this.state.showContactNameInHeader;
        return noContactName
            ? [this.tr('Contact')]
            : [
                  {
                      label: `${this.state?.contact?.firstname} ${this.state?.contact?.lastname}`,
                      sublabel: this.state?.contact?.title ? { label: this.state?.contact?.title } : undefined,
                  },
              ];
    };

    setShowContactNameInHeader = (showContactNameInHeader) => {
        this.context.functions.setOverrideHeaderTitles(
            showContactNameInHeader
                ? [
                      {
                          label: `${this.state?.contact?.firstname} ${this.state?.contact?.lastname}`,
                          sublabel: this.state?.contact?.title ? { label: this.state?.contact?.title } : undefined,
                      },
                  ]
                : undefined
        );
    };

    deleteContact = () => {
        DataHandler.post({ url: 'delete_contact/', id: this.props.id })
            .done(() => {
                this.context.functions.updateView({ module: 'contacts', action: 'main', selectedTab: 'contacts' });
                this.props.enqueueSnackbar(this.tr('Contact deleted!'), { variant: 'success' });
            })
            .fail(() => {
                this.props.enqueueSnackbar(this.tr('Deleting contact failed!'), { variant: 'error' });
            });
    };

    closeDeleteConfirmation = () => {
        this.context.functions.closeDialog();
    };

    showDeleteConfirmation = () => {
        this.context.functions.showConfirmationDialog({
            header: this.tr('Delete contact'),
            warning: this.tr('Are you sure you want to delete this contact?'),
            confirmText: this.tr('Delete'),
            onConfirm: this.deleteContact,
        });
    };

    renderActionButtons = () => {
        const {
            functions: { checkPrivilege },
        } = this.context;
        if (!checkPrivilege('persons', 'read')) {
            return null;
        }
        return (
            <div className={cardStyles.actionButtons}>
                <ContextMenu
                    //@ts-ignore
                    className={cardStyles.optionsMenu}
                    buttonProps={{
                        color: 'green',
                        stickyIcon: true,
                        size: 'large',
                    }}
                    variant="outlined"
                    label={this.tr('Actions')}
                    size="medium"
                    placement={'bottom-end'}
                >
                    <MenuItem className={cardStyles.delete} onClick={this.showDeleteConfirmation}>
                        <Delete />
                        {this.tr('Delete')}
                    </MenuItem>
                </ContextMenu>
            </div>
        );
    };

    render() {
        return (
            <div className="taimer-view" id="contact-view">
                <WithTabs height={55} tabs={this.tabs} selectedTab={this.props.selectedTab} rightComponent={this.renderActionButtons()}>
                    {(selectedTab) => {
                        switch (selectedTab) {
                            case 'personal_details':
                                return <TabPersonalDetails ref={this.tabPersonalDetails} setShowContactNameInHeader={this.setShowContactNameInHeader} {...this.props} />;
                            case 'memo':
                                return <TabMemo {...this.props} />;
                            case 'attachments':
                                return <TabAttachments {...this.props} editable={true} entity="contacts" />;
                            default:
                                return null;
                        }
                    }}
                </WithTabs>
            </div>
        );
    }
}
export default withSnackbar(ContactView);
