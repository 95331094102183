import React from 'react';

/* css */
import './InsightTable.css';

/* list */
import NumberListCell from "../../list/cells/NumberListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import CurrencyListCell from '../../list/CurrencyListCell';
import LinkListCell from "../../list/LinkListCell";
import List from "../../list/List";
import ListCell from '../../list/ListCell';
import PercentageListCell from "../../list/PercentageListCell";
import PropsOnlyListRow from "../../list/PropsOnlyListRow";

import { ArrowDownward as ArrowDropDown, ArrowUpward as ArrowDropUp, Remove as Dash } from '@mui/icons-material';
import OpenIcon from '@mui/icons-material/ChevronRight';
import CloudDownload from '@mui/icons-material/CloudDownload';
import CloseIcon from '@mui/icons-material/KeyboardArrowDown';

/* others */
import { Avatar, Tab, Tabs } from '@mui/material';
import cn from 'classnames';
import ColorHash from 'color-hash';
import _ from 'lodash';
import { SettingsContext } from '../../../src/SettingsContext';
import ContentBlock from '../../general/ContentBlock';
import TaimerAvatar from '../../general/TaimerAvatar';
import TaimerComponent from "../../TaimerComponent";
import { filterSliderData } from './InsightHelpers';
import InsightSlider from './InsightSlider';

const colorHash = new ColorHash();

export class InsightTableListGroupRow extends PropsOnlyListRow {
    constructor(props, context) {
        super(props, {}, { childRowType: InsightTableListRow }, "dashboard/insights/InsightTableRow");

        this.refProject = React.createRef();
    }

    // TODO: abstract
    defineClassName() {
        return "insightTableListRow insightTableListGroupRow";
    }

    toggleExpand = () => {
        this.props.listRef.toggleShowChildren(this.props.data.id);
    }

    rowClick = () => {
        this.toggleExpand();
    }

    defineCells() {
        const { data } = this.props;
        const { sharedData: { currency } } = this.props;

        const Indicator = data.change == 0 ? Dash : (data.change > 0 ? ArrowDropUp : ArrowDropDown);

        const { taimerAccount } = this.context;
        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency,
        }).format;

        const usernameDisplayed = data.users_company < 1 ? `${data.users_name} (${this.tr("freelancer")})` : data.users_name;

        let cells = {
            expand:
                <ListCell name="user" onlyDisplay editable={false}>
                    <div onClick={this.toggleExpand}>
                        {!this.props.childrenVisible ? <OpenIcon /> : <CloseIcon />}
                    </div>
                </ListCell>,
            user:
                <ListCell name="user" onlyDisplay editable={false}>
                    <div className="UserAvatarCell">
                        <TaimerAvatar
                            id={data.users_id}
                            name={data.users_name}
                            color={data.users_color}
                        />

                        {usernameDisplayed || '–'}
                    </div>
                </ListCell>,
            type:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.types_name || '–'} />,
            account_group:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.group_name || '–'} />,
            category:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.category_name || '–'} />,
            team:
                <ListCell name="user" onlyDisplay editable={false}>
                    <div className="UserAvatarCell">
                        <Avatar
                            // id={data.users_id}
                            name={data.name}
                            style={{ width: 30, height: 30, backgroundColor: colorHash.hex(data.name || '') }}
                        // color={data.users_color}
                        >{data.name ? data.name[0].toUpperCase() : ''}</Avatar>

                        {data.name || '–'}
                    </div>
                </ListCell>,
            team2:
                <LinkListCell
                    urlHandler={value => `index.html?module=customers&action=view&id=${data.account_id}`}
                    // onlyDisplay
                    editable={false}
                    asText
                    value={data.name || "–"} />,
            account:
                <LinkListCell
                    urlHandler={value => `index.html?module=customers&action=view&id=${data.customers_id}`}
                    // onlyDisplay
                    editable={false}
                    asText
                    value={data.customers_name || "–"} />,
            project:
                <ListCell
                    urlHandler={value => `index.html?module=projects&action=view&id=${data.projects_id}`}
                    onlyDisplay
                    editable={false}
                    cellClassName="projectcell"
                    asText={data.projects_id < 1}>
                    <div className="project_name">{data.projects_name || "–"}</div>
                    <div className="customer_name">{data.customers_name || "–"}</div>
                </ListCell>,
            product:
                <LinkListCell
                    urlHandler={value => `index.html?module=projects&action=view&id=${data.projects_id}`}
                    // onlyDisplay
                    editable={false}
                    asText
                    value={data.product_name || "–"} />,
            quantity:
                <TextInputCell
                    // listCellType={NumberListCell}
                    listCellProps={{ showZero: true }}
                    onlyDisplay
                    editable={false}
                    value="–" />,
            split:
                <TextInputCell
                    listCellType={PercentageListCell}
                    // listCellProps={{ showZero: true }}
                    onlyDisplay
                    editable={false}
                    value={data.split} />,
            sum:
                <TextInputCell
                    textAlign="right"
                    listCellType={CurrencyListCell}
                    listCellProps={{
                        className: "cell-sum",
                        showZero: true,
                        currency,
                    }}
                    onlyDisplay
                    editable={false}
                    value={data.sum} />,
            change:
                <ListCell name="change" onlyDisplay editable={false}>
                    {(data.change) ?
                        <span title={currencyFormatter(data.previous)} className={data.change > 0 ? "change-up" : "change-down"}>
                            <Indicator />
                            <span className="amount">{data.change}%</span>
                        </span> : undefined}
                </ListCell>,
        };

        return cells;
    }
}


export class InsightTableListRow extends PropsOnlyListRow {
    constructor(props, context) {
        super(props, {}, { childRowType: InsightTableListRow }, "dashboard/insights/InsightTableRow");

        this.refProject = React.createRef();
    }

    // TODO: abstract
    defineClassName() {
        return "insightTableListRow";
    }

    defineCells() {
        const { data } = this.props;
        const { sharedData: { currency } } = this.props;

        const Indicator = data.change == 0 ? Dash : (data.change > 0 ? ArrowDropUp : ArrowDropDown);

        const { taimerAccount } = this.context;
        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency,
        }).format;

        const usernameDisplayed = data.users_company < 1 ? `${data.users_name} (${this.tr("freelancer")})` : data.users_name;

        let cells = {
            expand:
                <ListCell name="user" onlyDisplay editable={false}></ListCell>,
            user:
                <ListCell name="user" onlyDisplay editable={false}>
                    <div className="UserAvatarCell">
                        <TaimerAvatar
                            id={data.users_id}
                            name={data.users_name}
                            color={data.users_color}
                        />

                        {usernameDisplayed || '–'}
                    </div>
                </ListCell>,
            type:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.types_name || '–'} />,
            account_group:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.group_name || '–'} />,
            category:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.category_name || '–'} />,
            team:
                <ListCell name="user" onlyDisplay editable={false}>
                    <div className="UserAvatarCell">
                        <Avatar
                            // id={data.users_id}
                            name={data.name}
                            style={{ width: 30, height: 30, backgroundColor: colorHash.hex(data.name || '') }}
                        // color={data.users_color}
                        >{data.name ? data.name[0].toUpperCase() : ''}</Avatar>

                        {data.name || '–'}
                    </div>
                </ListCell>,
            team2:
                <LinkListCell
                    urlHandler={value => `index.html?module=customers&action=view&id=${data.account_id}`}
                    // onlyDisplay
                    editable={false}
                    asText
                    value={data.name || "–"} />,
            account:
                <LinkListCell
                    urlHandler={value => `index.html?module=customers&action=view&id=${data.customers_id}`}
                    // onlyDisplay
                    editable={false}
                    asText
                    value={data.customers_name || "–"} />,
            project:
                <ListCell
                    urlHandler={value => `index.html?module=projects&action=view&id=${data.projects_id}`}
                    onlyDisplay
                    editable={false}
                    cellClassName="projectcell"
                    asText={data.projects_id < 1}>
                    <div className="project_name">{data.projects_name || "–"}</div>
                    <div className="customer_name">{data.customers_name || "–"}</div>
                </ListCell>,
            product:
                <LinkListCell
                    urlHandler={value => `index.html?module=projects&action=view&id=${data.projects_id}`}
                    // onlyDisplay
                    editable={false}
                    asText
                    value={data.product_name || "–"} />,
            quantity:
                <TextInputCell
                    listCellType={NumberListCell}
                    listCellProps={{ showZero: true, decimals: 2 }}
                    onlyDisplay
                    editable={false}
                    value={data.quantity} />,
            split:
                <TextInputCell
                    listCellType={PercentageListCell}
                    // listCellProps={{ showZero: true }}
                    onlyDisplay
                    editable={false}
                    value={data.split} />,
            sum:
                <TextInputCell
                    textAlign="right"
                    listCellType={CurrencyListCell}
                    listCellProps={{
                        className: "cell-sum",
                        showZero: true,
                        currency,
                    }}
                    onlyDisplay
                    editable={false}
                    value={data.sum} />,
            change:
                <ListCell name="change" onlyDisplay editable={false}>
                    {(data.change) ?
                        <span title={currencyFormatter(data.previous)} className={data.change > 0 ? "change-up" : "change-down"}>
                            <Indicator />
                            <span className="amount">{data.change}%</span>
                        </span> : undefined}
                </ListCell>,
        };

        return cells;
    }
}

class InsightTable extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        width: 3,
        visible: true,
        sliderTitleColumn: "name",
        rowKey: "key",
        parentKey: "parentId",
        sortKey: "sum",
        sliderProps: {},
        showExport: false,
    }

    constructor(props, context) {
        super(props, context, "dashboard/insights/InsightTable");

        this.state = {
            currentTab: props.defaultTab,
            sliderData: false,
            pageSize: 50,
            page: 1,
        }
    }

    handleTabChange = (event, value) => {
        this.setState({ currentTab: value });
    }

    onClickRow = (sliderData) => {
        if (sliderData._type === "group")
            return;

        if (this.props.showSlider) {
            this.props.showSlider(sliderData);
            return;
        }

        this.setState({ sliderData });
    }

    onCloseSlider = () => {
        this.setState({ sliderData: false });
    }

    export = () => {
        const { sliderRows, data, onExport } = this.props;
        const { currentTab } = this.state;

        const allData = (currentTab ? data[currentTab] : data) || [];
        const sliders = filterSliderData(allData, sliderRows);

        onExport({mode: currentTab, data: allData, sliders});
    }

    render() {
        const { title, className, data, noSlider, table, tabs, filterComponents, extraCols, height, style, rowKey, sliderTitleColumn, sliderColumns, currency, hideDefaults, columns, rowType, blockProps, sliderRows, sliderProps, sliderSortKey, groups, parentKey, sortKey, showExport, summaryRowType } = this.props;
        const { currentTab, sliderData, page, pageSize } = this.state;
        const { tr } = this;

        const cols = columns || [
            { field: "expand", name: "expand", header: "", width: 10, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "user", name: "user", header: tr("User"), width: 200, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "category", name: "category", header: tr("Category"), width: 200, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "account_group", name: "account_group", header: tr("Account Group"), width: 200, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "type", name: "type", header: tr("Type"), width: 200, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "team", name: "team", header: tr("Team"), width: 200, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "account", name: "account", header: tr("Account"), width: 200, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "project", name: "project", header: tr("Project"), width: 200, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "product", name: "product", header: tr("Product"), width: 200, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "quantity", name: "quantity", header: tr("Quantity"), width: 50, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "sum", name: "sum", header: tr("Sum"), width: 100, alwaysVisible: !hideDefaults, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "change", name: "change", header: "", width: 50, alwaysVisible: !hideDefaults, resizeable: false, moveable: false, hideable: false, showMenu: false },
            { field: "split", name: "split", header: tr("Split"), width: 50, alwaysVisible: !hideDefaults, resizeable: false, moveable: false, hideable: false, showMenu: false },
        ].filter(x => (extraCols || []).indexOf(x.name) !== -1 || x.alwaysVisible);

        const wrapperClass = blockProps ? blockProps.wrapperClass : '';

        const allData = (currentTab ? data[currentTab] : data) || [];

        const pageData = allData.slice((page - 1) * pageSize, page * pageSize);

        if (groups && groups.length) {
            for (const item of groups) {
                item._type = "group";
                pageData.push(item);
            }

            pageData.sort((a, b) => b[sortKey] - a[sortKey]);
        }

        return (
            <div className={className} style={style}>
                <InsightSlider
                    open={!!sliderData && !noSlider}
                    label={`${title} - ${sliderData && sliderData[sliderTitleColumn]}`}
                    onClose={this.onCloseSlider}
                    data={sliderData && sliderData.data !== undefined ? sliderData.data : undefined}
                    currentRows={sliderData ? sliderData.slider_rows : undefined}
                    allRows={sliderRows}
                    sum={sliderData ? sliderData.sum : 0}
                    table={table}
                    columns={sliderColumns}
                    sortKey={sliderSortKey}
                    currency={currency}
                    parent={sliderData}
                    {...sliderProps}
                />

                <ContentBlock
                    {...blockProps}
                    filterComponents={<div style={{display: 'flex'}}>
                        {filterComponents}
                        {(tabs && <Tabs key="tabs" color="primary" indicatorColor="primary" value={currentTab} onChange={this.handleTabChange}>
                            {_.map(tabs, (tab, i) => <Tab key={i} value={i} label={tab} />)}
                        </Tabs>)}
                        {showExport && <a className="insightsExportButton" onClick={this.export}><CloudDownload /></a>}
                    </div>}
                    buttonTitle={title} noPadding noBottomPadding wrapperClass={cn("insight-table-container", wrapperClass)}>
                    {data && <div className="table">
                        <List
                            key={cols.map(x => x.name).join('|')}
                            fluid
                            noStateData={true}
                            height={height}
                            trimHeight={(allData.length > pageSize ? -200 : 0)}
                            rowHeight={44}
                            rowKey={rowKey}
                            parentKey={parentKey}
                            data={pageData}
                            columns={cols}
                            noStateData
                            treeData={!!groups}
                            idType="string"
                            summaryRowType={summaryRowType}
                            listRowType={rowType || InsightTableListRow}
                            listRowTypeMap={{
                                group: InsightTableListGroupRow,
                            }}
                            parentsExpandedOnInit={false}
                            rowProps={{
                                onClickRow: this.onClickRow,
                                ...this.props.rowProps
                            }}
                            ignoreRowPropsChange={this.props.ignoreRowPropsChange}
                            sharedData={{
                                currency, ...this.props.sharedData,
                                allData,
                            }}
                            onPageChange={page => this.setState({ page })}
                            showPageSelector={allData.length > pageSize}
                            perpage={pageSize}
                            totalCount={allData.length}
                            onPerPageChange={(pageSize) => this.setState({ pageSize })}
                            pageCount={Math.ceil(allData.length / pageSize)}
                        />
                    </div>}
                </ContentBlock>
            </div>
        );
    }
}

export default InsightTable;
