import React from 'react';
import OutlinedField from "./../../general/OutlinedField";

class CustomFieldText extends React.Component {
    static defaultProps = {
        disabled: false,
        error: false,
    };

    onChange = (evt) => {
        this.props.onChange(this.props.name, evt.target.value);
    }

    render() {
        const { name, value, error, validate, disabled, hideNoValueError, field: {
            name: label, required, type
        }, rootRef } = this.props;

        const novalue = !value;

        return <OutlinedField
            ref={rootRef}
            multiline={type === "textarea"}
            className={type == 'textarea' ? 'customFieldTextarea' : undefined}
            // rows={type === "textarea" && 2}
            fullWidth
            label={!required ? label : label +" *"}
            name={name}
            value={value}
            // validation={required ? ["empty"] : []}
            error={!disabled && (error || (required && validate && novalue && !hideNoValueError))}
            disabled={disabled}
            onChange={this.onChange} />
    }
}

export default CustomFieldText;
