import React, { Component } from 'react';
import moment from "moment";
import TaimerComponent from '../TaimerComponent';
import DialogBorder from './DialogBorder';
import DialogSection from './DialogSection';
import { DialogDropDownButton, DialogDropDownButtonItem } from './DialogDropDownButton';
import OutlinedField from "../general/OutlinedField";
import { DatePicker } from '../general/react-date-range/src';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';

import { SettingsContext } from '../SettingsContext';
import DataHandler from '../general/DataHandler';
import DataList from './../general/DataList';



import './ResourceRecurrenceDialog.css';
import { cloneDeep } from 'lodash';


export default class ResourceRecurrenceDialog extends TaimerComponent {

    static contextType = SettingsContext;

	constructor(props, context) {
		super(props, context, "dialogs/ResourceRecurrenceDialog");        

        let defaulMonth = {value: 1, label: this.tr('Jan')};

        this.state = {
            freq: 'weekly',
            interval: 1,
            ends: 'after',
            after: 10,
            monthday: 1,
            setPos: {value: 1, label: this.tr("First")},
            month: defaulMonth,
            endDate: new Date(),
            selectedFormat: 'monthday',
            selectedWeekDays: {
                su: false,
                mo: false,
                tu: false,
                we: false,
                th: false,
                fr: false,
                sa: false,
                'su,mo,tu,we,th,fr,sa': false,
                'mo,tu,we,th,fr': false,
                'su,sa': false
            }
        };

        let days = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];

        this.state.selectedWeekDays[days[new Date().getDay()]] = true;

        this.weekdays = [
            {value: 'mo', label: this.tr("Mon")},
            {value: 'tu', label: this.tr("Tue")},
            {value: 'we', label: this.tr("Wed")},
            {value: 'th', label: this.tr("Thu")},
            {value: 'fr', label: this.tr("Fri")},
            {value: 'sa', label: this.tr("Sat")},            
        ];
        if(context.calendar.startOfWeek == 0) {
            this.weekdays.unshift({value: 'su', label: this.tr("Sun")});
        } else {
            this.weekdays.push({value: 'su', label: this.tr("Sun")});
        }
        this.weekdays.push({value: 'su,mo,tu,we,th,fr,sa', label: this.tr("Day"), isComposite: true});
        this.weekdays.push({value: 'mo,tu,we,th,fr', label: this.tr("Weekday"), isComposite: true},);
        this.weekdays.push({value: 'su,sa', label: this.tr("Weekend day"), isComposite: true},);

        this.months = [
            {value: 1, label: this.tr("Jan")},
            {value: 2, label: this.tr("Feb")},
            {value: 3, label: this.tr("Mar")},
            {value: 4, label: this.tr("Apr")},
            {value: 5, label: this.tr("May")},
            {value: 6, label: this.tr("Jun")},
            {value: 7, label: this.tr("Jul")},
            {value: 8, label: this.tr("Aug")},
            {value: 9, label: this.tr("Sep")},
            {value: 10, label: this.tr("Oct")},
            {value: 11, label: this.tr("Nov")},
            {value: 12, label: this.tr("Dec")},
        ];
        this.onTheDays = [
            {value: 1, label: this.tr("First")},
            {value: 2, label: this.tr("Second")},
            {value: 3, label: this.tr("Third")},
            {value: 4, label: this.tr("Fourth")},
            {value: -1, label: this.tr("Last")},
        ];

        if(this.props.rrule) {
            let rrules = this.props.rrule.split(";");
            let format = 'monthday';
            let endFormat = 'never';
            for(let i = 0; i < rrules.length; i++) {
                let split = rrules[i].split("=");
                let name = split[0].toLowerCase();
                let value = split[1].toLowerCase();                
                switch(name) {
                    case 'freq':
                        this.state.freq = value;
                        break;
                    case 'interval':
                        this.state.interval = value;
                        break;
                    case 'bysetpos':
                        format = 'countday';
                        this.state.setPos = this.onTheDays.find(el => el.value == value);
                        break;
                    case 'bymonth':
                        this.state.month = this.months.find(el => el.value == value);
                        break;
                    case 'bymonthday':
                        this.state.monthday = value;
                        break;
                    case 'byday':
                        for(let j in this.state.selectedWeekDays) {
                            this.state.selectedWeekDays[j] = false;
                        }
                        if(this.state.selectedWeekDays[value] !== undefined) {
                            this.state.selectedWeekDays[value] = true;
                        } else {
                            let splittedDays = value.split(",")                            
                            for(let k = 0; k < splittedDays.length; k++) {
                                if (splittedDays[k])
                                    this.state.selectedWeekDays[splittedDays[k]] = true;
                            }                            
                        }
                        break;
                    case 'count':
                        endFormat = 'after';
                        this.state.after = value;
                        break;
                    case 'until':
                        endFormat = 'onDate';
                        this.state.endDate = moment(value.toUpperCase())._d;
                        break;
                }                
            }
            this.state.selectedFormat = format;            
            this.state.ends = endFormat;            
        }
        
    }

    onChange = (e) => {
        this.setValue(e.target.name, e.target.value);
    }

    setValue = (key, value) => {
        this.setState({
            [key]: value
        });
    }

    setWeekDay = (value) => {
        const selectedWeekDays = cloneDeep(this.state.selectedWeekDays);
        selectedWeekDays[value] = !selectedWeekDays[value];
        this.setState({selectedWeekDays});
    }

    setSingleWeekday = (day) => {
        let selectedWeekDays = cloneDeep(this.state.selectedWeekDays);
        for(let i in selectedWeekDays) {
            selectedWeekDays[i] = false;
        }
        selectedWeekDays[day] = true;
        this.setState({selectedWeekDays});
    }

    createRule() {
        const { userObject } = this.context;
        let string = "";
        let humanReadable = "";
        let weekdayTranslations = [];
        const freq = this.state.freq;

      
        string += "freq=" + freq + ";";
        
        if(freq != 'yearly') {
            string += "interval=" + this.state.interval + ";";
            humanReadable += "interval=1";
        }
        humanReadable += "freq=1";

        if(freq == 'yearly' || freq == 'monthly') {            
            if(this.state.selectedFormat == 'monthday') {                
                if(freq == 'yearly') {
                    string += "bymonth=" + this.state.month.value + ";";
                    humanReadable += "bymonth=1";
                }
                string += "bymonthday=" + (this.state.monthday) + ";";
                humanReadable += "bymonthday=1";
            }
            if(this.state.selectedFormat == 'countday') {          
                let singleWeekday = false;
                if(this.state.selectedFormat == 'countday') {
                    for(let i in this.state.selectedWeekDays) {
                        if(this.state.selectedWeekDays[i]) {
                            singleWeekday = i;
                            weekdayTranslations.push(this.weekdays.find(el => el.value == i).label);
                            break;
                        }
                    }
                }                
                string += "bysetpos=" + this.state.setPos.value + ";";
                string += "byday=" + singleWeekday + ";";
                humanReadable += "bysetpos=1";
                humanReadable += "byday=1";
                if(freq == 'yearly') {
                    string += "bymonth=" + this.state.month.value + ";";
                    humanReadable += "bymonth=1";
                }
            }
        }
        if(freq == 'weekly') {
            string += "byday=";
            humanReadable += "byday=1";
            let selected = [];
            for(let i in this.state.selectedWeekDays) {
                if(this.state.selectedWeekDays[i] && i.indexOf(",") == -1) {
                    selected.push(i);
                    weekdayTranslations.push(this.weekdays.find(el => el.value == i).label);
                }
            }
            string += selected.join(",") + ";";
        }
        
        if(this.state.ends == 'after') {
            string += "count=" + this.state.after + ";";
            humanReadable += "count=1";
        }
        if(this.state.ends == 'onDate') {
            string += "until=" + moment(this.state.endDate).format("YYYYMMDDT000000") + "Z;";
            humanReadable += "until=1";
        }

        let freqTranslations = {
            yearly: this.tr('year'),
            monthly: this.state.interval > 1 ? this.tr('months') : this.tr('month'),
            weekly: this.state.interval > 1 ? this.tr('weeks') : this.tr('week'),
            daily: this.state.interval > 1 ? this.tr('days') : this.tr('day')
        };


        humanReadable = this.tr('Every') + " " + humanReadable;
        if(this.state.interval > 1) {
            humanReadable = humanReadable.replace(/interval=1/, this.state.interval);
        } else {
            humanReadable = humanReadable.replace(/interval=1/, "");
        }
        humanReadable = humanReadable.replace(/freq=1/, " " + freqTranslations[freq] + " ");
        humanReadable = humanReadable.replace(/bymonth=1/, (this.state.selectedFormat == 'countday' ? " " + this.tr('of') + ' ' : ''  ) + this.state.month.label + " ");
        humanReadable = humanReadable.replace(/bymonthday=1/, this.state.monthday + ". ");
        humanReadable = humanReadable.replace(/bysetpos=1/, this.state.setPos.label.toLowerCase() + " ");
        humanReadable = humanReadable.replace(/count=1/, ", " + this.state.after + " " + this.tr('times'));
        humanReadable = humanReadable.replace(/until=1/, ", " + this.tr('until') + " " + moment(this.state.endDate).format(userObject.dateFormat));
        humanReadable = humanReadable.replace(/byday=1/, weekdayTranslations.join(", ").toLowerCase() + " ");
        humanReadable = this.tr("Repeats") + " " + humanReadable;
        let result = {
            rule: string.slice(0, -1).toUpperCase(),
            text: humanReadable
        }
        return result.rule;
    }

    onSave = () => {
        this.props.onSave(this.createRule());
    }


    render() {
        const { userObject } = this.context;
        let singleWeekday = {};
        for(let i in this.state.selectedWeekDays) {
            if(this.state.selectedWeekDays[i]) {
                singleWeekday = i;
                break;
            }
        }

        return (
            <DialogBorder
                title={this.props.title || this.tr("Recurring Task")}
                onClose={this.props.onClose}
                onSave={this.onSave}
                disableSave={false}
                className="TMRDialogInputs ResourceRecurrenceDialog"
                >

                <DialogSection>
                    <OutlinedField 
                        className="half"
                        label={this.tr("Recurring")}
                        select
                        value={this.state.freq}
                        fullWidth
                    >
                        <MenuItem value="yearly" onClick={() => this.setState({freq: "yearly"})}>{this.tr('Yearly')}</MenuItem>
                        <MenuItem value="monthly" onClick={() => this.setState({freq: "monthly"})}>{this.tr('Monthly')}</MenuItem>
                        <MenuItem value="weekly" onClick={() => this.setState({freq: "weekly"})}>{this.tr('Weekly')}</MenuItem>
                        <MenuItem value="daily" onClick={() => this.setState({freq: "daily"})}>{this.tr('Daily')}</MenuItem>
                    </OutlinedField>
                    {
                        this.state.freq != 'yearly' &&  (<OutlinedField 
                            fullWidth
                            className="half"
                            label={this.tr("Interval")}
                            disabled={false}
                            error={false}
                            onChange={(e) => this.setState({interval: e.target.value})}
                            value={this.state.interval}
                        />)
                    }
                    <div className={"time-container " + this.state.freq}>
                        {
                            (this.state.freq == 'yearly' || this.state.freq == 'monthly') && (
                                <React.Fragment>
                                    <div className="on-day selection-container full">
                                        <Radio color="primary" className="radio" checked={this.state.selectedFormat == 'monthday'} onChange={() => this.setState({selectedFormat: 'monthday'})} value={'on'} name={'resource-recurrence-selected-format'} />
                                        {
                                            this.state.freq == 'yearly' && (
                                                <OutlinedField  
                                                    select
                                                    className="full"
                                                    label={this.tr("On month")}
                                                    disabled={this.state.selectedFormat != 'monthday'}
                                                    error={false}
                                                    value={this.state.month.value}
                                                >{
                                                    this.months.map(el => (
                                                        <MenuItem key={el.value} value={el.value} onClick={() => this.setValue('month', el)}>{el.label}</MenuItem>
                                                    ))
                                                }
                                                </OutlinedField>
                                            )
                                        }                                    
                                        <OutlinedField 
                                            select
                                            className="full"
                                            label={this.tr("On day")}
                                            disabled={this.state.selectedFormat != 'monthday'}
                                            error={false}
                                            value={this.state.monthday}
                                        >{
                                            Array.from(new Array(31), (x, i) => i + 1).map(el => (
                                                <MenuItem key={el.value} value={el} onClick={() => this.setValue('monthday', el)}>{el}</MenuItem>
                                            ))
                                        }
                                        </OutlinedField>                        
                                    </div>
                                    
                                        <div className="on-the selection-container full">
                                            <Radio color="primary" className="radio" checked={this.state.selectedFormat == 'countday'} onChange={() => this.setState({selectedFormat: 'countday'})} value={'onThe'} name={'resource-recurrence-selected-format'} />
                                            <OutlinedField 
                                                select
                                                className="full"
                                                label={this.tr("On the")}
                                                disabled={this.state.selectedFormat != 'countday'}
                                                error={false}
                                                value={this.state.setPos.value}
                                            >{
                                                this.onTheDays.map(el => (
                                                    <MenuItem key={el.value} value={el.value} onClick={() => this.setValue('setPos', el)}>{el.label}</MenuItem>
                                                ))
                                            }
                                            </OutlinedField>
                                            <OutlinedField 
                                                select
                                                className="full"
                                                label={this.tr("Weekday")}
                                                disabled={this.state.selectedFormat != 'countday'}
                                                error={false}
                                                value={singleWeekday}
                                            >{
                                                this.weekdays.map(el => (
                                                    <MenuItem key={el.value} value={el.value} onClick={() => this.setSingleWeekday(el.value)}>{el.label}</MenuItem>
                                                ))
                                            }
                                            </OutlinedField>
                                            {
                                                this.state.freq == 'yearly' && (
                                                    <React.Fragment>
                                                        <span className="separator">{this.tr('of')}</span>
                                                        <OutlinedField  
                                                            select
                                                            className="full"
                                                            label={this.tr("On month")}
                                                            disabled={this.state.selectedFormat != 'countday'}
                                                            error={false}
                                                            value={this.state.month.value}
                                                        >{
                                                            this.months.map(el => (
                                                                <MenuItem key={el.value} value={el.value} onClick={() => this.setValue('month', el)}>{el.label}</MenuItem>
                                                            ))
                                                        }
                                                        </OutlinedField>
                                                    </React.Fragment>
                                                )
                                            }                                    
                                        </div>                                                                       
                                </React.Fragment>
                            )
                        }
                        {
                            this.state.freq == 'weekly' && (
                                <div className="weekdays">
                                    {
                                        this.weekdays.filter(el => !el.isComposite).map( el => (
                                            <div key={el.value} className={this.state.selectedWeekDays[el.value] ? 'selected' : ''} onClick={() => this.setWeekDay(el.value)}>{el.label.substring(0,1)}</div>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>                   
                    <h3 className="full ends-header">{this.tr("Ends")}</h3>
                    <div className="ends-container">
                        
                            <FormControlLabel 
                                value="never"
                                className="radio never-radio"
                                control={<Radio color="primary" checked={this.state.ends == 'never'} name="recurrence-ends" onChange={() => this.setState({ends: 'never'})} />}
                                label={this.tr("Never")}
                            />                            
                            <FormControlLabel 
                                value="onDate" 
                                className="radio date-radio"
                                control={<Radio color="primary" checked={this.state.ends == 'onDate'} name="recurrence-ends" onChange={() => this.setState({ends: 'onDate'})} />} 
                                label={this.tr("On")}
                             />                              
                            <FormControlLabel 
                                value="after" 
                                className="radio after-radio"
                                control={<Radio color="primary" checked={this.state.ends == 'after'} name="recurrence-ends" onChange={() => this.setState({ends: 'after'})} />} 
                                label={this.tr("After")}
                             />                                              
                
                        <DatePicker
                            disabled={this.state.ends != 'onDate'}
                            className="on-date-picker date"
                            label={this.tr("Date")}
                            name="endDate"
                            date={this.state.endDate}
                            onChange={(date) => this.setValue('endDate', date)}
                            onInputChange={(date) => this.setValue('endDate', date)}
                            dateFormat={userObject.dateFormat}
                        />
                        <OutlinedField 
                            label={this.tr("After")}
                            disabled={this.state.ends != 'after'}
                            error={false}
                            name="after"
                            className="after-picker"
                            onChange={this.onChange}
                            value={this.state.after}
                        />
                        <span className="after-picker-explanation">{this.tr('occurrences')}</span>
                    </div>
                    
                </DialogSection>


            </DialogBorder>
        )
    }
}