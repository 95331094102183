import React, { Component } from 'react';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import TaimerComponent from '../../TaimerComponent';
import ImportHourEntriesReviewListRow from './ImportHourEntriesReviewListRow';
import List from '../../list/List';

/* other */
import cn from 'classnames';

/* css */
import styles from './ImportQuoteRowsReview.module.scss';

class ImportHourEntriesReview extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
        data: [],
    }
    constructor(props, context) {
        super(props, context, "dialogs/imports/ImportHourEntriesReview");

        const common = {
            width: 200, showMenu: false, resizeable: true, showResizeMarker: false, moveable: false, hideable: false, 
        };

        this.fields = [
            { ...common, field: "user_email", name: "user_email", header: this.tr("User email"), width: 150 },
            { ...common, field: "project_number", name: "project_number", header: this.tr("Project number"), width: 100 },
            { ...common, field: "company", name: "company", header: this.tr("Project's company"), width: 100 },
            { ...common, field: "date", name: "date", header: this.tr("Date"), width: 60 },
            { ...common, field: "start_time", name: "start_time", header: this.tr("Start time"), width: 50 },
            { ...common, field: "hours", name: "hours", header: this.tr("Hours"), width: 60 },
            { ...common, field: "jobtype", name: "jobtype", header: this.tr("Jobtype"), width: 60 },
            { ...common, field: "task", name: "task", header: this.tr("Task"), width: 60 },
            { ...common, field: "description", name: "description", header: this.tr("Description"), width: 60 },

        ].filter(x => x);
    }

    render() {
        return (
            <div className={styles.root}>
                <div className={cn(styles.top, styles.section)}>
                    <div className={styles.reviewList}>
                    <List
                        virtualized={true}
                        ref={this.list}
                        data={this.props.data}
                        columns={this.fields}
                        height={448}
                        minHeight={448}
                        trimHeight={-10}
                        rowHeight={44}
                        className={styles.list}
                        listRowType={ImportHourEntriesReviewListRow}
                        noStateData
                    />
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportHourEntriesReview;