import React from "react";

import isEqual from "lodash/isEqual"; 
import cloneDeep from "lodash/cloneDeep";

class FreezableInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: this.props.values
        };

        this.backspaceDown           = false;
        this.selectionStartOnKeyDown = undefined;

        this.focus                            = this.focus.bind(this)
        this.getValueUnderCursor              = this.getValueUnderCursor.bind(this);
        this._handleChange                    = this._handleChange.bind(this);
        this.handleChangeWhenFreezingDisabled = this.handleChangeWhenFreezingDisabled.bind(this);
        this.handleChange                     = this.handleChange.bind(this);
        this.handleKeyDown                    = this.handleKeyDown.bind(this);
        this.handleKeyUp                      = this.handleKeyUp.bind(this);
        this.handleBackspace                  = this.handleBackspace.bind(this);
        this.handleInputFocus                 = this.handleInputFocus.bind(this);
        this.setValue                         = this.setValue.bind(this);
        this.getValue                         = this.getValue.bind(this);
        this.getRightMostValue                = this.getRightMostValue.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        if(!isEqual(prevState.values, this.state.values)) {
            this.props.onChange(this.state.values);

            const { innerRef } = this.props;

            if(innerRef.current !== null) {
                innerRef.current.scrollLeft = innerRef.current.scrollWidth;
            }
        }

        if(!isEqual(this.props.values, prevProps.values) && !isEqual(this.props.values, this.state.values)) {
            this.setState({ values: this.props.values });
        }
    }


    focus() {
        if(!this.props.innerRef.current) {
            return;
        }

        this.props.innerRef.current.focus({ preventScroll: true });
    }


    getValueUnderCursor(cursorPosition) {
        const value = this.state.values.map(o => o.value).join(this.props.delimiter);

        if(cursorPosition === value.length) {
            return this.state.values[this.state.values.length - 1];
        }

        const delimiterLength = this.props.delimiter.length;
        const activeValue     = this.state.values.find((v, i, arr) => {
            const currentLength = arr.slice(0, ++i).reduce((acc, cur) => acc + cur.value.length + delimiterLength, 0);

            return cursorPosition <= currentLength;
        });

        return activeValue;
    }


    _handleChange(e) {
        e.persist();

        if(this.props.disableFreezing) {
            this.handleChangeWhenFreezingDisabled(e);
        } else {
            this.handleChange(e); 
        }
    }


    handleChangeWhenFreezingDisabled(e) {
        const delimiter = this.props.delimiter.trim();
        const values    = e.target.value
            .split(delimiter)
            .map(v => v.trimStart())
            .map((v, i) => {
                const currentValueAtIndex = this.state.values.length - 1 >= i
                    ? this.state.values[i]
                    : {};

                return { ...currentValueAtIndex, value: v, frozen: false };
            });

        this.setState({ values: values });
    }


    handleChange(e) {
        const activeValue    = this.getValueUnderCursor(this.selectionStartOnKeyDown);
        const delimiterAtEnd = e.target.value.trim().lastIndexOf(this.props.delimiter.trim()) === e.target.value.length - 1;
        let values           = cloneDeep(this.state.values);

        if(e.target.value === "") {
            this.setState({
                values: [{ value: "", frozen: false }]
            });
        } else if(!this.backspaceDown && delimiterAtEnd && values[values.length - 1].value.trim().length > 0) {
            values.push({ value: "", frozen: false });

            this.setState({ values });
        } else if(!delimiterAtEnd && activeValue && !activeValue.frozen) {
            let valueIndex = values.findIndex(v => v.value === activeValue.value);

            values[valueIndex].value = e.target.value.split(this.props.delimiter)[valueIndex];
            values                   = values.map(v => ({
                ...v,
                value: v.value.trimStart()
            }));

            this.setState({ values });
        } else if(!this.backspaceDown) {
            const value = e.target.value
                .split(this.props.delimiter)
                .map((v, i, arr) => i < arr.length - 1 ? v.trim() : v);

            value.forEach((v, i) => {
                if(!values.hasOwnProperty(i)) {
                    values[i] = { value: value[i], frozen: false };
                } else {
                    values[i].value = value[i];
                }
            });

            this.setState({ values });
        }
    }


    handleKeyDown(e) {
        this.selectionStartOnKeyDown = e.target.selectionStart;

        // Backspace or delete, the functionality 
        // should be the same, essentially.
        if(e.keyCode === 8 || e.keyCode === 46) {
            this.backspaceDown = true;
        } else if(e.keyCode !== 188 
            && 
            ((32 < e.keyCode && e.keyCode < 127) || (127 < e.keyCode && e.keyCode <= 255)) 
            && 
            this.state.values.length > 0 
            && 
            this.state.values[this.state.values.length - 1].frozen) {
            e.persist();

            this.setValue({ frozen: false, value: "" }, undefined, () => {
                this.handleKeyUp(e);
            });
        }
    }


    handleKeyUp(e) {
        if(e.keyCode === 8 || e.keyCode === 46) {
            this.handleBackspace();
        }

        this.props.onKeyUp(e);
    }


    handleInputFocus() {
        this.props.onFocus(this.state.values);
    }


    handleBackspace() {
        this.backspaceDown = false;

        const activeValue = this.getValueUnderCursor(this.selectionStartOnKeyDown);

        if(!activeValue || (!activeValue.frozen && activeValue.value)) {
            return false;
        }

        this.setState({
            values: this.state.values.filter(v => v.value !== activeValue.value)
        });
    }


    setValue(value, index = undefined, callback = () => {}) {
        const values  = cloneDeep(this.state.values);
        const current = cloneDeep(values[values.length - 1]);

        if(index === undefined) {
            values.push(value);
        } else {
            values[index] = value; 
        }

        if(current && typeof(current) === "object" && !current.frozen) {
            values.push(current);
        }

        this.setState({ values }, callback);
    }


    getValue() {
        return cloneDeep(this.state.values);
    }


    getRightMostValue() {
        const values = this.getValue();

        if(values.length === 0) {
            return { frozen: false, value: "" };
        }

        return values[values.length - 1];
    }


    render() {
        return (
            <input
                ref={this.props.innerRef}
                value={this.state.values.map(o => o.value).join(this.props.delimiter)}
                onChange={this._handleChange}
                onKeyDown={this.handleKeyDown}
                onKeyUp={this.handleKeyUp}
                onFocus={this.handleInputFocus}
                {...this.props.inputProps}
                type="text" 
            />
        );
    }
}

FreezableInput.defaultProps = {
    delimiter: ", ",
    disableFreezing: false,
    inputProps: {},
    onChange: () => {},
    values: []
};

export default FreezableInput;
