import React from 'react';
import PropTypes from "prop-types";
import TaimerComponent from "../TaimerComponent";
import Button from '@mui/material/Button';

import { ReactComponent as CollaborateOverlay } from './CollaborateOverlay.svg';

/* css */
import './CollaborateView.css';


/* data backend */
import DataHandler from './../general/DataHandler';
import NoPermissionOverlay from '../overlays/NoPermissionOverlay';

class CollaborateView extends TaimerComponent {
    

    constructor(props, context) {
        super(props, context, "src/collaborate/CollaborateView");
        this.state = {
            apikey: false,
            showAddLead: false,
            hasPrivilege: true,
        };



        ['getLogin']
        .forEach(e => this[e] = this[e].bind(this));
    }


    componentDidMount() {
        super.componentDidMount();
        this.getLogin();
    }

    getLogin() {
        DataHandler.get({url: `collaborate/login_details`}).done(data => {
            let state = this.state;
            state.apikey = data.apikey;
            if(data.no_extranet > 0) {
                state.showAddLead = true;
            }
            this.setState(state);
        }).fail(() => {
            this.setState({hasPrivilege: false});
        });
    }



    render() {
        const { tr } = this;
        const { functions: { checkPrivilege } } = this.context;

        if (!this.state.hasPrivilege) {
            return <NoPermissionOverlay />;
        }

        if(!this.state.apikey) {
            return (<div></div>);
        }
        if(this.state.showAddLead) {
            return (
                <div className={`collaborate-overlay ${this.props.noShadow ? 'no-shadow' : ''}`}>
                    <CollaborateOverlay />
                    <div className="text">
                        {tr("Add your first Lead to create a board.")}
                    </div>
                    <Button
                        color="primary"
                        onMouseDown={() => this.props.updateView({module: 'projects', action: 'view'})}
                    >
                    {tr("ADD LEAD")}
                    </Button>
                 </div>);
        }
        const singleParam = this.props.single ? '&single_extranet=1' : '';
        const collaborateParam = this.props.extranetsId ? '&extranets_id=' + this.props.extranetsId : '';
        let src;
        if(false) { /* devi */
            src = `http://localhost/taimer/extranet/login_apikey?auth_key=${this.state.apikey}`;
        } else {
            src = `https://boards.psa.heeros.com/login_apikey?auth_key=${this.state.apikey}`;
        }
        src += singleParam;
        src += collaborateParam;
        return (
            <div className={`collaborate-container ${this.props.noShadow ? 'no-shadow' : ''}`}>
                <iframe src={src}></iframe>
            </div>

        );
    }
}

export default CollaborateView;
