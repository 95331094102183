import React from "react";
import InsightTable, { InsightTableListRow } from "../../InsightTable";
import ListCell from "../../../../list/ListCell";
import { useState } from "react";
import InsightDropDown from "../../InsightDropDown";
import ListRow from "../../../../list/ListRow";

class PipelineByOwnerCell extends InsightTableListRow {
  defineCells() {
    const {
      sharedData: { currency },
      data,
    } = this.props;
    const {
      functions: { presentCurrency },
    } = this.context;
    let otherCells = {};
    Object.keys(data.values_by_stage_id).forEach((key) => {
      otherCells[key] = (
        <ListCell alignCell={true} onlyDisplay={true}>
          <div style={{ paddingLeft: 16 }}>
            <div className="pipeline-by-owner-cell">
              {presentCurrency(
                data.values_by_stage_id[key].value || 0,
                currency
              )}
              <div className="sub-values">
                {data.values_by_stage_id[key].count}
                <span className="percent-value">
                  {data.values_by_stage_id[key].percent} %
                </span>
              </div>
            </div>
          </div>
        </ListCell>
      );
    });

    let cells = {
      ...super.defineCells(),
      account_group: (
        <ListCell editable={false} value={data.name || "–"} />
      ),
      account_manager: (
        <ListCell editable={false} value={data.account_manager || "–"} />
      ),
      project_manager: (
        <ListCell editable={false} value={data.project_manager || "–"} />
      ),
      sub_unit: (
        <ListCell editable={false} value={data.customers_name || "–"} />
      ),
      ...otherCells,
    };

    return cells;
  }
}

const COLUMN_SETTINGS = {
  width: 200,
  resizeable: false,
  moveable: false,
  hideable: false,
  showMenu: false,
};

class PipelineByOwnerSummaryRow extends ListRow {
  constructor(props, context) {
    super(
      props,
      {},
      {},
      "dashboard/insights/sales/components/PipelineByOwnerSummaryRow"
    );
  }

  defineClassName() {
    return "pipelineByOwnerSummaryRow";
  }

  defineCells() {
    const {
      rows,
      sharedData: { currency },
    } = this.props;

    const {
      taimerAccount,
      functions: { presentCurrency },
    } = this.context;

    let values = {};
    rows.map((row) => {
      Object.keys(row.data.values_by_stage_id || {}).forEach((key) => {
        values[key]
          ? (values[key] += row.data.values_by_stage_id[key].value)
          : (values[key] = row.data.values_by_stage_id[key].value);
      });
    });

    let cells = {};

    Object.keys(this.props.columnConfig).forEach((key) => {
      cells[key] = (
        <ListCell
          width={this.props.columnConfig[key].width}
          className="totalcell"
          name="user"
          onlyDisplay
          editable={false}
          alignCell
        >
          {presentCurrency(values[key] || 0)}
        </ListCell>
      );
    });

    const totalColumns = [
      "user",
      "account",
      "account_manager",
      "account_group",
      "project_manager",
      "sub_unit",
    ];

    totalColumns.forEach((col) => {
      cells[col] = (
        <ListCell
          width={(this.props.columnConfig[col] || {}).width || 0}
          className="totalcell"
          name={col}
          onlyDisplay
          editable={false}
          alignCell
        >
          {this.tr("Total")}
        </ListCell>
      );
    });

    return cells;
  }

  render() {
    const cells = this.defineCells();
    const className = [
      "summaryRow listElement row flex",
      this.defineClassName(),
    ].join(" ");

    return (
      <div className={className} style={{ height: `50px` }}>
        {this.props.columnOrder.map((columnName) => cells[columnName])}
      </div>
    );
  }
}

const PipelineByOwner = (props) => {
  const { tr, currency, data, showSlider, ...otherProps } = props;
  const [mode, setMode] = useState("user");

  const getFirstColumn = (mode) => {
    switch (mode) {
      case "user":
        return {
          field: "user",
          name: "user",
          header: tr("User"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      case "account":
        return {
          field: "account",
          name: "account",
          header: tr("Account"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      case "account_manager":
        return {
          field: "user",
          name: "user",
          header: tr("Account Manager"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      case "account_group":
        return {
          field: "account_group",
          name: "account_group",
          header: tr("Account Group"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      case "project_manager":
        return {
          field: "user",
          name: "user",
          header: tr("Project Manager"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      case "account":
        return {
          field: "account",
          name: "account",
          header: tr("Account"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      case "sub_unit":
        return {
          field: "sub_unit",
          name: "sub_unit",
          header: tr("Sub unit"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      default:
        return null;
    }
  };

  const columns = [
    getFirstColumn(mode),
    ...data.stages.map((stage) => ({
      field: stage.id,
      name: stage.id,
      header: stage.name,
      ...COLUMN_SETTINGS,
    })),
    {
      field: "total",
      name: "total",
      header: tr("Total"),
      ...COLUMN_SETTINGS,
    },
  ];

  const tabs = [
    {
      key: "user",
      label: tr("Owner"),
      action: () => setMode("user"),
    },
    {
      key: "account_manager",
      label: tr("Account Manager"),
      action: () => setMode("account_manager"),
    },
    {
      key: "account_group",
      label: tr("Account Group"),
      action: () => setMode("account_group"),
    },
    {
      key: "project_manager",
      label: tr("Project Manager"),
      action: () => setMode("project_manager"),
    },
    {
      key: "account",
      label: tr("Account"),
      action: () => setMode("account"),
    },
    {
      key: "sub_unit",
      label: tr("Sub unit"),
      action: () => setMode("sub_unit"),
    },
  ];

  return (
    <InsightTable
      {...otherProps}
      filterComponents={<InsightDropDown tabs={tabs} selected={mode} />}
      height={400}
      hideDefaults={true}
      width={6}
      className="pipeline-by-owner"
      summaryRowType={PipelineByOwnerSummaryRow}
      blockProps={{
        largeTitle: true,
        rootClassName: "block",
        wrapperClass: "block-wrapper",
        headerClass: "block-header",
      }}
      sliderTitleColumn="users_name"
      title={tr("Pipeline by Owner & Account")}
      rowType={PipelineByOwnerCell}
      data={data.data[mode]}
      sliderColumns={[
        "project",
        "account",
        "user",
        "value",
        "probability",
        "funnel",
      ]}
      columns={columns}
      currency={currency}
      sharedData={{
        currency,
      }}
      showSlider={showSlider ? (item) => showSlider(mode, item) : undefined}
    />
  );
};

export default PipelineByOwner;
