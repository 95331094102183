import React from 'react';
import OutlinedField from "./../../general/OutlinedField";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';


class DeleteSearchQuery extends React.Component {

    constructor(props) {
        super(props);
    }

    handleCancel = () => {
        this.props.onDialogClose();
    }

    save = () => {     
        const { id, saveFunc } = this.props.data;
        this.props.onDialogSave(saveFunc, id);
    }

    render() {

        const { text } = this.props.data;

        return (
            <Dialog
                open
                className="deleteQueryDialog"
                maxWidth="sm"
                aria-labelledby="deleteQuery" >
                <DialogContent className="deleteQueryDialogContent" ref={cont => this.container = cont}>
                    <p>{text}</p>
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="outlined" onClick={this.handleCancel} >
                        Cancel
                    </Button>
                    <Button size="large" variant="outlined" onClick={this.save} color="secondary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

DeleteSearchQuery.defaultProps = {
    onDialogClose: () => {},
    onDialogSave: () => {}
}

export default DeleteSearchQuery;
