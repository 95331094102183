import React from 'react'

/* css */
import './ActivitiesInsightFilter.css';

/* material-ui */
//

/* others */
import _ from 'lodash';
import { format } from "date-fns";
import MultiSelect from '../../../general/MultiSelect';
import TaimerComponent from "../../../TaimerComponent";
import DataList from '../../../general/DataList';
import DataHandler from '../../../general/DataHandler';
import ReactSelect from 'react-select';
import { SettingsContext } from '../../../../src/SettingsContext';
import { DateRangePicker } from './../../../general/react-date-range/src';
import { endOfMonth, startOfMonth, addMonths } from "date-fns";

class ActivitiesInsightFilter extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context){
        super(props, context, "dashboard/insights/activities/ActivitiesInsightFilter");
        const dateRange = {start: addMonths(startOfMonth(new Date()), -10), end: addMonths(endOfMonth(new Date()), 1)};
        //const dateRange = {start: null, end: null};
        this.timeout =  0;
        //const dateRange = {start: null, end: null, key: "selection"};

        this.state = {
            listOpen: true,
            data: {},
            elementWidth: 10000,
            elementWidthSet: false,
            loaded: false,
            autoCompleteData: false,
            team_reset: false,
            targetDefaultDone: false,
            company:    [{value: "0", label: this.tr("All")}],
            targetedTo: [{value: context.userObject.usersId + "", label: context.userObject.fullname}],
            team:       {id: "0", name: this.tr("All")},
            createdBy:  [{value: "0", label: this.tr("All")}],
            account:    [{value: "0", label: this.tr("All")}],
            status:     [{value: "0", label: this.tr("All")}],
            type:       [{value: "0", label: this.tr("All")}],
            team:       [],
            team_users: false,
            dateRange: {
                startDate: dateRange.start,
                endDate: dateRange.end,
                valid: true,
                key: "selection"
            },
            filterData: {
                company:    [{value: "0", label: this.tr("All")}],
                targetedto: [context.userObject.usersId],
                startDate:  format( addMonths(startOfMonth(new Date()), -10), "YYYY-MM-DD"),
                endDate:    format( addMonths(endOfMonth(new Date()), 1), "YYYY-MM-DD"),
                companies: [],
                hasReadPermission: true,
                hasWritePermission: false,
            },
            autoCompleteData: {},
            currentAccounts: []
        }
    }

    /**
     * Gets autocompletedata for filter
     *
     */
    getAutocompleteData = () => {
        DataHandler.get({url: 'activities/autocompletedata'}).done(data => {

            data.users.forEach(u => {if (u.company < 1) u.label += ` (${this.tr('Freelancer')})`});
            
            let filterData = {...this.state.filterData};
            filterData.companies = data.companies.map(el => {el.value = el.id; return el;});
            filterData.hasReadPermission = data.companies.length > 0;
            filterData.hasWritePermission = data.has_write_permission;
            this.setState({autoCompleteData: data, filterData: filterData, currentAccounts: data.accounts}, () => {
                    let filterData = this.state.filterData;
                    filterData.company = [0];
                    this.props.onFilterChange(filterData);
                });
        }).fail(response => {});
    }
    
    componentDidMount(){
        super.componentDidMount();
        this.getAutocompleteData();
        this.props.onRef(this);
    }


    //Resets filters selectors and search data to default.
    resetFilter = () => {
        const dateRange = {start: null, end: null};
        const selectorsData = { 
            targetedTo: [{value: this.context.userObject.usersId, label: this.context.userObject.fullname}],
            createdBy:  [{value: "0", label: this.tr("All")}],
            account:    [{value: "0", label: this.tr("All")}],
            status:     [{value: "0", label: this.tr("All")}],
            type:       [{value: "0", label: this.tr("All")}],
            team:       [],
            dateRange: {
                startDate: dateRange.start,
                endDate: dateRange.end,
                valid: true,
                key: "selection"
            },
        };

        const filterData = { 
            targetedto: [this.context.userObject.usersId],
            startDate:  format( addMonths(startOfMonth(new Date()), -10), "YYYY-MM-DD"),
            endDate:    format( addMonths(endOfMonth(new Date()), 1), "YYYY-MM-DD"),
        };

        this.setState({filterData: filterData, ...selectorsData});
        this.props.onFilterChange(filterData);
    }

    onDateChange = (event) => {

        let { startDate, endDate } = event.selection;
        let { filterData } = this.state;

        startDate = format(startDate, "YYYY-MM-DD");
        endDate = format(endDate, "YYYY-MM-DD");
        filterData = {...filterData, startDate, endDate};

        this.setState({
            filterData: filterData, 
            dateRange: {
                startDate: startDate,
                endDate: endDate,
                key: "selection"
            }
        }, () => this.props.onFilterChange(filterData));

    };

    onDateInputChange = (dateType, date) => {

        let { filterData } = this.state;
        const { startDate, endDate } = this.state.dateRange;
        date = format(date, "YYYY-MM-DD");

        if (dateType == "start") {
            filterData.startDate = date;
            this.setState({
                dateRange: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection"
                }
            }, () => this.props.onFilterChange(filterData));
        } else {
            filterData.endDate = date;
            this.setState({
                dateRange: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection"
                }
            }, () => this.props.onFilterChange(filterData));
        }
    };

    render() {
        const { userObject } = this.context;
        const { autoCompleteData, listOpen, dateRange, targetedTo, createdBy, account, status, type, team, team_users, company } = this.state;

        const statusItems = [
            /* {value: "0", label: this.tr("All")}, */
            {value: "1", label: this.tr("Done")},
            {value: "2", label: this.tr("Due")},
            {value: "3", label: this.tr("Overdue")},
        ]
          
        return (
            <div className={"grid-container filter-block " + (!listOpen && "closed")} id="insights-activities-filter-main">
                <div className="filtercontainer">
                    <div className="filtergroup">
                        {this.context.taimerAccount.isMulticompany && <MultiSelect 
                            className="company"
                            width="150px"
                            options={ this.state.filterData.companies }
                            skipInitialChange={true}
                            defaultValue={ company }
                            label={this.tr("Company")}
                            onChange={(data) => {
                                let newdata = [];
                                data.forEach((val, i)=> {
                                    newdata.push(val.value)
                                })
                                let currentAccounts = [...this.state.autoCompleteData.accounts];
                                if(newdata[0] != 0) {
                                    currentAccounts = currentAccounts.filter(el => newdata.some(n => el.companies.includes(n)))
                                }
                                let newFilterData = this.state.filterData;
                                newFilterData.company = newdata;
                                if(this.state.filterData.companies.length == 0) {
                                    return;
                                }
                                this.setState({filterData: newFilterData, currentAccounts: currentAccounts});
                                this.props.onFilterChange(newFilterData);
                            }}
                        />}
                        <DateRangePicker
                            className="daterange"
                            ranges={[dateRange]}
                            onChange={this.onDateChange}
                            onInputChange={this.onDateInputChange}
                            label={this.tr("Time period")}
                            dateFormat={userObject.dateFormat}
                        />
                        <MultiSelect 
                            className="team"
                            width="150px"
                            options={autoCompleteData.team}
                            defaultValue={team}
                            skipInitialChange={true}
                            label={this.tr("Team")}
                            onChange={(data) => {
                                let a_team_users = [];

                                //If some team is selected
                                if(data.length > 0 && data[0].value != 0) {

                                    // if(this.state.team_users) a_team_users = this.state.team_users;
                                    
                                    //Add teams users to list
                                    data.forEach((val, i)=> {
                                        autoCompleteData.user_groups[val.value].forEach((user, ii)=> {
                                            let found = false;
                                            found = a_team_users.find((x) => {
                                                return x.value === user.value;
                                            });
                                            if(!found) a_team_users.push(user);
                                        }); 
                                    });

                                    this.setState({team_users: a_team_users, team_reset: true});
                                } else {
                                    this.setState({team_users: false});

                                    if(this.state.targetDefaultDone){
                                        this.setState({team_reset: true});
                                    } else {
                                        this.setState({targetDefaultDone: true});
                                    }
                                }

                                let newdata = [];
                                data.forEach((val, i)=> {
                                    newdata.push(val.value)
                                })

                                let newFilterData = this.state.filterData;
                                newFilterData.team = newdata;
                                let unewdata = [];

                                if (data.length > 0 && data[0].value != 0) {
                                    a_team_users.forEach((val, i)=> {
                                        unewdata.push(val.value)
                                    })

                                    if (unewdata.length === 0) {
                                        unewdata.push(-1);
                                    }
                                } else {
                                    // unewdata = targetedTo;
                                }

                                newFilterData.targetedto = unewdata;

                                this.setState({team_reset: true, filterData: newFilterData});
                                this.props.onFilterChange(newFilterData);
                            }}
                        />
                        <MultiSelect 
                            className="targetedto"
                            width="150px"
                            options={team_users ? team_users : autoCompleteData.users}
                            defaultValue={targetedTo}
                            returnAll
                            skipInitialChange={true}
                            label={this.tr("Targeted to")}
                            reset={this.state.team_reset}
                            resetFunction={()=>{ this.setState({team_reset: false})}}
                            resetValue={[{value: "0", label: this.tr("All")}]}
                            onChange={(data) => {

                                let newdata = [];
                                data.forEach((val, i)=> {
                                    newdata.push(val.value)
                                })

                                if (team_users && newdata.length === 0) {
                                    newdata.push(-1);
                                }

                                let newFilterData = this.state.filterData;
                                newFilterData.targetedto = newdata;
                                this.setState({filterData: newFilterData});
                                this.props.onFilterChange(newFilterData);
                            }}
                        />
                        <MultiSelect 
                            className="createdby"
                            width="150px"
                            options={team_users ? team_users : autoCompleteData.users}
                            defaultValue={createdBy}
                            label={this.tr("Created by")}
                            returnAll={team_users}
                            reset={this.state.team_reset}
                            skipInitialChange={true}
                            resetFunction={()=>{ this.setState({team_reset: false})}}
                            resetValue={[{value: "0", label: this.tr("All")}]}
                            onChange={(data) => {
                                let newdata = [];
                                data.forEach((val, i)=> {
                                    newdata.push(val.value)
                                })

                                let newFilterData = this.state.filterData;
                                newFilterData.createdby = newdata;
                                this.setState({filterData: newFilterData});
                                this.props.onFilterChange(newFilterData);
                            }}
                        />
                        <MultiSelect 
                            className="account"
                            width="150px"                            
                            options={this.state.currentAccounts}
                            defaultValue={account}
                            skipInitialChange={true}
                            label={this.tr("Account")}
                            onChange={(data) => {

                                let newdata = [];
                                data.forEach((val, i)=> {
                                    newdata.push(val.value)
                                })

                                let newFilterData = this.state.filterData;
                                newFilterData.account = newdata;
                                this.setState({filterData: newFilterData});
                                this.props.onFilterChange(newFilterData);
                            }}
                        />
                        <MultiSelect 
                            className="status"
                            width="150px"
                            options={statusItems}
                            defaultValue={status}
                            skipInitialChange={true}
                            //returnAll
                            label={this.tr("Status")}
                            onChange={(data) => {
                                let newdata = [];
                                data.forEach((val, i)=> {
                                    newdata.push(val.value)
                                })

                                let newFilterData = this.state.filterData;
                                newFilterData.status = newdata;
                                this.setState({filterData: newFilterData});
                                this.props.onFilterChange(newFilterData);

                            }}
                        />
                        <MultiSelect 
                            className="type"
                            width="150px"
                            options={this.props.activityTypes}
                            defaultValue={type}
                            skipInitialChange={true}
                            label={this.tr("Type")}
                            onChange={(data) => {
                                let newdata = [];
                                data.forEach((val, i)=> {
                                    newdata.push(val.value)
                                })

                                let newFilterData = this.state.filterData;
                                newFilterData.type = newdata;
                                this.setState({filterData: newFilterData});
                                this.props.onFilterChange(newFilterData);
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ActivitiesInsightFilter;
