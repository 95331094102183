import { ChangeCircle, Delete, Email, Print } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import { cloneDeep } from 'lodash';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';
import { ContextSubMenu } from '../general/ContextMenu';
import DataHandler from '../general/DataHandler';
import DropdownMenuButton from '../general/DropdownMenuButton';
import QuotePDFView from '../general/QuotePDFView';
import QuoteWizard from '../general/QuoteWizard';
import SliderFieldGroup from '../general/SliderFieldGroup';
import StatusTag from '../general/StatusTag';
import Utils from '../general/Utils';
import { convertDateFormat } from '../helpers';
import TaimerComponent from '../TaimerComponent';
import styles from './QuoteView.module.scss';
import SendQuoteWizard from './SendQuoteWizard';
import { getQuoteStatuses } from './TabQuotes';
import Dialog from '../dialogs/mass_operations/CoreDialog';
import CoreDialog from '../dialogs/mass_operations/CoreDialog';
const noQuoteIcon = require('./images/noQuote.svg').default;
interface QuoteViewProps extends WithSnackbarProps {
    project: any;
}
interface QuoteViewState {
    quote?: any;
    quotes: any[];
    printLanguage?: string;
    printDateFormat?: string;
    currency?: string;
    noQuotes?: boolean;
    users?: any[];
    includeProposal?: boolean;
    loadingQuote?: boolean;
}
class QuoteView extends TaimerComponent<QuoteViewProps, QuoteViewState> {
    pdfView: any = React.createRef();
    statuses: any[] = [];
    statusMap: any = {};
    constructor(props, context) {
        super(props, context, 'projects/QuoteView');
        this.statuses = getQuoteStatuses().map((q) => ({ ...q, name: this.tr(q.name), label: this.tr(q.label) }));
        this.statusMap = {};
        this.statuses.forEach((status) => (this.statusMap[status.id] = status));
        this.state = {
            quotes: [],
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.getCompanyData();
        this.getQuote();
        this.getUsers();
        window.addEventListener('quoteSaved', this.getQuote);
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
        window.removeEventListener('quoteSaved', this.getQuote);
    };

    getUsers = async () => {
        const { project } = this.props;
        let users = await DataHandler.get({ url: `subjects/employees/${project.companies_id}` });
        const userTagProps = {
            fields: { name: 'name' },
            showLocked: false,
            transl: {
                locked: this.tr('locked'),
                freelancer: this.tr('freelancer'),
            },
        };
        users = users.map((u) => ({ ...Utils.showLockedAndFreelancerUserTag(u, userTagProps) }));
        this.setState({
            users,
        });
    };

    getCompanyData = async () => {
        const {
            project: { companies_id },
        } = this.props;
        const companies = await DataHandler.get({
            url: `subjects/companies_with_project_right/read+project_cost_estimate_read`,
            currency: 1,
            date_format: 1,
            print_lang: 1,
            country_lang: 1,
            print_options: 1,
        });
        const currentCompany = companies.find((c) => c.id == companies_id);
        if (!currentCompany) return;
        const defaultPrintOptions: any = this.getDefaultPrintOptions(currentCompany);
        this.setState({
            ...defaultPrintOptions,
            currency: currentCompany.currency,
        });
    };

    getDefaultPrintOptions = (company) => {
        return {
            printLanguage: company.print_lang ? company.print_lang : company.country_lang,
            printLanguageOptions: company.print_languages || [],
            printDateFormat: company.date_format || convertDateFormat(this.context.taimerAccount.companyDateFormat, true),
            printDateOptions: company.print_date_formats || [],
        };
    };

    getQuote = async () => {
        this.setState({ loadingQuote: true }, async () => {
            const { project } = this.props;
            const quotes = await DataHandler.get({ url: `projects/${project.id}/quotes` });
            quotes?.sort((a, b) => Number(b.active) - Number(a.active));
            const quote = quotes.find((q) => q.id == this.state.quote?.id) || quotes[0];
            this.setState({
                quote,
                quotes,
                noQuotes: quotes.length == 0,
                loadingQuote: false,
            });
        });
    };

    selectQuote = (quote) => this.setState({ quote });

    getQuoteTotals = () => {
        const { quote } = this.state;
        let discount = 0;
        let cost = 0;
        let subtotal = 0;
        let vatTotal = 0;
        let vatTotals = {};
        let total = 0;
        if (!quote) return { subtotal, vatTotal, vatTotals, total };
        quote.headers.forEach((header) => {
            header.rows.forEach((row) => {
                const discountValue = parseInt(row.discountPercentage) > 0 ? (parseInt(row.discountPercentage) / 100) * Number(row.value) : 0;
                const calcValue = Number(row.value) - discountValue;
                const sum = Number(row.quantity) * calcValue;
                const sumWithoutDiscount = Number(row.value) * Number(row.quantity);
                const rowVatTotal = (Number(row.vat) / 100) * (calcValue * Number(row.quantity));
                const rowCost = parseFloat(row.cost) * parseFloat(row.quantity);
                const rowTotal = sum + rowVatTotal;
                const rowVat = Number(row.vat || 0).toFixed(2);
                const currentVatTotal = vatTotals[rowVat] || 0;
                discount += discountValue * Number(row.quantity);
                vatTotal += rowVatTotal;
                cost += rowCost;
                vatTotals = {
                    ...vatTotals,
                    [rowVat]: currentVatTotal + vatTotal,
                };
                subtotal += sumWithoutDiscount || 0;
                total += rowTotal;
            });
        });
        return { discount, cost, subtotal, vatTotal, vatTotals, total };
    };

    onEditQuoteClicked = () => {
        const { quote } = this.state;
        if (!quote) return;
        const text =
            quote.customer_action_pending == 1
                ? this.tr('This quote has been sent and is waiting for approval. If you edit it now, the quote will be pulled back from approval and set as draft.')
                : quote.status == 4
                ? this.tr(
                      "This quote has already been approved. If you edit it now, it will no longer be accessible for your client and will be set to review status. You'll need to resend the quote for approval."
                  )
                : this.tr(
                      "This quote has been declined. If you edit it now, it will no longer be accessible for your client and will be set to review status. You'll need to resend the quote for approval."
                  );
        if (Number(quote.customer_action_pending) > 0) {
            this.context.functions.showDialog(
                <CoreDialog
                    onDialogClose={this.context.functions.closeDialog}
                    onDialogSave={this.onEditQuote}
                    dialogType={'delete'}
                    dialogProps={{
                        wider: true,
                        onCloseClick: this.context.functions.closeDialog,
                        open: true,
                        close: this.context.functions.closeDialog,
                        confirmButtonClass: 'blue',
                        confirmDisabled: false,
                        header:
                            quote.customer_action_pending == 1 ? this.tr('Edit sent quote?') : quote.status == 4 ? this.tr('Edit approved quote?') : this.tr('Edit declined quote?'),
                        translatedConfirmButtonText: this.tr('Edit quote'),
                        warning: () => <p>{text}</p>,
                        onConfirm: () => {
                            this.context.functions.closeDialog();
                            this.onEditQuote();
                        },
                    }}
                />
            );
        } else {
            this.onEditQuote();
        }
    };

    onEditQuote = () => {
        this.context.functions.setOverlayComponent(<QuoteWizard quote={this.state.quote} />);
    };

    onCreateQuote = () => {
        this.context.functions.sendMixpanelEvent('create_sales_quote', {
            'origin_point': 'quote_wizard',
        });
        this.context.functions.sendMixpanelPeople('set_once', {
            'first_create_sales_quote_start': new Date().toISOString(),
        });
        this.context.functions.sendMixpanelPeople('set', {
            'last_create_sales_quote_start': new Date().toISOString(),
        });
        this.context.functions.sendMixpanelPeople('increment', {
            'lifetime_create_sales_quote': 1,
        });
        this.context.functions.setOverlayComponent(<QuoteWizard project={this.props.project?.id} />);
    };

    onSendQuote = () => {
        this.context.functions.setOverlayComponent(
            <SendQuoteWizard quote={this.state.quote} project={this.props.project} quoteTotal={this.getQuoteTotals().subtotal} currency={this.state.currency || this.context.taimerAccount.currency} />
        );
    };

    onPrintQuote = () => {
        this.context.functions.setOverlayComponent(
            <SendQuoteWizard
                quote={this.state.quote}
                project={this.props.project}
                printMode
                quoteTotal={this.getQuoteTotals().subtotal}
                currency={this.state.currency || this.context.taimerAccount.currency}
            />
        );
    };

    onDeleteQuote = () => {
        this.context.functions.showDialog(
            <Dialog
                onDialogClose={this.context.functions.closeDialog}
                onDialogSave={this.deleteQuote}
                dialogType={'delete'}
                dialogProps={{
                    wider: true,
                    onCloseClick: this.context.functions.closeDialog,
                    open: true,
                    close: this.context.functions.closeDialog,
                    confirmDisabled: false,
                    header: this.tr('Delete sales quote'),
                    translatedConfirmButtonText: this.tr('Delete'),
                    warning: () => <p>{this.tr('Are you sure you want to delete this sales quote?')}</p>,
                    onConfirm: this.deleteQuote,
                }}
            />
        );
    };

    deleteQuote = async () => {
        this.context.functions.closeDialog();
        try {
            await DataHandler.delete({ url: `projects/quotes/${this.state.quote?.id}` });
            setTimeout(() => {
                this.getQuote();
            }, 1000);
            this.props.enqueueSnackbar(this.tr('Quote deleted successfully!'), {
                variant: 'success',
            });
        } catch (err) {
            this.props.enqueueSnackbar(this.tr('Deleting quote failed!'), {
                variant: 'error',
            });
        }
    };

    checkInactiveStatus = (status) => {
        const inactiveStatuses = ['1', '5'];
        return inactiveStatuses.find((s) => s == status);
    };

    onStatusChange = (quote, status) => {
        if (status == quote.status) {
            return;
        }
        let active = quote.active;
        if (active == 0 && !this.checkInactiveStatus(status)) {
            active = 1;
        }
        this.changeStatus(quote, active, status);
    };

    changeStatus = (quote, active, status) => {
        this.updateQuoteStatus(active, status);
        DataHandler.post({ url: `projects/quotes/${quote.id}/status` }, { active: active, status: status })
            .done()
            .fail((err) => {
                let msg = this.tr('Error in saving quote status');
                if (err?.responseJSON?.error == 'INVALID_STATUS') {
                    msg = this.tr('Selected status is not allowed');
                }
                // this.props.enqueueSnackbar(msg, {
                //     variant: 'error',
                // });
                this.getQuote();
            });
    };

    updateQuoteStatus = (active, status) => {
        const quote = cloneDeep(this.state.quote);
        quote.active = active;
        quote.status = status;
        this.setState({ quote });
    };

    onShowProposalChanged = (e) => {
        this.setState({ includeProposal: e.target.checked });
    };

    render() {
        const { quote, quotes, printLanguage, printDateFormat, noQuotes, currency, users, loadingQuote } = this.state;
        const { project } = this.props;
        if (!printLanguage || !printDateFormat) {
            return null;
        }
        const totals = this.getQuoteTotals();
        return (
            <div id={styles.quoteView}>
                <div className={styles.main}>
                    {noQuotes ? (
                        <div className={styles.placeholder}>
                            <div>
                                <img src={noQuoteIcon} />
                                <h2>{this.tr('Sell something!')}</h2>
                                <p>
                                    {this.context.functions.checkPrivilege('projects', 'project_cost_estimate_write')
                                        ? this.tr('Create a sales quote to get started')
                                        : this.tr('No sales quote created yet.')}
                                </p>
                                {this.context.functions.checkPrivilege('projects', 'project_cost_estimate_write') && (
                                    <Button size="large" onClick={this.onCreateQuote}>
                                        {this.tr('Create sales quote')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={styles.preview}>
                            <div className={styles.actions}>
                                {quote?.proposals_id ? (
                                    <FormControlLabel control={<Checkbox checked={this.state.includeProposal} onChange={this.onShowProposalChanged} />} label={this.tr('Show proposal')} />
                                ) : (
                                    <div />
                                )}
                                <div>
                                    <DropdownMenuButton
                                        label={this.tr('Options')}
                                        items={[
                                            {
                                                label: this.tr('Send'),
                                                icon: <Email />,
                                                action: () => this.onSendQuote(),
                                            },
                                            {
                                                label: this.tr('Print'),
                                                icon: <Print />,
                                                action: () => this.onPrintQuote(),
                                            },
                                            {
                                                label: this.tr('Change status'),
                                                icon: <ChangeCircle />,
                                                visible: this.context.functions.checkPrivilege('projects', 'project_cost_estimate_write'),
                                                component: () => (
                                                    <ContextSubMenu title={this.tr('Change status')} icon={<ChangeCircle />}>
                                                        {this.statuses
                                                            .filter((status) => status.id != this.state.quote?.status)
                                                            .map((s) => (
                                                                <MenuItem onClick={() => this.onStatusChange(this.state.quote, s.id)}>{s.name}</MenuItem>
                                                            ))}
                                                    </ContextSubMenu>
                                                ),
                                            },
                                            {
                                                label: this.tr('Delete sales quote'),
                                                icon: <Delete />,
                                                action: () => this.onDeleteQuote(),
                                                visible: this.context.functions.checkPrivilege('projects', 'project_cost_estimate_write'),
                                            },
                                        ]}
                                    />
                                    {this.context.functions.checkPrivilege('projects', 'project_cost_estimate_write') && (
                                        <Button size="large" onClick={this.onEditQuoteClicked}>
                                            {this.tr('Edit quote')}
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <QuotePDFView
                                includeProposal={this.state.includeProposal}
                                ref={this.pdfView}
                                quoteId={loadingQuote ? undefined : quote?.id}
                                project={project}
                                printDateFormat={printDateFormat}
                                printLanguage={printLanguage}
                            />
                        </div>
                    )}
                </div>
                {noQuotes ? (
                    <div className={styles.right}>
                        <h2>{this.tr('What is a sales quote?')}</h2>
                        <p>
                            {this.htmlTr("Sales quote is a project document where you can gather all the products and services of the project with each row's cost and sales values defined.", {
                                linebreak: [<br />, <br />],
                            })}
                        </p>
                        <h2>{this.tr('What is a proposal?')}</h2>
                        <p>
                            {this.htmlTr(
                                'The proposal tool allows you to create diverse and visual proposals, with written text, images and PDFs, that you can attach together with your sales quote.',
                                {
                                    linebreak: [<br />, <br />],
                                }
                            )}
                        </p>
                        <h2>{this.tr('Sending your offer')}</h2>
                        <p>
                            {this.htmlTr(
                                'You can also send your sales quote & proposal to your client. Your client will get an email and can approve or decline the offer. We will keep you up-to-date about your clients actions.',
                                {
                                    linebreak: [<br />, <br />],
                                }
                            )}
                        </p>
                    </div>
                ) : (
                    <div className={styles.right}>
                        <div className={styles.top}>
                            <div>
                                <h2>{quote?.name}</h2>
                                <p>
                                    {this.context.functions.presentCurrency(totals.subtotal, currency)} ({this.tr('VAT 0%')})
                                </p>
                            </div>
                            <StatusTag text={this.statusMap[quote?.status]?.name} color={this.statusMap[quote?.status]?.color} />
                        </div>
                        <SliderFieldGroup
                            editingDisabled
                            items={[
                                {
                                    receiver: quote?.editedAddress?.custom_contact,
                                    sender: users?.find((u) => u.id == quote?.sender_contact_id)?.label || '',
                                    sendDate: quote?.sent_date,
                                    validUntil: quote?.valid_until,
                                },
                            ]}
                            fields={[
                                {
                                    key: 'receiver',
                                    title: this.tr('Receiver'),
                                },
                                {
                                    key: 'sender',
                                    title: this.tr('Sender'),
                                },
                                {
                                    key: 'sendDate',
                                    title: this.tr('Send date'),
                                    type: 'date',
                                },
                                {
                                    key: 'validUntil',
                                    title: this.tr('Valid until'),
                                    type: 'date',
                                },
                            ]}
                        />
                        {quotes.length > 1 && (
                            <div className={styles.quotes}>
                                <h3>{this.tr('Quote versions')}</h3>
                                <ul>
                                    {quotes?.map((q) => (
                                        <li className={quote.id == q.id ? styles.selected : ''} onClick={() => this.selectQuote(q)}>
                                            <h4>{q.name}</h4>
                                            {quote.id == q.id && <p>{this.tr('Currently active')}</p>}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default withSnackbar(QuoteView);
