import React from "react";

/* css */
import "./CategoryValueTable.css";

import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

/* others */
import _ from "lodash";
import TaimerComponent from "../../TaimerComponent";
import InsightBlock from "./InsightBlock";
import { stringToColour } from "../../helpers";
import InsightDropDown from "./InsightDropDown";
import MyDayDateRangeModal from "../my_day/components/MyDayDateRangeModal";
import moment from "moment";
import cn from 'classnames';
import colors from "../../colors";

const COLORS = ["#7b4bc8", "#f9669e", "#2d9ff7", colors.greenish_cyan];

class CategoryValueTable extends TaimerComponent {
	static defaultProps = {
		width: 3,
		visible: true,
		dateRange: {
			startDate: moment(),
			endDate: moment(),
		},
		sliderExtra: {},
	};

	constructor(props, context) {
		super(props, context, "dashboard/insights/CategoryValueTable");
		this._dropdown = React.createRef();
		// this.state = {
		// 	dateRange: {
		// 		startDate: moment(),
		// 		endDate: moment()
		// 	}
		// };
	}

	_setDates = dateRange => {
		const momentDateRange = {
			startDate: moment(dateRange.startDate),
			endDate: moment(dateRange.endDate)
		};
		this.props.getData && this.props.getData(momentDateRange);
		this._dropdown.current && this._dropdown.current.close();
		// this.setState({
		// 	dateRange: momentDateRange
		// });
	};

	_renderDatePicker = () => {
		const dateRange = {
			startDate: this.props.dateRange.startDate.toDate(),
			endDate: this.props.dateRange.endDate.toDate(),
			key: "selection"
		};
		return (
			<MyDayDateRangeModal
				initialDateRange={dateRange}
				mode={"daterange"}
				saveChanges={this._setDates}
			/>
		);
	};

	clickRow = (row) => {
		const { onClickRow, sliderExtra } = this.props;

		onClickRow && onClickRow({...row, ...sliderExtra});
	}

	render() {
		const {
			data,
			extraCols,
			height,
			loaded,
			currency,
			types,
			useDateSelection,
			colTitle,
			onClickRow,
			...props
		} = this.props;
		const { tr } = this;

		const { taimerAccount, userObject } = this.context;

		const currencyFormatter = new Intl.NumberFormat(
			taimerAccount.numberFormat,
			{
				style: "currency",
				currency
			}
		).format;

		const total = _.sum(_.map(data, "sum"));

		return (
			<InsightBlock
				className="categoryTableBlock"
				{...props}
				filterComponents={
					useDateSelection && (
						<InsightDropDown
							ref={this._dropdown}
							customComponent={this._renderDatePicker()}
							selected={"date"}
							tabs={[
								{
									key: "date",
									label: `${this.props.dateRange.startDate.format(
										userObject.dateFormat
									)} - ${this.props.dateRange.endDate.format(
										userObject.dateFormat
									)}`
								}
							]}
						/>
					)
				}
				wrapperClass="insights-category-value-table"
			>
				<div className="insights-table">
					<Table className="summary">
						<TableHead>
							<TableRow>
								<TableCell className="title">
									{colTitle || this.tr("Category")}
								</TableCell>
								<TableCell align="right" className="title">
									{this.tr("Value")}
								</TableCell>
								<TableCell align="right" className="title">
									{this.tr("% of total")}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{_.map(data, (val, i) => {
								return (
									types[i] && (
										<React.Fragment key={types[i].id}>
											<TableRow className={cn("row", onClickRow && "row-clickable")} onClick={() => this.clickRow(types[i])}>
												<TableCell className="label">
													<div title={types[i].title}>
														{/* <div className="ball-indicator" style={{ background: types[i].color }}></div> */}
														<span>{types[i].title}</span>
													</div>
												</TableCell>
												<TableCell align="right" className="number-value">
													<span className="number">
														{currencyFormatter(val.sum)}
													</span>
												</TableCell>
												<TableCell align="right" className="number-value">
													<span>
														{total ? Math.round((val.sum / total) * 100) : 0}%
													</span>
												</TableCell>
											</TableRow>
											<TableRow className={cn("row", "row-bar", onClickRow && "row-clickable")} onClick={() => this.clickRow(types[i])}>
												<TableCell colSpan={3}>
													<div
														className=""
														style={{
															background:
																(types[i].color ||
																	COLORS[i] ||
																	stringToColour(types[i].title)) + "10"
														}}
													>
														<div
															style={{
																background:
																	types[i].color ||
																	COLORS[i] ||
																	stringToColour(types[i].title),
																width:
																	(total
																		? Math.round((val.sum / total) * 100, 2)
																		: 0) + "%"
															}}
														></div>
													</div>
												</TableCell>
											</TableRow>
										</React.Fragment>
									)
								);
							})}
						</TableBody>
					</Table>
				</div>
			</InsightBlock>
		);
	}
}

export default CategoryValueTable;
