import React from 'react';
import './Integration.css';
import './Maventa.css';
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import TaimerComponent from "../../TaimerComponent";
import DataHandler from '../../general/DataHandler';
import OutlinedField from '../../general/OutlinedField';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import { SettingsContext } from '../../SettingsContext';

class Eventos extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Heeros");

        this.state = {
            activated: 0,
            access_token: ""
        };

        this.dialogs = {
            confirmation: ConfirmationDialog
        };

        this.integration = "eventos";
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
    }

    getAuthData = () => {
        DataHandler.get({url: `integrations`, company_id: this.props.company, integration: this.integration}).done(response => {
            this.setState(response);
        });
    }

    saveAuth = () => {
        const data = {
            access_token: this.state.username,
            company_id: this.props.company,
            integration: this.integration
        }

        DataHandler.put({url: `integrations`}, data).done(response => {
            this.props.enqueueSnackbar(this.tr("Eventos authentication information saved"), {
                variant: "success",
            });	
            this.setState({activated: 1});
        });
    }

    back = () => {
        const {functions: {updateView}} = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        this.setState({
            dialogData: {
                saveFunc: () => DataHandler.delete({url: `integrations`, company_id: this.props.company, integration: this.integration}).done(() => {
                    this.props.enqueueSnackbar(this.tr("Eventos authentication deactivated"), {
                        variant: "success"
                    });	
                    this.back();
                }),
                text: this.tr("Do you want to deactivate Eventos integration?")
            }
        }, () => this.openDialog('confirmation'));
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    render(){
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <div id="integration-wrapper" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{this.tr("Eventos settings")}</h3>
                    {this.state.activated == 1 && <Button className="red deactivate" size="large" onClick={this.deactivate}>{this.tr("Deactivate")}</Button>}
                </div>

                <div className="settings smaller-margin">
                    <h4>{this.tr("Authentication information")}</h4>
                    <p className='subtitle'>
                        {this.tr("After authentication, subprojects of projects that have an \"Eventos ID\" custom field value will be sent to Eventos when marking them as won in PSA. Location information data can be included automatically by creating two PSA custom fields for these projects: Osasto and Tila.")}
                    </p>
                    <OutlinedField value={this.state.access_token} onChange={(evt) => this.setState({username: evt.target.value})} label={this.tr("API key")} type="password" autoComplete="new-password"/>
                    <Button className="auth-button" color="primary" onClick={this.saveAuth} size="large">{this.tr("Save")}</Button>
                </div>

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
            </div>
        )
    }
}

export default Eventos;