import React from "react";

import TaimerComponent from "../TaimerComponent";
import DataHandler from "../general/DataHandler";

import "./TabMemo.css";
import { SettingsContext } from "../SettingsContext";
import MemoView from "../general/MemoView";
import { withSnackbar } from 'notistack';

class TabMemo extends TaimerComponent {
  static contextType = SettingsContext;

  constructor(props, context) {
    super(props, context, "contacts/TabMemo");

    this.state = {
      memos: []
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this._getMemos();
  }

  componentWillUnmount = () => {
    super.componentWillUnmount();
  };

  _getMemos = () => {
    const { id, company } = this.props;
    DataHandler.get({ url: `contacts/${id}/company/${company}/memo` }).done(memos => {
      memos = memos.map(m => {
        if (m.users_company < 1)
          m.users_name += ` (${this.tr("freelancer")})`;
        return m; 
      });
      this.setState({ memos });
    });
  };

  saveMemo = selectedMemo => {
    const { enqueueSnackbar, company, id } = this.props;
    const req =
      selectedMemo.id == "-1"
        ? DataHandler.put({ url: `contacts/${id}/memo/${company}` }, {customers_contacts_id: id, ...selectedMemo})
        : DataHandler.put({ url: `contacts/memo/${selectedMemo.id}/${company}` }, {customers_contacts_id: id, ...selectedMemo});
    req
      .then(() => {
        enqueueSnackbar(this.tr("Memo saved successfully!"), {
          variant: "success"
        });
        setTimeout(() => {
          this._getMemos();
        }, 1000);
        
      })
      .catch(err => {
        enqueueSnackbar(this.tr("Error saving memo!"), { variant: "error" });
      });
  };

  deleteMemo = selectedMemo => {
    const { enqueueSnackbar, company } = this.props;
    DataHandler.delete({ url: `contacts/memo/${selectedMemo.id}/company/${company}` })
      .then(() => {
        enqueueSnackbar(this.tr("Memo deleted successfully!"), {
          variant: "success"
        });
        setTimeout(() => {
          this._getMemos();
        }, 1000);
      })
      .catch(err => {
        enqueueSnackbar(this.tr("Error deleting memo!"), {
          variant: "error"
        });
      });
  };

  render() {

    return (
      <div id="contacts-memo">
        <MemoView
          {...this.props}
          memos={this.state.memos}
          saveMemo={this.saveMemo}
          deleteMemo={this.deleteMemo}
        />
      </div>
    );
  }
}

export default withSnackbar(TabMemo);
