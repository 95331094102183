import React from 'react';
import { WarningRounded } from "@mui/icons-material";
import './ConfirmationPopUp.scss';

const ConfirmationPopUp = props => {
    const { header, desc, leftButtons = [], rightButtons = [] } = props;
    return (
      <div className="confirmation-popup">
        <div className="confirmation-header">
          <WarningRounded className="icon" />
          {header}
          </div>
        <div className="confirmation-desc">{desc || ""}</div>
        <div className="confirmation-buttons">
          <div>
            {leftButtons.map(btn => {
              return (
                <div
                  style={btn.style}
                  className="confirmation-button"
                  onClick={btn.onClick}
                >
                  {btn.label}
                </div>
              );
            })}
          </div>
          <div className='button-container'>
            {rightButtons.map(btn => {
              return (
                <div
                  style={btn.style}
                  className="confirmation-button"
                  onClick={btn.onClick}
                >
                  {btn.label}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  export default ConfirmationPopUp;