import React from "react";
import "./ExpandingSearchBar.css";
import { Search, Close } from "@mui/icons-material";
import TaimerComponent from "../TaimerComponent";
import Tooltip from '@mui/material/Tooltip';

class ExpandingSearchBar extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "general/ExpandingSearchBar");

    this.input = React.createRef();
    this.state = {
      searchOpen: false,
      value: "",
    };
  }

  _toggleSearch = () => {
    this.setState({ searchOpen: !this.state.searchOpen }, () => {
      this.state.searchOpen && this._focus();
    });
  };

  _closeAndEmptySearch = () => {
    this.setState({ searchOpen: !this.state.searchOpen, value: "" });
    this.props.onChange && this.props.onChange("");
  };

  _focus = () => {
    if (!this.input.current) return;
    this.input.current.focus();
  };

  _onChange = (e) => {
    const value = e.currentTarget.value;
    this.setState({ value });
    this.props.onChange && this.props.onChange(value);
  };

  shouldComponentUpdate = (newProps, newState) => {
    return (
      this.state.value != newState.value ||
      this.state.searchOpen != newState.searchOpen
    );
  };

  render() {
    const { style } = this.props;
    const { searchOpen, value } = this.state;
    const width = searchOpen ? 200 : 30;
    const marginLeft = searchOpen ? -20 : 0;
    return (
      <div
        title={this.tr("Search")}
        style={{ ...style, width, marginLeft }}
        className="expanding-search-bar no-my-day-drag"
      >
        {
        !this.state.searchOpen ?
          <div className="icon-container" onClick={this._toggleSearch}>
            <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={this.tr("Search")}>
	            <Search className="icon" />
              </Tooltip>
          </div> : 
          <div onClick={this._closeAndEmptySearch} className="close-container">
            <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={this.tr("Close")}>
              <Close className="icon" />
            </Tooltip>
          </div>
        }
        <div className="input-container">
          <input
            value={value}
            onChange={this._onChange}
            ref={this.input}
            placeholder={this.tr("Search...")}
          />
        </div>
      </div>
    );
  }
}

export default ExpandingSearchBar;
