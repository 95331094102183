import React from "react";
import PropsOnlyListRow from "../PropsOnlyListRow";
import ListCell from "../ListCell";
import LinkListCell from "../LinkListCell";
import {
  MoreHoriz,
  RemoveRedEye,
  Edit,
  Archive,
  Link,
  LinkOff,
  ToggleOn,
} from "@mui/icons-material";
import ContextMenu from "../../general/ContextMenu";
import { MenuItem, Tooltip } from "@mui/material";
import CurrencyListCell from "../CurrencyListCell";
import DateCell from "../cells/DateCell";
import StatusTag from "../../general/StatusTag";

export default class ProductCatalogListRow extends PropsOnlyListRow {
  constructor(props) {
    super(props, null, {}, "list/rows/ProductCatalogListRow");
  }

  defineClassName() {
    return "productCatalogListRow";
  }

  getStatusData = (status) => {
    switch (status) {
      case "0":
        return {
          title: this.tr("Archived"),
          style: {
            backgroundColor: "rgba(107, 120, 151, 0.25)",
            color: "#6b7897",
          },
        };
      case "1":
        return {
          title: this.tr("Active"),
          style: { backgroundColor: "#d4f8f0", color: "#42b677" },
        };
      default:
        return {
          title: "-",
          style: { backgroundColor: "#aaa", color: "white" },
        };
    }
  };

  getAccountString = (data) => {
    const customers = data.customers || [];
    if (customers.length == 0) {
      return "-";
    }
    if (customers.length > 1) {
      const title = customers.length > 5 ? customers.slice(0, 5).map(c => c.customer_name).join(", ") + ` + ${this.tr("${count} accounts", {count: customers.length - 5})}` : customers.map(c => c.customer_name).join(", ")
      return (
        <Tooltip placement="bottom-start" title={title}>
          <div>{`${this.tr("${count} accounts", {count: customers.length})}`}</div>
        </Tooltip>
      );
    } else {
      return `${this.tr("1 account")} (${customers[0].customer_name})`;
    }
  };

  defineCells() {
    const {
      userObject: { dateFormat },
      functions: { updateView, checkPrivilege },
    } = this.context;
    const {
      data,
      columnWidthMap,
      rowProps: { currency, company },
    } = this.props;

    let cells = {};

    const statusData = this.getStatusData(data.status);

    Object.keys(data).forEach((key) => {
      cells[key] = (
        <ListCell
          width={columnWidthMap[key]}
          value={data[key] || "-"}
          textAlign="left"
          editable={false}
        />
      );
    });
    cells = {
      ...cells,
      context: (
        <ContextMenu
          label={<MoreHoriz />}
          buttonProps={{ className: "action-menu" }}
          className="cell row-menu"
          style={{
            width: this.props.columnWidthMap["context"] + "px",
            flex: this.props.columnWidthMap["context"] + " 1 0px",
          }}
          noExpandIcon
        >
          {this.props.rowProps.sliderMode
            ? [
                <MenuItem
                  key={1}
                  noClose={false}
                  onClick={() =>
                    updateView(
                      {
                        module: "products",
                        action: "main",
                        selectedTab: 'products',
                        catalog_id: data.id,
                        catalog_name: data.name,
                        catalog_company: data.companies_id
                      }
                    )
                  }
                >
                  <RemoveRedEye />
                  {this.tr("View Catalog")}
                </MenuItem>,
                checkPrivilege("products", "write", company) && <MenuItem
                  key={2}
                  className="delete"
                  onClick={() => this.props.rowProps.unlinkFromCatalog(data)}
                >
                  <LinkOff />
                  {this.tr("Unlink from Catalog")}
                </MenuItem>
              ]
            : [
              checkPrivilege("products", "write", company) && <MenuItem
                  key={1}
                  onClick={() => this.props.rowProps.renameCatalog(data)}
                >
                  <Edit />
                  {this.tr("Rename Catalog")}
                </MenuItem>,
                checkPrivilege("customers", "write", company) && <MenuItem
                  key={2}
                  onClick={() =>
                    this.props.rowProps.openLinkToAccountDialog(data)
                  }
                >
                  <Link />
                  {this.tr("Link to Account")}
                </MenuItem>,
                checkPrivilege("products", "write", company) && (data.status == 1 ? (
                  <MenuItem
                    key={3}
                    className="delete"
                    onClick={() => this.props.rowProps.archiveCatalog(data)}
                  >
                    <Archive />
                    {this.tr("Archive")}
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={3}
                    className="activate"
                    onClick={() => this.props.rowProps.activateCatalog(data)}
                  >
                    <ToggleOn />
                    {this.tr("Activate")}
                  </MenuItem>
                )),
              ]}
        </ContextMenu>
      ),
      name: (
        <LinkListCell
          width={columnWidthMap.name}
          handleClick={() =>
            updateView({
              module: "products",
              action: "main",
              selectedTab: 'products',
              catalog_id: data.id,
              catalog_name: data.name,
              catalog_company: data.companies_id
            })
          }
          value={data.name}
          noTab={true}
          editable={false}
        />
      ),
      price_in_catalog: (
        <CurrencyListCell
          currency={currency}
          width={columnWidthMap.price_in_catalog}
          value={data.income_price || 0}
          textAlign="left"
          editable={false}
        />
      ),
      status: (
        <ListCell width={columnWidthMap.status} onlyDisplay editable={false}>
          <StatusTag absolute text={statusData.title} style={{...statusData.style, left: 16}} />
        </ListCell>
      ),
      linked_to_account: (
        <LinkListCell
          width={columnWidthMap.linked_to_account}
          handleClick={() => this.props.rowProps.openSlider(data)}
          value={this.getAccountString(data)}
          noTab={true}
          editable={false}
        />
      ),
      products_amount: (
        <LinkListCell
          width={columnWidthMap.products_amount}
          handleClick={() =>
            updateView({
              module: "products",
              action: "main",
              selectedTab: 'products',
              catalog_id: data.id,
              catalog_name: data.name,
              catalog_company: data.companies_id
            })
          }
          value={data.products_amount || "-"}
          noTab={true}
          editable={false}
        />
      ),
      edited_date: <DateCell editable={false} value={data.edited_date} />,
      created: <DateCell editable={false} value={data.created} />,
    };

    return cells;
  }
}
