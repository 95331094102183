import React, { Component } from 'react';
import './TeamChat.css';
import DataHandler from './../general/DataHandler';
import { SettingsContext } from '../SettingsContext';
import Message from './NewsFeedMessage';
import TabHeader from './TabHeader';
import './TabNewsFeed.css';
import GroupView from './GroupView';

// Team Chat
export default class TabNewsFeed extends Component {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context);

        this.state = {
            viewParams: {},
            viewData: {
                messages: [],
                hasOlder: 0,
                hasNewer: 0,
            },
            error: false,
            loaded: false,
        }
    }

    componentDidMount = () => {
        this.refresh();
    }

    componentWillUnmount = () => {
    }

    refreshing = false;

    refresh = async (params = {}) => {
        if (this.refreshing)
            return;

        const { viewParams } = this.state;

        this.refreshing = true;

        let success = true;

        try {
            const viewData = await DataHandler.get({ url: 'teamchat/news', ...viewParams, ...params });

            this.setState({ viewData, error: false });
        } catch (error) {
            this.setState({ error: true });
            console.error("Team Chat News Feed Error ", error);

            success = false;
        }

        this.refreshing = false;

        return success;
    }

    onSendMessage = async (message, attachments) => {
        const form = new FormData();
        form.append("message", message);

        if (attachments.length === 1)
            form.append("attachment", attachments[0].file);

        try {
            const data = await DataHandler.file({ url: 'teamchat/news' }, form);

            this.refresh({refresh: 1});

            return data;
        } catch (error) {
            return false;
        }
    }

    afterComment = () => {
        this.refresh({refresh: 1});
    }

    update = () => {
        this.refresh();
    }

    onNavigatePage = async (viewParams) => {
        this.setState({ viewParams }, () => this.refresh());
    }

    render() {
        const { tr, classes, openPopup, onClose, inPopup } = this.props;
        const { viewData } = this.state;

        return (<>
            <TabHeader 
                classes={classes}
                title={tr("Newsfeed")} 
                tr={tr}
                openPopup={openPopup}
                onClose={onClose}
                inPopup={inPopup} />

            <GroupView
                desc
                className="FullWidth NewsFeed"
                onSendMessage={this.onSendMessage}
                messageProps={{ update: this.afterComment }}
                messageComponent={Message}
                onNavigatePage={this.onNavigatePage}
                data={viewData} />
        </>)
    }
}