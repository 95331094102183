import MoreHoriz from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import ContextMenu, { ContextSubMenu } from '../general/ContextMenu';
import CurrencyListCell from "../list/CurrencyListCell";
import LinkListCell from "../list/LinkListCell";
import ListCell from "../list/ListCell";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import AttachmentCell from "../list/cells/AttachmentCell";
import CheckboxCell from "../list/cells/CheckboxCell";
import DateCell from "../list/cells/DateCell";
import StatusCell from '../list/cells/StatusCell';
import TextInputCell from "../list/cells/TextInputCell";
import { SettingsContext } from './../SettingsContext';
import colors from '../colors';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveIcon from '@mui/icons-material/Archive';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import { ReactComponent as CopyIcon } from '../general/icons/copy.svg';
import { ReactComponent as ViewIcon } from '../general/icons/view.svg';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Send';

class ExpenseListRow extends PropsOnlyListRow {
	static contextType = SettingsContext;

	constructor(props) {
		super(props, {}, {}, 'expenses/ExpenseListRow');

		this.cellEdited  = this.cellEdited.bind(this);
		this.check 		 = this.check.bind(this);
		this.openExpense = this.openExpense.bind(this);
		this.setExpenseState = this.setExpenseState.bind(this);
		this.openAttachment = this.openAttachment.bind(this);

		this.states = [];
        this.states[0] = {name: this.tr("Draft"), color: "#6B7897"};
        this.states[1] = {name: this.tr("Waiting"), color: "#ffb822"};
        this.states[2] = {name: this.tr("Approved"), color: colors.greenish_cyan};
		this.states[3] = {name: this.tr("Rejected"), color: "#f7548f"};
		this.states[4] = {name: this.tr("Archived"), color: "#716aca"};
	}


    // shouldComponentUpdate(nextProps, prevProps) {
    // if(nextProps.hasOwnProperty("data") && nextProps['data']['id'] !== this.props.data['id']) {
    // this.setState({ data: nextProps.data });
    // return false;
    // }
    // 
    // return true;
    // }

	cellEdited(name, value) {
		const { rowProps: { openStatusCheckDialog } } = this.props;

		if (name == "state" && openStatusCheckDialog) {
			openStatusCheckDialog(value, {...this.props.data, state: value});
			return;
		}

        this.setDataAndUpdate(name, value);

        // let params = {module: "purchase_expenses", action: "set_purchase_expense_list_data", id: data['id'], companies_id: userObject.companies_id};
        // DataHandler.post(params, data).done(response => {
        // this.setState({ data: response.data });
        // this.props.rowProps.fetchData();
        // });
	}

	openExpense() {
		this.props.rowProps.updateView({
			"module": "worktrips",
			"action": "modify",
			"id": this.props.data['id'],
			"expenseType": "1"
		}, false);
	}

	addFromDraft = () => {
		this.context.functions.addExpense({draftId: this.props.data['id'], origin_point: "expense_list"});
	}

	setExpenseState(state) {
		this.cellEdited("state", state);
	}

	openAttachment() {
		this.props.rowProps.attachmentHandler(this.props.data.id);
	}

	currencyTotalFunction(sum, rate, label) {
		if(rate == 0) {
			rate = 1;
		}
		const presentCurrency = this.context.functions.presentCurrency;
		const val = (sum * (1 / rate)).toFixed(2);
		return presentCurrency(val, label ? label : this.context.taimerAccount.currency);
    }

    defineClassName() {
        return "expenseListRow"; 
    }

    defineCells() {
		const { rowProps: { stateUpdateConditions } } = this.props;
		const className = ["expenseListRow listElement row mainLevel", this.props.hidden ? "hidden" : "", this.props.data['id'] < 0 ? "new" : ""].join(" ");
		const isInvoiced = Number(this.props.data.is_invoiced) > 0;
		const isSent = this.props.data.is_sent != 0;
		const stateConditions = isInvoiced || isSent ? stateUpdateConditions.invoiced : stateUpdateConditions.notInvoiced;

		const bills = (this.props.data.bills_ids && this.props.data.bills_ids.split(',')) || ["0"];
		const billed = bills.indexOf("0") === -1;
		const billing_type = billed ? 'Billed' : (this.props.data.billing === "1" ? 'Unbilled' : 'Non Billable');

		const cells = {
			context:
				<ContextMenu data-testid="Expense_list_row" label={<MoreHoriz />} buttonProps={{className: 'action-menu'}} className="cell row-menu" width={this.props.columnWidthMap['context']} style={{width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + " 1 0px" }} noExpandIcon>
					<MenuItem  data-testid="expense_view" onClick={() => {this.openExpense()}}><ViewIcon />{this.tr('Open')}</MenuItem>
					{ this.context.functions.checkPrivilege("worktrips", "write") && <MenuItem data-testid="duplicate_expense" onClick={() => {this.addFromDraft()}}><CopyIcon />{this.tr('Use as Draft')}</MenuItem>}
					{ stateConditions.approve.includes(Number(this.props.data['state'])) && this.props.data['can_approve'] && <MenuItem data-testid="approve_expense" onClick={() => {this.setExpenseState(2)}}><CheckIcon />{this.tr('Approve')}</MenuItem>}
					{ stateConditions.reject.includes(Number(this.props.data['state'])) && this.props.data['can_approve'] && <MenuItem data-testid="reject_expense" onClick={() => {this.setExpenseState(3)}}><CloseIcon className="expenseReject" />{this.tr('Reject')}</MenuItem>}
					{ stateConditions.setWaiting.includes(Number(this.props.data['state'])) && <MenuItem data-testid="set_as_waiting" onClick={() => {this.setExpenseState(1)}}><HourglassFullIcon />{this.tr('Set as waiting')}</MenuItem>}
					{ stateConditions.archive.includes(Number(this.props.data['state'])) && this.props.data['can_approve'] && <MenuItem data-testid="archive_expense" onClick={() => {this.setExpenseState(4)}}><ArchiveIcon />{this.tr('Archive')}</MenuItem>}

					<MenuItem onClick={() => this.props.rowProps.print(this.props.data.id)}><PrintIcon title="" /> {this.tr('Print')}</MenuItem>
					<MenuItem onClick={() => this.props.rowProps.send(this.props.data.id)}><EmailIcon title="" /> {this.tr('Send')}</MenuItem>
				</ContextMenu>,
			attachment:
				<AttachmentCell style={{width: this.props.columnWidthMap['attachment'] + 'px'}} width={this.props.columnWidthMap['attachment']} onClick={() => this.openAttachment()} />,
			checked:
                <CheckboxCell style={{width: this.props.columnWidthMap['checked'] + 'px'}} checked={this.props.checked} width={this.props.columnWidthMap['checked']} onClick={() => this.props.listRef.check(this.props.data.id)} />,
			id:
				<LinkListCell
					urlHandler={value => `index.html?module=worktrips&action=modify&expenseType=1&id=${this.props.data.id}`}
					style={{width: this.props.columnWidthMap['id'] + 'px'}}
					width={this.props.columnWidthMap['id']}
					value={this.props.data['id']} editable={false}
					noTab={true} />,
            fullname:
		    	<ListCell style={{width: this.props.columnWidthMap['fullname'] + 'px'}} width={this.props.columnWidthMap['fullname']} value={this.props.data['fullname']} editable={false} />,
			account:
				<LinkListCell
					urlHandler={value => `index.html?module=customers&action=view&id=${this.props.data.customers_id}`}
					style={{width: this.props.columnWidthMap['account'] + 'px'}}
					width={this.props.columnWidthMap['account']}
					value={this.props.data['customer_name']}
					editable={false}
					asText={this.props.data.can_see_account == 0}
					noTab={true} />,
			project:
				<LinkListCell
					urlHandler={value => `index.html?module=projects&action=view&id=${this.props.data.projects_id}`}
					style={{width: this.props.columnWidthMap['project'] + 'px'}}
					width={this.props.columnWidthMap['project']}
					value={this.props.data['project_name']}
					editable={false}
					noTab={true} />,
			description:
				<TextInputCell style={{width: this.props.columnWidthMap['description'] + 'px'}} width={this.props.columnWidthMap['description']} name="description" value={this.props.data['description']} onEdited={this.cellEdited} listCellProps={{editable: this.props.data['can_modify'] == 1}}/>,
			expense_date:
				<DateCell style={{width: this.props.columnWidthMap['expense_date'] + 'px'}} width={this.props.columnWidthMap['expense_date']} value={this.props.data['expense_date']} name="expense_date" onEdited={this.cellEdited} listCellProps={{className: "date"}} editable={this.props.data['can_modify'] == 1} />,
			state:
				<StatusCell style={{width: this.props.columnWidthMap['state'] + 'px'}} value={this.states[this.props.data['state']]?.name} width={this.props.columnWidthMap['state']} displayData={{color: this.states[this.props.data['state']]?.color, name: this.states[this.props.data['state']]?.name}}/>,
			expense_types:
		    	<ListCell style={{width: this.props.columnWidthMap['expense_types'] + 'px'}} width={this.props.columnWidthMap['expense_types']} value={this.props.data['expense_types']?.split(',').sort().join(', ')} editable={false} />,
			payment_type:
				<ListCell style={{width: this.props.columnWidthMap['payment_type'] + 'px'}} width={this.props.columnWidthMap['payment_type']} value={this.props.data['payment_type']} editable={false} />,
			team_groups:
				<ListCell style={{width: this.props.columnWidthMap['team_groups'] + 'px'}} width={this.props.columnWidthMap['team_groups']} value={this.props.data['name']} editable={false} />,
			total_without_vat:
				<CurrencyListCell style={{width: this.props.columnWidthMap['total_without_vat'] + 'px'}} width={this.props.columnWidthMap['total_without_vat']} value={this.props.data['total_without_vat']} currency={this.props.rowProps.currency} editable={false} />,
			total:
				<CurrencyListCell style={{width: this.props.columnWidthMap['total'] + 'px'}} width={this.props.columnWidthMap['total']} value={this.props.data['total_with_vat']} currency={this.props.rowProps.currency} editable={false} />,
			vat_sum:
				<CurrencyListCell style={{width: this.props.columnWidthMap['vat_sum'] + 'px'}} width={this.props.columnWidthMap['vat_sum']} value={this.props.data['vat_sum']} currency={this.props.rowProps.currency} editable={false} />,
			currency_total:
				<TextInputCell style={{width: this.props.columnWidthMap['currency_total'] + 'px'}} width={this.props.columnWidthMap['currency_total']} value={ this.currencyTotalFunction(this.props.data['total_with_vat'], this.props.data['currency_rate'], this.props.data['currency_label']) } editable={false} />,
			billing_type:
				<TextInputCell style={{width: this.props.columnWidthMap['billing_type'] + 'px'}} width={this.props.columnWidthMap['billing_type']} name="billing_type" value={billing_type} editable={false} />,
			in_procountor:
		    	<ListCell style={{width: this.props.columnWidthMap['in_procountor'] + 'px'}} width={this.props.columnWidthMap['in_procountor']} value={this.props.data['in_procountor'] === "P" ? "P" : ""} editable={false} />,
			billing:
		    	<ListCell style={{width: this.props.columnWidthMap['billing'] + 'px'}} width={this.props.columnWidthMap['billing']} value={this.tr(this.props.data['billing_text'])} editable={false} />,
			invoiced:
		    	<ListCell style={{width: this.props.columnWidthMap['invoiced'] + 'px'}} width={this.props.columnWidthMap['invoiced']} value={this.tr(this.props.data['invoiced_text'])} editable={false} />,
        };

        return cells;
    }

    // render() {
    // }
}

export default ExpenseListRow;
