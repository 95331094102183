import colors from '../colors';
import getAccountColumns from './AccountColumns';
import getColumnType, { ColumnDefinition, columnSorter } from './ColumnTypes';
import { CustomFields } from './CustomFields';
import getProjectColumns from './ProjectColumns';
import getUserColumns from './UserColumns';
import { filterColumns } from "./ColumnVisibility";

const columnOrder = [
    'id',
    'user',
    'account',
    'customers_id',
    'company',
    'supervisor',
    'project',
    'date_month',
    'project_id',
    'task',
    'jobtype',
    'status',
    'billable',
    'billable_amount',
    'billed_price',
    'own_cost',
    'billed',
    'hours',
    'billed_hours',
    'non_billed_hours',
    'billable_hours',
    'non_billable_hours',
    'date',
    'starttime',
    'endtime',
    'date_year',
    'date_quarter',
    'log_created',    
];

interface Params {
    customFields: CustomFields;
    checkPrivilege: (group: string, perm: string, company?: number) => boolean;
    tr: (text, replacers?: Record<string, string>) => string;
    settingsContext: any;
}

export default function getWorkinghourColumns(params: Params) {
    const columns: ColumnDefinition[] = [        
        {
            field: 'id',
            headerName: 'Row ID',
            overrideGrandTotal: 'Total',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'company',
            headerName: 'Company',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'user_company',
            headerName: 'User\'s company',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'task',
            headerName: 'Task',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'description',
            headerName: 'Description',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'overtime_description',
            headerName: 'Overtime description',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'jobtype',
            headerName: 'Jobtype',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
            grandTotal: 'none',
        },
        {
            field: 'status',
            headerName: 'Status',
            ...getColumnType('status', params.tr),
            cellRendererParams: {
                displayDatas: [
                    {id: -1, "name": "declined", "color": "#ff5722"},
                    {id: 0, "name": "waiting", "color": "#ffb822"},
                    {id: 1, "name": "approved", "color": colors.greenish_cyan},
                    {id: 2, "name": "submitted", color: "#716ACA"}
                ],
                tr: params.tr,
            },
        },
        {
            field: 'billable',
            headerName: 'Billable',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas: [
                    {id: 0, name: "No"},
                    {id: 1, name: "Yes"},
                ],
                tr: params.tr,
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'billed_price',
            headerName: 'Billed Price',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'billable_amount',
            headerName: 'Selling Price',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'own_cost',
            headerName: 'Internal cost',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'billed',
            headerName: 'Billed',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas: [
                    {id: 0, name: "No"},
                    {id: 1, name: "Yes"},
                ],
                tr: params.tr,
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'hours',
            headerName: 'Hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
            useValueFormatterForExport: true,
        },
        {
            field: 'billed_hours',
            headerName: 'Billed Hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'non_billed_hours',
            headerName: 'Non Billed Hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'billable_hours',
            headerName: 'Billable Hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'non_billable_hours',
            headerName: 'Non Billable Hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'vacation_and_leave_hours',
            headerName: 'Vacation/leave hours',
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'is_overtime',
            headerName: 'Is overtime',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas: [
                    { id: 0, name: "No" },
                    { id: 1, name: "Yes" },
                ],
                tr: params.tr,
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'date',
            headerName: 'Date',
            ...getColumnType('date', null, 'date'),
        },
        {
            field: 'start',
            headerName: 'Start',
            ...getColumnType('time'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'end',
            headerName: 'End',
            ...getColumnType('time'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            chartDataType: 'category',
            field: 'date_year',
            headerName: 'Year',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'date_quarter',
            headerName: 'Quarter',
            ...getColumnType('quarter'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'date_month',
            headerName: 'Month',
            ...getColumnType('month'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'log_created',
            headerName: 'Created Date',
            ...getColumnType('date', null, 'log_created'),
        },
        {
            chartDataType: 'category',
            field: 'date_fy',
            headerName: 'Financial Year',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        ...getProjectColumns(params),
        ...getAccountColumns(params),
        ...getUserColumns('user', params),
        {
            field: 'bill',
            headerName: 'Invoice',
            ...getColumnType('links'),
            cellRendererParams: {
                url: "index.html?module=invoices&action=view&id=$(id)"
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'billable_rate',
            headerName: 'Billable rate',
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'billable_hours', divisor: 'hours_excl_absences' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum' // So shows formatted value correctly in grouped row. By default percentage has "grouped" and the the value is not formatted.
        },
    ];

    columns.sort(columnSorter(columnOrder));

    return filterColumns(
        columns, 
        "workingHours", 
        params.settingsContext, 
        "user"
    );
}
