import React from 'react';
import DataHandler from '../../general/DataHandler';
import TaimerComponent from '../../TaimerComponent';
import { CatalogDialog } from '../../list/dialogs/ProductCatalogDialogs';
import colors from '../../colors';
import DataList from '../../general/DataList';
import { ProposalContentSection, ProposalContentSectionType } from './types';

interface Props {
    onClose: () => void;
    onTemplateSelected: (template: any) => void;
    company: string;
    templateType?: ProposalContentSectionType;
}

interface State {
    templates: ProposalContentSection[];
    loading: boolean;
    selectedTemplate?: object;
}

export class SelectTemplateDialog extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'projects/proposal/SelectTemplateDialog');

        this.state = {
            templates: [],
            loading: true,
            selectedTemplate: undefined,
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        this._getTemplates();
    };

    _getTemplates = () => {
        DataHandler.get({
            url: `companies/${this.props.company}/proposal_templates/`,
        })
            .done((templates) => {
                this.setState({ templates: this.props.templateType ? templates.filter((t) => t.type == this.props.templateType) : templates, loading: false });
            })
            .fail((err) => {
                this.setState({ loading: false });
                console.log(err);
            });
    };

    onSelectTemplate = (selectedTemplate) => {
        this.setState({ selectedTemplate });
    };

    render() {
        const { onClose, onTemplateSelected } = this.props;
        const { selectedTemplate, templates, loading } = this.state;
        return (
            <CatalogDialog
                onClose={onClose}
                buttons={[
                    {
                        title: this.tr('Cancel'),
                        onClick: onClose,
                        style: {
                            backgroundColor: 'white',
                            color: colors.steel,
                        },
                    },
                    {
                        title: this.tr('Select template'),
                        onClick: () => onTemplateSelected(selectedTemplate),
                        style: {
                            backgroundColor: colors.deep_sky_blue,
                            color: 'white',
                        },
                    },
                ]}
                header={`${this.tr('Select template')}`}
            >
                <div className="dialog-content">
                    <p className="desc">{`${this.tr('Please select a template you would like to add to this proposal.')}`}</p>
                    <DataList
                        //@ts-ignore
                        label={loading ? this.tr('Loading templates...') : this.tr('Choose template')}
                        isDisabled={loading}
                        name="id"
                        options={templates.map((t) => ({ ...t, label: t.title }))}
                        value={selectedTemplate}
                        onChange={this.onSelectTemplate}
                        className="catalog-data-list"
                        shownCount={20}
                    />
                </div>
            </CatalogDialog>
        );
    }
}
