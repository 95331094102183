import React from 'react';
import ListCell from "./../ListCell";

class CheckboxCell extends React.Component {
	constructor(props) {
		super(props);

		this.isChecked 	 = this.isChecked.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}


	isChecked() {
		return this.props.checked;
	}


	handleClick() {
        if(this.props.disabled) {
            return;
        }

		this.props.onClick();

		this.props.onEdited(!this.props.checked);
	}


	render() {
        const { 
            disabled, 
            checked,
            partiallyChecked,
            className
        } = this.props;

        const classN = `checkmarkContainer 
            ${disabled ? "disabled" : ""} 
            ${checked ? "checked" : ""}
            ${partiallyChecked ? "partially-checked" : ""}
            ${className ? className : ""}`;

		return (
			<ListCell
				width={this.props.width}
				permanentEditMode={true}
                {...this.props.listCellProps}>
				<div className="checkbox">
					<div onClick={this.handleClick} className={classN}></div>
				</div>
			</ListCell>
		);
	}
}

CheckboxCell.defaultProps = {
	width: 100,
	checked: false,
	onClick: () => {},
	onEdited: () => {},
    listCellProps: {},
    disabled: false
};

export default CheckboxCell;
