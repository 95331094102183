import React from 'react';
import TreeDropdown from "../general/TreeDropdown";
import DataHandler from "../general/DataHandler";

class ProjectCustomerTreeDropdown extends React.Component {
    static defaultProps = {
        className: "full",
        onSelect: () => {},
        parentKey: "parentid",
        data: undefined,
    };

    constructor(props) {
        super(props);

        this.state = {
            projects: [],
        };

        this.fetchProjects = this.fetchProjects.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { value, data } = this.props;
        const { projects } = this.state;

        if(!data && prevProps.value !== value) {
            const project = projects.find(p => p.id == value);

            if (this.props.activityDialog) 
               this.fetchProjects("");
            else if (!project && !this.props.customer && !this.props.project)
                this.fetchProjects("", { id: value });
        }
    }

    componentDidMount() {
        const { value, data } = this.props;

        if (this.props.activityDialog) 
            this.fetchProjects("");
        else if (value && !data && !this.props.customer && !this.props.project)
            this.fetchProjects("", {id: value});
    }

    fetchProjects(value = "", params = {}) {
        let qParams = params;
        if(this.props.queryParameters) {
            qParams = {...this.props.queryParameters, ...qParams}
        }        
        DataHandler.get({ url: `projects/accountdropdown/${value}`, ...qParams }).done(projects => {
            this.setState({ projects });
        });
    }

    render() {
        const { data, disabled } = this.props;
        const options = data ? data : (Array.isArray(this.state.projects) ? this.state.projects : []);
        const selectedOption = options.find(e => Number(e.id) === Number(this.props.value));
        const displayValue = (this.props.customer && this.props.project) ? `${this.props.customer.name} / ${this.props.project.name }` : undefined;
        
        return (
            <TreeDropdown
                name={this.props.name}
                disabled={disabled}
                data={options}
                debounceOnChange={true}
                optionLabelKey={this.props.optionLabelKey}
                error={this.props.error}
                parentKey={this.props.parentKey}
                onChange={(value, source) => {
                    if (source !== "keyUp" && source !== "onClick") return;
                    this.fetchProjects(value);
                }}
                onSelect={value => {
                    if (value.id == this.props.value)
                        this.setState({displayValue: value.label})
                    else 
                        this.setState({displayValue: undefined})
                    
                    this.props.onSelect({
                        data: value
                    });
                }}
                className={this.props.className}
                label={this.props.label}
                placeholder={selectedOption ? selectedOption.label : undefined}
                displayValue={ this.state.displayValue ? this.state.displayValue : displayValue }
                value={this.props.value}
            />
        );
    }
}

export default ProjectCustomerTreeDropdown;
