import { values } from "lodash";
import getColumnType, { ColumnDefinition } from "./ColumnTypes";

enum CustomFieldType {
    Text = 0,
    Drodown = 1,
    Date = 2,
    DateRange = 3,
    Radio = 4,
    Multiselect = 5,
    TextArea = 6,
    Link = 7,
}

interface CustomFiledOption {
    id: number;
    name: string;
}

export interface CustomField {
    id: number;
    type: CustomFieldType;
    name: string;
    options: Record<number, CustomFiledOption>;
    companies_id: number;
}

export interface CustomFields {
    customers: CustomField[];
    projects: CustomField[];
}

export function createCustomFields(customFields: CustomField[], prefix: string): ColumnDefinition[] {
    const fields: ColumnDefinition[] = [];

    for (const field of customFields) {
        if (field.type === CustomFieldType.Date) {
            fields.push({
                field: `${prefix}_${field.id}`,
                headerName: field.name,
                isCustomField: true,
                hide: true,
                ...getColumnType('date', null, `${prefix}_${field.id}`),
                sumAndGroupType: "group",
            });        
        } else if (field.type === CustomFieldType.Link) {
            fields.push({
                field: `${prefix}_${field.id}`,
                headerName: field.name,
                isCustomField: true,
                hide: true,
                ...getColumnType('textLink'),
                sumAndGroupType: "group",
            });        
        } else {
            fields.push({
                field: `${prefix}_${field.id}`,
                headerName: field.name,
                isCustomField: true,
                hide: true,
                ...getColumnType('text'),
                sumAndGroupType: "sum", // getCustomFieldAggregateValue shows always grouped if any value is non-numeric (and not empty).
                grandTotal: 'sum',
            });
        }
    }

    return fields;
}