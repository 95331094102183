import { ColumnState } from "ag-grid-enterprise";

export interface FilterState {
    columns: ColumnState[]; 
    grouping: {
        groupId: string;
        open: boolean;
    }[];
    filterModel: {
        [key: string]: any;
    };
    pivotMode: boolean;
}

export const defaultFilterState: FilterState = {
    columns: [],
    grouping: [],
    filterModel: {},
    pivotMode: false,
};