import React from "react";

import ListCell from "../../list/ListCell";
import MenuItem from "@mui/material/MenuItem";
import SettingRow from "../../list/rows/SettingRow";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import TextInputCell from "../../list/cells/TextInputCell";
import CurrencyListCell from "../../list/CurrencyListCell";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import { format } from "date-fns";
import ContextMenu from "../../general/ContextMenu";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';

export default class TimeManagementJobtypeRow extends SettingRow {
    // The code that is commented out is waiting for the day these jobtypes need to be hierarchical, so don't delete it, thanks!

    productChanged(obj) {
        const newState = this.state.data;
        newState.product_id = obj.id;
        this.setState({ data: newState });
        this.editAndSave();
    }

    productRegisterProductChanged(obj) {
        const newState = this.state.data;
        newState.product_register_id = obj.id;
        this.setState({ data: newState });
        this.editAndSave();
    }

    salesAccountChanged(obj) {
        const newState = this.state.data;
        newState.accounting_sales_account_id = obj.id;
        this.setState({ data: newState });
        this.editAndSave();
    }

    purchasesAccountChanged(obj) {
        const newState = this.state.data;
        newState.accounting_purchases_account_id = obj.id;
        this.setState({ data: newState });
        this.editAndSave();
    }

    stockAccountChanged(obj) {
        const newState = this.state.data;
        newState.accounting_stock_account_id = obj.id;
        this.setState({ data: newState });
        this.editAndSave();
    }


    render() {
        // const className        = [`listElement row`, this.props.hidden ? "hidden" : "", this.state.data['id'] < 0 ? "new" : ""].join(" ");
        // const childRows        = this.createChildren(this.props.children, JobtypeRow);
        // const branchIndicators = this.createBranchIndicators(44, 24);

        const { tr } = this.props.rowProps;

        const product =
            this.props.rowProps.accountingProducts &&
            this.props.rowProps.accountingProducts.find(d => d.id == this.state.data["product_id"]);

        const productRegisterProduct = 
            this.props.rowProps.productRegisterProducts &&
            this.props.rowProps.productRegisterProducts.find(d => d.id == this.state.data["product_register_id"]);

        const accounting_sales_account = this.props.rowProps.salesAccounts && this.props.rowProps.salesAccounts.find(a => a.id == this.state.data["accounting_sales_account_id"]);
        const accounting_purchases_account = this.props.rowProps.purchasesAccounts && this.props.rowProps.purchasesAccounts.find(a => a.id == this.state.data["accounting_purchases_account_id"]);
        const accounting_stock_account = this.props.rowProps.stockAccounts && this.props.rowProps.stockAccounts.find(a => a.id == this.state.data["accounting_stock_account_id"]);    
        const newRow = Number(this.state.data.id) < 0;

        const cells = {
            context: (
                <ContextMenu
                    label={<MoreHoriz />}
                    buttonProps={{ className: "action-menu" }}
                    className="cell row-menu"
                    width={this.props.columnWidthMap["context"]}
                    style={{
                        width: this.props.columnWidthMap["context"] + "px",
                        flex: this.props.columnWidthMap["context"] + " 1 0px",
                    }}
                    noExpandIcon
                >
                    {this.props.data.deleted === "0" ? (
                        <MenuItem id="jobtyperow-menu-item-archive" onClick={() => this.delete(this.state.data)}>
                           {/*  <ListItemIcon> */}
                            {newRow ? <RemoveIcon className="delete" /> : <ArchiveOutlinedIcon fontSize="small" />}
                          {/*   </ListItemIcon> */}
                            <Typography className={newRow ? "delete" : ""} variant="inherit">{newRow ? tr("Delete") : tr("archive")}</Typography>
                        </MenuItem>
                    ) : (
                        <MenuItem onClick={() => this.delete(this.state.data)}>
                            {/* <ListItemIcon> */}
                                <ArchiveOutlinedIcon fontSize="small" />
                          {/*   </ListItemIcon> */}
                            <Typography variant="inherit">{tr("unarchive")}</Typography>
                        </MenuItem>
                    )}
                </ContextMenu>
            ),
            /*
            Älä poista. Tarvittaan luultavasti myöhemmin TAIM9-214.JobTypesSettings  
            delete: (
				<ListCell
					onClick={() => this.delete()}
					className="noBg pointer"
					permanentEditMode={true}
					width={this.props.columnWidthMap["delete"]}
				>
					<TrashCanIcon />
				</ListCell>
            ), 
            */
            name: (
                <TextInputCell
                    name="name"
                    editable={this.props.data.deleted === "0"}
                    validation={['empty']}
                    value={this.state.data.name}
                    width={this.props.columnWidthMap.name /* - (branchIndicators.length * 24)*/}
                    onEdited={this.editAndSaveV2}
                    listCellProps={{
                        className: "norightborder",
                        inEditMode:this.props.data.id < 0,
                    }}
                    focusOnMount={true}
                />
            ),
            archived: (
                <div
                    className="editedField noleftborder"
                    style={{
                        width: this.props.columnWidthMap["archived"] + "px",
                        flex: this.props.columnWidthMap["archived"] + " 1 0px",
                    }}
                >
                    <div className="editedField-edited">
                        <div className="editedField-edited-archived">
                            {this.props.data.deleted === "1" && tr("archived")}
                        </div>
                    </div>
                </div>
            ),
            billable: (
                <ListCell className="" width={this.props.columnWidthMap.billable} onlyDisplay>
                    <Switch
                        color="primary"
                        checked={Number(this.state.data.billable) === 1}
                        onChange={event => {
                            this.editAndSave("billable", event.target.checked ? "1" : "0");
                        }}
                        disabled={this.props.data.deleted === "1"}
                    />
                </ListCell>
            ),
            editedField: (
                <div
                    className="editedField noleftborder"
                    style={{
                        width: this.props.columnWidthMap["editedField"] + "px",
                        flex: this.props.columnWidthMap["editedField"] + " 1 0px",
                    }}
                >
                    <div className="editedField-edited">
                        <div className="editedField-edited-lastedited">
                            {this.state.data.edited &&
                                this.state.data.edited !== "0000-00-00" &&
                                tr("Last edited") +
                                    ": " +
                                    format(this.state.data.edited, this.context.userObject.dateFormat.toUpperCase())}
                        </div>
                        <div className="-editedField-editededitedby">
                            {this.state.data.editor &&
                                this.state.data.editor.length > 0 &&
                                tr("By") + " " + this.state.data.editor}
                        </div>
                    </div>
                </div>
            ),
            starts: (
                <TextInputCell
                    name="starts"
                    editable={false}
                    value={"starts"}
                    width={this.props.columnWidthMap.starts}
                    listCellProps={{
                        className: "noleftborder",
                    }}
                />
            ),
            ends: (
                <TextInputCell name="edited" editable={false} value={"ends"} width={this.props.columnWidthMap.ends} />
            ),
            price: (
                <TextInputCell
                    name="cost"
                    listCellType={CurrencyListCell}
                    textAlign="center"
                    editable={this.props.data.deleted === "0"}
                    value={this.state.data.cost}
                    width={this.props.columnWidthMap.price}
                    onEdited={this.editAndSaveV2}
                    listCellProps={{maximumFractionDigits: 4}}
                />
            ),
            active: (
                <ListCell className="" width={this.props.columnWidthMap["active"]} onlyDisplay>
                    <Switch
                        color="primary"
                        checked={this.state.data.active === "1" && this.props.data.deleted !== "1"}
                        onChange={event => {
                            this.editAndSave("active", event.target.checked ? "1" : "0");
                        }}
                        disabled={this.props.data.deleted === "1"}
                    />
                </ListCell>
            ),
            product_id: (
                <AutoCompleteCell
                    listCellProps={{
                        className: "product_id",
                    }}
                    editable={true}
                    autoCompleteData={this.props.rowProps.accountingProducts}
                    name="product_id"
                    width={this.props.columnWidthMap["product_id"]}
                    value={product}
                    onEdited={obj => this.productChanged(obj)}
                />
            ),
            product_register_id: (
                <AutoCompleteCell
                    listCellProps={{
                        className: "product_register_id",
                    }}
                    editable={true}
                    autoCompleteData={this.props.rowProps.productRegisterProducts}
                    name="product_register_id"
                    width={this.props.columnWidthMap["product_register_id"]}
                    value={productRegisterProduct}
                    onEdited={obj => this.productRegisterProductChanged(obj)}
                />
            ),
            accounting_sales_account_id: (
                <AutoCompleteCell
                    listCellProps={{
                        className: "accounting_sales_account_id",
                    }}
                    editable={parseInt(this.props.data.id) > 0 && this.props.data.deleted == "0"}
                    autoCompleteData={this.props.rowProps.salesAccounts}
                    name="accounting_sales_account_id"
                    width={this.props.columnWidthMap["accounting_sales_account_id"]}
                    value={accounting_sales_account}
                    onEdited={obj => this.salesAccountChanged(obj)}
                />
            ),
            accounting_purchases_account_id: (
                <AutoCompleteCell
                    listCellProps={{
                        className: "accounting_purchases_account_id",
                    }}
                    editable={parseInt(this.props.data.id) > 0 && this.props.data.deleted == "0"}
                    autoCompleteData={this.props.rowProps.purchasesAccounts}
                    name="accounting_purchases_account_id"
                    width={this.props.columnWidthMap["accounting_purchases_account_id"]}
                    value={accounting_purchases_account}
                    onEdited={obj => this.purchasesAccountChanged(obj)}
                />
            ),
            accounting_stock_account_id: (
                <AutoCompleteCell
                    listCellProps={{
                        className: "accounting_stock_account_id",
                    }}
                    editable={parseInt(this.props.data.id) > 0 && this.props.data.deleted == "0"}
                    autoCompleteData={this.props.rowProps.stockAccounts}
                    name="accounting_stock_account_id"
                    width={this.props.columnWidthMap["accounting_stock_account_id"]}
                    value={accounting_stock_account}
                    onEdited={obj => this.stockAccountChanged(obj)}
                />
            ),
            integration_id: (
                <TextInputCell
                    name="integration_id"
                    textAlign="center"
                    editable={this.props.data.deleted === "0" && parseInt(this.props.data.id) > 0}
                    value={this.state.data.integration_id}
                    width={this.props.columnWidthMap.integration_id}
                    onEdited={this.editAndSaveV2}
                />
            ),
            use_in_quote: (
                <ListCell className="" width={this.props.columnWidthMap["use_in_quote"]} onlyDisplay>
                    <Switch
                        color="primary"
                        checked={this.state.data.use_in_quote == 1}
                        onChange={event => {
                            this.editAndSave("use_in_quote", event.target.checked ? 1 : 0);
                        }}
                    />
                </ListCell>
            ),
            in_default_pricelist: (
                <ListCell className="" width={this.props.columnWidthMap["in_default_pricelist"]} onlyDisplay>
                    <Switch
                        color="primary"
                        checked={this.state.data.in_default_pricelist == 1}
                        onChange={event => {
                            this.editAndSave("in_default_pricelist", event.target.checked ? 1 : 0);
                        }}
                    />
                </ListCell>
            ),
        };

        return (
            <div
                className="listElement row flex"
                style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
            >
                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );

        /*return (
            <div className="listElement" style={{ position: "relative" }}>
                <div className={className} style={{
                    height: !this.props.hidden ? SettingRow.rowHeightPx : "0px",
                    lineHeight: SettingRow.rowHeightPx
                }}>
                    {<ContextMenu label={<MoreHoriz />} buttonProps={{className: 'action-menu'}} className="cell" width={this.props.columnWidthMap['contextMenu']} style={{width: this.props.columnWidthMap['contextMenu'], flex: this.props.columnWidthMap['contextMenu'] + " 1 0px" }} noExpandIcon>
                        <MenuItem onClick={() => this.props.rowProps.addChild(this.props.data)}>{tr("Add jobtype")}</MenuItem>
                        <MenuItem className="delete" onClick={this.delete}>{tr("Delete")}</MenuItem>
                    </ContextMenu>}
                    {branchIndicators.map(Indicator => Indicator)}
                </div>
                {childRows !== false && <div className="listElement">{childRows}</div>}
            </div>
        );*/
    }
}
