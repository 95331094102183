import React from "react";
import { TextField } from '@mui/material';
import "./TextFieldWithLimit.css";
import { cloneDeep } from 'lodash';
import TaimerComponent from "../TaimerComponent";

class TextFieldWithLimit extends TaimerComponent {

    constructor(props, context) {
        super(props, context, "general/TextFieldWithLimit");
    }

    onChange = (e) => {
        const { onChange, limit, value, onOverLimit } = this.props;
        const limitValue = Number(limit);
        const valueLength = value ? value.length : 0;

        if (limitValue) {
            const newLength = e.target.value.length;
            const restrictNewValue = newLength > valueLength && newLength > limitValue;

            if (restrictNewValue && valueLength <= limitValue) { // Old value is not yet over limit. Slice new value max to limit.
                const newValue = e.target.value.slice(0, limit);
                const newE = {
                    target: {
                        name: e.target.name,
                        value: newValue
                    }
                }
				onOverLimit && onOverLimit(limit);
                onChange && onChange(newE);
            }
            else if (restrictNewValue && valueLength > limitValue) { // Old value is already over limit. Return old value in onChange.
				onOverLimit && onOverLimit(limit);
            }   
            else { // New value is not over limit or new length is less than old length.
                onChange && onChange(e);
            }
        }
        else {
            onChange && onChange(e);
        }
    }

    render() {
        const { limitClassName, limitBesideField, showLimitOnlyWhenOver, onOverLimit, limit, value, hideLimit, multiline, limitEditorType = TextField, useAbsoluteLimitPositioning, infoTooltip, InputProps, ...rest } = this.props;
        const messageLength = value?.length || 0;
        const limitValue = Number(limit);
        const useOwnOnChange = multiline || onOverLimit;  // maxLength prop does not work with multiline so check the limit in this.onChange

        const fieldProps = cloneDeep({...rest});
        delete fieldProps.className;
        delete fieldProps.editorType;
        if (useOwnOnChange) {
            delete fieldProps.onChange;
        }
        const fieldClass = multiline ? "textField-with-limit-textArea " : "textField-with-limit ";
        const containerClass = "textfield-with-limit-container " + (this.props.containerClass ? this.props.containerClass : "");
        const limitClass = `textfield-with-limit-length ${useAbsoluteLimitPositioning ? 'limit-absolute-positioning' : ''} `;
        const overLimit = messageLength > limitValue;
        const Editor = limitEditorType;
        const showOverLimit = !showLimitOnlyWhenOver || (showLimitOnlyWhenOver && overLimit);

        const limitStringSize = (messageLength.toString().length + (limit?.toString().length || 0)) *-1 - 2.5;
        const limitBesideFieldStyle = limitBesideField ? 
            {
                marginRight:  limitStringSize + 'ch',
                marginTop: "5px"
            }
            : {};

        return (
            <div className={containerClass}>
                <Editor
                    value={value}
                    multiline={multiline}
                    className={fieldClass + this.props.className}
                    inputProps= {{maxLength: limitValue > 0 && !useOwnOnChange ? limitValue : undefined}}
                    callOnChangeOnKeyUp={true}
                    onChange={useOwnOnChange ? this.onChange : undefined}
                    InputProps={InputProps}
                    {...fieldProps}
                />

                {value && limitValue && (!hideLimit && showOverLimit) ?
                    <span className={limitClass + limitClassName} style={limitBesideFieldStyle}>
                        <span className={overLimit ? "over-limit" : ""}>{messageLength}</span> / {limit}
                    </span>
                    : 
                    undefined
                }
            </div>
        );
    }
}

export default TextFieldWithLimit;