import React from "react";
import InsightChart from "../../InsightChart";
import * as d3 from "d3";
import D3Funnel from "d3-funnel";
import _ from "lodash";
import TaimerComponent from "../../../../TaimerComponent";
import InsightChartTable from "../../InsightChartTable";
import { stringToColour } from "../../../../helpers";

class InsightFunnel extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "dashboard/insights/InsightFunnel");
    this.funnel = React.createRef();
  }

  componentDidMount = () => {
    super.componentDidMount();
    this._renderFunnel();
    window.addEventListener('resize', this._handleResize);
  };

  componentWillUnmount = () => {
    super.componentWillUnmount();
    window.removeEventListener('resize', this._handleResize);
  }

  _handleResize = () => {
    this._renderFunnel();
  }

  componentDidUpdate(prevProps) {
    if (_.isEqual(prevProps, this.props)) {
      this._renderFunnel();
    }
  }

  _renderFunnel = () => {
    const node = this.funnel.current;
    if (!node) return;

    const { datasets, colors } = this.props;
    const totalSum = datasets.reduce((prev, val) => prev + _.sum(val.data) , 0);

    const chartData = datasets.map((obj, i) => ({
      ...obj,
      value: totalSum != 0 ? Math.round(_.sum(obj.data) / totalSum * 100) : 0,
      backgroundColor: colors[i] || stringToColour(obj.label) ,
    }));

    const options = {
      chart: {
        curve: {
          enabled: true,
          height: 20
        },
        height: 345,
        // width: "80%",
        bottomWidth: 1 / 3,
        bottomPinch: chartData.length == 1 ? 0 : 1
      },
      block: {
        dynamicHeight: true,
        minHeight: 22,
        fill: {
          type: "solid"
        }
      },
      label: {
        format: (l, f) => `${l} ${f}%`,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: "11px",
        fill: "#ffffff"
      },
      tooltip: {
        enabled: false,
        format: (l, f) => `${l} ${f}%`
      }
    };

    if (chartData.length == 0)
      return;

    let chart = new D3Funnel(node);
    chart.draw(chartData, options);
  };

  render() {
    return (
      <div style={{width: "80%", height: "100%"}}>
        <div ref={this.funnel}></div>
      </div>
    )
  }
}

const PipelineOverview = props => {
  const { currency, tr, data, multipipeline, ...otherProps } = props;
  const colors = ["#b2b9c9", "#c88ffb", "#ffa082", "#2589d7", "#f7bc59"];
  return (
    <InsightChart
      {...otherProps}
      className="pipeline-overview"
      title={tr("Pipeline Overview")}
      data={{
        sets: data.stages.map(stage => ({
          ...stage,
          values: [data.values[stage.id] || 0],
          // data: data.slider[stage.id],
          label: stage.name
        }))
      }}
      sliderColumns={["user", "project", "account", "value", "stage", "funnel", "closing_date"]}
      chartProps={{
        colors
      }}
      tableProps={{
        setTitle: multipipeline ? tr("Pipeline") : tr("Stage"),
        customColors: colors,
        subtitles: data.stages.map(stage => data.counts[stage.id] + " " + tr("projects")),
        displayFooter: true,
        extraColumns: !multipipeline ? [
          {
            title: tr("Probability"),
            getValue: item =>
              _.sum(item.values) * (item.probabilityPercentage)
          }
        ] : [],
      }}
      chartType={InsightFunnel}
      tableType={InsightChartTable}
      currency={currency}
    />
  );
};

export default PipelineOverview;
