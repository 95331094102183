import React from "react";
import ListCell from "./ListCell";

class CompositeListCell extends ListCell {
	static defaultProps = {...ListCell.defaultProps, ...{
		disableDefaultEditTrigger: false
		// Default props here. A bunch is inherited from ListCell.
	}};


	constructor(props) {
		super(props);
	}


	render() {
		const style 			 = { width: this.props.width + "px", flex: `${this.props.width} 1 0` };
		const className 		 = `cell ${this.props.owner?.props.className} ${this.props.className} ${this.state.inEditMode ? "editMode" : ""}`;
		const cellValueClassName = `cellValue ${this.textAlignClasses[this.props.textAlign]}`;

		return (
			<div 
				ref={this.cell} 
				className={className} 
				style={style} 
				onMouseDown={this.handleMouseDown} 
				onMouseUp={this.handleMouseUp} 
				onClick={() => !this.props.disableDefaultEditTrigger ? this.openEdit() : null} 
				tabIndex={this.props.editable || this.props.permanentEditMode ? "0" : "-1"}
				onFocus={this.handleFocus}>
				{(!this.state.inEditMode && !this.props.permanentEditMode) && <div className={cellValueClassName}>{this.props.children[0]}</div>}
				{(this.state.inEditMode || this.props.permanentEditMode) && this.props.children[1]}
			</div>
		);
	}
}

export default CompositeListCell;