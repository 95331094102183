import React from "react";
import { Drawer, Button } from "@mui/material";
import { Close } from "@mui/icons-material";

import TaimerComponent from "../../TaimerComponent";
import List from "../List";
import ProductCatalogListRow from "../rows/ProductCatalogListRow";
import DataHandler from "../../general/DataHandler";
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import "./CatalogListSlider.css";

import CatalogListSliderOverlay from "../overlays/CatalogListSliderOverlay";

class CatalogListSlider extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/lists/CatalogListSlider");

    this.state = {
      deleteConfirmationOpen: false,
      deleteDialogData: {}
    }
  }

  _getColumns = () => {

    const staticColumnConfig = {
      showMenu: false,
      resizeable: false,
      showResizeMarker: false,
      moveable: false,
      hideable: false,
      visibleInToolbar: true,
    };

    return [
      {
        field: "context",
        name: "context",
        header: "",
        columnHeaderType: (this.props.product && this.context.functions.checkPrivilege("products", "write", this.props.product.companies_id)) && "roundButton",
        width: 50,
        ...staticColumnConfig,
      },
      {
        field: "name",
        name: "name",
        header: this.tr("Catalog Name"),
        width: 200,
        ...staticColumnConfig,
      },
      {
        field: "edited_date",
        name: "edited_date",
        header: this.tr("Last Edited"),
        width: 150,
        ...staticColumnConfig,
      },
      {
        field: "edited_by_name",
        name: "edited_by_name",
        header: this.tr("Edited by"),
        width: 150,
        ...staticColumnConfig,
      },
      {
        field: "price_in_catalog",
        name: "price_in_catalog",
        header: this.tr("Price in Catalog"),
        width: 150,
        ...staticColumnConfig,
      },
    ];
  }

  unlinkFromCatalog = (catalog) => {
    const { product, getData, enqueueSnackbar } = this.props;
    this.setState({ deleteConfirmationOpen: false })
    DataHandler.put(
      {
        url: `products/company/${product.companies_id}/catalog/products`,
      },
      { catalogs: [catalog.id], products: [product.id], delete: 1 }
    )
      .done(() => {
        enqueueSnackbar(this.tr("Changes saved succesfully!"), {
          variant: "success",
        });
        setTimeout(() => {
          getData();
        }, 1000);
      })
      .fail((error) => {
        enqueueSnackbar(this.tr("Saving changes failed!"), {
          variant: "error",
        });
        console.log(error);
      });
  };

  _renderContent = () => {
    const { onClose, data, product, currency, addToCatalog } = this.props;
    return (
      <div className="content">
        <div className="top-bar">
          <div className="left">
            <div>
              <h2>{product && product.name}</h2>
              <h3>{this.tr("Linked to Catalog(s)")}</h3>
            </div>
          </div>
          <div className="right">
            <Button onClick={onClose}>
              <Close />
            </Button>
          </div>
        </div>
        <div className="bottom">
          <List
            data={data || []}
            columns={this._getColumns()}
            height="fitRemaining"
            minHeight={448}
            trimHeight={-20}
            rowKey="id"
            className="catalogAccountList"
            listRowType={ProductCatalogListRow}
            rowProps={{
              currency,
              sliderMode: true,
              unlinkFromCatalog: (data) => this.setState({ deleteConfirmationOpen: true, deleteDialogData: data }),
              company: this.props.product && this.props.product.companies_id
            }}
            noStateData={true}
            showOverlay={(data || []).length == 0}
            overlayComponent={CatalogListSliderOverlay}
            overlayProps={{
              linkToCatalogs: () => addToCatalog([product])
            }}
            roundbutton={() => addToCatalog([product])}
            showPageSelector={false}
          />
        </div>
      </div>
    );
  };

  _renderDeleteConfirmation = () => {
    return (
      <MassDialog
        onDialogClose={() => this.setState({ deleteConfirmationOpen: false })}
        onDialogSave={() => this.unlinkFromCatalog(this.state.deleteDialogData)}
        dialogType={"delete"}
        dialogProps={{
          wider: true,
          onCloseClick: () => this.setState({ deleteConfirmationOpen: false }),
          open: this.state.deleteConfirmationOpen,
          close: () => this.setState({ deleteConfirmationOpen: false }),
          header: this.tr("Remove product from catalog?"),
          warning: () => {
            return <p id="product-delete-dialog-warning">{this.tr("Are you sure you want to remove product from catalog: ${catalog}?", { catalog: this.state.deleteDialogData.name })}</p>;
          },
          onConfirm: () => {
            this.unlinkFromCatalog(this.state.deleteDialogData);
          }
        }}
      />
    )
  }

  render() {
    const { open, onClose } = this.props;

    return (
      <>
        <Drawer
          id="catalog-list-slider"
          anchor="right"
          open={open}
          onClose={onClose}
        >
          {this._renderContent()}
        </Drawer>

        {this.state.deleteConfirmationOpen && this._renderDeleteConfirmation()}
      </>  
    );
  }
}

export default CatalogListSlider;
