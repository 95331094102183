
  import TaimerComponent from '../TaimerComponent';
  
  interface Props {
      value: string;
      tr?: (text, replacers?: Record<string, string>) => string;
  }

export default class MultiCellRenderer extends TaimerComponent<Props> {
    render() {
        const { value, tr } = this.props;
        const displayValues: string[] = [];

        if (Array.isArray(value)) {
            for (const val of value) {
                const v = typeof val === "string" && tr && val.startsWith("[translate]")
                    ? tr(val.substring('[translate]'.length))
                    : (val || "");

                displayValues.push(v);
            }
        }
        else {
            const v = typeof value === "string" && tr && value.startsWith("[translate]")
                ? tr(value.substring('[translate]'.length))
                : (value || "");

            displayValues.push(v);
        }

        return displayValues.join(", ");
    }
}