import React from 'react';

import ToggleableLabelField from "./ToggleableLabelField";
import Select, { components } from 'react-select';
import CreatableSelect from "react-select/creatable";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { FlexChild } from "./FlexUtils";
import { TaimerTheme } from './../TaimerTheme';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { JssProvider } from 'react-jss';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import jssPreset from '@mui/styles/jssPreset';
import DataList from './../general/DataList';

// import "./LabelFieldGroup.css";


class LabelFieldGroup extends React.Component {
	static defaultProps = {
        editMode: undefined,
		fields: [],
		flex: true,
		flexWeight: 1,
        values: undefined,
        errorsInFields: [],
        editable: true,
        dontGenerateClassName: false
	};


	constructor(props) {
		super(props);

		this.generateClassName = createGenerateClassName({
			dangerouslyUseGlobalCSS: true
		});

		this.state = {
			editMode: props.editMode === undefined ? false : Boolean(props.editMode)
		};

		this.toggleEditMode = this.toggleEditMode.bind(this);
	}


	componentDidUpdate(prevProps, prevState) {
		if(prevProps.editMode !== this.props.editMode)
			this.setState({ editMode: this.props.editMode });
	}


	toggleEditMode(which = undefined) {
		if(!this.props.editable || this.props.editMode !== undefined || which !== undefined && this.state.editMode === which)
			return;

		this.setState({ editMode: which === undefined ? !this.state.editMode : which });
	}


	render() {
		if(this.props.length === 0)
			return null;

		const Wrapper 	   = this.props.flex === true ? FlexChild : "div";
		const wrapperProps = this.props.flex === true ? { weight: this.props.flexWeight, basis: this.props.flexBasis } : {};
		const requiredSpan = this.props.required ? <span className="requiredSpan"> *</span> : null;

		return (
            <ClickAwayListener onClickAway={() => this.toggleEditMode(false)}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={TaimerTheme}>
                        <JssProvider generateClassName={!this.props.dontGenerateClassName ? this.generateClassName : undefined}>
                            
                            <Wrapper {...wrapperProps} className={`labelFieldGroup ${this.props.className ? this.props.className : ""}`} onClick={() => this.toggleEditMode(true)}>
                                {this.props.title && <h4 title={this.props.tooltipMessage ? this.props.tooltipMessage : ""} onClick={this.props.onHeaderClick ? () => this.props.onHeaderClick() : null} className={this.props.headerClassName ? this.props.headerClassName : "" + this.props.onHeaderClick && ' toggle-header'}>{this.props.title}{requiredSpan}{this.props.headerIcon}</h4>}
                                {this.props.fields.map(field => {
                                    if (!field)
                                        return;

                                        if(field.editorType === Select || field.editorType === CreatableSelect || field.limitEditorType === CreatableSelect) {
                                            if(!field.hasOwnProperty("className"))
                                                field.className = "";    

                                        field.className += " reactSelect";
                                        field = { ...field, 
                                            placeholder: !field.showPlaceholder ? "" : field.placeholder,
                                            createOptionPosition: "first",
                                            classNamePrefix: "reactSelect",
                                            isDisabled: field.disabled || field.editable === false || !this.state.editMode
                                        };

                                        field.options = field.options === undefined || !Array.isArray(field.options) ? [] : field.options;
                                    }

                                    let props = {
                                        ...field,
                                        className: ((field.editable === false ? "notEditable" : "") + (field.disabled || !this.state.editMode ? " disabled" : "") + " paperLabelField") + " " + field.className,
                                        disabled: field.editable === false || field.disabled || !this.state.editMode,
                                        error: Array.isArray(this.props.errorsInFields) && this.props.errorsInFields.indexOf(field.name) > -1,
                                        paperFix: true,
                                        editMode: this.state.editMode
                                    };

                                    if(field.type === undefined && this.props.onTextFieldChange && typeof this.props.onTextFieldChange === "function") {
                                        props.onKeyUp = e => {
                                            let value = e.target.value;

                                            if(this.props.onTextFieldChange.length == 2)
                                                this.props.onTextFieldChange(field.name, value);
                                            else
                                                this.props.onTextFieldChange(value);
                                        }
                                    }

                                    if(this.props.values && this.props.values.hasOwnProperty(field.name)) {
                                        if(field.value !== undefined)
                                            props.value = field.value;
                                        else if(this.props.values[field.name] !== undefined)
                                            props.value = this.props.values[field.name];
                                        else
                                            props.value = null;
                                    } else if (this.props.useEmptyStringValue && !field.value) {
                                        props.value = ''
                                    }

                                    return <ToggleableLabelField {...props} components={field.components} />;
                                })}
                            </Wrapper>
                        </JssProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </ClickAwayListener>
        );
	}
}

export default LabelFieldGroup;
