import getAccountColumns from './AccountColumns';
import getColumnType, { ColumnDefinition, columnSorter } from './ColumnTypes';
import { CustomFields } from './CustomFields';
import getProjectColumns from './ProjectColumns';
import getUserColumns from './UserColumns';
import { filterColumns } from "./ColumnVisibility";

const columnOrder = [
    'billentries_id',
    'bill_id',
    'account',
    'customer_id',
    'company',
    'customer_account_manager',
    'project',
    'project_id',
    'creationdate_month',
    'invoice_type',
    'invoice_state',
    'currency_code',

];

interface Params {
    customFields: CustomFields;
    netvisorActive: boolean;
    procountorActive: boolean;
    checkPrivilege: (group: string, perm: string, company?: number) => boolean;
    tr: (text, replacers?: Record<string, string>) => string;
    settingsContext: any;
}

export default function getInvoicingColumns(params: Params) {
    const columns: ColumnDefinition[] = [        
        {
            field: 'billentries_id',
            headerName: 'Row ID',
            grandTotal: 'none',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'bill_id',
            headerName: 'Invoice number',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=invoices&action=view&id=$(id)"
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'company',
            headerName: 'Company',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'invoice_account',
            headerName: 'Invoiced account',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'invoice_type',
            headerName: 'Invoice type',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 0, "name": "Blank"},
                    {id: 1, "name": "Project material"},
                    {id: 10, "name": "Scheduled invoicing"},
                ],
                tr: params.tr
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'invoice_state',
            headerName: 'State',
            ...getColumnType('status', params.tr),
            cellRendererParams: {
                displayDatas: [
                    {id: 0, "name": "all", "color": "#dde3e8"},
                    {id: 5, "name": "Draft", "color": "#979797"},
                    {id: 1, "name": "waiting", "color": "#ffb822"},
                    {id: 2, "name": "sent", "color": "#2d9ff7"},
                    {id: 4, "name": "paid", "color": "#20C6A1"},
                    {id: 3, "name": "credited", "color": "#716aca"},
                    {id: 6, "name": "overdue", "color": "#f52b2b"}
                ],
                tr: params.tr
            }
        },
        {
            field: 'integration_used',
            headerName: 'Sent to Integration',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 1, "name": "Procountor"},
                    {id: 2, "name": "Robocapital"},
                    {id: 3, "name": "Fennoa"},
                    {id: 4, "name": "Wintime"},
                    {id: 5, "name": "Heeros"},
                    {id: 6, "name": "Talenom"},
                    {id: 7, "name": "Fivaldi"},
                    {id: 8, "name": "eFina"},
                    {id: 9, "name": "Netvisor"},
                    {id: 10, "name": "Fortnox"},
                    {id: 11, "name": "Meritaktiva"},
                ],
                tr: params.tr
            },
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'currency',
            headerName: 'Invoice currency',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'currency_rate',
            headerName: 'Invoice currency rate',
            ...getColumnType('number'),
            decimalAmount: 6,
            grandTotal: 'none',
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'creationdate',
            headerName: 'Invoice date',
            ...getColumnType('date', null, 'creationdate'),
        },
        {
            field: 'creationdate_month',
            headerName: 'Invoice date Month',
            ...getColumnType('month'),
        },
        {
            chartDataType: 'category',
            field: 'creationdate_year',
            headerName: 'Invoice date Year',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'creationdate_quarter',
            headerName: 'Invoice date Quarter',
            ...getColumnType('quarter'),
        },
        {
            field: 'duedate',
            headerName: 'Due date',
            ...getColumnType('date', null, 'duedate'),
        },
        {
            field: 'duedate_month',
            headerName: 'Due date Month',
            ...getColumnType('month'),
        },
        {
            chartDataType: 'category',
            field: 'duedate_year',
            headerName: 'Due date Year',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'duedate_quarter',
            headerName: 'Due date Quarter',
            ...getColumnType('quarter'),
        },
        {
            field: 'deliverydate',
            headerName: 'Delivery date',
            ...getColumnType('date', null, 'deliverydate'),
        },
        {
            field: 'deliverydate_month',
            headerName: 'Delivery date Month',
            ...getColumnType('month'),
        },
        {
            chartDataType: 'category',
            field: 'deliverydate_year',
            headerName: 'Delivery date Year',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'deliverydate_quarter',
            headerName: 'Delivery date Quarter',
            ...getColumnType('quarter'),
        },
        {
            field: 'vat',
            headerName: 'VAT %',
            ...getColumnType('percentage'),
        },
        {
            field: 'vatAmount',
            headerName: 'VAT',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            /*
            valueGetter: (params) => {
                if(!params.data) {
                    return '';
                }
                return params.data.quantity * params.data.value * params.data.vat;
            }
            */
        },
        {
            field: 'totalNoVat',
            headerName: 'Total (vat 0%)',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            /*
            valueGetter: (params) => {
                if(!params.data) {
                    return '';
                }
                return params.data.quantity * params.data.value;
            }
            */
        },
        {
            field: 'totalVat',
            headerName: 'Total',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            /*
            valueGetter: (params) => {
                if(!params.data) {
                    return '';
                }
                return params.data.quantity * params.data.value * (1 + params.data.vat);
            }
            */
        },
        {
            field: 'log_created',
            headerName: 'Creation date',
            ...getColumnType('date', null, 'log_created'),
        },
        {
            field: 'log_created_month',
            headerName: 'Creation date Month',
            ...getColumnType('month'),
        },
        {
            chartDataType: 'category',
            field: 'log_created_year',
            headerName: 'Creation date Year',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'log_created_quarter',
            headerName: 'Creation date Quarter',
            ...getColumnType('quarter'),
        },
        {
            field: 'paid_date',
            headerName: 'Paid date',
            ...getColumnType('date', null, 'paid_date'),
        },
        {
            field: 'row_type',
            headerName: 'Row type',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 0, "name": "Normal"},
                    {id: 1, "name": "Product"},
                    {id: 2, "name": "Material"},
                    {id: 3, "name": "CPQ"},
                ],
                tr: params.tr
            } 
        },
        {
            field: 'content_type',
            headerName: 'Material type',
            ...getColumnType('variable', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 1, "name": "Hour entry"},
                    {id: 2, "name": "Scheduled invoice"},
                    {id: 4, "name": "Expense"},
                    {id: 5, "name": "Quote row"},
                    {id: 6, "name": "Bill"},
                    {id: 8, "name": "Travel expense daily allowance"},
                    {id: 9, "name": "Travel expense mileage"},
                    {id: 10, "name": "Travel expense other"},
                    {id: 11, "name": "Travel expense mileage additional"},
                ],
                tr: params.tr
            } 
        },
        {
            field: 'product',
            headerName: 'Product',
            ...getColumnType('text'),
        },
        {
            field: 'cpq',
            headerName: 'CPQ',
            ...getColumnType('text'),
        },
        {
            field: 'description',
            headerName: 'Description',
            ...getColumnType('text'),
        },
        {
            field: 'paid_amount',
            headerName: 'Paid amount',
            ...getColumnType('currency'),
            grandTotal: 'sum_per',
            grandTotalPerField: 'bills_id',
            defaultAggFunc: 'sum',
        },
        {
            chartDataType: 'category',
            field: 'financial_year',
            headerName: 'Financial Year',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'payment_reference',
            headerName: 'Payment reference',
            ...getColumnType('text'),
        },
        {
            field: 'your_reference',
            headerName: 'Your reference',
            ...getColumnType('text'),
        },
        {
            field: 'terms',
            headerName: 'Terms of payment (d)',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'annotation',
            headerName: 'Notice period (d)',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'country',
            headerName: 'Invoicing address country',
            ...getColumnType('text'),
        },
        {
            field: 'billingstartdate',
            headerName: 'Material period start',
            ...getColumnType('date', null, 'billingstartdate'),
        },
        {
            field: 'billingenddate',
            headerName: 'Material period end',
            ...getColumnType('date', null, 'billingenddate'),
        },
        {
            field: 'worktask_product',
            headerName: 'Worktask/product',
            ...getColumnType('text'),
        },
        ...getProjectColumns(params),
        ...getAccountColumns(params),
        ...getUserColumns('creator', params)
    ];

    columns.sort(columnSorter(columnOrder));

    return filterColumns(
        columns, 
        "invoicing", 
        params.settingsContext, 
        "creator"
    );
}
