import { Portal } from '@mui/base';
import CloseIcon from '@mui/icons-material/Close';
import Image from "mui-image";
import React from "react";
import styles from "./OverlayImage.module.scss";

interface OverlayImageProps {
    active: boolean;
    uri: string;
    alt?: string;
    onClose?: () => void;
}

class OverlayImage extends React.Component<OverlayImageProps> {
    private escHandler = (e: KeyboardEvent) => {
        if(e.keyCode !== 27 || !this.props.active) {
            return;
        }

        this.handleClose();
    }

    private mouseUpHandler = (e: MouseEvent) => {
        const target = e.target as HTMLElement;

        if(target.classList.contains("mui-image-img")) {
            return;
        }

        this.handleClose();
    }

    handleClose = () => {
        if(!this.props.onClose) {
            return;
        }

        this.props.onClose();
    }

    componentDidMount() {
        if(this.props.active) {
            this.handleActivation();
        }
    }
    
    componentDidUpdate(prevProps: OverlayImageProps) {
        if(!prevProps.active && this.props.active) {
            this.handleActivation();
        }

        if(prevProps.active && !this.props.active) {
            this.handleDeactivation();
        }
    }

    handleActivation = () => {
        document.body.addEventListener("keyup", this.escHandler);
        document.body.addEventListener("mouseup", this.mouseUpHandler);
    }

    handleDeactivation = () => {
        document.body.removeEventListener("keyup", this.escHandler);
        document.body.addEventListener("mouseup", this.mouseUpHandler);
    }

    render() {
        const {
            active,
            uri,
            alt,
        }: {
            active: boolean;
            uri: string;
            alt?: string | undefined;
        } = this.props;

        return active 
            ? (
                <Portal container={document.body}>
                    <div className={styles.overlay}>
                        <CloseIcon 
                            className={styles.x} 
                            onClick={this.handleClose} />
                        <Image
                            duration={0}
                            className={styles.image}
                            wrapperClassName={styles.wrapper}
                            showLoading={true}
                            fit={"contain"}
                            src={uri} 
                            alt={alt ?? ''} />
                    </div>
                </Portal>
            ) : null;
            
    }
}

export default OverlayImage;
