import React from 'react'
import TaimerComponent from '../../TaimerComponent';
import DataHandler from "../../general/DataHandler";

import Log from "./Log";

import styles from "./LogBatchSlider.module.css";

class LogBatchSlider extends TaimerComponent {
    static defaultProps = {
        data: [],
        description: null,
        entityType: null,
        logType: null
    };

    constructor(props, context) {
        super(props, context, "LogBatchSlider"); 
    }

    render() {
        const { 
            data,
            description,
            entityType,
            logType
        } = this.props;

        return (
            <div className={styles.wrapper}>
                <div className={styles.description}>
                    {description !== null && description}
                </div>
                <Log 
                    batchSliderMode={true} 
                    data={data}
                    logType={logType}
                    entityType={entityType}
                />
            </div>
        );
    }
}

export default LogBatchSlider;
