import React from 'react';
import { Button } from '@mui/material';
import TaimerComponent from '../../TaimerComponent';
import OutlinedField from '../OutlinedField';
import DataHandler from '../DataHandler';

import './AddTag.scss';
import { withSnackbar } from 'notistack';

class AddTag extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'general/no-options/AddTag');
    }

    addNew = () => {
        const { selectProps } = this.props;
        const company = selectProps.company || this.context.userObject.companies_id;
        const tag = selectProps.inputValue;
        const data = { tag, type: selectProps.tagType };
        DataHandler.post({ url: `tags/save/${company}` }, { data })
            .done((t) => {
                this.props.enqueueSnackbar(this.tr('Tag ${tag} created!', { tag: data.tag }), {
                    variant: 'success',
                });
                selectProps.tagCreated && selectProps.tagCreated({ tag: data.tag, label: data.tag, name: data.tag, id: t.id, value: t.id });
                this.props.setValue();
            })
            .fail((response) => {
                if (response && response.responseJSON && response.responseJSON.error === 'TAG_EXISTS_AS_DISABLED') {
                    this.props.enqueueSnackbar(this.tr('Tag already exists, but is disabled!'), {
                        variant: 'error',
                    });
                }
            });
    };

    render() {
        if (this.props.selectProps.inputValue == '')
            return (
                <div className="add-tag">
                    <div>{this.tr('No tags')}</div>
                </div>
            );

        return (
            <div className="add-tag">
                <div>
                    <span className="primary">{this.props.selectProps.inputValue}</span> {this.tr('is not in tags yet.')}
                </div>
                <div className="details">
                    <OutlinedField label={this.tr('Tag')} value={this.props.selectProps.inputValue} disabled fullWidth />
                </div>
                <div className="controls">
                    <Button size="large" color="primary" onClick={this.addNew}>
                        {this.tr('Add a new tag')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default withSnackbar(AddTag);
