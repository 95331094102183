import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import TaimerComponent from '../TaimerComponent';
import _ from 'lodash';
import './ProfitLossLegend.css';
import cn from 'classnames';

class ProfitLossLegend extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "charts/ProfitLossLegend");
    }

    showHide = ar => {
        const { hiddenCharts, onChange } = this.props;

        let newHidden = [...hiddenCharts];

        const all = Array.isArray(ar) ? ar : [ar];

        const hidden = all.filter(x => hiddenCharts.indexOf(x) !== -1);

        if (hidden.length > 0) {
            newHidden = hiddenCharts.filter(x => all.indexOf(x) === -1);
        } else {
            for (const chart of all) {
                if (newHidden.indexOf(chart) === -1)
                    newHidden.push(chart);
            }
        }

        onChange && onChange(newHidden);
    }

    render() {
        const { charts, chartGroups, hiddenCharts } = this.props;

        return <div className="grid-block chart-legend">
            {_.map(chartGroups, group => {
                return <div key={group.id} className={cn("legend-box", group.id)}>
                    <h3 onClick={() => this.showHide(group.items)}>{group.name}</h3>

                    <ul>
                        {group.items.map((id) => {
                            const chart = charts[id];

                            if (!chart)
                                return null;

                            const visible = hiddenCharts.indexOf(id) === -1;

                            return <li key={id} className={cn(visible && "visible", !visible && "hidden", id)} onClick={() => this.showHide(id)}>
                                <div className="legend-color" style={{ backgroundColor: chart.backgroundColor }} />
                                <div className="legend-name">{chart.label}</div>
                            </li>
                        })}
                    </ul>
                </div>
            })}
        </div>
    }
}

export default ProfitLossLegend;