import React from "react";
import { CheckRounded } from "@mui/icons-material";
import TaimerAvatar from "../../../../general/TaimerAvatar";
import TaimerComponent from "../../../../TaimerComponent";

import cn from 'classnames';
import styles from "./AddUserToProjectPopup.module.scss";
import ProjectTreeDropdown from "../../../../projects/ProjectTreeDropdown";
import { format } from "date-fns";

export default class AddUserToProjectPopup extends TaimerComponent {
	state = {
		selected: undefined,
	};

	constructor(props, context) {
        super(props, context, "resourcing/views/ResourcingGrid/components/AddUserToProjectPopup");
	}

	_toggleSelection = user => {
		const selected = this.props.single ? [] : [...this.state.selected];
		const index = selected.indexOf(user.users_id);
		index != -1 ? selected.splice(index, 1) : selected.push(user.users_id);
		this.setState({ selected });
	};

	render() {
		const { users, onSave, onCancel, header, subtitle } = this.props;
		const { selected } = this.state;
		return (
			<div className={styles.dialog}>
				<div className={styles.headerContainer}>
					<div>
						<h1>{header}</h1>
						{subtitle && <p>{subtitle}</p>}
					</div>
				</div>
				<div className={styles.content}>
					<ProjectTreeDropdown 
						name="project" // TODO: Onko tarpeellinen?
						label={this.tr("Project")}
						value={selected?.id}
						queryParameters={{ 
							right: 'read',
							company: this.props.company,
							start: format(this.props.start, 'YYYY-MM-DD'),
							end: format(this.props.end, 'YYYY-MM-DD'),
						}}
						onSelect={e => {
							this.setState({selected: e})
						}}					
						/>

				</div>
				<div className={styles.dialogButtons}>
					<button
						className={styles.cancel}
						onClick={onCancel}
					>
						{this.tr("Close")}
					</button>
					<button
						disabled={!selected}
						onClick={() => selected && onSave(this.state.selected)}
					>
						{this.tr("Save")}
					</button>
				</div>
			</div>
		);
	}
}
