import React from 'react';
import TaimerComponent from '../TaimerComponent';
import styles from './ProjectStatusReasonDialog.module.scss';
import { DialogHeader, DialogContent, DialogFooter, DialogAction } from './DialogComponents';
import DataList from '../general/DataList';
import DataHandler from '../general/DataHandler';
import OutlinedField from '../general/OutlinedField';
import { ThumbUp, ThumbDown } from '@mui/icons-material';
import colors from '../colors';

interface Props {
    /** Type of dialog to show. Can be either won or lost. */
    type: 'won' | 'lost';
    /** The project being moved. Used to show the name & id of the project. */
    project: any;
    /** Called with reason data when save button is pressed. */
    onSave: (reason: string, reasonComment: string) => void;
}

interface State {
    statusReasons: any;
    selectedReason?: any;
    reasonComment: string;
    loadingStatusReasons?: boolean;
}

class ProjectStatusReasonDialog extends TaimerComponent<Props, State> {
    rightActions: (allowSaveButtonException) => DialogAction[];
    leftActions: DialogAction[];
    constructor(props, context) {
        super(props, context, 'dialogs/ProjectStatusReasonDialog');
        this.rightActions = (allowSaveButtonException = false) => [
            {
                title: this.tr('Save'),
                action: this.onSave,
                disabled: this.state.loadingStatusReasons || ((!this.state.loadingStatusReasons && !allowSaveButtonException) && !this.state.selectedReason),
            },
        ];
        this.leftActions = [
            {
                title: this.tr('Cancel'),
                action: this.onCancel,
                variant: 'cancel',
            },
        ];
        this.state = {
            statusReasons: [],
            reasonComment: '',
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.getStatusReasons();
    };

    onSave = () => {
        const { selectedReason, reasonComment } = this.state;
        this.context.functions.closeDialog();
        this.props.onSave(selectedReason || { name: undefined, id: undefined }, reasonComment);
    };

    onCancel = () => {
        this.context.functions.closeDialog();
    };

    getStatusReasons = () => {
        const { project } = this.props;
        this.setState({ loadingStatusReasons: true }, async () => {
            const { statusReasons } = await DataHandler.get({ url: `settings/company/${project.companies_id}/project/status_reasons` });
            this.setState({ statusReasons, loadingStatusReasons: false });
        });
    };

    onSelectReason = (selectedReason) => this.setState({ selectedReason });
    onReasonCommentChanged = (e) => {
        this.setState({ reasonComment: e.target.value });
    };
    render() {
        const { statusReasons, selectedReason, reasonComment, loadingStatusReasons } = this.state;
        const { type, project } = this.props;
        const multipleString = type == 'won' ? this.tr('Please select a reason before moving these deals to won.') : this.tr('Please select a reason before moving these deals to lost.');
        const descriptionBase = project.multiple ? multipleString : (type == 'won' ? this.tr('Please select a reason before moving deal ${project} to won.') : this.tr('Please select a reason before moving deal ${project} to lost.'));
        const descriptionWords = descriptionBase.split(' ');
        const description: any = descriptionWords.map((word: string, i) => {
            const postFix = i == descriptionWords.length - 1 ? '' : ' ';
            const projectIdString = project.project_id == 'unsaved' ? '' : ` (${project.project_id})`;
            const projectName = project.name ? project.name : project.project;
            const projectLabel = project.customer ? `${project.customer} / ${projectName}${projectIdString}` : `${projectName}${projectIdString}`;
            return word == '${project}' ? (
                <strong>
                    {projectLabel}
                    {postFix}
                </strong>
            ) : (
                `${word}${postFix}`
            );
        });
        return (
            <div id={styles.projectStatusReasonDialog}>
                <DialogHeader
                    icon={type == 'won' ? <ThumbUp /> : <ThumbDown />}
                    iconColor={type == 'won' ? colors.greenish_cyan : colors.carnation_pink}
                    title={this.tr('Move Deal')}
                    onClose={this.onCancel}
                />
                <DialogContent>
                    <p className={styles.description}>{description}</p>
                    <DataList
                        //@ts-ignore
                        label={loadingStatusReasons ? this.tr('Loading reasons...') : (statusReasons[type] || []).length == 0 ? this.tr("No reasons defined") : (type == 'won' ? this.tr('Select won reason') : this.tr('Select lost reason'))}
                        name="reason"
                        isDisabled={loadingStatusReasons || (statusReasons[type] || []).length == 0}
                        value={selectedReason}
                        options={(statusReasons[type] || []).map((reason) => ({ ...reason, label: reason.name }))}
                        onChange={this.onSelectReason}
                        shownCount={20}
                    />
                    <OutlinedField multiline label={this.tr('Add a comment')} name="comment" value={reasonComment} fullWidth={true} onChange={this.onReasonCommentChanged} />
                    <DialogFooter leftActions={this.leftActions} rightActions={this.rightActions((statusReasons[type] || []).length == 0)} />
                </DialogContent>
            </div>
        );
    }
}

export default ProjectStatusReasonDialog;
