import React from 'react';

import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import NotificationContent from "./NotificationContent";
import Card from '@mui/material/Card';
import withStyles from '@mui/styles/withStyles';

const progressNotificationStyles = {
    card: {
        boxSizing: "border-box",
        padding: "0px 24px",
        backgroundColor: "#2d9ff7",
        minWidth: "336px", 
        color: "#fff"
    },
    span: {
        lineHeight: "48px" 
    },
    progress: {
        width: "14px !important",
        height: "14px !important",
        marginTop: "17px",
        marginRight: "17px",
        color: "#fff",
        float: "left"
    }
};

class ProgressNotification extends React.Component {
    static defaultProps = {
        cardColor: undefined,
        progressVariant: "indeterminate",
        message: "(placeholder)",
        totalTicks: undefined
    };


    constructor(props) {
        super(props);

        this.state = {
            progress: 0.0 
        };

        this.ticks = 0;

        this.setProgress    = this.setProgress.bind(this);
        this.incrementTicks = this.incrementTicks.bind(this);
    }


    setProgress(value) {
        if(value >= 1.0)
            value = 1;

        this.setState({
            progress: value 
        });
    }


    incrementTicks(inc = 1) {
        if(this.props.totalTicks === undefined)
            throw new Error("Attempting to use incrementTicks without defining props.totalTicks.");

        this.ticks += inc; 

        this.setProgress(this.ticks / this.props.totalTicks);
    }


    render() {
        const { classes, innerRef } = this.props;
        const progress    = parseInt(this.state.progress * 100);

        return (
            <NotificationContent innerRef={innerRef}>
                <CircularProgress 
                    className={classes.progress}
                    variant={this.props.progressVariant} 
                    value={progress}
                />
                <span className={classes.span}>{this.props.message}</span> 
            </NotificationContent>
        );
    }
}

ProgressNotification.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ForwarderRefComponent = React.forwardRef((props, ref) => <ProgressNotification innerRef={ref} {...props}/>)

export default withStyles(progressNotificationStyles)(ForwarderRefComponent);
