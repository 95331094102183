import React, { Component } from 'react';
import { filterByText } from '../helpers';
import _ from 'lodash';
import TaimerComponent from '../TaimerComponent';
import { SettingsContext } from '../SettingsContext';
import debounce from 'lodash/debounce';
import DataHandler from './DataHandler';
import DataList from './DataList';
import ReactSelect from 'react-select';

export default class FilterDropdownServer extends TaimerComponent {

	static contextType = SettingsContext;

	static defaultProps = {
		jsonKey: "",
		serverLimit: 50,
		useLocalFiltering: true,
	}

	constructor(props, context) {
        super(props, context, "general/FilterDropdownServer");

        this.state = {
			suggestItems: [],
			lastSearch: undefined,
			isLoading: false,
		};

		this.allItems = [];
		
		this.refField = React.createRef();
	}

	componentDidMount = () => {
		super.componentDidMount();
		this.fetchFromWeb('');
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { params, value } = this.props;
		const { params: prevParams, value: prevValue } = prevProps;

		const searchValue = value ? value.name : '';

		if (!_.isEqual(params, prevParams) || !_.isEqual(prevValue, value))
		{
			this.setState({suggestItems: []});
			this.updateItems(searchValue, true);
		}
	}

	updateItems = async (search, force = false) => {
		const { useLocalFiltering, serverLimit } = this.props;
		const { suggestItems: lastSuggestItems, lastSearch } = this.state;

		// Skip if same as last search
		if (lastSearch === search && !force)
			return;

		this.fetchFromWeb(search);
	}

	fetchFromWeb = debounce(async (search) => {
		const { url, jsonKey, module, action, params, serverLimit, userid, value } = this.props;

		if (params === false)
		{
			this.setState({suggestItems: [], lastSearch: search, isLoading: false});
			return;
		}

		this.setState({isLoading: true});

		let suggestItems = await DataHandler.get({url, ...params, name: search, limit: serverLimit})

		const options = [];
		this.allItems = suggestItems;
                
		if (userid) {
			const freelancerString = this.tr("freelancer");
			suggestItems = suggestItems.filter(function(item) {
				if (item.companies_id < 1) item.name = `${item.name} (${freelancerString})`
				if(item.id == userid) options.push({value: item.id, label: item.name});
				return item.id != userid
			})
		}	

		suggestItems.forEach( (v,i) => options.push({value: v.id, label: v.name, v}));
                
               

		this.setState({suggestItems, options, lastSearch: search, isLoading: false}, () => {
			if(value && value.id == 0 && url == "dialogs/customerscontacts" && suggestItems.length > 0) {
				this.onChange({value: suggestItems[0].id}, {action: "select-option"});
			}	
		});
	}, 1000)
	
	onFocus = (e) => {
		const { value } = this.props;

		this.updateItems(''/*value.name*/);

		if (this.props.onFocus)
			this.props.onFocus(e);
	}

	onChange = (value, { action }) => {
		const { suggestItems } = this.state;

		if (action === "select-option")
		{
			const selectedVal = this.allItems.find(x => x.id === value.value);

			const e = {
				target: {
					name: this.props.name,
					value: selectedVal,
				}
			}

			if (this.props.onChange)
				this.props.onChange(e);
		}
		else if (action === "pop-value")
		{
			const e = {
				target: {
					name: this.props.name,
					value: {id: 0, name: ''},
				}
			}

			if (this.props.onChange)
				this.props.onChange(e);

			this.updateItems('');
		} 
		else if (action === "clear")
		{
			const e = {
				target: {
					name: this.props.name,
					value: {id: 0, name: ''},
				}
			}

			if (this.props.onChange)
				this.props.onChange(e);

			this.updateItems('');
		}
	}

	onInputChange = (value, { action }) => {
		if (action === "input-change")
		{
			this.updateItems(value);
		}
	}

	focus = () => {
		if (this.refField.current)
			this.refField.current.focus();
	}

	render() {
		const { options, isLoading } = this.state;
		const { className, value, hide, unclearable } = this.props;

		const val = value && value.id && {
			value: value.id,
			label: value.name,
		}
		let clearable = true;
		if(unclearable) clearable = false;

		if (hide)
			return null;

		const { label, error, ...rest } = this.props;

		const textFieldProps = {
			label,
			error,
			InputLabelProps: {
				shrink: val ? true : undefined,
			}
		};

		return <div className={className}><DataList
			component={ReactSelect}
			ref={this.refField}
			{...rest} 
			value={val}
			isLoading={isLoading}
			options={options}
			onChange={this.onChange}
			openMenuOnFocus
			onInputChange={this.onInputChange}
			textFieldProps={textFieldProps}
			isClearable={clearable}
			 /></div>
	}
}