/* Column order defines the final columns, 
	'search' - the presense of the field in advanced search autocomplete query,
	'time_span' as a column needs 'dynamicColumnsDefinitions' in properties, so it can be populated in ReportsTab column definition function
	NB! if tag field is included in search, one must define tagtypes.*/
import parse from 'date-fns/parse';

const ReportsDefinitions = {

	getDefinitions: () => {

		const definitions = {

			reports: {

				wintime: {
					wintime_unfinished_stock: {
						name: "Unfinished Stock",
						sumText: "", 
						is_default: "1",
						table_width: "1800px",
						noPagination: true, 
						column_order: [
							"supplier",
							"customers_nr",
							"customers",
							"projects_nr",
							"projects",
							"total_sum",
						],
						hideDateRange: true,
						hideSavedSearch: true,
						hideAdvancedSearch: true,
						filters: [
							{name: "dateFilter"}
						],
						getSummaries: (data) => {
							const summaries = [];

							if (data.moved) {
								summaries.push({
									title: 'Moved',
									type: 'date',
									value: parse(data.moved, 'YYYY-MM-DD', new Date()),
								})
							}

							if (data.billing_period) {
								summaries.push({
									title: 'Billing Period',
									value: data.billing_period,
								})
							}

							if (data.voucher_number) {
								summaries.push({
									title: 'Voucher Number',
									value: data.voucher_number,
								})
							}

							return summaries;
						}
					},
				},

				sales: {
					projects_backlog: {
						name: "Backlog",
						is_default: "1",
						sumText: "Total",
						table_width: "2000px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: false,
						column_order: [
							"name",
							"projects_nr",
							"customers",
							"projects",  
							"branch_of_business",
							"project_status",
							"project_value",
							"gross_total",
							"invoiceable_hours_amount",
							"project_targeted_purchases",
							"project_targeted_expenses",
							"project_backlog",
							"project_backlog_undone_work",
						],
						presentation: {
							name:            				(data, props, listRef, {translate}) => { 
								if (data.groupTitle)
									return data.groupTitle || '-';
									
								if (data._type === 'total') {
									return `${data.group !== undefined ? `${data.group || '-'} ${translate('Total')}` : translate('All Total')}:`;
								}
								
								return data.group || '-'; 
							},
							projects_nr:            		(data, props, listRef, sharedData) => data.project_id,
							customers:              		(data, props, listRef, sharedData) => data.customers,
							projects:               		(data, props, listRef, sharedData) => data.project,
							branch_of_business:     		(data, props, listRef, sharedData) => data.branch_of_business,
							project_status:					(data, props, listRef, {translate}) => data._type === "base" && (translate(data.locked ? 'Locked' : 'Active')),
							project_value:					(data, props, listRef, {presentCurrency}) => presentCurrency(data.revenue),
							gross_total:					(data, props, listRef, {presentCurrency}) => presentCurrency(data.invoiced_total),
							invoiceable_hours_amount:		(data, props, listRef, {presentCurrency}) => presentCurrency(data.invoiceable_hours_amount),
							project_targeted_purchases:		(data, props, listRef, {presentCurrency}) => presentCurrency(data.project_targeted_purchases),
							project_targeted_expenses:		(data, props, listRef, {presentCurrency}) => presentCurrency(data.project_targeted_expenses),
							project_backlog:				(data, props, listRef, {presentCurrency}) => presentCurrency(data.backlog),
							project_backlog_undone_work:	(data, props, listRef, {presentCurrency}) => presentCurrency(data.backlog_undone_work),
						},
						groupByOptions: [
							{value: 'sales_agent', label: 'Sales agent'},
							{value: 'project_manager', label: 'Project manager'},
							{value: 'branch_of_business', label: 'Category'},
							{value: 'project_type', label: 'Project Type'},
							{value: 'pipeline', label: 'Pipeline'},
							{value: 'pipeline_stage', label: 'Pipeline Stage'},
							{value: 'customer', label: 'Account'},
							{value: 'customer_group', label: 'Account group'},
							{value: 'account_manager', label: 'Account manager'},
							{value: 'enterprise_group', label: 'Enterprise group', condition: (data) => data.enterprise_groups},
							// {value: 'project_type', label: 'Project Type'},
						],
						filters: [
							// {name: "customerFilter", dataClass: "customers"},
							// {name: "projectFilter", dataClass: "projects"},
							// {name: "userFilter", dataClass: "users"}
						],				
						switches: [
							// {id: 'show_export_hierarhia', name: "Show export units hierarhia"},
							// {id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
						],
						defaultFilterValues: [
							{name: "groupBy", type: "filterValues", value: 'project_manager'},
						],
						getColumnSaveKey: (filterValues) => [filterValues.groupBy],
					},
					sales_overview: {
						name: "Sales overview",
						sumText: "Total",
						table_width: "2000px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						hideDateRange: true, 
						rowClassNames: {parent: 'freetext-monthly-row', child: ''},
						multiCompanyQueries: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						isNotSortable: true,
						column_order: [
							"free_summary_text",
							"total_sum",
							"time_span",							
						],
						presentation: {
							free_summary_text:      (data, props, listRef, sharedData) => [sharedData.translate(data.free_summary_text), { title: sharedData.getCellTitleString(data.id)}],
							total_sum: 			 	(data, props, listRef, sharedData) => [sharedData[sharedData.getOptionalPresentation(data, {field: 'free_summary_text', fieldValue: 'win rate', trueFunctionName: 'presentPercentage', falseFunctionName: 'presentCurrency'})](data.total_sum), { align: "right"}],
							time_span: 			 	(data, props, listRef, sharedData) => [sharedData[sharedData.getOptionalPresentation(data, {field: 'free_summary_text', fieldValue: 'win rate', trueFunctionName: 'presentPercentage', falseFunctionName: 'presentCurrency'})](data.time_span), { align: "right"}],
						},
						filters: [
							{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "groupByFilter", label: "View pipeline data by"},
							{name: "customerFilter", dataClass: "customers"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						groupByOptions: [
							{id: 'pipeline', label: 'Added to pipeline'},
							{id: 'closing', label: 'Closing date'},
							{id: 'event', label: 'Event end date', optionConditions: [{type: 'companySettings', name: 'use_events'}]}, //cumulative!
						],
						defaultFilterValues: [
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['0']},
							{name: "groupByFilter", type: "filterValues",  value: 'pipeline'},
						],
						switches: [
							{id: 'view_quantity', name: "View by quantity"},
							{id: 'hide_recognitions', name: "Hide revenue recognition data", switchConditions:[{conditionType: 'switch-automation', name: "hide_sales", value: true}]},
							{id: 'hide_sales', name: "Hide sales revenue data", switchConditions:[{conditionType: 'switch-automation', name: "hide_recognitions", value: true}]},
						],
						//celltooltips are for possibly cryptic free text rows presenting some sort of summary
						cellTooltips: {
							3: "Number of won opportunities divided by the total number of opportunities over a given time",
							4: "Showing static data of all project added to pipeline for each month",
							5: "Actual data of active projects in the Pipeline, added date and current value",
							6: "Actual data of active projects in the Pipeline, added date and current value calculated based on pipeline stages probalility percentage",
						},
						getColumnSaveKey: (filterValues) => [filterValues.groupByFilter],
					},
                    sales_report: {
                        name: "Sales report",
						sumText: "Total",
						table_width: "2000px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						hideDateRange: false, 
						multiCompanyQueries: true,
						isNotSortable: true,
                        column_order: [
							"sales_agents",
							"customers",
							"projects",
                            "subprojects",
                            "project_status",
                            "funnels",
                            "stages",
                            "additional_info",
                            "funnel_change_date",
                            "closing_date",
                            "event_end_date",
                            "quote_sales",
                            "quote_project_margin",
                            "project_margin",
                            "quote_sales_margin",
                            "sales_margin",
						],
                        presentation: {
							customers:              (data, props, listRef, sharedData) => data.customers,
							enterprise_groups:      (data, props, listRef, sharedData) => data.enterprise_groups,
							projects:               (data, props, listRef, sharedData) => data.projects,
                            subprojects:            (data, props, listRef, sharedData) => data.subprojects,
							team:               	(data, props, listRef, sharedData) => data.team,
							project_types:          (data, props, listRef, sharedData) => data.project_types,
							customership_group:     (data, props, listRef, sharedData) => data.customership_group,
							reporting_group:        (data, props, listRef, sharedData) => data.reporting_group,
							branch_of_business:     (data, props, listRef, sharedData) => data.branch_of_business,
                            stages:                 (data, props, listRef, sharedData) => data.stages,
                            additional_info:        (data, props, listRef, sharedData) => data.misc_info,
                            funnel_change_date:     (data, props, listRef, sharedData) => data.projects_pipelines_date,
                            closing_date:           (data, props, listRef, sharedData) => data.closing_date,
                            event_end_date:         (data, props, listRef, sharedData) => data.event_enddate,
                            funnels:                (data, props, listRef, {translate}) => data.pipelines_id > 0 ? data.pipeline : (data.status == 1 ? translate('Won deal') : (data.status == 5 ? translate('Internal') : data.pipeline)),
                            project_status:			(data, props, listRef, {translate}) => data._type !== "total" && (translate(data.locked > 0 ? (data.locked < 2 ? (data.pipelines_id > 0 ? 'Lost' : 'Locked') : 'On hold') : 'Active')),
                            quote_sales:            (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.quote_sales), { align: "right" }],
                            quote_project_margin:   (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.quote_project_margin), { align: "right" }],
                            project_margin: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.project_margin), { align: "right" }],
                            quote_sales_margin: 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.quote_sales_margin), { align: "right" }],
                            sales_margin: 		    (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.sales_margin), { align: "right" }],
						},
                        filters: [
                            //TODO: TR-561, remove "Search all fields"
							{name: "viewByFilter", label: "View by date"},
                            {name: "groupByFilter", label: "Group by"},
                            {name: "projectStatusFilter", label: "Status"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						viewByOptions: [
                            {id: 'funnel_change', label: 'Funnel change date'},
							{id: 'closing', label: 'Closing date'},
							{id: 'event', label: 'Event end date', optionConditions: [{type: 'companySettings', name: 'use_events'}]},
						],
                        groupByOptions: [
                            {id: 'sales_agent', label: 'Sales agent'},
							{id: 'customer', label: 'Customer'},
                        ],
						defaultFilterValues: [
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['0']},
							{name: "groupByFilter", type: "filterValues",  value: 'sales_agent'},
                            {name: "viewByFilter", type: "filterValues",  value: 'funnel_change'},
						],
                    }
				},
				hourReports: {
					company_report: {
						name: "Customers",
						is_default: "1",
						sumText: "Total",
						subTotals: {customers: 'customers_id', projects: "projects_id"},
						table_width: "2000px", 
						useVirtualized: true,
						useScrollPaging: true,
						pageLimit: 500,
						scrollTriggerLimit: 400,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						totalFields: {names: ['hours'], _type: 'total', totalTitle: 'Total', align: 'right', id: 'n'},
						column_order: [
							"company",
							"customers",
							"enterprise_groups",
							"projects",  
							"users",  
							"team",
							"hours", 
							"project_types", 
							"customership_group", 
							"reporting_group",
							"branch_of_business",
						],
						presentation: {
							company:               	(data, props, listRef, sharedData) => data.company,
							customers:              (data, props, listRef, sharedData) => data.customers,
							enterprise_groups:      (data, props, listRef, sharedData) => data.enterprise_groups,
							projects:               (data, props, listRef, sharedData) => data.projects,
							users:               	(data, props, listRef, sharedData) => data.users,
							team:               	(data, props, listRef, sharedData) => data.team,
							hours:               	(data, props, listRef, sharedData) => [sharedData.presentHours(data.hours), { align: "right" }],
							project_types:          (data, props, listRef, sharedData) => data.project_types,
							customership_group:     (data, props, listRef, sharedData) => data.customership_group,
							reporting_group:        (data, props, listRef, sharedData) => data.reporting_group,
							branch_of_business:     (data, props, listRef, sharedData) => data.branch_of_business,
						},
						filters: [
							/*{name: "customerFilter", dataClass: "customers"},
							{name: "projectFilter", dataClass: "projects"},*/
							{name: "userFilter", dataClass: "users"}
						],				
						switches: [
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
						]
					},
					customers_daily: {
						name: "Customers daily",
						sumText: "Total",
						table_width: "2600px", 
						useVirtualized: true,
						useScrollPaging: true,
						pageLimit: 500,
						scrollTriggerLimit: 400,						
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						totalFields: {names: ['hours'], _type: 'total', totalTitle: 'Total', align: 'right', id: 'n'},
						column_order: [
							"company",
							"date", 
							"customers",
							"enterprise_groups",
							"projects",
							"project_types", 
							"users",
							"team", 
							"reporting_group",
							"work_types",
							"starttime",
							"endtime",							
							"hours",
							"description" ,
							"hour_task",
							"customership_group",
							"branch_of_business"
						],
						presentation: {
							company:               	(data, props, listRef, sharedData) => data.company,
							date:               	(data, props, listRef, sharedData) => [sharedData.presentDate(data.date), { align: "right" }],
							customers:              (data, props, listRef, sharedData) => data.customers,
							enterprise_groups:      (data, props, listRef, sharedData) => data.enterprise_groups,
							projects:               (data, props, listRef, sharedData) => data.projects,
							project_types:          (data, props, listRef, sharedData) => data.project_types,
							users:               	(data, props, listRef, sharedData) => data.users,
							team:               	(data, props, listRef, sharedData) => data.team,
							reporting_group:        (data, props, listRef, sharedData) => data.reporting_group,
							work_types: 	        (data, props, listRef, sharedData) => data.work_types,
							starttime:              (data, props, listRef, sharedData) => [sharedData.presentTime(data.starttime), { align: "right" }],
							endtime:              	(data, props, listRef, sharedData) => [sharedData.presentTime(data.endtime), { align: "right" }],
							hours:               	(data, props, listRef, sharedData) => [sharedData.presentHours(data.hours), { align: "right" }],
							description:     		(data, props, listRef, sharedData) => data.description,
							hour_task:     			(data, props, listRef, sharedData) => data.hour_task,
							customership_group:     (data, props, listRef, sharedData) => data.customership_group,
							branch_of_business:     (data, props, listRef, sharedData) => data.branch_of_business,
						},
						filters: [
							/*{name: "customerFilter", dataClass: "customers"},
							{name: "projectFilter", dataClass: "projects"},*/
							{name: "userFilter", dataClass: "users"}
						],				
						switches: [
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
						]
					},
					employees_report: {
						name: "Employees",
						sumText: "Total",
						table_width: "2200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						useScrollPaging: true,
						pageLimit: 500,
						scrollTriggerLimit: 400,						
						multiCompanyQueries: true,
						totalFields: {names: ['hours'], _type: 'total', totalTitle: 'Total', align: 'right', id: 'n'},
						column_order: [
							"users",
							"team",
							"company",
							"customers", 
							"enterprise_groups",
							"projects",
							"work_types", 
							"reporting_group",
							"hours",
							"description",
							"project_types",
							"branch_of_business",
							"division"
						],
						presentation: {
							users:               	(data, props, listRef, sharedData) => data.users,
							team:               	(data, props, listRef, sharedData) => data.team,
							company:               	(data, props, listRef, sharedData) => data.company,
							customers:              (data, props, listRef, sharedData) => data.customers,
							enterprise_groups:      (data, props, listRef, sharedData) => data.enterprise_groups,
							projects:               (data, props, listRef, sharedData) => data.projects,
							work_types: 	        (data, props, listRef, sharedData) => data.work_types,
							reporting_group:        (data, props, listRef, sharedData) => data.reporting_group,
							hours:               	(data, props, listRef, sharedData) => [sharedData.presentHours(data.hours), { align: "right" }],
							description:          	(data, props, listRef, sharedData) => data.description,
							project_types:          (data, props, listRef, sharedData) => data.project_types,
							branch_of_business:     (data, props, listRef, sharedData) => data.branch_of_business,
							division:  			    (data, props, listRef, sharedData) => data.division,
						},
						filters: [
							/*{name: "customerFilter", dataClass: "customers"},
							{name: "projectFilter", dataClass: "projects"},*/
							{name: "userFilter", dataClass: "users"}
						],				
						switches: [
							{id: 'show_singlecompany_hours', name: "Show employee's company hours only", switchConditions:[{conditionType: 'addon', name: "multicompany_hoursreport", value: 1}]},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
						]
					},
					employees_daily: {
						name: "Employees Daily",
						sumText: "Total",
						table_width: "2200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						useScrollPaging: true,
						pageLimit: 500,
						scrollTriggerLimit: 400,						
						multiCompanyQueries: true,
						totalFields: {names: ['hours'], _type: 'total', totalTitle: 'Total', align: 'right', id: 'n'},
						column_order: [
							"date",
							"users",
							"team",
							"company",
							"customers",
							"enterprise_groups",
							"projects",
							"work_types", 
							"reporting_group",
							"starttime",
							"endtime",
							"hours",
							"description",
							"hour_task",
							"project_types",
							"branch_of_business",
							"division",
							"netvisor_id"
						],
						presentation: {
							date:               	(data, props, listRef, sharedData) => [sharedData.presentDate(data.date), { align: "right" }],
							users:               	(data, props, listRef, sharedData) => data.users,
							team:               	(data, props, listRef, sharedData) => data.team,
							company:               	(data, props, listRef, sharedData) => data.company,
							customers:              (data, props, listRef, sharedData) => data.customers,
							enterprise_groups:      (data, props, listRef, sharedData) => data.enterprise_groups,
							projects:               (data, props, listRef, sharedData) => data.projects,
							work_types: 	        (data, props, listRef, sharedData) => data.work_types,
							reporting_group:        (data, props, listRef, sharedData) => data.reporting_group,
							starttime:              (data, props, listRef, sharedData) => [sharedData.presentTime(data.starttime), { align: "right" }],
							endtime:              	(data, props, listRef, sharedData) => [sharedData.presentTime(data.endtime), { align: "right" }],
							hours:               	(data, props, listRef, sharedData) => [sharedData.presentHours(data.hours), { align: "right" }],
							description:          	(data, props, listRef, sharedData) => data.description,
							hour_task:     			(data, props, listRef, sharedData) => data.hour_task,
							project_types:          (data, props, listRef, sharedData) => data.project_types,
							branch_of_business:     (data, props, listRef, sharedData) => data.branch_of_business,
							division:  			    (data, props, listRef, sharedData) => data.division,
							netvisor_id:            (data, props, listRef, sharedData) => data.netvisor_id,
						},
						filters: [
							/*{name: "customerFilter", dataClass: "customers"},
							{name: "projectFilter", dataClass: "projects"},*/
							{name: "userFilter", dataClass: "users"}
						],				
						switches: [
							{id: 'show_singlecompany_hours', name: "Show employee's company hours only", switchConditions:[{conditionType: 'addon', name: "multicompany_hoursreport", value: 1}]},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
						]
					},
					hour_balance: {
						name: "Hour Balance",
						sumText: "Total",
						table_width: "2200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						hideDateRange: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"users",
							"team",
							"balance_start",
							"previous_balance",
							"expected_hours",
							"monthly_hours",
							"current_balance",
							"timespan_changes",							
						],
						presentation: {
							company:               	(data, props, listRef, sharedData) => data.company,
							users:               	(data, props, listRef, sharedData) => data.users,
							team:               	(data, props, listRef, sharedData) => data.team,
							balance_start:          (data, props, listRef, sharedData) => [sharedData.presentDate(data.balance_start), { align: "right" }],
							previous_balance:       (data, props, listRef, sharedData) => [sharedData.presentHours(data.previous_balance), { align: "right" }],
							expected_hours:         (data, props, listRef, sharedData) => [sharedData.presentHours(data.expected_hours), { align: "right" }],
							monthly_hours:          (data, props, listRef, sharedData) => [sharedData.presentHours(data.monthly_hours), { align: "right" }],
							current_balance:        (data, props, listRef, sharedData) => [sharedData.presentHours(data.current_balance), { align: "right" }],
							timespan_changes:       (data, props, listRef, sharedData) => [sharedData.presentHours(data.timespan_changes), { align: "right" }],
						},
						filters: [
							{name: "startDateFilter"},
							{name: "dateFilter"},
							{name: "userFilter", dataClass: "users"}
						],
						tooltipTexts: [
							{column: 'timespan_changes', text: "Hours edited manually during chosen timeperiod"},						
						]						
					},
					invoice_rate: {
						name: "Invoice Rate",
						sumText: "Total",
						table_width: "2200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"users",
							"team",
							"expected_hours",
							"missed_hours",
							"expected_missed",
							"invoice_rate_hours",
							"invoiceable_hours",
							"non_invoiceable_hours",
							"done_invoiceable",
							"invoiceable_expected"							
						],
						presentation: {
							company:               	(data, props, listRef, sharedData) => data.company,
							users:               	(data, props, listRef, sharedData) => data.users,
							team:               	(data, props, listRef, sharedData) => data.team,
							expected_hours:         (data, props, listRef, sharedData) => [sharedData.presentHours(data.expected_hours), { align: "right" }],
							missed_hours:           (data, props, listRef, sharedData) => [sharedData.presentHours(data.missed_hours), { align: "right" }],
							expected_missed:        (data, props, listRef, sharedData) => [sharedData.presentHours(data.expected_missed), { align: "right" }],
							invoice_rate_hours:     (data, props, listRef, sharedData) => [sharedData.presentHours(data.invoice_rate_hours), { align: "right" }],
							invoiceable_hours:      (data, props, listRef, sharedData) => [sharedData.presentHours(data.invoiceable_hours), { align: "right" }],
							non_invoiceable_hours:  (data, props, listRef, sharedData) => [sharedData.presentHours(data.non_invoiceable_hours), { align: "right" }],
							done_invoiceable:    	(data, props, listRef, sharedData) => [sharedData.presentPercentage(data.done_invoiceable), { align: "right" }],
							invoiceable_expected:   (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.invoiceable_expected), { align: "right" }],
						},
						filters: [
							/*{name: "customerFilter", dataClass: "customers"},
							{name: "projectFilter", dataClass: "projects"},*/
							{name: "userFilter", dataClass: "users"}
						],
                        switches: [
							{id: 'users_with_zero_hours', name: "Show users with zero hours"},
						],
					},
					overtime_report: {
						name: "overtime",
						sumText: "Total",
						table_width: "2200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"date",
							"users",
							"company",  
							"customers",
							"enterprise_groups",
							"projects",
							"work_types",
							"hour_task",
							"expected_hours",
							"starttime",
							"endtime",
							"overtime_hours",
							"hours_multiplied",
							"overtime_used",
							"timespan_changes",
							"cumulative_overtime",
							"approved",
							"project_types",
							"branch_of_business",
							"edit_date",
						],
						presentation: {
							date:               	(data, props, listRef, sharedData) => [sharedData.presentDate(data.date), { align: "right" }],
							users:               	(data, props, listRef, sharedData) => data.users,
							company:               	(data, props, listRef, sharedData) => data.company,
							customers:				(data, props, listRef, {translate}) => data.isBalanceCorrectionRow ? translate(data.balanceCorrectionText) : data.customers,
							// customers:              (data, props, listRef, sharedData) => data.customers,
							enterprise_groups:      (data, props, listRef, sharedData) => data.enterprise_groups,
							projects:               (data, props, listRef, sharedData) => data.projects,
							work_types: 	        (data, props, listRef, sharedData) => data.work_types,
							hour_task:     			(data, props, listRef, sharedData) => data.hour_task,
							expected_hours:         (data, props, listRef, sharedData) => [sharedData.presentHours(data.expected_hours), { align: "right" }],
							starttime:              (data, props, listRef, sharedData) => [sharedData.presentTime(data.starttime), { align: "right" }],
							endtime:              	(data, props, listRef, sharedData) => [sharedData.presentTime(data.endtime), { align: "right" }],
							overtime_hours:         (data, props, listRef, sharedData) => [sharedData.presentHours(data.overtime_hours), { align: "right" }],
							hours_multiplied:       (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_multiplied), { align: "right" }],
							approved:     			(data, props, listRef, sharedData) => sharedData.translate(data.approved),
							project_types:          (data, props, listRef, sharedData) => data.project_types,
							branch_of_business:     (data, props, listRef, sharedData) => data.branch_of_business,
							timespan_changes:       (data, props, listRef, sharedData) => [sharedData.presentHours(data.timespan_changes), { align: "right" }],
							edit_date:              (data, props, listRef, sharedData) => [sharedData.presentDate(data.edit_date), { align: "right" }],
							overtime_used:     		(data, props, listRef, sharedData) => [sharedData.presentHours(data.overtime_used), { align: "right" }],
							cumulative_overtime:    (data, props, listRef, sharedData) => data.isPeriodBalanceStartRow || data.isPeriodBalanceEndRow  ? [`${sharedData.translate(data.periodBalanceStartText)}: ${sharedData.presentHours(data.cumulative_overtime)}`, { align: "right" }] : [sharedData.presentHours(data.cumulative_overtime), { align: "right" }],
						},
						filters: [
							/*{name: "customerFilter", dataClass: "customers"},
							{name: "projectFilter", dataClass: "projects"},*/
							{name: "userFilter", dataClass: "users"},
							{name: "approvedFilter", filterConditions: [{type: 'companySettings', name: 'approve_overtime_hours_before_balance', value: '1'}, {type: 'companySettings', name: 'approve_overtime_hours_before_billing', value: '1'}]}
						],				
						switches: [
							{id: 'show_singlecompany_hours', name: "Show employee's company hours only", switchConditions:[{conditionType: 'addon', name: "multicompany_hoursreport", value: 1}]},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
						],						
						tooltipTexts: [
							{column: 'timespan_changes', text: "Overtime hours edited manually during chosen timeperiod"},
							{column: 'overtime_used', text: "Hours tracked to use overtime hours"},						
						]
					},
					hours_by_jobtype: {
						name: "hours_by_jobtype",
						table_width: "2200px", 
						useVirtualized: true,
						useScrollPaging: false,
						//pageLimit: 500,
						//scrollTriggerLimit: 400,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"customers",
							"work_types",
							"enterprise_groups",
							"customership_group",
							"hours",  
							"own_cost",
							"selling_price",
						],
						presentation: {
							company:               	(data, props, listRef, sharedData) => data.company,
							customers:              (data, props, listRef, sharedData) => data.customers,
							work_types:             (data, props, listRef, sharedData) => data.work_types,
							hours:         			(data, props, listRef, sharedData) => [sharedData.presentHours(data.hours), { align: "right" }],
							own_cost:         		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.own_cost), { align: "right" }],
							selling_price:         	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.selling_price), { align: "right" }],
							
						},
						filters: [
							{name: "groupByFilter"},
							{name: "userFilter", dataClass: "users"},
						],
						defaultFilterValues: [
							{name: "groupByFilter", type: "filterValues", value: 'customers'},
						],
						groupByOptions: [
							{id: 'work_types', label: 'Jobtype'},
							{id: 'customers', label: 'Account'},
							{id: 'enterprise_groups', label: 'Enterprise Groups'},
							{id: 'customership_group', label: 'Account group'},
						],
						getColumnSaveKey: (filterValues) => [filterValues.groupByFilter],
					},
					hours_by_task_type: {
						name: "hours_by_task_type",
						table_width: "2200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						// multiCompanyQueries: true,
						column_order: [
							"users",
							"customers",
							"projects",  
							"work_types",
							"task_types",
							"task_description",
							"hours",
							"own_cost",
							"selling_price",
						],
						presentation: {
							users:               	(data, props, listRef, sharedData) => data.users,
							customers:              (data, props, listRef, sharedData) => data.customers,
							projects:             	(data, props, listRef, sharedData) => data.projects,
							work_types:             (data, props, listRef, sharedData) => data.work_types,
							task_types:	            (data, props, listRef, sharedData) => data.task_types,
							task_description:       (data, props, listRef, sharedData) => data.task_description,
							hours:         			(data, props, listRef, sharedData) => [sharedData.presentHours(data.hours), { align: "right" }],
							own_cost:         		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.own_cost), { align: "right" }],
							selling_price:         	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.selling_price), { align: "right" }],
						},
						filters: [
							{name: "groupByFilter"},
							{name: "userFilter", dataClass: "employees"},
						],
						defaultFilterValues: [
							{name: "groupByFilter", type: "filterValues", value: 'users'},
						],
						groupByOptions: [
							{id: 'customers', label: 'Account'},
							{id: 'projects', label: 'Project'},
							{id: 'users', label: 'Employee'},
							{id: 'work_types', label: 'Jobtype'},
							{id: 'task_types', label: 'Tasktype'},
						],
						switches: [
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
						],
						getColumnSaveKey: (filterValues) => [filterValues.groupByFilter],
					},
					hours_export: {
						name: "Export hours",
						sumText: "", 
						is_default: "1",
						table_width: "1800px",
						noPagination: true, 
						column_order: []
					},
				},
				productReports: {
					products_by_product: {
						name: "By product",
						sumText: "page totals",
						is_default: "1",
						table_width: "1600px",
						tagtypes: "1, 2",
						column_order: [
							"code",
							"product_name",
							"product_group_name",
							"costest_name",
							"balance",
							"cost_price",
							"selling_price",
							"sold",
							"total_cost_price",
							"total_price",
							"total_margin"
						],
						totalsunit: "symbol",
						filters: [
							{name: "invoicedFilter"}
						],
					},
					products_by_customer: {
						name: "By customer",
						sumText: "page totals",
						table_width: "1600px",
						tagtypes: "1, 2",
						column_order: [
							"customers",
							"projects",
							"code",
							"product_name",
							"product_group_name",
							"costest_name",
							"balance",
							"cost_price",
							"selling_price",
							"sold",
							"total_cost_price",
							"total_price",
							"total_margin"
						],
						totalsunit: "symbol",
						filters: [
							{name: "invoicedFilter"}
						],
					},
					cpq_by_cpq: {
						name: "CPQ",
						table_width: "1600px",
						sumText: "page totals",
						tagtypes: "1, 2",
						column_order: [
							"cpq_name",
							"cpq_description",
							"costest_name",
							"cost_price",
							"selling_price",
							"sold",
							"total_cost_price",
							"total_price",
							"total_margin"
						],
						totalsunit: "symbol",
						filters: [
							{name: "invoicedFilter"}
						],
					},
					cpq_by_customer: {
						name: "CPQ by customer",
						table_width: "1600px",
						sumText: "page totals",
						tagtypes: "1, 2",
						column_order: [
							"customers",
							"projects",						
							"cpq_name",
							"cpq_description",
							"costest_name",
							"cost_price",
							"selling_price",
							"sold",
							"total_cost_price",
							"total_price",
							"total_margin"
						],
						totalsunit: "symbol",
						filters: [
							{name: "invoicedFilter"}
						],
					}
				},
				invoicing: {
					invoicing_vs_subcontracting: {
						name: "Costs period",
						sumText: "Hours total", 
						is_default: "1",
						table_width: "2200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						rowClassNames: {parent: 'subheader-row', child: ''},
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"customers",
							"enterprise_groups",
							"units_name",
							"units_type",							
							"projects",
							"branch_of_business",
							"vatid",
							"hours_billable",
							"hours_billable_price",
							"hours_billed",
							"hours_billed_list_price",
							"hours_sum",
							"sum_pete_not_billed",
							"sum_pete_billed",
							"sum_re_not_billed",
							"sum_re",
							"billed_sum"
						],
						presentation: {
							company:               	 (data, props, listRef, sharedData) => data.company,
							customers:               (data, props, listRef, sharedData) => data.customers,
							units_name:              (data, props, listRef, sharedData) => data.units_name,
							units_type:              (data, props, listRef, sharedData) => data.units_type,
							projects:                (data, props, listRef, sharedData) => data.projects,
							branch_of_business:      (data, props, listRef, sharedData) => data.branch_of_business,
							vatid:                   (data, props, listRef, sharedData) => data.vatid,
                            hours_billable:          (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_billable), { align: "right" }],
                            hours_billable_price:    (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.hours_billable_price), { align: "right" }],
                            hours_billed:            (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_billed), { align: "right" }],
                            hours_billed_list_price: (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.hours_billed_list_price), { align: "right" }],
                            hours_sum:               (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.hours_sum), { align: "right" }],
                            sum_pete_not_billed:     (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.sum_pete_not_billed), { align: "right" }],
                            sum_pete_billed:         (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.sum_pete_billed), { align: "right" }],
                            sum_re_not_billed:       (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.sum_re_not_billed), { align: "right" }],
                            sum_re:                  (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.sum_re), { align: "right" }],
                            billed_sum:              (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_sum), { align: "right" }],
						},
						switches: [
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},
							{id: 'hide_projects', name: "Hide projects"},
							{id: 'show_subunits', name: "Show with subunits", switchConditions:[{conditionType: 'switch', name: "hide_projects", value: true}]},
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true, switchConditions:[{conditionType: 'switch', name: "hide_projects", value: false}]},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						]
					},
					invoicing_vs_subcontracting_bills: {
						name: "Invoicing period",
						sumText: "Hours total", 
						table_width: "2200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						rowClassNames: {parent: 'subheader-row', child: ''},
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"customers",
							"enterprise_groups",
							"units_name",
							"units_type",							
							"projects",
							"vatid",
							"branch_of_business",
							"hours_billable",
							"hours_billable_price",
							"hours_billed",
							"hours_billed_list_price",
							"hours_sum",
							"sum_pete_not_billed",
							"sum_pete_billed",
							"sum_re_not_billed",
							"sum_re",
							"billed_sum"
						],
						presentation: {
							company:               	 (data, props, listRef, sharedData) => data.company,
							customers:               (data, props, listRef, sharedData) => data.customers,
							units_name:              (data, props, listRef, sharedData) => data.units_name,
							units_type:              (data, props, listRef, sharedData) => data.units_type,							
							projects:                (data, props, listRef, sharedData) => data.projects,
							vatid:                   (data, props, listRef, sharedData) => data.vatid,
							branch_of_business:      (data, props, listRef, sharedData) => data.branch_of_business,
                            hours_billable:          (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_billable), { align: "right" }],
                            hours_billable_price:    (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.hours_billable_price), { align: "right" }],
                            hours_billed:            (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_billed), { align: "right" }],
                            hours_billed_list_price: (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.hours_billed_list_price), { align: "right" }],
                            hours_sum:               (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.hours_sum), { align: "right" }],
                            sum_pete_not_billed:     (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.sum_pete_not_billed), { align: "right" }],
                            sum_pete_billed:         (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.sum_pete_billed), { align: "right" }],
                            sum_re_not_billed:       (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.sum_re_not_billed), { align: "right" }],
                            sum_re:                  (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.sum_re), { align: "right" }],
                            billed_sum:              (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_sum), { align: "right" }],
						},
						switches: [
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},
							{id: 'hide_projects', name: "Hide projects"},
							{id: 'show_subunits', name: "Show with subunits", switchConditions:[{conditionType: 'switch', name: "hide_projects", value: true}]},
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true, switchConditions:[{conditionType: 'switch', name: "hide_projects", value: false}]},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						]
					},
					billing_rate_by_customers: {
						name: "Invoice degree per customer",
						sumText: "Hours total", 
						table_width: "2600px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						rowClassNames: {parent: 'subheader-row', child: ''},
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"customers",
							"enterprise_groups",
							"units_name",
							"units_type",							
							"projects",
							"branch_of_business",
							"customership_group",
							"hours_done",
							"own_cost",
							"hours_not_billable",
							"hours_billable",
							"billable_percent",
							"price",
							"hours_open",
							"open_percent",
							"open_own_cost",
							"open_cost",
							"hours_billed",
							"billed_percent",
							"billed_own_cost",
							"billed_list_price",
							"billed_own_cost_sum",
						],
						presentation: {
							company:             (data, props, listRef, sharedData) => data.company,
							customers:           (data, props, listRef, sharedData) => data.customers,
							units_name:          (data, props, listRef, sharedData) => data.units_name,
							units_type:          (data, props, listRef, sharedData) => data.units_type,								
							projects:            (data, props, listRef, sharedData) => data.projects,
							branch_of_business:  (data, props, listRef, sharedData) => data.branch_of_business,
							customership_group:  (data, props, listRef, sharedData) => data.customership_group,
                            hours_done:          (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_done), { align: "right" }],
                            own_cost:            (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.own_cost), { align: "right" }],
                            hours_not_billable:  (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_not_billable), { align: "right" }],
                            hours_billable:      (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_billable), { align: "right" }],
                            billable_percent:    (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.billable_percent), { align: "right" }],
                            price:               (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.price), { align: "right" }],
                            hours_open:          (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_open), { align: "right" }],
                            open_percent:        (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.open_percent), { align: "right" }],
                            open_own_cost:       (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.open_own_cost), { align: "right" }],
                            open_cost:           (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.open_cost), { align: "right" }],
                            hours_billed:        (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_billed), { align: "right" }],
                            billed_percent:      (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.billed_percent), { align: "right" }],
                            billed_own_cost:     (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_own_cost), { align: "right" }],
                            billed_list_price:   (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_list_price), { align: "right" }],
                            billed_own_cost_sum: (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_own_cost_sum), { align: "right" }],
						},
						switches: [
							{id: 'hide_projects', name: "Hide projects"},
							{id: 'show_grouped_by_company', name: "Show by company", indent: 20, switchConditions:[{conditionType: 'state', name: "multiCompany", value: true}, {conditionType: 'switch', name: "hide_projects", value: true}]},
							{
								id: 'show_grouped_by_main_customer', 
								name: "Show by main customer",
								indent: 20, 
								switchConditions:[{conditionType: 'switch', name: "show_subunits", value: false}, {conditionType: 'switch', name: "hide_projects", value: true}],
								filterConditions: {
									dataClass: 'customers',
									filter: 'customerFilter',
									operator: (o) => o.parent_id < 1,
									alertText: "Your previous search included subsidiaries and/or sub-units that cannot be shown in this view. They were automatically disabled. You can now continue with a new search."
							}},
							{id: 'show_subunits', name: "Show with subunits", switchConditions:[{conditionType: 'switch', name: "show_grouped_by_main_customer", value: false}, {conditionType: 'switch', name: "hide_projects", value: true}]},						
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true, switchConditions:[{conditionType: 'switch', name: "hide_projects", value: false}]},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						]
					},
					billing_rate_by_users: {
						name: "Invoice degree per user", 
						table_width: "3000px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						column_order: [
							"company",
							"name",
							// "administrative_group",
							"hours_total",
							"hours_done",
							"own_cost",
							"hours_not_billable",
							"hours_billable",
							"billable_percent",
							"price",
							"hours_open",
							"open_percent",
							"open_own_cost",
							"open_cost",
							"hours_billed",
							"billed_percent",
							"billed_own_cost",
							"billed_list_price",
							"billed_own_cost_sum",
						],
						presentation: {
							company:             (data, props, listRef, sharedData) => data.company,
							name:                (data, props, listRef, sharedData) => data.name,
							// administrative_group:(data, props, listRef, sharedData) => data.administrative_group,
							hours_total:         (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_total), { align: "right" }],
                            hours_done:          (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_done), { align: "right" }],
                            own_cost:            (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.own_cost), { align: "right" }],
                            hours_not_billable:  (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_not_billable), { align: "right" }],
                            hours_billable:      (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_billable), { align: "right" }],
                            billable_percent:    (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.billable_percent), { align: "right" }],
                            price:               (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.price), { align: "right" }],
                            hours_open:          (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_open), { align: "right" }],
                            open_percent:        (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.open_percent), { align: "right" }],
                            open_own_cost:       (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.open_own_cost), { align: "right" }],
                            open_cost:           (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.open_cost), { align: "right" }],
                            hours_billed:        (data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_billed), { align: "right" }],
                            billed_percent:      (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.billed_percent), { align: "right" }],
                            billed_own_cost:     (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_own_cost), { align: "right" }],
                            billed_list_price:   (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_list_price), { align: "right" }],
                            billed_own_cost_sum: (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_own_cost_sum), { align: "right" }],
						},
						filters: [
							{name: "userFilter", dataClass: "users"}
						],						
						switches: [
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
						]
					},
					invoices_summary: {
						name: "Invoices summary",
						sumText: "page hours total", 
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"bill_id",
							"creationdate",
							"customers",
							"enterprise_groups",
							"projects",
							"branch_of_business",
							"customerreference",
							"reporting_group",
							"customer_references",
							"period",
							"billed_own_cost",
							"billed_subcontracting",
							"net_total",
							"vat",
							"vat_total",
							"gross_total",
							"paid_total",
							"paid_date",
							"billing_zone"
						],
						presentation: {
                            totalTitle:        (data, props, listRef, sharedData) => data.totalTitle,
                            totalValue:        (data, props, listRef, sharedData) => data.totalValue,
							bill_id:           (data, props, listRef, sharedData) => data.bill_id,
							creationdate:      (data, props, listRef, sharedData) => sharedData.presentDate(data.creationdate),
							company:     	   (data, props, listRef, sharedData) => data.company,
							customer:          (data, props, listRef, sharedData) => data.customer,
							enterprise_groups: (data, props, listRef, sharedData) => data.enterprise_groups,
							projects:          (data, props, listRef, sharedData) => data.projects,
							branch_of_business:(data, props, listRef, sharedData) => data.branch_of_business,
							customerreference: (data, props, listRef, sharedData) => data.customerreference,
							reporting_group:   (data, props, listRef, sharedData) => data.reporting_group,
							customer_references:   (data, props, listRef, sharedData) => data.customer_references,
							period:            (data, props, listRef, sharedData) => data.period,
                            billed_own_cost:       (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_own_cost), { align: "right" }],
                            billed_subcontracting: (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_subcontracting), { align: "right" }],
                            net_total:             (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.net_total), { align: "right" }],
                            vat:                   (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.vat), { align: "right" }],
                            vat_total:             (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.vat_total), { align: "right" }],
                            gross_total:           (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.gross_total), { align: "right" }],
                            paid_total:            (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.paid_total), { align: "right" }],
                            paid_date:      	   (data, props, listRef, sharedData) => sharedData.presentDate(data.paid_date),
							billing_zone:          (data, props, listRef, sharedData) => data.billing_zone
						},
						filters: [
							{name: "nameTypeFilter"}
						],						
						switches: [
							{id: 'show_projects', name: "Show by projects"},
							{id: 'show_creditnotes', name: "Show credit notes"},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						]
					},
					invoices_summary_with_rows: {
						name: "Invoices summary with rows",
						sumText: "page hours total", 
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						addon_dependancies: ['show_invoice_summary_rows_report'],
						column_order: [
							"company",
							"bill_id",
							"creationdate",
							"customers",
							"enterprise_groups",
							"projects",
							"branch_of_business",
							"customerreference",
							"reporting_group",
							"customer_references",
							"row_name",							
							"product_name",
							"quantity",
							"unit_price",
							"vat",
							"vat_total",
							"net_total",
							"gross_total",
							"billing_zone"
						],
						presentation: {
							company:           (data, props, listRef, sharedData) => data.company,
							bill_id:           (data, props, listRef, sharedData) => data.bill_id,
							creationdate:      (data, props, listRef, sharedData) => sharedData.presentDate(data.creationdate),							
							customer:          (data, props, listRef, sharedData) => data.customer,
							enterprise_groups: (data, props, listRef, sharedData) => data.enterprise_groups,
							projects:          (data, props, listRef, sharedData) => data.projects,
							branch_of_business:(data, props, listRef, sharedData) => data.branch_of_business,
							customerreference: (data, props, listRef, sharedData) => data.customerreference,
							reporting_group:   (data, props, listRef, sharedData) => data.reporting_group,
							customer_references:   (data, props, listRef, sharedData) => data.customer_references,
							row_name:            (data, props, listRef, sharedData) => data.row_name,
							product_name:            (data, props, listRef, sharedData) => data.product_name,
							quantity:            (data, props, listRef, sharedData) => [sharedData.presentQuantity(data.quantity), { align: "right" }],
                            unit_price:       (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.unit_price), { align: "right" }],
                            vat:                   (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.vat), { align: "right" }],
                            vat_total:             (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.vat_total), { align: "right" }],
                            net_total:             (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.net_total), { align: "right" }],
                            gross_total:           (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.gross_total), { align: "right" }],
							billing_zone:          (data, props, listRef, sharedData) => data.billing_zone
						},
						filters: [
							{name: "nameTypeFilter"}
						],						
						switches: [
							{id: 'show_creditnotes', name: "Show credit notes"},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						]
					},					
					scheduled_invoicing_by_account: {
						name: "Scheduled invoicing by account",
						sumText: "Total",
						table_width: "2800px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						hideDateRange: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						column_order: [
							"company",
							"customers",
							"enterprise_groups",
							"vatid",
							"units_name",
							"units_type",
							"scheduled_invoicing_sum",
							"time_span"
						],
						presentation: {
							company:               		(data, props, listRef, sharedData) => data.company,
							customers:               	(data, props, listRef, sharedData) => data.customers,
							vatid:      	        	(data, props, listRef, sharedData) => data.vatid,
							units_name:      	       	(data, props, listRef, sharedData) => data.units_name,
							units_type:      	       	(data, props, listRef, sharedData) => sharedData.translate(data.units_type),
							scheduled_invoicing_sum: 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.scheduled_invoicing_sum), { align: "right" }],
                            time_span: 			 	 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
						},
						filters: [
							{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "customerFilter", dataClass: "customers"},
							{name: "funnelFilter", dataClass: "funnels", dataClass: "funnels", value: ['-1']},
							{name: "scheduledTypeFilter"}
						],				
						switches: [
							{id: 'show_grouped_by_company', name: "Show by company", switchConditions:[{conditionType: 'state', name: "multiCompany", value: true}]},
							{
								id: 'show_grouped_by_main_customer', 
								name: "Show by main customer", 
								switchConditions:[{conditionType: 'switch', name: "show_subunits", value: false}],
								filterConditions: {
									dataClass: 'customers',
									filter: 'customerFilter',
									operator: (o) => o.parent_id < 1,
									alertText: "Your previous search included subsidiaries and/or sub-units that cannot be shown in this view. They were automatically disabled. You can now continue with a new search."
							}},
							{id: 'show_subunits', name: "Show with subunits", switchConditions:[{conditionType: 'switch', name: "show_grouped_by_main_customer", value: false}]},
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},
							{id: 'show_invoicedScheduled', name: "Show invoiced scheduled invoicing"},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						]
					},
					scheduled_invoicing_by_project: {
						name: "Scheduled invoicing by project",
						sumText: "Total",
						table_width: "3000px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						hideDateRange: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						column_order: [
							"company",
							"customers",
							"enterprise_groups",
							"vatid",
							"projects",
							"branch_of_business",
							"scheduled_invoicing_sum",
							"time_span"
						],
						presentation: {
							company:               		(data, props, listRef, sharedData) => data.company,
							customers:               	(data, props, listRef, sharedData) => data.customers,
							vatid:      	        	(data, props, listRef, sharedData) => data.vatid,
							projects:               	(data, props, listRef, sharedData) => data.projects,
							branch_of_business:         (data, props, listRef, sharedData) => data.branch_of_business,
							scheduled_invoicing_sum: 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.scheduled_invoicing_sum), { align: "right" }],
                            time_span: 			 	 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
						},
						filters: [
							{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "customerFilter", dataClass: "customers"},
							{name: "projectFilter", dataClass: "projects"},
							{name: "funnelFilter", dataClass: "funnels", dataClass: "funnels", value: ['-1']},
							{name: "scheduledTypeFilter"}
						],				
						switches: [
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
							{id: 'show_invoicedScheduled', name: "Show invoiced scheduled invoicing"},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						]
					},
					monthly_invoicing_by_account: {
						name: "Accounts monthly",
						sumText: "Total",
						table_width: "3400px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						hideDateRange: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						forcedHeaders: [
							{name: 'company', index: 0}
						],
						column_order: [
							"company",
							"customers",
							"enterprise_groups",
							"units_name",
							"units_type",							
							"vatid",
							"billed_sum",
							"time_span"
						],
						presentation: {
							company:               		(data, props, listRef, sharedData) => data.company,
							customers:               	(data, props, listRef, sharedData) => data.customers,
							units_name:      	       	(data, props, listRef, sharedData) => data.units_name,
							units_type:      	       	(data, props, listRef, sharedData) => sharedData.translate(data.units_type),
							vatid:      	        	(data, props, listRef, sharedData) => data.vatid,
							billed_sum: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_sum), { align: "right" }],
                            time_span: 			 	 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
						},
						filters: [
							{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "customerFilter", dataClass: "customers"},
						],				
						switches: [
							{id: 'show_grouped_by_company', name: "Show by company", switchConditions:[{conditionType: 'state', name: "multiCompany", value: true}]},
							{
								id: 'show_grouped_by_main_customer', 
								name: "Show by main customer", 
								switchConditions:[{conditionType: 'switch', name: "show_subunits", value: false}],
								filterConditions: {
									dataClass: 'customers',
									filter: 'customerFilter',
									operator: (o) => o.parent_id < 1,
									alertText: "Your previous search included subsidiaries and/or sub-units that cannot be shown in this view. They were automatically disabled. You can now continue with a new search."
							}},
							{id: 'show_subunits', name: "Show with subunits", switchConditions:[{conditionType: 'switch', name: "show_grouped_by_main_customer", value: false}]},
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						]
					},
					monthly_invoicing_by_project: {
						name: "Projects monthly",
						sumText: "Total",
						table_width: "3400px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						rowClassNames: {parent: 'subheader-row', child: ''},
						noPagination: true,
						multiCompanyQueries: true,
						hideDateRange: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						column_order: [
							"company",
							"customers",
							"enterprise_groups",
							"vatid",
							"projects",
							"branch_of_business",
							"project_types",
							"billed_sum",
							"time_span"
						],
						presentation: {
							company:               		(data, props, listRef, sharedData) => data.company,
							customers:               	(data, props, listRef, sharedData) => data.customers,
							vatid:      	        	(data, props, listRef, sharedData) => data.vatid,
							projects:               	(data, props, listRef, sharedData) => data.projects,
							branch_of_business:         (data, props, listRef, sharedData) => data.branch_of_business,
							project_types:              (data, props, listRef, sharedData) => data.project_types,
							billed_sum: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_sum), { align: "right" }],
                            time_span: 			 	 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
						},
						filters: [
							{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "customerFilter", dataClass: "customers"},
							{name: "projectFilter", dataClass: "projects"},
						],				
						switches: [
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
							{id: 'show_account_subtotal', name: "Show account subtotal"},
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						]
					},										
				},
				billingReports: {
					bills_overview: {
						name: "Bills overview",
						sumText: "Grand total",
						is_default: "1",
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"customers",
							"customership_group",
							"project_managers",
							"projects",
							"billed_sum",
							"bills_targeted",
							"quote_selling_price",
							"quote_actual_margin",
							"own_work_margin"						
						],
						presentation: {
							company:               		(data, props, listRef, sharedData) => data.company,
							customers:              	(data, props, listRef, sharedData) => data.customers,
							customership_group:         (data, props, listRef, sharedData) => data.customership_group,
							project_managers:           (data, props, listRef, sharedData) => data.project_managers,
							projects:               	(data, props, listRef, sharedData) => data.projects,
							billed_sum: 		     	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_sum), { align: "right" }],
                            bills_targeted: 		    (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.bills_targeted), { align: "right" }],
                            quote_selling_price: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.quote_selling_price), { align: "right" }],
                            quote_actual_margin: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.quote_actual_margin), { align: "right" }],
                            own_work_margin: 		    (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.own_work_margin), { align: "right" }],
                            
						},
						filters: [
							{name: "groupByFilter"},
							{name: "customerFilter", dataClass: "customers"},
							{name: "nameTypeFilter", filterConditions: {type: 'filterValues', name: 'groupByFilter', value: 'customers'}},
						],
						groupByOptions: [
							{id: 'customers', label: 'Account'},
							{id: 'customership_group', label: 'Account group'},
							{id: 'project_managers', label: 'Project manager'},
						],						
						defaultFilterValues: [
							{name: "groupByFilter", type: "filterValues", value: 'customers'},
						],
						switches: [
							{id: 'hide_projects', name: "Hide projects"},
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},
							{id: 'show_creditnotes', name: "Show credit notes"},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						],
						tooltipTexts: [
							{column: 'billed_sum', text: "All invoices that are in status sent and onwards"},
							{column: 'bills_targeted', text: "All bills that are targeted to projects and status approved (NB! manually added cost rowsin actual costs are not calculated)"},
							{column: 'quote_selling_price', text: "Costs targeted to project sales quote rows with type \"sub-contractor\""},
							{column: 'quote_actual_margin', text: "Sales quote sub-contractor selling price - Bills targeted to project"},
							{column: 'own_work_margin', text: ""}						
						],
						getColumnSaveKey: (filterValues) => [filterValues.groupByFilter],
					},
					bills_by_category: {
						name: "Bills by category",
						sumText: "Total",
						is_default: "1",
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						dynamicColumnsDefinitions: {branch_of_business: "branch_of_business"},
						dynamicColumnHeaderOperators: [{
							definition: 'branch_of_business',
							filterByDataValues: true,
							reorderToTreeStructure: true,
							operator: (bobs, translations) => {
								const bobsIds = {};
								const bobsWithParents = bobs?.map(b => {
									let cond = true, bobstring = '', id = b.id;

									while(cond) {
										cond = false;
										bobs.forEach((bob) => {
											if(bob.id == id) {
												const bobName = bob.name.replace(/\(([^)]*)\)[^(]*$/g, '');
												if (bobstring == "") 
													bobstring = `${bobName} ${bob.locked > 0 ? `(${translations.locked})` : ''}${bob.deleted > 0 ? ` (${translations.deleted})` : ''}`;
												else 
													bobstring = `${bobName} ${bob.locked > 0 ? `(${translations.locked}) ` : ''}${bob.deleted > 0 ? `(${translations.deleted}) ` : ''}> ${bobstring}`;
												id = bob.parent_id;
												cond = true;
											}
										});
									}
									bobsIds[bobstring] = b.id;									
									return bobstring;
								});
								const processedHeaders = bobsWithParents.sort((a, b) => {
									const aArray = a.split(' > ');
									const bArray = b.split(' > ');
									let compare = false;
								    for (let i = 0; i < aArray.length && (!compare || compare === 0); ++i)
								        compare = a.localeCompare(b[i]);
									return compare || 0;
								});
								const reorderedIds = processedHeaders.map(p => bobsIds[p]);
								return {processedHeaders, reorderedIds};			
							}

						}],
						column_order: [
							"company",
							"customers",
							"branch_of_business",
							"billed_sum",
							"bills_targeted",
							"own_work_margin"						
						],
						presentation: {
							company:               		(data, props, listRef, sharedData) => data.company,
							customers:               	(data, props, listRef, sharedData) => data.customers,
							branch_of_business:         (data, props, listRef, sharedData) => data.branch_of_business,
							billed_sum: 		     	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_sum), { align: "right" }],
                            bills_targeted: 		    (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.bills_targeted), { align: "right" }],
                            own_work_margin: 		    (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.own_work_margin), { align: "right" }],
                            
						},
						filters: [
							{name: "customerFilter", dataClass: "customers"},
							{name: "nameTypeFilter", filterConditions: {type: 'filterValues', name: 'groupByFilter', value: 'customers'}},
						],
						switches: [
							{id: 'show_creditinvoices', name: "Show credit invoices"},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},						
						],
						tooltipTexts: [
							{column: 'billed_sum', text: "All invoices that are in status sent and onwards"},
							{column: 'bills_targeted', text: "All bills that are targeted to projects and status approved"},
							{column: 'own_work_margin', text: "own work margin text"}						
						]						
					},
					bills_by_supplier: {
						name: "Bills by supplier",
						sumText: "Grand total",
						is_default: "1",
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"supplier",
							"bill_nr",
							"customers",
							"projects",
							"bills_not_targeted",
							"bills_targeted",
							"total_sum"						
						],
						presentation: {
							company:               		(data, props, listRef, sharedData) => data.company,
							supplier:               	(data, props, listRef, sharedData) => data.supplier,
							bill_nr:               		(data, props, listRef, sharedData) => data.bill_nr,
							customers:               	(data, props, listRef, sharedData) => data.customers,
							projects:         			(data, props, listRef, sharedData) => data.projects,
							bills_not_targeted: 		(data, props, listRef, sharedData) => [(data._type == 'base' ? '' : sharedData.presentCurrency(data.bills_not_targeted)), { align: "right" }],
                            bills_targeted: 		    (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.bills_targeted), { align: "right" }],
                            total_sum: 		    		(data, props, listRef, sharedData) => [(data._type == 'base' ? '' : sharedData.presentCurrency(data.total_sum)), { align: "right" }],
						},
						filters: [
						],
						groupByOptions: [
							{id: 'customers', label: 'Account'},
							{id: 'customership_group', label: 'Account group'},
							{id: 'project_managers', label: 'Project manager'},
						],						
						defaultFilterValues: [
							{name: "groupByFilter", type: "filterValues", value: 'customers'},
						],
						switches: [
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},						
						],
						tooltipTexts: [
							{column: 'bills_not_targeted', text: "All bills that are not targeted to projects"},
							{column: 'bills_targeted', text: "All bills that are targeted to projects and status approved"},
							{column: 'total_sum', text: "own work margin text"}						
						],
						getColumnSaveKey: (filterValues) => [filterValues.groupByFilter],
					},										
				},
				recognitionReports: {
					revenue_recognition_by_account: {
						name: "Revenue recognition by account",
						sumText: "Total", 
						is_default: "1",
						table_width: "2600px",
						multiCompanyQueries: true, 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						hideDateRange: false,
                        showFinancialYearPicker: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						column_order: [
							"company",
							"customers",
							"projects",
							"branch_of_business",
                            "quote_sales",
							"billed_sum",
							"bill_ids",
							"bill_creationdates",
							"recognition_sum",
							"time_span",
							"memo",
							"sales_agents",
							"project_managers",
                            "funnels",
                            "funnel_change_date",
                            "nonrecognized_sum",
						],
						presentation: {
							company:          		 (data, props, listRef, sharedData) => data.company,
							customers:               (data, props, listRef, sharedData) => data.customers,
							projects:                (data, props, listRef, sharedData) => data.projects,
							branch_of_business:      (data, props, listRef, sharedData) => data.branch_of_business,
                            billed_sum: 		     (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_sum), { align: "right" }],
                            nonrecognized_sum: 		 (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.nonrecognized_sum), { align: "right" }],
                            bill_ids:          		 (data, props, listRef, sharedData) => data.bill_ids,
                            bill_creationdates: 	 (data, props, listRef, sharedData) => sharedData.presentDates(data.bill_creationdates),	
                            recognition_sum: 		 (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.recognition_sum), { align: "right" }],
                            time_span: 			 	 (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
                            memo:          			 (data, props, listRef, sharedData) => data.memo,
                            sales_agents:          	 (data, props, listRef, sharedData) => data.sales_agents,
                            project_managers:        (data, props, listRef, sharedData) => data.project_managers,
                            quote_sales:            (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.quote_sales), { align: "right" }],
                            funnels:                (data, props, listRef, {translate}) => data.pipelines_id > 0 ? data.pipeline : (data.status == 1 ? translate('Won deal') : (data.status == 5 ? translate('Internal') : data.pipeline)),
                            funnel_change_date:     (data, props, listRef, sharedData) => data.projects_pipelines_date,
                        },
						filters: [
							//{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "customerFilter", dataClass: "customers"},
							{name: "projectFilter", dataClass: "projects"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						switches: [
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
                            {id: 'show_projects_with_recognition', name: "Show projects with recognition"},
                            {id: 'show_projects_without_recognition', name: "Show projects with value but without recognition"},
						],
						defaultFilterValues: [
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],
                        tooltipTexts: [
							{column: 'nonrecognized_sum', text: "Project value - recognized sum"},
                            {column: 'funnel_change_date', text: "Date when changed to current pipeline"},			
						],
					},
					revenue_recognition_by_user: {
						name: "Revenue recognition by user",
						sumText: "Total",
						table_width: "2800px",
						multiCompanyQueries: true, 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						hideDateRange: false,
                        showFinancialYearPicker: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						column_order: [
							"company",
							"users",
							"recognition_sum",
							"time_span",
						],
						presentation: {
							company:          		(data, props, listRef, sharedData) => data.company,
							users:                	(data, props, listRef, sharedData) => data.users,
                            recognition_sum: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.recognition_sum), { align: "right" }],
                            time_span: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
						},
						filters: [
							//{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "userFilter", dataClass: "users"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						defaultFilterValues: [
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],												
					},
					revenue_recognition_by_user_and_project: {
						name: "Revenue recognition by user and project",
						sumText: "Total",
						table_width: "2800px",
						multiCompanyQueries: true, 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						hideDateRange: false,
                        showFinancialYearPicker: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						column_order: [
							"company",
							"users",
							"vatid",
							"customers",
							"projects",
							"branch_of_business",
							"recognition_sum",
							"time_span",
						],
						presentation: {
							company:          		(data, props, listRef, sharedData) => data.company,
							users:                	(data, props, listRef, sharedData) => data.users,
							vatid:          		(data, props, listRef, sharedData) => data.vatid,
							customers:              (data, props, listRef, sharedData) => data.customers,
							projects:              	(data, props, listRef, sharedData) => data.projects,
							branch_of_business:     (data, props, listRef, sharedData) => data.branch_of_business,
                            recognition_sum: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.recognition_sum), { align: "right" }],
                            time_span: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
						},
						filters: [
							//{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "userFilter", dataClass: "users"},
							{name: "customerFilter", dataClass: "customers"},
							{name: "projectFilter", dataClass: "projects"},
							{name: "funnelFilter", dataClass: "funnels"},							
						],
						switches: [
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						],
						defaultFilterValues: [
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],												
					},										
				},
				profitabilityReports: {
					profitability_by_account: {
						name: "Profitability by account",
						sumText: "Total", 
						is_default: "1",
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"customers",
							"units_name",
							"units_type",
							"vatid",							
							"billed_sum",
							"costs_bills",
							"costs_expenses",
							"costs_travel",
							"gross_margin",
							"gross_margin_percentage",
							"gross_margin_per_hour",
							"hours_total",
							"costs_hours",
							"net_profit",
							"net_profit_percentage",
							"net_profit_per_hour",							
						],
						presentation: {
							company:               		(data, props, listRef, sharedData) => data.company,
							customers:              	(data, props, listRef, sharedData) => [data.customers, {cellClassName: 'indent-cell'}],
							units_name:      	       	(data, props, listRef, sharedData) => data.units_name,
							units_type:      	       	(data, props, listRef, sharedData) => sharedData.translate(data.units_type),
							vatid:      	        	(data, props, listRef, sharedData) => data.vatid,							
                            billed_sum: 		    	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_sum), { align: "right" }],
                            costs_bills: 		    	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_bills), { align: "right" }],
                            costs_expenses: 			(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_expenses), { align: "right" }],
                            costs_travel: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_travel), { align: "right" }],
                            gross_margin: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.gross_margin), { align: "right" }],
                            gross_margin_percentage:    (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.gross_margin_percentage), { align: "right" }],
                            gross_margin_per_hour: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.gross_margin_per_hour), { align: "right" }],
                            hours_total:             	(data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_total), { align: "right" }],
                            costs_hours: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_hours), { align: "right" }],
							net_profit: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.net_profit), { align: "right" }],
                            net_profit_percentage:    	(data, props, listRef, sharedData) => [sharedData.presentPercentage(data.net_profit_percentage), { align: "right" }],
                            net_profit_per_hour: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.net_profit_per_hour), { align: "right" }],
						},
						filters: [
							{name: "customerFilter", dataClass: "customers"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						defaultFilterValues: [
							{name: "dateRange", value: "financial_year"},
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],
						switches: [
							{id: 'show_grouped_by_company', name: "Show by company", switchConditions:[{conditionType: 'state', name: "multiCompany", value: true}]},
							{
								id: 'show_grouped_by_main_customer', 
								name: "Show by main customer", 
								switchConditions:[{conditionType: 'switch', name: "show_subunits", value: false}],
								filterConditions: {
									dataClass: 'customers',
									filter: 'customerFilter',
									operator: (o) => o.parent_id < 1,
									alertText: "Your previous search included subsidiaries and/or sub-units that cannot be shown in this view. They were automatically disabled. You can now continue with a new search."
							}},
							{id: 'show_subunits', name: "Show with subunits", switchConditions:[{conditionType: 'switch', name: "show_grouped_by_main_customer", value: false}]},						
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},
							{id: 'show_invoiceable', name: "Show invoiceable projects"},
							{id: 'show_uninvoiceable', name: "Show uninvoiceable projects"},
							{id: 'show_internal', name: "Show internal projects"},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},						
						]
					},
					profitability_by_project: {
						name: "Profitability by project",
						sumText: "Total",
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						rowClassNames: {parent: 'subheader-row', child: ''},
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"customers",
							"vatid",
							"projects",
							"branch_of_business",
							"project_types",							
							"billed_sum",
							"costs_bills",
							"costs_expenses",
							"costs_travel",
							"gross_margin",
							"gross_margin_percentage",
							"gross_margin_per_hour",
							"hours_total",
							"costs_hours",
							"net_profit",
							"net_profit_percentage",
							"net_profit_per_hour",							
						],
						presentation: {
							company:               		(data, props, listRef, sharedData) => data.company,
							customers:              	(data, props, listRef, sharedData) => data.customers,
							vatid:      	        	(data, props, listRef, sharedData) => data.vatid,
							projects:       	       	(data, props, listRef, sharedData) => data.projects,
							branch_of_business:         (data, props, listRef, sharedData) => data.branch_of_business,
							project_types:              (data, props, listRef, sharedData) => data.project_types,
                            billed_sum: 		    	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.billed_sum), { align: "right" }],
                            costs_bills: 		    	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_bills), { align: "right" }],
                            costs_expenses: 			(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_expenses), { align: "right" }],
                            costs_travel: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_travel), { align: "right" }],
                            gross_margin: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.gross_margin), { align: "right" }],
                            gross_margin_percentage:    (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.gross_margin_percentage), { align: "right" }],
                            gross_margin_per_hour: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.gross_margin_per_hour), { align: "right" }],
                            hours_total:             	(data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_total), { align: "right" }],
                            costs_hours: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_hours), { align: "right" }],
							net_profit: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.net_profit), { align: "right" }],
                            net_profit_percentage:    	(data, props, listRef, sharedData) => [sharedData.presentPercentage(data.net_profit_percentage), { align: "right" }],
                            net_profit_per_hour: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.net_profit_per_hour), { align: "right" }],
						},
						filters: [
							{name: "customerFilter", dataClass: "customers"},
							{name: "projectFilter", dataClass: "projects"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						defaultFilterValues: [
							{name: "dateRange", value: "financial_year"},
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],
						switches: [
							{id: 'show_account_subtotal', name: "Show account subtotal"},
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},
							{id: 'show_invoiceable', name: "Show invoiceable projects"},
							{id: 'show_uninvoiceable', name: "Show uninvoiceable projects"},
							{id: 'show_internal', name: "Show internal projects"},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						]
					},
					profitability_on_recognitions_by_account: {
						name: "Revenue recognition profitability by account",
						sumText: "Total",
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"customers",
							"units_name",
							"units_type",
							"vatid",							
							"recognition_sum",
							"costs_bills",
							"costs_expenses",
							"costs_travel",
							"gross_margin",
							"gross_margin_percentage",
							"gross_margin_per_hour",
							"hours_total",
							"costs_hours",
							"net_profit",
							"net_profit_percentage",
							"net_profit_per_hour",							
						],
						presentation: {
							company:               		(data, props, listRef, sharedData) => data.company,
							customers:              	(data, props, listRef, sharedData) => [data.customers, {cellClassName: 'indent-cell'}],
							units_name:      	       	(data, props, listRef, sharedData) => data.units_name,
							units_type:      	       	(data, props, listRef, sharedData) => sharedData.translate(data.units_type),
							vatid:      	        	(data, props, listRef, sharedData) => data.vatid,							
                            recognition_sum: 		    (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.recognition_sum), { align: "right" }],
                            costs_bills: 		    	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_bills), { align: "right" }],
                            costs_expenses: 			(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_expenses), { align: "right" }],
                            costs_travel: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_travel), { align: "right" }],
                            gross_margin: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.gross_margin), { align: "right" }],
                            gross_margin_percentage:    (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.gross_margin_percentage), { align: "right" }],
                            gross_margin_per_hour: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.gross_margin_per_hour), { align: "right" }],
                            hours_total:             	(data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_total), { align: "right" }],
                            costs_hours: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_hours), { align: "right" }],
							net_profit: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.net_profit), { align: "right" }],
                            net_profit_percentage:    	(data, props, listRef, sharedData) => [sharedData.presentPercentage(data.net_profit_percentage), { align: "right" }],
                            net_profit_per_hour: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.net_profit_per_hour), { align: "right" }],
						},
						filters: [
							{name: "customerFilter", dataClass: "customers"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						defaultFilterValues: [
							{name: "dateRange", value: "financial_year"},
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],
						switches: [
							{id: 'show_grouped_by_company', name: "Show by company", switchConditions:[{conditionType: 'state', name: "multiCompany", value: true}]},
							{
								id: 'show_grouped_by_main_customer', 
								name: "Show by main customer", 
								switchConditions:[{conditionType: 'switch', name: "show_subunits", value: false}],
								filterConditions: {
									dataClass: 'customers',
									filter: 'customerFilter',
									operator: (o) => o.parent_id < 1,
									alertText: "Your previous search included subsidiaries and/or sub-units that cannot be shown in this view. They were automatically disabled. You can now continue with a new search."
							}},
							{id: 'show_subunits', name: "Show with subunits", switchConditions:[{conditionType: 'switch', name: "show_grouped_by_main_customer", value: false}]},
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},
							{id: 'show_invoiceable', name: "Show invoiceable projects"},
							{id: 'show_uninvoiceable', name: "Show uninvoiceable projects"},
							{id: 'show_internal', name: "Show internal projects"},						
						]
					},
					profitability_on_recognitions_by_project: {
						name: "Revenue recognition profitability by project",
						sumText: "Total",
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						rowClassNames: {parent: 'subheader-row', child: ''},
						noPagination: true,
						multiCompanyQueries: true,
						column_order: [
							"company",
							"customers",
							"vatid",
							"projects",
							"branch_of_business",
							"project_types",							
							"recognition_sum",
							"costs_bills",
							"costs_expenses",
							"costs_travel",
							"gross_margin",
							"gross_margin_percentage",
							"gross_margin_per_hour",
							"hours_total",
							"costs_hours",
							"net_profit",
							"net_profit_percentage",
							"net_profit_per_hour",							
						],
						presentation: {
							company:               		(data, props, listRef, sharedData) => data.company,
							customers:              	(data, props, listRef, sharedData) => data.customers,
							vatid:      	        	(data, props, listRef, sharedData) => data.vatid,
							projects:       	       	(data, props, listRef, sharedData) => data.projects,
							branch_of_business:         (data, props, listRef, sharedData) => data.branch_of_business,
							project_types:              (data, props, listRef, sharedData) => data.project_types,
                            recognition_sum: 		    (data, props, listRef, sharedData) => [sharedData.presentCurrency(data.recognition_sum), { align: "right" }],
                            costs_bills: 		    	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_bills), { align: "right" }],
                            costs_expenses: 			(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_expenses), { align: "right" }],
                            costs_travel: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_travel), { align: "right" }],
                            gross_margin: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.gross_margin), { align: "right" }],
                            gross_margin_percentage:    (data, props, listRef, sharedData) => [sharedData.presentPercentage(data.gross_margin_percentage), { align: "right" }],
                            gross_margin_per_hour: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.gross_margin_per_hour), { align: "right" }],
                            hours_total:             	(data, props, listRef, sharedData) => [sharedData.presentHours(data.hours_total), { align: "right" }],
                            costs_hours: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.costs_hours), { align: "right" }],
							net_profit: 				(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.net_profit), { align: "right" }],
                            net_profit_percentage:    	(data, props, listRef, sharedData) => [sharedData.presentPercentage(data.net_profit_percentage), { align: "right" }],
                            net_profit_per_hour: 		(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.net_profit_per_hour), { align: "right" }],
						},
						filters: [
							{name: "customerFilter", dataClass: "customers"},
							{name: "projectFilter", dataClass: "projects"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						defaultFilterValues: [
							{name: "dateRange", value: "financial_year"},
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],
						switches: [
							{id: 'show_account_subtotal', name: "Show account subtotal"},
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},
							{id: 'show_invoiceable', name: "Show invoiceable projects"},
							{id: 'show_uninvoiceable', name: "Show uninvoiceable projects"},
							{id: 'show_internal', name: "Show internal projects"},
						]
					},
				},
				forecastReports: {
					gross_profit_forecast: {
						name: "Gross profit forecast summary",
						sumText: "Total", 
						is_default: "1",
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						rowClassNames: {parent: 'subheader-row', child: ''},
						noPagination: true,
						hideDateRange: false,
                        showFinancialYearPicker: true,
						multiCompanyQueries: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						column_order: [
							"free_summary_text",
							"total_sum",
							"time_span",							
						],
						presentation: {
							free_summary_text:      (data, props, listRef, sharedData) => sharedData.translate(data.free_summary_text),
							total_sum: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.total_sum), { align: "right" }],
							time_span: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
						},
						filters: [
							//{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						defaultFilterValues: [
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],
						switches: [
							{id: 'show_probability', name: "Show values with probability"},
							{id: 'show_scheduled', name: "Show scheduled invoices"},
							{id: 'show_automatic', name: "Show automatic invoices"},
							{id: 'show_purchase_orders', name: "Show purchase orders"},
							{id: 'show_waiting_invoices', name: "Show invoices in waiting status"},
							{id: 'show_draft_invoices', name: "Show draft invoices"},
							{id: 'show_waiting_bills', name: "Show bills in waiting status"},
						]
					},
					gross_profit_forecast_by_account: {
						name: "Gross profit forecast by account",
						sumText: "Total",
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						rowClassNames: {parent: 'subheader-row', child: ''},
						noPagination: true,
						hideDateRange: false,
                        showFinancialYearPicker: true,
						multiCompanyQueries: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						column_order: [
							"company",
							"customers",
							"units_name",
							"units_type",							
							"summary_type",
							"total_sum",
							"time_span",							
						],
						presentation: {
							company:      			(data, props, listRef, sharedData) => data.company,
							customers:      		(data, props, listRef, sharedData) => data.customers,
							units_name:      	    (data, props, listRef, sharedData) => data.units_name,
							units_type:      	    (data, props, listRef, sharedData) => sharedData.translate(data.units_type),
							summary_type:      		(data, props, listRef, sharedData) => sharedData.translate(data.summary_type),
							total_sum: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.total_sum), { align: "right" }],
							time_span: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
						},
						filters: [
							//{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						defaultFilterValues: [
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],
						switches: [
							{id: 'show_grouped_by_company', name: "Show by company", switchConditions:[{conditionType: 'state', name: "multiCompany", value: true}]},
							{
								id: 'show_grouped_by_main_customer', 
								name: "Show by main customer", 
								switchConditions:[{conditionType: 'switch', name: "show_subunits", value: false}],
								filterConditions: {
									dataClass: 'customers',
									filter: 'customerFilter',
									operator: (o) => o.parent_id < 1,
									alertText: "Your previous search included subsidiaries and/or sub-units that cannot be shown in this view. They were automatically disabled. You can now continue with a new search."
							}},
							{id: 'show_subunits', name: "Show with subunits", switchConditions:[{conditionType: 'switch', name: "show_grouped_by_main_customer", value: false}]},
							{id: 'show_grouped', name: "Show grouped"},
							{id: 'show_probability', name: "Show values with probability"},
							{id: 'show_scheduled', name: "Show scheduled invoices"},
							{id: 'show_automatic', name: "Show automatic invoices"},
							{id: 'show_purchase_orders', name: "Show purchase orders"},
							{id: 'show_waiting_invoices', name: "Show invoices in waiting status"},
							{id: 'show_draft_invoices', name: "Show draft invoices"},
							{id: 'show_waiting_bills', name: "Show bills in waiting status"},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						]
					},
					gross_profit_forecast_by_project: {
						name: "Gross profit forecast by project",
						sumText: "Total",
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						rowClassNames: {parent: 'subheader-row', child: ''},
						noPagination: true,
						hideDateRange: false,
                        showFinancialYearPicker: true,
						multiCompanyQueries: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						column_order: [
							"company",
							"customers",
							"projects",
							"project_types",
							"branch_of_business",
							"summary_type",
							"total_sum",
							"time_span",							
						],
						presentation: {
							company: 	     		(data, props, listRef, sharedData) => data.company,
							customers:      		(data, props, listRef, sharedData) => data.customers,
							projects:      			(data, props, listRef, sharedData) => data.projects,
							project_types:          (data, props, listRef, sharedData) => data.project_types,
							branch_of_business:     (data, props, listRef, sharedData) => data.branch_of_business,							
							summary_type:      		(data, props, listRef, sharedData) => sharedData.translate(data.summary_type),
							total_sum: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.total_sum), { align: "right" }],
							time_span: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
						},
						filters: [
							//{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						defaultFilterValues: [
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],
						switches: [
							{id: 'show_project_tree', name: "Show project tree", notUsedInExport: true},
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},							
							{id: 'show_grouped', name: "Show grouped"},
							{id: 'show_probability', name: "Show values with probability"},
							{id: 'show_scheduled', name: "Show scheduled invoices"},
							{id: 'show_automatic', name: "Show automatic invoices"},
							{id: 'show_purchase_orders', name: "Show purchase orders"},
							{id: 'show_waiting_invoices', name: "Show invoices in waiting status"},
							{id: 'show_draft_invoices', name: "Show draft invoices"},
							{id: 'show_waiting_bills', name: "Show bills in waiting status"},
							{id: 'show_export_hierarhia', name: "Show export units hierarhia"},
						]
					},
					gross_profit_forecast_on_recognitions: {
						name: "Revenue recognition gross profit summary",
						sumText: "Total", 
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						rowClassNames: {parent: 'subheader-row', child: ''},
						noPagination: true,
						hideDateRange: false,
                        showFinancialYearPicker: true,
						multiCompanyQueries: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						column_order: [
							"free_summary_text",
							"total_sum",
							"time_span",							
						],
						presentation: {
							free_summary_text:      (data, props, listRef, sharedData) => sharedData.translate(data.free_summary_text),
							total_sum: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.total_sum), { align: "right" }],
							time_span: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
						},
						filters: [
							//{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						defaultFilterValues: [
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],
						switches: [
							{id: 'show_probability', name: "Show values with probability"},
							{id: 'show_purchase_orders', name: "Show purchase orders"},
							{id: 'show_waiting_bills', name: "Show bills in waiting status"},
						]
					},
					gross_profit_forecast_on_recognitions_by_account: {
						name: "Revenue recognition gross profit by account",
						sumText: "Total",
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						rowClassNames: {parent: 'subheader-row', child: ''},
						noPagination: true,
						hideDateRange: false,
                        showFinancialYearPicker: true,
						multiCompanyQueries: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						column_order: [
							"company",
							"customers",
							"units_name",
							"units_type",							
							"summary_type",
							"total_sum",
							"time_span",							
						],
						presentation: {
							company:      			(data, props, listRef, sharedData) => data.company,
							customers:      		(data, props, listRef, sharedData) => data.customers,
							units_name:      	    (data, props, listRef, sharedData) => data.units_name,
							units_type:      	    (data, props, listRef, sharedData) => sharedData.translate(data.units_type),
							summary_type:      		(data, props, listRef, sharedData) => sharedData.translate(data.summary_type),
							total_sum: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.total_sum), { align: "right" }],
							time_span: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
						},
						filters: [
							//{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						defaultFilterValues: [
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],
						switches: [
							{id: 'show_grouped_by_company', name: "Show by company", switchConditions:[{conditionType: 'state', name: "multiCompany", value: true}]},
							{
								id: 'show_grouped_by_main_customer', 
								name: "Show by main customer", 
								switchConditions:[{conditionType: 'switch', name: "show_subunits", value: false}],
								filterConditions: {
									dataClass: 'customers',
									filter: 'customerFilter',
									operator: (o) => o.parent_id < 1,
									alertText: "Your previous search included subsidiaries and/or sub-units that cannot be shown in this view. They were automatically disabled. You can now continue with a new search."
							}},
							{id: 'show_subunits', name: "Show with subunits", switchConditions:[{conditionType: 'switch', name: "show_grouped_by_main_customer", value: false}]},
							{id: 'show_grouped', name: "Show grouped"},
							{id: 'show_subunits', name: "Show subunits"},
							{id: 'show_probability', name: "Show values with probability"},
							{id: 'show_purchase_orders', name: "Show purchase orders"},
							{id: 'show_waiting_bills', name: "Show bills in waiting status"},
						]
					},
					gross_profit_forecast_on_recognitions_by_project: {
						name: "Revenue recognition gross profit by project",
						sumText: "Total",
						table_width: "3200px", 
						useVirtualized: true,
						headerDefiningRowType: 'base',
						rowClassNames: {parent: 'subheader-row', child: ''},
						noPagination: true,
						hideDateRange: false,
                        showFinancialYearPicker: true,
						multiCompanyQueries: true,
						dynamicColumnsDefinitions: {time_span: "financial_year"},
						column_order: [
							"company",
							"customers",
							"projects",
							"project_types",
							"branch_of_business",
							"summary_type",
							"total_sum",
							"time_span",							
						],
						presentation: {
							company:      			(data, props, listRef, sharedData) => data.company,
							customers:      		(data, props, listRef, sharedData) => data.customers,
							projects:      			(data, props, listRef, sharedData) => data.projects,
							project_types:          (data, props, listRef, sharedData) => data.project_types,
							branch_of_business:     (data, props, listRef, sharedData) => data.branch_of_business,							
							summary_type:      		(data, props, listRef, sharedData) => sharedData.translate(data.summary_type),
							total_sum: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.total_sum), { align: "right" }],
							time_span: 			 	(data, props, listRef, sharedData) => [sharedData.presentCurrency(data.time_span), { align: "right" }],
						},
						filters: [
							//{name: "financialYearFilter", dataClass: "financial_years"},
							{name: "funnelFilter", dataClass: "funnels"},
						],
						defaultFilterValues: [
							{name: "funnelFilter", type: "filterValues", dataClass: "funnels", value: ['-1']},
						],
						switches: [
							{id: 'show_project_tree', name: "Show project tree"},
							{id: 'show_unlocked', name: "Show open projects"},
							{id: 'show_locked', name: "Show closed projects"},
							{id: 'show_hold', name: "Show projects on hold"},							
							{id: 'show_grouped', name: "Show grouped"},
							{id: 'show_probability', name: "Show values with probability"},
							{id: 'show_purchase_orders', name: "Show purchase orders"},
							{id: 'show_waiting_bills', name: "Show bills in waiting status"},
						]
					},
				},
				chiefReports: {
					chief_reports: {
						name: "Hours Users Excel",
						is_default: "1",
						sumText: "",
						table_width: "1800px",
						noPagination: true, 
						column_order: []
					}
				}								
			},


			fields : {
				"bill_id": {
					"name": "bill_id", 
					"header": "Invoice number", 
					"width": 100,
					"fieldType": "text",
					"entityMode": true, 
					"searchFieldType": "text", 
					"search": [
						"invoices_summary",
						"invoices_summary_with_rows"
					]
				},
				"bill_nr": {
					"name": "bill_nr", 
					"header": "Bill number", 
					"width": 60,
					"fieldType": "text",
					"entityMode": true, 
					"searchFieldType": "text", 
					"search": [
						"bills_by_supplier",
					]
				},								
				"move":{
					"name": "buffer", 
					"header": "", 
					"width": 1, 
					"fieldType": "buffer", 
					"search": []
				},
				"name":{
					"name": "name", 
					"header": "Name", 
					"width": 180, 
					"fieldType": "text", 
					"search": []
				},
				"supplier":{
					"name": "supplier", 
					"header": "Supplier", 
					"width": 180, 
					"fieldType": "text", 
					"search": [
						"bills_by_supplier",
					]
				},
				"company":{
					"name": "company", 
					"header": "Company", 
					"width": 240, 
					"fieldType": "text", 
					"search": [],
					"filterConditions": [
						{type: "company_report", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "customers_daily", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "employees_report", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "employees_daily", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "hour_balance", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "invoicing_vs_subcontracting", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "invoicing_vs_subcontracting_bills", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "billing_rate_by_customers", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "billing_rate_by_users", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "invoices_summary", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "invoices_summary_with_rows", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "profitability_by_account", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "profitability_by_project", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "profitability_on_recognitions_by_account", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "profitability_on_recognitions_by_project", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "scheduled_invoicing_by_account", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "scheduled_invoicing_by_project", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "monthly_invoicing_by_account", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "monthly_invoicing_by_project", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "revenue_recognition_by_account", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "revenue_recognition_by_user", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "revenue_recognition_by_user_and_project", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "gross_profit_forecast_by_account", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "gross_profit_forecast_by_project", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "gross_profit_forecast_on_recognitions_by_account", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "gross_profit_forecast_on_recognitions_by_project", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "bills_overview", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "bills_by_category", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "bills_by_supplier", filters: [{name: "multiCompany", value: 'true'}]},
						{type: "sales_overview", filters: [{name: "multiCompany", value: 'true'}]},
					]
				},
				"customers_nr": {
					"name": "customers_nr", 
					"header": "Customer Number",
					"width": 180, 
					"fieldType": "text",
					"search": [],
				},		
				"customers": {
					"name": "customers", 
					"header": "Customer", 
					"width": 180, 
					"fieldType": "text", 
					"entityMode": true,
					"search": [
						"company_report",
						"customers_daily",
						"employees_report",
						"employees_daily",
						"invoice_rate",
						"overtime_report", 
						"products_by_product", 
						"products_by_customer",
						"cpq_by_cpq", 
						"cpq_by_customer",
						"invoicing_vs_subcontracting",
						"invoicing_vs_subcontracting_bills",
						"billing_rate_by_customers",
						"billing_rate_by_users",
						"invoices_summary",
						"invoices_summary_with_rows",
						"revenue_recognition_by_user",
						"gross_profit_forecast",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"projects_backlog",
			            "sales_report",
						"hours_by_jobtype",
						"hours_by_task_type",
					],
					"filterConditions": [
						{type: "bills_overview", filters: [{name: "groupByFilter", value: 'customers'}]},
						{type: "hours_by_jobtype", filters: [{name: "groupByFilter", value: 'customers'}]},						
					],
					"orderConditions": [
						{reportType: "hours_by_task_type", orderIndex: 0, filter: {name: "groupByFilter", value: 'customers'}},
					]
				},
				"enterprise_groups": {
					"name": "enterprise_groups", 
					"header": "Enterprise group", 
					"width": 180, 
					"fieldType": "text", 
					"search": [
						"company_report",
						"customers_daily",
						"employees_report",
						"employees_daily",
						"invoice_rate",
						"overtime_report",
						"invoicing_vs_subcontracting",
						"invoicing_vs_subcontracting_bills",
						"billing_rate_by_customers",
						"invoices_summary",
						"invoices_summary_with_rows",
						"scheduled_invoicing_by_account",
						"scheduled_invoicing_by_project",
						"monthly_invoicing_by_account",
						"monthly_invoicing_by_project",
						"projects_backlog",
						"sales_overview",
			            "sales_report",
						"hours_by_jobtype",
						"hours_by_task_type",
					],
					"filterConditions": [
						{type: "hours_by_jobtype", filters: [{name: "groupByFilter", value: 'enterprise_groups'}]},
					]
				},
				"projects_nr": {
					"name": "projects_nr", 
					"header": "Projects Number",
					"width": 180, 
					"fieldType": "text",
					"search": [],
				},	
				"projects": {
					"name": "projects", 
					"header": "Project", 
					"width": 250, 
					"fieldType": "text", 
					"entityMode": true,
					"search": [
						"company_report",
						"customers_daily",
						"employees_report",
						"employees_daily",
						"invoice_rate",
						"overtime_report",					
						"products_by_product", 
						"products_by_customer",
						"cpq_by_cpq", 
						"cpq_by_customer",
						"invoicing_vs_subcontracting",
						"invoicing_vs_subcontracting_bills",
						"billing_rate_by_customers",
						"billing_rate_by_users",
						"invoices_summary",
						"invoices_summary_with_rows",
						"scheduled_invoicing_by_account",
						"revenue_recognition_by_user",
						"monthly_invoicing_by_account",
						"gross_profit_forecast",
						"profitability_by_account",
						"profitability_on_recognitions_by_account",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"projects_backlog",
						"bills_overview",
						"bills_by_category",
						"bills_by_supplier",
						"sales_overview",
						"hours_by_jobtype",
						"hours_by_task_type",
                        "sales_report",
					],
					"filterConditions": [
						{type: "invoicing_vs_subcontracting", filters: [{name: "hide_projects", value: false}]},
						{type: "invoicing_vs_subcontracting_bills", filters: [{name: "hide_projects", value: false}]},
						{type: "billing_rate_by_customers", filters: [{name: "hide_projects", value: false}]},
						{type: "bills_overview", filters: [{name: "hide_projects", value: false}]},						
					],
					"orderConditions": [
						{reportType: "hours_by_task_type", orderIndex: 0, filter: {name: "groupByFilter", value: 'projects'}},
					]					
				},
                "subprojects": {
					"name": "subprojects", 
					"header": "Sub-project", 
					"width": 250, 
					"fieldType": "text", 
					"entityMode": true,
					"search": [
						"company_report",
						"customers_daily",
						"employees_report",
						"employees_daily",
						"invoice_rate",
						"overtime_report",					
						"products_by_product", 
						"products_by_customer",
						"cpq_by_cpq", 
						"cpq_by_customer",
						"invoicing_vs_subcontracting",
						"invoicing_vs_subcontracting_bills",
						"billing_rate_by_customers",
						"billing_rate_by_users",
						"invoices_summary",
						"invoices_summary_with_rows",
						"scheduled_invoicing_by_account",
						"revenue_recognition_by_user",
						"monthly_invoicing_by_account",
						"gross_profit_forecast",
						"profitability_by_account",
						"profitability_on_recognitions_by_account",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"projects_backlog",
						"bills_overview",
						"bills_by_category",
						"bills_by_supplier",
						"sales_overview",
                        "sales_report",
						"hours_by_jobtype",
					],
					"filterConditions": [
						{type: "invoicing_vs_subcontracting", filters: [{name: "hide_projects", value: false}]},
						{type: "invoicing_vs_subcontracting_bills", filters: [{name: "hide_projects", value: false}]},
						{type: "billing_rate_by_customers", filters: [{name: "hide_projects", value: false}]},
						{type: "bills_overview", filters: [{name: "hide_projects", value: false}]},						
					]					
				},
				"users": {
					"name": "users", 
					"header": "Employee", 
					"width": 150, 
					"fieldType": "text", 
					"entityMode": true,
					"search": [],
					"orderConditions": [
						{reportType: "hours_by_task_type", orderIndex: 0, filter: {name: "groupByFilter", value: 'users'}},
					]
				},
				"hours": {
					"name": "hours", 
					"header": "Hours", 
					"width": 200, 
					"fieldType": "hours", 
					"search": []
				},
				"hours_multiplied": {
					"name": "hours_multiplied", 
					"header": "Hours multiplied", 
					"width": 150, 
					"fieldType": "hours_b",
					"search": []
				},

				"hours_total": {
					"name": "hours_total", 
					"header": "Hours total", 
					"width": 120, 
					"fieldType": "hours", 
					"search": []
				},
				"overtime_hours": {
					"name": "overtime_hours", 
					"header": "Overtime hours", 
					"width": 200, 
					"fieldType": "hours",
					"search": [], 
					"excluded": []
				},
				"starttime": {
					"name": "starttime", 
					"header": "Start", 
					"width": 80, 
					"fieldType": "time", 
					"search": []
				},
				"endtime": {
					"name": "endtime", 
					"header": "End", 
					"width": 80, 
					"fieldType": "time", 
					"search": []
				},				
				"work_types": {
					"name": "work_types", 
					"header": "Job type", 
					"width" : 180, 
					"fieldType": "text", 
					"entityMode": true,
					"search": [
						"invoice_rate", 
						"employees_daily",
						"overtime_report", 
						"employees_report", 
						"customers_daily", 
						"company_report",
						"hours_by_task_type",
					],
					/*
					"filterConditions": [
						{type: "hours_by_jobtype", filters: [{name: "groupByFilter", value: 'work_types'}]},						
					],
					*/
					"orderConditions": [
						{reportType: "hours_by_task_type", orderIndex: 0, filter: {name: "groupByFilter", value: 'work_types'}},
					]
				},
				"project_types": {
					"name": "project_types", 
					"header": "Project types", 
					"width": 120, 
					"fieldType": "text", 
					"entityMode": true,
					"search": [
						"invoice_rate", 
						"company_report",
						"products_by_product", 
						"products_by_customer",
						"cpq_by_cpq", 
						"cpq_by_customer",
						'billing_rate_by_users',
						"invoices_summary",
						"invoices_summary_with_rows",
						"revenue_recognition_by_account",
						"revenue_recognition_by_user_and_project",
						"profitability_by_account",
						"profitability_by_project",
						"profitability_on_recognitions_by_account",
						"profitability_on_recognitions_by_project",
						"scheduled_invoicing_by_account",
						"scheduled_invoicing_by_project",
						"monthly_invoicing_by_account",
						"monthly_invoicing_by_project",
						"gross_profit_forecast",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"projects_backlog",
			            "sales_report",
						"sales_overview",
						"bills_overview",
						"bills_by_category",
						"bills_by_supplier",
						"hours_by_jobtype",
					]
				},
				"customership_group": {				/*account group*/
					"name": "customership_group", 
					"header": "Account group", 
					"width": 150, 
					"fieldType": "text", 
					"entityMode": true,
					"search": [
						"invoice_rate", 
						"company_report",
						"invoices_summary",
						"invoices_summary_with_rows",
						"invoicing_vs_subcontracting",
						"invoicing_vs_subcontracting_bills",
						"billing_rate_by_customers",
						"revenue_recognition_by_account",
						"revenue_recognition_by_user_and_project",
						"profitability_by_account",
						"profitability_by_project",
						"profitability_on_recognitions_by_account",
						"profitability_on_recognitions_by_project",
						"scheduled_invoicing_by_account",
						"scheduled_invoicing_by_project",
						"monthly_invoicing_by_account",
						"monthly_invoicing_by_project",
						"gross_profit_forecast",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"projects_backlog",
						"bills_overview",
						"bills_by_category",
						"bills_by_supplier",
						"sales_overview",
						"hours_by_jobtype",
						"hours_by_task_type",
			            "sales_report",
					],
					"filterConditions": [
						{type: "bills_overview", filters: [{name: "groupByFilter", value: 'customership_group'}]},
						{type: "hours_by_jobtype", filters: [{name: "groupByFilter", value: 'customership_group'}]},
					]
				},
				"administrative_group": {
					"name": "administrative_group", 
					"header": "Administrative group", 
					"width": 150, 
					"fieldType": "text",
					"entityMode": true,
					"canBeTotal": ['billing_rate_by_users'], 
					"search": [
						"billing_rate_by_users"
					]
				},				
				"reporting_group": {
					"name": "reporting_group", 
					"header": "Reporting group", 
					"width": 150, 
					"fieldType": "text",
					"entityMode": true,
					"search": [
						"company_report",
						"customers_daily",
						"employees_daily",
						"invoice_rate",
						"overtime_report",
						"employees_report",
						"invoicing_vs_subcontracting",
						"invoicing_vs_subcontracting_bills",
						"billing_rate_by_customers",
						"billing_rate_by_users",
						"invoices_summary",
						"invoices_summary_with_rows",
						"revenue_recognition_by_account",
						"revenue_recognition_by_user_and_project",
						"profitability_by_account",
						"profitability_by_project",
						"profitability_on_recognitions_by_account",
						"profitability_on_recognitions_by_project",
						"scheduled_invoicing_by_account",
						"scheduled_invoicing_by_project",
						"monthly_invoicing_by_account",
						"monthly_invoicing_by_project",
						"gross_profit_forecast",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"sales_overview",
						"hours_by_jobtype",
						"hours_by_task_type",
					]
				},		
				"hour_task": {
					"name": "hour_task", 
					"header": "Task", 
					"width": 150, 
					"fieldType": "text", 
					"search": [
					]
				},		
				"branch_of_business": {					/*project category*/
					"name": "branch_of_business", 
					"header": "Project Category", 
					"width": 150, 
					"fieldType": "text", 
					"entityMode": true,
					"search": [
						"invoice_rate", 
						"company_report",
						"products_by_product", 
						"products_by_customer",
						"cpq_by_cpq", 
						"cpq_by_customer",
						"invoicing_vs_subcontracting",
						"invoicing_vs_subcontracting_bills",
						"billing_rate_by_customers",
						"billing_rate_by_users",
						"invoices_summary",
						"invoices_summary_with_rows",
						"revenue_recognition_by_account",
						"revenue_recognition_by_user_and_project",
						"profitability_by_account",
						"profitability_by_project",
						"profitability_on_recognitions_by_account",
						"profitability_on_recognitions_by_project",
						"scheduled_invoicing_by_account",
						"scheduled_invoicing_by_project",
						"monthly_invoicing_by_account",
						"monthly_invoicing_by_project",
						"gross_profit_forecast",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"projects_backlog",
						"bills_overview",
						"bills_by_category",
						"bills_by_supplier",
						"sales_overview",
						"hours_by_jobtype",
			            "sales_report",
					]
				},
				"project_status": {
					"name": "project_status", 
					"header": "Status", 
					"width": 80, 
					"fieldType": "text", 
					"search": []
				},
				"project_value": {
					"name": "project_value", 
					"header": "Value", 
					"width": 80, 
					"fieldType": "text", 
					"search": []
				},
				"project_targeted_purchases": {
					"name": "project_targeted_purchases", 
					"header": "Targeted Purchases", 
					"width": 130, 
					"fieldType": "text", 
					"search": []
				},
				"project_targeted_expenses": {
					"name": "project_targeted_expenses", 
					"header": "Targeted Expenses", 
					"width": 130, 
					"fieldType": "text", 
					"search": []
				},
				"project_backlog": {
					"name": "project_backlog", 
					"header": "Backlog", 
					"tooltipText": "Project Value - Invoiced Value",
					"width": 130, 
					"fieldType": "text", 
					"search": []
				},
				"project_backlog_undone_work": {
					"name": "project_backlog_undone_work", 
					"header": "Backlog, undone work", 
					"tooltipText": "Project Value - (Invoiced Value + Uninvoiced Value)",
					"width": 130, 
					"fieldType": "text", 
					"search": []
				},
				"order_numbers": {
					"name": "order_numbers", 
					"header": "Order nr.", 
					"width": 80, 
					"fieldType": "text", 
					"search": []
				},
				"maestro_id": {
					"name": "maestro_id", 
					"header": "Maestro id", 
					"width": 120, 
					"fieldType": "text", 
					"search": []
				},
				"date": {
					"name": "date", 
					"header": "Date", 
					"width": 120, 
					"fieldType": "date", 
					"search": []
				},
                "funnel_change_date": {
					"name": "funnel_change_date", 
					"header": "Funnel change date", 
					"width": 120, 
					"fieldType": "date", 
					"search": []
				},
                "event_end_date": {
					"name": "event_end_date", 
					"header": "Event end date", 
					"width": 120, 
					"fieldType": "date", 
					"search": []
				},
                "closing_date": {
					"name": "closing_date", 
					"header": "Closing date", 
					"width": 120, 
					"fieldType": "date", 
					"search": []
				},
				"description": {
					"name": "description", 
					"header": "Description", 
					"width": 160, 
					"fieldType": "text", 
					"search": [
						"employees_report",
						"employees_daily", 
						"customers_daily"
					]
				},
				"current_balance": {
					"name": "current_balance", 
					"header": "Balance", 
					"width": 180, 
					"fieldType": "hours_b", 
					"search": []
				},
				"previous_balance": {
					"name": "previous_balance", 
					"header": "Balance last month", 
					"width": 180, 
					"fieldType": "hours_b", 
					"search": []
				},
				"balance_start": {
					"name": "balance_start", 
					"header": "Balance start date", 
					"width": 180, 
					"fieldType": "date", 
					"search": []
				},
				"expected_hours": {
					"name": "expected_hours", 
					"header": "Expected hours", 
					"width": 100, 
					"fieldType": "hours_b", 
					"search": []
				},
				"monthly_hours": {
					"name": "monthly_hours", 
					"header": "Hours this month", 
					"width": 200, 
					"fieldType": "hours", 
					"search": []
				},
				"missed_hours": {
					"name": "missed_hours", 
					"header": "Missed hours", 
					"width": 100, 
					"fieldType": "hours", 
					"search": []
				},
				"expected_missed": {
					"name": "expected_missed", 
					"header": "Expected - Missed hours", 
					"width": 130, 
					"fieldType": "hours", 
					"search": []
				},
				"invoice_rate_hours": {
				       "name": "invoice_rate_hours", 
				       "header": "Invoice rate hours", 
				       "width": 200, 
				       "fieldType": "hours", 
				       "search": []
				},				
				"invoiceable_hours": {
					"name": "invoiceable_hours", 
					"header": "Invoiceable hours", 
					"width": 130, 
					"fieldType": "hours", 
					"search": []
				},
				"invoiceable_hours_amount": {
					"name": "invoiceable_hours_amount", 
					"header": "Uninvoiced hours (amount)", 
					"width": 130, 
					"fieldType": "text", 
					"search": []
				},
				"non_invoiceable_hours": {
					"name": "non_invoiceable_hours", 
					"header": "Non Invoiceable hours", 
					"width": 130, 
					"fieldType": "hours", 
					"search": []
				},
				"done_invoiceable": {
					"name": "done_invoiceable", 
					"header": "Done/Invoiceable hours (%)", 
					"width": 150, 
					"fieldType": "text", 
					"search": []
				},
				"invoiceable_expected": {
					"name": "invoiceable_expected", 
					"header": "Invoiceable/(Expected-missed) (%)", 
					"width": 180, 
					"fieldType": "text", 
					"search": []
				},
				"approved": {
					"name": "approved", 
					"header": "Approved", 
					"width": 150, 
					"fieldType": "translatedText", 
					"search": [], 
					"excluded": [],
					"filterConditions": [
						{type: "overtime_report", conditionType: 'any', filters: [{name: "approve_overtime_hours_before_balance", value: '1'}]},
						{type: "overtime_report", conditionType: 'any', filters: [{name: "approve_overtime_hours_before_billing", value: '1'}]},					
					]
				},				
				"tags": {
					"name": "tags", 
					"header": "Tags", 
					"width": 180, 
					"fieldType": "text",
					"search": [
						"company_report",
						"invoice_rate",
						"revenue_recognition_by_account",
						"revenue_recognition_by_user",
						"revenue_recognition_by_user_and_project",
						"profitability_by_account",
						"profitability_by_project",
						"profitability_on_recognitions_by_account",
						"profitability_on_recognitions_by_project",
						"scheduled_invoicing_by_account",
						"scheduled_invoicing_by_project",
						"monthly_invoicing_by_account",
						"monthly_invoicing_by_project",
						"gross_profit_forecast",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"projects_backlog",
						"bills_overview",
						"bills_by_category",
						"bills_by_supplier",
						"hours_by_jobtype",
			            "sales_report",
					]
				},
				"account_managers": {
					"name": "account_managers", 
					"header": "Account manager", 
					"width": 180, 
					"fieldType": "text", 
					"entityMode": true,
					"search": [
						"invoice_rate", 
						"employees_daily", 
						"employees_report", 
						"customers_daily", 
						"company_report",
						"invoices_summary",
						"invoices_summary_with_rows",
						"revenue_recognition_by_account",
						"revenue_recognition_by_user",
						"revenue_recognition_by_user_and_project",
						"profitability_by_account",
						"profitability_by_project",
						"profitability_on_recognitions_by_account",
						"profitability_on_recognitions_by_project",
						"scheduled_invoicing_by_account",
						"scheduled_invoicing_by_project",
						"monthly_invoicing_by_account",
						"monthly_invoicing_by_project",
						"gross_profit_forecast",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"projects_backlog",
						"bills_overview",
						"bills_by_category",
						"bills_by_supplier",
						"sales_overview",
						"hours_by_jobtype",
						"hours_by_task_type",
			            "sales_report",
					]
				},
				"project_managers": {
					"name": "project_managers", 
					"header": "Project manager", 
					"width": 180, 
					"fieldType": "text", 
					"entityMode": true,
					"search": [
						"invoice_rate", 
						"employees_daily", 
						"employees_report", 
						"customers_daily", 
						"company_report",
						"invoices_summary",
						"invoices_summary_with_rows",
						"revenue_recognition_by_account",
						"revenue_recognition_by_user",
						"revenue_recognition_by_user_and_project",
						"profitability_by_account",
						"profitability_by_project",
						"profitability_on_recognitions_by_account",
						"profitability_on_recognitions_by_project",
						"scheduled_invoicing_by_account",
						"scheduled_invoicing_by_project",
						"monthly_invoicing_by_account",
						"monthly_invoicing_by_project",
						"gross_profit_forecast",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"projects_backlog",
						"sales_overview",
						"hours_by_jobtype",
			            "sales_report",
					],
					"filterConditions": [
						{type: "bills_overview", filters: [{name: "groupByFilter", value: 'project_managers'}]},						
					]
				},
				"project_team": {
					"name": "project_team", 
					"header": "Project team", 
					"width": 160, 
					"fieldType": "text", 
					"search": [
						"company_report",
						"invoice_rate",
						"invoices_summary",
						"invoices_summary_with_rows",
						"profitability_by_account",
						"profitability_by_project",
						"profitability_on_recognitions_by_account",
						"profitability_on_recognitions_by_project",
						"scheduled_invoicing_by_account",
						"scheduled_invoicing_by_project",
						"monthly_invoicing_by_account",
						"monthly_invoicing_by_project",
						"projects_backlog",
						"hours_by_jobtype",
						"hours_by_task_type",
					]
				},
				"sales_agents": {
					"name": "sales_agents", 
					"header": "Sales agent", 
					"width": 180, 
					"fieldType": "text", 
					"entityMode": true,
					"search": [
						"invoice_rate", 
						"employees_daily", 
						"employees_report", 
						"customers_daily", 
						"company_report",
						"invoices_summary",
						"invoices_summary_with_rows",
						"revenue_recognition_by_account",
						"revenue_recognition_by_user",
						"revenue_recognition_by_user_and_project",
						"profitability_by_account",
						"profitability_by_project",
						"profitability_on_recognitions_by_account",
						"profitability_on_recognitions_by_project",
						"scheduled_invoicing_by_account",
						"scheduled_invoicing_by_project",
						"monthly_invoicing_by_account",
						"monthly_invoicing_by_project",
						"gross_profit_forecast",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"projects_backlog",
						"bills_overview",
						"bills_by_category",
						"bills_by_supplier",
						"sales_overview",
						"hours_by_jobtype",
			            "sales_report",
					]
				},
				"team": {
					"name": "team", 
					"header": "Team", 
					"width": 150, 
					"fieldType": "text", 
					"search": [
						"invoice_rate", 
						"hour_balance", 
						"employees_daily", 
						"employees_report", 
						"customers_daily", 
						"company_report",
						"billing_rate_by_users",
						"revenue_recognition_by_account",
						"revenue_recognition_by_user",
						"revenue_recognition_by_user_and_project",
						"profitability_by_account",
						"profitability_by_project",
						"profitability_on_recognitions_by_account",
						"profitability_on_recognitions_by_project",
						"scheduled_invoicing_by_account",
						"scheduled_invoicing_by_project",
						"monthly_invoicing_by_account",
						"monthly_invoicing_by_project",
						"gross_profit_forecast",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"bills_overview",
						"bills_by_category",
						"bills_by_supplier",
						"hours_by_jobtype",
						"hours_by_task_type",
			            "sales_report",
					]
				},
				"user_type": {
					"name": "user_type", 
					"header": "User type", 
					"width": 150, 
					"fieldType": "number", 
					"entityMode": true,
					"search": [
						"invoice_rate", 
						"hour_balance", 
						"employees_daily", 
						"employees_report", 
						"customers_daily", 
						"company_report",
						"hours_by_jobtype",
					]
				},								
				"code": {
					"name": "code", 
					"header": "Code", 
					"width": 80, 
					"fieldType": "text", 
					"search": [
						"products_by_product", 
						"products_by_customer"
					]
				},
				"product_name": {
					"name": "product_name", 
					"header": "Product name", 
					"width": 250, 
					"fieldType": "text", 
					"search": [
						"products_by_product", 
						"products_by_customer",
					]
				},
				"product_group_name": {
					"name": "product_group_name", 
					"header": "Group", 
					"width": 180, 
					"fieldType": "text", 
					"search": [
						"products_by_product", 
						"products_by_customer"
					]
				},
				"costest_name": {
					"name": "costest_name", 
					"header": "Quote", 
					"width": 100, 
					"fieldType": "text",
					"filterConditions": [
						{type: "products_by_product", filters: [{name: "invoicedFilter", value: '2'}]},
						{type: "products_by_customer", filters: [{name: "invoicedFilter", value: '2'}]},
						{type: "cpq_by_cpq", filters: [{name: "invoicedFilter", value: '2'}]},
						{type: "cpq_by_customer", filters: [{name: "invoicedFilter", value: '2'}]}
					], 
					"search": [					
					]
				},
				"balance": {
					"name": "balance", 
					"header": "Balance", 
					"width": 80,
					"searchFieldType": "number", 
					"fieldType": "text", 
					"search": [
						"products_by_product", 
						"products_by_customer"
					]
				},
				"cost_price": {
					"name": "cost_price", 
					"header": "Purchase price", 
					"width": 100,
					"searchFieldType": "number", 
					"fieldType": "currency", 
					"search": [
						"products_by_product", 
						"products_by_customer",
						"cpq_by_cpq", 
						"cpq_by_customer"						
					]
				},
				"selling_price": {
					"name": "selling_price", 
					"header": "Selling price", 
					"width": 100,
					"searchFieldType": "number", 
					"fieldType": "currency",
					"search": [
						"products_by_product", 
						"products_by_customer",
						"cpq_by_cpq", 
						"cpq_by_customer"						
					]
				},				
				"price": {
					"name": "price", 
					"header": "Selling price", 
					"width": 100,
					"type": "number", 
					"fieldType": "zeroAcceptingCurrency",
					"search": [						
					]
				},
				"unit_price": {
					"name": "unit_price", 
					"header": "Unit price", 
					"width": 100,
					"type": "number", 
					"fieldType": "zeroAcceptingCurrency",
					"search": [						
					]
				},				
				"sold": {
					"name": "sold", 
					"header": "Sold", 
					"width": 100,
					"searchFieldType": "number", 
					"fieldType": "text",
					"canBeTotal": ['products_by_product', 'products_by_customer', 'cpq_by_cpq', 'cpq_by_customer'], 
					"search": [
						"products_by_product", 
						"products_by_customer",
						"cpq_by_cpq", 
						"cpq_by_customer"						
					]
				},
				"total_cost_price": {
					"name": "total_cost_price", 
					"header": "Total purchase price", 
					"width": 120,
					"searchFieldType": "number", 
					"fieldType": "currency", 
					"search": [
						"products_by_product", 
						"products_by_customer",
						"cpq_by_cpq", 
						"cpq_by_customer"						
					]
				},
				"total_price": {
					"name": "total_price", 
					"header": "Total price", 
					"width": 120,
					"searchFieldType": "number", 
					"fieldType": "currency", 
					"search": [
						"products_by_product", 
						"products_by_customer",
						"cpq_by_cpq", 
						"cpq_by_customer"						
					]
				},
				"total_margin": {
					"name": "total_margin", 
					"header": "Total margin", 
					"width": 120,
					"searchFieldType": "number",
					"fieldType": "currency", 
					"search": [
						"products_by_product", 
						"products_by_customer",
						"cpq_by_cpq", 
						"cpq_by_customer"						
					]
				},
				"total_sum": {
					"name": "total_sum", 
					"header": "Total", 
					"width": 180,
					"searchFieldType": "number", 
					"fieldType": "currency",
					"search": [],
				},
				"cpq_name": {
					"name": "cpq_name", 
					"header": "CPQ name", 
					"width": 250,
					"fieldType": "text",
					"search": [
						"cpq_by_cpq", 
						"cpq_by_customer"
					]
				},
				"cpq_description": {
					"name": "cpq_description", 
					"header": "CPQ description", 
					"width": 300,
					"fieldType": "text",
					"search": [
						"cpq_by_cpq", 
						"cpq_by_customer"
					]
				},
				"vatid": {
					"name": "vatid", 
					"header": "VAT ID", 
					"width": 100, 
					"fieldType": "text",
					"canBeTotal": ['invoicing_vs_subcontracting', 'invoicing_vs_subcontracting_bills'], 
					"search": [
						"invoicing_vs_subcontracting",
						"invoicing_vs_subcontracting_bills"
					]
				},
				"hours_billable": {
					"name": "hours_billable", 
					"header": "Hours Billable", 
					"width": 130, 
					"fieldType": "zerohours",
					"search": []
				},
				"hours_billable_price": {
					"name": "hours_billable_price", 
					"header": "Hours Billable Price", 
					"width": 150, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"hours_billed": {
					"name": "hours_billed", 
					"header": "Hours Billed", 
					"width": 130, 
					"fieldType": "zerohours", 
					"search": []
				},
				"hours_billed_list_price": {
					"name": "hours_billed_list_price", 
					"header": "Hours Billed List Price", 
					"width": 150, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"hours_sum": {
					"name": "hours_sum", 
					"header": "Hours Sum", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"sum_pete_not_billed": {
					"name": "sum_pete_not_billed", 
					"header": "sum_pete_not_billed", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"sum_pete_billed": {
					"name": "sum_pete_billed", 
					"header": "sum_pete_billed", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"sum_re_not_billed": {
					"name": "sum_re_not_billed", 
					"header": "sum_re_not_billed", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"sum_re": {
					"name": "sum_re", 
					"header": "sum_re", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"billed_sum": {
					"name": "billed_sum", 
					"header": "Billed sum", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"recognition_sum": {
					"name": "recognition_sum", 
					"header": "Recognition sum", 
					"width": 160, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
                "nonrecognized_sum": {
					"name": "nonrecognized_sum", 
					"header": "Non-recognized sum", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"scheduled_invoicing_sum": {
					"name": "scheduled_invoicing_sum", 
					"header": "Scheduled invoicing type sum", 
					"width": 160, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": [],
					"filterConditions": [																
					]
				},								
				"hours_done": {
					"name": "hours_done", 
					"header": "Hours done", 
					"width": 130, 
					"fieldType": "zerohours", 
					"search": []
				},
				"own_cost": {
					"name": "own_cost", 
					"header": "Own cost", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"hours_not_billable": {
					"name": "hours_not_billable", 
					"header": "Hours not billable", 
					"width": 130, 
					"fieldType": "zerohours", 
					"search": []
				},
				"billable_percent": {
					"name": "billable_percent", 
					"header": "Billable percent", 
					"width": 100, 
					"fieldType": "percentage", 
					"search": []
				},
				"hours_open": {
					"name": "hours_open", 
					"header": "Hours open", 
					"width": 130, 
					"fieldType": "zerohours", 
					"search": []
				},
				"open_percent": {
					"name": "open_percent", 
					"header": "Open percent", 
					"width": 100, 
					"fieldType": "percentage", 
					"search": []
				},
				"open_own_cost": {
					"name": "open_own_cost", 
					"header": "Open own cost", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"open_cost": {
					"name": "open_cost", 
					"header": "Open cost", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"billed_percent": {
					"name": "billed_percent", 
					"header": "Billed percent", 
					"width": 100, 
					"fieldType": "percentage", 
					"search": []
				},
				"billed_own_cost": {
					"name": "billed_own_cost", 
					"header": "Billed own cost", 
					"width": 150, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"billed_subcontracting": {
					"name": "billed_subcontracting", 
					"header": "Billed subcontracting", 
					"width": 150, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},				
				"billed_own_cost_sum": {
					"name": "billed_own_cost_sum", 
					"header": "Billed own cost sum", 
					"width": 150, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},				
				"billed_list_price": {
					"name": "billed_list_price", 
					"header": "Billed list price", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"bill_ids": {
					"name": "bill_ids", 
					"header": "Invoice numbers", 
					"width": 160, 
					"fieldType": "text",
					"search": [
					]
				},
				"bill_creationdates": {
					"name": "bill_creationdates", 
					"header": "Invoice dates", 
					"width": 160, 
					"fieldType": "text",
					"search": [
					]
				},		
				"creationdate": {
					"name": "creationdate", 
					"header": "Created date", 
					"width": 130, 
					"fieldType": "date",
					"type": "date",
					"search": [
					]
				},
				"customerreference": {
					"name": "customerreference", 
					"header": "Order number", 
					"width": 160, 
					"fieldType": "text",
					"searchFieldType": "text", 
					"search": [
						"invoices_summary",
						"invoices_summary_with_rows"
					]
				},
				"additional_info": {
					"name": "additional_info", 
					"header": "Additional info", 
					"width": 180, 
					"fieldType": "text", 
					"search": [
					]
				},
				"customer_references": {
					"name": "customer_references", 
					"header": "Customer references", 
					"width": 180, 
					"fieldType": "text", 
					"search": [
						"invoices_summary",
						"invoices_summary_with_rows"
					]
				},				
				"period": {
					"name": "period", 
					"header": "Peridiocity", 
					"width": 150, 
					"fieldType": "period",
					"canBeTotal": ['invoices_summary'], 
					"search": [
					]
				},
				"net_total": {
					"name": "net_total", 
					"header": "Invoiced total 0%", 
					"width": 130, 
					"fieldType": "currency", 
					"search": []
				},				
				"vat": {
					"name": "vat", 
					"header": "Vat %", 
					"width": 80, 
					"fieldType": "percentage", 
					"search": []
				},
				"vat_total": {
					"name": "vat_total", 
					"header": "Vat total", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"gross_total": {
					"name": "gross_total", 
					"header": "Invoiced total", 
					"width": 130, 
					"fieldType": "currency", 
					"search": []
				},
				"billing_zone": {
					"name": "billing_zone", 
					"header": "Area", 
					"width": 300, 
					"fieldType": "text", 
					"search": [
						"invoices_summary",
						"invoices_summary_with_rows"
					]
				},
				"memo": {
					"name": "memo", 
					"header": "Comments", 
					"width": 300,
					"fieldType": "text",
					"search": [
					]
				},
				"time_span": {
					"name": "time_span", 
					"header": "", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": [],
					"filterConditions": [																
					]					
				},
				"costs_bills": {
					"name": "costs_bills", 
					"header": "Bills", 
					"width": 130, 
					"fieldType": "currency", 
					"search": []					
				},
				"costs_expenses": {
					"name": "costs_expenses", 
					"header": "Expenses", 
					"width": 130, 
					"fieldType": "currency", 
					"search": []					
				},
				"costs_travel": {
					"name": "costs_travel", 
					"header": "Travel costs", 
					"width": 130, 
					"fieldType": "currency", 
					"search": []					
				},
				"costs_hours": {
					"name": "costs_hours", 
					"header": "Hours own costs", 
					"width": 130, 
					"fieldType": "currency", 
					"search": []					
				},			
				"gross_margin": {
					"name": "gross_margin", 
					"header": "Gross margin", 
					"width": 130, 
					"fieldType": "currency", 
					"search": []
				},
				"gross_margin_percentage": {
					"name": "gross_margin_percentage", 
					"header": "Gross margin %", 
					"width": 150, 
					"fieldType": "percentage", 
					"search": []
				},
				"gross_margin_per_hour": {
					"name": "gross_margin_per_hour", 
					"header": "Gross margin /h", 
					"width": 150, 
					"fieldType": "currency", 
					"search": []
				},
				"net_profit": {
					"name": "net_profit", 
					"header": "Net profit", 
					"width": 130, 
					"fieldType": "currency", 
					"search": []
				},
				"net_profit_percentage": {
					"name": "net_profit_percentage", 
					"header": "Net profit %", 
					"width": 150, 
					"fieldType": "percentage", 
					"search": []
				},
				"net_profit_per_hour": {
					"name": "net_profit_per_hour", 
					"header": "Net profit /h", 
					"width": 150, 
					"fieldType": "currency", 
					"search": []
				},
				"units_name": {
					"name": "units_name", 
					"header": "Unit's name", 
					"width": 180,
					"fieldType": "text",
					"search": [],
					"filterConditions": [
						{type: "invoicing_vs_subcontracting", filters: [{name: "show_subunits", value: true}]},
						{type: "invoicing_vs_subcontracting_bills", filters: [{name: "show_subunits", value: true}]},
						{type: "billing_rate_by_customers", filters: [{name: "show_subunits", value: true}]},
						{type: "profitability_by_account", filters: [{name: "show_subunits", value: true}]},
						{type: "profitability_on_recognitions_by_account", filters: [{name: "show_subunits", value: true}]},
						{type: "scheduled_invoicing_by_account", filters: [{name: "show_subunits", value: true}]},
						{type: "monthly_invoicing_by_account", filters: [{name: "show_subunits", value: true}]},
						{type: "gross_profit_forecast_by_account", filters: [{name: "show_subunits", value: true}]},
						{type: "gross_profit_forecast_on_recognitions_by_account", filters: [{name: "show_subunits", value: true}]}
					]					
				},
				"units_type": {
					"name": "units_type", 
					"header": "Unit's type", 
					"width": 180,
					"fieldType": "translatedText",
					"search": [],
					"filterConditions": [
						{type: "invoicing_vs_subcontracting", filters: [{name: "show_subunits", value: true}]},
						{type: "invoicing_vs_subcontracting_bills", filters: [{name: "show_subunits", value: true}]},
						{type: "billing_rate_by_customers", filters: [{name: "show_subunits", value: true}]},					
						{type: "profitability_by_account", filters: [{name: "show_subunits", value: true}]},
						{type: "profitability_on_recognitions_by_account", filters: [{name: "show_subunits", value: true}]},
						{type: "scheduled_invoicing_by_account", filters: [{name: "show_subunits", value: true}]},
						{type: "monthly_invoicing_by_account", filters: [{name: "show_subunits", value: true}]},
						{type: "gross_profit_forecast_by_account", filters: [{name: "show_subunits", value: true}]},
						{type: "gross_profit_forecast_on_recognitions_by_account", filters: [{name: "show_subunits", value: true}]}							
					]
				},
				"funnels": {
					"name": "funnels", 
					"header": "Pipelines", 
					"width": 160,
					"fieldType": "text",
					"entityMode": true,
					"search": [
					]
				},				
				"stages": {
					"name": "stages", 
					"header": "Pipeline's stages", 
					"width": 160,
					"fieldType": "text",
					"entityMode": true,
					"noSort": true, 
					"isFilteredBy": [
						{filterName: "funnelFilter", filterBy: "projects_pipelines_id"}
					],
					"search": [
						"scheduled_invoicing_by_account",
						"scheduled_invoicing_by_project",
						"profitability_by_account",
						"profitability_by_project",
						"profitability_on_recognitions_by_account",
						"profitability_on_recognitions_by_project",
						"gross_profit_forecast",
						"gross_profit_forecast_by_account",
						"gross_profit_forecast_by_project",
						"gross_profit_forecast_on_recognitions",
						"gross_profit_forecast_on_recognitions_by_account",
						"gross_profit_forecast_on_recognitions_by_project",
						"revenue_recognition_by_account",
						"revenue_recognition_by_user",
						"revenue_recognition_by_user_and_project",						
					]
				},
				"scheduled_invoicing_type": {
					"name": "scheduled_invoicing_type", 
					"header": "Invoicing type", 
					"width": 140,
					"fieldType": "translatedText",
					"search": [],
					"filterConditions": [
						{type: "scheduled_invoicing_by_account", filters: [{name: "scheduledTypeFilter", value: ['1', '2']}]},
						{type: "scheduled_invoicing_by_project", filters: [{name: "scheduledTypeFilter", value: ['1', '2']}]}
					]
				},
				"free_summary_text": {
					"name": "free_summary_text", 
					"header": "", 
					"width": 400,
					"fieldType": "translatedText",
					"removedHeaderProperties": [
						'showMenu',
						'sortable',
						'hideable'
					],
					"search": []
				},
				"default_account_custom_field": {
					"name": "", 
					"header": "", 
					"width": 200,
					"fieldType": "translatedText",
					"search": []
				},				
				"summary_type": {
					"name": "summary_type", 
					"header": "Summary type", 
					"width": 120,
					"fieldType": "translatedText",
					"search": [],
					"filterConditions": [
						{type: "gross_profit_forecast_by_account", filters: [{name: "show_grouped", value: false}]},
						{type: "gross_profit_forecast_by_project", filters: [{name: "show_grouped", value: false}]},
						{type: "gross_profit_forecast_on_recognitions_by_account", filters: [{name: "show_grouped", value: false}]},
						{type: "gross_profit_forecast_on_recognitions_by_project", filters: [{name: "show_grouped", value: false}]},					
					]
				},
				"row_name": {
					"name": "row_name", 
					"header": "Invoice row text", 
					"width": 200, 
					"fieldType": "text", 
					"search": [
						"invoices_summary_with_rows"
					]
				},
				"quantity": {
					"name": "quantity", 
					"header": "Quantity", 
					"width": 80, 
					"fieldType": "text", 
					"search": []
				},
				"main_unit": {
					"name": "quantity", 
					"header": "Quantity", 
					"width": 80, 
					"fieldType": "text", 
					"search": []
				},
				"division": {
					"name": "division", 
					"header": "Division", 
					"width": 160, 
					"fieldType": "text", 
					"search": [
						"employees_report",
						"employees_daily",
					],
					"filterConditions": [
						{type: "employees_report", filters: [{name: "show_division", value: true}]},
						{type: "employees_daily", filters: [{name: "show_division", value: true}]},
					]
				},
				"bills_targeted": {
					"name": "bills_targeted", 
					"header": "Bills targeted to", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"bills_not_targeted": {
					"name": "bills_not_targeted", 
					"header": "Not targeted from bill", 
					"width": 130, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},				
				"quote_selling_price": {
					"name": "quote_selling_price", 
					"header": "Sales quote subcontractor selling price", 
					"width": 180, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
                "quote_sales": {
					"name": "quote_sales", 
					"header": "Sales quote selling price", 
					"width": 180, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"quote_actual_margin": {
					"name": "quote_actual_margin", 
					"header": "Sales quote actual margin", 
					"width": 180, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"own_work_margin": {
					"name": "own_work_margin", 
					"header": "Own work margin", 
					"width": 180, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
                "quote_project_margin": {
					"name": "quote_project_margin", 
					"header": "Sales quote project margin", 
					"width": 180, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
                "project_margin": {
					"name": "project_margin", 
					"header": "Project margin", 
					"width": 180, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
                "quote_sales_margin": {
					"name": "quote_sales_margin", 
					"header": "Sales quote actual margin", 
					"width": 180, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
                "sales_margin": {
					"name": "sales_margin", 
					"header": "Sales margin", 
					"width": 180, 
					"fieldType": "zeroAcceptingCurrency", 
					"search": []
				},
				"paid_total": {
					"name": "paid_total", 
					"header": "Paid total", 
					"width": 180, 
					"fieldType": "zeroAcceptingCurrency",
					"search": [
						"hours_by_task_type",
					],
					"filterConditions": [
						{type: "invoices_summary", filters: [{name: "show_projects", value: false}]},						
					]					
				},
				"paid_date": {
					"name": "paid_date", 
					"header": "Paid date", 
					"width": 180, 
					"fieldType": "date",
					"type": "date",
					"search": [
					],
					"filterConditions": [
						{type: "invoices_summary", filters: [{name: "show_projects", value: false}]},						
					]
				},
				"timespan_changes": {
					"name": "timespan_changes", 
					"header": "Timespan changes", 
					"width": 180, 
					"fieldType": "hours_b", 
					"search": []
				},
				"edit_date": {
					"name": "edit_date", 
					"header": "Edit date", 
					"width": 130, 
					"fieldType": "date",
					"type": "date",
					"search": [
					]
				},
				"overtime_used": {
					"name": "overtime_used", 
					"header": "Overtime used", 
					"width": 140, 
					"fieldType": "hours_b", 
					"search": []
				},
				"cumulative_overtime": {
					"name": "cumulative_overtime", 
					"header": "Cumulative overtime", 
					"width": 140, 
					"fieldType": "hours_b", 
					"search": []
				},				
				"task_types": {
					"name": "task_types", 
					"header": "Task type", 
					"width": 180, 
					"fieldType": "text",
					"type": "text",
					"search": [
					],
					"orderConditions": [
						{reportType: "hours_by_task_type", orderIndex: 0, filter: {name: "groupByFilter", value: 'task_types'}},
					]
				},
				"task_description": {
					"name": "task_description", 
					"header": "Task description", 
					"width": 180, 
					"fieldType": "text",
					"type": "text",
					"search": [
					],
				},
				"netvisor_id": {
					"name": "netvisor_id", 
					"header": "Netvisor ID", 
					"width": 80, 
					"fieldType": "text",
					"type": "text",
					"search": [
					],
					"addonCondition": "netvisor"
				},				
			}
		};

		return definitions;

	}
	
};

export default ReportsDefinitions;
