/* originally for kanban */

import React from 'react';
import PropTypes from "prop-types";

/* context */
import { SettingsContext } from '../SettingsContext';

/* material ui */
import { Button } from '@mui/material';

/* local components */
import TaimerComponent from "../TaimerComponent";
import OutlinedField from "./../general/OutlinedField";
import { AddAccount } from '../general/no-options/AddItemComponents';
import DataList from '../general/DataList';
import CustomFields from './customfields/CustomFields';
import TreeSelect from '../general/TreeSelect';
import LoaderButton from "../general/LoaderButton";

/* css */
import './AddProject.css';

/* data backend */
import DataHandler from './../general/DataHandler';
import { format } from "date-fns";
import _ from 'lodash';
import { withSnackbar } from 'notistack';
 
class AddProject extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "project/AddProject");

        this.orderMap = {};
        this.state = {
            account: {},
            accounts: [],
            team: undefined,
            teams: [],
            teamError: false,
            bobsFlat: [],
            loading: true,
            name: "",
            project_id: "",
            costest_sum: "",
            costest_margin: "",
            customFields: [],
            customFieldsErrors: {},
            pipeline: {},
            salesStates: [],
            branchesOfBusiness: [],
            categoryCount: 0,
            project: {
                name: "",
                account: {},
                status: "0",
                locked: "-1",
                project_id: "unsaved",
                closing_date: format(new Date().setDate(new Date().getDate() + 30), "YYYY-MM-DD"),
                startdate: format(new Date(), "YYYY-MM-DD"),
                enddate: format(new Date().setDate(new Date().getDate() + 30), "YYYY-MM-DD"),
                statelog: [],
                dialogOpen: false,
                type: "2",
                next_activity: {},
                projects_pipelines_id: 0,
                projects_sales_states_id: 0,
                invoicing: {
                    invoiced: 0,
                    due: 0,
                    vsquote: 0
                },
                custom: {},
                costest_sum:"0",
                costest_margin:"0",
                only_selected_worktasks: 0,
                projectNumberExists: false
            }
        };

        const {
            addons,
            userObject,
            taimerAccount
        } = this.context;

        const { mandatory_team_selection_for_projects } = taimerAccount;

        this.container = React.createRef();
        
        this.validation = {
            "name": React.createRef(),
            "costest_sum": React.createRef(),
            "costest_margin": React.createRef()
        };

        if(this.context.addons.custom_project_id) {
            this.validation.project_id = React.createRef();
        }

        ["addNewProject", "accountCreated", "accountCreated", "accountChanged", "onChange", "cancel"].forEach(e => this[e] = this[e].bind(this));
    }
    componentWillMount () {
        DataHandler.get({url: `subjects/accounts`}).done(response => this.setState({accounts : response}));
    }
    componentDidMount () {
        super.componentDidMount();
        const { userObject } = this.context;
        const { project } = this.state;
        const { projects_pipelines_id, laneId } = this.props;

        DataHandler.get({ url: `subjects/dimensions/teams/${userObject.companies_id}` }).done(teams => {
            this.setState({ teams: teams });
        });

        DataHandler.get({ url: `subjects/dimensions/categories/${userObject.companies_id}` }).done(categories => {
            this.setState({ bobsFlat: categories });
        });
  
        DataHandler.get({url: `settings/company/${userObject.companies_id}/project/customfields`}).done(data => {
            const customFields = [];
            _.forEach(data, e => {
                if(e.required == 1 && e.show_in_details == 1)
                    customFields.push(e);
            })

            DataHandler.get({ url: `settings/company/${userObject.companies_id}/default_project_category` }).done(data => {
                const useDefaultCategory = data.project_category_settings == 1;
                DataHandler.get({url: 'dialogs/branches', deleted: 1, locked: 1, companies_id: userObject.companies_id}).done(branchesOfBusiness => {
                    const defaultBob = branchesOfBusiness.find(x => x.is_default == 1);
                    const categoryCount = branchesOfBusiness.filter(bob => bob.deleted != 1).length;
                    const branchofbusiness_id = useDefaultCategory && defaultBob ? defaultBob.id : undefined;
    
                    let url = "";
                    let status = false;
                    switch(Number(projects_pipelines_id)) {
                        case -1:
                            url = `subjects/project_states`;
                            status = 1;
                            break;
                        case -5:
                            url = `subjects/internal_states`;
                            status = 5;
                            break;
                        default:
                            url = `subjects/sales_states/${projects_pipelines_id}`;
                    }
    
                    DataHandler.get({ url: url }).done(salesStates => {
                        const orderMap = (salesStates || []).reduce((prev, value) => {
                            const map = {
                                ...prev,
                                [value.id]: value.stateorder
                            }
                            return map;
                        }, {});
                        this.orderMap = orderMap;
                        this.setState({ loading: false, customFields, branchesOfBusiness, categoryCount, salesStates, project: { ...project, branchofbusiness_id, status, projects_pipelines_id, projects_sales_states_id: laneId } });
                    });
                });
            }); 
        });

        const current = this.container.current;
        current.parentNode.parentNode.offsetHeight > 330 && this.setState({bottom: true});
    }
    accountCreated (account) {
        this.setState({accounts: [...this.state.accounts, account]});
        this.accountChanged(account);
    }
    accountChanged (account) {
        this.setState({account});
    }
    bobChanged = (evt) => {
        const { project } = this.state;
        project.branchofbusiness_id = evt.target.value;
        this.setState({project: {...project}});
    }
    onChange (evt) {
        const { name, value } = evt.target;
        this.setState({[name]: value});
    }
    handleTeamChange = (value) => {
        const {
            force_teams_branchofbusiness_for_projects,
            mandatory_team_selection_for_projects
        } = this.context.taimerAccount;

        const { bobsFlat } = this.state;

        const state = {
            team: value,
            teamError: false
        };

        if(force_teams_branchofbusiness_for_projects) {
            const bobId = bobsFlat.find(bob => {
                return bob.id === value.category;
            })?.id;

            state.project = { 
                ...this.state.project, 
                branchofbusiness_id: bobId 
            };
        }

        this.setState(state);
    }
    customFieldEdited = (name, value) => {
        const { project } = this.state;
        if (name.startsWith("custom_"))
            name = name.substring(7);

        this.setState({project:{...project, custom:  {...project.custom, [name]: value}}}, () => {this.validateCustomField(name)});
    }
    validateAllCustomFields = () => {
        const { customFields, project: { custom } } = this.state;
        let hasErrors = false;
        const customFieldsErrors = { ...this.state.customFieldsErrors };
        const errorFields = [];

        _.forEach(customFields, v => {
            const value = (custom && custom[v.id]) || '';
            const correctPipeline = (Number(v.projects_pipelines_id) === Number(this.props.projects_pipelines_id) || Number(v.projects_pipelines_id) === 0)
            const fieldSalesStateOrder = this.orderMap[v.projects_sales_states_id];
            const projectSalesStateOrder = this.orderMap[this.state.project.projects_sales_states_id];
            const correctSalesState =  (!fieldSalesStateOrder || !projectSalesStateOrder) || (Number(fieldSalesStateOrder) < Number(projectSalesStateOrder));

            if (v.required && correctPipeline && correctSalesState && String(value).trim() === '') {
                customFieldsErrors[v.id] = true;
                hasErrors = true;
                errorFields.push(v.name);
            }
        });
        this.setState({ customFieldsErrors });
        return hasErrors;
    }

    validateCustomField = (id) => {
        const { customFields, project: { custom } } = this.state;
        const customFieldsErrors = { ...this.state.customFieldsErrors };

        const field = customFields.find(f => Number(id) === Number(f.id));
        const value = (custom && custom[field.id]) || '';
        const correctPipeline = (Number(field.projects_pipelines_id) === Number(this.props.projects_pipelines_id) || Number(field.projects_pipelines_id) === 0)

        if (field.required && correctPipeline && String(value).trim() === '')
            customFieldsErrors[field.id] = true;
        else
            customFieldsErrors[field.id] = false;

        this.setState({ customFieldsErrors });
    }

    async addNewProject () {
        const { account, name, project_id, costest_sum, costest_margin, project, categoryCount, team } = this.state;
        const { status, projects_pipelines_id, laneId, onAdd } = this.props;
        const { usersId } = this.context.userObject;
        let errors = false;

        this.setState({ projectNumberExists: false, loading: true });

        const { addons, userObject, taimerAccount } = this.context;
        let errorText = this.tr("Required fields not filled.");
        if(addons?.dimensions?.used_by_companies?.indexOf(userObject.companies_id) > -1
            &&
            taimerAccount.mandatory_team_selection_for_projects 
            && team === undefined) {
            this.setState({ 
                teamError: true 
            });
            errors = true;
        }

        /* validate all inputs */
        Object.keys(this.validation).forEach(e => {
            if (!this.validation[e].current.isValid()) {
                errorText = this.tr("Some of the inputs are in an invalid format.");
                errors = true;
            }
        })

        if (errors && !name) {
            errorText = this.tr("Required fields not filled.");
        }

        if (!account.id) {
            this.setState(state => (state.account.id = 0) || state);
            errors = true;
        }
         if (categoryCount > 0 && !project.branchofbusiness_id) {
            this.setState(state => (state.project.branchofbusiness_id = 0) || state);
            errors = true;
        }

        if (this.validateAllCustomFields() || errors) {
            this.setState({ loading: false });
            this.props.enqueueSnackbar(errorText, {
				variant: "error",
			});
            return;
        }

        const defaults = await DataHandler.get({url: 'projects/new'});
        const newProject = {
            ...defaults,
            name,
            project_id,
            account,
            costest_sum,
            costest_margin,
            custom: project.custom,
            closing_date: project.closing_date,
            branchofbusiness_id: project.branchofbusiness_id,
            dimension_teams_id: this.state.team?.id || 0
        };
        if (status !== undefined)
            newProject.status = status;
        
        if (projects_pipelines_id !== undefined)
            newProject.projects_pipelines_id = projects_pipelines_id;
        
        if (laneId !== undefined)
            newProject.projects_sales_states_id = laneId;
        
        DataHandler.post({url: 'projects'}, newProject).done(e => {
            //this.context.mixpanel.track('Create Project', {'Origin': 'Project dropdown'});
            //this.context.mixpanel.people.increment('# of projects created');

            this.props.enqueueSnackbar(this.tr('Project ${id} created', {id: e.project_id}), {
                variant: "success" 
            });

            newProject.customer = account.name;
            newProject.id = e.id;
            newProject.label = newProject.name;
            newProject.project_id = e.project_id;
            newProject.project_team = [];
            newProject.revenue = costest_sum || 0;
            newProject.salesmargin = costest_margin || 0;
            this.props.onAdd && this.props.onAdd(newProject);
            this.props.fetchData && this.props.fetchData();
            this.setState({ loading: false });
        }).fail(response => {
            this.setState({ loading: false });
            if(response.status === 403 && response.responseJSON.hasOwnProperty("error") && response.responseJSON.error === "EXISTING_PROJECT_ID") {
                this.setState({ projectNumberExists: true }, () => {
                    this.showProjectNumberExistsNotification();
                });
            }
        });
    }
    showProjectNumberExistsNotification = () => {
        if (this.props.enqueueSnackbar) {
            this.props.enqueueSnackbar(this.tr("The given project number already exists. Please choose another project number."), {
                variant: "error" 
            });
        }
    };
    cancel () {
        this.props.onCancel && this.props.onCancel();
    }
    render () {
        const { 
            functions: { checkPrivilege }, 
            userObject, 
            addons, 
            taimerAccount 
        } = this.context;

        const { 
            force_teams_branchofbusiness_for_projects, 
            mandatory_team_selection_for_projects
        } = taimerAccount;

        const { 
            accounts,
            account,
            name,
            project_id,
            costest_sum,
            costest_margin,
            bottom,
            project,
            customFields,
            customFieldsErrors,
            salesStates,
            team,
            teams 
        } = this.state;

        return (
            <div className={`add-project ${bottom && "bottom"}`} ref={this.container}>
                <div className="details">
                    <DataList 
                        label={this.tr('Account name')} 
                        name="customers_id"
                        options={accounts} 
                        value={account}
                        noOptions={AddAccount}
                        hideDatalistWhenNoOptionInFocus={true}
                        onItemCreated={this.accountCreated} 
                        onChange={this.accountChanged}
                        error={account.id == 0}
                        shownCount={20}
                        disabledSubtext={"(" + this.tr("Project creation not allowed") + ")"}
                        isOptionDisabled={(accounts => accounts.allow_project_creation != 1)}                            
                    />
                    <OutlinedField label={this.tr("Project Name")} value={name} name="name" onChange={this.onChange} validation={["empty"]} fullWidth ref={this.validation.name} />
                    { this.context.addons.custom_project_id &&
                        <OutlinedField 
                            label={this.tr("Project Number")} 
                            value={project_id} 
                            name="project_id" 
                            onChange={this.onChange}
                            disabled={!!this.context.addons.corporate_automatic_project_numbering}
                            validation={!this.context.addons.corporate_automatic_project_numbering && ["empty"]} 
                            error={this.state.projectNumberExists}
                            fullWidth 
                            ref={this.validation.project_id} />
                    }
                    {addons?.dimensions?.used_by_companies?.indexOf(userObject.companies_id) > -1 && (
                        <DataList
                            ref={this.validation.team}
                            label={this.tr("Team")}
                            name="dimension_team"
                            options={teams}
                            onChange={this.handleTeamChange}
                            error={this.state.teamError}
                            value={team}
                        />
                    )}
                    <TreeSelect
                        className="full"
                        disabled={force_teams_branchofbusiness_for_projects 
                                && 
                                team !== undefined}
                        name="branchofbusiness_id"
                        label={this.tr("Category")}
                        value={(this.state.project.branchofbusiness_id)}
                        options={this.state.categoryCount > 0 && this.state.branchesOfBusiness}
                        customItemLock={(item) => {
                            return item.deleted == 1 || item.locked == 1;
                        }}
                        validation={this.state.categoryCount > 0 ? ["empty"] : null}
                        error={this.state.categoryCount > 0 && this.state.project.branchofbusiness_id == 0}
                        onChange={this.bobChanged}
                        noOptionsMessage ={this.tr("No options")}
                        fullWidth />

                    <CustomFields 
                        ref={this.refCustomFields}
                        fields={customFields}
                        form={0}
                        project={project}
                        notCurrentStage={true}
                        values={project.custom || {}}
                        disableOptional={true}
                        hideOptional={true}
                        onChange={this.customFieldEdited}
                        hideNoValueError={true}
                        errors={{...customFieldsErrors}}
                        projects_sales_states={salesStates}
                    />
                    <div className="values">
                        <OutlinedField label={this.tr("Deal Value")} name="costest_sum" onChange={this.onChange} value={costest_sum} validation={["numeric"]}  ref={this.validation.costest_sum}/>
                        <OutlinedField label={this.tr("Deal Margin")} name="costest_margin" value={costest_margin} onChange={this.onChange} validation={["numeric"]} ref={this.validation.costest_margin}/>
                    </div>
                </div>
                <div className="TMRDialogButtons">
                    <button data-testid="cancel_button" className="addProjectCancel" onClick={this.cancel}>{this.tr('Cancel')}</button>
                    <LoaderButton
                        data-testid="add_project_button"
                        className="TMRDialogButton blue addProjectButton"
                        text={this.tr("Add")}
                        loading={this.state.loading}
                        onClick={this.addNewProject}
                    />
                </div>
            </div>
        );
    }
    
}

export default withSnackbar(AddProject);
