import React, { Component } from 'react';
import cn from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment';
import { addMinutes, format } from 'date-fns';
import { Add } from '@mui/icons-material';

// Wrapper to support Drag & Drop of Projects
// Week/day view
class TimeSlotWrapper extends Component
{
	static contextTypes = {
		timetracker: PropTypes.object,
		calendar: PropTypes.object
	};

	constructor(props)
	{
		super(props)
		
		this.dragEnter = this.dragEnter.bind(this);
		this.dragLeave = this.dragLeave.bind(this);
		this.dragOver = this.dragOver.bind(this);
		this.drop = this.drop.bind(this);
		this.hours = props.value.getHours && props.value.getHours();
		this.minutes = props.value.getMinutes && props.value.getMinutes();
		this.state = { 
			over: false,
			mouseDown: false,
		 };

		this.refEl = React.createRef();
	}

	componentDidMount() {
		const { value } = this.props;

		window.addEventListener('mousedown', this.globalHandleMouseDown)
		window.addEventListener('mouseup', this.globalHandleMouseUp)

		if (this.refEl.current && value && value.getHours() === 6 && value.getMinutes() < 30)
		{
			const timeContents = document.getElementsByClassName("rbc-time-view");
			if (timeContents.length > 0) {
				timeContents[0].scrollTop = this.refEl.current.offsetTop;
			}	
		}
	}

	globalHandleMouseDown = () => {
		if(!this.state.mouseDown){
			this.setState({ mouseDown: true })
		}
		
	}

	setClickStart = (e) => {
		window.checkForDrag = e.clientY;
	}

	globalHandleMouseUp = () => {
		if(this.state.mouseDown){
			this.setState({ mouseDown: false })
		}
	}

	checkForClick = (e) => {
		const mouseUp = e.clientY;
		if (
		  mouseUp < window.checkForDrag + 6 &&
		  mouseUp > window.checkForDrag - 6
		) {
			this.props.createEvent(this.props.value, addMinutes(this.props.value, this.props.calendarAccuracy))
		}
	}

	dragEnter(e)
	{
		if (e.dataTransfer.types.indexOf("event") === -1)
			return;

		this.setState({over: true});
	}

	dragLeave(e)
	{
		if (e.dataTransfer.types.indexOf("event") === -1)
			return;

		this.setState({over: false});
	}

	dragOver(e)
	{
		if (e.dataTransfer.types.indexOf("event") === -1)
			return;
			
		e.preventDefault();
	}

	drop(e)
	{
		if (e.dataTransfer.types.indexOf("event") === -1)
			return;

		const data = e.dataTransfer.getData("event");
		const event = JSON.parse(data);

		event.start = this.props.value;
		event.end = this.props.value;
		event.isDragged = true;
		this.setState({over: false});

		this.context.timetracker.createNewEvent(event, new Date(event.start), new Date(event.end), false);
	}

	onMouseOver = () => {
		if (this.hours != undefined && this.minutes != undefined && !this.state.mouseDown) {
			this.props.setHoverLocation({ hours: this.hours, minutes: this.minutes });
		}
	}

	onMouseLeave = () => {
		if (this.state.mouseDown) return;
		this.props.cancelHoverLocation();
	}

	render() {
		const { timetracker: { workdays } } = this.context;

		const dateCompare = format(this.props.value, "YYYY-MM-DD");
		const holiday = (workdays[dateCompare] ?? {type: 0}).type === 3;

		const use12hr = this.context?.calendar?.clock === 0;
		const timeFormat = use12hr ? 'h:mm' : 'HH:mm';

		return (<div ref={this.refEl}
			data-testid={`calendar-slot-${format(this.props.value, 'HHmm')}`}
			className={cn("rbc-time-slot-wrapper", holiday ? "holiday" : "workday", this.state.over && "rbc-addons-dnd-over", this.state.mouseDown && 'no-hover')} 
			onDragEnter={this.dragEnter} 
			onDragLeave={this.dragLeave} 
			onDrop={this.drop} 
			onDragOver={this.dragOver} 
			onMouseDown={this.setClickStart}
			onMouseUp={this.checkForClick}
			onMouseOver={this.onMouseOver} 
			onMouseLeave={this.onMouseLeave}
			>
				<div className='entry-draw-hover-block'>
					<Add />
					<p>{this.props.tr("Drag to draw an entry")}</p>
				</div> 
				{this.props.hoverLocation?.hours == this.hours && this.props.hoverLocation?.minutes == this.minutes && this.minutes != 0 && <div className={`time-label`}>{format(this.props.value, timeFormat)}</div>}
			{this.props.children}
		</div>);
	}
}

TimeSlotWrapper.contextTypes = {
	timetracker: PropTypes.object,
	calendar: PropTypes.object
};

export default TimeSlotWrapper