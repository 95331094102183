import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import { Button, MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import MassDialog from '../dialogs/mass_operations/CoreDialog';
import ContextMenu from '../general/ContextMenu';
import DataHandler from "../general/DataHandler";
import DateCell from "../list/cells/DateCell";
import EditableStatusCell from "../list/cells/EditableStatusCell";
import CurrencyListCell from "../list/CurrencyListCell";
import LinkListCell from "../list/LinkListCell";
import List from "../list/List";
import ListCell from "../list/ListCell";
import ListRow from "../list/ListRow";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import { SettingsContext } from '../SettingsContext';
import TaimerComponent from '../TaimerComponent';
import './BillTargeting.css';
import './PoSyncing.css';

import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';
import PoSyncingSlider from './PoSyncingSlider';

const styles = theme => ({
    barButton: {
        padding: 1,
    }
})
class PoSyncingRow extends PropsOnlyListRow {
    static contextType = SettingsContext;
    static defaultProps = {
        ...ListRow.defaultProps,
        rowProps: {
            ...ListRow.defaultProps.rowProps,
            editMode: false
        },
    };
        
    constructor(props, context) {
        super(props, {}, {}, "bills/PoSyncing");
    }

    // TODO: abstract
    defineClassName() {
        return "PoSyncingRow";
    }

    defineCells() {
        const { data, rowProps } = this.props;
        const { currency, editMode, company } = rowProps;
        const listCellProps = {
            inEditMode: false
        }

        let cells = {
            context:
                !editMode ? 
                    <ListCell width={this.props.columnWidthMap.context} editable={false} /> 
                    : 
                    <ContextMenu className="cell row-menu" onClick={() => { this.setState({error : false}); }} label={<ContextMenuIcon />} buttonProps={{ className: 'action-menu' }} style={{ width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + " 1 0px" }} noExpandIcon>
                        <MenuItem className="delete" onClick={() => rowProps.onDelete(data['id']) }><RemoveIcon className="Delete"/> {this.tr("Unsync")}</MenuItem>
                    </ContextMenu>,
            nr:
                <LinkListCell
                    listCellProps={{ inEditMode: false }}
                    value={data['id']}
                    urlHandler={value => `index.html?module=purchaseorder&action=view&id=${data['id']}&companies_id=${company}`}
                    noTab={true}
                    asText={!data.readable}
                    editable={false}
                    data-testid={`nr_${this.props.rowIndex}`}
                />,
            state:
                <EditableStatusCell
                    listCellProps={{inEditMode: false}}
                    value={data['state']}
                    options={this.props.sharedData.statuses}
                    editable={false}
                /> ,
            state_date:
                <DateCell
                    listCellProps={listCellProps}
                    value={data['state_date']}
                    editable={false}
                /> ,
            supplier:
                <ListCell
                    listCellProps={listCellProps}
                    value={data['supplier']}
                    editable={false}
                />,
            customer:
                <ListCell
                    listCellProps={listCellProps}
                    value={data['customer']}
                    editable={false}
                />,
            project:
                <LinkListCell
                    listCellProps={listCellProps}
                    value={data['project']}
                    urlHandler={value => `index.html?module=projects&action=view&id=${data['project_id']}`}
                    editable={false}
                />,
            creation_date:
                <DateCell
                    listCellProps={listCellProps}
                    value={data['creation_date']}
                    editable={false}
                />,
            duedate:
                <DateCell
                    listCellProps={listCellProps}
                    value={data['duedate']}
                    editable={false}
                />,
            grosssum:
                <CurrencyListCell
                    listCellProps={listCellProps}
                    value={data['grosssum']}
                    editable={false}
                    currency={currency}
                />,
        };

        return cells;
    }
}

class PoSyncing extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "bills/PoSyncing");

        this.refList = React.createRef();

        this.state = {
            open: false,
            showPoSyncingSlider: false,
            syncedRows: [],
            statuses: [],
            warningDialogOpen: false,
            dialogData: {},
            showWarning: false,
            hasSyncRights: false,           
            ri_supplier_name: ""
        }
    }

    componentDidMount() {
       this.getData();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.company != this.props.company) {
            this.getData();
        }
    }

    getData = () => {
        const { company } = this.props;
        DataHandler.get({url: `bills/${this.props.billsId}/synced_po_rows/`}).done(response => {
            const hasPoWriteRights = this.context.functions.checkPrivilege("purchaseorders", "write", company) || response.allowed_sync_project_companies.includes(company);
            const hasRiWriteRights = this.context.functions.checkPrivilege("receivedinvoices", "approve", company) || this.context.functions.checkPrivilege("receivedinvoices", "pre_approve", company)
            const hasSyncRights = hasPoWriteRights && hasRiWriteRights;

            const showWarning = response.data.length > 0;
            const translated_statuses = response.states ? response.states.map(e => {return {...e, label: this.tr(e.label)}}) : [];
            this.setState({ hasSyncRights, syncedRows: response.data, statuses: translated_statuses, showWarning, ri_supplier_name: response.ri_supplier_name });
        });
    }

    getRows = () => {
        if (this.refList.current)
            return this.refList.current.getData();
        else
            return [];
    }

    handleToggle = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    }

    openPoSyncSlider = () => {
        if (this.state.showWarning)
            this.setState({ warningDialogOpen: true });
        else
            this.setState({ showPoSyncingSlider: true });
    }

    closePoSyncSlider = () => {
        this.setState({ showPoSyncingSlider: false });
    }

    openWarningDialog = (id) => {
        this.setState({ warningDialogOpen: true, dialogData: { id } });
    }

    closeWarningDialog = () => {
        this.setState({ warningDialogOpen: false });
    }

    confirmnWarningDialog = () => {
        this.setState({ warningDialogOpen: false, showPoSyncingSlider: true });
    }

    sync = async (id) => {
        let rows = [];
        try {
            rows = await DataHandler.get({ url: `bills/${this.props.billsId}/synced_po_rows`, po_id: id });
        } catch (e) {}

        this.setState({ syncedRows: rows.data, showWarning: false });
        this.closePoSyncSlider();
    }

    unSync = async (id) => {
        this.setState({ syncedRows: [], showWarning: false });
    }

    deleteSyncingRow(id) {
        let syncedRows = [...this.state.syncedRows];        

        const rowIndex = syncedRows.findIndex(e => e.id == id);
        const row      = syncedRows[rowIndex];

        let syncedRows_delete = [...this.state.syncedRows_delete, {...row, deleted: 1}];
        
        syncedRows.splice(rowIndex, 1);

        this.setState({syncedRows, syncedRows_delete});
    }

    renderWarningDialog = () => {
        return (
            <MassDialog
                onDialogClose={this.closeWarningDialog}
                onDialogSave={this.confirmnWarningDialog}
                dialogType={"delete"}
                dialogProps={{
                    onCloseClick: this.closeWarningDialog,
                    open: this.state.warningDialogOpen,
                    close: this.closeWarningDialog,
                    confirmButtonClass: "blue",
                    confirmButtonText: this.tr("Yes, sync").toUpperCase(),
                    header: this.tr("Are you sure?"),
                    warning: () => {
                        return this.tr("Bill is already synced to a purchase order. Syncing this purchase order will unsync your already synced purchase order!");
                    },
                    onConfirm: () => {
                        this.confirmnWarningDialog();
                    }
                }}
            />
        )
    }

    render() {
        const { editMode, currency, company, supplierId } = this.props;
        const { open, syncedRows, statuses, hasSyncRights, ri_supplier_name } = this.state;

        const showSyncing = hasSyncRights || syncedRows.length > 0;
        if (!showSyncing)
            return null;

        const columnConfig = {
            showMenu: false,
            resizeable: false,
            showResizeMarker: false,
            moveable: false,
            hideable: false,
        };

        return (
            <div id="PoSyncing" className="BillTargetingArea">
                <div className="BillTargetingHeader">
                    <Button
                        data-testid="po-targeting-toggle"
                        className="button"
                        size="small"
                        variant="text"
                        onClick={this.handleToggle}>
                        {this.tr('Purchase order sync') + " (" + syncedRows.length + ")"}
                        {!open ? <ExpandMore /> : <ExpandLess />}
                    </Button>
                </div>
                <div style={!open ? { display: 'none' } : undefined}>
                    <List
                        fluid={true}
                        noStateData={true}
                        id="poSyncingList"
                        className="poSyncingList"
                        ref={this.refList}
                        reverseNewData 
                        renderNewDataAtEnd
                        hideHeaderButtons={!editMode || !hasSyncRights}
                        height="auto"
                        data={syncedRows}
                        sharedData={{statuses: statuses}}
                        roundbutton={() => this.openPoSyncSlider()}
                        rowHeight={48} // row height + ONE margin
                        listRowType={PoSyncingRow}
                        ignoreRowPropsChange={false}
                        rowProps={{
                            currency,
                            editMode,
                            company,
                            hasSyncRights,
                            onDelete: (id) => {
                                if (id < 0)
                                    this.refList.current.removeNewRow(id);
                                else
                                    this.unSync(id);                        }
                        }}
                        columns={[
                            ... (hasSyncRights ? [{ name: "context", header: "", width: 30, ...columnConfig, columnHeaderType: "roundButton" }] : []),
                            { name: "nr", header: this.tr("PO nr."), width: 70, ...columnConfig },
                            { name: "state", header: this.tr("PO status"), width: 80, ...columnConfig },
                            { name: "state_date", header: this.tr("Status changed"), width: 70, ...columnConfig },
                            { name: "supplier", header: this.tr("Supplier"), width: 150, ...columnConfig },
                            { name: "customer", header: this.tr("Account"), width: 150, ...columnConfig },
                            { name: "project", header: this.tr("Project"), width: 150, ...columnConfig },
                            { name: "creation_date", header: this.tr("Created"), width: 50, ...columnConfig },
                            { name: "duedate", header: this.tr("Due date"), width: 50, ...columnConfig },
                            { name: "grosssum", header: this.tr("Sum"), width: 60, ...columnConfig },
                        ]} />
                </div>
                <PoSyncingSlider view={"bills"} company={company} supplierId={supplierId} ri_supplier_name={ri_supplier_name} currency={currency} syncedRows={syncedRows} statuses={statuses} open={this.state.showPoSyncingSlider} syncFunc={this.sync} onSliderClose={() => this.closePoSyncSlider()} />
                {this.state.warningDialogOpen && this.renderWarningDialog()}
            </div>
        );
    }
}

export default withStyles(styles)(PoSyncing);
