import React from 'react';
import TaimerComponent from '../TaimerComponent';

import Popover from '@mui/material/Popover';
import MUIList from '@mui/material/List';
import MUIListItem from '@mui/material/ListItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Switch from '@mui/material/Switch';
import { Tooltip } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

import "./TreeViewSelection.css";

export default class TreeViewSelection extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "general/TreeViewSelection");

        this.state = {
            viewMenuOpen: false,
            viewMenuAnchor: undefined,
        }

    }

    render() {
    	return (
			<Tooltip classes={{tooltip: 'darkblue-tooltip'}} title={this.tr("View options")}>
				<span className="tree-view-selection">
					<span onClick={e => {
						this.setState({ 
							viewMenuAnchor: this.state.viewMenuOpen ? null : e.currentTarget, 
							viewMenuOpen: !this.state.viewMenuOpen
						})
					}}>	
						<EyeIcon className="icon" />
						<ArrowDropDown className="small" />
					</span>
					<Popover
						open={this.state.viewMenuOpen}
						onClose={() => {
							this.setState({
								viewMenuAnchor: null,
								viewMenuOpen: false
							});
						}}
						anchorEl={this.state.viewMenuAnchor} 
						anchorOrigin={{ vertical: 45, horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						className="projectListPopOver"
						style={{}}>
						<MUIList>
							<MUIListItem className="muiListItem">
								<MUIListItemIcon className="bigicons">
									{/*<TaskOngoingIcon />*/}
								</MUIListItemIcon>
								<ListItemText primary={this.tr("Show tree structures")} className="controlSwitchers" />
								<ListItemSecondaryAction>
									<Switch
										color="primary"
										disabled={false}
										onChange={(e) => {
											this.props.onChange("showTreeStructures", e.target.checked);
										}}
										checked={this.props.showTreeStructures} />
								</ListItemSecondaryAction>
							</MUIListItem>
							<MUIListItem className="muiListItem">
								<MUIListItemIcon className="bigicons">
									{/*<TaskOngoingIcon />*/}
								</MUIListItemIcon>
								<ListItemText primary={this.tr("Show whole trees")} className="controlSwitchers" />
								<ListItemSecondaryAction>
									<Switch 
										color="primary"
										checked={this.props.showWholeTrees} 
										disabled={!this.props.showTreeStructures}
										onChange={(e) => {
											this.props.onChange("showWholeTrees", e.target.checked);
										}}
										/>
								</ListItemSecondaryAction>
							</MUIListItem>
							<MUIListItem className="muiListItem">
								<MUIListItemIcon className="bigicons">
									{/*<TaskOngoingIcon />*/}
								</MUIListItemIcon>
								<ListItemText primary={this.tr("Show matches and their children")} className="controlSwitchers" />
								<ListItemSecondaryAction>
									<Switch
										color="primary"
										checked={this.props.showMatchesAndChildren} 
										disabled={!this.props.showTreeStructures}
										onChange={(e) => {
											this.props.onChange("showMatchesAndChildren", e.target.checked);
										}}
										/>
								</ListItemSecondaryAction>
							</MUIListItem>
						</MUIList>
					</Popover>
				</span>
			</Tooltip>
    	);
    }
}