import React from 'react';

/* others */
import _ from 'lodash';
import DataHandler from './../../general/DataHandler';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import DataList from '../../general/DataList';
import { DateRangePicker } from './../../general/react-date-range/src';
import ContentBlock from '../../general/ContentBlock';

class InsightBlock extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        width: 3,
        visible: true,
    }

    constructor(props, context) {
        super(props, context, "dashboard/insights/InsightBlock");

        this.state = {
        }
    }

    render() {
        const { children, title, className, style, onMouseOut, ...props } = this.props;

        return (
            <div className={className} style={style} onMouseOut={onMouseOut}>
                <ContentBlock buttonTitle={title} {...props}>
                    {children}
                </ContentBlock>
            </div>
        );
    }
}

export default InsightBlock;