import React, { Component } from 'react';
import DataHandler from './../general/DataHandler';
import GroupView from './GroupView';
import { SettingsContext } from './../SettingsContext';
import './GroupView.css';
import TaimerComponent from '../TaimerComponent';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';

const styles = theme => ({

});

class GroupViewData extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "team-chat/GroupViewData");

        this.state = {
            viewData: {
                messages: [],
                hasOlder: 0,
                hasNewer: 0,
            },
            viewParams: {},
            locked: false,
        };

        this.refGroupView = React.createRef();
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.refreshMessages();
    }

    refresh = () => {
        this.refreshMessages();
    }

    refreshMessages = async (params = {}) => {
        const { type, id } = this.props;
        const { viewParams } = this.state;

        try {
            const viewData = await DataHandler.get({ url: `teamchat/${type}/${id}`, ...viewParams, ...params });
            this.setState({viewData, locked: false});

        } catch (error) {
            this.setState({locked: true});
        }

    }

    onSendMessage = async (message, attachments) => {
        const { onSendMessage } = this.props;

        try {
            const realid = await onSendMessage(message, attachments);
    
            this.refreshMessages({refresh: 1});
    
            return realid;
            
        } catch (error) {
            const { enqueueSnackbar } = this.props;


            return false;
        }
    }

    onNavigatePage = async (viewParams) => {
        this.setState({ viewParams }, () => this.refresh());
    }

    render() {
        const { disabled } = this.state;
        const { ...rest } = this.props;

        return (<GroupView 
            {...rest} 
            ref={this.refGroupView}
            onSendMessage={this.onSendMessage} 
            onNavigatePage={this.onNavigatePage} 
            data={this.state.viewData}
            disabled={disabled} />)
    }
}

export default withStyles(styles)(GroupViewData);