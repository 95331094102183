/* material-ui */
import Button from '@mui/material/Button';
import TrashCanIcon from "@mui/icons-material/Delete";

/* others */
import React from 'react';
import List from "../../list/List";
import { SettingsList } from "../../list/lists/SettingsList";
import ListCell from "../../list/ListCell";
import SettingRow from "../../list/rows/SettingRow";
import DataHandler from '../../general/DataHandler';
import TreeListRow from "../../list/TreeListRow";
import TextInputCell from "../../list/cells/TextInputCell";
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import { withSnackbar } from 'notistack';
import ImportDialog from "../../dialogs/imports/ImportProductDialog";
import SettingsGrid from "./SettingsGrid";
import ProductImportTemplate from '../import-examples/Taimer_Product_Import_template.xlsx';

class unitListRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell className="noBg pointer" permanentEditMode={true} onClick={() => this.delete(this.state.data)} width={this.props.columnWidthMap['delete']}><TrashCanIcon /></ListCell>,
            name: <TextInputCell validation={['empty']} onEdited={this.editAndSave} name="name" width={this.props.columnWidthMap['name']} value={this.state.data['name']} listCellProps={{ inEditMode: this.state.data.id < 0 }} 
                                 focusOnMount={true} />,
        };    
        return <div className="listElement row flex" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class Product extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
        perpage: 30,
    }
    constructor(props, context) {
        super(props, context, "settings/pages/Product");
        this.projectTagList = React.createRef();
        this.productCategoryList = React.createRef();
        this.productUnitList   = React.createRef();

        this.state = {
            initialized: false,
            dialogData: undefined,
            page: 1,
            pageCount: 1,
            groupCount: 0,
            perpage: this.props.perpage,
            invoiceRowSettingsData: {
                "show_product_code": 0,
                "show_product_unit_in_bill_row": 0
            },
            pCHeaderVisible:false,
            pTHeaderVisible:false,
        };

        this.dialogs = {
            confirmation: ConfirmationDialog,
            import: ImportDialog
        };

        this.invoiceRowSettings = [
            {
                type: "check",
                name: "show_product_unit_in_bill_row",
                label: this.tr("Show product units on invoice row"),
                className: "respSetting half",
                locked: false
            },
            {
                type: "check",
                name: "show_product_code",
                label: this.tr("Show product code on invoice row"),
                className: "respSetting half",
                locked: false
            },
        ];
    }

    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }
    
    updateComponentData = () => {
        this.getGroupsData();
        this.getUnitData();
        this.getProductInvoiceSettings();
    }

    getProductInvoiceSettings = () => {
        const { company } = this.props;
        DataHandler.get({url: `settings/company/${company}/product/invoicerow`}).done(response => {
            if(response.invoiceRowSettingsData)
                this.setState({invoiceRowSettingsData: response.invoiceRowSettingsData});
        }).fail(response => {
            console.log("Error", response);
        });
    }

    //Gets product groups data
    getGroupsData = (override = {}) => {
        const { company } = this.props;
        let parameters = { page: 1, perpage: this.state.perpage};

        for(let oi in override)
            parameters[oi] = override[oi];

        DataHandler.get({url: `settings/company/${company}/product/groups/`, ...parameters}).done(response => {
            this.setState(response, () => {
                setTimeout(() => {
                    try {
                        this.productCategoryList.current.endPageChangeAnimation();
                    } catch(e) {}
                });
            });
           // console.log(response, "response")
            if(response.groups.length == 0){
                this.setState({pCHeaderVisible:false});
            } 
        }).fail(response => {});
    }


    //Gets products and items units
    getUnitData = (extra = {}, cb = () => {}) => {
        const { company } = this.props;

        DataHandler.get({url: `settings/company/product/units/${company}`, ...extra}).done(response => {
            cb();

            this.setState(response);
            this.setState({initialized: true});
            if(response.units.length == 0){
                this.setState({pTHeaderVisible:false});
            } 
        }).fail(response => {});
    }
    
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    //Sets which dialog should be shown and opens it by settings currentDialog state
    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    //Closes all dialogs by settings currentDialog to false and resets dialogData
    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
        this.updateComponentData();
    }

    //Fires when dialog is confirmed
    confirmDialog = (data) => {
        const { company } = this.props;

        //Deletes selected product group
        if(data.process === "deletegroup") {
            this.productCategoryList.current.removeRow(data.id);

            DataHandler.delete({url: `settings/company/product/groups`},{id: data.id, companies_id: company}).done(response => {  
                this.getGroupsData({refresh: 1});
            }).fail(response => {});
        }

        //Deletes selected unit
        if(data.process === "deleteunit") {
            if(data.id > 0) {
                this.productUnitList.current.removeRow(data.id);
                
                DataHandler.post({url: 'settings/company/product/units'}, { companies_id: company, id: data.id, name: data.name, deleted: 1 }).done(response => {
                    this.getUnitData({refresh: 1});
                });
            } else {
                this.productUnitList.current.removeRow(data.id);
            }
        }

        this.closeDialog();
    }

    render() {
        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
        const { tr } = this;
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        const { company } = this.props; 
        const {groups,  pCHeaderVisible ,pTHeaderVisible} = this.state;
        const loaderStyle = {
            width: 60,
            height: 60,
            padding: 20,
          };

        return (
            <React.Fragment>
                {!this.state.initialized && 
                    <div> 
                      <img style={loaderStyle} src={require("../img/loading.svg").default}/>
                    </div>
                }
                {this.state.initialized
                    &&
                <React.Fragment>
                    
                    <div className="settings-feature-firstwrapper">
                        <h3>{tr("Products invoice row settings")}</h3>
                        <p className="subtitle">
                            {tr("Enable to control the cost targeting already during entering the data.")}
                        </p>
                        <SettingsGrid
                            settings={this.invoiceRowSettings}
                            item={this.state.invoiceRowSettingsData}
                            onChange={data => {
                                const { company } = this.props;
                                DataHandler.put(
                                    { url: `settings/company/${company}/product/invoicerow` }, data)
                                .then(response => {
                                    if(response.invoiceRowSettingsData) {
                                        this.setState({ invoiceRowSettingsData: response.invoiceRowSettingsData});
                                    }
                                });
                            }}
                        />
                    </div>
                    
                    <div className="settings-feature-wrapper">
                        <h3 >{tr("Product Category for advanced categorizing")}</h3>
                        <p className="subtitle">{tr("Sub-categories can be added from left side of the groups name.")}</p>
                        <Button className="green" onMouseUp={evt => {this.productCategoryList.current.addNewRow(); this.setState({pCHeaderVisible:true})}} size="large">{tr("Add product category")}</Button>
                        <List
                            id="productCategoryList"
                            className="settingsList"
                            height="auto"
                            manualListWidth={1000}
                            rowHeight={44}
                            listRowType={TreeListRow}
                            ref={this.productCategoryList}
                            showPageSelector={this.state.groupCount > 10}
                            emptyNewDataOnUpdate={false}
                            treeData={true}
                            noStateData={true}
                            pageCount={this.state.pageCount}
                            totalCount={this.state.groupCount}
                            hideHeader={( pCHeaderVisible || (this.state.groups && this.state.groups.length > 0)) ? false: true}
                            perpage={this.state.perpage}
                            onPerPageChange={perpage => {
                                this.setState({ perpage: perpage });
                                this.getGroupsData({ perpage: perpage });
                            }}
                            onPageChange={page => {
                                this.productCategoryList.current.startPageChangeAnimation();
                                this.setState({ page: page })
                                this.getGroupsData({ page: page })
                            }}
                            
                            rowProps={{
                                rowClassName: "projectCategoryRow",
                                rowHeight: 44,
                                addChildLabel: tr("Add sub-category"),
                                deleteLabel: tr("Delete"),
                                indentWidth: 24,
                                onCreate: data => {
                                    let error = false;
                                    this.state.groups.filter(pc => {
                                        if(pc.id != data.id && pc.name.toLowerCase() === data.name.toLowerCase())
                                            error = true
                                    })
                                    if(data.name == ""){
                                        this.props.enqueueSnackbar((tr("Enter product category ") + "."), {
                                            variant: "error",
                                        });
                                        return;
                                    }
                                    if (!error) {
                                        let parameters = { page: 1, perpage: this.state.perpage};
                                        return DataHandler.post({url: `settings/company/product/groups`},{ companies_id: company, name: data.name, parent_id: data.parent_id, ...parameters}).done(response => {
                                            this.productCategoryList.current.editData({ id: response.id }, data.id);
                                            // this.getGroupsData({refresh: 1});
                                        }).fail(response => {
                                            this.props.enqueueSnackbar((tr("Product category already exists") + "."), {
                                                variant: "error",
                                            });
                                            return;
                                        });
                                    }
                                },
                                onUpdate: data => {
                                    DataHandler.post({url: `settings/company/product/groups/update`}, data).done(response => {
                                        // this.setState(response);
                                    }).fail(response => {});
                                },
                                onDelete: data => {
                                    this.setState({
                                        dialogData: {
                                            id: data.id,
                                            text: tr("Do you want to delete product category") + " \"" + data.name + "\" " + tr("and its subcategories?"),
                                            process: "deletegroup"
                                        }
                                    });
                                    this.openDialog('confirmation');
                                },
                                addChild: data => {
                                    if(data.id < 0) {
                                        DataHandler.post({ url: `settings/company/product/groups` },{ companies_id: company, name: data.name, parent_id: data.parent_id }).done(response => {
                                            let newId = response.id;

                                            this.productCategoryList.current.editData({ id: newId }, data.id);
                                            this.productCategoryList.current.addNewRow({ parent_id: newId });
                                        });
                                    } else {
                                        this.productCategoryList.current.addNewRow({ parent_id: data.id });
                                    }
                                }
                            }}
                            parentKey="parent_id"
                            newRow={{
                                id: -1,
                                name: "",
                                companies_id: company
                            }}
                            columns={[
                                { width: 24, name: "contextMenu", header: "", ...dColConf },
                                { width: 350, name: "name", header: tr("Product category"), ...dColConf }
                            ]}
                            data={this.state.groups} />
                    </div>
                    <div className="settings-feature-wrapper">
                        <h3 style={{ marginTop: "24px" }}>{tr("Products & Items units")}</h3>
                        <p className="subtitle">{tr("Selectable units for products and items")}</p>
                        <Button className="green" onMouseUp={() => {this.productUnitList.current.addNewRow(); this.setState({pTHeaderVisible:true})}} size="large">{tr(" Add unit")}</Button>
                        <SettingsList
                            innerRef={this.productUnitList}
                            id="productUnitList"
                            className="settingsList"
                            height="auto"
                            rowHeight={SettingRow.rowHeight}
                            sharedData={this.state.autocompleteData}
                            hideHeader={( pTHeaderVisible || (this.state.units && this.state.units.length > 0)) ? false: true}
                            emptyNewDataOnUpdate={false}
                            data={this.state.units}
                            newRow={{
                                id: -1,
                                name:"",
                                users: [],
                                companies_id: company
                            }}
                            listRowType={unitListRow}
                            columns={[
                                { width: 55, name: "delete", header: "", ...dColConf },
                                { width: 315, name: "name", header: tr("Unit"), ...dColConf },
                            ]}
                            rowProps={{
                                onCreate: data => {
                                    let error = false;
                                    this.state.units.filter(u => {
                                        if(u.id != data.id && u.name.toLowerCase() === data.name.toLowerCase())
                                            error = true
                                    })
                                    if(data.name == ""){
                                        this.props.enqueueSnackbar((tr("Enter product unit ") + "."), {
                                            variant: "error",
                                        });
                                        return;
                                    }
                                    if (!error) {
                                        return DataHandler.post({url: 'settings/company/product/units'}, {companies_id: company, id: data.id, name: data.name, deleted: data.deleted }).done(response => {
                                         //   setTimeout(() => {
                                                // this.productUnitList.current.removeNewRow(data.id)
                                                // this.getUnitData();  
                                            //}, 1000) 
                                        });
                                    } if (error) {
                                        this.props.enqueueSnackbar((tr("Unit already exists") + "."), {
                                            variant: "error",
                                        });
                                        return;
                                    }
                                },
                                onUpdate: data => {
                                    let error = false;
                                    this.state.units.filter(u => {
                                        if(u.id != data.id && u.name.toLowerCase() === data.name.toLowerCase())
                                            error = true
                                    })
                                    if(!error) {
                                        DataHandler.post({url: 'settings/company/product/units'}, { id: data.id, name: data.name, deleted: data.deleted }).done(response => {
                                            // setTimeout(() => {this.updateComponentData()}, 1000);
                                        });
                                    } else {
                                        this.props.enqueueSnackbar((tr("Unit already exists") + "."), {
                                            variant: "error",
                                        });
                                        // setTimeout(() => {this.updateComponentData()}, 1000);
                                        return;
                                    }
                                },
                                    onDelete: data => {
                                    this.setState({
                                        dialogData: {
                                            id: data.id,
                                            name: data.name,
                                            deleted: 1,
                                            text: tr("Do you want to delete unit") + ": " + data.name + "?",
                                            process: "deleteunit"
                                        }
                                    });
                                    // },this.getUnitData()); // What's the point here?
                                    this.openDialog('confirmation');
                                    
                                    /* DataHandler.post({url: 'settings/company/product/units'}, { id: data.id, name: data.name, deleted: 1 }).done(response => {
                                        this.getUnitData();
                                    }); */
                                }
                            }} />   
                    </div>
                </React.Fragment>}
                
                    <div className="settings-feature-lastwrapper">  
                        <h3>{this.tr("Import Products")}</h3>
                        <p style={{ fontSize: "11px" }}> 
                            <span>
                                {this.tr("Import Products in XLSX format.")}
                            </span>
                            <span>
                            <a style={{ color: "#2D9FF7", marginLeft: "0.5em" }} href={ProductImportTemplate} download>
                                {this.tr("Download file template") + "."}
                                </a> 
                            </span>
                        </p>
                        <Button className="green" size="large" onMouseUp={() => {
                            this.openDialog('import')
                        }}>{this.tr("Import Products")}</Button>
                        
                        {Dialog && <Dialog
                            open
                            company={company}
                            onDialogClose={this.closeDialog}
                            updateParent={this.updateComponentData}
                            onDialogSave={() => {
                                this.confirmDialog(this.state.dialogData);
                            }}
                            data={this.state.dialogData} />}
                    </div>
                
            </React.Fragment>
        );
    }
}

export default withSnackbar(Product);
