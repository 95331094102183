import getColumnType, { ColumnDefinition, linkNumberComparator } from './ColumnTypes';
import { CustomFields, createCustomFields } from './CustomFields';

interface Params {
    customFields: CustomFields;
    tr: (text, replacers?: Record<string, string>) => string;
}

export default function getAccountColumns(params: Params) {
    const { customFields } = params;
    
    const columns: ColumnDefinition[] = [
        {
            field: 'account',
            headerName: 'Account',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=customers&action=view&id=$(id)"
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'account_num',
            headerName: 'Account number',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=customers&action=view&id=$(id)"
            },
            defaultAggFunc: 'sumAndGroup',
            comparator: linkNumberComparator
        },
        {
            field: 'account_parent',
            headerName: 'Account Parent',
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=customers&action=view&id=$(id)"
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'customer_account_managers',
            headerName: 'Account manager',
            ...getColumnType('links'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)"
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'customer_account_group',
            headerName: 'Account group',
            ...getColumnType('multi'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'customer_enterprise_group',
            headerName: 'Enterprise group',
            ...getColumnType('multi'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'customer_type',
            headerName: 'Account type',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'customer_industry',
            headerName: 'Account industry',
            ...getColumnType('multi'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'customer_tags',
            headerName: 'Account tags',
            ...getColumnType('multi'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: 'customer_locked',
            headerName: 'Account Status',
            ...getColumnType('status', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    {id: 0, "name": "Active", color: "#20C6A1"},
                    {id: 1, "name": "Archived", color: "#f52b2b"},
                ],
                tr: params.tr
            } 
        },
        ...createCustomFields(customFields.customers, 'customer_custom'),
    ]
    return columns;
}

            
