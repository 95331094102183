import React from 'react'

/* css */
import './ActivitiesInsightTypeView.css';

/* material-ui */
import Button from '@mui/material/Button';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

/* others */
import _ from 'lodash';
import List from "../../../list/List";
import ContentBlock from '../../../general/ContentBlock';
import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from '../../../../src/SettingsContext';
import ActivitiesInsightTypeViewRow from './ActivitiesInsightTypeViewRow';

class ActivitiesInsightTypeView extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context){
        super(props, context, "dashboard/insights/activities/ActivitiesInsightTypeView");
        this.state = {
            open: true,
        }

        this.columns = [
            { name: "name", header: this.tr("Type"), width: 130, moveable: true, hideable: false, showMenu: true },
            { name: "all", header: this.tr("New"), width: 100, moveable: true, hideable: true, showMenu: true, type: 'number' },
            { name: "done", header: this.tr("Done"), width: 100, moveable: true, hideable: true, showMenu: true, type: 'number' },
            { name: "due", header: this.tr("Due"), width: 100, moveable: true, hideable: true, showMenu: true, type: 'number' },
            { name: "overdue", header: this.tr("Overdue"), width: 100, moveable: true, hideable: true, showMenu: true, type: 'number' },
        ];
    }
    
    componentDidMount(){
        super.componentDidMount();
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.typeviewdata) this.sortRows("name", true, nextProps.typeviewdata);
    }

    //Opens and closes TypeView
    handleOpen = () => {
        this.setState({open: !this.state.open});
    }

    sortRows = (colName, asc, data) => {
        let multiplier = asc ? 1 : -1;
        const column = this.columns.find(e => e.name === colName);
        const sortData = data ? data : this.state.typeviewdata;

        const typeviewdata = sortData.sort((a, b) => {
            let A = a[column.name] || (column.type == "number" ? 0 : "");
            let B = b[column.name] || (column.type == "number" ? 0 : "");

            if (column.type == "number") 
                return (Number(A) - Number(B)) * multiplier;
            else 
                return (A + "").localeCompare(B + "", "fi") * multiplier;
        });

        this.setState({ typeviewdata });
    }

    render() {
        const { open } = this.state;

        return (
            <List
                fluid={true}
                rowKey="type_id"
                listRowType={ ActivitiesInsightTypeViewRow }
                data={ this.state.typeviewdata }
                columns={ this.columns }
                userListSettingsKey="activitiesinsight_typeview_list"
                saveColumnConfig={true}
                height="300" 
                onSortRows={(colName, asc) => {
                    this.sortRows( colName, asc );
                }}
            />
        )
    }
}

export default ActivitiesInsightTypeView;