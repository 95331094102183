import React from 'react';

/* others */
import _ from 'lodash';
import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from '../../../SettingsContext';
import MultiSelect from '../../../general/MultiSelect';

function idOrStr(value) {
    const val = Number(value)
    return !isNaN(val) ? val : String(value);
}

class InsightMultiselect extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        visible: true,
        returnAll: false,
    }

    constructor(props, context) {
        super(props, context, "dashboard/insights/filters/InsightMultiselect");

        this.state = {
            reset: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.value !== this.props.value) {
            this.setState({reset: true});
        }
    }

    change = (value) => {
        const { name, onChange } = this.props;
        const values = value.map(x => idOrStr(x.value)).filter(x => x !== 0);
        onChange(name, values);
    }

    render() {
        const { className, label, value, options, returnAll, disabled, ...props } = this.props;
        const { reset } = this.state;

        const values = value.map(idOrStr);

        const selectedValues = options.filter(x => values.indexOf(idOrStr(x.value)) !== -1);

        return (<MultiSelect
            className="margin"
            options={options.map(x => ({value: x.value, label: x.label ?? x.name}))}
            defaultValue={selectedValues}
            reset={reset}
            resetFunction={() => { this.setState({ reset: false }) }}
            resetValue={selectedValues}
            label={label}
            onChange={this.change}
            skipInitialChange
            returnAll={returnAll}
            disabled={disabled}
        />);
    }
}

export default InsightMultiselect;