import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import OutlinedField from "../../general/OutlinedField";
import Button from '@mui/material/Button';
import DataHandler from '../../general/DataHandler';
import { withSnackbar } from 'notistack';

const styles = theme => ({
    main: {
        paddingLeft: 0,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

class ReferralProgram extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/ReferralProgram");

        this.state = {
            loaded: false,
            canJoin: true,
        }
    }

    componentDidMount() {
        super.componentDidMount();
        DataHandler.post({ url: 'settings/referral' }, { create: 1 }).done((data) => {
            this.setState({
                ...data,
                loaded: true,
                canJoin: false,
            })
        });
    }

    render() {
        const { loaded, fp_authtoken, canJoin } = this.state;
        const { tr } = this;

        if (!loaded)
            return <div></div>

        if (!fp_authtoken) {

            return <div className="SettingsPadding">
                <div>{tr("Unable to create refferral account.")}</div>
            </div>

        }

        return <React.Fragment>
            <div className="ThirdpartyCookiesNotice">
                {this.htmlTr("If page does not login, ${link} or allow third-party cookies", {
                    link: <a href={`https://taimer.firstpromoter.com/iframe?at=${fp_authtoken}`} target="_blank">{this.tr("open in new window")}</a>
                })}
            </div>
            <iframe frameborder="0" src={`https://taimer.firstpromoter.com/iframe?at=${fp_authtoken}`}></iframe>
        </React.Fragment>
    }
}

export default withSnackbar(withStyles(styles)(ReferralProgram));