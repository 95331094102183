import React from "react";

/* css */
import "./ContentBlock.css";

/* material ui */
import Button from "@mui/material/Button";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";

/* others */

class ContentBlock extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: true
    };
  }

  static defaultProps = {
    headerClass: "",
    wrapperClass: "",
    wrapperId: "",
    headerId: "",
    showOverlay: false,
    canCollapse: true
  };

  //Opens and closes childrens div
  handleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { open } = this.state;
    const {
      rootClassName,
      headerClass,
      buttonTitle,
      canCollapse,
      wrapperClass,
      wrapperId,
      headerId,
      filterComponents,
      showOverlay,
      overlay,
      noPadding,
      noHeader,
      noBottomPadding
    } = this.props;
    const filtersClassName = filterComponents
      ? "content-block-main-header-filters"
      : "content-block-main-header-nofilters";

    let blockClassName = !open ? " closed" : "";
    let paddingClassName = !noPadding ? " content-block-padding" : "";

    return (
      <div
        id={`content-block`}
        className={rootClassName}
        style={(!open || noBottomPadding) ? { "padding-bottom": 0 } : {}}
      >
        <div
          className={"content-block-overlay" + blockClassName}
          style={!showOverlay ? { display: "none" } : {}}
        >
          <div
            className={"content-block-overlay-div"}
            style={!open ? { display: "none" } : {}}
          >
            {overlay}
          </div>
        </div>
        <div className={"content-block-main" + blockClassName}>
          {!noHeader && (
            <div
              id={headerId}
              className={`content-block-main-header content-block-padding ${headerClass}`}
            >
              {!canCollapse && buttonTitle && <h2 title={buttonTitle}>{buttonTitle}</h2>}
              {canCollapse && buttonTitle && (
                <Button
                  className="content-block-main-header-button"
                  size="small"
                  variant="text"
                  onClick={this.handleOpen}
                  title={buttonTitle}
                >
                    <h2>{buttonTitle}</h2>
                    {!open ? <ExpandMore /> : <ExpandLess />}
                </Button>
              )}
              <div
                className={`${filtersClassName}`}
                style={!open ? { display: "none" } : {}}
              >
                {filterComponents}
              </div>
            </div>
          )}
          <div
            id={wrapperId}
            className={wrapperClass + paddingClassName}
            style={!open ? { display: "none" } : {}}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default ContentBlock;
