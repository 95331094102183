import React from 'react';
import InsightTable, { InsightTableListRow } from "../../InsightTable";
import cn from 'classnames';
import ListCell from '../../../../list/ListCell';
import { parse, format } from 'date-fns';
import ListRow from '../../../../list/ListRow';

class HourlyProfitabilitySummaryRow extends ListRow {
  constructor(props, context) {
    super(
      props,
      {},
      {},
      "dashboard/insights/hours/components/HourlyProfitabilitySummaryRow"
    );
  }

  defineClassName() {
    return "insightSummaryRow";
  }

  defineCells() {
    const {
      rows,
      sharedData: { currency, allData },
    } = this.props;

    const {
      taimerAccount,
      functions: { presentCurrency },
    } = this.context;

    let values = {};
    allData.map((row) => {
      Object.keys(row || {}).forEach((key) => {
        values[key]
          ? (values[key] += Number(row[key] || 0))
          : (values[key] = Number(row[key] || 0));
      });
    });

    let cells = {};

    Object.keys(this.props.columnConfig).forEach((key) => {
      cells[key] = (
        <ListCell
          width={this.props.columnConfig[key].width}
          className="totalcell"
          name="user"
          onlyDisplay
          editable={false}
          alignCell
        >
          {key == "gross_profit" ? presentCurrency(values[key] || 0) : (Number(values[key] || 0).toFixed(2) || "-") + " h"}
        </ListCell>
      );
    });

    const totalColumns = [
      "project",
    ];

    const emptyColumns = [
      "account",
      "status",
      "created",
      "stage",
      "hourly_rate"
  ]

    totalColumns.forEach((col) => {
      cells[col] = (
        <ListCell
          width={(this.props.columnConfig[col] || {}).width || 0}
          className="totalcell"
          name={col}
          onlyDisplay
          editable={false}
          alignCell
        >
          {this.tr("Total")}
        </ListCell>
      );
    });
    
    emptyColumns.forEach((col) => {
      cells[col] = (
        <ListCell
          width={(this.props.columnConfig[col] || {}).width || 0}
          className="totalcell"
          name={col}
          onlyDisplay
          editable={false}
          alignCell
        >
          {""}
        </ListCell>
      );
    });

    return cells;
  }

  render() {
    const cells = this.defineCells();
    const className = [
      "summaryRow listElement row flex",
      this.defineClassName(),
    ].join(" ");

    return (
      <div className={className} style={{ height: `50px` }}>
        {this.props.columnOrder.map((columnName) => cells[columnName])}
      </div>
    );
  }
}

class HourlyProfitabilityCell extends InsightTableListRow {
  defineCells() {
    const { data } = this.props;
    let otherCells = {};

    const { taimerAccount, userObject } = this.context;
    const { sharedData: { currency } } = this.props;

    const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
        style: 'currency',
        currency,
    }).format;

    Object.keys(data).forEach(key => {
      switch (key) {
        case "account":
        case "project":
          otherCells[key] = (
            <ListCell onlyDisplay={true}>
              <div className="list-cell-container">
                <p>{data[key] || "-"}</p>
              </div>
            </ListCell>
          );
          break;
        case "hour_balance":
          otherCells[key] = (
            <ListCell onlyDisplay={true}>
              <div className="list-cell-container">
                <p>{Number(data[key]).toFixed(2) || "-"}</p>
              </div>
            </ListCell>
          );
          break;
          case "split":
            otherCells[key] = (
              <ListCell onlyDisplay={true}>
                <div className="list-cell-container">
                  <p>{data[key] || "-"} %</p>
                </div>
              </ListCell>
            );
            break;
        case "created":
          const date = parse(data[key], "YYYY-MM-DD", new Date(0));

          otherCells[key] = (
            <ListCell onlyDisplay={true}>
              <div className="list-cell-container">
                <p>{date ? format(date, userObject.dateFormat) : '-'}</p>
              </div>
            </ListCell>
          );
          break;
        case "tracked_h":
          otherCells[key] = (
            <ListCell onlyDisplay={true}>
              <div className="list-cell-container">
                <p>{Number(data[key]).toFixed(2) || "-"} h</p>
              </div>
            </ListCell>
          );
          break;
        case "gross_profit":
        case "hourly_rate":
          otherCells[key] = (
            <ListCell onlyDisplay={true}>
              <div className="list-cell-container">
                <p>{data[key] ? currencyFormatter(Number(data[key])) : "-"}</p>
              </div>
            </ListCell>
          );
          break;
        default:
          otherCells[key] = (
            <ListCell onlyDisplay={true}>
              <div className="list-cell-container">
                <p>{data[key] || "-"}</p>
              </div>
            </ListCell>
          );
          break;
      }
    });

    let cells = {
      ...super.defineCells(),
      ...otherCells
    };

    return cells;
  }
}

const HourlyProfitability = props => {
    const {loaded, tr, currency, data, showSlider } = props;
    const columns = [
        {
          field: "project",
          name: "project",
          header: tr("Project"),
          width: 200,
          resizeable: false,
          moveable: false,
          hideable: false,
          showMenu: false
        },
        {
          field: "account",
          name: "account",
          header: tr("Account"),
          width: 200,
          resizeable: false,
          moveable: false,
          hideable: false,
          showMenu: false
        },
        {
          field: "status",
          name: "status",
          header: tr("Status"),
          width: 100,
          resizeable: false,
          moveable: false,
          hideable: false,
          showMenu: false
        },
        {
          field: "created",
          name: "created",
          header: tr("Created"),
          width: 100,
          resizeable: false,
          moveable: false,
          hideable: false,
          showMenu: false
        },
        {
          field: "stage",
          name: "stage",
          header: tr("Stage"),
          width: 100,
          resizeable: false,
          moveable: false,
          hideable: false,
          showMenu: false
        },
        {
          field: "tracked_h",
          name: "tracked_h",
          header: tr("Tracked Hours"),
          width: 100,
          resizeable: false,
          moveable: false,
          hideable: false,
          showMenu: false
        },
        {
          field: "hourly_rate",
          name: "hourly_rate",
          header: tr("Hourly Rate"),
          width: 100,
          resizeable: false,
          moveable: false,
          hideable: false,
          showMenu: false
        },
        {
          field: "gross_profit",
          name: "gross_profit",
          header: tr("Gross Profit"),
          width: 100,
          resizeable: false,
          moveable: false,
          hideable: false,
          showMenu: false
        }
      ];

    return (
        <InsightTable
          loaded={loaded}
          height={400}
          hideDefaults={true}
          blockProps={{
            largeTitle: true,
            rootClassName: 'block',
            wrapperClass: "block-wrapper",
            headerClass: 'block-header'
          }}
          width={6}
          className={cn("insight-hours-list", "full-width")}
          showSlider={showSlider}
          title={tr("Hourly Profitability vs. Sales Quote Gross Profit")}
          rowType={HourlyProfitabilityCell}
          summaryRowType={HourlyProfitabilitySummaryRow}
          data={data}
          columns={columns}
          currency={currency}
        />
    );
}

export default HourlyProfitability;