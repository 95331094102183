import React from 'react';
import './Integration.css';
import './Maventa.css';
import './HR.css';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Delete from '@mui/icons-material/Delete';
import TaimerComponent from "../../TaimerComponent";
import DataHandler from '../../general/DataHandler';
import OutlinedField from '../../general/OutlinedField';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import { SettingsContext } from '../../SettingsContext';
import SettingsGrid from '../pages/SettingsGrid';
import DataList from '../../general/DataList';
import List from "../../list/List";
import SettingRow from "../../list/rows/SettingRow";
import ListCell from "../../list/ListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import _ from "lodash";


class ProjectRow extends SettingRow {

    setFieldData = (name, value) => {
        this.editAndSave(name, value);
        this.setState({data: {...this.state.data, [name]: value}})        
    }

    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <Delete />
            </ListCell>,
            code: <TextInputCell
                width={this.props.columnWidthMap['code']}
                name="code"
                value={this.state.data.code}
                onEdited={this.setFieldData}
            />,
            // company: <AutoCompleteCell
            //     listCellProps={{
            //         className: "project-cell"
            //     }}
            //     editable={true}
            //     autoCompleteData={this.props.rowProps.companies}
            //     name="company"
            //     width={this.props.columnWidthMap['company']}
            //     value={this.state.data.companies_id}
            //     onEdited={this.editAndSave}
            // />,            
            paid: 
                <ListCell className="" width={this.props.columnWidthMap["paid"]} onlyDisplay>    
                    <Switch
                        color="primary"
                        checked={this.state.data.paid == 1}
                        onChange={event => {
                            this.setFieldData("paid", event.target.checked ? 1 : 0);
                        }} />
                </ListCell>,
            project: <AutoCompleteCell
                listCellProps={{
                    className: "project-cell"
                }}
                editable={true}
                autoCompleteData={this.props.sharedData.projects}
                hideOptions={(options) => options?.filter(p => !this.props.rowProps.usedProjects.includes(p.id))}
                name="projects"
                width={this.props.columnWidthMap['project']}
                value={this.props.sharedData.projects?.find(f => f.id == this.state.data.projects_id)}
                onEdited={(obj) => {
                    this.setFieldData('projects_id', obj.id);
                }}
            />,            
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class HR extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Heeros");

        this.state = {
            activated: 0,
            username: "",
            password: "",
            send_invitations: true,
            default_permission_group: 0,
            default_worktype: 0,
            permissionGroups: [],
            workhourProjects: [],
            workTypes: [],
        };

        this.dialogs = {
            confirmation: ConfirmationDialog
        };

        this.integration = "hr";

        this.workhourProjectList = React.createRef();

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };     
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getPermissionGroups();
        this.getAuthData();
        this.getHRSettings();
        this.getProjects();
        this.getWorkhourProjects();
        this.getWorktypes();
    }

    getAuthData = () => {
        DataHandler.get({url: `integrations`, company_id: this.props.company, integration: this.integration}).done(response => {
            this.setState(response);
        });
    }

    getHRSettings = () => {
        DataHandler.get({url: `settings/hr`, company_id: this.props.company}).done(response => {
            console.log(response);
            this.setState(response);
        });
    }

    getPermissionGroups = () => {
        DataHandler.get({url: `subjects/user_groups/${this.props.company}`, companyId: this.props.company, type: 'userpermissiongroup'}).done(response => {
            this.setState({permissionGroups: response});
        });
    }

    getProjects = async () => {
        const projects = await DataHandler.get({ url: 'projects/dropdown_new', onlyVacations: true, company: this.props.company});
        this.setState({projects});
    }

    getWorkhourProjects = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/workhour_projects/2`}).done((workhourProjects) => {
            this.setState({workhourProjects});
        });
    }

    getWorktypes = () => {
        DataHandler.get({url: `dialogs/worktypes`, company: this.props.company}).done((response) => {
            this.setState({workTypes: response});
        });
    }

    onRowChange = (data) => {
        if (data.id > 0)
            DataHandler.put({url: `settings/company/${this.props.company}/workhour_projects`}, data).done((resp) => {
                this.getWorkhourProjects();
            });
        else
            DataHandler.post({url: `settings/company/${this.props.company}/workhour_projects/2`}, data).done((resp) => {
                this.getWorkhourProjects();
            });
    };

    saveAuth = () => {
        const data = {
            username: this.state.username,
            password: this.state.password,
            company_id: this.props.company,
            integration: this.integration,
        }

        DataHandler.put({url: `integrations`}, data).done(response => {
            this.props.enqueueSnackbar(this.tr("HR authentication information saved"), {
                variant: "success",
            });	
            this.setState({activated: 1});
        });
    }

    back = () => {
        const {functions: {updateView}} = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        this.setState({
            dialogData: {
                saveFunc: () => DataHandler.delete({url: `integrations`, company_id: this.props.company, integration: this.integration}).done(() => {
                    this.props.enqueueSnackbar(this.tr("HR authentication deactivated"), {
                        variant: "success"
                    });	
                    this.back();
                }),
                text: this.tr("Do you want to deactivate HR integration?")
            }
        }, () => this.openDialog('confirmation'));
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    onCheckChange = (update, name, value) => {
        this.setState({[name]: value}, () =>  DataHandler.put({url: `settings/hr`}, {company_id: this.props.company, name: name, value: value}));
    }

    changePermissionGroup = (id) => {
        this.setState({default_permission_group: id}, () => DataHandler.put({url: `settings/hr`}, {company_id: this.props.company, name: 'permission_group', value: id}));
    }

    changeWorktype = (id) => {
        this.setState({default_worktype: id}, () => DataHandler.put({url: `settings/hr`}, {company_id: this.props.company, name: 'default_worktype', value: id}));
    }

    render(){
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        const settings = {send_invitations: this.state.send_invitations};

        const usedProjects = this.state.workhourProjects.map(w => w.projects_id);

        return (
            <div id="integration-wrapper" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{this.tr("HR integration settings")}</h3>
                    {this.state.activated == 1 && <Button className="red deactivate" size="large" onClick={this.deactivate}>{this.tr("Deactivate")}</Button>}
                </div>

                <div className="settings smaller-margin">
                    <h4>{this.tr("Authentication information")}</h4>
                    <OutlinedField value={this.state.username} onChange={(evt) => this.setState({username: evt.target.value})} label={this.tr("Username")} />
                    <OutlinedField value={this.state.password} onChange={(evt) => this.setState({password: evt.target.value})} label={this.tr("Password")} type="password" autoComplete="new-password" />
                    <Button className="auth-button" color="primary" onClick={this.saveAuth} size="large">{this.tr("Save")}</Button>
                </div>

                <div className="settings">
                    <h4>{this.tr("Default permission group")}</h4>
                    <p className='subtitle'>{this.tr("Default for new users added to PSA via the HR-integration")}</p>
                    <div className='datalist-group-wrapper'>
                        <DataList
                            label={this.tr("Permission group")}
                            name={"default_permission_group"}
                            value={this.state.permissionGroups.find((g) => g.id == this.state.default_permission_group)}
                            options={this.state.permissionGroups}
                            onChange={(e) => this.changePermissionGroup(e.id)}
                            shownCount={20}
                        />
                    </div>
                </div>

                <div id="settings-maventa" className="main-integration-wrapper">
                    <h4>{this.tr("Invitation emails")}</h4>
                    <p className='subtitle'>{this.tr("Send invitation email to users when they are added to PSA via the HR-integration")}</p>
                    <SettingsGrid item={settings} onChange={this.onCheckChange} settings={[{
                        type: "check",
                        name: "send_invitations",
                        label: this.tr("Send invitation emails"),
                        className: "respSetting third"
                    }]} />
                </div>

                <div className="settings">                  
                    <h4>{this.tr("Vacation and leave projects")}</h4>
                    <Button className="green" onMouseUp={() => this.workhourProjectList.current.addNewRow()} size="large">{this.tr("Add status code")}</Button>
                    <br />
                    <List
                        ref={this.workhourProjectList}
                        id="workhourProjectList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            // companies_id: this.props.company,
                            projects_id: 0,
                            code: '',
                            paid: 0
                        }}
                        listRowType={ProjectRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            // { width: 200, name: "company", header: tr("Company name"), ...this.dColConf },
                            { width: 500, name: "project", header: this.tr("Project"), ...this.dColConf },
                            { width: 100, name: "code", header: this.tr("Status code"), ...this.dColConf },
                            // { width: 50, name: "paid", header: this("Paid leave"), ...this.dColConf },
                        ]}
                        sharedData={{projects: this.state.projects}}
                        rowProps={{
                            usedProjects: usedProjects,
                            onCreate: this.onRowChange,
                            onUpdate: this.onRowChange,
                            onDelete: row => {
                                if(row.id > 0) {
                                    DataHandler.delete({url: `settings/company/${this.props.company}/workhour_projects`, id: row.id}).done(response => {
                                        this.getWorkhourProjects();
                                    });
                                } else {
                                    let workhourProjects = _.cloneDeep(this.state.workhourProjects);
                                    workhourProjects = workhourProjects.filter(wp => wp.id != row.id);
                                    this.setState({workhourProjects});
                                }

                            }
                        }}
                        data={this.state.workhourProjects}
                    />
                </div>

                <div className="settings">
                    <h4>{this.tr("Vacation and leave worktype")}</h4>
                    <p className='subtitle'>{this.tr("Default for hour entries added to PSA via the HR-integration")}</p>
                    <div className='datalist-group-wrapper'>
                        <DataList
                            label={this.tr("Worktype")}
                            name={"default_worktype"}
                            value={this.state.workTypes.find((g) => g.id == this.state.default_worktype)}
                            options={this.state.workTypes}
                            onChange={(e) => this.changeWorktype(e.id)}
                            shownCount={20}
                        />
                    </div>
                </div>

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
            </div>
        )
    }
}

export default HR;