import TrashCanIcon from "@mui/icons-material/Delete";
import ListCell from "../../list/ListCell";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import TextInputCell from "../../list/cells/TextInputCell";
import SettingRow from "../../list/rows/SettingRow";

export default class TalenomCostPoolRow extends SettingRow {
	/* costPoolOnChanged(obj) {
		let newState = this.state.data;
		newState.name = obj.name;
		newState.branchofbusiness = obj.id;
		this.setState({ data: newState });
		this.editAndSave();
	} */

	/**
	 * TalenomCostPoolRow renderer
	 *
	 */
	render() {
		const bob = this.props.rowProps.branchofbusinesses.find(b => b.id == this.state.data['branchofbusiness_id']);
		const usedProjectCategories = this.props.rowProps.currentRows?.map(d => d.branchofbusiness_id);

		const cells = {
			delete: (
				<ListCell
					className='noBg pointer'
					permanentEditMode={true}
					onClick={() => this.delete(this.state.data)}
					width={this.props.columnWidthMap['delete']}
				>
					<TrashCanIcon />
				</ListCell>
            ),
            name: (
				<TextInputCell
					width={this.props.columnWidthMap["name"]}
					name="name"
					value={this.state.data["name"]}
					onEdited={this.editAndSave}
				/>
			),
			dimension: (
				<TextInputCell
					width={this.props.columnWidthMap['dimension']}
					name='dimension'
					value={this.state.data['dimension']}
					onEdited={this.editAndSave}
				/>
			),
			branchofbusiness_id: (
				<AutoCompleteCell
					listCellProps={{
						className: "branchofbusiness_id",
					}}
					editable={true}
					autoCompleteData={this.props.rowProps.branchofbusinesses}
					name="branchofbusiness_id"
					width={this.props.columnWidthMap["branchofbusiness_id"]}
					value={{label: bob?.name, value: bob?.id}}
					hideOptions={(options) => options?.filter(p => !usedProjectCategories.includes(p.id))}
					onEdited={data => {
                        const newdata = this.state.data;
                        newdata.branchofbusiness_id = data.id;
                        this.editAndSave(newdata);
                    }}
				/>
			),
		};

		return (
			<div
				className='listElement row flex'
				style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
			>
				{this.props.columnOrder.map(columnName => cells[columnName])}
			</div>
		);
	}
}