import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import './Onboarding.css'
import OrderForm from './OrderForm';

/* context */
import { SettingsContext } from '../SettingsContext';

class OrderView extends Component {
    static contextType = SettingsContext;

    static defaultProps = {
        selectedPlan: 2
    };    

    constructor(props, context) {
        super(props, context);
    }

    render() {
        const { userObject, functions: { getEnv } } = this.context;
        const STRIPE_PUBLIC_KEY = getEnv('REACT_APP_STRIPE_PUBLIC') 
        const versionId = this.context.versionId ? this.context.versionId : this.props.selectedPlan;

        return (
            <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
                <Elements locale={userObject.language}>
                    <OrderForm subId={this.props.subId} toggleBuyDialog={this.props.toggleBuyDialog} selectedPlan={versionId}/>
                </Elements>
            </StripeProvider>
        );
    }
    
}

export default OrderView;