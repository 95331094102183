const dashboardTabs = [
    {
        id: 'my-day',
        label: 'My Day',
        target: {
            module: 'dashboard',
            action: 'main',
            selectedTab: 'my-day',
        },
    },
    {
        id: 'profit-loss',
        label: 'Profit & Loss',
        target: {
            module: 'dashboard',
            action: 'main',
            selectedTab: 'profit-loss',
        },
    },
    {
        id: 'hours-overview',
        label: 'Hours',
        target: {
            module: 'dashboard',
            action: 'main',
            selectedTab: 'hours-overview',
        },
    },
    {
        id: 'invoicing-overview',
        label: 'Invoicing',
    },
    {
        id: 'leads-overview',
        label: 'Leads',
    },
    {
        id: 'deals-overview',
        label: 'Deals',
    },
    {
        id: 'activities-overview',
        label: 'Activities',
    },
    {
        id: 'goals-overview',
        label: 'Goals',
    },
];

const timeManagementTabs = [
    {
        id: 'time-tracker',
        target: {
            module: 'timemanagement',
            action: 'main',
            selectedTab: 'time-tracker',
        },
        label: 'Time Tracker',
        addonkey: 'timetracker',
        items: [
            {
                id: 'calendarView',
                label: 'Calendar View',
            },
            {
                id: 'bulkEntry',
                label: 'Bulk Entry',
            },
            {
                id: 'myHours',
                label: 'My Hours Listed',
            },
        ],
    },
    {
        id: 'calendar',
        label: 'Calendar',
        target: {
            module: 'timemanagement',
            action: 'main',
            selectedTab: 'calendar',
        },
    },
];

const projectTabs = [
    {
        id: '0',
        label: 'All',
        target: {
            module: 'projects',
            action: 'list',
            view: 'list',
            pipeline_id: 0,
        },
    },
    {
        id: 'pipeline',
        label: 'Pipelines',
        items: [], // pipelines
        target: {
            module: 'projects',
            action: 'list',
            view: 'kanban',
            mode: 'pipeline',
        },
    },
    {
        id: '-1',
        label: 'Won deals',
        target: {
            module: 'projects',
            action: 'list',
            view: 'list',
            pipeline_id: '-1',
        },
    },
    {
        id: '-5',
        label: 'Internal projects',
        target: {
            module: 'projects',
            action: 'list',
            view: 'list',
            pipeline_id: '-5',
        },
    },
];

const projectCardTabs = [
    {
        id: 'home',
        label: 'Home',
        items: [
            {
                id: 'overview',
                label: 'Overview',
            },
            {
                id: 'activities',
                label: 'Activities',
            },
            {
                id: 'memo',
                label: 'Memo',
            },
            {
                id: 'attachments',
                label: 'Attachments',
            },
            {
                id: 'forms',
                label: 'Forms',
            },
            {
                id: 'email',
                label: 'Email',
            },
            {
                id: 'boards',
                label: 'Boards',
            },
        ],
    },
    {
        id: 'sales',
        label: 'Sales',
        items: [
            { id: 'quote', label: 'Quote' },
            { id: 'revenueRecognition', label: 'Revenue Recognition' },
        ],
    },
    {
        id: 'resourcing',
        label: 'Resourcing',
        items: [
            {
                id: 'resourcing_grid',
                label: 'Grid',
            },
            {
                id: 'resourcing_gantt',
                label: 'Gantt',
            },
            {
                id: 'resourcing_list',
                label: 'List',
            },
            {
                id: 'resourcing_usage',
                label: 'Utilisation',
            },
        ],
    },
    {
        id: 'finances',
        label: 'Finances',
        items: [
            {
                id: 'financial_overview',
                label: 'Financial Overview',
            },
            {
                id: 'finances_1',
                label: 'Scheduled Invoicing',
            },
            {
                id: 'finances_2',
                label: 'Automatic Invoicing',
            },
            {
                id: 'finances_3',
                label: 'Actual Costs',
            },
            {
                id: 'invoicing',
                label: 'Invoicing',
            },
            {
                id: 'bills',
                label: 'Bills',
            },
            {
                id: 'po',
                label: 'Purchase Orders',
            },
            {
                id: 'expenses',
                label: 'Expenses',
            },
            {
                id: 'travelExpenses',
                label: 'Travel Expenses',
            },
        ],
    },
    {
        id: 'reports',
        label: 'Reports',
        items: [
            {
                id: 'hour_reports',
                label: 'Hours',
                items: [
                    {
                        id: 'employees_report',
                        label: 'Employees',
                        newTab: true,
                    },
                    {
                        id: 'employees_daily',
                        label: 'Employees Daily',
                        newTab: true,
                    },
                    {
                        id: 'hours_report',
                        label: 'Project Hours Report',
                        onClick: 'openHoursReportSlider',
                    },
                ],
            },
            {
                id: 'invoicing_reports',
                label: 'Invoicing',
                items: [
                    {
                        id: 'invoicing_vs_subcontracting',
                        label: 'Costs Period',
                        newTab: true,
                    },
                    {
                        id: 'invoicing_vs_subcontracting_bills',
                        label: 'Invoicing Period',
                        newTab: true,
                    },
                ],
            },
            {
                id: 'recognition_reports',
                label: 'Recognition',
                items: [
                    {
                        id: 'revenue_recognition_by_account',
                        label: 'Revenue Recognition by Account',
                        newTab: true,
                    },
                    {
                        id: 'revenue_recognition_by_user',
                        label: 'Revenue Recognition by User',
                        newTab: true,
                    },
                    {
                        id: 'revenue_recognition_by_user_and_project',
                        label: 'Revenue Recognition by User and Project',
                        newTab: true,
                    },
                ],
            },
            {
                id: 'bi-reports',
                label: 'BI Reports',
                items: [
                    {
                        id: 'profit_loss',
                        label: 'Profit & Loss',
                    },
                    {
                        id: 'invoicing_insight',
                        label: 'Invoicing Overview',
                    },
                    {
                        id: 'hours_insight',
                        label: 'Hours Overview',
                    },
                ],
            },
        ],
    },
];

// 1 piilossa
const version = 1;

const content = {
    topbar: ['add', 'separator', 'notes', 'timer', 'teamchat', 'separator', 'notifications', 'dev', 'settings', 'separator'],
    sidebar: [
        {
            id: 'dashboards',
            icon: 'dashboard',
            label: 'Dashboards',
            subitems: dashboardTabs,
        },
        {
            id: 'timemanagement',
            label: 'Time Management',
            icon: 'timemanagement',
            subitems: timeManagementTabs,
        },
        {
            id: 'projectmanagement',
            icon: 'projects',
            label: 'Projects',
            optional: ['action', 'view'],
            addonkey: 'projects',
            target: {
                module: 'projects',
                action: 'list',
                view: 'list',
                pipeline_id: version == 1 ? -1 : 0,
            },
            subitems: version == 1 ? undefined : projectTabs,
        },
    ],
    views: {
        'navigation/pages/TimeManagement': {
            tabs: timeManagementTabs,
        },
        'navigation/pages/Dashboard': {
            tabs: dashboardTabs,
        },
        'list/lists/ProjectList': {
            tabs: version == 1 ? undefined : projectTabs,
            viewModes:
                version == 1
                    ? undefined
                    : [
                          { key: 'list', label: 'List' },
                          { key: 'kanban', label: 'Kanban' },
                      ],
        },
        'projects/ProjectView': {
            tabs: projectCardTabs,
        },
    },
    features: {
        'list/lists/ProjectList': {
            extraFields: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            subprojects: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            pipelines: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            enterpriseGroups: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            reportingGroups: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            accountGroups: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            accountTypes: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            grossMargin: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            grossProfit: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            backlog: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            resourcing: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            teamGroups: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            bills: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            automaticInvoicing: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
        },
        'projects/ProjectView': {
            extraFields: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            subprojects: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            pipelines: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            specialPermissions: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
        },
        'projects/TabBasic': {
            pipelines: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            bills: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            invoicingStatus: {
                disabled: version == 1 ? true : false,
            },
            invoiceRowGrouping: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            massInvoicing: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            hourlyRates: {
                visibility: version == 1 ? 'upgrade' : 'visible',
            },
            hourTrackingSettings: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
        },
        'general/AddProjectSlider': {
            pipelines: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
        },
        'settings/Settings': {
            pipelines: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
        },
        'settings/pages/Project': {
            bills: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
            massInvoicing: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
        },
        'dashboard/my_day/components/ProjectsOverview': {
            pipelines: {
                visibility: version == 1 ? 'upgrade' : 'visible',
            },
        },
        'dashboard/my_day/components/ProjectsList': {
            pipelines: {
                visibility: version == 1 ? 'hidden' : 'visible',
            },
        },
    },
};

export default content;
