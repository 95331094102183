import React from "react";
import { isEqual } from "lodash";
import { InsightSliderRow } from "../../InsightSlider";
import TextInputCell from "../../../../list/cells/TextInputCell";
import LinkListCell from "../../../../list/LinkListCell";
import DateCell from "../../../../list/cells/DateCell";
import EditableStatusCell from "../../../../list/cells/EditableStatusCell";
import colors from "../../../../colors";

export default class GoalVsActualSliderRow extends InsightSliderRow {
  shouldComponentUpdate = (newProps, newState) => {
    return (
      super.shouldComponentUpdate(newProps, newState) ||
      !isEqual(newProps.columnOrder, this.props.columnOrder)
    );
  };

  defineCells() {
    const { data, columnOrder, sharedData } = this.props;
    const {
      functions: { presentCurrency },
    } = this.context;
    const cells = super.defineCells();
    columnOrder.forEach((c) => {
      switch (c) {
        case "number":
          cells[c] = (
            <TextInputCell onlyDisplay editable={false} value={data.id || 0} />
          );
          break;
        case "projects":
          cells[c] = (
            <LinkListCell
              handleClick={() => sharedData.setProjectMode(data)}
              // onlyDisplay
              editable={false}
              noTab={true}
              asText={(data.projects || []).length == 0}
              value={this.tr("${length} projects", {
                length: `${(data.projects || []).length}`,
              })}
            />
          );
          break;
        case "status":
          cells[c] = (
            <EditableStatusCell
              editable={false}
              className={"goalsVsActualProjectStatus"}
              options={[
                {
                  id: -1,
                  name: this.tr("Active"),
                  color: colors.greenish_cyan,
                },
                {
                  id: 1,
                  name: this.tr("Locked"),
                  color: colors.carnation_pink,
                },
                {
                  id: 2,
                  name: this.tr("On Hold"),
                  color: colors.orangey_yellow,
                },
              ]}
              value={data[c]}
            />
          );
          break;
        case "funnel":
        case "stage":
          cells[c] = (
            <TextInputCell
              onlyDisplay
              editable={false}
              value={data[c] || "-"}
            />
          );
          break;
        case "creation_date":
        case "status_date":
          cells[c] = (
            <DateCell onlyDisplay editable={false} value={data[c] || "-"} />
          );
          break;
        case "account":
        case "project":
          break;
        case "cumulative_difference_ytd":
          {
            const type = Number(data.type_ytd || 0);
            const goalType = Number(data.goal_type_ytd || 0);
            cells[c] = (
              <TextInputCell
                onlyDisplay
                editable={false}
                value={presentCurrency(goalType - type)}
              />
            );
          }
          break;
        default:
          cells[c] = (
            <TextInputCell
              onlyDisplay
              editable={false}
              value={presentCurrency(data[c] || 0)}
            />
          );
          break;
      }
    });
    return cells;
  }
}
