import React from "react";
import TaimerComponent from '../../../../TaimerComponent';
import { Tooltip } from "@mui/material";
import cn from 'classnames';
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import TimeTrackingButton from '../../ResourcingGrid/components/TimeTrackingButton';

export default class GridLeftCell extends TaimerComponent {
	constructor(props, context) {
		super(props, context, "resourcing/views/ResourcingGrid/components/GridLeftCell");
	}

	//   shouldComponentUpdate = oldProps => {
	//     return (
	//       oldProps.selected != this.props.selected ||
	//       oldProps.viewMode != this.props.viewMode ||
	//       (this.props.selected && oldProps.manualValue != this.props.manualValue) ||
	//       oldProps.item != this.props.item ||
	//       oldProps.column != this.props.column ||
	//       oldProps.holidays != this.props.holidays
	//     );
	//   };

	sourcedFromTask = {
		progress: true,
	};

	render() {
		const { functions: { getWorkhourTimers }, userObject: { usersId } } = this.context;
		const { column, item, priorities, hovering } = this.props;
		
		let value = item[column.key];

		if (column.key === 'pipeline_name' && item.display_type === 'project') {
			if (item.project.status == 1) {
				value = this.tr('Won Deals');
			} else if (item.project.status == 5) {
				value = this.tr('Internal');
			} else {
				value = item.project.pipeline_name;
			}
		} else if (column.key === 'priority'  && item.type === 'task') {
			const prior = item.task && priorities[item.task.priority];

			if (prior) {
				const Icon = prior.icon;

				value = <Icon title={prior.name} />
			}
		} else if (column.key === 'progress' && item.type === 'task') {
			value = item.task[column.key]
		} else if (value === undefined) {
		}

		const workhours = getWorkhourTimers();

		let workhourTimerIndex = workhours.findIndex(x =>
			(item.type === "task" && x.wh_projects_resource == item.task?.id) ||
			(item.type === "project" && item.project.projects_id == x.projects_id && !x.wh_projects_resource)
		);
		let workhourTimer = workhours[workhourTimerIndex];

		const lastInRow = false;

		const trackingBtn = usersId == item.users_id && lastInRow && <TimeTrackingButton index={workhourTimerIndex} item={workhourTimer} data={{
			customers_id: item.project.accounts_id,
			projects_id: item.project.projects_id,
			wh_projects_resource: item.task?.id,
		}} />

		return (<div className="grid-table-cell">
			{hovering && this.props.children}
			{!hovering && (column.formatValue
				? column.formatValue(value)
				: value || "-")}
			{(workhourTimer || hovering) && trackingBtn}
		</div>);
	}
}