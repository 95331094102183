import React from 'react';

/* css */
import '../styles.css';
import '../theme/default.css';

/* material-ui */
import CalendarIcon from '@mui/icons-material/DateRange';

/* other */
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import DateRange from './DateRange';
import { format } from 'date-fns';
import classnames from 'classnames';
import coreStyles from '../styles';
import MaskedInput from 'react-text-mask';
import DefinedRange from './DefinedRange';
import TaimerComponent from '../../../../TaimerComponent';
import ClickAwayWrapper from '../../../ClickAwayWrapper';
import { SettingsContext } from './../../../../SettingsContext';
import { getDefaultStaticRanges } from '../defaultRanges';
import { InputLabel, FormControl, Popper, Tooltip } from '@mui/material';
import StyledTooltip from './../../../StyledTooltip';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { findNextRangeIndex, generateStyles } from '../utils.js';
import Calendar from './Calendar';
import { 
    Close, 
    InfoOutlined 
} from '@mui/icons-material';
import { createStaticRanges } from '../defaultRanges';
import { FlexContainer, FlexChild } from '../../../FlexUtils';

export class DateRangePopper extends TaimerComponent {
    static defaultProps = {
        months: 2,
        direction: "horizontal",  
        disabled: false,
        rangeOptions: {
            useFinancialYear: false,
        },
        mode: 'daterange',
        disablePortal: false,
        relativeRange: null,
        relativeRanges: { past: [], future: [] },
        onRelativeRangeSelect: (range) => {}
    };

    constructor(props) {
        super(props, null, 'general/DateRangePopper');

        this.calendar = React.createRef();
        this.state = {
            focusedRange: [findNextRangeIndex(this.props.ranges), 0],
            showAllFinancialYears: false,
            expandPastSelections: false
        };

        this.singleDateSelection = null;
    }

    componentDidUpdate = (oldProps) => {
        if (oldProps.date !== this.props.date) {
            this.calendar.current && this.calendar.current.focusToDate(this.props.date);
        }
    }

    selectRelativeRange = (value) => {
        this.props?.onRelativeRangeSelect(value);

        this.props.handleBlur();
    }

    togglePastRanges = () => {
        this.setState({ 
            expandPastSelections: !this.state.expandPastSelections 
        });
    }

    renderRelativeSelections = () => {
        const { 
            past, 
            future 
        } = this.props.relativeRanges;

        const {
            expandPastSelections
        } = this.state;

        const rangeTitles = [this.tr("Past"), this.tr("Future")];

        return (
            <div className="relativeSelectorsContainer">
                <h3>{this.tr("Relative timespan")}</h3>
                <p>{this.props.relativeTimespanExplainer ?? ""}</p>
                <div className="rdrDefinedRangesWrapper">
                    {[past.slice(0, expandPastSelections ? past.length : 4), future].map((ranges, index) => {
                        return (
                            <FlexContainer className="relativeDateRangeWrapper">
                                <FlexChild weight={1}>
                                    <h4>{rangeTitles[index]}:</h4>
                                </FlexChild>
                                <FlexChild weight={9}>
                                    <div className="rdrStaticRanges" style={{ 
                                        flexWrap: "wrap",
                                        justifyContent: "left"
                                    }}>
                                        {ranges.map(range => {
                                            const className = [
                                                "rdrStaticRange",
                                                range.value === this.props.relativeRange 
                                                    ? "rdrStaticRangeSelected" 
                                                    : null
                                            ].filter(c => c).join(" ");

                                            return (
                                                <button 
                                                    className={className}
                                                    onClick={() => this.selectRelativeRange(range.value)}>
                                                    <span className="rdrStaticRangeLabel rdrStaticRangeLabelLessPadding">
                                                        {range.label}
                                                    </span>
                                                </button>
                                            );
                                        })}
                                        {index === 0 && (
                                            <>
                                                <span className="rdrStaticRange">
                                                    <span className="rdrStaticRangeLabel rdrStaticRangeLabelLessPadding">|</span>
                                                </span>
                                                <button 
                                                    style={{ textDecoration: "none" }} 
                                                    className="rdrStaticRange" 
                                                    onClick={this.togglePastRanges}>
                                                    <span className="rdrStaticRangeLabel rdrStaticRangeLabelLessPadding expandRangeButton">
                                                        {!expandPastSelections ? this.tr("More") : this.tr("Less")}
                                                    </span>
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </FlexChild>
                            </FlexContainer>
                        );
                    })}
                </div> 
            </div>
        );
    }

  render() {
      const { focusedRange, showAllFinancialYears } = this.state;
      const { rangeOptions, additionalBottomComponent, customDateRanges = [], disablePortal, date  } = this.props;

      const dateOptions = {
          weekStartsOn: this.context.calendar.startOfWeek,
      };
      const staticRanges = [...getDefaultStaticRanges(dateOptions, rangeOptions).map((e) => {
          e.label = this.tr(e.label);
          return e;
      }), ...(customDateRanges ? createStaticRanges(customDateRanges) : [])];
      const financialYears = showAllFinancialYears ? this.props.financialYears : (this.props.financialYears || []).slice(0, 3);
      return (
          <Popper 
              disablePortal={disablePortal} 
              open={this.props.open} 
              keepMounted 
              placement="bottom-start" 
              anchorEl={this.props.anchorEl}>
              <ClickAwayWrapper 
                  mouseEvent="onMouseDown" 
                  onClickAway={() => {
                      this.props.handleBlur();

                      if(this.singleDateSelection === null) {
                        return;
                      }

                      this.props?.onChange?.({
                        singleDate: true,
                        selection: {
                            startDate: this.singleDateSelection,
                            endDate: this.singleDateSelection,
                        }
                      });
                  }} 
                  active={this.props.open}>
                  <div className={`dateRangePickerPopperCalendarView ${this.props.className} ${this.props.dateRangePickerPopperCalendarViewClassName} ${(this.props.open && !this.props.disabled ? '' : ' hidden')}`}>
                      {this.props.mode == 'date' ? (
                          <Calendar
                              date={(this.props.date)}
                              shownDate={(this.props.date)}
                              ref={this.calendar}
                              onChange={(e) => {
                                  this.props.onChange(e);
                                  this.props.handleBlur();
                              }}
                              locale={this.context.calendar.locale}
                              showDateDisplay={false}
                          />
                      ) : (
                          <>
                              {!this.props.showRelative && <DefinedRange
                                  staticRanges={staticRanges}
                                  focusedRange={focusedRange}
                                  onPreviewChange={(value) => this.dateRange.updatePreview(value)}
                                  {...this.props}
                                  range={this.props.ranges[focusedRange[0]]}
                                  className={undefined}
                                  onRangeSelected={this.props.handleBlur}
                              />}
                              {this.props.financialYears && (
                                  <div className="financial-years">
                                      <p data-testid="daterangepopper_financial_years">{this.tr('Financial years')}</p>
                                      <div className="btns">
                                          {financialYears.map((fy) => (
                                              <button
                                                  data-testid="daterangepopper_financial_years_range_button"
                                                  onClick={() => {
                                                      const range = { startDate: fy.start, endDate: fy.end };
                                                      this.props.onChange({ selection: range });
                                                      this.dateRange.updatePreview(range);
                                                      this.props.handleBlur();
                                                  }}
                                              >
                                                  {fy.label}
                                              </button>
                                          ))}
                                          {this.props.financialYears.length > 3 && (
                                              <button onClick={() => this.setState({ showAllFinancialYears: !this.state.showAllFinancialYears })} className="show-more">
                                                  {showAllFinancialYears ? `- ${this.tr('Show less')}` : `+ ${this.tr('Show more')}`}
                                              </button>
                                          )}
                                      </div>
                                  </div>
                              )}
                              <DateRange
                                  onRangeFocusChange={(focusedRange) => this.setState({ focusedRange })}
                                  focusedRange={focusedRange}
                                  {...this.props}
                                  onChange={event => {
                                    const keys = Object.keys(event);

                                    if(keys.length === 0) {
                                        return;
                                    }

                                    const key = keys[0];

                                    const {
                                        startDate,
                                        endDate
                                    } = event?.[key];

                                    if(!startDate && !endDate) {
                                        return;
                                    }

                                    this.props?.onChange?.(event);

                                    this.singleDateSelection = null;

                                    if(startDate === endDate) {
                                        this.singleDateSelection = startDate;

                                        return;
                                    }

                                    this.props.handleBlur();
                                  }}
                                  ref={(t) => (this.dateRange = t)}
                                  className={undefined}
                                  onRangeSelected={this.props.handleBlur}
                              />
                              {this.props.showRelative && (this.renderRelativeSelections())}
                          </>
                      )}
                      {additionalBottomComponent}
                  </div>
              </ClickAwayWrapper>
          </Popper>
      );
  }
}

class DateRangePicker extends TaimerComponent {
  static contextType = SettingsContext;

  static defaultProps = {
    rangeOptions: {
      useFinancialYear: false,
    },
    dateSeparatorClassName: "",
    inputWrapperClassName: "",
    allowClear: false,
    showAbsolute: true,
    showRelative: false,
    relativeRange: null,
    infoTooltipContent: null,
  }

  constructor(props) {
      super(props, null, 'general/dateRangePicker');

      this.state = {
          focusedRange: [findNextRangeIndex(props.ranges), 0],
          open: false,
          showAllFinancialYears: false,
      };

      this.inputContainer = React.createRef();
      this.firstInput = React.createRef();
      this.secondInput = React.createRef();
      this.styles = generateStyles([coreStyles, props.classNames]);

      this.PAST_RELATIVE_RANGES = [
          {
              label: this.tr("Year to date"),
              value: "ytd"
          },
          {
              label: this.tr("Last 6 months"),
              value: "-6m_f"
          },
          {
              label: this.tr("Last quarter"),
              value: "-1q_f"
          },
          {
              label: this.tr("Last year"),
              value: "-1y_f"
          },
          {
              label: this.tr("Last Month"),
              value: "-1m_f"
          },
          {
              label: this.tr("Last Week"),
              value: "-1w_f"

          },
          {
              label: this.tr("Last 12 months"),
              value: "-12m_f"
          },
          {
              label: this.tr("This year"),
              value: "0y_f"
          },
          {
              label: this.tr("This quarter"),
              value: "0q_f"
          },
          {
              label: this.tr("This Month"),
              value: "0m_f"
          },
          {
              label: this.tr("This Week"),
              value: "0w_f"
          },
          {
              label: this.tr("Today"),
              value: "0d"
          },
          {
              label: this.tr("Yesterday"),
              value: "-1d_f"
          },
      ];

      this.FUTURE_RELATIVE_RANGES = [
          {
              label: this.tr("Tomorrow"),
              value: "+1d_f"
          },
          {
              label: this.tr("Next week"),
              value: "+1w_f"
          },
          {
              label: this.tr("Next Month"),
              value: "+1m_f"
          },
          {
              label: this.tr("Next 6 months"),
              value: "+6m_f"
          },
          {
              label: this.tr("Next 12 months"),
              value: "+12m_f"
          },
          {
              label: this.tr("Next year"),
              value: "+1y_f"
          },
      ];


      const regExp = new RegExp("([a-zA-Z])");

      this.props.dateFormat.split("").map(char => {
          if(regExp.exec(char) === null) {
              this.delimeter = char;
          }
          return null;
      });

      this.formatArray = this.props.dateFormat.split(this.delimeter).map(el => el.toLowerCase());
      this.handleBlur = this.handleBlur.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
      return true;
  }

  onDateChange(dateType, event) {
    if(event.target.value.indexOf("_") == -1 && (event.target.value != "" || this.props.allowClear)) {
      let date = undefined;

      if (event.target.value !== "") {
        const dateParts = event.target.value.split(this.delimeter);
        date = new Date(dateParts[this.formatArray.indexOf('yyyy')], dateParts[this.formatArray.indexOf('mm')]-1, dateParts[this.formatArray.indexOf('dd')]);
      }

      const range = this.props.onInputChange(dateType, date);
      if(this.state.open) {
        this.dateRange && this.dateRange.updatePreview(range)
        this.handleBlur();
      }
    }
  }

  handleToggle = () => {
    this.setState({open: !this.state.open});
  }

  handleFocus = () => {
    this.setState({open: true});
  }

  handleBlur() {
    if(!this.state.open)
      return;

    this.firstInput.current && this.firstInput.current.inputElement.blur();
    this.secondInput.current && this.secondInput.current.inputElement.blur();

    this.setState({
        open: false
    }, () => {
        this.props.onClose && this.props.onClose(); 
    });
  }

  handleChange(date) {
    this.props.onChange(date);
    this.handleBlur();
  }

  handleRelativeRangeSelect = (range) => {
    this.props.onChange({
        selection: {
            startDate: null,
            endDate: null,
            relative: range
        }
    });

    this.handleBlur();
  }

  clearSelection = () => {
      this.props.onChange({selection: { startDate: undefined, endDate: undefined }});
      this.handleBlur();
  }

  determineRelativePlaceholder = () => {
    const { relativeRange } = this.props;

    const selectedRange = [this.PAST_RELATIVE_RANGES, this.FUTURE_RELATIVE_RANGES]
        .flat()
        .find(range => range.value === relativeRange);

    return selectedRange?.label;
  }

    relativeRangeInUse = () => {
      const {
        startDate, 
        endDate
      } = this.props.ranges[0] ?? {};

      return this.props.showRelative 
        && Boolean(this.props.relativeRange)
        && (!startDate && !endDate);
    }

  render() {
    const { 
        className, 
        datePickerInputClassName,
        disabled, 
        dateFormat, 
        showClearButton, 
        hideCalendarIcon,
        placeholder,
        relativeRange,
        dateSeparatorClassName,
        inputWrapperClassName,
        infoTooltipContent,
        noSelectionPlaceholder
    } = this.props;

    const autoCorrectedDatePipe = createAutoCorrectedDatePipe(this.props.dateFormat.toLowerCase());
    const finalPlaceholder = relativeRange 
        ? this.determineRelativePlaceholder() 
        : placeholder;

    const regExp = new RegExp("([a-zA-Z])");
    const mask = dateFormat.split("").map(char => {
      if(regExp.exec(char) === null)
        return char;

      return /\d/;
    });

    const {startDate, endDate} = this.props.ranges[0] ?? {};

    const noSelection            = !relativeRange && !endDate && !startDate;
    const showTextOverlay        = this.relativeRangeInUse() || (noSelection && noSelectionPlaceholder !== null);
    const clearButtonWillBeShown = showClearButton && ((startDate && endDate) || this.relativeRangeInUse()) 

    return (
      <div ref={this.inputContainer} className={classnames(this.styles.dateRangePickerWrapper, `daterange ${className}`, disabled && "disabled", this.props.error && 'error')}>
        <div>
        {this.props.mode != "list" ? (<FormControl  className={"formControl"} variant="filled">
          <InputLabel
            ref={ref => {
              this.labelRef = ReactDOM.findDOMNode(ref);
            }}
            shrink={true}>
            {this.props.label}
          </InputLabel>
          <div data-testid="daterange_inputWrapper" className={`inputWrapper ${inputWrapperClassName}`}>
          {showTextOverlay && (
              <div 
                  className={`dateRangeTextOverlay ${this.props.disabled ? "disabled" : ""}`}
                  onClick={this.handleFocus}
                  // Move to styles and .. Do something.
                  style={{
                    height: "12px",
                    lineHeight: "12px",
                    top: "14px",
                    left: "-2px",
                  }}>
                  {this.determineRelativePlaceholder() ?? noSelectionPlaceholder}
              </div>
          )}
          {!showTextOverlay && <MaskedInput
              ref={this.firstInput}
              disabled={this.props.disabled}
              mask={mask}
              className={`datePickerInput first ${datePickerInputClassName ?? ""}`}
              keepCharPositions={true}
              guide={true}
              pipe={autoCorrectedDatePipe}
              value={format(startDate, this.props.dateFormat.toUpperCase())}
              onChange={(e) => this.onDateChange("start", e)}
              onFocus={(e) => this.handleFocus(e)} 
              data-testid={"daterangepicker_start_" + this.props.label}
              placeholder={finalPlaceholder} />}
            {!showTextOverlay && !startDate < 1 && !endDate < 1 && <span className={`date-separator ${dateSeparatorClassName}`}>-</span>}
            {!showTextOverlay && <MaskedInput
              ref={this.secondInput}
              disabled={this.props.disabled}
              mask={mask}
              className={`datePickerInput second ${datePickerInputClassName ?? ""}`}
              keepCharPositions={true}
              guide={true}
              pipe={autoCorrectedDatePipe}
              value={format(endDate, this.props.dateFormat.toUpperCase())}
              onChange={(e) => this.onDateChange("end", e)}
              onFocus={(e) => this.handleFocus(e)}
              data-testid={"daterangepicker_end_" + this.props.label} />}
              <>
                  {typeof(infoTooltipContent) === "string" && (
                      <StyledTooltip 
                          content={infoTooltipContent}
                          placement={"bottom-end"}>
                          <InfoOutlined 
                              className="dateRangeTooltipInfoIcon" />
                      </StyledTooltip>
                  )}
                  {
                      showClearButton && (startDate && endDate || this.relativeRangeInUse())
                          ? <Tooltip placement="top" title={this.tr("Clear selection")}><Close onClick={this.clearSelection}/></Tooltip> 
                          : <CalendarIcon
                              alt="Date picker"
                              onClick={(e) => this.handleToggle(e)}
                      />
                  }
              </>
          </div>
        </FormControl>
        ) : (
          <div className="inputWrapper"
            style={{
              marginRight: clearButtonWillBeShown ? 16 : undefined
            }}>
              {showTextOverlay && (
                  <div 
                      className={`dateRangeTextOverlay ${this.props.disabled ? "disabled" : ""}`}
                      onClick={this.handleFocus}>
                      {this.determineRelativePlaceholder() ?? noSelectionPlaceholder}
                  </div>
              )}
              {!showTextOverlay && <>
                  <MaskedInput
                      ref={this.firstInput}
                      disabled={this.props.disabled}
                      mask={mask}
                      className="datePickerInput"
                      keepCharPositions={true}
                      guide={true}
                      pipe={autoCorrectedDatePipe}
                      value={format(startDate, this.props.dateFormat.toUpperCase())}
                      onChange={(e) => this.onDateChange("start", e)}
                      onFocus={(e) => this.handleFocus(e)}
                      placeholder={finalPlaceholder} />
                  <span>{endDate ? "-" : ""}</span>
                  <MaskedInput
                      ref={this.secondInput}
                      disabled={this.props.disabled}
                      mask={mask}
                      className="datePickerInput"
                      keepCharPositions={true}
                      guide={true}
                      pipe={autoCorrectedDatePipe}
                      value={format(endDate, this.props.dateFormat.toUpperCase())}
                      onChange={(e) => this.onDateChange("end", e)}
                      onFocus={(e) => this.handleFocus(e)} />
              </>}
              <>
                  {clearButtonWillBeShown
                      ? <StyledTooltip placement="top" content={this.tr("Clear selection")}><Close onClick={this.clearSelection}/></StyledTooltip> 
                      : !hideCalendarIcon ? <CalendarIcon
                          alt="Date picker"
                          onClick={(e) => this.handleToggle(e)}
                      /> : null}
              </>
          </div>
        )}
        </div>
            <DateRangePopper 
              open={this.state.open} 
              handleBlur={this.handleBlur} 
              anchorEl={this.inputContainer.current} 
              relativeRange={relativeRange}
              relativeRanges={{
                past: this.PAST_RELATIVE_RANGES,
                future: this.FUTURE_RELATIVE_RANGES
              }}
              onRelativeRangeSelect={this.handleRelativeRangeSelect}
              {...this.props} />
        </div>
      );
  }
}

DateRangePicker.defaultProps = {
  months: 2,
  direction: "horizontal",
  dateFormat: "dd/mm/yyyy",
  mode: "input",
  disabled: false,
  noSelectionPlaceholder: null,
  hideCalendarIcon: false,
  dateRangePickerPopperCalendarViewClassName: ""
};

DateRangePicker.propTypes = {
  ...DateRange.propTypes,
  ...DefinedRange.propTypes,
  className: PropTypes.string,
  datePickerInputClassName: PropTypes.string
};

export default DateRangePicker;
