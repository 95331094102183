import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import OnboardingSection from '../dashboard/my_day/OnboardingSection';
import TaimerComponent from '../TaimerComponent';
import styles from './OnboardingSlider.module.scss';
import Slider from './Slider';

interface Props {
    ref?: any;
    open: boolean;
    onClose?: () => void;
}

interface State {
    open: boolean;
    onboardingVisible?: boolean;
}

class OnboardingSlider extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'general/OnboardingSlider');
        this.state = {
            open: false,
            onboardingVisible: this.context.userObject.onboardingStatus != -1,
        };
    }

    onClose = () => {
        this.setState({ open: false }, () => {
            setTimeout(() => {
                this.props.onClose && this.props.onClose();
            }, 500);
        });
    };

    componentDidMount = () => {
        if (this.props.open) {
            this.setState({ open: true });
        }
    };

    onOnboardingVisibilityChanged = (e) => {
        this.setState({ onboardingVisible: e.target.checked }, () => {
            const status = e.target.checked ? 1 : -1;
            this.context.functions.setOnboardingStatus(status);
        });
    };

    render() {
        const { open, onboardingVisible } = this.state;
        const { ref, ...rest } = this.props;

        return (
            <Slider {...rest} title={this.tr('Onboarding')} open={open} onClose={this.onClose}>
                <div id={styles.onboardingSlider}>
                    <div className={styles.content}>
                        <div className={styles.toolbar}>
                            <FormControlLabel control={<Checkbox checked={onboardingVisible} onChange={this.onOnboardingVisibilityChanged} />} label={this.tr('Show onboarding section in My Day')} />
                        </div>
                        <OnboardingSection sliderMode={true} onCardAction={this.onClose} />
                    </div>
                </div>
            </Slider>
        );
    }
}

export default OnboardingSlider;
