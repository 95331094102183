import { getMonth, getQuarter, getYear } from "date-fns";
import { padStart } from "lodash";

export type AllowedGrouping = 'month'|'quarter'|'year';

export function getKeyFromDate(date: Date, grouping: AllowedGrouping) {
    if (grouping === 'month') {
        return `${getYear(date)}-${padStart(String(getMonth(date)), 2, '0')}`;
    }
    else if (grouping === 'quarter') {
        return `${getYear(date)}Q${getQuarter(date)}`;
    } else if (grouping === 'year') {
        return `${getYear(date)}`;
    }

    return 'x';
}