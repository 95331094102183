/* css */
import './ProductDetailsInfo.css';

/* others */
import React from 'react';

import LabelFieldGroup from "./../../general/LabelFieldGroup";
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from './../../SettingsContext';
import Select from "react-select";
import { withSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

class ProductAccounting extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/lists/ProductAccounting");
        this.state = {
           product: props.product
        };

        this.writePrivileges = context.functions.checkPrivilege("products", "write", props.product.companies_id);
    }

    updateDimensionValues = (value, headerId) => {
        const product = cloneDeep(this.state.product);

        let rowDimensionValues = product.dimension_values || [];
        const valueIndex = rowDimensionValues.findIndex(i => i.header_id == headerId);
        if (valueIndex > -1) {
            rowDimensionValues[valueIndex] = { header_id: headerId, dimension_id: value };
        }
        else {
            rowDimensionValues.push({ header_id: headerId, dimension_id: value });
        }
        product.dimension_values = rowDimensionValues;
        
        this.props.saveProduct(product, "updateListFromSlider")

        this.setState(state => {
            state.product = product;
            return state;
        });
    }

    selectionChanged = (e, field) => {
        let product = { ...this.state.product, [field]: e.id }

        if (field == "sales_account" && e.integration == "talenom") {
            this.props.enqueueSnackbar(this.tr("Product VAT % updated from sales account!"), {
                variant: "info",
            });
            product.vat = e.vat_value || 0;
        }

        this.props.saveProduct(product, "updateListFromSlider")

        this.setState(state => {
            state.product = product;
            return state;
        });
    }
    
    render() {
        const  { product } = this.state;
        let {autoCompleteData } = this.props;      

        const dimensionHeaderFields = (autoCompleteData?.dimensionHeaders || []).map(h => {
            const headerDimensionValue = product.dimension_values?.find(v => v.header_id == h.id) || {dimension_id: 0};
            const fieldvalue = (h.options || []).find(o => o.id == headerDimensionValue?.dimension_id);
            return { label: h.label, name: h.name, editable: this.writePrivileges, value: fieldvalue, editorType: Select, options: h.options, onChange: async e => this.updateDimensionValues(e.id, h.id) }
        })

        // Product details tab shows vatcode field. Other accounting fields are shown here.
        const accountingFields = (autoCompleteData?.accountingFields || []).filter(a => a.name != "sales_vatcode").map(f => {
            return { ...f, label: f.header, editable: this.writePrivileges, value: product[f.name], editorType: Select, options: autoCompleteData[f.optionsName], onChange: async e => this.selectionChanged(e, f.name) }
        })

        return (
            <div className="product-details">
                <LabelFieldGroup
                    editMode={true}
                    fields={accountingFields.concat(dimensionHeaderFields)}
                />
            </div>
        );
    }
}

export default withSnackbar(ProductAccounting);
