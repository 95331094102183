import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import TextInputCell from '../../list/cells/TextInputCell';

class Divisions extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/components/Divisions");

        this.state = {
            divisions: [],
        }
        this.nameInput = React.createRef();
        this.newRowAdded = false;
    }

    componentDidMount() {
        super.componentDidMount();

        this.refreshDivisions();
    }

   componentDidUpdate = (prevProps) => {
        if (this.newRowAdded && this.nameInput.current) {
            //this.nameInput.current.listCell.current.openEdit();
            this.newRowAdded = false;
        }
        prevProps.company !== this.props.company && this.refreshDivisions();
    }

    refreshDivisions = () => {
        const company = this.props.company;

        DataHandler.get({ url: `settings/company/${company}/divisions`}).then(divisions => {
            this.setState({ divisions });
        });
    }

    removeDivision(division) {
        const company = this.props.company;
        let divisions = this.state.divisions;
        DataHandler.delete({url: `settings/company/${company}/divisions/${division}`}).then( () => {
            let index = divisions.findIndex(el => el.id == division);
            if(index > -1) {
                divisions.splice(index, 1);
                this.setState({divisions});
            }
        } );
    }

    addNewDivision = () => {
		const company = this.props.company;
        const { divisions } = this.state;

        this.newRowAdded = true;

        let newDivision = {
            name: this.tr("New division"),
            division_id: '0',
            id: divisions.length * -1 - 1,
        };

        DataHandler.post({url: `settings/company/${company}/divisions/`}, newDivision).then( (response) => {
            divisions.splice(0, 0, newDivision);
            newDivision.id = response.id;
            this.setState({ divisions });
        } );
    }

    updateDivision(id, data) {
        const company = this.props.company;
        let divisions = this.state.divisions;
        let index = divisions.findIndex(el => el.id == id);
        if(index > -1) {
            for(let i in data) {
                divisions[index][i] = data[i];
            }
            DataHandler.put({url: `settings/company/${company}/divisions/${id}`}, divisions[index]).then( () => {
                this.setState({divisions});
            } );
        }
        
    }

    render() {
        const { users } = this.props;
        const { divisions } = this.state;
        const { tr } = this;


        return <div id="teamgroup-settings">
            <h3>{tr("Divisions")}</h3>

            <Button className="green btn add" size="large" onClick={() => {this.addNewDivision()}}>{tr("Add division")}</Button>

            <div className="user-count">
                {divisions.length} {this.tr('divisions-count')}
            </div>

            <div id="teamgroup-wrapper">
                <div className="header">
                    <div className="column delete"></div>
                    <div className="column group">{tr("Name")}</div>
                    <div className="column users">{tr("Division ID")}</div>
                </div>
                <div className="content">
                    {divisions.map(row => (
                        <div className="row">
                            <div className="column delete">
                                {!row.dynamic && <DeleteIcon
                                    fontSize="small"
                                    onClick={() => this.removeDivision(row.id)}
                                />}
                            </div>
                            <div className="column group">
                                <TextInputCell
                                    ref={this.nameInput}
                                    name="name"
                                    editable={this.state.canEdit}
                                    value={row.name}
                                    onEdited={(name, value) => {
                                        this.updateDivision(row.id, {name: value});
                                    }}
                                />
                            </div>
                            <div className="column users">
                                <TextInputCell
                                    ref={this.divisionIdInput}
                                    name="division_id"
                                    editable={this.state.canEdit}
                                    value={row.division_id}
                                    onEdited={(name, value) => {
                                        this.updateDivision(row.id, {division_id: value});
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    }
}

export default Divisions;
