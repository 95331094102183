import React from "react";

import SettingRow from "./SettingRow";
import ListCell from "../ListCell";
import TextInputCell from "../cells/TextInputCell";

import TrashCanIcon from "@mui/icons-material/Delete";
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { CheckBox } from "@mui/icons-material";
import { Switch } from "@mui/material";
import RadioButtonCell from "../cells/RadioButtonCell";

import PropsOnlyListRow from "../PropsOnlyListRow";
import { withSnackbar } from 'notistack';

import cn from 'classnames';

import './ResourcingSkillListRow.css';

class ResourcingSkillListRow extends PropsOnlyListRow {
	constructor(props) {
		super(props, {invalids: []}, {}, "list/rows/ResourcingSkillListRow");
    }

    async setDataAndUpdate(a, b = undefined) {
        const data  = this.setData(a, b);
        const intId = Number(data.id);

        if(intId > 0) {
            try {
                const test = await this.update(data);
            } catch (err) {

                if (err.status == 422) {
                    this.setState({ invalids: ['name'] });
                }
            }
        }
    }

    editAndSave = (name, value) => {
        // this.setState({ invalids: [] });

        let data   = {...this.props.data};
        data[name] = value;

        this.setDataAndUpdate(data);
    }
    
    changeDefault = (e, checked) => {
        if (checked)
            this.editAndSave("is_default", checked);
    }

    save = () => {
        const { enqueueSnackbar } = this.props;
        const { tr } = this;

        let data = this.props.data;

        if (data.name == '' || data.name.trim() == '') {
            enqueueSnackbar(tr("Please enter name for skill"), {
                variant: "error",
            });
            return;
        }

        this.create(data);
    }

    defineCells() {
        const { data } = this.props;

        let cells = {
            delete: 
                (data.id > 0 ? <ListCell className={cn("noBg", !data.is_default && "pointer")} onClick={!data.is_default ? () => this.delete() : undefined} permanentEditMode>
                    {!data.is_default && <TrashCanIcon />}
                </ListCell> : <ListCell width={this.props.columnWidthMap.delete} permanentEditMode={true} onlyDisplay={true}>
					<Cancel className="cancelIcon" onClick={() => this.delete(data)} />

					<CheckCircle className="saveNewRowCheckCircleIcon" onClick={this.save} />
				</ListCell>),
            name:   
                <TextInputCell error={this.state.invalids.includes('name')} validation={['empty']} onEdited={this.editAndSave} name="name" value={data['name']} />,
            description:   
                <TextInputCell onEdited={this.editAndSave} name="description" value={data['description']} />,
            default:
                <ListCell className="" width={this.props.columnWidthMap['default']} onlyDisplay>
                   <RadioButtonCell 
                        onEdited= {this.changeDefault }
                        name="default" 
                        width={this.props.columnWidthMap['default']}
                        checked={data.is_default}
                    />
                </ListCell>
        };

        return cells;
    }

    defineClassName() {
        return "ResourcingSkillListRow";
    }
}

export default withSnackbar(ResourcingSkillListRow);