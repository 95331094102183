import React from "react";
import { Pie } from "react-chartjs-2";
import TaimerComponent from "../../../../TaimerComponent";
import _ from "lodash";
import customTooltips from '../../../../general/ChartTooltip';

class InsightPieChart extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "charts/ProfitLossChart");
  }

  render() {
    const { labels, datasets, currency } = this.props;
    const {
      functions: { presentCurrency }
    } = this.context;

    const data = {
      labels,
      datasets
    };

    return (
      <div style={{width: "100%", height: "100%", maxWidth: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Pie
        width={"100%"}
        data={data}
        options={{
          layout: {
            padding: {
              right: 25,
              top: 0,
              bottom: 25,
              left: 25
            }
          },
          tooltips: {
            enabled: false,
            custom: customTooltips,
            titleFontSize: 12,
            titleFontStyle: "bold",
            titleMarginBottom: 15,
            bodyFontSize: 11,
            bodyFontColor: "rgba(255,255,255,0.7)",
            bodySpacing: 8,
            caretSize: 0,
            xPadding: 20,
            yPadding: 20,
            intersect: false,
            callbacks: {
              labelColor: tooltipItem => {
                return {
                  backgroundColor:
                    datasets[0].borderColor[tooltipItem.index]
                };
              },
              label: (tooltipItem, data) => {
                var label =
                  " " + _.escape(data.labels[tooltipItem.index]) || "";

                if (label) {
                  label += ": ";
                }
                label += presentCurrency(data.datasets[0].values[tooltipItem.index], currency);
                return label;
              }
            }
          },
          legend: {
            display: false
          }
        }}
      />
      </div>
    );
  }
}

export default InsightPieChart;
