import React from 'react';

import List from '../../list/List';
import Utils from './../../general/Utils.js';
import Button from '@mui/material/Button';
import ListCell from '../../list/ListCell';
import TreeOption from '../../list/TreeOption';
import SettingRow from '../../list/rows/SettingRow';
import DataHandler from '../../general/DataHandler';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import TrashCanIcon from '@mui/icons-material/Delete';
import TextInputCell from '../../list/cells/TextInputCell';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from '../../TaimerComponent';
import CreateBobOptions from '../../list/CreateBobOptions';
import AutoCompleteCell from '../../list/cells/AutoCompleteCell';
import { SettingsContext } from '../../SettingsContext';
import SettingsGrid from '../pages/SettingsGrid';

/* css */
import './Efina.css';

/**
 * Efina settings - costPoolRow
 *
 */
class costPoolRow extends SettingRow {
	costPoolOnChanged(obj) {
		let newState = this.state.data;
		newState.name = obj.name;
		newState.branchofbusiness = obj.id;
		this.setState({ data: newState });
		this.editAndSave();
	}

	/**
	 * costPoolRow renderer
	 *
	 */
	render() {
		const cells = {
			delete: (
				<ListCell
					className='noBg pointer'
					permanentEditMode={true}
					onClick={() => this.delete(this.state.data)}
					width={this.props.columnWidthMap['delete']}
				>
					<TrashCanIcon />
				</ListCell>
			),
			cost_pool_id: (
				<TextInputCell
					width={this.props.columnWidthMap['cost_pool_id']}
					name='cost_pool_id'
					value={this.state.data['cost_pool_id']}
					onEdited={this.editAndSave}
				/>
			),
			name: (
				<AutoCompleteCell
					listCellProps={{
						className: 'product_types_id',
					}}
					editable={true}
					autoCompleteData={this.props.rowProps.options}
					name='name'
					width={this.props.columnWidthMap['name']}
					value={{ id: -1, name: this.state.data['name'] }}
					components={{
						Option: props => {
							const passProps = {
								isDisabled: props.isDisabled,
								isFocused: props.isFocused,
								isMulti: props.isMulti,
								isSelected: props.isSelected,
							};

							return (
								<TreeOption
									{...props.data}
									{...passProps}
									onSelect={bob => {
										props.setValue(bob.id);
										this.costPoolOnChanged(bob);
									}}
								/>
							);
						},
					}}
				/>
			),
		};

		return (
			<div
				className='listElement row flex'
				style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
			>
				{this.props.columnOrder.map(columnName => cells[columnName])}
			</div>
		);
	}
}

/**
 * Efina settings - productRow
 *
 */
class productRow extends SettingRow {

	/**
	 * productRow renderer
	 *
	 */
	render() {
		const cells = {
			delete: (
				<ListCell
					className='noBg pointer'
					permanentEditMode={true}
					onClick={() => this.delete(this.state.data)}
					width={this.props.columnWidthMap['delete']}
				>
					<TrashCanIcon />
				</ListCell>
			),
			name: (
				<TextInputCell
					width={this.props.columnWidthMap['name']}
					name='name'
					value={this.state.data['name']}
					onEdited={this.editAndSave}
				/>
			),
			product_id: (
				<TextInputCell
					width={this.props.columnWidthMap['product_id']}
					name='product_id'
					value={this.state.data['product_id']}
					onEdited={this.editAndSave}
				/>
			),
		};

		return (
			<div
				className='listElement row flex'
				style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
			>
				{this.props.columnOrder.map(columnName => cells[columnName])}
			</div>
		);
	}
}

/**
 * Efina settings
 *
 */
class Efina extends TaimerComponent {
	static contextType = SettingsContext;

	constructor(props, context) {
		super(props, context, 'settings/api-settings/Efina');

		this.state = {
			username: '',
			password: '',
			bill_username: '',
			bill_password: '',
		};

		this.efinaProductsList = React.createRef();
		this.efinaCostPoolList = React.createRef();
	}

	/**
	 * Efina settings componentDidMount
	 *
	 */
	componentDidMount() {
		super.componentDidMount();
		this.updateComponentData();
	}

	/**
	 * Gets company efina data from backend
	 *
	 */
	updateComponentData = () => {
		const { company } = this.props;

		DataHandler.get({ url: `settings/${company}/efina` }).done(response => {
			response.categories_leveled = Utils.treeFormatDataForList(response.categories, 'parent_id');

			if (response.categories_leveled) {
				response.categories_bobOptions = CreateBobOptions(response.categories_leveled);
			}

			this.setState(response);
		});
	};

	/**
	 * Saves invoice authentication information
	 *
	 */
	saveAuth = () => {
        const { userObject } = this.context;
        const { company } = this.props;

		DataHandler.put({ url: `settings/${company}/efina` }, this.state).done(resp =>
			this.updateComponentData()
		);
	};

	/**
	 * Saves bill authentication information
	 *
	 */
	saveBillAuth = () => {
        const { userObject } = this.context;
        const { company } = this.props;

		DataHandler.put({ url: `settings/${company}/efina_bill` }, this.state).done(resp =>
			this.updateComponentData()
		);
	};

	/**
	 * Gets new data
	 * Moves user back to integration page
	 *
	 */
	back = () => {
		const {
			functions: { updateView },
        } = this.context;
        
		this.props.fetchData();
		updateView({ module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: '' });
	};

	/**
	 * Deactivates efina and empties efina authentication data
	 *
	 */
	deactivate = () => {
		const { userObject } = this.context;
		DataHandler.delete({ url: `settings/${this.props.company}/efina` }).done(this.back);
	};

	/**
	 * Deactivates efina_bill and empties efina_bill authentication data
	 *
	 */
	deactivateBill = () => {
		const { userObject } = this.context;
		DataHandler.delete({ url: `settings/${this.props.company}/efina_bill` }).done(this.back);
	};

	onCheckChange = (update, name, value) => {
        this.setState({[name]: value}, () =>  DataHandler.put({url: `settings/${this.props.company}/efina/project_dimensions`}, {value: value}));
    }

	/**
	 * Efina renderer
	 *
	 */
	render() {
		const { tr } = this;
		const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
		const { username, password, activated, bill_username, bill_password, bill_activated } = this.state;
        const settings = { efina_project_dimensions: this.state.efina_project_dimensions };

		return (
			<div id='integration-efina' className='main-integration-wrapper'>
				<div className='header'>
					<Button className='return-button' variant='text' size='large' onClick={this.back}>
						{<ChevronLeft />}
					</Button>
					<h3>{tr('eFina Settings')}</h3>
				</div>
				<div className='settings'>
					<h4>{tr('Activate invoices (SFTP)')}</h4>
					<OutlinedField
						value={username}
						onChange={evt => this.setState({ username: evt.target.value })}
						label={tr('Username')}
					/>
					<OutlinedField
						value={password}
						autoComplete="new-password"
						onChange={evt => this.setState({ password: evt.target.value })}
						label={tr('Password')}
					/>
					<div className='settings-buttons'>
						<Button className='settings-buttons-save' color='primary' onClick={this.saveAuth} size='large'>
							{tr('Save')}
						</Button>
						{activated === '1' && (
							<Button
								className='settings-buttons-activate'
								color={'secondary'}
								onClick={this.deactivate}
								size='large'
							>
								{tr('Deactivate')}
							</Button>
						)}
					</div>
				</div>
				<div className='settings'>
					<h4>{tr('Activate expenses')}</h4>
					<OutlinedField
						value={bill_username}
						onChange={evt => this.setState({ bill_username: evt.target.value })}
						label={tr('Username')}
					/>
					<OutlinedField
						value={bill_password}
						onChange={evt => this.setState({ bill_password: evt.target.value })}
						label={tr('Password')}
					/>
					<div className='settings-buttons'>
						<Button
							className='settings-buttons-save'
							color={'primary'}
							onClick={this.saveBillAuth}
							size='large'
						>
							{tr('Save')}
						</Button>
						{bill_activated === '1' && (
							<Button
								className='settings-buttons-activate'
								color={'secondary'}
								onClick={this.deactivateBill}
								size='large'
							>
								{tr('Deactivate')}
							</Button>
						)}
					</div>
				</div>
				<div className="project-dimension-wrapper">
                    <h4>{tr("Create project dimensions")}</h4>
                    <p>{tr("Create project dimensions automatically to eFina when project is created in Taimer. You need to activate expense integration to use this feature.")}</p>
                    <SettingsGrid item={settings} onChange={this.onCheckChange} settings={[{
                        type: "check",
                        name: "efina_project_dimensions",
                        label: tr("Create project dimensions"),
                        className: "respSetting third"
                    }]} />
                </div>
				{
					<div className='settings'>
						<h4>{tr('Dimensions')}</h4>
						<Button
							className='green'
							onMouseUp={() => this.efinaCostPoolList.current.addNewRow()}
							size='large'
						>
							{tr('ADD DIMENSION')}
						</Button>
						<br />
						<List
							ref={this.efinaCostPoolList}
							id='efinaCostPoolList'
							className='settingsList'
							height='auto'
							rowHeight={SettingRow.rowHeight}
							sharedData={this.state.categories}
							newRow={{
								id: -1,
								name: '',
								cost_pool_id: -1,
							}}
							listRowType={costPoolRow}
							columns={[
								{ width: 55, name: 'delete', header: '', ...dColConf },
								{ width: 350, name: 'name', header: tr('Dimension'), ...dColConf },
								{ width: 200, name: 'cost_pool_id', header: tr('Dimension number'), ...dColConf },
							]}
							rowProps={{
								options: this.state.categories_bobOptions,
								onCreate: data => {
									DataHandler.post(
										{ url: `settings/${this.props.company}/add_efina_cost_pool` },
										{ data: JSON.stringify(data) }
									).done(response => {
										this.updateComponentData();
									});
								},
								onUpdate: data => {
									DataHandler.post(
										{ url: `settings/${this.props.company}/update_efina_cost_pool` },
										{ data: JSON.stringify(data) }
									).done(response => {
										this.updateComponentData();
									});
								},
								onDelete: data => {
									if (data.deleted) data.deleted = '1';
									DataHandler.post(
										{ url: `settings/${this.props.company}/update_efina_cost_pool` },
										{ data: JSON.stringify(data) }
									).done(response => {
										this.updateComponentData();
									});
								},
							}}
							data={this.state.cost_pools}
						/>
						<h4>{tr('Products')}</h4>
						<Button
							className='green'
							onMouseUp={() => this.efinaProductsList.current.addNewRow()}
							size='large'
						>
							{tr('ADD PRODUCT')}
						</Button>
						<br />
						<List
							ref={this.efinaProductsList}
							id='efinaProductsList'
							className='settingsList'
							height='auto'
							rowHeight={SettingRow.rowHeight}
							sharedData={this.state.autocompleteData}
							newRow={{
								id: -1,
								name: '',
								product_id: -1,
							}}
							listRowType={productRow}
							columns={[
								{ width: 55, name: 'delete', header: '', ...dColConf },
								{ width: 350, name: 'name', header: tr('Name'), ...dColConf },
								{ width: 200, name: 'product_id', header: tr('Product number'), ...dColConf },
							]}
							rowProps={{
								onCreate: data => {
									DataHandler.post(
										{ url: `settings/${this.props.company}/add_efina_product` },
										{ data: JSON.stringify(data) }
									).done(response => {
										this.updateComponentData();
									});
								},
								onUpdate: data => {
									DataHandler.post(
										{ url: `settings/${this.props.company}/update_efina_product` },
										{ data: JSON.stringify(data) }
									).done(response => {
										this.updateComponentData();
									});
								},
								onDelete: data => {
									if (data.deleted) data.deleted = '1';
									DataHandler.post(
										{ url: `settings/${this.props.company}/update_efina_product` },
										{ data: JSON.stringify(data) }
									).done(response => {
										this.updateComponentData();
									});
								},
							}}
							data={this.state.products}
						/>
					</div>
				}
			</div>
		);
	}
}

export default Efina;
