import React from 'react';
import ListRow from "../list/ListRow";
import ListCell from "../list/ListCell";
import TextInputCell from "../list/cells/TextInputCell";
import Utils from "../general/Utils";
import { formatInputNumber } from '../helpers';
import { SettingsContext } from './../SettingsContext';
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import ContextMenu from '../general/ContextMenu';
import MultilineAutocompleteCell from "../list/cells/MultilineAutocompleteCell";
import { Button, MenuItem, TextField } from '@mui/material';
import "./BillRow.css";
import { nmultiply, roundToFixed } from "./../general/MathUtils";

import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';

class BillRow extends ListRow {
    static contextType  = SettingsContext;
    static defaultProps = {
        ...ListRow.defaultProps,
        rowProps: {
            ...ListRow.defaultProps.rowProps,
            onChange: () => {},
            editMode: false
        }
    };


    constructor(props, context) {
        super(props, {},{}, "bills/BillRow");

        this.context = context;
        this.editRow = this.editRow.bind(this);
    }


    // TODO: abstract
    defineClassName() {
        const { useRowSpecificCatcodes } = this.props.rowProps;
        let className = "billRow";

        if (useRowSpecificCatcodes) {
            className += " increased_row_height"
        }

        return className;
    }

    defineCells() {
        const { taimerAccount } = this.context;
        const { currencyRate, renderRowAccountingCell, purchaseVatCodes, useRowSpecificCatcodes } = this.props.rowProps;
        const noCurrencySums = !currencyRate || currencyRate == "1.000000";

        const commonProps = {
            textAlign: "right",
            onEdited: this.editRow,
            // runOnEditedOnInput: true
        };

        const editModeProps = {
            listCellProps: {
                editable: this.props.rowProps.editMode || this.state.data.id < 0,
                inEditMode: this.props.rowProps.editMode || this.state.data.id < 0
            }
        };

        const rowVat = this.state.data.vat || 0;
        const defaultVatcode = { id: 0, value: rowVat, percentage: rowVat,  topText: rowVat + " %", subText: this.tr("No vatcode") }
        
        const priceFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            useGrouping: false,
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
        }).format;

        // const totalNoVat = this.numberFormat

        const cells = {
            context: 
                !this.props.rowProps.editMode ? <ListCell width={this.props.columnWidthMap.context} editable={false} /> : <ContextMenu data-testid={`row_context_menu_${this.state.data.id}`} className="cell row-menu" label={<ContextMenuIcon />} buttonProps={{ className: 'action-menu' }} style={{width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + " 1 0px" }} noExpandIcon>
                    <MenuItem data-testid="row_context_menu_delete" className="delete" onClick={() => {this.delete()}}><RemoveIcon className="Delete" />{this.tr("Delete")}</MenuItem>
                </ContextMenu>,
            description:
                <TextInputCell
                    {...editModeProps}
                    value={this.state.data.description}
                    runOnEditedOnInput={true}
                    onEdited={value => {
                        this.setData("description", value);
                    }} />,
            quantity:
                <TextInputCell
                    {...editModeProps}
                    {...commonProps}                   
                    name="quantity"
                    validation={["numeric"]}
                    value={formatInputNumber(this.state.data.quantity)} />,
            unit_price: 
                <TextInputCell
                    {...editModeProps}
                    {...commonProps}
                    name="unit_price"
                    validation={["numeric"]}
                    value={priceFormatter(noCurrencySums ? this.state.data.unit_price : this.state.data.currency_unit_price).replace(".", ",")?.replace('−', '-')} />,
            total_no_vat: 
                <TextInputCell
                    {...commonProps}
                    name="total_no_vat"
                    editable={false}
                    value={formatInputNumber(noCurrencySums ? this.state.data.total_no_vat : this.state.data.currency_total_no_vat)} />,
            vat: 
                useRowSpecificCatcodes ? 
                    <MultilineAutocompleteCell 
                        topText="percentage"
                        subText="label"
                        editMode={editModeProps?.listCellProps?.inEditMode}
                        autoCompleteData={purchaseVatCodes}
                        value={purchaseVatCodes?.find(p => p.id == this.state.data?.purchase_vatcode) ? this.state.data?.purchase_vatcode : defaultVatcode}
                        onEdit={value => {
                            if (Number(value.id) < 0) {
                                value.id = 0;
                                value.percentage = 0;
                            }
                            this.editRow({purchase_vatcode: value.id, vat: value.percentage}, 0);
                        }}
                        listCellProps={{ 
                            width: this.props.columnWidthMap.vat,
                            ...editModeProps.listCellProps
                        }}
                    />
                :
                <TextInputCell 
                    {...editModeProps}
                    {...commonProps}
                    name="vat"
                    validation={["numeric"]}
                    value={formatInputNumber(this.state.data.vat)} />,
            total: 
                <ListCell 
                    {...commonProps}
                    data-testid={`total_${this.props.rowIndex}`}
                    name="total"
                    editable={false}
                    value={formatInputNumber(noCurrencySums ? this.state.data.total : this.state.data.currency_total)} />,
            accounting: renderRowAccountingCell ? renderRowAccountingCell(this.state.data.id, {width: this.props.columnWidthMap.accounting, 'data-testid': "accounting_" + this.props.rowIndex, textAlign: "right"}) : <div></div>,
        };

        return cells;
    }


    editRow(name, value) {
        const noCurrencySums = !this.props.rowProps.currencyRate || this.props.rowProps.currencyRate == "1.000000";
        let currencyRate = Number(this.props.rowProps.currencyRate);
        if (!currencyRate)
            currencyRate = 1;

        if (name == "unit_price" && !noCurrencySums) {
            name = "currency_unit_price";
        }    

        this.setData(name, value, () => {
            const data = this.state.data;
            if (!noCurrencySums) {
                data.unit_price = data.currency_unit_price / currencyRate;
            }

            const vatMultip           = 1 + (parseFloat(String(data.vat).replace(/\,/g, ".")) || 0) / 100;
            const quantity            = parseFloat(String(data.quantity).replace(/\,/g, ".")) || 0;
            const unit_price          = parseFloat(String(data.unit_price).replace(/\,/g, ".")) || 0;
            const currency_unit_price = parseFloat(String(data.currency_unit_price).replace(/\,/g, ".")) || 0;

            data.total_no_vat = roundToFixed(nmultiply(quantity, unit_price), 2) || 0;
            data.total = roundToFixed(nmultiply(vatMultip || 0, Number(data.total_no_vat)), 2);

            if (!noCurrencySums) {
                data.currency_total_no_vat = roundToFixed(nmultiply(quantity, currency_unit_price), 2) || 0;
                data.currency_total = roundToFixed(nmultiply(vatMultip || 0, Number(data.currency_total_no_vat)), 2);
            }
        
            this.setData(data);
            this.props.rowProps.onChange(data);
        });
    }
}

export default BillRow;
