import React from 'react';
import { IconButton } from '@mui/material';
import OutlinedField from '../general/OutlinedField';
import TaimerComponent from '../TaimerComponent';
import { DialogUserHour, DialogUserHourPeriod } from './ResourceDialogUsers';
import cn from 'classnames';

import DeleteIcon from '@mui/icons-material/Delete';

import styles from './ResourceDialogPeriodRow.module.scss';
import { Employee } from '../resourcing/helpers';
import { Task } from '../resourcing/resourcing';
import { format } from 'date-fns';

interface Props {
    className?: string;
    disableEdit: boolean;
    disableHoursEdit: boolean;
    index: number;
    task: Task;
    user: DialogUserHour;
    period: DialogUserHourPeriod;
    employee?: Employee;
    onChange?: (period: DialogUserHourPeriod) => void;
    onDelete?: (period: DialogUserHourPeriod) => void;
}

interface State {
}

class ResourceDialogPeriodRow extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'dialogs/ResourceDialogPeriodRow');

        this.state = {
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
    };

    deleteClick = () => {
        const { period, onDelete } = this.props;

        onDelete?.(period);
    }

    changeHours = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { task, period, onChange } = this.props;

        const { target: { value } } = e;

        const hours = Number(value.replace(',', '.'));

        onChange?.({
            ...period, 
            hours, 
            lastHours: hours,
            invalid: isNaN(hours) || hours > task.hours,
        });
    }

    render() {
        const { className, task, employee, user, period, disableHoursEdit } = this.props;
        const { userObject } = this.context;

        return (<>
            <div className={cn(className, styles.row)}>
                <div className={styles.expand}>

                </div>
                <div className={styles.nametitle}>
                    {format(period.start, userObject.dateFormat)}
                    {period.end.getTime() !== period.start.getTime() && <> - {format(period.end, userObject.dateFormat)}</>}
                </div>
                <div className={styles.hours_done}>
                </div>
                <div className={styles.hours}>
                    <OutlinedField 
                        disabled={disableHoursEdit}
                        error={user.invalid}
                        onInput={this.changeHours} 
                        value={period.hours} />
                </div>
                <div>
                    <IconButton
                        classes={{ disabled: 'disabled' }}
                        disabled={disableHoursEdit}
                        aria-label={this.tr("Delete")}
                        onClick={this.deleteClick}
                        size="large">
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </div>
            </div>
        </>);
    }
}

export default ResourceDialogPeriodRow;
