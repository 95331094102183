import React from 'react';
import { Grid as VirtualizedGrid, List as VirtualizedList, AutoSizer, ScrollSync } from 'react-virtualized';
import { Button, Popover, Checkbox, Tooltip } from '@mui/material';
import { Edit, Person, Delete, Description, Add, DescriptionOutlined, AddCircleOutline } from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import { withSnackbar } from 'notistack';
import moment from 'moment';

import TaimerComponent from '../TaimerComponent';
import RevenueRecognitionTableHeader from './RevenueRecognitionTableHeader';
import TaimerAvatar from '../general/TaimerAvatar';
import DataList from '../general/DataList';
import MyDayDateRangeModal from '../dashboard/my_day/components/MyDayDateRangeModal';
import InsightDropDown from '../dashboard/insights/InsightDropDown';
import RevenueRecognitionTableFooter from './RevenueRecognitionTableFooter';
import DataHandler from '../general/DataHandler';
import InsightSlider from "../dashboard/insights/InsightSlider";

import './RevenueRecognitionTable.css';
import NoteDrawer from '../general/NoteDrawer';

import { ReactComponent as LockIcon } from '../general/icons/lock-filled.svg';
import { ReactComponent as UnLockIcon } from '../general/icons/Unlock-filled.svg';

const ROW_HEIGHT = 50;
const HEADER_HEIGHT = 48;
const LIST_HEIGHT = 250;
const LEFT_TABLE_WIDTH = 220;
const COLUMN_WIDTH = 160;
const RIGHT_TABLE_WIDTH = 140;

class RevenueRecognitionTable extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'projects/RevenueRecognitionTable');

        this.grid = React.createRef();
        this.tableHeader = React.createRef();
        this._noteDrawer = React.createRef();

        this.dateFormat = 'YYYY-MM';
        this.totalDateFormat = 'YYYY-MM-DD';

        this._dateToAllStyle = { display: 'flex', alignItems: 'center' };
        this._savedData = {};

        this.overscanRowCount = 5;
        this.overscanColumnCount = 0;

        this._revenueRecognitionData = { totals: {} };

        this.__estimatedCostsData = {};

        this._viewModes = [
            {
                key: 'percentage',
                label: this.tr('Percentage'),
                action: () => this.setState({ selectedViewMode: 'percentage' }),
            },
            {
                key: 'currency',
                label: this.tr('Currency'),
                action: () => this.setState({ selectedViewMode: 'currency' }),
            },
        ];

        const data = {
            totals: {},
        };

        let openEditMode = false;
        try {
            openEditMode = localStorage.getItem('revenue_recognition_enable_editmode_on_page_load') || '0';
            localStorage.removeItem("revenue_recognition_enable_editmode_on_page_load");
        } catch (e) {
            console.error(e);
        }

        this.state = {
            horizontalScrollBarWidth: 0,
            verticalScrollBarWidth: 0,
            showRows: false,
            data,
            noteData: null,
            months: this._getMonthsFromData(data),
            editMode: openEditMode == this.props.fieldKey ? true : false,
            hoverIndex: null,
            datePickerData: null,
            changeDateForAllMonths: false,
            selectedViewMode: this.props.onlyRead ? 'currency' : this.props.selectedViewMode || 'percentage',
            errors: {},
            loading: false,
            sliderProps: false,
            unlockMonths: false,
            sliderColumns: ["num", "sum"]
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        this._getData();
        this.props.showNoteDrawer && this._getNoteData();
    };

    componentDidUpdate = (oldProps, oldState) => {
        if (oldState.data != this.state.data || oldState.hoverIndex != this.state.hoverIndex || oldState.editMode != this.state.editMode || oldState.selectedViewMode != this.state.selectedViewMode) {
            this.grid.current && this.grid.current.forceUpdate();
        }

        if (oldProps.months != this.props.months) {
            this.setState({
                months: this._getMonthsFromData(),
            });
        }
    };

    _getNoteData = () => {
        DataHandler.get({
            url: `projects/${this.props.project.id}/revenue_recognition_note`,
        })
            .done((noteData) => {
                this.setState({ noteData });
            })
            .fail(() => {
                this.setState({ noteData: {} });
            });
    };

    _getData = () => {
        const { endpoint, setGraphData, fieldKey, setRevenueRecognitionData } = this.props;
        this.setState({ loading: true }, () => {
            DataHandler.get({
                url: endpoint,
            }).done((data) => {
                (data.rows || []).sort((a, b) => a.id - b.id);
                this._savedData = data;
                this.setState({ data, loading: false, months: this._getMonthsFromData(data) });
                setGraphData({ [fieldKey]: data });
                setRevenueRecognitionData && setRevenueRecognitionData(data);
            });
        });
    };

    setRevenueRecognitionData = (data, split = false) => {
        this._revenueRecognitionData = data;
        if (split) {
            this.splitAccordingToRevenueRecognition(true);
        }
    };

    setScheduledInvoiceData = (scheduledInvoiceData) => {
        const { months } = this.state;
        const { enqueueSnackbar, settings } = this.props;
        const data = cloneDeep(this.state.data);
        months.forEach((month) => {
            let value = 0;
            Object.keys(scheduledInvoiceData.totals).forEach((dateString) => {
                const momentDate = moment(dateString, this.totalDateFormat);
                if (month.isSame(momentDate, 'month')) {
                    value = Number(scheduledInvoiceData.totals[dateString].value || 0).toFixed(2);
                }
            });
            const monthString = month.format(this.totalDateFormat);
            const userMonthString = month.format(this.dateFormat);
            if (value !== 0 && data.totals[monthString] != value) {
                //update user values to keep correct percentage
                Object.keys(data.rows).forEach((index) => {
                    const percentage = this._calculatePercentage(data.rows[index].values[userMonthString], data.totals[monthString]) / 100;
                    const newValue = percentage * value;
                    data.rows[index].values[userMonthString] = newValue;
                });
            }
            data.totals[monthString] = value;
        });
        const difference = this._getSummaryValue({ difference: true, value: 0 }, data);
        if (settings.revenue_recognition_equal_project_value == 1 && difference != 0 && this.getTableTotal(data) != 0) {
            enqueueSnackbar(this.tr('Recognized value must be equal to project value!'), { variant: 'error' });
            return;
        }
        const finalData = { data };
        this.setState(finalData, () => {
            this._save(true);
        });
    };

    setEstimatedCostsData = (data, split = false) => {
        this._estimatedCostsData = data;
        /*if (split) {
            this.splitAccordingToRevenueRecognition(true);
        }*/
    };

    _getMonthsFromData = (data = this.state.data) => {
        const totals = data.totals || {};
        // if (this.props.useDataMonths) {
        //     const dataMonths = [];
        //     (Object.keys(totals) || []).forEach((date) => {
        //         const momentDate = moment(date, this.totalDateFormat);
        //         dataMonths.push(momentDate);
        //     });

        //     if (dataMonths.length > 0) {
        //         return dataMonths;
        //     }
        // }
        const months = [...this.props.months];
        // getting month dates from data
        Object.keys(totals).forEach((date) => {
            const momentDate = moment(date, this.totalDateFormat);
            const foundIndex = months.findIndex((m) => m.isSame(momentDate, 'month'));
            if (foundIndex != -1) {
                months[foundIndex] = momentDate;
            }
        });
        return months;
    };

    _getCurrencySymbol = () => {
        const {
            project: { companies_currency },
        } = this.props;

        const value = 0;
        return value.toLocaleString('en', { style: 'currency', currency: companies_currency }).replace(/\d+([,.]\d+)?/g, '');
    };

    getUnallocatedValueForMonth = (date) => {
        const { data } = this.state;
        const month = date.format(this.dateFormat);
        const totalMonth = date.format(this.totalDateFormat);
        const usersTotal = this._getUserTotalForMonth(month);
        const total = data.totals[totalMonth];
        return this._formatNumber(total || 0) - this._formatNumber(usersTotal || 0);
    };

    formatDecimals = (value) => {
        let formattedValue = value.replace(',', '.');
        if (formattedValue == '.') formattedValue = '0.';
        return formattedValue;
    };

    isInputValueInvalid = (value) => {
        const { fieldKey } = this.props;
        if (fieldKey == 'revenue_recognition')
            return (value != '0' && value != '0.' && value != '' && !value.startsWith('-') && !Number(value));
        else
            return (value != '0' && value != '0.' && value != '' && !Number(value));
    };

    _getDataKey = () => {
        const { selectedCustomViewMode } = this.props;
        return selectedCustomViewMode || 'values';
    };

    _getUserTotalForMonth = (month, data = this.state.data) => {
        return (data.rows || []).filter((r) => !r.delete).reduce((prev, u) => prev + this._formatNumber(u[this._getDataKey()][month] || 0), 0);
    };

    setTotal = (value, month, columnIndex) => {
        const { useValue } = this.props;
        const formattedValue = this.formatDecimals(value);
        if (this.isInputValueInvalid(formattedValue)) return;
        const data = cloneDeep(this.state.data);
        if (value == 0 && data.rows) {
            data.rows.forEach((row) => {
                row.values[month.format(this.dateFormat)] = 0;
            });
        }
        const monthString = [month.format(this.totalDateFormat)];
        const usersTotal = this._getUserTotalForMonth(month.format(this.dateFormat), data);
        const activeUserRows = this.props.fieldKey == 'revenue_recognition' ? data.rows.filter(user => (user.delete === false || user.is_new === true) ) : 0;
        if (usersTotal * value < 0 && data.rows && this.props.fieldKey == 'revenue_recognition') {
            data.rows.forEach((row) => {
                row.values[month.format(this.dateFormat)] = 0;
            });
        } else if (activeUserRows.length == 1 && this.props.fieldKey == 'revenue_recognition') {
            data.rows.forEach((row) => {
                if (row.sales_agent === true) {
                    row.values[month.format(this.dateFormat)] = value;
                }
            });
        }
        let errors = this.state.errors;
        if (Math.abs(Number(formattedValue)) < Math.abs(usersTotal) || (formattedValue == "-")) {
            errors = { ...this.state.errors, [columnIndex]: 'header' };
        } else {
            errors = { ...this.state.errors, [columnIndex]: null };
        }
        
        let stateData = {};
        if (useValue) {
            stateData = {
                ...data,
                totals: {
                    ...data.totals,
                    [monthString]: {
                        ...data.totals[monthString],
                        value: formattedValue,
                    },
                },
            };
        } else {
            stateData = {
                ...data,
                totals: {
                    ...data.totals,
                    [monthString]: formattedValue,
                },
            };
        }
        this.setState({ data: stateData, errors });
    };

    _formatNumber = (number) => {
        if (!number) return 0;
        return Number(String(number).replace(',', '.'));
    };

    _calculatePercentage = (value, total) => {
        if (!value || Number(value) == 0) return '';
        let percentage = 0;
        if (total && Number(total) != 0 && total != "-") {
            const percentageValue = (Number(value) / Number(total)) * 100;
            percentage = Math.round(percentageValue * 100) / 100;
        }
        return percentage;
    };

    _formatInputValue = (value, options, showZero = false) => {
        const {
            functions: { presentCurrency },
        } = this.context;
        const {
            project: { companies_currency },
        } = this.props;

        const { selectedViewMode, editMode, data } = this.state;
        const { isTotal = false, nonEditable = false, columnTotal, header = false, isFooter = false, month, headerViewMode } = options;
        if ((isTotal && headerViewMode != 'hours') || showZero) {
            return presentCurrency(value || 0, companies_currency);
        }
        if (selectedViewMode == 'percentage' && !header) {
            let percentage = 0;
            if (isFooter) {
                let final = 100;
                (data.rows || []).forEach((row) => {
                    const rowValue = row.values[month.format(this.dateFormat)] || 0;
                    const percentage = this._calculatePercentage(rowValue, columnTotal);
                    final -= percentage;
                });
                percentage = Math.round(final * 100) / 100;
            } else {
                percentage = this._calculatePercentage(value, columnTotal);
            }
            if (Number(percentage) == 0) {
                return '';
            }
            return (editMode && !nonEditable) || percentage == '' ? percentage : percentage + ' %';
        }
        if (editMode && !nonEditable) {
            return value && (Number(value) != 0 || value == '0.' || value == '-') ? ((String(value).endsWith('.') || value === '-') ? value : Math.round(value * 100) / 100) : '';
        }
        if (headerViewMode != 'hours') {
            return value && Number(value) != 0 ? presentCurrency(value, companies_currency) : '';
        }
        return value ? value.toFixed(2) + ' ' + this.tr('h') : '';
    };

    _onInputChange = (value, columnIndex, rowIndex) => {
        const { invertedTotalCalculation, fieldKey } = this.props;
        const formattedValue = this.formatDecimals(value);
        if (this.isInputValueInvalid(formattedValue)) return;
        const data = cloneDeep(this.state.data);
        const month = this.state.months[columnIndex].format(this.dateFormat);
        const totalMonth = this.state.months[columnIndex].format(this.totalDateFormat);
        const item = this._getRows()[rowIndex];
        const itemKey = fieldKey == 'revenue_recognition' ? 'users_id' : 'id';
        const itemIndex = data.rows.findIndex((r) => r[itemKey] == item[itemKey]);
        if (this.state.selectedViewMode == 'percentage') {
            const colTotal = this._formatNumber(data.totals[totalMonth]);
            // old way of calculating the monetary value – used to round it to two decimals.
            // Had some issues with very small numbers so trying it out with the "normal" way below
            // const final = (colTotal * 100 * formattedValue - ((colTotal * 100 * formattedValue) % 100)) / 100;
            const final = colTotal * (formattedValue / 100);
            data.rows[itemIndex][this._getDataKey()][month] = final; // if old way is used, final should be divided by 100
        } else {
            data.rows[itemIndex][this._getDataKey()][month] = formattedValue;
        }
        const userTotal = this._getUserTotalForMonth(month, data);
        let errors = this.state.errors;
        if (invertedTotalCalculation) {
            data.totals = {
                ...data.totals,
                [totalMonth]: userTotal,
            };
        }
        if (!invertedTotalCalculation && Math.abs(userTotal) > Math.abs(Number(data.totals[totalMonth] || 0))) {
            errors = {
                ...this.state.errors,
                [columnIndex]: rowIndex,
            };
        } else {
            errors = {
                ...this.state.errors,
                [columnIndex]: null,
            };
        }
        this.setState({
            data,
            errors,
        });
    };

    _onTitleInputChange = (value, rowIndex) => {
        const data = cloneDeep(this.state.data);
        const item = this._getRows()[rowIndex];
        const itemKey = this.props.fieldKey == 'revenue_recognition' ? 'users_id' : 'id';
        const itemIndex = data.rows.findIndex((r) => r[itemKey] == item[itemKey]);
        data.rows[itemIndex].title = value;
        this.setState({
            data,
        });
    };

    _hasErrors = () => {
        let hasErrors = false;
        Object.keys(this.state.errors).forEach((key) => {
            if (this.state.errors[key] != undefined && this.state.errors[key] != null) {
                hasErrors = true;
            }
        });
        return hasErrors;
    };

    handleKeyDown = (e) => {
        if (e.key == 'Enter' && !this._hasErrors()) {
            this._save();
        } else if (e.key == 'Escape') {
            this._cancel();
        }
    };

    openSlider = (sliderData, totalSum, title) => {
        const {
            project: { companies_currency },
        } = this.props;
        let keys = Object.keys(sliderData[0]);
        let type = sliderData[0]['$type'] ? sliderData[0]['$type'] : 'default';
        keys.splice(keys.indexOf("id"), 1);
        keys.indexOf("num") >= 0 && keys.unshift("number");

        this.setState({
            sliderProps: {
                data: sliderData,
                label: this.tr(title),
                sum: totalSum,
                currency: companies_currency,
                sharedData: {
                    table: {
                        defaultType: type
                    }
                }
            },
            sliderColumns: keys
        });
    };

    onCloseSlider = () => {
        this.setState({sliderProps: false});
    }

    _renderCellContent = (value, columnIndex, rowIndex, options, sliderData = false, title = '') => {
        const { editMode, data, errors, selectedViewMode, months } = this.state;
        const { nonEditable = false, isTotal = false } = options;
        const { fieldKey } = this.props;
        let columnTotal = 0;
        if (columnIndex != null) {
            const month = months[columnIndex].format(this.totalDateFormat);
            columnTotal = data.totals[month];
        }
        return (
            <div className="text-container">
                <div className="input-container">
                    {sliderData ?
                        <div onClick={() => this.openSlider(sliderData, value, title)} data-testid={`rr_table_${fieldKey}_grid_${rowIndex}_${columnIndex}`}>
                            {value !== undefined ? this._formatInputValue(value, { isTotal, nonEditable, columnTotal }, true) : value}
                        </div>
                    :
                        <input
                            onFocus={(e) => e.target.select()}
                            onKeyDown={this.handleKeyDown}
                            disabled={!editMode || nonEditable}
                            className={`${editMode && !nonEditable && 'active'} ${errors && columnIndex != null && errors[columnIndex] == rowIndex && 'error'}`}
                            onChange={(e) => this._onInputChange(e.target.value, columnIndex, rowIndex)}
                            onFocus={(e) => {e.target.scrollIntoView({ block: 'nearest', inline: 'center' })}}
                            value={value !== undefined ? this._formatInputValue(value, { isTotal, nonEditable, columnTotal }) : ""}
                            data-testid={`rr_table_${fieldKey}_grid_${rowIndex}_${columnIndex}_input`}
                        />
                    }
                </div>
                {editMode && !nonEditable && <span>{selectedViewMode == 'percentage' ? '%' : this._getCurrencySymbol()}</span>}
            </div>
        );
    };

    _setHoverIndex = (hoverIndex) => this.setState({ hoverIndex });

    _gridCellRenderer = ({ columnIndex, key, rowIndex, style }) => {
        const { unlockMonths } = this.state;
        const { onlyRead, invertedTotalCalculation, lockDate, checkMonthLock, settings, fieldKey } = this.props;
        const { months, data, hoverIndex } = this.state;
        const month = months[columnIndex].format(this.dateFormat);
        const totalMonth = months[columnIndex].format(this.totalDateFormat);
        const value = this._getRows()[rowIndex][this._getDataKey()][month];
        const total = data.totals[totalMonth];
        let locked = false;

        if (lockDate !== undefined && settings.revenue_recognition_month_lock_user_splits > 0) {
            locked = checkMonthLock(month, unlockMonths);
        }
        
        const sliderData = this._getRows()[rowIndex]['sliderData'] ? this._getRows()[rowIndex]['sliderData'][month] : false;
        const title = this._getRows()[rowIndex]['title'] ? this._getRows()[rowIndex]['title'] : '';
        return (
            <div key={key} className={`cell ${columnIndex == months.length - 1 ? 'no-border' : ''}`} style={style}>
                <div onMouseEnter={() => this._setHoverIndex(rowIndex)} style={{ height: ROW_HEIGHT }} className={`grid-cell ${hoverIndex == rowIndex && !onlyRead && 'hovering'}`}>
                    {this._renderCellContent(value, columnIndex, rowIndex, { nonEditable: locked || (!invertedTotalCalculation && (!total || Number(total) == 0 || total == "-")) }, sliderData, title)}
                </div>
            </div>
        );
    };

    removeUser = (user) => {
        const data = cloneDeep(this.state.data);
        const rows = data.rows;
        const userIndex = rows.findIndex((r) => r.users_id == user.users_id);
        if (userIndex != -1) {
            user.is_new ? rows.splice(userIndex, 1) : (rows[userIndex].delete = true);
        }
        this.setState({
            data: {
                ...data,
                rows,
            },
        });
    };

    removeRow = (row) => {
        const { invertedTotalCalculation } = this.props;
        const data = cloneDeep(this.state.data);
        const rows = data.rows;
        const rowIndex = rows.findIndex((r) => r.id == row.id);
        if (rowIndex != -1) {
            row.is_new ? rows.splice(rowIndex, 1) : (rows[rowIndex].delete = true);
        }
        data.rows = rows;
        if (invertedTotalCalculation) {
            this.state.months.forEach((month) => {
                const userTotal = this._getUserTotalForMonth(month.format(this.dateFormat), data);
                data.totals = {
                    ...data.totals,
                    [month.format(this.totalDateFormat)]: userTotal,
                };
            });
        }
        this.setState({
            data,
        });
    };

    _leftCellRenderer = ({ key, index, style }) => {
        const { data, hoverIndex, editMode } = this.state;
        const { onlyRead, fieldKey } = this.props;
        const item = this._getRows()[index];
        let content;
        switch (fieldKey) {
            case 'revenue_recognition_estimated_costs':
                content = (
                    <div className="editable-left-cell">
                        <div className="input-container">
                            {!item.purchaseorder_id ? <input
                                className={`left ${editMode ? 'active' : ''}`}
                                value={item.title}
                                placeholder={this.tr('Title here..')}
                                onChange={(e) => this._onTitleInputChange(e.target.value, index)}
                                disabled={!editMode}
                            /> :
                            <div className="purchase-order-link" onClick={(e) => this.context.functions.updateView({ module: "purchaseorder", action: "view", id: item.purchaseorder_id, companies_id: item.companies_id, projects_id: item.projects_id }, e.ctrlKey || e.metaKey || e.button === 1)}>
                                {this.tr('Purchase order') + ' (' + item.purchaseorder_id + ')'}
                            </div>
                            }
                        </div>
                        {editMode && !item.purchaseorder_id && (
                            <button onClick={() => this.props.showConfirmation(() => this.removeRow(item))}>
                                <Delete />
                            </button>
                        )}
                    </div>
                );
                break;
            case 'revenue_recognition_actual_costs':
                content = (
                    <div className="titles">
                        <h3>{this.tr(item.title)}</h3>
                    </div>
                );
                break;

            case 'revenue_recognition_invoiced':
                const showToolTip = (item.title == 'Credited' || item.title == 'Refunded');
                content = (
                    <div className="titles">
                        <h3 className={showToolTip ? 'HasTooltip' : ''} title={showToolTip ? this.tr("The figures on this row consist of invoice totals instead of the project part.") : ''}>{this.tr(item.title)}</h3>
                    </div>
                );
                break;

            case 'revenue_recognition_working_hour_costs':
                content = (
                    <div className="titles">
                        <h3>{this.tr(item.title)}</h3>
                    </div>
                );
                break;
            default:
                content = (
                    <>
                        {data.type != 'cost' && data.type != 'invoiced' && <TaimerAvatar name={item.name} id={item.users_id} />}
                        <div className="titles">
                            <h3>{item.name}</h3>
                            {item.title && <p>{item.title}</p>}
                        </div>
                        {!onlyRead && editMode && !item.sales_agent && (
                            <button onClick={() => this.props.showConfirmation(() => this.removeUser(item))}>
                                <Delete />
                            </button>
                        )}
                    </>
                );
                break;
        }
        return (
            <div key={key} className="cell" style={style}>
                <div onMouseEnter={() => this._setHoverIndex(index)} style={{ height: ROW_HEIGHT }} className={`left-cell ${hoverIndex == index && !onlyRead && 'hovering'}`}>
                    {content}
                </div>
            </div>
        );
    };

    _rightCellRenderer = ({ key, index, style }) => {
        const { months } = this.state;
        const item = this._getRows()[index];
        let rowTotal = 0;
        months.forEach((m) => {
            const value = item[this._getDataKey()][m.format(this.dateFormat)] || 0;
            rowTotal += Number(String(value).replace(',', '.'));
        });
        return (
            <div key={key} className="cell" style={style}>
                <div style={{ height: ROW_HEIGHT }} className="right-cell main">
                    {this._renderCellContent(rowTotal, null, null, { nonEditable: true, isTotal: true })}
                </div>
            </div>
        );
    };

    _getListHeight = () => {
        return Math.min(LIST_HEIGHT, this._getRows().length * ROW_HEIGHT);
    };

    _renderLeftTable = (scrollTop, onScroll) => {
        return (
            <div className="static-table-container">
                <VirtualizedList
                    overscanRowCount={this.overscanRowCount}
                    overscanColumnCount={this.overscanColumnCount}
                    // isScrollingOptOut={true}
                    scrollTop={scrollTop}
                    onScroll={onScroll}
                    className="no-outline no-scroll-bar left-table"
                    style={{
                        borderLeft: '1px solid #eee',
                    }}
                    width={LEFT_TABLE_WIDTH}
                    height={this._getListHeight()}
                    rowCount={this._getRows().length}
                    rowHeight={ROW_HEIGHT}
                    rowRenderer={this._leftCellRenderer}
                />
            </div>
        );
    };

    _renderRightTable = (scrollTop, onScroll) => {
        const { verticalScrollBarWidth } = this.state;
        return (
            <div className="static-table-container">
                <VirtualizedList
                    overscanRowCount={this.overscanRowCount}
                    overscanColumnCount={this.overscanColumnCount}
                    scrollTop={scrollTop}
                    onScroll={onScroll}
                    // isScrollingOptOut={true}
                    className="no-outline right-table"
                    style={{
                        borderLeft: '1px solid #eee',
                        marginLeft: -verticalScrollBarWidth,
                    }}
                    width={RIGHT_TABLE_WIDTH}
                    height={this._getListHeight()}
                    rowCount={this._getRows().length}
                    rowHeight={ROW_HEIGHT}
                    rowRenderer={this._rightCellRenderer}
                />
            </div>
        );
    };

    onScrollbarPresenceChange = ({ size, horizontal, vertical }) => {
        const horizontalScrollBarWidth = horizontal ? size : this.state.horizontalScrollBarWidth;
        const verticalScrollBarWidth = vertical ? size : this.state.verticalScrollBarWidth;
        this.setState({
            horizontalScrollBarWidth,
            verticalScrollBarWidth,
        });
    };

    _renderGrid = (scrollTop, scrollLeft, onScroll) => {
        const { onlyRead, isEstimatedCosts } = this.props;
        const { verticalScrollBarWidth, horizontalScrollBarWidth, data, months, unlockMonths } = this.state;
        return (
            <div className="grid">
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <VirtualizedGrid
                            overscanColumnCount={this.overscanColumnCount}
                            overscanRowCount={this.overscanRowCount}
                            ref={this.grid}
                            cellRenderer={this._gridCellRenderer}
                            columnCount={months.length}
                            className={`no-outline main-grid ${!onlyRead && !isEstimatedCosts && 'no-scroll-bar'}`}
                            scrollTop={scrollTop}
                            scrollLeft={scrollLeft}
                            unlockMonths={unlockMonths}
                            // isScrollingOptOut={true}
                            onScrollbarPresenceChange={this.onScrollbarPresenceChange}
                            onScroll={onScroll}
                            columnWidth={Math.max(COLUMN_WIDTH, (width - verticalScrollBarWidth) / months.length)}
                            height={this._getListHeight() + ((!onlyRead && !isEstimatedCosts) ? 0 : horizontalScrollBarWidth)}
                            rowCount={this._getRows().length}
                            rowHeight={ROW_HEIGHT}
                            width={width}
                        />
                    )}
                </AutoSizer>
            </div>
        );
    };

    toggleShowRows = () => {
        if (!this.state.data.rows) return;
        this.setState({ showRows: !this.state.showRows });
    };

    _toggleEditMode = () => {
        const showRows = this.state.editMode ? this.state.showRows : !!this.state.data.rows;
        this.setState({
            editMode: !this.state.editMode,
            showRows,
        });
    };

    _cancel = () => {
        this.tableHeader.current && this.tableHeader.current.resetSplitValue();
        const data = this._savedData;
        this.setState({
            data,
            months: this._getMonthsFromData(data),
            editMode: false,
            errors: {},
            unlockMonths: false,
        });
    };

    _finishSave = (afterSync = false, data) => {
        const { endpoint, enqueueSnackbar } = this.props;
        this.setState({
            editMode: false,
            errors: {},
            unlockMonths: false,
        });
        DataHandler.put({ url: endpoint }, data)
            .done((response) => {
                if (response.error == 'stale') {
                    enqueueSnackbar(this.tr("Some scheduled invoices couldn't be saved. This is possibly due to someone editing them in another view. Please check the finances tab."), {
                        variant: 'error',
                    });
                } else {
                    !afterSync && enqueueSnackbar(this.tr('Changes saved successfully!'), { variant: 'success' });
                }
                setTimeout(() => {
                    this._getData();
                }, 1000);
            })
            .fail(() => {
                enqueueSnackbar(this.tr('Saving changes failed!'), { variant: 'error' });
                setTimeout(() => {
                    this._getData();
                }, 1000);
            });
    };

    _save = (afterSync = false) => {
        const { enqueueSnackbar, descriptionText, fieldKey, settings, setRevenueRecognitionData, setScheduledInvoiceData, showSyncConfirmation } = this.props;
        this.tableHeader.current && this.tableHeader.current.resetSplitValue();
        let data = this.state.data;
        if (descriptionText) {
            data = {
                ...data,
                descriptionText,
            };
        }
        if (fieldKey == 'revenue_recognition') {
            if (settings.revenue_recognition_equal_project_value == 1) {
                const difference = this._getSummaryValue({ difference: true, value: 0 });
                if (difference != 0 && this.getTableTotal() != 0) {
                    enqueueSnackbar(this.tr('Recognized value must be equal to project value!'), { variant: 'error' });
                    return;
                }
            }

            if (settings.revenue_recognition_sync_scheduled_invoices == 1 && !afterSync) {
                showSyncConfirmation(
                    () => {
                        setRevenueRecognitionData(data, true);
                        this._finishSave(afterSync, data);
                    },
                    () => this._finishSave(afterSync, data)
                );
                return;
            }
        } else if (fieldKey == 'revenue_recognition_scheduled') {
            if (settings.revenue_recognition_sync_scheduled_invoices == 1 && !afterSync) {
                showSyncConfirmation(
                    () => {
                        setScheduledInvoiceData(data);
                        this._finishSave(afterSync, data);
                    },
                    () => this._finishSave(afterSync, data),
                    true
                );
                return;
            }
        } else if (fieldKey == 'revenue_recognition_estimated_costs') {
            //console.log('save estimate costs');
        }

        this._finishSave(afterSync, data);
    };

    updateProjectValue = (projectValue) => {
        const { enqueueSnackbar } = this.props;
        let url = `projects/${this.props.project.id}/revenue_recognition_project_value`;

        this.setState({
            editProjectValue: false
        });
        DataHandler.put({ url: url }, {project_value: projectValue})
        .done((response) => {
            if (response.simple_costest_updated == true) {
                enqueueSnackbar(this.tr('Project value updated!'), { variant: 'success' });
            }
            setTimeout(() => {
                if (response.simple_costest_updated == true) {
                    window.dispatchEvent(new Event('projectValueUpdated'));
                }
            }, 1000);
        })
        .fail(() => {
            enqueueSnackbar(this.tr('Project value update failed!'), { variant: 'error' });
            setTimeout(() => {
            }, 1000);
        });
    };

    splitAccordingToRevenueRecognition = (saveAfter = false) => {
        const { months } = this.state;
        const { useValue, enqueueSnackbar } = this.props;
        const { _revenueRecognitionData } = this;
        const data = cloneDeep(this.state.data);
        let notChanged = false;
        months.forEach((month) => {
            let value = 0;
            Object.keys(_revenueRecognitionData.totals).forEach((dateString) => {
                const momentDate = moment(dateString, this.totalDateFormat);
                if (month.isSame(momentDate, 'month')) {
                    value = _revenueRecognitionData.totals[dateString] || 0;
                }
            });
            const monthString = month.format(this.totalDateFormat);
            if (useValue) {
                if (data.totals[monthString]?.disabled != '1') {
                    if (value < 0) value = 0; //only positive scheduled invoices are allowed to be created
                    data.totals[monthString] = {
                        ...data.totals[monthString],
                        value,
                    };
                } else {
                    notChanged = true;
                }
            } else {
                data.totals[monthString] = value;
            }
        });
        if (notChanged) {
            enqueueSnackbar(this.tr('Some months were not changed, because they have multiple scheduled invoices. Edit scheduled invoices from the finances tab.'), { variant: 'info' });
        }
        const finalData = { data };
        this.setState(finalData, () => {
            if (saveAfter) {
                this._save(true);
            }
        });
    };

    splitEvenly = (value) => {
        const { enqueueSnackbar, useValue } = this.props;
        const months = this.props.projectMonths;
        const {
            functions: { presentCurrency },
        } = this.context;
        const {
            project: { companies_currency },
        } = this.props;
        let data = cloneDeep(this.state.data);
        const formatted = this._formatNumber(value || 0);
        const splitValue = (formatted * 100 - ((formatted * 100) % months.length)) / months.length;
        const splitted = splitValue / 100;
        const unallocated = (formatted * 100 - splitValue * months.length) / 100;
        if (unallocated) {
            enqueueSnackbar(this.tr('${amount} was left unallocated', { amount: presentCurrency(unallocated, companies_currency) }), { variant: 'info' });
        }
        let notChanged = false;
        let notChangedScheduled = false;
        months.forEach((month) => {
            const userTotal = this._getUserTotalForMonth(month.format(this.dateFormat));
            if ((userTotal > splitted) && (userTotal > 0) || (userTotal < splitted) && (splitted < 0)) {
                notChanged = true;
            } else {
                const monthString = month.format(this.totalDateFormat);
                if (useValue) {
                    if (data.totals[monthString]?.disabled != '1') {
                        data.totals[monthString] = {
                            ...data.totals[monthString],
                            value: splitted,
                        };
                    } else {
                        notChangedScheduled = true;
                    }
                } else {
                    data.totals[monthString] = splitted;
                }

                const activeUserRows = data.rows.filter(user => (user.delete === false || user.is_new === true) );
                // If changing the total amount for a month from positive to negative (or vice versa),
                // the users values for this month will be reset to zero:
                if (userTotal * splitted < 0 && data.rows) {
                    data.rows.forEach((row) => {
                        row.values[month.format(this.dateFormat)] = 0;
                    });
                } else if (activeUserRows.length == 1) {
                    data.rows.forEach((row) => {
                        if (row.sales_agent === true) {
                            row.values[month.format(this.dateFormat)] = data.totals[monthString];
                        }
                    });
                }
            }
        });
        if (notChanged) enqueueSnackbar(this.tr('Some months were not changed, because the current recognized value for users is higher than the new total.'), { variant: 'info' });
        if (notChangedScheduled) {
            enqueueSnackbar(this.tr('Some months were not changed, because they have multiple scheduled invoices. Edit scheduled invoices from the finances tab.'), { variant: 'info' });
        }
        const finalData = { data };
        this.setState(finalData);
    };

    getTableTotal = (data = this.state.data) => {
        const { months } = this.state;
        let total = 0;
        months.forEach((m) => {
            //the total Recognised Revenue does not change if only "-" is entered:
            if (data.totals[m.format(this.totalDateFormat)] != "-") 
                total += this._formatNumber(data.totals[m.format(this.totalDateFormat)]);
        });
        return Math.round(total * 100) / 100;
    };

    _getSummaryValue = (summary, tableData = this.state.data) => {
        const { summaries, extraRows = [] } = this.props;
        let value = summary.value;
        if (summary.isTableTotal) {
            value = this.getTableTotal(tableData);
        }
        if (summary.isExtraRowTotal) {
            const extraTotal = extraRows.reduce((prev, v) => prev + Object.values(v.data.totals).reduce((prev, v) => prev + this._formatNumber(v), 0), 0);
            value = extraTotal;
        }
        if (summary.difference) {
            const tableTotal = this.getTableTotal(tableData);
            const extraTotal = extraRows.reduce((prev, v) => prev + Object.values(v.data.totals).reduce((prev, v) => prev + this._formatNumber(v), 0), 0);
            const total = (summaries.find((s) => s.isTotal) || { value: 0 }).value;
            value = total - tableTotal - extraTotal;
        }
        return Math.round(value * 100) / 100;
    };

    _openNoteDrawer = () => {
        this._noteDrawer.current && this._noteDrawer.current.open();
    };

    _renderTopBar = () => {
        const {
            functions: { presentCurrency },
        } = this.context;
        const {
            project: { companies_currency },
        } = this.props;
        const { editMode, selectedViewMode, loading, noteData } = this.state;
        const { title, summaries = [], onlyRead, showNoteDrawer, hideViewModes, settings, project, fieldKey } = this.props;
        return (
            <div className={`header ${loading ? 'loading' : ''}`}>
                <div className="left">
                    <h2>{title}</h2>
                    {summaries.map((s) => {
                        if (s.hidden) return null;
                        const value = this._getSummaryValue(s);
                        if (s?.isTotal && this.state.editProjectValue) {
                            return (
                                <div className="summary">
                                    <p data-testid={`rr_table_${fieldKey}_summary_${s.key}`}>
                                    {s.title}: <input
                                            autoFocus
                                            onFocus={(e) => e.target.select()}
                                            onChange={(e) => {this.setState({projectValue: e.target.value});}}
                                            //disabled={!editMode || nonEditable}
                                            className={'project_value'}
                                            style={{width: presentCurrency(value, companies_currency).length+'ch'}}
                                            onBlur={(e) => this.updateProjectValue(e.target.value)}
                                            value={this.state.projectValue !== undefined ? this.state.projectValue : ""}
                                            data-testid={`rr_table_${fieldKey}_summary_${s.key}_input`}
                                        />
                                    </p>
                                </div>
                            );
                        } else {
                            return (
                                <div className="summary">
                                    <p data-testid={`rr_table_${fieldKey}_summary_${s.key}`}>
                                        {s.title}: <strong style={(s?.isTotal && project.can_edit_quote) ? {cursor: 'pointer'} : {}} onClick={(s?.isTotal && project.can_edit_quote) ? () => {this.setState({editProjectValue: true, projectValue: s.value});} : null} className={value < 0 && 'red'}>{presentCurrency((s.isTotal && this.state.projectValue) ? this.state.projectValue : value, companies_currency)}</strong>
                                    </p>
                                </div>
                            );
                        }
                        
                    })}
                    {showNoteDrawer && noteData && (
                        <Tooltip placement="top" title={this.tr('Note to revenue recognition report')}>
                            <Button onClick={this._openNoteDrawer} className="note-btn" data-testid={`rr_table_${fieldKey}_edit_note`}>
                                <DescriptionOutlined />
                                {noteData.creator_id ? this.tr('Edit note') : this.tr('Add note')}
                            </Button>
                        </Tooltip>
                    )}
                    {settings.revenue_recognition_month_lock_day > 0 && !(null == this.context.privileges.admin) && this.context.privileges.admin.admin.indexOf(Number(this.props.project.companies_id)) > -1 && this.props.lockDate && editMode && (
                        <Tooltip placement="top" title={this.tr('Unlock previous months')}>
                            <Button onClick={() => {this.setState({unlockMonths: !this.state.unlockMonths});}} className="unlock-btn" data-testid={`rr_table_${fieldKey}_unlock_button`}>
                                {this.state.unlockMonths ? (<UnLockIcon />) : (<LockIcon />)}
                                {this.state.unlockMonths ? this.tr('Lock') : this.tr('Unlock')}
                            </Button>
                        </Tooltip>
                    )}
                    {loading && <img src={require('../dashboard/insights/img/loading.svg').default} />}
                </div>
                <div className="right">
                    {this.props.customViewModes && <InsightDropDown title={this.tr('View')} tabs={this.props.customViewModes} selected={this.props.selectedCustomViewMode} />}
                    {this.state.showRows && !onlyRead && !hideViewModes && <InsightDropDown title={this.tr('View')} tabs={this._viewModes} selected={selectedViewMode} />}
                    {!onlyRead &&
                        (editMode ? (
                            <div className="edit-btns">
                                <Button onClick={this._cancel} className="cancel edit-btn" data-testid={`rr_table_${fieldKey}_cancel_button`}>
                                    {this.tr('Cancel')}
                                </Button>
                                <Button disabled={this._hasErrors()} className="edit-btn" onClick={() => this._save()} data-testid={`rr_table_${fieldKey}_save_button`}>
                                    {this.tr('Save')}
                                </Button>
                            </div>
                        ) : (
                            <div className="edit-btns">
                                <Button className="edit-btn" onClick={this._toggleEditMode} data-testid={`rr_table_${fieldKey}_edit_button`}>
                                    <Edit className="icon" />
                                    {this.tr('Edit')}
                                </Button>
                            </div>
                        ))}
                </div>
            </div>
        );
    };

    _getAddUserItems = () => {
        const { teams, users } = this.props;
        const { userObject } = this.context;
        const items = [
            {
                label: this.tr('Add project members'),
                type: 'project_team',
            },
            {
                icon: Person,
                type: 'header',
                label: this.tr('Team'),
                name: 'team',
            },
            ...(teams || []).map((t) => ({
                ...t,
                label: t.name,
                headerName: 'team',
                type: 'team',
            })),
            {
                icon: Person,
                type: 'header',
                label: this.tr('User'),
                name: 'user',
            },
            ...(users || []).filter(this._filterOutAlreadySelectedUsers).map((u) => ({ ...u, headerName: 'user', type: 'user' })),
        ];

        if (this._getRows().findIndex((du) => du.users_id == userObject.usersId) == -1) {
            items.unshift({
                label: this.tr('Add me'),
                type: 'own_user',
            });
        }

        return items;
    };

    setDatePickerData = (anchor, date, index) => {
        this.setState({
            datePickerData: {
                anchor,
                date,
                index,
            },
        });
    };

    _setDate = (date, monthIndex, toAllMonths) => {
        const months = [...this.state.months];
        const data = cloneDeep(this.state.data);
        const totals = data.totals;
        if (!toAllMonths) {
            months[monthIndex] = date;
            const foundIndex = Object.keys(totals).findIndex((d) => moment(d, this.totalDateFormat).isSame(date, 'month'));
            if (foundIndex != -1) {
                const oldKey = Object.keys(totals)[foundIndex];
                const value = totals[oldKey];
                delete totals[oldKey];
                totals[date.format(this.totalDateFormat)] = value;
            } else {
                totals[date.format(this.totalDateFormat)] = 0;
            }
        } else {
            months.forEach((month, i) => {
                const newMonth = moment(month).set('date', date.date());
                months[i] = newMonth;
                const foundIndex = Object.keys(totals).findIndex((d) => moment(d, this.totalDateFormat).isSame(newMonth, 'month'));
                if (foundIndex != -1) {
                    const oldKey = Object.keys(totals)[foundIndex];
                    const value = totals[oldKey];
                    delete totals[oldKey];
                    totals[newMonth.format(this.totalDateFormat)] = value;
                } else {
                    totals[newMonth.format(this.totalDateFormat)] = 0;
                }
            });
        }
        this.setState({
            months,
            data: {
                ...data,
                totals,
            },
        });
    };

    _renderDatePicker = () => {
        const { datePickerData } = this.state;
        if (!datePickerData) return null;
        const { anchor, date, index } = datePickerData;
        const dateRange = {
            startDate: date ? date.toDate() : new Date(),
            endDate: date ? date.toDate() : new Date(),
            key: 'selection',
        };
        return (
            <Popover
                open={Boolean(anchor)}
                elevation={4}
                anchorEl={anchor}
                onClose={() => this.setState({ datePickerData: null })}
                className="revenue-recognition-date-picker"
                anchorOrigin={{
                    vertical: 48,
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MyDayDateRangeModal
                    initialDateRange={dateRange}
                    noMonthChange={true}
                    mode={'date'}
                    saveChanges={(dateRange) => {
                        this.setState({ datePickerData: null });
                        this._setDate(moment(dateRange.startDate), index, this.state.changeDateForAllMonths);
                    }}
                />
                <div style={this._dateToAllStyle}>
                    <Checkbox
                        checked={this.state.changeDateForAllMonths}
                        onChange={() =>
                            this.setState({
                                changeDateForAllMonths: !this.state.changeDateForAllMonths,
                            })
                        }
                    />
                    <p className="add-to-all-desc">{this.tr('Add same creation date to all months')}</p>
                </div>
            </Popover>
        );
    };

    _filterOutAlreadySelectedUsers = (u) => {
        return this._getRows().findIndex((du) => du.users_id == u.users_id) == -1;
    };

    _addUsers = (e) => {
        const { users, project } = this.props;
        const { userObject } = this.context;
        let selectedUsers = [];
        switch (e.type) {
            case 'team':
                selectedUsers = (e.users || []).map((id) => users.find((fu) => fu.users_id == id));
                break;
            case 'project_team':
                selectedUsers = project.team_members;
                break;
            case 'own_user':
                const user = users.find((u) => u.users_id == userObject.usersId);
                if (user) selectedUsers = [user];
                break;
            default:
                selectedUsers = [e];
                break;
        }
        selectedUsers = selectedUsers.filter(this._filterOutAlreadySelectedUsers);
        const data = cloneDeep(this.state.data);
        const rows = [
            ...data.rows,
            ...selectedUsers.map((u) => ({
                ...u,
                values: {},
                name: u.label,
                is_new: true,
                title: u.role,
            })),
        ];
        this.setState({
            data: {
                ...data,
                rows,
            },
        });
    };

    _addRow = () => {
        const data = cloneDeep(this.state.data);
        let id = -1;
        data.rows.forEach((row) => {
            if (Number(row.id) <= id) {
                id--;
            }
        });
        const rows = [
            ...data.rows,
            {
                id,
                estimated_costs_headers_id: -1,
                title: '',
                values: {},
            },
        ];
        this.setState({
            data: {
                ...data,
                rows,
            },
        });
    };

    _getRows = () => {
        return (this.state.data.rows || []).filter((r) => !r.delete);
    };

    _saveNote = (note) => {
        const { enqueueSnackbar } = this.props;
        DataHandler.put({ url: `projects/${this.props.project.id}/revenue_recognition_note` }, { note })
            .done(() => {
                enqueueSnackbar(this.tr('Note saved successfully!'), { variant: 'success' });
                setTimeout(() => {
                    this._getNoteData();
                }, 1000);
            })
            .fail(() => {
                enqueueSnackbar(this.tr('Saving note failed!'), { variant: 'error' });
                setTimeout(() => {
                    this._getNoteData();
                }, 1000);
            });
    };

    renderAddRowComponent = () => {
        switch (this.props.fieldKey) {
            case 'revenue_recognition':
                return (
                    <div className="add-member">
                        <DataList dropLabel={this.tr('Add user(s)')} options={this._getAddUserItems()} onChange={this._addUsers} className="full" hasHeaders={true} virtualized={true} />
                    </div>
                );
            case 'revenue_recognition_estimated_costs':
                return (
                    <>
                        <button className="add-row-button" onClick={this._addRow}>
                            <AddCircleOutline />
                            {this.tr('Add cost')}
                        </button>
                        {this.props.project.rights.includes("purchase_order_write") && <button className="add-row-button" onClick={(e) => this.context.functions.updateView({ module: "purchaseorder", action: "view", companies_id: this.props.project.companies_id, projects_id: this.props.project.id }, e.ctrlKey || e.metaKey || e.button === 1)}>
                            <AddCircleOutline />
                            {this.tr('Add purchase order')}
                        </button>}
                    </>
                );
            default:
                return null;
        }
    };

    render() {
        const { horizontalScrollBarWidth, verticalScrollBarWidth, showRows, data, editMode, errors, months, loading, noteData, sliderProps, sliderColumns, unlockMonths } = this.state;
        const { cellTitle, nonEditableHeader, onlyRead, project, showNoteDrawer, noFooter, isEstimatedCosts, onScroll, scrollLeft, scrollTop, lockDate, checkMonthLock, settings, fieldKey } = this.props;
        const { userObject } = this.context;

        const commonTableProps = {
            formatInputValue: this._formatInputValue,
            getUnallocatedValueForMonth: this.getUnallocatedValueForMonth,
            data,
            rowHeight: ROW_HEIGHT,
            headerHeight: HEADER_HEIGHT,
            dateFormat: this.totalDateFormat,
            setTotal: this.setTotal,
            editMode,
            leftTableWidth: LEFT_TABLE_WIDTH,
            rightTableWidth: RIGHT_TABLE_WIDTH,
            columnWidth: COLUMN_WIDTH,
            months,
            horizontalScrollBarWidth,
            verticalScrollBarWidth,
            showRows,
            isEstimatedCosts,
        };

        const noteDrawerProps = {
            title: this.tr('Note to revenue recognition report'),
            noteProps: {
                onCtrlS: this._saveNote,
            },
            noteData: [
                {
                    project_id: project.id,
                    mainHeader: `${project.name} (${project.project_id})`,
                    subHeader: project.account.name,
                    ...noteData,
                    created_date: moment(noteData?.created_date).format(userObject.dateFormat),
                    edited_date: moment(noteData?.edited_date).format(userObject.dateFormat),
                },
            ],
        };

        return (
            <div className="revenue-recognition-table">
                {this._renderTopBar()}
                <div className={loading && 'loading'}>
                    <RevenueRecognitionTableHeader
                        {...this.props}
                        {...commonTableProps}
                        ref={this.tableHeader}
                        title={cellTitle}
                        errors={errors}
                        lockDate={lockDate}
                        checkMonthLock={checkMonthLock}
                        unlockMonths={unlockMonths}
                        settings={settings}
                        getCurrencySymbol={this._getCurrencySymbol}
                        onScrollbarPresenceChange={this.onScrollbarPresenceChange}
                        noEdit={nonEditableHeader}
                        onKeyDown={this.handleKeyDown}
                        formatDecimals={this.formatDecimals}
                        isInputValueInvalid={this.isInputValueInvalid}
                        onSplitEvenly={this.splitEvenly}
                        onSplitAccordingToRevenueRecognition={this.splitAccordingToRevenueRecognition}
                        setTotal={this.setTotal}
                        toggleShowRows={this.toggleShowRows}
                        setDatePickerData={this.setDatePickerData}
                        onScroll={onScroll}
                        scrollLeft={scrollLeft}
                        fieldKey={fieldKey}
                    />
                    {showRows && data.rows && (
                        <div>
                            <div onMouseLeave={() => this._setHoverIndex(null)} className="content">
                                {this._renderLeftTable(scrollTop, onScroll)}
                                {this._renderGrid(scrollTop, scrollLeft, onScroll)}
                                {this._renderRightTable(scrollTop, onScroll)}
                            </div>
                            {!onlyRead && !noFooter && <RevenueRecognitionTableFooter {...this.props} {...commonTableProps} onScroll={onScroll} scrollLeft={scrollLeft} />}
                        </div>
                    )}
                </div>
                {this._renderDatePicker()}
                {showRows && editMode && !onlyRead && data.rows && this.renderAddRowComponent()}
                {showNoteDrawer && <NoteDrawer ref={this._noteDrawer} {...noteDrawerProps} />}
                <InsightSlider
					onClose={this.onCloseSlider}
					open={sliderProps !== false}
					columns={sliderColumns}
					{...sliderProps} />
            </div>
        );
    }
}

export default withSnackbar(RevenueRecognitionTable);
