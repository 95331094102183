import React from 'react';
import Button from '@mui/material/Button';
import styles from './ButtonSelector.module.scss';
// import ButtonGroup from '@mui/material/ButtonGroup';

interface ButtonSelectorProps<T> {
    onClick: (param: T) => void;
    value?: T;
    options: T[];
    buttonClassName: string;
}

type ButtonVariant = "contained" | "outlined" | "text" | undefined;

class ButtonSelector<T> extends React.Component<ButtonSelectorProps<T>> {
    static defaultProps: ButtonSelectorProps<number> = {
        onClick: (n: number) => {},
        options: [0],
        buttonClassName: ""
    };

    render() {
        const {
            options,
            buttonClassName,
            onClick
        }: {
            options: T[];
            buttonClassName: string;
            onClick: (param: T) => void;
        } = this.props;

        const value: T 
            | undefined = this.props.value;
        const className = [styles.button, buttonClassName].join(" ");

        return (
            <>
                {options.map((option: T) => {
                    const variant: ButtonVariant = value === option
                        ? "contained"
                        : "outlined";

                    return <Button 
                        className={className} 
                        variant={variant}
                        onClick={() => onClick(option)}
                        >{String(option)}</Button>
                })}
            </>
        );
    }
}

export default ButtonSelector;
