import React, { Component } from 'react';
import cn from 'classnames';
import './UserList.css';
import { Avatar, ClickAwayListener, FilledInput, IconButton, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { CheckCircleOutline, AddCircleOutline, PlusOne } from '@mui/icons-material';
import { filterByText } from '../helpers';
import TaimerAvatar from '../general/TaimerAvatar';
import colors from '../colors';

const styles = theme => ({
    searchInput: {
        padding: '10px 18px 10px 25px'
	},
	addBtn: {
		color: colors.greenish_cyan,
	},
	moreAvatar: {
		width: '35px',
		height: '35px',
	},
});

class UserListUser extends Component {
	onHide = () => {
		this.props.onHide(this.props.item);
	}

	render() {
        const { item, item: { avatar } } = this.props;

		return (
			<TaimerAvatar 
				onClick={this.props.onClick} 
				className={cn("UserListUser", "selectedUser")} 
				id={item.id} 
				name={item.name}
				color={item.color}
				size="userlist" />
		);
	}
}

class UserListPopupUser extends Component {
	static defaultProps = {
		canAdd: false,
		canRemove: false,
	}
	
	add = () => {
		this.props.onAdd && this.props.onAdd(this.props.item);
	}

	remove = () => {
		this.props.onRemove && this.props.onRemove(this.props.item);
	}

	renderButton = () => {
		const { classes, canAdd, canRemove, item } = this.props;
		const removeAllowed = canRemove && !item.from_settings_team;
		
		return <div className="button">
			{!canAdd && !removeAllowed && <CheckCircleOutline classes={{root: classes.addBtn}}/>}
			{canAdd && <IconButton onClick={this.add} size="large"><AddCircleOutline/></IconButton>}
			{removeAllowed && <IconButton onClick={this.remove} size="large"><CheckCircleOutline classes={{root: classes.addBtn}} /></IconButton>}
		</div>
	}

	render() {
        const { item, tr } = this.props;

		return (
            <li>
				<div>
					<TaimerAvatar 
						className={cn("UserListUser", "selectedUser")} 
						id={item.id} 
						name={item.name}
						color={item.color}
						size="userlist" />
					<div className="UserInfo">
						<div className="UserName">{item.name}</div>
						<div className="UserTitle">{item.title}</div>
					</div>
				</div>
				{item.from_settings_team && <Tooltip
                    title={tr('User cannot be removed: Is added to the project team through a team.')}
                    classes={{ tooltip: 'darkblue-tooltip' }}
                >
					{this.renderButton()}
				</Tooltip>}
				{!item.from_settings_team && this.renderButton()}
			</li>
        );
	}
}

class UserList extends Component {
	static defaultProps = {
        maxToShow: 4
	}
	
	state = {
		popupVisible: false,
		searchValue: '',
	}
	
	showPopup = () => {
		const { popupVisible } = this.state;

		if (popupVisible)
			return;

		this.setState({popupVisible: true});
	}

	hidePopup = () => {
		this.setState({popupVisible: false});
	}

	inputChange = (e) => {
        this.setState({searchValue: e.target.value});
    }
    
	render() {
		const { classes, users, addableUsers, maxToShow, hideFilter, editable, canAdd, tr } = this.props;
		const { popupVisible, searchValue } = this.state;

		const usersToShow = users.length === maxToShow ? users : users.slice(0, maxToShow - 1);

		const showAdditional = users.length - usersToShow.length;

		const usersFiltered = searchValue ? filterByText(users, searchValue) : users;
		const additionalFiltered = addableUsers && searchValue ? 
			filterByText(addableUsers, searchValue) : addableUsers

		return (<React.Fragment>
			<div>
		{popupVisible && <ClickAwayListener onClickAway={this.hidePopup}>
					<div className="UserListPopup">
						{!hideFilter && <div>
							<FilledInput
								value={searchValue}
								fullWidth 
								placeholder="Search or add user" 
								classes={{input: classes.searchInput}}
								onChange={this.inputChange} />
						</div>}
						<ul>
							{usersFiltered.map((item, index) => <UserListPopupUser 
								classes={classes} 
								key={item.id} 
								item={item}
								canRemove={editable}
								tr={tr}
								onRemove={this.props.onRemove} />)}
							{(editable || canAdd) && additionalFiltered && additionalFiltered.map((item, index) => <UserListPopupUser 
								classes={classes} 
								key={item.id} 
								item={item}
								canAdd={editable || canAdd}
								tr={tr}
								onAdd={this.props.onAdd} />)}
						</ul>
					</div>
				</ClickAwayListener>}
			</div>
			<div className="UserList">
				{usersToShow.map((item, index) => <UserListUser onClick={this.showPopup} key={item.id} item={item} />)}
				{showAdditional > 0 && <Avatar classes={{root: classes.moreAvatar}} onClick={this.showPopup} className="UserListUser moreUsers">+{showAdditional}</Avatar>}
				{(editable || canAdd) && <Avatar data-testid="add_team_chat_user" classes={{root: classes.moreAvatar}} onClick={this.props.showAdd || this.showPopup} className="UserListUser addUser">+</Avatar>}
			</div>
		</React.Fragment>);
	}
}

export default withStyles(styles)(UserList);