import React, { Component } from 'react';
import TaimerComponent from '../TaimerComponent';
import DialogBorder from './DialogBorder';
import DialogSection from './DialogSection';
import { DialogDropDownButton, DialogDropDownButtonItem } from './DialogDropDownButton';

import { SettingsContext } from '../SettingsContext';
import DataHandler from '../general/DataHandler';
import DataList from './../general/DataList';
import './MoveTasksUsersDialog.css';

export default class MoveTasksUsersDialog extends TaimerComponent {

    static contextType = SettingsContext;

	constructor(props, context) {
		super(props, context, "dialogs/MoveTasksUsersDialog");

        const tasks = this.props.data.tasks.map(x => x.resource).filter(el => el.type == 'task');

        const userIds = [];
        tasks.forEach(t => {
            if(t.user_ids) {
                t.user_ids.forEach(u => {
                    userIds.push(u);
                });
            }
        });

        const fromUsers = this.props.data.employees.filter(e => {
            return userIds.indexOf(e.id) > -1;
        });

        const toUsers = this.props.data.employees;

        this.state = {
            fromUser: fromUsers.length === 1 ? fromUsers[0] : {},
            toUser: {},
            fromUsers: fromUsers,
            toUsers: toUsers,
            tasks: tasks
        };

    }

    onSave = () => {
        const data = {
            ids: this.state.tasks.map(el => el.id.split('-')[1]),
            from_user: this.state.fromUser.id,
            to_user: this.state.toUser.id,
        };
        const url = 'resourcing/move_tasks_user';
        DataHandler.post({url: url}, data).done(() => {
            this.props.onSave();
        });
    }

    render() {
        const { fromUsers } = this.state;

        return (
            <DialogBorder
                title={this.props.data.single ? this.tr("Move Chosen Task") : this.tr("Move Chosen Tasks")}
                onClose={this.props.onClose}
                onSave={this.onSave}
                disableSave={(fromUsers.length && (!this.state.fromUser || !this.state.fromUser.id)) || !this.state.toUser || this.state.fromUser.id == this.state.toUser.id}
                className="TMRDialogGrid TMRDialogInputs MoveTasksUsersDialog">

                <DialogSection>

                    {fromUsers.length > 0 && <DataList
                        dropLabel={this.props.data.single ? this.tr("Move Task from User") : this.tr("Move Tasks from User")}
                        className="full"
                        options={this.state.fromUsers}
                        onChange={(d) => this.setState({fromUser: d, toUsers: this.props.data.employees.filter(el => el.id != d.id)})}
                        value={this.state.fromUser}
                        openMenuOnFocus={true} />}
                    <DataList
                        dropLabel={this.tr("To User")}
                        className="full"
                        options={this.state.toUsers}
                        onChange={(d) => this.setState({toUser: d})}
                        value={this.state.toUser}
                        openMenuOnFocus={true} />

                </DialogSection>

            </DialogBorder>
        )
    }
}