function intersection(a = [], b = []) {
    const map = {};

    (a.length < b.length ? b : a).forEach(n => map[n] = true);

    return (a.length < b.length ? a : b).filter(n => map[n]);
}

function union(a = [], b = []) {
    const map     = {};
    const nUnique = a.concat(b);

    nUnique.forEach(n => map[n] = true);

    return nUnique.filter(n => map[n]);
}

function difference(a = [], b = []) {
    const map = {};

    intersection(a, b).forEach(n => map[n] = true);

    return a.filter(n => !map[n]);
}

export {
    intersection,
    union,
    difference
};

