import React from 'react';
import ListCell from "./../ListCell";
// import DatePicker from "./../../general/DatePicker";
import { DatePicker } from './../../general/react-date-range/src';
import { format, isValid } from "date-fns";
import './../../general/react-date-range/src/styles.css';
import './../../general/react-date-range/src/theme/default.css';


import { SettingsContext } from './../../SettingsContext';
import { data } from 'jquery';
import ClickAwayWrapper from '../../general/ClickAwayWrapper';

class DateCell extends React.Component {

	static contextType = SettingsContext;

	static defaultProps = {
		onEdited: () => {},
		onEdit: () => {},
		showErrorBorder: false,
		openOnEnterEditMode: true,
		minDate: undefined,
		maxDate: undefined,
        datePickerProps: {}
	};

	constructor(props) {
		super(props);

		this.datePicker = React.createRef();
		this.listCell 	= React.createRef();

        this.state = { date: props.value, editable: props.editable, editMode: false };

        this.handleSelect 	 = this.handleSelect.bind(this);
        this.handleInput 	 = this.handleInput.bind(this);
        this.focusOnEditor 	 = this.focusOnEditor.bind(this);
	}

    handleSelect(date) {
    	date = format(date, 'YYYY-MM-DD');

		const value = this.props.onEdited.length === 1 
			? [date] 
			: [this.props.name, date];

        // Wau.
        
        if(typeof(this.props.onEdited) === "function") {
            this.props.onEdited(...value);
        }

        if(typeof(this.props.onEdit) === "function") {
            this.props.onEdit(...value);
        }

		this.listCell.current.closeEdit();
	}

	handleInput(evt, date) {
		date = format(date, 'YYYY-MM-DD');

		const value = this.props.onEdited.length === 1 
			? [date] 
			: [this.props.name, date];

		this.props.onEdited(...value);
		this.props.onEdit(...value);

		if(this.props.closeOnComplete) {
			this.listCell.current.closeEdit();
        }
	}

	setEditMode = () => {
		if(!this.props.openOnEnterEditMode) {
			return;
		}

		this.setState({editMode: true}, () => {
			this.datePicker.current && this.datePicker.current.setState({open: true});
		})
	}

	focusOnEditor() {
		if (!this.datePicker.current.calendar) 
			this.datePicker.current.focusOnInput();
	}

	onClickAway = () => this.listCell.current.isInEditMode() && this.listCell.current.closeEdit();

	render() {
		const { editMode } 	 = this.state;
		const { userObject } = this.context;
		const dateFormat 	 = this.props.dateFormat ? this.props.dateFormat : userObject.dateFormat;
		const ListCellType   = this.props.listCellType;
		const listCellProps  = Object.assign({
			ref: this.listCell,
			owner: this,
			width: this.props.width,
			value: (!isValid(this.props.value) || this.props.value == "1970-01-01" || this.props.value == "0000-00-00" || this.props.value == "0000-00-00 00:00:00") ? (this.props.showEmptyValue ? "0000-00-00" : "") : format(this.props.value, dateFormat.toUpperCase()),
			editable: this.props.editable,
			className: `${this.props.className} dateCell`,
			onEnterEditMode: this.setEditMode
		}, this.props.listCellProps);
		const { 
			showErrorBorder,
			minDate,
			maxDate,
            datePickerProps,
            popperProps
		} = this.props;

		const datePickerClassName = showErrorBorder
			? "errorBorder"
			: "";

		listCellProps.value = (listCellProps.value == "Invalid Date") ? "" : listCellProps.value;
		const datePickerValue = (this.props.value == "0000-00-00") ? new Date() : this.props.value;

        const cell = (
            <ListCellType {...listCellProps}>
                <DatePicker
                    mode="list"
					ref={this.datePicker}
					className={datePickerClassName}
					open={this.props.open}
                    initialOpen={this.listCell?.current?.state?.inEditMode || false}	
                    noMonthChange={this.props.noMonthChange}
                    date={datePickerValue}
                    dateFormat={dateFormat}
                    onChange={this.handleSelect}
                    onInputChange={this.handleInput}
                    onClose={this.onClickAway}
					minDate={minDate}
					maxDate={maxDate}
                    closeCalendarOnComplete={this.props.closeCalendarOnComplete}
					closeOnComplete={this.props.closeOnComplete}
					preventDefaultOnCalendarClick={true}
					usePopper={this.props.usePopper}
					popperBottom={this.props.popperBottom}
                    popperProps={popperProps}
                    inputOnEnter={(e) => {
                        this.props.onEnter(e);
                        this.listCell.current.closeEdit();
                    }} 
                    {...datePickerProps}
                />
            </ListCellType>
        );

        return cell;
	}
}

DateCell.defaultProps = {
	width: 100,
	value: "",
	editable: true,
	onEdited: () => {},
	onEnter: () => {},
	closeOnComplete: true,
	closeCalendarOnComplete: false,
	listCellProps: {},
	listCellType: ListCell,
	usePopper: false,
    popperProps: {}
};

export default DateCell;
