import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';

import { SettingsContext } from '../../SettingsContext';
import OutlinedField from "../../general/OutlinedField";
import { DatePicker } from '../../general/react-date-range/src';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { TextField, MenuItem } from '@mui/material';

import { format } from "date-fns";
import { withSnackbar } from 'notistack';

import './../../general/react-date-range/src/styles.css';
import './../../general/react-date-range/src/theme/default.css';
import DataHandler from '../../general/DataHandler';
import TaimerComponent from '../../TaimerComponent';

const styles = theme => ({
    topBar: {
        backgroundColor: '#2D9FF7',
        height: '8px',
        borderRadius: '4px 4px 0 0',
        marginBottom: '10px'
    },
    field: {
        marginBottom: "10px",
        width: "calc(100% - 14px)"
    },
    shrink: {
        fontSize: "14px"
    },
    input: {
        textAlign: 'right',
        paddingTop: '19px',
        paddingBottom: '19px',
        fontSize: "14px",
        paddingRight: "15px"
    },
    datePicker: {
        width: '100%',
        boxSizing: 'border-box',
        minHeight: '56px',
        margin: '8px 0 4px'
    }    
});

class TalenomCirculation extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/dialogs/TalenomCirculation");

        this.state = {
            selectedList: {id: 0, name: ""}
        }
    }

    handleCancel = () => {
        this.props.onClose();
    }

    handleSave = async () => {
        this.props.listSelected && this.props.listSelected(this.state.selectedList.id);
        this.props.onClose && this.props.onClose();
    }

    render() {
        const { classes, circulationList } = this.props;
        const { selectedList } = this.state;

        return (
            <Dialog
                open
                className="invoiceDialog"
                maxWidth="md"
                onClose={this.handleCancel}
                aria-labelledby="circulationLists" >
                <div className={classNames(classes.topBar)}/> 
                <DialogContent className="invoiceDialogContent">
                    <div>{this.tr("Select Talenom circulation list")}</div>
                    <OutlinedField 
                        fullWidth
                        select 
                        label={this.tr("Circulation lists")}
                        className="listFilterOutlinedField" 
                        value={selectedList.id}>
                        {
                            circulationList.map((opt) =>
                                <MenuItem value={opt.id} key={opt.id} onClick={() => this.setState({selectedList: opt})}>
                                    {opt.name}
                                </MenuItem>
                            )
                        }
                    </OutlinedField>
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="outlined" onClick={this.handleCancel} >
                        {this.tr('Cancel')}
                    </Button>
                    <Button size="large" variant="outlined" onClick={this.handleSave} color="primary">
                        {this.tr('Send bills')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

TalenomCirculation.defaultProps = {
    onDialogClose: () => {},
    circulationList: [],
}

export default withSnackbar(withStyles(styles)(TalenomCirculation));
