import { AddCircleOutline, Close, KeyboardArrowLeft, Warning, WarningRounded } from '@mui/icons-material';
import React from 'react';
import DataList from './DataList';
import LoaderButton from './LoaderButton';
import OutlinedField from './OutlinedField';
import { TaimerWizardAction, TaimerWizardField, WizardPageContentObject } from './TaimerWizard';
import Lottie from 'react-lottie';
import styles from './WizardPageContentRenderer.module.scss';
import { Tooltip } from '@mui/material';

interface WizardPageContentRendererProps {
    item?: any;
    content: WizardPageContentObject;
    loading?: boolean;
    onAction?: (action: TaimerWizardAction) => void;
    onFieldChanged?: (key: string, value: any) => void;
    onClose?: () => void;
    onBack?: () => void;
    showBackButton?: boolean;
    showCloseButton?: boolean;
    updateTriggers?: object;
    tr: (string: string) => string;
}

class WizardPageContentRenderer extends React.PureComponent<WizardPageContentRendererProps> {
    fieldRefs = {};
    actionButton: any = React.createRef();
    state = {
        openOptionalFields: {},
    };

    requiredFieldsNotFilled = (content: WizardPageContentObject) => {
        const { item } = this.props;
        if (!item) return false;
        const fields = content.fields || [];
        let notFilled = false;
        fields.forEach((field) => {
            if (field.required && !item[field.key]) {
                notFilled = true;
            }
        });
        return notFilled;
    };

    openOptionalField = (fieldKey) => {
        this.setState({
            openOptionalFields: {
                ...this.state.openOptionalFields,
                [fieldKey]: true,
            },
        });
    };

    handleKeyPress = (event) => {
        if(event.key == 'Enter' && this.props.content.enableEnter){
            event.preventDefault();
            this.actionButton.current && this.actionButton.current.click();
        }   
    }


    renderAction = (content: WizardPageContentObject) => {
        const action = content.action;
        if (!action) return null;
        const { loading, onAction } = this.props;
        return (
            <LoaderButton buttonProps={{ ref: this.actionButton }} classes={{ root: styles.pageAction }} disabled={this.requiredFieldsNotFilled(content)} loading={loading} onClick={() => onAction && onAction(action)} text={action.label} />
        );
    };

    renderSecondaryAction = (action?: TaimerWizardAction) => {
        if (!action) return null;
        const { onAction } = this.props;
        return (
            <button className={styles.secondaryPageAction} onClick={() => onAction && onAction(action)}>
                {action.label}
            </button>
        );
    };

    renderCustomContent = (content) => {
        return <div className={styles.pageCustomContent}>{content}</div>;
    };

    onFieldChanged = (field, value) => {
        const formattedValue = field.formatSelectValue ? field.formatSelectValue(value) : value;
        this.props.onFieldChanged && this.props.onFieldChanged(field.key, formattedValue);
    }

    renderField = (field: TaimerWizardField) => {
        const { item, onFieldChanged } = this.props;
        if (field.component) return field.component;
        switch (field.type) {
            case 'select':
                return (
                    <DataList
                        ref={(ref) => {
                            this.fieldRefs = {
                                ...this.fieldRefs,
                                [field.key]: ref,
                            };
                        }}
                        name={field.key}
                        value={field.getSelectValue ? field.getSelectValue(item, (field.options || [])) : item[field.key]}
                        options={field.options}
                        label={field.label}
                        onChange={(value) => this.onFieldChanged(field, value)}
                        menuPosition='fixed'
                    />
                );
            case 'text':
            case 'textarea':
                return (
                    <OutlinedField
                        ref={(ref) => {
                            this.fieldRefs = {
                                ...this.fieldRefs,
                                [field.key]: ref,
                            };
                        }}
                        name={field.key}
                        value={item[field.key]}
                        label={field.label}
                        required={field.required}
                        onFocus={field.onFocus}
                        onKeyUp={field.useOnKeyUp ? ({ target: { name, value } }) => onFieldChanged && onFieldChanged(name, value) : undefined}
                        onChange={({ target: { name, value } }) => onFieldChanged && onFieldChanged(name, value)}
                        multiline={field.type == 'textarea'}
                        placeholder={field.placeholder}
                        shrinkLabel={field.placeholder ? true : undefined}
                    />
                );
            case 'optionalFields': {
                if (this.state.openOptionalFields[field.key] && field.fields) {
                    return field.fields.map(this.renderField);
                } else {
                    return (
                        <button className={styles.optionalFieldButton} onClick={() => this.openOptionalField(field.key)}>
                            <AddCircleOutline />
                            <p>{field.label}</p>
                        </button>
                    );
                }
            }
            default:
                return null;
        }
    };

    renderFields = (fields?: TaimerWizardField[]) => {
        this.fieldRefs = {};
        if (!fields?.length) return null;
        return <div className={styles.pageFields}>{fields.map(this.renderField)}</div>;
    };

    render() {
        const { content, onClose, onBack, showBackButton, showCloseButton } = this.props;
        if (content.image && Object.keys(content).length == 1) {
            return <img src={content.image} />;
        }
        let animation;
        if (content.animation) {
            animation = (
                <div className={styles.animation}>
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: content.animation,
                        }}
                        speed={1}
                        height={'100%'}
                        width={'100%'}
                    />
                </div>
            );
        }
        if (content.animation && Object.keys(content).length == 1) {
            return animation;
        }
        return (
            <div className={`${styles.pageContent} ${styles[content.alignVertical || 'top']} ${styles[content.textColor || 'dark']}`} style={{ backgroundColor: content.backgroundColor || 'white' }} onKeyPress={this.handleKeyPress}>
                {showBackButton && (
                    <button className={`${styles.secondaryPageAction} ${styles.backButton}`} onClick={onBack}>
                        <KeyboardArrowLeft />
                        Back
                    </button>
                )}
                {showCloseButton && (
                    <button className={`${styles.secondaryPageAction} ${styles.skipButton}`} onClick={onClose}>
                        {<Close />}
                    </button>
                )}
                {content.showTestModeIndicator && (
                    <Tooltip title={this.props.tr("Data created in this view won't be saved")} classes={{ tooltip: 'darkblue-tooltip' }} arrow placement="left">
                        <div className={styles.testModeIndicator}>
                            <WarningRounded /> {this.props.tr('Test mode')}
                        </div>
                    </Tooltip>
                )}
                <div>
                    {content.image && <img src={content.image} />}
                    {content.animation && animation}
                    {typeof content.subheader == 'string' ? <h4>{content.subheader}</h4> : content.subheader}
                    <h1>{content.header}</h1>
                    {content.subtitle && <p className={styles.subtitle}>{content.subtitle}</p>}
                    {content.description && <p className={styles.description}>{content.description}</p>}
                    {content.customContent && this.renderCustomContent(content.customContent())}
                    {this.renderFields(content.fields)}
                    {this.renderAction(content)}
                    {this.renderSecondaryAction(content.secondaryAction)}
                </div>
            </div>
        );
    }
}

export default WizardPageContentRenderer;
