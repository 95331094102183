import React from 'react';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import TaimerComponent from  '../../TaimerComponent';
import RowImportTemplate from './import-examples/quote_row_import_example.xlsx';
import ImportQuoteRowsReview from './ImportQuoteRowsReview';
import ImportDialog from './ImportDialog';

/* other */
import { withSnackbar } from 'notistack';

class ImportQuoteRowsDialog extends TaimerComponent {

    static contextType = SettingsContext;
    constructor(props, context) {
        super(props, context, "dialogs/imports/ImportQuoteRowsDialog")

        this.psaFields = [
            { id: 0, name: "" },
            { id: 'row_type', name: this.tr("Row type") },
            { id: 'description', name: this.tr("Description") },
            { id: 'product_code', name: this.tr("Product code") },
            { id: 'amount', name: this.tr("Amount") },
            { id: 'unit', name: this.tr("Unit") },
            { id: 'unit_cost', name: this.tr("Unit cost") },
            { id: 'selling_price', name: this.tr("Selling price") },
            { id: 'discount_percentage', name: this.tr("Discount %") },
            { id: 'vat_percentage', name: this.tr("Vat %") },
            { id: 'type', name: this.tr("Type") },
        ];
    }
    
    componentDidMount() {
        super.componentDidMount();
    }

    getRowError = (e) => {
        let msg = "Unknown error";

        switch (e.error) {
            case "ROW_SAVING_FAILED":
                msg = this.tr("Error in saving row.");
                break;
            case "PRODUCT_NOT_FOUND":
                msg = this.tr("Product code was not found from PSA: ${code}.", { code: e.message });
                break;
            case "INVALID_NUMERIC_VALUE":
                msg = this.tr("Invalid numeric value: ${field}.", { field: this.tr(e.message) });
                break;
            case "INVALID_PERCENTAGE":
                msg = this.tr("Invalid percentage value: ${field}.", { field: this.tr(e.message) });
                break;
            case "INVALID_WORKTYPE":
                msg = this.tr("Invalid type.");
                break;
            case "INVALID_ROWTYPE":
                msg = this.tr("Invalid row type.");
                break;
            case "INVALID_UNIT":
                msg = this.tr("Invalid unit.");
                break;
            case "FIRST_ROW_SHOULD_BE_HEADER":
                msg = this.tr("First row should be header-type.");
                break;
            default: //
        }

        return msg;
    }

    renderReviewComponent = (results) => {
        const { currency } = this.props;

        return <ImportQuoteRowsReview
            data={results?.rows_data || []}
            currency={currency}
        />
    }

    render() {
        const { company, enqueueSnackbar, onClose, onSuccess, quoteId, projectId } = this.props;
        const { tr } = this;

        return (
            <ImportDialog
                title={tr("Import sales quote rows from file")}
                company={company}
                getRowError={this.getRowError}
                renderReviewComponent={(results) => this.renderReviewComponent(results)}
                requiredFields={['row_type', 'description']}
                requiredFieldNames={`${this.tr('Row type')}, ${this.tr('Description')}`}
                enqueueSnackbar={enqueueSnackbar}
                psaFields={this.psaFields}
                importUrl={`imports/quote_rows/`}
                reviewUrl={`imports/quote_rows/review/`}
                onClose={onClose}
                onSuccess={onSuccess}
                importDataExtra={{ company, quoteId, projectId }}
                ImportTemplate={RowImportTemplate}
            />
        );
    }
}

export default withSnackbar(ImportQuoteRowsDialog);
