import React from 'react';
import { isEqual } from 'lodash';
import { ProposalContentSection } from '../types';
import TaimerComponent from '../../../TaimerComponent';
import styles from '../ProposalEditor.module.css';
import quoteStyles from '../../TabQuotes.module.scss';

interface QuoteSectionProps {
    section: ProposalContentSection;
    sectionIndex: number;
}

export default class QuoteSection extends TaimerComponent<QuoteSectionProps> {
    constructor(props, context) {
        super(props, context, 'projects/proposal/sections/QuoteSection');
    }

    shouldComponentUpdate = (oldProps: QuoteSectionProps) => {
        return !isEqual(oldProps.section.content, this.props.section.content) || oldProps.sectionIndex != this.props.sectionIndex;
    };

    render() {
        const {
            section: { content },
        } = this.props;

        return (
            <div className={styles.page}>
                <div className={styles.quotePage}>
                    <div className={quoteStyles.printContainer}>
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </div>
            </div>
        );
    }
}
