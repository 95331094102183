import React, { Component } from 'react';
import TaimerComponent from "../../TaimerComponent";
import ContextMenu from '../../general/ContextMenu';
import MoreHoriz from '@mui/icons-material/MoreHoriz';

import List from '../../list/List';
import ListRow from '../../list/ListRow';
import ListCell from '../../list/ListCell';
import DateCell from '../../list/cells/DateCell';
import TextInputCell from '../../list/cells/TextInputCell';
import StatusCell from '../../list/cells/StatusCell';

import { SettingsContext } from '../../SettingsContext';

import DataHandler from '../../general/DataHandler';
import OutlinedField from '../../general/OutlinedField';

import { MenuItem, Button } from '@mui/material';

import './UserManagement.css';


class BillingListRow extends ListRow {
	static contextType = SettingsContext;

	constructor(props) {
		super(props);
	}

	shouldComponentUpdate(nextProps, prevProps) {
		if(nextProps.hasOwnProperty("data") && nextProps['data']['id'] !== this.state.data['id']) {
			this.setState({ data: nextProps.data });
			return false;
		}

		return true;
    }
    
	render() {
		const className = ["billingListRow listElement row", this.props.hidden ? "hidden" : "", this.state.data['id'] < 0 ? "new" : ""].join(" ");

		const cells = {
            name:
                <ListCell style={{width: this.props.columnWidthMap['name'] + 'px'}} width={this.props.columnWidthMap['name']} value={this.state.data['subscription']['plan']['name']} editable={false} />,
            period:
                <ListCell style={{width: this.props.columnWidthMap['period'] + 'px'}} width={this.props.columnWidthMap['period']} value={this.state.data['period']} editable={false} />,
            date:
                <ListCell style={{width: this.props.columnWidthMap['date'] + 'px'}} width={this.props.columnWidthMap['date']} value={this.state.data['date']} editable={false} />,
            total:
                <ListCell style={{width: this.props.columnWidthMap['total'] + 'px'}} width={this.props.columnWidthMap['total']} value={new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(this.state.data['total'] / 100)} editable={false} />,
        };

		return (
			<div className={className} style={{display: "flex", height: !this.props.hidden ? "44px" : "0px", lineHeight: "44px" }}>
				{this.props.columnOrder.map(columnName => cells[columnName])}
			</div>
		);
	}
}

class BillingList extends TaimerComponent {
    static contextType = SettingsContext

    constructor(props, context) {
        super(props, context, "settings/pages/BillingList");
 
        this.state = {
            data: []
        }

        this.list = React.createRef();
    }

    componentDidMount () {
        super.componentDidMount();
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.perpage !== nextState.perpage || this.state.page !== nextState.page)
            return false;

        return true;
    }
    
    fetchData = () => {
        DataHandler.request("GET", {url: "settings/subscription/billing"}).done(response => {
            this.setState({
                data: response
            })
        });
    }

    render() {
        const { tr } = this;
        const fields = [
            { field: "name", name: "name", header: tr("Name"), width: 100, showMenu: false, resizeable: false, moveable: false, hideable: false },
            { field: "period", name: "period", header: tr("Billing period"), width: 100, showMenu: false, resizeable: false, moveable: false, hideable: false },
            { field: "date", name: "date", header: tr("Date"), width: 100, showMenu: false, resizeable: false, moveable: false, hideable: false },
            { field: "total", name: "total", header: tr("Total"), width: 100, showMenu: false, resizeable: false, moveable: false, hideable: false },
        ];

        return (
            <div id="billing-list-wrapper">
                <h3>{tr("Billing history")}</h3>
                <p className="help-text">{tr("Here you can see all the payments of your Taimer subscriptions.")}</p>
                
                <List
                    className="billing-list"
                    fluid
                    listRowType={BillingListRow}
					handler={this}
                    ref={this.list}
                    data={this.state.data}
                    columns={fields}
                    minWidth="750px"
                    height="fitRemaining"
                    showPageSelector={false}
                />
            </div>
        )
    }
}

export default BillingList;