import React, { PureComponent } from 'react';
import $ from 'jquery';

export default class AutoScroll extends PureComponent {
	static defaultProps = {
        disabled: false,
    }

    scrolledToBottom = true;

    constructor(props) {
		super(props);

		this.refScroll = React.createRef();
    }

    onScroll = (e) => {
        const $el = $(this.refScroll.current);

        this.scrolledToBottom = $el.scrollTop() + $el.outerHeight() - $el.prop('scrollHeight') > -50;
    }

    componentDidMount(){
        this.doScroll();
    }

    componentDidUpdate() {
        this.doScroll();
    }

    doScroll = () => {
        const { disabled } = this.props;

        if (disabled)
            return;

        const $el = $(this.refScroll.current);

        // Only scroll if user is at bottom
        if (this.scrolledToBottom)
            $el.scrollTop($el.prop('scrollHeight'));
    }
    
	render() {
        const { scrollEnabled, children, ...props } = this.props;

        return (<div ref={this.refScroll} {...props} onScroll={this.onScroll}>{children}</div>)
	}
}