import React, { useState } from "react";
import InsightDropDown from "../../insights/InsightDropDown";
import { Button, Popover } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { MoreHoriz, ViewColumn, RemoveRedEye, CheckBox } from "@mui/icons-material";
import ExpandingSearchBar from "../../../general/ExpandingSearchBar";

const BlockTopBar = (props) => {
  const {
    extraTopBarComponents,
    dropdownItems,
    selected,
    title,
    subtitles,
    className,
    showMenuButton,
    dropdownColor,
    loading,
    viewButton,
    dropdownTitle,
    allowSearch,
    onSearchChanged,
    dropdownTitleColor,
    hidden
  } = props;
  const [viewOptionsAnchor, setViewOptionsAnchor] = useState(null);
  return (
    <div className={`my-day-top-bar ${className}`}>
      <div className="title-container">
        <div style={{ marginTop: subtitles ? 10 : 0 }} className="titles">
          <h1>{title}</h1>
          {subtitles && (
            <div className="subtitles no-my-day-drag">
              {subtitles.map((subtitle) => (
                <h2 onClick={subtitle.action}>{subtitle.title}</h2>
              ))}
            </div>
          )}
        </div>
        {loading && <img src={require("../../insights/img/loading.svg").default} />}
      </div>

      <div style={{ display: hidden ? 'none' : 'flex' }} className="search-filters">

        {allowSearch && (
          <ExpandingSearchBar
            onChange={onSearchChanged}
            style={{ marginRight: 20 }}
          />
        )}
        {viewButton && (
          <>
            <div
              onClick={(e) => setViewOptionsAnchor(e.currentTarget)}
              className="eye-button">
              <Tooltip title={viewButton.title} classes={{ tooltip: 'darkblue-tooltip' }}>
                {props.selected == "calendar" ?
                  <RemoveRedEye className="icon no-my-day-drag" /> :
                  <ViewColumn className="icon no-my-day-drag" />}
              </Tooltip>
            </div>
            <Popover
              anchorEl={viewOptionsAnchor}
              open={Boolean(viewOptionsAnchor)}
              onClose={() => setViewOptionsAnchor(null)}
              anchorOrigin={{
                vertical: 60,
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {(viewButton.items || []).map((col, i) => {
                return (
                  <>
                    <div key={i} onClick={() => viewButton.onItemClick(col)} className="column-options-dropdown-item">
                      <div className="check-box">{viewButton.selectedItems.indexOf(col.name) != -1 && <CheckBox className="icon" />}</div>
                      {col.header}
                    </div>
                    {(col.subItems || []).map((sub, i) => (
                      <div key={i} onClick={() => viewButton.onItemClick(sub)} className={`column-options-dropdown-item sub`}>
                        <div className="check-box">{viewButton.selectedItems.indexOf(sub.name) != -1 && <CheckBox className="icon" />}</div>
                        {sub.header}
                      </div>
                    ))}
                  </>
                );
              })}
            </Popover>
          </>
        )}
      </div>
      {Array.isArray(extraTopBarComponents) && extraTopBarComponents.length > 0
        ? extraTopBarComponents.map((item) => {
          return item;
        })
        : extraTopBarComponents}
      {dropdownItems && (
        <InsightDropDown
          title={dropdownTitle}
          color={dropdownColor}
          titleColor={dropdownTitleColor}
          tabs={dropdownItems}
          selected={selected}
        />
      )}
      {showMenuButton && (
        <Button className="more-button no-my-day-drag">
          <MoreHoriz className="more-icon" />
        </Button>
      )}

    </div>
  );
};

export default BlockTopBar;
