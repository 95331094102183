import React, { PureComponent } from 'react';
import cn from 'classnames';
import OutlinedField from '../../../general/OutlinedField';
import { SettingsContext } from '../../../SettingsContext';
import TaimerComponent from '../../../TaimerComponent';

export default class InvoiceRow extends TaimerComponent {
	static contextType = SettingsContext;

	static defaultProps = {
		showValidationResult: false,
	}

	constructor(props, context) {
		super(props, context, "dialogs/elements/InvoiceRowsEditor/InvoiceRow");

		this.state = {
			valid: false,
			validation: this.validate(this.props.item),
		};
	}

	delete = () => {
		this.props.onDelete(this.props.item);
	}

	validate = (row) => {
		const result = {
			valid: true,
		};

		if (!row.name) {
			result.valid = false;
			result.name = true;
		}

		if (isNaN(row.amount) || row.amount <= 0) {
			result.valid = false;
			result.amount = true;
		}

		if (isNaN(row.unit_price) || row.unit_price <= 0) {
			result.valid = false;
			result.unit_price = true;
		}

		return result;
	}

	onChange = (e) => {
		let value = e.target.value;
		if (e.target.name == 'amount' || e.target.name == 'unit_price') {
			value = e.target.value.replace(',', '.');
		}
		const item = {
			...this.props.item,
			[e.target.name]: value,
		};

		const validation = this.validate(item);

		item.valid = validation.valid;

		this.setState({ validation });

		this.props.onChange(item.invoiceRowIndex, item, e.target.name);
	}

	render() {
		const { tr } = this;
		const { item, showValidationResult, disabled: propDisabled } = this.props;
		const validation = showValidationResult ? this.validate(item) : {};
		const { taimerAccount } = this.context;

		const disabled = propDisabled || item.disabled;

		return (<React.Fragment key={item.invoiceRowIndex}>
			<div className="third colspan">
				<OutlinedField name="name"
					label={this.tr("Description")}
					value={item.name}
					onChange={this.onChange}
					fullWidth
					error={validation.name}
					disabled={disabled} />

			</div>
			
			<OutlinedField name="amount"
				label={this.tr("pcs")}
				InputLabelProps={{ shrink: true }}
				value={item.amount}
				onChange={this.onChange}
				fullWidth
				error={validation.amount}
				disabled={disabled} />

			<OutlinedField name="unit_price"
				label={this.tr("Unit Price")}
				value={item.unit_price}
				onChange={this.onChange}
				format="currency"
				currency={taimerAccount.currency}
				fullWidth
				error={validation.unit_price}
				disabled={disabled} />

			<OutlinedField name="total"
				label={this.tr("Total")}
				value={item.total}
				onChange={this.onChange}
				format="currency"
				currency={taimerAccount.currency}
				InputProps={{
					readOnly: true,
				}}
				fullWidth
				error={validation.total}
				disabled={disabled} />

			<div className="invoiceRowDelete">{!disabled && <button onClick={this.delete}>X</button>}</div>
		</React.Fragment>)
	}
}