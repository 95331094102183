import React from "react";
import ListCell from "./ListCell";

class PlaceholderListCell extends ListCell {
	static defaultProps = {...ListCell.defaultProps, ...{
		className: "placeholderCell",
		placeholder: "",
		style: {}
	}};

	presentValue(value) {
		// value = value && String(value);
		const isPlaceholder = ((value === undefined || value === null) || 
		(isNaN(value) ? ((!(isNaN("NaN")) && value.trim() === "")) : ( String(value).trim()==="")));
		const style 		= {/*...{ fontStyle: isPlaceholder ? "italic" : "normal" },*/ ...this.props.style};

		return <span className={`${(isPlaceholder || !value) ? 'placeholder' : ''}`} style={style}>{(isPlaceholder  || !value) ? this.props.placeholder : value}</span>;
	}
}

export default PlaceholderListCell;
