import React from 'react';
import ProjectList from '../list/lists/ProjectList';

import './TabProjects.css';

class TabProjects extends React.Component {

    constructor(props) {
        super(props);

        this.list = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (this.props.account.allow_project_creation !== prevProps.account.allow_project_creation) {
            const data = this.list.current?.list.current?.getData() || [];
            const nD = data.filter(d => d.id < 0);
            if (nD.length > 0) {
                nD.forEach(project => {
                    this.list.current.list.current.removeRow(project.id);
                }); 
            }
        }
    }
    
    render () {
        const { entity } = this.props;
   
        return (
            <div id="accounts-projects">
                {this.props.account.id !== 'unsaved' && <ProjectList
                    noTabs
                    header={this.props.subTabLabels && this.props.subTabLabels.length > 1 && this.props.subTabLabels[this.props.subTabLabels.length - 1]?.header}
                    ref={this.list}
                    entity={entity}
                    autoCompleteData={{}}
                    view={this.props.view}
                    customerId={this.props.account.id}
                    customerName={this.props.account.name}
                    allowProjectCreation={Boolean(parseInt(this.props.account.allow_project_creation)) && this.props.account.locked < 1}
                    onlyThisCompany={this.props.company}
                    pipeline_id={this.props.pipeline_id}
                    stickySearchKey={`project_list_${this.props.account.id}_${this.props.company}`} />}
            </div>
        );
    }
    
    
}

export default TabProjects;
