import React from "react";

/* css */
import "./InsightChartTable.css";

import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

/* others */
import _ from "lodash";
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from "../../SettingsContext";
import ContentBlock from "../../general/ContentBlock";

import cn from "classnames";
import { TableFooter } from "@mui/material";

class InsightChartTable extends TaimerComponent {
  static contextType = SettingsContext;

  static defaultProps = {
    width: 3,
    visible: true
  };

  constructor(props, context) {
    super(props, context, "dashboard/insights/InsightChartTable");

    this.state = {};
  }

  onClickRow = (row, month = null) => {
    this.props.onClickRow(row, month);
  };

  _renderHeader = () => {
    const {
      datasets,
      setTitle = this.tr("Month"),
      valueTitle = this.tr("Value"),
      displaySplit,
      displayByLabels,
      extraColumns = []
    } = this.props;

    let cells = (
      <React.Fragment>
        <TableCell className="title large-cell">{setTitle}</TableCell>
        <TableCell align="right" className="title">
          {valueTitle}
        </TableCell>
        {extraColumns.map(col => {
          return (
            <TableCell align="right" className="title">
              {col.title}
            </TableCell>
          );
        })}
        {displaySplit && (
          <TableCell align="right" className="title">
            {this.tr("Split")}
          </TableCell>
        )}
      </React.Fragment>
    );

    if (displayByLabels) {
      cells = datasets.map(obj => (
        <TableCell align="right" className="title">
          {obj.label}
        </TableCell>
      ));
      cells.unshift(
        <TableCell align="left" className="title large-cell">
          {setTitle}
        </TableCell>
      );
      if (displaySplit) {
        cells.push(
          <TableCell align="right" className="title">
            {this.tr("Split")}
          </TableCell>
        );
      }
    }
    return (
      <TableHead>
        <TableRow>{cells}</TableRow>
      </TableHead>
    );
  };

  _renderRows = () => {
    const {
      datasets,
      currency,
      displaySplit,
      displayByLabels,
      labels,
      customColors,
      extraColumns = [],
      highlightRow,
      subtitles,
      showSlider,
    } = this.props;

    const {
      functions: { presentCurrency }
    } = this.context;

    const totalSum = datasets.reduce((prev, obj) => prev + _.sum(obj.data), 0);

    if (displayByLabels) {
      return labels.map((label, i) => {
        const totalOfMonth = datasets.reduce(
          (prev, obj) => prev + obj.data[i] || 0,
          0
        );
        const split =
          totalSum != 0 ? Math.round((totalOfMonth / totalSum) * 100) : 0;
        return (
          <TableRow
            onClick={() =>
              highlightRow &&
              this.onClickRow(
                // special case, used on sales insight's leads & deals leaderboard. Showing table by labels but need to click whole rows for slider.
                {
                  data: datasets[0].set.data.map(obj =>
                    obj.reduce((prev, obj) => prev + obj.value || 0, 0)
                  ),
                  set: { data: datasets[0].set.data }
                },
                i
              )
            }
            className={`row ${highlightRow ? "highlightable" : ""}`}
          >
            <TableCell align="left" className="number-value">
            {customColors && <div
                className="ball-indicator"
                style={{
                  background:
                    (customColors && customColors[i])
                }}
              />}
              {label}
            </TableCell>
            {datasets.map(obj => {
              const rowValue = obj.data.length
                ? obj.data[i] || 0
                : obj.data || 0;
              return (
                <TableCell
                  onClick={() => !highlightRow && this.props.onClickRow && this.onClickRow(obj, i)}
                  align="right"
                  className={`number-value ${
                    !highlightRow && this.props.onClickRow ? "highlightable" : ""
                  }`}
                >
                  {presentCurrency(rowValue, currency)}
                </TableCell>
              );
            })}
            {displaySplit && (
              <TableCell align="right" className="number-value">
                {split} %
              </TableCell>
            )}
          </TableRow>
        );
      });
    }

    return datasets.map((x, i) => {
      const rowSum = _.sum(x.data);
      const split = displaySplit && totalSum != 0 ? Math.round((rowSum / totalSum) * 100) : 0;
      return (
        <TableRow
          className="row highlightable"
          onClick={() => this.onClickRow(x)}
        >
          <TableCell className="label">
            <div className="label-container">
              <div
                className="ball-indicator"
                style={{
                  background:
                    (customColors && customColors[i]) ||
                    x.borderColor ||
                    x.backgroundColor
                }}
              />
              <div>
                {x.label}
                {subtitles && <p>{subtitles[i]}</p>}
              </div>
            </div>
          </TableCell>
          <TableCell align="right" className="number-value">
            {presentCurrency(rowSum, currency)}
          </TableCell>
          {extraColumns.map(col => {
            return (
              <TableCell align="right" className="number-value">
                {presentCurrency(col.getValue(x), currency)}
              </TableCell>
            );
          })}
          {displaySplit && (
            <TableCell align="right" className="number-value">
              {split} %
            </TableCell>
          )}
        </TableRow>
      );
    });
  };

  _renderFooter = () => {
    const {
      displayFooter,
      displayByLabels,
      displaySplit,
      currency,
      datasets,
      extraColumns = []
    } = this.props;
    const {
      functions: { presentCurrency }
    } = this.context;
    if (!displayFooter) return null;

    const totalSum = datasets.reduce((prev, set) => prev + _.sum(set.data), 0);

    if (displayByLabels) {
      return (
        <TableFooter>
          <TableRow>
            <TableCell className="title large-cell">
              {this.tr("Total")}
            </TableCell>
            {datasets.map(obj => {
              const rowValue = _.sum(obj.data);
              return (
                <TableCell align="right" className="number-value">
                  {presentCurrency(rowValue, currency)}
                </TableCell>
              );
            })}
            {displaySplit && (
              <TableCell align="right" className="title">
                {"100 %"}
              </TableCell>
            )}
          </TableRow>
        </TableFooter>
      );
    }
    return (
      <TableFooter>
        <TableRow>
          <TableCell className="title large-cell">{this.tr("Total")}</TableCell>
          <TableCell align="right" className="title">
            {presentCurrency(totalSum, currency)}
          </TableCell>
          {extraColumns.map(col => {
            const colTotal = datasets.reduce(
              (prev, set) => prev + col.getValue(set),
              0
            );
            return (
              <TableCell align="right" className="title">
                {presentCurrency(colTotal, currency)}
              </TableCell>
            );
          })}
          {displaySplit && (
            <TableCell align="right" className="title">
              {"100 %"}
            </TableCell>
          )}
        </TableRow>
      </TableFooter>
    );
  };

  render() {
    const {
      className,
      style,
      datasets,
      setTitle,
      valueTitle,
      currency,
      displayFooter,
      displaySplit,
      displayByLabels,
      labels,
      ...props
    } = this.props;

    return (
      <div className={cn("insight-chart-table", className)} style={style}>
        <div className="table-area">
          <Table className="summary">
            {this._renderHeader()}
            <TableBody>{this._renderRows()}</TableBody>
            {this._renderFooter()}
          </Table>
        </div>
      </div>
    );
  }
}

export default InsightChartTable;
