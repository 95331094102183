import React from "react";

import List from "../List";
import moment from "moment";
import Utils from "./../../general/Utils.js";
import DataList from "../../general/DataList";
import PropTypes from "prop-types";
import DataHandler from "../../general/DataHandler";
import TaimerComponent from "../../TaimerComponent";
import ApprovalsListRow from "../rows/ApprovalsListRow";
import ApprovalsSummaryListRow from "../rows/ApprovalsSummaryListRow";
import { format } from "date-fns";
import { DateRangePicker } from "./../../general/react-date-range/src";
import { SettingsContext } from "../../SettingsContext";
import { withSnackbar } from 'notistack';
import AdvancedSearch from "../../search/AdvancedSearch";
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import ProgressNotification from "./../../general/ProgressNotification";
import { ColumnHeaderButton } from '../ListHeader';
import OutlinedField from "../../general/OutlinedField";
import { getDefaultPricelistWorktypes } from '../../Data';
import NoPermissionOverlay from '../overlays/NoPermissionOverlay';
import MenuItem from '@mui/material/MenuItem';
import InsightDropDown from "../../dashboard/insights/InsightDropDown";
import { TextField } from '@mui/material';
import { formatInputNumber } from '../../helpers';
import MultiSelect from "../../general/MultiSelect";
import { 
    companyHasDimensionAddOn,
    getDimensionAutoCompleteData,
    createAdvancedSearchFieldsForDimensions,
    getInitialDimensionState
} from "../../dimensions/helpers"
import "./ApprovalsList.css";
import _ from 'lodash';
import FileSaver from 'file-saver';
import { ThumbUp, HourglassFull, BlockRounded } from '@mui/icons-material';
import { ReactComponent as AccountIcon } from '../../navigation/NavIcons/customers.svg';
import { ReactComponent as ProjectIcon } from '../../navigation/NavIcons/leads.svg';
import { deleteWorkhourEntry } from '../../Data';
import Loading from '../../dashboard/insights/img/loading.svg';
import cn from 'classnames';
require("moment-timezone");

class ApprovalsList extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/list/ApprovalsList");
        this.stickySearchKey = "approvals_list";

        const dateRange = {
            startDate: format(
                moment()
                    .startOf("month")
                    .toDate(),
                "YYYY-MM-DD"
            ),
            endDate: format(
                moment()
                    .endOf("month")
                    .toDate(),
                "YYYY-MM-DD"
            ),
            key: "selection",
        };

        const columnData = this.getColumns("summaryByUsers");
        const columns = columnData.columns;
        const advancedSearchFields = columns.advancedSearchFields;

        const type_filter_value = this.props.timeTrackerProps && this.props.timeTrackerProps.type_filter_value == 2 ? { value: 2, label: this.tr("overtime") } : { value: 0, label: this.tr("all") };
        const status_filter_value = this.props.timeTrackerProps && this.props.timeTrackerProps.status_filter_value == 2 ? { value: 2, label: this.tr("waiting") } : { value: 0, label: this.tr("all") };

        this.defaultListMode = props.show_approval_summary ? "summaryByUsers" : "hourList";
        this.state = {
            entries: [],
            checkedData: [],
            dateRange: dateRange,
            project: { id: 0, name: "" },
            customer: { id: 0, name: "" },
            entryCount: 0,
            users: [],
            teams:[],
            loadingList: true,
            company: context.userObject.companies_id,
            companies: [],
            filterSelections: {
                type: type_filter_value,
                status: status_filter_value,
                showSummaryHours: [],
            },
            filterData: { startDate: dateRange.startDate, endDate: dateRange.endDate},
            pageData: {
                page: 1,
                perpage: 30,
                pageCount: 1,
                totalCount: 0,
                sortby: "",
                sortasc: false,
            },
            allChecked: false,
            stickySearchInitialized: false,
            overtimeSettings: {},
            currentDialogProps: undefined, 
            approvalAutoComplete: {writeUsers: [], approveUsers: [], deleteUsers: [], approvable_projects: [], project_managers: []},
            autoCompleteData: false,
            noPermission: false,
            listMode: this.defaultListMode,
            loadedDataMode: this.defaultListMode,
            columns: columns,
            advancedSearchFields: advancedSearchFields,
            ...getInitialDimensionState()
        };
        this.filtersInitialValues = {
            company: context.userObject.companies_id,
            filterData: { 
                ...dateRange
            },
            filterSelections: {
                type:   props.timeTrackerProps && props.timeTrackerProps.type_filter_value == 2 ? { value: 2, label: this.tr("overtime") } : { value: 0, label: this.tr("all") },
                status: props.timeTrackerProps && props.timeTrackerProps.status_filter_value == 2 ? { value: 2, label: this.tr("waiting") } : { value: 0, label: this.tr("all") },
                showSummaryHours: []
            },
            pageData: {
                page: 1,
                sortby: "",
                sortasc: false,
                sortby: "",
                perpage: 30
            }
        };

        this.summaryHoursFilterOptions = [
            {value: "submitted", label: this.tr("Submitted")}, 
            {value: "not_submitted", label: this.tr("Not Submitted")}
        ];
        
        this.dialogs = {
            status: MassDialog
        };

        this.originalDialogProps = () => ({
            status: {
                dialogType: "delete",
                open: this.state.currentDialog === "status",
                onDialogClose: this.closeDialog,
                dialogProps: {
                    header: this.state.dialogData && this.state.dialogData.type == "status" ? this.state.dialogData.header : this.tr("Delete workhours?"),
                    confirmButtonClass: this.state.dialogData && this.state.dialogData.type == "status" ? "blue" : undefined,
                    confirmButtonText: this.state.dialogData && this.state.dialogData.type == "status" ? this.state.dialogData.confirmButtonText : undefined,
                    confirmDisabled: this.state.dialogData && this.state.dialogData.confirmDisabled,
                    warning: () => {
                        return this.state.dialogData ? this.renderConfirmationContent(this.state.dialogData) : "";
                    },
                    close: this.closeDialog,
                    onCloseClick: this.closeDialog,
                    onConfirm: () => {
                        const analyticsData = {
                            "event_date_time": moment().format('DD.MM.YYYY HH:mm:ss'),
                            "taimer_version": this.context.versionId,
                            "quantity": this.state.dialogData.type == "status" ? this.state.dialogData.checkedData.updateHours : 0,
                        };
                        const { analyticsFunction } = this.state;
                        if (this.state.dialogData.type === "status" && this.state.dialogData && this.state.dialogData.checkedData && this.state.dialogData.checkedData.updateHours) {
                            this.changeSelectedStatus(this.state.dialogData.value, this.state.dialogData.checkedData.updateHours, this.state.dialogData.checkedData.totalHours, this.state.dialogData.message?.trim());
                            this.closeDialog();
                            this.list.current.uncheckAll();
                        }
                        else if (this.state.dialogData.type === "delete" && !this.state.dialogData.isMassDelete)
                            analyticsData.quantity = this.delete();
                        else if (this.state.dialogData.type === "delete" && this.state.dialogData.isMassDelete)
                            analyticsData.quantity = this.deleteMultiple();

                        //this.context.functions.sendAnalytics(analyticsFunction, analyticsData);
                    }
                }
            }
        });

        this.dialogProps = this.originalDialogProps();
        this.searchTerms = undefined;
        this.list = React.createRef();
        this.advancedSearch = React.createRef();
        this.filtersAreInInitialState = this.filtersAreInInitialState.bind(this);
        this.initializeStickySearch   = this.initializeStickySearch.bind(this);
        this.saveStickySearch         = this.saveStickySearch.bind(this);
        this.initDimensions           = this.initDimensions.bind(this);

        this.userTypeAutocompleteClasses = ['users', 'project_managers', 'approve_privileged_users'];
        this.translations = {
            locked: this.tr("locked"),
            freelancer: this.tr("freelancer")
        };
        this.userTypeDataHeaders = {users_id: 'column1'};        
    }

    componentDidMount() {
        super.componentDidMount();
        this.getInitialData();
        this.listenReset();
        this.gettimeTrackerSettings();
        this.getListModes();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.company !== prevState.company) {
            this.initDimensions(this.state.company);
        }
        if(this.props.show_approval_summary !== prevProps.show_approval_summary) {
            this.getListModes();
        }
    }

    componentWillUnmount() {
		super.componentWillUnmount();
		this.unListenReset();
    }

    getListModes = () => {
        const listModes = [
            {
                key: "hourList",
                label: this.tr("Approval hour entries"),
                action: () => this.changeListMode("hourList"),
                icon: require("../../dashboard/my_day/assets/list.svg").default,
            }
        ];    

        if (this.props.show_approval_summary) {
            listModes.push({
                key: "summaryByUsers",
                label: this.tr("Summary by users"),
                action: () => this.changeListMode("summaryByUsers"),
                iconComponent: AccountIcon,
            });
            
            listModes.push({
                key: "summaryByProjects",
                label: this.tr("Summary by projects"),
                action: () => this.changeListMode("summaryByProjects"),
                iconComponent: ProjectIcon,
            });
        }

        this.setState({ listModes });
    }

    showUserProjectHours = async (props) => {
        const filters = {};
        const currentFilters = [];
        const status = props.status ? props.status : 4;

        if (props.whId) {
            filters.workhour = [{ operator: "is", type: "text", value: props.whId }];
            const currentFilter = {
                entityMode: true,
                identifier: props.whId,
                name: "workhour",
                nameTransl: this.tr("WorkhourID"),
                operator: "is",
                operatorTransl: this.tr("is"),
                value: props.whId
            }
            currentFilters.push(currentFilter);
        }
        if (!props.whId && props.projects_id) {
            const project = this.state.autoCompleteData.allProjects.find(p => p.id == props.projects_id);
            filters.project = [{operator: "contains", type: "text", value: project ? project.name : ""}];
            if (filters.project[0].value) {
                const currentFilter = {
                    entityMode: true,
                    identifier: props.projects_id,
                    name: "project",
                    nameTransl: this.advancedSearch.current.tr("project"),
                    operator: "contains",
                    operatorTransl: this.advancedSearch.current.tr("contains"),
                    value:  project ? project.name : ""
                }
                currentFilters.push(currentFilter);
            }
        }
        if (!props.whId && props.users_id) {
            let approvalAutoComplete = this.state.approvalAutoComplete;

            if (this.state.listMode != "summaryByUsers")
                approvalAutoComplete = await DataHandler.get({ url: "timetracker/workhours/approval/autocomplete", getCompanyData: true, company: -1 });
                
            const user = approvalAutoComplete.users.find(u => u.value == props.users_id);
            filters.user = [{operator: "contains", type: "text", value: user ? user.name : ""}];
            if (filters.user[0].value) {
                const currentFilter = {
                    entityMode: true,
                    identifier: props.users_id,
                    name: "user",
                    nameTransl: this.advancedSearch.current ? this.advancedSearch.current.tr("user") : this.tr("User"),
                    operator: "contains",
                    operatorTransl:  this.advancedSearch.current ? this.advancedSearch.current.tr("contains") : this.tr("contains"),
                    value:  user ? user.name : ""
                }
                currentFilters.push(currentFilter);
            }
        }

        this.searchTerms = {
            advanced_search_criteria: {
                filters: filters
            },
            currentFilters
        }

        this.searchTerms.mode = "advanced";
        const filterSelections = _.cloneDeep(this.state.filterSelections);
        const filterData = _.cloneDeep(this.state.filterData);
        filterSelections.status = this.state.autoCompleteData.statuses.find(s => s.value == status);
        filterData.status = status;

        if (this.state.listMode != "summaryByUsers" && !props.whId && this.state.companies.length > 0) {
            const companies = this.state.companies.concat({ id: -1, value: -1, name: "All", label: "All" });
            this.setState({ stickySearchInitialized: true, companies, company: -1}, () => this.changeListMode("hourList", filterSelections, filterData, true))
        }
        else
            this.changeListMode("hourList", filterSelections, filterData, false, props.whId);
	}

    changeListMode = (listMode, filterSelections = this.state.filterSelections, filterData = this.state.filterData, allCompanies = false, whId = false) => {
        let company = this.state.company;
        const companies = this.state.companies;
        if (!allCompanies && company == -1) {
            company = this.filtersInitialValues.company
            filterData.company = company;
            const companyIndex = companies.findIndex(c => c.id == -1);
            companies.splice(companyIndex, 1);
        }

        const columnData = this.getColumns(listMode, whId);
        const columns = columnData.columns;
        const advancedSearchFields = columnData.advancedSearchFields;
        const pageData = this.filtersInitialValues.pageData;
      
        this.setState({ stickySearchInitialized: true, company, companies, listMode, columns, advancedSearchFields, pageData, filterSelections }, () => this.fetchData(filterData, false, true));
    }

    getColumns = (listMode, whId = false) => {
        let columns = [];
        let advancedSearchFields = [];
        if (listMode == "hourList") {
            columns = [
                { field: "actions", name: "actions", header: "", width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, visibleInToolbar: true },
                { field: "checked", name: "checked", header: "", width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, columnHeaderType: "checkbox", visibleInToolbar: true },
                { field: "date", type: "date", name: "date", header: this.tr("Date"), width: 140 },
                { field: "user", name: "user", header: this.tr("user"), width: 200, resizeable: true },
                { field: "team", name: "team", header: this.tr("team"), width: 200, resizeable: true },
                { field: "customer", name: "customer", header: this.tr("Account Name"), width: 202, resizeable: true, visualizationType: "tree" },
                { field: "project", name: "project", header: this.tr("Project"), width: 180, resizeable: true, visualizationType: "tree" },
                { field: "type", name: "type", header: this.tr("type"), width: 103 },
                { field: "status", name: "status", header: this.tr("status"), width: 130, resizeable: false },
                { field: "starttime", name: "starttime", header: this.tr("Start time"), width: 103, type: "date" },
                { field: "endtime", name: "endtime", header: this.tr("End time"), width: 103, type: "date" },
                { field: "hours", type: "number", name: "hours", header: this.tr("Hours"), width: 82, type: "number" },
                { field: "task", name: "task", header: this.tr("Task"), width: 180 },
                // { field: "overtime_hours", type: "number", name: "overtime_hours", header: this.tr("overtime hours"), width: 90, showMenu: false }, Poistettu 2751 tiketissä
                // { field: "overtime_multiplier", name: "overtime_multiplier", header: this.tr("overtime multiplier"), width: 90, showMenu: false }, Poistettu 2751 tiketissä
                { field: "jobtype", name: "jobtype", header: this.tr("Jobtype"), width: 143, resizeable: true },
                { field: "description", name: "description", header: this.tr("Description"), width: 200 },
                { field: "overtime_description", name: "overtime_description", header: this.tr("overtime description"), width: 200, resizeable: true },
                { field: "status_date", name: "status_date", header: this.tr("status date"), width: 140, type: "date" },
                { field: "status_changed_by", name: "status_changed_by", header: this.tr("status changed by"), width: 140, resizeable: true },
            ];

            advancedSearchFields = columns
                .filter(f => f.field !== "actions" && f.field !== "checked" && f.field !== "date" && f.field !== "type" && f.field !== "status")
                .map(f => {
                    const field = { field: f.field, transl: f.header, type: f.type, visualizationType: f?.visualizationType || "list", entityMode: f.field === 'user' };

                    if (f.hasOwnProperty("autoCompleteData")) field.autoCompleteData = f.autoCompleteData;

                    return field;
                });
        }
        else {
            columns = [
                { field: "checked", name: "checked", header: "", width: 10, showMenu: false, resizeable: false, moveable: false, hideable: false, visibleInToolbar: false },
                { field: "openChildren", name: "openChildren", header: "", width: 10, showMenu: false, resizeable: false, moveable: false, hideable: false, visibleInToolbar: false },
                { field: "column1", name: "column1", header: listMode == "summaryByUsers" ? this.tr("user") : this.tr("account"), width: 200, moveable: false, hideable: false },
                { field: "column2", name: "column2", header: listMode == "summaryByUsers" ? this.tr("team") : this.tr("Project"), width: 140 },
                { field: "hours_not_submitted", name: "hours_not_submitted", header: this.tr("Hours not submitted"), width: 100 },
                { field: "hours_for_approval", name: "hours_for_approval", header: this.tr("Hours for approval"), width: 100 },
                { field: "tracked_h", name: "tracked_h", header: this.tr("Tracked h"), width: 100 },
                { field: "tracked_h_all", name: "tracked_h_all", header: this.tr("All Tracked h"), width: 100 },
                { field: "expected_h", name: "expected_h", header: this.tr("Expected h"), width: 100 },
                { field: "customer_h", name: "customer_h", header: this.tr("Customer h"), width: 100 },
                { field: "internal_h", name: "internal_h", header: this.tr("Internal h"), width: 100 },
                { field: "absence_vacation_h", name: "absence_vacation_h", header: this.tr("Absence/Vacation h"), width: 100 },
                { field: "project_budgeted", name: "project_budgeted", header: this.tr("Project budgeted"), width: 100 },
                { field: "project_allocated", name: "project_allocated", header: this.tr("Project allocated"), width: 100 },
                { field: "balance_change", name: "balance_change", header: this.tr("Balance change"), width: 100, sortable: false },
                { field: "overtime_change", name: "overtime_change", header: this.tr("Overtime change"), width: 100, sortable: false }
            ];

            advancedSearchFields = [
                { field: "user", transl: this.tr("user"), entityMode: true, },
                { field: "team", transl: this.tr("team") },
                { field: "customer", transl: this.tr("Account Name"), visualizationType: "tree" },
                { field: "project", transl: this.tr("Project"), visualizationType: "tree" },
                { field: "project_manager", transl: this.tr("Project manager") }
            ];
        }

        if (this.searchTerms && this.searchTerms.mode == "advanced" && !whId) {
            const currentFilters = this.searchTerms.currentFilters.map(e => e.name);
            currentFilters.forEach(c => {
                if (!advancedSearchFields?.find(a => a.field == c)) {
                    delete this.searchTerms.advanced_search_criteria.filters[c];
                    const index = this.searchTerms.currentFilters.findIndex(e => e.name == c);
                    this.searchTerms.currentFilters.splice(index, 1);
                }
            });

            if (this.searchTerms.currentFilters.length < 1) {
                this.searchTerms.mode = "freetext";
                this.searchTerms.freetextSearchTerm = "";
            }
        }

        return {columns, advancedSearchFields};
    }

    getInitialData = async () => {
        const autoCompleteData = await this.props.getAutoCompleteData();
        const companies = autoCompleteData ? autoCompleteData.approval_view_companies : [];
        if (companies.length === 0) {
            this.setState({ noPermission: true });
            return;
        }
        const company = companies.find(c => c.id == this.context.userObject.companies_id) ? this.context.userObject.companies_id : companies[0].id;

        this.filtersInitialValues.company = company;

        this.initDimensions(this.state.company);

        this.setState({ autoCompleteData, companies, company }, () => this.initializeStickySearch());
    }

    pageChanged = page => {
        const pageConf = {...this.state.pageData, page };
        this.fetchData(false, pageConf);
    };

    gettimeTrackerSettings = () => {
        const { userObject } = this.context;
        const company = userObject.companies_id;

        DataHandler.get({ url: `settings/company/${company}/overtime` })
            .done(response => {
                if (response.overtimeSettings) {
                    this.setState({ overtimeSettings: response.overtimeSettings });
                }
            })
            .fail(err => console.error(err));
    };

    initializeStickySearch() {
        const { timeTrackerProps } = this.props;
        const { tr } = this;

        const status_filter_value = timeTrackerProps.status_filter_value;
        if (status_filter_value && status_filter_value != 2) {
            this.setState({ listMode: "hourList" }, () => this.showUserProjectHours({users_id: this.props.timeTrackerProps.user, status: status_filter_value}));
            return;
        }

        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` }).done((response, _, request) => {
            if(request.status !== 200) {
                this.getNonStickyData();
                return;       
            }

            const filterSelections = response.filterSelections || this.state.filterSelections;

            if (response.filterSelections) {
                Object.keys(filterSelections).forEach(function (key) {
                    if (key === "type" && timeTrackerProps && timeTrackerProps.type_filter_value == 2) {
                        response.filterData[key] = { value: 2, label: tr("overtime") };
                    }
                    else if (key === "status" && timeTrackerProps && timeTrackerProps.status_filter_value == 2) {
                        response.filterData[key] = { value: 2, label: tr("waiting") };
                    } 
                    else if (key === "showSummaryHours") {
                        response.filterData[key] = filterSelections[key];
                    }
                    else {
                        response.filterData[key] = filterSelections[key].value;
                    }
                });
            }
            if (timeTrackerProps && timeTrackerProps.type_filter_value == 2) {
                response.filterSelections.type = { value: 2, label: tr("overtime") };
            }
            if (timeTrackerProps && timeTrackerProps.status_filter_value == 2) {
                response.filterSelections.status = { value: 2, label: tr("waiting") };
            }
            const dateRange = {
                startDate: response.filterData.startDate || this.state.filterData.startDate,
                endDate: response.filterData.endDate || this.state.filterData.endDate,
                key: "selection"
            }

            let company = this.state.company;
            if (response.filterData.company && this.state.companies.find(c => c.id == response.filterData.company))
                company = response.filterData.company;

            this.searchTerms = response.searchTerms;
            const listMode = this.props.show_approval_summary && response.listMode ? response.listMode : this.defaultListMode;
            const columnData = this.getColumns(listMode);
            const columns = columnData.columns;
            const advancedSearchFields = columnData.advancedSearchFields;

            this.setState({ company, dateRange, filterSelections, listMode, columns, advancedSearchFields }, () => this.fetchData(response.filterData, response.pageData, false, false, true));
        }).fail(response => {
            this.getNonStickyData();

        }).always((response, _, request) => {
            this.setState({ stickySearchInitialized: true });
        });
    }

    getNonStickyData = () => {
        const listMode = this.defaultListMode;
        const columnData = this.getColumns(listMode);
        const columns = columnData.columns;
        const advancedSearchFields = columnData.advancedSearchFields;
        this.setState({ stickySearchInitialized: true,  listMode, columns, advancedSearchFields }, () => this.fetchData(false, false, false, false, true));
    }

    saveStickySearch(filterData, pageData) {
        delete pageData.totalCount;
        delete pageData.pageCount;
        const stateToSave = {
            filterSelections: this.state.filterSelections,
            filterData,
            pageData,
            searchTerms: this.searchTerms,
            listMode: this.state.listMode
        }
        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}`, }, { search: stateToSave });
    }

    async initDimensions(company) {
        if(!companyHasDimensionAddOn(company, this.context.addons)) {
            this.setState(getInitialDimensionState());
            return;
        }

        this.setState({ 
            ...(await getDimensionAutoCompleteData(company)) 
        }); 
    }

    filtersAreInInitialState() {
        const initial = _.cloneDeep(this.filtersInitialValues);
        const filters = {filterData: {}, filterSelections: {}};
        delete initial.pageData;
        for(const key in initial.filterData) {
            if (key === "key")
                delete initial.filterData[key];

            if (["startDate", "endDate"].includes(key))
                filters.filterData[key] = this.state.filterData[key];
        }
        filters.filterSelections = _.cloneDeep(this.state.filterSelections);
        filters.company = this.state.company;

        for(const key in initial) {
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = JSON.stringify(filters[key]);
        }

        const freetext = this.searchTerms ? this.searchTerms.freetextSearchTerm : ""
        return _.isEqual(initial, filters) && freetext === "";
    }

    _resetFilters = (evt) => {
        if (!evt || evt.keyCode == '27') {
            const companies = this.state.companies;
            const allCompaniesIndex = companies.findIndex(c => c.id == -1);
            if (allCompaniesIndex >= 0)
                companies.splice(allCompaniesIndex, 1);

            const dateRange = {
                startDate: this.filtersInitialValues.filterData.startDate,
                endDate: this.filtersInitialValues.filterData.endDate,
                key: "selection"
            }
            this.searchTerms = undefined;
            this.advancedSearch.current.clearSearch(undefined, true);
            this.advancedSearch.current.clearSearchTextInput();
            this.setState({
                ...this.filtersInitialValues,
                companies,
                dateRange,
                filterData: dateRange
            }, () => {
                this.list.current.checkAll(false); 
                this.getApprovalAutocomplete();
                this.fetchData();
            });
        }
    }

    listenReset = () => {
		document.body.addEventListener("keyup", this._resetFilters);
	}

	unListenReset = () => {
		document.body.removeEventListener("keyup", this._resetFilters);
	}

    fetchData = (filterData = null, pageData = null, resetPage = false, onlyIds = false, stickySearch = false, overrides = {}) => {
        // this.setState({ checkedData: [] });

        if (!onlyIds) {
            this.setState({ loadingList: true });
        }

        /**
         * If filterData is not passed lets take it from the state
         */
        if (filterData && filterData != null && filterData.startDate) this.setState({ filterData: filterData });
        else filterData = this.state.filterData;

        /**
         * If pageData is not passed lets take it from the state
         */
        if (pageData && pageData != null && pageData.page) this.setState({ pageData: pageData });
        else pageData = this.state.pageData;
        
        filterData.company = this.state.company;

        if (onlyIds) 
            return DataHandler.post({ url: "timetracker/workhours/get_approval" }, { ...filterData, ...pageData, onlyIds, listMode: this.state.listMode, ...overrides });

        if (!stickySearch && Number(this.state.company) > 0)
            this.saveStickySearch({ startDate: filterData.startDate, endDate: filterData.endDate, company: filterData.company }, pageData);

        if (this.searchTerms !== undefined) {
            filterData.mode = this.searchTerms.mode;
            if (this.searchTerms.mode == "advanced") {
                filterData.advanced_search_criteria = JSON.stringify(this.searchTerms.advanced_search_criteria);
            } else {
                filterData.freetext = this.searchTerms.freetextSearchTerm;
            }
        }

        DataHandler.post({ url: "timetracker/workhours/get_approval" }, { ...filterData, ...pageData, listMode: this.state.listMode })
            .done(data => {
                if (stickySearch) {
                    this.getApprovalAutocomplete();
                }

                const pageData = this.state.pageData;
                let entries = this.state.entries;

                if (data.entries && data.entries.length > 0) {
                    if (this.state.listMode == 'hourList')
                        entries = data.entries.map(t => ({...t, user_name: `${t.user_name}${t.users_companies_id > 0 ? '' : ` (${this.translations.freelancer})`}${t.user_locked > 0 ? ` (${this.translations.locked})` : ''}`}));
                    
                    else if (['summaryByUsers', 'summaryByProjects'].includes(this.state.listMode)) {
                        entries = data.entries.map(t => ({
                            ...t, 
                            column1: `${t.column1}${(!t.user_company || t.user_company > 0) ? '' : ` (${this.translations.freelancer})`}${t.user_locked > 0 ? ` (${this.translations.locked})` : ''}`
                        }));
                    }

                    else
                        entries = data.entries;
                    
                } else {
                    entries = [];
                }

                if (data.entriesCount) {
                    pageData.totalCount = data.entriesCount;
                } else {
                    pageData.totalCount = 0;
                }

                if (data.pageCount) {
                    pageData.pageCount = data.pageCount;
                } else {
                    pageData.pageCount = 1;
                }

                if (resetPage)
                    pageData.page = 1;

                this.setState({ loadingList: false, pageData, entries, loadedDataMode: this.state.listMode }, () => resetPage && this.list.current && this.list.current.setPage(1));

                this.props.refreshWorkhourBalance();
            })
            .fail(err => { console.error(err)
                this.setState({ loadingList: false });   
            });
    };

    getApprovalAutocomplete = (company = false) => {
        company = company || this.state.company;
        DataHandler.get({ url: "timetracker/workhours/approval/autocomplete", getCompanyData: true, company: company }).done(async approvalAutoComplete => {
            approvalAutoComplete.worktypes = await getDefaultPricelistWorktypes(this.state.company);
            const userTagProps = {
                fields: {name: 'name'},
                showLocked: this.props.showLockedUsersWithTag,
                transl: this.translations
            };
            Object.keys(approvalAutoComplete).forEach(k => {
                if (this.userTypeAutocompleteClasses.includes(k)) {
                    approvalAutoComplete[k] = approvalAutoComplete[k].map(d => ({...Utils.showLockedAndFreelancerUserTag(d, userTagProps)}))
                }
            })
            // const listUserTagProps = {
            //     fields: this.userTypeDataHeaders,
            //     showLocked: this.props.showLockedUsersWithTag,
            //     transl: this.translations,
            //     userData: approvalAutoComplete.users.map(u => ({...u, id: u.value}))
            // };
            // let entries = _.cloneDeep(this.state.entries);
            // entries.forEach((e, i) => {
            //     if (Object.keys(e).some(k => Object.values(this.userTypeDataHeaders).includes(k))) {    
            //         entries[i] = ({...Utils.showLockedAndFreelancerUserTag(e, listUserTagProps)});
            //     }
            // });            
            this.setState({ approvalAutoComplete });
        });
    }

    /**
     * Daterange pickers onChange function
     *
     */
    onDateChange = event => {
        const { startDate, endDate } = event.selection;
        const filterData = this.state.filterData;
        filterData.startDate = format(startDate, "YYYY-MM-DD");
        filterData.endDate = format(endDate, "YYYY-MM-DD");

        this.setState({
            dateRange: {
                startDate: format(startDate, "YYYY-MM-DD"),
                endDate: format(endDate, "YYYY-MM-DD"),
                key: "selection",
            },
        });

        this.fetchData(filterData, false, true);
        this.list.current.checkAll(false);
    };

    /**
     * Daterange pickers onInputChange function
     *
     */
    onDateInputChange = (dateType, date) => {
        const { endDate, startDate } = this.state.dateRange;
        const filterData = this.state.filterData;
        date = format(date, "YYYY-MM-DD");

        if (dateType == "start") {
            filterData.startDate = date;
            this.setState({
                dateRange: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection",
                },
            });
        } else {
            filterData.endDate = date;
            this.setState({
                dateRange: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection",
                },
            });
        }

        this.fetchData(filterData, false, true);
        this.list.current.checkAll(false);
    };

    /**
     * onChange function for monst filters (except daterangepicker)
     *
     */
    onChangeFilter = (name, val) => {
        const filterSelections = _.cloneDeep(this.state.filterSelections);
        const filterData = _.cloneDeep(this.state.filterData);
        
        if(name == 'account') {
            filterSelections['account'] = val;
            filterSelections['project'] = { value: 0, label: this.tr("all") };
            filterData['account'] = val.value;
            filterData['project'] = '0';
        } else {
            filterSelections[name] = val;
            filterData[name] = val.value;
        }
        
        this.setState({ filterSelections });
        this.fetchData(filterData, false, true);
        this.list.current.checkAll(false);
    };

    changeMultiDropSearch(field, value) {
        if (value.length === 1 && (value[0].value === 0 || value[0].value === "0")) {
            value = [];
        } 
        const filterData = _.cloneDeep(this.state.filterData);
        const filterSelections = _.cloneDeep(this.state.filterSelections);
        filterSelections[field] = value;
        filterData[field] = value;
        this.setState({ filterSelections });
        this.fetchData(filterData, false, true);
        this.list.current.checkAll(false);
    }

    confirmStatusChange = async (value, fromSummary = false, id = 0) => {
        let header = "";
        let confirmButtonText = "";
        let confirmDisabled = false;
        let warningPart = "";
        let warningPart2 = "";
        let checkedData = {updateHours: [], notAllowed: [], notEditable: [], alreadyUpdated: [], incorrectData: []};

        if (!id) {    
            let selectedRows = await this.getSelectedRows();        
            if (fromSummary) selectedRows = selectedRows.join();

            const fetchData = {
                ids: selectedRows,
                name: "status",
                value,
                company: this.state.company,
                listMode: this.state.listMode
            }
            const fetchType = fromSummary ? "summary" : "";

            try {
                checkedData = await DataHandler.post({ url: `timetracker/workhours/check_massedit` }, { data: fetchData, type: fetchType });
            } catch (e) { }
        }
        else {
            checkedData.updateHours = [id];
        }
        
        let eventFunction = "";
        const caseValue = isNaN(value) ? value : Number(value);

        switch(caseValue) {
            case 1:
                header = this.tr("Approve hours") + "?";
                confirmButtonText = this.tr("Approve");
                warningPart = "approve";
                eventFunction = "hours_approved";
                break;
            case 0:
                header = this.tr("Change status of hours") + "?";
                confirmButtonText = this.tr("Change status");
                warningPart = "change status of";
                warningPart2 = " to: Waiting"
                break;
            case -1:
                header = this.tr("Decline hours") + "?";
                confirmButtonText = this.tr("Decline");
                warningPart = "decline";
                eventFunction = "hours_declined";
                break;
            case "delete":
                header = this.tr("Delete hours") + "?";
                confirmButtonText = this.tr("Delete");
                warningPart = "delete";
                eventFunction = "hours_deleted";
                break;
        }

        let warningInfo = !fromSummary 
            ? this.tr("Are you sure you want to " + warningPart + " ${amount} hour entries" + warningPart2 + "?", {amount: checkedData.updateHours.length})
            : this.tr("Are you sure you want to " + warningPart + " total of ${amount} hours" + warningPart2 + "?", {amount: formatInputNumber(checkedData.totalHours, "hours")})

        if (checkedData.updateHours.length < 1) {
            warningInfo = this.tr("No updateable hours!");
            confirmDisabled = true;
        }
        
        const type = value == "delete" ? "delete" : "status";
        const isMassDelete = value == "delete" ? true : false;
        this.setState({analyticsFunction: eventFunction}, () => {
            this.openDialog("status", {value, header, fromSummary, type, isMassDelete, confirmButtonText, warningInfo, checkedData, confirmDisabled});
        })
    };

    /**
     * Changes status of all checked entries to "value"
     *
     */
    changeSelectedStatus = async (value, ids, totalHours = 0, message = "") => {
        const notificationKey = this.showProgressNotification();

        const data = [];
        ids.forEach(id => {
            data.push({ id: id, name: "status", value: value });
        });

        DataHandler.put({ url: `timetracker/workhours/put_approval` }, { data: data, field: "status", message: message })
            .done(data => {
                this.props.massEditUpdate(data, true, totalHours);
            })
            .fail(err => console.log("error", err))
            .always(() => {
                setTimeout(() => {
                    this.props.closeSnackbar(notificationKey); 
                }, 1000);
            });
        
    };

    getSelectedRows = async () => {
        const allCheckedExcept = this.list.current.getAllCheckedExcept();
        let selected = [];

        if(allCheckedExcept) {
            selected = await this.fetchData(false, false, false, true);
            selected = selected.ids.filter(id => {
                return !allCheckedExcept[id]; 
            });
        } else {
            selected = this.list.current.getCheckedRows();
        }

        return selected;
    }

    export = async (target, fields) => {
        const { listMode, filterSelections: { showSummaryHours } } = this.state;

        const isSummaryList = listMode == "summaryByUsers" || listMode == "summaryByProjects"

        let columns = this.list && this.list.current ? this.list.current.visibleColumnsOrder : null;
        const exportHeaders = [];        
        const params   = {};
        let selected = await this.getSelectedRows();
        if (selected.length === 0 && isSummaryList) {
            selected = await this.fetchData(false, false, false, true, false, {
                listMode: 'hourList',
                projectCompany: listMode == "summaryByProjects" ? 1 : 0,
                status: null,
            });
            selected = selected.ids;
        } else if (selected.length === 0) {
            selected = await this.fetchData(false, false, false, true, false, {
                listMode: 'hourList',
            });
            selected = selected.ids;   
        }

        if (!columns || selected.length === 0) {
            this.props.enqueueSnackbar(this.tr("Nothing to export!"), {
                variant: "warning",
            });
            return;
        }

        if (isSummaryList){
            columns = [
                'actions',
                'checked',
                'date',
                'user',
                'team',
                'customer',
                'project',
                'type',
                'status',
                'starttime',
                'endtime',
                'hours',
                'task',
                'jobtype',
                'description',
                'overtime_description',
                'status_date',
                'status_changed_by',
            ];
            const f = this.getColumns("hourList");
            fields = f.columns;

            if (showSummaryHours.length) {
                if (showSummaryHours.find(x => x.value === 'submitted')) {
                    params.status = [4];
                } else if (showSummaryHours.find(x => x.value === 'not_submitted')) {
                    params.status = [2];
                }
            } else {
                params.status = [2, 4];
                params.hideBilled = true;
            }
        } 

        _.forEach(columns, column => {
            _.forEach(fields, (field, i) => {
                if (column == field.name && column != "checked" && column != "actions"){
                    exportHeaders.push(field.header);
                }
            })
        })

        params.order = columns;
        params.columnNames = exportHeaders;
        params.sort = this.state.sort;
        params.file_name = this.tr("hour_approvals_list_export");
        params.listMode = 'hourList';
        params.projectCompany = listMode == "summaryByProjects" ? 1 : 0;

        DataHandler.postArrayBuffer({ ...params, url: "timetracker/workhours/list_export/approvals", export: target, ...this.state.pageData }, {ids: selected}, true).done(response => {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            FileSaver.saveAs(blob, `${params.file_name}.${target}`);
        });
    }

    showProgressNotification() {
        return this.props.enqueueSnackbar(null, {
            persist: true,
            preventDuplicate: true,
            content: key => {
                return (
                    <ProgressNotification
                        message={this.tr("Applying changes")} />
                );
            }
        });
    }

    openDialog = (name, data = {}) => {
        this.setState({
            currentDialog: name,
            dialogData: data,
            currentDialogProps: { 
                ...(this.dialogProps.hasOwnProperty(name) ? this.dialogProps[name].dialogProps : {}), 
                ...(typeof(data) === "object" ? data : {}) ,
            }
        });
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    onToolbarEditClick = async () => {
        const hours = await this.getSelectedRows();
        this.props.openEditDialog(hours);
    }

    onToolbarExportClick = () => {
        this.export("xlsx", this.state.columns);
    }

    unCheckAll = () => {
        this.list.current.checkAll(false);
    }

    checkSummaryChildRows = (id, type = "1") => {
        const listData = this.list.current.getData();
        const rowData = listData.find(d => d.id == id);
            if (rowData && rowData.check_disabled)
                return;


        const currentCheckedRows = this.list.current.getCheckedRows();
        const parentChecked = currentCheckedRows.find(e => e == id);
        const childIds = this.state.entries.filter(s => s.parentId == id).map(e => e.id);
        const checkRows = [];

        childIds.forEach(cId => {
            const childData = listData.find(d => d.id == cId);
            const checkDisabled = childData && childData.check_disabled;
            if (!checkDisabled) {
                const childChecked = currentCheckedRows.find(e => e == cId);
                if (parentChecked && childChecked || !parentChecked && !childChecked)
                    checkRows.push(cId);
                    
                if (type === "0") {
                    this.checkSummaryChildRows(cId, -1);
                }
            }
        });

        type != -1 && checkRows.push(id);
        this.list.current.check(checkRows);
    }

    checkSummaryAllRows = () => {
        this.list.current.checkAll();
    }

    /**
     * Updates pageData state of pageData
     *
     */
    onListSettingsChange = pageData => {
        this.setState({ pageData }, () => this.fetchData());
    };

    companyChanged = company => {
        const companies = this.state.companies;
        const allCompaniesIndex = companies.findIndex(c => c.id == -1);
        if (allCompaniesIndex >= 0)
            companies.splice(allCompaniesIndex, 1);

        this.getApprovalAutocomplete(company);
        this.setState({ company, companies }, () => this.fetchData(false, false, true));
    };

    deleteConfirm = (item) => {
        item.isMassDelete = false;
        item.confirmDisabled = item.delete_permission != "1" || item.delete_allowed != "1";
        item.type = "delete";
        this.openDialog("status", item);
    }

    onToolbarDeleteClick = async () => {
        const checkedRows = await this.getSelectedRows();
        const checkData = {
            ids: checkedRows,
            name: "delete",
            value: ""
        }

        DataHandler.post({ url: `timetracker/workhours/check_massedit` }, { data: checkData, module: "approvals" }).done((checkedData) => {
            const warningInfo = checkedData.deletableRows.length > 0 
                ? this.tr("Are you sure you want to delete ${amount} hour entries?", {amount: checkedData.deletableRows.length})
                : this.tr("No updateable hour entries!")
            
            const data = {
                isMassDelete: true,
                confirmDisabled: checkedData.deletableRows.length < 1,
                type: "delete",
                warningInfo
            }
            this.openDialog("status", {checkedData, ...data});
        })
    }

    delete = async () => {
        const { dialogData } = this.state;
        let response = {};
        const analyticsCount = 1;

        try {
            response = await deleteWorkhourEntry(dialogData.id, dialogData.message?.trim());
        } catch (e) {
            this.closeDialog();
            this.props.enqueueSnackbar(this.tr("Failed to delete workhour"), {
                variant: "error",
            });
            return;
        }

        if (response.status === "FAILED") {
            let msg = false;

            if (response.msg) {
                msg = response.msg;
            } else if (response.errorcode === 'ALREDY_BILLED') {
                msg = 'Hour is already billed';
            }

            msg = this.tr("Failed to delete workhour") + (msg ? `: ${this.tr(msg)}`  : "" ) + "!";
            this.props.enqueueSnackbar(msg, {
                variant: "error",
            });
        }
        else {
            this.fetchData({}, null, true);
            this.unCheckAll();
            this.props.enqueueSnackbar(this.tr("Workhour deleted successfully!"), {
                variant: "success",
            });
        }
        this.closeDialog();
        return analyticsCount;
    }

    deleteMultiple = async () => {
        const { dialogData } = this.state;
        const ids = dialogData.fromSummary ? dialogData.checkedData.updateHours : dialogData.checkedData?.deletableRows;
        const response = await DataHandler.post({ url: `timetracker/mass_delete` }, { ids: ids, message: dialogData.message?.trim() });
        let amount = 0;
        let analyticsCount = 0;

        if (response.notDeleted > 0) {
            amount = dialogData.fromSummary ? formatInputNumber(response.notDeletedHours, "hours") : response.notDeleted;
            const msgString = dialogData.fromSummary ? "Failed to delete ${amount} hours!" : "Failed to delete ${amount} hour entries!" ;
            const msg = this.tr(msgString, { amount: amount });
            this.props.enqueueSnackbar(msg, {
                variant: "error",
            });
        }
        if (response.deleted > 0) {
            amount = dialogData.fromSummary ? formatInputNumber(response.deletedHours, "hours") : response.deleted;
            analyticsCount = response.deleted;
            const msgString = dialogData.fromSummary ? "${amount} hours deleted successfully!" : "${amount} hour entries deleted successfully!";
            this.props.enqueueSnackbar(this.tr(msgString, { amount: amount }), {
                variant: "success",
            });
            this.list.current.checkAll(false);
            setTimeout(() => {
                this.fetchData({}, null, true);
             }, 1000);
        }
        this.closeDialog();
        return analyticsCount;
    }

    renderConfirmationContent = (data) => {
        if (data.type == "delete" && !data.isMassDelete) {
            return (
                data.delete_permission == "1" && data.delete_allowed == "1"
                    ?  (<> <p>{this.tr("Are you sure you want to delete workhour entry from user: ${user}?", {user: data.user_name})}</p>
                            {this.renderDialogMessageField()}
                        </>)
                    :   <p>{this.tr("Workhour entry cannot be deleted?")}</p>

            )
        }

        const alreadyUpdated = data.checkedData.alreadyUpdated?.length;
        const notAllowed     = data.checkedData.notAllowed.length;
        const notEditable    = data.checkedData.notEditable.length;
        const notApprovable  = data.checkedData.notApprovable?.length;
        const deletable      = data.fromSummary ? data.checkedData?.updateHours?.length : data.checkedData?.deletableRows?.length;
        const showStatusMessageField = data.type === "status" && data.value == -1 && data.checkedData.updateHours?.length > 0;
        const showDeleteMessageField = data.type === "delete" && deletable > 0;

        let notOvertime = 0;
        if (data.checkedData.notOvertime && !data.fromSummary && data.type != "status")
            notOvertime = data.checkedData.notOvertime.length;

        let infoPart = "";

        if (data.type === "status") {
            switch(data.value) {
                case 1:
                    infoPart = "approved";
                    break;
                case 0:
                    infoPart = "set to waiting";
                    break;
                case -1:
                    infoPart = "declined";
                    break;
            }
        }
        else if (data.type === "delete") {
            infoPart = "deleted";
        }
        else {
            infoPart = "updated";
        }

        return (
            <>
                <p>{data.warningInfo}</p>
                <ul>
                {alreadyUpdated > 0 && 
                    <li>{alreadyUpdated + " " + this.tr("hour entries are already " + infoPart + ".")}</li>
                }
                 {notApprovable > 0 && 
                    <li>{notApprovable + " " + this.tr("hour entries cannot be " + infoPart) + ": " +  this.tr("Workhour is not approvable.")}</li>
                }
                 {notOvertime > 0 && 
                    <li>{notOvertime + " " + this.tr("hour entries are not overtime hours.")}</li>
                }
                 {notEditable > 0 && 
                    <li>{notEditable + " " + this.tr("hour entries cannot be " + infoPart) + ": " +  this.tr("Not editable anymore.")}</li>
                }
                {notAllowed > 0 && 
                    <li>{notAllowed + " " + this.tr("hour entries cannot be " + infoPart) + ": " + this.tr("No permission.")}</li>
                }
                </ul>

                {(showStatusMessageField || showDeleteMessageField) &&
                   this.renderDialogMessageField()
                }
            </>
        );
    }

    renderDialogMessageField = () => {
        const messageLength = this.state.dialogData?.message?.trim().length || 0;
        const maxLength = 250;

        return (
            <> 
            <TextField
                multiline
                disabled={false}
                className={`approval_decline_textarea`}
                variant="filled"
                value={this.state.dialogData?.message}
                rows={4}
                placeholder={this.tr("Add message...")}
                inputProps={{
                    onFocus: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
                onChange={e => e.target.value.trim().length <= maxLength && this.setState({ dialogData: { ...this.state.dialogData, message: e.target.value } })} />

            <span className="approval_decline_message-length">{messageLength} / {maxLength}</span>
            </>
        );
    }

    /**
     * ApprovalList renderer
     *
     */
    render() {
        if (this.state.loadedDataMode != this.state.listMode)
            return null;

        if(this.state.noPermission) {
            return <div>{<List showOverlay={true} overlayComponent={NoPermissionOverlay}/>}</div>
        }
        if(!this.state.stickySearchInitialized || !this.state.autoCompleteData) {
            return null;
        }
        
        const { userObject, addons, functions: { checkPrivilege } } = this.context;
        const {
            overtimeSettings,
            dateRange,
            companies,
            company,
            approvalAutoComplete,
            listMode,
            listModes,
            filterSelections: { type, status, showSummaryHours }
        } = this.state;
        const { autoCompleteData: { accounts, projects, types, statuses }} = this.state;
        const { enqueueSnackbar, approve_settings } = this.props;

        const companyApproval = approve_settings[company] ?? {};

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        const hasProjectApproveRights = approvalAutoComplete.approvable_projects.length > 0;
        const hasWriteRights = approvalAutoComplete.writeUsers.length > 0 || hasProjectApproveRights;
        const hasApproveRights = companyApproval.approval_visible && (approvalAutoComplete.approveUsers.length > 0 || hasProjectApproveRights);
        const hasDeleteRights = approvalAutoComplete.deleteUsers.length > 0 || checkPrivilege("workhours", "write");
        const showToolbarDelete = addons.delete_user_workhours && hasDeleteRights;
        const advancedSearchFields = this.state.advancedSearchFields ? this.state.advancedSearchFields : [];
        
        //actualyProjects not used???
        //const actualProjects = (account.value && account.value !== '0') ? projects.filter(x => x.customers_id === account.value || x.value === '0') : projects;
        return (
            <div className="contentBlock" id="contentBlockApprovals">
                <div className="approvals-header">
                    <div className="approvals-header-left">
                        {companies.length > 1 && 
                            <OutlinedField className="company-selector" name="company" value={company} label={this.tr("Select company")} onChange={e => this.companyChanged(e.target.value)} select>
                                {companies.map(e => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                            </OutlinedField>
                        }
                        <DateRangePicker
                            className="daterange"
                            ranges={[dateRange]}
                            onChange={this.onDateChange}
                            onInputChange={this.onDateInputChange}
                            label={this.tr("Time span")}
                            dateFormat={userObject.dateFormat}
                        />
                        <DataList
                            label={this.tr("type")}
                            options={types}
                            value={type}
                            onChange={val => this.onChangeFilter("type", val)}
                            virtualized
                        />
                        {this.state.listMode == "hourList" &&
                            <DataList
                                label={this.tr("status")}
                                options={statuses}
                                value={status}
                                onChange={val => this.onChangeFilter("status", val)}
                                virtualized
                            />
                        }
                        {this.state.listMode != "hourList" &&
                            <div className="drop-container multiselect-container">
                                <MultiSelect
                                    label={this.tr("Show hours")}
                                    autoReset
                                    defaultValue={showSummaryHours || []}
                                    options={this.summaryHoursFilterOptions}
                                    onChange={obj => this.changeMultiDropSearch("showSummaryHours", obj)}
                                />
                            </div>
                        }
                         <AdvancedSearch
                                ref={this.advancedSearch}
                                mode={this.searchTerms && this.searchTerms.mode ? this.searchTerms.mode : undefined}
                                initialFilters={this.searchTerms && this.searchTerms.currentFilters ? this.searchTerms.currentFilters : undefined}
                                mainConfig={this.searchTerms && this.searchTerms.advanced_search_criteria ? { operator: this.searchTerms.advanced_search_criteria.operator } : undefined} 
                                freetextLabel={this.searchTerms ? this.searchTerms.freetextSearchTerm : ""}
                                alwaysShowClearFilters={!this.filtersAreInInitialState()}
                                onClearSearch={this._resetFilters}
                                fields={[
                                    ...advancedSearchFields,
                                    ...createAdvancedSearchFieldsForDimensions(this.state.dimensions)
                                ]}
                                noRequests={true}
                                onSearchTrigger={searchTerms => {
                                    this.searchTerms = searchTerms;
                                    this.fetchData(false, false, true);
                                    this.list.current.checkAll(false);
                                }}
                                perpage={this.state.pageData.perpage}
                                autoCompleteData={this.state.autoCompleteData && approvalAutoComplete ? {
                                    customer: [accounts, "parent_id"],
                                    project: [projects, "parentid"],
                                    status_changed_by: approvalAutoComplete.approve_privileged_users,
                                    jobtype: approvalAutoComplete.worktypes,
                                    team: approvalAutoComplete.teams,
                                    user: approvalAutoComplete.users,
                                    project_manager: approvalAutoComplete.project_managers,
                                    ...this.state.dimensionAutoCompleteData
								} : {}}
                                autoCompleteDataFilters={this.state.dimensionAutoCompleteDataFilters}
                            />
                    </div>
                    <div className="approvals-header-right"></div>
                </div>

                {listModes.length > 1 && <InsightDropDown
                    tabs={listModes}
                    selected={listMode}
                    title={this.tr("View")}
                />}

                {(this.state.listMode == "summaryByUsers" || this.state.listMode == "summaryByProjects") && (
                    <>
                    <div className={cn("summary-contents-loading-overlay", !this.state.loadingList && "hidden")}>
                       <img src={Loading} />
                   </div>

                    <List
                    ref={this.list}
                    data={this.state.entries}
                    columns={this.state.columns}
                    sharedData={{...this.state.autoCompleteData, ...approvalAutoComplete}}
                    height="fitRemaining"
                    trimHeight={-10}
                    className="approvalsSummaryList"
                    ignoreRowPropsChange={false}
                    useAllCheckedExcept={true}
                    noStateData={true}
                    listRowType={ApprovalsSummaryListRow}
                    treeData={true}
                    idType="string"
                    rowProps={{
                        overtimeSettings,
                        enqueueSnackbar: this.props.enqueueSnackbar,
                        fetchData: this.fetchData,
                        checkChildRows: this.checkSummaryChildRows,
                        checkAllRows: this.checkSummaryAllRows,
                        showUserProjectHours: this.showUserProjectHours,
                        freshId: null,
                        mode: this.state.listMode,
                        tr: this.tr,
                        onEdited: (id, name, value) => {
                            const url  = `timetracker/workhours/update_hours`; 
                            const data = { name: name, value: value };

                            DataHandler.put({ url: url }, { data: data, id: id, module: "approvals" })
                                .done(data => {
                                    if (data.response && data.response.status === "FAILED") {
                                        if (data.response.errorcode === 'PROJECT_NOT_MAIN') {
                                            enqueueSnackbar(this.tr("Cannot add hour to this project because it has subprojects."), {
                                                variant: "error",
                                            });
                                        } else if (data.response.errorcode === 'INVALID_WORKTASK') {
                                            enqueueSnackbar(this.tr("Selected worktask can't be used in current project."), {
                                                variant: "error",
                                            });
                                        } else if (data.response.errorcode === 'OVERLAPS_DISABLED') {
                                            enqueueSnackbar(this.tr("Hour entries can't overlap"), {
                                              variant: "error",
                                            });
                                        } else if (data.response.errorcode === 'INVALID_PROJECT') {
                                            enqueueSnackbar(this.tr("This project can't be selected."), {
                                                variant: "error",
                                            });
                                        } else if (data.response.errorcode === 'NO_PERMISSION') {
                                            enqueueSnackbar(this.tr("This project can't be selected (no permission)."), {
                                                variant: "error",
                                            });
                                        } else if (data.response.errorcode === 'ZERO_HOURS') {
                                            enqueueSnackbar(this.tr("Start and end time must be different."), {
                                                variant: "error",
                                            });
                                        } else if (data.response.errorcode === 'INVALID_DATES' || data.response.errorcode === 'INVALID_DATE') {
                                            enqueueSnackbar(this.tr("Start and/or end date are not valid."), {
                                                variant: "error",
                                            });
                                        } else if (data.response.errorcode === 'NOT_IN_TEAM') {
                                            enqueueSnackbar(this.tr("Only project team members can add hours to this project."), {
                                                variant: "error",
                                            });
                                        } else if (data.response.errorcode === 'INSERT_PREVENTED_IN_PREV_MONTHS') {
                                            enqueueSnackbar(this.tr("Can't add hours to this month"), {
                                                variant: "error",
                                            });
                                        } else if (data.response.errorcode === 'DESCRIPTION_REQUIRED') {
                                            enqueueSnackbar(this.tr("Description is required."), {
                                              variant: "error",
                                            });
                                        } else if (data.response.errorcode) {
                                            enqueueSnackbar(this.tr("Saving failed!") + " " + this.tr("Error") + ": " + this.tr(data.response.errorcode), {
                                                variant: "error",
                                            });
                                        } else {
                                            enqueueSnackbar(this.tr("Saving failed!"), {
                                                variant: "error",
                                            });
                                        }
                                    } else {
                                        this.props.enqueueSnackbar(this.tr("Workhour saved."), {
                                            variant: "success",
                                        });
                                    }
                                })
                                .fail(err => {
                                    if (err.status == 403) {
                                        this.props.enqueueSnackbar(this.tr("Error in saving workhour: No permission"), {
                                            variant: "error",
                                        });
                                    }
                                });
                        },
                    }}
                    saveColumnConfig={true}
                    userListSettingsKey="approvals_summary_list"
                    showPageSelector={true}
                    pageCount={this.state.pageData.pageCount}
                    totalCount={this.state.pageData.totalCount}
                    perpage={this.state.pageData.perpage}
                    page={this.state.pageData.page}
                    controlPage={true}
                    enableToolbar={true}
                    hiddenToolbarColumns={showToolbarDelete && approvalAutoComplete.deleteUsers.length > 0 ? ["edit", "export", "selected_quantity"] : ["delete", "edit", "export", "selected_quantity"]}
                    onToolbarDeleteClick={() => this.confirmStatusChange("delete", true)}
                    onPerPageChange={perpage => {
                        const pageData = this.state.pageData;
                        pageData.perpage = perpage;
                        pageData.page = 1;
                        this.onListSettingsChange(pageData);
                        this.list.current.setPage(1);                        
                    }}
                    onPageChange={page => {
                        const pageData = this.state.pageData;
                        pageData.page = page;
                        this.onListSettingsChange(pageData);
                    }}
                    onSortRows={(colName, asc) => {
                        const pageData = this.state.pageData;
                        pageData.sortby = colName;
                        pageData.sortasc = asc;
                        pageData.page = 1;
                        this.onListSettingsChange(pageData);
                        this.list.current.setPage(1);                        
                    }}
                    additionalToolbarColumns={[
                        { name: "emptySpace", header: "", columnHeaderType: "emptySpace", width: 100 },
                        { name: "emptySpace2", header: "", columnHeaderType: "emptySpace", width: 100 },
                        { name: "approve", header: "", columnHeaderType: "approveButton", width: 100 },
                        { name: "decline", header: "", columnHeaderType: "declineButton", width: 100 },
                    ]}
                    additionalToolbarButtons={{
                        emptySpace:    <ColumnHeaderButton title={""} onClick={() => {}} />,
                        approveButton: <ColumnHeaderButton color={"green"} title={this.tr("Approve")} onClick={() => this.confirmStatusChange(1, true)} icon={ThumbUp} />,
                        declineButton: <ColumnHeaderButton color={"red"} title={this.tr("Decline")} onClick={() => this.confirmStatusChange(-1, true)} icon={BlockRounded} />,
                    }}
                    useHSRightPadding
                />
                </>
                )}

                {this.state.listMode == "hourList" && this.state.loadedDataMode == "hourList" && (
                <List
                    ref={this.list}
                    data={this.state.entries}
                    columns={this.state.columns}
                    sharedData={{...this.state.autoCompleteData, ...approvalAutoComplete}}
                    rowHeight={56}
                    height="fitRemaining"
                    className="myhoursList"
                    listRowType={ApprovalsListRow}
                    noStateData={true}
                    ignoreRowPropsChange={false}
                    rowProps={{
                        tr: this.tr,
                        overtimeSettings,
                        enqueueSnackbar: this.props.enqueueSnackbar,
                        onEdited: (id, name, value) => {
                            if (name === "status") {
                                this.confirmStatusChange(value, false, id);
                                return;
                            }

                            const url  = `timetracker/workhours/update_hours`; 
                            const data = { name: name, value: value };
                            DataHandler.put({ url: url }, { data: data, id: id, module: "approvals" })
                                .done(data => {
                                    if (data.response && data.response.status === "FAILED") {
                                        if (data.errorcode === 'PROJECT_NOT_MAIN') {
                                            enqueueSnackbar(this.tr("Cannot add hour to this project because it has subprojects."), {
                                                variant: "error",
                                            });
                                        } else if (data.errorcode === 'INVALID_WORKTASK') {
                                            enqueueSnackbar(this.tr("Selected worktask can't be used in current project."), {
                                                variant: "error",
                                            });
                                        } else if (data.errorcode === 'OVERLAPS_DISABLED') {
                                            enqueueSnackbar(this.tr("Hour entries can't overlap"), {
                                              variant: "error",
                                            });
                                        } else if (data.errorcode === 'INVALID_PROJECT') {
                                            enqueueSnackbar(this.tr("This project can't be selected."), {
                                                variant: "error",
                                            });
                                        } else if (data.errorcode === 'NO_PERMISSION') {
                                            enqueueSnackbar(this.tr("This project can't be selected (no permission)."), {
                                                variant: "error",
                                            });
                                        } else if (data.errorcode === 'ZERO_HOURS') {
                                            enqueueSnackbar(this.tr("Start and end time must be different."), {
                                                variant: "error",
                                            });
                                        } else if (data.errorcode === 'INVALID_DATES' || data.errorcode === 'INVALID_DATE') {
                                            enqueueSnackbar(this.tr("Start and/or end date are not valid."), {
                                                variant: "error",
                                            });
                                        } else if (data.errorcode === 'NOT_IN_TEAM') {
                                            enqueueSnackbar(this.tr("Only project team members can add hours to this project."), {
                                                variant: "error",
                                            });
                                        } else if (data.errorcode === 'INSERT_PREVENTED_IN_PREV_MONTHS') {
                                            enqueueSnackbar(this.tr("Can't add hours to this month"), {
                                                variant: "error",
                                            });
                                        } else if (data.errorcode === 'DESCRIPTION_REQUIRED') {
                                            enqueueSnackbar(this.tr("Description is required."), {
                                              variant: "error",
                                            });
                                        } else if (data.errorcode) {
                                            enqueueSnackbar(this.tr("Saving failed!") + " " + this.tr("Error") + ": " + this.tr(data.errorcode), {
                                                variant: "error",
                                            });
                                        } else {
                                            enqueueSnackbar(this.tr("Saving failed!"), {
                                                variant: "error",
                                            });
                                        }
                                    }
                                    else {                   
                                        this.props.enqueueSnackbar(this.tr("Workhour saved."), {
                                            variant: "success",
                                        });
                                        
                                        this.fetchData();
                                    }
                                })
                                .fail(err => {
                                    if (err.status == 403) {
                                        this.props.enqueueSnackbar(this.tr("Error in saving workhour: No permission"), {
                                            variant: "error",
                                        });
                                    }
                                });
                        },
                        onDelete: this.deleteConfirm, 
                    }}
                    onPerPageChange={perpage => {
                        const pageData = this.state.pageData;
                        pageData.perpage = perpage;
                        pageData.page = 1;
                        this.onListSettingsChange(pageData);
                        this.list.current.setPage(1);                        
                    }}
                    onPageChange={page => {
                        const pageData = this.state.pageData;
                        pageData.page = page;
                        this.onListSettingsChange(pageData);
                    }}
                    onSortRows={(colName, asc) => {
                        const pageData = this.state.pageData;
                        pageData.sortby = colName;
                        pageData.sortasc = asc;
                        pageData.page = 1;
                        this.onListSettingsChange(pageData);
                        this.list.current.setPage(1);                        
                    }}
                    userListSettingsKey="timetracker_approval_list"
                    saveColumnConfig={true}
                    showPageSelector={true}
                    pageCount={this.state.pageData.pageCount}
                    totalCount={this.state.pageData.totalCount}
                    perpage={this.state.pageData.perpage}
                    page={this.state.pageData.page}
                    controlPage={true}
                    useAllCheckedExcept={true}
                    enableToolbar={true}
                    hiddenToolbarColumns={hasWriteRights ? (showToolbarDelete ? [] : ["delete"]) : (showToolbarDelete ? ["edit"] : ["delete", "edit"])}
                    onToolbarExportClick={this.onToolbarExportClick}
                    onToolbarEditClick={this.onToolbarEditClick}
                    onToolbarDeleteClick={this.onToolbarDeleteClick}
                    additionalToolbarColumns={hasApproveRights ? [
                        { name: "approve", header: "", columnHeaderType: "approveButton", width: 100 },
                        { name: "waiting", header: "", columnHeaderType: "waitingButton", width: 100 },
                        { name: "decline", header: "", columnHeaderType: "declineButton", width: 100 },
                    ] : []}
                    additionalToolbarButtons={hasApproveRights ? {
                        approveButton: <ColumnHeaderButton color={"green"} title={this.tr("Approve")} onClick={() => this.confirmStatusChange(1)} icon={ThumbUp} />,
                        waitingButton: <ColumnHeaderButton color={"yellow"} title={this.tr("Set Waiting")} onClick={() => this.confirmStatusChange(0)} icon={HourglassFull} />,
                        declineButton: <ColumnHeaderButton color={"red"} title={this.tr("Decline")} onClick={() => this.confirmStatusChange(-1)} icon={BlockRounded} />,
                    } : {}}
                    useHSRightPadding
                />
                )}

                {
                    Dialog &&
                    <Dialog
                        open
                        onDialogClose={this.closeDialog}
                        onClose={this.closeDialog} // for workhour entry
                        onDialogSave={this.confirmDialog}
                        data={this.state.dialogData}
                        dialogType={this.dialogProps[this.state.currentDialog].dialogType}
                        dialogProps={{...this.state.currentDialogProps}}
                        item={this.state.dialogData.item} // for workhour entry
                    />
                }
            </div>
        );
    }
}

ApprovalsList.propTypes = {
    perpage: PropTypes.number.isRequired,
};

ApprovalsList.defaultProps = {
    perpage: 30,
    showLockedUsersWithTag: true,
};

export default withSnackbar(ApprovalsList);
