import React, { Component } from 'react';

import OutlinedField from "../../general/OutlinedField";
import ContextMenu from '../../general/ContextMenu';
import { SettingsContext } from '../../SettingsContext';
import TaimerComponent from "../../TaimerComponent";

import TrashCanIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { Button, MenuItem } from '@mui/material';
import DataHandler from './../../general/DataHandler';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { withSnackbar } from 'notistack';

import './ProjectForms.css';

class ProjectForms extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'settings/pages/ProjectForms');

        this.state = {
            editName: false,
            deleteDialogOpen: false,
        }
    }

    addForm = () => {
        this.props.onChangeForm(0);
        this.setState({ editName: 'new' });
    }

    createNew = (name, pipeline) => {
        const { userObject } = this.context;
        const { enqueueSnackbar, company } = this.props;

        DataHandler.put({ url: `settings/company/${company}/project/forms` }, {
            name: name,
            projects_pipelines_id: pipeline,
        }).then((data) => {
            this.setState({ editName: false })
            this.props.onChangeForm(data.id);
            this.props.onUpdated();
        }).fail(() => {
            enqueueSnackbar(`Form with same name already exists`, {
                variant: "error",
            });
        });
    }

    saveFormName = (name, id) => {
        const { pipeline, enqueueSnackbar, company } = this.props;
        const { editName } = this.state;
        const { userObject } = this.context;

        if (!name)
            return;

        if (editName === 'new') {
            if (pipeline) {
                this.createNew(name, pipeline);
            }
        } else {
            DataHandler.post({ url: `settings/company/${company}/project/forms/${id}` }, {
                name: name,
            }).then(() => {
                this.props.onUpdated();
                this.setState({ editName: false })
            }).fail(() => {
                enqueueSnackbar(`Form with same name already exists`, {
                    variant: "error",
                });
            });
        }
    }

    deleteForm = (id) => {
        const { company } = this.props;

        DataHandler.delete({ url: `settings/company/${company}/project/forms/${id}` }).then(() => {
            this.props.onUpdated();

            this.props.onChangeForm(0);
        });
    }

    onChange = (e) => {
        this.props.onChangeForm(e.target.value);
    }

    onChangePipeline = (e) => {
        const { selected, forms, company } = this.props;
        const { editName } = this.state;

        const form = selected > 0 && forms.find(x => x.id == selected);

        if (form && editName !== "new") {
            DataHandler.post({ url: `settings/company/${company}/project/forms/${form.id}` }, {
                projects_pipelines_id: e.target.value,
            }).then(() => {
                this.props.onUpdated();
            });
        } else {
            this.setState({ pipeline: e.target.value });
        }
    }

    render() {
        const { selected, forms, pipeline, pipelines } = this.props;
        const { editName } = this.state;
        const { tr } = this;

        const filteredForms = forms.filter(x => x.projects_pipelines_id == pipeline)
        const form = selected > 0 && forms.find(x => x.id == selected);
        const pipe = pipelines.find(x => x.id == pipeline);

        return <React.Fragment>
            <div className="projectFormsPipeline">
                {this.props.children}

                <div className="actions">
                    <Button disabled={!pipe} className="green" onMouseUp={this.addForm} size="large">
                        {this.tr('Add Form')}
                    </Button>
                </div>
            </div>
            {(filteredForms.length > 0 || editName) && <div className="projectFormsCreate">
                {editName && <OutlinedField
                    autoFocus
                    className="editName"
                    value={editName != "new" && form && form.name || ""}
                    onChange={e => {
                        if (e.target.value === '') {
                            this.setState({ editName: false });
                            return;
                        }

                        this.saveFormName(e.target.value, selected);
                    }}
                    onKeyUp={e => {
                        if (e.key === "Escape")
                            this.setState({ editName: false });

                        else if (e.key === "Enter")
                            this.saveFormName(e.target.value, selected);
                    }}
                    label={this.tr("Name")} />
                }
                {!editName && <OutlinedField
                    select
                    style={{ width: 230, }}
                    className="select"
                    label={this.tr("Form")}
                    value={selected}
                    onChange={this.onChange}
                    disabled={filteredForms.length == 0}
                    margin="normal">
                    {filteredForms.map(form => (
                        <MenuItem key={form.id} value={form.id}>
                            {form.name}
                        </MenuItem>
                    ))}
                </OutlinedField>}
                {filteredForms.length > 0 && <ContextMenu label={<MoreHorizIcon />} style={{ marginTop: 12, height: 54, width: 34 }} noExpandIcon>
                    {/* <MenuItem onClick={this.addForm}>
                        <AddCircleOutline style={{ color: "#6b7897", marginRight: 16 }} /> {this.tr('Add Form')}
                    </MenuItem> */}
                    {form && <MenuItem onClick={() => this.setState({ editName: true })}><EditIcon style={{ color: "#6b7897", marginRight: 16 }} /> {this.tr('Edit name')}</MenuItem>}
                    {form && <MenuItem style={{ color: "#d0021b" }} onClick={() => this.setState({ deleteDialogOpen: true })}><TrashCanIcon style={{ color: "#d0021b", marginRight: 16 }} /> {this.tr('DELETE')}</MenuItem>}
                </ContextMenu>}
            </div>}

            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={() => this.setState({ deleteDialogOpen: false })}>
                <DialogTitle><WarningIcon style={{ marginRight: 16 }} /> {this.tr('Delete Form?')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.tr('Are you sure you want to delete')} {form && form.name}?
                        </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ deleteDialogOpen: false })} variant="outlined" className="white">
                        {this.tr('CANCEL')}
                    </Button>
                    <Button onClick={() => {
                        this.deleteForm(form.id);
                        this.setState({ deleteDialogOpen: false });
                    }} variant="outlined" className="red">
                        {this.tr('DELETE')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    }
}

export default withSnackbar(ProjectForms);