import React, { Component } from 'react';
import DataHandler from './../general/DataHandler';
import { SettingsContext } from '../SettingsContext';
import GroupList from './GroupList';
import GroupViewData from './GroupViewData';
import _ from 'lodash';
import TabHeader from './TabHeader';

// Team Chat
export default class TabProjects extends Component {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context);

        this.state = {
            projects: [],
        };

        this.refGroupView = React.createRef();
    }

    componentDidMount() {
        this.refreshProjects();
    }

    componentDidUpdate() {

    }

    onSendMessage = async (message, attachments) => {
        const { selectedId } = this.props;

        const form = new FormData();
        form.append("message", message);

        if (attachments.length === 1)
            form.append("attachment", attachments[0].file);

        const msg = await DataHandler.file({ url: `teamchat/projects/${selectedId}` }, form);

        this.refreshProjects({refresh: 1});

        return msg;
    }

    refresh = async () => {
        const { selectedId } = this.props;
        const projects = await this.refreshProjects();

        const current = projects.find(x => x.id == selectedId);

        if (current && current.unseen && this.refGroupView.current) {
            this.refGroupView.current.refresh();
        }
    }

    refreshProjects = async (params = {}) => {
        const { selectedId, setPage } = this.props;

        const projects = await DataHandler.get({ url: 'teamchat/projects', ...params });

        this.setState({ projects });

        if (selectedId == 0) {
            const item = _.first(projects);

            if (item)
                setPage("projects", item.id)
        }

        return projects;
    }

    onSelect = (item) => {
        const { setPage } = this.props;

        setPage("projects", item.id);
    }

    render() {
        const { selectedId, classes, viewData, tr, enqueueSnackbar, openPopup, onClose, inPopup } = this.props;
        const { projects } = this.state;

        const current = projects.find(x => x.id == selectedId);

        return (<React.Fragment>
            <TabHeader 
                classes={classes}
                title={current ? current.project : tr("Projects")}
                subtitle={current && current.customer}
                type="projects"
                id={current ? current.id : undefined}
                linkTarget={current ? {module: 'projects', action: 'view', id: current.id} : undefined}
                tr={tr}
                showMemberSelect
                enqueueSnackbar={enqueueSnackbar}
                openPopup={openPopup}
                onClose={onClose}
                inPopup={inPopup} />

            <GroupList
                type="projects"
                className="TeamChatLeftSide"
                items={projects}
                selectedId={selectedId}
                onSelect={this.onSelect}
                enqueueSnackbar={enqueueSnackbar}
                hideBadges />

            {current && <GroupViewData
                ref={this.refGroupView}
                key={selectedId}
                type="projects"
                id={selectedId}
                autoScroll
                data={viewData}
                onSendMessage={this.onSendMessage} />}
        </React.Fragment>);
    }
}