import React from "react"
import ListCell from "./../list/ListCell";
import cn from 'classnames';

function BranchIndicator(props) {
    const lineType  = props.lineType !== undefined ? props.lineType : "dotted";
    const lineColor = props.lineColor !== undefined ? props.lineColor : "#a1a1a1";
    const lineWidth = props.lineWidth !== undefined ? props.lineWidth : 1;

    const verStyle = {
        position: "absolute",
        left: (props.width / 2) - 1,
        top: 0,
        width: 2,
        height: lineType === "solid" ? props.rowHeight : props.rowHeight - 1,
        borderLeft: `${lineWidth}px ${lineType} ${lineColor}`
    };

    if(props.noBottom) {
        verStyle['height'] = verStyle.height / 2 - 1;
    }

    const horStyle = {
        position: "absolute",
        top: props.rowHeight / 2 - (lineType === "dotted" ? 2 : 3),
        left: (props.width / 2) - 1,
        width: (props.width / (!props.extendHorizontally ? 2 : 1)),
        height: 2,
        borderBottom: `${lineWidth}px ${lineType} ${lineColor}`
    };

    const mainStyle = {
        display: "inline-block",
        width: props.width,
        height: props.rowHeight,
    };

    if(props.floatLeft) {
        mainStyle.float = "left";
    }

    const Wrapper 	 = props.inList ? ListCell : "div";
    let wrapperProps = props.inList ? {
    	className: "noBg pointer",
        permanentEditMode: true,
        width: props.width,
        // No energy, time, or motivation to find out
        // what causes to problem, so I'll fix it like this.
        style: props?.wrapperStyleFn?.(props) ?? {}
    } : {
    	style: mainStyle
    };

    return (
        <Wrapper {...wrapperProps} {...props.wrapperProps}>
            <div className={cn("branchIndicator", props.noBottom && "branchIndicatorLast")} style={{ position: "relative", width: "100%", height: "100%" }}>
                {!props.renderNothing && <div className="ver" style={verStyle}></div>}
                {!props.renderNothing && props.renderHor && <div className="hor" style={horStyle}></div>}
            </div>
        </Wrapper>
    );
}

BranchIndicator.defaultProps = {
	inList: false
};

function ChildAddition(props) {
    const lineType  = props.lineType !== undefined ? props.lineType : "dotted";
    const lineColor = props.lineColor !== undefined ? props.lineColor : "#a1a1a1";
    const lineWidth = props.lineWidth !== undefined ? props.lineWidth : 1;

    const verStyle = {
        position: "absolute",
        left: (props.width / 2) - 1,
        bottom: 0,
        width: 2,
        height: (lineType === "solid" ? props.rowHeight : props.rowHeight - 1) / 2,
        borderLeft: `${lineWidth}px ${lineType} ${lineColor}`
    };

    const horStyle = {
        position: "absolute",
        top: props.rowHeight / 2 - (lineType === "dotted" ? 2 : 3),
        left: -1,
        width: (props.width / 2),
        height: 2,
        borderBottom: `${lineWidth}px ${lineType} ${lineColor}`
    };

    // Ähh.
    return (
        <div style={{ position: "relative", display: "inline-block", width: "25px", height: "44px" }}>
            <div style={horStyle}></div>
            <div style={verStyle}></div>
        </div>
    );
}

function hasFurtherSiblings(row, level, whichId = false) {
    if(row === undefined)
        return false;
    
    if(level == 0)
        return row.children.length - 1 > row.children.findIndex(c => c.data.id == whichId);

    return hasFurtherSiblings(row.parentProps, --level, level == 0 ? row.data.id : whichId);
}

function createBranchIndicators(dimensions = { height: 44, indent: 50 }, branchIndicatorProps = {}) {
    let indicators = [];

    return (data, level = 0, isLast = false) => {
        const hasChildren = data.hasOwnProperty("children") && data.children.length > 0;

        for(let i = 0; i < level; ++i) {
            let hasMoreSiblings = level > 0 ? hasFurtherSiblings(data, level - i) : false;
            let noBottom        = i === level - 1 && level > 0 && isLast;

            indicators.push(<BranchIndicator
                inList={true}
                rowHeight={dimensions.height} 
                width={dimensions.indent} 
                renderHor={i === level - 1} 
                level={level}
                renderNothing={!hasMoreSiblings && !noBottom && i !== level - 1} 
                noBottom={noBottom}
                {...branchIndicatorProps} />);
        }

        return indicators;
    };
}

function determineBranchIndicators(treeHelperData, dimensions = { width: 25, height: 44 }) {
    const {
        depth,
        hasFurtherSiblingsOnLevels,
        isChild,
        hasChildren
    } = treeHelperData;

    const { width, height } = dimensions;
    const indicators = [];
    
    for(let i = 0; i < depth; ++i) {
        let furtherSiblingsAtLevel = hasFurtherSiblingsOnLevels.indexOf(i + 1) > -1;

        indicators.push(<BranchIndicator 
            inList={true}
            lineWidth={2}
            lineType={"solid"}
            rowHeight={height}
            width={width}
            level={i}
            renderHor={i === depth - 1} 
            renderNothing={!furtherSiblingsAtLevel && i !== depth - 1} 
            noBottom={hasFurtherSiblingsOnLevels.indexOf(i + 1) === -1}
        />);
    }

    return indicators;
}

export default BranchIndicator;
export { 
    createBranchIndicators, 
    hasFurtherSiblings, 
    determineBranchIndicators 
};
