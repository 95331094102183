import React, { Component } from 'react';
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import SettingsGrid from './SettingsGrid';
import Button from '@mui/material/Button';
import OutlinedField from "./../../general/OutlinedField";
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';

import './CompanyBankAccount.css';
import { IconButton } from '@mui/material';

const styles = theme => ({

});

class BankAccountsListRow extends TaimerComponent {
    onChange = (e) => {
        const { name: fname, value: row } = this.props;
        const { name, value } = e.target;

        this.props.onChange({
            target: {
                name,
                value: {
                    ...row,
                    [name]: value,
                },
            }
        })
    }

    onChangeBool = (e) => {
        const { value: row } = this.props;
        const { name, checked } = e.target;

        const other = name === "default_account" ? "additional_account" : "default_account";

        if (checked && name === "default_account") {
            this.props.onSetPrimary(row)
        }
        else if (checked) {
            this.props.onChange({
                target: {
                    name,
                    value: {
                        ...row,
                        [name]: 1,
                        [other]: 0,
                    },
                }
            })
        }
        else {
            this.props.onChange({
                target: {
                    name,
                    value: {
                        ...row,
                        [name]: checked ? 1 : 0,
                    },
                }
            })
        }
    }

    delete = () => {
        const { name, value: row } = this.props;

        this.props.onChange({
            target: {
                name,
                value: {
                    ...row,
                    deleted: 1,
                },
            }
        })
    }

    isError = (f) => {
        return this.props.isError(`${this.props.name}.${f}`);
    }

    render() {
        const { value } = this.props;

        return (
            <tr key={value.id}>
                <td><OutlinedField data-testid="add_bank_account_name" name="name" value={value.name} error={this.isError("name")} onChange={this.onChange} focusOnMount={true} /></td>
                <td><OutlinedField data-testid="add_bank_account_iban" name="iban" value={value.iban} error={this.isError("iban")} onChange={this.onChange} /></td>
                <td><OutlinedField data-testid="add_bank_account_swift" name="swift" value={value.swift} error={this.isError("swift")} onChange={this.onChange} /></td>
                <td>
                    <Switch
                        name="default_account"
                        data-testid="add_bank_account_default"
                        checked={value.default_account}
                        onChange={this.onChangeBool}
                        value="1"
                        color="primary" />
                </td>
                <td>
                    <Switch name="additional_account"
                        checked={value.additional_account}
                        onChange={this.onChangeBool}
                        disabled={value.default_account}
                        value="1"
                        color="primary" />
                </td>
                <td><IconButton onClick={this.delete} size="large"><DeleteIcon fontSize="small" /></IconButton></td>
            </tr>
        );
    }
}

class BankAccountsList extends TaimerComponent {
    constructor (props, context) {
        super(props, context, "settings/pages/CompanyBankAccounts");
    }
    
    raiseChange = (value) => {
        const { name } = this.props;

        this.props.onChange({
            target: {
                name,
                value
            }
        })
    }

    setPrimaryAccount = (value) => {
        const { value: rows = [] } = this.props;
        const newRows = [...rows];

        newRows.forEach( (row) => {
            row.default_account = value.id === row.id ? 1 : 0;

            if (row.default_account)
                row.additional_account = 0;
        });

        this.raiseChange(newRows);
    }

    newId = -1;

    addNew = () => {
        const { value } = this.props;

        const newvalue = [
            ...value,
            {
                id: this.newId--,
                additional_account: 0,
                default_account: 0,
            }
        ];

        this.raiseChange(newvalue);
    }

    onChange = (e) => {
        const { name, value } = e.target;
        const { value: rows = [] } = this.props;

        const row = rows.findIndex(x => x.id === value.id);

        const newRows = [...rows];
        newRows[row] = value;

        this.raiseChange(newRows);
    }

    render() {
        const { value } = this.props;
        const { tr } = this;
        return <div className="full">
            <Button onClick={this.addNew} data-testid="add_bank_account_button" className="green" size="large">{tr("Add bank account")}</Button>

            {value.length > 0 && 
            <table className="BankAccountsList">
                <tbody>
                    <tr>
                        <th>{tr("Bank Name")}</th>
                        <th>{tr("IBAN / Bank Account")}</th>
                        <th>{tr("BIC")}</th>
                        <th>{tr("Primary")}</th>
                        <th>{tr("Secondary")}</th>
                        <th></th>
                    </tr>
                    {value.map((x, i) => <BankAccountsListRow name={`accounts.${i}`} isError={this.props.isError} key={x.id} value={x} onChange={this.onChange} onSetPrimary={this.setPrimaryAccount} />)}
                </tbody>
            </table>}
        </div>
    }
}

class CompanyBankAccounts extends TaimerComponent {
    static contextType = SettingsContext;

    constructor (props, context) {
        super(props, context, "settings/pages/CompanyBankAccounts");

        this.state = {
            loaded: false,
            errors: {},
        }
    }

    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }
    updateComponentData = () => {
        const { company } = this.props;

        DataHandler.get({ url: `settings/company/${company}/bankaccounts` }).done(e => this.setState({ loaded: true, data: e }));
    }

    onChange = (data) => {
        this.setState({ data });

        this.save(data);
    }

    save = async (data) => {
        const { company } = this.props;

        const newdata = await DataHandler
            .post({ url: `settings/company/${company}/bankaccounts` }, data)
            .fail(x => this.setError(x));

        if (newdata.error)
            this.setState({ error: true });
        else
            this.setState({ loaded: true, data: newdata, errors: {} });
    }

    setError(error)
    {
        this.setState({error: true, errors: error.responseJSON})
    }

    render() {
        const { loaded, errors, data } = this.state;
        const { tr } = this;

        if (!loaded)
            return <div><img className='main-page-loading-indicator' src={require('../../dashboard/insights/img/loading.svg').default} /></div>
        
        const settings = [
            {
                type: 'title',
                className: 'header',
                key: 'section-tile',
                label: tr("Bank account shown on invoice"),
                subtext: tr("Add bank accounts to be shown on invoices created and printed. Add bank accounts you wish your customers to use when paying your invoice."),
            },
            {
                type: 'component',
                name: 'accounts',
                component: BankAccountsList
            },
            {
                type: 'title',
                key: 'section-tile-einv',
                label: tr("E-invoicing details"),
                subtext: tr("Activate the possibility to send and receive e-invoices. Add e-invoicing details and start sending. Check out your regional e-invoicing providers here."),
            },
            {
                type: 'text',
                className: 'nextLine',
                name: 'e_invoicingid',
                label: tr("Add E-Invoice Address"),
            },
            {
                type: 'text',
                className: 'nextLine',
                name: 'e_invoicingopid',
                label: tr("Operator Identifier"),
            },
        ];

        return <div className="SettingsGrid">
            <SettingsGrid settings={settings} errors={errors} item={data} onChange={this.onChange} />
        </div>
    }
}

export default CompanyBankAccounts;