import React from 'react';
import WithTabs from '../../navigation/WithTabs';
import TaimerComponent from '../../TaimerComponent';

import ActivitiesInsight from './activities/ActivitiesInsight';
import SalesInsight from './sales/SalesInsight';
import HoursInsight from './hours/HoursInsight';
import InvoicingInsight from './invoicing/InvoicingInsight';
import ProfitLoss from '../../charts/ProfitLoss';
import GoalsInsight from './goals/GoalsInsight';

import styles from './InsightsView.module.scss';

interface Props {
    selectedTab: string;
    selectedSubTab: string;
}

class InsightsView extends TaimerComponent<Props> {
    tabs: any[] = [];
    constructor(props, context) {
        super(props, context, './dashboard/insights/InsightsView');

        const {
            functions: { hasPrivilege },
        } = this.context;

        this.tabs = [
            {
                id: 'activities',
                label: this.tr('Activities'),
                hidden: !hasPrivilege('dashboard', ['activities_read']),
            },
            {
                id: 'sales',
                label: this.tr('Sales'),
                hidden: !hasPrivilege('dashboard', ['sales_read']),
                items: [
                    {
                        id: 'sales_leads',
                        label: this.tr('Pipeline Leads'),
                    },
                    {
                        id: 'sales_deals',
                        label: this.tr('Projects | Won Deals'),
                    },
                ],
            },
            {
                id: 'hours',
                label: this.tr('Hours'),
                hidden: !hasPrivilege('dashboard', ['hours_read']),
            },
            {
                id: 'invoicing',
                label: this.tr('Invoicing'),
                hidden: !hasPrivilege('dashboard', ['invoicing_read']),
            },
            {
                id: 'profit_loss',
                label: this.tr('Profit & Loss'),
                hidden: !hasPrivilege('dashboard', ['profit_loss_read']),
            },
            {
                id: 'goals',
                label: this.tr('Goals'),
                hidden: !hasPrivilege('dashboard', ['goals_read']),
            },
        ];
    }

    renderInsight = (selectedTab) => {
        switch (selectedTab) {
            case 'activities':
                return <ActivitiesInsight />;
            case 'sales_leads':
            case 'sales_deals':
                return <SalesInsight mode={selectedTab.replace('sales_', '')} />;
            case 'hours':
                return <HoursInsight />;
            case 'invoicing':
                return <InvoicingInsight />;
            case 'profit_loss':
                return <ProfitLoss />;
            case 'goals':
                return <GoalsInsight />;
            default:
                return null;
        }
    };

    render() {
        return (
            <div id={styles.insightsView} className="contentBlock">
                <WithTabs tabs={this.tabs} selectedTab={this.props.selectedTab} selectedSubTab={this.props.selectedSubTab}>
                    {(selectedTab) => {
                        return this.renderInsight(selectedTab);
                    }}
                </WithTabs>
            </div>
        );
    }
}

export default InsightsView;
