import React from "react";
import TaimerComponent from "../../TaimerComponent";

import styles from "./SplitTaskDialogUserRow.module.scss";
import OutlinedField from "../../general/OutlinedField";
import _ from 'lodash';
import cn from 'classnames';

export default class SplitTaskDialogUserRow extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'resourcing/dialogs/SplitTaskDialogUserRow');
    }

    changeNumber = ({ target: { name, value } }) => {
        const { user, onChange } = this.props;
        const newValue = Number(value.replace(',', '.'));

        if (newValue !== user[name]) {
            onChange && onChange(user, {[name]: newValue});
        }
    }

    render() {
        const { className, user: { name, role, hours, tracked, firstPart, firstPartTracked, secondPart, secondPartTracked } } = this.props;

        const totalResourcedNew = Number(firstPart) + Number(secondPart);

        return (<div className={className}>
            <div>
                <span>{name}{role && ','}</span> {role && <span className={styles.role}>{role}</span>}
            </div>
            <div className={styles.center}>
                {hours.toFixed(2)} {this.tr("h")}
            </div>
            <div className={styles.center}>
                {(hours - tracked).toFixed(2)} {this.tr("h")}
            </div>
            <div className={cn(styles.staticValue, styles.borderLeft, styles.right)}>{firstPartTracked.toFixed(2)} / </div>
            <div className={cn(styles.input)}>
                <OutlinedField name="firstPart" value={firstPart.toFixed(2)} onChange={this.changeNumber} />
            </div>
            <div className={cn(styles.staticValue, styles.right)}>{secondPartTracked.toFixed(2)} / </div>
            <div className={cn(styles.input, styles.borderRight)}>
                <OutlinedField name="secondPart" value={secondPart.toFixed(2)} onChange={this.changeNumber} />
            </div>
            <div className={styles.center}>
                {(totalResourcedNew - hours).toFixed(2)} {this.tr("h")}
            </div>
            <div className={styles.right}>
                {tracked.toFixed(2)} / {totalResourcedNew.toFixed(2)} {this.tr("h")}
            </div>
        </div>);
    }
}