import React from "react";
import ListCell from "../ListCell";
import ClickAwayWrapper from "../../general/ClickAwayWrapper";
import TextInput from "../../general/TextInput";
import { Popper } from '@mui/material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import styles from "./DropdownCell.module.scss";

import isEqual from "lodash/isEqual";

class DropdownCell extends React.Component {
    static defaultProps = {
        data: [],
        dataDescription: { 
            filterBy: null, 
            value: null,
            label: null
        },
        listCellType: ListCell,
        listCellProps: {},
        textInputProps: {},
        onSelect: (entry) => {},
        showErrorBorder: false,
        value: undefined,
        onAlternativeRow: false
    };

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: -1,
            data: props.data,
            listCellRef: null,
            open: false,
            selectedEntry: null
        };

        this.onInputTimeout = undefined;
        this.listCell       = React.createRef();
        this.textInput      = React.createRef();
        this.dropdown       = React.createRef();

        [
            "setValueFromValueProp",
            "reset",
            "openDropdown",
            "closeDropdown",
            "focusOnEditor",
            "onTextInput",
            "handleOnKeyDown",
            "handleOnKeyUp",
            "handleOnMouseOver",
            "filter",
            "select",
            "resetValue",
            "handleArrows",
            "handleEnter"
        ].forEach(f => this[f] = this[f].bind(this));
    }

    componentDidMount() {
        const { 
            data,
            value
        } = this.props;

        this.setState({
            listCellRef: this.__getListCell().getDiv().current,
            data: data
        });

        if(value !== undefined) {
            this.setValueFromValueProp();
        }
    }

    componentDidUpdate(prevProps) {
        if(!isEqual(prevProps.data, this.props.data)) {
            this.setState({ data: this.props.data }, this.setValueFromValueProp);
        }

        if(!isEqual(prevProps.value, this.props.value)) {
            this.setValueFromValueProp();
        }
    }

    setValueFromValueProp() {
        const { data }  = this.state;
        const { 
            value, 
            dataDescription 
        } = this.props;

        const { value: valueField } = dataDescription;

        if(!value 
            || !data 
            || data.length === 0 
            || data.find(d => d[valueField] === value) === undefined) {
            return;
        }

        // Second param = silent, 
        // doesn't trigger listeners.
        this.select(this.props.value, true);
    }

    reset(callback = () => {}) {
        const { onSelect } = this.props;

        this.setState({ 
            selectedEntry: null 
        }, () => {
            this.resetValue();
            onSelect(null);

            callback();
        });
    }

    openDropdown() {
        if(this.state.open) {
            return;
        }

        this.setState({ 
            open: true 
        }, () => {
            this.textInput?.current?.focus();
        });
    }

    closeDropdown() {
        if(!this.state.open) {
            return;
        }

        this.setState({ 
            open: false 
        });
    }

    focusOnEditor() {
        this.openDropdown();
    }

    onTextInput(value) {
        const { open } = this.state;

        if(!open) {
            this.openDropdown();
        }

        clearTimeout(this.onInputTimeout);

        this.value = value;

        this.onInputTimeout = setTimeout(this.filter, 500);
    }

    handleOnKeyDown(event) {
        const { selectedEntry } = this.state;

        if(event.keyCode === 8 
            && selectedEntry !== null 
            && this.value === "") {
                this.reset(this.openDropdown);
        }

        if(([38, 40].indexOf(event.keyCode) === -1)) {
            return;
        }

        event.preventDefault();

        return false;
    }

    handleOnKeyUp(event) {
        event.persist();

        const map = {
            38: () => this.handleArrows(-1),
            40: () => this.handleArrows(+1),
            13: () => this.handleEnter()
        };

        if(map.hasOwnProperty(event.keyCode)) {
            map[event.keyCode]();
        }
    }

    handleArrows(inc) {
        const { 
            activeIndex,
            data,
            open
        } = this.state;

        if(!open) {
            this.openDropdown();
        }

        let newIndex = activeIndex + inc;

        if(newIndex < 0) {
            newIndex = (data.length) + newIndex;
        } else if(newIndex > (data.length - 1)) {
            newIndex = newIndex - (data.length - 1);
        }

        this.setState({ 
            activeIndex: newIndex
        }, () => {
            if(!this.dropdown.current) {
                return;
            }

            // TODO: Row height as a prop.
            this.dropdown.current.scrollTop = (newIndex * 28) - 3 * 28;
        });
    }

    handleEnter() {
        const { 
            activeIndex,
            data 
        } = this.state;

        const { value } = this.props.dataDescription;

        if(activeIndex < 0) {
            return;
        }


        this.select(data[activeIndex][value]);
    }

    handleOnMouseOver() {
        if(this.state.activeIndex === -1) {
            return;
        }

        this.setState({ 
            activeIndex: -1 
        });
    }

    filter(value = undefined) {
        const { 
            data,
            dataDescription
        } = this.props;

        const { 
            filterBy 
        } = dataDescription;

        value = value === undefined
            ? this.value
            : value;

        const filteredData = value !== undefined 
            ? data
                .filter(d => d[filterBy]
                    .toLowerCase()
                    .indexOf(value) > -1)
            : data;

        this.setState({
            data: filteredData,
            activeIndex: data.length > 0 
                ? 0 
                : -1
        });
    }

    select(selectedValue, silent = false) {
        const { 
            data,
            dataDescription,
            onSelect
        } = this.props;

        const { 
            value
        } = dataDescription;

        const selectedEntry = data
            .find(d => d[value] === selectedValue) || false

        if(selectedEntry?.disabled || false) {
            return;
        }

        if(!silent) {
            onSelect(selectedEntry);
        }

        this.setState({
            selectedEntry: selectedEntry 
        }, () => {
            this.resetValue();
            this.filter();

            this.listCell.current.closeEdit();
        });

        this.closeDropdown();
    }

    resetValue() {
        this.textInput?.current?.setValue?.("");
        this.value = "";
    }

    render() {
        const { props } = this;
        const {
            activeIndex,
            listCellRef,
            open,
            data,
            selectedEntry
        } = this.state;
        const { 
            listCellProps,
            listCellType,
            textInputProps,
            dataDescription,
            width,
            showErrorBorder,
            onAlternativeRow
        } = props;
        const { 
            label,
            value
        } = dataDescription;
        const dropdownWidth = this.textInput
            ?.current
            ?.inputRef
            ?.current
            ?.offsetWidth;
        const textInputClassNames = `${textInputProps.className} 
            ${showErrorBorder 
                ? "errorBorder" 
                : ""}
            ${onAlternativeRow 
                ? "bordered blueBorderWhenFocused" 
                : ""}`;

        const ListCellType = listCellType;

        return (
            <ListCellType 
                ref={this.listCell}
                width={width}
                owner={this} 
                className="dropdownCell"
                onExitEditMode={this.closeDropdown}
                value={selectedEntry !== null 
                    ? selectedEntry[label]
                    : ""}
                {...listCellProps}>
                <ClickAwayWrapper 
                    active={open}
                    onClickAway={this.closeDropdown}>
                    <div className={styles.dropdownCell__wrapper}>
                        <TextInput 
                            ref={this.textInput}
                            onFocus={this.openDropdown}
                            onClick={this.openDropdown}
                            onInputListener={this.onTextInput}
                            onKeyDown={this.handleOnKeyDown}
                            onKeyUp={this.handleOnKeyUp}
                            placeholder={selectedEntry !== null
                                ? selectedEntry[label]
                                : ""}
                            {...textInputProps} 
                            // Overrides textInputProps' className on purpose
                            className={textInputClassNames}
                        />
                        <Popper 
                            anchorEl={listCellRef}
                            placement="bottom-start"
                            open={open}>
                            <div 
                                ref={this.dropdown}
                                className={styles.dropdownCell__dropdown} 
                                style={{
                                    minWidth: `${dropdownWidth}px`
                                }}>
                                <ul className={styles.dropdownCell__list}>
                                    {data.map((v, i) => {
                                        const disabled = v?.disabled || false;

                                        return (
                                            <li
                                                onMouseOver={this.handleOnMouseOver}
                                                className={[i === activeIndex 
                                                    ? styles.dropdownCell__activeRow
                                                    : null, 
                                                    disabled ? styles.disabled : ""].join(" ")}
                                                key={i}
                                                onClick={() => this.select(v[value])}>
                                                {v[label]}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </Popper>
                        <ArrowDropDown
                            onClick={() => {
                                this.openDropdown();
                            }} />
                    </div>
                </ClickAwayWrapper>
            </ListCellType>
        );
    }
}

export default DropdownCell;
