import React from 'react';

import MenuItem from './MenuItem';
import _ from 'lodash';
import { SettingsContext } from '../SettingsContext';

class SidebarGroup extends React.Component {

    static contextType = SettingsContext;
   
    onItemClicked = (event, group, label) => {
        const subitems = this.getSubitems(group);
        if (subitems && subitems.length > 0) {
            const allowedItems = subitems.filter(s => !s.upgrade);
            if (allowedItems.length == 0) {
                this.context.functions.showUpgradeSlider();
                return;
            }
            this.props.onClick && this.props.onClick(allowedItems[0], event.ctrlKey || event.metaKey || event.button === 1, label || group.label);
            // track mixpanel events
            this.context.functions.sendMixpanelEvent('click_sidebar_dropdown_item', {
                'main_item':group.label.toLowerCase(),
            });
            return;
        }

        if (!subitems || subitems.length === 0)
            this.props.onClick && this.props.onClick(group, event.ctrlKey || event.metaKey || event.button === 1, label || group.label);
            // track mixpanel events
            this.context.functions.sendMixpanelEvent('click_sidebar_dropdown_item', {
                'main_item':group.target.module.toLowerCase(),
                'sub_item': group.label.toLowerCase()
            });
        }

    getSubitems = (group) => {
        const items = group.subitems || group.items;
        if (!items) return undefined;
        return items;
    }

    render() {
        const {
            group, showLocked,
            allAddons, addonsInfo, headingComponnent: Heading = MenuItem,
            onClick, openDialog, selected, level, tree, tabletMode, navigationNotifications
        } = this.props;

        const subitems = this.getSubitems(group);

        // All subitems hidden
        if (!subitems && !group.target)
            return null;

        const groupAddon = group.addonkey && allAddons[group.addonkey];
        const groupAddonInfo = group.addonkey && addonsInfo[group.addonkey];

        const locked = groupAddon && groupAddon.can_buy && !groupAddon.limit;

        // For old menu, hide locked
        if (locked && !showLocked)
            return null;

        return (<div>
            <Heading
                selected={selected}
                item={group}
                data-testid={`navigation-${group.id}`}
                icon={group.icon}
                name={group.label}
                onClick={this.onItemClicked}
                openDialog={openDialog}
                buy={groupAddon}
                addon={groupAddonInfo}
                addonanme={group.addonkey}
                tabletMode={tabletMode}
                subitems={subitems}
                mainItem
                allAddons={allAddons} 
                addonsInfo={addonsInfo}
                navigationNotifications={navigationNotifications}
            />
        </div>)
    }
}

export default SidebarGroup;