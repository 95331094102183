import React from "react";
import TaimerComponent from "../../TaimerComponent";
/* material ui */
import { Button } from "@mui/material";
import { ReactComponent as OverlayImage } from "./ProductList.svg";

class CatalogListSliderOverlay extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/overlays/CatalogListSliderOverlay");
  }

  render() {
    const { functions: { hasPrivilege }} = this.context;
    return (
      <div className="list-overlay">
        <OverlayImage style={{ width: "auto", height: "260px" }} />
        <div className="text">{this.tr("This product is not added to any catalogs.")}</div>
        <Button disabled={!hasPrivilege("products", "write")} color="primary" onClick={() => this.props.linkToCatalogs()}>
          {this.tr("Add product to catalogs").toUpperCase()}
        </Button>
      </div>
    );
  }
}

export default CatalogListSliderOverlay;
