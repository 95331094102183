import React from 'react';
import "./Paper.css";
import { ResizeSensor } from "css-element-queries";

// TODO: Modes for manual scaling, "responsiveness", etc.
// Now the default mode is pretty much to just adapt to the width of the containing element.

const hardDimensions = {
	width: 1100,
    height: 1556
};

class Paper extends React.Component {
	static defaultProps = {
		useScaling: true, // Not checked yet.
		container: undefined, // Reference to the containing element, whose width the paper should adapt to.
		scaleIncrement: 0.05,
		initialScale: 1.0,
		scaleToContainer: true,
		minScale: 0.1,
		maxScale: 5.0,
		useMaxWidth: false,
		maxWidth: undefined, // Determined after mounting if useMaxWidth is true.
		minVisiblePercentage: 0.7,
		trimWindowHeightBy: 0,
		style: {},
        onScaleChange: () => {}
	};

	
	constructor(props) {
		super(props);

		this.container 	   			= React.createRef();
		this.paper 		   			= React.createRef();
		this.reconDiv 	   			= React.createRef();
		this.resizeTimeout 			= undefined;
		this.originalContainerWidth = undefined;
		this.originalWidthRatio		= undefined;

		this.maxWidth = this.props.maxWidth !== undefined ? this.props.maxWidth : undefined;

		this.originalDimensions = {
			width: undefined,
			height: undefined
		};

		this.state = {
			ready: false,
			scale: this.props.initialScale,
			containerDimensions: {},
		};

		this.handleMouseWheel 		  = this.handleMouseWheel.bind(this);
		this.changePaperScale 		  = this.changePaperScale.bind(this);
		this.scaleToFitContainerWidth = this.scaleToFitContainerWidth.bind(this);
		this.scaleToFitWidth 		  = this.scaleToFitWidth.bind(this);
		this.scalePaper 	  		  = this.scalePaper.bind(this);
		this.matchContainerSize 	  = this.matchContainerSize.bind(this);
	}


	componentDidMount() {
		if(this.props.scaleToContainer) {
			const scale = this.reconDiv.current.clientWidth / hardDimensions.width;

			this.setState({
				scale: scale,
				containerDimensions: {
					width: (hardDimensions.width * scale) + "px",
					height: (hardDimensions.height * scale) + "px"
				}
			}, () => {
				this.setState({
					ready: true
				}, () => {
					this.scalePaper(scale);
				});
			});
		} else {
			// TODO: Mitäs sitten
		}

		if(!this.props.useMaxWidth || this.maxWidth !== undefined)
			return;

        this.maxWidth = (21 / 29.7) * ((window.outerHeight + this.props.trimWindowHeightBy) / this.props.minVisiblePercentage);

        this.props.onMaxWidthDetermined(this.maxWidth);
	}


	componentDidUpdate(prevProps, prevState) {
		if(!prevState.ready && this.state.ready) {
			this.originalContainerWidth = this.props.container.current.clientWidth;
			this.originalPaperWidth 	= this.paper.current.getBoundingClientRect().width;
			this.originalWidthRatio     = this.originalPaperWidth / this.originalContainerWidth;

			// Container's (paperOuterContainer's container's) width minus the paperOuterContainer's paddings.
			// this.scaleToFitContainerWidth(); // Not needed when scaled to container...?

			// this.props.container being defined implies the Paper should adapt to its container's width.
			new ResizeSensor(this.props.container.current, (e) => {
				clearTimeout(this.resizeTimeout);

				this.resizeTimeout = setTimeout(() => {
					if(this.paper.current === null)
						return;

					this.scaleToFitContainerWidth();
				}, 150);
			});

            // For when the paper and its contents grow or shrink, for now we need to lengthen the paper
            // until paging is implemented properly.
			new ResizeSensor(this.paper.current, () => {
                this.matchContainerSize();
			});
        }
	}


	handleMouseWheel(e) {
		if(!e.ctrlKey)
			return;

		e.preventDefault();

		const sign = -(e.deltaY / Math.abs(e.deltaY));

		this.changePaperScale(sign * this.props.scaleIncrement);
	}


	changePaperScale(delta) {
		this.scalePaper(this.state.scale + delta);
	}


	scaleToFitContainerWidth(minusMargins = true) {
		if(this.props.container === undefined || this.props.container.current === null)
			return false;

		this.scaleToFitWidth(this.originalWidthRatio * this.props.container.current.clientWidth - (minusMargins ? 2 : 0) * 16);
	}


    scaleToFitWidth(width) {
		this.scalePaper(parseInt(width) / hardDimensions.width);
	}


    scalePaper(newScale, callback = () => {}) {
        newScale       = newScale < this.props.minScale ? this.props.minScale : newScale > this.props.maxScale ? this.props.maxScale : newScale;
        const curScale = this.state.scale;
        const rect     = this.paper.current.getBoundingClientRect();
        let rectScale  = newScale / curScale;

        if(this.props.useMaxWidth && this.maxWidth !== undefined && rect.width * rectScale > this.maxWidth) {
        	newScale  = this.maxWidth / hardDimensions.width;
        	rectScale = newScale / curScale;
        }

		this.setState({
			scale: newScale,
            containerDimensions: {
                width: (rect.width * rectScale) + "px",
                height: (rect.height * rectScale) + "px"
			}
		}, callback());

        if(typeof(this.props.onScaleChange) === "function")
            this.props.onScaleChange(newScale);
	}


	matchContainerSize() {
		if (!this.paper.current)
			return;

		setTimeout(() => {
			let rect;
			if (this.paper.current) {
				rect = this.paper.current.getBoundingClientRect();

				this.setState({
					containerDimensions: {
						width: rect.width + "px",
						height: rect.height + "px"
					}
				});
			}
		});
	}


	render() {
		if(!this.state.ready)
			return <div ref={this.reconDiv} style={{ width: "100%" }}></div>
		else {
			const className = `paper${this.state.ready ? " ready" : ""}`;
			const paperStyle = { transform: `scale(${this.state.scale})`, ...this.props.style };

			return (
				<div
					ref={this.container}
					className="paperOuterContainer"
					style={this.state.containerDimensions}>
					<div
						ref={this.paper}
						className={className}
						style={paperStyle}
						onWheel={this.handleMouseWheel}>
						{this.props.children}
					</div>
					<div style={{ clear: "both" }}></div>
				</div>
			);
		}
	}
}

export default Paper;
