import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import cn from 'classnames';
import styles from './OvertimeMultipliers.module.scss';
import _ from 'lodash';
import DataHandler from '../../general/DataHandler';

import TimeManagementOvertimeMultiplierRow from "../../list/rows/TimeManagementOvertimeMultiplierRow";
import List from '../../list/List';
import OutlinedField from '../../general/OutlinedField';
import { Button, MenuItem } from '@mui/material';
import { withSnackbar, WithSnackbarProps } from 'notistack';

interface OvertimeMultipliersProps extends WithSnackbarProps {
    company: number;
}

interface OvertimeMultipliersState {
    loading: boolean;
    type: OvertimeMultiplierType;
    multipliers: OvertimeMultiplier[];
    data: OvertimeMultiplierResponse | null;
    headerVisible: boolean;
}

enum OvertimeMultiplierType {
    Normal,
    Saturday,
    Sunday,
}

interface OvertimeMultiplier {
    id: number;
    type: OvertimeMultiplierType;
    start: number;
    multiplier: number;
    deleted?: boolean;
}

interface OvertimeMultiplierResponse {
    multipliers: OvertimeMultiplier[];
}

interface SaveResult {
    status: 'ok' | 'error';
}

class OvertimeMultipliers extends TaimerComponent<OvertimeMultipliersProps, OvertimeMultipliersState> {
    refList: React.RefObject<List>;

    constructor(props: OvertimeMultipliersProps, context) {
        super(props, context, "settings/pages/OvertimeMultipliers");

        this.state = {
            loading: true,
            type: OvertimeMultiplierType.Normal,
            multipliers: [],
            data: null,
            headerVisible: false,
        };

        this.refList = React.createRef();
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.refresh();
    }

    refresh = async (force = false) => {
        const { company } = this.props;

        this.setState({
            loading: true,
        })

        const data = await DataHandler.get({ url: `settings/company/${company}/overtime/multipliers`, refresh: force ? 1 : '' }) as OvertimeMultiplierResponse;

        this.setState({
            loading: false,
            data,
            multipliers: this.filter(data),
        });
    }

    filter = (response: OvertimeMultiplierResponse, type = this.state.type) => {
        return response.multipliers.filter(x => x.type === type);
    }

    componentDidUpdate(prevProps: OvertimeMultipliersProps, prevState: OvertimeMultipliersState) {
        if (prevProps.company !== this.props.company) {
            this.refresh();
        }
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
    }

    onTypeChange = (e) => {
        const type = Number(e.target.value) as OvertimeMultiplierType;

        this.setState({
            type,
            multipliers: this.state.data ? this.filter(this.state.data, type) : [],
        });
    }

    onDataChange = (data) => {
        console.log({ data });
    }

    render() {
        const { enqueueSnackbar, company } = this.props;
        const { multipliers, type, headerVisible } = this.state;
        const { tr } = this;

        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };

        return <div className={styles.root}>
            <div className="respSetting third">
                <OutlinedField
                    className="select"
                    select
                    label={this.tr("Type")}
                    value={type}
                    fullWidth
                    onChange={this.onTypeChange}
                    shrinkLabel>
                    <MenuItem value={OvertimeMultiplierType.Normal}>
                        {tr("Weekdays")}
                    </MenuItem>
                    <MenuItem value={OvertimeMultiplierType.Saturday}>
                        {tr("Saturday")}
                    </MenuItem>
                    <MenuItem value={OvertimeMultiplierType.Sunday}>
                        {tr("Sunday / Holiday")}
                    </MenuItem>
                </OutlinedField>
                <Button
                    className="green"
                    onMouseUp={() => {
                        this.refList.current?.addNewRow?.();
                        this.setState({ headerVisible: true });
                    }}
                    size="large"
                >
                    {tr("Add")}
                </Button>
            </div>
            <List
                id="overtimeManagementList"
                className="settingsList"
                ref={this.refList}
                hideHeader={!headerVisible && multipliers.length === 0}
                // fluid
                height="auto"
                minWidth={400}
                columns={[
                    { width: 90, name: "delete", header: "", ...dColConf },
                    { width: 150, name: "start", header: tr("Start"), tooltipContent: tr("Start tooltip"), ...dColConf },
                    { width: 150, name: "end", header: tr("End"), tooltipContent: tr("End tooltip"), ...dColConf },
                    { width: 150, name: "multiplier", header: tr("Multiplier"), tooltipContent: tr("Multiplier tooltip"), ...dColConf },
                    //{ width: 250, name: "esalary_worktype", header: tr("ESalary worktype"), tooltipContent: tr("Esalary tooltip"), ...dColConf },
                ]}
                newRow={{
                    type,
                    multiplier: 1,
                }}
                sharedData={{
                    multipliers,
                    enqueueSnackbar,
                    tr,
                }}
                listRowType={TimeManagementOvertimeMultiplierRow}
                rowProps={{
                    onCreate: async (data: OvertimeMultiplier) => {
                        try {
                            this.refList.current?.removeNewRow?.(data.id);

                            const saveResult = await DataHandler.put({ url: `settings/company/${company}/overtime/multipliers` }, {
                                multipliers: [
                                    data,
                                ],
                            }) as SaveResult;

                            if (saveResult.status === 'error') {
                                enqueueSnackbar(tr('Saving failed!'), { variant: 'error' });
                            }

                            this.refresh(true);
                        } catch (error) {
                            enqueueSnackbar(tr('Saving failed!'), { variant: 'error' });
                        }

                    },
                    onUpdate: async (data: OvertimeMultiplier) => {
                        try {
                            const saveResult = await DataHandler.put({ url: `settings/company/${company}/overtime/multipliers` }, {
                                multipliers: [
                                    data,
                                ],
                            }) as SaveResult;

                            if (saveResult.status === 'error') {
                                enqueueSnackbar(tr('Saving failed!'), { variant: 'error' });
                            }

                            this.refresh(true);
                        } catch (error) {
                            enqueueSnackbar(tr('Saving failed!'), { variant: 'error' });
                        }
                    },
                    onDelete: async (data: OvertimeMultiplier) => {
                        if (data.id > 0) {
                            data.deleted = true;

                            this.refList.current?.removeRow?.(data.id);

                            const saveResult = await DataHandler.put({ url: `settings/company/${company}/overtime/multipliers` }, {
                                multipliers: [
                                    data,
                                ],
                            }) as SaveResult;

                            if (saveResult.status === 'error') {
                                enqueueSnackbar(tr('Saving failed!', { variant: 'error' }));
                            }

                            this.refresh(true);
                        } else {
                            this.refList.current?.removeNewRow?.(data.id);
                        }
                    },
                    hasEsalaryAddon: this.context.addons.esalary?.used_by_companies.includes(company)
                    // returnTab: this.state.selectedTab
                }}
                onDataChange={this.onDataChange}
                data={multipliers}
            />
        </div>
    }
}

export default withSnackbar(OvertimeMultipliers);