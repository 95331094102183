import React from 'react';
import PropTypes from "prop-types";

/* material ui */
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import withStyles from '@mui/styles/withStyles';
import { Menu, MenuList, MenuItem } from '@mui/material';
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import './ContextMenu.css';

const styles = theme => ({
    arrowdropdown: {
        top: "50%",
        right: "6px",
        width: "18px",
        height: "18px",
        position: "absolute",
        "margin-top": "-9px",
        "border-radius": "18px",
        "background-color": "#f7f8fc",
        "cursor": "pointer",
    
        "& path": {
          color: "#dcdee6"
        }
    
      },
      button: {}
});

export class ContextSubMenu extends React.Component {
    state = {
        "open": false,
    }

    constructor(props) {
        super(props);
        this.anchorEl = React.createRef();
        //this.handleClose = this.handleClose.bind(this);
    }

    onClick = () => {
        const { open } = this.state;        

        this.setState({open: !open});

        this.props.onOpen && this.props.onOpen();
    }

    handleClose = () => {
        this.setState({open: false});

        this.props.onClose && this.props.onClose();
    }

    render() {
        const { icon, title, children } = this.props;
        const { open } = this.state;      

        return (
            <React.Fragment>
                <MenuItem ref={this.anchorEl} onClick={this.onClick}>{icon} <span className="context-submenu-title">{title}</span> <ArrowRightIcon className="context-submenu-arrow-right-icon" /></MenuItem>
                
                <Menu className="contextSubMenu" onClose={this.props.onClose} open={open} anchorEl={this.anchorEl.current} anchorOrigin={{ vertical: 'top', horizontal: 'right',}}>
                    {React.Children.map(children, child => {
                        if (!child) return undefined;
                        return React.cloneElement(child, {
                            onClose: this.handleClose,
                            onClick: child.props.onClick ? (evt) => { 
                                !child.props.noClose && this.handleClose(evt);
                                child.props.onClick(evt);
                        } : undefined});
                    })}
                </Menu>

            </React.Fragment>
        );
    }
}
 
class ContextMenu extends React.Component {
    state = {
        "open": false,
        submenuOpen: false,
    }
    constructor(props) {
        super(props);
        this.anchorEl = React.createRef();
        this.handleClose = this.handleClose.bind(this);
    }
    submenuOpen = () => {
        this.setState({submenuOpen: true});
    }
    handleClose (event) {
        if (event && event.target && this.anchorEl.contains(event.target)) {
          return;
        }

        if (this.state.submenuOpen) {
            this.setState({submenuOpen: false});
            if (!this.props.closeWithSubmenuSelection || event?.type == "mouseleave") { // "mouseleave" check because Tooltip in menuitem caused the menu to close.
                return;
            }
        }

        this.props.onClose && this.props.onClose(event);
        this.setState({open: false}); 
    }
    handleToggle = (event) => {
        if(this.props.disabled) {
            return;
        }

        // event.stopPropagation();
        !this.state.open && this.props.menuOpenCallback();
        this.state.open && this.props.onToggleClose && this.props.onToggleClose(event);
        this.setState({"open": !this.state.open});
    }
    close = () => {
        this.setState({
            open: false
        });
    }
    render() {
        const { open } = this.state;
        const { 
            classes, 
            customGrowId, 
            label, 
            children, 
            placement, 
            size, 
            buttonProps = {}, 
            popperProps, 
            variant, 
            noExpandIcon, 
            disablePortal, 
            dropdownMenu, 
            description = " ", 
            icon, 
            disabled,
            ...rest
        } = this.props;
        
        const containerClasses = [];
        const buttonClasses = ["context-menu", this.props.buttonClassName];
        buttonProps.className   && buttonClasses.push(buttonProps.className);
        open                    && buttonClasses.push("open");
        
        //make menu look like a dropdown menu
        if (dropdownMenu) {
            buttonClasses.push("dropdown");
            buttonProps.disableFocusRipple = true;
            buttonProps.disableElevation = true;
            rest.className += " context-menu-dropdown";
        }
        
        return (
            <div {...rest} onClose={this.handleClose}>
                <Button
                    classes={{root: classes.button}}
                    size={size}
                    disableRipple={true}
                    variant={variant} 
                    ref={node => { this.anchorEl = node; this.props.buttonRef && this.props.buttonRef(node); }} 
                    aria-owns={open ? 'menu-list-grow' : undefined} 
                    aria-haspopup="true" 
                    onClick={this.handleToggle} 
                    {...buttonProps}
                    className={buttonClasses.join(" ")}
                    >
                    {dropdownMenu && <div className="description">
                        {description}
                    </div>}
                    <span className={`context-menu-icon-button-container ${disabled ? "disabled" : ""}`}>
                        {icon}
                        {label}
                    </span>
                    {!noExpandIcon && (<ArrowDropDown className={this.props.classes.arrowdropdown}/>)}
                </Button>
                <Popper open={open} anchorEl={this.anchorEl} placement={placement} transition disablePortal={disablePortal} {...popperProps}>
                    {({ TransitionProps, placement }) => (
                    <Grow  {...TransitionProps}  id={customGrowId || "menu-list-grow"} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} >
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleClose} {...this.props.clickAwayListenerProps}> 
                                <MenuList>
                                    {React.Children.map(children, child => {
                                        if (!child) return undefined;
                                        return React.cloneElement(child, {
                                            onClose: this.handleClose,
                                            onOpen: this.submenuOpen,
                                            onClick: child.props.onClick ? (evt) => { 
                                                !child.props.noClose && this.handleClose(evt);
                                                child.props.onClick(evt);
                                        } : undefined});
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                    )}
                </Popper>
            </div>
        );
    }
}
ContextMenu.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    variant: PropTypes.string,
};
ContextMenu.defaultProps = {
    variant: "text",
    disablePortal: true,
    menuOpenCallback: () => {},
    disabled: false
}
export default withStyles(styles, { withTheme: true })(ContextMenu);
