import React from 'react';
import LinkListCell from "../LinkListCell";
import EditIconSVG from "../icons/edit_cell_icon.svg";
import Link from '../../general/Link';

class MultiLinkListCell extends LinkListCell {
	constructor(props) {
		super(props);
	}

	presentValue(value) {
		if (!value)
			return;

		const urls = this.props.urlHandler(value);
		const val = urls.length > 1 ? this.props.valueHandler(value).split(',') : [value];

		return urls.map((url, i) =>	<Link key={i} url={url}>{val[i]}{urls.length > i+1 ? ', ' : ''}</Link>);
	}
}

MultiLinkListCell.defaultProps = Object.assign({}, LinkListCell.defaultProps, {
	urlHandler: value => value,
	valueHandler: value => value
});

export default MultiLinkListCell;