import React from 'react';
import ListCell from '../../../list/ListCell';
import CurrencyListCell from "../../../list/CurrencyListCell";
import LinkListCell from '../../../list/LinkListCell';
import styles from './InvoiceMaterialBlockRow.module.scss';
import PropsOnlyListRow from "../../../list/PropsOnlyListRow";
import CheckboxCell from "../../../list/cells/CheckboxCell";
import ContextMenu from '../../../general/ContextMenu';
import ThreeDotsIcon from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import DataHandler from '../../../general/DataHandler';
import cn from 'classnames';

import { format, isValid } from "date-fns";
import { CellMeasurer } from 'react-virtualized';
import { formatInputNumber } from '../../../helpers';

export default class InvoiceMaterialBlockRow extends PropsOnlyListRow {
    //quoteRowSliderFields: any[];
    isDuedateOtherFieldsHidden;
    constructor(props, context) {
        super(props, {}, {}, "projects/InvoiceMaterialBlockRow");

        this.isDuedateOtherFieldsHidden = this.props.data?.duedate_repeat > 0 ? false : true;
    }

    componentDidUpdate(prevProps: any, prevState: any): void {

    }

    defineClassName() {
        return styles.row;
    }

    showEditSlider(fields, saveFunc) {
        const { rowProps: { showEditSlider }, data } = this.props;
        showEditSlider({
            onSave: saveFunc,
            fields: fields,
            item: data,
            title: this.tr('Edit row')
        });
    }

    saveQuoteRow(data) {
        const { rowProps: { updateQuoteRow } } = this.props;
        updateQuoteRow(data.costestimate_id, data.material_id, data);
    }



    contextMenuItems(data) {
        const { rowProps: { invoiceFromRow, openQuote, openReceivedinvoice, deleteQuoteRow, updateQuoteRow, products, cpqs, deleteScheduledInvoice, updateScheduledInvoice } } = this.props;
        let menuItems = <></>;
        switch (data.row_type) {
            case "hour_entry": { //hour entry
                menuItems = <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell row-menu" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem  className="menuItem" onClick={() => invoiceFromRow(data.key, data.projects_id)}>{this.tr('Invoice row')}</MenuItem>
                    </ContextMenu>;
                break;
            }
            case "manually_added_cost": {
                menuItems = <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell row-menu" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem  className="menuItem" onClick={() => invoiceFromRow(data.key, data.projects_id)}>{this.tr('Invoice row')}</MenuItem>
                    </ContextMenu>;
                break;
            }
            case "scheduled_invoice": { //scheduled invoice
                const scheduledRowSliderFields = [
                    {
                        title: this.tr('Invoice date'),
                        key: 'creation_date',
                        type: 'date',
                    },
                    Number(data?.cpq_id) > 0 ? 
                    {
                        title: this.tr('CPQ'),
                        key: 'cpq_id',
                        type: 'select',
                        options: cpqs,
                        setOtherValuesWithSelection: (_, value) => {
                            const cpq = cpqs.find(cpq => cpq.id == value.id);
                            return {
                                value: cpq.sum,
                                cpq_id: cpq.id,
                                description: cpq.name,
                            };
                        },
                    }
                    :
                    (Number(data?.product_id) > 0 ?
                    {
                        title: this.tr('Product'),
                        key: 'product_id',
                        type: 'select',
                        options: products,
                        setOtherValuesWithSelection: (_, value) => {
                            const product = products.find(product => product.id == value.id);
                            return {
                                value: product.income_price,
                                product_register_id: product.id,
                                description: product.name,
                            };
                        },
                    } 
                    :
                    {
                        title: this.tr('Description'),
                        key: 'description',
                    }
                    ),
                    {
                        title: this.tr('Description'),
                        key: 'description',
                    },
                    {
                        title: this.tr('Quantity'),
                        key: 'quantity',
                    },
                    {
                        title: this.tr('Value'),
                        key: 'value',
                    },
                    {
                        title: this.tr('vat'),
                        key: 'vat',
                    },
                    {
                        title: this.tr('Interval'),
                        key: 'duedate_repeat',
                        type: 'select',
                        options: [
                            {
                                id: 0,
                                value: 0,
                                label: this.tr('No repeat'),
                            },
                            {
                                id: 1,
                                value: 1,
                                label: this.tr('Every month'),
                            },
                            {
                                id: 2,
                                value: 2,
                                label: this.tr('Every week'),
                            },
                            {
                                id: 3,
                                value: 3,
                                label: this.tr('Every day'),
                            }
                        ],
                        setOtherValuesWithSelection: (_, value) => {
                            if (value.value > 0) {
                                this.isDuedateOtherFieldsHidden = false;
                                return {
                                    duedate_repeat: value.value,
                                };
                            } else {
                                this.isDuedateOtherFieldsHidden = true;
                                return {
                                    duedate_repeat: value.value,
                                    duedate_repeat_increment: "0",
                                };
                            }
                        },
                    },
                    {
                        isHidden: () => {return this.isDuedateOtherFieldsHidden},
                        title: this.tr('Recurring'),
                        key: 'duedate_repeat_increment',
                    },
                    {
                        isHidden: () => {return this.isDuedateOtherFieldsHidden},
                        title: this.tr('Until'),
                        key: 'until',
                        type: 'date',
                    }
                ];
                menuItems = <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell row-menu" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem  className="menuItem" onClick={() => invoiceFromRow(data.key, data.projects_id)}>{this.tr('Invoice row')}</MenuItem>
                        <MenuItem className="menuItem" onClick={() => this.showEditSlider(scheduledRowSliderFields, updateScheduledInvoice)}>{this.tr('Edit')}</MenuItem>
                        <MenuItem  className="menuItem" onClick={() => deleteScheduledInvoice(data.material_id)}>{this.tr('Delete row')}</MenuItem>
                    </ContextMenu>;
                break;
            }
            case "expense": { //expense
                menuItems = <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell row-menu" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem  className="menuItem" onClick={() => invoiceFromRow(data.key, data.projects_id)}>{this.tr('Invoice row')}</MenuItem>
                    </ContextMenu>;
                break;
            }
            case "quote_row": { //quote row
                const quoteRowSliderFields = [
                    {
                        title: this.tr('Quantity'),
                        key: 'quantity',
                    },
                    {
                        title: this.tr('Value'),
                        key: 'value',
                    },
                    data?.is_cpq == "1" ? 
                    {
                        title: this.tr('CPQ'),
                        key: 'cpq_id',
                        type: 'select',
                        options: cpqs,
                        setOtherValuesWithSelection: (_, value) => {
                            const cpq = cpqs.find(cpq => cpq.id == value.id);
                            return {
                                value: cpq.sum,
                                cpq_id: cpq.id,
                            };
                        },
                    }
                    :
                    (Number(data?.product_id) > 0 ?
                    {
                        title: this.tr('Product'),
                        key: 'product_id',
                        type: 'select',
                        options: products,
                        setOtherValuesWithSelection: (_, value) => {
                            const product = products.find(product => product.id == value.id);
                            return {
                                value: product.income_price,
                                product_id: product.id,
                            };
                        },
                    } 
                    :  
                    {
                        title: this.tr('Description'),
                        key: 'description',
                    }),

                ];
                menuItems = <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell row-menu" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem  className="menuItem" onClick={() => invoiceFromRow(data.key, data.projects_id)}>{this.tr('Invoice row')}</MenuItem>
                        <MenuItem  className="menuItem" onClick={() => openQuote(data.costestimate_id)}>{this.tr('Open quote')}</MenuItem>
                        <MenuItem className="menuItem" onClick={() => this.showEditSlider(quoteRowSliderFields, updateQuoteRow)}>{this.tr('Edit')}</MenuItem>
                        <MenuItem  className="menuItem" onClick={() => deleteQuoteRow(data.costestimate_id, data.material_id)}>{this.tr('Delete row')}</MenuItem>
                    </ContextMenu>;
                break;
            }
            case "received_invoice": { //bill
                menuItems = <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell row-menu" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem  className="menuItem" onClick={() => invoiceFromRow(data.key, data.projects_id)}>{this.tr('Invoice row')}</MenuItem>
                        <MenuItem  className="menuItem" onClick={() => openReceivedinvoice(data.receivedinvoice_id)}>{this.tr('Open received invoice')}</MenuItem>
                    </ContextMenu>;
                break;
            }
            case "travel_expense": { //travel expense daily allowance
                menuItems = <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell row-menu" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem  className="menuItem" onClick={() => invoiceFromRow(data.key, data.projects_id)}>{this.tr('Invoice row')}</MenuItem>
                    </ContextMenu>;
                break;
            }
            default: { 
                break;
            }
        }
        return menuItems;
    }

    defineCells() {
        const { data, sharedData: { currency }, rowProps: { onCheck } } = this.props;
        const { taimerAccount, userObject, addons } = this.context;
        const { checked } = this.state;

        /*const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency,
        }).format;*/

        const partialInvoicing = addons.quoterow_partial_invoicing_for_products;

        let cells = {
            context:
            <ListCell permanentEditMode={true} noBorder={true} >
                {this.contextMenuItems(data)}
            </ListCell>
                ,
            checked:
                <CheckboxCell 
                    checked={this.props.checked}
                    /*listCellProps={data.id < 0 && {style: {width: `${columnWidthMap.checked}px`, flexGrow: columnWidthMap.checked, flexShrink:1, flexBasis: '0px'}}}  */
                    onClick={() => {
                        this.props.listRef.check(this.props.data.id);
                        onCheck(this.props.listRef.getCheckedRows());
                    }} />,
            row_type:
                <ListCell
                    name="row_type"
                    value={this.tr(data.row_type)}
                    editable={false} />,
            creation_date:
                <ListCell
                    name="creation_date"
                    value={format(data.creation_date, userObject.dateFormat)}
                    editable={false} />,
            user_or_product:
                data?.users_company && (data.users_company > 0 && this.context.functions.checkPrivilege('admin', 'admin', data.users_company)) || (data.users_company == 0 && this.context.functions.hasPrivilege('admin', 'admin')) ?
                <LinkListCell
                    urlHandler={(value) => `index.html?module=users&action=view&id=${data.users_id}&company=${data.users_company}`}
                    noTab={false}
                    name="user_or_product"
                    value={data.user_or_product}
                    editable={false} />
                :
                <ListCell
                    name="user_or_product"
                    value={data.user_or_product}
                    editable={false} />
                ,
            description: 
                <ListCell
                    name="description"
                    value={data.entry_description || data.description}
                    editable={false} />,
            quantity: 
                <ListCell
                    textAlign="right"
                    name="quantity"
                    value={partialInvoicing && data.is_product_row == 1 ? `${data.costest_quantity} / ${(Number(data.invoiced_elsewhere) + Number(data.quantity)).toFixed(2)}` : Number(data.quantity).toFixed(2)}
                    editable={false} />,                    
            value: 
                <CurrencyListCell
                    name="placeholder"
                    currency={currency}
                    textAlign="right"
                    value={data.value}
                    editable={false} />,
            vat: 
                <ListCell
                    name="vat"
                    value={formatInputNumber(data.vat, 'no-zero-decimals') + ' %'}
                    editable={false} />,
            total_vat: 
                <CurrencyListCell
                    name="placeholder"
                    currency={currency}
                    textAlign="right"
                    value={data.total * (1 + (data.vat / 100))}
                    editable={false} />,
            total: 
                <CurrencyListCell
                    name="total"
                    currency={currency}
                    textAlign="right"
                    value={Number(data.total).toFixed(2)}
                    editable={false} />
            
        };

        cells = {
            ...super.defineCells(),
            ...cells
        };

        return cells;
    }
}