import React from 'react';
import OutlinedField from "./../../general/OutlinedField";
import MenuItem from '@mui/material/MenuItem';
import FilterDropdown from '../../general/FilterDropdown';

class CustomFieldDropdown extends React.Component {
    static defaultProps = {
        disabled: false,
        error: false,
    };

    onChange = (evt) => {
        this.props.onChange(this.props.name, evt.target.value.id)
    }

    render() {
        const { name, value, error, validate, disabled, className, hideNoValueError, menuPlacement, field: {
            name: label, required, options
        } } = this.props;

        const novalue = value === '';

        return  <FilterDropdown
                    fullwidth
                    label={label}
                    className={className}
                    name={name}
                    value={options.find(x => x.id == value)}
                    items={options.filter(x => !x.deleted || x.id == value)}
                    // validation={required ? ["empty"] : []}
                    error={!disabled && (error || (required && validate && novalue && !hideNoValueError))}
                    disabled={disabled}
                    onChange={this.onChange} 
                    menuPlacement={menuPlacement}
                />
    }
}

export default CustomFieldDropdown;
