import React from "react";
import InsightSlider from "../../InsightSlider";
import { useState } from "react";

const TopBlockCard = props => {
  const { item, onClick } = props;
  return (
    <div onClick={onClick} className="top-block-card-container">
      <div className="top-block-card">
        <h2>{item.title}</h2>
        {item.dates && <p className="date-string">{item.dates}</p>}
        <div className="flex-row value-container">
          {item.img && <img className="icon" src={item.img} />}
          <div className="text-container">
            <h1>{item.value}</h1>
            <p>{item.subtitle || ""}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const TopBlock = props => {
  const { currency, items, showSlider } = props;
  const [sliderData, setSliderData] = useState({open: false, data: [], title: "", total: 0, columns: []})

  return (
    <div className="top-block-container">
       <InsightSlider
          open={sliderData.open}
          label={sliderData.title}
          onClose={() => setSliderData({open: false, data: [], title: "", total: 0, columns: []})}
          data={sliderData.data}
          sum={sliderData.total}
          columns={sliderData.columns}
          sortKey={"value"}
          currency={currency}
        />
      <div className="inner">
        {items.map(item => (
          <TopBlockCard onClick={() => showSlider(item)} item={item} />
        ))}
      </div>
    </div>
  );
};

export default TopBlock;
