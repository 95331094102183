import React, { Component } from 'react';

/* css */

/* material-ui */

/* others */
import _ from 'lodash';
import TaimerComponent from "../../../TaimerComponent";

class ActivitiesInsightLegend extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "dashboard/insights/activities/ActivitiesInsightLegend");

        this.legends = [
            { 
                id: 1,
                label: this.tr('Done'),
                color: '#4BFECC'
            },
            { 
                id: 2,
                label: this.tr('Due'),
                color: '#FBAE00'
            },
            { 
                id: 3,
                label: this.tr('Overdue'),
                color: '#F70501'
            }
        ];
    }

    render() {
        return  <div className="grid-block chart-legend">
                    <div className={"legend-box"}>
                        <ul>
                            {_.map(this.legends, legend => {
                                return <li key={legend.id} className={"visible"} >
                                    <div className="legend-color" style={{ backgroundColor: legend.color }} />
                                    <div className="legend-name">{legend.label}</div>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
    }
}

export default ActivitiesInsightLegend;