import React from 'react';
import OutlinedField from "./../../general/OutlinedField";
import { Link } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { prefixLink } from '../../helpers';

import './CustomFieldLink.css'

class CustomFieldLink extends React.Component {
    static defaultProps = {
        disabled: false,
        error: false,
    };

    onChange = (evt) => {
        this.props.onChange(this.props.name, evt.target.value);
    }

    render() {
        const { name, value, error, validate, disabled, field: {
            name: label, required, type
        }, rootRef } = this.props;

        const novalue = !value;

        // if (disabled && !novalue) {
        //     return <Link target="_blank" href={value} rel="noopener">{label}</Link>
        // }

        return <OutlinedField
            InputProps={{classes: {root: 'CustomFieldLink'}}}
            ref={rootRef}
            fullWidth
            label={!required ? label : label +" *"}
            name={name}
            value={value}
            validation={["link"]}
            error={!disabled && (error || (required && validate && novalue))}
            disabled={disabled}
            onChange={this.onChange}
            adornmentPos="end"
            noOnchangeValidation={this.props.noOnchangeValidation}
            adornment={value && <Link target="_blank" href={prefixLink(value)} rel="noopener"><LinkIcon /></Link>} />
    }
}

export default CustomFieldLink;
