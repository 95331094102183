//Used with AutocompleteCell
function createBobOptions(options, level = 0, parent = undefined) {
    let finalOptions = [];

    for(let i = 0; i < options.length; ++i) {
        let tempOpt = options[i];
        let opt = {
            value: tempOpt.data.id,
            name: tempOpt.data.name,
            id: tempOpt.data.id,
            label: tempOpt.data.label,
            data: tempOpt.data,
            deleted: tempOpt.data.deleted,
            children: tempOpt.children,
            parentProps: { data: parent !== undefined ? parent.data : {}, children: parent !== undefined ? parent.children : [], parentProps: parent !== undefined ? parent.parentProps : {} },
            level: level,
            isChild: level > 0,
            isLastChild: level > 0 && i === options.length - 1
        };

        finalOptions.push(opt);

        if(tempOpt.children && tempOpt.children.length > 0)
            finalOptions = [...finalOptions, ...createBobOptions(tempOpt.children, level + 1, opt)];
    }

    return finalOptions;
}

export default createBobOptions;