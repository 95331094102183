import React from 'react';
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Avatar from '@mui/material/Avatar';
import { shadeColor, textColorOnBG } from '../helpers';
import { SettingsContext } from '../SettingsContext';
import './TaimerAvatar.css';
import { getColor } from '../workhours/time-tracker/Colors';

const styles = {
    smallAvatar: {
        height: 30,
        width: 30,
        fontSize: "12px",
    },
    userListAvatar: {
        width: 35,
        height: 35,
        fontSize: "15px",
    },
    mediumAvatar: {
        width: 40,
        height: 40,
        fontSize: "17px",
    },
    largeAvatar: {
        width: 60,
        height: 60,
        fontSize: "32px",
    },
    xLargeAvatar: {
        width: 80,
        height: 80,
        fontSize: "46px",
    },
  };

class TaimerAvatar extends React.Component{
    static contextType = SettingsContext;
    static defaultProps = {
        noavatar: false
    };

    constructor(props, context){
        super(props, context);
        
        this.state = {
            error: false,
        }
    }

    onError = () => {
        this.setState({error: this.props.id})
    }

    render(){

        const { className, classes, name="", id=0, size, color, noavatar, overrideText, ...rest } = this.props;
        const { error } = this.state;
        const { userObject } = this.context;
        const abbr = overrideText || name && (name.match(/(\s+|^)(.)/g) || []).slice(0, 2).join("").replace(/\s/g, "").toUpperCase();
        let s;

        const customColor = getColor({id}, 'user');
        const c = customColor[1] ? customColor[0].substring(1) : color;
        
        const shaded = c && shadeColor(c, 50);
        if(size === "x-large"){
            s = classes.xLargeAvatar;
        }else if(size === "large"){
            s = classes.largeAvatar;
        } else if (size === "userlist") {
            s = classes.userListAvatar;
        } else if (size === "medium") {
            s = classes.mediumAvatar;
        } else {
            s = classes.smallAvatar;
        }
        
        let param = this.props.param ? `&${this.props.param}` : '';
       
        param += '&_auth=' + this.context.functions.getStorage().taimerToken;
        if(userObject.usersId == id)
            param += '&avatarId=' + userObject.avatarId;
        return(
            <Avatar
                className={`${className} avatar`}
                alt=""
                title={name}
                classes={{root: s}}
                style={{backgroundColor: c != null ? `#${c}` : `#ee8139`}}
                imgProps={{onError: this.onError}}
                src={!noavatar && error !== id ? this.context.functions.getDownloadPath() + `attachment=user_profile&id=${id}${param}` : undefined}
                {...rest}>
                    {abbr}

            </Avatar>
        )
    }
}

export default withStyles(styles)(TaimerAvatar);