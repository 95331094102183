function insertAt<T>(arr: T[], index: number, toAdd: T): T[] {
    index = (index < 0)
        ? arr.length + index + 1
        : index;

    arr.splice(index, 0, toAdd);

    return arr;
}

function insertAtFn<T>(arr: T[], fn: (p: T) => boolean, toAdd: T, insertIfNotFound = true): T[] {
    const index: number = arr.findIndex(fn);

    if(index > -1) {
        arr = insertAt<T>(arr, index, toAdd);
    } else if(insertIfNotFound) {
        arr.push(toAdd);
    }

    return arr;
}

function insertAfter<T>(arr: T[], index: number, toAdd: T): T[] {
    return insertAt(arr, index + 1, toAdd);
}

function insertAfterFn<T>(arr: T[], fn: (p: T) => boolean, toAdd: T, insertIfNotFound = true): T[] {
    const index: number = arr.findIndex(fn);

    if(index === -1 && !insertIfNotFound) {
        return arr;
    }

    return insertAfter<T>(arr, index, toAdd);
}

export {
    insertAt,
    insertAtFn,
    insertAfter,
    insertAfterFn
};
