import React from 'react';
import { Grid as VirtualizedGrid, List as VirtualizedList, AutoSizer } from 'react-virtualized';
import moment from 'moment';
import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';
import { Button, Popover, Tooltip } from '@mui/material';
import { ReactComponent as LockIcon } from '../general/icons/lock-filled.svg';
import { ReactComponent as UnLockIcon } from '../general/icons/Unlock-filled.svg';

import TaimerComponent from '../TaimerComponent';

import './RevenueRecognitionTable.css';

class RevenueRecognitionTableHeader extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'projects/RevenueRecognitionTableHeader');
        this._headerDateFormat = 'DD MMM YYYY';
        this._shortHeaderDateFormat = 'MMM YYYY';
        this.grid = React.createRef();
        this.state = {
            monthTitles: this._parseMonths(props.months),
            splitValue: '',
            optionsPopupAnchor: null,
        };
    }

    componentDidUpdate = (oldProps) => {
        if (oldProps.months != this.props.months) {
            this.setState({
                monthTitles: this._parseMonths(this.props.months),
            });
        }

        if (oldProps.data != this.props.data) {
            this.grid.current.forceUpdate();
        }

        if (oldProps.editMode != this.props.editMode) {
            this.grid.current.forceUpdate();
        }
    };

    _parseMonths = (months) => {
        const monthTitles = months.map((month) => month.format(this.props.onlyRead || !this.props.dateEditable ? this._shortHeaderDateFormat : this._headerDateFormat));
        return monthTitles;
    };

    _onInputChange = (value, columnIndex) => {
        const month = this.props.months[columnIndex];
        this.props.setTotal(value, month, columnIndex);
    };

    _renderCellContent = (value, columnIndex, rowIndex, options) => {
        const { editMode, onKeyDown, errors, selectedViewMode, headerViewMode, fieldKey } = this.props;
        const { nonEditable = false, isTotal = false } = options;
        return (
            <div className="text-container">
                <div className="input-container">
                    <input
                        onKeyDown={onKeyDown}
                        onFocus={(e) => e.target.select()}
                        placeholder={editMode && '0'}
                        disabled={!editMode || nonEditable}
                        className={`${editMode && !nonEditable && 'active'} ${errors && errors[columnIndex] == 'header' && 'error'}`}
                        onChange={(e) => this._onInputChange(e.target.value, columnIndex, rowIndex)}
                        onFocus={(e) => {e.target.scrollIntoView({ block: 'nearest', inline: 'center' })}}
                        value={this.props.formatInputValue(value, { nonEditable, isTotal, header: true, headerViewMode })}
                        data-testid={`rr_table_${fieldKey}_header_grid_${columnIndex}_input`}
                    />
                </div>
                {editMode && !nonEditable && <span>{selectedViewMode == 'percentage' ? '%' : this.props.getCurrencySymbol()}</span>}
            </div>
        );
    };

    _gridCellRenderer = ({ columnIndex, rowIndex, key, style }) => {
        const { months, rowHeight, data, noEdit, extraRows = [], dateFormat, useValue, lockDate, checkMonthLock, unlockMonths, fieldKey  } = this.props;
        const month = months[columnIndex].format(dateFormat);
        let value = useValue ? data.totals[month]?.value : data.totals[month];
        const disabled = data.totals[month]?.disabled == '1';
        if (rowIndex < extraRows.length) {
            value = useValue ? extraRows[rowIndex].data.totals[month]?.value : extraRows[rowIndex].data.totals[month];
        }

        let locked = false;

        if (lockDate !== undefined) {
            locked = checkMonthLock(month, unlockMonths);
        }

        const cellContent = (
            <div style={{ height: rowHeight }} className="grid-cell main">
                {this._renderCellContent(value, columnIndex, rowIndex, { nonEditable: noEdit || disabled || locked })}
            </div>
        );

        const viewProps = { module: 'projects', action: 'view', id: this.props.project.id, selectedPage: 'projects-view', selectedTab: 'finances', selectedType: '1' };
        return (
            <div key={key} className={`cell ${columnIndex == months.length - 1 ? 'no-border' : ''}`} style={style}>
                {disabled ? (
                    <Tooltip
                        classes={{ tooltip: 'darkblue-tooltip' }}
                        interactive
                        arrow
                        placement="top"
                        title={
                            <p>
                                {this.tr('Several scheduled invoices for this month. Edit in the scheduled invoices section of the Finances tab.')}{' '}
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.context.functions.updateView(viewProps, true);
                                    }}
                                    href={this.context.functions.urlify(viewProps)}
                                    target="_blank"
                                >
                                    {this.tr('View')}
                                </a>
                            </p>
                        }
                    >
                        {cellContent}
                    </Tooltip>
                ) : (
                    cellContent
                )}
            </div>
        );
    };

    _headerCellRenderer = ({ columnIndex, key, style }) => {
        const { monthTitles } = this.state;
        const { months, editMode, dateEditable: propsDateEditable, dateFormat, setDatePickerData, data, lockDate, checkMonthLock, unlockMonths, fieldKey } = this.props;
        const month = months[columnIndex];
        const monthString = month.format(dateFormat);
        const isDisabled = data.totals[monthString]?.disabled == '1';
        const isSameMonth = months[columnIndex].isSame(moment(), 'month');
        const isPreviousMonth = lockDate > month && !isSameMonth;

        let locked = false;

        if (lockDate !== undefined) {
            locked = checkMonthLock(month, unlockMonths);
        }

        const dateEditable = propsDateEditable && !isDisabled && !locked;
        return (
            <div
                key={key}
                onClick={(editMode && dateEditable) ? (e) => setDatePickerData(e.target, month, columnIndex) : null}
                className={`header-cell ${isSameMonth ? 'blue' : ''} ${editMode && dateEditable && 'clickable'}`}
                style={style}
            >
                <span className={(dateEditable ? 'unlocked' : '')} data-testid={`rr_table_${fieldKey}_date_grid_${columnIndex}`}>{isDisabled ? month.format(this._shortHeaderDateFormat) : monthTitles[columnIndex]}{locked ? <LockIcon /> : unlockMonths && isPreviousMonth ? <UnLockIcon /> : ''}</span>
            </div>
        );
    };

    _leftCellRenderer = ({ key, style, index }) => {
        const { title, showRows, data, extraRows = [] } = this.props;

        let label = title;
        let rows = data.rows;
        if (index < extraRows.length) {
            label = extraRows[index].title;
            rows = extraRows[index].rows;
        }
        return (
            <div onClick={rows && this.props.toggleShowRows} key={key} className="cell" style={style}>
                <div style={{ height: this.props.rowHeight }} className="left-cell main clickable">
                    {label}
                    {(rows && rows.length > 0) && (showRows ? <ArrowDropUpRounded /> : <ArrowDropDownRounded />)}
                </div>
            </div>
        );
    };

    _formatNumber = (number) => {
        if (!number) return 0;
        return Number(String(number).replace(',', '.'));
    };

    _rightCellRenderer = ({ key, style }) => {
        const { rowHeight, data, months, dateFormat, useValue } = this.props;
        let rowTotal = 0;
        months.forEach((m) => {
            //the total amount does not change if only "-" is entered:
            if ((data.totals[m.format(dateFormat)] != "-") && (data.totals[m.format(dateFormat)]?.value != "-"))
                rowTotal += this._formatNumber(useValue ? data.totals[m.format(dateFormat)]?.value : data.totals[m.format(dateFormat)]);
        });
        return (
            <div key={key} className="cell" style={style}>
                <div style={{ height: rowHeight }} className="right-cell main">
                    {this._renderCellContent(rowTotal, null, null, { isTotal: true, nonEditable: true })}
                </div>
            </div>
        );
    };

    resetSplitValue = () => {
        this.setState({
            splitValue: '',
        });
    };

    _splitEvenly = () => {
        this.setState({
            optionsPopupAnchor: null,
        });
        this.props.onSplitEvenly(this.state.splitValue);
    };

    _splitAccordingToRevenueRecognition = () => {
        this.setState({
            optionsPopupAnchor: null,
        });
        this.props.onSplitAccordingToRevenueRecognition();
    };

    _setSplitValue = (e) => {
        const splitValue = this.props.formatDecimals(e.target.value);
        if (this.props.isInputValueInvalid(splitValue)) return;
        this.setState({
            splitValue: splitValue,
        });
    };

    _handleSplitKeyDown = (e) => {
        if (e.key == 'Enter') {
            this._splitEvenly();
        } else if (e.key == 'Escape') {
            this.props.onKeyDown(e);
        }
    };

    _closeOptionsPopup = () => {
        this.setState({
            optionsPopupAnchor: null,
        });
    };

    _setOptionsPopupAnchor = (e) => {
        this.setState({
            optionsPopupAnchor: e.target,
        });
    };

    _renderLeftTable = () => {
        const { extraRows = [], leftTableWidth, headerHeight, rowHeight, editMode, noEdit, optionsInSplit } = this.props;
        const { optionsPopupAnchor } = this.state;
        return (
            <div className="static-table-container">
                <div
                    style={{
                        width: leftTableWidth,
                        height: headerHeight,
                    }}
                    className="header"
                >
                    {editMode && !noEdit && (
                        <div className="split-container">
                            <div className="input-container">
                                <input onKeyDown={this._handleSplitKeyDown} value={this.state.splitValue} placeholder="0" onChange={this._setSplitValue} className="active" />
                            </div>

                            {!optionsInSplit ? (
                                <Button className="edit-btn" onClick={this._splitEvenly} data-testid={`rr_table_split_evenly_button`}>
                                    {this.tr('Split evenly')}
                                </Button>
                            ) : (
                                <Button className="edit-btn" onClick={this._setOptionsPopupAnchor} data-testid={`rr_table_split_options_button`}>
                                    {this.tr('Options')}
                                </Button>
                            )}
                            <Popover
                                anchorEl={optionsPopupAnchor}
                                open={!!optionsPopupAnchor}
                                onClose={this._closeOptionsPopup}
                                className="revenue-recognition-options-popup"
                                anchorOrigin={{
                                    vertical: 33,
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <div className="btn-container">
                                    <Button onClick={this._splitEvenly} data-testid={`rr_table_split_options_split_evenly`}>{this.tr('Split evenly')}</Button>
                                    <Button onClick={this._splitAccordingToRevenueRecognition} data-testid={`rr_table_split_options_split_according`}>{this.tr('Split according to revenue recognition')}</Button>
                                </div>
                            </Popover>
                        </div>
                    )}
                </div>
                <VirtualizedList
                    className="no-outline no-scroll-bar left-table"
                    style={{
                        borderLeft: '1px solid #eee',
                        marginTop: headerHeight,
                    }}
                    width={leftTableWidth}
                    height={rowHeight * (1 + extraRows.length)}
                    rowCount={1 + extraRows.length}
                    rowHeight={rowHeight}
                    rowRenderer={this._leftCellRenderer}
                />
            </div>
        );
    };

    _renderRightTable = () => {
        const { extraRows = [], verticalScrollBarWidth, rightTableWidth, headerHeight, rowHeight } = this.props;
        return (
            <div className="static-table-container">
                <div
                    style={{
                        width: rightTableWidth,
                        height: headerHeight,
                        left: -verticalScrollBarWidth,
                    }}
                    className="header"
                >
                    {this.tr('Total')}
                </div>
                <VirtualizedList
                    className="no-outline no-scroll-bar right-table"
                    style={{
                        borderLeft: '1px solid #eee',
                        marginTop: headerHeight,
                        marginLeft: -verticalScrollBarWidth,
                    }}
                    width={rightTableWidth}
                    height={rowHeight * (1 + extraRows.length)}
                    rowCount={1 + extraRows.length}
                    rowHeight={rowHeight}
                    rowRenderer={this._rightCellRenderer}
                />
            </div>
        );
    };

    _renderGrid = () => {
        const { months, showRows, onScrollbarPresenceChange } = this.props;
        const { verticalScrollBarWidth, horizontalScrollBarWidth, columnWidth, rowHeight, headerHeight, onScroll, scrollLeft, extraRows = [] } = this.props;
        return (
            <div className="grid">
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <div>
                            <div>
                                <VirtualizedGrid
                                    scrollLeft={scrollLeft}
                                    onScroll={(e) => onScroll({ ...e, scrollTop: null })}
                                    overscanColumnCount={0}
                                    className="no-outline no-scroll-bar"
                                    cellRenderer={this._headerCellRenderer}
                                    columnCount={months.length}
                                    columnWidth={Math.max(columnWidth, (width - verticalScrollBarWidth) / months.length)}
                                    height={headerHeight}
                                    rowCount={1}
                                    rowHeight={headerHeight}
                                    width={width - verticalScrollBarWidth}
                                />
                            </div>
                            <div>
                                <VirtualizedGrid
                                    ref={this.grid}
                                    overscanColumnCount={0}
                                    cellRenderer={this._gridCellRenderer}
                                    columnCount={months.length}
                                    onScrollbarPresenceChange={onScrollbarPresenceChange}
                                    className={`no-outline main-grid ${showRows && 'no-scroll-bar'}`}
                                    scrollLeft={scrollLeft}
                                    onScroll={(e) => onScroll({ ...e, scrollTop: null })}
                                    columnWidth={Math.max(columnWidth, (width - verticalScrollBarWidth) / months.length)}
                                    height={rowHeight * (1 + extraRows.length) + (showRows ? 0 : horizontalScrollBarWidth)}
                                    rowCount={1 + extraRows.length}
                                    rowHeight={rowHeight}
                                    width={width}
                                />
                            </div>
                        </div>
                    )}
                </AutoSizer>
            </div>
        );
    };

    render() {
        return (
            <div className="content">
                {this._renderLeftTable()}
                {this._renderGrid()}
                {this._renderRightTable()}
            </div>
        );
    }
}

export default RevenueRecognitionTableHeader;
