import { parseHexColor, textColorOnBG } from '../../helpers';
import moment from "moment";


let overrides = {
    user: {},
    project: {},
};
const taskColors = {
    overdue: 'f7548f',
    in_progress: '9baeb7',
    // done: '50e3c2',
    done: '20C6A1',
};
const colorClasses = {
    user: {

    },
    project: {
    },
};
let nextColor = 1;

const style = document.createElement("style");
document.head.appendChild(style);

export function setColorOverride(type, id, color)
{
    overrides[type][id] = color;

    getColorClass({id, color}, type, true);

    const ev = new Event("ColorOverrideChanged");
    document.dispatchEvent(ev);
}

export function setColorOverrides(data)
{
    overrides = data;
}

export function getColorOverrides()
{
    return overrides;
}

const defaultColor = ["#F9F9F9", 0];

// Get color for user/project colors
export function getColor(obj, type) {
    if (obj === undefined)
        return "";

    // User Color
    if (type === 'user' && 'user' in obj) {
        return getColor(obj.user, 'user');
    }
    else if (type === 'project' && 'subproject' in obj && obj.subproject.id) {
        return getColor(obj.subproject, 'project');
    }
    else if (type === 'project' && 'project' in obj) {
        return getColor(obj.project, 'project');
    }

    const id = obj.id;

    if (id === undefined)
        return defaultColor;

    let color = obj.color;

    // Restore color override
    if (type === 'project' && id in overrides[type])
        color = overrides[type][id];

    else if (type === 'user' && id in overrides[type])
        color = overrides[type][id];

    if (color === undefined || color === "FFFFFF")
        return defaultColor;

    return ["#" + color, id];
}

// Creates class for user/project colors
export function getColorClass(obj, type, force) {
    if (obj === undefined)
        return "";
    let color, id;
    if(obj.is_task > 0) {
        if(obj.done > 0) {
            id = 'done';
        } else if(moment(obj.end_date)._d < new Date()) {
            id = 'overdue';
        } else {
            id = 'in_progress';
        }
        color = taskColors[id];
    } else {
        [color, id] = getColor(obj, type);
    }

    let className = type + "color_" + id;

    if (force)
        className += "_" + nextColor++;

    // Create class only once
    if (!(id in colorClasses[type]) || force) {
        let alpha = "0.22";

        const top = parseHexColor(color);
        let bg = parseHexColor(color);

        if (bg.r > 239 && bg.g > 239 && bg.b > 239)
        {
            bg = parseHexColor("F9F9F9");
            alpha = "0.66";
        }

        let borderType = "solid";

        if (obj.isWorktripProject) {
            borderType = "dashed";
        }

        style.sheet.insertRule(".rbc-event." + className + " { " +
            "	border-top: 2px " + borderType + " rgb(" + top.r + ", " + top.g + ", " + top.b + ");" +
            (!obj.is_task ? "	background: rgba(" + bg.r + ", " + bg.g + ", " + bg.b + ", " + alpha + ");" : '') +
            "}"
        );

        style.sheet.insertRule(".UsersListUser." + className + " { " +
            "	background: rgb(" + top.r + ", " + top.g + ", " + top.b + ");" +
            "}"
        );

        style.sheet.insertRule("." + className + " .rbc-ball { " +
        "	background: rgb(" + bg.r + ", " + bg.g + ", " + bg.b + ");" +
        "}");

        style.sheet.insertRule("." + className + " .rbc-rec { " +
        "	background: rgb(" + bg.r + ", " + bg.g + ", " + bg.b + ");" +
        "}");

        style.sheet.insertRule("." + className + " .rbc-rec-list { " +
        "	background: rgb(" + bg.r + ", " + bg.g + ", " + bg.b + ");" +
        "}");

        const styleName = className + " event-" + textColorOnBG(bg);
        colorClasses[type][id] = styleName;
    }

    return colorClasses[type][id];
}