import React from "react";
import "./WelcomeBar.css";

const WelcomeBar = props => {
  const {
    tr,
    usersName,
    data: { thingsToComplete, trackedPercentage }
  } = props;
  const splitted = (usersName || "").split(" ") || [];
  const firstName = splitted[splitted.length - 1];

  let subString = [];
  if (!Object.keys(thingsToComplete).length) {
    subString = [
      `${tr("You have tracked")} ${trackedPercentage}% ${tr(
        "of your daily goal!"
      )}`
    ];
  } else {
    subString = [tr("You have ")];
    const keys = Object.keys(thingsToComplete);
    keys.forEach((key, i) => {
      subString.push(
        <span className="highlight">{`${thingsToComplete[key]} ${tr(
          key
        )}`}</span>
      );
      let divider = i == keys.length - 2 ? ` ${tr("and")} ` : ", ";
      if (i == keys.length - 1) {
        divider = ` ${tr("to complete today")} ${tr("and")} `;
      }
      subString.push(divider);
    });
    subString.push(`${tr("You have tracked").toLowerCase()} `);
    subString.push(
      <span className="highlight">{`${trackedPercentage}%`}</span>
    );
    subString.push(` ${tr("of your daily goal!")}`);
  }

  return (
    <div className="welcome-bar-container">
      <div className="welcome-bar">
        <div className="text-container">
          <h1 data-testid="my-day-header">{`${tr("Hello")} ${firstName}!`}</h1>
          <p>{tr("Here you can view and manage your hours and activities.")/* temporary static header */}</p>
        </div>
        <img src={require("../assets/cool_guy.svg").default} />
      </div>
    </div>
  );
};

export default WelcomeBar;
