import { DataTypeDefinition } from 'ag-grid-community';
import moment from 'moment';
import { roundToDecimals, roundToFixed, roundPercent } from '../general/MathUtils';

interface ExtraData {
    selectedCurrency?: string;
}

export default function getDataTypeDefinitions(context, extra: ExtraData = {}): {[key: string]: DataTypeDefinition} /* fix context */ {
    return {
        percentage: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueParser: params => Number(params.newValue),
            valueFormatter: params => {
                const decimals: number = params.colDef?.decimalAmount ?? 2;

                return params.value == null || isNaN(params.value)
                    ? ''
                    : `${roundPercent(params.value, decimals)} %` 
            }
        },
        date: {
            baseDataType: 'date',
            extendsDataType: 'date',
            valueParser: params => {
                if (params.newValue == null) {
                   return null;
                }
                const dateParts = params.newValue.split('-');
                return dateParts.length === 3 ? new Date(
                    parseInt(dateParts[0]),
                    parseInt(dateParts[1]) - 1,
                    parseInt(dateParts[2])
                ) : null;
            },
            valueFormatter: params => {
                if (Array.isArray(params.value)) {
                    const values: string[] = [];

                    for (const val of params.value) {
                        const value = val ? moment(val) : null;
                        
                        if (!value || !value.isValid()) {
                            continue;
                        }

                        values.push(value.format(context.userObject.dateFormat));
                    }

                    return values.join(', ');
                } else {
                    const value = params.value ? moment(params.value) : null;

                    return (!value || !value.isValid())
                        ? ''
                        : value.format(context.userObject.dateFormat);
                }
            },
        },
        time: {
            baseDataType: 'date',
            extendsDataType: 'date',
            valueParser: params => {
                if (params.newValue == null) {
                   return null;
                }
                const dateParts = params.newValue.split('-');
                return dateParts.length === 3 ? new Date(
                    parseInt(dateParts[0]),
                    parseInt(dateParts[1]) - 1,
                    parseInt(dateParts[2])
                ) : null;
            },
            valueFormatter: params => {
                if (Array.isArray(params.value)) {
                    const values: string[] = [];

                    for (const val of params.value) {
                        const value = val ? moment(val) : null;
                        
                        if (!value || !value.isValid()) {
                            continue;
                        }

                        values.push(value.format('LT'));
                    }

                    return values.join(', ');
                } else {
                    const value = params.value ? moment(params.value) : null;

                    return (!value || !value.isValid())
                        ? ''
                        : value.format('LT');
                }
            },
        },
        link: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
        number: {
            extendsDataType: 'number',
            baseDataType: 'number',
            //valueParser: params => Number(params.newValue),
            valueFormatter: params => { 
                if (params.value === undefined || params.value === null || isNaN(Number(params.value))) {
                    return '';
                }

                const postfix = params.colDef['postfix'];
                const decimalAmount = postfix == "h" 
                    ? 2
                    : params.colDef['decimalAmount'];

                const val = (decimalAmount || decimalAmount === 0)
                    ? roundToFixed(params.value, decimalAmount) 
                    : roundToDecimals(Number(params.value), 2);
                
                return val + (postfix ? ' ' + postfix : '')
            },   
        },
        hour: {
            extendsDataType: 'number',
            baseDataType: 'number',
            //valueParser: params => Number(params.newValue),
            valueFormatter: params => {
                if (params.value === undefined || params.value === null || isNaN(Number(params.value))) {
                    return '';
                }

                const formatted = (Number(params.value).toFixed(2)) + (params.colDef['postfix'] ? ' ' + params.colDef['postfix'] : '');
                return formatted;
            },
        },
        currency: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueParser: params => Number(params.newValue),
            valueFormatter: params => {
                const currency = params.colDef['noCurrencyConvert'] || params['data']?.useDataCurrency
                    ? params['data']?.currency
                    : extra.selectedCurrency;

                const value = params.value && typeof params.value === "object" // If "avg" aggregate function is selected, params.value is an object in grouped row.
                    ? params.value['value'] 
                    : params.value;

                const numericValue = Number(value);

                if (value === undefined || value === null || isNaN(numericValue)) {
                    return null;
                }
                
                return context.functions.presentCurrency(numericValue, currency);
            },
        },
    }
    
}

            
