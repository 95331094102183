import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import TaimerComponent from "../TaimerComponent";

/*material ui */
import Button from '@mui/material/Button';

/* chart js */
import { Line } from 'react-chartjs-2';

/* data backend */
import DataHandler from './../general/DataHandler';

/* css */
import './TabAsiakastieto.css';

/* asiakastieto logo */
import logo from './asiakastieto.jpg';
import PageTopSection from '../general/PageTopSection';

class TabAsiakastieto extends TaimerComponent {
    state = {
        asiakastietoSource: "fi",
        asiakastieto_basic: "",
        asiakastieto_graphs: {dates: [], profits: [], revenues: [], equityRatios: []},
        asiakastieto_ratios: "",
    }
    constructor(props, context) {
        super(props, context, "accounts/TabAsiakastieto");
    }
    
    updateComponentData() {
        const { id } = this.props;
 
        DataHandler.get({url: `accounts/${id}/asiakastieto`}).done(response => {
            if (response.asiakastieto_graphs) {
                const asiakastieto_graphs = JSON.parse(response.asiakastieto_graphs);
                asiakastieto_graphs.dates.unshift("");
                asiakastieto_graphs.dates.push("");
                asiakastieto_graphs.revenues.unshift(null);
                asiakastieto_graphs.revenues.push(null);
                asiakastieto_graphs.profits.unshift(null);
                asiakastieto_graphs.profits.push(null);
                this.setState({
                    asiakastieto_basic: response.asiakastieto_basic,
                    asiakastieto_graphs,
                    asiakastieto_ratios: response.asiakastieto_ratios,
                })
            } else {
                this.setState({asiakastietoSource: undefined});
            }
        });
    }
    componentDidMount() {
        this.updateComponentData();
    }
    buy = () => {
        //this.context.mixpanel.track('Redirect Asiakastieto');
        const vatid = this.props.account.vatid.replace(/[^0-9]/g, "");
        const win = window.open(`https://www.asiakastieto.fi/yritykset/fi/oy/${vatid}/rekisteritiedot`, '_blank');
    }
    buyFinancial = () => {
        //this.context.mixpanel.track('Redirect Asiakastieto');
        const vatid = this.props.account.vatid.replace(/[^0-9]/g, "");
        const win = window.open(`https://www.asiakastieto.fi/yritykset/fi/oy/${vatid}/taloustiedot`, '_blank');
    }
    
    render () {
        const {asiakastieto_basic, asiakastieto_graphs, asiakastieto_ratios} = this.state;
        const { tr } = this;
         
        const intToString = function (value) {
            let newValue = 0;
            switch (("" + Math.abs(value)).length) {
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                    newValue = value / 1000000 + "M";
                    break;
                case 6:
                case 5:
                case 4:
                    newValue = value / 1000 + "k";
                    break;
                default:
                    newValue = value;
                    break;
            }

            return newValue;
        }

        const lineOptions = {
            bezierCurve: false,
            animation: false,
            pointStyle: 'circle',
            pointDotRadius: 5,
            pointDotStrokeWidth: 3,
            datasetFill: false,
            datasetStrokeWidth: 3,
            scaleShowVerticalLines: false,
            scaleGridLineColor: "rgba(0,0,0,0.20)",
            responsive: true,
            maintainAspectRatio: true,
            scaleLineColor: "rgba(0,0,0,.0)",
            pointHitDetectionRadius: 5,
            tooltipFillColor: "rgba(238, 238, 238,1)",
            tooltipFontColor: "black",
            tooltipTemplate: "<%= addThousandSeparator(value) %>",
            scaleLabel: function (label) {
                return intToString(label.value)
            }
        };

        const revenueData = {
            labels: asiakastieto_graphs.dates,
            datasets: [
                {
                    label: "Revenue",
                    strokeColor: "rgba(0, 162, 192,1)",
                    fillColor: "rgba(0, 162, 192,1)",
                    pointColor: "white",
                    pointStrokeColor: "rgba(0, 162, 192,1)",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: asiakastieto_graphs.revenues
                }
            ]
        };
        const profitData = {
            labels: asiakastieto_graphs.dates,
            datasets: [
                {
                    label: "Profit",
                    strokeColor: "rgba(0, 162, 192,1)",
                    fillColor: "rgba(0, 162, 192,1)",
                    pointColor: "white",
                    pointStrokeColor: "rgba(0, 162, 192,1)",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: asiakastieto_graphs.profits
                }
            ]
        };
 
        return (
            <div id="accounts-asiakastieto">
                {this.props.header && (
                    <PageTopSection
                        header={this.props.header}
                        subheaders={this.props.subheaders}
                    />
                )}
                <div className="content">
                    <div className="column">
                        {/*<h2>{tr("Key Figures")}</h2>*/}
                        <div className="asiakastieto_basic">{ReactHtmlParser(asiakastieto_basic)}</div>
                        <div className="asiakastieto_ratios">{ReactHtmlParser(asiakastieto_ratios)}</div>
                        <div className="asiakastieto_chart_box">
                            <h2>{tr("Revenue")}</h2>
                            <Line data={revenueData} options={lineOptions} />
                        </div>
                        <div className="asiakastieto_chart_box">
                            <h2>{tr("Profit")}</h2>
                            <Line data={profitData} options={lineOptions} />
                        </div>
                    </div>
                    <div className="asiakastieto_chart_box">
                        <h2>{tr("Profit")}</h2>
                        <Line data={profitData} options={lineOptions} />
                    </div>
                </div>
                <div className="column">
                    <div className="buy-option">
                        <h3>{`${tr("Seller's Report")}${this.state.asiakastietoSource ? '' : ` (${tr("Available in Finland only")})`}`}</h3>
                        {tr("Get a quick overview of the company and its decision makers for a profitable sales job.")}
                        <Button onClick={this.buy} size='large' color='primary'>{tr("BUY NOW 6.79 €")}</Button>
                    </div>
                    <div className="buy-option">
                        <h3>{`${tr("Financial Information")}${this.state.asiakastietoSource ? '' : ` (${tr("Available in Finland only")})`}`}</h3>
                        {tr("See the companys financial status as well as a comparison of the industry.")}
                        <Button onClick={this.buyFinancial} size='large' color='primary'>{tr("BUY NOW 12.91 €")}</Button>
                    </div>
                    <div className="buy-option">
                        <h3>{`${tr("Credit History")}${this.state.asiakastietoSource ? '' : ` (${tr("Available in Finland only")})`}`}</h3>
                        {tr("Check the company for possible payment defaults and official registrations.")}
                        <Button onClick={this.buyFinancial} size='large' color='primary'>{tr("BUY NOW 6.79 €")}</Button>
                    </div>
                    <div className="buy-option powered-by">
                        <img src={logo} alt='asiakastieto' />
                        {tr("Powered By")}
                    </div>
                </div>
            </div>
        );
    }
    
    
}

export default TabAsiakastieto;