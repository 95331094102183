
import TrashCanIcon from "@mui/icons-material/Delete";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import DateCell from "../../list/cells/DateCell";
import TextAreaCell from "../../list/cells/TextAreaCell";
import TextInputCell from "../../list/cells/TextInputCell";
import LinkListCell from "../../list/LinkListCell";
import ListCell from "../../list/ListCell";
import SettingRow from "../../list/rows/SettingRow";

export default class TimeManagementSettingRow extends SettingRow {
    editAndSave(name, value) {
        const data = cloneDeep(this.state.data);
        data[name] = value;

        if(isEqual(data, this.state.data) && data[this.props.rowKey] > 0) {
            return;
        }

        this.setData(data);

        if(data[this.props.rowKey] < 0) {
            this._create(data);

            return;
        }

        this.props.listRef.editData(data);

        this.update(data);
    }

    render() {
        const cells = {
            delete: (
                <ListCell
                    className="noBg pointer"
                    onClick={() => this.delete()}
                    permanentEditMode={true}
                    width={this.props.columnWidthMap["delete"]}
                >
                    <TrashCanIcon />
                </ListCell>
            ),
            user: (
                <AutoCompleteCell
                    listCellType={LinkListCell}
                    listCellProps={{
                        urlHandler: v => `index.html?module=users&action=view&id=${v.id}&returnmodule=settings&returnaction=index&page=time-management&selectedTab=${this.props.rowProps.returnTab}&group=features`,
                        valueHandler: v => v.name,
                        inEditMode:this.state.data.id < 0,
                        openMenuOnFocus: this.state.data.id < 0,
                    }}
                    value={this.state.data["users_id"]}
                    width={this.props.columnWidthMap["user"]}
                    autoCompleteData={this.props.sharedData}
                    menuIsOpen={this.state.data.id < 0}
                    onEdited={v => {
                        this.editAndSave("users_id", v.id);
                    }}
                />
            ),
            date: (
                <DateCell width={this.props.columnWidthMap["date"]} value={this.state.data["date"]} editable={false} />
            ),
            paydate: (
                <DateCell
                    listCellProps={{
                        className: "paydate-cell"
                    }}
                    width={this.props.columnWidthMap["paydate"]}
                    value={this.state.data["paydate"]}
                    name="paydate"
                    onEdited={(name, value) => {
                        this.editAndSave(name, value);
                    }}
                />
            ),
            hours: (
                <TextInputCell
                    width={this.props.columnWidthMap["hours"]}
                    value={this.state.data["hours"]}
                    name="hours"
                    onEdited={(name, value) => {
                        this.editAndSave(name, value);
                    }}
                />
            ),
            description: (
                <TextAreaCell
                    listCellProps={{
                        className: "description-cell",
                        showTooltipForOverflownText: String(this.state.data["description"]).trim() !== ""
                    }}
                    width={this.props.columnWidthMap["description"]}
                    value={this.state.data["description"]}
                    name="description"
                    title={this.state.data["description"]}
                    onEdited={(name, value) => {
                        this.editAndSave(name, value);
                    }}
                />
            ),
        };

        return (
            <div
                className="listElement row flex"
                style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
            >
                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}
