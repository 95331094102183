import {
  ColDef,
} from 'ag-grid-community';
import TaimerComponent from '../TaimerComponent';
import { DisplayDataObject, getVariableDisplayValue } from './ColumnTypes';
import _ from 'lodash';

interface Props {
    value: string;
    displayDatas: DisplayDataObject[];
    data: object;
    colDef: ColDef;
    node: {
      field: string;
      group: boolean;
      footer: boolean;
      expanded: boolean;
      aggData: object;
      allLeafChildren: {data}[];
    };
    column: {
      colId: string;
    },
    api?:any;
    groupedDataValue?: string | number | boolean;
    tr?: (text, replacers?: Record<string, string>) => string;
}

export default class VariableCellRenderer extends TaimerComponent<Props> {
  render() {
    const displayDatas = _.cloneDeep(this.props.displayDatas);

    const value = this.props.hasOwnProperty("groupedDataValue") // value can be false, so doesn't work if check this.props.groupedDataValue
      ? this.props.groupedDataValue
      : this.props.data && (this.props.data[this.props.colDef.field || ''] || 0);

    return getVariableDisplayValue(value, displayDatas, this.props.tr);
  }
}