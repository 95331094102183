import React from "react";

import _, { cloneDeep, isEqual } from "lodash";
import moment from "moment";
import MyHours from "./MyHours";
import { Calendar } from "react-big-calendar";
import MenuItem from "@mui/material/MenuItem";
import BulkEntry from "./BulkEntry";
import IconButton from "@mui/material/IconButton";
import DataHandler from "../../general/DataHandler";
import CheckboxMUI from "@mui/material/Checkbox";
import CalendarView from "./CalendarView";
import ModifiedHours from "./ModifiedHours";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import TaimerComponent from "../../TaimerComponent";
import NoPermissionOverlay from "../../overlays/NoPermissionOverlay";
import { eachDayOfInterval, parse } from "date-fns";
import { Tabs, Tab, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Collapse, Radio } from "@mui/material";
import { SettingsContext } from "../../SettingsContext";
import { getColorOverrides, setColorOverrides, setColorOverride } from "./Colors.js";
import { getProjectsByID, postWorkhourEntry, postTaskQuick } from "../../Data";
import cn from 'classnames';
import Loading from '../../dashboard/insights/img/loading.svg';
import "./TimeTracker.css";
import PageTopSection from "../../general/PageTopSection";
import { formatInputNumber } from '../../helpers';
import SubmitHoursDialog from "./SubmitHoursDialog";
import { Event, FormatListBulleted, ViewModule, CheckCircle, CheckCircleOutline, AssignmentReturned, CheckBox, ExpandMore, ExpandLess, Palette, EventNote } from "@mui/icons-material";
import debounce from 'lodash/debounce';
import { withSnackbar } from 'notistack';
import CloudDownload from '@mui/icons-material/CloudDownload';
import { format } from "date-fns";
import WithTabs from "../../navigation/WithTabs";
import { getAutocompleteDataForDialog } from '../../resourcing/helpers';
import VersionContentManager from "../../general/VersionContentManager";

require("moment-timezone");

const DnDCalendar = withDragAndDrop(Calendar, { backend: false });

// Time Tracker Tabs
class TimeTracker extends TaimerComponent {
    static contextType = SettingsContext;

    localStorageUsers = false;
    localStorageColors = false;
    localStorageViewEvents = false;
    dataLoaded = false;

    constructor(props, context) {
        super(props, context, "workhours/time-tracker/TimeTracker");

        const { userObject, timeTracker } = context;

        const prefix = "TimeTracker_" + userObject.usersId; // TODO
        this.localStorageUsers = prefix + "_Users";
        this.localStorageColors = prefix + "_Colors";
        this.localStorageViewEvents = prefix + "_ViewEvents";
        this.localStorageColorOverride = prefix + "_ColorOverride";
        this.localStorageTab = prefix + "_LatestTab";
        this.localStorageBulkDays = prefix + "_BulkDays";

        const tab = this.props.tab;

        let selectedBulkDays = [1, 2, 3, 4, 5];

        try {
            const bulkDays = localStorage.getItem(this.localStorageBulkDays);
            if (bulkDays) {
                selectedBulkDays = JSON.parse(bulkDays) || selectedBulkDays;
            }
        } catch (e) {
            console.log(e);
        }

        const dateRange = {
            startDate: format(
                moment()
                    .startOf("month")
                    .toDate(),
                "YYYY-MM-DD"
            ),
            endDate: format(
                moment()
                    .endOf("month")
                    .toDate(),
                "YYYY-MM-DD"
            ),
            key: "selection",
        };

        this.state = {
            colorsToUse: "project", // "project"
            events: [],
            filteredEvents: [],
            selectedUser: {
                id: userObject.usersId,
            },
            currentUser: userObject.usersId,
            fullDay: true,
            startHour: new Date(2018, 1, 1, 0, 0, timeTracker.startTime[2] || 0),
            endHour: new Date(
                2018,
                1,
                1,
                (timeTracker.endTime[0] || 24) - 1,
                59,
                59
            ),
            workhourbalance: null,
            overtimebalance: null,
            workhourbalances: {},
            sidebarProjects: [],
            sidebarUsers: [],
            loading: false,
            loadedRange: false,
            showUserAddDialog: false,
            resourcingAutoCompleteData: false,
            showHourEntries: true,
            showTasks: false,
            showTasksInProgress: false,
            showTasksOverdue: false,
            showTasksDone: false,
            preventMulticompanyHourentries: false,
            selectedBulkDays,
            lastEntry: {
                worktask: {
                    id: 0,
                    name: "",
                },
            },
            workdays: {},
            showApprovals: false,
            overtime_description_mandatory: false,
            calendarReady: false,
            showInCalendarOpen: false,
            showViewEntriesBy: false,
            tab,
            dateRange,
            approvalSubmitTotals: {},
            approvalRevokeTotals: {},
            hasApprovalCompanies: false,
            enable_approval_submitting: 0,
            show_approval_summary: 0,
            visibleView: null,
            visibleRangeStart: null,
            visibleRangeEnd: null,
            approve_settings: {},
        };

        this.bulkDays = [
            {
                title: this.tr("Monday"),
                value: 1,
            },
            {
                title: this.tr("Tuesday"),
                value: 2,
            },
            {
                title: this.tr("Wednesday"),
                value: 3,
            },
            {
                title: this.tr("Thursday"),
                value: 4,
            },
            {
                title: this.tr("Friday"),
                value: 5,
            },
            {
                title: this.tr("Saturday"),
                value: 6,
            },
            {
                title: this.tr("Sunday"),
                value: 0,
            }
        ]

        this.dialogs = {
            submitHours: SubmitHoursDialog
        };

        this.isVisibleUser = this.isVisibleUser.bind(this);
        this.filterEvents = this.filterEvents.bind(this);

        this.myHourTab = React.createRef();
        this.approvalsTab = React.createRef();
        this.calendarView = React.createRef();
        this.bulkEntry = React.createRef();
    }

    componentDidMount = async () => {
        super.componentDidMount();

        const { userObject } = this.context;

        DataHandler.get({ url: "timetracker/workhours/approval/settings" }).done(response => {
            this.setState({
                approve_settings: response.approve_settings || {},
                showApprovals: response.show_approval && response.show_approval === "1",
                hasApprovalCompanies: response.has_approval_companies && Number(response.has_approval_companies) > 0,
                overtime_hours_activated: response.overtime_hours_activated && response.overtime_hours_activated === "1",
                overtime_description_mandatory: response.overtime_description_mandatory && response.overtime_description_mandatory === "1",
                enable_approval_submitting: response.enable_approval_submitting && response.enable_approval_submitting == "1",
                show_approval_summary: response.show_approval_summary && response.show_approval_summary == "1",
            }, () => this.getApprovalTotals())
        });

        // Restore state before page reload
        if (!this.dataLoaded) {
            this.defaultUsers = [];
            this.projectsWithHours = [];

            if (this.localStorageUsers in localStorage) {
                try {
                    const users = JSON.parse(localStorage[this.localStorageUsers]);

                    if (Array.isArray(users) && users.length > 0)
                        this.defaultUsers = users;
                } catch (error) {
                }
            }

            if (this.defaultUsers.length === 0 || typeof this.defaultUsers[0] == "number")
                this.defaultUsers = [{ id: userObject.usersId, label: userObject.fullname, color: userObject.color }];

            if (this.localStorageColors in localStorage)
                this.setState({ colorsToUse: localStorage[this.localStorageColors] });

            if (this.localStorageColorOverride in localStorage) {
                try {
                    const overrides = JSON.parse(localStorage[this.localStorageColorOverride]);

                    if (overrides && typeof overrides === "object") setColorOverrides(overrides);
                } catch (error) { }
            }

            if (this.localStorageViewEvents in localStorage) {
                this.setState(JSON.parse(localStorage[this.localStorageViewEvents]));
            }

            this.dataLoaded = true;
        }

        document.addEventListener("ColorOverrideChanged", this.colorsChanged);
        window.addEventListener("workhourSaved", this.refreshCurrentRange);
        window.addEventListener("taskSaved", this.refreshCurrentRange);

        this.refreshSidebarProjects();
        this.refreshSidebarUsers(this.defaultUsers);
        this.refreshResourcingAutocompleteData();

        const worktrip_project = await DataHandler.get({
            url: `settings/company/${this.context.userObject.companies_id}/worktripProject`,
        });

        this.setState({ worktrip_project, calendarReady: true });
    };

    getApprovalTotals = async (dateRange = this.state.dateRange, refresh = false) => {
        if (!this.state.enable_approval_submitting)
            return;

        const approvalSubmitTotals = await this.fetchApprovalTotals(dateRange, "submit", refresh);
        const approvalRevokeTotals = await this.fetchApprovalTotals(dateRange, "revoke", refresh);
        this.setState({ approvalSubmitTotals, approvalRevokeTotals, approvalCompanies: approvalSubmitTotals.approval_companies })
    }

    fetchApprovalTotals = async (dateRange, type = "submit", refresh = 0) => {
        const fetchParams = {
            start: dateRange.startDate,
            end: dateRange.endDate,
            for_approval_submit: 1,
            type,
            refresh
        }

        let fetchData = {};
        try {
            fetchData = await DataHandler.get({ url: "timetracker/workhours", ...fetchParams });
            fetchData.selectedHours = fetchData.daterange_hours.map(e => e.id);
        } catch (err) { }

        return fetchData;
    }

    refreshResourcingAutocompleteData = async () => {
        this.setState({ resourcingAutoCompleteData: await getAutocompleteDataForDialog(this.context.userObject.companies_id) });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        document.removeEventListener("ColorOverrideChanged", this.colorsChanged);
        window.removeEventListener("workhourSaved", this.refreshCurrentRange);
        window.removeEventListener("taskSaved", this.refreshCurrentRange);
        this.checkSidebarProjectsToServer();
    }

    componentDidUpdate = (oldProps) => {
        this.saveSettings();
        if (oldProps.tab != this.props.tab) {
            let tab = this.props.tab;
            if (tab == 'timeTracker') {
                tab = this.viewModes[0].key;
            }
            if (!this.props.tab) {
                tab = 'calendarView';
            }
            this.setState({ tab, visibleView: null });
        }
    };

    colorsChanged = () => {
        this.forceUpdate();
    };

    // Saves settings to localstorage (sidebar, colors settings)
    saveSettings = () => {
        const users = [];

        this.state.sidebarUsers.forEach(u => users.push(u.id));

        localStorage.setItem(this.localStorageUsers, JSON.stringify(this.state.sidebarUsers));
        localStorage.setItem(this.localStorageColors, this.state.colorsToUse);
        localStorage.setItem(this.localStorageColorOverride, JSON.stringify(getColorOverrides()));
        const viewEvents = {
            showHourEntries: this.state.showHourEntries,
            showTasks: this.state.showTasks,
            showTasksInProgress: this.state.showTasksInProgress,
            showTasksOverdue: this.state.showTasksOverdue,
            showTasksDone: this.state.showTasksDone,
        };
        localStorage.setItem(this.localStorageViewEvents, JSON.stringify(viewEvents));
    };

    getVisibleUsers = (visibleUsers = null) => {
        const list = visibleUsers || this.state.sidebarUsers;
        if (!list)
            return [];
        const userIds = [];
        for (const i in list) {
            const u = list[i];
            userIds.push(Number((u.id + "").replace(/[^0-9]/g, "")));
            for (const j in u.user_ids) {
                userIds.push(Number(u.user_ids[j]));
            }
        }

        return userIds;
    }

    isVisibleUser(user, visibleUsers) {
        const list = visibleUsers || this.state.sidebarUsers;
        if (!list || !user) return false;
        const userIds = [];
        for (const i in list) {
            const u = list[i];
            userIds.push(Number((u.id + "").replace(/[^0-9]/g, "")));
            for (const j in u.user_ids) {
                userIds.push(Number(u.user_ids[j]));
            }
        }

        return userIds.findIndex(u => u === user.id) > -1;
    }

    onChangeColorProject = (project, color) => {
        setColorOverride('project', project.id, color.substring(1));
    }
    onChangeColorUser = (user, color) => {
        user.id = (user.id + "").replace(/[^0-9]/g, "");
        setColorOverride('user', user.id, color.substring(1));
        const { functions: { updateView } } = this.context;
        updateView({});
    }

    refreshCurrentRange = (event, bulk = false) => {
        const {
            loadedRange: { s, e },
        } = this.state;


        if (!bulk && s && e)
            this.onRequestData(s, e, true);
        this.refreshWorkhourBalances(1);
        this.getApprovalTotals();
    };

    onRequestData = async (start, end, force) => {
        const { enqueueSnackbar } = this.props;
        const { showTasksInProgress, showTasksOverdue, showTasksDone, worktrip_project } = this.state;
        const timeTrackerSettings = this.context.functions.getTimeTrackerSettings();

        if (this.state.loadedRange && !force) {
            const { s, e } = this.state.loadedRange;

            // If full range is loaded, we can skip this
            if (start.isSameOrAfter(s) && end.isSameOrBefore(e)) return true;
        }

        if (this.getDataRequest) this.getDataRequest.abort();

        this.setState({ loading: true });

        start = (typeof start === 'string' || start instanceof String) ? start : start.format('YYYY-MM-DD');
        end = (typeof end === 'string' || end instanceof String) ? end : end.format('YYYY-MM-DD');

        const request = this.getDataRequest = DataHandler.get({
            url: "timetracker/workhours",
            mode: 'timerange',
            start: start,
            end: end,
            users: this.getVisibleUsers().concat([this.context.userObject.usersId]),
            resourcing: (showTasksInProgress || showTasksOverdue || showTasksDone) ? '1' : '0',
            refresh: force ? 1 : 0,
        });

        const entries = [];

        try {
            const data = await request;

            data.entries.forEach(function (el) {
                // tz breaks calendar if timezone changes
                // let startTime = moment.tz(el.start, "YYYY-MM-DD HH:mm", el.timezone).local();
                // let endTime = moment.tz(el.end, "YYYY-MM-DD HH:mm", el.timezone).local();
                const startTime = moment(el.start, "YYYY-MM-DD HH:mm:ss").local();
                const endTime = moment(el.end, "YYYY-MM-DD HH:mm:ss").local();
                el.start = startTime.format("YYYY-MM-DD HH:mm:ss");
                el.end = endTime.format("YYYY-MM-DD HH:mm:ss");
                if (String(el.project.id) === worktrip_project.worktrip_project) {
                    el.isWorktripProject = true;
                }
                if (el.is_task > 0) {
                    el.start_date = el.start;
                    el.starttime = startTime.format("HH:mm:ss");
                    el.end_date = el.end;
                    el.endtime = endTime.format("HH:mm:ss");
                }
                
                entries.push({
                    ...el,
                    start: parse(el.start, "YYYY-MM-DD HH:mm:ss", new Date(0)),
                    end: parse(el.end, "YYYY-MM-DD HH:mm:ss", new Date(0)),
                });
            });

            this.setState({
                loading: false,
                loadedRange: { s: start, e: end },
                events: entries,
                filteredEvents: this.filterEvents(entries),
                workdays: data.workdays?.days ?? {},
                lastEntry: data.lastEntry,
                preventMulticompanyHourentries: timeTrackerSettings.prevent_multicompany_hourentries,
            });
        } catch (error) {
            if (error.statusText === 'abort') {
               // no error when aborted
            } else {
                console.error(error);
                enqueueSnackbar(this.tr("Unable to load workhours"), {
                    variant: "error",
                });
            }
        }

        return true;
    };

    refreshSidebarProjects = async (data = {}) => {
        let sidebarProjects = [];

        if (data.projects) {
            const res = await DataHandler.post({ url: 'timetracker/sidebar', return_new: 1 }, {
                data: data.projects.map(x => ({
                    projects_id: x
                }))
            });

            if (res.sidebar) {
                sidebarProjects = res.sidebar;
            } else {
                sidebarProjects = await DataHandler.get({ url: "timetracker/sidebar" });
            }
        }
        else {
            const useRefresh = await this.checkSidebarProjectsToServer();
            sidebarProjects = await DataHandler.get({ url: "timetracker/sidebar", refresh: useRefresh ? 1 : 0 });
        }
        if (this.state.preventMulticompanyHourentries)
            sidebarProjects = sidebarProjects.filter(sp => sp.company == this.context.userObject.companies_id)

        this.setState({ sidebarProjects })
    }

    sidebarSavePending = false;

    checkSidebarProjectsToServer = async () => {
        if (!this.sidebarSavePending)
            return false;

        this.sidebarSavePending = false;

        const data = this.state.sidebarProjects.map(x => ({
            projects_id: x.id,
        }));

        await DataHandler.post({ url: 'timetracker/sidebar' }, {
            data
        });

        return true;
    }

    saveSidebarProjects = () => {
        if (this.sidebarSavePending)
            return;

        this.sidebarSavePending = true;

        setTimeout(this.checkSidebarProjectsToServer, 5000);
    }

    refreshSidebarUsers = async (currentUsers) => {
        this.setState({ sidebarUsers: currentUsers, filteredEvents: this.filterEvents(this.state.events, currentUsers) }, () => {
            this.refreshWorkhourBalances();
        });
    }

    refreshWorkhourBalances = debounce(async (refresh = 0) => {
        const { sidebarUsers } = this.state;

        const usersInSidebar = sidebarUsers.map(x => {
            const str = String(x.id);
            let num = Number(x.id);


            if (str.startsWith('u-')) {
                const [, id] = x.id.split('-', 2);
                num = Number(id);
            }

            if (!isNaN(num)) {
                return num;
            }


            return 0;
        }).filter(x => x);

        const data = await DataHandler.get({ url: "timetracker/workhourbalances", users: [this.context.userObject.usersId, ...usersInSidebar], refresh });

        if (data) {

            const ownBlance = data.users[this.context.userObject.usersId];

            this.setState({ workhourbalances: data.users, workhourbalance: ownBlance?.balance ?? null, overtimebalance: ownBlance?.overtime_balance ?? null });
        }
    }, 500);

    getTaskType = task => {
        if (task.done > 0) {
            return "done";
        }
        const end = moment(task.end_date);
        if (end._d < new Date()) {
            return "overdue";
        }
        return "in-progress";
    };

    // Filters event list only to visible users
    filterEvents(events, visibleUsers = false) {
        const eventsToShow = [];

        for (const key in events) {
            const event = events[key];
            var visible;
            if (event.is_task > 0) {
                for (const kk in event.users_hours) {
                    visible = this.isVisibleUser({ id: Number(event.users_hours[kk].users_id) }, visibleUsers);
                    if (visible) {
                        break;
                    }
                }
            } else {
                visible = this.isVisibleUser(event.user, visibleUsers);
            }
            if (!this.state.showHourEntries && !event.is_task) {
                visible = false;
            }
            if (event.is_task > 0) {
                const taskType = this.getTaskType(event);
                if (!this.state.showTasksInProgress && taskType == "in-progress") {
                    visible = false;
                }
                if (!this.state.showTasksOverdue && taskType == "overdue") {
                    visible = false;
                }
                if (!this.state.showTasksDone && taskType == "done") {
                    visible = false;
                }
                event.taskType = taskType;
            }

            // Only show if user is visible
            if (visible) eventsToShow.push(event);
        }

        return eventsToShow;
    }

    onChangeUser = user => {
        this.setState({ selectedUser: user });
    };

    onSelectTab = e => {
        const {
            functions: { updateView },
        } = this.context;

        const tab = typeof e === "string" ? e : e.target.name;

        updateView({ tab });
    };

    onSaveEvent = async item => {
        this.lastEditedEvent = item;
        const task = item.type == "task" ? postTaskQuick(item) : postWorkhourEntry(item);

        // Update entries to prevent flicker
        if (item.id) {
            const entries = [...this.state.events];

            const index = entries.findIndex(e => e.id === item.id);

            entries[index] = {
                ...item,
                saving: true
            };

            this.setState({ events: entries, filteredEvents: this.filterEvents(entries) });
        }

        const { loadedRange } = this.state;

        const data = await task;

        if (data.status === 'FAILED') {
            const { enqueueSnackbar } = this.props;

            if (data.errorcode === 'PROJECT_NOT_MAIN') {
                enqueueSnackbar(this.tr("Cannot add hour to this project because it has subprojects."), {
                    variant: "error",
                });

                return;
            } else if (data.errorcode === 'INVALID_WORKTASK') {
                return { action: 'open_edit' };
            } else if (data.errorcode === 'OVERLAPS_DISABLED') {
                enqueueSnackbar(this.tr("Hour entries can't overlap"), {
                    variant: "error",
                });
            } else if (data.errorcode === 'INSERT_PREVENTED_IN_PREV_MONTHS') {
                enqueueSnackbar(this.tr("Can't add hours to this month"), {
                    variant: "error",
                });
                return;
            } else if (data.errorcode === 'DESCRIPTION_REQUIRED') {
                enqueueSnackbar(this.tr("Description is required."), {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(this.tr("Saving failed!"), {
                    variant: "error",
                });
            }
        }

        if (isEqual(this.getLastEditedEvent(), item)) {
            this.onRequestData(loadedRange.s, loadedRange.e, true);
            this.refreshWorkhourBalances(1);
            this.refreshSidebarProjects();
            this.getApprovalTotals();
        }

        return { status: 'ok' };
    };

    onSidebarProjectsChanged = sidebarProjects => {
        this.setState({ sidebarProjects }, this.saveSidebarProjects);
    };

    onSidebarAddProject = async project => {
        const { sidebarProjects } = this.state;

        const index = sidebarProjects.findIndex(v => v.id === project.id);

        if (index > -1) return;

        const list = await getProjectsByID([project.id]);

        this.setState({ sidebarProjects: [...sidebarProjects, ...list] }, this.saveSidebarProjects);
    };

    onSidebarRemoveProject = async project => {
        const sidebarProjects = [...this.state.sidebarProjects];

        const index = sidebarProjects.findIndex(v => v.id === project.id);

        if (index > -1) sidebarProjects.splice(index, 1);

        this.setState({ sidebarProjects }, this.saveSidebarProjects);
    };

    onSidebarUsersChanged = (sidebarUsers) => {
        const { loadedRange } = this.state;
        this.setState({ sidebarUsers, filteredEvents: this.filterEvents(this.state.events, sidebarUsers) }, () => {
            this.onRequestData(loadedRange.s, loadedRange.e, true);
            this.refreshWorkhourBalances();
        });
    }

    changeFullDay = () => {
        this.setState({
            fullDay: !this.state.fullDay,
            filteredEvents: this.filterEvents(this.state.events, false, !this.state.fullDay),
        });
    };

    showUserAddDialog = () => {
        this.setState({ showUserAddDialog: true });
    };

    closeUserDialog = () => {
        this.setState({ showUserAddDialog: false });
    };

    addUser = user => {
        const { sidebarUsers } = this.state;

        if (!user || !user.id || sidebarUsers.findIndex(x => x.id === user.id) > -1) return;

        this.onSidebarUsersChanged([...sidebarUsers, user]);
    };

    removeUser = user => {
        const { sidebarUsers } = this.state;

        const items = [...sidebarUsers];

        const index = items.indexOf(user);

        if (index > -1) items.splice(index, 1);

        this.onSidebarUsersChanged(items);
    };

    updateLastEntry = lastEntry => {
        this.setState({ lastEntry });
    };

    changeFilterOptions(elems) {
        const { loadedRange: { s, e } } = this.state;

        const hasResourcing = this.state.showTasksDone || this.state.showTasksInProgress || this.state.showTasksOverdue;
        const willHaveResourcing = elems.showTasksDone || elems.showTasksInProgress || elems.showTasksOverdue;

        if (willHaveResourcing && !hasResourcing) {

            this.setState(elems, () => {
                this.setState({ showTasks: this.state.showTasksInProgress && this.state.showTasksOverdue && this.state.showTasksDone });

                this.onRequestData(s, e, true);
            });

            return;
        }

        this.setState(elems, () => {
            const { sidebarUsers } = this.state;
            const eventsToShow = this.filterEvents(this.state.events, sidebarUsers);

            this.setState({
                filteredEvents: eventsToShow,
                showTasks: this.state.showTasksInProgress && this.state.showTasksOverdue && this.state.showTasksDone,
            });
        });
    }

    toggleBulkDay = day => {
        const selectedBulkDays = [...this.state.selectedBulkDays];
        const foundIndex = selectedBulkDays.indexOf(day.value);
        foundIndex != -1 ? selectedBulkDays.splice(foundIndex, 1) : selectedBulkDays.push(day.value);
        try {
            localStorage.setItem(this.localStorageBulkDays, JSON.stringify(selectedBulkDays));
        } catch (e) {
            console.log(e);
        }
        this.setState({ selectedBulkDays });
    }

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    }

    openSubmitHoursDialog = async (selectedTab, type = "submit", dialogData = undefined) => {
        if (dialogData) {
            dialogData.type = type;
            this.setState({ currentDialog: "submitHours", dialogData });
            return;
        }

        let dateRange = {};

        switch (selectedTab) {
            case "calendarView":
                dateRange = this.calendarView.current.getSelectedDateRange();
                break;
            case "bulkEntry":
                dateRange = this.bulkEntry.current.getSelectedDateRange();
                break;
            case "myHours":
                dateRange = this.myHourTab.current.getSelectedDateRange();
                break;
            default:
                dateRange = _.cloneDeep(this.state.dateRange);
        }

        dialogData = {
            dateRange,
            type
        }
        this.setState({ currentDialog: "submitHours", dialogData });
    };

    confirmHourSubmit = (dateRange = undefined) => {
        this.getApprovalTotals(dateRange, true);
        this.updateViewData();
    }

    updateViewData = () => {
        const { tab: selectedTab } = this.state;

        switch (selectedTab) {
            case "calendarView":
                const dateRange = this.calendarView.current.getSelectedDateRange();
                this.onRequestData(dateRange.startDate, dateRange.endDate, true);
                break;
            case "bulkEntry":
                this.bulkEntry.current.getEntries();
                break;
            case "myHours":
                this.myHourTab.current.updateMyhoursData();
                this.myHourTab.current.unCheckMyhoursList();
                break;
        }
    };

    renderViewOptions = () => {

        const { functions: { checkPrivilege }, timeTracker } = this.context;

        if (this.state.tab == 'bulkEntry') {
            return (
                <List>
                    {this.bulkDays.map(d => {
                        return (
                            <ListItem
                                className="muiListItem"
                                button
                                onClick={() => this.toggleBulkDay(d)}>
                                <CheckboxMUI
                                    checked={this.state.selectedBulkDays.includes(d.value)}
                                    value={d.value}
                                    color="primary"
                                />
                                <ListItemText className="text" inset primary={d.title} />
                            </ListItem>
                        );
                    })}
                </List>
            );
        }

        return (
            <List>
                {timeTracker.resourcingEnabled && this.context.addons && this.context.addons.resourcing && (checkPrivilege("projects", "project_resourcing_read") || checkPrivilege("projects", "own_resourcing_read")) && <ListItem
                    button
                    className="muiListItem"
                    onClick={() => {
                        this.setState({
                            showInCalendarOpen: !this.state.showInCalendarOpen
                        });
                    }}>
                    <ListItemIcon style={{ marginRight: 0 }} className="icon">
                        <EventNote />
                    </ListItemIcon>
                    <ListItemText inset primary={this.tr("Show in calendar")} />
                    {!this.state.showInCalendarOpen ? <ExpandMore /> : <ExpandLess />}
                </ListItem>}
                <Collapse in={this.state.showInCalendarOpen}>
                    <List component="div">
                        <ListItem
                            className="muiListItem"
                            button
                            onClick={() =>
                                this.changeFilterOptions({ showHourEntries: !this.state.showHourEntries })
                            }>
                            <CheckboxMUI
                                checked={this.state.showHourEntries}
                                value="showHourEntries"
                                color="primary"
                            />
                            <ListItemText className="text" inset primary={this.tr("Hour Entries")} />
                        </ListItem>
                        <ListItem
                            className="muiListItem"
                            button
                            onClick={() =>
                                this.changeFilterOptions({
                                    showTasks: !this.state.showTasks,
                                    showTasksInProgress: !this.state.showTasks,
                                    showTasksOverdue: !this.state.showTasks,
                                    showTasksDone: !this.state.showTasks,
                                })
                            }
                        >
                            <CheckboxMUI
                                checked={this.state.showTasks}
                                value="showHourEntries"
                                color="primary"
                            />
                            <ListItemText inset primary={this.tr("Tasks")} />
                        </ListItem>
                        <ListItem
                            className="muiListItem"
                            style={{ paddingLeft: "26px" }}
                            button
                            onClick={() =>
                                this.changeFilterOptions({
                                    showTasksInProgress: !this.state.showTasksInProgress,
                                })
                            }
                        >
                            <CheckboxMUI
                                checked={this.state.showTasksInProgress}
                                value="showHourEntries"
                                color="primary"
                            />
                            <ListItemText inset primary={this.tr("In Progress")} />
                        </ListItem>
                        <ListItem
                            className="muiListItem"
                            style={{ paddingLeft: "26px" }}
                            button
                            onClick={() =>
                                this.changeFilterOptions({ showTasksOverdue: !this.state.showTasksOverdue })
                            }
                        >
                            <CheckboxMUI
                                checked={this.state.showTasksOverdue}
                                value="showHourEntries"
                                color="primary"
                            />
                            <ListItemText inset primary={this.tr("Overdue")} />
                        </ListItem>
                        <ListItem
                            className="muiListItem"
                            style={{ paddingLeft: "26px" }}
                            button
                            onClick={() =>
                                this.changeFilterOptions({ showTasksDone: !this.state.showTasksDone })
                            }
                        >
                            <CheckboxMUI
                                checked={this.state.showTasksDone}
                                value="showHourEntries"
                                color="primary"
                            />
                            <ListItemText inset primary={this.tr("Done")} />
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem className="muiListItem" button onClick={() => this.setState({ showViewEntriesBy: !this.state.showViewEntriesBy })}>
                    <ListItemIcon style={{ marginRight: 0 }}>
                        <Palette />
                    </ListItemIcon>
                    <ListItemText inset primary={this.tr("View entries by")} />
                    {!this.state.showViewEntriesBy ? <ExpandMore /> : <ExpandLess />}
                </ListItem>
                <Collapse in={this.state.showViewEntriesBy}>
                    <List component="div">
                        <ListItem
                            button
                            className="muiListItem"
                            onClick={() => this.setState({ colorsToUse: "project" })}>
                            <Radio
                                checked={this.state.colorsToUse == "project"}
                                value="useProjectColors"
                                color="primary"
                            />
                            <ListItemText inset primary={this.tr("Project Colors")} />
                        </ListItem>
                        <ListItem
                            button
                            className="muiListItem"
                            onClick={() => this.setState({ colorsToUse: "user" })}>
                            <Radio
                                checked={this.state.colorsToUse == "user"}
                                value="useUserColors"
                                color="primary"
                            />
                            <ListItemText inset primary={this.tr("User Colors")} />
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        );
    }

    selectTab = (e, tab) => {
        if (!e.ctrlKey && !e.metaKey)
            e.preventDefault();
        else
            return;

        this.context.functions.updateView({ tab })
    }

    export = () => {
        const { tab: selectedTab } = this.state;

        switch (selectedTab) {
            case "myHours":
                this.myHourTab.current && this.myHourTab.current.export();
                break;
            case "approvals":
                this.approvalsTab.current && this.approvalsTab.current.export();
                break;
        }
    }

    getSubheaders = () => {
        const { tab: selectedTab } = this.state;
        const subheaders = [];
        switch (selectedTab) {
            case "calendarView":
                subheaders.push({
                    header: this.tr("Calendar View")
                });
                break;
            case "bulkEntry":
                subheaders.push({
                    header: this.tr("Bulk Entry")
                });
                break;
            case "myHours":
                subheaders.push({
                    header: this.tr("My Hours Listed")
                });
                break;
            case "approvals":
                subheaders.push({
                    header: this.tr("approvals")
                });
                break;
            default:
                break;
        }
        return subheaders;
    }

    renderSummarySection = () => {
        const { functions: { checkPrivilege }, timeTracker, userObject: { usersId, disable_user_balance } } = this.context;
        const { workdays, tab: selectedTab, approvalSubmitTotals, workhourbalance, overtimebalance, events, visibleView, visibleRangeStart, visibleRangeEnd } = this.state;

        const summaries = [];

        if (!disable_user_balance && workhourbalance !== null) {
            summaries.push({
                title: this.tr("Hour Balance"),
                value: formatInputNumber(workhourbalance || 0, "hours") + " h"
            });
        }

        if (overtimebalance !== null) {
            summaries.push({
                title: this.tr("overtime balance"),
                value: formatInputNumber(overtimebalance || 0, "hours") + " h"
            })
        }

        if (visibleView && visibleRangeStart && visibleRangeEnd) {
            const filteredEvents = events.filter(x => x.start >= visibleRangeStart && x.end <= visibleRangeEnd && !(x.is_task > 0) && x.user.id == usersId);

            console.log({filteredEvents})

            const days = eachDayOfInterval({
                start: visibleRangeStart,
                end: visibleRangeEnd,
            });

            let totalHoursExpected = 0;
            let totalHoursDone = 0;

            for (const day of days) {
                const date = format(day, 'YYYY-MM-DD');
                const dayInfo = workdays[date] ?? { hours: 0 };

                if (dayInfo.workday) {
                    totalHoursExpected += dayInfo.hours ?? 0;
                }
            }

            for (const event of filteredEvents) {
                totalHoursDone += (event.end.getTime() - event.start.getTime()) / 3600000;
            }

            const titles = {
                day: 'Daily Hours',
                week: 'Weekly Hours',
                month: 'Monthly Hours',
            };

            summaries.push({
                title: this.tr(titles[visibleView] ?? 'Period Hours'),
                value: `${totalHoursDone.toFixed(2)} / ${totalHoursExpected.toFixed(2)} h`
            });
        }

        this.state.hasApprovalCompanies && this.state.enable_approval_submitting && !VersionContentManager.isFeatureHidden(this.namespace, 'hoursApproval') && summaries.push({
            title: this.tr("For approval all hours"),
            value: formatInputNumber(approvalSubmitTotals.sum_all_hours || 0, "hours") + " h"
        })

        const extras = [];

        if (timeTracker.resourcingEnabled && this.context.addons && this.context.addons.resourcing && checkPrivilege("projects", "project_resourcing_write") && selectedTab === "calendarView") {
            extras.push((
                <div className="task-add-check">
                    <span>{this.tr("Create new entries as tasks")}</span>
                    <CheckboxMUI
                        color="primary"
                        className="checkbox"
                        checked={this.state.createTasks}
                        onChange={() =>
                            this.setState({ createTasks: !this.state.createTasks }, () =>
                                this.state.createTasks &&
                                this.changeFilterOptions({
                                    showTasks: this.state.createTasks,
                                    showTasksInProgress: this.state.createTasks,
                                    showTasksOverdue: this.state.createTasks,
                                    showTasksDone: this.state.createTasks,
                                })
                            )
                        }
                    />
                </div>
            ))
        }

        const additionalButtons = this.getAdditionalButtons();

        return (
            <PageTopSection viewModes={(selectedTab == 'calendarView' || selectedTab == 'bulkEntry' || selectedTab == 'myHours' || selectedTab == 'myHours-modified') && this.viewModes} selectedViewMode={selectedTab == 'myHours-modified' ? 'myHours' : selectedTab} viewModesTitle={this.tr("View")} className="summary-section" viewButton={(selectedTab == 'calendarView' || selectedTab == 'bulkEntry') && {
                title: this.tr("View options"),
                popoverClass: "time-tracker-view-options",
                popoverComponent: this.renderViewOptions
            }} settingsButton={{
                isVisible: !(null == this.context.privileges.adminEventLen),
                title: this.tr("Settings"),
                href: this.context.functions.urlify({ module: "settings", action: "index", group: "features", page: "time-management" }),
                action: () => this.context.functions.updateView({ module: "settings", action: "index", group: "features", page: "time-management" }, false)
            }}
                additionalButtons={additionalButtons}
                extraComponents={extras} summaries={summaries} />
        );
    }

    getAdditionalButtons = () => {
        const { tab: selectedTab, approvalSubmitTotals, approvalRevokeTotals } = this.state
        let additionalButtons = [];

        if (this.state.enable_approval_submitting) {
            const approveButton =
            {
                title: this.tr("Send hours for approval"),
                text: this.tr("Approval"),
                action: () => this.openSubmitHoursDialog(selectedTab),
                icon: <CheckCircleOutline />,
                className: "wide-button primary",
                'data-testid': 'approval-send',
            }

            const revokeButton =
            {
                title: this.tr("Revoke hours from approval"),
                text: this.tr("Revoke"),
                action: () => this.openSubmitHoursDialog(selectedTab, "revoke"),
                icon: <AssignmentReturned />,
                className: "wide-button secondary",
                'data-testid': 'approval-revoke',
            }

            approvalRevokeTotals.all_hours?.length > 0 && !VersionContentManager.isFeatureHidden(this.namespace, 'hoursApproval') && additionalButtons.push(revokeButton);
            approvalSubmitTotals.all_hours?.length > 0 && !VersionContentManager.isFeatureHidden(this.namespace, 'hoursApproval') && additionalButtons.push(approveButton);
            additionalButtons = selectedTab !== "approvals" ? additionalButtons : [];
        }

        if (selectedTab === "myHours" || selectedTab === "approvals") {
            additionalButtons.push({
                title: selectedTab === "myHours" ? this.tr("Export hours") : this.tr("Export hour approvals"),
                action: () => this.export(),
                icon: <CloudDownload />
            });
        }

        return additionalButtons;
    }

    onAddEvent = (event) => {
        const events = cloneDeep(this.state.events);
        if (!event.id) {
            const start = event.start ? moment(event.start) : moment();
            const end = event.end ? moment(event.end) : moment();
            if (!start.isSame(end, 'date')) {
                const diff = Math.abs(start.diff(end, 'day'));
                for (let i = 0; i <= diff; i++) {
                    const currentStart = moment(start).add(i, 'day');
                    const currentEnd = moment(`${currentStart.format("YYYY-MM-DD")} ${end.format("HH:mm:ss")}`);
                    events.push({
                        ...event,
                        start: currentStart.toDate(),
                        end: currentEnd.toDate(),
                        unit: event.unit?.id == "0" ? { ...event.unit, name: "" } : event.unit,
                        saving: true
                    });
                }
            } else {
                events.push({
                    ...event,
                    unit: event.unit?.id == "0" ? { ...event.unit, name: "" } : event.unit,
                    saving: true
                });
            }
        } else {
            const eventIndex = events.findIndex(e => e.id == event.id);
            if (eventIndex != -1) {
                events[eventIndex] = {
                    ...event,
                    unit: event.unit?.id == "0" ? { ...event.unit, name: "" } : event.unit,
                    saving: true
                }
            }
        }
        this.lastAddedEvent = event;
        this.setState({ events, filteredEvents: this.filterEvents(events) });
    }

    getLastEditedEvent = () => {
        return this.lastEditedEvent;
    }

    getLastAddedEvent = () => {
        return this.lastAddedEvent;
    }

    onViewChange = (view, start, end) => {
        this.setState({
            visibleView: view,
            visibleRangeStart: start,
            visibleRangeEnd: end,

        });
    }

    render() {
        let currentView = undefined;

        const { functions: { hasPrivilege } } = this.context;
        const { preventMulticompanyHourentries, lastEntry, overtime_hours_activated, overtime_description_mandatory, calendarReady, tab, workdays, approve_settings } = this.state;
        if (tab != "approvals" &&
            !hasPrivilege("workhours", "read_full") &&
            !hasPrivilege("workhours", "read_superior") &&
            !hasPrivilege("workhours", "write") &&
            !hasPrivilege("workhours", "write_full")
        ) {
            return <NoPermissionOverlay />;
        }

        const selectedTab = tab;
        const timeTrackerSettings = this.context.functions.getTimeTrackerSettings();

        switch (selectedTab) {
            case "calendarView":
            default:
                currentView = (<CalendarView
                    ref={this.calendarView}
                    loading={this.state.loading}
                    startHour={!this.state.fullDay ? this.state.startHour : undefined}
                    endHour={!this.state.fullDay ? this.state.endHour : undefined}
                    colorsToUse={this.state.colorsToUse}
                    events={this.state.filteredEvents}
                    onAddEvent={this.onAddEvent}
                    getLastAddedEvent={this.getLastAddedEvent}
                    sidebarProjects={this.state.sidebarProjects}
                    onSidebarProjectsChanged={this.onSidebarProjectsChanged}
                    onSidebarAddProject={this.onSidebarAddProject}
                    onSidebarRemoveProject={this.onSidebarRemoveProject}
                    //sidebarUsers={this.state.sidebarUsers}
                    onSidebarUsersChanged={this.onSidebarUsersChanged}
                    onRequestData={debounce(this.onRequestData, 300)}
                    defaultUser={this.state.currentUser}
                    calendarType={DnDCalendar}
                    onSaveEvent={this.onSaveEvent}
                    onChangeColorProject={this.onChangeColorProject}
                    onChangeColorUser={this.onChangeColorUser}
                    resourcingAutoCompleteData={this.state.resourcingAutoCompleteData}
                    createTasks={this.state.createTasks}
                    sidebarUsers={this.state.sidebarUsers}
                    lastEntry={lastEntry}
                    updateLastEntry={this.updateLastEntry}
                    refreshSidebarProjects={this.refreshSidebarProjects}
                    refreshSidebarUsers={this.refreshSidebarUsers}
                    preventMulticompanyHourentries={preventMulticompanyHourentries}
                    overtime_description_mandatory={overtime_description_mandatory}
                    overtime_hours_activated={overtime_hours_activated}
                    workhourbalances={this.state.workhourbalances}
                    minEventLen={timeTrackerSettings?.workhour_accuracy ?? 15}
                    openSubmitHoursDialog={this.openSubmitHoursDialog}
                    enable_approval_submitting={timeTrackerSettings?.enable_approval_submitting == 1}
                    workdays={workdays}
                    parentComponent="time tracker"
                    onViewChange={this.onViewChange}
                />);

                break;
            case "bulkEntry":
                currentView = (
                    <BulkEntry
                        ref={this.bulkEntry}
                        timeTrackerSettings={timeTrackerSettings}
                        lastEntry={lastEntry}
                        colorsToUse={this.state.colorsToUse}
                        selectedDays={this.state.selectedBulkDays}
                        sidebarProjects={this.state.sidebarProjects}
                        onSidebarAddProject={this.onSidebarAddProject}
                        onSidebarProjectsChanged={this.onSidebarProjectsChanged}
                        onChangeUser={this.onChangeUser}
                        onRequestData={debounce(this.onRequestData, 300)}
                        updateLastEntry={this.updateLastEntry}
                        refreshSidebarProjects={this.refreshSidebarProjects}
                        events={this.filterEvents(this.state.events, [this.state.selectedUser])}
                        preventMulticompanyHourentries={preventMulticompanyHourentries}
                        parentComponent="Time tracker - Bulk"
                        workdays={workdays}
                        onViewChange={this.onViewChange}
                    />
                );
                break;
            case "myHours":
                currentView = <MyHours resourcingAutoCompleteData={this.state.resourcingAutoCompleteData} getApprovalTotals={this.getApprovalTotals} hasApprovalCompanies={this.state.hasApprovalCompanies} timeTrackerProps={this.props} selectedTab="myHours" ref={this.myHourTab} navigate={this.onSelectTab} openSubmitHoursDialog={this.openSubmitHoursDialog} />;
                break;
            case "approvals":
                currentView = <MyHours selectedTab="approvals" approve_settings={approve_settings} show_approval_summary={this.state.show_approval_summary} timeTrackerProps={this.props} ref={this.approvalsTab} navigate={this.onSelectTab} refreshWorkhourBalance={() => this.refreshWorkhourBalances()} />;
                break;
            // No tab, used for tests
            case "none":
                break;
        }

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <div className={`TimeTracker ${(selectedTab == 'myHours' || selectedTab == 'approvals' || selectedTab == 'myHours-modified') && "whiteBG"}`}>
                <div className={cn("contents-loading-overlay")}>
                    <img src={Loading} />
                </div>
                {this.renderSummarySection()}
                {calendarReady && currentView}
                {
                    Dialog && Dialog == SubmitHoursDialog &&
                    <Dialog
                        closeDialog={this.closeDialog}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                        dialogData={this.state.dialogData}
                        onConfirm={(dateRange) => this.confirmHourSubmit(dateRange)}
                    />
                }
            </div>
        );
    }
}

const snacktimetracker = withSnackbar(TimeTracker);

export { snacktimetracker as default };
