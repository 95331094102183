import React from 'react';

/* css */
import './InvoicingByCostType.css';

/* others */
import _ from 'lodash';
import TaimerComponent from "../../../TaimerComponent";
import CategoryValueTable from '../CategoryValueTable';
import colors from '../../../colors';

class InvoicingByCostType extends TaimerComponent {
    static defaultProps = {
        width: 3,
        visible: true,
    }

    constructor(props, context) {
        super(props, context, "dashboard/insights/invoicing/InvoicingByCostType");

        this.state = {
        }
    }

    render() {
        const { data, extraCols, height, loaded, currency, ...props } = this.props;
        const { tr } = this;

        const types = {
            hours: {
                title: tr('Hours'),
                color: '#7b4bc8'
            },
            scheduled: {
                title: tr('Scheduled Invoicing'),
                color: '#4c4ba8',
            },
            expense: {
                title: tr('Expenses'),
                color: '#2d9ff7',
            },
            quote: {
                title: tr('Quote'),
                color: colors.greenish_cyan,
            },
            subcontract: {
                title: tr('Sub-contracting'),
                color: '#f9669e',
            },
        };

        return <CategoryValueTable {...props} currency={currency} data={data} types={types} />;
    }
}

export default InvoicingByCostType;