/* css */
import './ProductDetailsInfo.css';

/* others */
import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from './../../SettingsContext';
import { AddCircleOutline} from '@mui/icons-material';
import ProductSupplier from './ProductSupplier';
import DataHandler from "./../../general/DataHandler";

class ProductSupplierDetails extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/lists/ProductSupplierDetails");
        this.state = {
           data: {
               suppliers: [],
               supplierIds: [],
               addressIds: []
           }
        };

        this.newSupplier={
            id:-1,
            customerName:"",
            businessId:"",
            address:"",
            postalCode:"",
            city:"",
            country:"",
            contact:"",
            email:"",
            is_default: 0
        }

        this.writePrivileges = context.functions.checkPrivilege("products", "write", props.product.companies_id);
    }

    componentDidMount() {
        super.componentDidMount();

        this.getData();
    }

    getData = () => {
        let productId = this.props.product.id;
        if (Number(this.props.product.parent_id > 0))
            productId = this.props.product.parent_id;

        DataHandler.get({url: `products/${productId}/suppliers/${this.props.product.companies_id}`}).done(data => {
            let hasDefaultSupplier = false;

            data.suppliers.forEach((supplier, i) => {
                if (supplier.is_default == 1) {
                    hasDefaultSupplier = true;
                    data.suppliers.splice(i, 1)
                    data.suppliers.unshift(supplier);
                }
            });

            if (!hasDefaultSupplier && data.suppliers.length > 0)
                data.suppliers[0]['is_default'] = 1;

            this.setState({ data });
        });    
    }

    setDefaultSupplier = (supplierId, showSnackBar = true, deleted = false) => {
        const { id, companies_id } = this.props.product;
        const { data } = this.state;
        // After deleting: Set another supplier as default if there still is suppliers
        if (deleted) {
            supplierId = data.suppliers.find(s => s.id != supplierId)?.id;
            if (!supplierId)
                return;
        }

        const supplierName = data.suppliers.find(s => s.id == supplierId).customerName;

        const params = { 
            is_default: 1,
            is_new: 0,
            products_suppliers_id: supplierId
        };
        DataHandler.post({url: `products/${id}/suppliers/${companies_id}`}, params).done(() => {
            this.changeDefaultAddress(supplierId);
            if (showSnackBar) {
                this.props.enqueueSnackbar(this.tr("Default supplier changed to: ${supplierName}!", {supplierName: supplierName}), {
                    variant: "success",
                });
            }
        })
        .fail(() => {
            this.props.enqueueSnackbar(this.tr("Error in changing default address!"), {
                variant: "error",
            });
        });
    }

    changeDefaultAddress = (addressId) => {
        const { data } = this.state;
        let newSupplierList = [];
        data.suppliers.forEach(supplier => {
            if (supplier.id == addressId) {
                supplier.is_default = 1;
                newSupplierList.unshift(supplier);
            }
            else {
                supplier.is_default = 0;
                newSupplierList.push(supplier);
            }
        });

        this.setState({ data: {...data, suppliers: newSupplierList} })
    }
    
    nextId = -1;
    addSupplier = () => {
        this.writePrivileges && this.setState({newItem: this.newSupplier});
        //this.setState({data: this.newSupplier});
    }
    cancel = () => {
        this.setState({newItem: null});
    }

    render() {
        const { items, disabled } = this.props;
        const { newItem , data} = this.state;
        const { tr } = this;
        const showAddSupplier = this.writePrivileges && !disabled && !newItem;
        const supplierProps = {
            getData: this.getData,
            product: this.props.product,
            companiesId: this.props.product.companies_id,
            enqueueSnackbar: this.props.enqueueSnackbar,
            getProductData: this.props.getProductData,
            writePrivileges: this.writePrivileges,
            setDefaultSupplier: this.setDefaultSupplier,
            supplierAmount: data.suppliers.length
        }

        return (
            <div className="product-details">
                {data.suppliers.map((row, i, arr) => (
                    <>
                        <ProductSupplier {...supplierProps} key={row.addressId} item={row} />
                        { arr.length - 1 !== i && 
                            <hr className="supplier-separator" />
                        }
                    </>
                ))}

                {newItem && <ProductSupplier {...supplierProps} name="new" item={newItem} supplierIds={data.supplierIds} addressIds={data.addressIds} cancel={this.cancel} />}
                {showAddSupplier &&
                    <div>
                        <AddCircleOutline className="plus" onClick={this.addSupplier} />
                        <span className="new-supplier" onClick={this.addSupplier}>{this.tr("Add New Supplier")}</span>
                    </div>
                }
            </div>
        )
    }
}

export default ProductSupplierDetails;
