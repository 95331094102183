import React from 'react';

import "./Onedrive.css";
import LoginView from './OnedriveComponents/LoginView';
import OnedriveView from './OnedriveComponents/OnedriveView';
import DataHandler from './../general/DataHandler';
import CircularProgress from '@mui/material/CircularProgress';

//Mail componens root component
class OnedriveLogin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mode:'view',
			isOffice: false,
			chosenMail: "",
			loading: true,
		};
	}

	//Triggers when office 365 is selected
	onClickOneDrive = () => {
		DataHandler.get({url: `onedrive/login`}).done(response => {
			if (response.url){
				sessionStorage.setItem("previousUrl", window.location);
				sessionStorage.setItem("nextAction", "onedrive-office-login");
				window.location = response.url;
			}
		})
	}

	//Triggers after component is mounted
	componentDidMount() {
		DataHandler.get({url: `onedrive/login`}).done(response => {
			if (response.url)
			{
				this.setState({
					isOnedriveAuthenticated: false,
					loading: false,
				})

			} else {
				this.setState({
					isOnedriveAuthenticated: true,
					token: response.login,
					loading: false,
				})
			}
		}).fail(response => {
			this.setState({loading: false});
		});
	}
 
	//Returns page depending is user authenticated to mail client (office 365)
	renderView() {
		
		if(this.state.isOnedriveAuthenticated && this.state.token) {
			return <OnedriveView />	
		} else {
			return <LoginView onClickOneDrive={this.onClickOneDrive} loading={this.state.loading} />
		}
	}

	//Renders page
	render() {
		return (
			<div id={'contentBlockOnedrive'} className={`${this.props.className}`} >
				{this.renderView()}		
			</div>
		);
	}
}

export default OnedriveLogin;