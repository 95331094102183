import React from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import _ from "lodash";
import LazyLoad from "react-lazyload";

import { SettingsContext } from "../../SettingsContext";
import TaimerComponent from "../../TaimerComponent";
import DataHandler from "../../general/DataHandler";

import TimeTracker from "./components/TimeTracker";
import WelcomeBar from "./components/WelcomeBar";
import HoursOverview from "./components/HoursOverview";
import ActivitiesOverview from "./components/ActivitiesOverview";
import ActivitiesList from "./components/ActivitiesList";
import TasksOverview from "./components/TasksOverview";
import TasksList from "./components/TasksList";
import ProjectsOverview from "./components/ProjectsOverview";
import ProjectsList from "./components/ProjectsList";

import bulkStyles from '../../workhours/time-tracker/BulkEntry.module.css';

import "./MyDay.css";
import VersionContentManager from "../../general/VersionContentManager";
import OnboardingSection from "./OnboardingSection";
import SetupAccountWizard from "../../general/SetupAccountWizard";

const ResponsiveGridLayout = WidthProvider(Responsive);
const LAYOUTS_KEY = "my-day-responsive-layouts-no-resize";
const MY_DAY_RIGHTS_KEY = "my_day_dynamic_rights";

/**
 * My day dashboard view.
 *
 * We could use this library to load the blocks lazily when this view gets more items:
 * https://github.com/twobin/react-lazyload
 */
export default class MyDay extends TaimerComponent {
  static contextType = SettingsContext;
  constructor(props, context) {
    super(props, context, "dashboard/my_day/MyDay");

    /** Breakpoints for the responsive grid (in px) – in what points the view changes to the corresponding layout.
     * @see _createDefaultLayouts
     */
    this.layoutBreakpoints = { xl: 1550, lg: 1300, md: 980, sm: 0 };

    /** Amount of columns in each layout size.
     * * @see _createDefaultLayouts
     */
    this.layoutColumns = { xl: 14, lg: 12, md: 12, sm: 1 };

    this.defaultBlockSettings = {
      isResizable: false,
      h: BLOCK_HEIGHT,
    };

    const {
      functions: { hasPrivilege },
    } = this.context;

    /**
     * Grid items. Key and layoutType must be defined.
     * @property {string} key - is used when rendering components inside blocks, check _getComponentForKey.
     * @property {string} layoutType - tells the grid is this block large or small. Must be one of the predefined layoutTypes.
     */
    this.items = [
      {
        key: "hours_overview",
        layoutType: layoutTypes.small,
        checkRight: () =>
          hasPrivilege("workhours", "read_full") ||
          hasPrivilege("workhours", "read_superior") ||
          hasPrivilege("workhours", "write_full") ||
          hasPrivilege("workhours", "write"),
      },
      {
        key: "time_tracker",
        layoutType: layoutTypes.wide,
        checkRight: () =>
          hasPrivilege("workhours", "read_full") ||
          hasPrivilege("workhours", "read_superior") ||
          hasPrivilege("workhours", "write_full") ||
          hasPrivilege("workhours", "write"),
      },
      {
        key: "activities_overview",
        layoutType: layoutTypes.small,
        checkRight: (dynamicRights) => {
          const dynamic = (dynamicRights || {}).activities;
          return (
            dynamic ||
            hasPrivilege("projects", "project_crm_read") ||
            hasPrivilege("customers", "customer_crm_read")
          );
        },
      },
      {
        key: "activities",
        layoutType: layoutTypes.wide,
        checkRight: (dynamicRights) => {
          const dynamic = (dynamicRights || {}).activities;
          return (
            dynamic ||
            hasPrivilege("projects", "project_crm_read") ||
            hasPrivilege("customers", "customer_crm_read")
          );
        },
      },
      {
        key: "tasks_overview",
        layoutType: layoutTypes.small,
        checkRight: (dynamicRights) => {
          const dynamic = (dynamicRights || {}).tasks;
          return (
            (this.context.addons && this.context.addons.resourcing) && !VersionContentManager.isFeatureHidden(this.namespace, 'tasks') && (dynamic ||
            hasPrivilege("projects", "project_resourcing_read") ||
            hasPrivilege("projects", "own_resourcing_read"))
          );
        },
      },
      {
        key: "tasks",
        layoutType: layoutTypes.wide,
        checkRight: (dynamicRights) => {
          const dynamic = (dynamicRights || {}).tasks;
          return (
            (this.context.addons && this.context.addons.resourcing) && !VersionContentManager.isFeatureHidden(this.namespace, 'tasks') && (dynamic ||
            hasPrivilege("projects", "project_resourcing_read") ||
            hasPrivilege("projects", "own_resourcing_read"))
          );
        },
      },
      {
        key: "projects_overview",
        layoutType: layoutTypes.small,
        checkRight: (dynamicRights) => {
          const dynamic = (dynamicRights || {}).projects;
          return dynamic || hasPrivilege("projects", "read");
        },
      },
      {
        key: "projects",
        layoutType: layoutTypes.wide,
        checkRight: (dynamicRights) => {
          const dynamic = (dynamicRights || {}).projects;
          return dynamic || hasPrivilege("projects", "read");
        },
      },
    ];

    let dynamicRights =
      this._getFilteredItems().length == this.items.length
        ? {
            tasks: false,
            activities: false,
            projects: false,
          }
        : null;

    try {
      dynamicRights = localStorage.getItem(MY_DAY_RIGHTS_KEY) || dynamicRights;
    } catch (e) {}

    if (dynamicRights && dynamicRights != "[object Object]")
      dynamicRights = JSON.parse(JSON.stringify(dynamicRights));

    const layouts = this._getInitialLayout(dynamicRights);

    this.state = {
      layouts,
      currency: "USD",
      popUpComponent: null,
      dynamicRights,
      timers: null,
    };

    [
      "hours_overview",
      "time_tracker",
      "activities_overview",
      "activities",
      "tasks_overview",
      "tasks",
      "projects_overview",
      "projects",
    ].forEach((e) => (this[e] = React.createRef()));
  }

  componentDidUpdate = (oldProps) => {
    if (oldProps.tabletMode != this.props.tabletMode) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize")); // is this hacky? not sure but works for now
      }, 1000);
    }
  };

  _getDynamicRights = () => {
    let hasActivityRight = false;
    let hasTaskRight = false;
    let hasProjectRight = false;
    this.items.forEach((item) => {
      if (item.key == "activities") {
        hasActivityRight = item.checkRight();
      } else if (item.key == "tasks") {
        hasTaskRight = item.checkRight();
      } else if (item.key == "projects") {
        hasProjectRight = item.checkRight();
      }
    });
    let requests = [];
    if (!hasActivityRight) {
      requests.push(
        new Promise((resolve) => {
          Promise.all([
            DataHandler.get({
              url: `subjects/companies_with_project_right/project_crm_read`,
            }),
            DataHandler.get({
              url: `subjects/companies_with_account_right/customer_crm_read`,
            }),
          ]).then((responses) => {
            (responses || []).forEach((arr) => {
              if ((arr || []).length > 0) hasActivityRight = true;
            });
            resolve();
          });
        })
      );
    }
    if (!hasTaskRight) {
      requests.push(
        new Promise((resolve) => {
          DataHandler.get({
            url: `subjects/companies_with_project_right/project_resourcing_read+own_resourcing_read`,
          }).done((response) => {
            hasTaskRight = (response || []).length > 0;
            resolve();
          });
        })
      );
    }
    if (!hasProjectRight) {
      requests.push(
        new Promise((resolve) => {
          DataHandler.get({
            url: `subjects/companies_with_project_right/read`,
          }).done((response) => {
            hasProjectRight = (response || []).length > 0;
            resolve();
          });
        })
      );
    }

    Promise.all(requests).then(() => {
      const dynamicRights = {
        tasks: hasTaskRight,
        activities: hasActivityRight,
        projects: hasProjectRight,
      };
      localStorage.setItem(MY_DAY_RIGHTS_KEY, JSON.stringify(dynamicRights));
      if (
        this.state.dynamicRights &&
        (this.state.dynamicRights.tasks != dynamicRights.tasks ||
          this.state.dynamicRights.activities != dynamicRights.activities ||
          this.state.dynamicRights.projects != dynamicRights.projects)
      ) {
        this.setState({
          dynamicRights,
          layouts: this._getInitialLayout(dynamicRights),
        });
      } else {
        this.setState({
          dynamicRights,
        });
      }
    });
  };

  componentDidMount() {
    super.componentDidMount();

    this._getDynamicRights();
    this._getRunningTimers();

    DataHandler.get({ url: `subjects/companies`, currency: 1 }).done(
      (companies) => {
        let c = false;
        companies.forEach((company) => {
          if (company.id == this.context.userObject.companies_id) {
            this.setState({ currency: company.currency });
            c = true;
          }
        });
        if (!c) {
          this.setState({ currency: companies[0].currency });
        }
      }
    );
    window.addEventListener("workhourSaved", this._updateHoursOverviewData);
    window.addEventListener("taskSaved", this._updateTasksOverviewData);
    window.addEventListener("projectSaved", this._updateProjectsOverviewData);
    window.addEventListener(
      "activitySaved",
      this._updateActivitiesOverviewData
    );

    window.addEventListener("timersChanged", this._timersChanged);
    const shouldShowOnboarding = this.context.functions.isUserOwner() && this.context.taimerAccount.onboardingSeen == 0 && this.context.addons.new_stripe;
    if (shouldShowOnboarding) {
        this.context.functions.sendMixpanelEvent('onboarding_wizard_started');
        this.context.functions.sendMixpanelPeople('set', {
            "onboarding_wizard_start_date": new Date().toISOString(),
        });
      this.context.functions.setOverlayComponent(<SetupAccountWizard />);
    }
  }

  _timersChanged = () => {
    setTimeout(() => this._getRunningTimers(), 1000);
  }

  _getRunningTimers = () => {
    DataHandler.get({ url: "timetracker/timers" }).done((timers) => {
      this.setState({ timers });
    });
  };

  _updateHoursOverviewData = () => {
    setTimeout(() => {
      if (!this.hours_overview.current) return;
      this.hours_overview.current._getData();
      if (!this.tasks_overview.current) return;
      this.tasks_overview.current._getData();
      if (!this.tasks.current) return;
      this.tasks.current._getData();
    }, 1000);
  };

  _updateTasksOverviewData = () => {
    setTimeout(() => {
      if (!this.tasks_overview.current) return;
      this.tasks_overview.current._getData();
    }, 1000);
  };

  _updateProjectsOverviewData = () => {
    setTimeout(() => {
      if (!this.projects_overview.current) return;
      this.projects_overview.current._getData();
    }, 1000);
  };

  _updateActivitiesOverviewData = () => {
    setTimeout(() => {
      if (!this.activities_overview.current) return;
      this.activities_overview.current._getData();
    }, 1000);
  };

  componentWillUnmount() {
    window.removeEventListener("workhourSaved", this._updateHoursOverviewData);
    window.removeEventListener(
      "activitySaved",
      this._updateActivitiesOverviewData
    );
    window.removeEventListener("taskSaved", this._updateTasksOverviewData);
    window.removeEventListener("timersChanged", this._timersChanged);
  }

  /**
   * Gets the users saved layout from localstorage (if any), and
   * merges it together with the default one.
   *
   * We could also just reset the layout if items have changed.
   */
  _getInitialLayout = (dynamicRights = null) => {
    const layouts = this._createDefaultLayouts(dynamicRights);
    const savedLayouts = this._getLayoutFromLS(LAYOUTS_KEY) || {};
    Object.keys(layouts).forEach((size) => {
      const layoutArray = layouts[size];
      const savedArray = savedLayouts[size] || [];
      if (layoutArray.length == savedArray.length) {
        layoutArray.forEach((layout) => {
          const matchIndex = (savedLayouts[size] || []).findIndex(
            (obj) => obj.i == layout.i
          );
          if (matchIndex != -1)
            layoutArray[matchIndex] = {
              ...layoutArray[matchIndex],
              ...savedLayouts[size][matchIndex],
              h: BLOCK_HEIGHT,
              minH: BLOCK_HEIGHT,
              maxH: BLOCK_HEIGHT,
              isResizable: false,
            };
        });
      }
      layouts[size] = layoutArray;
    });
    return layouts;
  };

  _sortLayout = (a, b) => {
    if (a.y < b.y) {
      return -1;
    } else if (a.y > b.y) {
      return 1;
    } else {
      if (a.x == b.x) return 0;
      if (a.x < b.x) {
        return -1;
      } else {
        return 1;
      }
    }
  };

  /**
   * Creating a layout for all sizes based on one edited layout.
   *
   * @param layout is the layout that has changed
   * @param layoutSize the size class of the changed layout (based on breakpoints)
   * @param allLayouts the whole current layout object (all sizes)
   */
  _createLayoutsByLayout = (layout, layoutSize, allLayouts) => {
    const layouts = {
      xl: [],
      lg: [],
      md: [],
      sm: [],
    };
    Object.keys(layouts).forEach((size) => {
      if (size == "sm") {
        // small size is always the same
        layout.sort(this._sortLayout);
        layouts[size] = layout.map((obj, i) => {
          const layoutType = this._getFilteredItems(
            this.state.dynamicRights
          ).find((item) => item.key == obj.i).layoutType;
          return {
            ...obj,
            minW: BLOCK_WIDTHS[size][layoutType],
            maxW: BLOCK_WIDTHS[size][layoutType],
            h: BLOCK_HEIGHT,
            x: 0,
            y: BLOCK_HEIGHT * i,
          };
        });
      } else {
        layouts[size] =
          layoutSize == "sm" // not messing other sizes up when changing the small layout, for now let's keep them as they were
            ? (layouts[size] = allLayouts[size])
            : layout.map((obj) => {
                // otherwise set widths and x-coordinates correctly on different sizes
                const layoutType = this._getFilteredItems(
                  this.state.dynamicRights
                ).find((item) => item.key == obj.i).layoutType;
                let widthOnRow = 0;
                let shouldChangeWidth = false;
                let onlyOneInRow = true;
                (allLayouts[size] || []).forEach((item) => {
                  if (item.y == obj.y && item.i != obj.i) {
                    onlyOneInRow = false;
                    if (item.x < obj.x) {
                      widthOnRow += item.w;
                    }
                    const checkLayoutType = this._getFilteredItems(
                      this.state.dynamicRights
                    ).find((i) => i.key == item.i).layoutType;
                    if (layoutType != checkLayoutType) {
                      // if a small and a large block are on the same row, should change width responsively
                      shouldChangeWidth = true;
                    }
                  }
                });
                const defaultWidth = BLOCK_WIDTHS[size][layoutType];
                if (onlyOneInRow && obj.w != 6 && obj.w != 12) {
                  // if is only one in row and width is not half or full, let's change responsively
                  shouldChangeWidth = true;
                }
                const x = widthOnRow > obj.x ? widthOnRow : obj.x;
                return {
                  ...obj,
                  minW: defaultWidth,
                  h: BLOCK_HEIGHT,
                  w: shouldChangeWidth
                    ? defaultWidth
                    : Math.max(obj.w, defaultWidth),
                  x,
                };
              });
      }
    });
    return layouts;
  };

  /**
   * Creating a new layout for all sizes based on the changed layout size.
   * Otherwise only the current layout size would be changed, and blocks would move around
   * when changing view size.
   *
   * There could be a (lot) better solution to this.
   */
  onLayoutChange = (currentLayout, allLayouts) => {
    let currentSize;
    Object.keys(allLayouts).forEach((size) => {
      if (allLayouts[size] == currentLayout) currentSize = size;
    });
    const layouts = this._createLayoutsByLayout(
      currentLayout,
      currentSize,
      allLayouts
    );
    this._saveLayoutToLS(LAYOUTS_KEY, layouts); // maybe better to save to our database
    this.setState({ layouts });
  };

  _getDefaultLayoutY = (size, index, width, layouts) => {
    switch (size) {
      case "sm":
        return BLOCK_HEIGHT * index;
      default: {
        // checks if the block can fit on the row it's supposed to, if not moves it down
        let calculatedY = BLOCK_HEIGHT * parseInt(index / 2);
        let widthOnRow = 0;
        (layouts[size] || []).forEach((item) => {
          if (item.y == calculatedY) {
            widthOnRow += item.w;
          }
        });
        const maxWidth = this.layoutColumns[size];
        if (widthOnRow + width > maxWidth) {
          calculatedY += BLOCK_HEIGHT;
        }
        return calculatedY;
      }
    }
  };

  _getDefaultLayoutX = (size, y, layouts) => {
    switch (size) {
      case "sm":
        return 0;
      default: {
        // puts the block in the end of the row
        let widthOnRow = 0;
        (layouts[size] || []).forEach((item) => {
          if (item.y == y) {
            widthOnRow += item.w;
          }
        });
        return widthOnRow;
      }
    }
  };

  /**
   * Creates a layout object for a specific grid item, for one layout size.
   */
  _getDefaultLayoutForItem = (size, item, index, layouts) => {
    const w = BLOCK_WIDTHS[size][item.layoutType];
    const y = this._getDefaultLayoutY(size, index, w, layouts);
    const x = this._getDefaultLayoutX(size, y, layouts);
    return {
      i: item.key,
      w,
      minW: w,
      minH: BLOCK_HEIGHT,
      maxH: BLOCK_HEIGHT,
      y,
      x,
      ...this.defaultBlockSettings,
    };
  };

  /**
   * Creates layouts for different view sizes.
   */
  _createDefaultLayouts = (dynamicRights) => {
    const layouts = {
      xl: [],
      lg: [],
      md: [],
      sm: [],
    };

    Object.keys(layouts).forEach((size) => {
      this._getFilteredItems(dynamicRights).forEach((item, i) => {
        const layout = this._getDefaultLayoutForItem(size, item, i, layouts);
        layouts[size].push(layout);
      });
    });
    return layouts;
  };

  _getLayoutFromLS = (key) => {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } catch (e) {}
    }
    return ls[key];
  };

  _saveLayoutToLS = (key, value) => {
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-8",
        JSON.stringify({
          [key]: value,
        })
      );
    }
  };

  _getComponentForKey = (key) => {
    const { currency, timers } = this.state;
    switch (key) {
      case "hours_overview":
        return <HoursOverview ref={this.hours_overview} currency={currency} />;
      case "time_tracker":
        return <TimeTracker />;
      case "activities_overview":
        return <ActivitiesOverview ref={this.activities_overview} />;
      case "activities":
        return (
          <ActivitiesList setPopUp={this.setPopUp} ref={this.activities} />
        );
      case "tasks_overview":
        return <TasksOverview ref={this.tasks_overview} />;
      case "tasks":
        return (
          <TasksList
            timers={timers}
            stopProjectsListTimer={
              this.projects.current ? this.projects.current.endTimer : () => {}
            }
            ref={this.tasks}
            setPopUp={this.setPopUp}
          />
        );
      case "projects_overview":
        return <ProjectsOverview ref={this.projects_overview} />;
      case "projects":
        return (
          <ProjectsList
            stopTasksListTimer={
              this.tasks.current ? this.tasks.current.endTimer : () => {}
            }
            setPopUp={this.setPopUp}
            ref={this.projects}
            timers={timers}
            setPopUp={this.setPopUp}
          />
        );
      default:
        return key;
    }
  };

  _getFilteredItems = (dynamicRights) => {
    return this.items.filter((item) =>
      item.checkRight ? item.checkRight(dynamicRights) : true
    );
  };

  _renderBlocks = () => {
    return this._getFilteredItems(this.state.dynamicRights).map((item) => {
      return (
        <Block
          extraClassName={
            item.key == "time_tracker" ? "time-tracker-block" : ""
          }
          key={item.key}
        >
          <LazyLoad
            once={true}
            key={item.key}
            overflow
            throttle={100}
            height={620}
          >
            {this._getComponentForKey(item.key)}
          </LazyLoad>
        </Block>
      );
    });
  };

  setPopUp = (popUpComponent) => {
    this.setState({ popUpComponent });
  };

  _renderPopUp = () => {
    if (!this.state.popUpComponent) return null;
    return (
      <div
        onClick={(e) =>
          e.target.className == "popup-container" && this.setPopUp(null)
        }
        className="popup-container"
      >
        {this.state.popUpComponent}
      </div>
    );
  };

  render() {
    const { layouts, dynamicRights } = this.state;
    return (
      <div id="my-day" data-testid="my-day">
        <OnboardingSection />
        {dynamicRights ? (
          <ResponsiveGridLayout
            className="layout"
            rowHeight={140}
            draggableCancel={`.no-my-day-drag, .TMRDialogGrid, .TMRWorkhourDialog, .TMRDialogBackground, .TMRDialogInputs, .MuiModal-root-72, .jss72, .ColorChangePopup, .ListPopup, .muiColumnMenuListPopper, .${bulkStyles.hourSelector}, #${bulkStyles.bulkEntry}, .treeDropDownPopperOptions`}
            margin={[20, 20]}
            layouts={layouts}
            breakpoints={this.layoutBreakpoints}
            cols={this.layoutColumns}
            onLayoutChange={this.onLayoutChange}
          >
            {this._renderBlocks()}
          </ResponsiveGridLayout>
        ) : (
          <img
            style={loaderStyle}
            src={require("../insights/img/loading.svg").default}
          />
        )}
        {this._renderPopUp()}
      </div>
    );
  }
}

const loaderStyle = {
  width: 60,
  height: 60,
  padding: 20,
};

const Block = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={`${props.className} block ${props.extraClassName}`}
    >
      {props.children}
    </div>
  );
});

const layoutTypes = {
  small: "small-block",
  wide: "wide-block",
};

const BLOCK_HEIGHT = 4;
const BLOCK_WIDTHS = {
  xl: {
    [layoutTypes.small]: 4,
    [layoutTypes.wide]: 10,
  },
  lg: {
    [layoutTypes.small]: 4,
    [layoutTypes.wide]: 8,
  },
  md: {
    [layoutTypes.small]: 5,
    [layoutTypes.wide]: 7,
  },
  sm: {
    [layoutTypes.small]: 1,
    [layoutTypes.wide]: 1,
  },
};
