import React from 'react';
import DataHandler from '../../general/DataHandler';
import TaimerComponent from '../../TaimerComponent';
import { CatalogDialog } from '../../list/dialogs/ProductCatalogDialogs';
import colors from '../../colors';
import DataList from '../../general/DataList';
import { ProposalContentSection } from './types';
import OutlinedField from '../../general/OutlinedField';

interface Props {
    onClose: () => void;
    onTemplateSelected: (name: string, id?: string) => void;
    company: string;
}

interface State {
    templates: ProposalContentSection[];
    loading: boolean;
    selectedTemplate?: any;
    newTemplateName: string;
}

export class AddTemplateDialog extends TaimerComponent<Props, State> {
    _createNewInput: any = React.createRef();
    constructor(props, context) {
        super(props, context, 'projects/proposal/AddTemplateDialog');
        this.state = {
            templates: [],
            loading: true,
            selectedTemplate: undefined,
            newTemplateName: '',
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        this._getTemplates();
        setTimeout(() => {
            this._createNewInput.current && this._createNewInput.current.focus();
        }, 100);
    };

    _getTemplates = () => {
        DataHandler.get({
            url: `companies/${this.props.company}/proposal_templates/`,
        })
            .done((templates) => {

                this.setState({ templates, loading: false });
            })
            .fail((err) => {
                this.setState({ loading: false });
                console.log(err);
            });
    };

    onSelectTemplate = (selectedTemplate) => {
        this.setState({ selectedTemplate: selectedTemplate.id == 0 ? undefined : selectedTemplate });
    };

    render() {
        const { onClose, onTemplateSelected } = this.props;
        const { selectedTemplate, templates, loading, newTemplateName } = this.state;
        return (
            <CatalogDialog
                onClose={onClose}
                buttons={[
                    {
                        title: this.tr('Cancel'),
                        onClick: onClose,
                        style: {
                            backgroundColor: 'white',
                            color: colors.steel,
                        },
                    },
                    {
                        title: this.tr('Save'),
                        disabled: !newTemplateName && !selectedTemplate,
                        onClick: () => onTemplateSelected(selectedTemplate?.title || newTemplateName, selectedTemplate?.id),
                        style: {
                            backgroundColor: colors.deep_sky_blue,
                            color: 'white',
                        },
                    },
                ]}
                header={`${this.tr('Save as template')}`}
            >
                <div className="dialog-content">
                    <p className="desc">{`${this.tr('Choose an existing template you want to edit, or create a completely new template.')}`}</p>
                    <h2>{this.tr('Edit existing template')}</h2>
                    <DataList
                        //@ts-ignore
                        label={loading ? this.tr('Loading templates...') : this.tr('Choose template')}
                        isDisabled={loading || !!newTemplateName}
                        name="id"
                        addNoneOption
                        options={templates.map((t) => ({ ...t, label: t.title }))}
                        value={selectedTemplate}
                        onChange={this.onSelectTemplate}
                        className="catalog-data-list"
                        shownCount={20}
                    />
                    <h2>{this.tr('Create new template')}</h2>
                    <OutlinedField
                        ref={this._createNewInput}
                        label={this.tr('Name template')}
                        name="name"
                        value={newTemplateName}
                        disabled={!!selectedTemplate}
                        fullWidth={true}
                        onKeyUp={(e) => {
                            this.setState({ newTemplateName: e.target.value });
                        }}
                    />
                </div>
            </CatalogDialog>
        );
    }
}
