import React, { useState } from "react";
import "./OverviewBlockCell.css";
import InsightSlider from "../../insights/InsightSlider";

const OverviewBlockCell = props => {
  const {
    header,
    sliderHeader,
    value,
    subValue,
    className,
    sliderData,
    sliderColumns = [],
    onMouseEnter,
    onMouseLeave,
    renderHover,
    subValueStyles,
    leftExtraComponent,
  } = props;
  const [sliderOpen, toggleSlider] = useState(false);
  return (
    <React.Fragment>
      <InsightSlider
        onClose={() => toggleSlider(false)}
        open={sliderOpen}
        data={sliderData || []}
        label={sliderHeader || header}
        columns={sliderColumns}
      />
      <div
        data-testid={props['data-testid']}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => sliderData && toggleSlider(true)}
        className={`overview-block-cell ${className}`}
      >
        <div className={`main-content ${sliderData && "highlightable"}`}>
          <h3>{header}</h3>
          <div className="row">
            {leftExtraComponent}
            <h2 data-testid={`${props['data-testid']}-value`}>{value}</h2>
            {subValue !== NaN && subValue !== null ? <p style={subValueStyles}>{subValue}</p> : undefined}
          </div>
        </div>
        {renderHover && <div className="hover-view">{renderHover}</div>}
      </div>
    </React.Fragment>
  );
};

export default OverviewBlockCell;
