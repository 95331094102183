import TaimerComponent from '../../TaimerComponent';
import ActionSelectDialog from '../../dialogs/ActionSelectDialog';
import ErrorsList from '../../dialogs/elements/ErrorsList';

import { DrawerProps } from '@mui/material';
import { AutoFixHigh, Autorenew, CheckBox } from '@mui/icons-material';
import { cloneDeep } from 'lodash';

export interface data {
    handleError?: (action?: string) => void;
    invoices?: any;
}

export interface HandleErrorDialogProps extends DrawerProps {
    onDialogClose: (event?: any, reason?: string) => void;
    data?: data;
}

class HandleErrorDialog extends TaimerComponent<HandleErrorDialogProps> {
    constructor(props, context) {
        super(props, context, "invoices/dialogs/HandleErrorDialog");
    }

    renderErrors = () => {
        const { data } = this.props;
        const invoices = data?.invoices ? cloneDeep(data?.invoices) : [];

        if (invoices.length == 0) {
            return <p>{this.tr("No error invoices selected!")}</p>
        }

        const errorData = invoices.map(i => {
            const error = {
                header: this.tr("Invoice") + " " + i.bill_id + ":",
                message: i.translate_error_message == 1 ? this.tr(i.log_error_message) : i.log_error_message
            }
            return error;
        })

        return (
            <ErrorsList
                type={"error"}
                data={errorData}
            />
        )
    }

    getActions = (massEdit) => {
        return [
			{
				key: "edit_invoice",
				title: massEdit ? this.tr("Revert invoices") : this.tr("Revert and edit invoice"),
				description: massEdit ? this.tr("Select this, if errors requires editing invoices. Send invoices again after editing.") : this.tr("Select this, if error requires editing invoice. Send invoice again after editing."),
				icon: AutoFixHigh
			},
			{
				key: "send_again",
				title: massEdit ? this.tr("Send invoices again") : this.tr("Send invoice again"),
				description: massEdit ? this.tr("Select this, if you want to try sending invoices again without editing.") : this.tr("Select this, if you want to try sending invoice again without editing."),
				icon: Autorenew
			},
			{
				key: "remove_error",
				title: massEdit ? this.tr("Clear errors") : this.tr("Clear error"),
				description: massEdit ? this.tr("Select this, if you think that the errors doesn't require further actions.") : this.tr("Select this, if you think that the error doesn't require further actions."),
				icon: CheckBox
			}
		];
    }
    
    render() { 
        const { data, onDialogClose } = this.props;
        const invoices = data?.invoices || [];
        const massEdit = invoices.length > 1;

        return (
            <ActionSelectDialog 
                contentBeforeActions={this.renderErrors()}
                actions={this.getActions(massEdit)}
                title={massEdit ? this.tr("Handle errors") : this.tr("Handle error") }
                subtitle={massEdit ? this.tr("Select action to handle invoice errors") : this.tr("Select action to handle invoice error")}
                onSelect={(action) => data?.handleError && data.handleError(action)} 
                onDialogClose={onDialogClose}
                hideActions={data?.invoices?.length == 0}
            />

        )
    }
}

export default HandleErrorDialog;