import React, { ChangeEvent } from 'react';
import { Tab, Tabs } from '@mui/material';
import styles from './EntityCardTabs.module.scss';

interface TabType {
    id: string;
    label: string;
    disabled?: boolean;
}

interface Props {
    tabs: TabType[];
    onTabChange: (event: ChangeEvent<{}>, value: any) => void;
    selectedTab: string;
}

const EntityCardTabs = (props: Props) => {
    const { tabs, onTabChange, selectedTab } = props;
    return (
        <Tabs onChange={onTabChange} value={selectedTab} className={`${styles.entityCardTabs} details`}>
            {tabs.map((tab) => (
                <Tab value={tab.id} label={tab.label} disabled={tab.disabled} />
            ))}
        </Tabs>
    );
};

export default EntityCardTabs;
