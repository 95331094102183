import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import ResourcingView from '../../resourcing/ResourcingView';

class ResourcePlanning extends TaimerComponent<any, any> {
    tabs;
    constructor(props, context) {
        super(props, context, 'navigation/pages/ResourcePlanning');
        const {
            userObject,
            functions: { hasPrivilege },
        } = this.context;
        this.tabs = [
            {
                id: 'grid',
                label: this.tr('Grid'),
            },
            {
                id: 'gantt',
                label: this.tr('Gantt'),
            },
            {
                id: 'list',
                label: this.tr('List'),
            },
            {
                id: 'usage',
                label: this.tr('Utilization'),
            },
        ];
    }

    getTabInfoButtonProps = (selectedTab) => {
        switch (selectedTab) {
            // case 'grid':
            //     return {
            //         title: this.tr('How Resourcing Grid works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8080501259154' : 'https://taimersupport.zendesk.com/hc/articles/8080501259154',
            //         },
            //     };
            //     case 'gantt':
            //     return {
            //         title: this.tr('How Resourcing Gantt works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8081424386194' : 'https://taimersupport.zendesk.com/hc/articles/8081424386194',
            //         },
            //     };
            //     case 'list':
            //     return {
            //         title: this.tr('How Resourcing List works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8080627455250' : 'https://taimersupport.zendesk.com/hc/articles/8080627455250',
            //         },
            //     };
            //     case 'usage':
            //     return {
            //         title: this.tr('How Resourcing Utilization works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8080972809106' : 'https://taimersupport.zendesk.com/hc/articles/8080972809106',
            //         },
            //     };
            default:
                return undefined;
        }
    };

    render() {
        return (
            <WithTabs
                offsetTop={10}
                tabs={this.tabs}
                selectedTab={this.props.selectedTab}
                selectedSubTab={this.props.selectedSubTab}
                tabsAlwaysVisible
                getTabInfoButtonProps={this.getTabInfoButtonProps}
            >
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'grid':
                        case 'gantt':
                        case 'list':
                        case 'usage':
                            return <ResourcingView {...this.props} stickySearchKey="resourcing-view" fullHeight selectedTab={selectedTab} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default ResourcePlanning;
