import {
	addDays,
	addMonths,
	addWeeks,
	differenceInDays,
	endOfWeek,
	format,
	getDaysInMonth,
	isSameDay,
	isSameMonth,
	isSameWeek,
	setDay,
	startOfWeek,
	subMonths
} from "date-fns";
import { setDate } from "date-fns/esm";
import React from "react";
import {
	AutoSizer,
	Column,
	GridCellRenderer,
	ScrollSync,
	Grid as VirtualizedGrid,
	Table as VirtualizedTable
} from "react-virtualized";
import "./Grid.scss";
import GridCell from "./GridCell";

import TaimerComponent from "../../../../TaimerComponent";
import ConfirmationPopUp from "../../ResourcingGrid/components/ConfirmationPopUp";
import ResourcingGridSlider from "../../ResourcingGrid/components/ResourcingGridSlider";
import TimeTrackingButton from '../../ResourcingGrid/components/TimeTrackingButton';

import { ReactComponent as HighPriority } from "../../../images/priority/high.svg";
import { ReactComponent as HighestPriority } from "../../../images/priority/highest.svg";
import { ReactComponent as LowPriorirty } from "../../../images/priority/low.svg";
import { ReactComponent as LowestPriorirty } from "../../../images/priority/lowest.svg";
import { ReactComponent as NormalPriorirty } from "../../../images/priority/normal.svg";


import cn from 'classnames';
import _, { cloneDeep } from 'lodash';

import { ExpandData, ResourcingDialog, ViewOptions } from "../../../ResourcingView";
import { Employee } from "../../../helpers";
import { GridViewData, GridViewDataItem, TaskType, TotalData } from "../../../resourcing";
import { GridViewColumn } from "../columns";
import { GridRow } from "../helpers";
import GridLeftCell from "./GridLeftCell";
import GridLeftProjectCell from "./GridLeftProjectCell";
import GridLeftTaskCell from "./GridLeftTaskCell";
import GridLeftUserCell from "./GridLeftUserCell";

const HEADER_HEIGHT = 45;
const ROW_HEIGHT = 45;
const FOOTER_HEIGHT = 30;

const GRID_CELL_WIDTH = 70;
const MARKER_BLUE = "rgba(45,159,247,0.08)";
const MARKER_RED = "rgba(254,213,228,0.3)";
const MARKER_GRAY = "rgba(221, 227, 232, 0.26)";
const MARKER_GRAY_DARK = "rgba(221, 227, 232, 0.4)";

const emptyObj = { hours: 0, percent: null };

const formatHours = value => {
	if (!value) return "-";
	return Number(value).toFixed(2) + " h";
};

const formatPercent = value => {
	if (value === undefined || value === null) return "-";
	if (!value) return "0 %";
	return Number(value).toFixed(0) + " %";
};

interface SelectedCell {
	row_id: string;
	cellIndex: any;
	gridRowIndex: any;
}

interface GridProps {
	renderLeftTable?: boolean;

	tableColumns: GridViewColumn[];
	viewMode: string;
	zoom: string;
	grouping?: string;

	range: { start: Date; end: Date; };
	viewOptions: ViewOptions;
	data: GridRow[];

	viewTotal?: TotalData;
	allTotal?: TotalData;
	userTotal?: TotalData;

	selectedCells?: SelectedCell[];
	manualValue?: any;

	columnWidth?: any;
	totalRows: string[];

	expandedRows: Dictionary<boolean>;

	setExpandedRows: (expandedRows: Dictionary<boolean>) => void;

	holidays: Dictionary<boolean>;

	renderHover?: (row: GridRow, extraButtons: JSX.Element | undefined) => void;
	closeHover?: () => void;

	openDialog: (dialog: ResourcingDialog) => void;

	save: (update: any, options?: any) => void;

	getWorkdayLength: (userId: number, date: Date) => number;

	expand: ExpandData;

	onProjectRangeExtended: (start: Date, end: Date) => void;
}

interface ColumnSizes {
	Columns:  Dictionary<number>;
	ContainerWidth?: number;
}

interface GridState {
	data: GridRow[];
	rowsByKey: Dictionary<GridRow>;
	dates: Date[];
	selectedCells: SelectedCell[];
	hoverIndex: number | null;
	scrollBarSize: number;
	scrollbar: {
		horizontal: boolean;
		vertical: boolean;
	};
	sliderOpen: boolean;
	containerWidth: number;
	manualValue: any;
	manualValueMode: any;
	selectedWorkdays: number;
	columns: ColumnSizes;
	resizingColumn: boolean;
	smallMode: boolean;
	canAddPercentage: boolean;
	scrollRow?: any;
	popUpComponent?: any;
}

export default class Grid extends TaimerComponent<GridProps, GridState> {
	static defaultProps = {
		renderLeftTable: true,
		tableColumns: [],
		subTableColumns: [],
		optionalTableColumns: [],
		columnWidth: GRID_CELL_WIDTH,
		range: {
			start: subMonths(new Date(), 6),
			end: addMonths(new Date(), 6)
		},
		totalRows: [],
		grouping: 'user',
	};

	container: React.RefObject<HTMLDivElement>;
	grid: React.RefObject<VirtualizedGrid>;
	headerGrid: React.RefObject<VirtualizedGrid>;
	gridContainer: React.RefObject<HTMLDivElement>;
	footerLeftGrid: React.RefObject<VirtualizedGrid>;
	footerRightGrid: React.RefObject<VirtualizedGrid>;

	_scrollingGrid: any;
	_scrollingTable: any;
	_manualValueChanged: any;

	_endHoverTimer: any;
	sliderMode: any;

	priorities: any;

	offset: any;

	sliderDailyHours?: any;

	_initialized = false;

	constructor(props, context) {
		super(props, context, "resourcing/views/ResourcingGrid/components/Grid");

		const { zoom } = props;

		this.container = React.createRef();
		this.grid = React.createRef();
		this.headerGrid = React.createRef();
		this.gridContainer = React.createRef();
		this.footerLeftGrid = React.createRef();
		this.footerRightGrid = React.createRef();

		this._scrollingGrid = false;
		this._scrollingTable = false;
		this._manualValueChanged = false;

		this._endHoverTimer = null;
		this.sliderMode = "relative";

		let columns: Partial<ColumnSizes> = {};

		if (localStorage.resourcing_grid_columns) {
			columns = JSON.parse(localStorage.resourcing_grid_columns) as Partial<ColumnSizes>;
		}

		this.priorities = {
			1: {
				id: 1,
				name: this.tr("Lowest"),
				// default: data.default_priority == 1,
				icon: LowestPriorirty
			},
			2: {
				id: 2,
				name: this.tr("Low"),
				// default: data.default_priority == 2,
				icon: LowPriorirty
			},
			3: {
				id: 3,
				name: this.tr("Normal"),
				// default: data.default_priority == 3,
				icon: NormalPriorirty
			},
			4: {
				id: 4,
				name: this.tr("High"),
				// default: data.default_priority == 4,
				icon: HighPriority
			},
			5: {
				id: 5,
				name: this.tr("Highest"),
				// default: data.default_priority == 5,
				icon: HighestPriority
			}
		};

		this.state = {
			...this._getParsedData(),
			selectedCells: [],
			hoverIndex: null,
			dates: this._getDates(zoom),
			scrollBarSize: 15,
			scrollbar: {
				horizontal: false,
				vertical: false,
			},
			sliderOpen: false,
			containerWidth: 0,
			manualValue: null,
			manualValueMode: null,
			selectedWorkdays: 0,
			columns: {
				Columns: {},
				...columns,
			},
			resizingColumn: false,
			smallMode: false,
			canAddPercentage: false,
		};
	}

	// LIFECYCLE

	componentDidMount = () => {
		super.componentDidMount();
		window.addEventListener("resize", this._setContainerWidth);
		document.addEventListener("keydown", this._checkBackspaceAndEsc);
		this._setContainerWidth();

		this._checkSmallMode();
		this.resetExpandedRows(this.props.expand);
	};

	componentWillUnmount = () => {
		super.componentWillUnmount();
		window.removeEventListener("resize", this._setContainerWidth);
		document.removeEventListener("keydown", this._checkBackspaceAndEsc);
	};

	componentDidUpdate = (oldProps, oldState) => {
		this._checkSmallMode();

		if (oldProps.range !== this.props.range || oldProps.zoom !== this.props.zoom) {
			//this.offset = this.getCurrentDateOffset(mode, dates);

			this.setState({ dates: this._getDates(this.props.zoom) }, () => this._updateGrid());
		}

		// Update footer
		if (oldProps.viewTotal !== this.props.viewTotal || oldProps.allTotal !== this.props.allTotal || oldProps.userTotal !== this.props.userTotal) {
			this._updateFooter();
		}

		else if (
			oldProps.selectedCells !== this.props.selectedCells ||
			oldProps.manualValue !== this.props.manualValue ||
			oldProps.viewMode !== this.props.viewMode) {
			this._updateGrid();
		}

		if (this.props.data !== oldProps.data) {
			const update = this._getParsedData(false,);

			if (this.gridContainer.current) {
				if (this.gridContainer.current.offsetWidth < this.state.dates.length * GRID_CELL_WIDTH) {
					this.offset = this.getCurrentDateOffset(this.props.zoom, this.state.dates);

				}
			}
			this.setState(update, () => {
				this._updateGrid();
				this.resetExpandedRows(this.props.expand);
			});
		}

		else if (!_.isEqual(oldProps.expandedRows, this.props.expandedRows)) {
			const update = this._getParsedData();
			this.setState(update, this._updateGrid);
		}
	};

	_getParsedDataLevel = (rows: GridRow[], grouped: Dictionary<GridRow[]>, expandedRows, parent: any = false) => {
		const unsorted = (!parent ? grouped[''] : grouped[parent.row_id]) ?? [];

		const sorted = _.sortBy(unsorted, [
			x => x.display_type === "user" && x.user?.id !== 0, // Unassigned on top
			x => x.type === "task" && x.task?.task_type !== TaskType.Project, // Project task on top
			x => (x.display_type === "user" ? x.user?.name?.toLowerCase() : (x.display_type === "project" ? x.project?.customer?.toLowerCase() : (x.task?.description?.toLowerCase() ?? x.project?.project?.toLocaleLowerCase()))),
			x => (x.display_type === "project" && x.project ? x.project?.project?.toLowerCase() : (x.task?.description.toLowerCase() ?? x.project?.project?.toLocaleLowerCase())),
		]);

		sorted.forEach(item => {
			const open = expandedRows[item.row_id] ?? false;
			const subItems = grouped[item.row_id] ?? [];

			rows.push({
				...item,
				isSub: parent !== '',
				open,
				hasSubItems: subItems.length > 0,
			});

			if (open) {
				this._getParsedDataLevel(rows, grouped, expandedRows, item);
			}
		});
	}

	_addToMapWithParents = (target, tasks, id) => {
		while (id && tasks[id]) {
			if (target[id]) {
				return;
			}

			target[id] = true;

			id = tasks[id].parent_row;
		}

		return;
	}

	_getParsedData = (expandedRows: Dictionary<boolean> | false = false) => {
		const { data } = this.props;

		const rows: GridRow[] = [];

		if (expandedRows === false && this.props && this.props.expandedRows) {
			expandedRows = this.props.expandedRows;
		}

		const grouped = data ? _.groupBy(data, 'parent_row') : {};
		const keyed = data ? _.keyBy(data, 'row_id') : {};

		if (data)
			this._getParsedDataLevel(rows, grouped, expandedRows || {}, '');

		return {
			data: rows,
			rowsByKey: keyed,
		};
	};

	resetExpandedRows = (option: ExpandData) => {
		const { data, expandedRows, setExpandedRows } = this.props;

		const keyed = data ? _.keyBy(data, 'row_id') : {};

		if (option.all) {
			setExpandedRows(_.chain(data)
				.keyBy(x => x.row_id)
				.mapValues(() => true)
				.value());
		}

		if (option.projects) {
			const projects = data.filter(x => x.display_type === 'project');

			const map = {};

			projects.forEach(p => {
				this._addToMapWithParents(map, keyed, p.row_id);
			});

			setExpandedRows({ ...expandedRows, ...map });
		}

		if (option.users) {
			const users = data.filter(x => x.display_type === 'user');

			const map = {};

			users.forEach(u => {
				this._addToMapWithParents(map, keyed, u.row_id);
			});

			setExpandedRows({ ...expandedRows, ...map });
		}
	}

	expand = (option) => {
		const { data, expandedRows, setExpandedRows } = this.props;

		const keyed = data ? _.keyBy(data, 'row_id') : {};

		if (option === "all") {
			setExpandedRows(_.chain(data)
				.keyBy(x => x.row_id)
				.mapValues(() => true)
				.value());
		} else if (option === "projects") {
			const projects = data.filter(x => x.display_type === 'project');

			const map = {};

			projects.forEach(p => {
				this._addToMapWithParents(map, keyed, p.row_id);
			});

			setExpandedRows({ ...expandedRows, ...map });
		} else if (option === "users") {
			const users = data.filter(x => x.display_type === 'user');

			const map = {};

			users.forEach(u => {
				this._addToMapWithParents(map, keyed, u.row_id);
			});

			setExpandedRows({ ...expandedRows, ...map });
		}
	}

	collapse = (option) => {
		const { data, expandedRows: oldExpandedRows, setExpandedRows } = this.props;

		const expandedRows = { ...oldExpandedRows };

		if (option === "all") {
			setExpandedRows({});
		} else if (option === "projects") {
			const projects = data.filter(x => x.display_type === 'project');

			projects.forEach(p => {
				delete expandedRows[p.row_id];
			});

			setExpandedRows(expandedRows);
		} else if (option === "users") {
			const users = data.filter(x => x.display_type === 'user');

			users.forEach(u => {
				delete expandedRows[u.row_id];
			});

			setExpandedRows(expandedRows);
		}
	}

	// LAYOUT

	_setContainerWidth = () => {
		if (this.container) {
			window.requestAnimationFrame(() => {
				if (!this.container.current)
					return;
				const containerWidth = this.container.current.offsetWidth || 0;

				this.setState({
					containerWidth
				});

				this._updateColumnSizes();
			});
		}

		this._checkSmallMode();
	};

	_checkSmallMode = () => {
		const { smallMode } = this.state;

		const currentheight = window.innerHeight;

		const shouldSmallMode = currentheight < 800 || window.innerWidth < 1400;

		if (smallMode !== shouldSmallMode) {
			this.setState({ smallMode: shouldSmallMode });
		}
	}

	//KEYBOARD
	_checkBackspaceAndEsc = event => {
		if (!this.state.sliderOpen) return;
		const charCode = event.keyCode || event.charCode;
		if (charCode == 27) {
			event.preventDefault();
			this._cancelEditing();
			return;
		}
		// if (this.sliderMode == "hours" || charCode != 8) return;

		// const manualValue = String(this.state.manualValue || "").slice(0, -1);
		// this._setManualValue(manualValue);
	};

	// GRID
	_renderGrid = (onScroll, scrollLeft, scrollTop) => {
		const { columnWidth, totalRows } = this.props;
		const { data, dates, scrollRow, scrollBarSize, scrollbar } = this.state;

		const totalRowsCount = totalRows?.length ?? 0;
		const footerHeight = FOOTER_HEIGHT * totalRowsCount;

		return (
			<div className="grid" ref={this.gridContainer}>
				<React.Fragment>
					{this._renderHeader(scrollLeft)}
					<AutoSizer>
						{({ width, height }) => (
							<>
								<VirtualizedGrid
									ref={this.grid}
									className="no-outline scroll-overlay"
									onScroll={e => {
										if (!this._scrollingTable) {
											this._scrollingGrid = true;
											onScroll(e);
										}
										this._scrollingTable = false;
									}}
									scrollTop={this.state.sliderOpen && scrollRow ? scrollTop - 200 : scrollTop}
									onScrollbarPresenceChange={({ size, horizontal, vertical }) =>
										this.setState({ scrollBarSize: size, scrollbar: { horizontal, vertical } })
									}
									scrollLeft={scrollLeft}
									cellRenderer={this._cellRenderer}
									columnCount={dates.length}
									columnWidth={columnWidth}
									height={height - HEADER_HEIGHT - footerHeight}
									width={width}
									estimatedColumnSize={columnWidth}
									estimatedRowSize={ROW_HEIGHT}
									rowCount={data.length}
									rowHeight={ROW_HEIGHT}
								/>
								<div className="grid-footer-right" style={{ width }}>
									<VirtualizedGrid
										// className="no-outline hidden-scroll"
										scrollLeft={scrollLeft}
										ref={this.footerRightGrid}
										cellRenderer={this._footerCellRenderer}
										columnCount={dates.length}
										columnWidth={this.props.columnWidth}
										overscanColumnCount={10}
										height={footerHeight}
										rowCount={totalRowsCount}
										rowHeight={FOOTER_HEIGHT}
										width={width - (scrollbar.vertical ? scrollBarSize : 0)}
									/>
								</div>
							</>
						)}
					</AutoSizer>
				</React.Fragment>
			</div>
		);
	};

	_updateGrid = () => {
		this.headerGrid.current && this.headerGrid.current.forceUpdate();
		this.grid.current && this.grid.current.forceUpdate();
	};

	_updateHeaderGrid = () => {
		this.headerGrid.current && this.headerGrid.current.forceUpdate();
	}

	_updateFooter = (resize = false) => {
		resize && this.footerLeftGrid.current && this.footerLeftGrid.current.recomputeGridSize();
		this.footerLeftGrid.current && this.footerLeftGrid.current.forceUpdate();
		this.footerRightGrid.current && this.footerRightGrid.current.forceUpdate();
	}

	// GRID HEADER

	_headerCellRenderer = ({ style, columnIndex }) => {
		const { dates } = this.state;
		const colObj = this._getColumnTitle(dates[columnIndex]);
		return (
			<div
				className="grid-header-cell"
				style={{
					...style,
					color: colObj.color
				}}
			>
				{colObj.value}
			</div>
		);
	};

	_renderHeader = scrollLeft => {
		const { dates, scrollbar, scrollBarSize } = this.state;
		return (
			<div style={{ width: "100%", height: HEADER_HEIGHT }}>
				<AutoSizer>
					{({ width, height }) => (
						<VirtualizedGrid
							className="no-outline hidden-scroll"
							scrollLeft={scrollLeft}
							ref={this.headerGrid}
							cellRenderer={this._headerCellRenderer}
							columnCount={dates.length}
							columnWidth={this.props.columnWidth}
							overscanColumnCount={10}
							height={height}
							rowCount={1}
							rowHeight={HEADER_HEIGHT}
							width={width - (scrollbar.vertical ? scrollBarSize : 0)}
						/>
					)}
				</AutoSizer>
			</div>
		);
	};

	// GRID Footer
	_footerLeftCellRenderer = ({ style, columnIndex, rowIndex }) => {
		const { totalRows, viewTotal, allTotal, userTotal } = this.props;
		const row = totalRows?.[rowIndex];
		const col = this._getTableColumns()[columnIndex];

		let content = '';

		// Title
		if (col.name === "text") {
			const titles = {
				totalVisible: this.tr("Total Visible"),
				totalOwn: this.tr("Total Own"),
				total: this.tr("Total"),
			};

			content = titles[row] ?? row;
		} else if (col.name === "allocated" || col.name === "allocated_all_time" || col.name === "resourced" || col.name === "resourced_all_time" || 
				col.name === "tracked" || col.name === 'tracked_all_time' || col.name === "projects_allocated" || 
				col.name === "projects_allocated_all_time"  || col.name === "remaining" || col.name === "remaining_all_time") {
			let source: TotalData | undefined | false = false;

			if (row === "totalVisible") {
				source = viewTotal;
			} else if (row === "total") {
				source = allTotal;
			} else if (row === "totalOwn") {
				source = userTotal;
			}

			if (source && source[col.name] !== undefined && source[col.name] !== null) {
				content = `${source[col.name].toFixed(2)} h`;
			} else {
				content = '-';
			}
		}

		return (
			<div
				className={cn("grid-footer-cell", `grid-footer-cell-${col.name}`)}
				style={{
					...style,
					// color: colObj.color
				}}
			>
				{content}
			</div>
		);
	};

	_getFooterCellValue = (columnIndex, totalRow) => {
		const { dates, data } = this.state;
		const { zoom } = this.props;
		const date = dates[columnIndex];
		const dateString = this._getDateString(date);

		let source: any = {};

		if (totalRow === 'totalVisible') {
			source = this.props.viewTotal;
		} else if (totalRow === 'totalOwn') {
			source = this.props.userTotal;
		} else if (totalRow === 'total') {
			source = this.props.allTotal;
		}

		if (!source?.gridData?.[zoom]) {
			return emptyObj;
		}

		return source.gridData[zoom || 'daily'][dateString] || emptyObj;
	};

	_footerCellRenderer = ({ style, columnIndex, rowIndex }) => {
		const { totalRows } = this.props;
		const { dates } = this.state;
		const colObj = this._getColumnTitle(dates[columnIndex], "#333333");

		const totalRow = totalRows?.[rowIndex];
		const v = this._getFooterCellValue(columnIndex, totalRow);

		return (
			<div
				className="grid-footer-right-cell"
				style={{
					...style,
					color: colObj.color
				}}
			>
				{v.hours !== 0 && `${Number(v.hours).toFixed(2)} h`}
			</div>
		);
	};

	_getColumnTitle = (date, color = "#7288a0") => {
		const { zoom } = this.props;
		const { tr } = this;
		let value = "";
		const today = new Date();
		switch (zoom) {
			case "daily":
				value = format(date, this.context.userObject.dateFormat);
				if (this._isHoliday(date)) color = "#EE3931";
				else if (isSameDay(date, today)) color = "#2d9ff7";
				break;
			case "weekly":
				if (
					isSameWeek(date, today, {
						weekStartsOn: this.context.calendar.startOfWeek
					})
				)
					color = "#2d9ff7";
				value = `${tr("Wk")} ${format(date, "WW")}, ${format(date, "YYYY")}`;
				break;
			case "monthly":
				if (isSameMonth(date, today)) color = "#2d9ff7";
				value = tr(format(date, "MMM")) + format(date, " YYYY");
				break;
			default:
				break;
		}
		return { value, color };
	};

	// GRID CELLS

	_cellRenderer: GridCellRenderer = ({ style, columnIndex, rowIndex }) => {
		const { dates, manualValue, manualValueMode, selectedCells, data, rowsByKey } = this.state;
		const { viewMode, holidays, getWorkdayLength } = this.props;

		const column = dates[columnIndex];
		const item = data[rowIndex];
		const parentItem = item.root_row ? rowsByKey[item.root_row] : null;
		const selected = selectedCells.findIndex(
			obj => obj.row_id == item.row_id && obj.cellIndex == columnIndex
		) != -1;
		return (<GridCell
			style={style}
			viewMode={viewMode}
			getHoursFromPercent={this._getHoursFromPercent}
			getPercentFromHours={this._getPercentFromHours}
			isMainRow={false}
			manualValue={manualValue}
			manualValueMode={manualValueMode}
			parentItem={parentItem}
			getValue={this._getCellValue}
			columnIndex={columnIndex}
			holidays={holidays}
			rowIndex={rowIndex}
			item={item}
			column={column}
			getColor={this._getCellColor}
			selected={selected}
			getWorkdayLength={getWorkdayLength}
			onClick={(event) => {
				if (item.type === "total") {
					this._toggleRowExpand(item);
					return;
				}

				if (!item.hoursEditable) {
					return;
				}
				event.persist();
				this._toggleCellSelection(
					item,
					columnIndex,
					rowIndex,
					event
				)
			}}
		/>);
	};

	_getCellColor = (value: number|undefined, date: Date, isSubCell = false, available = false) => {
		if (available && value !== undefined) {
			if (value < 0) {
				return { color: "#f7548f", backgroundColor: "#fed5e4" };
			} else {
				return { color: "#42b677", backgroundColor: "#caead9" };
			}
		}

		let color = isSubCell ? "#f9f9f9" : "transparent";
		let backgroundColor: string | null = null;
		if (value === 0 && date) {
			const { zoom } = this.props;
			const today = new Date();
			switch (zoom) {
				case "daily":
					if (this._isHoliday(date)) {
						return { color: MARKER_RED, backgroundColor: MARKER_RED };
					}
					else if (isSameDay(date, today)) {
						return { color: MARKER_BLUE, backgroundColor: MARKER_BLUE };
					}
					else if (this._isWeekend(date)) {
						return {
							color: color,
							backgroundColor: isSubCell ? MARKER_GRAY_DARK : MARKER_GRAY
						};
					}
					break;
				case "weekly":
					if (
						isSameWeek(date, today, {
							weekStartsOn: this.context.calendar.startOfWeek
						})
					)
						return { color: MARKER_BLUE, backgroundColor: MARKER_BLUE };
					break;
				case "monthly":
					if (isSameMonth(date, today))
						return { color: MARKER_BLUE, backgroundColor: MARKER_BLUE };
					break;
				default:
					break;
			}
			return { color, backgroundColor };
		}

		// Check to accurary of 2 decimals, because that's what shows
		const numberValue = Number(value);

		if (!isNaN(numberValue)) {
			const roundedValue = Number(numberValue.toFixed(2));

			if (roundedValue > 0 && roundedValue <= 40) {
				color = "#f5a623";
				backgroundColor = "#fdefd7";
			} else if (roundedValue > 40 && roundedValue <= 60) {
				color = "#d4890d";
				backgroundColor = "#facf88";
			} else if (roundedValue > 60 && roundedValue <= 80) {
				color = "#42b677";
				backgroundColor = "#d4f8f0";
			} else if (roundedValue > 80 && roundedValue <= 100) {
				color = "#42b677";
				backgroundColor = "#caead9";
			} else if (roundedValue > 100) {
				color = "#f7548f";
				backgroundColor = "#fed5e4";
			}
		}

		return { color, backgroundColor };
	};

	_getDateString = (date: Date) => {
		const { zoom } = this.props;
		let dateString;
		switch (zoom) {
			case "monthly":
				dateString = format(date, "YYYY-MM-01");
				break;
			case "weekly":
				dateString = format(
					startOfWeek(date, { weekStartsOn: 1 }),
					"YYYY-MM-DD"
				);
				break;
			case "daily":
				dateString = format(date, "YYYY-MM-DD");
				break;
			default:
				break;
		}
		return dateString;
	};

	_getCellValue = (date: Date, index: number) => {
		const { zoom } = this.props;
		const { data } = this.state;
		const dateString = this._getDateString(date);

		const row = data[index];

		if (!row?.gridData?.[zoom])
			return emptyObj;

		return data[index]?.gridData?.[zoom]?.[dateString] || emptyObj;
	};

	// GRID ACTIONS

	_toggleCellSelection = (item, cellIndex, gridRowIndex, event) => {
		const { data, dates, rowsByKey } = this.state;
		const { zoom, viewMode } = this.props;

		const selectedCells = [...this.state.selectedCells];
		const index = selectedCells.findIndex(
			obj =>
				obj.row_id == item.row_id &&
				obj.cellIndex == cellIndex
		);

		const root = data.find(x => x.row_id == item.root_row);

		if (!item.editable) {
			return;
		}

		const { project, user, task } = item;

		if (index == -1) {
			selectedCells.push({ row_id: item.row_id, cellIndex, gridRowIndex });
		} else {
			selectedCells.splice(index, 1);
		}

		let currentValue: any = null;

		const selectedWorkdays = _.max(_.map(_.uniq(_.map(selectedCells, 'cellIndex')), (v) => this._getWorkdaysInColumn(dates[v]))) ?? 0;
		
		if (selectedCells.length === 1) {
			const date = this._getDateString(dates[cellIndex]);

			currentValue = item.gridData?.[zoom]?.[date] || { hours: 0, percent: null };

			this.sliderDailyHours = user.max_hours_daily || 7.5;
		}

		const oldSlider = this.state.sliderOpen;
		const sliderOpen = selectedCells.length != 0;
		const justOpened = oldSlider != sliderOpen && sliderOpen;

		let manualValue = sliderOpen
			? justOpened && currentValue
				? (viewMode === 'percent' ? Number(currentValue.percent).toFixed(2) : Number(currentValue.hours).toFixed(2))
				: this.state.manualValue
			: null;

		if (manualValue === 0)
			manualValue = "";

		if (justOpened) {
			this._manualValueChanged = false;
			viewMode !== 'percent'
				? (this.sliderMode = "hours")
				: (this.sliderMode = "relative");
		}

		this.setState({ selectedCells, sliderOpen, manualValue, canAddPercentage: true, manualValueMode: justOpened ? (this.sliderMode) : this.state.manualValueMode, selectedWorkdays }, () => {
			if (justOpened && this.container && this.grid.current) {
				//@ts-ignore
				const parent = this.grid.current._scrollingContainer;
				const target = event.target.offsetParent;
				const top = target.offsetTop;
				const bottom = top + target.offsetHeight;

				const visibleTop = parent.scrollTop;
				const visibleBottom = visibleTop + parent.clientHeight - 250; // 250 is aprrox height of slider

				const willRemainVisible = bottom < visibleBottom;
				// const scrollBy = parent.scrollTop + visibleBottom - bottom;

				if (!willRemainVisible) {
					parent.scrollTo({ top: parent.scrollTop + bottom - visibleBottom, behavior: 'smooth' });
				}
			}
		});
	};

	_toggleRowExpand = row => {
		const { setExpandedRows } = this.props;
		const expandedRows = { ...this.props.expandedRows };

		const row_id = row.row_id;

		if (expandedRows[row_id]) {
			delete expandedRows[row_id];
		} else {
			expandedRows[row_id] = true;
		}

		setExpandedRows(expandedRows);
	};

	_activeResize: any = null;

	_startResizeColumn = (e, col, originalSize) => {
		this._activeResize = {
			col,
			originalSize,
			xStart: e.clientX,
		}

		this.setState({ resizingColumn: true });

		document.body.addEventListener("mousemove", this._resizeColumnMove);
		document.body.addEventListener("mouseup", this._endResizeColumn);
	}

	_resizeColumnMove = _.throttle((e) => {
		window.requestAnimationFrame(() => {
			// const 
			if (e.buttons == 0) {
				this._endResizeColumn(e);
				return;
			}

			const xNew = Math.max(10, this._activeResize.originalSize + e.clientX - this._activeResize.xStart);

			this._updateColumnSizes({
				[this._activeResize.col.name]: xNew
			}, true);
		});
	}, 16);

	_endResizeColumn = (e) => {
		const xNew = Math.max(10, this._activeResize.originalSize + e.clientX - this._activeResize.xStart);

		this._updateColumnSizes({
			[this._activeResize.col.name]: xNew
		}, true);

		document.body.removeEventListener("mousemove", this._resizeColumnMove);
		document.body.removeEventListener("mouseup", this._endResizeColumn);

		this.setState({ resizingColumn: false });
	}

	_updateColumnSizes = (updates = {}, fromColumnResize = false) => {
		const columns: ColumnSizes = {
			...this.state.columns,
			Columns: {
				...this.state.columns.Columns,
				...updates
			},
		};

		let width = 0;
		let normalWidth = 0;
		const columnSizes = {};

		this._getTableColumns().map(col => {
			columnSizes[col.name] = Math.max(10, columns.Columns[col.name] || col.width);
			width += columns.Columns[col.name];
			normalWidth += col.width;
		});

		const containerWidth = this.container.current?.offsetWidth ?? 500;
		const previousContainerWidth = columns.ContainerWidth ?? containerWidth;

		const currentScale = containerWidth / previousContainerWidth;

		if (!fromColumnResize) {
			_.each(columnSizes, (v, i) => {
				columnSizes[i] = Math.max(10, v * currentScale);
			});
		}

		columns.Columns = columnSizes;
		columns.ContainerWidth = containerWidth;

		localStorage.resourcing_grid_columns = JSON.stringify(columns);

		this.setState({ columns }, () => {
			this._updateFooter(true);
		});
	}

	allColumns: GridViewColumn[] = [];

	// LEFT TABLE
	_renderLeftTable = (onScroll, scrollTop) => {
		const { renderLeftTable, totalRows } = this.props;
		const { data, scrollBarSize, scrollbar } = this.state;
		let width = 0;

		let columnsCount = 0;
		const columnsWidth: number[] = [];
		const columnsInOrder: string[] = [];

		this.allColumns = this._getTableColumns();

		const columns = this.allColumns.map(col => {
			const colWidth = this.state.columns.Columns[col.name] || col.width;

			columnsCount++;
			columnsWidth.push(colWidth);
			columnsInOrder.push(col.name);
			width += colWidth;
			return (
				<Column
					headerClassName={cn("table-header-cell", col.resize && "table-header-resize")}
					cellRenderer={this.renderLeftTableCell}
					label={col.resize ? <div className="headerAndMarker">
						<span title={col.label ?? col.name}>{col.label ?? col.name}</span>
						<div className="dragmarker" onMouseDown={(e) => this._startResizeColumn(e, col, colWidth)}></div>
					</div> : col.label ?? col.name}
					dataKey={col.name}
					width={colWidth}
					minWidth={colWidth}
				/>
			);
		});

		if (!renderLeftTable) return null;

		const totalRowsCount = totalRows?.length ?? 0;
		const footerHeight = FOOTER_HEIGHT * totalRowsCount;

		return (
			<AutoSizer disableWidth>
				{({ height }) => (
					<><VirtualizedTable
						className="no-outline grid-left"
						headerRowRenderer={this._tableHeaderRowRenderer}
						gridClassName="no-outline no-scrollbar"
						style={{ borderRight: "1px solid #ebebeb" }}
						onScroll={e => {
							if (!this._scrollingGrid) {
								this._scrollingTable = true;
								onScroll(e);
							}
							this._scrollingGrid = false;
						}}
						scrollTop={scrollTop}
						rowClassName={({ index }) => cn(
							"grid-row", "no-outline", "border-bottom",
							data[index] && `grid-level-${data[index].level}`,
							data[index] && data[index].hasSubItems && "expandable-list-row",
							data[index] && data[index].task?.editable && "dialog-editable")}
						onRowMouseOver={this._setHover}
						onRowMouseOut={this._endHover}
						onRowClick={this._handleTableRowClick}
						onRowDoubleClick={this._handleTableRowDoubeClick}
						headerHeight={HEADER_HEIGHT}
						rowHeight={ROW_HEIGHT}
						rowCount={data.length}
						rowGetter={({ index }) => data[index]}
						width={width}
						height={height - (scrollbar.horizontal ? scrollBarSize : 0) - footerHeight}
					>
						{columns}
					</VirtualizedTable>
						<div className="grid-left grid-footer-left" style={{ width }}>
							<div className="grid-footer-scrollbar-paddding" style={{ height: (scrollbar.horizontal ? scrollBarSize : 0) }}></div>
							<VirtualizedGrid
								className="grid-footer-left-columns"
								// scrollLeft={scrollLeft}
								ref={this.footerLeftGrid}
								cellRenderer={this._footerLeftCellRenderer}
								columnCount={columnsCount}
								columnWidth={(index) => columnsWidth[index.index]}
								overscanColumnCount={10}
								height={footerHeight}
								rowCount={totalRowsCount}
								rowHeight={FOOTER_HEIGHT}
								width={width}
							/>
						</div>
					</>
				)}
			</AutoSizer>
		);
	};

	cellTypes = {
		default: GridLeftCell,
		user: GridLeftUserCell,
		task: GridLeftTaskCell,
		project: GridLeftProjectCell,
	}

	renderLeftTableCell = ({ columnIndex, rowIndex }) => {
		const { data } = this.state;

		const item = data[rowIndex];
		const finalColumns = this._getTableColumns(item.isSub);
		const column = finalColumns[columnIndex];

		const isLast = finalColumns.length - 1 === columnIndex;

		const { display_type } = item;

		const Cell = columnIndex === 0 && this.cellTypes[display_type];

		if (Cell)
			//@ts-ignore
			return <Cell column={column} item={item} toggleRowExpand={this._toggleRowExpand} />

		return this.renderLeftTableSubCell(column, item, rowIndex === this.state.hoverIndex, isLast);
	};

	sourcedFromTask = {
		progress: true,
	};

	renderLeftTableSubCell = (column: GridViewColumn, row: GridRow, hovering: boolean, isLast: boolean) => {
		const { functions: { getWorkhourTimers }, userObject: { usersId } } = this.context;

		let value = row[column.name];

		if (column.name === 'pipeline_name' && row.display_type === 'project') {
			if (row.project?.status === 1) {
				value = this.tr('Won Deals');
			} else if (row.project?.status === 5) {
				value = this.tr('Internal');
			} else {
				value = row.project?.pipeline_name;
			}
		} else if (column.name === 'priority' && row.type === 'task') {
			const prior = row.task && this.priorities[row.task.priorities_id];

			if (prior) {
				const Icon = prior.icon;

				value = <Icon title={prior.name} />
			}
		} else if (column.name === 'progress' && row.type === 'task') {
			value = row.task[column.name]
		} else if (column.name === 'projects_allocated' && row.display_type === 'project') {
			value = row.project?.projects_allocated_period;
		} else if (column.name === 'projects_allocated_all_time' && row.display_type === 'project') {
			value = row.project?.projects_allocated;
		} else if (row.type === 'task' && column.name === 'skill') {
			value = row.task.skill;
		}

		const workhours = getWorkhourTimers();

		const workhourTimerIndex = workhours.findIndex(x =>
			(row.type === "task" && x.wh_projects_resource == row.task?.task_id) ||
			(row.type === "project" && row.project?.projects_id == x.projects_id && !x.wh_projects_resource)
		);
		const workhourTimer = workhours[workhourTimerIndex];

		const trackingBtn = Number(usersId) === row.user?.id && isLast && row.type === 'task' && <TimeTrackingButton index={workhourTimerIndex} item={workhourTimer} data={{
			customers_id: row.project?.customers_id,
			projects_id: row.project?.projects_id,
			wh_projects_resource: row.task?.task_id,
		}} />

		const hover = hovering && isLast && this.props.renderHover ? this.props.renderHover(row, undefined) : '';

		if (column.format === 'hours')
			value = formatHours(value);
		else if (column.format === 'percent')
			value = formatPercent(value);
		else
			value = (value || "-");

		return (<div className="grid-table-cell">
			{hover}
			<div data-testid="assigned_hours_cell" className="grid-table-subcell">
				{!hover && value}
			</div>
			{(workhourTimer || hover) && <div className="grid-table-subcell">{trackingBtn}</div>}
		</div>);
	};

	_setHover = ({ index: hoverIndex }) => {
		this._endHoverTimer && clearTimeout(this._endHoverTimer);
		this.setState({ hoverIndex });
	};

	_endHover = () => {
		this._endHoverTimer = setTimeout(() => {
			this.setState({ hoverIndex: null });
			this.props.closeHover?.();
		}, 1000);
	};

	_handleTableRowDoubeClick = ({ index }) => {
		const { data } = this.state;
		const item = data[index];

		this._editTask(item);
	};

	_editTask = (row: GridRow) => {
		if (!row.task) {
			return;
		}
		this.context.functions.addResource(row.task, {
			onProjectRangeExtended: this.props.onProjectRangeExtended,
		});
	};

	_handleTableRowClick = ({ index }) => {
		const { data } = this.state;
		const item = data[index];
		if (item.hasSubItems) this._toggleRowExpand(item);
	};

	_tableHeaderRowRenderer = ({ className, columns, style }) => {
		return (
			<div className={className + " border-bottom"} style={style}>
				{columns}
			</div>
		);
	};

	_getTableColumns = (sub = false) => {
		const { tableColumns } = this.props;

		return tableColumns;
	};

	_getHoursInColumn = (user: Employee, date: Date) => {
		const { getWorkdayLength } = this.props;

		const days = this._getDaysInColumn(date);

		let totalHours = 0;

		for (const day of days) {
			const dayHours = getWorkdayLength(user.id, day);
			totalHours += dayHours;
		}

		return totalHours;
	}

	// HELPERS
	_getHoursFromPercent = (user: Employee, percent: number, date: Date, index: number): GridViewDataItem => {
		if (!this._manualValueChanged) {
			return this._getCellValue(date, index);
		}

		const totalHours = this._getHoursInColumn(user, date);
		const hours = Number((totalHours * (Number(percent) / 100)).toFixed(2));

		return {
			hours,
			percent: totalHours > 0 ? Math.round((hours / totalHours) * 100) : 0,
		}
	};

	_getPercentFromHours = (user: Employee, hours: number, date: Date, index: number): GridViewDataItem => {
		if (!this._manualValueChanged) {
			return this._getCellValue(date, index);
		}

		const totalHours = this._getHoursInColumn(user, date);
		const percent = totalHours !== 0 ? Number((hours / totalHours * 100).toFixed(2)) : 110;

		return {
			hours: Number(Number(hours).toFixed(2)),
			percent,
		}
	}

	_getDates = (zoom = this.props.zoom) => {
		let date = new Date();
		let {
			range: { start, end }
		} = this.props;

		const dates: Date[] = [];

		if (typeof start === "string")
			start = new Date(start);
		if (typeof end === "string")
			end = new Date(end);

		switch (zoom) {
			case "daily": {
				const diffStart = differenceInDays(start, date);
				const diffEnd = differenceInDays(end, date);
				break;
			}
			case "weekly": {
				date = endOfWeek(date, {
					weekStartsOn: this.context.calendar.startOfWeek
				});
				start = endOfWeek(start, {
					weekStartsOn: this.context.calendar.startOfWeek
				});
				end = endOfWeek(end, {
					weekStartsOn: this.context.calendar.startOfWeek
				});
				break;
			}
			case "monthly": {
				date = setDate(date, 1);
				start = setDate(start, 1);
				end = setDate(end, 1);
				break;
			}
			default:
				break;
		}

		dates.push(start);

		let incDate = start;

		while (incDate < end) {
			switch (zoom) {
				case "daily":
					incDate = addDays(incDate, 1);
					break;
				case "weekly":
					incDate = addWeeks(incDate, 1);
					break;
				case "monthly":
					incDate = addMonths(incDate, 1);
					break;
				default:
					incDate = addDays(incDate, 1);
					break;
			}

			if (incDate <= end) dates.push(incDate);
		}

		return dates;
	};

	_isHoliday = date => {
		const { holidays } = this.props;
		const isHoliday = !!holidays[format(date, "YYYY-MM-DD")];
		return isHoliday;
	};

	_isWeekend = date => {
		const day = date.getDay();
		return day == 0 || day == 6;
	};

	_isWorkday = date => {
		return !this._isWeekend(date) && !this._isHoliday(date);
	};

	/**
	 * 
	 * @param date 
	 * @deprecated Should use _getDaysInColumn
	 * @returns 
	 */
	_getWorkdaysInColumn = (date: Date) => {
		const { zoom } = this.props;
		let workdays = 0;
		switch (zoom) {
			case "daily":
				workdays++;
				break;
			case "weekly": {
				for (let i = 1; i <= 7; i++) {
					const checkDate = setDay(date, i);
					if (this._isWorkday(checkDate)) workdays++;
				}
				break;
			}
			case "monthly":
				const daysInMonth = getDaysInMonth(date);
				for (let i = 0; i < daysInMonth; i++) {
					const checkDate = setDate(date, i + 1);
					if (this._isWorkday(checkDate)) workdays++;
				}

				break;
			default:
				break;
		}
		return workdays;
	}

	/**
	 * Gets days displayed in column
	 * @param date Day
	 * @returns 
	 */
	_getDaysInColumn = (date: Date, exclude_holidays = true) => {
		const { zoom } = this.props;
		const days: Date[] = [];
		
		switch (zoom) {
			case "daily":
				days.push(date);
				break;
			case "weekly": {
				for (let i = 1; i <= 7; i++) {
					const checkDate = setDay(date, i);

					if (exclude_holidays && this._isHoliday(date)) {
						continue;
					}

					days.push(checkDate);
				}
				break;
			}
			case "monthly":
				const daysInMonth = getDaysInMonth(date);
				for (let i = 0; i < daysInMonth; i++) {
					const checkDate = setDate(date, i + 1);

					if (exclude_holidays && this._isHoliday(date)) {
						continue;
					}

					days.push(checkDate);				}
				break;
			default:
				break;
		}

		return days;
	}

	getCurrentDateOffset = (zoom, dates) => {
		const today = new Date();
		let centerIndex;
		switch (zoom) {
			case "daily":
				centerIndex = dates.findIndex(date => isSameDay(today, date));
				break;
			case "weekly":
				centerIndex = dates.findIndex(date =>
					isSameWeek(today, date, {
						weekStartsOn: this.context.calendar.startOfWeek
					})
				);
				break;
			case "monthly":
				centerIndex = dates.findIndex(date => isSameMonth(today, date));
				break;
			default:
				break;
		}
		const offset = centerIndex * GRID_CELL_WIDTH + 1;
		return offset;
	};

	// SLIDER

	_renderSlider = () => {
		const { sliderOpen, containerWidth, manualValue, manualValueMode, selectedWorkdays, smallMode, canAddPercentage } = this.state;
		return (
			<ResourcingGridSlider
				setSliderMode={this._setSliderMode}
				getColor={this._getCellColor}
				closeSlider={this.closeSlider}
				cancelSlider={this._cancelEditing}
				sliderValue={manualValue}
				sliderValueMode={manualValueMode}
				sliderMode={this.sliderMode}
				setValue={this._setManualValue}
				width={containerWidth}
				sliderOpen={sliderOpen}
				smallMode={smallMode}
				setHours={this._setHours}
				dailyHours={this.sliderDailyHours}
				selectedWorkdays={selectedWorkdays}
				selectedCells={this.state.selectedCells}
				tr={this.tr}
				canAddPercentage={canAddPercentage}
				onClickAway={(e: MouseEvent) => {
					if (e.target && e.target instanceof Element && (e.target.classList.contains("grid-cell-container") || e.target.classList.contains("grid-cell"))) {
						return;
					}
					
					this._cancelEditing();
				}}
			/>
		);
	};

	_cancelEditing = () => {
		this.setState({ sliderOpen: false, manualValue: null, selectedCells: [] });
	};

	closeSlider = (manualValue = this.state.manualValue, hours = false) => {
		const { data, selectedCells, dates, manualValueMode, rowsByKey } = this.state;
		const { zoom } = this.props;


		if (manualValue == "") manualValue = 0;

		const update: any[] = [];
		const newData = cloneDeep(data);
		const confirmations: any[] = [];

		selectedCells.forEach(selected => {
			const { row_id, cellIndex, gridRowIndex } = selected;

			const taskRow = rowsByKey[row_id];
			const { user } = taskRow;

			if (!user)
				return;

			const date = dates[cellIndex];
			const gridKey = this._getDateString(date);

			const newItem: GridViewDataItem = manualValueMode !== "hours" ? this._getHoursFromPercent(
				user,
				manualValue,
				date,
				gridRowIndex
			) : this._getPercentFromHours(
				user,
				manualValue,
				date,
				gridRowIndex
			);

			const gridData: GridViewData = newData[gridRowIndex].gridData ?? {
				daily: {},
				monthly: {},
				weekly: {},
			};

			gridData[zoom][gridKey] = newItem;
			newData[gridRowIndex].gridData = gridData;

			if (taskRow.type === 'task') {
				update.push({
					type: taskRow.task.task_type,
					projects_id: taskRow.project.projects_id,
					task_id: taskRow.task.task_id,
					user: user?.id,
					value: manualValue,
					hours,
					date: gridKey,
					mode: zoom,
				});
			} else if (taskRow.type === 'task_new') {
				update.push({
					type: taskRow.task_type,
					projects_id: taskRow.project.projects_id,
					task_id: 0,
					user: user?.id,
					value: manualValue,
					hours,
					date: gridKey,
					mode: zoom,
				});
			}

			// Check where timespan is potentially modified
			if (zoom && zoom !== "daily") {
				const gd = taskRow.gridData?.[zoom]?.[gridKey];

				if (gd && !gd.fully_resourced) {
					confirmations.push({
						gridKey,
						mode: zoom,
						projects_id: taskRow.project?.projects_id,
						task_id: taskRow.task?.task_id,
						user: user?.id,
					});
				}
			}
		});

		if (confirmations.length > 0) {
			this._showResourceUpdateConfirmation(newData, update);
		} else {
			this.props.save(update);
			this.setState({
				sliderOpen: false,
				manualValue: null,
				selectedCells: [],
				data: newData
			});
		}
	};

	_setHours = value => {
		this.closeSlider(value, true);
	};

	_setManualValue = (manualValue, manualValueMode) => {
		manualValue = Number(manualValue);

		if (isNaN(manualValue))
			return;

		if (manualValue < 0)
			manualValue = 0;

		this._manualValueChanged = true;
		this.setState({ manualValue, manualValueMode });
	};

	_setSliderMode = mode => {
		this.sliderMode = mode;
	};

	_renderGridContainer = () => {
		return (
			<ScrollSync>
				{({ onScroll, scrollLeft, scrollTop }) => {
					if (!this._initialized) {
						// scrollLeft = initialScrollLeft;
						this._initialized = true;
					} else if (this.offset) {
						scrollLeft = this.offset;
						this.offset = null;
					}

					return (
						<div className="hour-grid-container">
							{this._renderLeftTable(onScroll, scrollTop)}
							{this._renderGrid(onScroll, scrollLeft, scrollTop)}
						</div>
					);
				}}
			</ScrollSync>
		);
	};

	_showResourceUpdateConfirmation = (newData, update) => {
		const popUpComponent = (
			<ConfirmationPopUp
				header={this.tr(
					"Some of resources are shorten than selection. Update dates to match grid selection?"
				)}
				desc={null}
				leftButtons={[
					{
						label: this.tr("Cancel"),
						style: { backgroundColor: "transparent", color: "#34495e" },
						onClick: this._closePopup
					}
				]}
				rightButtons={[
					{
						label: this.tr("Update Dates"),
						style: { backgroundColor: "#2d9ff7", color: "white" },
						onClick: () => {
							this._closePopup();
							this.props.save(update, { update: "all" });
							this.setState({
								sliderOpen: false,
								manualValue: null,
								selectedCells: [],
								data: newData
							});
						}
					},
					{
						label: this.tr("Keep Dates, update hours only"),
						style: { backgroundColor: "#2d9ff7", color: "white" },
						onClick: () => {
							this._closePopup();
							this.props.save(update, { update: "hours_only" });
							this.setState({
								sliderOpen: false,
								manualValue: null,
								selectedCells: [],
								data: newData
							});
						}
					}
				]}
			/>
		);
		this.setState({
			popUpComponent
		});
	};

	_closePopup = () => {
		this.setState({ popUpComponent: null });
	};

	_renderPopup = () => {
		if (!this.state.popUpComponent) return null;
		return (
			<div
				onClick={e =>
					//@ts-ignore
					e.target.className == "popup-container" && this._closePopup()
				}
				className="popup-container"
			>
				{this.state.popUpComponent}
			</div>
		);
	};

	render() {
		const { grouping } = this.props;
		const { resizingColumn } = this.state;
		return (
			<div
				id="hour-grid"
				className={cn(resizingColumn && "grid-column-resize", `hour-grid-${grouping}`)}
				ref={this.container}
			>
				{this._renderGridContainer()}
				{this._renderSlider()}
				{this._renderPopup()}
			</div>
		);
	}
}
