import React from "react";

import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from "../../../SettingsContext";
import OverviewBlock from "./OverviewBlock";
import colors from "../../../colors";
import DataHandler from "../../../general/DataHandler";

import "./TasksOverview.css";
import OverviewBlockCell from "./OverviewBlockCell";
import moment from "moment";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";
import { formatInputNumber } from "../../../helpers";

const MODE_KEY = "my_day_tasks_overview_mode";
export default class TasksOverview extends TaimerComponent {
  static contextType = SettingsContext;
  constructor(props, context) {
    super(props, context, "dashboard/my_day/components/TasksOverview");

    this.modes = [
      {
        key: "today",
        label: this.tr("Today"),
        action: () => this._setMode("today"),
      },
      {
        key: "this_week",
        label: this.tr("This week"),
        action: () => this._setMode("this_week"),
      },
      {
        key: "this_month",
        label: this.tr("This month"),
        action: () => this._setMode("this_month"),
      },
    ];

    const mode = this._getModeFromLS(MODE_KEY, "this_month");

    this.state = {
      mode,
      data: initialData,
      loading: true,
    };
  }

  _getItems = () => {
    const fields = [
      {
        header: this.tr("Tracked / Resourced Hours"),
        dual: true,
        keys: ["hours_done", "hours_resourced"],
        sliderKey: "tracked_resourced",
        formatValue: this._formatHours,
        right: true,
      },
      {
        header: this.tr("Progress / Overdue"),
        dual: true,
        color: colors.carnation_pink,
        sliderKey: "progress_overdue",
        keys: ["progress_count", "overdue_count"],
        right: true,
      },
      {
        header: this.tr("Remaining Hours"),
        key: "hours_free",
        formatValue: this._formatHours,
        leftComponent: (data) => {
          const tracked = Number((data.tracked || {}).value || "0");
          const resourced = Number((data.resourced || {}).value || "0");
          const diff =
            resourced != 0 ? Math.round((tracked / resourced) * 100) : 0;
          return (
            <div className="remaining-progress-container">
              <div
                style={{ width: `${diff}%` }}
                className="remaining-progress-fill"
              />
            </div>
          );
        },
        right: true,
      },
      {
        header: this.tr("Completed"),
        key: "done_count",
        sliderKey: "done",
        color: colors.greenish_cyan,
        right: true,
      },
      {
        header: this.tr("Availability / Capacity"),
        dual: true,
        colorIndex: 0,
        keys: ["availability", "hours_possible_excluding_vacations", ],
        getValue: (data, key) => {
          if (key == "availability") {
            return (
              Number(data.hours_possible_excluding_vacations || 0) -
              Number(data.hours_resourced || 0)
            );
          }
          return data[key];
        },
        formatValue: this._formatHours,
        color: colors.orangey_yellow,
        right: true,
      },
      {
        header: this.tr("Utilization"),
        key: "utilization",
        formatValue: this._formatPercentage,
        getValue: (data) => {
          const possible = Number(data.hours_possible_excluding_vacations || 0);
          const resourced = Number(data.hours_resourced || 0);
          if (possible == 0) {
            return 0;
          }
          return (resourced / possible) * 100;
        },
        right: true,
      },
    ];
    return fields.filter((f) => f.right);
  };

  componentDidMount() {
    super.componentDidMount();
    this._getData(true);
  }

  shouldComponentUpdate = (_, newState) => {
    return newState != this.state;
  };

  _getData = (getGraphData = false) => {
    this.setState({ loading: true }, () => {
      const { startDate, endDate } = this._getDatesForMode(this.state.mode);
      DataHandler.get({
        url: "resourcing/overview",
        startdate: startDate,
        enddate: endDate,
        get_graph_data: getGraphData ? 1 : 0,
      }).done((data) => {
        this.setState({ data: { ...this.state.data, ...data }, loading: false });
      });
    });
  };

  _getModeFromLS = (key, defaultValue) => {
    let mode = defaultValue;
    try {
      mode = localStorage.getItem(key) || defaultValue;
    } catch (e) {}
    return mode;
  };

  _getDatesForMode = (mode) => {
    let startDate;
    let endDate;
    const today = new Date();
    switch (mode) {
      case "this_month":
        {
          startDate = moment(startOfMonth(today)).format("YYYY-MM-DD");
          endDate = moment(endOfMonth(today)).format("YYYY-MM-DD");
        }
        break;
      case "this_week":
        startDate = moment(
          startOfWeek(today, {
            weekStartsOn: this.context.calendar.startOfWeek,
          })
        ).format("YYYY-MM-DD");
        endDate = moment(
          endOfWeek(today, {
            weekStartsOn: this.context.calendar.startOfWeek,
          })
        ).format("YYYY-MM-DD");
        break;
      case "today":
        startDate = moment(today).format("YYYY-MM-DD");
        endDate = moment(today).format("YYYY-MM-DD");
        break;
    }
    return { startDate, endDate };
  };

  _setMode = (mode) => {
    this.setState({ mode }, () => {
      this._getData();
    });
    try {
      mode = localStorage.setItem(MODE_KEY, mode);
    } catch (e) {}
  };

  _formatNumber = (number) => {
    return Math.round(Number(number) * 100) / 100;
  };

  _formatHours = (value) => {
    if (Number(value) === 0) return value + " h";

    return formatInputNumber(value, "hours") + " h";
  };

  _formatPercentage = (value) => {
    if (Number(value) === 0 || Number(value) === 100) return value + " %";

    return formatInputNumber(value) + " %";
  };

  _getSliderColumns = () => {
    return [
      "task_customer",
      "task_project",
      "task_status",
      "description",
      "hours_done",
      "hours_resourced",
    ];
  };

  _getCellValue = (item) => {
    const { data, mode } = this.state;

    if (item.dual) {
      const string = [];
      (item.keys || []).forEach((key, i) => {
        const value = item.getValue ? item.getValue(data, key) : data[key];
        string.push(
          <span key={i} style={{ color: (item.colorIndex != null ?  i == item.colorIndex : i > 0) && item.color && item.color }}>
            {item.formatValue ? item.formatValue(value) : value}
          </span>
        );
        if (i == 0) string.push(" / ");
      });
      return string;
    }

    const value = item.getValue ? item.getValue(data) : data[item.key];

    return (
      <span style={{ color: item.hasOwnProperty("color") && item.color }}>
        {item.formatValue ? item.formatValue(value) : value}
      </span>
    );
  };

  _renderCells = (items) => {
    const { data, mode } = this.state;
    const selectedMode = this.modes.find((m) => m.key == mode) || {};
    return items.map((item, i) => {
      const value = this._getCellValue(item);
      return (
        <OverviewBlockCell
          key={i}
          className={i % 2 == 0 ? "left" : "right"}
          header={item.header}
          sliderHeader={item.header + " - " + selectedMode.label}
          sliderData={data[item.sliderKey] && data[item.sliderKey]}
          sliderColumns={this._getSliderColumns()}
          value={value}
          leftExtraComponent={item.leftComponent && item.leftComponent(data)}
        />
      );
    });
  };

  _renderContent = () => {
    return (
      <div className="details-container">
          {this._renderCells(this._getItems())}
      </div>
    );
  };

  _getGraphData = () => {
    const { data } = this.state;

    let datasets = [
      {
        label: this.tr("Resourced"),
        values: data.graph_data.resourced,
        formatTooltipLabel: (label) => this._formatHours(label),
        rights: true,
      },
    ];

    datasets = datasets.filter((ds) => ds.rights);

    return {
      months: data.graph_data.months,
      color: "#fec7b6",
      datasets: datasets,
    };
  };

  render() {
    const { mode, loading } = this.state;
    return (
      <OverviewBlock
        darkTitle={true}
        className="my-day-tasks-overview"
        dropdownItems={this.modes}
        selected={mode}
        loading={loading}
        graphData={this._getGraphData()}
        title={this.tr("Tasks Overview")}
        content={this._renderContent()}
      />
    );
  }
}

const getMonths = () => {
  let months = [];
  const now = moment();
  for (let i = 0; i < 6; i++) {
    months.push(moment(now).add(i, "month").format("MM-YYYY"));
  }
  return months;
};

const initialData = {
  graph_data: {
    resourced: [0, 0, 0, 0, 0, 0],
    months: getMonths(),
  },
  hours_done: 0,
  hours_resourced: 0,
  progress_overdue: [],
  progress_count: 0,
  tracked_resourced: [],
  overdue_count: 0,
  done: [],
  done_count: 0,
  hours_possible_excluding_vacations: 0,
  hours_free: 0,
};
