import React from 'react';
import TaimerComponent from "../../TaimerComponent"

/* material ui */
import { Button } from '@mui/material';

import Taimer from "../../Taimer"
import { SettingsContext } from '../../SettingsContext';

import { ReactComponent as OverlayImage } from './ProductList.svg';

class CPQListOverlay extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'list/overlays/CPQListOverlay');
    }

    render () {
        const { functions: { checkPrivilege }} = this.context;
        return (
            <div className="list-overlay">
                <OverlayImage style={{width: "auto", height: '260px'}} />
                <div className="text">
                    {this.tr("Add your first CPQ") }
                </div>
                <Button
                    color="primary"
                    className={!checkPrivilege("products", "write") && "gray"}
                    disabled={!checkPrivilege("products", "write")}
                    onClick={() => this.props.addCPQ()}
                >
                {this.tr("ADD CPQ") }
                </Button>
            </div>
        );
    }

}

export default CPQListOverlay;