import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import AttachIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import TaimerComponent from '../TaimerComponent';
import { SettingsContext } from './../SettingsContext';
import { IconButton, InputBase, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import 'emoji-mart/css/emoji-mart.css'
import EmojiPickerButton from './EmojiPickerButton';
import './SendMessageBar.css'
import { withSnackbar } from 'notistack';


const styles = theme => ({
    barButton: {
        //padding: 4
    },
    inputRoot: {
        borderRadius: 0,
        border: 0,
        borderTop: '1px solid #dde3e8',
        backgroundColor: '#f9f9f9 !important'
    },
    input: {
        padding: '16px',
        boxSizing: 'border-box',
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    btnSend: {
        color: '#2d9ff7'
    }
});

class SendMessage extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
		super(props, context, "team-chat/SendMessageBar");

		this.state = {
            message: '',
            attachments: [],
        };

        this.refFileInput = React.createRef();
        this.refField = React.createRef();
    }

    addAttachment = () => {
        this.refFileInput.current.click();
    }

    addFiles = (files) => {
        const { enqueueSnackbar } = this.props;
        const { tr } = this;
        const { taimerAccount: { attachmentMaxSize } } = this.context;
        var attachments = [];

        var totalSize = 0;

        for (var i = 0; i < files.length; i++) {
            const file = files[i];
            attachments.push({
                index: this.index++,
                name: file.name,
                size: file.size,
                type: file.type,
                file
            });

            totalSize += file.size;
        }

        if (totalSize > attachmentMaxSize) {
            enqueueSnackbar(tr('Selected file is too large'), {
                variant: "error"
            });
            return;
        }

        this.setState({attachments});
    }

    delete = (row = false) => {
        if (row)
        {
            var attachments  = [...this.state.attachments];

            attachments.splice(attachments.indexOf(row), 1);

            this.setState({attachments});
        }
        else
        {
            this.setState({attachments: []});
        }
    }

    onChangeFileInput = (e) => {
        this.addFiles(e.target.files);
        this.refFileInput.current.value = '';
    }

    onPaste = (evt) => {
        if (evt.clipboardData) {
            if (evt.clipboardData.files.length) {
                evt.preventDefault();
                this.addFiles(evt.clipboardData.files);
            }
        }
    }

    onChange = (e) => {
        this.setState({message: e.target.value});
    }

    onKeyUp = (e) => {
        const { message } = this.state;

        if ((e.key == 'Enter') && (!e.shiftKey)) {
            if (message === '' && this.state.attachments.length === 0)
                return;

            this.send();            
        }
    }

    send = async () => {
        const { enqueueSnackbar } = this.props;
        const { tr } = this;
        const { message, attachments } = this.state;

        if (message === '' && attachments.length === 0)
            return;

        this.setState({message: '', attachments: []});
        try {
            const res = await this.props.onSendMessage(message, attachments);
            
            this.setState({message: ''});

            if (res === false)
            {
                enqueueSnackbar(tr("Failed to send message!"), {
                    variant: "error",
                });
                this.setState({message, attachments});
            }
        } catch (err) {
            enqueueSnackbar(tr("Failed to send message!"), {
                variant: "error",
            });
            this.setState({message, attachments});
        }
    }

    showEmoji = () => {
        this.setState({emojiPickerVisible: true});
    }

    addEmoji = (emoji) => {
        let { message } = this.state;

        message += ` ${emoji.colons} `;

        this.setState({message, emojiPickerVisible: false}, () => {
            this.refField.current.focus();
        });
    }

    focus = () => {
        this.refField.current.focus();
    }

    render() {
        const { classes, disabled } = this.props;
        const { message, attachments } = this.state;

        const endAdornment = (
            <InputAdornment position="end">
                <IconButton
                    disabled={disabled}
                    classes={{root: classes.barButton}}
                    onClick={this.addAttachment}
                    size="large">
                    <AttachIcon />
                </IconButton>
                <EmojiPickerButton disabled={disabled} top onSelect={this.addEmoji} />
                <Divider className={classes.divider} />
                <IconButton
                    disabled={disabled}
                    classes={{root: classes.barButton}}
                    onClick={this.send}
                    size="large">
                    <SendIcon classes={{root: classes.btnSend}} />
                </IconButton>
            </InputAdornment>);

        return (
            <div className="SendMessageBar">
                {attachments.length > 0 && <div className="AttachmentBar">
                    <div>{attachments[0].name}</div>
                    <IconButton onClick={this.delete} size="large"><CloseIcon /></IconButton>
                </div>}
                <InputBase
                    onPaste={this.onPaste}
                    disabled={disabled}
                    multiline={true}
                    inputRef={this.refField}
                    classes={{root: classes.inputRoot, input: classes.input}}
                    name="message" 
                    value={message} 
                    onChange={this.onChange} 
                    fullWidth
                    onKeyUp={this.onKeyUp}
                    placeholder={this.tr("Type a message")}
                    endAdornment= {endAdornment}
                    autoComplete="off" />
                <input style={{display: 'none'}} ref={this.refFileInput} type="file" onChange={this.onChangeFileInput} />
            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(SendMessage));