import { components } from 'react-select';

// Material UI
import { DrawerProps, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { InfoOutlined } from '@mui/icons-material';

// Local components
import TaimerComponent from '../../TaimerComponent';
import AutoCompleteCell from "./AutoCompleteCell";
import ListCell from "./../ListCell";

// Styles
import "./MultilineAutocompleteCell.css";

export interface MultilineAutocompleteCellProps extends DrawerProps {
    autoCompleteData?: any[];
    value?: string;
    editMode?: boolean;
    onEdit?: (data?: any) => void;
    listCellProps: any;
    topText?: string;
    subText?: string;
    name?: string;
    tooltip?: string;
    tooltipIcon?: any;
}

function Option(props) {
    const rows = props.options;
    let menuAutoWidth = props.menuWidth || props.selectProps.menuWidth || 'auto';
    if (rows && rows.length > 0 && !props.menuWidth && !props.selectProps.menuWidth) {
        const len = Math.max(...rows.map(x => String(x.label || "").length));
        menuAutoWidth = (len + 4) + "ch";
    }

    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            disabled={props.isDisabled}
            component="div"
            className={"multiline_option"}
            style={{
                fontWeight: props.isSelected ? 500 : 400,
                width: menuAutoWidth,
            }}
            {...props.innerProps}
            data-testid={props.data['data-testid'] || `multiline_autocompletecell_option_${props.data.label}`}
        >
            <div>
                <div className="toptext">{props.data.topText}</div>
                <div className="subtext light">{props.data.subText}</div>
            </div>

        </MenuItem>
    );
}

function SingleValue(props) {
    const value = props.selectProps?.value || {};
    const TooltipIcon = value.tooltipIcon;

    return (
        <components.SingleValue {...props}>
            <p className='toptext'>
                {value.tooltip &&
                    <Tooltip title={value.tooltip} classes={{ tooltip: 'darkblue-tooltip' }} placement="top" arrow>
                        {TooltipIcon ? 
                            <TooltipIcon className={"tooltip"} /> :
                            <InfoOutlined className={"tooltip"} />
                        }
                    </Tooltip>
                }
                {value.topText || ""}
            </p>
            <p title={value.subText || ""} className="subtext">{value.subText}</p>
        </components.SingleValue>
    );
}

class MultilineAutocompleteCell extends TaimerComponent<MultilineAutocompleteCellProps> {
    components: any;

    constructor(props, context) {
        super(props, context, 'list/cells/MultilineAutocompleteCell');
        this.components = {Option, SingleValue};
    }

    render() {
        const { editMode, autoCompleteData, onEdit, listCellProps, value } = this.props;
        let data = { topText: "", subText: "", tooltip: "", tooltipIcon: InfoOutlined};
        if (typeof value === 'object') {
            data = value;
        }
        else {
            data = autoCompleteData?.find(a => a.id == value);
        }

        const TooltipIcon = data.tooltipIcon;

        return (
            editMode ? 
            <AutoCompleteCell 
                listCellProps={{
                    editable: editMode,
                    inEditMode: editMode,
                    noInitFocus: true,
                    textAlign: "right",
                    onlyDisplay: !editMode,
                    ...listCellProps
                }} 
                autoCompleteData={autoCompleteData}
                value={value} 
                onEdit={onEdit}
                components={this.components}
                className="multiline_autocompletecell_edit"
                data-testid={this.props['data-testid'] || `multiline_autocompletecell_edit_${this.props.name}`}
            />
            :
            <ListCell 
                className="multiline_autocompletecell_view"
                data-testid={this.props['data-testid'] || `multiline_autocompletecell_view_${this.props.name}`}
                onlyDisplay={true}
                editable={false}
                {...listCellProps}
            >
                <div className="value_container">
                    <p className='toptext'>
                        {data.tooltip &&
                            <Tooltip title={data.tooltip} classes={{ tooltip: 'darkblue-tooltip' }} placement="top" arrow>
                                {TooltipIcon ? 
                                    <TooltipIcon className={"tooltip"} /> :
                                    <InfoOutlined className={"tooltip"} />
                                }
                            </Tooltip>
                        }
                        {data?.topText || ""}
                    </p>
                    <p title={data?.subText || ""} className='subtext'>{data?.subText || ""}</p>
                </div>
            </ListCell>
        );
    }

}

export default MultilineAutocompleteCell;