import React from "react";

import { SettingsContext } from "../../SettingsContext";
import TaimerComponent from "../../TaimerComponent";
import DataHandler from "../../general/DataHandler";
import BlockContainer from "./BlockContainer";

import _, { debounce, filter, isEqual } from 'lodash';
import cn from 'classnames';

import styles from './CustomViewBase.module.scss';
import { getElementOffset } from "../../helpers";

import loading from '../../dashboard/insights/img/loading.svg';
import InsightDropDown from "../../dashboard/insights/InsightDropDown";

import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'

import { ReactComponent as EditIcon } from '../img/edit.svg';


export interface ViewBlock {
    /**
     * Machine Name
     */
    name: string;
    /**
     * Title (user readable name)
     */
    title: string;
    row: number;
    column: number;
    weight?: number;

    /**
     * Always full row
     */
    fullRow?: boolean;

    canBeVisible?: boolean;

    defaultVisible?: boolean;
    defaultCollapse?: boolean;
    disableCollapse?: boolean;

    render: (blockProps: BlockProps, subitemsProps: SubitemsProps) => React.ReactNode;
}

export interface DragInformation {
    /**
     * Position X, relative to offsetParent
     */
    x: number;
    /**
    * Position Y, relative to offsetParent
    */
    y: number;
}

export interface DragStartInformation extends DragInformation {
    offsetX: number;
    offsetY: number;
    width: number;
    height: number;
}

export interface BlockProps {
    title: string;
    canCollapse: boolean;
    collapsed: boolean;
    viewBlock: ViewBlock;
    onCollapse: (viewBlock: ViewBlock, collapse: boolean) => void;
}

export interface SubitemsProps {
    subitems: LayoutConfigurationBlock['subitems'];
    onConfigure: (viewBlock: ViewBlock, config: Record<string, Partial<SubItemConfig>>) => void;
}

interface Props {
    /**
     * ClassName
     */
    className?: string;
    viewName: string;
    // settings: T;
    blocks: ViewBlock[];
    title?: string;

    onConfigChanged?: (layoutConfiguration: LayoutConfiguration) => void;
}

export interface DraggingState {
    block: string;
    x: number;
    y: number;
    offsetX: number;
    offsetY: number;
    width: number;
    height: number;
}

interface DropInformation {
    type: 'above' | 'below' | 'self' | 'insideLeft' | 'insideRight';
    block: LayoutBlock;
    targetBlock: LayoutBlock;
}

interface State {
    defaultConfiguration: LayoutConfiguration;
    currentConfiguration: LayoutConfiguration | null;
    currentLayout: Layout | null;
    draggedBlock: DraggingState | undefined;
}

export interface SubItemConfig {
    visible?: boolean;
}

interface LayoutConfigurationBlock {
    name: string;
    visible: boolean;
    collapsed: boolean;
    row: number;
    col: number;
    subitems: Record<string, SubItemConfig>;
}

interface LayoutConfiguration {
    version: number;
    blocks: Record<string, LayoutConfigurationBlock>;
}

interface Layout {
    width: number;
    layoutBlocks: LayoutBlock[];
}

interface GetViewSettingsResponse {
    viewName: string;
    config: LayoutConfiguration;
}

export interface LayoutBlock {
    id: number;
    block: string;
    visible: boolean;
    collapsed: boolean;
    gridRow: number;
    gridCol: number;
    gridSpan: number;
    x: number;
    y: number;
}

const NEW_ROW_PIXELS = 100;

export default class CustomViewBase extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    refContent = React.createRef<HTMLDivElement>();
    hasUnsavedChanges = false;

    constructor(props, context) {
        super(props, context, "customview/components/CustomViewBase");

        const defaultConfiguration = this.generateDefaultLayout(props.blocks);

        this.state = {
            defaultConfiguration,
            currentConfiguration: null,
            currentLayout: null,
            draggedBlock: undefined,
        };
    }

    componentDidMount = () => {
        super.componentDidMount();

        this.loadConfiguration();

        window.addEventListener('beforeunload', this.beforeUnload);
    }

    componentDidUpdate(prevProps: Props) {
        const { blocks } = this.props;
        const { currentConfiguration } = this.state;

        if (currentConfiguration && !isEqual(blocks, prevProps.blocks)) {
            this.applyConfig(currentConfiguration, false);
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('beforeunload', this.beforeUnload);   
    }

    beforeUnload = (e: BeforeUnloadEvent) => {
        if (this.hasUnsavedChanges) {
            e.preventDefault();
            e.returnValue = '1';
        }

        return e.returnValue;
    }

    /** Config **/

    /**
     * 
     */
    loadConfiguration = async () => {
        const { viewName, blocks } = this.props;
        const { defaultConfiguration } = this.state;

        try {
            const response = await DataHandler.get({ url: `views/${viewName}` }) as GetViewSettingsResponse;

            this.applyConfig({
                ...defaultConfiguration,
                ...response.config,
                blocks: this.updateBlocksConfig(defaultConfiguration.blocks, response.config.blocks),
            }, false);
        } catch (error) {
            // No layout saved, use default
            this.applyConfig(defaultConfiguration, false);
        }
    }

    // hasUnsavedChanges

    /**
     * 
     * @returns 
     */
    saveConfiguration = async () => {
        const { viewName } = this.props;
        const { currentConfiguration } = this.state;

        this.hasUnsavedChanges = true;
        this.saveConfigurationInternal();
    };

    /**
     * 
     * @returns 
     */
    saveConfigurationInternal = debounce(async () => {
        const { viewName } = this.props;
        const { currentConfiguration } = this.state;

        if (!currentConfiguration) {
            return;
        }

        const response = await DataHandler.post({ url: `views/${viewName}` }, {
            config: currentConfiguration,
        });

        this.hasUnsavedChanges = false;
    }, 2000);

    /** Layout */

    /**
     * Applies configuration to layout
     * @param newConfig 
     * @param save Should configuration be saved
     */
    applyConfig = (newConfig: LayoutConfiguration, save: boolean) => {
        const { blocks, onConfigChanged } = this.props;

        this.setState({
            currentConfiguration: newConfig,
            currentLayout: this.buildLayout(blocks, newConfig),
        }, () => {
            onConfigChanged?.(newConfig);
            setTimeout(() => window.dispatchEvent(new Event("customViewLayoutChanged")), 100)

            if (save) {
                this.saveConfiguration();
            }
        });
    }

    /**
     * 
     * @param blocks 
     * @returns 
     */
    generateDefaultLayout = (blocks: ViewBlock[]): LayoutConfiguration => {
        const config: LayoutConfiguration = {
            version: 1,
            blocks: {},
        }

        for (const block of blocks) {
            config.blocks[block.name] = {
                name: block.name,
                visible: block.defaultVisible ?? true,
                collapsed: block.defaultCollapse ?? false,
                row: block.row,
                col: block.column,
                subitems: {
                    // prevent array conversion in php
                    dummy: { visible: true, },
                },
            }
        }

        return config;
    }

    updateBlocksConfig = (defaults: LayoutConfiguration['blocks'], userConfig: LayoutConfiguration['blocks']): LayoutConfiguration['blocks'] => {
        const newConfig = _.merge(defaults, userConfig);
        const blockByRow = _.groupBy(newConfig, x => x.row);

        let y = 1;

        for (const row of _.values(blockByRow)) {
            let x = 1;

            const blocksInOrder = _.orderBy(row, x => x.col);

            for (const block of blocksInOrder) {

                block.row = y;
                block.col = x;

                x++;
            }

            y++;
        }

        return newConfig;
    }

    /**
     * 
     * @param blocks 
     * @returns 
     */
    buildLayout = (blocks: ViewBlock[], config: LayoutConfiguration): Layout => {
        const blocksByKey = _.keyBy(blocks, x => x.name);
        const blockByRow = _.groupBy(config.blocks, x => x.row);

        const rowWidth = _.chain(blockByRow).map(x => x.length).uniq().reduce((x, y) => x * y).value();

        let gridRow = 1;
        let y = 1;

        const layoutBlocks: LayoutBlock[] = [];

        for (const row of _.values(blockByRow)) {
            let gridCol = 1;
            let x = 1;

            const visibleChildren = filter(row, col => col.visible && blocksByKey[col.name] && blocksByKey[col.name].canBeVisible !== false);

            const blocksPerItem = Math.floor(rowWidth / visibleChildren.length);

            const blocksInOrder = _.orderBy(visibleChildren, x => x.col);

            for (const col of blocksInOrder) {
                const block = blocksByKey[col.name];

                if (!block) {
                    console.error('missing block', col.name);
                    continue;
                }

                layoutBlocks.push({
                    id: layoutBlocks.length,
                    block: col.name,
                    visible: col.visible,
                    collapsed: col.collapsed,
                    gridRow,
                    gridCol,
                    gridSpan: blocksPerItem,
                    x,
                    y,
                });

                gridCol += blocksPerItem;
                x++;
            }

            gridRow++;
            y++;
        }

        return {
            width: rowWidth,
            layoutBlocks,
        };
    }

    /**
     * 
     * @param el 
     * @returns 
     */
    getBlockFromElement = (el: Element) => {
        const { currentLayout } = this.state;

        if (!currentLayout) {
            return null;
        }

        const x = Number(el.getAttribute("data-grid-x"));
        const y = Number(el.getAttribute("data-grid-y"));

        if (!x || !y) {
            return null;
        }

        return currentLayout.layoutBlocks.find(block => block.x === x && block.y === y) ?? null;
    }

    /**
     * 
     * @param drag 
     * @returns 
     */
    getBlockFromPosition = (drag: DragInformation): [Element, LayoutBlock] | null => {
        const nodes = this.refContent.current?.querySelectorAll('[data-grid-x]');

        if (!nodes) {
            return null;
        }

        let nearestDistance: number | null = null;
        let nearestEl: Element | null = null;

        for (const node of nodes) {
            const viewPort = node.getBoundingClientRect();
            const rect = {
                x1: viewPort.left,
                x2: viewPort.left + viewPort.width,
                y1: viewPort.top,
                y2: viewPort.top + viewPort.height,
            }

            if ((drag.x >= rect.x1 && drag.x <= rect.x2) && (drag.y >= rect.y1 && drag.y <= rect.y2)) {
                const block = this.getBlockFromElement(node);

                if (block) {
                    return [node, block];
                }

                return null;
            }

            // Find nearest corner
            const corners = [
                { x: rect.x1, y: rect.y1 },
                { x: rect.x2, y: rect.y1 },
                { x: rect.x1, y: rect.y2 },
                { x: rect.x2, y: rect.y2 },
            ];

            for (const corner of corners) {
                const distance = Math.sqrt(Math.pow(corner.x - drag.x, 2) + Math.pow(corner.y - drag.y, 2));

                if (nearestDistance === null || nearestDistance > distance) {
                    nearestDistance = distance;
                    nearestEl = node;
                }
            }
        }

        if (nearestEl) {
            const block = this.getBlockFromElement(nearestEl);

            if (block) {
                return [nearestEl, block];
            }
        }

        return null;
    }

    /**
     * 
     * @param block 
     * @param drag 
     * @returns 
     */
    getDropTargetFromPosition = (block: LayoutBlock, drag: DragInformation): DropInformation | null => {
        const { blocks } = this.props;

        const target = this.getBlockFromPosition(drag);

        if (!target)
            return null;

        const [node, targetBlock] = target;

        const viewPort = node.getBoundingClientRect();

        const rect = {
            x1: viewPort.left,
            x2: viewPort.left + viewPort.width,
            y1: viewPort.top,
            y2: viewPort.top + viewPort.height,
        }
        const sourceViewBlock = blocks.find(x => x.name === block.block);
        const targetViewBlock = blocks.find(x => x.name === targetBlock.block);

        const forceFullRow = (sourceViewBlock?.fullRow || targetViewBlock?.fullRow);

        const newRowMargin = forceFullRow ? node.clientHeight / 2 : Math.min(node.clientHeight / 3, NEW_ROW_PIXELS);

        if (rect.y1 + newRowMargin >= drag.y) {
            return {
                type: 'above',
                block,
                targetBlock,
            };
        }
        else if (rect.y2 - newRowMargin <= drag.y) {
            return {
                type: 'below',
                block,
                targetBlock,
            };
        } else if (block === targetBlock) {
            return {
                type: 'self',
                block,
                targetBlock,
            };
        } else {
            const midPoint = viewPort.left + (viewPort.width / 2);

            if (drag.x <= midPoint) {
                return {
                    type: 'insideLeft',
                    block,
                    targetBlock,
                };
            } else {
                return {
                    type: 'insideRight',
                    block,
                    targetBlock,
                };
            }
        }
    }

    /**
     * Updated layout
     * @param layout 
     * @param dropInformation 
     * @returns 
     */
    applyDropInformation = (config: LayoutConfiguration, dropInformation: DropInformation | null): LayoutConfiguration => {
        if (!dropInformation) {
            return config;
        }

        const { type } = dropInformation;

        if (type === 'self') {
            return config;
        }

        const source = config.blocks[dropInformation.block.block];
        const target = config.blocks[dropInformation.targetBlock.block];

        if (!source || !target) {
            console.error('invalid source or target');
            return config;
        }

        if (type === 'above' || type === 'below') {
            const newRow = type === 'below' ? target.row + 1 : target.row;
            const rowsBelow = _.pickBy(config.blocks, (block) => block.row >= newRow);
            const updatedRows = _.mapValues(rowsBelow, (block) => ({ ...block, row: block.row + 1 }));

            const newConfig: LayoutConfiguration = {
                ...config,
                blocks: {
                    ...config.blocks,
                    ...updatedRows,
                    [dropInformation.block.block]: {
                        ...source,
                        row: newRow,
                        col: 1,
                    },
                }
            };

            return newConfig;
        } else if (type === 'insideLeft' || type === 'insideRight') {
            const newCol = type === 'insideRight' ? target.col + 1 : target.col;
            const colsToRight = _.pickBy(config.blocks, (block) => block.row === target.row && block.col >= newCol);
            const updatedCols = _.mapValues(colsToRight, (block) => ({ ...block, col: block.col + 1 }));

            const newConfig: LayoutConfiguration = {
                ...config,
                blocks: this.updateBlocksConfig({}, {
                    ...config.blocks,
                    ...updatedCols,
                    [dropInformation.block.block]: {
                        ...source,
                        row: target.row,
                        col: newCol,
                    },
                }),
            };

            return newConfig;
        }

        console.error('failed to apply layout changes', { config, dropInformation });

        return config;
    }

    /** Drag **/

    /**
     * 
     * @param block 
     * @param data 
     * @returns 
     */
    onDragStart = (layoutBlock: LayoutBlock, data: DragStartInformation) => {
        const root = this.refContent.current;

        if (!root)
            return;

        this.setState({
            draggedBlock: {
                block: layoutBlock.block,
                ...data,
            },
        });
    }

    /**
     * 
     * @param block 
     * @param data 
     * @returns 
     */
    onDrag = debounce((layoutBlock: LayoutBlock, data: DragInformation) => {
        const { blocks } = this.props;
        const { draggedBlock, currentLayout, currentConfiguration } = this.state;

        const root = this.refContent.current;

        if (!root || !draggedBlock || !currentLayout || !currentConfiguration) {
            return;
        }

        const dropTarget = this.getDropTargetFromPosition(layoutBlock, data);
        const newConfig = this.applyDropInformation(currentConfiguration, dropTarget);

        this.setState({
            draggedBlock: {
                ...draggedBlock,
                ...data,
            },
            currentConfiguration: newConfig,
            currentLayout: newConfig !== currentConfiguration ? this.buildLayout(blocks, newConfig) : currentLayout,
        });
    }, 8)

    /**
     * 
     * @param block 
     * @param data 
     * @returns 
     */
    onDragEnd = (layoutBlock: LayoutBlock, data: DragInformation) => {
        const { blocks, onConfigChanged } = this.props;
        const { draggedBlock, currentLayout, currentConfiguration } = this.state;

        const root = this.refContent.current;

        if (!root || !draggedBlock || !currentLayout || !currentConfiguration)
            return;

        const dropTarget = this.getDropTargetFromPosition(layoutBlock, data);
        const newConfig = this.applyDropInformation(currentConfiguration, dropTarget);

        this.setState({
            draggedBlock: undefined,
            currentConfiguration: newConfig,
            currentLayout: newConfig !== currentConfiguration ? this.buildLayout(blocks, newConfig) : currentLayout,
        }, () => {
            onConfigChanged?.(newConfig);
            this.saveConfiguration();
        });
    }

    /* **/
    onCollapse = (viewBlock: ViewBlock, collapsed: boolean) => {
        const { blocks } = this.props;
        const { currentConfiguration } = this.state;

        const block = currentConfiguration?.blocks[viewBlock.name];

        if (!currentConfiguration || !block) {
            return;
        }

        this.applyConfig({
            ...currentConfiguration,
            blocks: {
                ...currentConfiguration?.blocks,
                [block.name]: {
                    ...block,
                    collapsed,
                }
            },
        }, true);
    }

    toggleBlock = (viewBlock: ViewBlock) => {
        const { blocks } = this.props;
        const { currentConfiguration } = this.state;

        const block = currentConfiguration?.blocks[viewBlock.name];

        if (!currentConfiguration || !block) {
            return;
        }

        this.applyConfig({
            ...currentConfiguration,
            blocks: {
                ...currentConfiguration?.blocks,
                [block.name]: {
                    ...block,
                    visible: !block.visible,
                }
            },
        }, true);
    }

    onConfigure = (viewBlock: ViewBlock, config: Record<string, Partial<SubItemConfig>>) => {
        const { currentConfiguration } = this.state;

        const block = currentConfiguration?.blocks[viewBlock.name];

        if (!currentConfiguration || !block) {
            return;
        }

        this.applyConfig({
            ...currentConfiguration,
            blocks: {
                ...currentConfiguration?.blocks,
                [block.name]: {
                    ...block,
                    subitems: _.merge(block.subitems, config),
                }
            },
        }, true);
    }

    /** render **/

    /**
     * 
     * @param col 
     * @returns 
     */
    getStyleForLayoutBlock = (col: LayoutBlock): React.CSSProperties => ({
        gridColumn: `${col.gridCol} / span ${col.gridSpan}`,
        gridRow: `${col.gridRow}`,
    })

    renderBlock = (col: LayoutBlock, block: ViewBlock, config: LayoutConfigurationBlock, draggingState?: DraggingState) => {
        const blockProps: BlockProps = {
            title: block.title,
            viewBlock: block,
            collapsed: !block.disableCollapse && col.collapsed,
            canCollapse: !block.disableCollapse,
            onCollapse: this.onCollapse,
        }

        return (
            <BlockContainer
                layoutBlock={col}
                blockStyles={this.getStyleForLayoutBlock(col)}
                onDragStart={this.onDragStart}
                onDrag={this.onDrag}
                onDragEnd={this.onDragEnd}
                rootContainer={this.refContent.current}
                block={block}
                draggingState={draggingState}
                key={block.name}>
                {block.render(blockProps, {
                    onConfigure: this.onConfigure,
                    subitems: config.subitems ?? {},
                })}
            </BlockContainer>
        );
    }

    render() {
        const { className, blocks, title } = this.props;
        const { currentLayout, currentConfiguration, draggedBlock } = this.state;

        if (!currentLayout || !currentConfiguration) {
            return (<div className={cn(className, styles.root, styles.loadingPage)}>
                <div className={styles.loading}>
                    <img src={loading} alt={this.tr('Loading...')} />
                </div>
            </div>)
        }

        const blocksByKey = _.keyBy(blocks, x => x.name);

        const draggedBlockLayout = draggedBlock ? currentLayout.layoutBlocks.find(x => x.block === draggedBlock.block) : undefined;

        return (<div className={cn(className, styles.root)}>
            <div className={cn(styles.controls)}>
                <div className={styles.left}>{title && <h1>{title}</h1>}</div>
                <div className={styles.right}>
                    <InsightDropDown tabs={blocks.filter(x => x.canBeVisible ?? true).map(x => ({
                        action: () => this.toggleBlock(x),
                        key: x.name,
                        label: x.title,
                        'data-testid': 'block-toggle-' + x.name,
                        iconComponent: (currentConfiguration.blocks[x.name]?.visible ?? false) ? CheckBox : CheckBoxOutlineBlank,
                        iconComponentClass: currentConfiguration.blocks[x.name]?.visible ? styles.menuCheckbox : undefined
                    }))} icon={<EditIcon className="icon-left" />} data-testid="edit-blocks" titleLabel={this.tr('Edit View')} disableAutoClose />
                </div>
            </div>
            <div ref={this.refContent} className={cn(styles.grid)} style={{
                gridTemplateColumns: `repeat(${currentLayout.width}, 1fr)`,
            }}>
                {draggedBlock && draggedBlockLayout && <div key="dragPlaceholder" className={styles.dragPlaceholder} data-grid-x={draggedBlockLayout.x} data-grid-y={draggedBlockLayout.y} style={{
                    ...this.getStyleForLayoutBlock(draggedBlockLayout),
                }}>
                    <div className={styles.marker} style={{
                        height: draggedBlock.height,
                    }} />
                </div>}
                {currentLayout.layoutBlocks.map(x =>
                    this.renderBlock(x, blocksByKey[x.block], currentConfiguration.blocks[x.block], draggedBlock?.block === x.block ? draggedBlock : undefined)
                )}
            </div>
        </div>);
    }
}