import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { withSnackbar } from 'notistack';
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import CameraIcon from '@mui/icons-material/Camera';
import Avatar from '@mui/material/Avatar';
import SettingsGrid from './SettingsGrid';
import Button from '@mui/material/Button';
import TaimerAvatar from '../../general/TaimerAvatar';
import TaimerComponent from "../../TaimerComponent";
import { isEqual } from 'lodash';
import OutlinedField from "../../general/OutlinedField";
import scssStyles from './PersonalDetails.module.scss';
import { Auth } from '@aws-amplify/auth'
import LoginView from "../../login/Login";
import CognitoHelpers from '../../general/CognitoHelpers';

const styles = theme => ({
    bigAvatar: {
        width: 105,
        height: 105,
    },
    icon: {
        color: 'white',
    }
});



const dateFormats = [
    {
        value: '%d.%m.%Y',
        label: "DD.MM.YYYY",
    },
    {
        value: '%m/%d/%Y',
        label: 'MM/DD/YYYY',
    },
    {
        value: '%Y-%m-%d',
        label: "YYYY-MM-DD",
    },
];

class PersonalDetails extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/PersonalDetails");

        this.state = {
            loaded: false,
            avatarKey: 1,
            awsUser: undefined,
        }

        this.refFileInput = React.createRef();
    }

    componentDidMount = async () => {
        super.componentDidMount();
        const data = await DataHandler.get({url: 'settings/profile/details'});
        const awsUser = await this.authenticatedUser();
        this.setState({loaded: true, data, awsUser});
    }

    authenticatedUser = async () => {
        let awsUser;

        try {
            awsUser = await Auth.currentAuthenticatedUser();
            return awsUser;
        }
        catch (error) {
            console.log(error);
        }
    }

    onChange = async (data) => {
        const { awsUser, verifyEmailRequestSent } = this.state;
        const prevEmail = this.state.data.email;

        if (isEqual(data, this.state.data)) return;

        if (awsUser) {
            if (!verifyEmailRequestSent && data.email !== this.state.data.email) {
                this.renderVerifyEmailDialog(data.email);
                const response = await this.changeCognitoAttributes({ email: data.email });
                console.log("change email", response)
                this.setState({verifyEmailRequestSent: true})
                return
            }
            if (data.lang !== this.state.data.lang) {
                this.changeCognitoAttributes({ locale: data.lang })
            }
        }    
        this.setState({ data }, () => {
            this.save(data);
        });
    }
    changeCognitoAttributes = async (attributes) => {
        const { data, awsUser } = this.state;
        
        try {
            const user = await Auth.currentAuthenticatedUser();
            return await Auth.updateUserAttributes(user, attributes);
        }
        catch (e) {
            console.log(e);
        }
    }

    save = async (data) => {
        const { functions: { whoami, setSidebarStyle }} = this.context;
        const newdata = await DataHandler.post({url: 'settings/profile/details'}, data)
            .fail(x => this.setError(x));
        this.setState({ loaded: true, data: newdata, errors: {} }, this.changeCognitoAttributes);
        if (data.sidebar_style != undefined) {
            setSidebarStyle(data.sidebar_style)
        }
        setTimeout(() => {
            whoami();
        }, 1000);
    }

    setError(error)
    {
        this.setState({errors: error.responseJSON}, () => {
            if (error.responseJSON.error === "EMAIL_IN_USE") {
                this.props.enqueueSnackbar(this.tr("Email already in use"), {
                    variant: "error"
                })
            }
        })
    }

    uploadClick = () => {
        this.refFileInput.current.click();
    }

    uploadAvatar = (e) => {
        const { avatarKey } = this.state;

        const file = e.target.files[0];
        if(file.size > 1024 * 1024 * 4) {

            this.props.enqueueSnackbar(`Maximum file size is 4 MB`, {
                variant: "error",
            });
            return;

        }
        e.target.value = '';

        DataHandler.file({url: 'settings/profile/avatar'}, file).always(() => {
            setTimeout(() => {
                this.setState({avatarKey: avatarKey+1}, () => this.context.functions.whoami())
            }, 1000);
        });
    }

    renderLoginForm = (username) => {
        const { functions, folder } = this.context;

        functions.showDialogContent(<div className={scssStyles.loginView}>
                <LoginView 
                    cognitoSignIn 
                    account={folder} 
                    username={username}
                    message={this.tr("You have already used this email on other account. Please log in using those credentials")}
                    onSignIn={async () => {
                        const user = await this.authenticatedUser();

                        CognitoHelpers.setCognitoUserId(user, true);
                        const data = Object.assign({}, this.state.data, {email: username});
                        this.setState({data});

                        console.log(user);
                        functions.closeDialog();
                    }} />
            </div>
        );
    }

    verifyEmail = async (email) => {
        let { awsUser, emailVerification: code, data } = this.state;
        try {
            await Auth.verifyUserAttributeSubmit(awsUser, "email", code);
            data = Object.assign({}, data, {email});
            this.setState({ data, emailVerification: "" }, () => {
                this.save(data);
                this.context.functions.closeDialog();
            });
        } catch (error) {
                console.log(error)

                this.setState({ emailVerification: "", verifyEmailRequestSent: false });

                if (error.code === "InternalFailure")
                    this.renderLoginForm(email);
                else if(error.code === 'CodeMismatchException')
                    this.renderVerifyEmailDialog(email, this.tr("error confirming email"));
                else if(error.code === 'AliasExistsException')
                    this.renderVerifyEmailDialog(email, this.tr("The given email already exists"));

        }
    }
    closeVerifyEmailDialog = (callback) => {
        const { data } = this.state;
        this.setState({data: {}, emailVerification: "", verifyEmailRequestSent: false}, () => this.setState({ data }));
        this.context.functions.closeDialog(callback);
    }
    renderVerifyEmailDialog = (email, errorMessage) => {
        const { functions } = this.context;
        const { emailVerification } = this.state;
        const { tr } = this;

        functions.showDialogContent(
            <div className={scssStyles.verifyEmail}>
                <h1>{tr("Verify your email address")}</h1>
                <p>{tr('We sent you an email. Please enter a verification code to complete email address change.')}</p>
                {errorMessage && <div className="error-alert">
                    <p>{errorMessage}</p>
                </div>}
                <OutlinedField
                    className={scssStyles.loginField}
                    value={email}
                    label={tr("Your email address")}
                    disabled />

                <OutlinedField 
                    data-testid="email-verification-code" 
                    noOnchangeValidation={true} 
                    className={scssStyles.loginField} 
                    value={emailVerification} 
                    onChange={(e) => this.setState({ emailVerification: e.target.value })} 
                    validation={["empty"]} 
                    autoComplete="one-time-code"
                    label={tr("Email verification code")} />
                <br/>
                <Button 
                    data-testid="verify-button" 
                    className={scssStyles.loginButton}
                    color="primary" 
                    onClick={() => this.verifyEmail(email)} 
                    size="large">
                    {tr("Verify").toUpperCase()}
                </Button>
            </div>
        , {onClose: () => this.closeVerifyEmailDialog()});

        
    }

    render() {
        const { classes } = this.props;
        const { loaded, data, avatarKey, errors } = this.state;
        const { userObject, taimerAccoun, addons } = this.context;
        const { tr } = this;

        if (!loaded)
            return <div><img className='main-page-loading-indicator' src={require('../../dashboard/insights/img/loading.svg').default} /></div>
        
        const languages = [
            {
                value: addons.translation_special1 ? 'en_special1' : 'en',
                label: tr("English"),
            },
            {
                value: addons.translation_special1 ? 'en_special1' : 'en_us',
                label: tr("English (US)"),
            },
            {
                value: addons.translation_special1 ? 'en_special1' : 'en_au',
                label: tr("English (AU)"),
            },
            {
                value: addons.translation_special1 ? 'en_special1' : 'en_ca',
                label: tr("English (CA)"),
            },
            {
                value: addons.translation_special1 ? 'fi_special1' : 'fi',
                label: tr("Finnish"),
            },
            {
                value: 'se',
                label: tr("Swedish"),
            },
        ];
         
        
        const calendars = [
            {
                value: 'en-US',
                label: tr("English (US)"),
            },
            {
                value: 'en-GB',
                label: tr("English (UK)"),
            },
            {
                value: 'fi-FI',
                label: tr("Finnish"),
            },
        ];
        
        const settings = [
            {
                type: 'text',
                name: 'firstname',
                label: tr("First Name"),
                validation: ["empty"]
            },
            {
                type: 'text',
                name: 'lastname',
                label: tr("Surname"),
                validation: ["empty"]
            },
            {
                type: 'text',
                name: 'email',
                label: tr("Email"),
                validation: ["email"]
            },
            {
                type: 'text',
                name: 'phone',
                label: tr("Phone"),
            },
            {
                type: 'text',
                name: 'title',
                label: tr("Title"),
                locked: true,
            },
            {
                type: 'text',
                name: 'superior_name',
                label: tr("Supervisor"),
                locked: true,
            },
            {
                type: 'title',
                key: 'section-user',
                label: tr("User Language & Date Format"),
            },
            {
                type: 'select',
                options: languages,
                name: 'lang',
                label: tr("Taimer Language"),
            },
            {
                type: 'select',
                options: dateFormats,
                name: 'date_format',
                label: tr("Date Format"),
            },
            {
                type: 'select',
                options: [{
                        value: '0',
                        label: tr("Sunday"),
                    },{
                        value: '1',
                        label: tr("Monday"),
                },],
                name: 'week_start',
                label: tr("Start Day of Week"),
            },
            {
                type: 'select',
                options: [
                    {
                        value: '0',
                        label: tr("12 hr"),
                    },
                    {
                        value: '1',
                        label: tr("24 hr"),
                    },
                ],
                name: 'clock_format',
                label: tr("Clock"),
            },
            {
                type: 'title',
                key: 'section-sidebar',
                label: tr("Sidebar Settings"),
            },
            {
                type: 'select',
                options: [
                    {
                        value: '0',
                        label: tr("Grouped"),
                    },
                    {
                        value: '1',
                        label: tr("Listed"),
                    },
                ],
                name: 'sidebar_style',
                label: tr("View Sidebar Features"),
            },
            {
                type: 'title',
                key: 'section-sidebar',
                label: tr("List settings"),
            },
            {
                type: 'check',
                name: 'sticky_search_in_list_views',
                label: tr("Remember your latest search when returning to a list view"),
            },
            {
                type: 'title',
                key: 'section-sidebar',
                label: tr("Meetings"),
            },
            {
                type: 'text',
                name: 'personal_meeting_link',
                label: tr("Personal Meeting Link"),
            },
            {
                type: 'title',
                key: 'section-sidebar',
                label: tr("Integrations"),
            },
            {
                type: 'check',
                name: 'show_email',
                label: tr("Office 365 Mail"),
            },
            {
                type: 'empty'
            },
            {
                type: 'check',
                name: 'show_onedrive',
                label: tr("One Drive"),
            },
            {
                type: 'empty'
            },
            {
                type: 'check',
                name: 'show_calendar',
                label: tr("Google or Outlook calendar"),
            },
            {
                type: 'title',
                key: 'section-sidebar',
                label: tr("Cookie settings"),
            },
            {
                type: 'check',
                name: 'accept_nonessential_cookies',
                label: tr("Allow non-essential cookies"),
            },
            /*{
                type: 'select',
                options: calendars,
                name: 'calendar_locale',
                label: tr("Calendar"),
            },*/
        ];

        return <React.Fragment>
            <h3>{tr("Personal Profile")}</h3>

            <div className="ProfileInfoBox">
                <div className="imageWithIcon" onClick={this.uploadClick}>
                    <div>
                        <TaimerAvatar
                            key={avatarKey}
                            //param={avatarKey}
                            id={userObject.usersId}
                            name={userObject.fullname}
                            color={userObject.color}
                            size="x-large"
                            //param={new Date().getTime()}
                        />
                    </div>
                    <div className="iconLayer">
                        <CameraIcon classes={{root: classes.icon}} />
                    </div>
                </div>

                <input ref={this.refFileInput} onChange={this.uploadAvatar} type="file" style={{display: 'none'}} />
                <div className="ProfileInfoBoxName">
                    <h4>{userObject.fullname}</h4>
                    <span>{userObject.title}</span>
                </div>
            </div>

            <div className="SettingsGrid">
                <SettingsGrid errors={errors} settings={settings} item={data} onChange={this.onChange} />
            </div>

        </React.Fragment>
    }
}

export default withStyles(styles)(withSnackbar(PersonalDetails));
