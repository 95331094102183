import React from 'react';
import DataHandler from "../../general/DataHandler";
import Utils from "../../general/Utils";
import TaimerComponent from '../../TaimerComponent';

import MultiSelect from '../../general/MultiSelect';
import { DateRangePicker } from '../../general/react-date-range/src';
import SimpleList from "../../list/SimpleList";
import AdvancedSearch from '../../search/AdvancedSearch';
import LogBatchSlider from "./LogBatchSlider";

import { format } from "date-fns";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import EyeIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';

// TODO: refactor into a module thingy.
import styles from "./Log.module.css";

class Log extends TaimerComponent {
    static defaultProps = {
        batchSliderMode: false,
        company: undefined,
        data: [],
        entityType: null, // Only relevant when batchSliderMode = true.
        logType: null, // Only relevant when batchSliderMode = true.
        onClose: () => {},
    };

    constructor(props, context) {
        super(props, context, "Log"); 

        this.list = React.createRef();

        this.state = {
            data: props.data,
            modules: [],
            searchOptionsByModule: {},
            eventSearchOptions: [],
            filters: {
                modules: [],
                fields: [],
                events: [],
                users: [],
                dateRange: {
                    startDate: '',
                    endDate: '',
                    key: "selection"
                }
            }
        };

        const { 
            batchSliderMode,
            company,
            logType,
            entityType,
            data
        } = this.props;
        const { tr }  = this;
        const commons = { 
            resizeable: false, 
            moveable: false, 
            sortable: false, 
            hideable: false, 
            showMenu: false, 
            showResizeMarker: true 
        };

        const basicColumns = [
            { name: "icon", header: "", width: 1, ...commons },
            { name: "module", header: tr("Module"), width: 1, ...commons },
            { name: "entity", header: tr("Entity"), width: 2, ...commons },
            { name: "event", header: tr("Event"), width: 2, ...commons, alignment: "left" },
            batchSliderMode ? { name: "field", header: tr(`${Utils.capitalize(entityType)} details`), width: 2, ...commons } : null,
            { name: "old_value", header: tr("From"), width: 2, ...commons },
            { name: "new_value", header: logType !== 100 ? tr("To") : tr("Value"), width: 2, ...commons },
            { name: "user", header: tr("User"), width: 2, ...commons },
            { name: "datetime", header: tr("Date and time"), width: 2, ...commons },
            { name: "account", header: tr("account"), width: 2, ...commons },
            { name: "project", header: tr("project"), width: 2, ...commons },
        ].filter(c => c)
         .filter(c => {
             const hasEdits = Boolean(data.find(d => d.log_type === 101));

             return !batchSliderMode || [
                 "field", 
                 // When the log_type is creation, there's 
                 // no need to show the old value field.
                 logType === 101 || hasEdits ? "old_value" : null, 
                 "new_value", 
                 "datetime"
             ].indexOf(c.name) > -1;
        });

        this.columns = {
            basic: basicColumns,
            event: basicColumns,
            batch_title: basicColumns,
            first: basicColumns,
        };

        // TODO: Refactor to use updateView.
        this.entityFieldHandlers = {
            account: (data, title = null) => <a target="_blank" rel="noreferrer" href={`index.html?module=customers&action=view&id=${data.entity_data?.id}`}>{title || data?.entity_data?.name || null}</a>,
            invoice: (data, title = null) => {
                // If the invoice has been deleted and data about it has 
                // been saved, show the data.
                if((data.log_type === 205 || !data.entity_data) && data?.data) {
                    const transl = tr("Invoice nr.");

                    return `${transl} ${data.data.bill_id || data.data.id} (${data.data?.customer})`;
                } else if(data.log_type === 205) {
                    return tr("Invoice");
                }

                return <a target="_blank" rel="noreferrer" href={`index.html?module=invoices&action=view&id=${data.entity_data?.id}`}>{title || data?.entity_data?.name || null}</a> 
            },
            project: (data, title = null) => <a target="_blank" rel="noreferrer" href={`index.html?module=projects&action=view&id=${data.entity_data?.id}`}>{title || data?.entity_data?.name || null}</a>,

            project_manual_cost: (data, title = null) => <a target="_blank" href={`index.html?module=projects&action=view&id=${data.entity_data?.projects_id}&selectedTab=finances&selectedSubTab=finances_3`}>{title || data?.entity_data?.name || null}</a>,
            project_scheduled: (data, title = null) => <a target="_blank" href={`index.html?module=projects&action=view&id=${data.entity_data?.projects_id}&selectedTab=finances&selectedSubTab=finances_1`}>{title || data?.entity_data?.name || null}</a>,
            project_automatic: (data, title = null) => <a target="_blank" href={`index.html?module=projects&action=view&id=${data.entity_data?.projects_id}&selectedTab=finances&selectedSubTab=finances_2`}>{title || data?.entity_data?.name || null}</a>,
            project_automatic_row: (data, title = null) => <a target="_blank" href={`index.html?module=projects&action=view&id=${data.entity_data?.projects_id}&selectedTab=finances&selectedSubTab=finances_2`}>{title || data?.entity_data?.name || null}</a>,

            user: (data, title = null) => <a target="_blank" rel="noreferrer" href={`index.html?module=users&action=view&id=${data.entity_data?.id}&company=${company}`}>{title || data?.entity_data?.name || null}</a>,
            quote: (data, title = null) => {
                const projectId = data?.projects?.[0]?.id || undefined;

                if(data.log_type === 102 && data.data.name) {
                    return data.data.name;
                }

                return projectId !== undefined ? (
                    <a target="_blank" rel="noreferrer" href={`index.html?module=projects&action=view&id=${projectId}&selectedTab=sales&selectedSubTab=quote&selectedQuoteId=${data.entity_id}`}>
                        {title || data?.entity_data?.name || ""}
                    </a>
                ) : "-";
            },
            api: (data, title = null) => {
                if(data?.data?.integration_name) {
                    return tr(data.data.integration_name);
                }

                return title || "";
            },
            expense: (data, title = null) => <a target="_blank" rel="noreferrer" href={`index.html?module=worktrips&action=modify&expenseType=1&id=${data?.entity_id}`}>{data?.entity_id}</a>,
            travel_expense: (data, title = null) => <a target="_blank" rel="noreferrer" href={`index.html?module=worktrips&action=modify&expenseType=2&id=${data?.entity_id}`}>{data?.entity_id}</a>,
            default: (data, title = null) => {
                // If the data field of the log entry has
                // display_name and/or display_id, show this
                // data. This is mainly for entities that have
                // been deleted.
                if(!data.entity_data && data?.data?.display_name) {
                    const idPart = data?.data?.display_id
                        ? ` (${data?.data?.display_id})`
                        : "";

                    return `${data.data.display_name}${idPart}`;
                }

                return title || data?.entity_data?.name || ""; 
            },
        };

        const { dateFormat } = this.context.userObject;
        const formatDate     = date => format(date, `${dateFormat} HH:mm:ss`);
        const handleValue    = (displayValue, data) => {
            const {
                is_batch_parent,
                priority_value,
                batch_children_count
            } = data;
            
            return is_batch_parent && priority_value && batch_children_count > 0
                ? <a onClick={() => this.openBatchSlider(data)}>{displayValue}</a>
                : displayValue;
        };

        const basicPresentation = {
            icon: (data)   => {
                const batchChildrenCount = data.batch_children_count;
                
                return [
                    data.is_batch_parent && batchChildrenCount > 0 
                    ? <Tooltip 
                        placement="right" 
                        arrow={true} 
                        title={`${tr("This event includes")} ${batchChildrenCount} ${tr("more changes – click to view all of them")}`}>
                        <span
                            onClick={() => this.openBatchSlider(data)}>
                            <EyeIcon 
                                style={{ cursor: "pointer" }} /> 
                            <span style={{ 
                                fontSize: 9,
                                cursor: "pointer"
                            }}>{` + ${batchChildrenCount}`}</span>
                        </span>
                    </Tooltip>
                    : null, 
                    { align: "center" }
                ]},
            module: data => tr(data.entity_type),
            entity: data => <strong>{this.handleEntityField(data)}</strong>,
            event: data  => {
                const { 
                    description_string,
                    priority_field,
                    field,
                    entity_type,
                    log_type
                } = data;

                const fieldField = priority_field
                    ? priority_field
                    : field;

                const displayField = log_type === 101
                    ? `${tr(entity_type)}/${tr(fieldField)}`
                    : "";

                return `${tr(description_string)} ${displayField}`;
            },
            field: data => {
                if(batchSliderMode && data?.batch_title) {
                    return tr(`${data.entity_type}`);
                } else if(batchSliderMode && [100, 101, 102].indexOf(data.log_type) === -1) {
                    return tr(data.description_string);
                } else {
                    return tr(`${data.entity_type}/${data.field}`);
                }
            },
            old_value: data => {
                const {
                    is_batch_parent,
                    priority_previous_value,
                    previous_value,
                    batch_children_count
                } = data;

                const displayValue = is_batch_parent && priority_previous_value
                    ? priority_previous_value
                    : previous_value;

                const useTooltip = !batchSliderMode && is_batch_parent && batch_children_count > 0;

                return [
                    handleValue(displayValue, data),
                    { 
                        align: "left",
                        valueWrapper: useTooltip
                            ? Tooltip 
                            : React.Fragment,
                        valueWrapperProps: useTooltip 
                            ? {
                                title: `+ ${data.batch_children_count} ${tr("change(s) – click to view all of them")}`,
                                placement: "top",
                                arrow: true
                            }
                            : {}
                    }
                ];
            },
            new_value: data => {
                const {
                    is_batch_parent,
                    priority_value,
                    value,
                    batch_children_count
                } = data;

                const displayValue = is_batch_parent && priority_value
                    ? priority_value
                    : value;

                const useTooltip = !batchSliderMode && is_batch_parent && batch_children_count > 0;

                return [
                    handleValue(displayValue, data),
                    { 
                        align: "left",
                        valueWrapper: useTooltip 
                            ? Tooltip 
                            : React.Fragment,
                        valueWrapperProps: useTooltip 
                            ? {
                                title: `+ ${data.batch_children_count} ${tr("change(s) – click to view all of them")}`,
                                placement: "top",
                                arrow: true
                            }
                            : {}
                    }
                ];
            },
            user: data     => data.user,
            datetime: data => formatDate(data.datetime),
            account: data  => {
                let {
                    accounts,
                    data: savedData
                } = data;

                // Likely a deleted invoice.
                if(!accounts || accounts.length === 0 && savedData) {
                    accounts = [{
                        id: savedData?.customers_id,
                        name: savedData?.customer,
                    }];
                }

                // The amount of customers will be equal to
                // the amount of projects joined to this entity,
                // but as this is an invoice, we can trust it only
                // has one customer and there's no need to display the
                // same customer multiple times.
                if(data.entity_type === "invoice") {
                    accounts = accounts.slice(0, 1);
                }

                return (<>
                    {accounts.map((a, index) => {
                        return (
                            <>
                                <a target="_blank" rel="noreferrer" href={`index.html?module=customers&action=view&id=${a.id}`}>{a.name}</a>
                                {index + 1 < accounts.length && ", "}
                            </>
                        );
                    })}
                </>);
            },
            project: data  => {
                let {
                    projects,
                    data: savedData
                } = data;

                // Likely a deleted invoice.
                if(!projects || projects.length === 0) {
                    const ids   = savedData?.project_ids?.split(",") || [];
                    const names = savedData?.project_names?.split(",") || [];

                    projects = ids.map((id, index) => {
                        return {
                            id: id,
                            name: names[index]
                        };
                    });
                }

                return (<>
                    {projects.map((p, index) => {
                        return (
                            <>
                                <a target="_blank" rel="noreferrer" href={`index.html?module=projects&action=view&id=${p.id}`}>{p.name}</a> 
                                {index + 1 < projects.length && ", "}
                            </>
                        );
                    })}
                </>);
            }
        };

        this.presentations = {
            basic: basicPresentation,
            batch_title: basicPresentation,
            first: basicPresentation,
            event: basicPresentation,
        };

        this.openBatchSlider       = this.openBatchSlider.bind(this);
        this.handleEntityField     = this.handleEntityField.bind(this);
        this.handleAdvancedSearch  = this.handleAdvancedSearch.bind(this);
        this.freeTextSearch        = this.freeTextSearch.bind(this);
        this.advancedSearch        = this.advancedSearch.bind(this);
        this.onDateRangeChange     = this.onDateRangeChange.bind(this);
        this.onMultiSelectChange   = this.onMultiSelectChange.bind(this);
        this.setFilter             = this.setFilter.bind(this);
        this.onFiltersChange       = this.onFiltersChange.bind(this);
        this.determineFields       = this.determineFields.bind(this);
        this.handleFetchParameters = this.handleFetchParameters.bind(this);
        this.fetch                 = this.fetch.bind(this);
        this.refreshData           = this.refreshData.bind(this);

        if(!batchSliderMode) {
            this.fetch();
            this.refreshData();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!isEqual(this.state.filters, prevState.filters)) {
            this.onFiltersChange();
        }
    }

    // @reference
    handleFetchParameters(parameters) {
        const handlers = {
            fields: () => {
                parameters.fields = parameters.fields
                    // Don't have time to figure out why
                    // MultiSelect gives a zero here.
                    .filter(field => parseInt(field) !== 0);
            },
            dateRange: () => {
                const { dateRange }          = parameters;
                const { startDate, endDate } = dateRange;

                if(!parameters.start_date && startDate) {
                    parameters.start_date = startDate;
                }

                if(!parameters.end_date && endDate) {
                    parameters.end_date = endDate;
                }

                delete parameters.dateRange;
            },
        };

        Object.keys(parameters)
            .filter(key => handlers.hasOwnProperty(key))
            .forEach(key => {
                handlers[key]();
            });

        parameters.timezone_offset = (new Date()).getTimezoneOffset();
    }

    async fetch(parameters = {}) {
        // Some params require some handling
        // before being sent to the backend.
        this.handleFetchParameters(parameters);

        try {
            const log = await DataHandler.get({ url: `log`, ...parameters });

            this.setState({ data: log }, () => this.initialFetchDone = true);
        } catch(e) {
            console.log("taimer-debug", e);
            this.initialFetchDone = true
        }
    }

    async refreshData() {
        const { tr } = this;

        Promise.all([
            DataHandler.get({ url: `log/search_options` }),
            DataHandler.get({ url: `log/modules` }),
            DataHandler.get({ url: `log/users` }),
        ]).then(([searchOptions, modules, users]) => {
            let {
                by_module,
                events
            } = searchOptions;

            events = Object.keys(events).map(key => {
                return {
                    id: key,
                    value: key,
                    label: tr(events[key]),
                    name: tr(events[key])
                };
            }).sort((a, b) => {
                if(a.label === b.label) return 0;
                if(a.label < b.label) return -1;
                if(a.label > b.label) return 1;
            })

            modules = modules.map(module => {
                return {
                    id: module,
                    value: module,
                    label: tr(module),
                    name: tr(module)
                };
            }).sort((a, b) => {
                if(a.label === b.label) return 0;
                if(a.label < b.label) return -1;
                if(a.label > b.label) return 1;
            });

            this.setState({ 
                modules: modules,
                searchOptionsByModule: by_module,
                eventSearchOptions: events,
                users: users.map(user => {
                    const end = [
                        parseInt(user.companies_id) === 0 ? tr("(freelancer)") : "",
                        parseInt(user.locked) > 0 ? tr("(locked)") : ""
                    ].join(" ");

                    user['name'] = (`${user['name']} ${end}`).trim();

                    return user;
                })
            });
        });
    }

    openBatchSlider(batchParent) {
        const { showSliderContent } = this.context.functions;
        const { 
            entity_data,
            log_type,
            entity_type,
            user,
            description_string,
            datetime
        } = batchParent;
        const { tr }         = this;
        const { data }       = this.state;
        const { dateFormat } = this.context.userObject;

        // Only creation, edit, and deletion
        // are shown in the batch slider for now.
        // const types = [100, 101, 102];
        // Except now any event can be shown
        // in the slider.

        const batchData = data.filter(d => {
            return d.parent_id === batchParent.id;
                // && types.indexOf(d.log_type) > -1;
                // Don't include the title itself.
                // Or.. I don't know.
                // && d.id !== batchParent.id
                // && d.id !== d.batch_id;
        });

        const date = format(datetime, dateFormat);
        let transl = tr(`{%event_placeholder%} on {%date_placeholder%} by {%user_placeholder%}`);

        transl = transl.replace("{%event_placeholder%}", description_string);
        transl = transl.replace("{%date_placeholder%}", date);
        transl = transl.replace("{%user_placeholder%}", user);

        showSliderContent(`${tr(batchParent.entity_type)} > ${entity_data?.name || ""}`, 
            <LogBatchSlider 
                data={batchData} 
                description={transl} 
                entityType={entity_type}
                logType={log_type} />, true);
        }

    handleEntityField(data, title = null) {
        const { 
            entity_type
        } = data;

        return this.entityFieldHandlers.hasOwnProperty(entity_type) 
            ? this.entityFieldHandlers[entity_type](data, title)
            : this.entityFieldHandlers['default'](data, title);
    }

    handleAdvancedSearch(search) {
        search.mode === "freetext"
            ? this.freeTextSearch(search)
            : this.advancedSearch(search);
    }

    freeTextSearch(search) {
        const { freetextSearchTerm } = search;

        this.setFilter({ search: freetextSearchTerm });
    }

    advancedSearch() {
        // TODO: will there even be an advanced search for logs?
    }

    onDateRangeChange(event, dateRangeType) {
        const { 
            startDate, 
            endDate 
        } = event.selection;

        this.setFilter("dateRange", {
            startDate: format(startDate, "YYYY-MM-DD"),
            endDate: format(endDate, "YYYY-MM-DD"),
            key: "selection"
        });
    }

    onMultiSelectChange(name, data) {
        data = data.filter(v => parseInt(v.value) !== 0);

        this.setFilter(name, data.map(m => m.value));
    }

    setFilter(f, v = null) {
        let { filters } = cloneDeep(this.state);

        if(typeof(f) === "object") {
            filters = { 
                ...filters, 
                ...f 
            };
        } else {
            filters[f] = v;
        }

        this.setState({ filters });
    }

    onFiltersChange() {
        this.fetch(cloneDeep(this.state.filters));
    }

    determineFields() {
        const { 
            tr 
        } = this;
        const { 
            searchOptionsByModule,
            filters
        } = this.state;
        // TODO: Move out of render.
        const fields = Object.keys(searchOptionsByModule).filter(module => {
            return filters.modules.length === 0 
                || filters.modules.indexOf(module) > -1;
        })
        .map(module => {
            return searchOptionsByModule[module].map(field => ({
                module: module,
                field: field
            }));
        })
        .flat()
        .map(f => {
            const { 
                module, 
                field 
            } = f;

            return {
                id: field,
                value: field,
                label: `${tr(module)} / ${tr(field)}`,
                name: `${tr(module)} / ${tr(field)}`,
            };
        })
    
        return fields;
    }

    renderFilters() {
        const { 
            filters,
            modules, 
            searchOptionsByModule, // What's this?
            eventSearchOptions,
            users,
        } = this.state;
        const { tr }         = this;
        const { dateRange }  = filters;
        const { userObject } = this.context;
        const fields         = this.determineFields();

        return (
            <div className={styles.logList}>
                {/*<div className="headers">
                    <h3>{tr("Log")}</h3>
                </div>*/}
                <div className={styles.listControlsContainer}>
                    <div className="header-container primary">
                        <DateRangePicker
                            className={styles.daterange} 
                            ranges={[dateRange]}
                            onChange={this.onDateRangeChange}
                            // onInputChange={(dateType, date) => this.onExpectDateInputChange(dateType, date, 'funnelRange')}
                            label={tr("Date range")}
                            dateFormat={userObject.dateFormat} 
                        />
                        <MultiSelect
                            label={tr("Module")}
                            name="module"
                            className={styles.multiselect}
                            options={modules}
                            onChange={data => this.onMultiSelectChange("modules", data)}
                        />
                        <MultiSelect
                            label={tr("Field")}
                            name="field"
                            className={styles.multiselect}
                            options={fields}
                            onChange={data => this.onMultiSelectChange("fields", data)}
                        />
                        <MultiSelect
                            label={tr("Event")}
                            name="event"
                            className={styles.multiselect}
                            options={eventSearchOptions}
                            onChange={data => this.onMultiSelectChange("events", data)}
                        />
                        <MultiSelect
                            label={tr("User")}
                            name="user"
                            className={styles.multiselect}
                            options={users?.map(u => {
                                return {
                                    value: u.id,
                                    name: u.name,
                                    label: u.name
                                };
                            }) || []}
                            onChange={data => this.onMultiSelectChange("users", data)}
                        />
                        <AdvancedSearch 
                            searchTextInputClassName={styles.searchTextInputWrapper}
                            textInputClassName={styles.searchTextInput}
                            hideAdvanced={true}
                            onSearchTrigger={this.handleAdvancedSearch}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { presentations } = this;
        const { 
            data, 
        } = this.state;
        const {
            onClose,
            batchSliderMode
        } = this.props;

        // const batchParentMap = makeMap(data.filter(d => d.is_batch_parent), "id");

        return (
            <React.Fragment>
                {!batchSliderMode && this.renderFilters()}
                <SimpleList 
                    data={data
                            .filter(d => {
                                // TODO: Move elsewhere.
                                // We only want to display "normal" rows that don't have any
                                // children or batched events, and batch parents. Everything
                                // that's in a batch will only be visible in an additional slider.
                                
                                // In batch slider mode we always want to show all rows, since 
                                // they've been filtered before passing them to this component.
                                // Also, even if the row is in a batch, but its batch parent doesn't
                                // match the search, we still need to show the child itself.
                                return (!d.event_parent_duplicate && (batchSliderMode 
                                        || !d.in_batch 
                                        || d.is_batch_parent))
                                    || (batchSliderMode && d.event_parent_duplicate);
                            })
                            .map(d => {
                        const { 
                            log_type, 
                            is_first 
                        } = d;

                        // TODO: Determine in the backend and map
                        // the types of rows to types of ui rows here.
                        d._type = log_type >= 100 && log_type <= 199
                            ? "basic"
                            : "event";

                        d._type = d._type === "basic" && is_first
                            ? "first"
                            : d._type;

                        d._type = d?.batch_title 
                            ? "batch_title" 
                            : d._type;

                        return d;
                    })}
                    listProps={{
                        ref: this.list,
                        listRowTypeKey: "_type",
                        height: "fitRemaining",
                        trimHeight: -70,
                        idType: "string",
                        noColorVariance: batchSliderMode,
                        showNoResultsMessage: !batchSliderMode && this.initialFetchDone,
                        className: batchSliderMode ? styles.batchSliderList : ""
                    }}
                    rowConfiguration={{
                        basic: {
                            definesHeader: true,
                            cells: this.columns.basic,
                            presentation: presentations.basic,
                        },
                        event: {
                            definesHeader: false,
                            cells: this.columns.basic,
                            presentation: presentations.event,
                        },
                        batch_title: {
                            definesHeader: false,
                            cells: this.columns.basic,
                            presentation: presentations.batch_title,
                        },
                        first: {
                            definesHeader: false,
                            cells: this.columns.basic,
                            presentation: presentations.first
                        }
                    }}
                    rowHeight={34}
                    fluid={true}
                />
            </React.Fragment>
        );
    }
}

export default Log;
