import React, { Fragment } from 'react';

import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import TaimerComponent from '../../TaimerComponent';


const styles = theme => ({
  textItem: {
    fontSize: "12px",
  },
  root: {
    paddingLeft: 0
  },
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.primary.main,
      },
    },
  },
  primary: {},
  icon: {},
});



function MailProjectList(props) {
  const { projects = [] } = props;
  var trans = new TaimerComponent(props, null, "mail/MailComponents/MailProjectList");

  return (
    <List subheader={<ListSubheader disableSticky="true" component="div">{trans.tr("Projects")}</ListSubheader>}>
      
      {projects.map(({ id, name, customer_name, mails_amount}) => (
          
          <ListItem
            button 
            className={name}
            onClick={() => props.onClick(id, name)}
            >
            
            <ListItemText classes={{root: props.classes.root, primary: props.classes.textItem}} primary={name} secondary={customer_name} />
            {
              mails_amount > 0 ?
              <ListItemIcon>
                {mails_amount}
              </ListItemIcon> :
              undefined
            }

          </ListItem>
 
      ))}
    </List>
  );
}



MailProjectList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MailProjectList);