import React from 'react';
import colors from '../colors';
import TaimerComponent from '../TaimerComponent';

export interface InvoiceLogEvents {
    sentToNordea: string;
    sentToMaventa: string;
    invoiceReverted: string;
    invoiceDeleted: string;
    sentToNav: string;
    sentToProcountor: string;
    sentToEfina: string;
    sentToTalenom: string;
    sentToNetvisor: string;
    sentToRopocapital: string;
    sentToFennoa: string;
    sentToFortNox: string;
    sentToMeritaktiva: string;
    sentToXero: string;
    sentToS3SweFinance: string;
    sentToHeeros: string,
    sendingToHeeros: string;
    sentToFivaldi: string,
    sentToSAP: string,
    sentManually: string;
    sellingPriceDiffers: string;
    paymentReceived: string;
    invoiceCreated: string;
    invoiceErrorCleared: string;
}

export interface InvoiceLogContextType {
    eventTypes: InvoiceLogEvents;
    eventTypeNames: any;
    formatMessage: (message: string) => string;
    getEventDescription: (row: any) => string;
    getStatusData: (row: any) => { color: string; name: string };
    getStampFormat: (row: any) => string;
    getMessage: (row: any, currency: string) => string;
}

export const InvoiceLogContext = React.createContext<InvoiceLogContextType>({
    eventTypes: {
        sentToNordea: '41',
        sentToMaventa: '100',
        sentToEfina: '102',
        sentToTalenom: '103',
        sentToNetvisor: '104',
        sentToRopocapital: '105',
        sentToFennoa: '106',
        sentToFortNox: '107',
        sentToMeritaktiva: '108',
        sentToXero: '109',
        sentToS3SweFinance: '111',
        sentToHeeros: '112',
        sentToFivaldi: '113',
        sendingToHeeros: '114',
        sentToSAP: '115',
        sentManually: '110',
        invoiceReverted: '201',
        invoiceDeleted: '202',
        invoiceErrorCleared: '203',
        sentToNav: '666',
        sentToProcountor: 'procountor',
        sellingPriceDiffers: '666000',
        paymentReceived: '7007',
        invoiceCreated: '101',
    },
    eventTypeNames: {},
    formatMessage: (message) => '',
    getEventDescription: (row) => '',
    getStatusData: (row) => ({ name: '', color: '' }),
    getStampFormat: (row) => '',
    getMessage: (row, currency) => '',
});

class InvoiceLogProvider extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'invoices/InvoiceLogProvider');
    }

    eventTypes: InvoiceLogEvents = {
        sentToNordea: '41',
        sentToMaventa: '100',
        sentToEfina: '102',
        sentToTalenom: '103',
        sentToNetvisor: '104',
        sentToRopocapital: '105',
        sentToFennoa: '106',
        sentToFortNox: '107',
        sentToMeritaktiva: '108',
        sentToXero: '109',
        sentToS3SweFinance: '111',
        sentToHeeros: '112',
        sentToFivaldi: '113',
        sendingToHeeros: '114',
        sentToSAP: '115',
        sentManually: '110',
        invoiceReverted: '201',
        invoiceDeleted: '202',
        invoiceErrorCleared: '203',
        sentToNav: '666',
        sentToProcountor: 'procountor',
        sellingPriceDiffers: '666000',
        paymentReceived: '7007',
        invoiceCreated: '101',
    };
    eventTypeNames = {
        [this.eventTypes.sentToNordea]: this.tr('Sent to Nordea'),
        [this.eventTypes.sentToMaventa]: this.tr('Sent to Maventa'),
        [this.eventTypes.invoiceReverted]: this.tr('Invoice reverted'),
        [this.eventTypes.invoiceDeleted]: this.tr('Invoice deleted'),
        [this.eventTypes.invoiceErrorCleared]: this.tr('Invoice error cleared'),
        [this.eventTypes.sentToNav]: this.tr('Sent to Nav'),
        [this.eventTypes.sentToProcountor]: this.tr('Sent to Procountor'),
        [this.eventTypes.sentToEfina]: this.tr('Sent to Efina'),
        [this.eventTypes.sentToTalenom]: this.tr('Sent to Talenom'),
        [this.eventTypes.sentToNetvisor]: this.tr('Sent to Netvisor'),
        [this.eventTypes.sentToRopocapital]: this.tr('Sent to Ropocapital'),
        [this.eventTypes.sentToFennoa]: this.tr('Sent to Fennoa'),
        [this.eventTypes.sentToFortNox]: this.tr('Sent to FortNox'),
        [this.eventTypes.sentToMeritaktiva]: this.tr('Sent to Meritaktiva'),
        [this.eventTypes.sentToXero]: this.tr('Sent to Xero'),
        [this.eventTypes.sentToS3SweFinance]: this.tr('Sent JSON to S3 Swe Finance'),
        [this.eventTypes.sentToHeeros]: this.tr('Sent to Heeros'),
        [this.eventTypes.sendingToHeeros]: this.tr('Sending to Heeros'),
        [this.eventTypes.sentToFivaldi]: this.tr('Sent to Fivaldi'),
        [this.eventTypes.sentManually]: this.tr('Marked as sent manually'),
        [this.eventTypes.sellingPriceDiffers]: this.tr('Selling price total differs from invoice net total'),
        [this.eventTypes.paymentReceived]: this.tr('Payment received'),
        [this.eventTypes.invoiceCreated]: this.tr('Invoice created'),
        [this.eventTypes.sentToSAP]: this.tr('Sent to SAP'),
    };

    procountorReferenceNames = [
        'procountor', 
        'procountor_accounting', 
        'procountor_automatic_send',
        'procountor_accounting_and_send'
    ];

    getStatusData = (row) => {
        if (typeof row.message == 'string' && (row.message.includes('errors":[{') || row.message.includes('constraintViolations":[{'))) {
            return { color: colors.faded_red, name: this.tr('Error') };
        }
        switch (Number(row.result)) {
            case 1:
                return { color: colors.greenish_cyan, name: this.tr('Success') };
            case 2:
                return { color: colors.faded_red, name: this.tr('Error') };
            default:
                return { color: colors.dark_sky_blue, name: this.tr('Log') };
        }
    };

    getEventDescription = (row) => {
        const eventTypes = this.eventTypes;
        const eventTypeNames = this.eventTypeNames;
        if (this.procountorReferenceNames.find(r => r == row.reference)) {
            return eventTypeNames[eventTypes.sentToProcountor];
        }
        return eventTypeNames[String(row.eventcode)] || '-';
    };

    formatMessage = (message) => {
        return `${this.tr('Message')}: ${message}`;
    };

    getMessage = (row, currency = this.context.taimerAccount.currency) => {
        if ((this.procountorReferenceNames.find(r => r == row.reference)) && row.result == 2) {
            let errorMsg = row.translate_message == 1 ? this.tr(row.message) : row.message;
            if (typeof errorMsg == 'string' && errorMsg.includes('errors":[{')) {
                const errorObj = JSON.parse(errorMsg);
                errorMsg = errorObj.errors.map(c => c.translate_message == 1 ? this.tr(c.message) : c.message).join(", ");
            }
            if (row.reference == 'procountor_accounting') {
                errorMsg = this.tr("Accounting send failed: ${errors}", {errors: errorMsg});
            }
            if (row.reference == 'procountor_automatic_send') {
                errorMsg = this.tr("Sending to customer failed: ${errors}", {errors: errorMsg});
            }
            if (row.reference == 'procountor_accounting_and_send') {
                errorMsg = this.htmlTr("Accounting send failed: ${errors}. ${linebreak} Invoice was not sent to customer.", {errors: errorMsg, linebreak: [<br />, <br />]});
            }
            return errorMsg;
        }
        if (row.eventcode == this.eventTypes.sentToTalenom && typeof row.message == 'string' && row.message.includes('{"error_msg"')) {
            const errorObj = JSON.parse(row.message);
            let errorMsg = row.translate_message == 1 ? this.tr(errorObj.error_msg) : errorObj.error_msg;
            if (errorObj.original_message) {
                errorMsg += " (" + errorObj.original_message + ")";
            }
            return errorMsg;
        }

        switch (row.eventcode) {
            case this.eventTypes.invoiceCreated:
                return row.reference == 'procountor' ? '' : this.tr('Invoice ${invoiceId} created', { invoiceId: row.visible_id });
            case this.eventTypes.invoiceDeleted:
                return this.tr('Invoice ${invoiceId} deleted', { invoiceId: row.visible_id });
            case this.eventTypes.invoiceReverted:
                return this.tr('Invoice ${invoiceId} reverted', { invoiceId: row.visible_id });
            case this.eventTypes.paymentReceived:
                return this.tr('Payment of amount ${amount} received', { amount: this.context.functions.presentCurrency(row.payment_amount, row.currency_code || currency) });
            case this.eventTypes.invoiceErrorCleared:
                return this.tr('Invoice ${invoiceId} error cleared', { invoiceId: row.visible_id });
            default:
                return row.translate_message == 1 ? this.tr(row.message) : row.message;
        }
    };

    getStampFormat = (row) => {
        const {
            userObject: { dateFormat },
        } = this.context;
        const eventTypes: InvoiceLogEvents = this.eventTypes;
        switch (row.eventcode) {
            case eventTypes.paymentReceived:
            case eventTypes.invoiceCreated:
                return dateFormat;
            default:
                return `${dateFormat} HH:mm:ss`;
        }
    };

    render() {
        return (
            <InvoiceLogContext.Provider
                value={{
                    eventTypes: this.eventTypes,
                    eventTypeNames: this.eventTypeNames,
                    formatMessage: this.formatMessage,
                    getEventDescription: this.getEventDescription,
                    getStatusData: this.getStatusData,
                    getStampFormat: this.getStampFormat,
                    getMessage: this.getMessage,
                }}
            >
                {this.props.children}
            </InvoiceLogContext.Provider>
        );
    }
}

export default InvoiceLogProvider;
