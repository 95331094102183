import React from 'react';
import "./../general/MuiTabs.css";
import { SettingsContext } from './../SettingsContext';
import "./BillTargeting.css";

import TaimerComponent from "../TaimerComponent";
import TextInputCell from '../list/cells/TextInputCell';
import CurrencyListCell from '../list/CurrencyListCell';
import { formatInputNumber } from '../helpers';

import { Button, Tabs, Tab, MenuItem }  from '@mui/material';
import { ReactComponent as CostTargetingImage } from './images/cost_targeting.svg';

import isEqual from "lodash/isEqual";

class BillTargeting extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "projects/BillTargeting");

        this.state = {
            selectedTab: "quantity",
            headers: undefined
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state); 
    }

    setData = (data) => {
        if(data.headers && (this.state.headers === undefined || !isEqual(data.headers, this.state.headers))) {
            this.setState({ headers: data.headers });
        }
    }

    countSums = (h) => {
        const rows = h && h.rows.filter(r => r.deleted != 1) || [];
        let quantity = 0;
        let quantity_delivered = 0;
        let quantity_invoiced = 0;
        if (rows.length > 0) {
            quantity = rows.map(r => parseFloat(r.quantity !== undefined ? r.quantity : 0)).reduce((acc, cur) => acc + cur);
            quantity_delivered = rows.map(r => parseFloat(r.quantity_delivered !== undefined ? r.quantity_delivered : 0)).reduce((acc, cur) => acc + cur);
            quantity_invoiced = rows.map(r => parseFloat(r.quantity_invoiced !== undefined ? r.quantity_invoiced : 0)).reduce((acc, cur) => acc + cur);
        }

        return (
            <React.Fragment>
                <div className="c1" style={{ justifyContent: "center" }}>
                    {rows.length > 0 && formatInputNumber(quantity)}
                </div>
                <div className="c2" style={{ justifyContent: "center" }}>
                    {rows.length > 0 && formatInputNumber(quantity_delivered)}
                </div>
                <div className="c3" style={{ justifyContent: "center" }}>
                    {rows.length > 0 && formatInputNumber(quantity_invoiced)}
                </div>
            </React.Fragment>
        );
    }

    // (Y)
    countTotals = (data) => {
        let unitSum = 0;
        let sumTotal = 0;
        let salesTotal = 0;
        let targetSum = 0;
        let targetWithoutOwnWorkSum = 0;
        let marginTotal = 0;
        
	    if(data && data.headers) {
            data.headers.forEach(h => {
                if(h.has_automatic_targeting > 0 || h.targeted > 0) {
                    targetSum += h.targeted - 0;
                    targetWithoutOwnWorkSum += h.targeted - 0;
                }
            	h.rows.forEach(r => {
                    unitSum += r.cost * r.quantity;
                    sumTotal += r.value * r.quantity;
            	    salesTotal += (r.value * r.quantity - (r.quantity * r.value * r.discountPercentage / 100)) - (r.cost * r.quantity);
                    targetSum += r.targeted - 0;
                    targetWithoutOwnWorkSum += r.workType != 1 ? r.targeted - 0 : 0;
                    marginTotal += (r.value * r.quantity) - (r.workType != 1 ? r.cost * r.quantity : 0);
	            })
            })
	    }
        
        //let invoiced = this.props.project.invoicing && this.props.project.invoicing.invoiced ? this.props.project.invoicing.invoiced : 0;

        if (isNaN(unitSum)) unitSum = 0;
        if (isNaN(sumTotal)) unitSum = 0;
        if (isNaN(salesTotal)) salesTotal = 0;
        if (isNaN(targetSum)) targetSum = 0;
        if (isNaN(targetWithoutOwnWorkSum)) targetWithoutOwnWorkSum = 0;
        if (isNaN(marginTotal)) marginTotal = 0;

        return {
            costs: {
                cost: unitSum,
                target: targetSum,
                margin: ((targetSum / unitSum) * 100).toFixed(2)
            },
            profits: {
                budgeted: sumTotal - unitSum,
                actual: sumTotal - targetSum,
                margin: (((sumTotal - targetSum) / (sumTotal - unitSum)) * 100).toFixed(2)
            },
            gross: {
                budgeted: marginTotal,
                actual: sumTotal - targetWithoutOwnWorkSum,
                margin: ((sumTotal - targetWithoutOwnWorkSum) / marginTotal * 100).toFixed(2)
            }
        };
    }

    render = () => {
        if(!this.state.headers) {
            return null; 
        }

        const totals             = this.countTotals({ headers: this.state.headers });
        const { data }           = this.props;
        const quoteId            = data.id;
        const { rowDataChanged, showTabs } = this.props;
        
        

        return (
            <React.Fragment>
                <div className="box targeting-info">
                    {/* Don't remove; will be used in the future.*/}
                    {/*<div className="muiTabContainer" id="quantityOrValueTabs">
                        <Tabs color="primary" onChange={(evt, selectedTab) => this.setState({ selectedTab })}>
                            <Tab value="quantity" className={`tabbutton left ${this.state.selectedTab === "quantity" ? "selected" : ""}`} label={this.tr("Quantity")} />
                            <Tab value="value" className={`tabbutton right ${this.state.selectedTab === "value" ? "selected" : ""}`} label={this.tr("Value")} />
                        </Tabs>
                    </div>*/}
                </div>
                <div className="box" id="billTargetingBox" style={{/*marginTop: this.props.offset - 764 + "px", transformOrigin: "top left", transform: `scale(${this.props.scale})`*/ width: "100%", position: "absolute", marginTop: "523px"}}>
                    <h1>&nbsp;</h1>
                    <div className="list targets">
                        <div className="list-header">
                            <div className="c1" style={{ textAlign: "center" }}>{this.tr('Sold')}</div>
                            <div className="c2" style={{ textAlign: "center" }}>{this.tr('Delivered')}</div>
                            <div className="c3" style={{ textAlign: "center" }}>{this.tr('Invoiced')}</div>
                        </div>
                        <div className="content">
                            {this.state.headers && this.state.headers.map(h => {
                                return (
                                    <React.Fragment>
                                    <div className="row">
                                    {
                                        <React.Fragment>
                                            <div className="c1"></div>
                                            <div className="c2"></div>
                                            {/*<div className="c3"></div>*/}
                                        </React.Fragment>
                                    }
                                    </div>
                                    {h.rows.filter(r => r.deleted != 1).map(r => (
                                        <div className="row">
                                            {((r.type == 1) || (r.type == 3) || (r.type == 4)) && (
                                                <React.Fragment>
                                                    <div className="c1" style={{ justifyContent: "center" }}>
                                                        <div>{formatInputNumber(r.quantity || 0)}</div>
                                                    </div>
                                                    <div className="c2" style={{ justifyContent: "center" }}>
                                                        <TextInputCell
                                                            name="quantity_delivered"
                                                            useClickAwayListener={false}
                                                            value={formatInputNumber(r.quantity_delivered || 0)}
                                                            validation={["numeric"]}
                                                            editable={this.props.editMode}
                                                            textAlign="center"
                                                            listCellProps={{
                                                                inEditMode: this.props.editMode,
                                                                noInitFocus: true,
                                                                style: {
                                                                    justifyContent: "center"
                                                                }
                                                            }}
                                                            textInputProps={{
                                                                style: { background: "#f3f3f3" }
                                                            }}
                                                            onEdited={(name, value) => {
                                                                rowDataChanged({ id: r.id, [name]: value });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={`c3 ${r._type !== "productRow" && 'hidden'}`} style={{ justifyContent: "center" }}>
                                                        <div>{formatInputNumber(r.quantity_invoiced || 0)}</div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    ))}
                                    <div className="row total">
                                        {this.countSums(h)}
                                    </div>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

BillTargeting.defaultProps = {
    data: []
}

export default BillTargeting;
