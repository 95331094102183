import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter
} from "@mui/material";
import InsightBlock from "../../InsightBlock";
import { formatInputNumber, hexColorWithOpacity, stringToColour } from "../../../../helpers";
import TaimerComponent from "../../../../TaimerComponent";
import { abbreviateNumber, getBarTotalFromContext } from "../../StackedCurrencyChart";
import _ from 'lodash';
import customTooltips from '../../../../general/ChartTooltip';
import colors from "../../../../colors";

const barColors = ["#f7548f", "#2d9ff7", "#c88ffb", "#4c4ba8", colors.greenish_cyan];

const convertToBarDataObject = (obj, color, hidden = false) => {
  return {
    key: obj.id,
    data: obj.values,
    worktask: obj.id,
    label: obj.name,
    slider_rows: obj.slider_rows,
    backgroundColor: hexColorWithOpacity(color),
    hidden,
    borderColor: color
  };
};

class HoursByJobtype extends TaimerComponent {

  constructor(props, context) {
    super(props, context, "dashboard/insights/hours/HoursByJobtype");

    this.sliderColumns = ["account", "project", "tracked", "split"];
    this.columns = [
      {
        label: "Category",
        width: 250,
        footerLabel: (tr) => tr("Total")
      },
      {
        label: "Hours",
        width: 100,
        footerLabel: () => Number(this._getTotalSum()).toFixed(2) + " h"
      },
      {
        label: "Split",
        width: 100,
        footerLabel: () => "100%"
      }
    ];

    const { data } = props;
    this.state = {
      chartData: this._parseBarData(data),
      hiddenIndexes: [],
      sliderData: {
        open: false,
        data: [],
        label: ""
      },
    };
  }

  componentDidUpdate = (oldProps, oldState) => {
    if (oldProps.data !== this.props.data || oldState.hiddenIndexes !== this.state.hiddenIndexes) {
      this.setState({ chartData: this._parseBarData(this.props.data) });
    }
  }

  componentDidMount = () => {
    super.componentDidMount();
  }

  componentWillUnmount = () => {
    super.componentWillUnmount();
  }

  _parseBarData = data => {
    const { months } = this.props;
    const { hiddenIndexes = [] } = this.state || {};

    const addedLabels = {};

    function label(name) {
      if (addedLabels[name]) {
        addedLabels[name] += 1;
        return `${name} (${addedLabels[name]})`;
      } 

      addedLabels[name] = 1;
      return name;
    }

    const sets = data.map(o => ({
      ...o,
      name: label(o.name || ""),
    }));

    return {
      labels: months,
      datasets: sets.map((obj, i) =>
        convertToBarDataObject(obj, barColors[i] || stringToColour(obj.name || ""), hiddenIndexes.indexOf(i) != -1)
      )
    };
  };

  _toggleSlider = sliderData => {
    const { showSlider } = this.props;

    showSlider(sliderData);
  };

  _getTotalSum = () => {
    const { chartData: { datasets }, hiddenIndexes } = this.state;
    return datasets.filter((_, i) => hiddenIndexes.indexOf(i) == -1).reduce(
      (prev, val) =>
        prev + val.data.reduce((subPrev, subVal) => subPrev + subVal, 0),
      0
    );
  };

  _toggleHidden = index => {
    const hiddenIndexes = [...this.state.hiddenIndexes];
    const itemIndex = hiddenIndexes.indexOf(index);
    itemIndex == -1
      ? hiddenIndexes.push(index)
      : hiddenIndexes.splice(itemIndex, 1);
    this.setState({ hiddenIndexes });
  };

  _renderGraph = () => {
    const { chartData, chartData: { datasets }, hiddenIndexes } = this.state;

    return (
      <div className="graph">
        <Bar
          datasetKeyProvider={set => set.key}
          options={{
            responsive: true,
            layout: {
              padding: {
                top: 25,
              }
            },
            plugins: {
              legend: {
                display: datasets.length < 25,
                position: "bottom",
                onClick: (e, legendItem) => {
                  const { datasetIndex } = legendItem;
                  this._toggleHidden(datasetIndex);
                },
                labels: {
                  boxWidth: 15,
                  padding: 20,
                  fontSize: 12,
                  usePointStyle: true,
                  generateLabels: () => {
                    return datasets.map((obj, i) => {
                      return {
                        text: obj.label,
                        datasetIndex: i,
                        pointStyle: "rectRounded",
                        fillStyle: obj.borderColor,
                        lineCap: "round",
                        lineJoin: "round",
                        strokeStyle: "#FFF",
                        lineWidth: 5,
                        hidden: hiddenIndexes.indexOf(i) != -1
                      };
                    });
                  }
                }
              },
              tooltips: {
                enabled: false,
                custom: customTooltips,
                callbacks: {
                  labelColor: tooltipItem => {
                    return {
                      backgroundColor:
                        datasets[tooltipItem.datasetIndex].borderColor
                    };
                  },
                  label: (tooltipItem) => {
                    const dataset = tooltipItem.dataset;
                    let label = _.escape(dataset.label) || "";

                    if (label) {
                      label += ": ";
                    }
                    label += formatInputNumber(tooltipItem.raw, 'hours') + " h";
                    return "  " + label;
                  }
                },
                mode: datasets.length < 25 ? "x" : "single",
                titleFontSize: 12,
                titleFontStyle: "bold",
                titleMarginBottom: 15,
                bodyFontSize: 11,
                bodyFontColor: "rgba(255,255,255,0.7)",
                bodySpacing: 8,
                caretSize: 0,
                xPadding: 20,
                yPadding: 20,
                intersect: false
              },
              datalabels: {
                color: "#34546b",
                backgroundColor: "#f9f9f9",
                borderRadius: 5,
                font: {
                  size: 10,
                },
                clamp: true,
                anchor: "end",
                align: "end",
                offset: 6,
                display: (context) => {
                  const total = getBarTotalFromContext(context);
                  return (total !== 0 && context.datasetIndex === (context.chart.data.datasets || []).length - 1 ? "auto" : false);
                },
                formatter: (_, context) => {
                  const total = getBarTotalFromContext(context);
                  const formatted = abbreviateNumber(total);
                  return formatted;
                },
              },
            },
            maintainAspectRatio: false,
            scales: {
              y:
              {
                stacked: true,
                grid: {
                  color: "rgba(0,0,0,0.04)",
                  zeroLineColor: "rgba(0,0,0,0.04)",
                  drawBorder: false
                },
                type: "linear",
                position: "left",
                drawBorder: false,
                beginAtZero: true,
                ticks: {
                  callback: (value, index, values) => {
                    return `${value} h`;
                  },
                  font: {
                    size: 12,
                    weight: 'bold'
                  },
                  padding: 15
                }
              },
              x: {
                offset: true,
                ticks: {
                  padding: 10,
                  font: {
                    size: 12,
                  },
                },
                stacked: true,
                grid: { display: false, drawBorder: false }
              }
            }
          }}
          width={"100%"}
          data={chartData}
        />
      </div>
    );
  };

  _renderTableRows = () => {
    const { chartData: { datasets }, hiddenIndexes } = this.state;
    const { emptyMessage } = this.props;
    const totalSum = this._getTotalSum();

    if (datasets.length == 0)
      return <TableBody>
        <TableRow>
          <TableCell align="center" colSpan="3" style={{ minWidth: 271 }}>{emptyMessage}</TableCell>
        </TableRow>
      </TableBody>

    return (
      <TableBody>
        {datasets.map((data, i) => {
          if (hiddenIndexes.indexOf(i) != -1) {
            return null;
          }
          const amount = data.data.reduce((prev, val) => prev + val, 0);
          const percent = totalSum != 0 ? amount / totalSum : 0;
          return (
            <TableRow
              key={i}
              onClick={() =>
                this._toggleSlider(data)
              }
              className="row highlightable"
            >
              <TableCell align="left">
                <div className="wide-cell-container">
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 4,
                      marginRight: 10,
                      backgroundColor: data.borderColor
                    }}
                  />
                  {data.label}
                </div>
              </TableCell>
              <TableCell align="right">{amount.toFixed(2)} h</TableCell>
              <TableCell align="right">{Math.round(percent * 100)}%</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  _renderTable = () => {
    const { tr } = this;
    const headerCells = this.columns.map((obj, index) => {
      return (
        <TableCell
          key={"h" + index}
          align={index == 0 ? "left" : "right"}
          className="title"
        >
          {tr(obj.label)}
        </TableCell>
      );
    });
    const footerCells = this.columns.map((obj, index) => {
      return (
        <TableCell
          key={"f" + index}
          align={index == 0 ? "left" : "right"}
          className="footer"
        >
          {obj.footerLabel(tr)}
        </TableCell>
      );
    });
    return (
      <div className="list constrained-height">
        <Table className="summary">
          <TableHead>
            <TableRow>{headerCells}</TableRow>
          </TableHead>
          {this._renderTableRows()}
          <TableFooter>
            <TableRow>{footerCells}</TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  };

  render() {
    const { tr } = this;
    const { currency, sliderRows } = this.props;
    const { sliderData } = this.state;
    return (
      <div className="full-width">
        <InsightBlock
          title={tr("Hours Tracked by Jobtype")}
          height={200}
          width={6}
        >
          <div className="graph-block">
            {this._renderGraph()}
            {this._renderTable()}
          </div>
        </InsightBlock>
      </div>
    );
  }
}

export default HoursByJobtype;
