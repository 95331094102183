import React from "react";
import ListCell from "./ListCell";
import { SettingsContext } from '../SettingsContext';

class PercentageListCell extends ListCell {
	static contextType = SettingsContext;

	presentValue(value) {
		return `${value} %`;
	}
}

export default PercentageListCell;