// Material UI
import { Badge, Tooltip } from '@mui/material';

// Local components
import TaimerComponent from '../TaimerComponent';

// Styles
import './AccountingButton.css';

export interface AccountingButtonProps {
    dimensionHeadersDiffer?: boolean;
    editable?: boolean;
    text: string;
    icon?: any;
    classname?: string;
    openAccountingSlider: () => void;
}

class AccountingButton extends TaimerComponent<AccountingButtonProps> {
    constructor(props, context) {
        super(props, context, 'invoices/AccountingButton');
    }

    render() {
        const { dimensionHeadersDiffer, editable, openAccountingSlider, text, icon, classname } = this.props;
        const Icon = icon;

        return (
            <div className={classname ? classname : ""}>
                <div className={"invoice-accounting-button"}>
                    <div className={"accounting-button"} onClick={() => openAccountingSlider()} data-testid="show_accounting_button">
                        {Icon && <Icon />}<span className="accounting-button-text">{text}</span>
                    </div>
                    {dimensionHeadersDiffer && editable &&
                        <Tooltip placement="top" title={this.tr("Dimensions differ from settings. Dimensions can be updated from accounting slider.")}>
                            <Badge badgeContent="!" className="notificationBadge" />
                        </Tooltip>
                    }
                </div>
            </div>
        );
    }
}

export default AccountingButton;