import React from 'react';
import TaimerComponent from '../TaimerComponent';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import FieldEditSlider, { EditableField, FieldEditSliderProps } from './FieldEditSlider';
import DataHandler from './DataHandler';
import _, { cloneDeep } from 'lodash';
import { languages } from 'country-data';
import AddTag from './no-options/AddTag';
import { AddAccount, AddProject } from './no-options/AddItemComponents';
import EntityCreatedSnackbar from './EntityCreatedSnackbar';

interface Props extends WithSnackbarProps, FieldEditSliderProps {
    initialSelectionProps?: any;
    onContactCreated?: (contact) => void;
}

interface State {
    contact: any;
    open: boolean;
    contactSettings: any;
    companies: any[];
    positions: any[];
    users: any[];
    accounts: any[];
    projects: any[];
    tags: any[];
    mailingLists: any[];
    titles: any[];
    saving?: boolean;
}

class AddContactSlider extends TaimerComponent<Props, State> {
    languageOptions: any;
    contactTypes: any;
    constructor(props, context) {
        super(props, context, 'general/AddContactSlider');

        const taimerLangs = [
            'fin',
            'swe',
            'nor',
            'dan',
            'icl',
            'est',
            'lit',
            'lav',
            'eng',
            'deu',
            'ita',
            'fra',
            'spa',
            'por',
            'rus',
            'pol',
            'bul',
            'hrv',
            'ces',
            'ell',
            'hun',
            'gle',
            'mlt',
            'ron',
            'slk',
            'slv',
            'zho',
            'jpn',
            'nld',
            'ara',
            'hin',
            'pan',
            'ind',
        ];

        this.contactTypes = [
            {
                id: '1',
                value: '1',
                label: this.tr('Contact'),
                name: this.tr('Contact'),
            },
            {
                id: '2',
                value: '2',
                label: this.tr('Partner'),
                name: this.tr('Partner'),
            },
        ];

        this.languageOptions = languages.all
            .filter((l) => taimerLangs.indexOf(l.alpha3) > -1)
            .filter((ln) => ['Panjabi', 'Flemish', 'Castilian', 'Moldavian'].indexOf(ln.name) == -1)
            .map((language) => {
                if (language.alpha3 === 'ell') return { id: language.alpha3, value: language.alpha3, name: this.tr('Greek'), label: this.tr('Greek') };
                else return { id: language.alpha3, value: language.alpha3, name: this.tr(language.name), label: this.tr(language.name) };
            });

        this.state = {
            contact: {
                contact_owner: this.context.userObject.usersId,
                ...this.props.initialSelectionProps,
                companies_id: this.props.initialSelectionProps?.companies_id || this.context.userObject.companies_id,
                contact_type: this.props.initialSelectionProps?.contact_type || '1',
            },
            open: false,
            contactSettings: {},
            companies: [],
            positions: [],
            accounts: [],
            projects: [],
            users: [],
            tags: [],
            mailingLists: [],
            titles: []
        };
    }

    componentDidMount = () => {
        if (this.props.open) {
            this.setState({ open: true });
        }
    };

    componentDidUpdate = (_, oldState) => {
        if (oldState.open != this.state.open && this.state.open) {
            this.getCompanies();
            this.getAutoCompleteData();
            this.getContactSettings();
            this.getProjects(this.state.contact.customers_id);
            this.getTags();
        }
        if (oldState.contact.companies_id != this.state.contact.companies_id) {
            this.getAutoCompleteData();
            this.getContactSettings();
            this.getTags();
        }
    };

    getProjects = async (customerId) => {
        const { contact } = this.state;
        if (!customerId) return;
        const projects = await DataHandler.get({ url: 'projects/dropdown_new', customerId, company: contact.companies_id });
        this.setState({ projects: projects.map((p) => ({ ...p, label: p.project_label, value: p.id })) });
    };

    getTags = async () => {
        const { contact } = this.state;
        const { tags } = await DataHandler.get({ url: `tags/${contact.companies_id}`, type: 'contact' });
        this.setState({ tags: tags.map((t) => ({ ...t, value: t.id })) });
    };

    getCompanies = async () => {
        const companies = await DataHandler.get({ url: `subjects/companies/persons/read` });
        const companies_id = (companies.find((c) => c.id == this.state.contact.companies_id) || companies[0])?.id;
        this.setState({
            contact: {
                ...this.state.contact,
                companies_id,
            },
            companies: (companies || []).map((c) => ({ ...c, label: c.name, value: c.id })),
        });
    };

    getAutoCompleteData = async () => {
        const { contact } = this.state;
        const data = await DataHandler.get({ url: `autocomplete_data/contacts/${contact.companies_id}` });
        const accounts = data.customers || [];
        const positions = data.positions || [];
        const users = data.users?.map(u => ({...u, label: `${u.label} ${u.companies_id < 1 ? ` (${this.tr("freelancer")})` : ''}${u.locked > 0 ? ` (${this.tr("locked")})` : ''}`})) || [];
        const mailingLists = data.mailing_list;

        this.setState({ 
            accounts, 
            positions, 
            users, 
            mailingLists: data.all_mailing_lists,
            titles: data.titles
        });
    };

    getContactSettings = async () => {
        const { contact } = this.state;
        const contactSettings = await DataHandler.get({ url: `settings/company/${contact.companies_id}/contact_settings` });
        this.setState({ contactSettings });
    };

    onClose = () => {
        this.setState({ open: false }, () => {
            setTimeout(() => {
                this.props.onClose && this.props.onClose();
            }, 500);
        });
    };

    onSave = async (contact) => {
        this.setState({ saving: true }, async () => {
            const { enqueueSnackbar, closeSnackbar } = this.props;
            const creatingSnackbar: any = enqueueSnackbar(this.tr('Creating contact...'), {
                variant: 'info',
                persist: true,
            });
            const data = {
                ...contact,
                on_mailing_lists: (contact.on_mailing_lists || []).map((m) => m.id),
                projects: (contact.projects || []).map((p) => p.id),
                contact_history: [
                    {
                        customers_id: contact.customers_id,
                        customer_partner: contact.contact_type,
                        companies_id: contact.companies_id,
                    },
                ],
            };
            delete data.customers_id;
            if (!this.props.onContactCreated) this.onClose();
            try {
                const response = await DataHandler.post({ url: `contacts_new/-1` }, data);
                closeSnackbar(creatingSnackbar);
                const viewProps = this.context.functions.getViewProps();
                const moveToContactCard =
                    (viewProps.module == 'contacts' && viewProps.action == 'main' && viewProps.selectedTab == 'contacts') || (viewProps.module == 'contact' && viewProps.action == 'view');
                const id = response.id;
                const createdContact = {
                    ...data,
                    id: response.id,
                };
                this.context.functions.sendMixpanelEvent('create_contact', {
                    'origin_point': this.props.initialSelectionProps?.origin_point,
                });
                this.context.functions.sendMixpanelPeople('set_once', {
                    'first_create_contact_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('set', {
                    'last_create_contact_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('increment', {
                    'lifetime_create_contact': 1,
                });
                if (this.props.onContactCreated) {
                    this.props.onContactCreated(createdContact);
                    this.onClose();
                    return;
                }
                const createdSnackbar: any = enqueueSnackbar(
                    <EntityCreatedSnackbar
                        type="contact"
                        id={id}
                        onClose={() => closeSnackbar(createdSnackbar)}
                        url={!moveToContactCard ? { module: 'contact', action: 'view', id: response.id, companies_id: data.companies_id } : undefined}
                    />,
                    {
                        variant: 'default',
                        autoHideDuration: 5000,
                        className: 'entityCreatedSnackbar',
                    }
                );
                if (moveToContactCard) {
                    this.context.functions.updateView({ module: 'contact', action: 'view', id: response.id, companies_id: data.companies_id });
                }
                setTimeout(() => {
                    window.dispatchEvent(new Event('contactCreated'));
                }, 1000);
            } catch (e) {
                console.error(e);
                closeSnackbar(creatingSnackbar);
                this.setState({ saving: false });
                enqueueSnackbar(this.tr('Creating contact failed!'), {
                    variant: 'error',
                });
            }
        });
    };

    onItemChanged = (contact) => this.setState({ contact });

    getFields = () => {
        const { 
            contact,
            contactSettings, 
            positions, 
            accounts, 
            users, 
            companies, 
            tags, 
            mailingLists, 
            projects,
            titles
        } = this.state;
        const {
            functions: { checkPrivilege },
            taimerAccount: { isMulticompany },
        } = this.context;
        const fields: EditableField[] = [
            {
                key: 'companies_id',
                title: this.tr('Company'),
                type: 'data_select',
                options: companies,
                isHidden: () => !isMulticompany,
                required: true,
            },
            {
                key: 'contact_type',
                title: this.tr('Contact type'),
                type: 'data_select',
                options: this.contactTypes,
                required: true,
            },
            {
                key: 'customers_id',
                title: this.tr('Account'),
                options: accounts.filter(x => x.deleted != 1),
                type: 'data_select',
                noOptions: AddAccount,
                onItemCreated: (account) => {
                    const accounts = cloneDeep(this.state.accounts);
                    accounts.unshift(account);
                    this.setState({
                        accounts,
                        contact: {
                            ...this.state.contact,
                            customers_id: account.id,
                        },
                        projects: [],
                    });
                },
                setOtherValuesWithSelection: (item, value) => {
                    this.getProjects(value);
                    return { projects: [] };
                },
                additionalProps: {
                    companies_id: this.state.contact.companies_id,
                },
            },
            {
                key: 'firstname',
                required: true,
                title: this.tr('First name'),
            },
            {
                key: 'lastname',
                required: true,
                title: this.tr('Last name'),
            },
            {
                key: 'email',
                required: contactSettings.contacts_force_email == 1,
                title: this.tr('Email'),
                validation: 'email',
            },
            {
                key: 'phone',
                required: contactSettings.contacts_force_phone == 1,
                title: this.tr('Phone'),
                validation: 'phone',
            },
            {
                key: 'title',
                type: 'text',
                required: contactSettings.contacts_force_title == 1,
                title: this.tr('Title'),
            },
            {
                key: 'vacance',
                required: contactSettings.contacts_force_position == 1,
                title: this.tr('Position'),
                options: positions,
                type: 'data_select',
            },
            {
                key: 'lang',
                required: contactSettings.contacts_force_language == 1,
                title: this.tr('Contact language'),
                options: this.languageOptions,
                type: 'data_select',
            },
            {
                key: 'contact_owner',
                required: contactSettings.contacts_force_owner == 1,
                title: this.tr('Contact owner'),
                options: users,
                type: 'data_select',
                isHidden: () => !checkPrivilege('persons', 'contact_owner_read'),
            },
            {
                key: 'tags',
                title: this.tr('Tags'),
                type: 'select',
                isMulti: true,
                options: tags,
                noOptions: AddTag,
                additionalProps: {
                    company: this.state.contact.companies_id,
                    tagType: 3
                },
            },
            {
                key: 'on_mailing_lists',
                title: this.tr('Mailing lists'),
                type: 'select',
                isMulti: true,
                options: mailingLists.filter(ml => {
                    return parseInt(ml.companies_id) === parseInt(contact.companies_id);
                }),
                hideIfEmpty: true
            },
            {
                key: 'projects',
                title: this.tr('Projects'),
                type: 'select',
                isMulti: true,
                options: projects,
                isHidden: (item) => !item.customers_id,
                noOptions: AddProject,
                additionalProps: {
                    companies_id: this.state.contact.companies_id,
                    customers_id: this.state.contact.customers_id,
                },
                onItemCreated: (project) => {
                    const projects = cloneDeep(this.state.projects);
                    accounts.unshift(project);
                    const contactProjects = cloneDeep(this.state.contact.projects || []);
                    contactProjects.push(project);
                    this.setState({
                        projects,
                        contact: {
                            ...this.state.contact,
                            projects: contactProjects,
                        },
                    });
                },
            },
        ];

        return fields;
    };

    render() {
        const { contact, open, saving } = this.state;
        return (
            <FieldEditSlider
                open={open}
                saving={saving}
                onClose={this.onClose}
                title={this.tr('Add contact')}
                onSave={this.onSave}
                onItemChanged={this.onItemChanged}
                item={contact}
                fields={this.getFields()}
            />
        );
    }
}

export default withSnackbar(AddContactSlider);
