
/* material-ui */
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';

/* others */
import { format } from "date-fns";
import { SettingsContext } from '../../../../src/SettingsContext';
import TextAreaCell from "../../../list/cells/TextAreaCell";
import LinkListCell from '../../../list/LinkListCell';
import ListCell from '../../../list/ListCell';
import PropsOnlyListRow from "../../../list/PropsOnlyListRow";
import ContextMenu from './../../../general/ContextMenu';
import TaimerAvatar from './../../../general/TaimerAvatar';

import EditIcon from "@mui/icons-material/Edit";
import { withSnackbar } from 'notistack';
import colors from '../../../colors';
import { ReactComponent as RemoveIcon } from '../../../general/icons/remove.svg';
import StatusTag from '../../../general/StatusTag';
import { ReactComponent as ActivateIcon } from './icons/Activate.svg';
import { ReactComponent as DoneIcon } from './icons/Done.svg';

class ActivitiesInsightListRow extends PropsOnlyListRow {

    static contextType = SettingsContext;

    constructor(props) {
        super(props, null, undefined, "dashboard/insights/activities/ActivitiesInsightListRow");
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
          super.shouldComponentUpdate(nextProps, nextState) ||
          this.state.emptyDescription != nextState.emptyDescription ||
          this.props.data != nextProps.data
        );
      }

    validateDescription(name, value) {
        if (value.trim() === "") 
            this.showEmptyError();
        else 
            this.props.rowProps.onCellEdited(this.props.data.id, this.props.data.type, name, value)
    }

    showEmptyError() {
        this.setState({ emptyDescription: true });
        this.props.enqueueSnackbar(this.tr('Description cannot be empty!'), {
            variant: 'error'
        });
    }

    defineClassName() {
        return "activitiesListRow";
    }

    _getProjectCellContent = (data) => {
        const { module } = this.props.rowProps;
        let projectLinkClass = "top blue-highlight";
        let accountLinkClass = "bottom clickable blue-highlight";
        let accountDisabled = false;
        let projectDisabled = false;
        if (!data.project || module == "projects") {
            projectLinkClass += " disabled";
            projectDisabled = true;
        }
        if (!data.customer_id || module == "customers") {
            accountLinkClass += " disabled";
            accountDisabled = true;
        }
    
        return (
          <div className="text-container">
            <a href={`index.html?module=projects&action=view&id=${data.projects_id}`}
              title={data.project || "-"}
              onClick={(e) => {
                !e.ctrlKey && e.preventDefault();
                !projectDisabled && this.context.functions.updateView({module: 'projects', action: 'view', id:data.projects_id});
              }
              }
              className={projectLinkClass}
            >
              {data.project || "–"}
            </a>
            <a href={`index.html?module=customers&action=view&id=${data.customer_id}`}
              onClick={(e) =>{
                !e.ctrlKey && e.preventDefault();
                !accountDisabled && this.context.functions.updateView({module: 'customers', action: 'view', id:data.customer_id});
              }}
              title={data.customer || "-"}
              className={accountLinkClass}
            >
              {data.customer || "–"}
            </a>
          </div>
        );
      };

    defineCells() {
        const { userObject } = this.context;
        const dateFormat = userObject.dateFormat;
        const { columnOrder, columnWidthMap, data, classes } = this.props;
        const { module, showLockedUsersWithTag } = this.props.rowProps;
        // const props = new Proxy({}, {
        //     get: (obj, prop) => 
        // });

        const { tr } = this;

        const targeted_to_locked = `${data.targeted_to}${data.targeted_to_locked > 0 ? ` (${tr('locked')})` : ''}`;
        const created_by_locked = `${data.created_by}${data.created_by_locked > 0 ? ` (${tr('locked')})` : ''}`;

        const cells = {
            context: 
                data.write ? <ContextMenu data-testid="actions_with_activity" label={<MoreHoriz />}  buttonProps={{className: 'action-menu'}} className="cell row-menu" style={{width: this.props.columnWidthMap['context'] + "px", textAlign: "center"}} noExpandIcon>
                    {data.status === "1" ? 
                    <MenuItem onClick={() => this.props.rowProps.onMarkUndone(data)}><ActivateIcon title="" /> {tr("Activate")}</MenuItem> : <MenuItem onClick={() => this.props.rowProps.onMarkDone(data)}><DoneIcon title="" /> {tr("Done")}</MenuItem>}
                    <MenuItem data-testid="edit_activity" onClick = {() => this.props.rowProps.onActivityDialog(data)} >
                        <EditIcon title="" /> {this.tr("Edit")}
                    </MenuItem>
                    <MenuItem className="delete" onClick={() => this.props.rowProps.onActivityDelete(data)}>
                    <RemoveIcon className="Delete" title="" /> {tr("Delete")}
                    </MenuItem>
                </ContextMenu> : <ListCell editable={false} width={columnWidthMap.context} />,
            type: (
                <ListCell onlyDisplay editable={false} width={columnWidthMap.type}>
                    <div className="type-icon">
                        {data.icon && <img src={data.icon} className="type" alt={data.type} />}
                    </div>
                </ListCell>
            ),
            status: 
                <ListCell onlyDisplay editable={false} width={columnWidthMap.status}>
                    {
                        data.status === "1" ? <StatusTag text={tr("Done")} color={colors.greenish_cyan} /> :
                        data.status === "2" ? <StatusTag text={tr("Due")} color={colors.orangey_yellow} /> :
                        data.status === "3" ? <StatusTag text={tr("Overdue")} color={colors.carnation_pink} /> : undefined
                    }
                </ListCell>,
            created:
                <ListCell onlyDisplay editable={false} width={columnWidthMap.activity_date}>
                    <span>{isNaN(Date.parse(data.activity_date)) ? "" : format(data.activity_date, dateFormat.toUpperCase())}</span>
                </ListCell>,
            reminder:
                <ListCell onlyDisplay editable={false} width={columnWidthMap.reminder}>
                    <span>{isNaN(Date.parse(data.date)) ? "" : format(data.date, dateFormat.toUpperCase())}</span>
                </ListCell>,
            customer:
                <LinkListCell
                    listCellProps={{
                        title: data.customer || ""
                    }}
                    title={data.customer || ""}
                    urlHandler={value => `index.html?module=customers&action=view&id=${data.customer_id}`}
                    width={columnWidthMap.customer}
                    value={data.customer || ""}
                    editable={false}
                    asText={module == "customers"}
                    noTab={true} />,
            project:
                <LinkListCell
                    listCellProps={{
                        title: data.project || ""
                    }}
                    title={data.project || ""}
                    urlHandler={value => `index.html?module=projects&action=view&id=${data.projects_id}`}
                    width={columnWidthMap.project}
                    value={data.project || ""}
                    editable={false}
                    asText={module == "projects"}
                    noTab={true} />,
            description: (
                <TextAreaCell
                    name="description"
                    width={columnWidthMap.description}
                    listCellProps={{
                        className: `activity-description-cell ${this.state.emptyDescription ? "activity-description-error-cell" : ""}`,
                        inEditMode: this.state.emptyDescription,
                        title: data.description
                    }}
                    onEdited={(name, value) => { this.setState({ emptyDescription: false }); this.validateDescription(name, value) }}
                    onlyDisplay
                    editable={data.write}
                    value={this.state.emptyDescription ? "" : data.description}
                />
            ),
            contact:
                <LinkListCell
                    urlHandler={value => `index.html?module=contact&action=view&id=${data.contact_id}&company=${data.targeted_to_company}`}
                    value={data.contact}
                    width={columnWidthMap.contact}
                    noTab={true}
                    editable={false}
                    asText={!this.context.functions.checkPrivilege('persons', 'read', this.props.rowProps.company)}
                     />,
            targetto: 
                !(data.targeted_to == null) ?
                <ListCell onlyDisplay editable={false} width={columnWidthMap.targetto}>
                    <div className="targetto">
                        <TaimerAvatar
                            id={data.targeted_to_id}
                            name={data.targeted_to}
                            color={data.targeted_to_color}
                        />
                        <div className="person">
                            <span>{showLockedUsersWithTag ? targeted_to_locked : data.targeted_to}</span><br/>
                            {data.role}
                        </div>
                    </div>
                </ListCell>
                : <ListCell onlyDisplay editable={false} width={columnWidthMap.targetto}><div ></div></ListCell>,
            createdby: 
                <ListCell onlyDisplay editable={false} width={columnWidthMap.createdby}>
                    <div className="createdby">
                        <TaimerAvatar
                            id={data.creator_id}
                            name={data.created_by}
                            color={data.creator_color}
                        />
                        <div className="person">
                            <span>{showLockedUsersWithTag ? created_by_locked : data.created_by}</span><br/>
                            {data.role}
                        </div>
                    </div>
                </ListCell>
        };

        return cells;
    }
}

export default withSnackbar(ActivitiesInsightListRow);