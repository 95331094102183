import React from "react";

import { 
    RemoveRedEye, 
    ArrowDropDown, 
    // CheckBox, 
    // CheckBoxOutlineBlank 
} from '@mui/icons-material';
import { 
    List,
    ListItem, 
    ListItemIcon,
    ListItemText, 
    Popper,
    Checkbox
} from '@mui/material';
import ClickAwayWrapper from "./ClickAwayWrapper";

import styles from "./EyeMenu.module.scss";

class EyeMenu extends React.Component {
    static defaultProps = {
        items: [],
        onItemClick: () => {},
        style: {},
        id: undefined
    };

    constructor(props) {
        super(props);

        this.div = React.createRef();

        this.state = {
            open: false
        };

        [
            "open",
            "close",
            "toggle",
            "handleItemClick"
        ].forEach(f => this[f] = this[f].bind(this));
    }


    open() {
        this.setState({ 
            open: true 
        });
    }


    close() {
        this.setState({ 
            open: false 
        });
    }


    toggle() {
        this.setState({ 
            open: !this.state.open 
        });
    }


    handleItemClick(item) {
        const { onItemClick } = this.props;

        onItemClick(item);
    }


    // TODO: customizable items.
    render() {
        const { 
            style,
            items,
            id
        } = this.props;

        const {
            open
        } = this.state;

        return (
            <ClickAwayWrapper active={open} onClickAway={this.close}>
                <div
                    id={id}
                    style={style}>
                    <div
                        ref={this.div}
                        className={styles.eyeMenu}
                        onClick={this.toggle}>
                        <RemoveRedEye />
                        <ArrowDropDown className="small" />
                    </div>
                    <Popper 
                        anchorEl={this.div.current}
                        placement="bottom-end"
                        open={open}>
                        <List component="div" className={styles.muiList}>
                            {items.map(item => {
                                return (
                                    <ListItem className={styles.muiListItem} onClick={() => this.handleItemClick(item)}>
                                        <ListItemIcon>
                                            <Checkbox checked={item.checked} color="primary" />
                                        </ListItemIcon>
                                        <ListItemText className="text" inset primary={item.text} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Popper>
                </div>
            </ClickAwayWrapper>
        );
    }
}

export default EyeMenu;
