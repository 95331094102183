export default class VersionContentManager {
    static content;

    static getTopBarActions = () => {
        return VersionContentManager.content?.topbar || [];
    };

    static setContent = (content) => {
        VersionContentManager.content = content;
    };

    static isFeatureHidden = (view, feature) => {
        return VersionContentManager.content?.features?.[view]?.[feature]?.visibility == false;
    };

    static isFeatureDisabled = (view, feature) => {
        return VersionContentManager.content?.features?.[view]?.[feature]?.disabled;
    };

    static isFeatureUpgradeTrigger = (view, feature) => {
        return VersionContentManager.content?.features?.[view]?.[feature]?.upgrade == true;
    };

    static getViewTabs = (view) => {
        return VersionContentManager.content?.views?.[view]?.tabs || [];
    };

    static getViewModes = (view) => {
        return VersionContentManager.content?.views?.[view]?.viewModes;
    };

    static getNextVersion = () => {
        return VersionContentManager.content?.nextVersion || {};
    };

    static getCurrentVersion = () => {
        return VersionContentManager.content?.currentVersion || {};
    };

    static hasFeature = (feature) => {
        return VersionContentManager.content?.features?.global?.[feature] == true;
    };

    static getOnboardingItems = () => {
        return VersionContentManager.content?.onboardingItems || [];
    };
}
