/* react */
import React from 'react';

/* css */
import './Efina.css';

/* material-ui */
import TaimerComponent from "../../TaimerComponent";
import List from "../../list/List";
import SettingRow from "../../list/rows/SettingRow";
import ListCell from "../../list/ListCell";
import { ChevronLeft, Delete }from '@mui/icons-material';
import { Switch, Button } from '@mui/material';
import TextInputCell from "../../list/cells/TextInputCell";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";

/* others */
import DataHandler from '../../general/DataHandler';
import { SettingsContext } from '../../SettingsContext';
import _ from "lodash";

class ProjectRow extends SettingRow {

    setFieldData = (name, value) => {
        this.editAndSave(name, value);
        this.setState({data: {...this.state.data, [name]: value}})        
    }

    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <Delete />
            </ListCell>,
            code: <TextInputCell
                width={this.props.columnWidthMap['code']}
                name="code"
                value={this.state.data.code}
                onEdited={this.setFieldData}
            />,
            project: <AutoCompleteCell
                listCellProps={{
                    className: "project-cell"
                }}
                editable={true}
                autoCompleteData={this.props.sharedData.projects}
                hideOptions={(options) => options?.filter(p => !this.props.rowProps.usedProjects.includes(p.id))}
                name="projects"
                width={this.props.columnWidthMap['project']}
                value={this.props.sharedData.projects.find(f => f.id == this.state.data.projects_id)}
                onEdited={(obj) => {
                    this.setFieldData('projects_id', obj.id);
                }}
            />,            
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class MepcoV2 extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Mepco");

        this.state = {
            projects: [],
            workhourProjects: []
        };

        this.workhourProjectList = React.createRef();
        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };     
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getProjects();
        this.getWorkhourProjects();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/mepco_v2`}).done(response => {
            this.setState(response);
        });
    }

    getProjects = async () => {
        const projects = await DataHandler.get({ url: 'projects/dropdown_new', onlyInternal: true, company: this.props.company});
        this.setState({projects});
    }

    getWorkhourProjects = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/workhour_projects/3`}).done((workhourProjects) => {
            this.setState({workhourProjects});
        });
    }

    onRowChange = (data) => {
        if (data.id > 0)
            DataHandler.put({url: `settings/company/${this.props.company}/workhour_projects`}, data).done((resp) => {
                this.getWorkhourProjects();
            });
        else
            DataHandler.post({url: `settings/company/${this.props.company}/workhour_projects/3`}, data).done((resp) => {
                this.getWorkhourProjects();
            });
    };

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        DataHandler.delete({url: `settings/company/${this.props.company}/mepco_v2`}).done(this.back);
    }
    
    render(){
        const { activated } = this.state;        
        const { tr } = this;

        const usedProjects = this.state.workhourProjects.map(w => w.projects_id);

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Mepco Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("Vacation and leave project codes")}</h4>
                    <Button className="green" onMouseUp={() => this.workhourProjectList.current.addNewRow()} size="large">{tr("Add new code")}</Button>
                    <br />
                    <List
                        ref={this.workhourProjectList}
                        id="workhourProjectList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            // companies_id: this.props.company,
                            projects_id: 0,
                            code: '',
                            paid: 0
                        }}
                        listRowType={ProjectRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            // { width: 200, name: "company", header: tr("Company name"), ...this.dColConf },
                            { width: 500, name: "project", header: tr("Project"), ...this.dColConf },
                            { width: 100, name: "code", header: tr("Code"), ...this.dColConf },
                        ]}
                        sharedData={{projects: this.state.projects}}
                        rowProps={{
                            usedProjects: usedProjects,
                            onCreate: this.onRowChange,
                            onUpdate: this.onRowChange,
                            onDelete: row => {
                                if(row.id > 0) {
                                    DataHandler.delete({url: `settings/company/${this.props.company}/workhour_projects`, id: row.id}).done(response => {
                                        this.getWorkhourProjects();
                                    });
                                } else {
                                    let workhourProjects = _.cloneDeep(this.state.workhourProjects);
                                    workhourProjects = workhourProjects.filter(wp => wp.id != row.id);
                                    this.setState({workhourProjects});
                                }

                            }
                        }}
                        data={this.state.workhourProjects}
                    />
                </div>
                    
            </div>
        )
    }
}

export default MepcoV2;
