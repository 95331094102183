import React, { Component } from 'react';
import DialogBorder from '../../dialogs/DialogBorder';
import DataHandler from '../../general/DataHandler';
import TaimerComponent from './../../TaimerComponent';
import { SettingsContext } from './../../SettingsContext';
import _ from 'lodash';

export default class AddToMailingListDialog extends TaimerComponent {
    static contextType = SettingsContext;

	constructor(props, context)
	{
        super(props, context, "list/dialogs/DeleteContactDialog");
        
        this.deleteContact = this.deleteContact.bind(this);
    
    }

    deleteContact(){
        const contacts = _.map(this.props.contacts, 'id');
        DataHandler.post({url: "delete_contact"}, {id: contacts}).done(() =>  this.props.onClose());
    }
    
    
	render() {
        return <DialogBorder className="DeleteContactDialog" id="dialog" title={this.tr("Delete Contact(s)?")} textOk={this.tr("Delete")} onClose={this.props.onClose} onSave={this.deleteContact}>
			<div id="container" className="DeleteContactDialog">
			</div>
		</DialogBorder>
	}
}