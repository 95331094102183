import React, { Component } from 'react';
import PopupList from './PopupList';
import cn from 'classnames'
import OutlinedField from './OutlinedField';
import './TextBoxSuggest.css';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Popper } from '@mui/material';

export default class TextBoxSuggest extends Component {
    static defaultProps = {
		onSelectSuggestion: (item) => {}
    }

	constructor(props) {
		super(props);
		
		this.refEl = React.createRef();

        this.state = {
			visible: false,
			mouseInPopup: false,
            focused: false,
			selectedIndex: -1,
		};
	}

	makeSelection = (item) => {
		this.props.onSelectSuggestion(item);
		this.setState({visible: false, mouseInPopup: false, suggestItems: []});
		this.props.onChange({
			target: {
				value: ''
			}
		});
	}

	onChange = (e) => {
		this.setState({value: e.target.value})
		this.props.onChange(e);
	}

	// Show items if any
	onFocus = () => {
		this.setState({visible: true});
		this.props.onFocus && this.props.onFocus();
	}

	// Hide menu if mouse is not in popup
	onBlur = () => {
		this.props.onBlur && this.props.onBlur();
	}

	onKeyDown = (e) => {
        let nextIndex = -1;
        
        const { suggestItems } = this.props;

		switch (e.key)
		{
			case 'Enter':
			case 'Tab':
				if (suggestItems[this.state.selectedIndex])
					this.makeSelection(suggestItems[this.state.selectedIndex]);
				break;
			case 'ArrowUp':
				if (this.state.selectedIndex > 0)
					nextIndex = this.state.selectedIndex - 1;			
				break;
			case 'ArrowDown':
				if (nextIndex < suggestItems.length - 1)
					nextIndex = this.state.selectedIndex + 1;
				break;
			default:
				this.setState({visible: true});
				break;
		}

		if (nextIndex > -1)
			this.setState({selectedIndex: nextIndex});
	}

	handleClose = () => {
		setTimeout(() => this.setState({ visible: false }), 1);
	}

	render() {
		const { label, name, value, placeholder, suggestItems, popupListClass, component, disabled, error } = this.props;
		const { visible, selectedIndex } = this.state;

		const Wrapped = OutlinedField;

		return (<ClickAwayListener onClickAway={this.handleClose}>
			<div ref={this.refEl} className="TextBoxSuggestWrapper">
				<Wrapped
					className={cn("full selectable", this.props.className)}
					autoComplete="off"
					label={label}
					name={name}
					value={value}
					error={error}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyDown={this.onKeyDown}
					placeholder={placeholder}
					disabled={disabled}
					useOnChange />
				{<Popper placement="bottom-start" anchorEl={this.refEl.current} open={visible && suggestItems.length > 0}><PopupList
					style={{width: (this.refEl.current && this.refEl.current.clientWidth) || 320}}
					className={popupListClass}
					ref={this.PopupList}
					onSelect={this.makeSelection}
					items={suggestItems}
					selectedIndex={selectedIndex}
					component={component} /></Popper>}
			</div></ClickAwayListener>)
	}
}