import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';


class ClickAwayWrapper extends React.Component {
    static defaultProps = {
        onClickAway: () => {},
        active: true
    };


    constructor(props) {
        super(props);

        this.handleClickAway = this.handleClickAway.bind(this);
    }


    handleClickAway(e) {
        if(!this.props.active) {
            return;
        }

        this.props.onClickAway(e);
    }


    render() {
        return (
            <ClickAwayListener {...this.props} onClickAway={this.handleClickAway}>
                {this.props.children}
            </ClickAwayListener>
        );
    }
}

export default ClickAwayWrapper;
