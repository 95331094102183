import React, { Component } from 'react'

import styles from './ReportsGalleryTemplate.module.scss';
import ThreeDotsIcon from '@mui/icons-material/MoreVert';
import { Edit, Delete } from '@mui/icons-material';

import { ReactComponent as Plus } from './img/plus.svg';

import cn from 'classnames';

/* local components */
import TaimerComponent from '../../TaimerComponent';

/* context */
import { SettingsContext } from '../../SettingsContext';
import _ from 'lodash';
import { Group, StarBorder, Star } from '@mui/icons-material';
import { MenuItem, Tooltip } from '@mui/material';
import { format, parse } from 'date-fns';
import ContextMenu from '../../general/ContextMenu';
import { TemplateListItem, TemplateData, reportTypeToTab } from '../TemplateUtils';
import DataHandler from '../../general/DataHandler';

interface Props {
    template: TemplateListItem;
    onSelectTemplate?: (template: TemplateListItem) => void;
    onUpdateTemplate?: (template: TemplateListItem, update: Partial<TemplateListItem>) => void;
    editTemplate?: (template: TemplateData, deleted: boolean) => void;
}

interface State {
}

export default class ReportsGalleryTemplate extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;
    
    constructor (props, context) {
        super (props, context, "new_reports/components/ReportsGalleryTemplate");
        
        this.state = {
        }
    }

    click = () => {
        const { template, onSelectTemplate } = this.props;
        onSelectTemplate?.(template);
    }

    toggleFavorite = async (e: React.MouseEvent<SVGElement>) => {
        const { functions: { whoami } } = this.context;
        const { template, onUpdateTemplate } = this.props;

        e.stopPropagation();

        const is_favorite = !template.is_favorite;

        const update = {
            is_favorite,
        };

        try {
            onUpdateTemplate?.(template, update);
            await DataHandler.patch({url: `new_reports/templates/${template.id}/user`}, update);
            this.onToggleFavorite(is_favorite);

            setTimeout( () => whoami(), 1000); // Required for sidebar to update
        } catch (error) {
            console.error(error);
        }
    }

    onToggleFavorite = (isFavorite: boolean) => {
        const { template } = this.props;

        const mixPanelEvent = isFavorite
            ? "pin_report"
            : "unpin_report";

        this.context.functions.sendMixpanelEvent(mixPanelEvent, {
            "report_module": reportTypeToTab(template.report_type),
            "report_name": template?.name || ""
        })
    }

    clickRight = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    editTemplate = (deleted = false) => {
        const { template, editTemplate } = this.props;
        if (!template.is_own) {
            return;
        }

        const saveData: TemplateData = {
            id: template.id,
            name: template.name,
            description: template.description,
            users_id: this.context.userObject.usersId,
            report_type: template.report_type,
            shared_to: template.shared_to, // Gets fetched in SaveTemplateDialog if undefined. shared_to data is added to gallery template after opened once from gallery.
        };

        editTemplate && editTemplate(saveData, deleted);
    }
    
    render() {
        const { template } = this.props;

        return (
            <div className={styles.root} onClick={this.click}>
                <div className={styles.left}>
                    <div className={styles.starArea}>
                        {template.is_favorite ? <Star className={styles.star} onClick={this.toggleFavorite} /> : <StarBorder className={styles.emptyStar} onClick={this.toggleFavorite} />}
                    </div>
                    <div className={styles.info}>
                        <div className={styles.name}>
                            {template.name}
                        </div>
                        <div className={styles.description}>
                            {template.description}
                        </div>
                    </div>
                </div>
                <div className={cn(styles.right, styles.iconArea)} onClick={this.clickRight}>
                    {template.is_own && <div>
                        <ContextMenu buttonClassName={styles.menuButton} label={<ThreeDotsIcon />} noExpandIcon>
                            <MenuItem className={styles.menuItem} onClick={() => this.editTemplate()}><Edit />{this.tr("Edit report details")}</MenuItem>
                            <MenuItem className={`${styles.menuItem} ${styles.delete} delete`} onClick={() => this.editTemplate(true)}><Delete />{this.tr("Delete report")}</MenuItem>
                        </ContextMenu>
                    </div>}
                    {!template.is_own && !template.is_default && <Tooltip arrow classes={{ tooltip: 'darkblue-tooltip' }}
                        title={this.tr("Shared to you by ${user} on ${date}", {
                            user: template.shared_by_user,
                            date: format(parse(template.shared_date, "YYYY-MM-DD", new Date()), this.context.userObject.dateFormat),
                        })}>
                            <span className={styles.sharedIcon}><Group /></span>
                        </Tooltip>}
                    {template.is_default && <Tooltip 
                        arrow classes={{ tooltip: 'darkblue-tooltip' }}
                        title={this.tr("This report is a default report created by Heeros.")}>
                            <span className={styles.defaultIcon}><Plus /></span>
                        </Tooltip>}
                </div>
            </div>
        )
    }
}