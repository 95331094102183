import React, { Component } from 'react';

// Event
class EventWrapper extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			showAllUsers: false
		}
		this.ref = React.createRef();		
	}


	hideAllUsers = () => {
		this.setState({showAllUsers: false});
	}

	render() {
		const {event} = this.props;
		return (<React.Fragment>
			<div class="event-box">
				<div className="eventCustomerName">{event.summary  || ""}</div>
				{<div>{event.location || ""}</div>}
			</div>
			</React.Fragment>);
	}
}

export default EventWrapper;
