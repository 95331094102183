import React, { Component } from 'react';
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import QRCode from 'qrcode.react';
import DataHandler from '../../general/DataHandler';
import BusinessIcon from '@mui/icons-material/Business';


import iosBadge from '../img/appstore.svg';
import playBadge from '../img/play.svg';

const styles = theme => ({
    main: {
        paddingLeft: 0,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

class ActivateMobileApp extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/ActivateMobileApp");

        this.state = {
            loaded: false,
        }
    }

    componentDidMount = async () => {
        super.componentDidMount();
        const mobileData = await DataHandler.get({url: 'settings/profile/mobileapp'});

        this.setState({loaded: true, ... mobileData});
    }

    render() {
        const { loaded, qr, user, installation } = this.state;
        const { tr } = this;

        if (!loaded)
            return <div><img className='main-page-loading-indicator' src={require('../../dashboard/insights/img/loading.svg').default} /></div>

        return <React.Fragment>
            <h3>{tr("How to activate your mobile app")}</h3>

            <div className="InfoBox">
                <BusinessIcon />

                <p>
                    {tr("Company ID")}: <strong>{installation}</strong><br />
                    {tr("Username")}: <strong>{user.username}</strong>
                </p>

            </div>

            <h3>{tr("Step 1 : Download the Taimer app")}</h3>
            <section className="taimerapp">
                <p>{tr("Taimer app is availabe on App Store and Google Play Store")}</p>

                <div className="storelinks">
                    <a className="storelink" href="https://itunes.apple.com/app/taimer-sales-crm-projects/id1453159925" target="_blank">
                        <img src={iosBadge} />
                    </a>
                    <a className="storelink" href="https://play.google.com/store/apps/details?id=com.taimer.enterprise" target="_blank">
                        <img src={playBadge} />
                    </a>
                </div>
            </section>

            <h3>{tr("Step 2 : Login")}</h3>
            <p>{tr("Open your app on your mobile and click on Login with QR code. if you login manually use company id and user name found at top of this page.")}</p>

            <div className="qrcode"><QRCode value={qr} size={256} /></div>
        </React.Fragment>
    }
}

export default withStyles(styles)(ActivateMobileApp);