/* css */
import "./ResourcingList.css";

/* others */
import _, { chunk } from 'lodash';
import FileSaver from 'file-saver';
import List from "./../List";
import React from 'react';
import DataHandler from './../../general/DataHandler';
import TaimerComponent from '../../TaimerComponent';
import ResourcingListRow from "./../rows/ResourcingListRow";
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import { format } from "date-fns";
import ProgressNotification from "./../../general/ProgressNotification";
import ErrorNotification from "./../../general/ErrorNotification";
import NotificationContent from "./../../general/NotificationContent";
import Button from '@mui/material/Button';
import { withSnackbar } from 'notistack';
import Radio  from '@mui/material/Radio';
import RadioGroup  from '@mui/material/RadioGroup';
import FormControlLabel  from '@mui/material/FormControlLabel';
import { getGetParameters } from '../../resourcing/resourcing';
import MoveTasksUsersDialog from "../../dialogs/MoveTasksUsersDialog";

class ResourcingList extends TaimerComponent {
    constructor(props) {
        super(props, null, 'list/lists/ResourcingList');

        this.dataInitializedOnce = false;

        const filteredData = props.data.filter(x => x.type !== "virtual_parent");

        let data = filteredData.slice(0, props.perPage);
        this.allData = filteredData;
        this.state = {
            data: data,
            pageCount: Math.ceil(props.data.length / props.perPage),
            resourceCount: "–",
            currentDialogProps: undefined,
            listSettings: this.props.listSettings,
            confirmationDialogRadio: 'this',
        };

        this.initialSettings = this.props.defaultListSettings;

        this.dialogs = {
            delete: MassDialog,
            editSelections: MassDialog,
            propertyEditor: MassDialog,
            promptBatchUpdate: MassDialog,
        };

        this.basicDialogControls = () => ({
            cancelText: this.tr("Cancel"),
            confirmText: this.tr("Confirm"),
            onCancel: this.closeDialog,
            onCloseClick: this.closeDialog
        });

        this.propertyEditorDialogFields = {
            project: {
                fields: (dialogData) => {
                    const sharedData = {...this.props.autoCompleteData};
                    return {
                        ...this.propertyPickerFields("project"),
                        project: {
                            _type: "projectTreeDrop",
                            label: this.tr("Choose a project"),
                            options: sharedData.projects.filter(el => el.id > 0)
                        }
                    }
                },
                onConfirm: (data) => {
                    const name = this.props.autoCompleteData.projects.find(el => el.id == data.project).name;

                    this.promptBatchUpdate({ name: this.tr("Project"), value: name }, { projects_id: data.project }) 
                }
            },
            description: {
                fields: (dialogData) => {
                    return {
                        ...this.propertyPickerFields("description"),
                        description: {
                            _type: "text",
                            label: this.tr("Write a description"),
                        }
                    }
                },
                onConfirm: (data) => {
                    this.promptBatchUpdate({ name: this.tr("Description"), value: data.description }, { description: data.description }) 
                }
            },
            additional_description: {
                fields: (dialogData) => {
                    return {
                        ...this.propertyPickerFields("additional_description"),
                        additional_description: {
                            _type: "textMultiline",
                            label: this.tr("Write a note"),
                        }
                    }
                },
                onConfirm: (data) => {
                    this.promptBatchUpdate({ name: this.tr("Note"), value: data.additional_description }, { additional_description: data.additional_description }) 
                }
            },
            startdate: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("startdate"),
                    startdate: {
                        _type: "date",
                        label: this.tr("Choose a date")
                    }
                }),
                onConfirm: (data) => {
                    this.promptBatchUpdate({ name: this.tr("Starting date"), value: format(data.startdate, this.context.userObject.dateFormat) }, { startdate: format(data.startdate, "YYYY-MM-DD") });
                }
            },
            enddate: {
                fields: (dialogData, sharedData) => ({
                    ...this.propertyPickerFields("enddate"),
                    enddate: {
                        _type: "date",
                        label: this.tr("Choose a date")
                    }
                }),
                onConfirm: (data) => {
                    this.promptBatchUpdate({ name: this.tr("Ending date"), value: format(data.enddate, this.context.userObject.dateFormat) }, { enddate: format(data.enddate, "YYYY-MM-DD") });
                }
            },
            priority: {
                fields: (dialogData) => {
                    const sharedData = {...this.props.autoCompleteData};
                    return {
                        ...this.propertyPickerFields("priority"),
                        priority: {
                            _type: "drop",
                            label: this.tr("Choose a priority"),
                            options: sharedData.priorities.map(el => {
                                el.label = el.name;
                                el.value = el.id;
                                return el;
                            }),
                        }
                    };
                },
                onConfirm: (data) => {
                    const name = this.props.autoCompleteData.priorities.find(el => el.id == data.priority).name;
                    this.promptBatchUpdate({ name: this.tr("Priority"), value: name }, { priorities_id: data.priority });
                }
            },
            skill: {
                fields: (dialogData) => {
                    const sharedData = {...this.props.autoCompleteData};
                    return {
                        ...this.propertyPickerFields("skill"),
                        skill: {
                            _type: "drop",
                            label: this.tr("Choose a skill"),
                            options: sharedData.skills.map(el => {
                                el.label = el.name;
                                el.value = el.id;
                                return el;
                            }),
                        }
                    };
                },
                onConfirm: (data) => {
                    const name = this.props.autoCompleteData.skills.find(el => el.id == data.skill).name;
                    this.promptBatchUpdate({ name: this.tr("Skill"), value: name }, { skills_id: data.skill });
                }
            },
            progress: {
                fields: (dialogData) => {
                    return {
                        ...this.propertyPickerFields("progress"),
                        progress: {
                            _type: "numeric",
                            label: this.tr("Mark progress"),
                        }
                    }
                },
                onConfirm: (data) => {
                    this.promptBatchUpdate({ name: this.tr("Progress"), value: data.progress + "%" }, { progress: data.progress / 100 }) 
                }
            },
            hours: {
                fields: (dialogData) => {
                    return {
                        ...this.propertyPickerFields("hours"),
                        hours: {
                            _type: "numeric",
                            label: this.tr("Mark allocated hours"),
                        }
                    }
                },
                onConfirm: (data) => {
                    this.promptBatchUpdate({ name: this.tr("Allocated hours"), value: data.hours }, { hours: data.hours }) 
                }
            },
            add_users: {
                fields: (dialogData) => {
                    const sharedData = {...this.props.autoCompleteData};

                    const x = this.props.autoCompleteData.employees.filter(el => {
                        if(el.id == '' || el.id <= 0) {
                            return true;
                        }
                        
                        return true;
                    });

                    return {
                        ...this.propertyPickerFields("add_users"),
                        add_users: {
                            _type: "dropMultiple",
                            label: this.tr("Add users"),
                            options: x,
                        }
                    }
                },
                onConfirm: (data) => {
                    const names = [], ids = [];
                    data.add_users.forEach(t => {
                        ids.push(t.id);
                        names.push(t.name);
                    });
                    this.promptBatchUpdate({ name: this.tr("Add users"), value: names.join(", ") }, { add_users: ids.join(",") }) 
                }
            },
            remove_users: {
                fields: (dialogData) => {
                    const employees = this.getEmployeesOfSelectedTasks();

                    return {
                        ...this.propertyPickerFields("remove_users"),
                        remove_users: {
                            _type: "dropMultiple",
                            label: this.tr("Remove users"),
                            options: employees.filter(el => el.id > 0)
                        }
                    }
                },
                onConfirm: (data) => {
                    const names = [], ids = [];

                    data.remove_users.forEach(t => {
                        ids.push(t.id);
                        names.push(t.name);
                    });
                    this.promptBatchUpdate({ name: this.tr("Remove users"), value: names.join(", ") }, { remove_users: ids.join(",") }) 
                }
            },
        };

        this.propertyPickerFields = (value = undefined) => ({
            propertyToEdit: {
                _type: "drop",
                label: this.tr("Choose a property to edit"),
                onChange: async (propertyToEdit) => {
                    switch(propertyToEdit) {
                        case 'mark_as_done':
                            this.promptBatchUpdate({ name: this.tr("Mark as done"), value: "" }, { mark_as_done: 1 })
                            return
                        case 'mark_as_undone':
                            this.promptBatchUpdate({ name: this.tr("Mark as undone"), value: "" }, { mark_as_undone: 1 })
                            return;
                        case 'move_tasks_users':
                            this.closeDialog();
                            this.showMoveTasksToNewUserDialog();
                            return;

                    }

                    let dialogProps = this.propertyEditorDialogFields[propertyToEdit];

                    if(dialogProps.hasOwnProperty("sharedData") && typeof(dialogProps.sharedData) === "function") {
                        dialogProps.sharedData = await dialogProps.sharedData();
                    }

                    this.dialogProps.propertyEditor.dialogProps = {
                        ...this.basicDialogControls(),
                        ...dialogProps
                    };

                    this.openDialog("propertyEditor", {
                        title: this.tr('Edit'),
                        subtitle: `(${this.getSelectedTasks().length} ${this.tr("row(s) selected")})`
                    });
                },
                value: value,
                options: [
                    { value: "project", label: this.tr("Project") },
                    { value: "description", label: this.tr("Description") },
                    { value: "additional_description", label: this.tr("Note") },
                    { value: "startdate", label: this.tr("Starting date") },
                    { value: "enddate", label: this.tr("Ending date") },
                    { value: "priority", label: this.tr("Priority") },
                    { value: "skill", label: this.tr("Skill") },
                    { value: "progress", label: this.tr("Progress") },
                    { value: "hours", label: this.tr("Allocated hours") },
                    { value: "add_users", label: this.tr("Add users") },
                    { value: "remove_users", label: this.tr("Remove users") },
                    { value: "mark_as_done", label: this.tr("Mark as done") },
                    { value: "mark_as_undone", label: this.tr("Mark as undone") },
                    { value: "move_tasks_users", label: this.tr("Move tasks to new user") },
                ].filter(x => x)
            }
        });

        this.originalDialogProps = () => ({
            delete: {
                dialogType: "delete",
                open: this.state.currentDialog === "delete",
                close: this.closeDialog,
                dialogProps: {
                    header: this.tr("Delete Task(s)") + "?",
                    warning: () => {
                        if (this.state.currentDialogProps.process === "delete_one")
                            return this.tr("You are about to permanently delete task \"${task}\". The deletion of a task can not be canceled or undone. Are you sure you want to permanently delete the selected task?", {task: this.state.currentDialogProps.name});

                        const checkedRows = this.state.currentDialogProps.checkedRows;
                        const length      = checkedRows ? checkedRows.length : 0;

                        return this.tr("You are about to permanently delete {{%placeholder-do-not-translate%}} task(s). The deletion of a task can not be canceled or undone. Are you sure you want to permanently delete the selected tasks?").replace("{{%placeholder-do-not-translate%}}", `${length}`);
                    },
                    extraContent: () => this.state.currentDialogProps.hasRecurring && <div className="RecurringSection">
                        <span>{this.tr("Delete Also Recurrences")}</span>
                        <RadioGroup
                            name="resource-edit-recurrence"
                            value={this.state.confirmationDialogRadio}
                            onChange={this.handleConfirmationRadio}
                        >
                            <FormControlLabel
                                value="this"
                                label={this.tr("Only this")}
                                labelPlacement="start"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                value="future"
                                label={this.tr("Future")}
                                labelPlacement="start"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                value="all"
                                label={this.tr("All")}
                                labelPlacement="start"
                                control={<Radio />}
                            />
                        </RadioGroup>
                    </div>,
                    close: this.closeDialog,
                    onConfirm: () => {
                        if (this.state.currentDialogProps.process === "delete_one") {
                            this.deleteTask(this.state.dialogData);
                            return;
                        }

                        let notificationKey = this.showProgressNotification();

                        const checkedRows   = this.state.currentDialogProps.checkedRows;
                        const string        = checkedRows.join("+");

                        DataHandler.delete({ url: 'resourcing/delete_multiple/' + string, delete_others: this.state.confirmationDialogRadio }).done((response) => {
                            this.props.updateData().then(() => {
                                this.resetPage();
                                if (response.deleted) {
                                    this.showChangesAppliedNotification(response.deleted + " " + this.tr("tasks deleted."));
                                }
                                if (response.not_deleted) {
                                    this.showErrorNotification({
                                        message: response.not_deleted + " " + this.tr("tasks could not be deleted because of working hours."), 
                                        buttonText: this.tr("Dismiss"),
                                        key: "batchUpdateError"
                                    });
                                }
                            });
                        }).fail(() => {
                            this.showErrorNotification({
                                message: this.tr("Something went wrong while applying the changes"), 
                                buttonText: this.tr("Dismiss"),
                                key: "batchUpdateError"
                            });
                        }).always(() => {
                            setTimeout(() => {
                                this.props.closeSnackbar(notificationKey); 
                            }, 1000);
                        });

                        this.closeDialog();
                        this.list.current.uncheckAll();
                    }
                }
            },
            editSelections: {
                dialogType: "edit",
                dialogProps: {
                    ...this.basicDialogControls(),
                    fields: this.propertyPickerFields(),
                    disableConfirm: true,
                }
            },
            propertyEditor: {
                dialogType: "edit",
                dialogProps: {
                    // The rest of this will be filled in from 
                    // this.propertyEditorDialogFields according to which 
                    // property the user wants to edit.
                }
            },
            promptBatchUpdate: {
                dialogType: "confirm",
                open: this.state.currentDialog === "promptBatchUpdate",
                dialogProps: {
                    ...this.basicDialogControls(),
                    title: this.tr("Edit"),
                    confirmText: this.tr("Confirm"),
                    cancelText: this.tr("Cancel"),
                    extraContent: () => this.state.currentDialogProps.hasRecurring && <div className="RecurringSection">
                        <span>{this.tr("Edit Also Recurrences")}</span>
                        <RadioGroup
                            name="resource-edit-recurrence"
                            value={this.state.confirmationDialogRadio}
                            onChange={this.handleConfirmationRadio}
                        >
                            <FormControlLabel
                                value="this"
                                label={this.tr("Only this")}
                                labelPlacement="start"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                value="future"
                                label={this.tr("Future")}
                                labelPlacement="start"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                value="all"
                                label={this.tr("All")}
                                labelPlacement="start"
                                control={<Radio />}
                            />
                        </RadioGroup>
                    </div>,
                },
            },
        });

        this.dialogProps = this.originalDialogProps();

        this.checkedTasks = [];

        this.list = React.createRef();

        this.sortMap = {
            project: {name: 'project_name'},
            customer: {name: 'customer_name'},
            startdate: {name: 'start_date', type: 'date'},
            enddate: {name: 'end_date', type: 'date'},

            progress: {name: 'progress', type: 'number'},
            hours_done: {name: 'hours_done', type: 'number'},
            hours: {name: 'hours', type: 'number'},
            budgeted: {name: 'budgeted', type: 'number'},
            remaining: {name: 'remaining', type: 'number'},
        };

        this.fields = [
            { field: "expand", name: "expand", header: "", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false, visibleInToolbar: true },
            { field: "checked", name: "checked", header: "", columnHeaderType: "checkbox", width: 50, moveable: false, hideable: false, visibleInToolbar: true},
            { field: "customer", name: "customer", header: this.tr("Customer"), width: 200 },
            { field: "status", name: "status", header: this.tr("Status"), width: 120 },
            { field: "text", name: "text", header: this.tr("Task"), width: 200 },
            { field: "users", name: "users", header: this.tr("Employees"), width: 200 },
            { field: "project", name: "project", header: this.tr("Project"), width: 160 },
            { field: "startdate", name: "startdate", header: this.tr("Start Date"), width: 150 },
            { field: "enddate", name: "enddate", header: this.tr("End Date"), width: 150 },
            { field: "timerange", name: "timerange", header: this.tr("Time range"), width: 150 },
            { field: "priority", name: "priority", header: this.tr("Priority"), width: 150 },
            { field: "skill", name: "skill", header: this.tr("Skill"), width: 120 },
            { field: "progress", name: "progress", header: this.tr("Progress") },
            { field: "hours_done", name: "hours_done", header: this.tr("Hours h"), width: 120 },
            { field: "hours_done_all_time", name: "hours_done_all_time", header: this.tr("Hours h (All time)"), width: 120 },
            { field: "hours", name: "hours", header: this.tr("Allocated h"), width: 120 },
            { field: "hours_all_time", name: "hours_all_time", header: this.tr("Allocated h (All time)"), width: 120 },
            { field: "budgeted", name: "budgeted", header: this.tr("Budgeted h"), width: 120 },
            { field: "budgeted_all_time", name: "budgeted_all_time", header: this.tr("Budgeted h (All time)"), width: 120 },
            { field: "remaining", name: "remaining", header: this.tr("Remaining h"), width: 120  },
            { field: "remaining_all_time", name: "remaining_all_time", header: this.tr("Remaining h (All time)"), width: 120  },
        ];

        this.statusTypes = [
            {
                color: "#FBB823",
                id: 1,
                label: this.tr("In progress"),
                name: "In progress",
                value: 1,
            },
            {
                color: "#F85623",
                id: 2,
                label: this.tr("Overdue"),
                name: "Overdue",
                value: 2,
            },
            {
                color: "#50E4C2",
                id: 3,
                label: this.tr("Done"),
                name: "Done",
                value: 3,
            },
            {
                color: "#D33DAF",
                id: 4,
                label: this.tr("Milestone"),
                name: "Milestone",
                value: 4,
            }
        ];


        ['sortRows', 'rowUpdate', 'unCheckAll', 'promptBatchUpdate',
        '_promptBatchUpdate', 'batchUpdate', '_batchUpdate', 'showProgressNotification',
        'showErrorNotification']
        .forEach(e => this[e] = this[e].bind(this));
    }

    handleConfirmationRadio = (evt) => {
        this.setState({confirmationDialogRadio: evt.target.value});
    }

    getEmployeesOfSelectedTasks = () => {
        const sharedData = {...this.props.autoCompleteData};
        const selectedTasks = this.getSelectedTasks();
        let users = [];
        let userIds = [];

        this.props.data.forEach(el => {
            if (selectedTasks.includes(el.id) && el.user_ids) {
                userIds.push(...el.user_ids);
            }
        });

        let addedIds = [];
        userIds.forEach(u => {
            if (!addedIds.includes(u)) {
                addedIds.push(u);
                const employee = sharedData.employees.find(e => e.id == u);
                employee && users.push(employee);
            }
        })

        return users.sort( (a, b) => a.name.localeCompare(b.name, 'fi')); 
    }

    /* Mass edit */
    promptBatchUpdate(config = {}) {
        if(arguments.length > 1) {
            this._promptBatchUpdate(...arguments);
            return;
        }

        const defConf = {
            confirmationSummary: [],
            data: {},
            uncheckAfter: false,
            onConfirm: undefined
        };

        const fConfig = { ...defConf, ...config };

        this._promptBatchUpdate(...Object.keys(defConf).map(f => fConfig[f])); 
    }


    _promptBatchUpdate(confirmationSummary = [], data, uncheckAfter = true, onConfirm = undefined) {
        let hasRecurring = this.getSelectedTasks().find(id => this.props.data.find(x => x.id === id)?.recurrence_parent_id > 0);

        const length = this.getSelectedTasks().length;
        this.openDialog("promptBatchUpdate", {
            title: `${this.tr('Edit')}`,
            subtitle: `(${length} ${this.tr("row(s) selected")})`,
            text: this.tr("Are you sure you want to apply the changes to the selected projects? Once applied, the changes can not be canceled or undone."),
            onConfirm: onConfirm === undefined ? () => {
                this.batchUpdate(data, uncheckAfter);
            } : onConfirm,
            confirmationSummary: Array.isArray(confirmationSummary) ? confirmationSummary : [confirmationSummary],
            hasRecurring,
        });
    }


    batchUpdate(config = {}) {
        if(arguments.length > 1)
            return this._batchUpdate(...arguments);
         
        const defConf = {
            data: {},
            uncheckAfter: false,
            showNotification: true,
            excludeIds: []
        };

        const fConfig = { ...defConf, ...config };

        return this._batchUpdate(...Object.keys(defConf).map(f => fConfig[f]));
    }


    // uncheckAfter === true will uncheck all rows on the list after changes have been applied.
    // Used when an update leads to the selected rows no longer being visible in the current set.
    async _batchUpdate(data, uncheckAfter = false, showNotification = true, excludeIds = []) {
        // https://i.imgflip.com/3ncsld.jpg
        const allSelectedTasks = this.getSelectedTasks();
        let notificationKey = showNotification ? this.showProgressNotification() : undefined;
        this.closeDialog();

        const errorsIn = [];

        for (const selectedTasks of chunk(allSelectedTasks, 50)) {
            let taskMap = {};
            selectedTasks.forEach(el => {
                taskMap[el] = data;
            });

            try {
                const response = await DataHandler.put({ url: `resourcing/mass`, edit_others: this.state.confirmationDialogRadio }, { taskMap });

                for (let id in response) {
                    if (response[id].hasOwnProperty("original") && response[id].original.hasOwnProperty("error")) {
                        errorsIn.push(id);
                    }
                }
            } catch (e) {
                this.props.closeSnackbar(notificationKey);
                this.showErrorNotification({
                    message: this.tr("Something went wrong while applying the changes"),
                    buttonText: this.tr("Dismiss"),
                    key: "batchUpdateError"
                });
                return;
            }
        }

        if (uncheckAfter) {
            this.list.current.uncheckAll();
        }

        setTimeout(() => {
            if (!showNotification)
                return;

            this.props.closeSnackbar(notificationKey);
        }, 1000);

        return this.props.updateData().then(() => {

            if (errorsIn.length > 0) {
                this.list.current.check(errorsIn, true);

                this.showErrorNotification({
                    message: this.tr("An error occured while applying changes to some projects. Please make sure that the projects checked in the list have their compulsory fields filled, then reattempt the edit."),
                    buttonText: this.tr("Dismiss"),
                    key: "batchUpdateFieldError",
                    preventDuplicate: true
                });
            }

            // If there were no errors, changes were applied succesfully,
            // if the quantity of sent projects is greater than of those which had errors, changes were applied partially,
            // otherwise no project was edited without errors, and showErrorNotification is the only relevant notification
            // to show.
            if (showNotification) {
                if (errorsIn.length === 0)
                    this.showChangesAppliedNotification();
                else if (allSelectedTasks.length > errorsIn.length)
                    this.showChangesAppliedPartiallyNotification();
            }

            return errorsIn;
        });
    }

    // Pass a ref to control the notification's progress indicator.
    // Since it's a persistant notification, we return the key of the
    // notification in order to be able to clear it later using clearSnackbar(key);
    showProgressNotification() {
        return this.props.enqueueSnackbar(null, {
            persist: true,
            preventDuplicate: true,
            content: key => {
                return (
                    <ProgressNotification
                        message={this.tr("Applying changes")} />
                );
            }
        });
    }


    showErrorNotification({ message, buttonText, onClick, key, preventDuplicate }) {
        const { closeSnackbar } = this.props;

        this.props.enqueueSnackbar("", {
            persist: true,
            key: key || "errorNotification",
            preventDuplicate: preventDuplicate || false,
            content: () => {
                return (
                    <ErrorNotification message={message} closeSnackbar={closeSnackbar} key={key} onClick={onClick} buttonText={buttonText} />
                ); 
            }
        });
    }


    showChangesAppliedNotification(msg = undefined) {
        this.props.enqueueSnackbar(msg === undefined ? this.tr("Changes applied") : msg, {
            persist: false,
            variant: "success",
            preventDuplicate: true
        });
    }


    showChangesAppliedPartiallyNotification(msg = undefined) {
        this.props.enqueueSnackbar(msg === undefined ? this.tr("Changes applied partially") : msg, {
            persist: false,
            variant: "warning",
            preventDuplicate: true
        });
    }


    closeDialogAndReset() {
        this.closeDialog();

        setTimeout(() => {
            this.list.current.uncheckAll(false);
        });
    }

    /* /MassEdit */

    rowUpdate(data, edit_others = 'this') {
        let progress = data.progress && data.progress.replace ? data.progress.replace(/[^0-9]/,"") : data.progress;  
        let postData = {
            description: data.text,
            startdate: data.start_date,
            enddate: data.end_date,
            hours: data.hours,
            progress: Number(progress),
            skills_id: data.skills_id,
            priorities_id: data.priorities_id,
            type: data.type,
            starttime: data.starttime,
            endtime: data.endtime,
        };

        //postData.starttime = moment(postData.starttime, 'h-mm-ss'); 
        //postData.endtime = moment(postData.endtime, 'h-mm-ss');
        let url = 'resourcing/edit_task/' + data.id;
        DataHandler.post({url: url, edit_others}, postData).done(() => {
            this.props.updateData(true);
        });
    }

    sortRows(colName, asc, initialSort = false, sortPressed) {
        if(colName == 'users') {
            this.sortByEmployees(asc, initialSort, sortPressed);
            return;
        }
        let sortedData = [...this.allData];
        let multiplier = asc ? 1 : -1;
        if(colName == 'timerange') {
            sortedData = this.sortByTimeRange(sortedData, multiplier);
        }
        else {
            sortedData = this.sortByColumn(sortedData, multiplier, colName);
        }
        this.allData = [...sortedData];
        this.setState({data: sortedData.slice((this.state.listSettings.page - 1) * this.state.listSettings.perPage, this.state.listSettings.page * this.state.listSettings.perPage)});
       
        if (sortPressed || (!initialSort && !this.props.listRowEdited)) {
            this.resetPage();
            this.setState({ listSettings: {...this.state.listSettings, sort: {colName, asc}} }, () => this.saveStickySearch(this.state.listSettings));
        }
    }

    showWorkhourDialog = (data) => {
        this.context.functions.addHours(data, { afterSaveCallback: this.props.updateData });
    }

    showTaskDialog = (data) => {
        this.context.functions.addResource(data.resource, {
            onProjectRangeExtended: this.props.onProjectRangeExtended,
        });
    }

    deleteTask = data => {
        const { enqueueSnackbar } = this.props;

        const url = data.type === 'task' ? 'resourcing/delete_task/' : 'resourcing/delete_milestone/';
        DataHandler.delete({ url: url + data.id, delete_others: this.state.confirmationDialogRadio }).done((response) => {
            this.props.updateData();
            this.resetPage();
            if (response.success) {
                enqueueSnackbar(this.tr(`Task deleted successfully!`), {
                    variant: "success",
                });
            } else if (response.error === 'workhours') {
                enqueueSnackbar(this.tr(`Tasks with workhours logged can't be removed!`), {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(this.tr(`Error while deleting.`), {
                    variant: "error",
                });
            }
        });

        this.closeDialog();
    }

    openDialog = (name, data = {}) => {
        this.setState({
            currentDialog: name,
            dialogData: data,
            currentDialogProps: { 
                ...(this.dialogProps.hasOwnProperty(name) ? this.dialogProps[name].dialogProps : {}), 
                ...(typeof(data) === "object" ? data : {}) 
            }
        });
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    componentDidUpdate(prevProps) {
        if(prevProps.data.length === 0 && this.props.data.length > 0 && !this.dataInitializedOnce) {
            this.dataInitializedOnce = true;
        }

        if(!_.isEqual(prevProps.data, this.props.data) || prevProps.showTasksBy != this.props.showTasksBy) {
            let data = this.props.data.filter(x => x.type !== "virtual_parent");

            if(this.props.showTasksBy != 0 && prevProps.showTasksBy != this.props.showTasksBy) {
                let compareValue = this.props.showTasksBy == 1 ? 1 : 0;
                data = data.filter((el) => {
                    return el.is_one_day_task == compareValue;
                });
            }
            this.allData = [...data];

            this.setData();
        }
    }

    resetList() {
        this.setState({listSettings: this.props.defaultListSettings}, () => this.sortRows(this.state.listSettings.sort.colName, this.state.listSettings.sort.asc, true))
    }

    setData = () => {
        let data = [...this.allData];
        let page = this.props.listSettings.page || this.state.listSettings.page;
        let perPage = this.props.listSettings.perPage || this.state.listSettings.perPage;

        if (this.props.listSettings.sort)
            this.setState({listSettings: {...this.state.listSettings, page, perPage}}, () => this.sortRows(this.props.listSettings.sort.colName, this.props.listSettings.sort.asc, true));
        else
            this.setState({data: data.slice((page - 1) * perPage, page * perPage), listSettings: {...this.state.listSettings, page, perPage}});
    }

    setPage(page) {
        this.list.current.setPage(page);
    }

    pageChanged = (page) => {
        let data = [...this.allData];
        this.setState({data: data.slice((page - 1) * this.state.listSettings.perPage, page * this.state.listSettings.perPage), listSettings: {...this.state.listSettings, page}}, () => this.saveStickySearch(this.state.listSettings));
    }

    perPageChanged = (perPage) => {
        let data = [...this.allData];
        this.setState({data: data.slice((this.state.listSettings.page - 1) * perPage, this.state.listSettings.page * perPage), page: 1, listSettings: {...this.state.listSettings, perPage}}, () => this.saveStickyAndResetList());
    }

    saveStickySearch = (listSettings) => {
        DataHandler.post({ url: `saved_search/sticky/resourcing_list_settings`, }, { search: listSettings });

    }

    saveStickyAndResetList() {
        this.saveStickySearch(this.state.listSettings);
        this.resetPage();
    }

    unCheckAll() {
        this.checkedTasks = [];
        this.list.current.resetCheckedRows();
    }

    sortByColumn(data, multiplier, colName) {
        const column = this.sortMap[colName] ? this.sortMap[colName] : { name: colName };

        return data.sort((a, b) => {
            let difference = 0;
            const A = a[colName === "priority" ? "priorities_id" : column.name] || (column.type === "number" ? 0 : "");
            const B = b[colName === "priority" ? "priorities_id" : column.name] || (column.type === "number" ? 0 : "");
            if (column.type === "number") {
                difference = (Number(A) - Number(B));
            } else if (column.type === "date") {
                difference = A.getTime() - B.getTime();
            } else {
                difference = (A + "").localeCompare(B, "fi");
            }

            if (difference == 0) {
                difference = (Number(a['id'].split("-")[1]) - Number(b['id'].split("-")[1]));
            }
            return difference * multiplier;
        });
    }

    sortByTimeRange(data, multiplier) {
        return data.sort((a, b) => {
            let difference = 0;
            const startA = (a.is_one_day_task < 1 || !a.starttime) ? 0 : (a.starttime === "00:00:00" ? 1 : a.starttime.split(':').reduce((acc, time) => (60 * acc) + +time));
            const startB = (b.is_one_day_task < 1 || !b.starttime) ? 0 : (b.starttime === "00:00:00" ? 1 : b.starttime.split(':').reduce((acc, time) => (60 * acc) + +time));
            const endA = (a.is_one_day_task < 1 || !a.endtime) ? 0 : (a.endtime === "00:00:00" ? 1 : a.endtime.split(':').reduce((acc, time) => (60 * acc) + +time));
            const endB = (b.is_one_day_task < 1 || !b.endtime) ? 0 : (b.endtime === "00:00:00" ? 1 : b.endtime.split(':').reduce((acc, time) => (60 * acc) + +time));

            if (startA < startB)
                difference = -1;

            if (startA > startB)
                difference = 1;

            if (startA == startB) {
                if (endA < endB)
                    difference = -1;

                if (endA > endB)
                    difference = 1;

                difference = 0;
            }

            if (difference == 0) {
                difference = (Number(a['id'].split("-")[1]) - Number(b['id'].split("-")[1]));
            }
            return difference * multiplier;
        });
    }

    sortByEmployees(asc, initialSort = false, sortPressed) {
        let sortedData = [...this.allData];
        let employees = this.props.autoCompleteData.employees;
        let multiplier = asc ? -1 : 1;

        sortedData = sortedData.sort((a, b) => {
            let aEmployees;
            let bEmployees;

            let ids = a.user_ids ? a.user_ids : [];
            if(ids.length == 1 && ids[0] == "0") {
                ids = [];
            }
            aEmployees = employees.filter(el => ids.indexOf(el.id) > -1);
            aEmployees = aEmployees.map(el => el.employee);


            ids = b.user_ids ? b.user_ids : [];
            if(ids.length == 1 && ids[0] == "0") {
                ids = [];
            }
            bEmployees = employees.filter(el => ids.indexOf(el.id) > -1);
            bEmployees = bEmployees.map(el => el.employee);

            aEmployees.sort();
            bEmployees.sort();

            let length = aEmployees.length > bEmployees.length ? aEmployees.length : bEmployees.length;
            for(let i = 0; i < length; i++) {
                if(aEmployees.length - 1 < i) {
                    return multiplier;
                }
                if(bEmployees.length - 1 < i) {
                    return -multiplier;
                }
                let result = aEmployees[i].localeCompare(bEmployees[i], "fi");
                if(result != 0) {
                    return result * (-multiplier);
                }
            }

            return (Number(a['id'].split("-")[1]) - Number(b['id'].split("-")[1])) * multiplier;
        });
        this.allData = [...sortedData];
        this.setState({data: sortedData.slice((this.state.listSettings.page - 1) * this.state.listSettings.perPage, this.state.listSettings.page * this.state.listSettings.perPage)});

        if  (sortPressed || (!initialSort && !this.props.listRowEdited)) {
            this.resetPage();
            this.setState({ listSettings: {...this.state.listSettings, sort: {colName: "users", asc}} }, () => this.saveStickySearch(this.state.listSettings));
        }
    }

    getSelectedTasks = () => {
        const allCheckedExcept = this.list.current.getAllCheckedExcept();
		let selected;
		
        if (allCheckedExcept) {
			let data = this.props.data.slice().map(el => el.id);
			selected = data.filter(id => {
				return !allCheckedExcept[id]; 
			});
        } else {
			selected = this.list.current.getCheckedRows();
		}

        return selected;
    }

    getCheckedData = () => {
        return this.list.current.getCheckedData();
    }

    onToolbarExportClick = () => {
        this.export("xlsx", this.fields);
    }

    onToolbarEditClick = () => {
        let tasks = this.getSelectedTasks();

        tasks = tasks.map(el => {
            return el.id;
        });
        this.openDialog('editSelections', {
            tasks: tasks,
            text: this.tr("Do you want to edit ${task_count} tasks?", {task_count: tasks.length}),
            process: "delete_multiple",
        });
    }

    onToolbarDeleteClick = () => {
        const checkedRows = this.getSelectedTasks();
        let hasRecurring = false;
        let deleteRows = [];
        _.forEach(checkedRows, row => {
            const t = this.props.data.find(x => x.id === row);

            if (!t) {
                return;
            }

            const type = row.split("-")[0];
            if (type !== "vp" && type !== "vt")
                deleteRows.push(row);

            if (t.recurrence_parent_id > 0)
                hasRecurring = true;
        })
        this.openDialog("delete", { checkedRows: deleteRows, hasRecurring });
    }

    export(target, fields) {
        const { filters } = this.props;
        const columns = this.list && this.list.current ? this.list.current.visibleColumnsOrder : this.state.listColumns;
        const exportHeaders = [];        
        let params   = {};
        let selected = this.getSelectedTasks();
        if (selected.length === 0)
            selected = this.props.data ? this.props.data.slice().map(el => el.id) : [];

        if (!columns || selected.length === 0) {
            this.props.enqueueSnackbar(this.tr("Nothing to export!"), {
                variant: "warning",
            });
            return;
        }

        let exportRows = [];

        _.forEach(selected, row => {
            const type = row.split("-")[0];
            if (type !== "vp" && type !== "vt")
                exportRows.push(row);
        })

        _.forEach(columns, column => {
            _.forEach(fields, (field, i) => {
                if(column == field.name && column != "expand" && column != "checked"){
                    exportHeaders.push(field.header);
                    if (column === "project_id")
                    exportHeaders.push(this.tr("Path"));
                }
            })
        })

        params.order = columns;
        params.columnNames = exportHeaders;
        params.file_name = this.tr("resources_list_export");
        if (this.state.listSettings?.sort) {
            params.sort = {
                name: this.state.listSettings?.sort.colName,
                asc: this.state.listSettings?.sort.asc
            }
        }

        DataHandler.postArrayBuffer({ ...params, url: "resourcing/list_export", export: target }, {
                status_types: this.statusTypes, 
                priorities: this.props.autoCompleteData.priorities.map(x => ({id: x.id, label: x.label})),
                getDataParams: getGetParameters(filters), 
                ids: exportRows}, true).done(response => {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            FileSaver.saveAs(blob, `${params.file_name}.${target}`);
        });
    }

    resetPage = () => {
        this.setState({ page: 1}, () => this.list.current.setPage(1));
    }

    showMoveTasksToNewUserDialog = (singleTask = undefined) => {
        let tasks = [];
        let single = false;

        console.log({singleTask})

        if (singleTask) {
            tasks.push(singleTask);
            single = true;
        } else {
            tasks = this.list.current.getCheckedData();
        }

        _.forEach(this.getSelectedTasks(), row => {
            const t = this.props.data.find(x => x.id === row);

            if (!t) {
                return;
            }

            const type = row.split("-")[0];
            if (type !== "vp" && type !== "vt")
                tasks.push(t);
        });

        if (tasks.length < 1) {
            return;
        }

        const data = {
            employees: this.props.autoCompleteData.employees,
            tasks: tasks,
            single: single
        };

        this.setState({ userChangeDialogData: data });
    };

    _renderUsersChangeDialog = () => {
        if (!this.state.userChangeDialogData) return null;
        return (
            <MoveTasksUsersDialog
                data={this.state.userChangeDialogData}
                onSave={() =>
                    this.setState({ userChangeDialogData: false }, () => {
                        this.unCheckAll();
                        this.props.updateResourcingData();
                    })
                }
                onClose={() => this.setState({ userChangeDialogData: false })}
            />
        );
    };

    render() {
        const { taimerAccount: { resourcingDisablePriority, resourcingDisableSkill } } = this.context;

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        const fields = this.fields.filter(x => !((x.name === "skill" && (resourcingDisableSkill || this.props.autoCompleteData.skills.length == 0)) || (x.name == "priority" && resourcingDisablePriority)) );

        const newRow = {
            text: "",
        };

        return (
            <React.Fragment>
                {this._renderUsersChangeDialog()}
                <List
                    ref={this.list}
                    data={this.state.data}
                    columns={fields}
                    sharedData={this.props.autoCompleteData}
                    height="fitRemaining"
                    className="resourcingList"
                    listRowType={ResourcingListRow}
                    newRow={newRow}
                    showNoResultsMessage={this.dataInitializedOnce && (!this.state.data || this.state.data.length === 0)}
                    userListSettingsKey="resourcing_list"
                    noStateData={true}
                    idType="string"
                    rowProps={{
                        checkPrivilege: this.props.checkPrivilege,
                        statusTypes: this.statusTypes,
                        updateData: this.props.updateData,
                        moveTaskToNewUser: this.showMoveTasksToNewUserDialog,
                        showWorkhourDialog: this.showWorkhourDialog,
                        enqueueSnackbar: this.props.enqueueSnackbar,
                        showTaskDialog: this.showTaskDialog,
                        onUpdate: this.rowUpdate,
                        onCheck: (checked, data, fromCheckAll) => {
                            if(!fromCheckAll) {
                                if(checked)
                                    this.checkedTasks.push(data)
                                else
                                    this.checkedTasks = this.checkedTasks.filter(p => p.id !== data.id);
                            } else {
                                if(checked)
                                    this.checkedTasks = [...this.allData];
                                else {
                                    this.checkedTasks = [];
                                }
                            }
                        },
                        onDelete: data => {
                            this.openDialog('delete', {
                                id: data.id,
                                name: data.text,
                                process: "delete_one",
                                type: data.type,
                            });
                        },
                        onDeleteTask: this.deleteTask,
                    }}
                    onSortRows={(colName, asc) => this.sortRows(colName, asc, false, true)}
                    saveColumnConfig={true}
                    page={this.state.listSettings.page}
                    controlPage={true}
                    showPageSelector={true}
                    onPerPageChange={this.perPageChanged}
                    onPageChange={this.pageChanged}
                    perpage={this.state.listSettings.perPage}
                    pageCount={Math.ceil(this.allData.length / this.state.listSettings.perPage)}
                    totalCount={this.allData.length}
                    enableToolbar={true}
                    onToolbarExportClick={this.onToolbarExportClick}
                    onToolbarEditClick={this.onToolbarEditClick}
                    onToolbarDeleteClick={this.onToolbarDeleteClick}
                    useAllCheckedExcept={true}
                    useHSRightPadding
                />
                {
                    Dialog &&
                    <Dialog
                        open
                        onDialogClose={this.closeDialog}
                        onClose={this.closeDialog} // for workhour entry
                        onDialogSave={this.confirmDialog}
                        data={this.state.dialogData}
                        dialogType={this.dialogProps[this.state.currentDialog].dialogType}
                        dialogProps={{...this.state.currentDialogProps}}
                        item={this.state.dialogData.item} // for workhour entry
                    />
                }
            </React.Fragment>
        );
    }
}

ResourcingList.defaultProps = {
    data: [] 
};

export default withSnackbar(ResourcingList);