import ListCell from "./../ListCell";
import { SettingsContext } from './../../SettingsContext';


class CurrencyRatePresentCell extends ListCell {
	static contextType = SettingsContext;

	presentValue(value) {
		const { name, companyCurrency, rateCurrency } = this.props.owner.props;

		if (name == 'currency_rate')
			return	`1 ${companyCurrency} = ${value} ${rateCurrency}`;
		else
			return  `1 ${rateCurrency} = ${value} ${companyCurrency}`;

	}

}

export default CurrencyRatePresentCell;