import React from 'react';
import { Button, Drawer, FormControlLabel, Switch } from '@mui/material';
import styles from './ViewOptionsSlider.module.scss';
import TaimerComponent from '../../TaimerComponent';
import ContentSlider from '../../general/ContentSlider';
import Slider from '../../general/Slider';
import { ProjectsShowMode, validview, ViewOptionName, ViewOptions } from '../ResourcingView';

interface Props {
    view: validview;
    open: boolean;
    onClose?: () => void;

    // toggleViewOption: (name: ViewOptionName, value: boolean | undefined | ProjectsShowMode) => void;
    // setProjectsShowMode: (value: ProjectsShowMode) => void;

    setViewOptions: (viewOptions: Partial<ViewOptions>) => void;

    viewOptions: ViewOptions;
}

interface State {
    viewOptions: ViewOptions;
}

interface OptionGroup {
    key: string;
    label: string;

    options: Option[];
    visible?: boolean;
}

interface Option {
    key: string;
    label: string;
    action: () => void;
    checked: boolean;
    visible?: boolean;
}

export default class ViewOptionsSlider extends TaimerComponent<Props, State> {
    constructor(props: Props, context) {
        super(props, context, 'resourcing/components/ViewOptionsSlider');
        this.state = {
            viewOptions: props.viewOptions,
        };
    }

    componentDidMount = () => {
    };

    componentDidUpdate = (oldProps: Props) => {
        // Reset viewOptions when opening or externally updated
        if (!oldProps.open && this.props.open || oldProps.viewOptions !== this.props.viewOptions) {
            this.setState({
                viewOptions: this.props.viewOptions,
            });
        }
    };

    toggleViewOption = (name: ViewOptionName, value: boolean | undefined | ProjectsShowMode = undefined) => {
        const viewOptions = { ...this.state.viewOptions };

        const currentValue = viewOptions[name];
        let newValue = currentValue;

        if (name === 'projectsShowMode') {
            viewOptions[name] = Number(value) || ProjectsShowMode.All;
        } else {
            if (value === undefined) {
                newValue = !newValue;
            } else {
                newValue = Boolean(value);
            }

            viewOptions[name] = newValue;
        }

        // showMatchesAndChildren and showMatchesAndChildren can't be active at same time
        if (name === 'showWholeTrees' && viewOptions.showWholeTrees) {
            viewOptions.showMatchesAndChildren = false;
        } else if (name === 'showMatchesAndChildren' && viewOptions.showMatchesAndChildren) {
            viewOptions.showWholeTrees = false;
        }

        this.setState({ viewOptions });
    }

    setProjectsShowMode = (mode: ProjectsShowMode) => {
        const { viewOptions } = this.state;

        if (mode === viewOptions.projectsShowMode) {
            mode = ProjectsShowMode.All;
        }

        this.toggleViewOption('projectsShowMode', mode);
    }

    isValid(viewOptions: ViewOptions) {
        if (!viewOptions.showOnGoing && !viewOptions.showDone && !viewOptions.showOverdue) {
            return false;
        }

        return true;
    }

    save = () => {
        const { setViewOptions, onClose } = this.props;
        const { viewOptions } = this.state;

        setViewOptions(viewOptions);

        onClose?.();
    }

    render() {
        const { open, onClose, view, ...rest } = this.props;
        const { viewOptions } = this.state;
        const { tr, toggleViewOption, setProjectsShowMode } = this;

        const options: OptionGroup[] = [
            {
                key: 'projects',
                label: 'Projects',
                visible: view !== 'list',
                options: [
                    {
                        key: 'projectsShowMode2',
                        label: 'Show Projects Without Tasks',
                        action: () => setProjectsShowMode(ProjectsShowMode.WithoutTasks),
                        checked: viewOptions.projectsShowMode === ProjectsShowMode.WithoutTasks,
                    },
                    {
                        key: 'projectsShowMode1',
                        label: 'Show Projects With Tasks',
                        action: () => setProjectsShowMode(ProjectsShowMode.WithTasks),
                        checked: viewOptions.projectsShowMode === ProjectsShowMode.WithTasks,
                    },
                    {
                        key: 'showWholeTrees',
                        label: 'Show whole trees',
                        action: () => toggleViewOption('showWholeTrees'),
                        checked: viewOptions.showWholeTrees,
                    },
                    {
                        key: 'showMatchesAndChildren',
                        label: 'Show matches and their children',
                        action: () => toggleViewOption('showMatchesAndChildren'),
                        checked: viewOptions.showMatchesAndChildren,
                    },
                ]
            },
            {
                key: 'display',
                label: 'Tasks',
                options: [
                    {
                        key: 'showOnGoing',
                        label: 'Show ongoing tasks',
                        action: () => toggleViewOption('showOnGoing'),
                        checked: viewOptions.showOnGoing,
                    },
                    {
                        key: 'showOverdue',
                        label: 'Show overdue tasks',
                        action: () => toggleViewOption('showOverdue'),
                        checked: viewOptions.showOverdue,
                    },
                    {
                        key: 'showDone',
                        label: 'Show completed tasks',
                        action: () => toggleViewOption('showDone'),
                        checked: viewOptions.showDone,
                    },
                    {
                        key: 'showMilestones',
                        label: 'Show milestones',
                        action: () => toggleViewOption('showMilestones'),
                        checked: viewOptions.showMilestones,
                        visible: view === 'gantt' || view === 'list',
                    },
                    {
                        key: 'showSplits',
                        label: 'Show splits',
                        action: () => toggleViewOption('showSplits'),
                        checked: viewOptions.showSplits,
                        visible: view === 'gantt',
                    },
                ],
            },
            {
                key: 'calculate',
                label: 'List settings',
                options: [
                    {
                        key: 'includeNegative',
                        label: 'Include negative values in calculations for remaining value',
                        action: () => toggleViewOption('includeNegative'),
                        checked: viewOptions.includeNegative,
                    },
                    {
                        key: 'ganttPercentage',
                        label: 'Show percentage in tracker and remaining columns',
                        action: () => toggleViewOption('ganttPercentage'),
                        checked: viewOptions.ganttPercentage,
                        visible: view === 'gantt',
                    },
                ],
            },
            {
                key: 'totals',
                label: 'Totals rows',
                visible: view !== 'list',
                options: [
                    {
                        key: 'showTotalVisible',
                        label: 'Show your visible tasks total row',
                        action: () => toggleViewOption('showTotalVisible'),
                        checked: viewOptions.showTotalVisible,
                        
                    },
                    {
                        key: 'showTotalOwn',
                        label: 'Show all your tasks total row',
                        action: () => toggleViewOption('showTotalOwn'),
                        checked: viewOptions.showTotalOwn,
                    },
                    {
                        key: 'showTotalRow',
                        label: 'Show total row',
                        action: () => toggleViewOption('showTotalRow'),
                        checked: viewOptions.showTotalRow,
                    },
                ],
            }
        ];

        return (
            <Slider title={tr('View Options')} onClose={onClose} {...rest} open={open} anchor="right">
                <div className={styles.root}>

                    <div className={styles.groups}>
                        {options.filter(x => x.visible !== false).map(group => <div key={group.key} className={styles.group}>
                            <h2>{tr(group.label)}</h2>

                            {group.options.filter(x => x.visible !== false).map(opt => <div className={styles.option_row}>
                                <FormControlLabel
                                    control={<Switch color="primary" onChange={opt.action} checked={opt.checked} />}
                                    label={tr(opt.label)}
                                />
                            </div>)}
                        </div>)}
                    </div>

                    <div className={styles.actions}>
                        <Button onClick={(e) => onClose?.()} variant="text" size="large">
                            {this.tr('Cancel')}
                        </Button>

                        <Button size="large" disabled={!this.isValid(viewOptions)} onClick={this.save}>{this.tr('Save')}</Button>
                    </div>
                </div>
            </Slider>
        );
    }
}