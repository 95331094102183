import TaimerComponent from '../TaimerComponent';
import { Tooltip } from "@mui/material";
import styles from './StyledTooltip.module.scss';

interface Props {
    content: any;
    placement: "left" | "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top" | undefined;
    children: any;
}

const getTooltipProps = (content, placement) => {
    return {
        classes: { tooltip: 'darkblue-tooltip' },
        title: renderContent(content),
        placement,
        arrow: true
    }
}

const renderContent = (content) => {
    return (
        <div className={styles.styledTooltip}>
            {content}
        </div>
    )
}

export default class StyledTooltip extends TaimerComponent<Props> {

    render() {
        const { content, placement, children } = this.props;

        const tooltipProps = getTooltipProps(content, placement);

        return (
            <Tooltip {...tooltipProps}>
                {children}
            </Tooltip>
        )
    }
}

export {
    getTooltipProps
}