import React from 'react';
import { isEqual, cloneDeep } from 'lodash';

/* css */

/* material-ui */

/* others */
import { Bar } from 'react-chartjs-2';
import TaimerComponent from "../../../TaimerComponent";

class ActivitiesInsightChart extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "dashboard/insights/activities/ActivitiesInsightChart");

        this.state = {
            datasets: cloneDeep(this.props.datasets)
        }
    }

    componentDidUpdate = () => {
        if (!isEqual(this.props.datasets, this.state.datasets)) {
            this.setState({ datasets: cloneDeep(this.props.datasets) });
        }
    }

    render() {
        const { datasets } = this.state;
        return (
            <div className="grid-block chart">
                <Bar 
                    data={{
                        labels: Object.values(this.props.labels || {}),
                        datasets,
                    }} 
                    options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                enabled: true,
                            },
                        },
                        elements: {
                            line: {
                                fill: false
                            }
                        },
                        scales: {
                            y: {
                                type: 'linear',
                                display: true,
                                stacked: true,
                                position: 'left',
                                beginAtZero: true,
                            },
                            x: {
                                stacked: true,
                                grid: { display: false }
                            }
                        }
                    }} />
            </div>
        )
    }
}



export default ActivitiesInsightChart;