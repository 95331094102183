import React from 'react';
import ListCell from "../ListCell";
import { SettingsContext } from './../../SettingsContext';
import TreeSelect from '../../general/TreeSelect';
import _ from 'lodash';
import { resolve } from 'path';

class TreeCell extends React.Component {
	static contextType = SettingsContext;

	static defaultProps = {
        width: 100,
		value: 0,
		editable: true,
		onEdited: () => {},
		onEnter: () => {},
		listCellProps: {},
		listCellType: ListCell
	};
	
	allOptions = [];

	constructor(props) {
		super(props);

		this.state = { editable: props.editable };
		
		this.allOptions = this.flatten(props.options);

        this.focusOnEditor 	 = this.focusOnEditor.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.options, this.props.options)) {
			this.allOptions = this.flatten(this.props.options);
		}
	}

	focusOnEditor() {
		// this.datePicker.current.focusOnInput()
	}

	onChange = (e) => {
		this.props.onEdited(e.target.value);
	}

	flatten = (options) => {
		const res = [];

		this.flattenLevel(res, options);

		return res;
	}

	flattenLevel = (res, options) => {
		if (!options)
			return;

		for (const item of options) {
			res.push(item);

			if (item.children && item.children.length > 0) {
				this.flattenLevel(res, item.children);
			}
		}
	}

	render() {
		const { treeSelectProps, listCellType: ListCellType, options, value, error } = this.props;

		const actualValue = this.allOptions.find(x => x.id == value);

		const listCellProps  = Object.assign({
			ref: this.listCell,
			owner: this,
			width: this.props.width,
			value: actualValue ? actualValue.name : "",
			editable: this.props.editable,
			// onEnterEditMode: () => this.fastEditHandler(true)
		}, this.props.listCellProps);

		// listCellProps.value = (listCellProps.value == "Invalid Date") ? "" : listCellProps.value;

		let extraProps = {
			SelectProps: {
				// autoWidth: true,
			},
			...treeSelectProps,
		}

		return (
			// // <ClickAwayListener onClickAway={this.onClickAway}>
				<ListCellType {...listCellProps}>
					<TreeSelect name="treeCell" fullWidth value={actualValue && actualValue.id || 0} options={options} onChange={this.onChange} {...extraProps} error = {actualValue ? false: error} />
				</ListCellType>
			// </ClickAwayListener>
		);
	}
}

export default TreeCell;