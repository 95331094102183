import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { prefixLink } from '../../helpers';
import AutoCompleteCell from "../cells/AutoCompleteCell";
import CheckboxCell from "../cells/CheckboxCell";
import DateCell from "../cells/DateCell";
import EditableStatusCell from "../cells/EditableStatusCell";
import TextAreaCell from "../cells/TextAreaCell";
import TextInputCell from "../cells/TextInputCell";
import TreeStructureIndicatorCell from "../cells/TreeStructureIndicatorCell";
import CurrencyListCell from "../CurrencyListCell";
import LinkListCell from "../LinkListCell";
import ListCell from "../ListCell";
import PropsOnlyListRow from "../PropsOnlyListRow";
import List from "./../List";
import { makePropertyMap } from "./../ListUtils";

import { format } from "date-fns";
import _ from 'lodash';
import clone from "lodash/clone";

import BarChart from '@mui/icons-material/BarChart';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import { ClickAwayListener, MenuItem, Popper } from '@mui/material';
import ContextMenu from '../../general/ContextMenu';
import { ReactComponent as QuoteDollar } from "./../icons/projectlist_quote_dollar.svg";

import "./ProjectListRow.css";

import { Description } from '@mui/icons-material';
import { withSnackbar } from 'notistack';
import ProjectStatusReasonDialog from '../../dialogs/ProjectStatusReasonDialog';
import DataHandler from "../../general/DataHandler";
import { ReactComponent as ActivateIcon } from '../../general/icons/Activate.svg';
import { ReactComponent as AddIcon } from '../../general/icons/add.svg';
import { ReactComponent as CreateSubProjectIcon } from '../../general/icons/AddSubsidairy.svg'; //svg:n nimessä typo pitäisi olla subsidiary, mutta älä vaihda.
import { ReactComponent as AssignAsSubProjectIcon } from '../../general/icons/Add_subunit.svg';
import { ReactComponent as ArchiveIcon } from '../../general/icons/Archive.svg';
import { ReactComponent as CopyIcon } from '../../general/icons/copy.svg';
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import { ReactComponent as ViewIcon } from '../../general/icons/view.svg';
import Link from '../../general/Link';
import QuoteWizard from '../../general/QuoteWizard';
import VersionContentManager from '../../general/VersionContentManager';
import TreeOption from "./../TreeOption";

// If/when this tree-structured select is needed in a list elsewhere, don't copy and paste; rather ask the original author to refactor it into a re-usable Option component.

class QuotePopUpRow extends PropsOnlyListRow {
    constructor(props) {
        super(props); 

        this.quantityTypes = {
            1: this.tr("qty"),
            2: this.tr("h")
        };
    }

    defineClassName() {
        return `quotePopUpRow ${this.props.data.uiRowType}`; 
    }


    defineCells() {
        const { data, rowProps: { currency } }   = this.props;
        let cells        = {};
        let quantityType = "";

        if(data.uiRowType === "row") {
            if(isNaN(Number(data.quantityType)) || Number(data.quantityType) === 0 || !this.quantityTypes.hasOwnProperty(Number(data.quantityType)))
                quantityType = data.quantityType;
            else
                quantityType = this.quantityTypes[Number(data.quantityType)]
		}

        switch(data.uiRowType) {
            case "header":
                cells = {
                    name: <ListCell value={data.name} fontWeight={data.type !== "2" ? "heavy" : "normal"} editable={false}  />,
                    quantity: <ListCell editable={false} />, 
                    cost: <ListCell editable={false} />, 
                    value: <ListCell editable={false} />,
                    vat: <ListCell editable={false} />,
                    vat_total: <ListCell editable={false} />,
                    discountPercentage: <ListCell editable={false} />,
                    total_no_vat: <ListCell editable={false} />,
                    quantity_delivered: <ListCell editable={false} />,
                    quantity_invoiced: <ListCell editable={false} />,
                };
                break;
            
            case "row":
                cells = {
                    name: <ListCell value={data.name} fontWeight={"normal"} editable={false}  />,
                    quantity: <ListCell textAlign="left" value={`${data.quantity} ${quantityType}`} editable={false}  />, 
                    cost: <CurrencyListCell textAlign="right" value={data.cost} currency={currency} editable={false}  />, 
                    value: <CurrencyListCell textAlign="right" value={data.value} currency={currency} editable={false}  />,
                    vat: <ListCell textAlign="right" value={data.vat} editable={false}  />,
                    vat_total: <CurrencyListCell textAlign="right" value={data.vat_total} currency={currency} editable={false}  />,
                    discountPercentage: <ListCell textAlign="right" value={data.discountPercentage} editable={false}  />,
                    total_no_vat: <CurrencyListCell textAlign="right" value={data.total_no_vat} currency={currency} editable={false}  />,
                    quantity_delivered: <ListCell fontWeight="bold" textAlign="right" value={data.quantity_delivered} editable={false}  />,
                    quantity_invoiced: <ListCell fontWeight="bold" textAlign="right" value={data.quantity_invoiced} editable={false}  />,
                };
                break;

            case "total":
                cells = {
                    name: <ListCell editable={false}  />,
                    quantity: <ListCell editable={false} />, 
                    cost: <CurrencyListCell value={data.cost} currency={currency} editable={false} textAlign="right" fontWeight="heavy" />, 
                    value: <ListCell editable={false} />,
                    vat: <ListCell editable={false} />,
                    vat_total: <CurrencyListCell value={data.vat_total} currency={currency} editable={false} textAlign="right" fontWeight="heavy" />,
                    discountPercentage: <ListCell editable={false} />,
                    total_no_vat: <CurrencyListCell value={data.total_no_vat} currency={currency} editable={false} textAlign="right" fontWeight="heavy" />,
                    quantity_delivered: <ListCell editable={false} />,
                    quantity_invoiced: <ListCell editable={false} />,
                };
                break;
        }

        return cells;
    }
}

const dropdownTypes = ["dropdown", "radio"];

const nUndef = v => v !== undefined;
const nUndefGtZero = v => v !== undefined && v > 0;
const nEmptyString = v => typeof v === "string" && v.trim().length > 0;


class ProjectListRow extends PropsOnlyListRow {
	static rowDimensions = {
		height: "44px",
		lineHeight: "44px"
	};

    static dateCellPopperProps = {
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: () => {
                        return [0, 12];
                    }
                }
            }
        ]
    };

	constructor(props, context) {
        super(props, { nonValids: [], tagOptions: props.sharedData['project_tags'] }, { childRowType: ProjectListRow }, "list/rows/ProjectListRow");

        this.context     = context;
		this.popperRoot  = React.createRef();
		this.customCells = {
			dropdown: AutoCompleteCell,
			radio: AutoCompleteCell,
			multiselect: AutoCompleteCell,
			text: TextInputCell,
			textarea: TextAreaCell,
			date: DateCell,
			link: TextInputCell,
		};
		this.salesStateOrderMap = {};
		const actualBobCount = this.props.sharedData.bobOptions.filter(bob => bob.data.deleted != 1).length;

		this.newRowValidators = {
			customers_id: 			  { validator: nUndefGtZero, columnName: "customer_id" },
			project:			      { validator: nEmptyString,  columnName: "name" },
			type: 		  			  { validator: nUndef, columnName: "project_type" },
			projects_sales_states_id: { validator: nUndef, columnName: "sales_state" },
			locked: 				  { validator: nUndef, columnName: "status" },
		};

        const { 
            addons, 
            company 
        } = this.props.rowProps;
        const { 
            force_teams_branchofbusiness_for_projects, 
            mandatory_team_selection_for_projects
        } = this.context.taimerAccount;

        if(mandatory_team_selection_for_projects 
            && 
            addons?.dimensions?.used_by_companies?.indexOf(company)> -1) {
            this.newRowValidators.dimension_teams_id = {
                validator: nUndef,
                columnName: "dimension_team"
            };
        }

		//if categories exist that aren't deleted add this
		if (actualBobCount > 0) {
			this.newRowValidators.branchofbusiness_id = { validator: nUndef, columnName: "branchofbusiness" };
		}

		for(const customField of props.sharedData.custom_fields) {
			let { required, show_in_details, name, id, form } = customField;
			if (required > 0 && show_in_details > 0 && form == 0) {
				name = name.toLowerCase();
				Object.assign(this.newRowValidators, {[`custom_${id}`]: {validator: nUndef, columnName: `custom_${id}`}});
			}
		}

		this.deletableReasons = {
            projects: this.tr("deletable_reason_projects"),
            workhours: this.tr("deletable_reason_workhours"),
            projectentries: this.tr("deletable_reason_projectentries"),
            attachments: this.tr("deletable_reason_attachments"),
            crm: this.tr("deletable_reason_crm"),
            invoices: this.tr("deletable_reason_invoices"),
            projects_resources: this.tr("deletable_reason_projects_resources"),
            traveling_expenses: this.tr("deletable_reason_traveling_expenses"),
            expenses: this.tr("deletable_reason_expenses"),
            resources: this.tr("deletable_reason_resources"),
            deadlines: this.tr("deletable_reason_deadlines"),
            contractbilling: this.tr("deletable_reason_contractbilling"),
            subcontracts: this.tr("deletable_reason_subcontracts"),
            bills: this.tr("deletable_reason_bills"),
            sales_quotes: this.tr("deletable_reason_sales_quotes"),
        };

		this.saveNewRow          = this.saveNewRow.bind(this);
		this.defineClassName     = this.defineClassName.bind(this);
        this.defineCells         = this.defineCells.bind(this);
        this.getStatesToValidate = this.getStatesToValidate.bind(this);
        this.fetchQuotes         = this.fetchQuotes.bind(this);
        this.copy                = this.copy.bind(this);
        this.closeQuotePopUp     = this.closeQuotePopUp.bind(this);
        this.checkDeletable      = this.checkDeletable.bind(this);
		this.archiveProject      = this.archiveProject.bind(this);
		this.wonProject          = this.wonProject.bind(this);
	}

    componentDidUpdate(prevProps, prevState) {
        const { data, rowKey }   = this.props;
        const { data: prevData } = prevProps;

        if(parseInt(prevData[rowKey]) < 0 && data[rowKey] !== prevData[rowKey]) {
            this.clearInvalidFields();
        }
    }

	shouldComponentUpdate(nextProps, nextState) {
		if ((this.state.tagOptions.length !== nextState.tagOptions.length) || (this.props.rowProps?.currency != nextProps.rowProps?.currency)) {
			// setting cells manually here so the currency is updated correctly on this virtualized list
			this.setCells(nextProps);
			return true;
		}

		return super.shouldComponentUpdate(nextProps, nextState);
	}

	// Check that all needed fields have some value.
    saveNewRow() {
        const invalids        = [];
		const data            = this.props.data;
        const isCreatingNew = data._isCreatingNew;
	 
		data.parentid = data.parentId;

		if (this.props.sharedData.company)
			data.companies_id = this.props.sharedData.company;
 
        // Allow creating a subproject for a customer other than the parent's.
        // if(data.parentId !== null)
		// data.customers_id = this.props.parentProps.data.customers_id;

		const projectSalesStateOrder = this.salesStateOrderMap[data.projects_sales_states_id];

		for (const i in this.newRowValidators) {
			const { validator, columnName } = this.newRowValidators[i];
			if (data.type && this.newRowValidators[i].pipelines_id && data.type === this.newRowValidators[i].pipelines_id + "") {
				if ((!projectSalesStateOrder || !this.newRowValidators[i].salesStateOrder || Number(projectSalesStateOrder) > Number(this.newRowValidators[i].salesStateOrder)) && !validator(data[i])) 
					invalids.push(columnName);
			}
			else if ((!data.type || !this.newRowValidators[i].pipelines_id) && !validator(data[i]))
				invalids.push(columnName);
		}

		this.setInvalidFields(invalids);
		
		if(invalids.length > 0) {
			this.props.rowProps.enqueueSnackbar(this.tr(`Required fields not filled.`), {
				variant: "error",
			});
			return;
        }

        if (isCreatingNew) {
            return;
        }

        this.setData("_isCreatingNew", true);

        this.create(data).then(response => {
            if(!response.hasOwnProperty("id") || !response.hasOwnProperty("project_id"))
                return; // Show some error or something.

                this.context.functions.sendMixpanelEvent('create_project', {
                    'origin_point': 'project_list_row',
                });
                this.context.functions.sendMixpanelPeople('set_once', {
                    'first_create_project_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('set', {
                    'last_create_project_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('increment', {
                    'lifetime_create_project': 1,
                });

            setTimeout(async () => {
                const createdProject = await DataHandler.get({ url: `projects/list/${response.id}` });

                this.setData({
                    ...createdProject,
                    id: String(response.id),
                    project_id: String(response.project_id),
                    _projectIdIsDuplicate: false
                });
            }, 500);

        }).catch(response => {
            // this.isCreatingNew = false;
            
            this.setData("_isCreatingNew", false);
           
            if(response.status === 403 && response.responseJSON.hasOwnProperty("error")) {
				let errorMsg = "Error in saving the project!";

				if (response.responseJSON.error === "EXISTING_PROJECT_ID") {
					this.setData({ _projectIdIsDuplicate: true });
					errorMsg = "The given project number already exists. Please choose another project number.";
				} else if (response.responseJSON.error === "PARENT_LOCKED") {
					errorMsg = "Cannot add subproject. Parent is locked!";
				} else if(response.responseJSON.error === "CUSTOMER_NOT_ALLOWED") {
                    errorMsg = "Creating projects for this account is not allowed.";
                }

                this.props.rowProps.enqueueSnackbar(this.tr(errorMsg), {
                    variant: "error" 
                });
            } else {
                // General error. 

                this.props.rowProps.enqueueSnackbar(this.tr("There was an error saving the project. You can try saving again, but if the problem persists, please contact Taimer support."), {
                    variant: "error" 
                });
            }
        });
    }


    async fetchQuotes() {
        const quotes = await DataHandler.get({ url: `projects/${this.props.data.id}/quotes` });
        const final  = [];

        quotes.forEach(q => {
            const quote = { id: q.id, name: q.name, rows: [], active: q.active === "1" }; 

            if(q.headers === undefined)
                return [];

            q.headers.forEach(h => {
                h.uiRowType = "header";

                quote.rows.push(h); 

                const headerTotals = { id: h.id + 9991999, cost: 0, vat_total: 0, total_no_vat: 0, margin: 0, uiRowType: "total" };

                h.rows.forEach(r => {
                    r.discount  = parseFloat(r.discountPercentage) / 100;
                    r.uiRowType = "row";

                    // asalskdalskjd
                    if(r.type === "2")
                        r.uiRowType = "header";

                    const tempVatTotal   = r.quantity * (r.vat / 100) * r.value;
                    const tempTotalNoVat = r.quantity * r.value;

                    r.vat_total    = tempVatTotal - (r.discount * tempVatTotal);
                    r.total_no_vat = tempTotalNoVat - (r.discount * tempTotalNoVat);

                    r.quantity_delivered = ["3", "4"].indexOf(r.type) > -1 ? `${r.quantity_delivered} / ${r.quantity}` : "–";
                    r.quantity_invoiced  = ["3", "4"].indexOf(r.type) > -1 ? `${r.quantity_invoiced} / ${r.quantity}` : "–";

                    headerTotals.cost         += parseFloat(r.cost);
                    headerTotals.vat_total    += r.vat_total;
                    headerTotals.total_no_vat += r.total_no_vat;

                    quote.rows.push(r);
                });

                quote.rows.push(clone(headerTotals));
            });

            final.push(quote);
        });

        final.sort((a, b) => {
            if(a.active && !b.active)
                return -1;
            if(!a.active && b.active)
                return +1;

            return 0;
        });

        return final;
    }


	copy(evt, id) {
		this.props.rowProps.updateView({ module: 'projects', action: 'view', id: '', copyId: id }, evt.button === 1);
	}
	

    closeQuotePopUp() {
        this.setData({ _quotePopUpOpen: false, quotes: undefined });
    }


    defineClassName() {
        const childClass       = this.props.isChild ? "child" : "";
        const matchedSearchClass = this.props.rowProps.showTreeStructures && this.props.rowProps.showWholeTrees && this.props.data.matched_search ? "matchedSearch" : "";

        return `projectListRow ${childClass} ${matchedSearchClass}`;
	}


	onCtrlS() {
		this.props.data.id < 0 ? this.saveNewRow() : this.update();
    }


	getStatesToValidate() {
		const { sharedData: { pipelines, sales_states } } = this.props;
		const { data: { projects_sales_states_id } } = this.props;

		const currentSalesSate = sales_states.find(x => x.id == projects_sales_states_id);

		if (!currentSalesSate)
            return [0];

		const statesToValidate = sales_states.filter(x => x.projects_pipelines_id == currentSalesSate.projects_pipelines_id && x.stateorder <= parseInt(currentSalesSate.stateorder)).map(x => parseInt(x.id));

		return [0, ...statesToValidate]	
	}

	
	sortSalesStates(a, b) {
		const state1 = Number(a.stateorder);
		const state2 = Number(b.stateorder);

		if (state1 < state2) {
			return -1;
		}
		if (state2 < state1) {
			return 1;
		}
		return 0;
	}


	checkDeletable() {
		DataHandler.get({ url: `projects/${this.props.data.id}/check_deletable`, company: this.props.data.companies_id }).done(response => {
			const reasons = [];
			for(const i in response.properties) {
				if(response.properties[i] > 0) {
					reasons.push(i);
				}
			}
			this.setData({deletable: response.deletable, deletableReasons: reasons})
		});		
	}

	
	archiveProject() {
        this.props.rowProps.archiveProject(this.props.data, () => {
			if (this.props.data.status == "0" && this.props.data.locked == '-1' && this.props.rowProps.projectLostReasonIsInUse(this.props.data.companies_id)) {
					this.context.functions.showDialogContent(<ProjectStatusReasonDialog type="lost" project={this.props.data} onSave={(reason, status_reason_comment) => {
						this.setDataAndUpdate({ locked: 1, status_reason_id: reason.id, status_reason_comment, status_date: format(new Date(), "YYYY-MM-DD") });		
					}} />
				);
			} else {
				this.setDataAndUpdate({ locked: 1, status_date: format(new Date(), "YYYY-MM-DD"), status_reason_id: null, status_reason_comment: null });
			}
        });
	}

    activateProject = () => {
        this.setDataAndUpdate({ locked: -1, status_date: format(new Date(), "YYYY-MM-DD"), status_reason_id: null, status_reason_comment: null });
    }    

	wonProject() {
        const newData = { type: 1, status: 1, status_date: format(new Date(), "YYYY-MM-DD") };

        DataHandler.post({url: `projects/${this.props.data.id}/status`}, newData)
        .done(() => {
            this.setDataAndUpdate(newData);
        }).fail(e => {
            const { enqueueSnackbar } = this.props.sharedData;
            const resp = e.responseJSON;

            if(resp && resp.error === "invalid_state") {
                enqueueSnackbar(`${this.tr('Required fields not filled:')} ${resp.missing.join(", ")}`, {
                    variant: "error",
                });
            }
        });
    }

	updateProjectTypes = (selectedObject) => {
		const projectTypes = selectedObject ? selectedObject.map(t => t.value) : [];
		const onlyOnetypeAllowed = this.props.rowProps.getOneProjectTypeSetting && this.props.rowProps.getOneProjectTypeSetting();

		if(onlyOnetypeAllowed && projectTypes.length > 1) {
			this.props.rowProps.enqueueSnackbar(this.tr(`one project type enabled`), {
				variant: "error",
			});
			return;
		}

		const tagsString = projectTypes.join(",");
		this.setDataAndUpdate("project_types", tagsString);
	}

	addSubProject = async () => {
		const projectlimitReached = await this.props.rowProps.isProjectLimitReached(true);

        if (projectlimitReached)
            this.props.rowProps.toggleBuyDialog("projects");
        else
			this.props.listRef.addNewRow({ parentId: this.props.data.id });
	}
				
	getSelectedUser = (id, users) => {
		let response = users && users.find(e => e.id == id);
		if (!response) {
			const user = this.props.sharedData['all_users'] && this.props.sharedData['all_users'].find(e => e.id == id);
			if (user) 
				response = user.name;
			else 
				response = "";
		}
		return response;
	}

	createDeleteMenuItem = (notDeletable, deletableReasons) => {
		let deletableMsg = this.tr("Can not delete because project has");
		if(deletableReasons) {
			deletableMsg +=  " " + deletableReasons.map(e => this.deletableReasons[e]).join(", ");
		}
		return this.createDisableableMenuItem(
			this.tr("Delete"), 
			deletableMsg,
			() => this.delete(), 
			notDeletable,
			RemoveIcon,
			"delete",
			"Delete"
		) 	
	}

	createSubprojectMenuItem = () => {
		return this.createDisableableMenuItem(
			this.tr("Add Subproject"), 
			this.tr("Cannot add subproject: Project is locked!"),
			() => this.addSubProject(), 
			Number(this.props.data.locked) === 1,
			AddIcon
		) 	
	}

	createDisableableMenuItem = (text, disableInfoMsg = "", onClickFunc = () => {}, disabled = false, Icon = undefined, className = "", iconClass = "") => {
		return disabled ? (
			<Tooltip title={disableInfoMsg} placement="right">
				<div>
					<MenuItem disabled={true} className={className} onClick={() => {}}>
						<Icon className={iconClass} title="" />{text}
					</MenuItem>
				</div>
			</Tooltip>
		) : (			
			<MenuItem className={className} onClick={onClickFunc}>
				<Icon className={iconClass} title="" />{text}
			</MenuItem>
		)
	}

	onTagCreate = (tag, tagsString, tagOptions) => {
        const data = {tag: tag, type: 2, id: -1};
        DataHandler.post({url: `tags/save/${this.props.data.companies_id}`}, {data}).done(t => {
			this.setState({tagOptions: tagOptions.concat({tag: tag, name: tag, value: tag, id: tag})}, () => this.setDataAndUpdate("tags", tagsString));
			
            this.props.rowProps.enqueueSnackbar(this.tr("Tag ${tag} created!", {tag: data.tag}), {
                variant: "success",
            });
        })
        .fail(response => {
            if (response && response.responseJSON && response.responseJSON.error === "TAG_EXISTS_AS_DISABLED") {    
                this.props.rowProps.enqueueSnackbar(this.tr("Tag already exists, but is disabled!"), {
                    variant: "error",
                });
            }
        });
	}
	
	onTagDelete = (selectedObject, tags) => {
		const deleted = tags.filter(e => !selectedObject.find(s => s.id == e.id))[0];
		const data = {tag: deleted.tag, type: 1, id: -1};
        DataHandler.post({url: `tags/save/${this.props.data.companies_id}`}, {data});
	}

	getSalesPersons = () => {
		let salespersons            = this.props.sharedData['privileged_employees'];
		const projectTeam           = this.props.data.project_team ? this.props.data.project_team.split(',') : [];
		const teamRightEmployees    = (this.props.sharedData['team_right_employees'] || []).filter(e => projectTeam.indexOf(e.id) > -1);
		const managerRightEmployees = (this.props.sharedData['manager_right_employees'] || []).filter(e => e.id == this.props.data.project_manager_user_id);

		salespersons = _.uniqBy(salespersons.concat(teamRightEmployees).concat(managerRightEmployees), 'id').sort((a, b) => {
            return a.name.localeCompare(b.name, "fi");
		});
		
		return salespersons;
	}

    getChildProjectIds = (children) => {
        let projectIds = [];
        children.forEach(child => {
            projectIds.push(child.data.id);
            projectIds = projectIds.concat(this.getChildProjectIds(child.children));
        });

        return projectIds;
    }

    onQuoteStatusChange = (props, status) => {
		const { data } = props;
		const currentStatus = data.quote_statuses[0]?.status;
		if (currentStatus == status) {
			return;
		}

		if (['-1', '5'].find(s => s == status)) { // If changing status to declined or archived, prompt about deactivating quote (list shows only active quotes).
			const { openQuoteStatusDialog } = props.rowProps;
			openQuoteStatusDialog && openQuoteStatusDialog(status, (active) => this.updateQuoteStatus(props, status, active));
		}
		else {
			this.updateQuoteStatus(props, status);
		}
	}

	updateQuoteStatus = (props, status, active = 1) => {
		const { data } = props;
		const oldData = data.quote_statuses[0];
		const newData = {...oldData, status: status}

		this.setData("quote_statuses", [newData]);
		DataHandler.post({ url: `projects/quotes/${newData.id}/status` }, { status: status, active: active })
		.done(() => {
			setTimeout(() => {
				props.rowProps.refreshList();
			}, 1000);
		})
		.fail((err) => {
			let msg = this.tr("Error in saving quote status");
			if (err?.responseJSON?.error == "INVALID_STATUS") {
				msg = this.tr('Selected status is not allowed');
			}
			props.enqueueSnackbar(msg, {
				variant: 'error',
			});
			props.rowProps.refreshList()
		});
	}

	getQuoteStatusCell = (props) => {
		const { data, sharedData, rowProps } = props;
		const { quoteStatuses = [], quoteStatusMap = {} } = sharedData;
		const { 
			project_cost_estimate_write = false,
			project_cost_estimate_read = false
	 	} = data.rights || {};

		if (data.quote_statuses?.length == 1 && project_cost_estimate_read) {
			return <EditableStatusCell
					options={quoteStatuses}
					tooltip={rowProps.getQuoteStatusTooltip ? rowProps.getQuoteStatusTooltip(data) : ""}
					onEdited={(data) => this.onQuoteStatusChange(props, data.id)}
					value={quoteStatusMap[data.quote_statuses[0].status]} 
					editable={project_cost_estimate_write ? true : false}
				/>
		}

		return this.renderQuoteValuesCell(props, 'status', "left");
	}

	renderQuoteValuesCell = (props, mode, textAlign = "right") => {
		const { data, rowProps } = props;

		return (
			<ListCell onlyDisplay alignCell textAlign={textAlign}>
				<div className={`cellValue normal pseudoLink ${textAlign == "right" ? "alignRight" : ""}`}>
					{rowProps.renderRowQuoteValues ? rowProps.renderRowQuoteValues(data, mode) : <div></div>}
				</div>
			</ListCell>
		)
	}

    defineCells(props = undefined) {
        props = props === undefined
            ? this.props
            : props;

        const { data, sharedData } = props;
        const { 
            hour_report_read = {}, 
            write = {} 
        } = sharedData?.rights || {};
        
        const { addons }         = props.rowProps;
        const hourReportRead     = hour_report_read[data.id];
        const partialInvoicingOn = addons.quoterow_partial_invoicing_for_products;
        const customProjectIdOn  = addons.custom_project_id && !addons.corporate_automatic_project_numbering;
        const customCustomerIdOn = addons.custom_customer_id?.used_by_companies?.indexOf(data.companies_id) > -1;
        const dimensionsOn       = addons?.dimensions?.used_by_companies?.indexOf(data.companies_id) > -1;
        const { 
            force_teams_branchofbusiness_for_projects, 
            mandatory_team_selection_for_projects
        } = this.context.taimerAccount;

        let customer = data.customer !== undefined 
            ? props.customer 
            : ((props.sharedData?.autoCompleteDataMap?.customers || {})[data.customers_id] || { name: "–" });

        if(props.isChild && data.id < 0) {
			customer = { name: props.parentProps.data.customer };
        }
			
		const { functions: { checkPrivilege }} = this.context
		const writePermission                  = write[data.id] || props.newRow || data.id < 0;
		const filterF                          = data.type > 0 ? ss => ss.projects_pipelines_id === data.type : ss => ss.project_type === String(data.type  * -1);
		const salesStateData                   = sharedData['sales_states'].filter(filterF).sort(this.sortSalesStates);
		const tagOptions                       = this.state.tagOptions || sharedData['project_tags'] || [];
        let tags                               = [];
		const currency						   = props.rowProps.currency;
		const reportingGroupOptions			   = props.sharedData?.reportingGroupOptions || [];
		const salespersons                     = this.getSalesPersons();
		const projectManagers                  = props.sharedData['employees'];

        this.salesStateOrderMap = makePropertyMap(
            props.sharedData?.sales_states || [],
            "id", 
            "stateorder"
        );

        if(data.tags != null) {
			const currentTags = data.tags.split(',');
			tags              = tagOptions.filter(t => currentTags.indexOf(t.id) > -1);
		}

        const reportingGroupsCusOpts = reportingGroupOptions.filter(rg => {
            return rg.data.customers_id === data.customers_id;
        });

		const selectable_customer_types = props.sharedData.customer_types.slice();
		selectable_customer_types.shift();
		const customerTypesIds = data['customer_types_id'] 
            ? data['customer_types_id'].split(',').map(x => x.trim()) 
            : [];
		const customerTypes = [];

        for(let i = 0, l = customerTypesIds.length; i < l; ++i) {
            if(!props.sharedData['customerType_map'][customerTypesIds[i]]) {
                continue;
            }

            customerTypes.push(props.sharedData['customerType_map'][customerTypesIds[i]]);
		} 

		const statesToValidate = this.getStatesToValidate();
        const typeIds = data['project_types'] ? data['project_types'].split(',').map(x => x.trim()) : [];
		const types   = [];
		const countCategory = props.sharedData.bobOptions.filter(bob => bob.data.deleted != 1).length;

        for(let i = 0, l = typeIds.length; i < l; ++i) {
            if(!props.sharedData['type_map'][typeIds[i]])
                continue;

			types.push(props.sharedData['type_map'][typeIds[i]]);
		}

		const staticColumnProps = { showMenu: false, showMenuContainer: true, resizeable: false, showResizeMarker: false, moveable: false, hideable: false };
		const hasHours = data.hours && data.hours !== "0.00";

		let invoicing_type = "";

		switch (Number(data['invoicing_type'])) {
			case 1:
				invoicing_type = this.tr("Billable");
			  break;
			case 2:
				invoicing_type = this.tr("Non-billable");
			  break;
			case 3:
				invoicing_type = this.tr("Leaves / vacation");
				break;
			default:
				invoicing_type = "";
		}

		let statusReasons = this.props.sharedData.status_reasons || [];
		const projectWon = data.status == '1' && data.locked == '-1';
		const projectLost = data.status == '0' && (data.locked == '1' || data.locked == '3');
		if (projectWon) {
			statusReasons = statusReasons.filter(reason => reason.status == '1' && reason.locked == '-1');
		} else if (projectLost) {
			statusReasons = statusReasons.filter(reason => reason.locked == '1');
		}

        const dateCellPopperProps = data.id < 0 
            ? {} 
            : ProjectListRow.dateCellPopperProps;
		
		const cells = {
			expand:
				data['id'] > 0
				?
				<ContextMenu label={<ContextMenuIcon /*style={{ color: "#b0b3ba" }}*/ />} menuOpenCallback={this.checkDeletable} data-testid={`project-list-row-context-menu-${data.id}`} buttonProps={{ className: 'action-menu' }} className="cell row-menu" style={{width: props.columnWidthMap['expand'] + 'px', flex: props.columnWidthMap['expand'] + " 1 0px" }} noExpandIcon>
					<MenuItem onClick={() => window.location = `index.html?module=projects&action=view&id=${data.id}`} data-testid={`project-list-row-context-menu-view-${data.id}`}><ViewIcon title="" />{this.tr("View")}</MenuItem>
					{/* <MenuItem onClick={() => {}}>Edit</MenuItem> */}
					{checkPrivilege("projects", "write", data.companies_id) && !VersionContentManager.isFeatureHidden(this.namespace, 'subprojects') ? this.createSubprojectMenuItem() : undefined }
					{checkPrivilege("projects", "write", data.companies_id) && !VersionContentManager.isFeatureHidden(this.namespace, 'subprojects') && data.locked == "-1" && <MenuItem onClick={ async () => {
							this.context.functions.addProject({ customers_id: data.customers_id, companies_id: data.companies_id, parentid: data.id, parentname: data.name, origin_point: "project_list_row", })
							}}><CreateSubProjectIcon />{this.tr('Add subproject from slider')}</MenuItem>}
					{checkPrivilege("projects", "write", data.companies_id) && !VersionContentManager.isFeatureHidden(this.namespace, 'subprojects') && <MenuItem  onClick={() => props.rowProps.openDialog("setAsSubProjectDialog", {company: data.companies_id, customerId: data.customers_id, onClose: props.rowProps.closeDialog, project: data, onSave: (evt) => {
						const d = data; 
						d[evt.target.name] = evt.target.value;
						props.rowProps.onUpdate(d);
						}}
					)} ><AssignAsSubProjectIcon />{this.tr('Assign as a subproject')}</MenuItem>}
					{checkPrivilege("projects", "write", data.companies_id) && <MenuItem onClick={() =>  {this.props.rowProps.openDialog('CopyProjectDialog', {id: data.id, project_id: data.project_id, name: data.name, customer: data.customer, companiesId: data.companies_id, children: props.hierarchyNode.childRefs, refreshList: props.rowProps.refreshList, updateLimitInfo: props.rowProps.updateLimitInfo})}}><CopyIcon title="" />{this.tr('Copy Project')}</MenuItem>}
					{/* Not sure why mark as won is not here, if you add it please make sure to take the won/lost reason setting into account (see archiveProject, also in ProjectView.js and kanban) */}
					{checkPrivilege("projects", "write", data.companies_id) && data.locked == "-1" ? <MenuItem  onClick={ this.archiveProject }><ArchiveIcon title="" />{data.hide_sales_insight_loss == 0 ? this.tr((data.status == 1 || data.status == 5) ? "Archive" : "Mark as lost") : this.tr("Archive project")}</MenuItem>: null }
                    {checkPrivilege("projects", "write", data.companies_id) && data.locked == "1" ? <MenuItem  onClick={ this.activateProject }><ActivateIcon title="" />{this.tr('Activate')}</MenuItem>: null }
					{(checkPrivilege("projects", "write", data.companies_id) || writePermission) ? this.createDeleteMenuItem(hasHours || !data.deletable, this.props.data.deletableReasons) : undefined }
				</ContextMenu>
				:
					<ListCell width={this.props.columnWidthMap.expand} permanentEditMode={true} onlyDisplay={true}>
                        {data.isCreatingNew && <CircularProgress size={18} style={{ padding: 14 }} className="" />}
                        {!data.isCreatingNew && <Tooltip title={this.tr("Save")} placement="bottom"><CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.saveNewRow()} /></Tooltip>}
					</ListCell>
				,
			checked:
				data['id'] > 0
				?
                <CheckboxCell checked={props.checked} onClick={() => props.listRef.check(data.id)} />
				:
				<ListCell width={this.props.columnWidthMap.checked} permanentEditMode={true} onlyDisplay={true}>
					<Tooltip title={this.tr("Cancel")} placement="bottom">
                        <Cancel className="cancelIcon" onClick={() => {
                            !data._isCreatingNew && this.delete(data);
                        }} />
					</Tooltip>
				</ListCell>,
            quotes:
                // TODO: Refactor into a pop-up cell or something similar.
				!data.rights?.project_cost_estimate_read ? <ListCell onlyDisplay={true} />
				:
                <ListCell onlyDisplay={true}>
                    <div ref={this.popperRoot}>
                        <QuoteDollar className="quoteDollarIcon" title={this.tr("Click to inspect this project's quotes")} style={{ cursor: "pointer" }} onClick={async (e) => {
                            this.setData({ _quotePopUpOpen: true });
                            this.setData({ quotes: await this.fetchQuotes() });
                        }} />
                        {this.popperRoot.current && data._quotePopUpOpen && <Popper keepMounted placement="bottom-start" anchorEl={this.popperRoot.current} container={this.popperRoot.current} open={data._quotePopUpOpen}>
                            <ClickAwayListener onClickAway={this.closeQuotePopUp}>
                                <div className={`quotePopUp ${!data.quotes || data.quotes.length === 0 ? "pending" : ""}`}>
                                    {!data.quotes && <CircularProgress style={{ marginTop: 50, marginBottom: 50 }} />}
                                    {data.quotes && data.quotes.length === 0 && 
                                        <h2 className="notice">{this.tr("This project has no quotes yet")}</h2>
                                    }
                                    {data.quotes && data.quotes.length > 0 && data.quotes.map((quote, index) => {
                                        return (
                                            <React.Fragment>
                                                <div className="quotePopUpHeader">
                                                    {quote.active && <h3 className="activeQuote">{this.tr("Active quote")}</h3>}
                                                    {index === 0 && <h2>{data.project}</h2>}
                                                    <h3><Link url={{ module: "projects", action: "view", id: data.id, tab: "quotes", quote: quote.id }} openInNewTab>{quote.name}</Link></h3>
                                                </div>
                                                <List
                                                    // fluid
                                                    height="auto"
                                                    noColorVariance={true}
                                                    rowHeight={30}
                                                    columns={[
                                                        { name: "name", header: "",  width: 240, ...staticColumnProps },
                                                        { name: "quantity", header: this.tr("Quantity"),  width: 150, ...staticColumnProps },
                                                        { name: "cost", alignRight: true, header: this.tr("Unit cost"),  width: 140, ...staticColumnProps },
                                                        { name: "value", alignRight: true, header: this.tr("Selling price"),  width: 140, ...staticColumnProps },
                                                        { name: "vat", alignRight: true, header: this.tr("VAT %"),  width: 100, ...staticColumnProps },
                                                        { name: "vat_total", alignRight: true, header: this.tr("VAT Total"),  width: 150, ...staticColumnProps },
                                                        { name: "discountPercentage", alignRight: true, header: this.tr("Discount-%"),  width: 100, ...staticColumnProps },
                                                        { name: "total_no_vat", alignRight: true, header: this.tr("Total 0%"),  width: 150, ...staticColumnProps },
                                                        { name: "quantity_delivered", alignRight: true, header: this.tr("Delivered (pcs)"),  width: 150, ...staticColumnProps },
                                                        { name: "quantity_invoiced", alignRight: true, header: this.tr("Invoiced (pcs)"),  width: 150, ...staticColumnProps }
                                                    ]}
                                                    data={quote.rows} 
                                                    listRowType={QuotePopUpRow}
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </ClickAwayListener>
                        </Popper>}
                    </div>
                </ListCell>,
			quote_status: this.getQuoteStatusCell(props),
            name:
                !props.isChild && data.id < 0 
                ? 
                <TextInputCell 
                    listCellProps={{ inEditMode: true, editable: true }} 
                    value={data.project}
					name="project" 
                    runOnEditOnInput={true}
                    onEdit={(name, value) => {
                        this.setDataAndUpdate(name, value);
                    }} />
                :
                <TreeStructureIndicatorCell
					name="name"
                    treeMetaData={props.treeMetaData}
                    childrenVisible={props.childrenVisible}
                    listCellProps={{ alignCell: true, textAlign: "left" }}
                    onExpanderClick={e => props.listRef.toggleShowChildren(data.id)}
                    value={data.project}>
                    <TextInputCell 
                        listCellType={LinkListCell} 
                        listCellProps={{ 
                            urlHandler: value => `index.html?module=projects&action=view&id=${data.id}`,
							valueHandler: value => `${value}`,
							noTab: true,
                            inEditMode: data.id < 0, 
                            editable: writePermission, 
                            style: { height: "44px" }, 
                            width: props.columnWidthMap['name'] - 
                                ((props.treeMetaData.childCount > 0 ? 1 : 0) + props.treeMetaData.depth) * 25 
                        }} 
                        name="project"
						validation={["empty"]}
                        onEdit={(name, value) => {
							if(value === "") {
								this.props.rowProps.enqueueSnackbar(this.tr("A project's name can not be empty."), {
									key: "EMPTY_PROJECT_NAME_NOTIFICATION",
									preventDuplicate: true,
									variant: "error"
								});
								this.setData(name, value);
							}
							else this.setDataAndUpdate(name, value)}
						}
                        value={data.project} />
                </TreeStructureIndicatorCell>,
            project_id: customProjectIdOn ? 
                <TextInputCell
                    allowEmpty={false}
                    onEmptyInput={() => {
                        props.rowProps.enqueueSnackbar(this.tr("A project's number can not be empty."), {
                            key: "EMPTY_PROJECT_NUMBER_NOTIFICATION",
                            preventDuplicate: true,
                            variant: "error" 
                        }); 

                        this.setData("_projectIdFieldIsEmpty", true);
                    }}
                    name="project_id"
                    useClickAwayListener={false}
                    listCellType={LinkListCell}
                    listCellProps={{
                        urlHandler: value => `index.html?module=projects&action=view&id=${data.id}`,
                        showErrorBorder: data['_projectIdIsDuplicate'] || data['_projectIdFieldIsEmpty'],
                        inEditMode: data['_projectIdIsDuplicate'] ? true : data.id < 0,
						value: data.project_id,
						noTab: true,
                    }}
                    onEdited={(name, value) => {
                        this.setData("_projectIdIsDuplicate", false);
                        this.setData("_projectIdFieldIsEmpty", false);

                        const promise = this.setDataAndUpdate(name, value);

                        if(promise) {
                            promise.fail(response => {
                                if(response.responseJSON.error === "EXISTING_PROJECT_ID") {
                                    this.setData("_projectIdIsDuplicate", true);
                                }
                            }); 
                        }
                    }}
                    value={data.project_id} /> : 
				<LinkListCell
					urlHandler={value => `index.html?module=projects&action=view&id=${data.id}`}
					value={parseInt(data['id'], 10) < 0 ? "" : data['project_id']}
					noTab={true}
                    editable={false} 
                    useIndenting={false}
                    indent={props.recursionLevel} />,
			customer_id:
				data['id'] > 0
				?
				<LinkListCell
					editable={false}
					asText={data.can_see_account == "0"}
					// value={customer.name}
					value={props.isChild && data.id < 0 || data.customer === undefined ? customer.name : data.customer}
					noTab={true}
					urlHandler={() => `index.html?module=customers&action=view&id=${data['customers_id']}`} />
				:
				<AutoCompleteCell
					listCellType={LinkListCell}
					listCellProps={{
						urlHandler: customer => customer.hasOwnProperty("id") ? `index.html?module=customers&action=view&id=${customer.id}` : "",
						valueHandler: customer => customer.hasOwnProperty("name") ? customer.name : "",
						noTab: true,
                        noInitFocus: true
					}}
					editable={writePermission}
                    allowCreate={!customCustomerIdOn ? true : false}
                    autoCompleteData={props.sharedData['customers']}
					value={data['customers_id'] ? data['customers_id'].toString() : ''}
                    // focusOnMount={data['customers_id'] > 0 ? false : true}
                    onEdited={async (customer) => {
                        if(customer.__isNew__) {
                            const response = await DataHandler.post({ url: "accounts" }, { name: customer.value, types: "default" });
                            customer.id  = String(response.id);

                            const { enqueueSnackbar, addToAutoCompleteData } = props.rowProps;

                            enqueueSnackbar(this.tr("The customer has been created."), { variant: "success" });
                            addToAutoCompleteData("customers", { id: customer.id, name: customer.value, label: customer.value, allow_project_creation: 1 }, false);
                        }

                        this.setData({ customers_id: customer.id });
					}} 
					selectProps={{
						disabledSubtext: "(" + this.tr("Project creation not allowed") + ")",
                        isOptionDisabled: (account) => {return !account.__isNew__ && account.allow_project_creation != 1},
					}} />,
            dimension_team:
                <AutoCompleteCell 
                    autoCompleteData={props.sharedData.dimension_teams}
                    value={data.dimension_teams_id} 
                    listCellProps={{
                        noInitFocus: true
                    }}
                    onEdit={value => {
                        const {
                            branch_of_business 
                        } = props.sharedData;
                        const data = {
                            dimension_teams_id: value.id
                        };

                        if(force_teams_branchofbusiness_for_projects) {
                            const bob = branch_of_business.find(bob => {
                                return value.category === bob.id;
                            });

                            if(bob) {
                                data.branchofbusiness_id = bob.id;
                            }
                        }

                        this.setDataAndUpdate(data);
                    }}
                />,
            customer_type:
                <AutoCompleteCell
                    autoCompleteData={selectable_customer_types}
                    listCellProps={{
                        noInitFocus: true
                    }}
                    name="customer_type"
                    value={customerTypes}
                    editable={false}
                    width={props.columnWidthMap['customer_type']}
                    multiple={true}
					/>,
			customership_group: 
				<TextInputCell 
                    listCellProps={{ editable: false }} 
                    value={data.customership_group} 
					name="customership_group" 
                />,
			enterprise_groups: 
				<TextInputCell 
                    listCellProps={{ editable: false }} 
                    value={data.enterprise_groups} 
					name="enterprise_groups" 
                />,
			reporting_group:
				<AutoCompleteCell
						listCellProps={{
							showTooltipForOverflownText: true,
                            noInitFocus: true
						}}
						editable={writePermission}
						autoCompleteData={reportingGroupsCusOpts}
						value={data.product_structures_id == 0 ? "" : data.product_structures_id }
						components={{ Option: passedProps => {
							const passProps = {
								isDisabled: passedProps.isDisabled,
								isFocused: 	passedProps.isFocused,
								isMulti: 	passedProps.isMulti,
								isSelected: passedProps.isSelected
							};

							return <TreeOption {...passedProps.data} {...passProps} onSelect={rgs => {
								passedProps.setValue(rgs.id)

								this.setDataAndUpdate("product_structures_id", rgs.id);
							}} />;
						}}}
						onEdited={selectedObject => {
							if(data.product_structures_id === selectedObject.value)
								return;

							this.setDataAndUpdate("product_structures_id", selectedObject.value);
						}} />,
			tags:
				<AutoCompleteCell 
					autoCompleteData={tagOptions.filter(e => Number(e.disabled) < 1)}
                    listCellProps={{
                        noInitFocus: true
                    }}
					value={tags} 
					multiple={true}
					editable={writePermission}
					placeholder="Tags"
					allowCreate={props.sharedData.tagPoolSettingsData ? props.sharedData.tagPoolSettingsData.create_tags_only_from_settings == "0" : false}
					autoCompleteLabelKey="name" 
					onEdited={selectedObject => {
						if (selectedObject.length < tags.length)
							this.onTagDelete(selectedObject, tags);

						const tagsString = selectedObject.map(t => t.value).join(",");
						const newTag = selectedObject.find(s => s.new == true);

						if (newTag) {
							this.onTagCreate(newTag.value, tagsString, tagOptions);
							return;
						} 

						this.setDataAndUpdate("tags", tagsString);
					}
					} />,
			branchofbusiness:
                ((mandatory_team_selection_for_projects && force_teams_branchofbusiness_for_projects) 
                    || 
                (force_teams_branchofbusiness_for_projects && data.dimension_teams_id !== undefined))
                ?
                <ListCell 
                    value={props.sharedData.autoCompleteDataMap.bobOptions[data.branchofbusiness_id]?.name || "-"}
                    editable={false} />
                :
				<AutoCompleteCell
					listCellProps={{
						showTooltipForOverflownText: true,
                        noInitFocus: true
					}}
                    // editable={writePermission}
					editable={writePermission && ((dimensionsOn && (!force_teams_branchofbusiness_for_projects || !data.dimension_teams_id)) || (!dimensionsOn))}
					autoCompleteData={props.sharedData.bobOptions}
                    value={data.branchofbusiness_id != 0 ? data.branchofbusiness_id : undefined}
					hideOptions={(options) => {
						return options.filter(f => f.data.locked != 1);
					}}
					components={{ Option: passedProps => {
						const passProps = {
							isDisabled: data.deleted == 1 || data.locked == 1,
							isFocused: 	passedProps.isFocused,
							isMulti: 	passedProps.isMulti,
							isSelected: passedProps.isSelected
                        };

                        return <TreeOption 
                            {...passedProps.data} 
                            {...passProps} 
                            branchIndicatorProps={{
                                wrapperStyleFn: (props) => {
                                    return {
                                        display: "inline-block",
                                        position: "relative",
                                        top: `-${(props.rowHeight / 2) + 3}px`
                                    }
                                }
                            }}
                            onSelect={bob => {
                                passedProps.setValue(bob.id)
                            }} />;
					}}}
					rowHeight={20}
					selectProps={{
						noOptionsMessage: () => this.tr("No option")
					}}
					onEdited={selectedObject => {
						if(data.branchofbusiness_id === selectedObject)
							return;

						this.setDataAndUpdate("branchofbusiness_id", selectedObject);
					}} />,
			status:
				<EditableStatusCell
					editable={writePermission && (Number(data.parentLocked) !== 1 || Number(data.locked) !== 1)}
					options={props.sharedData.statuses.filter(s => s.id != 0)}
					value={data.locked}
					onEdited={async (status) => {
						// If parent is locked, prevent changing status to "Active" or "On hold"
						if ((status.id == -1 || status.id == 2) && Number(data.parentLocked) === 1) {
							let msg = "Cannot activate project";
							if (status.id == 2) msg = "Cannot put project on hold";
							props.rowProps.enqueueSnackbar(this.tr(msg) + ": " + this.tr("Parent is locked."), {
								variant: "error",
							});
							return;
						}
						const date = format(new Date(), "YYYY-MM-DD");

						if((status.id == 1 && data.locked != 1) || (status.id == 3 && data.locked != 3)) {
							this.archiveProject();
							return;
						}
						if(status.id != 1){
							this.setDataAndUpdate({ locked: status.id, status_date: date, status_reason_id: null, status_reason_comment: null });
						}
						
					}} />,
			statusdate:
				<DateCell
					value={data['status_changed']}
					editable={false} />,
			sales_state:
				<AutoCompleteCell
					editable={writePermission}
					autoCompleteData={salesStateData}
					value={data['projects_sales_states_id']}
                    listCellProps={{
                        noInitFocus: true 
                    }}
					onEdited={salesState => {
						this.setDataAndUpdate("projects_sales_states_id", salesState.id);
					}} />,
			probability_percent:
				<ListCell value={Math.round(data['probability_percent'] * 100) + "%"} textAlign="right" editable={false} />,
			sales_state_changed:
				<DateCell value={data['sales_state_changed']} editable={false} />,
			startdate:
				<DateCell
					offsetCalendar={true}
					closeOnComplete={false}
					closeCalendarOnComplete={true}
                    usePopper={data['id'] > 0}
                    popperProps={dateCellPopperProps}
                    popperBottom={true}
					editable={writePermission}
					value={data['startdate']}
					onEdited={date => {
						this.setDataAndUpdate("startdate", date);
					}} />,
			enddate:
				<DateCell
					offsetCalendar={true}
					closeOnComplete={false}
					closeCalendarOnComplete={true}
                    usePopper={data['id'] > 0}
                    popperProps={dateCellPopperProps}
                    popperBottom={true}
					editable={writePermission}
					value={data['enddate']}
					onEdited={date => {
						this.setDataAndUpdate("enddate", date);
					}} />,
            event_startdate:
                <DateCell
                    offsetCalendar={true}
                    closeOnComplete={false}
                    closeCalendarOnComplete={true}
                    usePopper={data['id'] > 0}
                    popperProps={dateCellPopperProps}
                    popperBottom={true}
                    editable={writePermission}
                    value={data['event_startdate']}
                    onEdited={date => {
                        this.setDataAndUpdate("event_startdate", date);
                    }} />,
            event_enddate:
                <DateCell
                    offsetCalendar={true}
                    closeOnComplete={false}
                    closeCalendarOnComplete={true}
                    usePopper={data['id'] > 0}
                    popperProps={dateCellPopperProps}
                    popperBottom={true}
                    editable={writePermission}
                    value={data['event_enddate']}
                    onEdited={date => {
                        this.setDataAndUpdate("event_enddate", date);
                    }} />,                    
			project_manager:
				<AutoCompleteCell
					editable={writePermission}
					autoCompleteData={projectManagers || []}
                    listCellProps={{
                        noInitFocus: true 
                    }}
					name="project_manager"
					showStringValue={projectManagers ? !projectManagers.find(e => e.id == data.project_manager_user_id) : true}
					value={this.getSelectedUser(data['project_manager_user_id'], projectManagers)}
					onEdited={projectManager => {
						this.setDataAndUpdate("project_manager_user_id", projectManager.id);
					}} />,
			type:
				<AutoCompleteCell
					editable={writePermission}
					autoCompleteData={props.sharedData['types']}
					autoCompleteLabelKey="name"
                    listCellProps={{
                        noInitFocus: true 
                    }}
					name="type"
					value={types}
					multiple
					onEdited={selectedObject => {
						this.updateProjectTypes(selectedObject);
					}} />,
			seller:
				<AutoCompleteCell
					editable={writePermission}
					autoCompleteData={salespersons || []}
					autoCompleteLabelKey="name"
                    listCellProps={{
                        noInitFocus: true 
                    }}
					name="seller"
					showStringValue={salespersons ? !salespersons.find(e => e.id == data.status_users_id) : true}
                    value={this.getSelectedUser(data.status_users_id, salespersons)}
					onEdited={sellerUser => {
						this.setDataAndUpdate("status_users_id", sellerUser.id);
					}} />,
            /*project_team:
				<AutoCompleteCell
					editable={writePermission}
					autoCompleteData={props.sharedData['project_team_member_pool']}
					multiple={true}
					value={projectTeamMembers}
					onEdited={users => {
						this.setDataAndUpdate("project_team", users.map(u => u.id));
                    }} />,*/
			project_type:
				// This can be either one of the hard-coded pipelines (Won Deal or Internal), or any user-defined pipeline.
				// So when saving, we need to determine if the selected "type" is a hard-coded pipeline or a user-defined pipeline, since this information is stored in different columns in the db.
				<AutoCompleteCell
					editable={writePermission}
					// value={data['type']}
                    value={data.id < 0 ? data.type : data.status != 0 ? data.status * -1 : data.projects_pipelines_id}
					autoCompleteData={props.sharedData.pipelines}
                    listCellProps={{
                        noInitFocus: true 
                    }}
                    onEdited={type => {
						if (type.id == '-1' && data.locked == '-1' && this.props.rowProps.projectWonReasonIsInUse(data.companies_id)) {
							this.context.functions.showDialogContent(<ProjectStatusReasonDialog type="won" project={data} onSave={(reason, status_reason_comment) => {
								this.setDataAndUpdate({  type: type.id, projects_pipelines_date: format(new Date(), "YYYY-MM-DD"), status_reason_id: reason.id, status_reason_comment });		
							}} />);
						} else {
							this.setDataAndUpdate({ type: type.id, projects_pipelines_date: format(new Date(), "YYYY-MM-DD"), status_reason_id: null, status_reason_comment: null });
						}
					}} />,
            projects_pipelines_date:
                    data['projects_pipelines_date'] != '0000-00-00' ?
                    <DateCell value={data['projects_pipelines_date']} editable={false} />
                    :
                    <ListCell value="-" textAlign="center" editable={false} />,
			log_created:
				<DateCell value={data['log_created']} editable={false} />,
			closing_date:
				<DateCell
					editable={writePermission}
					offsetCalendar={true}
					closeOnComplete={false}
                    usePopper={data['id'] > 0}
                    popperProps={dateCellPopperProps}
                    popperBottom={true}
					closeCalendarOnComplete={true}
					value={data['closing_date']}
					onEdited={(name, value) => {
						this.setDataAndUpdate("closing_date", value);
					}} />,
			revenue:
				<TextInputCell
					listCellType={CurrencyListCell}
					value={(!data['quote_statuses'] && checkPrivilege('projects', 'project_cost_estimate_write', data.companies_id)) ? this.tr('Create quote') : data['revenue']}
					editable={false} // editable in quote
					textAlign="right"
					listCellProps={{
						allowEmpty: true,
						allowNaN: true,
						currency: currency,
						asLink: checkPrivilege('projects', 'project_cost_estimate_read', data.companies_id),
						url: `index.html?module=projects&action=view&id=${data.id}&selectedTab=sales`,
						useOnClick: checkPrivilege('projects', 'project_cost_estimate_write', data.companies_id) && VersionContentManager.hasFeature('quoteWizard') && !data['quote_statuses'],
						onClick: () => this.context.functions.setOverlayComponent(<QuoteWizard project={data.id} />)
					}}
					onEdited={value => {
						this.setDataAndUpdate("revenue", value);
                    }} />,
					// 
			project_invoices_sum: 
				<CurrencyListCell
					allowNaN={true}
					showZero={true}
					value={data.project_invoices_sum ? data.project_invoices_sum : null}
					currency={currency}
					editable={false}
					textAlign="right" />,
			other_entries_sum:
				<CurrencyListCell
					allowNaN={true}
					showZero={true}
					value={data.other_entries_sum ? data.other_entries_sum : null}
					currency={currency}
					editable={false}
					textAlign="right" />,
			automatic_invoicing_sum:
				<CurrencyListCell
					allowNaN={true}
					showZero={true}
					value={data.automatic_invoicing_sum ? data.automatic_invoicing_sum : null}
					currency={currency}
					editable={false}
					textAlign="right" />,
			actual_grossmargin:
				<CurrencyListCell
					data-testid="actual_grossmargin"
					allowNaN={true}
					showZero={true}
					value={data.actual_grossmargin ? data.actual_grossmargin : null}
					currency={currency}
					editable={false}
					textAlign="right" />,
			net_profit:
				<CurrencyListCell
					data-testid="net_profit"
					allowNaN={true}
					showZero={true}
					value={data.net_profit ? data.net_profit : null}
					currency={currency}
					editable={false}
					textAlign="right" />,
			gross_profit:
				<CurrencyListCell
					data-testid="gross_profit"
					allowNaN={true}
					showZero={true}
					value={data.gross_profit ? data.gross_profit : null}
					currency={currency}
					editable={false}
					textAlign="right" />,
            invoiced: 
                <CurrencyListCell
                    allowNaN={true}
                    showZero={true}
                    value={data.invoiced ? data.invoiced : null}
					currency={currency}
					editable={false}
                    textAlign="right" />,
			backlog: 
				<CurrencyListCell
					data-testid="backlog"
					name="backlog"
					allowNaN={true}
					showZero={true}
					value={data.backlog}
					currency={currency}
					editable={false}
					textAlign="right" />,
            invoiceable_left:
                partialInvoicingOn 
                ?
                <CurrencyListCell
                    allowNaN={true}
                    showZero={true}
                    value={data.invoiceable_left ? data.invoiceable_left : null}
					currency={currency}
					editable={false}
                    textAlign="right" />
                :
                null,
			salesmargin:
				<TextInputCell
					listCellType={CurrencyListCell}
					value={data['salesmargin']}
					editable={false} // editable in quote
					listCellProps={{
						allowEmpty: true,
						currency: currency
					}}
					textAlign="right"
					onEdited={value => {
						this.setDataAndUpdate("salesmargin", value);
                    }} />,
            hours_done: <ListCell value={data['hours_done']} editable={false} textAlign="right" />,
            unbilled_hours: <ListCell value={data['unbilled_hours']} editable={false} textAlign="right" />,
			maxhours: <ListCell value={data['maxhours']} editable={false} textAlign="right" />,
			extra_maxhours: <ListCell value={data['extra_maxhours']} editable={false} textAlign="right" />,
			status_reason:
				<AutoCompleteCell
					editable={writePermission && ((projectWon && this.props.rowProps.projectWonReasonIsInUse(data.companies_id)) || (projectLost && this.props.rowProps.projectLostReasonIsInUse(data.companies_id)))}
            	    value={data.status_reason_name}
					showStringValue
					autoCompleteData={statusReasons}
                    listCellProps={{
                        noInitFocus: true 
                    }}
            	    onEdited={type => {
            	        this.setDataAndUpdate({ status_reason_id: type.id, status_reason_name: type.name });
					}} />,
			status_reason_comment:
				<TextInputCell
					value={data['status_reason_comment'] || ''}
					editable={writePermission && ((projectWon && this.props.rowProps.projectWonReasonIsInUse(data.companies_id)) || (projectLost && this.props.rowProps.projectLostReasonIsInUse(data.companies_id)))}
					textAlign="left"
					onEdited={value => {
						this.setDataAndUpdate("status_reason_comment", value);
					}} />,
			misc_info:
				<TextInputCell
					value={data['misc_info'] || ''}
					editable={writePermission}
					textAlign="left"
					onEdited={value => {
						this.setDataAndUpdate("misc_info", value);
					}} />,
			invoicing_type: <ListCell value={invoicing_type} editable={false} />,
			parentId:
				<LinkListCell
					editable={false}
					value={data['parentName']}
					urlHandler={() => this.parentId ? `index.html?module=projects&action=view&id=${data['parentId']}` : ""}
					valueHandler={value => value} />,
			hour_report: (
					<ListCell onClick={() => hourReportRead && props.rowProps.showHoursReport(data)} className={`${hourReportRead ? 'hour-report' : ''}`} permanentEditMode={true} width={props.columnWidthMap.hour_report}>
						{hourReportRead && <Tooltip title={this.tr("Hour Report")} placement="bottom">
							<div className="hour-report-icon-container">
								<Description />
							</div>
						</Tooltip>}
					</ListCell>
			),
			"cost_estimate":
				<ListCell editable={false}>
					<BarChart />
				</ListCell>,
			quote_currency_sum: this.renderQuoteValuesCell(props, 'sum'),
			quote_currency: this.renderQuoteValuesCell(props, 'currency'),
			quote_currency_rate: this.renderQuoteValuesCell(props, 'currency_rate'),
			customer_reference:
				<TextInputCell
					value={data['customer_reference'] || ''}
					editable={writePermission}
					textAlign="left"
					onEdited={value => {
						this.setDataAndUpdate("customer_reference", value);
					}} />,
		};

		for(const customField of props.sharedData.custom_fields) {
			if(!customField.show_in_details)
				continue;

			const CellType = this.customCells[customField.type] ? this.customCells[customField.type] : ListCell;
			const cellName   = `custom_${customField.id}`;
			const valueName  = data.hasOwnProperty(`${cellName}_id`) ? `${cellName}_id` : cellName;
			let value      = data[valueName];

			if(customField.type == "multiselect") {
				value = value ? value.split(",").map(x => { 
					const opt = customField.options.find(opt => opt.id == x);
					return {
						id: x,
						value: x,
						label: (opt && opt.name) || x,
					}
				}) : [];
			}

			const cellProps = {
				name: cellName,
				key: cellName,
				value,
				listCellProps: {
					editable: writePermission,
					inEditMode: data.id < 0
				},
				multiple: customField.type == "multiselect" ? true : undefined,
				onEdited: (a, b = undefined) => {
					const name  = b !== undefined ? a : valueName;
					let value = b !== undefined ? b : a;

					if(customField.type == "multiselect")
						value = value ? value.map(x => x.id).join(",") : "";

					this.setData(name, value);

					if(data.id < 0)
						return;

					DataHandler
						.put({ url: `projects/${data.id}`}, { custom: { [customField.id]: value && typeof value === "object" && value.hasOwnProperty("id") ? value.id : value } })
						.fail(props.rowProps.refreshList);
				}
			};

			(customField.required) && (cellProps.validation = ["empty"]);
			(customField.options)  && (cellProps.autoCompleteData = customField.options);

			if (customField.type === "link") {
				cellProps.listCellType = LinkListCell;
				cellProps.listCellProps.asNormalLink = true;
				cellProps.listCellProps.noTab = false;
				cellProps.value = prefixLink(cellProps.value);

				if (cellProps.validation)
					cellProps.validation.push('link');
				else
					cellProps.validation = ['link'];
		   }

			cells[cellName] = <CellType {...cellProps} />;
			
			const required = customField.required && statesToValidate.indexOf(customField.projects_sales_states_id) > -1

			if(!required) {
				delete this.newRowValidators[cellName];
				continue;
			}

			this.newRowValidators[cellName] = {
				pipelines_id: customField.projects_pipelines_id,
				salesStateOrder: this.salesStateOrderMap[customField.projects_sales_states_id],
				columnName: cellName,
				validator: value => data.hasOwnProperty(cellName) && (dropdownTypes.indexOf(customField.type) > -1 ? nUndef : nEmptyString)(value)
			};
		}

		return cells;
	}
}

export default withSnackbar(ProjectListRow);
