import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import TaimerComponent from '../TaimerComponent';
import _ from 'lodash';
import cn from 'classnames';
import moment from 'moment';
import customTooltips from '../general/ChartTooltip';

// eslint-disable-next-line no-undef
// Chart.controllers.cumulativeLine = Chart.controllers.line.extend({});

class ProfitLossChart extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "charts/ProfitLossChart");

        this.state = {
        }
    }

    render() {
        const { labels, charts, visibleCharts, currencyFormatter, monthTitles } = this.props;

        const chartsToShow = [];
        const topCharts = [];

        let minValue = 0;
        let maxValue = 0;

        _.map(charts, (v, k) => {
            if (visibleCharts.indexOf(k) !== -1) {

                let min = _.min(v.data);
                let max = _.min(v.data);

                if (min < minValue) {
                    minValue = min;
                }
                if (max > maxValue) {
                    maxValue = max;
                }

                chartsToShow.push(v);
            }
        });

        const dealData = {
            labels: _.keys(labels).map(mKey => {
                const momentMonth = moment(mKey, "YYYY-MM");
                return `${monthTitles[momentMonth.month()]} ${momentMonth.format("YYYY")}`;
            }),
            datasets: chartsToShow,
        }

        return <div className="grid-block chart">
            <Bar data={dealData} datasetKeyProvider={(set) => set.key} options={{
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                        mode: 'index',
                        external: customTooltips,
                        itemSort: (itemA, itemB) => {
                            const datasetA = chartsToShow[itemA.datasetIndex];
                            const datasetB = chartsToShow[itemB.datasetIndex];
    
                            return datasetA.tooltipOrder - datasetB.tooltipOrder;
                        },
                        callbacks: {
                            beforeLabel: function (tooltipItem) {
                                const dataset = tooltipItem.dataset;
    
                                if (dataset.tooltipHeaderLine) {
                                    return dataset.tooltipHeaderLine;
                                }
                            },
                            label: function (tooltipItem) {
                                const dataset = tooltipItem.dataset;
    
                                var label = '';
                                if (dataset.label && !dataset.tooltipHeaderLine) {
                                    label += `${dataset.label}: `;
                                }
                                label += `<span data-positive="${tooltipItem.raw > 0 ? 1 : 0}" data-negative="${tooltipItem.raw > 0 ? 1 : 0}">${currencyFormatter(tooltipItem.raw)}</span>`;
    
                                return label;
                            },
                        }
                    },
                },
                elements: {
                    line: {
                        fill: false
                    }
                },
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        beginAtZero: false,
                        ticks: {
                            callback: function (value, index, values) {
                                return currencyFormatter(value);
                            }
                        }
                    },
                    x: {
                        stacked: true,
                        grid: { display: false }
                    },
                }
            }} />
        </div>
    }
}



export default ProfitLossChart;