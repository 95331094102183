import React, { Component } from 'react';
import TaimerComponent from '../TaimerComponent';
import { withSnackbar } from 'notistack';

import DialogBorder from './DialogBorder';
import DialogSection from './DialogSection';
import { DialogDropDownButton, DialogDropDownButtonItem } from './DialogDropDownButton';
import DataHandler from '../general/DataHandler';

import './ResourceLinkDeleteDialog.css';

export default withSnackbar(class ResourceLinkDeleteDialog extends TaimerComponent {

	constructor(props, context) {
		super(props, context, "dialogs/ResourceLinkDeleteDialog");		
	}

	deleteLink = () => {
		DataHandler.delete({url: 'resourcing/delete_link/' + this.props.id}).done(() => {
			this.props.onDelete(this.props.id);
		});
	}

	render() {
		return (
			<DialogBorder
                title={this.tr("Delete Link")}
                onClose={this.props.onClose}
                onDelete={this.deleteLink}
                className={"TMRDialogGrid TMRDialogInputs ResourceLinkDeleteDialog"}
                rightButtons={
                  	<div className={"TMRDialogButton red"} onClick={this.deleteLink}>{this.tr("Delete")}</div>
                }>
                <DialogSection>
                    <div className="full">                    
                        <span>{this.tr("Are you sure you want to delete relation between") + " "}</span>
                        <span className="linked-elem">{this.props.link.sourceData.text}</span>
                        <span>{" " + this.tr("and") + " "}</span>
                        <span className="linked-elem">{this.props.link.targetData.text}</span>
                        <span>{"?"}</span>
                    </div>
                </DialogSection>
            </DialogBorder>
		);
	}
});