import React, { Component } from 'react'
import TaimerComponent from '../TaimerComponent';
import _, { isEqual } from 'lodash';
import $ from 'jquery';
import moment from 'moment'
import { SettingsContext } from '../SettingsContext';
import DataHandler from '../general/DataHandler';
import { formatInputNumber } from '../helpers';
import { addDays, format, isSameDay } from 'date-fns';

import styles from './Notification.module.scss';
import cn from 'classnames';
import { getIconFromName, NotifcationSeen, NotificationDefinition, NotificationItem, NotificationsCategory, NotificationSubType, NotificationType } from './api';

interface Props {
    category: NotificationsCategory;
    type: NotificationDefinition;
    data: NotificationItem;
    refresh: (force: boolean) => void;
    openTeamChat: (state: any) => void;
    close: () => void;
    tr: (text: string) => string;
    
    updateNotification: (selector: (item: NotificationItem) => boolean, update: Partial<NotificationItem>) => void;
}
interface State {

}

interface ActionDef {
    title: string;
    action: () => void;
}

export class Notification extends TaimerComponent<Props, State>{
    static contextType = SettingsContext;

    static defaultProps = {
        data: {},
        companies: undefined
    }

    actions: Record<string, ActionDef> = {};

    constructor(props, context) {
        super(props, context, "notifications/Notification");

        const { tr } = this.props;

        this.actions = {
            chat: {
                title: tr('Chat'),
                action: this.openChat,
            },
            view_po: { 
                title: tr('View purchase order'),
                action: this.viewPurchaseOrder,
            },
            view_project: {
                title: tr('View project'),
                action: this.viewProject,
            },
            view_invoice: {
                title: tr('View invoice'),
                action: this.viewInvoice,
            },
            list_invoices: {
                title: tr('List invoices'),
                action: this.listInvoices,
            },
            view_overtime: {
                title: tr('View approvable overtime'),
                action: this.viewApprovableOvertimes,
            },
            view_approval_hours: {
                title: tr('View hours'),
                action: this.viewApprovalHours,
            },
            view_workhours: {
                title: tr('View hours'),
                action: this.viewWorkhours,
            },
            view_bill: {
                title: tr('View bill'),
                action: this.viewBill,
            },
            view_expense: {
                title: tr('View expense'),
                action: this.viewExpense,
            },
            view_activities: {
                title: tr('View activities'),
                action: this.viewActivities,
            },
            view_resourcing: {
                title: tr('View resourcing'),
                action: this.viewResourcing,
            },
            track_hours: {
                title: tr('Track hours'),
                action: this.viewTimeTracker,
            },
            view_report: {
                title: tr('View report'),
                action: this.viewReport,
            },
        };
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
    }

    removeNotification = () => {
        const { data, refresh, updateNotification } = this.props;
        updateNotification(x => x.id === data.id, {seen: NotifcationSeen.Hidden});
        
        DataHandler.put({ url: `notifications/remove/${data.id}` })
            .fail(err => {
                refresh(true);
            });
    }

    markAsSeen = () => {
        const { data, refresh, updateNotification } = this.props;
        DataHandler.put({ url: `notifications/seen/${data.id}` });
        updateNotification(x => x.id === data.id, {seen: NotifcationSeen.Seen});
    }

    openChat = () => {
        const { data, openTeamChat } = this.props;
        openTeamChat({ module: 'notifications', action: 'open-chat', id: data.target_id });
        this.props.close();
    }

    viewInvoice = () => {
        const { data } = this.props;
        const { functions: { updateView } } = this.context;
        updateView({ module: "invoices", action: "view", id: data.target_id, billid: data.value.invoice_nr }, false);
        this.props.close();
    }

    listInvoices = () => {
        const { data } = this.props;
        const { functions: { updateView }, userObject: { companies_id } } = this.context;
        updateView({ module: "invoices", action: "main", selectedTab: 'invoices', company: data.companies_id || companies_id, bill_ids: data.value.invoice_ids }, false);
        this.props.close();
    }

    viewProject = () => {
        const { data } = this.props;
        const { functions: { updateView } } = this.context;
        updateView({ module: "projects", action: "view", id: data.projects_id ?? data.target_id }, false);
        this.props.close();
    }

    viewResourcing = () => {
        const { data } = this.props;
        const { functions: { updateView } } = this.context;
        updateView({ module: "projects", action: "view", id: data.projects_id, selectedTab: "resourcing" }, false);
        this.props.close();
    }

    viewActivities = () => {
        const { data } = this.props;
        const { functions: { updateView } } = this.context;

        if (data.projects_id) {
            updateView({ module: "projects", action: "view", id: data.projects_id, selectedTab: 'home', selectedSubTab: "activities" }, false);
        } 
        else
        {
            updateView({ module: "customers", action: "view", id: data.customers_id, selectedTab: 'home', selectedSubTab: "activities" }, false);
        }

        this.props.close();
    }

    viewApprovableOvertimes = () => {
        const { functions: { updateView }, userObject: { companies_id } } = this.context;
        updateView({ module: "timemanagement", action: "main", selectedTab: "approvals", type_filter_value: 2, status_filter_value: 2 }, false);
        this.props.close();
    }

    viewWorkhours = () => {
        const { data } = this.props;
        const ids = data.value.ids?.join();

        // For some reason values for workhour statuses in workhour list are 1 for declined and 3 for approved although in database they are -1 and 1.
        const status_filter_value = data.subtype === NotificationSubType.WORKTIME_DECLINED ? 1 : 3

        const { functions: { updateView }, userObject: { companies_id } } = this.context;
        updateView({ module: "timemanagement", action: "main", selectedTab: "time-tracker", selectedSubTab: "myHours", nonUrlParams: { ids, status_filter_value } }, false);
        this.props.close();
    }

    viewApprovalHours = () => {
        const { data } = this.props;
        const { functions: { updateView }, userObject: { companies_id } } = this.context;
        updateView({ module: "timemanagement", action: "main", selectedTab: "approvals", status_filter_value: 4, user: data.target_id }, false);
        this.props.close();
    }

    viewPurchaseOrder = () => {
        const { data } = this.props;
        const { functions: { updateView }, userObject: { companies_id }, privileges } = this.context;

        if (data.value.po_ids && privileges.purchaseorders.read) {
            updateView({ module: "costs", action: "main", selectedTab: 'purchase-orders' }, false);
        }
        else if (data.value.po_id) {
            updateView({ module: "purchaseorder", action: "view", id: data.value.po_id }, false);
        }

        this.props.close();
    }

    viewBill = () => {
        const { data } = this.props;
        const { functions: { updateView } } = this.context;
        updateView({ module: "receivedinvoice", action: "view", companies_id: data.companies_id, id: data.target_id }, false);
        this.props.close();
    }

    viewExpense = () => {
        const { data } = this.props;
        const { functions: { updateView } } = this.context;
        updateView({ module: "worktrips", action: "modify", id: data.target_id, expenseType: 1 }, false);
        this.props.close();
    }        

    viewTimeTracker = () => {
        const { functions: { updateView } } = this.context;
        updateView({ module: "timemanagement", action: "main", selectedTab: "time-tracker" }, false);
        this.props.close();
    }

    viewReport = () => {
        const { data } = this.props;
        const { functions: { updateView } } = this.context;
        updateView({ module: "new_reports", action: "view", selectedTab: data?.value?.report_type, selectedSubTab: "template_" + data.target_id }, false);
        this.props.close();
    }

    render() {
        const { data, category, type } = this.props;
        const { userObject: { dateFormat }, taimerAccount } = this.context;

        const Icon = getIconFromName(type.icon);

        const localTime = moment.utc(data.creation_date, "YYYY-MM-DD HH:mm:ss").local().toDate();

        const isToday = isSameDay(localTime, new Date());
        const isYesterday = isSameDay(localTime, addDays(new Date(), -1));

        return (
            <div className={cn(styles.notification, data.seen === NotifcationSeen.NotSeen ? styles.unseen : styles.seen)}>
                <div className={styles.left}>
                    <div className={cn(styles.iconContainer, styles[`notification${data.subtype}`])}>
                        {Icon && <Icon />}
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.top}>
                        <div className={styles["main-header"]}>
                            <span className={styles.header}>
                                {data.header}
                            </span>
                            <span className={styles.time}>
                                {isToday ? moment(localTime).format('LT') : (isYesterday ? this.tr('Yesterday') : format(localTime, dateFormat))}
                            </span>
                        </div>
                    </div>
                    <div className={styles.bottom}>
                        <span className={styles.message}>
                            {data.msg}
                        </span>
                        <div className={styles.actions}>
                            {data.seen === NotifcationSeen.NotSeen && <span className={styles.action} onClick={this.markAsSeen}>
                                {this.props.tr('Mark as read')}
                            </span>}
                            {data.actions.map(action => <span key={action.name} className={styles.action} onClick={this.actions[action.name]?.action.bind(this, action)}>
                                {this.actions[action.name]?.title ?? action.name}
                            </span>)}
                            <span className={styles.action} onClick={this.removeNotification}>
                                {this.props.tr('remove')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Notification
