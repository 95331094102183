import React from "react";
import { Line } from "react-chartjs-2";
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter
} from "@mui/material";
import InsightBlock from "../../InsightBlock";
import InsightSlider from "../../InsightSlider";
import TaimerComponent from "../../../../TaimerComponent";
import _ from 'lodash';
import InsightDropDown from "../../InsightDropDown";
import { formatInputNumber } from "../../../../helpers";

const lineStyles = {
	fill: false,
	borderWidth: 2,
	pointBorderWidth: 2,
	pointBackgroundColor: "#FFF",
	lineTension: 0,
	pointRadius: 5,
	pointHoverRadius: 6
};

const convertToLineDataObject = (obj, color, hidden = false, type, tr = x => x) => {
	return {
		dataType: obj.type,
		data: obj.values,
		type,
		label: tr(obj.name),
		slider_rows: obj.slider_rows,
		sliderColumns: obj.sliderColumns,
		backgroundColor: color,
		borderColor: color,
		pointBorderColor: color,
		pointHoverBackgroundColor: color,
		hidden,
		...lineStyles
	};
};

const lineColors = ["#f5a623", "#2d9ff7", "#f97fab"];

class BillableVsNonBillable extends TaimerComponent {
	constructor(props, context) {
		super(props, context, "dashboard/insights/hours/BillableVsNonBillable");

		const { data, clientData } = props;
		this.state = {
			chartData: this._parseLineData(data),
			chartDataClient: this._parseLineData(clientData),
			sliderData: { open: false, data: [], columns: [], label: "" },
			hiddenIndexes: [],
			tab: "billable",
		};
	}

	componentDidUpdate = (oldProps, oldState) => {
		if (oldProps.data !== this.props.data || oldProps.clientData !== this.props.clientData || oldState.hiddenIndexes !== this.state.hiddenIndexes) {
			this.setState({
				chartData: this._parseLineData(this.props.data),
				chartDataClient: this._parseLineData(this.props.clientData),
			});
		}
	}

	componentDidMount = () => {
		super.componentDidMount();
	};

	componentWillUnmount = () => {
		super.componentWillUnmount();
	};

	_parseLineData = data => {
		const { months } = this.props;
		const { hiddenIndexes = [] } = this.state || {};
		return {
			labels: months,
			datasets: data.map((obj, i) =>
				convertToLineDataObject(obj, lineColors[i] || "#fff", hiddenIndexes.indexOf(i) != -1, months.length == 1 ? "bar" : undefined, this.tr)
			)
		};
	};

	_toggleHidden = index => {
		const hiddenIndexes = [...this.state.hiddenIndexes];
		const itemIndex = hiddenIndexes.indexOf(index);
		itemIndex == -1
			? hiddenIndexes.push(index)
			: hiddenIndexes.splice(itemIndex, 1);
		this.setState({ hiddenIndexes });
	};

	_renderGraph = () => {
		const {
			hiddenIndexes,
			tab,
		} = this.state;

		const chartData = tab === "billable" ? this.state.chartData : this.state.chartDataClient;
		const { datasets } = chartData;

		return (
			<div className="graph">
				<Line
					options={{
						responsive: true,
						plugins: {
							legend: {
								display: true,
								position: "bottom",
								onClick: (e, legendItem) => {
									const { datasetIndex } = legendItem;
									this._toggleHidden(datasetIndex);
								},
								labels: {
									boxWidth: 15,
									padding: 20,
									fontSize: 12,
									usePointStyle: true,
									generateLabels: () => {
										return datasets.map((obj, i) => {
											return {
												text: this.tr(obj.label),
												pointStyle: "rectRounded",
												fillStyle: obj.borderColor,
												datasetIndex: i,
												lineCap: "round",
												lineJoin: "round",
												strokeStyle: "#FFF",
												lineWidth: 5,
												hidden: hiddenIndexes.indexOf(i) != -1
											};
										});
									}
								}
							},
							tooltip: {
								callbacks: {
									labelColor: (tooltipItem, chart) => {
										return {
											backgroundColor:
												datasets[tooltipItem.datasetIndex].borderColor
										};
									},
									label: (tooltipItem) => {
										const dataset = tooltipItem.dataset;
										let label =
											_.escape(dataset.label) || "";
	
										if (label) {
											label += ": ";
										}
										label += (formatInputNumber(tooltipItem.raw, 'hours') ) + " h";
										return "  " + label;
									}
								},
								enabled: true,
								mode: "x",
								titleFontSize: 12,
								titleFontStyle: "bold",
								titleMarginBottom: 15,
								bodyFontSize: 11,
								bodyFontColor: "rgba(255,255,255,0.7)",
								bodySpacing: 8,
								caretSize: 0,
								xPadding: 20,
								yPadding: 20,
								intersect: false
							},
						},
						maintainAspectRatio: false,
						scales: {
							y: {
									grid: {
										color: "rgba(0,0,0,0.04)",
										zeroLineColor: "rgba(0,0,0,0.04)",
										drawBorder: false
									},
									type: "linear",
									position: "left",
									drawBorder: false,
									beginAtZero: true,
									ticks: {
										callback: (value, index, values) => {
											return `${value} h`;
										},
										font: {
											size: 12,
											weight: 'bold'
										},
										padding: 15
									}
								},
							x: {
									offset: true,
									ticks: {
										padding: 10,
										font: {
											size: 12,
										},
									},
									stacked: this.props.months.length > 1,
									grid: { display: false, drawBorder: false }
								}
						}
					}}
					data={chartData}
				/>
			</div>
		);
	};

	_renderTableRows = () => {
		const { hiddenIndexes, tab } = this.state;
		const { fullMonths } = this.props;

		const chartData = tab === "billable" ? this.state.chartData : this.state.chartDataClient;
		const { datasets, labels } = chartData;

		return (
			<TableBody className="table-body">
				{labels.map((label, i) => {
					const total = datasets.reduce(
						(prev, val) => prev + (val.data[i] || 0),
						0
					);
					let rowTotal = 0;
					const columns = datasets.map((obj, index) => {
						const value = obj.data[i];
						if (Number.isFinite(value)) rowTotal += value;
						const growth = total != 0 ? value / total : 0;
						return (
							<TableCell
								onClick={() =>
									this._toggleSlider({
										...obj,
										month: fullMonths[i],
										label: `${obj.label} - ${label}`
									})
								}
								key={index}
								align="right"
								className="highlightable"
							>
								{value && hiddenIndexes.indexOf(index) == -1 ? (
									<div className="row-container">
										{Number(value).toFixed(2)} h
										<span className="percent-label">
											{Math.round(growth * 100)}%
										</span>
										<div
											style={{
												position: "absolute",
												width: 2,
												left: 0,
												top: 0,
												bottom: 0,
												borderRadius: 4,
												backgroundColor: obj.borderColor
											}}
										/>
									</div>
								) : (
									"-"
								)}
							</TableCell>
						);
					});
					// adding cell for extra total column
					columns.push(
						<TableCell key="total" align="right">
							<div className="row-container">
								{rowTotal != 0 ? `${Number(rowTotal).toFixed(2)} h` : "-"}
							</div>
						</TableCell>
					);
					return (
						<TableRow className="row">
							<TableCell>{label}</TableCell>
							{columns}
						</TableRow>
					);
				})}
			</TableBody>
		);
	};

	_getTotalSum = () => {
		const { hiddenIndexes, tab } = this.state;
		const chartData = tab === "billable" ? this.state.chartData : this.state.chartDataClient;
		const { datasets, labels } = chartData;

		const filteredDatasets = datasets.filter((_, i) => hiddenIndexes.indexOf(i) == -1);

		try {
			return _.sum(filteredDatasets.map(x => _.sum(x.data)));
		} catch (error) {
			console.error("_getTotalSum error", error, datasets, filteredDatasets);

			return 0;
		}
	};

	_renderTable = () => {
		const { tr } = this;
		const { hiddenIndexes, tab } = this.state;

		const chartData = tab === "billable" ? this.state.chartData : this.state.chartDataClient;
		const { datasets, labels } = chartData;

		const columns = datasets.map((obj, index) => {
			return (
				<TableCell key={index} align="right" className="title">
					{tr(obj.label)}
				</TableCell>
			);
		});
		// adding extra total column
		columns.push(
			<TableCell key={"total"} align="right" className="title">
				{tr("Total")}
			</TableCell>
		);
		const totalSum = this._getTotalSum();
		let fullTotal = 0;
		const totals = datasets.map((obj, index) => {
			const sum = hiddenIndexes.indexOf(index) == -1 ? obj.data.reduce((prev, val) => prev + val, 0) : 0;
			fullTotal += sum;
			const percent = totalSum != 0 ? sum / totalSum : 0;
			return (
				<TableCell key={index} align="right" className="total">
					{Number(sum).toFixed(2)} h
					<span className="percent-label">{Math.round(percent * 100)}%</span>
				</TableCell>
			);
		});
		// adding footer for extra total column
		totals.push(
			<TableCell key={"total"} align="right" className="total">
				{Number(fullTotal).toFixed(2)} h
			</TableCell>
		);
		return (
			<div className="list">
				<Table className="summary">
					<TableHead>
						<TableRow>
							<TableCell className="title">{tr("Month")}</TableCell>
							{columns}
						</TableRow>
					</TableHead>
					{this._renderTableRows()}
					<TableFooter>
						<TableRow>
							<TableCell className="title">{tr("Total")}</TableCell>
							{totals}
						</TableRow>
					</TableFooter>
				</Table>
			</div>
		);
	};

	_toggleSlider = (sliderProps) => {
		const { tab } = this.state;
		const { showSlider } = this.props;

		showSlider({ tab, ...sliderProps });
	};

	_changeTab = (tab) => {
		this.setState({ tab });
	}

	render() {
		const { tr } = this;
		const { tab } = this.state;

		return (
			<InsightBlock
				title={tab === "billable" ? tr("Billable Hours vs. Non-Billable Hours") : tr("Client hours vs. Internal hours")}
				height={200}
				headerClass="block-header"
				rootClassName="block"
				wrapperClass="block-wrapper"
				className="full-width"
				width={6}
				filterComponents={(
					<InsightDropDown
						title={tr('View')}
						tabs={[
							{
								key: 'billable',
								label: tr('Billable vs non-billable'),
								action: () => this._changeTab('billable'),
							},
							{
								key: 'client',
								label: tr('Client vs internal'),
								action: () => this._changeTab('client'),
							},
						]}
						selected={tab}
					/>
				)
				}
			>
				<div className="graph-block">
					{this._renderGraph()}
					{this._renderTable()}
				</div>
			</InsightBlock>
		);
	}
}

export default BillableVsNonBillable;