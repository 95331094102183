import React from 'react';
import ListCell from "./../ListCell";
import TextInput from "./../../general/TextInput";
import ClickAwayWrapper from "./../../general/ClickAwayWrapper";
import { Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

/* validator */
import validator from 'validator';


const validators = {
    empty: e => !validator.isEmpty(e, { ignore_whitespace: true }),
    numeric: e => !e.length || validator.isNumeric(e),
    email: e => !e.length || validator.isEmail(e),
    percent100: e => {
		let percent = -1;

		if (typeof e === "number") {
			percent = e;
		} else if (typeof e === "string") {
			percent = Number(e.replace(",", ".").replace(/[^0-9\-\.]/,""));
		}

    	if(isNaN(percent) || percent < 0 || percent > 100) {
    		return false;
    	}
    	return true;
    },
    percent100AndNull: e => {
        if (e == null)
            return true;

		let percent = -1;

		if (typeof e === "number") {
			percent = e;
		} else if (typeof e === "string") {
			percent = Number(e.replace(",", ".").replace(/[^0-9\-\.]/,""));
		}

    	if(percent < 0 || percent > 100 || (!Number(percent) && percent != "0")) {
    		return false;
    	}
    	return true;
    },
    link: e => !e.length || validator.isURL(e),
};

class TextInputCell extends React.Component {
    static defaultProps = {
        allowEmpty: true,
		listCellType: ListCell,
		listCellProps: {},
		runOnEditedOnInput: false,
		runOnEditOnInput: false,
		width: 100,
		ownerRow: undefined,
		name: undefined,
		focusOnMount: false,
		value: "",
		editable: true,
		inputType: "text",
        placeholder: "",
        textInputProps: {},
        useClickAwayListener: true,
        showErrorBorder: false, // For TextInput's border.
		textAlign: "left",
        delayOnEdit: undefined,
		onEdited: () => {},
        onEdit: undefined,
        onEnter: () => {},
        onEmptyInput: () => {},
        onAlternativeRow: false
	};


	constructor(props) {
		super(props);

		this.listCell  = React.createRef();
		this.textInput = React.createRef();

		this.state = {
	        initialValue: undefined,
	        value: "",
			valid: true
        };

        this.focused       = false;
        this.onEditTimeout = undefined;

        this.handleClickAway        = this.handleClickAway.bind(this);
		this.runOnEdited            = this.runOnEdited.bind(this);
        this.focusOnEditor          = this.focusOnEditor.bind(this);
        this.handleTextInputOnFocus = this.handleTextInputOnFocus.bind(this);
        this.handleOnBlur           = this.handleOnBlur.bind(this);
        this.handleOnEnterEditMode  = this.handleOnEnterEditMode.bind(this);
        this.runOnEditOnInput       = this.runOnEditOnInput.bind(this);
    }


	componentDidUpdate(prevProps, prevState) {
		const prevVal    = (prevProps.editValue || prevProps.value);
		const currentVal = (this.props.editValue || this.props.value);

		// If value changed by another cell
		if(this.textInput.current && prevVal !== currentVal)
			this.textInput.current.setValue(currentVal);
    }


    // Miks täällä nyt taas on jotain validointeja
    runOnEdited() {
        this.focused = false;

        const onEditFunction = this.props.onEdit !== undefined && typeof(this.props.onEdit) === "function" 
            ? this.props.onEdit 
            : this.props.onEdited;

        let value = String(this.textInput.current.getValue());

        if(!this.props.allowEmpty && value.trim() === "") {
            this.props.onEmptyInput();
            return; 
        }

		const numeric = this.props.validation && this.props.validation.includes("numeric");

		if(numeric && (value.indexOf(",") > -1 && value.indexOf(".") === -1)) {
			value = value.replace(",",".");
		}

		const valid = !this.props.validation || this.props.validation.find(e => !validators[e](value)) === undefined;

		if(!valid){
			this.listCell.current.state.isInEditMode = true;
			onEditFunction.length === 1 ? onEditFunction(value) : onEditFunction(this.props.name, value);
			this.setState({ valid: valid });
			return;
		}
		else{
			this.setState({ valid: valid });
			onEditFunction.length === 1 ? onEditFunction(value) : onEditFunction(this.props.name, value);
			this.listCell.current.closeEdit();
		}			 
    }


    focusOnEditor() {
        if(this.textInput.current) {
            this.textInput.current.focus();
            this.textInput.current.select();
        }
    }

    handleClickAway() {
        if(!this.listCell.current.isInEditMode() || !this.focused) {
            return;
		}

        this.runOnEdited()
    }


    handleOnBlur() {
        this.focused = false;

        this.runOnEdited();
    }


    handleTextInputOnFocus(e) {
        if(this.props.textInputProps && this.props.textInputProps.onFocus && typeof(this.props.textInputProps.onFocus) === "function") {
            this.props.textInputProps.onFocus();
        }

        if(this.props.textInputProps?.selectOnFocus) {
            e.target.select();
        }
    }


    handleOnEnterEditMode() {
        if(!this.props.listCellProps.hasOwnProperty("noInitFocus") || !this.props.listCellProps.noInitFocus) {
            this.focusOnEditor();
        }
    }


    runOnEditOnInput() {
        const { delayOnEdit } = this.props;

        if(delayOnEdit !== undefined) {
            clearTimeout(this.onEditTimeout);
            this.onEditTimeout = setTimeout(this.runOnEdited, delayOnEdit);
        } else {
            this.runOnEdited()
        }
    }


    render() {
		const ListCellType  = this.props.listCellType;
		const listCellProps = Object.assign({}, {
			owner: 	  this,
			ref: 	  this.listCell,
			width: 	  this.props.width,
			value: 	  this.props.value,
			placeholder: this.props.placeholder,
			placeholderOnEmpty: this.props.placeholderOnEmpty || false,
			editable: this.props.editable,
			openChildren: 	this.props.openChildren,
			isChildrenOpen: this.props.isChildrenOpen,
			cellClassName:		this.props.cellClassName,
            onEnterEditMode: this.handleOnEnterEditMode,
            'data-testid': this.props['data-testid'] ? this.props['data-testid'] : this.props.name,
        }, this.props.listCellProps);

		const { 
			runOnEditOnInput, 
			runOnEditedOnInput,
            textInputProps,
            showErrorBorder,
            onAlternativeRow,
		} = this.props;

        const classNames = `textInputCell 
            ${textInputProps.className}
            ${showErrorBorder ? "errorBorder" : ""}
            ${onAlternativeRow ? "bordered blueBorderWhenFocused" : ""}`;
		
		if(this.props.validation && !this.state.valid) {
			listCellProps.inEditMode = true;
		}

        const cell = (
            <ListCellType 
                textAlign={this.props.textAlign} 
                showErrorBorder={this.props.validation ? !this.state.valid  : undefined} 
                error={this.props.validation ? !this.state.valid  : undefined} 
                {...listCellProps}>
                <TextInput
                    ref={this.textInput}
                    initValue={this.props.editValue || this.props.value}
                    onEnter={e => {
                        this.runOnEdited(e);
                        this.props.onEnter(e);
                    }}
                    onBlur={this.handleOnBlur}
                    onInputListener={(runOnEditedOnInput || runOnEditOnInput) 
                    ? this.runOnEditOnInput
                    : () => {}}
					error={this.props.validation ? !this.state.valid  : undefined}
                    type={this.props.inputType}
                    textAlign={this.props.textAlign}
                    focusOnMount={(!this.props.listCellProps.hasOwnProperty("noInitFocus") || !this.props.listCellProps.noInitFocus) && this.props.focusOnMount}
                    placeholder={this.props.placeholder}
                    overRunNativeValue={this.props.overRunNativeValue} 
                    {...textInputProps}
                    className={classNames}
                    onFocus={this.handleTextInputOnFocus}
                />
            </ListCellType>
        );

        const editMode = this.listCell.current && (!this.listCell.current.isInCreateMode() && this.listCell.current.isInEditMode());

        return (
            <ClickAwayWrapper active={editMode} onClickAway={this.handleClickAway}>
                {cell}
            </ClickAwayWrapper>
        );
	}
}

export default TextInputCell;
