import React from "react";

import _ from "lodash";
import ListCell from "../../list/ListCell";
import SettingRow from "../../list/rows/SettingRow";
import TrashCanIcon from "@mui/icons-material/Delete";
import TextInputCell from "../../list/cells/TextInputCell";
import CurrencyListCell from "../../list/CurrencyListCell";

export default class TimeManagementProtitleRow extends SettingRow {
    render() {
        const data = this.state.data;
        const cells = {
            delete: (
                <ListCell
                    onClick={() => this.delete()}
                    className="noBg pointer"
                    permanentEditMode={true}
                    width={this.props.columnWidthMap["delete"]}
                >
                    <TrashCanIcon />
                </ListCell>
            ),
            name: (
                <TextInputCell
                    onEdited={this.editAndSave}
                    name="name"
                    validation ={['empty']}
                    width={this.props.columnWidthMap["name"]}
                    value={this.state.data["name"]}
                    listCellProps={{
                        inEditMode:this.props.data.id < 0,
                    }}
                    focusOnMount={true}
                />
            ),
            price: (
                <TextInputCell
                    listCellType={CurrencyListCell}
                    validation={['numeric']}
                    onEdited={(name, value) => this.editAndSave(name, value.replace(",", "."))}
                    name="price"
                    width={this.props.columnWidthMap["price"]}
                    value={this.state.data["price"]}
                    textAlign="center"
                   
                />
            ),
        };
        return (
            <div
                className="listElement row flex"
                style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
            >
                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}
