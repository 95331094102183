import React, { Component } from 'react';
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { withSnackbar } from 'notistack';
import { SettingsContext } from '../../SettingsContext';
import QRCode from 'qrcode.react';
import DataHandler from '../../general/DataHandler';
import OutlinedField from "../../general/OutlinedField";
import LoginView from "../../login/Login";
import CognitoHelpers from '../../general/CognitoHelpers';

import { Button } from '@mui/material';

import styles from './MfaSettings.module.scss';

import iosBadge from '../img/appstore.svg';
import playBadge from '../img/play.svg';

/* cognito */
import { Auth } from '@aws-amplify/auth'
import { style } from '@mui/system';
import LoaderButton from '../../general/LoaderButton';

class MfaSettings extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/MfaSettings");

        this.state = {
            accountUnlinked: false,
            awsUser: {},
            password: "",
            errorPassword: 0,
            errorEmailVerification: false,
            emailVerification: "",
            qr: "",
            mfaVerifyCode: "",
            mfaActive: false,
            appListOpen: false,
        }
    }
    toggleState = (param) => this.setState({ [param]: !this.state[param] })

    resendSignUp = async () => {
        this.props.enqueueSnackbar(this.tr("A new verification code has been sent to your email address"), {
            variant: "info"
        })

        const { userObject: { email: username } } = this.context;
        try {
            Auth.resendSignUp(username);
        }
        catch (e) {
            console.log('resend code failed:', e);
        }
    }

    onSignUpClicked = () => {
        this.setState({ signingUp: true }, async () => {
            await this.signUp();
            this.setState({ signingUp: false });
        })
    }

    signUp = async () => {
        const { userObject: { email: username, language }, folder } = this.context;
        const password = this.password.value;

        //test if password is correct
        try {
            const response = await DataHandler.post({ 
                    url: "auth/login" 
                }, 
                { 
                    account: folder,
                    username, 
                    password, 
                });
        }
        catch(e) {
            console.log('taimer login failed:', e);
            this.setState({ errorPassword: 1 });
            return;
        }

        try {
            const awsUser = await Auth.signIn(username, password);
            this.setState({ awsUser, password, errorPassword: 0 }, this.setup);
            return awsUser;
        }
        catch (e) {
            console.log('error signing in:', e);

            if ( e.toString().includes("UserNotConfirmedException") ) {
                //username and password are correct but user hasnt confirmed email
                this.setState({ awsUser: { username }, password , errorPassword: 0 });
                this.resendSignUp();
                return;
            }       
        }

        //create cognito user 
        try {
            const { user: awsUser } = await Auth.signUp({
                username,
                password,
                attributes: {
                    email: username,
                    locale: language
                },
            });
            console.log(awsUser);
            this.setState({ awsUser, password, errorPassword: 0 });
            return awsUser;
        } catch (error) {
            if (error.toString().includes("InvalidPasswordException"))
                this.setState({ errorPassword: 2 });

            console.log('error signing up:', error);
        }
    }
    verifyEmail = () => {
        this.setState({ verifyingEmail: true }, async () => {
            const { userObject: { email: username } } = this.context;
            const { emailVerification: code, password } = this.state;
            try {
                await Auth.confirmSignUp(username, code);
                const awsUser = await Auth.signIn(username, password);
                this.setState({ awsUser, verifyingEmail: false }, this.setup);
                CognitoHelpers.setCognitoUserId(awsUser);
            } catch (error) {
                this.setState({ verifyingEmail: false });
                this.renderInvalidEmailCodeDialog();
                console.log('error confirming sign up', error);
            }
        });
    }

    resendEmailVerification = async () => {
        const { userObject: { email: username } } = this.context;
        try {
            
            await Auth.resendSignUp(username);
            console.log('code resent successfully');
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }
    authenticatedUser = async () => {
        let awsUser, mfaActive;
        const { userObject: { email } } = this.context;

        try {
            awsUser = await Auth.currentAuthenticatedUser();
            if (awsUser.attributes.email === email.trim()) {
                mfaActive = await Auth.getPreferredMFA(awsUser) !== 'NOMFA';
                console.log(awsUser);
                this.setState({ awsUser, mfaActive, accountUnlinked: false }, () => {
                    if (!mfaActive)
                        this.setup();
                })
                CognitoHelpers.setCognitoUserId(awsUser);
                return awsUser;
            }
        }
        catch (error) {
            this.getCognitoStatus();
            console.log(error);
        }
    }
    setup = async () => {
        let { awsUser, data } = this.state;
        const { userObject: { email } } = this.context;

        Auth.setupTOTP(awsUser).then((code) => {
            const issuer = "Heeros%20PSA";
            const qr = "otpauth://totp/"+ email + "?secret=" + code + "&issuer=" + issuer;
            
            this.setState({ awsUser, qr });
        });
    }
    setMFA = () => {
        this.setState({ settingMFA: true }, () => {
            const { awsUser, mfaVerifyCode } = this.state;
            Auth.verifyTotpToken(awsUser, mfaVerifyCode).then(() => {
                Auth.setPreferredMFA(awsUser, 'TOTP');
                this.setState({
                    qr: "",
                    mfaVerifyCode: "",
                    mfaActive: true,
                    settingMFA: false
                });
            }).catch( e => {
                console.error(e);
                this.setState({ settingMFA: false });
                this.renderInvalidMfaDialog();
            });
        })
    }
    getCognitoStatus = async () => {
        let status;
        try {
            status = await DataHandler.get({url: "settings/user/cognito_status"});
            if (status.status === 'unlinked')
                this.setState({ accountUnlinked: status.environments });
        }
        catch (e) {
            console.log("error checking cognito status", e);
        }
        
    }
    disableMFA = () => {
        const { awsUser } = this.state;
  
        Auth.setPreferredMFA(awsUser, 'NOMFA');
        this.setState({
            mfaActive: false,
        });
        this.setup();
    }
    componentDidMount() {
        this.authenticatedUser();
    }
    renderInvalidMfaDialog = () => {
        const { tr } = this;
        this.context.functions.showConfirmationDialog({
            header: tr("Invalid MFA code"), 
            warning: <>
                {tr("The multi-factor authentication (MFA) could not be verified. Either the code that you entered is not valid or you´re not using a supplied MFA device.")}
                <br/>
                <br/>
                <strong>{tr("Authentication code for device is not valid.")}</strong>
            </>,
            confirmText: tr("CLOSE"),
            confirmButtonClass: "blue",
            cancelButtonClass: "cancel-hidden",
        });
    }
    renderInvalidEmailCodeDialog = () => {
        const { tr } = this;
        this.context.functions.showConfirmationDialog({
            header: tr("Invalid email verification code"), 
            warning: tr("The email verification code is not valid. Please check your email for valid code"),
            confirmText: tr("CLOSE"),
            confirmButtonClass: "blue",
            cancelButtonClass: "cancel-hidden",
        });
    }
    renderDisableMfaConfirmDialog = () => {
        const { tr } = this;
        this.context.functions.showConfirmationDialog({
            header: tr("Disable two-factor authentication"), 
            warning: tr("Are your sure you wan’t to deactivate 2-factor authentication?"),
            confirmText: tr("DISABLE"),
            onConfirm: () => this.disableMFA(),
        });
    }
    renderLoading = () => {
        return <div><img className='main-page-loading-indicator' src={require('../../dashboard/insights/img/loading.svg').default} /></div>
    }
    renderMfaSetup = () => {
        const { qr, mfaVerifyCode, appListOpen, settingMFA } = this.state;
        const { tr } = this;

        if (!qr)
            return this.renderLoading();

        return <React.Fragment>
            <section className={styles.section}>
                <h3>{tr("Two-factor authentication")}</h3>
                <p>{tr("Turn On Two-Factor Authentication by clicking on the blue button. Add an extra layer of security to your account")}</p>
            </section>

            <section className={styles.section}>
                <h3>{tr("Step 1 : Download the Authenticator app app")}</h3>
                <p>{tr('Download the authenticator MFA device.')} <a onClick={() => this.toggleState("appListOpen")}>{appListOpen ? tr('Hide list of compatible applications.') : tr('See list of compatible applications.')}</a></p>

                {appListOpen && this.renderAppList()}

                {/* <div className="storelinks">
                    <a className="storelink" href="https://itunes.apple.com/app" target="_blank">
                        <img src={iosBadge} />
                    </a>
                    <a className="storelink" href="https://play.google.com/store/apps" target="_blank">
                        <img src={playBadge} />
                    </a>
                </div> */}
            </section>
 
            <section className={styles.section}>
                <h3>{tr("Step 2 : Open app and scan QR code")}</h3>
                <QRCode value={qr} size={256} />
            </section>

            <section className={styles.section}>
                <h3>{tr("Step 3 : Type a MFA code below")}</h3>
                <OutlinedField 
                    data-testid="mfa" 
                    noOnchangeValidation={true} 
                    className={styles.loginField}
                    value={mfaVerifyCode} 
                    onChange={(e) => this.setState({ mfaVerifyCode: e.target.value.trim() })}
                    autoComplete="new-password"
                    label={tr("MFA Code")} />
                <br/>
                <LoaderButton 
                    data-testid="verify-button"
                    className={styles.loginButton}
                    color="primary" 
                    onClick={() => this.setMFA()} 
                    size="large" 
                    text={tr("Verify").toUpperCase()}
                    loading={settingMFA}
                />
            </section>

        </React.Fragment>
    }
    renderAppList = () => {
        return <table cellspacing="0" cellpadding="1"> 
            <tbody> 
                <tr> 
                    <td style={{paddingRight: 16}}>Android:</td> 
                    <td width="100%"><a href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank" rel="noopener">Twilio Authy Authenticator</a>,&nbsp;<a href="https://play.google.com/store/apps/details?id=com.duosecurity.duomobile" target="_blank" rel="noopener">Duo Mobile</a>,&nbsp;<a href="https://play.google.com/store/apps/details?id=com.lastpass.authenticator" target="_blank" rel="noopener">LastPass Authenticator</a>,&nbsp;<a href="https://play.google.com/store/apps/details?id=com.azure.authenticator" target="_blank" rel="noopener">Microsoft Authenticator</a>,&nbsp;<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noopener">Google Authenticator</a>,&nbsp;<a href="https://m.vip.symantec.com/home.v#searchwebsite" target="_blank" rel="noopener">Symantec VIP</a></td> 
                </tr> 
                <tr> 
                    <td style={{paddingRight: 16}}>iOS:</td> 
                    <td width="100%"><a href="https://apps.apple.com/us/app/authy/id494168017" target="_blank" rel="noopener">Twilio Authy Authenticator</a>,&nbsp;<a href="https://apps.apple.com/us/app/duo-mobile/id422663827" target="_blank" rel="noopener">Duo Mobile</a>,&nbsp;<a href="https://apps.apple.com/us/app/lastpass-authenticator/id1079110004" target="_blank" rel="noopener">LastPass Authenticator</a>,&nbsp;<a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank" rel="noopener">Microsoft Authenticator</a>,&nbsp;<a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener">Google Authenticator</a>,&nbsp;<a href="https://m.vip.symantec.com/home.v#searchwebsite" target="_blank" rel="noopener">Symantec VIP</a></td> 
                </tr> 
            </tbody> 
       </table>
    }
    renderMfaActive = () => {
        const { tr } = this;
        return (
            <>
            <h3>{tr("Two-factor authentication")}</h3>
            <p>{tr("You have successfully assigned virtaul MFA, the virtual MFA will be required during sign-in")}</p>
            <br/>
            <Button 
                data-testid="verify-button" 
                color="secondary" 
                onClick={() => this.renderDisableMfaConfirmDialog()} 
                size="large">
                {tr("Deactivate").toUpperCase()}
            </Button>
            </>
        )
    }
    renderLoginForm = () => {
        const { functions, folder, userObject: { email: username } } = this.context;

        functions.showDialogContent(<div className={styles.loginView}>
                <LoginView 
                    cognitoSignIn 
                    account={folder} 
                    username={username} 
                    onSignIn={() => {
                        functions.closeDialog();
                        this.authenticatedUser();
                    }} />
            </div>
        );
    }
    renderLinkAccount = () => {
        const { accountUnlinked } = this.state;
        const { tr } = this;

        return <>
            <h3>{tr("Two-factor authentication")}</h3>
            <p>{tr("You have already activated two-factor authentication with your email in the following PSA account(s):")}</p>
            <ul className={styles.linkedAccounts}>
                {accountUnlinked.map(e => <li>{e}</li>)}
            </ul>
            <p>{tr("After clicking Activate below, you’ll be asked to sign in with your credentials and your current user will be linked to the ones above. This means in the future, your email and password will stay shared across all these accounts.")}</p>
            <Button onClick={() => this.renderLoginForm()}>{tr("Activate").toUpperCase()}</Button>
        </>
    }
    getPasswordError = (error) => {
        const { tr } = this;
        if (error == 1)
            return tr("Password did not match your current password");
        if (error == 2)
            return tr("Your password is too short. change longer password");
            
    }
    renderCognitoAccountCreate = () => {
        const { userObject } = this.context;
        const { password, awsUser, emailVerification, errorPassword, signingUp, verifyingEmail } = this.state;
        const { tr } = this;

        const header = () => <>
            <h3>{tr("Two-factor authentication")}</h3>
            <p>{tr("Before two-factor authentication can be activated, please verify your email account.")}</p>
            {errorPassword > 0 && <p className={styles.errorText}>{this.getPasswordError(errorPassword)}</p>}
            <OutlinedField
                className={styles.loginField}
                value={userObject.email}
                label={tr("Your email address")}
                disabled />
            <br/>
        </>
        
        if (!awsUser?.username)
            return <>
                {header()}
                <OutlinedField 
                    autoComplete="password" 
                    data-testid="password" 
                    noOnchangeValidation={true} 
                    className={styles.loginField} 
                    value={password}
                    inputRef={el => this.password = el}
                    onChange={(e) => this.setState({ password: e.target.value })} 
                    validation={["empty"]} 
                    type="password" 
                    label={tr("Account password")} />
                <br/>
                <LoaderButton 
                    data-testid="verify-button" 
                    loading={signingUp}
                    color="primary" 
                    className={styles.loginButton}
                    onClick={this.onSignUpClicked} 
                    size="large"
                    text={tr("Send verification code").toUpperCase()}
                />
            </>
        else
            return <>
                {header()}
                <OutlinedField 
                    data-testid="email-verification-code" 
                    noOnchangeValidation={true} 
                    className={styles.loginField} 
                    value={emailVerification} 
                    onChange={(e) => this.setState({ emailVerification: e.target.value })} 
                    validation={["empty"]}
                    autoComplete="one-time-code"
                    label={tr("Email verification code")} />
                <br/>
                <p>{tr("A verification code has been sent to your e-mail. Please enter the verification code above and hit Verify to move to the next step.")}</p>
                <LoaderButton 
                    loading={verifyingEmail}
                    data-testid="verify-button" 
                    className={styles.verifyButton}
                    color="primary" 
                    onClick={this.verifyEmail} 
                    size="large"
                    text={tr("Verify").toUpperCase()}
                />
                <div className={styles.havingIssues}>
                    {tr('Having issues?')} <a onClick={() => this.resendSignUp()}>{tr('Request a new code')}</a>
                </div>
                
            </>
    }

    render() {
        const { mfaActive, awsUser, accountUnlinked } = this.state;
        
        if (accountUnlinked)
            return this.renderLinkAccount();
        else if (!awsUser?.username || !awsUser?.signInUserSession)
            return this.renderCognitoAccountCreate();
        else if (mfaActive)
            return this.renderMfaActive();
        else
            return this.renderMfaSetup();
    }
}

export default withStyles(styles)(withSnackbar(MfaSettings));