import {
  ColDef,
} from 'ag-grid-community';
import TaimerComponent from '../TaimerComponent';
import StatusCell from './../list/cells/StatusCell';
import _ from 'lodash';
import StatusTag from '../general/StatusTag';
import { DisplayDataObject, getStatusDisplayValue } from './ColumnTypes';

interface Props {
    value: string;
    displayDatas: DisplayDataObject[];
    data: object;
    colDef: ColDef;
    node: {
      field: string;
      group: boolean;
      footer: boolean;
      expanded: boolean;
      aggData: object;
      allLeafChildren: {data}[];
    };
    column: {
      colId: string;
    },
    groupedDataValue?: string | number;
    displayArrayAsTextValues?: boolean;
    tr?: (text, replacers?: Record<string, string>) => string;
}

export default class StatusCellRenderer extends TaimerComponent<Props> {
  render() {
    const { displayDatas } = this.props;

    const id = (this.props.groupedDataValue || this.props.groupedDataValue?.toString() === "0") // Some displaydatas have id 0 so need to check also that.
      ? this.props.groupedDataValue 
      : this.props.data && (this.props.data[this.props.colDef.field || '']);

    if (id === null || id === undefined) {
      return <div />;
    } 

    const displayValue = getStatusDisplayValue(id, displayDatas, this.props?.displayArrayAsTextValues, this.props.tr);

    if(!Array.isArray(displayValue) || this.props?.displayArrayAsTextValues) {
        return displayValue;
    }

    return (
        <div className='ag-status-cell'>
              {displayValue.map(data => <StatusTag
                key={data.id}
                value={data.id}
                color={data.color}
                text={data.name}
            />)}
        </div>
    );
  }
}
