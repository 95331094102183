import { MoreHoriz } from "@mui/icons-material";
import { Popover } from "@mui/material";
import moment from "moment";
import React from "react";

import colors from "../../../colors";
import Link from "../../../general/Link";
import StatusTag from "../../../general/StatusTag";
import TextAreaCell from "../../../list/cells/TextAreaCell";
import ListCell from "../../../list/ListCell";
import PropsOnlyListRow from "../../../list/PropsOnlyListRow";

export default class ActivitiesListRow extends PropsOnlyListRow {
  constructor(props) {
    super(
      props,
      { anchorEl: null },
      {},
      "dashboard/my_day/components/ActivitiesListRow"
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      super.shouldComponentUpdate(nextProps, nextState) ||
      this.state.anchorEl != nextState.anchorEl ||
      this.state.emptyDescription != nextState.emptyDescription ||
      this.props.data != nextProps.data ||
      this.props.sharedData.updatedValues != nextProps.sharedData.updatedValues ||
      this.props.columnOrder != nextProps.columnOrder
    );
  }

  defineClassName() {
    return "myDayListRow";
  }

  _validateDescription = (value, data) => {
    if (value.trim() === "")
      this._showEmptyError();
    else {
      const { sharedData: { setActivityDesc, hasProjectRight, hasAccountRight } } = this.props;
      const hasWriteRight = data.type == "project" ? hasProjectRight : hasAccountRight;
      this.setState({ emptyDescription: false });
      hasWriteRight && setActivityDesc(value, data);
    }
  }

  _showEmptyError = () => {
    this.setState({ emptyDescription: true });
    this.props.rowProps.enqueueSnackbar(this.tr('Description cannot be empty!'), {
      variant: 'error'
    });
  }

  _getReminderStringAndColor = (reminder) => {
    if (!reminder || reminder == "0000-00-00") return { string: "-", color: "#aaa" };
    const mReminder = moment(reminder, "YYYY-MM-DD").startOf("day");
    const today = moment();
    const diff = today.startOf("day").diff(mReminder, "days");
    let string;
    let color = "#aaa";
    if (diff > 0) {
      string =
        diff == 1 ? this.tr("Yesterday") : `${this.tr("${amount} days ago")}`;
      color = colors.carnation_pink;
    } else if (diff == 0) {
      string = `${this.tr("Today")}`;
      color = colors.dark_sky_blue;
    } else {
      string =
        diff == -1 ? this.tr("Tomorrow") : `${this.tr("In ${amount} days")}`;
      color = diff < -3 ? colors.greenish_cyan : colors.orangey_yellow;
    }
    return {
      string: string.replace("${amount}", Math.abs(diff)),
      color: color,
    };
  };

  _getStatusStringAndColor = (status) => {
    switch (status) {
      case "1":
        return { color: colors.greenish_cyan, string: this.tr("Done") };
      case "2":
        return { color: colors.orangey_yellow, string: this.tr("Due") };
      case "3":
        return { color: colors.carnation_pink, string: this.tr("Overdue") };
      default:
        return { color: "#aaa", string: this.tr("-") };
    }
  };

  _getProjectCellContent = (data) => {
    if (!data.projects_id || data.projects_id < 0) {
      return (
        <div className="text-container">
          <a
            href={`index.html?module=customers&action=view&id=${data.customer_id}`}
            onClick={(e) => {
              !e.ctrlKey && !e.metaKey && e.preventDefault();
              this.context.functions.updateView({module: 'customers', action: 'view', id:data.customer_id});
            }}
            className="blue-highlight"
          >
            {data.customer || "–"}
          </a>
        </div>
      );
    }

    return (
      <div className="text-container">
        <Link url={`index.html?module=projects&action=view&id=${data.projects_id}`}
          title={data.project || "-"}
          onClick={(e) => {
            !e.ctrlKey && !e.metaKey && e.preventDefault();
            this.context.functions.updateView({module: 'projects', action: 'view', id:data.projects_id});
          }
          }
          className="top blue-highlight"
        >
          {data.project || "–"}
        </Link>
        <Link url={`index.html?module=customers&action=view&id=${data.customer_id}`}
          onClick={(e) =>{
            !e.ctrlKey && !e.metaKey && e.preventDefault();
            this.context.functions.updateView({module: 'customers', action: 'view', id:data.customer_id});
          }}
          title={data.customer || "-"}
          className="bottom clickable blue-highlight"
        >
          {data.customer || "–"}
        </Link>
      </div>
    );
  };

  defineCells() {
    const {
      userObject: { dateFormat },
    } = this.context;
    const {
      data,
      sharedData: { menuItems, showEditPopUp, setActivityDesc, updatedValues, hasProjectRight, hasAccountRight },
    } = this.props;
    const { anchorEl } = this.state;

    let cells = {};

    Object.keys(data).forEach((key) => {
      cells[key] = (
        <ListCell onlyDisplay editable={false}>
          {data[key] || ""}
        </ListCell>
      );
    });

    const statusData = this._getStatusStringAndColor(data.status);
    const reminderData = this._getReminderStringAndColor(
      updatedValues[data.key] && updatedValues[data.key].date
        ? updatedValues[data.key].date
        : data.date
    );

    const hasWriteRight = data.write ? data.write : (data.type == "project" ? hasProjectRight : hasAccountRight);
    cells = {
      ...cells,
      actions: (
        <ListCell cellClassName="actions" onlyDisplay editable={false}>
          <React.Fragment>
            <button
              onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
            >
              <MoreHoriz className="icon" />
            </button>
            <Popover
              open={Boolean(anchorEl)}
              elevation={4}
              anchorEl={anchorEl}
              onClose={() => this.setState({ anchorEl: null })}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {(menuItems || []).filter(item => !item.isWriteItem || (item.isWriteItem && hasWriteRight) ).map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      color: item.hasOwnProperty("color") && item.color,
                    }}
                    className="my-day-list-menu-item"
                    onClick={() => {
                      this.setState({ anchorEl: null });
                      item.action(data);
                    }}
                  >
                    <item.icon
                      style={{
                        color: item.hasOwnProperty("color") && item.color,
                      }}
                      className="icon"
                    />
                    {item.label}
                  </div>
                );
              })}
            </Popover>
          </React.Fragment>
        </ListCell>
      ),
      project_account: (
        <ListCell onlyDisplay editable={false}>
          {this._getProjectCellContent(data)}
        </ListCell>
      ),
      type: (
        <ListCell onlyDisplay editable={false}>
          <div
            onClick={(e) => hasWriteRight && showEditPopUp(e.currentTarget, "type", data)}
            className="type-icon"
          >
            {data.icon && <img src={data.icon} />}
          </div>
        </ListCell>
      ),
      description: (
        <TextAreaCell
          listCellProps={{
            className: `activity-description-cell ${this.state.emptyDescription ? "activity-description-error-cell" : ""}`,
            inEditMode: this.state.emptyDescription,
            title:
              updatedValues[data.key] && updatedValues[data.key].description
                ? updatedValues[data.key].description
                : data.description,
          }}
          onEdited={(value) => this._validateDescription(value, data)}
          onlyDisplay
          editable={hasWriteRight}
          value={ this.state.emptyDescription ? "" : (
            updatedValues[data.key] && updatedValues[data.key].description
              ? updatedValues[data.key].description
              : data.description
          )}
        />
      ),
      status: (
        <ListCell onlyDisplay editable={false}>
          <StatusTag color={statusData.color} text={statusData.string} />
        </ListCell>
      ),
      reminder: (
        <ListCell cellClassName="highlightable" onlyDisplay editable={false}>
          <div
            className="click-container"
            onClick={(e) => hasWriteRight && showEditPopUp(e.currentTarget, "reminder", data)}
          >
            <div className="text-container">
              <div className="top">
                {updatedValues[data.key] && updatedValues[data.key].date
                  ? moment(updatedValues[data.key].date, "YYYY-MM-DD").format(
                      dateFormat
                    )
                  : (data.date && data.date != "0000-00-00")
                  ? moment(data.date, "YYYY-MM-DD").format(dateFormat)
                  : "–"}
              </div>
              <div style={{ color: reminderData.color }} className="bottom">
                {reminderData.string}
              </div>
            </div>
          </div>
        </ListCell>
      ),
      contact: (
        <ListCell onlyDisplay editable={false}>
          <a
            href={`index.html?module=contact&action=view&id=${data.contact_id}&company=${data.targeted_to_company}`}
            title={data["contact"] || "-"}
            onClick={(e) => {
              !e.ctrlKey && !e.metaKey && e.preventDefault();
              this.context.functions.updateView({module: 'contact', action: 'view', id:data.contact_id, company: data.targeted_to_company});
            }}
            className={data["contact"] && "blue-highlight"}
          >
            {data["contact"] || "-"}
          </a>
        </ListCell>
      ),
      user: (
        <ListCell onlyDisplay editable={false}>
          <div title={data["targeted_to"] || "-"}>
            {data["targeted_to"] || "-"}
          </div>
        </ListCell>
      ),
    };

    return cells;
  }
}
