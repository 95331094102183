import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import DataHandler from './DataHandler';

class Cacher {
    constructor() {
        this.initialized = false;
        this.stored      = {};

        [
            "get",
            "getAndCache",
            "initialize",
            "isInitialized",
            "setInitialized",
            "exists",
            "getTime",
            "reset",
            "forceRefresh"
        ].forEach(f => this[f] = this[f].bind(this));
    }

    initialize(preloads = []) {
        if(this.initialized) {
            return;
        }

        Promise.all(preloads.map(c => {
            return this.getAndCache(...c);
        })).then(this.setInitialized)
    }

    isInitialized() {
        return this.initialized;
    }

    setInitialized() {
        this.initialized = true;
    }

    exists(parameters) {
        const p = Object.keys(this.stored).find(o => {
            return isEqual(
                this.stored[o].originalParameters, 
                parameters
            );
        });

        return [
            Boolean(p), 
            p, 
            this.stored[p] || {}
        ];
    }

    entryIsExpired(key) {
        const time = this.getTime();
        const {
            maxAge, cachedAt
        } = this.stored[key];

        return maxAge !== null && (time - cachedAt) > maxAge;
    }

    getTime() {
        return Math.round(new Date().getTime() / 1000);
    }

    async get(c, cache = false, maxAge = null) {
        // Pre-emptively clone the passed
        // parameters to prevent hard to find
        // object reference bugs.
        const clone = cloneDeep(c);
        const cStr  = JSON.stringify(clone);
        const [exists, key, ex] = this.exists(clone);

        if(exists && !this.entryIsExpired(key)) {
            return this.stored[key].data;
        }

        const promise = DataHandler.get(c);

        if(!exists && !cache) {
            return await promise;
        }

        this.stored[cStr] = {
            originalParameters: clone,
            pending: true,
            data: promise.then(data => {
                this.stored[cStr].pending  = false;
                this.stored[cStr].cachedAt = this.getTime();
                this.stored[cStr].maxAge   = maxAge !== null
                    ? maxAge
                    : ex?.maxAge || null;

                return data;
            })
        };

        return this.stored[cStr].data;
    }

    getAndCache(c, maxAge = null) {
        return this.get(c, true, maxAge);
    }

    forceRefresh(c) {
        const clone = cloneDeep(c);
        const [exists, key] = this.exists(clone);

        if(!exists) {
            console.warn(`${c} is not stored.`);
            return;
        }

        delete this.stored[key];

        this.getAndCache(clone);
    }

    reset() {
        this.initialized = false;
        this.stored      = {};
    }
}

export default Cacher;
