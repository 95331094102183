import React from 'react';
import ListCell from "./../../list/ListCell";
import DataHandler from '../../general/DataHandler';
import ContextMenu from '../../general/ContextMenu';
import { withSnackbar } from 'notistack';
import FocusGroup from "../../general/FocusGroup";
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from './../../SettingsContext';
import TreeStructureIndicatorCell from "./../../list/cells/TreeStructureIndicatorCell";
import TextInputCell from "./../../list/cells/TextInputCell";
import List from "./../../list/List";
import SettingRow from "../../list/rows/SettingRow";
import { MoreHoriz,Delete,CheckCircle,Cancel,PlaylistAdd } from "@mui/icons-material";
import { MenuItem, Button, Tooltip, Switch } from '@mui/material';

import cloneDeep from "lodash/cloneDeep";
import './AccountingDimensionsTreeStructure.scss';

class HeaderRow extends SettingRow {

    static contextType = SettingsContext;

	constructor(props) {
		super(props, { checked: false}, undefined, "settings/components/AccountingDimensionsTreeStructureRow");
		this.focusGroup = React.createRef();
	}
	
	shouldComponentUpdate(nextProps) {
		if(nextProps.hasOwnProperty("data") && nextProps['data']['id'] !== this.props.data['id']) {
			this.setData({ data: nextProps.data });
			return false;
		}
		return true;
	}

    addNewItem = () => {
        const { data, rowProps: { addNewRow } } = this.props;

        this.setState({ showChildren: true }, () => addNewRow(data.orig_id));
    }

    editRow = (name, value) => {
        const { data, rowProps: { saveRow, setData } } = this.props;

        if (data[name] !== value) {
            data[name] = value;
            if (Number(data.orig_id) > 0) {
                saveRow(data)
            }
            else {
                setData(data);
            }
        }
    }

    createDisableableMenuItem = (text, disableInfoMsg = "", onClickFunc = () => {}, disabled = false, Icon = undefined, className = "", iconClass = "") => {
		return disabled ? (
			<Tooltip title={disableInfoMsg} placement="right">
				<div>
					<MenuItem disabled={true} className={className} onClick={() => {}}>
						<Icon className={iconClass} title="" />{text}
					</MenuItem>
				</div>
			</Tooltip>
		) : (			
			<MenuItem className={className} onClick={onClickFunc}>
				<Icon className={iconClass} title="" />{text}
			</MenuItem>
		)
	}

	render() {
        const { data, columnWidthMap, 
            rowProps: { deleteRow, saveRow } } = this.props;
            
        const cellWidths = {
            name: Object.values(columnWidthMap).reduce((a, b) => a + b) - columnWidthMap.context - columnWidthMap.openChildren - columnWidthMap.is_header_default
        }

		const cells = {
            context: Number(data.orig_id) > 0 ?
                <ContextMenu label={<MoreHoriz />} buttonProps={{ className: 'action-menu' }} className="cell contextCell pointer" style={{ width: columnWidthMap['context'] + "px", textAlign: "center" }} noExpandIcon>
                    <MenuItem onClick={() => this.addNewItem()}>
                        <PlaylistAdd />
                        {this.tr("Add item")}
                    </MenuItem>
                    {this.createDisableableMenuItem(
                        this.tr("Delete"), 
                        this.tr("Cannot delete: Dimension is selected to be shown in invoice."),
                        () => deleteRow(data),
                        data.deletable == 0,
                        Delete,
                        "delete"
                    )}
                </ContextMenu>
                :
                <ListCell width={columnWidthMap['context']} permanentEditMode={true} onlyDisplay={true}>
                    <Cancel className="cancelIcon" onClick={() => deleteRow(data)} />
                    <CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => saveRow(data)} />
                </ListCell>,
            openChildren:
                <TreeStructureIndicatorCell
                    width={columnWidthMap['openChildren']}
                    name="openChildren"
                    childrenVisible={this.state.showChildren}
                    listCellProps={{ alignCell: true, textAlign: "left", className: "openChildren" }}
                    rowData={this.props}
                    showExpander={true}
                    onExpanderClick={() => this.setState({showChildren: !this.state.showChildren})}
                    value={""} />,
            name:
                <TextInputCell
                    // overrideWidth={cellWidths.name}
                    width={cellWidths.name}                     
                    placeholder={this.tr("Dimension...")}
                    name="name"
                    value={data.name}
                    editable={true}
                    listCellProps={{ 
                        inEditMode: Number(data.orig_id) < 0 || data.name_invalid,
                        showErrorBorder: data.name_invalid                    
                    }}
                    onEdited={(name, value) => this.editRow(name, value)}
                />,
            dimension:
                <div></div>,
            is_header_default:
                <ListCell
                    width={columnWidthMap['is_header_default']}
                    name="is_header_default"
                    onlyDisplay={true}
                    editable={false}
                />
        }

		let children = this.props.children;
		const childRows = this.createChildren(children, AccountingDimensionsTreeStructureRow);

		return (
			<div className="listElement mainLevel">
				<div style={{ height: "44px", lineHeight: "44px" }} className={"listElement row flex parent"} >
					<FocusGroup 
						ref={this.focusGroup} 
						columnOrder={this.props.columnOrder}>
						{this.props.columnOrder.map(columnName => {
							const cell = cells[columnName];
							
							return columnName === "context" ? cell : React.cloneElement(cell, {
								listCellProps: { ...(cell.props.hasOwnProperty("listCellProps") ? cell.props.listCellProps : {}) }
							});
						})}
					</FocusGroup>
				</div>
				{ this.state.showChildren ?
                    <React.Fragment>
                    {childRows}
                    </React.Fragment>
				: undefined}
			</div>
		);
	}
}

class ItemRow extends SettingRow {

	static contextType = SettingsContext;

	constructor(props) {
		super(props, { checked: false}, undefined, "settings/components/AccountingDimensionsTreeStructureRow");
	}

	shouldComponentUpdate(nextProps, nextState) {
		return super.shouldComponentUpdate(nextProps, nextState) || this.props.data !== nextProps.data
    }

    editRow = (name, value) => {
        const { data, rowProps: { saveRow, setData } } = this.props;

        if (data[name] !== value) {
            data[name] = value;
            data[name + "_invalid"] = false;
            if (Number(data.orig_id) > 0) {
                saveRow(data)
            }
            else {
                setData(data);
            }
        }
    }

	render() {
		const { data, columnWidthMap, rowProps: { deleteRow, saveRow } } = this.props;

        const cells = {
            context: Number(data.orig_id) > 0 ?
                <ListCell width={columnWidthMap['context']} className="contextCell deleteCell pointer" onClick={() => deleteRow(data)} permanentEditMode>
                    <Delete />
                </ListCell> 
                :
                <ListCell width={columnWidthMap['context']} permanentEditMode={true} onlyDisplay={true}>
                    <Cancel className="cancelIcon" onClick={() => deleteRow(data)} />
                    <CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => saveRow(data)} />
                </ListCell>,
            openChildren:
                <TreeStructureIndicatorCell
                    width={columnWidthMap['openChildren']}
                    name="openChildren"
                    childrenVisible={true}
                    listCellProps={{ alignCell: true, textAlign: "left" }}
                    rowData={this.props}
                    showExpander={false}
                    value={""} />,
            name:
                <TextInputCell
                    width={columnWidthMap['name']}
                    placeholder={this.tr("Item...")}
                    name="name"
                    value={data.name}
                    editable={true}
                    listCellProps={{ 
                        inEditMode: Number(data.orig_id) < 0 || data.name_invalid,
                        showErrorBorder: data.name_invalid
                    }}
                    onEdited={(name, value) => this.editRow(name, value)}
                />,
            dimension:
                <TextInputCell
                    width={columnWidthMap['dimension']}
                    placeholder={this.tr("Code...")}
                    name="dimension"
                    value={data.dimension}
                    editable={true}
                    listCellProps={{ 
                        inEditMode: Number(data.orig_id) < 0 || data.dimension_invalid,
                        showErrorBorder: data.dimension_invalid
                     }}
                    onEdited={(name, value) => this.editRow(name, value)}
                />,
            is_header_default:
                <ListCell name="is_header_default" width={this.props.columnWidthMap["is_header_default"]} onlyDisplay>
                    <Switch
                        color="primary"
                        checked={data['is_header_default'] == 1}
                        onChange={event => {
                            this.editRow("is_header_default", event.target.checked ? 1 : 0)
                        }}
                    />
                </ListCell>
            }

        return (
            <div style={{ height: "44px", lineHeight: "44px" }} id={this.props.data.id} className={"listElement row flex"}>
                {this.props.columnOrder.map(columnName => {
                    const cell = cells[columnName];

                    return columnName === "context" ? cell : React.cloneElement(cell, {
                        listCellProps: { ...(cell.props.hasOwnProperty("listCellProps") ? cell.props.listCellProps : {}) }
                    });
                })}
            </div>
        );
	}
}

class AccountingDimensionsTreeStructureRow extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/components/AccountingDimensionsTreeStructureRow");
    }

    render = () => {
        const { data } = this.props;
        const commonProps = {
            ...this.props,
            key: data.id,
            enqueueSnackbar: this.props.enqueueSnackbar
        }

        const isItemRow = Number(data.orig_parentId) > 0;

        return (
            <React.Fragment>
                {!isItemRow ? <HeaderRow
                    {...commonProps}
                /> : undefined}
                {isItemRow ? <ItemRow
                    {...commonProps}
                /> : undefined}
            </React.Fragment>
        )
    }
}

class AccountingDimensionsTreeStructure extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/components/AccountingDimensionsTreeStructure");

        this.state = {
            dimensions: [],
            newRowId: -1
        }

        this.columnConfig = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.columns = [
            { field: "context", name: "context", header: "", width: 50, ...this.columnConfig },
            { field: "openChildren", name: "openChildren", header: "", width: 30, forceMinimumWidth: true, ...this.columnConfig },
            { field: "name", name: "name", header: this.tr("Dimension"), width: 400, header: this.tr("Dimension"), ...this.columnConfig },
            { field: "dimension", name: "dimension", header: "", width: 200, ...this.columnConfig },
            { field: "is_header_default", name: "is_header_default", header: this.tr("Default"), width: 50, ...this.columnConfig },
        ];
    }

    componentDidMount () {
        super.componentDidMount();
        this.getDimensions();
    }

    validate = (data) => {
        const { enqueueSnackbar } = this.props;

        const isItemRow = Number(data.orig_parentId) > 0;

        const dimensions = cloneDeep(this.state.dimensions);
        const i = dimensions.findIndex(d => d.id == data.id);

        const requiredValues = ["name"];
        if (isItemRow) {
            requiredValues.push("dimension");
        }
        let errors = false;

        requiredValues.forEach(v => {
            const d = data[v];
            if (!d?.trim() && d !== "0") {
                errors = true;
                dimensions[i][v + "_invalid"] = true;
                dimensions[i][v] = d;
            }
            else {
                dimensions[i][v + "_invalid"] = false;
            }
        });

        if (errors) {
            enqueueSnackbar && enqueueSnackbar(this.tr("Required fields not filled"), {
                variant: "error",
            });
        }
        this.setState({ dimensions });

        return !errors;
    }

    saveRow = (data) => {
        const { company, onHeadersChanged } = this.props;
        const isItemRow = Number(data.orig_parentId) > 0;
       
        if (!this.validate(data)) {
            return;
        }

        this.setData(data);

        data.company = company;
        data.accounting_dimension_headers_id = data.orig_parentId;
        let url = !isItemRow ? 'accounting/dimension_headers' : 'accounting/dimensions';
        let method = "put";
        if (Number(data.orig_id) < 1) {
            url += "/" + this.props.integration;
            method = "post";
        }

        data.id = data.orig_id;
        DataHandler[method]({ url: url }, { data: JSON.stringify(data) }).done(response => {
            !isItemRow && onHeadersChanged && onHeadersChanged();
            this.getDimensions();
        });
    }

    setData = (data) => {
        const dimensions = cloneDeep(this.state.dimensions);
        const newDimensions = dimensions.map(d => {
            if (d.id == data.id) {
                d = cloneDeep(data);
            }
            if ((Number(data.orig_id) > 0 || Number(d.orig_id) < 1) && data.is_header_default == 1 && d.id != data.id && d.parentId == data.parentId) { // Remove default from header's other dimensions.
                d.is_header_default = 0;
            }
            return d;
        });

        this.setState({ dimensions: newDimensions });
    }

    /**
     * @param parentId Header's id for item rows
     */
    addNewRow = (parentId = 0) => {
        const { newRowId } = this.state;
        const dimensions = cloneDeep(this.state.dimensions);

        const preId = Number(parentId) > 0 ? "i" : "h";

        dimensions.unshift({ id: preId + "_" + newRowId, orig_id: newRowId, parentId: "h_" + parentId, orig_parentId: parentId });
        this.setState({ dimensions, newRowId: newRowId - 1 });
    }

    /**
     * 
     * @param data Row's data
     */
    deleteRow = (data) => {
        const { enqueueSnackbar, onHeadersChanged } = this.props;
        const isItemRow = Number(data.orig_parentId) > 0;

        if (Number(data.orig_id) > 0) {
            data.deleted = "1";
            data.id = data.orig_id;

            this.context.functions.showConfirmationDialog({
                header: !isItemRow ? this.tr("Delete dimension?") : this.tr("Delete item?"),
                warning: !isItemRow ? this.tr("Are you sure you want to delete dimension ${dimension}?", {dimension: data.name}) : this.tr("Are you sure you want to delete item ${item}?", {item: data.name}),
                onConfirm: () => {
                    const url = !isItemRow ? 'accounting/dimension_headers' : 'accounting/dimensions'; 
                    DataHandler.delete({ url: url }, { data: JSON.stringify(data) }).done(response => {
                        const msg = !isItemRow ? this.tr("Dimension ${dimension} deleted successfully", {dimension: data.name}) : this.tr("Item ${item} deleted successfully", {item: data.name});
                        enqueueSnackbar(msg, {
                            variant: "success",
                        });
                        !isItemRow && onHeadersChanged && onHeadersChanged();
                        this.getDimensions();
                    })
                    .fail(err => {
                        this.getDimensions();
                        enqueueSnackbar(this.tr('Error in deleting!'), {
                            variant: "error",
                        });
                    });
                }
            });
        }
        else {
            this.removeRow(data.id);
        }
    }

    removeRow = (id) => {
        const dimensions = cloneDeep(this.state.dimensions);
        const index = dimensions.findIndex(d => d.id == id);
        dimensions.splice(index, 1);
        this.setState({ dimensions });
    }

    getDimensions = () => {
        DataHandler.get({url: `accounting/dimensions_tree/${this.props.company}/${this.props.integration}`}).done(response => {
            this.setState(response);
        });
    }

    render = () => {
        const { dimensions } = this.state;
        const { enqueueSnackbar } = this.props;

        const rowProps = {
            saveRow: this.saveRow,
            addNewRow: this.addNewRow,
            deleteRow: this.deleteRow,
            setData: this.setData,
            enqueueSnackbar: enqueueSnackbar
        }

        return (
            <div className='accounting-dimensions-tree-container'>
                <Button className="green" onMouseUp={() => this.addNewRow()} size="large">{this.tr("Add dimension")}</Button>
                <List
                    ref={this.list}
                    data={dimensions}
                    idType="string"
                    rowKey="id"
                    columns={this.columns}
                    height="fitRemaining"
                    className="settingsList integrationList"            
                    ignoreRowPropsChange={false}
                    listRowType={AccountingDimensionsTreeStructureRow}
                    treeData={true}
                    rowProps={{...rowProps}}
                    hideHeader={dimensions?.length < 1}
                />
            </div>
        )
    }
}

export default withSnackbar(AccountingDimensionsTreeStructure);
