import TaimerComponent from '../TaimerComponent';
import ErrorsList from './elements/ErrorsList';
import SubtitleDialog from './SubtitleDialog';

import './ConfirmSuccessDialog.css';

export interface successData {
    id: string | number;
}

export interface errorData {
    header: string;
    message: string;
}

export interface errorsData {
    header: string;
    data: errorData[];
    type?: string;
}

export interface ConfirmSuccessDialogProps {
    successData: successData[];
    errorsData: errorsData[];
    successMessage: string;
    closeDialog: () => void;
    onConfirm: (ids) => void;
    confirmText: string;
    header: string;
    loading?: boolean;
}

class ConfirmSuccessDialog extends TaimerComponent<ConfirmSuccessDialogProps> {
    constructor(props, context) {
        super(props, context, "dialogs/ConfirmSuccessDialog");
    }

    renderContent = () => {
        const { errorsData } = this.props;

        return (
            <>
            <div id="confirm-success-dialog-content">
                {(errorsData || []).map((e) => {
                    if (e.data?.length > 0) {
                        return this.renderErrorsList(e.data, e.header, e.type)
                    }
                    return null;
                })}
            </div>
            </>
        )
    }

    renderErrorsList = (data, header, type = "error") => {
        return (
            <>
                <p className={"error-header"}>{header}</p>
                <ErrorsList 
                    type={type}
                    data={data}
                    className="confirm-success-errorList"
                /> 
            </>
        )
    }

    render() {
        const { successData, closeDialog, onConfirm, confirmText, header, successMessage, loading } = this.props;

        return (
            <SubtitleDialog
                onDialogClose={closeDialog}
                onConfirm={() => {
                    const successIds = (successData || []).map(s => {
                        return s.id;
                    });
                    closeDialog();
                    onConfirm && onConfirm(successIds);
                }}
                title={header}
                subtitle={successMessage}
                confirmButtonText={confirmText}
                confirmDisabled={!successData?.length}
                loading={loading}
            >
                {this.renderContent()}
            </SubtitleDialog>
        )
    }
}

export default ConfirmSuccessDialog;