import React from "react";
import "./ModeToggle.css";

const ModeToggle = props => {
  const { modes, selected, setMode } = props;
  return (
    <div className="insights-mode-toggle">
      {modes.map((mode, i) => (
        <div onClick={() => setMode(mode.key)} key={i} className={`mode ${selected == mode.key && "active"}`}>
          {mode.title}
        </div>
      ))}
    </div>
  );
};

export default ModeToggle;
