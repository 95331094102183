import React from 'react';
import ListCell from "./../ListCell";
import Radio from '@mui/material/Radio';

class RadioButtonCell extends React.Component {
	static defaultProps = {
		checked: false,
		name: "radioButtonCell",
		value: "radioButtonValue",
		onCheck: () => {},
		onEdited: () => {},
		disabled: false
	};


	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}


	handleChange(e, checked) {
		this.props.onEdited(e.target.value, checked);
	}


	render() {
		return (
			<ListCell
				width={this.props.width}
				permanentEditMode={true}>
				<div style={{ width: "100%", height: "100%" }}>
					<Radio checked={this.props.checked} onChange={this.handleChange} value={this.props.value} name={this.props.name} disabled={this.props.disabled} />
				</div>
			</ListCell>
		);
	}
}

export default RadioButtonCell;
