import React from 'react';
import { SettingsContext } from './../SettingsContext';
import withStyles from '@mui/styles/withStyles';
import cn from 'classnames';
import { format, isValid } from "date-fns";
import './GroupList.css';
import TaimerAvatar from '../general/TaimerAvatar';
import TaimerComponent from '../TaimerComponent';
import moment from 'moment';
import { renderWithEmoji, filterByText } from '../helpers';
import { FilledInput, Badge } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddCircle from '@mui/icons-material/AddCircle';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import DataHandler from '../general/DataHandler';
import _ from 'lodash';
import colors from '../colors';

const styles = theme => ({
    searchInput: {
        padding: '10px 18px 10px 25px'
    },
    searchIcon: {
        width: '18px',
        height: '18px',
    },
    icon: {
        width: '18px',
        height: '18px',
        color: colors.greenish_cyan,
    },
    barButton: {
        padding: 4
    },
    badgeText: {
        color : '#ffffff'
    }
});

class GroupListItem extends React.PureComponent {
    static contextType = SettingsContext;

    onClick = () => {
        this.props.onSelect(this.props.item);
    }

    render() {
        const { classes, selectedId, item: {
            id, customer, project, parent, name, avatars,
            last_users_id, last_user, last_color, last_timestamp, last_message, unseen }, hideBadge } = this.props;
        const { userObject: { dateFormat } } = this.context;

        const avatarsToRender = !hideBadge && avatars ? _.orderBy(avatars, ['has_avatar', 'user'], ['desc', 'asc']).slice(0, 4) : [];
        const members = avatars && avatars.length - 4;

        let i = 0;
        
        const avatarParam = '&_auth=' + this.context.functions.getStorage().taimerToken;

        return (
            <li className={cn(selectedId === id && "SelectedGroup")} onClick={this.onClick}>
                {!hideBadge && <div>
                    <div className={`GroupAvatar GroupAvatar_${avatarsToRender.length}`}>
                        {avatarsToRender.map( u => 
                            u.has_avatar ? <img alt={u.name} title={u.name} className={`GroupAvatarImage${i++}`} key={u.user} src={this.context.functions.getDownloadPath() + `attachment=user_profile&id=${u.user}${avatarParam}`} />
                                : <TaimerAvatar size="medium" className={`GroupAvatarText GroupAvatarText${i++}`} key={u.user} name={u.name} color={u.color} noavatar />
                        )}
                        {members > 0 && <div className="GroupAvatarMore"><div>+{members}</div></div>}
                    </div>
                </div>}
                <div className={cn("TeamChatGroupName", hideBadge && "withoutAvatar")}>
                    {false && <div className="parentName">{customer} {parent}</div>}
                    <div className="projectName">{project || name}</div>
                    {customer && <div>{customer}</div>}
                    {last_user && <div className="userName">{last_user}</div>}
                    <div className="messagePreview">{last_message && renderWithEmoji(last_message, 8, false)}</div>
                </div>
                <div className="TeamChatNewIndicatorArea">
                    {unseen > 0 && <div className="TeamChatNewIndicator" />}
                </div>
                <div className="TeamChatLastTime">
                    {last_timestamp && format(last_timestamp, dateFormat)}<br />
                    {last_timestamp && moment.utc(last_timestamp).local().format('LT')}
                </div>
            </li>
        );
    }
}

class GroupList extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "team-chat/GroupList");

        this.state = {
            searchValue: '',
        }
    }

    open = (e) => {
        this.setState({open: true});
    }

    close = () => {
        this.setState({open: false});
    }

    select = (item) => {
        const { onSelect } = this.props;

        onSelect && onSelect(item);

        this.close();
    }

    inputChange = (e) => {
        this.setState({searchValue: e.target.value});
    }

    createGroup = async () => {
        const { searchValue } = this.state;

        try {
            this.props.onCreate(searchValue);
            this.setState({searchValue: ''});
        } catch (error) {
            
        }
    }

    onKeyPress = (e) => {
        const { searchValue } = this.state;
        const { type } = this.props;

        switch (e.key)
		{
            case 'Enter':
                if (type !== "groups" || searchValue === '')
                    return;

                this.createGroup();
                break;
        }
    }

    render() {
        const { type, classes, selectedId, className, items = [], onSelect, hideBadges = false } = this.props;
        const { searchValue } = this.state;

        const show = !searchValue ? items : filterByText(items, searchValue);

        const endAdornment = (
            <InputAdornment position="end">
                {type === "groups" && searchValue ? <IconButton
                    classes={{root: classes.barButton}}
                    onClick={this.createGroup}
                    size="large">
                    <AddCircle classes={{root: classes.icon}} />
                </IconButton> : <Search classes={{root: classes.searchIcon}} />}
            </InputAdornment>);

        return (
            <div className={cn("TeamChatGroupList", className)}>
                <div className="TeamChatGroupListSearch">
                    <FilledInput
                        placeholder={type === "groups" ? this.tr("Search or start a new chat") : this.tr("Search")}
                        value={searchValue}
                        onChange={this.inputChange}
                        fullWidth classes={{input: classes.searchInput}}
                        endAdornment={endAdornment}
                        onKeyPress={this.onKeyPress} />
                </div>
                <ul>
                    {show.map(item => <GroupListItem hideBadge={hideBadges} classes={classes} key={item.id} selectedId={selectedId} onSelect={onSelect} item={item} />)}
                </ul>
            </div>
        );
    }
}

export default withStyles(styles)(GroupList);