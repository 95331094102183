import React from "react";

import SettingRow from "./SettingRow";
import ListCell from "../ListCell";
import TextInputCell from "../cells/TextInputCell";

import TrashCanIcon from "@mui/icons-material/Delete";

class SimpleSettingRow extends SettingRow {
	constructor(props) {
		super(props);
	}


    render() {
        return (
            <div className="listElement row" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>
                <ListCell 
                    className="noBg pointer" 
                    permanentEditMode={true} 
                    width={this.props.columnWidthMap['delete']}>
                    <TrashCanIcon onClick={() => this.delete()} />
                </ListCell>
                <TextInputCell 
                    validation={["empty"]} 
                    onEdited={this.editAndSave} 
                    name="name" 
                    width={this.props.columnWidthMap['name']} 
                    value={this.state.data['name']} 
                    listCellProps={{ inEditMode: this.state.data.id < 0 }} 
                    focusOnMount={true}  />
            </div>
        );
    }
}

export default SimpleSettingRow;
