import { format } from 'date-fns';
import getAccountColumns from './AccountColumns';
import getColumnType, { CalendarHeaderTemplate, ColumnDefinition, columnSorter } from './ColumnTypes';
import { CustomFields } from './CustomFields';
import getProjectColumns from './ProjectColumns';
import { filterColumns } from "./ColumnVisibility";

const columnOrder = [

];

interface Params {
    customFields: CustomFields;
    tr: (text, replacers?: Record<string, string>) => string;
    startDate: Date | null;
    endDate: Date | null;
    dateFormat: string;
    settingsContext: any;
}

export default function getProjectDataColumns(params: Params) {

    const calendarHeader = {
        tr: params.tr,
        startDate: params.startDate ? format(params.startDate, params.dateFormat) : null,
        endDate: params.endDate ? format(params.endDate, params.dateFormat) : null,
    }

    const columns: ColumnDefinition[] = [                
        ...getProjectColumns(params),
        {
            field: 'project_company',
            headerName: 'Project company',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_invoicing_status',
            headerName: 'Invoicing status',
            ...getColumnType('variable', params.tr),
            defaultAggFunc: 'sumAndGroup',
            cellRendererParams: {
                displayDatas:  [
                    {id: 1, "name": "Billable"},
                    {id: 2, "name": "Non-billable"},
                    {id: 3, "name": "Vacation/Leave"},
                ],
                tr: params.tr
            } 
        },
        {
            field: 'project_quote_status',
            headerName: 'Project quote status',
            ...getColumnType('status', params.tr),
            cellRendererParams: {
                displayDatas:  [
                    { id: 1, name: 'Draft', color: '#6B7897' },
                    { id: 2, name: 'sent', color: '#2D9FF7' },
                    { id: 3, name: 'Review', color: '#F5A623' },
                    { id: 4, name: 'approved', color: '#28A589' },
                    { id: 5, name: 'declined', color: '#F7548F' },
                    { id: -1, name: 'Archived', color: '#716aca' },
                ],
                displayArrayAsTextValues: true,
                tr: params.tr
            },
        },
        {
            field: 'project_status_changed',
            headerName: 'Project status changed',
            ...getColumnType('date', null, 'project_status_changed'),
        },
        {
            field: 'project_project_created',
            headerName: 'Project created',
            ...getColumnType('date', null, 'project_project_created'),
        },
        {
            field: 'project_startdate',
            headerName: 'Project start date',
            ...getColumnType('date', null, 'project_startdate'),
        },
        {
            field: 'project_enddate',
            headerName: 'Project end date',
            ...getColumnType('date', null, 'project_enddate'),
        },
        {
            field: 'project_event_startdate',
            headerName: 'Project event start date',
            ...getColumnType('date', null, 'project_event_startdate'),
        },
        {
            field: 'project_event_enddate',
            headerName: 'Project event end date',
            ...getColumnType('date', null, 'project_event_enddate'),
        },
        {
            field: 'project_pipeline_date',
            headerName: 'Funnel last changed',
            ...getColumnType('date', null, 'project_pipeline_date'),
        },
        {
            field: 'project_stage_date',
            headerName: 'Stage last changed',
            ...getColumnType('date', null, 'project_stage_date'),
        },
        {
            field: 'project_stage_probability',
            headerName: 'Stage probability',
            ...getColumnType('percentage'),
            defaultAggFunc: 'avg',
            sumAndGroupType: 'avg'
        },
        {
            field: 'project_closing_date',
            headerName: 'Closing date',
            ...getColumnType('date', null, 'project_closing_date'),
        },
        ...getAccountColumns(params),
        {
            field: 'hours_tracked',
            headerName: 'Tracked Hours',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'hours_approved',
            headerName: 'Approved Hours',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'hours_submitted',
            headerName: 'Submitted Hours',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'hours_billable',
            headerName: 'Billable Hours',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'hours_non_billable',
            headerName: 'Non Billable Hours',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'hours_invoiced',
            headerName: 'Invoiced Hours Amount',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'hours_invoiced_price',
            headerName: 'Invoiced Hours Price',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'hours_cost',
            headerName: 'Internal cost of hours',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },

        {
            field: 'resourcing_allocated',
            headerName: 'Allocated',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'resourcing_resourced',
            headerName: 'Resourced',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('hour'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
            postfix: 'h',
        },
        {
            field: 'bill_bills',
            headerName: 'Bills',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'bill_bills_waiting',
            headerName: 'Waiting bills',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'bill_bills_invoiced',
            headerName: 'Invoiced bills',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'bill_purchase_orders',
            headerName: 'Purchase orders',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_expenses',
            headerName: 'Expenses',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_expenses_waiting',
            headerName: 'Waiting expenses',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_expenses_invoiced',
            headerName: 'Invoiced expenses',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_travel',
            headerName: 'Travel expenses',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_travel_waiting',
            headerName: 'Waiting travel expenses',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_travel_invoiced',
            headerName: 'Invoiced travel expenses',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_added_costs',
            headerName: 'Added costs',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'expense_added_costs_invoiced',
            headerName: 'Invoiced added costs',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'costestimate_project_value',
            headerName: 'Project value',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'costestimate_budgeted_gross_margin',
            headerName: 'Budgeted gross margin',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'costestimate_budgeted_sales_margin',
            headerName: 'Budgeted sales margin',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'invoice_invoiced',
            headerName: 'Invoiced',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'invoice_drafts_and_waiting',
            headerName: 'Drafts and waiting invoices',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'probable_value',
            headerName: 'Probable value',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'probable_gross_margin',
            headerName: 'Probable budgeted gross margin',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'probable_sales_margin',
            headerName: 'Probable budgeted sales margin',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'gross_margin_invoicing',
            headerName: 'Gross margin (invoicing)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'gross_margin_invoicing_percentage',
            headerName: 'Gross margin % (invoicing)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'gross_margin_invoicing', divisor: 'invoice_invoiced' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum' // So shows formatted value correctly in grouped row. By default percentage has "grouped" and the the value is not formatted.
        },
        {
            field: 'gross_margin_invoicing_per_hour',
            headerName: 'Gross margin (invoicing) / hour',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            defaultAggFunc: 'avg',
            sumAndGroupType: 'avg'
        },
        {
            field: 'sales_margin_invoicing',
            headerName: 'Sales margin (invoicing)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'sales_margin_invoicing_percentage',
            headerName: 'Sales margin % (invoicing)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'sales_margin_invoicing', divisor: 'invoice_invoiced' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum' // So shows formatted value correctly in grouped row. By default percentage has "grouped" and the the value is not formatted.
        },
        {
            field: 'sales_margin_invoicing_per_hour',
            headerName: 'Sales margin (invoicing) / hour',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            defaultAggFunc: 'avg',
            sumAndGroupType: 'avg'
        },
        {
            field: 'project_backlog',
            headerName: 'Backlog',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'gross_margin_revenue',
            headerName: 'Gross margin (revenue recognition)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'gross_margin_revenue_percentage',
            headerName: 'Gross margin % (revenue recognition)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'gross_margin_revenue', divisor: 'revenue_recognition_recognised' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum' // So shows formatted value correctly in grouped row. By default percentage has "grouped" and the the value is not formatted.
        },
        {
            field: 'gross_margin_revenue_per_hour',
            headerName: 'Gross margin (revenue recognition) / hour',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            defaultAggFunc: 'avg',
            sumAndGroupType: 'avg'
        },
        {
            field: 'sales_margin_revenue',
            headerName: 'Sales margin (revenue recognition)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'sales_margin_revenue_percentage',
            headerName: 'Sales margin % (revenue recognition)',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'sales_margin_revenue', divisor: 'revenue_recognition_recognised' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum' // So shows formatted value correctly in grouped row. By default percentage has "grouped" and the the value is not formatted.
        },
        {
            field: 'sales_margin_revenue_per_hour',
            headerName: 'Sales margin (revenue recognition) / hour',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            defaultAggFunc: 'avg',
            sumAndGroupType: 'avg'
        },
        {
            field: 'material_total',
            headerName: 'Invoiceable Material',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'revenue_recognition_recognised',
            headerName: 'Recognised',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'revenue_recognition_estimated_costs',
            headerName: 'Estimated costs',
            headerComponentParams: {
                template: CalendarHeaderTemplate(calendarHeader),
            },
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
    ];

    columns.sort(columnSorter(columnOrder));
    
    return filterColumns(
        columns, 
        "projects", 
        params.settingsContext
    );
}
