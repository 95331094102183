import _, { cloneDeep } from 'lodash';
import moment from 'moment';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import CustomFields from '../projects/customfields/CustomFields';
import { clearAutoCompleteCache } from '../resourcing/helpers';
import TaimerComponent from '../TaimerComponent';
import DataHandler from './DataHandler';
import EntityCreatedSnackbar from './EntityCreatedSnackbar';
import FieldEditSlider, { EditableField, FieldEditSliderProps } from './FieldEditSlider';
import { AddAccount } from './no-options/AddItemComponents';
import AddProjectType from './no-options/AddProjectType';
import AddTag from './no-options/AddTag';
import {
    createOptions,
    hasSubvalue
} from './TreeSelect';
import VersionContentManager from './VersionContentManager';

interface Props extends WithSnackbarProps, FieldEditSliderProps {
    initialSelectionProps?: any;
    stayInCurrentView?: boolean;
    onProjectCreated?: (project) => void;
}

interface State {
    project: any;
    open: boolean;
    currency: string;
    accounts: any[];
    companies: any[];
    pipelines: any[];
    stages: any[];
    types: any[];
    tags: any[];
    users: any[];
    salesUsers: any[];
    branchesOfBusiness: any[];
    reportingGroups: any[];
    customershipGroups: any[];
    teams: any[];
    customFields: any[];
    customFieldsErrors: any;
    wonReasons: any[];
    defaultCategory: object;
    saving?: boolean;
    onlyOneProjectType?: boolean;
    projectTypeError?: boolean;
    projectNumberExists?: boolean;
    use_events?: boolean;
    currencies: any[];
}

class AddProjectSlider extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'general/AddProjectSlider');

        this.state = {
            project: {
                status_users_id: this.context.userObject.usersId,
                users_id: this.context.userObject.usersId,
                closing_date: moment().add(30, 'days').format('YYYY-MM-DD'),
                startdate: moment().format('YYYY-MM-DD'),
                enddate: moment().add(30, 'days').format('YYYY-MM-DD'),
                ...this.props.initialSelectionProps,
                companies_id: this.props.initialSelectionProps?.companies_id || this.context.userObject.companies_id,
            },
            accounts: [],
            companies: [],
            pipelines: [],
            stages: [],
            teams: [],
            types: [],
            tags: [],
            branchesOfBusiness: [],
            reportingGroups: [],
            customershipGroups: [],
            users: [],
            salesUsers: [],
            currency: 'EUR',
            open: false,
            customFields: [],
            customFieldsErrors: {},
            wonReasons: [],
            defaultCategory: {},
            use_events: false,
            currencies: []
        };
    }

    componentDidMount = () => {
        if (this.props.open) {
            this.setState({ open: true });
        }
    };

    componentDidUpdate = (_, oldState) => {
        if (oldState.open != this.state.open && this.state.open) {
            this.getCompanies();
            this.getAutoCompleteData();
            this.getTypes();
            this.getOneProjectTypeSetting();
            this.getTags();
            this.getCustomFields();
            this.getStatusReasons();
            this.getDefaultCategory();
            this.getReportingGroups();
            this.getCustomershipGroups();
            this.getCurrencies();
        }
        if (oldState.project.companies_id != this.state.project.companies_id) {
            this.getAutoCompleteData();
            this.getTypes();
            this.getOneProjectTypeSetting();
            this.getTags();
            this.getCustomFields();
            this.getStatusReasons();
            this.getDefaultCategory();
            this.getReportingGroups();
            this.getCustomershipGroups();
            this.getCurrencies();
        }
    };

    getReportingGroups = async (item = this.state.project) => {
        const reportingGroups = await DataHandler.get({ url: `projects/reporting_groups/${item.companies_id}`, account: item.customers_id ? item.customers_id : '' });
        const defaultReportingGroup = reportingGroups.find((rg) => rg.is_default == 1);
        let project = this.state.project;
        if (defaultReportingGroup) {
            project = {
                ...project,
                product_structures_id: defaultReportingGroup.id,
            };
        }
        this.setState({ reportingGroups, project });
    };

    getCustomershipGroups = async () => {
        const { project } = this.state;
        const customershipGroups = await DataHandler.get({ url: `subjects/customership_groups/${project.companies_id}` });
        this.setState({ customershipGroups });
    };

    getCurrencies = async () => {
        const { project } = this.state;
        let currencies = await DataHandler.get({ url: `invoices/currency_rates`, company: project.companies_id });
        const today = moment().format("YYYY-MM-DD");
        currencies = currencies.filter(el => (el.id > 0 && el.active_status == 'active' && el.valid_from <= today)).map(el =>  {
            return {id: el.id, label: el.currency_label, name: el.currency_label, currency_rate: el.currency_rate };
        });
        this.setState({ currencies });
    };

    getStatusReasons = async () => {
        const { project } = this.state;
        const { statusReasons } = await DataHandler.get({ url: `settings/company/${project.companies_id}/project/status_reasons` });
        this.setState({ wonReasons: (statusReasons?.won || []).map((sr) => ({ ...sr, label: sr.name, value: sr.id })) });
    };

    getDefaultCategory = async () => {
        const { project } = this.state;
        const { defaultCategory } = await DataHandler.get({ url: `settings/company/${project.companies_id}/project/default_category` });

        const newProject = {
            ...this.state.project,
            branchofbusiness_id: defaultCategory?.id,
        };
        this.setState({ defaultCategory, project: newProject });
    };

    projectWonReasonIsInUse = (company = this.state.project.companies_id) => {
        return this.context.userObject.project_read_companies.findIndex((c) => c.id == company && c.use_project_won_reason == 1) != -1;
    };

    getOneProjectTypeSetting = async () => {
        const { project } = this.state;
        const response = await DataHandler.get({ url: `settings/company/${project.companies_id}/project/oneprojecttype` });
        this.setState({ onlyOneProjectType: response.oneprojecttype });
    };

    getTypes = async () => {
        const { project } = this.state;
        const types = await DataHandler.get({ url: `subjects/project_types/${project.companies_id}` });
        const projectTypes = project.types || [];
        if (projectTypes.length == 0) {
            const defaultType = (types || []).find((t) => t.is_default == 1);
            if (defaultType) {
                projectTypes.push({ ...defaultType, value: defaultType.id });
            }
        }
        const newProject = {
            ...this.state.project,
            types: projectTypes,
        };
        this.setState({ types, project: newProject });
    };

    getTags = async () => {
        const { project } = this.state;
        const { tags } = await DataHandler.get({ url: `tags/${project.companies_id}`, type: 'project' });
        this.setState({ tags });
    };

    getCompanies = async () => {
        const companies = await DataHandler.get({ url: `subjects/companies/projects/read+write`, print_lang: 1, currency: 1, print_options: 1 });
        const currency = companies.find((c) => c.id == this.state.project.companies_id)?.currency || this.state.currency;
        const companies_id = (companies.find((c) => c.id == this.state.project.companies_id) || companies[0])?.id;
        this.setState({
            project: {
                ...this.state.project,
                companies_id,
            },
            companies: companies.map((c) => ({ ...c, value: c.id, label: c.name })),
            currency,
        });
    };

    getStagesForPipeline = (stages, pipelineId, companyId) => {
        let stagesForPipeline: any = [];
        if (Number(pipelineId) < 0) {
            stagesForPipeline = stages.filter((s) => s.companies_id == companyId && s.project_type == Math.abs(Number(pipelineId)));
        } else {
            stagesForPipeline = stages.filter((s) => s.companies_id == companyId && s.projects_pipelines_id == Math.abs(Number(pipelineId)));
        }

        return stagesForPipeline;
    };

    getAutoCompleteData = async () => {
        const { project } = this.state;
        const responses = await Promise.all([
            DataHandler.get({ url: `settings/company/${project.companies_id}/defaultPipeline` }),
            DataHandler.get({ url: `projects/autoCompleteData/${project.companies_id}`, frTransl: this.tr('Freelancer'), no_dynamic_rights: 1 }),
            DataHandler.get({ url: `subjects/dimensions/teams/${project.companies_id}` }),
            DataHandler.get({ url: `settings/company/${project.companies_id}/use_events` })
        ]);
        const defaultPipeline = VersionContentManager.isFeatureHidden(this.namespace, 'pipelines') ? '-1' : (responses[0] || {}).default_projects_pipelines_id;
        const data = responses[1] || {};
        const teams = responses[2] || [];
        const companyEventSettings = responses[3] || [];
        const accounts = (data.customers || []).filter((c) => c.allow_project_creation == 1).map((a) => ({ ...a, label: a.name }));
        const pipelines = [
            ...(data.pipelines || []).map((pl) => ({ ...pl, label: pl.name })),
            { id: '-1', value: '-1', name: this.tr('Won deals'), label: this.tr('Won deals') },
            { id: '-5', value: '-5', name: this.tr('Internal projects'), label: this.tr('Internal projects') },
        ];
        const projects_pipelines_id = (pipelines.find((pl) => pl.id == project.projects_pipelines_id || pl.id == defaultPipeline) || pipelines[0])?.id;
        const stages = (data.sales_states || []).map((s) => ({ ...s, label: s.name }));
        const branchesOfBusiness = (data.branch_of_business || []).map((bob) => ({ ...bob, label: bob.name }));
        const stagesForPipeline = this.getStagesForPipeline(stages, projects_pipelines_id, project.companies_id);
        const projects_sales_states_id = (stagesForPipeline.find((s) => s.id == project.projects_sales_states_id) || stagesForPipeline[0])?.id;
        const salesUsers = data.privileged_employees.map((u: any) => ({ ...u, label: u.name }));
        const users = data.employees.map((u: any) => ({ ...u, label: u.name }));
        
        let status = '0';
        if (projects_pipelines_id == '-1') status = '1';
        if (projects_pipelines_id == '-5') status = '5';

        const acc = accounts.find(x => x.id == project.customers_id);

        const newProject = {
            ...this.state.project,
            status,
            projects_pipelines_id,
            projects_sales_states_id,
            customership_groups_id: acc?.customership_groups_id && !project.customership_groups_id ? acc.customership_groups_id : project.customership_groups_id,
        };
        this.setState({ accounts, pipelines, branchesOfBusiness, stages, teams, users, salesUsers, project: newProject, use_events: !!+companyEventSettings.use_events });
    };

    getCustomFields = async () => {
        const { project } = this.state;
        const customFields = await DataHandler.get({ url: `settings/company/${project.companies_id}/project/customfields`, include_deleted: 0 });
        this.setState({ customFields });
    };

    onClose = () => {
        this.setState({ open: false }, () => {
            setTimeout(() => {
                this.props.onClose && this.props.onClose();
            }, 500);
        });
    };

    checkCustomFieldsValidity = () => {
        const customFieldsErrors = {};
        const { project, stages, customFields } = this.state;
        const { projects_sales_states_id, projects_pipelines_id, status } = project;
        const custom = this.state.project.custom;
        const currentSalesState = stages.find((x) => x.id == projects_sales_states_id);
        if (currentSalesState) {
            const statesToValidate = currentSalesState.stateorder > 1 ? stages.filter((x) => x.stateorder <= parseInt(currentSalesState.stateorder)).map((x) => parseInt(x.id)) : [];
            const pipelines = [0, Number(projects_pipelines_id) > 0 ? projects_pipelines_id : Number(status) * -1];
            const formFields = customFields.filter(
                (x) => (statesToValidate.indexOf(x.projects_sales_states_id) > -1 || x.projects_sales_states_id == 0) && pipelines.indexOf(x.projects_pipelines_id) > -1
            );
            _.forEach(formFields, (v) => {
                const value = (custom && custom[v.id]) || '';
                if (v.required && v.show_in_details && value === '') {
                    customFieldsErrors[v.id] = true;
                }
            });
        }
        this.setState({ customFieldsErrors });
        return Object.values(customFieldsErrors).indexOf(true) == -1;
    };

    onSave = async (project) => {
        this.setState({ saving: true }, async () => {
            const { enqueueSnackbar, closeSnackbar } = this.props;
            const { addons } = this.context;
            const creatingSnackbar: any = enqueueSnackbar(this.tr('Creating project...'), {
                variant: 'info',
                persist: true,
            });
            const data = {
                ...project,
                tags: (project.tags || []).map((t) => t.label),
                team_members: ["u_" + project.status_users_id],
            };
            if (!this.props.onProjectCreated) this.onClose();
            try {
                const response = await DataHandler.post({ url: `projects` }, data);
                closeSnackbar(creatingSnackbar);
                const viewProps = this.context.functions.getViewProps();
                const moveToProjectCard =
                    (!this.props.stayInCurrentView && viewProps.module == 'projects' && viewProps.action == 'list') || (viewProps.module == 'projects' && viewProps.action == 'view');
                const id = response.project_id;
                const invoicingSettings = response.invoicing_settings || {};
                const createdProject = {
                    ...data,
                    customers_name: this.state.accounts.find((a) => a.id == data.customers_id)?.label,
                    id: response.id,
                    project_id: data.project_id || response.project_id,
                    ...invoicingSettings
                };
                this.context.functions.sendMixpanelEvent('create_project', {
                    'origin_point': this.props.initialSelectionProps?.origin_point,
                });
                this.context.functions.sendMixpanelPeople('set_once', {
                    'first_create_project_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('set', {
                    'last_create_project_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('increment', {
                    'lifetime_create_project': 1,
                });
                if (this.props.onProjectCreated) {
                    this.props.onProjectCreated(createdProject);
                    this.onClose();
                    return;
                }
                const createdSnackbar: any = enqueueSnackbar(
                    <EntityCreatedSnackbar
                        type="project"
                        id={id}
                        onClose={() => closeSnackbar(createdSnackbar)}
                        actions={[
                            {
                                key: 'view_project',
                                onClick: () => {
                                    this.context.functions.updateView({ module: 'projects', action: 'view', id: createdProject.id });
                                },
                                isHidden: () => !this.context.functions.hasPrivilege('projects', 'read'),
                                label: 'View project',
                            },
                            {
                                key: 'activity',
                                onClick: () => {
                                    this.context.functions.openActivitySlider({
                                        customers_id: createdProject.customers_id,
                                        projects_id: createdProject.id,
                                    });
                                },
                                isHidden: () => !this.context.userObject.hasCrmWritePermission,
                                label: 'Add activity',
                            },
                            {
                                key: 'task',
                                onClick: () => {
                                    this.context.functions.addResource({
                                        projects_id: createdProject.id,
                                        origin_point: "add_project_slider",
                                    });
                                },
                                isHidden: () =>
                                    !(this.context.functions.hasPrivilege('projects', 'project_resourcing_write') || this.context.functions.hasPrivilege('projects', 'own_resourcing_write')),
                                label: 'Add task',
                            },
                            {
                                key: 'contact',
                                onClick: () => {
                                    this.context.functions.addContact({
                                        customers_id: createdProject.customers_id,
                                        projects: [
                                            {
                                                name: `${createdProject.name} (${createdProject.project_id})`,
                                                label: `${createdProject.name} (${createdProject.project_id})`,
                                                id: createdProject.id,
                                                value: createdProject.id,
                                            },
                                        ],
                                    });
                                },
                                isHidden: () => !this.context.functions.hasPrivilege('persons', 'read'),
                                label: 'Add contact',
                            },
                            {
                                key: 'hours',
                                onClick: () => {
                                    this.context.functions.addHours({
                                        customer: {
                                            id: createdProject.customers_id,
                                        },
                                        unit: {
                                            id: 0,
                                        },
                                        project: {
                                            name: createdProject.name,
                                            label: createdProject.name,
                                            id: createdProject.id,
                                            value: createdProject.id,
                                        },
                                    });
                                },
                                isHidden: () => !this.context.functions.hasPrivilege('workhours', 'write'),
                                label: 'Add hour entry',
                            },
                        ]}
                    />,
                    {
                        variant: 'default',
                        autoHideDuration: 5000,
                        className: 'entityCreatedSnackbar',
                    }
                );
                clearAutoCompleteCache(project.companies_id);
                if (moveToProjectCard) {
                    this.context.functions.updateView({ module: 'projects', action: 'view', id: response.id });
                }
                setTimeout(() => {
                    window.dispatchEvent(new Event('projectCreated'));
                }, 1000);
            } catch (e) {
                console.error(e);
                const errorResponse: any = e;
                let errorDescription = this.tr('Creating project failed!');
                switch (errorResponse.responseJSON?.error) {
                    case 'EXISTING_PROJECT_ID':
                        errorDescription = ` ${this.tr('The given project number already exists. Please choose another project number.')}.`;
                        break;
                    case 'CUSTOMER_NOT_ALLOWED':
                        errorDescription = ` ${this.tr('Cannot create project for this customer')}.`;
                        break;
                    default:
                        break;
                }
                this.setState({ saving: false });
                closeSnackbar(creatingSnackbar);
                enqueueSnackbar(errorDescription, {
                    variant: 'error',
                });
            }
        });
    };

    onItemChanged = (project) => {
        let update: any = { project };
        if (this.state.onlyOneProjectType) {
            update = {
                ...update,
                projectTypeError: (project.types || []).length > 1,
            };
        }
        this.setState(update);
    };

    checkProjectIDUniqueness = async () => {
        const { project } = this.state;
        const projectNumberExists = await DataHandler.get({ url: `projects/check_id_existence/${project.project_id}` });
        this.setState({ projectNumberExists });
    };

    showQuoteCurrencies = () => {
        const { functions: { isProjectReadCompanyUsingQuoteCurrencies } } = this.context;
        const { project } = this.state;
        return isProjectReadCompanyUsingQuoteCurrencies(project.companies_id);
    }

    getFields = () => {
        const { project, accounts, teams, users, salesUsers, types, tags, branchesOfBusiness, pipelines,
            stages, companies, currency, wonReasons, projectNumberExists, reportingGroups, customershipGroups, currencies } =
            this.state;
        const {
            addons,
            taimerAccount: { mandatory_team_selection_for_projects, force_teams_branchofbusiness_for_projects, isMulticompany },
            functions: { checkPrivilege, companyUsesAddOn },
        } = this.context;
        const filteredBranchesOfBusiness = branchesOfBusiness.filter((x) => x.deleted != 1 || hasSubvalue(x, project.branchofbusiness_id));
        const branchOfBusinessOptions = createOptions(filteredBranchesOfBusiness, "id", "parent_id");
        const useDimensions = addons?.dimensions?.used_by_companies?.indexOf(project.companies_id) != -1;

        const fields: EditableField[] = [
            {
                key: 'companies_id',
                title: this.tr('Company'),
                type: 'data_select',
                options: companies,
                isHidden: () => !isMulticompany,
                required: true,
                disabled: !!project.parentid,
                afterEdit: (item) => {
                    const company = companies.find(x => x.id == item.companies_id);

                    if (company) {
                        this.setState({
                            currency: company.currency,
                            project: {
                                ...this.state.project,
                                currency_id: 0,
                                currency_rate: '1.000000'
                            },
                        });
                    }
                },
            },
            {
                key: 'customers_id',
                title: this.tr('Account'),
                type: 'data_select',
                options: accounts,
                required: true,
                noOptions: AddAccount,
                onItemCreated: (account) => {
                    const accounts = cloneDeep(this.state.accounts);
                    accounts.unshift(account);
                    this.setState({
                        accounts,
                        project: {
                            ...this.state.project,
                            customers_id: account.id,
                        },
                    });
                },
                setOtherValuesWithSelection: (item, value) => {
                    const acc = accounts.find(x => x.id == value);

                    return {
                        customership_groups_id: acc.customership_groups_id ? acc.customership_groups_id : item.customership_groups_id,                        
                    };
                },
                afterEdit: (item) => {
                    this.getReportingGroups(item);
                },
                additionalProps: {
                    companies_id: project.companies_id,
                    noOptionsHidden: companyUsesAddOn('mediapro', project.companies_id),
                },
            },
            ...(project.parentid
                ? [
                      {
                          key: 'parentname',
                          title: this.tr('Parent project'),
                          disabled: true,
                      },
                  ]
                : []),
            {
                key: 'name',
                required: true,
                title: this.tr('Name'),
            },
            {
                key: 'project_id',
                title: this.tr('Project number'),
                required: addons.custom_project_id && !addons.corporate_automatic_project_numbering,
                isHidden: () => !addons.custom_project_id || !!addons.corporate_automatic_project_numbering,
                error: projectNumberExists,
                errorMessage: projectNumberExists && this.tr('The given project number already exists.'),
                afterEdit: () => {
                    this.checkProjectIDUniqueness();
                },
            },
            {
                key: 'dimension_teams_id',
                title: this.tr('Team'),
                type: 'data_select',
                isHidden: () => !useDimensions,
                hideIfEmpty: true,
                options: teams,
                required: useDimensions && mandatory_team_selection_for_projects,
                setOtherValuesWithSelection: (item, value) => {
                    const branchofbusiness_id = force_teams_branchofbusiness_for_projects ? teams.find((t) => t.id === value)?.category : this.state.project.branchofbusiness_id;
                    return { branchofbusiness_id };
                },
            },
            {
                key: 'types',
                title: this.tr('Project type'),
                type: 'select',
                isMulti: true,
                options: types.map((t) => ({ ...t, value: t.id })),
                noOptions: AddProjectType,
                error: this.state.projectTypeError == true,
                errorMessage: this.state.projectTypeError && this.tr('Only one project type is allowed.'),
                additionalProps: {
                    company: project.companies_id,
                },
            },
            {
                key: 'branchofbusiness_id',
                title: this.tr('Category'),
                type: 'treeselect',
                options: branchOfBusinessOptions,
                required: filteredBranchesOfBusiness.length > 0,
                isHidden: () => filteredBranchesOfBusiness.length == 0,
                disabled: useDimensions && force_teams_branchofbusiness_for_projects && project.dimension_teams_id && project.branchofbusiness_id,
                additionalProps: {
                    customItemLock: (item) => {
                        return item.deleted == 1 || item.locked == 1;
                    },
                    hideOptions: (options, value) => {
                        function filter(options, value) {
                            const newOptions: any = [];
                            for (const o of options.filter((f) => f.locked != 1 || (f.locked == 1 && f.id == value))) {
                                o.children = filter(o.children, value);
                                newOptions.push(o);
                            }
                            return newOptions;
                        }
                        const o = filter(options, value);
                        return o;
                    },
                },
            },
            {
                key: 'product_structures_id',
                title: this.tr('Reporting group'),
                type: 'treeselect',
                options: reportingGroups.map((c) => ({ ...c, label: c.name, value: c.id })),
                hideIfEmpty: true,
            },
            {
                key: 'customership_groups_id',
                title: this.tr('Customership group'),
                type: 'data_select',
                options: customershipGroups.filter((g) => g.deleted != 1).map((c) => ({ ...c, label: c.name, value: c.id })),
                addNoneOption: true,
                hideIfEmpty: true,
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'accountGroups'),
                
            },
            {
                key: 'costest_sum',
                title: this.tr('Deal value'),
                isHidden: () => !checkPrivilege('projects', 'project_cost_estimate_read'),
                validation: 'numeric',
                additionalProps: {
                    format: 'currency',
                    currency,
                },
            },
            {
                key: 'costest_margin',
                title: this.tr('Deal margin'),
                isHidden: () => !checkPrivilege('projects', 'project_cost_estimate_read'),
                validation: 'numeric',
                additionalProps: {
                    format: 'currency',
                    currency,
                },
            },
            {
                key: 'currency_id',
                title: this.tr('Deal Currency'),
                type: 'data_select',
                options: currencies,
                addNoneOption: true,
                isHidden: () => !this.showQuoteCurrencies(),
                setOtherValuesWithSelection: (item, value) => {
                    const rate = currencies.find((t) => t.id === value);
                    const currency_rate = rate?.currency_rate;
                    const currency = rate?.label;
                    return { currency_rate, currency };
                },
                afterEdit: (item) => {
                    this.setState({ currency: item.currency });
                }
            },
            {
                key: 'currency_rate',
                title: this.tr('Deal Currency rate'),
                validation: 'numeric',
                isHidden: () => !this.showQuoteCurrencies(),
            },
            {
                key: 'project_timespan',
                startKey: 'startdate',
                endKey: 'enddate',
                title: this.tr('Project duration'),
                type: 'daterange',
                setOtherValuesWithSelection: (item, values) => {
                    return { closing_date: values.enddate };
                },
            },
            ...(this.state.use_events ? [{
                key: 'event_range',
                startKey: 'event_startdate',
                endKey: 'event_enddate',
                title: this.tr('Event dates'),
                type: 'daterange',
            }] : []),
            {
                key: 'closing_date',
                title: this.tr('Closing date'),
                type: 'date',
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'pipelines'),
            },
            {
                key: 'status_users_id',
                title: this.tr('Sales agent'),
                type: 'data_select',
                options: salesUsers,
                required: true,
            },
            {
                key: 'users_id',
                title: this.tr('Project manager'),
                type: 'data_select',
                options: users,
                required: true,
            },
            {
                key: 'projects_pipelines_id',
                title: this.tr('Pipeline'),
                type: 'data_select',
                options: pipelines,
                required: true,
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'pipelines'),
                setOtherValuesWithSelection: (item, value) => {
                    const newStages = this.getStagesForPipeline(stages, value, item.companies_id);
                    let values = {};
                    if (newStages.findIndex((s) => s.id == this.state.project.projects_sales_states_id) == -1) {
                        values = { ...values, projects_sales_states_id: newStages[0]?.id };
                    }
                    let status = '0';
                    if (value == '-1') status = '1';
                    if (value == '-5') status = '5';
                    values = { ...values, status };
                    return values;
                },
            },
            {
                key: 'projects_sales_states_id',
                title: this.tr('Stage'),
                type: 'data_select',
                options: stages,
                getOptions: (item, options) => this.getStagesForPipeline(options, item.projects_pipelines_id, item.companies_id),
                required: true,
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'pipelines'),
            },
            {
                key: 'status_reason_id',
                title: this.tr('Won reason'),
                type: 'data_select',
                options: wonReasons,
                required: this.projectWonReasonIsInUse(project.companies_id) && project.status == 1,
                isHidden: (item) => !this.projectWonReasonIsInUse(item.companies_id) || item.status != 1,
            },
            {
                key: 'status_reason_comment',
                title: this.tr('Won reason comment'),
                type: 'textarea',
                isHidden: (item) => !this.projectWonReasonIsInUse(item.companies_id) || item.status != 1,
            },
            {
                key: 'tags',
                title: this.tr('Tags'),
                type: 'select',
                isMulti: true,
                options: tags.map((t) => ({ ...t, value: t.id })),
                noOptions: AddTag,
                additionalProps: {
                    company: project.companies_id,
                    tagType: 2,
                },
            },
        ];

        return fields;
    };

    getCustomFieldError = (fieldKey) => {
        const customFieldsErrors = cloneDeep(this.state.customFieldsErrors);
        const custom = this.state.project.custom;
        const field = this.state.customFields.find((c) => c.id == fieldKey);
        if (!field) return customFieldsErrors;
        const value = (custom && custom[field.id]) || '';
        customFieldsErrors[field.id] = field.required && field.show_in_details && value === '';
        return customFieldsErrors;
    };

    checkCustomFieldValidity = (fieldKey) => {
        const customFieldsErrors = this.getCustomFieldError(fieldKey);
        this.setState({ customFieldsErrors });
        return Object.values(customFieldsErrors).indexOf(true) == -1;
    };

    onCustomFieldEdited = (name, value) => {
        const { project } = this.state;
        if (name.startsWith('custom_')) name = name.substring(7);
        this.setState(
            {
                project: {
                    ...project,
                    custom: {
                        ...project.custom,
                        [name]: value,
                    },
                },
            },
            () => {
                this.checkCustomFieldValidity(name);
            }
        );
    };

    render() {
        const { project, open, customFields, customFieldsErrors, stages, saving } = this.state;
        const { ref, ...rest } = this.props;
        return (
            <FieldEditSlider
                {...rest}
                open={open}
                onClose={this.onClose}
                title={project.parentid ? this.tr('Add subproject') : this.tr('Add project')}
                onSave={this.onSave}
                onItemChanged={this.onItemChanged}
                item={project}
                fields={this.getFields()}
                additionalValidations={this.checkCustomFieldsValidity}
                additionalFields={
                    <CustomFields
                        project={project}
                        fields={customFields}
                        form={0}
                        values={project.custom || {}}
                        projects_sales_states={stages}
                        onChange={this.onCustomFieldEdited}
                        hideOptional
                        errors={customFieldsErrors}
                    />
                }
                saving={saving}
            />
        );
    }
}

export default withSnackbar(AddProjectSlider);
