import React from 'react';
import ListCell from "./../ListCell";

import "./ListCellWithSymbols.css";

class ListCellWithSymbols extends ListCell {
	static defaultProps = {
		dataSuffix: null,
		dataPrefix: null,
		textColor: null
	};

	constructor(props) {
		super(props);
	}

	render() {
		const { width, textColor } = this.props;
		const style 			 = { width: width + "px", flex: `${width} 1 0`, color: `${textColor && textColor}` };
		const className 		 = `cell symbol-cell ${this.props.className}`;
		const cellValueClassName = `cellValue ${this.textAlignClasses[this.props.textAlign]}`;

		const { dataPrefix, dataSuffix } = this.props;

		if(!this.props.useIndenting)
			style['paddingLeft'] = 0;

		return (
			!this.props.onlyDisplay
			?
			<div data-testid={this.props['data-testid']} ref={this.cell} className={className} style={style} onClick={this.openEdit} tabIndex={this.props.tabIndex} data-prefix={dataPrefix} data-suffix={dataSuffix}>
				{(!this.state.inEditMode && !this.props.permanentEditMode) && <div data-testid={`${this.props['data-testid']}-value`} className={cellValueClassName}>{this.cleanValue(this.presentValue(this.props.value))}</div>}
				{(this.state.inEditMode || this.props.permanentEditMode) && this.props.children}
			</div>
			:
			<div ref={this.cell} className={className} style={style} onClick={this.openEdit}>{this.props.children}</div>
		);
	}
}

export default ListCellWithSymbols;