import * as Msal from "msal";
import { Auth } from '@aws-amplify/auth'

const msalConfig = {
auth: {
    clientId: "22d4947c-b3e7-449a-a694-c450c8aa20d2",
    authority:
    "https://login.microsoftonline.com/common",
    redirectURI: "http://localhost:3000/"
},
cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
}
};
const myMSALObj = new Msal.UserAgentApplication(msalConfig);

const loginRequest = {
    scopes: ["openid", "profile", "email"]
};

const generatePassword = (
    passwordLength = 32,
    passwordChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  ) =>
    [...window.crypto.getRandomValues(new Uint32Array(passwordLength))]
      .map(x => passwordChars[x % passwordChars.length])
      .join('');

const cognitoSignUp = async (email, rawIdToken) => {
    try {
        const { user: awsUser } = await Auth.signUp({
            username: email,
            password: generatePassword(),
            attributes: {
                email,
                "custom:msalIdtoken": rawIdToken
            },
        });
        return awsUser
    }
    catch (e) {
        console.log(e)
    }
}
let href;
const cognitoSignIn = async (email, rawIdToken) => {
    const awsUser = await Auth.signIn(email);
    const response = await Auth.sendCustomChallengeAnswer(awsUser, rawIdToken);
    return response
}
const restoreUrl = () => window.history.replaceState({}, "", href)
const postLogin = async (loginResponse) => {
    restoreUrl()
    const { idToken } = loginResponse.account
    const rawIdToken = loginResponse.idToken.rawIdToken
    const email = idToken.email || idToken.preferred_username;
    
    await cognitoSignUp(email, rawIdToken)
    return cognitoSignIn(email, rawIdToken)
}
export const signIn = () => {
    href = window.location.href;
    const origin = window.location.origin;
    window.history.replaceState({}, "", origin)
    return myMSALObj
        .loginPopup(loginRequest)
        .then(postLogin)
        .catch(error => {
            console.log(error)
            restoreUrl()
        });
};