import React from 'react';
import PropTypes from 'prop-types';
import { format } from "date-fns";
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import FileSaver from 'file-saver';

import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from './../SettingsContext';
import DataHandler from "../general/DataHandler";
import PurchaseOrder from "./PurchaseOrder";
import PoSyncedList from "./PoSyncedList";
import { makeMap } from "../list/ListUtils";
import { validEmail } from "../dialogs/Validate";
import OutlinedField from "./../general/OutlinedField";
import DataList from './../general/DataList';
import { AddAccount } from './../general/no-options/AddItemComponents';
import ProgressBar from "../general/ProgressBar";
import BillAssign from "../list/dialogs/BillAssign";
import { FlexChild, FlexContainer } from "../general/FlexUtils";
import ContextMenu from '../general/ContextMenu';
import { ReactComponent as RemoveIcon } from './../general/icons/remove.svg';
import MassDialog from '../dialogs/mass_operations/CoreDialog';
import PoSyncingSlider from './PoSyncingSlider';
import Log from './Log';
import LoaderButton from "../general/LoaderButton";
import NoPermissionOverlay from '../overlays/NoPermissionOverlay';
import Checkbox from "./../general/Checkbox";
import Utils from "./../general/Utils.js";

import withStyles from '@mui/styles/withStyles';
import { Button, MenuItem } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ArrowBack, Print as PrintIcon, KeyboardArrowRightRounded } from '@mui/icons-material';
import SupplierSelect from './../general/SupplierSelect';
import TopBar from './TopBar';
import { withSnackbar } from 'notistack';

import "./PurchaseOrder.css";
import colors from '../colors';

const styles = theme => ({
    buttonContainer: {
        margin: "23px 10px 23px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    datalistInputRoot: {
        width: "unset"
    },
});

class PurchaseOrderView extends TaimerComponent {
    static contextType  = SettingsContext;
    static defaultProps = {
        id: undefined,
        showLockedUsersWithTag: true
    };
    backButton = {
        visible: true,
        fallbackLocation: { module: 'costs', action: 'main', selectedTab: 'purchase-orders' },
    };
    static propTypes    = { enqueueSnackbar: PropTypes.func.isRequired };

    headerTitle = this.tr('Purchase Order');

    constructor(props, context) {
        super(props, context, "bills/PurchaseOrderView");

        let initialCompany = props.companies_id ? context.functions.getCompany("purchaseorders", "write", this.props.companies_id) : context.functions.getCompany("purchaseorders", "write");    

        this.STATUSES = [
            { id: 1, name: this.tr("Waiting"), title: this.tr("Waiting"), color: "#ffb822" }, 
            { id: 2, name: this.tr("Rejected"), title: this.tr("Rejected"), color: "#f7548f" }, 
            { id: 3, name: this.tr("Sent"), title: this.tr("Sent"), color: "#2d9ff7" }, 
            { id: 4, name: this.tr("Synced"), title: this.tr("Synced"), color: colors.greenish_cyan }, 
            { id: 101, name: this.tr('Message'), color: "#ff9900" },
            { id: 102, name: this.tr('Edited'), color: "#ff9900" },
            { id: 103, name: this.tr('Synced'), color: colors.greenish_cyan },
            { id: 104, name: this.tr('Created'), color: "#ffb822" },
            { id: 105, name: this.tr('Unsynced'), color: "#f7548f" },
        ]
        
        this.statuses = this.STATUSES.map(s => {
            s.label = s.name;

            return s;
        });

        this.statusMap = {};
        this.STATUSES.forEach(status => this.statusMap[status.id] = status);

        this.state = {
            id: props.id || "-1",
            editMode: isNaN(parseInt(props.id)),
            poWeight: 65,
            sideWeight: 34,
            initialized: false,
            data: {details: {}, headers: []},
            products: [],
            CPQParents: [],
            companies: [],
            company: initialCompany,
            accounts: [],            
            projects: [],
            all_accounts: [],
            account: 0,
            project: 0,
            targetedAccount: 0,
            currency: "EUR",
            allUsers: [],
            users: [],
            statusLog: [],
            statusDialogOpen: false,
            newStatusId: undefined,
            newStatusName: "",
            purchaseOrderState: 2,
            message: "",
            errors: {},
            showSyncSlider: false,
            syncedRows: [],
            billStatuses: [],
            dataLoaded: false,
            deleteDialogData: {},
            saving: false,
            editable: false,
            hasSyncRights: false,
            noPermission: false,
            dontUpdateHeaderDetails: false,
            billViewRight: false,
            fromProject: props.projects_id && Number(props.projects_id) > 0,
            fromQuote: props.quote && Number(props.quote) > 0,
            searchLockedProjects: false,
            printLanguage: "en",
            printDateFormat: this.convertDateFormat(this.context.taimerAccount.companyDateFormat, true),
            printLanguageOptions: [],
            printDateOptions: [],
            renderPrintPo: false,
            showArchivedAccounts: true
        };

        this.userTypeAutocompleteClasses = [
            'users',
            'privileged_users',
        ];

        this.translations = {
           locked: this.tr("locked"),
           freelancer: this.tr("freelancer")
        };

        this.container         = React.createRef();
        this.paperContainer    = React.createRef();
        this.purchaseOrderView = React.createRef();
        this.purchaseOrder     = React.createRef();
        this.logEntryContainer = React.createRef();
        this.printPurchaseOrder = React.createRef();

        this.checkSave      = this.checkSave.bind(this);
        this.save           = this.save.bind(this);
        this.cancel         = this.cancel.bind(this);
        this.handleResizing = this.handleResizing.bind(this);
    }

    componentDidMount() {
        this.getAutoCompleteData();
    }

    componentDidUpdate(prevProps, prevState) { // "Reset" the component by re-instantiating its state.
        if((prevProps.id !== this.props.id && (this.props.id == -1 || !this.props.id)) ) {
            this.setState({id: "-1", editMode: true, data: {id: -1, details: {}, headers: []}}, () => {this.getAutoCompleteData()});
        }
    }

    getAutoCompleteData = async (callBack = false) => {
        let { company, id, fromProject } = this.state;    
        const isNew            = Number(id) < 0;
        const companyPrivilege = isNew ? "write" : "read";
        let currency           = "EUR";
        let companiesUrl       = !fromProject ? `subjects/companies/purchaseorders/${companyPrivilege}` : `subjects/companies_with_project_right/purchase_order_${companyPrivilege}`

        if (!isNew || fromProject) {
            company = await DataHandler.get({ url: `purchaseorders/${id}/company/`, projects_id: this.props.projects_id });

            const privileges = this.context.privileges?.purchaseorders;
            if (!privileges || (privileges && !privileges[companyPrivilege]?.includes(Number(company)))) {
                companiesUrl = `subjects/companies_with_project_right/purchase_order_${companyPrivilege}`;
            }
        }

        !isNew && !this.props.printMode && this.fetchPurchaseOrderLog(this.state.id);  
        let users  = await DataHandler.get({ url: `purchaseorders/autoCompleteData/${company}`, users: 1, projects_id: this.props.projects_id });          
        let data       = await DataHandler.get({ url: `purchaseorders/autoCompleteData/${company}` });
        let allUsers   = await DataHandler.get({ url: "subjects/employees" });
        const accounts   = await DataHandler.get({ url: `/subjects/suppliers/${company}`, get_customers: 1 });
        const CPQParents = await DataHandler.get({ url: `/cpq/parents/${company}`});
        const companies  = await DataHandler.get({ url: companiesUrl, currency: 1, print_lang: 1, date_format: 1, country_lang: 1, print_options: 1});
        currency         = this.getCurrency(company, companies);
        const printOptions = this.getDefaultPrintOptions(companies.find(c => c.id == company));

        const userTagProps = {
           fields: {name: 'name'},
           showLocked: this.props.showLockedUsersWithTag,
           transl: this.translations
        };
        Object.keys(users).forEach(k => {
           if (this.userTypeAutocompleteClasses.includes(k)) {
               users[k] = users[k].map(d => ({...Utils.showLockedAndFreelancerUserTag(d, userTagProps)}))
           }
        })

        allUsers.forEach((p, i) => {
            allUsers[i] = ({...Utils.showLockedAndFreelancerUserTag(p, userTagProps)});
        })
       
        this.setState({
            CPQParents: CPQParents.CPQParents, 
            allUsers,
            users: users.privileged_users,
            companies, 
            accounts, 
            all_accounts: data.customers,
            projects: [], 
            account: 0, 
            project: 0,
            company,
            currency,
            printLanguage: printOptions.printLanguage || "en",
            printDateFormat: printOptions.printDateFormat,
            printLanguageOptions: printOptions.printLanguageOptions || [],
            printDateOptions: printOptions.printDateOptions || []
        }, () => callBack ? callBack() : this.getPurchaseOrder());
    }

    getDefaultPrintOptions = (company) => {
        if (!company)
            return {};

        let options = {};
        options.printLanguage = company.print_lang ? company.print_lang : company.country_lang;
        options.printDateFormat = company.date_format || this.convertDateFormat(this.context.taimerAccount.companyDateFormat, true);
        options.printLanguageOptions = company.print_languages;
        options.printDateOptions = company.print_date_formats;

        return options;
    }

    convertDateFormat(format, reverse = false) {
        if (!format) return undefined;

        if (reverse) {
            return format.replace('DD', '%d')
            .replace('MM', '%m')
            .replace('YYYY', '%Y')
        }

        return format.replace('%d', 'DD')
            .replace('%m', 'MM')
            .replace('%Y', 'YYYY')
    }

    async fetchPurchaseOrderLog(id) {
        let statusLog = await DataHandler.get({ url: `/purchaseorders/${id}/log`});

        if (statusLog.error)
            return [];

        statusLog = !statusLog ? [] : statusLog.purchase_order_log;

        statusLog.forEach(s => {

            if (s.event == 103 || s.event == 105) {
                let event = Number(s.users_id) < 1 ? "Automatically synced" : (s.event == 103 ? "Synced" : "Unsynced");
                s.message = this.tr(event + " bill ${billNr} from supplier ${supplier}", {billNr: s.billNr || "#", supplier: s.supplier});
            }
        })

        this.setState({ statusLog });
    }

    getCurrency = (id, companies) => {
        companies = companies || this.state.companies;
        let currency = "EUR";

        if (!companies || companies.length < 1) 
            return currency;

        let found = false;
        companies.forEach(company => {
            if(company.id == id) {
                currency = company.currency
                found = true;
            }
        })
        
        if (!found) 
            currency = companies[0].currency;

        return currency;
    }

    getPurchaseOrder = async (newId = false, callBack = () => {}) => {
        let project = 0;
        let targetedAccount = 0;
        let {id, all_accounts, company, editable, fromProject} = this.state;
        if (newId) {
            id = newId;
        }
        const isNew = !id || Number(id) < 0;

        if (fromProject) {
            project = await DataHandler.get({ url: `projects/${this.props.projects_id}`});
            project.label = project.name + " (" + project.project_id + ")";
            targetedAccount = all_accounts.find(a => a.id == project.customers_id);
        }

        if (isNew) {
            this.setState({ editable: true }, () => this.getNewPoData(fromProject, project, targetedAccount));
            return;
        }

        !this.props.printMode && this.getSyncedRows();
        let data = [];
        try {
            data = await DataHandler.get({ url: `purchaseorders/${id}/data`, fromProject: this.state.fromProject });
        } catch (e) {
            this.setState({noPermission: true});
            return;
        }

        if (data.only_project_right == 1 && !fromProject) {
            this.context.functions.updateView({ projects_id: data.details.projects_id });
            fromProject = true;
        }

        if (fromProject) {
            editable = data.has_project_po_writeright;
        }
        else {
            targetedAccount = all_accounts.find(a => a.id == data.details.targeted_customers_id);
        }

        const projects = await DataHandler.get({ url: `purchaseorders/projects/${targetedAccount.id}/${company}`, include_locked: this.state.searchLockedProjects, mandatoryId: data.details?.projects_id});
        const account  = all_accounts.find(a => a.id == data.details.customers_id);
        const headers  = await this.arrangeQuoteRowsUnderHeaders(data.rows, id);

        if (!fromProject) {
            editable  = !isNew ? this.context.functions.checkPrivilege("purchaseorders", "write", company) : true;

            try {
                project = projects.find(p => p.id == data.details.projects_id);
            } catch(e) {}
        }

        const billViewRight = this.context.functions.checkPrivilege("receivedinvoices", "approve", company) || this.context.functions.checkPrivilege("receivedinvoices", "pre_approve", company);
        const hasSyncRights = editable && billViewRight;
        const products      = await DataHandler.get({ url: `/products/array/${company}`, include_deleted: 1, account: targetedAccount.id });
        
        this.setState({data: { ...data, headers }, editable, billViewRight, hasSyncRights, purchaseOrderState: data.details.state, account, targetedAccount, project, projects, company, fromProject, products: products.products, dataLoaded: true}, callBack && callBack());
    }

    getNewPoData = async (fromProject, project, targetedAccount) => {
        const { company } = this.state;
        const products = await DataHandler.get({ url: `/products/array/${company}`, include_deleted: 1, account: targetedAccount.id });
        const currency = this.getCurrency(company);

        if (fromProject) {
            this.setState({ targetedAccount, project, products: products.products, currency}, () => this.geProjectNewPoData());
            return;
        }
        else if (!this.context.functions.checkPrivilege("purchaseorders", "write", company)) {
            this.setState({noPermission: true});
            return;
        }

        this.setState({targetedAccount, project, currency, products: products.products, dataLoaded: true });
    }

    geProjectNewPoData = async () => {
        let data = [];
        let account = this.state.account;

        if (!this.state.fromQuote) {
            try {
                data = await DataHandler.get({ url: `projects/${this.props.projects_id}/rights` });
                if (!data.includes("purchase_order_write")){
                    this.setState({noPermission: true});
                    return;
                }
                data = {details: {}, headers: []};
            } catch (e) {
                this.setState({noPermission: true});
                return;
            }
        }
        else {
            try {
                data = await DataHandler.get({ url: `purchaseorders/quote/${this.props.quote}`, row_ids: this.props.quote_rows });
                account = this.state.all_accounts.find(a => a.id == data.details.customers_id);

            } catch (e) {
                this.setState({noPermission: true});
                return;
            }
        }

        this.setState({ data, account, purchaseOrderState: 1, dataLoaded: true});
    }

    getSyncedRows = () => {
        DataHandler.get({url: `purchaseorders/${this.state.id}/synced_bill_rows/`}).done(response => {
            const translated_statuses = response.states ? response.states.map(e => {return {...e, label: this.tr(e.label)}}) : [];
            this.setState({ syncedRows: response.data, billStatuses: translated_statuses })
        });
    }

    getProjects = async () => {
        const projects = await DataHandler.get({ url: `purchaseorders/projects/${this.state.targetedAccount.id}/${this.state.company}`, include_locked: this.state.searchLockedProjects});
        this.setState({ projects });
    }

    arrangeQuoteRowsUnderHeaders = async (data, id) => {
        const isHeader = r => !r.parentId || r.parentId === "0";
        let headers    = data.filter(r => isHeader(r));
        let rows       = data.filter(r => !isHeader(r));

        if (headers.length === 0) {
            rows = rows.map(d => {return {...d, parentId: "-1", type: "1", _type: "quoteRow"}});
            headers = [{id: "-1", parentId: "0", name: "", type: "0", _type: "headerRow", rows: rows}];

            const postData = {headers: headers, id: id}
            const newData  = await DataHandler.post({url: `purchaseorders/save_headers`}, postData);

            headers        = newData.rows.filter(r => isHeader(r));
            rows           = newData.rows.filter(r => !isHeader(r));
        }

        const hOrder    = headers.map(h => h.id);
        const headerMap = makeMap(headers.map(r => {
            r.rows = []; 
    
            return r; 
        }), "id");
    
        rows.forEach(r => {
            headerMap[Number(r.parentId)].rows.push(r) 
        });
    
        return hOrder.map(id => headerMap[id]);
    }
    
    newMethod() {
        this.setState({ noPermission: true });
        return;
    }

    checkSave() {
        const { email } = this.purchaseOrder.current.getDeliveryAddress();

        if(!validEmail(email, true)) {
            this.purchaseOrder.current.setAddressError(false);
            return false;
        }

        this.purchaseOrder.current.setAddressError(true);
        return true;
    }


    async save() {
        this.setState({ saving: true });
        const headerData = this.purchaseOrder.current.getHeaderData();
        const projectId  = this.state.project ? this.state.project.id : 0;

        if (!this.state.targetedAccount) {
            this.props.enqueueSnackbar(this.tr("Account and project are required!"), { variant: "error" });
            this.setState({ errors: {targetedAccount: true, project: true}, saving: false })
            return;
        }
        else if (!projectId) {
            this.props.enqueueSnackbar(this.tr("Project is required!"), { variant: "error" });
            this.setState({ errors: {project: true}, saving: false })
            return;
        }

        const misc = this.purchaseOrder.current.getMisc();

        const da         = headerData.deliveryAddress || headerData.deliveryAddresses.find(addr => addr.id === headerData.deliveryAddressId) || {};
        const dateFormat = "YYYY-MM-DD";
        let name         = "";

        if(headerData.deliveryAddress && typeof headerData.deliveryAddress.account === "string" && headerData.deliveryAddress.account.trim() !== "") {
            name = headerData.deliveryAddress.account;
        } else {
            let deliveryAccount = headerData.accounts.find(a => a.id == headerData.accountId);

            if(deliveryAccount && deliveryAccount.hasOwnProperty("name"))
                name = deliveryAccount.name;
        }

        let address = "";

        if(da.address)
            address = da.address;
        else {
            let foundAddress = headerData.deliveryAddresses.find(address => address.id === headerData.deliveryAddressId);

            if(foundAddress)
                address = foundAddress.address;
        }

        let postData = {
            details: JSON.stringify({
                id: this.state.id,
                customers_id: headerData.supplierId,
                delivery_date: headerData.deliveryDate == "0000-00-00" ? undefined : format(headerData.deliveryDate, dateFormat),
                duedate:  headerData.estimatedDueDate == "0000-00-00" ? undefined : format(headerData.estimatedDueDate, dateFormat),
                order_date: headerData.orderedDate == "0000-00-00" ? undefined : format(headerData.orderedDate, dateFormat),
                users_id: headerData.orderUserId,
                customers_contacts_id: headerData.supplierContactId ? headerData.supplierContactId : 0,
                delivery_name: name,
                delivery_address: (() => {
                    if(da.address)
                        return da.address;

                    const address = headerData.deliveryAddresses.find(address => address.id === headerData.deliveryAddressId);

                    if(address)
                        return address.address;

                    return "";
                })(),
                delivery_postalcode: da.postalcode,
                delivery_city: da.city,
                delivery_state: da.state,
                delivery_country: da.country,
                delivery_contact: da.contact,
                delivery_phone: da.phone,
                delivery_email: da.email,
                delivery_method: da.delivery_method,
                misc: misc, // textarea
                projects_id: projectId
            }),
        };

        const headers = this.getCurrentHeaders();
        postData.headers = headers;

        let response = {};
        try {
            response = await DataHandler.post({url: `purchaseorders/save`, projects_id: this.props.projects_id}, postData);
        } catch(e) {
            this.props.enqueueSnackbar(this.tr("Error in saving purchaseorder!"), {
                variant: "error"
            });
        }

        if(headerData.createdAccountIds.indexOf(headerData.accountId) > -1) {
            await DataHandler.put({ url: `accounts/${headerData.accountId}` }, { 
                email: da.email,
                telephone: da.phone
            });
        }

        if(headerData.createdDeliveryAddresses.indexOf(headerData.deliveryAddressId) > -1) {
            await DataHandler.put({ url: `/accounts/delivery_address/${headerData.deliveryAddressId}` }, {
                name: name, 
                contact: da.contact, 
                address: address, 
                postalcode: da.postalcode, 
                city: da.city, 
                state: da.state,
                country: da.country, 
                e_operator: "", 
                e_address: "", 
                email: da.email
            });
        }

        const callBackFunc = () => {
            this.setState({ id: response.subcontracts_id, saving: false, editMode: false });
            this.purchaseOrder.current.emptyNewData();
            this.context.functions.updateView({id: response.subcontracts_id})
        }

        this.props.onSave && this.props.onSave(response.subcontracts_id);
        this.fetchPurchaseOrderLog(response.subcontracts_id);
        this.getPurchaseOrder(response.subcontracts_id, callBackFunc);
    }

    getCurrentHeaders = () => {
        const typeMap = this.purchaseOrder.current.getRowTypeMap(true);
        let hRowOrder = 0;

        let quoteData = this.purchaseOrder.current.getQuoteData().map(row => {
            if(row.hasOwnProperty("text")) {
                row.name        = row.text;
                row.description = row.text;
            }

            if(typeMap.hasOwnProperty(row._type))
                row.type = typeMap[row._type];
            else
                row.type = 0;

            row.roworder = hRowOrder++;

            return row;
        });

        const headers = {};

        quoteData.forEach(row => {
            if(row.hasOwnProperty("parentId") && row.parentId && row.parentId !== "0") {
                return;
            }

            delete row.rows;

            row.rows                = [];
            headers[String(row.id)] = row;
        });

        quoteData.filter(row => row.hasOwnProperty("parentId") && row.parentId && row.parentId !== "0").forEach(row => {
            headers[String(row.parentId)].rows.push(row); 
        });

        return headers;
    }

    cancel() {
        this.setState({ errors: {targetedAccount: false, project: false } })
        this.getPurchaseOrder();
    }

    handleResizing() {
        const containerWidth = this.container.current.getBoundingClientRect().width;

        if(containerWidth < 1300) {
            this.setState({
                poWeight: 1,
                sideWeight: 0
            });
        } else if(containerWidth > 1300) {
            this.setState({
                poWeight: 65,
                sideWeight: 35
            });
        }
    }

    updatePurchaseOrder = () => {
        this.purchaseOrder.current.companyChanged(this.state.company)    
    }

    companyChanged = (company) => {
        this.setState({ company, account: 0, targetedAccount: 0, project: 0 }, () => this.getAutoCompleteData(this.updatePurchaseOrder));
        this.context.functions.updateView({companies_id: company})
    }

    resetUpdateHeaderDetails = () => {
        this.setState({ dontUpdateHeaderDetails: false });
    }

    supplierChanged = async (account, updateChildren = true, newSuppliers = false) => {
        let accounts = this.state.accounts;
        let all_accounts = this.state.all_accounts;
        let dontUpdateHeaderDetails = false;

        if (newSuppliers) {
            account = newSuppliers.find(e => e.id == account.id);
            accounts = newSuppliers;
            all_accounts = await DataHandler.get({ url: `/purchaseorders/accounts/${this.state.company}` });
            dontUpdateHeaderDetails = true;
        }

        this.setState({ dontUpdateHeaderDetails, account, accounts, all_accounts, errors: {...this.state.errors, account: false} }, 
            () => {updateChildren && this.purchaseOrder.current.accountChanged(account)});

    }

    accountChanged = async (targetedAccount, newAccounts = false) => {
        const { company } = this.state;
        let all_accounts = this.state.all_accounts;

        if (newAccounts) {
            targetedAccount = newAccounts.find(e => e.id == targetedAccount.id);
            all_accounts = newAccounts;
        }

        const products = await DataHandler.get({ url: `/products/array/${company}`, include_deleted: 1, account: targetedAccount.id });

        DataHandler.get({ url: `purchaseorders/projects/${targetedAccount.id}/${this.state.company}`, include_locked: this.state.searchLockedProjects}).done(projects => {
            this.setState({ targetedAccount, all_accounts, project: 0, projects, products: products.products, errors: {...this.state.errors, targetedAccount: false} })
        });
    }

    projectChanged = (project) => {
        this.setState({ project, errors: {} })
    }

    accountCreated = async (account, type = "") => {
        if (type === "supplier") {
            const accounts = await DataHandler.get({ url: `/subjects/suppliers/${this.state.company}` });
            this.supplierChanged(account, true, accounts);
        }
        else {
            const accounts = await DataHandler.get({ url: `/purchaseorders/accounts/${this.state.company}` });
            this.accountChanged(account, accounts)
        }
    }

    statusChanged = (item) => {
        const newState = parseInt(item.id);
        // const pre = checkPrivilege("receivedinvoices", "pre_approve", this.state.company);
        // const approve = checkPrivilege("receivedinvoices", "approve", this.state.company);
        // const curState = parseInt(this.state.purchaseOrderState);

        // Opens the status dialog.
        this.setState({
            newStatusId: newState,
            newStatusName: item.title,
            statusDialogOpen: true
        });
    }

    openSyncSlider = () => {
        this.setState({ showSyncSlider: true });
    }

    closeSyncSlider = () => {
        this.setState({ showSyncSlider: false });
    }

    openDeleteDialog = () => {
        const data = {
            confirmFunc: this.delete,
            header: this.tr("Delete purchase order?"),
            warning: this.tr("Are you sure you want to delete purchase order #${num}?", {num: this.state.id})
        }

        this.setState({ deleteDialogOpen: true, deleteDialogData: data });
    }

    openUnsyncDialog = (id, nr) => {
        const data = {
            confirmFunc: () => this.unSync(id),
            confirmButtonText: this.tr("Unsync").toUpperCase(),
            header: this.tr("Unsync from bill?"),
            warning: this.tr("Are you sure you want to unsync purchase order #${poNum} from bill ${billNum}?", {poNum: this.state.id, billNum: nr || "#"})
        }

        this.setState({ deleteDialogOpen: true, deleteDialogData: data });
    }

    closeDeleteDialog = () => {
        this.setState({ deleteDialogOpen: false });
    }

    delete = () => {
        this.closeDeleteDialog();
        DataHandler.post({ url: `/purchaseorders/${this.state.id}/delete/`, projects_id: this.props.projects_id }).done(() => {
            this.props.enqueueSnackbar(this.tr("Purchase order deleted successfully!"), {
                variant: "success",
            });

            if (this.state.fromProject) {
                this.props.updateView({ module: 'projects', action: 'view', id: this.props.projects_id, selectedTab: "sales", tabView: this.state.fromQuote ? "quote" : "purchaseOrder" });
            }
            else {
                this.props.updateView({ module: 'costs', action: 'main', selectedTab: 'purchase-orders', company: this.state.company });
            }
        })
        .catch(err => {
            this.props.enqueueSnackbar(this.tr("Error deleting purchase order!"), {
                variant: "error"
            });
        });
    }

    changeState = (details) => {
        let data = { 
            id: this.state.id, 
            projects_id: this.props.projects_id,
            details: { 
                state: details.state,
                users_id: details.userId,
                target_users_id: details.userId,
                message: details.message
            }
        };

        DataHandler.post({ url: `/purchaseorders/change_state/` }, data).done((response) => {
            if (!response.success) {
                this.props.enqueueSnackbar(this.tr("Cannot chance state!"), {
                    variant: "error"
                });
                return;
            }

            this.setState({ purchaseOrderState: details.state });
            this.fetchPurchaseOrderLog(this.state.id);
        })
        .fail(err => {
            this.props.enqueueSnackbar(this.tr("Cannot chance state!"), {
                variant: "error"
            });
        });
    }

    sendMessage = async (message) => {
        if(this.state.id === undefined || this.state.id < 0)
            return;

        let data = {
            id: this.state.id, 
            projects_id: this.props.projects_id,
            details: { 
                state: "101",
                message: message
            }
        };

        try {
            await DataHandler.post({ url: `/purchaseorders/change_state/` }, data);
        } catch (e) {
            this.props.enqueueSnackbar(this.tr("Error in sending message!"), {
                variant: "error"
            });
        }

        this.fetchPurchaseOrderLog(this.state.id);
    }

    sync = async (id) => {
        try {
            await DataHandler.post({ url: `purchaseorders/${this.state.id}/sync` }, {billsId: id, projects_id: this.props.projects_id});
        } catch (e) {
            this.props.enqueueSnackbar(this.tr("Error in syncing!"), {
                variant: "error"
            });
        }

        this.closeSyncSlider();
        this.setState({ purchaseOrderState: 4 });
        this.getSyncedRows();
        this.fetchPurchaseOrderLog(this.state.id);
    }

    unSync = async (id) => {
        this.closeDeleteDialog();
        try {
            await DataHandler.post({ url: `purchaseorders/${this.state.id}/unsync` }, {billsId: id, projects_id: this.props.projects_id});
        } catch (e) {
            this.props.enqueueSnackbar(this.tr("Error in unsyncing!"), {
                variant: "error"
            });
        }

        this.getSyncedRows();
        this.fetchPurchaseOrderLog(this.state.id);
    }

    printPo = async () => {
        const key = this.props.enqueueSnackbar(this.tr("Generating purchase order PDF-file..."), {
            variant: "info",
            persist: true
        });

        this.setState({ renderPrintPo: true, snackBarKey: key });
    }

    onPrintRenderReady = () => {
        if (this.props.printMode && this.props.onPrintRenderReady) {
            this.props.onPrintRenderReady();
        }
        else if (this.state.renderPrintPo) {
            setTimeout(() => {
                this.print(this.state.snackBarKey);
                this.setState({renderPrintPo: false})
            }, 1000);
        }
    }  

    print = (snackBarKey) => {
        let styleTags = document.head.getElementsByTagName('style');
        let styles = "";
        for (let style in styleTags) {
            if (styleTags[style].innerText != "" && styleTags[style].innerText != undefined && styleTags[style].innerText != "undefined")
                if (!styleTags[style].innerText.startsWith("iframe"))
                    styles += styleTags[style].innerText;
        };

        let linkTags = document.head.getElementsByTagName('link');
        let links = [];
        for (let link in linkTags) {
            if (linkTags[link].rel == "stylesheet" && (linkTags[link].href.indexOf("taimer.com") > 0 || linkTags[link].href.indexOf("heeros.com") > 0 || linkTags[link].href.indexOf("taimer-stack:1025") > 0)) {
                links.push(linkTags[link].href);
            }

        };
        
        // For dev - open the page in new window to inspect styles
    /*   let html = '<html><head><style>' + styles + "</style>";
        for (let link in links) {
            html += "<link rel='stylesheet' href='" + links[link] + "'>";
        }
        html += '</head><body style="margin:0px"><div id="purchaseorder-print-container">';
        let quoteHtml = ReactDOM.findDOMNode(this.purchaseOrder.current).outerHTML;
        html += quoteHtml + '</div></body></html>';
        let wnd = window.open("about:blank", "", "_blank");
        wnd.document.write(html);
        return;   */
   
        // For production
        let html = '<html><head><meta charset="UTF-8"></head><body style="margin:0px"><div id="purchaseorder-print-container">';
        let purchaseOrderHtml = ReactDOM.findDOMNode(this.printPurchaseOrder.current).outerHTML;
        html += purchaseOrderHtml + '</div></body></html>';

        const params = {
            projectId: this.state.project,
            html: html,
            styles: styles,
            links: links,
        }

        DataHandler.post({ url: "print/purchaseOrder" }, params).done(response => {
            this.props.closeSnackbar(snackBarKey);
            if (!response.error) {
                DataHandler.getArrayBuffer({ url: 'file', filename: response.filename }).done((response) => {
                    if (!response.error) {
                        const blob = new Blob([response], {
                            type:
                                'application/pdf'
                        });

                        FileSaver.saveAs(blob, format(new Date(), "YYYY-MM-DD") + "_PO" + this.state.id + ".pdf");
                    } else {
                        this.props.enqueueSnackbar(this.tr("Failed to generate PDF-file."), {
                            variant: "error",
                        });
                    }
                });
            } else {
                this.props.enqueueSnackbar(this.tr("Failed to generate PDF-file."), {
                    variant: "error",
                });
            }

        });
    } 

    renderTopBar = () => {
        const number = this.state.id && Number(this.state.id) > 0 ? this.tr("PO") + ' (' + this.state.id + ') ' : "#";
        const info = number;
        const linkData = this.createTopBarLinks();
        const buttons = this.createTopBarButtons();
        const progessBar = Number(this.state.id) > 0 ? this.renderProgressBar() : "";
        return <TopBar links={linkData.links} info={info} buttons={buttons} extra={[progessBar]} />
    }

    createTopBarButtons = () => {
        const button = {
			className: 'option-menu-button',
            stickyIcon: true,
            size: "large"
        };
        let buttons = [];

        if (this.state.id && Number(this.state.id) > 0 && this.state.editMode) {
            const button1 = 
                <Button
                    className="grey"
                    size="large"
                    color="info"
                    onClick={() => {
                        this.cancel();
                        this.setState({ editMode: false });
                    }}>{this.tr("CANCEL").toUpperCase()}
                </Button>
            buttons.push(button1);
        }

        if (this.state.id && Number(this.state.id) > 0 && !this.state.editMode && this.state.dataLoaded) {
            const button2 =
                <ContextMenu data-testid="purchase_order_options_button" buttonProps={button} variant="outlined" className="option-menu" label={this.tr("Options")} size="medium" placement={"bottom-start"}>
                    <MenuItem onClick={() => this.printPo()}><PrintIcon />{this.tr('Print')}</MenuItem>
                    {this.state.editable && this.context.functions.checkPrivilege("admin", "admin", this.state.company) && (
                        this.createDisableableMenuItem(
                            this.tr('Delete'),
                            this.state.syncedRows.length > 0 ? this.tr('Cannot delete: Synced to bills') : this.tr('Cannot delete: Status not \"Waiting\"'),
                            () => this.openDeleteDialog(),
                            this.state.purchaseOrderState != 1 || this.state.syncedRows.length > 0,
                            RemoveIcon,
                            "delete",
                            "delete"
                        )
                    )}
                </ContextMenu>
            buttons.push(button2);
        }

        if (this.state.editable || !this.state.dataLoaded) {
            const button3 =
                <LoaderButton
                    data-testid={!this.state.dataLoaded || this.state.saving ? "save-purchaseorder-button-loading" : "save-purchaseorder-button-ready"}
                    className="blue"
                    size="large"
                    loading={!this.state.dataLoaded || this.state.saving}
                    text={this.state.editMode ? this.tr("SAVE").toUpperCase() : this.tr("EDIT").toUpperCase()}
                    onClick={() => {
                        if (this.state.editMode) {
                            if (!this.checkSave()) {
                                this.props.enqueueSnackbar(this.tr("Please fill in the highlighted fields correctly."), { variant: "warning" });
                                return;
                            }

                            this.save();
                        }
                        else {
                            this.setState({ editMode: true });
                        }
                    }} />
            buttons.push(button3);
        }

        if (this.state.editMode && this.state.purchaseOrderState != 2 && this.state.editable) {
            const button4 =
                <Button
                    className="btn red"
                    onClick={() => {
                        const details = {
                            state: 2,
                            users_id: this.context.userObject.usersId,
                            target_users_id: this.context.userObject.usersId,
                            message: ""
                        }
                        this.changeState(details);
                    }}
                    size="large">
                    {this.tr("REJECT").toUpperCase()}
                </Button>
            buttons.push(button4);
        }

        return buttons;
    }

    createTopBarLinks = () => {
        const { fromProject, fromQuote } = this.state;
        const linkData = {};
        if (!fromProject) {
            linkData.links = [{
                header: this.tr("Bills list"),
                module: "costs",
                action: "main",
                other: {selectedTab: "bills"}
            },
            {
                header: this.tr("Purchase orders"),
                module: "costs",
                action: "main",
                other: {selectedTab: "purchase-orders"}
            }]
            return linkData;
        }
        else {
            const { project, targetedAccount, company } = this.state;
            const accountLinkName = targetedAccount ? targetedAccount.name : this.tr("Account");
            const projectLinkName = project ? project.label : this.tr("Project");

            linkData.backLink = {
                module: "projects",
                action: "view",
                other: {id: project.id, selectedTab: "sales", tabView: fromQuote ? "quote" : "purchaseOrder"},
                disabled: !project?.id
            }
            linkData.links = [{
                header: accountLinkName,
                module: "customers",
                action: "view",
                other: {company: company, id: targetedAccount.id},
                disabled: !targetedAccount?.id
            },
            {
                header: projectLinkName,
                module: "projects",
                action: "view",
                other: {id: project.id},
                disabled: !project?.id
            }]
            return linkData;
        }
    }

    createDisableableMenuItem = (text, disableInfoMsg = "", onClickFunc = () => {}, disabled = false, Icon = undefined, className = "", iconClass = "") => {
		return disabled ? (
			<Tooltip title={disableInfoMsg} placement="right">
				<div>
					<MenuItem data-testid={`menu_item_${className}`} disabled={true} className={className} onClick={() => {}}>
						<Icon className={iconClass} title="" />{text}
					</MenuItem>
				</div>
			</Tooltip>
		) : (			
			<MenuItem data-testid={`menu_item_${className}`} className={className} onClick={onClickFunc}>
				<Icon className={iconClass} title="" />{text}
			</MenuItem>
		)
	}

    renderProgressBar = () => {
        const disabled = parseInt(this.state.purchaseOrderState) == 2;
        return (
            <ProgressBar 
                className="progress-bar"
                disabled={disabled}
                tooltip={this.tr("Change status")}
                value={!this.state.dataLoaded ? 0 : (disabled ? 4 : this.state.purchaseOrderState)}
                disableColorAll={false}
                colorActiveOnly={false}
                disabledColor={"#f7548f"}
                items={this.STATUSES.filter(s => s.title && s.id != 2)}
                onClick={item => this.state.editable && this.statusChanged(item)}
            />
        )
    }

    renderLog = () => {
        return (
            <>
                <div className='column-header'>
                    {this.tr("Messages & Log")}
                </div>
                {this.state.statusLog.length > 0 || this.state.editable ?
                    <Log
                        users={this.state.allUsers}
                        statusLog={this.state.statusLog}
                        statusMap={this.statusMap}
                        sendMessage={(message) => this.sendMessage(message)}
                        disableMessage={!this.state.editable}
                        id={this.state.id}
                    />
                   : 
                   <span class="not-synced-text">{this.tr("No log or messages")}</span>
                 }
            </>
        )
    }

    renderStatusDialog = () => {
        return (
            <BillAssign
                status={this.state.newStatusId}
                makeRequest={false}
                user={this.context.userObject.usersId}
                autoCompleteData={{
                    users: this.state.users.map(u => {
                        u.name = `${u.lastname} ${u.firstname}`;

                        return u;
                    }),
                    bills_statuses: this.statuses
                }}
                onClose={() => this.setState({ statusDialogOpen: false })}
                onSaved={obj => {
                    const details = {
                        userId: obj.user,
                        message: obj.message,
                        state: obj.status
                    };

                    this.setState({ statusDialogOpen: false, newStatusId: undefined });
                    this.changeState(details);
                }} />
        )
    }

    renderDeleteDialog = () => {
        return (
            <MassDialog
                onDialogClose={this.closeDeleteDialog}
                onDialogSave={this.delete}
                dialogType={"delete"}
                dialogProps={{
                    confirmButtonText: this.state.deleteDialogData.confirmButtonText,
                    onCloseClick: this.closeDeleteDialog,
                    open: this.state.deleteDialogOpen,
                    close: this.closeDeleteDialog,
                    header: this.state.deleteDialogData.header,
                    warning: () => {
                        return this.state.deleteDialogData.warning;
                    },
                    onConfirm: () => {
                       this.state.deleteDialogData.confirmFunc();
                    }
                }}
            />
        )
    }

    renderPrintSettings = () => {
        return (
            <>
                <div className='column-header'>{this.tr("Print settings")}</div>
                <OutlinedField
                    select
                    label={this.tr("Print Language")}
                    className="right-column-field"
                    value={this.state.printLanguage}>
                    {
                        this.state.printLanguageOptions.map((opt) =>
                            <MenuItem value={opt.value} key={opt.value} onClick={() => this.setState({ printLanguage: opt.value })}>
                                {this.tr(opt.label)}
                            </MenuItem>
                        )
                    }
                </OutlinedField>
                <OutlinedField
                    select
                    label={this.tr("Date Format")}
                    className="right-column-field"
                    value={this.state.printDateFormat}>
                    {
                        this.state.printDateOptions.map((opt) =>
                            <MenuItem value={opt.value} key={opt.value} onClick={() => this.setState({ printDateFormat: opt.value })}>
                                {opt.label}
                            </MenuItem>
                        )
                    }
                </OutlinedField>
            </>
        );
    }

    render() {
        const { functions: { checkPrivilege } } = this.context;
        const { enqueueSnackbar } = this.props;
        const { classes } = this.props;
        const { id, hasSyncRights, billViewRight, data, company, companies, users, allUsers, account, accounts, targetedAccount, all_accounts, project, projects, currency, editMode, products, CPQParents, syncedRows, billStatuses, noPermission, dontUpdateHeaderDetails, fromQuote, searchLockedProjects, renderPrintPo, printLanguage, printDateFormat, showArchivedAccounts} = this.state;
        const isNew  = !id || Number(id) < 0;

        if (noPermission)
            return <NoPermissionOverlay />

        const fromProject = Number(this.props.projects_id) > 0;

        const rowProps = {
            dontUpdateHeaderDetails,
            resetUpdateHeaderDetails: this.resetUpdateHeaderDetails,
            editMode:           editMode,
            products:           products,
            CPQParents:         CPQParents,
            company:            company,
            currency:           currency,
            accounts:           accounts,
            all_accounts:       all_accounts,
            users:              users,
            allUsers:           allUsers,
            cancel:             this.cancel,
            checkSave:          this.checkSave,
            save:               this.save,
            supplierChanged:    this.supplierChanged,
            enqueueSnackbar:    this.props.enqueueSnackbar,
            units:              [],
            deletedProducts:    [],
            jobtypes:           [],
            printMoode:         false,
            deleteRow:          () => {},
            createTask:         () => {},
            rowDataChanged:     () => {},
            deleteHeaderRow:    () => {},
            headerNameChanged:  () => {},
            CPQGroupChanged:    () => {},
            addRow:             () => {},
            addProductRow:      () => {},
            addHeaderRow:       () => {},
            addCPQRow:          () => {},
            addCPQGroup:        () => {},
            addDescriptionRow:  () => {},
            editAddress:        () => {},
            editCompanyAddress: () => {},
            updateRowOrder:     () => {},
            updateHeaderOrder:  () => {}
        };

        const syncedListProps = {
            currency,
            billStatuses,
            syncedRows,
            hasSyncRights,
            unSyncFunc: (id, nr) => this.openUnsyncDialog(id, nr),
            openSyncSlider: this.openSyncSlider

        };

        const printPoContent = (
            <div style={{ display: "none", width: "0px", height: "0px" }}>
                <PurchaseOrder
                    renderPrintPo={true}
                    poDataLoaded={this.state.dataLoaded}
                    onPrintRenderReady={this.onPrintRenderReady}
                    printLanguage={this.props.printLang ? this.props.printLang : printLanguage}
                    printDateFormat={this.convertDateFormat(printDateFormat)}
                    id={id}
                    ref={this.printPurchaseOrder}
                    editMode={editMode}
                    rowProps={rowProps}
                    fromQuote={fromQuote}
                    data={data}
                    paperProps={{
                        container: this.paperContainer
                    }} 
                    />
            </div>
        );

        if (this.props.printMode) {
            return (
                <div ref={container => this.purchaseOrderView = container} id="purchaseorder-view">
                    <div id="purchaseorder-area" ref={this.paperContainer}>
                        {printPoContent}
                    </div>
                </div>
                );
        }
 
        return (
            <div ref={container => this.purchaseOrderView = container} id="purchaseorder-view">
                {renderPrintPo && printPoContent}

                {this.state.deleteDialogOpen && this.renderDeleteDialog()}
                {this.state.statusDialogOpen && this.renderStatusDialog()}
                {this.renderTopBar(fromProject)}
                <PoSyncingSlider view={"po"} company={company} supplierId={this.state.data.details.customers_id}  syncedRows={syncedRows} statuses={billStatuses} currency={currency} open={this.state.showSyncSlider} syncFunc={this.sync} onSliderClose={() => this.closeSyncSlider()} />

                <div id="purchaseorder-area" ref={this.paperContainer}>
                    <FlexContainer>
                    <FlexChild id="purchaseorder-left-column" className={`container-left left-column`} weight={65}>
                        <PurchaseOrder
                            renderPrintPo={false}
                            id={id}
                            ref={this.purchaseOrder}
                            editMode={editMode}
                            rowProps={rowProps}
                            fromQuote={fromQuote}
                            data={data}
                            paperProps={{
                                container: this.paperContainer
                            }} />
                        </FlexChild>
                    <FlexChild id="purchaseorder-right-column" weight={35}>
                        <div>
                            {companies.length > 1 && (
                            <> 
                                <div className='column-header'>{this.tr("Company")}</div>
                                <OutlinedField 
                                    label={this.tr("Company")} 
                                    value={company} 
                                    className="right-column-field"
                                    disabled={!editMode || Number(id) > 0 || fromProject} 
                                    select 
                                    onChange={e => this.companyChanged(e.target.value)}>
                                    {companies.map(row => (
                                        <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                    ))}
                                </OutlinedField>
                            </>
                            )}
                            <div className='column-header'>{this.tr("Supplier")}</div>
                            <SupplierSelect 
                                label={this.tr("Supplier")}
                                name="customers_id"
                                className="right-column-field"
                                value={account}
                                error={this.state.errors.account}
                                options={accounts}
                                enqueueSnackbar={enqueueSnackbar}
                                showArchivedAccounts={showArchivedAccounts}
                                inputBaseProps={{classes: {root: classNames(classes.datalistInputRoot)}}}
                                company={company}
                                supplierCreated={(account) => this.accountCreated(account, "supplier")}
                                accountUpdated={(supplier) => {
                                    this.props.enqueueSnackbar && this.props.enqueueSnackbar(this.tr("Account ${account} updated successfully!", {account: supplier.name}), {
                                        variant: "success"
                                    });
                                    this.supplierChanged(supplier, true);
                                }}  
                                onChange={this.supplierChanged}
                                isDisabled={!editMode} 
                                shownCount={20}
                            />    
                            {(editMode && !fromProject) && <Checkbox
                                name="showArchived"
                                checked={showArchivedAccounts}
                                onChange={() =>
                                    this.setState({ showArchivedAccounts: !showArchivedAccounts })
                                }
                                label={this.tr("Show archived accounts in supplier search")}
                            />}
                            <div className='column-header'>{this.tr("Targeted to account and project")}</div>
                            <DataList 
                                label={this.tr("Account")}
                                name="targeted_customers_id"
                                value={targetedAccount}
                                className="right-column-field"
                                error={this.state.errors.targetedAccount}
                                options={all_accounts}
                                noOptions={AddAccount}
                                noOptionsProps={{company: company}}
                                company={company}
                                onItemCreated={(account) => this.accountCreated(account)}
                                onChange={(account) => this.accountChanged(account)}
                                isDisabled={!editMode || fromProject}
                                inputBaseProps={{classes: {root: classNames(classes.datalistInputRoot)}}}
                                noEmptiedResetOnKeyDown={true}
                                shownCount={20}
                            />    
                            <DataList 
                                label={this.tr("Project")} 
                                name="project_id"
                                error={this.state.errors.project}
                                options={projects}
                                value={project}
                                className="right-column-field"
                                inputBaseProps={{classes: {root: classNames(classes.datalistInputRoot)}}}
                                noOptionsMessage={this.tr("No options")}
                                onChange={this.projectChanged}
                                isDisabled={!editMode || fromProject} 
                                noEmptiedResetOnKeyDown={true}
                                shownCount={20}
                            />
                             {(editMode && !fromProject) && <Checkbox
                                name="searchLocked"
                                checked={searchLockedProjects}
                                onChange={() =>
                                    this.setState({ projects: [], searchLockedProjects: !this.state.searchLockedProjects }, () => this.getProjects())
                                }
                                label={this.tr("Include closed projects")}
                            />}

                            {!isNew && <div className='column-header'>{this.tr("Sync to Bill")}</div>}
                            {syncedRows.length > 0 && <PoSyncedList {...syncedListProps} />}
                            {syncedRows.length === 0 && this.state.dataLoaded && !isNew && this.state.hasSyncRights && <Button
                                data-testid="sync-to-bill-button"
                                className="blue sync-button"
                                size="large"
                                onClick={() => {
                                        this.openSyncSlider();
                                    }
                                }>{this.tr("Sync to Bill").toUpperCase()}
                            </Button>}
                            {syncedRows.length === 0 && this.state.dataLoaded && !isNew && !this.state.hasSyncRights && (
                                <span class="not-synced-text">{this.tr("Not synced")}</span>
                            )}

                            {!isNew && (this.state.printLanguageOptions.length > 1 ? this.renderPrintSettings() :  <OutlinedField
                                    select
                                    label={this.tr("Date Format")}
                                    className="right-column-field"
                                    value={this.state.printDateFormat}>
                                    {
                                        this.state.printDateOptions.map((opt) =>
                                            <MenuItem value={opt.value} key={opt.value} onClick={() => this.setState({ printDateFormat: opt.value })}>
                                                {opt.label}
                                            </MenuItem>
                                        )
                                    }
                                </OutlinedField>)}
                            {!isNew && this.renderLog()}
                        </div>
                    </FlexChild>
                     </FlexContainer>
                </div>
            </div>
        );
    }
}
        
export default withSnackbar(withStyles(styles)(PurchaseOrderView));
