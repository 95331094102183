import React from 'react';
import InsightChart from "../../InsightChart";
import StackedCurrencyChart from '../../StackedCurrencyChart';
import InsightChartTable from '../../InsightChartTable';

const WonDealsDevelopment = props => {
    const {tr, data, months, ...otherProps} = props;
    return (
        <InsightChart
        {...otherProps}
          title={tr("Won Deals Development")}
          data={{
            sets: [
              data.two_years_back && {
                year: data.two_years_back.year,
                label: data.two_years_back.label,
                values: data.two_years_back.values,
                barPercentage: 1,
                categoryPercentage: 0.7,
                colors: {
                  backgroundColor: "#b891ff",
                  borderColor: "#b891ff"
                }
              },
              data.last_year && {
                year: data.last_year.year,
                label: data.last_year.label,
                values: data.last_year.values,
                barPercentage: 1,
                categoryPercentage: 0.7,
                colors: {
                  backgroundColor: "#ffa082",
                  borderColor: "#ffa082"
                }
              },
              data.this_year && {
                year: data.this_year.year,
                label: data.this_year.label,
                values: data.this_year.values,
                barPercentage: 1,
                categoryPercentage: 0.7,
                colors: {
                  backgroundColor: "#2d9ff7",
                  borderColor: "#2d9ff7"
                }
              }
            ].filter(x => x),
            labels: months
          }}
          chartType={StackedCurrencyChart}
          tableType={InsightChartTable}
          lowOpacityChart={false}
          titleOnSlider={true}
          sliderColumns={["project", "account", "user", "value", "total_split"]}
          chartProps={{
            stacked: false,
            xStacked: false,
            displayLegend: true
          }}
          tableProps={{
            displayByLabels: true,
            displayFooter: true,
            style: {
              maxHeight: 9999
            }
          }}
        />
    );
}

export default WonDealsDevelopment;