import React, { useState, useEffect } from "react";
import { Tabs, Tab, IconButton, Button, Input, ClickAwayListener } from "@mui/material";
import { Timer, Close } from "@mui/icons-material";
import _ from 'lodash';
import './ResourcingGridSlider.scss';
import cn from 'classnames';

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.prevValue = 0;
    const { start = 0, end = 110, sliderValue, dailyHours } = this.props;
    let values = [];
    let current = start;

    if (this.props.mode !== "hours") {
      while (current < end) {
        current += 10;
        values.push(current);
      }
    } else {

      const step = end > dailyHours * 6 ? this.props.dailyHours * 2 : (end > dailyHours * 2 ? this.props.dailyHours / 2 : 1);

      while (current < end) {
        current += step;
        values.push(current);
      }
    }

    this.dragging = false;
    this.values = values;

    this.state = {
      handlePosition: this._convertHandlePosition(sliderValue)
    };
  }

  componentDidUpdate = oldProps => {
    if (oldProps.sliderOpen != this.props.sliderOpen && this.props.sliderOpen) {
      this.setState({
        handlePosition: this._convertHandlePosition(this.props.sliderValue)
      });
    }
    if (oldProps.sliderValue != this.props.sliderValue) {
      this.setState({
        handlePosition: this._convertHandlePosition(this.props.sliderValue)
      });
    }
  };

  shouldComponentUpdate = (oldProps, oldState) => {
    if (
      oldState.handlePosition != this.state.handlePosition ||
      oldProps.sliderOpen != this.props.sliderOpen ||
      oldProps.width != this.props.width ||
      (oldProps.sliderValue != this.props.sliderValue && !this.dragging)
    ) {
      return true;
    }
    return false;
  };

  _convertHandlePosition = position => {
    const { width } = this.props;
    const sliderWidth = Math.max(Math.min(width - 220, 900), 700);
    let newLeft = sliderWidth * ((Number(position) || 0) / _.last(this.values));
    if (newLeft < 0) {
      newLeft = 0;
    }
    let rightEdge = sliderWidth;
    if (newLeft > rightEdge) {
      newLeft = rightEdge;
    }

    return newLeft;
  };

  _onMouseDown = event => {
    const { setValue } = this.props;
    event.preventDefault();
    const slider = document.getElementById("slider");
    const sliderWidth = slider.offsetWidth;
    this.dragging = true;
    const onMouseMove = event => {
      let newLeft = event.clientX - slider.getBoundingClientRect().left;
      if (newLeft < 0) {
        newLeft = 0;
      }
      let rightEdge = sliderWidth;
      if (newLeft > rightEdge) {
        newLeft = rightEdge;
      }
      const blockWidth = sliderWidth / this.values.length;
      this.setState({
        handlePosition: newLeft
      });

      if (this.props.mode !== "hours")
        newLeft = Math.round(newLeft / blockWidth) * blockWidth;

      const index = Math.round(newLeft / sliderWidth * this.values.length) - 1;
      const roundedValue = index > -1 ? this.values[index] : 0;
      const actualValue = Math.round(newLeft / sliderWidth * _.last(this.values) * 2) / 2;
      const usedValue = this.props.mode == "hours" ? actualValue : roundedValue;

      if (usedValue != this.prevValue) {
        this.prevValue = usedValue;
        setValue(usedValue, this.props.mode);
      }
    };

    const onMouseUp = () => {
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);
      this.dragging = false;
    };
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  render() {
    const { width, setValue, getColor, closeSlider, mode } = this.props;
    const blocks = this.values.map((value, i) => {
      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            borderRight: i == this.values.length - 1 ? "none" : undefined,
            backgroundColor: getColor(i > 0 ? this.values[i] : 0).backgroundColor
          }}
          className="slider-block"
        >
          <div
            onClick={() => {

              setValue(i > 0 ? this.values[i - 1] : 0, this.props.mode);

              setTimeout(() => {
                closeSlider();
              }, 500);
            }}
            style={{ flex: 1 }}
          />
          <div
            onClick={() => {
              setValue(value, this.props.mode);
              setTimeout(() => {
                closeSlider();
              }, 500);
            }}
            style={{ flex: 1 }}
          />
        </div>
      );
    });
    const labels = this.values.map((value, i) => {
      return (
        <div className="slider-label-container">
          <p>{value.toFixed(0)} {mode == "hours" ? 'h' : '%'}</p>
        </div>
      );
    });

    const marginLeft = Math.max(Math.min(width - 220, 900), 700) / this.values.length / 2;
    return (
      <div className="slider-container">
        <div id="slider" className="slider">
          {blocks}
          <div
            id="slider-handle"
            onMouseDown={this._onMouseDown}
            style={{ left: this.state.handlePosition + 40 }}
            className="slider-handle"
          />
        </div>
        <div
          style={{ marginLeft, pointerEvents: "none" }}
          className="slider-labels"
        >
          {labels}
          <div style={{ position: "absolute", left: -(marginLeft + 8) }}>
            <p>0 {mode == "hours" ? 'h' : '%'}</p>
          </div>
        </div>
      </div>
    );
  }
}

const GridHourEntry = props => {
  const { tr, onClick, inputRef, value, setValue, mode } = props;

  return (
    <div className="hour-entry-container">
      <Input
        inputRef={inputRef}
        onKeyPress={e => e.which == 13 && onClick()}
        value={value || ""}
        onChange={e => setValue(e.target.value, mode)}
        endAdornment={
          <div className="clock-icon-container">
            {mode == "hours" && <Timer style={{ height: 20, width: 20 }} />}
            {mode != "hours" && "%"}
          </div>
        }
        type="number"
        inputProps={{
          min: 0,
        }}
        placeholder={mode === "hours" ? tr("Hours") : tr("Percentage")}
      />
      <Button
        onClick={onClick}
      >
        {tr("Apply")}
      </Button>
    </div>
  );
};

class ResourcingGridSlider extends React.PureComponent {
  state = {
    mode: "relative"
  };
  hourInput = React.createRef();

  componentDidUpdate = (oldProps, oldState) => {
    if ((oldProps.sliderOpen != this.props.sliderOpen) && this.props.sliderOpen) {
      this.setState(
        {
          mode: this.props.sliderMode
        },
        () => {
          if (this.hourInput.current) {
            this.hourInput.current.focus();
          }
        }
      );
    }

    if (oldProps.selectedCells != this.props.selectedCells && this.props.sliderOpen || oldState.mode != this.state.mode) {
      if (this.hourInput.current) {
        this.hourInput.current.focus();
      }
    }

    if (!this.props.canAddPercentage && this.state.mode === "relative") {
      this.setMode("hours");
    }
  };

  closeSlider = () => {
    const { sliderValue, closeSlider } = this.props;

    const { mode } = this.state;

    closeSlider(sliderValue, mode == "hours");
  }

  cancelSlider = () => {
    const { sliderValue, cancelSlider } = this.props;

    const { mode } = this.state;

    cancelSlider();
  }

  getColor = (value) => {
    const { getColor } = this.props;
    const { mode } = this.state;

    if (mode === "hours")
      return getColor(value / (this.props.selectedWorkdays * this.props.dailyHours) * 100);

    return getColor(value)
  }

  setMode = (mode) => {
    const { setSliderMode, setValue, value } = this.props;
    this.setState({ mode });

    setSliderMode(mode);
    setValue(value, mode);
  }

  render() {
    const {
      sliderOpen,
      tr,
      width,
      sliderValue,
      setValue,
      selectedWorkdays,
      setHours,
      smallMode,
      canAddPercentage,
    } = this.props;
    const { mode } = this.state;
    return (
      <ClickAwayListener
				mouseEvent={sliderOpen ? "onClick" : false}
				onClickAway={this.props.onClickAway}
			>
      <div
        id="grid-slider"
        style={{ height: sliderOpen ? (smallMode ? 130 : 230) : 0 }}
        className={cn("grid-slider-container", smallMode && "grid-slider-container-small")}
      ><div className="grid-slider-padding">
          <div className="grid-slider-row multi-align">
            <div></div>
            <div className="grid-slider-modes-container">
              <div
                onClick={() => this.setMode("hours")}
                className={
                  "format-mode-button" + (mode == "hours" ? " selected" : "")
                }
              >
                h
            </div>
              {canAddPercentage && <div
                onClick={() => this.setMode("relative")}
                className={
                  "format-mode-button" + (mode == "relative" ? " selected" : "")
                }
              >
                %
              </div>}
            </div>
            <div>
              <IconButton size="small" onClick={() => this.cancelSlider()} className="close-button">
                <Close />
              </IconButton>
            </div>
          </div>

          {!smallMode && sliderOpen && <div className="grid-slider-row centered">
            {mode == "relative" ? (
              <Slider
                key="relative"
                getColor={this.getColor}
                closeSlider={this.closeSlider}
                sliderOpen={sliderOpen}
                mode="relative"
                sliderValue={sliderValue}
                setValue={setValue}
                width={width}
              />
            ) : (
                <Slider
                  key="hours"
                  getColor={this.getColor}
                  closeSlider={this.closeSlider}
                  sliderOpen={sliderOpen}
                  mode="hours"
                  dailyHours={this.props.dailyHours}
                  end={selectedWorkdays > 1 ? (selectedWorkdays + 1) * this.props.dailyHours : this.props.dailyHours + 2}
                  sliderValue={sliderValue}
                  setValue={setValue}
                  width={width}
                />
              )}
          </div>}

          {!smallMode && <div className="grid-slider-row centered grid-slider-or">
            <div />
            <span>{tr("Or")}</span>
            <div />
          </div>}

          <div className="grid-slider-row centered">
            <GridHourEntry
              inputRef={this.hourInput}
              onClick={this.closeSlider}
              value={sliderValue}
              setValue={setValue}
              mode={mode}
              tr={tr}
            />
          </div></div>
      </div>
      </ClickAwayListener>
    );
  }
}

export default ResourcingGridSlider;