// TODO: Remove all functions that have been moved to ListUtils and refactor callers to import the new functions.

import cloneDeep from "lodash/cloneDeep";

const Utils = {
	today: () => {
		return new Date()
			.toISOString()
			.substr(0, 10);
	},
	dateAdd: (date, inc) => {
		date = typeof(date) === "string"
			? new Date(date)
			: date;

		date.setDate(date.getDate() + inc);
			
		return date;
	},
    dateToString: (date) => {
        return date
            .toISOString()
            .substr(0, 10);
    },
	objectsAreEquivalentSimple: (a, b) => {
		if(a === b)
			return true;

		const aProps = Object.getOwnPropertyNames(a);
		const bProps = Object.getOwnPropertyNames(b);

		if(aProps.length !== bProps.length)
			return false;

		for(const i of aProps)
			if(!b.hasOwnProperty(i) || b[i] !== a[i])
				return false;

		for(const i of bProps)
			if(!a.hasOwnProperty(i) || a[i] !== b[i])
				return false;

		return true;
	},

	// Relies on all objects in sets a and b having an 'id' field.
	dataObjectArraysAreEquivalent: (a, b) => {
		for(const aObj of a) {
			const toCompare = b.find(bElement => bElement.id === aObj.id);

			if(toCompare === undefined || !Utils.objectsAreEquivalentSimple(aObj, toCompare))
				return false;
		}

		for(const bObj of b) {
			const toCompare = a.find(aElement => aElement.id === bObj.id);

			if(toCompare === undefined || !Utils.objectsAreEquivalentSimple(bObj, toCompare))
				return false;
		}

		return true;
	},

    // TODO: Optimize to use maps. Especially the indexOf calls here can slow things down quite a bit.
	arrayIntersect: (arrOfArrs) => {
        if(arrOfArrs.length === 0)
            return [];

        const argL          = arrOfArrs.length;
        const arrs          = [];
        let shortestIndex = 0;
        let shortest      = 0;
        const intersection  = [];

        for(let i = 0; i < argL; i++) {
            if(!Array.isArray(arrOfArrs[i]))
                arrOfArrs[i] = Array.from(arrOfArrs[i]);

            arrs.push(arrOfArrs[i]);

            if(shortest === 0 || arrOfArrs[i].length < shortest) {
                shortest      = arrOfArrs[i].length;
                shortestIndex = i;
            }
        }

        for(let ai = 0; ai < arrs[shortestIndex].length; ai++) {
            let inAll = true;

            for(let i = 0; i < arrs.length; i++) {
                if(i === shortestIndex)
                    continue;

                if(arrs[i].indexOf(arrs[shortestIndex][ai]) === -1) {
                    inAll = false;
                    break;
                }
            }

            if(inAll)
                intersection.push(arrs[shortestIndex][ai]);
        }

        return intersection;
    },

	pickProperties: (o, properties = [], clone = false) => {
		const newObject = {};
		const from      = !clone
			? o
			: cloneDeep(o);

		for(const i of properties) {
			newObject[i] = o[i];
		}

		return newObject;
	},

	intRange: (a, b, inc = +1) => {
		if(inc === 0)
			throw new Error("inc can not be 0.");
		if(a === b)
			return [a];

		inc = a < b ? Math.abs(inc) : inc > 0 ? -1 * inc : inc;

		const r = [];

		while(true) {
			r.push(a);

			a += inc;

			if((inc > 0 && a > b) || (inc < 0 && a < b))
				break;
		}

		return r;
	},

	pageRange: (curr, max, aroundCurr = 2, aroundEnds = 2) => {
		const pages 	 	   = [];
		let insertInterval = false;

		for(let i = 1; i <= max; i++) {
			if(i === 1 || i <= 1 + aroundEnds || i >= max - aroundEnds || (i >= curr - aroundCurr && i <= curr + aroundCurr)) {
				pages.push(i);

				insertInterval = true;
			} else if(insertInterval) {
				pages.push("...");

				insertInterval = false;
			}
		}

		return pages;
	},

	pageRangeNine: (cur, max) => {
		if(cur === 1 && (cur === max || max === 0))
			return [1];

		if(max <= 9)
			return Utils.intRange(1, max);
		
		const midpoint 	= Math.ceil(max/2);
		let start 		= []; 
		let end 		= [];
		let mid 		= [];

		if (midpoint != cur){
			start = (cur > 6 && max > 10) ? [1, 2] : Utils.intRange(1, 6);
			end   = (max - cur < 6 && max > 10) ? Utils.intRange(max - 5, max) : [max - 1, max];
			mid   = (start.length === 2 && end.length === 2) ? ["...", ...[cur -2, cur -1, cur, cur + 1, cur + 2], "..."] : ["..."];
		} else {
			start = [1, 2];
			end   = [max - 1, max];
			mid   = ["...", ...[cur - 2, cur - 1, cur, cur + 1, cur + 2], "..."];
		}

		return [...start, ...mid, ...end];
	},

	addThousandSeparator: (n) => {
		if(!n)
	        return "";
	    let sign = "";
	    n = "" + n;
	    if(n.indexOf("-") === 0) {
	       n = n.slice(1);
	        sign = "-";
	    }
	    const rx=  /(\d+)(\d{3})/;
	    return String(n).replace(/^\d+/, function(w){
	        while(rx.test(w)){
	            w= w.replace(rx, '$1 $2');
	        }
	        return sign + w;
	    });
	},

	// Find an object defined by the rule in f (f.ex. (element) => element.id === "13"); a is an array containing the data;
	// childrenPropertyName defines the name of the property that has the object's children.
	findRecursively: (a, f, childrenPropertyName = "children") => {
		let found = undefined;

		for(let i = 0; i < a.length && found === undefined; i++)
			found = f(a[i]) ? a[i] : Utils.findRecursively(a[i][childrenPropertyName], f, childrenPropertyName);

		return found;
    },

	makeMap: (arr, key) => {
		const map = {};

		for(const e of arr)
			map[e[key]] = e;

		return map;
    },

    makeMapFn: (arr, key, condFn) => {
        const map = Utils.makeMap(arr, key);

        for(const k in map) {
            map[k] = condFn(map[k]);
        } 

        return map;
    },

    // TODO: Check if in use; there's another implementation of this in ListUtils.
    // DO NOT USE.
    treeFormatDataForList: (data, parentKey = "parentId") => {
		data = (Array.isArray(data)) ? data : [data];
		if(data.length == 0) {
			return [];
		}

        const parentParentIdValues = [undefined, null,  0, "0"];

		const map 	  = {};
		const finalData = [];
		const idOrder   = [];

		data.forEach(el => {
            const tempObject 			= {};

			tempObject.data 		= Object.assign({}, el);
			tempObject.children 	= [];
			map[tempObject.data.id] = tempObject;

			idOrder.push(el.id);
		});

		const allIds = [...Object.keys(map), ...Object.keys(map).map(k => parseInt(k))];

		for(const i in map) {
			if(!map[i].data.hasOwnProperty(parentKey) || allIds.indexOf(map[i].data[parentKey]) === -1 || parentParentIdValues.indexOf(map[i].data[parentKey]) > -1)
				continue;

			map[map[i].data[parentKey]].children.push(map[i]);
		}

		// Form an array where there are no children on the first level, and children only occur under their parent's children property; TODO: unless the child's parent is not in this set.
		for(const i in map) {
			Object.defineProperty(map[i], "pristineData", { value: map[i].data, writable: false });

			if(map[i].data.hasOwnProperty(parentKey) && parentParentIdValues.indexOf(map[i].data[parentKey]) === -1) // map[i].data[parentKey] !== undefined && map[i].data[parentKey] !== null)
				continue;

			finalData.push(map[i]);
		}

		Utils.sortChildren(finalData, idOrder);

		return finalData;
	},

	sortChildren: (data, idOrder) => {
		data.sort((a, b) => idOrder.indexOf(a.data.id) - idOrder.indexOf(b.data.id));
		
		for (const i of data) {
			Utils.sortChildren(i.children, idOrder);
		}

	},

    // TODO: Check if in use; there's another implementation of this in ListUtils.
	formatDataForList: (data) => {
		data = (Array.isArray(data)) ? data : [data];

		if(data.length === 0)
			return;

		const finalData = [];
		const idOrder   = [];

		for(const i in data) {
			const tempObject = {};

			tempObject.data = Object.assign({}, data[i]);
			tempObject.children = [];

			finalData.push(tempObject);

			idOrder.push(data[i].key || data[i].id);
		}

		finalData.sort((a, b) => idOrder.indexOf(a.data.key || a.data.id) - idOrder.indexOf(b.data.key || b.data.id));

		return finalData;
	},

    truncateDecimals: (number, n = 2, showOnEven = false) => {
		const integer = parseInt(number);
		number 		= parseFloat(parseFloat(number).toFixed(n));

		if(!showOnEven && integer == number)
            number = integer;

		return number == integer && !showOnEven ? number : number.toFixed(n);
    }, 
    
    mergeArrayElements: (array, toMerge) => {
        const newArray = [];

        for(let i = 0; i < array.length; i++) {
            const tempMerge = toMerge.find(mergeArr => mergeArr[0] === i);

            if(tempMerge !== undefined) {
                newArray.push(array.slice(i, i + tempMerge.length).reduce((a, c) => a + c));

                i += tempMerge.length - 1;
            } else {
                newArray.push(array[i]);
            }
        }

        return newArray;
    },

    cloneObject: (obj) => {
        if (typeof obj !== "object")
            return obj;

        if (Array.isArray(obj))
            return obj.slice(0);

        let i, clone = {};

        for(i in obj) {
            if (Array.isArray(obj[i]))
                clone[i] = obj[i].slice(0);
            else if(typeof(obj[i])==="object" && obj[i] !== null && !obj[i].nodeName)
                clone[i] = this.cloneObject(obj[i]);
            else
                clone[i] = obj[i];
        }

        return clone;
    },

    flipObject: (obj) => {
        const newObj = {};

        for(const key in obj)
            newObj[obj[key]] = key;
        
        return newObj;
    },

	translateFields: (obj, transl) => {
		const newObj = {};

		Object
			.keys(obj)
			.forEach(key => {
				if(!transl.hasOwnProperty(key)) {
					newObj[key] = obj[key];

					return;
				}

				newObj[transl[key]] = obj[key];
			});

		return newObj;
	},

    primes: (n, max = false) => {
        const p     = [];
        let l     = p.length;
        let test  = 3;
        let prime = true;

        p.push(2);

        while((!max && p.length < n) || (max && p[p.length - 1] < n)) {
            prime = true;

            l = p.length;
            
            for(let i = 0; i < l; i++) {
				if(test % p[i] === 0) {
					prime = false;
					break;
				}
			}

			if(prime)
				p.push(test);

			test += 2;
        }

        return p;
    },

    pfactorize: (x) => {
        const factors = [];
        const p       = Utils.primes(x, true);
        let i       = 0;

		while(x > 1) {
			if(x % p[i] == 0) {
				factors.push(p[i]);
				x = x / p[i];
			} else {
                i++;
            }
		}
		return factors;
	},

    detectBrowserByUserAgent: () => {
        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) > -1) {
            return "opera";
        } 

        if(navigator.userAgent.indexOf("Edg") > -1) {
            return "edge";
        } 

        if(navigator.userAgent.indexOf("Chrome") > -1) {
            return "chrome";
        } 
       
        if(navigator.userAgent.indexOf("Safari") > -1) {
            return "safari";
        } 
       
        if(navigator.userAgent.indexOf("Firefox") > -1) {
            return "firefox";
        } 
       
        if((navigator.userAgent.indexOf("MSIE") > -1) || (!!document.documentMode === true)) {
            return "ie";
        } 

        return undefined;
    },

    isMacCmdKeyCode: (keyCode) => {
        if(!window?.isMac) {
            return false;
        }

        // The cmd key code is browser-specific.
        const codes = {
            firefox: [224],
            opera: [17],
            safari: [91, 93],
            chrome: [91, 93]
        };

        return codes?.[window?.shortUserAgent ?? null]?.indexOf(keyCode) > -1;
    },

    isMac: () => {
        return navigator.userAgent.indexOf('Mac OS X') > -1;
    },

    initMacCmdListener: () => {
        window.macCmdPressed  = false;
        window.isMac          = false;
        window.shortUserAgent = Utils.detectBrowserByUserAgent();

        if(!Utils.isMac()) {
            return;
        }

        window.isMac = true;

        document.addEventListener("keydown", e => {
            if(Utils.isMacCmdKeyCode(e.keyCode)) {
                window.macCmdPressed = true;
            }
        });

        document.addEventListener("keyup", e => {
            if(Utils.isMacCmdKeyCode(e.keyCode)) {
                window.macCmdPressed = false;
            }
        });
	},

	startCase: (text) => {
	    if (!text || text.length === 0)
	        return text;
	    let words = text.split(' ');
	    words = words.map(el => Utils.capitalize(el));
	    return words.join(" ");
	},
	capitalize: (text) => {	
	    if (!text || text.length === 0)
	        return text;
	    return text.charAt(0).toUpperCase() + text.slice(1);
	},
    lowerCase: (text) => {
	    if (!text || text.length === 0)
	        return text;
	    return text.charAt(0).toLowerCase() + text.slice(1);
    },
	getNameFieldByUserLang: (userLang, fieldName) => {
		if (!userLang)
			return;
		if (userLang.startsWith('en_us'))
			return `${fieldName}_en_us`;
		if (userLang.startsWith('en_au'))
			return `${fieldName}_en_au`;
		if (userLang.startsWith('en_ca'))
			return `${fieldName}_en_ca`;
		const shortLangCode = userLang.substring(0, 2).toLowerCase();

		return  `${fieldName}_${shortLangCode}`;
	},
	importAllIcons: (r) => {
        const imgs = {};
        r.keys().map((item, index) => {
            imgs[item.replace('./', '')] = r(item).default; 
        });
        return imgs;
    },

    //params.fields structure must be - <the field name which contains class users id in userData>: <the field name containing user name we are going to change>
    //this approach is used if we are modifying listdata, which does not have users locked&company information or/and has multiple user fields with different names
    //the userData array must contain them (should contain complete user pool too)
    showLockedAndFreelancerUserTag: (row, params) => {

        const modifiedFields = Object.entries(params.fields).reduce((acc, [idField, fieldName]) =>  {
        	const lockedString = (((params.userData?.length > 0 ? params.userData.find(u => u.id == row[idField])?.locked : row.locked) > 0) && params.showLocked) 
                	? 
                `(${params.transl?.locked})` 
                	: 
            	'';
            const freelancerString = (params.userData?.length > 0 ? params.userData.find(u => u.id == row[idField])?.companies_id : row.companies_id) < 1 
            	? 
            	` (${params.transl?.freelancer}) `
	            	: 
	            ' ';

            return {...acc, [fieldName]: `${row[fieldName] || ''}${freelancerString}${lockedString}`.trim() }
        }, {});
        return {...row, ...modifiedFields};
    },    
	
	localDate: (param1, ...rest) => {
	    if ( param1?.length === 10 && rest.length === 0 && param1.match(/\d{4}-\d{2}-\d{2}/) ) {
	        //test for sql date
	        return new Date(param1 + "T00:00:00");
	    }
	    else if (param1 === undefined) {
	        //current time
	        return new Date();
	    }
	    else {
	        return new Date(param1, ...rest);
	    }
	},
};

export default Utils;
