import React, { Component } from 'react';
import { Portal } from '@mui/base';
import './SettingsSlider.css';
import DataHandler from './../general/DataHandler';
import { SettingsContext } from '../SettingsContext';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';

const styles = theme => ({

});

// SettingsSlider
class SettingsSlider extends Component {
    static contextType = SettingsContext;
    constructor(props, context) {
        super(props, context);
    }

    close = () => {
        this.props.onClose();
    }

    render() {
        const { children } = this.props;

        return (<Portal>
        <div className="SettingsSliderBackground" onClick={this.close} />
        <div className="SettingsSlider">
            <div className="SettingsSliderHeader">
                <CloseIcon onClick={this.close} />
            </div>
            <div className="SettingsSliderContent">
                {children}
            </div>
            <div className="SettingsSliderFooter">
                <CloseIcon onClick={this.close} />
            </div>
        </div>
        </Portal>);
    }
}

export default withStyles(styles)(SettingsSlider);