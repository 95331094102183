
// List
import PropsOnlyListRow from "../../list/PropsOnlyListRow";
import TextInputCell from '../../list/cells/TextInputCell';
import DateCell from "../../list/cells/DateCell";
import { formatInputNumber } from '../../helpers';
import moment from 'moment';

// import styles from "./ImportTasksReviewListRow.module.scss";
import { withSnackbar } from 'notistack';

class ImportHourEntriesReviewListRow extends PropsOnlyListRow {
	static rowDimensions = {
		height: "44px",
		lineHeight: "44px"
	};

	constructor(props, context) {
		super(props, {}, { childRowType: ImportHourEntriesReviewListRow }, "dialogs/imports/ImportHourEntriesReviewListRow");

		this.context = context;
	}

	defineCells() {
		const { data } = this.props;

		const start = moment(data.date + " " + data.start_time)

		const cells = {
			user_email:
				<TextInputCell
					listCellProps={{showTooltipForOverflownText: true}}
					value={data.user_email}
					name="user_email"
					editable={false}
				/>,
            project_number:
				<TextInputCell
					listCellProps={{showTooltipForOverflownText: true}}
					value={data.project_number}
					name="project_number"
					editable={false}
				/>,
			company:
				<TextInputCell
					listCellProps={{showTooltipForOverflownText: true}}
					value={data.company}
					name="company"
					editable={false}
				/>,
            date:
				<DateCell
					value={data.date}
					name="date"
					editable={false}
				/>,
            start_time:
				<TextInputCell
					value={start.format('LT')}
					name="start_time"
					editable={false}
					inputType="text"
				/>,
            hours:
				<TextInputCell
					listCellProps={{textAlign: "right"}}
					value={formatInputNumber(data.hours, "hours")}
					name="hours"
					editable={false}
				/>,
            jobtype:
				<TextInputCell
					listCellProps={{showTooltipForOverflownText: true}}
					value={data.jobtype_name}
					name="jobtype"
					editable={false}
				/>,
            task:
				<TextInputCell
					listCellProps={{showTooltipForOverflownText: true}}
					value={data.task_description}
					name="task"
					editable={false}
				/>,
            description:
				<TextInputCell
					listCellProps={{showTooltipForOverflownText: true}}
					value={data.description}
					name="description"
					editable={false}
				/>,
		};

		return cells;
	}
}

export default withSnackbar(ImportHourEntriesReviewListRow);