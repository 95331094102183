import { Button } from '@mui/material';
import React from 'react';
import NotificationContent from './NotificationContent';

class ErrorNotification extends React.Component {
    render() {
        const { innerRef, message, closeSnackbar, onClick, buttonText, key } = this.props;
        return (
            <NotificationContent innerRef={innerRef} variant="error">
                <span style={{ lineHeight: '48px' }}>{message || this.tr('An error occured')}</span>
                <Button
                    className="notificationButton"
                    style={{
                        float: 'right',
                        marginTop: 8,
                    }}
                    onClick={() => {
                        closeSnackbar(key);
                        if (onClick && typeof onClick === 'function') onClick();
                    }}
                >
                    {buttonText || this.tr('Dismiss')}
                </Button>
            </NotificationContent>
        );
    }
}

const ForwarderRefComponent = React.forwardRef((props, ref) => <ErrorNotification innerRef={ref} {...props} />);
export default ForwarderRefComponent;
