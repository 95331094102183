import { MoreHoriz, RemoveRedEye } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import moment from 'moment';
import colors from '../../colors';
import ContextMenu from '../../general/ContextMenu';
import CheckboxCell from '../cells/CheckboxCell';
import MultiLinkListCell from '../cells/MultiLinkListCell';
import StatusCell from '../cells/StatusCell';
import TextInputCell from '../cells/TextInputCell';
import LinkListCell from '../LinkListCell';
import PropsOnlyListRow from '../PropsOnlyListRow';

class InvoiceLogListRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, undefined, undefined, 'list/rows/InvoiceLogListRow');
    }

    getInvoiceStatusData = (row) => {
        const statuses = {
            1: { name: this.tr('Waiting'), color: '#ffb822' },
            2: { name: this.tr('Sent'), color: '#2d9ff7' },
            3: { name: this.tr('Credited'), color: '#716aca' },
            4: { name: this.tr('Paid'), color: colors.greenish_cyan },
            5: { name: this.tr('Draft'), color: '#979797' },
            6: { name: this.tr('Overdue'), color: '#f52b2b' },
        };
        let statusData = statuses[row.invoice_state] || { name: '-', color: '#ffb822' };
        if (row.invoice_bill_type == '2') statusData = statuses['5'];
        if (row.duedate < moment().format('YYYY-MM-DD') && row.invoice_state == '2' && (row.invoice_type == '1' || row.invoice_type == '3')) statusData = statuses['6'];
        return statusData;
    };

    defineCells() {
        const {
            data,
            columnWidthMap,
            rowProps: { logContext, currency },
        } = this.props;
        const cells = {};
        const {
            userObject: { dateFormat },
        } = this.context;

        const eventDate = moment.utc(data['stamp']);
        const invoiceCreationDate = moment(data['creationdate']);
        const invoiceDueDate = moment(data['duedate']);
        Object.keys(this.props.columnConfig).forEach((key) => {
            cells[key] = <TextInputCell width={columnWidthMap[key]} name={key} editable={false} value={data[key]} />;
        });
        return {
            ...cells,
            menu: (
                <ContextMenu
                    label={<MoreHoriz />}
                    //@ts-ignore
                    buttonProps={{ className: 'action-menu' }}
                    className="cell row-menu"
                    style={{ width: columnWidthMap['menu'] + 'px', flex: columnWidthMap['menu'] + ' 1 0px' }}
                    noExpandIcon
                >
                    <MenuItem
                        onClick={() => {
                            this.context.functions.updateView({
                                module: 'invoices',
                                action: 'view',
                                id: data.bills_id,
                            });
                        }}
                    >
                        <RemoveRedEye />
                        {this.tr('View invoice')}
                    </MenuItem>
                </ContextMenu>
            ),
            bills_id: (
                <LinkListCell urlHandler={() => `index.html?module=invoices&action=view&id=${data.bills_id}`} width={columnWidthMap.visible_id} value={data.visible_id} editable={false} noTab={true} />
            ),
            checked: <CheckboxCell width={columnWidthMap.checked} checked={this.props.checked} onClick={() => this.props.listRef.check(data.id)} />,
            event_status: <StatusCell width={columnWidthMap.event_status} displayData={logContext.getStatusData(data)} />,
            event_stamp: (
                <TextInputCell width={columnWidthMap.event_date} name={'event_stamp'} editable={false} value={eventDate.isValid() && eventDate.local().format(logContext.getStampFormat(data))} />
            ),
            message: <TextInputCell width={columnWidthMap.message} name={'message'} editable={false} value={logContext.getMessage(data, currency)} />,
            event: <TextInputCell width={columnWidthMap.event} name={'event'} editable={false} value={logContext.getEventDescription(data)} />,
            edited_by: <TextInputCell width={columnWidthMap.edited_by} name={'edited_by'} editable={false} value={data.users_name} />,
            invoice_date: <TextInputCell width={columnWidthMap.invoice_date} name={'invoice_date'} editable={false} value={invoiceCreationDate.isValid() && invoiceCreationDate.format(dateFormat)} />,
            due_date: <TextInputCell width={columnWidthMap.due_date} name={'due_date'} editable={false} value={invoiceDueDate.isValid() && invoiceDueDate.format(dateFormat)} />,
            invoice_status: <StatusCell width={columnWidthMap.invoice_status} displayData={this.getInvoiceStatusData(data)} />,
            invoice_account: (
                <LinkListCell
                    urlHandler={() => `index.html?module=customers&action=view&id=${data.invoice_account_id}`}
                    width={columnWidthMap.invoice_account}
                    value={data.invoice_account_name}
                    editable={false}
                    noTab={true}
                />
            ),
            invoice_projects: (
                <MultiLinkListCell
                    width={columnWidthMap.invoice_projects}
                    value={data.project_names}
                    editable={false}
                    noTab={true}
                    urlHandler={() => {
                        return (data.project_ids || []).split(',').map((pId) => `index.html?module=projects&action=view&id=${pId}`);
                    }}
                />
            ),
        };
    }
}

export default InvoiceLogListRow;
