import React from 'react';
import TaimerComponent from "../../TaimerComponent";


class PurchaseExpenseTranslations extends TaimerComponent {

    constructor(props, context) {

        super(props, context, "general/backendTranslations/PurchaseExpensePrint");

    }

    returnTranslations = (langs) => {
        return langs.reduce((acc, lang) => ({...acc, [lang]: {
            invoice_number: this.beTr('Invoice number', lang),
            expenses: this.beTr('Expenses', lang),
            customer: this.beTr('Customer', lang),
            project: this.beTr('Project', lang),
            date: this.beTr('Date', lang),
            description: this.beTr('Description', lang),
            payment_type: this.beTr('Payment type', lang),
            invoicable: this.beTr('Invoicable', lang),
            sum: this.beTr('Sum', lang),
            sum_with_wat: this.beTr('Sum with Vat%', lang),
            cost: this.beTr('Cost', lang),
            vat: this.beTr('VAT %', lang),
            total: this.beTr('Total', lang),
            yes: this.beTr('Yes', lang),
            no: this.beTr('No', lang),
            nav_id: this.beTr('Nav id', lang),
            group_number: this.beTr('Group number', lang),
            attachments: this.beTr('Attachments', lang),
        }}), {})
    };

    render() {
        return null;  
   }
}

export default PurchaseExpenseTranslations;