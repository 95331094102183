import React, { useState } from 'react';
import ToggleableContainer from './ToggleableContainer';

import styles from './ToggleableContainerList.module.scss';

const ToggleableContainerList = (props) => {
    const { sections, renderContentForSection, containerRef } = props;
    const [openStates, onOpenStateChanged] = useState({});
    return (
        <div className={styles.closeableSectionView}>
            {sections.map((section, index) => {
                const sectionHidden = !section || (typeof section.hidden == 'function' ? section.hidden() : section.hidden) || (section.hideIfEmpty && section.getCount && section.getCount() == 0);
                const nextSection = sections[index + 1];
                return (
                    <ToggleableContainer
                        ref={containerRef}
                        key={section.key}
                        section={section}
                        title={`${section.title}${section.getCount && section.showCount ? ` (${section.getCount()})` : ''}`}
                        hidden={sectionHidden}
                        nextOpen={openStates[nextSection?.key] == true}
                        onOpenStateChanged={(sectionKey, open) => onOpenStateChanged({ ...openStates, [sectionKey]: open })}
                    >
                        {(selectedSection) => renderContentForSection && <div className={styles.content}>{renderContentForSection(selectedSection)}</div>}
                    </ToggleableContainer>
                );
            })}
        </div>
    );
};

export default ToggleableContainerList;
