import React from "react";

import TaimerComponent from "../TaimerComponent";
import DataHandler from "../general/DataHandler";

import "./TabMemo.css";
import { SettingsContext } from "../SettingsContext";
import MemoView from "../general/MemoView";

class TabMemo extends TaimerComponent {
  static contextType = SettingsContext;

  constructor(props, context) {
    super(props, context, "users/TabMemo");

    this.state = {
      memos: []
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this._getMemos();
  }

  componentWillUnmount = () => {
    super.componentWillUnmount();
  };

  _getMemos = () => {
    const { id } = this.props;
    DataHandler.get({ url: `users/${id}/memo` }).done(memos => {
      memos = memos.map(m => {
        if (m.users_company < 1)
          m.users_name += ` (${this.tr("freelancer")})`;
        return m;
      });
      this.setState({ memos });
    });
  };

  saveMemo = selectedMemo => {
    const { id, enqueueSnackbar } = this.props;
    const req =
      selectedMemo.id == "-1"
        ? DataHandler.put({ url: `users/${id}/memo` }, selectedMemo)
        : DataHandler.put(
            { url: `users/${id}/memo/${selectedMemo.id}` },
            selectedMemo
          );
    req
      .then(() => {
        enqueueSnackbar(this.tr("Memo saved successfully!"), {
          variant: "success"
        });
        setTimeout(() => {
          this._getMemos();
        }, 1000);
        
      })
      .catch(err => {
        enqueueSnackbar(this.tr("Error saving memo!"), { variant: "error" });
      });
  };

  deleteMemo = selectedMemo => {
    const { id, enqueueSnackbar } = this.props;
    DataHandler.delete({ url: `users/${id}/memo/${selectedMemo.id}` })
      .then(() => {
        enqueueSnackbar(this.tr("Memo deleted successfully!"), {
          variant: "success"
        });
        setTimeout(() => {
          this._getMemos();
        }, 1000);
      })
      .catch(err => {
        enqueueSnackbar(this.tr("Error deleting memo!"), {
          variant: "error"
        });
      });
  };

  render() {
    return (
      <div id="users-memo">
        <MemoView
          {...this.props}
          avatarIdKey={"creator_id"}
          memos={this.state.memos}
          saveMemo={this.saveMemo}
          deleteMemo={this.deleteMemo}
        />
      </div>
    );
  }
}

export default TabMemo;
