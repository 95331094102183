import React from "react";
import DataHandler from '../general/DataHandler';
import ErrorView from "./ErrorView";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorTime: "" };
    this.clearError = this.clearError.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    DataHandler.post({ url: `auth/logError` }, { errormsg: error.stack }).done(
      response => {
        this.setState({ errorTime: response });
      }
    );
  }

  clearError() {
    this.setState({ hasError: false });
  }


  render() {
    if (this.state.hasError) 
      return <ErrorView clearError={this.clearError} updateView={this.props.updateView} errorTime={this.state.errorTime} />
    
    return this.props.children;
  }
}

export default ErrorBoundary;
