import React from 'react';
import PropTypes from "prop-types";

/* material ui */
import { Button } from '@mui/material';

/* local components */
import TaimerComponent from "../TaimerComponent";
import OutlinedField from "./../general/OutlinedField";

/* css */
import './AddStage.css';

/* data backend */
import DataHandler from './../general/DataHandler';
 
class AddStage extends TaimerComponent {
    state = {
        "title": ""
    }
    
    constructor(props, context) {
        super(props, context, "projects/AddStage");

        ["addNewStage", "onChange"].forEach(e => this[e] = this[e].bind(this));
        
        this.validation = {
            "title": React.createRef(),
        };
    }
    onChange (evt) {
        const {name, value} = evt.target;
        this.setState({[name]: value});
    }
    addNewStage () {
        this.validation.title.current.isValid() && this.props.onAdd(this.state);
    }
    
    render () {
        const {onCancel} = this.props;
        
        return (
            <div className="add-stage">
                <div className="details">
                    <OutlinedField label={this.tr("Stage Name")} name="title" value={this.state.title} onChange={this.onChange} ref={this.validation.title} fullWidth validation={['empty']} />
                </div>
                <div className="controls">
                    <Button size="large" onClick={onCancel}>{this.tr('Cancel')}</Button>
                    <Button size="large" color="primary" onClick={this.addNewStage}>{this.tr('Add')}</Button>
                </div>
            </div>
        );
    }
    
}

export default AddStage;