import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';

import DataHandler from '../../general/DataHandler';

import { withSnackbar } from 'notistack';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { MoreHoriz, ArrowBack } from '@mui/icons-material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { CircularProgress } from '@mui/material';
import { ReactComponent as Loading } from '../../dashboard/insights/img/loading.svg';

import ContextMenu from '../../general/ContextMenu';
import { MenuItem } from '@mui/material';
import List from "./../../list/List";
import SettingsList from "./../../list/lists/SettingsList";
import PropsOnlyListRow from "./../../list/PropsOnlyListRow";
import TextInputCell from "./../../list/cells/TextInputCell";
import TreeDropdownCell from './../../list/cells/TreeDropdownCell';
import TreeStructureIndicatorCell from "./../../list/cells/TreeStructureIndicatorCell";
import AutoCompleteCell from "./../../list/cells/AutoCompleteCell";
import EditableStatusCell from "./../../list/cells/EditableStatusCell";
import StatusCell from "./../../list/cells/StatusCell";
import LinkListCell from "./../../list/LinkListCell";
import ListCell from "./../../list/ListCell";
import TreeOption from "./../../list/TreeOption";
import CreateBobOptions from "./../../list/CreateBobOptions";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import { FlexContainer as Row, FlexChild as Col } from "./../../general/FlexUtils";

import { treeFormatDataForList } from "./../../list/ListUtils";


import "./Dimensions.css";
import "./../Settings.css";
import colors from '../../colors';


const StyledTabs = withStyles({
    indicator: {
        display: 'none',
        "&.with-indicator": {
            display: 'block',
            backgroundColor: "#003A78",
            height: "3px",
        },
        "&.rounded-indicator": {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
        },
    }
})(Tabs);


class ContextMenuCell extends React.Component {
    constructor(props) {
        super(props);

        this.handleItemClick = this.handleItemClick.bind(this);
    }


    handleItemClick(item) {
        if(!item.hasOwnProperty("onClick")) {
            return;
        }

        item.onClick(item);
    }


    render() {
        return (
            <ListCell permanentEditMode={true} width={this.props.width}>
                <ContextMenu label={<MoreHoriz />} buttonProps={{ className: 'action-menu' }} className="cell row-menu" style={{ width: "100%" }} noExpandIcon>
                    {this.props.items.map(item => {
                        return (
                            <MenuItem onClick={() => this.handleItemClick(item)}>{item.label}</MenuItem>
                        );
                    })}
                </ContextMenu>
            </ListCell>
        );
    }
}

ContextMenuCell.defaultProps = {
    items: [],
    onClick: () => {}
};


class DimensionRow extends PropsOnlyListRow {
    constructor(props, state = {}, conf = { childRowType: DimensionRow }, namespace = "list/rows/DimensionRow") {
        super(props, state, conf, namespace);
    }

    defineCells() {
        const { 
            data, 
            rowProps 
        } = this.props;

        const listData  = this.props.listRef.getData();
        const hasNewRow = Boolean(listData.find(d => parseInt(d.id) < 0));
        const editable  = !rowProps.dimensionLevelList || (!hasNewRow || data.id < 0);

        let menu;

        if((rowProps.emptyMenuCell && data.id > 0) || ["units", "teams"].indexOf(data.id) > -1 || data?.parent_dimensions_id > 0) {
            menu = <ListCell editable={false} />;
        } else {
            menu = data.notSaved || parseInt(data.id) < 0 
                ? 
                <ListCell permanentEditMode={true} onlyDisplay={true}>
                    {<CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.create()} />}
                    {<Cancel className="cancelIcon" onClick={() => {
                        this.props.listRef.removeRow(data.id);
                    }} />}
                </ListCell> 
                : 
                <ContextMenuCell 
                    items={[{ onClick: () => this.delete(), label: this.tr("Delete") }]} />
        }

        return {
            menu: menu,
            name: <TreeStructureIndicatorCell
                childrenVisible={true}
                listCellProps={{ alignCell: true, textAlign: "left" }}
                rowData={this.props}
                showExpander={false}
                value={data.name}>
                <TextInputCell 
                    onEdit={(value) => {
                        this.setDataAndUpdate({ name: value });
                    }}
                    listCellProps={{
                        inEditMode: data.notSaved || parseInt(data.id) < 0,
                        editable: !data.default && editable, 
                        style: { height: "44px", width: "100%" }, 
                    }} 
                    value={data.name} />
            </TreeStructureIndicatorCell>,

            level: <TextInputCell editable={false} value={data.level} />,
            code: <TextInputCell 
                editable={editable}
                value={data.code} 
                listCellProps={{ 
                    inEditMode: data.notSaved || parseInt(data.id) < 0
                }} 
                onEdit={code => {
                    this.setDataAndUpdate({ code: code });
                }}
            />
        };
    }
}

class DimensionSettings extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/DimensionSettings");

        this.list            = React.createRef();
        this.newLevelCounter = 0;

        this.state = {
            dimensions: []
        };
        
        [
            "addLevel", 
            "handleLevelCreate", 
            "handleLevelUpdate",
            "handleLevelCancel",
            "handleLevelDelete"
        ].forEach(f => this[f] = this[f].bind(this));
    }


    async handleLevelCreate(data) {
        if(this.creatingNew) {
            return;
        }

        try {
            this.creatingNew = true;

            const dimensions = await DataHandler.post(
                { url: `settings/company/${this.props.company}/dimensions` }, 
                { dimension: data }
            );

            this.props.setDimensionsState(dimensions);
        } catch(e) {
            console.warn(e);
        } finally {
            this.creatingNew = false;
        }
    }


    async handleLevelUpdate(data) {
        // There are situations where a dimension level
        // can get a negative parent id, like when adding
        // a parent level and editing its child before 
        // saving the parent level. A negative parent id
        // should never make its way to the backend, so
        // let's strip that data out whenever we catch it.
        
        const dataToSend = cloneDeep(data);

        if(parseInt(dataToSend.parent_dimensions_id) < 0) {
            delete dataToSend.parent_dimensions_id;
        }
       
        try {
            await DataHandler.put(
                { url: `settings/company/${this.props.company}/dimensions/${dataToSend.id}` }, 
                { dimension: dataToSend }
            );

            this.props.setDimensionsState(cloneDeep(this.props.dimensions)
                .map(d => d.id === data.id ? data : d)
            );
        } catch(e) {
            console.warn(e);
        }
    }


    async handleLevelDelete(data) {
        try {
            await DataHandler.delete({
                url: `settings/company/${this.props.company}/dimensions/${data.id}`
            });

            this.props.setDimensionsState(this.props.dimensions
                .filter(d => d.id !== data.id)
                .map((d, i) => ({ ...d, level: i + 1})));
        } catch(e) {
            const { error, information } = e.responseJSON;
            const { enqueueSnackbar }    = this.props;

            if(error === "DIMENSION_IN_USE") {
                const msg = this.tr("This dimension has the following items, and therefore can not be deleted: ");

                enqueueSnackbar(`${msg} ${information}`, {
                    variant: "error"
                });

                return;
            }

            enqueueSnackbar(this.tr("An error occured while deleting the dimension"), {
                variant: "error"
            });
        }
    }


    handleLevelCancel(data) {
        const dimensions = this.props.dimensions
            .sort((a, b) => (a.level < b.level) ? -1 : 1)
            .filter(d => d.id !== data.id)
            .map((d, index, curArr) => {
                d.level = index + 1;

                if(index > 0) {
                    d.parent_dimensions_id = curArr[index - 1].id;
                }

                return d;
            });

        this.props.setDimensionsState(dimensions)
    }


    addLevel() {
        let dimensions = cloneDeep(this.props.dimensions);

        if(dimensions.length >= 4) {
            // TODO: Inform the user about this limit for dimensions.
            return;
        }

        if(dimensions.filter(s => s.notSaved).length > 0) {
            // TODO: Inform the user that there's a non-saved dimension.
            return;
        }

        const currentTop   = dimensions.find(d => d.level === 1);
        const nextId       = String(--this.newLevelCounter);
        const newDimension = { 
            id: nextId, 
            level: 1, 
            name: "top_dimension",
            notSaved: true
        };

        // Temp id before saving into the db.
        currentTop.parent_dimensions_id = nextId; // ref

        dimensions = dimensions.map(d => {
            d.level = ++d.level;

            return d;
        });

        dimensions.unshift(newDimension);

        this.props.setDimensionsState(dimensions)
    }


    render() {
        const commonConf = {
            showMenu: false, 
            resizeable: false, 
            showResizeMarker: false, 
            moveable: false, 
            hideable: false,
            visibleInToolbar: true
        };

        const { dimensions } = this.props;
        const { 
            dimension_limit,
            force_teams_branchofbusiness_for_projects, 
            mandatory_team_selection_for_projects 
        } = this.props.settings;

        return (
            <React.Fragment>
                <Row style={{ alignItems: "flex-start" }}>
                    <Col weight={3}>
                        <h3>{this.tr("Define Dimensions")}</h3>
                        <p>{this.tr("You can use dimensions to group and filter data and reports. By default you have 2 levels: Unit and Team. A user can be added to one Team and one Unit can have multiple Teams. In addition you can add two more 1st and 2nd level dimensions and name them according to your needs. You can filter data using these dimensions through-out the software.")}</p>
                    </Col>
                    <Col weight={1}>
                        {dimensions.length > 0 && dimensions.length < dimension_limit && <Button className="green" style={{ float: "right" }} onMouseUp={this.addLevel} size="large">{this.tr("Add level")}</Button>}
                    </Col>
                </Row>

                <List
                    ref={this.list}
                    className="settingsList"
                    noStateData={true}
                    fluid={true}
                    treeData={true}
                    idType="string"
                    listRowType={DimensionRow}
                    parentKey="parent_dimensions_id"
                    height="auto"
                    manualCreate={true}
                    hideHeader={dimensions.length === 0}
                    rowCallbacks={{
                        onCreate: this.handleLevelCreate,
                        onUpdate: this.handleLevelUpdate,
                        onDelete: this.handleLevelDelete,
                        cancel: this.handleLevelCancel
                    }}
                    rowProps={{
                        dimensionLevelList: true
                    }}
                    onDataChange={event => {
                        const { origin, data: dimensions } = event;

                        if(origin === "removeRow") {
                            // TODO: Fix cases where the 
                            // row is deleted from 
                            // between two rows.
                            this.props.setDimensionsState(
                                dimensions.map(d => {
                                    d.level = --d.level;
                                    return d;
                                })
                            );
                        }
                    }}
                    data={dimensions}
                    columns={[
                        {  name: "menu", header: "", width: 20, ...commonConf },
                        {  name: "name", header: this.tr("Dimension name"), width: 200, ...commonConf },
                        {  name: "level", header: this.tr("Level"), width: 50, ...commonConf },
                        // {  name: "code", header: this.tr("code"), width: 80, ...commonConf },
                    ]}
                />

                <Row>
                    <Col weight={3}>
                        <p style={{ flex: "3 1 0px" }}>{this.tr("Require a team to be selected when creating a new project")}</p>
                    </Col>
                    <Col weight={5}>
                        <Switch 
                            color="primary" 
                            checked={parseInt(mandatory_team_selection_for_projects) === 1} 
                            onChange={async (_, checked) => {
                                try {
                                    const data = {
                                        mandatory_team_selection_for_projects: checked ? 1 : 0
                                    };

                                    await DataHandler.patch({ url: `settings/company/${this.props.company}/dimensions/settings` }, data);

                                    this.props.setDimensionSettings(data);
                                } catch(e) {
                                    console.warn(e);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col weight={3}>
                        <p style={{ flex: "3 1 0px" }}>{this.tr("Automatically use the selected team's category as the project's category")}</p>
                    </Col>
                    <Col weight={5}>
                        <Switch 
                            color="primary" 
                            checked={parseInt(force_teams_branchofbusiness_for_projects) === 1} 
                            onChange={async (_, checked) => {
                                try {
                                    const data = {
                                        force_teams_branchofbusiness_for_projects: checked ? 1 : 0
                                    };

                                    await DataHandler.patch({ url: `settings/company/${this.props.company}/dimensions/settings` }, data);

                                    this.props.setDimensionSettings(data);
                                } catch(e) {
                                    console.warn(e);
                                }
                            }}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}


DimensionSettings.defaultProps = {
    company: 1,
    dimensions: [],
    settings: {},
    setDimensionsState: () => {},
    refreshDimensions: () => {},
    viewProps: {},
    dimensionId: undefined
};


class DimensionUserRow extends DimensionRow {
    constructor(props) {
        super(props, {}, {}, "list/rows/DimensionUserRow");
    }


    defineCells() {
        const parentCells = super.defineCells();
        const { 
            data,
            sharedData,
            columnWidthMap,
            rowProps
        } = this.props;

        let firstnameProps = {};

        if(data.id < 0) {
            firstnameProps.overrideWidth = 0;
        }

        return {
            menu: parentCells.menu,
            lastname: data.id > 0 
                ? 
                <ListCell 
                    value={data.lastname} 
                    editable={false} /> 
                : 
                <AutoCompleteCell 
                    autoCompleteData={sharedData.freeUsers}
                    overrideWidth={columnWidthMap.lastname 
                    + columnWidthMap.firstname}
                    value={data.users_id}
                    onEdit={user => this.setDataAndUpdate({
                        firstname: user.firstname,
                        lastname: user.lastname,
                        users_id: user.id,
                        email: user.email,
                        locked: user.locked
                    })}
                />,
            firstname: <ListCell 
                value={data.firstname} 
                editable={false} 
                {...firstnameProps} />,
            dimension_teams_id: <AutoCompleteCell 
                value={data.id > 0 
                ? 
                data.dimension_teams_id 
                : 
                rowProps.teamId}
                editable={data.id > 0}
                autoCompleteData={sharedData.teams} 
                onEdit={value => {
                    this.setDataAndUpdate({ 
                        dimension_teams_id: value.id 
                    });
                }}
            />,
            email: <LinkListCell 
                value={data.email} 
                urlHandler={value => `mailto:${value}`}
                editable={false}
            />,
            status: <EditableStatusCell 
                value={data.locked}
                editable={false}
                options={sharedData.statuses}
            />,
            type: <ListCell editable={false} />,
            added_to_team: <ListCell editable={false} />,
            previous_team: <ListCell editable={false} />,
        };
    }
}


class DimensionUserList extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/DimensionUserList"); 

        this.list  = React.createRef();
        this.state = {
            users: [],
            teams: []
        };

        this.statuses = [
            {id: '-1', name: this.tr('Active'), label: this.tr('Active'), value: '-1', color: colors.greenish_cyan},
            {id: '0', name: this.tr('Active'), label: this.tr('Active'), value: '0', color: colors.greenish_cyan},
            {id: '1', name: this.tr('Locked'), label: this.tr('Closed'), value: '1', color: "#f52b2b"},
        ];

        [
            "addUser",
            "updateUser",
            "deleteUser",
            "fetchData"
        ].forEach(f => this[f] = this[f].bind(this));

        this.fetchData();
    }


    async fetchData() {
        try {
            this.setState({ 
                users: await DataHandler.get({ 
                    url: `settings/company/${this.props.company}/dimensions/users/${this.props.teamId}` 
                }),
                freeUsers: (await DataHandler.get({ 
                    url: `settings/company/${this.props.company}/dimensions/users` 
                })).map(u => {
                    u.label = u.name;

                    return u;
                }),
                teams: await DataHandler.get({ 
                    url: `settings/company/${this.props.company}/dimensions/teams` 
                }),
            });
        } catch(e) {
            console.warn(e);
        }
    }


    addUser() {
        this.list.current.addNewRow();
    }


    async updateUser(data, row) {
        try {
            const id = data.id < 0 
                ? 
                data.users_id 
                : 
                data.id;

            await DataHandler.patch({
                url: `settings/company/${this.props.company}/dimensions/users/${id}`
            }, {
                dimension_teams_id: data.dimension_teams_id
            });

            row.setData({ 
                id: data.id < 0 
                ? 
                data.users_id 
                : 
                data.id
            });
        } catch(e) {
            console.warn(e);
        }
    }


    async deleteUser(data, row) {
        data.dimension_teams_id = 0;

        this.updateUser(data, row);

        row.props.listRef.removeRow(data.id);
    }


    render() {
        const { users }  = this.state;
        const { teamId } = this.props;
        const commonConf = {
            showMenu: false, 
            resizeable: false, 
            showResizeMarker: false, 
            moveable: false, 
            hideable: false,
            visibleInToolbar: true
        };

        return (
            <React.Fragment>
                <Row style={{ alignItems: "flex-start" }}>
                    <Col weight={3}>
                        {teamId && (
                            <Button>
                                <ArrowBack onClick={this.props.onArrowBack} />
                            </Button>
                        )}
                        <p>{this.tr("Manage the members of this team. A user can only be a member of one team at a time.")}</p>
                    </Col>
                    <Col weight={1}>
                        <Button className="green" style={{ float: "right" }} onMouseUp={this.addUser} size="large">{this.tr("Add user")}</Button>        
                    </Col>
                </Row>
                
                <List
                    ref={this.list}
                    className="settingsList"
                    noStateData={true}
                    fluid={true}
                    idType="string"
                    listRowType={DimensionUserRow}
                    height="auto"
                    manualCreate={true}
                    rowCallbacks={{
                        onCreate: this.updateUser,
                        onUpdate: this.updateUser,
                        onDelete: this.deleteUser
                    }}
                    sharedData={{
                        teams: this.state.teams,
                        statuses: this.statuses,
                        freeUsers: this.state.freeUsers
                    }}
                    rowProps={{
                        teamId: teamId,
                        emptyMenuCell: false 
                    }}
                    newRow={{
                        users_id: undefined,
                        dimension_teams_id: teamId
                    }}
                    data={users}
                    columns={[
                        { name: "menu", header: "", width: 40, ...commonConf },
                        { name: "lastname", header: this.tr("Last name"), width: 150, ...commonConf },
                        { name: "firstname", header: this.tr("First name"), width: 150, ...commonConf },
                        { name: "dimension_teams_id", header: this.tr("Team"), width: 120, ...commonConf },
                        { name: "email", header: this.tr("Email"), width: 120, ...commonConf },
                        { name: "status", header: this.tr("Status"), width: 90, ...commonConf },
                        // { name: "type", header: this.tr("Type"), width: 60, ...commonConf },
                        // { name: "added_to_team", header: this.tr("Added to team"), width: 80, ...commonConf },
                        // { name: "previous_team", header: this.tr("Previous team"), width: 80, ...commonConf },
                    ].filter(c => c)}
                />
            </React.Fragment>
        );
    }
}


DimensionUserList.defaultProps = {
    teamId: undefined,
    onArrowBack: () => {}
};


class DimensionItemRow extends DimensionRow {
    constructor(props) {
        super(props, {}, {}, "list/rows/DimensionItemRow");
    }


    defineCells() {
        const { 
            data, 
            sharedData,
            rowProps
        } = this.props;

        const parentCells        = super.defineCells();
        const showChildrenTransl = rowProps.level > 3 
            ? 
            this.tr("Show children")
            :
            this.tr("Show units");
        
        return {
            ...parentCells,
            parent: <AutoCompleteCell 
                value={parseInt(data.parent_dimension_items_id) > 0
                    ?
                    data.parent_dimension_items_id
                    :
                    0}
                autoCompleteData={sharedData.parentDimensionItems}
                onEdit={value => {
                    this.setDataAndUpdate({ 
                        parent_dimension_items_id: value.id 
                    });
                }}
            />,
            children: data.id < 0 ? <AutoCompleteCell
                autoCompleteData={sharedData.children}
                value={sharedData.children.filter(t => {
                    return data.children.indexOf(t.id) > -1;
                })}
                multiple={true}
                onEdit={values => {
                    this.setDataAndUpdate({ 
                        children: values.map(t => t.id) 
                    });
                }}
           /> : <LinkListCell 
                editable={false}
                value={data.child_count}
                valueHandler={value => `${showChildrenTransl} (${value})`}
                urlHandler={() => "/"}
                handleClick={() => {
                    this.props.rowCallbacks.showChildren(data.id);
                }}
            />
        };
    }
}


class DimensionItems extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/DimensionItems");

        this.list  = React.createRef();
        this.state = {
            childrenVisible: false,
            childDimension: undefined,
            level: undefined,
            items: [],
            dimensions: [],
            parentDimensionItems: [],
            children: []
        };

        [
            "fetchData",
            "determineChildDimension",
            "determineLevel",
            "showChildren",
            "hideChildren",
            "handleDelete"
            // "showUsers",
            // "hideUsers"
        ].forEach(f => this[f] = this[f].bind(this));

        this.fetchData();
    }


    componentDidUpdate(prevProps, prevState) {
        if(this.props.dimensionId !== prevProps.dimensionId) {
            this.fetchData();
        }
    }


    determineLevel(dimensions) {
        let level   = 0;
        let current = dimensions
            .find(d => d.id === this.props.dimensionId);

        while(current) {
            ++level;

            current = dimensions
                .find(d => d.parent_dimensions_id === current.id);
        }

        return level;
    }


    determineChildDimension() {
        const { dimensions } = this.state;
        const current = dimensions
            .find(d => d.id === this.props.dimensionId);

        return dimensions
            .find(d => d.parent_dimensions_id === current.id);
    }


    showChildren(dimensionItemId) {
        const childDimension = this.determineChildDimension(this.state.dimensions);

        this.setState({ 
            childDimension: childDimension.id,
            childrenVisible: true,
            parentDimensionItemId: dimensionItemId
        });
    }


    hideChildren() {
        this.setState({ 
            childrenVisible: false,
            childDimension: undefined
        }, this.fetchData);
        // Refresh so we'll see the potential 
        // changes made in child components
    }


    async handleDelete(data) {
        try {
            await DataHandler.delete({ 
                url: `settings/company/${data.companies_id}/dimensions/items/${data.id}` 
            }); 

            this.list.current.removeRow(data.id);
        } catch(e) {
            const { error, information } = e.responseJSON;
            const { enqueueSnackbar }    = this.props;

            if(error === "ITEM_IN_USE") {
                const msg      = this.tr("This item is in use, and therefore can not be deleted.");
                const unitPart = this.tr("The following units belong to the item: ");
                const itemPart = this.tr("The following items belong to the item: ");

                enqueueSnackbar(`${msg}`, {
                    variant: "error",
                    autoHideDuration: 5000
                });

                if(information.units.length > 0) {
                    enqueueSnackbar(`${unitPart} ${information.units}`, {
                        variant: "warning",
                        autoHideDuration: 5000
                    });
                }

                if(information.items.length > 0) {
                    enqueueSnackbar(`${itemPart} ${information.items}`, {
                        variant: "warning",
                        autoHideDuration: 5000
                    });
                }

                return;
            }

            enqueueSnackbar(this.tr("An error occured while deleting the unit"), {
                variant: "error"
            });
        }
    }


    async fetchData() {
        const { parentDimensionItemId } = this.props;
        const baseUrl = `settings/company/${this.props.company}/dimensions`;

        try {
            const dimensions = await DataHandler.get({ url: baseUrl });
            const level      = this.determineLevel(dimensions);
            const items      = (await DataHandler.get({ 
                url: `${baseUrl}/items/${this.props.dimensionId}` 
            })).filter(item => {
                return !parentDimensionItemId 
                    || 
                    item.parent_dimension_items_id === parentDimensionItemId;
            });

            const parentId = dimensions
                .find(d => d.id === this.props.dimensionId)
                .parent_dimensions_id;

            const parentDimensionItems = parseInt(parentId) > 0 ? await DataHandler.get({ 
                url: `${baseUrl}/items/${parentId}` 
            }) : [];

            const potentialChildItems = await DataHandler.get({
                url: `${baseUrl}/items/potential_children/${this.props.dimensionId}` 
            });

            this.setState({
                level: level,
                dimensions: dimensions,
                items: items,
                parentDimensionItems: parentDimensionItems,
                children: potentialChildItems
            });
        } catch(e) {
            console.warn(e);
        }
    }


    render() {
        const { 
            items, 
            level,
            dimensions,
            parentDimensionItems,
            children,
            childDimension,
            childrenVisible,
            parentDimensionItemId
        }  = this.state;

        const thisD = dimensions
            .find(d => d.id === this.props.dimensionId) || {};

        const childrenHeaderText = level > 3 
            ? 
            this.tr("Associated child items") 
            : 
            this.tr("Associated units");

        const descriptionText = this.tr("This is an extra dimension that you have created. This dimension is level ##{DO_NOT_ERASE_OR_TRANSLATE_THIS_PART}## in your dimension levels.")
            .replace("##{DO_NOT_ERASE_OR_TRANSLATE_THIS_PART}##", level !== undefined ? String(dimensions.length - level + 1) : "#");
        const dimensionName = thisD?.name || "";
        let parentName      = ""; 
        const nameTranslation = this.tr("name");

        if(level < dimensions.length) {
            const parent = dimensions
                .find(d => d.id === thisD.parent_dimensions_id);

            parentName = parent !== undefined
                ? parent?.name || ""
                : "";
        }

        const commonConf = {
            showMenu: false, 
            resizeable: false, 
            showResizeMarker: false, 
            moveable: false, 
            hideable: false,
            visibleInToolbar: true
        };

        const addText = this.tr("Add");

        return (
            <React.Fragment>
                {!childrenVisible && (
                    <React.Fragment>
                        <Row style={{ alignItems: "flex-start" }}>
                            <Col weight={3}>
                                {this.props.parentDimensionItemId && (
                                    <Button>
                                        <ArrowBack onClick={this.props.onArrowBack} />
                                    </Button>
                                )}
                                {!this.props.parentDimensionItemId && <h3>{dimensionName}</h3>}
                                {!this.props.parentDimensionItemId && <p>{descriptionText}</p>}
                            </Col>
                            <Col weight={1}>
                                <Button 
                                    className="green" 
                                    style={{ float: "right" }} 
                                    onMouseUp={() => this.list.current.addNewRow()} 
                                    size="large">
                                    {`${addText} ${dimensionName}`}
                                </Button>
                            </Col>
                        </Row>
                        <List
                            ref={this.list}
                            className="settingsList"
                            noStateData={true}
                            fluid={true}
                            idType="string"
                            listRowType={DimensionItemRow}
                            height="auto"
                            data={items}
                            hideHeader={items.length === 0}
                            manualCreate={true}
                            sharedData={{
                                parentDimensionItems: [{ id: 0, value: 0, name: this.tr("(None)") }, ...parentDimensionItems],
                                children: children
                            }}
                            newRow={{
                                name: "",
                                level: "",
                                code: "",
                                parent_dimension_items_id: 
                                    this.props.parentDimensionItemId,
                                children: [],
                                child_count: 0,
                                companies_id: this.props.company
                            }}
                            rowProps={{
                                emptyMenuCell: false,
                                level: level
                            }}
                            rowCallbacks={{
                                onCreate: async (data, row) => {
                                    data.dimension_id = this.props.dimensionId;

                                    try {
                                        const id = await DataHandler.post({ 
                                            url: `settings/company/${this.props.company}/dimensions/items` 
                                        }, data);

                                        row.setData({ 
                                            id: id, 
                                            child_count: data.children.length 
                                        });
                                    } catch(e) {
                                        console.warn("taimer-debug", e);
                                    }
                                },
                                onUpdate: async (data, row) => {
                                    try {
                                        await DataHandler.patch({ 
                                            url: `settings/company/${this.props.company}/dimensions/items/${data.id}` 
                                        }, data);
                                    } catch(e) {
                                        console.warn("taimer-debug", e);
                                    }
                                },
                                onDelete: (data, row) => {
                                    if(this.props.parentDimensionItemId === undefined) {
                                        this.handleDelete(data);
                                    } else {
                                        row.setDataAndUpdate({ parent_dimension_items_id: 0 }); 
                                        row.removeFromList();
                                    }
                                },
                                showChildren: this.showChildren
                            }}
                            columns={[
                                { name: "menu", header: "", width: 20, ...commonConf },
                                { name: "name", header: `${dimensionName} ${nameTranslation}`, width: 200, ...commonConf },
                                (level < dimensions.length) 
                                    ? 
                                { name: "parent", header: parentName, width: 100, ...commonConf } 
                                : 
                                null,
                                { name: "code", header: this.tr("Code"), width: 80, ...commonConf },
                                { name: "children", header: childrenHeaderText, width: 80, ...commonConf },
                            ].filter(c => c)}
                        />
                    </React.Fragment>
                )}
                {childrenVisible && childDimension !== "units" && (
                    <DimensionItems 
                        {...this.props} 
                        dimensionId={childDimension}
                        parentDimensionItemId={parentDimensionItemId}
                        onArrowBack={this.hideChildren}
                    />
                )}
                {childrenVisible && childDimension === "units" && (
                    <DimensionUnits
                        {...this.props} 
                        dimensionId={"units"}
                        parentDimensionItemId={parentDimensionItemId}
                        onArrowBack={this.hideChildren}
                    />
                )}
            </React.Fragment>
        );
    }

}

DimensionItems.defaultProps = {
    dimensionId: undefined,
    parentDimensionItemId: undefined,
    onArrowBack: () => {}
};


class DimensionUnitRow extends DimensionRow {
    constructor(props) {
        super(props, {}, {}, "list/rows/DimensionUnitRow");
    }


    defineCells() {
        const { data, sharedData } = this.props;
        const parentCells = super.defineCells();

        return {
            ...parentCells,
            parent: <AutoCompleteCell 
                value={data.parent_dimension_items_id}
                autoCompleteData={sharedData.items}
                onEdit={value => {
                    this.setDataAndUpdate({ 
                        parent_dimension_items_id: value.id 
                    });
                }}
            />,
            teams: data.id < 0 ? <AutoCompleteCell
                autoCompleteData={sharedData.teams}
                value={sharedData.teams?.filter(t => {
                    return data.teams.indexOf(t.id) > -1;
                })}
                multiple={true}
                onEdit={values => {
                    this.setDataAndUpdate({ 
                        teams: values.map(t => t.id) 
                    });
                }}
           /> : <LinkListCell 
                editable={false}
                value={data.team_count}
                valueHandler={value => {
                    const transl = this.tr("View Teams"); 

                    return `${transl} (${value})`;
                }}
                urlHandler={() => "/"}
                handleClick={() => {
                    this.props.rowCallbacks.showTeams(data.id);
                }}
            />
        };
    }
}


class DimensionUnits extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/DimensionUnits");

        this.list  = React.createRef();
        this.state = {
            units: [],
            teams: [],
            dimensions: [],
            items: [],
            teamsVisible: false,
            unitId: undefined
        };

        [
            "fetchData",
            "showTeams",
            "hideTeams",
            "handleDelete"
        ].forEach(f => this[f] = this[f].bind(this));

        this.fetchData();
    }


    async fetchData() {
        const baseUrl = `settings/company/${this.props.company}/dimensions`;
        const parentDimensionItemIdPart = this.props.parentDimensionItemId !== undefined 
            ? 
            `/${this.props.parentDimensionItemId}`
            : 
            "";

        try {
            this.setState({
                dimensions: await DataHandler.get({ 
                    url: `${baseUrl}` 
                }),
                units: await DataHandler.get({ 
                    url: `${baseUrl}/units${parentDimensionItemIdPart}` 
                }),
                teams: await DataHandler.get({ 
                    url: `${baseUrl}/unitless_teams` 
                }),
                items: await DataHandler.get({ 
                    url: `${baseUrl}/items` 
                })
            });
        } catch(e) {
            console.warn(e);
        }
    }


    showTeams(unitId) {
        this.setState({
            teamsVisible: true,
            unitId: unitId
        });
    }


    hideTeams() {
        this.setState({
            teamsVisible: false,
            unitId: undefined
        }, this.fetchData); 
        // Refresh the units just in case
        // any changes were made in the teams view.
    }


    async handleDelete(data) {
        try {
            await DataHandler.delete({ 
                url: `settings/company/${data.companies_id}/dimensions/units/${data.id}` 
            }); 

            this.list.current.removeRow(data.id);
        } catch(e) {
            const { error, information } = e.responseJSON;
            const { enqueueSnackbar }    = this.props;

            if(error === "UNIT_IN_USE") {
                const msg = this.tr("This unit is in use, and therefore can not be deleted. The following teams belong to it: ");

                enqueueSnackbar(`${msg} ${information}`, {
                    variant: "error",
                    autoHideDuration: 5000
                });

                return;
            }

            enqueueSnackbar(this.tr("An error occured while deleting the unit"), {
                variant: "error"
            });
        }
    }


    render() {
        const { 
            units, 
            teams,
            items,
            dimensions,
            teamsVisible,
            unitId
        } = this.state;

        const {
            parentDimensionItemId
        } = this.props;

        let parentDimensionItems = [];
        let parentDimension      = { name: "" };
        const commonConf         = {
            showMenu: false, 
            resizeable: false, 
            showResizeMarker: false, 
            moveable: false, 
            hideable: false,
            visibleInToolbar: true
        };

        if(dimensions.length > 2) {
            const unitsDimension = dimensions
                .find(d => d.id === "units");
            parentDimension = dimensions
                .find(d => d.id === unitsDimension.parent_dimensions_id);
            parentDimensionItems = items
                .filter(item => item.dimension_id === parentDimension.id);
        }

        return (
            <React.Fragment>
                {!teamsVisible && (
                    <React.Fragment>
                        <Row style={{ alignItems: "flex-start" }}>
                            <Col weight={3}>
                                {parentDimensionItemId && (
                                    <Button>
                                        <ArrowBack onClick={this.props.onArrowBack} />
                                    </Button>
                                )}
                                {!parentDimensionItemId && <h3>{this.tr("Units")}</h3>}
                                {!parentDimensionItemId && <p>{this.tr("Create Units to group your Teams for reporting and filtering purposes. One Unit can consist of multiple Teams.")}</p>}
                            </Col>
                            <Col weight={1}>
                                <Button className="green" style={{ float: "right" }} onMouseUp={() => this.list.current.addNewRow()} size="large">{this.tr("Add unit")}</Button>
                            </Col>
                        </Row>

                        <List
                            ref={this.list}
                            className="settingsList"
                            noStateData={true}
                            fluid={true}
                            idType="string"
                            listRowType={DimensionUnitRow}
                            height="auto"
                            data={units}
                            hideHeader={units.length === 0}
                            manualCreate={true}
                            rowProps={{
                                emptyMenuCell: false
                            }}
                            sharedData={{
                                teams: teams,
                                items: [{ id: 0, value: 0, name: this.tr("(None)") }, ...parentDimensionItems]
                            }}
                            newRow={{
                                name: "",
                                level: "",
                                code: "",
                                parent_dimension_items_id: parentDimensionItemId,
                                teams: [],
                                team_count: 0,
                                companies_id: this.props.company
                            }}
                            rowCallbacks={{
                                onCreate: async (data, row) => {
                                    try {
                                        const id = await DataHandler.post({ 
                                            url: `settings/company/${this.props.company}/dimensions/units` 
                                        }, data);

                                        row.setData({ 
                                            id: id, 
                                            team_count: data.teams.length 
                                        });
                                    } catch(e) {
                                        console.warn("taimer-debug", e);
                                    }
                                },
                                onUpdate: async (data, row) => {
                                    try {
                                        await DataHandler.patch({ 
                                            url: `settings/company/${this.props.company}/dimensions/units/${data.id}` 
                                        }, data);
                                    } catch(e) {
                                        console.warn("taimer-debug", e);
                                    }
                                },
                                onDelete: (data, row) => {
                                    if(this.props.parentDimensionItemId === undefined) {
                                        this.handleDelete(data);
                                    } else {
                                        row.setDataAndUpdate({ parent_dimension_items_id: 0 }); 
                                        row.removeFromList();
                                    }
                                },
                                showTeams: this.showTeams
                            }}
                            columns={[
                                { name: "menu", header: "", width: 20, ...commonConf },
                                { name: "name", header: this.tr("Unit name"), width: 200, ...commonConf },
                                (dimensions.length > 2) 
                                    ? 
                                    { name: "parent", header: parentDimension.name, width: 100, ...commonConf } 
                                    : 
                                    null,
                                { name: "code", header: this.tr("Code"), width: 80, ...commonConf },
                                { name: "teams", header: this.tr("Associated teams"), width: 80, ...commonConf },
                            ].filter(c => c)}
                        />
                    </React.Fragment>
                )}
                {teamsVisible && (
                    <React.Fragment>
                        <DimensionTeams 
                            company={this.props.company} 
                            unitId={unitId}
                            onArrowBack={this.hideTeams}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}


DimensionUnits.defaultProps = {
    parentDimensionItemId: undefined,
    onArrowBack: () => {}
};


class DimensionTeamRow extends DimensionRow {
    constructor(props) {
        super(props, {}, {}, "list/rows/DimensionTeamRow");
    }


    defineCells() {
        const parentCells = super.defineCells();
        const { 
            data, 
            sharedData
        } = this.props;

        return {
            ...parentCells,
            level: <ListCell />,
            // dimension_units_id: <ListCell value={data.unit_name} />,
            dimension_units_id: <AutoCompleteCell 
                value={data.dimension_units_id}
                autoCompleteData={sharedData.units} 
                onEdit={value => {
                    this.setDataAndUpdate({ 
                        dimension_units_id: value.id 
                    });
                }}
            />,
            category: 
                <AutoCompleteCell 
                    autoCompleteData={sharedData.categories}
                    value={data.category}
                    rowHeight={20}
					components={{ Option: props => {
						const passProps = {
							isDisabled: false,
							isFocused: 	props.isFocused,
							isMulti: 	props.isMulti,
							isSelected: props.isSelected
                        };

                        return <TreeOption {...props.data} {...passProps} onSelect={bob => {
							props.setValue(bob.id)
							this.setDataAndUpdate("category", bob.id);
						}} />;
					}}}
                    onEdit={value => {
                        this.setDataAndUpdate({ 
                            category: value.id 
                        })
                    }}
                />,
            team_leaders_id: 
                <AutoCompleteCell 
                    autoCompleteData={sharedData.users}
                    value={data.team_leaders_id}
                    onEdit={value => {
                        this.setDataAndUpdate({ 
                            team_leaders_id: value.id 
                        })
                    }}
                />,
            users: data.id < 0 
                ? 
                <AutoCompleteCell 
                    multiple={true}
                    autoCompleteData={sharedData.users}
                    value={sharedData.users.filter(u => data.users.indexOf(u.id) > -1)}
                    onEdit={values => {
                        this.setDataAndUpdate({
                            users: values.map(u => u.id)
                        });
                    }}
                /> 
                : 
                <LinkListCell 
                    editable={false}
                    value={data.user_count}
                    valueHandler={value => {
                        const transl = this.tr("View Users"); 

                        return `${transl} (${value})`;
                    }}
                    urlHandler={() => "/"}
                    handleClick={() => {
                        this.props.rowCallbacks.showUsers(data.id);
                    }}
                />
        };
    }
}


class DimensionTeams extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/DimensionTeams");

        this.list  = React.createRef();
        this.state = {
            usersVisible: false,
            teamId: undefined,
            teams:    [],
            units:    [],
            users:    [],
            categories: []
        };

        [
            "fetchData",
            "showUsers",
            "hideUsers",
            "handleDelete"
        ].forEach(f => this[f] = this[f].bind(this));

        this.fetchData();
    }


    async fetchData() {
        const unitPart = this.props.unitId === undefined ? "" : `/${this.props.unitId}`;
        let categories = await DataHandler.get({
            url: `settings/company/${this.props.company}/dimensions/categories`
        });

        categories = treeFormatDataForList(categories, "parent_id");
        categories = CreateBobOptions(categories);

        try {
            this.setState({
                teams: await DataHandler.get({
                    url: `settings/company/${this.props.company}/dimensions/teams${unitPart}` 
                }),
                units: await DataHandler.get({
                    url: `settings/company/${this.props.company}/dimensions/units`
                }),
                users: await DataHandler.get({
                    url: `settings/company/${this.props.company}/dimensions/users`
                }),
                categories: categories
            });
        } catch(e) {
            console.warn(e);
        }
    }


    showUsers(teamId) {
        this.setState({
            usersVisible: true,
            teamId: teamId
        });
    }


    hideUsers() {
        this.setState({
            usersVisible: false,
            teamId: undefined
        }, this.fetchData);
    }


    async handleDelete(data) {
        try {
            await DataHandler.delete({ 
                url: `settings/company/${data.companies_id}/dimensions/teams/${data.id}` 
            }); 

            this.list.current.removeRow(data.id);
        } catch(e) {
            const { error, information } = e.responseJSON;
            const { enqueueSnackbar }    = this.props;

            if(error === "TEAM_IN_USE") {
                const msg         = this.tr("This team is in use, and therefore can not be deleted.");
                const projectPart = this.tr("It is in use in the following projects: ");
                const userPart    = this.tr("The following users belong to the team: ");

                enqueueSnackbar(msg, {
                    variant: "error",
                    autoHideDuration: 5000
                });

                if(information.projects.length > 0) {
                    enqueueSnackbar(`${projectPart} ${information.projects}`, {
                        variant: "warning",
                        autoHideDuration: 5000
                    });
                }

                if(information.users.length > 0) {
                    enqueueSnackbar(`${userPart} ${information.users}`, {
                        variant: "warning",
                        autoHideDuration: 5000
                    });
                }

                return;
            }

            enqueueSnackbar(this.tr("An error occured while deleting the team"), {
                variant: "error"
            });
        }
    }


    render() {
        const { teams, usersVisible, teamId, units } = this.state;
        const teamsInTheUnit = this.tr("Teams in the unit");
        const { unitId }     = this.props;
        const unitName       = units?.find(u => u.id === unitId)?.name || "";
        const commonConf     = {
            showMenu: false, 
            resizeable: false, 
            showResizeMarker: false, 
            moveable: false, 
            hideable: false,
            visibleInToolbar: true
        };

        return (
            <React.Fragment>
                {!usersVisible && (
                    <React.Fragment>
                        <Row style={{ alignItems: "flex-start" }}>
                            <Col weight={3}>
                                {unitId && (
                                    <Button>
                                        <ArrowBack onClick={this.props.onArrowBack} />
                                    </Button>
                                )}
                                {!unitId && <h3>{this.tr("Teams")}</h3>}
                                {!unitId && <p>{this.tr("Create teams and assign users into teams. A user can belong to one team at a time, and a team can belong to one unit at a time.")}</p>}
                            </Col>
                            <Col weight={1}>
                                <Button className="green" style={{ float: "right" }} onMouseUp={() => this.list.current.addNewRow()} size="large">{this.tr("Add team")}</Button>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                {!usersVisible && <List
                    ref={this.list}
                    className="settingsList"
                    noStateData={true}
                    fluid={true}
                    idType="string"
                    listRowType={DimensionTeamRow}
                    height="auto"
                    manualCreate={true}
                    rowProps={{
                        emptyMenuCell: false
                    }}
                    rowCallbacks={{
                        onCreate: async (data, row) => {
                            if(data.category === undefined) {
                                row.setInvalidFields(["category"]);

                                this.props.enqueueSnackbar(this.tr("The category field can not be empty."), {
                                    variant: "error",
                                    autoHideDuration: 3000
                                });

                                setTimeout(row.setInvalidFields, 3000);

                                return;
                            }

                            try {
                                const teamId = await DataHandler.post({ 
                                    url: `settings/company/${this.props.company}/dimensions/teams`, 
                                }, data);

                                row.setData({ 
                                    id: teamId, 
                                    user_count: data.users.length 
                                });
                            } catch(e) {
                                console.warn(e);
                            }
                        },
                        onUpdate: async (data) => {
                            try {
                                await DataHandler.patch({ 
                                    url: `settings/company/${this.props.company}/dimensions/teams/${data.id}`, 
                                }, data);
                            } catch(e) {
                                console.warn(e);
                            }
                        },
                        onDelete: (data, row) => {
                            if(this.props.unitId === undefined) {
                                this.handleDelete(data);
                            } else {
                                row.setDataAndUpdate({ dimension_units_id: 0 }); 
                                row.removeFromList();
                            }
                        },
                        showUsers: (teamId) => {
                            this.showUsers(teamId);
                        }
                    }}
                    sharedData={{
                        units: [{ id: 0, value: 0, name: this.tr("(None)") }, ...this.state.units],
                        users: this.state.users,
                        categories: this.state.categories
                    }}
                    newRow={{
                        name:               "",
                        dimension_units_id: unitId,
                        category:           undefined,
                        team_leaders_id:    undefined,
                        code:               "",
                        users:              [],
                        companies_id:       this.props.company
                    }}
                    data={teams}
                    hideHeader={teams.length === 0}
                    columns={[
                        { name: "menu", header: "", width: 40, ...commonConf },
                        { name: "name", header: this.tr("Team name"), width: 120, ...commonConf },
                        // {  name: "level", header: this.tr("Level"), width: 50, ...commonConf },
                        { name: "dimension_units_id", header: this.tr("Unit"), width: 75, ...commonConf },
                        { name: "category", header: this.tr("Category"), width: 75, ...commonConf },
                        { name: "team_leaders_id", header: this.tr("Team leader"), width: 75, ...commonConf },
                        { name: "code", header: this.tr("Code"), width: 80, ...commonConf },
                        { name: "users", header: this.tr("Users"), width: 110, ...commonConf },
                    ]}
                />}
                {usersVisible && (
                    <React.Fragment>
                        <DimensionUserList 
                            company={this.props.company} 
                            teamId={teamId} 
                            onArrowBack={this.hideUsers}
                        /> 
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}


DimensionTeams.defaultProps = {
    unitId: undefined,
    onArrowBack: () => {}
};


class DimensionMappingRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, { childRowType: DimensionMappingRow }, "list/rows/DimensionMappingRow");
    }


    defineCells() {
        const { data } = this.props;
        const { dimensionName, dimensionId } = data;

        const dimensionNameValue = dimensionId === "units" || dimensionId === "teams"
            ?
            this.tr(dimensionName)
            :
            dimensionName;

        return {
            context: <ContextMenuCell />,
            name: <TreeStructureIndicatorCell
                indentDeepestLevel={true}
                childrenVisible={this.props.childrenVisible}
                onExpanderClick={e => this.props.listRef.toggleShowChildren(data.id)}
                listCellProps={{ alignCell: true, textAlign: "left" }}
                rowData={this.props}
                // showExpander={false}
                value={data.name}>
                <TextInputCell 
                    // onEdit={(value) => {
                        // this.setDataAndUpdate({ name: value });
                    // }}
                    listCellProps={{
                        // inEditMode: data.notSaved || parseInt(data.id) < 0,
                        // editable: !data.default, 
                        editable: false,
                        style: { height: "44px", width: "100%" }, 
                    }} 
                    value={data.name} />
            </TreeStructureIndicatorCell>,
            level: <ListCell 
                editable={false} 
                value={this.props.recursionLevel + 1} />,
            dimension: <ListCell 
                editable={false}
                value={data.dimensionName} />,
            parent: <ListCell 
                editable={false} 
                value={data.parentName} />,
            code: <ListCell 
                editable={false}
                value={data.code} />,
        };
    }
}


class DimensionMappings extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/Dimensions");

        this.list  = React.createRef();
        this.state = {
            data: []
        };

        [
            "fetchData"
        ].forEach(n => this[n] = this[n].bind(this));

        this.fetchData();
    }


    async fetchData() {
        try {
            this.setState({
                data: await DataHandler.get({
                    url: `settings/company/${this.props.company}/dimensions/mappings`
                })
            });
        } catch(e) {
        
        }
    }


    render() {
        const { data }   = this.state;
        const commonConf = {
            showMenu: false, 
            resizeable: false, 
            showResizeMarker: false, 
            moveable: false, 
            hideable: false,
            visibleInToolbar: true
        };

        return (
            <List
                ref={this.list}
                className="settingsList"
                noStateData={true}
                fluid={true}
                idType="string"
                treeData={true}
                parentKey={"parentId"}
                listRowType={DimensionMappingRow}
                height="auto"
                manualCreate={true}
                rowCallbacks={{}}
                sharedData={{}}
                newRow={{}}
                data={data}
                hideHeader={data.length === 0}
                columns={[
                    // { name: "context", header: "", width: 50, ...commonConf },
                    { name: "name", header: this.tr("Name"), width: 180, ...commonConf },
                    { name: "level", header: this.tr("Level"), width: 60, ...commonConf },
                    { name: "dimension", header: this.tr("Dimension"), width: 120, ...commonConf },
                    { name: "parent", header: this.tr("Parent"), width: 150, ...commonConf },
                    { name: "code", header: this.tr("Code"), width: 120, ...commonConf },
                ].filter(c => c)}
            />
        );
    }
}


class Dimensions extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/Dimensions");

        [
            "createTab", 
            "switchTab", 
            "fetchDimensions",
            "fetchSettings",
            "refreshDimensions",
            "setDimensionsState",
            "setDimensionSettings"
        ].forEach(n => this[n] = this[n].bind(this));

        this.defaultTabs = {
            settings: ["Settings", DimensionSettings],
            units: ["Units", DimensionUnits],
            teams: ["Teams", DimensionTeams],
            dimension_mappings: ["Dimension mappings", DimensionMappings]
        };

        this.state = {
            selectedTab: this.props.viewProps?.selectedSubTab || Object.keys(this.defaultTabs)[0],
            dimensions: [],
            settings: {}
        };

        this.refreshDimensions();
    }


    fetchDimensions() {
        return DataHandler.get({ url: `settings/company/${this.props.company}/dimensions` }); 
    }


    fetchSettings() {
        return DataHandler.get({ url: `settings/company/${this.props.company}/dimensions/settings` });  
    }


    async refreshDimensions() {
        const dimensions = await this.fetchDimensions();
        const settings   = await this.fetchSettings();

        this.setState({
            dimensions: dimensions,
            settings: settings
        });
    }


    setDimensionsState(dimensions) {
        this.setState({ 
            dimensions: dimensions
        });
    }


    setDimensionSettings(settings) {
        settings = { ...cloneDeep(this.state.settings), ...settings };

        this.setState({ settings: settings });
    }


    createTab(name, label) {
        const StyledTab = withStyles({
            label: {
                fontSize: "12px",
                letterSpacing: "1px"
            },
            selected: {
				color: "#003A78 !important",
			}
        })(Tab);

        return (
            <StyledTab 
                className={`settings-styled-tab ${this.state.selectedTab !== name ? "not-selected" : ""}`} 
                value={name}
                label={this.tr(label)} 
                href={this.context.functions.urlify({ ...this.props.viewProps, selectedSubTab: name })} 
                onClick={(evt) => !evt.ctrlKey && evt.preventDefault()} 
            />
        )
    }


    switchTab(tabName) {
        this.context.functions.updateView({ selectedSubTab: tabName });

        this.setState({ selectedTab: tabName });
    }


    render() {
        if(this.context.addons?.dimensions?.used_by_companies?.indexOf(this.props.company) === -1) {
            return null;
        }

        const TabComponent = this.defaultTabs.hasOwnProperty(this.state.selectedTab) 
            ? 
            this.defaultTabs[this.state.selectedTab][1] 
            : 
            DimensionItems;

        return (
            <div id="dimension-settings">
                <div id="dimensions-navigation">
                    <StyledTabs 
                        classes={{indicator: 'with-indicator rounded-indicator'}}
                        value={this.state.selectedTab}
                        onChange={(evt, tab) => !evt.ctrlKey && this.switchTab(tab)}
                        variant="standard">
                        {this.createTab("settings", this.defaultTabs['settings'][0])}
                        {this.state.dimensions
                                .filter(d => !d.default)
                                .map(d => this.createTab(d.id, d.name))
                        }
                        {Object.keys(this.defaultTabs)
                                .slice(1)
                                .map(tabName => this.createTab(tabName, this.defaultTabs[tabName][0]))
                        }
                    </StyledTabs>
                </div>
                <div id="tab-content">
                    <TabComponent 
                        key={this.state.selectedTab}
                        company={this.props.company}
                        dimensions={this.state.dimensions}
                        settings={this.state.settings}
                        setDimensionsState={this.setDimensionsState}
                        setDimensionSettings={this.setDimensionSettings}
                        refreshDimensions={this.refreshDimensions}
                        viewProps={this.props.viewProps}
                        dimensionId={this.state.selectedTab}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                    />
                </div>
            </div>
        );
    }
}

Dimensions.defaultProps = {

}

Dimensions.contextType = SettingsContext;

export default withSnackbar(Dimensions);
