import React, { Component } from 'react'
import TaimerComponent from '../TaimerComponent';

import { SettingsContext } from '../SettingsContext';

import Notification from './Notification';
import DataHandler from '../general/DataHandler';

import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';

import _, { keyBy } from 'lodash';
import styles from './Category.module.scss';
import { getIconFromName, NotifcationSeen, NotificationItem, NotificationsCategory, NotificationType } from './api';

import cn from 'classnames';

interface Props {
    notificationProps: {
        refresh: (force: boolean) => void;
        openTeamChat: (state: any) => void;
        close: () => void;
        tr: (text: string) => string;
    };
    type: NotificationType;
    category: NotificationsCategory;
    data: NotificationItem[];

    updateNotification: (selector: (item: NotificationItem) => boolean, update: Partial<NotificationItem>) => void;
}
interface State {
    open: boolean;
}

export class Category extends TaimerComponent<Props, State> {
    // localStorageOpen = {};

    constructor(props, context) {
        super(props, context, "notifications/Category");

        this.state = {
            open: false,
        }
    }

    componentDidMount() {
        const open = false;
        this.setState({ open: open });
    }

    open = () => {
        this.setState({ open: true });
        // this.localStorageOpen[this.props.type] = "open";
        // localStorage.setItem("openNotifications", JSON.stringify(this.localStorageOpen))
    }

    close = () => {
        this.setState({ open: false });
        // this.localStorageOpen[this.props.type] = "closed";
        // localStorage.setItem("openNotifications", JSON.stringify(this.localStorageOpen))
    }

    clear = (seen: number | null = null) => {
        const { type, updateNotification } = this.props;
        const { userObject: { usersId } } = this.context;

        const params = { seen: seen, type: type, user: usersId };

        updateNotification(x => x.type === type && (seen !== 1 || x.seen == NotifcationSeen.Seen), {seen: NotifcationSeen.Hidden});

        DataHandler.post({ url: `notifications/clear`, ...params });
    }

    render() {
        const { tr } = this;
        const { category, type, data, notificationProps, updateNotification } = this.props;
        const { open } = this.state;

        let newCount = 0, seenCount = 0;

        data.forEach(notification => {
            if (notification.seen == 0)
                newCount++;
            else
                seenCount++;
        });

        const Icon = getIconFromName(category.icon);

        const byType = keyBy(category.notifications, i => i.sub_type);

        const badgeContent: string = (newCount > 0 ? newCount : seenCount).toString();

        return (<div className={styles.categoryContainer}>
            <div onClick={open ? this.close : this.open} className={styles.category}>
                <div className={styles.categoryTitle}>
                    <div className={cn(styles.iconContainer, styles[`type${type}`])}>
                        {Icon && <Icon />}
                    </div>
                    <span className={styles.title}>{tr(category.header)}</span>
                </div>
                <div className={styles.categoryActions}>
                    <div className={cn(styles.notificationCount, newCount > 0 ? styles.active : styles.inactive)}>
                        {badgeContent}
                    </div>
                    {open ? <UpIcon /> : <DownIcon />}
                </div>
            </div>
            {open && <div className={styles.categoryFunctions}>
                <span onClick={() => { this.clear(1) }}>{tr("Clear read")}</span>
                <span onClick={() => { this.clear() }}>{tr("Clear all")}</span>
            </div>}
            {open && data.filter(x => x.seen !== NotifcationSeen.Hidden).map(x => <Notification
                key={x.id}
                category={category}
                type={byType[x.subtype]}
                data={x}
                updateNotification={updateNotification}
                {...notificationProps} />)}
            <div className={cn(styles.divider, open && styles.buffer)} />
        </div>);
    }
}

export default Category;