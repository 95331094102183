import TaimerComponent from '../TaimerComponent';
import ConfirmSuccessDialog from "../dialogs/ConfirmSuccessDialog";
import DataHandler from "../general/DataHandler";

import { errorsData, successData } from "../dialogs/ConfirmSuccessDialog";

export interface SingleData {
    id: string | number;
}

export interface StatusCheckDialogProps {
    module: string;
    newStatus: string | number;
    ids?: string[] | number[];
	singleData?: SingleData | undefined;
    closeDialog: () => void;
    onConfirm: (ids, singleData) => void;
	getIntegrationNumber?: (integration: string) => number;
}

interface State {
    errorsData: errorsData[];
    successData: successData[];
	dataLoaded: boolean;
	fetchError: boolean;
}

class StatusCheckDialog extends TaimerComponent<StatusCheckDialogProps, State> {
    headers: any;
	singleHeaders: any;
    buttonTexts: any;
    errorHeaders: any;
    currentStatusTexts: any;
    newStatusTexts: any;
    confirmTexts: any;
    noneAllowedTexts: any;

    constructor(props, context) {
        super(props, context, "expenses/StatusCheckDialog");
        
        this.state = {
            errorsData: [],
            successData: [],
			dataLoaded: false,
			fetchError: false
        };

		const netvisor       = props.getIntegrationNumber ? props.getIntegrationNumber("netvisor")       : 101;
		const netvisor_escan = props.getIntegrationNumber ? props.getIntegrationNumber("netvisor_escan") : 102;
		const procountor     = props.getIntegrationNumber ? props.getIntegrationNumber("procountor")     : 103;
		const fortnox        = props.getIntegrationNumber ? props.getIntegrationNumber("fortnox")        : 104;

        this.headers = {
			1: "Set expenses as waiting",
			2: "Approve expenses",
			3: "Reject expenses",
			4: "Archive expenses",
			[netvisor]: "Send to Netvisor",
			[netvisor_escan]: "Send to Netvisor via eScan",
			[procountor]: "Send to Procountor",
			[fortnox]: "Send to Fortnox",
		}

		this.singleHeaders = {
			1: "Set expense as waiting",
			2: "Approve expense",
			3: "Reject expense",
			4: "Archive expense",
		}

		this.buttonTexts = {
			1: "Set as waiting",
			2: "Approve",
			3: "Reject",
			4: "Archive",
			[netvisor]: "Send",
			[netvisor_escan]: "Send",
			[procountor]: "Send",
			[fortnox]: "Send"
		}

	    this.errorHeaders = {
			1: "Following expenses cannot be set as waiting:",
			2: "Following expenses cannot be approved:",
			3: "Following expenses cannot be rejected:",
			4: "Following expenses cannot be archived:",
			[netvisor]: "Following expenses cannot be sent to Netvisor:",
			[netvisor_escan]: "Following expenses cannot be sent to Netvisor:",
			[procountor]: "Following expenses cannot be sent to Procountor:",
			[fortnox]: "Following expenses cannot be sent to Fortnox:",
		}

		this.currentStatusTexts = {
			"-1": "invoiced",
			"-2": "sent",
			0: "draft",
			1: "waiting",
			2: "approved",
			3: "rejected",
			4: "archived",
		}

		this.newStatusTexts = {
			1: "set as waiting",
			2: "approved",
			3: "rejected",
			4: "archived",
			[netvisor]: "sent to Netvisor",
			[netvisor_escan]: "sent to Netvisor",
			[procountor]: "sent to Procountor",
			[fortnox]: "sent to Fortnox",
		}

		this.confirmTexts = {
			1: "Are you sure you want to set as waiting",
			2: "Are you sure you want to approve",
			3: "Are you sure you want to reject",
			4: "Are you sure you want to archive",
			[netvisor]: "Are you sure you want to send to Netvisor",
			[netvisor_escan]: "Are you sure you want to send to Netvisor",
			[procountor]: "Are you sure you want to send to Procountor",
			[fortnox]: "Are you sure you want to send to Fortnox",
		}

		this.noneAllowedTexts = {
			1: "None of the selected expenses can be set as waiting",
			2: "None of the selected expenses can be approved",
			3: "None of the selected expenses can be rejected",
			4: "None of the selected expenses can be archived",
			[netvisor]: "None of the selected expenses can be sent to Netvisor",
			[netvisor_escan]: "None of the selected expenses can be sent to Netvisor",
			[procountor]: "None of the selected expenses can be sent to Procountor",
			[fortnox]: "None of the selected expenses can be sent to Fortnox",
		}
    }

    componentDidMount() {
        super.componentDidMount();
        this.getData();
    }

    getData = () => {
        const { newStatus, ids, module, singleData } = this.props;

		if (singleData) {
			this.setState({ dataLoaded: true, successData: [singleData] });
			return;
		}

        const data = {
			newStatus,
            ids,
			module
		}

		DataHandler.post({url: "expenses/check_statuses"}, data).done(data => {
			this.handleData(data);
		})
		.fail(err => {
			this.setState({ dataLoaded: true, fetchError: true });
		})
    }

    handleData = (data) => {
        const { newStatus } = this.props;
		const status = Number(newStatus);

        const invalids = (data?.invalids || []).map(el => {
			const header = el.status == -1 
				? this.tr("${amount} expenses are invoiced: ", { amount: el.ids?.length || 0 })
				: this.tr("${amount} expenses in \"" + this.currentStatusTexts[el.status] + "\" state:", { amount: el.ids?.length || 0 });

			const message = this.tr(this.currentStatusTexts[el.status] + " expenses cannot be " + this.newStatusTexts[status] + ".");
			return {...el, header, message };
		});

		const errorsData = [
            {
                header: this.tr(this.errorHeaders[status]),
                data: invalids,
				type: "warning"
            }
        ];

        this.setState({ errorsData, successData: data.success, dataLoaded: true });
    }

	getSubtitle = () => {
		const { newStatus, singleData, ids } = this.props;
		const { successData, dataLoaded, fetchError } = this.state;
		const status = Number(newStatus);

		if (!dataLoaded) {
			return this.tr("Fetching expense data...");
		}
		else if (fetchError) {
			return this.tr("Error in fetching data");
		}
		else if (singleData) {
			return this.tr(this.confirmTexts[status] + " expense ${expense}?", {expense: singleData.id});
		}
		else if ((ids || []).length == 0) {
			return this.tr("No expenses selected");
		}
		else if (successData?.length == 0) {
			return this.tr(this.noneAllowedTexts[status]);
		}

		return this.tr(this.confirmTexts[status]  + " ${amount} expenses?", {amount: successData?.length || 0})
	}

    render() {
        const { closeDialog, onConfirm, newStatus, singleData } = this.props;
        const { errorsData, successData, dataLoaded } = this.state;
		const status = Number(newStatus);

        return <ConfirmSuccessDialog
			header={singleData ? this.tr(this.singleHeaders[status]) : this.tr(this.headers[status])}
			confirmText={this.tr(this.buttonTexts[status])}
			successData={successData}
			errorsData={errorsData}
			loading={!dataLoaded}
			successMessage={this.getSubtitle()}
			onConfirm={(successIds) => {
                closeDialog();
                onConfirm(successIds, singleData);
            }}
			closeDialog={() => {
				closeDialog();
			}}
		/> 
    }
}

export default StatusCheckDialog;