import Big from "big.js";

function round(x: number, d: number): number {
    return Big(x).round(d).toNumber();
}

function sum(x: number[]): number {
    return x.reduce(reduceSum);
}

function reduceSum(total: number, current: number): number {
    return nplus(total, current);
}

function roundToDecimals(n: number, decimals: number): number {
    if(!Number.isInteger(decimals)) {
        throw new Error("The decimal parameter is not an integer."); 
    }

    if(decimals < 0) {
        throw new Error("The decimal parameter is less than zero.");
    }

    if(decimals === 0) {
        return Math.round(n);
    }

    let mulDiv = 10;

    for(let i = 1; i < decimals; ++i) {
        mulDiv *= 10;
    }

    return Math.round(nmultiply(n, mulDiv)) / mulDiv;
}

function roundToFixed(n: number|string, decimals: number): string {
    if (typeof n === "string") {
        n = n.replace(",", ".");
    }
    const number = Number(n) || 0;

    return roundToDecimals(number, decimals).toFixed(decimals);
}

function roundToFixedNumber(n: number|string, decimals: number): number {
    return Number(roundToFixed(n, decimals));
}


function roundPercent(n: number|string, decimals = 2): string {
    const number = Number(n) || 0;
    
    if ((number  * 100) % 1 === 0) {
        decimals = 0;
    }

    return roundToDecimals(number * 100, decimals).toFixed(decimals);
}

// Big.js stuff -->
function _bigArgs(...numbers: (number | Big)[]): Big[] {
    // "Casting" the potential Big to Number
    // will yield a normal Javascript Number.
    return numbers
        .map((arg: any) => Number(arg))
        .map((arg: number) => new Big(arg));
}

function _compute(
    operation: string, 
    nums: Big[],
    // Is this really ever needed as an argument?
    initialValue: number | undefined = undefined
): Big {
    let big: Big;

    if(nums.length === 1) {
        return Big(nums[0]);
    }

    if(initialValue === undefined) {
        big  = nums[0];
        nums = nums.slice(1);
    } else {
        big = Big(initialValue);
    }

    nums.forEach((n: Big) => big = big[operation](n));

    return big;
}

function plus(...args: (number | Big)[]): Big {
    return _plus(_bigArgs(...args));
}

function nplus(...args: (number | Big)[]): number {
    return plus(...args).toNumber();
}

function _plus(nums: Big[]): Big {
    return _compute("plus", nums);
}

function minus(...args: (number | Big)[]): Big {
    return _minus(_bigArgs(...args));
}

function nminus(...args: (number | Big)[]): number {
    return minus(...args).toNumber();
}

function _minus(nums: Big[]): Big {
    return _compute("minus", nums);
}

function multiply(...args: (number | Big)[]): Big {
    return _multiply(_bigArgs(...args));
}

function nmultiply(...args: (number | Big)[]): number {
    return multiply(...args).toNumber();
}

function _multiply(nums: Big[]): Big {
    return _compute("times", nums);
}

function divide(...args: (number | Big)[]): Big {
    return _divide(_bigArgs(...args));
}

function ndivide(...args: (number | Big)[]): number {
    return divide(...args).toNumber();
}

function _divide(nums: Big[]): Big {
    return _compute("div", nums);
}

function calculate(num = 0): Big {
    return Big(num);
}
// <--- Big.js stuff

export {
    sum,
    reduceSum,
    roundToDecimals,
    plus,
    nplus,
    minus,
    nminus,
    multiply,
    nmultiply,
    divide,
    ndivide,
    Big,
    calculate,
    round,
    roundToFixed,
    roundPercent,
    roundToFixedNumber
}
