/* react */
import React from 'react';

/* css */
import './Efina.css';

/* material-ui */
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";
import { HelpOutline } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';

/* others */
import List from "../../list/List";
import Utils from "./../../general/Utils.js";
import ListCell from "../../list/ListCell";
import TreeOption from "../../list/TreeOption";
import SettingRow from "../../list/rows/SettingRow";
import DataHandler from '../../general/DataHandler';
import TrashCanIcon from "@mui/icons-material/Delete";
import TextInputCell from "../../list/cells/TextInputCell";
import CreateBobOptions from "../../list/CreateBobOptions";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import { SettingsContext } from '../../SettingsContext';

class ProductRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            product_id: <TextInputCell
                width={this.props.columnWidthMap['product_id']}
                name="product_id"
                value={this.state.data['product_id']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class Fivaldi extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Fivaldi");

        this.state = {
            realm: "",
            username: "",
            password: "",
            accountingProducts: [],
        };
      
        this.productsList = React.createRef();

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getProducts();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/fivaldi`}).done(response => {
            this.setState(response);
        });
    }

    getProducts = () => {
        DataHandler.get({url: `accounting/products/${this.props.company}/fivaldi`}).done(response => {
            this.setState(response);
        });
    }

    onClickSave = () => {
        DataHandler.put({url: `settings/company/${this.props.company}/fivaldi`}, this.state).done(resp => {
            this.props.enqueueSnackbar(this.tr("Fivaldi authentication information saved"), {
                variant: "success",
            });	
            this.updateComponentData();
        });
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        DataHandler.delete({url: `settings/company/${this.props.company}/fivaldi`}).done(this.back);
    }
    
    render(){
        const { realm, username, password, access_token, activated } = this.state;        
        const { tr } = this;

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Fivaldi Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("Authentication information")}</h4>
                    <OutlinedField value={realm} onChange={(evt) => this.setState({realm: evt.target.value})} label={tr("Company ID")} />
                    <OutlinedField value={username} onChange={(evt) => this.setState({username: evt.target.value})} label={tr("Username")} />
                    <OutlinedField value={password} onChange={(evt) => this.setState({password: evt.target.value})} label={tr("Password")} autoComplete="new-password" />
                    <div className="tooltip-row">
                        <OutlinedField value={access_token} onChange={(evt) => this.setState({access_token: evt.target.value})} label={tr("Folder URL")} />
                        <Tooltip className="tooltip" title={this.tr("For example: https://asp.fivaldi.net/pls/fv005/xml_web.sisaanluku")} placement="bottom">
                            <HelpOutline />
                        </Tooltip>
                    </div>
                </div>

                <div className="settings">
                    <Button color="primary" onClick={this.onClickSave} size="large">{tr("Save")}</Button>
                </div>

                <div className="settings">                  
                    <h4>{tr("Products")}</h4>
                    <Button className="green" onMouseUp={() => this.productsList.current.addNewRow()} size="large">{tr("Add product")}</Button>
                    <br />
                    <List
                        ref={this.productsList}
                        id="accountingProductsList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            product_id: "",
                        }}
                        listRowType={ProductRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Product name"), ...this.dColConf },
                            { width: 200, name: "product_id", header: tr("Product ID"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                data.company = this.props.company;
                                DataHandler.post({url: 'accounting/products/fivaldi'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: 'accounting/products'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            },
                            onDelete: data => {
                                data.deleted = "1";
                                DataHandler.delete({url: 'accounting/products'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            }
                        }}
                        data={this.state.accountingProducts}
                    />
                </div>
            </div>
        )
    }
}

export default Fivaldi;