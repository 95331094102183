import React from 'react';

import './InsightVsBar.css';

/* others */
import _ from 'lodash';
import TaimerComponent from "../../TaimerComponent";
import InsightSlider from "./InsightSlider";

import cn from 'classnames';

class InsightVsBar extends TaimerComponent {
    static defaultProps = {
        colorLeft: "#2589d7",
        colorRight: "#f6f9fb",
    };

    constructor(props, context) {
        super(props, context, "dashboard/insights/InsightVsBar");

        this.state = {
            sliderOpen: false,
        }
    }

    clickLeft = (evt) => {
        if (!this.props.dataLeft) return;
        evt.stopPropagation();
        this.setState({sliderOpen: 'left'});
    }

    clickRight = (evt) => {
        if (!this.props.dataRight) return;

        evt.stopPropagation();
        this.setState({sliderOpen: 'right'});
    }

    onCloseSlider = () => this.setState({sliderOpen: false});

    render() {
        const {
            className, sliderTable, currency,
            textLeft, colorLeft, dataLeft, valueLeft,
            textRight, colorRight, dataRight, valueRight } = this.props;

        const { sliderOpen } = this.state;

        const { functions: { presentCurrency } } = this.context;

        const width = valueLeft > 0 ? (valueLeft / (valueLeft + valueRight) * 100).toFixed(1) : 0;

        return (<div className={cn("insight-vsbar", className, dataLeft && "has-data")}>
            <InsightSlider
                open={!!sliderOpen}
                label={sliderOpen == 'left' ? textLeft : textRight}
                onClose={this.onCloseSlider}
                data={sliderOpen == 'left' ? dataLeft : dataRight}
                sum={sliderOpen == 'left' ? valueLeft : valueRight}
                table={sliderTable}
                // columns={sliderColumns}
            />
            <div className="bar-titles">
                <div className="bar-title" onClick={this.clickLeft}>
                    <div className="title">{textLeft}</div>
                    <div className="value">{presentCurrency(valueLeft, currency)}</div>
                </div>
                <div className="bar-title right" onClick={this.clickRight}>
                    <div className="title">{textRight}</div>
                    <div className="value">{presentCurrency(valueRight, currency)}</div>
                </div>
            </div>

            <div className="bar-container">
                <div className="bar" style={{ backgroundColor: colorRight }} onClick={this.clickRight}>
                    <div className="barInner" style={{ backgroundColor: colorLeft, width: `${width}%` }} onClick={this.clickLeft} />
                </div>
            </div>
        </div>)
    }
}

export default InsightVsBar;