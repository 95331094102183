import React, { Component } from 'react'

import styles from './ReportsGallerySection.module.scss';

/* local components */
import TaimerComponent from '../../TaimerComponent';
import { ReportsSection } from './ReportsGallery';
import ReportsGalleryTemplate from './ReportsGalleryTemplate';

/* context */
import { SettingsContext } from '../../SettingsContext';
import _ from 'lodash';
import { Collapse } from '@mui/material';

import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TemplateListItem, TemplateData } from '../TemplateUtils';

interface Props {
    open?: boolean;
    onSelectTemplate?: (template: TemplateListItem) => void;
    onUpdateTemplate?: (template: TemplateListItem, update: Partial<TemplateListItem>) => void;
    onClose?: () => void;
    section: ReportsSection;
    templates: TemplateListItem[];
    editTemplate?: (template: TemplateData, deleted: boolean) => void;
    toggleDisabled?: boolean;
}

interface State {
    open: boolean;
}

export default class ReportsGallerySection extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "new_reports/components/ReportsGallerySection");

        this.state = {
            open: true,
        }
    }

    toggle = () => {
        const { open } = this.state;
        if (this.props.toggleDisabled) {
            return;
        }

        this.setState({ open: !open });
    }

    render() {
        const { section: { title }, templates, onSelectTemplate, onUpdateTemplate, editTemplate, toggleDisabled } = this.props;
        const { open } = this.state;

        return (
            <div className={styles.root}>
                <div className={`${styles.header} ${toggleDisabled ? styles.noToggle : ""}`} onClick={this.toggle}>
                    <div>{this.tr(title)}</div>
                    <div className={styles.right}>
                        <div className={styles.count}>{templates.length} {this.tr('reports')}</div>
                        {!toggleDisabled ? <div>{open ? <UpIcon /> : <DownIcon />}</div> : <></>}
                    </div>
                </div>
                <Collapse in={open}>
                    {templates.map(x => <ReportsGalleryTemplate key={x.id} template={x} onSelectTemplate={onSelectTemplate} onUpdateTemplate={onUpdateTemplate} editTemplate={editTemplate} />)}
                </Collapse>
            </div>
        )
    }
}