import React from 'react';
import BranchIndicator, { hasFurtherSiblings } from "./../general/BranchIndicator";
// import HighlightedString from "./../general/HighlightedString";
import HighlightedString from "./../general/HighlightedString";
import Tooltip from '@mui/material/Tooltip';
import isEqual from "lodash/isEqual";
import "./TreeOption.css";


class TreeOption extends React.Component {
    static defaultProps = {
        className: "",
        floatLeft: false,
        rowHeight: 20,
        indentWidth: 20,
        inList: true,
        highlight: false,
        onSelect: () => {},
        style: {},
        branchIndicatorProps: {}
    };

    constructor(props) {
        super(props);

        this.wrapper = React.createRef();

        this.branchIndicators = [];

        this.state = { useTooltip: false };

        this.createIndicators = this.createIndicators.bind(this);

        this.createIndicators();
    }

    createIndicators() {
        this.branchIndicators = [];

        for(let i = 0; i < this.props.level; ++i) {
            let hasMoreSiblings = this.props.level > 0 ? hasFurtherSiblings(this.props, this.props.level - i) : false;
            let noBottom	 	= i === this.props.level - 1 && this.props.level > 0 && this.props.isLastChild;

            this.branchIndicators.push(
                <BranchIndicator
                    inList={this.props.inList}
                    floatLeft={this.props.floatLeft}
                    rowHeight={this.props.rowHeight}
                    width={this.props.indentWidth}
                    renderHor={i === this.props.level - 1}
                    renderNothing={!hasMoreSiblings && !noBottom && i !== this.props.level - 1}
                    noBottom={noBottom}
                    {...this.props.branchIndicatorProps}
                    key={`${this.props.data.value}-${i}`} />
            );
        }
    }


    componentDidUpdate(prevProps) {
        if(!isEqual(prevProps, this.props)) {
            this.createIndicators();
        }
    }


    componentDidMount() {
        // Only show the tooltip if the width of the element exceeds the width of the parent container.
        (this.wrapper.current.scrollWidth > this.wrapper.current.parentNode.clientWidth) && (this.setState({ useTooltip: true }));
    }

    render() {
        // Don't add ToolTip back. It's slow as hell.
        const Wrapper       = React.Fragment; 
        const { highlight } = this.props;
        const className     = `treeOption ${this.props.isFocused ? "focused" : ""} ${this.props.isSelected ? "selected" : ""} ${this.props.isDisabled ? "disabled" : ""} ${this.props.className}`;
        const style         = { height: "20px", lineHeight: "20px", ...this.props.style };
        const value         = this.props?.displayValue || this.props?.data?.name || "";
        let spanProps       = {};

        let HighlightedValue;

        if(highlight) {
            HighlightedValue = <HighlightedString 
                string={value} 
                highlighters={highlight ? highlight : []}
                highlightClassName={"highlight"}
            />
        }

        return (
            <Wrapper>
                <div data-testid={`tree_option_${this.props.index}`} title={this.state.useTooltip ? this.props.data.name : undefined} ref={this.wrapper} onClick={e => this.props.onSelect(this.props.data, e)} className={className} style={style} >
                    {this.branchIndicators}
                    {/*<span style={{ height: "20px", lineHeight: "20px" }}>{this.props.data ? this.props.data.name : ""}</span>*/}
                    <span style={{ height: "20px", lineHeight: "20px" }}>
                        {highlight
                            ? <HighlightedString 
                                string={value} 
                                highlighters={highlight ? highlight : []}
                                highlightClassName={"highlight"} />
                            : value}
                    </span>
                </div>
            </Wrapper>
        );
    }
}

export default TreeOption;
