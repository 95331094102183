import React, { Component } from 'react';
import $ from 'jquery';
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import SettingsGrid from './SettingsGrid';
import Button from '@mui/material/Button';
import OutlinedField from "./../../general/OutlinedField";
import Switch from '@mui/material/Switch';
import PhotoIcon from '@mui/icons-material/PhotoOutlined'
import { withSnackbar } from 'notistack';

import './CompanyLogo.css';

class LogoUpload extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/CompanyLogo");
        
        this.state = {
            loaded: false,
            logoUrl: this.getLogoUrl(),
        }

        this.refFileInput = React.createRef();
    }

    getLogoUrl = () => {
        const { company } = this.props;
        
        const params = {
            attachment: "company_logo",
            id: company,
            t: this.context.taimerAccount.logo,
            _auth: this.context.functions.getStorage().taimerToken,
        };
     
        return this.context.functions.getDownloadPath() + $.param(params);
    }
    
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.setState({logoUrl: this.getLogoUrl() + "&d=" + new Date().getTime()});
    }

    upload = () => {
        this.refFileInput.current.click();
    }

    uploadClick = () => {
        this.refFileInput.current.click();
    }

    uploadLogo = (e) => {
        const { company } = this.props;

        const file = e.target.files[0];
        e.target.value = '';

        if (file.type != 'image/png' && file.type != 'image/jpeg') {
            this.props.enqueueSnackbar(this.tr("Only jpeg and png are supported"), {
                variant: "error",
            });
            return;
        }
        else if (file.size > 10 * 1024 * 1024) { // 10 MB
            this.props.enqueueSnackbar(this.tr('File is too large (max 10 MB)'), {
                variant: 'error',
            });
            return;
        }

        var formData = new FormData();

        formData.append('avatar', file);

        DataHandler.file({url: `settings/company/${company}/logoupload` }, file).always(() => {
            // To Ensure logo is not cached
            setTimeout(() => {
                this.setState({ imageError: false, logoUrl: this.getLogoUrl() + "&d=" + new Date().getTime()});
            }, 1000);
        });

    }

    render() {
        const { userObject } = this.context;
        const { classes } = this.props;
        const { logoUrl, imageError } = this.state;
        const { tr } = this;

        return (<React.Fragment>
        <div className="companyLogoSelect">
            <div className="imageWithIcon" onClick={this.upload}>
                <div>
                    {!imageError && <img src={logoUrl} onError={() => this.setState({ imageError: true })} onLoad={() => this.setState({ imageError: false })} />}
                </div>
                <div className={`iconLayer ${!imageError ? 'darken' : ''}`}>
                    <PhotoIcon />
                </div>
            </div>
            <div className="logohelp">
                <input ref={this.refFileInput} onChange={this.uploadLogo} type="file" accept="image/png,image/jpeg" style={{ display: 'none' }} />
                {tr("Upload Logo")} (jpg/png, {tr("size")} 217 x 177, {tr("max")} 10 MB)
            </div>
        </div>
        </React.Fragment>)
    }
}

class CompanyLogo extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/CompanyLogo");

        this.state = {
            loaded: false,
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.updateComponentData();
    }
    updateComponentData = async () => {
        const { company } = this.props;

        const data = await DataHandler.get({ url: `settings/company/${company}/logo` });

        this.setState({ loaded: true, data });
    }
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    onChange = (data) => {
        this.setState({ data });

        this.save(data);
    }

    save = async (data) => {
        const { company } = this.props;

        const newdata = await DataHandler
            .post({ url: `settings/company/${company}/logo` }, data)
            .fail(x => this.setError(x));

        this.setState({ loaded: true, data: newdata, errors: {} });
    }

    setError(error) {
        this.setState({ errors: error.responseJSON })
    }

    render() {
        const { loaded, errors, data } = this.state;
        const { tr } = this;
        
        let settings = [
            {
                type: 'title',
                key: 'section-tile',
                className: 'header',
                label: tr("Logo"),
                subtext: tr("Add your company logo. Define to be shown on invoice, quote, cover letter and reports"),
            },
            {
                type: 'component',
                name: 'accounts',
                key: '2',
                component: withSnackbar(LogoUpload)
            },
            {
                type: 'check',
                className: 'nextLine',
                name: 'showlogo',
                label: tr("Show logo on documents and invoices"),
            },
            /* TAIM9-884{
                type: 'check',
                className: 'nextLine',
                name: 'show_header_logo',
                label: tr("Show logo in Taimer header"),
            }*/
        ];

        if (!loaded)
            return <div><img className='main-page-loading-indicator' src={require('../../dashboard/insights/img/loading.svg').default} /></div>
        

        return <div className="SettingsGrid" id="company-logo">
            <SettingsGrid errors={errors} settings={settings} item={data} company={this.props.company} onChange={this.onChange} />
        </div>
    }
}

export default CompanyLogo;