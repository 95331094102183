import React from 'react';
import Loading from '../dashboard/insights/img/loading.svg';

const LoadingOverlay = props => {
    return (
        <div className="ag-overlay-loading-center">
          <object
            style={{ height: 200, width: 200 }}
            type="image/svg+xml"
            data={Loading}
            aria-label="loading"
          ></object>
        </div>
      );
};

export default LoadingOverlay;