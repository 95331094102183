import React, { useState } from 'react';
import { isEqual } from 'lodash';
import { ProposalContentSection } from '../types';
import { DescriptionOutlined, Edit, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowUp, RemoveRedEye, Visibility, VisibilityOff } from '@mui/icons-material';
import { Drawer } from '@mui/material';
import TaimerComponent from '../../../TaimerComponent';
import styles from '../ProposalEditor.module.css';

interface ArrangementListProps {
    items: ProposalContentSection[];
    onTitleEdit: (index, value) => void;
    drawerMode?: boolean;
    onMoveSectionUp: (sectionIndex: number) => void;
    onMoveSectionDown: (sectionIndex: number) => void;
    onToggleSectionHidden: (sectionIndex: number) => void;
    loadingProposal: boolean;
    readMode: boolean;
}

export class ArrangementList extends TaimerComponent<ArrangementListProps> {
    constructor(props, context) {
        super(props, context, 'projects/proposal/sections/ArrangementList');
    }

    shouldComponentUpdate = (oldProps) => {
        return !isEqual(oldProps.items, this.props.items);
    };

    render() {
        const { items, onTitleEdit, drawerMode, onMoveSectionUp, onMoveSectionDown, onToggleSectionHidden, loadingProposal, readMode } = this.props;
        const _inputs: any = {};
        const _focusNameInput = (index) => {
            _inputs[index] && _inputs[index].focus();
        };
        return (
            <div className={`${styles.arrangementList} ${drawerMode && styles.drawerMode}`}>
                <h2>{this.tr('Arrangement')}</h2>
                <div>
                    {loadingProposal ? <p>{this.tr('Loading proposal...')}</p> : (items.length == 0 && <p>{this.tr('No sections. Start by creating one!')}</p>)}
                    {items.map((item, i) => {
                        return (
                            <div key={i} className={styles.arrangementListRow}>
                                {i + 1}
                                <div onClick={() => _focusNameInput(i)} className={styles.nameContainer}>
                                    <DescriptionOutlined />
                                    <input ref={(ref) => (_inputs[i] = ref)} disabled={readMode} onFocus={(e) => e.target.select()} onChange={(e) => onTitleEdit(i, e.target.value)} value={item.title} />
                                    {!readMode && <Edit className={styles.editIcon} />}
                                </div>
                                {!readMode && <div className={styles.reorder}>
                                    <button disabled={i == 0} onClick={() => onMoveSectionUp(i)}>
                                        <KeyboardArrowUp />
                                    </button>
                                    <button disabled={i == items.length - 1} onClick={() => onMoveSectionDown(i)}>
                                        <KeyboardArrowDown />
                                    </button>
                                </div>}
                                {!readMode && <button onClick={() => onToggleSectionHidden(i)}>{item.hidden ? <VisibilityOff /> : <Visibility />}</button>}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

interface ArrangementListDrawerProps extends ArrangementListProps {
    toggleButtonTitle: string;
}

export const ArrangementListDrawer = (props: ArrangementListDrawerProps) => {
    const [open, setOpen] = useState(false);
    const { toggleButtonTitle, ...arrangementListProps } = props;
    return (
        <>
            <Drawer anchor="right" onClose={() => setOpen(false)} open={open}>
                <ArrangementList drawerMode {...arrangementListProps} />
            </Drawer>
            <button onClick={() => setOpen(true)} className={styles.arrangementDrawerToggle}>
                <KeyboardArrowLeft />
                <span>{toggleButtonTitle}</span>
            </button>
        </>
    );
};
