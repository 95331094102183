import TaimerComponent from '../TaimerComponent';
import Link from './../general/Link';

interface Props {
    value: string;
    url: string;
    data: object;
    colDef: {
      field: string;
    };
    node: {
      field: string;
      group: boolean;
      footer: boolean;
      expanded: boolean;
      aggData: object;
      allLeafChildren: {data}[];
    };
    column: {colId: string};
    groupedDataValue?: string | number;
    displayLinkAsText?: (row: any) => boolean;
}

export default class LinkCellRenderer extends TaimerComponent<Props> {
  render() {
    const { displayLinkAsText } = this.props;

    const data = this.props.groupedDataValue 
      ? this.props.groupedDataValue 
      : this.props.data && this.props.data[this.props.colDef.field];

    let url = this.props.url;
    if(!url || !data || !data.name) {
      return <div/>;
    }
    const matches = [...url.matchAll(/\$\(([a-zA-Z0-9]+)\)/g)]; // eg. $(id)
    if(Array.isArray(data.name)) {
      const links: JSX.Element[] = [];
      for(let i = 0; i < data.name.length; i++) {
        if(!data.name[i]) {
          continue;
        }

        let linkUrl = url; // So urls work for multiple links. When url id was replaced, all links had the same url.
        matches.forEach(el => {
          if(el[1] && data[el[1]][i]) {
            linkUrl = url.replace(el[0], data[el[1]][i]);
          }
        });
        const name =  data?.name[i];
        if (data.hidden && data.hidden[i]) {
          continue;
        }
        if(data.as_text && data.as_text[i]) {
          links.push(<span>{name}</span>);
        } else {
          links.push(<Link url={linkUrl}>{name}</Link>);
        }
        links.push(<span>, </span>)
      }
      links.pop();
      return <div>{links}</div>
    } else {
      data && matches.forEach(el => {
        if(el[1] && data[el[1]]) {
          url = url.replace(el[0], data[el[1]]);
        }
      });
      let name =  data?.name || url;
      if (data.hidden) {
        name = "";
      }

      if (data.as_text || displayLinkAsText?.(data)) {
        return name;
      }
      return <Link url={url}>{name}</Link>
    }
  }
}
