import React from 'react';
import ReactDOM from 'react-dom';
import Taimer from './Taimer';
import ErrorBoundary from './error/ErrorBoundary';
import { unregister } from './registerServiceWorker';
unregister();

if (document.getElementById('test-content'))
    ReactDOM.render(<ErrorBoundary><Taimer /></ErrorBoundary>, document.getElementById('test-content'));
else
    ReactDOM.render(<ErrorBoundary><Taimer /></ErrorBoundary>, document.getElementById('main-navigation'));
    
