import React from 'react';
import "./../general/TextInput.css";
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
	input: {
		fontSize: "12px",
		margin: "4px 0 0 0",
		background: "#fdfdfd",
		height: "80%",
		borderRadius: 0,
		border: "1px solid #2d9ff7",
	},
	error: {
		border: `1px solid ${theme.palette.taimer.error}`,
		color: theme.palette.taimer.error
	}
});

class TextInput extends React.Component {
	static defaultProps = {
		className: "",
		initValue: "",
		placeholder: "",
		error: false,
		focusOnMount: false,
		textAlign: "left",
		overRunNativeValue: false,
		onInputListener: () => {},
		onFocus: () => {},
		onBlur: () => {},
		onKeyUp: () => {},
		onEnter: () => {},
		onOverLimit: () => {},
		classNames: {
			error: "error",
			noError: ""
		}
	};


	constructor(props) {
		super(props);

		this.inputRef = React.createRef();
		this.onInput  = this.onInput.bind(this);
		this.onKeyUp  = this.onKeyUp.bind(this);
		this.state    = { value: props.initValue };
	}


	componentDidMount() {
		if(this.props.focusOnMount)
			this.inputRef.current.focus();
		this.setState({value: this.props.initValue})
	}


	onInput(event) {
		event.persist();
		let value = event.target.value;
		const limit = Number(this.props.limit);
		if (limit) {
			value = this.checkLimit(event);
		}

		this.setState({
			value: value
		}, () => {
			if(typeof this.props.onInputListener == "function")
				this.props.onInputListener(value, event);
		});
	}


	setValue(value) {
		this.setState({ value: value });
	}


	getValue() {
		return this.state.value;
	}


	focus() {
		this.inputRef.current && this.inputRef.current.focus();
	}


	select() {
		this.inputRef.current && this.inputRef.current.select();
	}


	onKeyUp(event) {
		let value = event.target.value;
		const limit = Number(this.props.limit);
		if (limit) {
			value = this.checkLimit(event);
			event.target.value = value;
		}
		this.props.onKeyUp(event);

		if(event.keyCode === 13)
			this.props.onEnter(event);
	}

	checkLimit = (e) => {
        const { limit, onOverLimit } = this.props;
		const { value } = this.state;
        const limitValue = Number(limit);

        if (limitValue) {
            const newLength = e.target.value.length;
            const restrictNewValue = newLength > value.length && newLength > limitValue;

            if (restrictNewValue && value.length <= limitValue) { // Old value is not yet over limit. Slice new value max to limit.
				onOverLimit && onOverLimit(limit);
                return e.target.value.slice(0, limit);
            }
            else if (restrictNewValue && value.length > limitValue) { // Old value is already over limit. Return old value in onChange.
				onOverLimit && onOverLimit(limit);
                return value;
            }   
            else { // New value is not over limit or new length is less than old length.
				return e.target.value;
            }
        }
        else {
            return e.target.value;
        }
    }


	render() {
		const className = `textInput ${this.props.classes.input} ${this.props.className} ${this.props.error ? this.props.classes.error : ""}`;
;
		const value = this.props.overRunNativeValue ? this.props.initValue : (this.state.value || this.props.initValue);

		return (
			<input
				type={this.props.type || "text"}
				className={className}
				ref={this.inputRef}
				value={value}
				onChange={this.onInput}
				onKeyUp={this.onKeyUp}
				onFocus={this.props.onFocus}
				onBlur={this.props.onBlur}
				placeholder={this.props.placeholder}
				style={{ textAlign: this.props.textAlign }} />
		);
	}
}

export default withStyles(styles, {withTheme: true})(TextInput);
