import React from 'react';
import TaimerComponent from "../../TaimerComponent"

/* material ui */
import { Button } from '@mui/material';

import Taimer from "../../Taimer"
import { SettingsContext } from '../../SettingsContext';

import { ReactComponent as OverlayImage } from './InvoiceList.svg';

class InvoiceListOverlay extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'list/overlays/InvoiceListOverlay');
    }

    render () {

        return (
            <div className="list-overlay">
                <OverlayImage style={{width: "auto", height: '260px'}} />
                <div className="text">
                    {this.tr("Create Your First Invoice") }
                </div>
                <Button
                    color="primary"
                    onClick={() => this.context.functions.updateView({ module: 'invoices', action: 'view', editMode: 1 })}
                >
                {this.tr("CREATE INVOICE") }
                </Button>
             </div>
        );
    }

}

export default InvoiceListOverlay;