import React from "react";
import TaimerComponent from "../../TaimerComponent";
import DataList from "../../general/DataList";
import { Chip, Switch } from "@mui/material";
import { Clear } from "@mui/icons-material";
import DataHandler from "../../general/DataHandler";

import "./Goals.scss";
import { withSnackbar } from "notistack";

const GOALS_IDS = {
  gross_profit: 3,
  gross_margin: 4,
  net_profit: 5,
  net_margin: 6,
};

class Goals extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "settings/pages/Goals");

    this.goals = [
      {
        id: GOALS_IDS.gross_profit,
        label: this.tr("Gross Profit"),
      },
      {
        id: GOALS_IDS.gross_margin,
        label: this.tr("Gross Margin"),
      },
      {
        id: GOALS_IDS.net_profit,
        label: this.tr("Net Profit"),
      },
      {
        id: GOALS_IDS.net_margin,
        label: this.tr("Net Margin"),
      },
    ];

    this.state = {
      selectedGoals: [],
      selectedProjectTypes: [],
      activateCommitment: false,
      projectTypes: [],
    };
  }

  componentDidMount = () => {
    super.componentDidMount();
    this.getSettings();
    this.getProjectTypesAndSettings();
  };

  getProjectTypesAndSettings = () => {
    DataHandler.get({
      url: `subjects/project_types/${this.props.company}`,
    }).done((projectTypes) => {
      this.setState({
        projectTypes,
      });
    });
  };

  getSettings = () => {
    DataHandler.get({
      url: `settings/company/${this.props.company}/goals`,
    }).done((response) => {
      const activateCommitment = response.activate_commitment == 1;
      const selectedProjectTypes = response.project_types
        ? response.project_types.split(",")
        : [];
      const selectedGoals = [];
      if (response.gross_profit == 1 || response.profit == 1) {
        selectedGoals.push(
          this.goals.find((g) => g.id == GOALS_IDS.gross_profit)
        );
      }
      if (response.gross_margin == 1 || response.margin == 1) {
        selectedGoals.push(
          this.goals.find((g) => g.id == GOALS_IDS.gross_margin)
        );
      }
      if (response.net_profit == 1) {
        selectedGoals.push(
          this.goals.find((g) => g.id == GOALS_IDS.net_profit)
        );
      }
      if (response.net_margin == 1) {
        selectedGoals.push(
          this.goals.find((g) => g.id == GOALS_IDS.net_margin)
        );
      }
      this.setState({
        activateCommitment,
        selectedGoals,
        selectedProjectTypes,
      });
    });
  };

  saveSettings = () => {
    const {
      selectedGoals,
      activateCommitment,
      selectedProjectTypes,
    } = this.state;
    const data = {
      gross_profit: Number(
        selectedGoals.findIndex((g) => g.id == GOALS_IDS.gross_profit) != -1
      ),
      gross_margin: Number(
        selectedGoals.findIndex((g) => g.id == GOALS_IDS.gross_margin) != -1
      ),
      net_profit: Number(
        selectedGoals.findIndex((g) => g.id == GOALS_IDS.net_profit) != -1
      ),
      net_margin: Number(
        selectedGoals.findIndex((g) => g.id == GOALS_IDS.net_margin) != -1
      ),
      activate_commitment: Number(activateCommitment),
      project_types: selectedProjectTypes.join(","),
    };
    DataHandler.post(
      { url: `settings/company/${this.props.company}/goals` },
      data
    ).done(() => {
      const { enqueueSnackbar } = this.props;
      enqueueSnackbar(this.tr("Changes saved successfully!"), {
        variant: "success",
      });
    });
  };

  addSelectedGoal = (goal) => {
    const selectedGoals = [...this.state.selectedGoals];
    selectedGoals.push(goal);
    this.setState({ selectedGoals }, () => {
      this.saveSettings();
    });
  };

  removeSelectedGoal = (e) => {
    const { enqueueSnackbar } = this.props;
    const selectedGoals = [...this.state.selectedGoals];
    if (selectedGoals.length == 1) {
      enqueueSnackbar(
        this.tr("You should always have at least one goal selected."),
        {
          variant: "error",
        }
      );
      return;
    }
    const index = selectedGoals.findIndex(
      (a) => a.id == e.currentTarget.parentElement.id
    );
    if (index != -1) {
      selectedGoals.splice(index, 1);
    }
    this.setState({ selectedGoals }, () => {
      this.saveSettings();
    });
  };

  addSelectedProjectType = (projectType) => {
    const { enqueueSnackbar } = this.props;
    const selectedProjectTypes = [...this.state.selectedProjectTypes];
    if (selectedProjectTypes.length == 3) {
      enqueueSnackbar(this.tr("You can only select 3 project types at once."), {
        variant: "error",
      });
      return;
    }
    selectedProjectTypes.push(projectType.id);
    this.setState({ selectedProjectTypes }, () => {
      this.saveSettings();
    });
  };

  removeSelectedProjectType = (e) => {
    const selectedProjectTypes = [...this.state.selectedProjectTypes];
    const index = selectedProjectTypes.findIndex(
      (a) => a == e.currentTarget.parentElement.id
    );
    if (index != -1) {
      selectedProjectTypes.splice(index, 1);
    }
    this.setState({ selectedProjectTypes }, () => {
      this.saveSettings();
    });
  };

  toggleCommitment = (e) => {
    const activateCommitment = e.target.checked;
    this.setState(
      {
        activateCommitment,
      },
      () => {
        this.saveSettings();
      }
    );
  };

  render() {
    const {
      selectedGoals,
      activateCommitment,
      projectTypes,
      selectedProjectTypes,
    } = this.state;
    return (
      <div id="goals-settings">
        <div className="setting-block">
          <h3>{this.tr("Goals to track")}</h3>
          <p className="subtitle">
            {this.tr(
              "Select goals to track. You can choose to track multiple goals. Trackable goals are Gross Profit, Gross Margin, Net Profit and Net Margin."
            )}
          </p>
          <DataList
            label={this.tr("Choose goals to track")}
            name="goal"
            options={this.goals.filter(
              (g) => selectedGoals.findIndex((sg) => sg.id == g.id) == -1
            )}
            onChange={this.addSelectedGoal}
            className="goal-data-list"
            shownCount={20}
          />
          <div className="selected-tags">
            {selectedGoals.map((row) => (
              <Chip
                id={row.id}
                key={row.id}
                label={row.label}
                className="square"
                onDelete={this.removeSelectedGoal}
                deleteIcon={<Clear />}
              />
            ))}
          </div>
        </div>
        <div className="setting-block">
          <h3>{this.tr("Commitment to get a second target")}</h3>
          <p className="subtitle">
            {this.tr(
              "Commitment is budgeted together with target, once a year, and can be used to e.g. incentivise management team with an increased goal vs. the company main target. In addition a forecast can be made separately."
            )}
          </p>
          <div className="commitment-container">
            <p>{this.tr("Activate Commitment")}</p>
            <Switch
              onChange={this.toggleCommitment}
              checked={activateCommitment}
              color="primary"
            />
          </div>
        </div>
        <div className="setting-block">
          <h3>{this.tr("Advanced tracking of Project Types")}</h3>
          <p className="subtitle">
            {this.tr(
              "You can also choose to follow up on sales to a deeper level, by creating Project Types. Types can be e.g. Fee, Project, One-off and more. You can choose up to 3 different types of projects you wish to follow up on sales. This will enable you also on account level to be able to assign goals to the same chosen types."
            )}
          </p>
          <DataList
            label={this.tr("Choose project type")}
            name="project_type"
            allowCreate={true}
            options={projectTypes.filter(
              (pt) => selectedProjectTypes.findIndex(spt => spt == pt.id) == -1
            )}
            onChange={this.addSelectedProjectType}
            className="goal-data-list"
            shownCount={20}
          />
          <div className="selected-tags">
            {selectedProjectTypes.map((id) => {
              const projectType = projectTypes.find((pt) => pt.id == id) || {
                id,
                label: "",
              };
              return (
                <Chip
                  id={projectType.id}
                  key={projectType.id}
                  label={projectType.label}
                  className="square"
                  onDelete={this.removeSelectedProjectType}
                  deleteIcon={<Clear />}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(Goals);
