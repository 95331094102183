import React from 'react';
import {
    Tabs,
    Tab,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Collapse,
    Switch,
    Popover,
} from "@mui/material";
import {
    Check,
    RemoveRedEye,
    ExpandMore,
    ExpandLess,
    ViewColumn as ViewColumnIcon,
    CheckBoxOutlineBlank,
    CheckBox,
    Person,
    AccessTime,
} from "@mui/icons-material";

import TaimerComponent from '../../TaimerComponent';
import HeaderButton from './HeaderButton';
import InsightDropDown from '../../dashboard/insights/InsightDropDown';

import cn from 'classnames';
import styles from './Controls.module.scss';
import { ViewColumn, ViewGrouping, ViewMode, ViewZoom } from '../views/ViewConfiguration';
import { ExpandData, ProjectsShowMode, validview, ViewOptionName, ViewOptions, ViewSettings } from '../ResourcingView';

/* Images */
import { ReactComponent as ColumnsIcon } from "../images/ColumnsIcon.svg";
import { ReactComponent as TaskCompletedIcon } from "../images/TaskCompletedIcon.svg";
import { ReactComponent as TaskOngoingIcon } from "../images/TaskOngoingIcon.svg";
import { ReactComponent as ShowProjectTreeIcon } from "../images/ShowProjectTree.svg";
import { ReactComponent as ShowMatchesIcon } from "../images/ShowMatches.svg";

import GeneralIcons from "../../general/GeneralIcons";
import ViewOptionsSlider from './ViewOptionsSlider';

const PercentIcon = props => {
    return <div {...props}>%</div>;
};

const viewModeIcons = {
    percent: PercentIcon,
    available: AccessTime,
    hours: AccessTime,
};

export interface ControlsProps {
    view: validview;
    columns?: ViewColumn[];
    groupings?: ViewGrouping[];
    settings?: ViewSettings;
    zooms?: ViewZoom[];
    viewModes?: ViewMode[];
    viewOptions: ViewOptions;
    updateSettings?: (update: Partial<ViewSettings>) => void;
    toggleColumn?: (name: string) => void;
    toggleViewOption: (name: ViewOptionName, value: boolean | undefined | ProjectsShowMode) => void;
    setViewOptions: (viewOptions: Partial<ViewOptions>) => void;
    setProjectsShowMode: (value: ProjectsShowMode) => void;
    expand: ExpandData;
    toggleExpand: (type: keyof ExpandData) => void;
}

interface ControlsState {
    /**
     * 
     */
    hideColumnsMenuOpen: boolean;
    viewOptionsSliderOpen: boolean;
}

interface Option {
    key: string;
    label: string;
    action: () => void;
    icon?: React.ReactNode;
    checked: boolean;
    visible: boolean;
}

interface ExpandOption {
    key: keyof ExpandData;
    text: string;
}

class Controls extends TaimerComponent<ControlsProps, ControlsState> {
    constructor(props: ControlsProps, context) {
        super(props, context, "resourcing/components/Controls");

        this.state = {
            hideColumnsMenuOpen: false,
            viewOptionsSliderOpen: false,
        };
    }

    componentDidMount(): void {
        super.componentDidMount();
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
    }

    _toggleColumn = (name: string) => {
        const { toggleColumn } = this.props;

        toggleColumn?.(name);
    }

    _renderColumnsPopover = () => {
        const { columns, toggleViewOption, setProjectsShowMode, view, viewOptions } = this.props;
        const { hideColumnsMenuOpen } = this.state;
        const { tr } = this;

        return (
            <List
                style={{
                    position: "absolute",
                    overflow: "hidden",
                    borderRadius: 5,
                    boxSizing: "border-box"
                }}
                className="gridColumnMenuListPopper"
                component="div"
                onClick={e => e.stopPropagation()}>
                {columns && columns.filter(x => x.allowHiding).map(column => <ListItem
                    key={column.name}
                    button
                    style={{ paddingLeft: "30px" }}
                    className="muiListItem"
                    onClick={() => this._toggleColumn(column.name)}>
                    <ListItemIcon className="smallicons">
                        {column.hide ? (
                            <CheckBoxOutlineBlank />
                        ) : (
                            <CheckBox />
                        )}
                    </ListItemIcon>
                    <ListItemText inset primary={column.label} />
                </ListItem>)}
            </List>            
        );
    }

    _setGrouping = (grouping: string) => {
        const { updateSettings } = this.props;

        updateSettings?.({ grouping });
    }

    _renderGroupings = () => {
        const { groupings, settings } = this.props;
        const { tr } = this;

        if (!settings || !groupings)
            return null;

        return (
            <InsightDropDown
                title={tr("Grouping")}
                tabs={groupings.map(g => ({
                    key: g.name,
                    label: g.label,
                    action: () => this._setGrouping(g.name),
                }))} selected={settings.grouping} />
        );
    }

    _setZoom = (zoom: string) => {
        const { updateSettings } = this.props;

        updateSettings?.({ zoom });
    }

    _renderZooms = () => {
        const { zooms, settings } = this.props;
        const { tr } = this;

        if (!settings || !zooms)
            return null;

        return (
            <InsightDropDown
                title={tr("Show")}
                tabs={zooms.map(g => ({
                    key: g.name,
                    label: g.label,
                    action: () => this._setZoom(g.name),
                }))} selected={settings.zoom} />
        );
    }

    _setViewMode = (viewMode: string) => {
        const { updateSettings } = this.props;

        updateSettings?.({ viewMode });
    }

    _renderViewModes = () => {
        const { viewModes, settings } = this.props;
        const { tr } = this;

        if (!settings || !viewModes)
            return null;

        return (
            <InsightDropDown
                title={tr("Mode")}
                tabs={viewModes.map(g => ({
                    key: g.name,
                    label: g.label,
                    action: () => this._setViewMode(g.name),
                    iconComponent: viewModeIcons[g.name],
                }))} selected={settings.viewMode} />
        );
    }

    _renderExpandCollapsePopover = () => {
        const { view, settings, expand, toggleExpand } = this.props;

        if (!settings)
            return null;

        const options: ExpandOption[] = [];

        if (view === "gantt" && settings.grouping === "customer") {
            options.push({ key: 'all', text: 'All' });
            options.push({ key: 'subprojects', text: 'Sub-Projects' });
            options.push({ key: 'tasks', text: 'Tasks' });
            options.push({ key: 'subtasks', text: 'Sub-Tasks' });
        } else if (view === "gantt") {
            options.push({ key: 'all', text: 'All' });
        } else if (view === "grid") {
            options.push({ key: 'all', text: 'All' });
            options.push({ key: 'users', text: 'Users' });
            options.push({ key: 'projects', text: 'Projects' });
        }

        const isAllExpanded = options.filter(x => x.key !== 'all').every(x => !!expand[x.key]);

        if (!options.length)
            return null;

        return (
            <List
                style={{
                    position: "absolute",
                    overflow: "hidden",
                    borderRadius: 5,
                    boxSizing: "border-box"
                }}
                className="gridColumnMenuListPopper"
                component="div"
                onClick={e => e.stopPropagation()}
            >
                {options.map(x => x && <ListItem
                    key={x.key}
                    button
                    className="muiListItem"
                    onClick={() => toggleExpand(x.key)}
                >
                    <ListItemIcon className="bigicons">
                        {expand[x.key] ? <GeneralIcons.collapse /> : <GeneralIcons.expand />}
                    </ListItemIcon>
                    <ListItemText inset primary={this.tr(`${(expand[x.key] ? 'Collapse' : 'Expand')} ${x.text}`)} />
                </ListItem>)}
            </List>
        );
    }

    viewOptionsSliderOpen = () => {
        this.setState({viewOptionsSliderOpen: true});
    }

    viewOptionsSliderClose = () => {
        this.setState({viewOptionsSliderOpen: false});
    }

    render() {
        const { view, setViewOptions, viewOptions } = this.props;
        const { viewOptionsSliderOpen } = this.state;
        const { tr } = this;

        return <div className={styles.root}>
            <div className={styles.actions}>
                {this._renderGroupings()}
                {this._renderViewModes()}

                {(view === 'gantt' || view === 'grid') && <HeaderButton
                    icon={<GeneralIcons.collapse className='button-icon' />}
                    isDropdown
                    isMultiSelect
                    popover={this._renderExpandCollapsePopover()}
                    text={tr("Expand / Collapse")}
                />}

                {(view !== "list") && <HeaderButton
                    icon={<ViewColumnIcon className="button-icon" />}
                    isDropdown
                    isMultiSelect
                    popover={this._renderColumnsPopover()}
                    text={tr("Columns")} />}

                <HeaderButton
                    icon={<RemoveRedEye className="button-icon" />}
                    // isDropdown
                    isMultiSelect
                    onClick={this.viewOptionsSliderOpen}
                    text={tr("View")}
                />
            </div>
            <div>
                {this._renderZooms()}
            </div>

            <ViewOptionsSlider open={viewOptionsSliderOpen} view={view} viewOptions={viewOptions} setViewOptions={setViewOptions} onClose={this.viewOptionsSliderClose} />
        </div>
    }
}

export default Controls;