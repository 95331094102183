import React from 'react';
import './Efina.css';
import './SAP.css';
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";
import DataHandler from '../../general/DataHandler';
import { SettingsContext } from '../../SettingsContext';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import SettingsGrid from '../pages/SettingsGrid';

class SAP extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/SAP");

        this.state = {
            username: "",
            password: "",
            currentDialog: false,
            activated: 0,
            send_sap_invoices_immediately: 0
        };

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getIntegrationSettings();
    }

    getAuthData = () => {
        DataHandler.get({url: `integrations`, company_id: this.props.company, integration: 'sap'}).done(response => {
            this.setState(response);
        });
    }

    getIntegrationSettings = async () => {
        const integrationSettings = await DataHandler.post({ url: `/integrations/get_settings`}, { settings: ['send_sap_invoices_immediately'], company: this.props.company, integration: "sap" });
        this.setState(integrationSettings);
    }

    saveAuth = () => {
		const { username, password } = this.state;

		DataHandler.put(
			{ url: `integrations` },
			{ integration: 'sap', 'company_id': this.props.company, username: username, password: password }
		).done(resp => {
            this.props.enqueueSnackbar(this.tr("SAP S/4HANA authentication information saved"), {
                variant: "success"
            });	
            this.updateComponentData();
        });
	};

    changeDefault = (type, id) => {
        const company = this.props.company;
        this.setState({[type]: id}, () => DataHandler.put({url: `accounting/defaults/${company}`}, {type: type, id: id }));
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        this.setState({
            dialogData: {
                saveFunc: () => DataHandler.delete({url: `integrations`, company_id: this.props.company, integration: 'sap'}).done(() => {
                    this.props.enqueueSnackbar(this.tr("SAP S/4HANA authentication deactivated"), {
                        variant: "success"
                    });	
                    this.back();
                }),
                text: this.tr("Do you want to deactivate SAP S/4HANA integration?")
            }
        }, () => this.openDialog('confirmation'));
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    onSettingCheckChange = (update, name, value) => {
        this.setState({[name]: value}, () =>  DataHandler.post({url: `integrations/custom_settings`}, {company_id: this.props.company, integration: 'sap', settings: {[name]: value}}));
    }
    
    render(){
        const { tr } = this;

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        const settings = { send_sap_invoices_immediately: this.state.send_sap_invoices_immediately };

        return (
            <div id="integration-sap" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("SAP S/4HANA Settings")}</h3>
                    {this.state.activated > 0 && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <p className='subtitle'>{tr("This integration is for sending invoices to SAP S/4HANA.")}</p>

                <div className="settings">
					<h4>{tr("Authentication information")}</h4>
					<OutlinedField
						value={this.state.username}
                        name="username"
						onChange={evt => this.setState({ username: evt.target.value })}
						label={tr("SFTP username")}
					/>
					<OutlinedField
						value={this.state.password}
                        name="password"
                        type="password"
						onChange={evt => this.setState({ password: evt.target.value })}
						label={tr("SFTP password")}
                        autoComplete="new-password"
					/>             
					<div className="settings-buttons">
						<Button data-testid="save_sap" className="blue" color="primary" onClick={this.saveAuth} size="large">
							{tr("Save")}
						</Button>
					</div>
				</div>

                <div className="settings">
					<h4>{tr("Integration settings")}</h4>
                    <SettingsGrid item={settings} onChange={this.onSettingCheckChange} settings={[{
                        type: "check",
                        name: "send_sap_invoices_immediately",
                        label: tr("Send invoices to SAP directly after creation"),
                        infoTooltip: this.tr("If activated, all created invoices will be sent directly to SAP after they are created. Should only be used if no review of the invoices is needed after creation."),
                    }]}/>
                </div>

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
            </div>
        )
    }
}

export default SAP;