import React from 'react';
import ListCell from './../list/ListCell';
import { InsightTableListRow } from "./../dashboard/insights/InsightTable";
import styles from './BudgetedVsActualCostRow.module.scss';

export default class BudgetedVsActualCostRow extends InsightTableListRow {
    constructor(props, context) {
        super(props, {}, {}, "projects/BudgetedVsActualCostRow");
    }

    defineClassName() {
        return styles.row;
    }
    
    defineCells() {
        const { data, sharedData: { currency } } = this.props;
        const { taimerAccount, userObject } = this.context;

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency,
        }).format;

        let cells = {};

        Object.keys(data).forEach(key => {
            switch (key) {
                case "type_name":
                    cells[key] = (
                        <ListCell onlyDisplay={true}>
                            <div className="list-cell-container">
                                <p>{this.tr(data[key])}</p>
                            </div>
                        </ListCell>
                    );
                    break;
                case "budgeted_cost":
                case "actual_cost":
                case "remaining_cost":
                    cells[key] = (
                        <ListCell onlyDisplay={true}>
                            <div className="list-cell-container">
                                <p>{data[key] ? currencyFormatter(Number(data[key])) : "-"}</p>
                            </div>
                        </ListCell>
                    );
                    break;
                case "variance":
                    cells[key] = (
                        <ListCell onlyDisplay={true}>
                            <div className={`list-cell-container ${Number(data[key]) < 0 ? styles.negative : ''}`}>
                                <p>{data[key] ? currencyFormatter(Number(data[key])) : "-"}</p>
                            </div>
                        </ListCell>
                    );
                    break;
                default:
                    break;
            }
        });

        cells = {
            ...super.defineCells(),
            ...cells
        };

        return cells;
    }
}
