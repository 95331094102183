import { Button } from '@mui/material';
import moment from 'moment';
import UpgradeVersionView from '../../general/UpgradeVersionView';
import VersionContentManager from '../../general/VersionContentManager';
import TaimerComponent from '../../TaimerComponent';
import styles from './TrialPlanHandling.module.scss';

type Plan = {
    id: number;
    name: string;
};

class TrialPlanHandling extends TaimerComponent {
    plans: Plan[] = [];
    constructor(props, context) {
        super(props, context, 'settings/pages/TrialPlanHandling');
        this.plans = [
            {
                id: 11,
                name: this.tr('Growth'),
            },
            {
                id: 12,
                name: this.tr('Business'),
            },
            {
                id: 4,
                name: this.tr('Enterprise'),
            },
        ];
    }

    buyPlan = (plan) => {
        this.context.functions.showUpgradeSlider(plan);
    };

    render() {
        const currentVersion = VersionContentManager.getCurrentVersion();
        const trialDays = this.context.taimerAccount.trialStatus > 0 ? moment(this.context.taimerAccount.trialEnds).endOf('day').diff(moment(new Date()).startOf('day'), 'days') : 0;
        return (
            <div className={styles.trialPlanHandling}>
                <div className={styles.top}>
                    <h1>{this.tr('Manage plan')}</h1>
                    <p>{this.tr('You have ${trialDays} days left in your trial period.', { trialDays })}</p>
                </div>
                <div className={styles.content}>
                    <div className={styles.cards}>
                        {this.plans.map((plan) => {
                            return (
                                <div className={styles.cardContainer} key={plan.id}>
                                    <div className={`${styles.card} ${plan.id == currentVersion.id ? styles.current : ''}`}>
                                        <div className={styles.cardTop}>
                                            <h2>{plan.name}</h2>
                                            <p className={styles.description}>{this.tr(`${plan.name}_description`)}</p>
                                            <p className={styles.userLimit}>{this.tr(`${plan.name}_userlimit`)}</p>
                                            {this.tr(`${plan.name}_regular_price`).trim() && (
                                                <p className={styles.regularPrice}>
                                                    {this.tr(`${plan.name}_regular_price`)}/{this.tr('mo/user')}
                                                </p>
                                            )}
                                            <p className={styles.price}>
                                                <strong>{this.tr(`${plan.name}_campaign_price`)}</strong>/{this.tr('mo/user')}
                                            </p>
                                            <Button size="large" onClick={() => this.buyPlan(plan)}>
                                                {plan.id == currentVersion.id ? this.tr('Buy current plan') : this.tr('Select')}
                                            </Button>
                                        </div>
                                        <div className={styles.cardBottom}>
                                            <h3>{this.tr(`${plan.name}_feature_header`)}</h3>
                                            <ul>
                                                {this.tr(`${plan.name}_feature_1`).trim() && <li>{this.tr(`${plan.name}_feature_1`)}</li>}
                                                {this.tr(`${plan.name}_feature_2`).trim() && <li>{this.tr(`${plan.name}_feature_2`)}</li>}
                                                {this.tr(`${plan.name}_feature_3`).trim() && <li>{this.tr(`${plan.name}_feature_3`)}</li>}
                                                {this.tr(`${plan.name}_feature_4`).trim() && <li>{this.tr(`${plan.name}_feature_4`)}</li>}
                                                {this.tr(`${plan.name}_feature_5`).trim() && <li>{this.tr(`${plan.name}_feature_5`)}</li>}
                                                {this.tr(`${plan.name}_feature_6`).trim() && <li>{this.tr(`${plan.name}_feature_6`)}</li>}
                                                {this.tr(`${plan.name}_feature_7`).trim() && <li>{this.tr(`${plan.name}_feature_7`)}</li>}
                                                {this.tr(`${plan.name}_feature_8`).trim() && <li>{this.tr(`${plan.name}_feature_8`)}</li>}
                                                {this.tr(`${plan.name}_feature_9`).trim() && <li>{this.tr(`${plan.name}_feature_9`)}</li>}
                                            </ul>
                                            {this.tr(`${plan.name}_addon_header`).trim() && <h3>{this.tr(`${plan.name}_addon_header`)}</h3>}
                                            <ul>
                                                {this.tr(`${plan.name}_addon_1`).trim() && <li>{this.tr(`${plan.name}_addon_1`)}</li>}
                                                {this.tr(`${plan.name}_addon_2`).trim() && <li>{this.tr(`${plan.name}_addon_2`)}</li>}
                                                {this.tr(`${plan.name}_addon_3`).trim() && <li>{this.tr(`${plan.name}_addon_3`)}</li>}
                                            </ul>
                                        </div>
                                    </div>
                                    {this.tr(`${plan.name}_campaign`).trim() && <div className={styles.campaign}>{this.tr(`${plan.name}_campaign`)}</div>}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default TrialPlanHandling;
