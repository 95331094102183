import React from 'react';
import MailLogin from "../mail/MailLogin";
import PageTopSection from '../general/PageTopSection';
 
import './TabMessenger.scss';

class TabMessenger extends React.Component {

    constructor(props, context) {
        super(props, context, "projects/TabMessenger");
    }
    
    render () {
      
        return (
            <div id="projects-messenger">
                {this.props.header && (
                    <PageTopSection
                        header={this.props.header}
                        subheaders={this.props.subheaders}
                    />
                )}
                <MailLogin projectid={this.props.id} className="project"/>
            </div>
        );
    } 
}


//export default withStyles()(TabMessenger);
export default TabMessenger;