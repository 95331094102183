import React from 'react';
import ListCell from "../ListCell";
import { SettingsContext } from './../../SettingsContext';
import _ from 'lodash';
/* validator */
import validator from 'validator';
import { ClickAwayListener } from '@mui/material';

const validators = {
    empty: e => !validator.isEmpty(e, { ignore_whitespace: true })
};
class TreeDropdownCell extends React.Component {
	static contextType = SettingsContext;

	static defaultProps = {
        width: 100,
		value: 0,
		validation: [],
		editable: true,
		onEdited: () => {},
		onEnter: () => {},
		listCellProps: {},
		listCellType: ListCell
	};

	constructor(props) {
		super(props);
		this.state = {
	        valid: true
	    };


		// this.state = { editable: props.editable };
		// this.allOptions = this.flatten(props.options);
		 this.runOnEdited 	 = this.runOnEdited.bind(this);
		 this.listCell = React.createRef();
	}

	onSelect = (e) => {
		const { data } = e;

		this.props.onEdited.length === 1 ? this.props.onEdited(data) : this.props.onEdited(this.props.name, data);

		this.runOnEdited(e);
	}

	runOnEdited(evt) {
		const value = evt.name;
        const valid = !this.props.validation || this.props.validation.find(e => !validators[e](value)) === undefined;

		this.setState({ valid:valid });

		
		if(!valid)
			return;
			
		this.listCell.current && this.listCell.current.closeEdit();
	}

	closeEdit = () => {
		this.listCell.current && this.listCell.current.closeEdit();
	}

	render() {
		const { dropdownType: DropdownType, dropdownProps, listCellType: ListCellType, value, displayValue , error} = this.props;
		const validation = this.state.validation;

		const listCellProps  = Object.assign({
			ref: this.listCell,
			owner: this,
			width: this.props.width,
			value: displayValue,
			validation:validation,
			editable: this.props.editable,
			error: this.props.error,
			// onEnterEditMode: () => this.refDropdown.current && this.refDropdown.inputRef.current.ref.current.select(),
		}, this.props.listCellProps);

		return (
				<ListCellType {...listCellProps}>
					<ClickAwayListener onClickAway={this.closeEdit}><DropdownType
						ref={this.refDropdown}
						value={value}
						{...dropdownProps}
						error={this.state.activeIndex || this.props.displayValue  ? false : this.props.error}
						onSelect={this.onSelect}
						onEdited={e => {
							this.runOnEdited(e);
						}}
					/></ClickAwayListener>
				</ListCellType>
		);
	}
}

export default TreeDropdownCell;