import React from 'react';

/* css */
import './InvoicingInsight.css';

import _ from 'lodash';
import DataHandler from './../../../general/DataHandler';
import ContentBlock from '../../../general/ContentBlock';
import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from '../../../../src/SettingsContext';
import InsightTable from './../InsightTable';
import cn from 'classnames';

import { endOfMonth, startOfMonth, format } from "date-fns";
import withStyles from '@mui/styles/withStyles';
import InvoicingCumulativeChart from './InvoicingCumulativeChart';
import InvoicingByCostType from './InvoicingByCostType';
import NoPermissionOverlay from '../../../overlays/NoPermissionOverlay';
import InsightsBase from '../InsightsBase';
import InsightBlock from '../InsightBlock';
import InsightChart from '../InsightChart';
import StackedCurrencyChart from '../StackedCurrencyChart';
import InsightChartTable from '../InsightChartTable';
import InsightVsBar from '../InsightVsBar';
import { Doughnut } from 'react-chartjs-2';
import InsightSlider from '../InsightSlider';
import InsightHoursByUser from '../InsightHoursByUser';
import InvoicingOverView from '../../overview/Invoicing';
import ExpensesOverView from '../../overview/Expenses';
import BillableMaterialsByProject from "./BillableMaterialsByProject";
import ListRow from '../../../list/ListRow';
import ListCell from '../../../list/ListCell';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import colors from '../../../colors';

const styles = theme => ({
});

const baseState = {
	months: [],
	slider_rows: {},
	accounts: [],
	products: [],
	product_groups: [],
	projects: [],
	total: 0,
	invoicing_cost_type: {
		hours: {
			sum: 0,
		},
		scheduled: {
			sum: 0,
		},
		expense: {
			sum: 0,
		},
		quote: {
			sum: 0,
		},
		subcontract: {
			sum: 0,
		}
	},
	project_types: [],
	invoicing: {
		due: 0,
		late1: 0,
		late2: 0,
		late3: 0,
		totaldue: 0,
		totaloverdue: 0,
		data: {
			due: [],
			late1: [],
			late2: [],
			late3: [],
			totaldue: [],
			totaloverdue: [],
		}
	},
	bills_expenses: {
		bills_approved: {
			sum: 0,
			data: [],
		},
		bills_waiting: {
			sum: 0,
			data: [],
		},
		expenses_approved: {
			sum: 0,
			data: [],
		},
		expenses_waiting: {
			sum: 0,
			data: [],
		},
	},
	cumulative_invoicing: {
		sum: [],
		data: {},
		labelorder: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
	},
	invoicing_sales_agent: [],
	invoicing_project_manager: [],
	invoicing_account_manager: [],
	invoicing_teams: [],
	account_group: [],
	overview: {
		total: 0,
		paid: 0,
		unpaid: 0,
		draft: 0,
		waiting: 0,
		credited: 0,
		slider_rows: {
			paid: {},
			unpaid: {},
			draft: {},
			waiting: {},
		}
	},
	hours_invoicing: {
		users: [],
	},
	billable_materials_type: {
		sum: {
			travel: [],
			expense: [],
			hours: [],
			bills: [],
			scheduled: [],
			automatic: [],
			quote: [],
		},
		data: {
			travel: [],
			expense: [],
			hours: [],
			bills: [],
			scheduled: [],
			automatic: [],
			quote: [],
		},
		labelorder: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
	},
	billable_materials_project: {
		projects: [],
		customers: [],
		slider_rows: [],
	}
};

class InvoicingInsightSummaryRow extends ListRow {
	constructor(props, context) {
		super(
			props,
			{},
			{},
			"dashboard/insights/invoicing/InvoicingInsightSummaryRow"
		);
	}

	defineClassName() {
		return "insightSummaryRow";
	}

	defineCells() {
		const {
			rows,
			sharedData: { currency },
		} = this.props;

		const {
			functions: { presentCurrency },
		} = this.context;

		let values = {};
		rows.map((row) => {
			Object.keys(row.data || {}).forEach((key) => {
				values[key]
					? (values[key] += Number(row.data[key] || 0))
					: (values[key] = Number(row.data[key] || 0));
			});
		});

		let cells = {};

		Object.keys(this.props.columnConfig).forEach((key) => {
			cells[key] = (
				<ListCell
					width={this.props.columnConfig[key].width}
					className="totalcell"
					name="user"
					onlyDisplay
					editable={false}
					alignCell
					textAlign={key == "sum" ? "right" : "left"}
				>
					{key == "split" ? "100 %" : presentCurrency(values[key] || 0)}
				</ListCell>
			);
		});

		const totalColumns = [
			"account",
			"account_group",
			"project",
			"type",
			"category",
			"product",
			"user",
		];

		const emptyColumns = [
			"quantity",
			"change",
			"expand"
		]

		totalColumns.forEach((col) => {
			cells[col] = (
				<ListCell
					width={(this.props.columnConfig[col] || {}).width || 0}
					className="totalcell"
					name={col}
					onlyDisplay
					editable={false}
					alignCell
				>
					{this.tr("Total")}
				</ListCell>
			);
		});

		emptyColumns.forEach((col) => {
			cells[col] = (
				<ListCell
					width={(this.props.columnConfig[col] || {}).width || 0}
					className="totalcell"
					name={col}
					onlyDisplay
					editable={false}
					alignCell
				>
					{""}
				</ListCell>
			);
		});

		return cells;
	}

	render() {
		const cells = this.defineCells();
		const className = [
			"summaryRow listElement row flex",
			this.defineClassName(),
		].join(" ");

		return (
			<div className={className} style={{ height: `50px` }}>
				{this.props.columnOrder.map((columnName) => cells[columnName])}
			</div>
		);
	}
}

class InvoicingInsight extends TaimerComponent {
	static contextType = SettingsContext;
	constructor(props, context) {
		super(props, context, "dashboard/insights/invoicing/InvoicingInsight");

		let dateRange = [startOfMonth(new Date()), endOfMonth(new Date())];

		if  (this.props.useDefaultDateRange && this.props.startDate && this.props.endDate) {
			dateRange = [moment(this.props.startDate, 'YYYY-MM-DD').toDate(), moment(this.props.endDate, 'YYYY-MM-DD').toDate()];
		}

		this.state = {
			dateRange,
			currency: 'USD',
			...baseState,
			loaded: false,
			sliderOpen: false,
			slider: false,
		};

		this.refChart = React.createRef();
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	isRefreshing = false;
	filters = {};

	refreshData = async (filters, { currency, financial_year_start }) => {
		if (this.isRefreshing)
			return;

		const { functions: { getFinancialYear } } = this.context;
		const { enqueueSnackbar, closeSnackbar, singleProject } = this.props;

		this.isRefreshing = true;

		const {
			dateRange: { startDate, endDate },
			customers_ids,
			projects_ids,
			customer_tags,
			project_tags,
			customership_group_ids,
			project_categories_ids,
			reporting_group_ids,
			project_types_ids,
			groups_ids,
			users_ids,
			company,
			advanced_search,
		} = filters;

		this.filters = filters;

		this.setState({ loaded: false, ...baseState });

		const dataTask = DataHandler.post({
			url: 'dashboard/invoicing',
			company,
			start: format(startDate, "YYYY-MM-DD"),
			end: format(endDate, "YYYY-MM-DD"),
		}, {
			...advanced_search,
			projects: projects_ids,
			project_tags: project_tags,
			customers: customers_ids,
			customer_tags: customer_tags,
			customership_groups: customership_group_ids,
			project_type: project_types_ids,
			project_category: project_categories_ids,
			reporting_group: reporting_group_ids,
			users: users_ids,
			groups: groups_ids,
			singleProject: singleProject ? 1 : 0
		});

		const fy = getFinancialYear(startDate, financial_year_start);

		let snackbarKey = null;
		const hoursTask = DataHandler.autoRetryPost({
			url: 'dashboard/invoicing/hours',
			company,
			start: format(startDate, "YYYY-MM-DD"),
			end: format(endDate, "YYYY-MM-DD"),
			fystart: format(fy.start, "YYYY-MM-DD"),
			fyend: format(fy.end, "YYYY-MM-DD"),
		}, {
			...advanced_search,
			projects: projects_ids,
			project_tags: project_tags,
			customers: customers_ids,
			customer_tags: customer_tags,
			customership_groups: customership_group_ids,
			project_type: project_types_ids,
			project_category: project_categories_ids,
			users: users_ids,
			groups: groups_ids,
			singleProject: singleProject ? 1 : 0
		}, ({ done }) => {
			if (done && snackbarKey) {
				closeSnackbar(snackbarKey);
			} else if (!done && !snackbarKey) {
				snackbarKey = enqueueSnackbar(this.tr("Updating workhours"), {
					variant: "info",
					persist: true,
				});
			}
		});

		const data = await dataTask;

		const projectSliders = {};
		const customerSliders = {};

		_.forEach(data.billable_materials_project.slider_rows, (row, id) => {
			row.id = row.key;

			switch (row.$type) {
				case 'automatic':
					row.cost_type = this.tr('Automatic Invoicing');
					break;
				case 'scheduled':
					row.cost_type = this.tr('Scheduled Invoicing');
					break;
				case 'quote':
					row.cost_type = this.tr('Quote');
					break;
				case 'bill':
					row.cost_type = this.tr('Bill');
					break;
				case 'hour':
					row.cost_type = this.tr('Hour, ${worktype}', { worktype: row.worktype });
					break;
				case 'travelExpense':
					row.cost_type = this.tr('Travel Expense');
					break;
				case 'expense':
					row.cost_type = this.tr('Purchase Expense');
					break;
				case 'actual':
					row.cost_type = this.tr('Manual Cost');
					break;
				default:
					row.cost_type = `Unknown (${row.$type})`;
			}

			if (!projectSliders[row.project_id]) {
				projectSliders[row.project_id] = {};
			}
			if (!customerSliders[row.account_id]) {
				customerSliders[row.account_id] = {};
			}

			projectSliders[row.project_id][id] = true;
			customerSliders[row.account_id][id] = true;
		});

		_.forEach(data.billable_materials_type.data, (c) => { // Type
			_.forEach(c, (m) => { // Month
				_.forEach(m, (row) => { // Row

					const customer = data.billable_materials_type.customers[row.account_id];
					const project = data.billable_materials_type.projects[row.project_id];

					if (customer) {
						row.account_name = customer;
					} else {
						console.log("missing customer", data)
					}

					if (project) {
						row.project_name = project;
					} else {
						console.log("missing project", project)
					}

				})
			})
		});

		_.forEach(data.billable_materials_project.projects, (project) => {
			project.key = project.id;
			project.slider_rows = projectSliders[project.id] || {};
			project.total = project.travel + project.expense + project.hours
				+ project.bills + project.scheduled + project.automatic + project.quote;

			_.forEach(project.slider_rows, (v, i) => {
				data.billable_materials_project.slider_rows[i].project_name = project.projects_name;
			})
		});

		_.forEach(data.billable_materials_project.customers, (customer) => {
			customer.key = customer.id;
			customer.slider_rows = customerSliders[customer.id] || {};
			customer.total = customer.travel + customer.expense + customer.hours
				+ customer.bills + customer.scheduled + customer.automatic + customer.quote;

			_.forEach(customer.slider_rows, (v, i) => {
				data.billable_materials_project.slider_rows[i].account_name = customer.customers_name;
			})
		});

		this.setState({ ...data, currency, dateRange: [startDate, endDate] });

		const hoursData = await hoursTask;

		this.setState({ ...hoursData, loaded: true });

		this.isRefreshing = false;
	}

	fetchUserSlider = (id) => {
		const { singleProject } = this.props;
		const {
			dateRange: { startDate, endDate },
			customers_ids,
			projects_ids,
			customer_tags,
			project_tags,
			customership_group_ids,
			project_categories_ids,
			project_types_ids,
			groups_ids,
			users_ids,
			company,
			advanced_search,
		} = this.filters;

		return DataHandler.post({
			url: `dashboard/invoicing/hours/${id}`,
			company,
			start: format(startDate, "YYYY-MM-DD"),
			end: format(endDate, "YYYY-MM-DD"),
		}, {
			...advanced_search,
			projects: projects_ids,
			project_tags: project_tags,
			customers: customers_ids,
			customer_tags: customer_tags,
			customership_groups: customership_group_ids,
			project_type: project_types_ids,
			project_category: project_categories_ids,
			users: users_ids,
			groups: groups_ids,
			singleProject: singleProject ? 1 : 0
		});
	}

	showSlider = (slider) => {
		this.setState({ sliderOpen: true, slider })
	}

	onCloseSlider = () => {
		this.setState({ sliderOpen: false });
	}

	getSliderData(rows) {
	}

	getMonthLabels = (monthlyLabels) => {
		return this.state.months.map(m => {
			const momentMonth = moment(m, "YYYY-MM-DD");
			return `${monthlyLabels[momentMonth.month()]} ${momentMonth.format("YYYY")}`
		});
	}

	render() {
		const {
			slider_rows,
			accounts, products, product_groups,
			invoicing, projects, jobtypes,
			cumulative_invoicing, invoicing_cost_type,
			invoicing_sales_agent, invoicing_project_manager,
			invoicing_account_manager, invoicing_teams, account_group,
			project_types, project_categories,
			overview, loaded, sliderOpen, slider, currency, hours_invoicing,
			billable_materials_type, billable_materials_project, months, dateRange
		} = this.state;
		const { taimerAccount, userObject: { companies_id }, functions: { hasPrivilege, checkPrivilegeAny } } = this.context;
		const { tr } = this;
		const { singleCustomer, singleProject, company, project, customer, useDefaultDateRange } = this.props;

		const [start, end] = dateRange;

		const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
			style: 'currency',
			currency,
		}).format;

		if (!singleProject && !singleCustomer && !hasPrivilege("dashboard", "invoicing_read")) {
			return <NoPermissionOverlay />;
		}

		const paidColor = colors.greenish_cyan;
		const unpaidColor = "#ffcf5c";
		const draftColor = "#af4f5c";
		const waitingColor = "#affffc";

		let sliderLabel = '';
		let sliderData = {};
		let sliderSum = 0;
		let table = { defaultType: 'invoice' };

		const sliderLabels = {
			due: this.tr("Due in 0-7 days"),
			late1: this.tr("Overdue 1-14 days"),
			late2: this.tr("Overdue 15-30 days"),
			late3: this.tr("Overdue 30+ days"),
			total: this.tr("Invoiced Total"),
			paid: this.tr("Payment Received"),
			unpaid: this.tr("Total Outstanding"),
			draft: '',
			waiting: this.tr("Waiting Invoices"),
		};

		if (slider === 'due' || slider === 'late1' || slider === 'late2' || slider === 'late3') {
			sliderData = invoicing.data[slider];
			sliderSum = invoicing[slider];
			sliderLabel = `${tr("Invoicing")} - ${sliderLabels[slider]}`
		} else if (slider === 'paid' || slider === 'unpaid' || slider === 'draft' || slider === 'waiting' || slider === 'credited') {
			sliderData = overview.slider_rows[slider];
			sliderSum = overview[slider];
			sliderLabel = sliderLabels[slider];
		} else if (slider === 'total') {
			sliderData = { ...overview.slider_rows.paid, ...overview.slider_rows.unpaid, ...overview.slider_rows.waiting };
			sliderSum = overview.total;
			sliderLabel = sliderLabels[slider];
		}

		const monthlyLabels = [
			tr("Jan"),
			tr("Feb"),
			tr("Mar"),
			tr("Apr"),
			tr("May"),
			tr("Jun"),
			tr("Jul"),
			tr("Aug"),
			tr("Sep"),
			tr("Oct"),
			tr("Nov"),
			tr("Dec")
		];
		const conditionalFilters = [
			taimerAccount.hasEnterpriseGroups ? "enterprise_groups" : undefined,
		].filter(cf => cf);

		const insightFilterProps = {};

		if (singleProject || singleCustomer)
			insightFilterProps.hideCompanySelect = true;
		
		return <InsightsBase
			ref={this.refInsightBase}
			header={this.props.header}
			subheaders={this.props.subheaders}
			id="insights-invoicing"
			rightGroup={singleCustomer ? 'customers' : (singleProject ? 'projects' : "dashboard")}
			right="invoicing_read"
			savedQueryName={(singleProject || singleCustomer) ? undefined : "invoicing_insight"}
			loaded={loaded} 
			onRequestData={this.refreshData}
			infoParams={{
				project,
			}}
			dropFilters={[]}
			defaultFilters={useDefaultDateRange && { dateRange: {
				startDate: start,
				endDate: end,
				valid: true,
				key: "selection"
			}}}
			forcedFilters={singleCustomer ? {
				company,
				customers_ids: [customer],
			} : (singleProject ? {
				company,
				projects_ids: [project],
			} : undefined)}
			insightFilterProps={insightFilterProps}
			// defaultTime="year"
			advancedFilters={singleCustomer ? [
				"tags", "customership_groups", "projects", "project_type", "project_categories", "reporting_group",
				"account_manager", "sales_agent", "project_team", "project_manager", "team", ...conditionalFilters 
			] : singleProject ? [
				"tags", "customership_groups", "project_type", "project_categories", "reporting_group",
				"account_manager", "sales_agent", "project_team", "project_manager", "team", ...conditionalFilters 
			] : [
				"customers", "tags", "customership_groups", "projects", "project_type", "project_categories", "reporting_group",
				"account_manager", "sales_agent", "project_team", "project_manager", "team", ...conditionalFilters
			]}
			useSearchButton
		>
			<InsightBlock rootClassName="invoising-insight-top" title={tr("Overview")} width={3} canCollapse={false}>
				<InsightSlider
					open={sliderOpen}
					label={sliderLabel}
					onClose={this.onCloseSlider}
					allRows={slider_rows}
					currentRows={sliderData}
					sum={sliderSum}
					table={table}
					currency={currency}
				/>
				<div className="overview-split">
					<div className="overview-right">
						<Doughnut
							ref={this.refChart}
							data={{
								labels: [
									this.tr("Payment Received"),
									this.tr("Total Outstanding"),
									this.tr("Waiting Invoices"),
								],
								datasets: [{
									sliders: [
										'paid',
										'unpaid',
										'waiting',
									],
									data: [
										overview.paid,
										overview.unpaid,
										overview.waiting,
									],
									backgroundColor: [
										paidColor,
										unpaidColor,
										waitingColor,
									],
									hoverBackgroundColor: [
										paidColor,
										unpaidColor,
										waitingColor,
									]
								}]
							}}
							options={{
								maintainAspectRatio: false,
								cutoutPercentage: 60,
								responsive: true,
								plugins: {
									tooltip: {
										enabled: true,
										callbacks: {
											label: function (tooltipItem) {
												var label = " " + _.escape(tooltipItem.label) || '';

												if (label) {
													label += ': ';
												}
												const value = tooltipItem.raw;
												label += currencyFormatter(value);
												label += " " + (value / overview.total * 100).toFixed(2) + "%"

												return label;
											},
										}
									},
								},
								onClick: (evt, elements, chart) => {
									const activePoint = chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, false)[0];

									if (!activePoint) return;
									const data = activePoint.element.$context;
									this.showSlider(data.dataset.sliders[activePoint.index]);
								},
							}}
						/>
					</div>
					<div className="overview-left">
						<div className="overview-total-area" onClick={this.showSlider.bind(this, 'total')}>
							<div className="overview-total-text">
								{this.tr("Invoiced Total")}
							</div>
							<div className="overview-total-sum">
								{currencyFormatter(overview.total)}
							</div>
						</div>
						<div className="overview-legends">
							<div className="overview-legend" onClick={this.showSlider.bind(this, 'paid')}>
								<div className="ball-indicator" style={{ background: paidColor }}></div>
								<div>
									<div className="label">{this.tr("Payment Received")}</div>
									<div className="value">{currencyFormatter(overview.paid)}</div>
								</div>
							</div>

							<div className="overview-legend" onClick={this.showSlider.bind(this, 'unpaid')}>
								<div className="ball-indicator" style={{ background: unpaidColor }}></div>
								<div>
									<div className="label">{this.tr("Total Outstanding")}</div>
									<div className="value">{currencyFormatter(overview.unpaid)}</div>
								</div>
							</div>
							<div className="overview-legend" onClick={this.showSlider.bind(this, 'waiting')}>
								<div className="ball-indicator" style={{ background: waitingColor }}></div>
								<div>
									<div className="label">{this.tr("Waiting Invoices")}</div>
									<div className="value">{currencyFormatter(overview.waiting)}</div>
								</div>
							</div>
							<div className="overview-legend overview-legend-credited" onClick={this.showSlider.bind(this, 'credited')}>
								<div className="ball-indicator" style={{ background: waitingColor }}></div>
								<div>
									<div className="label">{this.tr("Credited Invoices")}</div>
									<div className="value">{currencyFormatter(overview.credited)}</div>
								</div>
							</div>
						</div>
						{/* <div className="overview-legends">
                            <div className="overview-legend" onClick={this.showSlider.bind(this, 'draft')}>
                                <div className="ball-indicator" style={{ background: draftColor }}></div>
                                <div>
                                    <div className="label">{this.tr("Draft Invoices")}</div>
                                    <div className="value">{currencyFormatter(overview.draft)}</div>
                                </div>
                            </div>
                        </div> */}
					</div>
				</div>
			</InsightBlock>

			<InvoicingCumulativeChart
				width={6}
				loaded={loaded}
				title={tr("Invoicing Cumulative Development")}
				data={cumulative_invoicing.sum}
				months={months}
				sliderData={cumulative_invoicing.data}
				labelOrder={cumulative_invoicing.labelorder}
				currency={currency}

			/>

			<InsightBlock rootClassName="invoising-insight-top" title={tr("Invoicing")} width={3} canCollapse={false}>
				<InsightVsBar
					colorLeft="#2d9ff7"
					textLeft={this.tr("Due")}
					valueLeft={invoicing.totaldue}
					dataLeft={invoicing.data.totaldue}
					colorRight="#f7548f"
					textRight={this.tr("Overdue")}
					valueRight={invoicing.totaloverdue}
					dataRight={invoicing.data.totaloverdue}
					sliderTable={{
						defaultType: 'invoice',
					}}
					currency={currency}
				/>

				<div className="bar-titles" onClick={this.showSlider.bind(this, 'due')}>
					<div className="bar-title">
						<div className="title">{this.tr("Due in 0-7 days")}</div>
					</div>
					<div className="bar-title">
						<div className={cn("value", invoicing.due > 0 && "value-positive")}>{currencyFormatter(invoicing.due)}</div>
					</div>
				</div>

				<div className="bar-titles" onClick={this.showSlider.bind(this, 'late1')}>
					<div className="bar-title">
						<div className="title">{this.tr("Overdue 1-14 days")}</div>
					</div>
					<div className="bar-title">
						<div className={cn("value", invoicing.late1 > 0 && "value-negative")}>{currencyFormatter(invoicing.late1)}</div>
					</div>
				</div>

				<div className="bar-titles" onClick={this.showSlider.bind(this, 'late2')}>
					<div className="bar-title">
						<div className="title">{this.tr("Overdue 15-30 days")}</div>
					</div>
					<div className="bar-title">
						<div className={cn("value", invoicing.late2 > 0 && "value-negative")}>{currencyFormatter(invoicing.late2)}</div>
					</div>
				</div>


				<div className="bar-titles" onClick={this.showSlider.bind(this, 'late3')}>
					<div className="bar-title">
						<div className="title">{this.tr("Overdue 30+ days")}</div>
					</div>
					<div className="bar-title">
						<div className={cn("value", invoicing.late3 > 0 && "value-negative")}>{currencyFormatter(invoicing.late3)}</div>
					</div>
				</div>
			</InsightBlock>

			<InsightTable
				loaded={loaded}
				className="bigscroll"
				sliderTitleColumn="category_name"
				title={this.tr("Invoicing by Project Category")}
				summaryRowType={InvoicingInsightSummaryRow}
				data={project_categories}
				sliderRows={slider_rows}
				extraCols={['category']}
				height={380}
				table={{
					defaultType: 'invoice',
				}}
				currency={currency}
			/>

			<InsightTable
				loaded={loaded}
				className="bigscroll"
				sliderTitleColumn="types_name"
				title={this.tr("Invoicing by Project Type")}
				summaryRowType={InvoicingInsightSummaryRow}
				data={project_types}
				sliderRows={slider_rows}
				extraCols={['type']}
				height={380}
				table={{
					defaultType: 'invoice',
				}}
				currency={currency}
			/>

			<InsightTable
				loaded={loaded}
				className="bigscroll"
				sliderTitleColumn="product_name"
				title={this.tr("Invoicing by Product")}
				summaryRowType={InvoicingInsightSummaryRow}
				data={products}
				groups={product_groups}
				sliderRows={slider_rows}
				extraCols={['expand', 'product', 'quantity']}
				height={380}
				sliderColumns={['number', 'account', 'project', 'qty', 'sum']}
				table={{
					defaultType: 'invoice',
				}}
				currency={currency}
			/>

			<InvoicingByCostType
				loaded={loaded}
				title={this.tr("Invoicing by Cost Type")}
				data={invoicing_cost_type}
				sliderRows={slider_rows}
				currency={currency}
			/>

			<InsightTable
				loaded={loaded}
				sliderTitleColumn="users_name"
				title={this.tr("Invoicing by Role")}
				summaryRowType={InvoicingInsightSummaryRow}
				defaultTab="invoicing_sales_agent"
				tabs={{
					invoicing_sales_agent: tr('Sales Agent'),
					invoicing_project_manager: tr('Project Management'),
					invoicing_account_manager: tr('Account Manager'),
				}}
				data={{
					invoicing_sales_agent, invoicing_project_manager, invoicing_account_manager
				}}
				sliderRows={slider_rows}
				extraCols={['user']}
				height={380}
				table={{
					defaultType: 'invoice',
					linkView: {
						module: 'invoices',
						action: 'main',
						selectedTab: 'invoices',
						users_id: '$users_id',
					},
				}}
				currency={currency}
			/>


			<InsightTable
				loaded={loaded}
				className="bigscroll"
				sliderTitleColumn="group_name"
				title={this.tr("Invoicing by Account Group")}
				summaryRowType={InvoicingInsightSummaryRow}
				data={account_group}
				sliderRows={slider_rows}
				extraCols={['account_group']}
				height={380}
				table={{
					defaultType: 'invoice',
				}}
				currency={currency}
			/>

			{/* <InsightTable
                loaded={loaded}
                title={this.tr("Invoicing by Team")}
                data={invoicing_teams}
                sliderRows={slider_rows}
                extraCols={['team']}
                height={380}
                table={{
                    defaultType: 'invoice',
                }}
                currency={currency}
            /> */}

			<InsightTable
				loaded={loaded}
				className="bigscroll"
				sliderTitleColumn="customers_name"
				title={this.tr("Invoicing by Accounts")}
				summaryRowType={InvoicingInsightSummaryRow}
				data={accounts}
				sliderRows={slider_rows}
				extraCols={['account']}
				height={380}
				table={{
					defaultType: 'invoice',
				}}
				currency={currency}
			/>
			<InsightTable
				loaded={loaded}
				className="bigscroll"
				sliderTitleColumn="projects_name"
				title={this.tr("Invoicing by Project")}
				summaryRowType={InvoicingInsightSummaryRow}
				data={projects}
				sliderRows={slider_rows}
				extraCols={['project']}
				height={380}
				table={{
					defaultType: 'invoice',
				}}
				currency={currency}
			/>

			<InsightChart
				title={tr("Invoicing by Jobtype")}
				width={6}
				data={{
					sets: jobtypes?.sets,
					labels: this.getMonthLabels(monthlyLabels)
				}}
				chartType={StackedCurrencyChart}
				tableType={InsightChartTable}
				tableProps={{
					setTitle: tr("Category"),
					valueTitle: tr("Value"),
				}}
				sliderTable={{
					defaultType: 'invoice',
				}}
				currency={currency}
			/>

			<InsightHoursByUser
				width={6}
				height={300}
				title={tr("Hours Invoicing Overview by User")}
				loaded={loaded}
				data={hours_invoicing.users}
				currency={currency}
				fetchUserSlider={this.fetchUserSlider}
			/>

			<InsightChart
				title={tr("Billable Materials by Type")}
				width={6}
				data={{
					sets: [
						{
							label: tr("Travel Expenses"),
							values: billable_materials_type.sum.travel,
							data: billable_materials_type.data.travel,
						},
						{
							label: tr("Purchase Expenses"),
							values: billable_materials_type.sum.expense,
							data: billable_materials_type.data.expense,
						},
						{
							label: tr("Hours"),
							values: billable_materials_type.sum.hours,
							data: billable_materials_type.data.hours,
						},
						{
							label: tr("Bills"),
							values: billable_materials_type.sum.bills,
							data: billable_materials_type.data.bills,
						},
						{
							label: tr("Scheduled Invoices"),
							values: billable_materials_type.sum.scheduled,
							data: billable_materials_type.data.scheduled,
						},
						{
							label: tr("Automatic Invoices"),
							values: billable_materials_type.sum.automatic,
							data: billable_materials_type.data.automatic,
						},
						{
							label: tr("Quote"),
							values: billable_materials_type.sum.quote,
							data: billable_materials_type.data.quote,
						},
					],
					labels: this.getMonthLabels(monthlyLabels)
				}}
				chartType={StackedCurrencyChart}
				tableType={InsightChartTable}
				chartProps={{
					displayLegend: true
				}}
				tableProps={{
					setTitle: tr("Material Category"),
					valueTitle: tr("Value"),
					displayFooter: true,
					displaySplit: true
				}}
				sliderTable={{
					defaultType: "invoice"
				}}
				currency={currency}
			/>

			<BillableMaterialsByProject
				width={6}
				loaded={loaded}
				currency={currency}
				data={billable_materials_project}
				onClickExport={this.exportBillableMaterialsByProject}
			/>

			<InsightBlock width={6}>
				{checkPrivilegeAny("invoices", ["write_full", "write_simple"]) && <InvoicingOverView insightFilters={this.filters} company={companies_id} currency={currency} />}
			</InsightBlock>

			<InsightBlock width={6}>
				{checkPrivilegeAny("worktrips") && <ExpensesOverView insightFilters={this.filters} company={companies_id} currency={currency} />}
			</InsightBlock>
		</InsightsBase >
	}
}

export default withSnackbar(withStyles(styles)(InvoicingInsight));