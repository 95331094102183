import { CustomFields } from './CustomFields';
import getColumnType, { ColumnDefinition, columnSorter } from './ColumnTypes';
import getInvoicingColumns from './InvoicingColumns';
import { filterColumns } from "./ColumnVisibility";

const columnOrder = [
    'billentries_id',
    'bill_id',
    'account',
    'customer_id',
    'company',
    'customer_account_manager',
    'project',
    'project_id',
    'creationdate_month',
    'invoice_type',
    'invoice_state',
    'currency_code',
];

interface Params {
    customFields: CustomFields;
    netvisorActive: boolean;
    procountorActive: boolean;
    checkPrivilege: (group: string, perm: string, company?: number) => boolean;
    tr: (text, replacers?: Record<string, string>) => string;
    settingsContext: any;
}

export default function getScheduledInvoicingColumns(params: Params) {
    const blacklist = [
        "bill_id",
        "invoice_account",
        "invoice_type",
        "invoice_state",
        "integration_used",
        "currency",
        "currency_rate",
        "log_created_month",
        "log_created_year",
        "log_created_quarter",
        "paid_date",
        "row_type",
        "content_type",
        "paid_amount",
        "payment_reference",
        "your_reference",
        "terms",
        "annotation",
        "country",
        "billingstartdate",
        "billingenddate",
        "worktask_product",
        'deliverydate',
        'deliverydate_month',
        'deliverydate_year',
        'deliverydate_quarter',
        'duedate',
        'duedate_month',
        'duedate_year',
        'duedate_quarter',
    ];

    let columns: ColumnDefinition[] = getInvoicingColumns({
        customFields: params.customFields,
        netvisorActive: params.netvisorActive,
        procountorActive: params.procountorActive,
        checkPrivilege: params.checkPrivilege,
        tr: params.tr,
        settingsContext: params.settingsContext,
    });



    columns = columns.filter(c => {
        return blacklist.indexOf(c.field) === -1
            && c.field.indexOf("creator") !== 0;
    });

    columns.push({
        field: 'invoiced',
        headerName: 'Invoiced',
        ...getColumnType('status', params.tr),
        cellRendererParams: {
            displayDatas: [
                { id: 0, "name": "No", "color": "#f52b2b" },
                { id: 1, "name": "Yes", "color": "#20C6A1" },
            ],
            tr: params.tr
        }
    });

    columns.push({
        field: 'row_type',
        headerName: 'Row type',
        ...getColumnType('variable', params.tr),
        cellRendererParams: {
            displayDatas: [
                { id: 0, "name": "Cost" },
                { id: 1, "name": "Product" },
                { id: 3, "name": "CPQ" },
            ],
            tr: params.tr
        }
    });

    columns.push({
        field: 'payment_term',
        headerName: 'Payment term (d)',
        ...getColumnType('number', params.tr),
        postfix: "d",
        grandTotal: 'none',
        sumAndGroupType: 'group'
    });

    columns.push({
        field: 'estimated_due_date',
        headerName: 'Estimated due date',
        ...getColumnType('date', params.tr, 'estimated_due_date'),
        
    });

    columns.sort(columnSorter(columnOrder));

    return filterColumns(
        columns,
        "scheduled_invoicing",
        params.settingsContext
    );
}
