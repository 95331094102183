import React from 'react';
import TaimerComponent from '../TaimerComponent';
import { SettingsContext } from '../SettingsContext';
import { Drawer, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import MenuItem from '@mui/material/MenuItem';
import PoSyncingSliderList from './PoSyncingSliderList';
import DataHandler from "../general/DataHandler";
import OutlinedField from "../general/OutlinedField";
import DataList from '../general/DataList';
import MassDialog from '../dialogs/mass_operations/CoreDialog';

class PoSyncingSlider extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        open: false, 
        onSliderClose: () => {}, 
        view: "",
        company: 1,
        supplierId: 0,
        currency: "EUR",
        statuses: [],
        syncedRows: []      
    }

    constructor(props, context) {
        super(props, context, "bills/poSyncingSlider");

        this.state = {
            rows: [],
            statuses: [],
            supplierId: 0,
            stateId: -1, 
            suppliers: [],
            dialogData: {},
            warningDialogOpen: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.open === false && this.props.open === true) {
            DataHandler.get({ url: `/subjects/suppliers/${this.props.company}` }).done(suppliers => {
                let supplierId = Number(this.props.supplierId) > 0 ? this.props.supplierId : 0;

                if (supplierId == 0 && this.props.ri_supplier_name && suppliers.length > 0) {
                    supplierId = suppliers.find(s => s.name.trim().toLowerCase() == this.props.ri_supplier_name.trim().toLowerCase())?.id || 0;
                }

                suppliers.unshift({ id: -1, value: -1, name: this.tr("All"), label: this.tr("All") });
                suppliers.unshift({ id: 0, value: 0, name: this.tr("No supplier"), label: this.tr("No supplier") });

                this.setState({ suppliers, supplierId, statuses: this.props.statuses, stateId: -1 }, () => this.getData());
            })
        }
    }

    getData = () => {
        const params = {
            supplierId: this.state.supplierId,
            state: this.state.stateId,
            view: this.props.view,
            company: this.props.company
        }

        DataHandler.get({url: `purchaseorders/syncList/`, ...params}).done(response => {
            this.setState({ rows: response.data })
        });
    }

    changeSupplier = (supplierId) => {
        this.setState({ supplierId }, () => this.getData());
    }

    changeState = (stateId) => {
        this.setState({ stateId }, () => this.getData());
    }

    openWarningDialog = (billId, poId) => {
        this.setState({ warningDialogOpen: true, dialogData: { billId, poId } });
    }

    closeWarningDialog = () => {
        this.setState({ warningDialogOpen: false });
    }

    confirmnWarningDialog = () => {
        this.setState({ warningDialogOpen: false}, () => this.props.syncFunc(this.state.dialogData.billId));
    }

    renderWarningDialog = () => {
        return (
            <MassDialog
                onDialogClose={this.closeWarningDialog}
                onDialogSave={this.confirmnWarningDialog}
                dialogType={"delete"}
                dialogProps={{
                    onCloseClick: this.closeWarningDialog,
                    open: this.state.warningDialogOpen,
                    close: this.closeWarningDialog,
                    confirmButtonClass: "blue",
                    confirmButtonText: this.tr("Yes, sync").toUpperCase(),
                    header: this.tr("Are you sure?"),
                    warning: () => {
                        return this.tr("Bill is already synced to purchase order PO${poNum}. Syncing this purchase order will unsync already synced purchase order!", {poNum: this.state.dialogData.poId});
                    },
                    onConfirm: () => {
                        this.confirmnWarningDialog();
                    }
                }}
            />
        )
    }

    render() {
        const { open, onSliderClose, view } = this.props;
        const { supplierId, stateId, suppliers, statuses } = this.state;
        
        const header = view === "po" ? this.tr("Sync to bill") : this.tr("Select purchase order to sync to bill");

        return (
            <> 
            {this.state.warningDialogOpen && this.renderWarningDialog()}
            <Drawer id="poSyncing-list-slider" anchor="right" open={open} onClose={() => onSliderClose()}>
                <div className="top-bar">
                    <div className="left">
                        <div>
                        <h2>{header}</h2>
                        </div>
                    </div>
                    <div className="right">
                        <Button onClick={() => onSliderClose()}>
                        <Close />
                        </Button>
                    </div>
                </div>
                <div className="poSyncing-slider-div">
                    <div className="filters">
                        <DataList 
                            className="listFilterOutlinedField" 
                            label={this.tr("Supplier")}
                            name="supplier"
                            options={suppliers}
                            value={suppliers.find(s => s.id == supplierId)} 
                            shownCount={20}
                            onChange={(supplier) => this.changeSupplier(supplier.id)}
                        />

                        <OutlinedField className="listFilterOutlinedField" label={this.tr("State")} name="state" value={stateId} select onChange={(e) => this.changeState(e.target.value)}>
                            <MenuItem key={-1} value={-1}>{this.tr("All")}</MenuItem>
                            { statuses.map(row => (
                                <MenuItem key={row.id} value={row.id}>{row.label}</MenuItem>
                            ))}
                        </OutlinedField>
                    </div> 
                    <PoSyncingSliderList openWarningDialog={this.openWarningDialog} {...this.state} {...this.props} />
                </div>
            </Drawer>
            </>
        );
    }
}

export default PoSyncingSlider;