import React from "react";
import TaimerComponent from '../../../../TaimerComponent';
import { ReactComponent as WorkhoursIcon } from '../../../../navigation/NavIcons/workhours.svg';

export default class TimeTrackingButton extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "resourcing/views/ResourcingGrid/components/TimeTrackingButton");

        this.state = {
            dialogOpen: false,
            dialogData: {},
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.timer = setInterval(() => this.forceUpdate(), 1000);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearInterval(this.timer);
    }

    click = async () => {
        const { functions: { startWorkhourTimer, getWorkhourTimer } } = this.context;

        if (this.state.dialogOpen)
            return;

        if (!this.props.item) {
            startWorkhourTimer(this.props.data);
        } else {
            const data = getWorkhourTimer();

            if (data) {
                const item = {
                    isWorkhourTimer: true,
                    start: data.start,
                    end: new Date(),
                    customer: {
                        id: data.customers_id || 0,
                    },
                    project: {
                        id: data.projects_id || 0,
                    },
                    wh_projects_resource: {
                        id: data.wh_projects_resource || 0,
                    },
                };
                this.context.functions.addHours(item, {
                    timer: data,
                    parentComponent: "Time tracking button",
                })
            }
        }
    }

    formatDiff(whStartTime) {
        let whTime;
        let diff = (new Date() - whStartTime) / 1000;
        let hours = Math.floor(diff / 3600);
        diff -= hours * 3600;
        let mins = Math.floor(diff / 60).toString().padStart(2, "0");
        diff = Math.round(diff - mins * 60).toString().padStart(2, "0");

        if (hours > 0)
            whTime = hours.toString().padStart(2, "0") + ':' + mins + ':' + diff;
        else
            whTime = mins.padStart(2, "0") + ':' + diff;

        return whTime;
    }

    render() {
        const {
            index,
            item,
        } = this.props;

        return (
            <div className="TimeTrackingBtnContainer" onClick={this.click}>
                <WorkhoursIcon className="WorkhoursIcon" />
                <div className="TimeTrackingBtnTxt">
                    {item && this.formatDiff(item.start)}
                </div>
            </div>
        );
    }
}