import React, { Fragment } from 'react';

import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ColorHash from 'color-hash';
import Typography from '@mui/material/Typography';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import TaimerComponent from '../../TaimerComponent';
import { format } from "date-fns";

const colorHash = new ColorHash();

const styles = theme => ({
    
});

function MailHeaders(props) {

  var trans = new TaimerComponent(props, null, "mail/MailComponents/MailHeaders");
  const { messages = [], search = "", selectedMailIndex, classes, selectedFolder, dateFormat } = props;
  
  String.prototype.truncate = function(){
    var re = this.match(/^.{0,25}[\S]*/);
    var l = re[0].length;
    var re = re[0].replace(/\s$/,'');
    if(l < this.length)
        re = re + "&hellip;";
    return re;
}

try {
  const filteredMessages = messages.filter( message =>{
    try {
      return message.subject.toLowerCase().indexOf( search.toLowerCase() ) !== -1
    } catch (error) {
      return true;
    }
    
  })
    
    let weight = "bold";

    return (
      <List>
            {filteredMessages.map(({ id, subject, isRead, from, toRecipients, sender, sentDateTime}) => {

                let text = "";
                let backgroundcolor = "";
                let toFromText
                
                if(selectedFolder === trans.tr("Sent mails")) {
                  text = sender.emailAddress.name.charAt(0).toUpperCase();
                  backgroundcolor = colorHash.hex(sender.emailAddress.address);
                  toFromText = trans.tr("To:"); 

                  toRecipients.forEach(to => {
                    toFromText += to.emailAddress.address + ", ";
                  })
                }
                else {
                  text = from.emailAddress.name.charAt(0).toUpperCase();
                  backgroundcolor = colorHash.hex(from.emailAddress.address);
                  toFromText = trans.tr("From:") + from.emailAddress.name + "\n" + from.emailAddress.address;
                }

                let date = new Date(sentDateTime).toUTCString();
                let realsubject = subject;

                if (realsubject == null) {
                  realsubject = " ";
                } 

                if(messages.length > 0) 
                {

                return (
                <ListItem button onClick={() => props.onClickMessage(id, backgroundcolor, text, isRead)} className={(isRead === undefined || isRead == true) ? "" : "unreadmessageitem"} selected={selectedMailIndex === id}>
                <Avatar style={{background: backgroundcolor, color: '#fff', fontWeight: "bold" }} >{text}</Avatar>
                  <ListItemText
                  primary={
                    <p className="message-subject-read">{realsubject}</p>             
                  }  
                  secondary={
                    <Fragment key={id}>
                      <Typography component="span" className={classes.inline} color="textPrimary">
                        {
                          <p className="message-subject-read">{toFromText}</p>
                        }
                      </Typography>
                      {date /* format(date, dateFormat) */}
                    </Fragment>
                    
                  } />
                  <ListItemSecondaryAction>
                    {/* <Checkbox/> */}
                  </ListItemSecondaryAction>
                </ListItem> 
                  )
                  } else {
                    return null;
                  }
                }       
              )
            }

            {
              (selectedFolder === trans.tr("Inbox") || selectedFolder === trans.tr("Sent mails") || selectedFolder === trans.tr("Archive") || selectedFolder === trans.tr("Junkmail") || selectedFolder === trans.tr("Deleted mails")) ? <ListItem button onClick={() => props.onClickMore()} style={{textAlign: "center"}}>
              <ListItemText primary={"......"} />
            </ListItem> : undefined
            } 
      </List>
    );
  } catch(error) {
    return <div></div>
  }
  
}

  export default withStyles(styles)(MailHeaders);