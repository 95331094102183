import React, { ReactNode, useState } from 'react';
import { Tooltip, Popover, CircularProgress } from '@mui/material';
import { AccessTime, CloudDownload } from '@mui/icons-material';

import './InsightExportButton.css';

interface PopoverData {
    anchor?: any;
    component?: ReactNode | ((closeFunction: any) => ReactNode);
}

interface Props {
    /** A title that is shown as a tooltip in other buttons. */
    title: string;
    /** Called when button is clicked. */
    action: (exportType: string) => void;
    /** Determines if a loading indicator should be shown. */
    loading?: boolean;
    /** Determines if disabled. */
    disabled?: boolean;
    /** Determines if a loading indicator should be shown. */
    exportTypes: string[];
    /**ClassName for button. */
    className?: string;
    text?: string;
}

interface style {
    marginLeft?: string;
}

const PopoverComponent = (props) => {
  return (
      <div id="insight-export-button-popover">
          {props.exportTypes.map(type => (
              <button 
                  onClick={() => {
                      props.onClose();
                      props.action(type);
                  }} >
                  {`${props.title} ${type}`}
              </button>
          ))}
      </div>
  );
};

const InsightExportButton = (props: Props) => {
    const { title, disabled, loading, className, exportTypes, action } = props;

    const initialData: PopoverData = {};
    const [popoverData, setPopoverData] = useState(initialData);

    return (
        <React.Fragment>

            <Tooltip placement="bottom" title={title}>
                <div
                    onClick={(e) => {
                        if (disabled) return;
                        !loading &&
                            (exportTypes.length > 1
                                ? setPopoverData({
                                      component: PopoverComponent({...props, onClose: () => setPopoverData({})}),
                                      anchor: e.target
                                  })
                                : action(exportTypes[0]));
                    }}
                    className={`insight-export-button ${disabled || disabled ? 'disabled' : ''} ${className}`} >

                    {loading ? <CircularProgress color="primary" disableShrink size={20} /> : <CloudDownload /> ?? <AccessTime />}

                </div>
            </Tooltip>

            {popoverData.anchor && (
                <Popover
                    onClose={() => setPopoverData({})}
                    anchorEl={popoverData.anchor}
                    open={Boolean(popoverData.anchor)}
                    anchorOrigin={{ vertical: 45, horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {popoverData.component}
                </Popover>
            )}
        </React.Fragment>
    );
};

export default InsightExportButton;
