import React from 'react';
import { getYear } from 'date-fns';

/* others */
import _ from 'lodash';
import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from '../../../SettingsContext';
import { DateRangePicker } from './../../../general/react-date-range/src';

class InsightDateRange extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        width: 3,
        visible: true,
    }

    constructor(props, context) {
        super(props, context, "dashboard/insights/filters/InsightDateRange");

        this.state = {
            pendingDate: false,
        }
    }

    onDateChange = (event) => {
        const { } = this.props;

        const { name, onChange, filters } = this.props;
        let { startDate, endDate } = event.selection;

        // const date = {
        //     startDate: startDate,
        //     endDate: endDate,
        // };

        // if (startDate.getTime() === endDate.getTime() && !this.pendingDate && event.source !== "definedRange") {
        //     this.setState({ pendingDate: date });
        //     this.pendingDate = true;
        // } else {
        //     this.pendingDate = false;
        //     this.raiseChange({
        //         ...filters,
        //         dateRange: date,
        //     }, { change: "dateRange" });
        //     this.setState({ pendingDate: false });
        // }

        onChange(name, {
            startDate,
            endDate,
        });
    };

    onDateInputChange = (dateType, date) => {
        const { name, value, onChange, filters } = this.props;
        const { startDate, endDate } = value;

        if (dateType == "start") {
            onChange(name, {
                startDate: date,
                endDate: endDate,
            });
        } else {
            onChange(name, {
                startDate: startDate,
                endDate: date,
            });
        }
    };

    dateRangeClose = () => {
        if (this.state.pendingDate) {
            setTimeout(() => {
                this.setState({ pendingDate: null });
            }, 100);
        }
    }

    render() {
        const { className, label, value, options, companyData, autoCompleteData, extraProps = {} } = this.props;
        const { userObject, functions: { getFinancialYear } } = this.context;

        const years = _.range(autoCompleteData.minYear - 1, getYear(getFinancialYear().start) + 2).map(x =>  getFinancialYear(x, companyData ? companyData.financial_year_start : null)).reverse();

        return <div className="margin date">
            <DateRangePicker
                className="daterange"
                financialYears={years}
                ranges={[{
                    startDate: value?.startDate,
                    endDate: value?.endDate,
                    key: "selection",
                }]}
                onChange={this.onDateChange}
                onInputChange={this.onDateInputChange}
                label={label}
                dateFormat={userObject.dateFormat}
                onClose={this.dateRangeClose}
                {...extraProps}
            />
        </div>
    }
}

export default InsightDateRange;