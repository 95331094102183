
import DataHandler from './DataHandler';
import { Auth, Amplify } from 'aws-amplify'
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js"; 
import $ from 'jquery';
import { env } from './env'


const configure = () => {
    
    const configuration = {
      userPoolWebClientId: env['REACT_APP_CLIENT_ID'],
      userPoolId: env['REACT_APP_USERPOOL_ID']
    }
    Amplify.configure({ Auth: configuration })
  
    return configuration
}
  
const cognitoConfig = configure()
  
const buildUserSession = auth => {
    const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
        AccessToken: auth.access_token
    })

    const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
        IdToken: auth.id_token
    })

    const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: auth.refresh_token
    })

    const sessionData = {
        IdToken,
        AccessToken,
        RefreshToken
    }
    return new AmazonCognitoIdentity.CognitoUserSession(sessionData)
}
const buildUserPool = () => {
    const poolData = {
        UserPoolId: cognitoConfig.userPoolId,
        ClientId: cognitoConfig.userPoolWebClientId
    }
    return new AmazonCognitoIdentity.CognitoUserPool(poolData)
}
const buildUser = (user, userPool) => {
    const userData = {
        Username: user,
        Pool: userPool
    }
  
    return new AmazonCognitoIdentity.CognitoUser(userData)
}
const setSession = auth => {
    try {
        const session = buildUserSession(auth)
        const userPool = buildUserPool()
        const username = session.accessToken.payload.username
        const user = buildUser(username, userPool)

        user.setSignInUserSession(session)
        return Auth.currentAuthenticatedUser()
    } catch (e) {
        console.error('Unable to set cognito session', e)
    }
}

export const rememberDevice = async () => {
    try{
        return await Auth.rememberDevice();
    }catch (error) {
        console.log('Error remembering device', error)
    }
}

export const forgetDevice = async () => {
    try{
        return await Auth.forgetDevice();
    }catch (error) {
        console.log('Error forgetting device', error)
    }
}

export const isCognitoUser = async (username, account) => {
    try {
        return await DataHandler.get({ url: "auth/cognito_user", username, account});
    }
    catch (e) {
        console.log("Error isCognitoUser", e);
    }
}

export const setCognitoUserId = (awsUser, changeEmail = false) => {
       
    DataHandler.put({url: "settings/user/set_cognito_id"}, {
        cognito_id: awsUser.attributes.sub, 
        accessToken: awsUser.signInUserSession.accessToken.jwtToken,
        changeEmail
    });
}

export const getUserEnvironments = (awsUser) => {
    let environments = []
    if (awsUser.attributes['custom:environments'])
        environments = JSON.parse(awsUser.attributes['custom:environments'])

    return environments
}

const getRedirectUrl = () => {
    const host = window.location.origin
    return host
}

const getTokens = (code) => {
    const url = env['REACT_APP_POOL_URL'] +  "/oauth2/token"
    const data = {
        grant_type: "authorization_code",
        client_id: cognitoConfig.userPoolWebClientId,
        redirect_uri: getRedirectUrl(),
        code
    }
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }

    return $.ajax({
        url,
        data,
        headers,
        method: "POST",
    });
}

export const handleCodeFlow = async (code) => {
   try {
        const tokens = await getTokens(code);
        setSession(tokens);
        return tokens
    }
    catch (e) {
        console.error("cognito session creation failed", e)
    }
}

export const loginOffice365 = () => {
    const url = new URL(env['REACT_APP_POOL_URL'] +  "/oauth2/authorize")
    url.searchParams.append("identity_provider", "microsoft-openid")
    url.searchParams.append("client_id", cognitoConfig.userPoolWebClientId)
    url.searchParams.append("response_type", "code")
    url.searchParams.append("scope", "email openid aws.cognito.signin.user.admin")
    url.searchParams.append("redirect_uri", getRedirectUrl())

    window.location = url.href
}

export default {
    rememberDevice, 
    forgetDevice,
    isCognitoUser,
    setCognitoUserId
}
