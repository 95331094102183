import React from 'react';
import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from './../SettingsContext';
import DialogBorder from "./DialogBorder";
import DialogSection from "./DialogSection";
import DataList from "../general/DataList";
import ProjectTreeDropdown from '../projects/ProjectTreeDropdown';
import DataHandler from "./../general/DataHandler";

import "./SetAsSubProjectDialog.css";
import TabGoals from '../accounts/TabGoals';

export class SetAsSubProjectDialog extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
        project: {},
        onClose: () => {},
        onSave: () => {}
    }

    constructor(props, context){
        super(props, context, "dialogs/SetAsSubProjectDialog");

        this.state = {
            accountOptions: [],
            customerId: 0,
            parentProjectId: 0,
            selectedCustomer: {},
            selectedProject: {},
        }
        
    }

    componentDidMount() {
        let { company } = this.props;
        if (!company)
            company = this.props.dialogProps.company;
        DataHandler.get({url: `subjects/accounts/${company}`, all_accounts: 1}).done(response => {
            this.setState({ accountOptions: response});
            this.onChange("customerId", response.find(c => c.id == this.props.data.customerId));
        });
    }

    cancel = () => {
        this.props.dialogProps && this.props.dialogProps.onClose ? this.props.dialogProps.onClose() : this.props.onClose();
    };

    save = async (values) => {
        this.props.dialogProps && this.props.dialogProps.onSave ? this.props.dialogProps.onSave(values) : this.props.onSave(values);
        this.props.dialogProps && this.props.dialogProps.onClose ? this.props.dialogProps.onClose() : this.props.onClose();
    };

    onChange = (target, value) => {
        if (target === "customerId") {
            this.setState({customerId: value?.id, selectedCustomer: value});
        } else if (target === "parentProjectId") {
            this.setState({parentProjectId: value?.id, selectedProject: value, customerId: value?.customers_id});
        }
    }

    render() {
        const {tr} = this;
        const { accountOptions, customerId, selectedCustomer, selectedProject, parentProjectId } = this.state;
        const { dialogProps: { project }} = this.props;
        
        return (
            <DialogBorder
                id="SetAsSubProjectDialog"
                title={this.tr("Assign as a subproject")}
                onClose={this.cancel}
                onSave={() => {this.save({target: {name: "parentid", value: parentProjectId}})}}
                //disableSave={isSaving}
                className="TMRDialogGrid TMRDialogInputs"
            >
                <DialogSection>
                    <h3 className="main-header">{tr("Select parent project")}</h3>
                    <p className="sub-header">{tr("Selecting a customer will filter projects to include projects only from that customer")}.</p>
                    <div className="subproject-options">
                        <DataList
                            label={tr("Account")}
                            variant='standard'
                            className="account-select"
                            value={selectedCustomer || ''}
                            options={accountOptions}
                            shownCount={20}
                            onChange={(c) => this.onChange("customerId", c)} 
                        />

                        <ProjectTreeDropdown 
                            label={tr("Project")} 
                            customerId={customerId > 0 ? customerId : undefined}
                            value={Number(parentProjectId)}
                            onSelect={(p) => this.onChange("parentProjectId", p)}
                            handleBackspace={true}
                            noOptionsMessage={tr("No options")}            
                            disableAutoSelect={true}
                            filterOutParentSelfAndChildrenFor={project.id}
                            queryParameters={{
                                company: project.companies_id,
                            }}
                        />
                    </div>
                    
                </DialogSection>
            </DialogBorder>
        )
    }
}

export default SetAsSubProjectDialog;