import React from "react";

import _ from "lodash";
import DataHandler from "../general/DataHandler";
import DialogBorder from "./DialogBorder";
import OutlinedField from "../general/OutlinedField";
import DialogSection from "./DialogSection";
import TaimerComponent from "../TaimerComponent";
import "./PersonalMeetingLinkDialog.css";

import { withSnackbar } from "notistack";


export class PersonalMeetingLinkDialog extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "dialogs/PersonalMeetingLinkDialog");

        this.state = {
            meetingLink: this.props.value,
        };
    }

    /**
     * Usable
     *
     */
    cancel = () => {
        this.props.onClose();
    };

    onChangeDialogEvent = ( value ) => {
        this.setState({ meetingLink: value });
    };

    onSave = () => {
        
        DataHandler.post({ url: 'settings/profile/personalmeetinglink'}, { personal_meeting_link: this.state.meetingLink })
            .done(response => {
                this.props.onSave(this.state.meetingLink);
            })
            .fail(err => {
                console.log('Error saving link',err);
            });
    };

    /**
     * GoogleEventDialog renderer
     *
     */
    render() {
        const { meetingLink } = this.state;
        const { onClose } = this.props;

        return (
            <DialogBorder
                title={this.tr("Edit Personal Meeting Link")}
                onSave={this.onSave}
                onClose={() => onClose()}
                className="personal-meeting-link-dialog TMRDialogGrid TMRDialogInputs"
            >
                <DialogSection>
                    <OutlinedField
                        className="personal-meeting-link"
                        label={this.tr("Personal Meeting Link")}
                        name="personal_meeting_link"
                        value={meetingLink}
                        onChange={e => this.onChangeDialogEvent(e.target.value)}
                    />
                </DialogSection>
            </DialogBorder>
        );
    }
}

export default withSnackbar(PersonalMeetingLinkDialog);
