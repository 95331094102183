import React from "react";
import TaimerComponent from "../../TaimerComponent";
/* material ui */
import { Button } from "@mui/material";
import { ReactComponent as OverlayImage } from "./ProductList.svg";

class CatalogAccountSliderOverlay extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/overlays/CatalogAccountSliderOverlay");
  }

  render() {
    const { functions: { hasPrivilege }} = this.context;
    return (
      <div className="list-overlay">
        <OverlayImage style={{ width: "auto", height: "260px" }} />
        <div className="text">{this.tr("No linked accounts.")}</div>
        <Button disabled={!hasPrivilege("customers", "write")} color="primary" onClick={() => this.props.linkAccounts()}>
          {this.tr("Link accounts to catalog").toUpperCase()}
        </Button>
      </div>
    );
  }
}

export default CatalogAccountSliderOverlay;
