
const WORKWEEK_DAYS = [
    "mon",
    "tue",
    "wed",
    "thu",
    "fri",
    "sat",
    "sun"
];

function decimalNumber(value) {
    const str = String(value);

    return str.replace(",", ".");
}

function isPercentualHourAmount(str) {
    return typeof(str) === "string"
        && str.indexOf("%") > -1;
}

function isConcreteHourAmount(str) {
    return !isPercentualHourAmount(str);
}

function countPercentualsAndConcretes(weekData) {
    const pc   = { p: 0, c: 0 };
    const days = WORKWEEK_DAYS;

    days.forEach(day => {
        const v = weekData[day];

        if(String(v).trim() === "") {
            return;
        }

        if(isPercentualHourAmount(v)) {
            pc.p++;
        }

        if(isConcreteHourAmount(v)) {
            pc.c++;
        }
    });

    return pc;
}

function calculateWeekTotal(week) {
    if(week.hasOwnProperty("based_on") && week.based_on !== null) {
        return _calculateBasedOnWeek(week);
    }

    if(week.is_percentage) {
        return "-";
    }

    return _calculateConcreteWeek(week);
}

function _calculateConcreteWeek(week) {
    return WORKWEEK_DAYS
        .map(d => parseFloat(String(week[d]).replace(",", ".")))
        .map(n => !isNaN(n) ? n : 0)
        .reduce((acc, cur) => acc + cur, 0)
        .toFixed(2);
}

function _calculateBasedOnWeek(week) {
    const hours = WORKWEEK_DAYS.map(day => {
        return parseFloat(week.concrete_and_percentual[day].concrete);
    }).filter(n => !isNaN(n))
        .reduce((acc, cur) => acc + cur, 0)
        .toFixed(2);

    const percentual = (parseFloat(hours / week.based_on_total) * 100).toFixed(2);

    return {
        hours: hours,
        percentual: `${percentual}%`,
        string: `${hours} / ${week.based_on_total}h`
    };
}

export {
    isPercentualHourAmount,
    isConcreteHourAmount,
    countPercentualsAndConcretes,
    calculateWeekTotal,
    decimalNumber
};
