import React, { Component } from 'react';
import _ from 'lodash';
import FilterDropdownServer from './FilterDropdownServer';

export default class FilterDropdownCPQ extends Component {
	render() {
		const { company, parent } = this.props;

		const params = {
			companies_id: company,
			parent,
		};

		return <FilterDropdownServer url="dialogs/cpqs" params={params} {...this.props} />
	}
}