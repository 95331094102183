import React from 'react';

/* css */
import "./TreeDropdown.css";

/* others */
import isEqual from "lodash/isEqual"; 
import OutlinedField from "./OutlinedField";
import ClickAwayWrapper from "./ClickAwayWrapper";
import { createBranchIndicators } from "./BranchIndicator";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import { treeFormatDataForList, createTreeOptions, findMaxDepth, makeMap, makeMapOfPrimitives } from "../list/ListUtils";
import { List as VirtualizedList, AutoSizer } from "react-virtualized";
import { Popper } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import { getTextWidthInElement } from '../helpers';
import { Tooltip } from '@mui/material';
import cn from 'classnames';

import cloneDeep from "lodash/cloneDeep";

/*
 * TODO: page up and page down in options.
 * TODO: Customizing rows.
 */
class TreeDropdown extends React.Component {
    static defaultProps = {
        customRenderer: undefined,
        data: [],
        debounceOnChange: false,
        debounceDelay: 500,
        deferOpenOnEmptyValueUntilNewData: false, // ...
        disabled: false,
        // disableValueReset === true should ensure that "resetting" 
        // the currently selected value doesn't actually reset it, 
        // but rather only lets you select a new value.
        // disableValueReset: false,
        displayValue: undefined,
        emptyValueOnClick: true,
        growOptionContainer: false,
        activateBestMatch: false,
        handleBackspace: false,
        highlightMatches: false,
        highlightSelectedValue: false,
        useTooltip: false,
        bestMatchKey: "best_match",
        label: "",
        noOptionsMessage: "No options",
        onChange: () => {},
        onErase: () => {},
        onSelect: () => {},
        onFocus: () => {},
        onBlur: () => {},
        openOnErase: true,
        optionContainerStyle: {},
        optionLabelKey: "label",
        onlySelectMode: false,
        parentKey: "parentId",
        rowHeight: 44, // TODO: Make more customizable.
        scrollToSelectedValue: true,
        selectOnBlur: true,
        showTreeDropdownInfoIcon: true,
        removeRowPadding: false,
        title: undefined,
        value: undefined,
        placeholder: "",
    };


    constructor(props) {
        super(props);

        this.state = {
            activeIndex: -1,
            // When props.value is passed, 
            // selectedValueActiveIndex corresponds to
            // the index of the passed props.value. (TAIM)-5933).
            selectedValueActiveIndex: -1, 
            containerWidth: undefined,
            initialDone: false,
            mouseSelecting: false,
            open: this.props.openOnMount || false,
            selectedValue: undefined,
            treeData: [],
            deferredOpen: false,
            readOnly: true,
        };

        this.virtualizedList   = React.createRef();
        this.treeDropInfoIcon  = React.createRef();
        this.refEl             = React.createRef();

        this.lastSelectedValue = undefined;
        this.currentValue      = undefined;

        this.optionsContainer  = React.createRef();
        this.outlinedField     = props.inputRef || React.createRef();

        this.containerStyle    = {};
        this.onChangeTimeout   = undefined;

        this.formatData        = this.formatData.bind(this);
        this.determineMaxWidth = this.determineMaxWidth.bind(this);
        this.checkValue        = this.checkValue.bind(this);
        this.handleOnKeyDown   = this.handleOnKeyDown.bind(this);
        this.handleOnKeyUp     = this.handleOnKeyUp.bind(this);
        this.handleOnChange    = this.handleOnChange.bind(this);
        this.handleOnClick     = this.handleOnClick.bind(this);
        this.handleOnClickAway = this.handleOnClickAway.bind(this);
        this.handleSelect      = this.handleSelect.bind(this);
        this.handleOnMouseOver = this.handleOnMouseOver.bind(this);
        this.handleOnBlur      = this.handleOnBlur.bind(this);
        this._rowRenderer      = this._rowRenderer.bind(this);
        this.reset             = this.reset.bind(this);
    }

    
    componentDidMount() {
        if(this.props.value !== undefined) {
            this.checkValue();
        }

        this.formatData();
    }


    componentDidUpdate(prevProps, prevState) {
        if(this.optionsContainer.current !== null && this.state.activeIndex > -1) {
            this.optionsContainer.current.scrollTop = (this.state.activeIndex - 3) * this.props.rowHeight;
        }

        const dataIsEqual = isEqual(prevProps.data, this.props.data);

        if(!this.state.initialDone && (!dataIsEqual || this.props.data.length > 0)) {
            this.setState({ initialDone: true });
        }

        if(!dataIsEqual) {
            this.formatData();
            
            setTimeout(() => this.determineMaxWidth(), 1000);
        } else if(!prevState.open && this.state.open) {
            setTimeout(() => this.determineMaxWidth(), 1000);
        }

        if(this.props.highlightMatches && this.virtualizedList.current !== null && this.props.data !== prevProps.data) {
            this.virtualizedList.current.forceUpdateGrid();
        }

        // Kyllä piti tästäkin tulla tämmöstä.
        if(!this.state.deferredOpen && !this.state.open && (prevState.open || this.state.selectedValue === undefined || String(prevProps.value) !== String(this.props.value) || !dataIsEqual)) {   
           this.checkValue();
        }

        if(this.props.deferOpenOnEmptyValueUntilNewData 
            && !this.state.open
            && this.state.deferredOpen 
            && (!dataIsEqual || prevProps.data !== this.props.data)
        ) 
        {
            this.setState({
                open: true,
                deferredOpen: false
            });
        }

        if(!prevState.open && this.state.open) {
            //this.setState({ selectedValue: undefined });
        }

        // If the dropdown was opened and there is a selected value, scroll to that value.
        if(!prevState.open && this.state.open && this.state.selectedValue !== undefined) {
            const sId = this.state.selectedValue.id;
            let index = 0;

            for(let i in this.state.treeData) {
                if (sId == this.state.treeData[i].id) {
                    index = parseInt(i);
                    break;
                }
            }

            this.setState({ 
                activeIndex: index,
                selectedValueActiveIndex: index
            });
        }

        if(this.state.selectedValue !== undefined) {
            this.lastSelectedValue = cloneDeep(this.state.selectedValue);
        }
    }

    getChildIds = (children) => {
        let childIds = [];
        children.forEach(child => {
            childIds.push(child.data.id);
            childIds = childIds.concat(this.getChildIds(child.children));
        });

        return childIds;
    }

    formatData() {
        let treeData       = createTreeOptions(treeFormatDataForList(this.props.data, this.props.parentKey));
        const bestMatchIndex = this.props.activateBestMatch && treeData ? treeData.findIndex(d => d[this.props.bestMatchKey] === true) : undefined;

        if (this.props.filterOutParentSelfAndChildrenFor && treeData.length > 0) {
            let getChildrenFor = treeData.find(td => td.id == this.props.filterOutParentSelfAndChildrenFor);
            if (getChildrenFor?.children) {
                let children = this.getChildIds(getChildrenFor.children);
                let parent = getChildrenFor.parentid;
                children.push(Number(this.props.filterOutParentSelfAndChildrenFor));
                
                let filterids = children.concat(parent);
                const map = makeMapOfPrimitives(filterids);
                treeData  = treeData.filter(td => !map[td.id]);
            }        
        }

        this.setState({ 
            treeData,
            activeIndex: !this.props.activateBestMatch ? undefined : bestMatchIndex
        });
    }


    determineMaxWidth() {
        const optionElements = Array.from(document.getElementsByClassName("option"));

        // Candu nuffin if no .option nodes are in the dom yet.
        if(!optionElements || optionElements.length === 0) {
            return;
        }

        let longest       = ""; 
        let longestLength = 0;

        this.props.data.forEach(d => {
            if(d[this.props.optionLabelKey] && d[this.props.optionLabelKey].length < longestLength) {
                return;
            }
            else if (d[this.props.optionLabelKey]) {
                longest       = d[this.props.optionLabelKey];
                longestLength = longest.length;
            }
        });


        const spans = Array.from(optionElements[0].getElementsByTagName("span"));

        if(!spans || optionElements.length === 0) {
            return;
        }

        const maxWidth = getTextWidthInElement(longest, spans[0]);

        this.setState({ containerWidth: parseInt(maxWidth) + 30 });
    }


    checkValue() {
        const { data, value } = this.props;
        const { treeData } = this.state;

        const v      = parseInt(value);
        const f      = data.find(el => parseInt(el.id) === v);
        const fIndex = treeData.findIndex(el => parseInt(el.id) === v);

        this.currentValue = f && f.hasOwnProperty(this.props.optionLabelKey) 
            ? f[this.props.optionLabelKey] 
            : "";

        this.setState({ 
            selectedValue: f || false,
            selectedValueActiveIndex: fIndex
        }, () => {
            this.props.onChange(this.currentValue);
        });
    }


    handleOnKeyDown(e) {
        if(e.key === "ArrowUp" || e.key === "ArrowDown" || e.key.startsWith("Page")) {
            e.preventDefault();

            let nextActiveIndex = this.state.activeIndex + (e.key.endsWith("Up") ? -1 : +1) * (e.key.startsWith("Page") ? 5 : 1);

            if(nextActiveIndex < 0)
                nextActiveIndex = e.key.startsWith("Arrow") ? this.state.treeData.length - 1 : 0;
            else if(nextActiveIndex >= this.state.treeData.length)
                nextActiveIndex = e.key.startsWith("Arrow") ? 0 : this.state.treeData.length - 1;

            this.setState({ activeIndex: nextActiveIndex });

            // let n=this.state.treeData.length,nai=this.state.activeIndex+(e.key.endsWith("Up")?-1:+1)*(e.key[0]==="P"?5:1);
            // this.setState({ activeIndex: [...([a=>a,a=>a.reverse()][e.key[0]==="A"&&"0"||"1"]([n-1,0])),nai][nai<0&&"0"||nai>=n&&"1"||"2"] });
        } else if(e.key === "Home" || e.key === "End") {
            this.setState({ activeIndex: e.key === "Home" ? 0 : this.state.treeData.length - 1 });
        }
    }


    handleOnKeyUp(e) {
        if(this.props.handleBackspace && e.key === "Backspace") {
            this.handleOnChange(e.target.value, false, "keyUp");
            return;
        }
        
        if(e.key === "Tab" || e.key === "Escape") {
            this.setState({ open: false });
            return false;
        }

        if(e.key === "Enter" || e.key === "Tab") {
            if(this.state.activeIndex > -1) {
                this.handleSelect(this.state.treeData[this.state.activeIndex]);
            }

            this.setState({ open: false });
        }

        if(!this.state.open) {
            this.setState({ open: true });
        }

        if(["ArrowUp", "ArrowDown", "PageUp", "PageDown", "Enter", "Home", "End"].indexOf(e.key) > -1) {
            return;
        }

        this.handleOnChange(e.target.value, false, "keyUp");
    }


    reset() {
        // TBD.
    }


    handleOnChange(value, overrideDebounce = false, source = undefined) {
        this.currentValue = value; 

        if(this.props.debounceOnChange && !overrideDebounce) {
            clearTimeout(this.onChangeTimeout);

            this.onChangeTimeout = setTimeout(() => {
                this.props.onChange(value, source);
            }, this.props.debounceDelay);
        } else {
            this.props.onChange(value, source);
        }
    }


    handleOnClick(e) {
        if(this.state.open || this.props.disabled) {
            return;
        }

        if(this.props.emptyValueOnClick) {
            this.currentValue = "";
        } else {
            this.currentValue = this.lastSelectedValue !== undefined && this.lastSelectedValue.hasOwnProperty(this.props.optionLabelKey) ? this.lastSelectedValue[this.props.optionLabelKey] : "";
        }

        this.handleOnChange(this.currentValue, true, "onClick");

        if(this.props.emptyValueOnClick && this.props.deferOpenOnEmptyValueUntilNewData) {
            this.setState({
                selectedValue: undefined,
                deferredOpen: true
            });
        } else {
            this.setState({
                //selectedValue: undefined,
                open: true
            });
        }
    }


    handleOnClickAway(e) {
        if(!this.state.open) {
            return;
        }

        if(["treeSelectItem", "TreeSelect"].filter(className => e && e.target.classList.contains(className)).length > 0) {
            return;
        }

        this.setState({ 
            open: false, 
            selectedValue: cloneDeep(this.lastSelectedValue)
        }, () => this.props.onChange(this.state.selectedValue ? this.state.selectedValue[this.props.optionLabelKey] : this.currentValue));
    }


    handleSelect(data) {
        if(!data || (!this.props.noDisabledValues && data.disabled)) {
            return;
        }

        this.setState({ open: false, mouseSelecting: false, deferredOpen: false }, () => {
            this.props.onSelect(data);

            // Called here because we definitely want to.
            this.props.onChange(data[this.props.optionLabelKey]);
        });
    }


    handleOnMouseOver(e) {
        if(this.activeIndex === -1) {
            return;
        }

        this.setState({ activeIndex: -1 });
    }

    
    handleOnBlur(e) {
        if(this.state.mouseSelecting)
            return;

        if(this.props.selectOnBlur) {
            if(!this.state.treeData || this.state.treeData.length === 0) {
                this.handleSelect(null);
            } else {
                this.handleSelect(this.state.activeIndex > -1 ? this.state.treeData[this.state.activeIndex] : this.state.treeData[0]);
            }
        }

        this.props.onBlur(e);
    }


    escapeRegExp(string) {
        return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    highlightMatches(label, searchString = null) {
        if (!searchString || !label) {
            return label || "";
        }
        const searchTerms = searchString.split(" ").filter(s => !!s);
        const regexString = this.escapeRegExp(searchTerms.join('|'));
        const regexp = new RegExp(`(${regexString})`, "gi");
        const matches = label.match(regexp) || [];
        const parts = (label.split(regexp) || []).filter(p => !!p);
        return parts.map((part) => {
            if (matches.includes(part)) {
                return <span className="highlight">{part}</span>;
            }
            return part;
        });
    }


    _rowRenderer({ key, index, style }) {
        let { 
            activeIndex, 
            selectedValueActiveIndex 
        } = this.state;

        selectedValueActiveIndex = selectedValueActiveIndex > -1 
            ? selectedValueActiveIndex
            : activeIndex;

        const { 
            highlightSelectedValue,
            noDisabledValues,
            customRenderer,
            useTooltip,
            title
        } = this.props;

        const d = this.state.treeData[index];
        const active = index === activeIndex 
            || (highlightSelectedValue && index === selectedValueActiveIndex);

        let indicators = createBranchIndicators({
            height: 44,
            indent: 25
        }, { lineType: "solid", lineColor: "#eaecf0", lineWidth: 2, inList: false })(d, d.level, d.isLastChild);

        let className = `option${active ? " active" : ""}`;

        if(d.disabled && !noDisabledValues) {
            className += " disabled";
        }

        let value;
        let displayedTitle = undefined;

        if(customRenderer && typeof(customRenderer) === "function") {
            value = customRenderer(d);
        } else if(!this.props.highlightMatches) {
            value = d[this.props.optionLabelKey];
        } else {
            value = this.highlightMatches(d[this.props.optionLabelKey], this.currentValue);
        }

        if(title !== undefined && typeof(title) === "string") {
            displayedTitle = title;
        } else if(title !== undefined && typeof(title) === "function") {
            displayedTitle = title(d);
        } else if(useTooltip) {
            displayedTitle = d[this.props.optionLabelKey];
        }

        const padding = this.props.removeRowPadding 
            ? 0 
            : undefined;

        return (
            <div 
                data-testid={"treeoption_" + value} 
                title={displayedTitle} 
                className={className} style={style} 
                onMouseDown={() => { this.setState({ mouseSelecting: true }) }} 
                onClick={() => { this.handleSelect(d); }} 
                onMouseOver={this.handleOnMouseOver} 
                key={key}>
                <div className="branchIndicators">{indicators.map(Indicator => Indicator)}</div>
                <span 
                    style={{ 
                        marginLeft: d.level > 0 ? "8px" : 0,
                        padding: padding
                    }}
                >{value}</span> 
            </div>
        );
    }

    onFocus = (e) => {
        this.props.onFocus && this.props.onFocus(e);
        this.setState({readOnly: false});
    }

    render() {
        const { readOnly } = this.state;

        let displayValue;

        if(this.props.displayValue !== undefined) {
            displayValue = this.props.displayValue;
        } else if(!this.state.selectedValue) {
            displayValue = undefined;
        } else {
            displayValue = (this.state.selectedValue && this.state.selectedValue.hasOwnProperty(this.props.optionLabelKey)) ? this.state.selectedValue[this.props.optionLabelKey] : this.props.displayValue;
        }

        const style            = { width: this.props.growOptionContainer ? this.state.containerWidth : undefined, minWidth: this.props.usePopper ? this.refEl.current?.clientWidth : "100%" };
        const NoOptionsMessage = this.props.noOptionsMessage;
        const className        = this.props.onlySelectMode || (this.state.selectedValue && !this.props.disabled) ? "keepWhiteWhenDisabled" : "";

        const optionsContent = (
            <div className={cn(this.props.usePopper ? "treeDropDownPopperOptions" : "options", this.state.treeData.length === 0 && "treeDropDownEmptyOptions")} ref={this.optionsContainer} style={{height: this.state.treeData[7] ? 308 : this.state.treeData.length * 44, ...style}}>
                <AutoSizer>
                    {
                        ({height, width}) => (
                            <VirtualizedList
                                ref={this.virtualizedList}
                                rowHeight={44}
                                auto
                                width={width - 4}
                                height={height}
                                rowCount={this.state.treeData.length}
                                treeData={this.state.treeData}
                                scrollToIndex={this.state.activeIndex}
                                rowRenderer={this._rowRenderer} />
                        )
                    }
                </AutoSizer>
            </div>
        );

        return (
            <ClickAwayWrapper active={!this.props.usePopper && this.state.open} onClickAway={this.handleOnClickAway}>
                <div ref={this.refEl} className={cn("treeDropdown full", this.props.disabled && "treeDropdownDisabled")}>
                    <OutlinedField
                        disabled={this.props.disabled || this.props.onlySelectMode}
                        className={`treeDropdownOutlinedField ${this.props.onlySelectMode ? "onlySelectMode" : ""} hasInfoIcon`}
                        error={this.props.error}
                        label={this.props.label}
                        name={this.props.name}
                        autocorrect="off"
                        onKeyDown={this.handleOnKeyDown}
                        placeholder={this.props.placeholder}
                        onKeyUp={this.handleOnKeyUp}
                        onClick={this.handleOnClick}
                        ref={this.outlinedField}
                        error={this.props.displayValue  ? false : this.props.error}
                        InputProps={{ 
                            onFocus: this.onFocus,
                            className: className + " " + (!this.props.disabled && "keepWhiteWhenDisabled") + " " + (this.props.inputClassName),
                            disabled: this.props.disabled || this.props.onlySelectMode,
                            readOnly, // Chrome autocomplete disable
                        }}
                        value={displayValue} 
                        adornmentPos="end"
                        adornment={<ArrowDropDownIcon className="treeDropIcon" />} />
                        {
                            this.state.open && (this.state.initialDone || this.props.noData) && (!this.state.treeData || this.state.treeData.length === 0) && ((typeof NoOptionsMessage === "string") || (this.currentValue && this.currentValue)) ? 
                                <div className={cn("options", "noOptionsMessage", this.props.noOptionsClassName)}>
                                {
                                    typeof NoOptionsMessage === "string" ?
                                        NoOptionsMessage
                                    :
                                    this.currentValue && this.currentValue != "" ?
                                    <NoOptionsMessage
                                        key={this.currentValue}
                                        handleClose={this.handleOnClickAway}
                                        inputValue={this.currentValue}
                                        {...this.props.noOptionsMessageProps}
                                    />
                                    : undefined
                                }
                                </div>
                            : undefined
                        }
                        {
                            // this.state.open && this.state.initialDone && (!this.state.selectedValue || this.props.onlySelectMode || this.props.disableValueReset) && 
                            this.state.open && this.state.initialDone && 
                            (this.props.usePopper
                            ?
                            <Popper open={this.state.open} placement="bottom-start" anchorEl={this.refEl.current}>
                                <ClickAwayWrapper active={this.state.open} onClickAway={this.handleOnClickAway}>
                                    {optionsContent}
                                </ClickAwayWrapper>
                            </Popper>
                            : optionsContent)
                        }
                    {this.props.showTreeDropdownInfoIcon && <div ref={this.treeDropInfoIcon} className="treeDropInfoIcon">
                        {this.state.selectedValue && this.state.selectedValue.notes && this.state.selectedValue.notes.length > 0 && 
                            <Tooltip classes={{tooltip: 'darkblue-tooltip'}} enterTouchDelay={10} title={this.state.selectedValue.notes.map( ([title, body],i) => <div>
                                <h3>{title}</h3>
                                <p>{body}</p>
                            </div> )}><InfoIcon /></Tooltip>}
                    </div>}
                </div>
            </ClickAwayWrapper>
        );
    }
}

export default TreeDropdown;
