import React from "react";

import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import mainLogo from "./../images/google-calendar.png";
import LinearProgress from '@mui/material/LinearProgress';

const styles = theme => ({
	indicator: {
        bottom: 0,
	},

	tabsroot: {
        'min-height': '0px',
	},

	flexcontainer: {
        height: '100%',
	}, 
});

class TaimerCalendarNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mode: "view",
			url: false,
			anchorEl: null,
		};
	}

	componentDidMount() {}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
    };

	/**
	 * TaimerCalendarLogin renderer
	 *
	 */
	render() {
		const { classes, isGoogleAuthenticated, isOutlookAuthenticated, loading } = this.props;
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		return (
            <div className="TaimerCalendarNavbar">
                <div className="TaimerCalendarNavbar-top">
                    <Tabs
                        value={"google"}
                        color="primary"
                        indicatorColor="primary"
                        className="taimercalendar-tabs"
                        classes={{indicator: classes.indicator, root: classes.tabsroot, flexContainer: classes.flexcontainer }}
                        indicatorColor="primary"
                    >
                        <Tab
                            //classes={{ root: classes.tabsroot, flexContainer: classes.flexcontainer }}
                            className="tab tab-google"
                            value="google"
                            label={isGoogleAuthenticated ? "Google" : isOutlookAuthenticated ? "Outlook" : ""}
                            icon={<img src={mainLogo} className="icon-googlecalendar" />}
                        />
                    </Tabs>
                    {isGoogleAuthenticated && (
                        <React.Fragment>
                            <IconButton
                                className="button-settings"
                                aria-label={"Settings"}
                                onClick={this.handleClick}
                                size="large">
                                <SettingsIcon />
                            </IconButton>
                            <Menu keepMounted anchorEl={anchorEl} open={open} onClose={this.handleClose}>
                                <MenuItem key={1} onClick={() => {this.handleClose(); this.props.googleLogout();}}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )}
                    {isOutlookAuthenticated && (
                        <React.Fragment>
                            <IconButton
                                className="button-settings"
                                aria-label={"Settings"}
                                onClick={this.handleClick}
                                size="large">
                                <SettingsIcon />
                            </IconButton>
                            <Menu keepMounted anchorEl={anchorEl} open={open} onClose={this.handleClose}>
                                <MenuItem key={2} onClick={() => {this.handleClose(); this.props.outlookLogout();}}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )}
                </div>
                {loading ? <LinearProgress className="taimercalendar-loading" /> : <div className="taimercalendar-loading"></div>}
			</div>
        );
	}
}

export default withStyles(styles)(TaimerCalendarNavbar);
