import TaimerComponent from '../TaimerComponent';  
import StyledTooltip from '../general/StyledTooltip';

export interface Quote {
    currency_sum: number;
    name: string;
    currency: string;
}

interface Props {
    quotes: Quote[];
    projectId: number | string;
    mode: "sum" | "currency" | "currency_rate" | "status";
    quoteReadRight: boolean;
    quoteWriteRight: boolean;
    renderOnlyTooltipContent?: boolean;
}

export default class QuoteValues extends TaimerComponent<Props> {
    constructor(props, context) {
        super(props, context, 'projects/QuoteValues');
    }

    getQuoteName = (quote) => {
        return quote.name == "New quote" ? this.tr("New quote header") : quote.name; // In quote header is translated if it is "New quote"
    }

    renderQuoteValuesTooltip = (data) => {
        const { quoteReadRight, quoteWriteRight } = this.props;

        const showCreate = !data.length && quoteWriteRight;
        const showButton = quoteReadRight && (showCreate || data.length);

        return (
            <>
                {!quoteReadRight
                    ? <p>{this.tr("No permission to quotes")}</p>
                    : (!data.length 
                            ? <p>{this.tr("No active quotes")}</p>
                            : data.map(quote => {
                                 return <p>{this.getQuoteName(quote) + ": " + this.getSingleValue(quote)}</p>
                            })
                        )
                }
                {showButton ?
					<button onMouseDown={(e) => this.goToQuote(e)}>{showCreate ? this.tr('Create quote') : (data.length > 1 ? this.tr('View quotes') : this.tr('View quote'))}</button>
                    : <div></div>
                }
            </>
        )
    }

    goToQuote = (e) => {
        const { projectId } = this.props;
        e.preventDefault && e.preventDefault();
		e.stopPropagation && e.stopPropagation();

        this.context.functions.updateView({ module: "projects", id: projectId, selectedSubTab: "quote", selectedTab: "sales", action: "view" });
    }

    showLinkToQuote = () => {
        const { quoteReadRight, quoteWriteRight, quotes } = this.props;
        const data = quotes || [];

        const shouldNotShow = !quoteReadRight || (data.length == 0 && !quoteWriteRight);
        return !shouldNotShow;
    }

    createQuoteLink = (content) => {
        const { projectId } = this.props;

        if (!this.showLinkToQuote()) { 
            return <span>{content}</span>
        }
        return (
            <a href={`index.html?module=projects&action=view&id=${projectId}&selectedSubTab=quote&selectedTab=sales`} onClick={(e) => this.goToQuote(e)}>
                {content}
            </a>
        )
    }

    formatQuoteSum = (quote) => {
        const { functions: { presentCurrency } } = this.context;
        const currency = quote?.currency || "";
        const sum = quote?.currency_sum || 0;

        return presentCurrency(sum, currency)
    }

    getSingleValue = (quote) => {
        const { mode, quoteReadRight, quoteWriteRight } = this.props;

        if (!quote) {
            return quoteWriteRight 
                ? this.tr("Create quote")
                : (quoteReadRight 
                    ? this.tr("No active quotes")
                    : this.tr("No permission to quotes")
                )

        }

        let value = "";
        switch (mode) {
            case 'currency':
                value = (quote?.currency || "").toUpperCase();
                break;
            case 'currency_rate':
                value = quote?.currency_rate || "1.000000";
                break;
            case 'status':
                value = quote?.statusName || "";
                break;
            default:
                value = this.formatQuoteSum(quote);
        }

        return value;
    }

    getValue = (data) => {
        const { quoteReadRight, quoteWriteRight } = this.props;

        if (!quoteReadRight) {
            return this.tr("No permission to quotes");
        }
        else if (!data?.length) {
            if (quoteWriteRight) {
                return this.tr("Create quote");
            }
            return this.tr("No active quotes");
        }
        else if (data.length > 1) {
            return this.tr("${amount} values", {amount: data.length});
        }

        const quote = data.length < 1 ? null : data[0];
        return this.getSingleValue(quote)
    }

    render() {
        const { quotes, renderOnlyTooltipContent } = this.props;
        const data = quotes || [];

        if (renderOnlyTooltipContent) {
            return this.renderQuoteValuesTooltip(data);
        }

        const value = this.getValue(data);

        return (
            <>
                {data.length != 1 && this.showLinkToQuote() ?
                    <StyledTooltip content={this.renderQuoteValuesTooltip(data)} placement="right">
                        {this.createQuoteLink(value)}
                    </StyledTooltip>

                : this.createQuoteLink(value)}
            </>
        )
    }
}