import React, { PureComponent } from 'react';
import OutlinedField from '../../../general/OutlinedField';
import { SettingsContext } from '../../../SettingsContext';
import TaimerComponent from "../../../TaimerComponent";
import FilterDropdown from '../../../general/FilterDropdown';
import cn from 'classnames';
import { formatInputNumber } from '../../../helpers';
import { 
    roundToDecimals,
} from "./../../../general/MathUtils";

export default class InvoiceRowOther extends TaimerComponent {
	static contextType = SettingsContext;

	static defaultProps = {
		showValidationResult: false,
	}

	constructor(props, context) {
		super(props, context, "dialogs/elements/InvoiceRowsEditor/InvoiceRowOther");

		this.state = {
			valid: false,
			validation: this.validate(this.props.item),
		};
	}

	delete = () => {
		this.props.onDelete(this.props.item);
	}

	validate = (row) => {
		const result = {
			valid: true,
		};

		if (!row.name) {
			result.valid = false;
			result.name = true;
		}

		if (isNaN(row.vat) || row.vat < 0) {
			result.valid = false;
			result.vat = true;
		}

		if (isNaN(row.total) || row.total < 0) {
			result.valid = false;
			result.total = true;
		}

		return result;
	}

	onChange = (e) => {
		let { value } = e.target;

		if (e.target.name == 'total_no_vat' || e.target.name == 'vat' || e.target.name == 'total') {
			value = e.target.value.replace(',', '.');

            value = roundToDecimals(value, 2);
		}

		const item = {
			...this.props.item,
			[e.target.name]: value,
		};
		if (e.target.name === "subtype") {
			item.vat = value.vat;
		}

		const validation = this.validate(item);

		item.valid = validation.valid;

		this.setState({ validation });

		this.props.onChange(item.invoiceRowIndex, item, e.target.name);
	}
	render() {
		const { item, showValidationResult, disabled: propDisabled } = this.props;
		const validation = showValidationResult ? this.validate(item) : {};
		const { taimerAccount } = this.context;

		const disabled = propDisabled || item.disabled;

		return (<React.Fragment className={item.className} key={item.invoiceRowIndex}>
			<OutlinedField name="name"
				label={this.tr("Description")}
				value={item.name}
				onChange={this.onChange}
				fullWidth
				error={validation.name}
				disabled={disabled} />
				
			<div className={cn("third", "invoiceInput")}>
				<div className="fullwidth">
				<FilterDropdown
						label={this.tr("Expense Type")}
						name="subtype"
						items={this.props.types}
						onChange={this.onChange}
						value={item.subtype}
						error={validation.subtype}
						disabled={disabled} />

				</div>
			</div>

			<OutlinedField name="total_no_vat"
				label={this.tr("Excl. VAT")}
				value={item.total_no_vat}
				onChange={this.onChange}
				format="currency"
				currency={taimerAccount.currency}
				fullWidth
				error={validation.total_no_vat}
				disabled={true} />

			<OutlinedField name="vat"
				label={this.tr("VAT %")}
				value={item.vat}
				onChange={this.onChange}
				error={validation.vat}
				disabled={item.subtype.vat != null} 
				validation={["percent100"]}
				/>

			<OutlinedField name="total"
				label={this.tr("Total")}
				value={item.total}
				onChange={this.onChange}
				format="currency"
				currency={taimerAccount.currency}
				fullWidth
				error={validation.total}
                maximumFractionDigits={2}
                equalPropValueFix={true}
                usePropValue={true}
				disabled={disabled} />

			<div className="invoiceRowDelete">{!disabled && <button onClick={this.delete}>X</button>}</div>
		</React.Fragment>)
	}
}
