import React from "react";
import { Drawer, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import $ from 'jquery';

import TaimerComponent from "../../TaimerComponent";
import List from "../List";
import DataHandler from "../../general/DataHandler";

import "./ProductDetailsInfo.css";
import PropsOnlyListRow from "../PropsOnlyListRow";
import ListCell from "../ListCell";
import LinkListCell from "../LinkListCell";
import CheckCircle from '@mui/icons-material/CheckCircle';
import AttachmentCell from "../cells/AttachmentCell";
import Cancel from '@mui/icons-material/Cancel';
import {
  MoreHoriz,
  RemoveRedEye,
  Edit,
  Archive,
  Link,
  LinkOff,
  ToggleOn,
} from "@mui/icons-material";
import ContextMenu from "../../general/ContextMenu";
import TextInputCell from "../cells/TextInputCell";
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem, Tooltip } from "@mui/material";
import AttachmentDialog from "../dialogs/HandleRowAttachments";
import CurrencyListCell from "../CurrencyListCell";
import DateCell from "../cells/DateCell";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningIcon from "@mui/icons-material/Warning";
import { ReactComponent as RemoveIcon } from './../../general/icons/remove.svg';
import { ReactComponent as ViewIcon } from './../../general/icons/view.svg';
import { withSnackbar } from 'notistack';
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import FileSize from '../../general/FileSize';

import CatalogListSliderOverlay from "../overlays/CatalogListSliderOverlay";

class ProductAttachmentsSliderRow extends PropsOnlyListRow {
    constructor(props) {
      super(props, null, {}, "list/rows/ProductAttachmentsSliderRow");
    }
  
    defineClassName() {
      return "ProductAttachmentsSliderRow";
    }
  
  viewAttachment() {
    const params = {
      id: this.props.data.id,
      attachment: "attachment",
      _auth: this.context.functions.getStorage().taimerToken,
    };

    window.open(this.context.functions.getDownloadPath() + $.param(params));
  }
  
    defineCells() {
      const {columnWidthMap, data} = this.props;
      let cells = {};

      const params = {
        id: data.id,
        attachment: "attachment",
        _auth: this.context.functions.getStorage().taimerToken
      }

      let thumbSrc = ""
      let type = "";
      const typeArray = data.filename.split(".");
      type = typeArray[typeArray.length -1];

      if (data.thumb_id && type.toLowerCase() !== "pdf") {
        const thumbParams = {
          id: data.thumb_id,
          attachment: "attachment",
          _auth: this.context.functions.getStorage().taimerToken
        }
        thumbSrc = this.context.functions.getDownloadPath() + $.param(thumbParams);
      }
      else {
        thumbSrc = `./images/filetype_icons/${type.toUpperCase()}.svg`;
      }

      cells = {
        context:
            <ListCell className="nobg alignLeft pricelistcontextcell" onlyDisplay>
            <ContextMenu  className="cell row-menu" label={<ContextMenuIcon />}
              buttonProps={{ className: 'action-menu' }} noExpandIcon>
              <MenuItem key={1} noClose={false} onClick={() => this.viewAttachment()} >
                  <ViewIcon />
                  {this.tr("View Attachment")}
              </MenuItem>
              {this.props.rowProps.writePrivileges && <MenuItem className="delete" onClick={() => this.props.rowProps.openDeleteDialog(this.props.data.id, this.props.data.filename)}><RemoveIcon title="" className="Delete"/>{this.tr("Delete")}</MenuItem>}                 
            </ContextMenu>
          </ListCell>,
       
        attachment: 
          <ListCell  alignCell onlyDisplay  name="name"  value={data['filename']} editable={false} >
            <img className="thumbImage" src={thumbSrc} onClick={() => this.viewAttachment()} /> 
            <TextInputCell
              listCellType={LinkListCell}
              listCellProps={{
                urlHandler: value =>  this.context.functions.getDownloadPath() + $.param(params),
              //valueHandler: value => value,
                asNormalLink: true,
                target: "_blank"
              }}
              width={230}
              name="attachment"
              value={data.filename} 
            />
          </ListCell>,
        size: 
        <ListCell width={columnWidthMap.size} onlyDisplay editable={false} >
          <FileSize className="file-size">{data.filesize-0}</FileSize>
        </ListCell>
        ,           
        edited_date: 
        <DateCell  width={columnWidthMap.edited_date}  value={data.edited_date}  onlyDisplay editable={false} /> ,

        edited_by: 
          <ListCell width={columnWidthMap.edited_by} value={data['created_by']} editable={false} >
           
             {data.created_by}
            
          </ListCell>
        
      };
  
      return cells;
    }
  }

class ProductAttachmentsSlider extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/lists/ProductAttachmentsSlider");
    this.state = {	
      currentDialog: false,
      attachments: [],
      deleteAttachmentDialogOpen: false,
      dialogData: {},
      isCatalogProduct: Number(this.props.product.parent_id) > 0,
      productId: Number(this.props.product.parent_id) > 0 ? this.props.product.parent_id : this.props.product.id
    };

    this.writePrivileges = context.functions.checkPrivilege("products", "write", props.product.companies_id);
    this.productAttachmentList = React.createRef();
    this.upload = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    this.getAttachments();
  }

  _getColumns = () => {
    const staticColumnConfig = {
      showMenu: false,
      resizeable: false,
      showResizeMarker: false,
      moveable: false,
      hideable: false,
      visibleInToolbar: true,
    };

    return [
      {
        field: "context",
        name: "context",
        header: "",
        columnHeaderType: this.writePrivileges ? "roundButton" : "",
        width: 80,
        ...staticColumnConfig,
      },
      {
        field: "attachment",
        name: "attachment",
        header: this.tr("Attachment"),
        width: 360,
        ...staticColumnConfig,
      },
      {
        field: "size",
        name: "size",
        header: this.tr("File size"),
        width: 120,
        ...staticColumnConfig,
      },
      
      {
        field: "edited_by",
        name: "edited_by",
        header: this.tr("Edited by"),
        width: 200,
        ...staticColumnConfig,
      }
    ];
  }

  getAttachments = () => {
    if (!this.state.productId) return;

    DataHandler.request("GET", { url: 'products/' + this.state.productId + '/attachments' }).done(response => {
      const filteredData = response.filter(file => {
        if (file.filesize == null || file.id == null || file.filesize == "0")
          return false;
        else
          return true;
      });

      this.setState({ attachments: filteredData });
    });
  }

  uploadFiles = (evt) => {
    const { taimerAccount: { attachmentMaxSize } } = this.context;
    const { enqueueSnackbar } = this.props;
    const _this = this;

    evt.stopPropagation();
    evt.preventDefault();
    const files = Array.from(evt.target.files || evt.dataTransfer.files);

    const url = 'products/' + this.state.productId + '/attachments';

    let totalSize = 0;

    files.forEach(function (file) {
      totalSize += file.size;
    });

    if (totalSize > attachmentMaxSize) {
      enqueueSnackbar(this.tr('Selected file is too large'), {
        variant: "error"
      });
      return;
    }

    files.forEach(function (file) {
      DataHandler.file({ url: url }, file, undefined, e => {
        if (e.responseJSON === false) {
          enqueueSnackbar(this.tr('Uploading file failed'), {
            variant: "error"
          });
          return;
        }
      });
    });

    setTimeout(() => {
      _this.getAttachments();
    }, 1000);

    _this.props.enqueueSnackbar(this.tr('Attachment(s) added successfully!'), {
      variant: "success"
    });
  }

  openDeleteDialog = (id, name) => {
    if (!this.writePrivileges) {
      this.props.enqueueSnackbar(this.tr('No permission!'), {
        variant: "error"
      });

      return;
    }

    this.setState({ deleteAttachmentDialogOpen: true, dialogData: {id: id, name: name} });
  };

  closeDeleteDialog = () => {
    this.setState({ deleteAttachmentDialogOpen: false, dialogData: null });
  };

  deleteAttachment = (id) => {
    DataHandler.delete({ url: `attachments/${id}` })
      .done(() => {
        this.closeDeleteDialog();

        this.props.enqueueSnackbar(this.tr('Attachment deleted successfully!'), {
          variant: "success"
        });

        setTimeout(() => {
          this.getAttachments();
        }, 1000);
      })
      .fail(() => {
        this.props.enqueueSnackbar(this.tr('Error in deleting attachment!'), {
          variant: "error"
        });
      });
  };

  openAttachmentUpload = () => {
    if (!this.writePrivileges) {
      this.props.enqueueSnackbar(this.tr('No permission!'), {
        variant: "error"
      });

      return;
    }

    this.upload.current.click();
  };

  render() {
    const { height } = this.props;

    return (
      <>
        <div className="product-card-content">
          <div className="product-card-bottom">
            <input ref={this.upload} onChange={(e) => this.uploadFiles(e)} type="file" multiple />
            <List
              fluid
              columns={this._getColumns()}
              height={height}
              ref={this.productAttachmentList}
              listRowType={ProductAttachmentsSliderRow}
              noStateData={true}
              sharedData={{ openAttachment: this.openAttachment }}
              data={this.state.attachments}
              roundbutton={() => this.openAttachmentUpload()}
              rowProps={{
                openDeleteDialog: this.openDeleteDialog,
                writePrivileges: this.writePrivileges
              }}
            />
          </div>
        </div>
        {this.state.deleteAttachmentDialogOpen &&
          <MassDialog
            onDialogClose={this.closeDeleteDialog}
            onDialogSave={() => this.deleteAttachment(this.state.dialogData.id)}
            dialogType={"delete"}
            dialogProps={{
              wider: true,
              onCloseClick: () => this.closeDeleteDialog(),
              open: this.state.deleteAttachmentDialogOpen,
              close: () => this.closeDeleteDialog(),
              header: this.tr("Delete Attachment?"),
              warning: () => {
                return (
                  <p id="product-delete-dialog-warning">
                    {this.tr("Are you sure you want to delete attachment \"${attachment}\"?", { attachment: this.state.dialogData.name })}
                    {this.state.isCatalogProduct && 
                      <p>{this.tr("This will delete this attachment from the parent product and all other catalogs.")}</p>
                    }
                  </p>
                )},
              onConfirm: () => {
                this.deleteAttachment(this.state.dialogData.id)
              }
            }}
          />
        }
      </>
    );
  }
}

export default withSnackbar(ProductAttachmentsSlider);
