import React from 'react';

import cn from 'classnames';

/* css */
import './ProfitLoss.css';

import _ from 'lodash';
import moment from 'moment';
import { format, parse, startOfMonth, endOfMonth } from "date-fns";
import DataHandler from './../general/DataHandler';
import TaimerComponent from '../TaimerComponent';

import ProfitLossTable from './ProfitLossTable';
import ProfitLossChart from './ProfitLossChart';
import ProfitLossLegend from './ProfitLossLegend';
import ProfitLossCollapse from './ProfitLossCollapse';

import InsightsBase from '../dashboard/insights/InsightsBase';
import InsightSlider from '../dashboard/insights/InsightSlider';
import { List, ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@mui/material';
import { withSnackbar } from 'notistack';
import colors from '../colors';
import VersionContentManager from '../general/VersionContentManager';

const charts = {
    invoices_waiting: {
    },
    invoices_sent: {
    },
    invoices_paid: {
    },
    invoices_overdue: {
    },
    invoices_scheduled: {
    },
    invoices_automatic: {
    },
    bills_approved: {
    },
    bills_waiting: {
    },
    purchase_orders_sent: {
    },
    purchase_orders_waiting: {
    },
    expenses_approved: {
    },
    travel_approved: {
    },
    expenses_waiting: {
    },
    travel_waiting: {
    },
    hours_invoiceable: {
        fetchSlider: true,
    },
    hours_own_cost: {
        fetchSlider: true,
    },
    hours_freelancers: {
        fetchSlider: true,
    },
    total: {
    },
    cumulative: {
    },
};

class ProfitLoss extends TaimerComponent {
    static defaultProps = {
        showTreeDropdown: false,
        hideAccountAndProjectDataLists: false,
        allowFullDateRange: false,

    };

    chartKey = 0;

    constructor(props, context) {
        super(props, context, "charts/ProfitLoss");

        const { functions: { getFinancialYear } } = context;
        const { tr } = this;

        let dateRange = getFinancialYear();

        if (this.props.useDefaultDateRange && this.props.startDate && this.props.endDate) {
            dateRange = {
                start: moment(this.props.startDate, 'YYYY-MM-DD').toDate(),
                end: moment(this.props.endDate, 'YYYY-MM-DD').toDate(),
            }
        }

        this.state = {
            currency: context.taimerAccount.currency,
            dateRange: {
                startDate: dateRange.start,
                endDate: dateRange.end,
                valid: true,
                key: "selection"
            },
            loaded: false,
            initialLoaded: false,
            labels: [],
            sets: {},
            data: {},
            hiddenCharts: [],
            sliderProps: false,
            totals: {},
            cumulative: {},
            viewOptions: {
                graphVisible: true,
                filtersVsible: true,
                datagridVisible: true,
                invoices_by_due: false,
                bills_by_due: false,
                purchase_order_by_pay: false,
                use_stage_probability: false,
            },
            chartGroups: this.getChartGroups(this.props.company || context.userObject.companies_id),
        }

        this.months = [
            this.tr("Jan"),
            this.tr("Feb"),
            this.tr("Mar"),
            this.tr("Apr"),
            this.tr("May"),
            this.tr("Jun"),
            this.tr("Jul"),
            this.tr("Aug"),
            this.tr("Sep"),
            this.tr("Oct"),
            this.tr("Nov"),
            this.tr("Dec"),
        ];

        this.refInsightBase = React.createRef();
    }

    getChartGroups = (company) => {
        const { tr } = this;
        const { functions: { checkPrivilegeAny } } = this.context;

        const invoicingItems = [
            'invoices_waiting',
            'invoices_sent',
            'invoices_paid',
            'invoices_overdue',
            'invoices_scheduled',
        ]

        if (!VersionContentManager.isFeatureHidden(this.namespace, 'automaticInvoicing')) {
            invoicingItems.push('invoices_automatic');
        }

        return [
            {
                id: 'invoices',
                name: tr('Invoicing'),
                visible: checkPrivilegeAny("invoices", ["write_simple", "write_full"], company),
                items: invoicingItems
            },
            {
                id: 'bills',
                name: tr('Bills & Purchase Orders'),
                visible: (this.context.addons && this.context.addons.bills) && !VersionContentManager.isFeatureHidden(this.namespace, 'bills') && checkPrivilegeAny("receivedinvoices", ["pre_approve", "approve"], company),
                items: [
                    'bills_approved',
                    'bills_waiting',
                    'purchase_orders_sent',
                    'purchase_orders_waiting',
                ]
            },
            {
                id: 'expenses',
                name: tr('Expenses'),
                visible: checkPrivilegeAny("worktrips", ["approve_superior", "approve", "modify_all"], company),
                items: [
                    'expenses_approved',
                    'travel_approved',
                    'expenses_waiting',
                    'travel_waiting',
                ].filter(item => VersionContentManager.isFeatureHidden(this.namespace, 'travelExpenses') ? !item.includes('travel') : true)
            },
            {
                id: 'hours',
                name: tr('Hours'),
                visible: checkPrivilegeAny("invoices", ["write_simple", "write_full"]) || checkPrivilegeAny("admin", ["admin"], company),
                items: [
                    checkPrivilegeAny("admin", ["admin"], company) || checkPrivilegeAny("invoices", ["write_simple", "write_full"], company) ? 'hours_invoiceable' : '',
                    (!VersionContentManager.isFeatureHidden(this.namespace, 'hoursFreelancer') && (checkPrivilegeAny("admin", ["admin"], company) || checkPrivilegeAny("invoices", ["write_full"], company))) ? 'hours_freelancers' : '',
                    (!VersionContentManager.isFeatureHidden(this.namespace, 'hoursOwnCost') && checkPrivilegeAny("admin", ["admin"], company)) ? 'hours_own_cost' : '',
                ]
            },
            {
                id: 'total',
                name: '',
                visible: true,
                items: [
                    'total',
                    'cumulative',
                ]
            },
        ].filter(x => x.visible);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    calculateTotals = (sets, hiddenCharts) => {
        const { chartGroups } = this.state;

        const totals = {};
        const cumulative = {};

        const monthToIndex = {};
        const indexToMonth = [];

        const allCharts = _.flatten(_.map(chartGroups, 'items'));

        _.map(sets.total, (v, k) => { 
            totals[k] = 0;
            cumulative[k] = 0;

            const index = indexToMonth.length;
            indexToMonth.push(k);
            monthToIndex[k] = index;
        });

        _.map(sets, (values, chart) => {
            if (chart === "total" || chart === "cumulative" || hiddenCharts.indexOf(chart) !== -1 || allCharts.indexOf(chart) === -1)
                return;

            _.map(values, (value, month) => { 
                totals[month] += value;

                const index = monthToIndex[month];

                for (let i = index; i < indexToMonth.length; i++) {
                    const m = indexToMonth[i];
                    cumulative[m] += value;
                }
            });
        });

        return {totals, cumulative};
    }

    changeVisibleCharts = (hiddenCharts) => {
        const { sets } = this.state;

        this.setState({ hiddenCharts, ...this.calculateTotals(sets, hiddenCharts) });
    }

    onRequestData = async (filters, { currency, financial_year_start }) => {
        const { enqueueSnackbar, closeSnackbar, singleProject, project } = this.props;
        const { hiddenCharts, viewOptions } = this.state;
        const { taimerAccount: { hasEnterpriseGroups } } = this.context;
        const {
            dateRange: { startDate, endDate },
            customers_ids,
            projects_ids,
            pipeline_ids,
            stages_ids,
            company,
            advanced_search,
        } = filters;
        let enterprise_groups = null;
        if (hasEnterpriseGroups)
            enterprise_groups = filters.enterprise_groups;

        this.setState({ 
            loaded: false, 
            chartGroups: this.getChartGroups(company),
        });

        try {
            let postParams = {
                ...advanced_search,
                projects: projects_ids,
                customers: customers_ids,
                single_project: false,
                pipeline_ids,
                stages_ids,
                viewOptions,
            }
            if (hasEnterpriseGroups)
                postParams = Object.assign(postParams, { enterprise_groups: enterprise_groups })

            if (singleProject) {
                postParams.single_project = true;
                postParams.main_project = project;
            }

            let snackbarKey = null;
            const data = await DataHandler.autoRetryPost({
                url: `dashboard/profitloss/${company}`,
                start: format(startDate, "YYYY-MM-DD"),
                end: format(endDate, "YYYY-MM-DD"),
            }, postParams, ({done}) => {
                if (done && snackbarKey) {
                    closeSnackbar(snackbarKey);
                } else if (!done && !snackbarKey) {
                    snackbarKey = enqueueSnackbar(this.tr("Updating workhours"), {
                        variant: "info",
                        persist: true,
                    });
                }
            });
    
            this.chartKey++;

            this.setState({
                loaded: true,
                initialLoaded: true,
                ...data,
                ...this.calculateTotals(data.sets, hiddenCharts),
            });
        } catch (error) {
            console.error(error);
        }
    }

    openSlider = async (chartId, data) => {
        const { singleProject, project } = this.props;
        const { sets, currency, viewOptions } = this.state;
        const { taimerAccount: { hasEnterpriseGroups } } = this.context;

        const set = sets[chartId];
        const chart = charts[chartId];

        const filters = this.refInsightBase.current.getFilters();

        const {
            dateRange: { startDate, endDate },
            customers_ids,
            projects_ids,
            pipeline_ids,
            stages_ids,
            company,
            advanced_search,
        } = filters;
        let enterprise_groups = null;
        if (hasEnterpriseGroups)
            enterprise_groups = filters.enterprise_groups;

        const actualMonth = parse(data.month, 'YYYY-MM', new Date());

        let start = startOfMonth(actualMonth);
        let end = endOfMonth(actualMonth);

        if (start < startDate)
            start = startDate;
        if (end > endDate)
            end = endDate;

        if (chart.fetchSlider) {
            try {
                this.setState({ loaded: false });
                let postParams = {
                    ...advanced_search,
                    projects: projects_ids,
                    customers: customers_ids,
                    single_project: false,
                    pipeline_ids,
                    stages_ids,
                    viewOptions,
                }
                if (hasEnterpriseGroups)
                    postParams = Object.assign(postParams, { enterprise_groups: enterprise_groups })
                if (singleProject) {
                    postParams.single_project = true;
                    postParams.main_project = project;
                }

                const sliderData = await DataHandler.post({
                    url: `dashboard/profitloss/${company}/slider/${chartId}`,
                    start: format(start, "YYYY-MM-DD"),
                    end: format(end, "YYYY-MM-DD"),
                }, postParams);

                const totalSum = _.sumBy(sliderData.data, 'sum');

                this.setState({
                    loaded: true,
                    sliderProps: {
                        label: data.label,
                        monthLabel: data.monthLabel,
                        table: data.table,
                        sum: totalSum,
                        currency,
                        ...sliderData,
                        parent: {
                            start: format(start, "YYYY-MM-DD"),
                            end: format(end, "YYYY-MM-DD"),
                            company,
                        },
                    }
                });
            } catch (error) {
                console.error(error);
                this.setState({ loaded: true });
            }
        } else {
            this.setState({
                sliderProps: {
                    label: data.label,
                    monthLabel: data.monthLabel,
                    table: data.table,
                    data: data.data,
                    parent: {
                        start: format(start, "YYYY-MM-DD"),
                        end: format(end, "YYYY-MM-DD"),
                        company,
                    },
                }
            });
        }
    }

    onCloseSlider = () => {
        this.setState({ sliderProps: false });
    }

    onDateChange = (event) => {
        let { startDate, endDate } = event.selection;

        const date = {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
            manual: true,
        };

        if (startDate.getTime() === endDate.getTime() && !this.pendingDate && event.source !== "definedRange") {
            this.setState({ pendingDate: date });
            this.pendingDate = true;
        } else {
            this.pendingDate = false;
            this.setState({
                dateRange: date,
                pendingDate: false
            }, this.updateComponentData);
        }
    };

    onDateInputChange = (dateType, date) => {
        const { filters } = this.props;
        const { startDate, endDate } = filters.dateRange;

        if (dateType == "start") {
            this.setState({
                dateRange: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection",
                    manual: true,
                }
            }, this.updateComponentData);
        } else {
            this.setState({
                dateRange: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection",
                    manual: true,
                }
            }, this.updateComponentData);
        }
    };

    toggleView = (name) => {
        const viewOptions = { ...this.state.viewOptions };

        viewOptions[name] = !viewOptions[name];

        this.setState({ viewOptions });
    }

    render() {
        const {
            currency, initialLoaded, loaded, data, chartGroups,
            sets, totals, cumulative, labels, hiddenCharts, sliderProps, dateRange
        } = this.state;
        const {
            singleCustomer, singleProject,
            customer, project, company, useDefaultDateRange, allowFullDateRange
        } = this.props;
        const { tr } = this;

        const { taimerAccount } = this.context;

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency,
        }).format;

        const allCharts = _.flatten(_.map(chartGroups, 'items'));

        const charts = {
            invoices_waiting: {
                key: 'invoices_waiting',
                data: _.values(sets.invoices_waiting),
                backgroundColor: 'rgb(79, 230, 247)',
                type: 'bar',
                label: tr('Waiting Invoices'),
                table: {
                    defaultType: 'invoice',
                    linkView: { module: 'invoices', action: 'main', selectedTab: 'invoices', type: 1, start: '$start', end: '$end', company: '$company', },
                },
                tooltipOrder: 1,
                stack: 'bars'
            },
            invoices_sent: {
                key: 'invoices_sent',
                data: _.values(sets.invoices_sent),
                backgroundColor: '#2d9ff7',
                type: 'bar',
                label: tr('Sent Invoices'),
                table: {
                    defaultType: 'invoice',
                    linkView: { module: 'invoices', action: 'main', selectedTab: 'invoices', type: 2, start: '$start', end: '$end', company: '$company', },
                },
                tooltipOrder: 2,
                stack: 'bars'
            },
            invoices_paid: {
                key: 'invoices_paid',
                data: _.values(sets.invoices_paid),
                backgroundColor: colors.greenish_cyan,
                type: 'bar',
                label: tr('Paid Invoices'),
                table: {
                    defaultType: 'invoice',
                    linkView: { module: 'invoices', action: 'main', selectedTab: 'invoices', type: 3, start: '$start', end: '$end', company: '$company', },
                },
                tooltipOrder: 3,
                stack: 'bars'
            },
            invoices_overdue: {
                key: 'invoices_overdue',
                data: _.values(sets.invoices_overdue),
                backgroundColor: '#f7548f',
                label: tr('Overdue Invoices'),
                table: {
                    defaultType: 'invoice',
                    linkView: { module: 'invoices', action: 'main', selectedTab: 'invoices', type: 6, start: '$start', end: '$end', company: '$company', },
                },
                tooltipOrder: 4,
                stack: 'bars'
            },
            invoices_scheduled: {
                key: 'invoices_scheduled',
                data: _.values(sets.invoices_scheduled),
                backgroundColor: '#f5a623',
                type: 'bar',
                label: tr('Scheduled Invoicing'),
                table: {
                    linkID: 'module=projects&action=view&id=$PID&tab=invoicing',
                },
                tooltipOrder: 5,
                stack: 'bars'
            },
            invoices_automatic: {
                key: 'invoices_automatic',
                data: _.values(sets.invoices_automatic),
                backgroundColor: 'rgb(198, 210, 95)',
                type: 'bar',
                label: tr('Automatic Invoicing'),
                table: {
                    linkID: 'module=projects&action=view&id=$PID&tab=invoicing',
                },
                tooltipOrder: 6,
                stack: 'bars'
            },
            bills_approved: {
                key: 'bills_approved',
                data: _.values(sets.bills_approved),
                backgroundColor: '#42b677',
                type: 'bar',
                label: tr('Approved Bills'),
                table: {
                    linkID: 'module=receivedinvoice&action=view&id=$ID',
                    linkView: { module: 'costs', action: 'main', selectedTab: 'bills', state: 1, start: '$start', end: '$end', companies_id: '$company', },
                },
                tooltipOrder: 7,
                stack: 'bars'
            },
            bills_waiting: {
                key: 'bills_waiting',
                data: _.values(sets.bills_waiting),
                backgroundColor: '#ffcf5c',
                type: 'bar',
                label: tr('Waiting Bills'),
                table: {
                    linkID: 'module=receivedinvoice&action=view&id=$ID',
                    linkView: { module: 'costs', action: 'main', selectedTab: 'bills', state: 0, start: '$start', end: '$end', companies_id: '$company', },
                },
                tooltipOrder: 8,
                stack: 'bars'
            },
            purchase_orders_sent: {
                key: 'purchase_orders_sent',
                data: _.values(sets.purchase_orders_sent),
                backgroundColor: '#2D9FF7',
                type: 'bar',
                label: tr('Purchase Orders Sent'),
                table: {
                    linkID: 'module=purchaseorder&action=view&id=$ID',
                    linkView: { module: 'costs', action: 'main', selectedTab: 'purchase_orders' },
                },
                tooltipOrder: 9,
                stack: 'bars'
            },
            purchase_orders_waiting: {
                key: 'purchase_orders',
                data: _.values(sets.purchase_orders_waiting),
                backgroundColor: '#c88ffb',
                type: 'bar',
                label: tr('Purchase Orders Waiting'),
                table: {
                    linkID: 'module=purchaseorder&action=view&id=$ID',
                    linkView: { module: 'costs', action: 'main', view: 'purchase_orders' },
                },
                tooltipOrder: 10,
                stack: 'bars'
            },
            expenses_approved: {
                key: 'expenses_approved',
                data: _.values(sets.expenses_approved),
                backgroundColor: '#00ff24',
                type: 'bar',
                label: tr('Approved Expense'),
                table: {
                    // 
                    linkID: 'module=worktrips&action=modify&id=$ID&expenseType=1',
                    linkView: { module: 'costs', action: 'main', selectedTab: 'expenses', type: 2, start: '$start', end: '$end', companies_id: '$company', expenseType: 1 },
                },
                tooltipOrder: 11,
                stack: 'bars'
            },
            travel_approved: {
                key: 'travel_approved',
                data: _.values(sets.travel_approved),
                backgroundColor: '#69d177',
                type: 'bar',
                label: tr('Approved Travel Expense'),
                table: {
                    linkID: 'module=worktrips&action=modify&id=$ID&expenseType=2',
                    linkView: { module: 'costs', action: 'main', selectedTab: 'travel-expenses', type: 2, start: '$start', end: '$end', companies_id: '$company', expenseType: 2 },
                },
                tooltipOrder: 12,
                stack: 'bars'
            },
            expenses_waiting: {
                key: 'expenses_waiting',
                data: _.values(sets.expenses_waiting),
                backgroundColor: '#f7bc59',
                type: 'bar',
                label: tr('Waiting Expense'),
                table: {
                    linkID: 'module=worktrips&action=modify&id=$ID&expenseType=1',
                    linkView: { module: 'costs', action: 'main', selectedTab: 'expenses', type: 1, start: '$start', end: '$end', companies_id: '$company', expenseType: 1 },
                },
                tooltipOrder: 13,
                stack: 'bars'
            },
            travel_waiting: {
                key: 'travel_waiting',
                data: _.values(sets.travel_waiting),
                backgroundColor: '#ffa082',
                type: 'bar',
                label: tr('Waiting Travel Expense'),
                linkID: {},
                table: {
                    linkID: 'module=worktrips&action=modify&id=$ID&expenseType=2',
                    linkView: { module: 'costs', action: 'main', selectedTab: 'travel-expenses', type: 1, start: '$start', end: '$end', companies_id: '$company', expenseType: 2 },
                },
                tooltipOrder: 14,
                stack: 'bars'
            },
            hours_invoiceable: {
                key: 'hours_invoiceable',
                data: _.values(sets.hours_invoiceable),
                backgroundColor: '#f8bda9',
                type: 'bar',
                label: tr('Invoiceable Hours'),
                table: {
                    linkID: 'module=projects&action=view&id=$PID',
                },
                useGlobalSlider: true,
                tooltipOrder: 15,
                stack: 'bars'
            },
            hours_own_cost: {
                key: 'hours_own_cost',
                data: _.values(sets.hours_own_cost),
                backgroundColor: '#c5b5a9',
                type: 'bar',
                label: tr('Hours Own Costs'),
                table: {
                    linkID: 'module=projects&action=view&id=$PID',
                },
                useGlobalSlider: true,
                tooltipOrder: 16,
                stack: 'bars'
            },
            hours_freelancers: {
                key: 'hours_freelancers',
                data: _.values(sets.hours_freelancers),
                backgroundColor: '#d4f8f0',
                type: 'bar',
                label: tr('Hours freelancers'),
                table: {
                    linkID: 'module=projects&action=view&id=$PID',
                },
                useGlobalSlider: true,
                tooltipOrder: 17,
                stack: 'bars'
            },
            total: {
                key: 'totals',
                data: _.values(totals),
                borderColor: 'rgba(107, 120, 151)',
                pointBackgroundColor: "#6b7897",
                type: 'line',
                label: tr('Profit & Loss'),
                borderDash: [5, 3],
                borderWidth: 2,
                tooltipHeaderLine: tr('Month Total'),
                tooltipOrder: 9000,
                order: -1,
                lineTension: 0.4,
            },
            cumulative: {
                key: 'cumulative',
                data: _.values(cumulative),
                borderColor: '#2D9FF7',
                pointBackgroundColor: "#2D9FF7",
                type: 'line',
                label: tr('Cumulative'),
                borderDash: [5, 3],
                borderWidth: 2,
                tooltipHeaderLine: tr('Cumulative'),
                tooltipOrder: 9001,
                order: -2,
                lineTension: 0.4,
            }
        }

        return <InsightsBase
            ref={this.refInsightBase}
            className="insights-profitloss"
            noHeader={this.props.noHeader}
            header={this.props.liteHeader ? undefined : this.props.header}
            subheaders={this.props.subheaders}
            loaded={loaded}
            dropFilters={[]}
            infoParams={{
                customer,
                project,
            }}
            autocompleteParams={{
                customer,
                project,
            }}
            defaultFilters={{ 
                stages_ids: [],
                enterprise_groups: [],
                ...(useDefaultDateRange ? { dateRange } : {}),
            }}
            forcedFilters={singleProject ? {
                company,
            } : (singleCustomer ? {
                company,
                customers_ids: [customer],
            } : undefined)}
            insightFilterProps={{
                showDefaultPipelines: true,
                filterProjectsByPipeline: true,
                // hideAdvancedSearch: true,
                advancedFilters: [
                    !singleCustomer && 'customership_groups',
                    !singleCustomer && 'account_manager',
                    'project_categories',
                    taimerAccount.hasEnterpriseGroups && 'enterprise_groups',
                    'projects',
                    'project_manager',
                    'project_type',
                    'reporting_group',
                    'sales_agent',
                    'tags',
                    'project_team',
                ],
                customFilters: [
                    !singleProject && !singleCustomer && {
                        name: 'customers_ids',
                        type: 'multiselect',
                        label: this.tr('Customer'),
                        optionsSource: 'customers',
                    },
                    {
                        name: 'pipeline_ids',
                        type: 'multiselect',
                        label: this.tr('Pipeline'),
                        optionsSource: 'allPipelines',
                    },
                    {
                        name: 'stages_ids',
                        type: 'multiselect',
                        label: this.tr('Stage'),
                        optionsSource: 'pipelinesStages',
                        filterOptions: (options, currentValues, filters) => {
                            const pipelines = filters.pipeline_ids ?? [];

                            const filtered = options.filter(opt => {
                                if (pipelines.length === 0)
                                    return true;

                                if (currentValues.includes(opt.id))
                                    return true;

                                if (pipelines.includes(opt.pipeline))
                                    return true;

                                return false;
                            });
                            
                            return filtered;
                        },
                        conditionalProps: (currentValues, filters) => ({disabled: filters.pipeline_ids.length === 0})
                    },
                    // taimerAccount.hasEnterpriseGroups ?
                    //     {
                    //         name: 'enterprise_groups',
                    //         type: 'multiselect',
                    //         label: this.tr('Enterprise group'),
                    //         optionsSource: 'enterprise_groups',
                    //         returnAll: true
                    //     } : undefined,

                ]
            }}
            useSearchButton
            onRequestData={this.onRequestData}
            defaultTime="year"
            right="profit_loss_read"
            rightGroup={singleProject ? "projects" : (singleCustomer ? "customers" : "dashboard")}
            savedQueryName="profit_loss"
            pipelineByDefault={false}
            fullWidthContent
            outsideFiltersState={this.state.viewOptions}
            onChangeOutsideFilters={(viewOptions) => this.setState({ viewOptions: { ...this.state.viewOptions, ...viewOptions } })}
            viewButtonProps={{
                title: this.tr("View options"),
                popoverClass: "profit-loss-view-options",
                popoverComponent: this.renderViewButtonOptions,
            }}
            allowFullDateRange={allowFullDateRange}
        >
            <div className="grid-container content-block profit-loss">
                {initialLoaded && <ProfitLossCollapse
                    title={this.tr('Filters')}
                    open={this.state.viewOptions.filtersVsible}
                    onToggle={this.toggleView.bind(this, 'filtersVsible')}>
                    <ProfitLossLegend
                        hiddenCharts={hiddenCharts}
                        labels={labels}
                        charts={charts}
                        chartGroups={chartGroups}
                        onChange={this.changeVisibleCharts} />
                </ProfitLossCollapse>}

                {initialLoaded && <ProfitLossCollapse
                    title={this.tr('Graph')}
                    open={this.state.viewOptions.graphVisible}
                    onToggle={this.toggleView.bind(this, 'graphVisible')}>
                    <ProfitLossChart
                        visibleCharts={_.difference(allCharts, hiddenCharts)}
                        // hiddenCharts={hiddenCharts}
                        labels={labels}
                        monthTitles={this.months}
                        charts={charts}
                        chartGroups={chartGroups}
                        currencyFormatter={currencyFormatter} />
                </ProfitLossCollapse>}

                {initialLoaded && <ProfitLossCollapse
                    title={this.tr('Data Grid')}
                    className="ProfitLossTable"
                    open={this.state.viewOptions.datagridVisible}
                    onToggle={this.toggleView.bind(this, 'datagridVisible')}>
                    <ProfitLossTable
                        hiddenCharts={hiddenCharts}
                        labels={labels}
                        sets={sets}
                        totals={totals}
                        cumulative={cumulative}
                        data={data}
                        charts={charts}
                        chartGroups={chartGroups}
                        currency={currency}
                        currencyFormatter={currencyFormatter}
                        openSlider={this.openSlider} />
                </ProfitLossCollapse>}
            </div>

            <InsightSlider
                onClose={this.onCloseSlider}
                open={sliderProps !== false}
                columns={["number", "project", "account", "sum"]}
                {...sliderProps} />
        </InsightsBase>
    }

    renderViewButtonOptions = () => {
        const { viewOptions } = this.state;
        
        const switchProps = (name) => ({ checked: viewOptions[name], onChange: () => this.toggleView(name) })
        const switchPropsAlt = (name) => ({ checked: !viewOptions[name], onChange: () => this.toggleView(name) })
        const onClick = (name) => () => this.toggleView(name);

        return <List classes={{ root: 'profit-loss-list-root' }}>
            <ListItem onClick={onClick('invoices_by_due')}>
                <ListItemText>{this.tr("Show invoices by due date")}</ListItemText>
                <ListItemSecondaryAction>
                    <Switch color="primary" {...switchProps('invoices_by_due')} />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem onClick={onClick('invoices_by_due')}>
                <ListItemText>{this.tr("Show invoices by invoice date")}</ListItemText>
                <ListItemSecondaryAction>
                    <Switch color="primary" {...switchPropsAlt('invoices_by_due')} />
                </ListItemSecondaryAction>
            </ListItem>

            <ListItem onClick={onClick('bills_by_due')}>
                <ListItemText>{this.tr("Show bills by due date")}</ListItemText>
                <ListItemSecondaryAction>
                    <Switch color="primary" {...switchProps('bills_by_due')} />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem onClick={onClick('bills_by_due')}>
                <ListItemText>{this.tr("Show bills by invoice date")}</ListItemText>
                <ListItemSecondaryAction>
                    <Switch color="primary" {...switchPropsAlt('bills_by_due')} />
                </ListItemSecondaryAction>
            </ListItem>

            <ListItem onClick={onClick('purchase_order_by_pay')}>
                <ListItemText>{this.tr("Show purchase orders by pay date")}</ListItemText>
                <ListItemSecondaryAction>
                    <Switch color="primary" {...switchProps('purchase_order_by_pay')} />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem onClick={onClick('purchase_order_by_pay')}>
                <ListItemText>{this.tr("Show purchase orders by create date")}</ListItemText>
                <ListItemSecondaryAction>
                    <Switch color="primary" {...switchPropsAlt('purchase_order_by_pay')} />
                </ListItemSecondaryAction>
            </ListItem>

            <ListItem onClick={onClick('use_stage_probability')}>
                <ListItemText>{this.tr("Use stage probability for scheduled and automatic invoicing")}</ListItemText>
                <ListItemSecondaryAction>
                    <Switch color="primary" {...switchProps('use_stage_probability')} />
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    }
}

export default withSnackbar(ProfitLoss);