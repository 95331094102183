import React from 'react';
import ListCell from "./../ListCell";
import ClickAwayListener from '@mui/material/ClickAwayListener';

class TextArea extends React.Component {
	static defaultProps = {
		className: "",
		initValue: "",
		placeholder: "",
		focusOnMount: false,
		textAlign: "left",
		overRunNativeValue: false,
		limit: 0,
		onInputListener: () => {},
		onFocus: () => {},
		onBlur: () => {},
		onKeyUp: () => {},
		onEnter: () => {},
		onOverLimit: () => {}
	};


	constructor(props) {
		super(props);

		this.inputRef = React.createRef();
		this.onInput  = this.onInput.bind(this);
		this.onKeyUp  = this.onKeyUp.bind(this);
		this.state    = { value: props.initValue };
	}


	componentDidMount() {
		if(this.props.focusOnMount)
			this.inputRef.current.focus();

		if (this.props.initialAutoHeight && document.getElementById(this.props.id)) {
			const height = document.getElementById(this.props.id).scrollHeight;
			document.getElementById(this.props.id).setAttribute('style','height:'+ height +'px');
		}
	}

	checkLimit = (e) => {
        const { limit, onOverLimit } = this.props;
		const { value } = this.state;
        const limitValue = Number(limit);

        if (limitValue) {
            const newLength = e.target.value.length;
            const restrictNewValue = newLength > value.length && newLength > limitValue;

            if (restrictNewValue && value.length <= limitValue) { // Old value is not yet over limit. Slice new value max to limit.
				onOverLimit && onOverLimit(limit);
                return e.target.value.slice(0, limit);
            }
            else if (restrictNewValue && value.length > limitValue) { // Old value is already over limit. Return old value in onChange.
				onOverLimit && onOverLimit(limit);
                return value;
            }   
            else { // New value is not over limit or new length is less than old length.
				return e.target.value;
            }
        }
        else {
            return e.target.value;
        }
    }

	onInput(event) {
		event.persist();
		let value = event.target.value;
		const limit = Number(this.props.limit);
		if (limit) {
			value = this.checkLimit(event);
		}

		this.setState({
			value: value
		}, () => {
			if(typeof this.props.onInputListener == "function")
				this.props.onInputListener(value, event);
		});
	}


	setValue(value) {
		this.setState({ value: value });
	}


	getValue() {
		return this.state.value;
	}


	focus() {
		this.inputRef.current && this.inputRef.current.focus();
	}


	select() {
		this.inputRef.current && this.inputRef.current.select();
	}


	onKeyUp(event) {
		let value = event.target.value;
		const limit = Number(this.props.limit);
		if (limit) {
			value = this.checkLimit(event);
			event.target.value = value;
		}
		this.props.onKeyUp(event);
		
		if(!event.shiftKey && event.keyCode === 13)
			this.props.onEnter(event);
	}


	render() {
		return (
			<textarea
				id={this.props.id}
				className={this.props.className}
				type={this.props.type || "text"}
				ref={this.inputRef}
				value={this.props.overRunNativeValue ? this.props.initValue : this.state.value}
				onChange={this.onInput}
				onKeyUp={this.onKeyUp}
				onFocus={this.props.onFocus}
				onBlur={this.props.onBlur}
                placeholder={this.props.placeholder}
                title={this.props.title ? this.props.title : ''}
				style={{ textAlign: this.props.textAlign }} />
		);
	}
}

class TextAreaCell extends React.Component {
	static defaultProps = {
		listCellType: ListCell,
		listCellProps: {},
		runOnEditedOnInput: false,
		width: 100,
		ownerRow: undefined,
		name: undefined,
		focusOnMount: false,
		value: "",
		limit: 0,
		editable: true,
		inputType: "text",
		placeholder: "",
		textAlign: "left",
		onEdited: () => {},
		onEnter: () => {},
		onOverLimit: () => {}
	};


	constructor(props) {
		super(props);

		this.listCell  = React.createRef();
		this.textInput = React.createRef();

		this.state = {
	        initialValue: undefined,
	        value: ""
	    };

		this.runOnEdited   = this.runOnEdited.bind(this);
		this.focusOnEditor = this.focusOnEditor.bind(this);
	}


	runOnEdited(evt) {
        const value = this.textInput.current.getValue();

		this.props.onEdited.length === 1 ? this.props.onEdited(value) : this.props.onEdited(this.props.name, value);

		this.listCell.current.closeEdit();
	}


	focusOnEditor() {
		this.textInput.current.focus();
		this.textInput.current.select();
	}


	render() {
		const ListCellType  = this.props.listCellType;
		const listCellProps = Object.assign({}, {
			owner: 	  this,
			ref: 	  this.listCell,
			width: 	  this.props.width,
			value: 	  this.props.value,
			editable: this.props.editable,
			onEnterEditMode: () => {
				// if(!this.props.listCellProps.hasOwnProperty("noInitFocus") || !this.props.listCellProps.noInitFocus) {
					this.focusOnEditor();
				// }
			}
		}, this.props.listCellProps);

		return (
				<ListCellType textAlign={this.props.textAlign} {...listCellProps} >
					<TextArea
						id={this.props.id}
						key={this.props.value}
						ref={this.textInput}
						limit={this.props.limit}
						onOverLimit={this.props.onOverLimit}
						initValue={this.props.editValue || this.props.value}
						onEnter={e => {
							this.runOnEdited(e);
							this.props.onEnter(e);
						}}
						onBlur={this.runOnEdited}
						initialAutoHeight={this.props.initialAutoHeight}
						onInputListener={this.props.runOnEditedOnInput ? this.runOnEdited : () => {}}
						className="textInputCell"
						type={this.props.inputType}
						textAlign={this.props.textAlign}
						// focusOnMount={(!this.props.listCellProps.hasOwnProperty("noInitFocus") || !this.props.listCellProps.noInitFocus) && this.props.focusOnMount}
						placeholder={this.props.placeholder} 
						overRunNativeValue={this.props.overRunNativeValue} />
				</ListCellType>
		);
	}
}

export default TextAreaCell;
