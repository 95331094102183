/* react */
import React from 'react';

/* css */
import './Efina.css';
import './Maventa.css';

/* material-ui */
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";

/* others */
import List from "../../list/List";
import Utils from "./../../general/Utils.js";
import ListCell from "../../list/ListCell";
import TreeOption from "../../list/TreeOption";
import SettingRow from "../../list/rows/SettingRow";
import DataHandler from '../../general/DataHandler';
import TrashCanIcon from "@mui/icons-material/Delete";
import TextInputCell from "../../list/cells/TextInputCell";
import CreateBobOptions from "../../list/CreateBobOptions";
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import { SettingsContext } from '../../SettingsContext';
import SettingsGrid from '../pages/SettingsGrid';
import AccountingVatCodes from '../components/AccountingVatCodes';
import FileSaver from 'file-saver';
import { MenuItem } from '@mui/material';

class AccountRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            account_number: <TextInputCell
                width={this.props.columnWidthMap['account_number']}
                name="account_number"
                value={this.state.data['account_number']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class ProductRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            product_id: <TextInputCell
                width={this.props.columnWidthMap['product_id']}
                name="product_id"
                value={this.state.data['product_id']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class DimensionHeaderRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class DimensionRow extends SettingRow {
    branchChanged(obj) {
        const newState = this.state.data; 
        newState.branchofbusiness_id = obj.id; 
        this.setState({data: newState}); 
        this.editAndSave();
    }

    dimensionHeadersChanged(obj) {
        const newState = this.state.data; 
        newState.accounting_dimension_headers_id = obj.id; 
        this.setState({data: newState}); 
        this.editAndSave();
    }

    render() {
        const bob = this.props.rowProps.branches.find(b => b.id == this.state.data['branchofbusiness_id']);
        const dh = this.props.rowProps.dimensionHeaders.find(d => d.id == this.state.data['accounting_dimension_headers_id']);
       
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            branchofbusiness_id: <AutoCompleteCell
                listCellProps={{
                    className: "branchofbusiness_id"
                }}
                editable={true}
                autoCompleteData={this.props.rowProps.branches}
                name="branchofbusiness_id"
                width={this.props.columnWidthMap['branchofbusiness_id']}
                value={bob}
                components={{Option: props => {
                    const passProps = {
                        isDisabled: props.data.data.deleted == 1 || props.data.data.locked == 1,
                        isFocused: 	props.isFocused,
                        isMulti: 	props.isMulti,
                        isSelected: props.isSelected
                    };
        
                    return <TreeOption {...props.data} {...passProps} onSelect={bob => {
                        props.setValue(bob.id)
                        this.branchChanged(bob);
                    }} />;
                }}}
            />,
            accounting_dimension_headers_id: <AutoCompleteCell
                listCellProps={{
                    className: "accounting_dimension_headers_id"
                }}
                editable={true}
                autoCompleteData={this.props.rowProps.dimensionHeaders}
                name="accounting_dimension_headers_id"
                width={this.props.columnWidthMap['accounting_dimension_headers_id']}
                value={dh}
                onEdited={obj => this.dimensionHeadersChanged(obj)}
            />
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class Netvisor extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Netvisor");

        this.state = {
            username: "",
            password: "",
            branches: [],
            salesAccounts: [],
            purchasesAccounts: [],
            accountingProducts: [],
            dimensionHeaders: [],
            dimensions: [],
            ratios: [],
            project_dimension_format: 0,
            escan_project_dimension_format: 0,
            paymentTypes: [],
            creditCardPaymentType: 0,
            ownMoneyPaymentType: 0,
        };

        this.salesAccountsList = React.createRef();
        this.purchasesAccountsList = React.createRef();
        this.productsList = React.createRef();
        this.dimensionHeadersList = React.createRef();
        this.dimensionsList = React.createRef();
        this.ratiosList = React.createRef();

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.invoiceTypes = [
            {value: 0, label: this.tr("No invoices")},
            {value: 1, label: this.tr("Open invoices")},
            {value: 2, label: this.tr("Approved invoices")},
            {value: 3, label: this.tr("Accepted invoices")},
            {value: 4, label: this.tr("All invoices")},
        ];

        this.projectDimensionFormats = [
            {value: 0, label: this.tr("Project number and Project name")},
            {value: 1, label: this.tr("Only project number")},
            {value: 2, label: this.tr("Only project name")},
        ];

        if (this.context.addons.netvisor_wildcard_project_dimension_format) {
            this.projectDimensionFormats.push({value: 3, label: this.tr("Project number *")});
        }

        this.integration = "netvisor";
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = async () => {
        this.getAuthData();
        this.getSalesAccounts();
        this.getPurchasesAccounts();
        this.getProducts();
        this.getBranches();
        await this.getDimensionHeaders();
        this.getDimensions();
        this.getRatios();
        this.getPaymentTypes();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/netvisor`}).done(response => {
            this.setState(response);
        }).fail(response => {
            this.setState({"username": "", "password": "", "activated": false});
        });
    }

    getSalesAccounts = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/netvisor/1`}).done(response => {
            this.setState(response);
        });
    }

    getPurchasesAccounts = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/netvisor/2`}).done(response => {
            this.setState(response);
        });
    }

    getProducts = () => {
        DataHandler.get({url: `accounting/products/${this.props.company}/netvisor`}).done(response => {
            this.setState(response);
        });
    }

    getBranches = () => {
        DataHandler.get({url: `dialogs/branches`, companies_id: this.props.company}).done(response => {
            const notSelectedOption = {
                accounting_integration_id: "",
                children: [],
                companies_id: "",
                deleted: "0",
                hubspot_id: "",
                id: "0",
                is_default: "0",
                locked: "0",
                name: "Not selected",
                parent_id: null
            };
            response.unshift(notSelectedOption);
            response = Utils.treeFormatDataForList(response, "parent_id");
            if(response) {
                response = CreateBobOptions(response);
            }
            this.setState({branches: response});
        });
    }

    getDimensions = () => {
        DataHandler.get({url: `accounting/dimensions/${this.props.company}/netvisor`}).done(response => {
            this.setState(response);
        });
    }

    getDimensionHeaders = async () => {
        const response = await DataHandler.get({url: `accounting/dimension_headers/${this.props.company}/netvisor`});
        this.setState(response);
    }

    getRatios = () => {
        DataHandler.get({url: `accounting/ratios/${this.props.company}/netvisor`}).done(response => {
            this.setState(response);
        });
    }

    getPaymentTypes = () => {
        DataHandler.get({ url: 'expenses/payment_types', only_regular_types: true }).done(response => {
            this.setState({paymentTypes: response.payment_types});
        });
    }

    onClickSave = () => {
        DataHandler.put({url: `settings/company/${this.props.company}/netvisor`}, this.state).done(resp => {
            this.props.enqueueSnackbar(this.tr("Netvisor authentication information saved"), {
                variant: "success",
            });	
            this.updateComponentData();
        });
    }

    updateIds = () => {
        const key = this.props.enqueueSnackbar("Updating Customer IDs...", {
            variant: "info",
            persist: true
        });

        DataHandler.getArrayBuffer({url: `settings/company/${this.props.company}/netvisor/customer_register`}).done(response => {
            this.props.closeSnackbar(key);

            this.props.enqueueSnackbar(this.tr("Customer IDs updated"), {
                variant: "success",
            });

            const blob = new Blob([response], {
                type: 'text/csv'
            });
            FileSaver.saveAs(blob, "missing_customers.csv");
        }).fail(response => {
            this.props.closeSnackbar(key);
            
            this.props.enqueueSnackbar(this.tr("Error while fetching customer data. Check authentication informaation and API privileges in Netvisor." ), {
                variant: "error",
            });
        });
    }

    updateProducts = () => {
        const key = this.props.enqueueSnackbar("Updating products...", {
            variant: "info",
            persist: true
        }); 

        DataHandler.get({url: `settings/company/${this.props.company}/netvisor/products`}, this.state).done(response => {
            this.props.closeSnackbar(key);

            if (response.status == "error") {
                this.props.enqueueSnackbar(this.tr("Error: " + response.message), {
                    variant: "error",
                });
            } else {
                this.props.enqueueSnackbar(this.tr("Products fetched from Netvisor successfully"), {
                    variant: "success",
                });

                this.getProducts();
            }
        });
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        DataHandler.delete({url: `settings/company/${this.props.company}/netvisor`}).done(this.back);
    }

    onCheckChange = (update, name, value) => {
        this.setState({[name]: value}, () =>  DataHandler.put({url: `settings/company/${this.props.company}/netvisor/contract_billing`}, this.state));
    }

    onSettingCheckChange = (update, name, value) => {
        this.setState({[name]: value}, () =>  DataHandler.put({url: `settings/company/${this.props.company}/netvisor/settings`}, {[name]: value}));
    }

    changeDefault = (type, id) => {
        const company = this.props.company;
        this.setState({[type]: id}, () => DataHandler.put({url: `accounting/defaults/${company}`}, {type: type, id: id }));
    }

    changeInvoiceType = (type) => {
        DataHandler.put({url: `settings/company/${this.props.company}/netvisor/purchase_invoice_type`}, {type: type}).done(() => {
            this.setState({purchase_invoice_type: type});
        });
    };

    changePaymentType = (id, type) => {
        DataHandler.put({url: `settings/company/${this.props.company}/netvisor/expense_payment_type`}, {id: id, type: type}).done(() => {
            if (type == 1) {
                this.setState({creditCardPaymentType: id});
            } else {
                this.setState({ownMoneyPaymentType: id});
            }
        });
    };
    
    render(){
        const { username, password, activated } = this.state;        
        const { tr } = this;

        const settings = { use_maventa_targeting: this.state.use_maventa_targeting, use_project_dimension: this.state.use_project_dimension, use_escan_project_dimension: this.state.use_escan_project_dimension, escan_project_dimension_header: this.state.escan_project_dimension_header };

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Netvisor Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("Authentication information")}</h4>
                    <OutlinedField value={username} onChange={(evt) => this.setState({username: evt.target.value})} label={tr("User ID")} />
                    <OutlinedField value={password} onChange={(evt) => this.setState({password: evt.target.value})} label={tr("API Key")} autoComplete="new-password" />
                </div>

                <div className="settings">
                    <Button color="primary" onClick={this.onClickSave} size="large">{tr("Save")}</Button>
                </div>

                <div className="settings">
                    <h4>{tr("Update Customer IDs")}</h4>
                    <Button color="primary" onClick={this.updateIds} size="large">{tr("Update")}</Button>
                </div>

                <div className="settings">
                    <h4>{tr("Sales accounts")}</h4>
                    <Button className="green" onMouseUp={() => this.salesAccountsList.current.addNewRow()} size="large">{tr("Add account")}</Button>
                    <br />
                    <List
                        ref={this.salesAccountsList}
                        id="accountingSalesAccountList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            account_number: "",
                        }}
                        listRowType={AccountRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Account name"), ...this.dColConf },
                            { width: 200, name: "account_number", header: tr("Account number"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                const company = this.props.company;
                                DataHandler.post({url: `accounting/accounts/${company}/netvisor/1`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            },
                            onUpdate: data => {
                                const company = this.props.company;
                                DataHandler.put({url: `accounting/accounts/${company}`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            },
                            onDelete: data => {
                                if(data.id == this.state.default_accounting_accounts_id) {
                                    this.props.enqueueSnackbar(this.tr("Default account cannot be deleted"), {
                                        variant: "error",
                                    });	
                                    return;
                                }
                                data.deleted = "1";
                                const company = this.props.company;
                                DataHandler.delete({url: `accounting/accounts/${company}`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getSalesAccounts();
                                });
                            }
                        }}
                        data={this.state.salesAccounts}
                    />

                    <div className="drop-section">
                        <h4>{tr("Default sales account")}</h4>
                        <OutlinedField select disabled={false} label={tr("Default")} name={"default"} value={this.state.default_accounting_accounts_id} fullWidth >
                            <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_accounts_id", 0)}>{this.tr("No default")}</MenuItem>
                            {this.state.salesAccounts.map(({name, id}) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_accounts_id", id)}>{name}</MenuItem>)}
                        </OutlinedField>
                    </div>
                </div>
                <div className="settings">                  
                    <h4>{tr("Purchase accounts")}</h4>
                    <Button className="green" onMouseUp={() => this.purchasesAccountsList.current.addNewRow()} size="large">{tr("Add account")}</Button>
                    <br />
                    <List
                        ref={this.purchasesAccountsList}
                        id="accountingPurchasesAccountList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            account_number: "",
                        }}
                        listRowType={AccountRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Account name"), ...this.dColConf },
                            { width: 200, name: "account_number", header: tr("Account number"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                const company = this.props.company;
                                DataHandler.post({url: `accounting/accounts/${company}/netvisor/2`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getPurchasesAccounts();
                                });
                            },
                            onUpdate: data => {
                                const company = this.props.company;
                                DataHandler.put({url: `accounting/accounts/${company}`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getPurchasesAccounts();
                                });
                            },
                            onDelete: data => {
                                data.deleted = "1";
                                const company = this.props.company;
                                DataHandler.delete({url: `accounting/accounts/${company}`}, { data: JSON.stringify(data) }).done(response => {
                                    this.getPurchasesAccounts();
                                });
                            }
                        }}
                        data={this.state.purchaseAccounts}
                    />
                </div>
                <div className="settings">                  
                    <h4>{tr("Products")}</h4>
                    <Button className="green" onMouseUp={() => this.productsList.current.addNewRow()} size="large">{tr("Add product")}</Button>
                    <br />
                    <List
                        ref={this.productsList}
                        id="accountingProductsList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            product_id: "",
                        }}
                        listRowType={ProductRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Product name"), ...this.dColConf },
                            { width: 200, name: "product_id", header: tr("Product ID"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                data.company = this.props.company;
                                DataHandler.post({url: 'accounting/products/netvisor'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: 'accounting/products'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            },
                            onDelete: data => {
                                if(data.id == this.state.default_accounting_products_id) {
                                    this.props.enqueueSnackbar(this.tr("Default product cannot be deleted"), {
                                        variant: "error",
                                    });	
                                    return;
                                }
                                data.deleted = "1";
                                DataHandler.delete({url: 'accounting/products'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            }
                        }}
                        data={this.state.accountingProducts}
                    />

                    <div className="drop-section">
                        <h4>{tr("Default product")}</h4>
                        <OutlinedField select disabled={false} label={tr("Default")} name={"default"} value={this.state.default_accounting_products_id} fullWidth >
                            <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_products_id", 0)}>{this.tr("No default")}</MenuItem>
                            {this.state.accountingProducts.map(({name, id}) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_products_id", id)}>{name}</MenuItem>)}
                        </OutlinedField>
                    </div>

                    <div className="settings">
                        <h4>{tr("Fetch Netvisor product register")}</h4>
                        <Button color="primary" onClick={this.updateProducts} size="large">{tr("Fetch product register")}</Button>
                    </div>
                </div>
                <div className="settings">                  
                    <h4>{tr("Dimension headers")}</h4>
                    <Button className="green" onMouseUp={() => this.dimensionHeadersList.current.addNewRow()} size="large">{tr("Add dimension header")}</Button>
                    <br />
                    <List
                        ref={this.dimensionHeadersList}
                        id="dimensionHeadersList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                        }}
                        listRowType={DimensionHeaderRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Dimension header name"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                data.company = this.props.company;
                                DataHandler.post({url: 'accounting/dimension_headers/netvisor'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensionHeaders();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: 'accounting/dimension_headers'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensionHeaders();
                                });
                            },
                            onDelete: data => {
                                data.deleted = "1";
                                DataHandler.delete({url: 'accounting/dimension_headers'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensionHeaders();
                                });
                            }
                        }}
                        data={this.state.dimensionHeaders}
                    />
                </div>
                <div className="settings">                  
                    <h4>{tr("Dimensions")}</h4>
                    <Button className="green" onMouseUp={() => this.dimensionsList.current.addNewRow()} size="large">{tr("Add dimension")}</Button>
                    <br />
                    <List
                        ref={this.dimensionsList}
                        id="dimensionsList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            accounting_dimension_headers_id: 0,
                            branchofbusiness_id: 0
                        }}
                        listRowType={DimensionRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "accounting_dimension_headers_id", header: tr("Dimension header"), ...this.dColConf },
                            { width: 350, name: "branchofbusiness_id", header: tr("Project category"), ...this.dColConf },
                            { width: 350, name: "name", header: tr("Dimension name"), ...this.dColConf },
                        ]}
                        rowProps={{
                            branches: this.state.branches ? this.state.branches : [],
                            dimensionHeaders: this.state.dimensionHeaders ? this.state.dimensionHeaders : [],
                            onCreate: data => {
                                data.company = this.props.company;
                                DataHandler.post({url: 'accounting/dimensions/netvisor'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensions();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: 'accounting/dimensions'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensions();
                                });
                            },
                            onDelete: data => {
                                if(data.id == this.state.default_accounting_dimensions_id) {
                                    this.props.enqueueSnackbar(this.tr("Default dimension cannot be deleted"), {
                                        variant: "error",
                                    });	
                                    return;
                                }
                                data.deleted = "1";
                                DataHandler.delete({url: 'accounting/dimensions'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getDimensions();
                                });
                            }
                        }}
                        data={this.state.dimensions}
                    />
                    <div className="drop-section">
                        <h4>{tr("Default dimension")}</h4>
                        <OutlinedField select disabled={false} label={tr("Default")} name={"default"} value={this.state.default_accounting_dimensions_id} fullWidth >
                            <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_dimensions_id", 0)}>{this.tr("No default")}</MenuItem>
                            {this.state.dimensions.map(({name, id}) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_dimensions_id", id)}>{name}</MenuItem>)}
                        </OutlinedField>
                    </div>

                    <div id="settings-maventa" className="main-integration-wrapper">
                        <h4>{tr("Netvisor project dimension")}</h4>
                        <p style={{ fontSize: "11px" }}>{tr("Adds dimension called 'Projekti' automatically to sales invoices when sending them to Netvisor")}</p>
                        <SettingsGrid item={settings} onChange={this.onSettingCheckChange} settings={[{
                            type: "check",
                            name: "use_project_dimension",
                            label: tr("Use project dimensions"),
                            className: "respSetting third"
                        }]} />

                        {this.state.use_project_dimension == 1 && (
                            <>
                            <OutlinedField select disabled={false} label={this.tr("Project dimension format")} name={"default"} value={this.state.project_dimension_format} fullWidth >
                                {this.projectDimensionFormats.map(({value, label}) => <MenuItem key={value} value={value} onClick={() => this.onSettingCheckChange("", "project_dimension_format", value)}>{label}</MenuItem>)}
                            </OutlinedField>
                            </>
                        )}
                    </div>

                    <div id="settings-maventa" className="main-integration-wrapper">
                        <h4>{tr("Netvisor purchase expense eScan project dimension")}</h4>
                        <p style={{ fontSize: "11px" }}>{tr("Adds project dimension automatically to purchase expenses when sending to Netvisor via eScan. If dimension header is not defined, 'Project' will be used.")}</p>
                        <SettingsGrid item={settings} onChange={this.onSettingCheckChange} settings={[{
                            type: "check",
                            name: "use_escan_project_dimension",
                            label: tr("Use eScan project dimensions"),
                            className: "respSetting third"
                        }]} />

                        {this.state.use_escan_project_dimension == 1 && (
                            <>
                             <SettingsGrid 
                                item={settings} 
                                onChange={(values) => {this.onSettingCheckChange("", 'escan_project_dimension_header', values.escan_project_dimension_header)}} 
                                settings={[{
                                    type: "text",
                                    name: "escan_project_dimension_header",
                                    label: this.tr("eScan project dimension header"),
                                    className: "respSetting third"
                                }]} />
                            <OutlinedField select disabled={false} label={this.tr("eScan project dimension format")} name={"default"} value={this.state.escan_project_dimension_format} fullWidth >
                                {this.projectDimensionFormats.map(({value, label}) => <MenuItem key={value} value={value} onClick={() => this.onSettingCheckChange("", "escan_project_dimension_format", value)}>{label}</MenuItem>)}
                            </OutlinedField>
                            </>
                        )}
                    </div>
                </div>
                <div className="settings">                  
                    <h4>{tr("Ratios")}</h4>
                    <Button className="green" onMouseUp={() => this.ratiosList.current.addNewRow()} size="large">{tr("Add ratio")}</Button>
                    <br />
                    <List
                        ref={this.ratiosList}
                        id="ratiosList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                        }}
                        listRowType={DimensionHeaderRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Ratio name"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                data.company = this.props.company;
                                DataHandler.post({url: 'accounting/ratios/netvisor'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getRatios();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: 'accounting/ratios'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getRatios();
                                });
                            },
                            onDelete: data => {
                                data.deleted = "1";
                                DataHandler.delete({url: 'accounting/ratios'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getRatios();

                                });
                            }
                        }}
                        data={this.state.ratios}
                    />
                </div>

                <div className="settings main-integration-wrapper">
                    <h4>{tr("VAT codes")}</h4>
                    <p>{tr("Add VAT codes that can be used in invoices. You can check the VAT codes here:")} <a target="_blank" href="https://support.netvisor.fi/fi/support/solutions/articles/77000467133-arvonlis%C3%A4veron-k%C3%A4sittely-ja-j%C3%A4rjestelm%C3%A4n-alv-tunnisteet" rel="noreferrer">{tr("Netvisor VAT code information")}</a>.</p>
                    <AccountingVatCodes
                        integration={this.integration}
                        company={this.props.company}
                        showVatAccount={false}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                    />
                </div>

                <div id="settings-maventa" className="main-integration-wrapper">
                    <h4>{tr("Netvisor contract billing")}</h4>
                    <p>{tr("Connect your Netvisor bills to contract billing of your customers")}</p>
                    <SettingsGrid item={settings} onChange={this.onCheckChange} settings={[{
                        type: "check",
                        name: "use_maventa_targeting",
                        label: tr("Netvisor contract billing"),
                        className: "respSetting third"
                    }]} />
                </div>

                <div className="settings">
                    <h4>{tr("Purchase invoice type")}</h4>
                    <p>{tr("Select the status of purchase invoices that are fetched daily from Netvisor To Taimer")}</p>
                    <OutlinedField select disabled={false} label={this.tr("Invoice type")} name={"default"} value={this.state.purchase_invoice_type} fullWidth >
                        {this.invoiceTypes.map(({value, label}) => <MenuItem key={value} value={value} onClick={() => this.changeInvoiceType(value)}>{label}</MenuItem>)}
                    </OutlinedField>
                </div>

                {/*<div className="settings">
                    <h4>{tr("Expense payment types")}</h4>
                    <p>{tr("Select which expense payment type is for company credit card payments and which is for own money payments so Taimer can set the infomation correctly when sending to Netvisor.")}</p>
                    
                    <h4>{this.tr("Company credit card")}</h4>
                    <OutlinedField select disabled={false} label={this.tr("Payment type")} name={"creditCardPaymentType"} value={this.state.creditCardPaymentType} fullWidth >
                        {this.state.paymentTypes.map(({id, name}) => <MenuItem key={id} value={id} onClick={() => this.changePaymentType(id, 1)}>{name}</MenuItem>)}
                    </OutlinedField>
                    
                    <h4>{this.tr("Own money")}</h4>
                    <OutlinedField select disabled={false} label={this.tr("Payment type")} name={"ownMoneyPaymentType"} value={this.state.ownMoneyPaymentType} fullWidth >
                        {this.state.paymentTypes.map(({id, name}) => <MenuItem key={id} value={id} onClick={() => this.changePaymentType(id, 2)}>{name}</MenuItem>)}
                    </OutlinedField>
                </div> */}
            </div>
        )
    }
}

export default Netvisor;
