import React, { Component } from 'react';
import Button from '@mui/material/Button';
import mainLogo from "../images/onedrive-taimer.png";
import LinearProgress from '@mui/material/LinearProgress';
import withStyles from '@mui/styles/withStyles';
import TaimerComponent from "../../TaimerComponent";

const styles = theme => ({
    groupprogress: {
        width: "100%",
    }
});

class LoginView extends TaimerComponent {

    constructor(props) {
        super(props, null, "onedrive/OnedriveComponents/LoginView");
    }

    render() {
        const { classes } = this.props;

        return (
            <div id="mail-signin">
                    <div className="left_div">
                        <div className="text_div">
                            <h1>{this.tr("Welcome to")} <b>{this.tr("Taimer OneDrive")}</b></h1>
                            <div className="info">
                            <div className="mail_infotext">
                                {this.tr("Activate your full sales potential and sync your files to Taimer.")}<br/><br/>
                                {this.tr("When unlocked, you can upload and download files straight from your OneDrive.")}<br/><br/>
                            </div>
                            </div>
                            { this.props.loading ? <LinearProgress className={classes.groupprogress} /> : <Button onClick={() => this.props.onClickOneDrive()}>ONEDRIVE</Button> }
                        </div>
                    </div>
                    <div className="right_div">
                        <img src={mainLogo}/>
                    </div>
            </div>)
    }
}

export default withStyles(styles)(LoginView);