import React from 'react';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import DataHandler from '../../general/DataHandler';
import DialogBorder from '../DialogBorder';
import DialogSection from '../DialogSection';
import TaimerComponent from  '../../TaimerComponent';
/* dialog parts */
import LoadingScreen from '../../general/LoadingScreen';
import ResultsScreen from './ResultsScreen';
import FileUpload from './FileUpload';
/* material ui */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

/* other */
import { withSnackbar } from 'notistack';
import _ from 'lodash';

/* css */
import './ImportDialog.css';
import './ImportExpensesDialog.css';

const initialState = {
    file: [],
    fileData: false,
    importStage: "upload",
    results: {total: 0, expenses: 0, travel_expenses: 0, errors: []}
}

class ImportExpensesDialog extends TaimerComponent {

    static contextType = SettingsContext;
    constructor(props, context) {
        super(props, context, "dialogs/imports/ImportExpensesDialog")
       
        this.state = _.cloneDeep(initialState);
        this.dialog = React.createRef();
        this.upload = React.createRef();

        ["startLoadingAnimation", 
        "stageFile", 
        "uploadFile"].forEach(e => this[e] = this[e].bind(this));
    }
    
    componentDidMount() {
        super.componentDidMount();
    }

    close = () => {
        this.props.onClose();
    }

    stageFile(file) {
        this.setState({file: file[0]});
    }

    uploadFile() {
        const { enqueueSnackbar } = this.props;
        const { file } = this.state;
        if(!file) {
            enqueueSnackbar(this.tr(`Upload a file to continue.`), {
                variant: "error",
            });
            return;
        }
        if(!file?.name?.endsWith('csv')) {
            enqueueSnackbar(this.tr(`File format is not CSV.`), {
                variant: "error",
            });
            return;
        }
        this.importExpenses(file);
    }

    importExpenses = (file) => {
        const e = {target: {name: "upload"}};
        this.startLoadingAnimation(e);

        DataHandler.file({ url: `imports/m2_expenses`, company: this.props.company }, file, undefined, e => {
            if (e.status != 200) {
                this.props.enqueueSnackbar(this.tr(`Error in importing file`), {
                    variant: "error",
                });
                this.close();         
                return;
            }

            this.setState({importStage: "results", results: e.responseJSON});
            this.props.onSuccess && this.props.onSuccess();
        });
    }

    startLoadingAnimation(e) {
        if(e.target.name === "upload")
            this.setState({importStage: "loading"});
    }

    renderResults = () => {
        const { results } = this.state;
        const errors = {
            project_not_found: this.tr("Project not found"),
            user_not_found: this.tr("User not found"),
            error_in_saving: this.tr("Error in saving row"),
        }

        const headers = {
            total: this.tr("Rows Processed"),
            expenses: this.tr("Expenses Imported"),
            travel_expenses: this.tr("Travel expenses Imported"),
            errors: this.tr("Errors")
        }

        if (results.error == "file_already_imported") {
            return (
                <div className="expense-dialog-result-container">
                    <p><span className='error'><b>{this.tr("Error")}:</b></span> {this.tr("File")} <b>{results.message}</b> {this.tr("has already been imported")}</p>
                </div>
            )
        }

        return (
            <div className="expense-dialog-result-container">
                <div className="result-headers" id="results">
                {_.map(results, (result, i) => {
                        if (i === "errors") {
                            return (
                                <div className="result">
                                    <h1 className={i}>{result?.length}</h1>
                                    <h3 className={"sub-header"}>{headers[i]}</h3>
                                </div>
                            )
                        } else {
                            return (
                                <div className="result">
                                    <h1 className={i}>{result}</h1>
                                    <h3 className={"sub-header"}>{headers[i]}</h3>
                                </div>
                            )
                        }
                    })}
                </div>
                {results.errors?.length > 0 && (
                    <div className="content-block">
                        <p>{this.tr("Importing had following error(s):")}</p>
                        <Table className="error-details">
                            <TableHead className="error-headers">
                                <TableRow>
                                    <TableCell className="left">{this.tr("Row")}</TableCell>
                                    <TableCell className="right">{this.tr("Error")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="error-container">
                                {results.errors.map(e => {
                                    return (
                                        <TableRow className="error-message">
                                            <TableCell className="left">{e.row}</TableCell>
                                            <TableCell className="right">{errors[e.error]}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </div>
        )
    }

    render() {
        const { company } = this.props;
        const { importStage } = this.state;
        const { tr } = this;

        const Components = {
            upload: 
                <FileUpload
                    ref={this.upload}
                    startLoadingAnimation={this.startLoadingAnimation} 
                    setFileData={this.setFileData}
                    stageFile={this.stageFile}
                    company={company}
                    uploadHeader={tr("Import expenses by dragging a file here or by")}
                />,
            loading:
                <LoadingScreen loadingMessage={tr("Processing data...")} />, 
            results: this.renderResults()
        }

        const Component = Components[importStage];
        
        return (
            <DialogBorder
                title={tr("Import expenses from file")}
                onClose={this.close}
                className="Account"
                id="ImportDialog"
                saveText={importStage === "results" ? tr("Close") : tr("Import")}
                onSave={
                    importStage === "upload" ? this.uploadFile :
                        importStage === "results" ? this.close :
                            undefined
                }
                hideCancel={importStage == "results"}
                dialogRef={this.dialog}>
                <DialogSection>
                    {Component}
                </DialogSection>
            </DialogBorder>
        );
    }
}

export default withSnackbar(ImportExpensesDialog);
