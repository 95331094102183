import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import { Line } from "react-chartjs-2";
import _ from 'lodash';

export default class FilledLineChart extends TaimerComponent {

  constructor(props, context) {
    super(props, context, "charts/FilledLineChart");
    this.refChart = React.createRef();
  }

  render() {

    const { labels, datasets, currency, options, displayLegend, toggleHidden, hiddenIndexes, stacked = true, xStacked = true } = this.props;
    const { functions: { presentCurrency } } = this.context;

    const data = {
      labels,
      datasets,
    };

    return (
      <Line
        ref={this.refChart}
        options={{
          ...options,
          onClick: (evt) => {
            const activePoint = this.refChart.current.chartInstance.getElementAtEvent(
              evt
            )[0];

            if (!activePoint) return;

            const data = activePoint._chart.data;
            const datasetIndex = activePoint._datasetIndex;




            data.datasets[datasetIndex] &&
              this.props.onClick(
                data.datasets[datasetIndex],
                activePoint._index
              );
          },
          plugins: {
            legend: {
              ...options.plugins.legend,
              display: displayLegend,
              onClick: (e, legendItem) => {
                const { datasetIndex } = legendItem;
                toggleHidden(datasetIndex);
              },
              labels: {
                ...options.plugins.legend.labels,
                generateLabels: () => {
                  return datasets.map((obj, i) => {
                    return {
                      text: obj.label,
                      pointStyle: "rectRounded",
                      fillStyle: obj.borderColor,
                      datasetIndex: i,
                      lineCap: "round",
                      lineJoin: "round",
                      strokeStyle: "#FFF",
                      lineWidth: 5,
                      hidden: hiddenIndexes.indexOf(i) != -1
                    };
                  });
                }
              }
            },
            tooltip: {
              ...options.plugins.tooltip,
              callbacks: {
                labelColor: (tooltipItem) => {
                  return {
                    backgroundColor:
                      datasets[tooltipItem.datasetIndex].borderColor
                  };
                },
                label: (tooltipItem) => {
                  const dataset = tooltipItem.dataset;
                  var label =
                    " " + _.escape(dataset.label) || "";
  
                  if (label) {
                    label += ": ";
                  }
                  label += presentCurrency(tooltipItem.raw, currency);
                  return label;
                }
              },
            },
          },
          scales: {
            ...options.scales,
            y: {
                ...options.scales.y,
                stacked: stacked,
                ticks: {
                  ...options.scales.y?.ticks,
                  callback: (value, index, values) => {
                    return presentCurrency(value, currency);
                  }
                }
              },
            x: {
                ...options.scales.x,
                stacked: xStacked
              }
          }
        }}
        data={data}
      />
    );
  }
}