import React from 'react';
import TaimerComponent from '../TaimerComponent';
import { Button } from '@mui/material';
import styles from './UpgradeVersionView.module.scss';
import { Check } from '@mui/icons-material';
import VersionContentManager from './VersionContentManager';
import DataHandler from '../general/DataHandler';

interface Props {
    version?: object;
}

interface State {
    selectedBillingCycle: 'arr' | 'mrr';
    open: boolean;
}

class UpgradeVersionView extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'general/UpgradeVersionView');
        this.state = {
            open: false,
            selectedBillingCycle: 'arr',
        };
    }

    onUpgradeClicked = (version) => {
        this.context.functions.sendMixpanelEvent('Upgrade plan', {
            "Plan": version.name,
            "Plan ID": version.id,
            "Subscription type": this.state.selectedBillingCycle == 'arr' ? "ARR" : "MRR"
        });
        this.upgrade(version.id);
    };

    upgrade = (version) => {
        // jos ei anneta versiota, palauttaa portalin
        DataHandler.get({ url: `settings/subscription/portal`, version, is_arr: this.state.selectedBillingCycle == 'arr' ? 1 : 0 }).done((response) => {
            window.location = response;
        });
    }

    setSelectedBillingCycle = (selectedBillingCycle) => this.setState({ selectedBillingCycle });

    render() {
        const { version: versionFromProps } = this.props;
        const { selectedBillingCycle } = this.state;
        const {
            functions: { hasPrivilege },
        } = this.context;

        // Version object with id and name, returned from the version model
        const version = versionFromProps || VersionContentManager.getNextVersion();
        return (
            <div className={styles.upgradeVersionView}>
                <img src={require('./icons/upgradeRocket.svg').default} />
                <div className={styles.header}>
                    <h1>{this.tr('Upgrade to ${version} plan', { version: version.name })}</h1>
                    {!versionFromProps && <p>{this.tr('To unlock this feature')}</p>}
                </div>
                <div className={styles.benefits}>
                    <h2>{this.tr('Additional benefits unlocked with the ${version} plan', { version: version.name })}:</h2>
                    <ul>
                        {this.tr(`${version.name}_benefit_1`).trim() && (
                            <li>
                                <Check />
                                <p>{this.tr(`${version.name}_benefit_1`)}</p>
                            </li>
                        )}
                        {this.tr(`${version.name}_benefit_2`).trim() && (
                            <li>
                                <Check />
                                <p>{this.tr(`${version.name}_benefit_2`)}</p>
                            </li>
                        )}
                        {this.tr(`${version.name}_benefit_3`).trim() && (
                            <li>
                                <Check />
                                <p>{this.tr(`${version.name}_benefit_3`)}</p>
                            </li>
                        )}
                        {this.tr(`${version.name}_benefit_4`).trim() && (
                            <li>
                                <Check />
                                <p>{this.tr(`${version.name}_benefit_4`)}</p>
                            </li>
                        )}
                        {this.tr(`${version.name}_benefit_5`).trim() && (
                            <li>
                                <Check />
                                <p>{this.tr(`${version.name}_benefit_5`)}</p>
                            </li>
                        )}
                    </ul>
                </div>
                <div className={styles.pricing}>
                    <h2>{this.tr('Select billing cycle')}</h2>
                    <div className={styles.prices}>
                        <div className={`${styles.price} ${selectedBillingCycle == 'arr' ? styles.selected : ''}`} onClick={() => this.setSelectedBillingCycle('arr')}>
                            <span className={`${styles.selectedIndicator} ${selectedBillingCycle == 'arr' ? styles.selected : ''}`}>
                                <Check />
                            </span>
                            <h3>{this.tr('Annual')}</h3>
                            <p className={styles.priceLabel}>
                                <strong>{this.tr(`${version.name}_annual_price`)}</strong>/{this.tr('month')}
                            </p>
                            <p>{this.tr('Per seat/month, billed annually')}</p>
                            {this.tr(`${version.name}_annual_campaign`) != '' && <p className={styles.campaign}>{this.tr(`${version.name}_annual_campaign`)}</p>}
                        </div>
                        <div className={`${styles.price} ${selectedBillingCycle == 'mrr' ? styles.selected : ''}`} onClick={() => this.setSelectedBillingCycle('mrr')}>
                            <span className={`${styles.selectedIndicator} ${selectedBillingCycle == 'mrr' ? styles.selected : ''}`}>
                                <Check />
                            </span>
                            <h3>{this.tr('Monthly')}</h3>
                            <p className={styles.priceLabel}>
                                <strong>{this.tr(`${version.name}_monthly_price`)}</strong>/{this.tr('month')}
                            </p>
                            <p>{this.tr('Per seat/month, billed monthly')}</p>
                        </div>
                    </div>
                </div>
                {hasPrivilege('admin', 'admin') ? (
                    <Button className={styles.cta} size="large" onClick={() => this.onUpgradeClicked(version)} disabled={!hasPrivilege('admin', 'admin')}>
                        {this.tr("Sure, let's upgrade")}
                    </Button>
                ) : (
                    <div className={styles.contactAdmin}>
                        <p>{this.tr('Contact the admin to upgrade')}</p>
                    </div>
                )}
            </div>
        );
    }
}

export default UpgradeVersionView;
