import React from "react";


import { SettingsContext } from "../SettingsContext";
import TaimerComponent from "../TaimerComponent";
import DataHandler from "../general/DataHandler";

import CustomViewBase, { ViewBlock } from "./base/CustomViewBase";

import styles from './TestView.module.scss';
import TestBlock from "./blocks/TestBlock";
import Keyfigures from "./blocks/Keyfigures";
import BlockBase from "./blocks/BlockBase";

import InvoicingOverView from '../dashboard/overview/Invoicing';


interface ViewSettings {

}


export default class TestView extends TaimerComponent {
    static contextType = SettingsContext;
    constructor(props, context) {
        super(props, context, "customview/TestView");

    }

    render() {
        return (<div className={styles.root}>
            <CustomViewBase
                className={styles.view}
                viewName="test"
                blocks={[
                    {
                        name: 'keyfigures',
                        title: 'Key Figures (Many)',
                        row: 1,
                        column: 1,
                        defaultVisible: true,
                        render: (blockProps, subitemProps) => <Keyfigures figures={[
                            {
                                key: 'x',
                                type: 'text',
                                title: 'Title',
                                text: 'Value',
                            },
                            {
                                key: 'x2',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: 500.65,
                            },
                            {
                                key: 'x3',
                                type: 'text',
                                title: 'Title',
                                text: 'Value',
                            },
                            {
                                key: 'x4',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: 500.65,
                            },
                            {
                                key: 'x5',
                                type: 'text',
                                title: 'Title',
                                text: 'Hidden',
                                defaultVisible: false,
                            },
                            {
                                key: 'x6',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: -323.65,
                                defaultVisible: false,

                            },
                            {
                                key: 'x7',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: 666.65,
                            },
                            {
                                key: 'x8',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: -323.65,
                                defaultVisible: false,

                            },
                            {
                                key: 'x9',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: 666.65,
                            },
                            {
                                key: 'x10',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: Math.random()* 1000,
                                defaultVisible: false,

                            },
                            {
                                key: 'x11',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()* 1000,
                            },
                            {
                                key: 'x12',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: Math.random()* 1000,
                                defaultVisible: false,

                            },
                            {
                                key: 'x13',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()* 1000,
                            },
                            {
                                key: 'x14',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: Math.random()* 1000,
                                defaultVisible: false,

                            },
                            {
                                key: 'x15',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()* 1000,
                            },
                            {
                                key: 'x16',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()* 1000,
                            },
                            {
                                key: 'x17',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: Math.random()* 1000,
                                defaultVisible: false,

                            },
                            {
                                key: 'x18',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()* 1000,
                            },
                            {
                                key: 'x19',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: Math.random()* 1000,
                                defaultVisible: false,

                            },
                            {
                                key: 'x20',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()* 1000,
                            },
                            {
                                key: 'x22',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()* 1000,
                            },
                            {
                                key: 'x23',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: Math.random()* 1000,
                                defaultVisible: false,

                            },
                            {
                                key: 'x13',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()* 1000,
                            },
                            {
                                key: 'x24',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: Math.random()* 1000,
                                defaultVisible: false,

                            },
                            {
                                key: 'x25',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()*1000
                            },
                            {
                                key: 'x26',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: 666.65,
                            },
                            {
                                key: 'x27',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: -323.65,
                                defaultVisible: false,

                            },
                            {
                                key: 'x28',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: 666.65,
                            },
                            {
                                key: 'x29',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: -323.65,
                                defaultVisible: false,

                            },
                            {
                                key: 'x30',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()* 1000,
                            },
                            {
                                key: 'x32',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()* 1000,
                            },
                            {
                                key: 'x33',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: Math.random()* 1000,
                                defaultVisible: false,

                            },
                            {
                                key: 'x13',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()* 1000,
                            },
                            {
                                key: 'x34',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: Math.random()* 1000,
                                defaultVisible: false,

                            },
                            {
                                key: 'x35',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: Math.random()*1000
                            },
                            {
                                key: 'x36',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: 666.65,
                            },
                            {
                                key: 'x37',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: -323.65,
                                defaultVisible: false,

                            },
                            {
                                key: 'x38',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: 666.65,
                            },
                            {
                                key: 'x39',
                                type: 'currency',
                                title: 'Currency (USD)',
                                currency: 'USD',
                                value: -323.65,
                                defaultVisible: false,

                            },
                        ]} blockProps={blockProps} {...subitemProps} />,
                    },

                    {
                        name: 'keyfigures2',
                        title: 'Test Figures',
                        row: 2,
                        column: 1,
                        defaultVisible: true,
                        render: (blockProps, subitemProps) => <Keyfigures figures={[
                            {
                                key: 'x',
                                type: 'text',
                                title: 'Title',
                                text: 'Value',
                            },
                            {
                                key: 'x2',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: 500.65,
                            },
                        ]} blockProps={blockProps} {...subitemProps} />,
                    },

                    {
                        name: 'keyfigures3',
                        title: 'Test Figures',
                        row: 2,
                        column: 2,
                        defaultVisible: true,
                        render: (blockProps, subitemProps) => <Keyfigures figures={[
                            {
                                key: 'x',
                                type: 'text',
                                title: 'Title',
                                text: 'Value',
                            },
                            {
                                key: 'x2',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: 500.65,
                            },
                        ]} blockProps={blockProps} {...subitemProps} />,
                    },

                    {
                        name: 'keyfigures4',
                        title: 'Test Figures Default hidden',
                        row: 3,
                        column: 1,
                        defaultVisible: false,
                        render: (blockProps, subitemProps) => <Keyfigures figures={[
                            {
                                key: 'x',
                                type: 'text',
                                title: 'Title',
                                text: 'Value',
                            },
                            {
                                key: 'x2',
                                type: 'currency',
                                title: 'Currency (EUR)',
                                currency: 'EUR',
                                value: 500.65,
                                defaultVisible: false,
                            },
                        ]} blockProps={blockProps} {...subitemProps} />,
                    },

                    {
                        name: 'invoicing_overview',
                        title: 'Test External Component (Full row)',
                        row: 4,
                        column: 1,
                        defaultVisible: false,
                        fullRow: true,
                        render: (blockProps) => <BlockBase {...blockProps}>
                            <InvoicingOverView testView />
                        </BlockBase>,
                    }
                ]}
            />
        </div>);
    }
}