import React, { Component } from 'react';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import TaimerComponent from '../../TaimerComponent';

/* material ui */
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

/* other */
import _ from 'lodash';

/* css */
import './ImportReview.css';
import { FormGroup } from '@mui/material';

class ImportReview extends TaimerComponent {

    static contextType = SettingsContext;
    static defaultProps = {
        conflictOptions: {
            skip: true,
            override: false
        },
        noContinue: {
            error: false,
            msg: "",
        }
    }
    constructor(props, context) {
        super(props, context, "dialogs/imports/ImportReview")
    }

    render() {
        const { fileAttributes, errorHeaders, totalHeaders, conflictOptions, buttonLabels, noContinue } = this.props;
        const { tr } = this;
        return (
            <div className="import-review">
                <div className="top section">
                    <div className="totals">
                        {_.map(fileAttributes.totals, (total, i) => {
                            return (
                                <div className={i}>
                                    <h1>{total}</h1>
                                    <h3>{totalHeaders[i]}</h3>
                                </div>
                                )
                            })}
                    </div>
                    {fileAttributes.totals && (fileAttributes.totals.fileErrorTotal > 0 || fileAttributes.totals.taimerTotal > 0 ) ?
                    <div className="content-block">
                        <Table className="error-details">
                            <TableHead className="error-headers">
                                <TableRow>
                                    <TableCell className="left">{tr("File row")}</TableCell>
                                    <TableCell className="right">{tr("Error")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="error-container">
                            {_.map(fileAttributes, (error, i) => {
                                if (i !== "totals") {
                                    if (error.length > 0) {
                                        return (
                                            _.map(error, entry => {
                                                const temp = String(entry).split("&");
                                                const conflictRow = temp[1];
                                                const originalRow = temp[0];
                                                return (        
                                                    <TableRow className="error-message">
                                                        <TableCell className="left">{conflictRow ? conflictRow : originalRow}</TableCell>
                                                        <TableCell className="right">{errorHeaders[i] + " " + (conflictRow ? originalRow : "")}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        )}
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    : undefined}
                </div>
                <div className="bottom section">
                    {fileAttributes.totals && (fileAttributes.totals.fileErrorTotal > 0 || fileAttributes.totals.taimerTotal > 0) && !noContinue.error ?  
                        <FormGroup component="fieldset" className="switch-group">
                            <FormControlLabel
                                value="skip"
                                control={
                                    <Switch name="skip" checked={conflictOptions.skip} onChange={this.props.handleConflict} color="primary" />
                                }
                                label={buttonLabels.skip}
                                labelPlacement="end"
                                />
                            <FormControlLabel
                                value="override"
                                control={
                                    <Switch name="override" checked={conflictOptions.override} onChange={this.props.handleConflict} color="primary" />
                                }
                                label={buttonLabels.override}
                                labelPlacement="end"
                                />             
                        </FormGroup>
                    : <span className="no-continue error">{noContinue.msg}</span>}
                </div>
            </div>
        );
    }
}

export default ImportReview;