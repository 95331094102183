import React from "react";
import List from "./List";
import { makeMap } from "./ListUtils";
import SimpleListRow from "./SimpleListRow";

class SimpleList extends React.Component {
    static defaultProps = {
        className: "",
        data: [],
        fluid: false,
        rowConfiguration: {},
        rowHeight: 48,
        showPageSelector: false,
        virtualized: true,
        virtualization__rowAmount: undefined,
        listProps: {}
    };


    constructor(props) {
        super(props);

        this.passThroughPropNames = [
            "data",
            "fluid",
            "rowHeight",
            "showPageSelector",
            "virtualized",
            "visible"
        ];

        this.determineDefaultRowType              = this.determineDefaultRowType.bind(this);
        this.transformRowConfigurationToListProps = this.transformRowConfigurationToListProps.bind(this);
        this.gatherProps                          = this.gatherProps.bind(this);
    }


    determineDefaultRowType() {
        if(Array.isArray(this.props.rowConfiguration)) {
            return "default";
        }

        if(Object.keys(this.props.rowConfiguration).length === 1) {
            return Object.keys(this.props.rowConfiguration)[0];
        }

        const conf           = this.props.rowConfiguration;
        const keys           = Object.keys(conf);
        const defaultRowType = keys.find(k => conf[k].definesHeader);

        return defaultRowType ? defaultRowType : keys[0];
    }


    transformRowConfigurationToListProps() {
        const defaultRowType = this.determineDefaultRowType();
        const conf           = Array.isArray(this.props.rowConfiguration) 
            ? 
            { default: this.props.rowConfiguration } 
            : 
            this.props.rowConfiguration;

        const keys       = [defaultRowType, ...Object.keys(conf).filter(k => k !== defaultRowType)];
        const listProps  = {
            listRowTypeMap:        {},
            listRowTypeClassNames: {},
            columnHeaderConfigKey: defaultRowType,
            presentation:          {},
            columns:               {}
        };

        keys.forEach(t => {
            listProps.listRowTypeMap[t]        = SimpleListRow;
            listProps.listRowTypeClassNames[t] = conf[t].className || "";
            listProps.columns[t]               = conf[t].cells;
            listProps.presentation[t]          = conf[t].presentation;
        });

        return listProps;
    }


    gatherProps() {
        const gathered = {};

        this.passThroughPropNames.forEach(x => {
            gathered[x] = this.props[x];
        });

        return gathered;
    }


    render() {
        return (
            <List
                {...this.gatherProps()}
                {...this.transformRowConfigurationToListProps()}
                noStateData={true}
                className={this.props.className}
                hideBottomMarker={true}
                {...this.props.listProps}
            />
        );
    }
}

export default SimpleList;
