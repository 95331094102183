import TaimerComponent from '../../TaimerComponent';
import ListCell from "./../ListCell";
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

interface Props {
    value: string;
    tooltip: string;
    textAlign?: "left" | "right";
    width: number;
    name: string;
    hideInfoIcon?: boolean;
    hideOverFlow?: boolean;
}

export default class InfoIconCell extends TaimerComponent<Props> {
    render() {
        const { value, tooltip, width, textAlign, name, hideInfoIcon, hideOverFlow } = this.props;
        const alignment = textAlign || "left";

        return (
            <ListCell
                className={(!value && value?.toString() !== "0") ? 'emptyInfoTooltipCell' : ''}
                editable={false}
                onlyDisplay={true}
                inEditMode={false}
                textAlign={alignment}
                width={width}
                name={name}
            >
                <Tooltip title={tooltip} classes={{ tooltip: 'darkblue-tooltip' }} placement="top" arrow>
                    {alignment == "left" ?
                        <div className="infoTooltipCell alignLeft">
                            <span className={hideOverFlow ? "hideOverFlow" : ""}>{value}</span>
                            {hideInfoIcon 
                                ? <div></div>
                                : <InfoOutlined />
                            }
                        </div>
                    :
                        <div className="infoTooltipCell alignRight">
                            {hideInfoIcon 
                                ? <div></div>
                                : <InfoOutlined />
                            }
                            <span className={hideOverFlow ? "hideOverFlow" : ""}>{value}</span>
                        </div>
                    }

                </Tooltip>
            </ListCell>
        )
    }
}
  