import React, { Component } from 'react';
import DialogBorder from './../../dialogs/DialogBorder';
import DataList from './../../general/DataList';
import DataHandler from './../../general/DataHandler';
import _ from 'lodash';
import TaimerComponent from './../../TaimerComponent';
import { SettingsContext } from './../../SettingsContext';

export default class AddToMailingListDialog extends TaimerComponent {
    constructor(props, context)
	{
        super(props, context, "list/dialogs/RemoveTagsDialog");

        this.removeTags    = this.removeTags.bind(this); 
        this.loadTags      = this.loadTags.bind(this);

        /*
        this.tags  = this.props.tags;
        var tags = []
        var tagsfltr = []
        tagsfltr = _.flatten(_.map(this.props.contacts, 'tags'));

        this.props.tags.forEach(element => {
            if (tagsfltr.indexOf(element.id) > -1){
                debugger;
                tags.push({
                    value: element.id,
                    label: element.name
                })
            }
        });
        */
        this.state = {
            values: [],
        };
    }

    removeTags(){
        let contacts            = _.map(this.props.contacts, 'id');
        let tags           = _.map(this.state.values, 'value');

        DataHandler.post({url: 'remove_tags'}, {contacts,tags, company: this.props.company}).done(() =>  this.props.onClose());
    }

    loadTags(value, {action}){
        this.setState({values: value})
    }

	render() {
        return <DialogBorder className="AddTagsDialog" id="dialog" title={this.tr("Remove tags from selected")} textOk={this.tr("Remove")} onClose={this.props.onClose} onSave={this.removeTags}>
			<div id="container" className="AddTagsDialog">
                <DataList options={this.props.tags} value={this.state.values} isMulti onChange={this.loadTags} />    
			</div>
		</DialogBorder>
	}
}