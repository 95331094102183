import React from "react";
import TaimerComponent from '../../../../TaimerComponent';
import cn from 'classnames';
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { ReactComponent as TaskImage } from '../../../images/type_task.svg';
import { ReactComponent as ProjectTaskImage } from '../../../images/type_project_task.svg';
import { GridRowTask } from "../helpers";
import { TaskType } from "../../../resourcing";

import styles from './GridLeftCell.module.scss';

interface Props {
	item: GridRowTask;
	toggleRowExpand: any;
}

interface State {
}

export default class GridLeftTaskCell extends TaimerComponent<Props, State> {
	constructor(props, context) {
		super(props, context, "resourcing/views/ResourcingGrid/components/GridLeftTaskCell");
	}

	shouldComponentUpdate = oldProps => oldProps.item !== this.props.item;

	iconMap = {
		[TaskType.Normal]: TaskImage,
		[TaskType.Project]: ProjectTaskImage,
	}

	render() {
		const { item, toggleRowExpand } = this.props;
		const { task } = item;

		const Icon = (task && this.iconMap[task.task_type]) || this.iconMap[item.task_type === 'project' ? TaskType.Project : TaskType.Normal];

		return (<div className={cn("grid-table-cell", "grid-table-cell-first", "grid-table-task", !item.editable && "row-not-editable")}>
		<div className="expandOrClose">
			{item.hasSubItems && <div className="expand-icon-container-sub" onClick={() => toggleRowExpand(item)}>
				{item.open ? (
					<Arrow className="expand-icon up" />
				) : (
					<Arrow className="expand-icon down" />
				)}
			</div>}
		</div>
		<div className={styles.TaskImageContainer}>
			<Icon />
		</div>
		<div className={styles.gridRowLeftNames} data-testid="left_task_view">
			<h3 className={styles['grid-type-task']}>{(task && task.description) ?? item.project.text}</h3>
		</div>
	</div>);
	}
}