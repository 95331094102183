
// List
import PropsOnlyListRow from "../../list/PropsOnlyListRow";
import TextInputCell from '../../list/cells/TextInputCell';
import CurrencyListCell from "../../list/CurrencyListCell";
import PercentageListCell from "../../list/PercentageListCell";
import { formatInputNumber } from '../../helpers';

// import styles from "./ImportTasksReviewListRow.module.scss";
import { withSnackbar } from 'notistack';

class ImportQuoteRowsReviewListRow extends PropsOnlyListRow {
	static rowDimensions = {
		height: "44px",
		lineHeight: "44px"
	};

	constructor(props, context) {
		super(props, {}, { childRowType: ImportQuoteRowsReviewListRow }, "dialogs/imports/ImportQuoteRowsReviewListRow");

		this.context = context;
	}

	defineCells() {
		const { data, sharedData: { currency } } = this.props;

		const capitalize = (s) => {
			return (s?.charAt(0).toUpperCase() + s?.slice(1)) || ""
		}


		const cells = {
			row_type:
				<TextInputCell
					value={capitalize(data.row_type)}
					name="description"
					editable={false}
				/>,
			description:
				<TextInputCell
					value={data.description}
					name="description"
					editable={false}
				/>,
            product_code:
				<TextInputCell
					value={data.product_code}
					name="product_code"
					editable={false}
				/>,
            amount:
				<TextInputCell
					listCellProps={{textAlign: "right"}}
					value={data.amount}
					name="amount"
					editable={false}
				/>,
            unit_cost:
				<TextInputCell
					listCellType={data.row_type == "header" ? undefined : CurrencyListCell}
					listCellProps={{currency: currency, textAlign: "right"}}
					value={data.row_type == "header" ? "" : formatInputNumber(data.unit_cost)}
					name="unit_cost"
					editable={false}
				/>,
            selling_price:
				<TextInputCell
					listCellType={data.row_type == "header" ? undefined : CurrencyListCell}
					listCellProps={{currency: currency, textAlign: "right"}}
					value={data.row_type == "header" ? "" : formatInputNumber(data.selling_price)}
					name="selling_price"
					editable={false}
				/>,
            discount_percentage:
				<TextInputCell
					listCellProps={{textAlign: "right"}}
					listCellType={data.row_type == "header" ? undefined : PercentageListCell}
					value={data.row_type == "header" ? "" : formatInputNumber(data.discount_percentage)}
					name="discount_percentage"
					editable={false}
				/>,
            vat_percentage:
				<TextInputCell
					listCellProps={{textAlign: "right"}}
					listCellType={data.row_type == "header" ? undefined : PercentageListCell}
					value={data.row_type == "header" ? "" : formatInputNumber(data.vat_percentage)}
					name="vat_percentage"
					editable={false}
				/>,
            type:
				<TextInputCell
					value={capitalize(data.type)}
					name="type"
					editable={false}
				/>,
		};

		return cells;
	}
}

export default withSnackbar(ImportQuoteRowsReviewListRow);