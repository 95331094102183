import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import SettingsGrid from '../pages/SettingsGrid';
 
/* mui */
import { Button } from '@mui/material';

/* mui icons */
import { ChevronLeft } from '@mui/icons-material';

/* local components */
import OutlinedField from '../../general/OutlinedField';

/* data backend */
import DataHandler from '../../general/DataHandler';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* styles */
import './Maventa.css';

class Maventa extends TaimerComponent {
    static contextType = SettingsContext;
    
    state = {
        username: "",
        password: "",
        use_maventa_targeting: 0,
        purchase_invoice_type: 0
    }
    constructor(props, context) {
        super(props, context, "settings/api-settings/Maventa");
    }
    componentDidMount () {
        super.componentDidMount();
        this.updateCompnentData();
    }
    updateCompnentData = () => {
        const { company } = this.props;
        DataHandler.get({url: `settings/company/${company}/maventa`}).done(resp => this.setState(resp));
    }
    onChange = (evt) => {
        const { name, value } = evt.target;
        const { company } = this.props;
        
        this.setState({[name]: value}, () =>  DataHandler.put({url: `settings/company/${company}/maventa`}, this.state));
    }
    onCheckChange = (update, name, value) => {
        const { company } = this.props;
        
        this.setState({[name]: value}, () =>  DataHandler.put({url: `settings/company/${company}/maventa_settings`}, {[name]: value}));
    }
    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''})
    }
    deActivate = () => {
        const { company } = this.props;
        DataHandler.delete({url: `settings/company/${company}/maventa`}).done(this.back);
    }
    
    render(){
        const { username, password, activated, use_maventa_targeting, purchase_invoice_type} = this.state;        
        const { tr } = this;

        const settings = { use_maventa_targeting: use_maventa_targeting, purchase_invoice_type: purchase_invoice_type };

        return (
            <React.Fragment>
            <div id="integration-maventa" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Maventa Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deActivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("Activate Maventa")}</h4>
                    <p>
                        {tr("If you don't already have Maventa account")}:<br/>
                        {tr("1. Create one in")} <a href="https://secure.maventa.com/registrations">{tr("here")}</a><br/>
                        {tr("2. Copy your UUID from your Maventa account settings")}
                    </p>
                    <OutlinedField name="password" value={password} onChange={this.onChange} label={tr("Maventa api key")} autoComplete="new-password" />
                    <OutlinedField name="username" value={username} onChange={this.onChange} label={tr("Maventa company UUID")} />

                    
                </div>
            </div>

            <div id="settings-maventa" className="main-integration-wrapper">
                <h4>{tr("Contract billing")}</h4>
                <p>{tr("Connect your Maventa bills to contract billing of your customers")}</p>
                <SettingsGrid item={settings} onChange={this.onCheckChange} settings={[{
                    type: "check",
                    name: "use_maventa_targeting",
                    label: tr("Maventa contract billing"),
                    className: "respSetting third"
                }]} />

                <h4>{tr("Fetch bills")}</h4>
                <p>{tr("Fetch bills daily from Maventa to Taimer")}</p>
                <SettingsGrid item={settings} onChange={this.onCheckChange} settings={[{
                    type: "check",
                    name: "purchase_invoice_type",
                    label: tr("Fetch bills from Maventa"),
                    className: "respSetting third"
                }]} />
            </div>
            </React.Fragment>
        )
    }
}

export default Maventa;