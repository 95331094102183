import React, { useState } from "react";
import InsightChart from "../../InsightChart";
import StackedCurrencyChart from "../../StackedCurrencyChart";
import DealsOverviewTable from "./DealsOverviewTable";
import InsightTabs from "../../InsightTabs";
import InsightDropDown from "../../InsightDropDown";

const DealsOverview = props => {
  const { tr, data, months, ...otherProps } = props;

  return (
    <InsightChart
      {...otherProps}
      title={tr("Won Deals Overview")}
      data={{
        sets: [
          {
            label: tr("Revenue Actual"),
            type: "bar",
            barPercentage: 0.7,
            order: 2,
            values: [...data.graph_data.revenue_actual],
            data: data.slider,
            colors: {
              backgroundColor: "#2d9ff7",
              borderColor: "#2d9ff7"
            }
          },
          {
            label: tr("Margin Actual"),
            order: 1,
            values: [...data.graph_data.margin_actual],
            data: data.slider,
            fill: "none",
            type: 'line',
            colors: {
              backgroundColor: "#b891ff",
              borderColor: "#b891ff"
            }
          }
        ],
        labels: months
      }}
      titleOnSlider={true}
      chartType={StackedCurrencyChart}
      tableType={DealsOverviewTable}
      lowOpacityChart={false}
      sliderColumns={["project", "account", "user", "value", "margin"]}
      chartProps={{
        displayLegend: true,
        stacked: false
      }}
      tableProps={{
        datasets: data.table_data
      }}
    />
  );
};

export default DealsOverview;
