import TaimerComponent from '../TaimerComponent';
import styles from './PageNotFound.module.scss';

class PageNotFound extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'general/PageNotFound');
    }
    render() {
        return (
            <div id={styles.notFound}>
                <img src={require('../dashboard/images/ActivitiesGraphic.svg').default} />
                <h3>{this.tr('Nothing to see here!')}</h3>
                <p>{this.tr('A page with this URL could not be found.')}</p>
            </div>
        );
    }
}

export default PageNotFound;
