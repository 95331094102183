import React, { Component } from 'react';
import DialogBorder from './../../dialogs/DialogBorder';
import DataList from './../../general/DataList';
import DataHandler from './../../general/DataHandler';
import _ from 'lodash';
import TaimerComponent from './../../TaimerComponent';
import { SettingsContext } from './../../SettingsContext';

export default class AddToMailingListDialog extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "list/dialogs/RemoveFromMailingListDialog");
        this.removeFromMailingLists = this.removeFromMailingLists.bind(this);
        this.loadMailingLists = this.loadMailingLists.bind(this);
        this.mailingLists = this.props.mailing_lists;

        let mailingLists = []
        let contactsml = []
        contactsml = _.flatten(_.map(this.props.contacts, 'on_mailing_lists'));
        
        this.props.mailing_lists.forEach(element => {
            if (contactsml.indexOf(element.id) > -1)
                mailingLists.push({
                value: element.id,
                label: element.name
            })
        });

        this.state = {
            mailingLists,
            values: [],
        };
    }

    removeFromMailingLists(name, value) {
        const contacts = _.map(this.props.contacts, 'relation_id');
        const maillists = _.map(this.state.values, 'value');

        DataHandler.post({ url: `mail_list_remove` }, { contacts, maillists }).done(() =>  this.props.onClose());
        
    }

    loadMailingLists(value, { action }) {
        this.setState({ values: value })
    }

    render() {
        return <DialogBorder className="RemoveFromMailingListDialog" id="dialog" title={this.tr("Remove selected from mailing list(s)?")} textOk={this.tr("Remove")} onClose={this.props.onClose} onSave={this.removeFromMailingLists}>
            <div id="container" className="AddToMailingListDialog">
                <DataList options={this.state.mailingLists} value={this.state.values} isMulti onChange={this.loadMailingLists} />
            </div>
        </DialogBorder>
    }
}
