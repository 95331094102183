import React from "react";
import classNames from 'classnames';

import { SettingsContext } from './../SettingsContext';

import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import TaimerComponent from "../TaimerComponent";
import VersionContentManager from "./VersionContentManager";

const styles = theme => ({
	button: {
		marginRight: "8px"
	}
});

class UpgradeButton extends TaimerComponent {

	static contextType = SettingsContext;

	constructor(props, context) {
		super(props, context, 'general/UpgradeButton'); 

		this.state = {
			visibility: context.taimerAccount.stripeCustomerId != '' && (context.taimerAccount.trialStatus > 0 || context.versionId < 4 || context.versionId == 10),

		}
	}

	render() {

		const { visibility } = this.state;
		const { classes, updateView, separator } = this.props;
		const { tr } = this;
		const currentVersion = VersionContentManager.getCurrentVersion();

		return (
			<>
			{visibility && 
				<Button color="primary"
					className={`${classNames(classes.button)} upgradeButton`} 
					onMouseDown={() => this.context.addons.new_stripe ? this.context.functions.showUpgradeSlider(currentVersion.id == 10 ? undefined : currentVersion) : updateView({module: 'onboarding', action: 'order', selectedPlan: (this.context.versionId > 1 ? this.context.versionId : 2)})}>
					{this.tr("Upgrade")}
				</Button>}
				{visibility && separator}
			</>
		);
	}	
}


export default withStyles(styles)(UpgradeButton);