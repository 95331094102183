import TaimerComponent from '../TaimerComponent';

interface Props {
    value?: string;
    replacers?: any;
}

export default class TranslateValueRenderer extends TaimerComponent<Props> {
    constructor(props, context) {
        // Same translation namespace as the report view,
        // so that report translations are easily findable.
        super(props, context, "new_reports/NewReportsView");
    }

    render() {
        const { value, replacers } = this.props;

        return this.tr(value, replacers || {});
    }
}