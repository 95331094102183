import React from 'react';
import TaimerComponent from '../../../TaimerComponent';
import cn from 'classnames';
import styles from './ListView.module.scss';
import { ResourcingSubviewProps } from '../../ResourcingView';
import ResourcingList from "./../../../list/lists/ResourcingList";
import { formatDataForList, ResourcingListRow } from './helpers';
import _ from 'lodash';
import { Resource } from '../../resourcing';

type ListViewProps = ResourcingSubviewProps

interface ListViewState {
    tasks: ResourcingListRow[];
    listSettings: {
        page: number;
        perPage: number;
        sort: {
            colName: string;
            asc: boolean;
        };
    },
}

class ListView extends TaimerComponent<ListViewProps, ListViewState> {
    resourcingList: React.RefObject<any>;

    constructor(props: ListViewState, context) {
        super(props, context, "resourcing/views/ListView");

        const { tr } = this;

        this.state = {
            tasks: this._getFormattedTasks(this.props),
            listSettings: {page: 1, perPage: 30, sort: {colName: "startdate", asc: true}},
        };

        this.resourcingList = React.createRef();
    }

    componentDidMount(): void {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: ListViewProps, prevState: ListViewState) {
        let taskUpdated = false;
        let shouldRender = true;

        const fields = [
            'resources',
            'projects',
            'viewOptions',
        ];

        const updateFields = {
            taskGetCount: true,
            ganttDataKey: true,
            totalData: true,
            expandedRowsVersion: true,
            resources: true,
            projects: true,
            viewOptions: true,
        };

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];

            if (!_.isEqual(prevProps[field], this.props[field])) {
                if (updateFields[field]) {
                    taskUpdated = true;
                }

                if (field === "hideColumns") {
                    // this.updateVisibleColumns(this.props.grouping);
                } else {
                    shouldRender = true;
                }
                break;
            }

        }

        if (taskUpdated) {
            this.setState({tasks: this._getFormattedTasks(this.props)});
        }
    }

    _getFormattedTasks = (props: ListViewProps) => {
        const { resources, projects, viewOptions, usersAllowedToShow, autocomplete: { employees } } = props;

        const formattedRows = formatDataForList({
            unassignedLabel: this.tr('Unassigned'),
            employees: _.keyBy(employees, x => x.id),
            viewOptions,
            usersAllowedToShow,
        }, projects, resources);

        return formattedRows;
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
    }

    openTaskDialog = (task: Partial<Resource>) => {
        this.context.functions.addResource(task, {
            onProjectRangeExtended: this.props.onProjectRangeExtended,
        });
    }

    onToolbarExportClick = () => {
        this.resourcingList.current?.onToolbarExportClick?.();
    }

    render() {
        const { checkPrivilege, updateResourcingData, autocomplete, priorities, onProjectRangeExtended } = this.props;
        const { tasks } = this.state;

        return <div className={styles.root}>
            <ResourcingList
                checkPrivilege={checkPrivilege}
                ref={this.resourcingList}
                data={tasks}
                perPage={30}
                updateData={(listRowEdited) => updateResourcingData()}
                autoCompleteData={{
                    ...autocomplete,
                    priorities,
                }}
                openTaskDialog={this.openTaskDialog}
                filters={this.props.filters}
                listSettings={this.state.listSettings}
                updateResourcingData={updateResourcingData}
                onProjectRangeExtended={onProjectRangeExtended}
            />
        </div>
    }
}

export default ListView;