import React from 'react';
import './Efina.css';
import './Maventa.css';
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";
import ListCell from "../../list/ListCell";
import TrashCanIcon from "@mui/icons-material/Delete";
import TextInputCell from "../../list/cells/TextInputCell";
import DataHandler from '../../general/DataHandler';
import MenuItem from '@mui/material/MenuItem';
import { SettingsContext } from '../../SettingsContext';
import AccountingVatCodes from '../components/AccountingVatCodes';
import SettingRow from "../../list/rows/SettingRow";
import List from "../../list/List";
import SettingsGrid from '../pages/SettingsGrid';
import { format } from "date-fns";

class ProductRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            product_id: <TextInputCell
                width={this.props.columnWidthMap['product_id']}
                name="product_id"
                value={this.state.data['product_id']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class MeritAktiva extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/MeritAktiva");

        this.state = {
            username: "",
            password: "",
            realm: "EE",
            accountingProducts: [],
            salesAccounts: [],
            dimensions: [],
            purchase_invoice_type: 0
        };

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.productsList = React.createRef();

        this.versions = [
            {id: "EE", name: "https://aktiva.merit.ee"},
            {id: "FI", name: "https://aktiva.meritaktiva.fi"},
        ];
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getProducts();
        this.getFetchedSalesAccounts();
        this.getFetchedDimensions();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/meritaktiva`}).done(response => {
            this.setState(response);
        });
    }

    onClickSave = () => {
        DataHandler.put({url: `settings/company/${this.props.company}/meritaktiva`}, this.state).done(resp => {
            this.props.enqueueSnackbar(this.tr("Merit Aktiva authentication information saved"), {
                variant: "success",
            });	
            this.updateComponentData();
        });
    }

    getCustomers = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting customers from Merit Aktiva..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `meritaktiva/${this.props.company}/customers`}).done(response => {
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Customer list updated."), {
                variant: "success",
            });
        });
    }

    getProducts = () => {
        DataHandler.get({url: `accounting/products/${this.props.company}/meritaktiva`}).done(response => {
            this.setState(response);
        });
    }

    getDimensions = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Merit Aktiva dimensions..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `meritaktiva/${this.props.company}/dimensions`}).done(response => {
            this.getFetchedDimensions();
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Dimensions fetched successfully!"), {
                variant: "success",
            });    
        }).fail(err => {
            let msg = this.tr("Error in fetching dimensions");
            if (err?.responseJSON?.error == "no_dimensions_found") {
                msg = this.tr("No dimensions found");
            }
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(msg, {
                variant: "error",
            });    
        })
    }


    getAccountingAccounts = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Merit Aktiva accounts..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `meritaktiva/${this.props.company}/accounting_accounts`}).done(response => {
            this.getFetchedSalesAccounts();
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(this.tr("Accounts fetched successfully!"), {
                variant: "success",
            });    
        }).fail(err => {
            let msg = this.tr("Error in fetching accounts");
            if (err?.responseJSON?.error == "no_accounts_found") {
                msg = this.tr("No accounts found");
            }
            this.props.closeSnackbar(key);
            this.props.enqueueSnackbar(msg, {
                variant: "error",
            });    
        })
    }

    getFetchedSalesAccounts = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/meritaktiva/1`}).done(response => {
            this.setState(response);
        });
    }

    getFetchedDimensions = async () => {
        DataHandler.get({url: `accounting/dimensions/${this.props.company}/meritaktiva`, withHeaders: 1}).done(response => {
            this.setState(response);
        });
    }

    changeDefault = (type, id) => {
        const company = this.props.company;
        this.setState({[type]: id}, () => DataHandler.put({url: `accounting/defaults/${company}`}, {type: type, id: id }));
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        DataHandler.delete({url: `settings/company/${this.props.company}/meritaktiva`}).done(this.back);
    }

    onSettingChange = (name, value) => {
        const { company } = this.props;
        
        this.setState({[name]: value}, () => DataHandler.put({url: `settings/company/${company}/meritaktiva_settings`}, {[name]: value}));
    }
    
    render(){
        const {userObject: {dateFormat}} = this.context;
        const { username, password, activated, realm, purchase_invoice_type, create_project_dimensions } = this.state;        
        const { tr } = this;

        const settings = { create_project_dimensions };

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Merit Aktiva Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                <div className="settings">
                    <h4>{tr("Authentication information")}</h4>
                    <OutlinedField select disabled={false} label={tr("Merit Aktiva version")} name={"version"} value={this.state.realm} fullWidth >
                            {this.versions.map(({name, id}) => <MenuItem key={id} value={id} onClick={() => this.setState({realm: id})}>{name}</MenuItem>)}
                    </OutlinedField>
                    <OutlinedField value={username} onChange={(evt) => this.setState({username: evt.target.value})} label={tr("API ID")} />
                    <OutlinedField value={password} onChange={(evt) => this.setState({password: evt.target.value})} label={tr("API Key")} autoComplete="new-password" />
                </div>

                <div className="settings">
                    <Button color="primary" onClick={this.onClickSave} size="large">{tr("Save")}</Button>
                </div>

                <div className="settings">
                    <h4>{tr("VAT codes")}</h4>
                    <AccountingVatCodes 
                        integration={"meritaktiva"}
                        company={this.props.company}
                        showIntegrationID={true}
                        showVatCode={false}
                        showVatAccount={false}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                    />
                </div>

                <div className="settings">                  
                    <h4>{tr("Products")}</h4>
                    <Button className="green" onMouseUp={() => this.productsList.current.addNewRow()} size="large">{tr("Add product")}</Button>
                    <br />
                    <List
                        ref={this.productsList}
                        id="accountingProductsList"
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            //product_id: "",
                        }}
                        listRowType={ProductRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...this.dColConf },
                            { width: 350, name: "name", header: tr("Product name"), ...this.dColConf },
                            //{ width: 200, name: "product_id", header: tr("Product ID"), ...this.dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                data.company = this.props.company;
                                DataHandler.post({url: 'accounting/products/meritaktiva'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            },
                            onUpdate: data => {
                                DataHandler.put({url: 'accounting/products'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            },
                            onDelete: data => {
                                if(data.id == this.state.default_accounting_products_id) {
                                    this.props.enqueueSnackbar(this.tr("Default product cannot be deleted"), {
                                        variant: "error",
                                    });	
                                    return;
                                }
                                data.deleted = "1";
                                DataHandler.delete({url: 'accounting/products'}, { data: JSON.stringify(data) }).done(response => {
                                    this.getProducts();
                                });
                            }
                        }}
                        data={this.state.accountingProducts}
                    />

                    <div className="drop-section">
                        <h4>{tr("Default product")}</h4>
                        <OutlinedField select disabled={false} label={tr("Default")} name={"default"} value={this.state.default_accounting_products_id} fullWidth >
                            <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_products_id", 0)}>{this.tr("No default")}</MenuItem>
                            {this.state.accountingProducts.map(({name, id}) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_products_id", id)}>{name}</MenuItem>)}
                        </OutlinedField>
                    </div>
                </div>

                <div className="settings">
                    <h4>{tr("Get customers")}</h4>
                    <p>{tr("Add new customers and update existing ones from Merit Aktiva to Taimer.")}</p>
                    <Button color="primary" onClick={this.getCustomers} size="large">{tr("Get customers")}</Button>
                </div>
                <div className="settings">
                    <h4>{this.tr('Get Accounting accounts')}</h4>
                    <p>{tr("Add new Accounting accounts and update existing ones from Merit Aktiva to Taimer.")}</p>
                    <Button color="primary" className="dimensions" onClick={this.getAccountingAccounts} size="large">{this.tr('Get Accounting accounts')}</Button>
                    <div className="drop-section">
                        <h4>{tr("Default accounting account")}</h4>
                        <OutlinedField select disabled={false} label={tr("Default")} name={"default"} value={this.state.default_accounting_accounts_id} fullWidth >
                            <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_accounts_id", 0)}>{this.tr("No default")}</MenuItem>
                            {this.state.salesAccounts.map(({account_number, name, id}) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_accounts_id", id)}>{account_number + " " + name}</MenuItem>)}
                        </OutlinedField>
                    </div>
                </div>
                <div className="settings">
                    <h4>{this.tr('Get Dimensions')}</h4>
                    <p>{tr("Add new dimensions and update existing ones from Merit Aktiva to Taimer.")}</p>
                    <Button color="primary" className="dimensions" onClick={this.getDimensions} size="large">{this.tr('Get Dimensions')}</Button>
                    <div className="drop-section">
                        <h4>{tr("Default dimension")}</h4>
                        <OutlinedField select disabled={false} label={tr("Default")} name={"default"} value={this.state.default_accounting_dimensions_id} fullWidth >
                            <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_dimensions_id", 0)}>{this.tr("No default")}</MenuItem>
                            {this.state.dimensions.map(({name, id}) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_dimensions_id", id)}>{name}</MenuItem>)}
                        </OutlinedField>
                    </div>
                </div>
                <div className="settings">
                    <h4>{tr("Create project dimensions to Merit Aktiva")}</h4>
                    <p>{tr("Create project dimension automatically to Merit Aktiva when project is created in Taimer")}</p>
                </div>
                <SettingsGrid item={settings} onChange={(update, name, value) => this.onSettingChange(name, value)} settings={[{
                    type: "check",
                    name: "create_project_dimensions",
                    label: tr("Create project dimensions"),
                    className: "respSetting third"
                }]} />
                <div className="settings">
                    <div className="drop-section">
                        <h4>{tr("Fetch bills from Merit Aktiva")}</h4>
                        <p>{tr("Fetch bills daily from Merit Aktiva to Taimer")}</p>
                        <OutlinedField select disabled={false} label={tr("Fetch bills")} name={"default"} value={purchase_invoice_type} fullWidth >
                            <MenuItem key={0} value={0} onClick={() => this.onSettingChange("purchase_invoice_type", 0)}>{this.tr("Don't fetch")}</MenuItem>
                            <MenuItem key={1} value={1} onClick={() => this.onSettingChange("purchase_invoice_type", 1)}>{this.tr("Fetch all bills")}</MenuItem>
                            <MenuItem key={2} value={2} onClick={() => this.onSettingChange("purchase_invoice_type", 2)}>{this.tr("Fetch only bills with project")}</MenuItem>
                        </OutlinedField>
                    </div>
                </div>
            </div>
        )
    }
}

export default MeritAktiva;