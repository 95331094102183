import { Article, InfoOutlined, Flag, SmartDisplay,OpenInNew } from '@mui/icons-material';
import { Button, MenuItem } from '@mui/material';
import TaimerComponent from '../TaimerComponent';
import ContextMenu from './ContextMenu';
import EmbedVideoOverlay from './EmbedVideoOverlay';
import styles from './TabInfoButton.module.scss';

class TabInfoButton extends TaimerComponent {
    icons = {
        article: <Article />,
        video: <SmartDisplay />,
        flow: <Flag />,
        
    };
    labels = {
        article: this.tr('Read article'),
        video: this.tr('Watch video'),
        flow: this.tr('Take tour'),
    };

    constructor(props, context) {
        super(props, context, 'general/TabInfoButton');
    }

    clickHandler = (method) => {
        const { title, methods } = this.props;
        switch(method) {
            case 'video':
                this.context.functions.setOverlayComponent(<EmbedVideoOverlay title={title} url={methods[method]} />)
                break;
            case 'flow':
                window.Appcues.show(methods[method])
                break;
            default:
            case 'article':
                window.open(methods[method], '_blank')
                break;
        }
        this.context.functions.sendMixpanelEvent('click_how_this_feature_works_action', {
            type: method
        });
    }

    render() {
        const { title, methods } = this.props;
        const methodKeys = (Object.keys(methods) || []).filter(m => !!methods[m]);
        // const hasSingleMethod = methodKeys.length == 1;
        const hasSingleMethod = false;
        return (
            <div className={styles.tabInfoButton}>
                {hasSingleMethod ? (
                    <Button onClick={() => window.open(methods[methodKeys[0]], '_blank')}>
                        {this.icons[methodKeys[0]]}
                        {title}
                    </Button>
                ) : (
                    <ContextMenu
                        label={title}
                        icon={<InfoOutlined className={styles.info} />}
                        buttonProps={{
                            stickyIcon: true,
                            size: 'large',
                        }}
                        className={styles.contextMenu}
                        noExpandIcon={hasSingleMethod}
                        variant="outlined"
                        size="medium"
                        placement={'bottom-end'}
                    >
                        {methodKeys.map((method) => {
                            return (
                                <MenuItem
                                    key={method}
                                    onClick={() => this.clickHandler(method)}
                                >
                                    {this.icons[method]}
                                    <p>{this.labels[method]}</p>
                                    <OpenInNew className={styles.openInNewBtn} style={{ width: '12px', height: '12px'}} />
                                </MenuItem>
                            );
                        })}
                    </ContextMenu>
                )}
            </div>
        );
    }
}

export default TabInfoButton;
