import React, { Component } from 'react';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import TaimerComponent from '../../TaimerComponent';
import ImportQuoteRowsReviewListRow from './ImportQuoteRowsReviewListRow';
import List from '../../list/List';

/* other */
import cn from 'classnames';

/* css */
import styles from './ImportQuoteRowsReview.module.scss';

class ImportQuoteRowsReview extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
        data: [],
    }
    constructor(props, context) {
        super(props, context, "dialogs/imports/ImportQuoteRowsReview");

        const common = {
            width: 200, showMenu: false, resizeable: true, showResizeMarker: false, moveable: false, hideable: false, 
        };

        this.fields = [
            { ...common, field: "row_type", name: "row_type", header: this.tr("Row type"), width: 100 },
            { ...common, field: "description", name: "description", header: this.tr("Description"), width: 200 },
            { ...common, field: "product_code", name: "product_code", header: this.tr("Product code"), width: 60 },
            { ...common, field: "amount", name: "amount", header: this.tr("Amount"), width: 50 },
            { ...common, field: "unit_cost", name: "unit_cost", header: this.tr("Unit cost"), width: 60 },
            { ...common, field: "selling_price", name: "selling_price", header: this.tr("Selling price"), width: 60 },
            { ...common, field: "discount_percentage", name: "discount_percentage", header: this.tr("Discount %"), width: 60 },
            { ...common, field: "vat_percentage", name: "vat_percentage", header: this.tr("Vat %"), width: 60 },
            { ...common, field: "type", name: "type", header: this.tr("Type"), width: 100 },

        ].filter(x => x);
    }

    render() {
        return (
            <div className={styles.root}>
                <div className={cn(styles.top, styles.section)}>
                    <div className={styles.reviewList}>
                    <List
                        virtualized={true}
                        ref={this.list}
                        data={this.props.data}
                        columns={this.fields}
                        height={448}
                        minHeight={448}
                        trimHeight={-10}
                        rowHeight={44}
                        className={styles.list}
                        listRowType={ImportQuoteRowsReviewListRow}
                        noStateData
                        sharedData={{ currency: this.props.currency }}
                    />
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportQuoteRowsReview;