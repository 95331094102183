import React from 'react';
import ReactDOM from 'react-dom';
import './MenuItem.css';
import navicons from './NavIcons';

import TaimerComponent from '../TaimerComponent';
import { ReactComponent as LockIcon } from './NavIcons/lock-filled.svg';

import cn from 'classnames';
import { SettingsContext } from './../SettingsContext';
import Link from '../general/Link';

import styles from './MenuItem.module.scss';
import { KeyboardArrowRight, Star } from '@mui/icons-material';
import { Tooltip, Badge } from '@mui/material';
import { isEqual } from 'lodash';

class MenuItem extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'navigation/MenuItem');

        this.refName = React.createRef();
        this.refItem = React.createRef();
        this.hoverMenu = React.createRef();
        this.menuPlacementTimeout = undefined;

        this.state = {
            nameTooltip: false,
            width: 0,
            top: 0,
            left: 0,
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        window.addEventListener('resize', this.onResize);
        this.setHoverMenuPlacements();
    };

    componentDidUpdate = (oldProps) => {
        if (!isEqual(oldProps.item, this.props.item)) {
            this.setHoverMenuPlacements();
        }
        if (oldProps.tabletMode != this.props.tabletMode) {
            setTimeout(() => {
                this.setHoverMenuPlacements();
            }, 600);
        }
    }

    componentWillUnmount = () => {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.onResize);
    };

    onResize = () => {
        if (this.menuPlacementTimeout) {
            clearTimeout(this.menuPlacementTimeout);
        }
        this.menuPlacementTimeout = setTimeout(() => {
            this.setHoverMenuPlacements();
        }, 500);
    };

    setHoverMenuPlacements = () => {
        if (this.refItem.current) {
            const rect = ReactDOM.findDOMNode(this.refItem.current).getBoundingClientRect();
            let top = rect.top;
            const left = rect.left;
            const menuHeight = (this.props.subitems || []).length * 48;
            if (rect.top + menuHeight > window.innerHeight) {
                top = window.innerHeight - menuHeight - 16;
            }
            this.setState({ top, left, width: rect.width });
        }
    };

    mouseUp(evt) {
        if (!this.props.onMouseUp) return;

        evt.$menuItem = this.props.menuItem;
        this.props.onMouseUp(evt);
    }

    showHover = () => {
        this.setState({ hoverVisible: true });
    };

    hideHover = () => {
        this.setState({ hoverVisible: false });
    };

    onSubItemClick = (event, item, label) => {
        this.hideHover();
        this.props.hideHover && this.props.hideHover();
        this.props.onClick && this.props.onClick(event, item, label);
    };

    isTouchDevice = () => {
        return window.matchMedia('(hover: none)').matches;
    };

    render() {
        const { item, addonanme, openDialog, addon, buy, onClick, subitems, mainItem, allAddons, addonsInfo, navigationNotifications, tabletMode } = this.props;
        const { tr } = this;

        const classList = ['menu-item'];
        if (mainItem) {
            classList.push('main-item');
        }
        if (tabletMode == 'On') {
            classList.push('tablet-mode');
        }
        this.props.state && classList.push(this.props.state);
        this.props.className && classList.push(this.props.className);

        this.props.selected && classList.push('selected');
        const Icon = navicons[this.props.icon] || navicons.default;

        const current = addonanme;

        const trialAction = () =>
            openDialog('trial', {
                name: addonanme,
                addon,
                current,
            });
        const action = item.upgrade ? () => this.context.functions.showUpgradeSlider() :
            (buy && buy.trial_days > 0
                ? trialAction
                : () => {
                      this.context.addons.new_stripe ? this.context.functions.showUpgradeSlider() : this.context.functions.toggleBuyDialog(addonanme);
                  })

        const hasItems = subitems && subitems.length > 0;

        const tooltip =
            buy && buy.can_buy && this.context.versionId < 4 && origin !== 'ingram' && addon && addon.description && !hasItems ? (
                <div className="AddonTooltipInner">
                    <h2>{addon.title} </h2>
                    <p>{buy && buy.limit > 0 && buy.limit == buy.used && addon.limitReached ? addon.limitReached : addon.description}</p>
                    <div className="addonBuyLink">
                        <a href="#" onMouseUp={action}>
                            {buy && buy.trial_days > 0 ? tr('Try Now For Free!') : buy && buy.unlimited ? tr('Buy Now') : tr('Go Unlimited Now!')}
                        </a>
                    </div>
                </div>
            ) : (
                ''
            );

        let allowClick = true;

        if (!addon || (addon && buy && !buy.trial_days > 0)) {
            allowClick = true;
        }

        if (item.action == 'buy-addon' || (tooltip && addon && buy.trial_days > 0)) {
            allowClick = false;
        }

        if (item.upgrade) allowClick = false;

        if (subitems && subitems.length == 0 && !item.showWithEmptyItems) {
            return null;
        }

        if (item.id == "invoices-main" && navigationNotifications?.invoices) {
            item.notification = navigationNotifications.invoices;
        }
        else {
            item.notification = "";
        }
        
        return (
            <div className={styles.menuItem} onMouseLeave={this.hideHover}>
                <Tooltip
                    classes={{
                        tooltip: 'darkblue-tooltip',
                    }}
                    title={tooltip}
                    placement="right-start"
                    interactive
                >
                    <div className={styles.main} ref={this.refItem} onMouseEnter={this.showHover} onTouchStart={this.showHover}>
                        <Link
                            data-testid={this.props['data-testid']}
                            className={classList.join(' ')}
                            url={item.target && this.context.functions.urlify(item.target)}
                            onClickWithCtrl
                            onClick={
                                !allowClick
                                    ? (e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          action(e);
                                      }
                                    : (e) => {
                                          if (!onClick) return;
                                          e.preventDefault();
                                          //   e.stopPropagation();
                                          (!this.isTouchDevice() || !subitems) && onClick(e, item);
                                      }
                            }
                        >
                            {!!this.props.icon && (
                                <div className="icon-container">
                                    <Icon alt={this.props.name} className={(item.id == 'products' || item.id == 'onedrive') ? 'no-icon-fill' : ''} />
                                </div>
                            )}
                            <div className={`name-container ${mainItem && item.notification ? "has-notification" : ""} ${tabletMode == "On" ? "tablet-mode" : ""}`}>
                                <Badge badgeContent={mainItem && item.notification && item.notification} className={mainItem && item.notification ? "notificationBadge" : ""}>
                                    <div className="name">
                                        {this.props.name}
                                    </div>
                                </Badge>
                            </div>
                            {item.isBeta && <span className="beta">Beta</span>}
                            {buy && buy.limit > 0 && (
                                <span className={cn('count', buy.used >= buy.limit && 'limit-reached')} onClick={action}>
                                    {buy.used} / {buy.limit}
                                </span>
                            )}
                            {buy && buy.can_buy && !buy.limit && this.context.versionId < 4 && origin !== 'ingram' && tooltip ? (
                                <div className="lock" onClick={action}>
                                    <LockIcon />
                                </div>
                            ) : undefined}
                            {item.upgrade && !subitems ? (
                                <div className="star">
                                    <Star />
                                </div>
                            ) : undefined}
                            <div className="expand">{hasItems && <KeyboardArrowRight />}</div>
                        </Link>
                    </div>
                </Tooltip>
                {subitems && subitems.length > 0 && this.state.hoverVisible && (
                    <div className={`${styles.hoverMenu} ${this.props.tabletMode == 'On' ? styles.tabletMode : ''}`} style={{ left: this.state.left + this.state.width, top: this.state.top }}>
                        <div className={styles.hoverMenuTitle}>
                            <h3>{this.props.name}</h3>
                        </div>
                        {subitems.map((subitem) => {
                            const items = subitem.subitems || subitem.items;
                            const subgroupAddon = subitem.addonkey && allAddons && allAddons[subitem.addonkey];
                            const subgroupAddonInfo = subitem.addonkey && addonsInfo && addonsInfo[subitem.addonkey];
                            return (
                                <MenuItem
                                    selected={false}
                                    item={subitem}
                                    data-testid={`navigation-sub-${subitem.id}`}
                                    name={subitem.label}
                                    onClick={(e, sub) => this.onSubItemClick(e, sub, item.label)}
                                    hideHover={this.hideHover}
                                    openDialog={openDialog}
                                    subitems={items}
                                    buy={subgroupAddon}
                                    addon={subgroupAddonInfo}
                                    addonanme={subitem.addonkey}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

export default MenuItem;
