import React from 'react';
import PropTypes from "prop-types";

const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const getBytes = (bytes) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed(2) + " " + sufixes[i];
};

function Bytes (props) {
    const {className, children } = props;
    return (<span className={className}>{ getBytes(children) }</span>);
}

Bytes.propTypes = {
  children: PropTypes.number,
};

export default Bytes;