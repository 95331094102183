import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';

import DataHandler from '../../general/DataHandler';

import { Button, Chip } from '@mui/material';
import Select from 'react-select';

import DeleteIcon from '@mui/icons-material/Delete';
import withStyles from '@mui/styles/withStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import './UserManagement.css';
import TextInputCell from '../../list/cells/TextInputCell';
import ProfessionalTitlesMembers from '../components/ProfessionalTitlesMembers';
import SuperiorsMembers from '../components/SuperiorsMembers';
import CorporationGroups from '../components/CorporationGroups';
import Dimensions from '../components/Dimensions';
import Divisions from '../components/Divisions';
import VersionContentManager from '../../general/VersionContentManager';


class UserGroups extends TaimerComponent {
    static contextType = SettingsContext

    constructor(props, context) {
        super(props, context, "settings/pages/UserGroups");

        const tabs = ["userpermissiongroup", "dimensions", "corporation_group", "teams", "professional", "superiors", "divisions"];

        this.state = {
            userGroups: [],
            users: [],
            allUsers: [],
            groups: [],
            selectedTab: this.props.selectedTab && tabs.includes(this.props.selectedTab) ? this.props.selectedTab : "userpermissiongroup"
        }
        this.newRowAdded = false;

        this.context.functions.updateView({selectedTab: this.state.selectedTab});

        this.nameInput = React.createRef();
        this.switchTab = this.switchTab.bind(this);
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.fetchDropDownData();
        this.fetchData();
    }

    componentDidUpdate = (prevProps) => {
        if (this.newRowAdded && this.nameInput.current) {
            this.nameInput.current.listCell.current.openEdit();
            this.newRowAdded = false;
        }

        prevProps.company !== this.props.company && this.fetchData();
    }

    fetchData = (override = {}) => {
        const { userObject, taimerAccount } = this.context;
        const { selectedTab } = this.state;
        if (selectedTab === "professional"  || selectedTab === "superiors") {
            return;
        }
        let parameters = { companyId: this.props.company, type: selectedTab };

        for (let oi in override)
            parameters[oi] = override[oi];

        const queryParams = {
            companyId: parameters.companyId,
            type: parameters.type
        }

        DataHandler.request("GET", { url: "settings/usergroups" }, queryParams).done(response => {

            this.setState({
                userGroups: [...response, ...(queryParams.type == "teams" ? [] : [{
                    id: -1,
                    name: this.tr("Account Team"),
                    note: this.tr("Dynamic group, users will be added to this group automatically when added to the account’s team."),
                    dynamic: true,
                }, {
                    id: -1,
                    name: this.tr("Account Manager"),
                    note: this.tr("Dynamic group, users will be added to this group automatically when added to the account manager."),
                    dynamic: true,
                }, {
                    id: -2,
                    name: this.tr("Project Team"),
                    note: this.tr("Dynamic group, users will be added to this group automatically when added to the project’s team."),
                    dynamic: true,
                }, {
                    id: -2,
                    name: this.tr("Project Manager"),
                    note: this.tr("Dynamic group, users will be added to this group automatically when assignd as project manager."),
                    dynamic: true,
                }, {
                    id: -3,
                    name: taimerAccount.name,
                    note: this.tr("Dynamic group, all company's users will be added to this group automatically."),
                    dynamic: true,
                }])]
            });

            if(selectedTab != 'teams') {
                response.length == 0 && this.addGroup();
            }

        });
        this.fetchDropDownData();

    }

    fetchDropDownData = () => {
        const { company } = this.props;
        const { selectedTab } = this.state;
        let parameters = {
            companyId: company,
            type: selectedTab
        };
        const queryParams = {
            companyId: company,
            type: parameters.type
        }
        DataHandler.get({ url: "subjects/user_groups/" + company, ...queryParams }).done(response => this.setState({ groups: response }));
        DataHandler.get({ url: "subjects/employees/" + company }).done(response => this.setState({ users: response }));
        DataHandler.get({ url: "subjects/employees/" }).done(response => this.setState({ allUsers: response }));
    }

    nameChanged = (groupId, value) => {

        if (value.length <= 0) return;

        const { userGroups, selectedTab } = this.state;
        const { userObject } = this.context;
        const { company } = this.props;

        let index = userGroups.findIndex(e => e.id == groupId);

        userGroups[index].name = value;

        let parameters = {
            companyId: company,
            name: value,
            type: selectedTab
        };

        DataHandler.request("PUT", { url: "settings/usergroups/" + groupId }, parameters).done(response => {
            this.setState({ userGroups });
        });
    }

    userDropChanged = (value, groupId) => {
        const { userGroups, selectedTab } = this.state;
        const { userObject } = this.context;
        const { company } = this.props;

        let parameters = {
            companyId: company,
            type: selectedTab
        };

        let groupIndex = userGroups.findIndex(e => e.id == groupId);

        if (value.hasOwnProperty("type")) {
            if (!userGroups[groupIndex].groups)
                userGroups[groupIndex].groups = [];

            userGroups[groupIndex].groups.push(value.id);

            parameters.groups = userGroups[groupIndex].groups;
        } else {
            if (!userGroups[groupIndex].users)
                userGroups[groupIndex].users = [];

            userGroups[groupIndex].users.push(value.id);

            parameters.users = userGroups[groupIndex].users;
        }

        DataHandler.request("PUT", { url: "settings/usergroups/" + groupId }, parameters).done(response => {
            this.setState({ userGroups });
        });
    }

    addGroup = () => {
        const { userGroups, selectedTab } = this.state;
        const { userObject } = this.context;

        const newGroup = {
            name: this.tr("New Group"),
            id: userGroups.length * -1 - 1,
            type: selectedTab,
            users: [],
            groups: [],
        };

        this.newRowAdded = true;

        let parameters = {
            companyId: this.props.company,
            type: selectedTab,
            data: newGroup
        };

        DataHandler.request("POST", { url: "settings/usergroups" }, parameters).done(response => {
            newGroup.id = response.id;
            userGroups.push(newGroup);
            this.setState({ userGroups });
        });

    }

    removeGroup = (groupId) => {
        const { userGroups, selectedTab } = this.state;
        const { userObject } = this.context;
        const { company } = this.props;

        userGroups.splice(userGroups.findIndex(e => e.id == groupId), 1);

        let parameters = {
            companyId: company,
            type: selectedTab
        };

        DataHandler.request("DELETE", { url: "settings/usergroups/" + groupId }, parameters).done(response => {
            this.setState({ userGroups });
        });
    }

    removeUser = (userId, groupId) => {
        const { userGroups, selectedTab } = this.state;
        const { userObject } = this.context;
        const { company } = this.props;

        let groupIndex = userGroups.findIndex(e => e.id == groupId);

        userGroups[groupIndex].users.splice(userGroups[groupIndex].users.findIndex(e => e == userId), 1);

        let parameters = {
            companyId: company,
            type: selectedTab,
            users: userGroups[groupIndex].users.length > 0 ? userGroups[groupIndex].users : "empty"
        };


        DataHandler.request("PUT", { url: "settings/usergroups/" + groupId }, parameters).done(response => {
            this.setState({ userGroups });
        });
    }

    removeSubGroup = (subGroupId, groupId) => {
        const { userGroups, selectedTab } = this.state;
        const { userObject } = this.context;
        const { company } = this.props;

        let groupIndex = userGroups.findIndex(e => e.id == groupId);

        userGroups[groupIndex].groups.splice(userGroups[groupIndex].groups.findIndex(e => e == subGroupId), 1);

        let parameters = {
            companyId: company,
            type: selectedTab,
            groups: userGroups[groupIndex].groups.length > 0 ? userGroups[groupIndex].groups : "empty"
        };


        DataHandler.request("PUT", { url: "settings/usergroups/" + groupId }, parameters).done(response => {
            this.setState({ userGroups });
        });

        this.setState({ userGroups });
    }

    generateOptions = (row, includeGroups = true) => {
        const {users, groups } = this.state;

        if (!row.users)
            row.users = [];
        if (includeGroups && !row.groups) 
            row.groups = [];

        const filteredUsers = users.filter(e => row.users.find(ee => ee === e.id) === undefined);
        let usersAndGroups;
        if(includeGroups) {
            const filteredGroups = groups.filter(e => row.groups.find(ee => ee === e.id) === undefined);
            usersAndGroups = [...filteredUsers, ...filteredGroups];
        } else {
            usersAndGroups = [...filteredUsers];
        }

        return usersAndGroups.map(el => {
            el['label'] = el.label ? el.label : (el.name || "");
            return el;
        });
    }

    switchTab(tabName) {
        this.context.functions.updateView({ selectedTab: tabName });
        this.setState({ selectedTab: tabName }, this.fetchData);

    }

    createTab(name, label) {
        const StyledTab = withStyles({
            label: {
                fontSize: "14px",
                letterSpacing: "1px"
            },
            selected: {
				color: "#003A78 !important",
			}
        })(Tab);

        return (
            <StyledTab 
                className={`settings-styled-tab ${this.state.selectedTab !== name ? "not-selected" : ""}`} 
                value={name}
                label={this.tr(label)} 
                href={this.context.functions.urlify({...this.props.viewProps, selectedTab: name})} 
                onClick={(evt) => !evt.ctrlKey && !evt.metaKey && evt.preventDefault()} 
            />
        )
    }

    render = () => {
        const usesDimensions = this.context.addons?.dimensions?.used_by_companies?.indexOf(this.props.company) > -1;
        const usesDivisions = this.context.addons?.division;
        const { userGroups, users, allUsers, groups } = this.state;
        const { tr } = this;
        const StyledTabs = withStyles({
            indicator: {
                display: 'none',
                "&.with-indicator": {
                    display: 'block',
                    backgroundColor: "#003A78",
                    height: "3px",
                },
                "&.rounded-indicator": {
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                },
            }
        })(Tabs);

        return (
            <React.Fragment>
                <div id="settings-usergroups-navigation">
                <StyledTabs
                    classes={{indicator: 'with-indicator rounded-indicator'}}
                    value={this.state.selectedTab}
                    onChange={(evt, tab) => !evt.ctrlKey && !evt.metaKey && this.switchTab(tab)}
                    variant="standard">
                        {this.createTab("userpermissiongroup", "User permission groups")}
                        {usesDimensions ? this.createTab("dimensions", "Dimensions") : null}
                        {!VersionContentManager.isFeatureHidden(this.namespace, 'corporationGroups') && this.createTab("corporation_group", "Corporation Groups")}
                        {!VersionContentManager.isFeatureHidden(this.namespace, 'teams') && this.createTab("teams", "Teams")}
                        {!VersionContentManager.isFeatureHidden(this.namespace, 'professionalTitles') && this.createTab("professional", "Professional Titles")}
                        {!VersionContentManager.isFeatureHidden(this.namespace, 'superiors') && this.createTab("superiors", "Superiors")} 
                        {usesDivisions ? this.createTab("divisions", "Divisions") : null}
                </StyledTabs>
                <hr />  
                </div>
                <div style={{ height: this.state.selectedTab === "dimensions" ? 0 : 42, width: "100%" }}></div>
    
            {this.state.selectedTab === "userpermissiongroup" && <React.Fragment>
            <div id="usergroup-settings">
                <h3>{tr("User permission groups")}</h3>

                <Button className="green btn add" size="large" data-testid="add-permission-group" onClick={() => {this.addGroup()}}>{tr("Add permission group")}</Button>

                <div className="user-count">
                    {userGroups.length} {this.tr('groups')}
                </div>

                <div id="usergroup-wrapper">
                    {userGroups.length > 0 && <div className="header">
                        <div className="column delete"></div>
                        <div className="column group">{tr("User permission group")}</div>
                        <div className="column users">{tr("Group members")}</div>
                    </div>}
                    <div className="content">
                        {userGroups.map(row => ( 
                            <div className="row">
                                <div className="column delete">
                                    {!row.dynamic && <DeleteIcon 
                                        fontSize="small"
                                        onClick={() => this.removeGroup(row.id)}
                                    />}
                                </div>
                                <div className="column group">
                                    <TextInputCell
                                        ref={this.nameInput}
                                        name="name"
                                        value={row.name}
                                        editable={!row.dynamic}
                                        type={row.type}
                                        onEdited={(name, value) => {
                                            this.nameChanged(row.id, value);
                                            
                                        }}
                                        
                                        />
                                </div>
                                {!row.dynamic && <div className="column users">
                                    {row.groups && row.groups.map(g => {
                                        let found = groups.find(elem => {return elem.id == g});
                                        if (found)
                                            return <Chip id={"g_"+found.id} key={"g_"+found.id} label={found.name} className="square" onDelete={() => this.removeSubGroup(found.id, row.id)} />
                                    })}

                                    {row.users && row.users.map(u => {
                                        let found = users.find(elem => {return elem.id == u});
                                        if (found)
                                            return <Chip id={"g_"+found.id} key={"g_"+found.id} label={found.label} className="square" onDelete={() => this.removeUser(found.id, row.id)} />
                                    })}

                                    <Select
                                        onChange={(value) => {
                                            this.userDropChanged(value, row.id)   
                                        }}
                                        value={null}
                                        isSearchable={true}
                                        isMulti={false}
                                        placeholder={this.tr("Select...")}
                                        options={this.generateOptions(row)}
                                        className="drop"
                                    />
                                </div>}
                                {row.dynamic && <div className="column users">
                                    {row.note}
                                </div>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            </React.Fragment>}
            {this.state.selectedTab === "teams" && <React.Fragment>
            <div id="teamgroup-settings">
                <h3>{tr("Teams")}</h3>

                <Button className="green btn add" size="large" onClick={() => {this.addGroup()}}>{tr("Add team")}</Button>

                <div className="user-count">
                    {userGroups.length} {this.tr('groups')}
                </div>

                <div id="teamgroup-wrapper">
                    {userGroups.length > 0 && <div className="header">
                        <div className="column delete"></div>
                        <div className="column group">{tr("Team group")}</div>
                        <div className="column users">{tr("Group members")}</div>
                    </div>}
                    <div className="content">
                        {userGroups.map(row => ( 
                            <div className="row">
                                <div className="column delete">
                                    {!row.dynamic && <DeleteIcon 
                                        fontSize="small"
                                        onClick={() => this.removeGroup(row.id)}
                                    />}
                                </div>
                                <div className="column group">
                                    <TextInputCell
                                        ref={this.nameInput}
                                        name="name"
                                        value={row.name}
                                        type={row.type}
                                        onEdited={(name, value) => {
                                            this.nameChanged(row.id, value);
                                        }}
                                        />
                                </div>
                                {!row.dynamic && <div className="column users">

                                    {row.users && row.users.map(u => {
                                        let found = users.find(elem => {return elem.id == u});
                                        if (found)
                                            return <Chip id={"g_"+found.id} key={"g_"+found.id} label={found.label} className="square" onDelete={() => this.removeUser(found.id, row.id)} />
                                    })}

                                    <Select
                                        onChange={(value) => {
                                            this.userDropChanged(value, row.id)   
                                        }}
                                        value={null}
                                        isSearchable={true}
                                        isMulti={false}
                                        placeholder={this.tr("Select...")}
                                        options={this.generateOptions(row, false)}
                                        className="drop"
                                    />
                                </div>}
                                {row.dynamic && <div className="column users">
                                    {row.note}
                                </div>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
                </React.Fragment>}
                {this.state.selectedTab === "professional" && <ProfessionalTitlesMembers company={this.props.company} users={users} />}
                {this.state.selectedTab === "corporation_group" && <CorporationGroups users={allUsers} />}
                {this.state.selectedTab === "superiors" && <SuperiorsMembers company={this.props.company} users={users} />}
                {this.state.selectedTab === "dimensions" && <Dimensions company={this.props.company} viewProps={this.props.viewProps} />}
                {this.state.selectedTab === "divisions" && <Divisions company={this.props.company} viewProps={this.props.viewProps} />}
            </React.Fragment>
        );
    }
}

UserGroups.defaultProps = {

}

export default UserGroups;
