import TaimerComponent from '../TaimerComponent';

interface Props {
    valueFormatted?: string;
    showEmptyAggSum?: boolean;
}

export default class FormattedValueCellRenderer extends TaimerComponent<Props> {
    render() {
        const { valueFormatted, showEmptyAggSum } = this.props;

        const value: string = valueFormatted && !showEmptyAggSum 
            ? valueFormatted 
            : "";

        return value
    }
}