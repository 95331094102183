import moment from "moment";

interface DateStringSpan {
    start: string | undefined;
    end: string | undefined;
}

interface DateSpan {
    start: Date | undefined;
    end: Date | undefined;
}

function getDateSeparator(date: string): string {
    if(date.indexOf(".") > -1) {
        return ".";
    }

    if(date.indexOf("-") > -1) {
        return "-";
    }

    if(date.indexOf("/") > -1) {
        return "/"; 
    }

    return " ";
}

// TODO: Rename.
function divideDateTimeRangeIntoDays(startDateTime: string, endDateTime: string): string[] {
    const format = "YYYY-MM-DD HH:mm";
    const start  = moment(createDateObject(startDateTime));
    const end    = moment(createDateObject(endDateTime));

    if(!start.isValid() || !end.isValid()) {
        return [];
    }
  
    const fStart = start.format(format);
    const fEnd   = end.format(format);

    if(fStart === fEnd) {
        return [];
    }

    if(getDateSeparator(startDateTime) !== getDateSeparator(endDateTime)) {
        throw new Error("The passed date times are of a different format.");
    }

    const startTime = fStart < fEnd ? fStart : fEnd;
    const endTime   = fStart < fEnd ? fEnd : fStart;
    const date      = moment(createDateObject(startTime));
    const endDate   = moment(createDateObject(endTime));

    const dates: string[] = [date.format(format)];

    // Won't work correctly if the dates
    // are of a different format.
    while(dates[dates.length - 1] < endTime) {
        date.add(1, 'day');

        dates.push(date.format(format)); 
    }

    dates.pop();
    dates.push(endDate.format(format));

    return dates;
}

// NOTE: Browser-dependent so just don't use it
function isValidDateString(date: string): boolean {
    return !isNaN(Date.parse(date));
}

function is12HourTime(time: string): boolean {
    return (/^(0?[1-9]|1[0-2])[:.]*([0-5][0-9])*([:.][0-5][0-9])* ?(am|pm)$/i).test(time);
}

function is24HourTime(time: string): boolean {
    return (/^([01]\d|2[0-3]):[0-5]\d(:[0-5]\d)*$/).test(time);
}

function isAMTime(time: string): boolean {
    return is12HourTime(time)
        && time.toLowerCase().indexOf("am") > -1;
}

function isPMTime(time: string): boolean {
    return is12HourTime(time)
        && time.toLowerCase().indexOf("pm") > -1;
}

function to12HourTime(time: string, includeAmPm = true): string {
    if(!is24HourTime(time)) {
        return time;
    }
    
    const sep: string         = time.indexOf(":") > -1 ? ":" : ".";
    const parts: string[]     = time.split(sep);
    const timeParts: number[] = parts.map((s: string) => parseInt(s));
    
    const hours: number   = timeParts[0];
    const minutes: number = timeParts.length > 1 
        ? timeParts[1] 
        : 0;
    const seconds: number | boolean = timeParts.length > 2
        ? timeParts[2]
        : false;

    const isAm: boolean = 0 <= hours && hours < 12;
    let nHours: number;

    nHours = hours === 0 ? hours + 12 : hours;
    nHours = hours > 12 ? hours - 12 : nHours;

    time = [
        String(nHours).padStart(2, '0'),
        String(minutes).padStart(2, '0'),
        seconds,
    ].filter(p => p).join(":");

    const amPm = includeAmPm
        ? ` ${isAm ? "AM" : "PM"}`
        : "";

    return `${time}${amPm}`;
}

function to24HourTime(time: string): string {
    if(!is12HourTime(time)) {
        return time;
    }

    time                  = time.trim();
    const sep: string     = time.indexOf(":") > -1 ? ":" : ".";
    const parts: string[] = time
        .toLowerCase()
        .split(" ")
        .map((part: string) => part.trim());

    const isPm: boolean = parts[parts.length - 1] === "pm";

    const timeParts: string[] = parts[0]
        .split(sep)
        .map((p: string) => p.trim());

    let hours: number     = parseInt(timeParts[0]);
    const minutes: number = timeParts.length > 1 
        ? parseInt(timeParts[1])
        : 0;
    const hasSeconds: boolean = timeParts.length > 2
        && timeParts[2].trim() !== "";

    let add = 0;

    if(isPm && hours !== 12) {
        add = 12;
    }

    if(!isPm && hours === 12) {
        add = -12;
    }

    hours += add;

    return [
        String(hours).padStart(2, '0'),
        !isNaN(minutes) 
            ? String(minutes).padStart(2, '0') 
            : "00",
        hasSeconds ? timeParts[2].trim() : false
    ]
    .filter(p => p)
    .join(":");
}

function dateTimeTo24HourDateTime(dateTime: string): string {
    const parts: string[] = dateTime
        .split(/ (.*)/s)
        .map((s: string) => s.trim())
        .filter((s: string) => s !== "");

    return (parts.length < 2 || (parts.length > 1 && is24HourTime(parts[1])))
        ? dateTime
        : `${parts[0]} ${to24HourTime(parts[1])}`;
}

function dateTimeTo12HourDateTime(dateTime: string): string {
    const parts: string[] = dateTime
        .split(/ (.*)/s, 2)
        .map((s: string) => s.trim())
        .filter((s: string) => s !== "");

    return (parts.length < 2 || (parts.length > 1 && is12HourTime(parts[1])))
        ? dateTime
        : `${parts[0]} ${to12HourTime(parts[1])}`;
}

function isSomeSortOfDateTimeOrDateString(str: string): boolean {
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}[ T](\d{2}:\d{2}(:\d{2})?(\.\d+)?( ?[AP]M)?|(\d{2}:\d{2}(\.\d+)?( ?[AP]M)))$/;
    const dateRegex     = /^\d{4}-\d{2}-\d{2}$/;

    return dateTimeRegex.test(str) || dateRegex.test(str);
}

// Safari fix for expenses.
function createDateObject(dateTime: string): Date {
    if(!isSomeSortOfDateTimeOrDateString(dateTime)) {
        return new Date();
    }

    const d = new Date(dateTime);

    if(!isNaN(d.getTime())) {
        return d;
    }

    return new Date(tryToStandardizeDateTimeString(dateTime));
    
}

function tryToStandardizeDateTimeString(dateTime: string): string {
    if(!isSomeSortOfDateTimeOrDateString(dateTime)) {
        throw new Error("This is not a date time string!");
    }

    const found: string | undefined = [' ', 'T']
        .find((char: string) => dateTime.charAt(10) === char);

    if(!found) {
        return `${dateTime}T00:00:00`;
    }

    const [
        date, 
        ...timeParts
    ] = dateTime.split(found);

    let time = timeParts.join(found);


    if(is12HourTime(time)) {
        time = to24HourTime(time);
    }

    if(time.split(":").length === 2) {
        time = `${time}:00`;
    }

    return `${date}T${time}`;
}

/**
 * Compares two dateTimes. 
 * @param string dateTime1
 * @param string dateTime2
 * @return int -1 if dateTime1 > dateTime2, 1 if dateTime2 > dateTime1, 0 if dateTimes are same.
 */
function compareDateTimes(dateTime1: string, dateTime2: string): number {
    const time1 = moment(dateTime1);
    const time2 = moment(dateTime2);

    if (time1.isBefore(time2)) {
        return -1;
    }
    else if (time2.isBefore(time1)) {
        return 1;
    }
    return 0;
}

function timestamp(): number {
    return (new Date()).getTime();
}

export {
    divideDateTimeRangeIntoDays,
    isValidDateString,
    is12HourTime,
    is24HourTime,
    to12HourTime,
    to24HourTime,
    isAMTime,
    isPMTime,
    dateTimeTo24HourDateTime,
    dateTimeTo12HourDateTime,
    compareDateTimes,
    timestamp,
    isSomeSortOfDateTimeOrDateString,
    tryToStandardizeDateTimeString,
    createDateObject,
    type DateSpan,
    type DateStringSpan,
};
