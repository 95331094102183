/* css */
import './TabQuotes.css';

/* material-ui */
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import { MenuItem } from '@mui/material';
import ThreeDotsIcon from '@mui/icons-material/MoreHoriz';
import MoveIcon from '@mui/icons-material/UnfoldMore';
import Popover from '@mui/material/Popover';
import MUIList from '@mui/material/List';
import MUIListItem from '@mui/material/ListItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Checkbox from '@mui/material/Checkbox';
import { Tooltip } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

/* others */
import React from 'react';
import List from '../list/List';
import Paper from "../general/Paper";
import { Popper } from '@mui/material';
import PropsOnlyListRow from '../list/PropsOnlyListRow';
import ListRow from '../list/ListRow';
import ListCell from '../list/ListCell';
import navicons from '../navigation/NavIcons';
import ContextMenu from '../general/ContextMenu';
import DataHandler from '../general/DataHandler';
import TextInputCell from '../list/cells/TextInputCell';
import TextAreaCell from '../list/cells/TextAreaCell';
import CreatableSelect from "react-select/creatable";
import LabelFieldGroup from "../general/LabelFieldGroup";
import TaimerComponent from "../TaimerComponent";
import AutoCompleteCell from '../list/cells/AutoCompleteCell';
import CurrencyListCell from "../list/CurrencyListCell";
import { SettingsContext } from './../SettingsContext';
import { FlexChild, FlexContainer } from "../general/FlexUtils";
import Select from "react-select";
import DatePicker from "./../general/react-date-range/src/components/DatePicker";
import cloneDeep from "lodash/cloneDeep";
import clone from "lodash/clone";
import isEqual from "lodash/isEqual";
import Utils from "./../general/Utils";
import { makeMapOfPrimitives } from "../list/ListUtils";
import { formatInputNumber } from '../helpers';
import Logo from "../invoices/Logo";
import InfoSelect from '../list/cells/InfoSelect';
import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';
import OutlinedField from '../general/OutlinedField';

const ResourcingIcon = navicons.resourcing;

function Header(props) {
    const Wrapper = props.noFlex !== true ? FlexContainer : "div";
    return <Wrapper className="header">{props.children}</Wrapper>;
}

function ContentArea(props) {
    return <div className="content">{props.children}</div>;
}

function Footer(props) {
    const Wrapper = props.noFlex !== true ? FlexContainer : "div";
    return <Wrapper className="footer">{props.children}</Wrapper>;
}

class Totals extends ListRow {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props);
        this.context = context;
    }
    
    render() {
        const { className = "" } = this.props;
        const numberFormat = new Intl.NumberFormat(this.context.taimerAccount.numberFormat, { style: 'currency', currency: this.props.currency });
        return (
            <div className={`sum-info ${className}`}>
                {this.props.rows.map(row => {
                    return (<div className={`row ${row.grey ? "grey" : row.black ? "black" : row.greyvat ? "greyvat" : ""}`}>
                        <div className="name">{row.name}</div>
                        <div className="sum">{numberFormat.format(row.sum)}</div>
                    </div>);
                })}
            </div>
        );
    }
}


class QuoteRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, {}, "list/rows/QuoteRow");

        this.workTypes = [
            { name: this.tr("Hours"),            id: 1 },
            { name: this.tr("Sub-contracting"),  id: 2 },
            { name: this.tr("Services"),         id: 3 },
            { name: this.tr("Products"),         id: 4 },
            { name: this.tr("Expenses"),         id: 5 },
        ];

        this.quantityTypes = [
            { id: 1, name: this.props.rowProps.getTranslation("qty") },
            { id: 2, name: this.props.rowProps.getTranslation("h") }
        ];
    }


    defineClassName() {
        if (this.props.data.hidden_for_print > 0)   
            return "quote-listrow quoteRow hidePrint";
        else 
            return "quote-listrow quoteRow";
    }


    defineCells() {
        const { data, rowProps, columnConfig } = this.props;
        const { taimerAccount }        = rowProps.context;
        const { CPQParents, editMode, canCreatePurchaseOrder } = rowProps;

        const textInputCellProps = { 
            useClickAwayListener: true, 
            runOnEditedOnInput: false 
        };

        const listCellEditModeProps = {
            editable: rowProps.editMode,
            inEditMode: rowProps.editMode,
            noInitFocus: true,
            disableInitialFocus: true,
        };

        const { 
            units,  
            hidden,  
            rowDataChanged,  
            quoteId,  
            headerId,
            products,
            nondeletedProducts,
            allProductNames
        } = rowProps;

        const calcValue        = parseInt(data.discountPercentage) > 0 ? data.value - parseInt(data.discountPercentage) / 100 * data.value : data.value;
        const sum              = data.quantity * calcValue;
        const vatTotal         = data.vat / 100 * (calcValue * data.quantity);
        const margin           = sum - data.quantity * data.cost;
        const marginPercentage = margin / sum;
        const workType         = Number(data.workType);
        const type             = Number(data.type);
        let rowText = "";
        if((type === 3 || (data.id < 0 && type === 2))) {
            let product = products.find(el => el.id == data.product_id);
            if (!product) {
                //if product is not found get name from all product names
                product = allProductNames.find(el => el.id == data.product_id);
            }
            if(product) {
                rowText = product.name;
            }
        } else if(type == 4) {
            let parent = CPQParents.find(el => el.id == data.product_id);
            if(parent) {
                rowText = parent.name;
            }
        } else {
            rowText = data.name;
        }

        return {
            context:
                <ListCell permanentEditMode={true} noBorder={true}>
                    <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell row-menu" noExpandIcon popperProps={{disablePortal: false}}>
                    {!editMode ? (
                        <> 
                        {canCreatePurchaseOrder && <MenuItem className="menuItem" onClick={() => this.props.rowProps.createPurchaseOrder(this.props.data)}><AddIcon className="menuIcon" />{this.tr('Add purchase order')}</MenuItem>}
                        <MenuItem 
                            className="menuItem"
                            disabled={data.has_task > 0 || !this.props.rowProps.canCreateTask}
                            onClick={() => {data.has_task < 1 && this.props.rowProps.canCreateTask && rowProps.createTask(data.id, data.quantity, rowText)}}>
                                <ResourcingIcon alt="Resourincg" className="menuIcon show" />{data.has_task < 1 ? this.tr('Create a task for this row') : this.tr('Task has been linked to this row')}
                        </MenuItem>
                        </>
                    ) : (
                        <>
                        <MenuItem className={"menuItem" + (data.has_task > 0 ? ' has-task' : '')} onClick={() => this.setData("hidden_for_print", this.props.data.hidden_for_print == 1 ? 0 : 1)}><EyeIcon className="menuIcon show" />{this.tr('Show/Hide from print')}</MenuItem>
                        <MenuItem  className="delete" onClick={() => this.props.rowProps.markRowDeleted(this.props.data.id)}><RemoveIcon className="menuIcon delete"/>{this.tr('Delete')}</MenuItem>
                        {/*<MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.data.id, _type: "row" })}>{this.tr('Add child')}</MenuItem>*/}
                        </>
                    )}
                    </ContextMenu>
                </ListCell>,
            createTask:
                !editMode 
                ? 
                <ListCell
                    editable={false}
                    onlyDisplay={true}
                    noBorder={true}
                    className="resourcing-cell hide-in-print"
                    title={data.has_task > 0 ? this.tr('Task has been linked to this row') : (this.props.rowProps.canCreateTask ? this.tr('Create a task for this row') : '')}>
                    <ResourcingIcon alt="Resourincg" className={"resourcing-icon" + (data.has_task > 0 ? ' has-task' : '') + (this.props.rowProps.canCreateTask ? '' : ' not-createable')} onClick={() => {this.props.rowProps.canCreateTask && data.has_task < 1 && rowProps.createTask(data.id, data.quantity, rowText)}} />
                </ListCell> 
                : 
                <ListCell
                    // className="move-cell"
                    style={{
                        // maxWidth: columnWidthMap['move'] + "px"
                    }}
                    noBorder={true}
                    permanentEditMode={true}>
                        <div className={!editMode ? "transparent" : ""} style={{ cursor: "grab" }} onMouseDown={this.startDrag}>
                            <MoveIcon />
                        </div>
                </ListCell>,
            name:
                /*
                 * types:
                 * 1: normal quote row (text)
                 * 2: description row
                 * 3: product row
                 * 4: cpq row
                 * 5: ???
                 *
                 */


                // If this row is a product row on the "main level" (outside CPQ) or it's a cpq product row as a child of a main level row,
                // show products and use the cpq product row's type_id
                //
                // This could've been written better, but lack of time and all the usual excuses.
                // (data["_type"] === "productRow" || (data["_type"] === "cpqRow" && (type == 2 || data.id > 0 && type == 4))) && workType === 4 ?
            
                // workType has no bearing over wether the row can have a product or not.  
          
                (data.id < 0 && type === 2) ? 
                <AutoCompleteCell
                    // {...editModeProps} // TODO
                    className="onQuoteRow"
                    name="name"
                    allowCreate={false}
                    openMenuOnFocus={false}
                    noOptionsMessage={() => this.tr("No options")}
                    value={data.product_id}
                    autoCompleteData={products}
                    searchable={true}
                    menuPortalTarget={document.body}
                    listCellProps={{...listCellEditModeProps, forceToolTip: true, showTooltipForOverflownText:true, className: columnConfig.name.className }}
                    onEdited={value => {
                        this.setData(data => {
                            const key = data["_type"] === "productRow" || data["_type"] === "cpqRow" && type == 4 ?  "product_id" : "type_id";
                            data[key] = value.id;

                            data = {
                                ...data,
                                ...{
                                    cost: value.cost_price,
                                    value: value.income_price,
                                    vat: value.vat,
                                    quantityType: value.unit,
                                    quantity: data.quantity == 0.00 ? 1 : data.quantity
                                }
                            };

                            return data;
                        });
                    }}
                /> : type === 3 ?
                <ListCell editable={false} onlyDisplay={true}>
                    <InfoSelect
                        height={230}
                        tableWidth={1000}
                        headerHeight={35}
                        rowHeight={49}
                        className={columnConfig.name.className + " InfoSelect__infoselect_product infoSelect__hideChildBorder"}
                        placeholder={rowText ? rowText : this.tr("Product")}
                        editable={rowProps.editMode}
                        noOptionsMessage={this.tr("No options")}
                        inEditMode={rowProps.editMode}
                        options={nondeletedProducts}
                        columns={[
                            { name: "code", header: this.tr("Code"), width: 75 },
                            { name: "name", header: this.tr("Name"), width: 500 },
                            { name: "path", header: this.tr("Category"), width: 350 },
                            { name: "unit", header: this.tr("Unit"), width: 75 },
                        ]}
                        value={rowText}
                        onChange={value => {
                            this.setData(data => {
                                const key = data["_type"] === "productRow" || data["_type"] === "cpqRow" && type == 4 ?  "product_id" : "type_id";
                                data[key] = value.id;

                                data = {
                                    ...data,
                                    ...{
                                        cost: value.cost_price,
                                        value: value.income_price,
                                        vat: value.vat,
                                        quantityType: value.unit,
                                        quantity: data.quantity == 0.00 ? 1 : data.quantity,
                                        discountPercentage: value.discount_percent || 0
                                    }
                                };

                                return data;
                            });
                        }}
                    /> 
                </ListCell>
                    : (type === 4) ?
                <AutoCompleteCell
                    // {...editModeProps} // TODO
                    className="onQuoteRow"
                    // style={{width: columnWidthMap['name'] + 'px'}}
                    name="name"
                    selectProps={{
                        noOptionsMessage: () => this.tr("No option"),
                    }}
                    allowCreate={false}
                    openMenuOnFocus={false}
                    noOptionsMessage={() => this.tr("No options")}
                    value={data.product_id}
                    autoCompleteData={CPQParents}
                    searchable={true}
                    menuPortalTarget={document.body}
                    listCellProps={{...listCellEditModeProps, forceToolTip: true, showTooltipForOverflownText:true, className: columnConfig.name.className}}
                    onEdited={async (value) => {
                        this.setData("product_id", value.id);

                        try {
                            const { cpqs } = await DataHandler.get({ url: `cpq/childrens`, parentId: value.id }); 
                            const sums     = { cost: 0, quantity: 1, value: 0, vat: 0, margin: 0 };

                            cpqs.forEach(row => {
                                sums.cost  += Number(row.quantity) * Number(row.unit_cost); 
                                sums.value += Number(row.quantity) * Number(row.selling_price);
                            });

                            sums.margin = sums.value - sums.cost;

                            this.setData(sums);
                        } catch(e) {
                            
                        }

                        // this.setData("cpqId", value.id);
                        // rowDataChanged("cpqSingleRow", value, quoteId, headerId, data.id);
                    }}
                    /> :
                <TextAreaCell
                    // {...editModeProps} TODO
                    // style={{width: columnWidthMap['name'] + 'px'}}
                    name="name"
                    placeholder={this.tr("Item")}
                    // value={data["_type"] !== "cpqRow" ? data.name : data.description}
                    value={(data.description == "Row" ? this.tr(data.description): data.description) || (data.name == "Row" ? this.tr(data.name): data.name)} 
                    {...textInputCellProps}
                    listCellProps={{...listCellEditModeProps, className: columnConfig.name.className, showTooltipForOverflownText: true}}
                    onEdited={value => {
                        this.setData("name", value);
                        this.setData("description", value); // En tiijjä
                        // rowDataChanged(name, value, quoteId, headerId, data.id)
                    }}/> ,
            quantity:
                <TextInputCell
                    // {...editModeProps} TODO
                    name="quantity"
                    value={formatInputNumber(data.quantity)}
                    validation={["numeric"]}
                    {...textInputCellProps}
                    //inputType="number"
                    listCellProps={{...listCellEditModeProps, className: columnConfig.quantity.className}}
                    textAlign="right"
                    onEdited={value => {
                        this.setData("quantity", value);
                        // rowDataChanged(name, value, quoteId, headerId, data.id)
                    }}/>,
            quantityType:
                data["_type"] === "productRow" || (data["_type"] === "cpqRow" && (!data.hasOwnProperty("type") || type == 2)) 
                ? 
                <AutoCompleteCell
                    listCellProps={{...listCellEditModeProps, className: columnConfig.quantityType.className}}
                    className="onQuoteRow"
                    name="quantityType"
                    value={data.quantityType}
                    useClickAwayListener={false}
                    cellClassName={editMode ? "productCell" : ""}
                    autoCompleteData={units}
                    searchable={true}
                    menuPortalTarget={document.body}
                    allowCreate={true}
                    onEdited={value => {
                        this.setData("quantityType", value);

                        /*if(value.name) {
                            rowDataChanged("quantityType", value.name, quoteId, headerId, data.id)
                        } else {
                            rowDataChanged("quantityType", value.value, quoteId, headerId, data.id);
                            DataHandler.post({url: 'settings/company/product/units'}, { id: -1, name: value.value, deleted: 0 }).done(response => {});
                        }*/
                    }} />
                : 
                <AutoCompleteCell
                    // {...editModeProps} // TODO
                    className="onQuoteRow"
                    // style={{width: columnWidthMap['quantityType'] + 'px'}}
                    name="quantityType"
                    value={data.quantityType || this.quantityTypes[0].id} // TODO
                    autoCompleteData={this.quantityTypes.map(qt => ({ ...qt, value: qt.id }))}
                    searchable={false}
                    menuPortalTarget={document.body}
                    listCellProps={{...listCellEditModeProps, className: columnConfig.quantityType.className}}
                    onEdited={(value) => {
                        this.setData("quantityType", value.id);

                        // rowDataChanged("quantityType", value.id, quoteId, headerId, data.id)
                    }} />,
            cost:
                <TextInputCell
                    listCellType={CurrencyListCell}
                    // {...editModeProps} // TODO
                    // style={{width: columnWidthMap['cost'] + 'px'}}
                    name="cost"
                    value={formatInputNumber(data.cost)}
                    validation={["numeric"]}
                    {...textInputCellProps}
                    //inputType="number"
                    listCellProps={{...listCellEditModeProps, className: columnConfig.cost.className, currency: this.props.rowProps.currency}}
                    textAlign="right"
                    onEdited={value => {
                        this.setData("cost", value);
                        // rowDataChanged(name, value, quoteId, headerId, data.id)
                    }}/>,
            value:
                <TextInputCell
                    listCellType={CurrencyListCell}
                    // {...editModeProps} // TODO
                    // style={{width: columnWidthMap['value'] + 'px'}}
                    name="value"
                    value={formatInputNumber(data.value)}
                    validation={["numeric"]}
                    {...textInputCellProps}
                    currency={this.props.rowProps.currency}
                    //inputType="number"
                    listCellProps={{...listCellEditModeProps, className: columnConfig.value.className, currency: this.props.rowProps.currency}}
                    textAlign="right"
                    onEdited={value => {
                        this.setData("value", value);
                        // rowDataChanged(name, value, quoteId, headerId, data.id)
                    }}/>,
            vat:
                <TextInputCell
                    // {...editModeProps} // TODO
                    // style={{width: columnWidthMap['vat'] + 'px'}}
                    name="vat"
                    value={formatInputNumber(data.vat)}
                    validation={["numeric"]}
                    {...textInputCellProps}
                    //inputType="number"
                    listCellProps={{...listCellEditModeProps, className: columnConfig.vat.className}}
                    textAlign="right"
                    onEdited={value => {
                        this.setData("vat", value);
                        // rowDataChanged(name, value, quoteId, headerId, data.id)
                    }}/>,
            vatTotal:
                <ListCell
                    // style={{width: columnWidthMap['vatTotal'] + 'px'}}
                    name="vatTotal"
                    textAlign="right"
                    value={Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: this.props.rowProps.currency}).format(vatTotal)}
                    editable={false}
                    noBorder
                    listCellProps={{className: columnConfig.vatTotal.className, currency: this.props.rowProps.currency}}
                    className={columnConfig.vatTotal.className}
                    />,
            discountPercentage:
                <TextInputCell
                    // {...editModeProps} // TODO
                    // style={{width: columnWidthMap['discountPercentage'] + 'px'}}
                    name="discountPercentage"
                    value={formatInputNumber(data.discountPercentage)}
                    validation={["numeric"]}
                    {...textInputCellProps}
                    //inputType="number"
                    listCellProps={{listCellEditModeProps, className: columnConfig.discountPercentage.className}}
                    textAlign="right"
                    onEdited={value => {
                        this.setData("discountPercentage", value);
                    // rowDataChanged(name, value, quoteId, headerId, data.id)
                    }}/>,
            sum:
                <ListCell
                    // style={{width: columnWidthMap['sum'] + 'px'}}
                    name="sum"
                    value={Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: this.props.rowProps.currency}).format(sum)} // TODO
                    textAlign="right"
                    editable={false}
                    noBorder
                    className={columnConfig.sum.className}
                    listCellProps={{currency: this.props.rowProps.currency}}
                    />,
            margin:
                <ListCell
                    style={{ width: '50px' }}
                    width={30}
                    name="margin"
                    value={Intl.NumberFormat(taimerAccount.numberFormat, { style: 'currency', currency: this.props.rowProps.currency }).format(margin)} // TODO
                    editable={false}
                    noBorder
                    textAlign="right"
                    className={columnConfig.margin.className}
                    listCellProps={{ currency: this.props.rowProps.currency }}
                />,
            workType:
                <AutoCompleteCell
                    className="onQuoteRow"
                    listCellProps={{
                        // editable: editMode && data.has_automatic_targeting < 1,
                        //inEditMode: editMode && data.has_automatic_targeting < 1
                    }}
                    // style={{width: columnWidthMap['workType'] + 'px'}}
                    name="workType"
                    // value={workType} // TODO
                    value={data.workType}
                    autoCompleteData={this.workTypes.map(wt => ({...wt, value: wt.id}))}
                    searchable={false}
                    editable={true}
                    listCellProps={{ ...listCellEditModeProps, textAlign: "center", className: "hide-in-print"}}
                    // editable={editMode && data.has_automatic_targeting < 1} // TODO:
                    menuPortalTarget={document.body}
                    onEdited={value => {
                        this.setData("workType", value.id);
                        // rowDataChanged("workType", value.id, quoteId, headerId, data.id)
                    }}
                />,
            jobtypes_id:
                <AutoCompleteCell
                    className="onQuoteRow"
                    listCellProps={{
                        // editable: editMode && data.has_automatic_targeting < 1,
                        //inEditMode: editMode && data.has_automatic_targeting < 1
                    }}
                    // style={{width: columnWidthMap['workType'] + 'px'}}
                    name="jobtypes_id"
                    // value={workType} // TODO
                    value={data.jobtypes_id}
                    autoCompleteData={rowProps.jobtypes}
                    searchable={true}
                    editable={true}
                    listCellProps={{ ...listCellEditModeProps, textAlign: "center", className: "hide-in-print"}}
                    // editable={editMode && data.has_automatic_targeting < 1} // TODO:
                    menuPortalTarget={document.body}
                    onEdited={value => {
                        this.setData("jobtypes_id", value.id);
                        // rowDataChanged("workType", value.id, quoteId, headerId, data.id)
                    }}
                />
        };
    }
}

// Also acts as a CPQ header row.
class QuoteHeaderRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, {}, "list/rows/QuoteHeaderRow");

        // TODO: What are these for?
        this.typeMap = {
            // 0: parent,
            // 1: item,
            // 2: product,
            // 3: description
        };

        this.selectConfiguration = this.selectConfiguration.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.data.parentChosen && this.props.data.parentChosen && this.props.data.cpqParentId !== undefined)
            this.selectConfiguration(this.props.data.cpqParentId);
    }


    defineClassName() {
        if (this.props.data.hidden_for_print > 0)   
            return "quote-listrow quoteRow header useEllipsis hidePrint";
        else 
            return "quote-listrow quoteRow header useEllipsis";
    }


    async selectConfiguration(parentId) {
        const typeMap = {
            1: "quoteRow",
            2: "productRow", 
            3: "descriptionRow"
        };

        const cpqResponse = await DataHandler.get({ 
            url: `cpq/childrens`, 
            parentId: parentId,
            company: this.props.rowProps.company
        });

        this.props.listRef.addNewRow(cpqResponse.cpqs.map(row => {
            let rowType = Number(row.type);
            let rRow    = {
                parentId: this.props.data.id, 
                _type: typeMap[rowType],

                cost: row.unit_cost,
                description: row.description,
                quantity: row.quantity,
                quantityType: row.unit,
                type: row.type,
                hidden_for_print: row.hidden_for_print,

                // TODO: type_id/product_id requires more scrutiny; can always having type_id in product_id produce bugs?
                type_id: row.type_id,
                product_id: rowType === 2 ? row.type_id : undefined,

                value: row.selling_price,
                vat: row.vat,
                workType: row.worktype,
            };

            return rRow;
        }));
    }


    defineCells() {
		// TODO:
        const { data, rowProps } = this.props;
        const { CPQParents, canCreatePurchaseOrder}     = rowProps;
        const editMode           = rowProps.editMode;
        const { units, hidden, rowDataChanged, quoteId, headerId } = rowProps;
        const listCellEditModeProps = {
            editable: rowProps.editMode,
            inEditMode: rowProps.editMode,
            noInitFocus: true
        };

        return {
            context:
                <ListCell permanentEditMode={true} noBorder={true}>
                    {(editMode || canCreatePurchaseOrder) && (
                    <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell row-menu" noExpandIcon popperProps={{disablePortal: false}}>
                    {!editMode ? (
                        <> 
                        <MenuItem className="menuItem" onClick={() => this.props.rowProps.createPurchaseOrder(this.props.data)}><AddIcon className="menuIcon show" />{this.tr('Add purchase order')}</MenuItem>
                        </>
                    ) : (
                        <>
                        <MenuItem className="menuItem" onClick={() => this.setData("hidden_for_print", this.props.data.hidden_for_print == 1 ? 0 : 1)}><EyeIcon className="menuIcon show" />{this.tr('Show/Hide from print')}</MenuItem>
                        <MenuItem  className="delete" disabled={data.has_task > 0} onClick={() => this.props.rowProps.markRowDeleted(this.props.data.id)}><RemoveIcon className="menuIcon delete"/>{this.tr('Delete')}</MenuItem>
                        {/*<MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.data.id, _type: "row" })}>{this.tr('Add child')}</MenuItem>*/}
                        </>
                    )}
                    </ContextMenu>)}
                </ListCell>,
            move: 
                <ListCell
                    // className="move-cell"
                    style={{
                        // maxWidth: columnWidthMap['move'] + "px"
                    }}
                    noBorder={true}
                    permanentEditMode={true}>
                        <div className={!editMode ? "transparent" : ""} style={{ cursor: "grab" }} onMouseDown={this.startDrag}>
                            <MoveIcon />
                        </div>
                </ListCell>,
            text: data["_type"] !== "cpqHeaderRow" || (data["_type"] === "cpqHeaderRow" && data.parentChosen)
            ? 
            <TextInputCell 
                value={(data.text == "Header" ? this.tr(data.text) : data.text) ||(data.name == "Header" ? this.tr(data.name) : data.name)} 
                listCellProps={{...listCellEditModeProps, className: this.props.columnConfig.text.className}}
                onEdited={value => {
                    this.setData("text", value);
                }} />
            :
            <AutoCompleteCell
                className="onQuoteRow"
                listCellProps={{...listCellEditModeProps, className: this.props.columnConfig.text.className}}
                menuPortalTarget={document.body}
                noOptionsMessage={() => this.tr("No options")}
                autoCompleteData={CPQParents}
                selectProps={{
                    noOptionsMessage: () => this.tr("No option"),
                }}
                onEdited={value => {
                    this.setData(data => {
                        data.cpqParentId  = value.id;
                        data.parentChosen = true;
                        data.text         = value.name;
                        data.hidden_for_print = value.hidden_for_print;

                        return data;
                    });
                }}
                value={data.cpqParentId ? data.cpqParentId : (CPQParents[0] ? CPQParents[0].id : 0)} 
            />
        };
    }


    render() {
        let columnOrder = ["context", "move", "button", "quantity", "quantityType", "cost", "value", "vat", "vatTotal", "discountPercentage", "sum", "margin"];
        //if (this.context.addons.nav) columnOrder.push("jobtypes_id");
        columnOrder.push("filler");
        
        return (
            <React.Fragment>
                {super.render()}
                <QuoteTopicSummaryRow 
                    key={"quoteTopicSummaryRow"}
                    fluid 
                    data={this.props.listRef.getData().filter(d => Number(d.parentId) === Number(this.props.data.id)).filter(d => !d.deleted || d.deleted === "0")}
                    editMode={this.props.rowProps.editMode}
                    formatCurrency={this.props.rowProps.formatCurrency}
                    parentId={this.props.data.id}
                    lastInGroup={true}
                    listRef={this.props.listRef}
                    noColorVariance={true}
                    columnOrder={columnOrder} 
                    columnConfig={{ 
                        context: { width: 10 },
                        move: { width: 10 },
                        button: { width: 110 },
                        quantity: { width: 25, className: this.props.columnConfigs.quoteRow.quantity.className },
                        quantityType: { width: 30, className: this.props.columnConfigs.quoteRow.quantityType.className },
                        cost: { width: 35, className: this.props.columnConfigs.quoteRow.cost.className },
                        value: { width: 35, className: this.props.columnConfigs.quoteRow.value.className },
                        vat: { width: 20, className: this.props.columnConfigs.quoteRow.vat.className },
                        vatTotal: { width: 35, className: this.props.columnConfigs.quoteRow.vatTotal.className },
                        discountPercentage: { width: 30, className: this.props.columnConfigs.quoteRow.discountPercentage.className },
                        sum: { width: 35, className: this.props.columnConfigs.quoteRow.sum.className },
                        margin: { width: 35, className: this.props.columnConfigs.quoteRow.margin.className },
                        //jobtypes_id: this.context.addons.nav ? { width: 50 } : undefined,
                        filler: { width: 50 }
                        // button: { width: 145 },
                        // cost: { width: 70 },
                        // vatTotal: { width: 45 },
                        // sum: { width: 30 },
                        // margin: { width: 100 }
                    }} />
            </React.Fragment>
        );
    }
}


class QuoteDescriptionRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, {}, "list/rows/QuoteDescriptionRow");
    }


    defineClassName() {
        if (this.props.data.hidden_for_print > 0)   
            return "quote-listrow quoteRow useEllipsis hidePrint";
        else 
            return "quote-listrow quoteRow useEllipsis";
    }


    defineCells() {
		// TODO:
        const { data, rowProps } = this.props;
        const editMode           = rowProps.editMode;
        const { units, hidden, rowDataChanged, quoteId } = rowProps;
        const listCellEditModeProps = {
            editable: rowProps.editMode,
            inEditMode: rowProps.editMode,
            noInitFocus: true
        };

        return {
            context:
                <ListCell permanentEditMode={true} noBorder={true} className={!editMode ? "transparent" : ""}>
                    <ContextMenu label={<ThreeDotsIcon />} buttonProps={{className: 'action-menu'}} className="cell" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem onClick={() => this.setData("hidden_for_print", this.props.data.hidden_for_print == 1 ? 0 : 1)}><EyeIcon className="menuIcon show" />{this.tr('Show/Hide from print')}</MenuItem>
                        <MenuItem  className="delete" onClick={() => this.props.rowProps.markRowDeleted(this.props.data.id)}><RemoveIcon className="menuIcon delete"/>{this.tr('Delete')}</MenuItem>
                        {/*<MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.data.id, _type: "row" })}>{this.tr("Add child")}</MenuItem>*/}
                    </ContextMenu>
                </ListCell>,
            move: 
                <ListCell
                    // className="move-cell"
                    style={{
                        // maxWidth: columnWidthMap['move'] + "px"
                    }}
                    noBorder={true}
                    permanentEditMode={true}>
                        <div className={!editMode ? "transparent" : ""} style={{ cursor: "grab" }} onMouseDown={this.startDrag}>
                            <MoveIcon />
                        </div>
                </ListCell>,
            description: 
                <TextInputCell 
                    listCellProps={{...listCellEditModeProps, className: this.props.columnConfig.description.className}}
                    value={data.description || data.name || data.text} 
                    runOnEditedOnInput={true}
                    onEdited={value => {
                        this.setData("description", value);
                    }} />
        };
    }
}



class QuoteTopicSummaryRow extends ListRow {
    constructor(props) {
        super(props, {}, {}, "list/rows/QuoteTopicSummaryRow");

        this.menu = React.createRef();

        this.formSummedData = this.formSummedData.bind(this);
    }


    defineClassName() {
        return "quote-listrow quoteRow summary";
    }


    formSummedData() {
        const summed = { cost: 0, vatTotal: 0, sum: 0, margin: 0 }; 

        for(let data of this.props.data) {
            const calcValue        = parseInt(data.discountPercentage) > 0 ? data.value - parseInt(data.discountPercentage) / 100 * data.value : data.value;
            const sum              = data.quantity * calcValue;
            const vatTotal         = data.vat / 100 * (calcValue * data.quantity);
            const margin           = sum - data.quantity * data.cost;

            // || 0 for when the value itself is NaN (description row, etc.)
            summed.cost     += (parseFloat(data.cost) * parseFloat(data.quantity)) || 0;
            summed.vatTotal += vatTotal || 0;
            summed.sum      += sum || 0;
            summed.margin   += margin || 0;
        }

        Object.keys(summed).forEach(k => {
            summed[k] = !summed[k] ? 0 : summed[k];
        });

        return summed;
    }


    defineCells() {
        const commonProps   = { noBorder: true, editable: false, textAlign: "right" };
        const { editMode, columnConfig }  = this.props;
        const { versionId } = this.context;
        const summed        = this.formSummedData();

        return {
            context: <ListCell {...commonProps} className='hide-in-print' />,
            move: <ListCell {...commonProps} className='hide-in-print' />,
            button: 
                editMode ? <ListCell noBorder={true} onlyDisplay={true} className="action-cell">
                    <ContextMenu label={
                        <Button variant="text" className={"action-button"}>
                            <AddIcon className="add-icon" />
                            {this.tr('Add new')}
                        </Button>
                    } buttonProps={{className: 'action-menu'}} className="cell" noExpandIcon popperProps={{disablePortal: false}}>
                        <MenuItem onClick={() => this.props.listRef.addNewRow({ _type: "headerRow" })}>{this.tr("Header")}</MenuItem>
                        <MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.parentId, _type: "descriptionRow" })}>{this.tr("Description")}</MenuItem>
                        <MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.parentId, _type: "quoteRow" })}>{this.tr("Row")}</MenuItem>
                        <MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.parentId, _type: "productRow", workType: 4 })}>{this.tr("Product")}</MenuItem>
                        <MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.parentId, _type: "cpqRow" })}>{this.tr("CPQ row (grouped)")}</MenuItem>
                        <MenuItem onClick={() => this.props.listRef.addNewRow({ parentId: this.props.data.id, _type: "cpqHeaderRow" })}>{this.tr("CPQ row (itemized)")}</MenuItem>
                    </ContextMenu>
                </ListCell> : <ListCell noBorder={true} editable={false} className="start-filler"/>,
            quantity: <ListCell {...commonProps} className={columnConfig.quantity.className} />,
            quantityType: <ListCell {...commonProps} className={columnConfig.quantityType.className} />,
            cost: <ListCell {...commonProps} className={columnConfig.cost.className} value={this.props.formatCurrency.format(summed.cost)} />,
            value: <ListCell {...commonProps} className={columnConfig.value.className} />,
            vat: <ListCell {...commonProps} className={columnConfig.vat.className} />,
            vatTotal: <ListCell {...commonProps} className={columnConfig.vatTotal.className} value={this.props.formatCurrency.format(summed.vatTotal)} />,
            discountPercentage: <ListCell {...commonProps} className={columnConfig.discountPercentage.className} />,
            sum: <ListCell {...commonProps} value={this.props.formatCurrency.format(summed.sum)} className={"sum " + columnConfig.sum.className} />,
            margin: <ListCell {...commonProps} className={columnConfig.margin.className} value={this.props.formatCurrency.format(summed.margin)} />,
            //jobtypes_id: this.context.addons.nav ? <ListCell {...commonProps} className={columnConfig.jobtypes_id.className + " hide-in-print"} /> : undefined,
            filler: <ListCell {...commonProps} className="hide-in-print" />,
        };
    }
}


//Main class (Quote)
class Quote extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "projects/Quote");

        this.list  = React.createRef();
        this.paper = React.createRef();
        this.refProjectName = React.createRef();

        this.typeMap = {
            "0": "headerRow",
            "1": "quoteRow",
            "2": "descriptionRow",
            "3": "productRow",
            "4": "cpqRow"
        };

        this.hideableColumns = [
            //{field: this.tr("Description"), key: "name"},
            {field: this.getTranslation("Quantity"), key: "quantity"},
            {field: this.getTranslation("Unit"), key: "quantityType"},
            {field: this.getTranslation("Unit cost"), key: "cost"},
            {field: this.getTranslation("Selling price"), key: "value"},
            {field: this.getTranslation("VAT %"), key: "vat"},
            {field: this.getTranslation("VAT Total"), key: "vatTotal"},
            {field: this.getTranslation("Discount %"), key: "discountPercentage"},
            {field: this.getTranslation("Sum 0%"), key: "sum"},
            {field: this.getTranslation("Margin 0%"), key: "margin"},
            {field: this.getTranslation("Quote Total"), key: "quoteTotal"},
            //{field: this.tr("Type of work"), key: "workType"},
        ];

        this.typeMapReversed = Utils.flipObject(this.typeMap);

        this.reformatHeaders          = this.reformatHeaders.bind(this);
        this.formatCurrency           = Intl.NumberFormat(this.context.taimerAccount.numberFormat, { style: 'currency', currency: this.props.rowProps.currency });
        this.markRowDeleted           = this.markRowDeleted.bind(this);
        this.addFirstRows             = this.addFirstRows.bind(this);
        this.emptyNewData             = this.emptyNewData.bind(this);
        this.getRowTypeMap            = this.getRowTypeMap.bind(this);
        this.getQuoteData             = this.getQuoteData.bind(this);
        this.countSums                = this.countSums.bind(this);
        this.updateTotals             = this.updateTotals.bind(this);
        this.quoteRowVisibilityFilter = this.quoteRowVisibilityFilter.bind(this);
        this.onListDataChange         = this.onListDataChange.bind(this);
        this.onDraggedRowDrop         = this.onDraggedRowDrop.bind(this);
        this.editData                 = this.editData.bind(this); 
        this.resetData                = this.resetData.bind(this);
        this.checkEditedAddress       = this.checkEditedAddress.bind(this);

        this.state = {
            anchorEl: null,
            quoteDataRows: this.reformatHeaders(this.props.data.headers),
            totals: this.countSums(),
            rowOrder: undefined,
            editProjectName: false,
            editProjectWidth: 100,
            print: false,
            hasEditedAddress: true,
        };
    }


    componentDidMount() {
        super.componentDidMount();
        this.updateProjectName();
        if(Number(this.props.id) > 0)
            return;

        setTimeout(this.addFirstRows);
    }

    onListMounted = () => {
        this.props.onPrintRenderReady && this.props.onPrintRenderReady();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
    }

    updateProjectName = () => {
        this.setState({editProjectName: false, projectName: this.props.data.project_name ? this.props.data.project_name : this.props.project.name});

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data.id !== prevProps.data.id) {
            this.updateProjectName();
            this.setState({hasEditedAddress: true});
        } else {
            this.setState({hasEditedAddress: this.checkEditedAddress()});
        }

        if(this.props.data.id < 0 && this.props.data.id !== prevProps.data.id) {
            this.setState({ quoteDataRows: [] }, () => {
                setTimeout(this.addFirstRows, 100); // Ei hyvä.
            });

            return;
        }

        const { active: prevActive, ...prevDataRest } = prevProps.data;
        const { active: curActive, ...curDataRest }   = this.props.data;

        if(!isEqual(prevDataRest, curDataRest)) {
            const { headers } = this.props.data;

            this.setState({
                quoteDataRows: this.reformatHeaders(headers),
                totals: this.countSums(headers)
            }); 
        }

        
    }

    checkEditedAddress() {
        const { taimerAccount: { showState } } = this.context;
        let hasEditedAddress = false;
        if (this.props.data.editedAddress) {
            let selectedAddress = this.props.visitingAddresses.find(addr => addr.id === this.props.data.address_id);
            let checkFields = ['address', 'postalcode', 'city', 'state', 'country', 'vatid'];
            if (showState) {
                checkFields.push('state');
            }
            checkFields.forEach(fieldName => {
                if (this.props.data.address_id == 0) {
                    if (this.props.data.editedAddress[fieldName] && this.props.data.editedAddress[fieldName] !== "") {
                        hasEditedAddress = true;
                    }
                } else if (selectedAddress && selectedAddress[fieldName] !== this.props.data.editedAddress[fieldName] && this.props.data.editedAddress.address) {
                    hasEditedAddress = true;
                }   
            });
        }
        return hasEditedAddress;
    }

    reformatHeaders(headers) {
        const quoteDataRows = [];

        (headers || []).forEach(header => {
            header["_type"] = "headerRow";

            quoteDataRows.push(header);

            header.rows.forEach(row => {
                row["_type"] = this.typeMap[row.type];

                quoteDataRows.push(cloneDeep(row));
            });
        });

        return quoteDataRows;
    }


    addFirstRows() {
        const ids = this.list.current.addNewRow({ _type: "headerRow" });

        setTimeout(() => this.list.current.addNewRow({ _type: "quoteRow", parentId: ids[0] }));
    }


    emptyNewData(cb = () => {}, origin = undefined) {
        this.list.current.emptyNewData(cb, origin);
    }


    getRowTypeMap(reversed = false) {
        return !reversed ? this.typeMap : this.typeMapReversed;
    }


    getQuoteData() {
        return this.list.current.getData();
    }


    markRowDeleted(id) {
        id = Number(id);

        const data = this.list.current.getData();
        const ids  = [id, ...data.filter(d => Number(d.parentId) === id).map(d => d.id)]

        this.list.current.editData({ deleted: 1 }, ids);
    }

    editData(...args) {
        this.list.current.editData(...args); 
    }


    resetData() {
        this.list.current.resetStateData();     
    }


    /*selectQuote = (id) => {
        const { quotes } = this.props;
        let foundQuote = null;

        if (id == -1) {
            foundQuote = quotes.find(c => c.active == 1);
            if (!foundQuote)
                foundQuote = quotes[0];
        }
        else
            foundQuote = quotes.find(c => c.id == id);

        if (foundQuote)
            return foundQuote;
        else
            return false;
    }*/

    countSums(headers) {
        const totals = {
            vat: 0,
            subtotal: 0,
            discount: 0
        };

        if (!headers) {
            return totals;
        }

        headers.map(h => h.rows.filter(row => row.deleted == 0).map(row => {
            if (row.type == 2)
                return;
            totals.vat += (1 - (row.discountPercentage / 100)) * (row.quantity * row.value) * (row.vat / 100) - 0;
            totals.subtotal += row.quantity * row.value;
            if (row.discountPercentage > 0)
                totals.discount += (row.discountPercentage / 100) * (row.quantity * row.value)
        }));

        return totals;
    }


    updateTotals(listData) {
        const totals = {
            vat: 0,
            subtotal: 0,
            discount: 0,
            vatsTotals: {}
        };

        listData.filter(r => r.parentId && r.parentId !== "0").forEach(row => {
            totals.vat      += Number((1 - (row.discountPercentage / 100)) * (row.quantity * row.value) * (row.vat / 100)) || 0;
            totals.subtotal += Number(row.quantity * row.value) || 0;

            //TODO: Some US states have vats with 3 decimals. Supporting 3 digits needs changes elsewhere too.
            if (row.vat != 0) {
                totals.vatsTotals[Number(row.vat).toFixed(2)] = !totals.vatsTotals[Number(row.vat).toFixed(2)] ? Number((1 - (row.discountPercentage / 100)) * (row.quantity * row.value) * (row.vat / 100)) || 0 : totals.vatsTotals[Number(row.vat).toFixed(2)] + Number((1 - (row.discountPercentage / 100)) * (row.quantity * row.value) * (row.vat / 100)) || 0;
            }
            totals.vatsTotals = Object.fromEntries(Object.entries(totals.vatsTotals).sort((a, b) => Number(a[0]) - Number(b[0]))); //sort vats percentages ascending

            if(row.discountPercentage > 0)
                totals.discount += Number((row.discountPercentage / 100) * (row.quantity * row.value)) || 0;
        });

        this.setState({ totals: totals });
    }


    quoteRowVisibilityFilter(row) {
        return row && (!row.deleted || row.deleted === "0");
    }


    onListDataChange(e) {
        const data = e.data.filter(r => !r.hasOwnProperty("deleted") || Number(r.deleted) === 0);

        this.updateTotals(data);
        this.props.onListDataChange(data);

        if(data.length === 0 && e.origin === "editData") {
            this.addFirstRows();
        }
    }


    onDraggedRowDrop(droppedRow, onRow, currentOrder, dataMap, listRef) {
        let dId        = Number(droppedRow.data.id);
        let strOnId    = typeof(onRow.data) === "object" ? String(onRow.data.id) : null;
        let onId       = typeof(onRow.data) === "object" ? Number(onRow.data.id) : null;
        const newOrder = [];
        const toMove   = [dId];
        let toFlip     = [dId];
        let after      = false;

        const onRowParentId = onRow.data.id !== "BOTTOM_MARKER" ? onRow.parentId || onRow.data.parentId : null;

        if(onRow.lastInGroup) {
            after = true; 

            const inHeader = listRef.getData()
                .filter(d => Number(d.parentId) === Number(onRowParentId));

            if(!inHeader || inHeader.length === 0) {
                onId = onRowParentId;
            } else {
                onId = Number(inHeader[inHeader.length - 1].id); 
            }
        }

        // If we're moving a group of rows.
        if(droppedRow.data['_type'] === "headerRow" || droppedRow.data['_type'] === "cpqHeaderRow") {
            listRef.getData()
                .filter(d => Number(d.parentId) === dId)
                .forEach(d => toMove.push(Number(d.id)));

            const treeMap = makeMapOfPrimitives(toMove, true);
            currentOrder  = currentOrder.filter(id => !treeMap[id]);
            toFlip        = clone(toMove);
        } else if((droppedRow.data.parentId && onRowParentId) && droppedRow.data.parentId !== "0" && Number(droppedRow.data.parentId) !== Number(onRowParentId)) { // If we're moving a row under a new parent (into a new group).
            toFlip.push(droppedRow.data.parentId);

            dataMap[dId].parentId = onRowParentId;
            currentOrder          = currentOrder.filter(id => id !== dId);
        } else {
            currentOrder = currentOrder.filter(id => id !== dId);
        }

        let cur;

        for(let i in currentOrder) {
            cur = currentOrder[i];

            if(cur === onId && !after) {
                toMove.forEach(id => newOrder.push(id))
            }

            newOrder.push(cur);

            if(cur === onId && after) {
                toMove.forEach(id => newOrder.push(id))            
            }
        }

        if(onRow.data.id === "BOTTOM_MARKER") {
            toMove.forEach(id => newOrder.push(id));
        }

        // Rows filtered out by List.props.visibilityFilter are not in currentOrder, 
        // so we have to add them manually to the 
        // data that will replace List's current data.
        const deletedRows = listRef.getData().filter(d => newOrder.indexOf(Number(d.id)) === -1);

        return [newOrder.map(id => dataMap[id]).concat(deletedRows), () => listRef.flipRows(toFlip)];
    }

    getClassName = (columnName) => {
        if (!this.props.data.print_exceptions)
            return "";
            
        return this.props.data.print_exceptions.includes(columnName) ? "hidePrint" : "";
    }

    togglHiddenColumns = (columnName) => {
        let exceptions = this.props.data.print_exceptions ? this.props.data.print_exceptions : [];
        const index = exceptions.indexOf(columnName);

        if (index > -1) {
            exceptions.splice(index, 1);
        } else {
            exceptions.push(columnName);
        }  

        this.props.quoteDataChanged(this.props.data.id, 'print_exceptions', exceptions);

    }

    getSelectedUser = (id, users) => {
		let response = users && users.find(e => e.id == id);
		if (!response) {
			const user = this.props.allUsers && this.props.allUsers.find(e => e.id == id);
			if (user) 
				response = user.name;
			else 
				response = false;
        }
		return response;
    }
    
    createPurchaseOrder = (data) => {
        this.context.functions.updateView({ module: 'purchaseorder', action: 'view', companies_id: this.props.project.companies_id, projects_id: this.props.project.id, quote: this.props.data.id, quoteRow: data.id});
    }

    changeProjectName = (e) => {
        const { target: { value } } = e;
        const { project } = this.props;

        this.setState({projectName: value});
    }

    getProjectName = () => {
        const { project } = this.props;
        const { projectName } = this.state;

        if (!projectName || projectName === project.name)
            return '';

        return projectName;
    }

    endProjectNameEdit = () => {
        const { projectName } = this.state;
        const { project } = this.props;

        this.setState({editProjectName: false, projectName: projectName || project.name});
    }
    
    getTranslation = (value) => {
        const { renderPrintQuote, printLanguage } = this.props;
        return !renderPrintQuote ? this.tr(value) : this.beTr(value, printLanguage || "en", {}, "general/backendTranslations/QuotePrint");
    }

    //Main class (Quote) render
    render() {
        // const {selectedQuoteId, project, defaultBillingAddressId, editMode, billingAddresses, customerContacts, companyContacts, paperProps, companyAddress, quotes} = this.props;
        // const data   = this.selectQuote(selectedQuoteId);
        const { editProjectName, projectName, hasEditedAddress } = this.state;
        const { project, defaultBillingAddressId, editMode, billingAddresses, visitingAddresses, customerContacts, companyContacts, receiverContacts, paperProps, companyAddress, quotes, defaultVAT } = this.props;
        const { taimerAccount: { showState } } = this.context;

        const data          = this.props.data;
        const totals        = this.state.totals;
        const quoteDataRows = this.state.quoteDataRows;
        const headerWidth   = /*this.context.addons.nav ? 490 :*/ 440;

        const rowProps = {
            ...this.props.rowProps, 
            markRowDeleted: this.markRowDeleted,
            createPurchaseOrder: this.createPurchaseOrder,
            formatCurrency: this.formatCurrency,
            context: this.context,
            canCreatePurchaseOrder: project.rights.includes("purchase_order_write"),
            getTranslation: this.getTranslation
        };

        const currency = this.props.rowProps.currency;

        /*let billingAddresses = this.props.billingAddresses ? this.props.billingAddresses.map(addr => {
            addr.value = addr.id;
            return addr;
        }) : undefined;*/

        let visitingAddressId  = data.address_id > 0 ? data.address_id : undefined;
        let receiverContactId = data.receiver_contact_id > 0 ? data.receiver_contact_id : undefined;
        let senderContactId   = data.sender_contact_id > 0 ? data.sender_contact_id : undefined;
        let totalRows         = [];

        totalRows.push({grey: true, name: this.getTranslation("Subtotal"), sum: (totals.subtotal).toFixed(2)});

        if (totals.discount > 0) 
            totalRows.push({grey: true, name: this.getTranslation("Discount"), sum: (totals.discount).toFixed(2)});

        //totalRows.push({grey: true, name: this.tr("VAT Total"), sum: (totals.vat).toFixed(2)});

        if (totals.vatsTotals) {
            for (const [key, value] of Object.entries(totals.vatsTotals)) {
                if (Object.keys(totals.vatsTotals).length == 1) {
                    totalRows.push({grey: true, name: this.getTranslation("Vat") + ` ${key}%` , sum: (value).toFixed(2)});
                } else {
                    totalRows.push({greyvat: true, name: this.getTranslation("Vat") + ` ${key}%` , sum: (value).toFixed(2)});
                }
            } 
        }

        //add vat totals only if more than 1 different vat %
        if (totals.vatsTotals && Object.keys(totals.vatsTotals).length !== 1) {
            totalRows.push({grey: true, name: this.getTranslation("VAT Total"), sum: (totals.vat).toFixed(2)});
        }

        totalRows.push({black: true, name: this.getTranslation("Total"), sum: (totals.vat + totals.subtotal - totals.discount).toFixed(2)});

        const newPaperProps    = Object.assign(paperProps, {paperMounted: this.paperMounted});
        const selectedUser = this.getSelectedUser(senderContactId, companyContacts);
        
        /* refund quotes have preferrably negative quantity */
        let defaultQuantity = data.type == 1 ? 0.00 : -1;

        //Main class (Quote) render return
        return (
            <Paper ref={this.paper} {...newPaperProps} style={{ /*paddingBottom: "200px"*/ }}>
                <div className="paper-container">
                    <div className="top">
                        <Header noFlex={true}>
                        <div className="project-details">
                            {data.active == true && !this.props.printMode && <span id="currently-active">{data.type == 1 ? this.tr("Active quote") : this.tr("Refund quote")}</span>}
                            {this.context.taimerAccount.showLogo == 1 ? (<div className="logo-container"><Logo editMode={false} company={project.companies_id ? project.companies_id : 1} base64Encode={true} /></div>) : <div></div>}
                            {!editMode && <h1 ref={this.refProjectName} className="project-name">
                                {projectName}
                            </h1>}
                            {editMode && <h1 className="project-name">
                                <input value={projectName} onChange={this.changeProjectName} autoFocus onBlur={this.endProjectNameEdit} /></h1>}
                            <h1 className="project-number">{project.project_id}</h1>
                        </div>
                        <div className="quote-name">
                            <TextInputCell useClickAwayListener={false} className="cell" name="quote-name" value={data.name== "New quote" ? this.tr(data.name) : data.name} onEdited={(name, value) => this.props.quoteDataChanged(data.id, 'name', value)} listCellProps={{inEditMode: editMode}} editable={editMode}/>
                        </div>
                        <div className="address-container">
                                <LabelFieldGroup
                                    title={this.getTranslation("To")}
                                    editMode={editMode}
                                    values={visitingAddressId !== undefined && visitingAddresses ? visitingAddresses.find(addr => addr.id === visitingAddressId) : {}}
                                    fields={[
                                        { label: this.tr("Company"), name: "name", value: data.editedAddress && data.editedAddress.name != "" ? data.editedAddress.name : undefined, onKeyUp: e => this.props.rowProps.editAddress(data.id, "name", e.target.value) },
                                        { label: this.tr("Address"), name: "address", 
                                            value: hasEditedAddress ? (data.editedAddress && data.editedAddress.address ? data.editedAddress.address : "") : visitingAddressId,
                                            editorType: hasEditedAddress ? undefined : CreatableSelect,
                                            options: visitingAddresses, 
                                            onChange: async address => {
                                                if (data.id > -1 && hasEditedAddress) {
                                                    return;
                                                } else {
                                                    let addressId = address.id;
                                                    if(address.__isNew__) {
                                                        let selectedVisitingAddress = visitingAddresses.find(va => va.id == quotes.find(quote => quote.id == data.id).address_id);
                                                        let addressData = {
                                                            id: -1,
                                                            name: (data.editedAddress && data.editedAddress?.name !== undefined) ? data.editedAddress.name : selectedVisitingAddress.name,
                                                            address: address.value,
                                                            postalcode: (data.editedAddress && data.editedAddress?.postalcode !== undefined) ? data.editedAddress.postalcode : selectedVisitingAddress.postalcode,
                                                            city: (data.editedAddress && data.editedAddress?.city !== undefined) ? data.editedAddress.city : selectedVisitingAddress.city,
                                                            state: (data.editedAddress && data.editedAddress?.state !== undefined) ? data.editedAddress.state : selectedVisitingAddress.state,
                                                            country: (data.editedAddress && data.editedAddress?.country !== undefined) ? data.editedAddress.country : selectedVisitingAddress.country,
                                                            vatid: (data.editedAddress && data.editedAddress?.vatid !== undefined) ? data.editedAddress.vatid : selectedVisitingAddress.vatid,
                                                        };
                                                        let response = await DataHandler.put({ url: `accounts/${project.customers_id}/visiting_addresses/${project.companies_id}` }, { visiting_addresses: [addressData] });
                                                        addressId = response[0].id;
                                                        setTimeout(() => this.props.getVisitingAddresses(project.customers_id, data.id, addressId),1100);
                                                        this.props.quoteDataChanged(data.id, 'address_id', addressId);
                                                        this.props.rowProps.editAddress(data.id, "name", addressData.name);
                                                        this.props.rowProps.editAddress(data.id, "address", addressData.address);
                                                        this.props.rowProps.editAddress(data.id, "postalcode", addressData.postalcode);
                                                        this.props.rowProps.editAddress(data.id, "city", addressData.city);
                                                        if (showState) {
                                                            this.props.rowProps.editAddress(data.id, "state", addressData.state);
                                                        }
                                                        this.props.rowProps.editAddress(data.id, "country", addressData.country);
                                                        this.props.rowProps.editAddress(data.id, "vatid", addressData.vatid);
                                                    } else {
                                                        if (addressId) {
                                                            this.props.quoteDataChanged(data.id, 'address_id', addressId);
                                                            this.props.rowProps.editAddress(data.id, "name", address.name);
                                                            this.props.rowProps.editAddress(data.id, "address", address.address);
                                                            this.props.rowProps.editAddress(data.id, "postalcode", address.postalcode);
                                                            this.props.rowProps.editAddress(data.id, "city", address.city);
                                                            if (showState) {
                                                                this.props.rowProps.editAddress(data.id, "state", address.state);
                                                            }
                                                            this.props.rowProps.editAddress(data.id, "country", address.country);
                                                            this.props.rowProps.editAddress(data.id, "vatid", address.vatid);
                                                        }
                                                    }
                                                }
                                            },
                                            onKeyUp: e => {
                                                this.props.rowProps.editAddress(data.id, "address", e.target.value)
                                            }
                                        },
                                        { label: this.tr("Zip code"), name: "postalcode", value: data.editedAddress && data.editedAddress.postalcode ? data.editedAddress.postalcode : "", onChange: e => this.props.rowProps.editAddress(data.id, "postalcode", e.target.value) },
                                        { label: this.tr("City"), name: "city", value: data.editedAddress && data.editedAddress.city ? data.editedAddress.city : "", onChange: e => this.props.rowProps.editAddress(data.id, "city", e.target.value) },
                                        showState && { label: this.tr("State"), name: "state", value: data.editedAddress && data.editedAddress.state ? data.editedAddress.state : "", onChange: e => this.props.rowProps.editAddress(data.id, "state", e.target.value) },
                                        { label: this.tr("Country"), name: "country", value: data.editedAddress && data.editedAddress.country ? data.editedAddress.country : "", onChange: e => this.props.rowProps.editAddress(data.id, "country", e.target.value) },
                                        { label: this.tr("Business ID"), name: "vatid", value: data.editedAddress && data.editedAddress.vatid ? data.editedAddress.vatid : "", onChange: e => this.props.rowProps.editAddress(data.id, "vatid", e.target.value) },
                                        !data.editedAddress?.custom_contact  && { label: this.tr("Contact"), name: "receiver_contact", 
                                            value: receiverContactId !== undefined && receiverContacts ? receiverContacts.find(contact => contact.id === receiverContactId) : undefined, 
                                            editorType:CreatableSelect, 
                                            options: receiverContacts, 
                                            onChange: value => {
                                                if (value.__isNew__ || !value.id) {
                                                    this.props.rowProps.editAddress(data.id, "custom_contact", value.label);
                                                }
                                                this.props.quoteDataChanged(data.id, 'receiver_contact_id', value.id);
                                                }
                                            },
                                            data.editedAddress?.custom_contact && { label: this.tr("Contact"), name: "receiver_contact", 
                                                value: data.editedAddress && data.editedAddress.custom_contact != undefined ? data.editedAddress.custom_contact : undefined, 
                                                onKeyUp: e => this.props.rowProps.editAddress(data.id, "custom_contact", e.target.value),
                                                onChange: e => {
                                                    if (e.target.value == "") {
                                                        this.props.rowProps.editAddress(data.id, "custom_contact", null);
                                                    }
                                                }
                                            },
                                            { label: this.tr("Phone"), name: "phone", value: data.editedAddress && data.editedAddress.phone != undefined ? data.editedAddress.phone : undefined, onKeyUp: e => this.props.rowProps.editAddress(data.id, "phone", e.target.value) },
                                            { label: this.tr("Email address"), name: "email", value: data.editedAddress && data.editedAddress.email != undefined ? data.editedAddress.email : undefined, onKeyUp: e => this.props.rowProps.editAddress(data.id, "email", e.target.value) }
                                        ]}
                                    />
                                    <LabelFieldGroup
                                        title={this.getTranslation("From")}
                                        editMode={editMode}
                                        values={companyAddress}
                                        fields={[
                                            { label: this.tr("From Company"), name: "name", value: undefined, editable: false },
                                            { label: this.tr("Address"), name: "address", value: undefined, editable: false },
                                            { label: this.tr("Zip code"), name: "postalcode", value: undefined, onKeyUp: e => this.props.rowProps.editCompanyAddress(data.id, "postalcode", e.target.value), editable: false },
                                            { label: this.tr("City"), name: "city", value: undefined, onKeyUp: e => this.props.rowProps.editCompanyAddress(data.id, "city", e.target.value), editable: false },
                                            showState && { label: this.tr("State"), name: "state", value: undefined, onKeyUp: e => this.props.rowProps.editCompanyAddress(data.id, "state", e.target.value), editable: false },
                                            { label: this.tr("Country"), name: "country", value: undefined, onKeyUp: e => this.props.rowProps.editCompanyAddress(data.id, "country", e.target.value), editable: false },
                                            { label: this.tr("Business ID"), name: "vatid", value: undefined, onKeyUp: e => this.props.rowProps.editCompanyAddress(data.id, "vatid", e.target.value), editable: false },
                                            { label: this.tr("Contact"), 
                                                name: "sender_contact", 
                                                value: selectedUser ? selectedUser : "", 
                                                showStringValue: !selectedUser.id, 
                                                editorType: Select, 
                                                className: "from-contact-name",
                                                options: companyContacts || [], 
                                                onChange: value => {this.props.quoteDataChanged(data.id, 'sender_contact_id', value.id);}
                                            },
                                            { label: this.tr("Phone"), name: "company_phone", value: data.company_phone != undefined && selectedUser ? data.company_phone : undefined, onKeyUp: e => this.props.quoteDataChanged(data.id, "company_phone", e.target.value) },
                                            { label: this.tr("Email address"), name: "company_email", value: data.company_email != undefined && selectedUser ? data.company_email : undefined, onKeyUp: e => this.props.quoteDataChanged(data.id, "company_email", e.target.value) }
                                        ]}
                                    />
                                    <LabelFieldGroup
                                        editMode={editMode}
                                        values={companyAddress}
                                        fields={[{
                                            label: this.getTranslation("Sent"), name: "sent_date", date: data.sent_date, 
                                            onInputChange: (name, date) => this.props.quoteDataChanged(data.id, 'sent_date', date),
                                            onChange: date => this.props.quoteDataChanged(data.id, 'sent_date', date), 
                                            editorType: DatePicker, 
                                            dateFormat: this.props.renderPrintQuote ? this.props.printDateFormat : this.context.userObject.dateFormat 
                                        },
                                        {   label: this.getTranslation("Valid until"), name: "valid_until", date: data.valid_until, 
                                            onInputChange: (name, date) => this.props.quoteDataChanged(data.id, 'valid_until', date),
                                            onChange: date => this.props.quoteDataChanged(data.id, 'valid_until', date), 
                                            editorType: DatePicker, 
                                            dateFormat: this.props.renderPrintQuote ? this.props.printDateFormat : this.context.userObject.dateFormat
                                        }]}
                                    />
                            </div>
                        </Header>

                        {editMode && (<Tooltip classes={{tooltip: 'darkblue-tooltip'}} title={this.tr("Show/hide columns")}>
                            <span className="hide-columns-options">
                                <span onClick={e => {
                                    this.setState({ 
                                        viewMenuAnchor: this.state.viewMenuOpen ? null : e.currentTarget, 
                                        viewMenuOpen: !this.state.viewMenuOpen
                                    })
                                }}>	
                                    <EyeIcon className="icon" />
                                    <ArrowDropDown className="small" />
                                </span>
                                <Popover
                                    open={this.state.viewMenuOpen}
                                    onClose={() => {
                                        this.setState({
                                            viewMenuAnchor: null,
                                            viewMenuOpen: false
                                        });
                                    }}
                                    anchorEl={this.state.viewMenuAnchor} 
                                    anchorOrigin={{ vertical: 45, horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    className="hide-columns-popover"
                                    style={{}}>
                                    <MUIList>
                                        {this.hideableColumns.map(row => {
                                            return (
                                                <MUIListItem className="muiListItem">
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={(e) => {
                                                            this.togglHiddenColumns(row.key);
                                                        }}
                                                        checked={this.props.data.print_exceptions && !this.props.data.print_exceptions.includes(row.key)}
                                                    />
                                                    <ListItemText primary={row.field} className="hide-columns-checkbox-text" />
                                                </MUIListItem>
                                            )})}
                                    </MUIList>
                                </Popover>
                            </span>
                        </Tooltip>)}

                        <ContentArea>
                            {/*<Button onClick={() => this.list.current.addNewRow({ _type: "quoteRow" })}>Add a normal row</Button>
                            <Button onClick={() => this.list.current.addNewRow({ _type: "descriptionRow" })}>Add a description row</Button>
                            <Button onClick={() => this.list.current.addNewRow({ _type: "headerRow" })}>Add a header row</Button>*/}
                            <div id="quote-list-wrapper">
                                <List
                                    fluid
                                    ref={this.list}
                                    data={quoteDataRows}
                                    rowHeight={26}
                                    noStateData={true}
                                    visibilityFilter={this.quoteRowVisibilityFilter}
                                    hideBottomMarker={false}
                                    onListMounted={this.onListMounted}
                                    controlOrder={true}
                                    parentKey="parentId"
                                    treeData={true}
                                    noColorVariance={true}
                                    reverseNewData={true}
                                    renderNewDataAtEnd={true}
                                    emptyNewDataOnUpdate={true}
                                    minimizeRerendersInTrees={true}
                                    onDataChange={this.onListDataChange}
                                    rowDragging={true}
                                    onDraggedRowDrop={this.onDraggedRowDrop}
                                    dragDropIsAllowed={(rowToDrop, onRow) => {
                                        if(rowToDrop.data.id === onRow.data.id) {
                                            return false;
                                        }

                                        const r                  = rowToDrop.data['_type'];
                                        const o                  = onRow.data['_type'];
                                        const onNormalRow        = o === "quoteRow" || o === "descriptionRow" || o === "productRow" || o === "cpqRow";
                                        const droppedIsNormalRow = r === "quoteRow" || r === "descriptionRow" || r === "productRow" || r === "cpqRow";
                                        const droppedIsHeaderRow = r === "headerRow" || r === "cpqHeaderRow";

                                        return (
                                            (onRow.data.id === "BOTTOM_MARKER" && droppedIsHeaderRow)
                                            ||
                                            (onNormalRow && droppedIsNormalRow) 
                                            || 
                                            ((o === "headerRow" || o === "cpqHeaderRow") && droppedIsHeaderRow)
                                            ||
                                            (onRow.lastInGroup && r !== "headerRow" && r !== "cpqHeaderRow")
                                        );
                                    }} 
                                    columnHeaderConfigKey="quoteRow"
                                    className="quote-list"
                                    userListSettingsKey="quote_list"
                                    rowProps={rowProps}
                                    ignoreRowPropsChange={false}
                                    showPageSelector={false}
                                    newRowMap={{
                                        headerRow: {
                                            text: this.getTranslation("Header"),
                                            hidden_for_print: 0
                                        },
                                        descriptionRow: {
                                            description: this.getTranslation("Description"),
                                            hidden_for_print: 0
                                        },
                                        quoteRow: {
                                            name: this.getTranslation("Row"),
                                            quantity: defaultQuantity,
                                            quantityType: 1,  // Unit.
                                            cost: 0.00,
                                            value: 0.00, // Selling price.
                                            vat: defaultVAT || 0,
                                            vatTotal: 0,
                                            discountPercentage: 0,
                                            sum: 0,
                                            margin: 0,
                                            marginPercentage: 0,
                                            workType: 1,
                                            type: 1,
                                            has_automatic_targeting: 0,
                                            hidden_for_print: 0
                                        },
                                        productRow: {
                                            product_id: undefined,
                                            quantity: defaultQuantity,
                                            quantityType: undefined, 
                                            cost: 0.00,
                                            value: 0.00,
                                            vat: defaultVAT || 0,
                                            vatTotal: 0,
                                            discountPercentage: 0,
                                            sum: 0,
                                            margin: 0,
                                            marginPercentage: 0,
                                            workType: 4,
                                            type: 3,
                                            has_automatic_targeting: 0,
                                            hidden_for_print: 0
                                        },
                                        cpqRow: {
                                            cpqId: undefined,
                                            // description: "", // Isn't needed?
                                            quantity: defaultQuantity,
                                            quantityType: undefined, 
                                            cost: 0.00,
                                            value: 0.00,
                                            vat: defaultVAT || 0,
                                            vatTotal: 0,
                                            discountPercentage: 0,
                                            sum: 0,
                                            margin: 0,
                                            marginPercentage: 0,
                                            workType: 3,
                                            type: 4,
                                            has_automatic_targeting: 0,
                                            hidden_for_print: 0
                                        },
                                        cpqHeaderRow: {
                                            cpqParentId: undefined,
                                            parentChosen: false,
                                            hidden_for_print: 0
                                        }
                                    }}
                                    listRowTypeKey="_type"
                                    listRowTypeMap={{
                                        headerRow: QuoteHeaderRow,
                                        descriptionRow: QuoteDescriptionRow,
                                        quoteRow: QuoteRow,
                                        productRow: QuoteRow, // Exactly the same component as QuoteRow, but with Product chosen as the initial worktype.
                                        cpqRow: QuoteRow,
                                        cpqHeaderRow: QuoteHeaderRow
                                    }}
                                    columns={{
                                        headerRow:
                                            [
                                                { name: "context", width: 10 },
                                                { name: "move", width: 10 },
                                                { name: "text", width: headerWidth, className: this.getClassName("name") },
                                            ],
                                        descriptionRow: 
                                            [
                                                { name: "context", width: 10 },
                                                { name: "move",  width: 10 },
                                                { name: "description", width: headerWidth, className: this.getClassName("name") },
                                            ],
                                        quoteRow:
                                            [
                                                { field: "context", name: "context", header: "", width: 10, showMenu: false, resizeable: false, moveable: false, hideable: false },
                                                { field: "createTask", name: "createTask", header: "", width: 10, showMenu: false, resizeable: false, moveable: false, hideable: false },
                                                { field: "name", name: "name", header: this.getTranslation("Rows"), width: 110, showMenu: false, resizeable: false, moveable: false, hideable: false, className: this.getClassName("name") },
                                                { field: "quantity", name: "quantity", header: this.getTranslation("Quantity"), width: 25, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("quantity") },
                                                { field: "quantityType", name: "quantityType", header: this.getTranslation("Unit"), width: 30, showMenu: false, resizeable: false, moveable: false, hideable: false, className: `quantityType ${this.getClassName("quantityType")}` },
                                                { field: "cost", name: "cost", header: this.getTranslation("Unit cost"), width: 35, showMenu: false,resizeable: false,  moveable: false, hideable: false, alignRight: true, className: this.getClassName("cost") },
                                                { field: "value", name: "value", header: this.getTranslation("Selling price"), width: 35, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("value") },
                                                { field: "vat", name: "vat", header: this.getTranslation("VAT %"), width: 20, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("vat") },
                                                { field: "vatTotal", name: "vatTotal", header: this.getTranslation("VAT Total"), width: 35, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("vatTotal") },
                                                { field: "discountPercentage", header: this.getTranslation("Discount %"), name: "discountPercentage", width: 30, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("discountPercentage") },
                                                { field: "sum", name: "sum", header: this.getTranslation("Sum 0%"), width: 35, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("sum") },
                                                { field: "margin", name: "margin", header: this.getTranslation("Margin 0%"), width: 35, showMenu: false, resizeable: false, moveable: false, hideable: false, alignRight: true, className: this.getClassName("margin") },
                                                { field: "workType", name: "workType", header: this.getTranslation("Type of work"), width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, alignment: "center", className: this.getClassName("workType") },
                                                // ? [{ field: "jobtypes_id", name: "jobtypes_id", header: this.tr("Jobtype"), width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, alignment: "center", className: this.getClassName("jobtypes_id")}] : [],
                                            ],
                                        productRow:
                                            [
                                                { name: "context", width: 10 },
                                                { name: "createTask", width: 10 },
                                                { name: "name", width: 110, className: this.getClassName("name") },
                                                { name: "quantity", width: 25, alignRight: true, className: this.getClassName("quantity") },
                                                { name: "quantityType", width: 30, className: `quantityType ${this.getClassName("quantityType")}` },
                                                { name: "cost", width: 35, alignRight: true, className: this.getClassName("cost") },
                                                { name: "value", width: 35, alignRight: true, className: this.getClassName("value") },
                                                { name: "vat", width: 20, alignRight: true, className: this.getClassName("vat") },
                                                { name: "vatTotal", width: 35, alignRight: true, className: this.getClassName("vatTotal") },
                                                { name: "discountPercentage", width: 30, alignRight: true, className: this.getClassName("discountPercentage") },
                                                { name: "sum", width: 35, alignRight: true, className: this.getClassName("sum") },
                                                { name: "margin", width: 35, alignRight: true, className: this.getClassName("margin") },
                                                { name: "workType", width: 50, alignment: "center", className: this.getClassName("workType") },
                                                //... this.context.addons.nav ? [{ field: "jobtypes_id", name: "jobtypes_id", header: this.tr("Jobtype"), width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, alignment: "center", className: this.getClassName("jobtypes_id")}] : [],
                                            ],
                                        cpqRow:
                                            [
                                                { name: "context", width: 10 },
                                                { name: "createTask", width: 10 },
                                                // { name: "move", width: 10 },
                                                { name: "name", width: 110, className: this.getClassName("name") },
                                                { name: "quantity", width: 25, className: this.getClassName("quantity") },
                                                { name: "quantityType", width: 30, className: `quantityType ${this.getClassName("quantityType")}` },
                                                { name: "cost", width: 35, className: this.getClassName("cost") },
                                                { name: "value", width: 35, className: this.getClassName("value") },
                                                { name: "vat", width: 20, className: this.getClassName("vat") },
                                                { name: "vatTotal", width: 35, className: this.getClassName("vatTotal") },
                                                { name: "discountPercentage", width: 30, className: this.getClassName("discountPercentage") },
                                                { name: "sum", width: 35, className: this.getClassName("sum") },
                                                { name: "margin", width: 35, className: this.getClassName("margin") },
                                                { name: "workType", width: 50, className: this.getClassName("workType") },
                                                //... this.context.addons.nav ? [{ field: "jobtypes_id", name: "jobtypes_id", header: this.tr("Jobtype"), width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, alignment: "center", className: this.getClassName("jobtypes_id")}] : [],
                                            ],
                                        cpqHeaderRow:
                                            [
                                                { name: "context",  width: 10 },
                                                { name: "move",  width: 10 },
                                                { name: "text", width: headerWidth, className: this.getClassName("name") },
                                            ]
                                    }}
                                />
                            </div>
                        </ContentArea>
                    </div>
                    <Footer>
                        <FlexChild weight={5} style={{ paddingRight: "16px" }}>
                        </FlexChild>
                        <FlexChild weight={3}>
                            <Totals className={this.getClassName("quoteTotal")} currency={currency} rows={totalRows} />
                        </FlexChild>
                    </Footer>
                </div>
            </Paper>
        )
    }
}

Quote.defaultProps = {
	printMode: false,
    onListDataChange: () => {}
};

export default Quote;
