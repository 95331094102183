import React, { Component } from 'react';
import TaimerComponent from '../TaimerComponent';
import { withSnackbar } from 'notistack';

import _ from "lodash";

import DialogBorder from './DialogBorder';
import DialogSection from './DialogSection';

import { SettingsContext } from '../SettingsContext';
import DataHandler from '../general/DataHandler';
import DataList from './../general/DataList';
import Checkbox from "./../general/Checkbox";
import OutlinedField from "../general/OutlinedField";
import ProjectTreeDropdown from "../projects/ProjectTreeDropdown";

import './CopyQuoteDialog.css';

class _CopyQuoteDialog extends TaimerComponent {

    static contextType = SettingsContext;

	constructor(props, context) {
		super(props, context, "dialogs/CopyQuoteDialog");

        const { userObject } = this.context;

        this.state = {
            companies: [],
            quotes: [],
            selectedCompany: undefined,
            selectedProject: props.data.project || undefined,
            selectedQuote: undefined,
            active: true,
            searchLocked: false,
            deactivateCurrent: true
        };

    }

    componentDidMount() {
        super.componentDidMount();
        this.updateCompanyData();
    }

    updateCompanyData = async () => {

        let company = {};
        let companies = await DataHandler.get({url: 'subjects/companies/projects/project_cost_estimate_read'});

        companies = companies.map(el => {
            el.label = el.name;
            return el;
        });

        if(companies.length > 0)
            company = companies.find(el => el.id == this.state.selectedCompany) || companies[0];

        this.setState({
            companies: companies,
            selectedCompany: company
        });
    }

    updateQuoteData = async (project) => {

        this.setState({quotes: await DataHandler.get({url: 'projects/allquotes', project: project})});

    };


    copy = () => {

        const { selectedQuote, active, deactivateCurrent} = this.state;

        if (!selectedQuote) {
            this.props.enqueueSnackbar(this.tr("Please select a quote to copy."), {
                variant: 'error'
            });
            return;
        }

        this.props.data.handleCopyResult(selectedQuote.id, active ? 1 : 0, 0, '', deactivateCurrent ? 1 : 0);
        this.props.onDialogClose();            
    }

    render() { 
        const { tr } = this;
        const { selectedCompany,selectedProject, selectedQuote, companies } = this.state;

        return (
            <DialogBorder
                id="CopyQuoteDialog"
                title={this.tr("Select and get sales quote")}
                onClose={this.props.onDialogClose}
                className="TMRDialogGrid TMRDialogInputs"
                rightButtons={
                    <div className={"TMRDialogButton blue " + ( (this.state.saving || !this.state.selectedQuote) ? 'disabled' : '')} onClick={this.copy} >{this.tr("Copy")}</div>
                }>
                <DialogSection >

                    {companies.length > 1 && <DataList
                        label={this.tr("Company")}
                        name="company"
                        className="full"
                        options={companies}
                        onChange={(value) => {
                            this.setState({selectedCompany: value, selectedQuote: undefined});
                        }}
                        value={selectedCompany}
                    />}

                    {selectedCompany && <ProjectTreeDropdown
                        label={this.tr("Project")}
                        value={selectedProject}
                        disabled={!selectedCompany}
                        noDisabledValues={this.state.searchLocked}
                        queryParameters={{ right: 'write', company: selectedCompany.id, includeLocked: this.state.searchLocked }}
                        treeDropdownProps={{
                            activateBestMatch: true,
                            highlightMatches: true,
                        }}
                        onSelect={e => {
                            const { data } = e;
                            data && this.setState({ selectedProject: data.id }, () => data.id && this.updateQuoteData(data.id));
                        }}
                    />}
                    <Checkbox
                        name="searchLocked"
                        checked={this.state.searchLocked}
                        onChange={() => this.setState({searchLocked: !this.state.searchLocked})}
                        label={this.tr("Include closed projects")}
                    />  
                    <DataList
                        label={this.tr("Quote")}
                        name="quote"
                        className="full"
                        options={this.state.quotes}
                        onChange={(value) => this.setState({selectedQuote: value}) }
                        value={selectedQuote}
                    />
                    <Checkbox
                        name="active"
                        checked={this.state.active}
                        onChange={() => this.setState({active: !this.state.active})}
                        label={this.tr("Activate quote upon creation")}
                    />
                    <Checkbox
                        className="full"
                        name="deactivateCurrent"
                        checked={this.state.deactivateCurrent}
                        onChange={() => this.setState({deactivateCurrent: !this.state.deactivateCurrent})}
                        label={this.tr("Deactivate source quote")}
                    />                    
                </DialogSection>
            </DialogBorder>
        )
    }
}

export const CopyQuoteDialog = withSnackbar(_CopyQuoteDialog);
export class CopySimpleQuoteDialog extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "dialogs/CopyQuoteDialog");

        const { userObject } = this.context;

        this.state = {
            selectedQuote: props.data.quote,
            quoteName: props.data.quoteName,
            active: true,
            targeting: false,
            translatedString: ` (${this.tr('Copy')})`,
            deactivateCurrent: true
        };

    }

    copy = () => {

        if (!this.state.quoteName)
            return;

        const { selectedQuote, active, targeting, quoteName, translatedString, deactivateCurrent} = this.state;

        this.props.data.handleCopyResult(selectedQuote, active ? 1 : 0, targeting ? 1 : 0, (translatedString == '' ? quoteName : quoteName + translatedString), deactivateCurrent ? 1 : 0);
        this.props.onDialogClose();            
    }

    render() { 
        const { tr } = this;
        const { selectedQuote, quoteName, translatedString } = this.state;

        return (
            <DialogBorder
                id="CopySimpleQuoteDialog"
                title={this.tr("Name dublicate and define settings")}
                onClose={this.props.onDialogClose}
                className="TMRDialogGrid TMRDialogInputs"
                rightButtons={
                    <div data-testid="copy_button" className={"TMRDialogButton blue " + ( (this.state.saving || !this.state.selectedQuote) ? 'disabled' : '')} onClick={this.copy} >{this.tr("Copy")}</div>
                }>
                <DialogSection >

                    <OutlinedField
                        className="full"
                        error={!this.state.quoteName}
                        label={this.tr("Quote name")}
                        name="name"
                        value={quoteName + translatedString}
                        onChange={e => this.setState({quoteName: e.target.value, translatedString: ''})}
                    />

                    <Checkbox
                        className="full"
                        name="active"
                        checked={this.state.active}
                        onChange={() => this.setState({active: !this.state.active})}
                        label={this.tr("Activate quote upon creation")}
                    />

                    <Checkbox
                        className="full"
                        name="deactivateCurrent"
                        checked={this.state.deactivateCurrent}
                        onChange={() => this.setState({deactivateCurrent: !this.state.deactivateCurrent})}
                        label={this.tr("Deactivate current quote")}
                    />

                    <Checkbox
                        className={`full ${!this.state.quoteName ? 'disabled' : ''}`}
                        name="targeting"
                        checked={this.state.targeting}
                        onChange={() => this.setState({targeting: !this.state.targeting})}
                        label={this.tr("Move cost targeting from current version")}
                    /> 
                </DialogSection>
            </DialogBorder>
        )
    }
}