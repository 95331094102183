import React from 'react';
import ListRow from "./ListRow";
import Utils from "../general/Utils.js";
import ListCell from "./ListCell";
import FocusGroup from "../general/FocusGroup";
import isEqual from "lodash/isEqual"; 
import cloneDeep from "lodash/cloneDeep";
import TaimerComponent from "../TaimerComponent";


class PropsOnlyListRow extends ListRow {
    static defaultProps = {
        ...ListRow.defaultProps,
        flip: undefined, // Controlled by List.
        noStateData: true,
        usesState: false,
        manualCreate: false,
        onEdit: undefined
    };


    constructor(props, state = {}, conf = {}, namespace) {
        super(props, state, conf, namespace);

        this.setDataAndUpdate  = this.setDataAndUpdate.bind(this);
        this.editDataAndUpdate = this.editDataAndUpdate.bind(this);
        this.editData          = this.editData.bind(this);
        this.removeFromList    = this.removeFromList.bind(this)
    }


    getData() {
        return this.props.data;
    }


    setDataAndUpdate(a, b = undefined) {
        const data       = this.setData(a, b);
        const intId      = Number(data[this.props.rowKey]);
        const intIdIsNaN = isNaN(intId);

        if(!intIdIsNaN && intId < 0 && !this.props.manualCreate) {
            return this.create(data);
        } else if(intIdIsNaN || intId > 0) {
            return this.update(data);
        }
    }


    editDataAndUpdate(...params) {
        return this.setDataAndUpdate(...params); 
    }


    // Override.
    setData(a, v = undefined) {
        let data       = cloneDeep(this.props.data);
        let originalId = undefined;

        if(this.props.idType === "number") {
            originalId = Number(data[this.props.rowKey]);
        } else if(this.props.idType === "string") {
            originalId = String(data[this.props.rowKey]);        
        }

        if(typeof a === "object") {
            for(let i in a)
                data[i] = a[i];
        } else if(typeof a === "string") {
            data[a] = v;
        } else if(typeof a === "function") {
            data = a(data);
        }

        if(typeof(this.props.onEdit) === "function") {
             this.props.onEdit(data, originalId);
        } else {
            // Edits the data that was passed to List as the data prop originally,
            // then re-renders, causing ListRows to reflect the edited data.
            // (Relies on all rows having an id; List should take care of this in the case of new rows.)
            this.props.listRef.editData(data, data[this.props.rowKey] !== originalId ? originalId : undefined);
        }


        // If the row has already been saved, call the onUpdate rowProp, 
        // which gives the dev a possibility to send the edited data to the backend
        // and then refresh the List's data prop.
        return data;
    }


    editData(...params) {
        return this.setData(...params); 
    }


    removeFromList() {
        if(this.props.listRef === undefined) {
            console.warn("props.listRef === undefined"); 
            return;
        }
        
        this.props.listRef.removeRow(this.props.data[this.props.rowKey]);
    }

    setCells = (props) => {
        this.cells = this.defineCells(props);
    }


    shouldComponentUpdate(nextProps, nextState) {
        const { 
            data, 
            flips,
            rowKey,
            virtualized,
            virtualizationStartColumn, 
            virtualizationColumnAmount 
        } = this.props;

        if(virtualized && (this.props.top !== nextProps.top || this.currentTop !== nextProps.top)) {
            this.moveRow(nextProps.top);
        }

        const flipped    = flips[data[rowKey]] !== nextProps.flips[data[rowKey]];
        const rowChanged = data[rowKey] !== nextProps.data[rowKey];

        if(virtualized && (flipped || rowChanged)) {
            // The cells have to be defined with the data
            // of the next row here, so that changes have
            // been made for the next render.

            this.setCells(nextProps);
        }

        return (virtualized && rowChanged) 
            || (virtualized && (
                virtualizationStartColumn !== nextProps.virtualizationStartColumn 
                || virtualizationColumnAmount !== nextProps.virtualizationColumnAmount 
            ))
            || flipped
            || (this.props.usesState && !isEqual(this.state, nextState))
    }


    // Overwrite ListRow's componentDidUpdate, so redundant setStates won't happen.
    componentDidUpdate(prevProps, prevState) {
        return;
    }


    isChecked() {
		return this.props.checked;
	}


	delete() {
        return this.getRowCallback("onDelete")(this.props.data, this, this.props.listRef, this.props.attributes);
	}


	update(data = false) {
		return this.getRowCallback("onUpdate")(data !== false ? data : this.props.data, this, this.props.listRef, this.props.attributes);
	}


	create(data = false) {
        return this.getRowCallback("onCreate")(data !== false ? data : this.props.data, this, this.props.listRef, this.props.attributes);
	}
}

export default PropsOnlyListRow;
