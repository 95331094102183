import MoreHoriz from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import validator from 'validator';
import ContextMenu from '../../general/ContextMenu';
import { ReactComponent as DeactivateIcon } from '../../general/icons/Deactivate.svg';
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import { ReactComponent as ResetPasswordIcon } from '../../general/icons/ResetPassword.svg';
import { ReactComponent as ViewIcon } from '../../general/icons/view.svg';
import CheckboxCell from "../cells/CheckboxCell";
import TextInputCell from "../cells/TextInputCell";
import ListCell from "../ListCell";
import PropsOnlyListRow from "../PropsOnlyListRow";
import { SettingsContext } from './../../SettingsContext';

import LinkListCell from '../LinkListCell';

class UserListRow extends PropsOnlyListRow {
	static contextType = SettingsContext;

	constructor(props) {
		super(props, { checked: false }, undefined, "list/rows/UserListRow");

		this.cellEdited = this.cellEdited.bind(this);
		// this.check 		= this.check.bind(this);
	}
	
	cellEdited(name, value) {
        this.setDataAndUpdate(name, value);
	}

	validateField(name, value, validation) {	
		if ((validation === "empty" || validation === "email") && (!value || value.trim() === "")) {
			this.showFieldError(name, value, name.charAt(0).toUpperCase() + name.slice(1) + " cannot be empty!")
			return false;
		}

		if (validation === "email" && !validator.isEmail(value)) {
			this.showFieldError(name, value, "Invalid email!")
			return false;
		}

		const data = {};
		data["_invalidField_" + name] = false; 
		data[name] = value;
		this.setDataAndUpdate(data);	
	}

	showFieldError(name, value, message) {
		const data = {};
		this.props.rowProps.enqueueSnackbar(this.tr(message), {
			variant: 'error'
		});
			
		data["_invalidField_" + name] = value.trim(); 
		this.setData(data);
	}

	defineCells() {
		const { privileges, userObject, functions: { updateView, checkPrivilege, hasPrivilege } } = this.context;
		const writePrivileges  	= (privileges.admin && privileges.admin.admin) || (privileges.persons && privileges.persons.write);
		const className 	   	= ["userListRow listElement row", this.props.hidden ? "hidden" : "", this.props.data['id'] < 0 ? "new" : ""].join(" ");
		const modifier		   	= this.props.sharedData['modified_by'].find(mb => this.props.data['modified_by'] === mb.id);
		const team  	   	   	   	= this.props.sharedData['team'].find(t => t.id === this.props.data['team']);
		const company 			= this.props.data.companies_id;

		const cells = {
			expand: 
				(company > 0 && checkPrivilege("admin", "admin", company) || company == 0 && hasPrivilege("admin", "admin")) ? (<ContextMenu label={<MoreHoriz />} buttonProps={{className: 'action-menu'}} className="cell row-menu" style={{width: this.props.columnWidthMap['expand'] + "px", textAlign: "center"}} noExpandIcon>
						<MenuItem onClick={() => updateView({ 'module': 'users', 'action': 'view', 'id': this.props.data['id'], ...this.props.rowProps.returnProps, company })} >
							<ViewIcon />{this.tr("Open")}
						</MenuItem>
                        <MenuItem
                            onClick={() => {
                                this.props.rowProps.lockUser(this.props.data);
                            }}
                        >
                            <DeactivateIcon />
                            {this.tr('Dectivate')}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                this.props.rowProps.sendPassword({firstname: this.props.data.firstname, lastname: this.props.data.lastname, id: this.props.data.id});
                            }}
                        >
                            <ResetPasswordIcon />
                            {this.tr('Reset password')}
                        </MenuItem>
                    {this.props.data.deletable > 0 && (
                        <MenuItem
                            className="delete"
                            onClick={() => {
								this.props.rowProps.onDelete(this.props.data)
                            }}
                        >
                            <RemoveIcon className="Delete" />
                            {this.tr('Delete')}
                        </MenuItem>
                    )}
				</ContextMenu>) : <ListCell width={this.props.columnWidthMap['expand']} onlyDisplay editable={false} />,
			checked:
				<CheckboxCell checked={this.props.checked} width={this.props.columnWidthMap['checked']} onClick={() => this.props.listRef.check(this.props.data.id)} />,
			profile_img:
				<ListCell width={this.props.columnWidthMap['project_id']} value={parseInt(this.props.data['id']) < 0 ? "NEW" : this.props.data['id']} editable={false} />,
			lastname:
				<TextInputCell
				listCellType={LinkListCell}
					listCellProps={{
						urlHandler: value => `index.html?module=users&action=view&id=${this.props.data.id}&company=${company}`,
						editable: true,
						asText: !((company > 0 && checkPrivilege("admin", "admin", company) || company == 0 && hasPrivilege("admin", "admin"))),
						noTab: true,
						inEditMode: this.props.data.id < 0,
						showErrorBorder: this.props.data['_invalidField_lastname'] === ""
					}}
					width={this.props.columnWidthMap['lastname']}
					name="lastname"
					value={this.props.data['_invalidField_lastname'] === "" ? "" : this.props.data['lastname']}
					onEdited={writePrivileges ? (name, value) => this.validateField(name, value, "empty") : undefined} />,
			firstname:
				<TextInputCell
					listCellType={LinkListCell}
					listCellProps={{
						urlHandler: value => `index.html?module=users&action=view&id=${this.props.data.id}&company=${company}`,
						editable: true,
						asText: !((company > 0 && checkPrivilege("admin", "admin", company) || company == 0 && hasPrivilege("admin", "admin"))),
						noTab: true,
						inEditMode: this.props.data.id < 0,
						showErrorBorder: this.props.data['_invalidField_firstname'] === ""
					}}
					width={this.props.columnWidthMap['firstname']}
					name="firstname"
					value={this.props.data['_invalidField_firstname'] === "" ? "" : this.props.data['firstname']}
					onEdited={writePrivileges ? (name, value) => this.validateField(name, value, "empty") : undefined} />,
			
			title:
			<TextInputCell
				listCellProps={{
					inEditMode: this.props.data.id < 0
				}}
				width={this.props.columnWidthMap['title']}
				name="title"
				value={this.props.data['title']}
				onEdited={writePrivileges ? this.cellEdited : undefined} />,
            position:
                <TextInputCell
                	listCellProps={{
						inEditMode: this.props.data.id < 0
					}}
			   		width={this.props.columnWidthMap['position']}
					name="position"
			   		value={this.props.data['position']}
			   		onEdited={writePrivileges ? this.cellEdited : undefined} />,
			company: 
				<ListCell width={this.props.columnWidthMap['company']} value={this.props.data['company']} editable={false} />,
			email:
				<TextInputCell 
					listCellProps={{
						inEditMode: this.props.data.id < 0,
						showErrorBorder: this.props.data['_invalidField_email'] || this.props.data['_invalidField_email'] === ""
					}}
					width={this.props.columnWidthMap['email']} 
					name="email"
					value={(this.props.data['_invalidField_email'] || this.props.data['_invalidField_email'] === "") ? this.props.data['_invalidField_email'] : this.props.data['email']}
					onEdited={writePrivileges ? (name, value) => this.validateField(name, value, "email") : undefined} />,
			phone:
				<TextInputCell 
					listCellProps={{
						inEditMode: this.props.data.id < 0
					}}
					width={this.props.columnWidthMap['phone']}
					name="phone" 
					value={this.props.data['phone']} 
					onEdited={writePrivileges ? this.cellEdited : undefined} />,
			team:
				<ListCell style={{width: this.props.columnWidthMap['team'] + 'px'}} width={this.props.columnWidthMap['team']} value={this.props.data['team']} editable={false} />,
			netvisor_id: 
				<TextInputCell 
					listCellProps={{
						inEditMode: this.props.data.id < 0,
						showTooltipForOverflownText: true 
					}}
					name="netvisor_id" 
					style={{ width: this.props.columnWidthMap['netvisor_id'] + 'px' }} 
					width={this.props.columnWidthMap['netvisor_id']} 
					value={this.props.data['netvisor_id']} 
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={writePrivileges} />,

				/*
			activated:
				<DateCell width={this.props.columnWidthMap['activated']} value={this.props.data['activated_date']} editable={false} />,
			deactivated:
				<DateCell width={this.props.columnWidthMap['deactivated']} value={this.props.data['deleted_date']} editable={false} />,
			edited_by:
				<ListCell width={this.props.columnWidthMap['edited_by']} value={modifier} editable={false} />,		
			*/	
			}

        return cells;
			
		// const childRows = this.createChildren(this.props.children, UserListRow);
// 
		// return (
			// <div className="listElement mainLevel">
				// <div style={{height:"44px", lineHeight:"44px"}} className={className}>
					// <FocusGroup 
						// focusOnInit={this.props.data.id < 0}
						// onCtrlS={() => {
							// // On the user list data.id can never be < 1.
							// this.cellEdited();
						// }}>
						// {this.props.columnOrder.map(columnName => cells[columnName])}
					// </FocusGroup>
				// </div>
				// {childRows !== false && <div className="listElement">{childRows}</div>}
			// </div>
		// );	
	}
}

export default UserListRow;
