import React from "react";
import { SettingsContext } from '../SettingsContext';
import TaimerComponent from "../TaimerComponent";
import "./ErrorView.css";
import { ReactComponent as ErrorGraphig } from "./images/error_graphig.svg";

/*material ui */
import Button from "@mui/material/Button";
import Link from "../general/Link";

class ErrorView extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "error/ErrorView");
        this.urlHandler = this.urlHandler.bind(this);
    }

    goBack(e, lastView) {
        this.props.clearError && this.props.clearError();
        if (!e.ctrlKey && !e.metaKey && this.props.updateView) {
            e.preventDefault();
            this.props.updateView(lastView);
        }
    }

    openIntercom = (e) => {
        e.preventDefault();
        window.Intercom("show");
    }

    urlHandler(view) {
        let url = "index.html?";
        let first = true;

        Object.keys(view).forEach((key) => {
            if (first) url += key + "=" + view[key];
            else url += "&" + key + "=" + view[key];

            first = false;
        });

        return url;
    }

    render() {
        const lastView = localStorage.lastView ? JSON.parse(localStorage.lastView) : undefined;
        const className = this.props.updateView ? "padding-top" : "no-padding-top";

        return (
            <React.Fragment>
                <div id="error-view">
                    <div className={`center-wrapper ${className}`}>
                        <ErrorGraphig />
                        {this.props.errorTime &&
                            <p className="error-info"> <span className="error-red">{this.tr("Error timestamp")}:</span> {this.props.errorTime}</p>
                        }
                        <h1>{this.tr("Oops! Something went wrong")}</h1>
                            <Button className={"try-again-button"} onClick={() => this.props.clearError()} size="large">{this.tr("Try again").toUpperCase()}</Button>
                            <br />
                        {lastView && (
                            <React.Fragment>
                                <Link url={this.context && this.context.functions && this.context.functions.urlify ? this.context.functions.urlify(lastView) : this.urlHandler(lastView)} onClick={(e) => this.goBack(e, lastView)}>{this.tr("Go back to the previous page")}</Link>
                                {" "}-{" "}
                            </React.Fragment>
                        )}
                        <Link onClick={(e) => this.openIntercom(e)}>{this.tr("Help Center")}</Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ErrorView;
