import React from "react";

import { SettingsContext } from "../../../SettingsContext";
import TaimerComponent from "../../../TaimerComponent";

import styles from './InvoiceMaterialBlock.module.scss';
import BlockBase from "../BlockBase";
import { BlockProps } from "../../base/CustomViewBase";
import InvoiceMaterialBlockRow from "./InvoiceMaterialBlockRow";
import InvoiceMaterialBlockSummaryRow from "./InvoiceMaterialBlockSummaryRow";
import List from "../../../list/List";
import colors from "../../../colors";
import InsightDropDown from "../../../dashboard/insights/InsightDropDown";
import DataHandler from '../../../general/DataHandler';
import { CheckBox, LegendToggle } from "@mui/icons-material";
import { ColumnHeaderButton } from '../../../list/ListHeader';
import { InvoiceMaterialTypeFilterName } from "../../../projects/ProjectStatistics";
import FieldEditSlider from '../../../general/FieldEditSlider';
import { MenuItem, Popover, MenuList } from '@mui/material';
import { format } from "date-fns";
import { sortBy } from "lodash";

export interface InvoiceMaterial {
    id: string;
    key: InvoiceMaterialTypeFilterName;
    selected: boolean;
    creation_date: string;
    row_type: string;
    user_or_product: string;
    description: string;
    quantity: number;
    value: number;
    total: number;
    until?: string;
    vat?: number;
    projects_id?: string | number;
}

interface Props {
    blockProps: BlockProps;
    materialTypeFilters: InvoiceMaterialTypeFilterName[];
    onSelectInvoiceMaterialListFilter: (filter: InvoiceMaterialTypeFilterName | InvoiceMaterialTypeFilterName[]) => void;
    //onSelectInvoiceMaterialListFilter: (filter: InvoiceMaterialTypeFilterName) => void;
    onCheckedInvoiceMaterial?: (ids: string[]) => void;
    createInvoiceFromSelectedMaterial: (projectsId?: string | number) => void;
    invoiceMaterial: InvoiceMaterial[];
    currency: string;
    project: any;
}

interface State {
    editSliderData?: any;
    products?: any;
    cpqs?: any;
    addMenuAnchor?: any;
    sortField: string;
    sortAscending: boolean;
}

export default class InvoiceMaterialBlock extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    columns = [
        { field: "context", name: "context", header: "", columnHeaderType: "roundButton", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false, visibleInToolbar: true, sortable: false },
        { field: "checked", name: "checked", header: "", columnHeaderType: "checkbox", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false, visibleInToolbar: true, sortable: false },
        { field: "row_type", name: "row_type", header: this.tr("Type"), width: 150, showMenu: true, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, sortable: true },
        { field: "creation_date", name: "creation_date", header: this.tr("Created"), width: 80, showMenu: true, resizeable: true, moveable: false, hideable: true, showResizeMarker: true, sortable: true },
        { field: "user_or_product", name: "user_or_product", header: this.tr("User / Product"), width: 180, showMenu: true, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, sortable: true },
        { field: "description", name: "description", header: this.tr("Description"), width: 180, showMenu: true, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, sortable: true },
        { field: "quantity", name: "quantity", header: this.tr("Quantity"), width: 80, showMenu: true, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, sortable: true },
        { field: "vat", name: "vat", header: this.tr("vat"), width: 100, showMenu: true, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, sortable: true },
        { field: "value", name: "value", header: this.tr("Price"), width: 100, showMenu: true, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, sortable: true },
        { field: "total", name: "total", header: this.tr("Total"), width: 100, showMenu: true, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, sortable: true },
        { field: "total_vat", name: "total_vat", header: this.tr("Total incl. vat"), width: 100, showMenu: true, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, sortable: true },
    ];

    materialTypes: { key: InvoiceMaterialTypeFilterName, id: string, name: string, label: string, value: string, color: string }[] = [
        { key: "hour_entry", id: '1', name: this.tr('Hour entry'), label: this.tr('Hour entry'), value: '1', color: colors.greenish_cyan },
        { key: "scheduled_invoice", id: '2', name: this.tr('Scheduled invoice'), label: this.tr('Scheduled invoice'), value: '2', color: "#f52b2b" },
        { key: "expense", id: '4', name: this.tr('Expense'), label: this.tr('Expense'), value: '4', color: "#ffaf0f" },
        { key: "travel_expense", id: '8', name: this.tr('Travel expense'), label: this.tr('Travel expense'), value: '8', color: "#ffaf0f" },
        { key: "quote_row", id: '5', name: this.tr('Quote row'), label: this.tr('Quote row'), value: '5', color: "#ffaf0f" },
        { key: "received_invoice", id: '6', name: this.tr('Received invoice'), label: this.tr('Received invoice'), value: '6', color: "#ffaf0f" },
        { key: "manually_added_cost", id: '6', name: this.tr('Manually added cost'), label: this.tr('Manually added cost'), value: '6', color: "#ffaf0f" }
    ];

    list;
    isDuedateOtherFieldsHidden;

    constructor(props, context) {
        super(props, context, "customview/blocks/InvoiceMaterial/InvoiceMaterialBlock");

        this.list = React.createRef();
        this.isDuedateOtherFieldsHidden = true;
        this.state = {
            sortField: 'id',
            sortAscending: true,
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
        sessionStorage.setItem("invoiceMaterialPreselect", JSON.stringify([]));
        const filterValues = localStorage.getItem("project_statistics_invoice_material_type_filter");
        if (filterValues) {
            const filters = JSON.parse(filterValues);
            if (filters !== undefined) {
                if (filters?.indexOf("") > -1) {
                    filters?.splice(filters?.indexOf(""), 1);
                }
                if (filters !== null) {
                    this.props.onSelectInvoiceMaterialListFilter(filters);
                }
            }
            
        }
            
        this.getProducts();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.invoiceMaterial !== this.props.invoiceMaterial) {
            this.list.current && this.list.current.setState({ isLoading: false});
        }
            
        /*const { projects } = this.props;

        if (projects !== prevProps.projects) {

        }*/
    }

    getProducts = async () => {
        const {
            project: { companies_id, account },
        } = this.props;
        const responses = await Promise.all([
            //DataHandler.get({ url: `/products/array/${companies_id}`, include_deleted: 0, account: account.id }),
            DataHandler.get({ url: `/cpq/`, company: companies_id, page: 1, perpage: 10000 }),
        ]);

        const products = responses[0].products || {};
        const cpqs = responses[0].cpqs || {};

        this.setState({
            products: (products || []).map((product) => ({ ...product, label: product.name })),
            cpqs: (cpqs || []).map((cpqs) => ({ ...cpqs, label: cpqs.name })),
        });
    };

    getTypeFilterTitle = () => {
        const { materialTypeFilters } = this.props;

        if (materialTypeFilters.length === 0 || materialTypeFilters.length === this.materialTypes.length) {
            return this.tr("All");
        }

        return materialTypeFilters.map(x => this.materialTypes.find(e => e.key === x)?.label).filter(x => !!x).join(', ');
    }

    renderToolbar = () => {
        const { onSelectInvoiceMaterialListFilter } = this.props;

        return (<>
            <InsightDropDown
                title={this.tr("Type")}
                titleLabel={this.getTypeFilterTitle()}
                color="#e3f4ff"
                titleColor="#2d9ff7"
                tabs={this.materialTypes.map(x => ({...x, action: () => {}}))}
                customComponent={(this.materialTypes || []).map((o) => {
                    return (
                        <div
                            key={o.key}
                            onClick={(e) => onSelectInvoiceMaterialListFilter(o.key)}
                            className="column-options-dropdown-item">
                            <div className="check-box">
                                {(this.props.materialTypeFilters.includes(o.key)) && (
                                    <CheckBox className="icon" />
                                )}
                            </div>
                            {o.name}
                        </div>
                    );
                })}
            />
        </>)
    }

    onCheck = (checked: string[]|boolean) => {
        const { invoiceMaterial, onCheckedInvoiceMaterial } = this.props;

        if (Array.isArray(checked)) {
            const ids = checked.map(x => x);

            onCheckedInvoiceMaterial?.(ids);
        } else {
            const ids = checked ? invoiceMaterial.map(x => x.id) : [];

            onCheckedInvoiceMaterial?.(ids);
        }
    }

    invoiceFromRow = (rowKey: string, projectsId: string | number = 0) => {
        sessionStorage.setItem("invoiceMaterialPreselect", JSON.stringify([rowKey]));
        this.props?.createInvoiceFromSelectedMaterial(projectsId);
    }

    openQuote = (quoteId: string) => {
        this.context.functions.updateView({ 
            selectedTab: 'sales',
            selectedSubTab: 'quote',
            selectedQuoteId: quoteId});
    }

    openReceivedinvoice = (receivedinvoiceId: string) => {
        this.context.functions.updateView({ 
            module: 'receivedinvoice',
            action: 'view',
            id: receivedinvoiceId});
    }

    deleteQuoteRow = (quoteId: string, material_id: string) => {
        if (Number(quoteId) > 0 && Number(material_id) > 0) {
            DataHandler.delete({ url: `projects/quotes/${quoteId}/row/${material_id}` })
                .done((response) => {
                    window.dispatchEvent(new Event('invoiceMaterialChanged'));
                    setTimeout(() => {
                        /*this.props.updateProjectData && this.props.updateProjectData(); */// updating project data to get updated values in revenue recognition
                    }, 1000);
                })
                .fail((err) => {
                    console.error(err);
                   /* this.props.enqueueSnackbar(this.tr('Deleting quote failed!'), {
                        variant: 'error',
                    });*/
                });
        }
    }

    updateQuoteRow = (update) => {
        if (Number(update.costestimate_id) > 0 && Number(update.material_id) > 0) {
            this.list.current && this.list.current.setState({ isLoading: true});
            DataHandler.post({ url: `projects/quotes/${update.costestimate_id}/row/${update.material_id}`}, update)
                .done((response) => {
                    this.closeEditSlider();
                })
                .fail((err) => {
                    console.error(err);
                })
                .always(() => {
                    window.dispatchEvent(new Event('invoiceMaterialChanged'));
                });
        }
    }

    closeAddMenu = () => {
        this.setState({addMenuAnchor: false});
    }

    deleteScheduledInvoice = (materialId) => {
        this.list.current && this.list.current.setState({ isLoading: true});
        DataHandler.delete({url: `projects/${this.props.project.id}/invoicing/${materialId}`}).done(() => window.dispatchEvent(new Event('invoiceMaterialChanged'))); 
    }

    updateScheduledInvoice = (update) => {
        let type = "cost";
        if (Number(update.cpq_id.id) > 0) {
            type = "cpq";
        } else if (Number(update.product_id.id) > 0) {
            type = "product";
        }
        this.saveScheduledInvoice(type, update);
    }

    saveScheduledInvoice = (type, item: null|any = null) => {
        const { products, cpqs } = this.state;
        const fields: any = [];
        let method = 'put';

        if (item === null) {
            item = {
                duedate: format(Date.now(), 'YYYY-MM-DD'),
                duedate_repeat: 0,
                duedate_repeat_select: 0,
                duedate_repeat_increment: "0",
                quantity: "1",
                value: 0,
                vat: this.context.taimerAccount.defaultVat,
                description: "", 
            };
            method = 'post';
        }

        fields.push({
            title: this.tr('Invoice date'),
            key: 'duedate',
            type: 'date',
        });

        switch (type) {
            case "cost": {
                break;
            }
            case "product": {
                fields.push({
                    title: this.tr('Product'),
                    key: 'product_select',
                    type: 'select',
                    options: products,
                    setOtherValuesWithSelection: (_, value) => {
                        const product = products.find(product => product.id == value.id);
                        return {
                            value: product.income_price,
                            product_register_id: product.id,
                            description: product.name,
                        };
                    },
                });
                break;
            }
            case "cpq": {
                fields.push({
                    title: this.tr('CPQ'),
                    key: 'cpq_select',
                    type: 'select',
                    options: cpqs,
                    setOtherValuesWithSelection: (_, value) => {
                        const cpq = cpqs.find(cpq => cpq.id == value.id);
                        return {
                            value: cpq.sum,
                            cpq_id: cpq.id,
                            description: cpq.name,
                        };
                    },
                });
                break;
            }
            default:
                break;
        }

        fields.push({
            title: this.tr('Description'),
            key: 'description',
        });
        fields.push({
            title: this.tr('Quantity'),
            key: 'quantity',
        });
        fields.push({
            title: this.tr('value'),
            key: 'value',
        });
        fields.push({
            title: this.tr('vat'),
            key: 'vat',
        });
        fields.push({
            title: this.tr('Interval'),
            key: 'duedate_repeat_select',
            type: 'select',
            options: [
                {
                    id: 0,
                    value: 0,
                    label: this.tr('No repeat'),
                },
                {
                    id: 1,
                    value: 1,
                    label: this.tr('Every month'),
                },
                {
                    id: 2,
                    value: 2,
                    label: this.tr('Every week'),
                },
                {
                    id: 3,
                    value: 3,
                    label: this.tr('Every day'),
                }
            ],
            setOtherValuesWithSelection: (_, value) => {
                if (value.value > 0) {
                    this.isDuedateOtherFieldsHidden = false;
                    return {
                        duedate_repeat: value.value,
                    };
                } else {
                    this.isDuedateOtherFieldsHidden = true;
                    return {
                        duedate_repeat: value.value,
                        duedate_repeat_increment: "0",
                    };
                }
            },
        });
        fields.push({
            isHidden: () => {return this.isDuedateOtherFieldsHidden},
            title: this.tr('Recurring'),
            key: 'duedate_repeat_increment',
        });
        fields.push({
            isHidden: () => {return this.isDuedateOtherFieldsHidden},
            title: this.tr('Until'),
            key: 'until',
            type: 'date',
        });

        switch (method) {
            case 'post': {
                this.showEditSlider({
                    onSave: (data) => {
                        this.list.current && this.list.current.setState({ isLoading: true});
                        DataHandler.post({url: `projects/${this.props.project.id}/invoicing`}, data).done(() => {
                        this.closeEditSlider();
                        window.dispatchEvent(new Event('invoiceMaterialChanged'));
                    })},
                    fields: fields,
                    item: item,
                    title: this.tr('Add billable material')
                });
                break;
            }
            case 'put': {
                this.list.current && this.list.current.setState({ isLoading: true});
                item.duedate_repeat = item.duedate_repeat.value;
                DataHandler.put({url: `projects/${this.props.project.id}/invoicing/${item.material_id}`}, item).done(() => {
                    this.closeEditSlider();
                    window.dispatchEvent(new Event('invoiceMaterialChanged'));
                });
            }
        }
        
        
    }

    showEditSlider = (editSliderData) => this.setState({ editSliderData });
    closeEditSlider = () => this.setState({ editSliderData: undefined });

    sortRows = (sortField: string, sortAscending: boolean) => {
        this.setState({sortField, sortAscending});
    }

    applySort =  (invoiceMaterial: InvoiceMaterial[], sortField: string, sortAscending: boolean): InvoiceMaterial[] => {
        if (sortField === 'row_type') {
            const transMap: Record<string, string> = {}

            for (const mat of this.materialTypes) {
                transMap[mat.key] = mat.label;
            }

            const sorted = sortBy(invoiceMaterial, x => transMap[x.row_type] ?? this.tr(x.row_type));
            return sortAscending ? sorted : sorted.reverse();
        }
        else if (sortField === 'creation_date') {
            const sorted = sortBy(invoiceMaterial, x => x.creation_date);
            return sortAscending ? sorted : sorted.reverse();
        } else if (sortField === 'user_or_product') {
            const sorted = sortBy(invoiceMaterial, x => x.user_or_product);
            return sortAscending ? sorted : sorted.reverse();
        } else if (sortField === 'description') {
            const sorted = sortBy(invoiceMaterial, x => x.description);
            return sortAscending ? sorted : sorted.reverse();
        } else if (sortField === 'quantity') {
            const sorted = sortBy(invoiceMaterial, x => x.quantity);
            return sortAscending ? sorted : sorted.reverse();
        } else if (sortField === 'value') {
            const sorted = sortBy(invoiceMaterial, x => x.value);
            return sortAscending ? sorted : sorted.reverse();
        } else if (sortField === 'total') {
            const sorted = sortBy(invoiceMaterial, x => x.total);
            return sortAscending ? sorted : sorted.reverse();
        } else if (sortField === 'vat') {
            const sorted = sortBy(invoiceMaterial, x => x.vat);
            return sortAscending ? sorted : sorted.reverse();
        } else if (sortField === 'total_vat') {
            const sorted = sortBy(invoiceMaterial, x => x.total * (1 + ((Number(x.vat) || 0) / 100)));
            return sortAscending ? sorted : sorted.reverse();
        } 

        return invoiceMaterial;
    }

    render() {
        const { blockProps, invoiceMaterial, currency, project: { companies_id, account } } = this.props;
        const { editSliderData, sortField, sortAscending } = this.state;

        const selected = invoiceMaterial.filter(x => x.selected);
        const allSelected = selected.length === invoiceMaterial.length;
        const selectedProjects = [...new Set(selected.map(s => s.projects_id))]

        const sorted = this.applySort(invoiceMaterial, sortField, sortAscending);

        return (
            
                <BlockBase
                    {...blockProps} 
                    subtitleOnlyWhenCollapsed
                    topbarComponent={this.renderToolbar()}>

                    <List
                        ref={this.list}
                        className="invoicematerial-list"
                        data={sorted}
                        checkedRows={invoiceMaterial.filter(x => x.selected).map(x => x.id)}
                        columns={this.columns}
                        trimHeight={20}
                        rowHeight={44}
                        minHeight={200}
                        maxHeight={500}
                        height="auto"
                        listRowType={InvoiceMaterialBlockRow}
                        saveColumnConfig={true}
                        userListSettingsKey="invoice_material_block"
                        noStateData={true}
                        onCheckAll={() => this.onCheck(true)}
                        onUncheckAll={() => this.onCheck(false)}
                        roundbutton={(e) => this.setState({addMenuAnchor: e.target}) }
                        rowProps={{
                            onCheck: this.onCheck,
                            invoiceFromRow: this.invoiceFromRow,
                            openQuote: this.openQuote,
                            openReceivedinvoice: this.openReceivedinvoice,
                            deleteQuoteRow: this.deleteQuoteRow,
                            showEditSlider: this.showEditSlider,
                            updateQuoteRow: this.updateQuoteRow,
                            updateScheduledInvoice: this.updateScheduledInvoice,
                            deleteScheduledInvoice: this.deleteScheduledInvoice,
                            // showWholeTrees: this.state.showWholeTrees,
                            // showTreeStructures: this.state.showTreeStructures,
                            statuses: this.materialTypes,
                            products: this.state.products,
                            cpqs: this.state.cpqs,
                            company: companies_id,
                            onlyDisplay: true,
                            editable: false,
                        }}
                        sharedData={{
                            currency
                        }}
                        //summaryRowType={InvoiceMaterialBlockSummaryRow}
                        enableToolbar={true}
                        hiddenToolbarColumns={["export", "edit", "delete"]}
                        additionalToolbarColumns={[{ name: 'createInvoice', header: '', columnHeaderType: 'createInvoiceButton', width: 100 }]}
                        additionalToolbarButtons={selectedProjects?.length == 1 ? { // Creation allowed if material selected only from one project. 
                            createInvoiceButton: <ColumnHeaderButton 
                                color={"blue"} 
                                title={this.tr("Create Invoice")}
                                onClick={() => {
                                    this.props?.createInvoiceFromSelectedMaterial(selectedProjects[0] || 0);
                                }}  
                            />
                        } : undefined}
                        onSortRows={this.sortRows}
                    />
                    <InvoiceMaterialBlockSummaryRow
                        rows={invoiceMaterial}
                        sharedData={{
                            currency
                        }}
                    />
                    
                    <Popover open={!!this.state.addMenuAnchor} anchorEl={this.state.addMenuAnchor} onClose={this.closeAddMenu} 
                        anchorOrigin={{
                            vertical: 35,
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}>
                        <MenuList>
                            <MenuItem onClick={() => {
                                this.closeAddMenu();
                                this.saveScheduledInvoice("cost");
                            }}>{this.tr('Cost')}</MenuItem>
                            <MenuItem onClick={() => {
                                this.closeAddMenu();
                                this.saveScheduledInvoice("product");
                            }}>{this.tr('Product')}</MenuItem>
                            <MenuItem onClick={() => {
                                this.closeAddMenu();
                                this.saveScheduledInvoice("cpq");
                            }}>{this.tr('CPQ')}</MenuItem>
                        </MenuList>
                    </Popover>

                <FieldEditSlider
                        onSave={editSliderData?.onSave}
                        item={editSliderData?.item}
                        fields={editSliderData?.fields}
                        title={editSliderData?.title}
                        open={!!editSliderData}
                        onClose={this.closeEditSlider}
                    />
            </BlockBase>
        );
    }
}  