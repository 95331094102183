import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';

import { SettingsContext } from './../../SettingsContext';
import OutlinedField from "./../../general/OutlinedField";
import { DatePicker } from './../../general/react-date-range/src';
import TaimerComponent from './../../TaimerComponent';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';

import { format } from "date-fns";
import { withSnackbar } from 'notistack';

import './../../general/react-date-range/src/styles.css';
import './../../general/react-date-range/src/theme/default.css';

const styles = theme => ({
    topBar: {
        backgroundColor: '#2D9FF7',
        height: '8px',
        borderRadius: '4px 4px 0 0',
        marginBottom: '10px'
    },
    invoiceDialogTitle: {
        textAlign: 'center',
    },
    invoiceDialogSubTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        padding: '0 24px',
    },
    field: {
        marginBottom: "10px",
        width: "100%"
    },
    shrink: {
        fontSize: "14px"
    },
    input: {
        textAlign: 'right',
        paddingTop: '19px',
        paddingBottom: '19px',
        fontSize: "14px",
        paddingRight: "15px"
    },
    datePicker: {
        width: '100%',
        boxSizing: 'border-box',
        minHeight: '56px',
        margin: '8px 0 4px'
    },
    invoiceDialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
    }
});

class MarkAsPaidSingle extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/dialogs/MarkAsPaidSingle");

        this.state = {
            payment: 0,
            date: format(new Date(), 'YYYY-MM-DD'),
            paid: props.data.paid,
            left: props.data.left,
            isCurrencyRatedInvoice: !['', context.taimerAccount.currency].includes(props.data?.currencyCode) && !['0', 0, '', 1].includes(props.data?.currencyRate)
        }

        this.datePicker = React.createRef();
        this.payment = React.createRef();
    }

    onChange = (e) => {
        let value = Number(String(e.target.value).replace(',', '.'));
        if (value - this.state.payment == 0)
            return;        
        const { left, paid, currency_total } = this.props.data;

        //added number and toFixed for left because floats are fun
        this.setState({
            payment: value, 
            left: Number((Number(currency_total)-Number(paid)-(value)).toFixed(4)), 
            paid:Number(paid)+(value)});

    }

    onInputChange = (name, date) => {
        this.setState({date: format(new Date(date), 'YYYY-MM-DD')});
    }

    onDateChange = (date) => {
        this.setState({date: format(new Date(date), 'YYYY-MM-DD')});
    }

    handleCancel = () => {
        this.props.onDialogClose();
    }

    save = () => {
        if (!this.payment.current.state.valid)
            return;
        if (this.props.data.paid > this.props.data.currency_total && this.state.payment > 0) {
            return;
        } else if ((this.props.data.paid < this.props.data.currency_total) && this.props.data.currency_total > 0 && !(this.state.payment > 0)){
            return;
        }

        let { payment, date } = this.state;
        const { enqueueSnackbar, data } = this.props;

        payment = Number(payment).toFixed(4);

        if (Math.round(data.currency_total-data.paid-payment) < 0) {
            enqueueSnackbar('Entered payment is too large.', {
                variant: "error",
            });
            return false;
        }

        this.props.onDialogSave(this.props.data.saveFunc, {
            action: 'add_payment',
            payment: payment-0, 
            date: date,
            id: this.props.data.id
        });
    }

    render() {

        const { tr } = this;
        const { taimerAccount, userObject } = this.context;
        const { data, classes } = this.props;
        const { date, prevState, isCurrencyRatedInvoice } = this.state;
        let { payment, paid, left } = this.state

        const dateFormat = userObject.dateFormat;

        const commonCurrencyProps = (value) => {
            const props = {
                value: Number(value).toFixed(2) == '-0.00' ? '0.00' : Number(value).toFixed(2),
                format: "currency",
                currency: this.props.currency,
                currencySymbol: isCurrencyRatedInvoice ? data.currencySymbol : false
            }
            return props;
        };

        return (
            <Dialog
                open
                className="invoiceDialog"
                maxWidth="xs"
                aria-labelledby="markAsPaidSingle"
                TransitionProps={{
                    onEntering: this.handleEnter
                }}>
                <div className={classNames(classes.topBar)}/> 
                <DialogTitle className={classNames(classes.invoiceDialogTitle)}>
                    <span>{tr("Mark invoice ${invoice_number} as paid", {invoice_number: data.bill_id})}</span>
                </DialogTitle>
                <div className={classNames(classes.invoiceDialogSubTitle)}>
                    <span>{tr("Projects: ${projects}", {projects: data.projects})}</span>
                    <span>{tr("Accounts: ${accounts}", {accounts: data.customers})}</span>
                </div>
                <DialogContent className="invoiceDialogContent" ref={cont => this.container = cont}>
                    <OutlinedField 
                        fullWidth
                        autoComplete="off"
                        InputLabelProps={{classes:{shrink: classNames(classes.shrink)}}}
                        InputProps={{classes:{input: classNames(classes.input)}}}
                        className={classNames(classes.field)}  
                        label={isCurrencyRatedInvoice ? this.tr("Total default currency") : this.tr("Total")}
                        name="total"
                        {...commonCurrencyProps(data.total)}
                        currencySymbol={false} 
                        disabled />
                    {isCurrencyRatedInvoice && 
                        <OutlinedField
                            fullWidth
                            autoComplete="off"
                            InputLabelProps={{classes:{shrink: classNames(classes.shrink)}}}
                            InputProps={{classes:{input: classNames(classes.input)}}}
                            className={classNames(classes.field)}  
                            label={isCurrencyRatedInvoice ? this.tr("Total invoiced currency") : this.tr("Total")}
                            name="rated_total"
                            {...commonCurrencyProps(data.total)}
                            value={Number(data.currency_total)} 
                            disabled /> 
                    }                                                
                    <OutlinedField
                        fullWidth
                        autoComplete="off"
                        InputLabelProps={{classes:{shrink: classNames(classes.shrink)}}}
                        InputProps={{classes:{input: classNames(classes.input)}}}
                        className={classNames(classes.field)}
                        label={this.tr("Paid" )}
                        name="paid"
                        {...commonCurrencyProps(paid)} 
                        disabled />
                    <OutlinedField
                        fullWidth
                        autoComplete="off"
                        InputLabelProps={{classes:{shrink: classNames(classes.shrink)}}}
                        InputProps={{classes:{input: classNames(classes.input)}}}
                        className={classNames(classes.field)}                      
                        label={this.tr("Left" )}
                        name="left"
                        {...commonCurrencyProps(left)} 
                        disabled />
                    <OutlinedField
                        autoFocus
                        fullWidth
                        autoComplete="off"
                        InputLabelProps={{classes:{shrink: classNames(classes.shrink)}}}
                        InputProps={{classes:{input: classNames(classes.input)}}}
                        className={classNames(classes.field)}                        
                        label={this.tr("Payment" )}
                        name="payment"
                        ref={this.payment}
                        {...commonCurrencyProps(payment)}
                        value={payment > 0 ? Number(payment).toFixed(2) : Number(left).toFixed(2)}
                        onChange={this.onChange} />
                    <DatePicker
                        ref={this.datePicker}
                        label={this.tr("Date")}
                        className={`${classNames(classes.datePicker)} date`}
                        date={date}
                        dateFormat={dateFormat}
                        onChange={this.onDateChange}
                        onInputChange={this.onInputChange}
                        onClose={this.datePicker.handleToggle}
                        closeCalendarOnComplete={true}
                        closeOnComplete={false}
                    />
                </DialogContent>
                <DialogActions className={classNames(classes.invoiceDialogActions)}>
                    <Button size="large" variant="text" onClick={this.handleCancel} >
                        {this.tr("Cancel")}
                    </Button>
                    <Button size="large" onClick={this.save} color="primary">
                        {this.tr("Ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

MarkAsPaidSingle.defaultProps = {
    onDialogClose: () => {},
    onDialogSave: () => {},
    data: {total: '', paid: '', left: '', date: "1970-01-01"},
    enqueueSnackbar: PropTypes.func.isRequired
}

export default withSnackbar(withStyles(styles)(MarkAsPaidSingle));