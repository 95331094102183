
import {
    RemoveRedEye
} from "@mui/icons-material";
import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem } from "@mui/material";
import ContextMenu from "../../general/ContextMenu";
import DataHandler from "../../general/DataHandler";
import TaimerComponent from "../../TaimerComponent";
import DateCell from "../cells/DateCell";
import MultiLinkListCell from "../cells/MultiLinkListCell";
import LinkListCell from "../LinkListCell";
import List from "../List";
import ListCell from "../ListCell";
import PropsOnlyListRow from "../PropsOnlyListRow";
import "./ProductDetailsInfo.css";

class ProductInvoiceHistorySliderRow extends PropsOnlyListRow {
  constructor(props) {
    super(props, null, {}, "list/rows/ProductInvoiceHistorySliderRow");
  }

  defineClassName() {
    return "ProductInvoiceHistorySliderRow";
  }

 

  defineCells() {
    const {columnWidthMap, data} = this.props;
    let cells = {};

    cells = {
      context:
          <ListCell className="nobg alignLeft pricelistcontextcell" onlyDisplay>
            <ContextMenu className="row-menu" label={<ContextMenuIcon />}
              buttonProps={{ className: 'action-menu' }} noExpandIcon>
              <MenuItem key={1} noClose={false} onClick={() => this.context.functions.updateView({ module: "invoices", action: "view", id: data.invoice_id, billid: data.invoice_nr }, false)} >
                  <RemoveRedEye />
                  {this.tr("View Invoice")}
              </MenuItem>
            </ContextMenu>
          </ListCell>,
      invoice_nr: (
        <LinkListCell 
          urlHandler={value => `index.html?module=invoices&action=view&id=${data.invoice_id}&billid=${data.invoice_nr}`}
          width={columnWidthMap.invoice_nr} 
          value={data['invoice_nr']} 
          editable={false} 
          noTab={true}
        />
         
      ),
      project: (
        <MultiLinkListCell
          width={columnWidthMap.project}
          noTab={true}
          value={data['projects']} 
          editable={false}
          urlHandler={value => {
            const urls = []
            data.all_project_ids.split(",").forEach(e => {
              urls.push(`index.html?module=projects&action=view&id=${e}`);
            });
            return urls;
          }}
        />
      ),
      account: (
        <LinkListCell
          urlHandler={value => `index.html?module=customers&action=view&id=${data.customerId}`}
          width={columnWidthMap.account}
          noTab={true}
          value={data['customer']} 
          editable={false}
          noTab={true}
        />
      ),
      date: <DateCell  width={columnWidthMap.date} value={data['date']} editable={false} />
    };

    return cells;
  }
}

class ProductInvoiceHistory extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/lists/ProductInvoiceHistory");
    this.state={ 
      data: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();

    DataHandler.get({url: `products/${this.props.productId}/invoice_history`}).done(response => {
       this.setState({ data: response.invoices});
    });
  }

  _getColumns = () => {

    const staticColumnConfig = {
      showMenu: false,
      resizeable: false,
      showResizeMarker: false,
      moveable: false,
      hideable: false,
      visibleInToolbar: true,
    };

    return [
      {
        field: "context",
        name: "context",
        header: "",
       // columnHeaderType:"roundButton",
        width: 40,
        ...staticColumnConfig,
      },
      {
        field: "invoice_nr",
        name: "invoice_nr",
        header: this.tr("Invoice nr."),
        width: 50,
        ...staticColumnConfig,
      },
      {
        field: "date",
        name: "date",
        header: this.tr("Date"),
        width: 50,
        ...staticColumnConfig,
      },
      {
        field: "account",
        name: "account",
        header: this.tr("Account"),
        width: 100,
        ...staticColumnConfig,
      },
      {
        field: "project",
        name: "project",
        header: this.tr("Project"),
        width: 100,
        ...staticColumnConfig,
      }
    ];
  }

 
  render() {
    const { height} = this.props;

    return (

      <div className="product-card-content">
        <div className="product-card-bottom">
          <List
            fluid
            columns={this._getColumns()}
            height={height}
            rowKey="id"
            listRowType={ProductInvoiceHistorySliderRow}
            noStateData={true}
            data={this.state.data}
          />
        </div>
      </div>

    );
  }
}

export default ProductInvoiceHistory;
