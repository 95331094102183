import React from 'react'

/* css */
import './ActivitiesInsightBox.css';

/* material-ui */

/* others */
import _ from 'lodash';
import ActivitiesInsightCase from './ActivitiesInsightCase';
import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from '../../../../src/SettingsContext';

class ActivitiesInsightBox extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context){
        super(props, context, "dashboard/insights/activities/ActivitiesInsightBox");
        this.state = {
            open: true,
        }
    }
    
    componentDidMount(){
        super.componentDidMount();
    }

    //Opens and closes Box
    handleOpen = () => {
        this.setState({open: !this.state.open});
    }

    render() {
        
        return (
            <div id="insights-activities-box-main">
                <div className="activitiesboxwrapper" >
                    {
                    <ActivitiesInsightCase {...this.props} />
                    }
                </div>
            </div>
        )
    }
}

export default ActivitiesInsightBox;