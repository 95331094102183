import getColumnType, { ColumnDefinition, columnSorter } from './ColumnTypes';
import getProjectColumns from './ProjectColumns';
import getAccountColumns from './AccountColumns';
import { CustomFields } from './CustomFields';
import { filterColumns } from "./ColumnVisibility";
import colors from "../colors";

// const columnOrder = [
//     'project',
//     'material_date_month',
//     'invoice_invoiced',
// ];

interface Params {
    customFields: CustomFields;
    tr: (text, replacers?: Record<string, string>) => string;
    startDate: Date | null;
    endDate: Date | null;
    dateFormat: string;
    settingsContext: any;
    checkPrivilege: (group: string, perm: string, company?: number) => boolean;
    getActivityTypeIcon: (data: any) => any;
}

export default function getActivitiesColumns(params: Params) {

    const columns: ColumnDefinition[] = [    
        {
            field: 'description',
            headerName: 'Description',
            ...getColumnType('text'),
            wrapText: true,
            autoHeight: true
        },
        {
            field: 'status',
            headerName: 'Status	',
            ...getColumnType('status', params.tr),
            cellRendererParams: {
                displayDatas: [
                    {id: '1', "name": "Done", "color": colors.greenish_cyan},
                    {id: '2', "name": "Due", "color": colors.orangey_yellow},
                    {id: '3', "name": "overdue", "color": colors.carnation_pink},
                ],
                tr: params.tr
            },
            autoHeight: true
        },
        {
            field: 'creation_date',
            headerName: 'Creation date',
            ...getColumnType('date', null, 'creation_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'activity_date',
            headerName: 'Activity date',
            ...getColumnType('date', null, 'activity_date'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'activity_date_month',
            headerName: 'Month (Activity date)',
            ...getColumnType('month'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'activity_date_quarter',
            headerName: 'Quarter (Activity date)',
            ...getColumnType('quarter'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'activity_date_year',
            headerName: 'Year (Activity date)',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group',
            chartDataType: 'category',
        },
        {
            field: 'deadline',
            headerName: 'Deadline',
            ...getColumnType('date', null, 'deadline'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'deadline_month',
            headerName: 'Month (Deadline)',
            ...getColumnType('month'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'deadline_quarter',
            headerName: 'Quarter (Deadline)',
            ...getColumnType('quarter'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'deadline_year',
            headerName: 'Year (Deadline)',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group',
            chartDataType: 'category',
        },
        {
            field: 'type',
            headerName: 'Type',
            ...getColumnType('icon_text'),
            getIconComponent: params.getActivityTypeIcon        
        },
        {
            field: "created_by",
            headerName: "Created by",
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('admin', 'admin', r.created_by_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: "contact",
            headerName: "Contact",
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=contact&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('admin', 'admin', r.contact_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: "assigned_to",
            headerName: "Assigned To",
            ...getColumnType('link'),
            cellRendererParams: {
                url: "index.html?module=users&action=view&id=$(id)&company=$(companyid)",
                displayLinkAsText: (r) => !params.checkPrivilege('admin', 'admin', r.assigned_to_company_id),
            },
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: "assigned_to_company",
            headerName: "Company (Targeted to)",
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup'
        },
        {
            field: "company",
            headerName: "Company",
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup'
        },
        ...getProjectColumns(params),
        ...getAccountColumns(params),
    ];

    // columns.sort(columnSorter(columnOrder));
    
    return filterColumns(
        columns, 
        "activities", 
        params.settingsContext
    );
}
