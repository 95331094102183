import React from 'react';
import { Button } from '@mui/material';
import './InsightTabs.css';

const ModeButton = props => {
    const { onClick, label, selected } = props;
    return (
      <div className="mode-button-container">
        <Button
          className="mode-button"
          style={{
            fontWeight: selected ? "bold" : "normal",
            opacity: selected ? 1 : 0.5
          }}
          onClick={onClick}
        >
          {label}
        </Button>
        {selected && <div className="selected-indicator" />}
      </div>
    );
  };

const InsightTabs = props => {
    const {tabs, selected} = props;
    return (
        <div className="insight-tabs-container">
        {tabs.map(tab => {
          return (
            <ModeButton
              selected={tab.key == selected}
              label={tab.label}
              onClick={tab.action}
            />
          );
        })}
      </div>
    );
}

export default InsightTabs;