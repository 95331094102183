import React from 'react';
import TaimerComponent from "../../TaimerComponent"

/* material ui */
import { Button } from '@mui/material';

import Taimer from "../../Taimer"
import { SettingsContext } from '../../SettingsContext';

import { ReactComponent as OverlayImage } from './ExpenseList.svg';

class ExpenseListOverlay extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'list/overlays/ExpenseListOverlay');
    }

    render () {
        const { functions: { checkPrivilege } } = this.context;

        return (
            <div className="list-overlay">
                <OverlayImage style={{width: "auto", height: '260px'}} />
                <div className="text">
                    {this.tr("Add your first Expense") }
                </div>
                {checkPrivilege("worktrips", "write") && <Button
                    color="primary"
                    onClick={() => this.context.functions.addExpense()}
                >
                {this.tr("ADD EXPENSE") }
                </Button>}
             </div>
        );
    }

}

export default ExpenseListOverlay;