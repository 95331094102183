import React from "react";
import TaimerComponent from "../../../../TaimerComponent";
import InsightChart from "../../InsightChart";
import InsightPieChart from "./InsightPieChart";
import InsightChartTable from "../../InsightChartTable";
import { stringToColour } from "../../../../helpers";
import InsightDropDown from "../../InsightDropDown";
import colors from "../../../../colors";

const PIE_COLORS = ["#7b4bc8", "#f9669e", "#2d9ff7", colors.greenish_cyan];

export default class PieChartBlock extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "dashboard/insight/sales/components/PieChartBlock");
    const mode = props.tabs && props.tabs.length > 0 && props.tabs[0].key;
    this.state = {
      ...this._parseData(props.data, mode),
      mode,
      tabs:
        props.tabs &&
        props.tabs.map(tab => ({
          ...tab,
          action: () => this.setState({ mode: tab.key })
        }))
    };
  }

  componentDidUpdate = (oldProps, oldState) => {
    if (oldProps.data != this.props.data || oldState.mode != this.state.mode) {
      this.setState({
        ...this.state,
        ...this._parseData(this.props.data, this.state.mode)
      });
    }
  };

  _parseData = (data, mode = null) => {
    const colors = [];
    const values = [];
    const sliderValues = [];
    const labels = [];
    if (mode) {
      data[mode].map((obj, i) => {
        values.push(obj.value);
        sliderValues.push(obj.slider);
        labels.push(obj.label);
        i < PIE_COLORS.length
          ? colors.push(PIE_COLORS[i])
          : colors.push(stringToColour(obj.label));
      });
      return { values, sliderValues, colors, labels };
    } else {
      data.map((obj, i) => {
        values.push(obj.value);
        sliderValues.push(obj.slider);
        labels.push(obj.label);
        i < PIE_COLORS.length
          ? colors.push(PIE_COLORS[i])
          : colors.push(stringToColour(obj.label));
      });
      return { values, sliderValues, colors, labels };
    }
  };

  render() {
    const { values, sliderValues, colors, labels, mode, tabs } = this.state;
    const { tableTitle, ...props } = this.props;
    return (
      <InsightChart
        {...props}
        className="pie-chart-block"
        filterComponents={tabs && <InsightDropDown tabs={tabs} selected={mode} />}
        data={{
          sets: [
            {
              values,
              data: sliderValues,
              label: this.tr("Value"),
              colors: {
                backgroundColor: colors,
                borderColor: colors
              }
            }
          ],
          labels: labels
        }}
        chartType={InsightPieChart}
        tableType={InsightChartTable}
        sliderColumns={["project", "account", "user", "value"]}
        tableProps={{
          displayByLabels: true,
          customColors: colors,
          highlightRow: true,
          displaySplit: true,
          setTitle: tableTitle
        }}
      />
    );
  }
}
