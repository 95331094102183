import { format } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import DataHandler from '../../../general/DataHandler';
import TaimerComponent from '../../../TaimerComponent';
import { ResourcingSubviewProps } from '../../ResourcingView';
import UsageList from "./../../../list/lists/UsageList";
import styles from './UsageView.module.scss';

interface UsageViewProps extends ResourcingSubviewProps {
    usageGetCount: number;
    singleProject?: boolean;
}

interface UsageViewState {
    usage: any;
    usageGetCount: number;
}

class UsageView extends TaimerComponent<UsageViewProps, UsageViewState> {
    usageList: React.RefObject<UsageList>;

    constructor(props: UsageViewProps, context) {
        super(props, context, "resourcing/views/UsageView");

        this.state = {
            usage: null,
            usageGetCount: 1,
        };

        this.usageList = React.createRef();
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.updateUsageData();

    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps: UsageViewProps, prevState: UsageViewState) {
        let taskUpdated = false;

        if (prevProps.usageGetCount < this.props.usageGetCount) {
            taskUpdated = true;
        }

        const fields = [
            'filters',
        ];

        const updateFields = {
            filters: true,
        };

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];

            if (!_.isEqual(prevProps[field], this.props[field])) {
                if (updateFields[field]) {
                    taskUpdated = true;
                    break;
                }
            }
        }

        if (taskUpdated) {
            this.updateUsageData();
        }
    }

    updateUsageData = () => {
        const {
            filters: {
                company,
                dateRange,
                customer,
                projects,
                teams = [],
                users = [],
                pipelines = [],
            }
        } = this.props;

        const { startDate, endDate } = dateRange || {};
        const customersId = customer?.value ?? 0;
        const projectsId = projects ? projects.map(x => x.value) : [];

        DataHandler.get({
            url: `resourcing/usage/${this.props.singleProject ? 0 : company}`,
            startdate: startDate ? format(startDate, "YYYY-MM-DD") : undefined,
            enddate: endDate ? format(endDate, "YYYY-MM-DD") : undefined,
            customers_id: customersId,
            users_ids: users.map(x => x.value),
            pipelines_ids: pipelines.map(x => x.value),
            projects_id: projectsId,
            team: teams.map(x => x.value),
        })
            .done(data => {
                data.users = data.users.map(u => {
                    if (u.companies_id < 1)
                        u.lastname += ` (${this.tr("freelancer")})`;
                    return u;
                })
                this.setState({
                    usage: data,
                    usageGetCount: this.state.usageGetCount + 1,
                });
            });
    };

    render() {
        const { autocomplete, checkPrivilege } = this.props;
        const { usage, usageGetCount } = this.state;

        return <div className={styles.root}>
            <UsageList
                checkPrivilege={checkPrivilege}
                ref={this.usageList}
                data={usage}
                updateData={this.updateUsageData}
                autoCompleteData={autocomplete}
                usageGetCount={usageGetCount}
            />
        </div>
    }
}

export default UsageView;