import React, { Component } from 'react';
import TaimerComponent from '../TaimerComponent';
import { SettingsContext } from './../SettingsContext'; 
import cn from 'classnames'
import { Popover } from '@mui/material';

export class DialogDropDownButtonItem extends TaimerComponent {
    static defaultProps = {
    }

    onClick = () => {
        this.props.onClick();
    }

	render() {
        const { children, className } = this.props;

        return (<div onClick={this.onClick} className={className}>
            {children}
        </div>)
	}
}

export class DialogDropDownButton extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = {
    }

    constructor(props, context)
	{
		super(props, context, "dialogs/DialogDropDownButton");

		this.state = {
            open: false,
		}

        this.refBtn = React.createRef();
        this.anchor = React.createRef();
    }

    outsideClick = (e) => {
        if (!this.state.open || !this.refBtn.current)
            return;

        const el = this.refBtn.current;

        if (el.contains(e.target))
            return;

        setTimeout( () => this.setState({open: false}), 100);
    }

	componentDidMount = () => {
        super.componentDidMount();
        document.addEventListener("click", this.outsideClick);
    }

    componentWillUnmount = () => {
        super.componentWillUnmount();
        document.removeEventListener("click", this.outsideClick);
    }

    onClick = () => {
        const { open } = this.state;

        this.setState({open: !open})
    }

    close = () => {
         this.setState({open: false})
    }

	render() {
        const { title, children } = this.props;

        return (
            <>
                <div ref={this.refBtn} className={cn("TMRDialogDropDownButton", this.props.className, this.state.open && "open")}>
                    <div ref={this.anchor} className="btnName" onClick={this.onClick}>{title}</div>
                </div>
                <Popover 
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }} 
                    anchorEl={this.anchor.current} open={this.state.open} onClose={this.close}>
                    <div className="TTdropdown-content">
                        {children}
                    </div>
                </Popover>
            </>
        )
	}
}