/* eslint import/no-webpack-loader-syntax: off */
import React from 'react'

/* material-ui */

/* others */
import _ from 'lodash';
import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from '../../../../src/SettingsContext';

class ActivitiesInsightTypeViewRow extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context);
    }

    render() {
        const { columnOrder, columnWidthMap, data } = this.props;
        const props = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": prop, style: {width: columnWidthMap[prop] + 'px', flex: columnWidthMap[prop] + " 1 0px" }} }
        });
        
        const cells = {
            name: 
                <div {...props.name}>
                    <img src={data.icon} className="type-img" alt={data.name} /> <div className="type-name">{data.name}</div> 
                </div>,
            all: 
                <div {...props.all}>
                    <span>{ data.all }</span>
                </div>,
            done: 
                <div {...props.done}>
                    <span>{ data.done }</span>
                </div>,
            due: 
                <div {...props.due}>
                    <span>{ data.due }</span>
                </div>,
            overdue: 
                <div {...props.overdue}>
                    <span>{ data.overdue }</span>
                </div>,
        };

        return (
            <div className="list-element" style={{display: "flex"}}>
                {columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}

export default ActivitiesInsightTypeViewRow;