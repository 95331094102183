import React, { PureComponent } from 'react';
import cn from 'classnames'
import PropTypes from 'prop-types';
import moment from 'moment';
import _, { isEqual } from 'lodash'; 
import { format } from 'date-fns';
import styles from './HeaderWrapper.module.scss';
import { filterEventsForDay } from '../helpers';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

// Header Component, to add number of hours to day columns
export default class HeaderWrapper extends PureComponent
{
	static contextTypes = {
		timetracker: PropTypes.object
	};

	constructor(props)
	{
		super(props)
		
		this.state = {
		}
	}

	render() {
		const { date, label } = this.props;
		const { timetracker: { workdays, events, tr, usersId, onlyOwnVisible } } = this.context;
		
		const { dayInfo, realWorktime, worktime, expectedWorktime, travelTime, differenceToExpected } = filterEventsForDay(onlyOwnVisible ? usersId : false, date, events, workdays);
		const holiday = dayInfo.type === 3;
		
		return (
			<React.Fragment>
				<span className={holiday ? "holiday" : "workday"}>{label}</span>
				{onlyOwnVisible && <Tooltip title={travelTime > 0 ? `${tr('Worktime:')} ${realWorktime.toFixed(2)} h ${tr('Travel Time:')} ${travelTime.toFixed(2)} h` : ""}>
					<span className={cn(styles.hours, holiday ? "holiday" : "workday")}>
						{worktime.toFixed(2)} / {expectedWorktime.toFixed(2)} h
						{travelTime > 0 && <InfoIcon />}
					</span>
				</Tooltip>}
				{!onlyOwnVisible && 
					<span className={cn(styles.hours, holiday ? "holiday" : "workday")}>
						{worktime.toFixed(2)} h
					</span>}
				{onlyOwnVisible && <span className={cn(styles.difference, differenceToExpected < 0 && styles.positive, differenceToExpected > 0 && styles.negative)}>
					{differenceToExpected > 0 ? '-' : (differenceToExpected == 0 ? '±' : '+')}{Math.abs(differenceToExpected).toFixed(2)} h
				</span>}
			</React.Fragment>);
	}
}