import React from 'react';
import TaimerComponent from "../TaimerComponent";
import DataHandler from '../general/DataHandler';
import { SettingsContext } from '../SettingsContext';
import './Onboarding.css';
import { Button, Tabs, Tab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {CardElement, injectStripe} from 'react-stripe-elements';
import OutlinedField from "./../general/OutlinedField";
import CheckCircle from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import LeftArrow from '@mui/icons-material/ChevronLeft';
import { withSnackbar } from 'notistack';

class OrderForm extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "onboarding/OrderForm");
        this.state = {
            selectedTab: 0,
            selectedPlan: props.selectedPlan || context.versionIds,
            currentPlan: context.versionId,
            licenceAmount: 0,
            licenceMin: 0,
            licenceMax: 9999,
            discountCode: '',
            termsAccepted: false,
            errorMessage: '',
            creditCardInfo: false,
            activatedAddons: [],
            deactivatedAddons: [],
            isPayingCustomer: false,
            showProgress: false,
            stripeCustomerId: "",
        }
        
        const { tr } = this;
        
        this.plans = [
            {id: 1, name: tr("Free CRM"), prices: [0, 0]},
            {id: 2, name: tr("Sales CRM"), prices: [156, "16.50"]},
            {id: 3, name: tr("Project Management"), prices: [192, "20.30"]},
            {id: 4, name: tr("ERP"), prices: [312, "32.90"]},
        ];

        /*this.mixpanelPlanNames = [
            "Free",
            "CRM",
            "PRM",
            "ERP"
        ];*/

        this.features = [
            {id: 1, available: [4,3,2,1], name: tr("Android and iOS mobile apps")},
            {id: 2, available: [4,3,2,1], name: tr("Team messenger")},
            {id: 3, available: [4,3,2,1], name: tr("Accounts & contacts CRM")},
            {id: 4, available: [4,3,2,1], name: tr("Leads & deals")},
            {id: 6, available: [4,3,2,1], name: tr("Boards & cards")},
            {id: 12, available: [4,3,2,1], name: tr("Expenses")},
            {id: 5, available: [4,3,2,1], name: tr("Invoicing")},
            //{id: 7, available: [4,3,2,1], name: "Sales quotes"},
            //{id: 8, available: [4,3,2,1], name: "O365 & G Suite integrations"},
            //{id: 9, available: [4,3,2,1], name: "Quickbooks & DocuSign integrations"},
            //{id: 10, available: [4,3,2,1], name: "Reports"},
            {id: 11, available: [4,3], name: tr("Time tracker")},
            {id: 17, available: [4], name: tr("Bills")},
            //{id: 13, available: [4], name: "Cash flow analysis"},
            //{id: 14, available: [4], name: "HR tool"},
            //{id: 15, available: [4], name: "GPS trip log"},
            {id: 16, available: [4,3], name: tr("Resourcing")},
        ];

        this.addonData = [
            {id: 4, code:"projects", info: tr("5 active (add-on 3€)"), show: [1], activatable: true, type: "limit"},
            {id: 5, code:"invoicing", info: tr("2/mo active (add-on 3€)"), show: [1, 2], activatable: true, type: "limit"},
            {id: 6, code:"collaborate", info: tr("3 boards (add-on 3€)"), show: [1], activatable: true, type: "limit"},
            {id: 7, code:"sales", info: tr("5 active (add-on 3€)"), show: [1, 2], activatable: false, type: "limit"},
            {id: 11, code:"timetracker", info: tr("Add-on 3€ / month / user"), show: [1, 2], activatable: true, type: "onOff"},
            {id: 17, code:"bills", info: tr("Add-on 3€ / month / user"), show: [1, 2, 3], activatable: true, type: "onOff"},
            {id: 12, code:"expenses", info: tr("2/mo active (add-on 3€)"), show: [1, 3], activatable: true, type: "limit"},
            {id: 14, code:"hrtool", info: tr("Add-on 3€ / month / user"), show: [1], activatable: true, type: "onOff"},
            {id: 15, code:"gps", info: tr("Add-on 3€ / month / user"), show: [1], activatable: true, type: "onOff"},
            {id: 16, code:"resourcing", info: tr("Add-on 3€ / month / user"), show: [1, 2], activatable: true, type: "onOff"}
        ];

        this.subscribe = this.subscribe.bind(this);
        this.getIntent = this.getIntent.bind(this);
        this.comfirmPurchase = this.comfirmPurchase.bind(this);
        this.getSubscriptionStatus = this.getSubscriptionStatus.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.getSubscriptionStatus();
    }

    async getSubscriptionStatus() {
        const result = await DataHandler.get({url: "/settings/subscription/stripeID"});

        if (result.stripeID) {
            this.setState({stripeCustomerId: result.stripeID});
            this.checkSubscriptionStatus();
            this.getCreditCardInfo();
            this.getUserCount();
        }
        else {
            this.setState({selectedTab: 1});
            this.checkInvoicedSubscriptionStatus();
        }
    }
    
    checkInvoicedSubscriptionStatus = () => {
        DataHandler.get({url: `settings/invoiced_subscription`}).done(response => {
            const plan = response.subscriptions.data[0];
            this.setState({
                isPayingCustomer: plan.id > 1, 
                licenceAmount: plan.quantity, 
                licenceMin: plan.used,
                licenceMax: this.getPlanLimit(plan.id)
            });
        });
    }

    // Free and Growth version can't have too many licenses
    getPlanLimit = (id) => {
        if (id == 10) {
            return 1;
        } else if (id == 11) {
            return 3;
        }
        return 9999;
    }
    
    /* stripe only */
    checkSubscriptionStatus = () => {
        DataHandler.get({url: `settings/subscription`}).done(response => {
            if (response.subscriptions && response.subscriptions.data.length > 0) {
                for(let i = 0; i < response.subscriptions.data.length; i++) {
                    let plan = response.subscriptions.data[i]['plan'];
                    
                    DataHandler.get({url: 'settings/subscription/getPlanName/' + plan['id']}).done(response => {
                        if (plan['amount'] > 0 && response.planName.indexOf('addon') == -1) {
                            if (plan['interval'] == "year") {
                                this.setState({selectedTab: 0, isPayingCustomer: true})
                                return;
                            } else if (plan['interval'] == "month") {
                                this.setState({selectedTab: 1, isPayingCustomer: true})
                                return;
                            }
                        }
                    });
                }
            }
        });
    }
    /* stripe only */
    getCreditCardInfo = () => {
        DataHandler.get({url: 'settings/subscription/creditcard'}).done(response => {
            if (response == false) {
                this.getIntent();
            }
            this.setState({creditCardInfo: response});
        });
    }
    /* stripe only */
    getUserCount = () => {
        DataHandler.get({url: 'userCount/active'}).done(response => this.setState({
            licenceAmount: this.props.subId != false ? response.count : 1,
            licenceMin: this.props.subId != false ? response.count : 1
        }));
    }
    /* stripe only */
    async getIntent() {
        const response = await DataHandler.get({url: 'settings/subscription/getSetupIntent'});
        this.setState({setupIntent: response.intent.client_secret});
    }

    onChange = (e) => {
        let { state } = this;
        const { name, value } = e.target;

        state[name] = value;
        this.setState({state});
    }

    toggleChange = () => {
        this.setState({termsAccepted: !this.state.termsAccepted});
    }

    switchTabs = (e, value) => {
		this.setState({selectedTab: value});
    }

    planChanged = (id) => {
        if (id < this.state.currentPlan)
            return;

        this.setState({selectedPlan: id});
    }
    
    getBoxClassNames = (id) => {
        const { selectedPlan, currentPlan } = this.state;

        let classNames = "plan-box";

        if (selectedPlan == id)
            classNames += " selected";

        if (currentPlan == id)
            classNames += " current";

        return classNames;
    }

    async subscribe() {
        if (!this.state.termsAccepted && (this.state.currentPlan == 1 || !this.props.subId)) {
            this.setState({errorMessage: this.tr("You need to accept the terms of agreement before subscribing.")});
            return;
        }
        
        if (!this.state.stripeCustomerId) {
            this.sendInvoicedSubscriptionData();
            return;
        }

        this.setState({hideButtons: true, errorMessage: '', showProgress: true});

        if (!this.state.creditCardInfo) {
            let {setupIntent, error} = await this.props.stripe.handleCardSetup(this.state.setupIntent);
            
            if (error) {
                this.setState({errorMessage: error.message + " (" + error.code + ")", hideButtons: false, showProgress: false});
            } else {
                this.sendSubscriptionData(setupIntent.payment_method);
            }
        } else {
            this.sendSubscriptionData();
        }
    }

    upgrade = () => {
        //this.context.mixpanel.track('Upgrade Plan', {
            //'Previous plan': this.mixpanelPlanNames[this.state.currentPlan-1],
            //'New plan': this.mixpanelPlanNames[this.state.selectedPlan-1]
        //});

        DataHandler.post({url: 'auth/jwt'}).done(response => {
            sessionStorage.setItem('taimerToken', response.token);
            sessionStorage.setItem('taimerPrivileges', JSON.stringify(response.privileges));
            sessionStorage.setItem('taimerVersion',response.version);
            sessionStorage.setItem('taimerVersionId',response.version_id);
            sessionStorage.setItem('taimerAddons', JSON.stringify(response.addons));
            sessionStorage.setItem('onboardingEnded', false);
            
            window.location.href = "?module=dashboard&action=main&selectedTab=my-day"; 
        });
    }

    async comfirmPurchase(clientSecret) {
        let {paymentIntent, error} = await this.props.stripe.handleCardPayment(clientSecret);
        if (error) {
            this.setState({errorMessage: error.message + " (" + error.code + ")", hideButtons: false, showProgress: false});
        } else {
            this.setState({successMessage: this.tr("Subscription created successfully."), showProgress: false});
        }
    }
    
    sendInvoicedSubscriptionData = () => {
        let params = this.state;
        const { enqueueSnackbar } = this.props;
 
        DataHandler.request("POST", {url: `settings/invoiced_subscription/subscribe`}, params).done(
            (response) => {
                if (response.error == "TOO_FEW_LICENCES") {
                    enqueueSnackbar(this.tr(`You have ${this.state.licenceMin} active users. You have to have more or equal amount of licences`), {  variant: "error" });
                } else if (response.error == "TOO_MANY_LICENCES_GROWTH") {
                    enqueueSnackbar(this.tr(`Maximum number of licenses in Growth version is 3 licenses. You need to upgrade your subscription to Business or ERP version to enable more than 3 licenses.`), {  variant: "error" });
                } else if (response.error == "TOO_MANY_LICENCES_FREE") {
                    enqueueSnackbar(this.tr(`Maximum number of licenses in Free version is 1 license. You need to upgrade your subscription to enable more than 1 licenses.`), {  variant: "error" });
                }
                else {
                    enqueueSnackbar(this.tr(`Order sent to customer service`), {  variant: "info" });
                    
                    this.props.selectedPlan !== params.selectedPlan && this.upgrade();
                }
            }
        );
    }

    sendSubscriptionData = (paymentMethodId) => {
        let params = this.state;
 
        if (this.props.subId)
            params.subId = this.props.subId;

        if (paymentMethodId)
            params.paymentMethodId = paymentMethodId;
        
        DataHandler.request("POST", {url: `settings/subscription/subscribe`}, params).done(response => {
            if (response.success) {
                if (response.client_secret) {
                    this.comfirmPurchase(response.client_secret);
                } else if (response.upgrade) {
                    this.upgrade();
                } else {
                    this.setState({successMessage: this.tr("Subscription created successfully."), showProgress: false});
                }
            } else if (response.message == "discount_not_valid"){
                this.setState({errorMessage: this.tr("Campaign code is not valid."), hideButtons: false, showProgress: false});
            } else {
                this.setState({errorMessage: this.tr("Something went wrong. Please try again."), hideButtons: false, showProgress: false});
            }
        });
    }

    changeAddonStatus = (addon) => {
        if (this.context.addons[addon.code].limit == 0)
            return;
        
        this.props.toggleBuyDialog(addon.code);
    }

    checkAddonStatus = addon => {
        if (addon == undefined)
            return null;

        const { tr } = this;
        
        if (addon.type == "limit" && this.context.addons[addon.code] && this.context.addons[addon.code].limit == 0) {
            return <div className="feature-action active">{tr("Activated")}</div>
        } else if (addon.type == "onOff" && this.context.addons[addon.code] && this.context.addons[addon.code].limit == 0) {
            return <div className="feature-action active">{tr("Activated")}</div>
        } else {
            return <div className="feature-action" onClick={() => this.changeAddonStatus(addon)}>{tr("Activate")}</div>
        }
    }

    render() {
        const { selectedTab, selectedPlan, currentPlan, isPayingCustomer, showProgress } = this.state;
        const { tr } = this;
        const { taimerAccount } = this.context;
        
        const StyledTabs = withStyles({
			indicator: {
				display: "none",
            },
            root: {
                minHeight: "36px"
            }
		})(Tabs);

		const StyledTab = withStyles({
			root: {
				backgroundColor: "#f7f7f7",
				minWidth: '50px',
				minHeight: '35px;'
			},
			selected: {
				color: "#ffffff !important",
				backgroundColor: "#2d9ff7"
			}
        })(Tab);

        let plans = [];
        
        if (this.state.stripeCustomerId) {
            if (isPayingCustomer && this.props.subId == false) {
                plans = this.plans.map(p => {
                    if (p.id == currentPlan)
                        return p;
                    else return false;
                }).filter(e => e != false);
            }
            else if (isPayingCustomer) {
                plans = this.plans.map(p => {
                    if (p.id >= currentPlan)
                        return p;
                    else return false;
                }).filter(e => e != false);
            }
            else if (this.props.subId == false) {
                plans = this.plans.map(p => {
                    if (p.id == currentPlan)
                        return p;
                    else return false;
                }).filter(e => e != false);
            } else {
                plans = this.plans;
            }
        }
        else {
            plans = this.plans.filter(e => e.id >= selectedPlan);
        }

        const inputProps = {
            onChange: this.onChange,
            inputProps: {
                min: this.state.licenceMin,
                max: this.state.licenceMax
            }
        };

        return (
            <div id="order-view">
                <div id="order-header">
                    <LeftArrow className="backButton" onClick={() => window.history.back()} />
                    <h2 className="header-text">{!this.props.subId ? tr("Buy new subscription") : tr("Change subscription")}</h2>
                </div>

                {isPayingCustomer == false && this.state.stripeCustomerId && <div id="billing-cycle-tabs">
                    <StyledTabs
                        className="billing-tabs"
                        onChange={this.switchTabs}
                        value={selectedTab}>
                        <StyledTab key={0} label={this.tr("Annual billing")} selected={selectedTab == 0}/>;
                        <StyledTab key={1} label={this.tr("Monthly billing")} selected={selectedTab == 1}/>;
                    </StyledTabs>

                    <h3>{tr("Buy annual plan and save 21%")}</h3>
                </div>}

                {(currentPlan == 1 || !this.props.subId) && (<div className="row">
                    {this.state.stripeCustomerId && 
                    <div class="slot" id="credit-card-info">
                        <h3>{tr("Payment card information")}</h3>
                        <div id="card-element-wrapper">
                            {this.state.creditCardInfo ?
                                <React.Fragment>
                                    <div class="card-data">
                                        {tr("Card")}: {this.state.creditCardInfo.brand} / **** **** **** {this.state.creditCardInfo.last4}
                                    </div>
                                    <div class="card-data">
                                        {tr("Expires")}: {this.state.creditCardInfo.exp_month} / {this.state.creditCardInfo.exp_year}
                                    </div>
                                </React.Fragment>
                            :
                                <CardElement />
                            }
                        </div>
                    </div>}

                    <div class="slot" id="licences">
                        <h3>{tr("Number of licences")}</h3>
                            <OutlinedField type="number" className="licences" label={this.tr("Number of licences you want to buy")} name="licenceAmount" value={this.state.licenceAmount} {...inputProps}/>
                    </div>

                    <div class="slot" id="discount-info">
                        <h3>{tr("Campaign code")}</h3>
                        <form id="discount-form">
                            <OutlinedField className="discount-code" label={this.tr("Add campaign code here")} name="discountCode" value={this.state.discountCode} {...inputProps}/>
                        </form>
                    </div>

                    <div class="slot" id="discount-info">
                        <h3>{tr("Terms of agreement")}</h3>
                        <div>
                            <input type="checkbox" name="termsAccepted" onChange={this.toggleChange}/>{tr("I have read and accept Heeros PSA's")}
                            {this.context.userObject.language == "fi" 
                            ? <a target="_blank" href="https://psahelpcenter.heeros.com/hc/fi/articles/10207381240978"> {this.tr("Terms of agreement")} </a>
                            : <a target="_blank" href="https://psahelpcenter.heeros.com/hc/en-us/articles/10207381240978"> {this.tr("Terms of agreement")} </a>}
                        </div>
                    </div>
                </div>)}

                <div id="plan-wrapper">
                    <div id="plan-list">
                        {plans.map(plan => {
                            return (
                                <div key={plan.id} className={this.getBoxClassNames(plan.id)} onClick={() => this.planChanged(plan.id)}>
                                    <div className="plan-name">{tr(plan.name)}</div>
                                    <div className="plan-price">{tr("Contract price")}</div>
                                    {/*<div className="plan-price">{plan.prices[selectedTab]} € / {this.tr('user')} / {(selectedTab == 1 ? tr('month') : tr('year'))}</div>*/}
                                    <div className="current-box-wrapper">
                                        <div className="current-box">
                                            {this.tr('Current plan')}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        <div id="error-message">
                            {this.state.errorMessage}
                        </div>

                        <div id="success-message">
                            {this.state.successMessage}
                        </div>

                        {(currentPlan == 1 && selectedPlan != 1 || currentPlan > 1) && !this.state.hideButtons && <Button onClick={() => this.subscribe()} id="order-button"  size="large" color="primary">{selectedTab == 0 ? this.tr('Buy annual plan') : this.tr('Buy monthly plan')}</Button>}  

                        {showProgress && <div class="progress-wrapper"><CircularProgress /></div>}
                    </div>

                    <div id="feature-list-wrapper">
                        <h3>{tr("Features")}</h3>
                        <div id="feature-list">
                            {this.features.map(feature => {
                                let addonData = this.addonData.find(addon => addon.id == feature.id && addon.show.indexOf(selectedPlan) > -1);
                                return (
                                    <div className={"row" + ((feature.available.indexOf(selectedPlan) > -1 || (addonData &&  this.context.addons[addonData.code] && this.context.addons[addonData.code].limit == 0)) ? " " + tr('available') : "")}>
                                        <CheckCircle size="small" />
                                        <div className="feature-name">{feature.name}</div>
                                        {this.props.subId != false && <div className="feature-info">{addonData ? addonData.info : ''}</div>}
                                        {this.props.subId != false && this.checkAddonStatus(addonData)}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(injectStripe(OrderForm));
