import React, { useState } from "react";
import "./OverviewBoxes.css";
import InsightSlider from "../../insights/InsightSlider";
import { Star } from "@mui/icons-material";

const OverviewBox = (props) => {
  const { setSliderData, item } = props;
  return (
    <div
      onClick={() =>
        setSliderData({
          open: true,
          data: item.sliderData,
          title: item.sliderTitle,
          columns: item.sliderColumns,
        })
      }
      className="overview-box"
    >
      <div className="highlight-container" />
      <div className="value-container">
        <h1 style={{ color: item.color }}>{item.value}</h1>
        <h2>
          {item.title} 
          {item.needsUpgrade && <Star />}
        </h2>
        {item.subtitle != null && <h3>{item.subtitle}</h3>}
      </div>
    </div>
  );
};

const OverviewBoxes = (props) => {
  const { topItems, bottomItems } = props;
  const [sliderData, setSliderData] = useState({
    open: false,
    title: "",
    columns: [],
    data: [],
  });
  return (
    <React.Fragment>
      <InsightSlider
        onClose={() =>
          setSliderData({ ...sliderData, open: false })
        }
        open={sliderData && sliderData.open}
        data={sliderData && sliderData.data}
        label={sliderData && sliderData.title}
        columns={sliderData && sliderData.columns}
      />
      <div className="overview-boxes">
        <div className="row">
          {topItems.map((item, i) => (
            <OverviewBox key={i} item={item} setSliderData={item.overrideOnClick ? item.overrideOnClick : setSliderData} />
          ))}
        </div>
        <div className="row">
          {bottomItems.map((item, i) => (
            <OverviewBox key={i} item={item} setSliderData={setSliderData} />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OverviewBoxes;
