import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

function TopButton(props) {
  const { cname, icon, onClick, tooltip } = props;
  return (
    <Tooltip title={tooltip} aria-label={tooltip} >
      <Button onClick={onClick} variant="contained" variant="outlined" color="info" centerRipple="true" className={cname}>
          {icon}
      </Button>
    </Tooltip>
  );
}

export default TopButton;