import React from 'react';
import {
    makeMap, 
    makeMapOfPrimitives, 
    findRecursively, 
    gatherIdsRecursively, 
    gatherTree, 
    gatherAncestors, 
    treeFormatDataForList, 
    formatDataForList, 
    findMaxDepth, 
    flattenTreeFormattedData,
    createOrderMap
} from "./ListUtils";
import {
	gatherFromRoot,
	findRoot,
	createHierarchyDataMap,
    getChildDepth
} from "./../general/TreeUtils";
import { intersection, difference } from "./../general/Set";
import Utils from "./../general/Utils";
import TaimerComponent from "../TaimerComponent";
import PropTypes from "prop-types";
import Select from '@mui/material/Select';
import { SettingsContext } from '../SettingsContext';

import "./List.css";
import MenuItem from '@mui/material/MenuItem';

import ListHeader from "./ListHeader";
import PropsOnlyListRow from "./PropsOnlyListRow";
import PageSelector from "./PageSelector";
import debounce from 'lodash/debounce';
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual"; 

/* 
 * Last resort for if a client can't see your newly added column(s) on a list; 
 * change this string to something else – append a number to the end or something.
 */

const LIST_SETTINGS_KEY_PREFIX          = "taimer_list_settings_for_"; 
const LIST_VIRTUALIZATION_EXTRA_PADDING = 30; 

class BottomMarker extends PropsOnlyListRow {
    constructor(props) {
        super(props); 
    }


    defineClassName() {
        return "bottomMarker";
    }


    defineCells() {
        return {};
    }


    shouldComponentUpdate() {
        return false; 
    }
}

class List extends TaimerComponent {
    static defaultProps = {
        allChecked: false,
        alternativeRows: false,
        alwaysShowHorizontalScrollBar: false,
        attributes: [],
        checkableRowFilter: () => true,
        checkedRowsAmount: undefined,
        checkedRowsAmountFn: (amount) => amount,
        checkedRows: undefined,
        className: "",
        columnDefaultWidth: 200,
        columnHeaderConfigKey: undefined,
        columnOrder: undefined,
        columns: [],
        commonCellProps: {}, // Props that will be given to every cell in ListRow's render.
        controlOrder: false,
        controlPage: false,
        data: [],
        defaultColumnHeaderConfig: {},
        disableCheck: false,
        disableInitialFocusOnRow: false,
        disableNewData: false, // TODO: Remove when state.newData isn't in use anymore.
        dragDropIsAllowed: (dragged, draggedOver, currentOrder) => true,
        // editMode can be
        // "one_cell" - the normal edit mode, where clicking on a cell only enables the clicked cell for editing
        // "row" - clicking one cell enables the whole row for editing
        editMode: "one_cell",
        emptyNewDataOnUpdate: true,
        enableToolbar: false,
        enforceMinimumWidths: true,
        fixFluidOverflow: false,
        fluid: false,
        height: 600,
        hideBottomMarker: true,
        hideFluidFix: false,
        hideHeaderButtons: false,
        hideHeader: false,
        idType: "number",
        ignoreRowPropsChange: true,
        infiniteScrollTriggerLimit: 10,
        infiniteScrollDynamicPageNumbering: false,
        listRowTypeClassNames: { default: "" },
        listRowTypeKey: "_type",
        listRowTypeMap: undefined,
        listRowType: undefined,
        manualCreate: false,
        manualListWidth: false,
        minHeight: 224,
        maxHeight: undefined,
        minimizeRerendersInTrees: false,
        newRow: {},
        newRowLimit: undefined,
        newRowMap: undefined,
        newRowType: undefined,
        noResultsMessage: undefined,
        noStateData: false,
        onAddNewRow: (newRows) => {},
        onCheck: () => {},
        onCheckAll: () => {},
        onDataChange: () => {},
        onDrag: (draggedRow, props, currentOrder) => {},
        onDraggedRowDrop: (dragger, droppedOver, currentOrder, dataMap, listRef) => {},
        onDragStart: (e) => {},
        onEdit: undefined,
        onEnterEditMode: (row, data, list) => {},
        onExitEditMode: (row, data, list) => {},
        onInfiniteScroll: (pageNumber) => {},
        onInfiniteScrollPageEnd: () => {},
        onMouseLeave: () => {},
        onMouseMove: () => {},
        onPageChange: () => {},
        onPerPageChange: () => {},
        onPressPlus: () => {},
        onScroll: (scrollTop) => {},
        onSortRows: (columnName, isAscending) => {},
        onToolbarDeleteClick: () => {},
        onToolbarEditClick: () => {},
        onToolbarExportClick: () => {},
        onUncheckAll: () => {},
        overrideAddNewRow: false, // Pass a function or falsy.
        page: 1,
        pageCount: 999,
        parentKey: "parentId",
        parentsExpandedOnInit: true,
        perpage: 30,
        presentation: undefined,
        renderNewDataAtEnd: false,
        reverseNewData: false,
        rowCallbacks: {},
        rowDragging: false,
        rowHeightFix: false,
        rowHeight: undefined,
        rowKey: "id",
        rowOrder: undefined,
        rowProps: {},
        saveColumnConfig: false,
        searchableHeader: false,
        sharedData: {},
        showNoResultsMessage: false,
        showPageSelector: false,
        style: {},
        summaryRowProps: {},
        summaryRowType: undefined,
        toolbarColumnOrder: undefined,
        totalCount: 999,
        treeData: false,
        trimHeight: 0,
        useAllCheckedExcept: false,
        useGlobalAllChecked: true, // Rename this someday. Heh.
        useHSRightPadding: false,
        userListSettingsKey: undefined,
        visibilityFilter: undefined,
        virtualized: false,
        virtualization__afterVirtualizationOrderRotation: () => {},
        virtualization__beforeVirtualizationOrderRotation: () => {},
        virtualization__overscan: 0,
        virtualization__useHorizontalVirtualization: false,
        virtualization__rowAmount: undefined, // TODO: Rename everywhere.
	};


	static propTypes = {
	    height: PropTypes.string
	};


	constructor(props, context) {
		super(props, context, "list/List");

		this.contentDiv       = React.createRef();
		this.outerMainWrapper = React.createRef();
		this.mainWrapper      = React.createRef();
		this.mainList         = React.createRef();
		this.summaryRow       = React.createRef();
		this.resizeMarker     = React.createRef();
		this.header           = React.createRef();
		this.pageSelector     = React.createRef();
        this.listFooter       = React.createRef();
        this.listHeader       = React.createRef();
		this.fluidFix         = React.createRef();

        this.initializationTimeout  = null;
        this.resizeFlipTimeout      = null;
        this.listElementReferences  = [];
        this.rowRefMap              = {};
        this.depthControlledColumns = [];
        this.maxDepth               = undefined;

        this.checkPreconditions();

        const conf = this.configureColumns(props.columns, true);

        // Attributes for rows -->
        const expandedParents = {};
		const flips           = {};

        if(Array.isArray(this.props.data)) {
            if(this.props.parentsExpandedOnInit)
                this.props.data.forEach(el => expandedParents[el[this.props.rowKey]] = true);

            this.props.data.forEach(el => flips[el[this.props.rowKey]] = 0);
        } else {
            console.warn("The data prop for List is not an array.");
        }
        // <-- attributes for rows.

        if(this.props.onColumnVisibilityChange) {
            this.props.onColumnVisibilityChange(conf.columns);
        }

        this.state = {
            mounted: false,
            newData: [], // TODO: Get rid of this separation for new and existing data; the id of the row should indicate.. Newness.
			data: this.props.data,
            attributes: this.props.attributes,
			height: this.props.height !== "fitRemaining" ? parseInt(this.props.height) : "100%",
			listWidth: conf.listWidth,
			columnWidths: conf.columnWidths,
			columns: conf.columns,
			currentPage: 1,
            checkedRows: this.props.checkedRows !== undefined ? makeMapOfPrimitives(this.props.checkedRows) : {},
            allCheckedExcept: undefined,
			allChecked: props.allChecked,
			minimumScaleWidth: conf.minimumScaleWidth,
            childrenVisibleRows: expandedParents,
            flips,
            // Both are for virtualization, effectively: 
            dataRenderStartIndex: 0, // this + props.virtualization__rowAmount is which rows should be visible
            columnRenderStart: 0, // start col
            columnRenderLength: 0,
            hiddenRows: {},
            hiddenRowAmountInCurrentData: 0,
            isLoading: false
		};
        
        // Note that initVirtualizationState does the same initialization for 
        // virtualization state as done here.
        // It's used to reset said state when data changes, etc.

        // Virtualization state:
        // From now on, the underscore before a property name indicates a state variable
        // that's used "outside" React's state, because it makes sense for this variables mutations to 
        // affect rendering.
       
        // Virtualization state -->
        this._previousStart          = 0;
        this._order                  = {};
        this._prevDiff               = 0;
        this._rowAmount                = 0;
        this._overscanActive         = false;
        this._previousScrollTop      = undefined;
        this._previousScrollLeft     = undefined;
        this._scrollEndTimeout       = undefined;
        this._firstWrapperScrollDone = false;

        // Hyvä.
        this.__rowConfigurations = {
            columnWidthMap: {},
            columnWidthMaps: {},
            columnOrder: [],
            columnConfig: {},
            columnConfigs: {},
            columnOrders: {},
            cellPresentations: {},
        };

        this.initVirtualizationOrder();
        // <-- Virtualization state

        // State used to determine
        // whether a page change event should 
        // be triggered
        // (Not directly associated with virtualization).
        //  -->
        this._infiniteScrollEventActive = false;
        this._prevHowManyRowsDown       = 0;
        this._prevInfiniteScrollPage    = 0;
        // <-- Scrolling state.

        this.parentKey                 = props.parentKey;
        this.nextNewRowId              = -1;
        this.nextNewRowIdOld           = -1; // Used in the "old" way of adding a new row.
        // This wasn't previously done here, 
        // but this caused a bug where componentDidUpdate
        // didn't trigger if props.data was given
        // for Listthe first render in the component's lifecycle.
        // this.formattedData             = this.formatData(this.props.visibilityFilter ? this.state.data.filter(this.props.visibilityFilter) : this.state.data); 
        
        // This call replaces the above assignment.
        this.handleDataChange(this.state.data);

        this.dataMap                   = {};
        this.attributeMap              = {};
        this.hierarchyDataMap          = createHierarchyDataMap(this.state.data, this.props.parentKey, this.props.rowKey);
        this.treeMetaDataMap           = {};
        this.currentOrder              = [];
        this.lastRenderRows            = [];
        this.dataInitialized           = false;
        this.virtualizationInitialized = false;
        this.pageChanged               = false;

        // ROW dragging state (not in React state, since the whole thing is very heavy and having 
        // List check prop and state equality on every drag event would be ineffective.
        this.dragging                           = false; 
        this.draggedRowId                       = undefined;
        this.currentlyDraggingOverId            = undefined; // Before TypeScript's enums, undefined will be used for no-value, and null for when the last dragging happened over a forbidden row type.
        this.bottomMarkerData                   = { id: "BOTTOM_MARKER" };

		// Method bindings.
        this.checkPreconditions                 = this.checkPreconditions.bind(this);
        this.initVirtualizationState            = this.initVirtualizationState.bind(this);
        this.initVirtualizationOrder            = this.initVirtualizationOrder.bind(this);
        this.setScrollTop                       = this.setScrollTop.bind(this);
        this.handleInfiniteScroll               = this.handleInfiniteScroll.bind(this);
        this.triggerInfiniteScrollEvent         = this.triggerInfiniteScrollEvent.bind(this);
        this.triggerInfiniteScrollPageEnd       = this.triggerInfiniteScrollPageEnd.bind(this);
        this.endInfiniteScrollEvent             = this.endInfiniteScrollEvent.bind(this);
        this.getScrolledRowAmount               = this.getScrolledRowAmount.bind(this);
        this.determineVisibleRowsAmount         = this.determineVisibleRowsAmount.bind(this);
        this.determineVirtualizationStartHeight = this.determineVirtualizationStartHeight.bind(this);
        this.onScroll                           = this.onScroll.bind(this);
        this.onWrapperScroll                    = this.onWrapperScroll.bind(this);
        this.handleVirtualizedVerticalScroll    = this.handleVirtualizedVerticalScroll.bind(this);
        this.handleVirtualizedHorizontalScroll  = this.handleVirtualizedHorizontalScroll.bind(this);
        this.rotateVirtualizationOrder          = this.rotateVirtualizationOrder.bind(this);
        this.initScrollEndTimeout               = this.initScrollEndTimeout.bind(this);
        this.hasSavedConfig                     = this.hasSavedConfig.bind(this);
        this.getSavedConfig                     = this.getSavedConfig.bind(this);
        this.invalidateSavedConfig              = this.invalidateSavedConfig.bind(this);
        this.getSavedConfigKey                  = this.getSavedConfigKey.bind(this);
        this.configureColumns                   = this.configureColumns.bind(this);
		this.addNewRow                          = this.addNewRow.bind(this);
		this.newAddNewRow                       = this.newAddNewRow.bind(this);
		this.oldAddNewRow                       = this.oldAddNewRow.bind(this);
        this.removeNewRow                       = this.removeNewRow.bind(this);
        this.removeRow                          = this.removeRow.bind(this);
		this.emptyNewData                       = this.emptyNewData.bind(this);
        this.batchEdit                          = this.batchEdit.bind(this);
        this.batchEditData                      = this.batchEditData.bind(this);
		this.editData                           = this.editData.bind(this);
        this.batchEditAttributes                = this.batchEditAttributes.bind(this);
		this.editAttributes                     = this.editAttributes.bind(this);
		this.runOnDataChange                    = this.runOnDataChange.bind(this);
        this.castId                             = this.castId.bind(this);
        this.setData                            = this.setData.bind(this);
        this.getData                            = this.getData.bind(this);
        this.getItem                            = this.getItem.bind(this);
        this.setAttributes                      = this.setAttributes.bind(this);
        this.getAttributes                      = this.getAttributes.bind(this);
        this.getAttributesFor                   = this.getAttributesFor.bind(this);
        this.getRow                             = this.getRow.bind(this);
        this.getCheckedData                     = this.getCheckedData.bind(this);
		this.setPage                            = this.setPage.bind(this);
        this._setPage                           = this._setPage.bind(this);
        this.getVisibleRows                     = this.getVisibleRows.bind(this);
        this.toggleShowChildren                 = this.toggleShowChildren.bind(this);
        this.showChildren                       = this.showChildren.bind(this);
        this.gatherTree                         = this.gatherTree.bind(this);
        this.gatherTreeSingle                   = this.gatherTreeSingle.bind(this);
        this.gatherFlips                        = this.gatherFlips.bind(this);
        this.flipRows                           = this.flipRows.bind(this);
        this.flipAllRows                        = this.flipAllRows.bind(this);
        this.startRowDrag                       = this.startRowDrag.bind(this);
        this.setCurrentlyDragging               = this.setCurrentlyDragging.bind(this);
        this.setCurrentlyDraggingOver           = this.setCurrentlyDraggingOver.bind(this);
        this.setCurrentlyDraggingOverNotAllowed = this.setCurrentlyDraggingOverNotAllowed.bind(this);
        this.isDragging                         = this.isDragging.bind(this);
        this.dragDropIsAllowed                  = this.dragDropIsAllowed.bind(this);
        this.endRowDrag                         = this.endRowDrag.bind(this);
        this.check                              = this.check.bind(this);
        this.checkAll                           = this.checkAll.bind(this);
        this.uncheckAll                         = this.uncheckAll.bind(this);
        this.getAllChecked                      = this.getAllChecked.bind(this);
        this.getAllCheckedExcept                = this.getAllCheckedExcept.bind(this);
		this.getCheckedRows                     = this.getCheckedRows.bind(this);
        this.getColumnConfig                    = this.getColumnConfig.bind(this);
        this.toolbarShouldBeVisible             = this.toolbarShouldBeVisible.bind(this);
        this.noResultsMessageShouldBeShown      = this.noResultsMessageShouldBeShown.bind(this);
		this.saveColumnConfig                   = this.saveColumnConfig.bind(this);
        this.getColumnConfigKeys                = this.getColumnConfigKeys.bind(this);
        this.handleDataChange                   = this.handleDataChange.bind(this);
        this.determineTreeMetaData              = this.determineTreeMetaData.bind(this);
		this.formatData                         = this.formatData.bind(this);
		this.calculateWidths                    = this.calculateWidths.bind(this);
		this._calculateWidths                   = this._calculateWidths.bind(this);
        this.configureRows                      = this.configureRows.bind(this);
		this.fixFluidWidth                      = this.fixFluidWidth.bind(this);
		this.addCloseColumnMenuWindowListener   = this.addCloseColumnMenuWindowListener.bind(this);
        this.resetCheckedRows                   = this.resetCheckedRows.bind(this);
        this.resetStateData                     = this.resetStateData.bind(this);
		this.windowOnResize                     = debounce(this.windowOnResize.bind(this), 100);
		this.startPageChangeAnimation           = this.startPageChangeAnimation.bind(this);
		this.endPageChangeAnimation             = this.endPageChangeAnimation.bind(this);
        this.startDataChangeAnimation           = this.startDataChangeAnimation.bind(this);
        this.endDataChangeAnimation             = this.endDataChangeAnimation.bind(this);
        this.runHeightSetCallback               = this.runHeightSetCallback.bind(this);
		this.startGrabScrollCounter             = this.startGrabScrollCounter.bind(this);
		this.stopGrabScrollCounter              = this.stopGrabScrollCounter.bind(this);
		this.startGrabScroll                    = this.startGrabScroll.bind(this);
		this.grabScroll                         = this.grabScroll.bind(this);
		this.endGrabScroll                      = this.endGrabScroll.bind(this);
        this.getScrollTop                       = this.getScrollTop.bind(this);
		this.bodyMouseMoveListenerForGrabScroll = event => this.grabScroll(event);
		this.bodyMouseUpListenerForGrabScroll   = event => this.endGrabScroll(event);
		this.onHeaderSearchChange               = this.onHeaderSearchChange.bind(this);
        this.determineRowType                   = this.determineRowType.bind(this);
        this.getColumnKey                       = this.getColumnKey.bind(this);
        this.runOnEnterEditMode                 = this.runOnEnterEditMode.bind(this);
        this.runOnExitEditMode                  = this.runOnExitEditMode.bind(this);
	}


    componentDidMount() {
        super.componentDidMount();

        const { minimumScaleWidth } = this.state;

        if(typeof(this.props.height) === "function") {
            this.runHeightSetCallback()
        }

        if(this.props.height === "fitRemaining") {
            this.determineAndSetHeightOnFit();
        }

        window.addEventListener("resize", this.windowOnResize);
        window.addEventListener("customViewLayoutChanged", this.windowOnResize);

        if(this.props.height === "auto") {
            this.determineAndSetHeightOnAuto();
        }

        if(this.props.fluid) {
            this.fixFluidWidth();
        }

        this.configureRows();

        if(this.props.virtualized) {
            // TODO: Move the column start index etc. here.
            this.initVirtualizationState();
        }

        // Store the handle of the timeout, so we can cancel it in componentWillUnmount.
        this.initializationTimeout = setTimeout(() => {
            const scaled = this.calculateWidths(this.state.columns, this.outerMainWrapper.current.clientWidth);

            this.setState({
                columnWidths: scaled.columnWidths, 
                listWidth: scaled.listWidth,
                mounted: true
            }, () => {
                if(Array.isArray(this.props.data) && this.props.data.length > 0) {
                    this.dataMap = makeMap(this.state.data, this.props.rowKey);

                    this.handleDataChange(this.state.data);
                    
                    this.dataInitialized = true;
                }

                this.handleVirtualizedHorizontalScroll();

                this.props.onListMounted && this.props.onListMounted();
                this.runOnDataChange("initial");
            });
        }, 100);
    }


    checkPreconditions() {
        const { 
            virtualization__overscan, 
            saveColumnConfig, 
            userListSettingsKey,
            useInfiniteScroll,
            onInfiniteScroll
        } = this.props;

        if((virtualization__overscan > 0 && virtualization__overscan % 2 > 0) || virtualization__overscan < 0) {
            throw `The overscan prop must be a positive, even integer; ${virtualization__overscan} given.`;
        }

        if(saveColumnConfig && !userListSettingsKey) {
            throw "List is set to save its column configuration, but props.userListSettingsKey is falsy.";
        }

        if(useInfiniteScroll && typeof(onInfiniteScroll) !== "function") {
            throw "List is set to use infinite scroll, but props.onInfiniteScroll is not a function."; 
        }
    }


    determineVisibleRowsAmount() {
        const { 
            virtualization__overscan,
            virtualization__rowAmount,
            rowHeight
        } = this.props;

        const {
            data
        } = this.state;

        let n;

        if(virtualization__rowAmount !== undefined) {
            n = virtualization__rowAmount;
        } else {
            n = Math.ceil(this.mainList.current.clientHeight / rowHeight) + 1;
        }

        n = (n > data.length) 
            ? data.length 
            : (n + virtualization__overscan);

        // Prevent two rows of the same color
        // appearing on the list consecutively.
        n = n % 2 > 0 
            ? n + 1
            : n;

        // TODO: Refactor when overscan has been implemented properly. 
        // Now it only works at the end of the list.
        return n;
    }


    determineVirtualizationStartHeight() {
        return this.state.dataRenderStartIndex * this.props.rowHeight;
    }


    createColumnOrder(order, columns) {
		const newOrder = [];

		for(const colName of order) {
			const found = columns.find(c => c.name === colName);

			// Fix the cause rather than this: it seems any element can be moved in the header. This should only be possible for .columns.
			if(found === undefined)
				continue;

			newOrder.push(found);
		}

		if(this.props.onColumnOrderChange) {
			this.props.onColumnOrderChange(newOrder.map(el => el.name));
		}

		return newOrder;
	}


    // TODO: When we move config storing into the db, refactor these methods.
    hasSavedConfig() {
        const config = localStorage.getItem(this.getSavedConfigKey());
        
        return Boolean(config);
    }


    // TODO: When we move config storing into the db, refactor these methods.
    getSavedConfig() {
        return cloneDeep(JSON.parse(localStorage.getItem(this.getSavedConfigKey())));
    }


    // TODO: When we move config storing into the db, refactor these methods.
    invalidateSavedConfig() {
        localStorage.removeItem(this.getSavedConfigKey());
    }


    getSavedConfigKey() {
        return `${LIST_SETTINGS_KEY_PREFIX}${this.props.userListSettingsKey}`;
    }


    // TODO: Should this be in ListHeader?
    configureColumns(columnsPropOrig, returnConf = false) {
        // Clone the columns prop because the forEach below mutates the object.
        const columnsProp        = cloneDeep(this.usesMultipleListRowTypes() ? columnsPropOrig[this.props.columnHeaderConfigKey] : columnsPropOrig); 
		const columnMap          = {};
		let minimumScaleWidth  = 0;

        let hasSavedConfig     = false;
        let savedConfig        = false;
        let savedConfigIsValid = false;
        let savedConfigMap     = {};
        let validOrder         = [];

        let columns;

        if(this.props.saveColumnConfig && this.hasSavedConfig()) {
            // TODO: Refactor everything pertaining to local storage.
            hasSavedConfig               = true;
            savedConfig                  = this.getSavedConfig();

            const currentColumnNames     = columnsProp.map(c => c.name);
            const savedConfigColumnNames = savedConfig.map(c => c.name);

            let validCurrent             = intersection(currentColumnNames, savedConfigColumnNames);
            const validCurrentMap        = makeMapOfPrimitives(validCurrent);
            const addToTheEnd            = difference(currentColumnNames, validCurrent);
            validCurrent                 = savedConfigColumnNames.filter(name => validCurrentMap[name]);

            validOrder                   = [...validCurrent, ...addToTheEnd];
            const addToIndexes           = columnsProp.filter(cp => cp.staticIndex != undefined);

            addToIndexes.forEach(item => {
                const foundIndex = validOrder.findIndex(name => name == item.name);
                if (foundIndex != -1) {
                    validOrder.splice(foundIndex, 1);
                }
                validOrder.splice(item.staticIndex, 0, item.name);
            });

            const validMap               = makeMapOfPrimitives(validOrder);

            savedConfigMap               = makeMap(savedConfig, "name");
            savedConfigIsValid           = (Object.keys(savedConfigMap).filter(key => validMap[key]).map(key => parseInt(savedConfigMap[key].width)).filter(width => width === null || isNaN(width)).length === 0);

            if(!savedConfigIsValid) {
                this.invalidateSavedConfig();
            }
        } else {
            validOrder = columnsProp.map(c => c.name);
        }

        columnsProp.forEach(column => {
            Object.keys(this.props.defaultColumnHeaderConfig).forEach(key => {
                if(column.hasOwnProperty(key)) {
                    return;
                }

                column[key] = this.props.defaultColumnHeaderConfig[key];
            });
        });

        // Default values for column header attributes.
        // Warning: edited by reference.
		columnsProp.forEach(column => {
			column['menuOpen']                 = false;
			column['columnVisibilityMenuOpen'] = false;
			column['showMenu']                 = column.hasOwnProperty("showMenu") ? column.showMenu : true;
			column['showMenuContainer']	       = column.hasOwnProperty("showMenuContainer") ? column.showMenuContainer : false;
            column['visible']                  = column.hasOwnProperty("visible") ? column.visible : true;
            column['visibleInToolbar']         = column.hasOwnProperty("visibleInToolbar") ? column.visibleInToolbar : false;
			column['resizeable']               = column.hasOwnProperty("resizeable") ? column.resizeable : true;
			column['moveable']                 = column.hasOwnProperty("moveable") ? column.moveable : true;
			column['hideable']                 = column.hasOwnProperty("hideable") ? column.hideable : true;
            column['hidden']                   = column.hasOwnProperty("hidden") ? column.hidden : false;
			column['sortable']                 = column.hasOwnProperty("sortable") ? column.sortable : true;
			column['showResizeMarker'] 		   = column.hasOwnProperty("showResizeMarker") ? column.showResizeMarker : true;
            column['type'] 					   = column.hasOwnProperty("type") ? column.type : undefined;
			column['width'] 				   = column.hasOwnProperty("width") ? column.width : this.props.columnDefaultWidth;
            column['alignment']                = column.hasOwnProperty("alignment") ? column.alignment : "left";
            column['className']                = column.hasOwnProperty("className") ? column.className : "";

            if(this.props.saveColumnConfig && hasSavedConfig && savedConfigIsValid && !column.dontSaveConfig) {
                column = { ...column, ...savedConfigMap[column['name']] };
            }

			columnMap[column.name] = column;
			minimumScaleWidth += column['width'];
        });

		// localStorage schema for columns:
		// columns' order in the array defines the order in the ui.
		/*
			[
				{
					name: id,
					width: 50,
					visible: true
				},
				{
					name: name,
					width: 200,
					visible: true
				},
				{
					name: parentId,
					width: 150,
					visible: false
				},
			]
		*/

        columns = validOrder.map(name => columnMap[name]);

		const widths 	 = this.calculateWidths(columns, this.outerMainWrapper.current && this.outerMainWrapper.current.clientWidth);
        this.columnOrder = columns.map(c => c.name);
        this.visibleColumnsOrder = columns.filter(a => a.visible).map(c => c.name);

        const conf = {
			listWidth: widths.listWidth,
			columnWidths: widths.columnWidths,
			columns: this.createColumnOrder(this.columnOrder, columns),
			minimumScaleWidth
        };

        if(returnConf) {
            return conf;
        } else {
            this.setState(conf);
        }
	}


	fixFluidWidth() {
		// Fix width of header and content to be equal so they match
		if(!this.mainList.current || !this.fluidFix.current)
			return;

		let scrollbarExtra = Math.max(0, this.mainList.current.offsetWidth - this.mainList.current.clientWidth);

		if(scrollbarExtra > 0)
			scrollbarExtra++;

		this.fluidFix.current.style.width = `${scrollbarExtra}px`;
	}


	componentWillUnmount() {
		super.componentWillUnmount();

        clearTimeout(this.initializationTimeout);

		window.removeEventListener("resize", this.windowOnResize);
		window.removeEventListener("customViewLayoutChanged", this.windowOnResize);
	}


    // Getting a little complex here..
    // But almost anything is better than reconciling such a component as List is.
    shouldComponentUpdate(nextProps, nextState) {
        const stateEqual   = isEqual(nextState, this.state);
        const shouldUpdate = !this.props.noStateData || (!isEqual(nextProps, this.props) || !stateEqual);

        if(!shouldUpdate) {
            return false;
        }

        if(!stateEqual && !isEqual(this.state.data, nextState.data)) {
            this.handleDataChange(nextState.data);
        }

        return true;
    }

	windowOnResize() {
        this.resizeFlipTimeout && clearTimeout(this.resizeFlipTimeout);

		this.resizeFlipTimeout = setTimeout(() => {
			if(this.props.height === "fitRemaining") {
				this.determineAndSetHeightOnFit();
            }
            
            const scaled = this.calculateWidths(this.state.columns, this.outerMainWrapper.current.clientWidth);
            
            this.setState({ 
                columnWidths: scaled.columnWidths, 
                listWidth: scaled.listWidth 
            }, () => {
                // This is done so this._rowAmount, which can be calculated
                // based on the window's size, will be recalculated.
                this.initVirtualizationState();
                this.flipAllRows();

                if(typeof(this.props.height) === "function") {
                    this.runHeightSetCallback();
                }
            });
		}, 200);
    }


    // Ensures you get the column configs in the correct order; main config first, and only after that the others.
    getColumnConfigKeys() {
        return (this.usesMultipleListRowTypes() ? [this.props.columnHeaderConfigKey, ...Object.keys(this.props.columns).filter(f => f !== this.props.columnHeaderConfigKey)] : ["default"]);
    }


    // TODO: Refactor; this is the first draft of this function.
    configureRows() {
        const usesMultipleListRowTypes = this.usesMultipleListRowTypes();
        const columnOrder              = this.state.columns.filter(c => c.visible).map(c => c.name);
        const columnOrderClone         = clone(columnOrder);

        this.__rowConfigurations = {
            columnWidthMap:    {},
            columnWidthMaps:   {},
            columnOrder:       columnOrder,
            columnConfig:      {},
            columnConfigs:     {},
            columnOrders:      {},
            cellPresentations: {},
        };

		for(const cw of this.state.columnWidths) {
            this.__rowConfigurations.columnWidthMap[cw['name']] = cw['width'];
        }

		for(const col of cloneDeep(this.state.columns)) {
			const key = this.getColumnKey(col);

			this.__rowConfigurations.columnConfig[key]       = col;
			this.__rowConfigurations.columnConfig[key].width = this.__rowConfigurations.columnWidthMap[key];
        }

        (this.getColumnConfigKeys()).forEach(rowType => {
            let orderDefined = false;

            this.__rowConfigurations.columnConfigs[rowType]     = {};
            this.__rowConfigurations.columnWidthMaps[rowType]   = {};
            this.__rowConfigurations.cellPresentations[rowType] = {};
            this.__rowConfigurations.columnOrders[rowType]      = [];

            // All other column orders are defined based on the column header order.
            if(rowType === this.props.columnHeaderConfigKey) {
                this.__rowConfigurations.columnOrders[rowType] = columnOrderClone;

                orderDefined = true;
            }

            let presentation = {};
            let colConf      = usesMultipleListRowTypes && rowType !== this.props.columnHeaderConfigKey 
                ? 
                this.props.columns[rowType] 
                : 
                this.state.columns;
            colConf = typeof(colConf) === "function" 
                ? 
                colConf(this.__rowConfigurations.columnOrder, this.__rowConfigurations.columnWidthMap) 
                : 
                colConf;

            if(typeof(this.props.presentation) === "object" 
                && 
                this.props.presentation.hasOwnProperty(rowType) 
                && 
                this.props.presentation[rowType] !== undefined) {
                    presentation = this.props.presentation[rowType];
            }

            for(const col of colConf) {
                const key = col.hasOwnProperty("name") 
                    ? 
                    col.name 
                    : 
                    col.field;

                this.__rowConfigurations.cellPresentations[rowType][key] = (
                        !col.hasOwnProperty("presentation") 
                        && 
                        !presentation.hasOwnProperty(key) 
                    ) 
                    ? 
                    false 
                    : 
                    col.presentation || presentation[key];
                this.__rowConfigurations.columnConfigs[rowType][key] = col;

                // The default row type can't use a function to determine its widths,
                // since the default row's widths are what's used to determine other rows' widths.
                if(rowType !== this.props.columnHeaderConfigKey && typeof(col.width) === "function") {
                    this.__rowConfigurations.columnWidthMaps[rowType][key] = col.width(this.__rowConfigurations.columnWidthMaps[this.props.columnHeaderConfigKey], columnOrderClone); 
                } else if(col.width !== undefined) {
                    this.__rowConfigurations.columnWidthMaps[rowType][key] = col.width;               
                } else {
                    this.__rowConfigurations.columnWidthMaps[rowType][key] = this.__rowConfigurations.columnWidthMap[key];
                }                

                if(!orderDefined) {
                    this.__rowConfigurations.columnOrders[rowType].push(key);
                }
            }
        });
    }


    // TODO: Move code that handles 
    // state data change in any way from
    // here to handleDataChange.
    componentDidUpdate(prevProps, prevState) {
        if(!isEqual(this.props.data, prevProps.data)) {
            // If the data changes "on the outside",
            // we need to reset all state concerning 
            // infinite scrolling.
            // TODO: Move init and reset into its own function.
            this._infiniteScrollEventActive = false;
            this._prevHowManyRowsDown       = 0; 
            this._prevInfiniteScrollPage    = 0;

            this.handleVirtualizedHorizontalScroll(this.flipAllRows);
        }

        if(!isEqual(this.state.attributes, prevState.attributes)) {
            this.attributeMap = makeMap(this.state.attributes, this.props.rowKey);
        }

        if(!isEqual(this.state.data, prevState.data)) {
            this.dataMap = makeMap(this.state.data, this.props.rowKey);
        }

        // TODO: Move to componentDidMount.
        if(this.props.virtualized && 
            (
                !this.virtualizationInitialized 
                || !isEqual(prevState.data, this.state.data)
                || !isEqual(prevProps.data, this.props.data)
            )) {
            this.initVirtualizationState();

            this.virtualizationInitialized = true;

            this.rotateVirtualizationOrder();
        }

        if(!isEqual(prevState.columns, this.state.columns) || !isEqual(prevState.columnWidths, this.state.columnWidths)) {
            this.configureRows();

            this.flipAllRows();

            return;
        }

		if(this.props.height === "auto") {
			this.determineAndSetHeightOnAuto();
        }

        // TODO: Is this needed anymore?
        if(this.props.fluid) {
            this.fixFluidWidth();
        }

        if(this.props.emptyNewDataOnUpdate && prevProps.data !== this.props.data) {
            this.emptyNewData();
        }

        if(this.props.checkedRows !== undefined && !isEqual(this.props.checkedRows, prevProps.checkedRows)) {
            this.setState({ checkedRows: makeMapOfPrimitives(this.props.checkedRows) });
        }

        // TODO: Is this sufficient?
        if(!this.props.noStateData && !isEqual(prevProps.data, this.props.data)) {
            this.setState({ data: this.props.data }, () => {
                if(this.pageChanged) {
                    this.mainList.current.scrollTop = 0; 

                    this.pageChanged = false;
                }

                this.runOnDataChange("propDataChange");
            });
        }

        
        // TODO: Move into functions. For example the rowOrder flip thingy must be done on its own or in lieu of data having changed.
        if(this.props.noStateData) {
            const flipAllForRowProps = !this.props.ignoreRowPropsChange && 
                (!isEqual(prevProps.rowProps, this.props.rowProps) 
                || !isEqual(prevProps.rowCallbacks, this.props.rowCallbacks) 
                || !isEqual(prevProps.sharedData, this.props.sharedData));

            const dataChangedMap = {
                props: !isEqual(prevProps.data, this.props.data),
                state: !isEqual(prevState.data, this.state.data) 
            };

            if(dataChangedMap.props || dataChangedMap.state) {
                if(this.pageChanged) {
                    this.mainList.current.scrollTop = 0; 

                    this.pageChanged = false;
                }

                const dataKey      = dataChangedMap.props ? "props" : "state";
                const previousData = dataChangedMap.props ? prevProps : prevState;

                // Flips -->
                // Maps and id sets for comparing elements that were in the prev set and are in the cur set;
                // We need to compare their data in case it has been changed by some other actors, and then flip the elements.
                const prevIds     = []; 
                const newIds      = [];
                const prevDataMap = {};
                const newDataMap  = {};
                const prevNewEls  = [];

                for(const pEl of previousData.data) {
                    prevDataMap[pEl[this.props.rowKey]] = pEl;
                    prevIds.push(this.props.idType === "number" ? Number(pEl[this.props.rowKey]) : String(pEl[this.props.rowKey]));
                }

                for(const nEl of this[dataKey].data) {
                    newDataMap[nEl[this.props.rowKey]] = nEl;
                    newIds.push(this.props.idType === "number" ? Number(nEl[this.props.rowKey]) : String(nEl[this.props.rowKey]));
                }

                for(const psEl of prevState.data) {
                    if(psEl[this.props.rowKey] > 0 || this.props.emptyNewDataOnUpdate || (!newDataMap[psEl[this.props.rowKey]] && dataKey === "state")) {
                        continue;
                    }

                    newIds.push(psEl[this.props.rowKey]);
                    prevNewEls.push(psEl);
                }

                const intersection  = Utils.arrayIntersect([prevIds, newIds]);
                const treesGathered = {};
                let flips           = clone(this.state.flips);
                const oldFlips      = clone(this.state.flips);
                const tempFlips     = {};
                const finalFlips    = {};
                const currentIds    = {};
                const prevData      = treeFormatDataForList(previousData.data, this.props.parentKey, undefined, this.props.idType);
                const newData       = treeFormatDataForList(this[dataKey].data, this.props.parentKey, undefined, this.props.idType);

                // Add all new ids to the flip map.
                for(const x in this[dataKey].data) {
                    const y = this[dataKey].data[x];

                    // Mark those ids that are still in use, since we need a diff between 
                    // relevant rows and removed rows.
                    currentIds[y[this.props.rowKey]] = true;

                    // If the id already exists, no need to do anything here.
                    if(flips.hasOwnProperty(y[this.props.rowKey])) {
                        continue;
                    }

                    // There's a new row in the set, and we need to check whether its parents are
                    // in the previous set and should be flipped.

                    flips[y[this.props.rowKey]] = 0;

                    for(const oid of this.gatherTreeSingle(y[this.props.rowKey], this[dataKey].data, newData)) {
                        treesGathered[oid] = true;
                    }
                }

                // Next, extract those rows that are, for what ever reason, not in our data anymore.
                for(const f in flips) {
                    if(currentIds[f]) {
                        continue;
                    }

                    // For all rows that were in the previous set, but are not in this set;
                    // gather their parents and all relevant rows in the tree
                    // and flip them, so that the parent and all relevant rows will rerender,
                    // visualizing that this removed row is not in the set anymore.

                    for(const nid of this.gatherTreeSingle(f, previousData.data, prevData)) {
                        treesGathered[nid] = true;
                    }
                }

                // Flips all existing rows.
                // TODO: What the hell?
                for(const id in treesGathered) {
                    if(currentIds[id]) {
                        tempFlips[id] = flips[id] ^ 1;
                    }
                }

                // Overwrite existing flips with our new flips, keeping the old flip value where changes are not relevant.
                flips = { ...flips, ...tempFlips };

                // If those elements that are still in the set, but whose data has changed, have not been flipped yet, flip them.
                const toGatherTreesFor = []; 

                for(const intersectionId of intersection) {
                    // Element is equal or has already been flipped.
                    if(isEqual(prevDataMap[intersectionId], newDataMap[intersectionId]) || flips[intersectionId] !== oldFlips[intersectionId]) {
                        continue;
                    }

                    toGatherTreesFor.push(intersectionId);

                    // ??
                    // if(!isEqual(prevDataMap[id], newDataMap[id]) && flips[id] === oldFlips[id])
                    // flips[id] = flips[id] ^ 1;
                }

                for(const toGatherId of toGatherTreesFor) {
                    const tree = this.gatherTreeSingle(toGatherId, this[dataKey].data, newData);

                    for(const flipId of tree) {
                        if(flips[flipId] !== oldFlips[flipId]) {
                            continue;
                        }

                        flips[flipId] = flips[flipId] ^ 1;
                    }
                }

                // Filter flips down to only those that are in the current set of elements.
                for(const curId of newIds) {
                    finalFlips[curId] = flips[curId];
                }
                // <-- flips end.

                // Child visibility -->
                const childrenVisibleRows = this.state.childrenVisibleRows;

                if(this.props.parentsExpandedOnInit) {
                    for(const i in this[dataKey].data) {
                        const el = this[dataKey].data[i];

                        if(childrenVisibleRows.hasOwnProperty(el[this.props.rowKey])) {
                            continue;
                        }

                        childrenVisibleRows[el[this.props.rowKey]] = true;
                    }
                }
                // <--

                const state = {
                    data: this[dataKey].data.concat(prevNewEls.filter(d => dataKey === "props" 
                        ? !this.props.emptyNewDataOnUpdate 
                        : !currentIds[d[this.props.rowKey]] )), 
                    flips: finalFlips, 
                    childrenVisibleRows 
                };

                if(this.props.checkedRows === undefined) {
                    state.checkedRows = { 
                        ...this.state.checkedRows, 
                        ...(this.props.useGlobalAllChecked && (this.state.allChecked || (this.props.useAllCheckedExcept && this.state.allCheckedExcept !== undefined)) 
                            ? makeMapOfPrimitives(this[dataKey].data.map(e => e[this.props.rowKey]).filter(id => this.state.allCheckedExcept === undefined || !this.state.allCheckedExcept[id])) 
                            : {}) };
                }

                this.setState(state, () => {
                    // Extend this to "state data mode" (!this.props.noStateData) too.
                    this.dataMap = makeMap(this.state.data, this.props.rowKey);
                    const origin = this.dataInitialized 
                        ? "propDataChange" 
                        : "initial";

                    if(!flipAllForRowProps) {
                        this.runOnDataChange(origin);
                    } else {
                        this.flipAllRows(() => this.runOnDataChange(origin));
                    }

                    if(!this.dataInitialized) {
                        this.dataInitialized = true;
                    }
                });

            } else if(flipAllForRowProps || prevProps.treeData !== this.props.treeData) {
                this.flipAllRows();
            } 

            if(!isEqual(this.state.attributes, prevState.attributes)) {
                const cur  = makeMap(this.state.attributes, this.props.rowKey);
                const prev = makeMap(prevState.attributes, this.props.rowKey);

                const ids = Object.keys(cur).filter(id => {
                    return !isEqual(cur[id], prev[id]);
                });

                this.setState({ 
                    flips: this.gatherFlips(ids)
                });
            }
        }

        if(!isEqual(prevState.data, this.state.data)) {
            this.runOnDataChange("stateDataChange");
        }

        if(!isEqual(this.props.columns, prevProps.columns)) {
            // What the hell? No idea why this works, in products list the columns weren't updating correctly so had to do something.
            // A console.log here also made it work.
            setTimeout(() => { 
                this.configureColumns(this.props.columns);
            }, 0);
        }

        if(prevProps.visibilityFilter !== this.props.visibilityFilter) {
            this.handleVisibilityFilterChange();
        }
	}


    resetCheckedRows() {
        this.uncheckAll();

        this.setState({
            allCheckedExcept: undefined,
			allChecked: this.props.allChecked
        }); 
    }


    // Used to reset List's state data by making it a deep copy of props.data again, 
    // and flipping all rows after that.
    resetStateData(callback = () => {}) {
        this.setState({ data: cloneDeep(this.props.data) }, () => this.flipAllRows(callback)); 
    }


	startPageChangeAnimation() {
		this.contentDiv.current.style.opacity = "0.0";
	}


    startDataChangeAnimation() {
        this.startPageChangeAnimation();
    }


	endPageChangeAnimation() {
		this.contentDiv.current.style.opacity = "1";
	}


    endDataChangeAnimation() {
        this.endPageChangeAnimation();
    }


    runHeightSetCallback() {
        const elements = {
            contentDiv: this.contentDiv.current,
            outerMainWrapper: this.outerMainWrapper.current,
            mainWrapper: this.mainWrapper.current,
            mainList: this.mainList.current,
            summaryRow: this.summaryRow.current,
            resizeMarker: this.resizeMarker.current,
            header: this.header.current,
            pageSelector: this.pageSelector.current,
            listFooter: this.listFooter.current,
            listHeader: this.listHeader.current,
            fluidFix: this.fluidFix.current
        };

        this.props.height(elements, (height) => {
            // TODO: 56 should be the height of the column header, not a hard-coded value.
            this.mainWrapper.current.style.height = Math.max((height - 56), this.props.minHeight) + "px"; 
        }, this);
    }


	determineAndSetHeightOnFit() {
        const height = window.innerHeight - this.outerMainWrapper.current.getBoundingClientRect().y - this.listFooter.current.offsetHeight + this.props.trimHeight;
        this.mainWrapper.current.style.height = Math.max((height - 56), this.props.minHeight) + "px";
	}


    determineAndSetHeightOnAuto() {
        const { visibilityFilter } = this.props;

        const relevantData = visibilityFilter !== undefined 
            ? this.state.data.filter(d => visibilityFilter(d))
            : this.state.data;

        // Äläläläläläl
		const rowHeight = this.props.rowHeight !== undefined 
                ? this.props.rowHeight 
                : (this.props.listRowType.hasOwnProperty("rowDimensions") 
                    ? parseInt(this.props.listRowType.rowDimensions.height) 
                    : "44px");
		let totalHeight = relevantData.length * rowHeight + this.state.newData.length * rowHeight;

		if(this.props.summaryRowType !== undefined) {
			const SummaryRowType = this.props.summaryRowType;
			let summaryRowHeight = 0;

			if(this.props.summaryRowHeight)
				summaryRowHeight = parseInt(this.props.summaryRowHeight);
			else if(SummaryRowType.hasOwnProperty("rowHeight"))
				summaryRowHeight = parseInt(SummaryRowType.rowHeight);
			else
				summaryRowHeight = 44; // most common height

			totalHeight += summaryRowHeight;
        }
        
        if(this.props.maxHeight) {
            const countedHeight = isNaN(totalHeight + this.props.trimHeight) ? 99999 : totalHeight + this.props.trimHeight;
            this.mainWrapper.current.style.height = Math.min(countedHeight, this.props.maxHeight) + "px";
        } else {
            this.mainWrapper.current.style.height = (totalHeight + this.props.trimHeight) + "px";
        }
        
        /*
        this.mainWrapper.current.style.height = (totalHeight + this.props.trimHeight) + "px";
        if(this.props.maxHeight) {
            this.mainWrapper.current.style.maxHeight = this.props.maxHeight + "px";
        }
        */
    }


    usesMultipleListRowTypes() {
        return !Array.isArray(this.props.columns) && typeof this.props.columns === "object";
    }


	// Only possible with the middle mouse button.
	startGrabScrollCounter(event) {
		if(event.button !== 1 && !event.ctrlKey)
			return;

		const coordinates = { x: event.clientX, y: event.clientY };

		this.startGrabScrollTimeout = setTimeout(() => {
			this.startGrabScroll(coordinates);
		}, 0);
	}


	stopGrabScrollCounter(event) {
		clearTimeout(this.startGrabScrollTimeout);

		this.startGrabScrollTimeout = undefined;
	}


	startGrabScroll(coordinates) {
		this.scrollingByGrab 	 		   = true;
		this.scrollingStartedAtCoordinates = coordinates;
		this.scrollPositionsWhenStarted    = { x: this.mainWrapper.current.scrollLeft, y: this.mainList.current.scrollTop };

		document.body.addEventListener("mousemove", this.bodyMouseMoveListenerForGrabScroll);
		document.body.addEventListener("mouseup", this.bodyMouseUpListenerForGrabScroll);
		window.addEventListener("mouseup", this.bodyMouseUpListenerForGrabScroll);

        	// this.mainWrapper.current.classList.add("scrolling");
	}


	grabScroll(event) {
		if(!this.scrollingByGrab || this.scrollingStartedAtCoordinates === undefined)
			return;

		const newX = event.clientX;
		const newY = event.clientY;

		this.mainWrapper.current.scrollLeft = this.scrollPositionsWhenStarted.x + (this.scrollingStartedAtCoordinates.x - newX);
		this.mainList.current.scrollTop 	= this.scrollPositionsWhenStarted.y + (this.scrollingStartedAtCoordinates.y - newY);
	}


	endGrabScroll() {
		this.scrollingByGrab 			   = false;
		this.scrollingStartedAtCoordinates = undefined;
		this.scrollPositionsWhenStarted    = undefined;

		document.body.removeEventListener("mousemove", this.bodyMouseMoveListenerForGrabScroll);
		document.body.removeEventListener("mouseup", this.bodyMouseUpListenerForGrabScroll);
		window.removeEventListener("mouseup", this.bodyMouseUpListenerForGrabScroll);
	}


    getScrollTop() {
        return this.mainList.current.scrollTop;
    }


    startRowDrag(props) {
        if(!this.props.rowDragging)
            return; 

        this.dragging   = true;
        this.draggedRow = props;
    }


    setCurrentlyDragging(props) {
        this.draggedRow = props;
    }


    setCurrentlyDraggingOver(props) {
        this.currentlyDraggingOverRow = props;
    }


    setCurrentlyDraggingOverNotAllowed() {
        this.currentlyDraggingOverProps = null;
    }


    isDragging() {
        return this.dragging;
    }


    // rowData and props are those of the row which the drop would happen _on_.
    dragDropIsAllowed(props) {
        if(!this.dragging)
            return false; 

        this.props.onDrag(this.draggedRow, props, this.currentOrder);

        return this.props.dragDropIsAllowed(this.draggedRow, props, this.currentOrder);
    }
    
    
    endRowDrag() {
        const dragged = this.draggedRow;
        const over    = this.currentlyDraggingOverRow;

        // Nothing to do if the row was dropped on itself.
        if(dragged && over && dragged !== over) {
            const [data, callback = () => {}] = this.props.onDraggedRowDrop(
                this.draggedRow,
                this.currentlyDraggingOverRow, 
                cloneDeep(this.currentOrder), 
                this.dataMap, 
                this
            );

            this.setState({ data }, callback);
        }

        this.draggedRow               = undefined;
        this.currentlyDraggingOverRow = undefined;
        this.dragging                 = false;
    }


    addNewRow(newData = {}) {
        const { 
            data,
            newRow,
            overrideAddNewRow,
            rowKey,
            virtualized
        } = this.props;

        if(typeof(overrideAddNewRow) === "function") {
            const id = String(this.nextNewRowId--);

            overrideAddNewRow({ 
                row: { ...newRow, ...newData, [rowKey]: id }, 
                data: cloneDeep(data), 
                list: this 
            });

            return;
        }

        const fn = virtualized
            ? () => {
                // TODO: Still a duct-tape fix.
                this.initVirtualizationOrder();
                this.flipAllRows();
            }
            : () => {};

        return (this.props.noStateData || this.props.disableNewData) 
            ? this.newAddNewRow(newData, fn) 
            : this.oldAddNewRow(newData);
    }

    
    // Optionally accepts either an object or an array. If an array is passed, it must be an array of objects.
    newAddNewRow(newData = {}, fn = () => {}) {
        const { 
            newRowLimit, 
            rowKey 
        } = this.props;
        const { data }     = this.state;
        const newRowAmount = data.filter(d => parseInt(d[rowKey]) < 0).length;

        if(newRowLimit !== undefined && newRowAmount >= newRowLimit) {
            return;
        }

        newData = !Array.isArray(newData) ? [newData] : newData;

        const allNewIds           = [];
        const childrenVisibleRows = clone(this.state.childrenVisibleRows);
        const newRows             = newData.map(row => {
            let newRow;

            // If multiple ListRow subtypes are in use and multiple newRows have also been declared, and said definition exists for this type.
            if(this.usesMultipleListRowTypes() && this.props.newRowMap !== undefined 
                && row.hasOwnProperty(this.props.listRowTypeKey) 
                && this.props.newRowMap.hasOwnProperty(row[this.props.listRowTypeKey])) {
                newRow = clone(this.props.newRowMap[row[this.props.listRowTypeKey]]);
            } else {
                newRow = clone(this.props.newRow);
            }

            newRow[this.props.rowKey] = String(this.nextNewRowId--);

            // In render, if this key (__originalCreationKey) exists in a row's data, 
            // it will be used as the key of the component instead of data[this.props.rowKey].
            // This is to prevent a row, whose identifier has just changed,
            // from being constructed again because it's key changes.
            newRow['__originalCreationKey'] = newRow[this.props.rowKey]; 

            allNewIds.push(newRow[this.props.rowKey]);

            for(const k in row) {
                newRow[k] = row[k];
            }
            
            if(newRow[this.props.parentKey]) {
                childrenVisibleRows[newRow[this.props.parentKey]] = true;
            }

            return newRow;
        });

        let newStateData = clone(this.state.data);
        const flips        = clone(this.state.flips);

        if(!this.props.reverseNewData) {
            newStateData = newRows.concat(newStateData);
        } else {
            newStateData = newStateData.concat(newRows);
        }

        const oldStateData = cloneDeep(this.state.data);

        this.setState({ 
            data: newStateData, 
            flips: this.gatherFlips(allNewIds, newStateData), 
            childrenVisibleRows: childrenVisibleRows, 
            hideOverlay: true 
        }, () => {
            this.props.onAddNewRow(newRows, oldStateData, this);
            this.runOnDataChange("addNewRow");

            fn();
        });

        return allNewIds;
    }


    oldAddNewRow(additionalData = {}) {
		const newData = this.state.newData;
		const newRow  = { children: [], data: JSON.parse(JSON.stringify(this.props.newRow)) };

        newRow.data['id'] = String(this.nextNewRowIdOld--);

		if(additionalData)
			newRow.data = Object.assign(newRow.data, additionalData);

		if(!this.props.reverseNewData)
			newData.unshift(newRow);
		else
			newData.push(newRow);

        this.setState({ newData: newData }, () => {
            this.runOnDataChange(); 
        });
    }


    // This should only be used for removing new rows when !this.props.noStateData.
    removeNewRow(id) {
	    this.setState({ newData: this.state.newData.filter(r => r.data[this.props.rowKey] !== id) }, () => this.runOnDataChange("removeNewRow"));
	}


    removeRow(ids) {
        ids         = Array.isArray(ids) ? ids : [ids];
        const idMap = makeMapOfPrimitives(ids);

        const data  = this.state.data.filter(d => !idMap[d[this.props.rowKey]]);
        const flips = this.gatherFlips(ids);

        ids.forEach(id => delete flips[id]);
       
        this.setState({ data, flips }, () => {
            this.runOnDataChange("removeRow"); 
        });
    }


    // TODO: Removing rows in batches.
    // TODO: Add a default origin parameter for all functions that call runOnDataChange, so the caller can customize
    // the origin to be able to distinguish data change "events" from each other.

    emptyNewData(callback = () => {}) {
        if(!this.props.noStateData) {
            this.setState({ newData: [] }, () => {
                if(typeof(callback) === "function")
                    callback();

                this.runOnDataChange("emptyNewData");
            });
        } else {
            const idsToRemove = [];
            const data        = this.state.data.filter(d => {
                const isNew = Number(d[this.props.rowKey]) < 0;

                if(isNew) {
                    idsToRemove.push(d[this.props.rowKey]);
                }

                return !isNew;
            });

            this.setState({ data: data, flips: this.gatherFlips(idsToRemove) }, () => {
                if(typeof(callback) === "function")
                    callback();

                this.runOnDataChange("emptyNewData");
            });
        }
	}


    batchEditData(data = undefined, identifier = undefined) {
        return this.batchEdit(data, identifier);
    }


    // Edit large chunks of data without triggering
    // any events between subsequent calls.
    batchEdit(data = undefined, identifier = undefined, prevData = undefined, dataOrAttributes = "data") {
        identifier = Array.isArray(identifier)
            ? identifier
            : [identifier];

        const originData = dataOrAttributes === "data"
            ? this.state.data
            : this.state.attributes;

        const fn = dataOrAttributes === "data"
            ? this.editData
            : this.editAttributes;

        const editedData = data !== undefined 
            ? fn(data, identifier, true, prevData)
            : originData;

        return {
            commit: () => {
                return dataOrAttributes === "data" 
                    ? this.setData(editedData) 
                    : this.setAttributes(editedData);
            },
            getData: () => editedData,
            edit: (cbData, cbIdentifier = undefined) => {
                return this.batchEdit(cbData, cbIdentifier, editedData, dataOrAttributes);
            }
        };
    }


    editData(data, identifier = undefined, returnEditedData = false, passedData = undefined) {
        const key        = this.props.rowKey !== undefined ? this.props.rowKey : "id";
        const originData = passedData === undefined
            ? this.state.data
            : passedData;
        const allMap     = makeMapOfPrimitives(originData.map(d => d[key]), false);

        let map;

        if(!Array.isArray(identifier)) {
            identifier = this.props.idType === "number" 
                ? Number(identifier === undefined ? data[key] : identifier) 
                : String(identifier === undefined ? data[key] : identifier);

            map = {
                ...allMap,
                [identifier]: true
            };
        } else {
            map = {
                ...allMap,
                ...makeMapOfPrimitives(identifier, true)
            };
        }

        const editedData = originData.map(r => (!map[r[key]] ? r : { ...r, ...data }));

        return returnEditedData 
            ? editedData 
            : new Promise(resolve => {
                this.setState({ 
                    data: editedData, 
                    flips: this.gatherFlips(identifier) 
                }, () => {
                    this.runOnDataChange("editData");

                    resolve();
                });
            });
	}


    setData(data, callback = () => {}) {
        return new Promise((resolve, reject) => {
            this.setState({ 
                data: data
            }, resolve);
        });
    }


    setAttributes(attributes, callback = () => {}) {
        return new Promise((resolve, reject) => {
            this.setState({ 
                attributes: attributes
            }, resolve);
        });
    }


    // NOTE: hasn't been tested (or therefore even developed) for ListRows that have internal state.
    // TODO: When attributes are implemented on the list (separating them from data), refactor this function. 
    getData() {
        const checkedRows = makeMapOfPrimitives(this.getCheckedRows());
        const data        = cloneDeep(this.state.data);

        return data.map(row => {
            row['_checked'] = checkedRows[row[this.props.rowKey]] || false;

            return row;
        });
    }


    // TODO: When attributes are implemented on the list (separating them from data), refactor this function. 
    getItem(id) {
        id = Number(id);

        return (this.dataMap && this.dataMap.hasOwnProperty(id)) 
            ? this.dataMap[id] 
            : this.state.data.find(d => Number(d[this.props.rowKey]) === id);
    }


    getAttributes() {
        return this.attributeMap;
    }


    getAttributesFor(id) {
        return cloneDeep(this.attributeMap[id]);
    }


    batchEditAttributes(data = undefined, identifier = undefined) {
        return this.batchEdit(data, identifier, undefined, "attributes");
    }


    editAttributes(newAttributes, identifier, returnEditedAttributes = false, passedAttributes = undefined) {
        identifier = Array.isArray(identifier)
            ? identifier
            : [identifier];

        const { rowKey } = this.props;
        const attributes = cloneDeep(passedAttributes === undefined 
            ? this.state.attributes 
            : passedAttributes);
        const map        = makeMap(attributes, rowKey);

        identifier
            .filter(id => !map.hasOwnProperty(id))
            .forEach(id => {
                attributes.push({ [rowKey]: id });
            });

        const editedAttributes = this.editData(newAttributes, identifier, true, attributes);

        return returnEditedAttributes 
            ? editedAttributes 
            : new Promise((resolve, reject) => {
                this.setState({
                    attributes: editedAttributes,
                    flips: this.gatherFlips(identifier) 
                }, () => {
                    resolve();

                    // TODO: onAttributeChange
                });
            });
    }


    getRow(id) {
        return this.rowRefMap[id].current;
    }


    getCheckedData() {
        return this.getData().filter(d => d['_checked'] === true);
    }


    castId(id) {
        return this.props.idType === "string"
            ? String(id)
            : Number(id);
    }


    runOnDataChange(origin = undefined) {
        this.props.onDataChange({ 
            data: cloneDeep(this.state.data), 
            newData: cloneDeep(this.state.newData), 
            origin,
            list: this
        });
    }


    getVisibleRows() {
        return this.listElementReferences.filter(r => r.current !== null).map(r => r.current);
    }


    // The "old" version, suitable for finding the tree for a single id
    // with preformatted tree data. TODO: Refactor this into one function.
    gatherTreeSingle(id, data = undefined, dataTreeFormatted = false) {
        if(!this.props.treeData)
            return [id];

        data = data !== undefined ? data : this.state.data;

        return !this.props.minimizeRerendersInTrees 
            ? 
            gatherTree(id, data, dataTreeFormatted, this.props.parentKey, this.props.idType) 
            : 
            gatherAncestors(id, data, this.props.parentKey, this.props.idType);
    }


    gatherTree(ids = [], data = undefined, preformattedData = undefined) {
        ids = !Array.isArray(ids)
            ? [ids]
            : ids;

        if(!this.props.treeData) {
            return ids;
        }

        let dataMap;

        const originalIds   = clone(ids);
        const { parentKey } = this.props;

        if(preformattedData !== undefined) {
            dataMap = preformattedData;
        } else {
            data    = cloneDeep(data !== undefined ? data : this.state.data);
            dataMap = createHierarchyDataMap(data, parentKey);
        }

        let roots     = [];
        let trees     = [];

        for(const id of ids) {
            roots = roots.concat(findRoot(dataMap[id]));
        }

        for(const rootId of roots) {
            trees = trees.concat(gatherFromRoot(dataMap[rootId]));
        }

        return trees;
    }


    // Climb back to the trunk from a leaf, id is a leaf's id.
    // We need to inform the trunk that its leaves need to update, 
    // meaning the trunk itself needs to update.
    gatherFlips(ids, data = undefined, dtf = false) {
        ids = !Array.isArray(ids) 
            ? [ids] 
            : ids;
        const map = {};

        this.gatherTree(ids, data)
            .forEach(id => map[id] = true);

        const treeIds  = Object.keys(map);
        const idsToUse = (!treeIds || treeIds.length === 0) ? ids : treeIds;
        const flips    = clone(this.state.flips);

        for(const x in idsToUse) {
            flips[idsToUse[x]] = flips[idsToUse[x]] ^ 1;
        }

        return flips;
    }


    flipRows(ids, callback = () => {}) {
        this.setState({ flips: this.gatherFlips(ids) }, callback);
    }


    flipAllRows(callback = () => {}) {
        const flips = clone(this.state.flips);

        for(const id in flips)
            flips[id] = flips[id] ^ 1;

        this.setState({ flips }, () => {
            if(typeof(callback) !== "function")
                return;

            callback();
        });
    }


    // TODO:
    // Refactor both of these into a general "toggleAttribute" method that toggles an attribute
    // on a row specified by its id.
    toggleShowChildren(id, show = undefined) {
        id = this.castId(id);

        const { data }            = this.state;
        const { rowKey }          = this.props;
        const toHide              = {};
        const childrenVisibleRows = clone(this.state.childrenVisibleRows);

        childrenVisibleRows[id]   = !childrenVisibleRows[id] || show || false;

        const hiddenTrees = {};

        Object.keys(childrenVisibleRows)
            .filter(cId => !childrenVisibleRows[cId])
            .map(cId => [cId, this.hierarchyDataMap[cId]])
            .forEach(([cId, ent]) => {
                hiddenTrees[cId] = gatherFromRoot(ent, rowKey)
                    .filter(tId => cId != tId);            
            });

        Object.keys(hiddenTrees)
            .forEach(key => {
                hiddenTrees[key].forEach(tId => toHide[tId] = true);
            });

        const hiddenN = data.filter(d => toHide[d[rowKey]]).length;

        this.setState({ 
            childrenVisibleRows, 
            flips: this.gatherFlips(id),
            hiddenRows: toHide,
            hiddenRowAmountInCurrentData: hiddenN
        });
    }

    
    showChildren(id) {
        this.toggleShowChildren(id, true);
    }


    check(ids, state = undefined, callback = () => {}, all = false) {
        ids                    = !Array.isArray(ids) ? [ids] : ids;
        const checkedRows      = this.state.checkedRows;
        const allCheckedExcept = this.state.allCheckedExcept;

        for(const id of ids) {
            if((state === undefined && checkedRows[id]) || state === false) {
                delete checkedRows[id];

                if(allCheckedExcept !== undefined)
                    allCheckedExcept[id] = true;
            } else {
                checkedRows[id] = true;            

                if(allCheckedExcept !== undefined)
                    delete allCheckedExcept[id];
            }
        }

        this.setState({
            flips: this.gatherFlips(ids),
            checkedRows,
            allChecked: all && state,
            allCheckedExcept,
            checkInProgress: false
        }, () => {
            callback();

            this.props.onCheck({ checkedRows: this.state.checkedRows, allChecked: this.state.allChecked, allCheckedExcept: this.state.allCheckedExcept });
        });
    }


    checkAll(checked = undefined, callback = () => {}) {
		const newAllChecked          = checked !== undefined ? checked : !this.state.allChecked;
        const allCheckedExcept       = this.props.useAllCheckedExcept && newAllChecked ? {} : undefined;
        const { checkableRowFilter } = this.props;

        if(!this.props.noStateData) {
            for(const ref of this.listElementReferences) {
                ref.current && ref.current.checkAll(newAllChecked);
            }

            newAllChecked ? this.props.onCheckAll() : this.props.onUncheckAll();

            this.setState({ allChecked: newAllChecked, allCheckedExcept });
        } else {
            const allIds = !newAllChecked 
                ? Object.keys(this.state.checkedRows) 
                : this.state.data
                    .filter(checkableRowFilter)
                    .map(el => {
                        return (!el.check_disabled)
                            ? el[this.props.rowKey]
                            : null
                    }).filter(a => a);

            newAllChecked 
                ? this.props.onCheckAll(allIds) 
                : this.props.onUncheckAll(allIds);

            this.setState({ allCheckedExcept }, () => this.check(allIds, newAllChecked, callback, true));
        }
    }


    uncheckAll(callback = () => {}) {
        this.checkAll(false, callback); 
    }


    // If you're trying to determine whether the user has pressed the header's checkbox in "all checked except" mode, use getAllCheckedExcept.
    getAllChecked() {
        return this.props.useGlobalAllChecked
            ? this.state.allChecked
            : this.state.data.map(d => d[this.props.rowKey]).every(id => this.state.checkedRows[id]);
    }


    getAllCheckedExcept() {
        return this.state.allCheckedExcept === undefined ? false : this.state.allCheckedExcept; 
    }


    // If the ListRow doesn't use any state data (this.props.noStateData == true),
    // this method only returns the ids of the checked rows.
    getCheckedRows() {
        let rows = [];

        if(!this.props.noStateData) {
            // TODO: ???
            // How does this mean the row is checked???
            for(const ref of this.listElementReferences) {
                if(!ref.current || !ref?.current?.hasOwnProperty("getData")) {
                    continue;
                }

                rows.push(ref?.current?.getData() || false);
            }
        } else {
            rows = Object.keys(this.state.checkedRows);
        }

        rows = rows.filter(r => r);

		return rows;
    }


    getColumnConfig() {
        return this.listHeader.current.getColumns();
    }


    toolbarShouldBeVisible() {
        return this.props.enableToolbar && (Object.keys(this.state.checkedRows).length > 0 || this.state.allChecked);
    }


    noResultsMessageShouldBeShown() {
        // The original overlay and the no results overlay can't be shown at the same time.
        return !this.props.showOverlay 
            && 
            (typeof(this.props.showNoResultsMessage) === "function" 
            ? this.props.showNoResultsMessage() 
            : this.props.showNoResultsMessage && (!this.state.data || this.state.data.length === 0));
    }


	setPage(page) {
		if(this.pageSelector === undefined || this.pageSelector.current === null)
			return;

		this.pageSelector.current.setPage(page);
	}


    _setPage(page) {
        this.pageChanged = true;

        if(!this.props.controlPage)
            this.setState({ currentPage: page });

        this.props.onPageChange(page);
    }


    saveColumnConfig(columns) {
        if(!this.props.saveColumnConfig) {
            return;
        } else if(!this.props.userListSettingsKey) {
            throw "List is set to save its column configuration, but props.userListSettingsKey is falsy.";
        }

        const fieldsToSave = ["name", "width", "visible"];

		localStorage.setItem(this.getSavedConfigKey(), JSON.stringify(columns.map(c => {
			const nc = {};

			for(const field in c) {
				if(fieldsToSave.indexOf(field) > -1) {
					nc[field] = c[field];
                }
            }

			return nc;
		})));
    }

    
    getMinimumWidthForColumn = column => {
        const savedConfig = this.getSavedConfig();
        let width = 0;
        if (savedConfig) {
            const savedCol = savedConfig.find(x => x.name == column.name);
            width = savedCol ? savedCol.width : this.getMinimumWidthFromProps(column);
        } else {
            width = this.getMinimumWidthFromProps(column);
        }
        return width;
    }


    getMinimumWidthFromProps = column => {
        let item = {};
        if (!Array.isArray(this.props.columns)) {
            const colArr = this.usesMultipleListRowTypes() && typeof(this.props.columns) === "object" ? this.props.columns[this.props.columnHeaderConfigKey] : this.props.columns;
            const found = colArr.find(x => this.getColumnKey(x) == this.getColumnKey(column));

            if (found) item = found;
        } else {
            item = (this.props.columns).find(x => this.getColumnKey(x) == this.getColumnKey(column)) || {};
        }
        return item.width || 0;
    }


    handleVisibilityFilterChange() {
        const { 
            visibilityFilter 
        } = this.props;
        const { 
            data 
        } = this.state;

        this.formattedData = this.formatData(visibilityFilter ? data.filter(visibilityFilter) : data);

        // Oh boy.
        this.forceUpdate();
    }


    handleDataChange(data = undefined) {
        const { 
            treeData,
            visibilityFilter,
            rowKey
        } = this.props;

        const {
            hiddenRows
        } = this.state;

        data = data === undefined
            ? this.state.data
            : data;

        this.formattedData = this.formatData(visibilityFilter ? data.filter(visibilityFilter) : data);
        this.currentOrder  = this.formattedData.map(d => d.data[rowKey]); 

        if(treeData) {
            this.determineTreeMetaData(data);
        }

        this.setState({ 
            hiddenRowAmountInCurrentData: data
                .filter(d => hiddenRows[d[rowKey]]).length
        });
    }


    determineTreeMetaData(data) {
        const { 
            treeData,
            rowKey,
            parentKey
        } = this.props;
        const orderMap = createOrderMap(this.currentOrder);
        const map      = {};

        this.hierarchyDataMap = createHierarchyDataMap(data, parentKey, rowKey);

        for(const d of data) {
            const id = d[rowKey];
            const e  = this.hierarchyDataMap[id];

            map[id] = {
                isChild: e.parentRef !== null,
                childCount: e.childRefs.length,
                hasChildren: e.childRefs.length > 0,
                depth: getChildDepth(e),
                hasFurtherSiblingsOnLevels: []
            };
        }

        for(const d of data) {
            const id   = d[rowKey];
            const e    = this.hierarchyDataMap[id];
            const root = this.hierarchyDataMap[findRoot(e, rowKey)];
            const tree = gatherFromRoot(root, rowKey);

            const ownIndex      = orderMap[id];
            const furtherInTree = tree
                .filter(t => t !== tree)
                .filter(t => orderMap[t] > ownIndex);

            map[id].hasFurtherSiblingsOnLevels = furtherInTree
                .map(f => map[f].depth);
        }

        this.treeMetaDataMap = map;
    }


    formatData(data) {
        const {
            idType,
            parentKey,
            rowKey,
            treeData,
            virtualized
        } = this.props;

        let formattedData;

        if(!treeData) {
            formattedData = formatDataForList(data, rowKey);
        } else if(treeData && !virtualized) {
            formattedData = treeFormatDataForList(data, parentKey, undefined, idType);
        } else {
            formattedData = treeFormatDataForList(
                data, 
                parentKey, 
                data.map(d => d[rowKey]),
                idType
            );

            // Empty the children property so ListRows won't get confused.
            formattedData = flattenTreeFormattedData(formattedData).map(fd => {
                fd.children = [];

                return fd;
            });
        }

        return formattedData;
    }


    calculateWidths(columns, elementWidth = 0, afterColumnResize = false) {
        const conf = this._calculateWidths(columns, elementWidth, afterColumnResize);

        if(this.props.onWidthCalculate) {
            this.props.onWidthCalculate(conf);
        }

        return conf;
    }


    _calculateWidths(columns, elementWidth = 0, afterColumnResize = false) {
		let scaledTotal    = 0;
		const columnWidths   = [];
		const scaledColumns  = [];
        const nonResizeables = columns.filter(c => !c.resizeable);
        const staticSize     = nonResizeables.length === 0 ? 0 : nonResizeables.map(c => c.width).reduce((acc, cur) => acc + cur, 0);
        const totalWidth     = columns.filter(c => c.visible).map(c => c.width).reduce((acc, cur) => acc + cur, 0);

		columns.forEach(c => {
			columnWidths.push({
				name: c.name,
                width: c.width,
                percentual: String((c.width / totalWidth * 100)) + "%"
			});
		});

        // Commented out & removed this onlyUp from every call of this function. 
        // Replaced with afterColumnResize to let user resize the column to whatever size they want (minimum width not taken into account).
		// if(onlyUp)
        // 	elementWidth = Math.max(totalWidth, elementWidth);

		if(elementWidth > 0) {
			columns.forEach(c => {
                let minimumWidth = this.getMinimumWidthForColumn(c);

                if (c.forceMinimumWidth) {
                    c.width = minimumWidth;
                }

                if (afterColumnResize) {
                    const old = this.state.columnWidths.find(x => x.name == c.name) || { width: 0 };

                    if(old.width != c.width) {
                        minimumWidth = c.width;
                    }
                }

				let scaledWidth = c.width;

				if(c.visible && c.resizeable)
					scaledWidth = Math.max(minimumWidth, Math.round(((elementWidth - staticSize) / (totalWidth - staticSize)) * c.width));

				scaledTotal += c.visible && !c.hidden ? scaledWidth : 0;

				scaledColumns.push({
					name: c.name,
					width: scaledWidth
				});
			});
        }

		return { listWidth: scaledTotal > 0 ? scaledTotal : totalWidth, columnWidths: scaledColumns.length > 0 ? scaledColumns : columnWidths };
    }


    initVirtualizationState() {
        this._previousStart = 0;
        this._order         = {};
        this._prevDiff      = 0;
        this._rowAmount       = this.determineVisibleRowsAmount();

        this.initVirtualizationOrder();
    }


    initVirtualizationOrder() {
        const { data } = this.state;

        for(let i = 0; i < this._rowAmount; ++i) {
            if(i >= data.length) {
                break;
            }

            this._order[i] = i; 
        }
    }


    setScrollTop(scrollTop) {
        if(!this.mainList.current) {
            return;
        }
           
        this.mainList.current.scrollTop = scrollTop;
    }


    // onScroll and rotateVirtualizationOrder are separated so that showing a slice
    // can be done "manually" without a scrolling event, like in the case of data changing.
    onScroll(e) {
        const { 
            onScroll,
            useInfiniteScroll
        } = this.props;
        const { 
            scrollTop 
        } = this.mainList.current;

        if(onScroll && typeof(onScroll) === "function") {
            onScroll(scrollTop);
        }

        if(useInfiniteScroll) {
            this.handleInfiniteScroll();
        }

        if(this.props.virtualized) {
            this.handleVirtualizedVerticalScroll();
        }

        e.stopPropagation();
    }


    // Used to detect horizontal scroll of the list.
    onWrapperScroll(e) {
        const {
            virtualization__useHorizontalVirtualization,
            virtualized
        } = this.props;
        // TODO: have horizontal virtualization under
        // a different prop, so that it isn't enabled
        // by default.
        if(virtualized && virtualization__useHorizontalVirtualization) {
            this.handleVirtualizedHorizontalScroll();
        }
    }


    handleInfiniteScroll() {
        const { 
            rowHeight,
            perpage,
            infiniteScrollTriggerLimit,
            infiniteScrollDynamicPageNumbering
        } = this.props;

        const { 
            scrollTop, 
            clientHeight 
        } = this.mainList.current;

        const dataLength          = this.state.data.length;
        const prevHowManyRowsDown = this._prevHowManyRowsDown;
        const howManyRowsDown     = this.getScrolledRowAmount();
        const distanceToBottom    = dataLength - howManyRowsDown;
        this._prevHowManyRowsDown = howManyRowsDown;

        //tarvitaan esim tilanteessa, jossa pitää kuvata tiimalasi ilmaisemaan seuraavan sivun latausta
        if (distanceToBottom === 0)
            this.triggerInfiniteScrollPageEnd();

        // If the current value for howManyRowsDown is
        // greater than the previous value, we're likely
        // scrolling down. 
        if(this._infiniteScrollEventActive
            || howManyRowsDown < prevHowManyRowsDown
            || distanceToBottom > infiniteScrollTriggerLimit) {
                return;
        }

        //in some reports we cannot count on matching number of rows to pagelimit. In this case 
        //we must continuosly trigger the event and have the possible end of report checked elsewhere
        if (infiniteScrollDynamicPageNumbering) {
            this.triggerInfiniteScrollEvent();
            return;
        }

        const nextPageNumber = Math.floor(dataLength / perpage) + 1;

        if(nextPageNumber === this._prevInfiniteScrollPage) {
            return;
        }

        this.triggerInfiniteScrollEvent(nextPageNumber);
    }


    triggerInfiniteScrollEvent(pageNumber = undefined) {
        const { onInfiniteScroll } = this.props;

        pageNumber = pageNumber !== undefined
            ? pageNumber
            : this._prevInfiniteScrollPage + 1;

        this._prevInfiniteScrollPage    = pageNumber;
        this._infiniteScrollEventActive = true;

        onInfiniteScroll(pageNumber, this.endInfiniteScrollEvent);
    }

    triggerInfiniteScrollPageEnd() {
        this.props.onInfiniteScrollPageEnd();
    }

    // newData will be appended to the end of
    // state.data.
    endInfiniteScrollEvent(newData = undefined) {
        this._infiniteScrollEventActive = false;

        if(newData === undefined || !Array.isArray(newData)) {
            return;
        }

        this.setData([
            ...cloneDeep(this.state.data), 
            ...newData
        ]);
    }


    // Based on the height of rows, 
    // so the actual amount of rows 
    // might be less than the return 
    // value in some cases.
    getScrolledRowAmount() {
        const { 
            rowHeight 
        } = this.props;
        const { 
            scrollTop, 
            clientHeight 
        } = this.mainList.current;

        return (scrollTop / rowHeight) + (clientHeight / rowHeight);
    }


    handleVirtualizedHorizontalScroll(callback = () => {}) {
        const {
            scrollLeft,
            offsetWidth
        } = this.mainWrapper.current;

        // TODO: Move virtualization specific code to
        // its own function, like handleVirtualizedVerticalScroll,
        // but name rename handleVirtualizedVerticalScroll to something
        // better like handleVirtualizedVerticalScroll, and so on.

        const right = scrollLeft + offsetWidth;
        const visibleColumns = cloneDeep(this.state.columns)
            .map((c, i) => {
                c.i               = i;
                c.cumulativeWidth = 0;
                c.offsetWidth     = 0;

                return c;
            })
            .map((c, i, arr) => {
                c.cumulativeWidth = i > 0
                    ? arr[i-1].cumulativeWidth + c.width
                    : c.width;

                c.offsetLeft = i > 0 
                    ? c.cumulativeWidth - c.width
                    : 0;

                return c;
            })
            .filter(c => {
                return (scrollLeft <= c.offsetLeft || scrollLeft <= c.cumulativeWidth)
                    && c.offsetLeft <= right;
            })
            .map(c => c.i);

        this._previousScrollLeft = scrollLeft;

        this.setState({
            columnRenderStart: visibleColumns[0],
            columnRenderLength: visibleColumns.length 
        }, callback);
    }


    handleVirtualizedVerticalScroll() {
        const { 
            scrollTop 
        } = this.mainList.current;
        const { 
            virtualization__beforeVirtualizationOrderRotation 
        } = this.props;

        this._previousScrollTop = scrollTop;

        // Clear the timeout, since we're gonna set it again 
        // in rotateVirtualizationOrder.
        clearTimeout(this._scrollEndTimeout);

        virtualization__beforeVirtualizationOrderRotation();

        // virtualization__afterVirtualizationOrderRotation is called as the 
        // callback of the setState call in rotateVirtualizationOrder.
        // (In initScrollEndTimeout actually.)
        this.rotateVirtualizationOrder(scrollTop);
    }


    // When determining the order, we don't yet know whether there will be enough
    // elements to show, so this._order might be mutated again in render if there aren't enough
    // renderRows.
    rotateVirtualizationOrder(top = undefined) {
        const {
            virtualization__overscan,
            rowHeight
        } = this.props;

        top = top === undefined 
            ? this.mainList.current.scrollTop 
            : top; 
        top = top - (virtualization__overscan / 2 * rowHeight);
        top = top < 0 ? 0 : top;

        this._overscanActive = !(top === 0);
        this._rowAmount        = this.determineVisibleRowsAmount();

        const start = top >= rowHeight 
            ? Math.round(top / rowHeight) 
            : 0;
        const diff = ((start - this._previousStart) * -1) % this._rowAmount;

        for(const o in this._order) {
            this._order[o] += diff;
        }

        let cur;

        for(const o in this._order) {
            cur = this._order[o];

            if(cur < 0) {
                this._order[o] = this._rowAmount + cur;
            } else if(cur >= this._rowAmount) {
                this._order[o] = cur - this._rowAmount;
            }
        }

        this._prevDiff      = diff;
        this._previousStart = start;

        this.setState({
            dataRenderStartIndex: start
        }, this.initScrollEndTimeout);
    }


    initScrollEndTimeout() {
        this._scrollEndTimeout = setTimeout(this.props.virtualization__afterVirtualizationOrderRotation, 100);
    }


	addCloseColumnMenuWindowListener() {
		window.addEventListener("click", this.closeColumnMenuWindowListener);
	}


	onHeaderSearchChange(filter, columnName) {
		this.props.onHeaderSearchChange(filter, columnName);
    }


    determineRowType(row) {
        let ListRowType = undefined;

        const { data } = row;
        const { 
            listRowTypeMap,
            listRowTypeKey,
            listRowType
        } = this.props;

        if(listRowTypeMap !== undefined && data.hasOwnProperty(listRowTypeKey)) {
            ListRowType = listRowTypeMap[data[listRowTypeKey]];
        } else if(listRowTypeMap !== undefined && listRowTypeMap?.default) {
            ListRowType = listRowTypeMap?.default;
        } else {
            ListRowType = listRowType;
        }

        return ListRowType;
    }
    
    
    getColumnKey(col) {
        return col.hasOwnProperty("name") ? col.name : col.field;
    }


    runOnEnterEditMode(id) {
        this.props.onEnterEditMode(
            this.getRow(id), 
            this.dataMap[id], 
            this,
        );
    }


    runOnExitEditMode(id) {
        this.props.onEnterEditMode(
            this.getRow(id), 
            this.dataMap[id], 
            this,
        );
    }


    determineCheckedRowsAmount() {
        const { 
            checkedRowsAmount,
            checkedRowsAmountFn
        } = this.props;

        const originalAmount = !this.state.allCheckedExcept 
            ? Object.keys(this.state.checkedRows).length 
            : this.props.totalCount - Object.keys(this.state.allCheckedExcept).length;

        const amountToUse = checkedRowsAmount !== undefined 
            ? checkedRowsAmount 
            : originalAmount;

        return checkedRowsAmountFn 
            ? checkedRowsAmountFn(amountToUse, this.getCheckedData(), this) 
            : amountToUse;
    }


    // TODO: Rework row ordering.
    render() {
        const usesMultipleListRowTypes = this.usesMultipleListRowTypes();
		const { 
            tr 
        } = this;
        const { 
            hiddenRows, 
            hiddenRowAmountInCurrentData,
            data,
            attributes
        } = this.state;
        const { 
            rowKey,
            height,
            alternativeRows
        } = this.props;

        // STYLES -->
        const listStyle     = { 
            height: typeof(this.props.height) === "function" 
            ? "100%" 
            : this.state.height
        };
        const contentsStyle = {};

        if(this.props.virtualized) {
            const height = (data.length - hiddenRowAmountInCurrentData)
                * this.props.rowHeight;

            contentsStyle.height = this.mainWrapper.current !== null 
                && height < parseInt(this.mainWrapper.current.style.height) 
                ? this.mainWrapper.current.style.height 
                : height;
        }

        // let contentsStyle     = { height: this.props.virtualized ? this.props.data.length * this.props.rowHeight : undefined };
        const mainListWrapperStyle = {};

        if(this.props.fluid) {
            listStyle.minWidth = contentsStyle.minWidth = this.props.minWidth;
		} else {
            listStyle.width = contentsStyle.width = this.state.listWidth + "px";
        }

        // Scrollbar compensation.
        if(this.props.virtualized && !this.props.fluid) {
            listStyle.width = String((parseInt(listStyle.width) + LIST_VIRTUALIZATION_EXTRA_PADDING) + "px");
        }

        if(this.props.hideHeader) {
        	mainListWrapperStyle.paddingTop = 0;
        }

		if(this.props.manualListWidth) {
			listStyle.width = parseInt(this.props.manualListWidth) + "px";
        }
        // <-- STYLES

        // this.currentOrder = (this.props.treeData 
            // ? flattenTreeFormattedData(this.formattedData) 
            // : this.formattedData).map(d => d.data[this.props.rowKey]);

        let virtualizationData = [];
        let startHeight        = 0;

        // TODO: Potential problem with the slice.
        if(this.props.virtualized) {
            virtualizationData = this.formattedData
                .filter(d => !hiddenRows[d.data[rowKey]])
                .slice(this.state.dataRenderStartIndex, 
                    this.state.dataRenderStartIndex + this._rowAmount);

            // This variable is for debugging.
            // this._prevVirtualizationData = virtualizationData;
            startHeight = this.determineVirtualizationStartHeight();
        }

		const columnAmount   = this.state.columns.length;
		const rootClassName  = this.props.rootClassName || "";
		const addedClassName = this.props.className;
        let fluidClassName   = this.props.fluid || this.props.flex 
            ? "inFluidMode" 
            : "";

        if(this.props.alwaysShowHorizontalScrollBar) {
            fluidClassName += " alwaysShowHorizontalScrollBar";
        }

        // Row-ordering -->
        let renderRows = [];
        const newRows    = !this.props.noStateData ? this.state.newData.map(row => {
    		row.newRow = true;
			return row;
        }) : [];
		const oldRows    = (this.props.virtualized ? virtualizationData : this.formattedData).map(row => {
			row.newRow = false;
			return row;
        });

        const rows = this.props.noStateData ? ([...oldRows]) : ([...(!this.props.renderNewDataAtEnd ? newRows : oldRows), ...(!this.props.renderNewDataAtEnd ? oldRows : newRows)]);

        // NOTE: When props.rowOrder prop !== undefined, it's passed to treeFormatForDataList, which will use the order to form tree-structured data for List to use. 
        // BUT this only works for noStateData for now; don't want to break older implementations.

        // TODO: Do something to this. 
        // Now some of the props are only relevant in noStateData mode.
		if(this.props.rowOrder !== undefined) {
            const rowOrder     = typeof(this.props.rowOrder) === "function" 
                ? this.props.rowOrder(rows) 
                : this.props.rowOrder;
			const map          = {};
			const renderRowMap = {};

			for(const r in rows)
				map[rows[r].data[this.props.rowKey]] = rows[r];

			for(const i in rowOrder) {
				const row = map[rowOrder[i]];

				renderRowMap[row.data[this.props.rowKey]] = row;

				renderRows.push(row);
			}

			rows.filter(row => row.newRow).forEach(row => (!renderRowMap[row.data[this.props.rowKey]]) && (renderRows.unshift(row)));
        } else if(this.props.noStateData && !this.props.controlOrder) {
            const newRows      = [];
            const existingRows = [];

            for(const r in rows) {
                const row = rows[r];

                if(row.data[this.props.rowKey] < 0)
                    newRows.push(row);
                else
                    existingRows.push(row); 
            }

            if(this.props.renderNewDataAtEnd)
                renderRows = [...existingRows, ...newRows];
            else
                renderRows = [...newRows.reverse(), ...existingRows];
        } else {
            renderRows = rows;
        }

        if(renderRows.length > this.listElementReferences.length) {
            for(const i in renderRows) {
                this.listElementReferences[i] = React.createRef();
            }
        }

        // <-- Manual row-ordering.
		this.lastRenderRows = renderRows;
        // <-- Row-ordering.
        
        const { 
            columnWidthMap,
            columnWidthMaps,
            columnOrder,
            columnConfig,
            columnConfigs,
            columnOrders,
            cellPresentations } = this.__rowConfigurations;
        const listRowType      = this.props.listRowType;
        const listRowTypeMap   = this.props.listRowTypeMap;
        const listRowTypeKey   = this.props.listRowTypeKey;
        const flips            = clone(this.state.flips);
		const OverlayComponent = this.props.overlayComponent;
        const renderSummaryRow = this.props.summaryRowType !== undefined; // TODO: Refactor all implementations to use row types and remove this concept.
        const SummaryRowType   = this.props.summaryRowType; // TODO: Needed anywhere?
        const columnMap        = Utils.makeMap(this.state.columns, "name");

        // The checking behavior is getting too complex and needs refactoring.
        // When props.useGlobalAllChecked === true, checking the checkbox in the List's header means ALL rows, including those not in props/state data at this moment will be checked automatically.
        // When props.useGlobalAllChecked === false, checking the header's checkbox only checks those rows in the current data set, but check states will still persist from previous data sets.
        // In the latter mode checking all rows in the current view manually activates the header checkbox, but this is not reflected in List's state's allChecked being true. 
        const checkedRows         = clone(this.state.checkedRows);
        const allChecked          = this.props.useGlobalAllChecked ? (this.state.allChecked || (this.props.useAllCheckedExcept && this.state.allCheckedExcept !== undefined)) : this.state.data.map(d => d[this.props.rowKey]).every(id => this.state.checkedRows[id]);
        const childrenVisibleRows = clone(this.state.childrenVisibleRows);

        // Stateful conditions that are inferred from other state data.
        const toolbarVisible  = this.toolbarShouldBeVisible();
        const checkedRowsAmount = this.determineCheckedRowsAmount();

		return (
			<div className={`mainListOuterWrapper 
                ${addedClassName} 
                ${rootClassName} 
                ${alternativeRows ? "alternativeRows" : ""}`} 
                ref={this.outerMainWrapper} style={this.props.style} id={this.props.id !== false ? this.props.id : false}>
                {this.state.isLoading && <div className="loading-indicator"><img src={require('../dashboard/insights/img/loading.svg').default} /></div>}
                {!this.props.hideHeader && this.props.virtualized && <div className="paddingHider" style={{ width: LIST_VIRTUALIZATION_EXTRA_PADDING }}></div>}
                <div className={`mainListWrapper 
                ${addedClassName} 
                ${fluidClassName}
                ${alternativeRows ? "alternativeRows" : ""}`} 
                    ref={this.mainWrapper} onScroll={this.onWrapperScroll} style={mainListWrapperStyle}>
                {
                    <ListHeader
                        ref={this.listHeader}
                        visible={!this.props.hideHeader}
                        hideHeaderButtons={this.props.hideHeaderButtons}
                        allChecked={this.state.allChecked || (this.props.useAllCheckedExcept && this.state.allCheckedExcept !== undefined)}
                        disableCheck={this.props.disableCheck}
                        mainWrapper={this.mainWrapper}
                        additionalToolbarButtons={this.props.additionalToolbarButtons}
                        additionalToolbarColumns={this.props.additionalToolbarColumns}
                        enforceMinimumWidths={this.props.enforceMinimumWidths}
                        hiddenToolbarColumns={this.props.hiddenToolbarColumns}
                        toolbarColumnOrder={this.props.toolbarColumnOrder}
                        outerMainWrapper={this.outerMainWrapper}
                        resizeMarker={this.resizeMarker}
                        fluidFix={this.fluidFix}
                        hideFluidFix={this.props.hideFluidFix}
                        className={`${addedClassName} ${alternativeRows ? "alternativeRows" : ""}`}
                        columnAmount={this.state.columns.length}
                        columnWidths={this.state.columnWidths}
                        columns={this.state.columns}
                        toolbarMode={toolbarVisible}
                        fluid={this.props.fluid}
                        minWidth={this.props.minWidth}
                        listWidth={this.state.listWidth}
                        listRef={this}
                        onSortRows={this.props.onSortRows}
                        onColumnConfigChange={(columns, whatChanged) => {
                            const newState   = {};
                            this.columnOrder = columns.map(c => c.name); // this.columnOrder + the order is also significant in state.columns..?
                            this.visibleColumnsOrder = columns.filter(a => a.visible).map(c => c.name);

                            newState.columns = this.createColumnOrder(this.columnOrder, columns);

                            if(whatChanged.visibility) {
                                if(typeof(this.props.onColumnVisibilityChange) === "function") {
                                    this.props.onColumnVisibilityChange(columns);
                                }

                                // let widths = this.calculateWidths(columns, this.state.listWidth);
                                const widths = this.calculateWidths(columns, this.outerMainWrapper.current.clientWidth);

                                newState.columnWidths = widths.columnWidths;
                                newState.listWidth    = widths.listWidth;
                            }

                            if(whatChanged.width) {
                                const widths = this.calculateWidths(columns, this.outerMainWrapper.current.clientWidth, true);

                                newState.columnWidths = widths.columnWidths;
                                newState.listWidth    = widths.listWidth;
                            }

                            this.setState(newState, () => {
                                this.flipAllRows();
                                this.saveColumnConfig(columns);
                            });
                        }}
                        onSetColumnOrder={order => {
                            this.columnOrder = order;
                            this.visibleColumnsOrder = order;

                            this.setState({
                                columns: this.createColumnOrder(order, this.state.columns) 
                            }, () => this.flipAllRows()); 
                        }}
                        checkedRowsAmount={checkedRowsAmount}
                        maxColumnMenuHeight={this.mainList.current !== null ? this.mainList.current.offsetHeight - 80 : 350}
                        showHiddenFromPrintIndicator={this.props.showHiddenFromPrintIndicator}
                    />
                }
					<div 
                        className={`mainList ${addedClassName} ${fluidClassName}`} 
                        style={listStyle} ref={this.mainList} 
                        onScroll={this.onScroll} 
                        onMouseDown={this.startGrabScrollCounter} 
                        onMouseUp={e => { this.stopGrabScrollCounter(e); this.endGrabScroll(); }}>
						<div ref={this.resizeMarker} className={`resizeMarker ${addedClassName}`}></div>
                        <div ref={this.contentDiv} className={`contents ${addedClassName}`} style={contentsStyle} onMouseMove={(event) => this.props.onMouseMove(event, this.mainList.current.scrollTop, this)} onMouseLeave={() => this.props.onMouseLeave(this)}>
                            {this.state.mounted && renderRows.length > 0 && (!this.props.virtualized ? renderRows : Object.keys(this._order))
                            .filter((i, elIndex) => {
                                return (
                                    !this.props.virtualized 
                                        ? i 
                                        : renderRows[this._order[i]]
                                ) !== undefined;
                            })
                            .map((i, elIndex) => {
                                const row = !this.props.virtualized 
                                    ? i 
                                    : renderRows[this._order[i]];

                                const ListElementComponent = this.determineRowType(row);
                                const colConfig = row.data[listRowTypeKey] !== this.props.columnHeaderConfigKey 
                                    && typeof this.props.columns === "object" 
                                    && columnConfigs.hasOwnProperty(row.data[listRowTypeKey]) 
                                        ? columnConfigs[row.data[listRowTypeKey]] 
                                        : columnConfig;

                                const colWidths = row.data[listRowTypeKey] !== this.props.columnHeaderConfigKey 
                                    && typeof this.props.columns === "object" 
                                    && columnWidthMaps.hasOwnProperty(row.data[listRowTypeKey]) 
                                        ? columnWidthMaps[row.data[listRowTypeKey]] 
                                        : columnWidthMap;

                                const colOrder = row.data[listRowTypeKey] !== this.props.columnHeaderConfigKey 
                                    && typeof this.props.columns === "object" 
                                    && columnOrders.hasOwnProperty(row.data[listRowTypeKey]) 
                                        ? columnOrders[row.data[listRowTypeKey]] 
                                        : columnOrder;

                                row.newRow          = row.data[this.props.rowKey] < 0;
                                row.checked         = checkedRows[row.data[this.props.rowKey]] || false;
                                row.allChecked      = allChecked;
                                row.childrenVisible = childrenVisibleRows[row.data[this.props.rowKey]] || false;

                                const props = {
                                    ref: this.listElementReferences[elIndex],
                                };

                                this.rowRefMap[row.data[rowKey]] = props.ref;

                                if(this.props.virtualized) {
                                    props.key = i;
                                } else if(row.data.hasOwnProperty("__originalCreationKey")) {
                                    props.key = row.data['__originalCreationKey'];
                                } else {
                                    props.key = !row.newRow ? row.data[this.props.rowKey !== undefined ? this.props.rowKey : "id"] || false : row.data.id || false;
                                }

                                if(!ListElementComponent) {
                                    return null;
                                }

                                return <ListElementComponent
                                	{...props}
                                    {...Object.assign({}, row,
                                        {
                                            listRef: this,
                                            attributes: this.attributeMap[row.data[rowKey]] || {},
                                            mainColumnConfig: columnConfig,
                                            className: usesMultipleListRowTypes ? this.props.listRowTypeClassNames[row.data[listRowTypeKey]] || "" : "",
                                            commonCellProps: this.props.commonCellProps,
	                                    	columnConfig: colConfig,
		                                    columnOrder: colOrder,
                                            columnOrders: columnOrders,
		                                    columnWidths: this.state.columnWidths,
                                            columnWidthMap: colWidths,
                                            columnWidthMaps: columnWidthMaps,
                                            columnMap: columnMap,
                                            columnConfigs: columnConfigs,
                                            cellPresentations: cellPresentations,
                                            editMode: this.props.editMode,
                                            hierarchyNode: this.hierarchyDataMap[row.data[this.props.rowKey]],
                                            disableInitialFocus: this.props.disableInitialFocusOnRow,
                                            fixFluidOverflow: this.props.fixFluidOverflow,
                                            flex: Boolean(this.props.fluid),
                                            fluid: Boolean(this.props.fluid),
                                            contentDivRef: this.contentDiv.current,
                                            rowConfiguration: this.props.rowConfig,
		                                    rowProps: this.props.rowProps,
                                            rowCallbacks: this.props.rowCallbacks,
                                            top: this.props.virtualized ? this._order[i] * this.props.rowHeight + startHeight : 0,
                                            treeMetaData: this.treeMetaDataMap[row.data[this.props.rowKey]] || {},
                                            treeMetaDataMap: this.treeMetaDataMap,
                                            rowHeight: this.props.rowHeight,
                                            rowHeightFix: this.props.rowHeightFix,
                                            order: this.props.virtualized ? this._order[i]: undefined,
		                                    recursionLevel: 0,
                                            hidden: false,
                                            onEdit: this.props.onEdit,
                                            rowDraggingEnabled: this.props.rowDragging,
                                            onDragStart: this.props.onDragStart,
                                            idType: this.props.idType,
                                            ignoreRowPropsChange: this.props.ignoreRowPropsChange,
                                            rowKey: this.props.rowKey,
                                            listRowTypeMap: listRowTypeMap,
                                            listRowTypeKey: listRowTypeKey,
                                            rowType: row.data.hasOwnProperty(listRowTypeKey) ? row.data[listRowTypeKey] : "default",
                                            manualCreate: this.props.manualCreate,
                                            multipleListRowTypes: usesMultipleListRowTypes,
                                            noStateData: this.props.noStateData,
                                            usesState: !this.props.noStateData,
                                            noColorVariance: this.props.noColorVariance,
                                            sharedData: this.props.sharedData,
                                            checkedRows: checkedRows,
                                            childrenVisibleRows: childrenVisibleRows,
                                            flips: flips,
                                            flip: flips[row.data[this.props.rowKey]],
                                            virtualized: this.props.virtualized,
                                            horizontalVirtualization: this.props.virtualization__useHorizontalVirtualization,
                                            virtualizationStartColumn: this.state.columnRenderStart, 
                                            virtualizationColumnAmount: this.state.columnRenderLength,
                                            rowIndex: elIndex,
                                            hideUndefinedCells: this.props.hideUndefinedCells,
                                            useLazyLoad: this.props.useLazyLoad,
                                            showHiddenFromPrintIndicator: this.props.showHiddenFromPrintIndicator,
                                            renderIndicators: this.props.renderIndicators,
                                            noElementWithMissingDefinition: this.props.noElementWithMissingDefinition
		                                })
                                    } />;
                            })}
                            {!this.props.hideBottomMarker && <BottomMarker 
                                listRef={this}
                                rowDraggingEnabled={true} 
                                noColorVariance={true}
                                data={this.bottomMarkerData}
                            />}
						</div>
						{
							renderSummaryRow
							&& 
							<SummaryRowType
								ref={this.summaryRow}
								key="summary-row"
								rows={renderRows}
                                listWidth={this.state.listWidth}
								{...{ listRef: this, columnConfig: columnConfig, rowProps: this.props.summaryRowProps, tree: this.formattedData, columnOrder: columnOrder, columnWidths: this.state.columnWidths, columnWidthMap: columnWidthMap, sharedData: this.props.sharedData }} />
						}
					</div>
					{(this.props.showOverlay && !this.state.hideOverlay && (this.state.newData.length == 0)) && (<OverlayComponent {...this.props.overlayProps} />)}
                    {(this.noResultsMessageShouldBeShown()) && <div className="no-results-overlay" style={{ top: !this.props.hideHeader ? 48 : 0, width: listStyle.width }}>
                        <div className="message">{this.props.noResultsMessage === undefined ? this.tr("No search results") : this.props.noResultsMessage}</div>
                    </div>}
				</div>
				<div className={this.props.showPageSelector ? "listFooter" : "listFooter zeroHeight"} ref={this.listFooter}>
					{this.props.showPageSelector && <div className="footerHalf left">
						<PageSelector ref={this.pageSelector} page={this.props.page} onPageChange={this._setPage} pageCount={this.props.pageCount} controlPage={this.props.controlPage} />
					</div>}
					{this.props.showPageSelector && <div className={`footerHalf right ${this.props.useHSRightPadding ? 'hs-right-padding' : ''}`}>
						<Select
							className="perPageSelector"
							variant="outlined"
							color="primary"
							value={this.props.perpage}
							onChange={(e, val) => this.props.onPerPageChange(e.target.value)}>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={30}>30</MenuItem>
							<MenuItem value={50}>50</MenuItem>
							<MenuItem value={75}>75</MenuItem>
							<MenuItem value={100}>100</MenuItem>
							<MenuItem value={200}>200</MenuItem>
                            <MenuItem value={500}>500</MenuItem>
                        </Select>
                        <p style={{ display: "inline-block" }}>
                            {this.tr('Displaying')} {this.props.perpage * ((this.props.controlPage ? this.props.page : this.state.currentPage) - 1) + (this.state.data.length > 0 ? 1 : 0)} – {
                                this.props.perpage * (this.props.controlPage ? this.props.page : this.state.currentPage) < this.props.totalCount ? this.props.perpage * (this.props.controlPage ? this.props.page : this.state.currentPage) : this.props.totalCount
                            } {this.tr('of')} {this.props.totalCount} {this.tr('records')}
                        </p>
					</div>}
				</div>
			</div>
		);
	}
}

export default List;
