import React from "react";
import { Button } from "@mui/material";
import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Calendar from "../../general/react-date-range/src/components/Calendar";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import InboxIcon from "@mui/icons-material/MoveToInbox";

export default function TaimerCalendarSidebarBody(props) {
	const updateCollapse = key => {
		let collapses = props.collapses;

		if (props.collapses.includes(key)) {
			const index = collapses.indexOf(key);
			if (index > -1) {
				collapses.splice(index, 1);
			}
		} else {
			collapses.push(key);
		}
		props.updateCalendarsCollapse(collapses);
	};

	const getListItem = (calendars, owner) => {

		return calendars.map((value, key) => {

			const labelId = `checkbox-list-label-${key}`;

			if ((owner && value.accessRole === "owner") || (!owner && value.accessRole != "owner")) {
				return (
					<ListItem
						className="calendar-list-item"
						key={key}
						role={undefined}
						dense={true}
						onClick={() => {
                            let calendars = props.calendars;
                            let showValue = calendars[key].show ? 0 : 1;
							calendars[key].show = showValue;
							props.updateCalendars(calendars, showValue);
						}}
					>
						<ListItemIcon>
							<Checkbox
								edge="start"
								color="primary"
								checked={value.show}
								tabIndex={-1}
								disableRipple
								className={
									"calendar-listitem-checkbox " + "box_color_" + value.backgroundColor.substr(1)
								}
								inputProps={{ "aria-labelledby": labelId }}
							/>
						</ListItemIcon>
						{value.summary && value.summary.length > 20 ? (
							<Tooltip title={value.summary} placement="right">
								<ListItemText
									className={"calendar-list-item-text"}
									id={labelId}
									primary={value.summary}
								/>
							</Tooltip>
						) : (
							<ListItemText className={"calendar-list-item-text"} id={labelId} primary={value.summary} />
						)}
					</ListItem>
				);
			}
		});
	};

	return (
		<div className="section first">
			<div className="searchArea">
				<Calendar
					date={props.currentDate == "0000-00-00" ? new Date() : props.currentDate}
					onChange={date => props.changeDate(date)}
					className="taimercalendar-dateselector"
				/>
				<List className="sidebar-list" dense>
					<ListItem
						className="calendar-list-item-collapse"
						button
						onClick={() => updateCollapse("own", !props.collapses.includes("own"))}
					>
						<ListItemText primary={props.tr("own calendars")} />
						{props.collapses.includes("own") ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={props.collapses.includes("own")} timeout="auto" unmountOnExit>
						<List component="div" disablePadding dense>
							{getListItem(props.calendars, true)}
						</List>
					</Collapse>
					<ListItem
						className="calendar-list-item-collapse"
						button
						onClick={() => updateCollapse("others", !props.collapses.includes("others"))}
					>
						<ListItemText primary={props.tr("other calendars")} />
						{props.collapses.includes("others") ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={props.collapses.includes("others")} timeout="auto" unmountOnExit>
						<List component="div" disablePadding dense>
							{getListItem(props.calendars, false)}
						</List>
					</Collapse>
				</List>
			</div>
		</div>
	);
}
