import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import React, { useContext } from 'react';
import { SettingsContext } from '../SettingsContext';
import styles from './EmbedVideoOverlay.module.scss';

const EmbedVideoOverlay = (props) => {
    const context = useContext(SettingsContext);
    return (
        <div id={styles.embedVideoOverlay}>
            <iframe src={props.url} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title={props.title}></iframe>
            <Button onClick={() => context.functions.setOverlayComponent(null)}>
                <Close />{' '}
            </Button>
        </div>
    );
};

export default EmbedVideoOverlay;
