import React from "react";
import InsightTable, { InsightTableListRow } from "../../InsightTable";
import cn from "classnames";
import ListCell from "../../../../list/ListCell";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import ListRow from "../../../../list/ListRow";
import { formatInputNumber } from "../../../../helpers";

class HoursByProjectSummaryRow extends ListRow {
  constructor(props, context) {
    super(
      props,
      {},
      {},
      "dashboard/insights/hours/components/HoursByProjectSummaryRow"
    );
  }

  defineClassName() {
    return "insightSummaryRow";
  }

  defineCells() {
    const {
      rows,
      sharedData: { currency, allData },
    } = this.props;

    let values = {};
    allData.map((row) => {
      Object.keys(row || {}).forEach((key) => {
        values[key]
          ? (values[key] += Number(row[key] || 0))
          : (values[key] = Number(row[key] || 0));
      });
    });

    let cells = {};

    Object.keys(this.props.columnConfig).forEach((key) => {
      cells[key] = (
        <ListCell
          width={this.props.columnConfig[key].width}
          className="totalcell"
          name="user"
          onlyDisplay
          editable={false}
          alignCell
        >
          {key == "split" ? "100 %" : formatInputNumber(values[key] || 0, "hours") + " h"}
        </ListCell>
      );
    });

    const totalColumns = [
      "project",
    ];

    totalColumns.forEach((col) => {
      cells[col] = (
        <ListCell
          width={(this.props.columnConfig[col] || {}).width || 0}
          className="totalcell"
          name={col}
          onlyDisplay
          editable={false}
          alignCell
        >
          {this.tr("Total")}
        </ListCell>
      );
    });

    return cells;
  }

  render() {
    const cells = this.defineCells();
    const className = [
      "summaryRow listElement row flex",
      this.defineClassName(),
    ].join(" ");

    return (
      <div className={className} style={{ height: `50px` }}>
        {this.props.columnOrder.map((columnName) => cells[columnName])}
      </div>
    );
  }
}
class HoursByProjectCell extends InsightTableListRow {
  defineCells() {
    const { data } = this.props;
    let otherCells = {};
    Object.keys(data).forEach(key => {
      switch (key) {
        case "project":
          otherCells[key] = (
            <ListCell onlyDisplay={true}>
              <div className="list-cell-container">
                <div className="list-cell-subcontainer">
                  <p className="header">{data["account"] || "-"}</p>
                  <p>{data[key] || "-"}</p>
                </div>
              </div>
            </ListCell>
          );
          break;
        case "split":
          otherCells[key] = (
            <ListCell onlyDisplay={true}>
              <div className="list-cell-container">
                <p>{data[key] ? Number(data[key]).toFixed(2) : "-"} %</p>
              </div>
            </ListCell>
          );
          break;
          break;
        case "tracked_h":
          {
            const percent = data.change;
            const percentStyle = { color: percent > 0 ? "#42b677" : "#f7548f" };
            const Arrow = percent > 0 ? ArrowUpward : ArrowDownward;

            otherCells[key] = (
              <ListCell onlyDisplay={true}>
                <div className="list-cell-container">
                  <p>{Number(data[key]).toFixed(2) || "-"} h</p>

                  {percent && <Arrow
                    style={percentStyle}
                    className="percent-icon"
                  />}
                  {percent && <span style={percentStyle} className="percent-label">
                    {Number(percent).toFixed(2)} %
                </span>}
                </div>
              </ListCell>
            );
          }
          break;
        default:
          otherCells[key] = (
            <ListCell onlyDisplay={true}>
              <div className="list-cell-container">
                <p>{data[key] || "-"}</p>
              </div>
            </ListCell>
          );
          break;
      }
    });

    let cells = {
      ...super.defineCells(),
      ...otherCells
    };

    return cells;
  }
}

const HoursByProject = props => {
  const { tr, data, currency, showSlider, loaded } = props;
  const columns = [
    {
      field: "project",
      name: "project",
      header: tr("Project"),
      width: 200,
      resizeable: false,
      moveable: false,
      hideable: false,
      showMenu: false
    },
    {
      field: "tracked_h",
      name: "tracked_h",
      header: tr("Tracked h"),
      width: 100,
      resizeable: false,
      moveable: false,
      hideable: false,
      showMenu: false
    },
    {
      field: "split",
      name: "split",
      header: tr("Split"),
      width: 70,
      resizeable: false,
      moveable: false,
      hideable: false,
      showMenu: false
    }
  ];
  return (
    <InsightTable
      loaded={loaded}
      height={400}
      hideDefaults={true}
      width={3}
      blockProps={{
        largeTitle: true,
        rootClassName: "block",
        wrapperClass: "block-wrapper",
        headerClass: "block-header"
      }}
      className={cn("insight-hours-list", "half-width")}
      showSlider={showSlider}
      title={tr("Hours by Project")}
      rowType={HoursByProjectCell}
      summaryRowType={HoursByProjectSummaryRow}
      rowKey="id"
      data={data}
      columns={columns}
      currency={currency}
    />
  );
};
export default HoursByProject;
