import React, { Fragment } from 'react';

import withStyles from '@mui/styles/withStyles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ColorHash from 'color-hash';
import { deepOrange } from '@mui/material/colors';


const colorHash = new ColorHash();

const style = {
    margin: 10,
    color: '#fff',
    backgroundColor: deepOrange[500],
};

const styles = theme => ({
    text: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    paper: {
      paddingBottom: 50,
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    subHeader: {
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      top: 'auto',
      bottom: 0,
    },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    fabButton: {
      position: 'absolute',
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: '0 auto',
    },
    primary: {},
    icon: {},
  });

function MailHeaders(props) {
  const { messages = [], search = "", classes } = props;

try {
  
  const filteredMessages = messages.filter( message =>{
    return message.displayName.toLowerCase().indexOf( search.toLowerCase() ) !== -1 || message.scoredEmailAddresses[0].address.toLowerCase().indexOf( search.toLowerCase() ) !== -1
  })
 
    return (
      <List>
            {filteredMessages.map(({ displayName, scoredEmailAddresses}) => 
              {
                var text = displayName.charAt(0).toUpperCase();
                if(messages.length > 0) 
                {
                  return (
                      <ListItem button onClick={() => props.onClick(scoredEmailAddresses[0].address)} >
                        <Avatar style={{background: colorHash.hex(scoredEmailAddresses[0].address), color: '#fff', fontWeight: "bold" }} >{text}</Avatar>
                        <ListItemText primary={displayName} secondary={
                          <Fragment>
                            <Typography component="span" className={classes.inline} color="textPrimary">
                              {scoredEmailAddresses[0].address}
                            </Typography>
                          </Fragment>
                        } 
                        />
                      </ListItem> 
                  )
                } else {
                  return null;
                }
              }
              )
            }
      </List>
    );
  } catch(error) {
    return <div></div>
  }
  
}

  export default withStyles(styles)(MailHeaders);