let overrides = {
    user: {},
    project: {},
};

const colorClasses = {
    user: {

    },
    project: {
    },
};
const nextColor = 1;

const style = document.createElement("style");
document.head.appendChild(style);

export function setColorOverride(type, id, color)
{
    overrides[type][id] = color;

    getColorClass({id, color}, type, true);

    const ev = new Event("ColorOverrideChanged");
    document.dispatchEvent(ev);
}

export function setColorOverrides(data)
{
    overrides = data;
}

export function getColorOverrides()
{
    return overrides;
}

const defaultColor = ["#F9F9F9", 0];

// Get color for user/project colors
export function getColor(obj, type) {
    if (obj === undefined)
        return "";

    // User Color
    if (type === 'user' && 'user' in obj) {
        return getColor(obj.user, 'user');
    }
    else if (type === 'project' && 'subproject' in obj && obj.subproject.id) {
        return getColor(obj.subproject, 'project');
    }
    else if (type === 'project' && 'project' in obj) {
        return getColor(obj.project, 'project');
    }

    const id = obj.id;

    if (id === undefined)
        return defaultColor;

    let color = obj.color;

    // Restore color override
    if (type === 'project' && id in overrides[type])
        color = overrides[type][id];

    if (color === undefined || color === "FFFFFF")
        return defaultColor;

    return ["#" + color, id];
}

export function getColorMarking(obj) {
    let color = "#0CFF00";

    if(obj.backgroundColor) {
        color = obj.backgroundColor;
    }
    const className = "color_" + color.substr(1);
    const styleName = className;
    colorClasses[color.substr(1)] = styleName;

    return colorClasses[color.substr(1)];
}

// Creates class for user/project colors
export function getColorClass(obj) {
    
    const id = 1;
    let color = "#0CFF00";

    if(obj.backgroundColor) {
        color = obj.backgroundColor;
    }

    const className = "color_" + color.substr(1);
    const classNameBox = "box_color_" + color.substr(1);

    const Color = require('color');
    color = Color(color).hsl().saturate(0.4).darken(0.4).hex();
    

    style.sheet.insertRule("." + className + " { " +
    "	background: " + color +
    "}");

    style.sheet.insertRule("." + classNameBox + " svg { " +
    "	color: " + color +
    "}");

    const styleName = className;
    colorClasses[color.substr(1)] = styleName;

    return colorClasses[color.substr(1)];
}