
export interface ReportData {
    used_rates: {
        field: string;
        rate: string;
        currency: string;
        rate_not_found: number | boolean;
    }[];
    default_currency: string;
}

export interface CurrencyConvertData {
    usedConverts: UsedConvert[];
    convertsNotFound: ConvertNotFound[];
    amountConverted: number;
}

export interface UsedConvert {
    companyCurrency: string;
    defaultCurrency: string;
    rate: string;
}
export interface ConvertNotFound {
    companyCurrency: string;
    defaultCurrency: string;
}

function getCurrencyConvertData(reportData: ReportData[], field: string): CurrencyConvertData {
    const usedConverts: UsedConvert[] = [];
    const convertsNotFound: ConvertNotFound[] = [];
    let amountConverted = 0;

    (reportData || []).forEach(data => {
        data = data || {};

        const rateData = (data.used_rates || []).filter(r => r.field == field);
        (rateData || []).forEach(r => {
            const companyCurrency = r?.currency?.toUpperCase() || "";
            const defaultCurrency = data.default_currency?.toUpperCase();
            const rate = r?.rate;

            if (r?.rate_not_found == 1) {
                if (companyCurrency && !convertsNotFound.find(n => n.companyCurrency == companyCurrency && n.defaultCurrency == defaultCurrency)) {
                    convertsNotFound.push({ companyCurrency, defaultCurrency });
                }
                amountConverted++;
            }
            else if (companyCurrency && rate && companyCurrency != defaultCurrency) {
                if (!usedConverts.find(n => n.companyCurrency == companyCurrency && n.defaultCurrency == defaultCurrency && n.rate == rate)) {
                    usedConverts.push({ companyCurrency, defaultCurrency, rate });
                }
                amountConverted++;
            }
        });
    });
    return {
        usedConverts,
        convertsNotFound,
        amountConverted
    }
}

export {
    getCurrencyConvertData
}
