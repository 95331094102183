import React from 'react';
import TaimerComponent from '../TaimerComponent';
import cn from 'classnames';
import { Collapse } from '@mui/material';

import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";

import styles from './ProfitLossCollapse.module.scss';

interface ProfitLossCollapseProps {
    title: string;
    className?: string;
    open: boolean;
    onToggle: () => void;
}

interface ProfitLossCollapseState {
}

class ProfitLossCollapse extends TaimerComponent<ProfitLossCollapseProps, ProfitLossCollapseState> {
    constructor(props, context) {
        super(props, context, "charts/ProfitLossChart");

        this.state = {
        }
    }

    render() {
        const { title, open, className } = this.props;

        return <>
            <div className={styles.title} onClick={this.props.onToggle}>
                <h2>{title}</h2>
                {!open ? <ExpandMore /> : <ExpandLess />}
            </div>
            <Collapse className={cn(styles.hidden, className)} in={open} mountOnEnter>
                {this.props.children}
            </Collapse>
        </>
    }
}

export default ProfitLossCollapse;