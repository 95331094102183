import MoreHoriz from '@mui/icons-material/MoreHoriz';
import React from 'react';
import ContextMenu from '../../general/ContextMenu';
import TaimerComponent from '../../TaimerComponent';

import DateCell from '../../list/cells/DateCell';
import StatusCell from '../../list/cells/StatusCell';
import TextInputCell from '../../list/cells/TextInputCell';
import List from '../../list/List';
import ListCell from '../../list/ListCell';
import PropsOnlyListRow from '../../list/PropsOnlyListRow';
import AdvancedSearch from '../../search/AdvancedSearch';

import { SettingsContext } from '../../SettingsContext';

import { Button, MenuItem } from '@mui/material';
import DataHandler from '../../general/DataHandler';
import MultiSelect from '../../general/MultiSelect';
import OutlinedField from '../../general/OutlinedField';
import ProductCatalogModalProvider from '../../list/dialogs/ProductCatalogModalProvider';

import { withSnackbar } from 'notistack';
import ConfirmationDialog from './../dialogs/ConfirmationDialog';

import _ from 'lodash';
import validator from 'validator';
import AutoCompleteCell from '../../list/cells/AutoCompleteCell';
import CurrencyListCell from '../../list/CurrencyListCell';
import LinkListCell from '../../list/LinkListCell';

import { ReactComponent as ActivateIcon } from '../../general/icons/Activate.svg';
import { ReactComponent as DeactivateIcon } from '../../general/icons/Deactivate.svg';
import { ReactComponent as Note } from '../../general/icons/Note.svg';
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import { ReactComponent as ResetPasswordIcon } from '../../general/icons/ResetPassword.svg';
import { ReactComponent as ViewIcon } from '../../general/icons/view.svg';

import { Lock } from '@mui/icons-material';
import colors from '../../colors';
import VersionContentManager from '../../general/VersionContentManager';
import CheckboxCell from '../../list/cells/CheckboxCell';
import { ColumnHeaderButton } from '../../list/ListHeader';
import './UserList.css';
import './UserManagement.css';

import UserLimitDialog from './../dialogs/UserLimitDialog';

class UserListRow extends PropsOnlyListRow {
    static contextType = SettingsContext;

    constructor(props) {
        super(props, {}, {}, 'settings/pages/UserList');
    }

    shouldComponentUpdate(nextProps, prevProps) {
        if (nextProps.hasOwnProperty('data') && nextProps['data']['id'] !== this.props.data['id']) {
            this.setSData({ data: nextProps.data });
            return false;
        }

        return true;
    }

    openUserCard() {
        const { updateView, company } = this.props.rowProps;
        const { id } = this.props.data;

        updateView({
            module: 'users',
            action: 'view',
            id: id,
            company,
            ...this.props.rowProps.returnProps,
        });
    }

    deleteUser = () => {
        this.props.rowProps.onDelete(this.props.data);
    };

    cellEdited = (name, value) => {
        const { data } = this.props;

        if (data[name] == value) return;

        data[name] = value;
        data['_invalidField_' + name] = false;

        DataHandler.request('PUT', { url: 'settings/users/' + data.id }, { data })
            .done((resp) => {
                this.setDataAndUpdate({ data });
            })
            .fail((resp) => {
                if (resp.status == 422) {
                    this.props.rowProps.enqueueSnackbar(this.tr('Invalid email'), { variant: 'error' });
                } else {
                    this.props.rowProps.enqueueSnackbar(this.tr('Error Saving'), { variant: 'error' });
                }
            });
    };

    validateField(name, value, validation) {
        if ((validation === 'empty' || validation === 'email') && (!value || value.trim() === '')) {
            this.showFieldError(name, value, name.charAt(0).toUpperCase() + name.slice(1) + ' cannot be empty!');
            return false;
        }

        if (validation === 'email' && !validator.isEmail(value)) {
            this.showFieldError(name, value, 'Invalid email!');
            return false;
        }

        this.cellEdited(name, value);
    }

    showFieldError(name, value, message) {
        let data = {};
        this.props.rowProps.enqueueSnackbar(this.tr(message), {
            variant: 'error',
        });

        data['_invalidField_' + name] = value.trim();
        this.setData(data);
    }

    getStateCell(locked) {
        return locked < 1 ? (
            <StatusCell
                style={{ width: this.props.columnWidthMap['state'] + 'px' }}
                value="Active"
                width={this.props.columnWidthMap['state']}
                displayData={{ color: colors.greenish_cyan, name: this.tr('Active') }}
            />
        ) : (
            <StatusCell
                style={{ width: this.props.columnWidthMap['state'] + 'px' }}
                value="Locked"
                width={this.props.columnWidthMap['state']}
                displayData={{ color: '#f7548f', name: this.tr('Locked') }}
            />
        );
    }

    defineCells() {
        const { data } = this.props;
        const { tr }   = this;

        const workWeekModifiedTr = data.workweek_modified
            ? ` (${tr("modified")})`
            : "";

        const workWeek = `${data.workweek_template_name}${workWeekModifiedTr}`

        return {
            context: (
                <ContextMenu
                    label={<MoreHoriz />}
                    buttonProps={{ className: 'action-menu' }}
                    className="cell row-menu"
                    width={this.props.columnWidthMap['context']}
                    style={{ width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + ' 1 0px' }}
                    noExpandIcon
                >
                    <MenuItem
                        onClick={() => {
                            this.openUserCard();
                        }}
                    >
                        <ViewIcon />
                        {tr('Open')}
                    </MenuItem>
                    {this.props.data.locked < 1 && (
                        <MenuItem
                            onClick={() => {
                                this.props.rowProps.lockUser(this.props.data);
                            }}
                        >
                            <DeactivateIcon />
                            {tr('Dectivate')}
                        </MenuItem>
                    )}
                    {this.props.data.locked < 1 && (
                        <MenuItem
                            onClick={() => {
                                this.props.rowProps.sendPassword({firstname: this.props.data.firstname, lastname: this.props.data.lastname, id: this.props.data.id});
                            }}
                        >
                            <ResetPasswordIcon />
                            {tr('Reset password')}
                        </MenuItem>
                    )}
                    {this.props.data.locked >= 1 && (
                        <MenuItem
                            onClick={() => {
                                this.props.rowProps.unlockUser(this.props.data);
                            }}
                        >
                            <ActivateIcon />
                            {tr('activate')}
                        </MenuItem>
                    )}
                    {this.props.data.deletable > 0 && (
                        <MenuItem
                            className="delete"
                            onClick={() => {
                                this.deleteUser();
                            }}
                        >
                            <RemoveIcon className="Delete" />
                            {tr('Delete')}
                        </MenuItem>
                    )}
                </ContextMenu>
            ),
            checked: this.props.data.id == this.context.userObject.usersId ? <ListCell width={this.props.columnWidthMap['checked']} value={''} editable={false} /> :  <CheckboxCell checked={this.props.checked} width={this.props.columnWidthMap['checked']} onClick={() => this.props.listRef.check(this.props.data.id)} />,
            uid: <ListCell style={{ width: this.props.columnWidthMap['uid'] + 'px' }} width={this.props.columnWidthMap['uid']} value={this.state.data['uid']} editable={false} />,
            firstname: (
                <TextInputCell
                    listCellType={LinkListCell}
                    listCellProps={{
                        urlHandler: (value) => `index.html?module=users&action=view&id=${this.props.data.id}&company=${this.props.rowProps.company}`,
                        editable: false,
                        noTab: true,
                        inEditMode: this.props.data.id < 0,
                        showErrorBorder: this.props.data['_invalidField_firstname'] === '',
                    }}
                    name="firstname"
                    style={{ width: this.props.columnWidthMap['firstname'] + 'px' }}
                    width={this.props.columnWidthMap['firstname']}
                    value={this.props.data['_invalidField_firstname'] === '' ? '' : this.props.data['firstname']}
                    editable={false}
                    onEdited={(name, value) => this.validateField(name, value, 'empty')}
                />
            ),
            lastname: (
                <TextInputCell
                    listCellType={LinkListCell}
                    listCellProps={{
                        urlHandler: (value) => `index.html?module=users&action=view&id=${this.props.data.id}&company=${this.props.rowProps.company}`,
                        editable: false,
                        noTab: true,
                        inEditMode: this.props.data.id < 0,
                        showErrorBorder: this.props.data['_invalidField_lastname'] === '',
                    }}
                    name="lastname"
                    style={{ width: this.props.columnWidthMap['lastname'] + 'px' }}
                    width={this.props.columnWidthMap['lastname']}
                    value={this.props.data['_invalidField_lastname'] === '' ? '' : this.props.data['lastname']}
                    editable={false}
                    onEdited={(name, value) => this.validateField(name, value, 'empty')}
                />
            ),
            email: (
                <TextInputCell
                    listCellProps={{
                        showErrorBorder: this.props.data['_invalidField_email'] || this.props.data['_invalidField_email'] === '',
                    }}
                    name="email"
                    style={{ width: this.props.columnWidthMap['email'] + 'px' }}
                    width={this.props.columnWidthMap['email']}
                    value={this.props.data['_invalidField_email'] || this.props.data['_invalidField_email'] === '' ? this.props.data['_invalidField_email'] : this.props.data['email']}
                    editable={false}
                    onEdited={(name, value) => this.validateField(name, value, 'email')}
                />
            ),
            activated_date: (
                <DateCell
                    style={{ width: this.props.columnWidthMap['activated_date'] + 'px' }}
                    width={this.props.columnWidthMap['activated_date']}
                    value={this.props.data['activated_date']}
                    name="activated_date"
                    editable={false}
                />
            ),
            title: (
                <TextInputCell
                    name="title"
                    style={{ width: this.props.columnWidthMap['title'] + 'px' }}
                    width={this.props.columnWidthMap['title']}
                    value={this.props.data['title']}
                    editable={true}
                    onEdited={this.cellEdited}
                    data-testid={"user-title"}
                />
            ),
            locked_date: (
                <DateCell
                    style={{ width: this.props.columnWidthMap['locked_date'] + 'px' }}
                    width={this.props.columnWidthMap['locked_date']}
                    value={this.props.data['locked_date']}
                    name="locked_date"
                    editable={false}
                />
            ),
            modified_by: (
                <ListCell style={{ width: this.props.columnWidthMap['modified_by'] + 'px' }} width={this.props.columnWidthMap['modified_by']} value={this.props.data['modified_by']} editable={false} />
            ),
            state: this.getStateCell(this.props.data.locked),
            groups: (
                <AutoCompleteCell
                    listCellProps={{
                        showTooltipForOverflownText: true,
                        inEditMode: this.props.data.id < 0,
                    }}
                    // autoCompleteData={this.autoCompleteData['groups']}
                    multiple={true}
                    width={this.props.columnWidthMap['groups']}
                    value={this.props.data['groups']}
                    editable={false}
                />
            ),
            latest_cost: (
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{
                        currency: this.props.rowProps.currency,
                    }}
                    name="latest_cost"
                    style={{ width: this.props.columnWidthMap['latest_cost'] + 'px' }}
                    width={this.props.columnWidthMap['latest_cost']}
                    value={this.props.data['latest_cost']}
                    editable={false}
                />
            ),
            type: (
                <ListCell
                    style={{ width: this.props.columnWidthMap['type'] + 'px' }}
                    width={this.props.columnWidthMap['type']}
                    value={this.props.data.companies_id > 0 ? this.tr('user') : this.tr('freelancer')}
                    editable={false}
                />
            ),
            workweek: (
                <ListCell
                    value={workWeek}
                    editable={false}
                />
            ),
            balanceStartdate: (
                <DateCell
                    style={{ width: this.props.columnWidthMap['balanceStartdate'] + 'px' }}
                    width={this.props.columnWidthMap['balanceStartdate']}
                    value={this.props.data['balanceStartdate']}
                    editable={false}
                />
            ),
            position: (
                <TextInputCell
                    name="position"
                    style={{ width: this.props.columnWidthMap['position'] + 'px' }}
                    width={this.props.columnWidthMap['position']}
                    value={this.props.data['position']}
                    editable={true}
                    onEdited={(name, value) => this.cellEdited(name, value)}
                />
            ),
            workdayLength: (
                <ListCell
                    type="number"
                    style={{ width: this.props.columnWidthMap['workdayLength'] + 'px' }}
                    width={this.props.columnWidthMap['workdayLength']}
                    value={this.props.data['workdayLength']}
                    editable={false}
                />
            ),
            employmentStartdate: (
                <DateCell
                    style={{ width: this.props.columnWidthMap['employmentStartdate'] + 'px' }}
                    width={this.props.columnWidthMap['employmentStartdate']}
                    value={this.props.data['employmentStartdate']}
                    editable={false}
                />
            ),
            employmentEnddate: (
                <DateCell
                    style={{ width: this.props.columnWidthMap['employmentEnddate'] + 'px' }}
                    width={this.props.columnWidthMap['employmentEnddate']}
                    value={this.props.data['employmentEnddate']}
                    editable={false}
                />
            ),
            supervisor: (
                <ListCell style={{ width: this.props.columnWidthMap['supervisor'] + 'px' }} width={this.props.columnWidthMap['supervisor']} value={this.props.data['supervisor']} editable={false} />
            ),
            teams: (
                <ListCell
                    showTooltipForOverflownText={true}
                    style={{ width: this.props.columnWidthMap['teams'] + 'px' }}
                    width={this.props.columnWidthMap['teams']}
                    value={this.props.data['teams']}
                    editable={false}
                />
            ),
            protitle: <ListCell style={{ width: this.props.columnWidthMap['protitle'] + 'px' }} width={this.props.columnWidthMap['protitle']} value={this.props.data['protitle']} editable={false} />,
            division: <ListCell style={{ width: this.props.columnWidthMap['division'] + 'px' }} width={this.props.columnWidthMap['division']} value={this.props.data['division']} editable={false} />,
        };
    }
}

class UserList extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'settings/pages/UserList');

        this.stickySearchKey = 'settings_user_list';

        this.state = {
            perpage: this.props.perpage,
            page: 1,
            users: [],
            userState: 1, // 0 = all, 1 = active, 2 = locked
            userCount: 0,
            userLimit: 0,
            activeUsers: 0,
            dialogData: undefined,
            typeFilter: '0',
            typeFilterDefault: [{ value: '0', label: this.tr('All') }],
            stickySearchInitialized: false,
            userDialogData: {
                open: false,
            },
        };

        this.filtersInitialValues = {
            typeFilter: '0',
            typeFilterDefault: [{ value: '0', label: this.tr('All') }],
            perpage: this.props.perpage,
            page: 1,
            userState: 1,
        };

        this.dialogs = {
            confirmation: ConfirmationDialog,
            userlimit: UserLimitDialog,
        };

        this.userTypes = [
            { id: '1', value: '1', label: this.tr('user') },
            { id: '2', value: '2', label: this.tr('freelancer') },
        ];

        this.sortConfig = undefined;
        this.searchTerms = undefined;
        this.autoCompleteData = false;

        this.fetchData = this.fetchData.bind(this);
        this.filtersAreInInitialState = this.filtersAreInInitialState.bind(this);
        this.initializeStickySearch = this.initializeStickySearch.bind(this);
        this.saveStickySearch = this.saveStickySearch.bind(this);
        this.sendPassword = this.sendPassword.bind(this);
        this.lockUser = this.lockUser.bind(this);
        this.unlockUser = this.unlockUser.bind(this);
        //  this.closeUserDialog = this.closeUserDialog.bind(this);

        this.list = React.createRef();
        this.advancedSearch = React.createRef();
        this.userTypeSelect = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();
        this.listenReset();
        window.addEventListener("userCreated", this.fetchData);

        DataHandler.request('GET', { url: `settings/company/${this.props.company}/defaults` }).done((response) => {
            this.setState({ currency: response.currency });
        });

        DataHandler.get({ url: `settings/users/autoCompleteData`, company: this.props.company }).done((data) => {
            this.autoCompleteData = data;
            this.initializeStickySearch();
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.unListenReset();
        window.removeEventListener("userCreated", this.fetchData);
    }

    listenReset = () => {
        document.body.addEventListener('keyup', this._resetFilters);
    };

    unListenReset = () => {
        document.body.removeEventListener('keyup', this._resetFilters);
    };

    sendPassword(data) {
        const { enqueueSnackbar } = this.props;

        if (this.state.passwordResetInProgress) {
            enqueueSnackbar(this.tr(`Password reset is already in progress`), {
                variant: 'error',
            });
            return;
        }

        enqueueSnackbar(this.tr(`Sending password reset mail`), {
            variant: 'info',
        });

        DataHandler.post({ url: 'settings/users/' + data.id + '/send_password_email' })
            .done((resp) => {
                if (resp.result === 'fail')
                    enqueueSnackbar(this.tr(`Password reset failed. please check user email`), {
                        variant: 'error',
                    });
                else
                    enqueueSnackbar(this.tr(`Password reset mail sent`), {
                        variant: 'success',
                    });
            })
            .fail(() => enqueueSnackbar(this.tr(`Something went wrong. Please try again`), { variant: 'error' }))
            .always(() => this.setState({ passwordResetInProgress: false }));

        this.setState({ passwordResetInProgress: true });
        this.closeUserDialog();
    }

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    };

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    };

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    };

    closeUserDialog = () => {
        this.setState({ userDialogData: { ...this.state.userDialogData, open: false } });
    };

    openUserDialog = (data, action, confirmFunc) => {
        this.setState({ userDialogData: { open: true, data, action, onClose: this.closeUserDialog, onConfirm: confirmFunc } });
    };

    unlockUser(data) {
        const { id } = data;
        const { enqueueSnackbar } = this.props;

        DataHandler.post({ url: 'settings/users/' + id + '/unlock' }).done((resp) => {
            if (resp.status && resp.status == 'error') {
                enqueueSnackbar(this.tr(`Not enough licenses`), {
                    variant: 'error',
                });
            }
            setTimeout(() => {
                this.fetchData();
            }, 1000);
        });
        this.closeUserDialog();
    }

    lockUser(data) {
        const { id } = data;
        DataHandler.post({ url: 'settings/users/' + id + '/lock' }).done((resp) => {
            setTimeout(() => {
                this.fetchData();
            }, 1000);
        });
        this.closeUserDialog();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.perpage !== nextState.perpage || this.state.page !== nextState.page) return false;

        return true;
    }

    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.fetchData();
        if (prevState.activeUsers != this.state.activeUsers) {
            this.props.onUsersLoaded && this.props.onUsersLoaded(this.state.activeUsers);
        }
    }

    initializeStickySearch() {
        DataHandler.get({ url: `saved_search/sticky/${this.stickySearchKey}` })
            .done((response, _, request) => {
                if (request.status !== 200) {
                    this.fetchData({}, true);
                    return;
                }
                this.searchTerms = response.searchTerms;
                this.sortConfig = response.sortConfig;

                this.setState({ ...response }, () => this.fetchData({ page: response.page }, true));
            })
            .fail((response) => {
                this.fetchData({}, true);
            })
            .always((response, _, request) => {
                this.setState({ stickySearchInitialized: true });
            });
    }

    saveStickySearch(parameters) {
        let stateToSave = {};
        stateToSave.searchTerms = _.cloneDeep(this.searchTerms);
        stateToSave.sortConfig = _.cloneDeep(this.sortConfig);

        Object.keys(this.filtersInitialValues).forEach((e) => (stateToSave[e] = parameters[e]));

        DataHandler.post({ url: `saved_search/sticky/${this.stickySearchKey}` }, { search: stateToSave });
    }

    filtersAreInInitialState() {
        const initial = _.cloneDeep(this.filtersInitialValues);
        delete initial.page;
        delete initial.perpage;
        delete initial.typeFilterDefault;
        const filters = {};

        for (let key in initial) {
            initial[key] = JSON.stringify(initial[key]);
            filters[key] = JSON.stringify(this.state[key]);
        }

        const freetext = this.searchTerms ? this.searchTerms.freetextSearchTerm : '';

        return _.isEqual(initial, filters) && !freetext && this.state.typeFilterDefault[0].value === '0';
    }

    _resetFilters = (evt) => {
        if (!evt || evt.keyCode == '27') {
            this.advancedSearch.current.clearSearch(evt, true);
            this.advancedSearch.current.clearSearchTextInput();

            this.searchTerms = {};
            this.sortConfig = undefined;
            this.userTypeSelect.current.handleChangeMulti(this.filtersInitialValues.typeFilterDefault);

            this.setState(
                {
                    ...this.filtersInitialValues,
                },
                () => this.fetchData()
            );
        }
    };

    fetchData = (override = {}, stickySearch = false) => {
        const { company, lockUsersViewMode } = this.props;
        let parameters = { page: 1, perpage: this.state.perpage, state: lockUsersViewMode ? '1' : this.state.userState, typeFilter: lockUsersViewMode ? '-1' : this.state.typeFilter };
        for (let oi in override) parameters[oi] = override[oi];

        const sortQuery = this.sortConfig !== undefined ? { name: this.sortConfig.name, asc: this.sortConfig.asc } : {};
        const queryParams = {
            page: parameters.page,
            perpage: parameters.perpage,
            sort: sortQuery,
            state: parameters.state,
            company,
            typeFilter: parameters.typeFilter,
        };
        const url = { url: 'settings/allusers', ...queryParams };

        if (!stickySearch && !lockUsersViewMode) this.saveStickySearch({ ...parameters, typeFilterDefault: this.state.typeFilterDefault, userState: parameters.state });

        if (this.searchTerms !== undefined && this.searchTerms.mode === 'advanced') {
            const advParams = { advanced_search_criteria: JSON.stringify(this.searchTerms.advanced_search_criteria) };
            this.savedParams = { ...url, mode: 'advanced' };

            DataHandler.post(this.savedParams, advParams).done((data) => {
                this.setState(data);
            });
        } else if (this.searchTerms !== undefined && this.searchTerms.mode === 'freetext') {
            const advParams = { freetext: this.searchTerms.freetextSearchTerm, advanced_search_criteria: JSON.stringify(this.searchTerms.advanced_search_criteria) };
            this.savedParams = { ...url, mode: 'freetext' };

            DataHandler.post(this.savedParams, advParams).done((data) => {
                this.setState(data);
            });
        } else {
            this.savedParams = { ...url };

            DataHandler.post(this.savedParams)
                .done((data) => {
                    this.setState(data);
                })
                .fail((data) => {
                    console.log('taimer-debug', 'failed');
                });
        }
    };

    onStateChange = (e) => {
        let { value } = e.target;
        this.setState({ userState: value, page: 1 }, this.fetchData({ state: value }));
    };

    rowChanged = (row) => {
        const { users } = this.state;

        let index = users.findIndex((e) => e.id == row.id);
        index = index < 0 ? users.length : index;
        users[index] = row;

        this.setState({ users });
    };

    addUser = (e) => {
        this.context.functions.addUser();
    };

    delete = (data) => {
        this.setState({
            dialogData: {
                id: data.id,
                saveFunc: () =>
                    DataHandler.request('DELETE', { url: 'settings/users/' + data.id }).done((resp) => {
                        this.fetchData();
                    }),
                text: this.tr('Do you want to delete user') + ': ' + data.lastname + '  ' + data.firstname + ' (US' + data.id + ')' + '?',
            },
        });
        this.openDialog('confirmation');
    };

    shouldShowLicenseCount = () => {
        return !(this.context.taimerAccount.trialStatus > 0 && this.context.versionId != 10 && this.context.versionId != 11)
    }

    render() {
        if (this.state.hasFreelancers === undefined || !this.state.stickySearchInitialized) return null;

        const { tr } = this;
        const { company, sharedData, lockUsersViewMode, usersToLock } = this.props;
        const { data, currency } = this.state;
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        const fields = [
            lockUsersViewMode ? { field: 'checked', name: 'checked', staticIndex: 0, header: '', width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false } : { field: 'context', name: 'context', staticIndex: 0, header: '', width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false },
            { field: 'lastname', name: 'lastname', header: tr('Surname'), width: 150 },
            { field: 'firstname', name: 'firstname', header: tr('First name'), width: 120 },
            { field: 'title', name: 'title', header: tr('Professional title'), width: 175 },
            { field: 'email', name: 'email', header: tr('Email address'), width: 250 },
            { field: 'state', name: 'state', header: tr('Status'), width: 200 },
            ...(this.state.hasFreelancers > 0 && !VersionContentManager.isFeatureHidden(this.namespace, 'userType') ? [{ field: 'type', name: 'type', header: tr('type'), width: 120 }] : []),
            ...(!VersionContentManager.isFeatureHidden(this.namespace, 'employmentTemplate') ? [{ 
                field: 'workweek', 
                name: 'workweek', 
                header: tr('Employment type'), 
                width: 200, 
                entityMode: true, 
                nullEntity: true,
                excludedOperators: ["contains", "doesnotcontain"] 
            }] : []),
            ...(!VersionContentManager.isFeatureHidden(this.namespace, 'supervisor') ? [{ field: 'supervisor', name: 'supervisor', header: tr('Supervisor'), width: 200 }] : []),
            ...(!VersionContentManager.isFeatureHidden(this.namespace, 'permissionGroups') ? [{ field: 'groups', name: 'groups', header: tr('Group'), width: 200 }] : []),
            ...(!VersionContentManager.isFeatureHidden(this.namespace, 'professionalTitle') ? [{ field: 'protitle', name: 'protitle', header: tr('Professional title (correct protitle)'), width: 200 }] : []),
            ...(!VersionContentManager.isFeatureHidden(this.namespace, 'teams') ? [{ field: 'teams', name: 'teams', header: tr('Team'), width: 200 }] : []),
            { field: 'position', name: 'position', header: tr('Position'), width: 200 },
            ...(!VersionContentManager.isFeatureHidden(this.namespace, 'internalCost') ? [{ field: 'latest_cost', name: 'latest_cost', header: tr('Internal hourly cost'), width: 175 }] : []),
            { field: 'balanceStartdate', name: 'balanceStartdate', header: tr('Balance start date'), width: 200 },
            // { field: 'workdayLength', name: 'workdayLength', header: tr('Workday length'), width: 200, type: 'number' },
            { field: 'employmentStartdate', name: 'employmentStartdate', header: tr('Employment Start Date'), width: 200 },
            ...(!VersionContentManager.isFeatureHidden(this.namespace, 'employmentEndDate') ? [{ field: 'employmentEnddate', name: 'employmentEnddate', header: tr('Employment End Date'), width: 200 }] : []),
            { field: 'uid', name: 'uid', header: tr('User ID'), width: 100 },
            { field: 'activated_date', name: 'activated_date', header: tr('Activated'), width: 100 },
            { field: 'locked_date', name: 'locked_date', header: tr('Locked'), width: 100 },
            { field: 'modified_by', name: 'modified_by', header: tr('Modified by'), width: 200 },
            ...(this.context.addons && this.context.addons.division ? [{ field: 'division', name: 'division', header: tr('Division'), width: 200 }] : []),
        ];

        const advancedSearchFields = fields
            .filter((f) => f.field !== 'context' && f.field !== 'state' && f.field !== 'latest_cost' && f.field !== 'type')
            .map((f) => {
                const field = { 
                    field: f.field, 
                    transl: f.header, 
                    type: f.type, 
                    entityMode: f?.entityMode || false,
                    nullEntity: f?.nullEntity || false,
                    excludedOperators: f?.excludedOperators || []
                };

                if (f.field == 'activated_date' || f.field == 'locked_date' || f.field == 'balanceStartdate') field.type = 'date';

                if (f.hasOwnProperty('autoCompleteData')) field.autoCompleteData = f.autoCompleteData;

                return field;
            });

        const rowProps = {
            fetchData: this.fetchData,
            rowChanged: this.rowChanged,
            updateView: this.props.updateView,
            enqueueSnackbar: this.props.enqueueSnackbar,
            // sendPassword: this.sendPassword,
            sendPassword: (data) => this.openUserDialog(data, 'Reset_password', this.sendPassword),
            lockUser: (data) => this.openUserDialog(data, 'Deactivate', this.lockUser),
            unlockUser: (data) => this.openUserDialog(data, 'Activate', this.unlockUser),
            onDelete: this.delete,
            company,
            currency: currency,
            returnProps: {
                returnmodule: 'settings',
                returnaction: 'index',
                group: 'users',
                page: 'default',
            },
            onUpdate: () => {
                this.fetchData({ page: this.state.page });
            },
            hasFreelancers: this.state.hasFreelancers,
        };

        const inputProps = {
            onChange: this.onStateChange,
        };
        return (
            <React.Fragment id="userlist-wrapper">
                {!lockUsersViewMode && <div id="top-bar-wrapper">
                    <div className="info-section">
                        <div className="header-section">
                            <h3>{tr('Users Title')}</h3>
                            <div className="user-count">
                                    <span><p className="sub-header-text">{this.tr('Users')}: </p><p className="bold-text">{this.state.userCount}</p></span>
                            </div>
                            {this.shouldShowLicenseCount() && <div className="license-count">
                                <span><p className="sub-header-text">{this.tr('Licenses used')}:</p><p className="bold-text">{this.state.activeUsers} / {this.context.taimerAccount.userlimit}</p></span> 
                            </div>}
                        </div>

                        <div className="info-text-section">
                            <p className="help-text">
                                {tr('Here you can find all your staff. You can')} <span style={{ fontWeight: 'bold' }}>{tr('activate')}</span>
                                {tr('  or  ')}
                                <span style={{ fontWeight: 'bold' }}>{tr('deactivate')}</span>
                                {tr(' users on a monthly basis.')}
                            </p>
                            {this.context.taimerAccount.isMulticompany && this.state.hasFreelancers ? 
                                <div className="freelancer-warning">
                                    <Note />
                                    <p>{tr("Freelancer users are shared between all companies in a multi-company Taimer account.")}</p>
                                </div>
                            : undefined}
                        </div>
                    </div>
                    <div className="top-bar">
                        <div className="input-container header-container primary">
                            <OutlinedField className="user-status-drop" label={tr('User status')} name="user_status" value={this.state.userState} select {...inputProps}>
                                {[tr('All users'), tr('Active users'), tr('Locked users')].map((row, index) => (
                                    <MenuItem key={index} value={index}>
                                        {row}
                                    </MenuItem>
                                ))}
                            </OutlinedField>
                            {this.state.hasFreelancers > 0 && (
                                <MultiSelect
                                    ref={this.userTypeSelect}
                                    className="user-type"
                                    width="160px"
                                    menuMaxWidth="160px"
                                    menuMinWidth="160px"
                                    options={this.userTypes}
                                    defaultValue={this.state.typeFilterDefault}
                                    noAllOptions
                                    label={this.tr('User type')}
                                    onChange={(data) => {
                                        data && this.setState({ typeFilter: data[0].value, typeFilterDefault: data, page: 1 }, () => this.fetchData());
                                    }}
                                />
                            )}
                            <AdvancedSearch
                                className
                                ref={this.advancedSearch}
                                mode={this.searchTerms && this.searchTerms.mode ? this.searchTerms.mode : undefined}
                                initialFilters={this.searchTerms ? this.searchTerms.currentFilters : undefined}
                                mainConfig={this.searchTerms && this.searchTerms.advanced_search_criteria ? { operator: this.searchTerms.advanced_search_criteria.operator } : undefined}
                                freetextLabel={this.searchTerms ? this.searchTerms.freetextSearchTerm : ''}
                                alwaysShowClearFilters={!this.filtersAreInInitialState()}
                                onClearSearch={this._resetFilters}
                                fields={advancedSearchFields}
                                noRequests={true}
                                onSearchTrigger={(searchTerms) => {
                                    this.searchTerms = searchTerms;
                                    this.setState({ page: 1 }, () => this.fetchData());
                                }}
                                autoCompleteData={{
                                    lastname: this.autoCompleteData['lastname'],
                                    firstname: this.autoCompleteData['firstname'],
                                    email: this.autoCompleteData['email'],
                                    uid: this.autoCompleteData['uid'],
                                    title: this.autoCompleteData['title'],
                                    modified_by: this.autoCompleteData['modified_by'],
                                    groups: this.autoCompleteData['groups'],
                                    supervisor: this.autoCompleteData['modified_by'],
                                    teams: this.autoCompleteData['teams'],
                                    protitle: this.autoCompleteData['protitles'],
                                    workweek: this.autoCompleteData['employment_templates'],
                                }}
                                perpage={this.state.perpage}
                            />
                        </div>
                        <div className="info-container">
                            <Button 
                                className="green btn add"
                                size="large" onClick={(e) => e.button !== 1 && this.addUser(e)}
                                onMouseUp={(e) => e.button === 1 && this.addUser(e)}
                                data-testid={"add-user-button"}
                                >
                                {tr('Add user')}
                            </Button>                            
                        </div>
                    </div>
                </div>}
                <div id="list-wrapper">
                    <List
                        id="userList"
                        //fluid
                        listRowType={UserListRow}
                        handler={this}
                        ref={this.list}
                        data={this.state.users}
                        columns={fields}
                        rowProps={rowProps}
                        saveColumnConfig={true}
                        userListSettingsKey="settings_userlist"
                        minWidth="1200px"
                        height={"fitRemaining"}
                        trimHeight={lockUsersViewMode ? -20 : -50}
                        onSortRows={(name, asc) => {
                            this.sortConfig = {
                                name: name,
                                asc: asc,
                            };
                            this.setState({ page: 1 }, this.fetchData());
                        }}
                        showPageSelector={true}
                        hideHeader={this.state.userCount === 0}
                        perpage={this.state.perpage}
                        controlPage={true}
                        page={this.state.page}
                        pageCount={this.state.pageCount}
                        totalCount={this.state.userCount}
                        onPageChange={(page) => this.setState({ page }, this.fetchData({ page }))}
                        onPerPageChange={(perpage) => {
                            this.setState({ perpage: perpage, page: 1 }, this.fetchData({ perpage: perpage }));
                        }}
                        enableToolbar={lockUsersViewMode}
                        hiddenToolbarColumns={["edit", "delete", "export"]}
                        additionalToolbarColumns={[{ name: "lockUsers", header: "", columnHeaderType: "lockUsersButton", width: 100 }]}
                        additionalToolbarButtons={{
                            lockUsersButton: <ColumnHeaderButton color={usersToLock == 0 ? undefined : 'disabled'} title={usersToLock == 0 ? this.tr("Lock users") : this.tr("Select ${amount} more users to lock", { amount: usersToLock })} onClick={usersToLock == 0 ? this.props.onLockUsers : undefined} icon={Lock} />,
                        }}
                        onCheck={this.props.onCheck}
                    />
                </div>
                {Dialog && <Dialog open onDialogClose={this.closeDialog} onDialogSave={this.confirmDialog} data={this.state.dialogData} />}

                <ProductCatalogModalProvider userDialogData={this.state.userDialogData} />
            </React.Fragment>
        );
    }
}

UserList.defaultProps = {
    perpage: 30,
};

export default withSnackbar(UserList);
