import React from 'react';
import OutlinedField from "./../../general/OutlinedField";
import { RadioGroup, Radio, FormLabel, FormControl, FormControlLabel } from '@mui/material';

class CustomFieldRadio extends React.Component {
    static defaultProps = {
        disabled: false,
        error: false,
    };

    onChange = (evt) => {
        this.props.onChange(this.props.name, evt.target.value)
    }

    render() {
        const { name, value, error, disabled, hideNoValueError, field: {
            name: label, required, validate, options
        } } = this.props;

        const novalue = value === '';

        return <FormControl component="fieldset" error={!disabled && (error || (required && validate && novalue && !hideNoValueError))}>
            <FormLabel component="legend">{!required ? label : label +" *"}</FormLabel>
            <RadioGroup row
                aria-label={label}
                name={name}
                // className={classes.group}
                value={value}
                onChange={this.onChange}>
                {options.map(o => (!o.deleted || o.id == value) && <FormControlLabel key={o.id} value={o.id} control={<Radio disabled={disabled} />} label={o.name} />)}

            </RadioGroup>
        </FormControl>
    }
}

export default CustomFieldRadio;

/**/