import React from "react";
import InsightTable, { InsightTableListRow } from "../../InsightTable";
import ListCell from "../../../../list/ListCell";
import { useState } from "react";
import InsightDropDown from "../../InsightDropDown";
import ListRow from "../../../../list/ListRow";

class FunnelBySalesAgentSummaryRow extends ListRow {
  constructor(props, context) {
    super(
      props,
      {},
      {},
      "dashboard/insights/sales/components/FunnelBySalesAgentSummaryRow"
    );
  }

  defineClassName() {
    return "funnelBySalesAgentSummaryRow";
  }

  defineCells() {
    const {
      rows,
      sharedData: { currency },
    } = this.props;

    const {
      taimerAccount,
      functions: { presentCurrency },
    } = this.context;

    let values = {};
    rows.map((row) => {
      Object.keys(row.data || {}).forEach((key) => {
        values[key]
          ? (values[key] += row.data[key])
          : (values[key] = row.data[key]);
      });
    });

    let cells = {};

    Object.keys(this.props.columnConfig).forEach((key) => {
      cells[key] = (
        <ListCell
          width={this.props.columnConfig[key].width}
          className="totalcell"
          onlyDisplay
          editable={false}
          alignCell
        >
          {presentCurrency(values[key])}
        </ListCell>
      );
    });

    const totalColumns = [
      "user",
      "account",
      "account_manager",
      "account_group",
      "project_manager",
      "sub_unit",
    ];

    totalColumns.forEach((col) => {
      cells[col] = (
        <ListCell
          width={(this.props.columnConfig[col] || {}).width || 0}
          className="totalcell"
          name={col}
          onlyDisplay
          editable={false}
          alignCell
        >
          {this.tr("Total")}
        </ListCell>
      );
    });

    return cells;
  }

  render() {
    const cells = this.defineCells();
    const className = [
      "summaryRow listElement row flex",
      this.defineClassName(),
    ].join(" ");

    return (
      <div className={className} style={{ height: `50px` }}>
        {this.props.columnOrder.map((columnName) => cells[columnName])}
      </div>
    );
  }
}

class FunnelBySalesAgentCell extends InsightTableListRow {
  defineCells() {
    const {
      sharedData: { currency },
      data
    } = this.props;
    const {
      functions: { presentCurrency }
    } = this.context;
    let otherCells = {};
    Object.keys(data).forEach(key => {
      switch (key) {
        default:
          otherCells[key] = (
            <ListCell alignCell={true} onlyDisplay={true}>
              <div style={{ paddingLeft: 16 }}>
                {presentCurrency(data[key] || 0, currency)}
              </div>
            </ListCell>
          );
          break;
      }
    });

    let cells = {
      ...super.defineCells(),
      account_group: (
        <ListCell editable={false} value={data.name || "–"} />
      ),
      account_manager: (
        <ListCell editable={false} value={data.users_name || "–"} />
      ),
      project_manager: (
        <ListCell editable={false} value={data.users_name || "–"} />
      ),
      sub_unit: (
        <ListCell editable={false} value={data.customers_name || "–"} />
      ),
      ...otherCells,
    };

    return cells;
  }
}

const COLUMN_SETTINGS = {
  width: 150,
  resizeable: false,
  moveable: false,
  hideable: false,
  showMenu: false
};

const FunnelBySalesAgent = props => {
  const { tr, currency, data, showSlider, ...otherProps } = props;
  const [mode, setMode] = useState("user");

  const getFirstColumn = (mode) => {
    switch (mode) {
      case "user":
        return {
          field: "user",
          name: "user",
          header: tr("Sales Agent"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      case "account":
        return {
          field: "account",
          name: "account",
          header: tr("Account"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      case "account_manager":
        return {
          field: "account_manager",
          name: "account_manager",
          header: tr("Account Manager"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      case "account_group":
        return {
          field: "account_group",
          name: "account_group",
          header: tr("Account Group"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      case "project_manager":
        return {
          field: "project_manager",
          name: "project_manager",
          header: tr("Project Manager"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      case "account":
        return {
          field: "account",
          name: "account",
          header: tr("Account"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      case "sub_unit":
        return {
          field: "sub_unit",
          name: "sub_unit",
          header: tr("Sub unit"),
          ...COLUMN_SETTINGS,
          width: 200,
        };
      default:
        return null;
    }
  };

  const columns = [
    getFirstColumn(mode),
    {
      field: "total",
      name: "total",
      header: tr("Total"),
      ...COLUMN_SETTINGS
    },
    {
      field: "pipeline_value",
      name: "pipeline_value",
      header: tr("Pipeline Value"),
      ...COLUMN_SETTINGS
    },
    {
      field: "pipeline_probability",
      name: "pipeline_probability",
      header: tr("Pipeline Probability"),
      ...COLUMN_SETTINGS
    },
    {
      field: "won_deals",
      name: "won_deals",
      header: tr("Won Deals"),
      ...COLUMN_SETTINGS
    },
    {
      field: "on_hold",
      name: "on_hold",
      header: tr("On Hold"),
      ...COLUMN_SETTINGS
    },
    {
      field: "lost",
      name: "lost",
      header: tr("Lost"),
      ...COLUMN_SETTINGS
    }
  ];

  const tabs = [
    {
      key: "user",
      label: tr("Sales Agent"),
      action: () => setMode("user"),
    },
    {
      key: "account_manager",
      label: tr("Account Manager"),
      action: () => setMode("account_manager"),
    },
    {
      key: "account_group",
      label: tr("Account Group"),
      action: () => setMode("account_group"),
    },
    {
      key: "project_manager",
      label: tr("Project Manager"),
      action: () => setMode("project_manager"),
    },
    {
      key: "account",
      label: tr("Account"),
      action: () => setMode("account"),
    },
    {
      key: "sub_unit",
      label: tr("Sub unit"),
      action: () => setMode("sub_unit"),
    },
  ];

  return (
    <InsightTable
      {...otherProps}
      className="funnel-by-sales-agent"
      filterComponents={<InsightDropDown tabs={tabs} selected={mode} />}
      height={400}
      hideDefaults={true}
      width={6}
      blockProps={{
        largeTitle: true,
        rootClassName: "block",
        wrapperClass: "block-wrapper",
        headerClass: "block-header"
      }}
      sliderTitleColumn="users_name"
      title={tr("Funnel by Sales Agent & Account")}
      rowType={FunnelBySalesAgentCell}
      summaryRowType={FunnelBySalesAgentSummaryRow}
      data={data[mode]}
      sliderSortKey="tracked"
      sliderColumns={["project", "account", "user", "value", "funnel"]}
      columns={columns}
      currency={currency}
      sharedData={{
        currency
      }}
      showSlider={showSlider ? (item) => showSlider(mode, item) : undefined}
    />
  );
};

export default FunnelBySalesAgent;
