import React, { Component } from 'react';
import ModifiedHoursList from '../../list/lists/ModifiedHoursList';


// My Hours tab
export default class ModifiedHours extends Component {

	constructor(props)
	{
		super(props);

		this.state = {
        };
	}
	
	sortTest = (items) => {
		this.setState({items})
	}

	render() {
		return (<div className="MyHours">
                <ModifiedHoursList {...this.props} />
            </div>);
	}
}