import React from 'react';

// List
import PropsOnlyListRow from "../../list/PropsOnlyListRow";
import TextInputCell from '../../list/cells/TextInputCell';
import TreeStructureIndicatorCell from '../../list/cells/TreeStructureIndicatorCell';
import AutoCompleteCell from "../../list/cells/AutoCompleteCell";
import DateCell from "../../list/cells/DateCell";

// import styles from "./ImportTasksReviewListRow.module.scss";
import { withSnackbar } from 'notistack';

class ImportTasksReviewListRow extends PropsOnlyListRow {
	static rowDimensions = {
		height: "44px",
		lineHeight: "44px"
	};

	constructor(props, context) {
		super(props, {}, { childRowType: ImportTasksReviewListRow }, "dialogs/imports/ImportTasksReviewListRow");

		this.context = context;
	}

	defineCells() {
		const { data, sharedData: { skills, priorities } } = this.props;

		let cells = {
			hierarchy:
				<TreeStructureIndicatorCell
					name="hierarchy"
					childrenVisible={this.props.childrenVisible}
					listCellProps={{ alignCell: true, textAlign: "left" }}
					onExpanderClick={e => this.props.listRef.toggleShowChildren(this.props.data.id)}
					rowData={this.props}
					value={data.hierarchy}>
					<TextInputCell
						name="hierarchy"
						value={data.hierarchy}
						editable={false}
					/>
				</TreeStructureIndicatorCell>,
			description:
				<TextInputCell
					value={data.description}
					name="description"
					editable={false}
					onEdited={(name, value) => {
						this.setDataAndUpdate(name, value);
					}} />,
			type:
				<AutoCompleteCell
					autoCompleteData={skills}
					value={data.skills_id}
					name="skills_id"
					editable={false}
					onEdited={(name, value) => {
						this.setDataAndUpdate(name, value);
					}} />,
			notes:
				<TextInputCell
					value={data.additional_description}
					name="additional_description"
					editable={false}
					onEdited={(name, value) => {
						this.setDataAndUpdate(name, value);
					}} />,
			priority:
				<AutoCompleteCell
					autoCompleteData={priorities}
					value={data.priorities_id}
					name="priorities_id"
					editable={false}
					onEdited={(name, value) => {
						this.setDataAndUpdate(name, value);
					}} />,
			start_date:
				<DateCell
					value={data.startdate}
					name="startdate"
					editable={false}
					onEdited={(name, value) => {
						this.setDataAndUpdate(name, value);
					}} />,
			end_date:
				<DateCell
					value={data.enddate}
					name="end_date"
					editable={false}
					onEdited={(name, value) => {
						this.setDataAndUpdate(name, value);
					}} />,
			allocated:
				<TextInputCell
					value={data.hours}
					name="hours"
					editable={false}
					onEdited={(name, value) => {
						this.setDataAndUpdate(name, value);
					}} />,
		};

		return cells;
	}
}

export default withSnackbar(ImportTasksReviewListRow);