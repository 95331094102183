import React from 'react';
import TaimerComponent from '../TaimerComponent';
import { Button, Drawer } from '@mui/material';
import styles from './UpgradeVersionSlider.module.scss';
import { Close } from '@mui/icons-material';
import UpgradeVersionView from './UpgradeVersionView';

interface Props {
    ref?: any;
    open: boolean;
    onClose?: () => void;
    version?: object;
}

interface State {
    open: boolean;
}

class UpgradeVersionSlider extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'general/UpgradeVersionSlider');
        this.state = {
            open: false,
        };
    }

    onClose = () => {
        this.setState({ open: false }, () => {
            setTimeout(() => {
                this.props.onClose && this.props.onClose();
            }, 500);
        });
    };

    componentDidMount = () => {
        if (this.props.open) {
            this.setState({ open: true });
        }
    };

    render() {
        const { open } = this.state;
        const { ref, version, ...rest } = this.props;

        return (
            <Drawer {...rest} open={open} onClose={this.onClose} anchor="right">
                <div id={styles.upgradeVersionSlider}>
                    <div className={styles.top}>
                        <Button onClick={this.onClose}>
                            <Close />
                        </Button>
                    </div>
                    <div className={styles.content}>
                        <UpgradeVersionView version={version} />
                    </div>
                </div>
            </Drawer>
        );
    }
}

export default UpgradeVersionSlider;
