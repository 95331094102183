import React from 'react';
import ListRow from "./../list/ListRow";
import ListCell from './../list/ListCell';

import styles from './SummaryRow.module.scss';
import cn from 'classnames';

export default class SummaryRow extends ListRow {
    constructor(props, context) {
        super(props, {}, {}, "projects/TabFinancialOverview");
    }

    defineClassName() {
        return cn("overview-summary-row", "listElement", "row", "flex", styles.row);
    }

    defineCells() {

        const { rows, sharedData: { currency } } = this.props;
        const { taimerAccount, functions: { presentCurrency } } = this.context;

        let values = {};
        rows.map((row) => {
            Object.keys(row.data || {}).forEach((key) => {
                values[key]
                    ? (values[key] += Number(row.data[key] || 0))
                    : (values[key] = Number(row.data[key] || 0));
            });
        });

        let cells = {};

        Object.keys(this.props.columnConfig).forEach((key) => {
            cells[key] = (
                <ListCell
                    key={key}
                    width={this.props.columnConfig[key].width}
                    className={cn("totalcell", Number(values[key]) < 0 && styles.negative)}
                    name="user"
                    onlyDisplay
                    editable={false}
                    alignCell>
                    {Number(values[key]) != '0' && values[key] ? presentCurrency(values[key].toFixed(2), currency) : '-'}
                </ListCell>
            );
        });

        const totalColumns = [
            "type_name",
        ];

        totalColumns.forEach((col) => {
            cells[col] = (
                <ListCell
                    key={col}
                    width={(this.props.columnConfig[col] || {}).width || 0}
                    className="totalcell"
                    name={col}
                    onlyDisplay
                    editable={false}
                    alignCell
                >
                    {this.tr("Total")}
                </ListCell>
            );
        });

        return cells;
    }

    render() {
        const cells = this.defineCells();
        return (
            <div className={this.defineClassName()} style={{ height: `50px` }}>
                {this.props.columnOrder.map((columnName) => cells[columnName])}
            </div>
        );
    }
}
