import TaimerComponent from '../TaimerComponent';
import SubtitleDialog from './SubtitleDialog';
import './ActionSelectDialog.css';

export interface action {
    key?: string; 
    title?: string;
    description?: string;
    icon?: any;
}

export interface ActionSelectDialogProps {
    onDialogClose: (event?: any, reason?: string) => void;
    title?: string;
    subtitle?: string;
    actions?: action[];
    onSelect?: (action?: string) => void;
    contentBeforeActions?: any;
    hideActions?: boolean;
    showButtons?: boolean;
    onConfirm?: (event?: any, reason?: string) => void;
    confirmButtonText?: string;
    confirmDisabled?: boolean;
}

class ActionSelectDialog extends TaimerComponent<ActionSelectDialogProps> {
    constructor(props, context) {
        super(props, context, "dialogs/ActionSelectDialog");
    }

    onSelect = (action) => {
        const { onSelect } = this.props;
        onSelect && onSelect(action);
    }

    render() { 
        const { title, subtitle, actions, onDialogClose, contentBeforeActions, hideActions } = this.props;

        return (
            <SubtitleDialog
                title={title || ""}
                subtitle={subtitle || ""}
                onDialogClose={onDialogClose}
                className="actionSelectDialog"
                hideButtons={true}
                >
                {contentBeforeActions && <div className="content-before-actions">{contentBeforeActions}</div>}
                {!hideActions && (actions || []).map(a => {
                    const Icon = a.icon;
                    return (
                        <ul className="action-select-container" onClick={() => this.onSelect(a.key)}>
                            <li className="icon">
                                {Icon ? <Icon /> : <div></div>}
                            </li>
                            <li className="texts">
                                <p className="actionHeader">{a.title}</p>
                                <p className="description">{a.description}</p>
                            </li>
                        </ul>
                )})}
            </SubtitleDialog>
        )
    }
}

export default ActionSelectDialog;