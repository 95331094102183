import React from 'react';
import ListRow from "../ListRow";
import ListCell from "../ListCell";
import moment from 'moment';
import { SettingsContext } from '../../SettingsContext';
import DateCell from '../cells/DateCell';

class ModifiedHoursListRow extends ListRow {
	static contextType = SettingsContext;

	constructor(props, context) {
		super(props, { checked: false });

		this.cellEdited = this.cellEdited.bind(this);
		this.check 		= this.check.bind(this);
	}


	check() {
		this.setState({ checked: !this.state.checked });
	}


	shouldComponentUpdate(nextProps, prevProps) {
		if(nextProps.hasOwnProperty("data") && nextProps['data']['id'] !== this.state.data['id']) {
			this.setState({ data: nextProps.data });
			return false;
		}

		return true;
	}


	cellEdited(name, value) {
		let data = this.state.data;

		data[name] = value;

		this.setState({ data: data });
	}


	render() {
		const className 			= ["myhoursListRow listElement row", this.props.hidden ? "hidden" : "", this.state.data['id'] < 0 ? "new" : ""].join(" ");

		const { data } = this.state;
		const { userObject } = this.context;
		const { tr } = this.props.rowProps;

		const change_time = moment(data.change_time);
		
		const cells = {
			change_time:
				<ListCell width={this.props.columnWidthMap['change_time']} value={change_time.format(userObject.datetimeFormat)} editable={false} />,
			user:
				<ListCell width={this.props.columnWidthMap['user']} value={data.old_user} editable={false} />,		
			project_old:
				<ListCell width={this.props.columnWidthMap['project_old']} value={data.project_old + " (" + data.projects_id + ")"} editable={false} />,
			project_new:
				<ListCell width={this.props.columnWidthMap['project_new']} value={data.project_new + " (" + data.new_projects_id + ")"} editable={false} />,		
			date_old:
				<DateCell width={this.props.columnWidthMap['date_old']} value={data.date} editable={false} />,
			date_new:
				<DateCell width={this.props.columnWidthMap['date_new']} value={data.new_date} editable={false} />,		
			time_old:
				<ListCell width={this.props.columnWidthMap['time_old']} value={data.old_time} editable={false} />,
			time_new:
				<ListCell width={this.props.columnWidthMap['time_new']} value={data.new_time} editable={false} />,	
			description_old:
				<ListCell width={this.props.columnWidthMap['description_old']} value={data.description} editable={false} showTooltipForOverflownText={true} />,
			description_new:
				<ListCell width={this.props.columnWidthMap['description_new']} value={data.new_description} editable={false} showTooltipForOverflownText={true} />,
			type:
				<ListCell width={this.props.columnWidthMap['type']} value={data.deleted == 1 ? tr("Deleted") : tr("Modified")} editable={false} />,			
			delete_message:
				<ListCell width={this.props.columnWidthMap['delete_message']} value={data.delete_message} editable={false} showTooltipForOverflownText={true} />,						
			edit_user:
				<ListCell width={this.props.columnWidthMap['edit_user']} value={data.edit_user} editable={false} />,		
		};

		return (
			<div className="listElement mainLevel">
				<div className={className} style={{ height: !this.props.hidden ? "56px" : "0px", lineHeight: "56px" }}>
					{this.props.columnOrder.map(columnName => cells[columnName])}
				</div>
			</div>
		);
	}
}

export default ModifiedHoursListRow;