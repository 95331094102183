import React from 'react';
import ListCell from './../list/ListCell';
import styles from './HourEntriesRow.module.scss';
import PropsOnlyListRow from '../list/PropsOnlyListRow';
import cn from 'classnames';
import { UserHourEntry } from './ProjectStatistics';

export default class HourEntriesRow extends PropsOnlyListRow {
    constructor(props, context) {
        super(props, {}, {}, "projects/HourEntriesRow");
    }

    defineClassName() {
        return styles.row;
    }

    defineCells() {
        const { sharedData: { currency } } = this.props;
        const { taimerAccount, userObject } = this.context;

        const data = this.props.data as UserHourEntry;

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency,
        }).format;

        let cells = {
            "user": (
                <ListCell onlyDisplay={true}>
                    <div className="list-cell-container">
                        <p>{data.users_name}</p>
                    </div>
                </ListCell>),
            "tracked": (
                <ListCell onlyDisplay={true}>
                    <div className="list-cell-container">
                        <p>{data.tracked.toFixed(2)} h</p>
                    </div>
                </ListCell>),
        }

        cells = {
            ...super.defineCells(),
            ...cells
        };

        return cells;
    }
}