import React, { Component } from 'react';
import Button from '@mui/material/Button';
import mainLogo from "../images/mail_image.png";
import TaimerComponent from "../../TaimerComponent";

export default class LoginView extends TaimerComponent {

	constructor(props, context) {
		super(props, context, "mail/MailComponents/LoginView")
	}

    render() {
        return (
				<div id="mail-signin">
						<div className="left_div">
							<div  className="text_div">
								<h1>{this.tr("Welcome to")} <b>{this.tr("Taimer Mail")}</b></h1>
								<div className="info">
								<div className="mail_infotext">
									{this.tr("Activate your full sales potential and sync your emails to Taimer.")}<br /><br />
									{this.tr("When unlocked, you can use send and receive emails straight into Taimer accounts and projects, while automatically linking them to related deals and contacts.")}<br /><br />
									{this.tr("Sync Taimer emails with any O365 mail.")}
								</div>
								</div>
								<Button onClick={() => this.props.onClickMailOffice()}>OFFICE</Button>
								{/* <Button onClick={() => this.props.onClickMailGoogle()}>Google</Button> */}
							</div>
						</div>
						<div className="right_div">
							<img src={mainLogo}/>
						</div>
				</div>)
    }
}