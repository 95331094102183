import React from "react";
import { Calendar } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import { parse } from "date-fns";
import debounce from 'lodash/debounce';

import TaimerComponent from "../../../TaimerComponent";
import CalendarView from "../../../workhours/time-tracker/CalendarView";
import { SettingsContext } from "../../../SettingsContext";
import DataHandler from "../../../general/DataHandler";
import SubmitHoursDialog from "../../../workhours/time-tracker/SubmitHoursDialog";
import { getAutocompleteDataForDialog } from '../../../resourcing/helpers';

import {
  postTaskQuick,
  postWorkhourEntry,
  getProjectsByID,
} from "../../../Data";
import BulkEntry from "../../../workhours/time-tracker/BulkEntry";
import MyHours from "../../../workhours/time-tracker/MyHours";
import BlockTopBar from "./BlockTopBar";
import "./TimeTracker.css";
import {
  setColorOverride,
  setColorOverrides,
  getColorOverrides,
} from "../../../workhours/time-tracker/Colors";
import { CheckCircleOutline, AssignmentReturned } from "@mui/icons-material";
import { Tooltip } from '@mui/material';
import { Button } from '@mui/material';
import { cloneDeep, isEqual } from "lodash";
import { withSnackbar } from "notistack";
import VersionContentManager from "../../../general/VersionContentManager";

const DnDCalendar = withDragAndDrop(Calendar, { backend: false });

const MODE_KEY = "my-day-time-tracker-mode";
export default withSnackbar(class TimeTracker extends TaimerComponent {
  static contextType = SettingsContext;

  constructor(props, context) {
    super(props, context, "dashboard/my_day/components/TimeTracker");

    this.modes = [
      {
        key: "calendar",
        label: this.tr("Calendar"),
        action: () => this._changeMode("calendar"),
        icon: require("../assets/calendar.svg").default,
      }
    ];

    if (!VersionContentManager.isFeatureHidden(this.namespace, 'bulkEntry')) {
      this.modes.push({
        key: "bulk",
        label: this.tr("Bulk view"),
        action: () => this._changeMode("bulk"),
        icon: require("../assets/bulk.svg").default,
      });
    }

    if (!VersionContentManager.isFeatureHidden(this.namespace, 'myHours')) {
      this.modes.push({
        key: "list",
        label: this.tr("List"),
        action: () => this._changeMode("list"),
        icon: require("../assets/list.svg").default,
      });
    }

    this._calendarView = React.createRef();
    this._bulkEntry    = React.createRef();
    this._myHours      = React.createRef();

    this._viewOptions = [
      {
        field: "showHourEntries",
        name: "showHourEntries",
        header: this.tr("Hour Entries"),
      },
      {
        field: "showTasks",
        name: "showTasks",
        header: this.tr("Tasks"),
        subItems: [
          {
            field: "showTasksInProgress",
            name: "showTasksInProgress",
            header: this.tr("In Progress"),
          },
          {
            field: "showTasksOverdue",
            name: "showTasksOverdue",
            header: this.tr("Overdue"),
          },
          {
            field: "showTasksDone",
            name: "showTasksDone",
            header: this.tr("Done"),
          },
        ]
      },
      {
        field: "createTasks",
        name: "createTasks",
        header: this.tr("Create new entries as tasks"),
      },
    ]

    const { userObject } = context;
    this.defaultUsers = [
      {
        id: userObject.usersId,
        label: userObject.fullname,
        color: userObject.color,
      },
    ];
    this.defaultProjects = [];
    const prefix = "TimeTracker_" + userObject.usersId;
    this.localStorageProjects = prefix + "_Projects";
    this.localStorageColorOverride = prefix + "_ColorOverride";
    this.localStorageViewEvents = prefix + "_ViewEvents";
    this.localStorageBulkDays = prefix + "_BulkDays";

    const mode = this._getModeFromLS();
    const viewModes = this._getViewModesFromLS();

    let selectedBulkDays = [1,2,3,4,5];

    try {
        const bulkDays = localStorage.getItem(this.localStorageBulkDays);
        if (bulkDays) {
            selectedBulkDays = JSON.parse(bulkDays) || selectedBulkDays;
        }
    } catch (e) {
        console.log(e);
    }

    this.state = {
      mode,
      events: [],
      filteredEvents: [],
      sidebarUsers: this.defaultUsers,
      sidebarProjects: [],
      currentUser: userObject.usersId,
      selectedBulkDays,
      colorsToUse: "project",
      loadedRange: false,
      viewModes,
      createTasks: false,
      resourcingAutoCompleteData: false,
      loading: true,
      lastEntry: {
        worktask: {
          id: 0,
          name: "",
        },
      },
      preventMulticompanyHourentries: false,
      approvalSendTotals: {},
      revokeTotals: {},
      additionalButtons: [], 
      submitHoursDialogOpen: false,
      hasApprovalCompanies: false,
      approvalTotalsFetched: false,
      enable_approval_submitting: 0,
      show_approval_summary: 0,
      workdays: {},
    };
  }

  componentDidMount = () => {
    super.componentDidMount();
    window.addEventListener("workhourSaved", this.refreshCurrentRange);
    window.addEventListener("taskSaved", this.refreshCurrentRange);
    document.addEventListener("ColorOverrideChanged", this.colorsChanged);
    this._getProjectsFromLS();
    this.refreshSidebarProjects(this.defaultProjects);
    this._getResourcingAutocompleteData();
    this._getApprovalTotals(false, false, true);
  };

  _getApprovalTotals = async (dateRange = false, refresh = false, getApprovalCompanies = false) => {
    const timeTrackerSettings = this.context.functions.getTimeTrackerSettings();
    let hasApprovalCompanies = false;
    if (getApprovalCompanies) {
      const approvalSettings = await DataHandler.get({ url: "timetracker/workhours/approval/settings", only_approval_companies: 1 });
      hasApprovalCompanies = approvalSettings.has_approval_companies && Number(approvalSettings.has_approval_companies) > 0
    }
    if (!timeTrackerSettings?.enable_approval_submitting) {
        this.setState({approvalTotalsFetched: true, hasApprovalCompanies});
        return;
    }

    const approvalSendTotals = await this._fetchApprovalTotals(dateRange, "submit", refresh);
    const revokeTotals = await this._fetchApprovalTotals(dateRange, "revoke", refresh);
    this.setState({approvalSendTotals, revokeTotals, hasApprovalCompanies, approvalTotalsFetched: true}, () => this._getAdditionalButtons());
  }

  _fetchApprovalTotals = async (dateRange, type = "submit", refresh = 0) => {
    const fetchParams = {
      start: dateRange.startDate,
      end: dateRange.endDate,
      for_approval_submit: 1,
      type,
      refresh
    }

    let fetchData = {};
    try {
      fetchData = await DataHandler.get({ url: "timetracker/workhours", ...fetchParams });
      fetchData.selectedHours = fetchData.daterange_hours.map(e => e.id);
    } catch (err) { }

    return fetchData;
  }

  _getTimeTrackerSettings = async () => {
    const timeTrackerSettings = await DataHandler.get({ url: `settings/timetracker/${this.context.userObject.companies_id}`});
    this.setState({ timeTrackerSettings }, () => this._getApprovalTotals(false, false, true));
  }

  shouldComponentUpdate = (newProps, newState) => {
    return newState != this.state;
  };

  colorsChanged = () => {
    this.forceUpdate();
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  _changeMode = (mode) => {
    this.setState({ mode });
    try {
      localStorage.setItem(MODE_KEY, mode);
    } catch (e) { }
  };

  _getModeFromLS = () => {
    let mode = "calendar";
    try {
      mode = localStorage.getItem(MODE_KEY) || "calendar";
    } catch (e) { }
    return mode;
  };

  _getViewModesFromLS = () => {
    let viewModes = {
      showHourEntries: true,
      showTasks: false,
      showTasksOverdue: false,
      showTasksDone: false,
      showTasksInProgress: false,
    };
    try {
      const viewModesString = localStorage.getItem(this.localStorageViewEvents);
      if (viewModesString) viewModes = JSON.parse(viewModesString);
    } catch (e) { }
    return viewModes;
  }

  _saveViewModesToLS = () => {
    try {
      localStorage.setItem(this.localStorageViewEvents, JSON.stringify(this.state.viewModes))
    } catch (e) { }
  }

  _getProjectsFromLS = async () => {
    this.defaultProjects = [];
    if (this.localStorageProjects in localStorage) {
      try {
        this.defaultProjects = JSON.parse(
          localStorage[this.localStorageProjects]
        );
      } catch (error) { }
    }

    if (this.localStorageColorOverride in localStorage) {
      try {
        const overrides = JSON.parse(
          localStorage[this.localStorageColorOverride]
        );

        if (overrides && typeof overrides === "object")
          setColorOverrides(overrides);
      } catch (error) { }
    }

    if (!this.defaultProjects || this.defaultProjects.length === 0) {
      this.defaultProjects = await DataHandler.get({
        url: "timetracker/defaultProjects",
      });
    }
  };

  refreshSidebarProjects = async (data = {}) => {
    let sidebarProjects = [];

    if (data.projects) {   
        const res = await DataHandler.post({url: 'timetracker/sidebar', return_new: 1 }, {
            data: data.projects.map(x => ({
                projects_id: x
            }))
        });

        if (res.sidebar) {
            sidebarProjects = res.sidebar;
        } else {
            sidebarProjects = await DataHandler.get({ url: "timetracker/sidebar" });
        }
    }
    else {
        sidebarProjects = await DataHandler.get({ url: "timetracker/sidebar" });
    }
    if (this.state.preventMulticompanyHourentries)
        sidebarProjects = sidebarProjects.filter(sp => sp.company == this.context.userObject.companies_id)

    this.setState({sidebarProjects})
}

  componentDidUpdate = () => {
    this.saveSettings();
  };

  saveSettings = () => {
    const projects = [];
    this.state.sidebarProjects.forEach((p) => projects.push(p.id));
    localStorage.setItem(this.localStorageProjects, JSON.stringify(projects));
    localStorage.setItem(
      this.localStorageColorOverride,
      JSON.stringify(getColorOverrides())
    );
  };

  componentWillUnmount = () => {
    super.componentWillUnmount();
    window.removeEventListener("workhourSaved", this.refreshCurrentRange);
    window.removeEventListener("taskSaved", this.refreshCurrentRange);
    document.removeEventListener("ColorOverrideChanged", this.colorsChanged);
  };

  refreshCurrentRange = (event, bulk = false) => {
    setTimeout(() => {
      const {
        loadedRange: { s, e },
      } = this.state;
      if (!bulk) this.getData(s, e, true);
      this.refreshSidebarProjects();
      this._getApprovalTotals(false, true, false);
    }, 1000);
  };

  _isVisibleUser = (user, visibleUsers) => {
    const list = visibleUsers || this.state.sidebarUsers;
    if (!list || !user) return false;
    const userIds = [];
    for (const i in list) {
      const u = list[i];
      userIds.push(Number((u.id + "").replace(/[^0-9]/g, "")));
      for (const j in u.user_ids) {
        userIds.push(Number(u.user_ids[j]));
      }
    }
    return userIds.findIndex((u) => u === user.id) > -1;
  };

  _getTaskType = (task) => {
    if (task.done > 0) {
      return "done";
    }
    const end = moment(task.end_date);
    if (end._d < new Date()) {
      return "overdue";
    }
    return "in-progress";
  };

  // Filters event list only to visible users
  _filterEvents = (events, visibleUsers = false) => {
    const { viewModes: { showHourEntries, showTasksInProgress, showTasksOverdue, showTasksDone } } = this.state;
    const eventsToShow = [];

    for (const key in events) {
      const event = events[key];
      var visible;
      if (event.is_task > 0) {
        for (const kk in event.users_hours) {
          visible = this._isVisibleUser(
            { id: Number(event.users_hours[kk].users_id) },
            visibleUsers
          );
          if (visible) {
            break;
          }
        }
      } else {
        visible = this._isVisibleUser(event.user, visibleUsers);
      }
      if (!showHourEntries && !event.is_task) {
        visible = false;
      }
      if (event.is_task > 0) {
        const taskType = this._getTaskType(event);
        if (!showTasksInProgress && taskType == "in-progress") {
          visible = false;
        }
        if (!showTasksOverdue && taskType == "overdue") {
          visible = false;
        }
        if (!showTasksDone && taskType == "done") {
          visible = false;
        }
        event.taskType = taskType;
      }

      // Only show if user is visible
      if (visible) eventsToShow.push(event);
    }

    return eventsToShow;
  };

  onSaveEvent = async (item) => {
    this.lastEditedEvent = item;
    const task =
      item.type == "task" ? postTaskQuick(item) : postWorkhourEntry(item);

    // Update entries to prevent flicker
    if (item.id) {
      const entries = [...this.state.events];
      const index = entries.findIndex((e) => e.id === item.id);
      entries[index] = {
        ...item,
        saving: true
      };
      this.setState({
        events: entries,
        filteredEvents: this._filterEvents(entries),
      });
    }

    const data = await task;

    if (data.status === 'FAILED') {
      const { enqueueSnackbar } = this.props;

      if (data.errorcode === 'PROJECT_NOT_MAIN') {
        enqueueSnackbar(this.tr("Cannot add hour to this project because it has subprojects."), {
          variant: "error",
        });

        return;
      } else if (data.errorcode === 'INVALID_WORKTASK') {
        return { action: 'open_edit' };
      } else if (data.errorcode === 'OVERLAPS_DISABLED') {
        enqueueSnackbar(this.tr("Hour entries can't overlap"), {
          variant: "error",
        });
      } else if (data.errorcode === 'INSERT_PREVENTED_IN_PREV_MONTHS') {
        enqueueSnackbar(this.tr("Can't add hours to this month"), {
          variant: "error",
        });
      } else if (data.errorcode === 'DESCRIPTION_REQUIRED') {
        enqueueSnackbar(this.tr("Description is required."), {
          variant: "error",
        });
      } else if (data.errorcode) {
        enqueueSnackbar(this.tr("Saving failed!") + " " + this.tr("Error") + ": " + this.tr(data.errorcode), {
            variant: "error",
        });
      } else {
        enqueueSnackbar(this.tr("Saving failed!"), {
          variant: "error",
        });
      }
    }

    if (isEqual(this.getLastEditedEvent(), item)) {
      window.dispatchEvent(new Event("workhourSaved"));
      this.refreshSidebarProjects();
    }
    return {status: 'ok'};
  };

  getData = (start, end, force) => {
    const { viewModes: { showTasksDone, showTasksInProgress, showTasksOverdue } } = this.state;

    this.setState({ loading: true }, async () => {
      if (this.state.loadedRange && !force) {
        const { s, e } = this.state.loadedRange;

        // If full range is loaded, we can skip this
        if (start.isSameOrAfter(s) && end.isSameOrBefore(e)) {
          this.setLoading(false);
          return;
        }
      }

      if (this.getDataRequest) this.getDataRequest.abort();

      this.setState({ loadedRange: { s: start, e: end } });

      const request = this.getDataRequest = DataHandler.get({
        url: "timetracker/workhours",
        mode: "timerange",
        start: start.format("YYYY-MM-DD"),
        end: end.format("YYYY-MM-DD"),
        resourcing: (showTasksInProgress || showTasksOverdue || showTasksDone) ? '1' : '0',
        users: [this.context.userObject.usersId],
      });

      const data = await request;
      const entries = [];
      data.entries.forEach((el) => {
        // tz breaks calendar if timezone changes
        // let startTime = moment
        //   .tz(el.start, "YYYY-MM-DD HH:mm", el.timezone)
        //   .local();
        // let endTime = moment
        //   .tz(el.end, "YYYY-MM-DD HH:mm", el.timezone)
        //   .local();
        const startTime = moment(el.start, "YYYY-MM-DD HH:mm").local();
        const endTime = moment(el.end, "YYYY-MM-DD HH:mm").local();

        el.start = startTime.format("YYYY-MM-DD HH:mm:ss");
        el.end = endTime.format("YYYY-MM-DD HH:mm:ss");
        if (el.is_task > 0) {
          el.start_date = el.start;
          el.starttime = startTime.format("HH:mm:ss");
          el.end_date = el.end;
          el.endtime = endTime.format("HH:mm:ss");
        }
        entries.push({
          ...el,
          start: parse(el.start, "YYYY-MM-DD HH:mm:SS", new Date(0)),
          end: parse(el.end, "YYYY-MM-DD HH:mm:SS", new Date(0)),
        });
      });

      const timeTrackerSettings = this.context.functions.getTimeTrackerSettings();

      this.setState({
        events: entries,
        filteredEvents: this._filterEvents(entries),
        dailyHours: data.days,
        loading: false,
        lastEntry: data.lastEntry,
        workdays: data.workdays?.days ?? {},
        preventMulticompanyHourentries: timeTrackerSettings.prevent_multicompany_hourentries,
      });
    });
  };

  getDataDebounced = debounce(this.getData, 300);

  _getResourcingAutocompleteData = async () => {
    this.setState({
      resourcingAutoCompleteData: await getAutocompleteDataForDialog(this.context.userObject.companies_id),
    });
  };

  updateLastEntry = (lastEntry) => {
    this.setState({ lastEntry });
  };

  onSelectTab = (e) => {
    const {
      functions: { updateView },
    } = this.context;
    updateView({ tab: typeof e === "string" ? e : e.target.name });
  };

  onSidebarProjectsChanged = (sidebarProjects) => {
    this.setState({ sidebarProjects });
  };

  onSidebarAddProject = async (project) => {
    const { sidebarProjects } = this.state;

    const index = sidebarProjects.findIndex((v) => v.id === project.id);

    if (index > -1) return;

    const list = await getProjectsByID([project.id]);

    this.setState({ sidebarProjects: [...sidebarProjects, ...list] });
  };

  onSidebarRemoveProject = async (project) => {
    const sidebarProjects = [...this.state.sidebarProjects];

    const index = sidebarProjects.findIndex((v) => v.id === project.id);

    if (index > -1) sidebarProjects.splice(index, 1);

    this.setState({ sidebarProjects });
  };

  onChangeColorProject = (project, color) => {
    setColorOverride("project", project.id, color.substring(1));
  };

  onAddEvent = (event) => {
    const events = cloneDeep(this.state.events);
    if (!event.id) {
        const start = event.start ? moment(event.start) : moment();
        const end = event.end ? moment(event.end) : moment();
        if (!start.isSame(end, 'date')) {
            const diff = Math.abs(start.diff(end, 'day'));
            for (let i = 0; i <= diff; i++) {
                const currentStart = moment(start).add(i, 'day');
                const currentEnd = moment(`${currentStart.format("YYYY-MM-DD")} ${end.format("HH:mm:ss")}`);
                events.push({
                    ...event,
                    start: currentStart.toDate(),
                    end: currentEnd.toDate(),
                    unit: event.unit?.id == "0" ? { ...event.unit, name: "" } : event.unit,
                    saving: true
                });    
            }
        } else {
            events.push({
                ...event,
                unit: event.unit?.id == "0" ? { ...event.unit, name: "" } : event.unit,
                saving: true
            });
        }
    } else {
        const eventIndex = events.findIndex(e => e.id == event.id);
        if (eventIndex != -1) {
            events[eventIndex] = {
                ...event,
                unit: event.unit?.id == "0" ? { ...event.unit, name: "" } : event.unit,
                saving: true
            }
        }
    }
    this.lastAddedEvent = event;
    this.setState({ events, filteredEvents: this._filterEvents(events) });
  }

  getLastEditedEvent = () => {
    return this.lastEditedEvent;
  }

  getLastAddedEvent = () => {
      return this.lastAddedEvent;
  }

  _renderContent = () => {
    const {
      filteredEvents,
      colorsToUse,
      currentUser,
      lastEntry,
      resourcingAutoCompleteData,
      preventMulticompanyHourentries,
      mode,
      sidebarProjects,
      createTasks,
      timeTrackerSettings,
      workdays,
    } = this.state;

    switch (mode) {
      case "calendar":
        return (
          <CalendarView
            ref={this._calendarView}
            startHour={undefined}
            endHour={undefined}
            colorsToUse={colorsToUse}
            events={filteredEvents}
            onAddEvent={this.onAddEvent}
            getLastAddedEvent={this.getLastAddedEvent}
            sidebarProjects={[]}
            onSidebarProjectsChanged={() => { }}
            onSidebarAddProject={() => { }}
            onSidebarRemoveProject={() => { }}
            sidebarUsers={[]}
            onSidebarUsersChanged={() => { }}
            onRequestData={this.getDataDebounced}
            defaultUser={currentUser}
            calendarType={DnDCalendar}
            onSaveEvent={this.onSaveEvent}
            onChangeColorProject={() => { }}
            resourcingAutoCompleteData={resourcingAutoCompleteData}
            noSidebar={true}
            createTasks={createTasks}
            lastEntry={lastEntry}
            updateLastEntry={this.updateLastEntry}
            refreshSidebarProjects={this.refreshSidebarProjects}
            preventMulticompanyHourentries={preventMulticompanyHourentries}
            openSubmitHoursDialog={this.openSubmitHoursDialog}
            enable_approval_submitting={timeTrackerSettings?.enable_approval_submitting == 1}
            parentComponent={"my day"} //for analytics
            workdays={workdays}
          />
        );
      case "bulk":
            return (
                <BulkEntry
                    ref={this._bulkEntry}
                    lastEntry={lastEntry}
                    isMyDay={true}
                    selectedDays={this.state.selectedBulkDays}
                    colorsToUse={colorsToUse}
                    sidebarProjects={sidebarProjects}
                    onSidebarAddProject={this.onSidebarAddProject}
                    onSidebarRemoveProject={this.onSidebarRemoveProject}
                    onSidebarProjectsChanged={this.onSidebarProjectsChanged}
                    onChangeUser={() => { }}
                    onChangeColor={this.onChangeColorProject}
                    onRequestData={this.getDataDebounced}
                    listHeight={620-93-85-48-15-59-18} //wow
                    // onEventSaved={this.refreshCurrentRange}
                    updateLastEntry={this.updateLastEntry}
                    refreshSidebarProjects={this.refreshSidebarProjects}
                    events={filteredEvents}
                    preventMulticompanyHourentries={preventMulticompanyHourentries}
                    parentComponent="MyDay - Bulk"
                    workdays={workdays}
                />
            );
      case "list":
        return (
          <div className="horizontal-scroll">
            <MyHours
              ref={this._myHours}
              setLoading={this.setLoading}
              noModifiedButton
              navigate={this.onSelectTab}
              openSubmitHoursDialog={this.openSubmitHoursDialog}
              hasApprovalCompanies={this.state.hasApprovalCompanies}
              getApprovalTotals={this._getApprovalTotals}
            />
          </div>
        );
      default:
        return null;
    }
  };

  _toggleViewOption = (option) => {
    if (option.name == "createTasks") {
      this.setState({ createTasks: !this.state.createTasks });
      return;
    }
    const viewModes = { ...this.state.viewModes };
    const newValue = !viewModes[option.name];
    viewModes[option.name] = newValue;
    if (option.subItems) {
      option.subItems.forEach(s => viewModes[s.name] = newValue);
    }
    Object.values(this._viewOptions).forEach(v => {
      if (v.subItems) {
        let value = true;
        v.subItems.forEach(s => {
          if (value) {
            value = viewModes[s.name];
          }
        });
        viewModes[v.name] = value;
      }
    });
    this.setState({ viewModes }, () => {
      this._saveViewModesToLS();
      this._calendarView.current && this._calendarView.current.requestDataUpdate(true);
    });
  };

  createAdditionalButton = (title, text, icon, className, action) => {
    return (
      <Tooltip
        placement="bottom"
        classes={{ tooltip: "darkblue-tooltip" }}
        title={title}
      >
        <Button 
          onClick={(e) => { action(); }}
          className={`wide-button ${className} no-my-day-drag`}
        >
          {icon}
          <span>{text.toUpperCase()}</span>
        </Button>
      </Tooltip>
    )
  }

  _getAdditionalButtons = () => {
    const { revokeTotals, approvalSendTotals } = this.state;

    const approveButton = this.createAdditionalButton(this.tr("Send hours for approval"), this.tr("Approval"), <CheckCircleOutline />, "primary", () => this.openSubmitHoursDialog(this.state.mode, "submit")); 
    const revokeButton = this.createAdditionalButton(this.tr("Revoke hours from approval"), this.tr("Revoke"), <AssignmentReturned />, "secondary", () => this.openSubmitHoursDialog(this.state.mode, "revoke")); 

    const additionalButtons = [];
    revokeTotals.all_hours?.length > 0 && !VersionContentManager.isFeatureHidden(this.namespace, 'hoursApproval') && additionalButtons.push(revokeButton);
    approvalSendTotals.all_hours?.length > 0 && !VersionContentManager.isFeatureHidden(this.namespace, 'hoursApproval') && additionalButtons.push(approveButton);
    this.setState({ additionalButtons });
  }

  closeSendDialog = () => {
    this.setState({ submitHoursDialogOpen: false });
  }

  openSubmitHoursDialog = async (mode = "calendar", type = "submit", dialogData = undefined) => {
    if (dialogData) {
      dialogData.type = type;
      this.setState({ submitHoursDialogOpen: true, dialogData });
      return;
    }
   
    let dateRange = {};
    if (mode == "myHours")
      mode = "list";

    switch (mode) {
        case "calendar":
            dateRange = this._calendarView.current.getSelectedDateRange();
            break;
        case "bulk":
            dateRange = this._bulkEntry.current.getSelectedDateRange();
            break;
        case "list":
            dateRange = this._myHours.current.getSelectedDateRange();
            break;
        default:
            dateRange = {};
    }

    dateRange.key = "selection";

    dialogData = {
      dateRange,
      type
    }
    this.setState({ submitHoursDialogOpen: true, dialogData });
};

confirmHourSubmit = (dateRange = undefined) => {
  this._getApprovalTotals(dateRange, true);
  this._updateViewData();
}

_updateViewData = () => {
  const {mode} = this.state;

  switch (mode) {
      case "calendar":
          const dateRange = this._calendarView.current.getSelectedDateRange(true);
          this.getData(dateRange.startDate, dateRange.endDate, true, true);
          break;
      case "bulk":
          this._bulkEntry.current.getEntries();
          break;
      case "list":
          this._myHours.current.updateMyhoursData();
          this._myHours.current.unCheckMyhoursList();
          break;
  }
};

  render() {
    const { mode, loading, viewModes, createTasks, additionalButtons, submitHoursDialogOpen, approvalTotalsFetched } = this.state;
    const { functions: { checkPrivilege }, timeTracker } = this.context;

    if (this.state.mode == "list" && !approvalTotalsFetched)
      return null;

    return (
      <>
      {submitHoursDialogOpen && 
        <SubmitHoursDialog 
          closeDialog={this.closeSendDialog}
          enqueueSnackbar={this.props.enqueueSnackbar}
          dialogData={this.state.dialogData}
          onConfirm={(dateRange) => this.confirmHourSubmit(dateRange)}
        />
      }
      <div className={`time-tracker`}>
        <BlockTopBar
          title={this.tr("Time Tracker")}
          dropdownItems={this.modes}
          loading={loading}
          selected={mode}
          extraTopBarComponents={additionalButtons}
          viewButton={mode == "calendar" && timeTracker.resourcingEnabled && this.context.addons && this.context.addons.resourcing && (checkPrivilege("projects", "project_resourcing_read") || checkPrivilege("projects", "own_resourcing_read")) && {
            title: this.tr("View options"),
            items: this._viewOptions,
            selectedItems: [...Object.keys(viewModes).filter(k => !!viewModes[k]), ...(createTasks ? ["createTasks"] : [])],
            onItemClick: this._toggleViewOption,
          }}
        />
        {mode == 'bulk' ? this._renderContent() : <div className="scrollable no-my-day-drag">
          <div style={{minWidth: 'min-content', height: "100%"}}>{this._renderContent()}</div>
        </div>}
      </div>
      </>
    );
  }
});