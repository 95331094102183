import React from 'react'
import { Navigate } from "react-big-calendar"
import Toolbar from 'react-big-calendar/lib/Toolbar';
import './CalendarToolbar.css';

// Calendar Toolbar
export default class CalendarToolbar extends Toolbar {
  render() {

    const { localizer: { messages }, label } = this.props

    return (
      <div className="CalendarHeader CalendarToolbar">
        <div className="CalendarToolbarSection navButtons">
            <button
                type="button"
                onClick={this.navigate.bind(null, Navigate.PREVIOUS)}
            >
                &lt;
            </button>
            <button
                type="button"
                onClick={this.navigate.bind(null, Navigate.TODAY)}
            >
                {messages.today}
            </button>
            <button
                type="button"
                onClick={this.navigate.bind(null, Navigate.NEXT)}
            >
                &gt;
            </button>
        </div>

        <div className="CalendarToolbarSection rangeName"><span className="rbc-toolbar-label">{label}</span></div>

        <div className="CalendarToolbarSection viewButtons">{this.viewNamesGroup(messages)}</div>
      </div>
    )
  }
}