import React from 'react';


import { format, isValid } from "date-fns";
import FileSaver from 'file-saver';

/* context */
import { SettingsContext } from './../../SettingsContext';

import ContextMenu from '../../general/ContextMenu';
import AttachmentCell from "../cells/AttachmentCell";
import CheckboxCell from "../cells/CheckboxCell";
import DateCell from "../cells/DateCell";
import MultiLinkListCell from "../cells/MultiLinkListCell";
import StatusCell from "../cells/StatusCell";
import TextInputCell from "../cells/TextInputCell";
import CurrencyListCell from "../CurrencyListCell";
import LinkListCell from "../LinkListCell";
import ListCell from "../ListCell";
import PropsOnlyListRow from "../PropsOnlyListRow";
import DataHandler from "./../../general/DataHandler";

import MoreHoriz from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';

import { ReactComponent as FormalizePreInvoice } from '../../general/icons/FormalizePre-invoice.svg';
import { ReactComponent as MaskAsPaid } from '../../general/icons/MarkAsPaid.svg';
import { ReactComponent as MarkAsSendIcon } from '../../general/icons/MarkAsSend.svg';
import { ReactComponent as Note } from '../../general/icons/Note.svg';
import { ReactComponent as Notice } from '../../general/icons/Notice.svg';
import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import { ReactComponent as ViewIcon } from '../../general/icons/view.svg';
import InvoiceTranslations from './../../general/backendTranslations/InvoiceTranslations';

import PrintIcon from '@mui/icons-material/Print';

import "./InvoiceListRow.css";
import { History, Send, Error } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import colors from '../../colors';
import "./InvoiceListRow.css";

class InvoiceListRow extends PropsOnlyListRow {

	static contextType = SettingsContext;

	constructor(props, context) {
		super(props, undefined, undefined, "list/rows/InvoiceListRow");

		const functionBinds = ['cellEdited', 'getDisplayData', 'openAttachment', 'printPdf'];
		[...functionBinds].forEach(f => this[f] = this[f].bind(this));

		for (const field of this.props.columnWidths)
			if (field.type == 'date')
				this[field.name] = React.createRef();

	}

	cellEdited(name, value) {
		const params = { id: this.props.data.id, name: name, value: value };

        DataHandler.post({url: `invoices/modify`}, params).done(

	        response => {
	        	const obj = {};
	        	obj[name] = value;
				this.setData(obj);
	        }
        );
	}

	openAttachment() {
		this.props.rowProps.attachmentHandler(this.props.data.id);
	}

	getDisplayData() {

		const statuses = {
			"0": {"name": this.tr("all"), "color": "#dde3e8"},
			"5": {"name": this.tr("draft"), "color": "#979797", "trigger_id": 0},
			"1": {"name": this.tr("waiting"), "color": "#ffb822", "trigger_id": 1},
			"2": {"name": this.tr("sent"), "color": "#2d9ff7", "trigger_id": 2},
			"4": {"name": this.tr("paid"), "color": colors.greenish_cyan, "trigger_id": 3},
			"3": {"name": this.tr("credited"), "color": "#716aca", "trigger_id": 4},
			"6": {"name": this.tr("overdue"), "color": "#f52b2b", "trigger_id": 5},
			"7": {"name": this.tr("sending"), "color": "#003A78", "trigger_id": 6}
		};

		let pr = this.props.data,
			data = statuses[pr['state']];
 

		if (pr['bill_type'] == "2")
			data = statuses['5'];

		if (pr.duedate < format(new Date(), 'YYYY-MM-DD') && pr.state == "2" && (pr.type == '1' || pr.type == '3'))
			data = statuses['6'];
		return data;
	}

	/* showInvoice() {
		window.location.href = `index.html?module=invoices&action=view&id=${this.props.data.id}&billid=${this.props.data.bill_id}`
	} */

	async printPdf(lang) {
        
        let langs = [lang];
		if (!langs || langs[0] == undefined || langs.length < 1)
			langs = await DataHandler.get({ url: `invoices/selected_bills_languages`, ids: [this.props.data.id]});

		const transl = new InvoiceTranslations().returnTranslations(langs);
      
		DataHandler.postArrayBuffer({url: 'invoices/print_pdf', ids: this.props.data.id, lang: lang}, {translations: transl})
        .done((response) => {
            const blob = new Blob([response], {
                type: 'application/pdf'
            });
            FileSaver.saveAs(blob, 'invoice_' + this.props.data.bill_id +"_" + this.props.data.projects + ".pdf");

        });
	}

	shouldComponentUpdate(nextProps, prevProps) {
		if(nextProps.hasOwnProperty("data") && nextProps['data']['id'] !== this.props.data['id']) {
			this.setState({ data: nextProps.data });
			return false;
		}

		return true;
	}

	//creates link data for cell
 	createLinkData = (name, data) => {
		const modules = {
			customer: 'customers',
			projects: 'projects',
			edited: 'persons',
			bill_id: 'invoices'
		},
			pickId = (n) => ({
			  "customer": `${modules[name]}_id`,
			  "projects": 'all_project_ids',
			  "edited": 'users_id',
			  "bill_id": 'id'

		})[n],
			id = data[pickId(name)].split(',');

		return {pathname: 'index.html',
			module: modules[name],
			action: `view${name == 'bill_id' ? '_new' : ''}`,
			id: (id.length > 1 ? id : id[0])
		}
	}

	getErrorNotificationTooltip = () => {
		const { data, rowProps } = this.props;
        const message = data.translate_error_message == 1 ? rowProps.listTr(data.log_error_message) : data.log_error_message;

		const tooltip = {
			classes: { tooltip: 'darkblue-tooltip' },
			interactive: true,
			arrow: true,
			placement: "right",
			title:
				<div className={"invoicelist-error-notification-tooltip"}>
					<p>{message || ""}</p>
					<button onClick={() => this.handleError()}>{this.tr('Handle error')}</button>
				</div>
		}

		return tooltip;
	}

	handleError = () => {
		const { data, rowProps } = this.props;

		rowProps.callErrorHandleDialog && rowProps.callErrorHandleDialog(data.id);
	}

    defineClassName() {
		const { data } = this.props;

		let className = "mainInvoiceListRow";
		if (data.integration_state == 2) {
			className += " errorRow"
		}
        return className;
    }

	defineCells() {

		const { taimerAddons, taimerAccount } = this.context;
		const { rowProps } = this.props;

		const className = `invoiceListRow listElement row mainLevel`,
			{checked } = this.props,
			statusDisplay = this.getDisplayData();
		const data = this.props.data;
		const rowStyle = { height: "44px", lineHeight: "44px" , display: this.props.rowProps.rowDisplay};

        const cells   = {};
        const cellArr = this.props.columnOrder.map(columnName => {

					const params = this.props.columnWidths.filter(col=>col.name == columnName)[0] || {};
					const width = params.width;
					let fieldType = "";
					if (columnName === "duedate" || columnName === "deliverydate") {
						if ((data.state == 1 && data.bill_type == 1) || (data.state == 10 && data.bill_type == 2))
							fieldType = "date_editable";
						else
							fieldType = "date";
					} else {
						fieldType = this.props.columnConfig[columnName].fieldType;
					}
					const value = columnName == 'customer' ? data.company : data[columnName];
					const { id, state, bill_type, type, integration_state } = data;

					const commonProps = {
						width: width,
						value: value,
						name: columnName
					};

					const isCurrencyRatedInvoice = !['', rowProps.currency].includes(data.currency_code) && (!['0', 0, '', 1, '1', '1.000000'].includes(data.currency_rate))

					const hasIntegrationError = integration_state == '2';

					switch (fieldType) {
						case 'rowmenu':
							return <ContextMenu label={<MoreHoriz />} buttonProps={{className: 'action-menu'}} className="cell row-menu" style={{width: width, flex: `${width} 1 0`}} noExpandIcon>
			                    {hasIntegrationError && <MenuItem
			                    	key="handleError"
			                    	onClick={()=> this.handleError()} >
										<div className="error-notification-container menuItem">
			                    			<Error />{this.tr('Handle error')}
										</div>
			                    </MenuItem>}
								<MenuItem
			                    	key="show"
                                    onClick={(evt) => rowProps.updateView({ module: 'invoices', action: 'view', id: data.id, billid: data.bill_id, companies_id: data.companies_id, editMode: false }, evt.button === 1)} >
			                    		<ViewIcon title="" /> {this.tr('Show')}
			                    </MenuItem>
			                    {(!hasIntegrationError && state == '1' && bill_type != '2') && <MenuItem
			                    	key="send"
			                    	onClick={()=>rowProps.sentHandler([id])} >
			                    		<MarkAsSendIcon />{this.tr('Mark as sent')}
			                    </MenuItem>}
			                    {(!hasIntegrationError && state == "2" && bill_type != '2') && <MenuItem
			                    	key="pay"
			                    	onClick={()=>rowProps.markPaidHandler(id)} >
			                    		<MaskAsPaid />{this.tr('Mark as paid')}
			                    </MenuItem>}
			                    {(!hasIntegrationError && !new Date(this.props.data.duedate) < new Date() && state == "2" && bill_type != '2' && type == '1') && <MenuItem
			                    	key="notice"
			                    	onClick={()=>rowProps.createNoticeHandler('createNotice', id)} >
			                    		<Notice />{this.tr('Create payment notice')}
			                    </MenuItem>}
			                    {(!hasIntegrationError && ['2', '4'].includes(state) && bill_type != '2' && type == '1' && this.props.data.credited_status == '') && <MenuItem
			                    	key="note"
			                    	onClick={()=>rowProps.createCreditHandler('createCredit', id)} >
			                    		<Note />{this.tr('Create credit note')}
			                    </MenuItem>}
			                    { bill_type == '2' && <MenuItem
			                    	key="formalize"
			                    	onClick={()=>rowProps.formalizeHandler('formalize', id)} >
			                    		<FormalizePreInvoice />{this.tr('Formalize pre-invoice')}
			                    </MenuItem>}
								<MenuItem key={'sendViaEmail'} onClick={() => rowProps.send(this.props.data)}> <Send />{this.tr('Send by email')}</MenuItem>
								<MenuItem key={'print'} onClick={() => rowProps.print(undefined, this.props.data)}> <PrintIcon />{this.tr('Print')}</MenuItem>
								{ bill_type == '2' && <MenuItem
									key="delete"
									className="delete"
			                    	onClick={()=>rowProps.deleteHandler('delete', id)} >
			                    		<RemoveIcon className="Delete" />{this.tr('Remove pre-invoice')}
			                    </MenuItem>}
								<MenuItem
			                    	key="showLog"
			                    	onClick={() => rowProps.showInvoiceLogSlider({ id: data.id, bill_id: data.bill_id, company: data.companies_id })}>
			                    		<History title="" /> {this.tr('Show Invoice Log')}
			                    </MenuItem>
			                </ContextMenu>;
						break;
						case 'check':
							return <CheckboxCell
								{...commonProps} 
								checked={checked}
								onClick={() => this.props.listRef.check(this.props.data.id)} />;
						break;
						case 'attachment':
							return <AttachmentCell 
								{...commonProps} 
								onClick={() => this.openAttachment()} />;
						break;
						case 'special':
							return <StatusCell 
								{...commonProps} 
								displayData={statusDisplay}/>;
						break;
						case 'date_editable':
							return <DateCell 
								{...commonProps} 
								onEdited={this.cellEdited} editable="true" listCellProps={{className: "date"}}/>;
						break;
						case 'date':
							return <DateCell 
								{...commonProps}
								value={(isValid(value) && value)}
								editable={false} />;
						break;
						case 'linktext':
							if(columnName == 'customer') {
								return <LinkListCell
									urlHandler={value => `index.html?module=customers&action=view&id=${this.props.data.customers_id}`}
									asText={this.props.data.can_see_account == 0}
									style={{width: this.props.columnWidthMap.customer + 'px'}}
									width={width}
									value={this.props.data.company}
									editable={false}
									noTab={true} />		
							}
							return <LinkListCell
								urlHandler={value => `index.html?module=users&action=view&id=${this.props.data.users_id}&company=${this.props.data.users_company}`}
								style={{width: this.props.columnWidthMap.edited + 'px'}}
								width={width}
								value={this.props.data.users_company !== null && Number(this.props.data.users_company) < 1 ? `${this.props.data.edited} (${this.tr("freelancer")})` : this.props.data.edited}
								editable={false}
								noTab={true} />		
						break;
						case 'bill_link':
							return <TextInputCell
							listCellType={LinkListCell}
							listCellProps={{
								urlHandler: value => `index.html?module=invoices&action=view&id=${data.parent_id > 0 && columnName == 'parentid' ? data.parent_id : data.id}&billid=${data.parentid > 0 && columnName == 'parentid' ? data.parentid : data.bill_id}&companies_id=${data.companies_id}&editMode=false`,
								valueHandler: value => value,
								editable: false,
								noTab: true,
							}}
							name={columnName}
							value={columnName == 'parentid' ? 
								(data.parentid > 0 ? `${data.type == 2 ? this.tr('Refund invoice') : this.tr('Payment notice')}: ${value}` : '') :
								value}
							width={width} />;
						break;
						case 'multitext':
							return <MultiLinkListCell
								{...commonProps}
								editable={false}
								noTab={true}
								urlHandler={value => {
									let urls = [],
										ld = this.createLinkData(columnName, data),
										url = `${ld.pathname}?`,
										ids = Array.isArray(ld.id) ? ld.id : [ld.id];

									Object.keys(ld).map(function(k, i) {
										url = `${url}${i > 0 && i < 3 ? `${k}=${ld[k]}&` : ''}`;
									});

									for (const id of ids) {
										urls.push(`${url}id=${id}`);
									}
									return urls;
								}} />;
						break;
						case 'currency':
							return <CurrencyListCell 
								{...commonProps}
								currency={rowProps.currency}
								editable={false} />;
							break;
						case 'paid':
							return <CurrencyListCell 
								{...commonProps}
								value={parseFloat(value).toFixed(2)}
								currency={rowProps.currency}
								editable={false} />;
							break;							
						case 'rate_currency':
							return <ListCell 
								{...commonProps}
								value={isCurrencyRatedInvoice ? `${parseFloat(data.total_in_currency).toFixed(2)} ${data.currency_symbol}` : ''}
								textAlign="right"
								editable={false} />;
							break;
						case 'rate_text':
							return <ListCell 
								{...commonProps}
								value={isCurrencyRatedInvoice ? value : ''}
								editable={false}
							/>;		
						case 'notification':
							return data.integration_state == 2 ? <ListCell
								{...commonProps}
								onlyDisplay={true}
								editable={false}
								>
								<Tooltip { ...this.getErrorNotificationTooltip() }>
									<div className="error-notification-container">
										<Error />
									</div>
								</Tooltip>
							</ListCell>
							: <ListCell 
								{...commonProps}
								editable={false}
							/>;													
						default:
							return <ListCell 
								{...commonProps}
								editable={false}
							/>;
						break;
					}
				});

        cellArr.forEach((c, i) => {
            cells[this.props.columnOrder[i]] = c;
        });

        return cells;
	}
}

InvoiceListRow.defaultProps = {
	children: []
};

export default InvoiceListRow;
