// TODO: Make into a singleton.

import _ from 'lodash';
import DataHandler from './../general/DataHandler';
import { parse } from 'date-fns';

type InvoiceReportBasicRow = {
    projects_id: string | number | string[];
    customers_id: string | number;
    companies_id: string | number;
    users_id: string | number;
}

type HoursReportBasicRow = {
    projects_id: string | number | string[];
    customers_id: string | number;
    companies_id: string | number;
    users_id: string | number;
    date: string;
    starttime: string;
    endtime: string;
}

type ForecastReportBasicRow = {
    projects_id: string | number | string[];
    customers_id: string | number;
    companies_id: string | number;
}

type ActivitiesReportBasicRow = {
    projects_id: string | number | string[];
    customers_id: string | number;
    companies_id: string | number;
}

type SalesReportBasicRow = {
    projects_id: string | number | string[];
    customers_id: string | number;
    companies_id: string | number;
}


type DataMap = {
    [key: string | number]: { [key: string | number]: string | number };
}

class ReportService {
    public async getInvoicingReports(params: Record<string, string> = {}): Promise<any> {
        const response = await DataHandler.get({ url: "new_reports/invoices", ...params });
        let rows       = response.reports.rows;

        rows = await this.addPCCDataToInvoicingReports(rows);
        rows = this.handleInvoiceLinksAndDates(rows);

        return rows;
    }

    public async getScheduledInvoicingReports(params: Record<string, string> = {}): Promise<any> {
        const response = await DataHandler.get({ url: "new_reports/scheduled_invoices", ...params });
        let rows       = response.reports.rows;

        rows = await this.addPCCDataToInvoicingReports(rows);
        rows = this.handleInvoiceLinksAndDates(rows);

        return rows;
    }

    public async getAccountReports(params: Record<string, string> = {}): Promise<any> {
        const response = await DataHandler.get({ url: "new_reports/accounts", ...params });
        const rows       = response.reports.rows;

        return rows;
    }

    private async addPCCDataToInvoicingReports(rows: InvoiceReportBasicRow[]): Promise<InvoiceReportBasicRow[]> {
        const customerCompanyPairs: { [key: string]: boolean }       = {};
        const projectIds: { [projectId: string | number ]: boolean } = {};
        const creatorIds: { [creatorId: string | number]: boolean }  = {};

        rows.forEach((r: InvoiceReportBasicRow) => {
            if(Array.isArray(r.projects_id)) {
                r.projects_id.forEach(pid => {
                    projectIds[pid] = true
                })
            } else {
                projectIds[r.projects_id] = true
            }
        });

        rows.forEach((r: InvoiceReportBasicRow) => creatorIds[r.users_id] = true);
        rows.forEach((r: InvoiceReportBasicRow) => customerCompanyPairs[`${r.customers_id}-${r.companies_id}`] = true);

        const pccData: {
            projects: DataMap,
            customers: DataMap,
            creators: DataMap,
        } = await DataHandler.post({
            url: "new_reports/invoices/pcc_data"
        }, {
            project_ids: Object.keys(projectIds),
            creator_ids: Object.keys(creatorIds),
            customer_company_pairs: Object.keys(customerCompanyPairs).map((k: string) => {
                const [customerId, companyId] = k.split("-");

                return {
                    customers_id: customerId,
                    companies_id: companyId
                };
            })
        });

        return rows.map((r: InvoiceReportBasicRow) => {
            const ccKey = `${r.customers_id}-${r.companies_id}`;

            if(Array.isArray(r.projects_id)) {
                r.projects_id.forEach(el => {
                    Object.keys(pccData?.projects[el] ?? []).forEach((k: string) => {
                        if(pccData.projects[el][k]) {
                            r[k] = pccData.projects[el][k];
                        }
                    });
                });

                if (r['project_locked']) {
                    r['project_locked'] = _.uniq(r['project_locked']).sort();
                }
            } else {
                const pid = r.projects_id;

                Object.keys(pccData?.projects[pid] ?? [])
                    .forEach((k: string) => r[k] = pccData.projects[pid][k]);
            }

            Object.keys(pccData?.customers[ccKey] ?? [])
                .forEach((k: string) => r[k] = pccData.customers[ccKey][k]);
            Object.keys(pccData?.creators[r.users_id] ?? [])
                .forEach((k: string) => r[k] = pccData.creators[r.users_id][k]);

            return r;
        });
    }

    private handleInvoiceLinksAndDates(rows): any[] {
        rows = this.createProjectLinks(rows);
        rows = this.createAccountLinks(rows);
        rows = this.createUserLinks(rows, 'creator');
        rows = this.handleInvoiceDates(rows);

        return rows;
    }

    private handleInvoiceDates(rows): any[] {
        return rows.map(r => {
            r = this.addDates(r, "duedate");
            r = this.addDates(r, "creationdate");
            r = this.addDates(r, "deliverydate");
            r = this.addDates(r, "log_created");

            return r;
        });
    }

    public async getHoursReports(params: Record<string, string> = {}): Promise<any> {
        const response = await DataHandler.get({ url: "new_reports/hours", ...params });
        let rows       = response.reports.rows;

        rows = await this.addPCCDataToHoursReports(rows);
        rows = this.handleHoursLinksAndDates(rows);

        return rows;
    }

    private handleHoursLinksAndDates(rows): any[] {
        rows = this.createProjectLinks(rows);
        rows = this.createAccountLinks(rows);
        rows = this.createUserLinks(rows, 'user');
        // rows = this.handleInvoiceDates(rows);

        return rows;
    }

    private async addPCCDataToHoursReports(rows: HoursReportBasicRow[]): Promise<HoursReportBasicRow[]> {
        const customerCompanyPairs: { [key: string]: boolean }      = {};
        const projectIds: { [projectId: string | number ]: boolean } = {};
        const usersIds: { [userId: string | number]: boolean } = {};

        rows.forEach(r => {
            if(Array.isArray(r.projects_id)) {
                r.projects_id.forEach(pid => {
                    projectIds[pid] = true
                })
            } else {
                projectIds[r.projects_id] = true
            }
        });

        rows.forEach(r => usersIds[r.users_id] = true);
        rows.forEach(r => customerCompanyPairs[`${r.customers_id}-${r.companies_id}`] = true);

        const pccData: {
            projects: DataMap,
            customers: DataMap,
            users: DataMap,
        } = await DataHandler.post({
            url: "new_reports/hours/pcc_data"
        }, {
            project_ids: Object.keys(projectIds),
            user_ids: Object.keys(usersIds),
            customer_company_pairs: Object.keys(customerCompanyPairs).map((k: string) => {
                const [customerId, companyId] = k.split("-");

                return {
                    customers_id: customerId,
                    companies_id: companyId
                };
            })
        });

        return rows.map((r: HoursReportBasicRow) => {
            const ccKey = `${r.customers_id}-${r.companies_id}`;

            r['start'] = `${r.date} ${r.starttime}`; 
            r['end'] = `${r.date} ${r.endtime}`; 

            if(Array.isArray(r.projects_id)) {
                r.projects_id.forEach(el => {
                    Object.keys(pccData?.projects[el] ?? []).forEach((k: string) => {
                        if(pccData.projects[el][k]) {
                            r[k] = pccData.projects[el][k];
                        }
                    });
                });
            } else {
                const pid = r.projects_id;
                Object.keys(pccData?.projects[pid] ?? [])
                    .forEach((k: string) => r[k] = pccData.projects[pid][k]);
            }

            Object.keys(pccData?.customers[ccKey] ?? [])
                .forEach((k: string) => r[k] = pccData.customers[ccKey][k]);
            Object.keys(pccData?.users[r.users_id] ?? [])
                .forEach((k: string) => r[k] = pccData.users[r.users_id][k]);

            return r;
        });
    }

    public async getForecastReports(params: Record<string, string> = {}): Promise<any> {
        const response = await DataHandler.get({ url: "new_reports/forecast", ...params });
        let rows       = response.reports.rows;

        rows = await this.addPCCDataToForecastReports(rows);
        rows = this.handleForecastLinksAndDates(rows);

        return rows;
    }

    private handleForecastLinksAndDates(rows): any[] {
        rows = this.createProjectLinks(rows);
        rows = this.createAccountLinks(rows);

        return rows;
    }

    private async addPCCDataToForecastReports(rows: ForecastReportBasicRow[]): Promise<ForecastReportBasicRow[]> {
        const customerCompanyPairs: { [key: string]: boolean }      = {};
        const projectIds: { [projectId: string | number ]: boolean } = {};

        rows.forEach(r => {
            if(Array.isArray(r.projects_id)) {
                r.projects_id.forEach(pid => {
                    projectIds[pid] = true
                })
            } else {
                projectIds[r.projects_id] = true
            }
        });

        rows.forEach(r => customerCompanyPairs[`${r.customers_id}-${r.companies_id}`] = true);

        const pccData: {
            projects: DataMap,
            customers: DataMap,
        } = await DataHandler.post({
            url: "new_reports/forecast/pcc_data"
        }, {
            project_ids: Object.keys(projectIds),
            customer_company_pairs: Object.keys(customerCompanyPairs).map((k: string) => {
                const [customerId, companyId] = k.split("-");

                return {
                    customers_id: customerId,
                    companies_id: companyId
                };
            })
        });

        return rows.map((r: ForecastReportBasicRow) => {
            const ccKey = `${r.customers_id}-${r.companies_id}`;

            if(Array.isArray(r.projects_id)) {
                r.projects_id.forEach(el => {
                    Object.keys(pccData?.projects[el] ?? []).forEach((k: string) => {
                        if(pccData.projects[el][k]) {
                            r[k] = pccData.projects[el][k];
                        }
                    });
                });
            } else {
                const pid = r.projects_id;
                Object.keys(pccData?.projects[pid] ?? [])
                    .forEach((k: string) => r[k] = pccData.projects[pid][k]);
            }

            Object.keys(pccData?.customers[ccKey] ?? [])
                .forEach((k: string) => r[k] = pccData.customers[ccKey][k]);

            return r;
        });
    }

    public async getActivitiesReports(params: Record<string, string> = {}): Promise<any> {
        const response = await DataHandler.get({ url: "new_reports/activities", ...params });
        let rows     = response.reports.rows;

        rows = await this.addPCCDataToActivitiesReports(rows);
        rows = this.handleActivitiesLinksAndDates(rows);

        return rows;
    }

    private handleActivitiesLinksAndDates(rows): any[] {
        rows = this.createProjectLinks(rows);
        rows = this.createAccountLinks(rows);

        return rows;
    }

    public async getSalesReports(params: Record<string, string> = {}): Promise<any> {
        const response = await DataHandler.get({ url: "new_reports/sales", ...params });
        let rows     = response.reports.rows;

        rows = await this.addPCCDataToSalesReports(rows);
        rows = this.handleSalesLinksAndDates(rows);

        return rows;
    }

    private handleSalesLinksAndDates(rows): any[] {
        rows = this.createProjectLinks(rows);
        rows = this.createAccountLinks(rows);
        rows = this.handleSalesDates(rows);

        return rows;
    }

    private handleSalesDates(rows): any[] {
        return rows.map(r => {
            r = this.addDates(r, "project_closing_date", r['financial_year_start']);
            r = this.addDates(r, "project_status_changed", r['financial_year_start']);
            r = this.addDates(r, "project_stage_date", r['financial_year_start']);
            r = this.addDates(r, "project_pipeline_date", r['financial_year_start']);

            return r;
        });
    }

    private async addPCCDataToActivitiesReports(rows: ForecastReportBasicRow[]): Promise<ActivitiesReportBasicRow[]> {
        const customerCompanyPairs: { [key: string]: boolean }      = {};
        const projectIds: { [projectId: string | number ]: boolean } = {};

        rows.forEach(r => {
            if(Array.isArray(r.projects_id)) {
                r.projects_id.forEach(pid => {
                    projectIds[pid] = true
                })
            } else {
                projectIds[r.projects_id] = true
            }
        });

        rows.forEach(r => customerCompanyPairs[`${r.customers_id}-${r.companies_id}`] = true);

        const pccData: {
            projects: DataMap,
            customers: DataMap,
        } = await DataHandler.post({
            url: "new_reports/activities/pcc_data"
        }, {
            project_ids: Object.keys(projectIds),
            customer_company_pairs: Object.keys(customerCompanyPairs).map((k: string) => {
                const [customerId, companyId] = k.split("-");

                return {
                    customers_id: customerId,
                    companies_id: companyId
                };
            })
        });

        return rows.map((r: ActivitiesReportBasicRow) => {
            const ccKey = `${r.customers_id}-${r.companies_id}`;

            if(Array.isArray(r.projects_id)) {
                r.projects_id.forEach(el => {
                    Object.keys(pccData?.projects[el] ?? []).forEach((k: string) => {
                        if(pccData.projects[el][k]) {
                            r[k] = pccData.projects[el][k];
                        }
                    });
                });
            } else {
                const pid = r.projects_id;
                Object.keys(pccData?.projects[pid] ?? [])
                    .forEach((k: string) => r[k] = pccData.projects[pid][k]);
            }

            Object.keys(pccData?.customers[ccKey] ?? [])
                .forEach((k: string) => r[k] = pccData.customers[ccKey][k]);

            return r;
        });
    }

    private async addPCCDataToSalesReports(rows: ForecastReportBasicRow[]): Promise<ActivitiesReportBasicRow[]> {
        const customerCompanyPairs: { [key: string]: boolean }      = {};
        const projectIds: { [projectId: string | number ]: boolean } = {};

        rows.forEach(r => {
            if(Array.isArray(r.projects_id)) {
                r.projects_id.forEach(pid => {
                    projectIds[pid] = true
                })
            } else {
                projectIds[r.projects_id] = true
            }
        });

        rows.forEach(r => customerCompanyPairs[`${r.customers_id}-${r.companies_id}`] = true);

        const pccData: {
            projects: DataMap,
            customers: DataMap,
        } = await DataHandler.post({
            url: "new_reports/sales/pcc_data"
        }, {
            project_ids: Object.keys(projectIds),
            customer_company_pairs: Object.keys(customerCompanyPairs).map((k: string) => {
                const [customerId, companyId] = k.split("-");

                return {
                    customers_id: customerId,
                    companies_id: companyId
                };
            })
        });

        return rows.map((r: ActivitiesReportBasicRow) => {
            const ccKey = `${r.customers_id}-${r.companies_id}`;

            if(Array.isArray(r.projects_id)) {
                r.projects_id.forEach(el => {
                    Object.keys(pccData?.projects[el] ?? []).forEach((k: string) => {
                        if(pccData.projects[el][k]) {
                            r[k] = pccData.projects[el][k];
                        }
                    });
                });
            } else {
                const pid = r.projects_id;
                Object.keys(pccData?.projects[pid] ?? [])
                    .forEach((k: string) => r[k] = pccData.projects[pid][k]);
            }

            Object.keys(pccData?.customers[ccKey] ?? [])
                .forEach((k: string) => r[k] = pccData.customers[ccKey][k]);

            return r;
        });
    }

    private addDates(r: any, field: string, financialYearStart: number | undefined = undefined) {
        // TODO: Move to DateUtils.
        const getFinancialYear = (t, fy_start = 1) => {
            let year    = t.getFullYear();
            const month = t.getMonth() + 1;

            if(month < fy_start) {
                year--;
            }

            const start = new Date(year, fy_start - 1, 1, 0, 0, 0, 0);
            const end   = new Date(start.getFullYear() + 1, start.getMonth(), start.getDate() - 1, 23, 59, 59, 0);

            return {
                start_month: fy_start,
                year: year,
                start: start,
                end: end
            };
        }
        
        // TODO: Move to DateUtils.
        const isValidDateString: (d: string | undefined) => boolean = (d: string | undefined): boolean => {
            return d !== undefined
                && d !== "0000-00-00" 
                && d !== "1970-01-01";
        };

        if(!isValidDateString(r[field])) {
            r[field + '_year'] = null;
            r[field + '_month'] = null;
            r[field + '_quarter'] = null;
            r[field] = null;

            if(financialYearStart != undefined) {
                r[field + '_fy'] = null;
            }

            return r;
        }

        const date = new Date(r[field]);

        r[field + '_year'] = date.getFullYear();

        const month = date.getMonth() + 1;

        r[field + '_month'] = String(month).padStart(2, "0") + "/" + r[field + '_year'];
        r[field + '_quarter'] = "Q" + Math.ceil(month / 3) + "/" + r[field + '_year'];

        if(financialYearStart != undefined) {
            r[field + '_fy'] = getFinancialYear(date, financialYearStart)['year'];
        }

        return r;
    }

    private createProjectLinks(rows): any[] {
        return rows.map(r => {
            r.project = {
                name: r.project_name,
                id: r.project_id,
                hidden: !r.project_id
            };

            r.project_id = {
                name: r.project_project_id,
                id: r.project_id,
                hidden: !r.project_id
            };

            r.project_parent = {
                name: r.project_parent_name,
                id: r.project_parent_id,
                hidden: !r.project_parent_id
            };

            r.project_manager = {
                name: r.project_manager_name,
                id: r.project_manager_id,
                companyid: r.project_manager_company,
                hidden: !r.project_manager_id
            };

            r.seller = {
                name: r.project_seller_name,
                id: r.project_seller_id,
                companyid: r.project_seller_company,
                hidden: !r.project_seller_company
            };

            return r;
        });
    }

    private createAccountLinks(rows): any[] {
        return rows.map(r => {
            r.account = {
                name: r.customer_name,
                id: r.customer_id,
                hidden: !r.customer_id
            };

            r.account_num = {
                name: r.customer_id,
                id: r.customer_id,
                hidden: !r.customer_id
            };

            r.account_parent = {
                name: r.customer_parent_name,
                id: r.customer_parent_id,
                hidden: !r.customer_parent_id
            };

            r.customer_account_manager = {
                name: r.customer_account_manager_name,
                id: r.customer_account_manager_id,
                companyid: r.customer_account_manager_company,
                hidden: !r.customer_account_manager_id
            };

            return r;
        });
    }

    private createUserLinks(rows, field: string, includeSupervisor = true): any[] {
        return rows.map(r => {
            r[field] = {
                'name': r[field + '_name'],
                'id': r[field + '_id'],
                'companyid': r[field + '_company_id'],
                'hidden': !r[field + '_id']
            };

            r[field + '_id'] = {
                'name': r[field + '_id'],
                'id': r[field + '_id'],
                'companyid': r[field + '_company_id'],
                'hidden': !r[field + '_id']
            };

            delete r[field + '_name']; 
            delete r[field + '_company_id'];

            r[field + '_superior'] = includeSupervisor && r[field + '_superior_id'] > 0 ? {
                'name': r[field + '_superior_name'],
                'id': r[field + '_superior_id'],
                'companyid': r[field + '_superior_company_id'],
                'hidden': !r[field + '_superior_id']
            } : null;

            delete r[field + '_superior_id'];
            delete r[field + '_superior_name'];
            delete r[field + '_superior_company_id'];
            
            return r;
        });
    }
}

export default ReportService;
