import React from 'react';
import List from "../List";
import PropsOnlyListRow from "../PropsOnlyListRow";
import CheckboxCell from "../cells/CheckboxCell";
import LinkListCell from "../LinkListCell";
import TreeStructureIndicatorCell from "../cells/TreeStructureIndicatorCell";
import TextInputCell from "../cells/TextInputCell";

class SimpleTreeRow extends PropsOnlyListRow {
	static rowDimensions = {
		height: "44px",
		lineHeight: "44px"
	};


    constructor(props) {
        super(props, {}, { childRowType: SimpleTreeRow });

        this.defineCells     = this.defineCells.bind(this);
		this.defineClassName = this.defineClassName.bind(this);
    }

    onCheck = () => {
        this.props.listRef.check(this.props.data.id);
        this.props.rowProps.onCheck();
    }


    defineCells() {
        const hasTitle2 = this.props.data.title2 !== undefined;
        let textInputStyle = {
            height: SimpleTreeRow.rowDimensions.height,
            lineHeight: SimpleTreeRow.rowDimensions.lineHeight
        }
        if(hasTitle2) {
             textInputStyle.height = (Number(textInputStyle.height.slice(0, -2)) / 2) + "px";
             textInputStyle.lineHeight = (Number(textInputStyle.lineHeight.slice(0, -2)) / 2) + "px";
        }
        return {
            checkbox: 
                <CheckboxCell checked={this.props.checked} onClick={this.onCheck} />,
            title:
                <TreeStructureIndicatorCell
					name="title"
                    childrenVisible={this.props.childrenVisible}
                    listCellProps={{ alignCell: true, textAlign: "left" }}
                    onExpanderClick={e => this.props.listRef.toggleShowChildren(this.props.data.id)}
                    rowData={this.props}
                    value={this.props.data.title}>
                        <>
                            <TextInputCell 
                                listCellProps={{
        							noTab: true,
                                    editable: false, 
                                    style: textInputStyle,
                                    width: this.props.columnWidthMap['title'] - ((this.props.children.length > 0 ? 1 : 0) + this.props.recursionLevel) * 25,
                                    title: this.props.data.title,
                                    fontWeight: hasTitle2 ? "bold" : "normal"
                                }}
                                useClickAwayListener={false}
                                onEdited={(name, value) => this.setDataAndUpdate(name, value)}
                                value={this.props.data.title} />
                            {
                                hasTitle2 && (
                                <TextInputCell 
                                    listCellProps={{
                                        noTab: true,
                                        editable: false, 
                                        style: textInputStyle, 
                                        width: this.props.columnWidthMap['title'] - ((this.props.children.length > 0 ? 1 : 0) + this.props.recursionLevel) * 25,
                                        title: this.props.data.title2
                                    }}
                                    useClickAwayListener={false}
                                    onEdited={(name, value) => this.setDataAndUpdate(name, value)}
                                    value={this.props.data.title2} />
                                )
                            }
                        </>
                </TreeStructureIndicatorCell>,
            input:
                <TextInputCell 
                    listCellProps={{
                        noTab: true,
                        editable: true,
                        permanentEditMode: true,
                        className: 'input-cell',
                        style: { height: SimpleTreeRow.rowDimensions.height }, 
                        width: this.props.columnWidthMap['input']
                    }}
                    name="input"
                    className={'input-cell'}
                    useClickAwayListener={false}
                    onEdited={(name, value) => this.props.rowProps.onInputChange(value, this.props.data)}
                    value={this.props.data.input} />

        }; 
    }


    defineClassName() {
        return "simpleTreeListRow"; 
    }
}

class SimpleTreeList extends React.Component {
    static defaultProps = {
        data: [],
        hideHeader: false,
        parentKey: "parentId",
        titleHeaderText: "placeholder",
        onCheck: () => {}
    };


    constructor(props) {
        super(props); 

        this.list = React.createRef();

        this.getCheckedRows = this.getCheckedRows.bind(this); 
    }


    getCheckedRows() {
        return this.list.current.getCheckedRows(); 
    }


    render() {
        const commonProps = {
            showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false
        };

        const rowProps = {
            onCheck: this.props.onCheck,
            onInputChange: this.props.onInputChange
        };

        let columns = [
            { name: "checkbox", header: "", columnHeaderType: "checkbox", width: 50, ...commonProps },
            { name: "title", header: this.props.titleHeaderText, width: 280, ...commonProps  }
        ];
        if(this.props.additionalColumns) {
            this.props.additionalColumns.forEach(el => columns.push({...el, ...commonProps}));
        }
        return (
            <List
                ref={this.list}
                data={this.props.data}
                parentKey={this.props.parentKey}
                noStateData={true}
                noColorVariance={true}
                listRowType={SimpleTreeRow}
                rowHeight={parseInt(SimpleTreeRow.rowDimensions.height)}
                hideHeader={this.props.hideHeader}
                treeData={true}
                rowProps={rowProps}
                parentsExpandedOnInit={true}
                height={this.props.height}
                width={this.props.height}
                columns={columns}
            />  
        ); 
    }

}

export default SimpleTreeList;
