import { Component } from 'react';

interface Props {
    value: unknown;
    valueFormatted: string;
    style: {[cssProperty: string]: string | number};
    colDef: {grandTotal: string, isCustomField?: boolean, defaultAggFunc?: string};
    overrideGrandTotal?: string;
    column: {aggFunc?: string, aggregationActive?: boolean};
}

export default class CustomPinnedRowRenderer extends Component<Props> {
  render() {
    const { colDef: { grandTotal, isCustomField, defaultAggFunc }, column: { aggFunc, aggregationActive }, overrideGrandTotal, value, valueFormatted } = this.props;

    let displayValue = "";

    if (overrideGrandTotal) {
      displayValue = overrideGrandTotal;
    }
    else if (grandTotal === 'count') {
      displayValue = `${value} values`;
    }
    else if (grandTotal)
    {
      displayValue = valueFormatted;
    }
    
    if (isCustomField && value)
    {
      displayValue = String(value);
    }

    const agg = aggFunc == "sumAndGroup"
      ? defaultAggFunc
      : aggFunc;
    
    if (aggregationActive && agg != grandTotal) {
      displayValue = "";
    }

    return <span style={this.props.style}>{displayValue}</span>;
  }
}