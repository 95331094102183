import React from "react";

import { SettingsContext } from "../../SettingsContext";
import TaimerComponent from "../../TaimerComponent";

import cn from 'classnames';

import styles from './BlockBase.module.scss';
import { BlockProps, ViewBlock } from "../base/CustomViewBase";

/* material ui */
import Button from "@mui/material/Button";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { Collapse } from "@mui/material";


export interface BlockBaseProps extends BlockProps {
    title: string;
    subtitle?: string;
    subtitleOnlyWhenCollapsed?: boolean;
    keepMounted?: boolean;
    contentClassName?: string;
    viewBlock: ViewBlock;
    topbarClassName?: string;
    topbarComponent?: React.ReactNode;
}

interface State {
}

export default class BlockBase extends TaimerComponent<BlockBaseProps, State> {
    static contextType = SettingsContext;

    refContent: React.RefObject<HTMLDivElement>;

    constructor(props, context) {
        super(props, context, "customview/block/BlockBase");

        this.refContent = React.createRef();

        this.state = {
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
    }

    componentDidUpdate() {
        const { } = this.props;
    }

    onClickCollapse = () => {
        const { viewBlock, canCollapse, collapsed, onCollapse } = this.props;

        if (!canCollapse)
            return;

        onCollapse(viewBlock, !collapsed);
    }

    render() {
        const { title, subtitle, subtitleOnlyWhenCollapsed, children, collapsed, canCollapse, topbarClassName, topbarComponent, contentClassName } = this.props;
        const { } = this.state;

        return (
            <div className={cn(styles.root, collapsed && styles.collapsed, !collapsed && styles.open)}>
                <div className={cn(styles.handle, "block-drag-handle")} />
                <div className={cn(styles.title)}>
                    <div className={cn(styles.left, canCollapse && styles.collapeable)} onClick={this.onClickCollapse}>
                        <div>
                            <span>{title}</span>
                            {canCollapse && <span>
                                {collapsed ? <ExpandMore /> : <ExpandLess />}
                            </span>}
                        </div>
                        {subtitle && (collapsed || !subtitleOnlyWhenCollapsed) && <div className={styles.subtitle}>
                            <span>{subtitle}</span>
                        </div>}
                    </div>
                    <div className={cn(styles.right, topbarClassName)}>
                        {topbarComponent}
                    </div>
                </div>
                <Collapse className={cn(styles.content, collapsed && styles.hidden)} classes={{ wrapperInner: contentClassName }} in={!collapsed}>
                    {children}
                </Collapse>
            </div>
        );
    }
}  