import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import ExpenseList from '../../expenses/ExpenseList';
import BillsPoList from '../../bills/BillsPoList';
import VersionContentManager from '../../general/VersionContentManager';

class Costs extends TaimerComponent<any> {
    tabs;
    constructor(props, context) {
        super(props, context, 'navigation/pages/Costs');
        this.tabs = VersionContentManager.getViewTabs(this.namespace).map((tab) => ({ ...tab, label: this.tr(tab.label) }));
    }

    getTabInfoButtonProps = (selectedTab) => {
        switch (selectedTab) {
            // case 'expenses':
            //     return {
            //         title: this.tr('How Expenses works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8094289397010' : 'https://taimersupport.zendesk.com/hc/articles/8094289397010',
            //             flow: '8a134135-10cc-479a-ad46-646a7e0352db',
            //         },
            //     };
            // case 'travel-expenses':
            //     return {
            //         title: this.tr('How Travel Expenses works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8094289397010' : 'https://taimersupport.zendesk.com/hc/articles/8094289397010',
            //         },
            //     };
            // case 'bills':
            //     return {
            //         title: this.tr('How Bills works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8080780485010' : 'https://taimersupport.zendesk.com/hc/articles/8080780485010',
            //         },
            //     };
            // case 'purchase-orders':
            //     return {
            //         title: this.tr('How Purchase Orders works'),
            //         methods: {
            //             article:
            //                 this.context.userObject.language == 'fi' ? 'https://taimersupport.zendesk.com/hc/fi/articles/8080811208850' : 'https://taimersupport.zendesk.com/hc/articles/8080811208850',
            //         },
            //     };
            default:
                return undefined;
        }
    };

    render() {
        return (
            <WithTabs offsetTop={10} tabs={this.tabs} selectedTab={this.props.selectedTab} tabsAlwaysVisible getTabInfoButtonProps={this.getTabInfoButtonProps}>
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'expenses':
                        case 'travel-expenses':
                            //@ts-ignore
                            return <ExpenseList {...this.props} listType={selectedTab == 'travel-expenses' ? 'travelExpenses' : 'expenses'} />;
                        case 'bills':
                        case 'purchase-orders':
                            return <BillsPoList {...this.props} selectedTab={selectedTab == 'purchase-orders' ? 'po' : 'bills'} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default Costs;
