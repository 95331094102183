import React from 'react';
import SnackbarNotification from "../general/SnackbarNotification";
import styles from "./CreateSubUnitNotification.module.css";
import TaimerComponent from "../TaimerComponent";

import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Button from '@mui/material/Button';

class CreateSubUnitNotification extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'accounts/CreateSubUnitNotification');
    }

    render() {
        const {
            onCreateClick,
            onDismissClick,
            innerRef
        } = this.props;

        return (
            <SnackbarNotification variant="info" ref={innerRef}>
                <PriorityHighIcon style={{ marginRight: "12px" }} />
                <span className={styles.span}>{this.tr("Remember to create a sub-unit for this account")}</span>
                <Button 
                    variant={"contained"}
                    style={{ marginTop: "-3px", marginLeft: "16px" }} 
                    size={"medium"}
                    className={styles.blueButton}
                    onClick={onCreateClick}
                >{this.tr("Create the sub-unit")}</Button> 
                <Button 
                    variant={"contained"}
                    style={{ marginTop: "-3px", marginLeft: "16px" }} 
                    size={"medium"}
                    className={styles.redButton}
                    onClick={onDismissClick}
                >{this.tr("Dismiss")}</Button> 
            </SnackbarNotification>
        );
    }
}

export default React.forwardRef((props, ref) => <CreateSubUnitNotification innerRef={ref} {...props} />)
