import React from 'react';
import TaimerComponent from '../TaimerComponent';
import DataHandler from '../general/DataHandler';
import TabQuotes from '../projects/TabQuotes';

class QuotePrint extends TaimerComponent {
    constructor(props) {
        super(props, null, 'projects/QuotePrint');

        this.state = {
            project: undefined,
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.getData();
    };

    checkPrivilege = (group, right, company) => {
        if (!this.state.project) return false;
        const {
            functions: { checkPrivilege },
        } = this.context;
        const {
            project: { id, rights, companies_id },
        } = this.state;
        if (group === 'projects' && id > 0) return rights.indexOf(right) > -1;
        //global
        else return checkPrivilege(group, right, company || companies_id);
    };

    getData = async () => {
        const { project_id } = this.props;
        const project = await DataHandler.get({ url: `projects/${project_id}` });
        this.setState({ project });
    };

    render() {
        if (!this.state.project) return null;
        return <TabQuotes selectedQuoteId={this.props.quote_id} project={this.state.project} checkPrivilege={this.checkPrivilege} printMode />;
    }
}

export default QuotePrint;
