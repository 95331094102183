import React from "react";
import { Button, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import "./LoaderButton.css";

class LoaderButton extends React.Component {

    constructor(props) {
		super(props);
    }

    renderTooltipButton = () => {
        const { notAllowedTooltip } = this.props;

        return (
            <Tooltip title={notAllowedTooltip} placement="top" classes={{ tooltip: 'darkblue-tooltip' }} arrow>
                <div>
                    {this.renderButton()}
                </div>
            </Tooltip>
        );
    }

    renderButton = () => {
        const {text, className, progressClassName, loading, notAllowed, buttonProps, progressProps, setProggressHeight, progressPadding, icon, ...other} = this.props;

        const buttonStyle = {
            opacity: loading ? '.7' : (notAllowed ? '.3' : "1")
        };
        const progressStyle = {
            minWidth: text.length + 'ch',
            height: setProggressHeight ? "100%" : undefined,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: progressPadding ? progressPadding + "px" : 0,
            paddingBottom: progressPadding ? progressPadding + "px" : 0
        };

        return (
            <Button data-testid={this.props['data-testid']} className={`loader-button ${className}`} disabled={loading || notAllowed} style={buttonStyle} onClick={!loading && !notAllowed && this.props.onClick} onMouseUp={!loading && !notAllowed && this.props.onMouseUp} {...buttonProps} {...other} >
                {!loading && icon}
                <span>{!loading && text.toUpperCase()}</span>
                {loading && (
                    <div style={progressStyle}>
                        <CircularProgress color="inherit" disableShrink size={18} className={`TMRDialogProgress ${progressClassName}`} {...progressProps} />
                    </div>
                )}
            </Button>
        );
    }

    render() {
        const { notAllowedTooltip, notAllowed } = this.props;

        return (notAllowed && notAllowedTooltip)
            ? this.renderTooltipButton()
            : this.renderButton();
    }	
}

Button.defaultProps = {
    text: "",
    className: "",
    progressClassName: "",
    loading: false,
    notAllowed: false,
    buttonProps: {},
    progressProps: {},
    onMouseUp: () => {},
    onClick: () => {},
    notAllowedTooltip: ""
};

export default LoaderButton;