import React from "react";
import ListCell from "./../ListCell";
import CompositeListCell from "./../CompositeListCell";
import TextInput from "./../../general/TextInput";
import SettingRow from "../rows/SettingRow";
import Select from 'react-select';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import "./UserAvatarCell.css";
import TaimerAvatar from "../../general/TaimerAvatar";

class UserAvatarCell extends React.Component {
	static defaultProps = {
		editable: true,
        width: 50,
        heightFix: undefined,
		options: [],
		optionLabelKey: "name",
		openMenuOnEdit: true,
		onEdited: () => {},
		value: []
	};


	constructor(props) {
		super(props);

		this.compositeListCell = React.createRef();
	}


	openUserMenu() {
		this.setState({
			userMenuOpen: true
		});
	}


	render() {
		return (
			<ClickAwayListener onClickAway={() => this.compositeListCell.current.closeEdit()}>
				<CompositeListCell disableDefaultEditTrigger className="userAvatarCell" editable={this.props.editable} width={this.props.width} ref={this.compositeListCell}>
					<div class="clearfix" style={!this.props.useCustomStyles ? { paddingLeft: "28px", height: this.props.heightFix } : {}}>
						{(this.props.editable || this.props.showPlusWhenDisabled) && (
						<div className="plus" onClick={() => this.props.editable && this.compositeListCell.current.openEdit()} >
							+
						</div>
						)}
						{this.props.users.map(user => {
							return(
								<TaimerAvatar 
									id={user.id}
									name={user.name}
									color={user.color}
									className="avatar"
								/>
							)
						})}
					</div>
					<Select
						ref={this.autoComplete}
						isSearchable={this.props.searchable}
						autoFocus={true}
						isMulti
						value={this.props.value}
						options={this.props.options.map(el => {
							el['label'] = el.hasOwnProperty("label") ? el.label : el.hasOwnProperty(this.props.optionLabelKey) ? el[this.props.optionLabelKey] : "(no label)";
							return el;
						})}
						menuIsOpen={this.props.openMenuOnEdit}
						className="autoCompleteInCell"
						classNamePrefix="autoCompleteInCell"
						onBlur={() => this.compositeListCell.current.closeEdit()}
						onChange={value => {
							this.compositeListCell.current.closeEdit();
							this.props.onEdited(value);
						}} />
				</CompositeListCell>
			</ClickAwayListener>
		);
	}
}

export default UserAvatarCell;
