import React from 'react';
import SendIcon from '@mui/icons-material/Send';
import "./TextInputWithIcon.css";

class TextInputWithIcon extends React.Component {
    static defaultProps = {
        className: "",
        icon: SendIcon,
        onChange: () => {},
        onEnter: () => {},
        onClick: () => {},
        placeholder: "",
        value: ""
    };


    constructor(props) {
        super(props);

        this.handleKeyUp = this.handleKeyUp.bind(this); 
        this.input = React.createRef();
    }


    handleKeyUp(e) {
        if(e.key !== "Enter") 
            return;

        setTimeout(() => this.props.onEnter());
    }


    render() {
        const Icon = this.props.icon;

        return (
            <div className={`textInputWithIcon ${this.props.className}`}>
                <input type="text" ref={this.input} value={this.props.value} placeholder={this.props.placeholder} onKeyUp={this.handleKeyUp} onChange={e => this.props.onChange(e.target.value)} /> 
                <div className="iconContainer" onClick={this.props.onClick}>
                    <Icon  />
                </div>
            </div>
        );
    }
}

export default TextInputWithIcon;
