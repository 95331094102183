import { createTheme } from '@mui/material/styles';
import colors from './colors';
 
const disabledStyle = {
    backgroundColor: `${colors.white_two} !important`,
    borderRadius: "4px",
    color: `${colors.title} !important`,
    cursor: 'not-allowed',
    "&.keepWhiteWhenDisabled": {
        backgroundColor: `${colors.white} !important`,

        "&>input": {
            backgroundColor: `${colors.white} !important`,
            color: `${colors.dark_grey_blue}`,
            borderRadius: "16px",
            userSelect: "none"
        }
    }
}


const fontSize = 14;

export const TaimerTheme = createTheme({
    palette: {
        taimer: colors,
        primary: {
            main: colors.primary
        },
        secondary: {
            main: colors.warm_pink,
        },
        green: {
            main: colors.greenish_cyan,
        },
        action: {
            main: colors.greenish_cyan,
        },
        info: {
            main: "#ddd"
        },
        cancel: {
            main: "#ddd"
        },
        white: {
            main: "#fff"
        },
        textPrimary: {
            main: colors.dark,
        },
    },
    disabled: disabledStyle,
    typography: {
        useNextVariants: true,
        fontFamily: "'Open Sans', sans-serif",
        fontSize,
    },
    components: {
        disabled: disabledStyle,
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize,
                    color: colors.title,
                    paddingTop: 11,
                    paddingBottom: 11,
                    height: 24,
                    boxSizing: 'content-box',
                    overflow: 'hidden',  
                    "&>.listitem-subtext": {
                        fontSize: 10,
                        clear: 'both',
                        position: 'absolute',
                        top: 32,
                        "&.light": {
                            color: "gray",
                        }
                    }, 
                    "&.listitem-error": {
                        color: colors.error
                    },    
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: 12,
                    backgroundColor: colors.steel,
                    color: colors.white,
                    "&.square": {
                        borderRadius: 4,
                    },
                    "& svg.warning": {
                        fill: colors.egg_shell,
                    }
                },
                deleteIcon: {
                    fill: colors.white,
                    fontSize: 20,
                },
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                input: {
                    paddingTop: '20px',
                    paddingBottom: '5px',
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize,
                    "&:before": {
                        borderBottom: "0px !important"
                    },
                    "&:after": {
                        borderBottom: "0px !important"
                    },
                    backgroundColor: colors.white + " !important", // ¯\_(ツ)_/¯
                    border: `1px solid ${colors.border}`,
                    borderRadius: "4px",
                    color: colors.title,
                    '&.Mui-disabled': disabledStyle,
                    '&.Mui-error': {
                        border: `1px solid ${colors.error} !important`,
                    },
                    '&.MuiInputBase-multiline': {
                        paddingTop: 22
                    }
                },
                error: {
                    border: `1px solid ${colors.error}` ,
                },
                input: {
                    fontSize: 12,
                    '&.Mui-disabled': disabledStyle,
                    height: 'auto',
                    '&.MuiInputBase-inputMultiline': {
                        paddingTop: '0px !important',
                        paddingBottom: '0px !important',
                        lineHeight: 1.4
                    },
                },
                adornedStart: {
                    boxSizing: 'border-box', /* theres a bug with sizing when adornment is used */
                    "& p": {
                        fontSize: 12,
                        paddingTop: 18,
                    },
                    "& input": {
                        marginLeft: -4,
                    },
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    transform: 'translate(12px, 14px) scale(1)',
                },
                shrink: {
                    transform: 'translate(12px, 7px) scale(0.75)'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 12,
                    color: colors.subtitle,
                    "&.select": {
                        color: `${colors.subtitle} !important`,
                    }
                },
                disabled: {
                    color: `${colors.subtitle} !important`,
                },
                focused: {
                    color: colors.subtitle
                },
                error: {
                    color: `${colors.error }!important`
                },
                filled: {
                    color: colors.subtitle
                },
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontSize,
                    borderRadius: 4,
                    backgroundColor: `${colors.white} !important`,
                    color: colors.title,
    
                    "&>svg": {
                        position: "absolute",
                        right: "6px",
                        top: "50%",
                        marginTop: "-9px",
                        backgroundColor: "#f7f8fc",
                        color: "rgba(0, 0, 0, 0.54)",
                        width: "18px",
                        height: "18px",
                        borderRadius: "18px",
                    },
    
                    "& path": {
                        color: "#dcdee6"
                    }
                },
                icon: {
                    position: "absolute",
                    right: "6px",
                    top: "50%",
                    marginTop: "-9px",
                    backgroundColor: "#f7f8fc",
                    width: "18px",
                    height: "18px",
                    borderRadius: "18px",
                },
                filled: {
                    backgroundColor: colors.white,
                    color: colors.dark_grey_blue,
                    borderRadius: 4,
                    "&>div": {
                        borderRadius: 4,
                        backgroundColor: colors.white,
                    },
                    "&:active": {
                        borderRadius: 4,
                        backgroundColor: colors.white
                    },
                
                    ">svg": {
                        position: "absolute",
                        right: "6px",
                        top: "50%",
                        marginTop: "-9px",
                        backgroundColor: "#f7f8fc",
                        width: "18px",
                        height: "18px",
                        borderRadius: "18px",
                    },
                },
                disabled: disabledStyle,
                selectMenu: {
                    // border radius here doesn't have the desired effect, so the background of this element is transparent,
                    // since it clips off the "inward rounded" corners of the contained element if it has a background color other than transp.
                    fontSize: 12,
                    borderRadius: 4,
                    backgroundColor: "transparent !important",
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    "&>div": {
                        borderRadius: 10,
                    },
                    "&.red div": {
                        backgroundImage: "linear-gradient(91deg, " + colors.warm_pink + ", #fa337b)",
                    },
                    "&.green div": {
                        backgroundImage: "linear-gradient(to left, #1de9b6, #1dc4e9)",
                    }
                },
                colorPrimary: {
                    backgroundColor:"#eaeaea",
                }
            }
        },
        //* Kaataa settingsin
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: 11,
                    paddingBottom: 11,
                    "& .listitem-subtext": {
                        fontSize: 10,
                        clear: 'both',
                        position: 'absolute',
                        top: 25,
                    },
                    "&.listitem-error": {
                        color: colors.error
                    },  
                    "&$selected": {
                        backgroundColor: "rgba(44, 159, 247, 0.08)",
                        "&:hover, &:focus": {
                            backgroundColor: "rgba(44, 159, 247, 0.08)",  
                        },
                      },
                },
                selected: {
                    backgroundColor: 'rgba(44, 159, 247, 0.08)',
                    '&:hover, &:focus': {
                        backgroundColor: 'rgba(44, 159, 247, 0.08)',
                    }
                },
                button: {
                    '&:hover': {
                        backgroundColor: 'rgba(44, 159, 247, 0.08) !important',
                    },
                    '&:focus': {
                        backgroundColor: 'transparent',
                    }
                },
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 0,
                    marginRight: 16
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    padding: '0 16px',
                    marginTop: 0,
                    marginBottom: 0,
                    '&:first-child': {
                        paddingLeft: 0
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '0.75rem'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem'
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    color: colors.steel,
                    "&.simple button[aria-selected=true]": {
                        backgroundColor: colors.primary,
                        borderRadius: 25,
                        color: "white",
                    },
                    "&.simple div[role=tablist]>span": {
                        display:"none"
                    },
                    "&.simple button": {
                        minWidth: "initial",
                        fontSize: "12px !important", /* default theme mediaqueries f*ck things up */
                        minHeight: 32,
                    },
                    "&.simple": {
                        minHeight: 32,
                    },
                    "& button": {
                        minWidth: "initial",
                        fontSize: "12px", /* default theme mediaqueries f*ck things up */
                    },
                    "& button span": {
                        span: "6px 14px",
                    },
    
                    "&.details div[role=tablist]>span": {
                        display:"none"
                    },
                    "&.details button": {
                        flex: 1,
                        borderBottom: '1px solid ' + colors.light_sky_blue,
                        backgroundColor: '#ededed',
                        color: '#9b9b9b',
                        borderRight: '1px solid ' + colors.light_sky_blue,
                    },
                    "&.details button:last-of-type": {
                        borderRight: 0,
                    },
                    "&.details button[aria-selected=true]": {
                        backgroundColor: 'white',
                        borderBottom: 0,
                        color: colors.dark_grey_blue,
                    },
                    "&.details button:last-child": {
                        borderTopRightRadius: 5,
                    },
                    "&.details button:first-child": {
                        borderTopLeftRadius: 5,
                    },
                },
                indicator: {
                    display: 'none',
                    "&.with-indicator": {
                        display: 'block',
                    },
                    "&.rounded-indicator": {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                    },
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: "12px !important", /* default theme mediaqueries f*ck things up */
                    fontWeight: "600",
                    textTransform: "none",
                    opacity:"1 !important",
                },
                selected: {
                    color: colors.primary,
                },
                disabled: {
                    opacity:"0.4 !important",
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: "#7c86a2",
                    verticalAlign: "middle",
                },
                colorDisabled: {
                    color: "#a2abc5",
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: colors.white,
                    fontSize: 11,
                    fontFamily: "'Roboto', sans-serif",
                    fontWeight: 600,
                    textAlign: "center",
                },
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                colorPrimary: {
                    "&.Mui-checked": {
                        "& svg": {
                            color: colors.primary,
                        }
                    }
                },
            }
        },
        MuiRadio: {
            styleOverrides: {
                colorPrimary: {
                    "&.Mui-checked": {
                        "& svg": {
                            color: colors.primary,
                        }
                    }
                },
            }
        },
        MuiDialog: {
            styleOverrides: {
                paperWidthXs: {
                    overflow: "unset"
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    "&.invoiceDialogContent": {
                        overflow: "unset",
                        wordBreak: "break-word",
                    }
                }   
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                disabled: disabledStyle,
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    margin: "12px 20px",
                    justifyContent: "space-between",
                    "&:has(> :nth-child(-n+1):last-child)": { //center if only one button
                        justifyContent: "space-around",
                    },
                    "& button": {
                        textTransform: "uppercase"
                    }
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    zIndex: 100000, // larger than hubspot chat button's z-index
                }
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    zIndex: 100001, // larger than hubspot chat button's z-index
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: colors.white,
                    border: `solid 1px ${colors.border}`,
                    fontSize: '32px',
                    borderRadius: "50%",
                    "& text": {
                       fill: colors.border
                    }
                },
                active: {
                    border: "none",
                    "& text": {
                        fill: colors.white
                    }
                }
            }
        },
        MuiStepConnector: {
            styleOverrides: {
                lineHorizontal: {
                    borderRadius: '2px',
                    borderColor: colors.border,    
                    borderTopWidth: '4px',
                },
                active: {
                    "& span": {
                        borderColor: `${colors.primary} !important`,
                    }
                },
                completed: {
                    "& span": {
                        borderColor: `${colors.primary} !important`,
                    }
                },
                alternativeLabel: {
                    top: '14px',
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    color: `${colors.border} !important`, 
                },
                active: {
                    color: `${colors.primary} !important`
                },
                completed: {
                    color: `${colors.primary} !important`
                }
            }
        },
        MuiTooltip: {
            
            styleOverrides: {
                
                tooltip: {
                    //display: "none",
                    zIndex: 6000001,
                    wordBreak: "break-word",
                    "&.hide": {
                        display: "none"
                    },
                    "&.darkblue-tooltip": {
                        background: colors.dark,
                    },
                    "&.tooltip-big": {
                        fontSize: 13,
                    },
                },
                arrow: {
                    "&.darkblue-arrow": {
                        color: colors.dark,
                    },
                }
            }
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    zIndex: 60000001,
                    "& svg": {
                        fill: 'currentColor',
                        color: 'inherit',
                    }
                },
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    "&.fullWidth": {
                        width: "100%",
                        textAlign: "right",
                        marginBottom: "12px"
                    },
                    ":disabled": disabledStyle,
                    "&.date-range": {
                        float: "left",
                        margin: "0px",
                        marginRight: "8px",
                        height: "47px"
                    },
                    "&.disabled": disabledStyle,
                },
            },
            defaultProps: {
                margin: "dense",
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: 12,
                    textTransform: "none",
                    color: colors.dark,
                    //list actionmenu css override
                    '&.action-menu': {
                        padding: '0px',
                        minWidth: 'unset',
                        width: '100%',
                        borderRadius: '0px',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        }
                    },
                    '&.context-menu.dropdown': {
                        '&:hover': {
                            backgroundColor: 'transparent',
                        }
                    },
                    '&:hover': {
                        backgroundColor: "#d5d5d5",
                        boxShadow: 'none'
                    },
                    '&.list-menu': {
                        fontWeight: 'unset',
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: 'white',
                        height: '100%',
                        color: colors.light_sky_blue,
                        borderRadius: '2px',
                        borderColor: colors.light_sky_blue,
                        minWidth: '160px',
                        padding: '8px 12px',
                    }
                },
                sizeLarge: {
                    fontSize,
                    textTransform: "uppercase",
                    padding: '8px 24px'
                },
                outlinedPrimary: {
                    color: colors.primary,
                    '&:hover': {
                        backgroundColor: "rgba(45, 159, 247, 0.06)",
                    },
                },
                outlinedInfo: {
                    borderColor: '#eee'
                },
                containedPrimary: {
                    color: colors.white,
                    '&:hover': {
                        backgroundColor: "rgb(31, 111, 172)",
                    },
                },
                containedSecondary: {
                    color: colors.white,
                },
                disabled: {
                    opacity: 0.7
                },
                outlined: {
                    /*(invoice) list menu button css override*/
                    '&.list-menu': {
                        fontWeight: 'unset',
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: 'white',
                        height: '100%',
                        "color": colors.subtitle,
                        borderRadius: '2px',
                        borderColor: colors.border,
                        minWidth: '160px',
                        padding: '8px 12px',
                    },
                    "&.red": {
                        "color": "white",
                        "background": colors.warm_pink,
                        '&:hover': {
                            backgroundColor: colors.warm_pink,
                        },
                    },
                    "&.white": {
                        "color": colors.dark_grey_blue,
                        "background": colors.white,
                        '&:hover': {
                            backgroundColor: colors.white,
                        },
                    },
                    "&.green": {
                        "color": "white",
                        "background": colors.greenish_cyan,
                        '&:hover': {
                            backgroundColor: colors.greenish_cyan,
                        },
                        border: 0,
                    },
                    "&.green svg": {
                        fill: 'white',
                        background: colors.greenish_cyan,
                        '&:hover': {
                            backgroundColor: colors.greenish_cyan,
                        },
                    }
                },
                contained: {
                    "&.green": {
                        "color": "white",
                        "background": colors.greenish_cyan,
                        '&:hover': {
                            backgroundColor: colors.greenish_cyan,
                        },
                    },
                    "&.grey": {
                        "color": colors.dark,
                        "background": "#ddd",
                    },
                    "&.red": {
                        "color": "white",
                        "background": colors.warm_pink,
                        '&:hover': {
                            backgroundColor: colors.warm_pink,
                        },
                    },
                    "&.darkred": {
                        "color": "white",
                        "background": colors.scarlet,
                        '&:hover': {
                            backgroundColor: colors.scarlet,
                        },
                    },
                    "&.blue": {
                        "color": "white",
                        "background": colors.primary,
                        '&:hover': {
                            backgroundColor: "rgb(31, 111, 172)",
                        },
                    },
                    "&.white": {
                        "color": colors.dark_grey_blue,
                        "background": colors.white,
                        '&:hover': {
                            backgroundColor: colors.white,
                        },
                    },
                    boxShadow: "none",
                },
    
            },
            defaultProps: {
                variant: "contained",
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            }
        }
    }
});
