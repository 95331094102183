import React from "react";

/* others */
import _ from "lodash";
import TaimerComponent from "../../TaimerComponent";
import { Line } from "react-chartjs-2";
import customTooltips from '../../general/ChartTooltip';

export const colors = ["#2d9ff7", "#f5a623", "#f97fab"];
export const colorsHover = ["#d5e9fa", "#fce8c7", "#fed5e4"];

class CumulativeChart extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "dashboard/insights/CumulativeChart");

    this.state = {};

    this.refChart = React.createRef();
  }

  render() {
    const {
      labels,
      datasets,
      currency,
      hiddenIndexes = [],
      toggleHidden
    } = this.props;
    const { tr } = this;

    const { taimerAccount } = this.context;

    const currencyFormatter = new Intl.NumberFormat(
      taimerAccount.numberFormat,
      {
        style: "currency",
        currency: currency
      }
    ).format;

    const charts = {
      labels,
      datasets
    };

    return (
      <Line
        ref={this.refChart}
        data={charts}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            tooltip: {
              enabled: false,
              external: customTooltips,
              callbacks: {
                label: function(tooltipItem) {
                  const dataset = tooltipItem.dataset;
                  var label =
                    " " + _.escape(dataset.label) || "";
  
                  if (label) {
                    label += ": ";
                  }
                  label += currencyFormatter(tooltipItem.raw);
                  return label;
                }
              },
              mode: "x",
              titleFontSize: 12,
              titleFontStyle: "bold",
              titleMarginBottom: 15,
              bodyFontSize: 11,
              bodyFontColor: "rgba(255,255,255,0.7)",
              bodySpacing: 8,
              caretSize: 0,
              xPadding: 20,
              yPadding: 20,
              intersect: false
            },
            legend: {
              display: true,
              position: "bottom",
              onClick: (e, legendItem) => {
                const { datasetIndex } = legendItem;
                toggleHidden(datasetIndex);
              },
              labels: {
                boxWidth: 15,
                padding: 20,
                fontSize: 12,
                usePointStyle: true,
                generateLabels: () => {
                  return datasets.map((obj, i) => {
                    return {
                      text: obj.label,
                      pointStyle: "rectRounded",
                      fillStyle: obj.borderColor,
                      datasetIndex: i,
                      lineCap: "round",
                      lineJoin: "round",
                      strokeStyle: "#FFF",
                      lineWidth: 5,
                      hidden: hiddenIndexes.indexOf(i) != -1
                    };
                  });
                }
              }
            },
          },
          elements: {
            line: {
              fill: false
            }
          },
          onClick: evt => {
            const activePoint = this.refChart.current.chartInstance.getElementAtEvent(
              evt
            )[0];

            if (!activePoint) return;

            const data = activePoint._chart.data;
            const datasetIndex = activePoint._datasetIndex;

            data.datasets[datasetIndex] &&
              this.props.onClick(
                data.datasets[datasetIndex],
                activePoint._index
              );
          },
          scales: {
            y: {
                grid: {
                  color: "rgba(0,0,0,0.04)",
                  zeroLineColor: "rgba(0,0,0,0.04)",
                  drawBorder: false
                },
                type: "linear",
                position: "left",
                drawBorder: false,
                beginAtZero: true,
                ticks: {
                  callback: function(value, index, values) {
                    return currencyFormatter(value);
                  },
                  font: {
                    size: 12,
                    weight: 'bold'
                  },
                  padding: 15
                }
              },
            x: {
                offset: true,
                ticks: {
                  padding: 10,
                  font: {
                    size: 12,
                  },
                },
                stacked: labels.length > 1,
                grid: { display: false, drawBorder: false }
              }
          }
        }}
      />
    );
  }
}

export default CumulativeChart;
