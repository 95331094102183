import React, { Component } from 'react';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* local components */
import TaimerComponent from '../../TaimerComponent';
/* material ui */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import _ from 'lodash';

import './ResultsScreen.css';

class ResultsScreen extends TaimerComponent {
    static contextType = SettingsContext;
    constructor(props, context) {
        super(props, context, "dialogs/imports/ResultsScreen")
    }
    render() {
        const {tr} = this;
        const { results, headers } = this.props;
        return (
            <div className="result-container">
                <div className="result-headers" id="results">
                    {_.map(results, (result, i) => {
                        if (i === "errors") {
                            return (
                                <div className="result">
                                    <h1 className={i}>{result.totals.totalErrors}</h1>
                                    <h3 className={"sub-header"}>{headers.totals[i]}</h3>
                                </div>
                            )
                        } else {
                            return (
                                <div className="result">
                                    <h1 className={i}>{result}</h1>
                                    <h3 className={"sub-header"}>{headers.totals[i]}</h3>
                                </div>
                            )
                        }
                    })}
                </div>
                {results.errors.totals && results.errors.totals.totalErrors > 0 ? <h3 className="instructions">{tr("Importing had following error(s):")}</h3> : undefined}
                {results.errors.totals.totalErrors > 0 ? 
                <div className="content-block">
                    <Table className="error-details">
                        <TableHead className="error-headers">
                            <TableRow>
                                <TableCell className="left">{tr("Error")}</TableCell>
                                <TableCell className="right">{tr("Row")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="error-container">
                            {_.map(results.errors, (category, c) => {
                                if (c !== "totals") {
                                    return (
                                        _.map(category, (type, i) => {
                                            if (type.length > 0) {
                                                return (
                                                    _.map(type, error => {
                                                        return (        
                                                            <TableRow className="error-message">
                                                                <TableCell className="left">{headers.errors[c][i]}</TableCell>
                                                                <TableCell className="right">{error}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                )
                                            }
                                        })
                                    )
                                }
                            })}
                        </TableBody>
                    </Table>
                </div>
                : undefined}
            </div>
        );
    }
}

export default ResultsScreen;