import React, { useState } from "react";
import { DateRange, Calendar } from "../../../general/react-date-range/src";

const MyDayDateRangeModal = (props) => {
  const { initialDateRange, saveChanges, mode, noMonthChange } = props;
  const [dateRange, setDateRange] = useState(initialDateRange);
  let pendingDate = false;

  const _dateSelected = (event) => {
    let { startDate, endDate } = event.selection;
    setDateRange(event.selection);
    if (
      startDate === endDate &&
      !pendingDate &&
      event.source !== "definedRange"
    ) {
      pendingDate = true;
    } else {
      pendingDate = false;
      saveChanges(event.selection);
    }
  };

  const calProps = {
    showSelectionPreview: true,
    showMonthAndYearPickers: !noMonthChange,
    showMonthArrow: !noMonthChange,
    showDateDisplay: true,
    editableDateInputs: true,
  };

  return mode == "date" ? (
    <Calendar
      {...calProps}
      onChange={(date) =>
        saveChanges({ startDate: date, endDate: date, key: "selection" })
      }
      shownDate={dateRange.endDate || new Date()}
      date={dateRange.endDate || new Date()}
    />
  ) : (
    <DateRange
      {...calProps}
      months={2}
      direction="horizontal"
      onChange={_dateSelected}
      moveRangeOnFirstSelection={false}
      ranges={[dateRange]}
      onRangeSelected={() => {}}
    />
  );
};

export default MyDayDateRangeModal;
