import React from 'react';
import { format } from "date-fns";

import { SettingsContext } from '../SettingsContext';
import ListRow from "../list/ListRow";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import ListCell from "../list/ListCell";
import CurrencyListCell from '../list/CurrencyListCell';
import ListCellWithSymbols from "./../list/cells/ListCellWithSymbols";
import DateCell from './../list/cells/DateCell';
import ReportsDefinitions from "./ReportsDefinitions";

const defineOptions = ReportsDefinitions.getDefinitions();


class ReportListRow extends PropsOnlyListRow {
	static contextType = SettingsContext;
	constructor(props, context) {
		super(props, {}, {}, "reports/ReportListRow");

	}

	defineClassName() {
		const {data} = this.props;
		return `reportListRow listElement row ${data.class != undefined ? data.class : ''} ${data.additional_class != undefined ? data.additional_class : ''}`;
	}

	defineCells() {

		const { data, columnOrder } = this.props;
		const { hour, percentage, total } = this.props.rowProps.symbols;
		const { showDescription, currentReport, currency } = this.props.rowProps;

		if (data.sold && data.sold === "total")
			data.sold = this.tr("total");

        const cells   = {};
        const cellArr = columnOrder.map((columnName, i, arr) => {

			const params = Object.values(defineOptions.fields).filter(col=>col.name == columnName)[0];
			// const width = this.props.columnConfig[columnName].width;
			const type = params.fieldType;
			const value = data[columnName] ? data[columnName] : '';
			const isTotalTextCell = params.canBeTotal && params.canBeTotal.includes(currentReport) && data.totalTitle;
			let { id } = data;
			id = id.toString();

            const cellProps = {
            	key: `${id}-${columnName}`, 
            	name: columnName, 
            	value: isTotalTextCell ? `${this.tr(data.totalTitle)}${data.totalValue ? ` ${data.totalValue}` : ''}:` : value, 
            	editable: false, 
            	listCellProps: {}
            };

            if (data.summary && arr.length - 1 == i)
				return (
					<div className="summary-cell" >
						{Object.entries(data.summary).map(([name, value]) => 
							<div className="summary-item">{this.tr(name)}: <div style={{minWidth: "60px"}}>{value.toFixed(2)}</div> </div>)}
					</div>);            	

			switch (type) {
				case 'text':
					return value == 'totalstextcell' ?
					<ListCell {...cellProps} value={`${this.props.rowProps.sumText}:`} textAlign="right" className={isTotalTextCell ? 'totals' : ''} />
					:
					<ListCell {...cellProps} textAlign={data.align || "left"} className={isTotalTextCell ? 'subtotals' : ''}/>;
				break;
				case 'translatedText':
					return <ListCell {...cellProps} value={this.tr(cellProps.value)} textAlign={data.align || "left"} />;
				break;				
				case 'buffer':
					return <ListCell moveable={false} {...cellProps}/>;
				break;
				case 'hours':
					return <ListCellWithSymbols
						{...cellProps} 
						className="hours" 
						textAlign="right"
						textColor={data.totalTitle && hour[data.totalTitle]?.color}
						dataPrefix={ id.indexOf("_") > -1 ?
							data.totalTitle && hour[data.totalTitle].prefix : undefined	}
						dataSuffix={value && hour.suffix} 
						value={(!isNaN(Number(cellProps.value)) && cellProps.value !== '' ? Number(cellProps.value).toFixed(2) : cellProps.value)}
						/>;
				break;
				case 'zerohours':
					return <ListCellWithSymbols
					 	{...cellProps}
						className="hours" 
						textAlign="right"
						textColor={data.totalTitle && hour[data.totalTitle]?.color}
						dataPrefix={ id.indexOf("_") > -1 && isTotalTextCell ? hour[data.totalTitle].prefix : undefined	}
						dataSuffix={hour.suffix} 
						value={cellProps.value ? (!isNaN(Number(cellProps.value)) && cellProps.value !== '' ? Number(cellProps.value).toFixed(2) : cellProps.value) : '0.00'}/>;
				break;				
				case 'hours_b':
					return <ListCellWithSymbols
						{...cellProps} 
						className="hours" 
						textAlign="right"
						dataPrefix={id.indexOf("_t") > -1 ?
							data.hourbalancetotalTitle &&( !data.users ? (data.users = `${hour[data.hourbalancetotalTitle]?.prefix}`) : "") : undefined	}
						dataSuffix={value && hour.suffix} 
						value={(!isNaN(Number(cellProps.value)) && cellProps.value !== '' ? Number(cellProps.value).toFixed(2) : cellProps.value)} />;
				break;
				case 'percentage':
					return <ListCellWithSymbols
						{...cellProps} 
						className="percentage" 
						textAlign="right"
						textColor={data.totalTitle && percentage[data.totalTitle]?.color}
						dataPrefix={ id.indexOf("_") > -1 && isTotalTextCell ? percentage[data.totalTitle].prefix : undefined }
						dataSuffix={value !== '' ? percentage.suffix : undefined}
						value={value == 'nan' ? '-' : value} />;
				break;				
				case 'currency':
					return value != 0 ? <CurrencyListCell
						{...cellProps}
						currency={currency}
	                    textAlign="right"
	                    value={(!isNaN(Number(cellProps.value)) && cellProps.value !== '' ? Number(cellProps.value).toFixed(2) : cellProps.value)}
	                /> : 
	                <ListCell {...cellProps} value='' />;
				break;
				case 'zeroAcceptingCurrency':
					return <CurrencyListCell
						{...cellProps}
						currency={currency}
	                    textAlign="right"
	                    value={(!isNaN(Number(cellProps.value)) && cellProps.value !== '' ? Number(cellProps.value).toFixed(2) : cellProps.value)}
	                />;
				break;
				case 'date':	
					return <DateCell 
						editable={false}
						// width={width}
						value={data.date}
						{...cellProps}	
						/>
				break;
				case 'period':
					let dates = value.split(" - ");
					const period = dates.reduce((acc, curr, i) => acc = `${format(acc, this.context.userObject.dateFormat)}${i > 0 ? ' - ' : ''}${format(curr, this.context.userObject.dateFormat)}`);
					return <ListCell 
						{...cellProps} 
						value={isTotalTextCell ? cellProps.value : period} 
						/>
				break;
				case 'time':
					return <ListCell {...cellProps}/>;
				break;									
				default:
					return <ListCell {...cellProps}/>;
				break;
			}

        });

        cellArr.forEach((c, i) => {
            cells[this.props.columnOrder[i]] = c;
        });

        return cells;
	}
}

ReportListRow.defaultProps = {
	children: []
};

export default ReportListRow;
