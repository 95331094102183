import React from "react";
import List from "../../../list/List";
import TaimerComponent from "../../../TaimerComponent";

import "./MyDayList.css";

export default class MyDayList extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/lists/MyDayList");
    this.myDayList = React.createRef();
  }

  _renderEmptyOverlay = () => {
    const {
      showEmptyOverlay,
      emptyOverlayData: { image, content, top },
      offsetTop,
    } = this.props;
    if (!showEmptyOverlay) return null;
    return (
      <div style={{ top: top || 0 }} className="placeholder-container">
        <div className="center">
          <img src={image} />
          {content}
        </div>
      </div>
    );
  };

  setPage = (page) => {
    this.myDayList.current && this.myDayList.current.setPage(page);
  };

  render() {
    const { height, offsetTop, trimHeight, showEmptyOverlay, ...restProps } = this.props;
    return (
      <div id="my-day-list" className="no-my-day-drag content">
        <div style={{ visibility: !showEmptyOverlay ? 'visible' : 'hidden' }}>
          <List
            ref={this.myDayList}
            style={{ marginTop: offsetTop || 0 }}
            noStateData
            trimHeight={trimHeight || 0}
            height={height || (this.props.showPageSelector ? "447" : "502")}
            rowHeight={60}
            useHSRightPadding
            {...restProps}
          />
        </div>

        {this._renderEmptyOverlay()}
      </div>
    );
  }
}
