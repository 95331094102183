// Material UI
import { WarningRounded } from '@mui/icons-material';

// Local components
import TaimerComponent from '../TaimerComponent';

// Styles
import styles from './NotificationBar.module.scss';

export interface NotificationBarProps {
    title: string;
    buttonTitle: string;
    style: string;
    onButtonClick?: () => void;
}

class NotificationBar extends TaimerComponent<NotificationBarProps> {
    public static defaultProps = {
        style: "serious"
    };

    constructor(props, context) {
        super(props, context, 'general/NotificationBar');
    }

    render() {
        const { style, title, buttonTitle, onButtonClick } = this.props;

        return (
            <div data-testid="notification_bar_container" className={styles.notificationBar}>
                <span className={`${styles.notification} ${styles[style]}`}>
                    <WarningRounded />
                    <span>{title}</span>
                    <span className={styles.button} onClick={onButtonClick}>{buttonTitle}</span>
                </span>
            </div>
        );
    }

}

export default NotificationBar;