import React from "react";

/* css */
import "./DealsOverviewTable.css";

import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

/* others */
import _ from "lodash";
import TaimerComponent from "../../../../TaimerComponent";
import { SettingsContext } from "../../../../SettingsContext";

import cn from "classnames";
import { TableFooter } from "@mui/material";
import TaimerAvatar from "../../../../general/TaimerAvatar";

class DealsOverviewTable extends TaimerComponent {
  static contextType = SettingsContext;

  static defaultProps = {
    width: 3,
    visible: true
  };

  constructor(props, context) {
    super(
      props,
      context,
      "dashboard/insights/sales/components/DealsOverviewTable"
    );
  }

  onClickRow(row) {
    this.props.onClickRow(row);
  }

  _renderHeader = () => {
    const {
      className,
      style,
      datasets,
      setTitle,
      valueTitle,
      currency,
      displayFooter,
      displaySplit,
      displayByLabels
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell align="left" className="title">
            {this.tr("Sales Agent")}
          </TableCell>
          <TableCell align="right" className="title">
            {this.tr("Value")}
          </TableCell>
          <TableCell align="right" className="title">
            {this.tr("Margin")}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  _renderRows = () => {
    const {
      className,
      style,
      datasets,
      setTitle,
      valueTitle,
      currency,
      displayFooter,
      displaySplit,
      displayByLabels,
      labels
    } = this.props;

    const {
      functions: { presentCurrency }
    } = this.context;

    return datasets.map(x => {
      return (
        <TableRow
          className="row highlightable"
          onClick={this.onClickRow.bind(this, x)}
        >
          <TableCell className="label">
            <div className="user-cell">
              <TaimerAvatar id={x.users_id} name={x.users_name} />
              <div className="text-container">
                <h3>{x.users_name}</h3>
                <p>{x.users_title}</p>
              </div>
            </div>
          </TableCell>
          <TableCell align="right" className="number-value">
            {presentCurrency(x.value, currency)}
          </TableCell>
          <TableCell align="right" className="number-value">
            {presentCurrency(x.margin, currency)}
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const {
      className,
      style,
      datasets,
      setTitle,
      valueTitle,
      currency,
      displayFooter,
      displaySplit,
      displayByLabels,
      labels,
      ...props
    } = this.props;
    const {
      functions: { presentCurrency }
    } = this.context;

    return (
      <div className={cn("deals-overview-table", className)} style={style}>
        <div className="table-area">
          <Table className="summary">
            {this._renderHeader()}
            <TableBody>{this._renderRows()}</TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

export default DealsOverviewTable;
