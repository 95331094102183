import React from "react";

import ListCell from "../../list/ListCell";
import DateCell from "../../list/cells/DateCell";
import SettingRow from "../../list/rows/SettingRow";
import TrashCanIcon from "@mui/icons-material/Delete";

export default class TimeManagementHolidayRow extends SettingRow {
    render() {
        const data = this.state.data;
        const cells = {
            delete: (
                <ListCell
                    onClick={() => this.delete()}
                    className="noBg pointer"
                    permanentEditMode={true}
                    width={this.props.columnWidthMap["delete"]}
                >
                    <TrashCanIcon />
                </ListCell>
            ),
            date: (
                <DateCell
                    //offsetCalendar={true}
                    closeOnComplete={false}
                    closeCalendarOnComplete={true}
                    editable={true}
                    open={data.id < 0 }
                    className="holiday-datecell"
                    value={data["date"]}
                    width={this.props.columnWidthMap["date"]}
                    onEdited={e => {
                        data.date = e;
                        this.props.rowProps.onUpdate(data);
                    }}
                    listCellProps={{
                        inEditMode: data.id < 0
					}} 
                />
            ),
        };
        return (
            <div
                className="listElement row flex"
                style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
            >
                {this.props.columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }
}
