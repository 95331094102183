import React, { Component } from 'react';
import TaimerComponent from "../../TaimerComponent";
import withStyles from '@mui/styles/withStyles';
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import CameraIcon from '@mui/icons-material/Camera';
import Avatar from '@mui/material/Avatar';
import SettingsGrid from './SettingsGrid';
import Button from '@mui/material/Button';
import { withSnackbar } from 'notistack';



const dateFormats = [
    {
        value: '%d.%m.%Y',
        label: "DD.MM.YYYY",
    },
    {
        value: '%m/%d/%Y',
        label: 'MM/DD/YYYY',
    },
    {
        value: '%Y-%m-%d',
        label: "YYYY-MM-DD",
    },
];

class AccountDefaultSettings extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/pages/AccountDefaultSettings");

        this.state = {
            loaded: false,
            errors: {},
            printLanguageOptions: []
        }

        this.refFileInput = React.createRef();
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.updateComponentData();
    }
    updateComponentData = async () => {
        const { company } = this.props;
        let data = {};
        try {
            data = await DataHandler.get({url: `settings/company/${company}/defaults`});

            if (!data.print_lang && data.country_lang) {
                data.print_lang = data.country_lang;
                this.save(data, false);
            }
        } catch(e) {}

        this.updateState(data, true);
    }
    
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    onChange = (data) => {
        this.setState({data});

        this.save(data);
    }

    save = async (data, updateState = true) => {
        const { company, enqueueSnackbar } = this.props;
        if ( data.session_time < 3600 ){
            enqueueSnackbar(this.tr("Session timeout can not be less than one hour"), {
                variant: "error"
            });
        }
        
        await DataHandler
            .post({ url: `settings/company/${company}/defaults` }, data)
            .fail(x => this.setError(x));
            
        const newdata = {...this.state.data, data};
        updateState && this.updateState(newdata);
    }

    updateState = (data, initial) => {
        let printLanguageOptions = this.state.printLanguageOptions;
        if (initial) {
            data.currencies = data.currencies.map(el => ({value: el.toLowerCase(), label: el.toUpperCase() }));
            data.print_languages = data.print_languages.map(pl => {return {...pl, label: this.tr(pl.label)}});
            printLanguageOptions = data.print_language_options.map(pl => {return {...pl, label: this.tr(pl.label)}});
        }

        this.setState({ loaded: true, data, errors: {}, printLanguageOptions })
    }

    setError(error)
    {
        this.setState({errors: error.responseJSON})
    }

    render() {
        const { loaded, data, errors } = this.state;
        const { tr } = this;

        if (!loaded)
            return <div><img className='main-page-loading-indicator' src={require('../../dashboard/insights/img/loading.svg').default} /></div>
        
        const languages = [
            {
                value: 'en',
                label: tr("English"),
            },
            {
                value: 'fi',
                label: tr("Finnish"),
            },
            {
                value: 'se',
                label: tr("Swedish"),
            },            
        ];
        
        const months = [
            {
                value: '1',
                label: tr("January")
            },
            {
                value: '2',
                label: tr("February")
            },
            {
                value: '3',
                label: tr("March")
            },
            {
                value: '4',
                label: tr("April")
            },
            {
                value: '5',
                label: tr("May")
            },
            {
                value: '6',
                label: tr("June")
            },
            {
                value: '7',
                label: tr("July")
            },
            {
                value: '8',
                label: tr("August")
            },
            {
                value: '9',
                label: tr("September")
            },
            {
                value: '10',
                label: tr("October")
            },
            {
                value: '11',
                label: tr("November")
            },
            {
                value: '12',
                label: tr("December")
            },
        ];

        const settings = [
            {
                type: 'title',
                className: 'header',
                key: 'section-company-defaults',
                label: tr("Define your company default settings"),
                subtext: tr("Define your company's default language and currency."),
            },
            {
                type: 'select',
                options: languages,
                name: 'country_lang',
                label: tr("Language"),
            },
            {
                type: 'select',
                options: this.state.printLanguageOptions,
                className: 'nextLine',
                name: 'print_lang',
                label: tr("Default Print Language"),
            },
            {
                type: 'chipSelect',
                options: this.state.printLanguageOptions.filter(e => !data.print_languages.find(p => p.value == e.value) && e.value != data.print_lang),
                className: 'nextLine',
                name: 'print_languages',
                label: tr("Additional Print Languages"),
                chips: data.print_languages.filter(e => e.value != data.print_lang),
            },
            {
                type: 'select',
                className: 'nextLine',
                options: dateFormats,
                name: 'date_format',
                label: tr("Date Format"),
            },
            {
                type: 'select',
                className: 'nextLine',
                options: data.currencies,
                name: 'currency',
                label: tr("Currency"),
            },
            {
                type: 'title',
                key: 'section-financial',
                label: tr("Your company"),
                subtext: tr("Define your company fiscal/financial/budget year), Taimer will automatically define your reports and dashboards according to chosen month."),
            },
            {
                type: 'select',
                options: months,
                name: 'financial_year_start',
                label: tr("Financial year starting from"),
            },
            {
                type: 'title',
                key: 'section-login-defaults',
                label: tr("Login default settings"),
                subtext: tr("Automatic log out sign user out automatically after chosen period of time. This can be useful if user leaves Taimer open in their browser and forgets to close computer, browser or log out."),
            },
            {
                type: 'text',
                className: 'nextLine',
                name: 'session_time',
                label: tr("Automatic log out"),
                validation: ["numeric"]
            },
        ];


        return <div className="SettingsGrid">
                <SettingsGrid settings={settings} errors={errors} item={data} onChange={this.onChange} />
            </div>
    }
}

export default withSnackbar(AccountDefaultSettings);
