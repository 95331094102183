/* css */
import './ProductDetailsInfo.css';

/* material-ui */
import { MenuItem, Radio, Tooltip, Checkbox, FormControlLabel } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";

/* others */
import React from 'react';
import ContextMenu from './../../general/ContextMenu';
import LabelFieldGroup from "./../../general/LabelFieldGroup";
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from './../../SettingsContext';
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import SupplierSelect from './../../general/SupplierSelect';
import DataHandler from "./../../general/DataHandler";
import { ReactComponent as RemoveIcon } from './../../general/icons/remove.svg';
import { MoreHoriz,CancelOutlined, SaveRounded } from '@mui/icons-material';
import { withSnackbar } from 'notistack';
import _ from 'lodash';
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import validator from 'validator';

class ProductSupplier extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "list/lists/ProductSupplier");

        this.customerWriteRights = context.functions.checkPrivilege("customers", "write", props.product.companies_id);

        this.state = {
            item: props.item,
            currentAddress: props.item,
            deleteSupplierDialogOpen: false,
            editMode: false,
            suppliers: [],
            addresses: [],
            invalidField: "",
            isCatalogProduct: Number(this.props.product.parent_id) > 0,
            productId: Number(this.props.product.parent_id) > 0 ? this.props.product.parent_id : this.props.product.id,
            customerWriteRight: this.customerWriteRights
        };
    }

    componentDidMount() {
        super.componentDidMount();

        DataHandler.get({ url: `subjects/suppliers/${this.props.companiesId}`, get_customers: 1 }).done(response => {
            let customerWriteRight = false;
            const currentSupplier = this.props.item && response.find(e => e.id == this.props.item.customerId);
            if (currentSupplier && currentSupplier.writeable == 1) {
                customerWriteRight = true;
            }

            this.setState({ customerWriteRight, suppliers: response.map(e => { return { ...e, value: e.id } }) });
        });
    }

    saveSupplier = () => {
        let data = {
            customerId: this.state.item['customerId'],
            addressId: this.state.item['addressId'],
            email: this.state.item['email'],
        } 

        if (this.props.name === "new") {
            data.is_default = this.state.item['is_default'];
            data.is_new = 1;
            if (this.props.supplierAmount < 1)
                data.is_default = 1;

            if (!data.customerId) {
                this.props.enqueueSnackbar(this.tr("No supplier selected!"), {
                    variant: "error",
                });
                this.setState({ invalidField: "customerName" })
                return;
            }
            else if (!data.addressId) {
                this.props.enqueueSnackbar(this.tr("No address selected!"), {
                    variant: "error",
                });
                this.setState({ invalidField: "address" })
                return;
            }
            else if (data.email.trim() !== "" && !validator.isEmail(data.email)) {
                this.props.enqueueSnackbar(this.tr("Invalid email!"), {
                    variant: "error",
                });
                this.setState({ invalidField: "email" })
                return;
            }

            this.setState({ invalidField: "" })
            
            DataHandler.post({url: `products/${this.state.productId}/suppliers/${this.props.companiesId}`}, data).done(() => {
                if (this.state.customerWriteRight)
                    this.saveSupplierAddress(data.customerId, data.addressId);
                else {
                    this.supplierSavedSuccessfully();
                }
            })
            .fail(() => {
                this.props.enqueueSnackbar(this.tr("Error in adding supplier!"), {
                    variant: "error",
                });

                this.cancel();
            });
        }
        else {
            if (data.email.trim() !== "" && !validator.isEmail(data.email)) {
                this.props.enqueueSnackbar(this.tr("Invalid email!"), {
                    variant: "error",
                });
                this.setState({ invalidField: "email" });
                return;
            }
            this.setState({ invalidField: "" });
            this.saveSupplierAddress(data.customerId, data.addressId);
        }
    }

    saveSupplierAddress = (supplierId, addressId) => {
        DataHandler.put({ url: `accounts/${supplierId}/company/${this.props.companiesId}` }, { 
            vatid: this.state.item['businessId']
        })
        .done(() => {
            this.saveAddress(addressId);
        })
        .fail(() => {
            this.props.enqueueSnackbar(this.tr("Error in saving address!"), {
                variant: "error",
            });

            this.cancel();
        });
    }

    saveAddress = (addressId) => {
        const item = this.state.item;

        DataHandler.put({ url: `accounts/billing_address/${addressId}` }, {
            name: item['customerName'],
            vatid: item['businessId'],
            contact: item['contact'],
            address: item['address'],
            postalcode: item['postalCode'],
            city: item['city'],
            country: item['country'],
            email: item['email']
        })
        .done(() => {
            this.supplierSavedSuccessfully();
        })
        .fail(() => {
            this.props.enqueueSnackbar(this.tr("Error in saving address!"), {
                variant: "error",
            });

            this.cancel();
        });
    }

    supplierSavedSuccessfully = () => {
        const text = this.props.name !== "new" ? "Supplier \"${supplier}\" address saved!" : "Supplier \"${supplier}\" added succesfully!";
        const successText = this.tr(text, { supplier: this.state.item['customerName'] });

        if (this.props.name === "new")
            this.props.cancel();
        else
            this.setState({ editMode: false });

        this.props.enqueueSnackbar(successText, {
            variant: "success",
        });

        this.setState({ currentAddress: this.state.item })

        setTimeout(() => {
            this.props.getData();
            this.props.getProductData();
        }, 1000);
    }

    deleteSupplier = () => {
        const { item } = this.state;
        const data = {
            customerId: item['customerId'],
            addressId: item['addressId']
        }

        DataHandler.delete({ url: `products/${this.state.productId}/suppliers/${this.props.companiesId}` }, data).done(() => {
            this.closeDeleteDialog();
            if (item.is_default) {
                // Set another supplier as default if there still is suppliers
                this.props.setDefaultSupplier(item.id, false, true);
            }

            this.props.enqueueSnackbar(this.tr("Supplier \"${supplier}\" deleted succesfully!", {supplier: item['customerName']}), {
                variant: "success",
              });
            setTimeout(() => {
                this.props.getData();
                this.props.getProductData();
            }, 1000);
        })
        .fail(response => {
            this.props.enqueueSnackbar(this.tr("Error: ") + this.tr(response.responseJSON.error), {
                variant: "error",
            });
        });
    }

    closeDeleteDialog = () => {
        this.setState({ deleteSupplierDialogOpen: false });
    }

    cancel = () => {
        if (this.props.name === "new")
            this.props.cancel();
        else 
            this.setState({ editMode: false, item: this.state.currentAddress });
    }

    editSupplierAddress = (name, value) => {
        this.setState(state => {
            state.item = { ...state.item, [name]: value };
            return state;
        });
    }

    async supplierChanged(supplier) {
        this.setState({ invalidField: "" })

        let customerWriteRight = supplier.writeable == 1;
        this.getAndSetAddresses(supplier);

        this.setState(state => {
            state.item = {
                ...supplier,
                customerName: supplier.name,
                customerId: supplier.id,
                businessId: supplier.business_id,
                is_default: state.item.is_default
            };
            state.customerWriteRight = customerWriteRight;
            return state;
        });

    }

    supplierCreated = async (newSupplier) => {
        await DataHandler.get({url: `subjects/suppliers/${this.props.companiesId}`, get_customers: 1}).done(response => {
            this.setState({ suppliers: response.map(e => { return {...e, value: e.id} }) });
        });

        this.getAndSetAddresses(newSupplier);

        this.setState(state => {
            state.item = { ...newSupplier, customerId: newSupplier.id, customerName: newSupplier.name };
            state.customerWriteRight = true;

            return state;
        });
    }

    getAndSetAddresses(supplier) {
        DataHandler.get({ url: `subjects/billing_addresses_by_company/${this.props.companiesId}/${supplier.id}` }).done(response => {
                
            const addresses = response.filter(e => !this.props.addressIds.includes(e.id)).map (e => {
                return {...e, label: e.address, value: e.id} 
            });

            this.setState({ addresses });
        });
    }

    addressChanged(address, newSupplier) {
        this.setState({ invalidField: "" })

        if (newSupplier) {
            if (address.__isNew__) {
                this.createNewAddress(address);
            }
            else {
                this.setState(state => {
                    state.item = {
                        ...state.item,
                        addressId: address.id,
                        contact: address.contact,
                        address: address.address,
                        postalCode: address.postalcode,
                        city: address.city,
                        country: address.country,
                        email: address.email
                    };
                    return state;
                });
            }
        }
        else {
            this.setState(state => {
                state.item = { ...state.item, address };
                return state;
            });
        }
    }

    async createNewAddress(address) {
        let addressId = 0;
        let newAddress = await DataHandler.post({ url: `accounts/${this.state.item.customerId}/address/${this.props.companiesId}` }, { address: address.value, address_types: [1] }).fail(response => {
            this.props.enqueueSnackbar(this.tr("Error in creating address!"), {
                variant: "error",
            });

            return;
        });

        _.forEach(newAddress.ids, (id, i) => {
            if (i == 1)
                addressId = id;
            })

        setTimeout(() => {
            DataHandler.get({ url: `subjects/billing_addresses_by_company/${this.props.companiesId}/${this.state.item.customerId}` }).done(response => {
                const addresses = response.map(e => {
                    return { ...e, label: e.address, value: e.id }
                });

                this.setState({ addresses });
                this.setState(state => {
                    state.item = { 
                        ...state.item,
                        addressId, 
                        address: address.label,
                        contact: "",
                        postalCode: "",
                        city: "",
                        country: "",
                        email: ""
                    };
                    return state;
                });
            });

        }, 1000);
    }

    render() {
        const { product, addressIds, supplierAmount, enqueueSnackbar } = this.props;
        const { functions: { checkPrivilege } } = this.context;
        const { item } = this.state;
        const newSupplier = this.props.name === "new";
        const customerFieldDisabled = !this.state.item.customerId || !this.state.customerWriteRight;
        const addressFieldDisabled = !this.state.item.addressId || !this.state.customerWriteRight;
        return (
            <div className="product-details">
            { this.props.name != "new" && (this.props.writePrivileges || this.state.customerWriteRight) &&
             <div className="button-container">
                 {supplierAmount > 1 &&
                    <Tooltip placement="top" title={this.tr('Set as default')}>
                        <Radio
                            onChange={() => this.props.setDefaultSupplier(item.id)}
                            color="primary"
                            checked={Number(item['is_default']) > 0}
                        />
                    </Tooltip>}
                    <ContextMenu label={<MoreHoriz />} noExpandIcon placement="bottom-end">
                        {this.state.customerWriteRight && <MenuItem onClick={() => this.setState({ editMode: true })} ><EditIcon title="" />{this.tr('Edit details')}</MenuItem>}
                        {this.props.writePrivileges && <MenuItem className="delete"  onClick={() => this.setState({ deleteSupplierDialogOpen: true })}><RemoveIcon title="" />{this.tr("Delete")}</MenuItem>}             
                    </ContextMenu>
            </div>}
                <LabelFieldGroup
                    editMode={newSupplier || this.state.editMode}   
                    title={this.props.name !== "new" ? item['customerName'] : this.tr("Add new supplier")}
                    values={undefined}
                    onTextFieldChange={(name, value) => this.editSupplierAddress(name, value)}
                    fields= 
                            {[
                        
                                {label: this.tr("Sub Contractor"), 
                                fromLabelFieldGroup: true,
                                name: "customerName",
                                className: this.state.invalidField === "customerName" ? "product-supplier-error-select" : "",
                                placeholder: this.tr("Select supplier..."),
                                value: item.id == -1 ? null : (newSupplier ? item: item['customerName']),
                                showArchivedAccounts: true,
                                disabled: !newSupplier,
                                editorType: !newSupplier ? undefined : SupplierSelect, 
                                options: this.state.suppliers, 
                                shownCount: 20,
                                company: product.companies_id,
                                enqueueSnackbar: enqueueSnackbar,
                                onChange: async supplier => {
                                    this.supplierChanged(supplier);
                                },
                                supplierCreated: (supplier) => this.supplierCreated(supplier),
                                accountUpdated: (supplier) => {
                                    this.setState({ supplier }, () => {
                                        this.props.enqueueSnackbar && this.props.enqueueSnackbar(this.tr("Account ${account} updated successfully!", {account: supplier.name}), {
                                            variant: "success"
                                        });
                                        this.supplierChanged(supplier);
                                    });
                                }},
                                { label: this.tr("Business ID"), name: "businessId", value: item['businessId'], disabled: customerFieldDisabled},
                                { label: this.tr("Address"), 
                                  name: "address", 
                                  showPlaceholder: true, 
                                  className: this.state.invalidField === "address" ? "product-supplier-error-select" : "",
                                  placeholder: newSupplier && this.state.item.customerId ? this.tr("Select address...") : "",
                                  value: newSupplier ? item['addressId'] : item['address'],
                                  disabled: this.props.name !== "new" ? customerFieldDisabled : !this.state.item.customerId,
                                  editorType: !newSupplier ? undefined : (this.state.customerWriteRight ? CreatableSelect : Select), options: this.state.addresses.filter(e => !addressIds.includes(e.id)), onChange: async e => {                                    
                                    const address = newSupplier ? e : e.target.value;
                                    this.addressChanged(address, newSupplier);
                                   
                            }},
                                { label: this.tr("Postal"), name: "postalCode",value: item['postalCode'], disabled: addressFieldDisabled},
                                { label: this.tr("City"), name: "city",value: item['city'], disabled: addressFieldDisabled},
                                { label: this.tr("Country"), name: "country",value: item['country'], disabled: addressFieldDisabled},
                                { label: this.tr("Contact"), name: "contact",value: item['contact'], disabled: addressFieldDisabled},
                                { label: this.tr("Email"), name: "email", value: item['email'], className: this.state.invalidField === "email" ? "product-supplier-error-text" : "", disabled: addressFieldDisabled}
                          
                            ]}
                
                />
                <div>   
                {(this.props.name === "new" || this.state.editMode) && 
                        <React.Fragment>
                            {supplierAmount > 0 && this.props.name === "new" &&
                                <FormControlLabel
                                    className="is-default-check"
                                    control={
                                        <Checkbox
                                            checked={item['is_default'] == 1}
                                            onChange={() => this.setState({ item: { ...item, is_default: item['is_default'] == 1 ? 0 : 1 } })}
                                            name="is_default_check"
                                            color="primary"
                                        />
                                    }
                                    label={this.tr("Set this address as default")}
                                />}
                            <CancelOutlined className="discardicon" onClick={() => this.cancel()} />
                            <span className="discard" onClick={() => this.cancel()}>{this.tr("Discard")}  </span>

                            <SaveRounded className="save" onClick={() => this.saveSupplier()} />
                            <span className="new-supplier" onClick={() => this.saveSupplier()}>{this.tr("Save changes")}</span>
                        </React.Fragment>
                    }
                </div>
                {this.state.deleteSupplierDialogOpen &&
                    <MassDialog
                        onDialogClose={this.closeDeleteDialog}
                        onDialogSave={this.deleteSupplier}
                        dialogType={"delete"}
                        dialogProps={{
                            wider: true,
                            onCloseClick: () => this.closeDeleteDialog(),
                            open: this.state.deleteSupplierDialogOpen,
                            close: () => this.closeDeleteDialog(),
                            header: this.tr("Delete supplier?"),
                            warning: () => {
                                return ( 
                                <p id="product-delete-dialog-warning">
                                    {this.tr("Are you sure you want to delete supplier \"${supplier}\"?", { supplier: this.state.item['customerName'] })}
                                    {this.state.isCatalogProduct && 
                                        <p>{this.tr("This will delete this supplier from the parent product and all other catalogs.")}</p>
                                    }
                                </p>
                            
                            )},
                            onConfirm: () => {
                                this.deleteSupplier();
                            }
                        }}
                    />
                }
            </div>
           
            
        )
    }
}

export default withSnackbar(ProductSupplier);
