import React from "react";
import InsightTable, { InsightTableListRow } from "../../InsightTable";
import ListCell from "../../../../list/ListCell";
import moment from "moment";
import { useState } from "react";
import InsightTabs from "../../InsightTabs";
import colors from "../../../../colors";

class LeadsAndDealsListedCell extends InsightTableListRow {
  defineCells() {
    const {
      sharedData: { currency, tr },
      data
    } = this.props;
    const {
      userObject: { dateFormat },
      functions: { presentCurrency }
    } = this.context;

    const getProjectStatusCell = () => {
      switch (data.status) {
        case "lost":
          return (
            <div
              className="status-cell-container"
            >
              <div className="status-cell" style={{backgroundColor: "#f97fab" }}>
                {tr("Lost")}
              </div>
            </div>
          );
          case "won":
          return (
            <div
              className="status-cell-container"
            >
              <div className="status-cell" style={{backgroundColor: colors.greenish_cyan }}>
                {tr("Won")}
              </div>
            </div>
          );
          case "on_hold":
          return (
            <div
              className="status-cell-container"
            >
              <div className="status-cell" style={{backgroundColor: "#f5a623" }}>
                {tr("On Hold")}
              </div>
            </div>
          );
          case "pipeline":
          return (
            <div
              className="status-cell-container"
            >
              <div className="status-cell" style={{backgroundColor: "#2d9ff7" }}>
                {tr("Pipeline")}
              </div>
            </div>
          );
        default:
          return (
            <div
              className="status-cell-container"
            >
              <div className="status-cell" style={{ backgroundColor: "red" }}>
                -
              </div>
            </div>
          );
      }
    };
    let otherCells = {};
    Object.keys(data).forEach(key => {
      switch (key) {
        case "tracked_h":
          otherCells[key] = (
            <ListCell alignCell={true} onlyDisplay={true}>
              <div style={{ paddingLeft: 16 }}>{data[key] + " h"}</div>
            </ListCell>
          );
          break;
        case "value":
        case "gross_profit":
          otherCells[key] = (
            <ListCell alignCell={true} onlyDisplay={true}>
              <div style={{ paddingLeft: 16 }}>
                {presentCurrency(data[key] || 0, currency)}
              </div>
            </ListCell>
          );
          break;
        case "status_changed":
          otherCells[key] = (
            <ListCell alignCell={true} onlyDisplay={true}>
              <div style={{ paddingLeft: 16 }}>
                {moment(data[key], "YYYY-MM-DD").format(dateFormat)}
              </div>
            </ListCell>
          );
          break;
        case "status":
          otherCells[key] = (
            <ListCell alignCell={true} onlyDisplay={true}>
              <div style={{ height: "100%", paddingLeft: 16 }}>{getProjectStatusCell()}</div>
            </ListCell>
          );
          break;
        case "status_changed":
          otherCells[key] = (
            <ListCell alignCell={true} onlyDisplay={true}>
              <div style={{ paddingLeft: 16 }}>{data[key]}</div>
            </ListCell>
          );
          break;
        default:
          otherCells[key] = (
            <ListCell alignCell={true} onlyDisplay={true}>
              <div style={{ paddingLeft: 16 }}>{data[key] || "-"}</div>
            </ListCell>
          );
          break;
      }
    });

    let cells = {
      ...otherCells,
      ...super.defineCells()
    };

    return cells;
  }
}

const COLUMN_SETTINGS = {
  width: 150,
  resizeable: false,
  moveable: false,
  hideable: false,
  showMenu: false
};

const LeadsAndDealsListed = props => {
  const { tr, currency, data, ...otherProps } = props;
  const [mode, setMode] = useState("all");
  const tabs = [
    {
      key: "all",
      label: tr("All"),
      action: () => setMode("all")
    },
    {
      key: "new_leads",
      label: tr("New Leads"),
      action: () => setMode("new_leads")
    },
    {
      key: "won_deals",
      label: tr("Won Deals"),
      action: () => setMode("won_deals")
    },
    {
      key: "on_hold",
      label: tr("On Hold"),
      action: () => setMode("on_hold")
    },
    {
      key: "lost",
      label: tr("Lost"),
      action: () => setMode("lost")
    }
  ];
  const columns = [
    {
      field: "nr",
      name: "nr",
      header: tr("Nr"),
      ...COLUMN_SETTINGS,
      width: 50
    },
    {
      field: "project",
      name: "project",
      header: tr("Project"),
      ...COLUMN_SETTINGS
    },
    {
      field: "status",
      name: "status",
      header: tr("Status"),
      ...COLUMN_SETTINGS,
      width: 120
    },
    {
      field: "sales_agent",
      name: "sales_agent",
      header: tr("Sales Agent"),
      ...COLUMN_SETTINGS
    },
    {
      field: "status_changed",
      name: "status_changed",
      header: tr("Status Changed"),
      ...COLUMN_SETTINGS
    },
    {
      field: "funnel",
      name: "funnel",
      header: tr("Funnel"),
      ...COLUMN_SETTINGS
    },
    {
      field: "stage",
      name: "stage",
      header: tr("Stage"),
      ...COLUMN_SETTINGS,
    },
    {
      field: "value",
      name: "value",
      header: tr("Value"),
      ...COLUMN_SETTINGS
    },
    {
      field: "tracked_h",
      name: "tracked_h",
      header: tr("Tracked h"),
      ...COLUMN_SETTINGS
    }
  ];

  return (
    <InsightTable
      {...otherProps}
      filterComponents={<InsightTabs tabs={tabs} selected={mode} />}
      height={400}
      hideDefaults={true}
      noSlider={true}
      blockProps={{
        largeTitle: true,
        rootClassName: "block",
        wrapperClass: "block-wrapper",
        headerClass: "block-header"
      }}
      title={tr("Leads & Deals Listed")}
      rowType={LeadsAndDealsListedCell}
      data={data[mode]}
      columns={columns}
      currency={currency}
      sharedData={{
        currency,
        tr
      }}
    />
  );
};

export default LeadsAndDealsListed;
