import React from 'react';
import Card from '@mui/material/Card';
import { SnackbarContent } from 'notistack';
import styles from "./SnackbarNotification.module.css";

const snackbarNotificationColors = {
    success: "#43a047",
    error: "#d32f2f",
    info: "#2d9ff7",
    warning: "#ffa000",
};

class SnackbarNotification extends React.Component {
    static defaultProps = {
        innerRef: null,
        variant: "info"
    };

    render() {
        const { 
            innerRef,
            children,
            variant
        } = this.props;

        const cardStyles = {
            backgroundColor: snackbarNotificationColors[variant]
        };

        return (
            <SnackbarContent ref={innerRef}>
                <Card className={styles.card} style={cardStyles}>
                    {children}
                </Card>
            </SnackbarContent>
        );
    }
}

export default React.forwardRef((props, ref) => <SnackbarNotification innerRef={ref} {...props} />)
