import { startOfDay } from "date-fns";
import { Employee } from "../../helpers";
import { HourTotalFields, Project, Resource, TaskStatus } from "../../resourcing";
import { ViewOptions } from "../../ResourcingView";

interface GanttFormatingOptions {
    unassignedLabel: string;
    employees: Dictionary<Employee>;
    viewOptions: ViewOptions;
    usersAllowedToShow: false|Dictionary<number>;
}

interface GanttUser {
    id: number;
    name: string;
    color: string;
}

export interface ResourcingListRow {
    id: string;
    text: string;

    hours: number;
    hours_done: number;
    resourced: number;
    remaining: number;

    hours_all_time: number;
    hours_done_all_time: number;
    resourced_all_time: number;
    remaining_all_time: number;

    projectInfo?: Project;
    resource?: Resource;

    progress: false | number;

    customer_name: string;
    project_name: string;

    start_date: Date;
    end_date: Date;
    is_one_day_task: boolean;
    starttime: string|null;
    endtime: string|null;

    users: GanttUser[];

    status: TaskStatus;
    done: boolean;
    priorities_id: number;
    skills_id: number;

    type: string;
}

function addHours(options: GanttFormatingOptions, target?: ResourcingListRow, source?: HourTotalFields|null) {
    if (!target || !source)
        return;

    target.hours = (target.hours || 0) + (source.allocated ?? 0);
    target.resourced = (target.resourced || 0) + (source.resourced ?? 0);
    target.hours_done = (target.hours_done || 0) + (source.tracked ?? 0);

    if (options.viewOptions.includeNegative || target.type === 'task') {
        target.remaining = (target.remaining || 0) + (source.remaining ?? 0);      
    } else {
        target.remaining = (target.remaining || 0) + (Math.max(0, source.remaining ?? 0));
    }
}

function addAllTimeHours(options: GanttFormatingOptions, target?: ResourcingListRow, source?: HourTotalFields|null) {
    if (!target || !source)
        return;

    target.hours_all_time = (target.hours_all_time || 0) + (source.allocated ?? 0);
    target.resourced_all_time = (target.resourced_all_time || 0) + (source.resourced ?? 0);
    target.hours_done_all_time = (target.hours_done_all_time || 0) + (source.tracked ?? 0);

    if (options.viewOptions.includeNegative || target.type === 'task') {
        target.remaining_all_time = (target.remaining_all_time || 0) + (source.remaining ?? 0);
    } else {
        target.remaining_all_time = (target.remaining_all_time || 0) + (Math.max(0, source.remaining ?? 0));
    }
}

export function formatDataForList(options: GanttFormatingOptions, projects: Project[], resources: Resource[]): ResourcingListRow[] {
    const { employees, viewOptions, usersAllowedToShow } = options;

    const today = startOfDay(new Date());

    const rows: ResourcingListRow[] = [];

    for (const r of resources) {
        const users: GanttUser[] = [];

        if (!viewOptions.showOnGoing && r.type === 'task' && r.status === TaskStatus.Ongoing) {
            continue;
        }

        if (!viewOptions.showOverdue && r.type === 'task' && r.status === TaskStatus.Overdue) {
            continue;
        }

        if (!viewOptions.showDone && r.type === 'task' && r.status === TaskStatus.Done) {
            continue;
        }

        if (!viewOptions.showMilestones && r.type === 'milestone') {
            continue;
        }

        if (r.type === 'milestone') {
            const user = employees[r.users_id];
            if (user) {
                users.push({
                    id: user.id,
                    name: user.name,
                    color: user.color || '',
                });
            } else {
                console.error('missing user', r);
            }
        } else if (r.type === 'task') {
            for (const uh of r.users_hours) {
                const user = employees[uh.users_id];
        
                if (!user) {
                    console.error('missing user', uh);
                    continue;
                }
        
                users.push({
                    id: user.id,
                    name: user.name,
                    color: user.color || '',
                });
            }
        }

        const row: ResourcingListRow = {
            id: r.id,
            text: r.description,
            // Hours (period)
            hours: 0,
            hours_done: 0,
            resourced: 0,
            remaining: 0,
            // Hours (all time)
            hours_all_time: 0,
            hours_done_all_time: 0,
            resourced_all_time: 0,
            remaining_all_time: 0,
            progress: r.type === 'task' ? r.progress : 0,
            resource: r,
            customer_name: r.customer_name,
            project_name: r.project_name,
            users,
            done: r.done,
            status: r.status,
            start_date: r.start_date,
            end_date: r.end_date,
            is_one_day_task: r.type === 'task' ? r.is_one_day_task : false,
            starttime: r.type === 'task' ? r.starttime : null,
            endtime: r.type === 'task' ? r.endtime : null,
            priorities_id: r.priorities_id,
            skills_id: r.skills_id,
            type: r.type,
        }

        if (r.type === 'task') {
            const combined = [...r.users_hours, ...r.users_hours_other];

            for (const uh of combined) {
                if (usersAllowedToShow !== false && !usersAllowedToShow[uh.users_id])
                    continue;

                addHours(options, row, uh.periodTotal);
                addAllTimeHours(options, row, uh.allTotal);
            }

            if (r.unassignedHours && (usersAllowedToShow === false || usersAllowedToShow[0])) {
                row.hours += r.unassignedHours.resourced;
                row.hours_all_time += r.unassignedHours.resourced_all_time;
                row.remaining += r.unassignedHours.resourced;
                row.remaining_all_time += r.unassignedHours.resourced_all_time;
            }
        }

        rows.push(row);
    }

    return rows;
}