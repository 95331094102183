import cn from 'classnames';
import React from 'react';
import TaimerComponent from '../../../TaimerComponent';
import { ExpandData, ResourcingSubviewProps, ViewSettings } from '../../ResourcingView';
import styles from './GanttView.module.scss';

import './Gantt.scss';

//  Dhtmlx Gantt
import '../../dhtmlx-gantt/codebase/dhtmlxgantt.css';
// eslint-disable
import '../../dhtmlx-gantt/codebase/dhtmlxgantt.js';
import '../../dhtmlx-gantt/codebase/ext/dhtmlxgantt_drag_timeline.js';
import '../../dhtmlx-gantt/codebase/ext/dhtmlxgantt_grouping.js';
import '../../dhtmlx-gantt/codebase/ext/dhtmlxgantt_marker.js';
/* eslint-enable */

import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem } from '@mui/material';
import { addMonths, endOfDay, endOfMonth, endOfWeek, format, parse, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import _, { debounce } from 'lodash';
import moment from 'moment';
import ContextMenu from '../../../general/ContextMenu';
import DataHandler from '../../../general/DataHandler';
import { getElementOffset } from '../../../helpers';
import { ProjectType, TaskType, TotalData } from '../../resourcing';
import { GanttViewColumn, ValidGanttColumn } from './columns';
import { formatDataForGantt, GanttRow, GanttRowGrouping, GanttRowUser, OrderData } from './helpers';


type ColumnRenderer = (task: any) => string;

function get_icon(item: GanttRow) {
    const { type } = item;

    if (type === 'task') {
        if (item.resource.type === 'task' && item.resource.task_type === TaskType.Project) {
            return `<svg class='task-icon' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 1.84615C0 0.83173 0.83173 0 1.84615 0H14.1538C15.1683 0 16 0.83173 16 1.84615V14.1538C16 15.1683 15.1683 16 14.1538 16H1.84615C0.83173 16 0 15.1683 0 14.1538V1.84615ZM4.92308 1.23077H1.84615C1.5 1.23077 1.23077 1.5 1.23077 1.84615V4.92308H4.92308V1.23077ZM6.15385 1.23077H9.84615V4.92308H6.15385V1.23077ZM14.1538 1.23077H11.0769V4.92308H14.7692V1.84615C14.7692 1.5 14.5 1.23077 14.1538 1.23077ZM1.23077 6.15385H4.92308V9.84615H1.23077V6.15385ZM9.84615 6.15385H6.15385V9.84615H9.84615V6.15385ZM11.0769 6.15385H14.7692V9.84615H11.0769V6.15385ZM4.92308 11.0769H1.23077V14.1538C1.23077 14.5 1.5 14.7692 1.84615 14.7692H4.92308V11.0769ZM6.15385 11.0769H9.84615V14.7692H6.15385V11.0769ZM14.7692 11.0769H11.0769V14.7692H14.1538C14.5 14.7692 14.7692 14.5 14.7692 14.1538V11.0769Z"
                fill="#F8B34B" />
        </svg>`
        } else {
            return `<svg class='task-icon' width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 17.4L2 17.4C1.2268 17.4 0.600001 16.7732 0.600001 16L0.600001 2C0.600001 1.2268 1.2268 0.599999 2 0.6L11.5 0.6C12.2732 0.6 12.9 1.2268 12.9 2L12.9 16C12.9 16.7732 12.2732 17.4 11.5 17.4Z" stroke="#2D9FF7" stroke-width="1.2"/>
            <path d="M3 12.5L4.5001 16.2499L7.5 16.5L17.5379 6.46208C17.9492 6.05072 17.924 5.3764 17.483 4.99696L14.6871 2.59119C14.2972 2.25571 13.7164 2.2707 13.3444 2.62585L3 12.5Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3285 7.84596L15.4597 6.7148C15.8253 6.34918 15.8253 5.75732 15.4597 5.3917L14.4428 4.3748C14.0771 4.00918 13.4853 4.00918 13.1197 4.3748L11.9885 5.50596L14.3285 7.84596ZM4.96846 14.3979C4.71023 14.3979 4.5 14.6081 4.5 14.8663C4.5 15.1245 4.71023 15.3348 4.96846 15.3348C5.22668 15.3348 5.43691 15.1245 5.43691 14.8663C5.43691 14.6081 5.22668 14.3979 4.96846 14.3979ZM5.55798 11.936L11.0538 6.44023L13.3938 8.78023L7.89798 14.276C7.89798 14.276 7.90941 13.8167 7.75859 13.6682C7.68951 13.5991 7.42768 13.5918 7.14755 13.584C6.82534 13.575 6.47891 13.5654 6.37378 13.4602C6.27047 13.3569 6.25747 13.0187 6.24513 12.6978C6.23401 12.4083 6.22342 12.133 6.14755 12.0571C5.98759 11.8972 5.55798 11.936 5.55798 11.936Z" fill="#2D9FF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.9685 13.4609L5.67004 14.1648L4.71942 15.1154L4.52747 14.7132L4.9685 13.4609ZM6.37408 14.8669L5.67025 14.1653L4.71963 15.116L5.12182 15.3079L6.37408 14.8669Z" fill="#2D9FF7"/>
            </svg>`;
        }
    } else if (type === 'milestone') {
        return `<svg class='milestone-icon' width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="7.5" cy="10.5" r="6.9" fill="white" stroke="#A54090" stroke-width="1.2"/>
        <path d="M7.5 11.5V6" stroke="#A54090" stroke-width="1.2"/>
        <path d="M5 1L10 1" stroke="#A54090" stroke-width="1.2"/>
        <path d="M12.9998 2.99977L15.1211 5.12109" stroke="#A54090" stroke-width="1.2" stroke-linecap="round"/>
        </svg>`;
    }

    return "";
}

interface GanttViewProps extends ResourcingSubviewProps {
    parentElement: React.RefObject<HTMLDivElement>;
    settings: ViewSettings;
    columns: GanttViewColumn[];
    startdate: Date;
    enddate: Date;
    expandedRows: Dictionary<boolean>;
    expandedRowsVersion: number;
    setExpandedRows: (rows: Dictionary<boolean>, force_update: boolean) => void;
}

interface GanttViewState {
    columns: ColumnSize[];
    orderData: OrderData | null;
}

interface ColumnSize {
    name: string;
    /**
     * Size in pixels
     */
    width: number;
}

class GanttView extends TaimerComponent<GanttViewProps, GanttViewState> {
    refGanttArea: React.RefObject<HTMLDivElement>;
    refToolbox: React.RefObject<HTMLDivElement>;

    // Drag/Draw variables
    dragState: any = null;
    disableDrag = false;
    disableDraw = false;
    _drawStart: GanttRow|null = null;

    // Scroll position
    ignoreScrollPositionChanges = false;
    currentScrollPositionX: Date | null = null;
    currentScrollPositionY: number | null = null;

    constructor(props: GanttViewProps, context) {
        super(props, context, "resourcing/Gantt");

        this.refGanttArea = React.createRef();
        this.refToolbox = React.createRef();

        this.state = {
            columns: [],
            orderData: null,
        };
    }

    zoomToMap = {
        Hours: 'daily',
        Days: 'daily',
        Weeks: 'weekly',
        Months: 'monthly',
    }

    getGanttDataArea = (): HTMLDivElement|null => {
        const el = document.querySelector("div.gantt_task");

        return el as HTMLDivElement|null;
    }

    getRange = (type, date): [start: Date, end: Date] => {
        const { calendar } = this.context;

        if (type === "daily") {
            return [startOfDay(new Date(date)), endOfDay(new Date(date))];
        } else if (type === "monthly") {
            return [startOfMonth(new Date(date)), endOfMonth(new Date(date))];
        } else if (type === "weekly") {
            return [startOfWeek(new Date(date), { weekStartsOn: calendar.startOfWeek }), endOfWeek(new Date(date), { weekStartsOn: calendar.startOfWeek })];
        }

        return [new Date(), new Date()];
    }

    setVisibleRange = () => {
        const [ganttStart, ganttEnd] = this.visibleRange();

        gantt.config.start_date = ganttStart;
        gantt.config.end_date = ganttEnd;
    }

    /**
     * Range that should be visible in gantt
     * @returns
     */
    visibleRange = (): [start: Date, end: Date] => {
        const { startdate, enddate } = this.props;

        return [
            startOfMonth(addMonths(startdate, -1)),
            endOfMonth(addMonths(enddate, 1)),
        ];
    }

    componentDidMount(): void {
        super.componentDidMount();

        window.addEventListener('resize', this._handleResize);
        window['goToProject'] = this.goToProject;

        this.initializeGantt();
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();

        window.removeEventListener('resize', this._handleResize);
        this.removeGanttEvents();
    }

    _handleResize = debounce(() => this.forceUpdate(), 33);

    goToProject = (evt: MouseEvent, id: number) => {
        const { functions: { updateView } } = this.context;
        updateView({ module: 'projects', action: 'view', id });
        return false;
    }

    componentDidUpdate(prevProps: GanttViewProps, prevState: GanttViewState) {
        const { userObject: { dateFormat } } = this.context;
        const { settings } = this.props;

        let shouldRender = false;
        let taskUpdated = false;
        let updateColumns = false;

        const fields = [
            'taskGetCount',
            'grouping',
            'zoom',
            'startdate',
            'enddate',
            'ganttDataKey',
            'totalData',
            'availability',
            'expandedRowsVersion',
            'resources',
            'projects',
            'columns',
            'viewOptions',
            'links',
        ];
        const updateFields = {
            taskGetCount: true,
            ganttDataKey: true,
            totalData: true,
            expandedRowsVersion: true,
            resources: true,
            projects: true,
            settings: true,
            viewOptions: true,
            links: true,
        };

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];

            const previousValue = prevProps[field];
            const newValue = this.props[field];

            if (field === 'startdate' || field === 'enddate') {
                try {
                    if (format(previousValue, "YYYY-MM-DD") !== format(newValue, "YYYY-MM-DD")) {
                        shouldRender = true;
                    }
                } catch (ex) {
                    console.error(ex);
                }
            } else {
                if (!_.isEqual(previousValue, newValue)) {
                    if (updateFields[field]) {
                        taskUpdated = true;
                    }

                    if (field === "columns") {
                        updateColumns = true;
                    } else {
                        shouldRender = true;
                    }
                }
            }
        }

        if (prevProps.settings.grouping !== settings.grouping) {
            taskUpdated = true;
            updateColumns = true;
        }

        if (updateColumns) {
            this.updateGanttColumns();
            shouldRender = true;
        }

        let scrollPositionToRestore: number|false = false;

        if (prevProps.settings.zoom !== settings.zoom) {
            const el = this.getGanttDataArea();

            if (el) {
                scrollPositionToRestore = el.scrollLeft + (el.offsetWidth / 2) / el.scrollWidth;
            }

            this.setZoom(settings.zoom);
            shouldRender = true;
        }

        if (taskUpdated) {
            shouldRender = true;

            gantt.clearAll();
            gantt.parse(this._getFormattedTasks());
            gantt.groupBy(false);
        }

        if (shouldRender) {
            this.ignoreScrollPositionChanges = true;

            this.setVisibleRange();
            gantt.render();

            this.ignoreScrollPositionChanges = false;

            // Initial scroll to today
            if (this.currentScrollPositionX === null) {
                gantt.showDate(new Date());
            } else {
                const el = this.getGanttDataArea();

                if (scrollPositionToRestore !== false && el) {
                    // el.scrollWidth =
                    const targetPosition = (el.scrollWidth * scrollPositionToRestore) - (el.offsetWidth / 2);
                    el.scrollTo({left: targetPosition});
                } else {
                    // Restore scroll position
                    const left = gantt.posFromDate(this.currentScrollPositionX);
                    gantt.scrollTo(left, null);
                }
            }

            gantt.addMarker({
                start_date: new Date(),
                css: "today",
                text: this.tr("Today"),
                title: format(new Date(), dateFormat)
            });
        }
    }

    _getFormattedTasks = () => {
        const {
            resources, projects, links, viewOptions,
            settings, autocomplete: { employees, skills },
            expandedRows, priorities, usersAllowedToShow, filters,
        } = this.props;
        const { orderData } = this.state;

        const [formattedRows, newOrderData] = formatDataForGantt({
            filters,
            unassignedLabel: this.tr('Unassigned'),
            noneLabel: this.tr('None'),
            employees: _.keyBy(employees, x => x.id),
            grouping: settings.grouping,
            useProjectTrees: true,
            viewOptions,
            expandedRows,
            lastOrder: orderData,
            priorities,
            skills: _.keyBy(skills, x => x.id),
            usersAllowedToShow,
        }, projects, resources);

        // const taskMap = this.gatherTasks(formattedRows, expand);

        // for (const row of formattedRows) {
        //     row.$open = expandedRows[row.id] ?? !!taskMap[row.id];
        // }

        this.setState({ orderData: newOrderData });

        return { data: formattedRows, links };
    }

    _getGanttColumnRenderer = (column: ValidGanttColumn): ColumnRenderer | undefined => {
        if (column === 'text') {
            return this.taskColumnTemplate;
        } else if (column === 'users_name') {
            return this.userColumnTemplate;
        } else if (column === 'pipeline_name') {
            return this.pipelineColumnTemplate;
        } else if (column === 'projects_allocated') {
            return this.hoursAllocatedPeriodTemplate;
        } else if (column === 'projects_allocated_all_time') {
            return this.hoursAllocatedTemplate;
        } else if (column === 'progress') {
            return this.progressColumnTemplate;
        } else if (column === 'add') {
            return this.emptyColumnTemplate;
        } else if (column === 'hours') {
            return this.hoursTemplate;
        } else if (column === 'hours_done') {
            return this.hoursDoneTemplate;
        } else if (column === 'remaining') {
            return this.hoursRemainingTemplate;
        } else if (column === 'budgeted') {
            return this.budgetedTemplate;
        } else if (column === 'hours_all_time') {
            return this.hoursAllTimeTemplate;
        } else if (column === 'hours_done_all_time') {
            return this.hoursDoneAllTimeTemplate;
        } else if (column === 'remaining_all_time') {
            return this.hoursRemainingAllTimeTemplate;
        } else if (column === 'budgeted_all_time') {
            return this.budgetedAllTimeTemplate;
        } else if (column === 'priority') {
            return this.priorityTemplate;
        } else if (column === 'skill') {
            return this.skillTemplate;
        }

        return undefined;
    }

    priorityTemplate = (task: GanttRow) => {
        if (task.priority) {
            return `<img src="${task.priority.icon}" class="priorityIcon" alt="${task.priority.name}" title="${task.priority.name}">`;
        }

        return "";
    }

    skillTemplate = (task: GanttRow) => {
        if (task.skill) {
            return task.skill;
        }

        return "";
    }

    hoursTemplate = (task: GanttRow) => {
        const { tr } = this;

        return task.hours === false ? "" : `${Number(task.hours).toFixed(2)} ${tr('h')}`;
    }

    hoursAllTimeTemplate = (task: GanttRow) => {
        const { tr } = this;

        return task.hours_all_time === false || task.hours_all_time === undefined ? "" : `${Number(task.hours_all_time).toFixed(2)} ${tr('h')}`;
    }

    hoursAllocatedPeriodTemplate = (task: GanttRow) => {
        const { tr } = this;

        if (task.type === 'taimer-project') {
            return task.projectInfo?.projects_allocated_period === null ? "" : `${Number(task.projectInfo.projects_allocated_period).toFixed(2)} ${tr('h')}`;
        }

        return "";
    }

    hoursAllocatedTemplate = (task: GanttRow) => {
        const { tr } = this;

        if (task.type === 'taimer-project') {
            return task.projectInfo?.projects_allocated === null ? "" : `${Number(task.projectInfo.projects_allocated).toFixed(2)} ${tr('h')}`;
        }

        return "";
    }

    hoursDoneTemplate = (task: GanttRow) => {
        const { tr } = this;
        const { settings: { grouping } } = this.props;

        return task.type === 'taimer-project' && grouping === 'project'
            ? this.hoursWithProgress(task.hours_done, this.projectHours(task), tr('tracked of allocated hours'))
            : this.hoursWithProgress(task.hours_done, task.budgeted, tr('tracked of resourced hours'));
    }

    hoursDoneAllTimeTemplate = (task: GanttRow) => {
        const { tr } = this;
        const { settings: { grouping } } = this.props;

        return task.type === 'taimer-project' && grouping === 'project'
            ? this.hoursWithProgress(task.hours_done_all_time, this.projectHours(task), tr('tracked of allocated hours'))
            : this.hoursWithProgress(task.hours_done_all_time, task.budgeted_all_time, tr('tracked of resourced hours'));
    }

    projectHours = (task: GanttRow) => {
        const { taimerAccount: { useExtraProjectHours } } = this.context;

        if (task.type === 'taimer-project') {
            return useExtraProjectHours ? Number(task.projectInfo.hours) || Number(task.projectInfo.maxhours) : Number(task.projectInfo.maxhours);
        }

        return 0;
    }

    hoursRemainingTemplate = (task: GanttRow) => {
        const { tr } = this;
        const { settings: { grouping } } = this.props;

        return task.type === 'taimer-project' && grouping === 'project'
            ? this.hoursWithProgress(task.remaining, this.projectHours(task), tr('tracked of allocated hours'))
            : this.hoursWithProgress(task.remaining, task.hours, tr('remaining of allocated hours'));
    }

    hoursRemainingAllTimeTemplate = (task: GanttRow) => {
        const { tr } = this;
        const { settings: { grouping } } = this.props;

        return task.type === 'taimer-project' && grouping === 'project'
            ? this.hoursWithProgress(task.remaining, this.projectHours(task), tr('tracked of allocated hours'))
            : this.hoursWithProgress(task.remaining_all_time, task.hours_all_time, tr('remaining of allocated hours'));
    }

    budgetedTemplate = (task: GanttRow) => {
        const { tr } = this;

        return (task.budgeted === false || task.budgeted === undefined) ? "" : `${Number(task.budgeted).toFixed(2)} ${tr('h')}`;
    }

    budgetedAllTimeTemplate = (task: GanttRow) => {
        const { tr } = this;

        return (task.budgeted_all_time === false || task.budgeted_all_time === undefined) ? "" : `${Number(task.budgeted_all_time).toFixed(2)} ${tr('h')}`;
    }

    emptyColumnTemplate = () => "";

    // Templates for gantt
    userColumnTemplate = (task: GanttRow) => {
        const { autocomplete: { employees: users } } = this.props;

        if (task.users) {
            if (task.users.length === 0)
                return this.tr("Unassigned");

            if (task.users.length === 1) {
                const owner = task.users[0];
                return _.escape(owner.name);
            }

            let result = "";

            for (const owner of task.users) {
                result += "<div class='owner-label' title='" + _.escape(String(owner.name)).replace('\'', '&#39;') + "'>" + owner.name.substr(0, 1) + "</div>";
            }

            return result;
        }

        return "";
    }

    pipelineColumnTemplate = (task: GanttRow) => {
        if (task.type === 'taimer-project') {
            if (task.projectInfo.status === 1) {
                return "Won Deals";
            } else if (task.projectInfo.status === 5) {
                return "Internal";
            } else {
                return task.projectInfo.pipeline_name ?? "-";
            }
        }
        return "";
    }

    progressColumnTemplate = (task: GanttRow) => {
        if (task.progress !== false) {
            return (task.progress * 100).toFixed(0) + " %";
        }

        return '';
    }

    renderGroupRow = (task: GanttRowGrouping, defaultRender, view) => {
        return document.createElement("div");
    }

    renderUserRow = (task: GanttRowUser, defaultRender, view) => {
        const { availability } = this.props;

        if (task.type === 'user') {
            const el = document.createElement("div");

            if (!task.user)
                return el;

            const { settings: { zoom } } = this.props;
            const user = availability[task.user.id];

            if (!user || !user.gridData) {
                return el;
            }

            const typeToShow = this.zoomToMap[zoom];

            if (!typeToShow) {
                return el;
            }

            const data = user.gridData[typeToShow];

            if (!data) {
                return el;
            }

            const pos = view.getItemPosition(task);

            el.style.position = 'absolute';
            el.style.top = `${pos.top}px`;

            setImmediate(() => {
                let text = '';

                _.each(data, (v, k) => {
                    const [start, end] = this.getRange(typeToShow, k);

                    if (end < gantt.config.start_date)
                        return;
                    else if (start > gantt.config.end_date)
                        return;

                    const left = gantt.posFromDate(start);
                    const right = gantt.posFromDate(end);

                    text += `<div class="gantt_user_availability${v.available < 0 ? " gantt_user_availability_negative" : ""}" style="left: ${left}px; width: ${right - left}px;">
                        ${v.available.toFixed(2)} h
                    </div>`
                });

                el.innerHTML = text;
            });

            return el;
        }
        else {
            return defaultRender(task);
        }
    }

    leftside_text = (start: Date, end: Date, task: any) => {
        const { userObject } = this.context;

        if (task.type === "taimer-project" || task.type === "total") {
            return "";
        }

        return format(start, userObject.dateFormatShort);
    }

    rightside_text = (start: Date, end: Date, task: any) => {
        const { userObject } = this.context;

        if (task.type === "taimer-project" || task.type === "total") {
            return "";
        }
        if (task.type === 'milestone') {
            return task.text;
        }

        return format(end, userObject.dateFormatShort);
    }

    taskLineTemplate = (start, end, task) => {
        if (task.type === 'taimer-project') {
            let text =
                "<span class='start'><div class='date'>" +
                task.start_date.getDate() +
                "</div>";
            text +=
                "<div class='month'>" +
                task.start_date
                    .toLocaleDateString("en-US", { month: "long" })
                    .substring(0, 3) +
                "</div></span>";
            text += "<span class='text'>" + task.text + "</span>";
            text +=
                "<span class='end'><div class='date'>" +
                task.end_date.getDate() +
                "</div>";
            text +=
                "<div class='month'>" +
                task.end_date
                    .toLocaleDateString("en-US", { month: "long" })
                    .substring(0, 3) +
                "</div></span>";
            return text;
        }
        return task.text;
    }

    taskColumnTemplate = (task: GanttRow) => {
        const id = task.projectInfo?.projects_id;

        if (task.type === 'total') {
            return `${task.customer} - ${task.project}`;
        } else if (task.type === "taimer-project" && id) {
            if (task.viewable) {
                return `
                <a href="index.html?module=projects&action=view&id=${id}" onClick="return goToProject(event, ${id})" class='gantt-link project'>${task.project}</a>
                <span class='gantt_text_row customer_bottom'>${task.customer}</span>`;
            } else {
                return `<span class='customer'>${task.customer}</span>
                <span class='customer'>${task.project} (${task.project_number})</span>`;
            }
        }

        return task.text;
    }

    hoursWithProgress = (value: number | false | undefined, total: number | false | undefined, title) => {
        const { tr } = this;
        const { viewOptions: { ganttPercentage } } = this.props;

        if (value === false || value === undefined)
            return '';

        value = Number(value);

        let percentage: number | null = null;

        if (typeof total === 'number' && total > 0) {
            percentage = Math.round(value / total * 100);
        }

        const percent = ganttPercentage && percentage !== null ? `<span class='percentage'>${(percentage).toFixed(0)}%</span>` : "<span class='percentage'>-</span>";

        return `<span title='${percentage !== null && title ? `${(percentage).toFixed(0)}% ${title}` : ''}'><span class='value'>${value.toFixed(2)} ${tr('h')}</span> ${percent}</span>`;
    }

    timelineCellClass = (task: GanttRow, date: Date) => {
        const { startdate, enddate } = this.props;

        const isOutside = date < startdate || date > enddate;
        return isOutside ? "gantt_outside_range" : "gantt_inside_range";
    }

    taskClassFunction = (start, end, task: GanttRow) => {
        const isProject = task.type === 'taimer-project';

        let className = "is-" + task.type + (task.done ? ' done' : '');

        if (task.projectInfo?.projects_type === ProjectType.Vacation) {
            className += " vacation-project";
        }
        if (task.matched) {
            className += " is-matched";
        }

        if (isProject && task.can_add_task) {
            className += " can-add-task";
        }

        if (task.is_root) {
            className += " gantt-root";
        } else if (task.type === 'group') {
            className += " gantt-group";
        }

        if (task.$open) {
            className += " gantt-open";
        }

        return className
    }

    taskRowClass = (start, end, task) => {
        if (task.type === 'user') {
            return 'gantt_user_availability_row';
        }

        return "";
    }

    setZoom = (value: string) => {
        switch (value) {
            case 'Hours':
                gantt.config.scale_unit = 'day';
                gantt.config.date_scale = '%d %M';

                gantt.config.scale_height = 50;
                gantt.config.min_column_width = 30;
                gantt.config.subscales = [
                    { unit: 'hour', step: 1, date: '%H' }
                ];
                break;
            case 'Days':
                gantt.config.min_column_width = 70;
                gantt.config.scale_unit = "week";
                gantt.config.date_scale = "#%W";
                gantt.config.subscales = [
                    { unit: "day", step: 1, date: "%d %M" }
                ];
                gantt.config.scale_height = 50;
                break;
            case 'Weeks':
                gantt.config.min_column_width = 70;
                gantt.config.scale_unit = "month";
                gantt.config.date_scale = "%F";
                gantt.config.scale_height = 50;
                gantt.config.subscales = [
                    { unit: "week", step: 1, date: "#%W" }
                ];
                break;
            case 'Months':
                gantt.config.min_column_width = 70;
                gantt.config.scale_unit = "year";
                gantt.config.date_scale = "%Y";
                gantt.config.scale_height = 50;
                gantt.config.subscales = [
                    { unit: "month", step: 1, date: "%M" }
                ];
                break;
            default:
                break;
        }
    }

    updateGanttColumns = () => {
        const { columns, settings: { grouping } } = this.props;

        gantt.config.columns = columns.map((column) => ({
            ...column,
            label: column.name === 'add' ? "" : column.label,
            template: this._getGanttColumnRenderer(column.name),
            hide: (column.name === 'projects_allocated' || column.name === 'projects_allocated_all_time' || column.name === 'hours' || column.name === 'hours_all_time') && grouping === 'user' ? true : column.hide,
        }));
    }

    /**
     * Sets up Gantt config
     * @returns 
     */
    initializeGantt = () => {
        const { settings } = this.props;

        if (!this.refGanttArea.current) {
            console.error("skipping gantt, no root element")
            return;
        }

        this.updateGanttColumns();
        // gantt.config.api_date = '%Y-%m-%d %H:%i';
        gantt.config.xml_date = "%Y-%m-%d %H:%i";

        gantt.config.static_background = true;
        gantt.config.smart_scales = true;
        gantt.config.smart_rendering = true;

        gantt.config.row_height = 40;
        gantt.config.task_height = 30;
        gantt.config.scale_height = 50;
        gantt.config.grid_resize = true;
        gantt.config.keep_grid_width = false;
        gantt.config.order_branch = false;
        gantt.config.show_errors = false;

        gantt.config.drag_links = true;
        gantt.config.round_dnd_dates = false;

        gantt.config.open_tree_initially = true;
        gantt.config.show_tasks_outside_timescale = true; // Projects might be out of range

        // gantt.locale.labels.section_hours = tr("Hours");
        // gantt.locale.labels.section_users_hours = tr("Users");
        // gantt.locale.labels.section_skill = tr("Skill");
        // gantt.locale.labels.section_priority = tr("Priority");
        // gantt.locale.labels.section_parent = tr("Parent task");
        // gantt.locale.labels.section_customer = tr("Customer");
        // gantt.locale.labels.section_project = tr("Project");
        // gantt.locale.labels.section_type_selection = tr("Type selection");

        gantt.config.layout = {
            css: "gantt_container",
            cols: [
                {
                    // // width: 400,
                    // min_width: 100,
                    rows: [
                        { view: "grid", scrollX: "gridScroll", scrollY: "scrollVer" },

                        // horizontal scrollbar for the grid
                        { view: "scrollbar", id: "gridScroll", group: "horizontal" }
                    ]
                },
                { resizer: true, width: 1 },
                {
                    // min_width: 300,
                    rows: [
                        { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },

                        // horizontal scrollbar for the timeline
                        { view: "scrollbar", id: "scrollHor", group: "horizontal" }
                    ]
                },
                { view: "scrollbar", id: "scrollVer" }
            ]
        };

        // Templates
        gantt.templates.grid_row_class = this.taskClassFunction;
        gantt.templates.task_class = this.taskClassFunction;
        gantt.templates.timeline_cell_class = this.timelineCellClass;
        gantt.templates.task_text = this.taskLineTemplate;

        gantt.templates.leftside_text = this.leftside_text;

        gantt.templates.rightside_text = this.rightside_text;

        gantt.templates.grid_open = function (item) {
            return `<div class='gantt_tree_icon gantt_${item.$open ? "close" : "open"}'>
                <div class='gantt-svg-icon'>
                    <svg class='${item.$open ? 'arrow_up' : 'arrow_down'}' width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.85206 4.68619C2.42678 5.1046 1.73727 5.1046 1.312 4.68619C0.886723 4.26777 0.886723 3.58938 1.312 3.17096L4.21596 0.313814C4.64123 -0.104605 5.33074 -0.104605 5.75602 0.313814L8.65998 3.17096C9.08525 3.58938 9.08525 4.26777 8.65998 4.68619C8.2347 5.1046 7.54519 5.1046 7.11992 4.68619L4.98599 2.58666L2.85206 4.68619Z" fill="#2C3547"/>
                    </svg>
                </div>

            </div>`;
        }

        gantt.templates.grid_blank = function (item) {
            const icon = get_icon(item);

            if (!icon) {
                return "<div class='gantt_tree_icon gantt_blank'></div>";
            }

            return `
            <div class='gantt-svg-icon'>
                ${icon}
            </div>`;
        };

        gantt.templates.grid_file = () => "";

        gantt.templates.grid_folder = (item) => {
            const icon = get_icon(item);

            if (!icon)
                return "";

            return `
            <div class='gantt-svg-icon'>
                ${icon}
            </div>`;
        };

        gantt.config.drag_timeline = {
            ignore: ".gantt_task_line, .gantt_task_link",
            useKey: "ctrlKey"
        };

        gantt.config.types.global_total = "global_total";
        gantt.config.types.user_total = "user_total";
        gantt.config.types.user = "user";
        gantt.config.types.group = "group";

        gantt.config.type_renderers[gantt.config.types.global_total] = function (task, defaultRender) {
            return document.createElement("div");
        };

        gantt.config.type_renderers[gantt.config.types.user] = this.renderUserRow;
        gantt.config.type_renderers[gantt.config.types.group] = this.renderGroupRow;

        gantt.templates.task_row_class = this.taskRowClass

        gantt.showLightbox = this.showDialog;

        this.setZoom(settings.zoom);

        // Events
        this.ganttAttachEvent("onGanttRender", this.onGanttRender);
        this.ganttAttachEvent("onGridResizeEnd", this.onGridResizeEnd);
        this.ganttAttachEvent('onBeforeTaskDrag', this.onBeforeTaskDrag)

        this.ganttAttachEvent('onLightboxSave', this.onLightBoxSave);
        this.ganttAttachEvent('onLinkDblClick', this.openLinkDeleteDialog);
        this.ganttAttachEvent('onTaskDrag', this.onTaskDrag);
        this.ganttAttachEvent('onAfterTaskDrag', this.onAfterTaskDrag);
        // this.ganttAttachEvent("onBeforeTaskDisplay", this.filter);
        this.ganttAttachEvent("onColumnResizeEnd", this.columnResize);
        this.ganttAttachEvent("onAfterLinkAdd", this.createLink);
        this.ganttAttachEvent('onGanttScroll', this.onGanttScroll);
        this.ganttAttachEvent('onMouseMove', this.ganttMouseMove);
        this.ganttAttachEvent('onTaskClick', this.ganttTaskClick);
        this.ganttAttachEvent('onTaskOpened', this.onTaskOpened);
        this.ganttAttachEvent('onTaskClosed', this.onTaskClosed);

        require('../../dhtmlx-gantt/codebase/ext/dhtmlxgantt_smart_rendering.js');
        gantt.init(this.refGanttArea.current);

        this.setVisibleRange();

        gantt.clearAll();
        gantt.parse(this._getFormattedTasks());
        gantt.groupBy(false);

        gantt.render();
        this.initDrag();
    }

    allGanttEvents: string[] = [];

    ganttAttachEvent = (event: GanttEventName, callback: GanttCallback) => {
        this.allGanttEvents.push(gantt.attachEvent(event, callback));
    }

    removeGanttEvents = () => {
        for (const event of this.allGanttEvents) {
            gantt.detachEvent(event);
        }
    }

    // Events handlers
    columnResize = (index, column, newWidth) => {
        const columns = gantt.getGridColumns();
        const obj = {};
        columns.forEach(el => {
            if (el.name === column.name) {
                obj[el.name] = newWidth;
            } else {
                obj[el.name] = el.width;
            }
        });
        localStorage.setItem("gantt_view_column_widths", JSON.stringify(obj));
    }

    onGanttRender = () => {
        this.calculateColumns();
        this.ganttPositionHoverItem();
    }

    onGridResizeEnd = () => {
        this.calculateColumns();
    }

    onGanttScroll = (left: number, top: number) => {
        this.ganttPositionHoverItem();

        if (!this.ignoreScrollPositionChanges) {
            // const scrollState = 

            const date = gantt.dateFromPos(left);

            this.currentScrollPositionX = date;
            this.currentScrollPositionY = top;
        }
    }

    onTaskOpened = (id) => {
        const { expandedRows, setExpandedRows } = this.props;
        setExpandedRows({ ...expandedRows, [id]: true }, false);
    }

    onTaskClosed = (id) => {
        const { expandedRows, setExpandedRows } = this.props;
        setExpandedRows({ ...expandedRows, [id]: false }, false);
    }

    onLightBoxSave = (id, task, isNew, usersHours) => {
        const enddate = moment(new Date(task.start_date)).add(task.duration, 'd');
        const projectsId = task.projects_id;
        const data = {
            description: task.text,
            startdate: moment(task.start_date).format("YYYY-MM-DD"),
            enddate: moment(enddate).format("YYYY-MM-DD"),
            parent_id: task.original_parent,
            hours: task.hours,
            projects_id: projectsId,
            skills_id: task.skills_id,
            priorities_id: task.priorities_id,
            type: task.type,
            users_id: task.users_id,
            time: task.time,
            users_hours: usersHours.map(el => ({ id: el.id, users_id: el.users_id, hours: el.hours, deleted: el.deleted }))
        };
        const url = isNew ? 'resourcing/add_task' : 'resourcing/edit_task/' + id;
        DataHandler.post({ url: url }, data).done(() => {
            this.props.updateResourcingData();
        });
        return true;
    }

    onTaskDrag = (id, mode, task, original) => {
        const dragState = this.dragState || {};
        const modes = gantt.config.drag_mode;
        if (mode == modes.move && dragState.dragType === 'project') {
            const { children } = dragState;
            const diff = task.start_date - (new Date(original.original_start_date).getTime());

            for (const child of children) {
                child.start_date = new Date(+new Date(child.original_start_date) + diff);
                child.end_date = new Date(+new Date(child.original_end_date) + diff);
                gantt.refreshTask(child.id, true);
            }
        }
    }

    onAfterTaskDrag = (id, mode, event) => {
        if (this.disableDrag) {
            return false;
        }
        const dragState = this.dragState || {};
        const task = this.getTaskById(id);

        console.log({id,mode,event, task, dragState})

        switch (mode) {
            case 'resize':
                event.stopPropagation();

                this.changeTaskDate(id);

                this.disableDraw = false;
                break;
            case 'move':
                event.stopPropagation();
                this.disableDrag = true;

                if (task && task.type === 'taimer-project' && dragState.dragType === 'project') {
                    DataHandler.post({
                        url: `resourcing/move_project/${task.projectInfo.projects_id}`,
                        project: task.projectInfo.projects_id,
                        start: format(task.start_date, 'YYYY-MM-DD'),
                    }).done(() => {

                        setTimeout(() => this.props.updateCompomentData(), 1000);
                        this.disableDrag = false;
                    }).fail(() => {
                        this.props.enqueueSnackbar(this.tr("To move project, you need to have permission to move all tasks."), {
                            variant: "error",
                        });
                    });
                } else {
                    this.changeTaskDate(id);
                }

                this.disableDraw = false;
                break;
            case 'progress':
                event.stopPropagation();
                this.disableDrag = true;
                this.changeProgress(id);
                this.disableDraw = false;
                break;
            default:
                break;
        }

        this.dragState = false;
    }

    onBeforeTaskDrag = (id, mode, event) => {
        if (this.disableDrag) {
            return false;
        }

        const task = this.getTaskById(id);

        if (!task || task.type === 'total' || ((task.type === 'milestone' || task.type === 'task') && (!task.editable || task.done)) || task.type === 'task-part' || (task.type === 'taimer-project' && task.projectInfo.projects_type === 3)) {
            return false;
        }

        // this.scrollPos = gantt.getScrollState();
        switch (mode) {
            case 'resize':
                event.stopPropagation();
                this.disableDraw = true;
                return true;
                break;
            case 'move':
                if (task.type === 'taimer-project') {
                    const scale = gantt.getScale();

                    if (scale.min_date > task.start_date || scale.max_date < task.end_date) {
                        this.props.enqueueSnackbar(this.tr(" Can not move projects not fully visible."), {
                            variant: "error",
                        });

                        return false;
                    }

                    // Project: Drag
                    const children = this.getAllChildrenTasks(task.id);

                    this.dragState = {
                        dragType: 'project',
                        children,
                    };

                    if (!children.every(x => x.editable)) {
                        this.props.enqueueSnackbar(this.tr("To move project, you need to have permission to move all tasks."), {
                            variant: "error",
                        });

                        return false;
                    }
                }

                event.stopPropagation();
                this.disableDraw = true;
                return true;
                break;
            case 'progress':
                event.stopPropagation();
                this.disableDraw = true;
                return true;
                break;
        }
        return false;
    }

    changeTaskDate = async (id) => {
        const { updateTask } = this.props;

        let needsServerRefresh = true; // Affects period totals, needs refresh

        const task = this.getTaskById(id);

        if (task?.type === 'task' || task?.type === 'milestone') {
            const { resource } = task;

            const enddate = task.end_date;
            const data = {
                startdate: format(task.start_date, "YYYY-MM-DD"),
                enddate: format(enddate, "YYYY-MM-DD"),
                type: task.type,
                // parent_id: task.original_parent,
            };
            task.end_date = enddate;
            gantt.updateTask(task.id);

            try {
                const dates = await DataHandler.get({ url: 'resourcing/extend_projects', id: [resource.projects_id], start: data.startdate, end: data.enddate });

                if (dates.extended) {
                    needsServerRefresh = true;

                    this.props.enqueueSnackbar(this.tr("Projects date range was extended, because task was moved outside."), {
                        variant: "info"
                    });

                    this.props.onProjectRangeExtended(task.start_date, task.end_date);
                }

            } catch (error) {
                this.props.enqueueSnackbar(this.tr("Moving tasks outside projects current dates requires permission to update project."), {
                    variant: "error"
                });

                return;
            }

            try {
                const response = await DataHandler.post({ url: 'resourcing/edit_task/' + id }, data);

                if (needsServerRefresh) {
                    setTimeout(() => this.props.updateCompomentData(), 1000);
                } else {
                    updateTask(id, {
                        start_date: parse(`${data.startdate} 00:00:00`, 'YYYY-MM-DD HH:mm:ss', new Date()),
                        end_date: parse(`${data.enddate} 23:59:59`, 'YYYY-MM-DD HH:mm:ss', new Date()),
                    }, {
                        moved: true,
                    });
                }

                this.disableDrag = false;
            } catch (error) {
                this.props.enqueueSnackbar(this.tr("Moving tasks failed."), {
                    variant: "error"
                });

                this.props.updateCompomentData();
            }
        } else if (task?.type === 'taimer-project') {
            const { resource } = task;

            const enddate = task.end_date;
            const data = {
                startdate: format(task.start_date, "YYYY-MM-DD"),
                enddate: format(enddate, "YYYY-MM-DD"),
            };
            task.end_date = enddate;
            gantt.updateTask(task.id);

            console.log(task)

            try {
                const response = await DataHandler.put({ url: 'projects/' + task.projectInfo.projects_id }, data);

                if (needsServerRefresh) {
                    setTimeout(() => this.props.updateCompomentData(), 1000);
                } else {
                    updateTask(id, {
                        start_date: parse(`${data.startdate} 00:00:00`, 'YYYY-MM-DD HH:mm:ss', new Date()),
                        end_date: parse(`${data.enddate} 23:59:59`, 'YYYY-MM-DD HH:mm:ss', new Date()),
                    }, {
                        moved: true,
                    });
                }

                this.disableDrag = false;
            } catch (error) {
                this.props.enqueueSnackbar(this.tr("Moving project failed."), {
                    variant: "error"
                });

                this.props.updateCompomentData();
            }
        }
    }

    changeProgress = (id) => {
        const task = this.getTaskById(id);

        if (!task) {
            return;
        }

        const { resource } = task;

        if (resource?.type === 'task') {
            const data = {
                progress: task.progress,
                type: task.type
            };
            DataHandler.post({ url: 'resourcing/edit_task/' + resource.id }, data).done(() => {
                this.disableDrag = false;
            });
        }
    }

    createLink = (id, link) => {
        const typeMap = {
            'task': 0,
            'taimer-project': 1,
            'milestone': 2,
        };
        const sourceRow = this.getTaskById(link.source);
        const targetRow = this.getTaskById(link.target);

        if (!sourceRow || !targetRow) {
            return;
        }

        const { resource: source } = sourceRow;
        const { resource: target } = targetRow;

        if (!source || !target) {
            return;
        }

        const data = {
            source_id: link.source.replace(/[^0-9]/g, ''),
            source_type: typeMap[source.type],
            target_id: link.target.replace(/[^0-9]/g, ''),
            target_type: typeMap[target.type],
            type: link.type
        };

        DataHandler.post({ url: 'resourcing/create_link' }, data).done((newId) => {
            newId && gantt.changeLinkId(id, newId);

            const linkData = gantt.getLink(newId);
            const newLinks = [...this.props.links, linkData];
            this.props.updateLinks && this.props.updateLinks(newLinks);
        });
    }

    // Drag events
    initDrag = () => {
        const { userObject } = this.context;
        const dataArea = document.querySelector(".gantt-container .gantt_data_area");

        if (!dataArea) {
            console.error("gantt element not found!");
            return;
        }

        let drawBar;
        let startDate;
        let leftPx;
        const self = this;
        let hasMoved = false;
        dataArea.addEventListener("mousedown", (evt: any) => {
            if (evt.ctrlKey || !this.props.allowCreate || evt.which != 1 || evt.target != dataArea) {
                return;
            }

            const task = self.getTaskAtPos(dataArea.scrollLeft + evt.layerX, dataArea.scrollTop + evt.layerY);
            const tasks = (gantt.getTaskByTime() as GanttRow[]).filter(el => el.drawnTaskDefaults);

            const nearest = self.getNearestAbove((dataArea.scrollTop + evt.layerY), tasks);

            if (task || self.disableDraw || (nearest && nearest.type === 'taimer-project' && !nearest.can_add_task)) {
                evt.preventDefault();
                return false;
            }

            this._drawStart = nearest || null;
            hasMoved = false;
            if (drawBar) {
                drawBar.remove();
            }
            evt.stopPropagation();
            self.disableDrag = true;
            startDate = gantt.dateFromPos(evt.layerX);
            if (self.props.settings.zoom !== 'Hours') {
                startDate.setHours(0, 0, 0, 0);
            }
            drawBar = document.createElement("div");
            drawBar.classList.add("draw-bar");

            const sDateDiv = drawBar.appendChild(document.createElement("div"));
            sDateDiv.classList.add("date");
            if (self.props.settings.zoom === 'Hours') {
                sDateDiv.textContent = format(startDate, userObject.timeFormat);
            } else {
                sDateDiv.textContent = format(startDate, userObject.dateFormatShort);
            }
            const eDateDiv = drawBar.appendChild(document.createElement("div"));
            eDateDiv.classList.add("date");

            dataArea.appendChild(drawBar);
            let top = dataArea.scrollTop + evt.layerY;
            top -= top % gantt.config.row_height;
            top += (gantt.config.row_height - Number(gantt.config.task_height)) / 2;
            drawBar.style.top = top + "px";
            drawBar.style.left = (dataArea.scrollLeft + evt.layerX) + "px";
            leftPx = (dataArea.scrollLeft + evt.layerX);
        });
        dataArea.addEventListener("mousemove", (evt: any) => {
            if (drawBar) {
                evt.stopPropagation();
                if (!hasMoved && dataArea.scrollLeft + evt.offsetX - leftPx > 4) {
                    hasMoved = true;
                    dataArea.classList.add("drawing");
                }
                drawBar.style.width = (dataArea.scrollLeft + evt.offsetX - leftPx) + "px";
                const endDate = gantt.dateFromPos(evt.layerX);
                if (endDate) {
                    if (self.props.settings.zoom == 'Hours') {
                        drawBar.children[1].textContent = format(endDate, userObject.timeFormat);
                    } else {
                        drawBar.children[1].textContent = format(endDate, userObject.dateFormatShort);
                    }
                }
            }
        });
        dataArea.addEventListener("mouseup", (evt: any) => {
            if (!drawBar) {
                self.disableDrag = false;
                return;
            }
            const endDate = gantt.dateFromPos(evt.layerX);
            if (!endDate) {
                return;
            }
            if (!hasMoved) {
                drawBar.remove();
                drawBar = undefined;
                self.disableDrag = false;
                return;
            }
            evt.stopPropagation();
            let timeData = {}
            if (self.props.settings.zoom != 'Hours') {
                endDate.setHours(0, 0, 0, 0);
            } else {
                timeData = {
                    starttime: format(startDate, "HH:MM"),
                    endtime: format(endDate, "HH:MM"),
                }
            }

            
            const nearest = this._drawStart;

            this._drawStart = null;
            drawBar.remove();
            drawBar = undefined;
            const drawnTask = {
                start_date: startDate,
                end_date: endDate,
                $new: true,
                notFromGantt: true,
                ...timeData,
                ...this.props.newTaskExtra,
                ...nearest?.drawnTaskDefaults,
                origin_point: "gantt_view_drawing"
            };
            dataArea.classList.remove("drawing");

            this.context.functions.addResource(drawnTask, {
                onProjectRangeExtended: this.props.onProjectRangeExtended,
            });
        });
    }

    getAllChildrenTasksLevel = (foundTasks, checked, id) => {
        if (id in checked)
            return;

        checked.push(id);

        gantt.getChildren(id).forEach(child => {
            const task = gantt.getTask(child);

            if (task.type === 'taimer-project' || task.type === 'total')
                return;

            foundTasks.push(task);

            if (!(child in checked))
                this.getAllChildrenTasksLevel(foundTasks, checked, child);
        })
    }

    getAllChildrenTasks = (id): any[] => {
        const foundTasks = [];
        const checked = [];

        this.getAllChildrenTasksLevel(foundTasks, checked, id);

        return foundTasks;
    }

    getTaskById = (id: string): GanttRow | undefined => {
        const tasks = gantt.getTaskBy("id", id);

        if (tasks.length === 1) {
            return tasks[0];
        }

        return undefined;
    }

    getTaskAtPos = (x, y) => {
        const tasks = gantt.getTaskByTime();
        if (tasks.length == 0) {
            return false;
        }
        for (let i = 0; i < tasks.length; i++) {
            const el = tasks[i];
            //@ts-ignore
            const elPos = gantt.getTaskPosition(el)
            if (x >= elPos.left && x <= elPos.left + elPos.width && y >= elPos.top && y <= elPos.top + elPos.height) {
                return el;
            }
        }
        return false;
    }

    getNearestAbove = (abovePx, tasks: GanttRow[]): GanttRow|false => {
        if (tasks.length == 0) {
            return false;
        }
        if (tasks.length == 1) {
            return tasks[0];
        }
        let nearest;
        const elY = gantt.getTaskPosition(tasks[0], this.props.startdate, this.props.enddate).top;
        if (elY < abovePx) {
            nearest = tasks[0];
        }
        const lastDistance = abovePx - elY;
        for (let i = 1; i < tasks.length; i++) {
            const el = tasks[i];
            const elY = gantt.getTaskPosition(el, this.props.startdate, this.props.enddate).top;
            const distance = abovePx - elY;
            if (distance > 0 && distance < lastDistance) {
                nearest = el;
            }
        }
        if (lastDistance < 0) {
            return false;
        }
        return nearest;
    }

    getParents = (parentMap, id) => {
        let parent = parentMap[id];

        const parents: any[] = [];

        while (parent) {
            parents.push(parent);
            parent = parentMap[parent];
        }

        return parents;
    }

    gatherTasks = (tasks: GanttRow[], option: Partial<ExpandData>, collapse = false) => {
        const taskMap = {};

        const { resources } = this.props;

        if (!resources)
            return taskMap;

        const parentMap = _.chain(tasks)
            .keyBy(x => x.id)
            .mapValues(x => x.parent)
            .value();

            debugger;

        if (option.all) {
            for (const task of tasks) {
                taskMap[task.id] = !collapse;
            }
        }

        if (option.subprojects) {
            const taskList = tasks.filter(x => x.projectInfo && x.projectInfo.parentid > 0);

            for (const task of taskList) {
                taskMap[task.id] = !collapse;

                if (!collapse) {
                    for (const subtask of this.getParents(parentMap, task.id)) {
                        taskMap[subtask] = !collapse;
                    }
                }
            }
        }

        if (option.tasks) {
            const taskList = tasks.filter(x => x.projectInfo?.parentid === 0);

            for (const task of taskList) {
                taskMap[task.id] = !collapse;

                if (!collapse) {
                    for (const subtask of this.getParents(parentMap, task.id)) {
                        taskMap[subtask] = !collapse;
                    }
                }
            }
        }

        if (option.subtasks && !collapse) {
            const taskList = tasks.filter(x => x.resource?.type === 'task' && x.resource.parent_id > 0);

            for (const task of taskList) {
                taskMap[task.id] = !collapse;

                for (const subtask of this.getParents(parentMap, task.id)) {
                    taskMap[subtask] = !collapse;
                }
            }
        }

        else if (option.subtasks && collapse) {
            const taskList = tasks.filter(x => x.resource?.type === 'task' && x.resource.parent_id > 0);

            for (const task of taskList) {
                taskMap[task.parent] = !collapse;
            }
        }

        return taskMap;
    }

    expand = (option: keyof ExpandData) => {
        const { expandedRows, setExpandedRows } = this.props;

        const taskMap = this.gatherTasks(gantt.getTaskByTime() as GanttRow[], { [option]: true });
        setExpandedRows({ ...expandedRows, ...taskMap }, true);
    }

    collapse = (option: keyof ExpandData) => {
        const { expandedRows, setExpandedRows } = this.props;

        const taskMap = this.gatherTasks(gantt.getTaskByTime() as GanttRow[], { [option]: true }, true);
        setExpandedRows({ ...expandedRows, ...taskMap }, true);
    }

    openLinkDeleteDialog = (id) => {
        const deleteLink = gantt.getLink(id);

        const source = this.getTaskById(deleteLink.source);
        const target = this.getTaskById(deleteLink.target);

        if (!source || !deleteLink || !target)
            return;

        this.props.openDialog({name: 'deleteLink', link: deleteLink, source, target });
    }

    calculateColumns = () => {
        const columns: ColumnSize[] = [];

        gantt.config.columns.forEach((c) => {
            if (c.hide) return;

            columns.push({
                name: c.name,
                width: c.width,
            })
        });

        this.setState({ columns });
    }

    showDialog = (id: string, taskData: any = false) => {

        // this.scrollPos = gantt.getScrollState();

        console.log({id, taskData})

        if (taskData) {
            this.context.functions.addResource(taskData, {
                onProjectRangeExtended: this.props.onProjectRangeExtended,
            });
        } else {
            const ganttTask = this.getTaskById(id);

            // New Task
            if (ganttTask?.$new) {
                try {
                    gantt.deleteTask(ganttTask.id);
                } catch (ex) {
                    console.error(ex);
                }
                this.context.functions.addResource({ 
                        projects_id: ganttTask?.parent?.substring?.(2), 
                        origin_point: "gantt_view",
                        ...this.props.newTaskExtra 
                    }, {
                        onProjectRangeExtended: this.props.onProjectRangeExtended,
                });
            } else if (ganttTask?.type === 'task' || ganttTask?.type === 'task-part' || ganttTask?.type === 'milestone') {
                this.context.functions.addResource(ganttTask.resource, {
                    onProjectRangeExtended: this.props.onProjectRangeExtended,
                });
            }
        }
    }

    // Gantt Menu handlers
    ganttMoveTimeout: ReturnType<typeof setTimeout> | false = false;
    gantttMenuIsOpen = false;
    currentHoverItem: false | string = false;
    ganttIsSplitting = false;
    ganttSplitIndicator: false | HTMLElement = false;

    ganttMenuOpen = () => {
        this.gantttMenuIsOpen = true;

        this.forceUpdate();
    }

    ganttMenuCloseToggle = () => {
        this.gantttMenuIsOpen = false;
    }

    ganttMenuClose = () => {
        this.gantttMenuIsOpen = false;

        this.ganttHoverClose();
        this.forceUpdate();
    }

    ganttHoverClose = () => {
        if (this.ganttMoveTimeout) {
            clearInterval(this.ganttMoveTimeout);
        }

        this.ganttMoveTimeout = setTimeout(this.ganttToolsHide, 1000);
    }

    gantMenuOver = () => {
        if (this.ganttMoveTimeout) {
            clearInterval(this.ganttMoveTimeout);
        }
    }

    gantMenuOut = () => {
        if (this.gantttMenuIsOpen)
            return;

        this.ganttHoverClose();
    }

    ganttPositionHoverItem = () => {
        if (!this.currentHoverItem || !this.refToolbox.current) {
            return;
        }

        const el = document.querySelector(`div.gantt_task_line[task_id='${this.currentHoverItem}']`) as HTMLDivElement | undefined;

        if (!el) {
            return;
        }

        const task = this.getTaskById(this.currentHoverItem);

        if (!task)
            return;

        const pos = getElementOffset(el);
        //@ts-ignore
        const scrollArea = getElementOffset(gantt.$task_scale);

        if (task.type === "task" && pos.top >= scrollArea.top) {
            let menuBoxLocation = task.parent && String(task.parent).startsWith('vt-') ? 'BOTTOM' : 'RIGHT';

            //@ts-ignore
            const leftSidePos = pos.left + el.offsetWidth + 25;
            const leftSidePosEnd = leftSidePos + 60;

            if (leftSidePosEnd > window.innerWidth) {
                menuBoxLocation = 'BOTTOM';
            }

            if (menuBoxLocation === 'RIGHT') {
                this.refToolbox.current.style.display = 'block';
                this.refToolbox.current.style.left = `${pos.left + el.offsetWidth + 25}px`;
                this.refToolbox.current.style.top = `${pos.top}px`;
                this.refToolbox.current.style.padding = `0 0 0 30px`;
            } else if (menuBoxLocation === 'BOTTOM') {
                this.refToolbox.current.style.display = 'block';
                this.refToolbox.current.style.left = `${pos.left + (el.offsetWidth / 2) - 15}px`;
                this.refToolbox.current.style.top = `${pos.top + 25}px`;
                this.refToolbox.current.style.padding = `5px 0 0 0`;
            }
        }
        else if (this.refToolbox.current) {
            this.refToolbox.current.style.display = 'none';
        }
    }

    ganttMenuEditClick = () => {
        if (!this.currentHoverItem) {
            return;
        }

        this.showDialog(this.currentHoverItem);
    }

    ganttMenuSplitClick = () => {
        if (!this.currentHoverItem) {
            return;
        }

        const task = this.getTaskById(this.currentHoverItem);

        if (task?.resource && task.resource.type === 'task') {
            this.props.openDialog({ name: 'split', task: task.resource });
        }
    }

    ganttMenuMarkAsDoneClick = () => {
        if (!this.currentHoverItem)
            return;

        const ganttSelectedItem = this.getTaskById(this.currentHoverItem);

        if (!ganttSelectedItem)
            return;

        this.ganttMenuClose();

        DataHandler.post({ url: 'resourcing/mark_task_done/' + ganttSelectedItem.id }).done(() => {
            this.props.updateCompomentData();
        });
    }

    ganttTaskClick = (id, e) => {
        const task = this.getTaskById(id);

        if (this.ganttIsSplitting && id === this.currentHoverItem && task?.resource && task.resource.type === 'task') {
            //@ts-ignore
            const pos = gantt.utils.dom.getRelativeEventPosition(e, gantt.$task_data);
            const date = gantt.dateFromPos(pos.x);

            this.ganttIsSplitting = false;

            if (this.ganttSplitIndicator)
                this.ganttSplitIndicator.remove();

            this.props.openDialog({ name: 'split', task: task.resource });
        }

        return true;
    }

    ganttMouseMove = (id, e) => {
        this.ganttMoveTimeout && clearTimeout(this.ganttMoveTimeout);

        if (this.gantttMenuIsOpen) {
            return;
        }

        if (this.ganttIsSplitting && id === this.currentHoverItem) {
            const el = document.querySelector(`div.gantt_task_line[task_id='${this.currentHoverItem}']`);

            if (!el)
                return;

            const rect = el.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            if (this.ganttSplitIndicator) {
                this.ganttSplitIndicator.style.left = `${x}px`;
            }
        } else if (this.ganttIsSplitting) {
            return;
        }

        if (id) {
            this.currentHoverItem = id;
            this.ganttPositionHoverItem();
        }
        else {
            this.ganttMoveTimeout = setTimeout(this.ganttToolsHide, 1000);
        }
    }

    ganttToolsHide = () => {
        if (this.gantttMenuIsOpen)
            return false;

        this.currentHoverItem = false;

        if (this.refToolbox.current) {
            this.refToolbox.current.style.display = 'none';
        }
    }

    ganttMenuDeleteClick = () => {
        if (!this.currentHoverItem) {
            return;
        }

        const task = this.getTaskById(this.currentHoverItem);

        if (!task?.resource)
            return;

        this.props.openDialog({ name: 'delete', task: task.resource });
    }

    _renderTotal = (type, totalData: TotalData | undefined, name) => {
        const columnsMap = {
            hours: 'allocated',
            budgeted: 'resourced',
            hours_done: 'tracked',
            projects_allocated: 'projects_allocated',
            projects_allocated_all_time: 'projects_allocated_all_time',
            remaining: 'remaining',
            remaining_all_time: 'remaining_all_time',
        }

        if (name === "text") {
            if (type === 'visible')
                return this.tr('Visible Total');
            else if (type === 'user')
                return this.tr('Own Total');
            else if (type === 'all')
                return this.tr('All Total');
        } else if (columnsMap[name] && totalData) {
            const col = columnsMap[name] ?? name;
            const value = Number(totalData[col]);

            return <React.Fragment key={name}>{value.toFixed(2)} {this.tr('h')}</React.Fragment>
        }
    }

    render() {
        const { parentElement, viewOptions, totalDataAll, totalDataUser, totalDataView } = this.props;
        const { columns } = this.state;

        if (!parentElement.current) {
            return <div />;
        }

        const totalVisible = (viewOptions.showTotalVisible ? 1 : 0) + (viewOptions.showTotalOwn ? 1 : 0) + (viewOptions.showTotalRow ? 1 : 0);
        const totalArea = totalVisible * 30;

        const ganttHeight = parentElement.current.clientHeight - totalArea;
        const ganttWidth = parentElement.current.clientWidth;

        const ganttSelectedItem = this.currentHoverItem ? this.getTaskById(this.currentHoverItem) : undefined;

        return <div id="gantt-area" className={styles.root}>
            <div ref={this.refToolbox} className="GanttMenuContainer" onMouseOver={this.gantMenuOver}>
                <ContextMenu label={<ContextMenuIcon />} noExpandIcon menuOpenCallback={this.ganttMenuOpen} onClose={this.ganttMenuClose} onToggleClose={this.ganttMenuCloseToggle}>
                    <MenuItem onClick={this.ganttMenuEditClick}>{this.tr("Edit")}</MenuItem>
                    {ganttSelectedItem && ganttSelectedItem.type === 'task' && <>
                        {ganttSelectedItem.resource.rrule === "" && !ganttSelectedItem.resource.recurrence_parent_id && <MenuItem onClick={this.ganttMenuSplitClick}>{this.tr("Split")}</MenuItem>}
                        {!ganttSelectedItem.resource.done && <MenuItem onClick={this.ganttMenuMarkAsDoneClick}>{this.tr("Mark as done")}</MenuItem>}
                    </>}
                    <MenuItem className="gantt-menu-danger" onClick={this.ganttMenuDeleteClick}>{this.tr("Delete")}</MenuItem>
                </ContextMenu>
            </div>

            <div className={cn(styles.gantt, "gantt-container")} style={{ height: ganttHeight, width: ganttWidth }}>
                <div ref={this.refGanttArea} className={styles.inner} style={{ height: ganttHeight }} />
            </div>

            <div style={{ height: ganttHeight, width: ganttWidth }} />

            {viewOptions.showTotalVisible && <div className={cn(styles.totalRow, styles.totalVisible)}>
                {columns.map(x => <div key={x.name} className={styles[`GanttTotal-${x.name}`]} style={{ width: x.width }}>
                    {this._renderTotal("visible", totalDataView, x.name)}
                </div>)}
            </div>}

            {viewOptions.showTotalOwn && <div className={cn(styles.totalRow, styles.totalUser)}>
                {columns.map(x => <div key={x.name} className={styles[`GanttTotal-${x.name}`]} style={{ width: x.width }}>
                    {this._renderTotal("user", totalDataUser, x.name)}
                </div>)}
            </div>}

            {viewOptions.showTotalRow && <div className={cn(styles.totalRow, styles.totalAll)}>
                {columns.map(x => <div key={x.name} className={styles[`GanttTotal-${x.name}`]} style={{ width: x.width }}>
                    {this._renderTotal("all", totalDataAll, x.name)}
                </div>)}
            </div>}
        </div>
    }
}

export default GanttView;