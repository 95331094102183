import ContextMenuIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem } from '@mui/material';
import ContextMenu from '../general/ContextMenu';
import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';
import EditableStatusCell from "../list/cells/EditableStatusCell";
import CurrencyListCell from "../list/CurrencyListCell";
import LinkListCell from "../list/LinkListCell";
import List from "../list/List";
import ListCell from "../list/ListCell";
import ListRow from "../list/ListRow";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import { SettingsContext } from '../SettingsContext';
import TaimerComponent from '../TaimerComponent';

class PoSyncedListRow extends PropsOnlyListRow {
    static contextType = SettingsContext;
    static defaultProps = {
        ...ListRow.defaultProps,
        rowProps: {
            ...ListRow.defaultProps.rowProps,
            editMode: false
        },
    };
    
        
    constructor(props, context) {
        super(props, {}, {}, "bills/PoSyncedListRow");
    }

    // TODO: abstract
    defineClassName() {
        return "PoSyncedListRow";
    }

    unSync = async () => {
        this.props.rowProps.unSyncFunc(this.props.data.id, this.props.data.nr);
    }

    defineCells() {
        const { data, rowProps, sharedData } = this.props;
        const { currency } = rowProps;
        
        let cells = {
            context:
                <ContextMenu data-testid={`po-synclist-row-context-menu-${data.id}`}  className="cell row-menu" onClick={() => { this.setState({error : false}); }} label={<ContextMenuIcon />} buttonProps={{ className: 'action-menu' }} style={{ width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + " 1 0px" }} noExpandIcon>
                    <MenuItem data-testid={`po-synclist-row-context-menu-unsync-${data.id}`} className="delete" onClick={() => this.unSync() }><RemoveIcon className="Delete"/> {this.tr("Unsync")}</MenuItem>
                </ContextMenu>,
            nr: 
                <LinkListCell
                    listCellProps={{inEditMode: false}}
                    value={data['nr'] || "#"}
                    urlHandler={value => `index.html?module=receivedinvoice&action=view&id=${data['id']}`}
                    noTab={true}
                    asText={!data.readable}
                    editable={false}
                    data-testid={`nr_${this.props.rowIndex}`}
                /> ,
            supplier:
                <ListCell
                    listCellProps={{inEditMode: false}}
                    value={data['supplier']}
                    editable={false}
                />,
            sum:
                <CurrencyListCell
                    listCellProps={{inEditMode: false}}
                    value={data['netsum']}
                    currency={currency}
                    editable={false}
                />,
            syncStatus:
                <EditableStatusCell
                    listCellProps={{inEditMode: false}}
                    value={data.state}
                    options={sharedData.billStatuses}
                    editable={false}
                />,
        };

        return cells;
    }
}

class PoSyncedList extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "bills/PoSyncedList");
    }

    render() {
        const { currency, billStatuses, syncedRows, unSyncFunc, hasSyncRights } = this.props;

        const columnConfig = {
            showMenu: false,
            resizeable: false,
            showResizeMarker: false,
            moveable: false,
            hideable: false,
        };

        return (
            <List
                fluid={true}
                noStateData={true}
                id="poSyncedList"
                className="poSyncedList"
                hideHeaderButtons={!hasSyncRights}
                height="auto"
                reverseNewData 
                renderNewDataAtEnd
                data={syncedRows}
                rowHeight={48} // row height + ONE margin
                listRowType={PoSyncedListRow}
                sharedData={{billStatuses: billStatuses}}
                ignoreRowPropsChange={false}
                roundbutton={() => this.props.openSyncSlider()}
                rowProps={{
                    currency,
                    syncedRows,
                    unSyncFunc,
                    hasSyncRights
                }}
                columns={[
                    ... (hasSyncRights ? [{ name: "context", header: "", width: 20, ...columnConfig, columnHeaderType: "roundButton" }] : []),
                    { name: "nr", header: this.tr("Bill nr."), width: 60, ...columnConfig },
                    { name: "supplier", header: this.tr("Supplier"), width: 100, ...columnConfig },
                    { name: "sum", header: this.tr("Sum 0%"), width: 60, ...columnConfig },
                    { name: "syncStatus", header: this.tr("Bill status"), width: 60, ...columnConfig },
                ]} />
        );}
}

export default PoSyncedList;
