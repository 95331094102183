import React from 'react';
import TaimerComponent from '../TaimerComponent';
import { withSnackbar } from 'notistack';
import moment from "moment";
import _ from 'lodash';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Radio  from '@mui/material/Radio';
import RadioGroup  from '@mui/material/RadioGroup';
import FormControlLabel  from '@mui/material/FormControlLabel';
import DialogBorder from './DialogBorder';
import DialogSection from './DialogSection';
import { DialogDropDownButton, DialogDropDownButtonItem } from './DialogDropDownButton';
import { SettingsContext } from '../SettingsContext';
import OutlinedField from "../general/OutlinedField";
import TimeRangeOutlinedField from '../general/TimeRangeOutlinedField';
import DataHandler from '../general/DataHandler';
import DataList from './../general/DataList';
import TimeOutlinedField from './../general/TimeOutlinedField';
import { AddProject } from '../general/no-options/AddItemComponents';
import { DateRangePicker, DatePicker } from '../general/react-date-range/src';
import { ReactComponent as Clock } from '../general/icons/clock.svg';
import { ReactComponent as Repeat } from '../general/icons/repeat.svg';
import { getWorktypesForProject } from '../Data';
import ResourceRecurrenceDialog from './ResourceRecurrenceDialog';
import ConfirmationDialog from "../settings/dialogs/ConfirmationDialog";
import './ResourceDialog.css';
import ProjectTreeDropdown from '../projects/ProjectTreeDropdown';
import TreeDropdown from '../general/TreeDropdown';
import Checkbox from "../general/Checkbox";
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import cn from 'classnames';

import TaskImage from '../resourcing/images/type_task.svg';
import { ReactComponent as TaskImageIcon } from '../resourcing/images/type_task.svg';
import ProjectTaskImage from '../resourcing/images/type_project_task.svg';
import MilestoneImage from '../resourcing/images/type_deadline.svg';
import { ReactComponent as MilestoneImageIcon } from '../resourcing/images/type_deadline.svg';
import SplitTaskDialog from '../resourcing/dialogs/SplitTaskDialog';
import styles from './ResourceDialog.module.scss';

import { clearAutoCompleteCache, getAutocompleteDataForDialog } from '../resourcing/helpers';
import ResourceDialogUsers from './ResourceDialogUsers';
import { parse, format } from 'date-fns';

import HighestPriority from "../resourcing/images/priority/highest.svg";
import HighPriority from "../resourcing//images/priority/high.svg";
import NormalPriorirty from "../resourcing//images/priority/normal.svg";
import LowPriorirty from "../resourcing//images/priority/low.svg";
import LowestPriorirty from "../resourcing//images/priority/lowest.svg";

const priorityToIcon = {
    1: LowestPriorirty,
    2: LowPriorirty,
    3: NormalPriorirty,
    4: HighPriority,
    5: HighestPriority,
}

require("moment-timezone");

function isSingleDay(start, end) {
    const cmpStart = new Date(start);
    cmpStart.setHours(0, 0, 0, 0);

    const cmpEnd = new Date(end);
    cmpEnd.setHours(0, 0, 0, 0);

    return !cmpEnd || ((cmpEnd - cmpStart) / (1000 * 60 * 60 * 24) < 1);
}

export function SingleValueInfo(props) {
    return (
      <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
        {props.children}
  
        {props.data.info && <Tooltip interactive classes={{tooltip: 'darkblue-tooltip'}} className="resourceInfoTooltip" title={props.data.info} enterTouchDelay={10}>
          <span className="DataListInfoIconContainer" onClick={(e) => e.preventDefault()}><InfoIcon className="DataListInfoIcon" /></span>
        </Tooltip>}
      </Typography>
    );
}

export function SingleValuePriority(props) {
    return (
      <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
        <img src={priorityToIcon[props.data.id]} className="priorityIcon" alt="" /> {props.children}
      </Typography>
    );
}

export function CustomOptionPriority(props) {
    return (
          <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            disabled={props.isDisabled}
            component="div"
            style={{
              fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
          >
            <img src={priorityToIcon[props.data.id]} className="priorityIcon" alt="" /> {props.children}
          </MenuItem>
    );
  }

export default withSnackbar(class ResourceDialog extends TaimerComponent {
    static contextType  = SettingsContext;
    static defaultProps = {
        massEditMode: false,
        massEditProjectIds: undefined,
        showLockedUsersWithTag: true,
    };

    isNewProject = false;

	constructor(props, context) {
		super(props, context, "dialogs/ResourceDialog");

        const { userObject } =  this.context;

        const default_skill = this.props.autoCompleteData.skills.find(el => el.is_default == "1" );
        const default_priority = this.props.autoCompleteData.priorities.find(el => el.default );

        const baseData = props.task ?? props.data;

        const task = {
            skills_id: default_skill ? default_skill.id : 0,
            priorities_id: default_priority ? default_priority.id : 3,
            ...baseData,
        }

        task.start_date = moment(task.start_date)._d;
        task.end_date = moment(task.end_date)._d;
        task.is_one_day_task = isSingleDay(task.start_date, task.end_date);

        const { customers, projects, project_drop } = this.props.autoCompleteData;
        const skills = this.props.autoCompleteData.skills.map(el => ({id: el.id, label: el.name, info: el.description}));
        const priorities = this.props.autoCompleteData.priorities.map(el => ({id: el.id, label: this.tr(el.name), icon: el.icon}));

        const skill = skills.find(el => el.id == task.skills_id);
        const priority = priorities.find(el => el.id == task.priorities_id);

        const usersId = task.$new && !task.users_id ? userObject.usersId : task.users_id;

        const employees = (this.props.employees ? this.props.employees : this.props.autoCompleteData.employees).filter(el => el.id > 0);
        const user = employees.find(el => el.id == usersId);
        const projectsId = task.projects_id ? task.projects_id : (task.original_parent ? task.original_parent.split("-")[1] : 0);
        const project = !props.massEditMode ? project_drop.find(el => el.id == projectsId) : undefined;

        let usersHours;
        this.findEmployees = this.props.allEmployees ? this.props.allEmployees : (this.props.employees ? this.props.employees : this.props.autoCompleteData.employees);

        if(task.users_hours) {
            usersHours = task.users_hours.filter(el => !(el.deleted > 0) ).map(el => Object.assign(el, this.findEmployees.find(e => el.users_id == e.id), {id: el.id}))
        } else {
            usersHours = [];
        }

        let projectDisabled = task.project_disabled > 0;

        if(task.quote_rows_id > 0) {
            projectDisabled = true;
        }

        if(!task.starttime) {
            task.starttime = String(new Date().getHours()).padStart(2, '0') + ":00";
        }
        if(!task.endtime) {
            task.endtime = (new Date().getHours() + 1) + ":00";
        }
        if(!task.work_starttime) {
            task.work_starttime = task.starttime;
        }
        if(!task.work_endtime) {
            task.work_endtime = task.endtime;
        }

        if(!task.hours) {
            task.hours = 0;
        }

        task.workhour_date = task.start_date;
        const disabledMap = {};
        if(task.id && task.id.substr) {
            disabledMap[task.id.substr(2)] = true;
        }
        if(this.props.allTasks) {
            this.allTasks = _.cloneDeep(this.props.allTasks)
            .filter(el => {
                return el.type === 'task' && el.task_type == 0;
            }).sort((a, b) => {
                const aP = a.original_parent ? a.original_parent : "";
                const bP = b.original_parent ? b.original_parent : "";
                return aP.localeCompare(bP);
            }).map(el => {
                el.id = el.id.substr(2);
                el.name = el.label = el.description;
                if(typeof el.original_parent == "string") {
                    if(el.original_parent.substr(0,1) === "p") {
                        el.original_parent = undefined;
                    } else {
                        el.original_parent = el.original_parent.substr(2);
                    }
                }
                const isDisabled = disabledMap[el.id] || disabledMap[el.original_parent];

                if(isDisabled) {
                    disabledMap[el.id] = true;
                    if(el.original_parent) {
                        disabledMap[el.original_parent] = true;
                    }
                    return false;
                }

                return {data: el, id: el.id, name: el.name, label: el.name, original_parent: el.original_parent, projects_id: el.projects_id};
            }).filter(el => {
                return el !== false
            }).sort((a, b) => {
                const aN = a.name ? a.name : "";
                const bN = b.name ? b.name : "";
                return aN.localeCompare(bN);
            });
            this.allTasks.unshift({data: {}, id: 0, name: this.tr("Not selected"), label: this.tr("Not selected"), original_parent: 0, disabled: false});
        } else {
            this.allTasks = [];
        }

        const timeRange = format(parse(task.starttime, 'HH:mm', new Date()), 'HH:mm') + " - " + format(parse(task.endtime, 'HH:mm', new Date()), 'HH:mm');

        this.state = {
            task: task,
            isNew: task.$new,
            customers: customers,
            projects: projects,
            project_drop: project_drop,
            employees: employees,
            skills: skills,
            priorities: priorities,
            jobTypes: [],
            skill: skill,
            priority: priority,
            jobType: {},
            user: user,
            project: project,
            add_projects: project ? [project] : [],
            usersHours: usersHours,
            maxhoursInvalid: false,
            userHoursInvalid: false,
            projectInvalid: false,
            timeInvalid: false,
            workhourDateInvalid: false,
            workhourTimeInvalid: false,
            jobTypeInvalid: false,
            descriptionInvalid: false,
            workhourDescriptionInvalid: false,
            projectDisabled: projectDisabled,
            timeRange: timeRange,
            workourTimeRange: timeRange,
            showRecurrenceDialog: false,
            showConfirmationDialog: false,
            confirmationDialogExtra: {},
            confirmationDialogText: '',
            confirmationDialogYSaveFunc: () => {},
            confirmationDialogRadio: 'this',
            showVacationCheck: task?.$new ? project?.type == 3 : task?.projects_type == 3,
            allTasks: this.allTasks.filter(el => el.id === 0 || el.projects_id == projectsId || el.id === task?.original_parent?.substr?.(2)),
            teams: [],
            isSaving: false,
            moveSubtasks: false,
        };

        if(task.$new) {
            this.state.task.users_id = usersId;
        }
        this.lastHours = task.hours;
        this.lastUsersHours = [...usersHours];

        this.customers = customers;
        this.projects = projects;
        this.employees = employees;
        this.assignedAmount = 0;
        this.assignedNoPermissionAmount = 0;
        this.hoursRef = React.createRef();
        this.dialogRef = React.createRef();
        this.refDialogBorder = React.createRef();

        ['setValue', 'onChange', 'onSave',
        'setValueMultiple','delete', 'checkMaxHours','markOwnAsDone', 'markAsDone', 'unlockTask', 'changeTime',
        'changeDateRange','changeWorkTime', 'createHourEntry'
        ].forEach(e => this[e] = this[e].bind(this));
    }

    componentDidMount() {
        super.componentDidMount();

        const { task, date, isNew } = this.state;
        if(isNew && task.original_parent) {
            const projectsId = task.original_parent.split("-")[1];
            this.setState({task: {projects_id: projectsId, ...task}});
        }
        this.refreshJobTypes(this.state.task);

        DataHandler.request("GET", { url: "settings/usergroups" }, {type: "teams", allCompanies: !this.context.taimerAccount.hideOtherCompanyTasks}).done(teams => {
            this.setState({teams: teams.map(x => ({
                id: Number(x.id),
                name: x.name,
                type: Number(x.type),
                companies_id: Number(x.companies_id),
                users: x.users ? x.users.map(y => Number(y)) : [],
            }))});
        });
    }

    compareTeams(t1, t2) {
        const label1 = t1.label.toLowerCase();
        const label2 = t2.label.toLowerCase();

        if (label1 > label2) return 1;
        if (label2 > label1) return -1;
      
        return 0;
    }

    getSelectedProjects = () => {
        const { userObject } = this.context;
        const { task } = this.state;
        return task.$new && this.state.add_projects.length >= 1 ? this.state.add_projects : (task.projects_id > 0 ? [{
            id: Number(task.projects_id), companies_id: Number(task.companies_id) || Number(userObject.companies_id),
        }] : []);
    }

    onChange(e) {
        if(e && !e.target){
            const task = {...this.state.task};
            const add_projects = _.cloneDeep(this.state.add_projects);

            setTimeout(() => clearAutoCompleteCache(e.companies_id), 1000);

            if(task.$new && e.id > 0 && !add_projects.find(el => el && el.id == e.id)) {
                add_projects.push(e);
            }

            this.setState({
                showVacationCheck: add_projects.length > 0 && add_projects.every(x => x.type == 3),
                projectInvalid: false,
                allTasks: this.allTasks.filter(el => el.id === 0 || el.projects_id == e.id || el.id === task?.original_parent?.substr?.(2)),
                add_projects
            });

            this.setValue("projects_id", e.id);
        } else{
            let value = e.target.value;
            if(e.target.name === 'hours') {
                value = value.replace(',','.');
            }
            this.setValue(e.target.name, value);
        }
    }

    async refreshJobTypes(task)
    {
        const jobTypes = await getWorktypesForProject(task.projects_id ? ("" + task.projects_id).replace(/[^0-9]*/g, "") : 0);
        jobTypes.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({jobTypes});

        if (this.state.jobType.id && !jobTypes.find(x => x.id == this.state.jobType.id)) {
            if  (jobTypes.length)
                this.setValue("job_types_id", jobTypes[0].id)
            else
                this.setValue("job_types_id", 0);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.task.projects_id != this.state.task.projects_id) {
            this.refreshJobTypes(this.state.task);
        }
    }

    hoursFromEntries = () => {
        const { usersHours } = this.state;

        const hours = _.sumBy(usersHours.filter(x => !x.deleted), x => Number(x.hours) || 0);

        this.setValue("hours", hours);
        this.checkMaxHours(usersHours, undefined, hours);
    }

    setValue(key, value) {
        const stateObject = {
            task:  {
                ...this.state.task,
                [key]: value
            }
        };

        switch(key) {
            case 'skills_id':
                stateObject.skill = this.state.skills.find(el => el.id == value);
            break;
            case 'priorities_id':
                stateObject.priority = this.state.priorities.find(el => el.id == value);
            break;
            case 'job_types_id':
                stateObject.jobType = this.state.jobTypes.find(el => el.id == value);
                if(stateObject.jobType) {
                    stateObject.jobTypeInvalid = false;
                }
            break;
            case 'workhour_description':
                stateObject.workhourDescriptionInvalid = false;
                break;
            case 'users_id':
                stateObject.user = this.state.employees.find(el => el.id == value);
            break;
            case 'hours':
                this.lastHours = value;
            break;
            default:
                break;
        }
        this.setState(stateObject);
    }

    setValueMultiple(values, callback = undefined) {
        this.setState({task: {...this.state.task, ...values}}, callback);
    }

    switchTab(type) {
        const task = this.state.task;
        task.type = type;
        this.setState({ task: task });
    }

    async onSave() {
        const { isNew, task, usersHours } = this.state;
        const { enqueueSnackbar } = this.props;
        const projectsId = task.projects_id;
        const task_description = task.description;
        if (!projectsId && !this.props.massEditMode) {
            if (task.type === 'milestone') {
                enqueueSnackbar(this.tr("Please select a project for a milestone"), {
                    variant: "error",
                });
            }
            else {
                enqueueSnackbar(this.tr("Please select a project for a task"), {
                    variant: "error",
                });
            }
            this.setState({ projectInvalid: true });
            return;
        }
        if (!task_description) {
            this.setState({ descriptionInvalid: true });
            if (task.type === 'milestone') {
                enqueueSnackbar(this.tr("Please give description for a milestone"), {
                    variant: "error",
                });
            }
            else {
                enqueueSnackbar(this.tr("Please give description for a task"), {
                    variant: "error",
                });
            }

            return;
        }

        this.setState({projectInvalid: false, isSaving: true });

        if (isNew) {
            const projectsId = this.getSelectedProjects();
            let isAllProjectsValid = true;

            if (projectsId.length === 0) {
                enqueueSnackbar(this.tr("Please select a project for a task"), {
                    variant: "error",
                });
                return;
            }

            const invalidUsers = [];

            for (const user of usersHours) {
                if (user.deleted == "1") continue;

                for (const p of projectsId) {
                    const d = await getAutocompleteDataForDialog(p.companies_id, true).map(o => ({...o, 
                        name: `${o.name} ${o.companies_id < 1 ? ` (${this.tr("freelancer")})` : ''}${o.locked > 0 ? ` (${this.tr("locked")})` : ''}`,
                        label: this.props.showLockedUsersWithTag ? `${o.label} ${o.companies_id < 1 ? ` (${this.tr("freelancer")})` : ''}${o.locked > 0 ? ` (${this.tr("locked")})` : ''}` : o.label,
                    }));
                    const u = d.employees.find(x => x.id ==  user.users_id);

                    if (!u.read_companies_all.includes(Number(p.companies_id)) && !u.projects.includes(Number(p.id))) {
                        isAllProjectsValid = false;
                        invalidUsers.push(u.employee);
                        break;
                    }
                }
            }

            if (!isAllProjectsValid) {
                enqueueSnackbar(this.tr("Selected users (${user}) is not valid for all of selected projects", {
                    user: invalidUsers.join(', ')
                }), {
                    variant: "error",
                });
                return;
            }
        }

        if(!isNew && task.recurrence_parent_id > 0) {
            this.setState({
                projectInvalid: false,
                showConfirmationDialog: true,
                confirmationRecurringTask: true,
                confirmationDialogText: this.tr("Do you want to edit recurring elements also? Edited elements are separated from original elements and can't be edited at the same time anymore."),
                confirmationDialogSaveFunc: () => {this.saveQuery()},
                confirmationDialogExtra: {},
            });
        } else {
            this.setState({projectInvalid: false}, () => {
                this.saveQuery();
            });
        }
    }

    rruleToHumaReadable(rrule) {
        const { userObject } = this.context;
        const defaulMonth = {value: 1, label: this.tr('Jan')};
        const data = {
            freq: 'yearly',
            interval: 1,
            ends: 'never',
            after: 1,
            monthday: 1,
            setPos: {value: 1, label: this.tr("First")},
            month: defaulMonth,
            endDate: new Date(),
            selectedFormat: 'monthday',
            selectedWeekDays: {
                su: true,
                mo: false,
                tu: false,
                we: false,
                th: false,
                fr: false,
                sa: false,
                'su,mo,tu,we,th,fr,sa': false,
                'mo,tu,we,th,fr': false,
                'su,sa': false
            }
        };
        const rrules = rrule.split(";");
        let format = 'monthday';
        const weekdays = [
            {value: 'su', label: this.tr("Sun")},
            {value: 'mo', label: this.tr("Mon")},
            {value: 'tu', label: this.tr("Tue")},
            {value: 'we', label: this.tr("Wed")},
            {value: 'th', label: this.tr("Thu")},
            {value: 'fr', label: this.tr("Fri")},
            {value: 'sa', label: this.tr("Sat")},
            {value: 'su,mo,tu,we,th,fr,sa', label: this.tr("Day"), isComposite: true},
            {value: 'mo,tu,we,th,fr', label: this.tr("Weekday"), isComposite: true},
            {value: 'su,sa', label: this.tr("Weekend day"), isComposite: true},
        ];
        const months = [
            {value: 1, label: this.tr("Jan")},
            {value: 2, label: this.tr("Feb")},
            {value: 3, label: this.tr("Mar")},
            {value: 4, label: this.tr("Apr")},
            {value: 5, label: this.tr("May")},
            {value: 6, label: this.tr("Jun")},
            {value: 7, label: this.tr("Jul")},
            {value: 8, label: this.tr("Aug")},
            {value: 9, label: this.tr("Sep")},
            {value: 10, label: this.tr("Oct")},
            {value: 11, label: this.tr("Nov")},
            {value: 12, label: this.tr("Dec")},
        ];
        const onTheDays = [
            {value: 1, label: this.tr("First")},
            {value: 2, label: this.tr("Second")},
            {value: 3, label: this.tr("Third")},
            {value: 4, label: this.tr("Fourth")},
            {value: -1, label: this.tr("Last")},
        ];
        let endFormat = 'never';
        for(let i = 0; i < rrules.length; i++) {
            const split = rrules[i].split("=");
            const name = split[0].toLowerCase();
            const value = split[1].toLowerCase();                
            switch(name) {
                case 'freq':
                    data.freq = value;
                    break;
                case 'interval':
                    data.interval = value;
                    break;
                case 'bysetpos':
                    format = 'countday';
                    data.setPos = onTheDays.find(el => el.value == value);
                    break;
                case 'bymonth':
                    data.month = months.find(el => el.value == value);
                    break;
                case 'bymonthday':
                    data.monthday = value;
                    break;
                case 'byday':
                    for(const j in data.selectedWeekDays) {
                        data.selectedWeekDays[j] = false;
                    }
                    if(data.selectedWeekDays[value] !== undefined) {
                        data.selectedWeekDays[value] = true;
                    } else {
                        const splittedDays = value.split(",")     
                        for(let k = 0; k < splittedDays.length; k++) {
                            if (splittedDays[k])
                                data.selectedWeekDays[splittedDays[k]] = true;
                        }                            
                    }
                    break;
                case 'count':
                    endFormat = 'after';
                    data.after = value;
                    break;
                case 'until':
                    endFormat = 'onDate';
                    data.endDate = moment(value.toUpperCase())._d;
                    break;
            }                
        }
        data.selectedFormat = format;            
        data.ends = endFormat;                    
        let humanReadable = "";
        const weekdayTranslations = [];
        const freq = data.freq;
        
        if(freq != 'yearly') {
            humanReadable += "interval=1";
        }
        humanReadable += "freq=1";

        if(freq == 'yearly' || freq == 'monthly') {            
            if(data.selectedFormat == 'monthday') {                
                if(freq == 'yearly') {
                    humanReadable += "bymonth=1";
                }
                humanReadable += "bymonthday=1";
            }
            if(data.selectedFormat == 'countday') {          
                let singleWeekday = false;
                if(data.selectedFormat == 'countday') {
                    for(const i in data.selectedWeekDays) {
                        if(data.selectedWeekDays[i]) {
                            singleWeekday = i;
                            weekdayTranslations.push(weekdays.find(el => el.value == i).label);
                            break;
                        }
                    }
                }
                humanReadable += "bysetpos=1";
                humanReadable += "byday=1";
                if(freq == 'yearly') {
                    humanReadable += "bymonth=1";
                }
            }
        }
        if(freq == 'weekly') {
            humanReadable += "byday=1";
            const selected = [];
            for(const i in data.selectedWeekDays) {
                if(data.selectedWeekDays[i] && i.indexOf(",") == -1) {
                    selected.push(i);
                    weekdayTranslations.push(weekdays.find(el => el.value == i).label);
                }
            }
        }
        
        if(data.ends == 'after') {
            humanReadable += "count=1";
        }
        if(data.ends == 'onDate') {
            humanReadable += "until=1";
        }

        const freqTranslations = {
            yearly: this.tr('year'),
            monthly: data.interval > 1 ? this.tr('months') : this.tr('month'),
            weekly: data.interval > 1 ? this.tr('weeks') : this.tr('week'),
            daily: data.interval > 1 ? this.tr('days') : this.tr('day')
        };


        humanReadable = this.tr('Every') + " " + humanReadable;
        if(data.interval > 1) {
            humanReadable = humanReadable.replace(/interval=1/, data.interval);
        } else {
            humanReadable = humanReadable.replace(/interval=1/, "");
        }
        humanReadable = humanReadable.replace(/freq=1/, " " + freqTranslations[freq] + " ");
        humanReadable = humanReadable.replace(/bymonth=1/, (data.selectedFormat == 'countday' ? " " + this.tr('of') + ' ' : ''  ) + data.month.label + " ");
        humanReadable = humanReadable.replace(/bymonthday=1/, data.monthday + ". ");
        humanReadable = humanReadable.replace(/bysetpos=1/, data.setPos.label.toLowerCase() + " ");
        humanReadable = humanReadable.replace(/count=1/, ", " + data.after + " " + this.tr('times'));
        humanReadable = humanReadable.replace(/until=1/, ", " + this.tr('until') + " " + moment(data.endDate).format(userObject.dateFormat));
        humanReadable = humanReadable.replace(/byday=1/, weekdayTranslations.join(", ").toLowerCase() + " ");
        humanReadable = humanReadable.replace(" , ", ", ");
        humanReadable = this.tr("Repeats") + " " + humanReadable;
        return humanReadable;
    }

    saveQuery = async () =>  {
        const { enqueueSnackbar, closeSnackbar, onProjectRangeExtended } = this.props;
        const { isNew, task, usersHours } = this.state;
        const id = task.id;
        const projectsId = task.$new && this.state.add_projects.length > 1 ? this.state.add_projects.map(el => el.id)  : task.projects_id;
        let onSave = () => {};
        if(this.props.onSave) {
            onSave = this.props.onSave;
        } else if(this.props.onDialogSave) {
            onSave = this.props.onDialogSave;
        }
        const isOneDayTask = !task.end_date || ((task.end_date - task.start_date) / (1000 * 60 * 60 * 24) < 1);

        const data = {
            description: task.description,
            startdate: moment(task.start_date).format("YYYY-MM-DD"),
            enddate: moment(task.end_date).format("YYYY-MM-DD"),
            parent_id: task.original_parent,
            hours: task.hours,
            projects_id: !this.props.massEditMode ? projectsId : this.props.massEditProjectIds,
            skills_id: task.skills_id,
            priorities_id: task.priorities_id,
            type: task.type,
            users_id: task.users_id,
            time: task.time,
            starttime: moment(task.starttime, 'LT') ? moment(task.starttime, 'LT').format("HH:mm") : null,
            endtime: moment(task.endtime, 'LT') ? moment(task.endtime, 'LT').format("HH:mm") : null,
            quote_rows_id: task.quote_rows_id,
            users_hours: usersHours.map(el => ({
                id: el.id, 
                users_id: el.users_id, 
                hours: el.hours, 
                periods: el.periods?.map(p => ({
                    start: format(p.start, 'YYYY-MM-DD'),
                    end: format(p.end, 'YYYY-MM-DD'),
                    hours: p.hours,
                })),
                deleted: el.deleted
            })),
            timezone: moment.tz.guess(),
            rrule: task.rrule ? task.rrule : '',
            is_full_vacation: !this.state.showVacationCheck ? 0 : task.is_full_vacation,
            additional_description: task.additional_description,
            moveSubtasks: this.state.moveSubtasks ? 1 : 0,
        };

        if(isOneDayTask) {
            const hours = usersHours.reduce((a, b, i) => {
                if(b.deleted > 0) {
                    return Number(a);
                }
                return Number(a) + Number(b.hours);
            }, 0);
            data.hours = hours;
        }
        const key = enqueueSnackbar(this.tr("Saving..."), {
            variant: "info",
            persist: true,
        });

        const startDate = moment(task.start_date).format("YYYY-MM-DD");
        const endDate = moment(task.end_date).format("YYYY-MM-DD");

        try {
            const dates = await DataHandler.get({url: 'resourcing/extend_projects', id: this.getSelectedProjects().map(x => x.id), start: startDate, end: endDate});

            if (dates.extended) {
                setTimeout(() => {
                    onProjectRangeExtended && onProjectRangeExtended();
                }, 1000);
                enqueueSnackbar(this.tr("Projects date range was extended, because task was created outside."), {
                    variant: "info"
                });
            }

        } catch (error) {
            closeSnackbar(key);
            
            enqueueSnackbar(this.tr("Creating tasks outside projects current dates requires permission to update project."), {
                variant: "error"
            });
            this.setState({isSaving: false});

            return;
        }

        let url = "";

        if(!this.props.massEditMode && !(isNew && this.state.add_projects.length > 1))
            url = isNew ? 'resourcing/add_task' : 'resourcing/edit_task/' + id;
        else
            url = "resourcing/add_task_multiple";

        DataHandler.post({url: url, edit_others: this.state.confirmationDialogRadio}, data).done((response) => {
            if (response.error) {
                enqueueSnackbar(this.tr("Can't create task to locked project."), {
                    variant: "error"
                });
                this.setState({isSaving: false});
            } else {
                onSave(task, isNew, usersHours, data)
                window.dispatchEvent(new Event('taskSaved'));
            }
            closeSnackbar(key);
        }).fail(() => {
            closeSnackbar(key);
            enqueueSnackbar(this.tr("Error while saving."), {
                variant: "error"
            });
        });
    }

    split = () => {
        this.setState({
            showSplitDialog: true,
        });
    }

    delete() {
        const { task } = this.state;   
        let congirmText = this.tr("Delete task \"${task}\"?", {task: task.description});
        let isRecurring = false;
        if (task.type === "milestone") {
            congirmText = this.tr("Delete milestone \"${task}\"?", {task: task.description});
        }
        if(task.recurrence_parent_id > 0) {
            congirmText = this.tr("Do you want to delete recurring elements also?")
            isRecurring = true;
        }

        this.setState({
            showConfirmationDialog: true,
            confirmationRecurringTask: isRecurring,
            confirmationDialogText: congirmText,
            confirmationDialogSaveFunc: () => {this.deleteQuery()},
            confirmationDialogExtra: {},
        });
    }

    closeAndUpdate = () => {
        this.props.closeAndUpdate && this.props.closeAndUpdate();
    }

    deleteQuery() {
        const { task } = this.state;
        const { enqueueSnackbar, closeSnackbar } = this.props;
        const key = enqueueSnackbar(this.tr("Deleting..."), {
            variant: "info",
            persist: true,
        });
        this.setState({
            showConfirmationDialog: false,
        });
        const url = task.type === 'task' ? 'resourcing/delete_task/' : 'resourcing/delete_milestone/';
        DataHandler.delete({ url: url + task.id, delete_others: this.state.confirmationDialogRadio }).done((response) => {
            closeSnackbar(key);

            if (response.success && task.type === 'task') {
                enqueueSnackbar(this.tr(`Task deleted successfully!`), {
                    variant: "success",
                });

                this.props.onClose && this.props.onClose();
                this.props.onDelete && this.props.onDelete(task.id, this.state.confirmationDialogRadio != 'this');
            } else if (response.success) {
                enqueueSnackbar(this.tr(`Milestone deleted successfully!`), {
                    variant: "success",
                });

                this.props.onClose && this.props.onClose();
                this.props.onDelete && this.props.onDelete(task.id, this.state.confirmationDialogRadio != 'this');
            } else if (response.error === 'subtask') {
                enqueueSnackbar(this.tr(`Tasks with subtasks can't be removed until you remove or move subtasks!`), {
                    variant: "error",
                });
            }else if (response.error === 'workhours') {
                enqueueSnackbar(this.tr(`Tasks with workhours logged can't be removed!`), {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(this.tr(`Error while deleting.`), {
                    variant: "error",
                });
            }

            window.dispatchEvent(new Event('taskSaved'));
        }).fail(() => {
            closeSnackbar(key);
            enqueueSnackbar(this.tr("Error while deleting."), {
                variant: "error"
            });
        });
    }

    changeDateRange(range) {
        const { task, usersHours } = this.state;

        const start = range.range1.startDate;
        const end = range.range1.endDate;

        const isOneDayTask = isSingleDay(start, end);
        let callback;

        const taskUpdate = {};

        if (isOneDayTask) {
            console.log({task})
            const split = this.state.timeRange.split("-");
            let starttime = parse(split[0].trim(), 'HH:mm', new Date());
            let endtime = parse(split[1].trim(), 'HH:mm', new Date());

            if (isNaN(starttime) || isNaN(endtime)) {
                starttime = parse(task.starttime, 'HH:mm', new Date());
                endtime = parse(task.endtime, 'HH:mm', new Date());
            }

            starttime = !isNaN(starttime) ? starttime : undefined;
            endtime = !isNaN(endtime) ? endtime : undefined;         

            callback = () => this.changeTime(`${format(starttime, 'HH:mm')} - ${format(endtime, 'HH:mm')}`, starttime, endtime)
        } else {
            taskUpdate.hours = this.lastHours;
            console.log(task)
            callback = () => {
                this.setState({
                    usersHours: usersHours.map(x => ({
                        ...x,
                        hours: x.lastHours !== undefined ? x.lastHours : x.hours,
                    })),
                    timeRange: `${task.starttime} - ${task.endtime}`,
                })
            };
        }

        this.setValueMultiple({
            start_date: new Date(start),
            end_date: new Date(end),
            is_one_day_task: isOneDayTask,
            ...taskUpdate,
        }, callback);
    }

    markOwnAsDone() {
        const { userObject } = this.context;
        let { usersHours } = this.state;
        const data = {
            users_id: userObject.usersId
        };

        DataHandler.post({url: 'resourcing/mark_hours_done/' + this.state.task.id},data).done(() => {
            usersHours = usersHours.map(el => {
                if(el.users_id === Number(userObject.usersId)) {
                    return {...el, done: true};
                }
                return el;
            });
            this.dialogRef.current.close();
            this.setState({usersHours: usersHours});
            this.lastUsersHours = [...usersHours];

            this.closeAndUpdate();
        });

    }

    markOwnAsUndone = () => {
        const { userObject } = this.context;
        let { usersHours } = this.state;
        const data = {
            users_id: userObject.usersId
        };

        DataHandler.post({ url: 'resourcing/mark_hours_undone/' + this.state.task.id }, data).done(() => {
            usersHours = usersHours.map(el => {
                if (el.users_id == userObject.usersId) {
                    return { ...el, done: false };
                }
                return el;
            });
            this.dialogRef.current.close();
            this.setState({ usersHours: usersHours });
            this.lastUsersHours = [...usersHours];

            this.closeAndUpdate();
        });
    }

    markAsDone() {
        const state = this.state;

        DataHandler.post({url: 'resourcing/mark_task_done/' + this.state.task.id}).done(() => {
            state.usersHours = state.usersHours.map(el => ({...el, done: true}));
            state.task.done = 1;
            this.setState(state, () => {
                this.onSave();
            })
        });
    }

    unlockTask() {
        const state = this.state;
        DataHandler.post({url: 'resourcing/unlock_task/' + this.state.task.id}).done(() => {
            state.usersHours = state.usersHours.map(el => ({...el, done: false}));
            state.task.done = 0;
            this.dialogRef.current.close();
            this.setState(state);

            this.closeAndUpdate();
        });
    }

    openRecurrenceDialog = () => {
        this.setState({showRecurrenceDialog: true});
    }

    recurrenceSave = (rule) => {
        const state = this.state;
        state.task.rrule = rule;
        state.showRecurrenceDialog = false;
        this.setState(state);   
    }

    handleConfirmationRadio = (evt) => {
        this.setState({confirmationDialogRadio: evt.target.value});
    }

    changeTime(value, start, end) {
        const task = { ...this.state.task };

        let usersHours = this.state.usersHours;
        const timeInvalid = !start || !end || end < start;

        if (!timeInvalid) {
            const hours = (end - start) / (1000 * 60 * 60);
            usersHours = usersHours.map(el => ({ ...el, hours: hours, lastHours: el.hours, invalid: false }));
            const count = usersHours.filter(el => !el.deleted || el.deleted < 1).length;
            task.hours = hours * count;
        }
        task.starttime = timeInvalid ? false : format(start, 'HH:mm');
        task.endtime = timeInvalid ? false : format(end, 'HH:mm');

        this.setState({
            timeRange: value,
            task: task,
            usersHours: usersHours,
            timeInvalid: timeInvalid,
            userHoursInvalid: false,
            maxhoursInvalid: false
        });
    }

    changeWorkTime(value, start, end) {
        const task = this.state.task;
        const times = value.split("-");
        const timeInvalid = !start || !end || end < start;
        task.work_starttime = timeInvalid ? false : start;
        task.work_endtime = timeInvalid ? false :  end;
        this.setState({workourTimeRange: value, task: task, workhourTimeInvalid: timeInvalid})
    }

    checkMaxHours(usersHours, index = undefined, maxhours = undefined) {
        const { task } = this.state;

        const maxHours = maxhours ? maxhours : Number(this.state.task.hours);
        const hours = _.sumBy(usersHours.filter(x => !x.deleted), x => x.hours);

        console.log({hours, maxHours})
        
        if(!task.is_one_day_task && hours > maxHours) {
            if(index !== undefined && index > -1) {
                usersHours[index].invalid = true;
            }
            this.setState({usersHours: usersHours, maxhoursInvalid: true, userHoursInvalid: true});
            return false;
        }
        usersHours = usersHours.map(el => ({...el, invalid: false}));
        this.setState({usersHours: usersHours, maxhoursInvalid: false, userHoursInvalid: false});
        this.lastUsersHours = [...usersHours];
    }

    optionRenderer() {
        const optionRenderer = withStyles({
            customer: {
                display: 'block'
            },
            project: {
                display: 'block',
            },
            item: {
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                alignItems: 'baseline',

            }
        })(props => {
            return (
                <MenuItem className={props.classes.item}
                    buttonRef={props.innerRef}
                    selected={props.isFocused}
                    component="div"
                    style={{
                        fontWeight: props.isSelected ? 500 : 400,
                    }}
                    {...props.innerProps}
                >
                <div className={props.classes.customer}>{props.data.customer}</div>
                <div className={props.classes.project}>{props.data.parent_name ? props.data.parent_name + ' / ' +  props.data.name : props.data.name}</div>
                </MenuItem>
            );
        });
        return optionRenderer;
    }

    createHourEntry() {
        const { userObject, functions: { getTimeTrackerSettings } } = this.context;
        const { enqueueSnackbar } = this.props;

        const { task } = this.state;
        
        const state = this.state;
        let hasErrors = false;
        if(!state.task.workhour_date || typeof this.state.task.workhour_date.getMonth !== 'function') {
            state.workhourDateInvalid = true;
            hasErrors = true;
        }
        const starttime = moment(task.work_starttime, 'LT');
        const endtime = moment(task.work_endtime, 'LT');
        if(!starttime || !starttime.format || 
            !endtime || !endtime.format) {
            state.workhourTimeInvalid = true;
            hasErrors = true;
        }
        if(!state.task.job_types_id || state.task.job_types_id < 1) {
            state.jobTypeInvalid = true;
            hasErrors = true;
        }

        const timeTrackerSettings = getTimeTrackerSettings(task.companies_id);

        if (timeTrackerSettings.hour_entry_description && !task.workhour_description) {
            enqueueSnackbar(this.tr("Please enter the description."), {
                variant: "error",
            });
            hasErrors = true;
            state.workhourDescriptionInvalid = true;
        }

        if(hasErrors) {
            this.setState(state);
            return;
        }
        const data = {
            job_types_id: this.state.task.job_types_id,
            date: moment(this.state.task.workhour_date).format("YYYY-MM-DD"),
            starttime: starttime.format("HH:mm"),
            endtime: endtime.format("HH:mm"),
            resources_id: this.state.task.id,
            timezone: moment.tz.guess(),
            description: this.state.task.workhour_description,
        };
        const hours = (endtime - starttime) / (1000 * 60 * 60);
        state.usersHours = state.usersHours.map(el => {
            if(el.users_id == userObject.usersId) {
                el.hours_done += hours;
                return el;
            }
            return el;
        });        
        let onSave = () => {};
        if(this.props.onSave) {
            onSave = this.props.onSave;
        } else if(this.props.onDialogSave) {
            onSave = this.props.onDialogSave;
        }
        DataHandler.post({url: 'resourcing/create_hour_entry'}, data).done((response) => {

            if (response.status === 'FAILED') {
                const { enqueueSnackbar } = this.props; 
    
                if (response.errorcode === 'PROJECT_NOT_MAIN') {
                    enqueueSnackbar(this.tr("Cannot add hour to this project because it has subprojects."), {
                        variant: "error",
                    });
                } else if (response.errorcode === 'OVERLAPS_DISABLED') {
                    enqueueSnackbar(this.tr("Hour entries can't overlap"), {
                      variant: "error",
                    });
                } else if (response.errorcode === 'INSERT_PREVENTED_IN_PREV_MONTHS') {
                    enqueueSnackbar(this.tr("Can't add hours to this month"), {
                        variant: "error",
                    });
                } else if (response.errorcode === 'DESCRIPTION_REQUIRED') {
                    enqueueSnackbar(this.tr("Description is required."), {
                        variant: "error",
                    });
                } else if (data.errorcode) {
                    enqueueSnackbar(this.tr("Saving failed!") + " " + this.tr("Error") + ": " + this.tr(data.errorcode), {
                        variant: "error",
                    });
                } else {    
                    enqueueSnackbar(this.tr("Saving failed!"), {
                        variant: "error",
                    });
                }

                return;
            }

            enqueueSnackbar(this.tr("Hour entry created"), {
                variant: "success",
            });
            onSave(state.task, state.isNew, state.usersHours, true);
            window.dispatchEvent(new Event('taskSaved'));
            this.setState(state);
        }).fail(() => {
            enqueueSnackbar(this.tr("Creating hour entry failed"), {
                variant: "error",
            });
        });
    }

    singleValueRenderer() {
        const singleValueRenderer = withStyles({
            customer: {
                display: 'block'
            },
            project: {
                display: 'block',
            },
            item: {
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                alignItems: 'baseline',

            }
        })(props => {
            return (
                <Typography className={props.classes.item}
                    buttonRef={props.innerRef}
                    selected={props.isFocused}
                    component="div"
                    style={{
                        fontWeight: props.isSelected ? 500 : 400,
                    }}
                    {...props.innerProps}
                >
                <div className={props.classes.customer}>{props.data.customer}</div>
                <div className={props.classes.project}>{props.data.parent_name ? props.data.parent_name + ' / ' +  props.data.name : props.data.name}</div>
                </Typography>
            );
        });
        return singleValueRenderer;
    }

    cancelSplit = () => {
        this.setState({showSplitDialog: null});
    }

    onSaveSplit = () => {
        this.setState({showSplitDialog: null});
        this.props.updateComponentData && this.props.updateComponentData();
        this.props.onClose && this.props.onClose();
    }

    mountNoOptions = (evt) => {
        const { selectProps, selectProps: { noOptionsProps } } = this.props;
        selectProps.mountNoOptions(selectProps, "projects_id");
    } 

    usersChanged = (usersHours, index) => {
        this.checkMaxHours(usersHours, index);
    }

    render() {
        const { userObject } = this.context;
        const { task, isNew, usersHours, maxhoursInvalid, isSaving, showSplitDialog, showVacationCheck, teams } = this.state;
        const { checkPrivilege, autoCompleteData } = this.props;

        const type = task.type ? task.type : 'task';
        const ownHour = usersHours.find(el => el.users_id == userObject.usersId && el.deleted != 1 );
        const hasOwnHours = usersHours.find(el => el.users_id == userObject.usersId && el.deleted != 1 && el.done != 1 ) != null;
        const hasDoneOwnHours = ownHour && this.state.usersHours.find(el => el.users_id == userObject.usersId && el.deleted != 1 && el.done > 0) != null;
        const hasSavedOwnHours = this.state.usersHours.find(el => el.users_id == userObject.usersId && el.deleted != 1 && el.done != 1 && el.id > 0) != null;
        const canWrite = task.$new || task.editable;
        const disableEdit = this.state.task.done|| !canWrite;

        const disableSave = !canWrite || disableEdit || maxhoursInvalid || this.state.userHoursInvalid || this.state.projectInvalid || this.state.timeInvalid;
        const isOneDayTask = !task.end_date || ((task.end_date - task.start_date) / (1000 * 60 * 60 * 24) < 1);
        const dateRange = {
            startDate: task.start_date,
            endDate: task.end_date,
        };

        const StyledTabs = withStyles({
            indicator: {
                backgroundColor: "#43A7F5"
            }
        })(Tabs);

        const StyledTab = withStyles({
            root: {
                fontSize: "14px !important",
                letterSpacing: "1px",
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                "& svg": {
                    marginRight: 5,
                },
            }
        })(Tab);

        const canHaveSubtasks = this.context.taimerAccount.useSubresources && (task.task_type == "0" || task.$new) && 'task' === type;

        const showAsDoneOptions = this.state.task.id && this.state.task.done != 1;
        const showAsUndoneOptions = this.state.task.id && this.state.task.done == 1 && canWrite;

        const hasWorkhourSection = checkPrivilege("workhours", "write") && !task.$new && isOneDayTask && hasSavedOwnHours && 'task' === type;

        const employees = (this.props.employees ? this.props.employees : this.props.autoCompleteData.employees)
        const projects = this.getSelectedProjects();
        
        let title = 'Task';
        let icon = TaskImage;

        if (type === 'task' && task.task_type === 1) {
            title = 'Project Task';
            icon = ProjectTaskImage;
        } else if (type === 'milestone') {
            title = 'Milestone';
            icon = MilestoneImage;
        }

        let trackedTotal = 0;
        let resourcedTotal = 0;

        const usersHoursFiltered = usersHours.map((v, index) => ({
            ...v,
            index,
        })).filter(row => row.deleted != 1);

        usersHoursFiltered.forEach((row, index) => {
            trackedTotal += Number(row.hours_done ?? 0) || 0;
            resourcedTotal += Number(row.hours);
        });

        return (
            <React.Fragment>
            <DialogBorder
                dialogRef={this.refDialogBorder}
                icon={icon}
                title={this.tr(title)}
                onClose={this.props.onClose}
                onSave={this.onSave}
                disableSave={disableSave || isSaving || this.state.task.done == 1}
                isSaving={isSaving}
                className={cn("TMRDialogGrid", "TMRDialogInputs", "ResourceDialog", hasWorkhourSection && 'has-workhour-section', task.$new && 'ResourceDialog-CreateNew')}
                rightButtons={
                    this.state.task.id && (
                        (!disableEdit || showAsDoneOptions || showAsUndoneOptions) && <DialogDropDownButton ref={this.dialogRef} className="white" title={this.tr("Options")}>
                            {showAsDoneOptions && hasOwnHours && <DialogDropDownButtonItem className="" onClick={this.markOwnAsDone}>
                                {this.tr("Mark own hours as done")}
                            </DialogDropDownButtonItem>}
                            {showAsDoneOptions && hasDoneOwnHours && <DialogDropDownButtonItem className="" onClick={this.markOwnAsUndone}>
                                {this.tr("Mark own hours as undone")}
                            </DialogDropDownButtonItem>}
                            {!disableEdit && showAsDoneOptions && <DialogDropDownButtonItem className="" onClick={this.markAsDone}>
                                {'task' === type ? this.tr("Mark whole task as done") : this.tr("Mark milestone as done")}
                            </DialogDropDownButtonItem>}
                            {showAsUndoneOptions && <DialogDropDownButtonItem className="" onClick={this.unlockTask}>
                                {'task' === type ? this.tr("Activate task") : this.tr("Mark milestone as undone")}
                            </DialogDropDownButtonItem>}
                            {['task'].indexOf(type) > -1 && !disableEdit && this.state.task.id && this.state.task.rrule === '' && !this.state.task.recurrence_parent_id && <DialogDropDownButtonItem onClick={this.split}>
                                {this.tr("Split")}
                            </DialogDropDownButtonItem>}    
                            {['task', 'milestone'].indexOf(type) > -1 && !disableEdit && this.state.task.id && <DialogDropDownButtonItem className="danger" onClick={this.delete}>
                                {this.tr("Delete")}
                            </DialogDropDownButtonItem>}
                        </DialogDropDownButton>
                    )
                }>
                {isNew && (
                        <StyledTabs
                            value={type}
                            centered>
                            <StyledTab value="task" label={<><TaskImageIcon /> {this.tr("Task")}</>} onClick={() => this.switchTab("task")} />
                            {!this.state.task.onlyTask && (<StyledTab value="milestone" label={<><MilestoneImageIcon /> {this.tr("Milestone")}</>} onClick={() => this.switchTab("milestone")} />)}
                            {/*<StyledTab value="topic" label="Topic" onClick={() => this.switchTab("topic")} /> */}
                        </StyledTabs>
                    )
                }

                <div class="section-container horizontal limited-height"> 

                    <DialogSection>
                        <h3 className="section-header full">{this.tr("Task details")}</h3>

                        {!this.props.massEditMode && <ProjectTreeDropdown  
                            label={this.tr("Project")}
                            value={task.projects_id}
                            treeDropdownProps={{
                                resetOnClickaway: true,
                                highlightMatches: true
                            }}
                            disabled={this.props.singleProject || disableEdit}
                            disableAutoSelect
                            error={this.state.projectInvalid}
                            noOptionsMessage={AddProject}
                            noOptionsMessageProps={{
                                selectProps: {
                                    onItemCreated: this.onChange
                                }
                            }}
                            customer={task.customer_name && {name: task.customer_name}}
                            project={task.project_name && {name: task.project_name}}
                            queryParameters={{
                                resource_note: 1, 
                                right: 'project_resourcing_write own_resourcing_write',
                                includeCurrent: (task && task.projects_id) ?? -1,
                            }}
                            onSelect={e => {
                                const { data = e } = e;
                              
                                const add_projects = _.cloneDeep(this.state.add_projects);

                                if(task.$new && data.id > 0 && !add_projects.find(el => el && el.id == data.id)) {
                                    add_projects.push(data)
                                }

                                this.setState({
                                    showVacationCheck: add_projects.every(x => x.type == 3),
                                    projectInvalid: false,
                                    allTasks: this.allTasks.filter(el => el.id === 0 || el.projects_id == data.id || el.id === task?.original_parent?.substr?.(2)),
                                    add_projects
                                });

                                this.setValue('projects_id', data.id);

                                if (this.context.taimerAccount.useSubresources && !task.$new && type === 'task') {
                                    DataHandler.get({url: `resourcing/task/${task.task_id}/subtasks_count`}).then(response => {
                                        if (response.count > 0) {
                                            this.setState({
                                                projectInvalid: false,
                                                showConfirmationDialog: true,
                                                confirmationRecurringTask: false,
                                                moveSubtasks: false,
                                                confirmationDialogText: this.tr("Do you want to also move subtasks to new project?"),
                                                confirmationDialogSaveFunc: () => {
                                                    
                                                    this.setState({moveSubtasks: true, showConfirmationDialog: false});
        
                                                },
                                                confirmationDialogExtra: {
                                                    cancelText: this.tr("No"),
                                                },
                                            });
                                        }
                                    });
                                }
                            }} />}

                        {
                            task.$new && !this.props.singleProject && (
                                <div className="add-multi-projects full">
                                    {
                                        this.state.add_projects.map(el => 
                                            {
                                                return el && (
                                                    <span className="project">
                                                        <span className="name">{el.label}</span>
                                                        <span className="remove" onClick={() => {
                                                            const add_projects = this.state.add_projects.filter(p => p.id !== el.id);
                                                            this.setState({add_projects, showVacationCheck: add_projects.length > 0 && add_projects.every(x => x.type == 3)});
                                                        }}>X</span>
                                                    </span>
                                                )
                                            }
                                        )
                                    }
                                </div>
                            )
                        }

                        {(!autoCompleteData.resourcing_disable_skill && this.state.skills.length > 0) && <DataList
                            disabled={disableEdit || this.state.task.done == 1}
                            isDisabled={disableEdit || this.state.task.done == 1}
                            dropLabel={this.tr("Skill")}
                            options={this.state.skills}
                            onChange={(obj) => this.setValue('skills_id', obj.id)}
                            fullWidth={true}
                            value={this.state.skill}
                            className="full"
                            customSingleValue={SingleValueInfo}
                        />}
                        {
                            canHaveSubtasks && (
                            <TreeDropdown
                                label={this.tr("Parent task")}
                                value={typeof task.original_parent == "string" ? task.original_parent.substr(2) : false}
                                data={this.state.allTasks}
                                parentKey={"original_parent"}
                                resetOnClickaway
                                disabled={disableEdit}
                                onSelect={
                                    (e) => {
                                        const { data } = e;
                                        if(data) {
                                            this.setValue('original_parent', "r-" + e.id);
                                        }
                                    }
                                }
                                onErase={() => {
                                    this.setValue('original_parent', undefined);
                                }}
                            />)
                        }

                        <OutlinedField
                            disabled={disableEdit || this.state.task.done}
                            className="full"
                            label={this.tr("Description")}
                            name="description"
                            onChange={this.onChange}
                            value={task.description}
                            error={this.state.descriptionInvalid}
                             />

                        { type === 'task' && (
                            <OutlinedField
                                multiline
                                disabled={disableEdit || this.state.task.done}
                                className="full"
                                label={this.tr("Note")}
                                name="additional_description"
                                onChange={this.onChange}
                                value={task.additional_description}
                                 />
                            )
                        }

                        {!autoCompleteData.resourcing_disable_priority && <DataList
                            disabled={disableEdit || this.state.task.done}
                            isDisabled={disableEdit || this.state.task.done}
                            dropLabel={this.tr("Priority")}
                            options={this.state.priorities}
                            onChange={(obj) => this.setValue('priorities_id', obj.id)}
                            fullWidth={true}
                            value={this.state.priority}
                            customSingleValue={SingleValuePriority}
                            customOption={CustomOptionPriority}
                            className="full"
                        />}

                        <div className="section-separator first full" />

                        {(type === 'task' || type === 'topic') &&
                            <React.Fragment>
                                <DateRangePicker
                                    disabled={disableEdit || this.state.task.done}
                                    className={isOneDayTask ? "is-one-day" : ''}
                                    label={this.tr("Date Range")}
                                    name="daterange"
                                    ranges={[dateRange]}
                                    onChange={this.changeDateRange}
                                    onInputChange={(type, dd) => {
                                        const start = type === 'start' ? dd : this.state.task.start_date;
                                        const end = type === 'end' ? dd : this.state.task.end_date;
                                        this.changeDateRange({range1: {startDate: start, endDate: end}});
                                    }}
                                    dateFormat={userObject.dateFormat}
                                />
                                {!disableEdit && <div className="half book-hours">
                                    <span>{this.tr("or")}</span>
                                    {
                                        isOneDayTask ?
                                            (<div onClick={() => {
                                                const start = new Date();
                                                const end = new Date();
                                                end.setDate(end.getDate() + 7);
                                                this.changeDateRange({range1: {startDate: start, endDate: end}});
                                            }}
                                            >{this.tr("Book Specific Time")}</div>)
                                            :
                                            (<div onClick={() => {
                                                const start = new Date();
                                                const end = new Date();
                                                this.changeDateRange({range1: {startDate: start, endDate: end}});
                                            }}>{this.tr("Book Specific Hours")}</div>)
                                    }
                                </div>}
                            </React.Fragment>
                        }


                        {(type === 'task' || type === 'topic' ) && !isOneDayTask && 
                            <OutlinedField
                                className={"full maxhours"  + (this.state.maxhoursInvalid ? ' error' : '')}
                                label={this.tr("Allocated Hours")}
                                name="hours"
                                error={this.state.maxhoursInvalid}
                                ref={this.hoursRef}
                                onChange={this.onChange}
                                onInput={(evt) => this.checkMaxHours(this.state.usersHours, undefined, Number(evt.target.value.replace(',','.')))}
                                value={task.hours}
                                disabled={disableEdit || (this.state.showVacationCheck && task.is_full_vacation > 0)} />}

                        {(type === 'task' || type === 'topic' ) && isOneDayTask &&
                            <TimeRangeOutlinedField
                                rootClass="time-range full"
                                disabled={disableEdit || this.state.task.done || (this.state.showVacationCheck && task.is_full_vacation > 0)}
                                label={this.tr("Time")}
                                name="time"
                                error={this.state.timeInvalid}
                                onChange={this.changeTime}
                                value={this.state.timeRange} />
                        }

                        {type === 'milestone' &&
                            <DatePicker
                                disabled={disableEdit || this.state.task.done}
                                className="date"
                                label={this.tr("Date")}
                                name="date"
                                date={task.start_date}
                                onChange={(date) => this.setValue('start_date', date)}
                                onInputChange={(type, date) => this.setValue('start_date', date)}
                                dateFormat={userObject.dateFormat}
                            />
                        }

                        {type === 'milestone' &&
                            <TimeOutlinedField
                                disabled={disableEdit || this.state.task.done}
                                label={this.tr("Time")}
                                name="time"
                                type="time"
                                value={task.time}
                                onChange={this.onChange}
                            />
                        }
                        {
                            type === 'task' && (this.state.showVacationCheck || task.is_full_vacation > 0) && (
                                <Checkbox 
                                    label={this.tr("Is full vacation")}
                                    checked={task.is_full_vacation > 0}
                                    disabled={disableEdit}
                                    onChange={(value) => {
                                        this.setValue('is_full_vacation', value.target.checked ? 1 : 0);
                                    }}
                                />
                            )
                        }

                        <div className="resource-buttons">
                            {(type === 'task' && !isOneDayTask) && <div className="update-hours-button" onClick={this.hoursFromEntries}><RefreshIcon /> {this.tr('Update resourced h')}</div>}

                            {(type === 'task' || type === 'milestone' ) && !disableEdit && (
                                <div 
                                    className={"repeat-button"}
                                    onClick={this.openRecurrenceDialog}>
                                    <Repeat />
                                    {
                                        this.state.task.rrule ? this.rruleToHumaReadable(this.state.task.rrule) : this.tr("Repeat")
                                    }
                                </div>
                            )}
                        </div>

                        <div className="section-separator second full" />

                        {type === 'task' && <ResourceDialogUsers 
                            className='full'
                            teams={teams}
                            projects={projects}
                            employees={employees}
                            task={task} 
                            disableEdit={disableEdit}
                            showVacationCheck={showVacationCheck}
                            userHours={usersHours}
                            onChange={this.usersChanged}
                            />}

                        {type === 'milestone' && <>
                            <DataList
                                disabled={disableEdit || this.state.task.done}
                                isDisabled={disableEdit || this.state.task.done}
                                dropLabel={this.tr("Assigned to")}
                                options={this.state.employees}
                                shownCount={20}
                                onChange={(obj) => this.setValue('users_id', obj.id)}
                                fullWidth={true}
                                value={this.state.user}
                                className="full"
                                name="assigned_to"
                            />

                        </>}
                    </DialogSection>

                    { hasWorkhourSection && (                        
                        <DialogSection
                            className="workhour"
                        >
                            <h3 className="section-header">{this.tr("Log hours")}</h3>
                            <Clock className="clock-icon" />
                            <h3 
                                className="hours-header HasTooltip" 
                                title={
                                    this.tr("Add hours to your task here. Choose the actual timespan for the hours worked, jobtype and click on the green button to log your hours. Click save to continue working on your task. You can even add multiple hour entries to this task.") + "\r\n\r\n" + this.tr("If you wish to mark the task as done, click on options and choose the specific action.")
                                }
                            >
                                {this.tr("Edit and log hours by clicking on green button")}
                            </h3>
                            <DatePicker
                                className="date full"
                                label={this.tr("Completion Date")}
                                name="workhour_date"
                                date={task.workhour_date}
                                error={this.state.workhourDateInvalid}
                                onChange={(date) => this.setValue('workhour_date', date)}
                                onInputChange={(date) => this.setValue('workhour_date', date)}
                                dateFormat={userObject.dateFormat}
                            />
                            <TimeRangeOutlinedField
                                rootClass="time-range full"
                                label={this.tr("Actual Hours")}
                                name="time_workhours"
                                error={this.state.workhourTimeInvalid}
                                onChange={this.changeWorkTime}
                                value={this.state.workourTimeRange} />
                            <DataList
                                dropLabel={this.tr("Job Type")}
                                options={this.state.jobTypes}
                                onChange={(obj) => this.setValue('job_types_id', obj.id)}
                                fullWidth={true}
                                value={this.state.jobType}
                                className="full"
                                error={this.state.jobTypeInvalid}
                                name="job_type"
                            />
                            <OutlinedField
                                // disabled={disableEdit || this.state.task.done == 1}
                                className="full"
                                label={this.tr("Workhour Description")}
                                name="text"
                                // onChange={this.onChange}
                                onChange={({target: {value}}) => this.setValue('workhour_description', value)}
                                value={task.workhour_description}
                                error={this.state.workhourDescriptionInvalid}
                                />

                            <div
                                className="TMRDialogButton green log-hours-button"
                                onClick={this.createHourEntry}
                            >
                                {this.tr("Log hours")}
                            </div>
                        </DialogSection>
                    )}
                </div>
                    
            </DialogBorder>

            <SplitTaskDialog 
                open={showSplitDialog} 
                onCancel={this.cancelSplit}
                onSave={this.onSaveSplit}
                task={showSplitDialog ? this.state.task : undefined} />
		{
                this.state.showRecurrenceDialog && (
                    <ResourceRecurrenceDialog 
                        onSave={(rule) => this.recurrenceSave(rule)}
                        rrule={this.state.task.rrule ? this.state.task.rrule : ''}
                        onClose={() => this.setState({showRecurrenceDialog: false})}
                    />  
                )
                            
            }
            {
                this.state.showConfirmationDialog && (
                    <ConfirmationDialog 
                        data={{
                            text: this.state.confirmationDialogText,
                            cancelText: this.state.confirmationDialogExtra?.cancelText,
                        }}
                        onDialogClose={() => this.setState({showConfirmationDialog: false})}
                        onDialogSave={this.state.confirmationDialogSaveFunc}
                    >   
                            {this.state.confirmationRecurringTask &&
                                <RadioGroup
                                    name="resource-edit-recurrence"
                                    value={this.state.confirmationDialogRadio}
                                    onChange={this.handleConfirmationRadio}
                                >
                                    <FormControlLabel
                                        value="this"
                                        label={this.tr("Only this")}
                                        labelPlacement="start"
                                        control={<Radio />}
                                    />
                                    <FormControlLabel
                                        value="future"
                                        label={this.tr("Future")}
                                        labelPlacement="start"
                                        control={<Radio />}
                                    />
                                    <FormControlLabel
                                        value="all"
                                        label={this.tr("All")}
                                        labelPlacement="start"
                                        control={<Radio />}
                                    />
                                </RadioGroup>
                            }
                    </ConfirmationDialog>
                )
                            
            }
            </React.Fragment>
        );
    }
});
