import React from "react";

import { SettingsContext } from "../../../SettingsContext";
import TaimerComponent from "../../../TaimerComponent";

import { BlockProps, SubitemsProps } from "../../base/CustomViewBase";
import ChartBlock from "./ChartBlock";
import StackedChart, { Dataset } from "./StackedChart";
import ChartTable from "./ChartTable";

import styles from './MaterialChart.module.scss';
import { MaterialCostsWithDate } from "../../../projects/ProjectStatistics";
import InsightDropDown from "../../../dashboard/insights/InsightDropDown";
import moment from "moment";
import startOfMonth from "date-fns/startOfMonth/index.js";
import endOfMonth from "date-fns/endOfMonth/index.js";
import { addMonths, getMonth, getQuarter, getYear } from "date-fns";
import { orderBy, padStart, sum, values } from "lodash";
import colors from "../../../colors";
import _ from "lodash";
import { AllowedGrouping, getKeyFromDate } from "./GroupingHelpers";

interface Props extends SubitemsProps {
    blockProps: BlockProps;

    earliestDate: Date;
    latestDate: Date;
    materialByDate: MaterialCostsWithDate[];
    currencyFormatter: (val: number) => string;
}

interface State {
    grouping: AllowedGrouping;
}

type Datasets = Record<'hours'|'expenses'|'traveling_expenses'|'bills'|'quotes'|'scheduled_invoices'|'automatic_invoices', Dataset>;

export default class MaterialChart extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    monthlyLabels: string[];

    constructor(props, context) {
        super(props, context, "customview/blocks/Chart/MaterialChart");

        const { tr } = this;

        this.monthlyLabels =  [
            tr("Jan"),
            tr("Feb"),
            tr("Mar"),
            tr("Apr"),
            tr("May"),
            tr("Jun"),
            tr("Jul"),
            tr("Aug"),
            tr("Sep"),
            tr("Oct"),
            tr("Nov"),
            tr("Dec")
        ];

        this.state = {
            grouping: 'month', // todo audo detect based on data
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
    }

    setGrouping = (grouping: AllowedGrouping) => {
        this.setState({
            grouping
        });
    }

    renderToolbar = () => {
        const { grouping } = this.state;

        return (<>
            <InsightDropDown
                title={this.tr('View')}
                selected={grouping}
                tabs={[
                    {
                        key: 'month',
                        label: this.tr('By Month'),
                        action: () => this.setGrouping('month'),
                    },
                    {
                        key: 'quarter',
                        label: this.tr('By Quarter'),
                        action: () => this.setGrouping('quarter'),
                    },
                    {
                        key: 'year',
                        label: this.tr('By Year'),
                        action: () => this.setGrouping('year'),
                    },
                ]} />
        </>);
    }

    render() {
        const { blockProps, materialByDate, earliestDate, latestDate, currencyFormatter } = this.props;
        const { grouping } = this.state;
        const { tr } = this;

        const start = startOfMonth(earliestDate);
        const end = endOfMonth(latestDate);

        // To ensure no gaps in graph
        let date = start;

        const labels: string[] = [];
        const keyToIndex: Record<string, number> = {};
        let i = 0;

        while (date <= end) {
            const key = getKeyFromDate(date, grouping);

            if (keyToIndex[key] !== undefined) {
                date = addMonths(date, 1);
                continue;
            }

            keyToIndex[key] = i;

            if (grouping === 'quarter') {
                labels.push(`${getYear(date)} Q${getQuarter(date)}`);
            } else if (grouping === 'month') {
                labels.push(`${this.monthlyLabels[getMonth(date)]} ${getYear(date)}`);
            } else if (grouping === 'year') {
                labels.push(`${getYear(date)}`);
            }

            date = addMonths(date, 1);
            i++;
        }

        const sets: Datasets = {
            traveling_expenses: {
                label: tr("Travel Expenses"),
                data: new Array(labels.length).fill(0),
                backgroundColor: 'rgb(247, 84, 143)',
            },
            expenses: {
                label: tr("Purchase Expenses"),
                data: new Array(labels.length).fill(0),
                backgroundColor: 'rgb(45, 159, 247)',
            },
            hours: {
                label: tr("Hours"),
                data: new Array(labels.length).fill(0),
                backgroundColor: 'rgb(200, 143, 251)',
            },
            bills: {
                label: tr("Bills"),
                data: new Array(labels.length).fill(0),
                backgroundColor: 'rgb(76, 75, 168)',
            },
            scheduled_invoices: {
                label: tr("Scheduled Invoices"),
                data: new Array(labels.length).fill(0),
                backgroundColor: 'rgb(32, 198, 161)',
            },
            automatic_invoices: {
                label: tr("Automatic Invoices"),
                data: new Array(labels.length).fill(0),
                backgroundColor: 'rgb(123, 49, 10)',
            },
            quotes: {
                label: tr("Quote"),
                data: new Array(labels.length).fill(0),
                backgroundColor: 'rgb(92, 78, 172)',
            },
        };


        for (const row of materialByDate) {
            const key = getKeyFromDate(row.date, grouping);
            const index = keyToIndex[key] ?? -1;

            if (index < 0) {
                continue;
            }

            sets.automatic_invoices.data[index] += row.automatic_invoices;
            sets.bills.data[index] += row.bills;
            sets.expenses.data[index] += row.expenses;
            sets.hours.data[index] += row.hours;
            sets.quotes.data[index] += row.quotes;
            sets.scheduled_invoices.data[index] += row.scheduled_invoices;
            sets.traveling_expenses.data[index] += row.traveling_expenses;
        }

        const datasets = _.chain(sets)
            .map(x => ({
                ...x,
                sum: sum(x.data)
            }))
            .orderBy(x => -x.sum)
            .value();

        return (<ChartBlock
            className={styles.root}
            blockProps={blockProps}
            tableType={ChartTable}
            tableProps={{
                className: styles.table,
                datasets,
                setColumnTitle: this.tr('Type'),
                valueColumnTitle: this.tr('Amount'),
                formatValue: currencyFormatter,
            }}
            chartType={StackedChart}
            chartProps={{
                className: styles.graph,
                datasets,
                labels,
                formatValue: currencyFormatter,
            }}
            toolbarComponent={this.renderToolbar()}
            />);
    }
}