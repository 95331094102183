import { AddCircleOutline, Delete, ImageSearch, KeyboardArrowLeft } from '@mui/icons-material';
import { LinearProgress, Tooltip } from '@mui/material';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';
import TaimerComponent from '../TaimerComponent';
import InvoiceTranslations from './backendTranslations/InvoiceTranslations';
import DataHandler from './DataHandler';
import OutlinedField from './OutlinedField';
import styles from './SetupAccountWizard.module.scss';
import { convertDateFormatToPHP } from '../invoices/InvoiceView';
import TaimerWizard, { TaimerWizardPage } from './TaimerWizard';
import Dialog from '../dialogs/mass_operations/CoreDialog';
import $ from 'jquery';
import Utils from './Utils';
import VersionContentManager from './VersionContentManager';

interface State {
    item: any;
    rows: any;
    countries: any[];
    uploadingLogo?: boolean;
    previewLogo?: any;
    invoicePreviewStyle?: 'addressFocus' | 'bankAccountFocus' | 'logoFocus' | 'clientFocus' | 'rowFocus';
    projectName?: string;
    logoUrl?: string;
    logoLoadError?: boolean;
    useCountryList?: boolean;
}

interface LogoUploadProps extends WithSnackbarProps {
    onLogoUpload: (previewFile: any) => void;
}

interface LogoUploadState {
    file?: string;
}

class LogoUpload extends TaimerComponent<LogoUploadProps, LogoUploadState> {
    _upload: any = React.createRef();

    constructor(props, context) {
        super(props, context, 'general/SetupAccountWizard/LogoUpload');
        this.state = {
            file: undefined,
        };
    }

    onUploadClicked = () => {
        this._upload.current && this._upload.current.click();
    };

    uploadLogo = (e) => {
        const { enqueueSnackbar } = this.props;
        const {
            taimerAccount: { attachmentMaxSize },
        } = this.context;
        e.stopPropagation();
        e.preventDefault();
        const file = (e.target.files || e.dataTransfer.files)[0];
        if (file.size > attachmentMaxSize) {
            enqueueSnackbar(this.tr('Selected file is too large'), {
                variant: 'error',
            });
            return;
        } else if (file.type != 'image/png' && file.type != 'image/jpeg') {
            enqueueSnackbar(this.tr('Only jpeg and png are supported'), {
                variant: 'error',
            });
            return;
        } else if (file.size > 10 * 1024 * 1024) {
            // 10 MB
            enqueueSnackbar(this.tr('File is too large (max 10 MB)'), {
                variant: 'error',
            });
            return;
        }
        if (!file) return;
        this.props.onLogoUpload(file);
        this.setState({ file: file.name });
    };

    onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
    };

    render() {
        const { file } = this.state;
        return (
            <div className={styles.logoUpload}>
                <div className={styles.container} onDragOver={this.onDragOver} onDrop={this.uploadLogo}>
                    <ImageSearch />
                    {file ? (
                        <div className={styles.selectedFile}>
                            <p>{file}</p>
                            <p>{this.htmlTr('Drag or ${upload} another logo', { upload: <button onClick={this.onUploadClicked}>{this.tr('upload')}</button> })}</p>
                        </div>
                    ) : (
                        <p>{this.htmlTr('Drag or ${upload} your logo', { upload: <button onClick={this.onUploadClicked}>{this.tr('upload')}</button> })}</p>
                    )}
                </div>
                {file && <p>{this.tr('Perfect! Now we have everything we need from your side to create the first test invoice.')}</p>}
                <input ref={this._upload} onChange={this.uploadLogo} type="file" accept="image/png,image/jpeg" />
            </div>
        );
    }
}

interface Props extends WithSnackbarProps {
    sliderMode?: boolean;
}

class SetupAccountWizard extends TaimerComponent<Props, State> {
    pages: TaimerWizardPage[];
    wizard: any;
    fieldRefs = {};
    rowFields: any = [];
    projectCount = 0;
    bankAccountId = -1;
    constructor(props, context) {
        super(props, context, 'general/SetupAccountWizard');
        this.wizard = React.createRef();
        const names = (this.context.userObject.fullname || '').split(' ').reverse();
        this.pages = this.getPages({});
        this.rowFields = [
            {
                key: 'topic',
                label: this.tr('Product or service'),
                type: 'text',
                autoFocus: true,
            },
            {
                key: 'quantity',
                label: this.tr('Quantity'),
                type: 'text',
                formatValue: this.formatNumberField,
                afterEdit: this.afterNumberFieldEdit,
            },
            {
                key: 'unitCost',
                label: this.tr('Unit cost'),
                type: 'text',
                formatValue: this.formatNumberField,
                afterEdit: this.afterNumberFieldEdit,
            },
            {
                key: 'vat',
                label: this.tr('VAT'),
                type: 'text',
                formatValue: this.formatNumberField,
                afterEdit: this.afterNumberFieldEdit,
                placeholder: 24,
            },
        ];

        this.state = {
            item: {
                recipient: this.context.userObject.email,
                message: this.tr("Hi ${receiver}, thank you for the cooperation.${linebreak}Here's your test invoice. BR, ${sender}.", {
                    receiver: names[0],
                    sender: names[0],
                    linebreak: '\r\n\r\n'
                }),
            },
            rows: [],
            countries: [],
            uploadingLogo: false,
            logoUrl: this.getLogoUrl(),
            useCountryList: false,
        };
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.getCompanyAddress();
        this.getBankAccount();
        this.getProjectCount();
    }

    getPages = (state: any = this.state): TaimerWizardPage[] => {
        const names = (this.context.userObject.fullname || '').split(' ').reverse();
        return [
            {
                stepParam: 'welcome',
                title: this.tr('Welcome'),
                content: {
                    left: {
                        enableEnter: true,
                        alignVertical: 'center',
                        header: this.tr("Let's setup your account by creating a test invoice."),
                        subheader: this.tr('Welcome, ${fullname}! Great to have you.', { fullname: names.join(' ') }),
                        action: {
                            label: this.tr("Let's setup your account"),
                            action: 'next',
                        },
                    },
                    main: {
                        image: require('./icons/wizard_visu.svg').default,
                    },
                },
                layout: 'even',
            },
            {
                layout: 'even',
                title: this.tr('Company address'),
                content: {
                    main: this.renderInvoicePreview,
                    left: {
                        enableEnter: true,
                        alignVertical: 'center',
                        subheader: this.tr('First things first,'),
                        header: this.tr('Where is your company located?'),
                        stepParam: 'geo',
                        fields: [
                            {
                                key: 'address',
                                label: this.tr('Your company address'),
                                type: 'text',
                                useOnKeyUp: true,
                                required: true,
                                onFocus: () => {
                                    this.setState({ invoicePreviewStyle: 'addressFocus' });
                                },
                            },
                            {
                                key: 'postalCode',
                                label: this.tr('Postal code'),
                                type: 'text',
                                useOnKeyUp: true,
                                required: true,
                                onFocus: () => {
                                    this.setState({ invoicePreviewStyle: 'addressFocus' });
                                },
                            },
                            {
                                key: 'city',
                                label: this.tr('City'),
                                type: 'text',
                                useOnKeyUp: true,
                                required: true,
                                onFocus: () => {
                                    this.setState({ invoicePreviewStyle: 'addressFocus' });
                                },
                            },
                            this.context.taimerAccount.showState && {
                                key: 'state',
                                label: this.tr('State'),
                                type: 'text',
                                useOnKeyUp: true,
                                required: true,
                                onFocus: () => {
                                    this.setState({ invoicePreviewStyle: 'addressFocus' });
                                },
                            },
                            {
                                key: 'country',
                                label: this.tr('Country'),
                                type: state.useCountryList ? 'select' : 'text',
                                options: state.countries,
                                getSelectValue: (item, options) => {
                                    return options.find((o) => o.label == item.country);
                                },
                                formatSelectValue: (value) => value.label,
                                useOnKeyUp: true,
                                required: true,
                                onFocus: () => {
                                    this.setState({ invoicePreviewStyle: 'addressFocus' });
                                },
                            },
                        ],
                        action: {
                            label: this.tr('Add your bank account'),
                            action: this.onCompanyAddressSet,
                        },
                    },
                },
            },
            {
                layout: 'even',
                title: this.tr('Bank details'),
                content: {
                    main: this.renderInvoicePreview,
                    left: {
                        enableEnter: true,
                        alignVertical: 'center',
                        subheader: this.tr('Great! Next,'),
                        header: this.tr('Make sure you get paid!'),
                        subtitle: this.tr('You can also add your bank details later on.'),
                        stepParam: 'bank',
                        fields: [
                            {
                                key: 'bankName',
                                label: this.tr('Your bank name'),
                                type: 'text',
                                useOnKeyUp: true,
                                onFocus: () => {
                                    this.setState({ invoicePreviewStyle: 'bankAccountFocus' });
                                },
                            },
                            {
                                key: 'bankAccount',
                                label: this.tr('Bank account nr.'),
                                type: 'text',
                                useOnKeyUp: true,
                                onFocus: () => {
                                    this.setState({ invoicePreviewStyle: 'bankAccountFocus' });
                                },
                            },
                            {
                                key: 'swift',
                                label: this.tr('BIC/SWIFT'),
                                type: 'text',
                                useOnKeyUp: true,
                                onFocus: () => {
                                    this.setState({ invoicePreviewStyle: 'bankAccountFocus' });
                                },
                            },
                        ],
                        action: {
                            label: this.tr('Next add your branding'),
                            action: this.onBankAccountSet,
                        },
                    },
                },
            },
            {
                layout: 'even',
                title: this.tr('Customization'),
                content: {
                    main: this.renderInvoicePreview,
                    left: {
                        enableEnter: true,
                        alignVertical: 'center',
                        subheader: this.tr('Payment details set. Now get creative,'),
                        header: this.tr('Make it look like you!'),
                        stepParam: 'logo',
                        fields: [
                            {
                                key: 'logo',
                                component: <LogoUpload enqueueSnackbar={this.props.enqueueSnackbar} closeSnackbar={this.props.closeSnackbar} onLogoUpload={this.onLogoUpload} />,
                            },
                        ],
                        action: {
                            label: this.tr('Add your first client'),
                            action: () => {
                                this.setState({ invoicePreviewStyle: undefined }, () => {
                                    this.wizard.current && this.wizard.current.nextPage();
                                });
                            },
                        },
                    },
                },
            },
            {
                layout: 'even',
                title: this.tr('Your first client'),
                content: {
                    main: this.renderInvoicePreview,
                    left: {
                        enableEnter: true,
                        alignVertical: 'center',
                        subheader: this.tr('To be able to create a test invoice, you need a client.', { firstname: names[0] }),
                        header: this.tr('Add your first client'),
                        stepParam: 'client',
                        fields: [
                            {
                                key: 'clientName',
                                label: this.tr('Client name'),
                                type: 'text',
                                placeholder: this.tr('Demo client'),
                                useOnKeyUp: true,
                                onFocus: () => {
                                    this.setState({ invoicePreviewStyle: 'clientFocus' });
                                },
                            },
                            {
                                key: 'clientAddressHeader',
                                label: this.tr('Add client address details'),
                                type: 'optionalFields',
                                fields: [
                                    {
                                        key: 'clientAddress',
                                        label: this.tr('Client company address'),
                                        type: 'text',
                                        useOnKeyUp: true,
                                        onFocus: () => {
                                            this.setState({ invoicePreviewStyle: 'clientFocus' });
                                        },
                                    },
                                    {
                                        key: 'clientPostalCode',
                                        label: this.tr('Postal code'),
                                        type: 'text',
                                        useOnKeyUp: true,
                                        onFocus: () => {
                                            this.setState({ invoicePreviewStyle: 'clientFocus' });
                                        },
                                    },
                                    {
                                        key: 'clientCity',
                                        label: this.tr('City'),
                                        type: 'text',
                                        useOnKeyUp: true,
                                        onFocus: () => {
                                            this.setState({ invoicePreviewStyle: 'clientFocus' });
                                        },
                                    },
                                    this.context.taimerAccount.showState && {
                                        key: 'clientState',
                                        label: this.tr('State'),
                                        type: 'text',
                                        useOnKeyUp: true,
                                        onFocus: () => {
                                            this.setState({ invoicePreviewStyle: 'clientFocus' });
                                        },
                                    },
                                    {
                                        key: 'clientCountry',
                                        label: this.tr('Country'),
                                        type: 'select',
                                        options: state.countries,
                                        getSelectValue: (item, options) => {
                                            return options.find((o) => o.label == item.clientCountry);
                                        },
                                        formatSelectValue: (value) => value.label,
                                        useOnKeyUp: true,
                                        onFocus: () => {
                                            this.setState({ invoicePreviewStyle: 'clientFocus' });
                                        },
                                    },
                                    {
                                        key: 'clientVatId',
                                        label: this.tr('Business ID'),
                                        type: 'text',
                                        useOnKeyUp: true,
                                        onFocus: () => {
                                            this.setState({ invoicePreviewStyle: 'clientFocus' });
                                        },
                                    },
                                    {
                                        key: 'clientContact',
                                        label: this.tr('Contact name'),
                                        type: 'text',
                                        useOnKeyUp: true,
                                        onFocus: () => {
                                            this.setState({ invoicePreviewStyle: 'clientFocus' });
                                        },
                                    },
                                ],
                            },
                        ],
                        action: {
                            label: this.tr('Add your first project'),
                            action: () => {
                                this.wizard.current && this.wizard.current.nextPage();
                            },
                        },
                    },
                },
            },
            {
                layout: 'even',
                title: this.tr('Project details'),
                content: {
                    main: this.renderInvoicePreview,
                    left: {
                        enableEnter: true,
                        alignVertical: 'center',
                        subheader: this.tr("Let's create a project for your client", { firstname: names[0] }),
                        header: this.tr('Add your first project'),
                        stepParam: 'client',
                        fields: [
                            {
                                key: 'projectName',
                                label: this.tr('Name your project'),
                                placeholder: this.tr('Demo project'),
                                type: 'text',
                                onFocus: () => {
                                    this.setState({ invoicePreviewStyle: 'clientFocus' });
                                },
                            },
                        ],
                        action: {
                            label: this.tr('Add items to invoice'),
                            action: () => {
                                this.setState({ invoicePreviewStyle: undefined }, () => {
                                    this.wizard.current && this.wizard.current.nextPage();
                                    if (this.state.rows.length == 0) {
                                        this.addRow(true);
                                    }
                                });
                            },
                        },
                    },
                },
            },
            {
                layout: 'even',
                title: this.tr('Test invoice rows'),
                content: {
                    main: this.renderInvoicePreview,
                    left: {
                        enableEnter: true,
                        alignVertical: 'center',
                        subheader: this.tr('Now we know who to invoice! Finally,'),
                        header: this.tr('Describe what you did'),
                        stepParam: 'items',
                        customContent: this.renderRowEditor,
                        action: {
                            label: this.tr('Next compose a message'),
                            action: () => {
                                this.setState({ invoicePreviewStyle: undefined });
                                this.wizard.current && this.wizard.current.nextPage();
                            },
                        },
                    },
                },
            },
            {
                layout: 'even',
                title: this.tr('Compose email'),
                content: {
                    main: this.renderInvoicePreview,
                    left: {
                        alignVertical: 'center',
                        subtitle: this.tr("Let's send the first test invoice to your email, so you'll see how it works!"),
                        header: this.tr("Nice one! Now let's send it."),
                        stepParam: 'email',
                        fields: [
                            {
                                key: 'recipient',
                                label: this.tr('Email recipient'),
                                required: true,
                                type: 'text',
                            },
                            {
                                key: 'message',
                                label: this.tr('Write a short message'),
                                type: 'textarea',
                            },
                        ],
                        action: {
                            label: this.tr("All done! Let's send it"),
                            action: () => {
                                this.sendInvoice();
                            },
                        },
                    },
                },
            },
            {
                layout: 'even',
                title: this.tr('Sending test invoice'),
                noReturn: true,
                content: {
                    main: {
                        image: require('./icons/wizard_visu3.svg').default,
                    },
                    left: {
                        alignVertical: 'center',
                        header: this.tr('How easy was that!'),
                        subtitle: this.tr(
                            'With your permission we and our partners may use precise geolocation data and identification through device scanning. You may click to consent to our and our partners’ processing as described above. '
                        ),
                        stepParam: 'email-sending',
                        customContent: () => {
                            return (
                                <div className={styles.progress}>
                                    <LinearProgress />
                                </div>
                            );
                        },
                    },
                },
            },
            {
                layout: 'even',
                title: this.tr('Complete'),
                noReturn: true,
                content: {
                    main: {
                        image: require('./icons/wizard_visu4.svg').default,
                    },
                    left: {
                        alignVertical: 'center',
                        subheader: this.tr('Your invoice was sent,'),
                        header: this.tr("Let's start exploring!"),
                        stepParam: 'email-sent',
                        action: {
                            label: this.tr('Take me to the dashboard'),
                            action: 'close',
                        },
                    },
                },
            },
        ];
    };

    getCountries = async () => {
        const response = await DataHandler.get({ url: 'onboarding/locales/' });
        const countries = response.countries || [];
        let item = cloneDeep(this.state.item);
        const currentCountry = countries.find((c) => c.label == item.country) || countries.find((c) => !item.country && c.id == this.context.taimerAccount.countryCode.toLowerCase());
        let useCountryList = false;
        if (currentCountry) {
            useCountryList = true;
            item = {
                ...item,
                country: currentCountry.label,
            };
        }
        this.pages = this.getPages({ ...this.state, item, useCountryList, countries });
        this.setState({ countries, item, useCountryList }, () => {
            this.wizard.current && this.wizard.current.setItem(this.state.item);
        });
    };

    getLogoUrl = () => {
        const params = {
            attachment: 'company_logo',
            id: this.context.userObject.companies_id,
            t: this.context.taimerAccount.logo,
            _auth: this.context.functions.getStorage().taimerToken,
        };

        return this.context.functions.getDownloadPath() + $.param(params);
    };

    getBankAccount = async () => {
        const data = await DataHandler.get({ url: `settings/company/${this.context.userObject.companies_id}/bankaccounts` });
        if ((data.accounts || []).length == 0) return;
        const defaultAccounts = (data.accounts || []).filter((d) => d.default_account == 1);
        const account = defaultAccounts[0];
        if (account) {
            this.setState(
                {
                    item: {
                        ...this.state.item,
                        bankAccount: account.iban,
                        bankName: account.name,
                        swift: account.swift,
                        originalBankAccount: account,
                    },
                },
                () => {
                    this.wizard.current && this.wizard.current.setItem(this.state.item);
                }
            );
        }
    };

    getCompanyAddress = async () => {
        const data = await DataHandler.get({ url: `settings/company/${this.context.userObject.companies_id}/address` });
        this.setState(
            {
                item: {
                    ...this.state.item,
                    originalAddress: data,
                    address: data.address,
                    postalCode: data.postalcode,
                    city: data.city,
                    state: data.state,
                    country: data.country,
                },
            },
            () => {
                this.wizard.current && this.wizard.current.setItem(this.state.item);
                this.getCountries();
            }
        );
    };

    getProjectCount = async () => {
        const url = {
            url: 'projects/list_new?\\',
            getCount: 1,
            company: this.context.userObject.companies_id,
            perpage: 30,
            page: 1,
            view: 'list',
            status: 0,
            expectedClosingDate: 0,
            salesAgent: 0,
            projectStatus: -1,
            projects_pipelines_id: 0,
        };
        const counts = await DataHandler.post(url);
        const projectCount = counts.count || 0;
        this.projectCount = projectCount;
    };

    onCompanyAddressSet = () => {
        this.setState({ invoicePreviewStyle: undefined }, () => {
            this.saveCompanyAddress();
            this.wizard.current && this.wizard.current.nextPage();
        });
    };

    onBankAccountSet = () => {
        this.setState({ invoicePreviewStyle: 'logoFocus' }, () => {
            this.saveBankAccount();
            this.wizard.current && this.wizard.current.nextPage();
        });
    };

    formatNumberField = (value) => {
        const formattedValue = (value || '').replace(',', '.').replace(/[^0-9.]/g, '');
        return formattedValue;
    };

    deleteRow = (row) => {
        const rows = cloneDeep(this.state.rows);
        const index = rows.findIndex((r) => r.id == row.id);
        if (index != -1) {
            rows.splice(index, 1);
        }
        this.setState({ rows });
    };

    renderRowFields = (row) => {
        return (
            <div key={row.id} className={styles.rowFields}>
                {this.state.rows.length > 1 && (
                    <Tooltip title={this.tr('Delete row')}>
                        <button className={styles.deleteButton} onClick={() => this.deleteRow(row)}>
                            <Delete />
                        </button>
                    </Tooltip>
                )}
                <hr />
                {this.rowFields.map((field) => this.renderRowField(field, row))}
            </div>
        );
    };

    onRowFieldEdited = (e, field, row) => {
        const rows = cloneDeep(this.state.rows);
        const index = rows.findIndex((r) => r.id == row.id);
        const value = field.formatValue ? field.formatValue(e.target.value) : e.target.value;
        if (index != -1) {
            rows[index] = {
                ...rows[index],
                [field.key]: value,
            };
        }
        this.setState({ rows });
    };

    afterNumberFieldEdit = (e, field, row) => {
        const rows = cloneDeep(this.state.rows);
        const index = rows.findIndex((r) => r.id == row.id);
        const formattedValue = e.target.value != 0 ? Utils.truncateDecimals(e.target.value, 2, false) : e.target.value;
        if (index != -1) {
            rows[index] = {
                ...rows[index],
                [field.key]: formattedValue,
            };
        }
        this.setState({ rows });
    };

    renderRowField = (field, row) => {
        switch (field.type) {
            case 'text':
            case 'textarea':
                return (
                    <OutlinedField
                        ref={(ref) => {
                            this.fieldRefs = {
                                ...this.fieldRefs,
                                [field.key]: ref,
                            };
                        }}
                        name={field.key}
                        value={row[field.key]}
                        label={field.label}
                        autoFocus={field.autoFocus}
                        multiline={field.type == 'textarea'}
                        onKeyUp={(e) => this.onRowFieldEdited(e, field, row)}
                        onChange={field.afterEdit ? (e) => field.afterEdit(e, field, row) : undefined}
                        onFocus={() => this.setState({ invoicePreviewStyle: 'rowFocus' })}
                        noStateValue
                        placeholder={field.placeholder}
                        shrinkLabel={field.placeholder ? true : undefined}
                    />
                );
            default:
                return null;
        }
    };

    renderRowEditor = () => {
        const { rows } = this.state;
        this.fieldRefs = {};
        return (
            <div className={styles.rowEditor}>
                <div className={styles.rows}>{rows.map(this.renderRowFields)}</div>
                <button className={styles.addRowButton} onClick={() => this.addRow()}>
                    <AddCircleOutline />
                    {this.tr('Add more items')}
                </button>
            </div>
        );
    };

    onLogoUpload = (logoFile) => {
        this.setState({ uploadingLogo: true, previewLogo: URL.createObjectURL(logoFile) }, () => {
            DataHandler.file({ url: `settings/company/${this.context.userObject.companies_id}/logoupload` }, logoFile).always(() => {
                this.setState({ uploadingLogo: false });
            });
        });
    };

    addRow = (usePlaceholders = false) => {
        const rows = cloneDeep(this.state.rows || []);
        let id = -1;
        rows.forEach((row) => {
            if (row.id <= id) {
                id = row.id - 1;
            }
        });
        const prefill = usePlaceholders ? { topic: this.tr('Demo product'), unitCost: 100 } : {};
        const newRow = { id, quantity: 1, vat: 24, ...prefill };
        rows.push(newRow);
        this.setState({ rows });
    };

    editRow = (key, value) => {
        const rows = cloneDeep(this.state.rows || []);
        rows[rows.length - 1][key] = value;
        this.setState({ rows });
    };

    saveAccount = async () => {
        const { item } = this.state;
        const data = {
            id: -1,
            name: item.clientName || this.tr('Demo client'),
            types: 'default',
            vatid: item.clientVatId,
        };
        const response = await DataHandler.post({ url: `accounts/company/${this.context.userObject.companies_id}` }, data);
        const id = response.id;
        this.saveProject(id);
        return id;
    };

    saveProject = async (accountId) => {
        const { item } = this.state;
        const data = {
            id: -1,
            name: item.projectName || this.tr('Demo project'),
            customers_id: accountId,
            types: 'default',
            status_users_id: this.context.userObject.usersId,
            team_members: [this.context.userObject.usersId],
            users_id: this.context.userObject.usersId,
            closing_date: moment().add(30, 'days').format('YYYY-MM-DD'),
            startdate: moment().format('YYYY-MM-DD'),
            enddate: moment().add(30, 'days').format('YYYY-MM-DD'),
            companies_id: this.context.userObject.companies_id,
        };
        const response = await DataHandler.post({ url: `projects` }, data);
        return response.id;
    };

    saveBillingAddress = async (clientId) => {
        const { item } = this.state;
        const data = {
            name: item.clientName || this.tr('Demo client'),
            vatid: item.clientVatId,
            contact: item.clientContact,
            address: item.clientAddress,
            postalcode: item.clientPostalCode,
            city: item.clientCity,
            country: item.clientCountry,
            state: item.clientState,
            currency: 'EUR',
            bill_language: 'fi',
            reverse_charge: '0',
        };
        const response = await DataHandler.post({ url: `accounts/${clientId}/billing_address` }, data);
        return response;
    };

    saveCompanyAddress = async () => {
        const { item } = this.state;
        const { taimerAccount } = this.context;
        const companyAddress = {
            ...item.originalAddress,
            name: item.originalAddress?.company_address_name || taimerAccount.name,
            address: item.address,
            postalcode: item.postalCode,
            city: item.city,
            state: item.state,
            country: item.country,
        };
        const response = await DataHandler.post({ url: `settings/company/${this.context.userObject.companies_id}/address` }, companyAddress);
        return response;
    };

    saveBankAccount = async () => {
        const { item } = this.state;
        if (!item.bankAccount) {
            return -1;
        }
        const bankAccountData = {
            accounts: [
                {
                    id: item.bankAccountId || -1,
                    default_account: 1,
                    additional_account: 0,
                    ...item.originalBankAccount,
                    name: item.bankName,
                    iban: item.bankAccount,
                    swift: item.swift,
                },
            ],
        };
        const response = await DataHandler.post({ url: `settings/company/${this.context.userObject.companies_id}/bankaccounts` }, bankAccountData);
        this.bankAccountId = item.bankAccountId || response.accounts[0]?.id;
    };

    sendInvoice = () => {
        this.wizard.current && this.wizard.current.nextPage();
        const { taimerAccount } = this.context;
        const { item } = this.state;
        const dateFormat = convertDateFormatToPHP(taimerAccount.companyDateFormat);
        this.saveAccount().then((clientId) => {
            this.saveBillingAddress(clientId).then((billingAddress) => {
                const today = moment();
                const data = {
                    dont_validate_bank_account: true,
                    bill_type: 1,
                    invoice_type: 1,
                    type: 1,
                    terms: 14,
                    annotation: 7,
                    annotationinterest: 11,
                    bank_account: this.bankAccountId,
                    company: this.context.userObject.companies_id,
                    users_id: this.context.userObject.usersId,
                    creationdate: today.format('YYYY-MM-DD'),
                    deliverydate: today.format('YYYY-MM-DD'),
                    duedate: today.add(14, 'days').format('YYYY-MM-DD'),
                    customers_id: clientId,
                    billing_zone: 'fi',
                    date_format: dateFormat,
                    currency_code: taimerAccount.currency,
                    currency_rate: 1,
                    currency_rates_id: 0,
                    billing_address: billingAddress,
                    rows: this.state.rows.map((row) => {
                        return {
                            id: row.id,
                            content_type: '0',
                            description: row.topic,
                            total: Number(row.unitCost || 0) * Number(row.quantity || 1),
                            value: Number(row.unitCost || 0),
                            quantity: Number(row.quantity || 1),
                            vat: row.vat || 24,
                        };
                    }),
                };
                const billLanguage = this.context.userObject.language == 'fi' ? 'fi' : 'en';
                DataHandler.post({ url: `invoices/invoice` }, data)
                    .done((invoice) => {
                        const transl = new InvoiceTranslations().returnTranslations([billLanguage]);
                        DataHandler.postArrayBuffer(
                            { url: 'invoices/send_pdf' },
                            {
                                translations: transl,
                                ids: [invoice.id],
                                lang: billLanguage,
                                date_format: dateFormat,
                                message: item.message,
                                messageContent: 'text',
                                recipients: item.recipient,
                                pdfName: this.tr('test_invoice.pdf'),
                                subject: this.tr('Heeros test invoice'),
                            }
                        )
                            .done(() => {
                                this.wizard.current && this.wizard.current.nextPage();
                            })
                            .fail(() => {
                                // should show some fail page
                                this.wizard.current && this.wizard.current.nextPage();
                            })
                            .always(() => {
                                DataHandler.get({ url: 'settings/delete_bill', bill_id: invoice.bill_id, company: this.context.userObject.companies_id });
                            });
                    })
                    .fail((e) => {
                        console.error(e);
                        // should show some fail page
                        this.wizard.current && this.wizard.current.nextPage();
                    });
            });
        });
    };

    renderInvoicePreview = () => {
        const { item, invoicePreviewStyle, rows = [], previewLogo, logoUrl, logoLoadError, uploadingLogo } = this.state;
        const {
            functions: { presentCurrency },
            taimerAccount,
        } = this.context;
        return (
            <div className={`${styles.invoicePreview} ${invoicePreviewStyle ? styles[invoicePreviewStyle] : ''}`}>
                <div className={styles.topSection}>
                    <div className={styles.ownData}>
                        <div className={styles.logo}>
                            {(previewLogo || (logoUrl && !logoLoadError)) && <img src={previewLogo || logoUrl} onError={() => this.setState({ logoLoadError: true })} />}
                            {uploadingLogo && (
                                <div className={styles.uploadOverlay}>
                                    <img src={require('../dashboard/insights/img/loading.svg').default} />
                                </div>
                            )}
                        </div>
                        <div className={styles.address}>
                            <p>{taimerAccount.name}</p>
                            {item.address ? <p>{item.address}</p> : <span />}
                            {item.postalCode ? <p>{item.postalCode}</p> : <span />}
                            {item.city ? <p>{item.city}</p> : <span />}
                            {taimerAccount.useState && (item.state ? <p>{item.state}</p> : <span />)}
                            {item.country ? <p>{item.country}</p> : <span />}
                        </div>
                    </div>
                    <div className={styles.clientData}>
                        <div className={styles.address}>
                            <p>{this.tr('Receiver')}</p>
                            {(item.clientName || this.tr('Demo client')) ? <p>{item.clientName || this.tr('Demo client')}</p> : <span />}
                            {item.clientAddress ? <p>{item.clientAddress}</p> : <span />}
                            {item.clientPostalCode ? <p>{item.clientPostalCode}</p> : <span />}
                            {item.clientCity ? <p>{item.clientCity}</p> : <span />}
                            {taimerAccount.useState && (item.clientState ? <p>{item.clientState}</p> : <span />)}
                            {item.clientCountry ? <p>{item.clientCountry}</p> : <span />}
                            {item.clientVatId ? <p>{item.clientVatId}</p> : <span />}
                            {item.clientContact ? <p>{item.clientContact}</p> : <span />}
                        </div>
                    </div>
                </div>
                {rows.length > 0 && (
                    <div className={styles.rowSection}>
                        <div className={styles.header}>
                            <div>{this.tr('Product or service')}</div>
                            <div>{this.tr('Quantity')}</div>
                            <div>{this.tr('Unit cost')}</div>
                            <div>{this.tr('VAT')}</div>
                        </div>
                        <div className={styles.rows}>
                            {rows.map((row) => (
                                <div className={styles.row}>
                                    <div>
                                        <p>{row.topic}</p>
                                        {row.description && <p className={styles.description}>{row.description}</p>}
                                    </div>
                                    <div>
                                        <p>{row.quantity || 0}</p>
                                    </div>
                                    <div>
                                        <p>{presentCurrency(row.unitCost || 0)}</p>
                                    </div>
                                    <div>
                                        <p>{row.vat || 24} %</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className={styles.footerSection}>
                    <div className={styles.address}>
                        <p>{taimerAccount.name}</p>
                        {item.address ? <p>{item.address}</p> : <span />}
                        {item.postalCode ? <p>{item.postalCode}</p> : <span />}
                        {item.city ? <p>{item.city}</p> : <span />}
                        {taimerAccount.useState && (item.state ? <p>{item.state}</p> : <span />)}
                        {item.country ? <p>{item.country}</p> : <span />}
                    </div>
                    <div className={`${styles.address} ${styles.bankAccount}`}>
                        {item.bankName ? <p>{item.bankName}</p> : <span />}
                        {item.bankAccount ? <p>{item.bankAccount}</p> : <span />}
                        {item.swift ? <p>{item.swift}</p> : <span />}
                    </div>
                </div>
            </div>
        );
    };

    onItemChanged = (item) => this.setState({ item });

    onClose = () => {
        this.context.functions.sendMixpanelEvent('onboarding_wizard_completed');
        this.context.functions.sendMixpanelPeople('set', {
            "onboarding_wizard_end_date": new Date().toISOString(),
        });
        DataHandler.post({ url: `settings/onboarding_seen` });
        if (this.wizard.current && this.wizard.current.getCurrentPage() == this.pages.length) {
            this.context.functions.onOnboardingItemCompleted('setup', this.props.sliderMode);
        }
        this.context.functions.updateView({
            module: 'dashboard',
            action: 'main',
            selectedTab: 'my-day',
            replace: true
        });
    };

    beforeClose = (onClose) => {
        if (this.wizard.current && this.wizard.current.getCurrentPage() < this.pages.length && this.projectCount == 0) {
            const onboardingItems = VersionContentManager.getOnboardingItems();
            const closeText =
                onboardingItems.length == 0
                    ? this.tr("If you close the setup now, we'll create a demo project for you – just so you can better try out all the features in Taimer.")
                    : this.tr(
                          "If you close the setup now, we'll create a demo project for you – just so you can better try out all the features in Taimer. You'll still be able to access this setup later from the onboarding section in My Day."
                      );
            this.context.functions.showDialog(
                <Dialog
                    onDialogClose={this.context.functions.closeDialog}
                    onDialogSave={onClose}
                    dialogType={'delete'}
                    dialogProps={{
                        wider: true,
                        onCloseClick: this.context.functions.closeDialog,
                        open: true,
                        close: this.context.functions.closeDialog,
                        confirmDisabled: false,
                        header: this.tr('Close account setup?'),
                        translatedConfirmButtonText: this.tr('Close account setup'),
                        warning: () => <p>{closeText}</p>,
                        onConfirm: () => {
                            this.context.functions.closeDialog();
                            this.saveProject(1);
                            onClose();
                        },
                    }}
                />
            );
        } else {
            onClose();
        }
    };

    showCloseConfirmation = () => {
        const currentPage = this.wizard.current ? this.wizard.current.getCurrentPage() : 1;
        return currentPage != 1 && currentPage != this.pages.length && Number(this.projectCount) > 0;
    }

    render() {
        return (
            <TaimerWizard
                title={this.tr('Setup your account')}
                initialItem={this.state.item}
                ref={this.wizard}
                pages={this.pages}
                onItemChanged={this.onItemChanged}
                updateTriggers={{ rows: this.state.rows, useCountryList: this.state.useCountryList }}
                onClose={this.onClose}
                beforeClose={this.beforeClose}
                hideMainWhenSmall
                showCloseConfirmation={this.showCloseConfirmation}
                disableDefaultCloseConfirmation={true}
            />
        );
    }
}

export default withSnackbar(SetupAccountWizard);
