import React from "react";

const SIMPLE_LIST_CELL_ALIGNMENT_CLASSES = {
    left: "alignLeft",
    right: "alignRight",
    center: "alignCenter"
};

class SimpleListCell extends React.Component {
    static defaultProps = {
        align: "left",
        cellClassName: "",
        cellStyle: {},
        cellValueClassName: "",
        cellValueStyle: {},
        flex: false,
        title: undefined,
        value: undefined,
        valueWrapper: React.Fragment,
        valueWrapperProps: {},
        width: 140
    };


    render() {
        const { 
            value,
            title,
            valueWrapper,
            valueWrapperProps
        } = this.props;
        const cellClassName      = `cell ${this.props.cellClassName}`;
        const cellValueClassName = `cellValue \
            ${SIMPLE_LIST_CELL_ALIGNMENT_CLASSES[this.props.align]} \
            ${this.props.cellValueClassName}`;

        const ValueWrapper = valueWrapper;

        return (
            <div 
                className={cellClassName}
                data-testid={this.props['data-testid']}
                title={title}
                style={{
                    width: this.props.width,
                    flex: this.props.flex ? `${this.props.width} 1 0px` : undefined,
                    ...this.props.cellStyle
                }}>
                <div 
                    className={cellValueClassName}
                    data-testid={`${this.props['data-testid']}-value`}
                    style={this.props.cellValueStyle}>
                    <ValueWrapper {...valueWrapperProps}>
                        <span>{value}</span>
                    </ValueWrapper>
                </div>
            </div>
        );
    }
}

export default SimpleListCell;
