import React from "react";
import {
  AddCatalogDialog,
  AddToCatalogDialog,
  LinkCatalogToAccountDialog,
  ArchiveCatalogDialog,
  AccountDeleteDialog,
  UserDialog
} from "./ProductCatalogDialogs";
import CatalogListSlider from "../lists/CatalogListSlider";
import CatalogAccountSlider from "../lists/CatalogAccountSlider";
import ProductBalanceWarningDialog from "./ProductBalanceWarningDialog";

const ProductCatalogModalProvider = (props) => {
  const {
    editDialogData,
    addToCatalogDialogData,
    linkToAccountDialogData,
    archiveCatalogDialogData,
    accountDeleteDialogData,
    userDialogData,
    catalogSliderData,
    catalogAccountSliderData,
    balanceWarningDialogData,
    balanceWarningData
  } = props;
  return (
    <>
      {editDialogData && editDialogData.open && <AddCatalogDialog {...editDialogData} />}
      {addToCatalogDialogData && addToCatalogDialogData.open && (
        <AddToCatalogDialog {...addToCatalogDialogData} />
      )}
      {linkToAccountDialogData && linkToAccountDialogData.open && (
        <LinkCatalogToAccountDialog {...linkToAccountDialogData} />
      )}
      {archiveCatalogDialogData && archiveCatalogDialogData.open && (
        <ArchiveCatalogDialog {...archiveCatalogDialogData} />
      )}
       {accountDeleteDialogData && accountDeleteDialogData.open && (
        <AccountDeleteDialog {...accountDeleteDialogData} />
      )}
      {userDialogData && userDialogData.open && (
        <UserDialog {...userDialogData} />
      )}
      <CatalogListSlider {...catalogSliderData} enqueueSnackbar={props.enqueueSnackbar} />
      <CatalogAccountSlider {...catalogAccountSliderData} />
     { linkToAccountDialogData && balanceWarningDialogData && balanceWarningDialogData.open && (<ProductBalanceWarningDialog  {...balanceWarningDialogData} />)}
    </>
  );
};

export default ProductCatalogModalProvider;
