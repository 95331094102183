import React from 'react';
import moment from 'moment';
import { addDays, addHours, endOfDay, format, getHours, isSameDay, subMinutes, subSeconds } from 'date-fns';
import _, { cloneDeep, debounce, isEqual } from 'lodash';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { ArrowDropDown, RemoveRedEye, WarningRounded } from '@mui/icons-material';
import { Button, Checkbox, Switch, Tooltip, Popover, FormControlLabel, Badge, IconButton } from '@mui/material';
import DataHandler from './DataHandler';
import TaimerComponent from '../TaimerComponent';
import Dialog from '../dialogs/mass_operations/CoreDialog';
import EntityCreatedSnackbar from './EntityCreatedSnackbar';
import ProjectTreeDropdown from '../projects/ProjectTreeDropdown';
import { checkOverlaps, deleteWorkhourEntry, getWorktypesForProject } from '../Data';
import FieldEditSlider, { EditableField, FieldEditSliderProps } from './FieldEditSlider';
import InvoiceRowsEdit, { CreateInvoiceRow, UpdateInvoiceRow } from '../dialogs/elements/InvoiceRowsEditor/InvoiceRowsEdit';
import WindowIcon from '@mui/icons-material/Window';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import cn from 'classnames'
import styles from './AddHoursSlider.module.scss';
import { AddProject } from './no-options/AddItemComponents';
import VersionContentManager from './VersionContentManager';

interface Props extends WithSnackbarProps, FieldEditSliderProps {
    initialSelectionProps?: any;
    preventMulticompanyHourentries?: boolean;
    isWorkhourTimer?: boolean;
    timer?: any;
    afterSaveCallback?: () => void;
    parentComponent?: string;
    confirmHourSubmit?: (type: string, id: string) => void;
    getLastAddedEvent?: () => any;
    onEntryChanged?: (item: any) => void;
    demoMode?: boolean;
}

// a lot of these should be typed instead of any
interface State {
    entry: any;
    open: boolean;
    hasOverlaps: boolean;
    accounts: any[];
    accountMap: object;
    units: any[];
    unitMap: object;
    projects: any[];
    projectMap: object;
    jobtypes: any[];
    resources: any[];
    mileages: any[];
    mileageAdditionals: any[];
    dailies: any[];
    types: any[];
    quotes: any[];
    quoteRows: any[];
    invoiceableRows: any[];
    previousEntries: any[];
    showPreviousEntryDescriptions: boolean;
    showPreviousEntryTasks: boolean;
    showPreviousEntries: boolean;
    loadingPreviousEntries: boolean;
    dailyHours: any;
    overtimeSettings?: any;
    viewSettingsAnchor?: any;
    approvalData: any;
    useProjectTreeDropdown?: boolean;
    showOnlyOwn?: boolean;
    dateError?: string;
    timeError?: string;
    projectError?: string;
    jobtypeError?: string;
    showInvoiceableRowValidation?: boolean;
    projectsLoaded?: boolean;
    treeProjectsLoaded?: boolean;
    previousEntryGrid?: boolean;
}

class AddHoursSlider extends TaimerComponent<Props, State> {
    tabs;
    jobtypeToSet;
    invoiceableRowTypes = {
        expense: 1,
        invoiceable: 2,
    };
    invoiceableRowHeaders;
    addableInvoiceableRows;
    targetingMap: any = [];
    projectTreeDropdown: any = React.createRef();
    editSlider: any = React.createRef();
    forceJobtypeLoad = false;
    constructor(props, context) {
        super(props, context, 'general/AddHoursSlider');
        this.invoiceableRowHeaders = {
            [this.invoiceableRowTypes.expense]: this.tr('Expense'),
            [this.invoiceableRowTypes.invoiceable]: this.tr('Invoiceable'),
        };

        this.addableInvoiceableRows = [

            {
                key: 'product',
                label: this.tr('Product'),
                onClick: () => {
                    this.onAddInvoiceableRow('product', this.invoiceableRowTypes.invoiceable);
                },
            },
            {
                key: 'cpq',
                label: this.tr('CPQ'),
                hidden: VersionContentManager.isFeatureHidden(this.namespace, 'cpq'),
                needsUpgrade: VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'cpq'),
                onClick: () => {
                    this.onAddInvoiceableRow('cpq', this.invoiceableRowTypes.invoiceable);
                },
            },
            {
                key: 'invoiceable',
                label: this.tr('Invoiceable'),
                onClick: () => {
                    this.onAddInvoiceableRow('invoiceable', this.invoiceableRowTypes.invoiceable);
                },
            },
            {
                key: 'mileage',
                label: this.tr('Mileage'),
                hidden: VersionContentManager.isFeatureHidden(this.namespace, 'mileages') || !this.checkPermission(),
                needsUpgrade: VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'mileages'),
                onClick: () => {
                    this.onAddInvoiceableRow('mileage', this.invoiceableRowTypes.expense);
                },
            },
            {
                key: 'daily',
                label: this.tr('Daily allowance'),
                hidden: VersionContentManager.isFeatureHidden(this.namespace, 'dailies') || !this.checkPermission(),
                needsUpgrade: VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'dailies'),
                onClick: () => {
                    this.onAddInvoiceableRow('daily', this.invoiceableRowTypes.expense);
                },
            },
            {
                key: 'other',
                label: this.tr('Other expense'),
                hidden: VersionContentManager.isFeatureHidden(this.namespace, 'otherExpenses') || !this.checkPermission(),
                needsUpgrade: VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'otherExpenses'),
                onClick: () => {
                    this.onAddInvoiceableRow('other', this.invoiceableRowTypes.expense);
                },
            },
        ];

        this.tabs = [
            {
                id: 'entry',
                label: this.tr('New entry'),
            },
            {
                id: 'previous_entries',
                label: this.tr('Previous entries'),
            },
        ];

        const start = props.initialSelectionProps?.start || new Date();
        // fixing a bug where an hour entry drawn to the end of the calendar would round to 00:00 on the next day
        // – just removing one minute
        let end = props.initialSelectionProps?.end || addHours(new Date(), 1);
        if (!isSameDay(start, end) && getHours(end) == 0) {
            end = subMinutes(end, 1);
        }

        const endTimeExact = format(end, 'HH:mm:ss');

        const startdate = format(start, 'YYYY-MM-DD');
        const enddate = format(end, 'YYYY-MM-DD');
        const starttime = props.starttime || format(start, 'HH:mm');
        const endtime = props.endtime || (endTimeExact == '23:59:59' ? '24:00' : format(end, 'HH:mm'));

        const invoiceableRows: any = [];
        let invoiceRowIndex = 0;

        if (this.props.initialSelectionProps?.invoiceable_rows) {
            this.props.initialSelectionProps?.invoiceable_rows.forEach((row, i) => {
                invoiceableRows.push({ ...row, invoiceRowIndex: ++invoiceRowIndex });
            });
        }

        this.state = {
            entry: {
                user: {
                    id: this.context.userObject.usersId,
                },
                startdate,
                enddate,
                starttime,
                endtime,
                editable: true,
                ...this.props.initialSelectionProps,
                task: this.props.initialSelectionProps?.wh_projects_resource?.id == -1 ? { id: 0 } : this.props.initialSelectionProps?.wh_projects_resource,
                projects_quotation_row:
                    this.props.initialSelectionProps?.id && !this.props.initialSelectionProps?.projects_quotation_row ? '0' : this.props.initialSelectionProps?.projects_quotation_row,
            },
            open: false,
            hasOverlaps: false,
            accounts: [],
            accountMap: {},
            units: [],
            unitMap: {},
            projects: [],
            projectMap: {},
            jobtypes: [],
            resources: [],
            mileages: [],
            mileageAdditionals: [],
            dailies: [],
            types: [],
            quotes: [],
            quoteRows: [],
            invoiceableRows,
            previousEntries: [],
            showPreviousEntryDescriptions: this.getSettingValueFromLS('showPreviousEntryDescriptions'),
            showPreviousEntryTasks: this.getSettingValueFromLS('showPreviousEntryTasks'),
            showPreviousEntries: this.props.demoMode ? false : this.getSettingValueFromLS('showPreviousEntries'),
            previousEntryGrid: this.getSettingValueFromLS('showPreviousEntryGrid'),
            loadingPreviousEntries: false,
            dailyHours: {
                can_add_overtime: false,
            },
            useProjectTreeDropdown: this.context.userObject.timetracker_old_drop == '0',
            showOnlyOwn: this.getSettingValueFromLS('showOnlyOwn'),
            approvalData: {},
        };
    }

    componentDidMount = () => {
        if (this.props.open) {
            this.setState({ open: true });
        }
    };

    checkPermission() {
        const { functions: { hasPrivilege } } = this.context;
        return hasPrivilege("worktrips", "write");
    }

    componentDidUpdate = (_, oldState) => {
        if (oldState.open != this.state.open && this.state.open) {
            if (!this.state.entry.id && !this.state.entry.customer?.id && !this.state.entry.project?.id) {
                this.getLastEntry();
            } else {
                this.checkOverlaps();
                this.getJobtypes(this.state.entry);
                this.getApprovalData();
            }
            this.getAccountsAndProjects();
            this.getResources();
            this.getPreviousEntries();
            this.getDailyHours();
            this.getOvertimeSettings();
            this.getInvoiceableRowData();
            this.getQuoteData();
        }

        const oldProjectKey = this.getSelectedProjectKey(oldState.entry);
        const newProjectKey = this.getSelectedProjectKey(this.state.entry);
        if (oldState.entry[oldProjectKey]?.id != this.state.entry[newProjectKey]?.id || this.forceJobtypeLoad) {
            this.forceJobtypeLoad = false;
            this.getJobtypes(this.state.entry);
        }
        // If cost targeting is off and we're editing an entry targeted to a quote, we need to remove the targeting if project is changed
        if (
            oldState.entry[oldProjectKey]?.id != this.state.entry[newProjectKey]?.id &&
            (this.state.entry.projects_quotation || this.state.entry.projects_quotation_row) &&
            this.targetingMap[this.state.entry[newProjectKey]?.companies_id] && this.targetingMap[this.state.entry[newProjectKey]?.companies_id] != '1'
        ) {
            // FieldEditSlider doesn't like too many fast updates at once and this causes multiple, so a timeout is set here
            setTimeout(() => {
                this.setState({
                    entry: {
                        ...this.state.entry,
                        projects_quotation: undefined,
                        projects_quotation_row: undefined,
                    },
                });
            }, 1000);
        }
    };

    getSettingValueFromLS = (key) => {
        let value = false;
        try {
            value = localStorage.getItem(`hourSlider_${key}`) == '1';
        } catch (e) {
            console.error(e);
        }
        return value;
    };

    getOptions = () => {
        if (this.props.demoMode) return [];
        const { entry, approvalData } = this.state;
        const items = entry.editable ? [...this.addableInvoiceableRows]
            .filter(option => {
                // TR-1488: you can only create traveling
                // expenses for your own company.
                return Number(this.state.entry?.project?.companies_id) === Number(this.context?.userObject?.companies_id)
                    || ["mileage", "daily", "other"].indexOf(option.key) === -1;
            })
            .filter((r) => !r.hidden) : [];
        const {
            userObject,
            functions: { getTimeTrackerSettings },
        } = this.context;

        if (Number(entry.id) > 0 && this.props.confirmHourSubmit && entry.approvable_hour) {
            const projectKey = this.getSelectedProjectKey(entry);
            const timeTrackerSettings = getTimeTrackerSettings(entry[projectKey]?.companies_id);
            const enableApprovals = timeTrackerSettings?.enable_approval_submitting || (userObject.companies_id == '0' && approvalData.enable_submit_companies?.length > 0);
            if (!enableApprovals) return items;
            if (entry.submittable && !VersionContentManager.isFeatureHidden(this.namespace, 'hoursApproval')) {
                items.push({
                    key: 'approval',
                    label: this.tr('Send for approval'),
                    onClick: () => {
                        this.props.confirmHourSubmit && this.props.confirmHourSubmit('submit', entry.id);
                        this.onClose();
                    },
                });
            }
            if (entry.revokeable && !VersionContentManager.isFeatureHidden(this.namespace, 'hoursApproval')) {
                items.push({
                    key: 'revoke',
                    label: this.tr('Revoke'),
                    onClick: () => {
                        this.props.confirmHourSubmit && this.props.confirmHourSubmit('revoke', entry.id);
                        this.onClose();
                    },
                });
            }
        }
        return items;
    };

    setSettingValueToLS = (key, value) => {
        try {
            localStorage.setItem(`hourSlider_${key}`, value ? '1' : '0');
        } catch (e) {
            console.error(e);
        }
    };

    getResources = async () => {
        if (!(this.context.addons && this.context.addons.resourcing)) return;
        const { entry } = this.state;
        const { userObject } = this.context;
        let resources = await DataHandler.get({ url: 'dialogs/resources', user: entry.user?.id, current: entry.task?.id });
        resources = resources
            .filter((r) => (r.done != 1 && r.done != true) || r.id == entry.task?.id)
            .map((el) => {
                const start = new Date(el.startdate);
                const end = new Date(el.enddate);
                let name = el.name;
                if (el.startdate == el.enddate) {
                    name += ' (' + format(start, userObject.dateFormat) + ')';
                } else {
                    name += ' (' + format(start, userObject.dateFormat) + ' - ' + format(end, userObject.dateFormat) + ')';
                }
                return {
                    ...el,
                    name,
                    label: name,
                    value: el.id,
                };
            });
        this.setState({
            resources,
        });
    };

    getApprovalData = async () => {
        const approvalData = await DataHandler.get({ url: 'timetracker/workhours/approval/autocomplete' });
        this.setState({ approvalData });
    };

    getQuoteData = async () => {
        const response = await DataHandler.get({ url: 'dialogs/quotations' });
        this.targetingMap = response.company_quote_targeting;
        const quotes = (response.projects_quotations || []).map((q) => ({ ...q, value: q.id, label: q.name }));
        const quoteRows = (response.projects_quotation_rows || []).map((qr) => ({ ...qr, value: qr.id, label: qr.name }));
        this.setState({ quotes, quoteRows });
    };

    getOvertimeSettings = async () => {
        const { userObject } = this.context;
        const company = userObject.companies_id;
        const { overtimeSettings } = await DataHandler.get({ url: `settings/company/${company}/overtime` });
        this.setState({ overtimeSettings });
    };

    getDailyHours = async () => {
        const {
            entry: { id, startdate, enddate },
        } = this.state;
        const dailyHours = await DataHandler.get({ url: `timetracker/daily_hours`, id: id ? [id] : [], start: startdate, end: enddate });
        this.setState({ dailyHours });
    };

    getPreviousEntries = () => {
        this.setState({ loadingPreviousEntries: true }, async () => {
            const previousEntries = await DataHandler.get({ url: `timetracker/previous_entries`, description: this.state.showPreviousEntryDescriptions, task: this.state.showPreviousEntryTasks });
            this.setState({ previousEntries, loadingPreviousEntries: false });
        });
    };

    getInvoiceableRowData = async () => {
        const {
            entry: { id, startdate, enddate },
        } = this.state;

        const mileages = await DataHandler.get({ url: 'worktrips/rates/mileage', date: startdate });
        const mileageAdditionals = await DataHandler.get({ url: 'worktrips/rates/additional', date: startdate });
        const dailies = await DataHandler.get({ url: 'worktrips/rates/daily', date: startdate });
        const types = await DataHandler.get({ url: 'expenses/custom_types' });

        this.setState({
            mileages,
            mileageAdditionals,
            dailies,
            types: types.custom_types,
        });
    };

    getJobtypes = async (item, keepIfNotIncluded = true) => {
        const projectKey = this.getSelectedProjectKey(item);
        const projectId = item[projectKey]?.id;
        if (!projectId) {
            const notSelected = {
                id: 0,
                value: 0,
                label: this.tr('Not selected'),
                name: this.tr('Not selected'),
            };
            const entry = {
                ...this.state.entry,
                worktask: notSelected,
            };
            if (this.jobtypeToSet) this.jobtypeToSet = undefined;
            this.setState({ entry, jobtypes: [notSelected] });
            return;
        }
        let current;
        if (item.worktask?.id == this.props.initialSelectionProps?.wh_projects_resource?.id) {
            current = item.worktask?.id;
        }
        let jobtypes = !projectId ? [] : await getWorktypesForProject(projectId, { current });
        if (!keepIfNotIncluded) jobtypes = jobtypes.filter((e) => e.included_in_project_worktypes === '1');
        const worktask = jobtypes.find((jt) => jt.id == (this.jobtypeToSet || this.state.entry.worktask)?.id) || jobtypes[0];
        const entry = {
            ...this.state.entry,
            worktask,
        };
        if (this.jobtypeToSet) this.jobtypeToSet = undefined;
        this.setState({ entry, jobtypes: jobtypes.map((jt) => ({ ...jt, value: jt.id })) });
    };

    getProjectData = (projectMap, entry, useProjectTreeDropdown = this.state.useProjectTreeDropdown) => {
        if (!projectMap || Object.values(projectMap).length == 0) return {};
        let projectToSet = projectMap[entry.project?.id] || Object.values(projectMap)[0];
        if (!projectToSet) return {};

        const projectsToSet = [projectToSet];
        let projectData = {};

        if (!useProjectTreeDropdown) {
            while (projectToSet && Number(projectToSet.parent?.id || 0) > 0) {
                projectToSet = projectMap[projectToSet.parent?.id];
                projectsToSet.unshift(projectToSet);
            }

            const firstProject = projectsToSet[0];

            // Ensure customer is correct (from first project)
            if (firstProject) {
                projectData = {
                    ...projectData,
                    customer: {
                        ...firstProject.customer,
                        label: firstProject.customer?.name,
                        value: firstProject.customer?.id,
                    },
                    unit: {
                        ...firstProject.unit,
                        label: Number(firstProject.unit?.id || 0) > 0 ? firstProject.unit?.name : this.tr('Not selected'),
                        value: firstProject.unit?.id,
                    },
                }
            }
        }

        projectsToSet.forEach((p, i) => {
            projectData = {
                ...projectData,
                [i == 0 ? `project` : `project_sub_${i}`]: p,
            };
        });

        return projectData;
    };

    getLastEntry = async () => {
        const { lastEntry } = await DataHandler.get({ url: 'timetracker/lastEntry' });
        const projectData = this.getProjectData(this.state.projectMap, lastEntry);
        this.jobtypeToSet = { ...lastEntry.worktask, value: lastEntry.worktask?.id, label: lastEntry.worktask?.name };
        const notSelected = {
            id: 0,
            value: 0,
            label: this.tr('Not selected'),
            name: this.tr('Not selected'),
        };
        const entry = {
            ...this.state.entry,
            customer: {
                ...lastEntry.customer,
                label: lastEntry.customer?.name,
                value: lastEntry.customer?.id,
            },
            unit:
                !lastEntry.unit || lastEntry.unit?.id == 0
                    ? notSelected
                    : {
                        ...lastEntry.unit,
                        label: lastEntry.unit?.name,
                        value: lastEntry.unit?.id,
                    },
            project: {
                ...lastEntry.project,
                label: lastEntry.project?.name,
                value: lastEntry.project?.id,
            },
            task: lastEntry.task?.id
                ? {
                    ...lastEntry.task,
                    label: lastEntry.task?.name,
                    value: lastEntry.task?.id,
                }
                : notSelected,
            projects_quotation: lastEntry.quote?.id,
            projects_quotation_row: lastEntry.quote_row?.id,
            ...projectData,
        };

        this.setState({ entry }, () => {
            this.checkOverlaps();
        });
    };

    createIdMapFrom = (array) => {
        const map = {};
        for (let i = 0; i < array.length; i++) {
            const value = array[i];
            map[value.id] = value;
        }
        return map;
    };

    getAccountsAndProjects = async () => {
        const item = this.state.entry;

        const projectKey = this.getSelectedProjectKey(item);
        let params: any = { limit: 10000, companies_id: this.props.preventMulticompanyHourentries ? this.context.userObject.companies_id : undefined, current: this.state.entry[projectKey]?.id };
        if (this.state.showOnlyOwn) {
            params = {
                ...params,
                only_own: true,
            };
        }
        const responses = await Promise.all([
            DataHandler.get({ url: `dialogs/customers/`, get_all_units: true, hasAllowedProjects: true, checkHourRight: true, workhour: item.id ?? 0, ...params, }),
            DataHandler.get({ url: `dialogs/projects/`, checkHourRight: true, workhour: item.id ?? 0, ...params }),
        ]);
        const accountsArr = (responses[0] || []).map((a) => ({ ...a, label: a.name, value: a.id }));
        const projects = (responses[1] || []).map((p) => ({ ...p, label: p.name, value: p.id, customers_id: Number(p.unit?.id || 0) > 0 ? p.unit?.id : p.customer?.id }));
        const accounts = accountsArr.filter((a) => a.parent == 0);
        const units = accountsArr.filter((a) => a.parent != 0);
        const accountMap = this.createIdMapFrom(accounts);
        const unitMap = this.createIdMapFrom(units);
        const projectMap = this.createIdMapFrom(projects);

        // selecting correct account in case a unit's id was passed as customer id
        let accountData = {};
        const customer = accountMap[this.state.entry.customer?.id];
        if (!customer) {
            const unit = unitMap[this.state.entry.customer?.id];
            if (unit) {
                const parentAccount = accountMap[unit.parent];
                if (parentAccount) {
                    accountData = {
                        customer: parentAccount,
                        unit,
                    };
                }
            }
        }

        const projectData = this.getProjectData(projectMap, this.state.entry);
        const entry = {
            ...this.state.entry,
            ...accountData,
            ...projectData,
        };
        this.setState({ entry, accounts, units, projects, accountMap, unitMap, projectMap, projectsLoaded: true });
    };

    onClose = (e?, source?) => {
        const {
            functions: { stopWorkhourTimer },
        } = this.context;
        const { timer } = this.props;
        if (timer !== undefined && source == 'cancel') {
            stopWorkhourTimer(timer);
        }
        this.setState({ open: false }, () => {
            setTimeout(() => {
                this.props.onClose && this.props.onClose();
            }, 500);
        });
    };

    checkValidity = (entry) => {
        let valid = true;
        let update = {
            projectError: undefined,
            jobtypeError: undefined,
            dateError: undefined,
            timeError: undefined,
            showInvoiceableRowValidation: false,
        };
        if (!entry.project || !entry.project.id) {
            valid = false;
            update = {
                ...update,
                projectError: this.tr('Please select a project'),
            };
        }
        if (!entry.worktask) {
            valid = false;
            update = {
                ...update,
                jobtypeError: this.tr('Please select a jobtype'),
            };
        }
        const { startdate, enddate, starttime, endtime } = entry;
        const start = moment(startdate, 'YYYY-MM-DD');
        const end = moment(enddate, 'YYYY-MM-DD');

        const {
            functions: { getTimeTrackerSettings },
        } = this.context;

        const projectKey = this.getSelectedProjectKey(entry);
        const timeTrackerSettings = getTimeTrackerSettings(entry[projectKey]?.companies_id);
        if (Number(entry.id) < 1 && start.isBefore(timeTrackerSettings.allow_add_after, 'day')) { // Check allow_add_after only for new entries. Old can be edited.
            valid = false;
            update = {
                ...update,
                dateError: this.tr('Hour entries can only be added after ${date}', { date: moment(timeTrackerSettings.allow_add_after).format(this.context.userObject.dateFormat) }),
            };
        } else if (!start.isValid() || !end.isValid() || end.isBefore(start)) {
            valid = false;
            update = {
                ...update,
                dateError: this.tr('Please check the provided dates'),
            };
        }

        const endTime = moment(endtime, 'HH:mm');
        const startTime = moment(starttime, 'HH:mm');

        if (!starttime || !endtime) {
            valid = false;
            update = {
                ...update,
                timeError: this.tr('Please check the provided times'),
            };
        } else if (endTime.isSameOrBefore(startTime)) {
            valid = false;
            update = {
                ...update,
                timeError: this.tr('Please make sure the start time is before the end time'),
            };
        }

        // Check invoice rows
        if (this.state.invoiceableRows && this.state.invoiceableRows.find((x) => !x.valid)) {
            valid = false;
            update = {
                ...update,
                showInvoiceableRowValidation: true,
            };
        }

        this.setState(update);

        return valid;
    };

    onSave = async (entry) => {
        const { enqueueSnackbar, closeSnackbar, isWorkhourTimer, timer, afterSaveCallback, demoMode } = this.props;
        const valid = this.checkValidity(entry);
        if (!valid) {
            return;
        }

        const {
            functions: { stopWorkhourTimer },
        } = this.context;
        const { invoiceableRows } = this.state;
        let creatingSnackbar: any;
        if (!demoMode) {
            creatingSnackbar = enqueueSnackbar(this.tr('Saving hour entry...'), {
                variant: 'info',
                persist: true,
            });
        }
        const projectKey = this.getSelectedProjectKey(entry);
        const details = {
            ...entry,
            wh_customers_id: entry.customer?.id,
            wh_units_id: entry.unit?.id,
            wh_projects_id: entry[projectKey]?.id,
            wh_projects_resource_id: entry.task?.id,
            wh_worktasks_id: entry.worktask?.id,
            datemode: isWorkhourTimer ? 'timer' : 'normal',
            quote_rows_id: typeof entry.projects_quotation_row === 'object' ? entry.projects_quotation_row.id : entry.projects_quotation_row,
            timezone: moment.tz.guess(),
        };
        let data: any = {
            details,
        };
        const analyticsData = {
            event_date_time: moment().format('DD.MM.YYYY HH:mm:ss'),
            taimer_version: this.context.versionId,
            feature_name: this.props.parentComponent,
            hours_entry_products: 0,
            hours_entry_cpq: 0,
            hours_entry_invoiceable_row: 0,
            hours_entry_mileage: 0,
            hours_entry_daily_allowance: 0,
            hours_entry_expense: 0,
        };
        let eventFunction = '';
        if (Number(entry.id || 0) > 0) {
            eventFunction = 'hours_edited';
        } else {
            eventFunction = 'hours_created';
        }
        if (invoiceableRows.length > 0) {
            const bill_entries: any = [];
            const bill_entries_products: any = [];
            const mileages: any = { mileages: [], additional: [] };
            const others: any = [];
            const dailies: any = [];
            let id: any = -1;

            invoiceableRows.forEach((row) => {
                if (row.type === 'product') {
                    analyticsData.hours_entry_products++;
                    bill_entries.push({
                        id: row.id || id--,
                        description: row.name,
                        vat: row.vat,
                        quantity: row.amount,
                        value: row.unit_price,
                        product_register_id: row.product_register_id,
                        total: row.total,
                        deleted: row.deleted,
                    });
                } else if (row.type === 'cpq') {
                    analyticsData.hours_entry_cpq++;
                    bill_entries.push({
                        id: row.id || id--,
                        description: row.name,
                        vat: row.vat,
                        quantity: row.amount,
                        value: row.unit_price,
                        cpq_id: row.cpq_id,
                        total: row.total,
                        deleted: row.deleted,
                    });
                } else if (row.type === 'mileage') {
                    analyticsData.hours_entry_mileage++;
                    const realId = row.id || id--;
                    mileages.mileages.push({
                        id: realId,
                        mileage_id: row.subtype.id,
                        distance: row.amount,
                        total: row.total,
                        deleted: row.deleted,
                    });

                    row.child.forEach(function (c) {
                        mileages.additional.push({
                            id: c.id || id--,
                            parent: realId,
                            additional_id: c.subtype.id,
                            distance: c.amount,
                            total: c.total,
                            deleted: c.deleted,
                        });
                    });
                } else if (row.type === 'daily') {
                    analyticsData.hours_entry_daily_allowance++;
                    dailies.push({
                        id: row.id || id--,
                        daily_id: row.subtype.id,
                        days: row.amount,
                        total: row.total,
                        deleted: row.deleted,
                    });
                } else if (row.type === 'invoiceable') {
                    analyticsData.hours_entry_invoiceable_row++;
                    bill_entries.push({
                        id: row.id || id--,
                        quantity: row.amount,
                        value: row.unit_price,
                        description: row.name,
                        total: row.total,
                        deleted: row.deleted,
                    });
                } else if (row.type === 'other') {
                    analyticsData.hours_entry_expense++;
                    others.push({
                        id: row.id || id--,
                        description: row.name,
                        total_no_vat: row.total_no_vat,
                        vat: row.vat,
                        total: row.total,
                        deleted: row.deleted,
                        purchase_expense_type_id: row.subtype.id,
                    });
                }
            });
            data = {
                ...data,
                bill_entries,
                bill_entries_products,
                mileages,
                dailies,
                others,
            };
        }

        this.onClose();
        const shownEntry = {
            ...entry,
            project: entry[projectKey] || entry.project,
            start: moment(`${entry.startdate} ${entry.starttime}`, 'YYYY-MM-DD HH:mm').toDate(),
            end: moment(`${entry.enddate} ${(entry.endtime === '00:00:00' || entry.endtime === '24:00:00' ? '23:59:59' : entry.endtime)}`, 'YYYY-MM-DD HH:mm').toDate(),
        };
        if (shownEntry.unit && Number(shownEntry.unit.id) <= 0) {
            delete shownEntry.unit;
        }
        this.props.onEntryChanged && this.props.onEntryChanged(shownEntry);
        if (demoMode) {
            return;
        }
        try {
            const response = await DataHandler.post({ url: `timetracker/dialog` }, data);
            closeSnackbar(creatingSnackbar);
            if (response.status === 'FAILED') {
                if (response.errorcode === 'PROJECT_NOT_MAIN') {
                    enqueueSnackbar(this.tr('Cannot add hour to this project because it has subprojects.'), {
                        variant: 'error',
                    });
                } else if (response.errorcode === 'INVALID_WORKTASK') {
                    enqueueSnackbar(this.tr("Selected worktask can't be used in current project."), {
                        variant: 'error',
                    });
                } else if (response.errorcode === 'OVERLAPS_DISABLED') {
                    enqueueSnackbar(this.tr("Hour entries can't overlap"), {
                        variant: 'error',
                    });
                } else if (response.errorcode === 'INVALID_PROJECT') {
                    enqueueSnackbar(this.tr("This project can't be selected."), {
                        variant: 'error',
                    });
                } else if (response.errorcode === 'NO_PERMISSION') {
                    enqueueSnackbar(this.tr("This project can't be selected (no permission)."), {
                        variant: 'error',
                    });
                } else if (response.errorcode === 'ZERO_HOURS') {
                    enqueueSnackbar(this.tr('Start and end time must be different.'), {
                        variant: 'error',
                    });
                } else if (response.errorcode === 'INVALID_DATES' || response.errorcode === 'INVALID_DATE') {
                    enqueueSnackbar(this.tr('Start and/or end date are not valid.'), {
                        variant: 'error',
                    });
                } else if (response.errorcode === 'NOT_IN_TEAM') {
                    enqueueSnackbar(this.tr('Only project team members can add hours to this project.'), {
                        variant: 'error',
                    });
                } else if (response.errorcode === 'INSERT_PREVENTED_IN_PREV_MONTHS') {
                    enqueueSnackbar(this.tr("Can't add hours to this month"), {
                        variant: 'error',
                    });
                } else if (response.errorcode === 'DESCRIPTION_REQUIRED') {
                    enqueueSnackbar(this.tr('Description is required.'), {
                        variant: 'error',
                    });
                } else if (response.errorcode) {
                    enqueueSnackbar(this.tr('Saving failed!') + ' ' + this.tr('Error') + ': ' + this.tr(response.errorcode), {
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar(this.tr('Saving failed!'), {
                        variant: 'error',
                    });
                }
                return;
            }
            const id = response.id;
            const customerId = details.wh_units_id ? details.wh_units_id : details.wh_customers_id;
            const createdSnackbar: any = enqueueSnackbar(
                <EntityCreatedSnackbar
                    type="hour"
                    id={id}
                    onClose={() => closeSnackbar(createdSnackbar)}
                    actions={[
                        {
                            key: 'activity',
                            onClick: () => {
                                this.context.functions.openActivitySlider({
                                    customers_id: customerId,
                                    projects_id: details.wh_projects_id,
                                });
                            },
                            isHidden: () => !this.context.userObject.hasCrmWritePermission,
                            label: 'Add activity',
                        },
                        {
                            key: 'task',
                            onClick: () => {
                                this.context.functions.addResource({
                                    projects_id: details.wh_projects_id,
                                    origin_point: "add_hours_slider",
                                });
                            },
                            isHidden: () =>
                                !(this.context.addons && this.context.addons.resourcing) ||
                                !(this.context.functions.hasPrivilege('projects', 'project_resourcing_write') || this.context.functions.hasPrivilege('projects', 'own_resourcing_write')),
                            label: 'Add task',
                        },
                        {
                            key: 'view_project',
                            onClick: () => {
                                this.context.functions.updateView({ module: 'projects', action: 'view', id: details.wh_projects_id });
                            },
                            isHidden: () => !this.context.functions.hasPrivilege('projects', 'read'),
                            label: 'View project',
                        },
                    ]}
                />,
                {
                    variant: 'default',
                    autoHideDuration: 5000,
                    className: 'entityCreatedSnackbar',
                }
            );
            if (timer !== undefined) {
                stopWorkhourTimer(timer).then(() => afterSaveCallback && afterSaveCallback());
            } else {
                afterSaveCallback && afterSaveCallback();
            }
            //sendAnalytics(eventFunction, analyticsData);
            if (eventFunction == 'hours_created') {
                this.context.functions.sendMixpanelEvent('track_hours', {
                    /*'From': 'Slider',*/
                    'origin_point': this.props.parentComponent
                });
                this.context.functions.sendMixpanelPeople('set_once', {
                    'first_track_hours_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('set', {
                    'last_track_hours_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('increment', {
                    'lifetime_track_hours': 1,
                });
            }
            setTimeout(() => {
                // only refreshing data when all new entries are saved
                if (!this.props.getLastAddedEvent || isEqual(this.props.getLastAddedEvent(), shownEntry)) {
                    window.dispatchEvent(new Event('workhourSaved'));
                }
            }, 1000);
        } catch (e) {
            console.error(e);
            closeSnackbar(creatingSnackbar);
            enqueueSnackbar(this.tr('Saving hour entry failed!'), {
                variant: 'error',
            });
        }
    };

    onItemChanged = (entry) => {
        this.setState({ entry });
    };

    checkOverlaps = debounce(async () => {
        const {
            functions: { getTimeTrackerSettings },
        } = this.context;
        const { entry } = this.state;
        const data = {
            ...entry,
            start: moment(`${entry.startdate} ${entry.starttime}`, 'YYYY-MM-DD HH:mm').toDate(),
            end: moment(`${entry.enddate} ${entry.endtime}`, 'YYYY-MM-DD HH:mm').toDate(),
        };
        const projectKey = this.getSelectedProjectKey(entry);
        const timeTrackerSettings = getTimeTrackerSettings(entry[projectKey]?.companies_id);
        if (!timeTrackerSettings.disable_hours_overlap) return;
        if (!entry.startdate || !entry.enddate) return;
        const res = await checkOverlaps(data);
        this.setState({ hasOverlaps: res.status !== 'OK' });
    }, 1300);

    getTimeTrackerSettings = () => {
        const {
            functions: { getTimeTrackerSettings },
        } = this.context;
        const { entry } = this.state;
        const projectKey = this.getSelectedProjectKey(entry);
        return getTimeTrackerSettings(entry[projectKey]?.companies_id);
    };

    getSelectedProjectKey = (item) => {
        const keys = Object.keys(item || {});
        const projectKeys = keys.filter((k) => k.startsWith('project_sub_') && !!item[k] && Number(item[k]?.id || 0) > 0);
        if (projectKeys.length == 0) return 'project';
        const sorted = projectKeys.sort((a, b) => Number(a.replace('project_sub_', '')) - Number(b.replace('project_sub_', '')));
        return sorted[sorted.length - 1];
    };

    getParentProjectKey = (item): string | null => {
        const keys = Object.keys(item || {});
        const projectKeys = keys.filter((k) => k.startsWith('project_sub_') && !!item[k] && Number(item[k]?.id || 0) > 0);
        if (projectKeys.length <= 1) return 'project';
        const sorted = projectKeys.sort((a, b) => Number(a.replace('project_sub_', '')) - Number(b.replace('project_sub_', '')));
        return sorted[sorted.length - 2];
    };

    getSelectedAccountKey = (item) => {
        return Number(item.unit?.id || 0) > 0 ? 'unit.id' : 'customer.id';
    };

    onOvertimeChanged = (e) => {
        this.setState({
            entry: {
                ...this.state.entry,
                is_overtime: e.target.checked ? '1' : '0',
            },
        });
    };

    onTreeProjectsFetched = (projects) => {
        if (projects && projects.length === 0) {
            const entryData = {
                ...this.state.entry,
                project: {
                    id: null,
                    name: null,
                    companies_id: null,
                },
            };

            this.setState({
                entry: entryData,
            });
        }

        this.setState({ treeProjectsLoaded: true });
    };

    onTreeProjectChanged = (e, isNew = false) => {
        const { data = e } = e;

        const entryData = {
            ...this.state.entry,
            project: {
                id: data?.id,
                name: data?.label,
                companies_id: data?.companies_id,
            },
        };

        Object.keys(entryData).forEach((key) => {
            if (key.startsWith('project_sub_')) {
                delete entryData[key];
            }
        });

        this.setState(
            {
                entry: entryData,
            },
            () => {
                if (isNew) {
                    this.projectTreeDropdown.current && this.projectTreeDropdown.current.fetchProjects();
                }
            }
        );
    };

    renderProjectTreeDrop = (item) => {
        const projectKey = this.getSelectedProjectKey(item);
        return (
            <ProjectTreeDropdown
                ref={this.projectTreeDropdown}
                label={this.tr('Project')}
                value={item.project?.id}
                disabled={!item.editable}
                disableBeforeInit={true}
                route={'projects/dropdown_new'}
                showWholeTrees={true}
                error={!!this.state.projectError}
                queryParameters={{
                    right: 'read',
                    workhour: item.id,
                    context: 'workhours',
                    includeCurrent: item.project?.id,
                }}
                noOptionsMessage={AddProject}
                noOptionsMessageProps={{
                    selectProps: {
                        companies_id: item[projectKey]?.companies_id,
                        onItemCreated: (item) => this.onTreeProjectChanged(item, true),
                        sliderWidth: 550,
                    },
                }}
                treeDropdownProps={{
                    activateBestMatch: true,
                    highlightMatches: true,
                    useTooltip: true,
                    growOptionContainer: false,
                    usePopper: true,
                    selectedValue: item.project,
                }}
                onlyOwnProjects={this.state.showOnlyOwn}
                onSelect={this.onTreeProjectChanged}
                onProjectsFetched={this.onTreeProjectsFetched}
                clearOnEmpty
            />
        );
    };

    recursivelyFindById = (arr, id) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id == id) {
                return arr[i];
            } else if (arr[i].children && arr[i].children.length > 0) {
                const child = this.recursivelyFindById(arr[i].children, id);
                if (child) {
                    return child;
                }
            }
        }
    };

    getFields = () => {
        const { isWorkhourTimer } = this.props;
        const { entry, accounts, units, projects, jobtypes, resources, hasOverlaps, quotes, quoteRows, dateError, timeError, projectError, jobtypeError } = this.state;
        const timeTrackerSettings = this.getTimeTrackerSettings();

        const projectKey = this.getSelectedProjectKey(entry);
        const parentProjectKey = this.getParentProjectKey(entry);

        const filteredQuoteRows = quoteRows.filter((el) => el.id == 0 || el.costestimate_id == entry.projects_quotation?.id);

        const checkProject = parentProjectKey ? entry[parentProjectKey] : null;

        const fields: EditableField[] = [
            {
                key: 'daterange',
                startKey: 'startdate',
                endKey: 'enddate',
                title: this.tr('Dates'),
                type: 'daterange',
                errorMessage: dateError,
                disabled: !entry.editable,
                afterEdit: () => {
                    this.checkOverlaps();
                    this.getDailyHours();
                    this.getInvoiceableRowData();
                },
            },
            {
                key: 'timerange',
                startKey: 'starttime',
                endKey: 'endtime',
                title: this.tr('Time'),
                type: 'timerange',
                errorMessage: hasOverlaps ? this.tr('Selected time overlaps with existing workhour.') : timeError,
                rightComponent:
                    this.state.overtimeSettings &&
                        this.state.overtimeSettings.activate_overtime_tracking === '1' &&
                        entry[this.getSelectedProjectKey(entry)]?.id != this.state.overtimeSettings.project_to_deduct_overtime &&
                        !VersionContentManager.isFeatureHidden(this.namespace, 'overtime') &&
                        (this.state.dailyHours.can_add_overtime || entry.is_overtime == '1' || entry.is_overtime == '2')
                        ? (item) => (
                            <div className={styles.switch}>
                                <Switch checked={item.is_overtime === '1' || item.is_overtime === '2'} disabled={!item.editable} onChange={this.onOvertimeChanged} />
                                <p>{this.tr('Overtime')}</p>
                            </div>
                        )
                        : undefined,
                additionalProps: {
                    disabled: !entry.editable || (isWorkhourTimer && entry.startdate != entry.enddate),
                    disabledReason: isWorkhourTimer && entry.startdate != entry.enddate ? this.tr('Select single day to edit') : undefined,
                },
                afterEdit: () => {
                    this.checkOverlaps();
                },
                useMidnightEnd: true,
            },
            ...(this.state.useProjectTreeDropdown
                ? [
                    {
                        key: 'project',
                        required: true,
                        customComponent: this.renderProjectTreeDrop,
                        error: !entry.project?.id,
                    },
                ]
                : [
                    {
                        key: 'customer',
                        required: true,
                        title: this.tr('Account'),
                        type: 'select',
                        selectFirstAutomatically: true,
                        options: accounts,
                        triggerSelection: ['unit'],
                        disabled: !entry.editable,
                    },
                    {
                        key: 'unit',
                        title: this.tr('Unit'),
                        type: 'select',
                        selectFirstAutomatically: true,
                        addNoneOption: true,
                        hideIfEmpty: true,
                        options: units,
                        filterBy: 'customer.id',
                        filterByKey: 'parent',
                        disabled: !entry.editable,
                    },
                    {
                        key: 'project',
                        required: true,
                        title: this.tr('Project'),
                        type: 'select',
                        errorMessage: projectError,
                        options: projects,
                        selectFirstAutomatically: true,
                        filterBy: this.getSelectedAccountKey,
                        filterByKey: 'customers_id',
                        divideToSubFieldsKey: 'parent.id',
                        noOptions: AddProject,
                        disabled: !entry.editable,
                        additionalProps: {
                            companies_id: entry[projectKey]?.companies_id,
                            customers_id: Number(entry.unit?.id || 0) > 0 ? entry.unit.id : entry.customer?.id,
                            sliderWidth: 550,
                        },
                        onItemCreated: (project, selectValueCallback) => {
                            const projects = cloneDeep(this.state.projects);
                            projects.unshift({ ...project, label: `${project.name} (${project.project_id})` });
                            this.setState({ projects }, () => {
                                selectValueCallback && selectValueCallback();
                            });
                        },
                        subFieldProps: {
                            type: 'select',
                            title: this.tr('Subproject'),
                            addNoneOption: checkProject?.can_add_hours ?? true,
                            selectFirstAutomatically: checkProject ? !checkProject.can_add_hours : false,
                        },
                    },
                ]),
            {
                key: 'worktask',
                required: true,
                title: this.tr('Jobtype'),
                errorMessage: jobtypeError,
                type: 'select',
                options: jobtypes,
                selectFirstAutomatically: true,
                disabled: !entry.editable,
            },
            ...(this.context.addons && this.context.addons.resourcing
                ? [
                    {
                        key: 'task',
                        title: this.tr('Task'),
                        type: 'select',
                        addNoneOption: true,
                        options: resources,
                        filterBy: (item) => this.getSelectedProjectKey(item) + '.id',
                        selectFirstAutomatically: true,
                        filterByKey: 'projects_id',
                        hideIfEmpty: true,
                        disabled: !entry.editable,
                        setOtherValuesWithSelection: (_, value) => {
                            if (Number(value.quote_rows_id || 0) > 0) {
                                const quoteRow = this.recursivelyFindById(this.state.quoteRows, value.quote_rows_id);
                                if (quoteRow) {
                                    const projects_quotation_row = quoteRow.id;
                                    const quote = this.state.quotes.find((el) => el.id == quoteRow.costestimate_id);
                                    if (quote) {
                                        const projects_quotation = quote.id;
                                        return { projects_quotation, projects_quotation_row };
                                    }
                                }
                            }
                            return {};
                        },
                    },
                ]
                : []),
            ...(this.targetingMap[entry[projectKey]?.companies_id] == '1' // did it like this to avoid quotation fields being reset due to removeValueIfHidden if cost targeting is not in use
                ? [
                    {
                        key: 'projects_quotation',
                        title: this.tr('Quotation'),
                        type: 'select',
                        options: quotes,
                        selectFirstAutomatically: true,
                        filterBy: (item) => this.getSelectedProjectKey(item) + '.id',
                        filterByKey: 'projects_id',
                        hideIfEmpty: true,
                        removeValueIfHidden: true,
                        isHidden: (item) => {
                            const projectKey = this.getSelectedProjectKey(item);
                            return !(this.targetingMap[item[projectKey]?.companies_id] == '1');
                        },
                        disabled: !entry.editable || Number(entry.task?.quote_rows_id || 0) > 0,
                    },
                    {
                        key: 'projects_quotation_row',
                        title: this.tr('Quotation row'),
                        type: 'treeselect',
                        addNoneOption: true,
                        options: filteredQuoteRows,
                        selectFirstAutomatically: true,
                        hideIfEmpty: true,
                        removeValueIfHidden: true,
                        isHidden: (item) => {
                            const projectKey = this.getSelectedProjectKey(item);
                            const hidden = !item.projects_quotation || !(this.targetingMap[item[projectKey]?.companies_id] == '1');
                            return hidden;
                        },
                        disabled: !entry.editable || Number(entry.task?.quote_rows_id || 0) > 0,
                    },
                ]
                : []),
            {
                key: 'description',
                title: this.tr('Description'),
                required: this.getTimeTrackerSettings().hour_entry_description,
                type: 'textarea',
                disabled: !entry.editable,
                maxRows: 3,
                rows: 3,
            },
            {
                key: 'overtime_description',
                title: this.tr('Overtime description'),
                isHidden: (item) => !(this.state.overtimeSettings?.activate_overtime_tracking == '1' && item.is_overtime == '1'),
                required: this.state.overtimeSettings?.activate_overtime_tracking == '1' && this.state.overtimeSettings?.overtime_description_mandatory == '1' && entry.is_overtime == '1',
                disabled: !entry.editable,
            },
        ];

        return fields;
    };

    onAddInvoiceableRow = (type, category) => {
        const company = this.context.userObject.companies_id;

        const invoiceRowIndex = this.state.invoiceableRows.length + 1;
        const extraData: any = {};
        if (type === 'mileage') {
            if (VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'mileages')) {
                this.context.functions.showUpgradeSlider();
                return;
            }
            const mileage = this.state.mileages.filter(x => x.companies_id == company)[0];
            extraData.mileage = mileage;
        } else if (type === 'daily') {
            if (VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'dailies')) {
                this.context.functions.showUpgradeSlider();
                return;
            }
            const daily = this.state.dailies.filter(x => x.companies_id == company)[0];
            extraData.daily = daily;
        } else if (type === 'other') {
            if (VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'otherExpenses')) {
                this.context.functions.showUpgradeSlider();
                return;
            }
            const { taimerAccount } = this.context;
            const types = this.state.types.filter(x => x.companies_id == company)[0] || [];
            extraData.types = types;
            extraData.vat = taimerAccount.defaultVat;
        }

        if (VersionContentManager.isFeatureUpgradeTrigger(this.namespace, type)) {
            this.context.functions.showUpgradeSlider();
            return;
        }

        const row = CreateInvoiceRow(invoiceRowIndex, type, category, extraData);
        const invoiceableRows = [...this.state.invoiceableRows, row];
        invoiceableRows.sort((a, b) => (a.category > b.category ? 1 : a.category === b.category ? 0 : -1));
        this.setState({ invoiceableRows });
    };

    deleteInvoiceableRow = (row) => {
        let invoiceableRows = [...this.state.invoiceableRows];
        if (!row.id) {
            invoiceableRows = invoiceableRows.filter((r) => r.invoiceRowIndex !== row.invoiceRowIndex);
        } else {
            const updated = { ...row, deleted: 1 };
            if (updated.child) {
                updated.child.forEach((c) => (c.deleted = 1));
            }
            const index = invoiceableRows.indexOf(row);
            if (index != -1) {
                invoiceableRows[index] = updated;
            }
        }
        this.setState({ invoiceableRows });
    };

    onInvoiceableRowChange = (rowIndex, data, from) => {
        const invoiceableRows = [...this.state.invoiceableRows];
        let index;
        if (rowIndex) index = invoiceableRows.findIndex((x) => x.invoiceRowIndex === rowIndex);
        else index = invoiceableRows.findIndex((x) => x.id == data.id);
        const row = UpdateInvoiceRow(data, from);
        invoiceableRows[index] = row;
        this.setState({ invoiceableRows });
    };

    onSelectPreviousEntry = (previousEntry) => {
        const notSelectedItem = {
            id: 0,
            name: this.tr('Not selected'),
            label: this.tr('Not selected'),
        };
        let customer = {
            id: previousEntry.customers_id,
            name: previousEntry.customers_name,
            label: previousEntry.customers_name,
        };
        let unit = notSelectedItem;
        if (previousEntry.customers_parents_id) {
            unit = customer;
            customer = {
                id: previousEntry.customers_parents_id,
                name: previousEntry.customers_parents_name,
                label: previousEntry.customers_parents_name,
            };
        }
        let modifiedData = {
            customer,
            unit,
            project: {
                id: previousEntry.projects_id,
                name: previousEntry.projects_name,
                label: previousEntry.projects_name,
                companies_id: previousEntry.companies_id,
            },
            worktask: {
                id: previousEntry.worktasks_id,
                name: previousEntry.worktasks_name,
                label: previousEntry.worktasks_name,
            },
            task:
                !this.state.showPreviousEntryTasks || !previousEntry.tasks_id
                    ? notSelectedItem
                    : {
                        id: previousEntry.tasks_id,
                        name: previousEntry.tasks_name,
                        label: previousEntry.tasks_name,
                    },
            description: this.state.showPreviousEntryDescriptions ? previousEntry.description : '',
        };
        const projectData = this.getProjectData(this.state.projectMap, modifiedData);
        modifiedData = {
            ...modifiedData,
            ...projectData,
        };
        const entryData = {
            ...this.state.entry,
        };
        Object.keys(entryData).forEach((key) => {
            if (key.startsWith('project_sub_')) {
                delete entryData[key];
            }
        });
        const entry = {
            ...entryData,
            ...modifiedData,
        };
        this.forceJobtypeLoad = true;
        this.setState(
            {
                entry,
                jobtypes: [],
            },
            () => {
                entry.description && this.editSlider.current && this.editSlider.current.checkValidity(entry);
            }
        );
    };

    showPreviousEntriesChanged = () =>
        this.setState({ showPreviousEntries: !this.state.showPreviousEntries }, () => {
            this.setSettingValueToLS('showPreviousEntries', this.state.showPreviousEntries);
        });

    showPreviousEntryDescriptionsChanged = (e) =>
        this.setState({ loadingPreviousEntries: true, showPreviousEntryDescriptions: e.target.checked }, () => {
            this.setSettingValueToLS('showPreviousEntryDescriptions', this.state.showPreviousEntryDescriptions);
            this.getPreviousEntries();
        });

    showPreviousEntryTasksChanged = (e) =>
        this.setState({ loadingPreviousEntries: true, showPreviousEntryTasks: e.target.checked }, () => {
            this.setSettingValueToLS('showPreviousEntryTasks', this.state.showPreviousEntryTasks);
            this.getPreviousEntries();
        });

    displayPreviousEntryGrid = () => {
        this.setState((prevState) => ({ previousEntryGrid: !prevState.previousEntryGrid }), () => {
            this.setSettingValueToLS('showPreviousEntryGrid', this.state.previousEntryGrid)
        })
    }

    renderPreviousEntries = () => {
        const { entry, previousEntries, loadingPreviousEntries, showPreviousEntryTasks, showPreviousEntryDescriptions, previousEntryGrid } = this.state;
        if (!this.isNewEntry()) return null;
        return (
            <div className={styles.previousEntries} style={{width: previousEntryGrid ? '50vw' : '420px'}}>
                <div className={styles.topBar}>
                    <h1>{this.tr('Previous entries')}</h1>
                </div>
                <div className={styles.previousEntrySettings}>
                    <div>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.showPreviousEntryDescriptions} onChange={this.showPreviousEntryDescriptionsChanged} />}
                            label={this.tr('Show description')}
                        />
                        {this.context.addons && this.context.addons.resourcing && (
                            <FormControlLabel control={<Checkbox checked={this.state.showPreviousEntryTasks} onChange={this.showPreviousEntryTasksChanged} />} label={this.tr('Show task')} />
                        )}
                    </div>
                    <Tooltip arrow classes={{ arrow: "darkblue-arrow", tooltip: "darkblue-tooltip" }} title={!previousEntryGrid ? this.tr('View as a grid') : this.tr('View as a list')}>
                        <IconButton onClick={this.displayPreviousEntryGrid}>
                            {!previousEntryGrid ? <WindowIcon /> : <ViewStreamIcon />}
                        </IconButton>
                    </Tooltip>


                </div>
                {loadingPreviousEntries ? (
                    <img src={require('../dashboard/insights/img/loading.svg').default} />
                ) : (!previousEntryGrid ? (
                    <ul>
                        {previousEntries.map((entry) => (
                            <li key={entry.id} onClick={() => this.onSelectPreviousEntry(entry)}>
                                <div className={styles.highlightContainer}>
                                    <div className={styles.titles}>
                                        <p>{entry.customers_name}</p>
                                        <h3>{entry.projects_name}</h3>
                                        {showPreviousEntryTasks && entry.tasks_name && <p className={styles.task}>{entry.tasks_name}</p>}
                                        <p>{entry.worktasks_name}</p>
                                        {showPreviousEntryDescriptions && entry.description && <p className={styles.description}>{entry.description}</p>}
                                    </div>
                                    <Button>{this.tr('Select')}</Button>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (

                    <div className={styles.gridEntryContainer}>
                        {previousEntries.map((entry) => (

                            <div className={styles.highlightContainer} key={entry.id} onClick={() => this.onSelectPreviousEntry(entry)}>

                                <div className={styles.titles}>
                                    <p>{entry.customers_name}</p>
                                    <h3>{entry.projects_name}</h3>
                                    {showPreviousEntryTasks && entry.tasks_name && <p className={styles.task}>{entry.tasks_name}</p>}
                                    <p>{entry.worktasks_name}</p>
                                    {showPreviousEntryDescriptions && entry.description && <p className={styles.description}>{entry.description}</p>}
                                </div>
                                <Button>{this.tr('Select')}</Button>

                            </div>



                        ))}
                    </div>

                )

                )
                }
            </div>
        );
    };

    onShowOnlyOwnChanged = (e) => {
        this.setState(
            {
                showOnlyOwn: e.target.checked,
            },
            () => {
                this.setSettingValueToLS('showOnlyOwn', this.state.showOnlyOwn);
                this.getAccountsAndProjects();
            }
        );
    };

    onUseProjectTreeDropdownChanged = (e) => {
        const useProjectTreeDropdown = !e.target.checked;
        let entry = this.state.entry;
        if (!useProjectTreeDropdown) {
            const projectData: any = this.getProjectData(this.state.projectMap, this.state.entry, useProjectTreeDropdown);
            const project = projectData?.project || this.state.entry.project;
            const newEntry = {
                ...this.state.entry,
                customer: {
                    ...project.customer,
                    label: project.customer?.name,
                    value: project.customer?.id,
                },
                unit: {
                    ...project.unit,
                    label: Number(project.unit?.id || 0) > 0 ? project.unit?.name : this.tr('Not selected'),
                    value: project.unit?.id,
                },
                ...projectData,
            };
            entry = newEntry;
        } else {
            const projectKey = this.getSelectedProjectKey(this.state.entry);
            const newEntry = cloneDeep(this.state.entry);
            const project = newEntry[projectKey];
            Object.keys(newEntry).forEach((key) => {
                if (key.startsWith('project_sub_')) {
                    delete newEntry[key];
                }
            });
            entry = {
                ...newEntry,
                project,
            };
        }
        this.setState(
            {
                useProjectTreeDropdown,
                entry,
            },
            () => {
                DataHandler.put({ url: `settings/timetracker_old_drop` }, { value: this.state.useProjectTreeDropdown ? '0' : '1' })
                    .done((res) => {
                        setTimeout(() => {
                            this.context.functions.whoami();
                        }, 1000);
                    })
                    .fail((err) => {
                        console.error(err);
                    });
            }
        );
    };

    getViewSettings = () => {
        return [
            {
                title: this.tr('Show project fields separately'),
                value: !this.state.useProjectTreeDropdown,
                onChange: this.onUseProjectTreeDropdownChanged,
            },
            {
                title: this.tr('Only own projects'),
                value: this.state.showOnlyOwn,
                key: 'only_own',
                onChange: this.onShowOnlyOwnChanged,
            },
        ];
    };

    isNewEntry = () => Number(this.state.entry.id || 0) <= 0;
    canDeleteItem = () => {
        const { entry } = this.state;
        const showDelete = entry.user.id == this.context.userObject.usersId || this.context.addons.delete_user_workhours;
        return entry.editable && showDelete;
    };

    delete = async () => {
        const { entry } = this.state;
        this.context.functions.closeDialog();
        this.props.onEntryChanged && this.props.onEntryChanged(entry);
        this.onClose();
        try {
            const response = await deleteWorkhourEntry(entry.id);
            if (response.status == 'FAILED') {
                this.props.enqueueSnackbar(this.tr('Error in deleting workhour!'), {
                    variant: 'error',
                });
            }
        } catch (e) {
            this.props.enqueueSnackbar(this.tr('Error in deleting workhour!'), {
                variant: 'error',
            });
        }
        // only refreshing data when all new entries are saved
        if (!this.props.getLastAddedEvent || isEqual(this.props.getLastAddedEvent(), entry)) {
            window.dispatchEvent(new Event('workhourSaved'));
        }
    };

    renderDeleteDialogContent = () => {
        const regExp = new RegExp('([a-zA-Z])');
        const { entry } = this.state;
        let delimiter = '.';
        const dateFormat = this.context.userObject.dateFormat;
        dateFormat.split('').map((char) => {
            if (regExp.exec(char) === null) {
                delimiter = char;
            }
            return null;
        });
        const start = `${entry.startdate} ${entry.starttime}`;
        const end = `${entry.enddate} ${entry.endtime}`;
        const workhour = moment(start).format('DD' + delimiter + 'MM' + delimiter + 'YYYY' + ' HH:mm') + ' - ' + moment(end).format('HH:mm');
        return <p id="product-delete-dialog-warning">{this.tr('Are you sure you want to delete workhour "${workhour}"?', { workhour })}</p>;
    };

    onDeleteItem = () => {
        this.context.functions.showDialog(
            <Dialog
                onDialogClose={this.context.functions.closeDialog}
                onDialogSave={this.delete}
                dialogType={'delete'}
                dialogProps={{
                    wider: true,
                    onCloseClick: this.context.functions.closeDialog,
                    open: true,
                    close: this.context.functions.closeDialog,
                    confirmDisabled: false,
                    header: this.tr('Delete workhour?'),
                    warning: this.renderDeleteDialogContent,
                    onConfirm: this.delete,
                }}
            />
        );
    };

    neededProjectsLoaded = () => {
        const { projectsLoaded, treeProjectsLoaded, useProjectTreeDropdown } = this.state;
        return useProjectTreeDropdown ? treeProjectsLoaded : projectsLoaded;
    };

    render() {
        const {
            entry,
            open,
            mileages,
            mileageAdditionals,
            dailies,
            types,
            invoiceableRows,
            showPreviousEntries,
            viewSettingsAnchor,
            hasOverlaps,
            showInvoiceableRowValidation,
            showOnlyOwn,
            useProjectTreeDropdown,
        } = this.state;
        let selectedFiltersCount = 0;

        if (showOnlyOwn) selectedFiltersCount++;
        if (!useProjectTreeDropdown) selectedFiltersCount++;
        return (
            <>
                <FieldEditSlider
                    rightComponent={
                        this.props.demoMode ? (
                            <Tooltip title={this.tr("Data created in this view won't be saved")} classes={{ tooltip: 'darkblue-tooltip' }} arrow placement="left">
                                <div className={styles.testModeIndicator}>
                                    <WarningRounded /> {this.tr('Test mode')}
                                </div>
                            </Tooltip>
                        ) : undefined
                    }
                    ref={this.editSlider}
                    open={open}
                    width={550}
                    onClose={this.onClose}
                    saveDisabled={hasOverlaps || !entry.project || !entry.project.id || !this.neededProjectsLoaded()}
                    saveHidden={!entry.editable}
                    title={this.isNewEntry() ? this.tr('Add hours') : this.tr('Edit hours')}
                    showLeftComponent={showPreviousEntries}
                    leftComponent={this.renderPreviousEntries()}
                    options={this.getOptions()}
                    canDeleteItem={this.canDeleteItem}
                    onDeleteItem={this.onDeleteItem}
                    optionsAsAdd={this.isNewEntry()}
                    onSave={this.onSave}
                    onItemChanged={this.onItemChanged}
                    item={entry}
                    fields={this.getFields()}
                    additionalFields={
                        <InvoiceRowsEdit
                            className="full"
                            headers={this.invoiceableRowHeaders}
                            item={entry}
                            company={this.context.userObject.companies_id}
                            rows={invoiceableRows}
                            onDelete={this.deleteInvoiceableRow}
                            onChange={this.onInvoiceableRowChange}
                            mileage={mileages}
                            types={types}
                            mileageAdditional={mileageAdditionals}
                            daily={dailies}
                            showValidationResult={showInvoiceableRowValidation}
                            disabled={!entry.editable}
                        />
                    }
                >
                    {(sliderContent) => (
                        <div className={styles.content}>
                            {this.isNewEntry() && !this.props.demoMode && (
                                <div className={styles.toolbar}>
                                    <button onClick={this.showPreviousEntriesChanged}>{showPreviousEntries ? this.tr('Hide previous entries') : this.tr('Show previous entries')}</button>
                                    <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={this.tr('Edit view settings')}>
                                        <Badge badgeContent={selectedFiltersCount} className={styles.badge}>
                                            <div onClick={(e) => this.setState({ viewSettingsAnchor: e.target })} className={styles.viewSettingsButton}>
                                                <RemoveRedEye />
                                                <ArrowDropDown className="small" />
                                            </div>
                                        </Badge>
                                    </Tooltip>
                                </div>
                            )}
                            {sliderContent}
                        </div>
                    )}
                </FieldEditSlider>
                <Popover
                    onClose={() => this.setState({ viewSettingsAnchor: null })}
                    anchorEl={viewSettingsAnchor}
                    open={!!viewSettingsAnchor}
                    anchorOrigin={{ vertical: 45, horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {this.getViewSettings().map((vs, i) => {
                        return (
                            <div key={i} className={styles.viewSetting}>
                                <FormControlLabel control={<Switch color="primary" name={vs.key} checked={vs.value} onChange={vs.onChange} />} label={vs.title} />
                            </div>
                        );
                    })}
                </Popover>
            </>
        );
    }
}

export default withSnackbar(AddHoursSlider);
