import React from "react";
import { Bar, Chart } from "react-chartjs-2";
import TaimerComponent from "../../../../TaimerComponent";
import _ from "lodash";
import './LeaderBoardChart.scss';


class LeaderboardChart extends TaimerComponent {
  static classNameForWrapper = "leaderboardChart";

  constructor(props, context) {
    super(props, context, "charts/ProfitLossChart");

    this.refChart = React.createRef();
  }

  render() {
    const {
      labels,
      datasets,
      currency,
      displayLegend,
      hiddenIndexes,
      toggleHidden,
      options,
      stacked,
      xStacked,
    } = this.props;
    const {
      functions: { presentCurrency },
      taimerAccount
    } = this.context;

    const data = {
      labels,
      datasets
    };

    const currencyFormatter = new Intl.NumberFormat(
      taimerAccount.numberFormat,
      {
        style: "currency",
        currency,
        currencyDisplay: "symbol",
        notation: "compact"
      }
    );

    return (<Bar
        ref={this.refChart}
        data={data}
        width={"100%"}
        options={{
          ...options,
          indexAxis: 'y',
          barValueSpacing: 20,
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: "y"
              },
              zoom: {
                enabled: false
              },
            },
            tooltip: {
              ...options.plugins.tooltip,
              callbacks: {
                labelColor: tooltipItem => {
                  return {
                    backgroundColor:
                      datasets[tooltipItem.datasetIndex].borderColor
                  };
                },
                label: (tooltipItem) => {
                  const dataset = tooltipItem.dataset;
                  var label =
                    " " + _.escape(dataset.label) || "";
  
                  if (label) {
                    label += ": ";
                  }
                  label += presentCurrency(tooltipItem.raw, currency);
                  return label;
                }
              }
            },
            legend: {
              ...options.plugins.legend,
              display: displayLegend,
              onClick: (e, legendItem) => {
                const { datasetIndex } = legendItem;
                toggleHidden(datasetIndex);
              },
              labels: {
                ...options.plugins.legend.labels,
                generateLabels: () => {
                  return datasets.map((obj, i) => {
                    return {
                      text: obj.label,
                      datasetIndex: i,
                      pointStyle: "rectRounded",
                      fillStyle: obj.borderColor,
                      lineCap: "round",
                      lineJoin: "round",
                      strokeStyle: "#FFF",
                      lineWidth: 5,
                      hidden: hiddenIndexes.indexOf(i) != -1
                    };
                  });
                }
              }
            },
          },
          onClick: (event, elements, chart) => {
            const activePoint = chart.getElementsAtEventForMode(
              event, 'nearest', { intersect: true }, false
            )[0];

            if (!activePoint) return;

            data.datasets &&
              this.props.onClick(
                data.datasets[activePoint.datasetIndex],
                activePoint.index
              );
          },
          scales: {
            ...options.scales,
            y: {
                ...options.scales.y,
                id: null,
                stacked: stacked ?? false,
                beginAtZero: true,
                min: data.labels[0],
                max: data.labels[Math.min(4, data.labels.length - 1)],
                ticks: {
                  ...options.scales.y.ticks,
                }
              },
            x: {
                ...options.scales.x,
                stacked: xStacked ?? false,
                ticks: {
                  ...options.scales.x.ticks,
                  min: 0,
                  callback: (value, index, values) => {
                    return currencyFormatter.format(value);
                  }
                }
              }
          }
        }}
      />);
  }
}

export default LeaderboardChart;
