import React, { Ref } from 'react';
import TaimerComponent from '../../TaimerComponent';
import { Button } from '@mui/material';
import styles from './GothiaImportView.module.scss';
import { Check } from '@mui/icons-material';
import DataHandler from '../../general/DataHandler';
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined'
import { withSnackbar, WithSnackbarProps } from 'notistack';
import List from '../../list/List';
import DateCell from '../../list/cells/DateCell';
import SettingRow from '../../list/rows/SettingRow';
import CurrencyListCell from "../../list/CurrencyListCell";
import ListCell from '../../list/ListCell';
import CheckboxCell from '../../list/cells/CheckboxCell';
import PropsOnlyListRow from '../../list/PropsOnlyListRow';

interface Props extends WithSnackbarProps{
    enqueueSnackbar: any
    onClose: any
}

interface State {
    listData: any
}

class PaymentRow extends PropsOnlyListRow {
    render() {
        const cells = {
            checked: <CheckboxCell
                checked={this.props.checked} 
                onClick={() => {
                    this.props.listRef.check(this.props.data.id);
                }}
                width={this.props.columnWidthMap['checked']}
            />,
            payment_date: <DateCell
                width={this.props.columnWidthMap['payment_date']}
                name="payment_date"
                value={this.props.data['payment_date']}
                editable={false}
            />,
            reference: <ListCell 
                width={this.props.columnWidthMap['reference']}
                name="reference"
                value={this.props.data['reference']}
                editable={false}
            />,
            amount: <CurrencyListCell
                width={this.props.columnWidthMap['amount']}
                value={this.props.data['amount']}
                name="amount"
                currency={"EUR"}
                editable={false}
            />,
            total: <CurrencyListCell
                width={this.props.columnWidthMap['total']}
                value={this.props.data['total']}
                name="total"
                currency={"EUR"}
                editable={false}
            />,
        };    

        return <div className="listElement mainLevel row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class GothiaImportView extends TaimerComponent<Props, State> {
    refList: any;
    uploadRef: any;
    columns: any;
    dColConf: any;

    constructor(props, context) {
        super(props, context, 'general/GothiaImportView');
        this.state = {
            listData: []
        };

        this.refList = React.createRef();
        this.uploadRef = React.createRef();

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false,
            hideable: false
        };
        
        this.columns = [
            { width: 50, name: "checked", header: "", columnHeaderType: "checkbox", ...this.dColConf },
            { width: 100, name: "payment_date", header: this.tr("Payment date"), ...this.dColConf },
            { width: 150, name: "reference", header: this.tr("Reference number"), ...this.dColConf },
            { width: 150, name: "amount", header: this.tr("Amount"), ...this.dColConf },
            { width: 150, name: "total", header: this.tr("Total"), ...this.dColConf },

        ];
    }

    onDragOver = (evt: any) => {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy';
    };

    uploadFile = (evt: any) => {
        evt.stopPropagation();
        evt.preventDefault();

        const { userObject } = this.context;
        const { taimerAccount: { attachmentMaxSize } } = this.context;

        const file = evt.target.files[0] || evt.dataTransfer.files[0];

        if (file.size > attachmentMaxSize) {
            this.props.enqueueSnackbar(this.tr('Selected file is too large'), {
                variant: "error"
            });
            return;
        }

        DataHandler.file({ url: `settings/gothia/parsefile` }, file, undefined, res => {
            if (res.responseJSON === false) {
                /*this.props.enqueueSnackbar(this.tr('Uploading file failed'), {
                    variant: "error"
                });*/
                return;
            } else {
                /*this.props.enqueueSnackbar(this.tr('File uploaded'), {
                    variant: "success"
                });*/
                this.populateList(res.responseJSON);
                return;
            }
        });
    }

    populateList = (data: any) => {
        this.setState({listData: data});
    }

    fileChooserClicked = () => {
        if (this.uploadRef.current) {
            this.uploadRef.current.click()
        }
    }

    importSelected = () => {
        const checkedIds = this.refList.current.getCheckedRows();

        const filteredList = this.state.listData.filter((item) => {
            return checkedIds.includes(item.id.toString());
        });
        
        DataHandler.post({url: `/settings/gothia/payments/`, data: filteredList}).done((response) => {
            this.props.onClose && this.props.onClose();
        });
    }

    render() {
        return (
            <div id={styles.GothiaImportView}>
                {this.state.listData.length == 0 && 
                    <div className={styles.addFile} onDragOver={this.onDragOver} onDrop={this.uploadFile}>
                        <div>
                            <CloudDownloadOutlined /><br />
                            <span onClick={() => this.fileChooserClicked()}>{this.tr("Choose a file")}</span> {this.tr("or drag it here")}
                            <input ref={this.uploadRef} onChange={this.uploadFile} type="file" />
                        </div>
                    </div>
                }
                {this.state.listData.length > 0 &&
                    <>
                    <div className={styles.listContainer}>
                        <List
                            ref={this.refList} 
                            columns={this.columns}
                            height="auto"
                            listRowType={PaymentRow}
                            data={this.state.listData}
                            useAllCheckedExcept={true}
                            ignoreRowPropsChange={false}
                            handler={this}
                            noStateData={true}
                        />
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button disabled={false} className={'blue ' + styles.importButton} size="large" onClick={() => this.importSelected()}>
                                {this.tr("Import payments")}
                        </Button>
                    </div>
                    </>
                }
            </div>
        );
    }
}

export default withSnackbar(GothiaImportView);
