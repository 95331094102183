import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";

import GeneralIcons from "../../../../general/GeneralIcons";
import {
  Check,
  RemoveRedEye,
  ExpandMore,
  ExpandLess,
  ViewColumn,
  CheckBoxOutlineBlank,
  CheckBox,
  Person,
} from "@mui/icons-material";
import InsightDropDown from '../../../../dashboard/insights/InsightDropDown';
import HeaderButton from '../../../components/HeaderButton';
import { ReactComponent as ProjectIcon } from './img/Project.svg';

export const MenuItem = props => {
  const { item, isMultiSelect } = props;
  return (
    <div onClick={item.onClick && item.onClick} onMouseUp={item.onMouseUp && item.onMouseUp}  className="menu-item">
      {isMultiSelect && (
        <div className="multi-select-box">
          {!!item.selected && <Check className="menu-item-icon" />}
        </div>
      )}
      {item.Icon && !isMultiSelect && (
        <item.Icon
          style={{ fill: item.color || "#b2b9c9" }}
          className="menu-item-icon"
        />
      )}
      <p>{item.text}</p>
    </div>
  );
};