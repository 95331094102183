import React, { Component } from 'react';

/* material ui */
import Button from '@mui/material/Button';
import WarningIcon from "@mui/icons-material/Warning";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

/* css */
// import './Confirm.css';

class Confirm extends React.Component {
    static defaultProps = {
        cancelText: "",
        confirmText: "",
        confirmationSummary: [],
        onCancel: () => {},
        onConfirm: () => {},
        title: "",
        subtitle: "",
        text: "",
        extraContent: undefined,
        isLoading: false
        // TODO:
        // Replace the delete confirm dialog with this, and make this dialog 
        // extensible somehow.
        // variant: "default", // TODO: default, delete
    }


    constructor(props) {
        super(props);

        this.cancel  = this.cancel.bind(this);
        this.confirm = this.confirm.bind(this);
    }


    cancel() {
        this.props.onCancel(); 
    }


    confirm() {
        this.props.onConfirm();
    }

    renderContent = () => {
        if (this.props.isLoading) {
            return <div className="loading"><img src={require('../../dashboard/insights/img/loading.svg').default} /></div>
        }

        return (
            <DialogContent className="content">
                <DialogContentText>
                    {this.props.text}
                    <ul>
                        {this.props.confirmationSummary.map(e => {
                            return (
                                <li><p>{e.name}: <strong>{e.value}</strong></p></li>
                            );
                        })}
                    </ul>
                </DialogContentText>
                {this.props.extraContent && this.props.extraContent()}
            </DialogContent>
        )
    }

    render() {
        return (
            <div className="confirm main implementation">
                <DialogTitle className="title">
                    {this.props.title}<span className="subtitle">{this.props.subtitle}</span>
                </DialogTitle>
                {this.renderContent()}
                <DialogActions className="buttons">
                    <Button onClick={() => {
                        this.cancel();
                    }} variant="text" className="white">
                        {this.props.cancelText}
                    </Button>
                    <Button disabled={this.props.isLoading || this.props.disableConfirm} onClick={() => {
                        this.confirm();
                    }} variant="contained" color="primary">
                        {this.props.confirmText}
                    </Button>
                </DialogActions>
            </div>
        );
    }
}

export default Confirm;
