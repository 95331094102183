import React from 'react';
import moment from "moment";
import ListRow from "../ListRow";
import ListCell from "../ListCell";
import MenuItem from '@mui/material/MenuItem';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import DataHandler from '../../general/DataHandler';
import NumberListCell from "../cells/NumberListCell";
import UserAvatarCell from "../cells/UserAvatarCell";
import { SettingsContext } from './../../SettingsContext';

class UsageListRow extends ListRow {
	static contextType = SettingsContext;

	constructor(props, context) {
		super(props, context, undefined, "list/rows/UsageListRow");	
	}


	render() {
		const { data } = this.props;		
		const className = "usageListRow listElement row";
		let availability_percent = (data.hours_possible_excluding_vacations > 0 ? (data.hours_resourced / data.hours_possible_excluding_vacations * 100) : 100).toFixed(0);
		let availability_percent_text = availability_percent;
		if(isNaN(availability_percent) || !isFinite(availability_percent)) {
			availability_percent_text = "-";
		} 
		let user = [
			{
				label: data.lastname + " " + data.firstname,
				name: data.lastname + " " + data.firstname,
				firstname: data.firstname,
				lastname: data.lastname,
				color: data.color,
				id: data.id,
		}];
		const cells = {
			user_icon:
				<UserAvatarCell
					width={this.props.columnWidthMap['user_icon']}
					name="user_icon"
					editable={false}
					value={user}
                    listCellProps={{ zeroBasis: true }}
					users={user}/>,
			lastname:
				<ListCell
					width={this.props.columnWidthMap['lastname']}
					name="lastname"
					editable={false}
                    zeroBasis={true}
					value={data.lastname} />,			
			firstname:
				<ListCell
					width={this.props.columnWidthMap['firstname']}
					name="firstname"
					editable={false}
                    zeroBasis={true}
					value={data.firstname} />,			
			hours_possible_including_vacations:
				<NumberListCell
					width={this.props.columnWidthMap['hours_possible_including_vacations']}
					name="hours_possible_including_vacations"
					editable={false}
					value={data.hours_possible_including_vacations}
					decimals={2}
                    zeroBasis={true}
					allowEmpty/>,
			hours_possible_excluding_vacations:
				<NumberListCell
					width={this.props.columnWidthMap['hours_possible_excluding_vacations']}
					name="hours_possible_excluding_vacations"
					editable={false}
					value={data.hours_possible_excluding_vacations}
					decimals={2}
                    zeroBasis={true}
					allowEmpty/>,
			hours_away:
				<NumberListCell
					width={this.props.columnWidthMap['hours_away']}
					name="hours_away"
					editable={false}
					value={data.hours_away}
					decimals={2}
                    zeroBasis={true}
					allowEmpty/>,
			hours_resourced:
				<NumberListCell
					width={this.props.columnWidthMap['hours_resourced']}
					name="hours_resourced"
					editable={false}
					value={data.hours_resourced}
					decimals={2}
                    zeroBasis={true}
					allowEmpty/>,
			hours_free:
				<NumberListCell
					width={this.props.columnWidthMap['hours_free']}
					name="hours_free"
					editable={false}
					value={data.hours_possible_excluding_vacations - data.hours_resourced}
					decimals={2}
                    zeroBasis={true}
					allowEmpty/>,
			availability:
				<ListCell
					width={this.props.columnWidthMap['availability']}
					name="availability"
					editable={false}
					onlyDisplay
                    zeroBasis={true}
					className="availability">
						<div className="percent">{availability_percent_text + "%"}</div>
						<div className="bar">
							<div className={"indicator" + (availability_percent >= 100 ? ' over' : '')} style={{width: availability_percent + '%'}}></div>
						</div>					
				</ListCell>,	
		};

		const childRows = this.createChildren(this.props.children, UsageListRow);

		return (
			<div className="listElement">
				<div className={className} style={{ height: !this.props.hidden ? "44px" : "0px", lineHeight: "44px" }}>
					{this.props.columnOrder.map(columnName => cells[columnName])}
				</div>
				{childRows !== false && <div className="listElement">{childRows}</div>}
			</div>
		);
	}
}

export default UsageListRow;
