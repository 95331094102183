import React from "react";

import { SettingsContext } from "../../../SettingsContext";
import TaimerComponent from "../../../TaimerComponent";

//import styles from './UnbilledHoursBlock.module.scss';
import "./UnbilledHoursBlock.css";
import BlockBase from "../BlockBase";
import { BlockProps } from "../../base/CustomViewBase";
import UnbilledHoursBlockRow from "./UnbilledHoursBlockRow";
import List from "../../../list/List";
import colors from "../../../colors";
import DataHandler from '../../../general/DataHandler';
import { ColumnHeaderButton } from '../../../list/ListHeader';
import { InvoiceMaterialTypeFilterName, UnbilledHours } from "../../../projects/ProjectStatistics";
import { ThumbUp, ThumbDown } from '@mui/icons-material';
interface Props {
    blockProps: BlockProps;
    unbilledHours: UnbilledHours[];
    currency: string;
    project: any;
    onCheckedUninvoicedHours?: (ids: string[]) => void;
}

interface State {
    editSliderData?: any;
    products?: any;
    cpqs?: any;
    addMenuAnchor?: any;
}

export default class UnbilledHoursBlock extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    columns = [
        { field: "checked", name: "checked", header: "", /*columnHeaderType: "checkbox",*/ width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false, },
        { field: "name", name: "name", header: this.tr("Worktask"), width: 250, showMenu: false, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, },
        { field: "quantity", name: "quantity", header: this.tr("Quantity"), width: 50, showMenu: false, resizeable: true, moveable: false, hideable: true, showResizeMarker: true, },
        { field: "billable", name: "billable", header: this.tr("Billable hours"), width: 50, showMenu: false, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, },
        { field: "unbillable", name: "unbillable", header: this.tr("Non-billable hours"), width: 50, showMenu: false, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, },
        { field: "value", name: "value", header: this.tr("Hour price"), width: 50, showMenu: false, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, },
        { field: "billable_amount", name: "billable_amount", header: this.tr("Billable value"), width: 50, showMenu: false, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, },
        { field: "total", name: "total", header: this.tr("Total"), width: 100, showMenu: false, resizeable: true, moveable: false, hideable: false, showResizeMarker: true, },
    ];

    materialTypes: { key: InvoiceMaterialTypeFilterName, id: string, name: string, label: string, value: string, color: string }[] = [
        { key: "hour_entry", id: '1', name: this.tr('Hour entry'), label: this.tr('Hour entry'), value: '1', color: colors.greenish_cyan },
        { key: "scheduled_invoice", id: '2', name: this.tr('Scheduled invoice'), label: this.tr('Scheduled invoice'), value: '2', color: "#f52b2b" },
        { key: "expense", id: '4', name: this.tr('Expense'), label: this.tr('Expense'), value: '4', color: "#ffaf0f" },
        { key: "travel_expense", id: '8', name: this.tr('Travel expense'), label: this.tr('Travel expense'), value: '8', color: "#ffaf0f" },
        { key: "quote_row", id: '5', name: this.tr('Quote row'), label: this.tr('Quote row'), value: '5', color: "#ffaf0f" },
        { key: "received_invoice", id: '6', name: this.tr('Received invoice'), label: this.tr('Received invoice'), value: '6', color: "#ffaf0f" }
    ];

    list;

    constructor(props, context) {
        super(props, context, "customview/blocks/UnbilledHours/UnbilledHoursBlock");

        this.list = React.createRef();
        this.state = {
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.unbilledHours !== this.props.unbilledHours) {
            this.list.current && this.list.current.setState({ isLoading: false});
        }
    }

    onCheck = (checked: string[]|boolean, check: boolean|null, rowData: UnbilledHours|null) => {
        const { unbilledHours, onCheckedUninvoicedHours } = this.props;

        if (Array.isArray(checked)) {
            let ids = checked.map(x => x);
            //get children ids
            if (check === true && rowData?.children && rowData?.children?.length > 0) {
                ids = ids.concat(rowData.children);
            } else if (check === false && rowData?.children && rowData?.children?.length > 0) {
                ids = ids.filter((id) => (rowData?.children && !rowData?.children.includes(id)));
            }

            //this piece of extreme ugliness unchecks parents which do not have any checked workinghours in children
            const parentsWithCheckedWorkingHours = unbilledHours.filter(x => x.workinghour_children?.some(id => ids.includes(id))).map(x => x.id);
            const workinghours = unbilledHours.filter(x => x?.is_workinghour).map(x => x.id);
            ids = ids.filter((id) => parentsWithCheckedWorkingHours.includes(id) || workinghours.includes(id)).concat(parentsWithCheckedWorkingHours);

            onCheckedUninvoicedHours?.(ids);
        }
    }

    toggleBillable = (workingHourId, projectsId, billable) => {
        this.list.current && this.list.current.setState({ isLoading: true});
        const data = {
            sub_type: 1,
            all_workhour_ids: workingHourId,
            billable: {value: billable ? -1 : 0},
        };
        DataHandler.post({url: `projects/${projectsId}/cost`}, data).done(() => {
            window.dispatchEvent(new Event('invoiceMaterialChanged'));
        });
    }

    render() {
        const { blockProps, unbilledHours, currency, project: { companies_id } } = this.props;

        return (
            
                <BlockBase
                    {...blockProps} 
                    topbarComponent={<div style={{height: 36}} />}
                    >

                    <List
                        ref={this.list}
                        className="unbilledHours-list"
                        data={unbilledHours}
                        checkedRows={unbilledHours.filter(x => x.selected).map(x => x.id)}
                        columns={this.columns}
                        trimHeight={20}
                        rowHeight={44}
                        minHeight={200}
                        maxHeight={500}
                        height="auto"
                        listRowType={UnbilledHoursBlockRow}
                        noStateData={true}
                        onCheckAll={() => this.onCheck(true, null, null)}
                        onUncheckAll={() => this.onCheck(false, null, null)}
                        roundbutton={(e) => this.setState({addMenuAnchor: e.target}) }
                        treeData={true}
                        parentKey={"parentId"}
                        idType="string"
                        parentsExpandedOnInit={false}
                        rowProps={{
                            onCheck: this.onCheck,
                            company: companies_id,
                            onlyDisplay: true,
                            editable: false,
                        }}
                        sharedData={{
                            currency
                        }}
                        enableToolbar={true}
                        hiddenToolbarColumns={["export", "edit", "delete", "selected_quantity"]}
                        additionalToolbarColumns={[
                            { name: 'markBillable', header: '', columnHeaderType: 'markBillableButton', width: 100 },
                            { name: 'markNonbillable', header: '', columnHeaderType: 'markNonbillableButton', width: 100 },
                        ]}
                        additionalToolbarButtons={{
                            markBillableButton: <ColumnHeaderButton 
                                color={"green"}
                                title={this.tr("Mark as billable")}
                                onClick={() => {
                                    const selectedWorkinghours = unbilledHours.filter(x => x.selected && x.is_workinghour);
                                    if (selectedWorkinghours.length > 0) {
                                        const ids = selectedWorkinghours.map(x => x.workinghour_id).toString();
                                        this.toggleBillable(ids, selectedWorkinghours[0].projects_id, 0);
                                    }
                                }} 
                                icon={ThumbUp}
                            />,
                            markNonbillableButton: <ColumnHeaderButton 
                                color={"red"} 
                                title={this.tr("Mark as non-billable")}
                                onClick={() => {
                                    const selectedWorkinghours = unbilledHours.filter(x => x.selected && x.is_workinghour);
                                    if (selectedWorkinghours.length > 0) {
                                        const ids = selectedWorkinghours.map(x => x.workinghour_id).toString();
                                        this.toggleBillable(ids, selectedWorkinghours[0].projects_id, -1);
                                    }
                                }}  
                                icon={ThumbDown}
                            />,
                        }}
                    />
            </BlockBase>
        );
    }
}  