import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import { Button, Drawer } from '@mui/material';
import styles from './GothiaSlider.module.scss';
import { Close } from '@mui/icons-material';
import GothiaImportView from './GothiaImportView';
import Slider from '../../general/Slider';

interface Props {
    ref?: any;
    open: boolean;
    onClose?: () => void;
    enqueueSnackbar: any;
}

interface State {
    open: boolean;
}

class GothiaSlider extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'general/GothiaSlider');
        this.state = {
            open: false,
        };
    }

    onClose = () => {
        this.setState({ open: false }, () => {
            setTimeout(() => {
                this.props.onClose && this.props.onClose();
            }, 500);
        });
    };

    componentDidMount = () => {
        if (this.props.open) {
            this.setState({ open: true });
        }
    };

    render() {
        const { open } = this.state;
        const { ref } = this.props;

        return (
            <Slider open={open} title={this.tr("Gothia payments")} onClose={this.onClose}>
                <div id={styles.GothiaSlider}>
                    <GothiaImportView onClose={this.onClose} />
                </div>
            </Slider>
        );
    }
}

export default GothiaSlider;
