import React from 'react';
import classNames from 'classnames';

/* context */
import { SettingsContext } from './../SettingsContext';

import List from '../list/List';
import ListRow from "../list/ListRow";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import ListCell from "../list/ListCell";
import ContextMenu from '../general/ContextMenu';
import DataHandler from "../general/DataHandler";
import TextAreaCell from '../list/cells/TextAreaCell';
import DataList from './../general/DataList';
import AdvancedSearch from "../search/AdvancedSearch";

import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Switch from "@mui/material/Switch";
import withStyles from '@mui/styles/withStyles';

import { withSnackbar } from 'notistack';

import './TranslationTool.css';

const styles = theme => ({
    dialogRoot: {
        height: "80%"
    }
});

class HeaderRow extends ListRow {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props);
    }

    render = () => {

        const { data } = this.props;

        return (
            <div className="header-row listElement row">
                <ListCell
                    name="module"
                    editable={false}
                    className="listElement row"
                    value={data.name} />
            </div>
        )
    }
}

class TranslationRow extends PropsOnlyListRow {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props);

    }

    defineClassName() {
        return "translations-row";
    }

    onEdit = (name, value) => {
        const { path, tr } = this.props.data;
        const editObj = {
            path: path,
            key: tr,
            name: name,
            value: value
        }
        this.props.rowProps.onEdit(editObj);
    }

    defineCells() {

        const { columnWidthMap, columnConfig, data, rowProps } = this.props;

        const { taimerAccount, userObject } = this.context;

        const commonProps = {
            editable: true,
            onEdited: this.onEdit,
            runOnEditedOnInput: false,
            listCellProps: {
                editable: true,
                inEditMode: true
            }         
        };

        let cells = {
            tr:
                <ListCell
                    editable={false}
                    className="keyCell"
                    forceToolTip
                    showTooltipForOverflownText
                    name="tr"
                    value={data.tr} />,            
            en:
                <TextAreaCell
                    {...commonProps}
                    name="en"
                    value={data.en} />,
            en_us:
                <TextAreaCell
                    {...commonProps}
                    name="en_us"
                    value={data.en_us} />,
            en_au:
                <TextAreaCell
                    {...commonProps}
                    name="en_au"
                    value={data.en_au} />,
            en_ca:
                <TextAreaCell
                    {...commonProps}
                    name="en_ca"
                    value={data.en_ca} />,
            fi:
                <TextAreaCell
                    {...commonProps}
                    name="fi"
                    value={data.fi} />,
            se: 
                <TextAreaCell
                    {...commonProps}
                    name="se"
                    value={data.se} />,
            lt: 
                <TextAreaCell
                    {...commonProps}
                    name="lt"
                    value={data.lt} />,
            da: 
                <TextAreaCell
                    {...commonProps}
                    name="da"
                    value={data.da} />,
            nn: 
                <TextAreaCell
                    {...commonProps}
                    name="nn"
                    value={data.nn} />,
            es: 
                <TextAreaCell
                    {...commonProps}
                    name="es"
                    value={data.es} />,
            pt: 
                <TextAreaCell
                    {...commonProps}
                    name="pt"
                    value={data.pt} />,
            it: 
                <TextAreaCell
                    {...commonProps}
                    name="it"
                    value={data.it} />,
            ee: 
                <TextAreaCell
                    {...commonProps}
                    name="ee"
                    value={data.ee} />,
            fi_special1: 
                <TextAreaCell
                    {...commonProps}
                    name="fi_special1"
                    value={data.fi_special1} />,
            en_special1: 
                <TextAreaCell
                    {...commonProps}
                    name="en_special1"
                    value={data.en_special1} />
        };

        return cells;
    }
};

class WrapperRow extends React.Component {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props);
    }

    defineClassName = () => {
        return "invoice-row";
    };   

    render = () => {

        const { data } = this.props;

        return (
            <React.Fragment>
                {!data.header ? <TranslationRow 
                    {...this.props}
                    disableInitialFocus
                    />
                    :
                <HeaderRow 
                    data={data}
                     />}
            </React.Fragment>
        )
    }
};



class TranslationTool extends React.Component {    

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props);

        this.state = {
            rows: [],
            selectedModule: 1,
            selectedLang: {id: 'en', label: "English"},
            tableType: 'filter',
            count: 0,
            perPage: 30,
            hideUselessColumns: true
        }

        this.dialog = React.createRef();
        this.list = React.createRef();
        this.advancedSearch = React.createRef();

        this.fields = [
            { field: "tr", name: "tr", header: "key", width: 120, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { field: "en", name: "en", header: "English", width: 250, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { field: "en_us", name: "en_us", header: "English (us)", width: 250},
            { field: "en_au", name: "en_au", header: "English (au)", width: 250},
            { field: "en_ca", name: "en_ca", header: "English (ca)", width: 250},
            { field: "fi", name: "fi", header: "Suomi", width: 250},
            { field: "se", name: "se", header: "Svenska", width: 250},
            { field: "lt", name: "lt", header: "Lietuvių kalba", width: 250 },
            { field: "da", name: "da", header: "Dansk", width: 250 },
            { field: "nn", name: "nn", header: "Norsk", width: 250 },
            { field: "es", name: "es", header: "Español", width: 250 },
            { field: "pt", name: "pt", header: "Português", width: 250 },
            { field: "it", name: "it", header: "Italiano", width: 250 },
            { field: "ee", name: "ee", header: "Eesti", width: 250 },
            { field: "fi_special1", name: "fi_special1", header: "Suomi Ourbusiness", width: 250 },
            { field: "en_special1", name: "en_special1", header: "English Ourbusiness", width: 250 },
        ];

        this.activelyUsedLanguages = ['tr','en', 'en_us', 'en_au', 'en_ca', 'fi', 'se', 'ee', 'fi_special1', 'en_special1'];

        this.modules = [];        

    }

    optionRenderer = () => {
        const optionRenderer = withStyles({
            project: {
                display: "inline-block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            },
            item: {
                height: 'auto',
                display: 'flex',
                textAlign: 'left'

            }
        })(props => {
            return (
                <MenuItem 
                    className={props.classes.item}
                    selected={props.isFocused}
                    component="div"
                    {...props.innerProps} >

                    <div>{props.data.label}</div>
                    <div className="total-status">Total: <span>{props.data.total}</span></div>
                    <div className="missing-status">EN_US: <span>{props.data.en_us}</span></div>
                    <div className="missing-status">EN_AU: <span>{props.data.en_au}</span></div>
                    <div className="missing-status">EN_CA: <span>{props.data.en_ca}</span></div>
                    <div className="missing-status">FI: <span>{props.data.fi}</span></div>
                    <div className="missing-status">SE: <span>{props.data.se}</span></div>
                    <div className="missing-status">LT: <span>{props.data.lt}</span></div>
                    <div className="missing-status">DA: <span>{props.data.da}</span></div>
                    <div className="missing-status">NN: <span>{props.data.nn}</span></div>
                    <div className="missing-status">ES: <span>{props.data.es}</span></div>
                    <div className="missing-status">PT: <span>{props.data.pt}</span></div>
                    <div className="missing-status">IT: <span>{props.data.it}</span></div>
                    <div className="missing-status">EE: <span>{props.data.ee}</span></div>
                    <div className="missing-status">FI OUR: <span>{props.data.fi_special1}</span></div>
                    <div className="missing-status">EN OUR: <span>{props.data.en_special1}</span></div>
                </MenuItem>
            );
        });
        return optionRenderer;
    };    

    componentDidMount() {
        /*
        DataHandler.get({url: 'translations', modules: window.loadedComponents}).done(response => {
            this.setData(response);
        });
        */
        DataHandler.get({url: 'translations/modules'}).done(response => {
            this.setState({modules: response})
        });
    }

    onChange = (editObj) => {

        const { rows } = this.state;

        if (editObj.value === rows.find(r => r.path === editObj.path && r.tr === editObj.key)[editObj.name])
            return;

        if (!editObj.value || editObj.value == '') {
            const { enqueueSnackbar } = this.props;
            enqueueSnackbar('You have emptied a translation field! ', {
                variant: "warning",
                preventDublicate: true
            });
        }

        DataHandler.post({url: 'translations'}, {...editObj}).done(response => {
            
            let { rows } = this.state;
            const index = rows.findIndex(r => r.tr === response.key);
            rows[index] = { ...rows[index], ...response.value };

            this.setState({rows: rows});
        });

    };

    onModuleChange = (module) => {

        this.setState({selectedModule: module});
        DataHandler.get({url: 'translations/keys_by_module', module: module.label}).done(response => {
            this.setState({tableType: 'filter'});
            this.setData(response);
        });        
    };

    onLangChange = (item) => {

        this.setState({selectedLang: item});
        DataHandler.get({url: 'translations/missing_keys_by_lang', lang: item.id}).done(response => {
            this.setState({tableType: 'filter'});
            this.setData(response);
        });        
    };    

    onSearch = (terms, overwrite) => {

        if (!terms) {
            if (!this.advancedSearch.current.state.advanced) {
                terms = { freetextSearchTerm: this.advancedSearch.current.prevValue, mode: "freetext", advanced_search_criteria: {page: overwrite.page, perpage: this.state.perPage}};
            } else {
                terms = { advanced_search_criteria: this.advancedSearch.current.state.mainConfig, mode: "advanced"}
            }
        }

        if (overwrite)
            terms = {...terms, ...overwrite};

        DataHandler.get({url: 'translations/keys_by_search', params: terms}).done(response => {
            this.setData(response.translations);
            this.setState({tableType: 'search', count: response.count});
        });
    };

    setData = (data) => {

        let rows = [];
        Object.entries(data).map(([k, e]) => {
            rows.push({header: 1, name: k, rowKey: k});
            e && Object.entries(e).map(([n, v]) => {
                rows.push({...v, path: k, tr: n, rowKey: `${k}_${n}`});
            });
        });
        this.setState({rows: rows});
    };

    render() {

        const { taimerAccount, userObject } = this.context;

        const { classes} = this.props;

        const { rows, modules, selectedModule, selectedLang, hideUselessColumns } = this.state;        

        const rowProps = {
            onEdit: this.onChange,     
        }

        const columns = hideUselessColumns ? this.fields.filter(f => this.activelyUsedLanguages.includes(f.name)) : this.fields;

        return (
            <Dialog
                open
                fullScreen
                PaperProps={{classes:{root: classNames(classes.dialogRoot)}}}
                className="translations-dialog"
                TransitionProps={{
                    onEntering: this.handleEnter
                }}>
                <div className={classNames(classes.topBar)}/>
                <div className="translations-title" ref={this.dialog} >
                    <h6>
                        Translations
                    </h6>
                    <DataList 
                        label="modules" 
                        name="selectedModule"
                        options={modules}
                        customOption={this.optionRenderer()}
                        value={selectedModule}
                        onChange={this.onModuleChange}
                        shownCount={20}/>
                    <DataList 
                        label="search by missing translation/language" 
                        name="selectedLang"
                        options={this.fields.filter(e => e.name !== 'tr').map(f => { return { id: f.name, label: f.header } })}
                        value={selectedLang}
                        onChange={this.onLangChange} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: '20px'}} >
                        <p style= {{margin: '0', fontSize: '12px', color: '#2d9ff7'}} >Hide rare langs</p>
                        <Switch color="primary" onChange={() => this.setState({hideUselessColumns: !hideUselessColumns, rows: []})} checked={hideUselessColumns} />
                    </div>
                    <AdvancedSearch
                        ref={this.advancedSearch}
                        fields={this.fields.map(f => { return { field: f.field, transl: f.header } })}
                        onSearchResult={this.setData}
                        noRequests={true}
                        searchMenuContainer={this.dialog.current}
                        onSearchTrigger={(terms) => {
                            this.onSearch(terms);
                        }}
                    />                       
                </div> 
                <DialogContent>
                    <List
                        ref={this.list}
                        fluid
                        height="auto"
                        rowHeight={56}
                        rowKey="rowKey"
                        idType="string"
                        trimHeight={-20}                     
                        data={rows}
                        columns={columns}
                        className="translations-list"
                        listRowType={WrapperRow}
                        showPageSelector={this.state.tableType === 'search' ? true : false}
                        onPageChange={page => {
                            this.onSearch(false, { page: page });
                        }}
                        pageCount={Math.ceil((this.state.count > 0 ? this.state.count : 1 ) / this.state.perPage)}
                        totalCount={this.state.count}
                        perpage={this.state.perPage}
                        onPerPageChange={perPage => {
                            this.setState({perPage}, () => this.onSearch(false, { ...perPage, page: 1 }));
                        }}                        
                        rowProps={rowProps}
                    />

                </DialogContent>
                <DialogActions >
                    <div className={classNames(classes.buttonsContainer)}>                    
                        <Button size="large" variant="contained" onClick={this.props.onClose} >
                            Close
                        </Button>
                    </div>
                </DialogActions>                    
            </Dialog>
        );
    }
}

export default withSnackbar(withStyles(styles)(TranslationTool));
