import React, { Component } from 'react';
import DialogBorder from './../../dialogs/DialogBorder';
import DataList from './../../general/DataList';
import DataHandler from './../../general/DataHandler';
import TaimerComponent from './../../TaimerComponent';
import { SettingsContext } from './../../SettingsContext';
import _ from 'lodash';

export default class AddToMailingListDialog extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context)
	{
        super(props, context, "list/dialogs/AddTagsDialog");

        this.addTags    = this.addTags.bind(this); 
        this.loadTags   = this.loadTags.bind(this);

        this.tags  = this.props.tags;

        var tags = []

        this.props.tags.forEach(element => {
            tags.push({
                value: element.id,
                label: element.name
            })
        });

        this.state = {
            tags,
            values: [],
        };
    }

    addTags(){
        let contacts            = _.map(this.props.contacts, 'id');
        let tags           = _.map(this.state.values, 'value');

        DataHandler.post({url: 'add_tags'}, {contacts,tags, company: this.props.company}).done(() =>  this.props.onClose());
       
    }

    loadTags(value, {action}){
        this.setState({values: value})
    }

	render() {
        return <DialogBorder className="AddTagsDialog" id="dialog" title={this.tr("Add Tags to Selected")} textOk={this.tr("Add")} onClose={this.props.onClose} onSave={this.addTags}>
			<div id="container" className="AddTagsDialog">
                <DataList options={this.state.tags} value={this.state.values} isMulti onChange={this.loadTags} />    
			</div>
		</DialogBorder>
	}
}