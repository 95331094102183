import React from "react";
import { format, endOfMonth, startOfMonth, isSameYear, parse } from 'date-fns';

/* css */
import "./HoursInsight.css";

/* others */
import _ from "lodash";
import DataHandler from "./../../../general/DataHandler";
import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from "../../../../src/SettingsContext";

import withStyles from '@mui/styles/withStyles';
import NoPermissionOverlay from "../../../overlays/NoPermissionOverlay";

import InsightsBase, { styles } from "../InsightsBase";
import BillableVsNonBillable from "./components/BillableVsNonBillable";
import HoursByJobtype from "./components/HoursByJobtype";
import HourlySplit from "./components/HourlySplit";
import HoursOverview from "./components/HoursOverview";
import OverviewByUser from "./components/OverviewByUser";
import HourlyProfitability from "./components/HourlyProfitability";
import HoursByAccount from "./components/HoursByAccount";
import HoursByProject from "./components/HoursByProject";

import moment from 'moment';
import cn from 'classnames';
import InsightSlider from "../InsightSlider";
import { withSnackbar } from "notistack";
import VersionContentManager from "../../../general/VersionContentManager";

class HoursInsight extends TaimerComponent {
	static contextType = SettingsContext;

	static defaultProps = {
        allowFullDateRange: false,
    }

	constructor(props, context) {
		super(props, context, "dashboard/insights/hours/HoursInsight");

		this.baseData = {
			overview: {
				pie_data: [
					{
						name: "Billable",
						value: 0,
						value_non_approved: 0,
						data: [],
					},
					{
						name: "Non-Billable",
						value: 0,
						value_non_approved: 0,
						data: [],
					},
					...(!VersionContentManager.isFeatureHidden(this.namespace, 'vacation') ? [{
						name: "Vacation/Leave",
						value: 0,
						value_non_approved: 0,
						data: [],
					}] : [])
				],
				total: {
					value: 0,
					value_non_approved: 0,
					data: []
				},
				billable_amount: {
					value: 0,
					value_non_approved: 0,
					data: []
				}
			},
			by_user: [],
			by_account: [],
			by_project: [],
			hourly_profitability: [],
			hourly_split: [],
			hourly_split_user: [],
			by_jobtype: [],
			billable_vs_non: [
				{
					name: "Billable",
					values: [0],
					data: [],
					sliderColumns: ["user", "tracked", "split"]
				},
				{
					name: "Non-Billable",
					values: [0],
					data: [],
					sliderColumns: ["user", "tracked", "split"]
				},
				...(!VersionContentManager.isFeatureHidden(this.namespace, 'vacation') ? [{
					name: "Vacation",
					values: [0],
					data: [],
					sliderColumns: ["user", "tracked", "split"]
				}] : [])
			],
			client_vs_internal: [
				{
					name: "Internal",
					values: [0],
					data: [],
					sliderColumns: ["user", "tracked", "split"]
				},
				{
					name: "Client",
					values: [0],
					data: [],
					sliderColumns: ["user", "tracked", "split"]
				},
			],
			labelorder: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
			months: [],
		};

		const currency = "USD";

		const { taimerAccount } = this.context;

		this.numberFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		}).format;

		const { tr } = this;
		this.months = [
			tr("Jan"),
			tr("Feb"),
			tr("Mar"),
			tr("Apr"),
			tr("May"),
			tr("Jun"),
			tr("Jul"),
			tr("Aug"),
			tr("Sep"),
			tr("Oct"),
			tr("Nov"),
			tr("Dec")
		];

		let dateRange = [startOfMonth(new Date()), endOfMonth(new Date())];

		if  (this.props.useDefaultDateRange && this.props.startDate && this.props.endDate) {
			dateRange = [moment(this.props.startDate, 'YYYY-MM-DD').toDate(), moment(this.props.endDate, 'YYYY-MM-DD').toDate()];
		}

		this.state = {
			dateRange,
			currency,
			financial_year_start: 1,
			data: this.baseData,
			userBalances: {},
			sliderProps: false,
		};

		this.refInsightBase = React.createRef();
	}

	componentDidMount() {
		super.componentDidMount();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	refreshData = async (filters, { currency, financial_year_start }, change) => {
		const {
			dateRange: { startDate, endDate },
			customers_ids,
			projects_ids,
			customer_tags,
			project_tags,
			customership_group_ids,
			project_categories_ids,
			project_types_ids,
			reporting_group_ids,
			groups_ids,
			users_ids,
			company,
			advanced_search,
		} = filters;

		const { userBalances } = this.state;
        const { enqueueSnackbar, closeSnackbar } = this.props;

		try {
			this.setState({ loaded: false });

            let snackbarKey = null;
			const data = await DataHandler.autoRetryPost({
				url: 'dashboard/hours',
				company,
				start: format(startDate, "YYYY-MM-DD"),
				end: format(endDate, "YYYY-MM-DD"),
			}, {
				...advanced_search,
				projects: projects_ids,
				project_tags: project_tags,
				customers: customers_ids,
				customer_tags: customer_tags,
				customership_groups: customership_group_ids,
				project_type: project_types_ids,
				project_category: project_categories_ids,
				reporting_group:reporting_group_ids,
				users: users_ids,
				groups: groups_ids,
			}, ({done}) => {
                if (done && snackbarKey) {
                    closeSnackbar(snackbarKey);
                } else if (!done && !snackbarKey) {
                    snackbarKey = enqueueSnackbar(this.tr("Updating workhours"), {
                        variant: "info",
                        persist: true,
                    });
                }
            });

			// Splits
			for (const key of ["by_user", "by_project", "by_account"]) {
				const total = _.sum(_.map(data[key], 'tracked_h'));

				_.forEach(data[key], (row) => {
					row.total = total;
					row.split = total ? row.tracked_h / total * 100 : 0;

					if (key === "by_user" && userBalances[row.users_id]) {
						row.hour_balance = userBalances[row.users_id];
					}
					else if (key === "by_project") {
						const account = data.by_account[row.account_id];
						if (account) {
							row.account = account.account;
						}
					}
				});

				data[key] = _.sortBy(data[key], 'tracked_h').reverse();
			}

			try {
				data.billable_vs_non = data.billable_vs_non.filter(d => !VersionContentManager.isFeatureHidden(this.namespace, 'vacation') || d.type != 2);
				data.overview.pie_data = data.overview.pie_data.filter(d => !VersionContentManager.isFeatureHidden(this.namespace, 'vacation') || d.type != 'vacation');
				data.overview.pie_data.forEach(x => {
					x.name = this.tr(x.name);
				});
			} catch (error) {
				console.error(error);
			}

			try {
				_.forEach(data.by_project, x => {
					if (x.status == -1) {
						x.status = this.tr("Won Deals");
					} else if (x.status == -5) {
						x.status = this.tr("Internal")
					}
				});
			} catch (error) {
				console.error(error);
			}

			this.setState({
				dateRange: [startDate, endDate],
				data: { ...this.baseData, ...data }, currency, financial_year_start, loaded: true
			});
		} catch (error) {
			console.error(error);
		}
	}

	_showSliderForHoursOverview = ({type, ...props}) => {
		this._fetchSlider(type, {}, {}, props);
	}

	_showSliderForBillableVsNonBillable = ({tab, dataType, month, label, sliderColumns: columns}) => {
		const date = parse(month, 'YYYY-MM-DD', new Date());

		const filters = this.refInsightBase.current.getFilters();

		const {
			dateRange: { startDate, endDate },
		} = filters;

		const som = startOfMonth(date);
		const eom = endOfMonth(date);

		const startActual = startDate > som ? startDate : som;
		const endActual = endDate < eom ? endDate : eom;

		if (tab === "billable") {
			const type = dataType === 1 ? 'billable' : (dataType === 0 ? 'nonbillable' : 'vacation');

			this._fetchSlider(`${type}_user`, {
				start: format(startActual, 'YYYY-MM-DD'),
				end: format(endActual, 'YYYY-MM-DD'),
			}, {}, {
				label, columns
			});
		} else {
			this._fetchSlider(`${dataType}`, {
				start: format(startActual, 'YYYY-MM-DD'),
				end: format(endActual, 'YYYY-MM-DD'),
			}, {}, {
				label, columns
			});
		}
	}

	_showSliderForUser = (row) => {

		this._fetchSlider('user', {
		}, {
			users: [row.id],
		}, {
			label: row.users_name,
			columns: ["account", "project", "tracked", "split"],
		});
	}

	_showSliderForJobtype = (row) => {
		this._fetchSlider('worktask', {
		}, {
			worktask: [row.worktask],
		}, {
			label: row.label,
			columns: ["account", "project", "tracked", "split"],
		});
	}

	_showSliderForCustomer = (row) => {
		this._fetchSlider('customer', {
		}, {
			customers: [row.id]
		}, {
			label: row.account,
			columns: ["project", "tracked", "billable", "total_billable"],
		});
	}

	_showSliderForProject = (row) => {
		this._fetchSlider('project', {
		}, {
			projects: [row.id]
		}, {
			label: row.label ?? row.project,
			columns: ["user", "tracked", "billable", "total_billable"]
		});
	}

	_fetchSlider = async (slider, params = {}, data = {}, sliderProps = {}) => {
		const { mode: tab } = this.state;
		const filters = this.refInsightBase.current.getFilters();

		const {
			dateRange: { startDate, endDate },
			customers_ids,
			projects_ids,
			customer_tags,
			project_tags,
			customership_group_ids,
			project_categories_ids,
			project_types_ids,
			reporting_group_ids,
			groups_ids,
			users_ids,
			company,
			advanced_search,
			pipeline_ids,
			status,
		} = filters;

		this.setState({loaded: false});

		try {
			const sliderData = await DataHandler.post({
				url: `dashboard/hours/slider/${slider}`,
				company,
				start: format(startDate, "YYYY-MM-DD"),
				end: format(endDate, "YYYY-MM-DD"),
				tab,
				...params,
			}, {
				...advanced_search,
				projects: projects_ids,
				project_tags: project_tags,
				customers: customers_ids,
				customer_tags: customer_tags,
				customership_groups: customership_group_ids,
				project_type: project_types_ids,
				project_category: project_categories_ids,
				reporting_group: reporting_group_ids,
				users: users_ids,
				groups: groups_ids,
				pipeline: tab === "leads" ? pipeline_ids : [-1],
				status,
				...data,
			});

			this.setState({
				loaded: true,
				sliderProps: { ...sliderProps, ...sliderData },
			});
		} catch (e) {
			console.error("hours insight slider load fail: ", e);
			this.setState({ sliderProps: false });
		}
	}

	onCloseSlider = () => {
        this.setState({ sliderProps: false });
    }

	render() {
		const {
			functions: { hasPrivilege }, taimerAccount, addons
		} = this.context;
		const { singleCustomer, singleProject, company, customer, project, className, allowFullDateRange, useDefaultDateRange } = this.props;

		if (!(singleCustomer || singleProject) && !hasPrivilege("dashboard", "hours_read")) {
			return <NoPermissionOverlay />;
		}

		const [start, end] = this.state.dateRange;

		const { tr, months } = this;
		const { loaded, currency, data, sliderProps } = this.state;

		const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
			style: 'currency',
			currency,
		}).format;

		const insightFilterProps = {};

		if (singleProject)
			insightFilterProps.hideCompanySelect = true;

		let conditionalFilters = [
			taimerAccount.hasEnterpriseGroups ? "enterprise_groups" : undefined,
		];
		conditionalFilters = conditionalFilters.filter(cf => cf);
			
		return (
			<InsightsBase
				ref={this.refInsightBase}
				noHeader={this.props.noHeader}
				loaded={loaded}
				header={this.props.header}
				subheaders={this.props.subheaders}
				infoParams={{
					customer,
					project,
				}}
				onRequestData={this.refreshData}
				className={cn("insight-hours", className)}
				savedQueryName={!singleCustomer && !singleProject ? "hours_insight" : undefined}
				rightGroup={singleCustomer ? 'customers' : (singleProject ? 'projects' : "dashboard")}
				right="hours_read"
				dropFilters={["users"]}
				defaultFilters={this.props.useDefaultDateRange && { dateRange: {
					startDate: start,
					endDate: end,
					valid: true,
					key: "selection"
				}}}
				forcedFilters={singleCustomer ? {
					company,
					customers_ids: [customer],
				} : (singleProject ? {
					company,
					projects_ids: [project],
				} : undefined)}
				advancedFilters={singleCustomer ? [
					"projects", "team", "customership_groups", "project_type", 
					"project_categories", "tags", "reporting_group", 
				] : (singleProject ? [
					"team", "customership_groups", "project_type", 
					"project_categories", "tags", "reporting_group", 
				] : [
					"customers", "projects", "team", "customership_groups",
					"project_type", "project_categories", "tags", "reporting_group", ...conditionalFilters
				])}
				insightFilterProps={insightFilterProps}
				autocompleteParams={singleCustomer ? {customers_id: customer, include_locked: 1} : undefined}
				useSearchButton
				allowFullDateRange={allowFullDateRange}
				allowEmptyDateRange={allowFullDateRange}
			>
				<HoursOverview
					data={data.overview}
					tr={tr}
					currencyFormatter={currencyFormatter}
					numberFormatter={this.numberFormatter}
					currency={currency}
					showSlider={this._showSliderForHoursOverview}
					namespace={this.namespace}
				/>
				<BillableVsNonBillable
					numberFormatter={this.numberFormatter}
					data={data.billable_vs_non}
					clientData={data.client_vs_internal}
					months={data.months.map(m => {
						const momentMonth = moment(m, "YYYY-MM-DD");
						return `${months[momentMonth.month()]} ${momentMonth.format("YYYY")}`
					})}
					currency={currency}
					showSlider={this._showSliderForBillableVsNonBillable}
					fullMonths={data.months}
					/>
				{!addons.hours_insight_hide_users && <OverviewByUser
					width={6}
					data={_.values(data.by_user)}
					currency={currency}
					tr={tr}
					loaded={loaded}
					numberFormatter={this.numberFormatter}
					showSlider={this._showSliderForUser}
				/>}
				<HoursByJobtype
					months={data.months.map(m => {
						const momentMonth = moment(m, "YYYY-MM-DD");
						return `${months[momentMonth.month()]} ${momentMonth.format("YYYY")}`
					})}
					data={data.by_jobtype}
					currency={currency}
					numberFormatter={this.numberFormatter}
					emptyMessage={this.tr("No Hours Tracked")}
					showSlider={this._showSliderForJobtype}
				/>
				<HoursByAccount
					data={_.values(data.by_account)}
					tr={tr}
					currency={currency}
					loaded={loaded}
					numberFormatter={this.numberFormatter}
					showSlider={this._showSliderForCustomer}
				/>
				<HoursByProject
					data={_.values(data.by_project)}
					tr={tr}
					currency={currency}
					loaded={loaded}
					numberFormatter={this.numberFormatter}
					showSlider={this._showSliderForProject}
				/>
				<HourlyProfitability
					data={_.values(data.by_project)}
					tr={tr}
					currency={currency}
					loaded={loaded}
					numberFormatter={this.numberFormatter}
					showSlider={this._showSliderForProject}
				/>
				<HourlySplit
					data={_.values(data.by_project)}
					currency={currency}
					numberFormatter={this.numberFormatter}
					start={start}
					end={end}
					showSlider={this._showSliderForProject}
				/>

				<HourlySplit
					byUser
					data={_.values(data.by_user)}
					currency={currency}
					numberFormatter={this.numberFormatter}
					start={start}
					end={end}
					showSlider={this._showSliderForUser}
					hideExtras={!!addons.hours_insight_hide_users}
					showLockedUsersWithTag={true}
				/>

				<InsightSlider
					onClose={this.onCloseSlider}
					open={sliderProps !== false}
					columns={["number", "project", "account", "sum"]}
					{...sliderProps} />
			</InsightsBase>
		);
	}
}

export default withSnackbar(withStyles(styles)(HoursInsight));
