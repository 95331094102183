import React, { ReactNode } from 'react';
import { Drawer } from '@mui/material';
import { DrawerProps } from '@mui/material/Drawer';
import styles from './Slider.module.scss';
import { Close } from '@mui/icons-material';

interface Props extends DrawerProps {
    title: string;
    children: ReactNode;
    leftComponent?: ReactNode;
    rightComponent?: ReactNode;
    showLeftComponent?: boolean;
    onToggleLeftComponent?: () => void;
    mainContentClassName?: string;
    beforeOnClose?: (onClose: (e, reason) => void) => void;
    leftComponentButtonTooltip?: {
        open: string;
        closed: string;
    };
}

export class AnimatedSlider extends React.PureComponent<Props> {
    state = {
        open: false,
    };

    componentDidMount = () => {
        if (this.props.open) {
            this.setState({ open: true });
        }
    };

    componentDidUpdate = (prevProps: Props) => {
        if(prevProps.open !== this.props.open 
           && this.state.open !== this.props.open) {
               this.setState({ open: this.props.open });
        }
    };

    onClose = (e, reason) => {
        this.setState({ open: false }, () => {
            setTimeout(() => {
                this.props.onClose && this.props.onClose(e, reason);
            }, 500);
        });
    };

    render() {
        return (
            <Slider {...this.props} open={this.state.open} onClose={(e, reason) => (this.props.beforeOnClose ? this.props.beforeOnClose(() => this.onClose(e, reason)) : this.onClose(e, reason))} />
        );
    }
}

const Slider = (props: Props) => {
    const { title, className, leftComponent, showLeftComponent, onToggleLeftComponent, leftComponentButtonTooltip, rightComponent, mainContentClassName, ...rest } = props;
    return (
        <Drawer anchor="right" {...rest} className={`${styles.slider} ${className}`} disableEnforceFocus>
            <div data-testid={'slider'} className={styles.container}>
                <div className={`${styles.leftContainer} ${showLeftComponent ? styles.visible : ''}`}>{leftComponent}</div>
                <div className={`${styles.mainContent} ${mainContentClassName}`}>
                    <div className={`${styles.topBar}`}>
                        <h1>{title}</h1>
                        <div className={styles.right}>
                            {rightComponent}
                            <button onClick={(event) => props.onClose && props.onClose(event, 'backdropClick')}>
                                <Close />
                            </button>
                        </div>
                    </div>
                    {props.children}
                </div>
            </div>
        </Drawer>
    );
};

export default Slider;
