// Material UI
import { DrawerProps } from '@mui/material';
import TaimerComponent from '../../TaimerComponent';
import FieldEditSlider from '../../general/FieldEditSlider';

export interface Option {
    name: string;
}

export interface NameSelectSliderProps extends DrawerProps {
    onClose: (event?: any, reason?: string) => void;
    onSave: (data?: any) => void;
    selectedName: string;
    options: any[];
    getOptions: () => Option[];
    dataFetched: boolean;
    title: string;
    fieldTitle: string;
    infoText: string;
    loadingText?: string;
    changeFreeTextLabel?: string;
}

interface State {
    name: string;
    showTextField: boolean;
    showSelectField: boolean;
}

class NameSelectSlider extends TaimerComponent<NameSelectSliderProps, State> {
    constructor(props, context) {
        super(props, context, 'settings/components/NameSelectSlider');
        this.state = {
            name: props.selectedName,
            showTextField: false,
            showSelectField: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.props.getOptions();
    }

    getFields = () => {
        const { name, showTextField, showSelectField } = this.state;
        const { options, fieldTitle, infoText, changeFreeTextLabel } = this.props;

        /* Show text field if showing is selected in slider or if selectfield is not selected for showing and dimension is not found from options (and dimension name is not empty) */
        const useTextField = showTextField || (!showSelectField && (!options?.find(o => o.name == name) && name?.trim()));

        const nameField = !useTextField ?
            {
                type: "select",
                key: "name",
                title: fieldTitle,
                options: options || [],
                value: (options || []).find(o => o.name == name),
                shownCount: 20
            } :
            {
                key: "name",
                title: fieldTitle,
                value: name
            }

        const changTypeField = !useTextField ?
            {
                type: "subLabelButton",
                key: "useTextFieldButton",
                label: changeFreeTextLabel || this.tr("Don’t see the correct name?"),
                title: this.tr("Use free text instead"),
                onClick: (key) => this.toggleFieldType(key)
            } :
            {
                type: "subLabelButton",
                key: "useSelectFieldButton",
                label: this.tr("Want to select from existing options?"),
                title: this.tr("Use drop down instead"),
                onClick: (key) => this.toggleFieldType(key)
            }

        const fields: any[] = [
            nameField,
            changTypeField
        ];

        if (infoText) {
            fields.unshift(
                {
                    type: "info",
                    key: "info",
                    title: infoText
                }
            );
        }

        return fields;
    }

    toggleFieldType = (key) => {
        const showTextField = key == "useTextFieldButton" ? true : false;
        const showSelectField = key == "useSelectFieldButton" ? true : false;

        this.setState({ showTextField, showSelectField });
    }

    updateName = (item) => {
        const name = typeof item.name === "object" ? item.name?.name : item.name;
        this.setState({ name });
    }

    render() {
        const { name } = this.state;
        const { onSave, onClose, dataFetched, title, loadingText } = this.props;

        return (
            <FieldEditSlider
                open={true}
                onClose={onClose}
                title={title}
                onSave={() => {
                    onClose();
                    onSave(name)
                }}
                item={{ ...this.state }}
                fields={this.getFields()}
                onItemChanged={(item) => this.updateName(item)}
                loadingData={!dataFetched}
                loadingText={loadingText || this.tr("Loading options...")}
                saveDisabled={!name?.trim()}
            />
        );
    }

}

export default NameSelectSlider;