import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter
} from "@mui/material";
import colors from "../../../colors";
import { stringToColour } from "../../../helpers";
import { formatInputNumber } from '../../../helpers';

const JobtypeTable = props => {
  const { data, tr } = props;
  const total = Object.keys(data || {}).reduce(
    (prev, key) => prev + data[key].hours,
    0
  );
  return (
    <div className="by-jobtype-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="title">{tr("Jobtype")}</TableCell>
            <TableCell align="right" className="narrow-cell">
              {tr("Hours")}
            </TableCell>
            <TableCell align="right" className="narrow-cell">
              {tr("Split")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data || {}).map((key, i) => {
            const x = data[key];
            const percent =
              total != 0 ? Math.round((x.hours / total) * 100) : 0;
            return (
              <TableRow key={i}>
                <TableCell>
                  <div
                    className="ball-indicator"
                    style={{
                      background:
                        stringToColour(x.jobtype) || colors.dark_sky_blue
                    }}
                  ></div>
                  {x.jobtype}
                </TableCell>
                <TableCell align="right" className="narrow-cell">
                  {formatInputNumber(x.hours, "hours")} h
                </TableCell>
                <TableCell align="right" className="narrow-cell">
                  {percent} %
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell className="title">{tr("Total")}</TableCell>
            <TableCell align="right" className="title">
              {formatInputNumber(total, "hours")} h
            </TableCell>
            <TableCell align="right" className="title">
              100 %
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default JobtypeTable;
