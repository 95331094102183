import React from 'react';
import { AccessTime } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Tab } from './PageTopSection';
import './IconTabs.css';

interface Props {
    /**
     * Tabs to show.
     */
    tabs: Tab[];
    /**
     * Key of the currently selected tab.
     */
    selected?: string;
    disabled?: boolean;
}

const IconTabs = (props: Props) => {
    const { tabs = [], selected, disabled } = props;
    if (tabs.length <= 1) return null;
    return (
        <div className="taimer-icon-tabs">
            {tabs.map((tab) => (
                <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={tab.title}>
                    {tab.href ? (
                        <a data-testid={`tab_${tab.key}`} href={tab.href} onClick={tab.action} className={`tab ${disabled ? 'disabled' : ''} ${tab.key == selected ? 'active' : ''}`}>
                            {tab.icon || <AccessTime />}
                        </a>
                    ) : (
                        <div data-testid={`tab_${tab.key}`} onClick={tab.action} className={`tab ${disabled ? 'disabled' : ''} ${tab.key == selected ? 'active' : ''}`}>
                            {tab.icon || <AccessTime />}
                        </div>
                    )}
                </Tooltip>
            ))}
        </div>
    );
};

export default IconTabs;
