import React from "react";
import SimpleSettingRow from "../rows/SimpleSettingRow";
import { SettingsList as List } from "./SettingsList";

// import List from "../List";

class OneColumnSettingList extends React.Component {
    static defaultProps = {
        id: false,
        rowHeight: 73,
        listRowType: SimpleSettingRow,
        data: [],
        headerTitle: "Name",
        newRowPlaceHolder: "",
        deleteWidth: 52,
        nameWidth: 350,
        rowProps: {},
        hideHeader:false,
        emptyNewDataOnUpdate: false 
    };


    constructor(props) {
        super(props);

        this.list = React.createRef();

        this.state={
            headerTitleVisible:false
        }

        this.addNewRow    = this.addNewRow.bind(this);
        this.removeNewRow = this.removeNewRow.bind(this);
        this.emptyNewData = this.emptyNewData.bind(this);
        this.removeRow    = this.removeRow.bind(this);

        this.columnDefaultConfig = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
    }


    addNewRow() {
        this.list.current.addNewRow();
        // this.setState({headerTitleVisible:true});
    }


    removeNewRow(id) {
        this.list.current.removeNewRow(id);
        if(this.props.data.length == 0){
            // this.setState({headerTitleVisible:false});
        }
    }


    emptyNewData() {
        this.list.current.emptyNewData();
    }

    removeRow(id) {
        this.list.current.removeRow(id);
    }


    render() {
        return (
            <List
                key={this.props.data.length}
                id={this.props.id}
                innerRef={this.list}
                className="settingsList"
                height="auto"
                rowHeight={this.props.rowHeight}
                ignoreRowPropsChange={true}
                listRowType={this.props.listRowType}
                rowProps={this.props.rowProps}
                emptyNewDataOnUpdate={this.props.emptyNewDataOnUpdate}
                columns={[
                    { width: this.props.deleteWidth, "name": "delete", header: "", ...this.columnDefaultConfig },
                    { width: this.props.nameWidth, name: "name", header: this.props.headerTitle, ...this.columnDefaultConfig }
                ]}
                newRow={{
                    name: this.props.newRowPlaceHolder,
                    companies_id: this.props.company
                }}
                data={this.props.data} />
        );
    }
}

export default OneColumnSettingList;
