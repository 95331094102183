import React, { Component } from 'react';
import DialogBorder from './../../dialogs/DialogBorder';
import DataList from './../../general/DataList';
import DataHandler from './../../general/DataHandler';
import TaimerComponent from './../../TaimerComponent';
import { SettingsContext } from './../../SettingsContext';
import _ from 'lodash';

export default class AddToMailingListDialog extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context)
	{
		super(props, context, "list/dialogs/DeleteMailingListDialog");
        this.deleteMailingList	= this.deleteMailingList.bind(this);
        this.loadMailingList = this.loadMailingList.bind(this);
        this.mailingLists  = this.props.mailing_lists;

        var mailingLists = []

        this.props.mailing_lists.forEach(element => {
            mailingLists.push({
                value: element.id,
                label: element.name
            })
        });


        this.state = {
            mailingLists,
            values: [],
        };
    }
   
    deleteMailingList(name, value){  
        let maillists           = _.map(this.state.values, 'value');
        DataHandler.post({url: `company/${this.props.company}/mail_list_delete`}, {maillists}).done(() =>  this.props.onClose());
    }

    loadMailingList (value, { action }) {     
        this.setState({values: value})
    }

	render() {
        return <DialogBorder className="DeleteMailingListDialog" id="dialog" title={this.tr("Delete mailing list?")} textOk={this.tr("Delete")} onClose={this.props.onClose} onSave={this.deleteMailingList}>
			<div id="container" className="DeleteMailingListDialog">
                <DataList options={this.state.mailingLists} value={this.state.values} isMulti onChange={this.loadMailingList}  />
			</div>
		</DialogBorder>
	}
}