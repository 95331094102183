import React from "react";

import { SettingsContext } from "../../../SettingsContext";
import TaimerComponent from "../../../TaimerComponent";

import styles from './StackedChart.module.scss';
import { BlockProps } from "../../base/CustomViewBase";
import { Bar } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import merge from 'lodash/merge';
import _ from "lodash";
import ChartDefaultOptions from './ChartDefaultOptions';
import cn from 'classnames';

interface Props {
    className?: string;
    options?: ChartOptions<"bar">;
    datasets: DatasetWithSum[];
    labels: string[];
    formatValue?: (val: number) => string;
    xStacked?: boolean;
    yStacked?: boolean;
}

interface State {
}

export interface Dataset {
    key?: string;
    label: string;
    data: number[];
    backgroundColor: string;
    hideFromTotal?: boolean;
    totalClassName?: string;
    totalDisplayAbsolute?: boolean;
}

export interface DatasetWithSum extends Dataset {
    sum: number;
}

export const abbreviateNumber = (value) => {
    let formatted = Math.round(value);
    const breakpoints = {
        1000: {
            formatValue: (value) => {
                return `${Math.round(value / 1000)}K`;
            },
        },
        1000000: {
            formatValue: (value) => {
                return `${Math.round((value / 1000000) * 10) / 10}M`;
            },
        },
    };

    Object.keys(breakpoints).forEach((point) => {
        if (value >= point) {
            formatted = breakpoints[point].formatValue(value);
        }
    });
    return formatted;
};

export const getBarTotalFromContext = (context) => {
    const total = (context.chart.data.datasets || []).reduce(
        (prev, ds) => (prev += ds.data[context.dataIndex] || 0),
        0
    );
    return total;
};

export default class StackedChart extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "customview/blocks/Chart/StackedChart");

        this.state = {
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
    }

    render() {
        const { className, options, datasets, labels, formatValue } = this.props;
        const { } = this.state;
        
        const xStacked = this.props.xStacked ?? true;
        const yStacked = this.props.yStacked ?? true;

        const fullOptions = merge({}, ChartDefaultOptions, {
            layout: {
                padding: {
                    top: 25,
                },
            },
            barValueSpacing: 20,
            plugins: {
                tooltip: {
                    callbacks: {
                        labelColor: (tooltipItem) => {
                            return {
                                borderColor: tooltipItem.dataset.backgroundColor,
                                backgroundColor: tooltipItem.dataset.backgroundColor,
                            };
                        },
                        label: (tooltipItem) => {
                            const dataset = tooltipItem.dataset;
                            let label =
                                " " + _.escape(dataset.label) || "";

                            if (label) {
                                label += ": ";
                            }

                            if (tooltipItem.raw !== undefined)
                                label += formatValue?.(tooltipItem.raw as number) ?? tooltipItem.raw;

                            return label;
                        },
                    },
                },
                legend: {
                    display: false,
                    onClick: (e, legendItem) => {
                        const { datasetIndex } = legendItem;
                        // toggleHidden(datasetIndex);
                    },
                    labels: {
                        generateLabels: () => {
                            return datasets.map((obj, i) => {
                                return {
                                    text: obj.label,
                                    datasetIndex: i,
                                    pointStyle: "rectRounded",
                                    fillStyle: obj.backgroundColor,
                                    lineCap: "round",
                                    lineJoin: "round",
                                    strokeStyle: "#FFF",
                                    lineWidth: 5,
                                    // hidden: hiddenIndexes.indexOf(i) != -1,
                                };
                            });
                        },
                    },
                },
                datalabels: {
                    color: "#34546b",
                    backgroundColor: "#f9f9f9",
                    borderRadius: 5,
                    font: {
                        size: 10,
                    },
                    // clamp: true,
                    anchor: "end",
                    align: "end",
                    offset: 6,
                    display: (context) => {
                        const total = getBarTotalFromContext(context);
                        const shouldShow = total !== 0 &&
                            yStacked &&
                            xStacked &&
                            context.datasetIndex ===
                            (context.chart.data.datasets || []).length - 1
                            ? "auto"
                            : false;

                        return shouldShow;
                    },
                    formatter: (_, context) => {
                        const total = getBarTotalFromContext(context);
                        const formatted = abbreviateNumber(total);
                        return formatted;
                    },
                },
            },
            scales: {
                y: {
                    stacked: yStacked,
                    type: "linear",
                    position: "left",
                    beginAtZero: true,
                    ticks: {
                        callback: (value, index, values) => {
                            if (typeof value === 'number')
                                return formatValue?.(value);
                            return value;
                        },
                    },
                },
                x: {
                    stacked: xStacked,
                }
            }
        } as ChartOptions<"bar">, options);

        return (<div className={cn(styles.root, className)}>
            <Bar
                options={fullOptions}
                data={{
                    datasets: datasets,
                    labels,
                }}
            />
        </div>);
    }
}