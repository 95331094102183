import React from "react";
import TaimerComponent from "../../../../TaimerComponent";
import InsightChart from "../../InsightChart";
import LeaderBoardChart from "./LeaderBoardChart";
import InsightChartTable from "../../InsightChartTable";
import InsightDropDown from "../../InsightDropDown";

const BAR_THICCCCCNESS = 30;
class LeadsAndDealsLeaderboard extends TaimerComponent {
  constructor(props, context) {
    super(
      props,
      context,
      "dashboard/insights/sales/components/LeadsAndDealsLeaderboard"
    );
    const mode = props.tabs && props.tabs.length > 0 && props.tabs[0].key;
    this.state = {
      formattedData: this._formatData(props.data, mode),
      mode,
      tabs:
        props.tabs &&
        props.tabs.map((tab) => ({
          ...tab,
          action: () => this.setState({ mode: tab.key }),
        })),
    };
  }

  componentDidUpdate = (oldProps, oldState) => {
    if (oldProps.data != this.props.data || oldState.mode != this.state.mode) {
      this.setState({
        formattedData: this._formatData(this.props.data),
      });
    }
  };

  _formatData = (data, mode = this.state.mode) => {
    let dataObj = mode && !Array.isArray(data) ? data[mode] : data;
    if (this.props.showLockedUsersWithTag && this.props.showLockedUsersWithTag.includes(mode))
      dataObj = dataObj.map(o => {
        o.users_name = o.user_locked > 0 ? `${o.users_name} (${this.tr('locked')})` : o.users_name;
        return o;
      });
    const formatted = {
      sets: [
        {
          label: this.tr("Pipeline Probability"),
          values: dataObj.map((obj) => obj.pipeline_probability),
          data: [],
          // data: dataObj.map((obj) => obj.slider),
          type: "bar",
          barThickness: BAR_THICCCCCNESS,
          colors: {
            backgroundColor: "#1e74b6",
            borderColor: "#1e74b6",
          },
        },
        {
          label: this.tr("Pipeline Value"),
          values: dataObj.map((obj) => obj.pipeline_value),
          data: [],
          // data: dataObj.map((obj) => obj.slider),
          type: "bar",
          barThickness: BAR_THICCCCCNESS,
          colors: {
            backgroundColor: "#2d9ff7",
            borderColor: "#2d9ff7",
          },
        },
      ],
      labels: dataObj.map((obj) => obj.name ?? obj.users_name ?? obj.customers_name),
    };
    return formatted;
  };

  showSlider = (a, index) => {
    const { data } = this.props;
    const { mode } = this.state;
    let dataObj = mode && !Array.isArray(data) ? data[mode] : data;

    this.props.showSlider(mode, dataObj[index]);
  }

  render() {
    const { formattedData } = this.state;
    const { showSlider } = this.props;
    return (
      <InsightChart
        {...this.props}
        filterComponents={
          this.state.tabs && (
            <InsightDropDown
              tabs={this.state.tabs}
              selected={this.state.mode}
            />
          )
        }
        title={this.tr("Pipeline Leaderboard")}
        data={formattedData}
        chartType={LeaderBoardChart}
        tableType={InsightChartTable}
        lowOpacityChart={false}
        titleOnSlider={true}
        showSlider={showSlider ? this.showSlider : undefined}
        sliderColumns={["sales_status", "project", "account", "value"]}
        chartProps={{
          displayLegend: true,
          horizontal: true,
          stacked: true,
          xStacked: false,
        }}
        tableProps={{
          displayByLabels: true,
          highlightRow: true,
          displayFooter: true,
          setTitle: this.tr("User"),
        }}
      />
    );
  }
}

export default LeadsAndDealsLeaderboard;
