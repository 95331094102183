import React from 'react';
import { convertDateFormat } from '../helpers';
import ProposalEditor from '../projects/proposal/ProposalEditor';
import TabQuotes from '../projects/TabQuotes';
import { SettingsContext } from '../SettingsContext';
import DataHandler from './DataHandler';
import PDFView from './PDFView';
import styles from './QuotePDFView.module.scss';

interface QuotePDFViewState {
    pdf?: any;
    quote?: any;
    quoteHTML?: any;
    proposalLoaded?: boolean;
}

interface QuotePDFViewProps {
    quoteId: string;
    project: any;
    printLanguage?: string;
    printDateFormat?: string;
    includeProposal?: boolean;
    onFileLoaded?: (pdf: any, file: any, quote: any) => void;
}

class QuotePDFView extends React.PureComponent<QuotePDFViewProps, QuotePDFViewState> {
    static contextType = SettingsContext;
    tabQuotes: any = React.createRef();
    proposal: any = React.createRef();
    fileId?: string;

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate = (oldProps) => {
        if (
            this.state.quote &&
            (oldProps.printLanguage != this.props.printLanguage || oldProps.printDateFormat != this.props.printDateFormat || oldProps.includeProposal != this.props.includeProposal)
        ) {
            this.onSettingChanged();
        }
        if (oldProps.quoteId != this.props.quoteId) {
            this.setState({
                pdf: undefined,
                quoteHTML: undefined,
            });
        }
    };

    onSettingChanged = () => {
        const update: any = {
            pdf: undefined,
            quoteHTML: undefined,
        };
        this.setState(update, () => {
            setTimeout(() => {
                this.getQuotePDF();
            }, 1000);
        });
    };

    getQuotePDF = async () => {
        const params = this.tabQuotes.current && this.tabQuotes.current.getQuotePrintParams();
        if (!params) return;
        if (this.props.includeProposal) {
            this.setState({ quoteHTML: params.html });
        } else {
            const response = await DataHandler.post({ url: 'print/quote' }, params);
            if (!response.error) {
                const file = await DataHandler.getArrayBuffer({ url: 'file', filename: response.filename });
                if (file != false) {
                    const pdf = new Blob([file], {
                        type: 'application/pdf',
                    });
                    this.fileId = response.filename;
                    this.setState({ pdf, quoteHTML: undefined, proposalLoaded: false }, () => {
                        this.props.onFileLoaded && this.props.onFileLoaded(pdf, this.fileId, this.state.quote);
                    });
                }
            } else {
                this.setState({ pdf: undefined, quoteHTML: undefined, proposalLoaded: false }, () => {
                    this.props.onFileLoaded && this.props.onFileLoaded(undefined, undefined, this.state.quote);
                });
            }
        }
    };

    getProposalPDF = async () => {
        const params = this.proposal.current && this.proposal.current.getProposalPrintParams();
        if (!params) return;
        const response = await DataHandler.post({ url: 'print/proposal' }, params);
        if (!response.error) {
            const file = await DataHandler.getArrayBuffer({ url: 'file', filename: response.filename });
            if (file != false) {
                const pdf = new Blob([file], {
                    type: 'application/pdf',
                });
                this.fileId = response.filename;
                this.setState({ pdf }, () => {
                    this.props.onFileLoaded && this.props.onFileLoaded(pdf, this.fileId, this.state.quote);
                });
            } else {
                this.setState({ pdf: undefined }, () => {
                    this.props.onFileLoaded && this.props.onFileLoaded(undefined, undefined, this.state.quote);
                });
            }
        }
    };

    onPrintQuoteReady = (quote) => {
        this.setState({ quote }, () => {
            this.getQuotePDF();
        });
    };

    onProposalLoaded = () => {
        this.setState({ proposalLoaded: true }, () => {
            this.getProposalPDF();
        });
    };

    render() {
        const { pdf, quoteHTML, quote } = this.state;
        const { quoteId, project, printLanguage, printDateFormat } = this.props;
        return (
            <>
                <PDFView file={pdf} />
                {printLanguage && printDateFormat && quoteId && (
                    <div className={styles.tabQuotes}>
                        <TabQuotes
                            onPrintQuoteReady={this.onPrintQuoteReady}
                            ref={this.tabQuotes}
                            selectedQuoteId={quoteId}
                            project={project}
                            checkPrivilege={this.context.functions.checkPrivilege}
                            printMode
                            printLanguage={printLanguage}
                            printDateFormat={printDateFormat}
                        />
                        {this.state.quoteHTML && (
                            <ProposalEditor
                                ref={this.proposal}
                                printLanguage={printLanguage}
                                printDateFormat={convertDateFormat(printDateFormat)}
                                refreshQuoteData={() => {}}
                                quoteHTML={quoteHTML}
                                checkPrivilege={this.context.functions.checkPrivilege}
                                project={project}
                                quote={quote}
                                onProposalLoaded={this.onProposalLoaded}
                            />
                        )}
                    </div>
                )}
            </>
        );
    }
}

export default QuotePDFView;
