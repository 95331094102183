import React from "react";

import SettingRow from "./SettingRow";
import ListCell from "../ListCell";
import TextInputCell from "../cells/TextInputCell";

import TrashCanIcon from "@mui/icons-material/Delete";
import { CheckBox } from "@mui/icons-material";
import { Switch } from "@mui/material";

import PropsOnlyListRow from "../PropsOnlyListRow";

import './NotificationListRow.css';

class NotificationListRow extends PropsOnlyListRow {
	constructor(props) {
		super(props);
    }

    editAndSave = (name, value) => {
        let data   = this.props.data;
        data[name] = value;

        this.setDataAndUpdate(data);
    }
    
    changeDefault = (e, checked) => {
        this.editAndSave("active", checked ? true : false);
    }

    defineCells() {
        const { data } = this.props;
        let cells = {
            name:   
                <ListCell alignCell onlyDisplay name="name">
                    {data.header}
                </ListCell>,
            active:
                <ListCell className="" width={this.props.columnWidthMap['active']} onlyDisplay>
                    <Switch checked={data.active} onChange={this.changeDefault} color="primary" />
                </ListCell>

        };

        return cells;
    }

    defineClassName() {
        return "NotificationListRow";
    }
}

export default NotificationListRow;
