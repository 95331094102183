import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import { SettingsContext } from '../../SettingsContext';
import './AccountingAccounts.scss';
import DataHandler from "../../general/DataHandler";
import SettingRow from "../../list/rows/SettingRow";
import { SettingsList as List } from "../../list/lists/SettingsList";
import ListCell from "../../list/ListCell";
import TextInputCell from "../../list/cells/TextInputCell";
import RadioButtonCell from "../../list/cells/RadioButtonCell";
import Button from '@mui/material/Button';
import TrashCanIcon from "@mui/icons-material/Delete";
import { withSnackbar } from 'notistack';
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";

class WorkflowRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            workflow: <TextInputCell
                width={this.props.columnWidthMap['workflow']}
                name="workflow"
                value={this.state.data['workflow']}
                onEdited={this.editAndSave}
            />,
            status_name: <TextInputCell
                width={this.props.columnWidthMap['status_name']}
                name="status_name"
                value={this.state.data['status_name']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class AccountingWorkflows extends TaimerComponent {
    static defaultProps = {
		showDefaultOption: false
    }
    
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/AccountingWorkflows");

        this.state = {
            workflows: [],
        }

        this.columnConfig = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.workflowList = React.createRef();

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
    }

    componentDidMount () {
        super.componentDidMount();
        this.getWorkflows();
    }

    getWorkflows = () => {
        DataHandler.get({url: `accounting/workflows/${this.props.company}/${this.props.integration}`}).done(response => {
            this.setState(response);
        });
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    render() {
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <div className="accounting-list">
               <Button className="green" onMouseUp={() => this.workflowList.current.addNewRow()} size="large">{this.tr("Add workflow")}</Button>
                <List
                    innerRef={this.workflowList}
                    id="workflowList"
                    className="settingsList integrationList"
                    height="auto"
                    rowHeight={SettingRow.rowHeight}
                    newRow={{
                        id: -1,
                        workflow: "",
                        status_name: "",
                    }}
                    listRowType={WorkflowRow}
                    columns={[
                        { width: 55, name: "delete", header: "", ...this.columnConfig },
                        { width: 350, name: "workflow", header: this.tr("Workflow name"), ...this.columnConfig },
                        { width: 350, name: "status_name", header: this.tr("Status number"), ...this.columnConfig },
                    ]}
                    rowProps={{
                        onCreate: data => {
                            data.company = this.props.company;
                            DataHandler.post({url: `accounting/workflows/${this.props.integration}`}, { data: JSON.stringify(data) }).done(response => {
                                this.getWorkflows();
                            });
                        },
                        onUpdate: data => {
                            data.company = this.props.company;
                            DataHandler.put({url: 'accounting/workflows'}, { data: JSON.stringify(data) }).done(response => {
                                this.getWorkflows();
                            });
                        },
                        onDelete: data => {
                            if (Number(data.id) < 0) {
                                this.workflowList.current.removeRow(data.id);
                                return;
                            }
                            data.deleted = "1";
                            this.setState({
                                dialogData: {
                                    saveFunc: () => DataHandler.delete({url: 'accounting/workflows'}, { data: JSON.stringify(data) }).done(response => {
                                        this.getWorkflows();
                                    }),
                                    text: this.tr("Do you want to delete workflow") + ": " + data.workflow + (data.status_name != "" ? " " + data.status_name : "") + "?" 
                                }
                            });
                            this.openDialog('confirmation');
                        }
                    }}
                    data={this.state.workflows}
                />

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
            </div>
        );
    }
}

export default withSnackbar(AccountingWorkflows);