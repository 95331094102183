import React from 'react';
import TaimerComponent from '../TaimerComponent';
import { SettingsContext } from '../SettingsContext';
import DataHandler from "./../general/DataHandler";
import List from '../list/List';
import CustomerListRow from '../list/rows/CustomerListRow';
import AttachmentDialog from "../list/dialogs/HandleRowAttachments";
import { withSnackbar } from 'notistack';

import './TabAccountStructure.css';
import PageTopSection from '../general/PageTopSection';



class TabAccountStructure extends TaimerComponent {

	static contextType = SettingsContext;
	constructor(props, context) {
		super(props, context, 'accounts/TabAccountStructure');
		this.state = {
			accounts: []
		}
		this.list = React.createRef();
		const customCustomerIdOn = this.context.addons.custom_customer_id && this.context.addons.custom_customer_id.used_by_companies.indexOf(this.props.company) > -1 ? true : false;
		this.columns = [
			{ field: "context", name: "context", header: "", width: 50, showMenu: false, resizeable: false, moveable: false, hideable: false, showResizeMarker: false },
			{ field: "id", name: "id", header: customCustomerIdOn ? this.tr("Account Id") : this.tr("Account Nr."), width: 122, resizeable: true, moveable: false },
			{ field: "name", name: "name", header: this.tr("Account"), width: 400 },
			{ field: "customership_group", name: "customership_group", header: this.tr("Account Group"), width: 160 },
			{ field: "customer_type", name: "customer_type", header: this.tr("Account Type"), width: 150 },
			{ field: "is_parent", name: "is_parent", header: this.tr("Parent Company"), width: 100 },
			{ field: "customer_unit", name: "customer_unit", header: this.tr("Unit"), width: 120 },
		];
		if(customCustomerIdOn) {
			this.columns.splice(1, 0, { field: "customer_id", name: "customer_id", header: this.tr("Account: Nr.") , width: 122, resizeable: true, moveable: false });
		}

		this.is_parent_values = [
	        {id: 0, name: ''},
    	    {id: 1, name: this.tr('Emo')},
    	    {id: -1, name: this.tr('Tytär')}
    	];
    	this.customer_units = [
	        {id: 0, name: ''},
    	    {id: 1, name: this.tr('Pääyksikkö')},
    	    {id: 2, name: this.tr('Aliyksikkö')}
		];


		this.dialogs = {
			attachments: AttachmentDialog
		}; 
	}

	fetchData = () => {
		DataHandler.get({url: "accounts/account_structure/" + this.props.customer, company: this.props.company}).done(data => {
			this.setState({accounts: data});
		});
	}

	componentDidMount() {
		this.fetchData();
	}



	componentDidUpdate(prevProps) {
		if(this.props.company != prevProps.company) {
			this.fetchData();
		}
	}

	openDialog(name, callback = null) {
		this.setState({ currentDialog: name, savedCallback: callback });
	}

	closeDialog = () => {
		this.setState({currentDialog: false, dialogData: undefined, savedCallback: null});
	}

	confirmDialog(saveFunc, id) {
		saveFunc(id);
		this.closeDialog();
	}

	callAttachmentDialog(id) {
		this.setState({
			dialogData: {
				id: id,
				targetModule: "accounts"
			}
		}, () => this.openDialog('attachments'));
	}

    render () {
    	const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        return (
            <div id="accounts-structure">
				{this.props.header && (
                    <PageTopSection
                        header={this.props.header}
                        subheaders={this.props.subheaders}
                    />
                )}
            	<List
            		ref={this.list}
            		className="accountStructureList"
            		listRowType={CustomerListRow}
            		sharedData={{
            			customership_groups: this.props.customership_groups,
            			customer_types: this.props.account_types,
            			is_parent_values: this.is_parent_values,
            			customer_units: this.customer_units,
            		}}
            		rowProps={{
            			addons: this.context.addons,
            			callAttachmentDialog: id => this.callAttachmentDialog(id),
            			enqueueSnackbar: this.props.enqueueSnackbar,
            			sharedData: {
            				customership_groups: this.props.customership_groups,
            				customer_types: this.props.account_types,
            				is_parent_values: this.is_parent_values,
            				customer_units: this.customer_units,
            			},
						company: this.props.company
            		}}
            		data={this.state.accounts}
            		columns={this.columns}
	        		parentKey="parent_id"
					height="fitRemaining"
                    minHeight={448}
                    trimHeight={-10}
                    treeData={true}
                    parentsExpandedOnInit={true}
                    noStateData={true}
                    manualCreate={false}
                    controlPage={false}
                    enableToolbar={false}
            	/>
            	{Dialog && <Dialog
					open
					onDialogClose={this.closeDialog}
					onDialogSave={this.confirmDialog}
					data={this.state.dialogData}
					/>}
            </div>
        );
    }

};

export default withSnackbar(TabAccountStructure);