import { MenuItem } from '@mui/material';
import React from 'react';
import TaimerComponent from '../TaimerComponent';
import ContextMenu from './ContextMenu';
import Link from './Link';
import styles from './EntityCreatedSnackbar.module.scss';

interface Props {
    type: string;
    id?: string;
    onClose?: () => void;
    url?: object;
    actions?: any[];
}

class EntityCreatedSnackbar extends TaimerComponent<Props> {
    constructor(props, context) {
        super(props, context, 'general/EntityCreatedSnackbar');
    }

    getTexts = () => {
        const { type, id } = this.props;
        switch (type) {
            case 'account':
                return {
                    snackbar: this.tr('Account ${id} created!', { id }),
                    link: this.tr('View account'),
                };
            case 'project':
                return {
                    snackbar: this.tr('Project ${id} created!', { id }),
                    link: this.tr('View project'),
                };
            case 'contact':
                return {
                    snackbar: this.tr('Contact created!'),
                    link: this.tr('View contact'),
                };
            case 'activity':
                return {
                    snackbar: this.tr('Activity saved!'),
                    link: undefined,
                };
            case 'user':
                return {
                    snackbar: this.tr('User created!'),
                    link: this.tr('View user'),
                };
            case 'freelancer':
                return {
                    snackbar: this.tr('Remember to add a workweek definition for the freelancer, since no template could be selected.'),
                };    
            case 'hour':
                return {
                    snackbar: this.tr('Entry saved!'),
                };
            case 'task':
                return {
                    snackbar: this.tr('Task saved!'),
                };
            case 'milestone':
                return {
                    snackbar: this.tr('Milestone saved!'),
                };
            default:
                return {
                    snackbar: this.tr('Item created!'),
                    link: undefined,
                };
        }
    };

    render() {
        const { url, actions, onClose } = this.props;
        const { snackbar, link } = this.getTexts();
        const filteredActions = (actions || []).filter((a) => !a.isHidden || !a.isHidden());
        return (
            <span data-testid="entity-created-snackbar" className="entityCreatedSnackbar-content">
                <div>{snackbar}</div>
                {!actions && url && link && (
                    <Link onLinkClicked={onClose} url={url}>
                        {link}
                    </Link>
                )}
                {filteredActions.length > 0 && (
                    <ContextMenu
                        //@ts-ignore
                        disablePortal={false}
                        className={styles.optionsMenu}
                        customGrowId={styles.grow}
                        variant="outlined"
                        label={this.tr('Next actions')}
                        size="medium"
                        placement={'bottom-end'}
                    >
                        {filteredActions.map((a) => (
                            <MenuItem
                                key={a.key}
                                onClick={() => {
                                    onClose && onClose();
                                    a.onClick && a.onClick();
                                }}
                            >
                                {this.tr(a.label)}
                            </MenuItem>
                        ))}
                    </ContextMenu>
                )}
            </span>
        );
    }
}

export default EntityCreatedSnackbar;
