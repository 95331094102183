import React from 'react';
import './Efina.css';
import './Maventa.css';
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import OutlinedField from '../../general/OutlinedField';
import TaimerComponent from "../../TaimerComponent";
import ListCell from "../../list/ListCell";
import TrashCanIcon from "@mui/icons-material/Delete";
import TextInputCell from "../../list/cells/TextInputCell";
import DataHandler from '../../general/DataHandler';
import MenuItem from '@mui/material/MenuItem';
import { SettingsContext } from '../../SettingsContext';
import SettingRow from "../../list/rows/SettingRow";
import List from "../../list/List";
import AccountingAccounts from '../components/AccountingAccounts';

class ProductRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            product_id: <TextInputCell
                width={this.props.columnWidthMap['product_id']}
                name="product_id"
                value={this.state.data['product_id']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class Xero extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/Xero");

        this.state = {
            activated: 0,
            accountingProducts: [],
            salesAccounts: []
        };

        this.dColConf = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.productsList = React.createRef();
    }
    
    componentDidMount () {
        super.componentDidMount();
        this.updateComponentData();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.updateComponentData();
    }

    updateComponentData = () => {
        this.getAuthData();
        this.getSalesAccounts();
        this.getProducts();
    }

    getAuthData = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/xero`}).done(response => {
            this.setState(response);
        });
    }

    getSalesAccounts = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/xero/1`}).done(response => {
            this.setState(response);
        });
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        this.props.fetchData();
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''});
    }
    
    deactivate = () => {
        DataHandler.delete({url: `settings/company/${this.props.company}/xero`}).done(this.back);
    }

    authenticate = () => {
        if (this.state.url != "") 
            window.location = this.state.url;
    }

    getCustomers = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting customers from Xero..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `xero/${this.props.company}/customers`}).done(response => {
            this.props.closeSnackbar(key);

            if (response == "ok") {
                this.props.enqueueSnackbar(this.tr("Contact list updated."), {
                    variant: "success",
                });
            } else {
                this.props.enqueueSnackbar(this.tr("Error while fetching customers: " . response), {
                    variant: "error",
                });
            }
        });
    }

    getProducts = () => {
        DataHandler.get({url: `accounting/products/${this.props.company}/xero`}).done(response => {
            this.setState(response);
        });
    }

    changeDefault = (type, id) => {
        const company = this.props.company;
        this.setState({[type]: id}, () => DataHandler.put({url: `accounting/defaults/${company}`}, {type: type, id: id }));
    }
    
    render(){
        const { activated } = this.state;        
        const { tr } = this;

        return (
            <div id="integration-efina" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{tr("Xero Settings")}</h3>
                    {activated === "1" && <Button className="red deactivate" size="large" onClick={this.deactivate}>{tr("Deactivate")}</Button>}
                </div>

                {activated != 1 && <div className="settings">
                    <Button color="primary" onClick={this.authenticate} size="large">{tr("Connect to Xero")}</Button>
                </div>}

                {activated == 1 && <React.Fragment>
                    <div className="settings">
                        <h4>{tr("Sales accounts")}</h4>
                        <AccountingAccounts 
                            integration={"xero"}
                            company={this.props.company}
                            type={1}
                            addButtonText={this.tr("Add sales account")}
                        />

                        <div className="drop-section">
                            <h4>{tr("Default sales account")}</h4>
                            <OutlinedField select disabled={false} label={tr("Default")} name={"default"} value={this.state.default_accounting_accounts_id} fullWidth >
                                <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_accounts_id", 0)}>{this.tr("No default")}</MenuItem>
                                {this.state.salesAccounts.map(({name, id}) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_accounts_id", id)}>{name}</MenuItem>)}
                            </OutlinedField>
                        </div>
                    </div>

                    <div className="settings">                  
                        <h4>{tr("Items")}</h4>
                        <Button className="green" onMouseUp={() => this.productsList.current.addNewRow()} size="large">{tr("Add product")}</Button>
                        <br />
                        <List
                            ref={this.productsList}
                            id="accountingProductsList"
                            className="settingsList integrationList"
                            height="auto"
                            rowHeight={SettingRow.rowHeight}
                            newRow={{
                                id: -1,
                                name: "",
                                //product_id: "",
                            }}
                            listRowType={ProductRow}
                            columns={[
                                { width: 55, name: "delete", header: "", ...this.dColConf },
                                { width: 350, name: "name", header: tr("Item name"), ...this.dColConf },
                                { width: 200, name: "product_id", header: tr("Item Code"), ...this.dColConf },
                            ]}
                            rowProps={{
                                onCreate: data => {
                                    data.company = this.props.company;
                                    DataHandler.post({url: 'accounting/products/xero'}, { data: JSON.stringify(data) }).done(response => {
                                        this.getProducts();
                                    });
                                },
                                onUpdate: data => {
                                    DataHandler.put({url: 'accounting/products'}, { data: JSON.stringify(data) }).done(response => {
                                        this.getProducts();
                                    });
                                },
                                onDelete: data => {
                                    if(data.id == this.state.default_accounting_products_id) {
                                        this.props.enqueueSnackbar(this.tr("Default item cannot be deleted"), {
                                            variant: "error",
                                        });	
                                        return;
                                    }
                                    data.deleted = "1";
                                    DataHandler.delete({url: 'accounting/products'}, { data: JSON.stringify(data) }).done(response => {
                                        this.getProducts();
                                    });
                                }
                            }}
                            data={this.state.accountingProducts}
                        />

                        <div className="drop-section">
                            <h4>{tr("Default item")}</h4>
                            <OutlinedField select disabled={false} label={tr("Default")} name={"default"} value={this.state.default_accounting_products_id} fullWidth >
                                <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_products_id", 0)}>{this.tr("No default")}</MenuItem>
                                {this.state.accountingProducts.map(({name, id}) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_products_id", id)}>{name}</MenuItem>)}
                            </OutlinedField>
                        </div>
                    </div>
               
                    <div className="settings">
                        <h4>{tr("Get contacts")}</h4>
                        <p>{tr("Add Xero contacts as accounts to Taimer and update information about existing accounts")}</p>
                        <Button color="primary" onClick={this.getCustomers} size="large">{tr("Get contacts")}</Button>
                    </div>
                </React.Fragment>}
            </div>
        )
    }
}

export default Xero;