import { CloudDownload, Delete as DeleteIcon, ExpandMore, Publish} from "@mui/icons-material";
import cn from 'classnames';
import { addMonths, addYears, endOfMonth, format, parse, startOfMonth } from 'date-fns';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';
import DataHandler from '../general/DataHandler';
import PageTopSection, { Subheader, Tab } from '../general/PageTopSection';
import TaimerComponent from '../TaimerComponent';
import Toolbar from "./components/Toolbar";
import { AutocompleteData, getAutocompleteDataForDialog, getGlobalAutocomplete, ResourcingGlobalAutocomplete } from './helpers';
import styles from './ResourcingView.module.scss';

import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Loading from '../dashboard/insights/img/loading.svg';

import GanttConfiguration, { GanttViewColumn, GanttViewGrouping } from './views/Gantt/columns';
import GridConfiguration, { GridViewColumn, GridViewGrouping } from './views/Grid/columns';

import ResourcingImportTemplate from './import/Taimer_Resourcing_Import_template.xlsx';

import HighPriority from "./images/priority/high.svg";
import HighestPriority from "./images/priority/highest.svg";
import LowPriorirty from "./images/priority/low.svg";
import LowestPriorirty from "./images/priority/lowest.svg";
import NormalPriorirty from "./images/priority/normal.svg";

// Subviews
import Controls, { ControlsProps } from './components/Controls';
import GanttView from './views/Gantt/GanttView';
import GridView from './views/Grid/GridView';
import ListView from './views/List/ListView';
import UsageView from './views/Usage/UsageView';
import { ViewColumn, ViewGrouping, ViewMode } from './views/ViewConfiguration';

// Dialogs
import ImportTaskDialog from "../dialogs/imports/ImportTaskDialog";
import ResourceDialog from '../dialogs/ResourceDialog';
import ResourceLinkDeleteDialog from "../dialogs/ResourceLinkDeleteDialog";
import ConfirmationDialog from "../settings/dialogs/ConfirmationDialog";
import SplitTaskDialog from './dialogs/SplitTaskDialog';

// API
import { Radio } from '@mui/material';
import _ from 'lodash';
import VersionContentManager from '../general/VersionContentManager';
import { DayColumn, getAvailability, getTasks, getTaskTotals, Link, Project, Resource, ResourcingUser, Task, TotalData, UserAvailabilityData } from './resourcing';
import { GanttRow } from './views/Gantt/helpers';
import { addGridViewDataItem } from './views/Grid/helpers';
import { func } from "prop-types";

const viewName = "resourcing";

// Change if sticky search data changes
const stickySearchVersion = 3;

type StickyFilters = Omit<ResourcingFilters, "dateRange">;
interface StickySearchData {
    v: number,
    company: number;
    startDate?: string;
    endDate?: string;
    filters: Partial<StickyFilters>;
}

// Supported views
const views = {
    gantt: true,
    grid: true,
    usage: true,
    list: true,
};
export type validview = keyof typeof views;

const defaultView = 'gantt';

function isValidView(view: string | undefined | null): boolean {
    return !!view && (view in views);
}

export enum ProjectsShowMode {
    All,
    WithTasks,
    WithoutTasks,
}

interface GetViewSettingsResponse {
    viewName: string;
    config: ResourcingViewSettings;
}

interface ResourcingViewSettings {
    viewOptions: Partial<ViewOptions>;
}

export interface ViewOptions {
    showDone: boolean,
    showOverdue: boolean,
    showOnGoing: boolean,
    showMilestones: boolean,
    showWholeTrees: boolean,
    showMatchesAndChildren: boolean,
    showTotalVisible: boolean,
    showTotalOwn: boolean,
    showTotalRow: boolean,
    showUsersWithoutTasks: boolean,
    ganttPercentage: boolean,
    projectsShowMode: ProjectsShowMode,
    showSplits: boolean;
    includeNegative: boolean;
}
export type ViewOptionName = keyof ViewOptions;

interface ResourcingViewProps extends WithSnackbarProps {
    fullHeight: boolean;
    projectsId?: number;
    locked?: boolean;
    view?: keyof typeof views;
    singleProject?: boolean;
    company?: string;
    startDate?: string|null;
    endDate?: string|null;
    viewProps?: Record<string, unknown>,
    stickySearchKey: string;
    checkPrivilege?: (group: string, perm: string) => boolean;
    onProjectRangeExtended?: () => void;
    onDialogSave?: () => void;
    selectedTab: validview;
}

interface ResourceSplitDialog {
    name: 'split';
    task: Task;
}

interface ResourceEditDialog {
    name: 'edit';
    task: any;
}

interface ResourceDeleteConfirmDialog {
    name: 'delete';
    task: Resource;
}

interface ResourceDeleteLinkDialog {
    name: 'deleteLink';
    link: Link;
    source: GanttRow;
    target: GanttRow;
}

interface ResourceSimpleDialog {
    name: 'import' | 'deleteAll';
}


export type ResourcingDialog = ResourceEditDialog | ResourceSplitDialog | ResourceDeleteConfirmDialog | ResourceDeleteLinkDialog | ResourceSimpleDialog;

export interface Priority {
    id: number;
    name: string
    default: boolean;
    icon: string;
}

interface ResourcingViewState {
    dialog?: ResourcingDialog;
    filters: ResourcingFiltersWithKey;
    loading: boolean;
    companies: Company[];
    companiesWithWriteRight: Company[];
    hasReadRight: boolean;
    hasWriteRight: boolean;
    autocompleteGlobal?: ResourcingGlobalAutocomplete;
    autocomplete?: AutocompleteData;
    zoom?: string;
    expand: ExpandData;
    viewOptions: ViewOptions;
    // Gantt
    ganttSettings: ViewSettings;
    ganttColumns: GanttViewColumn[];
    ganttGroupings: GanttViewGrouping[];
    ganttExpandedRows: Dictionary<boolean>;
    // Grid
    gridSettings: ViewSettings;
    gridColumns: GridViewColumn[];
    gridGroupings: GridViewGrouping[];
    gridViewModes: ViewMode[];
    gridExpandedRows: Dictionary<boolean>;
    // Usage
    getUsageCount: number;
    // Data
    earliestTask: Date;
    latestTask: Date;
    projects: Project[];
    resources: Resource[];
    links: Link[];
    allowCreate: boolean;
    users: Dictionary<ResourcingUser>,
    //
    ganttExpandedRowsVersion: number;
    totalDataView?: TotalData;
    totalDataUser?: TotalData;
    totalDataAll?: TotalData;
    availability: Dictionary<UserAvailabilityData>;
    usersAllowedToShow: false|Dictionary<number>;
    // Dialog extra
    confirmationDialogRadio: string;
}

interface SelectedLabel {
    value: number;
    label: string;
}

export interface ResourcingFilters {
    company: number;
    dateRange?: {
        startDate: Date|null,
        endDate: Date|null,
        key: "selection",
    },
    customer?: SelectedLabel,
    projects?: SelectedLabel[],
    pipelines?: SelectedLabel[],
    users?: SelectedLabel[],
    teams?: SelectedLabel[],
    query?: string;
    terms?: any;
    showStatusBy?: {
        id: number;
        label: string;
    },
}

interface ResourcingFiltersWithKey extends ResourcingFilters {
    key: number;
}

interface Company {
    id: string | number;
    name: string;
}

export interface ExpandData {
    all: boolean;
    users: boolean;
    projects: boolean;
    subprojects: boolean;
    tasks: boolean;
    subtasks: boolean;
}

interface ExpandableView {
    expand: (type: keyof ExpandData) => void;
    collapse: (type: keyof ExpandData) => void;
}

interface LocalStorageColumn {
    name: string;
    hide?: boolean;
}

export interface ViewSettings {
    /**
     * Grouping (Project / User)
     */
    grouping: string;
    /**
     * Zoom (days/weeks/months)
     */
    zoom: string;
    /**
     * View mode (resourced hours / resourced % / availability in grid)
     */
    viewMode?: string;
}

export interface UpdateOptions {
    moved?: boolean;
}

export interface ResourcingSubviewProps extends WithSnackbarProps {
    autocompleteGlobal: ResourcingGlobalAutocomplete;
    autocomplete: AutocompleteData;
    updateCompomentData: () => void;
    updateResourcingData: () => Promise<void>;
    viewOptions: ViewOptions;
    checkPrivilege: (group: string, perm: string, company: number) => boolean;
    resources: Resource[];
    projects: Project[];
    links: Link[];
    allowCreate: boolean;
    updateTask: (id: string, update: Partial<Resource>, options: UpdateOptions) => void;
    updateLinks: (links: Link[]) => void;
    openDialog: (dialog: ResourcingDialog) => void;
    expand: ExpandData;
    totalDataView?: TotalData;
    totalDataUser?: TotalData;
    totalDataAll?: TotalData;
    filters: ResourcingFiltersWithKey;
    availability: Dictionary<UserAvailabilityData>;
    priorities: Priority[];
    newTaskExtra: Partial<Resource>;
    projectsId?: number;
    usersAllowedToShow: false|Dictionary<number>;
    getWorkdayLength: (userId: number, day: Date) => number;
    onProjectRangeExtended: (start: Date, end: Date) => void;
}

function restoreColumnSettings(view: string, columns: ViewColumn[]) {
    const key = `resourcing_${view}_columns`;

    if (!(key in localStorage))
        return;

    const data = JSON.parse(localStorage[key]) as (LocalStorageColumn[] | undefined | null);

    const columnByName = _.keyBy(columns, x => x.name);

    if (data && data.length) {
        for (const c of data) {

            const col = columnByName[c.name];

            if (col) {
                col.hide = c.hide;
            }
        }
    }
}

function saveColumnSettings(view: string, columns: ViewColumn[]) {
    const key = `resourcing_${view}_columns`;

    const data: LocalStorageColumn[] = [];

    for (const c of columns) {
        data.push({
            name: c.name,
            hide: c.hide,
        });
    }

    localStorage[key] = JSON.stringify(data);
}

function restoreViewSettings(view: string, settings: ViewSettings): ViewSettings {
    const key = `resourcing_${view}_settings`;

    if (!(key in localStorage))
        return settings;

    const data = JSON.parse(localStorage[key]) as (ViewSettings | undefined | null);

    if (!data) {
        return settings;
    }

    return { ...settings, ...data };
}


function saveViewSettings(view: string, settings: ViewSettings) {
    const key = `resourcing_${view}_settings`;

    localStorage[key] = JSON.stringify(settings);
}

function validateDate(date?: Date|null) {
    return !date || isNaN(date.getTime()) ? undefined : date;
}

async function postViewSettings(config: ResourcingViewSettings) {
    return await DataHandler.post({ url: `views/${viewName}` }, {
        config
    });
}

class ResourcingView extends TaimerComponent<ResourcingViewProps, ResourcingViewState> {
    static defaultProps = {
        stickySearchKey: "resourcing-view",
    };

    filtersInitialValues: ResourcingFiltersWithKey;
    refContent: React.RefObject<HTMLDivElement>;

    tabs: Tab[];
    priorities: Priority[];

    resourcingList: React.RefObject<ListView>;
    refGrid: React.RefObject<GridView>;
    refGantt: React.RefObject<GanttView>;

    localStorageKeyGantt: string;
    localStorageKeyGrid: string;

    constructor(props: ResourcingViewProps, context) {
        super(props, context, "resourcing/ResourcingView");

        // Onko tarpeellinen?
        let { startDate, endDate } = props;
        if (startDate === '0000-00-00' || endDate === '0000-00-00') {
            startDate = undefined;
            endDate = undefined;
        }

        const prefix = "Resourcing_" + context.userObject.usersId;
        this.localStorageKeyGantt = `${prefix}_GanttOpen`;
        this.localStorageKeyGrid = `${prefix}_GridOpen`;

        const { tr } = this;

        const filters: ResourcingFiltersWithKey = {
            key: 1,
            company: Number(this.props.company || context.functions.getCompany(
                "projects",
                "project_resourcing_read",
                false, true
            ) || context.functions.getCompany(
                "projects",
                "own_resourcing_read",
                false, true
            )),
            dateRange: {
                startDate: startDate ? parse(startDate, 'YYYY-MM-DD', new Date()) : (startDate === null ? null : startOfMonth(new Date())),
                endDate: endDate ? parse(endDate, 'YYYY-MM-DD', new Date()) : (endDate === null ? null : endOfMonth(addMonths(new Date(), 3))),
                key: "selection",
            },
            customer: { value: 0, label: tr("All") },
            projects: [],
            pipelines: [],
            users: [],
            teams: [],
            query: "",
            terms: {},
            showStatusBy: { id: -1, label: tr("All") },
        };

        let expand: ExpandData = {
            all: true,
            users: true,
            projects: true,
            subprojects: true,
            tasks: true,
            subtasks: true,
        };

        if (localStorage["gantt_expand_state"]) {
            const lsExpand = JSON.parse(localStorage["gantt_expand_state"]);

            if (lsExpand && "all" in lsExpand)
                expand = { ...lsExpand };
        }

        let ganttExpandedRows = {};
        let gridExpandedRows = {};

        // Gantt restore state
        if (localStorage[this.localStorageKeyGantt]) {
            const ls = JSON.parse(localStorage[this.localStorageKeyGantt]);
            if (typeof ls === 'object') {
                ganttExpandedRows = ls;
            }

        }

        // Grid restore state
        if (localStorage[this.localStorageKeyGrid]) {
            const ls = JSON.parse(localStorage[this.localStorageKeyGrid]);

            if (typeof ls === 'object') {
                gridExpandedRows = ls;
            }
        }

        this.tabs = VersionContentManager.getViewTabs(this.namespace).map(t => ({ ...t, action: (e) => this.setView(e, t.id) }));

        this.refContent = React.createRef();
        this.resourcingList = React.createRef();
        this.refGrid = React.createRef();
        this.refGantt = React.createRef();

        this.state = {
            filters,
            // Autocomplete
            loading: true,
            companies: [],
            companiesWithWriteRight: [],
            hasReadRight: false,
            hasWriteRight: false,
            // Common Options
            viewOptions: {
                showSplits: false,
                showOnGoing: true,
                showOverdue: true,
                showDone: false,
                showMilestones: true,
                showWholeTrees: true,
                showMatchesAndChildren: false,
                showTotalVisible: false,
                showTotalOwn: false,
                showTotalRow: true,
                showUsersWithoutTasks: false,
                ganttPercentage: true,
                includeNegative: false,
                projectsShowMode: ProjectsShowMode.WithTasks,
            },
            expand,
            // Gantt
            ganttSettings: restoreViewSettings('gantt', {
                grouping: GanttConfiguration.defaultGrouping,
                zoom: GanttConfiguration.defaultZoom,
            }),
            ganttColumns: this.handleColumns("gantt", GanttConfiguration.columns),
            ganttGroupings: this.handleGroupings("gantt", GanttConfiguration.groupings),
            ganttExpandedRows: ganttExpandedRows,
            ganttExpandedRowsVersion: 0,
            // Grid
            gridSettings: restoreViewSettings('grid', {
                grouping: GridConfiguration.defaultGrouping,
                zoom: GridConfiguration.defaultZoom,
                viewMode: GridConfiguration.defaultViewMode,
            }),
            gridColumns: this.handleColumns("grid", GridConfiguration.columns),
            gridGroupings: this.handleGroupings("grid", GridConfiguration.groupings),
            gridViewModes: this.handleViewModes("grid", GridConfiguration.viewModes ?? []),
            gridExpandedRows: gridExpandedRows,
            // Usage
            getUsageCount: 0,
            // Resourcing Data
            allowCreate: false,
            earliestTask: startOfMonth(new Date()),
            latestTask: endOfMonth(new Date()),
            projects: [],
            resources: [],
            links: [],
            availability: {},
            users: {},
            confirmationDialogRadio: 'this',
            usersAllowedToShow: false,
        }

        this.filtersInitialValues = { ...filters };
        this.priorities = this.getPriorities();
    }

    getPriorities = (default_priority = 3): Priority[] => {
        return [
            {
                id: 1,
                name: this.tr("Lowest"),
                default: default_priority === 1,
                icon: LowestPriorirty,
            },
            {
                id: 2,
                name: this.tr("Low"),
                default: default_priority === 2,
                icon: LowPriorirty,
            },
            {
                id: 3,
                name: this.tr("Normal"),
                default: default_priority === 3,
                icon: NormalPriorirty,
            },
            {
                id: 4,
                name: this.tr("High"),
                default: default_priority === 4,
                icon: HighPriority,
            },
            {
                id: 5,
                name: this.tr("Highest"),
                default: default_priority === 5,
                icon: HighestPriority,
            }
        ].reverse();
    }

    handleColumns = <T extends ViewColumn>(view: validview, columns: T[]) => {
        const { tr } = this;

        const final: T[] = columns.map(c => ({
            ...c,
            label: c.label && tr(c.label),
            hide: !c.defaultVisible,
        }));

        restoreColumnSettings(view, final);

        return final;
    }

    filterColumns = <T extends ViewColumn>(view: validview, columns: T[]) => {
        const { autocomplete, allowCreate } = this.state;

        const columnsToHide: Dictionary<boolean> = {
            projects_allocated: !this.context.taimerAccount.useExtraProjectHours,
            priority: autocomplete?.resourcing_disable_priority ?? false,
            skill: autocomplete?.resourcing_disable_skill ?? false,
            add: !allowCreate,
        };

        return columns.filter(x => !columnsToHide[x.name]);
    }

    filterGroupings = <T extends ViewGrouping>(view: validview, groupings: T[]) => {
        const { autocomplete } = this.state;

        const columnsToHide: Dictionary<boolean> = {
            priority: autocomplete?.resourcing_disable_priority ?? false,
            skill: autocomplete?.resourcing_disable_skill ?? false,
        };

        return groupings.filter(x => !columnsToHide[x.name]);
    }

    updateColumn = <T extends ViewColumn>(columns: T[], name: string, update: (column: T) => T) => {
        return columns.map(x => x.name === name ? update(x) : x);
    }

    handleGroupings = <T extends ViewGrouping>(view: validview, columns: T[]) => {
        const { tr } = this;

        const final: T[] = columns.map(c => ({
            ...c,
            label: tr(c.label),
        }));

        return final;
    }

    handleViewModes = <T extends ViewMode>(view: validview, columns: T[]) => {
        const { tr } = this;

        const final: T[] = columns.map(c => ({
            ...c,
            label: tr(c.label),
        }));

        return final;
    }

    componentDidMount(): void {
        this.initialize();

        window.addEventListener('taskSaved', this._onTaskSaved);
    }

    componentWillUnmount(): void {
        window.removeEventListener('taskSaved', this._onTaskSaved);
    }

    _onTaskSaved = () => {
        this.updateComponentData();
    }

    getResourcingViewSettings = async (): Promise<ResourcingViewSettings> => {
        try {
            const response = await DataHandler.get({ url: `views/${viewName}` }) as GetViewSettingsResponse;
            
            return response.config;
        } catch (error) {
            return {
                viewOptions: {},
            };
        }
    }

    saveResourcingViewSettings = () => {
        const { viewOptions } = this.state;

        postViewSettings({
            viewOptions
        });
    }

    initialize = async () => {
        const { viewOptions } = this.state;

        const [autocompleteGlobal, resourcingViewSettings, readCompanies, writeCompanies] = await Promise.all([
            getGlobalAutocomplete(),
            this.getResourcingViewSettings(),
            DataHandler.get({ url: `subjects/companies_with_project_right/project_resourcing_read+own_resourcing_read` }) as Promise<Company[]>,
            DataHandler.get({ url: `subjects/companies_with_project_right/project_resourcing_write+own_resourcing_write` }) as Promise<Company[]>,
        ]);

        this.setState({
            autocompleteGlobal,
            companies: readCompanies,
            hasReadRight: readCompanies.length > 0,
            companiesWithWriteRight: writeCompanies,
            hasWriteRight: writeCompanies.length > 0,
            viewOptions: {
                ...viewOptions,
                ...resourcingViewSettings.viewOptions,
            },
        }, () => {
            this.initializeStickySearch();
        });
    }

    checkPrivilege = (group, perm, company) => {
        if (this.props.checkPrivilege)
            return this.props.checkPrivilege(group, perm);

        return this.context.functions.checkPrivilege(group, perm, company);
    };

    initializeStickySearch = async () => {
        const { stickySearchKey, singleProject } = this.props;
        const { companies } = this.state;

        try {
            const response = await DataHandler.get({ url: `saved_search/sticky/${stickySearchKey}` }) as StickySearchData;

            if (response?.v !== stickySearchVersion) {
                this.updateComponentData();
                return;
            }

            const { company, filters: savedFilters, startDate, endDate } = response;

            const c = companies.find(x => Number(x.id) === company);

            if (!c) {
                this.updateComponentData();
                return;
            }

            const filters: ResourcingFiltersWithKey = {
                ...this.filtersInitialValues,
                ...savedFilters,
                key: this.state.filters.key + 1,
            };

            // TAIM9-5550 – in project view the date range should be project's length by default
            if (!singleProject && startDate && endDate) {
                const s = parse(startDate, "YYYY-MM-DD", new Date());
                const e = parse(endDate, "YYYY-MM-DD", new Date());

                filters.dateRange = {
                    startDate: s,
                    endDate: e,
                    key: "selection",
                };
            }

            this.setState({
                filters,
            }, () => {
                this.updateComponentData();
            });
        } catch (error) {
            this.updateComponentData();
        }
    }

    saveStickySearch = () => {
        const { stickySearchKey } = this.props;
        const { filters: { dateRange, ...filters } } = this.state;

        const stateToSave: StickySearchData = {
            v: stickySearchVersion,
            company: filters.company,
            filters: filters,
            startDate: dateRange?.startDate ? validateDate(dateRange?.startDate) && format(dateRange.startDate, 'YYYY-MM-DD') : undefined,
            endDate: dateRange?.endDate ? validateDate(dateRange?.endDate) && format(dateRange.endDate, 'YYYY-MM-DD') : undefined,
        };

        DataHandler.post({ url: `saved_search/sticky/${stickySearchKey}`, }, { search: stateToSave });
    }

    setView = (e: React.MouseEvent<HTMLElement>, view: validview) => {
        if (!e.ctrlKey && !e.metaKey)
            e.preventDefault();
        else
            return;

        this.context.functions.updateView({ view });
    }

    setProjectsShowMode = (mode: ProjectsShowMode) => {
        const { viewOptions } = this.state;

        if (mode === viewOptions.projectsShowMode) {
            mode = ProjectsShowMode.All;
        }

        this.toggleViewOption('projectsShowMode', mode);
    }

    toggleColumn = (name: string) => {
        const { ganttColumns, gridColumns } = this.state;
        const view = this.getCurrentView();

        if (view === 'gantt') {
            const columns = this.updateColumn(ganttColumns, name, (c) => ({ ...c, hide: !c.hide }));
            saveColumnSettings(view, columns);

            this.setState({
                ganttColumns: columns,
            });
        } else if (view === 'grid') {
            const columns = this.updateColumn(gridColumns, name, (c) => ({ ...c, hide: !c.hide }));
            saveColumnSettings(view, columns);

            this.setState({
                gridColumns: columns,
            });
        }
    }

    requiresReload = {
        showMatchesAndChildren: true,
        showWholeTrees: true,
        projectsShowMode: true,
        showTasksBy: true,
    };

    setViewOptions = (update: Partial<ViewOptions>) => {
        const { viewOptions } = this.state;

        const updated: ViewOptions = { 
            ...viewOptions,
            ...update,
        };

        let requiresReload = false;

        if (updated.showWholeTrees) {
            updated.showMatchesAndChildren = false;
        }

        if (viewOptions.showMatchesAndChildren !== updated.showMatchesAndChildren) {
            requiresReload = true;
        } else if (viewOptions.showWholeTrees !== updated.showWholeTrees) {
            requiresReload = true;
        } else if (viewOptions.projectsShowMode !== updated.projectsShowMode) {
            requiresReload = true;
        }

        this.setState({ viewOptions: updated }, () => {
            this.saveResourcingViewSettings();

            if (requiresReload) {
                this.updateComponentData();
            } else {
                this.setState({
                    totalDataView: this.calculateViewTotal(this.state.projects, this.state.resources, this.state.filters, updated, this.state.usersAllowedToShow),
                });
            }
        });
    }

    toggleViewOption = (name: ViewOptionName, value: boolean | undefined | ProjectsShowMode = undefined) => {
        const { viewOptions } = this.state;

        const update: Partial<ViewOptions> = {  };

        const currentValue = viewOptions[name];
        let newValue = currentValue;

        if (name === 'projectsShowMode') {
            update[name] = Number(value) || ProjectsShowMode.All;
        } else {
            if (value === undefined) {
                newValue = !newValue;
            } else {
                newValue = Boolean(value);
            }

            update[name] = newValue;
        }

        this.setViewOptions(update);
    }

    toggleExpand = (type: keyof ExpandData) => {
        const { ganttSettings } = this.state;
        const view = this.getCurrentView();

        let currentView: ExpandableView | null = null;

        if (view === 'gantt')
            currentView = this.refGantt.current;
        else if (view === 'grid')
            currentView = this.refGrid.current;

        const visible: string[] = [];

        if (view === 'gantt' && ganttSettings.grouping === 'customer') {
            visible.push('subprojects');
            visible.push('tasks');
            visible.push('subtasks');
        } else if (view === 'grid') {
            visible.push('projects');
            visible.push('users');
        }

        if (currentView) {
            const expand = {
                ...this.state.expand,
                [type]: !this.state.expand[type]
            };

            if (type === "all") {
                for (const iterator of Object.keys(expand)) {
                    expand[iterator] = expand.all;
                }
            } else if (visible.length > 0) {
                const isAllExpanded = visible.every(x => expand[x]);
                expand.all = isAllExpanded;
            }

            if (expand[type])
                currentView.expand(type);
            else
                currentView.collapse(type);

            this.setState({
                expand,
            });

            localStorage['gantt_expand_state'] = JSON.stringify(expand);
        }
    }

    getSubheaders = () => {
        const view = this.getCurrentView();

        const subheaders: Subheader[] = [];
        switch (view) {
            case 'list':
                subheaders.push({
                    header: this.tr("List")
                });
                break;
            case 'gantt':
                subheaders.push({
                    header: this.tr("Gantt")
                });
                break;
            case 'grid':
                subheaders.push({
                    header: this.tr("Grid")
                });
                break;
            case 'usage':
                subheaders.push({
                    header: this.tr("Utilisation")
                });
                break;
            default:
                break;
        }
        return subheaders;
    }

    getCurrentView = (): validview => {
        const { selectedTab } = this.props;

        return (isValidView(selectedTab) ? selectedTab : defaultView) ?? defaultView;
    }

    updateComponentData = async () => {
        const { filters } = this.state;

        this.updateCompanyData(filters);
        this.updateResourcingData(filters);
    }

    updateCompanyData = async (filters: ResourcingFilters) => {
        const { autocomplete } = this.state;

        if (autocomplete?.company !== filters.company) {
            const ac = await getAutocompleteDataForDialog(filters.company);
            this.setState({ autocomplete: ac });

            this.priorities = this.getPriorities(ac.default_priority);
        }
    }

    updateResourcingData = async (filters: ResourcingFilters = this.state.filters) => {
        const { enqueueSnackbar, projectsId } = this.props;
        const { viewOptions, getUsageCount } = this.state;

        this.setState({ loading: true });

        const filterOverrides: Partial<ResourcingFilters> = {};

        if (projectsId) {
            filterOverrides.projects = [{ value: projectsId, label: '' }];
        }

        try {
            const taskData = await getTasks({
                ...filters,
                ...filterOverrides,
            }, {
                showMatchesAndChildren: viewOptions.showMatchesAndChildren,
                showWholeTrees: viewOptions.showWholeTrees,
                singleProject: !!projectsId,
            });
            const { resources, projects, links, matchedUsers, users } = taskData;

            const usersAllowedToShow = matchedUsers !== false ? _.keyBy(matchedUsers, x => x) : false;

            let earliestTask = new Date();
            let latestTask = new Date();

            const safetyMarginStart = addYears(new Date(), -2);
            const safetyMarginEnd = addYears(new Date(), 2);

            if (taskData.has_more_results) {
                enqueueSnackbar(this.tr('There is more results than can be shown, showing earliest results. Use filters to narrow results.'), {
                    variant: 'info',
                });
            }

            for (const p of projects) {
                const start = p.start_date < safetyMarginStart ? safetyMarginStart : p.start_date;
                const end = p.end_date > safetyMarginEnd ? safetyMarginEnd : p.end_date;

                if (start < earliestTask) {
                    earliestTask = start;
                }
                if (end > latestTask) {
                    latestTask = end;
                }
            }

            for (const r of resources) {
                const start = r.start_date < safetyMarginStart ? safetyMarginStart : r.start_date;
                const end = r.end_date > safetyMarginEnd ? safetyMarginEnd : r.end_date;

                if (start < earliestTask) {
                    earliestTask = start;
                }
                if (end > latestTask) {
                    latestTask = end;
                }
            }

            this.setState({
                resources,
                projects,
                links,
                allowCreate: taskData.can_create,
                totalDataView: this.calculateViewTotal(projects, resources, filters, viewOptions, usersAllowedToShow),
                earliestTask: startOfMonth(earliestTask),
                latestTask: endOfMonth(latestTask),
                users: _.keyBy(users, x => x.id),
                usersAllowedToShow,
                getUsageCount: getUsageCount + 1,
            }, () => this.updateTotals(filters));
        } catch (error) {
            console.error(error);
            enqueueSnackbar(this.tr("Error loading tasks."), {
                variant: "error"
            });
        }

        this.setState({ loading: false });
    }

    getWorkweekForUser = (id: number, date: Date) => {
        const { users } = this.state;

        const u = users[id];

        if (!u)
            return null;

        for (const workweek of u.ww) {
            if (workweek.start <= date && (workweek.end === null || workweek.end >= date))
                return workweek;
        }
        
        return null;
    }

    dowToCol: {[id: number]: DayColumn} = {
        1: 'mon',
        2: 'tue',
        3: 'wed',
        4: 'thu',
        5: 'fri',
        6: 'sat',
        0: 'sun',
    }

    getWorkdayLength = (id: number, date: Date) => {
        const workweek = this.getWorkweekForUser(id, date);

        if (!workweek)
            return 0;

        const col = this.dowToCol[date.getDay()];

        const day = workweek[col];

        if (day === null || day < 0)
            return 0;

        return day;
    }

    calculateViewTotal = (projects: Project[], resources: Resource[], filters: ResourcingFilters, viewOptions: ViewOptions, usersAllowedToShow: Dictionary<number>|false) => {
        const viewTotal: TotalData = {
            gridData: {
                daily: {},
                monthly: {},
                weekly: {},
            },
            allocated: 0,
            projects_allocated: 0,
            projects_allocated_all_time: 0,
            remaining: 0,
            resourced: 0,
            tracked: 0,
        }

        for (const p of projects) {
            viewTotal.projects_allocated_all_time += p.projects_allocated || 0;
            viewTotal.projects_allocated += p.projects_allocated_period || 0;
        }

        for (const r of resources) {
            if (r.type !== 'task')
                continue;

            if (r.done && !viewOptions.showDone)
                continue;

            const combined = [...r.users_hours, ...r.users_hours_other];
       
            for (const uh of combined) {
                if (usersAllowedToShow !== false && !usersAllowedToShow[uh.users_id])
                    continue;

                viewTotal.allocated += uh.periodTotal?.resourced || 0;
                viewTotal.resourced += uh.periodTotal?.resourced || 0;
                viewTotal.tracked += uh.periodTotal?.tracked || 0;
                viewTotal.remaining += uh.periodTotal?.remaining || 0;

                addGridViewDataItem(viewTotal.gridData.daily, uh.gridData?.daily);
                addGridViewDataItem(viewTotal.gridData.weekly, uh.gridData?.weekly);
                addGridViewDataItem(viewTotal.gridData.monthly, uh.gridData?.monthly);
            }

            if (usersAllowedToShow === false || usersAllowedToShow[0]) {
                if (r.unassignedHours) {
                    viewTotal.allocated += r.unassignedHours.resourced;

                    addGridViewDataItem(viewTotal.gridData.daily, r.unassignedHours.gridData?.daily);
                    addGridViewDataItem(viewTotal.gridData.weekly, r.unassignedHours.gridData?.weekly);
                    addGridViewDataItem(viewTotal.gridData.monthly, r.unassignedHours.gridData?.monthly);
                }

                // viewTotal.tracked += r.periodTotal?.tracked_other ?? 0;
            }
        }

        return viewTotal;
    }

    updateTotals = async (filters: ResourcingFilters = this.state.filters) => {
        const { projectsId } = this.props;
        const { viewOptions } = this.state;

        const filterOverrides: Partial<ResourcingFilters> = {};

        if (projectsId) {
            filterOverrides.projects = [{ value: projectsId, label: '' }];
        }

        const response = await getTaskTotals({
            ...filters,
            ...filterOverrides,
        }, {
            showMatchesAndChildren: viewOptions.showMatchesAndChildren,
            showWholeTrees: viewOptions.showWholeTrees,
            singleProject: !!projectsId,
        });

        this.setState({
            totalDataAll: response.all,
            totalDataUser: response.user,
        });

        this.updateUserAvailability();
    }

    updateUserAvailability = async () => {
        const { latestTask } = this.state;

        const availability = await getAvailability(endOfMonth(latestTask));

        this.setState({ availability });
    }

    /**
     * Update task
     * @param {*} id Task ID
     * @param {*} update 
     */
    updateTask = (id, update: Partial<Resource>, options: UpdateOptions) => {
        const { resources } = this.state;

        const resource = resources.find(x => x.id === id);

        if (resource) {
            // Update 
            this.setState({
                resources: [
                    ...resources.filter(x => x.id !== id),
                    {
                        ...resource,
                        ...update,
                    } as Resource,
                ]
            });
        }

        if (options.moved) {
            setTimeout(() => this.updateUserAvailability(), 1000);
        }
    }

    updateLinks = (links: Link[]) => {
        this.setState({ links });
    }

    /** Dialogs **/

    /**
     * 
     * @param dialog 
     */
    openDialog = (dialog: ResourcingDialog) => {
        this.setState({ dialog });
    }

    /**
     * 
     */
    closeDialog = () => {
        this.setState({ dialog: undefined });
    }

    handleConfirmationRadio = (evt) => {
        this.setState({ confirmationDialogRadio: evt.target.value });
    }

    onProjectRangeExtended = (start: Date, end: Date) => {
        const { filters } = this.state;


        const newFilters: ResourcingFiltersWithKey = {
            ...filters,
            dateRange: filters.dateRange?.startDate && filters?.dateRange?.endDate ? {
                startDate: filters.dateRange.startDate > start ? start : filters.dateRange.startDate,
                endDate: filters.dateRange.endDate < end ? end : filters.dateRange.endDate,
                key: "selection",
            } : filters.dateRange,
            key: filters.key + 1,
        }

        console.log({start, end, newFilters})

        if (filters.dateRange?.startDate !== newFilters.dateRange?.startDate || filters.dateRange?.endDate !== newFilters.dateRange?.endDate)
        {
            this._onChangeFilters(newFilters, true);
        }

        this.props.onProjectRangeExtended?.();
    }

    _renderDialog = () => {
        const { dialog, resources } = this.state;
        const { company, projectsId, enqueueSnackbar } = this.props;

        if (!dialog)
            return null;

        if (dialog.name === 'edit') {
            const { task } = dialog;

            return (
                <ResourceDialog
                    singleProject={this.props.singleProject}
                    checkPrivilege={this.checkPrivilege}
                    onSave={this.saveResourceDialog}
                    onClose={this.closeDialog}
                    onProjectRangeExtended={this.onProjectRangeExtended}
                    task={task}
                    autoCompleteData={this.state.autocomplete}
                    allTasks={resources}
                    onDelete={this.updateComponentData}
                    closeAndUpdate={() => {
                        this.closeDialog();
                        setTimeout(() => this.updateComponentData(), 1100);
                    }}
                />
            );
        } else if (dialog.name === 'split') {
            const { task } = dialog;

            return (<SplitTaskDialog
                open
                onCancel={this.closeDialog}
                onSave={this.saveResourceDialog}
                task={task} />);
        } else if (dialog.name === 'delete') {
            const { task } = dialog;

            let congirmText = this.tr("Delete task \"${task}\"?", { task: task.description });
            let isRecurring = false;
            if (task.type === "milestone") {
                congirmText = this.tr("Delete milestone \"${task}\"?", { task: task.description });
            }
            if (task.type === 'task' && task.recurrence_parent_id > 0) {
                congirmText = this.tr("Do you want to delete recurring elements also?")
                isRecurring = true;
            }

            return (
                <ConfirmationDialog
                    data={{
                        text: congirmText,
                    }}
                    onDialogClose={() => this.setState({ dialog: undefined })}
                    onDialogSave={() => this.deleteQuery(task)}
                >
                    {isRecurring &&
                        <RadioGroup
                            name="resource-edit-recurrence"
                            value={this.state.confirmationDialogRadio}
                            onChange={this.handleConfirmationRadio}
                        >
                            <FormControlLabel
                                value="this"
                                label={this.tr("Only this")}
                                labelPlacement="start"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                value="future"
                                label={this.tr("Future")}
                                labelPlacement="start"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                value="all"
                                label={this.tr("All")}
                                labelPlacement="start"
                                control={<Radio />}
                            />
                        </RadioGroup>
                    }
                </ConfirmationDialog>);
        } else if (dialog.name === 'deleteLink') {
            return <ResourceLinkDeleteDialog
                id={dialog.link.id}
                onDelete={this.linkDeleted}
                link={{ ...dialog.link, sourceData: dialog.source, targetData: dialog.target }}
                onClose={() => { this.setState({ dialog: undefined }); }}
            />;
        } else if (dialog.name === 'import') {
            return <ImportTaskDialog
                company={company}
                projectId={projectsId}
                onDialogClose={this.saveResourceDialog}
            />
        } else if (dialog.name === 'deleteAll') {
            return (
                <ConfirmationDialog
                    data={{ text: this.tr("Do you really want to delete all tasks?") }}
                    onDialogSave={async () => {
                        this.closeDialog();
                        const response = await DataHandler.delete({ url: `resourcing/delete_all_tasks/${projectsId}` });

                        if (!response.success) {
                            enqueueSnackbar(this.tr("Some tasks could not be removed."), {
                                variant: "error"
                            });
                        } else {
                            enqueueSnackbar(this.tr("Tasks removed"), {
                                variant: "success"
                            });
                        }

                        setTimeout(() => this.updateComponentData(), 1100);
                    }}
                    onDialogClose={() => {
                        this.closeDialog();
                    }}
                />
            );
        }
    }

    linkDeleted = (id: string) => {
        this.setState({ dialog: undefined });
        const newLinks = this.state.links.filter(x => x.id !== id);
        this.setState({ links: newLinks });
    }

    deleteQuery = (task: Resource) => {
        const { enqueueSnackbar, closeSnackbar } = this.props;

        const key = enqueueSnackbar(this.tr("Deleting..."), {
            variant: "info",
            persist: true,
        });
        this.setState({
            dialog: undefined,
        });
        const url = task.type === 'task' ? 'resourcing/delete_task/' : 'resourcing/delete_milestone/';
        DataHandler.delete({ url: url + task.id, delete_others: this.state.confirmationDialogRadio }).done((response) => {
            closeSnackbar(key);

            if (response.success && task.type === 'task') {
                enqueueSnackbar(this.tr(`Task deleted successfully!`), {
                    variant: "success",
                });

                // this.deleteDialog(task.id, this.state.confirmationDialogRadio !== 'this');
            } else if (response.success) {
                enqueueSnackbar(this.tr(`Milestone deleted successfully!`), {
                    variant: "success",
                });

                // this.deleteDialog(task.id, this.state.confirmationDialogRadio !== 'this');
            } else if (response.error === 'subtask') {
                enqueueSnackbar(this.tr(`Tasks with subtasks can't be removed until you remove or move subtasks!`), {
                    variant: "error",
                });
            } else if (response.error === 'workhours') {
                enqueueSnackbar(this.tr(`Tasks with workhours logged can't be removed!`), {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(this.tr(`Error while deleting.`), {
                    variant: "error",
                });
            }

            window.dispatchEvent(new Event('taskSaved'));
        }).fail(() => {
            closeSnackbar(key);
            enqueueSnackbar(this.tr("Error while deleting."), {
                variant: "error"
            });
        });
    }

    saveResourceDialog = () => {
        this.setState({ dialog: undefined });

        setTimeout(() => this.updateComponentData(), 1100);

        if (this.props.onDialogSave) {
            this.props.onDialogSave();
        }
    };

    /** Renders **/

    /**
     * 
     * @returns 
     */
    _renderSummarySection = () => {
        const { totalDataView } = this.state;
        const { projectsId, locked } = this.props;
        const { tr } = this;
        const { functions: { addResource }} = this.context;

        const view = this.getCurrentView();

        return (
            <PageTopSection
                className={styles.summarySection}
                mainButtons={[
                    {
                        isVisible: !projectsId && this.state.hasWriteRight && !this.props.locked,
                        title: this.tr("NEW TASK"),
                        'data-testid': "add_task_button",
                        action: () => {
                            addResource({}, {

                            });
                        },
                    }
                ]}
                summaries={[
                    this.context.taimerAccount.useExtraProjectHours ? {
                        title: tr('Budgeted tot.'),
                        'data-testid': "budgeted_total",
                        value: totalDataView ? `${totalDataView?.projects_allocated.toFixed(2)} h` : '-',
                    } : undefined,
                    {
                        title: tr('Allocated tot.'),
                        'data-testid': "allocated_total",
                        value: totalDataView ? `${totalDataView?.allocated.toFixed(2)} h` : '-',
                    },
                    {
                        title: tr('Resourced tot.'),
                        'data-testid': "resourced_total",
                        value: totalDataView ? `${totalDataView?.resourced.toFixed(2)} h` : '-',
                    },
                    {
                        title: tr('Tracked tot.'),
                        'data-testid': "tracked_total",
                        value: totalDataView ? `${totalDataView?.tracked.toFixed(2)} h` : '-',
                    },
                ]}
                settingsButton={{
                    isVisible: !(null == this.context.privileges.admin),
                    title: this.tr("Settings"),
                    href: this.context.functions.urlify({ module: 'settings', action: 'index', group: 'features', page: 'resourcing' }),
                    action: () => this.context.functions.updateView({ module: 'settings', action: 'index', group: 'features', page: 'resourcing' }, false)
                }}
                additionalButtons={[
                    view === "list" && {
                        title: this.tr("Export tasks"),
                        action: () => this.resourcingList.current && this.resourcingList.current.onToolbarExportClick(),
                        icon: <CloudDownload />
                    },
                    !!projectsId && !locked && {
                        icon: '',
                        iconAfter: <ExpandMore />,
                        className: 'text-button grey',
                        text: this.tr("Options"),
                        title: this.tr("Options"),
                        popoverClass: styles['resourcing-options-dropdown'],
                        popoverComponent: (closeFunc) => (
                            <div className={styles["export-dropdown"]}>
                                <button onClick={() => {
                                    this.openDialog({name: 'import'});
                                    closeFunc();
                                }}>
                                    <Publish /> {this.tr("Import template")}
                                </button>
                                <a href={ResourcingImportTemplate} download>
                                    <CloudDownload /> {this.tr("Download import excel")}
                                </a>
                                <button onClick={() => { this.openDialog({ name: 'deleteAll' }); closeFunc(); }}>
                                    <DeleteIcon /> {this.tr("Delete tasks")}
                                </button>
                            </div>
                        ),
                    }
                ]}
            />
        );
    };

    _onChangeFilters = (filters: ResourcingFiltersWithKey, skipResourcingUpdate = false) => {
        this.updateCompanyData(filters);

        if (!skipResourcingUpdate)
            this.updateResourcingData(filters);

        this.setState({ filters }, async () => {
            this.saveStickySearch();
        });
    }

    setGridExpandedRows = (expandedRows) => {
        this.setState({ gridExpandedRows: expandedRows });
        localStorage[this.localStorageKeyGrid] = JSON.stringify(expandedRows);
    }

    setGanttExpandedRows = (expandedRows, forceUpdate = false) => {
        const { ganttExpandedRowsVersion } = this.state;
        this.setState({ ganttExpandedRows: expandedRows, ganttExpandedRowsVersion: forceUpdate ? ganttExpandedRowsVersion + 1 : ganttExpandedRowsVersion });
        localStorage[this.localStorageKeyGantt] = JSON.stringify(expandedRows);
    }

    _renderHeader = () => {
        const { companies, filters, loading } = this.state;
        const { singleProject, projectsId } = this.props;

        return (
            <div className={cn(styles.header, styles.listControlsContainer)}>
                <Toolbar
                    filters={filters}
                    companies={companies}
                    singleProject={singleProject}
                    onChange={this._onChangeFilters}
                    loading={loading}
                    projectsId={projectsId}
                    view={this.getCurrentView()}
                    // statusOptions={this.statusOptions}
                    filtersInitialValues={this.filtersInitialValues}
                />
                {this._renderSummarySection()}
            </div>
        );
    };

    /**
     * 
     * @returns 
     */
    _renderGantt = (commonProps: ResourcingSubviewProps) => {
        const {
            ganttSettings, ganttColumns, earliestTask, latestTask,
            ganttExpandedRowsVersion, ganttExpandedRows,
            filters: {
                dateRange,
            } } = this.state;

        if (!this.refContent.current) {
            return <div />
        }

        const startdate = validateDate(dateRange?.startDate) || earliestTask;
        const enddate = validateDate(dateRange?.endDate) || latestTask;

        return <GanttView
            ref={this.refGantt}
            {...commonProps}
            columns={this.filterColumns("gantt", ganttColumns)}
            parentElement={this.refContent}
            startdate={startdate}
            enddate={enddate}
            settings={ganttSettings}
            expandedRows={ganttExpandedRows}
            expandedRowsVersion={ganttExpandedRowsVersion}
            setExpandedRows={this.setGanttExpandedRows} />
    }

    /**
     * 
     * @returns 
     */
    _renderGrid = (commonProps: ResourcingSubviewProps) => {
        const { gridSettings, gridColumns, earliestTask, latestTask,
            gridExpandedRows,
            filters: {
                dateRange,
            } } = this.state;

        const startdate = validateDate(dateRange?.startDate) || earliestTask;
        const enddate = validateDate(dateRange?.endDate) || latestTask;

        return <GridView
            ref={this.refGrid}
            {...commonProps}
            columns={this.filterColumns("grid", gridColumns)}
            settings={gridSettings}
            startdate={startdate}
            enddate={enddate}
            expandedRows={gridExpandedRows}
            setExpandedRows={this.setGridExpandedRows} />
    }

    /**
     * 
     * @returns 
     */
    _renderList = (commonProps: ResourcingSubviewProps) => {
        return <ListView ref={this.resourcingList} {...commonProps} />
    }

    /**
     * 
     * @returns 
     */
    _renderUsage = (commonProps: ResourcingSubviewProps) => {
        return <UsageView usageGetCount={this.state.getUsageCount} {...commonProps} singleProject={this.props.singleProject} />
    }

    _updateGanttSettings = (update: Partial<ViewSettings>) => {
        const { ganttSettings } = this.state;

        const newSettings = {
            ...ganttSettings,
            ...update,
        };

        saveViewSettings('gantt', newSettings);

        this.setState({
            ganttSettings: newSettings,
        });
    }

    _updateGridSettings = (update: Partial<ViewSettings>) => {
        const { gridSettings } = this.state;

        const newSettings = {
            ...gridSettings,
            ...update,
        };

        saveViewSettings('grid', newSettings);

        this.setState({
            gridSettings: newSettings,
        });
    }

    _renderControls = () => {
        const view = this.getCurrentView();

        if (view === 'usage')
            return null;

        const { ganttSettings, ganttColumns, ganttGroupings, gridSettings, gridViewModes, gridColumns, gridGroupings, viewOptions, expand } = this.state;

        const commonProps = {
            view,
            toggleColumn: this.toggleColumn,
            setViewOptions: this.setViewOptions,
            toggleViewOption: this.toggleViewOption,
            setProjectsShowMode: this.setProjectsShowMode,
            viewOptions,
            expand,
            toggleExpand: this.toggleExpand
        };

        const propsByView: Dictionary<Omit<ControlsProps, keyof typeof commonProps>> = {
            gantt: {
                columns: this.filterColumns("gantt", ganttColumns),
                groupings: this.filterGroupings("gantt", ganttGroupings),
                settings: ganttSettings,
                zooms: GanttConfiguration.zooms.map(x => ({ ...x, label: this.tr(x.label) })),
                updateSettings: this._updateGanttSettings,
            },
            grid: {
                columns: this.filterColumns("grid", gridColumns),
                groupings: this.filterGroupings("grid", gridGroupings),
                settings: gridSettings,
                zooms: GridConfiguration.zooms.map(x => ({ ...x, label: this.tr(x.label) })),
                viewModes: gridViewModes,
                updateSettings: this._updateGridSettings,
            },
            list: {
                viewModes: [],
                zooms: [],
            },
        }

        return <Controls {...commonProps} {...propsByView[view]} />
    }

    render() {
        const { singleProject, projectsId, fullHeight, enqueueSnackbar, closeSnackbar } = this.props;
        const {
            filters, viewOptions, autocomplete, autocompleteGlobal, resources, projects,
            links, allowCreate, expand, totalDataView, totalDataUser, totalDataAll, availability,
            usersAllowedToShow,
        } = this.state;

        const isLoading = !autocomplete || !autocompleteGlobal;

        const view = this.getCurrentView();

        const contentRender = {
            gantt: this._renderGantt,
            grid: this._renderGrid,
            list: this._renderList,
            usage: this._renderUsage,
        }

        const newTaskExtra: Partial<Resource> = {};

        if (singleProject) {
            newTaskExtra.projects_id = projectsId;
        }

        const filterOverrides: Partial<ResourcingFilters> = {};

        if (projectsId) {
            filterOverrides.projects = [{ value: projectsId, label: '' }];
        }

        return <div id="resourcing-view" className={cn(styles.root, fullHeight && styles.fullHeight)}>
            {isLoading && <div className={styles.loading}>
                <img src={Loading} alt={this.tr("Loading...")} />
            </div>}
            {this._renderHeader()}
            {this._renderControls()}
            <div ref={this.refContent} className={styles.content}>
                {!isLoading ? contentRender[view]?.({
                    enqueueSnackbar, closeSnackbar,
                    updateCompomentData: this.updateComponentData,
                    updateResourcingData: this.updateResourcingData,
                    checkPrivilege: this.checkPrivilege,
                    autocomplete,
                    autocompleteGlobal,
                    viewOptions,
                    resources,
                    projects,
                    links,
                    allowCreate,
                    updateTask: this.updateTask,
                    updateLinks: this.updateLinks,
                    openDialog: this.openDialog,
                    expand,
                    totalDataView,
                    totalDataUser,
                    totalDataAll,
                    filters: {
                        ...filters,
                        ...filterOverrides,
                    },
                    availability,
                    priorities: this.priorities,
                    newTaskExtra,
                    usersAllowedToShow,
                    getWorkdayLength: this.getWorkdayLength,
                    onProjectRangeExtended: this.onProjectRangeExtended,
                }) : <div />}
            </div>
            {this._renderDialog()}
        </div>
    }
}

export default withSnackbar(ResourcingView);