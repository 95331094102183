import { returnCurrencyList } from "./Currencies";
import { roundToFixed, nmultiply, ndivide } from './MathUtils';
import cloneDeep from 'lodash/cloneDeep';

const currencySymbolsMap = returnCurrencyList()?.reduce((acc, cl) => ({...acc, [cl.id]: cl.symbol}), {});

interface currencyObject {
    currency_label: string;
	id: string;
    active_status: string;
    currency_rate: string;
    currency_rate_reverse: string;
}

interface basicDropData {
    name: string;
    label: string;
    id: string;
}

interface currencyMappedObject extends basicDropData {
    rateId: string;
    currency_code: string;
    currency_rates_id: string;
    status: string;
    rate: number;
    currency_rate: number;
    rate_reverse: number;
    reverse: number;
}

interface currencyDetailsObject {
    currency_code: string;
    currency_rate: number;
    currency_rates_id: string;
}

interface params {
    defaultValue: currencyDetailsObject;
    field: string;
    searchValue: string;
}

interface rowObject {
    deleted: string;
}

export class CurrencyUtils {


    static getSymbol(currencyCode: string): string {
        return currencySymbolsMap[currencyCode];
    }

    static mapCurrenciesForAutocomplete(rawCurrencyArray: currencyObject[]): currencyMappedObject[] {
    	const mappedCurrencies = rawCurrencyArray.map(c => ({
    		rateId: c.id, 
    		id: c.currency_label,
            currency_rates_id: c.id,
    		name: c.currency_label,
            currency_code: c.currency_label, 
    		status: c.active_status, 
    		label: c.currency_label, 
    		rate: Number(c.currency_rate),
            currency_rate: Number(c.currency_rate), 
    		symbol: this.getSymbol(c.currency_label), 
    		rate_reverse: Number(c.currency_rate_reverse), 
    		reverse: Number(c.currency_rate_reverse),
            value: c.currency_label
    	}));
        return mappedCurrencies;
    }

    static returnActiveCurrencyAutocompleteValue(currencies: currencyMappedObject[], id: string, params: params): basicDropData {
        
        const {defaultValue: {currency_code}} = params;
        const activeCurrencies = currencies.filter(c => c.status == 'active');
        const activeCurrency = activeCurrencies.find(ac => ac.id == id)

        const autocompleteValue = ['id', 'name', 'label'].reduce((acc, r) => ({...acc, [r]: activeCurrency?.id || currency_code}), {} as basicDropData);
        return autocompleteValue;
    }

    static sumRowValues(rows, params: {fieldName: string, rate: number, inCompanyCurrency: boolean, oldCurrencyConvert: boolean}): number {

        let sum = 0;
        const {fieldName, rate, inCompanyCurrency} = params;

        rows.map(r => {
            const rowValue = !isNaN(r[fieldName]) ? Number(r[fieldName]) : 0;
            let currencyValue = !isNaN(r[`currency_${fieldName}`]) ? Number(r[`currency_${fieldName}`]) : 0;

            if (inCompanyCurrency) {
                currencyValue = rowValue;
            }

            sum += (!r.deleted || r.deleted < 1) && r.row_type != 13 ? currencyValue : 0
        });

        return Number(sum.toFixed(2)) || 0;       
    }    

    static calculateVats(rows): object {
        const vats: { [key: number]: number } = {};

        rows.map(r => {
            if ((!r.deleted || r.deleted < 1) && r.row_type != 13) {
                if (r.vat !== undefined) {
                    const rowVat = Number(Number(r.vat).toFixed(2));
                    const vatAmount = Number((Number(r.currency_total) - Number(r.currency_total_no_vat)).toFixed(2));
                    if (vats[rowVat] === undefined && vatAmount) {
                        vats[rowVat] = vatAmount;
                    }
                    else if (vatAmount) {
                        vats[rowVat] += vatAmount;
                    }
                }
            }
        });

        Object.keys(vats).forEach(vatRate => {
            vats[vatRate] = Number(vats[vatRate].toFixed(2));
        });

        return vats;
    }  

    static returnFirstActiveCurrencyDetails(currencies: currencyMappedObject[], params: params): currencyDetailsObject {
        const {defaultValue} = params;
        const activeCurrencies = currencies.filter(c => c.status == 'active');
        const {currency_code = defaultValue.currency_code, currency_rate = defaultValue.currency_rate, currency_rates_id = defaultValue.currency_rates_id} = activeCurrencies?.[0] || {};
        return {currency_code, currency_rate, currency_rates_id};
    }

    static returnSpecificActiveCurrencyDetails(currencies: currencyMappedObject[], params: params): currencyDetailsObject {
        const {defaultValue, field, searchValue} = params;
        const activeCurrencies = currencies.filter(c => c.status == 'active');
        const currency = activeCurrencies.find(c => c[field] == searchValue);
        const {currency_code = defaultValue?.currency_code, currency_rate = defaultValue?.currency_rate, currency_rates_id = defaultValue?.currency_rates_id} = currency || {};
        return {currency_code, currency_rate, currency_rates_id};
    }

    static returnMatchingActiveCurrencyDetail(currencies: currencyMappedObject[], field: string, searchValueProgression: string[]): any {
        const activeCurrencies = currencies.filter(c => c.status == 'active');
        let currency: currencyDetailsObject|undefined = undefined;
        searchValueProgression.some(v => {
            currency = activeCurrencies.find(c => c.id == v);
            return currency;
        });
        return currency?.[field];
    }

    static roundNumber(number: string|number, decimalPlaces: number): string {
        if (decimalPlaces < 0) {
            return number.toString();
        }
        return roundToFixed(number, decimalPlaces);
    }

    static calculateRowValues(row, params: {fieldName: string, rate: number, oldCurrencyConvert: boolean, valueDecimalAmount?: number, totalsDecimalAmount?: number}) {
        const {fieldName, rate, oldCurrencyConvert} = params;

        const valueDecimalAmount = params.valueDecimalAmount || 4;
        const totalsDecimalAmount = params.totalsDecimalAmount || 2;

        if (!row['value']) {
            row['value'] = 0;
        }
        if (!row['quantity']) {
            row['quantity'] = 0;
        }
        if (!row['vat']) {
            row['vat'] = 0;
        }
        const quantity = Number(row['quantity']);
        const vat = Number(row['vat']);

        let currencyValue  = row['currency_value'];

        const calculateCurrencyValue = function (val) {
            const value = Number(val || 0);
            const r = Number(rate || 0);
            if (oldCurrencyConvert) {
                return ndivide(value, r);
            }
            return nmultiply(value, r);
        }
        const calculateCompanyValue = function (val) {
            const value = Number(val || 0);
            const r = Number(rate || 0);
            if (oldCurrencyConvert) {
                return nmultiply(value, r);
            }
            return ndivide(value, r);
        }

        if (fieldName == "cost" || fieldName == "cost_and_value") { // cost is in company's currency.
            row['cost']                = this.roundNumber(row['cost'], 10);
            row['currency_cost']       = this.roundNumber(calculateCurrencyValue(row['cost']), valueDecimalAmount);
            row['cost_total']          = this.roundNumber(nmultiply(row['cost'], quantity), 2);
            row['currency_cost_total'] = this.roundNumber(nmultiply(row['currency_cost'], quantity), 2);
        }

        if (fieldName == "value" || fieldName == "cost_and_value") { // value is in company's currency.
            row['value']  = this.roundNumber(row['value'], 10);
            currencyValue = this.roundNumber(calculateCurrencyValue(row['value']), valueDecimalAmount);
        }
        else if (fieldName == "quantity") {
            row['previous_quantity'] = row['quantity'];
        }
        else if (fieldName == "total") { // total is in company's currency.
            const currencyTotalNoVat = this.roundNumber(calculateCurrencyValue(this.calculateNetValueFromGross(row['total'], vat, -1)), 2);
            row['value']  = this.roundNumber(calculateCompanyValue(Number(currencyTotalNoVat) / quantity), 10); // Calculate company value from currency value (totalNoVat / quantity)
            currencyValue = this.roundNumber(calculateCurrencyValue(row['value']), valueDecimalAmount);
        }

        const roundedValue = this.roundNumber(row['value'], valueDecimalAmount); // row['value'] is the precise value. Calculations are done with valueDecimalAmount decimals but currency change with all decimals.
        
        row['discount_amount']          = this.calculateDiscountAmount(roundedValue, row.discountPercentage, 2);
        row['currency_discount_amount'] = this.calculateDiscountAmount(currencyValue, row.discountPercentage, 2);

        row['discount_total']           = this.roundNumber(nmultiply(quantity, Number(row['discount_amount'])), 2);
        row['currency_discount_total']  = this.roundNumber(nmultiply(quantity, Number(row['currency_discount_amount'])), 2);

        row['total_no_vat']             = this.calculateNetTotalWithDiscount(roundedValue, quantity, row['discount_amount'], totalsDecimalAmount); // Total with discount.
        row['currency_total_no_vat']    = this.calculateNetTotalWithDiscount(currencyValue, quantity, row['currency_discount_amount'], totalsDecimalAmount); // Total in currency with discount.

        row['total']                    = this.calculateGrossValueFromNet(row['total_no_vat'], vat, totalsDecimalAmount);
        row['currency_total']           = this.calculateGrossValueFromNet(row['currency_total_no_vat'], vat, totalsDecimalAmount);
        
        row['currency_value']           = currencyValue;

        return row;       
    } 

    static calculateNetTotal(value: string|number, quantity: string|number, decimals: number): string {
        const total = nmultiply(Number(quantity), Number(value));
        return this.roundNumber(total, decimals);
    }

    static calculateNetTotalWithDiscount(value: string|number, quantity: string|number, discountAmount: string|number, decimals: number): string {
        const total = nmultiply(Number(quantity), Number(value) - Number(discountAmount));
        return this.roundNumber(total, decimals);
    }

    static calculateGrossValueFromNet(netTotal: string|number, vatPercent: string|number, decimals: number): string {
        const total = nmultiply(Number(netTotal), (1 + Number(vatPercent)/100));
        return this.roundNumber(total, decimals);
    }

    static calculateNetValueFromGross(grossTotal: string|number, vatPercent: string|number, decimals: number): string {
        const value = ndivide(Number(grossTotal), (1 + Number(vatPercent)/100));
        return this.roundNumber(value, decimals);
    }

    static calculateDiscountAmount(value: string|number, discountPercent: string|number, decimals: number): string {
        const percent = Number(discountPercent || 0);
        const amount = percent > 0
            ? nmultiply(percent / 100, Number(value))
            : 0;

        return this.roundNumber(amount, decimals);
    }

    static setRowCurrencyValues(rows, params: {rate: number, oldCurrencyConvert: boolean}): number {
        const {rate, oldCurrencyConvert} = params;

        if (rows && rows.length) {
            rows = rows.map(r => {
                if (r.value != 0) {
                    r = this.calculateRowValues(r, {fieldName: "value", rate, oldCurrencyConvert});
                }
                return r;
            })
        }

        return rows;       
    } 
}

//chekka et kui teed uue arve vana editoinnist siis tulebikka company data