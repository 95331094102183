import React, { Component } from 'react';
import TaimerComponent from '../TaimerComponent';
import _ from 'lodash';
import cn from 'classnames';

class ProfitLossTableSlot extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "charts/ProfitLossTableSlot");

        this.state = {
            sliderOpen: false,
        }
    }

    open = () => {
        const { chartId, data, value, label, table, month, monthLabel, currency, currencyFormatter, onMouseOver, onMouseOut } = this.props;
        const { openSlider } = this.props;

        openSlider(chartId, {
            label,
            value,
            data,
            table,
            month,
            monthLabel,
            // currency={currency}
            // currencyFormatter={currencyFormatter} 
            // label={label} 
            // sum={value} 
            // data={data} 
            // open={sliderOpen} 
            // table={table}
            // month={month}
            // monthLabel={monthLabel}
        });

        this.setState({ sliderOpen: true });
    }

    close = (e) => {
        e.stopPropagation();
        this.setState({ sliderOpen: false });
    }

    render() {
        const { className, linked, data, value, label, table, month, monthLabel, currency, currencyFormatter, onMouseOver, onMouseOut } = this.props;
        const { sliderOpen } = this.state;

        return <div onClick={linked && this.open} className={cn(
            "column", "row-value", className, linked && "linked",
            value < 0 && "value-negative", value > 0 && "value-positive"
        )} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            {currencyFormatter(value)}
        </div>
    }
}



export default ProfitLossTableSlot;