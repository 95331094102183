//onko deprecated?

import React from 'react';
import DatePicker from './../../general/react-date-range/src/components/DatePicker';
import { parse, format } from 'date-fns';

class CustomFieldDate extends React.Component {
    static defaultProps = {
        disabled: false,
        error: false,
    };

    constructor(props) {
        super(props);
    }

    onChange = (date) => {
        // this.setState({valid: !!date});

        if (date) {

            this.props.onChange(this.props.name, format(date, 'YYYY-MM-DD'));
        } else {
            this.props.onChange(this.props.name, '');
        }
    }

    onInputChange = (evt, date) => {
		date = format(date, 'YYYY-MM-DD');
        this.onChange(date);
	}

    render() {
        const { name, value, error, disabled, hideNoValueError, field: {
            name: label, required, validate
        } } = this.props;

        const novalue = value === '';

        return  <DatePicker
                    fullWidth
                    label={!required ? label : label +" *"}
                    className="date"
                    name={name}
                    date={value ? parse(value, 'YYYY-MM-DD', new Date(0)) : ''}
                    error={!disabled && (error || (required && validate && novalue && !hideNoValueError))}
                    disabled={disabled}
                    dateFormat={this.props.dateFormat}
                    onInputChange={this.onInputChange}
                    onChange={this.onChange} 
                    usePopper
                    popperBottom />
    }
}

export default CustomFieldDate;