import cloneDeep from 'lodash/cloneDeep';
import DataHandler from '../general/DataHandler';
import { ProjectType } from './resourcing';


interface Customer {
    id: string;
    name: string;
    locked: string;
}

interface Pipeline {
    id: string;
    name: string;
    locked: string;
    label: string;
    is_default?: number;
}

interface Project {
    id: string;
    name: string;
    customers_id: string;
    parentid: string;
    parent_id: string;
    locked: string;
    type: ProjectType;
}

interface ProjectDrop extends Project {
    customer: string;
    label: string;
}

interface Priority {
    id: number;
    name: string;
    is_default: boolean;
    icon?: any;
}

export interface Skill {
    id: number;
    name: string;
    description: string;
    is_default: boolean;
}

interface Team {
    id: any;
    value: any;
    name: string;
    label: string;
    type: any;
}

export interface Employee {
    id: number;
    companies_id: number;
    locked: number;
    deleted: boolean;
    name: string;
    role?: string;
    color?: string;
    read_companies: number[];
    read_companies_all: number[];
    read_companies_projects_only: number[];
    projects: number[];
}

interface EmployeeWithLabel extends Employee {
    label: string;
}

interface AutocompleteRequest {
    company: number;
    customers: Customer[];
    projects: Project[];
    priorities: Priority[];
    skills: Skill[];
    pipelines: Pipeline[];
    team: Team[];
    default_priority: number;
    resourcing_disable_skill: boolean;
    resourcing_disable_priority: boolean;
    resourcing_count_saturday: boolean;
    resourcing_count_sunday: boolean;
    allow_tracking_all_tasks: boolean;
    employees: Employee[];
}

export interface AutocompleteData extends AutocompleteRequest {
    employees: EmployeeWithLabel[];
    project_drop: ProjectDrop[];
}

export interface ResourcingGlobalAutocomplete {
    holidays: Dictionary<Dictionary<boolean>>;
}

const autocompleteCache: Dictionary<AutocompleteData> = {};

export async function getGlobalAutocomplete() {
    const data = await DataHandler.get({
        url: `resourcing/autocomplete`,
    }) as ResourcingGlobalAutocomplete;

    return data;
}

export async function clearAutoCompleteCache(companies_id) {
    const company = Number(companies_id);

    if (autocompleteCache[company]) {
        delete autocompleteCache[company];
    }
}

export async function getAutocompleteDataForDialog(companies_id, force_refresh = false, translations = {freelancer: 'freelancer', locked: 'locked'}): Promise<AutocompleteData> {
    const company = Number(companies_id);

    if (autocompleteCache[company] && !force_refresh) {
        return cloneDeep(autocompleteCache[company]);
    }

    const data = await DataHandler.get({
        url: `resourcing/autocomplete/${companies_id}`, include_employees: 1
    }) as AutocompleteRequest;

    try {
        const project_drop = data.projects.map((p) => {
            const cust = data.customers.find((c) => Number(c.id) === Number(p.customers_id));
            return { customer: cust?.name ?? "", label: `${p.name} ${cust?.name}`, ...p };
        });
    
        project_drop.sort((a, b) => {
            const result = (a.customer ? a.customer : '').localeCompare((b.customer ? b.customer : ''));
            if(result !== 0) {
                return result;
            }
            return (a.name ? a.name : '').localeCompare((b.name ? b.name : ''));
        })
    
        autocompleteCache[company] = {
            ...data,
            project_drop,
            employees: data.employees.map(o => ({...o, 
                name: `${o.name}${o.companies_id < 1 ? ` (${translations.freelancer})` : ''}${o.locked > 0 ? ` (${translations.locked})` : ''}`}))
            .map(el => ({...el, label: el.name })),
        };
    
    } catch (error) {
        console.error("error parsing resourcing autocomplete", {data});

        return {
            company: companies_id,
            allow_tracking_all_tasks: false,
            customers: [],
            default_priority: 1,
            employees: [],
            pipelines: [],
            priorities: [],
            project_drop: [],
            projects: [],
            resourcing_count_saturday: false,
            resourcing_count_sunday: false,
            resourcing_disable_priority: true,
            resourcing_disable_skill: true,
            skills: [],
            team: [],
        }
    }

    return cloneDeep(autocompleteCache[company]);
}

export function divideHoursEqually(hours: number, users: number): number {
    if (hours <= 0)
        return 0;

    if (users < 2) // 0 or 1 users
        return hours;

    let perUser = Number((hours / users).toFixed(2));

    while (perUser * users > hours) {
        perUser -= 0.01;
    }

    return Number(perUser.toFixed(2));
}