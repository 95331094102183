import DataHandler from "./DataHandler";
import FileSaver from "file-saver";
import { reduceSum } from "./MathUtils";

interface UploadResponse {
    success: boolean;
    partialSuccess: boolean;
    failure: boolean;
    failedFiles: File[];
    originalFiles: File[];
    maxSizeExceeded: boolean;
}

function isImageFileType(s: string | undefined): boolean {
    if(!s) {
        return false;
    }

    return ["png", "jpg", "jpeg"]
        .find((it: string) => s.indexOf(it) > -1) !== undefined;
}

function downloadAttachment(fileId: string, fileName: string | undefined) {
    fileName = fileName !== undefined
        ? fileName
        : fileId;

    DataHandler.getArrayBuffer({ url: `attachment/${fileId}`}).done((response, status, xhr) => {
        const type = xhr.getResponseHeader("content-type") || "";
        const blob = new Blob([response], { type });

        FileSaver.saveAs(blob, fileName);
    });
}

// TODO: Max size.
async function uploadFiles(
    files: File[], 
    uploadParameters: { [key: string]: (string | number) }, 
    maxSize?: number | undefined
): Promise<UploadResponse> {
    const uploadResponse: UploadResponse = {
        success: false,
        partialSuccess: false,
        failure: false,
        failedFiles: [],
        originalFiles: files,
        maxSizeExceeded: false
    };
   
    const fileMap: { 
        [key: string]: boolean 
    }                       = {};
    const failed: File[]    = []
    const totalSize: number = files.map((f: File) => f.size).reduce(reduceSum, 0);

    if(maxSize !== undefined && totalSize > maxSize) {
        uploadResponse.maxSizeExceeded = true;

        return uploadResponse;
    }

    for(const file of files) {
        fileMap[file.name] = false;

        try {
            await DataHandler.file(uploadParameters, file, undefined);
        } catch(ex) {
            console.error(ex);

            failed.push(file);

            continue;
        }

        fileMap[file.name] = true;
    }

    const successN: number = Object.keys(fileMap)
        .filter((fileName: string) => fileMap[fileName]).length;

    uploadResponse.success        = successN === files.length;
    uploadResponse.partialSuccess = !uploadResponse.success && successN > 0;
    uploadResponse.failure        = !uploadResponse.success && !uploadResponse.partialSuccess;
    uploadResponse.failedFiles    = failed;

    return uploadResponse;
}

export {
    isImageFileType,
    downloadAttachment,
    uploadFiles,
    type UploadResponse
}
