// Material UI
import { DrawerProps, Tooltip } from '@mui/material';
import { ReactComponent as BookkeepingIcon } from '../general/icons/bookkeeping.svg';

// Local components
import TaimerComponent from '../TaimerComponent';
import ListCell from "../list/ListCell";

// Styles
import styles from './AccountingCell.module.scss';

// Other
import isEqual from "lodash/isEqual";

export interface Column {
   field: string;
   name: string;
   type: string;
   header: string;
   optionsName: string;
}

export interface dimensionHeader {
    id?: string; 
    orig_id?: string;
    name?: string;
    label?: string;
    options?: any[];
 }

 export interface dimensionValue {
    header_id: string; 
    dimension_id?: string;
 }

export interface rowData {
    id?: string; 
    efina_product?: string;
    efina_cost_pool?: string;
    accounting_product?: string;
    product_register_product?: string;
    dimension_item?: string;
    sales_account?: string;
    purchase_account?: string;
    dimension_values?: dimensionValue[];
 }

 export interface accountingData {
    purchaseAccounts?: any[];
    dimensions?: any[];
    dimension_headers?: dimensionHeader[];
 }

 export interface tooltipRow {
    column?: string;
    header?: string;
    value?: string;
 }

export interface AccountingCellProps extends DrawerProps {
    rowData: rowData;
    accountingData: accountingData;
    showAccounting?: () => {};
    listCellProps: any[];
}

interface State {
   tooltipRows: tooltipRow[];
}

class AccountingCell extends TaimerComponent<AccountingCellProps, State> {
    columns: Column[];
    constructor(props, context) {
        super(props, context, 'invoices/AccountingCell');

        this.state = {
            tooltipRows: []
        };

        this.columns = [
            ...(props.module == "InvoiceView" ? [{ field: "efina_product", name: "efina_product", type: "sales_invoices", header: this.tr("eFina Product"), optionsName: "efinaProducts" }] : []),
            ...(props.module == "InvoiceView" ? [{ field: "efina_cost_pool", name: "efina_cost_pool", type: "sales_invoices", header: this.tr("eFina Dimension"), optionsName: "efinaCostPools" }] : []),
            { field: "accounting_product", name: "accounting_product", type: "sales_invoices", header: this.tr("Product"), optionsName: "accountingProducts" },
            { field: "product_register_product", name: "product_register_product", type: "sales_invoices", header: this.tr("Product"), optionsName: "productRegisterProducts" },
            { field: "dimension_item", name: "dimension_item", type: "sales_invoices", header: this.tr("Dimension"), optionsName: "dimensions" },
            { field: "sales_account", name: "sales_account", type: "sales_invoices", header: this.tr("Account"), optionsName: "salesAccounts" },
            { field: "purchase_account", name: "purchase_account", type: "purchase_invoices", header: this.tr("Purchase account"), optionsName: "purchaseAccounts" }, 
            { field: "dimension_item", name: "dimension_item", type: "purchase_invoices", header: this.tr("Dimension"), optionsName: "dimensions" },
        ]
        .filter(c => c.type == props.type);
    }

    componentDidMount() {
        super.componentDidMount();
        this.getTooltipRows();
    }

    componentDidUpdate(prevProps: Readonly<AccountingCellProps>): void {
        if (!isEqual(prevProps.rowData, this.props.rowData) || !isEqual(prevProps.accountingData, this.props.accountingData)) {
            this.getTooltipRows();
        }
    }

    getTooltipRows = () => {
        const { rowData, accountingData } = this.props;

        const tooltipRows : tooltipRow[] = [];

        this.columns.forEach(c => {
            let columnData: any = undefined;

            if (c.name == "efina_product" && rowData["efina_product_label"]) {
                columnData = {label: rowData["efina_product_label"]};
            }
            else if (c.name == "efina_cost_pool" && rowData["efina_costpool_label"]) {
                columnData = {label: rowData["efina_costpool_label"]};
            }              

            if (!columnData && Number(rowData[c.name]) > 0 && accountingData) {
                columnData = accountingData[c.optionsName]?.find(a => a.id == rowData[c.name]);
            }

            columnData && tooltipRows.push({ column: c.name, header: c.header, value: columnData?.label });
        });

        const dimensionValues = rowData.dimension_values || [];
        dimensionValues.sort((a, b) => a.header_id.localeCompare(b.header_id));

        if (dimensionValues.length > 0) {
            for(const dv of dimensionValues) {
                if (!Number(dv.dimension_id) || !dv.header_id) {
                    continue;
                }

                const split = (dv.header_id || "").split("_");
                const headerId = split[0];

                const headerData = accountingData?.dimension_headers?.find(h => h.orig_id == headerId);
                const dimensionData = headerData?.options?.find(d => d.id == dv.dimension_id);

				dimensionData && headerData && tooltipRows.push({ column: headerData.name, header: headerData?.label, value: dimensionData?.name });
			}
        }

        this.setState({ tooltipRows });
    }

    render() {
        const { showAccounting, listCellProps } = this.props;
        const { tooltipRows } = this.state;
        const TOOLTIP_ROW_AMOUNT = 3;

        const tooltipShownRows = tooltipRows.slice(0, TOOLTIP_ROW_AMOUNT);
        const restRowsCount = tooltipRows.length - tooltipShownRows.length;

        const tooltip = {
			classes: { tooltip: 'darkblue-tooltip' },
			interactive: true,
			arrow: true,
			title:
				<div className={styles.accountingCellTooltip}>
                    {!tooltipShownRows.length
                        ? <p data-testid="no_data_tooltip_row">{this.tr("No accounting data")}</p>
                        : tooltipShownRows.map((r, i) => {
                            return (<p data-testid={`tooltip_row_${r.column}`} key={`tooltip_row_${i}`}>{r.header + ": " + r.value}</p>);
                        })
                    }
                    {restRowsCount > 0 && <p>{"+ " + restRowsCount + " more"}</p>} 
					<button data-testid="tooltip_show_accounting_button" onClick={showAccounting && showAccounting}>{this.tr('Show accounting')}</button>
				</div>
		}

        return (
            <ListCell onlyDisplay={true} inEditMode={false} noBorder={true} {...listCellProps}>
                <Tooltip {...tooltip} placement="right">
                    <div onClick={showAccounting && showAccounting} className={styles.accountingIconContainer}>
                        <BookkeepingIcon className={tooltipRows.length > 0 ? styles.active : ""} />
                    </div>
                </Tooltip>
            </ListCell>
        )
    }

}

export default AccountingCell;