import React from 'react';

/* css */
import './OverviewActivities.css';

/* material ui */

/* others */
import _ from 'lodash';
import { format } from "date-fns";
import DataHandler from '../../general/DataHandler';
import ContentBlock from '../../general/ContentBlock';
import TaimerComponent from '../../TaimerComponent';
import { DateRangePicker } from './../../general/react-date-range/src';
import { SettingsContext } from '../../SettingsContext';
import { getActivityTypes } from '../../Data';
import ActivitiesInsightBox from '../insights/activities/ActivitiesInsightBox';
import ActivitiesInsightList from '../insights/activities/ActivitiesInsightList';
import { ReactComponent as ActivitiesGraphic } from '../images/ActivitiesGraphic.svg';

class Activities extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context){
        super(props, context, "accounts/tabbasic/overview/activities");
        const dateRange = {start: null, end: null, key: "selection"};
        this.state = {
            showOverlay: false,
            dateRange: {
                startDate: dateRange.start,
                endDate: dateRange.end,
                valid: true,
                key: "selection"
            },
            filterData: {

            },
            pageData: {
                page: 1,
                perpage: 30,
                pageCount: 1,
                activitiesCount: 0,
                sortby: "created",
                sortasc: false,
            },
            boxdata: {
                total: 0,
                done: 0,
                due: 0,
                overdue: 0,
            },
            datasets: [
                //Done
                {
                    borderColor: '#4FE3C2',
                    pointBackgroundColor: "#4FE3C2",
                    type: 'bar',
                    yAxisID: 'y-axis-1',
                    borderDash: [5, 3],
                    borderWidth: 2,
                    backgroundColor: "#4FE3C2"
                },
                //Due
                {
                    borderColor: '#FBB824',
                    pointBackgroundColor: "#FBB824",
                    type: 'bar',
                    yAxisID: 'y-axis-1',
                    borderDash: [5, 3],
                    borderWidth: 2,
                    backgroundColor: "#FBB824"
                },
                //Overdue
                {
                    borderColor: '#F7273D',
                    pointBackgroundColor: "#F7273D",
                    type: 'bar',
                    yAxisID: 'y-axis-1',
                    borderDash: [5, 3],
                    borderWidth: 2,
                    backgroundColor: "#F7273D"
                }
            ],
            dialogOpenEdit: false,
            dialogData: {},
        };

        this.ActivitiesInsightList = React.createRef();
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.getActivityData({account: [this.props.customersId]});
        window.addEventListener('activitySaved', this.refreshData);
    }

    componentWillUnmount = () => {
        super.componentWillUnmount();
        window.removeEventListener('activitySaved', this.refreshData);
    }

    refreshData = () => {
        this.getActivityData({account: [this.props.customersId]});
    }

    componentDidUpdate = (prevProps) => {
        (prevProps.company != this.props.company || prevProps.customersId != this.props.customersId) && this.getActivityData({account: [this.props.customersId]});
    }

    onDateInputChange = (dateType, date) => {


        const { filterData } = this.state;
        const { startDate, endDate } = this.state.dateRange;
        date = format(date, "YYYY-MM-DD");

        if (dateType == "start") {
            filterData.startDate = date;
            this.setState({
                dateRange: {
                    startDate: date,
                    endDate: endDate,
                    key: "selection"
                }
            }, () => this.onFilterChange(filterData));
        } else {
            filterData.endDate = date;
            this.setState({
                dateRange: {
                    startDate: startDate,
                    endDate: date,
                    key: "selection"
                }
            }, () => this.onFilterChange(filterData));
        }
    };

    onDateChange = (event) => {
        let { startDate, endDate } = event.selection;
        let { filterData } = this.state;

        startDate = format(startDate, "YYYY-MM-DD");
        endDate = format(endDate, "YYYY-MM-DD");
        filterData = {...filterData, startDate, endDate};

        this.setState({
            filterData: filterData, 
            dateRange: {
                startDate: startDate,
                endDate: endDate,
                key: "selection"
            }
        }, () => this.onFilterChange(filterData));

    };

    onFilterChange = (filterData) => {
        const pageData = this.state.pageData;
        pageData.page = 1;
        this.setState({ pageData: pageData });
        this.getActivityData(filterData);
    }

    //Filtercomponents those are passed to "ContentBlock"
    filterComponents = () => {
        const { userObject } = this.context;
        const { dateRange } = this.state;

        return(
            <DateRangePicker
                className="daterange"
                ranges={[dateRange]}
                onChange={this.onDateChange}
                onInputChange={this.onDateInputChange}
                label={this.tr("Time period")}
                dateFormat={userObject.dateFormat}
            />
        );
    }

    overleyComponents = () => {
        const { privileges } = this.context;

        return (
            <React.Fragment>
                <ActivitiesGraphic />
                {   
                    (privileges.customers && privileges.customers.customer_crm) || (privileges.projects && privileges.projects.write) ? <p>{this.tr("Nothing to do ?")} <a onClick={this.onActivityDialog} >{this.tr("Add activity")}</a></p>
                    : <p>{this.tr("There are no activities")}</p>
                }
            </React.Fragment>
        );
        
    }

    //Gets activity data with filterData and pageData
    async getActivityData(filterData = null) {

        //If filterData is not passed, lets take it from the state
        if(filterData && filterData != null) this.setState({filterData: filterData});
        else filterData = this.state.filterData;

        filterData.company = this.props.company;

        const activityTypes = await getActivityTypes(this.props.company ? this.props.company : this.context.userObject.companies_id);
        const cleanActivityTypes = [{id: "0", name: this.tr("All")}];

        //Removes excess information from activitytypes 
        activityTypes.forEach((item) => {
            cleanActivityTypes.push({id: item.id, name: item.name});
        });

        this.setState({cleanActivityTypes: cleanActivityTypes});

        DataHandler.post({url: `activities/activities`}, {...filterData, ...this.state.pageData}).done(response => {

            //Icon data to listrows
            if(response && response.listActivities) {
                const newactivities = response.listActivities;
                newactivities.map((row, i) => {
                    let newitem = { icon: "" };
                    try {
                        activityTypes.map((item, i) => {
                            if(item.id === row.type_id) {
                                newitem = {icon: item.icon};
                            }
                        })
                    } catch(err) {
                        newitem = { icon: "" };
                    }
                    const newrow = {...row, ...newitem, key: row.projects_id ? "p" + row.id : "c" + row.id};
                    newactivities[i] = newrow;
                })
                this.setState({ activities: newactivities });
            }

            //Activities count information
            if(response && (response.activitiesCount || response.activitiesCount === 0)) {
                const pageData = this.state.pageData;
                pageData.activitiesCount = response.activitiesCount;
                this.setState({ pageData: pageData });
            }

            //Lists page count information
            if(response && response.pageCount) {
                const pageData = this.state.pageData;
                pageData.pageCount = response.pageCount;
                this.setState({ pageData: pageData });
            }

            //Overlay show state
            if(response && response.showoverlay) {
                this.setState({showOverlay: response.showoverlay});
            }

            //Sets boxes number information (total, done, due, overdue)
            if(response && response.boxdata) {
                this.setState({boxdata: response.boxdata});
            }

        }).fail(response => {});

    }

    //Opens activity dialog
    onActivityDialog = () => {
        this.context.functions.openActivitySlider({
            customers_id: this.props.customersId
        }, { afterEdit: this.onCloseActivityDialog })
        
    }

    //Closes activity dialog
    onCloseActivityDialog = () => {
        this.getActivityData({account: [this.props.customersId]});
    }

    render() {
        const { pageData } = this.state;
        
        return (
            <ContentBlock
                buttonTitle={ this.tr("Activities Overview") } 
                headerId="overview-activities-header"
                headerClass=""
                wrapperId="insights-activities-list-main"
                wrapperClass="activitieslistwrapper"
                rootClassName="content-block"
                filterComponents={ this.filterComponents() }
                showOverlay={ this.state.showOverlay } 
                overlay={ this.overleyComponents() } >
                <ActivitiesInsightBox
                    id="#overview-activities-header"
                    classNameBoxHeader="box-header-accountcard"
                    classNameBox="account-card-activities-box"
                    boxdata = { this.state.boxdata } />
            </ContentBlock>
        )
    }
}

export default Activities;