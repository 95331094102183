import React from "react";

/* others */
import _ from "lodash";
import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from "../../../SettingsContext";
import OutlinedField from "../../../general/OutlinedField";
import { MenuItem } from "@mui/material";

class InsightSelect extends TaimerComponent {
  static contextType = SettingsContext;

  static defaultProps = {
    width: 3,
    visible: true,
  };

  constructor(props, context) {
    super(props, context, "dashboard/insights/filters/InsightSelect");

    this.state = {
      reset: false,
    };
  }

  change = (e) => {
    const { name, onChange } = this.props;
    onChange(name, e.target.value);
  };

  render() {
    const { label, name, value, options } = this.props;

    return (
      <OutlinedField
        select
        className="listFilterOutlinedField"
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          },
        }}
        label={label}
        name={name}
        value={value}
        onChange={this.change}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          );
        })}
      </OutlinedField>
    );
  }
}

export default InsightSelect;
