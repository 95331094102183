import getColumnType, { ColumnDefinition, columnSorter } from './ColumnTypes';
import getProjectColumns from './ProjectColumns';
import getAccountColumns from './AccountColumns';
import { CustomFields } from './CustomFields';
import { filterColumns } from "./ColumnVisibility";

const columnOrder = [
    'project',
    'material_date_month',
    'invoice_invoiced',
];

interface Params {
    customFields: CustomFields;
    tr: (text, replacers?: Record<string, string>) => string;
    startDate: Date | null;
    endDate: Date | null;
    dateFormat: string;
    settingsContext: any;
}

export default function getForecastColumns(params: Params) {

    const columns: ColumnDefinition[] = [    
        {
            field: 'project_company',
            headerName: 'Project company',
            ...getColumnType('text'),
            defaultAggFunc: 'sumAndGroup',
        },
        {
            field: 'project_startdate',
            headerName: 'Project start date',
            ...getColumnType('date', null, 'project_startdate'),
        },
        {
            field: 'project_enddate',
            headerName: 'Project end date',
            ...getColumnType('date', null, 'project_enddate'),
        },
        {
            field: 'material_date_month',
            headerName: 'Month',
            ...getColumnType('text'),
        },
        {
            field: 'material_date_quarter',
            headerName: 'Quarter',
            ...getColumnType('text'),
        },
        {
            field: 'material_date_year',
            headerName: 'Year',
            ...getColumnType('text'),
        },
        {
            field: 'material_date_fy',
            headerName: 'Financial Year',
            ...getColumnType('number'),
            decimalAmount: 0,
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'group'
        },
        {
            field: 'invoice_invoiced',
            headerName: 'Invoicing',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'revenue_recognition_recognised',
            headerName: 'Recognition',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',        
        },
        {
            field: 'sales_costs',
            headerName: 'Costs (excl. hours)',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'gross_costs',
            headerName: 'Costs (incl. hours)',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'gross_margin_invoicing',
            headerName: 'Project margin (invoicing)',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'gross_margin_invoicing_percentage',
            headerName: 'Project margin % (invoicing)',
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'gross_margin_invoicing', divisor: 'invoice_invoiced' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum' // So shows formatted value correctly in grouped row. By default percentage has "grouped" and the the value is not formatted.
        },
        {
            field: 'sales_margin_invoicing',
            headerName: 'Sales margin (invoicing)',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'sales_margin_invoicing_percentage',
            headerName: 'Sales margin % (invoicing)',
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'sales_margin_invoicing', divisor: 'invoice_invoiced' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum' // So shows formatted value correctly in grouped row. By default percentage has "grouped" and the the value is not formatted.
        },
        {
            field: 'gross_margin_revenue',
            headerName: 'Project margin (revenue recognition)',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'gross_margin_revenue_percentage',
            headerName: 'Project margin % (revenue recognition)',
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'gross_margin_revenue', divisor: 'revenue_recognition_recognised' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum' // So shows formatted value correctly in grouped row. By default percentage has "grouped" and the the value is not formatted.
        },
        {
            field: 'sales_margin_revenue',
            headerName: 'Sales margin (revenue recognition)',
            ...getColumnType('currency'),
            grandTotal: 'sum',
            defaultAggFunc: 'sum',
        },
        {
            field: 'sales_margin_revenue_percentage',
            headerName: 'Sales margin % (revenue recognition)',
            ...getColumnType('percentage'),
            calculateGroupedRate: { dividend: 'sales_margin_revenue', divisor: 'revenue_recognition_recognised' },
            defaultAggFunc: 'sumAndGroup',
            sumAndGroupType: 'sum' // So shows formatted value correctly in grouped row. By default percentage has "grouped" and the the value is not formatted.
        },
        ...getProjectColumns(params),
        ...getAccountColumns(params),
    ];

    columns.sort(columnSorter(columnOrder));
    
    return filterColumns(
        columns, 
        "forecast", 
        params.settingsContext
    );
}
