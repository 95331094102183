import React from 'react';
import classNames from 'classnames';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from './../../SettingsContext';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './../../general/react-date-range/src/styles.css';
import './../../general/react-date-range/src/theme/default.css';

const styles = theme => ({
    paper: {
        width: '360px'
    }
});

class ConfirmationDialog extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/dialogs/ConfirmationDialog");

    }

    handleCancel = () => {
        this.props.onDialogClose();
    }

    handleOk = () => {
        const { saveFunc, id } = this.props.data;   
        this.props.onDialogSave(saveFunc, id);
    }

    handleYes = () => {
        const { yesFunc, id } = this.props.data;   
        this.props.onDialogYes(yesFunc, id);
    }

    handleNo = () => {
        const { noFunc, id } = this.props.data;   
        this.props.onDialogNo(noFunc, id);
    }

    render() {
        const { tr } = this;
        const { data, classes, yesNo } = this.props,
            { text, cancelText, okText, noText, yesText, hasXCloseButton } = data,
            { taimerAccount } = this.context;

        const maxWidth = data.maxWidth || "xs";        
        const TextIcon = this.props.data.textIcon || undefined;
        return (
            <Dialog
                open
                className="invoiceDialog"
                maxWidth={maxWidth}
                classes={{paper: classNames(classes.paper)}} 
                aria-labelledby="markAsPaidSingle" >
                {hasXCloseButton && <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={this.handleCancel}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }} >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>}
                <DialogContent className={`invoiceDialogContent ${TextIcon ? 'settings-dialogs-confirmation-content-withicon' : ''}`} ref={cont => this.container = cont}>
                    {TextIcon && <TextIcon />}
                    <p style={{textAlign: 'center'}} >{text}</p>
                    {
                        this.props.children ? this.props.children : undefined   
                    }
                </DialogContent>
                <DialogActions>
                    {!data.noCancelButton && <Button size="large" variant="outlined" onClick={this.handleCancel} color="info">
                        {cancelText || tr("Cancel")}
                    </Button>}
                    {
                        yesNo ? (
                            <React.Fragment>
                                <Button size="large" variant="outlined" onClick={this.handleNo} color="info">
                                    {noText || tr("No")}
                                </Button>
                                <Button size="large" variant="outlined" onClick={this.handleYes} color="primary">
                                    {yesText || tr("Yes")}
                                </Button>
                            </React.Fragment>
                        )
                        : (                            
                            <Button size="large" variant="outlined" onClick={this.handleOk} color="primary">
                                {okText || tr("Ok")}
                            </Button>
                        )

                    }                    
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmationDialog.defaultProps = {
    onDialogClose: () => {},
    onDialogSave: () => {}
}

export default withStyles(styles)(ConfirmationDialog);
