import React from "react";
import TextField from "@mui/material/TextField";
import Popper from '@mui/material/Popper';
import { Column, Table } from "react-virtualized";
import Tooltip from '@mui/material/Tooltip';
import "./InfoSelect.css";

/**
 * InfoSelect - Shows objects information in dropdown table view.
 * Please ask the author for help if you need to do changes to this file.
 *
 */
class InfoSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            options: this.props.options,
            search: "",
            typingTimeout: 0,
        };

        this.is_table = React.createRef();
    }

    /**
     * Returns given columns
     *
     */
    renderColumns = (columns) => {
        if (columns && columns.length > 0) {
            return columns.map((c, index) => <Column label={c.header} dataKey={c.name} width={c.width} />);
        } else {
            return undefined;
        }
    };

    /**
     * Opens dropdown (table) view when textfield is selected
     *
     */
    onFocus = (e) => {
        this.setState({ anchorEl: e.currentTarget, open: true });
    };

    /**
     * Closes dropdown (table) when component is no more in focus
     *
     */
    onBlur = (e) => {
        const target = e.relatedTarget;
        const parent = e.currentTarget;

        if (target && (target.className == "ReactVirtualized__Table__row" || target.className == "ReactVirtualized__Grid ReactVirtualized__Table__Grid"))
            return;

        if (!this.contains(parent, target)) {
            this.setState({ open: false });
        }
    };

    /**
     * Function for onBlur()
     *
     */
    contains = (parent, child) => {
        if (!child || !child.parentElement) return false;
        if (child.parentElement === parent) return true;

        return this.contains(parent, child.parentElement);
    };

    /**
     * Returns rowdata of selected row
     *
     */
    onRowClick = ({ rowData }) => {
        this.setState({ open: false });
        if (this.props.onChange) this.props.onChange(rowData);
    };

    /**
     * When textfield value is changed
     *
     */
    onChangeSearch = (value) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            search: value,
            typingTimeout: setTimeout(() => {
                this.onSearch(this.state.search);
            }, 1000),
        });
    };

    /**
     * Function for onChangeSearch()
     *
     */
    onSearch = (text) => {
        const { options, columns } = this.props;
        const columnNames = [];

        columns.forEach((column) => {
            columnNames.push(column.name);
        });

        if (typeof text === "string" || text instanceof String) {
            if (text === "") {
                this.setState({ options });
                return;
            }

            const searchText = text.toLowerCase();
            const newOptions = [];
            let contains = false;
            let val = "";

            options.forEach((option) => {
                contains = false;
                Object.keys(option).forEach((item) => {
                    if (!contains && columnNames.includes(item)) {
                        val = (option[item] + "").toLowerCase();
                        if (val.includes(searchText)) contains = true;
                    }
                });

                if (contains) newOptions.push(option);
            });

            this.setState({ options: newOptions, search: text });
        }
    };

    /**
     * Calculates table height for <Table />
     *
     */
    getTableHeight = (height, options, rowHeight, headerHeight, editable) => {
        if (!editable || !options || !options.length) {
            return rowHeight;
        } else if (height > options.length * rowHeight) {
            return options.length * rowHeight + headerHeight - 1;
        }

        return height;
    };

    getTableClassName = (height, options, rowHeight, editable, open) => {
        if (!editable || !options || !options.length || height > options.length * rowHeight) {
            return "InfoSelect__is_table InfoSelect__hide_overflow";
        }

        return "InfoSelect__is_table";
    };

    getStyle = (width, useFlex, zeroBasis) => {
        return ({ 
            width: width + "px", 
            flex: !zeroBasis ? width + " 1 0" : undefined,
            flexGrow: useFlex ? width : undefined,
            flexShrink: useFlex ? 1 : undefined,
            flexBasis: useFlex && zeroBasis ? "0" : undefined,
            ...this.props.style 
        });
    }

    getRootClassName = (className, open, inEditMode) => {
        let text = "";
        text += "InfoSelect";
        text += className ? " " + className : "";
        text += open ? " is_focus" : " is_blur";
        text += inEditMode ? "" : " is_not_editmode";
        return text;
    }

    render() {
        const { columns, rowHeight, headerHeight, tableWidth, height, value, editable, className, width, useFlex = true, zeroBasis, placeholder, inEditMode, disabled } = this.props;
        const { open, search } = this.state;
        const val = open ? search : value;
        const options = (search == "") ? this.props.options : this.state.options;
        const tableClassName = this.getTableClassName(height, options, rowHeight, editable);
        const TableHeight = this.getTableHeight(height, options, rowHeight, headerHeight, editable);
        const style = this.getStyle(width, useFlex, zeroBasis);
        const rootClassName = this.getRootClassName(className, open, inEditMode);

        options?.map(o => {
            o['data-testid'] = 'infoselect_option_' + o.name;
            return o;
        })
        return (
            <div className={rootClassName} onFocus={this.onFocus} onBlur={this.onBlur} style={style}>
                <div className="InfoSelect__is_textfield_div">
                    {editable ? (
                        <TextField
                            data-testid={"infoselect_" + this.props.name}
                            ref="is_textfield"
                            variant="filled"
                            className="is_textfield"
                            onChange={(event) => this.onChangeSearch(event.target.value)}
                            value={val}
                            autoFocus={this.props.autoFocus}
                            placeholder={placeholder}
                            disabled={disabled}
                        />
                    ) : (
                        <Tooltip title={val} placement="right">
                            <div className="is_div" onFocus={this.onFocus}>{val}</div>
                        </Tooltip>
                    )}
                </div>
                {((open && editable)) ? (
                    <Popper anchorEl={this.state.anchorEl} keepMounted placement="bottom-start" open={open}>
                        {Array.isArray(options) && options.length > 0 ? (
                        <Table
                            ref="is_table"
                            className={tableClassName}
                            width={tableWidth}
                            height={TableHeight}
                            headerHeight={headerHeight}
                            rowHeight={rowHeight}
                            rowCount={options && options.length ? options.length : 0}
                            onRowClick={this.onRowClick}
                            rowGetter={({ index }) => options[index]}
                            onRowsRendered={(e) => {
                                /* console.log(e.overscanStopIndex, e.stopIndex) */
                            }}
                        >
                            {this.renderColumns(columns)}
                        </Table> 
                        ) : ( this.props.noOptionsMessage ? (
                            <p className="InfoSelect__no-options">{this.props.noOptionsMessage}</p>
                        ) : undefined)}
                    </Popper>
                ) :  undefined}
            </div>
        );
    }
}

export default InfoSelect;
