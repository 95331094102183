import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import Button from '@mui/material/Button';
import SettingsGrid from './SettingsGrid';
import "../Settings.css";
import OneColumnSettingList from "../../list/lists/OneColumnSettingList";
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import { Switch } from '@mui/material';
import Loading from "../img/loading.svg";

import StatusTag from '../../general/StatusTag';
import VersionContentManager from '../../general/VersionContentManager';
class Contact extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/pages/Contact");

        this.langList = React.createRef();
        this.positionList = React.createRef();
        this.contactRelationOptionList = React.createRef();
        this.titleList = React.createRef();

        this.state = {
            data: {
                positions: [],
                contactRelationOptions: [],
                langs: [],
                titles: [],
            },
            initialized:false,
            contactSettings: [],
        };
        this.dialogs = {
            confirmation: ConfirmationDialog
        };

        this.setStateData = this.setStateData.bind(this);
        this.initData = this.initData.bind(this);
        this.fetchPositions = this.fetchPositions.bind(this);
        this.fetchTitles = this.fetchTitles.bind(this);
        this.updateContactRelationOption = this.updateContactRelationOption.bind(this);

        this.initData();
    }

    setStateData(key, data) {
        const stateData = this.state.data;
        stateData[key] = data;
        this.setState({ data: stateData });
    }
    onChange = (data) => {
        this.setState({ data });
    }

    initData() {
        this.fetchPositions();
        this.fetchTitles();
        this.fetchCompanyLangs();
        this.updateComponentData();
    }

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    }

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
    }

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    }
    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.initData();
    }

    updateComponentData = async () => {
        const { company } = this.props;
        const contactSettings = await DataHandler.get({ url: `settings/company/${company}/contact_settings`});
        const additional = await DataHandler.get({ url: `settings/company/${company}/contact_relation_option` });
        this.setState({
            contactSettings: contactSettings, additional, initialized:true
        }, this.updateContactRelationOption);
    }

    updateContactRelationOption = () => {
        const { additional: { contact_relation_default_option } } = this.state;

        this.state.data.contactRelationOptions = [
            {
                id: 1,
                name: this.tr("Constrained to the corporation tree"),
                default: false
            }, {
                id: 2,
                name: this.tr("Can be added to any customer"),
                default: false
            }
        ];

        const crdop = this.state.data.contactRelationOptions.find(x => x.id == contact_relation_default_option);

        if (crdop) crdop.default = true;
        this.setStateData(this.state.data.contactRelationOptions);
    }

    fetchPositions = () => {
        DataHandler.get({ url: `settings/contacts/positions/${this.props.company}` }).done(data =>
            this.setStateData("positions", data)
        );
        
    }

    fetchTitles = () => {
        DataHandler.get({ url: `settings/contacts/titles/${this.props.company}` }).done(data =>
            this.setStateData("titles", data)
        );
        
    }

    fetchCompanyLangs = async () => {
        const { company } = this.props;
        const response = await DataHandler.get({ url: `settings/company/${company}/langs` });
        this.setStateData("langs", response);
    }

    changeCreationSettings = (settings, setting, value) => {
        const ns = settings;
        ns[setting] = value;
        this.setState({contactSettings: ns}, () => {
            const params = {setting: setting, setting_value: value};
            DataHandler.post({ url: `settings/company/${this.props.company}/contact_settings`, ...params});
        })
    }

    render() {
        const { tr } = this;
        const { company } = this.props;
        const { taimerAccount: { isMulticompany } } = this.context;
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        const loaderStyle = {
            width: 60,
            height: 60,
            padding: 20,
          };

        const contactSettings = [  
            {
                type: "check",
                name: "contacts_force_email",
                label: tr("Email mandatory when adding or editing contact"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "contacts_force_position",
                label: tr("Position mandatory when adding or editing contact"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "contacts_force_title",
                label: tr("Title mandatory when adding or editing contact"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "contacts_force_language",
                label: tr("Language mandatory when adding or editing contact"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "contacts_force_phone",
                label: tr("Phone mandatory when adding or editing contact"),
                className: "respSetting half",
            },
            {
                type: "check",
                name: "contacts_force_owner",
                label: tr("Contact owner mandatory when adding or editing contact"),
                className: "respSetting half",
            },
        ];
        return (
            <div id="settings-feature-contact">
           
                {!this.state.initialized && 
                    <div> 
                        <img alt="loading" style={loaderStyle} src={Loading.default}/>
                    </div>
                }
                {this.state.initialized && 
                    <React.Fragment>
                        <div className="settings-feature-firstwrapper">

                            <h3>{this.tr("Positions")}
                                {isMulticompany ? <StatusTag
                                        style={{ marginLeft: 16, display: "inline", padding: "5px 12px" }}
                                        color={"#ffaf0f"}
                                        text={this.tr("Multi-Company")}
                                    /> : ''
                                }
                            </h3>
                            <p style={{ fontSize: "11px" }}>{tr("Positions text.")} {isMulticompany ? tr("(shared with corporation)") : ""}</p>
                            <Button data-testid="add-position-button" className="green" onMouseUp={() => this.positionList.current.addNewRow()} size="large">{tr("ADD POSITION")}</Button>
                            <OneColumnSettingList
                                id="positionList"
                                key={this.state.data.positions.length}
                                ref={this.positionList}
                                company={company}
                                height="auto"
                                headerTitle={this.tr("Position")}
                                rowProps={{
                                    onCreate: data => {

                                        let error = false;
                                        this.state.data.positions.forEach(p => {
                                            if(p.id != data.id && p.name.toLowerCase() === data.name.toLowerCase())
                                                error = true
                                        })
                                        if(data.name == ""){
                                            this.props.enqueueSnackbar((tr("Enter position") + "."), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        if (!error) {
                                            return DataHandler.post({url: `settings/contacts/positions/${company}`}, {name:data.name});
                                        }
                                        else{
                                            this.props.enqueueSnackbar(( data.name + tr(" Position already exists") + "."), {
                                                variant: "error",
                                            });
                                            return;

                                        }
                                    },
                                    onUpdate: data => DataHandler.put({url: `settings/contacts/positions/${company}`}, {name: data.name, id: data.id }),
                                    onDelete: (data, _, list) => {
                                        if (Number(data.id) < 0) {
                                            list.removeRow(data.id);
                                            return;
                                        }
                                        this.setState({
                                            dialogData: {
                                                id: data.id,
                                                saveFunc: () => DataHandler.delete({ url: `settings/contacts/positions/${data.id}`}).done(() => {
                                                    list.removeRow(data.id);
                                                }),
                                                text: tr("Do you want to delete the position") + ": " + data.name + "?"
                                            }
                                        });
                                        this.openDialog('confirmation');
                                    }
                                }}
                                data={this.state.data.positions} />
                        </div>
                        <div className="settings-feature-firstwrapper">
                            <h3>{this.tr("Contacts mandatory fields settings")}</h3>
                            <p style={{ fontSize: "11px" }}>{tr("Contact adding definitions and Account linking rules")}</p>
                            <SettingsGrid settings={contactSettings} item={this.state.contactSettings} onChange={this.changeCreationSettings} />
                            
                        </div>

                        {/* Contacts/Partners settings */}

                        {!VersionContentManager.isFeatureHidden(this.namespace, 'relations') && <div className="settings-feature-lastwrapper">
                            <h3>{tr("Contact relation settings")}</h3>
                            <p className="subtitle">{tr("Controls whether an existing contact can be added to any customer, or only to those customers that exist in the original customer's corporation tree.")}</p>

                            <span>{this.tr("Restrict contact relations to corporation tree")}</span> 
                            <Switch 
                                color="primary" 
                                checked={this.state.additional ? String(this.state.additional.contact_relation_default_option) === "1" : false} 
                                onChange={(_, checked) => {
                                    DataHandler.post({ url: `settings/company/${company}/contact_relation_option` }, {
                                        contact_relation_default_option: checked === true ? "1" : "2"
                                    }).done(response => {
                                        this.setState({
                                            additional: { contact_relation_default_option: response.contact_relation_default_option }
                                        });
                                    });
                                }}
                            />
                        </div>}
                    

                        {Dialog && <Dialog
                            open
                            company={company}
                            onDialogClose={this.closeDialog}
                            onDialogSave={this.confirmDialog}
                            data={this.state.dialogData}

                        />}
                    </React.Fragment>
                    }
            </div>
    
                        
                  
        );

    }
}

export default Contact;
