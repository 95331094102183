import React from 'react';
import FileSaver from 'file-saver';
import { withSnackbar } from 'notistack';

/* mui */
import { Button, MenuItem, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningIcon from "@mui/icons-material/Warning";
import { CloudUpload } from '@mui/icons-material';

/* mui icons */
import { ChevronLeft } from '@mui/icons-material';

/* local components */
import OutlinedField from '../../general/OutlinedField';
import ProjectTreeDropdown from "../../projects/ProjectTreeDropdown";
import SettingsGrid from '../pages/SettingsGrid';

/* data backend */
import DataHandler from '../../general/DataHandler';

/* context */
import { SettingsContext } from '../../SettingsContext';

/* styles */
import './Procountor.css';
import TaimerComponent from '../../TaimerComponent';

import { format } from "date-fns";
class VatCodeDialog extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/api-settings/Procountor");

        this.state = {
            currentStatus: 0
        }
    }

    componentDidMount = () => {
        this.setState({currentStatus: this.props.currentStatus})
    }

    handleCancel = () => {
        this.props.onClose();
    }

    handleSave = async () => {
        this.props.vatCodeSelected && this.props.vatCodeSelected(this.state.currentStatus);
        this.props.onClose && this.props.onClose();
    }

    render() {
        const { vatCodeList } = this.props;
        const { currentStatus } = this.state;

        return (
            <Dialog
                open
                className="invoiceDialog"
                maxWidth="md"
                onClose={this.handleCancel}
                aria-labelledby="vatCodeList" >
                <div style={{backgroundColor: '#2D9FF7', height: '8px', borderRadius: '4px 4px 0 0', marginBottom: '10px'}}/> 
                <DialogContent className="invoiceDialogContent">
                    <div>{this.tr("Select Procountor VAT status for reverse VAT invoice")}</div>
                    <OutlinedField 
                        fullWidth
                        select 
                        label={this.tr("VAT statuses")}
                        className="listFilterOutlinedField" 
                        value={currentStatus}>
                        {
                            vatCodeList.map((opt) =>
                                <MenuItem value={opt.vatStatus} key={opt.vatStatus} onClick={() => this.setState({currentStatus: opt.vatStatus})}>
                                    {opt.description}
                                </MenuItem>
                            )
                        }
                    </OutlinedField>
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="outlined" onClick={this.handleCancel} >
                        {this.tr('Cancel')}
                    </Button>
                    <Button size="large" variant="outlined" onClick={this.handleSave} color="primary">
                        {this.tr('Select VAT status')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

class DimensionDialog extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/api-settings/Procountor");

        this.state = {
            currentDimension: 0
        }
    }

    componentDidMount = () => {
        this.setState({currentDimension: this.props.currentDimension})
    }

    handleCancel = () => {
        this.props.onClose();
    }

    handleSave = async () => {
        this.props.dimensionSelected && this.props.dimensionSelected(this.state.currentDimension);
        this.props.onClose && this.props.onClose();
    }

    render() {
        const { dimensionList } = this.props;
        const { currentDimension } = this.state;

        return (
            <Dialog
                open
                className="invoiceDialog"
                maxWidth="md"
                onClose={this.handleCancel}
                aria-labelledby="dimensionList" >
                <div style={{backgroundColor: '#2D9FF7', height: '8px', borderRadius: '4px 4px 0 0', marginBottom: '10px'}}/> 
                <DialogContent className="invoiceDialogContent">
                    <div>{this.tr("Select Procountor dimension for project targeting")}</div>
                    <OutlinedField 
                        fullWidth
                        select 
                        label={this.tr("Dimensions")}
                        className="listFilterOutlinedField" 
                        value={currentDimension}>
                        {
                            dimensionList.map((opt) =>
                                <MenuItem value={opt.id} key={opt.id} onClick={() => this.setState({currentDimension: opt.id})}>
                                    {opt.name}
                                </MenuItem>
                            )
                        }
                    </OutlinedField>
                </DialogContent>
                <DialogActions>
                    <Button size="large" variant="outlined" onClick={this.handleCancel} >
                        {this.tr('Cancel')}
                    </Button>
                    <Button size="large" variant="outlined" onClick={this.handleSave} color="primary">
                        {this.tr('Select dimensions')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
class Procountor extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/api-settings/Procountor");

        this.project_upload_success_total = 0;

        this.state = {
            dialogOpen: false,
            vacation_project_id: 0,
            sick_leave_project_id: 0,
            child_sick_leave_project_id: 0,
            username: "",
            active: "-1",
            use_maventa_targeting: 0,
            dialog: undefined,
            dialogProps: undefined,
            reverse_vat_status: 0,
            accountingAccounts: [],
            sendingProjects: false,
            allProjectsAmount: 0,
            sentProjectsAmount: 0
        }

        this.invoiceTypes = [
            {value: 0, label: this.tr("No invoices")},
            {value: 1, label: this.tr("Verified invoices")},
            {value: 2, label: this.tr("Approved invoices")},
            {value: 3, label: this.tr("All invoices")},
        ];

        this.projectDimensionFormats = [
            {value: 0, label: this.tr("Project number and Project name")},
            {value: 1, label: this.tr("Only project number")},
            {value: 2, label: this.tr("Only project name")},
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.getProcountorStatus();
        this.getProjects();
        this.getFetchedAccounts();
    }

    getProcountorStatus = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/procountor/status`}).done(response => {
            this.setState({
                coaUpdatedDate: response.coaUpdatedDate,
                dimensionsUpdatedDate: response.dimensionsUpdatedDate,
                productsUpdatedDate: response.productsUpdatedDate,
                purchase_invoice_type: response.purchase_invoice_type,
                expenseProductsUpdatedDate: response.expenseProductsUpdatedDate,
                active: response.activated,
                username: response.username,
                reverse_vat_status: response.reverse_vat_status,
                default_accounting_accounts_id: response.default_accounting_accounts_id,
                default_purchase_accounts_id: response.default_purchase_accounts_id,
                use_maventa_targeting: response.use_maventa_targeting,
                project_dimension: response.project_dimension,
                send_invoices_directly_to_customers: response.send_invoices_directly_to_customers || 0,
                use_parent_project_dimension_for_subprojects: response.use_parent_project_dimension_for_subprojects || 0,
                add_project_dimension_automatically: response.add_project_dimension_automatically || 0,
                project_dimension_format: response.project_dimension_format || 0,
                create_project_dimensions: response.create_project_dimensions || 0,
                allProjectsAmount: response.allProjectsAmount || 0,
                sentProjectsAmount: response.sentProjectsAmount || 0,
            });
        });
    }

    dialogOpen = () => {
        const open = this.state.dialogOpen;
        this.setState({dialogOpen: !open});
    }

    back = () => {
        const {functions: { updateView } } = this.context;
        updateView({module: 'settings', action: 'index', group: 'integrations', page: 'default', subpage: ''})
    }

    procountorAuth = async () => {
        await DataHandler.get({url: `settings/company/${this.props.company}/procountor/link`}).done(data => {
            console.log(data.url);
            sessionStorage["nextAction"] = "procountor-redirect";
            window.location = data.url;
        });

    }

    deactivate = () => {
        DataHandler.post({url: `settings/integrations/procountor/${this.props.company}/deactivate`}).done(response => {
            if (response == "true"){
                this.props.fetchData();
            }
        }).done(() => {
            this.back();
        });
    }

    getDimensions = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Procountor dimensions..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/company/${this.props.company}/procountor/dimensions`}).done(response => {
            this.props.closeSnackbar(key);

            if (response.success == 0) {
                this.showErrorMessage(response.errors, this.tr("Failed to fetch dimensions") + ": ");
                return;
            }

            this.props.enqueueSnackbar(this.tr("Dimensions fetched successfully!"), {
                variant: "success",
            });    
            this.setState({dimensionsUpdatedDate: response.date});
        });
    }

    getCoA = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Procountor Chart of Accounts..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/company/${this.props.company}/procountor/coa`}).done(response => {
            this.props.closeSnackbar(key);

            if (response.success == 0) {
                this.showErrorMessage(response.errors, this.tr("Failed to fetch accounts") + ": ");
                return;
            }

            this.getFetchedAccounts();
            this.props.enqueueSnackbar(this.tr("Chart of Accounts fetched successfully!"), {
                variant: "success",
            });
            this.setState({coaUpdatedDate: response.date});
        });
    }

    getFetchedAccounts = () => {
        DataHandler.get({url: `accounting/accounts/${this.props.company}/procountor/1`}).done(response => {
            this.setState({ accountingAccounts: response.salesAccounts });
        });
    }

    getProducts = (type) => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Procountor products..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/company/${this.props.company}/procountor/products/${type}`}).done(response => {
            this.props.closeSnackbar(key);

            if (response.success == 0) {
                this.showErrorMessage(response.errors, this.tr("Failed to fetch products") + ": ");
                return;
            }

            this.props.enqueueSnackbar(this.tr("Products fetched successfully!"), {
                variant: "success",
            });

            if (type == 1)
                this.setState({productsUpdatedDate: response.date});
            else
                this.setState({expenseProductsUpdatedDate: response.date});
        });
    }

    getVatCodes = () => {
        const key = this.props.enqueueSnackbar(this.tr("Getting Procountor VAT statuses..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/company/${this.props.company}/procountor/vatcodes`}).done(response => {
            this.props.closeSnackbar(key);

            if (response.errors || response.lenght < 1) {
                this.showErrorMessage(response.errors, this.tr("Unable to get list of VAT statuses from Procountor") + ": ");
                return;
            } else {
                this.openDialog("vatCodes", {
                    vatCodeList: response,
                    currentStatus: this.state.reverse_vat_status,
                    vatCodeSelected: (id) => this.saveVatCode(id),
                });
            }
        });
    }

    getDimensionHeaders = () => {
        const key = this.props.enqueueSnackbar(this.tr("Fetching dimensions from Procountor..."), {
            variant: "info",
            persist: true
        });

        DataHandler.get({url: `settings/company/${this.props.company}/procountor/dimension_headers`}).done(response => {
            this.props.closeSnackbar(key);

            if (response.errors || response.lenght < 1) {
                this.showErrorMessage(response.errors, this.tr("Unable to fetch dimensions from Procountor") + ": ");
                return;
            } else {
                this.openDialog("dimensionHeaders", {
                    dimensionList: response,
                    currentDimension: this.state.project_dimension,
                    dimensionSelected: (id) => this.saveProjectDimension(id),
                });
            }
        });
    }

    saveVatCode = (id) => {
        if (!id)
            return;

        DataHandler.put({url: `settings/company/${this.props.company}/procountor/reverse_vat_status`}, {reverse_vat_status: id}).done(() => {
            this.setState({reverse_vat_status: id});

            this.props.enqueueSnackbar(this.tr("VAT status for reverse VAT invoices saved"), {
                variant: "success",
            });
        });
    }

    saveProjectDimension = (id) => {
        const { project_dimension, create_project_dimensions, add_project_dimension_automatically } = this.state;

        DataHandler.put({url: `settings/company/${this.props.company}/procountor/project_dimension`}, {project_dimension: id}).done(() => {
             // If project dimension name is cleared, disable settings that depend on that.
             if (!id && id != project_dimension) {
                create_project_dimensions == 1 && this.onSettingChange("create_project_dimensions", 0);
                add_project_dimension_automatically == 1 && this.changeProjectDimensionSetting("add_project_dimension_automatically", 0);
            }
            
            this.setState({project_dimension: id});

            this.props.enqueueSnackbar(this.tr("Project dimension saved"), {
                variant: "success",
            });
        });
    }

    openDialog = (dialog, props) => {
        this.setState({dialog, dialogProps: props});
    }

    closeDialog = () => {
        this.setState({dialog: undefined, dialogProps: undefined});
    }

    showErrorMessage = (errors, defaultTxt = "") => {
        const err = (errors || []).map(e => {
            return (e.translate_message == 1 ? this.tr(e.message) : e.message);
        });

        const errorMsg = err.length > 0 ? err.join(", ") : this.tr("Unknown error");

        this.props.enqueueSnackbar(defaultTxt + errorMsg, {
            variant: "error",
            autoHideDuration: 10000
        });
    }
    
    getUserIds = () => {
        const key = this.props.enqueueSnackbar("Generating Procountor ID list...", {
            variant: "info",
            persist: true
        });

        DataHandler.getArrayBuffer({url: `procountor/userIds/${this.props.company}`}).done((response) => {
            this.props.closeSnackbar(key);
            
            if (response) {
                const blob = new Blob([response], {
                    type: 'text/plain'
                });
                FileSaver.saveAs(blob, "procountor_ids.txt");
            }
        });
    }

    getProjects = () => {
        DataHandler.get({url: `settings/company/${this.props.company}/procountor/projects`}).done(response => {
            this.setState(response);
        });
    }

    changeInvoiceType = (type) => {
        DataHandler.put({url: `settings/company/${this.props.company}/procountor/purchase_invoice_type`}, {type: type}).done(() => {
            this.setState({purchase_invoice_type: type});
        });
    };

    changeProject = (target, update) => {
        if (update[target] == undefined)
            return;
        
        DataHandler.post({ url: `settings/company/${this.props.company}/procountor/projects` }, {update});
    }
    
    onCheckChange = (update, name, value) => {
        const { company } = this.props;
        
        this.setState({[name]: value}, () =>  DataHandler.put({url: `settings/company/${company}/procountor_settings`}, this.state));
    }

    onSettingChange = (name, value) => {
        const { company } = this.props;
        const update = { [name]: value }
        
        this.setState({...update}, () =>  DataHandler.put({url: `settings/company/${company}/procountor_integration_settings`}, update));
    }

    authenticate = () => {
        DataHandler.put({url: `settings/company/${this.props.company}/procountor/auth`}, this.state).done(resp => {
            if (resp.status == "ok") {
                this.props.enqueueSnackbar(this.tr("Taimer connected with Procountor"), {
                    variant: "success",
                });	
                this.getProcountorStatus();
            } else {
                this.props.enqueueSnackbar(this.tr("Unable to connect with Procountor. Check the API key and Procountor settings"), {
                    variant: "error",
                });	
            }
        })
        .fail(err => {
            this.props.enqueueSnackbar(this.tr("Error in connecting with Procountor"), {
                variant: "error",
            });	
        })    
    }

    changeDefault = (type, id) => {
        const company = this.props.company;
        this.setState({[type]: id}, () => DataHandler.put({url: `accounting/defaults/${company}`}, {type: type, id: id }));
    }

    changeProjectDimensionSetting = (name, value) => {
        this.onSettingChange(name, value);
        if (name == "add_project_dimension_automatically" && value == 0) {
            this.onSettingChange("use_parent_project_dimension_for_subprojects", 0);
        }
    }

    renderProjectDimensionSettings = () => {
        const { use_parent_project_dimension_for_subprojects, add_project_dimension_automatically } = this.state;
        const settings = { 
            use_parent_project_dimension_for_subprojects,
            add_project_dimension_automatically
        };

        const settingChangeAllowed = (name) => {
            return name == "add_project_dimension_automatically" || add_project_dimension_automatically == 1
        }

        return <div className='procountor-settings'>
            <SettingsGrid item={settings} onChange={(update, name, value) => settingChangeAllowed(name) && this.changeProjectDimensionSetting(name, value)} settings={[
                {
                    type: "check",
                    name: "add_project_dimension_automatically",
                    label: this.tr("Select project dimension automatically for invoice rows"),
                    infoTooltip: this.tr("When this setting is active, project dimension is automatically selected for invoice rows."),
                    className: "respSetting",
                    locked: !this.state.project_dimension
                },
                add_project_dimension_automatically == 1 ? {
                    type: "check",
                    name: "use_parent_project_dimension_for_subprojects",
                    label: this.tr("Use parent project dimension for subproject invoices"),
                    infoTooltip: this.tr("When this setting is active, parent project is used as project dimension for invoice rows on subproject invoices."),
                    className: "respSetting",
                    locked: !this.state.project_dimension
                } : undefined
            ]} />
        </div>
    }

    renderSettingWithTooltip = (setting, tooltip) => {
        if (tooltip) {
            return (
                    <Tooltip title={tooltip} classes={{ tooltip: 'darkblue-tooltip' }} placement="top" arrow >
                        <div>
                            {setting}
                        </div>
                    </Tooltip>
            )
        }
        return <div className='tooltip-setting'>{setting}</div>;
    }

    renderSendInvoicesDirectlySetting = () => {
        const settings = { send_invoices_directly_to_customers: this.state.send_invoices_directly_to_customers };

        return <div className='procountor-settings'>
            <SettingsGrid item={settings} onChange={(update, name, value) => this.onSettingChange(name, value)} settings={[{
                type: "check",
                name: "send_invoices_directly_to_customers",
                label: this.tr("Send invoices directly to customers after sending them to Procountor"),
                infoTooltip: this.tr("When this setting is active, invoices sent from Heeros PSA to Procountor are immediately sent to customers."),
                className: "respSetting full-length-check no-top-margin",
            }]} />
        </div>
    }

    renderProjectCreationSwitch = () => {
        const settings = { create_project_dimensions: this.state.create_project_dimensions };

        return <SettingsGrid item={settings} onChange={(update, name, value) => this.onSettingChange(name, value)} settings={[{
                    type: "check",
                    name: "create_project_dimensions",
                    label: this.tr("Send created projects automatically to Procountor"),
                    className: "respSetting full-length-check",
                    locked: !this.state.project_dimension
                }]} />
    }

    renderProjectDimensionFormatSelect = () => {
        return <OutlinedField select disabled={!this.state.project_dimension} label={this.tr("Project dimension format")} name={"default"} value={this.state.project_dimension_format} fullWidth >
                    {this.projectDimensionFormats.map(({value, label}) => <MenuItem key={value} value={value} onClick={() => this.onSettingChange("project_dimension_format", value)}>{label}</MenuItem>)}
                </OutlinedField>
    }

    renderProjectDimensionSend = () => {
        const { sendingProjects, project_dimension, allProjectsAmount, sentProjectsAmount } = this.state;
        const sendingAllowed = project_dimension && !sendingProjects;

        return <div className={`text-button ${sendingAllowed ? "" : "disabled"}`}>
                <div className={`send-projects-button`} onClick={() => sendingAllowed && this.openUploadConfirmation()}>
                    <CloudUpload className='icon' />
                    <span className='button'>{this.tr('Upload all Heeros PSA projects to Procountor')}</span>
                </div>
                <span className='info'>({sentProjectsAmount} / {allProjectsAmount} {this.tr("sent")})</span>
            </div>
    }

    openUploadConfirmation = () => {
        this.context.functions.showConfirmationDialog({
            header: this.tr('Upload all Heeros PSA projects to Procountor'),
            warning: this.htmlTr('Are you sure you want to upload all active Heeros PSA projects to Procountor for dimension use? ${linebreak} Max. ${maxamount} projects are sent at a time. Projects are sent in chunks of ${chunklength}, sending can take several minutes.', { linebreak: [<br />, <br />], maxamount: 300, chunklength: 50}),
            confirmText: this.tr('Upload'),
            onConfirm: this.uploadProjects,
            confirmButtonClass: "blue",
            wider: true
        });
    }

    uploadProjects = (data = []) => { 
        const total_sent = this.project_upload_success_total;
        const message = total_sent > 0 
            ? this.tr("${amount} projects sent, sending more projects...", { amount: total_sent })
            : this.tr("Sending projects to Procountor...");

        const key = this.props.enqueueSnackbar(message, {
            variant: "info",
            persist: true
        });

        this.setState({ sendingProjects: true });

        DataHandler.post({url: `settings/company/${this.props.company}/procountor/upload_projects`}, {data}).done(resp => {
            this.project_upload_success_total += Number(resp.success_count) || 0;

            if (resp.not_sent_projects?.length > 0) {
                this.props.closeSnackbar(key);
                this.uploadProjects(resp.not_sent_projects);
            }
            else {
                this.props.closeSnackbar(key);
                this.showProjectUploadSuccess();
            }
        })
        .fail(err => {
            this.props.closeSnackbar(key);
            this.showProjectUploadError(err);
        })
    }

    showProjectUploadSuccess = () => { 
        this.setState({ sendingProjects: false });

        this.props.enqueueSnackbar(this.tr("${amount} projects uploaded successfully!", { amount: this.project_upload_success_total || 0 }), {
            variant: "success",
        });	
        const sentProjectsAmount = Number(this.state.sentProjectsAmount) + this.project_upload_success_total;
        this.setState({sentProjectsAmount});
        this.project_upload_success_total = 0;
    }

    showProjectUploadError= (err) => { 
        this.setState({ sendingProjects: false });

        let msg = this.tr("Error in uploading projects!");
        const errorMsg = err?.responseJSON?.message || "";

        if (errorMsg) {
            const translate = err.translate_message == 1;
            msg = this.tr("Error in uploading projects: ${errorMsg}", {errorMsg: translate ? this.tr(errorMsg) : errorMsg});
        }

        this.props.enqueueSnackbar(msg, {
            variant: "error",
        });	

        if (this.project_upload_success_total > 0) {
            this.props.enqueueSnackbar(this.tr("${amount} projects uploaded successfully!", { amount: this.project_upload_success_total || 0 }), {
                variant: "success",
            });	
        }
        const sentProjectsAmount = Number(this.state.sentProjectsAmount) + this.project_upload_success_total;
        this.setState({sentProjectsAmount});
        this.project_upload_success_total = 0;
    }

    render(){
        const {userObject: {dateFormat}} = this.context;
        
        const settings = { use_maventa_targeting: this.state.use_maventa_targeting };
        const dialogs = { vatCodes: VatCodeDialog, dimensionHeaders: DimensionDialog }
        const CustomDialog = this.state.dialog && dialogs[this.state.dialog];

        const selectProjectDimensionTooltip = !this.state.project_dimension ? this.tr("Select project dimension first") : "";

        return (
            <div id="integration-procountor" className="main-integration-wrapper">
                <div className="header">
                    <Button className="return-button" variant="text" size="large" onClick={this.back}>{<ChevronLeft />}</Button>
                    <h3>{this.tr('Procountor Settings')}</h3>
                </div>
                {this.state.active == "-1" ?
                    <div className="settings">
                        <h4>{this.tr("API key")}</h4>
                        <p>{this.tr("Put your API key that you got from Procountor here and press 'Authenticate' to connect Taimer with Procountor")}</p>
                        <p>{this.tr("Taimer's client ID is ")}<b>taimer2Client</b></p>
                        <OutlinedField name="api_key" value={this.state.username} onChange={(evt) => this.setState({username: evt.target.value})} label={this.tr("API key")} />
                        <br />
                        <Button data-testid="button_authenticate" color="primary" onClick={this.authenticate} size="large">{this.tr("Authenticate")}</Button>
                    </div>
                :
                    <div className="settings">
                        <h4>{this.tr("Deactivate Procountor")}</h4>
                        <Button className="deactivate" onClick={this.dialogOpen} size="large">{this.tr('Deactivate')}</Button>

                        <div className="settings">
                            <h4>{this.tr("Purchase invoice type")}</h4>
                            <p className='subtitle'>{this.tr("Select the status of purchase invoices that are fetched daily from Procountor To Taimer")}</p>
                            <OutlinedField select disabled={false} label={this.tr("Invoice type")} name={"default"} value={this.state.purchase_invoice_type} fullWidth >
                                {this.invoiceTypes.map(({value, label}) => <MenuItem key={value} value={value} onClick={() => this.changeInvoiceType(value)}>{label}</MenuItem>)}
                            </OutlinedField>
                        </div><br/><br/>

                        <h4>{this.tr('Get Chart of Accounts from Procountor')}</h4>
                        <Button data-testid="button_get_coa" color="primary" className="dimensions" onClick={this.getCoA} size="large">{this.tr('Get CoA')}</Button>
                        {this.state.coaUpdatedDate != "0000-00-00" && (
                            <span data-testid="accounts_fetched_info" className="date-info">{this.tr('Updated') + ": " +  format(this.state.coaUpdatedDate, dateFormat.toUpperCase())}</span>
                        )}

                        <div className="drop-section">
                            <h4>{this.tr("Default sales account")}</h4>
                            <OutlinedField select disabled={false} label={this.tr("Default")} name={"default"} value={this.state.default_accounting_accounts_id} fullWidth >
                                <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_accounting_accounts_id", 0)}>{this.tr("No default")}</MenuItem>
                                {this.state.accountingAccounts.map(({ account_number, name, id }) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_accounting_accounts_id", id)}>{account_number + " " + name}</MenuItem>)}
                            </OutlinedField>
                        </div>

                        <div className="drop-section">
                            <h4>{this.tr("Default purchase account")}</h4>
                            <OutlinedField select disabled={false} label={this.tr("Default")} name={"default"} value={this.state.default_purchase_accounts_id} fullWidth >
                                <MenuItem key={0} value={0} onClick={() => this.changeDefault("default_purchase_accounts_id", 0)}>{this.tr("No default")}</MenuItem>
                                {this.state.accountingAccounts.map(({ account_number, name, id }) => <MenuItem key={id} value={id} onClick={() => this.changeDefault("default_purchase_accounts_id", id)}>{account_number + " " + name}</MenuItem>)}
                            </OutlinedField>
                        </div><br/><br/>

                        <h4>{this.tr('Get Dimensions from Procountor')}</h4>
                        <Button data-testid="button_get_dimensions" color="primary" className="dimensions" onClick={this.getDimensions} size="large">{this.tr('Get Dimensions')}</Button>
                        {this.state.dimensionsUpdatedDate != "0000-00-00" && (
                            <span data-testid="dimensions_fetched_info" className="date-info">{this.tr('Updated') + ": " + format(this.state.dimensionsUpdatedDate, dateFormat.toUpperCase())}</span>
                        )}

                        <h4>{this.tr('Get Products from Procountor')}</h4>
                        <Button color="primary" className="dimensions" onClick={() => this.getProducts(1)} size="large">{this.tr('Get Products')}</Button>
                        {this.state.productsUpdatedDate != "0000-00-00" && (
                            <span className="date-info">{this.tr('Updated') + ": " + format(this.state.productsUpdatedDate, dateFormat.toUpperCase())}</span>
                        )}

                        <h4>{this.tr('Get Expense Products from Procountor')}</h4>
                        <Button color="primary" className="dimensions" onClick={() => this.getProducts(3)} size="large">{this.tr('Get Expense Products')}</Button>
                        {this.state.productsUpdatedDate != "0000-00-00" && (
                            <span className="date-info">{this.tr('Updated') + ": " + format(this.state.expenseProductsUpdatedDate, dateFormat.toUpperCase())}</span>
                        )}

                        <h4 className="dimensions">{this.tr('Get person IDs from Procountor')}</h4>
                        <Button color="primary" onClick={this.getUserIds} size="large">{this.tr('Get IDs')}</Button>

                        <h4>{this.tr('Set VAT status for reverse VAT invoices')}</h4>
                        <p className='subtitle'>{this.tr("Select which Procountor VAT status is used for revere VAT invoices")}</p>
                        <Button color="primary" className="dimensions" onClick={() => this.getVatCodes()} size="large">{this.tr('Set VAT status')}</Button>

                        <div id="settings-maventa" className="main-integration-wrapper">
                            <h4>{this.tr("Contract billing")}</h4>
                            <p className='subtitle'>{this.tr("Connect your Procountor bills to contract billing for your customers")}</p>
                            <SettingsGrid item={settings} onChange={this.onCheckChange} settings={[{
                                type: "check",
                                name: "use_maventa_targeting",
                                label: this.tr("Procountor contract billing"),
                                className: "respSetting third"
                            }]} />
                        </div>

                        <div className="settings">
                            <h4>{this.tr("Vacation project")}</h4>
                            <ProjectTreeDropdown
                                name="project" // TODO: Onko tarpeellinen?
                                label={this.tr("Project")}
                                treeDropdownProps={{ growOptionContainer: false, showTooltip: true, resetOnClickaway: true, showTreeDropdownInfoIcon: false }}
                                value={this.state.vacation_project_id}
                                disabled={false}
                                disableAutoSelect
                                queryParameters={{right: 'read'}}
                                onSelect={e => {
                                    this.setState({
                                        vacation_project_id: e.id
                                    }, () => {
                                        const update = {
                                            vacation_project_id: e.id
                                        };
                                        this.changeProject('vacation_project_id', update);
                                    });
                                }}
                            />
                        </div>

                        <div className="settings">
                            <h4>{this.tr("Sick leave project")}</h4>
                            <ProjectTreeDropdown
                                name="project" // TODO: Onko tarpeellinen?
                                label={this.tr("Project")}
                                treeDropdownProps={{ growOptionContainer: false, showTooltip: true, resetOnClickaway: true, showTreeDropdownInfoIcon: false }}
                                value={this.state.sick_leave_project_id}
                                disabled={false}
                                disableAutoSelect
                                queryParameters={{right: 'read'}}
                                onSelect={e => {
                                    this.setState({
                                        sick_leave_project_id: e.id
                                    }, () => {
                                        const update = {
                                            sick_leave_project_id: e.id
                                        };
                                        this.changeProject('sick_leave_project_id', update);
                                    });
                                }}
                            />
                        </div>

                        <div className="settings">
                            <h4>{this.tr("Childcare leave project")}</h4>
                            <ProjectTreeDropdown
                                name="project" // TODO: Onko tarpeellinen?
                                label={this.tr("Project")}
                                treeDropdownProps={{ growOptionContainer: false, showTooltip: true, resetOnClickaway: true, showTreeDropdownInfoIcon: false }}
                                value={this.state.child_sick_leave_project_id}
                                disabled={false}
                                disableAutoSelect
                                queryParameters={{right: 'read'}}
                                onSelect={e => {
                                    this.setState({
                                        child_sick_leave_project_id: e.id
                                    }, () => {
                                        const update = {
                                            child_sick_leave_project_id: e.id
                                        };
                                        this.changeProject('child_sick_leave_project_id', update);
                                    });
                                }}
                            />
                        </div>

                        <div className="settings full-length">
                            <h2>{this.tr('Projects')}</h2>
                            
                            <h4>{this.tr('Set dimension for project targeting')}</h4>
                            <p className='subtitle'>{this.tr("Select which Procountor dimension is used to automatically target bills to projects")}</p>
                            <Button color="primary" className="dimensions" onClick={() => this.getDimensionHeaders()} size="large">{this.tr('Select dimension')}</Button>

                            {this.renderSettingWithTooltip(
                                this.renderProjectDimensionSettings(),
                                selectProjectDimensionTooltip
                            )}

                            <h4>{this.tr('Project creation')}</h4>
                            <p className='subtitle'>{this.tr("Here you can define whether projects created in PSA will be synced with Procountor automatically and what format is used.")}</p>

                            {this.renderSettingWithTooltip(
                                this.renderProjectCreationSwitch(),
                                selectProjectDimensionTooltip
                            )}
                        </div>

                        <div className="settings">
                            {this.renderSettingWithTooltip(
                                this.renderProjectDimensionFormatSelect(),
                                selectProjectDimensionTooltip
                            )}
                        </div>

                        <div className="settings full-length">
                            {this.renderSettingWithTooltip(
                                this.renderProjectDimensionSend(),
                                selectProjectDimensionTooltip
                            )}
                        </div>

			<div className="settings full-length">
                            <h2>{this.tr('Invoices')}</h2>
                            {this.renderSendInvoicesDirectlySetting()}
                        </div>

                        {CustomDialog && <CustomDialog {...this.state.dialogProps} onClose={this.closeDialog} />}

                        <Dialog
                            open={this.state.dialogOpen}
                            >
                            <DialogTitle><WarningIcon style={{ marginRight: 16 }} /> {this.tr('Deactivate Procountor?')}</DialogTitle>
                            <DialogContent>
                                    <DialogContentText>
                                       {this.tr(' Are you sure you want to deactivate Procountor?')}
                                    </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={this.dialogOpen}>
                                    {this.tr('Cancel')}
                                </Button>
                                <Button onClick={this.deactivate} style={{background: "#d0021b", color: "#ffffff"}}>
                                    {this.tr('Deactivate')}
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </div>
                }
            </div>
        )
    }
}

export default withSnackbar(Procountor);
