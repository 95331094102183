import React from "react";
import TaimerComponent from "../TaimerComponent";
import CoreDialog from './mass_operations/CoreDialog';

class QuoteStatusDialog extends TaimerComponent {

    constructor(props, context) {
        super(props, context, "dialogs/QuoteStatusDialog");
    }

    render() {
        const { data: { active, status, closeDialog, onConfirm } } = this.props;

        let text = "";
        switch (status) {
            case 4:
                text = this.tr("You are changing status to Approved. Do you also want to activate quote?");
                break;
            case -1:
                text = this.tr("You are changing status to Archived. Do you also want to deactivate quote?");
                break;
            case 5:
                text = this.tr("You are changing status to Declined. Do you also want to deactivate quote?");
                break;
        }
        
        return (
            <CoreDialog
                dialogType={"delete"}
                dialogProps={{
                    closeIconTooltip: this.tr("Cancel status change"),
                    hideWarningIcon: true,
                    header: this.tr('Editing sales quote status'),
                    confirmButtonClass: "blue",
                    translatedConfirmButtonText: active == 1 ? this.tr("Deactivate") : this.tr("Activate"),
                    cancelButtonText: active == 1 ? this.tr("Keep active") : this.tr("Keep inactive"),
                    warning: () => text,
                    close: closeDialog,
                    onCloseClick: closeDialog,
                    onConfirm: () => {
                        closeDialog();
                        onConfirm(active == 1 ? 0 : 1);
                    },
                    onCancel: () => {
                        closeDialog();
                        onConfirm(active);
                    },
                }}
        />
        );
    }	
}

export default QuoteStatusDialog;