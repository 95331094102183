
/* css */
// CPQList.css (ylempi taso)

/* material-ui */
import Menu from '@mui/material/Menu';
import Cancel from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import MoveIcon from '@mui/icons-material/UnfoldMore';
import MenuItem from '@mui/material/MenuItem';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import CheckCircle from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import withStyles from '@mui/styles/withStyles';

/* others */
import React from 'react';
import ListRow from "../ListRow";
import PropsOnlyListRow from "./../PropsOnlyListRow";
import ListCell from "../ListCell";
import FocusGroup from "../../general/FocusGroup";
import DataHandler from '../../general/DataHandler';
import ContextMenu from '../../general/ContextMenu';
import CheckboxCell from "../cells/CheckboxCell";
import TextInputCell from "../cells/TextInputCell";
import PlaceholderListCell from '../PlaceholderListCell';
import TaimerComponent from "../../TaimerComponent";
import AutoCompleteCell from '../../list/cells/AutoCompleteCell';
import CurrencyListCell from '../CurrencyListCell';
import { SettingsContext } from './../../SettingsContext';
import InfoSelect from '../cells/InfoSelect';
import cn from 'classnames';
import { withSnackbar } from 'notistack';
import { formatInputNumber } from '../../helpers';

import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import EditIcon from "@mui/icons-material/Edit";

const styles = theme => ({

});

class CPQDraggableListRow extends PropsOnlyListRow {
	constructor(props, state, conf, namespace) {
		super(props, state, conf, namespace);
	}

	//Checks should row data be updated
	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.over !== this.state.over || nextState.dragPos !== this.state.dragPos)
			return true;
		
		return super.shouldComponentUpdate(nextProps, nextState);
	}
}

//Description child row
class DescriptionRow extends CPQDraggableListRow {

	static contextType = SettingsContext;

	constructor(props) {
		super(props, { checked: false}, undefined, "list/rows/CPQListRow");
		this.focusGroup = React.createRef();
		this.cellEdited = this.cellEdited.bind(this);
		this.saveNewRow	= this.saveNewRow.bind(this);
		this.check 		= this.check.bind(this);
		this.saveRow 	= this.saveRow.bind(this);
	}

	//Checks should row data be updated


	//If cell is edited lets fire this function
	cellEdited(name, value) {
		let data = this.props.data;
		data[name] 			 = value;
		data['field_edited'] = name;
		this.setData({ data: data });
		if(this.props.data.id < 0)
			return;
		return this.saveRow(data);
	}

	//When we want to save totally new row
	// - set rows data state
	// - save row to database 
	saveNewRow() {
		if(this.props.data['id'] > -1)
			return;

		let data = this.state.data;
		this.setData({ data: data });
		return this.saveRow(data);
	}

	//This function saves or updates rows data 
	saveRow(data) {
		return DataHandler.post({url: `cpq/cpqnew`}, data)
		.done(response => {
			this.setData({ invalids: [] });

			if(this.props.data.id < 0)
				this.setData("id", response.id);

			this.props.rowProps.getCPQData();
		})
		.fail(response => {
			if(response.status === 422) {
				this.setData({ invalids: Object.keys(response.responseJSON) });
			}
		});
	}

	//Fires when children is dragged
	onDragStart = (e) => {
		e.dataTransfer.setData(`childrow_${this.props.data.parentId}`, this.props.data.id);
		e.dataTransfer.effectAllowed = "move";
		var img = document.createElement('img'); img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
		e.dataTransfer.setDragImage(img, 0, 0);
	}

	//Fires when dragged object enters over object where it can be dropped
    dragEnter = (e) =>
	{
		if (e.dataTransfer.types.indexOf(`childrow_${this.props.data.parentId}`) === -1)
			return;

        this.setState({over: true, dragPos: e.nativeEvent.offsetY < 20 ? 'before' : 'after'});
		e.preventDefault();
	}

	//Fires when dragged object leaves object where it can be dropped
	dragLeave = (e) =>
	{
		if (e.dataTransfer.types.indexOf(`childrow_${this.props.data.parentId}`) === -1)
			return;

        this.setState({over: false});
		e.preventDefault();
	}

	//Fires when dragged object is on object where it can be dropped
    dragOver = (ev) => {
		if (ev.dataTransfer.types.indexOf(`childrow_${this.props.data.parentId}`) === -1)
			return;

        this.setState({over: true, dragPos: ev.nativeEvent.offsetY < 20 ? 'before' : 'after'});
		ev.preventDefault();
    }

	//Fires when dragged object is dropped where it can be dropped
    drop = (e) => {
        var moved_id = e.dataTransfer.getData(`childrow_${this.props.data.parentId}`);
		this.setState({over: false});

		this.props.rowProps.updateRowOrder(this.props.data.id, moved_id, this.props.data.parentId, this.props.data.order_id, e.nativeEvent.offsetY < 20 ? 'before' : 'after');    
	}

	showHidePrint = () => {
		const newValue = this.props.data.hidden_for_print == 1 ? 0 : 1;
		this.setData("hidden_for_print", newValue);
		const saveData = {
			id: this.props.data.id,
			column: "hidden_for_print",
			value: newValue,
			companies_id: this.props.data.companies_id
		}
		this.props.rowProps.showHidePrint(saveData)
    }

	//Render function for DescriptionRow
	render() {
		const writePrivileges = this.props.rowProps['write'];
		const className 		= ["CPQListRow row", this.props.hidden ? "hidden" : "", this.props.data['id'] < 0 ? "new" : ""].join(" ");
		const { over, dragPos } = this.state;

        const cellProps = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": prop, listCellProps: {"className": prop}, width: this.props.columnWidthMap[prop], name: prop, value: this.props.data[prop]}}
        });

		//DescriptionRow renders return function
		return (
			<div className={cn(className, "desc-row", over && "drag-over", dragPos)}  onDragEnter={this.dragEnter} onDragLeave={this.dragLeave} onDragOver={this.dragOver} onDrop={this.drop} style={{lineHeight: "44px", display: "flex", height: "44px"}}>
				{
					this.props.data.id > 0 ?
	                <ListCell tabIndex="1" onlyDisplay={true} {...cellProps.expand} title={this.tr("Delete")} >   
	                    {writePrivileges ? <DeleteIcon
	                        onClick={() => {this.delete(this.props.data.id)}} 
	                    /> : null}
	                </ListCell>
					:
					<ListCell tabIndex="1" onlyDisplay={true} width={this.props.columnWidthMap['expand']}>
						<Tooltip title="Save" placement="bottom">
							<CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.saveNewRow()} />
						</Tooltip>
					</ListCell>
				}
				{
					this.props.data['id'] > 0 ?
					<ListCell
						width={this.props.columnWidthMap['checked']}
						permanentEditMode={true}>
						{writePrivileges && <div title={this.tr("Move")} draggable onDragStart={this.onDragStart} >
							<MoveIcon />
						</div>}
					</ListCell>
					:
					<ListCell width={this.props.columnWidthMap.checked} permanentEditMode={true} onlyDisplay={true}>
						<Tooltip title="Cancel" placement="bottom">
							<Cancel className="cancelIcon" onClick={() => this.delete(this.props.data)} />
						</Tooltip>
					</ListCell>
				}
				<ListCell
					name="show_in_quote_print"
					width={this.props.columnWidthMap.show_in_quote_print}
					onlyDisplay >
					<div className="cpq-list-switch-container">
						<Switch
							color="primary"
							disableRipple={true}
							onChange={() => this.showHidePrint()}
							checked={this.props.data.hidden_for_print == 0}
						/>
					</div>
				</ListCell>
				<TextInputCell
					name="name"
					value={this.tr("Description")}
					width={this.props.columnWidthMap['name']}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={false}
					cellClassName={"childnamecell"}
					// listCellProps={{
					// 	inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
					// 	editable: writePrivileges,
					// }}
				/>
				<TextInputCell
					name="description"
					listCellType={PlaceholderListCell}
					value={this.props.data.description}
					width={this.props.columnWidthMap['description'] + this.props.columnWidthMap['quantity'] + this.props.columnWidthMap['unit'] + this.props.columnWidthMap['unit_cost'] + this.props.columnWidthMap['selling_price'] + this.props.columnWidthMap['vat'] + this.props.columnWidthMap['sum'] + this.props.columnWidthMap['margin'] + this.props.columnWidthMap['worktype']}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					textInputProps={{
						maxChars: 250
					}}
					editable={writePrivileges}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						editable: writePrivileges,
						placeholder: this.tr("Write description here")
					}}
				/>
			</div>
		);
	}
}

//Item row
class ItemRow extends CPQDraggableListRow {

	static contextType = SettingsContext;

	constructor(props) {
		super(props, { checked: false}, undefined, "list/rows/CPQListRow");

		this.worktypes = [
			{name: this.tr("Hours"), 			id: 1},
			{name: this.tr("Sub-contracting"), 	id: 2},
            {name: this.tr("Services"), 		id: 3},
            {name: this.tr("Expenses"), 		id: 5},
            {name: this.tr("Products"), 		id: 4}
		]

		this.quantityTypes = [
            {id: 1, name: this.tr("qty")},
            {id: 2, name: this.tr("h")}
		]
		
		this.focusGroup = React.createRef();
		this.cellEdited = this.cellEdited.bind(this);
		this.saveNewRow	= this.saveNewRow.bind(this);
		this.check 		= this.check.bind(this);
		this.saveRow 	= this.saveRow.bind(this);
	}

	//If cell is edited lets fire this function
	cellEdited(name, value, validation) {
		let data = this.props.data;
		data[name] 			 = value;
		data['field_edited'] = name;

		if (validation && !this.validateField(name, value, validation)) 
			return;

		if (name == "unit")
			data.worktype = value == 2 ? '1' : '3';

		data['field_error_' + name] = false;
		this.setData({ data: data });
		if(this.props.data.id < 0)
			return;
		return this.saveRow(data);
	}

	validateField(name, value, validation) {
		let data = this.props.data;

		if (validation === "numeric" && !Number(value) && Number(value) != 0) {
			data['field_error_' + name] = value;
			data.name = value;
			this.setData({ data: data });

			this.props.parentProps.enqueueSnackbar(this.tr("Invalid number!"), {
				variant: "error"
			});
			return false;
		}

		return true;
	}

	//When we want to save totally new row
	// - set rows data state
	// - save row to database 
	saveNewRow() {
		if(this.props.data['id'] > -1)
			return;

		let data = this.props.data;
		this.setData({ data: data });
		return this.saveRow(data);
	}

	//This function saves or updates rows data 
	saveRow(data) {
		return DataHandler.post({url: `cpq/cpqnew`}, data)
		.done(response => {
			this.setData({ invalids: [] });

			if(this.props.data.id < 0)
				this.setData("id", response.id);

			this.props.rowProps.getCPQData();
		})
		.fail(response => {
			if(response.status === 422) {
				this.setData({ invalids: Object.keys(response.responseJSON) });
			}
		});
	}

	calcSumValue(quantity, selling_price) {
		return quantity * selling_price;
	}

	calcMarginValue(quantity, unit_price, selling_price) {
		return (selling_price - unit_price) * quantity;
	}

	//Fires when children is dragged
	onDragStart = (e) => {
		e.dataTransfer.setData(`childrow_${this.props.data.parentId}`, this.props.data.id);
		e.dataTransfer.effectAllowed = "move";
		var img = document.createElement('img'); img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
		e.dataTransfer.setDragImage(img, 0, 0);
	}

	//Fires when dragged object enters over object where it can be dropped
    dragEnter = (e) =>
	{
		if (e.dataTransfer.types.indexOf(`childrow_${this.props.data.parentId}`) === -1)
			return;

		this.setState({over: true, dragPos: e.nativeEvent.offsetY < 20 ? 'before' : 'after'});
		e.preventDefault();
	}

	//Fires when dragged object leaves object where it can be dropped
	dragLeave = (e) =>
	{
		if (e.dataTransfer.types.indexOf(`childrow_${this.props.data.parentId}`) === -1)
			return;
			
        this.setState({over: false});
		e.preventDefault();
    }
	
	//Fires when dragged object is on object where it can be dropped
    dragOver = (ev) => {
		if (ev.dataTransfer.types.indexOf(`childrow_${this.props.data.parentId}`) === -1)
			return;

		this.setState({over: true, dragPos: ev.nativeEvent.offsetY < 20 ? 'before' : 'after'});
		ev.preventDefault();
    }

	//Fires when dragged object is dropped where it can be dropped
    drop = (e) => {
        var moved_id = e.dataTransfer.getData(`childrow_${this.props.data.parentId}`);
		this.setState({over: false});

        this.props.rowProps.updateRowOrder(this.props.data.id, moved_id, this.props.data.parentId, this.props.data.order_id, e.nativeEvent.offsetY < 20 ? 'before' : 'after');
	}

	showHidePrint = () => {
		const newValue = this.props.data.hidden_for_print == 1 ? 0 : 1;
		this.setData("hidden_for_print", newValue);
		const saveData = {
			id: this.props.data.id,
			column: "hidden_for_print",
			value: newValue,
			companies_id: this.props.data.companies_id
		}
		this.props.rowProps.showHidePrint(saveData)
    }

	//Render function for ItemRow
	render() {
		const writePrivileges = this.props.rowProps['write'];
		const className 	   	= ["CPQListRow row", this.props.hidden ? "hidden" : "", this.props.data['id'] < 0 ? "new" : ""].join(" ");
		const { over, dragPos } = this.state;

        const cellProps = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": prop, listCellProps: {"className": prop}, width: this.props.columnWidthMap[prop], name: prop, value: this.props.data[prop]}}
        });
		//ItemRow cells
		const cells = {
			expand:
				this.props.data['id'] > 0 ?
                <ListCell tabIndex="1" onlyDisplay={true} {...cellProps.expand} title={this.tr("Delete")} >   
                    {writePrivileges ? <DeleteIcon
                        onClick={() => {this.delete(this.props.data.id)}} 
                    /> : null}
                </ListCell>
				:
				<ListCell tabIndex="1" onlyDisplay={true} width={this.props.columnWidthMap['expand']}>
					<Tooltip title={this.tr("Save")} placement="bottom">
						<CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.saveNewRow()} />
					</Tooltip>
				</ListCell>,
			checked:
				this.props.data['id'] > 0 ?
				<ListCell
					width={this.props.columnWidthMap['checked']}
					permanentEditMode={true}>
					{writePrivileges && <div title={this.tr("Move")} draggable onDragStart={this.onDragStart} >
						<MoveIcon />
					</div>}
				</ListCell>
				:
				<ListCell width={this.props.columnWidthMap.checked} permanentEditMode={true} onlyDisplay={true}>
					<Tooltip title={this.tr("Cancel")} placement="bottom">
						<Cancel className="cancelIcon" onClick={() => this.delete(this.props.data)} />
					</Tooltip>
				</ListCell>,
			name:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0
					}}
					width={this.props.columnWidthMap['name']}
					name="name"
					value={this.tr("Item")}
					cellClassName={"childnamecell"}
					editable={false}
					onEdited={writePrivileges ? this.cellEdited : undefined}/>,
			description:
				<TextInputCell
					listCellType={PlaceholderListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0,
						placeholder: this.tr("Item description")
					}}
					width={this.props.columnWidthMap['description']}
					name="description"
					value={this.props.data['description']}
					textInputProps={{
						maxChars: 250
					}}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={writePrivileges}
					 />,
			quantity:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						className: this.props.data["field_error_quantity"] ? "error-field" : ""
					}}
					width={this.props.columnWidthMap['quantity']}
					name="quantity"
					value={this.props.data["field_error_quantity"] ? this.props.data["field_error_quantity"] :  this.props.data['quantity']}
					onEdited={writePrivileges ? (name, value) => this.cellEdited(name, value, "numeric") : undefined}
					editable={writePrivileges}
					validation={["numeric"]}
					/>,
			/* unit:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0
					}}
					width={this.props.columnWidthMap['unit']}
					name="unit"
					value={this.props.data['unit']}
					onEdited={writePrivileges ? this.cellEdited : undefined}
						/>, */
			unit:
				<AutoCompleteCell
					width={this.props.columnWidthMap['unit']}
					value={this.quantityTypes.find(qt => qt.id == this.props.data['unit'])}
					autoCompleteData={this.quantityTypes}
					searchable={false}
					name="unit"
					onEdited={(value) => {
						this.cellEdited("unit", value.id);
					}}
					editable={writePrivileges}
					/>,
			unit_cost:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						className: this.props.data["field_error_unit_cost"] ? "error-field" : "",
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['unit_cost']}
					name="unit_cost"
					value={this.props.data["field_error_unit_cost"] ? this.props.data["field_error_unit_cost"] : this.props.data['unit_cost']}
					onEdited={writePrivileges ? (name, value) => this.cellEdited(name, value, "numeric") : undefined}
					editable={writePrivileges}
					validation={["numeric"]}
					 />,
			selling_price:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						className: this.props.data["field_error_selling_price"] ? "error-field" : "",
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['selling_price']}
					name="selling_price"
					value={this.props.data["field_error_selling_price"] ? this.props.data["field_error_selling_price"] : this.props.data['selling_price']}
					onEdited={writePrivileges ? (name, value) => this.cellEdited(name, value, "numeric") : undefined}
					editable={writePrivileges}
					validation={["numeric"]}
					 />,
			vat:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						className: this.props.data["field_error_vat"] ? "error-field" : "",
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['vat']}
					name="vat"
					value={this.props.data["field_error_vat"] ? this.props.data["field_error_vat"] : this.props.data['vat']}
					onEdited={writePrivileges ? (name, value) => this.cellEdited(name, value, "numeric") : undefined}
					editable={writePrivileges}
					validation={["numeric"]}
					/>,
			sum:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['sum']}
					name="sum"
					value={this.calcSumValue(this.props.data['quantity'], this.props.data['selling_price']) || 0}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={false}
					 />,
			margin:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['margin']}
					name="margin"
					value={this.calcMarginValue(this.props.data['quantity'], this.props.data['unit_cost'], this.props.data['selling_price']) || 0}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={false}
					 />,
			worktype:
				<AutoCompleteCell
					style={{width: this.props.columnWidthMap['worktype'] + 'px'}}
					width={this.props.columnWidthMap['worktype']}
					value={parseInt(this.props.data['worktype'], 10)}
					autoCompleteData={this.worktypes}
					cellClassName={ this.props.data.id < 0 ? "editMode" : "" }
					onEdited={value => {
                        this.cellEdited("worktype", value.id)
					}}
					editable={writePrivileges}

					/>,
			show_in_quote_print:
				<ListCell
					width={this.props.columnWidthMap.show_in_quote_print}
					name="show_in_quote_print"
					onlyDisplay >
					<div className="cpq-list-switch-container">
						<Switch
							color="primary"
							disableRipple={true}
							onChange={() => this.showHidePrint()}
							checked={this.props.data.hidden_for_print == 0}
						/>
					</div>
				</ListCell>
		}

		//ItemRow renders return function
		return (
			<div style={{height: "44px", lineHeight: "44px"}} id={this.props.data.id} className={cn(className, over && "drag-over", dragPos)} onDragEnter={this.dragEnter} onDragLeave={this.dragLeave} onDragOver={this.dragOver} onDrop={this.drop}>
				<FocusGroup 
					ref={this.focusGroup} 
					onCtrlS={() => {
						this.props.data.id < 0 ? this.saveNewRow() : this.cellEdited();
					}}
					focusOnInit={this.props.data.id < 0}
					columnOrder={this.props.columnOrder}>
					{this.props.columnOrder.map(columnName => {
						const cell = cells[columnName];
						
						return columnName === "expand" || columnName === "checked" ? cell : React.cloneElement(cell, {
							listCellProps: { ...(cell.props.hasOwnProperty("listCellProps") ? cell.props.listCellProps : {}), showErrorBorder: this.props.invalids && this.props.invalids.indexOf(columnName) > -1 }
						});
					})}
				</FocusGroup>
			</div>
		);
	}
}

//Product child row
class ProductRow extends CPQDraggableListRow {

	static contextType = SettingsContext;

	constructor(props) {
		super(props, { checked: false}, undefined, "list/rows/CPQListRow");

		this.worktypes = [
			{name: "Hours", 			id: 1},
			{name: "Sub-contracting", 	id: 2},
            {name: "Services", 			id: 3},
            {name: "Expenses", 			id: 5},
            {name: "Products", 			id: 4}
		]

		this.initialFocusDone = false;
		this.focusGroup = React.createRef();
		this.cellEdited = this.cellEdited.bind(this);
		this.saveNewRow	= this.saveNewRow.bind(this);
		this.check 		= this.check.bind(this);
		this.saveRow 	= this.saveRow.bind(this);
	}

    componentDidMount() {
        super.componentDidMount();
        if (!this.initialFocusDone && this.props.rowProps.freshId == this.props.data.id) {
        	//tämän tilalle tehdään suoraan focusgroupiin funktio jolla saa refin ulos!!!
        	//this.focusGroup.current.references[3].current.listCell.current.openEdit();
            this.initialFocusDone = true;
        }
    }	

	//If cell is edited lets fire this function
	cellEdited(name, value, validation) {
		let data = this.props.data;
		if (validation && !this.validateField(name, value, validation)) 
			return;

		data['field_error_' + name] = false;
		data[name] 			 = value;
		data['field_edited'] = name;
		this.setData({ data: data });
		if(this.props.data.id < 0)
			return;
		return this.saveRow(data);
	}

	validateField(name, value, validation) {
		let data = this.props.data;

		if (validation === "numeric" && !Number(value) && Number(value) != 0) {
			data['field_error_' + name] = value;
			data.name = value;
			this.setData({ data: data });

			this.props.parentProps.enqueueSnackbar(this.tr("Invalid number!"), {
				variant: "error"
			});
			return false;
		}

		return true;
	}

	//If cell is edited lets fire this function
	productCellEdited(value) {
		let data = this.props.data;

		data["vat"] 			= value.vat;
		data["description"] 	= value.name;
		data["unit"] 			= value.unit;
		data["unit_cost"] 		= value.cost_price;
		data["type_id"] 		= value.id;
		data["selling_price"] 	= value.income_price;

		this.setData({ data: data });
		if(this.props.data.id < 0)
			return;
		return this.saveRow(data);
	}

	//When we want to save totally new row
	// - set rows data state
	// - save row to database 
	saveNewRow() {
		if(this.props.data['id'] > -1)
			return;

		let data = this.props.data;
		this.setData({ data: data });
		return this.saveRow(data);
	}

	//This function saves or updates rows data 
	saveRow(data) {
			return DataHandler.post({url: `cpq/cpqnew`}, data)
			.done(response => {
				this.setData({ invalids: [] });

				if(this.props.data.id < 0)
					this.setData("id", response.id);

				this.props.rowProps.getCPQData();
			}).fail(response => {
				if(response.status === 422) {
					this.setData({ invalids: Object.keys(response.responseJSON) });
				}
			});
	}
	

	//Does simple quantity * selling price calculation
	calcSumValue(quantity, selling_price) {
		return quantity * selling_price;
	}

	//Calculates rows margin
	calcMarginValue(quantity, unit_price, selling_price) {
		return (selling_price - unit_price) * quantity;
	}

	//Fires when children is dragged
	onDragStart = (e) => {
		e.dataTransfer.setData(`childrow_${this.props.data.parentId}`, this.props.data.id);
		e.dataTransfer.effectAllowed = "move";

		//Transparent pixel as ghost image
		var img = document.createElement('img'); img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
		e.dataTransfer.setDragImage(img, 0, 0);
	}

	//Fires when dragged object enters over object where it can be dropped
    dragEnter = (e) =>
	{
		if (e.dataTransfer.types.indexOf(`childrow_${this.props.data.parentId}`) === -1)
			return;

        this.setState({over: true, dragPos: e.nativeEvent.offsetY < 20 ? 'before' : 'after'});
		e.preventDefault();
	}

	//Fires when dragged object leaves object where it can be dropped
	dragLeave = (e) =>
	{
		if (e.dataTransfer.types.indexOf(`childrow_${this.props.data.parentId}`) === -1)
			return;
        this.setState({over: false});
		e.preventDefault();
    }
	
	//Fires when dragged object is on object where it can be dropped
    dragOver = (ev) => {
		if (ev.dataTransfer.types.indexOf(`childrow_${this.props.data.parentId}`) === -1)
			return;
       	this.setState({over: true, dragPos: ev.nativeEvent.offsetY < 20 ? 'before' : 'after'});
		ev.preventDefault();
    }

	//Fires when dragged object is dropped object where it can be dropped
    drop = (e) => {
        var moved_id = e.dataTransfer.getData(`childrow_${this.props.data.parentId}`);
		this.setState({over: false});

		this.props.rowProps.updateRowOrder(this.props.data.id, moved_id, this.props.data.parentId, this.props.data.order_id, e.nativeEvent.offsetY < 20 ? 'before' : 'after');	
	}

	showHidePrint = () => {
		const newValue = this.props.data.hidden_for_print == 1 ? 0 : 1;
		this.setData("hidden_for_print", newValue);
		const saveData = {
			id: this.props.data.id,
			column: "hidden_for_print",
			value: newValue,
			companies_id: this.props.data.companies_id
		}
		this.props.rowProps.showHidePrint(saveData)
    }

	//Render function for ProductRow
	render() {
		const writePrivileges = this.props.rowProps['write'];
		const className 	   	= ["CPQListRow row", this.props.hidden ? "hidden" : "", this.props.data['id'] < 0 ? "new" : ""].join(" ");
		const { over, dragPos } = this.state

        let products  	   	   = this.props.sharedData['products'].filter(c => c.id === this.props.data['type_id']);

        const cellProps = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": prop, listCellProps: {"className": prop}, width: this.props.columnWidthMap[prop], name: prop, value: this.props.data[prop]}}
        });
		//ProductRow cells
		const cells = {
			expand:
				this.props.data['id'] > 0 ?
                <ListCell tabIndex="1" onlyDisplay={true} {...cellProps.expand} title={this.tr("Delete")}>   
                    {writePrivileges ? <DeleteIcon 
                        onClick={() => {this.delete(this.props.data.id)}} 
                    /> : null}
                </ListCell>
				:
				<ListCell tabIndex="1" onlyDisplay={true} width={this.props.columnWidthMap['expand']}>
					<Tooltip title="Save" placement="bottom">
						<CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.saveNewRow()} />
					</Tooltip>
				</ListCell>,
			checked:
				this.props.data['id'] > 0 ?
				<ListCell
					width={this.props.columnWidthMap['checked']}
					permanentEditMode={true}>
					{writePrivileges && <div title={this.tr("Move")} draggable onDragStart={this.onDragStart} >
						<MoveIcon />
					</div>}
				</ListCell>
				:
				<ListCell width={this.props.columnWidthMap.checked} permanentEditMode={true} onlyDisplay={true}>
					<Tooltip title={this.tr("Cancel")} placement="bottom">
						<Cancel className="cancelIcon" onClick={() => this.delete(this.props.data)} />
					</Tooltip>
				</ListCell>,
			name:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0
					}}
					width={this.props.columnWidthMap['name']}
					name="name"
					value={this.tr("Product")}
					cellClassName={"childnamecell"}
					editable={false}
					onEdited={writePrivileges ? this.cellEdited : undefined}/>,
			description:
                <InfoSelect
                    height={230}
                    tableWidth={550}
                    headerHeight={35}
                    rowHeight={49}
                    width={this.props.columnWidthMap['description']}
                    className={"cell InfoSelect__infoselect_product"}
                    value={this.props.data.description ? this.props.data.description : undefined}
                    options={this.props.sharedData['products'].filter(c => Number(c.catalog_id) === 0 )}
                    placeholder={this.props.data.description ? this.props.data.description : this.tr("Select product")}
                    columns={[
                        { name: "code", header: this.tr("Code"), width: 75 },
                        { name: "name", header: this.tr("Name"), width: 200 },
                        { name: "path", header: this.tr("Category"), width: 200 },
                        { name: "unit", header: this.tr("Unit"), width: 75 },
                    ]}
                    onChange={value => {
                        this.productCellEdited(value);
                    }}
                    editable={writePrivileges}
                />,
			quantity:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						className: this.props.data["field_error_quantity"] ? "error-field" : ""
					}}
					width={this.props.columnWidthMap['quantity']}
					name="quantity"
					decimals={2}
					value={this.props.data["field_error_quantity"] ? this.props.data["field_error_quantity"] : formatInputNumber(this.props.data['quantity'], "hours")}
					onEdited={writePrivileges ? (name, value) => this.cellEdited(name,  value.replace(",", ".")) : undefined}
					editable={writePrivileges}
					validation={["numeric"]}
					/>,
			unit:
				<AutoCompleteCell
					 style={{width: this.props.columnWidthMap['unit'] + 'px'}}
					 width={this.props.columnWidthMap['unit']}
					 value={{id: -1, name: this.props.data['unit']}}
					 autoCompleteData={this.props.sharedData.units}
					 cellClassName={ this.props.data.id < 0 ? "editMode" : "" }
					 allowCreate={true}
					 onEdited={value => {
						if(value.name) {
							this.cellEdited("unit", value.name);
						} else {
							DataHandler.post({url: 'settings/company/product/units'}, { id: -1, name: value.value, deleted: 0 }).done(response => {
								this.cellEdited("unit", value.value);
							});
						}	 	
					 }}
					 editable={writePrivileges}
					/>,
			unit_cost:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						className: this.props.data["field_error_unit_cost"] ? "error-field" : "",
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['unit_cost']}
					name="unit_cost"
					value={this.props.data["field_error_unit_cost"] ? this.props.data["field_error_unit_cost"] : this.props.data['unit_cost']}
					onEdited={writePrivileges ? (name, value) => this.cellEdited(name, value, "numeric") : undefined}
					editable={writePrivileges}
					validation={["numeric"]}
					 />,
			selling_price:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						className: this.props.data["field_error_selling_price"] ? "error-field" : "",
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['selling_price']}
					name="selling_price"
					value={this.props.data["field_error_selling_price"] ? this.props.data["field_error_selling_price"] : this.props.data['selling_price']}
					onEdited={writePrivileges ? (name, value) => this.cellEdited(name, value, "numeric") : undefined}
					editable={writePrivileges}
					validation={["numeric"]}
					 />,
			vat:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						className: this.props.data["field_error_vat"] ? "error-field" : "",
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['vat']}
					name="vat"
					value={this.props.data["field_error_vat"] ? this.props.data["field_error_vat"] : this.props.data['vat']}
					onEdited={writePrivileges ? (name, value) => this.cellEdited(name, value, "numeric") : undefined}
					editable={writePrivileges}
					/>,
			sum:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['sum']}
					name="sum"
					value={this.calcSumValue(this.props.data['quantity'], this.props.data['selling_price'])}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={false}
					 />,
			margin:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['margin']}
					name="margin"
					value={this.calcMarginValue(this.props.data['quantity'], this.props.data['unit_cost'], this.props.data['selling_price'])}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={false}
					 />,
			worktype:
				<AutoCompleteCell
					style={{width: this.props.columnWidthMap['worktype'] + 'px'}}
					width={this.props.columnWidthMap['worktype']}
					value={parseInt(this.props.data['worktype'], 10)}
					autoCompleteData={this.worktypes}
					cellClassName={ this.props.data.id < 0 ? "editMode" : "" }
					onEdited={value => {
                        this.cellEdited("worktype", value.id)
					}}
					editable={writePrivileges}
					/>,
			show_in_quote_print:
				<ListCell
					width={this.props.columnWidthMap.show_in_quote_print}
					name="show_in_quote_print"
					onlyDisplay >
					<div className="cpq-list-switch-container">
						<Switch
							color="primary"
							disableRipple={true}
							onChange={() => this.showHidePrint()}
							checked={this.props.data.hidden_for_print == 0}
						/>
					</div>
				</ListCell>
		}

		//ProductRow renders return function
		return (
			<div style={{height: "44px", lineHeight: "44px"}} className={cn(className, over && "drag-over", dragPos)} onDragEnter={this.dragEnter} onDragLeave={this.dragLeave} onDragOver={this.dragOver} onDrop={this.drop}>
				<FocusGroup 
					ref={this.focusGroup} 
					onCtrlS={() => {
						this.props.data.id < 0  ? this.saveNewRow() : this.cellEdited();
					}}
					//focusOnInit={this.state.data.id < 0}
					columnOrder={this.props.columnOrder}>
					{this.props.columnOrder.map(columnName => {
						const cell = cells[columnName];

						return columnName === "expand" || columnName === "checked" ? cell : React.cloneElement(cell, {
							listCellProps: { ...(cell.props.hasOwnProperty("listCellProps") ? cell.props.listCellProps : {}), 
							showErrorBorder: this.props.data.invalids && this.props.data.invalids.indexOf(columnName) > -1 }
						});
					})}
				</FocusGroup>
			</div>
		);
	}
}

//ParentRow for all other cpq rows
class ParentRow extends PropsOnlyListRow {

    static contextType = SettingsContext;

	constructor(props) {
		super(props, { checked: false}, undefined, "list/rows/CPQListRow");
		this.focusGroup = React.createRef();
		this.cellEdited = this.cellEdited.bind(this);
		this.saveNewRow	= this.saveNewRow.bind(this);
		this.check 		= this.check.bind(this);
		this.saveRow 	= this.saveRow.bind(this);
		if (props.rowProps.newParents.includes(props.data.id)) 
			this.state = {...this.state, showChildren: true};
	}

	//Checks should row data be updated
	
	shouldComponentUpdate(nextProps) {
		if(nextProps.hasOwnProperty("data") && nextProps['data']['id'] !== this.props.data['id']) {
			this.setData({ data: nextProps.data });
			return false;
		}
		return true;
	}

	//If cell is edited lets fire this function
	cellEdited(name, value) {
		if (name === "name" && !this.validateName(value))
			return;

		let data = this.props.data;
		data[name] 			 = value;
		data['field_edited'] = name;

		if (name === "name")
			data['field_error_name'] = false;

		this.setData({ data: data, rename: false});
		if(this.props.data.id < 0)
			return;
		return this.saveRow(data);
	}

	//When we want to save totally new row
	// - set rows data state
	// - save row to database 
	saveNewRow(name, value) {
		if (this.props.data['id'] > -1 || !this.validateName(this.props.data.name, true))
			return;

		let data = this.props.data;
		data[name] = value;
		this.setData({ data: data, normalmode: true });
		return this.saveRow(data);
	}

	validateName(value, showErrorNotification = false) {
		let data = this.props.data;

		if (value.trim() === "") {
			data['field_error_name'] = true;
			data.name = value;
			this.setData({ data: data });

			if (this.props.data['id'] > -1 || showErrorNotification) {
				this.props.enqueueSnackbar(this.tr("Name cannot be empty!"), {
					variant: "error"
				});
			}
			return false;
		}

		return true;
	}

	//This function saves or updates rows data 
	saveRow(data) {
		
		return DataHandler.post({url: `cpq/cpqnew`}, data)
		.done(response => {
			this.setData({ invalids: [] });
			if(this.props.data.id < 0) {
				this.setData("id", response.id);
				this.props.rowProps.newParentAdded(response.id);
			}
			this.props.rowProps.getCPQData();
		})
		.fail(response => {
			if(response.status === 422) {
				this.setData({ invalids: Object.keys(response.responseJSON) });
			}
		});
		
		
	}

	//"Add new" button menus opening function
	handleAddNew = event => {
        this.setData({ anchorEl: event.currentTarget });
    };
	
	//"Add new" button menus closing function
    handleClose = () => {
        this.setData({ anchorEl: null });
	};

	//Calculates rows "Unit cost"
	childCost = () => {
		let cost = 0;
		this.props.children.forEach(child => {
			if(child.data.type == "1" || child.data.type == "2") {
				cost = (parseFloat(child.data.quantity) *  parseFloat(child.data.unit_cost)) + cost;
			}
		})
		return cost.toFixed(2);;
	}

	//Calculates rows "Selling Price Total"
	childPriceAndSum = () => {
		let price = 0;

		this.props.children.forEach(child => {
			if(child.data.type == "1" || child.data.type == "2") {
				price = (parseFloat(child.data.quantity) *  parseFloat(child.data.selling_price)) + price;
			}
		})
		return price.toFixed(2);;
	}

	//Calculates rows "Margin 0%"
	childMargin = () => {
		let margin = 0;
		this.props.children.forEach(child => {
			if(child.data.type == "1" || child.data.type == "2") {
				margin = (parseFloat(child.data.quantity) *  (parseFloat(child.data.selling_price) - parseFloat(child.data.unit_cost))) + margin;
			}
		})
		return margin.toFixed(2);
	}

	showHidePrint = () => {
		const newValue = this.props.data.hidden_for_print == 1 ? 0 : 1;
		this.setData("hidden_for_print", newValue);
		const saveData = {
			id: this.props.data.id,
			column: "hidden_for_print",
			value: newValue,
			companies_id: this.props.data.companies_id
		}
		this.props.rowProps.showHidePrint(saveData)
    }

	//Parent rows render function
	render() {
		const writePrivileges = this.props.rowProps['write'];
		// const writePrivileges = true;
		const className 	   = ["CPQListRow row", this.props.hidden ? "hidden" : "", this.props.data['id'] < 0 ? "new" : ""].join(" ");

		const childHeaders = {
			expand: "",
			checked: "",
			name: this.tr("Name"),
			description: this.tr("Row Details"),
			quantity: this.tr("Qty"),
			unit: this.tr("Unit"),
			unit_cost: this.tr("Unit Cost"),
			selling_price: this.tr("Selling Price"),
			vat: this.tr("Vat"),
			sum: this.tr("Sum 0%"),
			margin: this.tr("Margin 0%"),
			worktype: this.tr("Type")
		}

		//Parent rows cells
		const cells = {
			expand:
				this.props.data['id'] > 0 ?
				<ContextMenu label={<MoreHoriz />} buttonProps={{className: 'action-menu'}} className="cell row-menu" style={{width: this.props.columnWidthMap['expand'] + "px", textAlign: "center"}} noExpandIcon>
					{
						writePrivileges ? 
						<MenuItem onClick={() => this.setData({rename: true})}>
							<EditIcon title="" />{this.tr("Rename")}
						</MenuItem>
						:
						<MenuItem disabled>
							{this.tr("Rename")}
						</MenuItem>
					}
					{
						writePrivileges ? 
						<MenuItem className="delete-action delete"  onClick={() => this.delete()}>
							<RemoveIcon className="Delete" title="" />{this.tr("Delete")}
						</MenuItem>
						:
						<MenuItem disabled>
							{this.tr("Delete")}
						</MenuItem>
					}
				</ContextMenu>
				:
				<ListCell tabIndex="1" onlyDisplay={true} width={this.props.columnWidthMap['expand']}>
					<Tooltip title={this.tr("save")} placement="bottom">
						<CheckCircle className="saveNewRowCheckCircleIcon" onClick={() => this.saveNewRow()} />
					</Tooltip>
				</ListCell>,
			checked:
				this.props.data['id'] > 0 ?
				<CheckboxCell checked={this.props.checked} width={this.props.columnWidthMap['checked']} onClick={() => this.props.listRef.check(this.props.data.id)} />
				:
				<ListCell width={this.props.columnWidthMap.checked} permanentEditMode={true} onlyDisplay={true}>
					<Tooltip title="Cancel" placement="bottom">
						<Cancel className="cancelIcon" onClick={() => this.props.listRef.removeRow(this.props.data.id)} />
					</Tooltip>
				</ListCell>,
			name:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0 || this.props.data.rename === true ,
						className: `name-cell ${this.props.data["field_error_name"] ? "error-field" : ""}`,
					}}
					width={this.props.columnWidthMap['name']}
					name="name"
					openChildren={() => {
							const bool = !this.state.showChildren;
							this.setState({showChildren: bool});
						}
					}
					isChildrenOpen={this.state.showChildren}
					value={this.props.data.field_error_name ? "" : this.props.data['name']}
					editable={writePrivileges && (this.props.data.id < 0 || this.props.data.rename === true) }
					onEdited={writePrivileges ? this.cellEdited : undefined}/>,
			description:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0
					}}
					width={this.props.columnWidthMap['description']}
					name="description"
					value={this.props.data['description']}
					editable={writePrivileges}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					textInputProps={{
						maxChars: 250
					}}
					 />,
			quantity:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0
					}}
					validation={['numeric']}
					width={this.props.columnWidthMap['quantity']}
					name="quantity"
					value={""}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={false}
					/>,
			unit:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0
					}}
					width={this.props.columnWidthMap['unit']}
					name="unit"
					value={""}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={false}
						/>,
			unit_cost:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						textAlign: "right"
					}}
					validation={['numeric']}
					width={this.props.columnWidthMap['unit_cost']}
					name="unit_cost"
					value={this.props.data.unit_cost}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={false}
					 />,
			selling_price:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						textAlign: "right"
					}}
					validation={['numeric']}
					width={this.props.columnWidthMap['selling_price']}
					name="selling_price"
					value={this.props.data.selling_price}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={false}
					 />,
			vat:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0
					}}
					width={this.props.columnWidthMap['vat']}
					name="vat"
					value={""}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={false}
					/>,
			sum:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['sum']}
					name="sum"
					value={this.props.data.sum}
					editable={false}	 
					/>,
			margin:
				<TextInputCell
					listCellType={CurrencyListCell}
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0,
						textAlign: "right"
					}}
					width={this.props.columnWidthMap['margin']}
					name="margin"
					value={this.props.data.margin}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={false}
					 />,
			worktype:
				<TextInputCell
					listCellProps={{
						inEditMode: this.props.data.id < 0 && this.props.data.parentId != 0
					}}
					width={this.props.columnWidthMap['worktype']}
					name="worktype"
					value={""}
					onEdited={writePrivileges ? this.cellEdited : undefined}
					editable={false}
					/>,
			show_in_quote_print:
				<ListCell
					width={this.props.columnWidthMap.show_in_quote_print}
					name="show_in_quote_print"
					onlyDisplay >
					<div className="cpq-list-switch-container">
						<Switch
							color="primary"
							disableRipple={true}
							onChange={() => this.showHidePrint()}
							checked={this.props.data.hidden_for_print == 0 || this.props.data.id < 0}
							disabled={this.props.data.id < 0}
						/>
					</div>
				</ListCell>
		}

		let children = this.props.children;

		if(children.length > 0) {
			children.sort((a, b) => parseInt(a.data.order_id) - parseInt(b.data.order_id));
		}

		const childRows = this.createChildren(children, CPQListRow);

		//Parent rows render return
		return (
			<div className="listElement mainLevel">
				<div style={{height: "44px", lineHeight: "44px"}} className={className + "parent"} >
					<FocusGroup 
						ref={this.focusGroup} 
						onCtrlS={() => {
							this.props.data.id < 0 ? this.saveNewRow() : this.cellEdited();
						}}
						focusOnInit={this.props.data.id < 0}
						columnOrder={this.props.columnOrder}>
						{this.props.columnOrder.map(columnName => {
							const cell = cells[columnName];
							
							return columnName === "expand" || columnName === "checked" ? cell : React.cloneElement(cell, {
								listCellProps: { ...(cell.props.hasOwnProperty("listCellProps") ? cell.props.listCellProps : {}), showErrorBorder: this.props.invalids && this.props.invalids.indexOf(columnName) > -1 }
							});
						})}
					</FocusGroup>
				</div>
				{ this.state.showChildren ?
				<React.Fragment>
				<div className={className + "childsheaderdiv"} style={{lineHeight: "22px", display: "flex", height: "30px"}}>
					{this.props.columnOrder.map(columnName => {
						const headerClassName = columnName == "name" ? "childsheadernamecell" : "childsheader";
						return (
							<div style={{width: this.props.columnWidthMap[columnName], flex: this.props.columnWidthMap[columnName] + ' 1 0'}}>
								<span className={headerClassName}>{childHeaders[columnName]}</span>
							</div>
						)
					})}
				</div>
				{childRows}
				<div className={className + "childsfooterdiv"} style={{lineHeight: "44px", display: "flex", height: "44px"}}>
					<div className="addButtonsDiv">
						<Button variant="text" aria-owns={this.props.data.anchorEl ? 'add-new-menu' : undefined} aria-haspopup="true" className={`action-button ${!writePrivileges ? 'disabled' : ''}`} onClick={(e) => writePrivileges && this.handleAddNew(e)}>
							<AddIcon className="add-icon" />
							{this.tr("Add new")}
						</Button>
						<Menu
						id="add-new-menu"
						anchorEl={this.props.data.anchorEl ? this.props.data.anchorEl : null}
						open={Boolean(this.props.data.anchorEl)}
						onClose={this.handleClose}
						>
							<MenuItem onClick={()=> {
								this.setData({ anchorEl: null });
								this.props.rowProps.addItemRow(this.props.data.id);}}>{this.tr("Item")}</MenuItem>
							<MenuItem onClick={()=> {
								this.setData({ anchorEl: null });
								this.props.rowProps.addProductRow(this.props.data.id);}}>{this.tr("Product")}</MenuItem>
							<MenuItem onClick={()=> {
								this.setData({ anchorEl: null });
								this.props.rowProps.addDescriptionRow(this.props.data.id);}}>{this.tr("Description")}</MenuItem>
						</Menu>
					</div>
				</div>
				</React.Fragment> 
				: undefined}
			</div>
		);
	}
}

//Main class for all rows that decide wich row will be used
class CPQListRow extends TaimerComponent {

    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "list/rows/CPQListRow");
        this.list = React.createRef();
        this.focusGroup = React.createRef();
        this.defaultDescriptionRow = {
            id: -1,
            name: "",
			description: "",
			hidden_for_print: 0,
            parentId: 0
		}
    }

    editRow = (name, value) => {
        const { rowProps: {editRow}, data: { id } } = this.props;
        editRow(name, value, id);
    };

    deleteRow = (target, rowId) => {
        const { rowProps: {deleteRow, descriptionRows}, data: { id } } = this.props;
        deleteRow(target, (rowId ? rowId : id));
    };    

	//Main class render
    render = () => {

		const { data, rowProps:{descriptionRows} } = this.props;
		const commonProps = {
			...this.props,
			key: data.id,
			editRow: this.editRow,
			deleteRow: this.deleteRow,
			enqueueSnackbar: this.props.enqueueSnackbar
		}
		
		//Main class render return
        return (
            <React.Fragment>
                {/* Parent */}
                {data.type == '0' ? <ParentRow 
					{...commonProps}
					disableInitialFocus
                    /> : undefined}
                {/* ItemRow */}
                {data.type == '1' ? <ItemRow 
                    {...commonProps}
					disableInitialFocus
					/> : undefined}
				{/* ProductRow */}
				{data.type == '2' ? <ProductRow
					{...commonProps}
					disableInitialFocus
					/> : undefined}
				{/* DescriptionRow */}
				{data.type == '3' ? <DescriptionRow
					{...commonProps}
					disableInitialFocus 
					/> : undefined}
            </React.Fragment>
        )
    }
}

export default withSnackbar(withStyles(styles)(CPQListRow));
