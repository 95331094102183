import React from 'react';
import TaimerComponent from '../../TaimerComponent';

import { SettingsContext } from '../../SettingsContext';

import './AccountingAccounts.scss';

import DataHandler from "../../general/DataHandler";

import SettingRow from "../../list/rows/SettingRow";
//import List from "../../list/List";
import { SettingsList as List } from "../../list/lists/SettingsList";
import ListCell from "../../list/ListCell";
import TextInputCell from "../../list/cells/TextInputCell";

import Button from '@mui/material/Button';
import { withSnackbar } from 'notistack';

import TrashCanIcon from "@mui/icons-material/Delete";

import ConfirmationDialog from "./../dialogs/ConfirmationDialog";

class TermsOfPaymentRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell 
                className="noBg pointer"
                permanentEditMode={true}
                onClick={() => this.delete(this.state.data)}
                width={this.props.columnWidthMap['delete']}>
                <TrashCanIcon />
            </ListCell>,
            name: <TextInputCell
                width={this.props.columnWidthMap['name']}
                name="name"
                value={this.state.data['name']}
                onEdited={this.editAndSave}
            />,
            termsofpayment_id: <TextInputCell
                width={this.props.columnWidthMap['termsofpayment_id']}
                name="termsofpayment_id"
                value={this.state.data['termsofpayment_id']}
                onEdited={this.editAndSave}
            />,
        };    

        return <div className="listElement row flex" style={{height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx}}>{this.props.columnOrder.map(columnName => cells[columnName]) }</div>;
    }
}

class AccountingTermsOfPayments extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/api-settings/AccountingTermsOfPayments");

        this.state = {
            termsofpayment: []
        }

        this.columnConfig = {
            resizeable: false,
            moveable: false,
            showMenu: false,
            showResizeMarker: false
        };

        this.list = React.createRef();

        this.dialogs = {
            confirmation: ConfirmationDialog
        };
    }

    componentDidMount () {
        super.componentDidMount();
        this.getData();
    }

    getData = () => {
        DataHandler.get({url: `accounting/termsofpayments/${this.props.company}/${this.props.integration}`}).done(response => {
            this.setState({termsofpayment: response});
        });
    }

    openDialog = (name) => {
        this.setState({currentDialog: name});
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }

    confirmDialog = (saveFunc) => {
        saveFunc();
        this.closeDialog();
    }

    render() {
        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;

        return (
            <div className="accounting-list">
                <Button className="green" onMouseUp={() => this.list.current.addNewRow()} size="large">{this.tr("Add terms of payment")}</Button>
            
                <List
                    innerRef={this.list}
                    className="settingsList integrationList"
                    height="auto"
                    rowHeight={SettingRow.rowHeight}
                    newRow={{
                        id: -1,
                        name: "",
                        termsofpayment_id: "",
                    }}
                    listRowType={TermsOfPaymentRow}
                    columns={[
                        {width: 55, name: "delete", header: "", ...this.columnConfig},
                        {width: 350, name: "name", header: this.tr("Terms of payment name"), ...this.columnConfig},
                        {width: 350, name: "termsofpayment_id", header: this.tr("Terms of payment ID"), ...this.columnConfig},
                    ]}
                    rowProps={{
                        onCreate: data => {
                            DataHandler.post({url: `accounting/termsofpayments/${this.props.company}/${this.props.integration}`}, {data: JSON.stringify(data)}).done(response => {
                                this.getData();
                            });
                        },
                        onUpdate: data => {
                            DataHandler.put({url: `accounting/termsofpayments/${this.props.company}`}, {data: JSON.stringify(data)}).done(response => {
                                this.getData();
                            });
                        },
                        onDelete: data => {
                            if (Number(data.id) < 0) {
                                this.list.current.removeRow(data.id);
                                return;
                            }
                            data.deleted = "1";
                            this.setState({
                                dialogData: {
                                    saveFunc: () => DataHandler.delete({url: `accounting/termsofpayments/${this.props.company}`}, {data: JSON.stringify(data)}).done(response => {
                                        this.getData();
                                    }),
                                    text: this.tr("Do you want to delete terms of payment") + ": " + data.name + "?" 
                                }
                            });
                            this.openDialog('confirmation');
                        }
                    }}
                    data={this.state.termsofpayment}
                />

                {Dialog && <Dialog
                    open
                    company={this.props.company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
            </div>
        )
    }
}

export default withSnackbar(AccountingTermsOfPayments);