import React from "react";

/* css */
import "./InsightChart.css";

/* others */
import _ from "lodash";
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from "../../SettingsContext";
import ContentBlock from "../../general/ContentBlock";
import InsightSlider from "./InsightSlider";

import cn from "classnames";
import { hexColorWithOpacity, stringToColour } from "../../helpers";
import InsightChartOptions from "./InsightChartOptions";
import colors from "../../colors";

// const colors = [
//   "#c88ffb",
//   "#f9669e",
//   "#2d9ff7",
//   "#50e3c2",
//   "#4c4ba8",
//   "#898ac4",
//   "#fd959d",
//   "#42b677",
//   "#f5a623",
//   "#ff5722"
// ];

const barColors = ["#f7548f", "#2d9ff7", "#c88ffb", "#4c4ba8", colors.greenish_cyan];

class InsightChart extends TaimerComponent {
  static contextType = SettingsContext;

  static defaultProps = {
    width: 3,
    visible: true,
    sliderProps: {},
  };

  constructor(props, context) {
    super(props, context, "dashboard/insights/InsightChart");

    this.state = {
      sliderTitle: null,
      hiddenIndexes: [],
    };
  }

  showSliderForRow = (type, month) => {
    const { showSlider } = this.props;

    if (showSlider) {
      showSlider(type, month);
      return;
    }

    if (showSlider === false)
      return;

    if (month != null) {
      const sliderData = type.set.data[month];

      let sliderTitle = type.label
        ? `${type.label} - ${this.props.data.labels[month]}`
        : this.props.data.labels[month];

      if (this.props.titleOnSlider) {
        sliderTitle = this.props.title
          ? `${this.props.title} - ${this.props.data.labels[month]}`
          : this.props.data.labels[month];
      }

      this.setState({
        sliderTitle,
        sliderData,
        sliderSum: type.data[month],
      });
    } else {
      const sliderTitle = `${type.label}`;
      const sliderData = _.flatten(type.set.data);
      this.setState({
        sliderTitle,
        sliderData,
        sliderSum: _.sum(type.data),
      });
    }
  };

  onCloseSlider = () => {
    this.setState({ sliderData: false });
  };

  _toggleHidden = (index) => {
    const hiddenIndexes = [...this.state.hiddenIndexes];
    const foundIndex = hiddenIndexes.indexOf(index);
    foundIndex == -1
      ? hiddenIndexes.push(index)
      : hiddenIndexes.splice(foundIndex, 1);
    this.setState({ hiddenIndexes });
  };

  render() {
    const {
      title,
      sliderTable,
      sliderColumns,
      className,
      style,
      data,
      chartType: ChartType,
      tableType: TableType,
      tableProps,
      chartProps,
      currency,
      lowOpacityChart = true,
      showSlider,
      ...props
    } = this.props;
    const { sliderTitle, sliderData, sliderSum, hiddenIndexes } = this.state;

    const datasets = [];

    if (data && data.sets) {
      let i = 0;
      // bug in react-chartjs-2 leads in to crash if similar labels are found in the data
      let addedLabels = {};
      for (const set of data.sets) {
        const colors = set.colors || {
          backgroundColor: barColors[i] || stringToColour(set.label || ""),
          borderColor: barColors[i] || stringToColour(set.label || ""),
        };
        let label = set.label;
        if (addedLabels[label]) {
          addedLabels[label] += 1;
          label = `${label} (${addedLabels[label]})`
      } else {
          addedLabels = {
              ...addedLabels,
              [label]: 1
          }
      }
        datasets.push({
          set,
          ...set,
          backgroundColor: lowOpacityChart
            ? hexColorWithOpacity(colors.backgroundColor)
            : colors.backgroundColor,
          borderColor: colors.borderColor,
          fill: colors.fill,
          pointBackgroundColor: colors.pointBackgroundColor,
          lineTension: 0,
          label,
          data: set.values,
          hidden: hiddenIndexes.indexOf(i) != -1,
        });

        i++;
      }
    }

    return (
      <div className={className} style={style}>
        {!showSlider && (
          <InsightSlider
            open={!!sliderData}
            label={sliderTitle}
            onClose={this.onCloseSlider}
            data={sliderData || []}
            sum={sliderSum}
            table={sliderTable}
            columns={sliderColumns}
            currency={currency}
            {...this.props.sliderProps}
          />
        )}

        <ContentBlock buttonTitle={title} {...props}>
          <div
            className={cn(
              "insights-chart-split",
              TableType && "insight-chart-with-table"
            )}
          >
            <div className={cn("insights-chart", ChartType?.classNameForWrapper)}>
              {ChartType && (
                <ChartType
                  {...chartProps}
                  toggleHidden={this._toggleHidden}
                  hiddenIndexes={hiddenIndexes}
                  labels={data ? data.labels : []}
                  options={InsightChartOptions}
                  datasets={datasets}
                  currency={currency}
                  onClick={this.showSliderForRow}
                />
              )}
            </div>
            {TableType && (
              <div className="table-container">
                <div className="insights-table">
                  <TableType
                    labels={data ? data.labels : []}
                    currency={currency}
                    datasets={datasets}
                    onClickRow={showSlider !== false ? this.showSliderForRow : undefined}
                    {...tableProps}
                  />
                </div>
              </div>
            )}
          </div>
        </ContentBlock>
      </div>
    );
  }
}

export default InsightChart;
