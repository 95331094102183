import React from 'react';
import UpgradeVersionView from './UpgradeVersionView';
import styles from './UpgradeVersionPlaceholder.module.scss';

const UpgradeVersionPlaceholder = () => {
    return (
        <div className={styles.upgradeVersionPlaceholder}>
            <UpgradeVersionView />
        </div>
    );
};

export default UpgradeVersionPlaceholder;
