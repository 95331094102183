import React, { Component } from 'react';
import ProfitLossTableRow from './ProfitLossTableRow';
import ProfitLossTableGroup from './ProfitLossTableGroup';
import TaimerComponent from '../TaimerComponent';
import _ from 'lodash';
import './ProfitLossTable.css';
import cn from 'classnames';


class ProfitLossTable extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "charts/ProfitLossTable");

        this.state = {
            hiddenCharts: [],
        }
    }

    render() {
        const { labels, charts, data, sets, totals, cumulative, currency, currencyFormatter, chartGroups, openSlider, hiddenCharts } = this.props;

        const keys = _.keys(totals);

        return <div className="grid-block profit-loss-table">
            <div style={{ gridTemplateColumns: `200px repeat(${Math.max(12, keys.length)}, 1fr) 1fr` }}>
                <div className="column column-first"></div>
                {keys.map((x, i) =>
                    <div key={x} className={cn("column header column-month", i % 2 == 0 && "column-month-alt")}>
                        {labels[x]}
                    </div>)}
                <div className="column header column-total">
                    {this.tr("Total")}
                </div>

                <ProfitLossTableRow
                    className="row-summary"
                    label={this.tr("Profit & Loss")}
                    labels={labels}
                    keys={keys}
                    values={totals}
                    currency={currency}
                    currencyFormatter={currencyFormatter}
                    openSlider={openSlider} />

                <ProfitLossTableRow
                    className="row-summary"
                    label={this.tr("Cumulative")}
                    labels={labels}
                    keys={keys}
                    values={cumulative}
                    currency={currency}
                    currencyFormatter={currencyFormatter}
                    openSlider={openSlider}
                    lastValueIsTotal />

                {
                    chartGroups.map(group => {
                        if (!group.name || group.items.length === 0)
                            return null;

                        const sums = {};

                        keys.map(k => sums[k] = 0);

                        const items = {};
                        let numCharts = 0;

                        group.items.map((id) => {
                            const chart = charts[id];

                            if (!chart || hiddenCharts.indexOf(id) > -1)
                                return;

                            _.map(sets[id], (v, k) => sums[k] += v);

                            items[id] = {
                                chart,
                                data: data[id],
                                values: sets[id],
                            };

                            numCharts++;
                        });

                        return numCharts > 0 && <ProfitLossTableGroup
                            hiddenCharts={hiddenCharts}
                            id={group.id}
                            key={group.id}
                            label={group.name}
                            labels={labels}
                            values={sums}
                            currency={currency}
                            currencyFormatter={currencyFormatter}
                            groups={items}
                            keys={keys}
                            data={data}
                            sets={sets}
                            openSlider={openSlider} />

                    })
                }
            </div>
        </div>
    }
}



export default ProfitLossTable;