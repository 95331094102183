import React from 'react';
import { Checkbox, FormLabel, FormControl, FormControlLabel } from '@mui/material';

class CustomFieldRadio extends React.Component {
    static defaultProps = {
        disabled: false,
        error: false,
    };

    checkboxChange = (e) => {
        const { value } = this.props;
        const { target: { value: selected }} = e;

        let values = value ? value.split(",") : [];
        
        if (values.indexOf(selected) > -1) {
            values = values.filter(x => x != selected)
        } else {
            values.push(selected);
        }

        this.props.onChange(this.props.name, values.join(","));
    }

    render() {
        const { name, value, error, disabled, validate, hideNoValueError, field: {
            name: label, required, options
        } } = this.props;

        const values = value ? value.split(",") : [];
        const novalue = values.length === 0;

        return <FormControl component="fieldset" error={!disabled && (error || (required && validate && novalue && !hideNoValueError))}>
            <FormLabel component="legend">{!required ? label : label +" *" }</FormLabel>

            {options.map(o => (!o.deleted || values.indexOf(o.id) > -1) && <FormControlLabel 
                key={o.id} 
                value={o.id}
                checked={values.indexOf(o.id) > -1}
                control={<Checkbox disabled={disabled} />}
                onChange={this.checkboxChange}
                label={o.name} />)}

        </FormControl>
    }
}

export default CustomFieldRadio;

/**/