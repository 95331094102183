import React, { ReactChild } from 'react';
import { Tooltip } from '@mui/material';
import styles from './UserAvatarRow.module.scss';
import TaimerAvatar from './TaimerAvatar';
import NavIcons from '../navigation/NavIcons';

interface Props {
    items: any[];
    tooltip: ((items: any[]) => ReactChild) | string;
    onClick: () => void;
    useAvatars?: boolean;
}

const UserAvatarRow = (props: Props) => {
    const { items, tooltip, onClick, useAvatars } = props;
    const shownItems = items.slice(0, 3);
    const restAmount = items.length - shownItems.length;
    return (
        <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} arrow placement="right" title={typeof tooltip == 'function' ? tooltip(items) : tooltip}>
            <div className={styles.userAvatarRow} onClick={onClick}>
                {shownItems.length == 0 ? (
                    <div className={styles.emptyAvatar}>
                        <NavIcons.contacts_new />
                    </div>
                ) : (
                    shownItems.map((item) => {
                        return <TaimerAvatar className={styles.userAvatar} key={item.id} name={item.label} color={item.color} id={useAvatars ? item.users_id : ''} noavatar={!useAvatars} title="" />;
                    })
                )}
                {restAmount > 0 && <TaimerAvatar className={`${styles.userAvatar} ${styles.rest}`} key="res" overrideText={`+${restAmount}`} noavatar title="" />}
            </div>
        </Tooltip>
    );
};

export default UserAvatarRow;
