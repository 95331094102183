import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import { SettingsContext } from '../../SettingsContext';
import DataHandler from '../../general/DataHandler';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import TextInputCell from '../../list/cells/TextInputCell';
// import './ProfessionalTitlesMembers.css';

class ProfessionalTitlesMembers extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "settings/components/ProfessionalTitlesMembers");

        this.state = {
            protitles: [],
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.refreshProtitles();
    }

    refreshProtitles = () => {
        const { company } = this.props;
        // const { userObject: { companies_id } } = this.context;

        DataHandler.get({ url: `settings/company/${company}/protitles`, members: 1 }).then(protitles => {
            this.setState({ protitles });
        });
    }

    generateOptions = (row, all) => {
        const { users, groups } = this.props;

        if (!row.users)
            row.users = [];

        const filteredUsers = users.filter(e => row.users.find(ee => ee === e.id) === undefined);

        return filteredUsers.map(el => {
            el['label'] = el.label ? el.label : (el.name || "");
            return el;
        });
    }

    setProtitle(user, title) {
        DataHandler.post({url: `users/${user}/protitles`}, {protitle_groups_id: title}).done(this.refreshProtitles);
    }

    removeGroup(title) {
        const { userObject: { companies_id } } = this.context;

        DataHandler.delete({url: `settings/company/${companies_id}/protitles/${title}`}).then( () => {
            this.refreshProtitles();
        } );    
    }

    updateGroup(title, data) {
        const { userObject: { companies_id } } = this.context;

        DataHandler.put({url: `settings/company/${companies_id}/protitles/${title}`}, data).then( () => {
            this.refreshProtitles();
        } );    
    }

    render() {
        const { users } = this.props;
        const { protitles } = this.state;
        const { tr } = this;

        const allUsers = protitles.map(a => a.users).flat(1);


        return <div id="teamgroup-settings">
            <h3>{tr("Professional Titles")}</h3>

            {/* <Button className="green btn add" size="large" onClick={() => { this.addGroup() }}>{tr("Add new team")}</Button> */}

            <div className="user-count">
                {protitles.length} {this.tr('groups')}
            </div>

            <div id="teamgroup-wrapper">
                {protitles.length > 0 && <div className="header">
                    <div className="column delete"></div>
                    <div className="column group">{tr("Title")}</div>
                    <div className="column users">{tr("Group members")}</div>
                </div>}
                <div className="content">
                    {protitles.map(row => (
                        <div className="row">
                            <div className="column delete">
                                {!row.dynamic && <DeleteIcon
                                    fontSize="small"
                                    onClick={() => this.removeGroup(row.id)}
                                />}
                            </div>
                            <div className="column group">
                                <TextInputCell
                                    ref={this.nameInput}
                                    name="name"
                                    value={row.name}
                                    type={row.type}
                                    onEdited={(name, value) => {
                                        this.updateGroup(row.id, {name: value});
                                    }}
                                />
                            </div>
                            <div className="column users">
                                {row.users && row.users.map(u => {
                                    let found = users.find(elem => { return elem.id == u });
                                    if (found)
                                        return <Chip id={"g_" + found.id} key={"g_" + found.id} label={found.label} className="square" onDelete={() => this.setProtitle(found.id, 0)} />
                                })}

                                <Select
                                    onChange={(value) => {
                                        this.setProtitle(value.id, row.id);
                                    }}
                                    value={null}
                                    isSearchable={true}
                                    isMulti={false}
                                    placeholder={this.tr("Select...")}
                                    options={this.generateOptions(row)}
                                    className="drop"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    }
}

export default ProfessionalTitlesMembers;