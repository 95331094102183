import React from "react";
import TaimerComponent from "../../TaimerComponent";
/* material ui */
import { Button } from "@mui/material";
import { ReactComponent as OverlayImage } from "./ProductList.svg";

class ProductCatalogListOverlay extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/overlays/ProductCatalogListOverlay");
  }

  render() {
    const { functions: { hasPrivilege }} = this.context;
    return (
      <div className="list-overlay">
        <OverlayImage style={{ width: "auto", height: "260px" }} />
        <div className="text">{this.tr("No catalogs found.")}</div>
        <Button disabled={!hasPrivilege("products", "write")} color="primary" onClick={() => this.props.addCatalog()}>
          {this.tr("Add your first catalog").toUpperCase()}
        </Button>
      </div>
    );
  }
}

export default ProductCatalogListOverlay;
