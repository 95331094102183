import React from 'react';
import InsightChart from "../../InsightChart";
import StackedCurrencyChart from '../../StackedCurrencyChart';
import InsightChartTable from '../../InsightChartTable';

const PipelineByCloseDate = props => {
    const {tr, data, months, ...otherProps} = props;
    return (
        <InsightChart
        {...otherProps}
          title={tr("Pipeline by Close Date")}
          data={{
            sets: [
              {
                id: 'overdue',
                label: tr("Overdue"),
                values: data.overdue,
                data: [],
                // data: data.slider.overdue,
                colors: {
                  backgroundColor: "#f9669e",
                  borderColor: "#f9669e"
                }
              },
              {
                id: 'due',
                label: tr("Due"),
                values: data.due,
                data: [],
                // data: data.slider.due,
                colors: {
                  backgroundColor: "#2d9ff7",
                  borderColor: "#2d9ff7"
                }
              },
            ],
            labels: months
          }}
          sliderColumns={["project", "account", "user", "value", "closing_date"]}
          chartType={StackedCurrencyChart}
          tableType={InsightChartTable}
          lowOpacityChart={true}
          chartProps={{
            stacked: true,
            xStacked: true,
            displayLegend: true
          }}
          tableProps={{
            // displayByLabels: true,
            displayFooter: true,
            style: {
              maxHeight: 9999
            }
          }}
        />
    );
}

export default PipelineByCloseDate;