import FileSaver from 'file-saver';
import React from 'react';
import Utils from "./../../general/Utils.js";
import List from "./../List";

/* css */
import "./ProductList.css";

/* material-ui */
import CloudDownload from '@mui/icons-material/CloudDownload';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

/* others */
import DialogContentText from '@mui/material/DialogContentText';
import TaimerComponent from "../../TaimerComponent";
import AdvancedSearch from "../../search/AdvancedSearch";
import CreateBobOptions from "../CreateBobOptions";
import AutoCompleteCell from "../cells/AutoCompleteCell";
import ProductListRow from "../rows/ProductListRow";
import DataHandler from "./../../general/DataHandler";
import OutlinedField from "./../../general/OutlinedField";
import TreeOption from "./../TreeOption";
//import ProductListOverlay from "../overlays/ProductListOverlay";
import { AddCircleOutline, Archive, Business, Edit, Link, MoreHoriz, ToggleOn } from '@mui/icons-material';
import { MenuList, Popover } from '@mui/material';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import colors from '../../colors.js';
import MassDialog from '../../dialogs/mass_operations/CoreDialog';
import PageTopSection from '../../general/PageTopSection';
import VersionContentManager from '../../general/VersionContentManager.js';
import { ColumnHeaderButton } from '../ListHeader';
import ProductCatalogModalProvider from '../dialogs/ProductCatalogModalProvider.jsx';
import { getMultiDimensionHeaders } from './../..//invoices/helpers';
import { SettingsContext } from './../../SettingsContext';
import ProductSliderTabs from './ProductSliderTabs.jsx';

import ProductListOverlay from "../overlays/ProductListOverlay";

class ProductList extends TaimerComponent {
    static contextType = SettingsContext;

    static defaultProps = {
        perpage: 30,
        customerId: false,
        projectDefaults: {},
        stickySearchKey: "product_list",
    };

    constructor(props, context) {
        super(props, context, "list/lists/ProductList");
        const { tr } = this;
        this.productSliderTabsData = [
            {
              label: tr("Product Info"),
              key: "product_info",
              action: () => this._setProductTab("product_info")
            },
            {
              label: tr("Accounting Information"),
              key: "product_accounting",
              action: () => this._setProductTab("product_accounting")
            },
            {
              label: tr("Supplier Details"),
              key: "supplier_details",
              action: () => this._setProductTab("supplier_details")
            },
            /* {
              label: tr("Order History"),
              key: "order_history",
              action: () => this._setProductTab("order_history")
            }, */
            {
                label: tr("Invoice History"),
                key: "invoice_history",
               action: () => this._setProductTab("invoice_history")
            },
            {
                label: tr("Project Sales"),
                key: "project_sales",
                action: () => this._setProductTab("project_sales")
            },
            {
                label: tr("Event Log"),
                key: "event_log",
                action: () => this._setProductTab("event_log")
            },
            {
                label: tr("Attachment"),
                key: "attachment",
                action: () => this._setProductTab("attachment")
            }
          ];

        this.state = {
            data: [],
            page: 1,
            pageCount: 1,
            groupscount: -1,
            productCount: 0,
            perpage: this.props.perpage,
            totals: {
                value: 0,
                margin: 0,
                tracked: 0
            },
            openOneDelete: false,
            deleteData: [],
            DataDone: false,
            groupfilter: "0",
            searchterms: undefined,
            companies: [],
            company: this.props.catalog_company || context.functions.getCompany("products", "read", false, true),
            companyCurrency: "EUR",
            addToCatalogDialogData: {
                open: false
            },
            catalogSliderData: {
                open: false
            },
            balanceWarningData: {
                open: false
            },
            balanceWarningDialogData: {
                open: false
            },
            productSliderData: false,
            productCurrentTab: "product_info",

            catalogAccountSliderData: {
                open: false
            },
            linkToAccountDialogData: {
                open: false
            },
            editCatalogDialogData: {
                open: false
            },
            archiveCatalogDialogData: {
                open: false
            },
            addProductsToCatalogMode: false,
            catalog: this.props.catalog_name ? { name: unescape(this.props.catalog_name) } : null,
            loading: true,
            aggregateValues: {},
            checkedProducts: [],
            stickySearchInitialized: false,
            has_products: true,
            filtersChanged: false,
            autoCompleteData: { catalogs: [], vatcodes: [], salesAccounts: [], dimensionHeaders: [], dimensions: [], dimensionHeaderFieldOptions: {} },
            accountingFields: [],
            productSliderTabsData: []
        };

        this.accountingAddons = ['meritaktiva', 'heeros', 'netvisor'];
        this.useAccounting = false;
        this.accountingAddons.forEach(a => {
            if (context.addons && context.addons[a]) {
                this.useAccounting = true;
            }
        });

               
        this.accountingFields = [
            { field: "sales_vatcode", name: "sales_vatcode", optionsName: "vatcodes", header: this.tr("VAT code"), width: 125, showMenu: true, resizeable: true, usedIn: ['meritaktiva', 'heeros', 'netvisor'] },
            { field: "sales_account", name: "sales_account", optionsName: "salesAccounts", header: this.tr("Sales account"), width: 125, showMenu: true, resizeable: true, usedIn: ['netvisor', 'procountor', 'emce', 'talenom', 'fennoa', 'wintime', "xero", 'heeros', 'meritaktiva', 'navcsv'] },
            { field: "dimension_item", name: "dimension_item", optionsName: "dimensions", header: this.tr("Dimension"), width: 125, showMenu: true, resizeable: true, usedIn: ['netvisor', 'talenom', 'fennoa', 'wintime', 'emce_invoicing', 'meritaktiva'] },
        ];

        this.filtersInitialValues = {
            company: this.props.catalog_company || context.functions.getCompany("products", "read", false, true),
            groupfilter: "0",
        };

        this.defaultgroupfilter = {
            children: [],
            data: {
                deleted: "0",
                id: "0",
                name: this.tr("All groups"),
                parent_id: "0"
            },
            deleted: "0",
            id: "0",
            isChild: false,
            isLastChild: false,
            label: this.tr("All groups"),
            level: 0,
            name: this.tr("All groups"),
            value: "0"
        }

        this.autoCompleteData = false;

        this.list            = React.createRef();
        this.advancedSearch  = React.createRef();

        this.getProductData           = this.getProductData.bind(this);
        this.addNewProductRow          = this.addNewProductRow.bind(this);
        this.filtersAreInInitialState = this.filtersAreInInitialState.bind(this);
        this.initializeStickySearch   = this.initializeStickySearch.bind(this);
        this.saveStickySearch         = this.saveStickySearch.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        DataHandler.get({ url: `subjects/companies/products/read`, currency: 1 }).done(companies => {
            this.setState({ companies });
        });
        this.initializeStickySearch();
        window.addEventListener("addNewProduct", this.addNewProductRow);
        this.listenReset();
        this.context.functions.setAdditionalHeaders(this.getHeaderTitles());
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("addNewProduct", this.addNewProductRow);
        this.context.functions.setAdditionalHeaders([]);
        this.unListenReset();
    }

    listenReset = () => {
        document.body.addEventListener("keyup", this._resetFilters);
    }
 
     unListenReset = () => {
        document.body.removeEventListener("keyup", this._resetFilters);
    }

    _setProductTab = productCurrentTab => {
        this.setState({ productCurrentTab });
      };
    
    getCatalogDetails = () => {
        if (!this.props.catalog_id) return;
        DataHandler.post({ url: `products/company/${this.state.company}/catalog` }, { catalogids: [this.props.catalog_id] })
        .done((data) => {
            this.setState({ catalog: (data.catalogs || [])[0], loading: false }, () => {
                this.context.functions.setAdditionalHeaders(this.getHeaderTitles());
            });
        });
    }

    getAccountingFields = () => {
        const { addons } = this.context;
        const { company } = this.state;
        const companyAddons = [];
        const accountingFields = [];

        Object.keys(addons).forEach(a => {
            if (addons[a] && addons[a].used_by_companies?.indexOf(company) > -1) {
                companyAddons.push(a);
            }
        });

        for (const field of this.accountingFields) {
            const addon = companyAddons.find(a => {
                return field.usedIn.indexOf(a) > -1;
            });
            addon && accountingFields.push(field);
        }

        return accountingFields;
    }

    addNewProductRow = () =>{
         if (this.list.current) {
            this.addProduct();
         } else {
             setTimeout(this.addNewProductRow, 100);
         }
    }

    //Mainfunction to get wanted Products 
    getProductData = (onlyIds = false, filters, stickySearch = false) => {
        this.list.current && this.list.current.setState({ isLoading: true});
        if (onlyIds) {
            const { company, searchterms, page, perpage, sortvalue, asc, groupfilter } = this.state;
            const parameters = { company, searchterms, page, perpage, sortvalue, asc, groupfilter, onlyIds, catalogid: !this.state.addProductsToCatalogMode ? (this.props.catalog_id || 0) : 0, sticky_search_key_name: this.props.stickySearchKey };
            return DataHandler.get({ url: `products`, ...parameters });
        }

       this.setState({loading: true}, () => {
            let { company, searchterms, page, perpage, sortvalue, asc, groupfilter, addProductsToCatalogMode } = this.state;
            const parameters = { company, searchterms, page, perpage, sortvalue, asc, groupfilter, onlyIds, addProductsToCatalogMode, catalogid: this.props.catalog_id || 0, sticky_search_key_name: this.props.stickySearchKey };

            if (filters) {
                Object.keys(filters).forEach(key => {
                    parameters[key] = filters[key];
                })
                searchterms = filters.searchterms;
            }

            if (!stickySearch)
                this.saveStickySearch(parameters);

            DataHandler.post({ url: `products`, ...parameters }, { searchterms }).done(async (response) => {
                response.hasProducts = response.has_products > 0;

                response.bob_leveled = Utils.treeFormatDataForList(response.flatgroups, "parent_id");
                this.autoCompleteData = response;
                if (response.bob_leveled) {
                    this.autoCompleteData.bobOptions = CreateBobOptions(this.autoCompleteData.bob_leveled);
                }

                try {
                    this.autoCompleteData.suppliers = await DataHandler.get({ url: `subjects/suppliers/${company}` });
                } catch(e) {
                    this.autoCompleteData.suppliers = [];

                    console.error("ProductList: Error when fetching suppliers.");
                }

                this.setState({...response, DataDone: true, loading: false, searchterms: parameters.searchterms });
                this.list.current && this.list.current.setState({ isLoading: false});
                try {
                    this.list.current.endPageChangeAnimation();
                } catch(e) {
                
                }
            }).fail(() => { 
                this.setState({ DataDone: true, loading: false });
                this.list.current && this.list.current.setState({ isLoading: false});
                try {
                    this.list.current.endPageChangeAnimation();
                } catch(e) {
                
                }
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.company !== this.state.company || prevState.companies !== this.state.companies) {
            let companyCurrency = null;
            this.state.companies.forEach(company => {
                if(company.id == this.state.company) {
                    companyCurrency = company.currency;
                }
            })
            if (!companyCurrency && this.state.companies.length > 0) {
                companyCurrency = this.state.companies[0].currency
            }
            this.setState({ companyCurrency: companyCurrency || "EUR" });
            if (prevState.company !== this.state.company) {
                this.getAutoCompleteData();
                this.getProductData();
            }
        }
        if (prevProps.catalog_id != this.props.catalog_id) {
            this.setState({page: 1, products: [], addProductsToCatalogMode: false, catalog: this.props.catalog_name ? { name: unescape(this.props.catalog_name)  } : null, DataDone: false, catalogSliderData: {...this.state.catalogSliderData, open: false }}, () => {
                this.context.functions.setAdditionalHeaders(this.getHeaderTitles());
                this.getProductData();
                this.getCatalogDetails();
            })
        }
        if (this.state.addProductsToCatalogMode != prevState.addProductsToCatalogMode) {
            this.context.functions.setAdditionalHeaders(this.getHeaderTitles());
        }
        if (prevState.products != this.state.products && this.state.catalogSliderData.open) {
            const foundProduct = this.state.products.find(
                (p) => p.id == this.state.catalogSliderData.product.id
              );
              if (foundProduct) {
                this.setState({
                  catalogSliderData: {
                    ...this.state.catalogSliderData,
                    data: foundProduct.catalogs,
                    product: foundProduct,
                  },
                });
              }
        }

        if (prevState.catalog != this.state.catalog && this.state.catalogAccountSliderData.open) {
            if (this.state.catalog) {
              this.setState({
                catalogAccountSliderData: {
                  ...this.state.catalogAccountSliderData,
                  data: this.state.catalog.customers,
                  catalog: this.state.catalog,
                },
              });
            }
          }
    }

    getAutoCompleteData = async () => {
        const accountingFields = this.getAccountingFields() || [];
        const accountingTabFields = accountingFields.filter(f => f.name != "sales_vatcode");
        let productSliderTabsData = this.productSliderTabsData;
        if (accountingTabFields?.length < 1) { 
            productSliderTabsData = productSliderTabsData.filter(d => d.key != "product_accounting"); // Don't show accounting tab if no accounting fields.
        }

        const requests = [this.getCatalogs(), this.getAccountingData(accountingFields)];
        const responses = await Promise.all(requests);
        let autoCompleteData = _.cloneDeep(this.state.autoCompleteData);
        responses.forEach(resp => {
            autoCompleteData = {...autoCompleteData, ...resp};
        })
        this.setState({ autoCompleteData, accountingFields, productSliderTabsData });
    }

    getCatalogs = async () => {
        const catalogs = await DataHandler.post({ url: `products/company/${this.state.company}/catalog` }, { all: 1 });
        return catalogs;
    }

    getAccountingData = async (accountingFields) => {
        if (accountingFields.length < 1){
           return {salesAccounts: [], vatcodes: [], dimensionHeaders: [], dimensions: []};
        }
        const { company } = this.state;
        const accountingData = await DataHandler.get({ url: `invoices/accounting/${company}`});

        const salesAccounts = accountingData.salesAccounts;
        salesAccounts.unshift({id: 0, value: 0, label: "", name: ""});

        const vatcodes = accountingData.vatCodes?.map(v => {
            v.value = v.value + " " + v.name;
            return v;
        });
        vatcodes.unshift({id: 0, value: 0, label: "", name: "", percentage: 0});

        const { dimensionHeaders } = getMultiDimensionHeaders({ accountingData, type: "products" });

        const dimensionHeaderFieldOptions = {};
        (dimensionHeaders || []).forEach(h => {
            const headerField = "dimension-" + h.id;
            dimensionHeaderFieldOptions[headerField] = h.options;
        })

        return {salesAccounts, vatcodes, dimensionHeaders, dimensions: accountingData.dimensions, dimensionHeaderFieldOptions};
    }

    getData(onlyIds = false, filters = false, sticky = false) {
        this.getProductData(onlyIds, filters, sticky);
        this.getCatalogDetails();
        this.getAutoCompleteData();
    }

    initializeStickySearch() {
        DataHandler.get({ url: `saved_search/sticky/${this.props.stickySearchKey}` }).done((response, _, request) => {
            if(request.status !== 200) {
                this.getData();
                return;            
            }

            delete response.company;
            delete response.addProductsToCatalogMode;

            if (this.isCatalogMode())
                response.groupfilter = this.filtersInitialValues.groupfilter;
            
            this.setState({...response, currentFilters: response.currentFilters }, this.getData(false, response, true));
        }).fail(response => {
            this.getData();

        }).always((response, _, request) => {
            this.setState({ stickySearchInitialized: true });
        });
    }


    saveStickySearch(filters) {
        const stateToSave = _.cloneDeep(filters);
        stateToSave.currentFilters = _.cloneDeep(this.state.currentFilters);

        [
            "onlyIds",
            "catalogid",
            "sticky_search_key_name",
            "company",
            "addProductsToCatalogMode"
        ].forEach(field => {
            delete stateToSave[field];
        });

        DataHandler.post({ url: `saved_search/sticky/${this.props.stickySearchKey}`, }, { search: stateToSave });
    }

    filtersAreInInitialState() {
        const initial = _.cloneDeep(this.filtersInitialValues);
        initial.company = this.props.catalog_company || this.context.functions.getCompany("products", "read", false, true);

        const filters = {
            company: this.state.company,
            groupfilter: this.state.groupfilter,
        }

        for(const key in initial) {
            initial[key] = String(initial[key]);
            filters[key] = String(filters[key]);
        }

        const freetext = this.state.searchterms ? this.state.searchterms.freetextSearchTerm : ""

        return _.isEqual(initial, filters) && freetext === "";
    }

    _resetFilters = (evt) => {
        if (!evt || evt.keyCode == '27') {
            this.advancedSearch.current.clearSearch(undefined, true);
            this.advancedSearch.current.clearSearchTextInput();

            this.setState({
                ...this.filtersInitialValues, 
                company: this.props.catalog_company || this.context.functions.getCompany("products", "read", false, true),
                searchterms: undefined,
                sortvalue: undefined,
                asc: undefined,
                page: 1
            }, this.getProductData());
        }
    }

    fetchAllIds = async () => {
		const data = await this.getProductData( true );
		return data.map(e => { return e.id });
	}

    getCheckedProducts = async () => {
        if (!this.list.current) return [];

        const allCheckedExcept = this.list.current.getAllCheckedExcept();
        let checkedProducts = [];

        if (allCheckedExcept) {
            checkedProducts = await this.fetchAllIds();
            checkedProducts = checkedProducts.filter(id => {
                return !allCheckedExcept[id]; 
            });
        } else {
            checkedProducts = this.list.current.getCheckedRows();
        }
        return checkedProducts;
    }

    resetCheckedProducts = () => {
        if (!this.list.current) return;
        this.list.current.resetCheckedRows();
    }

    //Export function
    export = async (target, fields) => {
        const { searchterms, sortvalue, asc, groupfilter, company } = this.state;
        let ids = await this.getCheckedProducts();

        if (ids.length === 0) 
            ids = await this.fetchAllIds( true );

        if (ids.length === 0) {
            this.props.enqueueSnackbar(this.tr("Nothing to export!"), {
                variant: "warning",
            });
            return;
        }

        const parameters = { ids: ids, company: company, searchterms: searchterms, sortvalue: sortvalue, asc: asc, groupfilter: groupfilter, columnNames: [], currency: this.state.companyCurrency };
        parameters.file_name = this.tr("products_list_export");

        const columnOrder = this.list.current.visibleColumnsOrder;
		_.forEach(columnOrder, column => {
			_.forEach(fields, (field, i) => {
				if(column == field.name && column != "expand" && column != "checked"){
					parameters.columnNames.push(field.header);
				}
			})
        })
        
        parameters.columnOrder = columnOrder;
        DataHandler.postArrayBuffer({ url: `products/list_export`, export: target}, parameters, true).done(response => {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            FileSaver.saveAs(blob, `${parameters.file_name}.${target}`);
            this.list.current && this.list.current.setState({ isLoading: false});
            this.resetCheckedProducts();
        });
    }

    deleteProduct = (data) => {
        data.isCatalogProduct = this.isCatalogMode();
        data.catalog = this.state.catalog ? this.state.catalog.name : "";
        DataHandler.get({ url: `products/${data.id}/cpq_parents/${data.companies_id}` }).then(response => {
            data.CPQParents = response.CPQParents;
            this.setState({ openOneDelete: true, deleteData: data });
        }).fail(err => {
            console.error(err);
        });
    }

    async deleteMultipleProducts() {
        const checkedProducts = await this.getCheckedProducts();

        DataHandler.post({ url: `products/cpqs_or_catalogs/${this.state.company}`}, {ids: checkedProducts}).then(response => {
            const data = {};
            data.isCatalogProduct = this.isCatalogMode();
            data.catalog = this.state.catalog ? this.state.catalog.name : "";
            data.showWarning = Number(response) === 1 ? true : false;
            data.products = checkedProducts.length;
            data.checkedProducts = checkedProducts;
            this.setState({ openMultipleDelete: true, deleteData: data })       
        }).fail(err => {
        });
    }

    //Function to delete one product
    dialogDeleteOne = () => {
        const data = this.state.deleteData;
        DataHandler.delete({ url: `products/deleteproduct` }, { id: data.id }).done(response => {
            const warningText = data.isCatalogProduct ? 
                            this.tr("Product \"${product}\" deleted from catalog \"${catalog}\"!", {product: data.name, catalog: data.catalog}) 
                            : this.tr("Product \"${product}\" deleted succesfully!", {product: data.name});
            this.getProductData();
            this.setState({ page: 1 });
            this.props.enqueueSnackbar(warningText, {
                variant: "success",
            });
        }).fail(response => {
            this.props.enqueueSnackbar(this.tr("Error in deleting product!"), {
                variant: "error",
            });
         });

        this.setState({ openOneDelete: false });
    }

    //Function to delete multiple products at once
    dialogDeleteMultiple = () => {
        const { company } = this.state;
        const data = this.state.deleteData;
        const checkedProducts = data.checkedProducts;

        DataHandler.post({ url: `products/deleteproducts` }, { products: checkedProducts, company: company}).done(response => {
            const warningText = data.isCatalogProduct ? 
                            this.tr("${amount} products deleted from catalog \"${catalog}\"!", {amount: checkedProducts.length, catalog: data.catalog}) 
                            : this.tr("${amount} products deleted succesfully!", {amount: checkedProducts.length});
            this.getProductData();
            this.setState({ page: 1 });
            this.props.enqueueSnackbar(warningText, {
                variant: "success",
            });
        }).fail(response => { 
            this.props.enqueueSnackbar(this.tr("Error in deleting products!"), {
                variant: "error",
            });
        });

        this.setState({ openMultipleDelete: false });
        this.resetCheckedProducts();
    }

    //Closes dialogs and cancels delete process
    dialogCancel = () => {
        this.setState({ openOneDelete: false, openMultipleDelete: false });
    }

    //Function for groupfilter to set its data
    cellEdited(name, value) {
        const data = this.state.data;
        data[name] = value;
        this.setState({ data: data });
    }


    updateUnits = (unit) => {
        this.autoCompleteData.units.push(unit);
    }

    isCatalogMode = () => {
        return this.props.catalog_id != null;
    }

    setAddProductsToCatalogMode = mode => {
        this.setState({DataDone: false, addProductsToCatalogMode: mode, page: 1, products: [] }, () => {
            this.getProductData();
        });
    }

    addSelectedProductsToCatalog = async () => {
        const checked = await this.getCheckedProducts();
        this.resetCheckedProducts();
        this.setState({DataDone: false, addProductsToCatalogMode: false, page: 1, products: [], loading: true });
        this.addToCatalog([this.state.catalog], checked);
    }

    addProduct = () => {
        if (this.isCatalogMode() && !this.state.addProductsToCatalogMode) {
            this.setAddProductsToCatalogMode(true);
            return;
        }
        const { functions: { checkPrivilege } } = this.context;
        const { company } = this.state;
        if (!checkPrivilege("products", "write", company)) return;
        this.list.current.addNewRow();
    }

    getHeaderTitles = () => {
        return !this.isCatalogMode() 
        ? 
            undefined
        : 
        [ 
            {
                label: this.tr("Catalogs"), 
                url: { module: 'products', action: 'main', selectedTab: 'catalogs' }
            }, 
            {
                label: this.state.catalog ? (this.state.catalog.name + (this.state.catalog.status == "0" ? ` (${this.tr("Archived")})` : "")) : "", 
                action: this.state.addProductsToCatalogMode ? () => this.setAddProductsToCatalogMode(false) : null
            }, 
            ...(this.state.addProductsToCatalogMode ? [{label: this.tr("Add products")}] : []) 
        ]
    }

    renderSummarySection = () => {
        const { functions: { checkPrivilege }, taimerAccount } = this.context;
        const { company } = this.state;
        const productfields = this.getProductFields();

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, { style: "currency", currency: this.state.companyCurrency }).format;
        const summaries = [
            {
                title: this.tr("Inventory Cost Value"),
                value: currencyFormatter(this.state.aggregateValues.inventoryCostSum || 0)
            },
            {
                title: this.tr("Inventory Sales Value"),
                value: currencyFormatter(this.state.aggregateValues.inventorySalesSum || 0)
            },
            {
                title: this.tr("Average Unit Cost"),
                value: currencyFormatter(this.state.aggregateValues.averageUnitCost || 0)
            },
            {
                title: this.tr("Average Selling Price"),
                value: currencyFormatter(this.state.aggregateValues.averageSellingPrice || 0)
            }
        ]

        return (
            <PageTopSection settingsButton={{
				isVisible: !(null == this.context.privileges.admin),
                title: this.tr("Settings"),
                href: this.context.functions.urlify({ module: 'settings', action: 'index', group: 'features', page: 'products' }),
				action: () => this.context.functions.updateView({ module: 'settings', action: 'index', group: 'features', page: 'products' }, false)
			}} mainButtons={[
                {
                    title: this.state.addProductsToCatalogMode ? this.tr("Add products to catalog") : (this.isCatalogMode() ? this.tr("Add products") : this.tr("ADD PRODUCT")),
                    color: this.state.addProductsToCatalogMode ? colors.deep_sky_blue : colors.greenish_cyan,
                    isVisible: checkPrivilege("products", "write", company),
                    action: this.state.addProductsToCatalogMode ? this.addSelectedProductsToCatalog : this.addProduct,
                }
            ]} additionalButtons={this.isCatalogMode() ? [] : [{
                title: this.tr("EXPORT"),
                action: () => this.export('xlsx', productfields),
                icon: <CloudDownload />
            }]} summaries={summaries} />
        );
    }

    selectTab = (e, module) => {
        if (!e.ctrlKey && !e.metaKey)
            e.preventDefault();
        else 
            return;

        this.context.functions.updateView(module);
    }

    getProductFields = () => {
        const { functions: { checkPrivilege } } = this.context;
        const { company, autoCompleteData, accountingFields } = this.state;
        const showExpandField = !this.state.addProductsToCatalogMode || (this.state.addProductsToCatalogMode && (this.state.products || []).length == 0);
        const productfields = [
            ...(showExpandField ? [{ field: "expand", name: "expand", header: "", staticIndex: 0, columnHeaderType: checkPrivilege("products", "write", company) && "roundButton", width: 50, showMenu: false, visibleInToolbar: true, resizeable: false, showResizeMarker: false, moveable: false, hideable: false }] : []),
            { field: "checked", name: "checked", header: "", columnHeaderType: "checkbox", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false, visibleInToolbar: true },
            { field: "code", name: "code", header: this.tr("Code"), width: 125, showMenu: true, resizeable: true },
            { field: "name", name: "name", header: this.tr("Name"), width: 125, showMenu: true, resizeable: true },
            { field: "product_types_id", name: "product_types_id", header: this.tr("Group"), width: 200 },
            ...(!this.isCatalogMode() && !VersionContentManager.isFeatureHidden(this.namespace, 'catalogs') ? [{ field: "catalog", name: "catalog", header: this.tr("Catalog"), width: 200, sortable: true }] : []),
            { field: "supplier", name: "supplier", header: this.tr("Supplier"), width: 125, showMenu: true, resizeable: true, visualizationType: "tree" },
            { field: "balance", name: "balance", header: this.tr("In Stock"), width: 125, showMenu: true, resizeable: true, type: "number" },
            { field: "freeBalance", name: "freeBalance", header: this.tr("Free balance"), width: 125, showMenu: true, resizeable: true,  type: "number" },
            { field: "cost_price", name: "cost_price", header: this.tr("Unit cost"), width: 125, showMenu: true, resizeable: true, type: "number" },
            { field: "income_price", name: "income_price", header: this.tr("Selling price"), width: 125, showMenu: true, resizeable: true, type: "number" },
            ...(this.context.taimerAccount.use_maventa_targeting == 1 ? [{ field: "percentage_increase", name: "percentage_increase", header: this.tr("% Increase"), width: 125, showMenu: true, resizeable: true, type: "number" }] : []),
            { field: "inventory_cost_value", name: "inventory_cost_value", header: this.tr("Inventory Cost Value"), width: 125, showMenu: true, resizeable: true, type: "number" },
            { field: "inventory_sales_value", name: "inventory_sales_value", header: this.tr("Inventory Sales Value"), width: 125, showMenu: true, resizeable: true, type: "number" },
            { field: "vat", name: "vat", header: this.tr("Vat %"), width: 125, showMenu: true, resizeable: true, type: "number" },
            { field: "discount_percent", name: "discount_percent", header: this.tr("Disc.%"), width: 125, showMenu: true, resizeable: true, type: "number" },
            { field: "unit", name: "unit", header: this.tr("Unit"), width: 125, showMenu: true, resizeable: true },
            { field: "comment", name: "comment", header: this.tr("Comment"), width: 125, showMenu: true, resizeable: true },
            ...(this.context.addons.product_integration_ids || this.context.addons.venda ? [{ field: "integration_id", name: "integration_id", header: this.tr("Integration ID"), width: 125, showMenu: true, resizeable: true }] : []),
        ];

        const dimensionHeaderFields = (autoCompleteData?.dimensionHeaders || []).map(h => {
            const name = "dimension-" + h.id;
            return { field: name, name: name, header: h.label, transl: h.label, tagTransl: h.label, width: 125, showMenu: true, resizeable: true }
        })

        return productfields.concat(accountingFields).concat(dimensionHeaderFields);
    }

    addToCatalog = (catalogs, products, company) => {
        this.closeAddToCatalog();
        if (catalogs[0].id == -1) {
            this.createNewCatalog(catalogs[0].name).done((res) => {
                const id = (res.catalog_ids || [])[0];
                const catalogWithId = {...catalogs[0], id};
                this.saveProductsToCatalog([catalogWithId], (products || []).map(p => p.id ? p.id : p), company);
            })
        } else {
            this.saveProductsToCatalog(catalogs, (products || []).map(p => p.id ? p.id : p), company);
        }
    }

    createNewCatalog = (name) => {
        return DataHandler.put(
            {
              url: `products/company/${this.state.company}/catalog`,
            },
            { catalogs: [{ name }] }
        );
    }

    saveProductsToCatalog = (catalogs, products, company) => {
        DataHandler.put({
            url: `products/company/${this.props.catalog_company || company}/catalog/products`,
        }, { catalogs: catalogs.map(c => c.id), products})
        .done((response) => {
            if (response.status === "error" && response.code !== "missing_parameter") {
                this.props.enqueueSnackbar(this.tr("Error in adding product(s): ") + (response.invalid_products > 0 ? response.invalid_products + " " : "") + this.tr(response.msg), {
                    variant: "error",
                });
            }

            if (this.isCatalogMode() && response.product_ids && response.product_ids.length > 0) {
                this.props.enqueueSnackbar(this.tr("${amount} products added to catalog: ${catalog}!", {catalog: this.state.catalog.name, amount: response.product_ids.length}), {
                    variant: "success",
                });
            }
            setTimeout(() => {
                if (this.state.addProductsToCatalogMode) {
                    this.setAddProductsToCatalogMode(false);
                } else {
                    this.getProductData();
                }
            }, 1000);
        })
        .fail((error) => {
          console.log(error);
        });
    }

    closeAddToCatalog = () => {
        this.resetCheckedProducts();
        this.setState({ addToCatalogDialogData: { ...this.state.addToCatalogDialogData, open: false } });
    }
    closeBalanceWarning = () => {
        this.setState({ balanceWarningDialogData: { ...this.state.balanceWarningData, open: false } });
    }

    openAddToCatalog = async (products) => {
        if (!products)
            products = await this.getCheckedProducts()
        this.setState({ catalogSliderData: { ...this.state.catalogSliderData, open: false }, addToCatalogDialogData: { open: true, company: this.state.company, products, onClose: this.closeAddToCatalog, onSave: this.addToCatalog } });
    }
    openBalanceWarning = (product) => {
        this.setState({ balanceWarningData: { ...this.state.balanceWarningData, open: false }, balanceWarningDialogData: { open: true, onClose: this.closeBalanceWarning, product, getProductData: this.getProductData, enqueueSnackbar: this.props.enqueueSnackbar } });
    }

    openLinkToAccountDialog = (catalog) => {
        this.setState({
            catalogMenuAnchor: null,
            catalogAccountSliderData: { ...this.state.catalogAccountSliderData, open: false },
            linkToAccountDialogData: { open: true, catalog, onClose: this.closeLinkToAccountDialog, onSave: this.linkCatalogToAccount },
        });
      };
    
      closeLinkToAccountDialog = () => {
        this.setState({ linkToAccountDialogData: { ...this.state.linkToAccountDialogData, open: false } });
      };
    
    linkCatalogToAccount = (accounts, catalog) => {
        const { enqueueSnackbar } = this.props;
        this.closeLinkToAccountDialog();
        DataHandler.put(
          {
            url: `products/company/${catalog.companies_id}/catalog`,
          },
          { catalogids: [catalog.id], customerids: accounts.map((a) => a.id) }
        ).done(() => {
            enqueueSnackbar(this.tr("Changes saved succesfully!"), {
                variant: "success",
              });
          setTimeout(() => {
            this.getCatalogDetails();
          }, 1000);
        }).fail(() => {
            enqueueSnackbar(this.tr("Saving changes failed!"), {
                variant: "error",
              });
        });
      };

    openCatalogSlider = (product) => {
        const { companyCurrency } = this.state;
        this.setState({
            catalogSliderData: { open: true, data: product.catalogs, product, getData: this.getProductData, addToCatalog: this.openAddToCatalog, currency: companyCurrency, onClose: this.closeCatalogSlider },
        });
    };
    openProductSlider = (product, tab) => {
        const { companyCurrency, accountingFields } = this.state;
        this.setState({
            productCurrentTab: tab || this.state.productCurrentTab,
            productSliderData: { open: true, product, autoCompleteData: {...this.autoCompleteData, ...this.state.autoCompleteData, accountingFields}, addToCatalog: this.openAddToCatalog, currency: companyCurrency, onClose: this.closeProductSlider},
        });
    };
    closeProductSlider = () => {
        this.setState({ productSliderData: { ...this.state.productSliderData, open: false } });
    }; 

    closeCatalogSlider = () => {
        this.setState({ catalogSliderData: { ...this.state.catalogSliderData, open: false } });
    };

    openCatalogAccountSlider = () => {
        const { catalog } = this.state;
        this.setState({
            catalogAccountSliderData: { open: true, data: catalog.customers, catalog, getData: this.getCatalogDetails, openLinkToAccountDialog: this.openLinkToAccountDialog, onClose: this.closeCatalogAccountSlider },
        });
    }

    closeCatalogAccountSlider = () => {
        this.setState({ catalogAccountSliderData: { ...this.state.catalogAccountSliderData, open: false } });
    };

    closeArchiveCatalogDialog = () => {
        this.setState({ archiveCatalogDialogData: { ...this.state.archiveCatalogDialogData, open: false } });
    }

    openArchiveCatalogDialog = catalog => {
        this.setState({ catalogMenuAnchor: null, archiveCatalogDialogData: { open: true, catalog, onClose: this.closeArchiveCatalogDialog, onSave: this.archiveCatalog } })
    }

    archiveCatalog = (catalog, newCatalog) => {
            this.closeArchiveCatalogDialog();
            const archived = { ...catalog, status: "0" };
            this.editCatalog(archived, null, newCatalog);
    };

    activateCatalog = (catalog) => {
        this.setState({catalogMenuAnchor: null}, () => {
            const activated = { ...catalog, status: "1" };
            this.editCatalog(activated);
        })
    };

    editCatalog = (catalog, callback = null, newCatalog) => {
        this.setState({ loading: true }, () => {
          const { enqueueSnackbar } = this.props;
          this.closeEditCatalogDialog();
          const switchParams = newCatalog ? { catalogids: [newCatalog.id], customerids: (catalog.customers || []).map(c => c.customer_id) } : {}
          DataHandler.put(
            {
              url: `products/company/${catalog.companies_id}/catalog`,
            },
            { catalogs: [catalog], ...switchParams }
          )
            .done((res) => {
              enqueueSnackbar(this.tr("Catalog saved succesfully!"), {
                variant: "success",
              });
              callback
                ? callback(res)
                : setTimeout(() => {
                    this.getCatalogDetails();
                  }, 1000);
            })
            .fail((error) => {
              enqueueSnackbar(this.tr("Saving catalog failed!"), {
                variant: "error",
              });
              this.setState({ loading: false });
            });
        });
    };

    openEditCatalogDialog = (catalog) => {
        this.setState({ catalogMenuAnchor: null, editCatalogDialogData: { open: true, catalog, onSave: this.editCatalog, onClose: this.closeEditCatalogDialog } });
      };
    
    closeEditCatalogDialog = () => {
        this.setState({ editCatalogDialogData: { ...this.state.editCatalogDialogData, open: false } });
    };

    saveRow = (data, callback) => {
		return DataHandler.post({url: `products/productsnew`}, data).done((response) => {
            if (callback === "updateListFromSlider") {
                this.getProductData();
                const { companyCurrency, accountingFields } = this.state;
                this.setState({
                    productSliderData: {...this.state.productSliderData, product: data, autoCompleteData: {...this.autoCompleteData, ...this.state.autoCompleteData, accountingFields}, addToCatalog: this.openAddToCatalog, currency: companyCurrency, onClose: this.closeProductSlider },
                });
            }

            if (data.id < 1) {
                this.resetCheckedProducts();
                this.props.enqueueSnackbar(this.tr("Product \"${product}\" created successfully!", {product: data.name}), {
                    variant: "success",
                });
            }
        });
	}

    render() {
        if(!this.state.stickySearchInitialized) {
            return null;
        }

        const { functions: { checkPrivilege } } = this.context;
        const { companies, company, companyCurrency, productCurrentTab, accountingFields, autoCompleteData } = this.state;

        const productfields = this.getProductFields();

        const newRow = {
            code: 0,
            name: "",
            balance: 0,
            cost_price: 0,
            income_price: 0,
            vat: 0,
            discount_percent: 0,
            unit: 0,
            comment: "",
            companies_id: company
        };

        return (
            <div className="contentBlock" id="productList">
                <div className="listControlsContainer clearfix">
                    <div className="primary header-container">
                        <div className="filters">
                            <div className="left">
                                {companies.length > 1 && <OutlinedField className="listFilterOutlinedField" disabled={this.isCatalogMode()} label={this.tr("Company")} value={company} select onChange={e => {
                                    this.resetCheckedProducts();
                                    this.setState({ company: e.target.value, groupfilter: this.filtersInitialValues.groupfilter, page: 1 });
                                    this.context.functions.setLastCompany(e.target.value);
                                    }}>
                                    {companies.map(row => (
                                        <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                    ))}
                                </OutlinedField>}                    
                                {/* <OutlinedField select label={this.tr("Options")} className="listFilterOutlinedField" shrinkLabel={false}>
                                    <MenuItem disabled={!checkPrivilege("products", "write", company)} onClick={() => { this.getCheckedProducts().length > 0 && this.setState({ openMultipleDelete: true }) }} >
                                        {this.tr("Delete")}
                                    </MenuItem>
                                </OutlinedField> */}
                                <div className="groupfilter">
                                    <AutoCompleteCell
                                        editable={true}
                                        name="groupfilter"
                                        value={this.state.groupfilter}
                                        rowHeight={20}
                                        autoCompleteData={[this.defaultgroupfilter, ...(this.autoCompleteData.bobOptions ? this.autoCompleteData.bobOptions : [])]}
                                        listCellProps={{
                                            showTooltipForOverflownText: true,
                                            inEditMode: true,
                                            width: "100%"
                                        }}
                                        components={{
                                            Option: props => {
                                                const passProps = {
                                                    isDisabled: props.isDisabled,
                                                    isFocused: props.isFocused,
                                                    isMulti: props.isMulti,
                                                    isSelected: props.isSelected
                                                };

                                                return <TreeOption {...props.data} {...passProps} onSelect={bob => {
                                                    props.setValue(bob.id);
                                                    this.resetCheckedProducts();
                                                    this.setState({ groupfilter: bob.id, page: 1 }, () => {
                                                        this.getProductData();
                                                    });

                                                    this.cellEdited("groupfilter", bob.id);
                                                }} />;
                                            }
                                        }} />
                                </div>
                                <AdvancedSearch
                                    ref={this.advancedSearch}
                                    mode={this.state.searchterms && this.state.searchterms.mode ? this.state.searchterms.mode : undefined}
                                    initialFilters={this.state.currentFilters ? this.state.currentFilters : undefined}
                                    mainConfig={this.state.searchterms && this.state.searchterms.advanced_search_criteria ? { operator: this.state.searchterms.advanced_search_criteria.operator } : undefined} 
                                    freetextLabel={this.state.searchterms ? this.state.searchterms.freetextSearchTerm : ""}
                                    alwaysShowClearFilters={!this.filtersAreInInitialState()}
                                    onClearSearch={this._resetFilters}
                                    fields={productfields.filter(f => ["expand", "checked", "product_types_id"].indexOf(f.field) === -1).map(f => { return { field: f.field, transl: f.header, tagTransl: f.tagTransl, type: f.type, visualizationType: f.visualizationType || "list" } })}
                                    noRequests={true}
                                    autoCompleteData={{
                                        supplier: [this.autoCompleteData.suppliers, "parent_id"],
                                        catalog: autoCompleteData.catalogs,
                                        sales_account: autoCompleteData.salesAccounts,
                                        sales_vatcode: autoCompleteData.vatcodes,
                                        dimension_item: autoCompleteData.dimensions,
                                        ...autoCompleteData.dimensionHeaderFieldOptions
                                    }}
                                    onSearchTrigger={(searchterms) => {
                                        if(_.isEqual(searchterms, this.state.searchterms))
                                            return;
                                            
                                        this.setState({ currentFilters: searchterms.currentFilters, page: 1 });
                                        delete searchterms.currentFilters;

                                        const update = {
                                            searchterms: searchterms
                                        }
                                        this.getProductData(false, update);
                                        this.resetCheckedProducts();
                                    }}
                                    perpage={this.props.perpage} />
                            </div>
                            {this.isCatalogMode() && this.state.catalog && !this.state.addProductsToCatalogMode && <div className="right">
                                <div onClick={this.openCatalogAccountSlider} className="linked-to">
                                    <Business className="icon" />
                                    {(this.state.catalog.customers || []).length == 1 ? `${this.tr("Linked to 1 account")} (${this.state.catalog.customers[0].customer_name})` : this.tr('Linked to ${count} accounts', {count: (this.state.catalog.customers || []).length})}
                                </div>
                                <Button onClick={(e) => this.setState({ catalogMenuAnchor: e.currentTarget })}>
                                    <MoreHoriz />
                                </Button>
                                <Popover anchorOrigin={{ vertical: 50, horizontal: 'right'}} classes={{ paper: "product-catalog-more-menu" }} onClose={() => this.setState({ catalogMenuAnchor: null })} open={Boolean(this.state.catalogMenuAnchor)} anchorEl={this.state.catalogMenuAnchor}>
                                    <MenuList>
                                        <MenuItem onClick={() => this.openEditCatalogDialog(this.state.catalog)}>
                                            <Edit />
                                            {this.tr("Rename Catalog")}
                                        </MenuItem>
                                        <MenuItem onClick={() => this.openLinkToAccountDialog(this.state.catalog)}>
                                            <Link />
                                            {this.tr("Link to Account")}
                                        </MenuItem>
                                        {this.state.catalog.status == "1" 
                                        ? <MenuItem
                                            className="delete"
                                            onClick={() => this.openArchiveCatalogDialog(this.state.catalog)}>
                                            <Archive />
                                            {this.tr("Archive")}
                                        </MenuItem> 
                                        : 
                                        <MenuItem
                                        className="activate"
                                        onClick={() => this.activateCatalog(this.state.catalog)}>
                                            <ToggleOn />
                                            {this.tr("Activate")}
                                    </MenuItem>}
                                    </MenuList>
                                </Popover>
                            </div>}
                        </div>
                    </div>
                    {this.renderSummarySection()}
                </div>
                <div>

                    {(this.state.openOneDelete || this.state.openMultipleDelete) &&
                        <MassDialog
                            onDialogClose={this.dialogCancel}
                            onDialogSave={this.state.openMultipleDelete ? this.dialogDeleteMultiple : this.dialogDeleteOne}
                            dialogType={"delete"}
                            dialogProps={{
                                wider: true,
                                onCloseClick: this.dialogCancel,
                                open: this.state.openOneDelete || this.state.openMultipleDelete,
                                close: this.dialogCancel,
                                header: `${this.state.openMultipleDelete ? this.tr("Delete products") : this.tr("Delete product")}${this.isCatalogMode() ? this.tr(" from catalog") : ""}?`,
                                warning: () => {
                                    return <DeleteDialogContent massDelete={this.state.openMultipleDelete} data={this.state.deleteData} />;
                                },
                                onConfirm: () => {
                                    this.state.openMultipleDelete ? this.dialogDeleteMultiple() : this.dialogDeleteOne();
                                }

                            }}
                        />
                    }


                    <div className="grid">
                        <div className="grid-container">
                            <List
                                ref={this.list}
                                data={this.state.products ? this.state.products.map(p => {
                                    p['_balanceEditMode'] = false;

                                    return p;
                                }) : []}
                                columns={productfields}
                                sharedData={{...this.autoCompleteData, ...this.state.autoCompleteData, accountingFields }}
                                hiddenToolbarColumns={checkPrivilege("products", "write", company) ? ["edit"] : ["edit", "delete"]}
                                additionalToolbarColumns={ this.isCatalogMode() || !checkPrivilege("products", "write", company) ? null : [
                                    { name: "addToCatalogue", header: "", columnHeaderType: "addToCatalogueButton", width: 100 },
                                ]}
                                additionalToolbarButtons={this.isCatalogMode() || !checkPrivilege("products", "write", company) || VersionContentManager.isFeatureHidden(this.namespace, 'catalogs') ? null : {
                                    addToCatalogueButton: <ColumnHeaderButton title={this.tr("Add to Catalog")} onClick={() => this.openAddToCatalog()} icon={AddCircleOutline} />,
                                }}
                                onToolbarExportClick={() => this.export('xlsx', productfields)}
                                onToolbarDeleteClick={() => this.deleteMultipleProducts()}
                                roundbutton={this.addProduct}
                                height="fitRemaining"
                                trimHeight={-20}
                                className="productList"
                                listRowType={ProductListRow}
                                rowKey="id"
                                noStateData={true}
                                enableToolbar={!this.state.addProductsToCatalogMode}
                                showNoResultsMessage={this.state.DataDone && this.state.has_products}
                                showOverlay={!this.state.has_products}
                                overlayComponent={ProductListOverlay}
                                ignoreRowPropsChange={false}
                                useAllCheckedExcept={true}
                                manualCreate={true}
                                overlayProps={{
                                    addProduct: this.addProduct
                                }}
                                newRow={newRow}
                                rowProps={{
                                    addProductsToCatalogMode: this.state.addProductsToCatalogMode,
                                    onDelete: (data) => {
                                        if (data.id < 0) {
                                            this.list.current.removeNewRow(data.id);
                                        } else {
                                            this.deleteProduct(data);
                                        }
                                    },
                                    onCreate: this.saveRow,
                                    onUpdate: this.saveRow,
                                    openCatalogSlider: this.openCatalogSlider,
                                    openProductSlider: this.openProductSlider,
                                    onAddToCatalog: this.openAddToCatalog,
                                    onBalanceWarning: this.openBalanceWarning,
                                    list: this.list.current,
                                    updateList: this.getProductData,
                                    updateUnits: this.updateUnits,
                                    productCodes: this.state.productCodes,
                                    codeError: this.tr("Product code must be unique."),
                                    currency: companyCurrency,
                                    catalog_id: this.props.catalog_id
                                }}
                                saveColumnConfig={true}
                                userListSettingsKey="product_list"
                                showPageSelector={true}
                                pageCount={this.state.pageCount}
                                totalCount={this.state.productCount}
                                perpage={this.state.perpage}
                                page={this.state.page}
                                onPerPageChange={perpage => {
                                    this.setState({ perpage: perpage, page: 1 });
                                    this.getProductData();
                                }}
                                controlPage={true}
                                onPageChange={page => {
                                    this.list.current.startPageChangeAnimation();
                                    this.setState({ page: page })
                                    this.getProductData();
                                }}
                                onSortRows={(colName, asc) => {
                                    this.setState({ sortvalue: colName, asc: asc, page: 1 });
                                    this.getProductData();
                                }}
                                useHSRightPadding
                            />
                        </div>
                    </div>
                </div>
                <ProductCatalogModalProvider 
                    editDialogData={this.state.editCatalogDialogData}
                    addToCatalogDialogData={this.state.addToCatalogDialogData}
                    balanceWarningDialogData={this.state.balanceWarningDialogData}
                    balanceWarningData={this.state.balanceWarningData}
                    linkToAccountDialogData={this.state.linkToAccountDialogData}
                    archiveCatalogDialogData={this.state.archiveCatalogDialogData}
                    catalogSliderData={this.state.catalogSliderData}
                    catalogAccountSliderData={this.state.catalogAccountSliderData}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                />
                 <ProductSliderTabs
                   productSliderData={this.state.productSliderData}
                   tabs={this.state.productSliderTabsData}
                   selected={productCurrentTab}
                   enqueueSnackbar={this.props.enqueueSnackbar}
                   saveProduct={this.saveRow}
                   getProductData={this.getProductData}
                   productCodes={this.state.productCodes}
                   tr={this.tr}
                />
            </div>
        );
    }
}

export default withSnackbar(ProductList);


class DeleteDialogContent extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, null, "list/lists/ProductList/DeleteDialogContent");
    }

    render() {
        const data = this.props.data;
        let warningText = data.isCatalogProduct ? 
                    this.tr("Are you sure you want to delete product \"${product}\" from catalog \"${catalog}\"?", {product: data.name, catalog: data.catalog}) 
                    : this.tr("Are you sure you want to delete product \"${product}\"?", {product: data.name});

        if (this.props.massDelete) 
            warningText = data.isCatalogProduct ? 
                        this.tr("Are you sure you want to delete ${amount} products from catalog \"${catalog}\"?", {amount: data.products, catalog: data.catalog}) 
                        : this.tr("Are you sure you want to delete ${amount} products?", {amount: data.products});

        return (
            !this.props.massDelete ? (
            <>
                <DialogContentText id="product-delete-dialog-warning">
                    {warningText}
                </DialogContentText>
                {((data.catalogs && data.catalogs.length > 0) || (data.CPQParents && data.CPQParents.length > 0)) &&
                        <DialogContentText id="product-delete-dialog-content">
                            {this.tr("This will also delete the product from the following CPQs and/or catalogs:")}
                            <div class="catalog-cpq-list">
                                {data.catalogs && data.catalogs.length > 0 &&
                                    <figure>
                                        <figcaption>{this.tr("Catalogs")}:</figcaption>
                                        <ul>
                                            {data.catalogs.map(catalog => (
                                                <li>{catalog.name}</li>
                                            ))}
                                        </ul>
                                    </figure>
                                }
                                {data.CPQParents && data.CPQParents.length > 0 &&
                                    <figure>
                                        <figcaption>{this.tr("CPQs")}:</figcaption>
                                        <ul>
                                            {data.CPQParents.map(cpq => (
                                                <li>{cpq.name}</li>
                                            ))}
                                        </ul>
                                    </figure>
                                }
                            </div>
                        </DialogContentText>
                }
            </>
            ) : (
                    <>
                        <DialogContentText id="product-delete-dialog-warning">
                            {warningText}
                        </DialogContentText>
                        {data.showWarning &&
                            <DialogContentText id="product-delete-dialog-content">
                                {this.tr("This will also delete the products from CPQs and/or catalogs!")}
                            </DialogContentText>
                        }
                    </>
            )
        )
    }
}
