import React from 'react';

import TaimerComponent from "../TaimerComponent";

/* local components */
import OutlinedField from "./../general/OutlinedField";
import DataHandler from './../general/DataHandler';
import CognitoHelpers from './../general/CognitoHelpers';

/*material ui */
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '../general/Link';

import { Auth } from '@aws-amplify/auth'

class ForgotPasswordView extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "login/ForgotPasswordView");

        this.state = {
            email: "",
            folder: "",
            error: false,
            emailEmpty: false,
            loading: false,
            CodeDeliveryDetails: false,
            verificationCode: "",
            passwordChanged: false,
        };

        this.validation = {
            email: React.createRef()
        }
    } 

    componentDidMount() {
        super.componentDidMount();
        this.setCustomerFolder();
    }

    sendPasswordRequest = async (e) => {
        if (!this.validation["email"].current.isValid()) {
            this.setState({ error: this.state.email ? true : false, loading: false });
            return;
        }
        this.setState({ loading: true });

        const username = this.state.email;
        const account = this.state.folder;

        const useCognito = await CognitoHelpers.isCognitoUser(username, account);

        if (useCognito > 0)
            this.sendCognitoRequest(username);
        else
            this.sendLocalRequest(username, account)
    };
    sendCognitoRequest = async (username) => {
        Auth.forgotPassword(username)
            .then(data => {
                this.setState({ ...data, loading: false, error: false })
            })
            .catch(err => {
                console.log(err)
                if (err.code === "LimitExceededException")
                    this.setState({error: "limit_exceeded", loading: false});
            });
    }

    sendLocalRequest = (username, account) => {
        DataHandler.post({ url: `onboarding/retrieve/` }, { email: username, folder: account }).done(e => {
            this.setState({ error: false });
            this.props.changePage("passwordRequested", account);
        })
        .fail(err => {
            this.setState({ error: "requestError", loading: false });
        });
    }

    changePage = (e, page) => {
        e.preventDefault();
        this.props.changePage(page);
    };

    setCustomerFolder = (folder = window.location.href.split("/")[3]) => {
        if(folder.substring(0,1) === "?") {
            folder = "";
        }
        this.setState({folder: folder});    
    }
    getErrorMessage = (error) => {
        const { tr } = this;
        if (error === "CodeMismatchException")
            return tr('Invalid verification code');
        if (error === 'InvalidPasswordException' || error === 'InvalidParameterException')
            return tr('Password does not conform to policy: Password not long enough');
        if (error === 'invalid_confirm_password')
            return tr('Passwords did not match');
        if (error === "LimitExceededException")
            return tr('Too many attempts to reset the password. Please try again after a while');
        if (error === "requestError")
            return tr("Error in requesting password")
        if (error === "AuthError")
            return tr("Verification code cannot be empty")
        if (error === true)
            return tr("Invalid email address");

        return error;
    }
    resetCognitoPassword = () => {
        const { verificationCode, email, newPassword, confirmPassword } = this.state;

        if (newPassword !== confirmPassword) {
            return this.setState({ error: "invalid_confirm_password"})
        }   

        Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
            .then(() => this.setState({ passwordChanged: true }))
            .catch(err => {
                let error;

                if (err.code)
                    error = err.code;
                else if (err.message.includes("Confirmation"))
                    error = "AuthError";
                else if (err.message.includes("Password"))
                    error = "InvalidPasswordException";
                
                console.log(err);
                this.setState({ error, loading: false });
            });
    }

    renderResetPasswordView = () => {
        const { CodeDeliveryDetails, error, newPassword, confirmPassword } = this.state;
        const { tr } = this;
        const errorMessage = this.getErrorMessage(error);

        return <>
            <h1>{tr('Reset password')}</h1>
            <p>{tr('Password reset verification code was sent to your email address ${email}', {email: CodeDeliveryDetails.Destination})}</p>
            {error &&
                <div className="error-alert">
                    <p>{errorMessage}</p>
                </div>
            }
            <OutlinedField 
                className={"login-field"}
                onKeyUp={(e) => this.setState({ verificationCode: e.target.value })} 
                ref={this.validation.email} 
                inputRef={el => this.email = el} 
                label={tr("Verification code")}
                autoFocus={true}
                autoComplete="one-time-code"
                error={error === 'invalid_code' || error === 'AuthError'}
                />

            <OutlinedField
                className={"login-field"}
                name="newPassword" 
                label={tr("New Password")}
                value={newPassword} 
                onKeyUp={(e) => this.setState({ newPassword: e.target.value })} 
                type="password" 
                fullWidth 
                autoComplete="new-password" />
            

            <OutlinedField 
                className={"login-field"}
                name="confirmPassword" 
                label={tr("Confirm Password")}
                value={confirmPassword} 
                error={error === 'invalid_confirm_password'} 
                onKeyUp={(e) => this.setState({ confirmPassword: e.target.value })} 
                type="password" 
                fullWidth 
                autoComplete="new-password" />

            <Button 
                className={"login-button"} 
                data-testid="forgot-password-cognito-submit"
                color="primary" 
                onClick={(e) => this.resetCognitoPassword(e)} 
                size="large"> 
                {tr("Submit").toUpperCase()}
            </Button>
        </>
    }
    renderPasswordResetSuccessful = () => {
        const { tr } = this;

        return <>
            <h1>{tr("Password changed successfully")}</h1>
            <p className={"link-text"}>{tr("Go back to")} <Link onClick={(e) => { this.changePage(e, "login") }}>{tr("login page")}</Link></p>
        </>
    }

    renderForgotPasswordView = () => {
        const { state, tr } = this;
        const { email, folder, error, loading } = state;
        const isOdin = window.location.href.indexOf(':8080') > -1;
        let currentFolder = window.location.href.split("/")[3];
        if(currentFolder.substring(0,1) === "?") {
            currentFolder = "";
        }
        const errorMessage = this.getErrorMessage(error);

        return (
            <React.Fragment>
                <h1>{tr("Forgot password?")}</h1>
                <p className={"forgot-password-text"}>{tr("Enter the email address associated with your account.")}</p>
                {error &&
                    <div className="error-alert">
                        <p>{errorMessage}</p>
                    </div>
                }
                <OutlinedField name="reset-username" noOnchangeValidation={true} className={"login-field email-field"} value={email} onKeyUp={(e) => this.setState({ email: e.target.value })} ref={this.validation.email} validation={["email", "empty"]} inputRef={el => this.email = el} label={tr("Email")} autoFocus={true} />
                {(currentFolder === "" || isOdin) && <OutlinedField className={"login-field clientID-field"} value={folder} onKeyUp={(e) => this.setCustomerFolder(e.target.value)} label={tr("Client ID (Optional)")} />}
                {!loading &&
                    <Button data-testid="forgot-password-submit" className={"login-button"} color="primary" onClick={(e) => this.sendPasswordRequest(e)} size="large">{tr("Submit").toUpperCase()}</Button>
                }
                {loading &&
                    <Button className={"login-button"} color="primary" size="large" disabled><CircularProgress disableShrink size={18} className={"button-progress"} /></Button>
                }
                <p className={"link-text"}>{tr("Go back to")} <Link onClick={(e) => { this.changePage(e, "login") }}>{tr("login page")}</Link></p>
            </React.Fragment>
        );
    }

    render() {
        const { CodeDeliveryDetails, passwordChanged } = this.state;
        if (passwordChanged)
            return this.renderPasswordResetSuccessful();
        else if (CodeDeliveryDetails)
            return this.renderResetPasswordView();
        else
            return this.renderForgotPasswordView();

    }
}

export default ForgotPasswordView;