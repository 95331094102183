import React from 'react';
import "./TextInput.css";
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
	input: {
        height: "80%"
	},
	error: {
		color: `${theme.palette.taimer.error} !important`
	}
});

class TextInput extends React.Component {
	static defaultProps = {
		charRegEx: undefined, 
		className: "",
		initValue: "",
		placeholder: "",
		error: false,
		focusOnMount: false,
		textAlign: "left",
		overRunNativeValue: false,
		onInputListener: () => {},
		onFocus: () => {},
		onBlur: () => {},
		onKeyDown: () => {},
		onKeyUp: () => {},
		onEnter: () => {},
		onClick: () => {},
		classNames: {
			error: "error",
			noError: ""
        },
        style: {},
        maxLength: undefined
	};


	constructor(props) {
		super(props);

		this.inputRef     = React.createRef();
		this.onInput      = this.onInput.bind(this);
		this.onKeyDown    = this.onKeyDown.bind(this);
		this.onKeyUp      = this.onKeyUp.bind(this);
		this.updateValue  = this.updateValue.bind(this);

		this.state    = { value: props.initValue };
	}


	componentDidMount() {
		if(this.props.focusOnMount) {
			this.inputRef.current.focus();
		}
	}


	onInput(event) {
		const { charRegEx } = this.props;
		const { value } = event.target;

		if((this.props.maxChars 
			&& value.length > this.props.maxChars 
			&& value.length > this.state.value.length)
			|| (charRegEx !== undefined && value.match(charRegEx))) {
				return;
		}

		event.persist();

		this.setState({
			value: value
		}, () => {
			if(typeof this.props.onInputListener == "function") {
				this.props.onInputListener(value, event);
			}
		});
	}


	setValue(value) {
		this.setState({ value: value });
	}


	getValue() {
		return this.state.value;
	}


    focus() {
		this.inputRef.current && this.inputRef.current.focus();
	}


	select() {
		this.inputRef.current && this.inputRef.current.select();
	}

	onKeyDown(event) {
		this.props.onKeyDown(event);
	}

	onKeyUp(event) {
		this.props.onKeyUp(event);
		
		if(event.keyCode === 13) {
			this.props.onEnter(event);
		}
	}

	updateValue(value) {
		this.setState({ value })
	}


	render() {
		const className = `textInput 
			${this.props.classes.input} 
			${this.props.className} 
			${this.props.error ? this.props.classes.error : ""}`;
        
        return (
			<input
				type={this.props.type || "text"}
				className={className}
				disabled={this.props.disabled}
				ref={this.inputRef}
				value={this.props.overRunNativeValue ? this.props.initValue : this.state.value}
				onChange={this.onInput}
				onKeyDown={this.onKeyDown}
				onKeyUp={this.onKeyUp}
				onFocus={this.props.onFocus}
				onBlur={this.props.onBlur}
				onClick={this.props.onClick}
				placeholder={this.props.placeholder}
                maxLength={this.props.maxLength}
				style={{ textAlign: this.props.textAlign, ...this.props.style }} />
		);
	}
}

export default withStyles(styles, {withTheme: true})(TextInput);
