import React from 'react';
import TaimerComponent from "../../TaimerComponent"

/* material ui */
import { Button } from '@mui/material';

import Taimer from "../../Taimer"
import { SettingsContext } from '../../SettingsContext';

import { ReactComponent as OverlayImage } from './BillList.svg';

class PurchaseOrderListOverlay extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, 'list/overlays/PurchaseOrderListOverlay');
    }

    render () {

        return (
            <div className="list-overlay">
                <OverlayImage style={{width: "auto", height: '260px'}} />
                <div className="text">
                    {this.props.hasPoWritePrivilege ? this.tr("Create Your First Purchase Order") : this.tr("No purchase orders") }
                </div>
                {this.props.hasPoWritePrivilege && (
                    <Button
                        color="primary"
                        onClick={() => this.context.functions.updateView({ module: 'purchaseorder', action: 'view', editMode: 1, companies_id: this.props.company, projects_id: this.props.projects_id })}
                    >
                    {this.tr("NEW PURCHASE ORDER") }
                    </Button>
                )}
             </div>
        );
    }

}

export default PurchaseOrderListOverlay;