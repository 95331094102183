import React from "react";

import { SettingsContext } from "../../SettingsContext";
import TaimerComponent from "../../TaimerComponent";
import DataHandler from "../../general/DataHandler";
import { DraggableCore, DraggableEventHandler } from 'react-draggable';

import cn from 'classnames';

import styles from './BlockContainer.module.scss';
import { DraggingState, DragInformation, DragStartInformation, LayoutBlock, ViewBlock } from "./CustomViewBase";
import { getElementOffset } from "../../helpers";

interface Props {
    layoutBlock: LayoutBlock;
    block: ViewBlock;
    blockStyles?: React.CSSProperties;

    rootContainer?: HTMLDivElement | null;

    draggingState?: DraggingState;

    onDragStart?: (layoutBlock: LayoutBlock, data: DragStartInformation) => void;
    onDrag?: (layoutBlock: LayoutBlock, data: DragInformation) => void;
    onDragEnd?: (layoutBlock: LayoutBlock, data: DragInformation) => void;
}

interface State {

}

export default class BlockContainer extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    refContent: React.RefObject<HTMLDivElement>;

    constructor(props, context) {
        super(props, context, "customview/components/BlockContainer");

        this.refContent = React.createRef();

        this.state = {
        }
    }

    componentDidMount = () => {
        const { block } = this.props;

        super.componentDidMount();

    }

    componentDidUpdate() {
        const { block } = this.props;

    }

    /** Draggig **/

    /**
     * 
     * @param e 
     * @param data 
     */
    onDrag: DraggableEventHandler = (e, data) => {
        const { layoutBlock, onDrag } = this.props;

        onDrag?.(layoutBlock, {
            x: data.x,
            y: data.y,
        });
    }

    /**
    * 
    * @param e 
    * @param data 
    */
    onStart: DraggableEventHandler = (e, data) => {
        const { layoutBlock, onDragStart } = this.props;

        const el = this.refContent.current;

        if (!el) {
            return;
        }

        const viewPort = el.getBoundingClientRect();
        const offsets = {
            offsetX: data.x - viewPort.left,
            offsetY: data.y - viewPort.top,
        }

        onDragStart?.(layoutBlock, {
            x: data.x,
            y: data.y,
            ...offsets,
            width: this.refContent.current?.clientWidth ?? 0,
            height: this.refContent.current?.clientHeight ?? 0.
        });
    }

    /**
     * 
     * @param e 
     * @param data 
     */
    onStop: DraggableEventHandler = (e, data) => {
        const { layoutBlock, onDragEnd } = this.props;

        onDragEnd?.(layoutBlock, {
            x: data.x,
            y: data.y,
        });
    }

    render() {
        const { layoutBlock, blockStyles, children, draggingState } = this.props;

        const dragStyle: React.CSSProperties = draggingState ? {
            position: 'fixed',
            left: draggingState.x - draggingState.offsetX,
            top: draggingState.y - draggingState.offsetY,
            width: draggingState.width,
            height: draggingState.height,
        } : {}

        const style: React.CSSProperties = {
            ...dragStyle,
            ...blockStyles,
        }

        return (
            <DraggableCore onStart={this.onStart} onDrag={this.onDrag} onStop={this.onStop} enableUserSelectHack handle=".block-drag-handle" cancel=".block-no-drag .no-my-day-drag">
                <div
                    className={cn(styles.root, draggingState && styles.dragging)}
                    style={style}
                    data-grid-x={!draggingState ? layoutBlock.x : undefined}
                    data-grid-y={!draggingState ? layoutBlock.y : undefined}>
                    <div ref={this.refContent} className={styles.block}>
                        {children}
                    </div>
                </div>
            </DraggableCore>
        );
    }
}  