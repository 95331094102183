import React from 'react';
import TaimerComponent from "../../TaimerComponent";
import DataHandler from '../../general/DataHandler';
import { SettingsContext } from '../../SettingsContext';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CreditCardForm from './CreditCardForm';
import { withSnackbar } from 'notistack';


class SubscriptionInfo extends TaimerComponent {
    static contextType = SettingsContext;
    
    constructor(props, context) {
        super(props, context, "settings/pages/SubscriptionInfo");

        this.state = {
            data: {},
            cardDialogOpen: false,
            cancelDialogOpen: false,
            reactivateDialogOpen: false,
            currentSubId: undefined
        }

        this.changeablePlanIds = [
            "plan_EZ5S9nCDd6d5Dh",
            "plan_EZ5RIY0O5CNw1g",
            "plan_EZ5QDk3yW2PcI8",
            "plan_EZ7LIEwFmqhIBo",
            "plan_EZ7NjGVOtAM6zG",
            "plan_EZ7RcBRGVtdQmx",
            "plan_EVcziHAObxl0pR",
            "crm-test",
            "free-test",
            "prm-test",
            "erp-test",
        ];
        
        this.subType = context.taimerAccount.stripeCustomerId ? 'subscription' : 'invoiced_subscription';
    }

    componentDidMount () {
        super.componentDidMount();
        this.fetchSubscription();
    }

    fetchSubscription = () => {
        DataHandler.request("GET", {url: `settings/${this.subType}`}).done(response => {
            this.setState({data: response})
        });
    }

    cardTokenCreated = tokenId => {
        this.setState({hideButtons: true});
        DataHandler.request("PUT", {url: `settings/subscription/creditcard`}, {tokenId: tokenId}).done(response => {
            this.setState({cardDialogOpen: false, hideButtons: false}, this.fetchSubscription);
        });
    }

    cancelSubscription = (id) => {
        const  { enqueueSnackbar } = this.props;
        
        this.setState({hideButtons: true});
        DataHandler.request("POST", {url: `settings/${this.subType}/cancel/${id}`}).done(response => {
            //this.context.mixpanel.track('Cancel Subscription');
            this.setState({cancelDialogOpen: false, currentSubId: undefined, hideButtons: false}, this.fetchSubscription);
            
            response.mail_sent && enqueueSnackbar(this.tr(`Cancel request sent to customer service`), {
                variant: "info",
            }); 
        });
    }

    reactivateSubscription = (id) => {
        this.setState({hideButtons: true});
        DataHandler.request("POST", {url: `settings/${this.subType}/reactivate/${id}`}).done(response => {
            this.setState({reactivateDialogOpen: false, currentSubId: undefined, hideButtons: false}, this.fetchSubscription);
        });
    }

    openSubscriptionDialog = (sub) => {
        sub.cancel_at != null ?
            this.setState({currentSubId: sub.id, reactivateDialogOpen: true})
        :
            this.setState({currentSubId: sub.id, cancelDialogOpen: true})
    }

    addNewSubscription = () => {
        this.props.updateView({
            module: 'onboarding',
            action: 'order',
            subId: false
        });
    }

    openOrderForm = (sub) => {
        if (this.changeablePlanIds.indexOf(sub.plan.id) == -1)
            return;

        this.props.updateView({
            module: 'onboarding',
            action: 'order',
            subId: sub.id,
        });
    }

    openCustomerPortal = () => {
        DataHandler.request("GET", {url: `settings/subscription/portal`}).done(response => {
            window.location = response;
        });
    }

    openCheckout = (product) => {
        DataHandler.request("GET", {url: `settings/subscription/checkout`, product: product}).done(response => {
            window.location = response;
        });
    }

    render() {
        const { data } = this.state;
        const { userObject, taimerAccount, addons, functions: { getEnv } } = this.context;
        const { tr } = this;
        const STRIPE_PUBLIC_KEY = getEnv('REACT_APP_STRIPE_PUBLIC') 

        return (
            <div id="subscription-settings">
                <h3>{tr("Subscriptions")}</h3>
                <p>{tr("Here you can manage your subscriptions.")}</p>
                
                {!addons.new_stripe && (
                    <div id="subscription-list">
                        <Button className="button blue" size="large" onClick={() => this.addNewSubscription()}>{tr("Buy more licences")}</Button>
                        {data && data.subscriptions && (data.subscriptions.data.length > 0) && (
                        <div className="subscription-header">
                            <div className="sub-name">{tr("Subscription")}</div>
                            <div className="sub-name">{tr("Licences")}</div>
                            {/*<div className="sub-name">{tr("Total")}</div>
                            <div className="sub-name">{tr("VAT")}</div>
                            <div className="sub-name">{tr("Total (inc. VAT)")}</div>*/}
                            <div className="sub-name">{tr("Total")}</div>
                            <div className="sub-name">{tr("Status")}</div>
                            {taimerAccount.stripeCustomerId && <div className="sub-next">{tr("Next payment")}</div>}
                            <div className="sub-name"></div>
                            <div className="sub-name"></div>
                        </div>)}
                        {data && data.subscriptions && data.subscriptions.data.length > 0 && data.subscriptions.data.map(sub => {
                            let sum = sub.quantity * sub.plan.amount / 100;
                            //discount
                            if(sub.discount && sub.discount.coupon && sub.discount.coupon.percent_off > 0) {
                                sum = sum * (sub.discount.coupon.percent_off / 100);
                            }
                            const tax = sum * (sub.tax_percent / 100);
                            const sumTaxed =  sum + tax;
                            return (
                                <div className="subscription-row">
                                    <div className="sub-name header">{sub.plan.name}</div>
                                    <div className="sub-licences">{sub.quantity}</div>
                                    {/*<div className="sub-price">{new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(sum)}</div>
                                    <div className="sub-tax">{new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(tax)}</div>
                                    <div className="sub-total">{new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(sumTaxed)}</div>*/}
                                    <div className="sub-name">{tr("Contract pricing")}</div>
                                    <div className="sub-status">{sub.cancel_at != null ? tr('Will be cancelled') + ' ' + format(new Date(sub.cancel_at * 1000), userObject.dateFormat) : tr('Active')}</div>
                                    {taimerAccount.stripeCustomerId && <div className="sub-next">{sub.cancel_at == null ? format(new Date(sub.current_period_end * 1000), userObject.dateFormat) : '-'}</div>}
                                    <div className="sub-cancel" onClick={() => this.openOrderForm(sub)}>{this.changeablePlanIds.indexOf(sub.plan.id) > -1 ? (sub.cancel_at != null ? "" : <span class="row-action blue">{tr("Change")}</span>) : ''}</div>
                                    <div className="sub-cancel" onClick={() => this.openSubscriptionDialog(sub)}>{sub.cancel_at != null ? <span class="row-action blue">{tr("Reactivate")}</span> : <span class="row-action red">{tr("Cancel")}</span>}</div>
                                </div>
                            )
                        })}
                    </div>
                )}
                    
                {taimerAccount.stripeCustomerId && !addons.new_stripe &&
                <div id="card-area" className="area">
                    <h3>{tr("Change payment card")}</h3>
                    <p>{tr("Change the payment card information you use for this subscription.")}</p>

                    {data && data.card ? (
                        (data.card.last4 != null) ?
                            <React.Fragment>
                            <div id="card-info" className="area">
                                <div className="info-row">
                                    <div className="info-cell header">{tr("Current payment card")}</div>
                                    <div className="info-cell value">{data.card.brand} / **** **** **** {data.card.last4}</div>
                                </div>

                                <div className="info-row">
                                    <div className="info-cell header">{tr("Payment card expiration")}</div>
                                    <div className="info-cell value">{data.card.expMonth} / {data.card.expYear}</div>
                                </div>
                            </div>
                            <Button className="button green" size="large" onClick={() => this.setState({cardDialogOpen: true})}>{tr("CHANGE CARD")}</Button>
                            </React.Fragment>
                        : <Button className="button green" size="large" onClick={() => this.setState({cardDialogOpen: true})}>{tr("Add card")}</Button>
                    ) : <Button className="button green" size="large" onClick={() => this.setState({cardDialogOpen: true})}>{tr("Add card")}</Button>}
                </div>}

                {taimerAccount.stripeCustomerId && addons.new_stripe &&
                <div id="card-area" className="area">
                    <h3>{tr("Test customer portal")}</h3>
                    <Button className="button green" size="large" onClick={() => this.openCustomerPortal()}>{tr("Open portal")}</Button>
                    <h3>{tr("Test checkout")}</h3>
                    <Button className="button green" size="large" onClick={() => this.openCheckout("self-service-free")}>{tr("Checkout Free")}</Button>
                    <Button className="button green" size="large" onClick={() => this.openCheckout("self-service-growth")}>{tr("Checkout Growth")}</Button>
                    <Button className="button green" size="large" onClick={() => this.openCheckout("self-service-business")}>{tr("Checkout Business")}</Button>
                    <Button className="button green" size="large" onClick={() => this.openCheckout("self-service-business-resourcing")}>{tr("Checkout Business + Resourcing")}</Button>
                    <Button className="button green" size="large" onClick={() => this.openCheckout("self-service-business-bills")}>{tr("Checkout Business + Bills")}</Button>
                    <Button className="button green" size="large" onClick={() => this.openCheckout("self-service-business-all")}>{tr("Checkout Business + All")}</Button>
                    <Button className="button green" size="large" onClick={() => this.openCheckout("self-service-enterprise")}>{tr("Checkout Enterprise")}</Button>
                </div>}

                <Dialog
                    open={this.state.cardDialogOpen}
                    onClose={() => this.setState({cardDialogOpen: false})}
                    maxWidth="sm"
                    fullWidth={true}>
                    <DialogTitle>{tr("Change payment card")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
                                <Elements locale={userObject.language}>
                                    <CreditCardForm showButtons={!this.state.hideButtons} cardTokenCreated={this.cardTokenCreated} close={() => this.setState({cardDialogOpen: false})}/>
                                </Elements>
                            </StripeProvider>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.reactivateDialogOpen}
                    onClose={() => this.setState({reactivateDialogOpen: false, currentSubId: undefined})}
                    maxWidth="sm"
                    fullWidth={true}>
                    <DialogTitle>{tr("Reactivate subscription")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {tr("Do you want to reactivate your previously cancelled Taimer subscription and continue with the same payment schedule?")}
                        </DialogContentText>
                    </DialogContent>
                    {!this.state.hideButtons && <DialogActions>
                        <Button onClick={() => this.setState({reactivateDialogOpen: false, currentSubId: undefined})}>
                            {tr("Back")}
                        </Button>
                        <Button onClick={() => this.reactivateSubscription(this.state.currentSubId)}>
                            {tr("Reactivate subscription")}
                        </Button>
                    </DialogActions>}
                </Dialog>

                <Dialog
                    open={this.state.cancelDialogOpen}
                    onClose={() => this.setState({cancelDialogOpen: false})}
                    maxWidth="sm"
                    fullWidth={true}>
                    <DialogTitle>{tr("Cancel subscription")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {tr("Do you want to cancel your Taimer subscription? If you are canceling a plan subscription it will end after the current payment period. If you are canceling add-on it will be canceled immediately.")}
                        </DialogContentText>
                    </DialogContent>
                    {!this.state.hideButtons && <DialogActions>
                        <Button onClick={() => this.setState({cancelDialogOpen: false})}>
                            {tr("Back")}
                        </Button>
                        <Button className="darkred"  onClick={() => this.cancelSubscription(this.state.currentSubId)}>
                            {tr("Cancel subscription")}
                        </Button>
                    </DialogActions>}
                </Dialog>
            </div>
        );
    }
}

export default withSnackbar(SubscriptionInfo);
