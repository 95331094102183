import React from 'react';
import TaimerComponent from '../TaimerComponent';
import DataHandler from "./DataHandler";
import { reduceSum } from "./MathUtils";
import { SettingsContext } from '../SettingsContext';
import { 
    UploadResponse,
    uploadFiles
} from "./FileUtils";
import styles from "./AttachmentInput.module.scss";

interface INotifier {
    notify:        (msg: string) => void;
    notifyError:   (msg: string) => void;
    notifySuccess: (msg: string) => void;
}

interface AttachmentInputProps {
    // Return true if you don't want handleOnChange
    // to continue execution after calling props.onChange.
    onChange:               (files: File[]) => boolean;
    onUploadSuccess:        () => boolean;
    onUploadPartialSuccess: (files: File[]) => boolean;
    onUploadFail:           (files: File[]) => boolean;
    onFileTooLarge:         (files: File[]) => boolean;
    upload:                 boolean;
    notifier:               INotifier;
}

class AttachmentInput extends TaimerComponent<AttachmentInputProps> {
    static contextType  = SettingsContext;
    static defaultProps = {
        onChange:               (files: File[]) => false,
        onUploadSuccess:        () => false,
        onUploadPartialSuccess: (files: File[]) => false,
        onUploadFail:           (files: File[]) => false,
        onFileTooLarge:         (fileSize: number, maxSize: number) => false,
        upload:                 true,
        notifier: {
            notify:        (msg: string) => null,
            notifyError:   (msg: string) => null,
            notifySuccess: (msg: string) => null
        }
    };

    private inputRef: React.RefObject<HTMLInputElement>;
    private uploadParameters: { [key: string]: number | string };
    private tempOnChange: ((files: File[]) => boolean) | undefined;
    private tempResolve: ((r: UploadResponse) => void) | undefined;
    private tempReject:  ((r: UploadResponse) => void) | undefined;

    constructor(props, context) {
        super(props, context, "AttachmentInput");

        this.uploadParameters = {};
        this.tempOnChange     = undefined;
        this.inputRef         = React.createRef<HTMLInputElement>();
    }

    componentDidMount() {
        this.setToSingleSelection();
    }

    handleOnChange = (event) => {
        const files: File[] = Array.from(event.target.files || event.dataTransfer.files)

        if(this.props.onChange(files)) {
            return;
        }

        if(this.tempOnChange !== undefined && this.tempOnChange(files)) {
            return;
        }

        this.upload(event);
    }

    setToSingleSelection = () => {
        this.inputRef?.current?.removeAttribute("multiple");
    }

    setToMultipleSelection = () => {
        this.inputRef?.current?.setAttribute("multiple", "true");
    }

    prompt = (uploadParameters: { [key: string]: number | string }): Promise<UploadResponse> => {
        this.uploadParameters = uploadParameters;

        return new Promise((
            resolve: (r: UploadResponse) => void, 
            reject:  (r: UploadResponse) => void
        ) => {
            this.tempResolve = resolve;
            this.tempReject  = reject;

            this.inputRef?.current?.click();
        });
    }

    promptSingle = (
        uploadParameters: { [key: string]: number | string }, 
        onChange: (files: File[]) => boolean = (files: File[]) => false
    ): Promise<UploadResponse> => {
        this.setToSingleSelection();

        this.tempOnChange = onChange;

        return this.prompt(uploadParameters);
    }

    promptMultiple = (
        uploadParameters: { [key: string]: number | string }, 
        onChange: (files: File[]) => boolean = (files: File[]) => false
    ): Promise<UploadResponse> => {
        this.setToMultipleSelection();

        this.tempOnChange = onChange;

        return this.prompt(uploadParameters);
    }

    fileTooLarge = (response: UploadResponse) => {
        if(this.props.onFileTooLarge(response.originalFiles)) {
            return;
        }

        this.tempReject?.(response);

        this.props.notifier.notifyError(this.tr(
            "The total size of the selected files is above the maximum attachment size"
        ));
    }

    uploadFailed = (response: UploadResponse) => {
        if(this.props.onUploadFail(response.failedFiles)) {
            return;
        }

        this.tempReject?.(response);

        this.props.notifier.notifyError(this.tr(`Uploading the attachments failed`));
    }

    uploadSuccessFul = (response: UploadResponse) => {
        if(this.props.onUploadSuccess()) {
            return;
        }

        this.tempResolve?.(response);

        this.props.notifier.notifySuccess(this.tr(`The attachments were uploaded successfully`));
    }

    uploadPartiallySuccessful = (response: UploadResponse) => {
        if(this.props.onUploadPartialSuccess(response.failedFiles)) {
            return;
        }

        this.tempReject?.(response);

        const placeholder    = "{{%placeholder-do-not-translate%}}";
        const fileTr: string = this.tr(`Failed to upload the file ${placeholder}`);

        this.props.notifier.notifyError(this.tr(`Some of the files you selected could not be uploaded`));

        response.failedFiles.forEach((file: File) => {
            this.props.notifier.notifyError(fileTr.replace(placeholder, file.name));
        });
    }

    upload = async (event) => {
        const { 
            attachmentMaxSize 
        }: {
            attachmentMaxSize: number
        } = this.context.taimerAccount;

        const response: UploadResponse = await uploadFiles(
            Array.from(event.target.files || event.dataTransfer.files), 
            this.uploadParameters, 
            attachmentMaxSize
        );

        if(response.success) {
            this.uploadSuccessFul(response);
        }

        if(response.maxSizeExceeded) {
            this.fileTooLarge(response);
        }

        if(response.partialSuccess) {
            this.uploadPartiallySuccessful(response);
        }

        if(!response.success && !response.partialSuccess) {
            this.uploadFailed(response);
        }

        this.reset();
    }

    reset = () => {
        this.uploadParameters = {};
        this.tempResolve      = undefined;
        this.tempReject       = undefined;
        this.tempOnChange     = undefined;

        this.setToSingleSelection();
    }

    // Needed for when the user decides to 
    // choose the same file twice in a row.
    resetInputValue = (event) => {
        event.target.value = null;
    }

    render() {
        return (
            <input 
                ref={this.inputRef}
                className={styles.input}
                onChange={this.handleOnChange}
                onClick={this.resetInputValue}
                type="file" />
        );
    }
}

export default AttachmentInput;
