import React from "react";
import { Button } from "@mui/material";

export default function TaimerCalendarSidebarHeader(props) {
    return (
        <div className="header">
            {/* <Button
                className="button-create"
                color="primary"
                onClick={() => props.createNewEvent()}
            >
                {"CREATE"}
            </Button> */}
            <div className="header-text">{props.tr("Calendars")}</div>
        </div>
    );
}