import React from "react";

import { SettingsContext } from "../../../SettingsContext";
import TaimerComponent from "../../../TaimerComponent";

import styles from './TableBlock.module.scss';
import BlockBase from "../BlockBase";
import { BlockProps } from "../../base/CustomViewBase";

import List from "../../../list/List";

interface Props<T = any> {
    blockProps: BlockProps;
    data: T[];
    listRowType: React.ElementType;

    summaryRowType?: React.ElementType;

    listProps?: object;
    listRowProps?: object;
}

interface State {
}

export default class TableBlock<T = any> extends TaimerComponent<Props<T>, State> {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "customview/blocks/Table/TableBlock");

        this.state = {
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
    }

    render() {
        const { blockProps, data, listRowType, summaryRowType, listProps, listRowProps } = this.props;
        const { } = this.state;

        return (
            <BlockBase contentClassName={styles.root} {...blockProps}>
                <List
                    // ref={this.subprojectsList}
                    className={styles.table}
                    fluid
                    data={data}
                    height="auto"
                    parentKey="parentId"
                    listRowType={listRowType}
                    summaryRowType={summaryRowType}
                    // treeData={true}
                    parentsExpandedOnInit={true}
                    noStateData={true}
                    {...listProps}
                    // onCheckAll={() => this.onCheck(true)}
                    // onUncheckAll={() => this.onCheck(false)}
                    rowProps={{
                        ...listRowProps,
                    //     onCheck: (x) => this.onCheck(x),
                    //     // showWholeTrees: this.state.showWholeTrees,
                    //     // showTreeStructures: this.state.showTreeStructures,
                    //     statuses: this.projectStatuses,
                    //     // currency: currency
                    }}
                />
            </BlockBase>
        );
    }
}  