import React from "react";

import { SettingsContext } from "../../../SettingsContext";
import TaimerComponent from "../../../TaimerComponent";

import styles from './ChartBlock.module.scss';
import BlockBase, { BlockBaseProps } from "../BlockBase";
import { BlockProps } from "../../base/CustomViewBase";

import cn from "classnames";

interface Props<ChartProps extends object, TableProps extends object> {
    blockProps: BlockProps;
    className?: string;

    chartType: React.ComponentType<ChartProps>;
    chartProps: ChartProps;

    tableType: React.ComponentType<TableProps>;
    tableProps: TableProps;

    toolbarComponent?: BlockBaseProps['topbarComponent'];
}

interface State {
}

export default class ChartBlock<ChartProps extends object, TableProps extends object> extends TaimerComponent<Props<ChartProps, TableProps>, State> {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "customview/blocks/Chart/ChartBlock");

        this.state = {
        }
    }

    componentDidMount = () => {
        super.componentDidMount();
    }

    render() {
        const { className, blockProps, chartType: ChartType, chartProps, tableType: TableType, tableProps, toolbarComponent } = this.props;
        const { } = this.state;

        return (
            <BlockBase contentClassName={cn(styles.root, className)} topbarComponent={toolbarComponent} {...blockProps}>
                {ChartType && chartProps && <ChartType {...chartProps} />}
                {TableType && tableProps && <TableType {...tableProps} />}
            </BlockBase>
        );
    }
}