import { BubbleChart } from "@mui/icons-material";
import LoaderButton from "../general/LoaderButton";
import { useState } from "react";
import OutlinedField from "../general/OutlinedField";
import { Button } from "@mui/material";

const SummarizeWithAIButton = (props) => {
    const { onGenerate, loading } = props;
    const [showInput, setShowInput] = useState(false);
    const [prompt, setPrompt] = useState("");
    const onClick = () => {
        setShowInput(true);
    }
    return (
        <>
            <LoaderButton className="summarize-button" text={'Summarize with AI'} icon={<BubbleChart />} onClick={onClick} loading={loading} />
            {showInput && <div className="dark-bg" />}
            {showInput && <div className='summary-input-container'>
                <h2>Summarize with AI</h2>
                <p>Here, you can give some descriptions on what you'd like to know about your data. You can also just go with the default prompt.</p>
                <OutlinedField multiline label={"Prompt"} onChange={(e) => setPrompt(e.target.value)} value={prompt} shrinkLabel={true} placeholder={"Analyze and summarize the following data. I want a textual overview of the data and the most important findings. Start with some key insights about the data."} />
                <div className="summarize-dialog-actions">
                    {/*@ts-ignore*/}
                    <Button color="cancel" onClick={() => {
                        setShowInput(false);
                    }}>CANCEL</Button>
                    <LoaderButton loading={false} text="Summarize" onClick={() => {
                        setShowInput(false); 
                        onGenerate(prompt);
                    }} />
                </div>
            </div>}</>
    );
}

export default SummarizeWithAIButton;