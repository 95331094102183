import React, { Component } from 'react';
import cn from 'classnames'

export default class DialogSection extends Component {
	render() {
        const { className, innerRef } = this.props;

        return (<div ref={innerRef} className={cn("section", className)}>
            {this.props.children}
        </div>)
	}
}