import React from "react";
import TaimerComponent from '../../../../TaimerComponent';
import cn from 'classnames';
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { GridRow } from "../helpers";

import styles from './GridLeftCell.module.scss';

interface Props {
	item: GridRow;
	toggleRowExpand: any;
}

interface State {
}

export default class GridLeftProjectCell extends TaimerComponent<Props, State> {
	constructor(props, context) {
		super(props, context, "resourcing/views/ResourcingGrid/components/GridLeftProjectCell");
	}

	shouldComponentUpdate = oldProps => oldProps.item !== this.props.item;

	_clickLeftName = (e) => {
		const { updateView } = this.context.functions;
		const { item } = this.props;

		if (item.display_type === "project") {
			updateView({ module: 'projects', action: 'view', id: item.project?.projects_id }, e.button === 1);
		}
	}

	render() {
		const { item, toggleRowExpand } = this.props;
		const { project } = item;

		return (<div className={cn("grid-table-cell", "grid-table-cell-first", "grid-table-project", !item.editable && "row-not-editable")}>
				<div className="expandOrClose">
					{item.hasSubItems && <div className="expand-icon-container-sub" onClick={() => toggleRowExpand(item)}>
						{item.open ? (
							<Arrow className="expand-icon up" />
						) : (
							<Arrow className="expand-icon down" />
						)}
					</div>}
				</div>
				<div className={styles.gridRowLeftNames}>
					<h3 className={styles['grid-type-project']} onMouseUp={this._clickLeftName}>
						{project?.text || "-"}
					</h3>
					<p>
						{project?.customer || "-"}
					</p>
				</div>
			</div>);
	}
}