import React from "react";
import ListCell from "../ListCell";

class NumberListCell extends ListCell {
	presentValue(value) {
		const decimals = this.props.decimals ? this.props.decimals : 0;
		if(this.props.allowEmpty && value === false)  {
			return "";
		} else {
			return Number(value).toFixed(decimals);
		}
	}
}

export default NumberListCell;