import React from "react";
import { Drawer, Button } from "@mui/material";
import { Close } from "@mui/icons-material";

import TaimerComponent from "../../TaimerComponent";
import List from "../List";
import DataHandler from "../../general/DataHandler";
import CatalogListSliderOverlay from "../overlays/CatalogListSliderOverlay";
import "./ProductDetailsInfo.css";
import PropsOnlyListRow from "../PropsOnlyListRow";
import ListCell from "../ListCell";
import DateCell from "../cells/DateCell";


class ProductEventLogSliderRow extends PropsOnlyListRow {
    constructor(props) {
      super(props, null, {}, "list/rows/ProductEventLogSliderRow");
    }
  
    defineClassName() {
      return "ProductEventLogSliderRow";
    }
  
  
    defineCells() {
      const {columnWidthMap, data} = this.props;
      let cells = {};
  
    
      cells = {
       
        date: <DateCell  width={columnWidthMap.date} value={data['date']} editable={false} />,
    event: (
        <ListCell
          width={columnWidthMap.event}
          noTab={true}
          value={data['comment']}
          editable={false}
          showTooltipForOverflownText={true}
        />
      ),
    balance: (
        <ListCell width={columnWidthMap.balance} value={data['balance']} editable={false} >
          {data.balance}
        </ListCell>
      ),
    free_balance: (
      <ListCell width={columnWidthMap.free_balance} value={data['free_balance']} editable={false} >
         {data.free_balance}
      </ListCell>
      ),
    change: (
        <ListCell width={columnWidthMap.change} value={data['change']} editable={false} >
         {data.change}
        </ListCell>
      )
    };
  
      return cells;
    }
  }

class ProductEventLogSlider extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/lists/ProductEventLogSlider");

    this.state={ 
      data: [], 
    }
  }

  componentDidMount() {
    super.componentDidMount();

    DataHandler.get({url: `products/${this.props.productId}/event_log`}).done(response => {
      if (response.balance_history)
        this.setState({ data: response.balance_history });
    });
  }

  _getColumns = () => {

    const staticColumnConfig = {
      showMenu: false,
      resizeable: false,
      showResizeMarker: false,
      moveable: false,
      hideable: false,
      visibleInToolbar: true,
    };

    return [
        {
            field: "date",
            name: "date",
            header: this.tr("Date"),
            width: 100,
            ...staticColumnConfig,
        },
      {
        field: "event",
        name: "event",
        header: this.tr("Event"),
        width:300,
        ...staticColumnConfig,
      },
      {
        field: "balance",
        name: "balance",
        header: this.tr("Balance"),
        width: 120,
        ...staticColumnConfig,
      },
      {
        field: "free_balance",
        name: "free_balance",
        header: this.tr("Free Balance"),
        width: 120,
        ...staticColumnConfig,
      },
      {
        field: "change",
        name: "change",
        header: this.tr("Change"),
        width: 120,
        ...staticColumnConfig,
      }
    ];
  }

 
  render() {
    const { open, onClose, height } = this.props;
    const {ProjectSalesList} = this.state;

    return (

      <div className="product-card-content">
        <div className="product-card-bottom">
          <List
            fluid
            columns={this._getColumns()}
            height={height}
            listRowType={ProductEventLogSliderRow}
            noStateData={true}
            data={this.state.data}

          />
        </div>
      </div>
     
    );
  }
}

export default ProductEventLogSlider;
