import { Button } from '@mui/material';
import colors from '../colors';
import DataHandler from "../general/DataHandler";
import DateCell from "../list/cells/DateCell";
import EditableStatusCell from "../list/cells/EditableStatusCell";
import CurrencyListCell from "../list/CurrencyListCell";
import LinkListCell from "../list/LinkListCell";
import List from "../list/List";
import ListCell from "../list/ListCell";
import ListRow from "../list/ListRow";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import { SettingsContext } from '../SettingsContext';
import TaimerComponent from '../TaimerComponent';

class PoSyncingSliderRow extends PropsOnlyListRow {
    static contextType = SettingsContext;
    static defaultProps = {
        ...ListRow.defaultProps,
        rowProps: {
            ...ListRow.defaultProps.rowProps,
            editMode: false
        },
    };
    
        
    constructor(props, context) {
        super(props, {}, {}, "bills/PoSyncingSliderRow");
    }

    // TODO: abstract
    defineClassName() {
        return "PoSyncingSliderRow";
    }

    sync = async () => {
        if (this.props.rowProps.view === "po") {
            const currentBillSync = await DataHandler.get({url: `bills/${this.props.data.id}/synced_po_rows/`});

            if (currentBillSync.data.length > 0)
                this.props.rowProps.showWarning(this.props.data.id, currentBillSync.data[0].id);
            else 
                this.props.rowProps.syncFunc(this.props.data.id);
        }
        else 
            this.props.rowProps.syncFunc(this.props.data.id);
    }

    defineCells() {
        const { data, rowProps } = this.props;
        const { currency, syncedRows, view } = rowProps;

        const isSynced = syncedRows.find(e => e.id == data.id);
        const linkModule = view === "po" ? "receivedinvoice" : "purchaseorder";
        const linkCompany = view === "po" ? "" : "&companies_id=" + data['companies_id'];

        const syncStatuses = [
            { id: 4, name: this.tr("Synced"), title: this.tr("Synced"), color: colors.greenish_cyan }
        ]

        let cells = {
            nr: 
                <LinkListCell
                    listCellProps={{inEditMode: false}}
                    value={data['nr'] || "#"}
                    urlHandler={value => `index.html?module=${linkModule}&action=view&id=${data['id']}${linkCompany}`}
                    noTab={true}
                    asText={false}
                    editable={false}
                /> ,
            state:
                <EditableStatusCell
                    listCellProps={{inEditMode: false}}
                    value={data['state']}
                    options={this.props.sharedData.statuses}
                    editable={false}
                /> ,
            supplier:
                <ListCell
                    listCellProps={{inEditMode: false}}
                    value={data['supplier']}
                    editable={false}
                />,
            project:
                view === "po" ? (
                    <LinkListCell
                        value={data.target_project}
                        valueHandler={value => value.name}
                        urlHandler={value => value.url}
                        noTab={true}
                        editable={false}
                    />
                    ) : (
                    <ListCell
                        listCellProps={{inEditMode: false}}
                        editable={false}
                        onlyDisplay
                    >
                        <a href={`index.html?module=customers&action=view&id=${data['targeted_customers_id']}&companies_id=${data['companies_id']}`}>{data['targeted_customers_name']}</a>
                        &nbsp; {'>'} &nbsp;
                        <a href={`index.html?module=projects&action=view&id=${data['project_id']}`}>{data['project']}</a>
                    </ListCell>),
            duedate:
                <DateCell
                    listCellProps={{inEditMode: false}}
                    value={data['duedate']}
                    editable={false}
                />,
            netsum:
                <CurrencyListCell
                    listCellProps={{inEditMode: false}}
                    value={data['netsum']}
                    currency={currency}
                    editable={false}
                />,
            sync:
                !isSynced ? <ListCell
                    listCellProps={{inEditMode: false}}
                    editable={false}
                    onlyDisplay
                >
                    {isSynced ? <div></div> :
                        <Button data-testid={"sync-po-bill-" + data.id} className="blue" onClick={() => this.sync()}>{this.tr("Sync").toUpperCase()}</Button>}
                </ListCell> :
                <EditableStatusCell
                    listCellProps={{inEditMode: false}}
                    value={4}
                    options={syncStatuses}
                    editable={false}
                />,
        };

        return cells;
    }
}

class PoSyncingSliderList extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "bills/PoSyncingSliderList");
    }

    showWarning = (billId, poId) => {
        this.props.openWarningDialog(billId, poId);
    }
            
    render() {
        const { rows, editMode, currency, statuses, syncedRows, view, syncFunc, company } = this.props;
        const listHeader = view === "po" ? "Bill" : "PO"

        const columnConfig = {
            showMenu: false,
            resizeable: false,
            showResizeMarker: false,
            moveable: false,
            hideable: false,
        };

        return (
                <List
                    fluid={true}
                    noStateData={true}
                    id="poSyncingSliderList"
                    className="poSyncingSliderList"
                    hideHeaderButtons={!editMode}
                    height="auto"
                    data={rows}
                    rowHeight={48} // row height + ONE margin
                    listRowType={PoSyncingSliderRow}
                    sharedData={{statuses: statuses}}
                    rowProps={{
                        currency,
                        syncedRows,
                        syncFunc,
                        view,
                        company,
                        showWarning: this.showWarning
                    }}
                    columns={[
                        { name: "nr", header: this.tr(listHeader + " nr."), width: 80, ...columnConfig },
                        { name: "state", header: this.tr(listHeader + " status"), width: 80, ...columnConfig },
                        { name: "supplier", header: this.tr("Supplier"), width: 100, ...columnConfig },
                        { name: "project", header: this.tr("Targeted to project"), width: 170, ...columnConfig },
                        { name: "duedate", header: this.tr("Dated"), width: 60, ...columnConfig },
                        { name: "netsum", header: this.tr("Sum 0%"), width: 50, ...columnConfig },
                        { name: "sync", header: this.tr("Sync PO"), width: 90, ...columnConfig },
                    ]} />
        )}
}

export default PoSyncingSliderList;
