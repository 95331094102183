import _ from 'lodash';

export function filterSliderData(items, sliderRows) {
    const sliders = {};

    for (const item of items) {
        _.map(item.slider_rows, (v, i) => {
            if (v === true || v === 1) {
                sliders[i] = sliderRows[i];
            } else {
                sliders[i] = { ...sliderRows[i], ...v };
            }
        });
    }

    return sliders;
}