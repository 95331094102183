import React from 'react';
import TaimerComponent from '../../TaimerComponent';
import WithTabs from '../WithTabs';
import TimeTracker from '../../workhours/time-tracker/TimeTracker';
import TaimerCalendarLogin from '../../taimercalendar/TaimerCalendarLogin';
import VersionContentManager from '../../general/VersionContentManager';

class TimeManagement extends TaimerComponent<any, any> {
    tabs;
    timeTrackingVideoURL = "https://www.youtube.com/embed/Z5BNHXaVI7M?si=oeGwIsQhXeQhjUq4";
    constructor(props, context) {
        super(props, context, 'navigation/pages/TimeManagement');
        this.tabs = VersionContentManager.getViewTabs(this.namespace).map(this.formatTab);
    }

    getAppcuesFlow = () => {
        switch (this.context.versionId) {
            case 10:
                return this.context.userObject.language == 'fi' ? '7a439618-cf42-4533-a88e-a980de74642e' : '57c31c35-81bc-472d-bb8f-a47710a9d8c8';
            case 11:
                return this.context.userObject.language == 'fi' ? 'fe5e504a-dd77-47b9-81e6-fd80606336ed' : 'a8dd7ff6-6889-407c-8e39-8e8212392752';
            case 12:
                return this.context.userObject.language == 'fi' ? '096f207b-d583-469a-b4b2-f21d3f2bd73d' : '53cb5db3-8ffa-47bc-8f21-23d14f671e72';
            default:
                return this.context.userObject.language == 'fi' ? '177dfbd5-82ff-45da-96fc-f2a56df78561' : '9bcb00bf-8783-4c5c-a015-87f7b31dc047';
        }
    };

    getTabInfoButtonProps = (selectedTab) => {
        switch (selectedTab) {
            case 'calendarView':
                return {
                    title: this.tr('How Hours Calendar works'),
                    methods: {
                        article:
                            this.context.userObject.language == 'fi'
                                ? 'https://psahelpcenter.heeros.com/hc/fi/articles/8081204912914'
                                : 'https://psahelpcenter.heeros.com/hc/en-us/articles/8081204912914',
                        flow: this.getAppcuesFlow(),
                        video: this.context.userObject.language == 'fi' ? this.timeTrackingVideoURL : undefined
                    },
                };
            case 'bulkEntry':
                return {
                    title: this.tr('How Bulk Entry works'),
                    methods: {
                        article:
                            this.context.userObject.language == 'fi'
                                ? 'https://psahelpcenter.heeros.com/hc/fi/articles/8080396336914'
                                : 'https://psahelpcenter.heeros.com/hc/en-us/articles/8080396336914',
                        video: this.context.userObject.language == 'fi' ? this.timeTrackingVideoURL : undefined
                    },
                };
            case 'approvals':
                return {
                    title: this.tr('How Hours Approval works'),
                    methods: {
                        article:
                            this.context.userObject.language == 'fi' ? 'https://psahelpcenter.heeros.com/hc/fi/articles/8080895162770' : 'https://psahelpcenter.heeros.com/hc/en-us/articles/8080895162770',
                    },
                };
            default:
                return undefined;
        }
    };

    formatTab = (tab) => ({ ...tab, label: this.tr(tab.label), items: tab.items ? tab.items.map(this.formatTab) : undefined });

    render() {
        return (
            <WithTabs
                offsetTop={10}
                tabs={this.tabs}
                selectedTab={this.props.selectedTab}
                selectedSubTab={this.props.selectedSubTab}
                tabsAlwaysVisible
                getTabInfoButtonProps={this.getTabInfoButtonProps}
            >
                {(selectedTab) => {
                    switch (selectedTab) {
                        case 'calendarView':
                        case 'bulkEntry':
                        case 'myHours':
                        case 'approvals':
                            return <TimeTracker {...this.props} tab={selectedTab} />;
                        case 'calendar':
                            return <TaimerCalendarLogin {...this.props} />;
                        default:
                            return null;
                    }
                }}
            </WithTabs>
        );
    }
}

export default TimeManagement;
