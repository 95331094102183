import React from "react";
import ListCell from "../../list/ListCell";
import SettingRow from "../../list/rows/SettingRow";
import TrashCanIcon from "@mui/icons-material/Delete";
import TextInputCell from "../../list/cells/TextInputCell";

export default class TalenomProductRow extends SettingRow {

	/**
	 * TalenomCostPoolRow renderer
	 *
	 */
	render() {
		const cells = {
			delete: (
				<ListCell
					className='noBg pointer'
					permanentEditMode={true}
					onClick={() => this.delete(this.state.data)}
					width={this.props.columnWidthMap['delete']}
				>
					<TrashCanIcon />
				</ListCell>
            ),
            name: (
				<TextInputCell
					width={this.props.columnWidthMap["name"]}
					name="name"
					value={this.state.data["name"]}
					onEdited={this.editAndSave}
				/>
			),
			product_id: (
				<TextInputCell
					width={this.props.columnWidthMap['product_id']}
					name='product_id'
					value={this.state.data['product_id']}
					onEdited={this.editAndSave}
				/>
			),
		};

		return (
			<div
				className='listElement row flex'
				style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}
			>
				{this.props.columnOrder.map(columnName => cells[columnName])}
			</div>
		);
	}
}